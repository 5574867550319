import { Schema } from "@effect/schema";
import { Option as O, Predicate as P, String as S } from "effect";

import type { UseFormReturn } from "@ender/form-system/base";
import { Form } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import type { Undefined } from "@ender/shared/constants/general";
import { Button } from "@ender/shared/ds/button";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { CustomFactorFactorOutputType } from "@ender/shared/generated/ender.model.factor";
import { CustomFactorFactorOutputTypeEnum } from "@ender/shared/generated/ender.model.factor";
import type { FactorOutputType } from "@ender/shared/types/custom-factors";

const outputTypeOptions = [
  { label: "Text", value: CustomFactorFactorOutputTypeEnum.STRING },
  { label: "Money", value: CustomFactorFactorOutputTypeEnum.MONEY },
  { label: "Number", value: CustomFactorFactorOutputTypeEnum.NUMBER },
  { label: "Date", value: CustomFactorFactorOutputTypeEnum.DATE },
  { label: "Yes/No", value: CustomFactorFactorOutputTypeEnum.BOOLEAN },
  {
    label: "Multiple Choice (Enum)",
    value: CustomFactorFactorOutputTypeEnum.ENUM,
  },
];

const booleanOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
  { label: "No Default Value", value: "" },
];

const NewCustomFactorFormSchema = Schema.Struct({
  defaultBooleanValue: Schema.Literal(
    ...booleanOptions.map(({ value }) => value),
  ).pipe(Schema.OptionFromSelf),
  defaultDateValue: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  defaultMoneyValue: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  defaultNumberValue: Schema.Number.pipe(Schema.OptionFromSelf),
  defaultStringValue: Schema.String,
  enumValues: Schema.String,
  isFavorite: Schema.Boolean,
  isRestricted: Schema.Boolean,
  name: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Field Name is required" }),
  ),
  outputType: Schema.Literal(
    ...outputTypeOptions.map(({ value }) => value),
  ).pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Field Type is required" }),
  ),
}).pipe(
  Schema.filter((values) => {
    if (
      values.outputType.pipe(O.getOrUndefined) ===
        CustomFactorFactorOutputTypeEnum.ENUM &&
      S.isEmpty(values.enumValues)
    ) {
      return {
        message: "Please provide comma separated list of possible choices",
        path: ["enumValues"],
      };
    }
  }),
);

type NewCustomFactorFormInput = Schema.Schema.Encoded<
  typeof NewCustomFactorFormSchema
>;
type NewCustomFactorFormOutput = Schema.Schema.Type<
  typeof NewCustomFactorFormSchema
>;

type CustomFactorValueProps = {
  form: UseFormReturn<NewCustomFactorFormInput, {}, NewCustomFactorFormOutput>;
  outputType: FactorOutputType;
};

function FormSystemCustomFactorValue({
  form,
  outputType,
}: CustomFactorValueProps) {
  if (outputType === CustomFactorFactorOutputTypeEnum.ENUM) {
    return (
      <FormTextInput
        label="Possible Choices (comma separated list)"
        name="enumValues"
        form={form}
        placeholder="Apple, Banana, Pear"
      />
    );
  }

  if (outputType === CustomFactorFactorOutputTypeEnum.BOOLEAN) {
    return (
      <FormSelect
        label="Default Value (Optional)"
        name="defaultBooleanValue"
        data={booleanOptions}
        form={form}
      />
    );
  }

  if (outputType === CustomFactorFactorOutputTypeEnum.STRING) {
    return (
      <FormTextInput
        label="Default Value (Optional)"
        name="defaultStringValue"
        form={form}
      />
    );
  }

  if (outputType === CustomFactorFactorOutputTypeEnum.MONEY) {
    return (
      <FormMoneyInput
        label="Default Value (Optional)"
        name="defaultMoneyValue"
        form={form}
      />
    );
  }

  if (outputType === CustomFactorFactorOutputTypeEnum.NUMBER) {
    return (
      <FormNumberInput
        label="Default Value (Optional)"
        name="defaultNumberValue"
        form={form}
      />
    );
  }

  if (outputType === CustomFactorFactorOutputTypeEnum.DATE) {
    return (
      <FormDateInput
        label="Default Value (Optional)"
        name="defaultDateValue"
        form={form}
      />
    );
  }
}

type NewCustomFactorFormProps = {
  form: UseFormReturn<NewCustomFactorFormInput, {}, NewCustomFactorFormOutput>;
  handleSubmit: (values: NewCustomFactorFormOutput) => void;
  outputType: CustomFactorFactorOutputType | Undefined;
  showRestrictedOption: boolean;
};

function NewCustomFactorFormView({
  form,
  handleSubmit,
  outputType,
  showRestrictedOption,
}: NewCustomFactorFormProps) {
  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput
          label="Field Name"
          name="name"
          form={form}
          placeholder="e.g. Investment Strategy"
        />
        <FormSelect
          label="Field Type"
          name="outputType"
          data={outputTypeOptions}
          placeholder="Select"
          form={form}
        />
        {P.isNotNullable(outputType) && (
          <FormSystemCustomFactorValue form={form} outputType={outputType} />
        )}
        <FormCheckbox label="Favorite" name="isFavorite" form={form} />
        {showRestrictedOption && (
          <FormCheckbox
            label="Hide from Brokers"
            name="isRestricted"
            form={form}
          />
        )}
        <Group justify={Justify.end}>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { NewCustomFactorFormSchema, NewCustomFactorFormView };
export type { NewCustomFactorFormInput, NewCustomFactorFormOutput };
