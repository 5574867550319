/**
 * HTTP Method: POST
 * Pathname: /lease/{leaseId}/reverseRefund
 * @function reverseTenantRefund
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPIReverseTenantRefundPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPIReverseTenantRefundPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPIReverseTenantRefundSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPIReverseTenantRefundBodyParams = {
  description?: string | undefined;
  periodId?: EnderId | undefined;
  refundMoneyTransferId: EnderId;
  reversalDate: SerializesInto<LocalDate>;
};

type TenantLedgerAPIReverseTenantRefundPayload =
  TenantLedgerAPIReverseTenantRefundPathParams &
    TenantLedgerAPIReverseTenantRefundSearchParams &
    TenantLedgerAPIReverseTenantRefundBodyParams;

function reverseTenantRefundUnsafeEffect(
  payload: TenantLedgerAPIReverseTenantRefundPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/lease/${leaseId}/reverseRefund`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function reverseTenantRefund(
  payload: TenantLedgerAPIReverseTenantRefundPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId> {
  return F.pipe(
    payload,
    reverseTenantRefundUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { reverseTenantRefund };
export type {
  TenantLedgerAPIReverseTenantRefundBodyParams,
  TenantLedgerAPIReverseTenantRefundPathParams,
  TenantLedgerAPIReverseTenantRefundPayload,
  TenantLedgerAPIReverseTenantRefundSearchParams,
};
