/**
 * HTTP Method: GET
 * Pathname: /insurances/{insuredParty}/{insuredPartyId}
 * @function getInsurancePolicies
 * @memberof InsuranceAPI
 * @param {InsuranceAPIGetInsurancePoliciesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InsurancePolicy[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  InsurancePolicy,
  InsurancePolicyInsuredParty,
} from "@ender/shared/generated/ender.model.core.vendor";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InsuranceAPIGetInsurancePoliciesPathParams = {
  insuredParty: InsurancePolicyInsuredParty;
  insuredPartyId: EnderId;
};

type InsuranceAPIGetInsurancePoliciesSearchParams = {
  token?: string | undefined;
};

type InsuranceAPIGetInsurancePoliciesPayload =
  InsuranceAPIGetInsurancePoliciesPathParams &
    InsuranceAPIGetInsurancePoliciesSearchParams;

function getInsurancePoliciesUnsafeEffect(
  payload: InsuranceAPIGetInsurancePoliciesPayload,
) {
  const { insuredParty, insuredPartyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InsurancePolicy[]>({
        body,
        decode: unsafeDecodeJsonResponse<InsurancePolicy[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/insurances/${insuredParty}/${insuredPartyId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInsurancePolicies(
  payload: InsuranceAPIGetInsurancePoliciesPayload,
  options?: { signal?: AbortSignal },
): Promise<InsurancePolicy[]> {
  return F.pipe(
    payload,
    getInsurancePoliciesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInsurancePolicies };
export type {
  InsuranceAPIGetInsurancePoliciesPathParams,
  InsuranceAPIGetInsurancePoliciesPayload,
  InsuranceAPIGetInsurancePoliciesSearchParams,
};
