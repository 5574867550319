/**
 * HTTP Method: GET
 * Pathname: /system-widgets/{widgetName}
 * @function getWidget
 * @memberof ReportsAPI
 * @param {ReportsAPIGetWidgetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportWidget>}
 */
import { Effect, Function as F } from "effect";

import type { ReportWidget } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIGetWidgetPathParams = {
  widgetName: string;
};

type ReportsAPIGetWidgetSearchParams = {
  token?: string | undefined;
};

type ReportsAPIGetWidgetPayload = ReportsAPIGetWidgetPathParams &
  ReportsAPIGetWidgetSearchParams;

function getWidgetUnsafeEffect(payload: ReportsAPIGetWidgetPayload) {
  const { widgetName, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportWidget>({
        body,
        decode: unsafeDecodeJsonResponse<ReportWidget>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/system-widgets/${widgetName}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getWidget(
  payload: ReportsAPIGetWidgetPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportWidget> {
  return F.pipe(
    payload,
    getWidgetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getWidget };
export type {
  ReportsAPIGetWidgetPathParams,
  ReportsAPIGetWidgetPayload,
  ReportsAPIGetWidgetSearchParams,
};
