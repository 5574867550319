import { Predicate as P } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import { Money$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";

type PayeePayerAccountNumberAmountProps = {
  accountName: string | Undefined;
  accountNumber: string | Undefined;
  checkNumber?: string;
  memo?: string;
  payeeName?: string;
  payerName?: string;
  totalAmount: number | string | Money$.Money | Undefined;
};

// 2025-01-29 Geoffrey: refactor to CommonPaymentDetails or some like
function PayeePayerAccountNumberAmount({
  accountName,
  accountNumber,
  checkNumber,
  memo,
  payeeName,
  payerName,
  totalAmount,
}: PayeePayerAccountNumberAmountProps) {
  return (
    <Stack spacing={Spacing.none}>
      {P.isNotNullable(payeeName) && <Tuple label="Payee" value={payeeName} />}
      {P.isNotNullable(payerName) && <Tuple label="Payer" value={payerName} />}
      <Tuple label="Account Name" value={accountName} />
      <Tuple label="Account Number" value={accountNumber} />
      <Tuple
        label="Total Amount"
        value={<MoneyDisplay showSymbol value={Money$.parse(totalAmount)} />}
      />
      {P.isNotNullable(checkNumber) && (
        <Tuple label="Check Number" value={checkNumber} />
      )}
      {P.isNotNullable(memo) && <Tuple label="Memo" value={memo} />}
    </Stack>
  );
}

export { PayeePayerAccountNumberAmount };
export type { PayeePayerAccountNumberAmountProps };
