import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyAreaPropertyHomeTypeValues = [
  "SINGLE_FAMILY_DETACHED",
  "SINGLE_FAMILY_ATTACHED",
] as const;

const PropertyAreaPropertyHomeTypeEffectSchema = Schema.Literal(
  ...PropertyAreaPropertyHomeTypeValues,
);

type PropertyAreaPropertyHomeType = Schema.Schema.Type<
  typeof PropertyAreaPropertyHomeTypeEffectSchema
>;

const PropertyAreaPropertyHomeTypeEnum = castEnum(
  PropertyAreaPropertyHomeTypeEffectSchema,
);

function randomPropertyAreaPropertyHomeType(): PropertyAreaPropertyHomeType {
  return rand(PropertyAreaPropertyHomeTypeValues);
}

export {
  PropertyAreaPropertyHomeTypeEffectSchema,
  PropertyAreaPropertyHomeTypeEnum,
  PropertyAreaPropertyHomeTypeValues,
  randomPropertyAreaPropertyHomeType,
};
export type { PropertyAreaPropertyHomeType };
