/**
 * HTTP Method: POST
 * Pathname: /systemReports/{reportId}/run
 * @function runSystemReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunSystemReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportsAPIRunSystemReportResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ReportsAPIRunSystemReportResponse } from "../reports-api-run-system-report-response";

type ReportsAPIRunSystemReportPathParams = {
  reportId: string;
};

type ReportsAPIRunSystemReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunSystemReportBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  paramValues: any[][];
};

type ReportsAPIRunSystemReportPayload = ReportsAPIRunSystemReportPathParams &
  ReportsAPIRunSystemReportSearchParams &
  ReportsAPIRunSystemReportBodyParams;

function runSystemReportUnsafeEffect(
  payload: ReportsAPIRunSystemReportPayload,
) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportsAPIRunSystemReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ReportsAPIRunSystemReportResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/systemReports/${reportId}/run`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runSystemReport(
  payload: ReportsAPIRunSystemReportPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIRunSystemReportResponse> {
  return F.pipe(
    payload,
    runSystemReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runSystemReport };
export type {
  ReportsAPIRunSystemReportBodyParams,
  ReportsAPIRunSystemReportPathParams,
  ReportsAPIRunSystemReportPayload,
  ReportsAPIRunSystemReportSearchParams,
};
