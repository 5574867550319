import { Function as F } from "effect";
import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { UserContext } from "@ender/shared/contexts/user";
import { MoveInAPI } from "@ender/shared/generated/ender.api.leasing";
import { EnderAPI } from "@ender/shared/generated/ender.api.misc";
import { ModelSettingsSettingKeyEnum } from "@ender/shared/generated/ender.model.misc";
import type {
  UseListState,
  UseListStateHandlers,
} from "@ender/shared/hooks/use-list-state";
import { useListState } from "@ender/shared/hooks/use-list-state";
import type { ChecklistConfigStep } from "@ender/shared/types/move-in";
import { fail } from "@ender/shared/utils/error";

type SettingsState = {
  digitalConditionStatement?: boolean;
  electronicPayments?: boolean;
  achPayments?: boolean;
  includeDepositReceipt?: boolean;
  includeOther?: boolean;
};

type MoveInSettingsContextValue = {
  checklistConfigSteps: UseListState<ChecklistConfigStep>;
  refetch: () => void;
  settings: SettingsState & {
    setElectronicPayments: (enabled: boolean) => void;
    setACHPayments: (enabled: boolean) => void;
    setIncludeDepositReceipt: (enabled: boolean) => void;
  };
};

const initialSettingsState: SettingsState = {
  digitalConditionStatement: false,
  electronicPayments: false,
  includeOther: false,
};

const initialChecklistConfigStepsState: ChecklistConfigStep[] = [];

const initialValue: MoveInSettingsContextValue = {
  checklistConfigSteps: [
    initialChecklistConfigStepsState,
    {} as UseListStateHandlers<ChecklistConfigStep>,
  ],
  refetch: F.constVoid,
  settings: {
    ...initialSettingsState,
    setElectronicPayments: F.constVoid,
    setACHPayments: F.constVoid,
    setIncludeDepositReceipt: F.constVoid,
  },
};

function fetchChecklistConfigSteps(): Promise<ChecklistConfigStep[]> {
  return MoveInAPI.getMoveInConfig({});
}

const MoveInSettingsContext =
  createContext<MoveInSettingsContextValue>(initialValue);

function MoveInSettingsProvider(props: PropsWithChildren) {
  const { userPM, refetchUserPM } = useContext(UserContext);

  const pmSettings = useMemo<SettingsState>(
    () => ({
      electronicPayments: !userPM.disableElectronicPaymentForNewLeases,
      achPayments: !userPM.disableACHPaymentForNewLeases,
      includeDepositReceipt: userPM.requireMoveInDepositReceipt,
    }),
    [
      userPM.disableElectronicPaymentForNewLeases,
      userPM.disableACHPaymentForNewLeases,
      userPM.requireMoveInDepositReceipt,
    ],
  );
  const checklistConfigSteps = useListState<ChecklistConfigStep>(
    initialChecklistConfigStepsState,
  );

  const [, checklistHandlers] = checklistConfigSteps;

  const fetchAndSetChecklistConfigSteps = useCallback(
    () => fetchChecklistConfigSteps().then(checklistHandlers.setState),
    // mantine useListState does not use callbacks and therefore can't be added to dep. array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const refetch = useCallback(() => {
    return fetchAndSetChecklistConfigSteps();
  }, [fetchAndSetChecklistConfigSteps]);

  const setElectronicPayments = useCallback(
    (enabled: boolean) =>
      EnderAPI.updateModelSettings({
        modelId: userPM.id,
        on: !enabled,
        setting:
          ModelSettingsSettingKeyEnum.DISABLE_ELECTRONIC_PAYMENT_FOR_NEW_LEASES,
      })
        .then(refetchUserPM)
        .catch(fail),
    [userPM.id, refetchUserPM],
  );

  const setACHPayments = useCallback(
    (enabled: boolean) =>
      EnderAPI.updateModelSettings({
        modelId: userPM.id,
        on: enabled,
        setting: ModelSettingsSettingKeyEnum.DISABLE_ACH_PAYMENT_FOR_NEW_LEASES,
      })
        .then(refetchUserPM)
        .catch(fail),
    [userPM.id, refetchUserPM],
  );

  const setIncludeDepositReceipt = useCallback(
    (enabled: boolean) =>
      EnderAPI.updateModelSettings({
        modelId: userPM.id,
        on: enabled,
        setting: ModelSettingsSettingKeyEnum.REQUIRE_MOVE_IN_DEPOSIT_RECEIPT,
      })
        .then(refetchUserPM)
        .catch(fail),
    [userPM.id, refetchUserPM],
  );

  // lazy useEffect
  useEffect(() => {
    refetch();
  }, [refetch]);

  const value: MoveInSettingsContextValue = {
    checklistConfigSteps,
    refetch,
    settings: {
      ...pmSettings,
      setACHPayments,
      setElectronicPayments,
      setIncludeDepositReceipt,
    },
  };

  return (
    <MoveInSettingsContext.Provider value={value}>
      {props.children}
    </MoveInSettingsContext.Provider>
  );
}

export { MoveInSettingsContext, MoveInSettingsProvider };
