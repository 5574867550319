import { Function as F, Option as O, Predicate as P } from "effect";
import type { PropsWithChildren } from "react";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { AuthStateEnum, useAuthActor } from "@ender/features/auth";
import {
  BrandingStateEnum,
  useBrandingStore,
} from "@ender/shared/stores/branding-store";

type BrandingStoreInitializerProps = PropsWithChildren;

function BrandingStoreInitializer(props: BrandingStoreInitializerProps) {
  const { children } = props;
  const location = useLocation();

  const [authSnapshot] = useAuthActor();
  const {
    value: authState,
    context: { session },
  } = authSnapshot;

  const isTenant = useMemo(() => {
    return F.pipe(
      session,
      O.map((s) => s.user.isTenant),
      O.getOrElse(() => false),
    );
  }, [session]);

  const attemptLoadingBranding = useBrandingStore(
    ({ attemptLoadingBranding }) => attemptLoadingBranding,
  );
  const setIsEnabled = useBrandingStore(({ setIsEnabled }) => setIsEnabled);
  const branding = useBrandingStore(({ branding }) => branding);
  const brandingState = useBrandingStore(({ brandingState }) => brandingState);

  type BrandedRoute = {
    route: RegExp | string;
    condition?: boolean | undefined;
  };

  /** @description Routes which should appear as branded */
  const brandedRoutes: BrandedRoute[] = useMemo(() => {
    return [
      /** @description Tenant Dashboard, Chat */
      {
        condition: authState === AuthStateEnum.AUTHENTICATED && isTenant,
        route: /^\/(app|tenant-portal|profile|chat(\/lease)?)$/, // Matches "/app", "/tenant-portal", "/profile", "/chat" & "/chat/lease"
      },

      /** @description Tenant Application Workflow */
      { route: new RegExp(/^\/units\/[0-9A-Z]+\/apply/) },

      { route: "/login" },
      { route: "/welcome" },
    ];
  }, [authState, isTenant]);

  const canImplementBranding = useMemo(() => {
    // PREVENT BRANDING STYLES FROM LOADING
    return brandedRoutes.some(({ route, condition = true }) => {
      const _routeMatches =
        route instanceof RegExp
          ? route.test(location.pathname)
          : route === location.pathname;

      return _routeMatches && condition;
    });
  }, [brandedRoutes, location.pathname]);

  const isLoaded = useMemo(
    () =>
      !canImplementBranding ||
      P.isNotNullable(branding) ||
      brandingState === BrandingStateEnum.FAIL ||
      brandingState === BrandingStateEnum.LOADED,
    [branding, brandingState, canImplementBranding],
  );

  /** @description Manage the addition of branding styles */
  useEffect(() => {
    setIsEnabled(canImplementBranding);
  }, [canImplementBranding, setIsEnabled]);

  /** @description Manage the addition of branding styles */
  useEffect(() => {
    if (
      !canImplementBranding ||
      brandingState !== BrandingStateEnum.UNINITIALIZED
    ) {
      // PREVENT BRANDING STYLES FROM LOADING
      return;
    }

    attemptLoadingBranding(canImplementBranding);
  }, [attemptLoadingBranding, brandingState, canImplementBranding]);

  return isLoaded ? children : <></>;
}

export { BrandingStoreInitializer };
