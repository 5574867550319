/**
 * HTTP Method: DELETE
 * Pathname: /buy/boxes/{buyBoxId}
 * @function deleteBuyBox
 * @memberof BuyBoxAPI
 * @param {BuyBoxAPIDeleteBuyBoxPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyBoxAPIDeleteBuyBoxPathParams = {
  buyBoxId: EnderId;
};

type BuyBoxAPIDeleteBuyBoxSearchParams = {
  token?: string | undefined;
};

type BuyBoxAPIDeleteBuyBoxPayload = BuyBoxAPIDeleteBuyBoxPathParams &
  BuyBoxAPIDeleteBuyBoxSearchParams;

function deleteBuyBoxUnsafeEffect(payload: BuyBoxAPIDeleteBuyBoxPayload) {
  const { buyBoxId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/buy/boxes/${buyBoxId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteBuyBox(
  payload: BuyBoxAPIDeleteBuyBoxPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteBuyBoxUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteBuyBox };
export type {
  BuyBoxAPIDeleteBuyBoxPathParams,
  BuyBoxAPIDeleteBuyBoxPayload,
  BuyBoxAPIDeleteBuyBoxSearchParams,
};
