import { Predicate as P } from "effect";
import { Link } from "react-router-dom";

import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { DealStatusPipelineStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";
import type { WidgetDataJobResponseBucketRow } from "@ender/shared/generated/ender.service.reports.response";
import type { LabelValue } from "@ender/shared/types/label-value";
import { convertToNumber } from "@ender/shared/utils/string";

import type {
  FactorValue,
  Widget,
} from "./underwriting-queue-table/filter-fields/filter-types";
import { Factor } from "./underwriting-queue-table/filter-fields/filter-types";
import { UserSelector } from "./underwriting-queue-table/user-selector";

type Metadata = {
  assignUnderwriter: (dealId: EnderId, userId: string | EnderId) => void;
  userOptions: LabelValue<EnderId>[];
  dealId: EnderId;
  history: unknown;
  row: WidgetDataJobResponseBucketRow;
};

const hasNoData = (value?: string) =>
  P.isNullable(value) || value === "NO_DATA";

function DateCell({ value }: { value: string }) {
  if (hasNoData(value)) {
    return "";
  }

  return LocalDate$.toFormatted(
    LocalDate$.of(value),
    LocalDate$.Formats.DEFAULT,
  );
}

function ListPriceCell({ value }: { value: string }) {
  if (hasNoData(value)) {
    return "";
  }

  const period = value.indexOf(".");
  if (period === -1) {
    return value;
  }

  return `${value}`.slice(0, period);
}

type StreetCellProps = {
  dealId: EnderId;
  metadata: Metadata;
  value: string;
  widget: Widget;
};

function StreetCell({ metadata, value, widget }: StreetCellProps) {
  if (hasNoData(value)) {
    return "";
  }

  const statusIndex = widget.yFactors.findIndex(
    ({ name }) => name === Factor.STATUS,
  );
  const status = metadata.row.values?.[statusIndex]?.value?.pipelineStatus?.id;
  const duplicateAddressIndex = widget.yFactors.findIndex(
    ({ name }) => name === Factor.DUP_ADDRESS,
  );
  const isDuplicate = /true/i.test(
    metadata.row.values?.[duplicateAddressIndex]?.value,
  ); // BE passing "true" or "false" strings

  return (
    <Link to={`/buy/properties/${metadata.dealId}`}>
      <div>{value}</div>
      <Group spacing={Spacing.sm}>
        {(status === DealStatusPipelineStatusEnum.OFFER ||
          status === DealStatusPipelineStatusEnum.LEAD) && (
          <Badge color={BadgeColor.slate}>{status}</Badge>
        )}
        {isDuplicate && (
          <Tooltip label="Home imported with the same address in the previous 90 days">
            <Badge color={BadgeColor.red}>Duplicate</Badge>
          </Tooltip>
        )}
      </Group>
    </Link>
  );
}

function formatCellData(
  value: string,
  factorName: FactorValue,
  widget: Widget,
  metadata: Metadata,
) {
  switch (factorName) {
    case Factor.UNDERWRITER:
      return (
        <UserSelector
          assignUnderwriter={metadata.assignUnderwriter}
          // @ts-expect-error I don't want to but I couldn't figured it out what should I type value as, it could be just a string but it also has properties id & name
          selectedUserId={value?.id}
          users={metadata.userOptions}
          dealId={metadata.dealId}
        />
      );
    case Factor.STREET:
      return (
        <StreetCell
          dealId={metadata.dealId}
          metadata={metadata}
          value={value}
          widget={widget}
        />
      );
    case Factor.LIST_PRICE:
      return <ListPriceCell value={value} />;
    case Factor.LIST_DATE:
    case Factor.UPLOAD_DATE:
      return <DateCell value={value} />;
    case Factor.UNDERWRITING_SCORE:
      return `${value}`?.substring(0, 5);
    case Factor.MARKET:
      // @ts-expect-error I don't want to but I couldn't figured it out what should I type value as, it could be just a string but it also has properties id & name
      return value?.name;
    case Factor.SQFT:
      return convertToNumber(value).toLocaleString();
  }

  return value;
}

const GenericSubStatusEnum = {
  NEED_TO_UNDERWRITE: "Need to Underwrite",
  WAITING_ON_RESPONSE: "Waiting on Response",
} as const;

export { formatCellData, GenericSubStatusEnum };
