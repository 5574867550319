import { IconChevronDown } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Array as A, Option as O, Order as Od, flow, pipe } from "effect";
import { useContext, useEffect, useMemo, useState } from "react";

import type {
  OptimizedLedgerEvent,
  OptimizedLedgerEventAllocation,
} from "@ender/shared/contexts/ledger";
import { LedgerActions, LedgerContext } from "@ender/shared/contexts/ledger";
import type { EnderId, Money } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import type { TenantLedgerReportLedgerEntryTenantLedgerEventType } from "@ender/shared/generated/ender.arch.accounting";
import type {
  CategoryFlag,
  GLCategory,
} from "@ender/shared/generated/ender.model.accounting";
import type { TenantLedgerEventType } from "@ender/shared/types/ender-general";
import { TenantLedgerEventTypeEnum } from "@ender/shared/types/ender-general";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";

import styles from "./category-history.module.css";

type CategoryAllocationType = OptimizedLedgerEventAllocation & {
  isHighlighted: boolean;
  tenantLedgerEventType: TenantLedgerReportLedgerEntryTenantLedgerEventType;
  ledgerEventId: EnderId;
  ledgerEvent: OptimizedLedgerEvent;
};

type CategoryAllocationProps = {
  allocation: CategoryAllocationType;
  openAllocation: (ledgerEvent: OptimizedLedgerEvent) => void;
};

const chargeColumnTypes: Set<TenantLedgerEventType> = new Set([
  TenantLedgerEventTypeEnum.CHARGE,
  TenantLedgerEventTypeEnum.CREDIT,
]);
const negatedPaymentTypes: Set<TenantLedgerEventType> = new Set([
  TenantLedgerEventTypeEnum.REFUND,
  TenantLedgerEventTypeEnum.REVERSAL,
  TenantLedgerEventTypeEnum.CREDIT,
]);

type TenantLedgerCategory = GLCategory & {
  endingBalance: Money;
  flags: CategoryFlag[];
  totalCharges: Money;
  totalPayments: Money;
};

function CategoryAllocation({
  allocation,
  openAllocation,
}: CategoryAllocationProps) {
  const [isCharge, isPayment] = useMemo(() => {
    const _isCharge = chargeColumnTypes.has(allocation.tenantLedgerEventType);
    return [_isCharge, !_isCharge];
  }, [allocation.tenantLedgerEventType]);

  const amount = useMemo(() => {
    return isCharge
      ? allocation.amount
      : pipe(
          allocation.amount,
          O.map(
            flow(
              Money$.abs,
              Money$.negateWhen(() =>
                negatedPaymentTypes.has(allocation.tenantLedgerEventType),
              ),
            ),
          ),
        );
  }, [isCharge, allocation.amount, allocation.tenantLedgerEventType]);

  const allocationClassName = useMemo(() => {
    return clsx({
      "grid-table__row": true,
      highlight: allocation.isHighlighted,
    });
  }, [allocation.isHighlighted]);

  return (
    <div className={allocationClassName}>
      <div className="numeric">
        {EnderDate.of(allocation.transactionDate).toLongSlashDateString()}
      </div>
      <div>
        <EnderLink onClick={() => openAllocation(allocation.ledgerEvent)}>
          {allocation.description}
        </EnderLink>
      </div>
      <div className="right">{isCharge && <MoneyDisplay value={amount} />}</div>
      <div className="right">
        {isPayment && <MoneyDisplay value={amount} />}
      </div>
      <div className="right">
        <MoneyDisplay value={allocation.runningBalanceForCategory} />
      </div>
    </div>
  );
}

function CategoryHistory({ isAlwaysExpanded = false }) {
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const {
    ledgerEvents,
    selectedLedgerEvent,
    selectedLedgerCategory,
    categories,
    prepaymentCategory,
    dispatch,
  } = useContext(LedgerContext);

  const category = useMemo<Partial<TenantLedgerCategory> | undefined>(() => {
    if (selectedLedgerCategory) {
      return categories.find(({ id }) => id === selectedLedgerCategory);
    }

    const nonPrepaymentCategory = selectedLedgerEvent?.allocations.find(
      (allocation) => {
        return allocation.glCategoryId !== prepaymentCategory?.id;
      },
    );
    if (!nonPrepaymentCategory) {
      fail(
        "It appears that the selected charge may have been mis-allocated. Please contact Ender OPS to settle the issue.",
      );
      return {};
    }

    const { glCategoryId } = nonPrepaymentCategory;
    return categories.find(({ id }) => id === glCategoryId);
  }, [
    selectedLedgerEvent,
    selectedLedgerCategory,
    categories,
    prepaymentCategory?.id,
  ]);

  const categoryName = useMemo(() => {
    return category ? category.accountName : "";
  }, [category]);

  // lazy useEffect
  useEffect(() => {
    setIsHistoryVisible(false);
  }, [selectedLedgerEvent]);

  const categoryAllocations = useMemo(() => {
    const _categoryAllocations: CategoryAllocationType[] = [];

    ledgerEvents.forEach((ledgerEvent) => {
      const { id, allocations, tenantLedgerEventType } = ledgerEvent;
      allocations.forEach((allocation) => {
        if (allocation.glCategoryId !== category?.id) {
          return;
        }

        _categoryAllocations.push({
          ...allocation,
          isHighlighted: id === selectedLedgerEvent?.id,
          tenantLedgerEventType,
          ledgerEventId: id,
          ledgerEvent,
        });
      });
    });

    return A.sortBy(
      Od.mapInput(
        Od.string,
        (a: CategoryAllocationType) => a.transactionDate ?? "",
      ),
      Od.mapInput(
        Money$.Order,
        (a: CategoryAllocationType) => a.effectOnCategoryBalance,
      ),
      Od.mapInput(Od.string, (a: CategoryAllocationType) => a.id),
    )(_categoryAllocations);
  }, [category, ledgerEvents, selectedLedgerEvent?.id]);

  function toggleHistoryVisibility() {
    return setIsHistoryVisible(!isHistoryVisible);
  }

  function openAllocation(ledgerEvent: OptimizedLedgerEvent) {
    dispatch({
      type: LedgerActions.SET_SELECTED_LEDGER_EVENT,
      payload: ledgerEvent,
    });
  }

  return (
    <div className={styles.categoryHistoryParent}>
      {!isAlwaysExpanded && (
        <div
          className={styles.categoryHistoryHeading}
          onClick={toggleHistoryVisibility}>
          <IconChevronDown
            size={22}
            className={isHistoryVisible ? `${styles.dropDownOpen}` : ""}
          />
          {categoryName} History
        </div>
      )}
      {(isHistoryVisible || isAlwaysExpanded) && (
        <div>
          <div className={styles.categoryHistoryHeader}>
            <div className={styles.categoryHistoryHeaderRow}>
              <div>Total {categoryName} Charges</div>
              <div>
                <MoneyDisplay value={Money$.parse(category?.totalCharges)} />
              </div>
            </div>
            <div className={styles.categoryHistoryHeaderRow}>
              <div>Total {categoryName} Paid</div>
              <div>
                <MoneyDisplay value={Money$.parse(category?.totalPayments)} />
              </div>
            </div>
            <div className={`bold ${styles.categoryHistoryHeaderRow}`}>
              <div>{categoryName} Balance</div>
              <div>
                <MoneyDisplay value={Money$.parse(category?.endingBalance)} />
              </div>
            </div>
          </div>
          <div className="grid-table grid-table--flat grid-table--xsmall">
            <div className="grid-table__header">
              <div className={styles.gridTableRow}>
                <div className="left">Date</div>
                <div className="left">Name</div>
                <div className="right">Charges</div>
                <div className="right">Payments</div>
                <div className="right">
                  {category?.accountName ?? ""} Balance
                </div>
              </div>
            </div>
            <div className={styles.gridTableBody}>
              {categoryAllocations.map((allocation) => (
                <CategoryAllocation
                  key={allocation.id}
                  allocation={allocation}
                  openAllocation={openAllocation}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { CategoryHistory };
