/**
 * HTTP Method: GET
 * Pathname: /getNextInvoiceNumber
 * @function getNextInvoiceNumber
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetNextInvoiceNumberPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetNextInvoiceNumberResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetNextInvoiceNumberResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetNextInvoiceNumberSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetNextInvoiceNumberPayload =
  InvoicesAPIGetNextInvoiceNumberSearchParams;

function getNextInvoiceNumberUnsafeEffect(
  payload: InvoicesAPIGetNextInvoiceNumberPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetNextInvoiceNumberResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetNextInvoiceNumberResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/getNextInvoiceNumber",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getNextInvoiceNumber(
  payload: InvoicesAPIGetNextInvoiceNumberPayload,
  options?: { signal?: AbortSignal },
): Promise<GetNextInvoiceNumberResponse> {
  return F.pipe(
    payload,
    getNextInvoiceNumberUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getNextInvoiceNumber };
export type {
  InvoicesAPIGetNextInvoiceNumberPayload,
  InvoicesAPIGetNextInvoiceNumberSearchParams,
};
