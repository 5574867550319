import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetRenewalsRequestRenewalTableTabValues = [
  "NEEDS_OFFERS",
  "SEND_OFFERS",
  "AWAITING_RESPONSE",
  "ACCEPTED",
  "NEGOTIATING",
  "NEW_OFFER_REQUESTED",
  "DRAFTED_LEASES",
  "NO_RENEW",
  "NEEDS_MOVE_OUT",
] as const;

const GetRenewalsRequestRenewalTableTabEffectSchema = Schema.Literal(
  ...GetRenewalsRequestRenewalTableTabValues,
);

type GetRenewalsRequestRenewalTableTab = Schema.Schema.Type<
  typeof GetRenewalsRequestRenewalTableTabEffectSchema
>;

const GetRenewalsRequestRenewalTableTabEnum = castEnum(
  GetRenewalsRequestRenewalTableTabEffectSchema,
);

function randomGetRenewalsRequestRenewalTableTab(): GetRenewalsRequestRenewalTableTab {
  return rand(GetRenewalsRequestRenewalTableTabValues);
}

export {
  GetRenewalsRequestRenewalTableTabEffectSchema,
  GetRenewalsRequestRenewalTableTabEnum,
  GetRenewalsRequestRenewalTableTabValues,
  randomGetRenewalsRequestRenewalTableTab,
};
export type { GetRenewalsRequestRenewalTableTab };
