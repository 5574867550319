import { IconEdit } from "@tabler/icons-react";
import { Function as F, Option as O } from "effect";
import { useId, useMemo, useState } from "react";

import { LocalDate$, Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import type { TextColors } from "@ender/shared/ds/text";
import { Text, TextColor } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { CreateUpdateInsurancePolicyModal } from "@ender/shared/ui/modals";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import {
  capitalize,
  convertSnakeCaseToTitleCase,
} from "@ender/shared/utils/string";

import type { UpdatedInsurancePolicy } from "./insurance.types";
import type { InsurancePolicyStatus } from "./transform-insurance-policies";
import {
  InsurancePolicyStatusEnum,
  getInsurancePolicyStatus,
} from "./transform-insurance-policies";

const PolicyStatusColorMap: Partial<Record<InsurancePolicyStatus, TextColors>> =
  {
    [InsurancePolicyStatusEnum.ACTIVE]: TextColor["green-600"],
    [InsurancePolicyStatusEnum.EXPIRED]: TextColor["red-500"],
  } as const;

const PolicyStatusDisplayString: Record<InsurancePolicyStatus, string> = {
  [InsurancePolicyStatusEnum.EXPIRED]: "Expired",
  [InsurancePolicyStatusEnum.ACTIVE]: "Up to date",
  [InsurancePolicyStatusEnum.UPCOMING]: "Upcoming",
};

type InsurancePolicyCardProps = {
  policy: UpdatedInsurancePolicy;
  refetchInsurancePolicies?: () => void;
};

function InsurancePolicyCard({
  policy,
  refetchInsurancePolicies = F.constVoid,
}: InsurancePolicyCardProps) {
  const [isUpdatePolicyModalOpen, setIsUpdatePolicyModalOpen] = useState(false);
  const policyStatus = useMemo(() => {
    return getInsurancePolicyStatus(policy);
  }, [policy]);

  function closeUpdatePolicyModal() {
    setIsUpdatePolicyModalOpen(false);
  }

  function onPolicyCreated() {
    showSuccessNotification({ message: "Insurance Policy Updated" });
    closeUpdatePolicyModal();
    refetchInsurancePolicies();
  }

  const policyDisplayStatus = PolicyStatusDisplayString[policyStatus];
  const policyStatusColor = PolicyStatusColorMap[policyStatus];

  const monthlyPremium = Money$.of(policy.monthlyPremium);

  const cardId = useId();
  return (
    <>
      <Card labelledBy={cardId}>
        <Stack spacing={Spacing.md}>
          <Group justify={Justify.between}>
            <H3 id={cardId}>
              {convertSnakeCaseToTitleCase(policy.type)} Insurance -{" "}
              {policy.carrier}
            </H3>
            <ActionIcon
              variant={ButtonVariant.transparent}
              size={ButtonSize.sm}
              onClick={() => setIsUpdatePolicyModalOpen(true)}
              label="Edit Policy">
              <IconEdit size={22} />
            </ActionIcon>
          </Group>
          <Grid spacingY={Spacing.none}>
            <Tuple
              label="Length"
              value={
                policy.effectiveDate && policy.expirationDate ? (
                  <>
                    <DateDisplay value={policy.effectiveDate} /> -{" "}
                    <DateDisplay value={policy.expirationDate} />
                  </>
                ) : (
                  "--"
                )
              }
            />
            <Tuple
              label="Coverage"
              value={
                policy.coverageAmount ? (
                  <MoneyDisplay
                    showSymbol
                    value={Money$.parse(policy.coverageAmount)}
                  />
                ) : (
                  "--"
                )
              }
            />
            <Tuple
              label="Deductible"
              value={
                policy.deductible ? (
                  <MoneyDisplay
                    value={Money$.parse(policy.deductible)}
                    showSymbol
                  />
                ) : (
                  "--"
                )
              }
            />
            <Tuple label="Policy #" value={policy.policyNumber} />
            <Tuple
              label="Monthly Premium"
              value={
                policy.monthlyPremium ? (
                  <MoneyDisplay value={monthlyPremium} showSymbol />
                ) : (
                  "--"
                )
              }
            />
            <Tuple
              label="Status"
              value={
                <Text color={policyStatusColor}>{policyDisplayStatus}</Text>
              }
            />
          </Grid>
        </Stack>
      </Card>
      <Modal
        title={`Edit ${capitalize(policy.insuredPartyType)} Insurance Policy`}
        opened={isUpdatePolicyModalOpen}
        onClose={closeUpdatePolicyModal}>
        <CreateUpdateInsurancePolicyModal
          policy={{
            ...policy,
            effectiveDate: LocalDate$.toFormatted(
              O.getOrThrow(policy.effectiveDate),
              LocalDate$.Formats.DEFAULT,
            ),
            expirationDate: LocalDate$.toFormatted(
              O.getOrThrow(policy.expirationDate),
              LocalDate$.Formats.DEFAULT,
            ),
          }}
          onSuccess={onPolicyCreated}
        />
      </Modal>
    </>
  );
}

export { InsurancePolicyCard };
