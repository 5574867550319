/**
 * Get all required flags and the corresponding assigned GL Accounts.
 * HTTP Method: GET
 * Pathname: /accounting/categories/requiredFlags
 * @function getRequiredFlagsCategories
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetRequiredFlagsCategoriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Partial<Record<CategoryFlag, GLCategory[]>>>}
 */
import { Effect, Function as F } from "effect";

import type {
  CategoryFlag,
  GLCategory,
} from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetRequiredFlagsCategoriesSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetRequiredFlagsCategoriesPayload =
  GeneralLedgerAPIGetRequiredFlagsCategoriesSearchParams;

function getRequiredFlagsCategoriesUnsafeEffect(
  payload: GeneralLedgerAPIGetRequiredFlagsCategoriesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Partial<Record<CategoryFlag, GLCategory[]>>>({
        body,
        decode: unsafeDecodeJsonResponse<
          Partial<Record<CategoryFlag, GLCategory[]>>
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/accounting/categories/requiredFlags",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRequiredFlagsCategories(
  payload: GeneralLedgerAPIGetRequiredFlagsCategoriesPayload,
  options?: { signal?: AbortSignal },
): Promise<Partial<Record<CategoryFlag, GLCategory[]>>> {
  return F.pipe(
    payload,
    getRequiredFlagsCategoriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRequiredFlagsCategories };
export type {
  GeneralLedgerAPIGetRequiredFlagsCategoriesPayload,
  GeneralLedgerAPIGetRequiredFlagsCategoriesSearchParams,
};
