import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import type { RepairCalculation, Underwriting } from "@ender/shared/api/buy";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId, Money } from "@ender/shared/core";
import {
  BuyAPI,
  UnderwritingAPI,
} from "@ender/shared/generated/com.ender.buy.api";
import type { GetDealResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import { PhotosAPI } from "@ender/shared/generated/ender.api.misc";
import { PropertyHOAFeeFrequencyEnum } from "@ender/shared/generated/ender.model.core.property";
import type { Photo } from "@ender/shared/generated/ender.model.files";
import { useDocumentTitle } from "@ender/shared/hooks/use-document-title";
import { useSetHeader } from "@ender/shared/hooks/use-set-header";
import { fail } from "@ender/shared/utils/error";
import { convertToNumber } from "@ender/shared/utils/string";

import { QUERY_KEY } from "../underwriting-page-helpers";
import { getAssignedPropertyIds } from "../underwriting-queue-helpers";
import {
  DEFAULT_CONTEXT,
  DEFAULT_REPAIR_OPTIONS,
  DEFAULT_UNDERWRITING,
} from "./underwriting-page-context-defaults";
import type {
  UnderwritingPageContextProps,
  UnderwritingRail,
} from "./underwriting-page-context-types";
import { useCanAddOffer } from "./use-can-add-offer";

const UnderwritingPageContext =
  createContext<UnderwritingPageContextProps>(DEFAULT_CONTEXT);

function UnderwritingPageProvider({
  children,
  dealId,
}: PropsWithChildren<{ dealId: EnderId }>) {
  const [underwritingPreview, setUnderwritingPreview] = useState<Underwriting>({
    ...DEFAULT_UNDERWRITING,
  });
  const [repairs, setRepairs] = useState(DEFAULT_REPAIR_OPTIONS);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [propertyImages, setPropertyImages] = useState<Photo[]>([]);
  const [openedRightRail, setOpenedRightRail] = useState<UnderwritingRail>();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const updateUnderwritingPreviewDebounce =
    useRef<ReturnType<typeof setTimeout>>();

  const updateUnderwritingPreview = useCallback(
    async (updates: Partial<Underwriting>) => {
      if (updateUnderwritingPreviewDebounce.current) {
        clearTimeout(updateUnderwritingPreviewDebounce.current);
      }

      let updateRequest: Partial<Underwriting> = {};
      setUnderwritingPreview((underwriting) => {
        const updated = {
          ...underwriting,
          ...updates,
        };
        updateRequest = updated;
        return updated;
      });
      setUnsavedChanges(true);

      updateUnderwritingPreviewDebounce.current = setTimeout(async () => {
        try {
          // @ts-expect-error needs to correctly type the passed in API payload
          const response = await UnderwritingAPI.computeUnderwritingPreview({
            dealId,
            ...updateRequest,
          });
          // @ts-expect-error param type mismatching
          setUnderwritingPreview(response);
        } catch (error) {
          fail(error);
        }
      }, 50);
    },
    [dealId],
  );

  const {
    data: deal,
    isFetched,
    refetch: refetchDeal,
  } = useQuery<GetDealResponse>({
    queryFn: async ({ signal }) => {
      const deal: GetDealResponse = await BuyAPI.getDeal(
        { dealId },
        { signal },
      );
      const hoaInfo: Partial<Underwriting> = {};
      if (deal.hoaFeeFrequency && deal.hoaFees) {
        switch (deal.hoaFeeFrequency) {
          case PropertyHOAFeeFrequencyEnum.YEARLY:
            hoaInfo.hoaYearlyFees = deal.hoaFees;
            break;
          case PropertyHOAFeeFrequencyEnum.BI_YEARLY:
            hoaInfo.hoaYearlyFees =
              `${convertToNumber(deal.hoaFees) * 2}` as Money;
            break;
          case PropertyHOAFeeFrequencyEnum.QUARTERLY:
            hoaInfo.hoaYearlyFees =
              `${convertToNumber(deal.hoaFees) * 4}` as Money;
            break;
          case PropertyHOAFeeFrequencyEnum.MONTHLY:
            hoaInfo.hoaYearlyFees =
              `${convertToNumber(deal.hoaFees) * 12}` as Money;
            break;
        }
        hoaInfo.hoaFeeFrequency = PropertyHOAFeeFrequencyEnum.YEARLY;
        hoaInfo.hoaFees = hoaInfo.hoaYearlyFees;
      }

      const underWritings = await UnderwritingAPI.getUnderwriting(
        {
          dealId: deal.id,
        },
        { signal },
      );
      await updateUnderwritingPreview({
        ...DEFAULT_UNDERWRITING,
        ...hoaInfo,
        ...underWritings?.[underWritings.length - 1],
      });

      setUnsavedChanges(false);

      return deal;
    },
    queryKey: ["BuyAPI.getDeal", dealId] as const,
    refetchOnWindowFocus: false,
  });

  const {
    data: propertiesAssignedToMe,
    refetch: refetchPropertiesAssignedToMe,
  } = useQuery({
    queryFn: () => getAssignedPropertyIds(user.id),
    queryKey: [QUERY_KEY.ASSIGNED_PROPERTY_IDS, user.id] as const,
    staleTime: Number.MAX_SAFE_INTEGER,
  });

  const { canAddOffer, errorMessage } = useCanAddOffer(dealId, deal);

  const headerConfig = useMemo(() => {
    const title =
      deal?.address.fullDisplay &&
      `${deal.address.fullDisplay} (${deal.market.name})`;

    return {
      breadcrumbs: [
        { title: "Buy Properties", href: "/buy" },
        { title: "Global Underwriting Queue", href: "/buy/underwriting/queue" },
        { title: "My Underwriting Queue", href: "/buy/underwriting/assigned" },
        { title: title || "Loading" },
      ],
    };
  }, [deal]);

  useSetHeader(headerConfig);
  useDocumentTitle(
    P.isNotNullable(deal)
      ? `Underwriting - ${deal.address.street} - Ender`
      : "Underwriting - Ender",
  );

  // lazy useEffect
  useEffect(() => {
    async function loadPhotos() {
      try {
        const data = await BuyAPI.getPropertyPhotos({ dealId });
        const images = data.albums.flatMap((album) => album.photos);
        setPropertyImages(images);
      } catch (error) {
        fail(error);
      }
    }

    loadPhotos();
  }, [dealId]);

  function nextImage() {
    setImageIndex((i) => ++i % propertyImages.length);
  }

  function previousImage() {
    setImageIndex(
      (i) => (i - 1 + propertyImages.length) % propertyImages.length,
    );
  }

  const updateRepairs = useCallback((updates: Partial<RepairCalculation>) => {
    setRepairs((currentRepairs) => ({
      ...currentRepairs,
      ...updates,
    }));
  }, []);

  function gotoNextUnderwriting() {
    if (!propertiesAssignedToMe) {
      return;
    }

    const currentIndex = propertiesAssignedToMe.indexOf(dealId);

    if (
      currentIndex === -1 ||
      A.isEmptyArray(propertiesAssignedToMe?.slice(1))
    ) {
      history.push("/buy/underwriting/queue");
      return;
    }

    let nextIndex = currentIndex + 1;
    if (nextIndex >= propertiesAssignedToMe.length) {
      nextIndex = 0;
    }

    refetchPropertiesAssignedToMe();
    history.push(
      `/buy/underwriting/queue/${propertiesAssignedToMe[nextIndex]}`,
    );
  }

  async function saveImageComment(comment: string) {
    const currentIndex = imageIndex;
    const currentImage = propertyImages?.[currentIndex];
    if (comment === (currentImage?.comment || "")) {
      return;
    }

    try {
      await PhotosAPI.updatePhoto({ photoId: currentImage.id, comment });
      setPropertyImages((c) => {
        const updates = [...c];
        updates[currentIndex].comment = comment;
        return updates;
      });
    } catch (error) {
      fail(error);
    }
  }

  return (
    <UnderwritingPageContext.Provider
      // @ts-expect-error missing prop readOnly, unsure what set it as default
      value={{
        canAddOffer,
        currentImage: propertyImages?.[imageIndex],
        dataLoading: !isFetched,
        deal,
        dealId,
        errorMessage,
        gotoNextUnderwriting,
        imageIndex,
        nextImage,
        openedRightRail,
        previousImage,
        propertiesAssignedToMe,
        propertyImages,
        refetchDeal,
        repairs,
        saveImageComment,
        setOpenedRightRail,
        underwritingPreview,
        unsavedChanges,
        updateRepairs,
        updateUnderwritingPreview,
      }}>
      {children}
    </UnderwritingPageContext.Provider>
  );
}

export { UnderwritingPageContext, UnderwritingPageProvider };
