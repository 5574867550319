import { IconEdit, IconPlus, IconTrash, IconUpload } from "@tabler/icons-react";
import { Function as F, Option as O, Predicate as P } from "effect";
import * as A from "effect/Array";
import type { ElementRef, PropsWithChildren, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useId } from "react";

import { ApplicantFile } from "@ender/entities/application/applicant-file";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Inset } from "@ender/shared/ds/inset";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import type {
  GetApplicationGroupResponseApplicationResponse,
  GetApplicationGroupResponseOtherSourcesOfIncomeResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { noOpAsync } from "@ender/shared/utils/no-op";

import { DisplayableApplicantEmployment } from "./employment-fields/display-applicant-employment-fields";
import type { ApplicantEmploymentFormOutput } from "./employment-fields/edit-applicant-employment-fields";
import {
  ApplicantEmploymentFormSchema,
  EditableApplicantEmployment,
} from "./employment-fields/edit-applicant-employment-fields";

type ApplicationApplicantEmploymentCardProps = {
  application: Pick<
    GetApplicationGroupResponseApplicationResponse,
    | "employmentStatus"
    | "employer"
    | "annualSalary"
    | "managerName"
    | "managerPhone"
    | "housingChoiceVoucherTimestamp"
    | "housingChoiceVoucherCaseWorkerName"
    | "housingChoiceVoucherCaseWorkerEmail"
    | "validatedThreeMonthIncome"
    | "paystubs"
    | "applicationId"
    | "otherSourcesOfIncome"
  >;
  onSubmit?: (data: ApplicantEmploymentFormOutput) => Promise<void>;
  isEditable?: boolean;
  header?: ReactNode;
  isWorking?: boolean;

  canDeleteDocuments?: boolean;
  onUploadClick?: () => void;
  onDeletePaystub?: (file: FilesClientEnderFile) => void;
  onEditFileClick?: (file: FilesClientEnderFile) => void;
  onAddIncomeClick?: () => void;
  onDeleteIncomeClick?: (
    income: GetApplicationGroupResponseOtherSourcesOfIncomeResponse,
  ) => void;
};

const ApplicationApplicantEmploymentCard = forwardRef<
  ElementRef<typeof Card>,
  PropsWithChildren<ApplicationApplicantEmploymentCardProps>
>(function ApplicationApplicantEmploymentCard(props, ref) {
  const {
    application,
    onSubmit = noOpAsync,
    isEditable,
    header,
    isWorking,
    canDeleteDocuments = false,
    onUploadClick = F.constVoid,
    onDeletePaystub = F.constVoid,
    onEditFileClick = F.constVoid,
    onAddIncomeClick = F.constVoid,
    onDeleteIncomeClick = F.constVoid,
    children,
  } = props;
  const {
    annualSalary,
    housingChoiceVoucherTimestamp,
    paystubs,
    otherSourcesOfIncome,
  } = application;

  const [editing, editingHandlers] = useBoolean(false);
  const form = useEffectSchemaForm({
    defaultValues: {
      ...application,
      annualSalary: Money$.parse(annualSalary),
      employmentStatus: O.fromNullable(application.employmentStatus),
      housingChoiceVoucher: P.isNotNullable(housingChoiceVoucherTimestamp),
      housingChoiceVoucherCaseWorkerName:
        application.housingChoiceVoucherCaseWorkerName ?? "",
      housingChoiceVoucherCaseWorkerEmail:
        application.housingChoiceVoucherCaseWorkerEmail ?? "",
    },
    schema: ApplicantEmploymentFormSchema,
  });
  const formRef = useRefLatest(form);

  /**
   * Synchronize the form values with the incoming props
   */
  useEffect(() => {
    const newValues = {
      ...application,
      annualSalary: Money$.parse(application.annualSalary),
      employmentStatus: O.fromNullable(application.employmentStatus),
      housingChoiceVoucher: P.isNotNullable(
        application.housingChoiceVoucherTimestamp,
      ),
      housingChoiceVoucherCaseWorkerName:
        application.housingChoiceVoucherCaseWorkerName ?? "",
      housingChoiceVoucherCaseWorkerEmail:
        application.housingChoiceVoucherCaseWorkerEmail ?? "",
    };
    formRef.current.reset(newValues);
  }, [formRef, application]);

  const handleSubmit = useCallback(
    async (values: ApplicantEmploymentFormOutput) => {
      await onSubmit(values);
      editingHandlers.setFalse();
    },
    [onSubmit, editingHandlers],
  );

  const { reset: resetForm } = formRef.current;
  const handleCancel = useCallback(() => {
    editingHandlers.setFalse();
    resetForm();
  }, [resetForm, editingHandlers]);

  const headingId = useId();
  return (
    <Card ref={ref}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Group justify={Justify.between} align={Align.center}>
            <span id={headingId}>{header}</span>
            {isEditable && (
              <Inset
                r={editing ? Spacing.none : Spacing.sm}
                t={Spacing.sm}
                b={Spacing.sm}>
                {editing ? (
                  <Group>
                    <Button
                      variant={ButtonVariant.transparent}
                      onClick={handleCancel}
                      disabled={isWorking}>
                      Cancel
                    </Button>
                    <Button type="submit" loading={isWorking}>
                      Save
                    </Button>
                  </Group>
                ) : (
                  <ActionIcon
                    variant={ButtonVariant.transparent}
                    onClick={editingHandlers.setTrue}>
                    <IconEdit />
                  </ActionIcon>
                )}
              </Inset>
            )}
          </Group>
          <Grid spacingY={Spacing.none} underline="all">
            {editing ? (
              <EditableApplicantEmployment form={form} />
            ) : (
              <DisplayableApplicantEmployment form={form} />
            )}
          </Grid>
          <Group justify={Justify.between}>
            <span>Additional Income</span>
            <Inset t={Spacing.sm} b={Spacing.sm}>
              <Button
                leftSection={<IconPlus />}
                variant={ButtonVariant.transparent}
                onClick={onAddIncomeClick}>
                Add Additional Income Source
              </Button>
            </Inset>
          </Group>
          {A.isEmptyArray(otherSourcesOfIncome) ? (
            <Text>No additional income</Text>
          ) : (
            <Grid underline="none" spacingY={Spacing.none}>
              {otherSourcesOfIncome.map((val, index) => (
                <>
                  <div style={{ gridColumn: "1" }}>
                    <Tuple
                      label={`Additional Income Source ${index + 1}`}
                      value={
                        <Group spacing={Spacing.sm} noWrap align={Align.center}>
                          <span style={{ textWrap: "nowrap" }}>
                            {val.description}
                          </span>
                          <ActionIcon
                            onClick={() => onDeleteIncomeClick(val)}
                            variant={ButtonVariant.transparent}>
                            <IconTrash />
                          </ActionIcon>
                        </Group>
                      }
                    />
                  </div>
                  <Tuple
                    label={`Additional Earnings from Income ${index + 1}`}
                    value={Money$.parse(val.yearlyEstimate).pipe(
                      O.map((val) => val.toFormatted()),
                      O.getOrElse(() => ""),
                    )}
                  />
                </>
              ))}
            </Grid>
          )}
          <Group justify={Justify.between}>
            <span>Proof of Income</span>
            <Inset t={Spacing.sm} b={Spacing.sm}>
              <Button
                leftSection={<IconUpload />}
                variant={ButtonVariant.transparent}
                onClick={onUploadClick}>
                Upload Proof of Income
              </Button>
            </Inset>
          </Group>
          {A.isEmptyArray(paystubs) ? (
            <Text>No paystubs</Text>
          ) : (
            <Grid underline="none">
              {paystubs.map((file) => (
                <ApplicantFile
                  file={file}
                  key={file.id}
                  canDelete={canDeleteDocuments}
                  canEdit={canDeleteDocuments}
                  onDelete={onDeletePaystub}
                  onEdit={onEditFileClick}
                />
              ))}
            </Grid>
          )}
          {children}
        </Stack>
      </Form>
    </Card>
  );
});

export { ApplicationApplicantEmploymentCard };

export type { ApplicationApplicantEmploymentCardProps };
