import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { isMultiple } from "@ender/shared/utils/is";

type GetIsMultiPropertyInvoiceParams = Pick<
  InvoiceSerializerInvoiceResponse,
  "currentState"
>;

function getIsMultiPropertyInvoice(
  invoice: GetIsMultiPropertyInvoiceParams,
): boolean {
  return isMultiple(invoice.currentState.allocationsByProperty);
}

export { getIsMultiPropertyInvoice };
