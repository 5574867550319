/**
 * For now this gets the cash-basis version of the journal entry.
 * HTTP Method: GET
 * Pathname: /accounting/journal-entries/{journalEntryId}
 * @function getJournalEntry
 * @memberof AccountingAPI
 * @param {AccountingAPIGetJournalEntryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingAPIGetJournalEntryResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AccountingAPIGetJournalEntryResponse } from "../accounting-api-get-journal-entry-response";

type AccountingAPIGetJournalEntryPathParams = {
  journalEntryId: EnderId;
};

type AccountingAPIGetJournalEntrySearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetJournalEntryPayload =
  AccountingAPIGetJournalEntryPathParams &
    AccountingAPIGetJournalEntrySearchParams;

function getJournalEntryUnsafeEffect(
  payload: AccountingAPIGetJournalEntryPayload,
) {
  const { journalEntryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingAPIGetJournalEntryResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AccountingAPIGetJournalEntryResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/accounting/journal-entries/${journalEntryId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getJournalEntry(
  payload: AccountingAPIGetJournalEntryPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingAPIGetJournalEntryResponse> {
  return F.pipe(
    payload,
    getJournalEntryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getJournalEntry };
export type {
  AccountingAPIGetJournalEntryPathParams,
  AccountingAPIGetJournalEntryPayload,
  AccountingAPIGetJournalEntrySearchParams,
};
