// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { ModalProps } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Modal } from "@mantine/core";
import { Suspense } from "react";

import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";

/**
 * @name EnderModal
 * @description in-DOM Mantine modal with Suspense support for lazy loading
 */

type EnderModalProps = ModalProps;

/**
 * danger: if mounted while `opened=true` it may not show up. This is probably a stupid bug with Mantine
 *
 * @deprecated use Modal from @ender/shared/ds/modal
 */
function EnderModal(props: EnderModalProps) {
  const {
    title,
    classNames = {},
    closeOnClickOutside = false,
    children,
    ...modalProps
  } = props;
  classNames.root = `${classNames.root ?? ""} ender-Modal-root ${!title ? "hideTitle" : ""}`;

  return (
    <Modal
      {...modalProps}
      title={title}
      overlayColor="var(--color-modal-overlay)"
      overlayOpacity={1}
      closeOnClickOutside={closeOnClickOutside}
      classNames={classNames}
      closeButtonLabel="Exit">
      <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
    </Modal>
  );
}

export { EnderModal };
