import { Schema } from "@effect/schema";
import { IconChevronDown } from "@tabler/icons-react";
import { cva, cx } from "class-variance-authority";
import { Function as F } from "effect";
import type { MouseEvent, PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { Color, SizeSchema } from "@ender/shared/utils/theming";

import { Align, Justify, Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";
import { Tooltip } from "../../../tooltip/src";
import { isEmptyReactNode } from "../../../utils";

const TriggerButtonSizeSchema = SizeSchema.pipe(
  Schema.pickLiteral("sm", "md", "lg"),
);
const TriggerButtonSizeValues = TriggerButtonSizeSchema.literals;
type TriggerButtonSizes = Schema.Schema.Type<typeof TriggerButtonSizeSchema>;
const TriggerButtonSize = castEnum(TriggerButtonSizeSchema);

type TriggerButtonProps = {
  size?: TriggerButtonSizes;
  rounded?: boolean;
  disabled?: boolean;
  selected?: boolean;
  loading?: boolean;
  tooltip?: ReactNode;
  disabledTooltip?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const TriggerButtonVariantGenerator = cva(
  [
    "border px-3",
    "text-sm/standard",
    "group-data-[state=open]/menu:border-primary-500 group-data-[state=open]/popover:border-primary-500 data-[state=open]:border-primary-500",
  ],
  {
    defaultVariants: {
      size: TriggerButtonSize.md,
      color: Color.primary,
      loading: false,
      rounded: true,
    },
    variants: {
      color: {
        [Color.primary]: "wildcard-primary",
        [Color.green]: "wildcard-green",
        [Color.red]: "wildcard-red",
        [Color.slate]: "wildcard-slate",
      },
      loading: {
        //TODO determine if loading styles should be different than disabled styles
        false: "",
        true: "",
      },
      rounded: {
        false: "rounded",
        true: "rounded-full",
      },
      selected: {
        false: "border-slate-200",
        true: "border-primary-200 bg-primary-100",
      },
      size: {
        [TriggerButtonSize.sm]: "py-[calc(0.375rem-1px)]",
        [TriggerButtonSize.md]: "py-[calc(0.625rem-1px)]",
        [TriggerButtonSize.lg]: "py-3.5",
      },
    },
  },
);

const TriggerButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<TriggerButtonProps>
>(function TriggerButton(props, ref) {
  const {
    children,
    disabled = false,
    onClick = F.constVoid,
    loading = false,
    tooltip,
    disabledTooltip,
    size,
    rounded,
    selected,
    ...other
  } = props;
  const attributes = {
    ...props,
  };

  const tooltipLabel = disabled ? disabledTooltip : tooltip;

  return (
    <Tooltip label={tooltipLabel} disabled={isEmptyReactNode(tooltipLabel)}>
      <button
        type="button"
        disabled={disabled || loading}
        onClick={onClick}
        {...other}
        className={cx(
          TriggerButtonVariantGenerator(attributes),
          //@ts-expect-error className is not an exposed prop, but is used when this is a child via Radix `asChild`
          other.className,
        )}
        ref={ref}>
        <Group
          spacing={Spacing.sm}
          justify={Justify.between}
          align={Align.center}
          noWrap>
          {!isEmptyReactNode(children) && (
            <span className="grow whitespace-nowrap">{children}</span>
          )}
          <IconChevronDown className="group-data-[state=open]/menu:rotate-180 group-data-[state=open]/popover:rotate-180 text-xl" />
        </Group>
      </button>
    </Tooltip>
  );
});

export { TriggerButton, TriggerButtonSize, TriggerButtonSizeValues };

export type { TriggerButtonProps, TriggerButtonSizes };
