// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { HoverCard } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { Array as A, String as S } from "effect";
import { useContext } from "react";

import { getOffers } from "@ender/shared/api/buy";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { BrokersServiceBrokerType } from "@ender/shared/generated/com.ender.buy.service";
import { BrokersServiceBrokerTypeEnum } from "@ender/shared/generated/com.ender.buy.service";
import { cast } from "@ender/shared/types/cast";
import { EmptyTableRow } from "@ender/shared/ui/empty-table-row";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { convertToNumber } from "@ender/shared/utils/string";

import type { OfferType } from "../types";
import { OfferTypeEnum } from "../types";
import { UnderwritingPageContext } from "./underwriting-queue-context/underwriting-page-context";

import styles from "./underwriting-offer-history.module.css";

type Offer = {
  amount: string;
  comment: string;
  date: string;
  id: string;
  isHighestAndBestOffer: boolean;
  source: BrokersServiceBrokerType;
  status: {
    name: string;
    id: string;
  };
  timeOnMarket: number;
  timestamp: string;
  type: OfferType;
};

const OFFER_TYPE_MAP: Record<OfferType, string> = {
  [OfferTypeEnum.BROKER]: "Broker",
  [OfferTypeEnum.BUYER]: "Buyer",
  [OfferTypeEnum.BUYER_CONCESSION_REQUEST]: "Buyer Concession Request",
  [OfferTypeEnum.SELLER_CONCESSION_RESPONSE]: "Seller Concession Response",
  [OfferTypeEnum.SELLER_COUNTER]: "Seller Counter",
};

function determineIsHighestAndBestOffer(
  source: BrokersServiceBrokerType,
  isHighestAndBestOffer: boolean,
) {
  if (source === BrokersServiceBrokerTypeEnum.SELLSIDE) {
    return "N/A";
  }

  return isHighestAndBestOffer ? "Yes" : "No";
}

function UnderwritingOfferHistory() {
  const { dealId } = useContext(UnderwritingPageContext);

  const { data: offers } = useQuery<Offer[]>({
    initialData: [],
    queryFn: () => cast<Promise<Offer[]>>(getOffers({ dealId })),
    queryKey: ["BuyAPI.getOffers", dealId] as const,
  });

  return (
    <EnderTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Source</th>
          <th>Amount</th>
          <th>Time on Market</th>
          <th>Highest and Best Offer</th>
          <th>Status</th>
          <th>Comments</th>
        </tr>
      </thead>
      <tbody>
        {A.isNonEmptyArray(offers ?? []) &&
          offers.map(
            ({
              id,
              comment,
              date,
              source,
              type,
              amount,
              isHighestAndBestOffer,
              timeOnMarket,
              status,
              timestamp,
            }) => (
              <tr key={id}>
                <td>
                  <Tooltip label={new Date(timestamp).toLocaleString()}>
                    <div>{EnderDate.of(date).toSlashDateString()}</div>
                  </Tooltip>
                </td>
                <td>{OFFER_TYPE_MAP[type]}</td>
                <td>
                  {amount ? `$${convertToNumber(amount).toLocaleString()}` : ""}
                </td>
                <td>{!isNaN(timeOnMarket) ? `${timeOnMarket} days` : "N/A"}</td>
                <td>
                  {determineIsHighestAndBestOffer(
                    source,
                    isHighestAndBestOffer,
                  )}
                </td>
                <td>
                  <span>{status.name}</span>
                </td>
                <td>
                  {S.length(comment) < 30 ? (
                    comment
                  ) : (
                    <HoverCard width={300}>
                      <HoverCard.Target>
                        <div className={styles.comment}>{comment}</div>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <div className={styles.commentHoverCard}>{comment}</div>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  )}
                </td>
              </tr>
            ),
          )}
        {A.isEmptyArray(offers ?? []) && (
          <EmptyTableRow message="No offers to display." colSpan={7} />
        )}
      </tbody>
    </EnderTable>
  );
}

export { UnderwritingOfferHistory };
