import { Predicate as P } from "effect";
import { useMemo } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import type { InvoicesAPIMarkPaidPayload } from "@ender/shared/generated/ender.api.accounting";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

import type { MarkAsPaidReceivedSingleFormValues } from "./use-mark-as-paid-received-single-invoice-form";

type MarkAsPaidReceivedSingleFormArgs = {
  paymentType: MoneyTransferTransferType;
  values: MarkAsPaidReceivedSingleFormValues;
  invoiceId: EnderId;
};

function getApiArgumentsForSingleInvoice({
  paymentType,
  values,
  invoiceId,
}: MarkAsPaidReceivedSingleFormArgs): InvoicesAPIMarkPaidPayload {
  return {
    invoiceId,
    periodId: values.periodId,
    transferDetails: {
      bankAccountId: values.bankAccountId,
      ...(P.isNotNullable(values.checkDate) && {
        checkDate: LocalDate$.of(values.checkDate).toJSON(),
      }),
      ...(P.isNotNullable(values.checkNumber) && {
        checkNumber: values.checkNumber,
      }),
      invoiceIds: [invoiceId],
      ledgerDate: LocalDate$.of(values.ledgerDate).toJSON(),
      memo: values.memo,
      periodId: values.periodId,
      transferType: paymentType,
    },
  };
}

function useMarkPaidWithWarnings() {
  const confirmation = useConfirmationContext();
  return useMemo(
    () =>
      withWarningHandler(InvoicesAPI.markPaid, (warnings) =>
        confirmation(
          {
            content: warnings.join("\n"),
            title: "Warning!",
          },
          { confirmButtonProps: { color: Color.red } },
        ),
      ),
    [confirmation],
  );
}

export { getApiArgumentsForSingleInvoice, useMarkPaidWithWarnings };
