import type { ReactNode } from "react";
import { useCallback, useState } from "react";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

function useClipboard({ timeout = 2000 } = {}) {
  const [error, setError] = useState<Error | Null>(NULL);
  const [copied, { toggle: setCopied }] = useBoolean(false);
  const [copyTimeout, setCopyTimeout] = useState<number | Undefined>(UNDEFINED);

  const handleCopyResult = useCallback(
    (value: boolean) => {
      window.clearTimeout(copyTimeout);
      setCopyTimeout(window.setTimeout(() => setCopied(false), timeout));
      setCopied(value);
    },
    [copyTimeout, setCopied, timeout],
  );

  const copy = useCallback(
    (valueToCopy: string | ReactNode | Undefined | number) => {
      if ("clipboard" in navigator) {
        navigator.clipboard
          .writeText(valueToCopy as string)
          .then(() => handleCopyResult(true))
          .catch((err) => setError(err));
      } else {
        setError(
          new Error("useClipboard: navigator.clipboard is not supported"),
        );
      }
    },
    [handleCopyResult],
  );

  const reset = useCallback(() => {
    setCopied(false);
    setError(NULL);
    window.clearTimeout(copyTimeout);
  }, [copyTimeout, setCopied]);

  return { copied, copy, error, reset };
}

export { useClipboard };
