import { Schema } from "@effect/schema";

import type { EnderId, Money } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import {
  ModelTypeEffectSchema,
  ModelTypeEnum,
} from "@ender/shared/generated/com.ender.common.model";
import type { GetChatInfoResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { MessageMedium } from "@ender/shared/generated/ender.model.comm";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { ChatTimelineAuthorResponse } from "@ender/shared/generated/ender.service.comm";
import type { ObjectValues } from "@ender/shared/types/general";

import type { Medium } from "./subscription";

const ChatStatus = {
  ERRORED: "errored",
  LOADED: "loaded",
  LOADING: "loading",
} as const;

const ClientCommandEnum = {
  LOG: "log",
  MARK_SEEN: "seen",
  REFRESH_TIMELINE: "refreshTimeline",
  SEND_CHAT: "chat",
  SET_CHANNEL: "setChannel",
} as const;
type ClientCommand = ObjectValues<typeof ClientCommandEnum>;

const ServerCommandEnum = {
  ACKNOWLEDGE: "acknowledge",
  ALERT: "alert",
  CALL: "call",
  CHAT: "chat",
  DOCUMENT: "document",
  INVOICE: "invoice",
  LIVE_USERS: "liveUsers",
  TASK: "task",
  TIMELINE: "timeline",
  USERS_LIST: "usersList",
} as const;
type ServerCommand = ObjectValues<typeof ServerCommandEnum>;

/**
 * these commands represent items that should be appended to the timeline
 */
type TimelineVisibleCommand = Pick<
  typeof ServerCommandEnum,
  "CHAT" | "CALL" | "DOCUMENT" | "INVOICE"
>;

const SocketStatusEnum = {
  CLOSING: "closing",
  CONNECTED: "connected",
  CONNECTING: "connecting",
  DISCONNECTED: "disconnected",
  ERROR: "error",
  NO_CONNECTION: "no_connection",
  UNKNOWN: "unknown",
} as const;
type SocketStatus = ObjectValues<typeof SocketStatusEnum>;

const ChatAction = {
  ADD_MESSAGE: "addMessage",
  LOADING_CHANNEL: "loadingChannel",
  SET_CHANNEL: "setChannel",
  SET_CHAT_STATUS: "setChatStatus",
  SET_GROUPED_MESSAGES: "setGroupedMessages",
  SET_LIVE_USERS: "setLiveUsers",
  SET_ONLINE: "setOnline",
  SET_SOCKET: "setSocket",
  SET_SOCKET_STATUS: "setSocketStatus",
  SET_UNREAD_MESSAGES: "setUnreadMessages",
  SET_USERS: "setUsers",
  VISIBILITY_CHANGE: "visibilityChange",
} as const;

/** WebSocketHandler::syncLiveUsers (ender/chat/WebSocketHandler.java) */
type LiveUser = {
  id: EnderId;
  name: string;
};

/** ChatTimeline::dateTime (ender/service/comm/ChatTimeline.java) */
type TimelineDateTime = {
  time: string;
  date: string;
  unix: number;
  iso: string;
};

///
///
/**
 * This section of types represents the serialized format of several responses we can get from the websocket.
 * The 4 items that can be displayed on the chat timeline are:
 * Message- a text-only message
 * VoiceCall- a voice call which may contain a transcription
 * Invoice- an invoice which may contain a payment button
 * Attachment- an image or file. This is named 'Document' in some locations in the BE, but attachment is more accurate.
 */
///
///

type SocketCommand<T extends ServerCommand> = { command: T };

//Eventually we want the Message to be a serializable, and then we can just re-use that type and extend it with the timeline command
/** ChatTimeline::serialize Message (ender/service/comm/ChatTimeline.java) */
type Message = {
  id: EnderId;
  text: string;
  dateTime: TimelineDateTime;
  author: ChatTimelineAuthorResponse;
  medium: MessageMedium;
  /**
   * only attached if the message is associated with a notification
   */
  smsSent?: string[];
  emailsSent?: string[];
};
type TimelineMessage = SocketCommand<"chat"> & Message;

//same as Message, but for voice calls.
/** ChatTimeline::serialize VoiceCall (ender/service/comm/ChatTimeline.java) */
type VoiceCall = {
  id: EnderId;
  transcription: string;
  dateTime: TimelineDateTime;
  audioUrl?: string;
  author?: ChatTimelineAuthorResponse;
};
type TimelineCall = SocketCommand<"call"> & VoiceCall;

/** ChatTimeline::serialize Invoice (ender/service/comm/ChatTimeline.java) */
type Invoice = {
  id: EnderId;
  price: Money;
  description: string;
  ago: string;
  dateTime: TimelineDateTime;
  status: string;
  author: ChatTimelineAuthorResponse;
  showButtons: boolean;
  payee?: string;
};
type TimelineInvoice = SocketCommand<"invoice"> & Invoice;

/** ChatTimeline::serializeDocument (ender/service/comm/ChatTimeline.java) */
type Attachment = {
  id: EnderId;
  name: string;
  url: string;
  dateTime: TimelineDateTime;
  author?: ChatTimelineAuthorResponse;
};
type TimelineAttachment = SocketCommand<"document"> & Attachment;

/** WebSocketHandler::handle (ender/chat/WebSocketHandler.java)
 *
 * this type determines how uploaded files are associated with a given message
 */
type ChatFileAttachment = {
  filename: string;
  url: string;
};

/**
 * These are currently unused, and are identical to TimelineMessage.
 * They will eventually be used to display unique items in the timeline.
 */
type TimelineTaskEvent = {
  id: EnderId;
  dateTime: TimelineDateTime;
  author: ChatTimelineAuthorResponse;
  url: string;
  description: string;
};
type TimelineTask = SocketCommand<"task"> &
  (TimelineTaskEvent);

/**
 * This is the union of all items that can be displayed on the chat timeline.
 *
 * This cannot be replaced with ChatTimelineTimelineItemResponse because that response type does not correctly indicate
 * the discriminated union nature of the timeline message types.
 */
type TimelineEntry =
  | TimelineMessage
  | TimelineCall
  | TimelineInvoice
  | TimelineAttachment
  | TimelineTask;

type GroupUser =
  | {
      id: EnderId;
      name: string;
    }
  | User;
type UserGroup = { groupName: string; groupUsers: GroupUser[] };

/** ChatUserFinder::createUserGroups (ender/chat/ChatUserFinder.java) */
type AlertPayload = SocketCommand<"alert"> & { alert: string; ackId?: string };
type LiveUsersPayload = SocketCommand<"liveUsers"> & { liveUsers: LiveUser[] };
type TimelinePayload = SocketCommand<"timeline"> & {
  timeline: TimelineEntry[];
};
type UsersListPayload = SocketCommand<"usersList"> & { usersList: UserGroup[] };
type AcknowledgementPayload = SocketCommand<"acknowledge"> & {
  ackId: string;
  messageId: EnderId;
};
type ServerPayload =
  | AlertPayload
  | LiveUsersPayload
  | TimelinePayload
  | UsersListPayload
  | TimelineEntry
  | AcknowledgementPayload;

const ChatModelSchema = Schema.Struct({
  modelId: EnderIdFormSchema,
  modelType: Schema.Literal(
    ...ModelTypeEffectSchema.literals.filter(
      (v) => v !== ModelTypeEnum.MULTI_MODEL_CHAT,
    ),
  ),
});

const MultiChatModelSchema = Schema.Struct({
  modelParams: ChatModelSchema.pipe(Schema.Array),
  modelType: ModelTypeEffectSchema.pipe(
    Schema.pickLiteral(ModelTypeEnum.MULTI_MODEL_CHAT),
  ),
});

/**
 * the below types form a discriminable union
 * If modelType is MULTI_MODEL_CHAT, then modelParams is defined as an array of chat models
 * If modelType is any direct model, modelId is required
 */
type ChatModel = Schema.Schema.Type<typeof ChatModelSchema>;
type MultiChatModel = Schema.Schema.Type<typeof MultiChatModelSchema>;

type ChatInfo = GetChatInfoResponse;

/** WebSocketHandler::handle (ender/chat/WebSocketHandler.java) */
type SetChannelCommand = {
  command: typeof ClientCommandEnum.SET_CHANNEL;
  channel: ChatInfo["channel"];
  firstConnect: boolean;
};
/** WebSocketHandler::handle (ender/chat/WebSocketHandler.java) */
type SendChatCommand = {
  command: typeof ClientCommandEnum.SEND_CHAT;
  text: string;
  medium?: Medium;
  attachments?: ChatFileAttachment[];
  acknowledgmentId?: string;
};
/** WebSocketHandler::handle (ender/chat/WebSocketHandler.java) */
type MarkSeenCommand = {
  command: typeof ClientCommandEnum.MARK_SEEN;
};
/** WebSocketHandler::handle (ender/chat/WebSocketHandler.java) */
type RefreshTimelineCommand = {
  command: typeof ClientCommandEnum.REFRESH_TIMELINE;
};
/** WebSocketHandler::handle (ender/chat/WebSocketHandler.java) */
type LogCommand = {
  command: typeof ClientCommandEnum.LOG;
  payload: {
    logSource: string;
    data: string;
  };
};

type ClientPayload =
  | SetChannelCommand
  | SendChatCommand
  | MarkSeenCommand
  | RefreshTimelineCommand
  | LogCommand;

export {
  ChatStatus,
  ClientCommandEnum,
  ServerCommandEnum,
  SocketStatusEnum,
  ChatAction,
  ChatModelSchema,
  MultiChatModelSchema,
};
export type {
  SocketStatus,
  TimelineVisibleCommand,
  ClientCommand,
  ServerCommand,
  LiveUser,
  TimelineDateTime,
  TimelineAttachment,
  TimelineCall,
  TimelineInvoice,
  TimelineMessage,
  TimelineTaskEvent,
  TimelineEntry,
  GroupUser,
  UserGroup,
  ServerPayload,
  ClientPayload,
  SocketCommand,
  ChatModel,
  MultiChatModel,
  ChatInfo,
  ChatFileAttachment,
};
