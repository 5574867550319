/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/archive
 * @function archiveInspection
 * @memberof InspectionAPI
 * @param {InspectionAPIArchiveInspectionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIArchiveInspectionPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIArchiveInspectionSearchParams = {
  token?: string | undefined;
};

type InspectionAPIArchiveInspectionPayload =
  InspectionAPIArchiveInspectionPathParams &
    InspectionAPIArchiveInspectionSearchParams;

function archiveInspectionUnsafeEffect(
  payload: InspectionAPIArchiveInspectionPayload,
) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/archive`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function archiveInspection(
  payload: InspectionAPIArchiveInspectionPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    archiveInspectionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { archiveInspection };
export type {
  InspectionAPIArchiveInspectionPathParams,
  InspectionAPIArchiveInspectionPayload,
  InspectionAPIArchiveInspectionSearchParams,
};
