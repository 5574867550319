import { FontSize, Text } from "@ender/shared/ds/text";

type ItemsReviewedCellProps = {
  itemsReviewed: number;
  reviewItemsNeeded: number;
};

function ItemsReviewedCell({
  itemsReviewed,
  reviewItemsNeeded,
}: ItemsReviewedCellProps) {
  return (
    <Text size={FontSize.sm}>
      {itemsReviewed}/{reviewItemsNeeded} items reviewed
    </Text>
  );
}

export { ItemsReviewedCell };
