import { z } from "zod";

import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";
import { CategoryFlagValues } from "@ender/shared/generated/ender.model.accounting";

const Flags = [
  "LATE_FEES",
  "RENT",
  "RENTAL_INCOME",
  "RENTAL_INCOME_ADJUSTMENT",
  "ACCOUNT_RECEIVABLE",
  "ACCOUNTS_PAYABLE",
] as const;

const CoaCategoryBaseSchema = z.object({
  id: EnderIdSchema,
  pmId: EnderIdSchema,
  name: z.string(),
  accountNumber: z.string(),
  accountName: z.string(),
  tenantFriendlyName: z.string(),
  waterfallPriority: z.number(),
  type: z.string(),
  header: z.string(),
  flags: z.enum(CategoryFlagValues).array(),
});

type CoaCategoryBase = z.infer<typeof CoaCategoryBaseSchema>;

type CoaCategoryChildProto = CoaCategoryBase & {
  parentId: EnderId;
  children: CoaCategoryChildProto[];
};

const CoaCategoryChildSchema: z.ZodTypeAny = CoaCategoryBaseSchema.extend({
  parentId: EnderIdSchema,
  children: z.lazy(() => CoaCategoryChildSchema.array()),
});
type CoaCategoryChild = z.infer<typeof CoaCategoryChildSchema>;

const CoaCategorySchema = CoaCategoryBaseSchema.extend({
  children: CoaCategoryChildSchema.array(),
});

type CoaCategory = z.infer<typeof CoaCategorySchema>;

const CoaFlagDetailsSchema = z.object({
  name: z.string(),
  required: z.boolean(),
  unique: z.boolean(),
});

type CoaFlagDetails = z.infer<typeof CoaFlagDetailsSchema>;

const GetChartOfAccountsResponseSchema = z.object({
  categories: CoaCategorySchema.array(),
  flagsDetail: CoaFlagDetailsSchema.array(),
});
type GetChartOfAccountsResponse = z.infer<
  typeof GetChartOfAccountsResponseSchema
>;

export {
  CoaCategoryBaseSchema,
  CoaCategoryChildSchema,
  CoaCategorySchema,
  CoaFlagDetailsSchema,
  Flags,
  GetChartOfAccountsResponseSchema,
};
export type {
  CoaCategory,
  CoaCategoryBase,
  CoaCategoryChild,
  CoaCategoryChildProto,
  CoaFlagDetails,
  GetChartOfAccountsResponse,
};
