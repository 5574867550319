import type { ReactElement } from "react";
import { memo } from "react";

import type { InvoicePage } from "@ender/shared/api/invoices";
import type { Null } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { CreateInvoice } from "@ender/widgets/finance/create-invoice";

import { InvoiceFileCarousel } from "./invoice-file-carousel";
import { InvoiceFileUpload } from "./invoice-file-upload";
import type { UploadedByFilterProps } from "./uploaded-by-filter";
import { UploadedByFilter } from "./uploaded-by-filter";

import styles from "./invoice-inbox.module.css";

const CREATE_INVOICE_DESKTOP_STYLE = Object.freeze({
  gridArea: "createInvoice",
});

type InvoiceInboxDesktopProps = {
  apEmailAddress: string;
  arEmailAddress: string;
  baseKey: string;
  invoicePageList: InvoicePage[];
  invoiceType: InvoiceInvoiceType;
  onDeleteInvoicePage: () => void;
  onDeselectAll: () => void;
  onInvoiceCreated: (invoiceType: InvoiceInvoiceType) => void | Promise<void>;
  onSelectInvoicePage: (args: { page: InvoicePage }) => void;
  onUploadInvoicePage: () => void;
  pmId: EnderId;
  selectedInvoicePageList: InvoicePage[];
  setUploadedByUserId: UploadedByFilterProps["onChange"];
  uploadedByUserId: UploadedByFilterProps["value"];
};

const InvoiceInboxDesktop = memo(function InvoiceInboxDesktop({
  apEmailAddress,
  arEmailAddress,
  baseKey,
  invoicePageList,
  invoiceType,
  onDeleteInvoicePage,
  onDeselectAll,
  onInvoiceCreated,
  onSelectInvoicePage,
  onUploadInvoicePage,
  pmId,
  selectedInvoicePageList,
  setUploadedByUserId,
  uploadedByUserId,
}: InvoiceInboxDesktopProps): ReactElement | Null {
  return (
    <>
      <InvoiceFileUpload
        key={`${baseKey}_InvoiceFileUpload`}
        pmId={pmId}
        invoiceType={invoiceType}
        apEmailAddress={apEmailAddress}
        arEmailAddress={arEmailAddress}
        selectedInvoiceCount={selectedInvoicePageList.length}
        style={{ gridArea: "upload" }}
        onDeselectAll={onDeselectAll}
        onUploadInvoicePage={onUploadInvoicePage}
      />
      <div style={{ gridArea: "uploadedBy" }}>
        <UploadedByFilter
          value={uploadedByUserId}
          onChange={setUploadedByUserId}
        />
      </div>
      <p className={styles.categorizeInfo} style={{ gridArea: "info" }}>
        You have {invoicePageList.length || 0}{" "}
        {`page${invoicePageList.length !== 1 ? "s" : ""}`} left to categorize!
      </p>
      <InvoiceFileCarousel
        key={`${baseKey}_AvailableFileCarousel`}
        invoicePageList={invoicePageList}
        selectedInvoicePageList={selectedInvoicePageList}
        onSelectInvoicePage={onSelectInvoicePage}
        style={{ gridArea: "carousel" }}
      />
      <InvoiceFileCarousel
        key={`${baseKey}_SelectedFileCarousel`}
        invoicePageList={selectedInvoicePageList}
        emptyContent={
          <Stack spacing={Spacing.none} align={Align.center}>
            <H2>No Document Selected</H2>
            <p>Invoice will generate without any attachments</p>
          </Stack>
        }
        onDeleteInvoicePage={onDeleteInvoicePage}
        style={{ gridArea: "basket" }}
      />
      <CreateInvoice
        key={`${baseKey}_CreateInvoice`}
        invoiceType={invoiceType}
        selectedPages={selectedInvoicePageList}
        onInvoiceSuccessfullyCreated={onInvoiceCreated}
        style={CREATE_INVOICE_DESKTOP_STYLE}
      />
    </>
  );
});

export { InvoiceInboxDesktop };
export type { InvoiceInboxDesktopProps };
