/**
 * HTTP Method: DELETE
 * Pathname: /renewalOffers/{offerId}
 * @function deleteOffer
 * @memberof RenewalsAPI
 * @param {RenewalsAPIDeleteOfferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPIDeleteOfferPathParams = {
  offerId: EnderId;
};

type RenewalsAPIDeleteOfferSearchParams = {
  token?: string | undefined;
};

type RenewalsAPIDeleteOfferPayload = RenewalsAPIDeleteOfferPathParams &
  RenewalsAPIDeleteOfferSearchParams;

function deleteOfferUnsafeEffect(payload: RenewalsAPIDeleteOfferPayload) {
  const { offerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/renewalOffers/${offerId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteOffer(
  payload: RenewalsAPIDeleteOfferPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteOfferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteOffer };
export type {
  RenewalsAPIDeleteOfferPathParams,
  RenewalsAPIDeleteOfferPayload,
  RenewalsAPIDeleteOfferSearchParams,
};
