import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SortBySortColumnValues = [
  "ID",
  "AMOUNT",
  "BANK_ACCOUNT_NAME",
  "CHECK_NUMBER",
  "DEPOSIT_DATE",
  "PROPERTY_NAME",
  "RECEIPT_DATE",
  "TENANT_NAME",
  "UNIT_NAME",
  "PAYMENT_TYPE",
] as const;

const SortBySortColumnEffectSchema = Schema.Literal(...SortBySortColumnValues);

type SortBySortColumn = Schema.Schema.Type<typeof SortBySortColumnEffectSchema>;

const SortBySortColumnEnum = castEnum(SortBySortColumnEffectSchema);

function randomSortBySortColumn(): SortBySortColumn {
  return rand(SortBySortColumnValues);
}

export {
  randomSortBySortColumn,
  SortBySortColumnEffectSchema,
  SortBySortColumnEnum,
  SortBySortColumnValues,
};
export type { SortBySortColumn };
