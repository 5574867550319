import { useQuery } from "@tanstack/react-query";
import { Array as A, Function as F, Predicate as P } from "effect";
import { useMemo } from "react";

import { InvoiceCounterpartyTuple } from "@ender/entities/invoice-counterparty-tuple";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { SearchBankAccountResponse } from "@ender/shared/generated/ender.model.accounting.response";
import { LoadingIndicator } from "@ender/shared/utils/general";
import {
  getBatchableInvoicesFromInvoiceSerializerInvoiceResponseInvoicesAdapter,
  getPropertyShortNameFromInvoice,
  useInvoiceBatches,
} from "@ender/shared/utils/invoice-utils";

import { MarkAsPaidReceivedSingleInvoiceForm } from "./mark-as-paid-received-single-invoice-form";
import { extractInvoicePropertyIds } from "./use-operating-account";

type MarkAsPaidModalProps = {
  bankAccounts: SearchBankAccountResponse[];
  invoiceId: EnderId;
  onSuccess?: () => void;
};

function MarkAsPaidForm({
  bankAccounts,
  invoiceId,
  onSuccess = F.constVoid,
}: MarkAsPaidModalProps) {
  const { data: invoice, isLoading: isLoadingInvoice } = useQuery({
    enabled: P.isNotNullable(invoiceId),
    queryFn: ({ queryKey: [, payload], signal }) =>
      InvoicesAPI.getInvoice(payload, { signal }),
    queryKey: ["invoiceDetails", { invoiceId }] as const,
  });

  const { amount, service, task } = invoice || {};

  const propertyIds = extractInvoicePropertyIds(invoice);
  const { data: operatingId, isLoading: isLoadingBankAccountIdDetails } =
    useQuery({
      queryKey: [
        "PropertiesAPI.getOperatingAccountIdByPropertyId",
        { invoiceType: invoice?.type, propertyIds: [propertyIds[0]] },
      ] as const,
      queryFn: ({ queryKey: [, { invoiceType, propertyIds }] }) => {
        return A.isNonEmptyArray([...propertyIds]) && invoiceType
          ? PropertiesAPI.getOperatingAccountIdByPropertyId({
              invoiceType,
              propertyIds: [...propertyIds],
            })
          : Promise.resolve({});
      },
      enabled: P.isNotNullable(invoice?.type),
      select: (data: Partial<Record<EnderId, EnderId>>) => data[propertyIds[0]],
    });

  const batchableInvoices = useMemo(
    () =>
      getBatchableInvoicesFromInvoiceSerializerInvoiceResponseInvoicesAdapter(
        A.fromNullable(invoice),
      ),
    [invoice],
  );
  const { invoiceBatches } = useInvoiceBatches({ invoices: batchableInvoices });

  if (isLoadingInvoice) {
    return <LoadingIndicator message="Loading invoice..." />;
  }

  if (!invoice) {
    return <div>No invoice data available.</div>;
  }

  if (isLoadingBankAccountIdDetails) {
    return <LoadingIndicator message="Loading bank account details..." />;
  }

  if (A.isEmptyArray(invoiceBatches)) {
    return <LoadingIndicator message="Loading data..." />;
  }

  return (
    <Stack>
      <Stack spacing={Spacing.none}>
        <InvoiceCounterpartyTuple invoice={invoice} />
        {P.isNotNullable(task) && (
          <Tuple label="Task" value={task.description} />
        )}
        {P.isNotNullable(service) && (
          <Tuple label="Service" value={service.name} />
        )}
        {/* TODO DRY w invoice-details see PropertyValue */}
        <Tuple
          label="Property"
          value={getPropertyShortNameFromInvoice(invoice)}
        />
        {P.isNotNullable(amount) && (
          <Tuple
            label="Amount"
            value={<MoneyDisplay value={Money$.parse(amount)} showSymbol />}
          />
        )}
      </Stack>
      <MarkAsPaidReceivedSingleInvoiceForm
        bankAccounts={bankAccounts}
        invoice={invoice}
        operatingAccountId={operatingId}
        onSuccess={onSuccess}
      />
    </Stack>
  );
}

export { MarkAsPaidForm };
