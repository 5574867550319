/**
 * This API is used for listing page. It returns a list of info of listing for the given PmId, filtered by
 propertyIds, unitIds and status.
 * HTTP Method: POST
 * Pathname: /listings
 * @function searchListings
 * @memberof LeasingAPI
 * @param {LeasingAPISearchListingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeasingServiceListingResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeasingServiceListingResponse } from "@ender/shared/generated/ender.service.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISearchListingsSearchParams = {
  token?: string | undefined;
};

type LeasingAPISearchListingsBodyParams = {
  /**
   * whether the listing is featured or not
   */
  isFeatured?: boolean | undefined;
  marketIds: EnderId[];
  ownershipGroupIds: EnderId[];
  /**
 * A list of propertyIds which you want to include in the listing result. If Empty, all listings
                    are included.
 */
  propertyIds: EnderId[];
  /**
   * LISTED or UNLISTED. Default is LISTED.
   */
  status?: string | undefined;
  /**
 * A list of unitIds which you want to include in the listing result. If Empty, all listings are
                    included.
 */
  unitIds: EnderId[];
};

type LeasingAPISearchListingsPayload = LeasingAPISearchListingsSearchParams &
  LeasingAPISearchListingsBodyParams;

function searchListingsUnsafeEffect(payload: LeasingAPISearchListingsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeasingServiceListingResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<LeasingServiceListingResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/listings",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchListings(
  payload: LeasingAPISearchListingsPayload,
  options?: { signal?: AbortSignal },
): Promise<LeasingServiceListingResponse[]> {
  return F.pipe(
    payload,
    searchListingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchListings };
export type {
  LeasingAPISearchListingsBodyParams,
  LeasingAPISearchListingsPayload,
  LeasingAPISearchListingsSearchParams,
};
