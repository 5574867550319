import { createLinkToken } from "./create-link-token";
import { dwollaBeneficialOwners } from "./dwolla-beneficial-owners";
import { getBankLinkInfoByModelV1 } from "./get-bank-link-info-by-model-v1";
import { getBankLinkInfoByModelV2 } from "./get-bank-link-info-by-model-v2";
import { getIndustries } from "./get-industries";
import { linkBankToPlaid } from "./link-bank-to-plaid";
import { linkBankWithMicrodeposits } from "./link-bank-with-microdeposits";
import { linkPlaidBankAccount } from "./link-plaid-bank-account";
import { linkPlaidConnectedBankToDwolla } from "./link-plaid-connected-bank-to-dwolla";
import { linkReceiveOnlyBank } from "./link-receive-only-bank";
import { registerDwollaCustomer } from "./register-dwolla-customer";
import { registerReceiveOnlyCustomer } from "./register-receive-only-customer";
import { webhook } from "./webhook";

const DwollaAPI = {
  createLinkToken,
  dwollaBeneficialOwners,
  getBankLinkInfoByModelV1,
  getBankLinkInfoByModelV2,
  getIndustries,
  linkBankToPlaid,
  linkBankWithMicrodeposits,
  linkPlaidBankAccount,
  linkPlaidConnectedBankToDwolla,
  linkReceiveOnlyBank,
  registerDwollaCustomer,
  registerReceiveOnlyCustomer,
  webhook,
};

export { DwollaAPI };
