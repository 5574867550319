/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/comps/rent-listings
 * @function getRentComps
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPIGetRentCompsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnderwritingAPIGetRentCompsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { CompsServiceComparableDataSource } from "@ender/shared/generated/com.ender.buy.service";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { UnderwritingAPIGetRentCompsResponse } from "../underwriting-api-get-rent-comps-response";

type UnderwritingAPIGetRentCompsPathParams = {
  dealId: EnderId;
};

type UnderwritingAPIGetRentCompsSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPIGetRentCompsBodyParams = {
  dataSource?: CompsServiceComparableDataSource | undefined;
  forceUpdate?: boolean | undefined;
  maxBathDiff: number;
  maxBedDiff: number;
  maxDistanceMiles: number;
  maxSqftDiff: number;
  minListingDate: SerializesInto<LocalDate>;
};

type UnderwritingAPIGetRentCompsPayload =
  UnderwritingAPIGetRentCompsPathParams &
    UnderwritingAPIGetRentCompsSearchParams &
    UnderwritingAPIGetRentCompsBodyParams;

function getRentCompsUnsafeEffect(payload: UnderwritingAPIGetRentCompsPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnderwritingAPIGetRentCompsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<UnderwritingAPIGetRentCompsResponse[]>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/comps/rent-listings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRentComps(
  payload: UnderwritingAPIGetRentCompsPayload,
  options?: { signal?: AbortSignal },
): Promise<UnderwritingAPIGetRentCompsResponse[]> {
  return F.pipe(
    payload,
    getRentCompsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRentComps };
export type {
  UnderwritingAPIGetRentCompsBodyParams,
  UnderwritingAPIGetRentCompsPathParams,
  UnderwritingAPIGetRentCompsPayload,
  UnderwritingAPIGetRentCompsSearchParams,
};
