/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/saveSalesData
 * @function saveSalesData
 * @memberof LeasingAPI
 * @param {LeasingAPISaveSalesDataPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISaveSalesDataPathParams = {
  leaseId: EnderId;
};

type LeasingAPISaveSalesDataSearchParams = {
  token?: string | undefined;
};

type LeasingAPISaveSalesDataBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type LeasingAPISaveSalesDataPayload = LeasingAPISaveSalesDataPathParams &
  LeasingAPISaveSalesDataSearchParams &
  LeasingAPISaveSalesDataBodyParams;

function saveSalesDataUnsafeEffect(payload: LeasingAPISaveSalesDataPayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/saveSalesData`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function saveSalesData(
  payload: LeasingAPISaveSalesDataPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    saveSalesDataUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { saveSalesData };
export type {
  LeasingAPISaveSalesDataBodyParams,
  LeasingAPISaveSalesDataPathParams,
  LeasingAPISaveSalesDataPayload,
  LeasingAPISaveSalesDataSearchParams,
};
