import { IconAlertTriangle, IconInfoCircle } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import type { ReactNode } from "react";

import type { KeywordSearch } from "@ender/entities/search-input";
import { SearchInput } from "@ender/entities/search-input";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { EnderId } from "@ender/shared/core";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { ApprovalsAPIAddApproverPayload } from "@ender/shared/generated/ender.api.misc";
import {
  ApprovalsAPI,
  SearchAPI,
} from "@ender/shared/generated/ender.api.misc";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { SearchServiceSearchTypeEnum } from "@ender/shared/generated/ender.service.search";

type AddApproverProps = {
  disabled?: boolean;
  excludeIds: EnderId[];
  onSuccess: () => Promise<void>;
  stepId: EnderId;
  tooltip?: ReactNode;
};

function AddApprover({
  excludeIds,
  disabled,
  tooltip,
  onSuccess,
  stepId,
}: AddApproverProps) {
  const queryClient = useQueryClient();
  const addApproverMutation = useMutation({
    mutationFn: (payload: ApprovalsAPIAddApproverPayload) =>
      ApprovalsAPI.addApprover(payload),
    mutationKey: ["ApprovalsAPI.addApprover"] as const,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ApprovalsAPI.addApprover"],
      });
      onSuccess();
    },
  });

  async function onResultSelect(id: O.Option<EnderId>) {
    if (O.isSome(id)) {
      await addApproverMutation.mutateAsync({
        stepId,
        userId: O.getOrThrow(id),
      });
    }
  }

  const searchManagers: (params: {
    excludeIds: EnderId[];
  }) => KeywordSearch<EnderId> =
    ({ excludeIds }) =>
    async (keyword: string) => {
      const res = await SearchAPI.omnisearch({
        excludeIds,
        keyword,
        limit: 25,
        propertyIds: [],
        resultsOnEmpty: true,
        types: [SearchServiceSearchTypeEnum.MANAGER],
      });
      return res.map((user: User) => ({
        label: `${user.firstName} ${user.lastName}`,
        meta: user,
        value: user.id,
      }));
    };

  return (
    <Group justify={Justify.stretch} align={Align.center} grow noWrap>
      <SearchInput<EnderId>
        placeholder="Add User or Group"
        search={searchManagers({ excludeIds })}
        modelType={ModelTypeEnum.USER}
        onChange={onResultSelect}
        value={O.none()}
      />
      {P.isNotNullable(tooltip) && (
        <Tooltip label={tooltip}>
          {!disabled && <IconInfoCircle color={EnderThemeColorEnum.PRIMARY} />}
          {disabled && <IconAlertTriangle color={EnderThemeColorEnum.YELLOW} />}
        </Tooltip>
      )}
    </Group>
  );
}

export { AddApprover };
