/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/offers
 * @function createOffer
 * @memberof BuyAPI
 * @param {BuyAPICreateOfferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyOffer>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type {
  BuyOffer,
  BuyOfferOfferSource,
  BuyOfferOfferType,
} from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPICreateOfferPathParams = {
  dealId: EnderId;
};

type BuyAPICreateOfferSearchParams = {
  token?: string | undefined;
};

type BuyAPICreateOfferBodyParams = {
  amount: SerializesInto<Money>;
  comment?: string | undefined;
  isHighestAndBestOffer?: boolean | undefined;
  source: BuyOfferOfferSource;
  type: BuyOfferOfferType;
};

type BuyAPICreateOfferPayload = BuyAPICreateOfferPathParams &
  BuyAPICreateOfferSearchParams &
  BuyAPICreateOfferBodyParams;

function createOfferUnsafeEffect(payload: BuyAPICreateOfferPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyOffer>({
        body,
        decode: unsafeDecodeJsonResponse<BuyOffer>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/offers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createOffer(
  payload: BuyAPICreateOfferPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyOffer> {
  return F.pipe(
    payload,
    createOfferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createOffer };
export type {
  BuyAPICreateOfferBodyParams,
  BuyAPICreateOfferPathParams,
  BuyAPICreateOfferPayload,
  BuyAPICreateOfferSearchParams,
};
