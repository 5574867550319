import type { Undefined } from "@ender/shared/constants/general";

import type { ApprovalProcessHybridId } from "../types";
import {
  DEFAULT_APPROVAL_PROCESS_HYBRID_ID,
  NEW_APPROVAL_PROCESS_HYBRID_ID,
} from "../types";
import { DeleteApprovalProcessButton } from "./delete-approval-process-button";

type ApprovalProcessRuleActionIconsMenuProps = {
  approvalProcessId: ApprovalProcessHybridId;
  hasEditPermission: boolean;
  onDeleteApprovalProcessSuccess: () => void;
  isDeleting?: boolean | Undefined;
  setIsDeleting?: (val: boolean) => void;
};

function ApprovalProcessRuleActionIconsMenu({
  approvalProcessId,
  hasEditPermission,
  onDeleteApprovalProcessSuccess,
  isDeleting,
  setIsDeleting,
}: ApprovalProcessRuleActionIconsMenuProps) {
  return (
    <div>
      {hasEditPermission &&
        approvalProcessId !== DEFAULT_APPROVAL_PROCESS_HYBRID_ID &&
        approvalProcessId !== NEW_APPROVAL_PROCESS_HYBRID_ID && (
          <DeleteApprovalProcessButton
            approvalProcessId={approvalProcessId}
            onSuccess={onDeleteApprovalProcessSuccess}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
          />
        )}
    </div>
  );
}

export { ApprovalProcessRuleActionIconsMenu };
