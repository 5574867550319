// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type {
  MultiSelectProps as MantineMultiSelectProps,
  SelectItem as MantineSelectItem,
} from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { MultiSelect as MantineMultiSelect } from "@mantine/core";
import { Predicate as P } from "effect";
import type { ForwardedRef, ReactNode } from "react";
import { forwardRef, useCallback, useMemo } from "react";





type SelectItem<T> = MantineSelectItem & {
  meta: T;
};

type MultiSelectProps<T> = Omit<
  MantineMultiSelectProps,
  "data" | "value" | "onChange"
> & {
  data: SelectItem<T>[];
  value: SelectItem<T>[];
  onChange: (value: SelectItem<T>[], selectedItem?: SelectItem<T>) => void;
};

function MultiSelect<T>(
  props: MultiSelectProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { data, value, onChange, ...rest } = props;

  const values = useMemo(() => value.map((v) => v.value), [value]);
  const valueSet = useMemo(() => new Set(values), [values]);
  const dataMap = useMemo(
    () =>
      new Map<string, SelectItem<T>>(data.map((item) => [item.value, item])),
    [data],
  );
  const _onChange = useCallback(
    (selectedValues: string[]) => {
      const selectedItem = dataMap.get(
        selectedValues.filter((v) => !valueSet.has(v))[0],
      );
      const selectedItems = selectedValues
        .map((v) => dataMap.get(v))
        .filter(P.isNotNullable);
      // We need this because Mantine's popover implementation sucks! without this one tick delay we receive the
      // following `Warning: flushSync was called from inside a lifecycle method` which causes memory issues in FF
      // They claim [here](https://github.com/mantinedev/mantine/issues/1823) to have fixed it but it's a lie!!
      setTimeout(() => onChange(selectedItems, selectedItem));
    },
    [dataMap, valueSet, onChange],
  );

  return (
    <MantineMultiSelect
      data={data}
      value={values}
      onChange={_onChange}
      {...rest}
      ref={ref}
    />
  );
}

// We need to cast here since higher order function type inferences are not supported by TS for functions that
// referenced their generic parameter outside their signature like all react components
// see: https://github.com/microsoft/TypeScript/issues/30650#issuecomment-488036395
/**
 * @deprecated use shared/ds/multi-select or widgets/filters/multi-filter
 */
const ForwardedMultiSelect = forwardRef(MultiSelect) as <T>(
  // eslint-disable-next-line no-use-before-define
  props: MultiSelectProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReactNode;

export { ForwardedMultiSelect as MultiSelect };
export type { MultiSelectProps, SelectItem };
