import { Predicate as P, String as S } from "effect";
import { useMemo } from "react";

import { Badge } from "@ender/shared/ds/badge";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FontWeight, Text } from "@ender/shared/ds/text";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationGroup } from "@ender/shared/generated/ender.model.leasing";
import { ApplicationApplicantTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import { EnderDate } from "@ender/shared/utils/ender-date";
import type { ChatChannelParams } from "@ender/widgets/chat";
import { Chat } from "@ender/widgets/chat";

type ApplicationChatProps = {
  applicationGroup: ApplicationGroup;
  applications: GetApplicationGroupResponseApplicationResponse[];
};

/**
 * @function sortApplications
 * @description Sorting for applicants to place them in order by 1) Financial Responsibility, 2) Adult vs Minor, 3) Name
 * @note Returning 1 sorts applicant into higher index in the array; returning -1 sorts applicant into a lower index in the array.
 * @returns {number} The sorting index for applicantA
 */
function sortApplications(
  {
    applicantType: typeA,
    applicant: { firstName: firstNameA, lastName: lastNameA, birthday: dobA },
  }: GetApplicationGroupResponseApplicationResponse,
  {
    applicantType: typeB,
    applicant: { firstName: firstNameB, lastName: lastNameB, birthday: dobB },
  }: GetApplicationGroupResponseApplicationResponse,
) {
  const nameA = `${firstNameA} ${lastNameA}`;
  const nameB = `${firstNameB} ${lastNameB}`;
  const isApplicantAMinor = !dobA || EnderDate.isBirthdayMinor(dobA);
  const isApplicantBMinor = !dobB || EnderDate.isBirthdayMinor(dobB);
  const isAFinanciallyResponsible = [
    ApplicationApplicantTypeEnum.GUARANTOR,
    ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
    ApplicationApplicantTypeEnum.OTHER_OCCUPANT,
  ].includes(typeA);
  const isBFinanciallyResponsible = [
    ApplicationApplicantTypeEnum.GUARANTOR,
    ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
    ApplicationApplicantTypeEnum.OTHER_OCCUPANT,
  ].includes(typeB);

  if (isAFinanciallyResponsible && !isBFinanciallyResponsible) {
    return -1;
  }

  if (!isAFinanciallyResponsible && isBFinanciallyResponsible) {
    return 1;
  }

  if (isApplicantAMinor && !isApplicantBMinor) {
    return 1;
  }

  if (!isApplicantAMinor && isApplicantBMinor) {
    return -1;
  }

  if (nameA.toUpperCase() > nameB.toUpperCase()) {
    return 1;
  }

  if (nameA.toUpperCase() < nameB.toUpperCase()) {
    return -1;
  }

  return 0;
}

function ApplicationChat({
  applicationGroup,
  applications,
}: ApplicationChatProps) {
  const chatChannels = useMemo<ChatChannelParams[]>(() => {
    const financiallyResponsible =
      applications.filter((application) =>
        application.applicantType.includes(
          ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
        ),
      ) ?? [];

    const contactableApplicants = applications.filter(
      ({ applicant }) =>
        //do they have at least one valid form of contact?
        (S.isNonEmpty(applicant.email) || S.isNonEmpty(applicant.phone)) &&
        //are they not a minor?
        (P.isNullable(applicant.birthday) ||
          !EnderDate.isBirthdayMinor(applicant.birthday)),
    );

    const arr: ChatChannelParams[] =
      financiallyResponsible.length > 1
        ? [
            {
              chatTitle: "All Financially Responsible Applicants",
              isInternal: false,
              modelId: applicationGroup?.id,
              modelType: ModelTypeEnum.APPLICATION_GROUP,
            },
          ]
        : [];

    const sortedContactableApplications =
      contactableApplicants.sort(sortApplications);
    return arr.concat([
      ...sortedContactableApplications.map<ChatChannelParams>(
        ({ applicant, applicantType, applicationId }) => ({
          chatTitle: (
            <Group align={Align.center} spacing={Spacing.sm}>
              <Text weight={FontWeight.medium}>
                {applicant.firstName} {applicant.lastName}
              </Text>
              {applicantType ===
                ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT && (
                <Badge size="sm">Financially Responsible</Badge>
              )}
            </Group>
          ),
          isInternal: false,
          modelId: applicationId,
          modelType: ModelTypeEnum.APPLICATION,
        }),
      ),
    ]);
  }, [applicationGroup, applications]);

  return <Chat channels={chatChannels} title="Application Chat" />;
}

export { ApplicationChat };
