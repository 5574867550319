import { Array as A, Option as O, pipe } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { MoneyInputProps } from "@ender/shared/ds/money-input";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";

import type { AllFinanciallyResponsibleTenantsSwitchProps } from "./all-financially-responsible-tenants-switch";
import { AllFinanciallyResponsibleTenantsSwitch } from "./all-financially-responsible-tenants-switch";

type AllocationMoneyInputProps = Pick<
  MoneyInputProps,
  "error" | "value" | "onChange"
>;

function AllocationMoneyInput({
  error,
  onChange,
  value,
}: AllocationMoneyInputProps) {
  return (
    <MoneyInput
      error={error}
      label="Refund Amount"
      onChange={onChange}
      value={value}
    />
  );
}

type AllocationGroupProps = {
  label: string;
} & Pick<MoneyInputProps, "error" | "value" | "onChange">;

function AllocationGroup({
  error,
  label,
  onChange,
  value,
}: AllocationGroupProps) {
  return (
    <Group justify={Justify.between}>
      <Text>{label}</Text>
      <AllocationMoneyInput error={error} onChange={onChange} value={value} />
    </Group>
  );
}

type RefundRecipient = {
  amount: MoneyInputProps["value"];
  id: EnderId;
  name: string;
};

type TenantAllocationsV2Props = {
  allTenantsAmount: MoneyInputProps["value"];
  handleChangeAllTenantsAmount: MoneyInputProps["onChange"];
  handleChangeIsForAllTenants: AllFinanciallyResponsibleTenantsSwitchProps["onChange"];
  handleCurrencyInputChange: (
    value: MoneyInputProps["value"],
    oldValue: RefundRecipient,
    refundRecipientsPosition: number,
  ) => void;
  isForAllTenants: AllFinanciallyResponsibleTenantsSwitchProps["value"];
  refundRecipients: RefundRecipient[];
};

function TenantAllocationsV2({
  allTenantsAmount,
  handleChangeAllTenantsAmount,
  handleChangeIsForAllTenants,
  handleCurrencyInputChange,
  isForAllTenants,
  refundRecipients,
}: TenantAllocationsV2Props) {
  return (
    <Stack>
      <AllFinanciallyResponsibleTenantsSwitch
        onChange={handleChangeIsForAllTenants}
        value={isForAllTenants}
      />
      {isForAllTenants ? (
        <AllocationMoneyInput
          error={undefined}
          onChange={handleChangeAllTenantsAmount}
          value={allTenantsAmount}
        />
      ) : (
        <>
          <Text>
            Please set an amount to refund one or more tenants. A unique invoice
            will be created for each tenant.
          </Text>
          {refundRecipients.map((recipient, index) => (
            <AllocationGroup
              key={index}
              label={recipient.name}
              onChange={(value) =>
                handleCurrencyInputChange(value, recipient, index)
              }
              value={recipient.amount}
            />
          ))}
          <Tuple
            label="Total Refund Amount"
            value={pipe(
              refundRecipients,
              A.map((r) => r.amount),
              O.reduceCompact(Money$.zero(), Money$.add),
              Money$.toFormatted("DEFAULT"),
            )}
          />
        </>
      )}
    </Stack>
  );
}

export { TenantAllocationsV2 };
