import { z } from "zod";

import { EnderIdSchema, ParseEnderId } from "@ender/shared/core";
import { PayableCategoryPayableReasonValues } from "@ender/shared/generated/ender.model.accounting";
import type { ObjectValues } from "@ender/shared/types/general";
import { CurrencySchema, castEnum } from "@ender/shared/utils/zod";

// TODO [2024-01-09] [TASK_V2]: Remove this temporary hack which is here to shunt users over to Tasks V2 for EVERGREEN
const EVERGREEN_ID = ParseEnderId("01FZ3Y1NKMYP8JNW3PJ51GFZZC");

/**
 * @description model/task/Task.java TaskStatusEnum
 */
const TaskStatusValues = [
  "PM_CONFIRMING_TASK", // The tenant created this Task and the PM is confirming that it is eligible to be completed
  "PM_ASSIGNING_VENDOR", // The PM needs to assign a Vendor or User to complete this Task.
  "VENDOR_ACCEPTING", // The Vendor needs to accept/reject this Task.
  "SCHEDULING", // The PM & Vendor need to schedule when this Task is going to be completed.
  "AWAITING_WORK_TO_BEGIN", // The work has been scheduled, but has not yet begun:"scheduled",
  "IN_PROGRESS", // The assignees are doing the work to accomplish this Task.
  "PM_CONFIRMING_WORK", // The PM needs to confirm that the Vendor completed the work to the PM's (and tenant's) satisfaction.
  "VENDOR_ADDING_INVOICE", // The Vendor needs to add an Invoice for the work done.
  "PM_APPROVING_INVOICE", // The PM needs to approve of the Invoice (it goes through the Invoice Approval Process)
  "PM_SENDING_PAYMENT", // Invoice has been approved and PM needs to send payment to the Vendor.
  "CLOSED", // The task has been completed and paid for. Or closed out b/c it is no longer an issue.
] as const;

const TaskStatusSchema = z.enum(TaskStatusValues);
type TaskStatus = z.infer<typeof TaskStatusSchema>;

const TaskStatusEnum = castEnum<TaskStatus>(TaskStatusSchema);

/**
 * @description model/task/Task.java TaskStatusGroupingEnum
 */
const TaskStatusGroupingValues = [
  "OPEN", // The Task has not been completed yet.
  "PAYING", // The Task has been completed, and we are in the process of paying the Vendor.
  "CLOSED", // The Task has been completed and paid for.
] as const;

const TaskStatusGroupingSchema = z.enum(TaskStatusGroupingValues);
type TaskStatusGrouping = z.infer<typeof TaskStatusGroupingSchema>;

const TaskStatusGroupingEnum = castEnum<TaskStatusGrouping>(
  TaskStatusGroupingSchema,
);

const TenantTaskStatusGroupingValues = ["OPEN", "SCHEDULED", "CLOSED"] as const;
const TenantTaskStatusGroupingSchema = z.enum(TenantTaskStatusGroupingValues);
type TenantTaskStatusGrouping = z.infer<typeof TenantTaskStatusGroupingSchema>;

const TenantTaskStatusGroupingEnum = castEnum<TenantTaskStatusGrouping>(
  TenantTaskStatusGroupingSchema,
);

const TaskStatusDisplayMap = {
  ANY: "Any",
  [TaskStatusGroupingEnum.OPEN]: "Open",
  [TaskStatusGroupingEnum.PAYING]: "Paying",
  [TaskStatusGroupingEnum.CLOSED]: "Closed",
  [TaskStatusEnum.PM_CONFIRMING_TASK]: "Pending PM Confirmation",
  [TaskStatusEnum.PM_ASSIGNING_VENDOR]: "Pending Vendor Assignment",
  [TaskStatusEnum.VENDOR_ACCEPTING]: "Pending Vendor Acceptance",
  [TaskStatusEnum.SCHEDULING]: "Scheduling",
  [TaskStatusEnum.AWAITING_WORK_TO_BEGIN]: "Awaiting Work to Begin",
  [TaskStatusEnum.IN_PROGRESS]: "In Progress",
  ESTIMATE_REJECTED: "Estimate Rejected",
  REVIEW_OF_ESTIMATE: "Review of Estimate",
  [TaskStatusEnum.PM_CONFIRMING_WORK]: "Pending Work Confirmation",
  [TaskStatusEnum.VENDOR_ADDING_INVOICE]: "Pending Invoice",
  [TaskStatusEnum.PM_APPROVING_INVOICE]: "Reviewing Invoice",
  [TaskStatusEnum.PM_SENDING_PAYMENT]: "Sending Payment",
  // "Closed" is already defined above
  // [TaskStatusEnum.CLOSED]: "Closed",
};

const WorkOrderStatusList = [
  "ANY",
  TaskStatusEnum.PM_CONFIRMING_TASK,
  TaskStatusEnum.PM_ASSIGNING_VENDOR,
  TaskStatusEnum.VENDOR_ACCEPTING,
  TaskStatusEnum.SCHEDULING,
  TaskStatusEnum.IN_PROGRESS,
  "ESTIMATE_REJECTED",
  "REVIEW_OF_ESTIMATE",
  TaskStatusEnum.PM_CONFIRMING_WORK,
  TaskStatusEnum.VENDOR_ADDING_INVOICE,
  TaskStatusEnum.PM_APPROVING_INVOICE,
  TaskStatusEnum.PM_SENDING_PAYMENT,
  TaskStatusEnum.CLOSED,
] as const;

const WorkOrderStatusSet = new Set<string>(WorkOrderStatusList);

/**
 * @description /model/task/TaskAssignment.java TaskAssignmentStatusEnum
 */
const TaskAssignmentStatusEnum = {
  ACCEPTED: "Accepted",
  ASSIGNED: "Assigned",
  REJECTED: "Rejected",
  UNASSIGNED: "Unassigned",
} as const;

type TaskAssignmentStatus = ObjectValues<typeof TaskAssignmentStatusEnum>;

/**
 * @description api/misc/response/PayableCategoryResponse.java
 */
const PayableCategorySchema = z.object({
  amount: CurrencySchema,
  assignedById: EnderIdSchema,
  assignmentId: EnderIdSchema,
  categoryName: z.string(),
  glCategoryAccountName: z.string(),
  glCategoryAccountNumber: z.string(),
  glCategoryId: EnderIdSchema,
  payableCategoryAuthorId: EnderIdSchema,
  payableCategoryId: EnderIdSchema,
  reason: z.enum(PayableCategoryPayableReasonValues).nullable(),
  subCategoryName: z.string(),
});
type TaskCategory = z.infer<typeof PayableCategorySchema>;

const TargetDateOptionValues = [
  "NONE",
  "7",
  "30",
  "60",
] as const; /* numDaysAhead */
const TargetDateOptionSchema = z.enum(["ANY", ...TargetDateOptionValues]);
type TargetDateOption = z.infer<typeof TargetDateOptionSchema>;

const TargetDateOptionEnum = castEnum<TargetDateOption>(TargetDateOptionSchema);

const PMTaskTypeValues = [
  "INTERNAL",
  "WORK_ORDER",
  "TENANT_CHARGEBACK",
  "LATE_RENT",
] as const;
const PMTaskTypeSchema = z.enum(PMTaskTypeValues);
type PMTaskType = z.infer<typeof PMTaskTypeSchema>;

const PMTaskTypeEnum = castEnum<PMTaskType>(PMTaskTypeSchema);

const PMNewTaskTypeDisplayEnum = {
  INTERNAL: "Internal",
  WORK_ORDER: "Work Order",
} as const;

const PMTaskTypeDisplayEnum = {
  ...PMNewTaskTypeDisplayEnum,
  TENANT_CHARGEBACK: "Tenant Chargeback",
} as const;
const PMTaskTypeDisplaySchema = z.nativeEnum(PMTaskTypeDisplayEnum);
const PMTaskTypeDisplayValues = Object.values(PMTaskTypeDisplayEnum);
type PMTaskTypeDisplay = z.infer<typeof PMTaskTypeDisplaySchema>;

/**
 * @enum {string} AssigneeTypeEnum
 */
const AssigneeTypeValues = ["INTERNAL", "VENDOR"] as const;
const AssigneeTypeSchema = z.enum(AssigneeTypeValues);
type AssigneeType = z.infer<typeof AssigneeTypeSchema>;

const AssigneeTypeEnum = castEnum<AssigneeType>(AssigneeTypeSchema);

const CostEstimateRejectionReasonValues = [
  "PRICE",
  "INCORRECT_APPROACH",
  "MORE_INFO_REQUIRED",
] as const;
const CostEstimateRejectionReasonSchema = z.enum(
  CostEstimateRejectionReasonValues,
);

/**
 * @description /api/misc/TasksAPI.java TaskAPI::getCostEstimateHistory
 */
const CostEstimateHistorySchema = z
  .object({
    costEstimate: z.string(),
    rejectionDescription: z.string().nullish(),
    rejectionReason: z.string().nullish(),
    timestamp: z.string(),
  })
  .array();

type CostEstimateHistory = z.infer<typeof CostEstimateHistorySchema>;

const TaskTabKeyValues = [
  "HISTORY",
  "TASK_INFO",
  "PAYABLES",
  "MESSAGES",
] as const;
const TaskTabKeySchema = z.enum(TaskTabKeyValues);
type TaskTabKey = z.infer<typeof TaskTabKeySchema>;

const TaskTabKeyEnum = castEnum<TaskTabKey>(TaskTabKeySchema);

// Keep Sorted Use: CMD+SHIFT+P -> `Sort Lines Ascending`, It helps with merge conflicts
export {
  AssigneeTypeEnum,
  CostEstimateHistorySchema,
  CostEstimateRejectionReasonSchema,
  CostEstimateRejectionReasonValues,
  EVERGREEN_ID,
  PMNewTaskTypeDisplayEnum,
  PMTaskTypeDisplayEnum,
  PMTaskTypeDisplaySchema,
  PMTaskTypeDisplayValues,
  PMTaskTypeEnum,
  PMTaskTypeSchema,
  PMTaskTypeValues,
  PayableCategorySchema,
  TargetDateOptionEnum,
  TargetDateOptionSchema,
  TargetDateOptionValues,
  TaskAssignmentStatusEnum,
  TaskStatusDisplayMap,
  TaskStatusEnum,
  TaskStatusGroupingEnum,
  TaskStatusGroupingSchema,
  TaskStatusGroupingValues,
  TaskStatusSchema,
  TaskStatusValues,
  TaskTabKeyEnum,
  TenantTaskStatusGroupingEnum,
  TenantTaskStatusGroupingSchema,
  TenantTaskStatusGroupingValues,
  WorkOrderStatusList,
  WorkOrderStatusSet,
};
// Keep Sorted Use: CMD+SHIFT+P -> `Sort Lines Ascending`, It helps with merge conflicts
export type {
  AssigneeType,
  CostEstimateHistory,
  PMTaskType,
  PMTaskTypeDisplay,
  TargetDateOption,
  TaskAssignmentStatus,
  TaskCategory,
  TaskStatus,
  TaskStatusGrouping,
  TaskTabKey,
  TenantTaskStatusGrouping,
};
