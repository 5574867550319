import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useCallback, useEffect, useMemo, useState } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import {
  AccountingPeriodAccountingModuleEnum,
  AccountingPeriodPeriodStatusEnum,
} from "@ender/shared/generated/ender.model.accounting";
import type { SearchBankAccountResponse } from "@ender/shared/generated/ender.model.accounting.response";
import { ApprovalStepApprovalPhaseEnum } from "@ender/shared/generated/ender.model.approvals";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { useAccountingPeriods } from "@ender/shared/hooks/use-accounting-periods";
import { getInvoicePropertyIds } from "@ender/shared/utils/invoice-utils";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { AuditHistory } from "@ender/widgets/finance/approval";
import { InvoiceAllocations } from "@ender/widgets/finance/invoice-allocations";

import { ApprovalDetails } from "./approval-details";
import { getInvoiceSuccessMessage } from "./get-invoice-success-message";
import { InvoiceApprovalMenu } from "./invoice-approval-menu/invoice-approval-menu";
import { InvoiceApprovalStatus } from "./invoice-approval-status";
import { InvoiceAttachments } from "./invoice-attachments";
import { InvoiceDetails } from "./invoice-details/invoice-details";
import { TenantBillbacks } from "./tenant-billbacks/lib/tenant-billbacks";
import { PaymentStatusEnum } from "./types";
import { useApprovalProcessForInvoice } from "./use-approval-process-for-invoice";
import { useFetchBankAccounts } from "./use-fetch-bank-accounts";
import { PaymentDetailsSingleInvoice } from "./widgets-finance-payment-details-single-invoice";

import styles from "./invoice-view.module.css";

type GetApprovalProcessStepProps = {
  approvalProcessSteps: GetApprovalProcessResponseStep[];
  invoice: InvoiceSerializerInvoiceResponse;
};

function getApprovalProcessStep({
  approvalProcessSteps,
  invoice,
}: GetApprovalProcessStepProps) {
  const stepId = invoice?.currentState?.stepId;
  return stepId
    ? approvalProcessSteps.find((step) => step.id === stepId)
    : NULL;
}

type InvoiceViewContentProps = {
  approvalProcessSteps: GetApprovalProcessResponseStep[];
  bankAccounts: SearchBankAccountResponse[];
  closeModal: () => void;
  invoice: InvoiceSerializerInvoiceResponse | Undefined;
  isEditable?: boolean;
  hideApprovalMenu?: boolean /* In certain situations we need to hide the approval Menu eg. on tasks */;
  isLoadingBankAccounts: boolean;
  isLoadingInvoice: boolean;
  loadNextInvoice: () => void;
  refetchBankAccounts: () => void;
  refreshData: () => void;
};

function InvoiceViewContent({
  approvalProcessSteps,
  bankAccounts,
  closeModal,
  invoice,
  isEditable,
  hideApprovalMenu,
  isLoadingBankAccounts,
  isLoadingInvoice,
  loadNextInvoice,
  refetchBankAccounts,
  refreshData,
}: InvoiceViewContentProps) {
  const [paymentDetailsModalInvoice, openPaymentDetailsModalForInvoice] =
    useState<InvoiceSerializerInvoiceResponse | Undefined>(UNDEFINED);

  const {
    approvalProcessForInvoice: approvalSteps,
    isFetchingApprovalProcessForInvoice: isFetchingApprovalSteps,
  } = useApprovalProcessForInvoice(invoice);

  const { isAP, counterpartyType, counterpartyId } = useMemo(() => {
    if (!P.isNotNullable(invoice)) {
      return {};
    }

    const isAP = invoice.owedByParty.party === PartyEnum.FIRM;
    return {
      counterpartyId: isAP ? invoice.owedToParty.id : invoice.owedByParty.id,
      counterpartyType: isAP
        ? invoice.owedToParty.party
        : invoice.owedByParty.party,
      firmId: isAP ? invoice.owedByParty.id : invoice.owedToParty.id,
      isAP,
    };
  }, [invoice]);

  const { data: periods } = useAccountingPeriods({
    disabled: !isAP,
    periodType: AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE,
  });

  const currentApprovalStep = useMemo(() => {
    if (P.isNullable(invoice) || P.isNullable(approvalSteps)) {
      return UNDEFINED;
    }

    return approvalSteps.steps.find(
      (step) => step.id === invoice.currentState.stepId,
    );
  }, [approvalSteps, invoice]);

  /**
   * Only true if moving FROM approvals phase INTO treasury phase.
   * If current step is treasury this will be false
   */
  const isNextStepInTreasuryPhase = useMemo(() => {
    if (
      P.isNullable(currentApprovalStep) ||
      currentApprovalStep.phase !==
        ApprovalStepApprovalPhaseEnum.ACCOUNTS_PAYABLE ||
      (P.isNotNullable(invoice) && invoice.currentState.isLastStep) ||
      P.isNullable(approvalSteps)
    ) {
      return false;
    }

    const currentStepIndex = approvalSteps.steps.findIndex(
      (step) => step.id === currentApprovalStep.id,
    );
    const nextStep = approvalSteps.steps[currentStepIndex + 1];

    return nextStep.phase === ApprovalStepApprovalPhaseEnum.TREASURY;
  }, [approvalSteps, currentApprovalStep, invoice]);

  const isAccountingPeriodClosed = useMemo(() => {
    // Prevent showing false messaging to the user
    if (P.isNullable(invoice) || P.isNullable(periods)) {
      return false;
    }

    const invoiceAccountingPeriodValue = invoice.accountingPeriods.find(
      ({ display }) => display === invoice.accountingPeriod,
    )?.date;

    if (P.isNullable(invoiceAccountingPeriodValue)) {
      return false;
    }

    const invoiceAccountingPeriod = periods.find(
      ({ startDate }) => startDate === invoiceAccountingPeriodValue,
    );
    return (
      invoiceAccountingPeriod?.status?.toUpperCase() ===
      AccountingPeriodPeriodStatusEnum.CLOSED
    );
  }, [invoice, periods]);

  // lazy useEffect
  useEffect(() => {
    if (!P.isNotNullable(invoice)) {
      return;
    }

    refetchBankAccounts();
  }, [counterpartyId, counterpartyType, invoice, isAP, refetchBankAccounts]);

  const selectedInvoiceApprovalProcessStep = useMemo(
    () =>
      P.isNotNullable(invoice)
        ? getApprovalProcessStep({
            // TODO
            approvalProcessSteps,
            invoice,
          })
        : UNDEFINED,
    [approvalProcessSteps, invoice],
  );

  //TODO: remove manual truncation once Ellipses is added to RightRail
  const charsForTitle = useMemo(() => {
    return 50 - (selectedInvoiceApprovalProcessStep?.name?.length ?? 0);
  }, [selectedInvoiceApprovalProcessStep]);

  const title = useMemo(() => {
    if (P.isNullable(invoice)) {
      return "";
    }
    return invoice.description.length > charsForTitle
      ? `${invoice.description.slice(0, charsForTitle)}...`
      : invoice.description;
  }, [charsForTitle, invoice]);

  const showPaymentDetailsButton =
    invoice?.status === ApprovableApprovalStatusEnum.APPROVED &&
    P.isNotNullable(invoice?.paymentInfo);
  const showTenantBillbacks =
    P.isNotNullable(invoice) && invoice.owedToParty.type !== PartyEnum.FIRM;

  function onApproveSuccess() {
    refreshData();
    loadNextInvoice();
  }

  function onPaymentSuccess() {
    refreshData();
    showSuccessNotification({
      message: getInvoiceSuccessMessage(
        invoice?.invoiceType,
        PaymentStatusEnum.PAID,
      ),
    });
    closeModal();
  }

  function onPaymentRejection() {
    refreshData();
    showSuccessNotification({
      message: getInvoiceSuccessMessage(
        invoice?.invoiceType,
        PaymentStatusEnum.REJECTED,
      ),
    });
    if (invoice?.invoiceType === InvoiceInvoiceTypeEnum.PAYABLE) {
      loadNextInvoice();
    }
  }

  function onVoidSuccess() {
    refreshData();
    showSuccessNotification({
      message: getInvoiceSuccessMessage(
        invoice?.invoiceType,
        PaymentStatusEnum.VOIDED,
      ),
    });
  }

  if (P.isNullable(invoice)) {
    return NULL;
  }

  return (
    <Stack spacing={Spacing.xl} aria-label="invoice-view">
      <H1>
        <Group justify={Justify.between}>
          {title}
          <InvoiceApprovalStatus
            currentState={invoice?.currentState}
            approvalProcessStepName={selectedInvoiceApprovalProcessStep?.name}
          />
        </Group>
      </H1>
      {!hideApprovalMenu && (
        <Group noWrap>
          <Skeleton
            visible={
              isFetchingApprovalSteps ||
              isLoadingBankAccounts ||
              isLoadingInvoice
            }>
            <InvoiceApprovalMenu
              approvalSteps={approvalSteps}
              bankAccounts={bankAccounts ?? []}
              invoice={invoice}
              isAccountingPeriodClosed={isAccountingPeriodClosed}
              onApproveSuccess={onApproveSuccess}
              onPaymentSuccess={onPaymentSuccess}
              onPaymentRejection={onPaymentRejection}
              onVoidSuccess={onVoidSuccess}
              isNextStepInTreasuryPhase={isNextStepInTreasuryPhase}
            />
          </Skeleton>
        </Group>
      )}
      <Skeleton visible={isLoadingInvoice}>
        <InvoiceDetails
          invoice={invoice}
          isAccountingPeriodClosed={isAccountingPeriodClosed}
          isEditable={isEditable}
          onSuccess={refreshData}
        />
      </Skeleton>
      <Skeleton visible={isFetchingApprovalSteps || isLoadingInvoice}>
        <InvoiceAllocations
          approvalSteps={approvalSteps}
          invoice={invoice}
          onSuccess={refreshData}
        />
      </Skeleton>
      {showTenantBillbacks && (
        <Skeleton visible={isLoadingInvoice}>
          <TenantBillbacks invoice={invoice} onSuccess={refreshData} />
        </Skeleton>
      )}
      <Skeleton visible={isFetchingApprovalSteps || isLoadingInvoice}>
        {P.isNotNullable(approvalSteps) && P.isNotNullable(invoice) && (
          <ApprovalDetails approvalProcess={approvalSteps} invoice={invoice} />
        )}
      </Skeleton>
      <Skeleton visible={isLoadingInvoice}>
        <InvoiceAttachments invoice={invoice} onSuccess={refreshData} />
      </Skeleton>
      <Skeleton visible={isLoadingInvoice}>
        {/* @ts-expect-error InvoiceSerializerInvoiceResponse's currentState timeline type is not as specific as audit-history's TimelineStep decision (Decision) */}
        <AuditHistory timeline={invoice?.currentState?.timeline} />
      </Skeleton>
      {showPaymentDetailsButton && (
        <Group justify={Justify.center}>
          <Skeleton visible={isLoadingInvoice}>
            <Button onClick={() => openPaymentDetailsModalForInvoice(invoice)}>
              View Payment Details
            </Button>
          </Skeleton>
        </Group>
      )}
      <Modal
        onClose={() => openPaymentDetailsModalForInvoice(UNDEFINED)}
        opened={P.isNotNullable(paymentDetailsModalInvoice)}
        title="">
        {P.isNotNullable(paymentDetailsModalInvoice) && (
          <PaymentDetailsSingleInvoice invoice={paymentDetailsModalInvoice} />
        )}
      </Modal>
    </Stack>
  );
}

type InvoiceDetailsProps = {
  approvalProcessSteps: GetApprovalProcessResponseStep[];
  invoiceId: EnderId;
  isEditable?: boolean;
  hideApprovalMenu?: boolean;
  loadNextInvoice: () => void;
  refreshData?: () => void;
  closeModal: () => void;
};

function InvoiceView({
  approvalProcessSteps,
  invoiceId,
  isEditable,
  hideApprovalMenu = false,
  loadNextInvoice,
  refreshData: refreshParentData,
  closeModal,
}: InvoiceDetailsProps) {
  const queryClient = useQueryClient();

  const {
    data: invoice,
    refetch: refetchInvoice,
    isLoading: isLoadingInvoice,
  } = useQuery<InvoiceSerializerInvoiceResponse>({
    queryKey: ["InvoicesAPI.getInvoice", invoiceId] as const,
    queryFn: ({ signal }) => InvoicesAPI.getInvoice({ invoiceId }, { signal }),
  });

  const triggerBankRecUpdate = useCallback(async () => {
    await queryClient.invalidateQueries(["bankAccounts"]);
  }, [queryClient]);

  const refreshData = useCallback(() => {
    refetchInvoice();
    if (refreshParentData) {
      refreshParentData();
    }
    triggerBankRecUpdate();
  }, [refetchInvoice, refreshParentData, triggerBankRecUpdate]);

  const isPayable = invoice?.invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;
  const {
    data: bankAccounts = [],
    refetch: refetchBankAccounts,
    isLoading: isLoadingBankAccounts,
  } = useFetchBankAccounts({
    enabled: P.isNotNullable(invoice),
    firmId: isPayable ? invoice?.owedByParty.id : invoice?.owedToParty.id,
    propertyIds: getInvoicePropertyIds(invoice),
  });

  return (
    <div className={styles.invoiceViewContainer}>
      <InvoiceViewContent
        approvalProcessSteps={approvalProcessSteps}
        bankAccounts={bankAccounts}
        closeModal={closeModal}
        invoice={invoice}
        isEditable={isEditable}
        hideApprovalMenu={hideApprovalMenu}
        isLoadingBankAccounts={isLoadingBankAccounts}
        isLoadingInvoice={isLoadingInvoice}
        loadNextInvoice={loadNextInvoice}
        refetchBankAccounts={refetchBankAccounts}
        refreshData={refreshData}
      />
    </div>
  );
}

export { InvoiceView };
