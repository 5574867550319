import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionInspectionStatusValues = [
  "ASSIGNING_MANAGER",
  "PLANNING",
  "IN_REVISION",
  "BUDGET_REVIEW",
  "PURCHASE_ORDER_CREATION",
  "EXECUTING",
  "COMPLETED",
  "ARCHIVED",
] as const;

const InspectionInspectionStatusEffectSchema = Schema.Literal(
  ...InspectionInspectionStatusValues,
);

type InspectionInspectionStatus = Schema.Schema.Type<
  typeof InspectionInspectionStatusEffectSchema
>;

const InspectionInspectionStatusEnum = castEnum(
  InspectionInspectionStatusEffectSchema,
);

function randomInspectionInspectionStatus(): InspectionInspectionStatus {
  return rand(InspectionInspectionStatusValues);
}

export {
  InspectionInspectionStatusEffectSchema,
  InspectionInspectionStatusEnum,
  InspectionInspectionStatusValues,
  randomInspectionInspectionStatus,
};
export type { InspectionInspectionStatus };
