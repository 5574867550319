import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskTaskPriorityValues = ["LOW", "MEDIUM", "HIGH", "EMERGENCY"] as const;

const TaskTaskPriorityEffectSchema = Schema.Literal(...TaskTaskPriorityValues);

type TaskTaskPriority = Schema.Schema.Type<typeof TaskTaskPriorityEffectSchema>;

const TaskTaskPriorityEnum = castEnum(TaskTaskPriorityEffectSchema);

function randomTaskTaskPriority(): TaskTaskPriority {
  return rand(TaskTaskPriorityValues);
}

export {
  randomTaskTaskPriority,
  TaskTaskPriorityEffectSchema,
  TaskTaskPriorityEnum,
  TaskTaskPriorityValues,
};
export type { TaskTaskPriority };
