import type { FC } from "react";

import { getEnderModalContext } from "./ender-modal.context";

import "./modal.scss";

// This ReactDOM.render technique breaks ModalInner relationship to parent
// Which means when the parent is removed it may leave dangling modals with callbacks pointing to non-existent components
/**
 * @deprecated please use EnderModal jsx and useState isOpen
 */
function openModal<T extends { closeModal?: () => void }>(
  ModalInner: FC<T>,
  props?: Omit<T, "closeModal"> & { closeModal?: () => void },
  className?: string,
) {
  const { openModal: _openModal } = getEnderModalContext();

  const { closeModal } = _openModal(ModalInner, { ...props, className });
  return Promise.resolve(() => closeModal());
}

export { openModal };
