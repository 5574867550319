import { Array as A } from "effect";

import type { Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";

import type { BankingDetailListTableData } from "../banking-detail-list-columns/banking-detail-list-columns";

const BankingDetailHeaderTooltips = {
  EXCLUDE_PERMISSION: "You do not have permission to exclude transactions.",
  RECONCILE_PERMISSION:
    "You do not have permission to manually reconcile Ender transactions. Please contact your system administrator to gain this permission.",
} as const;

type BankingDetailHeaderProps = {
  selectedRows: BankingDetailListTableData[];
  selectedRowsTotalAmount: Money$.Money;
  onExclude: () => void;
  onReconcile: () => void;
  permissions: {
    canManuallyReconcile: boolean;
    canManuallyExclude: boolean;
  };
};

function BankingDetailHeader({
  selectedRows,
  selectedRowsTotalAmount,
  onExclude,
  onReconcile,
  permissions,
}: BankingDetailHeaderProps) {
  return (
    <Group justify={Justify.end}>
      {A.isNonEmptyArray(selectedRows) && (
        <Group align={Align.center}>
          <Text size={FontSize.md}>Selected Total:</Text>
          <Text size={FontSize.md} weight={FontWeight.bold}>
            {selectedRowsTotalAmount.toFormatted()}
          </Text>
        </Group>
      )}
      <Tooltip
        label={BankingDetailHeaderTooltips.EXCLUDE_PERMISSION}
        disabled={permissions.canManuallyExclude}>
        <Button
          disabled={
            !permissions.canManuallyExclude || A.isEmptyArray(selectedRows)
          }
          onClick={onExclude}>
          Exclude
        </Button>
      </Tooltip>
      <Tooltip
        label={BankingDetailHeaderTooltips.RECONCILE_PERMISSION}
        disabled={permissions.canManuallyReconcile}>
        <Button
          disabled={
            !permissions.canManuallyReconcile || A.isEmptyArray(selectedRows)
          }
          onClick={onReconcile}>
          Reconcile
        </Button>
      </Tooltip>
    </Group>
  );
}

export { BankingDetailHeader, BankingDetailHeaderTooltips };
export type { BankingDetailHeaderProps };
