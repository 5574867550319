import {
  IconChevronLeft,
  IconChevronRight,
  IconDownload,
  IconTrash,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { parse } from "date-fns";
import { Option as O, Predicate as P } from "effect";
import { useContext, useState } from "react";

import { journalEntriesReportExcel } from "@ender/shared/api/files";
import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetGLJournalEntryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { ApprovalsAPIRejectPayload } from "@ender/shared/generated/ender.api.misc";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import type { GetJournalEntriesReportResponseJournalEntryReportRow } from "@ender/shared/generated/ender.api.reports.request";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useQueryParamsEnderId } from "@ender/shared/hooks/use-query-params";
import { fail } from "@ender/shared/utils/error";
import { getAndDownloadXlsx } from "@ender/shared/utils/general";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

import { ReverseJournalEntryModal } from "../../../../widgets/journal-entries/reverse-journal-entry-modal";
import { useGeneralLedgerTransactionApprovalsTable } from "../gltx-approvals-table/use-gltx-approvals-table";
import {
  useGeneralLedgerTransactionApprovalsTableTabs,
  useTableTypeTabs,
} from "../gltx-approvals-table/use-gltx-approvals-table-tabs";
import { GeneralLedgerTransactionApprovalsRejectTransactionForm } from "../gltx-approvals-transaction-reject-form";

type TransactionApprovalMenuProps = {
  approvalProcessSteps: GetApprovalProcessResponseStep[];
  isAccountingPeriodClosed: boolean;
  refreshReversalData: () => void;
  refreshTransactionData: () => void;
  reversedById?: EnderId;
  reversesId?: EnderId;
  journalEntry: O.Option<GetGLJournalEntryResponse>;
  recurringJournalEntry: O.Option<GetRecurringGLJournalEntryDetailsResponse>;
};

function GeneralLedgerTransactionApprovalsTransactionApprovalMenu({
  approvalProcessSteps,
  isAccountingPeriodClosed,
  refreshTransactionData,
  refreshReversalData,
  reversedById,
  reversesId,
  journalEntry,
  recurringJournalEntry,
}: TransactionApprovalMenuProps) {
  const { hasPermissions, user } = useContext(UserContext);
  const [tableTypeTab] = useTableTypeTabs();
  const [approvalStatusTab] = useGeneralLedgerTransactionApprovalsTableTabs();
  const [, setTransactionId] = useQueryParamsEnderId("id");
  const { table } = useGeneralLedgerTransactionApprovalsTable({
    approvalStatusTab,
    tableTypeTab: tableTypeTab.pipe(O.getOrThrow),
  });

  const [reverseModalProps, setReverseModalProps] = useState<
    O.Option<GetJournalEntriesReportResponseJournalEntryReportRow>
  >(O.none());
  const [
    isRejectModalOpen,
    { setTrue: openRejectModal, setFalse: closeRejectModal },
  ] = useBoolean();
  const [isProcessing, setIsProcessing] = useBoolean(false);

  const { mutateAsync: rejectTransaction } = useMutation({
    mutationFn: async (props: ApprovalsAPIRejectPayload) =>
      await ApprovalsAPI.reject(props),
    mutationKey: ["ApprovalsAPI.reject"] as const,
  });

  const confirmation = useConfirmationContext();
  const approveTransactionWithWarnings = withWarningHandler(
    ApprovalsAPI.approve,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );

  function loadNextTransaction() {
    const rows = table.getRowModel().rows;
    const currentIndex = rows.findIndex(
      (row) =>
        row.original.id ===
        (O.isSome(journalEntry)
          ? journalEntry.pipe(
              O.map((j) => j.id),
              O.getOrThrow,
            )
          : recurringJournalEntry.pipe(
              O.map((j) => j.id),
              O.getOrThrow,
            )),
    );

    if (currentIndex === -1) {
      return;
    }

    const nextIndex = (currentIndex + 1) % rows.length; // Loop back to the first row if at the end
    setTransactionId(rows[nextIndex]?.original?.id);
  }

  function loadPreviousTransaction() {
    const rows = table.getRowModel().rows;
    const currentIndex = rows.findIndex(
      (row) =>
        row.original.id ===
        (O.isSome(journalEntry)
          ? journalEntry.pipe(
              O.map((j) => j.id),
              O.getOrThrow,
            )
          : recurringJournalEntry.pipe(
              O.map((j) => j.id),
              O.getOrThrow,
            )),
    );

    if (currentIndex === -1) {
      return;
    }

    // Go to previous index, if at start loop to end
    const previousIndex =
      currentIndex === 0 ? rows.length - 1 : currentIndex - 1;
    setTransactionId(rows[previousIndex]?.original?.id);
  }

  async function handleApproveClick() {
    try {
      setIsProcessing.setTrue();
      const response = await approveTransactionWithWarnings({
        modelId: O.isSome(recurringJournalEntry)
          ? recurringJournalEntry.value.id
          : journalEntry.pipe(
              O.map((j) => j.id),
              O.getOrThrow,
            ),
        modelType: O.isSome(recurringJournalEntry)
          ? ModelTypeEnum.RECURRING_GL_JOURNAL_ENTRY
          : ModelTypeEnum.GL_JOURNAL_ENTRY,
      });
      //OK response can sometimes be null
      if (P.isNotNullable(response) || P.isNull(response)) {
        showSuccessNotification({ message: "Transaction approved." });
        refreshTransactionData();
        refreshReversalData();
        loadNextTransaction();
      }
    } catch (err) {
      fail(err);
    } finally {
      setIsProcessing.setFalse();
    }
  }

  async function handleRejectClick(comment: string, completelyReject: boolean) {
    //Retrieves the id of the previous approval step based on the current step id.
    const previousStepId = !completelyReject
      ? (approvalProcessSteps[
          approvalProcessSteps.findIndex(
            (step) =>
              step.id ===
              journalEntry.pipe(
                O.map((j) => j.approvalStatus.stepId),
                O.getOrUndefined,
              ),
          ) - 1
        ]?.id ?? UNDEFINED)
      : UNDEFINED;

    try {
      setIsProcessing.setTrue();
      await rejectTransaction({
        comment,
        modelId: O.isSome(recurringJournalEntry)
          ? recurringJournalEntry.value.id
          : journalEntry.pipe(
              O.map((j) => j.id),
              O.getOrThrow,
            ),
        modelType: O.isSome(recurringJournalEntry)
          ? ModelTypeEnum.RECURRING_GL_JOURNAL_ENTRY
          : ModelTypeEnum.GL_JOURNAL_ENTRY,
        rejectedToStepId: previousStepId,
        transactionDate: O.isNone(recurringJournalEntry)
          ? journalEntry.pipe(
              O.map((j) => j.transactionDate),
              O.getOrThrow,
            )
          : UNDEFINED,
      });
      showSuccessNotification({ message: "Transaction rejected." });
      refreshTransactionData();
      refreshReversalData();
      closeRejectModal();
      loadNextTransaction();
    } catch (err) {
      fail(err);
    } finally {
      setIsProcessing.setFalse();
    }
  }

  async function handleDeleteClick() {
    try {
      setIsProcessing.setTrue();
      await confirmation(
        {
          confirmButtonLabel: "Delete Recurring Entry",
          content:
            "This will only cancel any future entries from being posted, it will not reverse any previously posted entries.",
          title: "Delete Recurring Journal Entry?",
        },
        { confirmButtonProps: { color: Color.red } },
      );
      if (O.isSome(recurringJournalEntry)) {
        await AccountingAPI.deleteRecurringGLJournalEntry({
          id: recurringJournalEntry.value.id,
        });
      }
      showSuccessNotification({ message: "Recurring journal entry deleted." });
      loadNextTransaction();
    } catch (err) {
      fail(err);
    } finally {
      setIsProcessing.setFalse();
    }
  }

  const handleReverseClick = () => {
    const accountingPeriod = parse(
      journalEntry.pipe(
        O.map((j) => j.accountingPeriod),
        O.getOrElse(() => ""),
      ),
      "MMM yyyy",
      new Date(),
    );
    const formattedReverseModalProps = O.isSome(journalEntry)
      ? {
          accountingDate: LocalDate$.of(accountingPeriod).toJSON(),
          authorDisplay: journalEntry.value.authorName,
          description: journalEntry.value.description,
          hasAttachments: false,
          journalEntryId: journalEntry.value.id,
          ledgerDate: journalEntry.value.transactionDate,
          propertyDisplay: journalEntry.value.propertyResponse.name,
          systemDate: journalEntry.value.systemDate,
        }
      : UNDEFINED;
    setReverseModalProps(O.fromNullable(formattedReverseModalProps));
  };

  const handleCloseReverseModal = () => {
    setReverseModalProps(O.none());
  };

  const disabledReverseTooltip = P.isNotNullable(reversedById)
    ? "Transaction has already been reversed"
    : "Cannot reverse a Journal Entry reversal ";

  const isRejectable =
    (O.isSome(journalEntry) &&
      !journalEntry.value.approvalStatus.isFullyRejected &&
      !journalEntry.value.approvalStatus.isApproved) ||
    (O.isSome(recurringJournalEntry) &&
      recurringJournalEntry.value.approvalStatus ===
        ApprovableApprovalStatusEnum.NEW);

  return (
    <>
      <Group>
        {isRejectable && (
          <>
            <Button
              variant={ButtonVariant.outlined}
              color={Color.red}
              loading={isProcessing}
              disabled={
                (O.isSome(journalEntry) &&
                  !P.isTruthy(journalEntry.value.assignedToMe)) ||
                (O.isSome(recurringJournalEntry) &&
                  user.id !== recurringJournalEntry.value.approverId)
              }
              disabledTooltip="You are not the specified approver for this transaction."
              onClick={openRejectModal}>
              Reject
            </Button>
            <Modal
              onClose={closeRejectModal}
              opened={isRejectModalOpen}
              title="Reject Transaction">
              <GeneralLedgerTransactionApprovalsRejectTransactionForm
                onSuccess={handleRejectClick}
                isFirstStep={journalEntry.pipe(
                  O.map((j) => j.approvalStatus.isFirstStep),
                  O.getOrElse(() => true),
                )}
                isProcessing={isProcessing}
              />
            </Modal>
            <Button
              variant={ButtonVariant.outlined}
              color={Color.green}
              disabled={
                (O.isSome(journalEntry) &&
                  (!P.isTruthy(journalEntry.value.assignedToMe) ||
                    isAccountingPeriodClosed)) ||
                (O.isSome(recurringJournalEntry) &&
                  user.id !== recurringJournalEntry.value.approverId)
              }
              disabledTooltip={
                O.isSome(journalEntry) && isAccountingPeriodClosed
                  ? "Associated Accounting Period is Closed"
                  : "You are not the specified approver for this transaction."
              }
              loading={isProcessing}
              onClick={handleApproveClick}>
              {(O.isSome(journalEntry) &&
                journalEntry.value.approvalStatus.isLastStep) ||
              (O.isSome(recurringJournalEntry) &&
                recurringJournalEntry.value.approvalStatus ===
                  ApprovableApprovalStatusEnum.NEW)
                ? "Approve & Post"
                : "Approve"}
            </Button>
          </>
        )}
        {O.isSome(journalEntry) &&
          journalEntry.value.approvalStatus.isApproved && (
            <Button
              variant={ButtonVariant.outlined}
              color={Color.red}
              disabled={
                P.isNotNullable(reversedById) || P.isNotNullable(reversesId)
              }
              disabledTooltip={disabledReverseTooltip}
              loading={isProcessing}
              onClick={handleReverseClick}>
              Reverse
            </Button>
          )}
        {O.isSome(recurringJournalEntry) &&
          recurringJournalEntry.value.approvalStatus ===
            ApprovableApprovalStatusEnum.APPROVED &&
          hasPermissions(FunctionalPermissionEnum.CREATE_JOURNAL_ENTRIES) && (
            <ActionIcon
              tooltip="Delete Recurring Journal Entry"
              label="Delete Recurring Journal Entry"
              onClick={handleDeleteClick}
              color={Color.red}>
              <IconTrash />
            </ActionIcon>
          )}
        {O.isSome(journalEntry) && (
          <ActionIcon
            label="Download XLSX"
            onClick={() =>
              getAndDownloadXlsx(() =>
                journalEntriesReportExcel({
                  authorFilter: [],
                  filters: [],
                  firmIds: [],
                  fundIds: [],
                  journalEntryId: journalEntry.pipe(
                    O.map((j) => j.id),
                    O.getOrThrow,
                  ),
                  propertyIds: [],
                }),
              )
            }>
            <IconDownload />
          </ActionIcon>
        )}
        <ActionIcon label="Previous" onClick={() => loadPreviousTransaction()}>
          <IconChevronLeft />
        </ActionIcon>
        <ActionIcon label="Next" onClick={() => loadNextTransaction()}>
          <IconChevronRight />
        </ActionIcon>
      </Group>
      <ReverseJournalEntryModal
        row={reverseModalProps}
        closeModal={handleCloseReverseModal}
        refreshReversalData={refreshReversalData}
        refreshTransactionData={refreshTransactionData}
      />
    </>
  );
}

export { GeneralLedgerTransactionApprovalsTransactionApprovalMenu };
