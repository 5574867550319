/**
 * Bulk assign homes to an underwriter
 * HTTP Method: POST
 * Pathname: /buy/assignToUnderwriter
 * @function assignToUnderwriter
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPIAssignToUnderwriterPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnderwritingAPIAssignToUnderwriterSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPIAssignToUnderwriterBodyParams = {
  dealIds: EnderId[];
  /**
   * If this value is not present, then the homes will be unassigned.
   */
  underwriterId?: EnderId | undefined;
};

type UnderwritingAPIAssignToUnderwriterPayload =
  UnderwritingAPIAssignToUnderwriterSearchParams &
    UnderwritingAPIAssignToUnderwriterBodyParams;

function assignToUnderwriterUnsafeEffect(
  payload: UnderwritingAPIAssignToUnderwriterPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/buy/assignToUnderwriter",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function assignToUnderwriter(
  payload: UnderwritingAPIAssignToUnderwriterPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    assignToUnderwriterUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { assignToUnderwriter };
export type {
  UnderwritingAPIAssignToUnderwriterBodyParams,
  UnderwritingAPIAssignToUnderwriterPayload,
  UnderwritingAPIAssignToUnderwriterSearchParams,
};
