import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ModelSettingsSettingKeyValues = [
  "AUTO_GENERATE_PROSPECT_RESPONSE",
  "AUTOPAY",
  "CONFIRM_PARENT_ALLOCATIONS",
  "DISABLE_ELECTRONIC_PAYMENT_FOR_NEW_LEASES",
  "DISABLE_ACH_PAYMENT_FOR_NEW_LEASES",
  "ACH_REVERSAL_FEE",
  "CHARGE_TENANTS_CARD_DISPUTE_FEE",
  "CHARGE_PM_CARD_DISPUTE_FEE",
  "ENABLE_EMERGENCY_TASK_CREATION",
  "REQUIRE_INVOICE_NUMBER_FOR_TASK_INVOICES",
  "ENABLE_PERMISSION_TO_ENTER",
  "ENABLE_THIRD_PARTY_RECOVERY",
  "INVOICE_DELAY_DAYS",
  "NOTIFY_ACCEPTED_APPLICATION",
  "NOTIFY_SECONDARY_ON_DENIED",
  "NOTIFY_TENANTS_ON_ONLINE_PAYMENTS_TURNED_ON",
  "REQUIRE_APPLICANT_PAYSTUBS_AND_ID",
  "REQUIRE_APPLICANT_PET_DOCUMENTS",
  "SOFT_MINIMUM_CREDIT_SCORE_APPLICATIONS",
  "HARD_MINIMUM_CREDIT_SCORE_APPLICATIONS",
  "SOFT_MINIMUM_INCOME_TO_RENT_RATIO_APPLICATIONS",
  "HARD_MINIMUM_INCOME_TO_RENT_RATIO_APPLICATIONS",
  "REQUIRE_MOVE_IN_DEPOSIT_RECEIPT",
  "TENANT_CAN_UPDATE_VEHICLES_AND_PETS",
  "VENDOR_INSURANCE_REQUIRED",
  "VENDOR_JOB_COMPLETE_PHOTO_REQUIRED",
  "VENDOR_MAY_VIEW_TENANT_CONTACT_INFO",
  "ESTIMATE_APPROVAL_EMAIL",
  "NOTIFY_LEAD_OF_SUBMITTED_APPLICATION",
  "WARN_APPLICANT_ON_OTHER_APPLICATIONS",
  "DISABLE_VENDOR_NOTIFICATIONS",
  "SHOW_EMPLOYEE_LAST_NAMES",
  "AUTO_TRANSITION_LEASE_TO_ACTIVE_ON_START_DATE",
  "CHARGE_APPLICATION_FEE_ON_SCREENING",
  "CHARGE_APPLICATION_FEE_ON_SKIPPED_SCREENING",
  "AUTO_CREATE_RENEWAL_TASK",
  "AUTO_CREATE_TASK_WHEN_LISTING_CREATE_OR_REACTIVATE",
  "LEASING_FEE_AMOUNT",
  "LEASING_FEE_PERCENT",
  "TWO_STEP_REVIEW_FOR_APPLICATIONS",
  "WORK_ORDER_SURVEY_URL",
  "ENABLE_TASK_CONTACT_PHONE",
  "NUM_HOURS_UNTIL_MOVE_IN_BALANCE_DUE",
  "DISABLE_POSTING_SCHEDULED_CHARGES",
  "ALLOW_MULTIPLE_APPLICATIONS_IN_REVIEW",
  "ENABLE_RMIS_VENDOR_SYNCHRONIZATION",
  "RMIS_BASE_URL",
  "RMIS_CLIENT_ID",
  "RMIS_CLIENT_PASSWORD",
  "ENABLE_NEW_RMIS_VENDOR_APPROVAL",
  "ALLOW_PROSPECTS_TO_SCHEDULE_TOUR",
  "UNFINISHED_APPLICATION_NOTIFICATION_WAIT_HOURS",
  "PROSPECT_LISTED_UNIT_NOTIFICATION_TEMPLATE",
  "PROSPECT_LEASED_UNIT_NOTIFICATION_TEMPLATE",
  "APPLICATION_LEASE_EXECUTED_NOTIFICATION_TEMPLATE",
  "DEFAULT_RENEWAL_OFFER_TEMPLATE",
  "DEFAULT_FOLLOW_UP_RENEWAL_OFFER_TEMPLATE",
  "DEFAULT_NON_RENEWAL_TEMPLATE",
  "LIMITED_RENEWAL_OFFER_DEDUCTION_AMOUNT",
  "RENEWAL_OFFER_PROCESSING_FEE_PERCENT",
  "PROSPECT_AUTO_ARCHIVE_WAIT_DAYS",
  "FIND_RENTAL_HOME_LINK",
  "FAQ_LINK",
  "QUALIFICATION_REQUIREMENTS_LINK",
  "ENABLE_ESUSU_REPORTING",
  "REQUIRE_EMPLOYMENT_START_DATE",
  "SHOWING_NOTIFICATION_ADVANCE_HOURS",
  "PM_EMAIL_LOGO_URL",
  "FULL_PAYMENT_REQUIRED",
  "FULL_PAYMENT_REQUIRED_ADJUSTMENT",
  "FULL_PAYMENT_REQUIRED_GRACE_DAYS",
  "FG_AUTO_REVERSE_ACH_FAILURES",
  "FG_EARLIEST_FETCHABLE_RECOVERY_LEASE",
  "DEFAULT_INITIAL_CONSTRUCTION_INSPECTION_TEMPLATE",
  "DEFAULT_TURNOVER_INSPECTION_TEMPLATE",
  "DEFAULT_SPECIAL_DAMAGE_INSPECTION_TEMPLATE",
  "DEFAULT_CHANGE_ORDERS_INSPECTION_TEMPLATE",
  "DEFAULT_NEW_BUILDS_INSPECTION_TEMPLATE",
  "HOLDING_FEE_AMOUNT",
  "NOTIFY_MARKETING_CERTIFICATE_REJECTION",
  "VENDOR_PAYMENT_TERM_DAYS",
] as const;

const ModelSettingsSettingKeyEffectSchema = Schema.Literal(
  ...ModelSettingsSettingKeyValues,
);

type ModelSettingsSettingKey = Schema.Schema.Type<
  typeof ModelSettingsSettingKeyEffectSchema
>;

const ModelSettingsSettingKeyEnum = castEnum(
  ModelSettingsSettingKeyEffectSchema,
);

function randomModelSettingsSettingKey(): ModelSettingsSettingKey {
  return rand(ModelSettingsSettingKeyValues);
}

export {
  ModelSettingsSettingKeyEffectSchema,
  ModelSettingsSettingKeyEnum,
  ModelSettingsSettingKeyValues,
  randomModelSettingsSettingKey,
};
export type { ModelSettingsSettingKey };
