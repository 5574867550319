import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PayScoreScreeningGroupScreeningStatusValues = [
  "NOT_STARTED",
  "IN_PROGRESS",
  "COMPLETED",
] as const;

const PayScoreScreeningGroupScreeningStatusEffectSchema = Schema.Literal(
  ...PayScoreScreeningGroupScreeningStatusValues,
);

type PayScoreScreeningGroupScreeningStatus = Schema.Schema.Type<
  typeof PayScoreScreeningGroupScreeningStatusEffectSchema
>;

const PayScoreScreeningGroupScreeningStatusEnum = castEnum(
  PayScoreScreeningGroupScreeningStatusEffectSchema,
);

function randomPayScoreScreeningGroupScreeningStatus(): PayScoreScreeningGroupScreeningStatus {
  return rand(PayScoreScreeningGroupScreeningStatusValues);
}

export {
  PayScoreScreeningGroupScreeningStatusEffectSchema,
  PayScoreScreeningGroupScreeningStatusEnum,
  PayScoreScreeningGroupScreeningStatusValues,
  randomPayScoreScreeningGroupScreeningStatus,
};
export type { PayScoreScreeningGroupScreeningStatus };
