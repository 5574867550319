/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}/unitAreas
 * @function getUnitAreas
 * @memberof InspectionAPI
 * @param {InspectionAPIGetUnitAreasPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitAreasResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitAreasResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetUnitAreasPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIGetUnitAreasSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetUnitAreasPayload = InspectionAPIGetUnitAreasPathParams &
  InspectionAPIGetUnitAreasSearchParams;

function getUnitAreasUnsafeEffect(payload: InspectionAPIGetUnitAreasPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitAreasResponse>({
        body,
        decode: unsafeDecodeJsonResponse<UnitAreasResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}/unitAreas`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnitAreas(
  payload: InspectionAPIGetUnitAreasPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitAreasResponse> {
  return F.pipe(
    payload,
    getUnitAreasUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitAreas };
export type {
  InspectionAPIGetUnitAreasPathParams,
  InspectionAPIGetUnitAreasPayload,
  InspectionAPIGetUnitAreasSearchParams,
};
