/**
 * HTTP Method: POST
 * Pathname: /invoices/approve-and-pay-batch
 * @function approveAndPayBatch
 * @memberof InvoicesAPI
 * @param {InvoicesAPIApproveAndPayBatchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransfer[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TransferDetails } from "@ender/shared/generated/ender.api.accounting.request";
import type { MoneyTransfer } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIApproveAndPayBatchSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIApproveAndPayBatchBodyParams = {
  invoiceIds: EnderId[];
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
  transferDetails: TransferDetails;
};

type InvoicesAPIApproveAndPayBatchPayload =
  InvoicesAPIApproveAndPayBatchSearchParams &
    InvoicesAPIApproveAndPayBatchBodyParams;

function approveAndPayBatchUnsafeEffect(
  payload: InvoicesAPIApproveAndPayBatchPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransfer[]>({
        body,
        decode: unsafeDecodeJsonResponse<MoneyTransfer[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/approve-and-pay-batch",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function approveAndPayBatch(
  payload: InvoicesAPIApproveAndPayBatchPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransfer[]> {
  return F.pipe(
    payload,
    approveAndPayBatchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { approveAndPayBatch };
export type {
  InvoicesAPIApproveAndPayBatchBodyParams,
  InvoicesAPIApproveAndPayBatchPayload,
  InvoicesAPIApproveAndPayBatchSearchParams,
};
