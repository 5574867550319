import { IconInfoCircle } from "@tabler/icons-react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { Tooltip } from "@ender/shared/ds/tooltip";

function DuplicateInvoiceIcon() {
  return (
    <Tooltip label="This invoice number matches a different invoice issued by the same vendor.">
      <IconInfoCircle color={EnderThemeColorEnum.RED} size={18} />
    </Tooltip>
  );
}

export { DuplicateInvoiceIcon };
