import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import type { ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { Color } from "@ender/shared/utils/theming";

const LevelSchema = Schema.Literal("h1", "h2", "h3", "h4", "h5");
const LevelValues = LevelSchema.literals;
type Levels = Schema.Schema.Type<typeof LevelSchema>;
const Level = castEnum(LevelSchema);

/**
 * map heading types to heading tags for DOM rendering
 */
const headingLevelRenderMap: Record<Levels, "h1" | "h2" | "h3" | "h4" | "h5"> =
  {
    [Level.h1]: "h1",
    [Level.h2]: "h2",
    [Level.h3]: "h3",
    [Level.h4]: "h4",
    [Level.h5]: "h5",
  } as const;

const HeadingVariantGenerator = cva(["block"], {
  compoundVariants: [],
  defaultVariants: {
    color: Color.slate,
  },
  variants: {
    color: {
      [Color.slate]: "text-slate-900",
      inherit: "text-inherit",
      white: "text-white",
    },
    level: {
      [Level.h1]: "font-semibold text-1.5xl",
      [Level.h2]: "font-medium text-xl",
      [Level.h3]: "font-semibold text-base",
      [Level.h4]: "font-medium text-base",
      [Level.h5]: "font-medium text-xs",
    },
  },
});

type HeadingProps = {
  level: Levels;
  id?: string;
  /**
   * the color of the text. Defaults to "slate" but can be inverted ("white") or inherited ("inherit")
   */
  color?: typeof Color.slate | "white" | "inherit";
};

const Heading = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingProps>>(
  (
    props: PropsWithChildren<HeadingProps>,
    ref: ForwardedRef<HTMLHeadingElement>,
  ) => {
    const { children, level, id } = props;
    const HeadingTag = headingLevelRenderMap[level];
    return (
      <HeadingTag className={HeadingVariantGenerator(props)} ref={ref} id={id}>
        {children}
      </HeadingTag>
    );
  },
);
Heading.displayName = "Heading";

type HeadingLevelProps = Omit<HeadingProps, "level">;

const H1 = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingLevelProps>>(
  ({ children, id, color }, ref) => (
    <Heading level={Level.h1} ref={ref} id={id} color={color}>
      {children}
    </Heading>
  ),
);
H1.displayName = "H1";

const H2 = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingLevelProps>>(
  ({ children, id, color }, ref) => (
    <Heading level={Level.h2} ref={ref} id={id} color={color}>
      {children}
    </Heading>
  ),
);
H2.displayName = "H2";

const H3 = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingLevelProps>>(
  ({ children, id, color }, ref) => (
    <Heading level={Level.h3} ref={ref} id={id} color={color}>
      {children}
    </Heading>
  ),
);
H3.displayName = "H3";

const H4 = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingLevelProps>>(
  ({ children, id, color }, ref) => (
    <Heading level={Level.h4} ref={ref} id={id} color={color}>
      {children}
    </Heading>
  ),
);
H4.displayName = "H4";

const H5 = forwardRef<HTMLHeadingElement, PropsWithChildren<HeadingLevelProps>>(
  ({ children, id, color }, ref) => (
    <Heading level={Level.h5} ref={ref} id={id} color={color}>
      {children}
    </Heading>
  ),
);
H5.displayName = "H5";

export { H1, H2, H3, H4, H5, Heading, Level, LevelValues };

export type { HeadingProps, Levels };
