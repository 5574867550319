import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitCoolingSystemValues = [
  "NONE",
  "CENTRAL",
  "EVAPORATIVE",
  "GEOTHERMAL",
  "WALL",
  "SOLAR",
  "OTHER",
] as const;

const UnitCoolingSystemEffectSchema = Schema.Literal(
  ...UnitCoolingSystemValues,
);

type UnitCoolingSystem = Schema.Schema.Type<
  typeof UnitCoolingSystemEffectSchema
>;

const UnitCoolingSystemEnum = castEnum(UnitCoolingSystemEffectSchema);

function randomUnitCoolingSystem(): UnitCoolingSystem {
  return rand(UnitCoolingSystemValues);
}

export {
  randomUnitCoolingSystem,
  UnitCoolingSystemEffectSchema,
  UnitCoolingSystemEnum,
  UnitCoolingSystemValues,
};
export type { UnitCoolingSystem };
