import type { ColumnPinningState } from "@tanstack/react-table";
import { useState } from "react";

import type { EnderTableColumnPinningParams } from "../table.types";

type UseTableColumnPinningParams = {
  initialColumnPinning?: { left?: string; right?: string };
};

function useTableColumnPinning(
  params: UseTableColumnPinningParams = {},
): EnderTableColumnPinningParams {
  const { initialColumnPinning: { left, right } = {} } = params;

  const [columnPinning] = useState<ColumnPinningState>({
    left: left ? [left] : [],
    right: right ? [right] : [],
  });

  // This is a read-only state, so we don't need to return a setter function now since there is no way for the user to pin columns on the fly.
  // In the future when we add support for the user to pin columns on the fly, we will need to return a setter function here.
  return { columnPinning };
}

export { useTableColumnPinning };
