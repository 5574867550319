import { Schema } from "@effect/schema";
import { Option as O, String as S, pipe } from "effect";

import {
  EmailEffectSchema,
  LocalDateEffectSchema,
  LocalTimeEffectSchema,
  PhoneEffectSchema,
} from "@ender/form-system/schema";
import {
  EnderIdFormSchema,
  Instant$,
  LocalDate$,
  LocalTime$,
} from "@ender/shared/core";
import type { LeasingAPIAddLeadPayload } from "@ender/shared/generated/ender.api.leasing";
import type { GetLeadResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { LeadLeadSourceEffectSchema } from "@ender/shared/generated/ender.model.leasing";

const ProspectFormSchema = Schema.Struct({
  firstName: Schema.String,
  lastName: Schema.String,
  email: EmailEffectSchema,
  phone: PhoneEffectSchema,
  moveInDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  followUpDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  followUpTime: LocalTimeEffectSchema.pipe(Schema.OptionFromSelf),
  source: LeadLeadSourceEffectSchema.pipe(Schema.OptionFromSelf),
  propertyId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Property is required",
    }),
  ),
  unitId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  unitCount: Schema.Number,
}).pipe(
  Schema.filter((input) => {
    const issues: Schema.FilterIssue[] = [];

    // firstName is required
    if (S.isEmpty(input.firstName)) {
      issues.push({
        message: "First Name is required",
        path: ["firstName"],
      });
    }

    // lastName is required
    if (S.isEmpty(input.lastName)) {
      issues.push({
        message: "Last Name is required",
        path: ["lastName"],
      });
    }

    // Email or Phone is required
    if (S.isEmpty(input.email) && S.isEmpty(input.phone)) {
      issues.push({
        message: "Email or Phone is required",
        path: ["email"],
      });
      issues.push({
        message: "Email or Phone is required",
        path: ["phone"],
      });
    }

    // If followUpTime exists, followUpDate is required
    if (O.isSome(input.followUpTime) && O.isNone(input.followUpDate)) {
      issues.push({
        message: "Date is required if time is provided",
        path: ["followUpDate"],
      });
    }

    // If followUpTime exists, followUpTime must be in the future
    if (
      input.followUpDate.pipe(
        O.exists((date) => date.isEqual(LocalDate$.today())),
      ) &&
      input.followUpTime.pipe(O.exists((time) => time.isBefore(LocalTime$.now)))
    ) {
      issues.push({
        message: "Time must be in the future",
        path: ["followUpTime"],
      });
    }

    // If unitCount is greater than 0, unitId is required
    if (input.unitCount > 1 && O.isNone(input.unitId)) {
      issues.push({
        message: "Unit is required",
        path: ["unitId"],
      });
    }

    return issues;
  }),
);

type ProspectFormInput = Schema.Schema.Encoded<typeof ProspectFormSchema>;
type ProspectFormOutput = Schema.Schema.Type<typeof ProspectFormSchema>;

function getInitialFormValues(prospect?: GetLeadResponse): ProspectFormInput {
  const parsedFollowUpInstant = pipe(
    prospect?.followUpTime,
    O.fromNullable,
    O.flatMap((time) => Instant$.parse(time)),
  );

  return {
    firstName: prospect?.firstName || "",
    lastName: prospect?.lastName || "",
    email: prospect?.email || "",
    phone: prospect?.phone || "",
    moveInDate: LocalDate$.parse(prospect?.moveInDate),
    followUpDate: O.map(parsedFollowUpInstant, (instant) =>
      instant.toLocalDate(),
    ),
    followUpTime: O.map(parsedFollowUpInstant, (instant) =>
      instant.toLocalTime(),
    ),
    source: O.fromNullable(prospect?.source),
    propertyId: O.fromNullable(prospect?.propertyId),
    unitId: O.fromNullable(prospect?.unitId),
    unitCount: 0,
  };
}

function transformFormValues(
  values: ProspectFormOutput,
): LeasingAPIAddLeadPayload {
  const followUpTime = values.followUpDate.pipe(
    O.map((date) => date.toJSON()),
    O.flatMap((date) => Instant$.parse(date)),
    O.map((instant) =>
      O.match(values.followUpTime, {
        onSome: (time) =>
          instant.add({ hours: time.hour, minutes: time.minute }),
        onNone: () => instant,
      }),
    ),
    O.map((date) => date.toJSON()),
    O.getOrUndefined,
  );

  return {
    ...values,
    source: values.source.pipe(O.getOrUndefined),
    moveInDate: values.moveInDate.pipe(
      O.map((date) => date.toJSON()),
      O.getOrUndefined,
    ),
    followUpTime,
    propertyId: O.getOrThrow(values.propertyId),
    unitId: O.getOrUndefined(values.unitId),
  };
}

export { getInitialFormValues, ProspectFormSchema, transformFormValues };
export type { ProspectFormInput, ProspectFormOutput };
