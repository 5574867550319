/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/noRenew
 * @function noRenew
 * @memberof RenewalsAPI
 * @param {RenewalsAPINoRenewPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPINoRenewPathParams = {
  leaseId: EnderId;
};

type RenewalsAPINoRenewSearchParams = {
  token?: string | undefined;
};

type RenewalsAPINoRenewBodyParams = {
  templateId?: EnderId | undefined;
};

type RenewalsAPINoRenewPayload = RenewalsAPINoRenewPathParams &
  RenewalsAPINoRenewSearchParams &
  RenewalsAPINoRenewBodyParams;

function noRenewUnsafeEffect(payload: RenewalsAPINoRenewPayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/noRenew`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function noRenew(
  payload: RenewalsAPINoRenewPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    noRenewUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { noRenew };
export type {
  RenewalsAPINoRenewBodyParams,
  RenewalsAPINoRenewPathParams,
  RenewalsAPINoRenewPayload,
  RenewalsAPINoRenewSearchParams,
};
