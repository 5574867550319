import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InsurancePolicyInsuredPartyValues = [
  "PROPERTY",
  "TENANT",
  "VENDOR",
] as const;

const InsurancePolicyInsuredPartyEffectSchema = Schema.Literal(
  ...InsurancePolicyInsuredPartyValues,
);

type InsurancePolicyInsuredParty = Schema.Schema.Type<
  typeof InsurancePolicyInsuredPartyEffectSchema
>;

const InsurancePolicyInsuredPartyEnum = castEnum(
  InsurancePolicyInsuredPartyEffectSchema,
);

function randomInsurancePolicyInsuredParty(): InsurancePolicyInsuredParty {
  return rand(InsurancePolicyInsuredPartyValues);
}

export {
  InsurancePolicyInsuredPartyEffectSchema,
  InsurancePolicyInsuredPartyEnum,
  InsurancePolicyInsuredPartyValues,
  randomInsurancePolicyInsuredParty,
};
export type { InsurancePolicyInsuredParty };
