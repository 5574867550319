import { LocalDate$, Money$ } from "@ender/shared/core";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import type { InvoiceAllocationHistoryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import { Ellipsis } from "@ender/shared/ui/ellipsis";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { toSlashString } from "@ender/shared/utils/local-date";

import { GLAccountCell, PropertyCell } from "./allocations-table-columns";

import styles from "./allocations-table.module.css";

const columns: ColumnDef<InvoiceAllocationHistoryResponse>[] = [
  {
    accessorFn: ({ propertyFriendlyId, propertyId, propertyName }) => ({
      propertyFriendlyId,
      propertyId,
      propertyName,
    }),
    cell: (props) => (
      <PropertyCell
        property={{
          friendlyId: props.getValue().propertyFriendlyId,
          id: props.getValue().propertyId,
          name: props.getValue().propertyName,
        }}
      />
    ),
    header: "Property",
  },
  {
    accessorFn: ({ glAccountName, glAccountNumber }) => ({
      glAccountName,
      glAccountNumber,
    }),
    cell: (props) => (
      <GLAccountCell
        accountName={props.getValue().glAccountName}
        accountNumber={props.getValue().glAccountNumber}
      />
    ),
    header: () => <span style={{ whiteSpace: "nowrap" }}>GL Account</span>,
    id: "glAccount",
  },
  {
    accessorKey: "amount",
    cell: (props) => <MoneyDisplay value={Money$.parse(props.getValue())} />,
    className: styles.amountColumn,
    header: "Amount",
  },
  {
    accessorKey: "description",
    cell: (props) => <Ellipsis>{props.getValue()}</Ellipsis>,
    header: () => <span>Description</span>,
  },
  {
    accessorKey: "category",
    cell: (props) => <Ellipsis>{props.getValue()}</Ellipsis>,
    header: () => (
      <span style={{ whiteSpace: "nowrap" }}>Payables Category</span>
    ),
  },
  {
    accessorKey: "subCategory",
    cell: (props) => <Ellipsis>{props.getValue()}</Ellipsis>,
    header: () => <span style={{ whiteSpace: "nowrap" }}>Sub-Category</span>,
  },
  {
    accessorKey: "authorName",
    cell: (props) => <Ellipsis>{props.getValue()}</Ellipsis>,
    header: () => <span style={{ whiteSpace: "nowrap" }}>Author</span>,
  },
  {
    accessorKey: "timestamp",
    cell: (props) => {
      const dateString = toSlashString(
        LocalDate$.of(new Date(props.getValue())),
      );
      return <Ellipsis>{dateString}</Ellipsis>;
    },
    header: () => <span style={{ whiteSpace: "nowrap" }}>Timestamp</span>,
  },
];

export { columns };
