import type { SelectProps as MantineSelectPropsAll } from "@mantine/core/lib/Select/Select";
import { z } from "zod";

import { generateGeneralMantinePropsSchema } from "@ender/shared/forms/types/general";
import type { InputHeight } from "@ender/shared/types/ender-general";
import { InputHeightSchema } from "@ender/shared/types/ender-general";
import type { PartialPick } from "@ender/shared/types/general";
import type {
  LabelValue,
  ValuesOrLabelValues,
} from "@ender/shared/types/label-value";

import type { UseSelectReturn } from "./select.hooks";

type MantineSelectProps = Pick<
  MantineSelectPropsAll,
  | "autoComplete"
  | "clearable"
  | "creatable"
  | "description"
  | "disabled"
  | "error"
  | "getCreateLabel"
  | "hoverOnSearchChange"
  | "itemComponent"
  | "label"
  | "name"
  | "nothingFound"
  | "onBlur"
  | "onCreate"
  | "onFocus"
  | "onKeyDown"
  | "placeholder"
  | "required"
  | "searchable"
  | "withAsterisk"
  | "zIndex"
>;

const SelectBasePropsSchema = z.object({
  clearable: z.boolean().optional(),
  creatable: z.boolean().optional(),
  data: z.union([z.custom<LabelValue<string>>().array(), z.string().array()]),
  filter: z.function().args(z.unknown()).returns(z.boolean()).optional(),
  getCreateLabel: z.function().args(z.unknown()).returns(z.string()).optional(),
  height: InputHeightSchema.optional(),
  itemComponent: z.custom<MantineSelectPropsAll["itemComponent"]>().optional(),
  onCreate: z.custom<MantineSelectPropsAll["onCreate"]>().optional(),
  onKeyDown: z.custom<MantineSelectPropsAll["onKeyDown"]>().optional(),
  searchable: z.boolean().optional(),
  toFilter: z.array(z.unknown()).optional(),
  withAsterisk: z.boolean().optional(),
});
// not used to generate type because it's not generic
const SelectPropsSchema = generateGeneralMantinePropsSchema(z.unknown()).merge(
  SelectBasePropsSchema,
);

type SelectProps<T extends string> = PartialPick<
  UseSelectReturn<T>,
  "onSearchChange" | "searchValue"
> &
  MantineSelectProps & {
    /**leftSection instead of icon- it can be more general than an icon */
    leftSection?: MantineSelectPropsAll["icon"];
    testId?: string;
    showRightSection?: boolean;
    toFilter?: T[];
    data: LabelValue<T>[] | ValuesOrLabelValues<T>;
    filter?: (value: string, item: T) => boolean;
    height?: InputHeight;
  };

export { SelectBasePropsSchema, SelectPropsSchema };
export type { MantineSelectProps, SelectProps };
