/**
 * It is expected that the referenced bankingBatch is a deposit of tenant receipts. This endpoint cancels the batch
 deposit, reverting the moneyTransfers back to the IN_RECEIPTS state, so that they can be re-deposited.
 * HTTP Method: POST
 * Pathname: /deposits/{bankingBatchId}/cancel
 * @function cancelDeposit
 * @memberof PaymentsAPI
 * @param {PaymentsAPICancelDepositPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPICancelDepositPathParams = {
  /**
 * Must refer to a banking batch. This could refer to the ID of a row in the BankingBatch table,
                       or it could be the bankingBatchId of a moneyTransfer (i.e. an "unpersisted batch").
 */
  bankingBatchId: EnderId;
};

type PaymentsAPICancelDepositSearchParams = {
  token?: string | undefined;
};

type PaymentsAPICancelDepositPayload = PaymentsAPICancelDepositPathParams &
  PaymentsAPICancelDepositSearchParams;

function cancelDepositUnsafeEffect(payload: PaymentsAPICancelDepositPayload) {
  const { bankingBatchId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/deposits/${bankingBatchId}/cancel`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function cancelDeposit(
  payload: PaymentsAPICancelDepositPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    cancelDepositUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { cancelDeposit };
export type {
  PaymentsAPICancelDepositPathParams,
  PaymentsAPICancelDepositPayload,
  PaymentsAPICancelDepositSearchParams,
};
