// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { AutocompleteItem } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { UseFormReturnType } from "@mantine/form";
import { Function as F } from "effect";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";

import { usePrevious } from "@ender/shared/hooks/use-previous";





type UseEnderSearchParameters<SelectedType = unknown> = {
  // whether to clear the search bar on item select
  clearOnSelect?: boolean;
  // callback called by EnderSearch when clear X is clicked
  onClear?: (...args: unknown[]) => void;
  // callback called by EnderSearch when a dropdown item is selected
  onSelect?: (...args: SelectedType[]) => void;
  // starting value passed into hook
  value?: string;
};

type UseEnderSearchReturn = {
  // current value of the search input
  value?: string;
  // function passed to autocomplete input that changes current input value
  onChange: Dispatch<SetStateAction<string | undefined>>;
  // function passed to clear X
  onClear: (...args: unknown[]) => void;
  // function passed to autocomplete that is called when item is selected
  onSelect: (item?: AutocompleteItem) => void;
};

/**
 * useEnderSearch sets up the state and handlers needed to control EnderSearch
 * it allows access to value, onChange and onClear, so they can be used externally from EnderSearch
 *
 * If you want to manually clear the field, call onClear
 * If you want to change the value of the input field, call onChange with a string value
 *
 * Basic Example:
 *   ```const propertySearchProps = useEnderSearch({
 *     onSelect: onPropertyResultSelect,
 *   });
 *   <EnderSearch
 *    onSelect={propertySearchProps.onSelect}
 *    onChange={propertySearchProps.onChange}
 *    onClear={propertySearchProps.onClear}
 *    value={propertySearchProps.value}
 *   />```
 *
 * Change Input Example:
 *   ```const propertySearchProps = useEnderSearch({
 *     onSelect: onPropertyResultSelect,
 *   });
 *
 *   const manuallyChangeInputValue = () => {
 *     propertySearchProps.onChange('New Input Value')
 *   }
 *
 *   <EnderSearch
 *    onSelect={propertySearchProps.onSelect}
 *    onChange={propertySearchProps.onChange}
 *    onClear={propertySearchProps.onClear}
 *    value={propertySearchProps.value}
 *   />```
 *
 * @deprecated use entities/ender-search/api and plug the desired function in to entities/ender-search
 */
const useEnderSearch = <SelectedType = unknown,>({
  value: _value = "",
  clearOnSelect = false,
  onSelect: _onSelect = F.constVoid,
  onClear: _onClear = F.constVoid,
}: UseEnderSearchParameters<SelectedType>): UseEnderSearchReturn => {
  const [value, setValue] = useState<string | undefined>(_value);
  const oldValue = usePrevious(_value);

  const onSelect = useCallback(
    (item?: AutocompleteItem) => {
      if (clearOnSelect) {
        setValue("");
      }

      _onSelect(item?.result);
    },
    [_onSelect, clearOnSelect],
  );

  const onClear = useCallback(() => {
    setValue("");
    onSelect(undefined);
    _onClear();
  }, [onSelect, _onClear]);

  const onChange = useCallback<Dispatch<SetStateAction<string | undefined>>>(
    (action) => {
      if (!action) {
        onClear();
      } else {
        setValue(action);
      }
    },
    [onClear],
  );

  // lazy useEffect
  useEffect(() => {
    if (oldValue && _value !== oldValue && !_value) {
      onClear();
    }
  }, [_value, oldValue, onClear]);

  return { onChange, onClear, onSelect, value };
};

type UseEnderSearchFormProps<T> = {
  // form field name
  name: string;
  // form object returned from useForm
  form: UseFormReturnType<T>;
  // custom onSelect
  onSelect?: (result: unknown) => void;
};

/**
 *  @deprecated use entities/ender-search/api and plug the desired function in to entities/ender-search
 */
const useEnderSearchForm = <T,>({
  name,
  form,
  onSelect = F.constVoid,
}: UseEnderSearchFormProps<T>) => {
  const formFieldProps = form.getInputProps(name);
  return useEnderSearch({
    onClear: () => {
      // @ts-expect-error Argument of type 'undefined' is not assignable to parameter of type 'string extends keyof T ? T[keyof T & string] : unknown'.ts(2345)
      form.setFieldValue(name, undefined);
    },
    onSelect: (result) => {
      formFieldProps.onChange(result);
      onSelect(result);
    },
    value: formFieldProps.value?.name || "",
  });
};

export { useEnderSearch, useEnderSearchForm };
export type {
  UseEnderSearchFormProps,
  UseEnderSearchParameters,
  UseEnderSearchReturn,
};
