import { clsx } from "clsx";
import {
  addYears,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  startOfYear,
  subYears,
} from "date-fns";
import {
  Array as A,
  Function as F,
  HashMap as HM,
  Option as O,
  Predicate as P,
  pipe,
} from "effect";
import { useCallback, useId, useMemo } from "react";

import { LocalDate$ } from "@ender/shared/core";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";

import { Spacing } from "../../../../flex/src";
import { Stack } from "../../../../stack/src";
import { CalendarHeader } from "../shared-ds-calendar-header";

type MonthPickerProps = {
  /**
   * a function invoked when the user requests to go "up" a level of granularity
   * e.g. from days -> months -> years
   */
  goUp?: () => void;

  maxDate?: LocalDate$.LocalDate;
  minDate?: LocalDate$.LocalDate;
  /**
   * range is intentionally not supported.
   * We can approximate support for range by using multiple selection mode-
   * but in general, ranges are only possible to select at the day level.
   */
  mode?: "single" | "multiple";
  /**
   * will be invoked when the user selects a month.
   * Whatever is passed out will represent the true held value of the calendar.
   * This means if you get 1 date, you can store it in your state
   * if you get multiple dates, you can store them in your state
   *
   * no fancy mapping or toggling is required outside of this component
   */
  onChange: (
    value: O.Option<LocalDate$.LocalDate>[],
    lastPicked: O.Option<LocalDate$.LocalDate>,
  ) => void;
  /**
   * invoked when navigating between years
   */
  setYear: (value: LocalDate$.LocalDate) => void;

  value: O.Option<LocalDate$.LocalDate>[];
  /**
   * the year shown in the calendar
   */
  year: LocalDate$.LocalDate;
};

const MonthPicker = (props: MonthPickerProps) => {
  const {
    goUp,
    maxDate,
    minDate,
    mode = "single",
    onChange,
    setYear,
    value,
    year = pipe(
      value,
      A.head,
      O.flatten,
      O.getOrElse(() => LocalDate$.today()),
    ),
  } = props;

  const dateMap = useRefLatest(
    pipe(
      value,
      A.filterMap(F.identity),
      A.map((val) => [val.toJSON(), val] as const),
      HM.fromIterable,
    ),
  );

  const months = useMemo(() => {
    return eachMonthOfInterval({
      end: endOfYear(year.toDate()),
      start: startOfYear(year.toDate()),
    });
  }, [year]);

  const handleSelect = useCallback(
    (val: O.Option<LocalDate$.LocalDate>) => {
      if (O.isNone(val)) {
        return;
      }
      if (mode === "single") {
        return onChange(A.make(val), val);
      } else {
        if (HM.has(dateMap.current, O.getOrThrow(val).toJSON())) {
          onChange(
            pipe(
              dateMap.current,
              HM.remove(O.getOrThrow(val).toJSON()),
              HM.values,
              A.fromIterable,
              A.map(O.fromNullable),
            ),
            val,
          );
        } else {
          onChange(
            pipe(
              dateMap.current,
              HM.set(O.getOrThrow(val).toJSON(), O.getOrThrow(val)),
              HM.values,
              A.fromIterable,
              A.map(O.fromNullable),
            ),
            val,
          );
        }
      }
    },
    [mode, dateMap, onChange],
  );

  //the id that will be used by the label element
  const labelId = useId();

  return (
    <div className="w-63">
      <Stack spacing={Spacing.none}>
        <CalendarHeader
          id={labelId}
          labelText={format(year.toDate(), "yyyy")}
          period="Year"
          onLabelClick={goUp}
          onPrevClick={() => setYear(year.add({ years: -1 }))}
          onNextClick={() => setYear(year.add({ years: 1 }))}
          //is minDate after the end of the prior year?
          prevDisabled={
            P.isNotNullable(minDate) &&
            isAfter(minDate.toDate(), endOfYear(subYears(year.toDate(), 1)))
          }
          //is maxDate before the start of the next year?
          nextDisabled={
            P.isNotNullable(maxDate) &&
            isBefore(maxDate.toDate(), startOfYear(addYears(year.toDate(), 1)))
          }
        />
        <div className="grid grid-cols-3" role="grid" aria-labelledby={labelId}>
          {months.map((month) => (
            <button
              key={month.getTime()}
              onClick={() => handleSelect(LocalDate$.parse(month))}
              role="gridcell"
              //is our selected value in this month
              aria-selected={pipe(
                value,
                A.some((val) =>
                  pipe(
                    val,
                    O.exists((val) => isSameMonth(val.toDate(), month)),
                  ),
                ),
              )}
              disabled={
                (P.isNotNullable(minDate) &&
                  isBefore(endOfMonth(month), minDate.toDate())) ||
                (P.isNotNullable(maxDate) && isAfter(month, maxDate.toDate()))
              }
              className={clsx(
                "h-9 rounded hover:bg-primary-50 active:bg-primary-100",
                "aria-selected:bg-primary-500 aria-selected:text-white",
                "disabled:text-gray-200 disabled:pointer-events-none",
              )}>
              {format(month, "MMM")}
            </button>
          ))}
        </div>
      </Stack>
    </div>
  );
};

export { MonthPicker };
