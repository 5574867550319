import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";

type ApprovalDetailsProps = {
  approvalProcess: GetApprovalProcessResponse;
  invoice: InvoiceSerializerInvoiceResponse;
};

function ApprovalDetails({ approvalProcess, invoice }: ApprovalDetailsProps) {
  const approvalStep =
    approvalProcess?.steps.find(
      (step) => step.id === invoice.currentState.stepId,
    )?.name ?? "";

  return (
    <>
      <Group noWrap justify={Justify.between}>
        <H3>Approval Details</H3>
      </Group>
      <Stack spacing={Spacing.none}>
        <Tuple label="Approval Process" value={approvalProcess.name} />
        <Tuple label="Approval Step" value={approvalStep} />
      </Stack>
    </>
  );
}

export { ApprovalDetails };
