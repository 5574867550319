/**
 * HTTP Method: POST
 * Pathname: /collections/actions
 * @function takeAction
 * @memberof CollectionsAPI
 * @param {CollectionsAPITakeActionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsAction[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { CollectionsAction } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPITakeActionSearchParams = {
  token?: string | undefined;
};

type CollectionsAPITakeActionBodyParams = {
  isSkipAction?: boolean | undefined;
  lateFeeAmount?: SerializesInto<Money> | undefined;
  leaseId: EnderId;
  notes?: string | undefined;
  overrideAddressWarning?: boolean | undefined;
  stepId: EnderId;
};

type CollectionsAPITakeActionPayload = CollectionsAPITakeActionSearchParams &
  CollectionsAPITakeActionBodyParams;

function takeActionUnsafeEffect(payload: CollectionsAPITakeActionPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsAction[]>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsAction[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/collections/actions",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function takeAction(
  payload: CollectionsAPITakeActionPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsAction[]> {
  return F.pipe(
    payload,
    takeActionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { takeAction };
export type {
  CollectionsAPITakeActionBodyParams,
  CollectionsAPITakeActionPayload,
  CollectionsAPITakeActionSearchParams,
};
