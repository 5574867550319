import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useState } from "react";

import { ReportsAPI } from "@ender/shared/generated/ender.api.reports";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

type CityFilterProps = Omit<FilterComponentProps, "metadata" | "factor"> & {
  metadata: {
    placeholderOverride?: string;
  };
  factor?: WidgetFactor;
};

function getCityFilter(
  city: string | null,
  factor: WidgetFactor | undefined,
): WidgetFilter[] | undefined {
  if (P.isNull(city) || P.isNullable(factor)) {
    return undefined;
  }

  return [
    {
      factor,
      operator: DynamicTableOperatorsEnum.IN,
      values: [city],
    },
  ];
}

function getDefaultValue(widget: Widget): string | null {
  const defaultValue = widget.filters.find(
    ({ factor }) => factor.name === Factor.CITY,
  )?.values?.[0];
  return defaultValue ? defaultValue.toString() : null;
}

function CityFilter({
  disabled,
  factor,
  metadata,
  updateFilters,
  widget,
}: CityFilterProps) {
  const [city, setCity] = useState<string | null>(getDefaultValue(widget));

  const { data: cities = [], isInitialLoading } = useQuery<string[]>({
    enabled: !disabled,
    queryFn: async ({ signal }) => {
      if (P.isNullable(factor)) {
        return [];
      }
      const data = await ReportsAPI.getFactorMetadataForWidget(
        {
          factorIds: [factor.id],
          widgetId: widget.id,
        },
        { signal },
      );
      return Array.from(
        new Set<string>(
          data[factor.id].map(({ value }: { value: string }) => value),
        ).values(),
      ).sort();
    },
    queryKey: ["ReportsAPI.getFactorMetadata", widget.id, factor?.id] as const,
  });

  function handleChange(newCity: string | null) {
    setCity(newCity);

    const filters = getCityFilter(newCity, factor);

    updateFilters(Factor.CITY, filters);
  }

  const placeholder =
    metadata?.placeholderOverride ??
    (isInitialLoading ? "Loading..." : "Select a city");

  return (
    <Select
      label="City"
      clearable
      searchable
      data={cities}
      value={city}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={isInitialLoading || disabled}
    />
  );
}

export { CityFilter };
