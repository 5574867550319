/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/creditSecurityDeposit
 * @function creditSecurityDeposit
 * @memberof LeasingAPI
 * @param {LeasingAPICreditSecurityDepositPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPICreditSecurityDepositPathParams = {
  leaseId: EnderId;
};

type LeasingAPICreditSecurityDepositSearchParams = {
  token?: string | undefined;
};

type LeasingAPICreditSecurityDepositBodyParams = {
  amount?: SerializesInto<Money> | undefined;
  date: SerializesInto<LocalDate>;
  depositCategoryId?: EnderId | undefined;
};

type LeasingAPICreditSecurityDepositPayload =
  LeasingAPICreditSecurityDepositPathParams &
    LeasingAPICreditSecurityDepositSearchParams &
    LeasingAPICreditSecurityDepositBodyParams;

function creditSecurityDepositUnsafeEffect(
  payload: LeasingAPICreditSecurityDepositPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/creditSecurityDeposit`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function creditSecurityDeposit(
  payload: LeasingAPICreditSecurityDepositPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    creditSecurityDepositUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { creditSecurityDeposit };
export type {
  LeasingAPICreditSecurityDepositBodyParams,
  LeasingAPICreditSecurityDepositPathParams,
  LeasingAPICreditSecurityDepositPayload,
  LeasingAPICreditSecurityDepositSearchParams,
};
