import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Option as O, Order, Predicate as P } from "effect";
import { useCallback, useContext } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { UserContext } from "@ender/shared/contexts/user";
import { EnderIdFormSchema, LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { AccountingAPIUpdateRecurringGLJournalEntryPayload } from "@ender/shared/generated/ender.api.accounting";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { RecurringGLJournalEntryFrequency } from "@ender/shared/generated/ender.model.accounting";
import {
  RecurringGLJournalEntryFrequencyEffectSchema,
  RecurringGLJournalEntryFrequencyValues,
} from "@ender/shared/generated/ender.model.accounting";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

type RecurringJournalEntryEditFormProps = {
  recurringJournalEntry: GetRecurringGLJournalEntryDetailsResponse;
  onSuccess: () => void;
  onCancel: () => void;
};

const RecurringJournalEntryEditFormSchema = Schema.Struct({
  approver: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  endDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  frequency: RecurringGLJournalEntryFrequencyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<RecurringGLJournalEntryFrequency> =>
        O.isSome(input),
      {
        message: () => "Frequency is required",
      },
    ),
  ),
  startDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => O.isSome(input),
      {
        message: () => "Start Date is required",
      },
    ),
  ),
  title: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Description is required" }),
  ),
});

type RecurringJournalEntryEditFormInput = Schema.Schema.Encoded<
  typeof RecurringJournalEntryEditFormSchema
>;
type RecurringJournalEntryEditFormOutput = Schema.Schema.Type<
  typeof RecurringJournalEntryEditFormSchema
>;

function GeneralLedgerTransactionApprovalsEditRecurringJournalEntry({
  recurringJournalEntry,
  onSuccess,
  onCancel,
}: RecurringJournalEntryEditFormProps) {
  const {
    approvalStatus,
    approvalStepDisplay,
    approverId,
    authorDisplay,
    endDate,
    frequency,
    startDate,
    title,
  } = recurringJournalEntry;
  const { hasPermissions } = useContext(UserContext);

  const { data: approvers } = useQuery({
    queryFn: ({ signal }) =>
      ApprovalsAPI.getDefaultApprovalProcess(
        {
          approvalProcessType:
            ApprovalProcessTypeEnum.RECURRING_GL_JOURNAL_ENTRY,
        },
        { signal },
      ),
    queryKey: ["ApprovalsAPI.getDefaultApprovalProcess"] as const,
    select: (data) => {
      const approvalStep = data.steps.find(
        (step) => step.name === approvalStepDisplay,
      );
      return approvalStep?.approvers.map((approver) => ({
        label: `${approver.firstName} ${approver.lastName}`,
        value: approver.id,
      }));
    },
  });

  const form = useForm<RecurringJournalEntryEditFormInput>({
    defaultValues: {
      approver: O.fromNullable(approverId),
      endDate: P.isNotNullable(endDate) ? LocalDate$.parse(endDate) : O.none(),
      frequency: O.some(frequency),
      startDate: LocalDate$.parse(startDate),
      title: title,
    },
    mode: "onSubmit",
    resolver: effectTsResolver(RecurringJournalEntryEditFormSchema),
  });

  const { mutateAsync: updateRecurringJournalEntry, isLoading } = useMutation({
    mutationFn: (payload: AccountingAPIUpdateRecurringGLJournalEntryPayload) =>
      AccountingAPI.updateRecurringGLJournalEntry(payload),
    mutationKey: ["updateRecurringJournalEntry"],
  });

  const hasEditApproverPermission = hasPermissions(
    FunctionalPermissionEnum.SPECIFY_JOURNAL_ENTRY_APPROVER,
  );

  const handleSubmit = useCallback(
    async (values: RecurringJournalEntryEditFormOutput) => {
      const payload = {
        endDate: values.endDate.pipe(
          O.map((date) => date.toJSON()),
          O.getOrUndefined,
        ),
        frequency: values.frequency.pipe(O.getOrThrow),
        id: recurringJournalEntry.id,
        startDate: values.startDate.pipe(
          O.map((date) => date.toJSON()),
          O.getOrThrow,
        ),
        title: values.title,
        txs: recurringJournalEntry.allocations.map((allocation) => ({
          ...allocation,
          description: recurringJournalEntry.title,
        })),
      };

      await updateRecurringJournalEntry(
        hasEditApproverPermission
          ? { ...payload, approverId: values.approver.pipe(O.getOrUndefined) }
          : payload,
      );
      showSuccessNotification({ message: "Transaction updated" });
      await onSuccess();
    },
    [
      onSuccess,
      updateRecurringJournalEntry,
      hasEditApproverPermission,
      recurringJournalEntry.allocations,
      recurringJournalEntry.id,
      recurringJournalEntry.title,
    ],
  );

  const isStartDateInPast = Order.lessThan(LocalDate$.Order)(
    LocalDate$.of(startDate),
    LocalDate$.today(),
  );
  const isApproved = approvalStatus === ApprovableApprovalStatusEnum.APPROVED;

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack spacing={Spacing.md}>
        <FormTextInput form={form} label="Description" name="title" />
        <Tooltip label="Start date has passed" disabled={!isStartDateInPast}>
          <FormDateInput
            disabled={isStartDateInPast}
            form={form}
            label="Start Date"
            name="startDate"
            minDate={LocalDate$.today()}
          />
        </Tooltip>
        <FormDateInput
          form={form}
          label="End Date"
          name="endDate"
          minDate={LocalDate$.today()}
        />
        <FormSelect
          form={form}
          name="frequency"
          label="Frequency"
          placeholder="Select Frequency"
          data={RecurringGLJournalEntryFrequencyValues.map((value) => ({
            label: convertSnakeCaseToTitleCase(value),
            value: value,
          }))}
        />
        {hasEditApproverPermission && (
          <Tooltip
            label="Recurring journal entry has already been approved"
            disabled={!isApproved}>
            <FormSelect
              placeholder="Select Approver"
              label="Assigned Approver"
              form={form}
              data={
                approvers?.filter(
                  (approver) => approver.label !== authorDisplay,
                ) ?? []
              }
              name="approver"
              disabled={isApproved}
            />
          </Tooltip>
        )}
        <Group spacing={Spacing.md} justify={Justify.end}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="submit">
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { GeneralLedgerTransactionApprovalsEditRecurringJournalEntry };
