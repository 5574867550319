import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeaseUserRoleLeaseUserFlagValues = [
  "TENANT",
  "FINANCIALLY_RESPONSIBLE",
  "EMERGENCY",
  "PRIMARY_CONTACT",
  "IN_STORE",
  "CORPORATE",
  "SALES_FIGURES",
  "GUARANTOR",
  "FACILITIES",
  "ACCOUNTS_PAYABLE",
  "LEGAL",
  "HOUSING_CHOICE_VOUCHER",
] as const;

const LeaseUserRoleLeaseUserFlagEffectSchema = Schema.Literal(
  ...LeaseUserRoleLeaseUserFlagValues,
);

type LeaseUserRoleLeaseUserFlag = Schema.Schema.Type<
  typeof LeaseUserRoleLeaseUserFlagEffectSchema
>;

const LeaseUserRoleLeaseUserFlagEnum = castEnum(
  LeaseUserRoleLeaseUserFlagEffectSchema,
);

function randomLeaseUserRoleLeaseUserFlag(): LeaseUserRoleLeaseUserFlag {
  return rand(LeaseUserRoleLeaseUserFlagValues);
}

export {
  LeaseUserRoleLeaseUserFlagEffectSchema,
  LeaseUserRoleLeaseUserFlagEnum,
  LeaseUserRoleLeaseUserFlagValues,
  randomLeaseUserRoleLeaseUserFlag,
};
export type { LeaseUserRoleLeaseUserFlag };
