import { Option as O, Predicate as P, pipe } from "effect";
import * as S from "effect/String";
import { useCallback } from "react";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useForm } from "@ender/form-system/base";
import { NULL } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextarea } from "@ender/shared/ds/textarea";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { AccountingPeriod } from "@ender/shared/generated/ender.model.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import {
  DwollaTransferDwollaTransferStatusEnum,
  PartyEnum,
} from "@ender/shared/generated/ender.model.payments";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import type { TransferType } from "@ender/shared/types/ender-general";
import { TransferTypeEnum } from "@ender/shared/types/ender-general";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";

import { useVoidPayment } from "./use-void-payment";

const voidableTransferTypes: TransferType[] = [
  TransferTypeEnum.BANK_TRANSFER,
  TransferTypeEnum.DWOLLA_TRANSFER,
  TransferTypeEnum.MARK_PAID_CHECK,
  TransferTypeEnum.OTHER,
  TransferTypeEnum.PRINT_CHECK,
] as const;

function canVoid(invoice: InvoiceSerializerInvoiceResponse) {
  const isOwedToTenant = invoice.owedToParty.party === PartyEnum.LEASE;
  if (P.isNullable(invoice.paymentInfo) || isOwedToTenant) {
    return false;
  }

  const { dwollaTransferStatus } = invoice;

  if (
    P.isNotNullable(dwollaTransferStatus) &&
    dwollaTransferStatus !== DwollaTransferDwollaTransferStatusEnum.FAILED
  ) {
    return false;
  }

  return voidableTransferTypes.includes(invoice.paymentInfo.type);
}

type VoidPaymentFormValues = {
  accountingPeriod: O.Option<AccountingPeriod>;
  description: string;
  transactionDate: O.Option<LocalDate$.LocalDate>;
};

type VoidPaymentButtonProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  onVoidSuccess: () => void;
};

function VoidPaymentButton({ invoice, onVoidSuccess }: VoidPaymentButtonProps) {
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean();
  const confirmation = useConfirmationContext();

  const { mutateAsync: voidPayment, isLoading: isProcessing } = useVoidPayment({
    invoiceId: invoice.id,
    onSuccess: () => {
      showSuccessNotification({ message: "Payment voided successfully." });
      onVoidSuccess();
      closeModal();
    },
  });

  const form = useForm<VoidPaymentFormValues>({
    defaultValues: {
      accountingPeriod: O.none(),
      description: invoice.description || "",
      transactionDate: LocalDate$.parse(invoice.transactionDate),
    },
  });

  const handleSubmit = useCallback(
    async (values: VoidPaymentFormValues) => {
      await confirmation({
        confirmButtonLabel: "Void Payment",
        title: "Are you sure you want to void this payment?",
      });

      await voidPayment({
        accountingPeriod: pipe(
          values.accountingPeriod,
          O.map((period) => period.startDate),
          O.getOrUndefined,
        ),
        description: values.description,
        invoiceId: invoice.id,
        transactionDate: pipe(
          values.transactionDate,
          O.map((date) => date.toJSON()),
          O.getOrUndefined,
        ),
      });
    },
    [confirmation, invoice.id, voidPayment],
  );

  if (!canVoid(invoice)) {
    return NULL;
  }

  const hasVoidPaymentMessage = S.isNonEmpty(invoice.voidPaymentMessage);

  return (
    <>
      <Button
        disabled={
          invoice.paymentInfo?.isBankRecMatched || hasVoidPaymentMessage
        }
        disabledTooltip={
          hasVoidPaymentMessage
            ? invoice.voidPaymentMessage
            : "This payment cannot be voided because it has been matched in bank reconciliation."
        }
        variant={ButtonVariant.outlined}
        color={Color.red}
        onClick={openModal}>
        Void Payment
      </Button>
      <Modal opened={isModalOpen} onClose={closeModal} title="Void Payment">
        <Form form={form} onSubmit={handleSubmit}>
          <Stack>
            <FormDateInput
              form={form}
              name="transactionDate"
              label="Transaction Date"
              disabled={isProcessing}
              placeholder="Select date"
            />
            <FormAccountingPeriodSelector
              form={form}
              name="accountingPeriod"
              label="Accounting Period"
              placeholder="Select accounting period"
              periodType={
                invoice.invoiceType === InvoiceInvoiceTypeEnum.RECEIVABLE
                  ? AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE
                  : AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE
              }
              disabled={isProcessing}
            />
            <FormTextarea
              form={form}
              name="description"
              label="Reason for Voiding"
              disabled={isProcessing}
              placeholder="Enter reason"
            />
            <Group justify={Justify.between}>
              <Button
                variant={ButtonVariant.outlined}
                onClick={closeModal}
                disabled={isProcessing}>
                Cancel
              </Button>
              <Button type="submit" color={Color.red} loading={isProcessing}>
                Void Payment
              </Button>
            </Group>
          </Stack>
        </Form>
      </Modal>
    </>
  );
}

export { canVoid, VoidPaymentButton };
