import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BuyOfferOfferSourceValues = ["BUYSIDE", "SELLSIDE"] as const;

const BuyOfferOfferSourceEffectSchema = Schema.Literal(
  ...BuyOfferOfferSourceValues,
);

type BuyOfferOfferSource = Schema.Schema.Type<
  typeof BuyOfferOfferSourceEffectSchema
>;

const BuyOfferOfferSourceEnum = castEnum(BuyOfferOfferSourceEffectSchema);

function randomBuyOfferOfferSource(): BuyOfferOfferSource {
  return rand(BuyOfferOfferSourceValues);
}

export {
  BuyOfferOfferSourceEffectSchema,
  BuyOfferOfferSourceEnum,
  BuyOfferOfferSourceValues,
  randomBuyOfferOfferSource,
};
export type { BuyOfferOfferSource };
