import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { IconEdit } from "@tabler/icons-react";
import { Option as O, Predicate as P, pipe } from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useEffect, useId } from "react";
import { useWatch } from "react-hook-form";

import { Form, useForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import type { Instant } from "@ender/shared/core";
import { Instant$, LocalDate$, Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { Inset } from "@ender/shared/ds/inset";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type {
  ApplicationApplicantType,
  ApplicationGroup,
} from "@ender/shared/generated/ender.model.leasing";
import { ApplicationApplicantTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { noOpAsync } from "@ender/shared/utils/no-op";

const ApplicationFormSchema = Schema.Struct({
  applicationFeeAmount: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  moveInDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => O.isSome(input),
      {
        message: () => "Required",
      },
    ),
  ),
});
type ApplicationFormInput = Schema.Schema.Encoded<typeof ApplicationFormSchema>;
type ApplicationFormOutput = Schema.Schema.Type<typeof ApplicationFormSchema>;

type ApplicationSummaryCardProps = {
  applications: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "applicantType"
  >[];
  applicationGroup: Pick<
    ApplicationGroup,
    | "feeAmount"
    | "moveInDate"
    | "archiveTime"
    | "completedAt"
    | "acceptedAt"
    | "rejectedAt"
  >;
  isEditable?: boolean;
  onSubmit?: (values: ApplicationFormOutput) => Promise<void>;
  isWorking?: boolean;
};

const displayInstant = (instant: Instant | undefined) => {
  return pipe(
    Instant$.parse(instant),
    O.map((val) => val.toString()),
    O.getOrElse(() => "--"),
  );
};

const ApplicationSummaryCard = forwardRef<
  ElementRef<typeof Card>,
  ApplicationSummaryCardProps
>(function ApplicationSummaryCard(props, ref) {
  const {
    applications,
    applicationGroup,
    isEditable = false,
    onSubmit = noOpAsync,
    isWorking = false,
  } = props;
  const { feeAmount, moveInDate } = applicationGroup;
  const [editing, editingHandlers] = useBoolean(false);
  const applicantTypeCount = applications.reduce(
    (acc, { applicantType }) => {
      acc[applicantType] = (acc[applicantType] ?? 0) + 1;
      return acc;
    },
    {
      [ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT]: 0,
      [ApplicationApplicantTypeEnum.OTHER_OCCUPANT]: 0,
      [ApplicationApplicantTypeEnum.GUARANTOR]: 0,
    } as Record<ApplicationApplicantType, number>,
  );

  const form = useForm<ApplicationFormInput>({
    defaultValues: {
      applicationFeeAmount: Money$.parse(feeAmount),
      moveInDate: LocalDate$.parse(moveInDate),
    },
    mode: "onSubmit",
    resolver: effectTsResolver(ApplicationFormSchema),
  });
  const [watchedMoveInDate, watchedApplicationFeeAmount] = useWatch({
    control: form.control,
    name: ["moveInDate", "applicationFeeAmount"],
  });
  const formRef = useRefLatest(form);

  /**
   * Synchronize the form values with the incoming props
   */
  useEffect(() => {
    const newValues = {
      applicationFeeAmount: Money$.parse(feeAmount),
      moveInDate: LocalDate$.parse(moveInDate),
    };
    formRef.current.reset(newValues);
  }, [formRef, moveInDate, feeAmount]);

  const handleSubmit = useCallback(
    async (values: ApplicationFormOutput) => {
      await onSubmit(values);
      editingHandlers.setFalse();
    },
    [onSubmit, editingHandlers],
  );

  const headingId = useId();
  return (
    <Card ref={ref} labelledBy={headingId}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Group justify={Justify.between} align={Align.center}>
            <H4 id={headingId}>Application Summary</H4>
            {isEditable && (
              <Inset
                r={editing ? Spacing.none : Spacing.sm}
                t={Spacing.sm}
                b={Spacing.sm}>
                {editing ? (
                  <Group>
                    <Button
                      variant={ButtonVariant.transparent}
                      onClick={editingHandlers.setFalse}>
                      Cancel
                    </Button>
                    <Button type="submit" loading={isWorking}>
                      Save
                    </Button>
                  </Group>
                ) : (
                  <ActionIcon
                    variant={ButtonVariant.transparent}
                    onClick={editingHandlers.setTrue}
                    label="Edit">
                    <IconEdit />
                  </ActionIcon>
                )}
              </Inset>
            )}
          </Group>
          <Grid spacingY={Spacing.none} underline="uneven">
            <Tuple
              label="Responsible Occupants"
              value={applicantTypeCount.RESPONSIBLE_OCCUPANT}
            />
            <Tuple
              label="Other Occupants"
              value={applicantTypeCount.OTHER_OCCUPANT}
            />
            <Tuple label="Guarantors" value={applicantTypeCount.GUARANTOR} />
            <Tuple
              label={<span className="text-nowrap">Application Fee</span>}
              value={
                editing ? (
                  <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                    <FormMoneyInput
                      form={form}
                      name="applicationFeeAmount"
                      size="sm"
                    />
                  </Inset>
                ) : (
                  watchedApplicationFeeAmount.pipe(
                    O.map((val) => val.toFormatted()),
                    O.getOrElse(() => "--"),
                  )
                )
              }
            />
            <Tuple
              label={<span className="text-nowrap">Move in Request</span>}
              value={
                editing ? (
                  <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                    <FormDateInput form={form} name="moveInDate" size="sm" />
                  </Inset>
                ) : (
                  watchedMoveInDate.pipe(
                    O.map((val) => val.toFormatted()),
                    O.getOrElse(() => "--"),
                  )
                )
              }
            />
            {P.isNotNullable(applicationGroup.completedAt) && (
              <Tuple
                label="Application Submitted"
                value={displayInstant(applicationGroup.completedAt)}
              />
            )}
            {P.isNotNullable(applicationGroup.acceptedAt) && (
              <Tuple
                label="Application Approved"
                value={displayInstant(applicationGroup.acceptedAt)}
              />
            )}
            {P.isNotNullable(applicationGroup.rejectedAt) && (
              <Tuple
                label="Application Denied"
                value={displayInstant(applicationGroup.rejectedAt)}
              />
            )}
            {P.isNotNullable(applicationGroup.archiveTime) && (
              <Tuple
                label="Application Archived"
                value={displayInstant(applicationGroup.archiveTime)}
              />
            )}
          </Grid>
        </Stack>
      </Form>
    </Card>
  );
});

export { ApplicationFormSchema, ApplicationSummaryCard };

export type {
  ApplicationFormInput,
  ApplicationFormOutput,
  ApplicationSummaryCardProps,
};
