/**
 * HTTP Method: GET
 * Pathname: /collections/processes
 * @function getCollectionsProcesses
 * @memberof CollectionsAPI
 * @param {CollectionsAPIGetCollectionsProcessesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsProcessResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { CollectionsProcessResponse } from "@ender/shared/generated/ender.api.finance.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIGetCollectionsProcessesSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIGetCollectionsProcessesPayload =
  CollectionsAPIGetCollectionsProcessesSearchParams;

function getCollectionsProcessesUnsafeEffect(
  payload: CollectionsAPIGetCollectionsProcessesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsProcessResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsProcessResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/collections/processes",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCollectionsProcesses(
  payload: CollectionsAPIGetCollectionsProcessesPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsProcessResponse[]> {
  return F.pipe(
    payload,
    getCollectionsProcessesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCollectionsProcesses };
export type {
  CollectionsAPIGetCollectionsProcessesPayload,
  CollectionsAPIGetCollectionsProcessesSearchParams,
};
