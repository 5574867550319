import { Array as A, Function as F, String as S } from "effect";
import { useMemo } from "react";

import { NULL } from "@ender/shared/constants/general";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import type { EmptyObject } from "@ender/shared/types/general";

import type { EnderTable } from "../table.types";

function ClearFiltersButtonRenderer(props: {
  onClick: () => void;
  disabled?: boolean;
}) {
  const { onClick, disabled = false } = props;

  return (
    <Button
      variant={ButtonVariant.transparent}
      disabled={disabled}
      onClick={onClick}>
      Clear Filters
    </Button>
  );
}

function ClearFiltersButtonOld<
  RowData,
  Meta extends object = EmptyObject,
>(props: { table: EnderTable<RowData, Meta> }) {
  const { table } = props;
  const { onClearFilters = F.constVoid } = table.options.meta ?? {};
  const { columnFilters = [], sorting = [] } = table.getState();
  const canGlobalFilter = useMemo(
    () => table.getAllColumns().some((column) => column.getCanGlobalFilter()),
    [table],
  );

  return A.isEmptyArray(columnFilters) && A.isEmptyArray(sorting) ? (
    NULL
  ) : (
    <ClearFiltersButtonRenderer
      onClick={() => {
        table?.setColumnFilters([]);
        onClearFilters();
        table?.setSorting([]);
        if (canGlobalFilter) {
          table?.setGlobalFilter("");
        }
      }}
    />
  );
}

function ClearFiltersButton<RowData, Meta extends object>(props: {
  table: EnderTable<RowData, Meta>;
  onClick?: () => void;
}) {
  const { table, onClick = F.constVoid } = props;
  const columnFilters = table.getState().columnFilters || [];
  const globalFilter = table.getState().globalFilter;
  const canGlobalFilter = useMemo(
    () => table.getAllColumns().some((column) => column.getCanGlobalFilter()),
    [table],
  );

  return (
    <ClearFiltersButtonRenderer
      disabled={
        A.isEmptyArray(columnFilters) &&
        S.isString(globalFilter) &&
        S.isEmpty(globalFilter)
      }
      onClick={() => {
        table.setColumnFilters([]);
        if (canGlobalFilter) {
          table.setGlobalFilter("");
        }
        onClick();
      }}
    />
  );
}

export { ClearFiltersButton, ClearFiltersButtonOld };
