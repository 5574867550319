import { useQueryClient } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useMemo } from "react";
import { useStore } from "zustand";

import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { LedgerEventReversalButton } from "@ender/widgets/finance/ledger-event-reversal-button";

import { useGetLeaseId } from "../../../../../hooks";
import { useTenantLedgerStore } from "../../../../../tenant-ledger-store.context";
import { ReverseCreditForm } from "./reverse-credit-form";

function LedgerEventReverseCreditButton() {
  const leaseId = useGetLeaseId();
  const queryClient = useQueryClient();
  const tenantLedgerStore = useTenantLedgerStore();

  const [
    isReverseModalOpen,
    { setTrue: openReverseModal, setFalse: closeReverseModal },
  ] = useBoolean();
  const { ledgerEvent, setLedgerEvent } = useStore(
    tenantLedgerStore,
    (state) => ({
      ledgerEvent: state.selectedLedgerEvent,
      setLedgerEvent: state.setLedgerEvent,
    }),
  );

  const isReversedCredit = ledgerEvent.pipe(
    O.map((event) => event.isReversedCredit),
    O.getOrElse(() => false),
  );

  const isCreditReversal = ledgerEvent.pipe(
    O.map((event) => event.isCreditReversal),
    O.getOrElse(() => false),
  );

  async function handleSuccess() {
    await queryClient.invalidateQueries(["ledger", leaseId]);
    setLedgerEvent(O.none());
    closeReverseModal();
  }

  const isReversible = !isReversedCredit && !isCreditReversal;

  const disabledTooltip = useMemo(() => {
    if (isReversedCredit) {
      return "This credit has already been reversed.";
    }

    if (isCreditReversal) {
      return "This transaction reverses a previous one and cannot be reversed again.";
    }
  }, [isCreditReversal, isReversedCredit]);

  return (
    <>
      <LedgerEventReversalButton
        onClick={openReverseModal}
        disabled={!isReversible}
        disabledTooltip={disabledTooltip}>
        Reverse Credit
      </LedgerEventReversalButton>
      <Modal
        size={ModalSize.sm}
        title="Reverse Credit"
        onClose={closeReverseModal}
        opened={isReverseModalOpen}>
        <ReverseCreditForm onSuccess={handleSuccess} />
      </Modal>
    </>
  );
}

export { LedgerEventReverseCreditButton };
