import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const NeighborNeighborTypeValues = [
  "HOUSE",
  "VACANT_HOUSE",
  "LOT",
  "COMMERCIAL",
  "ROAD",
] as const;

const NeighborNeighborTypeEffectSchema = Schema.Literal(
  ...NeighborNeighborTypeValues,
);

type NeighborNeighborType = Schema.Schema.Type<
  typeof NeighborNeighborTypeEffectSchema
>;

const NeighborNeighborTypeEnum = castEnum(NeighborNeighborTypeEffectSchema);

function randomNeighborNeighborType(): NeighborNeighborType {
  return rand(NeighborNeighborTypeValues);
}

export {
  NeighborNeighborTypeEffectSchema,
  NeighborNeighborTypeEnum,
  NeighborNeighborTypeValues,
  randomNeighborNeighborType,
};
export type { NeighborNeighborType };
