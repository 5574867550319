import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const AccountingReportTypeValues = [
  "GENERAL_LEDGER",
  "BALANCE_SHEET",
  "INCOME_STATEMENT",
] as const;

const AccountingReportTypeEffectSchema = Schema.Literal(
  ...AccountingReportTypeValues,
);

type AccountingReportType = Schema.Schema.Type<
  typeof AccountingReportTypeEffectSchema
>;

const AccountingReportTypeEnum = castEnum(AccountingReportTypeEffectSchema);

function randomAccountingReportType(): AccountingReportType {
  return rand(AccountingReportTypeValues);
}

export {
  AccountingReportTypeEffectSchema,
  AccountingReportTypeEnum,
  AccountingReportTypeValues,
  randomAccountingReportType,
};
export type { AccountingReportType };
