import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";

function getLeaseTenantsString(
  lease: Pick<LeaseSerializerLeaseResponse, "contacts">,
  withParentheses = false,
): string {
  let tenantsString = "";

  if (lease.contacts) {
    tenantsString = lease.contacts[0]?.name || "No Lease Contacts";

    if (withParentheses) {
      tenantsString +=
        lease.contacts.length > 1 ? ` (+${lease.contacts.length - 1})` : "";
    } else {
      tenantsString +=
        lease.contacts.length > 1 ? ` +${lease.contacts.length - 1}` : "";
    }
  }

  return tenantsString;
}

export { getLeaseTenantsString };
