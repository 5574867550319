import { IconRefresh } from "@tabler/icons-react";
import { Predicate as P } from "effect";
import { useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";

type PlaidAutoUpdateProps = {
  details?: {
    hasPlaid: boolean;
    lastUpdate?: string;
  };
  accountId: EnderId;
  refreshTransactions: () => Promise<void>;
};

function PlaidAutoUpdate({
  details,
  accountId,
  refreshTransactions,
}: PlaidAutoUpdateProps) {
  const [isManuallyUpdatingFromPlaid, setIsManuallyUpdatingFromPlaid] =
    useState(false);

  async function manuallyUpdateFromPlaid() {
    try {
      setIsManuallyUpdatingFromPlaid(true);

      const resp = await BankingAPI.refreshPlaidTransactions({
        bankAccountId: accountId,
      });
      if (resp?.request_id) {
        showSuccessNotification({
          message: "Successfully refreshed transactions",
        });
        await refreshTransactions();
      } else if (resp?.error_code === "ITEM_LOGIN_REQUIRED") {
        fail(resp.error_message);
      }
    } catch (error) {
      fail(error);
    } finally {
      setIsManuallyUpdatingFromPlaid(false);
    }
  }

  if (!P.isNotNullable(details) || !details?.hasPlaid) {
    return <Text size={FontSize.sm}>Auto Update Not Enabled</Text>;
  }

  if (isManuallyUpdatingFromPlaid) {
    return <Text size={FontSize.sm}>Fetching new transactions</Text>;
  }

  return (
    <Group spacing={Spacing.sm}>
      <Stack align={Align.end} spacing={Spacing.none}>
        <Group justify={Justify.end}>
          <Text color="green-600" size={FontSize.sm}>
            Auto Update Enabled
          </Text>
        </Group>

        <Group spacing={Spacing.xs}>
          <Text size={FontSize.sm} weight={FontWeight.bold}>
            Last Updated:{" "}
          </Text>
          <Text size={FontSize.sm}>
            {details?.lastUpdate
              ? EnderDate.of(details.lastUpdate).toTimestampString()
              : "Not Available"}
          </Text>
        </Group>
      </Stack>
      <ActionIcon color={Color.primary} onClick={manuallyUpdateFromPlaid}>
        <IconRefresh />
      </ActionIcon>
    </Group>
  );
}

export { PlaidAutoUpdate };
