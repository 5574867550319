import type { TransformedValues } from "@mantine/form";
import { useForm, zodResolver } from "@mantine/form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Array as A, Option as O, Predicate as P } from "effect";
import { useMemo, useState } from "react";
import { z } from "zod";

import { addFilesToInvoice } from "@ender/shared/api/ledger";
import type { EnderId, LocalDate, Money } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import {
  GeneralLedgerAPI,
  TenantLedgerAPI,
} from "@ender/shared/generated/ender.api.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { cast } from "@ender/shared/types/cast";
import { AccountingPeriodSelector } from "@ender/shared/ui/accounting-period-selector";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { EnderDatePicker } from "@ender/shared/ui/ender-date-picker";
import { Select } from "@ender/shared/ui/select";
import { EnderDate, EnderDateSchema } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { CurrencySchema, OptionSchema } from "@ender/shared/utils/zod";

import { useGetGlCategories, useGetLeaseId } from "../../hooks";

function sortAZ(a: { name: string }, b: { name: string }) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  return 0;
}

//TODO: convert to DS Form system https://ender-1337.atlassian.net/browse/ENDER-22498 2025-02-18

const LeaseChargesFormSchema = z.object({
  amount: OptionSchema(CurrencySchema)
    .refine(O.isSome, { message: "Amount is required" })
    .refine(O.exists(Money$.isPositive), "Amount must be positive"),
  chargeType: z.string().min(1, "Charge type is required"),
  description: z.string().min(1, "Please add a description"),
  ledgerDate: EnderDateSchema.nullable().refine(P.isNotNullable, {
    message: "Date is required",
  }),
  periodId: z.string().nullable(),
});
type LeaseChargesFormInput = z.input<typeof LeaseChargesFormSchema>;

type LeaseChargesFormProps = {
  closeModal: () => void;
};

function LeaseChargesForm({ closeModal }: LeaseChargesFormProps) {
  const leaseId = useGetLeaseId();
  const [isProcessing, setIsProcessing] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const queryClient = useQueryClient();

  const { glCategories = [], isGlCategoriesFetching } = useGetGlCategories({
    excludeParentCategories: true,
  });
  const { data: chargeTypes = [], isLoading: isLoadingChargeTypes } = useQuery({
    queryFn: ({ signal }) =>
      GeneralLedgerAPI.getChargeScheduleCategories({ keyword: "" }, { signal }),
    queryKey: ["GeneralLedgerAPI.getChargeScheduleCategories"] as const,
    select: (data) => data.sort(sortAZ),
  });

  const form = useForm<LeaseChargesFormInput>({
    initialValues: {
      amount: O.none(),
      chargeType: "",
      description: "",
      ledgerDate: EnderDate.today,
      periodId: "",
    },
    validate: zodResolver(LeaseChargesFormSchema),
  });

  async function handleFormSubmit(values: TransformedValues<typeof form>) {
    try {
      setIsProcessing(true);

      const addChargeAndRefetch = async () => {
        const { amount, ledgerDate } = values;

        const data = {
          ...values,
          amount: cast<Money>(O.getOrThrow(amount).toFormatted()),
          chargeType: cast<EnderId>(values.chargeType),
          ledgerDate: cast<LocalDate>(
            EnderDate.of(ledgerDate).toLocalISOString(),
          ),
          periodId: cast<EnderId>(values.periodId),
        };

        const [{ id }] = await TenantLedgerAPI.createLeaseCharges({
          charges: [data],
          leaseId,
          requestId: "",
        });

        if (A.isNonEmptyArray(files)) {
          await addFilesToInvoice({ files, invoiceId: id });
        }

        await queryClient.invalidateQueries({ queryKey: ["getTenantLedger"] });
        await queryClient.invalidateQueries({
          queryKey: ["LeasingAPI.getLeaseDetails"],
        });

        showSuccessNotification({
          message: `${O.getOrThrow(amount).toFormatted()} has been charged to the tenant.`,
          title: "Charge created",
        });

        closeModal();
      };

      await addChargeAndRefetch();
    } catch (e) {
      fail(e);
    } finally {
      setIsProcessing(false);
    }
  }

  const chargeTypesList = useMemo(() => {
    if (!chargeTypes || A.isEmptyArray(chargeTypes)) {
      return [];
    }

    const nonParentCategoryIds = new Set(
      glCategories.map((category) => category.id),
    );

    return chargeTypes.reduce<{ label: string; value: EnderId }[]>(
      (acc, { id, name }) => {
        if (nonParentCategoryIds.has(id)) {
          acc.push({
            label: name,
            value: id,
          });
        }

        return acc;
      },
      [],
    );
  }, [chargeTypes, glCategories]);

  return (
    <div className="lease-charges-modal">
      <form
        onSubmit={form.onSubmit(handleFormSubmit)}
        aria-label="Add Lease Charge Form">
        <Stack>
          <H2>Add Lease Charge</H2>

          <EnderDatePicker
            label="Charge Date"
            showTodayButton
            {...form.getInputProps("ledgerDate")}
          />

          <AccountingPeriodSelector
            data-test-id="lease-charges-accounting-period"
            periodType={
              AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE
            }
            {...form.getInputProps("periodId")}
            disabled={isProcessing}
          />
          <Skeleton visible={isGlCategoriesFetching || isLoadingChargeTypes}>
            <Select
              label="Charge Type"
              placeholder="Choose a charge type"
              searchable
              data={chargeTypesList}
              {...form.getInputProps("chargeType")}
              disabled={isProcessing}
            />
          </Skeleton>
          <MoneyInput
            label="Amount"
            placeholder="0.00"
            {...form.getInputProps("amount")}
            disabled={isProcessing}
          />

          <TextInput
            label="Description (will be displayed to Tenant on ledger)"
            {...form.getInputProps("description")}
            disabled={isProcessing}
          />

          <FileInput
            value={files}
            onChange={setFiles}
            disabled={isProcessing}
          />
          <Group justify={Justify.end}>
            <Button type="submit" loading={isProcessing}>
              Add Charge
            </Button>
          </Group>
        </Stack>
      </form>
    </div>
  );
}

export { LeaseChargesForm };
