import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import { Stack } from "@ender/shared/ds/stack";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";

const EditLateFeeFormSchema = Schema.Struct({
  lateFee: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Some<Money$.Money> =>
        O.exists(input, Money$.isPositive),
      { message: () => "Late Fee must be greater than $0.00" },
    ),
  ),
  numGraceDays: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Grace Days is required" }),
  ),
});

type EditLateFeeFormType = Schema.Schema.Type<typeof EditLateFeeFormSchema>;

type EditLateFeeFormProps = {
  isUpdateLateFeeInProgress: boolean;
  lease: LeaseSerializerLeaseResponse;
  onClose: () => unknown;
  onFormSubmit: (values: EditLateFeeFormType) => unknown;
};

function EditLateFeeForm({
  isUpdateLateFeeInProgress,
  lease,
  onClose,
  onFormSubmit,
}: EditLateFeeFormProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      lateFee: Money$.parse(lease.lateFee),
      numGraceDays: O.fromNullable(lease.numGraceDays),
    },
    schema: EditLateFeeFormSchema,
  });
  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack spacing={Spacing.md}>
        <FormMoneyInput form={form} name="lateFee" label="Late Fee" />
        <FormNumberInput
          form={form}
          name="numGraceDays"
          label="Grace Days"
          precision={0}
        />
        <Group justify={Justify.end}>
          <Button variant={ButtonVariant.outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={isUpdateLateFeeInProgress}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditLateFeeForm };
export type { EditLateFeeFormType };
