// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@radix-ui/react-popover";
import { IconCalendar, IconX } from "@tabler/icons-react";
import { clsx } from "clsx";
import { format } from "date-fns";
import { Array as A, Option as O, Predicate as P, Tuple as T } from "effect";
import type { FocusEvent, PropsWithChildren } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { LocalDate$ } from "@ender/shared/core";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { ActionIcon } from "../../../action-icon/src";
import { ButtonVariant } from "../../../button/src";
import type { CalendarLevel } from "../../../calendar/src";
import { Calendar, CalendarLevelEnum } from "../../../calendar/src";
import type { InputBaseProps } from "../../../input/src";
import { Input } from "../../../input/src";

function WithinPortal(props: PropsWithChildren<{ withinPortal?: boolean }>) {
  const { withinPortal, children } = props;

  return withinPortal ? <Portal>{children}</Portal> : children;
}

/**
 * given the user's typed input, strip out all characters not allowed in a Date
 * The resulting string should be something we are comfortable to display in the UI.
 */
function sanitize(value: string) {
  const sanitized = value.replace(/[^\d\s/-]/g, ""); //filter out non-arithmetic characters
  // displayString = displayString.replace(/((?:[\d/]+){4})\//, "$1");
  // re-add slashes
  return sanitized.replace(/(\d+)[/\s-]+/g, (match, g1) => {
    return `${g1.padStart(2, "0")}/`;
  });
  // const displayString = sanitized.replace(/((?:\d+\/){2})(?:(\d+)\/)/, "$1$2");
  // return displayString;
}

function fromUserInput(
  value: string,
  minDate?: LocalDate$.LocalDate,
  maxDate?: LocalDate$.LocalDate,
): O.Option<LocalDate$.LocalDate> {
  const pairs = value.match(/\d{1,2}/g);
  if (P.isNotNullable(pairs) && T.isTupleOfAtLeast(pairs, 2)) {
    const [month, date, ...yearArr] = pairs;
    if (
      P.isNullable(date) ||
      Number.parseInt(month) > 12 ||
      Number.parseInt(date) > 31
    ) {
      return O.none();
    }
    let year = "";
    if (!A.isArray(yearArr) || A.isEmptyArray(yearArr)) {
      year = `${new Date().getFullYear()}`;
    } else if (A.isNonEmptyArray(yearArr)) {
      // [20, 24]
      year = `${yearArr[0]}${yearArr[1] ?? ""}`;
    } else {
      const yearEnd = parseInt(yearArr[0]);
      const century = Math.floor(new Date().getFullYear() / 100);
      //anything past 40 years from now (such as "65") is assumed to mean "1965" rather than "2065"
      if (yearEnd - 40 > new Date().getFullYear() % 100) {
        //assume last century
        year = `${century - 1}${`${yearEnd}`.padStart(2, "0")}`;
      } else {
        year = `${century}${`${yearEnd}`.padStart(2, "0")}`;
      }
    }

    const parsedDate = LocalDate$.clamp(
      LocalDate$.of(`${year}-${month}-${date}`),
      {
        maximum: maxDate,
        minimum: minDate,
      },
    );
    return O.some(parsedDate);
  }
  return O.none();
}

function getDisplayFormat(calendarLevel?: CalendarLevel): string {
  switch (calendarLevel) {
    case CalendarLevelEnum.year:
      return "yyyy";
    case CalendarLevelEnum.month:
      return "MMMM yyyy";
    case CalendarLevelEnum.day:
    default:
      return "MM/dd/yyyy";
  }
}

type DateInputProps = {
  calendarLevel?: CalendarLevel;
  clearable?: boolean;
  closeOnChange?: boolean;
  maxDate?: LocalDate$.LocalDate;
  minDate?: LocalDate$.LocalDate;
  onChange: (value: O.Option<LocalDate$.LocalDate>) => void;
  value: O.Option<LocalDate$.LocalDate>;
  /**  @deprecated */
  withinPortal?: boolean;
} & Omit<InputBaseProps, "leftSection" | "rightSection">;

const DateInput = forwardRef<
  HTMLInputElement,
  PropsWithChildren<DateInputProps>
>(function DateInput(props, ref) {
  const {
    borderless,
    calendarLevel,
    clearable = false,
    closeOnChange = true,
    description,
    disabled,
    error,
    label,
    maxDate,
    minDate,
    name,
    onChange,
    placeholder,
    role = "textbox",
    size,
    textAlign,
    value,
    withinPortal = false,
  } = props;

  const [open, openHandlers] = useBoolean(false);

  /**
   * a reference to the root element of the popover component.
   * Used to check if a click event occurred inside the popover component, so that the popover does not close when the user clicks
   * any of the relevant elements within.
   */
  const popoverRoot = useRef<HTMLSpanElement>(null);

  /**
   * whether the user is currently typing in the field or not.
   * If this is true, we want to prevent incoming value changes
   * from replacing the user's typed input
   */
  const isEditing = useRef(false);

  /**
   * converts the LocalDate to a formatted, slash-separated string
   */
  const getDisplayString = useCallback(
    (value: LocalDate$.LocalDate) => {
      return format(value.toDate(), getDisplayFormat(calendarLevel));
    },
    [calendarLevel],
  );

  /**
   * the display string representing the user's typed input.
   */
  const [display, setDisplay] = useState<string>(() =>
    value.pipe(
      O.map(getDisplayString),
      O.getOrElse(() => ""),
    ),
  );

  /**
   * react to value changes from the parent state management
   */
  useEffect(() => {
    if (!isEditing.current) {
      setDisplay(
        value.pipe(
          O.map(getDisplayString),
          O.getOrElse(() => ""),
        ),
      );
    }
  }, [value, getDisplayString]);

  /**
   * handles the user typing. What this achieves:
   * 1. instantly update the display value with the user's input
   * 2. sanitize and compute the LocalDate value based on the user's input
   * 3. update the LocalDate value via the onChange prop
   */
  const handleTextInput = useCallback(
    (val: string) => {
      //immediately prevent invalid characters from being displayed
      const sanitized = sanitize(val);

      try {
        setDisplay(sanitized);

        onChange(fromUserInput(sanitized, minDate, maxDate));
      } catch {
        onChange(O.none());
      }
    },
    [onChange, minDate, maxDate],
  );

  function handleFocus(e: FocusEvent<HTMLInputElement>) {
    isEditing.current = true;
    //highlight the user's input when the field receives focus for any reason
    e.currentTarget.select();
  }

  //the only purpose of this is to reformat the displayValue when the user clicks away. The value is unchanged.
  const handleBlur = useCallback(() => {
    isEditing.current = false;
    setDisplay(
      value.pipe(
        O.map(getDisplayString),
        O.getOrElse(() => ""),
      ),
    );
  }, [value, getDisplayString]);

  const handleSelectDay = useCallback(
    (date: A.NonEmptyArray<O.Option<LocalDate$.LocalDate>>) => {
      onChange(A.headNonEmpty(date));
      if (closeOnChange) {
        openHandlers.setFalse();
      }
    },
    [onChange, closeOnChange, openHandlers],
  );

  const showX = clearable && O.isSome(value);

  return (
    <Popover open={!disabled && open}>
      <PopoverTrigger
        onFocus={openHandlers.setTrue}
        asChild
        disabled={disabled}>
        <span
          ref={popoverRoot}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Escape" || e.key === "Tab") {
              openHandlers.setFalse();
              (e.target as HTMLElement).blur?.();
            }
          }}>
          <Input
            borderless={borderless}
            description={description}
            disabled={disabled}
            error={error}
            label={label}
            leftSection={<IconCalendar />}
            rightSection={
              showX && (
                <ActionIcon
                  variant={ButtonVariant.transparent}
                  disabled={disabled}
                  label="Clear"
                  onClick={() => {
                    setDisplay("");
                    onChange(O.none());
                  }}>
                  <IconX />
                </ActionIcon>
              )
            }
            onBlur={handleBlur}
            onChange={handleTextInput}
            onFocus={handleFocus}
            placeholder={placeholder}
            ref={ref}
            role={role}
            size={size}
            textAlign={textAlign}
            value={display}
            name={name}
          />
        </span>
      </PopoverTrigger>
      <WithinPortal withinPortal={withinPortal}>
        <PopoverContent
          sideOffset={4}
          onOpenAutoFocus={(event) => event.preventDefault()}
          onCloseAutoFocus={(event) => event.preventDefault()}
          className={clsx(
            "border border-primary-300 bg-white max-h-80 overflow-auto rounded will-change-transform z-10",
            "data-[state=closed]:hidden data-[state=open]:block",
          )}
          onInteractOutside={(e) => {
            if (
              P.isNotNullable(e.currentTarget) &&
              popoverRoot.current?.contains(e.currentTarget as Node)
            ) {
              return;
            }
            openHandlers.setFalse();
          }}>
          <Calendar
            value={A.ensure(value)}
            onChange={handleSelectDay}
            minDate={minDate}
            maxDate={maxDate}
            level={calendarLevel}
          />
        </PopoverContent>
      </WithinPortal>
    </Popover>
  );
});

export { DateInput, fromUserInput };

export type { DateInputProps };
