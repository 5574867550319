import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import type { MouseEvent } from "react";
import { useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import {
  InvoicesAPI,
  PaymentsAPI,
} from "@ender/shared/generated/ender.api.accounting";
import { PaymentDetailsSingleInvoice } from "@ender/widgets/finance/invoice-view";

import { SummaryOfInvoicesTable } from "./summary-of-invoices-table";

type BatchInvoiceDetailsProps = {
  moneyTransferIds: EnderId[];
  onClickInvoice: (
    invoiceId: EnderId,
    e: MouseEvent<HTMLAnchorElement>,
  ) => void;
};

function BatchInvoiceDetails({
  moneyTransferIds,
  onClickInvoice,
}: BatchInvoiceDetailsProps) {
  const { data: linkedInvoices, isLoading: isLoadingMoneyTransferInvoices } =
    useQuery({
      queryKey: [
        "PaymentsAPI.getMoneyTransferInvoices",
        moneyTransferIds,
      ] as const,
      queryFn: ({ signal }) => {
        return PaymentsAPI.getInvoicesForMoneyTransfers(
          { moneyTransferIds },
          { signal },
        );
      },
      initialData: [],
    });
  const { data: exemplaryInvoice, isLoading: isLoadingInvoices } = useQuery({
    queryKey: ["InvoicesAPI.getInvoice", linkedInvoices?.[0]?.id] as const,
    queryFn: ({ signal }) => {
      return InvoicesAPI.getInvoice(
        { invoiceId: linkedInvoices?.[0].id },
        { signal },
      );
    },
    enabled: A.isNonEmptyArray(linkedInvoices),
  });

  // TODO unknown if isMultipleProperties is and will always be true in this batch-invoice-details-modal context
  const isMultipleProperties = useMemo(
    () => new Set(linkedInvoices.map((invoice) => invoice.propertyId)).size > 1,
    [linkedInvoices],
  );

  return (
    <Stack>
      <Skeleton visible={isLoadingInvoices}>
        {/* PaymentDetailsSingleInvoice isn't built to handle undefined, so don't even attempt to render it to appease typescript */}
        {!P.isNullable(exemplaryInvoice) && (
          <PaymentDetailsSingleInvoice
            invoice={exemplaryInvoice}
            overridePropertyName={isMultipleProperties && "Multiple Properties"}
          />
        )}
      </Skeleton>

      <Skeleton visible={isLoadingMoneyTransferInvoices && isLoadingInvoices}>
        <SummaryOfInvoicesTable
          linkedInvoices={linkedInvoices}
          onClickInvoice={onClickInvoice}
        />
      </Skeleton>
    </Stack>
  );
}

export { BatchInvoiceDetails };
