import { zodResolver } from "@mantine/form";
import type { UseFormInput } from "@mantine/form/lib/types";
import { useMutation } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useCallback, useMemo } from "react";
import { z } from "zod";

import { NULL } from "@ender/shared/constants/general";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { ContextAwareInput } from "@ender/shared/forms/ui/context-aware-input";
import { ManagedForm } from "@ender/shared/forms/ui/managed-form";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { ApplicationGroup } from "@ender/shared/generated/ender.model.leasing";
import { EnderDatePicker } from "@ender/shared/ui/ender-date-picker";
import { EnderDate, EnderDateSchema } from "@ender/shared/utils/ender-date";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const MoveInFormSchema = z.object({
  moveInDate: EnderDateSchema.nullable().refine(P.isNotNullable, {
    message: "Required",
  }),
});

type MoveInFormInput = z.input<typeof MoveInFormSchema>;
type MoveInFormOutput = z.output<typeof MoveInFormSchema>;

type UpdateMoveInFormProps = {
  applicationGroup: ApplicationGroup;
  onCancel: () => void;
  onSuccess: () => void;
};

function UpdateMoveInForm({
  applicationGroup,
  onSuccess,
  onCancel,
}: UpdateMoveInFormProps) {
  const formProps = useMemo<UseFormInput<MoveInFormInput>>(
    () => ({
      initialValues: {
        moveInDate: applicationGroup?.moveInDate
          ? EnderDate.of(applicationGroup.moveInDate)
          : NULL,
      },
      validate: zodResolver(MoveInFormSchema),
    }),
    [applicationGroup?.moveInDate],
  );

  const { mutateAsync: updateApplication, isLoading: isSubmitting } =
    useMutation({
      mutationFn: async (values: MoveInFormOutput) => {
        //@ts-expect-error EnderDate is serialized to LocalDate
        await ApplicationsAPI.updateApplication({
          applicationGroupId: applicationGroup?.id,
          ...values,
        });
      },
      mutationKey: ["updateApplication", applicationGroup?.id],
    });

  const handleSubmit = useCallback(
    async (values: MoveInFormOutput) => {
      await updateApplication(values);
      showSuccessNotification({ message: `Move-in request date updated` });
      onSuccess();
    },
    [onSuccess, updateApplication],
  );

  return (
    //@ts-expect-error form's validated output is MoveInFormOutput, and compatible with handleSubmit
    <ManagedForm formProps={formProps} onSubmit={handleSubmit}>
      <Stack>
        <ContextAwareInput
          Component={EnderDatePicker}
          name="moveInDate"
          props={{
            label: "Move-in Request Date",
            placeholder: "Enter Move-in Request Date",
            disabled: isSubmitting,
          }}
        />
        <Group justify={Justify.end} align={Align.center}>
          <Button
            onClick={onCancel}
            type="button"
            variant={ButtonVariant.transparent}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit">
            Save
          </Button>
        </Group>
      </Stack>
    </ManagedForm>
  );
}

export { UpdateMoveInForm };
