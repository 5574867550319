/**
 * HTTP Method: PATCH
 * Pathname: /construction/inspections/{inspectionId}/projectManager
 * @function updateInspectionProjectManager
 * @memberof InspectionAPI
 * @param {InspectionAPIUpdateInspectionProjectManagerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIUpdateInspectionProjectManagerPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIUpdateInspectionProjectManagerSearchParams = {
  token?: string | undefined;
};

type InspectionAPIUpdateInspectionProjectManagerBodyParams = {
  managerId?: EnderId | undefined;
};

type InspectionAPIUpdateInspectionProjectManagerPayload =
  InspectionAPIUpdateInspectionProjectManagerPathParams &
    InspectionAPIUpdateInspectionProjectManagerSearchParams &
    InspectionAPIUpdateInspectionProjectManagerBodyParams;

function updateInspectionProjectManagerUnsafeEffect(
  payload: InspectionAPIUpdateInspectionProjectManagerPayload,
) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "PATCH" }),
        pathname: `/construction/inspections/${inspectionId}/projectManager`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateInspectionProjectManager(
  payload: InspectionAPIUpdateInspectionProjectManagerPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateInspectionProjectManagerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateInspectionProjectManager };
export type {
  InspectionAPIUpdateInspectionProjectManagerBodyParams,
  InspectionAPIUpdateInspectionProjectManagerPathParams,
  InspectionAPIUpdateInspectionProjectManagerPayload,
  InspectionAPIUpdateInspectionProjectManagerSearchParams,
};
