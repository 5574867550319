/**
 * Get the Autopay limit for a tenant
 * HTTP Method: GET
 * Pathname: /autopaySettings
 * @function getAutopaySettings
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetAutopaySettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetAutopaySettingsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetAutopaySettingsResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetAutopaySettingsSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetAutopaySettingsPayload =
  PaymentsAPIGetAutopaySettingsSearchParams;

function getAutopaySettingsUnsafeEffect(
  payload: PaymentsAPIGetAutopaySettingsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetAutopaySettingsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetAutopaySettingsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/autopaySettings",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAutopaySettings(
  payload: PaymentsAPIGetAutopaySettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetAutopaySettingsResponse> {
  return F.pipe(
    payload,
    getAutopaySettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAutopaySettings };
export type {
  PaymentsAPIGetAutopaySettingsPayload,
  PaymentsAPIGetAutopaySettingsSearchParams,
};
