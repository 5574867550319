/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/searchShowingsTable
 * @function searchShowingsTable
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPISearchShowingsTablePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchShowingsTableResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SearchShowingsTableResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { GetShowingsRequestGetShowingsActiveTab } from "@ender/shared/generated/ender.api.leasing.request";
import type { ShowingReservationStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPISearchShowingsTableSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPISearchShowingsTableBodyParams = {
  activeTab: GetShowingsRequestGetShowingsActiveTab;
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  propertyIds: EnderId[];
  prospectKeyword?: string | undefined;
  sortAscending?: boolean | undefined;
  status: ShowingReservationStatus[];
};

type LeasingMiddleLayerAPISearchShowingsTablePayload =
  LeasingMiddleLayerAPISearchShowingsTableSearchParams &
    LeasingMiddleLayerAPISearchShowingsTableBodyParams;

function searchShowingsTableUnsafeEffect(
  payload: LeasingMiddleLayerAPISearchShowingsTablePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchShowingsTableResponse>({
        body,
        decode: unsafeDecodeJsonResponse<SearchShowingsTableResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/leasing/searchShowingsTable",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchShowingsTable(
  payload: LeasingMiddleLayerAPISearchShowingsTablePayload,
  options?: { signal?: AbortSignal },
): Promise<SearchShowingsTableResponse> {
  return F.pipe(
    payload,
    searchShowingsTableUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchShowingsTable };
export type {
  LeasingMiddleLayerAPISearchShowingsTableBodyParams,
  LeasingMiddleLayerAPISearchShowingsTablePayload,
  LeasingMiddleLayerAPISearchShowingsTableSearchParams,
};
