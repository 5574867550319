import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const HvacHvacSystemTypeValues = ["SPLIT", "PACKAGED"] as const;

const HvacHvacSystemTypeEffectSchema = Schema.Literal(
  ...HvacHvacSystemTypeValues,
);

type HvacHvacSystemType = Schema.Schema.Type<
  typeof HvacHvacSystemTypeEffectSchema
>;

const HvacHvacSystemTypeEnum = castEnum(HvacHvacSystemTypeEffectSchema);

function randomHvacHvacSystemType(): HvacHvacSystemType {
  return rand(HvacHvacSystemTypeValues);
}

export {
  HvacHvacSystemTypeEffectSchema,
  HvacHvacSystemTypeEnum,
  HvacHvacSystemTypeValues,
  randomHvacHvacSystemType,
};
export type { HvacHvacSystemType };
