import { useState } from "react";

import { Button } from "@ender/shared/ds/button";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Textarea } from "@ender/shared/ds/textarea";

function ApproveHomePurchaseForm({
  onApprove,
}: {
  onApprove: (prop: string) => void;
}) {
  const [comment, setComment] = useState("");

  return (
    <Stack>
      <H2>Approve</H2>
      <Textarea
        label="Comment"
        name="comment"
        value={comment}
        onChange={(e) => setComment(e)}
        data-autofocus
      />
      <Button onClick={() => onApprove(comment)}>Approve</Button>
    </Stack>
  );
}

export { ApproveHomePurchaseForm };
