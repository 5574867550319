import { exportInvestmentApproval } from "@ender/shared/api/files";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { DealStatusPipelineStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";
import { getAndDownloadBlobData } from "@ender/shared/utils/general";

const investmentExportableStatuses: string[] = [
  DealStatusPipelineStatusEnum.OFFER,
  DealStatusPipelineStatusEnum.DUE_DILIGENCE,
  DealStatusPipelineStatusEnum.PRE_REPAIR,
  DealStatusPipelineStatusEnum.CONSTRUCTION,
  DealStatusPipelineStatusEnum.MARKETING,
  DealStatusPipelineStatusEnum.STABILIZED,
  DealStatusPipelineStatusEnum.OCCUPIED,
];

type ExportInvestmentTemplateButtonProps = {
  dealId: EnderId;
  status: string;
};

function ExportInvestmentTemplateButton({
  dealId,
  status,
}: ExportInvestmentTemplateButtonProps) {
  async function exportInvestmentTemplate() {
    //temporary workaround for Werner's REST system fixes
    getAndDownloadBlobData(() => exportInvestmentApproval({ dealId }), {
      message: "Downloading XLSX...",
    });
  }

  if (!investmentExportableStatuses.includes(status)) {
    return null;
  }

  return (
    <Button onClick={exportInvestmentTemplate}>
      Export Investment Template
    </Button>
  );
}

export { ExportInvestmentTemplateButton };
