/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/cancel
 * @function cancelLease
 * @memberof LeasingAPI
 * @param {LeasingAPICancelLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPICancelLeasePathParams = {
  leaseId: EnderId;
};

type LeasingAPICancelLeaseSearchParams = {
  token?: string | undefined;
};

type LeasingAPICancelLeasePayload = LeasingAPICancelLeasePathParams &
  LeasingAPICancelLeaseSearchParams;

function cancelLeaseUnsafeEffect(payload: LeasingAPICancelLeasePayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/cancel`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function cancelLease(
  payload: LeasingAPICancelLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    cancelLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { cancelLease };
export type {
  LeasingAPICancelLeasePathParams,
  LeasingAPICancelLeasePayload,
  LeasingAPICancelLeaseSearchParams,
};
