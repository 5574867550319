/**
 * HTTP Method: POST
 * Pathname: /reports/{reportId}/pages
 * @function createNewPage
 * @memberof ReportsAPI
 * @param {ReportsAPICreateNewPagePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportPage>}
 */
import { Effect, Function as F } from "effect";

import type { ReportPage } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPICreateNewPagePathParams = {
  reportId: string;
};

type ReportsAPICreateNewPageSearchParams = {
  token?: string | undefined;
};

type ReportsAPICreateNewPagePayload = ReportsAPICreateNewPagePathParams &
  ReportsAPICreateNewPageSearchParams;

function createNewPageUnsafeEffect(payload: ReportsAPICreateNewPagePayload) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportPage>({
        body,
        decode: unsafeDecodeJsonResponse<ReportPage>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/reports/${reportId}/pages`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createNewPage(
  payload: ReportsAPICreateNewPagePayload,
  options?: { signal?: AbortSignal },
): Promise<ReportPage> {
  return F.pipe(
    payload,
    createNewPageUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createNewPage };
export type {
  ReportsAPICreateNewPagePathParams,
  ReportsAPICreateNewPagePayload,
  ReportsAPICreateNewPageSearchParams,
};
