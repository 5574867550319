import { useQuery } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";

import type { EnderId } from "@ender/shared/core";
import { InspectionAPI } from "@ender/shared/generated/com.ender.construction.api.inspection";
import { InspectionInspectionStatusEnum } from "@ender/shared/generated/com.ender.construction.model.inspection";

function useHasOngoingInspectionQuery(unitId: EnderId) {
  const { data: inspectionSearchResponse } = useQuery({
    queryFn: () =>
      InspectionAPI.inspectionSearch({
        filterBy: {
          statuses: [
            InspectionInspectionStatusEnum.ASSIGNING_MANAGER,
            InspectionInspectionStatusEnum.PLANNING,
            InspectionInspectionStatusEnum.BUDGET_REVIEW,
            InspectionInspectionStatusEnum.IN_REVISION,
          ],
          unitIds: [unitId],
          marketIds: [],
          propertyIds: [],
          projectManagerIds: [],
          types: [],
        },
        sortBy: [],
      }),
    queryKey: ["InspectionAPI.inspectionSearch", { unitId }],
  });
  const hasOngoingInspection = F.pipe(
    inspectionSearchResponse?.totalResults,
    O.fromNullable,
    O.getOrElse(F.constant(0)),
    (x) => x > 0,
  );
  return hasOngoingInspection;
}

export { useHasOngoingInspectionQuery };
