import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SearchServiceSearchTypeValues = [
  "PROPERTY",
  "PROPERTY_ADDRESS",
  "UNIT",
  "VENDOR",
  "TENANT",
  "MANAGER",
  "INVESTOR",
  "PORTFOLIO",
  "TASK",
  "FIRM",
  "PROPERTY_SERVICE",
  "PROSPECT",
  "PROSPECT_ARCHIVE_REASON",
  "INVOICE",
  "JOURNAL_ENTRY",
  "MARKET",
  "LEASE",
  "PAGE",
  "APPLICATION",
  "DEAL",
] as const;

const SearchServiceSearchTypeEffectSchema = Schema.Literal(
  ...SearchServiceSearchTypeValues,
);

type SearchServiceSearchType = Schema.Schema.Type<
  typeof SearchServiceSearchTypeEffectSchema
>;

const SearchServiceSearchTypeEnum = castEnum(
  SearchServiceSearchTypeEffectSchema,
);

function randomSearchServiceSearchType(): SearchServiceSearchType {
  return rand(SearchServiceSearchTypeValues);
}

export {
  randomSearchServiceSearchType,
  SearchServiceSearchTypeEffectSchema,
  SearchServiceSearchTypeEnum,
  SearchServiceSearchTypeValues,
};
export type { SearchServiceSearchType };
