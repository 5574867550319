import { Schema } from "@effect/schema";
import { useQuery } from "@tanstack/react-query";
import { Option as O, pipe } from "effect";

import type { KeywordSearch } from "@ender/entities/search-input";
import { FormSearchInput } from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, LocalDate$, LocalTime$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FormTimeInput } from "@ender/shared/ds/time-input";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetLeadResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { SearchAPI } from "@ender/shared/generated/ender.api.misc";
import { SearchServiceSearchTypeEnum } from "@ender/shared/generated/ender.service.search";
import { fail } from "@ender/shared/utils/error";

const ShowingModalFormSchema = Schema.Struct({
  date: LocalDateEffectSchema.pipe(Schema.OptionFromSelf).pipe(
    Schema.filter(O.isSome, { message: () => "Showing date is required" }),
  ),
  time: Schema.instanceOf(LocalTime$)
    .pipe(Schema.OptionFromSelf)
    .pipe(
      Schema.filter(O.isSome, { message: () => "Showing time is required" }),
    ),
  unit: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
}).pipe(
  Schema.filter(({ date, time }) => {
    const issues: Schema.FilterIssue[] = [];

    if (
      O.exists(date, (v) => v.isBeforeOrEqual(LocalDate$.today())) &&
      O.exists(time, (v) => v.isBeforeOrEqual(LocalTime$.now))
    ) {
      issues.push({
        message: "Showing time must be in the future",
        path: ["time"],
      });
    }
    return issues;
  }),
);

type ShowingModalFormOutput = Schema.Schema.Type<typeof ShowingModalFormSchema>;

const searchUnitsWithin =
  (propertyId: EnderId): KeywordSearch<EnderId> =>
  (keyword: string) =>
    SearchAPI.omnisearch({
      excludeIds: [],
      keyword,
      propertyIds: [propertyId],
      resultsOnEmpty: true,
      types: [SearchServiceSearchTypeEnum.UNIT],
    }).then((res) =>
      res.map((unit) => ({
        label: unit.name,
        meta: unit,
        value: unit.id,
      })),
    );

function ProspectShowingModal({
  prospect,
  onSuccess,
}: {
  prospect: GetLeadResponse;
  onSuccess: () => void;
}) {
  const { data: singleUnitId, isFetched } = useQuery({
    queryKey: ["get-property", prospect.propertyId],
    queryFn: () =>
      PropertiesAPI.getProperty({ propertyId: prospect.propertyId }),
    select: (data) => (data.units.length === 1 ? data.units[0].id : undefined),
  });

  const form = useEffectSchemaForm({
    defaultValues: {
      date: O.some(LocalDate$.today()),
      unit: O.fromNullable(prospect.unitId),
      time: O.none(),
    },
    schema: ShowingModalFormSchema,
  });

  async function handleSubmit(values: ShowingModalFormOutput) {
    const { date, time, unit } = values;
    try {
      await LeasingAPI.addShowing({
        date: pipe(
          date,
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
        leadId: prospect.leadId,
        prospectId: prospect.userId,
        time: pipe(
          time,
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
        unitId: pipe(
          unit,
          O.orElse<EnderId>(() => O.fromNullable(singleUnitId)),
          O.getOrThrow,
        ),
      });
      onSuccess();
    } catch (err) {
      fail(err);
    }
  }

  return (
    <Skeleton visible={!isFetched}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack spacing={Spacing.md}>
          {!singleUnitId && (
            <FormSearchInput
              form={form}
              name="unit"
              label="Unit"
              modelType={ModelTypeEnum.UNIT}
              placeholder="Search for unit"
              search={searchUnitsWithin(prospect.propertyId)}
              clearable
            />
          )}
          <FormDateInput
            form={form}
            label="Showing Date"
            name="date"
            minDate={LocalDate$.today()}
          />
          <FormTimeInput form={form} label="Showing Time" name="time" />
          <Group justify={Justify.end}>
            <Button type="submit">Save</Button>
          </Group>
        </Stack>
      </Form>
    </Skeleton>
  );
}

export { ProspectShowingModal };
