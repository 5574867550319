/**
 * HTTP Method: POST
 * Pathname: /insurances/{insuranceId}
 * @function updateInsurance
 * @memberof InsuranceAPI
 * @param {InsuranceAPIUpdateInsurancePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InsuranceAPIUpdateInsurancePathParams = {
  insuranceId: EnderId;
};

type InsuranceAPIUpdateInsuranceSearchParams = {
  token?: string | undefined;
};

type InsuranceAPIUpdateInsuranceBodyParams = {
  carrier?: string | undefined;
  coverageAmount?: SerializesInto<Money> | undefined;
  deductible?: SerializesInto<Money> | undefined;
  effectiveDate?: SerializesInto<LocalDate> | undefined;
  expirationDate?: SerializesInto<LocalDate> | undefined;
  insuranceType?: string | undefined;
  monthlyPremium?: SerializesInto<Money> | undefined;
  policyNumber?: string | undefined;
};

type InsuranceAPIUpdateInsurancePayload =
  InsuranceAPIUpdateInsurancePathParams &
    InsuranceAPIUpdateInsuranceSearchParams &
    InsuranceAPIUpdateInsuranceBodyParams;

function updateInsuranceUnsafeEffect(
  payload: InsuranceAPIUpdateInsurancePayload,
) {
  const { insuranceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/insurances/${insuranceId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateInsurance(
  payload: InsuranceAPIUpdateInsurancePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateInsuranceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateInsurance };
export type {
  InsuranceAPIUpdateInsuranceBodyParams,
  InsuranceAPIUpdateInsurancePathParams,
  InsuranceAPIUpdateInsurancePayload,
  InsuranceAPIUpdateInsuranceSearchParams,
};
