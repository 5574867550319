import { cva } from "class-variance-authority";
import { Function as F } from "effect";
import * as S from "effect/String";
import type { ChangeEvent, ForwardedRef, KeyboardEvent } from "react";
import { forwardRef, useCallback, useId } from "react";

import type { InputProps } from "../../../input/src";
import { InputWrapper } from "../../../input/src";

type TextareaProps = {
  value: string;
  onChange: (value: string) => void;
  /**
   * text that displays as a suggested value after the user's entered text.
   */
  suggestion?: string;
  /**
   * Callback function that is invoked when the user presses the tab key while a suggestion is present.
   */
  onCommitSuggestion?: () => void;
  /**
   * the minimum number of rows the textarea will display, even if the content is less than that.
   * @default 3
   */
  minRows?: number;
  /**
   * the maximum number of rows the textarea can display at once before showing a scrollbar.
   * @default 5
   */
  maxRows?: number;
  placeholder?: string;
} & Omit<
  InputProps,
  | "value"
  | "onChange"
  | "onBlur"
  | "onFocus"
  | "inputType"
  | "leftSection"
  | "rightSection"
>;

const textClasses = "text-sm/snug whitespace-break-spaces";

const InputContainerVariantGenerator = cva(
  ["rounded overflow-auto", textClasses],
  {
    compoundVariants: [
      {
        class: "border-gray-100 bg-gray-50 text-gray-300",
        disabled: true,
        invalid: [true, false],
      },
      {
        class: "border-red-500",
        disabled: false,
        invalid: true,
      },
      {
        class: "border-gray-300",
        disabled: false,
        invalid: false,
      },
    ],
    defaultVariants: {
      borderless: false,
      disabled: false,
    },
    variants: {
      borderless: {
        false: "border",
        true: "",
      },
      disabled: {
        false: "focus-within:border-primary-500",
        true: "border-gray-100 bg-gray-50 text-gray-300",
      },
      invalid: {
        false: "",
        true: "",
      },
    },
  },
);

const TextareaVariantGenerator = cva(
  [
    "py-2.5 px-4 absolute inset-0 resize-none overflow-hidden block w-full bg-transparent",
    "placeholder:text-gray-300",
    textClasses,
    "outline-none focus:outline-none focus-visible:outline-none",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      disabled: false,
    },
    variants: {
      disabled: {
        false: "text-slate-900",
        true: "text-gray-300",
      },
    },
  },
);

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(
    props: TextareaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) {
    const {
      borderless,
      description,
      disabled,
      error,
      label,
      maxRows = 5,
      minRows = 3,
      name,
      onChange,
      onCommitSuggestion = F.constVoid,
      placeholder = "",
      suggestion = "",
      value,
    } = props;

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
      },
      [onChange],
    );

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Tab" && S.isNonEmpty(suggestion.trim())) {
          onCommitSuggestion();
          e.preventDefault();
        }
      },
      [onCommitSuggestion, suggestion],
    );

    const idBase = useId();
    const labelId = `${idBase}-label`;
    const descriptionId = `${idBase}-description`;
    const errorId = `${idBase}-error`;
    const describedBy = `${error ? errorId : ""} ${S.isNonEmpty(description?.trim() ?? "") ? descriptionId : ""}`;

    const attributes = {
      borderless,
      disabled,
      invalid: Boolean(error),
    };
    return (
      <InputWrapper
        inputId={idBase}
        labelId={labelId}
        descriptionId={descriptionId}
        errorId={errorId}
        label={label}
        error={error}
        description={description}>
        <div
          className={InputContainerVariantGenerator(attributes)}
          //magic numbers: 0.875rem is the font size, 1.375 is the line height, 0.625rem is the padding on top and bottom
          style={{
            maxHeight: `calc(${maxRows} * ${0.875 * 1.375}rem + 2 * 0.625rem)`,
          }}
          data-scroll-container>
          {/* This div autosizes based on its contents. This is what allows the "growing textarea" trick to work */}
          <div
            className="relative"
            style={{
              minHeight: `calc(${minRows} * ${0.875 * 1.375}rem + 2 * 0.625rem)`,
            }}>
            <div
              className="text-[0px] text-gray-300 py-2.5 px-4 pointer-events-none"
              role="presentation">
              {/* this " " is necessary due to a quirk of inline elements next to each other. Do not remove it */}
              <span
                className={`invisible ${textClasses}`}>{`${value}\u200B`}</span>{" "}
              {S.isNonEmpty(suggestion) && (
                <>
                  <span className={`mr-1 ${textClasses}`}>{suggestion}</span>{" "}
                  <span className="inline-block text-xxs/none border rounded-sm border-gray-300 bg-gray-50 px-0.5 py-px">
                    Tab
                  </span>
                </>
              )}
            </div>
            <textarea
              ref={ref}
              value={value}
              aria-labelledby={labelId}
              aria-describedby={describedBy}
              aria-invalid={Boolean(error)}
              id={idBase}
              disabled={disabled}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className={TextareaVariantGenerator(attributes)}
              placeholder={S.isEmpty(suggestion) ? placeholder : ""}
              name={name}
            />
          </div>
        </div>
      </InputWrapper>
    );
  },
);

export { Textarea };

export type { TextareaProps };
