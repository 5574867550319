import { Predicate as P } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";

import { getInvoicePartyIds } from "../get-invoice-party-ids";

type GetFromToBatchKeyWithSideEffectsInvoice = {
  fundId?: EnderId;
  type: InvoiceInvoiceType;
  owedByParty?: {
    id?: EnderId;
  };
  owedToParty?: {
    id?: EnderId;
  };
};

/**
 * Get from-to batch keys with side effects
 * - returns <fromId>-to-<toId> string
 * - side effects: logs error if `ourId` is nullable
 *   - In AP: we support payments to parties without id (eg external merchants), but not from parties without id
 *   - In AR: we may support payments from parties without id (eg external merchants), but not to unknown parties
 */
function getFromToBatchKeyWithSideEffects(
  invoice: GetFromToBatchKeyWithSideEffectsInvoice,
): string {
  const ourFundId = invoice.fundId;
  const fromPartyId = invoice.owedByParty?.id;
  const toPartyId = invoice.owedToParty?.id;

  const { fromId, ourId, toId } = getInvoicePartyIds({
    fromPartyId,
    invoiceType: invoice.type,
    ourFundId,
    toPartyId,
  });

  if (P.isNullable(ourId)) {
    console.error(
      "This should never happen: attempted to build batch without `our ID`",
    );
  }

  return `${fromId}-to-${toId}`;
}

export { getFromToBatchKeyWithSideEffects };
export type { GetFromToBatchKeyWithSideEffectsInvoice };
