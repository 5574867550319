import { Array as A, Predicate as P } from "effect";

import type { Options } from "./rest";

type WarningHandlerError = {
  errors: string[];
  warnings: string[];
};

type WithWarningHandlerParamsOnWarnings = (warnings: string[]) => Promise<void>;

function withWarningHandler<
  T extends { overrideWarnings?: boolean },
  R = unknown,
>(
  api: (payload: T, options?: Options) => Promise<R>,
  onWarnings: WithWarningHandlerParamsOnWarnings,
): (
  payload: Omit<T, "overrideWarnings"> & { overrideWarnings?: boolean },
  options?: Options,
) => Promise<R> {
  return async (
    payload: Omit<T, "overrideWarnings"> & { overrideWarnings?: boolean },
    options?: Options,
  ): Promise<R> => {
    try {
      // @ts-expect-error T can be instantiated with a different subtype, but we know it won't here
      return await api({ overrideWarnings: false, ...payload }, options);
    } catch (error) {
      if (P.isNullable(error)) {
        throw new Error("API failed without warnings or errors");
      }
      const { errors, warnings } = error as WarningHandlerError;

      if (
        P.isNullable(errors) ||
        A.isNonEmptyArray(errors) ||
        A.isEmptyArray(warnings)
      ) {
        throw error;
      }

      if (A.isNonEmptyArray(warnings)) {
        await onWarnings(warnings);
        // @ts-expect-error T can be instantiated with a different subtype, but we know it won't here
        return await api({ ...payload, overrideWarnings: true }, options);
      }
      throw error;
    }
  };
}

export { withWarningHandler };
export type { WithWarningHandlerParamsOnWarnings };
