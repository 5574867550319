import { Option as O, Predicate as P } from "effect";
import type { FocusEvent, ForwardedRef } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import type { InputBaseProps } from "../../../input/src";
import { Input } from "../../../input/src";

/**
 * given the user's typed input, strip out all characters not allowed in a number.
 * The resulting string should be something we are comfortable to display in the UI.
 */
function sanitize(value: string) {
  return value.replace(/(\.)+|[^\d.\-e+]*/g, "$1"); //filter out non-digit characters or multiple decimal points
}

type NumberInputProps = {
  value: O.Option<number>;
  onChange: (value: O.Option<number>) => void;
  /**
   * @description
   * how many decimal places to show/allow in the input.
   * Must be in the range 0 - 20, inclusive.
   *
   * CAREFUL: changing this value at runtime will update the displayed value
   * but will not recompute or trigger the onChange with the new precision.
   *
   * @default 2
   */
  precision?: number;
  withArrows?: boolean;
  step?: number;
} & InputBaseProps;

function NumberInput(
  props: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    borderless,
    description,
    disabled = false,
    error,
    label,
    leftSection,
    onChange,
    placeholder,
    precision = 2,
    rightSection,
    size,
    value,
    //TODO re-enable these props when we have a button base to use for the controls
    // withArrows = false,
    // step = 1,
    name,
  } = props;

  /**
   * whether the user is currently typing in the field or not.
   * If this is true, we want to prevent incoming value changes
   * from replacing the user's typed input
   */
  const isEditing = useRef(false);

  const [display, setDisplay] = useState(
    value.pipe(
      O.map((val) => val.toFixed(precision)),
      O.getOrElse(() => ""),
    ),
  );

  /**
   * react to value changes from the parent state management
   */
  useEffect(() => {
    if (!isEditing.current) {
      setDisplay(
        value.pipe(
          O.map((val) => val.toFixed(precision)),
          O.getOrElse(() => ""),
        ),
      );
    }
  }, [value, precision]);

  /**
   * handles the user typing. What this achieves:
   * 1. sanitize and compute the number value based on the user's input
   * 3. update the Number value via the onChange prop
   */
  const handleTextInput = useCallback(
    (val: string) => {
      try {
        const sanitized = sanitize(val);
        setDisplay(sanitized);
        const num = parseFloat(sanitized);
        if (P.isNumber(num) && !Number.isNaN(num)) {
          onChange(O.some(parseFloat(num.toFixed(precision))));
        } else {
          onChange(O.none());
        }
      } catch {
        onChange(O.none());
      }
    },
    [onChange, precision],
  );

  //the only purpose of this is to reformat the displayValue when the user clicks away. The value is unchanged.
  const handleBlur = useCallback(() => {
    isEditing.current = false;
    setDisplay(
      value.pipe(
        O.map((val) => val.toFixed(precision)),
        O.getOrElse(() => ""),
      ),
    );
  }, [value, precision]);

  function handleFocus(_e: FocusEvent<HTMLInputElement>) {
    isEditing.current = true;
  }

  return (
    <Input
      borderless={borderless}
      description={description}
      disabled={disabled}
      error={error}
      inputType="number"
      label={label}
      leftSection={leftSection}
      onBlur={handleBlur}
      onChange={handleTextInput}
      onFocus={handleFocus}
      placeholder={placeholder}
      ref={ref}
      rightSection={rightSection}
      size={size}
      value={display}
      name={name}
    />
  );
}
const ForwardedNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  NumberInput,
);

export { ForwardedNumberInput as NumberInput };

export type { NumberInputProps };
