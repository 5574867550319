/**
 * HTTP Method: POST
 * Pathname: /collections/process/{id}/criteria
 * @function updateProcessCriteria
 * @memberof CollectionsAPI
 * @param {CollectionsAPIUpdateProcessCriteriaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SelectionCriteriaRequest } from "@ender/shared/generated/ender.api.finance.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIUpdateProcessCriteriaPathParams = {
  id: EnderId;
};

type CollectionsAPIUpdateProcessCriteriaSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIUpdateProcessCriteriaBodyParams = {
  criteria: SelectionCriteriaRequest[];
};

type CollectionsAPIUpdateProcessCriteriaPayload =
  CollectionsAPIUpdateProcessCriteriaPathParams &
    CollectionsAPIUpdateProcessCriteriaSearchParams &
    CollectionsAPIUpdateProcessCriteriaBodyParams;

function updateProcessCriteriaUnsafeEffect(
  payload: CollectionsAPIUpdateProcessCriteriaPayload,
) {
  const { id, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/collections/process/${id}/criteria`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateProcessCriteria(
  payload: CollectionsAPIUpdateProcessCriteriaPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateProcessCriteriaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateProcessCriteria };
export type {
  CollectionsAPIUpdateProcessCriteriaBodyParams,
  CollectionsAPIUpdateProcessCriteriaPathParams,
  CollectionsAPIUpdateProcessCriteriaPayload,
  CollectionsAPIUpdateProcessCriteriaSearchParams,
};
