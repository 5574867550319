import { Predicate as P } from "effect";
import type { ReactNode } from "react";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";

import { AddApprover } from "./add-approver";
import { ApproverBadge } from "./approver-badge";
import { DeleteStepButton } from "./delete-step-button";
import { StepNameForm } from "./step-name-form";

import styles from "./step.module.css";

type StepProps = {
  canDelete: boolean;
  refetchData: () => Promise<void>;
  missingEditPermissions?: ReactNode;
  step: GetApprovalProcessResponseStep;
  stepNumber: number;
};

function Step({
  canDelete,
  refetchData,
  missingEditPermissions,
  step,
  stepNumber,
}: StepProps) {
  const { approvers: _approvers, id, name } = step;
  const hasEditPermission = P.isNullable(missingEditPermissions);

  /**
   * This should not be necessary
   * But BE is returning a null value in the approvers array for Ender
   * https://enderhub.slack.com/archives/D02UHGZRXBM/p1717091988774759
   */
  const approvers = _approvers.filter((approver) => approver !== NULL);

  return (
    <div aria-label="Approval Step Form">
      <Group noWrap align={Align.center}>
        <div>{stepNumber}.</div>
        <div className={styles.flexGrow1}>
          <div className={styles.stepInner}>
            <Stack spacing={Spacing.sm}>
              <Group>
                <StepNameForm
                  disabled={!hasEditPermission}
                  initialValue={name}
                  onSuccess={refetchData}
                  stepId={id}
                  tooltip={
                    !hasEditPermission ? missingEditPermissions : UNDEFINED
                  }
                />
                <div className={styles.flexGrow1}>
                  <AddApprover
                    disabled={!hasEditPermission}
                    excludeIds={approvers.map(({ id }) => id)}
                    onSuccess={refetchData}
                    stepId={id}
                    tooltip={
                      !hasEditPermission ? missingEditPermissions : UNDEFINED
                    }
                  />
                </div>
                <DeleteStepButton
                  onSuccess={refetchData}
                  stepId={id}
                  disabled={!hasEditPermission || !canDelete}
                  tooltip={
                    !hasEditPermission ? missingEditPermissions : UNDEFINED
                  }
                />
              </Group>
              <Group spacing={Spacing.xs}>
                {approvers.length > 0 ? (
                  approvers.map((approver) => (
                    <ApproverBadge
                      approver={approver}
                      disabled={!hasEditPermission}
                      key={approver.id}
                      onDeleteSuccess={refetchData}
                      stepId={id}
                      tooltip={
                        hasEditPermission
                          ? `Remove ${approver.firstName}`
                          : missingEditPermissions
                      }
                    />
                  ))
                ) : (
                  <div>Please add an approver.</div>
                )}
              </Group>
            </Stack>
          </div>
        </div>
      </Group>
    </div>
  );
}

export { Step };
