/**
 * HTTP Method: POST
 * Pathname: /getChargeScheduleCategories
 * @function getChargeScheduleCategories
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetChargeScheduleCategoriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetChargeScheduleCategoriesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetChargeScheduleCategoriesResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetChargeScheduleCategoriesSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetChargeScheduleCategoriesBodyParams = {
  keyword: string;
};

type GeneralLedgerAPIGetChargeScheduleCategoriesPayload =
  GeneralLedgerAPIGetChargeScheduleCategoriesSearchParams &
    GeneralLedgerAPIGetChargeScheduleCategoriesBodyParams;

function getChargeScheduleCategoriesUnsafeEffect(
  payload: GeneralLedgerAPIGetChargeScheduleCategoriesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetChargeScheduleCategoriesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetChargeScheduleCategoriesResponse[]>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getChargeScheduleCategories",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getChargeScheduleCategories(
  payload: GeneralLedgerAPIGetChargeScheduleCategoriesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetChargeScheduleCategoriesResponse[]> {
  return F.pipe(
    payload,
    getChargeScheduleCategoriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getChargeScheduleCategories };
export type {
  GeneralLedgerAPIGetChargeScheduleCategoriesBodyParams,
  GeneralLedgerAPIGetChargeScheduleCategoriesPayload,
  GeneralLedgerAPIGetChargeScheduleCategoriesSearchParams,
};
