import { Array as A, Function as F } from "effect";
import type {
  PropsWithChildren,
  ReactEventHandler,
  ReactNode,
  SyntheticEvent,
} from "react";
import { isValidElement, useCallback, useRef } from "react";

function JsxToString(children: ReactNode): string {
  if (!children) {
    return "";
  }

  if (typeof children === "string") {
    return children;
  }

  if (A.isArray(children)) {
    return children.reduce((str, child) => {
      return str + JsxToString(child as ReactNode);
    }, "") as string;
  }

  if (isValidElement(children)) {
    const { type, props } = children;
    let propsString = "";
    for (const key in props) {
      if (key !== "children") {
        const propValue = props[key];
        let value = "";
        if (propValue instanceof Object) {
          value = `{${JSON.stringify(propValue).replace(/['"]+/g, "")}}`;
        } else {
          value = `"${propValue}"`;
        }

        propsString += ` ${key}=${value}`;
      }
    }

    if (props.children) {
      const childrenString = JsxToString(props.children);
      return `<${type}${propsString}>${childrenString}</${type}>`;
    }
    return `<${type}${propsString} />`;
  }
  return "";
}

type EnderIframeProps = {
  onLoad?: ReactEventHandler<HTMLIFrameElement>;
  className?: string;
};

const setIframeHeightToFullScrollable = (iframe: HTMLIFrameElement | null) => {
  if (!iframe?.contentWindow) {
    return;
  }

  iframe.height = iframe.contentWindow.document.body.scrollHeight.toString();
  iframe.contentWindow.document.body.style.overflowY = "hidden";
};

function EnderIFrame({
  children,
  onLoad = F.constVoid,
  className = "",
}: PropsWithChildren<EnderIframeProps>) {
  const ref = useRef<HTMLIFrameElement | null>();
  const isFirstResize = useRef<boolean>(true);

  function onIframeLoad(e: SyntheticEvent<HTMLIFrameElement>) {
    setIframeHeightToFullScrollable(e.currentTarget);
    onLoad(e);
  }

  const handleRef = useCallback(
    (iframe: HTMLIFrameElement | null) => {
      if (!ref.current) {
        ref.current = iframe;

        ref.current?.contentWindow?.addEventListener("resize", () => {
          if (isFirstResize.current) {
            isFirstResize.current = false;
            return;
          }

          if (ref.current) {
            setIframeHeightToFullScrollable(ref.current);
          }
        });
      }
    },
    [isFirstResize],
  );

  return (
    <iframe
      ref={handleRef}
      style={{ border: 0, width: "100%" }}
      onLoad={onIframeLoad}
      className={className}
      srcDoc={JsxToString(children)}
      title="EnderIframe"></iframe>
  );
}

export { EnderIFrame };
export type { EnderIframeProps };
