import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { String as S } from "effect";
import { useContext, useEffect, useState } from "react";

import { UserContext } from "@ender/shared/contexts/user";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { FontSize, Text } from "@ender/shared/ds/text";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { LeaseSerializerLeaseContact } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { CopyToClipboard } from "@ender/shared/ui/copy-to-clipboard";
import { EnderGroup } from "@ender/shared/ui/ender-group";

import { ViewSSNReasonModal } from "./view-ssn-reason.modal";

import "./view-ssn-button.scss";

function Dash() {
  return <div className="dash">-</div>;
}

function Dot() {
  return <div className="dot" />;
}

function MaskedSsn() {
  return (
    <EnderGroup spacing={4} align="center" className="view-ssn-button__mask">
      <Dot />
      <Dot />
      <Dot />
      <Dash />
      <Dot />
      <Dot />
      <Dash />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </EnderGroup>
  );
}

const SSN_VISIBILITY_TIME = 5000; // 5 seconds;

function formatSsn(ssn: string): string {
  if (ssn === "ENCRYPTED") {
    return "<REDACTED>";
  }

  return ssn.replace(/(\d{3})(\d{2})(\d{4})/g, "$1-$2-$3"); // FORMAT XXX-XX-XXXX
}

/**
 * @name ViewSSNButton
 * @description The button which handles the displaying the SSN for a contact
 * @param {object} props
 * @param {string} props.ssn
 * @param {string=} props.className
 * @returns {React.ReactElement}
 */

type ViewSSNButtonProps = {
  contact: LeaseSerializerLeaseContact;
  className?: string;
};

function ViewSSNButton({ contact, className = "" }: ViewSSNButtonProps) {
  const { user, originalUser } = useContext(UserContext);
  const [
    isReasonModalOpen,
    { setTrue: openReasonModal, setFalse: closeReasonModal },
  ] = useBoolean(false);
  const [viewReason, setViewReason] = useState<string>("");

  const canFetchSSN = originalUser && originalUser.id === user.id;

  const { data: ssn } = useQuery<{ ssn: string }, Error, string>({
    enabled: S.isNonEmpty(viewReason),
    queryFn: ({ signal }) => {
      if (canFetchSSN) {
        return LeasingAPI.getSSN(
          {
            reasonToView: viewReason,
            userId: contact.id,
          },
          { signal },
        );
      }

      return { ssn: "ENCRYPTED" };
    },
    queryKey: ["LeasingAPI.getSSN", contact.id, viewReason] as const,
    select: (res) => res.ssn,
  });

  // lazy useEffect
  useEffect(() => {
    if (S.isEmpty(viewReason)) {
      return;
    }

    const hideTimeout = setTimeout(() => {
      setViewReason("");
    }, SSN_VISIBILITY_TIME);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [viewReason]);

  if (!contact.ssn) {
    return <i>SSN missing</i>;
  }

  function handleViewClick() {
    S.isEmpty(viewReason) ? openReasonModal() : setViewReason("");
  }

  function handleReasonModalClose(): void {
    closeReasonModal();
    setViewReason("");
  }

  function handleReasonModalSubmit(reason: string): void {
    closeReasonModal();
    setViewReason(reason);
  }

  const ssnLabel = ssn ? formatSsn(ssn) : "";

  return (
    <EnderGroup align="center" spacing={8} className={className}>
      <ViewSSNReasonModal
        opened={isReasonModalOpen}
        onClose={handleReasonModalClose}
        onSubmit={handleReasonModalSubmit}
      />
      <ActionIcon onClick={handleViewClick}>
        {S.isEmpty(viewReason) ? <IconEye /> : <IconEyeOff />}
      </ActionIcon>
      <div className="ssn__value" data-private>
        {S.isEmpty(viewReason) ? (
          <MaskedSsn />
        ) : (
          <CopyToClipboard value={ssnLabel}>
            <Text size={FontSize.sm}>{ssnLabel}</Text>
          </CopyToClipboard>
        )}
      </div>
    </EnderGroup>
  );
}

export { ViewSSNButton };
