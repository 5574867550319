import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const MoneyTransferTransferStatusValues = [
  "UNPOSTED",
  "CANCELED",
  "REVERSED",
  "IN_RECEIPTS",
  "NORMAL",
] as const;

const MoneyTransferTransferStatusEffectSchema = Schema.Literal(
  ...MoneyTransferTransferStatusValues,
);

type MoneyTransferTransferStatus = Schema.Schema.Type<
  typeof MoneyTransferTransferStatusEffectSchema
>;

const MoneyTransferTransferStatusEnum = castEnum(
  MoneyTransferTransferStatusEffectSchema,
);

function randomMoneyTransferTransferStatus(): MoneyTransferTransferStatus {
  return rand(MoneyTransferTransferStatusValues);
}

export {
  MoneyTransferTransferStatusEffectSchema,
  MoneyTransferTransferStatusEnum,
  MoneyTransferTransferStatusValues,
  randomMoneyTransferTransferStatus,
};
export type { MoneyTransferTransferStatus };
