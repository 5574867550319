import { Schema } from "@effect/schema";
import { Option as O } from "effect";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import type { ScheduledChargesRightRailView } from "@ender/shared/contexts/scheduled-charges";
import {
  ScheduledChargesRightRailViewEnum,
  ScheduledChargesRightRailViewValues,
} from "@ender/shared/contexts/scheduled-charges";
import type { EnderId } from "@ender/shared/core";
import { useQueryParamsEnum } from "@ender/shared/hooks/use-query-params";

type UseSetTenantLedgerScheduledChargesRailProps = {
  initialValue?: ScheduledChargesRightRailView;
};

const ScheduledChargesRightRailViewSchema = Schema.Literal(
  ...ScheduledChargesRightRailViewValues,
);
const ScheduledChargesRightRailViewSchemaDecodeOption =
  Schema.decodeUnknownOption(ScheduledChargesRightRailViewSchema);

function useSetTenantLedgerScheduledChargesRail(
  props?: UseSetTenantLedgerScheduledChargesRailProps,
) {
  const { initialValue } = props ?? {};

  return useQueryParamsEnum("tenantLedgerScheduledChargesRail", {
    initialValue: O.some(
      initialValue ?? ScheduledChargesRightRailViewEnum.CLOSED,
    ),
    schema: ScheduledChargesRightRailViewSchema,
  });
}

function useGotoTenantLedgerScheduledChargesRail() {
  const history = useHistory();
  const [value, _setValue] = useSetTenantLedgerScheduledChargesRail({
    initialValue: ScheduledChargesRightRailViewEnum.CLOSED,
  });

  const setValue = useCallback(
    (value: ScheduledChargesRightRailView, leaseId: EnderId) => {
      history.push(`/leases/${leaseId}`);
      _setValue(ScheduledChargesRightRailViewSchemaDecodeOption(value));
    },
    [_setValue, history],
  );

  return [value, setValue] as const;
}

export {
  useGotoTenantLedgerScheduledChargesRail,
  useSetTenantLedgerScheduledChargesRail,
};
