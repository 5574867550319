import { useController as useControllerRhf } from "react-hook-form";

import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseControllerReturn,
} from "../types";

function useController<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: UseControllerProps<TFieldValues, TName>,
): UseControllerReturn<TFieldValues, TName> {
  return useControllerRhf(props);
}

export { useController };
