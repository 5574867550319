import { Option as O } from "effect";
import { createStore } from "zustand/vanilla";

import type {
  LedgerRightRailView,
  OptimizedLedgerEvent,
} from "@ender/shared/contexts/ledger";
import { LedgerRightRailViewEnum } from "@ender/shared/contexts/ledger";
import type { ScheduledChargesRightRailView } from "@ender/shared/contexts/scheduled-charges";
import { ScheduledChargesRightRailViewEnum } from "@ender/shared/contexts/scheduled-charges";
import type { EnderId, LocalDate$ } from "@ender/shared/core";
import type { TenantLedgerReportLedgerEntryTenantLedgerEventType } from "@ender/shared/generated/ender.arch.accounting";

type TenantLedgerStoreState = {
  accountingPeriod: LocalDate$.LocalDate[];
  endDate: O.Option<LocalDate$.LocalDate>;
  selectedScheduledChargeId: O.Option<EnderId>;
  selectedLedgerEvent: O.Option<OptimizedLedgerEvent>;
  selectedLedgerCategory: O.Option<EnderId>;
  startDate: O.Option<LocalDate$.LocalDate>;
  ledgerRightRailView: LedgerRightRailView;
  transactionDescription: string;
  transactionType: O.Option<TenantLedgerReportLedgerEntryTenantLedgerEventType>;
};

type TenantLedgerStoreActions = {
  setAccountingPeriod: (accountingPeriod: LocalDate$.LocalDate[]) => void;
  setEndDate: (endDate: O.Option<LocalDate$.LocalDate>) => void;
  setSelectedCategory: (selectedCategory: O.Option<EnderId>) => void;
  setLedgerRightRailView: (view: LedgerRightRailView) => void;
  setSelectedLedgerCategory: (id: O.Option<EnderId>) => void;
  setLedgerEvent: (event: O.Option<OptimizedLedgerEvent>) => void;
  setSelectedScheduledChargeId: (
    selectedScheduledChargeId: O.Option<EnderId>,
  ) => void;
  setStartDate: (startDate: O.Option<LocalDate$.LocalDate>) => void;
  setTransactionDescription: (description: string) => void;
  setTransactionType: (
    type: O.Option<TenantLedgerReportLedgerEntryTenantLedgerEventType>,
  ) => void;
  reset: () => void;
};

type TenantLedgerStore = TenantLedgerStoreState & TenantLedgerStoreActions;

const initialTenantLedgerStoreState: TenantLedgerStoreState = {
  accountingPeriod: [],
  endDate: O.none(),
  ledgerRightRailView: LedgerRightRailViewEnum.NONE,
  selectedLedgerCategory: O.none(),
  selectedLedgerEvent: O.none(),
  selectedScheduledChargeId: O.none(),
  startDate: O.none(),
  transactionDescription: "",
  transactionType: O.none(),
};

type CreateTenantLedgerStoreProps = {
  setScheduledChargesRightRailView: (
    view: O.Option<ScheduledChargesRightRailView>,
  ) => void;
};

function createTenantLedgerStore(props: CreateTenantLedgerStoreProps) {
  const { setScheduledChargesRightRailView } = props;
  return createStore<TenantLedgerStore>()(
    (set): TenantLedgerStore => ({
      ...initialTenantLedgerStoreState,
      reset: () => {
        set({ ...initialTenantLedgerStoreState });
      },
      setAccountingPeriod: (accountingPeriod) => {
        set({ accountingPeriod });
      },
      setEndDate: (endDate) => {
        set({ endDate });
      },
      setLedgerEvent: (selectedLedgerEvent) => {
        set({ selectedLedgerEvent });
      },
      setLedgerRightRailView: (ledgerRightRailView) => {
        set({ ledgerRightRailView });
      },
      setSelectedCategory: (selectedCategory) => {
        if (O.isSome(selectedCategory)) {
          set({ ledgerRightRailView: LedgerRightRailViewEnum.CATEGORY });
        }
        set({ selectedLedgerCategory: selectedCategory });
      },
      setSelectedLedgerCategory: (selectedLedgerCategory) => {
        set({ selectedLedgerCategory });
      },
      setSelectedScheduledChargeId: (selectedScheduledChargeId) => {
        if (O.isSome(selectedScheduledChargeId)) {
          setScheduledChargesRightRailView(
            O.some(ScheduledChargesRightRailViewEnum.DETAIL),
          );
        } else {
          setScheduledChargesRightRailView(
            O.some(ScheduledChargesRightRailViewEnum.CLOSED),
          );
        }
        set({ selectedScheduledChargeId });
      },
      setStartDate: (startDate) => {
        set({ startDate });
      },
      setTransactionDescription: (transactionDescription) => {
        set({ transactionDescription });
      },
      setTransactionType: (transactionType) => {
        set({ transactionType });
      },
    }),
  );
}

export { createTenantLedgerStore };
export type {
  TenantLedgerStore,
  TenantLedgerStoreActions,
  TenantLedgerStoreState,
};
