import { Match, Option as O, Predicate as P } from "effect";
import { useMemo } from "react";
import { z } from "zod";

import { Instant$ } from "@ender/shared/core";
import type { BadgeColors } from "@ender/shared/ds/badge";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { TaskSearchResponseTaskSearchResponseRow } from "@ender/shared/generated/ender.api.misc.response";
import type { TaskAssignmentAssignmentStatus } from "@ender/shared/generated/ender.model.task";
import {
  TaskAssignmentAssignmentStatusEnum,
  TaskTaskStatusGroupingEnum,
} from "@ender/shared/generated/ender.model.task";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import {
  convertSnakeCaseToTitleCase,
  fromScreamingSnakeCaseToSpaceCase,
} from "@ender/shared/utils/string";
import { castEnum } from "@ender/shared/utils/zod";

type WorkOrder = TaskSearchResponseTaskSearchResponseRow;

const AssigneeTypeValues = ["INTERNAL_USER", "VENDOR_USER", "VENDOR"] as const;
const AssigneeTypeSchema = z.enum(AssigneeTypeValues);
type AssigneeType = z.infer<typeof AssigneeTypeSchema>;
const AssigneeTypeEnum = castEnum<AssigneeType>(AssigneeTypeSchema);

function getAssigneeTypeBadgeProps(type: AssigneeType): {
  color: BadgeColors;
  label: string;
} {
  return Match.value(type).pipe(
    Match.when(AssigneeTypeEnum.INTERNAL_USER, () => ({
      color: BadgeColor.slate,
      label: "Internal",
    })),
    Match.when(AssigneeTypeEnum.VENDOR_USER, () => ({
      color: BadgeColor.slate,
      label: "Vendor Employee",
    })),
    Match.when(AssigneeTypeEnum.VENDOR, () => ({
      color: BadgeColor.slate,
      label: "Vendor",
    })),
    Match.orElse(() => ({ color: BadgeColor.slate, label: "Unknown" })),
  );
}

function getAssignmentStatusBadgeProps(
  status?: TaskAssignmentAssignmentStatus,
): {
  color: BadgeColors;
  label: string;
} {
  return Match.value(status).pipe(
    Match.when(TaskAssignmentAssignmentStatusEnum.ASSIGNED, () => ({
      color: BadgeColor.green,
      label: "Assigned",
    })),
    Match.when(TaskAssignmentAssignmentStatusEnum.ACCEPTED, () => ({
      color: BadgeColor.green,
      label: "Accepted",
    })),
    Match.when(TaskAssignmentAssignmentStatusEnum.REJECTED, () => ({
      color: BadgeColor.yellow,
      label: "Rejected",
    })),
    Match.when(TaskAssignmentAssignmentStatusEnum.UNASSIGNED, () => ({
      color: BadgeColor.slate,
      label: "Unassigned",
    })),
    Match.orElse(() => ({ color: BadgeColor.slate, label: "Unknown" })),
  );
}

type InternalUserAssignee = NonNullable<WorkOrder["internalUserAssignee"]>;
type VendorUserAssignee = NonNullable<WorkOrder["vendorUserAssignee"]>;
type VendorAssignee = NonNullable<WorkOrder["vendorAssignee"]>;

type AssigneeProps = {
  name: string;
  type: AssigneeType;
  assignmentStatus?: TaskAssignmentAssignmentStatus;
};

function Assignee(props: AssigneeProps) {
  const { name, type, assignmentStatus } = props;
  const { color: typeColor, label: typeLabel } =
    getAssigneeTypeBadgeProps(type);
  const { color: statusColor, label: statusLabel } =
    getAssignmentStatusBadgeProps(assignmentStatus);
  return (
    <Stack spacing={Spacing.xs}>
      <Text size={FontSize.sm}>{name}</Text>
      <Group spacing={Spacing.xs} align={Align.center} noWrap>
        <Badge color={typeColor}>{typeLabel}</Badge>
        <Badge color={statusColor}>{statusLabel}</Badge>
      </Group>
    </Stack>
  );
}

type AssignedCellProps = {
  internalUserAssignee?: Pick<
    InternalUserAssignee,
    "displayName" | "assignmentStatus"
  >;
  vendorUserAssignee?: Pick<
    VendorUserAssignee,
    "displayName" | "assignmentStatus"
  >;
  vendorAssignee?: Pick<VendorAssignee, "name" | "assignmentStatus">;
};

function AssignedCell(props: AssignedCellProps) {
  const { internalUserAssignee, vendorUserAssignee, vendorAssignee } = props;
  return (
    <Stack>
      {P.isNotNullable(internalUserAssignee) && (
        <Assignee
          name={internalUserAssignee.displayName}
          type={AssigneeTypeEnum.INTERNAL_USER}
          assignmentStatus={internalUserAssignee.assignmentStatus}
        />
      )}
      {P.isNotNullable(vendorAssignee) && (
        <Assignee
          name={vendorAssignee.name}
          type={AssigneeTypeEnum.VENDOR}
          assignmentStatus={vendorAssignee.assignmentStatus}
        />
      )}
      {P.isNotNullable(vendorUserAssignee) && (
        <Assignee
          name={vendorUserAssignee.displayName}
          type={AssigneeTypeEnum.VENDOR_USER}
          assignmentStatus={vendorUserAssignee.assignmentStatus}
        />
      )}
    </Stack>
  );
}

const invoiceDisplayStatusMap = {
  [ApprovableApprovalStatusEnum.APPROVED]: "Approved",
  [ApprovableApprovalStatusEnum.REJECTED]: "Rejected",
  [ApprovableApprovalStatusEnum.NEW]: "Pending",
  // [ApprovableApprovalStatusEnum.PAID]: "Paid", // TODO does the BE ever return "PAID" as a status?
  // [ApprovableApprovalStatusEnum.PENDING]: "Pending", // TODO does the BE ever return "PENDING" as a status?
} as const;

function getInvoicesTooltipContent(invoices: WorkOrder["invoices"]) {
  const invoicesPerStatus: Record<ApprovableApprovalStatus, number> = {
    [ApprovableApprovalStatusEnum.APPROVED]: 0,
    [ApprovableApprovalStatusEnum.REJECTED]: 0,
    [ApprovableApprovalStatusEnum.NEW]: 0,
    // [ApprovableApprovalStatusEnum.PAID]: 0, // TODO does the BE ever return "PAID" as a status?
    // [ApprovableApprovalStatusEnum.PENDING]: 0, // TODO does the BE ever return "PENDING" as a status?
  };

  invoices.forEach((invoice) => invoicesPerStatus[invoice.status]++);

  return Object.entries(invoicesPerStatus)
    .sort(([aStatus]) => {
      if (aStatus === ApprovableApprovalStatusEnum.APPROVED) {
        return -1;
      }

      if (aStatus === ApprovableApprovalStatusEnum.NEW) {
        return 1;
      }

      return 0;
    })
    .map(([invoiceStatus, num]) => {
      return `${num} ${invoiceDisplayStatusMap[invoiceStatus as ApprovableApprovalStatus]}`;
    })
    .join(", ");
}

type WorkOrderListItemProps = {
  workOrder: WorkOrder;
};

function WorkOrderListItem(props: WorkOrderListItemProps) {
  const { workOrder } = props;
  const { internalUserAssignee, vendorUserAssignee, vendorAssignee } =
    workOrder;
  const invoicesTooltipContent = useMemo(
    () => getInvoicesTooltipContent(workOrder.invoices),
    [workOrder.invoices],
  );

  return (
    <tr data-work-order-list-item>
      <td>
        <RouterLink href={`/tasks/${workOrder.id}`}>
          {workOrder.shortName}
        </RouterLink>
      </td>
      <td>{convertSnakeCaseToTitleCase(workOrder.type)}</td>
      <td>{convertSnakeCaseToTitleCase(workOrder.statusGrouping)}</td>
      {workOrder.statusGrouping === TaskTaskStatusGroupingEnum.CLOSED && (
        <td>
          {workOrder.closeReason
            ? fromScreamingSnakeCaseToSpaceCase(workOrder.closeReason)
            : "-"}
        </td>
      )}
      <td>
        <DateDisplay
          value={Instant$.parse(workOrder.timestamp).pipe(
            O.map((created) => created.toLocalDate()),
          )}
        />
      </td>
      <td>{workOrder.description}</td>
      <td>
        <AssignedCell
          internalUserAssignee={internalUserAssignee}
          vendorUserAssignee={vendorUserAssignee}
          vendorAssignee={vendorAssignee}
        />
      </td>
      <td className="numeric right">
        {workOrder.invoices.length > 0 ? (
          <Tooltip label={invoicesTooltipContent}>
            {workOrder.invoices.length}
          </Tooltip>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
}

export { WorkOrderListItem };
