/**
 * HTTP Method: POST
 * Pathname: /applications/fake
 * @function createFakeApp
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPICreateFakeAppPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPICreateFakeAppSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPICreateFakeAppBodyParams = {
  unitId: EnderId;
};

type ApplicationsAPICreateFakeAppPayload =
  ApplicationsAPICreateFakeAppSearchParams &
    ApplicationsAPICreateFakeAppBodyParams;

function createFakeAppUnsafeEffect(
  payload: ApplicationsAPICreateFakeAppPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/applications/fake",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createFakeApp(
  payload: ApplicationsAPICreateFakeAppPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    createFakeAppUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createFakeApp };
export type {
  ApplicationsAPICreateFakeAppBodyParams,
  ApplicationsAPICreateFakeAppPayload,
  ApplicationsAPICreateFakeAppSearchParams,
};
