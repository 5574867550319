import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const FormInputTypeValues = [
  "TEXT",
  "NUMBER",
  "SLIDER",
  "SLIDER_RANGE",
  "SELECT",
  "LIST",
  "SECTION",
] as const;
const FormInputTypeSchema = z.enum(FormInputTypeValues);
type FormInputType = z.infer<typeof FormInputTypeSchema>;

const FormInputTypeEnum = castEnum<FormInputType>(FormInputTypeSchema);

export { FormInputTypeEnum, FormInputTypeSchema, FormInputTypeValues };
export type { FormInputType };
