import { z } from "zod";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";
import { UserUserTimezoneValues } from "@ender/shared/generated/ender.model.core.user";
import { VendorUserTagVendorUserTagTypeValues } from "@ender/shared/generated/ender.model.core.vendor";
import {
  EmailSchema,
  IsoDateSchema,
  IsoDatetimeSchema,
  PhoneSchema,
} from "@ender/shared/types/ender-general";
import { castEnum } from "@ender/shared/utils/zod";

const VendorUserOnboardingStatusValues = [
  "PENDING_ONBOARDING_MESSAGE",
  "PENDING_VERIFICATION",
  "FULLY_ONBOARDED",
] as const;
const VendorUserOnboardingStatusSchema = z.enum(
  VendorUserOnboardingStatusValues,
);
type VendorUserOnboardingStatus = z.infer<
  typeof VendorUserOnboardingStatusSchema
>;

/**
 * @enum {string} VendorUserOnboardingStatusEnum
 * @description User::VendorUserOnboardingStatus from model/core/user/User.java
 */
const VendorUserOnboardingStatusEnum = castEnum<VendorUserOnboardingStatus>(
  VendorUserOnboardingStatusSchema,
);

type GetVendorUsersPayload = {
  vendorId: EnderId;
};

const VendorUserEmailSchema = z
  .string()
  .optional()
  .default("")
  .transform((val) => (val === "" ? UNDEFINED : val))
  .transform((val) => {
    const parsed = EmailSchema.safeParse(val);
    return parsed.success ? parsed.data : UNDEFINED;
  });

const GetVendorUsersResponseSchema = z.object({
  birthday: IsoDateSchema.nullish(),
  canSendOnboardingEmail: z.boolean(),
  canSendOnboardingSms: z.boolean(),
  displayEmail: VendorUserEmailSchema,
  email: VendorUserEmailSchema,
  emailVerifiedTimestamp: IsoDatetimeSchema.nullish(),
  firstName: z.string(),
  id: EnderIdSchema,
  isActiveMilitary: z.boolean().nullish(),
  isBroker: z.boolean(),
  isEnder: z.boolean(),
  isInvestor: z.boolean(),
  isPM: z.boolean(),
  isTenant: z.boolean(),
  isVendor: z.boolean(),
  lastOnline: z.string().default(""),
  lastOnlineTimestamp: IsoDatetimeSchema.nullish(),
  lastName: z.string(),
  name: z.string(),
  onboarded: z.string().default(""),
  onboardingStatus: VendorUserOnboardingStatusSchema,
  onboardingTimestamp: IsoDatetimeSchema.nullish(),
  phone: z
    .string()
    .optional()
    .default("")
    .transform((val) => (val === "" ? UNDEFINED : val))
    .transform((val) => {
      const parsed = PhoneSchema.safeParse(val);
      return parsed.success ? parsed.data : UNDEFINED;
    }),
  phoneIsLandline: z.boolean().nullish(),
  pmId: EnderIdSchema.nullish(),
  roles: z.array(z.string()),
  ssn: z.string(),
  tags: z.enum(VendorUserTagVendorUserTagTypeValues).array(),
  timezone: z.enum(UserUserTimezoneValues),
  vendorId: EnderIdSchema.nullish(),
});

export {
  GetVendorUsersResponseSchema,
  VendorUserOnboardingStatusEnum,
  VendorUserOnboardingStatusSchema,
};
export type { GetVendorUsersPayload, VendorUserOnboardingStatus };
