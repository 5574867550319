/**
 * HTTP Method: POST
 * Pathname: /renewalChargeSchedules/{chargeScheduleId}
 * @function updateRenewalChargeSchedule
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPIUpdateRenewalChargeSchedulePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { ChargeScheduleFrequency } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ChargeSchedulesAPIUpdateRenewalChargeSchedulePathParams = {
  chargeScheduleId: EnderId;
};

type ChargeSchedulesAPIUpdateRenewalChargeScheduleSearchParams = {
  token?: string | undefined;
};

type ChargeSchedulesAPIUpdateRenewalChargeScheduleBodyParams = {
  amount?: SerializesInto<Money> | undefined;
  frequency?: ChargeScheduleFrequency | undefined;
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  startDate?: SerializesInto<LocalDate> | undefined;
};

type ChargeSchedulesAPIUpdateRenewalChargeSchedulePayload =
  ChargeSchedulesAPIUpdateRenewalChargeSchedulePathParams &
    ChargeSchedulesAPIUpdateRenewalChargeScheduleSearchParams &
    ChargeSchedulesAPIUpdateRenewalChargeScheduleBodyParams;

function updateRenewalChargeScheduleUnsafeEffect(
  payload: ChargeSchedulesAPIUpdateRenewalChargeSchedulePayload,
) {
  const { chargeScheduleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/renewalChargeSchedules/${chargeScheduleId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateRenewalChargeSchedule(
  payload: ChargeSchedulesAPIUpdateRenewalChargeSchedulePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateRenewalChargeScheduleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateRenewalChargeSchedule };
export type {
  ChargeSchedulesAPIUpdateRenewalChargeScheduleBodyParams,
  ChargeSchedulesAPIUpdateRenewalChargeSchedulePathParams,
  ChargeSchedulesAPIUpdateRenewalChargeSchedulePayload,
  ChargeSchedulesAPIUpdateRenewalChargeScheduleSearchParams,
};
