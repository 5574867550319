/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/chargeSchedules
 * @function getLeaseChargeSchedules
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPIGetLeaseChargeSchedulesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetChargeScheduleResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetChargeScheduleResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ChargeSchedulesAPIGetLeaseChargeSchedulesPathParams = {
  leaseId: EnderId;
};

type ChargeSchedulesAPIGetLeaseChargeSchedulesSearchParams = {
  token?: string | undefined;
};

type ChargeSchedulesAPIGetLeaseChargeSchedulesPayload =
  ChargeSchedulesAPIGetLeaseChargeSchedulesPathParams &
    ChargeSchedulesAPIGetLeaseChargeSchedulesSearchParams;

function getLeaseChargeSchedulesUnsafeEffect(
  payload: ChargeSchedulesAPIGetLeaseChargeSchedulesPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetChargeScheduleResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetChargeScheduleResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/chargeSchedules`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeaseChargeSchedules(
  payload: ChargeSchedulesAPIGetLeaseChargeSchedulesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetChargeScheduleResponse[]> {
  return F.pipe(
    payload,
    getLeaseChargeSchedulesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeaseChargeSchedules };
export type {
  ChargeSchedulesAPIGetLeaseChargeSchedulesPathParams,
  ChargeSchedulesAPIGetLeaseChargeSchedulesPayload,
  ChargeSchedulesAPIGetLeaseChargeSchedulesSearchParams,
};
