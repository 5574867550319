import { IconInfoCircle } from "@tabler/icons-react";

import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";

function InvoiceNumberLabel() {
  return (
    <Group spacing={Spacing.xs}>
      Invoice Number (optional)
      <Tooltip label="Ender will assign an invoice number if you do not specify one.">
        <IconInfoCircle size={14} />
      </Tooltip>
    </Group>
  );
}

export { InvoiceNumberLabel };
