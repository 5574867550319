import { IconMessage, IconMinus } from "@tabler/icons-react";
import { clsx } from "clsx";
import type { PropsWithChildren } from "react";
import { forwardRef, useCallback, useContext, useState } from "react";

import { Button } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import { RightRailContext } from "@ender/shared/ui/right-rail";

import { ExpandoBox } from "./expando-box";

import styles from "./chat-window.module.css";

type ChatWindowProps = PropsWithChildren<{
  title?: string;
}>;

/**
 * ChatWindow must be used with a deterministic-sized child component. This is because the window needs to obtain
 * the sizes of the child component in order to properly transition between the opened and closed states.
 */
export const ChatWindow = forwardRef<HTMLDivElement, ChatWindowProps>(
  function ChatWindow(props, ref) {
    const { children, title = "Chat" } = props;
    const [expanded, setExpanded] = useState(false);
    const handleChatWindowClick = useCallback(() => {
      setExpanded((prev) => !prev);
    }, []);

    const [opened] = useContext(RightRailContext);
    return (
      <div className={clsx(styles.root, { [styles.offsetRight]: opened })}>
        <Stack ref={ref} spacing={Spacing.none}>
          <Button
            onClick={handleChatWindowClick}
            leftSection={<IconMessage className={styles.icon} />}
            rightSection={
              <IconMinus
                className={clsx(styles.minimizeButton, {
                  [styles.show]: expanded,
                })}
              />
            }>
            {title}
          </Button>
          <ExpandoBox in={expanded} className={styles.content}>
            {children}
          </ExpandoBox>
        </Stack>
      </div>
    );
  },
);
