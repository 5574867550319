import { Predicate as P, String as S } from "effect";
import { useEffect, useMemo, useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Tuple } from "@ender/shared/ds/tuple";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import type { SelectProps } from "@ender/shared/ui/select";
import { Select } from "@ender/shared/ui/select";

type SelectBankAccountProps = {
  bankAccounts: BankAccount[];
  inputProps: Omit<SelectProps<string>, "data">;
  selectedBankAccountId: EnderId | undefined;
};

function SelectBankAccount({
  bankAccounts,
  inputProps,
  selectedBankAccountId,
}: SelectBankAccountProps) {
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    BankAccount | undefined
  >(bankAccounts[0]);

  // lazy useEffect (legacy, moved from mark-as-paid-form.tsx)
  useEffect(() => {
    const _selectedBankAccount = bankAccounts?.find(
      (account) => account.id === selectedBankAccountId,
    );

    if (!_selectedBankAccount) {
      setSelectedBankAccount(UNDEFINED);
      return;
    }

    setSelectedBankAccount(_selectedBankAccount);
  }, [selectedBankAccountId, bankAccounts]);

  const data = useMemo(
    () => bankAccounts.map(({ id: value, name: label }) => ({ label, value })),
    [bankAccounts],
  );

  return (
    <>
      {data.length > 1 && (
        <Select
          label="Bank Account"
          name="bankAccountId"
          {...inputProps}
          data={data}
        />
      )}
      {P.isNotNullable(selectedBankAccount) && (
        <div>
          <Tuple label="Account Name" value={selectedBankAccount.name} />
          <Tuple
            label="Account Number"
            value={`••••${selectedBankAccount.mask}`}
          />
          {S.isNonEmpty(selectedBankAccount.institution) && (
            <Tuple
              label="Account Institution"
              value={selectedBankAccount.institution}
            />
          )}
        </div>
      )}
    </>
  );
}

export { SelectBankAccount };
