import { useEffect, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { Select } from "@ender/shared/ui/select";

type UserSelectorProps = {
  assignUnderwriter: (dealId: EnderId, userId: EnderId | string) => void;
  dealId: EnderId;
  selectedUserId: EnderId;
  users: { label: string; value: EnderId }[];
};

function UserSelector({
  assignUnderwriter,
  dealId,
  selectedUserId,
  users,
}: UserSelectorProps) {
  const [selectedId, setSelectedId] = useState<EnderId | null>(selectedUserId);

  // lazy useEffect
  useEffect(() => {
    setSelectedId(selectedUserId);
  }, [selectedUserId]);

  function handleChange(userId: EnderId | null) {
    setSelectedId(userId);
    assignUnderwriter(dealId, userId || "");
  }

  return (
    <Select
      clearable
      searchable
      value={selectedId}
      onChange={handleChange}
      placeholder="Select an Assignee"
      data={users}
    />
  );
}

export { UserSelector };
