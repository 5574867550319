import { Loader } from "@googlemaps/js-api-loader";
import { Option as O, Predicate as P, pipe } from "effect";

import type { Address } from "@ender/shared/generated/ender.model.core";

const GOOGLE_MAPS_API_KEY = "AIzaSyC5G2-Iz9tRvtuLliPB-kCJ83wx5Da4HuI";

type PlaceResult = google.maps.places.PlaceResult;
type LatLngLiteral = google.maps.LatLngLiteral;
type LatLngBounds = google.maps.LatLngBounds;
type LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral;
type AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;
type PinElement = google.maps.marker.PinElement;
type PinElementOptions = google.maps.marker.PinElementOptions;
type GoogleMap = google.maps.Map;

/**
 * converts a Gmaps place to an Ender Address
 * //TODO move this into an Address ender class
 */
function parseAddress(
  place: google.maps.places.PlaceResult,
): O.Option<
  Pick<
    Address,
    | "street"
    | "city"
    | "state"
    | "zipcode"
    | "placeId"
    | "latitude"
    | "longitude"
  >
> {
  if (P.isNullable(place.address_components)) {
    return O.none();
  }
  // let name: string;
  let street: O.Option<Address["street"]> = O.none();
  let city: O.Option<Address["city"]> = O.none();
  let state: O.Option<Address["state"]> = O.none();
  let zipcode: O.Option<Address["zipcode"]> = O.none();
  const placeId: O.Option<Address["placeId"]> = O.fromNullable(place.place_id);
  const latitude: O.Option<Address["latitude"]> = O.fromNullable(
    place.geometry?.location?.lat(),
  );
  const longitude: O.Option<Address["longitude"]> = O.fromNullable(
    place.geometry?.location?.lng(),
  );

  for (const component of place.address_components) {
    //each component has a short_name, a long_name, and various categories (types) it belongs to
    const { short_name, long_name, types } = component;
    if (types.includes("street_number")) {
      street = pipe(
        street,
        O.map((v) => `${short_name} ${v}`.trim()),
        O.orElseSome(() => short_name),
      );
    } else if (types.includes("route")) {
      street = pipe(
        street,
        O.map((v) => `${v} ${short_name}`.trim()),
        O.orElseSome(() => short_name),
      );
    } else if (
      types.includes("locality") ||
      types.includes("administrative_area_level_2") ||
      types.includes("sublocality")
    ) {
      city = pipe(
        city,
        O.map((v) => `${v} ${long_name}`.trim()),
        O.orElseSome(() => long_name),
      );
    } else if (types.includes("administrative_area_level_1")) {
      state = O.fromNullable(short_name);
    } else if (types.includes("postal_code")) {
      zipcode = O.fromNullable(short_name);
    }
  }

  return O.all({
    city,
    latitude,
    longitude,
    placeId,
    state,
    street,
    zipcode,
  });
}

// pass in the full display address from the BE, which combines street, city, state, and zip in a single string
function getGoogleMapsUrl(fullDisplay: string) {
  return `https://www.google.com/maps/place/${fullDisplay.replace(/\s+/, "+")}`;
}

/**
 * taken from Loader.importLibrary definition
 */
type LibraryImportMap = {
  core: google.maps.CoreLibrary;
  maps: google.maps.MapsLibrary;
  places: google.maps.PlacesLibrary;
  geocoding: google.maps.GeocodingLibrary;
  routes: google.maps.RoutesLibrary;
  marker: google.maps.MarkerLibrary;
  geometry: google.maps.GeometryLibrary;
  elevation: google.maps.ElevationLibrary;
  streetView: google.maps.StreetViewLibrary;
  journeySharing: google.maps.JourneySharingLibrary;
  drawing: google.maps.DrawingLibrary;
  visualization: google.maps.VisualizationLibrary;
};

//Loader is already using a singleton pattern
//
class GoogleMapsLoaderSingleton extends Loader {
  public static loadedLibraries: Partial<LibraryImportMap> = {};

  /**
   * Import the given library from the Google Maps API. If the library has already been imported,
   * it will not be imported again, and instead the existing library will be returned.
   */
  public override importLibrary<T extends keyof LibraryImportMap>(
    library: T,
  ): Promise<LibraryImportMap[T]> {
    if (P.isNotNullable(GoogleMapsLoaderSingleton.loadedLibraries[library])) {
      console.warn("Library already loaded", library);
      return Promise.resolve(
        GoogleMapsLoaderSingleton.loadedLibraries[
          library
        ] as LibraryImportMap[T],
      );
    }
    return super.importLibrary(library).then((lib) => {
      GoogleMapsLoaderSingleton.loadedLibraries[library] =
        lib as LibraryImportMap[T];
      return lib as LibraryImportMap[T];
    });
  }
}

const GoogleMapsLoaderInstance = new GoogleMapsLoaderSingleton({
  apiKey: GOOGLE_MAPS_API_KEY,
  id: "googleMapsScript",
  libraries: ["maps"],
  version: "weekly",
});

export {
  getGoogleMapsUrl,
  GoogleMapsLoaderInstance as GoogleMapsLoader,
  parseAddress,
};
export type {
  AdvancedMarkerElement,
  GoogleMap,
  LatLngBounds,
  LatLngBoundsLiteral,
  LatLngLiteral,
  PinElement,
  PinElementOptions,
  PlaceResult,
};
