import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UserInvestorUserOnboardingStatusValues = [
  "PENDING_ONBOARDING",
  "LINK_SENT",
  "FULLY_ONBOARDED",
] as const;

const UserInvestorUserOnboardingStatusEffectSchema = Schema.Literal(
  ...UserInvestorUserOnboardingStatusValues,
);

type UserInvestorUserOnboardingStatus = Schema.Schema.Type<
  typeof UserInvestorUserOnboardingStatusEffectSchema
>;

const UserInvestorUserOnboardingStatusEnum = castEnum(
  UserInvestorUserOnboardingStatusEffectSchema,
);

function randomUserInvestorUserOnboardingStatus(): UserInvestorUserOnboardingStatus {
  return rand(UserInvestorUserOnboardingStatusValues);
}

export {
  randomUserInvestorUserOnboardingStatus,
  UserInvestorUserOnboardingStatusEffectSchema,
  UserInvestorUserOnboardingStatusEnum,
  UserInvestorUserOnboardingStatusValues,
};
export type { UserInvestorUserOnboardingStatus };
