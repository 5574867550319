/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}/areas
 * @function getAreas
 * @memberof InspectionAPI
 * @param {InspectionAPIGetAreasPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionAreaResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionAreaResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetAreasPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIGetAreasSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetAreasPayload = InspectionAPIGetAreasPathParams &
  InspectionAPIGetAreasSearchParams;

function getAreasUnsafeEffect(payload: InspectionAPIGetAreasPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionAreaResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionAreaResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}/areas`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAreas(
  payload: InspectionAPIGetAreasPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionAreaResponse[]> {
  return F.pipe(
    payload,
    getAreasUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAreas };
export type {
  InspectionAPIGetAreasPathParams,
  InspectionAPIGetAreasPayload,
  InspectionAPIGetAreasSearchParams,
};
