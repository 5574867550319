/**
 * HTTP Method: POST
 * Pathname: /applications/{appGroupId}/verifyIncome
 * @function verifyApplicantIncomes
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIVerifyApplicantIncomesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIVerifyApplicantIncomesPathParams = {
  appGroupId: EnderId;
};

type ApplicationsAPIVerifyApplicantIncomesSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIVerifyApplicantIncomesPayload =
  ApplicationsAPIVerifyApplicantIncomesPathParams &
    ApplicationsAPIVerifyApplicantIncomesSearchParams;

function verifyApplicantIncomesUnsafeEffect(
  payload: ApplicationsAPIVerifyApplicantIncomesPayload,
) {
  const { appGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${appGroupId}/verifyIncome`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function verifyApplicantIncomes(
  payload: ApplicationsAPIVerifyApplicantIncomesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    verifyApplicantIncomesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { verifyApplicantIncomes };
export type {
  ApplicationsAPIVerifyApplicantIncomesPathParams,
  ApplicationsAPIVerifyApplicantIncomesPayload,
  ApplicationsAPIVerifyApplicantIncomesSearchParams,
};
