import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type { LeaseSerializerLeaseContact } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";
import type { TenantLedgerReportResponse } from "@ender/shared/generated/ender.service.reports.builtin";

import type { AugmentedMoveOutLease } from "../../../widgets/move-out/types";
import { getDeposits } from "./move-out-balance-section/deposits";
import { getRemainingCharges } from "./move-out-balance-section/remaining-charges";

function getFinanciallyResponsibleTenants(lease: AugmentedMoveOutLease) {
  return lease.contacts.filter((contact) =>
    contact.roles.includes(
      LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
    ),
  );
}

function getTotalDepositsValueFromLease(
  lease: AugmentedMoveOutLease,
): Money$.Money {
  return Money$.sum(
    lease.securityDeposits.balances.map((b) => Money$.of(b.amount)),
  );
}

type UseMoveOutProps = {
  lease?: AugmentedMoveOutLease;
};

type UseMoveOutReturn = {
  balanceAmount: Money$.Money;
  chargesTotalAmount: Money$.Money;
  deposits: {
    id: EnderId;
    description: string;
    remainingAmount: Money$.Money;
  }[];
  depositsTotalAmount: Money$.Money;
  remainingCharges: {
    description: string;
    remainingAmount: Money$.Money;
  }[];
  financiallyResponsibleTenants: LeaseSerializerLeaseContact[];
  primaryTenant: LeaseSerializerLeaseContact;
  initialEscrowTransferAmount: Money$.Money;
  ledgerData?: TenantLedgerReportResponse;
};

function useMoveOut(props: UseMoveOutProps): UseMoveOutReturn {
  const { lease } = props;

  const initialEscrowTransferAmount = useMemo(() => {
    if (P.isNotNullable(lease)) {
      return getTotalDepositsValueFromLease(lease);
    }
    return Money$.zero();
  }, [lease]);

  const financiallyResponsibleTenants = useMemo(() => {
    if (P.isNullable(lease)) {
      return [];
    }
    return getFinanciallyResponsibleTenants(lease);
  }, [lease]);

  const primaryTenant = useMemo(
    () => financiallyResponsibleTenants[0],
    [financiallyResponsibleTenants],
  );

  const { data: ledgerData } = useQuery({
    queryKey: [
      "TenantLedgerAPI.getTenantLedger",
      { leaseId: lease?.id },
    ] as const,
    queryFn: ({ signal }) => {
      if (P.isNotNullable(lease)) {
        return TenantLedgerAPI.getTenantLedger(
          {
            leaseId: lease.id,
            selectedCategoryIds: [],
          },
          { signal },
        );
      }
      return UNDEFINED;
    },
    enabled: !P.isNullable(lease),
  });

  const { deposits, depositsTotalAmount } = useMemo(() => {
    if (P.isNullable(lease)) {
      return { deposits: [], depositsTotalAmount: Money$.zero() };
    }
    return getDeposits(lease);
  }, [lease]);

  const { remainingCharges, chargesTotalAmount } = useMemo(() => {
    if (P.isNullable(ledgerData)) {
      return { remainingCharges: [], chargesTotalAmount: Money$.zero() };
    }
    return getRemainingCharges(ledgerData);
  }, [ledgerData]);

  const balanceAmount = useMemo(() => {
    return chargesTotalAmount.subtract(depositsTotalAmount);
  }, [chargesTotalAmount, depositsTotalAmount]);

  return {
    balanceAmount,
    chargesTotalAmount,
    deposits,
    depositsTotalAmount,
    financiallyResponsibleTenants,
    initialEscrowTransferAmount,
    ledgerData,
    primaryTenant,
    remainingCharges,
  };
}

export { useMoveOut };
export type { UseMoveOutReturn };
