import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const RecurringGLJournalEntryFrequencyValues = [
  "MONTHLY",
  "QUARTERLY",
  "YEARLY",
] as const;

const RecurringGLJournalEntryFrequencyEffectSchema = Schema.Literal(
  ...RecurringGLJournalEntryFrequencyValues,
);

type RecurringGLJournalEntryFrequency = Schema.Schema.Type<
  typeof RecurringGLJournalEntryFrequencyEffectSchema
>;

const RecurringGLJournalEntryFrequencyEnum = castEnum(
  RecurringGLJournalEntryFrequencyEffectSchema,
);

function randomRecurringGLJournalEntryFrequency(): RecurringGLJournalEntryFrequency {
  return rand(RecurringGLJournalEntryFrequencyValues);
}

export {
  randomRecurringGLJournalEntryFrequency,
  RecurringGLJournalEntryFrequencyEffectSchema,
  RecurringGLJournalEntryFrequencyEnum,
  RecurringGLJournalEntryFrequencyValues,
};
export type { RecurringGLJournalEntryFrequency };
