import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import type { ReactNode } from "react";
import { forwardRef, useCallback } from "react";

import { ApplicationApproval } from "@ender/entities/application/approval";
import type { EnderId } from "@ender/shared/core";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { ApplicationGroupApprovalResponseApprovalStatusResponse } from "@ender/shared/generated/com.ender.middle.response";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

type ApprovableCardHeaderProps = {
  approval?: ApplicationGroupApprovalResponseApprovalStatusResponse;
  heading?: ReactNode;
  onSuccess?: () => void;
  vehicleId: EnderId;
};

const ApprovableVehicleCardHeader = forwardRef<
  HTMLDivElement,
  ApprovableCardHeaderProps
>(function ApprovableCardHeader(props, ref) {
  const { approval, vehicleId, heading, onSuccess = F.constVoid } = props;

  const { mutateAsync: updateVehicle } = useMutation({
    mutationFn: LeasingAPI.updateVehicle,
    mutationKey: ["LeasingAPI.updateVehicle"] as const,
  });
  const handleApproval = useCallback(async () => {
    await updateVehicle({
      approvalStatus: ApprovableApprovalStatusEnum.APPROVED,
      vehicleId,
    });
    onSuccess();
  }, [vehicleId, updateVehicle, onSuccess]);
  const handleRejection = useCallback(async () => {
    await updateVehicle({
      approvalStatus: ApprovableApprovalStatusEnum.REJECTED,
      vehicleId,
    });
    onSuccess();
  }, [vehicleId, updateVehicle, onSuccess]);
  const handleUnset = useCallback(async () => {
    await updateVehicle({
      approvalStatus: ApprovableApprovalStatusEnum.NEW,
      vehicleId,
    });
    onSuccess();
  }, [vehicleId, updateVehicle, onSuccess]);

  return (
    <Group align={Align.center} ref={ref}>
      {heading}
      {P.isNotNullable(approval) && (
        <ApplicationApproval
          approval={approval}
          onApprove={handleApproval}
          onReject={handleRejection}
          onClear={handleUnset}
        />
      )}
    </Group>
  );
});

export { ApprovableVehicleCardHeader };

export type { ApprovableCardHeaderProps };
