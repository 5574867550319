import type { ReactNode } from "react";
import { useMemo } from "react";
import type {
  ArrayPath,
  FieldArrayWithId,
  UseFieldArrayReturn,
} from "react-hook-form";
import { useFieldArray } from "react-hook-form";

import { cast } from "@ender/shared/types/cast";

import { FormSectionContext } from "../contexts/form-section-context";
import type { FormInput, UseFormReturn } from "../types";

type FormListProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends ArrayPath<FormInput<Form>>,
> = {
  name: Name;
  form: Form;
  children: (props: {
    list: {
      name: `${Name}.${number}`;
      /**
       * The key prop is required to keep track of list items when adding, removing, or rearranging.
       * https://react.dev/learn/rendering-lists#keeping-list-items-in-order-with-key
       */
      key: FieldArrayWithId<FormInput<Form>, Name>["id"];
    }[];
    arrayMethods: UseFieldArrayReturn<FormInput<Form>, Name>;
  }) => ReactNode;
};

/**
 * A special type of Form Section built to use ArrayPath instead of Path.
 */
function FormList<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends ArrayPath<FormInput<Form>>,
>({ name, children: _children, form }: FormListProps<Form, Name>) {
  const arrayMethods = useFieldArray<FormInput<Form>, Name>({
    control: form.control,
    name,
  });

  const list = useMemo(() => {
    return arrayMethods.fields.map((_field, index) => ({
      name: `${name}.${index}`,
      key: _field.id,
    }));
  }, [arrayMethods.fields, name]);

  const children = useMemo(
    () => _children({ list: cast(list), arrayMethods }),
    [_children, list, arrayMethods],
  );

  return (
    <FormSectionContext.Provider value={{}}>
      {children}
    </FormSectionContext.Provider>
  );
}

export { FormList };
