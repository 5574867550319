import { Array as A, Predicate as P } from "effect";
import type { KeyboardEvent, KeyboardEventHandler } from "react";

import type { Key } from "@ender/shared/types/general";

type AtLeastOne<T> = {
  [K in keyof T]: Required<Pick<T, K>> & Partial<T>;
}[keyof T];

type KeyHandlerMap<T> = Record<Key, KeyboardEventHandler<T>>;

function onKeyEvent<T>(
  key: Key | Key[],
  handler?: KeyboardEventHandler<T>,
): KeyboardEventHandler<T> {
  return (e: KeyboardEvent<T>) => {
    const keys = A.isArray(key) ? key : [key];

    if (P.isNotNullable(handler) && keys.includes(e.key as Key)) {
      handler(e);
    }
  };
}

function onKeyEvents<T>(
  keyHandlerMap: AtLeastOne<KeyHandlerMap<T>>,
): KeyboardEventHandler<T> {
  return (e: KeyboardEvent<T>) => {
    const handler = keyHandlerMap[e.key as Key];

    if (P.isNotNullable(handler)) {
      handler(e);
    }
  };
}

export { onKeyEvent, onKeyEvents };
