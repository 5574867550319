import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SQLReportOutputTypeValues = [
  "STRING",
  "MONEY",
  "NUMBER",
  "DATE",
  "BOOLEAN",
  "ENUM",
  "URI",
] as const;

const SQLReportOutputTypeEffectSchema = Schema.Literal(
  ...SQLReportOutputTypeValues,
);

type SQLReportOutputType = Schema.Schema.Type<
  typeof SQLReportOutputTypeEffectSchema
>;

const SQLReportOutputTypeEnum = castEnum(SQLReportOutputTypeEffectSchema);

function randomSQLReportOutputType(): SQLReportOutputType {
  return rand(SQLReportOutputTypeValues);
}

export {
  randomSQLReportOutputType,
  SQLReportOutputTypeEffectSchema,
  SQLReportOutputTypeEnum,
  SQLReportOutputTypeValues,
};
export type { SQLReportOutputType };
