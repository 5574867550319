import { useMemo } from "react";

import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import type { TimelineInvoice } from "@ender/shared/types/ender-general";

function ChatInvoice({ price, status, description }: TimelineInvoice) {
  const { badgeColor, displayStatus } = useMemo(() => {
    switch (status) {
      case "NEW":
        return { badgeColor: BadgeColor.yellow, displayStatus: "Pending" };
      case "APPROVED":
      case "PAID":
        return { badgeColor: BadgeColor.green, displayStatus: "Paid" };
      case "REJECTED":
        return { badgeColor: BadgeColor.red, displayStatus: "Rejected" };
      default:
        return { badgeColor: BadgeColor.slate, displayStatus: "Unknown" };
    }
  }, [status]);

  return (
    <Stack spacing={Spacing.sm}>
      <Group spacing={Spacing.sm} align={Align.center}>
        <Text size={FontSize.md} weight={FontWeight.semibold}>
          Invoice
        </Text>
        <Text size={FontSize.md} weight={FontWeight.semibold}>
          {price}
        </Text>
        <Badge color={badgeColor}>{displayStatus}</Badge>
      </Group>
      <Text size={FontSize.md}>{description}</Text>
    </Stack>
  );
}

export { ChatInvoice };
