import type { ObjectValues } from "./object-values";

const KeyEnum = {
  A: "a",
  Alt: "Alt",
  ArrowDown: "ArrowDown",
  ArrowLeft: "ArrowLeft",
  ArrowRight: "ArrowRight",
  ArrowUp: "ArrowUp",
  AudioVolumeDown: "AudioVolumeDown",
  AudioVolumeMute: "AudioVolumeMute",
  AudioVolumeUp: "AudioVolumeUp",
  B: "b",
  BackQuote: "`",
  BackSlash: "\\",
  Backspace: "Backspace",
  BracketLeft: "[",
  BracketRight: "]",
  C: "c",
  CapsLock: "CapsLock",
  Comma: ",",
  ContextMenu: "ContextMenu",
  Control: "Control",
  D: "d",
  Dash: "-",
  Delete: "Delete",
  E: "e",
  Eight: "8",
  End: "End",
  Enter: "Enter",
  Equals: "=",
  Escape: "Escape",
  F: "f",
  F1: "F1",
  F2: "F2",
  F3: "F3",
  F4: "F4",
  F5: "F5",
  F6: "F6",
  F7: "F7",
  F8: "F8",
  F10: "F10",
  F9: "F9",
  F11: "F11",
  F12: "F12",
  Five: "5",
  Four: "4",
  G: "g",
  H: "h",
  Home: "Home",
  I: "i",
  Insert: "Insert",
  J: "j",
  K: "k",
  L: "l",
  LaunchApplication1: "LaunchApplication1",
  LaunchApplication2: "LaunchApplication2",
  LaunchMediaPlayer: "LaunchMediaPlayer",
  M: "m",
  Meta: "Meta",
  N: "n",
  Nine: "9",
  NumLock: "NumLock",
  O: "o",
  One: "1",
  P: "p",
  PageDown: "PageDown",
  PageUp: "PageUp",
  Pause: "Pause",
  Period: ".",
  Plus: "+",
  PrintScreen: "PrintScreen",
  Q: "q",
  Quote: "'",
  R: "r",
  S: "s",
  ScrollLock: "ScrollLock",
  Semicolon: ";",
  Seven: "7",
  Shift: "Shift",
  Six: "6",
  Slash: "/",
  Space: " ",
  Star: "*",
  T: "t",
  Tab: "Tab",
  Three: "3",
  Two: "2",
  U: "u",
  V: "v",
  W: "w",
  X: "x",
  Y: "y",
  Z: "z",
  Zero: "0",
} as const;

type Key = ObjectValues<typeof KeyEnum>;

export { KeyEnum };
export type { Key };
