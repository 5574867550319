import type { LinkProps as NextLinkProps } from "next/link";
import type { ElementRef, PropsWithChildren } from "react";
import { forwardRef } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

/**
 * use the NextLinkProps so that the shape is the same as the NextLinkProps
 * to make the future swap to next/link easier
 */
type RouterLinkProps = {
  href: Extract<NextLinkProps["href"], string>;
};

/**
 * @deprecated once we are on Next, use `Link` from this directory
 */
const RouterLink = forwardRef<
  ElementRef<typeof ReactRouterLink>,
  PropsWithChildren<RouterLinkProps>
>(function Link(props, ref) {
  const { href, children } = props;
  return (
    <ReactRouterLink ref={ref} to={href} className="flex flex-col">
      {children}
    </ReactRouterLink>
  );
});

export { RouterLink };

export type { RouterLinkProps };
