import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const KitchenKitchenTypeValues = ["GALLEY", "OPEN", "TRADITIONAL"] as const;

const KitchenKitchenTypeEffectSchema = Schema.Literal(
  ...KitchenKitchenTypeValues,
);

type KitchenKitchenType = Schema.Schema.Type<
  typeof KitchenKitchenTypeEffectSchema
>;

const KitchenKitchenTypeEnum = castEnum(KitchenKitchenTypeEffectSchema);

function randomKitchenKitchenType(): KitchenKitchenType {
  return rand(KitchenKitchenTypeValues);
}

export {
  KitchenKitchenTypeEffectSchema,
  KitchenKitchenTypeEnum,
  KitchenKitchenTypeValues,
  randomKitchenKitchenType,
};
export type { KitchenKitchenType };
