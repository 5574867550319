/**
 * HTTP Method: GET
 * Pathname: /markets/{marketId}/photos
 * @function getMarketPhotos
 * @memberof MarketsAPI
 * @param {MarketsAPIGetMarketPhotosPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PhotoServicePhotoInfo>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PhotoServicePhotoInfo } from "@ender/shared/generated/ender.service.files";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MarketsAPIGetMarketPhotosPathParams = {
  marketId: EnderId;
};

type MarketsAPIGetMarketPhotosSearchParams = {
  token?: string | undefined;
};

type MarketsAPIGetMarketPhotosPayload = MarketsAPIGetMarketPhotosPathParams &
  MarketsAPIGetMarketPhotosSearchParams;

function getMarketPhotosUnsafeEffect(
  payload: MarketsAPIGetMarketPhotosPayload,
) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PhotoServicePhotoInfo>({
        body,
        decode: unsafeDecodeJsonResponse<PhotoServicePhotoInfo>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/markets/${marketId}/photos`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMarketPhotos(
  payload: MarketsAPIGetMarketPhotosPayload,
  options?: { signal?: AbortSignal },
): Promise<PhotoServicePhotoInfo> {
  return F.pipe(
    payload,
    getMarketPhotosUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMarketPhotos };
export type {
  MarketsAPIGetMarketPhotosPathParams,
  MarketsAPIGetMarketPhotosPayload,
  MarketsAPIGetMarketPhotosSearchParams,
};
