import { Predicate as P } from "effect";
import { useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, Money } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type {
  BankAccount,
  BankTransaction,
} from "@ender/shared/generated/ender.model.payments";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { ReconcileSolution } from "./reconcile-solution";

import styles from "./reconcile-modal.module.css";

type ReconcileModalProps = {
  accountDetails: BankAccount;
  draw?: Money;
  amount?: Money;
  associatedProperties: {
    id: EnderId;
    name: string;
  }[];
  bankTransaction: BankTransaction;
  closeModal: () => void;
  date?: string;
  deposit: string;
  description?: string;
  refreshTransactions: () => void;
  startBatchMatching: (value: EnderId) => void;
};

function ReconcileModal({
  accountDetails,
  amount,
  associatedProperties,
  bankTransaction,
  closeModal,
  date,
  deposit,
  description,
  refreshTransactions,
  startBatchMatching,
}: ReconcileModalProps) {
  const isDeposit = !!deposit;
  const absoluteAmount = amount?.replace("-", "");
  const dateString = EnderDate.of(date).toShortDateString();
  const property = useMemo(
    () =>
      associatedProperties?.length === 1 ? associatedProperties[0] : UNDEFINED,
    [associatedProperties],
  );

  function onBatchMatchClick() {
    closeModal();
    startBatchMatching(bankTransaction?.id);
  }

  function onInvoiceSuccessfullyCreated(invoiceType: string) {
    closeModal();
    refreshTransactions();

    const invoiceTypeString =
      invoiceType === InvoiceInvoiceTypeEnum.PAYABLE ? "Payable" : "Receivable";

    showSuccessNotification({
      message: `${invoiceTypeString} has been successfully created!`,
    });
  }

  return (
    <Stack>
      <Stack spacing={Spacing.none}>
        {accountDetails && (
          <>
            <Tuple label="Institution" value={accountDetails?.institution} />
            <Tuple
              label="Account"
              value={
                <div>
                  <div>••••{accountDetails?.mask}</div>
                  <div className={styles.accountName}>
                    {accountDetails?.name}
                  </div>
                </div>
              }
            />
          </>
        )}
        <Tuple label="Type" value={isDeposit ? "Deposit" : "Withdrawal"} />
        <Tuple label="Amount" value={absoluteAmount} />
        {P.isNotNullable(property) && (
          <Tuple label="Property" value={property.name} />
        )}
        <Tuple label="Date" value={dateString} />
        <Tuple label="Description" value={description} />
      </Stack>
      <ReconcileSolution
        bankTransaction={bankTransaction}
        isDeposit={isDeposit}
        onBatchMatchClick={onBatchMatchClick}
        onConfirmExclude={closeModal}
        onInvoiceSuccessfullyCreated={onInvoiceSuccessfullyCreated}
        property={property}
        reconcileTransactionAmount={absoluteAmount}
        refreshTransactions={refreshTransactions}
      />
    </Stack>
  );
}

export { ReconcileModal };
