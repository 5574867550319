import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";

import { Form, useForm } from "@ender/form-system/base";
import type { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { pluralize } from "@ender/shared/utils/string";

import type { BankingDetailListTableData } from "../banking-detail-list-columns/banking-detail-list-columns";

const RECONCILE_MODAL_FORM_VALIDATION = {
  MEMO_REQUIRED: "Memo is required",
} as const;

const BankingDetailListFormSchema = Schema.Struct({
  memo: Schema.String.pipe(
    Schema.nonEmptyString({
      message: () => RECONCILE_MODAL_FORM_VALIDATION.MEMO_REQUIRED,
    }),
  ),
});

type BankingDetailListFormValues = Schema.Schema.Type<
  typeof BankingDetailListFormSchema
>;

type ReconcileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: BankingDetailListTableData[];
  totalAmount: Money$.Money;
  onSubmit: (values: BankingDetailListFormValues) => Promise<void>;
};

// Reconcile Modal Component
function ReconcileModal({
  isOpen,
  onClose,
  selectedRows,
  totalAmount,
  onSubmit,
}: ReconcileModalProps) {
  const form = useForm({
    defaultValues: { memo: "" },
    mode: "onSubmit",
    resolver: effectTsResolver(BankingDetailListFormSchema),
  });

  return (
    <Modal title="" opened={isOpen} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        <Stack>
          <H1>
            Manually Reconcile {pluralize("Transaction", selectedRows.length)}?
          </H1>
          <Text>
            You are reconciling {selectedRows.length}{" "}
            {pluralize("transaction", selectedRows.length)} totaling{" "}
            {totalAmount.toFormatted()}, are you sure you would like to
            continue?
          </Text>
          <FormTextarea
            name="memo"
            form={form}
            label="Memo"
            borderless={false}
          />
          <Group justify={Justify.end}>
            <Button onClick={onClose} variant={ButtonVariant.outlined}>
              Go Back
            </Button>
            <Button type="submit">Reconcile</Button>
          </Group>
        </Stack>
      </Form>
    </Modal>
  );
}

export { RECONCILE_MODAL_FORM_VALIDATION, ReconcileModal };
export type { ReconcileModalProps };
