import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { LeaseLeaseSubstatus } from "@ender/shared/generated/ender.model.leasing";
import { LeaseLeaseSubstatusEnum } from "@ender/shared/generated/ender.model.leasing";

const activeLeaseSubstatuses: LeaseLeaseSubstatus[] = [
  LeaseLeaseSubstatusEnum.ACTIVE_EVICTING,
  LeaseLeaseSubstatusEnum.ACTIVE_NON_RENEWABLE,
  LeaseLeaseSubstatusEnum.ACTIVE_RENEWED,
  LeaseLeaseSubstatusEnum.ACTIVE,
  LeaseLeaseSubstatusEnum.EXPIRING,
  LeaseLeaseSubstatusEnum.MONTH_TO_MONTH_POST_TERM,
  LeaseLeaseSubstatusEnum.MONTH_TO_MONTH,
  LeaseLeaseSubstatusEnum.ON_NOTICE,
  LeaseLeaseSubstatusEnum.ON_NOTICE_EVICTING,
  LeaseLeaseSubstatusEnum.MOVE_OUT_OVERDUE,
] as const;

const upcomingLeaseSubstatuses: LeaseLeaseSubstatus[] = [
  LeaseLeaseSubstatusEnum.UPCOMING,
] as const;

const pendingLeaseSubstatuses: LeaseLeaseSubstatus[] = [
  LeaseLeaseSubstatusEnum.CREATING_PDF,
  LeaseLeaseSubstatusEnum.DRAFTING,
  LeaseLeaseSubstatusEnum.DRAFTING_RENEWAL,
  LeaseLeaseSubstatusEnum.FINAL_SIGNATURE,
  LeaseLeaseSubstatusEnum.OUT_FOR_SIGNATURES,
] as const;

const pastLeaseSubstatuses: LeaseLeaseSubstatus[] = [
  LeaseLeaseSubstatusEnum.CANCELED,
  LeaseLeaseSubstatusEnum.MOVE_OUT_BALANCE,
  LeaseLeaseSubstatusEnum.MOVE_OUT_PENDING_DEPOSIT_ACCOUNTING,
  LeaseLeaseSubstatusEnum.MOVE_OUT_REFUND,
  LeaseLeaseSubstatusEnum.MOVED_OUT,
  LeaseLeaseSubstatusEnum.RENEWED,
] as const;

type TaggedLeases = {
  active: LeaseSerializerLeaseResponse[];
  upcoming: LeaseSerializerLeaseResponse[];
  pending: LeaseSerializerLeaseResponse[];
  past: LeaseSerializerLeaseResponse[];
  error: LeaseSerializerLeaseResponse[];
};

export type { TaggedLeases };

export {
  activeLeaseSubstatuses,
  pastLeaseSubstatuses,
  pendingLeaseSubstatuses,
  upcomingLeaseSubstatuses,
};
