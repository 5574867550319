import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SearchApplicationsPageRequestApplicationsPageSortKeyValues = [
  "ACTION_REQUIRED",
  "DATE_STARTED",
] as const;

const SearchApplicationsPageRequestApplicationsPageSortKeyEffectSchema =
  Schema.Literal(...SearchApplicationsPageRequestApplicationsPageSortKeyValues);

type SearchApplicationsPageRequestApplicationsPageSortKey = Schema.Schema.Type<
  typeof SearchApplicationsPageRequestApplicationsPageSortKeyEffectSchema
>;

const SearchApplicationsPageRequestApplicationsPageSortKeyEnum = castEnum(
  SearchApplicationsPageRequestApplicationsPageSortKeyEffectSchema,
);

function randomSearchApplicationsPageRequestApplicationsPageSortKey(): SearchApplicationsPageRequestApplicationsPageSortKey {
  return rand(SearchApplicationsPageRequestApplicationsPageSortKeyValues);
}

export {
  randomSearchApplicationsPageRequestApplicationsPageSortKey,
  SearchApplicationsPageRequestApplicationsPageSortKeyEffectSchema,
  SearchApplicationsPageRequestApplicationsPageSortKeyEnum,
  SearchApplicationsPageRequestApplicationsPageSortKeyValues,
};
export type { SearchApplicationsPageRequestApplicationsPageSortKey };
