import type { PropsWithChildren } from "react";

type Soc2HiddenProps = {
  inline?: boolean;
};

function Soc2Hidden(props: PropsWithChildren<Soc2HiddenProps>) {
  const { children, inline = false } = props;
  const Tag = inline ? "span" : "div";
  return <Tag data-private>{children}</Tag>;
}

export { Soc2Hidden };
export type { Soc2HiddenProps };
