/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/contacts/{tenantId}/remove
 * @function removeLeaseContact
 * @memberof LeasingAPI
 * @param {LeasingAPIRemoveLeaseContactPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIRemoveLeaseContactPathParams = {
  leaseId: EnderId;
  tenantId: EnderId;
};

type LeasingAPIRemoveLeaseContactSearchParams = {
  token?: string | undefined;
};

type LeasingAPIRemoveLeaseContactPayload =
  LeasingAPIRemoveLeaseContactPathParams &
    LeasingAPIRemoveLeaseContactSearchParams;

function removeLeaseContactUnsafeEffect(
  payload: LeasingAPIRemoveLeaseContactPayload,
) {
  const { leaseId, tenantId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/contacts/${tenantId}/remove`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeLeaseContact(
  payload: LeasingAPIRemoveLeaseContactPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeLeaseContactUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeLeaseContact };
export type {
  LeasingAPIRemoveLeaseContactPathParams,
  LeasingAPIRemoveLeaseContactPayload,
  LeasingAPIRemoveLeaseContactSearchParams,
};
