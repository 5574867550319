import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import type { ReactNode } from "react";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationUserResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import { EnderLink } from "@ender/shared/ui/ender-link";
import type { CellProps, ColumnDef } from "@ender/shared/ui/table-tanstack";
import {
  DateCell,
  EllipsisCell,
  MoneyCell,
  asColumnDef,
} from "@ender/shared/ui/table-tanstack";

import { EditButton, TrashButton } from "./check-receipts-table-row-actions";
import type { CheckReceiptsTableRow } from "./check-receipts-table.types";

import styles from "./check-receipts-table.module.css";

function TenantCell({
  getValue,
  row,
}: CellProps<CheckReceiptsTableRow, string | number | LocalDate>): ReactNode {
  const value = getValue();
  const receipt = row.original;

  const { data: applicant } = useQuery<GetApplicationUserResponse, EnderId>({
    enabled: P.isNotNullable(receipt.applicationId),
    queryKey: ["applicationUser", receipt.applicationId],
    queryFn: () => {
      if (P.isNullable(receipt.applicationId)) {
        throw new Error("Should not happen: applicationId is undefined");
      }
      return ApplicationsAPI.getApplicationUser({
        applicationId: receipt.applicationId,
      });
    },
  });

  return P.isNotNullable(receipt.applicationId) ? (
    <EnderLink
      to={`/leasing-center/applications/${applicant?.applicationGroupId}`}
      target="_blank">
      {value}
    </EnderLink>
  ) : (
    <EnderLink to={`/leases/${receipt.leaseId}`} target="_blank">
      {value}
    </EnderLink>
  );
}

function CheckReceiptsActionsCell({
  row,
  table,
}: CellProps<CheckReceiptsTableRow>): ReactNode {
  const { refetch } = table.options.meta;
  const receipt = row.original;

  const isMarkPaidCheck =
    receipt.paymentType === MoneyTransferTransferTypeEnum.MARK_PAID_CHECK;
  if (!isMarkPaidCheck) {
    return NULL;
  }
  return (
    <Group spacing={Spacing.xs}>
      <EditButton receipt={receipt} onSuccess={refetch} />
      <TrashButton />
    </Group>
  );
}

const columns: ColumnDef<CheckReceiptsTableRow>[] = [
  asColumnDef({
    accessorKey: "bankAccount.name",
    className: styles.bankAccount,
    header: "Bank Account",
    id: "bankAccountName",
  }),
  asColumnDef({
    accessorKey: "propertyName",
    cell: EllipsisCell<CheckReceiptsTableRow>,
    className: styles.property,
    header: "Property",
  }),
  asColumnDef({
    accessorKey: "unitName",
    cell: EllipsisCell<CheckReceiptsTableRow>,
    className: styles.unit,
    header: "Unit",
  }),
  asColumnDef({
    accessorKey: "displayPaymentType",
    cell: EllipsisCell<CheckReceiptsTableRow>,
    className: styles.displayPaymentType,
    header: "Payment Type",
  }),
  asColumnDef({
    accessorKey: "checkNumber",
    className: styles.checkNumber,
    header: "Check Number",
  }),
  asColumnDef({
    accessorKey: "receiptDate",
    cell: DateCell<CheckReceiptsTableRow>,
    className: styles.receiptDate,
    header: "Date",
    sortUndefined: -1,
  }),
  asColumnDef({
    accessorKey: "tenantName",
    cell: TenantCell,
    className: styles.tenant,
    header: "Tenant",
  }),
  asColumnDef({
    accessorKey: "memo",
    className: styles.memo,
    enableSorting: false,
    header: "Memo",
  }),
  asColumnDef({
    accessorKey: "amount",
    cell: MoneyCell<CheckReceiptsTableRow>,
    className: styles.amount,
    header: () => <Group justify={Justify.end}>Amount</Group>,
  }),
  asColumnDef({
    cell: CheckReceiptsActionsCell,
    className: styles.actions,
    header: "",
    id: "actions",
  }),
];

export { columns };
