import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Option as O,
  Predicate as P,
  String as S,
  pipe,
} from "effect";
import { useCallback, useContext, useMemo } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId, LocalDate } from "@ender/shared/core";
import type { BankingAPIGetTransactionsPayload } from "@ender/shared/generated/ender.api.accounting";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { ReconTypeFilter } from "@ender/shared/generated/ender.api.accounting.request";
import type { GetTransactionsResponse } from "@ender/shared/generated/ender.api.accounting.response";
import { PeriodFilterFilterTypeEnum } from "@ender/shared/generated/ender.api.model";
import type { BankRecRow } from "@ender/shared/generated/ender.service.accounting.banking";

import { transformToBankRecTableRow } from "./transform-to-bank-rec-table-row";
import { PAGE_SIZE } from "./use-banking-detail-table";

/**
 * 2024-04-11, Geoff:
 * ENDER-17750 wants a hot fix; here it is.
 * I don't want this `emptyResult` hack here either; it should never be needed.
 * 1. `shared-ui-date-range` should not even allow invalid date ranges of this nature.
 * 2. the existing useInfiniteQuery with enabled: isValidDateRange should also have been sufficient,
 *   but there's a deeper re-rendering issue happening that is too much for a hot fix imho.
 * Future:
 * 1. Fix the date range picker to not allow invalid date ranges and then remove this.
 * 2. Super bonus round: figure out why the existing useInfiniteQuery with enabled: isValidDateRange wasn't sufficient.
 */

type UseBankingDetailDataProps = {
  accountId: EnderId;
  amountFilterValue: string;
  descriptionFilterValue: string | Undefined;
  endDate: LocalDate;
  isValidDateRange: boolean;
  periodFilter: LocalDate[];
  propertyFilterValue: EnderId[];
  reconTypeFilter: ReconTypeFilter | undefined;
  showDeposits: boolean;
  showDraws: boolean;
  startDate: LocalDate;
};

function useBankingDetailData({
  accountId,
  amountFilterValue,
  descriptionFilterValue,
  endDate,
  isValidDateRange,
  periodFilter,
  propertyFilterValue,
  reconTypeFilter,
  showDeposits,
  showDraws,
  startDate,
}: UseBankingDetailDataProps) {
  const { user } = useContext(UserContext);

  const {
    data: bankDetails,
    isLoading: isLoadingAccountDetails,
    refetch: getAccountDetails,
  } = useQuery({
    enabled: P.isNotNullable(accountId),
    queryFn: ({ signal }) =>
      BankingAPI.getBankAccount({ bankAccountId: accountId }, { signal }),
    queryKey: ["BankingAPI.getBankAccount", accountId] as const,
    staleTime: 900000, // 900000ms = 15min
  });

  const getBankTransactionsPayload: BankingAPIGetTransactionsPayload = useMemo(
    () => ({
      ...(amountFilterValue && { amountSearchString: amountFilterValue }),
      bankAccountId: accountId,
      ...(P.isString(descriptionFilterValue) &&
        S.isNonEmpty(descriptionFilterValue) && {
          description: descriptionFilterValue,
        }),
      inclusiveEndDate: endDate,
      ...(A.isNonEmptyArray(periodFilter) && {
        periodFilter: {
          customFilter: periodFilter,
          type: PeriodFilterFilterTypeEnum.CUSTOM,
        },
      }),
      limit: PAGE_SIZE,
      propertyIds: propertyFilterValue,
      showDeposits,
      showDraws,
      startDate,
      typeFilter: reconTypeFilter,
    }),
    [
      accountId,
      amountFilterValue,
      descriptionFilterValue,
      endDate,
      periodFilter,
      propertyFilterValue,
      reconTypeFilter,
      showDeposits,
      showDraws,
      startDate,
    ],
  );

  type GetTransactionsQueryKey = BankingAPIGetTransactionsPayload[];

  const {
    fetchNextPage,
    isFetching: isTransactionsQueryRunning,
    data: infiniteGetTransactionsData,
    refetch: transactionRefetch,
    hasNextPage,
  } = useInfiniteQuery<
    GetTransactionsResponse,
    unknown,
    GetTransactionsResponse,
    GetTransactionsQueryKey
  >(
    {
      // @ts-expect-error useQuery says this does not belong here; functionality says otherwise.
      getNextPageParam: (lastPage) =>
        pipe(
          // Access rows safely
          O.fromNullable(lastPage?.bankDetails?.rows),
          // Ensure 'next' and 'results' are present and not empty
          O.filter(
            (rows) =>
              (S.isString(rows.next) || A.isArray(rows.next)) &&
              A.isArray(rows.results) &&
              A.isNonEmptyArray(rows.next) &&
              A.isNonEmptyArray(rows.results),
          ),
          // Extract 'next'
          O.map((rows) => rows.next),
          // Return result or undefined
          O.getOrUndefined,
        ),

      // @ts-expect-error Payload seems to make queryKey unhappy
      queryFn: ({ pageParam = "", queryKey: [, payload], signal }) => {
        if (!isValidDateRange) {
          return {};
        }

        return BankingAPI.getTransactions(
          { ...payload, offsetId: pageParam },
          { signal },
        );
      },
      queryKey: [
        "BankingAPI.getTransactions",
        getBankTransactionsPayload,
      ] as const,
    },
    {
      enabled: isValidDateRange,
    },
  );

  const refreshData = useCallback(() => {
    transactionRefetch();
  }, [transactionRefetch]);

  const {
    bankAccountStats,
    bankAccountTransactions,
    endingAdjustedBalance,
    endingStatementBalance,
    glCashCategoryEndingBalance,
  } = useMemo(() => {
    if (
      P.isNullable(infiniteGetTransactionsData) ||
      P.isNullable(infiniteGetTransactionsData.pages[0].bankDetails)
    ) {
      return {
        bankAccountStats: UNDEFINED,
        bankAccountTransactions: [],
        endingAdjustedBalance: UNDEFINED,
        endingStatementBalance: UNDEFINED,
        glCashCategoryEndingBalance: UNDEFINED,
      };
    }

    const firstPage = infiniteGetTransactionsData.pages[0];
    return {
      bankAccountStats: firstPage.bankDetails.rows?.stats,
      bankAccountTransactions: infiniteGetTransactionsData.pages
        .flatMap((page) => page.bankDetails.rows?.results)
        .map((row, i) =>
          transformToBankRecTableRow(
            row as BankRecRow,
            i,
            user.isPM,
            refreshData,
          ),
        ),
      endingAdjustedBalance: firstPage.bankDetails.endingAdjustedBalance,
      endingStatementBalance: firstPage.bankDetails.endingStatementBalance,
      glCashCategoryEndingBalance: firstPage.glCashCategoryEndingBalance,
    };
  }, [infiniteGetTransactionsData, user.isPM, refreshData]);

  return {
    bankAccountStats,
    bankAccountTransactions,
    bankDetails,
    endingAdjustedBalance,
    endingStatementBalance,
    fetchNextPageOfTransactions: fetchNextPage,
    getAccountDetails,
    glCashCategoryEndingBalance,
    hasNextPage,
    isLoadingAccountDetails,
    isTransactionsQueryRunning,
  };
}

export { useBankingDetailData };
