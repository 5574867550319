import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TenantLedgerAccountingClosureTLInvoicePaidStatusValues = [
  "UNPAID",
  "PARTIALLY_PAID",
  "FULLY_PAID",
] as const;

const TenantLedgerAccountingClosureTLInvoicePaidStatusEffectSchema =
  Schema.Literal(...TenantLedgerAccountingClosureTLInvoicePaidStatusValues);

type TenantLedgerAccountingClosureTLInvoicePaidStatus = Schema.Schema.Type<
  typeof TenantLedgerAccountingClosureTLInvoicePaidStatusEffectSchema
>;

const TenantLedgerAccountingClosureTLInvoicePaidStatusEnum = castEnum(
  TenantLedgerAccountingClosureTLInvoicePaidStatusEffectSchema,
);

function randomTenantLedgerAccountingClosureTLInvoicePaidStatus(): TenantLedgerAccountingClosureTLInvoicePaidStatus {
  return rand(TenantLedgerAccountingClosureTLInvoicePaidStatusValues);
}

export {
  randomTenantLedgerAccountingClosureTLInvoicePaidStatus,
  TenantLedgerAccountingClosureTLInvoicePaidStatusEffectSchema,
  TenantLedgerAccountingClosureTLInvoicePaidStatusEnum,
  TenantLedgerAccountingClosureTLInvoicePaidStatusValues,
};
export type { TenantLedgerAccountingClosureTLInvoicePaidStatus };
