import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BrokersServiceBrokerTypeValues = ["BUYSIDE", "SELLSIDE"] as const;

const BrokersServiceBrokerTypeEffectSchema = Schema.Literal(
  ...BrokersServiceBrokerTypeValues,
);

type BrokersServiceBrokerType = Schema.Schema.Type<
  typeof BrokersServiceBrokerTypeEffectSchema
>;

const BrokersServiceBrokerTypeEnum = castEnum(
  BrokersServiceBrokerTypeEffectSchema,
);

function randomBrokersServiceBrokerType(): BrokersServiceBrokerType {
  return rand(BrokersServiceBrokerTypeValues);
}

export {
  BrokersServiceBrokerTypeEffectSchema,
  BrokersServiceBrokerTypeEnum,
  BrokersServiceBrokerTypeValues,
  randomBrokersServiceBrokerType,
};
export type { BrokersServiceBrokerType };
