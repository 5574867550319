/** @description Dynamically loads font families */
function initializeFontFamily(fontName: string): Promise<Event> {
  return new Promise((resolve, reject) => {
    const fontImportName = fontName.replace(/\s/g, "+");

    const stylesheet = document.createElement("link");
    stylesheet.href = `https://fonts.googleapis.com/css?family=${fontImportName}`;
    stylesheet.rel = "stylesheet";
    stylesheet.onerror = reject;
    stylesheet.onload = resolve;
    document.head.appendChild(stylesheet);
  });
}

export { initializeFontFamily };
