/**
 * HTTP Method: POST
 * Pathname: /pets/{petId}
 * @function updatePet
 * @memberof PetsAPI
 * @param {PetsAPIUpdatePetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIUpdatePetPathParams = {
  petId: EnderId;
};

type PetsAPIUpdatePetSearchParams = {
  token?: string | undefined;
};

type PetsAPIUpdatePetBodyParams = {
  age?: number | undefined;
  approvalStatus?: ApprovableApprovalStatus | undefined;
  breed?: string | undefined;
  isEmotionalSupportAnimal?: boolean | undefined;
  isNeutered?: boolean | undefined;
  name?: string | undefined;
  species?: string | undefined;
  weightInPounds?: number | undefined;
};

type PetsAPIUpdatePetPayload = PetsAPIUpdatePetPathParams &
  PetsAPIUpdatePetSearchParams &
  PetsAPIUpdatePetBodyParams;

function updatePetUnsafeEffect(payload: PetsAPIUpdatePetPayload) {
  const { petId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/pets/${petId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updatePet(
  payload: PetsAPIUpdatePetPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updatePetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updatePet };
export type {
  PetsAPIUpdatePetBodyParams,
  PetsAPIUpdatePetPathParams,
  PetsAPIUpdatePetPayload,
  PetsAPIUpdatePetSearchParams,
};
