import { UNDEFINED } from "@ender/shared/constants/general";
import type { NestedKeysOf } from "@ender/shared/types/general";

import type {
  AccessorColumnDef,
  ColumnDef,
  DisplayColumnDef,
  GroupColumnDef,
} from "../table.types";

// eslint-disable-next-line
function isAccessorColumnDef<RowData, ColumnData = any, FilterData = any>(
  colDef: ColumnDef<RowData, ColumnData, FilterData>,
): colDef is AccessorColumnDef<RowData, ColumnData, FilterData> {
  return "accessorKey" in colDef || "accessorFn" in colDef;
}

// eslint-disable-next-line
function isGroupColumnDef<RowData, ColumnData = any, FilterData = any>(
  colDef: ColumnDef<RowData, ColumnData, FilterData>,
): colDef is GroupColumnDef<RowData, ColumnData, FilterData> {
  return "columns" in colDef;
}

// eslint-disable-next-line
function isDisplayColumnDef<RowData, ColumnData = any, FilterData = any>(
  colDef: ColumnDef<RowData, ColumnData, FilterData>,
): colDef is DisplayColumnDef<RowData, ColumnData, FilterData> {
  return !(
    "accessorKey" in colDef ||
    "accessorFn" in colDef ||
    "columns" in colDef
  );
}

// eslint-disable-next-line
function getColumnIdentifier<RowData, ColumnData = any, FilterData = any>(
  colDef: ColumnDef<RowData, ColumnData, FilterData>,
): string | NestedKeysOf<RowData> | undefined {
  if (isAccessorColumnDef(colDef)) {
    return colDef.id || colDef.accessorKey;
  }

  if (isGroupColumnDef(colDef)) {
    return colDef.id;
  }

  return UNDEFINED;
}

export {
  getColumnIdentifier,
  isAccessorColumnDef,
  isDisplayColumnDef,
  isGroupColumnDef,
};
