import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import type { ElementRef } from "react";
import { forwardRef } from "react";

import type { EnderId } from "@ender/shared/core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  GetApplyInfoResponseUnitResponse,
  GetRenewalsReportResponsePropertyResponse,
} from "@ender/shared/generated/ender.api.leasing.response";

import type { ApplicationListingInfoCardProps } from "./widgets-application-listing-info-card";
import { ApplicationListingInfoCard } from "./widgets-application-listing-info-card";

type ConnectedApplicationListingInfoCardProps = {
  listingId?: EnderId;
  unit?: GetApplyInfoResponseUnitResponse;
  property?: GetRenewalsReportResponsePropertyResponse;
};

const ConnectedApplicationListingInfoCard = forwardRef<
  ElementRef<typeof ApplicationListingInfoCard>,
  ConnectedApplicationListingInfoCardProps
>(function ConnectedApplicationListingInfoCard(props, ref) {
  const { listingId, unit, property } = props;
  const defaultListing: ApplicationListingInfoCardProps["listing"] = {
    bedrooms: unit?.bedrooms,
    fullBaths: unit?.bathrooms,
    marketName: property?.marketName,
    property: property,
    sqft: unit?.sqft,
  };
  const { data: listing = defaultListing, isInitialLoading: loading } =
    useQuery(
      ["LeasingAPI.getListingDetailsByListingId", listingId] as const,
      ({ queryKey: [, listingId] }) => {
        if (P.isNotNullable(listingId)) {
          return LeasingAPI.getListingDetailsByListingId({ listingId });
        }
      },
      { enabled: P.isNotNullable(listingId) },
    );
  return (
    <ApplicationListingInfoCard
      listing={listing}
      unit={unit}
      ref={ref}
      loading={loading}
    />
  );
});

export { ConnectedApplicationListingInfoCard };

export type { ConnectedApplicationListingInfoCardProps };
