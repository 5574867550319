import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Text } from "@ender/shared/ds/text";
import { LeasingMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import { fail } from "@ender/shared/utils/error";

import { ListingInfoWidgetView } from "./listing-info-view";

type ListingInfoWidgetProps = {
  propertyId: EnderId;
  unitId?: EnderId;
};

function ListingInfoWidget(
  props: ListingInfoWidgetProps & { borderless?: boolean },
) {
  const { propertyId, unitId, borderless = true } = props;

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: [
      "LeasingMiddleLayerAPI.getListingDetailsWidget",
      { propertyId, unitId },
    ],
    queryFn: () =>
      LeasingMiddleLayerAPI.getListingDetailsWidget({ propertyId, unitId }),
    onError: fail,
  });

  if (P.isNullable(data)) {
    return null;
  }

  const {
    baths,
    beds,
    investor,
    listed,
    listingId,
    market,
    propertyName,
    rent,
    sqft,
    submittedApplicationCount,
    unitName,
    unitStatus,
  } = data;

  return (
    <Skeleton visible={isLoading}>
      {isSuccess ? (
        <ListingInfoWidgetView
          borderless={borderless}
          baths={baths}
          beds={beds}
          investor={investor}
          listed={listed}
          listingId={listingId}
          market={market}
          rent={rent}
          sqft={sqft}
          submittedApplicationCount={submittedApplicationCount}
          unitStatus={unitStatus}
          unitName={unitName}
          propertyName={propertyName}
        />
      ) : (
        <Text>Failed to load.</Text>
      )}
    </Skeleton>
  );
}

export { ListingInfoWidget };
