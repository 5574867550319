import { Schema as S } from "@effect/schema";
import { Option as O } from "effect";

import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import type { LocalDate$ } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";

const GenerateCheckFormSchema = S.Struct({
  amount: S.OptionFromSelf(MoneyEffectSchema),
  bankAccountId: S.OptionFromSelf(EnderIdFormSchema),
  checkDate: LocalDateEffectSchema.pipe(
    S.OptionFromSelf,
    S.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => O.isSome(input),
      {
        message: () => "Date is required",
      },
    ),
  ),
  checkNumber: S.String.pipe(
    S.nonEmptyString({ message: () => "Check Number is required" }),
    S.maxLength(7, {
      message: () => "Check Number cannot be more than 7 characters",
    }),
    S.filter((val) => /^[0-9a-zA-Z.-]*$/.test(val), {
      message: () => "Invalid Check Number",
    }),
  ),
  memo: S.String.pipe(
    S.maxLength(60, { message: () => "Memo must be less than 60 characters" }),
  ),
  periodId: S.OptionFromSelf(
    S.Struct({
      id: EnderIdFormSchema,
    }).pipe(S.mutable),
  ),
  recipientName: S.String.pipe(
    S.nonEmptyString({ message: () => "Recipient Name is required" }),
  ),
  senderName: S.String.pipe(
    S.nonEmptyString({ message: () => "Sender Name is required" }),
  ),
});

type GenerateCheckFormSchemaInput = S.Schema.Encoded<
  typeof GenerateCheckFormSchema
>;
type GenerateCheckFormSchemaOutput = S.Schema.Type<
  typeof GenerateCheckFormSchema
>;

export { GenerateCheckFormSchema };
export type { GenerateCheckFormSchemaInput, GenerateCheckFormSchemaOutput };
