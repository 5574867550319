import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InvoicePayableTypeValues = [
  "GENERAL",
  "RECURRING_VENDOR_PAYMENTS",
  "TENANT_REFUNDS",
  "MANAGEMENT_FEES",
  "TASKS",
  "OWNER_DISTRIBUTIONS",
  "ACQUISITION",
  "LEGAL",
  "MARKETING",
  "ACCOUNTING",
  "PROPERTY_TAXES_3RD_PARTY_MANAGER",
  "INSURANCE",
  "HOA_DUES",
  "HOA_3RD_PARTY_MANAGER",
  "RENOVATION",
  "RIPPLING",
  "TURNOVER",
  "UTILITY_3RD_PARTY_MANAGER",
  "SPECIAL_DAMAGES",
  "MANAGEMENT_FEES_3RD_PARTY_MANAGER",
] as const;

const InvoicePayableTypeEffectSchema = Schema.Literal(
  ...InvoicePayableTypeValues,
);

type InvoicePayableType = Schema.Schema.Type<
  typeof InvoicePayableTypeEffectSchema
>;

const InvoicePayableTypeEnum = castEnum(InvoicePayableTypeEffectSchema);

function randomInvoicePayableType(): InvoicePayableType {
  return rand(InvoicePayableTypeValues);
}

export {
  InvoicePayableTypeEffectSchema,
  InvoicePayableTypeEnum,
  InvoicePayableTypeValues,
  randomInvoicePayableType,
};
export type { InvoicePayableType };
