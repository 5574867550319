import type { Row } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { clsx } from "clsx";
import { Predicate as P } from "effect";
import type { ForwardedRef, RefObject } from "react";
import { forwardRef } from "react";

import { asColumnMeta } from "./table.types";

import styles from "./table.module.css";

type TableRowProps<RowData> = {
  infiniteScrollRef?: RefObject<HTMLDivElement>;
  row: Row<RowData>;
  rowNum: number;
};

const TableRow = forwardRef(function TableRowWithRef<RowData>(
  props: TableRowProps<RowData>,
  ref: ForwardedRef<HTMLTableRowElement>,
) {
  const { infiniteScrollRef, row, rowNum } = props;

  return (
    <>
      {P.isNotNullable(infiniteScrollRef) && (
        <tr className={styles.invisible}>
          <td colSpan={row.getVisibleCells().length}>
            <div ref={infiniteScrollRef} />
          </td>
        </tr>
      )}
      <tr aria-label={`Row ${rowNum + 1}`} ref={ref}>
        {row.getVisibleCells().map((cell) => {
          const className = clsx(
            {
              [styles.stickyEnd]: cell.column.getIsPinned() === "right",
              [styles.stickyStart]: cell.column.getIsPinned() === "left",
            },
            asColumnMeta(cell.column.columnDef.meta).className,
          );

          return (
            <td
              key={cell.id}
              className={className}
              style={{
                maxWidth: cell.column.columnDef.maxSize,
                minWidth: cell.column.columnDef.minSize,
                width: cell.column.columnDef.size,
              }}
              data-accessor-key={cell.column.id}
              data-testid={cell.column.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    </>
  );
}) as <RowData>(
  // eslint-disable-next-line no-use-before-define
  props: TableRowProps<RowData> & { ref?: ForwardedRef<HTMLTableRowElement> },
) => JSX.Element;

export { TableRow };
