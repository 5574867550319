/**
 * PM to update Tenant Payment Allocation Waterfall priority
 * HTTP Method: POST
 * Pathname: /accounting/tenantPaymentAllocationWaterfall
 * @function updateTenantPaymentAllocationWaterfall
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallBodyParams = {
  /**
 * A sorted list of categories flagged with "TENANT_CHARGE_TYPE", ordered from highest priority
                      to lowest priority
 */
  glCategoryIds: EnderId[];
};

type GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallPayload =
  GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallSearchParams &
    GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallBodyParams;

function updateTenantPaymentAllocationWaterfallUnsafeEffect(
  payload: GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/tenantPaymentAllocationWaterfall",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateTenantPaymentAllocationWaterfall(
  payload: GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateTenantPaymentAllocationWaterfallUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateTenantPaymentAllocationWaterfall };
export type {
  GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallBodyParams,
  GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallPayload,
  GeneralLedgerAPIUpdateTenantPaymentAllocationWaterfallSearchParams,
};
