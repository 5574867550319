import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SelectionCriteriaFilterTypeValues = [
  "FIRM",
  "MARKET",
  "PROPERTY",
  "LEASE_STATUS",
] as const;

const SelectionCriteriaFilterTypeEffectSchema = Schema.Literal(
  ...SelectionCriteriaFilterTypeValues,
);

type SelectionCriteriaFilterType = Schema.Schema.Type<
  typeof SelectionCriteriaFilterTypeEffectSchema
>;

const SelectionCriteriaFilterTypeEnum = castEnum(
  SelectionCriteriaFilterTypeEffectSchema,
);

function randomSelectionCriteriaFilterType(): SelectionCriteriaFilterType {
  return rand(SelectionCriteriaFilterTypeValues);
}

export {
  randomSelectionCriteriaFilterType,
  SelectionCriteriaFilterTypeEffectSchema,
  SelectionCriteriaFilterTypeEnum,
  SelectionCriteriaFilterTypeValues,
};
export type { SelectionCriteriaFilterType };
