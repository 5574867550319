import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskTagSystemTagValues = [
  "PRE_MOVE_IN_WALK",
  "OCCUPANCY_CHECK",
  "VACANT_PROPERTY_WALK",
  "MAINTENANCE_REQUEST",
  "REQUESTING_MARKET_CERTIFICATION",
  "RECURRING_CLEANING",
  "RECURRING_POOL",
  "RECURRING_LAWN",
  "RECURRING_PEST_CONTROL",
  "LOCKOUT_REMOVAL_OF_ITEMS",
  "MOVE_IN_ORIENTATION",
  "VIOLATION_HOA",
  "VIOLATION_CITY",
  "HCV_INSPECTION",
  "BE_HOME",
  "UTILITIES",
  "WINTERIZE_HOME",
  "DEWINTERIZATION",
  "OTHER",
] as const;

const TaskTagSystemTagEffectSchema = Schema.Literal(...TaskTagSystemTagValues);

type TaskTagSystemTag = Schema.Schema.Type<typeof TaskTagSystemTagEffectSchema>;

const TaskTagSystemTagEnum = castEnum(TaskTagSystemTagEffectSchema);

function randomTaskTagSystemTag(): TaskTagSystemTag {
  return rand(TaskTagSystemTagValues);
}

export {
  randomTaskTagSystemTag,
  TaskTagSystemTagEffectSchema,
  TaskTagSystemTagEnum,
  TaskTagSystemTagValues,
};
export type { TaskTagSystemTag };
