import type {
  QueryKey,
  QueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useMemo } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type { BankingAPIGetAccountsForPartyPayload } from "@ender/shared/generated/ender.api.accounting";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import type {
  BankAccount,
  Party,
} from "@ender/shared/generated/ender.model.payments";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";

import type { BatchableInvoice } from "./invoice-batches.types";

type BankAccountByPartyResponse = {
  id: EnderId;
  bankAccounts: BankAccount[];
};

type BankAccountByPartyMap = Record<EnderId, BankAccount[]>;

/**
 * Builds the payload for fetching accounts of a party.
 */
function getAccountsForPartyPayload(owedToParty: {
  id: EnderId;
  type: Party;
}): BankingAPIGetAccountsForPartyPayload | Undefined {
  if (
    owedToParty.type === PartyEnum.EXTERNAL ||
    P.isUndefined(owedToParty.id)
  ) {
    return;
  }
  return {
    modelId: owedToParty.id,
    party: owedToParty.type,
  };
}

function hasValidId(party: {
  id?: EnderId;
  type: Party;
}): party is { id: EnderId; type: Party } {
  return !P.isUndefined(party.id);
}

/**
 * Maps invoices to unique payloads for fetching bank accounts.
 */
function mapInvoicesToOwedToPartyPayloads(
  invoices: BatchableInvoice[],
): BankingAPIGetAccountsForPartyPayload[] {
  const uniquePayloads = invoices
    .map((invoice) => invoice.owedToParty)
    .filter(hasValidId)
    .map(getAccountsForPartyPayload)
    .filter((payload): payload is BankingAPIGetAccountsForPartyPayload =>
      P.isNotUndefined(payload),
    );

  // Deduplicate payloads by `modelId`
  const payloadMap = new Map(
    uniquePayloads.map((payload) => [payload.modelId, payload]),
  );
  return Array.from(payloadMap.values());
}

/**
 * Executes queries for fetching bank accounts for each party payload.
 */
function useBankAccounts(
  payloads: BankingAPIGetAccountsForPartyPayload[],
): UseQueryResult<BankAccountByPartyResponse>[] {
  return useQueries<
    Omit<
      QueryOptions<
        Promise<BankAccountByPartyResponse>,
        unknown,
        BankAccountByPartyResponse,
        QueryKey
      >,
      "context"
    >[]
  >({
    queries: payloads.map((payload) => ({
      queryFn: async ({ queryKey: [, data], signal }) => {
        const payload = data as BankingAPIGetAccountsForPartyPayload;
        try {
          const resp = await BankingAPI.getAccountsForParty(payload, {
            signal,
          });
          return { bankAccounts: resp, id: payload.modelId };
        } catch (error) {
          console.error(
            `Failed to fetch bank accounts for party ${payload.modelId}:`,
            error,
          );
          throw error;
        }
      },
      queryKey: ["BankingAPI.getAccountsForParty", payload],
    })),
  }) as UseQueryResult<BankAccountByPartyResponse>[];
}

/**
 * Memoizes and reduces query results into a map of bank accounts by party ID.
 */
function useAggregateBankAccountsByParty(
  results: UseQueryResult<BankAccountByPartyResponse>[],
): BankAccountByPartyMap {
  return useMemo(() => {
    return results.reduce<BankAccountByPartyMap>((acc, { data }) => {
      if (P.isNotUndefined(data)) {
        acc[data.id] = data.bankAccounts;
      }
      return acc;
    }, {});
  }, [results]);
}

/**
 * Hook to fetch bank accounts for the parties owed to in the provided invoices.
 */
function useBankAccountsByOwedToPartyIdForInvoices(
  invoices: BatchableInvoice[],
): BankAccountByPartyMap {
  const owedToPartyPayloads = useMemo(
    () => mapInvoicesToOwedToPartyPayloads(invoices),
    [invoices],
  );
  const bankAccountQueries = useBankAccounts(owedToPartyPayloads);
  return useAggregateBankAccountsByParty(bankAccountQueries);
}

export { useBankAccountsByOwedToPartyIdForInvoices };
