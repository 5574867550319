import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { Array as A, Option as O } from "effect";
import { useMemo } from "react";

import { useForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import { EnderIdFormSchema, Money$ } from "@ender/shared/core";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { getInvoicePropertyIds } from "@ender/shared/utils/invoice-utils";
import { isSingle } from "@ender/shared/utils/is";

const AllocationSchema = Schema.Struct({
  accountId: Schema.OptionFromSelf(EnderIdFormSchema),
  amount: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  description: Schema.String,
  isParentCategory: Schema.Boolean.pipe(Schema.optional),
  payableCategoryId: Schema.OptionFromSelf(EnderIdFormSchema),
});

const AllocationsByPropertySchema = Schema.Struct({
  allocations: Schema.Array(AllocationSchema).pipe(Schema.mutable),
  propertyId: Schema.OptionFromSelf(EnderIdFormSchema),
});

const EditInvoiceAllocationsFormSchema = Schema.Struct({
  allocationsByProperty: Schema.Array(AllocationsByPropertySchema).pipe(
    Schema.mutable,
  ),
  invoiceAmount: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
});

type AllocationSchemaInput = Schema.Schema.Encoded<typeof AllocationSchema>;
type AllocationSchemaOutput = Schema.Schema.Type<typeof AllocationSchema>;

type AllocationsByPropertyValuesInput = Schema.Schema.Encoded<
  typeof AllocationsByPropertySchema
>;
type AllocationsByPropertyValuesOutput = Schema.Schema.Type<
  typeof AllocationsByPropertySchema
>;

type EditInvoiceAllocationsFormInput = Schema.Schema.Encoded<
  typeof EditInvoiceAllocationsFormSchema
>;
type EditInvoiceAllocationsFormOutput = Schema.Schema.Type<
  typeof EditInvoiceAllocationsFormSchema
>;

const emptyAllocationsWithProperty = AllocationsByPropertySchema.make({
  allocations: [
    {
      accountId: O.none(),
      amount: O.none(),
      description: "",
      isParentCategory: UNDEFINED,
      payableCategoryId: O.none(),
    },
  ],
  propertyId: O.none(),
});

function getInitialAllocationsByProperty(
  invoice: InvoiceSerializerInvoiceResponse,
): AllocationsByPropertyValuesOutput[] {
  const { allocationsByProperty } = invoice.currentState;
  if (A.isEmptyArray(allocationsByProperty)) {
    const allocationsByProperty = { ...emptyAllocationsWithProperty };
    const propertyIds = getInvoicePropertyIds(invoice);
    if (isSingle(propertyIds)) {
      allocationsByProperty.propertyId = O.fromNullable(propertyIds[0]);
    }
    return [allocationsByProperty];
  }

  return allocationsByProperty.map((property) => ({
    allocations: property.allocations.map((allocation) => {
      return {
        accountId: O.fromNullable(allocation.accountId),
        amount: Money$.parse(allocation.amount),
        description: allocation.description ?? "",
        isParentCategory: UNDEFINED,
        payableCategoryId: O.fromNullable(allocation.payableCategoryId),
      };
    }),
    propertyId: O.fromNullable(property.propertyId),
  }));
}

function useEditInvoiceAllocationsForm({
  invoice,
}: {
  invoice: InvoiceSerializerInvoiceResponse;
}) {
  const defaultValues = useMemo(
    () => ({
      allocationsByProperty: getInitialAllocationsByProperty(invoice),
      invoiceAmount: Money$.parse(invoice.amount),
    }),
    [invoice],
  );

  const form = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: effectTsResolver(EditInvoiceAllocationsFormSchema),
  });
  return {
    form,
  };
}

export {
  EditInvoiceAllocationsFormSchema,
  emptyAllocationsWithProperty,
  useEditInvoiceAllocationsForm,
};
export type {
  AllocationsByPropertyValuesInput,
  AllocationsByPropertyValuesOutput,
  AllocationSchemaInput,
  AllocationSchemaOutput,
  EditInvoiceAllocationsFormInput,
  EditInvoiceAllocationsFormOutput,
};
