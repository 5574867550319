import { useInfiniteQuery } from "@tanstack/react-query";
import type { SortingState } from "@tanstack/react-table";
import { Array as A, Option as O, Predicate as P } from "effect";
import { isNotNullable, isNull } from "effect/Predicate";
import { useEffect, useMemo } from "react";
import { z } from "zod";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload } from "@ender/shared/generated/com.ender.middle";
import { PaymentsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type {
  SortBySortColumn,
  TenantReceiptDepositSearchRequestSortBy,
} from "@ender/shared/generated/com.ender.middle.request";
import { SortBySortColumnEnum } from "@ender/shared/generated/com.ender.middle.request";
import type {
  TenantReceiptDepositSearchResponse,
  TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow,
} from "@ender/shared/generated/com.ender.middle.response";
import { MoneyTransferTransferStatusEnum } from "@ender/shared/generated/ender.model.payments";
import {
  DEFAULT_ACCOUNTING_END_DATE,
  DEFAULT_ACCOUNTING_START_DATE,
  useAccountingFiltersStore,
} from "@ender/shared/stores/accounting-filters-store";
import {
  useTable,
  useTableFilter,
  useTableSorting,
} from "@ender/shared/ui/table-tanstack";
import { castEnum } from "@ender/shared/utils/zod";

import {
  getPeriodFilterProp,
  processDate,
} from "../../../../shared/utils/accounting-utils";
import { columns } from "./check-deposits-table-columns";

const PAGE_SIZE = 100;

function useGetInitialColumnFilters() {
  const {
    accountingPropertyFilter,
    amount,
    endDate: endDateInclusive,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    startDate,
  } = useAccountingFiltersStore();

  const initialFilters = [];

  if (!isNull(amount)) {
    initialFilters.push({
      id: "amount",
      value: amount,
    });
  }

  if (endDateInclusive !== DEFAULT_ACCOUNTING_END_DATE) {
    initialFilters.push({
      id: "depositEndDate",
      value: endDateInclusive,
    });
  }

  if (!isNull(firmFilter)) {
    initialFilters.push({
      id: "firm",
      value: firmFilter,
    });
  }

  if (!isNull(fundFilter)) {
    initialFilters.push({
      id: "fund",
      value: fundFilter,
    });
  }

  if (!isNull(paymentType)) {
    initialFilters.push({
      id: "paymentType",
      value: paymentType,
    });
  }

  if (A.isNonEmptyArray(periodFilter)) {
    initialFilters.push({
      id: "period",
      value: periodFilter,
    });
  }

  if (!isNull(accountingPropertyFilter)) {
    initialFilters.push({
      id: "propertyName",
      value: accountingPropertyFilter,
    });
  }

  if (startDate !== DEFAULT_ACCOUNTING_START_DATE) {
    initialFilters.push({
      id: "depositStartDate",
      value: startDate,
    });
  }

  return initialFilters;
}

const SortKeyValues = [
  "amount",
  "bankAccount_name",
  "checkNumber",
  "depositDate",
  "displayPaymentType",
  "propertyName",
  "receiptDate",
  "tenantName",
] as const;
const SortKeySchema = z.enum(SortKeyValues);
type SortKey = z.infer<typeof SortKeySchema>;
const SortKeyEnum = castEnum<SortKey>(SortKeySchema);

const ColumnToSortColumnMap: Record<SortKey, SortBySortColumn> = {
  [SortKeyEnum.amount]: SortBySortColumnEnum.AMOUNT,
  [SortKeyEnum.bankAccount_name]: SortBySortColumnEnum.BANK_ACCOUNT_NAME,
  [SortKeyEnum.checkNumber]: SortBySortColumnEnum.CHECK_NUMBER,
  [SortKeyEnum.depositDate]: SortBySortColumnEnum.DEPOSIT_DATE,
  [SortKeyEnum.displayPaymentType]: SortBySortColumnEnum.PAYMENT_TYPE,
  [SortKeyEnum.propertyName]: SortBySortColumnEnum.PROPERTY_NAME,
  [SortKeyEnum.receiptDate]: SortBySortColumnEnum.RECEIPT_DATE,
  [SortKeyEnum.tenantName]: SortBySortColumnEnum.TENANT_NAME,
} as const;

type MakeCheckDepositsRequestPayloadArgs = Pick<
  ReturnType<typeof useAccountingFiltersStore>,
  | "accountingPropertyFilter"
  | "amount"
  | "endDate"
  | "firmFilter"
  | "fundFilter"
  | "paymentType"
  | "periodFilter"
  | "startDate"
> & {
  sortingState: SortingState;
};

function makeCheckDepositsRequestPayload(
  args: MakeCheckDepositsRequestPayloadArgs,
): PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload {
  const {
    accountingPropertyFilter,
    amount,
    endDate: endDateInclusive,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    sortingState,
    startDate,
  } = args;

  const propertyIds = A.isNonEmptyArray(accountingPropertyFilter)
    ? accountingPropertyFilter?.map((prop) => prop.id)
    : [];
  const periods = getPeriodFilterProp(periodFilter)?.customFilter;

  return {
    filterBy: {
      /**
       * BE IS FILTERING BY STRING, NOT MONEY
       * On top of that, they are expecting cents as a string
       * We filter off .00 so we can do "fuzzy" filtering
       */
      amountMatch: O.fromNullable(amount).pipe(
        O.match({
          onNone: () => UNDEFINED,
          onSome: (v) =>
            v.valueInCents % 100 === 0
              ? (v.valueInCents / 100).toString()
              : v.valueInCents.toString(),
        }),
      ),
      endDateInclusive,
      firmIds: firmFilter,
      fundIds: fundFilter,
      paymentTypes: !isNull(paymentType) ? [paymentType] : [],
      periods: periods?.map(processDate) || [],
      propertyIds,
      startDate,
      transferStatus: MoneyTransferTransferStatusEnum.NORMAL,
    },
    sortBy: sortingState.reduce<TenantReceiptDepositSearchRequestSortBy[]>(
      (acc, { id, desc }) => {
        const sortColumn = SortKeyValues.includes(id as SortKey)
          ? ColumnToSortColumnMap[id as SortKey]
          : UNDEFINED;
        if (P.isNullable(sortColumn)) {
          return acc;
        }

        return acc.concat({
          column: sortColumn,
          descending: desc,
        });
      },
      [],
    ),
  };
}

type UseCheckDepositsQueryProps = {
  sortingState: SortingState;
};

function useCheckDepositsQuery({ sortingState }: UseCheckDepositsQueryProps) {
  const {
    accountingPropertyFilter,
    amount,
    dateValidationState,
    endDate,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    startDate,
  } = useAccountingFiltersStore();

  const checkDepositsRequestPayload = useMemo(() => {
    return makeCheckDepositsRequestPayload({
      accountingPropertyFilter,
      amount,
      endDate,
      firmFilter,
      fundFilter,
      paymentType,
      periodFilter,
      sortingState,
      startDate,
    });
  }, [
    accountingPropertyFilter,
    amount,
    endDate,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    sortingState,
    startDate,
  ]);
  return useInfiniteQuery<
    TenantReceiptDepositSearchResponse,
    unknown,
    TenantReceiptDepositSearchResponse,
    [string, PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload]
  >(
    ["getDeposits", checkDepositsRequestPayload],
    ({
      pageParam = "",
      queryKey: [, _checkDepositsRequestPayload],
      signal,
    }) => {
      return PaymentsMiddleLayerAPI.tenantReceiptDepositSearch(
        {
          ..._checkDepositsRequestPayload,
          limit: PAGE_SIZE,
          startAfterId: pageParam,
        },
        {
          signal,
        },
      );
    },
    {
      enabled: dateValidationState.isValidDateRange,
      getNextPageParam: (lastPage) => {
        return lastPage.results.length < PAGE_SIZE
          ? UNDEFINED
          : lastPage.results[PAGE_SIZE - 1].id;
      },
    },
  );
}

const initialSorting = [
  { desc: true, id: "depositDate" },
  { desc: true, id: "depositId" },
];

function useCheckDepositsTable() {
  const {
    updateAccountingPropertyFilter,
    updateAmount,
    updateEndDate,
    updateFirmFilter,
    updateFundFilter,
    updatePaymentType,
    updatePeriodFilter,
    updateStartDate,
  } = useAccountingFiltersStore();
  const sorting = useTableSorting({
    initialSorting,
    manualSorting: true,
  });
  const { sorting: sortingState, onSortingChange } = sorting;

  const {
    data: fetchedDepositsData,
    refetch,
    isInitialLoading,
    isFetching,
    fetchNextPage,
  } = useCheckDepositsQuery({ sortingState });

  const data = useMemo(() => {
    if (!isNotNullable(fetchedDepositsData)) {
      return [];
    }
    return fetchedDepositsData.pages.flatMap(({ results }) => results);
  }, [fetchedDepositsData]);

  const initialColumnFilters = useGetInitialColumnFilters();
  const columnFilters =
    useTableFilter<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        enableGlobalFilter: false,
        initialColumnFilters,
        manualFiltering: true,
      },
    );
  const { columnFilters: columnFiltersState } = columnFilters;

  // Sync filter reset with accountingFilterStore
  useEffect(() => {
    if (A.isNonEmptyArray(columnFiltersState)) {
      return;
    }

    onSortingChange(initialSorting);
    updateAccountingPropertyFilter([]);
    updateAmount(NULL);
    updateEndDate(DEFAULT_ACCOUNTING_END_DATE);
    updateFirmFilter([]);
    updateFundFilter([]);
    updatePaymentType(NULL);
    updatePeriodFilter([]);
    updateStartDate(DEFAULT_ACCOUNTING_START_DATE);

    // empty the filters
  }, [
    columnFiltersState,
    onSortingChange,
    updateAccountingPropertyFilter,
    updateAmount,
    updateEndDate,
    updateFirmFilter,
    updateFundFilter,
    updatePaymentType,
    updatePeriodFilter,
    updateStartDate,
  ]);

  const table =
    useTable<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        columnFilters,
        columns,
        data,
        fetchNextPage,
        isLoading: isInitialLoading || isFetching,
        // @ts-ignore Type mismatch between useTableParams and useQuery.refetch
        refetch,
        sorting,
        title: "Tenant Deposits",
        totalResults: fetchedDepositsData?.pages[0]?.totalResults ?? 0,
      },
    );

  return { table, sortingState };
}

export { makeCheckDepositsRequestPayload, useCheckDepositsTable };
