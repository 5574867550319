import { IconChevronDown } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Group } from "@ender/shared/ds/group";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  ApplicationGroup,
  ApplicationGroupApplicationStatus,
} from "@ender/shared/generated/ender.model.leasing";
import { ApplicationGroupApplicationStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import type { ApplicationRightRailValue } from "@ender/widgets/utils/application-page-right-rail";
import { ApplicationPageRightRailEnum } from "@ender/widgets/utils/application-page-right-rail";

import { ApplicationGroupRecommendedAction } from "./recommended-action";

const finalStatuses: ApplicationGroupApplicationStatus[] = [
  ApplicationGroupApplicationStatusEnum.ACCEPTED,
  ApplicationGroupApplicationStatusEnum.REJECTED,
];

const initialReviewStatuses: ApplicationGroupApplicationStatus[] = [
  ApplicationGroupApplicationStatusEnum.INITIAL_ACCEPTED,
  ApplicationGroupApplicationStatusEnum.INITIAL_REJECTED,
];

const unsettableStatuses: ApplicationGroupApplicationStatus[] = [
  ApplicationGroupApplicationStatusEnum.INITIAL_ACCEPTED,
  ApplicationGroupApplicationStatusEnum.ACCEPTED,
  ApplicationGroupApplicationStatusEnum.INITIAL_REJECTED,
  ApplicationGroupApplicationStatusEnum.REJECTED,
];

type ApplicationGroupActionsMenuProps = {
  applicationGroup: Pick<
    ApplicationGroup,
    "id" | "status" | "archiveTime" | "completedAt"
  >;
  leaseId?: EnderId;
  setRightRail: (rightRail: ApplicationRightRailValue) => void;
  refetchApplication: () => void;
  isEditable?: boolean;
};

const ApplicationGroupActionsMenu = forwardRef<
  ElementRef<typeof MenuContent>,
  ApplicationGroupActionsMenuProps
>(function ApplicationGroupActionsMenu(props, ref) {
  const {
    applicationGroup,
    leaseId,
    setRightRail,
    refetchApplication,
    isEditable = false,
  } = props;
  const { permissions, hasPermissions, userPM } = useContext(UserContext);
  const confirmation = useContext(ConfirmationContext);
  const history = useHistory();
  const hasReportsPermission =
    permissions[FunctionalPermissionEnum.RUN_CONSUMER_REPORTS];
  const twoStepReviewEnabled = !!userPM.twoStepReviewForApplications;

  /**
   * whether the application is reviewable.
   * The application is reviewable if:
   * - the user has the Application Reviewer permission
   * - the application is not in a final status
   *
   * - the previous requirements, AND
   * IF two-step review is enabled:
   * - the application is NOT in one of the initial review statuses (meaning this would be its first review) OR
   * - it is, and the user has the Final Application Reviewer permission
   */
  const isReviewable =
    hasPermissions(FunctionalPermissionEnum.APPLICATION_REVIEWER) &&
    !finalStatuses.includes(applicationGroup.status) &&
    (twoStepReviewEnabled
      ? !initialReviewStatuses.includes(applicationGroup.status) ||
        hasPermissions(FunctionalPermissionEnum.FINAL_APPLICATION_REVIEWER)
      : true);

  const hasInitiallyReviewed = initialReviewStatuses.includes(
    applicationGroup.status,
  );

  const isRunReportsVisible = useMemo(() => {
    return (
      hasReportsPermission && P.isNotNullable(applicationGroup?.completedAt)
    );
  }, [applicationGroup, hasReportsPermission]);

  const onRunScreeningReportsClick = useCallback(async () => {
    if (!applicationGroup) {
      return;
    }

    try {
      await ApplicationsAPI.screenApplication({
        appGroupId: applicationGroup.id,
      });
      showSuccessNotification({ message: "Application screening reports ran" });
      refetchApplication();
    } catch (err) {
      fail(err);
    }
  }, [applicationGroup, refetchApplication]);

  const onAcceptApplicationClick = useCallback(async () => {
    if (P.isNullable(applicationGroup)) {
      return;
    }

    try {
      if (
        applicationGroup.status ===
        ApplicationGroupApplicationStatusEnum.IN_PROGRESS
      ) {
        await confirmation({
          confirmButtonLabel: "Accept",
          content: "Not all applicants have been screened.",
          title: "Are you sure you would like to accept the application?",
        });
      }

      await ApplicationsAPI.acceptApplication({
        applicationGroupId: applicationGroup.id,
        message: "",
      });
      showSuccessNotification({ message: "Application accepted" });
      refetchApplication();
    } catch (err) {
      fail(err);
    }
  }, [applicationGroup, refetchApplication, confirmation]);

  const handleUnsetDecision = useCallback(async () => {
    if (!applicationGroup) {
      return;
    }

    try {
      await ApplicationsAPI.unsetDecision({
        applicationGroupId: applicationGroup.id,
      });
      showSuccessNotification({
        message: "Application Reverted to Initial Review",
      });
      refetchApplication();
    } catch (err) {
      fail(err);
    }
  }, [applicationGroup, refetchApplication]);

  const onArchiveApplicationClick = useCallback(async () => {
    if (!applicationGroup) {
      return;
    }

    try {
      await confirmation({
        confirmButtonLabel: "Archive Application",
        content: <Text>Application will be archived.</Text>,
        title: "Are you sure you would like to archive the application?",
      });
      await ApplicationsAPI.archiveApplication({
        applicationGroupId: applicationGroup.id,
      });
      showSuccessNotification({ message: "Application archived" });
      refetchApplication();
    } catch (err) {
      fail(err);
    }
  }, [applicationGroup, refetchApplication, confirmation]);

  const { mutate: onUnarchiveApplicationClick } = useMutation({
    mutationFn: ApplicationsAPI.unarchiveApplication,
    onSuccess: () => {
      showSuccessNotification({ message: "Application unarchived" });
      refetchApplication();
    },
    onError: (err) => fail(err),
  });

  const onRejectApplicationClick = useCallback(async () => {
    if (!applicationGroup) {
      return;
    }

    try {
      if (
        applicationGroup.status ===
        ApplicationGroupApplicationStatusEnum.INITIAL_REJECTED
      ) {
        await confirmation({
          confirmButtonLabel: "Reject Application",
          content: <Text>Application will change to Rejected status.</Text>,
          title: "Are you sure you would like to reject the application?",
        });
      }
      await ApplicationsAPI.rejectApplicationV2({
        applicationGroupId: applicationGroup.id,
      });
      showSuccessNotification({ message: "Application rejected" });
      refetchApplication();
    } catch (err) {
      fail(err);
    }
  }, [applicationGroup, refetchApplication, confirmation]);

  const { status } = applicationGroup;

  return (
    <Group>
      {P.isNotNullable(leaseId) && (
        <EnderLink to={`/leases/${leaseId}`}>
          <Button>Open Lease Page</Button>
        </EnderLink>
      )}
      <ApplicationGroupRecommendedAction
        applicationGroup={applicationGroup}
        leaseId={leaseId}
        setRightRail={setRightRail}
      />
      <Menu>
        <MenuTrigger>
          <Button
            variant={ButtonVariant.outlined}
            rightSection={
              <IconChevronDown className="group-data-[state=open]/menu:rotate-180" />
            }>
            Application Actions
          </Button>
        </MenuTrigger>
        <MenuContent align="end" ref={ref}>
          {P.isNullable(leaseId) && isEditable && (
            <MenuButton
              onClick={() =>
                setRightRail({
                  type: ApplicationPageRightRailEnum.CHANGE_PROPERTY_UNIT,
                })
              }>
              Change Property/Unit
            </MenuButton>
          )}
          {!userPM.beHomeEnabled && (
            <MenuButton
              onClick={() =>
                setRightRail({ type: ApplicationPageRightRailEnum.ADD_SHOWING })
              }>
              Add Showing
            </MenuButton>
          )}
          {isEditable && (
            <MenuButton
              onClick={() =>
                setRightRail({
                  type: ApplicationPageRightRailEnum.ADD_APPLICANT,
                })
              }>
              Add Applicant
            </MenuButton>
          )}
          {isEditable && (
            <MenuButton
              onClick={() =>
                setRightRail({
                  type: ApplicationPageRightRailEnum.TRANSFER_APPLICANT,
                })
              }>
              Transfer Applicant
            </MenuButton>
          )}
          {isRunReportsVisible && (
            <MenuButton onClick={onRunScreeningReportsClick}>
              Run Screening Reports
            </MenuButton>
          )}
          {isReviewable && (
            <>
              <MenuButton color="green" onClick={onAcceptApplicationClick}>
                {twoStepReviewEnabled ? (
                  <>
                    {hasInitiallyReviewed ? "Approve" : "Give Initial Approval"}
                  </>
                ) : (
                  "Accept Application"
                )}
              </MenuButton>
              <MenuButton color="red" onClick={onRejectApplicationClick}>
                {twoStepReviewEnabled ? (
                  <>{hasInitiallyReviewed ? "Deny" : "Give Initial Denial"}</>
                ) : (
                  "Reject Application"
                )}
              </MenuButton>
            </>
          )}
          {unsettableStatuses.includes(status) && (
            <MenuButton onClick={handleUnsetDecision}>
              Send Back for Initial Review
            </MenuButton>
          )}
          {P.isNotNullable(applicationGroup?.archiveTime) ? (
            <MenuButton
              onClick={() =>
                onUnarchiveApplicationClick({
                  applicationGroupId: applicationGroup.id,
                })
              }>
              Unarchive Application
            </MenuButton>
          ) : (
            <Tooltip
              label={`Archived applications can still be found under the "Inactive" tab.`}
              side="right">
              <MenuButton onClick={onArchiveApplicationClick}>
                Archive Application
              </MenuButton>
            </Tooltip>
          )}
          {P.isNotNullable(leaseId) && (
            <Tooltip label="View Lease Snapshot Page" side="right">
              <MenuButton
                onClick={() => history.push(`/leases/${leaseId}/snapshot`)}>
                Lease Snapshot
              </MenuButton>
            </Tooltip>
          )}
        </MenuContent>
      </Menu>
    </Group>
  );
});

export { ApplicationGroupActionsMenu };

export type { ApplicationGroupActionsMenuProps };
