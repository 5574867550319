import type { ReactNode } from "react";
import { useMemo } from "react";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Modal } from "@ender/shared/ds/modal";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { SearchBankAccountResponse } from "@ender/shared/generated/ender.model.accounting.response";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { Size } from "@ender/shared/utils/theming";

import { MarkAsPaidForm } from "./mark-as-paid-form";

type MarkAsPaidButtonProps = {
  bankAccounts: SearchBankAccountResponse[];
  children: ReactNode;
  disabled: boolean;
  invoice: InvoiceSerializerInvoiceResponse;
  onPaymentSuccess: () => void;
};

function MarkAsPaidButton({
  bankAccounts,
  children,
  disabled,
  invoice,
  onPaymentSuccess,
}: MarkAsPaidButtonProps) {
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean(false);

  const handleSuccess = () => {
    closeModal();
    onPaymentSuccess();
  };

  const verifiedBankAccounts = useMemo(
    () =>
      bankAccounts
        ? bankAccounts.filter(
            (bankAccount) =>
              !bankAccount.needsDwollaVerification &&
              !bankAccount.needsLobVerification,
          )
        : [],
    [bankAccounts],
  );

  return (
    <>
      <Button
        variant={ButtonVariant.outlined}
        disabled={disabled}
        onClick={openModal}>
        {children}
      </Button>
      <Modal
        opened={isModalOpen}
        onClose={closeModal}
        size={Size.md}
        title={
          invoice.type === InvoiceInvoiceTypeEnum.PAYABLE
            ? "Mark As Paid"
            : "Mark As Received"
        }>
        <MarkAsPaidForm
          invoiceId={invoice.id}
          bankAccounts={verifiedBankAccounts}
          onSuccess={handleSuccess}
        />
      </Modal>
    </>
  );
}

export { MarkAsPaidButton };
