import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P, String as S, pipe } from "effect";
import { useContext } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import type { InvoicesAPIAllocateInvoiceOffsetsPayload } from "@ender/shared/generated/ender.api.accounting";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";

import type { EditInvoiceAllocationsFormOutput } from "./use-edit-invoice-allocations-form";

function useInvoiceAllocationsHandleSubmit({
  invoiceId,
  onSuccess,
  values,
}: {
  invoiceId: EnderId;
  onSuccess: () => void;
  values: EditInvoiceAllocationsFormOutput;
}) {
  const { userPM } = useContext(UserContext);
  const confirmation = useConfirmationContext();

  const { allocationsByProperty, invoiceAmount } = values;
  const isParentCategorySelected = allocationsByProperty.some(
    (propertyAllocations) =>
      propertyAllocations.allocations.some(
        (allocation) => allocation.isParentCategory,
      ),
  );
  const allocations = allocationsByProperty.reduce<
    InvoicesAPIAllocateInvoiceOffsetsPayload["allocations"]
  >(
    (acc, property) =>
      pipe(
        property.propertyId,
        O.match({
          onNone: () => acc,
          onSome: (propertyId) => {
            const _allocations = property.allocations.reduce<
              InvoicesAPIAllocateInvoiceOffsetsPayload["allocations"]
            >((propertyAcc, allocation) => {
              if (
                O.isSome(allocation.accountId) &&
                O.exists(allocation.amount, Money$.isPositive)
              ) {
                const newAllocation: InvoicesAPIAllocateInvoiceOffsetsPayload["allocations"][0] =
                  {
                    amount: O.getOrThrow(allocation.amount).toJSON(),
                    categoryId: O.getOrThrow(allocation.accountId),
                    description:
                      P.isNullable(allocation.description) ||
                      S.isEmpty(allocation.description)
                        ? UNDEFINED
                        : allocation.description,
                    payableCategoryId: O.getOrUndefined(
                      allocation.payableCategoryId,
                    ),
                    propertyId: propertyId,
                  };
                propertyAcc.push(newAllocation);
              }

              return propertyAcc;
            }, []);

            return acc.concat(_allocations);
          },
        }),
      ),
    [],
  );

  const { mutateAsync: allocateInvoiceOffsets, isLoading: isSubmitting } =
    useMutation({
      mutationFn: InvoicesAPI.allocateInvoiceOffsets,
      mutationKey: ["allocateInvoiceOffsets"],
    });

  async function handleSubmit() {
    if (isParentCategorySelected && userPM.confirmParentAllocations) {
      await confirmation({
        confirmButtonLabel: "Yes, use category",
        title:
          "You have selected to allocate to a parent category. Are you sure you would like to proceed?",
      });
    }

    await allocateInvoiceOffsets({
      allocations,
      invoiceAmount: O.getOrThrow(invoiceAmount).toJSON(),
      invoiceId,
    });
    onSuccess();
  }

  return { handleSubmit, loading: isSubmitting };
}

export { useInvoiceAllocationsHandleSubmit };
