/**
 * HTTP Method: POST
 * Pathname: /chargeSchedules
 * @function createChargeSchedule
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPICreateChargeSchedulePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ChargeSchedule>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  ChargeSchedule,
  ChargeScheduleFrequency,
} from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ChargeSchedulesAPICreateChargeScheduleSearchParams = {
  token?: string | undefined;
};

type ChargeSchedulesAPICreateChargeScheduleBodyParams = {
  amount: SerializesInto<Money>;
  chargeForTerminatedLease?: boolean | undefined;
  chargeTypeId: EnderId;
  frequency: ChargeScheduleFrequency;
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  leaseId: EnderId;
  overrideWarnings?: boolean | undefined;
  startDate: SerializesInto<LocalDate>;
};

type ChargeSchedulesAPICreateChargeSchedulePayload =
  ChargeSchedulesAPICreateChargeScheduleSearchParams &
    ChargeSchedulesAPICreateChargeScheduleBodyParams;

function createChargeScheduleUnsafeEffect(
  payload: ChargeSchedulesAPICreateChargeSchedulePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ChargeSchedule>({
        body,
        decode: unsafeDecodeJsonResponse<ChargeSchedule>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/chargeSchedules",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createChargeSchedule(
  payload: ChargeSchedulesAPICreateChargeSchedulePayload,
  options?: { signal?: AbortSignal },
): Promise<ChargeSchedule> {
  return F.pipe(
    payload,
    createChargeScheduleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createChargeSchedule };
export type {
  ChargeSchedulesAPICreateChargeScheduleBodyParams,
  ChargeSchedulesAPICreateChargeSchedulePayload,
  ChargeSchedulesAPICreateChargeScheduleSearchParams,
};
