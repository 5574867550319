import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  pipe,
} from "effect";
import { useMemo } from "react";

import type { ApplicationsMiddleLayerAPIApplicationsTableStatusCountPayload } from "@ender/shared/generated/com.ender.middle";
import { ApplicationsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type { SearchApplicationsPageRequestApplicationsPageTab } from "@ender/shared/generated/com.ender.middle.request";
import type { ApplicationsTableStatusCountResponse } from "@ender/shared/generated/com.ender.middle.response";

import type { ApplicationsFilterState } from "../applications-filters/applications-filters.types";

type PayloadType =
  ApplicationsMiddleLayerAPIApplicationsTableStatusCountPayload;
type ResponseType = ApplicationsTableStatusCountResponse;
type QueryKeyType = readonly [
  "ApplicationsMiddleLayerAPI.applicationsTableStatusCount",
  PayloadType,
];

type UseQueryOptionsType = UseQueryOptions<
  ResponseType,
  unknown,
  ResponseType,
  QueryKeyType
>;

type TabCountMap = Partial<
  Record<
    SearchApplicationsPageRequestApplicationsPageTab,
    ResponseType | undefined
  >
>;

type UseTabCountsProps = {
  visibleTabs: SearchApplicationsPageRequestApplicationsPageTab[];
  filterState: Omit<ApplicationsFilterState, "statuses">;
};

type UseTabCountsReturn = {
  tabCounts: TabCountMap | undefined;
  isLoading: boolean;
};

function useTabCounts({
  visibleTabs,
  filterState,
}: UseTabCountsProps): UseTabCountsReturn {
  const { applicantName, markets, properties, onlyNeedsFollowUp, usingHcv } =
    filterState;

  const queries: readonly UseQueryOptionsType[] = visibleTabs.map(
    (tab: SearchApplicationsPageRequestApplicationsPageTab) => ({
      queryKey: [
        "ApplicationsMiddleLayerAPI.applicationsTableStatusCount",
        {
          applicantNameOrPhone: applicantName,
          marketIds: F.pipe(
            markets ?? [],
            A.map((itm) => itm.value),
          ),
          onlyShowNeedsFollowup: onlyNeedsFollowUp,
          propertyIds: F.pipe(
            properties ?? [],
            A.map((itm) => itm.value),
          ),
          tab,
          usingHousingChoiceVoucher: pipe(
            usingHcv,
            O.match({
              onNone: () => undefined,
              onSome: (val) => val === true,
            }),
          ),
        },
      ] as const,
      queryFn: ({ queryKey: [, param], signal }) =>
        ApplicationsMiddleLayerAPI.applicationsTableStatusCount(param, {
          signal,
        }),
    }),
  );

  const queriesResp = useQueries<UseQueryOptionsType[]>({ queries });

  const tabCounts = useMemo<TabCountMap | undefined>(() => {
    if (P.isNullable(queriesResp)) {
      return;
    }
    const ret: TabCountMap = {};

    queriesResp.forEach(
      (queryResp: UseQueryResult<ResponseType>, index: number) => {
        ret[visibleTabs[index]] = queryResp?.data;
      },
    );

    return ret;
  }, [queriesResp, visibleTabs]);

  const isLoading = useMemo<boolean>(() => {
    return (
      A.isEmptyArray(queriesResp) ||
      queriesResp.every(
        (queryResp: UseQueryResult<ResponseType>) => queryResp.isLoading,
      )
    );
  }, [queriesResp]);

  return { isLoading, tabCounts };
}

export { useTabCounts };
