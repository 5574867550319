import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InternalTransfersSortByInternalTransfersSortColumnValues = [
  "TRANSACTION_DATE",
  "PERIOD",
  "SYSTEM_DATE",
  "PROPERTY_CODE",
  "PROPERTY",
  "TYPE",
  "TRANSFER_TO",
  "TRANSFER_FROM",
  "USER",
  "MEMO",
  "AMOUNT",
] as const;

const InternalTransfersSortByInternalTransfersSortColumnEffectSchema =
  Schema.Literal(...InternalTransfersSortByInternalTransfersSortColumnValues);

type InternalTransfersSortByInternalTransfersSortColumn = Schema.Schema.Type<
  typeof InternalTransfersSortByInternalTransfersSortColumnEffectSchema
>;

const InternalTransfersSortByInternalTransfersSortColumnEnum = castEnum(
  InternalTransfersSortByInternalTransfersSortColumnEffectSchema,
);

function randomInternalTransfersSortByInternalTransfersSortColumn(): InternalTransfersSortByInternalTransfersSortColumn {
  return rand(InternalTransfersSortByInternalTransfersSortColumnValues);
}

export {
  InternalTransfersSortByInternalTransfersSortColumnEffectSchema,
  InternalTransfersSortByInternalTransfersSortColumnEnum,
  InternalTransfersSortByInternalTransfersSortColumnValues,
  randomInternalTransfersSortByInternalTransfersSortColumn,
};
export type { InternalTransfersSortByInternalTransfersSortColumn };
