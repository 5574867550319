/**
 * Calculates a repair cost for underwriting
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/repairCalculations/preview
 * @function computeRepairCalculationsPreview
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPIComputeRepairCalculationsPreviewPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RepairCalculations>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import type { RepairCalculations } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnderwritingAPIComputeRepairCalculationsPreviewPathParams = {
  dealId: EnderId;
};

type UnderwritingAPIComputeRepairCalculationsPreviewSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPIComputeRepairCalculationsPreviewBodyParams = {
  adjustmentAmount: SerializesInto<Money>;
  adjustmentReason: string;
  appliancesCost: SerializesInto<Money>;
  countertopCost: SerializesInto<Money>;
  exteriorCost: SerializesInto<Money>;
  flooringPercentToReplace: SerializesInto<Percent>;
  foundationCost: SerializesInto<Money>;
  hvacCost: SerializesInto<Money>;
  kitchenCabinetsCost: SerializesInto<Money>;
  roofCost: SerializesInto<Money>;
};

type UnderwritingAPIComputeRepairCalculationsPreviewPayload =
  UnderwritingAPIComputeRepairCalculationsPreviewPathParams &
    UnderwritingAPIComputeRepairCalculationsPreviewSearchParams &
    UnderwritingAPIComputeRepairCalculationsPreviewBodyParams;

function computeRepairCalculationsPreviewUnsafeEffect(
  payload: UnderwritingAPIComputeRepairCalculationsPreviewPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RepairCalculations>({
        body,
        decode: unsafeDecodeJsonResponse<RepairCalculations>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/repairCalculations/preview`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function computeRepairCalculationsPreview(
  payload: UnderwritingAPIComputeRepairCalculationsPreviewPayload,
  options?: { signal?: AbortSignal },
): Promise<RepairCalculations> {
  return F.pipe(
    payload,
    computeRepairCalculationsPreviewUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { computeRepairCalculationsPreview };
export type {
  UnderwritingAPIComputeRepairCalculationsPreviewBodyParams,
  UnderwritingAPIComputeRepairCalculationsPreviewPathParams,
  UnderwritingAPIComputeRepairCalculationsPreviewPayload,
  UnderwritingAPIComputeRepairCalculationsPreviewSearchParams,
};
