import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";
import type { SelectValue } from "@ender/shared/ds/select";

import type { SearchInputProps } from "./entities-search-input";
import { SearchInput } from "./entities-search-input";

type FormSearchInputProps<
  T extends SelectValue,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<SearchInputProps<T>, Form, Name>;

function FormSearchInput<
  T extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>> = Path<FormInput<Form>>,
>(props: FormSearchInputProps<T, Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field, error }) => {
        return <SearchInput<T> {...rest} {...field} error={error} />;
      }}
    </FormField>
  );
}

export { FormSearchInput };
export type { FormSearchInputProps };
