/**
 * This API is meant to overtake the `/leases/{leaseId}/payout` leasePayout endpoint. The /payout creates a payout
 without it going through an approval process, which is wrong outdated. This endpoint handles things correctly by
 setting up the payout to go through an approval process at the end of which the payout will be created.
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/payout-invoice
 * @function createLeasePayoutInvoice
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPICreateLeasePayoutInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateLeasePayoutInvoiceResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { CreateLeasePayoutInvoiceResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPICreateLeasePayoutInvoicePathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPICreateLeasePayoutInvoiceSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPICreateLeasePayoutInvoiceBodyParams = {
  amount: SerializesInto<Money>;
  ledgerDate: SerializesInto<LocalDate>;
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
};

type TenantLedgerAPICreateLeasePayoutInvoicePayload =
  TenantLedgerAPICreateLeasePayoutInvoicePathParams &
    TenantLedgerAPICreateLeasePayoutInvoiceSearchParams &
    TenantLedgerAPICreateLeasePayoutInvoiceBodyParams;

function createLeasePayoutInvoiceUnsafeEffect(
  payload: TenantLedgerAPICreateLeasePayoutInvoicePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateLeasePayoutInvoiceResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateLeasePayoutInvoiceResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/payout-invoice`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createLeasePayoutInvoice(
  payload: TenantLedgerAPICreateLeasePayoutInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<CreateLeasePayoutInvoiceResponse> {
  return F.pipe(
    payload,
    createLeasePayoutInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createLeasePayoutInvoice };
export type {
  TenantLedgerAPICreateLeasePayoutInvoiceBodyParams,
  TenantLedgerAPICreateLeasePayoutInvoicePathParams,
  TenantLedgerAPICreateLeasePayoutInvoicePayload,
  TenantLedgerAPICreateLeasePayoutInvoiceSearchParams,
};
