import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationGroupConditionalAcceptanceReasonValues = [
  "LOW_INCOME",
  "LOW_CREDIT",
] as const;

const ApplicationGroupConditionalAcceptanceReasonEffectSchema = Schema.Literal(
  ...ApplicationGroupConditionalAcceptanceReasonValues,
);

type ApplicationGroupConditionalAcceptanceReason = Schema.Schema.Type<
  typeof ApplicationGroupConditionalAcceptanceReasonEffectSchema
>;

const ApplicationGroupConditionalAcceptanceReasonEnum = castEnum(
  ApplicationGroupConditionalAcceptanceReasonEffectSchema,
);

function randomApplicationGroupConditionalAcceptanceReason(): ApplicationGroupConditionalAcceptanceReason {
  return rand(ApplicationGroupConditionalAcceptanceReasonValues);
}

export {
  ApplicationGroupConditionalAcceptanceReasonEffectSchema,
  ApplicationGroupConditionalAcceptanceReasonEnum,
  ApplicationGroupConditionalAcceptanceReasonValues,
  randomApplicationGroupConditionalAcceptanceReason,
};
export type { ApplicationGroupConditionalAcceptanceReason };
