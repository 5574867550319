/**
 * HTTP Method: POST
 * Pathname: /applications/search
 * @function queryFilteredApplications
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIQueryFilteredApplicationsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchApplicationsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SearchApplicationsResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationGroupApplicationStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ApplicationsAPIApplicationSortKey } from "../applications-api-application-sort-key";

type ApplicationsAPIQueryFilteredApplicationsSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIQueryFilteredApplicationsBodyParams = {
  applicantNameOrPhone: string;
  ascending?: boolean | undefined;
  followUpFilter?: boolean | undefined;
  hasPets?: boolean | undefined;
  limit?: number | undefined;
  marketIds: EnderId[];
  needsReassignment?: boolean | undefined;
  offset?: number | undefined;
  onlyInactive?: boolean | undefined;
  propertyIds: EnderId[];
  residentScore?: number | undefined;
  sortKey?: ApplicationsAPIApplicationSortKey | undefined;
  statuses: ApplicationGroupApplicationStatus[];
  underReviewQueue?: boolean | undefined;
  unitIds: EnderId[];
};

type ApplicationsAPIQueryFilteredApplicationsPayload =
  ApplicationsAPIQueryFilteredApplicationsSearchParams &
    ApplicationsAPIQueryFilteredApplicationsBodyParams;

function queryFilteredApplicationsUnsafeEffect(
  payload: ApplicationsAPIQueryFilteredApplicationsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchApplicationsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<SearchApplicationsResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/applications/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function queryFilteredApplications(
  payload: ApplicationsAPIQueryFilteredApplicationsPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchApplicationsResponse[]> {
  return F.pipe(
    payload,
    queryFilteredApplicationsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { queryFilteredApplications };
export type {
  ApplicationsAPIQueryFilteredApplicationsBodyParams,
  ApplicationsAPIQueryFilteredApplicationsPayload,
  ApplicationsAPIQueryFilteredApplicationsSearchParams,
};
