/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/renewalOffers
 * @function getOffers
 * @memberof RenewalsAPI
 * @param {RenewalsAPIGetOffersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeaseRenewalOffer[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeaseRenewalOffer } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPIGetOffersPathParams = {
  leaseId: EnderId;
};

type RenewalsAPIGetOffersSearchParams = {
  token?: string | undefined;
};

type RenewalsAPIGetOffersPayload = RenewalsAPIGetOffersPathParams &
  RenewalsAPIGetOffersSearchParams;

function getOffersUnsafeEffect(payload: RenewalsAPIGetOffersPayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeaseRenewalOffer[]>({
        body,
        decode: unsafeDecodeJsonResponse<LeaseRenewalOffer[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/renewalOffers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOffers(
  payload: RenewalsAPIGetOffersPayload,
  options?: { signal?: AbortSignal },
): Promise<LeaseRenewalOffer[]> {
  return F.pipe(
    payload,
    getOffersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOffers };
export type {
  RenewalsAPIGetOffersPathParams,
  RenewalsAPIGetOffersPayload,
  RenewalsAPIGetOffersSearchParams,
};
