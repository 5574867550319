import { z } from "zod";

import { UNDEFINED } from "@ender/shared/constants/general";
import { EnderIdSchema } from "@ender/shared/core";
import { VendorVendorStatusValues } from "@ender/shared/generated/ender.model.core.vendor";
import { ApprovableApprovalStatusValues } from "@ender/shared/generated/ender.service.approvals";
import {
  EmailSchema,
  IsoDatetimeSchema,
  PhoneSchema,
} from "@ender/shared/types/ender-general";

import { RmisComplianceStatusSchema } from "./get-vendor-markets";
import { GetVendorUsersResponseSchema } from "./get-vendor-users";
import { VendorOnboardingStatusSchema } from "./vendor-enums";

const GetVendorsResponseSchema = z.object({
  address: z
    .object({
      id: EnderIdSchema,
    })
    .nullish(),
  adminUser: GetVendorUsersResponseSchema.nullish(), //XOptional<GetVendorUserResponse>;
  bankAccountApprovalStatus: z.enum(ApprovableApprovalStatusValues).nullish(),
  companyEmail: z
    .string()
    .nullable()
    .default("")
    .transform((val) => (val === "" ? UNDEFINED : val))
    .transform((val) => {
      const parsed = EmailSchema.safeParse(val);
      return parsed.success ? parsed.data : UNDEFINED;
    }),
  companyPhone: z
    .string()
    .optional()
    .default("")
    .transform((val) => (val === "" ? UNDEFINED : val))
    .transform((val) => {
      const parsed = PhoneSchema.safeParse(val);
      return parsed.success ? parsed.data : UNDEFINED;
    }),
  creatorPM: z.string(),
  entityName: z.string(),
  id: EnderIdSchema,
  jobCapacity: z.number().nullish(),
  lastRmisSyncTimestamp: IsoDatetimeSchema.nullish(),
  markets: z.any().array(),
  name: z.string(),
  onboardingStatus: VendorOnboardingStatusSchema,
  paymentPolicy: z.object({
    autopay: z.boolean().nullish(),
    invoiceDelayDays: z.number().nullish(),
  }),
  riskManagementSystemId: z.number().nullish(),
  rmisComplianceStatus: RmisComplianceStatusSchema,
  status: z.enum(VendorVendorStatusValues),
  tags: z.string().array(),
  users: GetVendorUsersResponseSchema.array(), //XList<GetVendorUserResponse>;
});

export { GetVendorsResponseSchema };
