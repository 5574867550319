import { Predicate as P } from "effect";

import type { BankTransaction } from "@ender/shared/generated/ender.model.payments";
import type { BankRecRowEnderBatchResponse } from "@ender/shared/generated/ender.service.accounting.banking";

export function isEnderBatchItem(
  transactionDetails?: BankTransaction | BankRecRowEnderBatchResponse,
): transactionDetails is BankRecRowEnderBatchResponse {
  return (
    P.isNotNullable(transactionDetails) &&
    P.hasProperty(transactionDetails, "moneyTransfers")
  );
}
