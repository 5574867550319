/**
 * Gets all showings for a PM with the given filters.
 * HTTP Method: POST
 * Pathname: /showings
 * @function getShowings
 * @memberof LeasingAPI
 * @param {LeasingAPIGetShowingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchShowingsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetShowingsRequestGetShowingsActiveTab } from "@ender/shared/generated/ender.api.leasing.request";
import type { SearchShowingsResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { ShowingReservationStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetShowingsSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetShowingsBodyParams = {
  activeTab: GetShowingsRequestGetShowingsActiveTab;
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  propertyIds: EnderId[];
  prospectKeyword?: string | undefined;
  sortAscending?: boolean | undefined;
  status: ShowingReservationStatus[];
};

type LeasingAPIGetShowingsPayload = LeasingAPIGetShowingsSearchParams &
  LeasingAPIGetShowingsBodyParams;

function getShowingsUnsafeEffect(payload: LeasingAPIGetShowingsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchShowingsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<SearchShowingsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/showings",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getShowings(
  payload: LeasingAPIGetShowingsPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchShowingsResponse> {
  return F.pipe(
    payload,
    getShowingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getShowings };
export type {
  LeasingAPIGetShowingsBodyParams,
  LeasingAPIGetShowingsPayload,
  LeasingAPIGetShowingsSearchParams,
};
