/**
 * HTTP Method: GET
 * Pathname: /middleLayer/renewals/leases/{leaseId}/renewalPackage
 * @function getRenewalPackage
 * @memberof RenewalsMiddleLayerAPI
 * @param {RenewalsMiddleLayerAPIGetRenewalPackagePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RenewalPackageResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { RenewalPackageResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsMiddleLayerAPIGetRenewalPackagePathParams = {
  leaseId: EnderId;
};

type RenewalsMiddleLayerAPIGetRenewalPackageSearchParams = {
  token?: string | undefined;
};

type RenewalsMiddleLayerAPIGetRenewalPackagePayload =
  RenewalsMiddleLayerAPIGetRenewalPackagePathParams &
    RenewalsMiddleLayerAPIGetRenewalPackageSearchParams;

function getRenewalPackageUnsafeEffect(
  payload: RenewalsMiddleLayerAPIGetRenewalPackagePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RenewalPackageResponse>({
        body,
        decode: unsafeDecodeJsonResponse<RenewalPackageResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/middleLayer/renewals/leases/${leaseId}/renewalPackage`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRenewalPackage(
  payload: RenewalsMiddleLayerAPIGetRenewalPackagePayload,
  options?: { signal?: AbortSignal },
): Promise<RenewalPackageResponse> {
  return F.pipe(
    payload,
    getRenewalPackageUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRenewalPackage };
export type {
  RenewalsMiddleLayerAPIGetRenewalPackagePathParams,
  RenewalsMiddleLayerAPIGetRenewalPackagePayload,
  RenewalsMiddleLayerAPIGetRenewalPackageSearchParams,
};
