import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeaseLeaseStatusValues = [
  "DRAFTING",
  "CREATING_PDF",
  "OUT_FOR_SIGNATURES",
  "FINAL_SIGNATURE",
  "EXECUTED",
  "ERROR",
  "CANCELED",
] as const;

const LeaseLeaseStatusEffectSchema = Schema.Literal(...LeaseLeaseStatusValues);

type LeaseLeaseStatus = Schema.Schema.Type<typeof LeaseLeaseStatusEffectSchema>;

const LeaseLeaseStatusEnum = castEnum(LeaseLeaseStatusEffectSchema);

function randomLeaseLeaseStatus(): LeaseLeaseStatus {
  return rand(LeaseLeaseStatusValues);
}

export {
  LeaseLeaseStatusEffectSchema,
  LeaseLeaseStatusEnum,
  LeaseLeaseStatusValues,
  randomLeaseLeaseStatus,
};
export type { LeaseLeaseStatus };
