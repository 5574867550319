/**
 * HTTP Method: GET
 * Pathname: /accounting/{propertyId}/getRetainedEarningsJournalEntries
 * @function getRetainedEarningsJournalEntries
 * @memberof AccountingAPI
 * @param {AccountingAPIGetRetainedEarningsJournalEntriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GLJournalEntry[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GLJournalEntry } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIGetRetainedEarningsJournalEntriesPathParams = {
  propertyId: EnderId;
};

type AccountingAPIGetRetainedEarningsJournalEntriesSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetRetainedEarningsJournalEntriesPayload =
  AccountingAPIGetRetainedEarningsJournalEntriesPathParams &
    AccountingAPIGetRetainedEarningsJournalEntriesSearchParams;

function getRetainedEarningsJournalEntriesUnsafeEffect(
  payload: AccountingAPIGetRetainedEarningsJournalEntriesPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GLJournalEntry[]>({
        body,
        decode: unsafeDecodeJsonResponse<GLJournalEntry[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/accounting/${propertyId}/getRetainedEarningsJournalEntries`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRetainedEarningsJournalEntries(
  payload: AccountingAPIGetRetainedEarningsJournalEntriesPayload,
  options?: { signal?: AbortSignal },
): Promise<GLJournalEntry[]> {
  return F.pipe(
    payload,
    getRetainedEarningsJournalEntriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRetainedEarningsJournalEntries };
export type {
  AccountingAPIGetRetainedEarningsJournalEntriesPathParams,
  AccountingAPIGetRetainedEarningsJournalEntriesPayload,
  AccountingAPIGetRetainedEarningsJournalEntriesSearchParams,
};
