/**
 * Renew a lease. Same tenants; new rent(s), startDate, endDate, firstPaymentDueDate, applicationFee.
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/renew
 * @function renewLease
 * @memberof LeasingAPI
 * @param {LeasingAPIRenewLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RenewLeaseResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { RenewLeaseResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIRenewLeasePathParams = {
  leaseId: EnderId;
};

type LeasingAPIRenewLeaseSearchParams = {
  token?: string | undefined;
};

type LeasingAPIRenewLeaseBodyParams = {
  endDate?: SerializesInto<LocalDate> | undefined;
  expectedMoveOutDate?: SerializesInto<LocalDate> | undefined;
  firstPaymentDueDate?: SerializesInto<LocalDate> | undefined;
  overrideWarnings?: boolean | undefined;
  securityDeposit?: SerializesInto<Money> | undefined;
  startDate: SerializesInto<LocalDate>;
};

type LeasingAPIRenewLeasePayload = LeasingAPIRenewLeasePathParams &
  LeasingAPIRenewLeaseSearchParams &
  LeasingAPIRenewLeaseBodyParams;

function renewLeaseUnsafeEffect(payload: LeasingAPIRenewLeasePayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RenewLeaseResponse>({
        body,
        decode: unsafeDecodeJsonResponse<RenewLeaseResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/renew`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function renewLease(
  payload: LeasingAPIRenewLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<RenewLeaseResponse> {
  return F.pipe(
    payload,
    renewLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { renewLease };
export type {
  LeasingAPIRenewLeaseBodyParams,
  LeasingAPIRenewLeasePathParams,
  LeasingAPIRenewLeasePayload,
  LeasingAPIRenewLeaseSearchParams,
};
