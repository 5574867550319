/**
 * HTTP Method: POST
 * Pathname: /invoices/{invoiceId}/voidPayment
 * @function voidPayment
 * @memberof InvoicesAPI
 * @param {InvoicesAPIVoidPaymentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIVoidPaymentPathParams = {
  invoiceId: EnderId;
};

type InvoicesAPIVoidPaymentSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIVoidPaymentBodyParams = {
  accountingPeriod?: SerializesInto<LocalDate> | undefined;
  description: string;
  overrideWarnings?: boolean | undefined;
  transactionDate?: SerializesInto<LocalDate> | undefined;
};

type InvoicesAPIVoidPaymentPayload = InvoicesAPIVoidPaymentPathParams &
  InvoicesAPIVoidPaymentSearchParams &
  InvoicesAPIVoidPaymentBodyParams;

function voidPaymentUnsafeEffect(payload: InvoicesAPIVoidPaymentPayload) {
  const { invoiceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/invoices/${invoiceId}/voidPayment`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function voidPayment(
  payload: InvoicesAPIVoidPaymentPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    voidPaymentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { voidPayment };
export type {
  InvoicesAPIVoidPaymentBodyParams,
  InvoicesAPIVoidPaymentPathParams,
  InvoicesAPIVoidPaymentPayload,
  InvoicesAPIVoidPaymentSearchParams,
};
