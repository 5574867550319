/**
 * Import a CSV file which contains TX entries of a journal entry.
 * HTTP Method: POST
 * Pathname: /accounting/journal-entries/import
 * @function importJournalEntriesFromFile
 * @memberof AccountingAPI
 * @param {AccountingAPIImportJournalEntriesFromFilePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIImportJournalEntriesFromFileSearchParams = {
  token?: string | undefined;
};

type AccountingAPIImportJournalEntriesFromFilePayload =
  AccountingAPIImportJournalEntriesFromFileSearchParams;

function importJournalEntriesFromFileUnsafeEffect(
  payload: AccountingAPIImportJournalEntriesFromFilePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/journal-entries/import",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function importJournalEntriesFromFile(
  payload: AccountingAPIImportJournalEntriesFromFilePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    importJournalEntriesFromFileUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { importJournalEntriesFromFile };
export type {
  AccountingAPIImportJournalEntriesFromFilePayload,
  AccountingAPIImportJournalEntriesFromFileSearchParams,
};
