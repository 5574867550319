/* eslint-disable sort-keys */
import { FullStory, init as initFullStory } from "@fullstory/browser";
import { fullStoryIntegration } from "@sentry/fullstory";
import {
  browserTracingIntegration,
  inboundFiltersIntegration,
  replayIntegration,
  init as sentryInit,
  setUser,
} from "@sentry/react";
import { Function as F, Option as O, Predicate as P } from "effect";

import type { AuthActorStoreState } from "@ender/features/auth";
import { subscribeWithCurrentState } from "@ender/features/auth";
import { UNDEFINED } from "@ender/shared/constants/general";
import {
  environmentStore,
  initializeEnvironmentStore,
} from "@ender/shared/stores/environment-store";

async function init() {
  const parsedUrl = new URL(globalThis.location.toString());

  await initializeEnvironmentStore();
  const { environment, isDeveloper, isProduction } =
    environmentStore.getState();

  const isOnLoginPage = parsedUrl.pathname.startsWith("/login");
  const userAgent: string = globalThis.navigator?.userAgent ?? "";
  const isGhostInspector: boolean = userAgent.includes("Ghost Inspector");
  const isLinux: boolean = userAgent.includes("Linux");

  const shouldInitializeSentry: boolean =
    !isDeveloper && !isLinux && !isGhostInspector && !isOnLoginPage;
  const shouldSentryReplay: boolean =
    !isDeveloper && !isLinux && !isGhostInspector && !isOnLoginPage;
  const shouldLoadFullStory: boolean =
    isProduction && !isLinux && !isGhostInspector && !isOnLoginPage;
  const shouldLoadGoogleAnalytics: boolean =
    isProduction && !isLinux && !isGhostInspector && !isOnLoginPage;

  try {
    if (shouldLoadFullStory) {
      initFullStory({
        // debug: isDeveloper,
        // devMode: isDeveloper,
        orgId: "FRC83",
      });
    }
  } catch (err) {
    console.error("FullStory failed to initialize", err);
  }

  if (shouldInitializeSentry) {
    try {
      sentryInit({
        // debug: isDeveloper,
        environment,
        release: import.meta.env.VITE_COMMIT_SHA,
        allowUrls: [/localhost(:\d+)?/, /ender\.com/],
        denyUrls: [
          /fullstory\.com/,
          /nr-data\.net/,
          /amazonaws\.com/,
          /google-analytics\.com/,
        ],
        dsn: "https://2ef7fa7d338168afb8d8a1b279264e19@o4506023752368128.ingest.us.sentry.io/4507166932271104",
        integrations: [
          browserTracingIntegration(),
          inboundFiltersIntegration(),
          ...(shouldSentryReplay ? [replayIntegration()] : []),
          ...(shouldLoadFullStory
            ? [fullStoryIntegration("ender-asteroid", { client: FullStory })]
            : []),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/localhost(:\d+)?/, /ender\.com/],
        // Session Replay scale 0 to 1, currently set to 1%
        replaysSessionSampleRate: 0.01,
        // We are not recording every session but we record every session that has an error
        replaysOnErrorSampleRate: 1.0,
      });

      // Set user
      const unsubscribe = subscribeWithCurrentState(
        function setIdentityForSession(state: AuthActorStoreState) {
          const { originalUser, originalUserId, user } = F.pipe(
            state.actor,
            O.flatMap(([snapshot]) => snapshot.context.session),
            O.map((s) => {
              return {
                originalUser: s.originalUser,
                originalUserId: s.originalUserId,
                user: s.user,
              };
            }),
            O.getOrElse(() => ({
              user: UNDEFINED,
              originalUser: UNDEFINED,
              originalUserId: UNDEFINED,
            })),
          );

          if (P.isNullable(user) || P.isNullable(originalUserId)) {
            return;
          }
          const isMasquerading = user.id !== originalUserId;

          try {
            if (isMasquerading) {
              if (P.isNullable(originalUser)) {
                // Wait for the original user to be set
                return;
              }

              setUser({
                email: `${originalUser.email}::${user.email}`,
                id: originalUser.id,
                isMasquerading: true,
                username: `${originalUser.displayName} > ${user.displayName}`,
              });

              if (shouldLoadFullStory) {
                FullStory("setIdentity", {
                  properties: {
                    displayName: `${originalUser.displayName} > ${user.displayName}`,
                    email: `${originalUser.email}::${user.email}`,
                  },
                  uid: originalUser.id,
                });
              }
            } else {
              setUser({
                email: user.email,
                id: user.id,
                username: user.displayName,
              });

              if (shouldLoadFullStory) {
                FullStory("setIdentity", {
                  properties: {
                    displayName: user.displayName,
                    email: user.email,
                  },
                  uid: user.id,
                });
              }
            }
          } catch (err) {
            console.error("Failed to identify user", err);
          }
          return unsubscribe();
        },
      );
    } catch (err) {
      console.error("Failed to initialize Sentry", err);
    }
  }

  if (shouldLoadGoogleAnalytics) {
    import("./lib/google-analytics");
  }

  // This polyfill for GliderGrid adds support for older browsers
  if (!window.OffscreenCanvas) {
    // @ts-expect-error - Our Polyfill doesn't meet the full spec, but it's good enough for our use case
    window.OffscreenCanvas = class OffscreenCanvas {
      private readonly canvas: HTMLCanvasElement;

      constructor(width: number, height: number) {
        this.canvas = document.createElement("canvas");
        this.canvas.width = width;
        this.canvas.height = height;

        // @ts-expect-error - convertToBlob is not part of the spec, but it's useful for our use case
        this.canvas.convertToBlob = () => {
          return new Promise((resolve) => {
            this.canvas.toBlob(resolve);
          });
        };

        // @ts-expect-error - shouldn't return the canvas
        return this.canvas;
      }
    };
  }
}

export { init };
