import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PurchaseOrderPurchaseOrderStatusValues = [
  "CREATED",
  "SUBMITTED",
] as const;

const PurchaseOrderPurchaseOrderStatusEffectSchema = Schema.Literal(
  ...PurchaseOrderPurchaseOrderStatusValues,
);

type PurchaseOrderPurchaseOrderStatus = Schema.Schema.Type<
  typeof PurchaseOrderPurchaseOrderStatusEffectSchema
>;

const PurchaseOrderPurchaseOrderStatusEnum = castEnum(
  PurchaseOrderPurchaseOrderStatusEffectSchema,
);

function randomPurchaseOrderPurchaseOrderStatus(): PurchaseOrderPurchaseOrderStatus {
  return rand(PurchaseOrderPurchaseOrderStatusValues);
}

export {
  PurchaseOrderPurchaseOrderStatusEffectSchema,
  PurchaseOrderPurchaseOrderStatusEnum,
  PurchaseOrderPurchaseOrderStatusValues,
  randomPurchaseOrderPurchaseOrderStatus,
};
export type { PurchaseOrderPurchaseOrderStatus };
