import { IconFilter } from "@tabler/icons-react";
import type {
  Column,
  Header,
  SortDirection,
  Table,
} from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import { useCallback, useEffect, useState } from "react";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@ender/shared/ds/popover";
import { Stack } from "@ender/shared/ds/stack";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { KeyEnum } from "@ender/shared/types/general";
import { onKeyEvent } from "@ender/shared/utils/dom";
import { capitalize } from "@ender/shared/utils/string";

import type { ColumnSortFilterProps } from "./column-sort-filter";
import { ColumnSortFilter } from "./column-sort-filter";
import { asColumnMeta } from "./table.types";

import styles from "./table.module.css";

function SortIcon(props: { dir: SortDirection | false }) {
  return (
    <div className={styles.headerIcon} tabIndex={0}>
      <Stack spacing={Spacing.xs}>
        {props.dir !== "desc" && <div className={styles.asc} />}
        {props.dir !== "asc" && <div className={styles.desc} />}
      </Stack>
    </div>
  );
}

function FilterIcon() {
  return (
    <IconFilter
      tabIndex={0}
      size={14}
      stroke={2}
      className={clsx(styles.filter, styles.headerIcon)}
    />
  );
}

type TableHeaderSortFilterDropdownProps<RowData, ColumnData, FilterData> =
  ColumnSortFilterProps<RowData, ColumnData, FilterData> & {
    onClear?: () => void;
    onApply?: () => void;
  };

function TableHeaderSortFilterDropdown<RowData, ColumnData, FilterData>(
  props: TableHeaderSortFilterDropdownProps<RowData, ColumnData, FilterData>,
) {
  const {
    canSort,
    clearSorting = F.constVoid,
    column,
    filterComponent,
    filterValue, //header.column.getFilterValue()
    onApply = F.constVoid,
    onClear = F.constVoid,
    onFilterChange,
    sortDir,
    table,
    toggleSorting = F.constVoid,
  } = props;

  const [tempSortDir, setTempSortDir] = useState<SortDirection | false>(
    sortDir,
  );
  const [tempFilterValue, setTempFilterValue] = useState<
    FilterData | undefined
  >(filterValue);

  // lazy useEffect
  useEffect(() => {
    setTempSortDir(sortDir);
  }, [sortDir]);

  // lazy useEffect
  useEffect(() => {
    setTempFilterValue(filterValue);
  }, [filterValue]);

  const clearSortingTemp = useCallback(() => setTempSortDir(false), []);
  const toggleSortingTemp = useCallback(
    (desc?: boolean) => setTempSortDir(desc ? "desc" : "asc"),
    [],
  );
  const onFilterChangeTemp = setTempFilterValue;

  const clearFiltersAndSorting = useCallback(() => {
    clearSorting();
    onFilterChange(UNDEFINED);
    onClear();
  }, [clearSorting, onClear, onFilterChange]);

  const applyFilters = useCallback(() => {
    if (tempSortDir) {
      toggleSorting(tempSortDir === "desc", true);
    } else {
      clearSorting();
    }

    onFilterChange(tempFilterValue);
    onApply();
  }, [
    tempSortDir,
    onFilterChange,
    tempFilterValue,
    onApply,
    toggleSorting,
    clearSorting,
  ]);

  return (
    <Stack spacing={Spacing.md}>
      <ColumnSortFilter
        table={table}
        column={column}
        // sort
        canSort={canSort}
        sortDir={tempSortDir}
        clearSorting={clearSortingTemp}
        toggleSorting={toggleSortingTemp}
        // filter
        filterValue={tempFilterValue}
        onFilterChange={onFilterChangeTemp}
        filterComponent={filterComponent}
      />
      <Group justify={Justify.between}>
        <Button
          variant={ButtonVariant.outlined}
          onClick={clearFiltersAndSorting}>
          Clear Filters
        </Button>
        <Button onClick={applyFilters}>Apply</Button>
      </Group>
    </Stack>
  );
}

type TableHeaderProps<RowData, ColumnData> = {
  table: Table<RowData>;
  header: Header<RowData, ColumnData>;
};

function TableHeader<RowData, ColumnData>(
  props: TableHeaderProps<RowData, ColumnData>,
) {
  const { table, header } = props;
  const meta = asColumnMeta(header.column.columnDef.meta);
  const { className, filterComponent } = meta;
  const canFilter = P.isNotNullable(filterComponent);
  const canSort = header.column.getCanSort();
  const sortDir = header.column.getIsSorted();
  const isFocused =
    (header.column.getCanSort() && header.column.getIsSorted()) ||
    (canFilter &&
      header.column.getCanFilter() &&
      header.column.getIsFiltered());

  const [
    filterPopoverOpened,
    {
      setTrue: openFilterPopover,
      setFalse: closeFilterPopover,
      toggle: toggleFilterPopover,
    },
  ] = useBoolean(false);
  const handleThClick = canFilter
    ? openFilterPopover
    : header.column.getToggleSortingHandler();

  const classNames = clsx(
    {
      [styles.canFocus]: canSort || canFilter,
      [styles.focused]: isFocused,
      [styles.stickyEnd]: header.column.getIsPinned() === "right",
      [styles.stickyStart]: header.column.getIsPinned() === "left",
    },
    className,
  );

  return (
    <Popover opened={filterPopoverOpened} onOpenedChange={toggleFilterPopover}>
      <PopoverTrigger disabled={!canFilter}>
        <th
          aria-label={
            P.isString(header.column.columnDef.header)
              ? header.column.columnDef.header
              : `Column ${capitalize(header.column.id)}`
          }
          key={header.id}
          colSpan={header.colSpan}
          className={classNames}
          style={{
            width: header.column.columnDef.size,
            minWidth: header.column.columnDef.minSize,
            maxWidth: header.column.columnDef.maxSize,
          }}
          onClick={handleThClick}
          onKeyDown={onKeyEvent(KeyEnum.Enter, handleThClick)}
          data-accessor-key={header.column.id}>
          {header.isPlaceholder ? (
            NULL
          ) : (
            <Group spacing={Spacing.sm} noWrap justify={Justify.start}>
              <span className={styles.headerLabel}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </span>
              {canFilter ? (
                <FilterIcon />
              ) : (
                canSort && <SortIcon dir={sortDir} />
              )}
            </Group>
          )}
        </th>
      </PopoverTrigger>
      <PopoverContent>
        <div className="p-4">
          <TableHeaderSortFilterDropdown
            canSort={canSort}
            clearSorting={header.column.clearSorting}
            column={
              // For some reason TS won't allow assignment from Column<RowData, ColumnData> to Column<RowData, unknown> without this cast
              header.column as Column<unknown, unknown>
            }
            filterComponent={filterComponent}
            filterValue={header.column.getFilterValue()}
            onApply={closeFilterPopover}
            onClear={closeFilterPopover}
            onFilterChange={header.column.setFilterValue}
            sortDir={sortDir}
            table={
              // For some reason TS won't allow assignment from Table<RowData> to Table<unknown> without this cast
              table as Table<unknown>
            }
            toggleSorting={header.column.toggleSorting}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export { TableHeader };
