/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/getListingDetailsWidget
 * @function getListingDetailsWidget
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIGetListingDetailsWidgetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ListingDetailsWidgetResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ListingDetailsWidgetResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIGetListingDetailsWidgetSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIGetListingDetailsWidgetBodyParams = {
  propertyId: EnderId;
  unitId?: EnderId | undefined;
};

type LeasingMiddleLayerAPIGetListingDetailsWidgetPayload =
  LeasingMiddleLayerAPIGetListingDetailsWidgetSearchParams &
    LeasingMiddleLayerAPIGetListingDetailsWidgetBodyParams;

function getListingDetailsWidgetUnsafeEffect(
  payload: LeasingMiddleLayerAPIGetListingDetailsWidgetPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ListingDetailsWidgetResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ListingDetailsWidgetResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/leasing/getListingDetailsWidget",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getListingDetailsWidget(
  payload: LeasingMiddleLayerAPIGetListingDetailsWidgetPayload,
  options?: { signal?: AbortSignal },
): Promise<ListingDetailsWidgetResponse> {
  return F.pipe(
    payload,
    getListingDetailsWidgetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getListingDetailsWidget };
export type {
  LeasingMiddleLayerAPIGetListingDetailsWidgetBodyParams,
  LeasingMiddleLayerAPIGetListingDetailsWidgetPayload,
  LeasingMiddleLayerAPIGetListingDetailsWidgetSearchParams,
};
