/**
 * HTTP Method: POST
 * Pathname: /listings/{listingId}
 * @function updateListing
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdateListingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  AmenityAmenityType,
  PropertyCatPolicy,
  PropertyDogPolicy,
} from "@ender/shared/generated/ender.model.core.property";
import type { ListingListingStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdateListingPathParams = {
  listingId: EnderId;
};

type LeasingAPIUpdateListingSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdateListingBodyParams = {
  advertisedRent?: SerializesInto<Money> | undefined;
  amenities: AmenityAmenityType[];
  appliances?: string | undefined;
  applicationFee?: SerializesInto<Money> | undefined;
  catPolicy?: PropertyCatPolicy | undefined;
  contactEmail?: string | undefined;
  contactPhone?: string | undefined;
  dogPolicy?: PropertyDogPolicy | undefined;
  hoaInformation?: string | undefined;
  isFeatured?: boolean | undefined;
  marketingBody?: string | undefined;
  marketingTitle?: string | undefined;
  maxNumPets?: number | undefined;
  maxNumVehicles?: number | undefined;
  minimumLeaseDuration?: number | undefined;
  moveInReadyDate?: SerializesInto<LocalDate> | undefined;
  offerDescription?: string | undefined;
  offerEndDate?: SerializesInto<LocalDate> | undefined;
  offerStartDate?: SerializesInto<LocalDate> | undefined;
  photoUrls?: string[] | undefined;
  securityDeposit?: SerializesInto<Money> | undefined;
  status?: ListingListingStatus | undefined;
  tourReadyDate?: SerializesInto<LocalDate> | undefined;
  videoUrl?: string | undefined;
};

type LeasingAPIUpdateListingPayload = LeasingAPIUpdateListingPathParams &
  LeasingAPIUpdateListingSearchParams &
  LeasingAPIUpdateListingBodyParams;

function updateListingUnsafeEffect(payload: LeasingAPIUpdateListingPayload) {
  const { listingId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/listings/${listingId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateListing(
  payload: LeasingAPIUpdateListingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateListingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateListing };
export type {
  LeasingAPIUpdateListingBodyParams,
  LeasingAPIUpdateListingPathParams,
  LeasingAPIUpdateListingPayload,
  LeasingAPIUpdateListingSearchParams,
};
