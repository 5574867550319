/**
 * HTTP Method: GET
 * Pathname: /buy/pipeline-analytics
 * @function getPipelineAnalytics
 * @memberof BuyAPI
 * @param {BuyAPIGetPipelineAnalyticsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIGetPipelineAnalyticsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIGetPipelineAnalyticsResponse } from "../buy-api-get-pipeline-analytics-response";

type BuyAPIGetPipelineAnalyticsSearchParams = {
  token?: string | undefined;
  numDays?: number | undefined;
};

type BuyAPIGetPipelineAnalyticsPayload = BuyAPIGetPipelineAnalyticsSearchParams;

function getPipelineAnalyticsUnsafeEffect(
  payload: BuyAPIGetPipelineAnalyticsPayload,
) {
  const { token, numDays, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIGetPipelineAnalyticsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<BuyAPIGetPipelineAnalyticsResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/buy/pipeline-analytics",
        searchParams: [
          ["token", token],
          ["numDays", numDays],
        ],
      }),
    ),
  );
}

function getPipelineAnalytics(
  payload: BuyAPIGetPipelineAnalyticsPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIGetPipelineAnalyticsResponse[]> {
  return F.pipe(
    payload,
    getPipelineAnalyticsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPipelineAnalytics };
export type {
  BuyAPIGetPipelineAnalyticsPayload,
  BuyAPIGetPipelineAnalyticsSearchParams,
};
