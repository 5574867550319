import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationGroupApplicationStatusValues = [
  "IN_PROGRESS",
  "COMPLETE",
  "UNDER_REVIEW",
  "INITIAL_ACCEPTED",
  "ACCEPTED",
  "INITIAL_REJECTED",
  "REJECTED",
  "WITHDRAWN",
] as const;

const ApplicationGroupApplicationStatusEffectSchema = Schema.Literal(
  ...ApplicationGroupApplicationStatusValues,
);

type ApplicationGroupApplicationStatus = Schema.Schema.Type<
  typeof ApplicationGroupApplicationStatusEffectSchema
>;

const ApplicationGroupApplicationStatusEnum = castEnum(
  ApplicationGroupApplicationStatusEffectSchema,
);

function randomApplicationGroupApplicationStatus(): ApplicationGroupApplicationStatus {
  return rand(ApplicationGroupApplicationStatusValues);
}

export {
  ApplicationGroupApplicationStatusEffectSchema,
  ApplicationGroupApplicationStatusEnum,
  ApplicationGroupApplicationStatusValues,
  randomApplicationGroupApplicationStatus,
};
export type { ApplicationGroupApplicationStatus };
