import { Predicate as P } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";

function extractInvoicePropertyIds(
  invoice?: InvoiceSerializerInvoiceResponse,
): EnderId[] {
  if (P.isNullable(invoice)) {
    return [];
  }

  const propertyIds =
    invoice?.currentState?.allocationsByProperty?.map(
      (allocation) => allocation.propertyId,
    ) ?? [];
  return Array.from(new Set(propertyIds));
}

export { extractInvoicePropertyIds };
