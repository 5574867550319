import { z } from "zod";

import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { ModelTypeValues } from "@ender/shared/generated/com.ender.common.model";
import { castEnum } from "@ender/shared/utils/zod";

/**
 * @description accounting/LedgerEvent.java
 */
const LedgerEventTypeValues = [
  "INVOICE",
  "MONEY_TRANSFER",
  "LEDGER_ALLOCATION",
  "JOURNAL_ENTRY",
  "PREPAYMENT_ALLOCATION",
] as const;
const LedgerEventTypeSchema = z.enum(LedgerEventTypeValues);
type LedgerEventType = z.infer<typeof LedgerEventTypeSchema>;

const LedgerEventTypeEnum = castEnum<LedgerEventType>(LedgerEventTypeSchema);

/**
 * @description Mantine size options
 */
const MantineSizeEnum = {
  LG: "lg",
  MD: "md",
  SM: "sm",
  XL: "xl",
  XS: "xs",
} as const;
const MantineSizeSchema = z.nativeEnum(MantineSizeEnum);
type MantineSize = z.infer<typeof MantineSizeSchema>;

const ModelTypeSchema = z.enum(ModelTypeValues);

/**
 * @deprecated use ModelTypeEnum from shared/generated/packages
 */
const ModelTypeEnum = castEnum<ModelType>(ModelTypeSchema);

const MoneyTransferValues = [
  "PRINT_CHECK",
  "MARK_PAID_CHECK",
  "STRIPE_PAYMENT",
  "DWOLLA_TRANSFER",
  "BANK_TRANSFER",
  "OTHER",
] as const;
const MoneyTransferSchema = z.enum(MoneyTransferValues);
type MoneyTransfer = z.infer<typeof MoneyTransferSchema>;

type MoneyTransferSearch = "ESCROW_TRANSFER" | "MOVE_OUT_REFUND";

const TransferTypeValues = [
  "BANK_TRANSFER",
  "MARK_PAID_CHECK",
  "PRINT_CHECK",
  "DWOLLA_TRANSFER",
  "LOB_CHECK",
  "OTHER",
  "WIRE",
  "STRIPE_PAYMENT",
  "PAYABLE",
] as const;
const TransferTypeSchema = z.enum(TransferTypeValues);
type TransferType = z.infer<typeof TransferTypeSchema>;

const TransferTypeEnum = castEnum<TransferType>(TransferTypeSchema);

export {
  LedgerEventTypeEnum,
  LedgerEventTypeSchema,
  LedgerEventTypeValues,
  MantineSizeEnum,
  MantineSizeSchema,
  ModelTypeEnum,
  ModelTypeSchema,
  MoneyTransferSchema,
  MoneyTransferValues,
  TransferTypeEnum,
  TransferTypeSchema,
  TransferTypeValues,
};
export type {
  LedgerEventType,
  MantineSize,
  ModelType,
  MoneyTransfer,
  MoneyTransferSearch,
  TransferType,
};
