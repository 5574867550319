/**
 * HTTP Method: POST
 * Pathname: /stripe/paymentMethods
 * @function createStripePaymentMethod
 * @memberof PaymentsAPI
 * @param {PaymentsAPICreateStripePaymentMethodPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateStripePaymentMethodResponse>}
 */
import { Effect, Function as F } from "effect";

import type { CreateStripePaymentMethodResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPICreateStripePaymentMethodSearchParams = {
  token?: string | undefined;
};

type PaymentsAPICreateStripePaymentMethodPayload =
  PaymentsAPICreateStripePaymentMethodSearchParams;

function createStripePaymentMethodUnsafeEffect(
  payload: PaymentsAPICreateStripePaymentMethodPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateStripePaymentMethodResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateStripePaymentMethodResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/stripe/paymentMethods",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createStripePaymentMethod(
  payload: PaymentsAPICreateStripePaymentMethodPayload,
  options?: { signal?: AbortSignal },
): Promise<CreateStripePaymentMethodResponse> {
  return F.pipe(
    payload,
    createStripePaymentMethodUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createStripePaymentMethod };
export type {
  PaymentsAPICreateStripePaymentMethodPayload,
  PaymentsAPICreateStripePaymentMethodSearchParams,
};
