import { createChargeSchedule } from "./create-charge-schedule";
import { createRenewalChargeSchedule } from "./create-renewal-charge-schedule";
import { deactivateChargeSchedule } from "./deactivate-charge-schedule";
import { deleteChargeSchedule } from "./delete-charge-schedule";
import { getChargesForChargeSchedule } from "./get-charges-for-charge-schedule";
import { getLeaseChargeSchedules } from "./get-lease-charge-schedules";
import { getScheduledChargesForLease } from "./get-scheduled-charges-for-lease";
import { replaceChargeSchedule } from "./replace-charge-schedule";
import { updateChargeSchedule } from "./update-charge-schedule";
import { updateRenewalChargeSchedule } from "./update-renewal-charge-schedule";

const ChargeSchedulesAPI = {
  createChargeSchedule,
  createRenewalChargeSchedule,
  deactivateChargeSchedule,
  deleteChargeSchedule,
  getChargesForChargeSchedule,
  getLeaseChargeSchedules,
  getScheduledChargesForLease,
  replaceChargeSchedule,
  updateChargeSchedule,
  updateRenewalChargeSchedule,
};

export { ChargeSchedulesAPI };
