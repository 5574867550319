import { Array as A } from "effect";
import type { ReactNode } from "react";

import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { EmptyObject } from "@ender/shared/types/general";

import { TableTopActions } from "./table-top-actions";
import { TableTopResults } from "./table-top-results";
import type { EnderTable } from "./table.types";

import styles from "./table.module.css";

type TableTopProps<RowData, Meta extends object = EmptyObject> = {
  table: EnderTable<RowData, Meta>;
  customLeft?: ReactNode;
  customRight?: ReactNode;
};

function TableTop<RowData, Meta extends object = EmptyObject>(
  props: TableTopProps<RowData, Meta>,
): ReactNode {
  const { table, customLeft, customRight } = props;
  const { actions, hideResults } = table.options.meta;

  return (
    <Group align={Align.stretch} justify={Justify.between}>
      <div className={styles.tableTopLeftItem}>
        {hideResults || <TableTopResults table={table} />}
        {customLeft}
      </div>
      <div className={styles.tableTopRightItem}>
        {customRight}
        {!actions || A.isEmptyArray(actions) || (
          <TableTopActions table={table} />
        )}
      </div>
    </Group>
  );
}

export { TableTop };
export type { TableTopProps };
