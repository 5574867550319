import { useQuery } from "@tanstack/react-query";

import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { EnderAPI } from "@ender/shared/generated/ender.api.misc";
import type { AccountingPeriodPeriodStatus } from "@ender/shared/generated/ender.model.accounting";
import { AccountingPeriodPeriodStatusEnum } from "@ender/shared/generated/ender.model.accounting";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { EnderDate } from "@ender/shared/utils/ender-date";

const periodModuleAbbreviations = {
  ACCOUNTS_PAYABLE: "AP",
  ACCOUNTS_RECEIVABLE: "AR",
  GENERAL_LEDGER: "GL",
};

function generateActionMessage(
  periodDate: string,
  beforeStatus: AccountingPeriodPeriodStatus,
  afterStatus: AccountingPeriodPeriodStatus,
) {
  const dateString = EnderDate.of(periodDate).toLongMonthString();

  if (afterStatus === AccountingPeriodPeriodStatusEnum.CLOSED) {
    if (beforeStatus === AccountingPeriodPeriodStatusEnum.PARTIALLY_OPEN) {
      return `Closed ${dateString} for Accountants`;
    } else if (beforeStatus === AccountingPeriodPeriodStatusEnum.OPEN) {
      return `Closed ${dateString} for Clerks and Accountants`;
    }
  } else if (afterStatus === AccountingPeriodPeriodStatusEnum.PARTIALLY_OPEN) {
    if (beforeStatus === AccountingPeriodPeriodStatusEnum.OPEN) {
      return `Closed ${dateString} for Clerks`;
    } else if (beforeStatus === AccountingPeriodPeriodStatusEnum.CLOSED) {
      return `Reopened ${dateString} for Accountants`;
    }
  } else if (afterStatus === AccountingPeriodPeriodStatusEnum.OPEN) {
    if (beforeStatus === AccountingPeriodPeriodStatusEnum.PARTIALLY_OPEN) {
      return `Reopened ${dateString} for Clerks`;
    } else if (beforeStatus === AccountingPeriodPeriodStatusEnum.CLOSED) {
      return `Reopened ${dateString} for Clerks and Accountants`;
    }
  }
}

function AuditHistoryList() {
  const { data: auditHistory, isLoading } = useQuery({
    queryKey: ["EnderAPI.getAuditTrailData", "AccountingPeriod"] as const,
    queryFn: ({ signal }) =>
      EnderAPI.getAuditTrailData(
        {
          modelType: "AccountingPeriod",
          operations: [],
        },
        { signal },
      ),
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <EnderTable>
      <colgroup>
        <col style={{ width: "10em" }} />
        <col style={{ width: "6em" }} />
        <col style={{ width: "8em" }} />
        <col style={{ width: "1fr" }} />
      </colgroup>
      <thead>
        <tr>
          <th>Date</th>
          <th>Module</th>
          <th>User</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody style={{ fontSize: 10 }}>
        {auditHistory?.results
          ?.filter((auditItem) => {
            return auditItem.operation === "UPDATE";
          })
          .sort((a, b) => {
            return b.timestamp - a.timestamp;
          })
          .map(({ timestampFormatted, author, before, after }, idx: number) => (
            <tr key={idx}>
              <td>{timestampFormatted}</td>
              <td>
                {
                  periodModuleAbbreviations[
                    before?.module as keyof typeof periodModuleAbbreviations
                  ]
                }
              </td>
              <td>{author?.name}</td>
              <td>
                {generateActionMessage(
                  before.startDate,
                  before.status,
                  after.status,
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </EnderTable>
  );
}

export { AuditHistoryList };
