import { IconInfoCircle } from "@tabler/icons-react";

import type { CheckboxProps } from "@ender/shared/ds/checkbox";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";

function SeparatePaymentsCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      label={
        <Group spacing={Spacing.xs}>
          Treat each invoice as a separate payment.
          <Tooltip label="By default Ender treats each batch as a single payment. Checking this box will treat each invoice paid as its own separate transaction.">
            <IconInfoCircle />
          </Tooltip>
        </Group>
      }
      name="separatePayments"
      {...props}
    />
  );
}

export { SeparatePaymentsCheckbox };
