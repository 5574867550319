"use client";

import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import { useCallback, useMemo, useState } from "react";

import type { TwilioClientVerificationCodeChannel } from "@ender/shared/generated/com.ender.common.arch.client";
import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";

import { useAuthActor } from "../context/auth-actor.context";
import type { LoginPayload } from "../machine/auth.types";
import { AuthEventEnum } from "../machine/auth.types";
import { LoginFormController } from "./login-form.controller";
import { MultiFactorAuthenticationFormView } from "./multi-factor-authentication-form.view";
import { MultiFactorChannelSelectFormController } from "./multi-factor-channel-select-form.controller";
import { MultiFactorCodeFormController } from "./multi-factor-code-form.controller";
import { toErrorMessage } from "./utils";

async function login(payload: LoginPayload) {
  return await AuthAPI.login({ force2Fa: true, code: "", ...payload });
}

type MultiFactorAuthenticationFormControllerProps = {
  onDone: () => void;
};

function MultiFactorAuthenticationFormController(
  props: MultiFactorAuthenticationFormControllerProps,
) {
  const { onDone } = props;

  const {
    mutateAsync: loginAsync,
    error: loginError,
    isLoading: isLoginLoading,
  } = useMutation({ mutationFn: login });
  const loginErrorMessage = useMemo(
    () => toErrorMessage(loginError),
    [loginError],
  );

  const {
    mutateAsync: sendVerificationCodeAsync,
    error: sendVerificationCodeError,
    isLoading: isSendVerificationCodeLoading,
  } = useMutation({ mutationFn: AuthAPI.sendVerificationCode });
  const sendVerificationCodeErrorMessage = useMemo(
    () => toErrorMessage(sendVerificationCodeError),
    [sendVerificationCodeError],
  );

  const [authSnapshot, sendAuthEvent] = useAuthActor();
  const {
    context: { session, loginPayload },
  } = authSnapshot;
  const { email, phone, requiresMultiFactorAuth, userId } = useMemo(() => {
    return {
      email: F.pipe(
        session,
        O.map((s) => s.user.email),
      ),
      phone: F.pipe(
        session,
        O.map((s) => s.user.phone),
      ),
      requiresMultiFactorAuth: F.pipe(
        session,
        O.map((s) => s.requiresMultiFactorAuth),
        O.getOrElse(() => true),
      ),
      userId: F.pipe(
        session,
        O.map((s) => s.user.id),
      ),
    };
  }, [session]);

  const [channel, setChannel] = useState<
    O.Option<TwilioClientVerificationCodeChannel>
  >(O.none());

  const handleLogin = useCallback(
    (payload: LoginPayload) => {
      loginAsync(payload).then((session: EnderSessionResponse | undefined) => {
        sendAuthEvent({
          payload: O.some(payload),
          type: AuthEventEnum.SET_LOGIN_PAYLOAD,
        });

        sendAuthEvent({
          payload: O.fromNullable(session),
          type: AuthEventEnum.SET_SESSION,
        });
      });
    },
    [loginAsync, sendAuthEvent],
  );

  const handleSendMultiFactorCode = useCallback(
    (channel: TwilioClientVerificationCodeChannel) => {
      sendVerificationCodeAsync({ channel, userId: O.getOrThrow(userId) }).then(
        () => {
          setChannel(O.some(channel));
        },
      );
    },
    [sendVerificationCodeAsync, userId, setChannel],
  );

  const handleResetChannel = useCallback(() => {
    setChannel(O.none());
  }, [setChannel]);

  const handleDone = useCallback(
    (session: EnderSessionResponse | undefined) => {
      sendAuthEvent({
        payload: O.fromNullable(session),
        type: AuthEventEnum.SET_SESSION,
      });
      onDone();
    },
    [sendAuthEvent, onDone],
  );

  if (requiresMultiFactorAuth && O.isNone(loginPayload)) {
    return (
      <MultiFactorAuthenticationFormView>
        <LoginFormController
          errorMessage={loginErrorMessage}
          loginPayload={loginPayload}
          isFetching={isLoginLoading}
          onLogin={handleLogin}
        />
      </MultiFactorAuthenticationFormView>
    );
  }

  if (O.isNone(channel)) {
    return (
      <MultiFactorAuthenticationFormView>
        <MultiFactorChannelSelectFormController
          errorMessage={sendVerificationCodeErrorMessage}
          hasEmail={O.isSome(email)}
          hasPhone={O.isSome(phone)}
          initialChannel={channel}
          isFetching={isSendVerificationCodeLoading}
          onSendCode={handleSendMultiFactorCode}
        />
      </MultiFactorAuthenticationFormView>
    );
  }

  return (
    <MultiFactorAuthenticationFormView>
      <MultiFactorCodeFormController
        channel={O.getOrThrow(channel)}
        email={O.getOrElse(email, () => "")}
        loginPayload={O.getOrUndefined(loginPayload)}
        onDone={handleDone}
        onResetChannel={handleResetChannel}
        phone={O.getOrElse(phone, () => "")}
      />
    </MultiFactorAuthenticationFormView>
  );
}

export { MultiFactorAuthenticationFormController };
