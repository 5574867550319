import { Schema } from "@effect/schema";
import { Function as F, Option as O } from "effect";
import { useCallback, useEffect, useMemo } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { NULL } from "@ender/shared/constants/general";

type UseQueryParamsEnumOptions<T extends string> = {
  schema: Schema.Literal<[T, ...T[]]>;
  initialValue?: O.Option<T>;
};

type UseQueryParamsEnumReturn<T extends string> = [
  O.Option<T>,
  (value: O.Option<T>) => void,
];

function useQueryParamsEnum<T extends string>(
  key: string,
  opts: UseQueryParamsEnumOptions<T>,
): UseQueryParamsEnumReturn<T> {
  const { schema, initialValue = O.none() } = opts;
  const [_value, _setValue] = useQueryParam(key, StringParam);

  const decodeToOption = useMemo(
    () => Schema.decodeUnknownOption(schema),
    [schema],
  );
  const value = useMemo(
    () =>
      F.pipe(
        decodeToOption(_value),
        O.orElse(() => initialValue),
      ),
    [decodeToOption, initialValue, _value],
  );

  const setValue = useCallback(
    (value: O.Option<T>) => {
      F.pipe(
        value,
        O.match({
          onNone: () => _setValue(NULL),
          onSome: _setValue,
        }),
      );
    },
    [_setValue],
  );

  // Added useEffect to set initial value in URL on first render
  useEffect(() => {
    if (O.isNone(decodeToOption(_value)) && O.isSome(initialValue)) {
      setValue(initialValue);
    }
  }, [decodeToOption, _value, initialValue, setValue]);

  return [value, setValue];
}

export { useQueryParamsEnum };
export type { UseQueryParamsEnumOptions, UseQueryParamsEnumReturn };
