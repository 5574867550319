import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PaymentPlanPaymentPlanTypeValues = ["PM", "HOUSING_AUTHORITY"] as const;

const PaymentPlanPaymentPlanTypeEffectSchema = Schema.Literal(
  ...PaymentPlanPaymentPlanTypeValues,
);

type PaymentPlanPaymentPlanType = Schema.Schema.Type<
  typeof PaymentPlanPaymentPlanTypeEffectSchema
>;

const PaymentPlanPaymentPlanTypeEnum = castEnum(
  PaymentPlanPaymentPlanTypeEffectSchema,
);

function randomPaymentPlanPaymentPlanType(): PaymentPlanPaymentPlanType {
  return rand(PaymentPlanPaymentPlanTypeValues);
}

export {
  PaymentPlanPaymentPlanTypeEffectSchema,
  PaymentPlanPaymentPlanTypeEnum,
  PaymentPlanPaymentPlanTypeValues,
  randomPaymentPlanPaymentPlanType,
};
export type { PaymentPlanPaymentPlanType };
