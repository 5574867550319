// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { GroupProps } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Group } from "@mantine/core";
import type { ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";

type EnderGroupProps = GroupProps;

/**
 * @deprecated use @ender/shared/ds/group
 */
const EnderGroup = forwardRef(function EnderGroupWithRef(
  props: PropsWithChildren<EnderGroupProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return <Group {...props} ref={ref} />;
});

export { EnderGroup };
export type { EnderGroupProps };
