import { Schema } from "@effect/schema";
import { Option as O, Predicate as P } from "effect";
import { useContext, useMemo } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { LedgerActions, LedgerContext } from "@ender/shared/contexts/ledger";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type { TenantLedgerReportLedgerEntry } from "@ender/shared/generated/ender.arch.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { LedgerEventReversalButton } from "@ender/widgets/finance/ledger-event-reversal-button";

type ReverseChargeButtonProps = {
  isReversal: boolean;
  isReversed: boolean;
  openReverseModal: () => void;
};

type ReverseChargeFormProps = {
  selectedLedgerEvent: TenantLedgerReportLedgerEntry | Null;
  onSuccess: () => void;
  leaseId: EnderId;
};

function ReverseChargeButton({
  isReversal,
  isReversed,
  openReverseModal,
}: ReverseChargeButtonProps) {
  const disabledTooltip = useMemo(() => {
    if (isReversed) {
      return "This charge has already been reversed.";
    }

    if (isReversal) {
      return "This transaction reverses a previous one and cannot be reversed again.";
    }
  }, [isReversal, isReversed]);

  const isReversible = !isReversed && !isReversal;

  return (
    <LedgerEventReversalButton
      onClick={openReverseModal}
      disabled={!isReversible}
      disabledTooltip={disabledTooltip}>
      Reverse
    </LedgerEventReversalButton>
  );
}

const ReverseChargeFormSchema = Schema.Struct({
  reversalDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Reversal Date is required." }),
  ),
});

type ReverseChargeFormValues = Schema.Schema.Type<
  typeof ReverseChargeFormSchema
>;

function ReverseChargeForm({
  selectedLedgerEvent,
  onSuccess,
  leaseId,
}: ReverseChargeFormProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      reversalDate: O.fromNullable(LocalDate$.today()),
    },
    schema: ReverseChargeFormSchema,
  });

  async function reverseCharge(values: ReverseChargeFormValues) {
    if (P.isNullable(selectedLedgerEvent)) {
      return;
    }

    const { reversalDate } = values;

    try {
      await TenantLedgerAPI.reverseTenantCharge({
        chargeId: selectedLedgerEvent.id,
        leaseId,
        reversalDate: O.getOrThrow(reversalDate).toJSON(),
      });
      onSuccess();
    } catch (err) {
      fail(err);
    }
  }

  return (
    <Form form={form} onSubmit={reverseCharge}>
      <Stack spacing={Spacing.sm}>
        <Text>
          Processing a reversal will create offsetting entries for this charge.
          The reversing entry will appear on both the General Ledger and the
          Tenant Ledger
        </Text>
        <FormDateInput form={form} label="Reversal Date" name="reversalDate" />
        <Group justify={Justify.end}>
          <Button type="submit">Confirm</Button>
        </Group>
      </Stack>
    </Form>
  );
}

function LedgerEventChargeReverseBtn({ leaseId }: { leaseId: EnderId }) {
  const { selectedLedgerEvent, dispatch } = useContext(LedgerContext);
  const [
    isReverseModalOpen,
    { setTrue: openReverseModal, setFalse: closeReverseModal },
  ] = useBoolean(false);

  const handleSuccess = () => {
    dispatch({
      payload: NULL,
      type: LedgerActions.SET_SELECTED_LEDGER_EVENT,
    });
    dispatch({
      payload: { leaseId },
      type: LedgerActions.FETCH_LEDGER,
    });
    closeReverseModal();
  };

  return (
    <>
      <ReverseChargeButton
        openReverseModal={openReverseModal}
        isReversed={
          P.isNotNullable(selectedLedgerEvent) &&
          selectedLedgerEvent.isReversedCharge
        }
        isReversal={
          P.isNotNullable(selectedLedgerEvent) &&
          selectedLedgerEvent.isChargeReversal
        }
      />
      <Modal
        title="Reverse Charge"
        onClose={closeReverseModal}
        opened={isReverseModalOpen}>
        <ReverseChargeForm
          selectedLedgerEvent={selectedLedgerEvent}
          onSuccess={handleSuccess}
          leaseId={leaseId}
        />
      </Modal>
    </>
  );
}

export { LedgerEventChargeReverseBtn };
