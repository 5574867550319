import { Function as F, Option as O } from "effect";

import { useEffectSchemaForm } from "@ender/form-system/base";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import { CustomFactorFactorOutputTypeEnum } from "@ender/shared/generated/ender.model.factor";
import type { Factor } from "@ender/shared/generated/ender.service.factors";
import { fail } from "@ender/shared/utils/error";

import type { NewCustomFactorFormOutput } from "./new-custom-factor-form.view";
import {
  NewCustomFactorFormSchema,
  NewCustomFactorFormView,
} from "./new-custom-factor-form.view";

type NewCustomFactorFormControllerProps = {
  closeModal: () => void;
  grouping?: string;
  modelType: ModelType;
  onSuccess?: (factor: Factor) => unknown;
  showRestrictedOption?: boolean;
};

function NewCustomFactorFormController({
  closeModal,
  grouping,
  modelType,
  onSuccess = F.constVoid,
  showRestrictedOption = false,
}: NewCustomFactorFormControllerProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      defaultBooleanValue: O.some(""),
      defaultDateValue: O.none(),
      defaultMoneyValue: O.none(),
      defaultNumberValue: O.none(),
      defaultStringValue: "",
      enumValues: "",
      isFavorite: false,
      isRestricted: false,
      name: "",
      outputType: O.none(),
    },
    schema: NewCustomFactorFormSchema,
  });

  const outputType = form.watch("outputType").pipe(O.getOrUndefined);

  function getDefaultValue() {
    switch (outputType) {
      case CustomFactorFactorOutputTypeEnum.BOOLEAN:
        return form.watch("defaultBooleanValue").pipe(O.getOrUndefined);
      case CustomFactorFactorOutputTypeEnum.DATE:
        return form.watch("defaultDateValue").pipe(
          O.map((date) => date.toJSON()),
          O.getOrUndefined,
        );
      case CustomFactorFactorOutputTypeEnum.MONEY:
        return form.watch("defaultMoneyValue").pipe(
          O.map((val) => val.toJSON()),
          O.getOrUndefined,
        );
      case CustomFactorFactorOutputTypeEnum.NUMBER:
        return form.watch("defaultNumberValue").pipe(
          O.map((val) => val.toString()),
          O.getOrUndefined,
        );
      case CustomFactorFactorOutputTypeEnum.STRING:
        return form.watch("defaultStringValue");
      default:
        return UNDEFINED;
    }
  }

  async function handleSubmit({
    isFavorite,
    isRestricted,
    name,
    ...values
  }: NewCustomFactorFormOutput) {
    const requestBody = {
      isFavorite,
      isRestricted,
      name,
    };
    const enumValues =
      outputType === CustomFactorFactorOutputTypeEnum.ENUM
        ? values.enumValues?.split(",").map((val) => val.trim())
        : UNDEFINED;

    try {
      const factor = await FactorsAPI.newFactor({
        ...requestBody,
        defaultValue: getDefaultValue(),
        enumValues,
        grouping,
        inputType: modelType,
        outputType,
      });
      onSuccess(factor);
      closeModal();
    } catch (err) {
      fail(err);
    }
  }

  return (
    <NewCustomFactorFormView
      form={form}
      handleSubmit={handleSubmit}
      outputType={outputType}
      showRestrictedOption={showRestrictedOption}
    />
  );
}

export { NewCustomFactorFormController };
