import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import type { Lease } from "@ender/shared/types/ender-general";
import { rest } from "@ender/shared/utils/rest";

const GetLeasePayloadSchema = z.object({
  leaseId: EnderIdSchema,
});

type GetLeasePayload = z.infer<typeof GetLeasePayloadSchema>;

async function getLease(props: GetLeasePayload): Promise<Lease> {
  const { leaseId } = GetLeasePayloadSchema.parse(props);
  return await rest.get<Lease>(`/leases/${leaseId}`);
}

const useInvalidateGetLeaseQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (props: GetLeasePayload) =>
      queryClient.invalidateQueries([
        "LeasingAPI.getLeaseDetails",
        props.leaseId,
      ]),

    [queryClient],
  );
};

export { getLease, GetLeasePayloadSchema, useInvalidateGetLeaseQuery };
export type { GetLeasePayload };
