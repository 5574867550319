// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { openSpotlight } from "@mantine/spotlight";
import { IconMail, IconSearch } from "@tabler/icons-react";
import { Predicate as P } from "effect";
import * as A from "effect/Array";
import type { SyntheticEvent } from "react";
import { useCallback, useContext, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { handleLogout } from "@ender/features/auth";
import { UserContext } from "@ender/shared/contexts/user";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { TabButton, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useHeaderStore } from "@ender/shared/stores/header-store";
import { LinkIf } from "@ender/shared/ui/link-if";
import { UserIcon } from "@ender/shared/ui/user-icon";
import { VendorSelect } from "@ender/shared/ui/vendor-select";
import { NewTaskButton } from "@ender/widgets/maintenance";

import { AdminTools } from "./admin-tools";
import { Breadcrumbs } from "./components";

import styles from "./nav-header.module.css";

function NavHeader() {
  const { originalUser, permissions, user, userVendors } =
    useContext(UserContext);
  const breadcrumbs = useHeaderStore.use.breadcrumbs();
  const pageActionButton = useHeaderStore.use.pageActionButton();
  const subMenu = useHeaderStore.use.subMenu();

  const history = useHistory();
  const location = useLocation();

  const userHasInbox = user.isPM || user.isBroker;

  const newTaskArgs = {
    propertyId: "",
    unitId: "",
  };

  const pathInfo = history.location.pathname.split("/").slice(1);

  if (pathInfo.length > 0) {
    switch (pathInfo[0]) {
      case "properties": {
        if (pathInfo.length > 1) {
          newTaskArgs.propertyId = pathInfo[1];
        }
        break;
      }

      case "units": {
        newTaskArgs.propertyId = breadcrumbs[1]?.href?.split("/")[2] ?? "";
        if (pathInfo.length > 1) {
          newTaskArgs.unitId = pathInfo[1];
        }
        break;
      }

      case "leases": {
        newTaskArgs.propertyId = breadcrumbs[1]?.href?.split("/")[2] ?? "";
        newTaskArgs.unitId = breadcrumbs[2]?.href?.split("/")[2] ?? "";
        break;
      }

      default:
        break;
    }
  }

  /**
   * greedily finds the longest subMenu path that matches the current URL.
   *
   * if the current URL is /properties/123/units/456, and the subMenu is [/properties, /properties/123, /units]
   * the longest match will be /properties/123.
   *
   * this function is better than the useRouteMatch for computing which of the subRoutes is the active tab
   * because useRouteMatch will only match the first route that matches the current URL (in the above example, /properties)
   *
   * @todo useBasePath would be the smarter solution as it would know what the base path looks like when stripped of all params
   * however, we can't use that because the NavHeader is not a child of the Route. We may also be able to set up sub-routes using the "basename"
   * prop on the BrowserRouter, but we'll likely tackle that with Nextjs
   */
  const match = useMemo(() => {
    const matchingPaths =
      subMenu
        ?.map(({ path }) => path)
        .filter((path) => location.pathname.includes(path)) ?? [];

    return matchingPaths.reduce(
      (longest, current) =>
        current.length > longest.length ? current : longest,
      "",
    );
  }, [location, subMenu]);

  const navigate = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const onSignOutClick = useCallback((ev: SyntheticEvent<unknown>) => {
    ev.preventDefault();
    handleLogout();
  }, []);

  const isMasquerading =
    P.isNotNullable(user) &&
    P.isNotNullable(originalUser) &&
    originalUser.id !== user.id;
  const showAdmin = user?.isEnder || isMasquerading;

  // @ts-ignore
  return (
    <div data-testid="nav-header" className={styles.root} role="banner">
      <div className="grid" style={{ gridTemplateRows: "1fr auto" }}>
        <Breadcrumbs separator=">">
          {P.isNotNullable(breadcrumbs) &&
            breadcrumbs.map(({ title, href }, index) => (
              <LinkIf
                key={index}
                if={P.isNotNullable(href)}
                to={href ?? ""}
                className={styles.breadcrumb}>
                <Text size={FontSize.lg} weight={FontWeight.inherit}>
                  {title}
                </Text>
              </LinkIf>
            ))}
        </Breadcrumbs>
        {A.isNonEmptyArray(subMenu) && (
          <Tabs value={match ?? ""} onChange={navigate}>
            <TabsList>
              {subMenu.map(({ path, subRoute }) => (
                <TabButton value={path} href={path} key={path}>
                  {subRoute}
                </TabButton>
              ))}
            </TabsList>
          </Tabs>
        )}
      </div>
      <Group justify={Justify.center} align={Align.center}>
        {user.isVendor && userVendors.length === 1 && (
          <Text>{user.party?.name}</Text>
        )}
        {user.isVendor && userVendors.length > 1 && <VendorSelect />}
        {user.isPM && (
          <>
            <div
              data-testid="omnisearch-button"
              className={`${styles.circleButton} spotlight-search`}
              onClick={() => openSpotlight()}>
              <IconSearch size={24} />
            </div>
            <div>
              {permissions[FunctionalPermissionEnum.CREATE_TASKS] && (
                // @ts-expect-error
                <NewTaskButton {...newTaskArgs} />
              )}
            </div>
          </>
        )}
        {pageActionButton}
        {userHasInbox && (
          <Link to="/inbox" className={styles.circleButton}>
            <IconMail size={24} />
          </Link>
        )}
        {showAdmin && <AdminTools />}
        <Menu>
          <MenuTrigger>
            <UserIcon user={user} size={38} className={styles.userIcon} />
          </MenuTrigger>
          <MenuContent>
            <MenuButton onClick={() => history.push("/profile")}>
              Profile
            </MenuButton>
            <MenuButton onClick={onSignOutClick}>Sign out</MenuButton>
          </MenuContent>
        </Menu>
      </Group>
    </div>
  );
}

export { NavHeader };
