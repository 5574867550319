import { IconDownload, IconInfoCircle } from "@tabler/icons-react";
import type { PropsWithChildren } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ender/shared/ds/accordion";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";

type UploadRailHeaderTupleProps = {
  label: string;
  value: string | JSX.Element;
  infoTooltip?: string;
};

function UploadRailHeaderTuple({
  label,
  value,
  infoTooltip,
}: UploadRailHeaderTupleProps) {
  return (
    <Tuple
      label={label}
      value={
        <Group spacing={Spacing.xs}>
          {value}
          {infoTooltip && (
            <Tooltip label={infoTooltip}>
              <IconInfoCircle
                color={EnderThemeColorEnum.PRIMARY}
                style={{ verticalAlign: "bottom" }}
              />
            </Tooltip>
          )}
        </Group>
      }
    />
  );
}

type UploadRailHeaderProps = {
  header: string;
  sampleFileUrl: string;
  headerText?: string;
};

const uploadRailHeaderDefaultText =
  "Please make sure your file includes the following column headers with data in the listed format. To" +
  " upload an invoice that includes multiple properties or multiple GL allocations use the same invoice" +
  " number on each allocation row you would like to group.";

function UploadRailHeader(props: PropsWithChildren<UploadRailHeaderProps>) {
  const { children, header, sampleFileUrl, headerText } = props;
  return (
    <>
      <Text size={FontSize.sm}>{header}</Text>
      <Accordion>
        <AccordionItem value="first">
          <AccordionTrigger>File Formatting Rules</AccordionTrigger>
          <AccordionContent variant="bordered">
            <Stack>
              <Text size={FontSize.sm}>
                {headerText ?? uploadRailHeaderDefaultText}
              </Text>
              {/* TODO @kfalicov determine best strategy forward between roles on shared-ds-button or creating shared-ds-link (not shared-ds-nav-link) */}
              <a href={sampleFileUrl} download>
                <Button
                  variant={ButtonVariant.transparent}
                  leftSection={<IconDownload />}>
                  Download Sample File
                </Button>
              </a>
              <div>{children}</div>
            </Stack>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export { UploadRailHeader, UploadRailHeaderTuple };
