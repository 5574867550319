import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionLineItemLineItemRejectionReasonValues = [
  "MISSING_PHOTOS",
  "MORE_INFO_REQUIRED",
  "PRICING_REQUEST",
] as const;

const InspectionLineItemLineItemRejectionReasonEffectSchema = Schema.Literal(
  ...InspectionLineItemLineItemRejectionReasonValues,
);

type InspectionLineItemLineItemRejectionReason = Schema.Schema.Type<
  typeof InspectionLineItemLineItemRejectionReasonEffectSchema
>;

const InspectionLineItemLineItemRejectionReasonEnum = castEnum(
  InspectionLineItemLineItemRejectionReasonEffectSchema,
);

function randomInspectionLineItemLineItemRejectionReason(): InspectionLineItemLineItemRejectionReason {
  return rand(InspectionLineItemLineItemRejectionReasonValues);
}

export {
  InspectionLineItemLineItemRejectionReasonEffectSchema,
  InspectionLineItemLineItemRejectionReasonEnum,
  InspectionLineItemLineItemRejectionReasonValues,
  randomInspectionLineItemLineItemRejectionReason,
};
export type { InspectionLineItemLineItemRejectionReason };
