import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import type { UseFormReturn } from "@ender/form-system/base";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import type { SelectOption } from "@ender/shared/ds/select";
import { FormSelect } from "@ender/shared/ds/select";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tuple } from "@ender/shared/ds/tuple";

const PetFormSchema = Schema.Struct({
  name: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Name is required" }),
  ),
  species: Schema.String.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<string> => O.isSome(input), {
      message: () => "Species is required",
    }),
  ),
  breed: Schema.String,
  age: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<number> => O.isSome(input), {
      message: () => "Age is required",
    }),
  ),
  weightInPounds: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<number> => O.isSome(input), {
      message: () => "Weight is required",
    }),
  ),
  isNeutered: Schema.Boolean.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<boolean> => O.isSome(input), {
      message: () => "Neutered/Spayed is required",
    }),
  ),
  isEmotionalSupportAnimal: Schema.Boolean.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<boolean> => O.isSome(input), {
      message: () => "ESA/Service Animal is required",
    }),
  ),
});

type PetFormInput = Schema.Schema.Encoded<typeof PetFormSchema>;
type PetFormOutput = Schema.Schema.Type<typeof PetFormSchema>;

const petSpeciesOptions: SelectOption[] = [
  { label: "Dog", value: "Dog" },
  { label: "Cat", value: "Cat" },
  { label: "Bird", value: "Bird" },
  { label: "Other", value: "Other" },
];
const neuteredOptions: SelectOption[] = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
const esaOptions: SelectOption[] = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

type EditablePetInfoProps = {
  form: UseFormReturn<PetFormInput, (values: PetFormInput) => PetFormOutput>;
};

function EditablePetInfo(props: EditablePetInfoProps) {
  const { form } = props;

  return (
    <>
      <Tuple
        label="Name"
        value={
          <FormTextInput form={form} placeholder="Enter Name" name="name" />
        }
      />
      <Tuple
        label="Species"
        value={
          <FormSelect
            data={petSpeciesOptions}
            form={form}
            name="species"
            placeholder="Select species"
          />
        }
      />
      <Tuple
        label="Breed"
        value={
          <FormTextInput form={form} name="breed" placeholder="Enter Breed" />
        }
      />
      <Tuple
        label="Neutered/Spayed"
        value={
          <FormSelect data={neuteredOptions} form={form} name="isNeutered" />
        }
      />
      <Tuple label="Age" value={<FormNumberInput form={form} name="age" />} />
      <Tuple
        label="Weight"
        value={<FormNumberInput form={form} name="weightInPounds" />}
      />
      <Tuple
        label="ESA/Service Animal"
        value={
          <FormSelect
            data={esaOptions}
            form={form}
            name="isEmotionalSupportAnimal"
          />
        }
      />
    </>
  );
}

export { EditablePetInfo, PetFormSchema };
export type { PetFormInput, PetFormOutput };
