import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationEmploymentStatusValues = [
  "EMPLOYED",
  "SELF_EMPLOYED",
  "UNEMPLOYED",
  "STUDENT",
  "RETIRED",
] as const;

const ApplicationEmploymentStatusEffectSchema = Schema.Literal(
  ...ApplicationEmploymentStatusValues,
);

type ApplicationEmploymentStatus = Schema.Schema.Type<
  typeof ApplicationEmploymentStatusEffectSchema
>;

const ApplicationEmploymentStatusEnum = castEnum(
  ApplicationEmploymentStatusEffectSchema,
);

function randomApplicationEmploymentStatus(): ApplicationEmploymentStatus {
  return rand(ApplicationEmploymentStatusValues);
}

export {
  ApplicationEmploymentStatusEffectSchema,
  ApplicationEmploymentStatusEnum,
  ApplicationEmploymentStatusValues,
  randomApplicationEmploymentStatus,
};
export type { ApplicationEmploymentStatus };
