import type { MantineProviderProps } from "@mantine/core";
import { MantineProvider } from "@mantine/core";
import { Predicate as P } from "effect";
import { useEffect, useMemo } from "react";

import { useBrandingStore } from "@ender/shared/stores/branding-store";
import type { Branding } from "@ender/shared/types/branding";
import { initializeFontFamily } from "@ender/shared/utils/branding";
import { enderMantineTheme } from "@ender/shared/utils/mantine-theme";

function insertBrandingCSS(branding: Branding) {
  const brandingStylesheet = document.createElement("style");
  brandingStylesheet.setAttribute("data-branding-styles", "true");
  brandingStylesheet.textContent = `
    :root {
      --body-bg: var(--color-gray-100);
    
      --color-primary: ${branding.color500};
      --color-primary-50: ${branding.color50};
      --color-primary-100: ${branding.color100};
      --color-primary-200: ${branding.color200};
      --color-primary-300: ${branding.color300};
      --color-primary-400: ${branding.color400};
      --color-primary-500: ${branding.color500};
      --color-primary-600: ${branding.color600};
      --color-primary-700: ${branding.color700};
      --color-primary-800: ${branding.color800};
      --color-primary-900: ${branding.color900};
      
      --color-breadcrumbs: ${branding.breadcrumbs};
      --color-input-border: ${branding.inputBorder};
      --color-nav-bg: ${branding.navBg};
      --theme-nav-bg: ${branding.navBg};
      --theme-login-bg: ${
        branding.backgroundLogin
          ? `${branding.navBg} url(${branding.backgroundLogin}) no-repeat center center`
          : branding.navBg
      };
      --theme-welcome-bg: ${
        branding.backgroundAccountCreation
          ? `${branding.navBg} url(${branding.backgroundAccountCreation}) 40% 50% no-repeat`
          : branding.navBg
      };

      ${branding.themeFontFamily ? `--theme-font-family: ${branding.themeFontFamily};` : ""}
    }
  `;
  document.head.appendChild(brandingStylesheet);
}

function EnderMantineProvider({ children, ...props }: MantineProviderProps) {
  const branding = useBrandingStore(({ branding }) => branding);
  const isBrandingEnabled = useBrandingStore(({ isEnabled }) => isEnabled);

  const updatedEnderMantineTheme = useMemo(
    () => ({
      ...enderMantineTheme,
      ...(P.isNotNullable(branding) &&
        isBrandingEnabled && {
          ...(branding.themeFontFamily && {
            fontFamily: branding.themeFontFamily,
            headings: {
              ...enderMantineTheme.headings,
              fontFamily: branding.themeFontFamily,
            },
          }),
          primaryColor: "client",
        }),
    }),
    [branding, isBrandingEnabled],
  );

  // lazy useEffect
  useEffect(() => {
    if (P.isNullable(branding)) {
      return;
    }

    insertBrandingCSS(branding);

    if (P.isNullable(branding.themeFontName)) {
      return;
    }

    void initializeFontFamily(branding.themeFontName);
  }, [branding]);

  // lazy useEffect
  useEffect(() => {
    if (isBrandingEnabled) {
      if (P.isNotNullable(branding)) {
        insertBrandingCSS(branding);
      }
      return;
    }

    const brandingStylesheet = document.querySelector(
      "style[data-branding-styles]",
    );
    if (brandingStylesheet) {
      document.head.removeChild(brandingStylesheet);
    }
  }, [branding, isBrandingEnabled]);

  return (
    <MantineProvider theme={updatedEnderMantineTheme} {...props}>
      {children}
    </MantineProvider>
  );
}

export { EnderMantineProvider };
