/**
 * HTTP Method: POST
 * Pathname: /invoices/batchPay/search
 * @function getInvoicesForBatchPay
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoicesForBatchPayPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchPayInvoicesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchPayInvoicesResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoicesForBatchPaySearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetInvoicesForBatchPayBodyParams = {
  invoiceIds: EnderId[];
};

type InvoicesAPIGetInvoicesForBatchPayPayload =
  InvoicesAPIGetInvoicesForBatchPaySearchParams &
    InvoicesAPIGetInvoicesForBatchPayBodyParams;

function getInvoicesForBatchPayUnsafeEffect(
  payload: InvoicesAPIGetInvoicesForBatchPayPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchPayInvoicesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchPayInvoicesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/batchPay/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoicesForBatchPay(
  payload: InvoicesAPIGetInvoicesForBatchPayPayload,
  options?: { signal?: AbortSignal },
): Promise<BatchPayInvoicesResponse> {
  return F.pipe(
    payload,
    getInvoicesForBatchPayUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoicesForBatchPay };
export type {
  InvoicesAPIGetInvoicesForBatchPayBodyParams,
  InvoicesAPIGetInvoicesForBatchPayPayload,
  InvoicesAPIGetInvoicesForBatchPaySearchParams,
};
