import { useMemo } from "react";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { Modal } from "@ender/shared/ds/modal";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { Color } from "@ender/shared/utils/theming";

import { ConnectedRejectInvoiceForm } from "./connected-reject-invoice-form";

type InvoiceApprovalMenuProps = {
  approvalSteps: GetApprovalProcessResponse;
  invoice: InvoiceSerializerInvoiceResponse;
  onPaymentRejection?: () => void;
};

function RejectInvoiceButton({
  approvalSteps,
  invoice,
  onPaymentRejection,
}: InvoiceApprovalMenuProps) {
  const [isRejectInvoiceModalOpen, isRejectInvoiceModalOpenHandlers] =
    useBoolean();
  const [isInvoiceFullyRejected, { setTrue: fullyRejectInvoice }] =
    useBoolean();
  const { currentState, id, type } = invoice;

  const { currentApprovalStep, previousApprovalStep } = useMemo(() => {
    const iapCurrentStepIndex = approvalSteps?.steps?.findIndex(
      (iapStep) => iapStep.id === currentState.stepId,
    );
    const iapPreviousStepIndex = iapCurrentStepIndex - 1;
    return {
      currentApprovalStep: approvalSteps?.steps?.[iapCurrentStepIndex],
      previousApprovalStep: approvalSteps?.steps?.[iapPreviousStepIndex],
    };
  }, [approvalSteps?.steps, currentState.stepId]);
  const isRejectingToSamePhase =
    previousApprovalStep?.phase === currentApprovalStep?.phase;
  const rejectToPreviousStepLabel = isRejectingToSamePhase
    ? `Reject back to ${previousApprovalStep?.name || "previous step"}`
    : "Reject back to Payables";

  function closeRejectInvoiceModal() {
    isRejectInvoiceModalOpenHandlers.setFalse();
  }

  function showRejectInvoiceModal() {
    isRejectInvoiceModalOpenHandlers.setTrue();
  }

  function onFullyRejectClick() {
    fullyRejectInvoice();
    showRejectInvoiceModal();
  }

  function onRejectToPreviousStepClick() {
    showRejectInvoiceModal();
  }

  return (
    <div>
      <Menu>
        <MenuTrigger>
          <Button variant={ButtonVariant.outlined} color={Color.red}>
            Reject
          </Button>
        </MenuTrigger>
        <MenuContent>
          {!currentState.isFirstStep && (
            <MenuButton onClick={onRejectToPreviousStepClick}>
              {rejectToPreviousStepLabel}
            </MenuButton>
          )}
          <MenuButton color="red" onClick={onFullyRejectClick}>
            Reject Completely
          </MenuButton>
        </MenuContent>
      </Menu>
      <Modal
        title="Reject Invoice"
        opened={isRejectInvoiceModalOpen}
        onClose={closeRejectInvoiceModal}>
        <ConnectedRejectInvoiceForm
          invoiceId={id}
          invoiceTransactionDate={invoice.transactionDate}
          invoiceType={type}
          isFullyRejected={isInvoiceFullyRejected}
          isRejectingToSamePhase={isRejectingToSamePhase}
          stepId={previousApprovalStep?.id}
          stepName={previousApprovalStep?.name ?? "previous step"}
          onSuccess={onPaymentRejection}
          closeModal={closeRejectInvoiceModal}
        />
      </Modal>
    </div>
  );
}

export { RejectInvoiceButton };
