import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionInspectionTypeValues = [
  "INITIAL_CONSTRUCTION",
  "TURNOVER",
  "SPECIAL_DAMAGE",
  "CHANGE_ORDERS",
  "NEW_BUILDS",
] as const;

const InspectionInspectionTypeEffectSchema = Schema.Literal(
  ...InspectionInspectionTypeValues,
);

type InspectionInspectionType = Schema.Schema.Type<
  typeof InspectionInspectionTypeEffectSchema
>;

const InspectionInspectionTypeEnum = castEnum(
  InspectionInspectionTypeEffectSchema,
);

function randomInspectionInspectionType(): InspectionInspectionType {
  return rand(InspectionInspectionTypeValues);
}

export {
  InspectionInspectionTypeEffectSchema,
  InspectionInspectionTypeEnum,
  InspectionInspectionTypeValues,
  randomInspectionInspectionType,
};
export type { InspectionInspectionType };
