import { IconDownload } from "@tabler/icons-react";
import type { PropsWithChildren } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import type { ButtonSizes, ButtonVariants } from "@ender/shared/ds/button";
import { ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { Menu, MenuContent, MenuTrigger } from "@ender/shared/ds/menu";
import { Color } from "@ender/shared/utils/theming";

type DownloadMenuProps = PropsWithChildren & {
  label?: string | Undefined;
  disabled?: boolean | Undefined;
  disabledTooltip?: string | Undefined;
  iconSize?: ButtonSizes;
  variant?: ButtonVariants;
};

function DownloadMenu(props: DownloadMenuProps) {
  const {
    children,
    label = "Download",
    disabled = false,
    disabledTooltip = "",
    variant = ButtonVariant.transparent,
    iconSize = ButtonSize.md,
  } = props;

  return (
    <Menu>
      <MenuTrigger>
        <ActionIcon
          color={Color.primary}
          label={label}
          variant={variant}
          size={iconSize}
          disabled={disabled}
          disabledTooltip={disabledTooltip}>
          <IconDownload />
        </ActionIcon>
      </MenuTrigger>
      <MenuContent align="end">{children}</MenuContent>
    </Menu>
  );
}

export { DownloadMenu };
