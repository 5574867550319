import { Option as O } from "effect";
import { useCallback } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";

type UseQueryParamsEnderIdReturn = [
  O.Option<EnderId>,
  (value: EnderId | O.Option<EnderId>) => void,
];

function useQueryParamsEnderId(key: string): UseQueryParamsEnderIdReturn {
  const [_value, _setValue] = useQueryParam(key, StringParam);

  // Ensure the value from the query params matches the schema
  const value: O.Option<EnderId> = EnderIdSchema.safeParse(_value).success
    ? O.some(_value as EnderId)
    : O.none();

  const setValue = useCallback(
    (value: EnderId | O.Option<EnderId>) => {
      if (O.isOption(value)) {
        O.match(value, {
          onNone: () => _setValue(UNDEFINED),
          onSome: (v) => _setValue(v),
        });
      } else if (EnderIdSchema.safeParse(value).success) {
        _setValue(value);
      }
    },
    [_setValue],
  );

  return [value, setValue];
}

export { useQueryParamsEnderId };
export type { UseQueryParamsEnderIdReturn };
