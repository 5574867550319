import { Predicate as P } from "effect";
import { isValidElement, useEffect } from "react";

import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import type { HeaderStoreState } from "@ender/shared/stores/header-store";
import { useHeaderStore } from "@ender/shared/stores/header-store";

type HeaderConfig = Partial<
  Pick<HeaderStoreState, "breadcrumbs" | "pageActionButton" | "subMenu">
>;

/**
 * util function that can turn ReactNodes into a stringifiable format.
 *
 * If we don't want this solution, we should prohibit `ReactNode` from the Breadcrumb type, and require Strings instead
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function safeStringifyReactNodes(key: any, value: any) {
  if (isValidElement(value)) {
    return [value.type, value.props];
  }
  return value;
}

/*
 * Sets all header state values based on the provided config object.
 * Due to the nature of this function, if you override header config within child components, the unmounting of those components
 * will not reset the header config to the previous state- instead, all values will be lost!
 *
 * The best way to handle expected behavior is to define the header config at the page level, and not change it by
 * adding new subRoutes or breadcrumbs within the child components.
 */
function useSetHeader(config?: HeaderConfig) {
  const setBreadcrumbs = useHeaderStore.use.setBreadcrumbs();
  const setSubMenu = useHeaderStore.use.setSubMenu();
  const setPageActionButton = useHeaderStore.use.setPageActionButton();

  /**
   * Store the latest config object to avoid unnecessary dispatches.
   */
  const _config = useRefLatest(config);
  /**
   * stringify the config object to be able to compare changes in the useEffect dependency
   */
  const configChangeObservant = JSON.stringify(config, safeStringifyReactNodes);

  /**
   * Dispatch the initial header config on mount.
   * clean up on unmount.
   */
  useEffect(() => {
    P.isNotNullable(_config.current?.breadcrumbs) &&
      setBreadcrumbs(_config.current.breadcrumbs);
    P.isNotNullable(_config.current?.subMenu) &&
      setSubMenu(_config.current.subMenu);
    P.isNotNullable(_config.current?.pageActionButton) &&
      setPageActionButton(_config.current.pageActionButton);
    return () => {
      setBreadcrumbs([]);
      setSubMenu([]);
      setPageActionButton(null);
    };
  }, [
    _config,
    configChangeObservant,
    setBreadcrumbs,
    setSubMenu,
    setPageActionButton,
  ]);
}

export { useSetHeader };
