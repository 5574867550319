import { deleteFactor } from "./delete-factor";
import { getBuiltInFactor } from "./get-built-in-factor";
import { getCustomFactors } from "./get-custom-factors";
import { getCustomFields } from "./get-custom-fields";
import { getFactor } from "./get-factor";
import { getLockedGroups } from "./get-locked-groups";
import { getPossibleEnumValues } from "./get-possible-enum-values";
import { lockFactorGroup } from "./lock-factor-group";
import { newFactor } from "./new-factor";
import { searchFactors } from "./search-factors";
import { setCustomField } from "./set-custom-field";
import { setCustomFields } from "./set-custom-fields";
import { unlockFactorGroup } from "./unlock-factor-group";
import { updateCustomFactor } from "./update-custom-factor";
import { updateCustomFactorOrdering } from "./update-custom-factor-ordering";

const FactorsAPI = {
  deleteFactor,
  getBuiltInFactor,
  getCustomFactors,
  getCustomFields,
  getFactor,
  getLockedGroups,
  getPossibleEnumValues,
  lockFactorGroup,
  newFactor,
  searchFactors,
  setCustomField,
  setCustomFields,
  unlockFactorGroup,
  updateCustomFactor,
  updateCustomFactorOrdering,
};

export { FactorsAPI };
