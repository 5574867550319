import { Function as F } from "effect";

import { RightRail } from "@ender/shared/ui/right-rail";
import { fail } from "@ender/shared/utils/error";

import type { UploadFormProps } from "./upload-form";
import { UploadForm } from "./upload-form";

type UploadRailProps = UploadFormProps & {
  title: string;
  opened: boolean;
  onClose: () => void;
};

/**
 * @deprecated no replacement intended. Just use UploadForm with a Rail directly.
 */
function UploadRail({
  children,
  footer,
  header,
  onClose,
  onError = fail,
  onSubmit,
  onSuccess = F.constVoid,
  opened,
  submitButtonDisabled = false,
  title,
}: UploadRailProps) {
  return (
    <RightRail title={title} opened={opened} onClose={onClose}>
      <UploadForm
        footer={footer}
        header={header}
        onError={onError}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        submitButtonDisabled={submitButtonDisabled}>
        {children}
      </UploadForm>
    </RightRail>
  );
}

export { UploadRail };
