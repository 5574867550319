/**
 * Register receive only customer in Dwolla
 * HTTP Method: POST
 * Pathname: /dwolla/registerReceiveOnlyCustomer
 * @function registerReceiveOnlyCustomer
 * @memberof DwollaAPI
 * @param {DwollaAPIRegisterReceiveOnlyCustomerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPIRegisterReceiveOnlyCustomerSearchParams = {
  token?: string | undefined;
};

type DwollaAPIRegisterReceiveOnlyCustomerBodyParams = {
  /**
   * Agree to terms or not
   */
  agreedToTerms: boolean;
  /**
   * Business name. Optional. If it is not given, then the bank account is personal.
   */
  businessName: string;
  /**
   * The EnderID of the customer model
   */
  customerId: EnderId;
  /**
   * Type of the customer model
   */
  customerType: ModelType;
  /**
   * Customer email
   */
  email: string;
  /**
   * Customer first name
   */
  firstName: string;
  /**
   * Customer first name
   */
  lastName: string;
};

type DwollaAPIRegisterReceiveOnlyCustomerPayload =
  DwollaAPIRegisterReceiveOnlyCustomerSearchParams &
    DwollaAPIRegisterReceiveOnlyCustomerBodyParams;

function registerReceiveOnlyCustomerUnsafeEffect(
  payload: DwollaAPIRegisterReceiveOnlyCustomerPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/dwolla/registerReceiveOnlyCustomer",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function registerReceiveOnlyCustomer(
  payload: DwollaAPIRegisterReceiveOnlyCustomerPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    registerReceiveOnlyCustomerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { registerReceiveOnlyCustomer };
export type {
  DwollaAPIRegisterReceiveOnlyCustomerBodyParams,
  DwollaAPIRegisterReceiveOnlyCustomerPayload,
  DwollaAPIRegisterReceiveOnlyCustomerSearchParams,
};
