import { IconCar, IconPlus } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useCallback, useState } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { Vehicle } from "@ender/shared/generated/ender.model.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import type {
  AddVehicleFormOutput,
  EditVehicleFormOutput,
} from "@ender/widgets/leasing/add-vehicle-form";
import {
  AddVehicleForm,
  EditVehicleForm,
} from "@ender/widgets/leasing/add-vehicle-form";
import { VehicleCard } from "@ender/widgets/leasing/vehicle-card";

function VehicleCardController({
  isDeleting,
  onEdit,
  onRemove,
  vehicle,
}: {
  isDeleting: boolean;
  onEdit: (vehicle: Vehicle) => void;
  onRemove: (vehicle: Vehicle) => void;
  vehicle: Vehicle;
}) {
  const handleDeleteClick = useCallback(
    () => onRemove(vehicle),
    [onRemove, vehicle],
  );
  const handleEditClick = useCallback(() => onEdit(vehicle), [onEdit, vehicle]);

  return (
    <VehicleCard
      key={vehicle.id}
      vehicle={vehicle}
      isWorking={isDeleting}
      onDeleteClick={handleDeleteClick}
      onEditClick={handleEditClick}
      isDeletable
      isEditable
    />
  );
}

function TenantVehicles({ lease }: { lease: LeaseSerializerLeaseResponse }) {
  const [vehicleToDelete, setVehicleToDelete] = useState<Vehicle | Undefined>();
  const [vehicleToEdit, setVehicleToEdit] = useState<Vehicle | Undefined>();
  const confirmation = useConfirmationContext();
  const { data: vehicles, refetch: refetchVehicles } = useQuery({
    queryFn: () => LeasingAPI.getVehicles({ leaseId: lease.id }),
    queryKey: ["getVehicles", lease.id],
  });

  const [isAddVehicleModalOpen, vehicleModalHandlers] = useBoolean(false);
  const [isEditVehicleModalOpen, editVehicleModalHandlers] = useBoolean(false);
  const { mutateAsync: addVehicle, isLoading: addingVehicle } = useMutation({
    mutationFn: LeasingAPI.addVehicle,
    mutationKey: ["addVehicle", lease.id],
  });
  const { mutateAsync: deleteVehicle, isLoading: isDeletingVehicle } =
    useMutation({
      mutationFn: LeasingAPI.deleteVehicle,
      mutationKey: ["deleteVehicle", lease.id],
    });
  const { mutateAsync: editVehicle, isLoading: editingVehicle } = useMutation({
    mutationFn: LeasingAPI.updateVehicle,
    mutationKey: ["editVehicle", lease.id],
  });

  const handleAddVehicleFormSubmit = useCallback(
    async (values: AddVehicleFormOutput) => {
      await addVehicle({ leaseId: lease.id, ...values });
      showSuccessNotification({ message: "Vehicle Added" });
      vehicleModalHandlers.setFalse();
      await refetchVehicles();
    },
    [vehicleModalHandlers, refetchVehicles, addVehicle, lease.id],
  );

  const handleEditVehicle = useCallback(
    (vehicle: Vehicle) => {
      setVehicleToEdit(vehicle);
      editVehicleModalHandlers.setTrue();
    },
    [editVehicleModalHandlers],
  );

  const handleEditVehicleCancel = useCallback(() => {
    setVehicleToEdit(undefined);
    editVehicleModalHandlers.setFalse();
  }, [editVehicleModalHandlers]);

  const handleEditVehicleFormSubmit = useCallback(
    async (values: EditVehicleFormOutput) => {
      if (P.isNullable(vehicleToEdit)) {
        throw new Error("Should never occur: Vehicle to edit is undefined");
      }

      await editVehicle({ vehicleId: vehicleToEdit.id, ...values });
      showSuccessNotification({ message: "Vehicle Added" });
      editVehicleModalHandlers.setFalse();
      await refetchVehicles();
    },
    [editVehicleModalHandlers, refetchVehicles, editVehicle, vehicleToEdit],
  );

  const handleRemoveVehicle = useCallback(
    async (vehicle: Vehicle) => {
      await confirmation({
        content: (
          <Text
            size={
              FontSize.sm
            }>{`${vehicle.make} ${vehicle.model}, License Plate ${vehicle.licensePlateNumber}`}</Text>
        ),
        title: "Are you sure you want to remove the following vehicle?",
      });
      setVehicleToDelete(vehicle);
      await deleteVehicle({ vehicleId: vehicle.id });
      setVehicleToDelete(undefined);
      showSuccessNotification({ message: "Tenant vehicle removed" });
      refetchVehicles();
    },
    [confirmation, refetchVehicles, deleteVehicle],
  );

  if (P.isNullable(vehicles)) {
    return NULL;
  }

  return (
    <>
      <Stack>
        <Group justify={Justify.between}>
          <Group spacing={Spacing.sm}>
            <IconCar size={24} color="var(--color-slate-600)" />
            <H2>Vehicles</H2>
          </Group>
          <ActionIcon
            label="Add Vehicle"
            onClick={vehicleModalHandlers.setTrue}>
            <IconPlus />
          </ActionIcon>
        </Group>
        {A.isEmptyArray(vehicles) ? (
          <Text weight={FontWeight.medium}>
            There are no vehicles for this tenant.
          </Text>
        ) : (
          <Grid>
            {vehicles.map((vehicle) => (
              <VehicleCardController
                key={vehicle.id}
                isDeleting={
                  isDeletingVehicle && vehicleToDelete?.id === vehicle.id
                }
                onEdit={handleEditVehicle}
                onRemove={handleRemoveVehicle}
                vehicle={vehicle}
              />
            ))}
          </Grid>
        )}
      </Stack>
      <Modal
        title="Add Vehicle"
        opened={isAddVehicleModalOpen}
        onClose={vehicleModalHandlers.setFalse}>
        <AddVehicleForm
          onSubmit={handleAddVehicleFormSubmit}
          onCancel={vehicleModalHandlers.setFalse}
          loading={addingVehicle}
          isPM
        />
      </Modal>
      <Modal
        title="Edit Vehicle"
        opened={isEditVehicleModalOpen}
        onClose={handleEditVehicleCancel}>
        {P.isNotNullable(vehicleToEdit) && (
          <EditVehicleForm
            onSubmit={handleEditVehicleFormSubmit}
            onCancel={handleEditVehicleCancel}
            loading={editingVehicle}
            vehicle={vehicleToEdit as Vehicle}
          />
        )}
      </Modal>
    </>
  );
}

export { TenantVehicles };
