import type { ButtonStylesParams } from "@mantine/core";
import type {
  DefaultMantineColor,
  MantineColor,
  MantineThemeOverride,
  Tuple,
} from "@mantine/styles";

type EnderExtraColors = "purple" | "magenta" | "slate";
type EnderColors = EnderExtraColors | DefaultMantineColor;

const ENDER_CONTAINER_HORIZONTAL_PADDING = 24;

/**
 * Theme configuration object. All Mantine theme goes here.
 * Intended to be used in MantineProvider theme prop only.
 * @readonly
 * @object
 */
const enderMantineTheme: MantineThemeOverride = {
  components: {
    Affix: {
      defaultProps: {
        zIndex: 100,
      },
    },
    Container: {
      defaultProps: {
        sizes: {
          // sm: 944,
          // md: 944,
          // lg: 1328,
        },
      },
      styles: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        },
      },
    },
    Input: {
      styles: (theme) => ({
        invalid: { color: theme.black },
        wrapper: {
          marginBottom: 0,
          "&:has(.mantine-Input-invalid) > .mantine-Input-icon": {
            color: theme.colors.red[5],
          },
        },
      }),
    },
    InputWrapper: {
      styles: (theme) => ({
        error: { marginTop: 4 },
        root: {
          "&:has(.mantine-Input-invalid,.mantine-InputWrapper-error) > .mantine-InputWrapper-label":
            {
              color: theme.colors.red[5],
            },
        },
      }),
    },
    Button: {
      styles: (_theme, { variant }: ButtonStylesParams) => ({
        root: {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: variant !== "light" ? "transparent" : "currentcolor",
        },
      }),
    },
  },
  breakpoints: {
    xs: 734,
    sm: 734,
    md: 1080,
    lg: 1464,
    xl: 2072,
  },
  primaryColor: "purple",
  colors: {
    client: [
      "var(--color-primary-50)",
      "var(--color-primary-100)",
      "var(--color-primary-200)",
      "var(--color-primary-300)",
      "var(--color-primary-400)",
      "var(--color-primary-500)",
      "var(--color-primary-600)",
      "var(--color-primary-700)",
      "var(--color-primary-800)",
      "var(--color-primary-900)",
    ] as Tuple<MantineColor, 10>,
    gray: [
      "#F9F8F8", // Gray 50
      "#E9E9E9", // Gray 100 / Disabled Button
      "#CCCCCC", // Gray 200 / Input Outline
      "#B3B3B3", // Gray 300 / Unfilled Input
      "#999999", // Gray 400
      "#808080", // Gray 500
      "#666666", // Gray 600
      "#4C4C4C", // Gray 700 / Alt Text
      "#333333", // Gray 800
      "#181818", // Gray 900
    ] as Tuple<MantineColor, 10>,
    green: [
      // Success
      "#EDFFF2", //50
      "#D4FADE",
      "#BBF6CB",
      "#94DEBF",
      "#6AC9A1",
      "#3BAC7D", //500
      "#349E72",
      "#22835B",
      "#196445",
      "#0F422D",
    ] as Tuple<MantineColor, 10>,
    magenta: [
      //Potentially unused? We use red for error now
      // Notifications
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FF6982",
      "#FF6982",
      "#FF6982",
      "#FF6982",
      "#FF6982",
    ] as Tuple<MantineColor, 10>,
    purple: [
      "#F8F7FF", // Purple 0 / Secondary Button, repeated value
      "#F3F0FF", // Purple 100 / Secondary Button
      "#E2DBFF", // Purple 200 / Secondary Button hover
      "#D7CEF8", // Purple 300 / Secondary Button pressed
      "#7667C0", // Purple 400
      "#5843BE", // Purple 500 / Primary Purple
      "#4227C1", // Purple 600 / Primary Button hover
      "#2E179E", // Purple 700 / Primary Button pressed
      "#25137B", // Purple 800
      "#1A0A6B", // Purple 900 / Side Nav
    ] as Tuple<MantineColor, 10>,
    red: [
      // Error, Unsuccessful
      "#FFF5F4", // Red 50
      "#FCE3E1", // Red 100
      "#FFB5C1", // Red 200
      "#FF6982", // Red 300
      "#F64A57", // Red 400
      "#E6202F", // Red 500
      "#D91221", // Red 600
      "#C51421", // Red 700
      "#A7252F", // Red 800
      "#81121B", // Red 900
    ] as Tuple<MantineColor, 10>,
    slate: [
      "#F2F3FF", // White / Text, icons
      "#E6E7F4",
      "#D5D6E8",
      "#C0C1DA", // Slate 300
      "#B7B8D2",
      "#9B9CB9",
      "#7E7F9B", // Slate 600 / Icons, alt text
      "#64657C",
      "#3E3F55",
      "#0E0E2C", // Slate 900 / Main text color
    ] as Tuple<MantineColor, 10>,
    yellow: [
      // Status
      "#FFF8DF", // Yellow 50
      "#FEF2C7",
      "#FFE8AC",
      "#FFDC98",
      "#FFCD6D",
      "#FFB21E", // Yellow 500
      "#EA9F11", // Yellow 600
      "#C3840B", // Yellow 700
      "#A45D23", // Yellow 800
      "#7C4415", // Yellow 900
    ] as Tuple<MantineColor, 10>,
  },
  fontFamily: `var(--theme-font-family)`,
  // Font sizes in px, other units are not supported
  fontSizes: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 20,
  },
  headings: {
    sizes: {
      // Big header
      h1: { fontSize: 22, fontWeight: 600, lineHeight: 1.25 },
      // Section header
      h2: { fontSize: 16, fontWeight: 600, lineHeight: 1.5 },
      h3: { fontSize: 16, fontWeight: 600, lineHeight: 1.5 },
      // Table header (letter spacing = 1%)
      h4: { fontSize: 12, fontWeight: 500, lineHeight: 1.15 },
      // Eyebrow (letter spacing = 8%)
      h5: { fontSize: 12, fontWeight: 400, lineHeight: 1.33 },
      h6: { fontSize: 12, fontWeight: 400, lineHeight: 1.33 },
    },
  },
};

declare module "@mantine/core" {
  //@ts-expect-error
  // eslint-disable-next-line import/group-exports
  export type MantineThemeColorsOverride = {
    colors: Record<EnderColors, Tuple<string, 10>>;
  };
}

export { ENDER_CONTAINER_HORIZONTAL_PADDING, enderMantineTheme };
