import {
  IconChecklist,
  IconCoin,
  IconDeviceMobileMessage,
  IconHomeX,
  IconMail,
  IconMailbox,
  IconPhone,
} from "@tabler/icons-react";
import type { ReactNode } from "react";
import { z } from "zod";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { CollectionsStepCollectionsActionType } from "@ender/shared/generated/ender.model.misc";
import { CollectionsStepCollectionsActionTypeEnum } from "@ender/shared/generated/ender.model.misc";
import { castEnum } from "@ender/shared/utils/zod";

import type { CollectionsType } from "./collections.types";
import { CollectionsTypeEnum } from "./collections.types";

type CollectionsActionNeededForDisplay = {
  icon: ReactNode;
  labelFull: string;
  labelShort: string;
};

/** @description 5 minutes */
const COLLECTIONS_API_STALE_TIME = 300000;

const COLLECTIONS_DRAWER_QUERY_KEY = "leaseId";

const PaymentPlansRightRailValues = ["DETAIL", "CREATE"] as const;
const PaymentPlansRightRailSchema = z.enum(PaymentPlansRightRailValues);
type PaymentPlansRightRailType = z.infer<typeof PaymentPlansRightRailSchema>;
const PaymentPlansRightRailTypeEnum = castEnum<PaymentPlansRightRailType>(
  PaymentPlansRightRailSchema,
);

const CollectionsActionNeededForDisplayMap: Record<
  CollectionsStepCollectionsActionType,
  CollectionsActionNeededForDisplay
> = {
  [CollectionsStepCollectionsActionTypeEnum.CREATE_TASK]: {
    icon: <IconChecklist color={EnderThemeColorEnum.RED_300} />,
    labelFull: 'Create Task "[label]"',
    labelShort: "Create Task",
  },
  [CollectionsStepCollectionsActionTypeEnum.EMAIL]: {
    icon: <IconMail color={EnderThemeColorEnum.RED_300} />,
    labelFull: "Send [label]",
    labelShort: "Send [label]",
  },
  [CollectionsStepCollectionsActionTypeEnum.LATE_FEE]: {
    icon: <IconCoin color={EnderThemeColorEnum.RED_300} />,
    labelFull: "Charge Late Fee",
    labelShort: "Charge Late Fee",
  },
  [CollectionsStepCollectionsActionTypeEnum.INTEND_TO_EVICT]: {
    icon: <IconHomeX color={EnderThemeColorEnum.RED_300} />,
    labelFull: "Intend to Evict",
    labelShort: "Intend to Evict",
  },
  [CollectionsStepCollectionsActionTypeEnum.MOVE_TO_EVICT]: {
    icon: <IconHomeX color={EnderThemeColorEnum.RED_300} />,
    labelFull: "Move to Evict",
    labelShort: "Move to Evict",
  },
  [CollectionsStepCollectionsActionTypeEnum.CERTIFIED_MAIL]: {
    icon: <IconMailbox color={EnderThemeColorEnum.RED_300} />,
    labelFull: "Send Certified Mail",
    labelShort: "Send Certified Mail",
  },
  [CollectionsStepCollectionsActionTypeEnum.PHONE_CALL]: {
    icon: <IconPhone color={EnderThemeColorEnum.RED_300} size={16} />,
    labelFull: "Call [label]",
    labelShort: "Call",
  },
  [CollectionsStepCollectionsActionTypeEnum.SMS]: {
    icon: <IconDeviceMobileMessage color={EnderThemeColorEnum.RED_300} />,
    labelFull: "Send [label]",
    labelShort: "Send [label]",
  },
} as const;

function getCollectionsActionNeededForDisplay(
  actionType: CollectionsStepCollectionsActionType,
): CollectionsActionNeededForDisplay {
  return CollectionsActionNeededForDisplayMap[actionType];
}

function isTemplatedActionType(
  actionType: CollectionsStepCollectionsActionType | Null | Undefined,
): boolean {
  return (
    actionType === CollectionsStepCollectionsActionTypeEnum.EMAIL ||
    actionType === CollectionsStepCollectionsActionTypeEnum.CERTIFIED_MAIL ||
    actionType === CollectionsStepCollectionsActionTypeEnum.SMS
  );
}

function doesCollectionsTypeSupportActions(
  activeType: CollectionsType | Null,
): boolean {
  return (
    activeType === CollectionsTypeEnum.STABLE ||
    activeType === CollectionsTypeEnum.ALL_ACTIVE_LEASES ||
    activeType === CollectionsTypeEnum.INTEND_TO_EVICT ||
    activeType === CollectionsTypeEnum.EVICTIONS
  );
}

export {
  COLLECTIONS_API_STALE_TIME,
  COLLECTIONS_DRAWER_QUERY_KEY,
  CollectionsActionNeededForDisplayMap,
  PaymentPlansRightRailSchema,
  PaymentPlansRightRailTypeEnum,
  PaymentPlansRightRailValues,
  doesCollectionsTypeSupportActions,
  getCollectionsActionNeededForDisplay,
  isTemplatedActionType,
};

export type { PaymentPlansRightRailType };
