import { z } from "zod";

import type { DealChannel } from "@ender/shared/generated/com.ender.buy.model.misc";
import { castEnum } from "@ender/shared/utils/zod";

const BrokerFilterableStatusesValues = [
  "OFFER",
  "DUE_DILIGENCE",
  "TERMINATED",
] as const;
const BrokerFilterableStatusesSchema = z.enum(BrokerFilterableStatusesValues);
type BrokerFilterableStatuses = z.infer<typeof BrokerFilterableStatusesSchema>;

const BrokerFilterableStatusesEnum = castEnum<BrokerFilterableStatuses>(
  BrokerFilterableStatusesSchema,
);

const BrokerEditableStatusesValues = [
  "DUE_DILIGENCE",
  "NOT_FOR_SALE",
  "OFFER",
  "ON_MARKET",
  "TERMINATED",
] as const;
const BrokerEditableStatusesSchema = z.enum(BrokerEditableStatusesValues);
type BrokerEditableStatuses = z.infer<typeof BrokerEditableStatusesSchema>;
const BrokerEditableStatusesEnum = castEnum<BrokerEditableStatuses>(
  BrokerEditableStatusesSchema,
);

const OfferTypeValues = [
  "BUYER",
  "BROKER",
  "SELLER_COUNTER",
  "BUYER_CONCESSION_REQUEST",
  "SELLER_CONCESSION_RESPONSE",
] as const;
const OfferTypeSchema = z.enum(OfferTypeValues);
type OfferType = z.infer<typeof OfferTypeSchema>;

const OfferTypeEnum = castEnum<OfferType>(OfferTypeSchema);

const PipelineStatusValues = [
  "CONSTRUCTION",
  "DUE_DILIGENCE",
  "LEAD",
  "MARKETING",
  "OCCUPIED",
  "NOT_FOR_SALE",
  "OFFER",
  "ON_MARKET",
  "POST_DUE_DILIGENCE",
  "PRE_REPAIR",
  "STABILIZED",
  "TERMINATED",
] as const;
const PipelineStatusSchema = z.enum(PipelineStatusValues);
type PipelineStatus = z.infer<typeof PipelineStatusSchema>;

const PipelineStatusEnum = castEnum<PipelineStatus>(PipelineStatusSchema);

const BuyAppChannelDisplayEnum: Record<DealChannel, string> = {
  BUILD_TO_RENT: "Build to Rent",
  BULK: "Bulk",
  MLS: "MLS",
  OFF_MARKET: "Off Market",
};

export {
  BrokerEditableStatusesEnum,
  BrokerEditableStatusesSchema,
  BrokerEditableStatusesValues,
  BrokerFilterableStatusesEnum,
  BrokerFilterableStatusesSchema,
  BrokerFilterableStatusesValues,
  BuyAppChannelDisplayEnum,
  OfferTypeEnum,
  OfferTypeSchema,
  OfferTypeValues,
  PipelineStatusEnum,
  PipelineStatusSchema,
  PipelineStatusValues,
};
export type {
  BrokerEditableStatuses,
  BrokerFilterableStatuses,
  OfferType,
  PipelineStatus,
};
