import { clsx } from "clsx";
import { Array as A } from "effect";
import { forwardRef, useMemo } from "react";

import { NavLogoDarkMarkOnlySvg } from "@ender/shared/assets";
import type { EnderId } from "@ender/shared/core";
import type { User } from "@ender/shared/generated/ender.model.core.user";

import styles from "./user-icon.module.css";

const colorArray: { color: string; background: string }[] = [
  { background: "#d7f9eb", color: "#5ec69b" },
  { background: "#dfcad5", color: "#8e4b6e" },
  { background: "#ffdbd0", color: "#ff815f" },
  { background: "#fff1e5", color: "#fdb02c" },
  { background: "#ffd2d4", color: "#e75c62" },
  // reversed to get more variation
  { background: "#5ec69b", color: "#d7f9eb" },
  { background: "#8e4b6e", color: "#dfcad5" },
  { background: "#ff815f", color: "#ffdbd0" },
  { background: "#fdb02c", color: "#fff1e5" },
  { background: "#e75c62", color: "#ffd2d4" },
];

// Hash a string to 32bit integer
function nameToHash(name: string): number {
  return (
    name
      .split("")
      .reduce((hash, char) => (hash << 5) - hash + char.charCodeAt(0), 0) | 0
  );
}

function getColors(name: string): [string, string] {
  // Select color using the hash of the name
  const hash = Math.abs(nameToHash(name));
  const baseIdx = Math.max(
    0,
    Math.min(hash % colorArray.length, colorArray.length - 1),
  );
  const { color, background } = colorArray[baseIdx];
  return [color, background];
}

type BaseUserIconProps = {
  size?: number;
  className?: string;
};

type DynamicUserIconProps = BaseUserIconProps & {
  id: EnderId;
  name: string;
};

type UserIconProps = BaseUserIconProps & {
  /**
   * If the provided `user` does not have a `name`, an empty string is used
   * If the provided `user` does not have an ID, the auto user icon will be seeded with ""
   * which means all un-identified users will display the same icon.
   *
   * We cannot make this field _required_ as there are some cases (ChatTimelineAuthorResponse) which are not guaranteed
   * to have an ID.
   */
  user?: Partial<Pick<User, "id" | "name">>;
};

/** @description User Icon which integrates dynamic data */
const DynamicUserIcon = forwardRef<HTMLDivElement, DynamicUserIconProps>(
  function DynamicUserIcon({ id, name, size, className, ...other }, ref) {
    const [color, background] = useMemo(() => getColors(name), [name]);
    const initials = useMemo(() => {
      const firstLetters = name
        .replace(/[^A-Z a-z]/g, "")
        .trim()
        .split(" ")
        .map((part) => part.charAt(0).toUpperCase());
      if (A.isEmptyArray(firstLetters)) {
        return id.slice(0, 2).toUpperCase();
      }
      return firstLetters.length < 2
        ? firstLetters[0]
        : [firstLetters[0], firstLetters[firstLetters.length - 1]].join("");
    }, [id, name]);

    return (
      <div className={className} ref={ref} {...other}>
        <svg
          className={styles.img}
          xmlns="http://www.w3.org/2000/svg"
          width={`${size}px`}
          height={`${size}px`}
          viewBox="0 0 56 56"
          version="1.1">
          <circle
            fill={background}
            cx="28"
            width="56"
            height="56"
            cy="28"
            r="28"
          />
          <text
            x="50%"
            y="50%"
            style={{ color: color, fontFamily: "inherit", lineHeight: 1 }}
            alignmentBaseline="middle"
            textAnchor="middle"
            fontSize="24"
            fontWeight="500"
            dy=".1em"
            dominantBaseline="middle"
            fill={color}>
            {initials}
          </text>
        </svg>
      </div>
    );
  },
);

/**
 * @deprecated move this into the nav package or the chat widget.
 */
const UserIcon = forwardRef<HTMLDivElement, UserIconProps>(function UserIcon(
  { user, size = 28, className, ...other },
  ref,
) {
  const { name = "", id = "" as EnderId } = user || {};

  if (name === "Ender Bot") {
    return (
      <div className={clsx(styles.root, className)} ref={ref} {...other}>
        <img
          src={NavLogoDarkMarkOnlySvg}
          width={size}
          height={size}
          alt=""
          loading="lazy"
          className={styles.img}
        />
      </div>
    );
  }

  return (
    <DynamicUserIcon
      id={id}
      name={name}
      size={size}
      className={clsx(styles.root, className)}
      ref={ref}
      {...other}
    />
  );
});

export { DynamicUserIcon, UserIcon };
