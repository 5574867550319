/**
 * HTTP Method: GET
 * Pathname: /leads/{leadId}/pets
 * @function getPetsForLead
 * @memberof PetsAPI
 * @param {PetsAPIGetPetsForLeadPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Pet[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIGetPetsForLeadPathParams = {
  leadId: EnderId;
};

type PetsAPIGetPetsForLeadSearchParams = {
  token?: string | undefined;
  includeArchived?: boolean | undefined;
};

type PetsAPIGetPetsForLeadPayload = PetsAPIGetPetsForLeadPathParams &
  PetsAPIGetPetsForLeadSearchParams;

function getPetsForLeadUnsafeEffect(payload: PetsAPIGetPetsForLeadPayload) {
  const { leadId, token, includeArchived, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Pet[]>({
        body,
        decode: unsafeDecodeJsonResponse<Pet[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leads/${leadId}/pets`,
        searchParams: [
          ["token", token],
          ["includeArchived", includeArchived],
        ],
      }),
    ),
  );
}

function getPetsForLead(
  payload: PetsAPIGetPetsForLeadPayload,
  options?: { signal?: AbortSignal },
): Promise<Pet[]> {
  return F.pipe(
    payload,
    getPetsForLeadUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPetsForLead };
export type {
  PetsAPIGetPetsForLeadPathParams,
  PetsAPIGetPetsForLeadPayload,
  PetsAPIGetPetsForLeadSearchParams,
};
