import { Array as A } from "effect";

import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";

import type { PropertyDetails } from "./underwriting-queue-table.types";

import styles from "./underwriting-bulk-action-deal-list.module.css";

const NUM_DEALS_TO_SHOW = 7;

function UnderwritingBulkActionDealList({
  selectedDeals = [],
}: {
  selectedDeals: PropertyDetails[];
}) {
  return (
    <>
      <Text size={FontSize.lg} weight={FontWeight.semibold}>
        Selected Deals
      </Text>
      <div className={styles.dealsContainer}>
        {selectedDeals
          .slice(0, NUM_DEALS_TO_SHOW)
          .map(
            ({
              dealId,
              street: { value: street },
              city: { value: city },
              listPrice: { value: listPrice },
            }) => (
              <div key={dealId} className={styles.deal}>
                {street}, {city} {listPrice ? `- ${listPrice}` : ""}
              </div>
            ),
          )}
        {selectedDeals.length > NUM_DEALS_TO_SHOW && (
          <>
            <div className={styles.plusMoreDeals}>...</div>
            <div className={styles.deal}>
              + {selectedDeals.length - NUM_DEALS_TO_SHOW} more deals
            </div>
          </>
        )}
        {A.isEmptyArray(selectedDeals) && <div>No Deals Selected</div>}
      </div>
    </>
  );
}

export { UnderwritingBulkActionDealList };
