import {
  IconChevronsDown,
  IconChevronsUp,
  IconTrash,
} from "@tabler/icons-react";
import type { PropsWithChildren, ReactElement, Ref } from "react";
import { forwardRef, useImperativeHandle } from "react";
import { z } from "zod";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { useFormContext } from "@ender/shared/forms/hooks/general";
import { FormSection } from "@ender/shared/forms/ui/form-section";

import { useCreateFormListControls } from "./form-list.hooks";
import type { FormListControls } from "./form-list.types";
import { getFinalValue } from "./form-list.utils";

const FormListPropsSchema = z.object({
  initialValue: z.any(),
  name: z.string(),
});

type FormListProps = z.infer<typeof FormListPropsSchema>;

const FormList = forwardRef<
  FormListControls<unknown>,
  PropsWithChildren<FormListProps>
>(function FormList<T>(
  { children, initialValue, name }: PropsWithChildren<FormListProps>,
  ref: Ref<FormListControls<T>>,
) {
  const form = useFormContext<T>();
  const finalValue = getFinalValue(form, name);
  const controls = useCreateFormListControls<T>(name, initialValue);
  useImperativeHandle(ref, () => controls, [controls]);

  return (
    <>
      {finalValue.map((_, index: number) => {
        return (
          <Group key={index}>
            <Stack grow>
              <FormSection name={`${name}.${index}`}>{children}</FormSection>
            </Stack>
            <Stack>
              <ActionIcon
                disabled={index === 0}
                onClick={() => {
                  controls.reorder(index, index - 1);
                }}>
                <IconChevronsUp />
              </ActionIcon>
              <ActionIcon>
                <IconTrash onClick={() => controls.remove(index)} />
              </ActionIcon>
              <ActionIcon
                disabled={index === finalValue.length - 1}
                onClick={() => {
                  controls.reorder(index, index + 1);
                }}>
                <IconChevronsDown />
              </ActionIcon>
            </Stack>
          </Group>
        );
      })}
    </>
  );
}) as <T>(
  // eslint-disable-next-line no-use-before-define
  props: PropsWithChildren<FormListProps> & { ref?: Ref<FormListControls<T>> },
) => ReactElement;

export { FormList, FormListPropsSchema };
export type { FormListControls, FormListProps };
