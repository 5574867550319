import { useQuery } from "@tanstack/react-query";

import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type {
  AccountingPeriod,
  AccountingPeriodAccountingModule,
} from "@ender/shared/generated/ender.model.accounting";

type UseAccountingPeriodsProps = {
  disabled?: boolean;
  periodType: AccountingPeriodAccountingModule;

  /** @description String value to make the query key unique */
  queryKeyAddendum?: string;

  /** @description Controls whether caching should be used for the query */
  useCache?: boolean;
};

/** @description Hook used to retrieve accounting periods data */
function useAccountingPeriods({
  disabled = false,
  periodType,
  queryKeyAddendum = "",
  useCache = true,
}: UseAccountingPeriodsProps) {
  const queryConfiguration = useCache
    ? {
        cacheTime: 300000,
        enabled: !disabled,
        initialData: [],
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      }
    : {
        initialData: [],
      };
  return useQuery<AccountingPeriod[], unknown>({
    queryFn: ({ signal }) =>
      AccountingAPI.getAccountingPeriods({ module: periodType }, { signal }),
    queryKey: [
      "AccountingAPI.getAccountingPeriods",
      periodType,
      queryKeyAddendum,
    ] as const,
    ...queryConfiguration,
  });
}

export { useAccountingPeriods };
