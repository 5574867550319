/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}
 * @function updateDeal
 * @memberof BuyAPI
 * @param {BuyAPIUpdateDealPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIUpdateDealPathParams = {
  dealId: EnderId;
};

type BuyAPIUpdateDealSearchParams = {
  token?: string | undefined;
};

type BuyAPIUpdateDealBodyParams = {
  /**
 * The Investor who this Deal is allocated to. The outer optional indicates if the request is
                      setting this value or not. The inner optional allows the value to be set to EMPTY
 */
  buyerFirmId?: EnderId | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
  overrideWarnings?: boolean | undefined;
  titleCompanyId?: EnderId | undefined;
  /**
 * The underwriter who is assigned to this Deal. The outer optional indicates if the request is
                      setting this value or not. The inner optional allows the value to be set to EMPTY
 */
  underwriterId?: EnderId | undefined;
};

type BuyAPIUpdateDealPayload = BuyAPIUpdateDealPathParams &
  BuyAPIUpdateDealSearchParams &
  BuyAPIUpdateDealBodyParams;

function updateDealUnsafeEffect(payload: BuyAPIUpdateDealPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateDeal(
  payload: BuyAPIUpdateDealPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateDealUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateDeal };
export type {
  BuyAPIUpdateDealBodyParams,
  BuyAPIUpdateDealPathParams,
  BuyAPIUpdateDealPayload,
  BuyAPIUpdateDealSearchParams,
};
