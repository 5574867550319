import { Schema } from "@effect/schema";
// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Card as RadixCard } from "@radix-ui/themes";
import { cva } from "class-variance-authority";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { ColorSchema, Size, SizeSchema } from "@ender/shared/utils/theming";

const CardPaddingSchema = Schema.Literal(
  ...SizeSchema.pipe(Schema.pickLiteral(Size.sm, Size.md, Size.lg)).literals,
  "none",
);
const CardPaddingValues = CardPaddingSchema.literals;
type CardPaddings = Schema.Schema.Type<typeof CardPaddingSchema>;
const CardPadding = castEnum(CardPaddingSchema);

const CardColorSchema = ColorSchema.pipe(
  Schema.pickLiteral("primary", "slate", "yellow", "red", "green"),
);
const CardColorValues = CardColorSchema.literals;
type CardColors = Schema.Schema.Type<typeof CardColorSchema>;
const CardColor = castEnum(CardColorSchema);

type CardProps = {
  padding?: CardPaddings;
  color?: CardColors;
  borderless?: boolean;
  /**
   * The id of the element that labels the card.
   * intended to be used with heading ID
   */
  labelledBy?: string;
  //whether this card should shrink when in a flex container. default false
  shrink?: boolean;
  grow?: boolean;
};

const CardVariantGenerator = cva(["rounded-lg relative"], {
  compoundVariants: [],
  defaultVariants: {
    borderless: false,
    color: CardColor.slate,
    grow: false,
    padding: CardPadding.md,
    shrink: false,
  },
  variants: {
    borderless: {
      false: "border",
      true: "",
    },
    color: {
      [CardColor.green]: "bg-green-50 border-green-500",
      [CardColor.primary]: "bg-primary-50 border-primary-500",
      [CardColor.red]: "bg-red-50 border-red-500",
      [CardColor.slate]: "bg-white border-slate-200",
      [CardColor.yellow]: "bg-yellow-50 border-yellow-500",
    },
    grow: {
      false: "grow-0",
      true: "grow",
    },
    padding: {
      [CardPadding.lg]: "p-6",
      [CardPadding.md]: "p-4",
      [CardPadding.none]: "p-0",
      [CardPadding.sm]: "p-2",
    },
    shrink: {
      false: "shrink-0",
      true: "shrink min-h-0 min-w-0",
    },
  },
});

const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
  function Card(props, ref) {
    const { children, labelledBy, ...other } = props;
    return (
      <RadixCard
        asChild
        ref={ref}
        className={CardVariantGenerator(other)}
        aria-labelledby={labelledBy}>
        <section>{children}</section>
      </RadixCard>
    );
  },
);

export {
  Card,
  CardColor,
  CardColorSchema,
  CardColorValues,
  CardPadding,
  CardPaddingSchema,
  CardPaddingValues,
};
export type { CardColors, CardPaddings, CardProps };
