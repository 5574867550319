import { addToExclusiveGroup } from "./add-to-exclusive-group";
import { bulkSkipAction } from "./bulk-skip-action";
import { createCollectionsProcess } from "./create-collections-process";
import { createStep } from "./create-step";
import { createStepForProcess } from "./create-step-for-process";
import { deleteCollectionsProcess } from "./delete-collections-process";
import { deleteStep } from "./delete-step";
import { getCollectionsProcesses } from "./get-collections-processes";
import { getCollectionsSummary } from "./get-collections-summary";
import { getLeaseActions } from "./get-lease-actions";
import { getLeaseSummary } from "./get-lease-summary";
import { getLeasesInCollections } from "./get-leases-in-collections";
import { getSteps } from "./get-steps";
import { removeFromExclusiveGroup } from "./remove-from-exclusive-group";
import { takeAction } from "./take-action";
import { updateProcessCriteria } from "./update-process-criteria";
import { updateStep } from "./update-step";

const CollectionsAPI = {
  addToExclusiveGroup,
  bulkSkipAction,
  createCollectionsProcess,
  createStep,
  createStepForProcess,
  deleteCollectionsProcess,
  deleteStep,
  getCollectionsProcesses,
  getCollectionsSummary,
  getLeaseActions,
  getLeaseSummary,
  getLeasesInCollections,
  getSteps,
  removeFromExclusiveGroup,
  takeAction,
  updateProcessCriteria,
  updateStep,
};

export { CollectionsAPI };
