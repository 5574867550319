/**
 * HTTP Method: POST
 * Pathname: /text-templates/{templateId}
 * @function updateTemplate
 * @memberof TemplatesAPI
 * @param {TemplatesAPIUpdateTemplatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TextTemplate>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TextTemplate } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TemplatesAPIUpdateTemplatePathParams = {
  templateId: EnderId;
};

type TemplatesAPIUpdateTemplateSearchParams = {
  token?: string | undefined;
};

type TemplatesAPIUpdateTemplateBodyParams = {
  body?: string | undefined;
  hasBackground?: boolean | undefined;
  hasFooter?: boolean | undefined;
  hasHeader?: boolean | undefined;
  name?: string | undefined;
  subject?: string | undefined;
};

type TemplatesAPIUpdateTemplatePayload = TemplatesAPIUpdateTemplatePathParams &
  TemplatesAPIUpdateTemplateSearchParams &
  TemplatesAPIUpdateTemplateBodyParams;

function updateTemplateUnsafeEffect(
  payload: TemplatesAPIUpdateTemplatePayload,
) {
  const { templateId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TextTemplate>({
        body,
        decode: unsafeDecodeJsonResponse<TextTemplate>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/text-templates/${templateId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateTemplate(
  payload: TemplatesAPIUpdateTemplatePayload,
  options?: { signal?: AbortSignal },
): Promise<TextTemplate> {
  return F.pipe(
    payload,
    updateTemplateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateTemplate };
export type {
  TemplatesAPIUpdateTemplateBodyParams,
  TemplatesAPIUpdateTemplatePathParams,
  TemplatesAPIUpdateTemplatePayload,
  TemplatesAPIUpdateTemplateSearchParams,
};
