import { Schema } from "@effect/schema";

import { EnderIdFormSchema } from "@ender/shared/core";

const ApplicationsAnnouncementFormSchema = Schema.Struct({
  body: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Body is required" }),
  ),
  subject: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Subject is required" }),
  ),
  templateId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
});

type ApplicationsAnnouncementFormInput = Schema.Schema.Encoded<
  typeof ApplicationsAnnouncementFormSchema
>;
type ApplicationsAnnouncementFormOutput = Schema.Schema.Type<
  typeof ApplicationsAnnouncementFormSchema
>;

export { ApplicationsAnnouncementFormSchema };
export type {
  ApplicationsAnnouncementFormInput,
  ApplicationsAnnouncementFormOutput,
};
