import { IconPlus, IconTrash } from "@tabler/icons-react";
import { Array as A, Option as O, Predicate as P } from "effect";
import { useCallback, useContext, useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { LocalDate } from "@ender/shared/core";
import { LocalDate$, Money$, Percent$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { DateInput } from "@ender/shared/ds/date-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { PercentDisplay } from "@ender/shared/ds/percent-display";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useDebounceState } from "@ender/shared/hooks/use-debounce";
import { Size } from "@ender/shared/utils/theming";

import type { RenewalOffer } from "./renewals.types";
import {
  getRenewalOfferMonths,
  renewalDurationOptions,
} from "./renewals.utils";

import styles from "./renewals.module.css";

const isMoneyEquivalent = O.getEquivalence<Money$.Money>(Money$.Equivalence);

type EditRenewalOfferProps = {
  leaseEndDate: LocalDate;
  offer: RenewalOffer;
  onNewEndDateUpdate?: (offer: RenewalOffer) => void;
  onNewRentUpdate?: (offer: RenewalOffer) => void;
  onProcessingFeeUpdate?: (offer: RenewalOffer) => void;
  onDelete?: (offer: RenewalOffer) => void;
  disabled: boolean;
};

function EditRenewalOffer(props: EditRenewalOfferProps) {
  const {
    leaseEndDate,
    offer,
    onDelete,
    onNewEndDateUpdate,
    onNewRentUpdate,
    onProcessingFeeUpdate,
    disabled,
  } = props;

  const updateOffer = useCallback(
    (fields: Partial<RenewalOffer>) => ({ ...offer, ...fields }),
    [offer],
  );

  const onEndDateChange = useCallback(
    (endDate: O.Option<LocalDate$.LocalDate>) => {
      O.match(endDate, {
        // need to unset the newEndDate, only possible with empty string because our API layer strips out nulls from our payloads
        onNone: () =>
          onNewEndDateUpdate?.(updateOffer({ newEndDate: "" as LocalDate })),
        onSome: (date) =>
          onNewEndDateUpdate?.(
            updateOffer({ newEndDate: date.add({ days: 1 }).toJSON() }),
          ),
      });
    },
    [onNewEndDateUpdate, updateOffer],
  );

  const onDurationChange = useCallback(
    (duration: O.Option<number>) => {
      const months = O.getOrThrow(duration);
      if (months === 0) {
        onEndDateChange(O.none());
      } else {
        onEndDateChange(
          O.some(LocalDate$.of(leaseEndDate).add({ days: -1, months })),
        );
      }
    },
    [leaseEndDate, onEndDateChange],
  );

  // Coerce empty string to undefined because of above comment
  const newEndDate = O.map(
    LocalDate$.parse(offer.newEndDate ? offer.newEndDate : UNDEFINED),
    (newEndDate) => newEndDate.add({ days: -1 }),
  );

  const offerDuration = O.getOrElse(
    O.map(newEndDate, (newEndDate) =>
      Math.max(getRenewalOfferMonths(leaseEndDate, newEndDate.toJSON()), 1),
    ),
    () => 0,
  );

  const newRentPercent = useMemo(
    () => O.map(O.fromNullable(offer.newRentPercentage), Percent$.of),
    [offer.newRentPercentage],
  );

  const [newRent, onNewRentChange] = useDebounceState<O.Option<Money$.Money>>({
    initialValue: Money$.parse(offer.newRent),
    onDebounceValueChange: (
      value: O.Option<Money$.Money>,
      previousValue: O.Option<Money$.Money>,
    ) => {
      if (!isMoneyEquivalent(value, previousValue)) {
        O.map(value, (newRent) =>
          onNewRentUpdate?.(updateOffer({ newRent: newRent.toJSON() })),
        );
      }
    },
  });

  const [processingFee, onProcessingFeeChange] = useDebounceState<
    O.Option<Money$.Money>
  >({
    initialValue: Money$.parse(offer.processingFee),
    onDebounceValueChange: (
      value: O.Option<Money$.Money>,
      previousValue: O.Option<Money$.Money>,
    ) => {
      if (!isMoneyEquivalent(value, previousValue)) {
        O.map(value, (processingFee) =>
          onProcessingFeeUpdate?.(
            updateOffer({ processingFee: processingFee.toJSON() }),
          ),
        );
      }
    },
  });

  return (
    <Card>
      <Stack>
        {P.isNotNullable(onDelete) && (
          <Group justify={Justify.end} align={Align.center}>
            <Button
              variant={ButtonVariant.transparent}
              leftSection={<IconTrash />}
              onClick={() => onDelete(offer)}
              disabled={disabled}>
              Delete
            </Button>
          </Group>
        )}
        <Group noWrap>
          <Select
            label="Duration"
            disabled={disabled || P.isNullable(onNewEndDateUpdate)}
            data={renewalDurationOptions}
            value={O.some(offerDuration)}
            onChange={onDurationChange}
          />
          <DateInput
            label="End Date"
            clearable
            disabled={disabled || P.isNullable(onNewEndDateUpdate)}
            value={newEndDate}
            onChange={onEndDateChange}
            minDate={LocalDate$.of(leaseEndDate)}
            maxDate={LocalDate$.of(leaseEndDate).add({ years: 2 })}
          />
        </Group>
        <div className={styles.rentAmount}>
          <MoneyInput
            label="Rent Amount"
            disabled={disabled || P.isNullable(onNewRentUpdate)}
            value={newRent}
            onChange={onNewRentChange}
          />
          <span>
            (
            <PercentDisplay
              value={newRentPercent}
              showSign
              complementary
              precision={0}
            />
            )
          </span>
        </div>
        <MoneyInput
          label="Processing Fee"
          disabled={disabled || P.isNullable(onProcessingFeeUpdate)}
          value={processingFee}
          onChange={onProcessingFeeChange}
        />
      </Stack>
    </Card>
  );
}

type EditRenewalOffersProps = {
  leaseEndDate: LocalDate;
  offers: RenewalOffer[];
  onCreate?: () => void;
  onNewEndDateUpdate?: (offer: RenewalOffer) => void;
  onNewRentUpdate?: (offer: RenewalOffer) => void;
  onProcessingFeeUpdate?: (offer: RenewalOffer) => void;
  onDelete?: (offer: RenewalOffer) => void;
  disabled?: boolean;
};

function EditRenewalOffers(props: EditRenewalOffersProps) {
  const {
    leaseEndDate,
    offers,
    onCreate,
    onNewEndDateUpdate,
    onNewRentUpdate,
    onProcessingFeeUpdate,
    onDelete,
    disabled,
  } = props;
  const { hasPermissions } = useContext(UserContext);
  const canEdit = hasPermissions(FunctionalPermissionEnum.SET_RENEWAL_RENT);

  return (
    <Stack>
      {A.isEmptyArray(offers) && <p>No Renewal Offers Created</p>}
      <Grid columnSize={Size.lg} spacingX={Spacing.md} spacingY={Spacing.md}>
        {offers.map((offer) => (
          <EditRenewalOffer
            key={offer.id}
            leaseEndDate={leaseEndDate}
            offer={offer}
            onNewEndDateUpdate={onNewEndDateUpdate}
            onNewRentUpdate={onNewRentUpdate}
            onProcessingFeeUpdate={onProcessingFeeUpdate}
            onDelete={onDelete}
            disabled={disabled || !canEdit}
          />
        ))}
      </Grid>
      {P.isNotNullable(onCreate) && (
        <Group>
          <Button
            variant={ButtonVariant.outlined}
            leftSection={<IconPlus />}
            onClick={onCreate}
            disabled={disabled || !canEdit}>
            Add Offer
          </Button>
        </Group>
      )}
    </Stack>
  );
}

export { EditRenewalOffers };
