/**
 * HTTP Method: PATCH
 * Pathname: /collections/steps/{stepId}
 * @function updateStep
 * @memberof CollectionsAPI
 * @param {CollectionsAPIUpdateStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsStep>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalTime,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { ChargeScheduleBasedThreshold } from "@ender/shared/generated/ender.api.finance.request";
import type { CollectionsStep } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIUpdateStepPathParams = {
  stepId: EnderId;
};

type CollectionsAPIUpdateStepSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIUpdateStepBodyParams = {
  actionTime?: SerializesInto<LocalTime> | undefined;
  automatedAfterNHours?: number | undefined;
  maxBalanceDue?: SerializesInto<Money> | undefined;
  maxChargeScheduleBasedThreshold?: ChargeScheduleBasedThreshold | undefined;
  minBalanceDue?: SerializesInto<Money> | undefined;
  minChargeScheduleBasedThreshold?: ChargeScheduleBasedThreshold | undefined;
  numDaysPastRentDue?: number | undefined;
  ordering?: number | undefined;
  taskTitle?: string | undefined;
  templateId?: EnderId | undefined;
};

type CollectionsAPIUpdateStepPayload = CollectionsAPIUpdateStepPathParams &
  CollectionsAPIUpdateStepSearchParams &
  CollectionsAPIUpdateStepBodyParams;

function updateStepUnsafeEffect(payload: CollectionsAPIUpdateStepPayload) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsStep>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsStep>({}),
        encode: encodeJsonBody({ method: "PATCH" }),
        pathname: `/collections/steps/${stepId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateStep(
  payload: CollectionsAPIUpdateStepPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsStep> {
  return F.pipe(
    payload,
    updateStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateStep };
export type {
  CollectionsAPIUpdateStepBodyParams,
  CollectionsAPIUpdateStepPathParams,
  CollectionsAPIUpdateStepPayload,
  CollectionsAPIUpdateStepSearchParams,
};
