import { Schema } from "@effect/schema";
import { Option as O, pipe } from "effect";
import * as S from "effect/String";
import { useWatch } from "react-hook-form";

import type { UseFormReturn } from "@ender/form-system/base";
import { PhoneEffectSchema } from "@ender/form-system/schema";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { FormSelect } from "@ender/shared/ds/select";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tuple } from "@ender/shared/ds/tuple";

const ApplicantRentalHistoryFormSchema = Schema.Struct({
  firstTimeRenter: Schema.Boolean.pipe(Schema.OptionFromSelf),
  landlordEmail: Schema.String,
  landlordName: Schema.String,
  landlordPhone: PhoneEffectSchema,
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];
    if (!pipe(values.firstTimeRenter, O.getOrThrow)) {
      if (S.isEmpty(values.landlordName)) {
        issues.push({
          message: "Landlord Name is required",
          path: ["landlordName"],
        });
      }
      if (S.isEmpty(values.landlordEmail)) {
        issues.push({
          message: "Landlord Email is required",
          path: ["landlordEmail"],
        });
      }
    }
    return issues;
  }),
);

type ApplicantRentalHistoryFormInput = Schema.Schema.Encoded<
  typeof ApplicantRentalHistoryFormSchema
>;
type ApplicantRentalHistoryFormOutput = Schema.Schema.Type<
  typeof ApplicantRentalHistoryFormSchema
>;

const yesNoOptions = [
  { label: "Yes", value: true, key: "yes" },
  { label: "No", value: false, key: "no" },
];

type EditableApplicantRentalHistoryProps = {
  form: UseFormReturn<ApplicantRentalHistoryFormInput>;
};

function EditableApplicantRentalHistory(
  props: EditableApplicantRentalHistoryProps,
) {
  const { form } = props;
  const firstTimeRenter = useWatch({
    control: form.control,
    name: "firstTimeRenter",
  });
  return (
    <>
      <Tuple
        label="First Time Renter"
        value={
          <FormSelect
            data={yesNoOptions}
            form={form}
            name="firstTimeRenter"
            size="sm"
          />
        }
      />
      {firstTimeRenter.pipe(O.getOrUndefined) || (
        <>
          <Tuple
            label="Previous Landlord"
            value={<FormTextInput form={form} name="landlordName" size="sm" />}
          />
          <Tuple
            label="Landlord Email"
            value={<FormTextInput form={form} name="landlordEmail" size="sm" />}
          />
          <Tuple
            label="Landlord Phone"
            value={<FormPhoneInput form={form} name="landlordPhone" />}
          />
        </>
      )}
    </>
  );
}

export { ApplicantRentalHistoryFormSchema, EditableApplicantRentalHistory };
export type {
  ApplicantRentalHistoryFormInput,
  ApplicantRentalHistoryFormOutput,
};
