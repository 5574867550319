import { IconDots } from "@tabler/icons-react";
import { Array as A, Predicate as P } from "effect";
import type { ReactNode } from "react";
import { useCallback } from "react";

import { NULL } from "@ender/shared/constants/general";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Group } from "@ender/shared/ds/group";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { EmptyObject } from "@ender/shared/types/general";
import { ScreenRangeEnum, ScreenSize } from "@ender/shared/ui/screen-size";

import type { EnderTable, EnderTableTopAction } from "./table.types";

import styles from "./table.module.css";

type MenuActionItemProps<RowData, Meta extends object = EmptyObject> = {
  id: string;
  table: EnderTable<RowData, Meta>;
} & Pick<
  EnderTableTopAction<RowData, Meta>,
  | "label"
  | "loading"
  | "disabled"
  | "tooltip"
  | "leftIcon"
  | "rightIcon"
  | "onAction"
>;

// NOTE: Internal Component, DO NOT EXPORT!!
function MenuActionItem<RowData, Meta extends object = EmptyObject>(
  props: MenuActionItemProps<RowData, Meta>,
): ReactNode {
  const {
    id,
    table,
    label,
    loading,
    disabled,
    tooltip,
    leftIcon = () => NULL,
    rightIcon = () => NULL,
    onAction,
  } = props;
  const icon: ReactNode = loading ? <LoadingSpinner /> : leftIcon();

  const onClick = useCallback(() => {
    if (disabled || loading) {
      return;
    }
    return onAction({ key: id, table });
  }, [id, table, disabled, loading, onAction]);

  return (
    <Tooltip label={tooltip} disabled={!disabled || P.isNullable(tooltip)}>
      <MenuButton disabled={disabled || loading} onClick={onClick}>
        <Group>
          {icon}
          {label}
          {rightIcon()}
        </Group>
      </MenuButton>
    </Tooltip>
  );
}

type MenuActionsProps<RowData, Meta extends object = EmptyObject> = {
  table: EnderTable<RowData, Meta>;
};

// NOTE: Internal Component, DO NOT EXPORT!!
function MenuActions<RowData, Meta extends object = EmptyObject>(
  props: MenuActionsProps<RowData, Meta>,
): ReactNode {
  const { table } = props;
  const actions = table.options.meta.actions;

  if (P.isNullable(actions) || A.isEmptyArray(actions)) {
    return NULL;
  }

  return (
    <Menu>
      <MenuTrigger>
        <ActionIcon>
          <IconDots />
        </ActionIcon>
      </MenuTrigger>
      <MenuContent>
        {actions.map(({ key, ...itemProps }) => {
          return (
            <MenuActionItem key={key} id={key} table={table} {...itemProps} />
          );
        })}
      </MenuContent>
    </Menu>
  );
}

type ButtonActionProps<RowData, Meta extends object = EmptyObject> = {
  id: string;
  table: EnderTable<RowData, Meta>;
} & Pick<
  EnderTableTopAction<RowData, Meta>,
  | "label"
  | "loading"
  | "disabled"
  | "variant"
  | "tooltip"
  | "leftIcon"
  | "rightIcon"
  | "onAction"
>;

// NOTE: Internal Component, DO NOT EXPORT!!
function ButtonAction<RowData, Meta extends object = EmptyObject>(
  props: ButtonActionProps<RowData, Meta>,
): ReactNode {
  const {
    id,
    table,
    label,
    loading,
    disabled,
    tooltip,
    variant = ButtonVariant.filled,
    leftIcon = () => NULL,
    rightIcon = () => NULL,
    onAction,
  } = props;

  const onClick = useCallback(() => {
    if (disabled || loading) {
      return;
    }
    return onAction({ key: id, table });
  }, [id, table, disabled, loading, onAction]);

  return (
    <Button
      tooltip={tooltip}
      variant={variant}
      leftSection={leftIcon()}
      rightSection={rightIcon()}
      loading={loading}
      disabled={disabled}
      onClick={onClick}>
      {label}
    </Button>
  );
}

type ButtonActionsProps<RowData, Meta extends object = EmptyObject> = {
  table: EnderTable<RowData, Meta>;
};

// NOTE: Internal Component, DO NOT EXPORT!!
function ButtonActions<RowData, Meta extends object = EmptyObject>(
  props: ButtonActionsProps<RowData, Meta>,
): ReactNode {
  const { table } = props;
  const actions = table.options.meta.actions;

  if (P.isNullable(actions) || A.isEmptyArray(actions)) {
    return NULL;
  }

  return (
    <div className={styles.actions}>
      <Group>
        {actions.map(({ key, ...itemProps }) => {
          return (
            <ButtonAction key={key} id={key} table={table} {...itemProps} />
          );
        })}
      </Group>
    </div>
  );
}

type TableTopActionsProps<RowData, Meta extends object = EmptyObject> = {
  table: EnderTable<RowData, Meta>;
};

function TableTopActions<RowData, Meta extends object = EmptyObject>(
  props: TableTopActionsProps<RowData, Meta>,
): ReactNode {
  const { table } = props;

  return (
    <ScreenSize>
      <ScreenSize.LessThan size={ScreenRangeEnum.MEDIUM}>
        <MenuActions table={table} />
      </ScreenSize.LessThan>
      <ScreenSize.GreaterThanEqual size={ScreenRangeEnum.MEDIUM}>
        <ButtonActions table={table} />
      </ScreenSize.GreaterThanEqual>
    </ScreenSize>
  );
}

export { TableTopActions };
export type { TableTopActionsProps };
