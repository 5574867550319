import { Option as O, Predicate as P } from "effect";
import type { ReactNode } from "react";
import { useId, useMemo } from "react";
import { useWatch } from "react-hook-form";

import type { FormSubSectionReference } from "@ender/form-system/base";
import { UNDEFINED } from "@ender/shared/constants/general";
import { Money$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import type { AllocationType } from "@ender/shared/types/ender-general";
import { AllocationTypeEnum } from "@ender/shared/types/ender-general";

import type {
  JournalEntryFormOutput,
  PropertyIdWithAllocationsInput,
} from "./journal-entry-right-rail.types";

type AllocationTotals = Record<AllocationType, Money$.Money>;

type AllocationsSummaryProps = {
  error: ReactNode | undefined;
} & FormSubSectionReference<
  JournalEntryFormOutput,
  PropertyIdWithAllocationsInput
>;

function AllocationsSummary(props: AllocationsSummaryProps) {
  const { form, name } = props;
  const summaryId = useId();
  const allocations = useWatch({
    control: form.control,
    name: `${name}.allocations`,
  });
  const allocationError =
    form.formState?.errors.allocationsWithProperties?.root?.message;
  const allocationTotals = useMemo(() => {
    const totals: AllocationTotals = {
      [AllocationTypeEnum.CREDIT]: Money$.zero(),
      [AllocationTypeEnum.DEBIT]: Money$.zero(),
    };

    allocations.forEach((allocation) => {
      if (P.isNotNullable(allocation.type)) {
        if (
          P.isNotNullable(allocation.credits) &&
          O.isSome(allocation.credits)
        ) {
          totals[AllocationTypeEnum.CREDIT] = Money$.add(
            totals[allocation.type],
            O.getOrThrow(allocation.credits),
          );
        }
        if (P.isNotNullable(allocation.debits) && O.isSome(allocation.debits)) {
          totals[AllocationTypeEnum.DEBIT] = Money$.add(
            totals[allocation.type],
            O.getOrThrow(allocation.debits),
          );
        }
      }
    });

    return {
      credit: totals[AllocationTypeEnum.CREDIT],
      debit: totals[AllocationTypeEnum.DEBIT],
      remaining: totals[AllocationTypeEnum.DEBIT].subtract(
        totals[AllocationTypeEnum.CREDIT],
      ),
    };
  }, [allocations]);
  return (
    <div role="region" aria-labelledby={summaryId}>
      <Stack spacing={Spacing.sm}>
        <H3 id={summaryId}>Property Totals</H3>
        {P.isNotNullable(allocationError) && (
          <Text color="red-500" size={FontSize.sm}>
            {allocationError}
          </Text>
        )}
        <div>
          <Tuple label="Debits" value={allocationTotals.debit.toFormatted()} />
          <Tuple
            label="Credits"
            value={allocationTotals.credit.toFormatted()}
          />
          <Tuple
            label="Difference"
            value={
              <Text
                color={
                  Money$.isZero(allocationTotals.remaining)
                    ? UNDEFINED
                    : "red-500"
                }
                size={FontSize.sm}
                weight={FontWeight.medium}>
                {allocationTotals.remaining.toFormatted()}
              </Text>
            }
          />
        </div>
      </Stack>
    </div>
  );
}

export { AllocationsSummary };
