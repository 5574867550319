import type { Row } from "@tanstack/react-table";

import { LocalDate$ } from "@ender/shared/core";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { RouterLink } from "@ender/shared/ds/router-link";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { asColumnDef } from "@ender/shared/ui/table-tanstack";
import { getLeaseDisplayStatus } from "@ender/shared/utils/lease";

import { getLeaseTenantsString } from "../../unit-page-utils";

const byLeaseDuration = (
  rowA: Row<LeaseSerializerLeaseResponse>,
  rowB: Row<LeaseSerializerLeaseResponse>,
) => {
  const a = rowA.original;
  const b = rowB.original;
  let comparison = LocalDate$.of(b.startDate).compareTo(
    LocalDate$.of(a.startDate),
  );
  if (comparison === 0) {
    comparison = LocalDate$.of(b.endDate).compareTo(LocalDate$.of(a.endDate));
  }
  return comparison;
};

type PreviousLeasesTableMeta = {
  invoker: User;
  unitType: string;
};

const columns: ColumnDef<LeaseSerializerLeaseResponse>[] = [
  asColumnDef<
    LeaseSerializerLeaseResponse,
    unknown,
    unknown,
    PreviousLeasesTableMeta
  >({
    accessorKey: "id",
    enableSorting: false,
    header: "Tenants",
    cell: (props) => (
      <RouterLink href={`/leases/${props.getValue()}`}>
        {getLeaseTenantsString(props.row.original, true)}
      </RouterLink>
    ),
  }),
  asColumnDef<
    LeaseSerializerLeaseResponse,
    unknown,
    unknown,
    PreviousLeasesTableMeta
  >({
    id: "leaseDuration",
    header: "Lease Duration",
    sortingFn: byLeaseDuration,
    cell: (props) => {
      const lease = props.row.original;
      const endDate =
        lease.inclusiveEndDate ??
        (lease.moveOutComplete && lease.expectedMoveOutDate);
      return (
        <>
          <DateDisplay value={LocalDate$.parse(lease.startDate)} /> {" - "}
          {endDate ? (
            <DateDisplay value={LocalDate$.parse(endDate)} />
          ) : (
            "No End Date"
          )}
        </>
      );
    },
  }),
  asColumnDef<
    LeaseSerializerLeaseResponse,
    unknown,
    unknown,
    PreviousLeasesTableMeta
  >({
    accessorKey: "status",
    enableSorting: false,
    header: "Status",
    cell: (props) => getLeaseDisplayStatus(props.row.original),
  }),
  asColumnDef<
    LeaseSerializerLeaseResponse,
    unknown,
    unknown,
    PreviousLeasesTableMeta
  >({
    accessorKey: "baseRent",
    enableSorting: false,
    header: "Base Rent",
  }),
  asColumnDef<
    LeaseSerializerLeaseResponse,
    unknown,
    unknown,
    PreviousLeasesTableMeta
  >({
    accessorKey: "currentBalance",
    enableSorting: false,
    header: "Balance",
  }),
];

export { columns };
export type { PreviousLeasesTableMeta };
