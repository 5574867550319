/**
 * HTTP Method: POST
 * Pathname: /checks/{checkBatchId}/print-batch
 * @function printCheckBatch
 * @memberof PaymentsAPI
 * @param {PaymentsAPIPrintCheckBatchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PrintCheckResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PrintCheckResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIPrintCheckBatchPathParams = {
  checkBatchId: EnderId;
};

type PaymentsAPIPrintCheckBatchSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIPrintCheckBatchPayload = PaymentsAPIPrintCheckBatchPathParams &
  PaymentsAPIPrintCheckBatchSearchParams;

function printCheckBatchUnsafeEffect(
  payload: PaymentsAPIPrintCheckBatchPayload,
) {
  const { checkBatchId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PrintCheckResponse>({
        body,
        decode: unsafeDecodeJsonResponse<PrintCheckResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/checks/${checkBatchId}/print-batch`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function printCheckBatch(
  payload: PaymentsAPIPrintCheckBatchPayload,
  options?: { signal?: AbortSignal },
): Promise<PrintCheckResponse> {
  return F.pipe(
    payload,
    printCheckBatchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { printCheckBatch };
export type {
  PaymentsAPIPrintCheckBatchPathParams,
  PaymentsAPIPrintCheckBatchPayload,
  PaymentsAPIPrintCheckBatchSearchParams,
};
