/**
 * Get a token for connecting a bank account with Plaid.
 
 Returns  <code> 
 {
   linkToken: "abc123xyz"
 }
  </code>
 * HTTP Method: POST
 * Pathname: /plaid/createLinkToken
 * @function createLinkToken
 * @memberof DwollaAPI
 * @param {DwollaAPICreateLinkTokenPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<DwollaAPICreateLinkTokenResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { DwollaAPICreateLinkTokenResponse } from "../dwolla-api-create-link-token-response";

type DwollaAPICreateLinkTokenSearchParams = {
  token?: string | undefined;
};

type DwollaAPICreateLinkTokenPayload = DwollaAPICreateLinkTokenSearchParams;

function createLinkTokenUnsafeEffect(payload: DwollaAPICreateLinkTokenPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, DwollaAPICreateLinkTokenResponse>({
        body,
        decode: unsafeDecodeJsonResponse<DwollaAPICreateLinkTokenResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/plaid/createLinkToken",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createLinkToken(
  payload: DwollaAPICreateLinkTokenPayload,
  options?: { signal?: AbortSignal },
): Promise<DwollaAPICreateLinkTokenResponse> {
  return F.pipe(
    payload,
    createLinkTokenUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createLinkToken };
export type {
  DwollaAPICreateLinkTokenPayload,
  DwollaAPICreateLinkTokenSearchParams,
};
