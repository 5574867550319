import { Option as O } from "effect";
import type { ReactNode } from "react";
import { createContext } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { cast } from "@ender/shared/types/cast";

import type { FieldValues, FormProviderChild, UseFormReturn } from "../types";

const FormContext = createContext<O.Option<UseFormReturn<FieldValues>>>(
  O.none(),
);

type FormProviderProps<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | Undefined = Undefined,
> = {
  children: FormProviderChild<TFieldValues, TContext, TTransformedValues>;
};

const FormProvider = <
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | Undefined = Undefined,
>(props: {
  children: ReactNode;
  form: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
}) => {
  const { children, form } = props;
  return (
    <FormContext.Provider value={O.fromNullable(cast(form))}>
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
export type { FormProviderProps };
