/**
 * HTTP Method: POST
 * Pathname: /chargeSchedules/{chargeScheduleId}
 * @function updateChargeSchedule
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPIUpdateChargeSchedulePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ChargeSchedulesAPIUpdateChargeScheduleResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { ChargeScheduleFrequency } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ChargeSchedulesAPIUpdateChargeScheduleResponse } from "../charge-schedules-api-update-charge-schedule-response";

type ChargeSchedulesAPIUpdateChargeSchedulePathParams = {
  chargeScheduleId: EnderId;
};

type ChargeSchedulesAPIUpdateChargeScheduleSearchParams = {
  token?: string | undefined;
};

type ChargeSchedulesAPIUpdateChargeScheduleBodyParams = {
  amount: SerializesInto<Money>;
  chargeForTerminatedLease?: boolean | undefined;
  frequency?: ChargeScheduleFrequency | undefined;
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  overrideWarnings?: boolean | undefined;
  startDate: SerializesInto<LocalDate>;
};

type ChargeSchedulesAPIUpdateChargeSchedulePayload =
  ChargeSchedulesAPIUpdateChargeSchedulePathParams &
    ChargeSchedulesAPIUpdateChargeScheduleSearchParams &
    ChargeSchedulesAPIUpdateChargeScheduleBodyParams;

function updateChargeScheduleUnsafeEffect(
  payload: ChargeSchedulesAPIUpdateChargeSchedulePayload,
) {
  const { chargeScheduleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ChargeSchedulesAPIUpdateChargeScheduleResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ChargeSchedulesAPIUpdateChargeScheduleResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/chargeSchedules/${chargeScheduleId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateChargeSchedule(
  payload: ChargeSchedulesAPIUpdateChargeSchedulePayload,
  options?: { signal?: AbortSignal },
): Promise<ChargeSchedulesAPIUpdateChargeScheduleResponse> {
  return F.pipe(
    payload,
    updateChargeScheduleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateChargeSchedule };
export type {
  ChargeSchedulesAPIUpdateChargeScheduleBodyParams,
  ChargeSchedulesAPIUpdateChargeSchedulePathParams,
  ChargeSchedulesAPIUpdateChargeSchedulePayload,
  ChargeSchedulesAPIUpdateChargeScheduleSearchParams,
};
