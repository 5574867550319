/**
 * This route only works for SQL system reports.
 * HTTP Method: POST
 * Pathname: /systemReports/{reportId}/csv.zip
 * @function exportSQLReportCSV
 * @memberof ReportsAPI
 * @param {ReportsAPIExportSQLReportCSVPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIExportSQLReportCSVPathParams = {
  reportId: string;
};

type ReportsAPIExportSQLReportCSVSearchParams = {
  token?: string | undefined;
};

type ReportsAPIExportSQLReportCSVBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  paramValues: any[][];
};

type ReportsAPIExportSQLReportCSVPayload =
  ReportsAPIExportSQLReportCSVPathParams &
    ReportsAPIExportSQLReportCSVSearchParams &
    ReportsAPIExportSQLReportCSVBodyParams;

function exportSQLReportCSVUnsafeEffect(
  payload: ReportsAPIExportSQLReportCSVPayload,
) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/systemReports/${reportId}/csv.zip`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function exportSQLReportCSV(
  payload: ReportsAPIExportSQLReportCSVPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    exportSQLReportCSVUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { exportSQLReportCSV };
export type {
  ReportsAPIExportSQLReportCSVBodyParams,
  ReportsAPIExportSQLReportCSVPathParams,
  ReportsAPIExportSQLReportCSVPayload,
  ReportsAPIExportSQLReportCSVSearchParams,
};
