import * as A from "effect/Array";

import type { ApplicationGroupApprovalResponseApprovalStatusResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

/**
 * Given a list of approvals, compute the overall approval status:
 *
 * - if any approval is rejected, the overall status is rejected
 * - if all approvals are approved, the overall status is approved
 * - otherwise, the overall status is new
 */
const computeBulkApprovalStatus = (
  approvals: ApplicationGroupApprovalResponseApprovalStatusResponse[],
): ApprovableApprovalStatus => {
  if (
    approvals.some(
      (approval) => approval.status === ApprovableApprovalStatusEnum.REJECTED,
    )
  ) {
    return ApprovableApprovalStatusEnum.REJECTED;
  } else if (
    A.isNonEmptyArray(approvals) &&
    approvals.every(
      (approval) => approval.status === ApprovableApprovalStatusEnum.APPROVED,
    )
  ) {
    return ApprovableApprovalStatusEnum.APPROVED;
  } else {
    return ApprovableApprovalStatusEnum.NEW;
  }
};

export { computeBulkApprovalStatus };
