import {
  add,
  differenceInCalendarDays,
  differenceInCalendarMonths,
} from "date-fns";
import {
  Array as A,
  Option as O,
  Order,
  Predicate as P,
  String as S,
  pipe,
} from "effect";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { LocalDate, Money } from "@ender/shared/core";
import {
  Instant$,
  LocalDate$,
  Money$,
  Percent$,
  randomEnderId,
} from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import type { RenewalPackageResponse } from "@ender/shared/generated/com.ender.middle.response";
import type {
  LeaseSerializerLeaseContact,
  LeaseSerializerLeaseResponse,
} from "@ender/shared/generated/ender.arch.serializer.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";

import type { RenewalOffer, RenewalPackageType } from "./renewals.types";

function getRenewalOfferMonths(
  newStartDate: LocalDate,
  newEndDate: LocalDate | undefined,
): number {
  if (P.isNullable(newEndDate)) {
    return 0;
  }

  const leaseStartDate = LocalDate$.of(newStartDate).toDate();
  const leaseEndDate = add(new Date(newEndDate), { days: 1 });
  const monthDifference = differenceInCalendarMonths(
    leaseEndDate,
    leaseStartDate,
  );
  const dayDifference = differenceInCalendarDays(
    leaseEndDate,
    add(leaseStartDate, { months: monthDifference }),
  );

  return (
    monthDifference +
    (dayDifference >= 15 ? 1 : 0) +
    (dayDifference <= -15 ? -1 : 0)
  );
}

function getOfferDurationFromMonths(months: number) {
  return months === 0 ? "Month-to-Month" : `${months} Month`;
}

function getOfferDurationFromDates(
  leaseEndDate: LocalDate,
  nextLeaseEndDate: LocalDate | undefined,
) {
  return getOfferDurationFromMonths(
    getRenewalOfferMonths(leaseEndDate, nextLeaseEndDate),
  );
}

function getRenewalOfferDurationOption(months: number): SelectOption<number> {
  return {
    label: getOfferDurationFromMonths(months),
    value: months,
  };
}

const renewalMonthOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
] as const;

const renewalDurationOptions = renewalMonthOptions.map((months) =>
  getRenewalOfferDurationOption(months),
);

/**
 * @deprecated we should not be converting to/from JSON within FE except at boundaries.
 */
function addMonths(
  date: LocalDate$.LocalDate,
  months: number,
): LocalDate$.LocalDate;
function addMonths(
  date: LocalDate$.Serialized,
  months: number,
): LocalDate$.Serialized;
function addMonths(
  date: LocalDate$.Serialized | LocalDate$.LocalDate,
  months: number,
): LocalDate$.Serialized | LocalDate$.LocalDate {
  if (P.isString(date)) {
    return LocalDate$.add(LocalDate$.of(date), { months }).toJSON();
  }
  return LocalDate$.add(date, { months });
}

function getLastElement<T>(arr: T[]): T | undefined {
  if (A.isEmptyArray(arr)) {
    return UNDEFINED;
  }

  return A.get(arr, -1).pipe(O.getOrUndefined);
}

function getLastOffer(
  renewalPackage: RenewalPackageType,
): RenewalOffer | undefined {
  return (
    getLastElement(renewalPackage.newRenewalOffers) ??
    getLastElement(renewalPackage.currentRenewalOffers) ??
    getLastElement(renewalPackage.previousRenewalOffers)
  );
}

function updateNewRentPercentage(
  offer: RenewalOffer,
  currentRent?: Money,
): RenewalOffer {
  if (P.isNullable(currentRent)) {
    return offer;
  }

  const currentRent$ = Money$.of(currentRent);
  const newRent = Money$.of(offer.newRent);

  const newRentPercentage$ = Percent$.divide(
    newRent.valueInCents,
    currentRent$.valueInCents,
  );
  return { ...offer, newRentPercentage: newRentPercentage$.toJSON() };
}

function getNextExpirationDate(leaseEndDate?: LocalDate) {
  const today = LocalDate$.today();
  const endOfNextMonth = pipe(
    today,
    (date) => LocalDate$.add(date, { months: 1 }),
    LocalDate$.endOfMonth,
  );
  if (P.isNullable(leaseEndDate)) {
    return endOfNextMonth;
  }

  const monthBeforeLeaseEnd = addMonths(LocalDate$.of(leaseEndDate), -1);
  if (Order.lessThan(LocalDate$.Order)(monthBeforeLeaseEnd, endOfNextMonth)) {
    return endOfNextMonth;
  }

  return monthBeforeLeaseEnd;
}

function getLatestOffers(
  renewalPackage: RenewalPackageResponse,
): RenewalOffer[] {
  const latestOffer = renewalPackage.previousRenewalOffers.reduce(
    (acc, offer) =>
      P.isNotNullable(offer.rescindedTimestamp) &&
      P.isNotNullable(acc.rescindedTimestamp) &&
      offer.rescindedTimestamp > acc.rescindedTimestamp
        ? offer
        : acc,
    renewalPackage.previousRenewalOffers[0],
  );
  const latestOffers = renewalPackage.previousRenewalOffers.filter(
    (offer) => offer.rescindedTimestamp === latestOffer.rescindedTimestamp,
  );

  return latestOffers.map((offer) => ({
    ...offer,
    expirationDate: getNextExpirationDate(renewalPackage.leaseEndDate).toJSON(),
  }));
}

function getAcceptedOfferDescription(renewalPackage: RenewalPackageType) {
  const acceptedOffer = renewalPackage.currentRenewalOffers.find((offer) =>
    P.isNotNullable(offer.acceptedTimestamp),
  );
  if (!acceptedOffer) {
    return NULL;
  }
  return `${getOfferDurationFromDates(acceptedOffer.newStartDate, acceptedOffer.newEndDate)} ${acceptedOffer.newRent}`;
}

function generateNewOffer(renewalPackage: RenewalPackageType): RenewalOffer {
  const lastOffer = getLastOffer(renewalPackage);
  const newRent =
    lastOffer?.newRent ?? renewalPackage.currentRent ?? Money$.zero().toJSON();
  const processingFee = lastOffer?.processingFee ?? Money$.zero().toJSON();
  const newEndDate =
    P.isNotNullable(lastOffer?.newEndDate) &&
    S.isNonEmpty(lastOffer.newEndDate ?? "")
      ? addMonths(lastOffer.newEndDate, 3)
      : addMonths(renewalPackage.leaseEndDate, 6);
  const expirationDate = getNextExpirationDate(
    renewalPackage.leaseEndDate,
  ).toJSON();

  return updateNewRentPercentage(
    {
      expirationDate,
      id: randomEnderId(),
      isOutstanding: true,
      newEndDate,
      newRent,
      newStartDate: renewalPackage.leaseEndDate,
      processingFee,
      timestamp: Instant$.now.toJSON(),
    },
    renewalPackage.currentRent,
  );
}

function isHousingChoiceVoucher(contacts: LeaseSerializerLeaseContact[]) {
  return contacts.some(({ roles }) =>
    roles.includes(LeaseUserRoleLeaseUserFlagEnum.HOUSING_CHOICE_VOUCHER),
  );
}

function getExpiringDate(lease?: LeaseSerializerLeaseResponse) {
  // HCV leases get 150 days, regular leases get 120 days
  const daysToAdd =
    lease?.contacts && isHousingChoiceVoucher(lease.contacts) ? 150 : 120;
  return LocalDate$.add(LocalDate$.today(), { days: daysToAdd });
}

function shouldShowRenewalsTab(lease: LeaseSerializerLeaseResponse) {
  // show for month to month or isDrafting
  if (P.isNullable(lease.endDate) || lease.hasDraftingRenewal) {
    return true;
  }

  // hide for renewed
  if (lease.hasRenewal && !lease.hasDraftingRenewal) {
    return false;
  }

  // hide for move out set
  if (P.isNotNullable(lease.expectedMoveOutDate)) {
    return false;
  }

  return Order.lessThan(LocalDate$.Order)(
    LocalDate$.of(lease.endDate),
    getExpiringDate(lease),
  );
}

export {
  generateNewOffer,
  getAcceptedOfferDescription,
  getLatestOffers,
  getOfferDurationFromDates,
  getRenewalOfferMonths,
  isHousingChoiceVoucher,
  renewalDurationOptions,
  shouldShowRenewalsTab,
  updateNewRentPercentage,
};
