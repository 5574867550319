import { Schema } from "@effect/schema";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Predicate as P, String as S } from "effect";
import type { BaseSyntheticEvent, MouseEvent } from "react";
import { useContext, useState } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useClipboard } from "@ender/shared/hooks/use-clipboard";

import styles from "./ssn-view.module.css";

type SsnViewProps = {
  targetUserId: EnderId;
};

const ViewReasonSchema = Schema.Struct({
  reason: Schema.NonEmptyString,
});
type ViewReasonFormOutput = Schema.Schema.Type<typeof ViewReasonSchema>;

type SsnViewReasonFormProps = {
  onSubmit: (values: ViewReasonFormOutput, e?: BaseSyntheticEvent) => void;
};

function SsnViewReasonForm(props: SsnViewReasonFormProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      reason: "",
    },
    schema: ViewReasonSchema,
  });
  return (
    <Form form={form} onSubmit={props.onSubmit}>
      <Stack>
        <FormTextInput form={form} name="reason" />
        <Button type="submit">View SSN</Button>
      </Stack>
    </Form>
  );
}

/**
 * attempts to format a given string to a hyphenated SSN: XXX-XX-XXXX.
 * If the input string does not conform to 9-digit numeric, it will be returned as-is.
 * This means values like "ENCRYPTED" or "REDACTED" will be piped through
 */
function formatSsn(ssn: string): string {
  return ssn.replace(/(\d{3})(\d{2})(\d{4})/g, "$1-$2-$3");
}

/**
 * Provides a way to request to view a user's SSN.
 * If the user is not masquerading, the SSN will be viewable upon providing a reason to view.
 */
function SsnView({ targetUserId }: SsnViewProps) {
  const { user, originalUser } = useContext(UserContext);
  const [
    isReasonModalOpen,
    { setTrue: openReasonModal, setFalse: closeReasonModal },
  ] = useBoolean(false);
  const [viewReason, setViewReason] = useState<string>("");

  const clipboard = useClipboard({ timeout: 1000 });

  const canFetchSSN =
    P.isNotNullable(originalUser) && originalUser.id === user.id;

  const { data: ssn, isFetched } = useQuery({
    enabled: S.isNonEmpty(viewReason),
    queryFn: () => {
      if (canFetchSSN && S.isNonEmpty(viewReason)) {
        return LeasingAPI.getSSN({
          reasonToView: viewReason,
          userId: targetUserId,
        });
      }

      return { ssn: "<REDACTED>" };
    },
    queryKey: [
      "LeasingAPI.getSSN",
      {
        reasonToView: viewReason,
        userId: targetUserId,
      },
    ],
    select: (res) => formatSsn(res.ssn),
  });

  function toggleView() {
    S.isEmpty(viewReason) ? openReasonModal() : setViewReason("");
  }

  function onCopyTextClick(e: MouseEvent<HTMLSpanElement>) {
    e.stopPropagation();
    clipboard.copy(ssn);
  }

  function handleSubmit(
    formValues: ViewReasonFormOutput,
    e?: BaseSyntheticEvent,
  ) {
    e?.stopPropagation();
    setViewReason(formValues.reason);
    closeReasonModal();
  }

  return (
    <>
      <Group spacing={Spacing.sm}>
        <Tooltip
          label={clipboard.copied ? "Copied!" : "Copy"}
          disabled={!isFetched}>
          <Text>
            <span className={styles.tabularNums} onClick={onCopyTextClick}>
              {isFetched ? ssn : "•••-••-••••"}
            </span>
          </Text>
        </Tooltip>
        {canFetchSSN && (
          <ActionIcon onClick={toggleView} label="Show/Hide SSN">
            {S.isEmpty(viewReason) ? <IconEye /> : <IconEyeOff />}
          </ActionIcon>
        )}
      </Group>
      <Modal
        size={ModalSize.sm}
        title="Enter reason for viewing SSN"
        opened={isReasonModalOpen}
        onClose={closeReasonModal}>
        <SsnViewReasonForm onSubmit={handleSubmit} />
      </Modal>
    </>
  );
}

export { SsnView };
export type { SsnViewProps };
