/**
 * HTTP Method: POST
 * Pathname: /invoices/steps/{stepId}/getInvoices
 * @function getInvoicesOnStep
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoicesOnStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInvoiceOnStepResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInvoiceOnStepResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoicesOnStepPathParams = {
  stepId: EnderId;
};

type InvoicesAPIGetInvoicesOnStepSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetInvoicesOnStepBodyParams = {
  invoiceType: InvoiceInvoiceType;
};

type InvoicesAPIGetInvoicesOnStepPayload =
  InvoicesAPIGetInvoicesOnStepPathParams &
    InvoicesAPIGetInvoicesOnStepSearchParams &
    InvoicesAPIGetInvoicesOnStepBodyParams;

function getInvoicesOnStepUnsafeEffect(
  payload: InvoicesAPIGetInvoicesOnStepPayload,
) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInvoiceOnStepResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInvoiceOnStepResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/invoices/steps/${stepId}/getInvoices`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoicesOnStep(
  payload: InvoicesAPIGetInvoicesOnStepPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInvoiceOnStepResponse> {
  return F.pipe(
    payload,
    getInvoicesOnStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoicesOnStep };
export type {
  InvoicesAPIGetInvoicesOnStepBodyParams,
  InvoicesAPIGetInvoicesOnStepPathParams,
  InvoicesAPIGetInvoicesOnStepPayload,
  InvoicesAPIGetInvoicesOnStepSearchParams,
};
