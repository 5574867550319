import { useMutation, useQuery } from "@tanstack/react-query";
import { Function as F, Option as O, pipe } from "effect";
import * as S from "effect/String";
import type { MouseEvent } from "react";
import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetTransactionsResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type {
  BankAccountAccountStatus,
  Party,
} from "@ender/shared/generated/ender.model.payments";
import { useFullBankAccountNumber } from "@ender/shared/hooks/use-full-bank-account-number";
import type { ObjectValues } from "@ender/shared/types/general";
import { withWarningHandler } from "@ender/shared/utils/rest";

import { BankAccountConfigureForm } from "./bank-account-configure-form";
import { BankAccountCard } from "./card/bank-account-card";
import { PlaidSyncButton } from "./plaid-sync-button";
import { VerifyAccountModal } from "./verify-account-modal";

const ModalContentTypeEnum = {
  CONFIGURE: "CONFIGURE",
  VERIFY: "VERIFY",
} as const;
type ModalContentType = ObjectValues<typeof ModalContentTypeEnum>;

type BankAccountContainerProps = {
  canDelete?: boolean;
  // Used to hide delete button on the bank account card
  disableDelete?: boolean;
  canViewBankAccountSensitiveFields?: boolean;
  cashCategoryId?: EnderId;
  deleteTooltip?: string;
  dwollaStatus: BankAccountAccountStatus;
  endDate?: LocalDate;
  fetchBankAccounts: () => void;
  hasBankAccountRevealOption?: boolean;
  hasPlaid?: boolean;
  hideBalances?: boolean;
  id: EnderId;
  institution?: string;
  isLink?: boolean;
  lobStatus?: string;
  mask: string;
  name: string;
  needsDwollaVerification?: boolean;
  needsLobVerification?: boolean;
  needsPlaidCredentialsUpdate?: boolean;
  onAccountRemoved?: () => void;
  onClick?: () => void;
  onPlaidUpdateClick?: (accountId: EnderId) => void;
  ownerId: EnderId;
  ownerType: Party;
  routingNumber: string;
  startDate?: LocalDate;
  propertyFilterValue: EnderId[];
};

function BankAccountContainer({
  canDelete = true,
  disableDelete = false,
  canViewBankAccountSensitiveFields,
  cashCategoryId,
  deleteTooltip,
  dwollaStatus,
  endDate,
  fetchBankAccounts,
  hasBankAccountRevealOption,
  hasPlaid = false,
  hideBalances,
  id,
  institution,
  isLink,
  lobStatus,
  mask,
  name,
  needsPlaidCredentialsUpdate,
  onAccountRemoved = F.constVoid,
  onClick = F.constVoid,
  onPlaidUpdateClick = F.constVoid,
  ownerId,
  ownerType,
  routingNumber,
  startDate,
  needsDwollaVerification,
  needsLobVerification,
  propertyFilterValue,
}: BankAccountContainerProps) {
  const history = useHistory();
  const { user, originalUser } = useContext(UserContext);
  const [needsVerification, setNeedsVerification] = useState(
    needsDwollaVerification || needsLobVerification || false,
  );

  const isMasquerading =
    originalUser && !originalUser.isEnder && user.id !== originalUser?.id;
  const bankReconciliationAccountUrl = `/accounting/banking/accounts/${id}`;
  const bankAccountNumber = useFullBankAccountNumber({
    id,
    mask: mask,
    ownerType: ownerType,
  });

  const [modalType, setModalType] = useState<O.Option<ModalContentType>>(
    O.none(),
  );

  /**
   * @description
   * Used to determine if the bank account card has any transactions associated whatsoever.
   * The date range should not be based upon input values; It needs to span the full life of the account
   * todo: Find a better way to accomplish this.  This feels very wrong.
   */
  const { data: hasTransactions } = useQuery<
    GetTransactionsResponse,
    unknown,
    boolean
  >({
    queryKey: ["BankingAPI.getTransactions", id] as const,
    queryFn: ({ signal }) =>
      BankingAPI.getTransactions(
        {
          bankAccountId: id,
          inclusiveEndDate: LocalDate$.today().toJSON(),
          propertyIds: [],
          startDate: LocalDate$.of("2020-01-01").toJSON(),
          includeRows: false,
        },
        { signal },
      ),
    enabled:
      (canDelete &&
        !disableDelete &&
        ModelTypeEnum.PROPERTY_MANAGER === ownerType) ||
      ModelTypeEnum.FIRM === ownerType,
    select: ({ cannotModifyBankAccountReason }): boolean =>
      S.isNonEmpty(cannotModifyBankAccountReason),
    staleTime: 900000,
  });

  const isDeleteButtonEnabled = useMemo(
    () => canDelete && !disableDelete && !hasTransactions,
    [canDelete, disableDelete, hasTransactions],
  );
  const deleteButtonTooltip = useMemo(() => {
    if (deleteTooltip) {
      return deleteTooltip;
    }

    if (hasTransactions) {
      return "You cannot delete a bank account that has accounting data associated with it. Please reach out to Ender OPS for any additional support.";
    }
  }, [deleteTooltip, hasTransactions]);

  const closeModal = () => {
    setModalType(O.none());
  };
  const confirmation = useConfirmationContext();

  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };

  const { mutateAsync: deleteBankAccount } = useMutation({
    mutationFn: withWarningHandler(BankingAPI.deleteBankAccount, onWarnings),
  });

  async function removeAccount() {
    await confirmation({
      confirmButtonLabel: "Remove Account",
      title: `Remove account ending in ${mask}?`,
    });
    await deleteBankAccount({ bankAccountId: id });
    closeModal();
    onAccountRemoved();
  }

  const modalTitles = {
    [ModalContentTypeEnum.CONFIGURE]: "Configure Bank Account",
    [ModalContentTypeEnum.VERIFY]: "Verify Bank Account",
  };

  const modalContent = {
    [ModalContentTypeEnum.CONFIGURE]: (
      <BankAccountConfigureForm
        bankAccountId={id}
        canViewBankAccountSensitiveFields={canViewBankAccountSensitiveFields}
        closeModal={closeModal}
        dwollaStatus={dwollaStatus}
        hasBankAccountRevealOption={hasBankAccountRevealOption}
        hasPlaid={hasPlaid}
        initialCashCategoryId={cashCategoryId as EnderId}
        initialName={name}
        lobStatus={lobStatus}
        mask={mask}
        onSuccess={fetchBankAccounts}
        ownerId={ownerId}
        ownerType={ownerType}
        routingNumber={routingNumber}
      />
    ),
    [ModalContentTypeEnum.VERIFY]: (
      <VerifyAccountModal
        accountId={id}
        closeModal={closeModal}
        mask={mask}
        onSuccess={() => {
          fetchBankAccounts();
          setNeedsVerification(false);
        }}
      />
    ),
  };

  const linkHandler = () => {
    onClick();
    isLink && history.push(bankReconciliationAccountUrl);
  };

  // e.stopPropagation() is needed to work properly on the property page
  function safeOpenModal(modalType: ModalContentType) {
    return (e: MouseEvent) => {
      e.stopPropagation();
      setModalType(O.some(modalType));
    };
  }

  const openEditModal = safeOpenModal(ModalContentTypeEnum.CONFIGURE);
  const openDeleteModel = async (e: MouseEvent) => {
    e.stopPropagation();
    await removeAccount();
  };
  const openVerifyModal = safeOpenModal(ModalContentTypeEnum.VERIFY);

  return (
    <>
      <BankAccountCard
        bankAccountNumber={bankAccountNumber}
        deleteButtonTooltip={deleteButtonTooltip}
        disableDelete={disableDelete}
        endDate={endDate}
        hideBalances={hideBalances}
        id={id}
        institution={institution}
        isDeleteButtonEnabled={isDeleteButtonEnabled}
        isLink={isLink}
        isMasquerading={isMasquerading ?? false}
        linkHandler={linkHandler}
        name={name}
        needsVerification={needsVerification}
        openDeleteModel={openDeleteModel}
        openEditModal={openEditModal}
        openVerifyModal={openVerifyModal}
        ownerType={ownerType}
        startDate={startDate}
        propertyFilterValue={propertyFilterValue}
      />

      {needsPlaidCredentialsUpdate && (
        <PlaidSyncButton onClick={() => onPlaidUpdateClick(id)} />
      )}

      <Modal
        size={ModalSize.sm}
        title={pipe(
          modalType,
          O.map((v) => modalTitles[v]),
          O.getOrElse(() => ""),
        )}
        opened={O.isSome(modalType)}
        onClose={closeModal}>
        {pipe(
          modalType,
          O.map((v) => modalContent[v]),
          O.getOrNull,
        )}
      </Modal>
    </>
  );
}

export { BankAccountContainer };
