import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Option as O, Predicate as P } from "effect";
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useMemo } from "react";

import { useAuthActor } from "@ender/features/auth";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import type { GetUserResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  AuthAPI,
  EnderAPI,
  PermissionsAPI,
} from "@ender/shared/generated/ender.api.misc";
import type {
  EnderSessionResponseVendorResponse,
  GetPMResponse,
} from "@ender/shared/generated/ender.api.misc.response";
import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import { EnderError, fail } from "@ender/shared/utils/error";
import { navigateToApp } from "@ender/shared/utils/navigation";
import { noOpAsync } from "@ender/shared/utils/no-op";

const FIFTEEN_MINUTES_MS = 900000;

type UserVendor = EnderSessionResponseVendorResponse;

type UserContextState = {
  activeVendorId?: EnderId; // If the user is a vendor employee these indicate the active vendor and all vendors they work for
  originalUser?: GetUserResponse;
  permissions: Record<FunctionalPermission, boolean>;
  user: GetUserResponse;
  userPM: GetPMResponse;
  userVendors: UserVendor[];
};

type UserContextValue = UserContextState & {
  getMissingPermissions: (
    requestedPermissions: FunctionalPermission[],
  ) => Set<FunctionalPermission>;
  hasPermissions: (
    requestedPermissions?: FunctionalPermission | FunctionalPermission[],
  ) => boolean;
  refetchPermissions: () => Promise<void>;
  refetchUser: () => Promise<void>;
  refetchUserPM: () => Promise<void>;
  refetchUserVendors: () => Promise<void>;
};

const initialContextState: UserContextState = {
  // @ts-expect-error '{}' is missing 85 properties
  permissions: {},
  // @ts-expect-error Type '{}' is not assignable to type 'GetUserResponse'.
  user: {},
  // @ts-expect-error Type '{}' is not assignable to type 'GetPMResponse'.
  userPM: {},
  userVendors: [],
} as const;

const initialContextValue: UserContextValue = {
  ...initialContextState,
  getMissingPermissions: () => new Set<FunctionalPermission>(),
  hasPermissions: () => false,
  refetchPermissions: noOpAsync,
  refetchUser: noOpAsync,
  refetchUserPM: noOpAsync,
  refetchUserVendors: noOpAsync,
} as const;

const UserContext = createContext<UserContextValue>(initialContextValue);

function UserProvider(props: PropsWithChildren) {
  const [authSnapshot] = useAuthActor();
  const {
    context: { session },
  } = authSnapshot;

  const { userId, originalUserId, activeVendorId, userVendors } =
    useMemo(() => {
      return {
        activeVendorId: O.match(session, {
          onNone: () => UNDEFINED,
          onSome: (s) => s.activeVendor?.id,
        }),
        originalUserId: O.match(session, {
          onNone: () => UNDEFINED,
          onSome: (s) => s.originalUserId,
        }),
        userId: O.match(session, {
          onNone: () => UNDEFINED,
          onSome: (s) => s.user.id,
        }),
        userVendors: O.match(session, {
          onNone: () => UNDEFINED,
          onSome: (s) => s.vendors,
        }),
      };
    }, [session]);

  const { mutateAsync: logoutAsync } = useMutation({
    mutationFn: AuthAPI.logout,
  });

  const logout = useCallback(() => {
    logoutAsync({})
      .then(() => {
        // Logout changes the session so refresh the page.
        return navigateToApp();
      })
      .catch(fail);
  }, [logoutAsync]);

  /**
   * stores the permissions for the current user as an object
   * We can reduce refetching if needed by telling the useQuery when to refetch.
   */
  const {
    data: permissions = {} as Record<FunctionalPermission, boolean>,
    refetch: refetchPermissions,
  } = useQuery({
    onError: fail,
    queryFn: (): ReturnType<typeof PermissionsAPI.getPermissions> =>
      PermissionsAPI.getPermissions({}),
    queryKey: ["getPermissions"],
    /**
     * the purpose of select is to transform the data into an object of booleans rather than an array of strings.
     */
    select(data) {
      return data.reduce(
        (acc, permission) => {
          acc[permission] = true;
          return acc;
        },
        {} as Record<FunctionalPermission, boolean>,
      );
    },
    enabled: P.isNotNullable(userId), //prevents this query from running if there is no userId
    staleTime: FIFTEEN_MINUTES_MS,
  });

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useQuery({
    enabled: P.isNotNullable(userId),
    keepPreviousData: true,
    onError: (err: unknown) => {
      if (
        err instanceof EnderError &&
        err.response instanceof Response &&
        err.response.status === 401 // Unauthorized
      ) {
        // We should clear the user's session when BE indicates the session/token is invalid
        logout();
      }
      fail(err);
    },
    queryFn: async ({ queryKey: [, targetId, vendorId], signal }) => {
      if (P.isNullable(targetId)) {
        return NULL;
      }
      return await UsersAPI.getUser({ targetId, vendorId }, { signal });
    },
    queryKey: ["getUserById", userId, activeVendorId] as const,
    staleTime: FIFTEEN_MINUTES_MS,
  });

  const {
    data: userPM,
    error: userPMError,
    isLoading: isUserPMLoading,
    refetch: refetchUserPM,
  } = useQuery({
    keepPreviousData: true,
    onError: fail,
    queryFn: async ({ signal }) => {
      return await EnderAPI.getMyPM({}, { signal });
    },
    queryKey: ["EnderAPI.getMyPM"] as const,
    staleTime: FIFTEEN_MINUTES_MS,
  });

  const { data: originalUser } = useQuery({
    enabled: P.isNotNullable(originalUserId) && userId !== originalUserId,
    queryFn: async ({ queryKey: [, targetId, vendorId], signal }) => {
      if (P.isNullable(targetId)) {
        return UNDEFINED;
      }
      return await UsersAPI.getUser({ targetId, vendorId }, { signal });
    },
    queryKey: [
      "getUserById",
      originalUserId,
      userId === originalUserId ? activeVendorId : UNDEFINED,
    ] as const,
    staleTime: FIFTEEN_MINUTES_MS,
  });

  const { data: _userVendors = [], refetch: refetchUserVendors } = useQuery({
    enabled: P.isNotNullable(activeVendorId),
    queryFn: () => UsersAPI.getCurrentUserVendors({}),
    queryKey: ["getCurrentUserVendors"] as const,
    staleTime: FIFTEEN_MINUTES_MS,
  });

  /**
   * Get a set of permissions the user is missing
   */
  const getMissingPermissions = useCallback(
    (
      requestedPermissions: FunctionalPermission[],
    ): Set<FunctionalPermission> => {
      return new Set(
        requestedPermissions.filter((permission) => !permissions[permission]),
      );
    },
    [permissions],
  );

  /**
   * a memoized function that checks if the user has the requested permissions
   */
  const hasPermissions = useCallback(
    (
      requestedPermissions: FunctionalPermission | FunctionalPermission[] = [],
    ): boolean => {
      const missing = getMissingPermissions(
        A.isArray(requestedPermissions)
          ? requestedPermissions
          : [requestedPermissions],
      );
      return missing.size < 1;
    },
    [getMissingPermissions],
  );

  const _refetchUser = useCallback(async () => {
    // Doing this because return type of useQuery's refetch is incompatible with () => Promise<void>
    await refetchUser();
  }, [refetchUser]);

  const _refetchUserPM = useCallback(async () => {
    // Doing this because return type of useQuery's refetch is incompatible with () => Promise<void>
    await refetchUserPM();
  }, [refetchUserPM]);

  const _refetchUserVendors = useCallback(async () => {
    // Doing this because return type of useQuery's refetch is incompatible with () => Promise<void>
    await refetchUserVendors();
  }, [refetchUserVendors]);

  const _refetchPermissions = useCallback(async () => {
    // Doing this because return type of useQuery's refetch is incompatible with () => Promise<void>
    await refetchPermissions();
  }, [refetchPermissions]);

  if (isUserLoading || isUserPMLoading) {
    return <LoadingSpinner />;
  }

  if (userError) {
    return `Error Loading User: ${userError}`;
  }

  if (userPMError) {
    return `Error Loading PM: ${userPMError}`;
  }

  if (P.isNullable(user) || P.isNullable(userPM)) {
    return "Error: Unexpected State expecting a valid User & PM!";
  }

  const contextValue: UserContextValue = {
    activeVendorId,
    getMissingPermissions,
    hasPermissions,
    originalUser: originalUser,
    permissions,
    refetchPermissions: _refetchPermissions,
    refetchUser: _refetchUser,
    refetchUserPM: _refetchUserPM,
    refetchUserVendors: _refetchUserVendors,
    user: user,
    userPM,
    userVendors: userVendors || _userVendors,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
}

export {
  initialContextValue as initialUserContextValue,
  UserContext,
  UserProvider,
};
export type { UserContextValue, UserVendor };
