/**
 * HTTP Method: POST
 * Pathname: /updateRecurringGLJournalEntry
 * @function updateRecurringGLJournalEntry
 * @memberof AccountingAPI
 * @param {AccountingAPIUpdateRecurringGLJournalEntryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { CreateRecurringGLJournalEntryRequestTX } from "@ender/shared/generated/ender.api.accounting.request";
import type { RecurringGLJournalEntryFrequency } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIUpdateRecurringGLJournalEntrySearchParams = {
  token?: string | undefined;
};

type AccountingAPIUpdateRecurringGLJournalEntryBodyParams = {
  approverId?: EnderId | undefined;
  endDate?: SerializesInto<LocalDate> | undefined;
  frequency: RecurringGLJournalEntryFrequency;
  id: string;
  startDate: SerializesInto<LocalDate>;
  title: string;
  txs: CreateRecurringGLJournalEntryRequestTX[];
};

type AccountingAPIUpdateRecurringGLJournalEntryPayload =
  AccountingAPIUpdateRecurringGLJournalEntrySearchParams &
    AccountingAPIUpdateRecurringGLJournalEntryBodyParams;

function updateRecurringGLJournalEntryUnsafeEffect(
  payload: AccountingAPIUpdateRecurringGLJournalEntryPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/updateRecurringGLJournalEntry",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateRecurringGLJournalEntry(
  payload: AccountingAPIUpdateRecurringGLJournalEntryPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateRecurringGLJournalEntryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateRecurringGLJournalEntry };
export type {
  AccountingAPIUpdateRecurringGLJournalEntryBodyParams,
  AccountingAPIUpdateRecurringGLJournalEntryPayload,
  AccountingAPIUpdateRecurringGLJournalEntrySearchParams,
};
