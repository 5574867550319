import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const FlooringFlooringMaterialValues = [
  "WOOD_LVP",
  "TILE",
  "CARPET",
  "POLISHED_CONCRETE",
  "OTHER",
] as const;

const FlooringFlooringMaterialEffectSchema = Schema.Literal(
  ...FlooringFlooringMaterialValues,
);

type FlooringFlooringMaterial = Schema.Schema.Type<
  typeof FlooringFlooringMaterialEffectSchema
>;

const FlooringFlooringMaterialEnum = castEnum(
  FlooringFlooringMaterialEffectSchema,
);

function randomFlooringFlooringMaterial(): FlooringFlooringMaterial {
  return rand(FlooringFlooringMaterialValues);
}

export {
  FlooringFlooringMaterialEffectSchema,
  FlooringFlooringMaterialEnum,
  FlooringFlooringMaterialValues,
  randomFlooringFlooringMaterial,
};
export type { FlooringFlooringMaterial };
