import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import * as A from "effect/Array";

import type { EnderId } from "@ender/shared/core";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { SearchBankAccountResponse } from "@ender/shared/generated/ender.model.accounting.response";

type UseFetchBankAccountsProps = {
  enabled?: boolean;
  firmId?: EnderId;
  propertyIds?: EnderId[];
};

const useFetchBankAccounts = ({
  enabled = true,
  firmId,
  propertyIds = [],
}: UseFetchBankAccountsProps) => {
  return useQuery<SearchBankAccountResponse[]>({
    enabled,
    queryKey: [
      "BankingAPI.searchBankAccountsByFirm",
      firmId,
      propertyIds,
    ] as const,
    queryFn: async ({ signal }) => {
      if (A.isEmptyArray(propertyIds) || P.isNullable(firmId)) {
        return [];
      }

      return A.isNonEmptyArray(propertyIds)
        ? await BankingAPI.searchBankAccountsByFirm(
            {
              filters: [],
              firmIds: P.isNotNullable(firmId) ? [firmId] : [],
              fundIds: [],
              propertyIds: propertyIds,
            },
            { signal },
          )
        : [];
    },
  });
};

export { useFetchBankAccounts };
