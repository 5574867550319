/**
 * HTTP Method: GET
 * Pathname: /middleLayer/leasing/{leaseId}/upcomingCharges
 * @function getUpcomingCharges
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIGetUpcomingChargesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetUpcomingChargesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetUpcomingChargesResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIGetUpcomingChargesPathParams = {
  leaseId: EnderId;
};

type LeasingMiddleLayerAPIGetUpcomingChargesSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIGetUpcomingChargesPayload =
  LeasingMiddleLayerAPIGetUpcomingChargesPathParams &
    LeasingMiddleLayerAPIGetUpcomingChargesSearchParams;

function getUpcomingChargesUnsafeEffect(
  payload: LeasingMiddleLayerAPIGetUpcomingChargesPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetUpcomingChargesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetUpcomingChargesResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/middleLayer/leasing/${leaseId}/upcomingCharges`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUpcomingCharges(
  payload: LeasingMiddleLayerAPIGetUpcomingChargesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetUpcomingChargesResponse> {
  return F.pipe(
    payload,
    getUpcomingChargesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUpcomingCharges };
export type {
  LeasingMiddleLayerAPIGetUpcomingChargesPathParams,
  LeasingMiddleLayerAPIGetUpcomingChargesPayload,
  LeasingMiddleLayerAPIGetUpcomingChargesSearchParams,
};
