/**
 * HTTP Method: POST
 * Pathname: /reports/filterProperties
 * @function filterProperties
 * @memberof ReportsAPI
 * @param {ReportsAPIFilterPropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportsAPIFilterPropertiesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ReportsAPIFilterPropertiesResponse } from "../reports-api-filter-properties-response";

type ReportsAPIFilterPropertiesSearchParams = {
  token?: string | undefined;
};

type ReportsAPIFilterPropertiesBodyParams = {
  filters: ReportFilterRequest[];
};

type ReportsAPIFilterPropertiesPayload =
  ReportsAPIFilterPropertiesSearchParams & ReportsAPIFilterPropertiesBodyParams;

function filterPropertiesUnsafeEffect(
  payload: ReportsAPIFilterPropertiesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportsAPIFilterPropertiesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ReportsAPIFilterPropertiesResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/reports/filterProperties",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function filterProperties(
  payload: ReportsAPIFilterPropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIFilterPropertiesResponse> {
  return F.pipe(
    payload,
    filterPropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { filterProperties };
export type {
  ReportsAPIFilterPropertiesBodyParams,
  ReportsAPIFilterPropertiesPayload,
  ReportsAPIFilterPropertiesSearchParams,
};
