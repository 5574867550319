/**
 * HTTP Method: DELETE
 * Pathname: /accounting/glCategoryGrouping/{glCategoryGroupingId}
 * @function deleteGLCategoryGrouping
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIDeleteGLCategoryGroupingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIDeleteGLCategoryGroupingPathParams = {
  glCategoryGroupingId: EnderId;
};

type GeneralLedgerAPIDeleteGLCategoryGroupingSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIDeleteGLCategoryGroupingPayload =
  GeneralLedgerAPIDeleteGLCategoryGroupingPathParams &
    GeneralLedgerAPIDeleteGLCategoryGroupingSearchParams;

function deleteGLCategoryGroupingUnsafeEffect(
  payload: GeneralLedgerAPIDeleteGLCategoryGroupingPayload,
) {
  const { glCategoryGroupingId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/accounting/glCategoryGrouping/${glCategoryGroupingId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteGLCategoryGrouping(
  payload: GeneralLedgerAPIDeleteGLCategoryGroupingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteGLCategoryGroupingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteGLCategoryGrouping };
export type {
  GeneralLedgerAPIDeleteGLCategoryGroupingPathParams,
  GeneralLedgerAPIDeleteGLCategoryGroupingPayload,
  GeneralLedgerAPIDeleteGLCategoryGroupingSearchParams,
};
