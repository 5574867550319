import { Option as O, pipe } from "effect";
import { useWatch } from "react-hook-form";

import { applicantTypeDisplayMap } from "@ender/entities/utils/application-utils";
import type { UseFormReturn } from "@ender/form-system/base";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Tuple } from "@ender/shared/ds/tuple";
import type { ApplicationApplicantType } from "@ender/shared/generated/ender.model.leasing";

import type {
  ApplicantInfoFormInput,
  ApplicantInfoFormOutput,
} from "./edit-applicant-info-fields";

type DisplayableApplicantInfoProps = {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<ApplicantInfoFormInput, any, ApplicantInfoFormOutput>;
  applicantType: O.Option<ApplicationApplicantType>;
  isMinor: boolean;
};

function DisplayableApplicantInfo(props: DisplayableApplicantInfoProps) {
  const { form, isMinor, applicantType } = props;
  const [firstName, lastName, birthday, phone, email] = useWatch({
    control: form.control,
    exact: true,
    name: ["firstName", "lastName", "birthday", "phone", "email"],
  });

  return (
    <>
      <Tuple label="First Name" value={firstName} />
      <Tuple label="Last Name" value={lastName} />
      <Tuple
        label="Role"
        value={pipe(
          applicantType,
          O.map((val) => applicantTypeDisplayMap[val]),
          O.getOrElse(() => "--"),
        )}
      />
      <Tuple label="Date of Birth" value={<DateDisplay value={birthday} />} />
      {!isMinor && <Tuple label="Phone" value={phone} />}
      {!isMinor && <Tuple label="Email" value={email} />}
    </>
  );
}

export { DisplayableApplicantInfo };
