/**
 * HTTP Method: GET
 * Pathname: /pm/{pmId}/ledger
 * @function getPMLedger
 * @memberof AccountingAPI
 * @param {AccountingAPIGetPMLedgerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingAPIGetPMLedgerResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AccountingAPIGetPMLedgerResponse } from "../accounting-api-get-p-m-ledger-response";

type AccountingAPIGetPMLedgerPathParams = {
  pmId: EnderId;
};

type AccountingAPIGetPMLedgerSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetPMLedgerPayload = AccountingAPIGetPMLedgerPathParams &
  AccountingAPIGetPMLedgerSearchParams;

function getPMLedgerUnsafeEffect(payload: AccountingAPIGetPMLedgerPayload) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingAPIGetPMLedgerResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AccountingAPIGetPMLedgerResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/pm/${pmId}/ledger`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPMLedger(
  payload: AccountingAPIGetPMLedgerPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingAPIGetPMLedgerResponse> {
  return F.pipe(
    payload,
    getPMLedgerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPMLedger };
export type {
  AccountingAPIGetPMLedgerPathParams,
  AccountingAPIGetPMLedgerPayload,
  AccountingAPIGetPMLedgerSearchParams,
};
