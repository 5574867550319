import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import type { LeasingAPIUpdateLeasePayload } from "@ender/shared/generated/ender.api.leasing";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { LeaseIntentionValues } from "@ender/shared/generated/ender.model.leasing";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { makeUseMutation } from "@ender/shared/utils/rest";
import { CurrencySchema } from "@ender/shared/utils/zod";

const UpdateLeasePayloadSchema = z.object({
  actualMoveOutDate: z.string().optional(),
  allowOnlinePayments: z.boolean().optional(),
  applicationGroupId: EnderIdSchema.optional(),
  collectionsPausedNote: z.string().optional(),
  collectionsPausedUntilDate: z
    .date()
    .optional()
    .transform((date) =>
      date ? EnderDate.of(date).toLocalISOString() : undefined,
    ),
  conditionStatementSentTime: z.date().optional(),
  depositReceiptSentTime: z.date().optional(),
  deposits: z
    .object({
      amount: CurrencySchema.nullish(),
      glCategoryId: z.string(),
    })
    .array()
    .optional(),
  expectedMoveOutDate: z.string().or(z.instanceof(EnderDate)).nullish(),
  frequency: z.enum(["MONTHLY", "WEEKLY", "BIWEEKLY", "DAILY"]).optional(),
  inclusiveEndDate: z.string().or(z.instanceof(EnderDate)).nullish(),
  intention: z.enum(LeaseIntentionValues).optional(),
  leaseId: EnderIdSchema,
  leaseTemplateId: EnderIdSchema.optional(),
  leasingAgentId: EnderIdSchema.optional(),
  moveInComplete: z.boolean().optional(),
  moveInDate: z.string().or(z.instanceof(EnderDate)).nullish(),
  overrideWarnings: z.boolean().default(false).optional(),
  rent: z.number().or(CurrencySchema).optional(),
  startDate: z.string().or(z.instanceof(EnderDate)).nullish(),
  tenantInPlace: z.boolean().optional(),
});

type UpdateLeasePayload = z.infer<typeof UpdateLeasePayloadSchema>;

const useUpdateLeaseMutation = makeUseMutation<
  LeasingAPIUpdateLeasePayload,
  void
>("updateLease", LeasingAPI.updateLease);

export { UpdateLeasePayloadSchema, useUpdateLeaseMutation };
export type { UpdateLeasePayload };
