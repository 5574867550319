import type { Row, RowSelectionState } from "@tanstack/react-table";
import { useState } from "react";

import type { EmptyObject } from "@ender/shared/types/general";

import type { EnderTable, EnderTableRowSelectionParams } from "../table.types";

type UseTableRowSelectionParams<RowData, Meta extends object = EmptyObject> = {
  initialRowSelection?: RowSelectionState;
  enableSelectAll?: boolean;
  enableRowSelection?:
    | boolean
    | ((row: Row<RowData>, table: EnderTable<RowData, Meta>) => boolean);
  enableMultiRowSelection?:
    | boolean
    | ((row: Row<RowData>, table: EnderTable<RowData, Meta>) => boolean);
};

function useTableRowSelection<RowData, Meta extends object = EmptyObject>(
  params: UseTableRowSelectionParams<RowData, Meta> = {},
): EnderTableRowSelectionParams<RowData, Meta> {
  const {
    initialRowSelection = {},
    enableSelectAll = true,
    enableRowSelection = true,
    enableMultiRowSelection = true,
  } = params;
  const [rowSelection, onRowSelectionChange] =
    useState<RowSelectionState>(initialRowSelection);

  return {
    enableMultiRowSelection,
    enableRowSelection,
    enableSelectAll,
    onRowSelectionChange,
    rowSelection,
  };
}

export { useTableRowSelection };
