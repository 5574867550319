/**
 * HTTP Method: GET
 * Pathname: /applications/{applicationId}/getApplicationUser
 * @function getApplicationUser
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIGetApplicationUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetApplicationUserResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetApplicationUserResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIGetApplicationUserPathParams = {
  applicationId: EnderId;
};

type ApplicationsAPIGetApplicationUserSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIGetApplicationUserPayload =
  ApplicationsAPIGetApplicationUserPathParams &
    ApplicationsAPIGetApplicationUserSearchParams;

function getApplicationUserUnsafeEffect(
  payload: ApplicationsAPIGetApplicationUserPayload,
) {
  const { applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetApplicationUserResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetApplicationUserResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/applications/${applicationId}/getApplicationUser`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getApplicationUser(
  payload: ApplicationsAPIGetApplicationUserPayload,
  options?: { signal?: AbortSignal },
): Promise<GetApplicationUserResponse> {
  return F.pipe(
    payload,
    getApplicationUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getApplicationUser };
export type {
  ApplicationsAPIGetApplicationUserPathParams,
  ApplicationsAPIGetApplicationUserPayload,
  ApplicationsAPIGetApplicationUserSearchParams,
};
