import type { EnderId } from "@ender/shared/core";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import { SearchAPI } from "@ender/shared/generated/ender.api.misc";
import { SearchTypeEnum } from "@ender/shared/ui/ender-search";
import { getPropertyNameWithFriendlyId } from "@ender/shared/utils/property/get-property-name-with-friendly-id";

import type { SearchInputFn, SearchInputHydrateFn } from "./search-input";

const searchProperties: SearchInputFn<EnderId> = (keyword: string) =>
  // @ts-expect-error - generated type SearchAPIOmnisearchPayload is inaccurate
  SearchAPI.omnisearch({
    keyword: `"${keyword}"`,
    resultsOnEmpty: false,
    types: [SearchTypeEnum.PROPERTY],
  }).then((res) =>
    res.map((property) => ({
      label: getPropertyNameWithFriendlyId(property),
      value: property.id,
    })),
  );

const hydrateProperty: SearchInputHydrateFn<EnderId> = (propertyId: EnderId) =>
  PropertiesAPI.getProperty({ propertyId }).then((property) => [
    { label: property.name, value: property.id },
  ]);

const hydratePropertyWithFriendlyId: SearchInputHydrateFn<EnderId> = (
  propertyId: EnderId,
) =>
  PropertiesAPI.getProperty({ propertyId }).then((property) => [
    {
      label: getPropertyNameWithFriendlyId(property),
      value: property.id,
    },
  ]);

export { hydrateProperty, hydratePropertyWithFriendlyId, searchProperties };
