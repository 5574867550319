import { Predicate as P, String as S } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import type { InvoicePaymentMethod } from "@ender/shared/generated/ender.model.payments.invoice";

import { getPaymentMethodTypeFromPaymentType } from "../../../../../widgets/invoice-filters/get-payment-method-from-payment-type";
import type { InvoiceTableTabs } from "../../invoice-table-tabs.types";
import { InvoiceTableTabsEnum } from "../../invoice-table-tabs.types";

const ValidTabsForPaymentMethodType = [
  InvoiceTableTabsEnum.ALL,
  InvoiceTableTabsEnum.ASSIGNED_TO_ME,
  InvoiceTableTabsEnum.PAID,
] as InvoiceTableTabs[];

function getGuardedPaymentMethodType(
  tab: InvoiceTableTabs,
  paymentTypeFilter: InvoicePaymentMethod | Undefined,
): MoneyTransferTransferType | Undefined {
  if (
    !ValidTabsForPaymentMethodType.includes(tab) ||
    P.isUndefined(paymentTypeFilter) ||
    P.isNull(paymentTypeFilter) ||
    S.isEmpty(paymentTypeFilter)
  ) {
    return UNDEFINED;
  }
  return getPaymentMethodTypeFromPaymentType(paymentTypeFilter);
}
export { getGuardedPaymentMethodType, ValidTabsForPaymentMethodType };
