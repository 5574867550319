import { IconDownload, IconTrash } from "@tabler/icons-react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { EllipsisCell, asColumnDef } from "@ender/shared/ui/table-tanstack";

type AttachmentsViewFile = {
  authorName: string;
  key: string;
  name: string;
};

type AttachmentsViewMeta = {
  onDelete: (file: AttachmentsViewFile) => Promise<void>;
};

const columns: ColumnDef<AttachmentsViewFile>[] = [
  asColumnDef({
    accessorKey: "name",
    cell: (props) => {
      return (
        <a href={props.row.original.key} target="_blank" rel="noreferrer">
          {props.row.original.name}
        </a>
      );
    },
    header: "File Name",
  }),
  asColumnDef<AttachmentsViewFile>({
    accessorKey: "authorName",
    cell: EllipsisCell,
    header: "Uploaded By",
  }),
  asColumnDef<AttachmentsViewFile, unknown, unknown, AttachmentsViewMeta>({
    cell: (props) => {
      const { onDelete } = props.table.options.meta;
      return (
        <Group justify={Justify.center}>
          <ActionIcon
            variant={ButtonVariant.transparent}
            size={ButtonSize.sm}
            onClick={() => {
              window.open(props.row.original.key, "_blank");
            }}
            label="Download Attachment">
            <IconDownload />
          </ActionIcon>
          <ActionIcon
            variant={ButtonVariant.transparent}
            size={ButtonSize.sm}
            onClick={() => onDelete(props.row.original)}
            label="Delete Attachment">
            <IconTrash />
          </ActionIcon>
        </Group>
      );
    },
    id: "delete",
    size: 100,
  }),
];

export { columns };
export type { AttachmentsViewFile, AttachmentsViewMeta };
