import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitUnitStatusValues = [
  "MARKETED",
  "VACANT_LEASED",
  "OCCUPIED",
  "OCCUPIED_TIP",
  "RETIRED",
  "STAGING",
  "ADVERSELY_OCCUPIED",
  "SPECIAL_DAMAGE_RENOVATION",
  "MARKETING_DOWN",
  "HELD_FOR_SALE",
  "SOLD",
  "RENOVATING",
  "PENDING_MARKETING_CERTIFICATE",
  "PENDING_LISTING_CREATION",
  "TURN",
] as const;

const UnitUnitStatusEffectSchema = Schema.Literal(...UnitUnitStatusValues);

type UnitUnitStatus = Schema.Schema.Type<typeof UnitUnitStatusEffectSchema>;

const UnitUnitStatusEnum = castEnum(UnitUnitStatusEffectSchema);

function randomUnitUnitStatus(): UnitUnitStatus {
  return rand(UnitUnitStatusValues);
}

export {
  randomUnitUnitStatus,
  UnitUnitStatusEffectSchema,
  UnitUnitStatusEnum,
  UnitUnitStatusValues,
};
export type { UnitUnitStatus };
