import { Schema } from "@effect/schema";
import { IconArrowBackUp, IconX } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { clsx } from "clsx";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  pipe,
} from "effect";
import { useContext, useEffect, useMemo, useState } from "react";
import { useStore } from "zustand";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type {
  OptimizedLedgerCategory,
  OptimizedLedgerEvent,
  OptimizedLedgerEventAllocation,
} from "@ender/shared/contexts/ledger";
import { LedgerRightRailViewEnum } from "@ender/shared/contexts/ledger";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import {
  LocalDate$,
  LocalDateEffectSchema,
  Money$,
  randomEnderId,
} from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { useForm } from "@ender/shared/forms/hooks/general";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import { TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum } from "@ender/shared/generated/ender.arch.accounting";
import { CategoryFlagEnum } from "@ender/shared/generated/ender.model.accounting";
import { FeatureFlagFlagKeyEnum } from "@ender/shared/generated/ender.model.misc";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { fail } from "@ender/shared/utils/error";
import { LoadingIndicator } from "@ender/shared/utils/general";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertTitleCaseToSnakeCase } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";
import { shouldNegateAmount } from "@ender/widgets/finance/ledger-table";
import { FeatureFlagWrapper } from "@ender/widgets/system/feature-flag-wrapper";

import { LedgerDetailsTuple } from "../../../../../../../widgets/ledger-details-tuple/widgets-finance-ledger-details-tuple";
import { LedgerEventAttachmentBtn } from "../../../../../../../widgets/ledger-event-attachment-btn/ledger-event-attachment-btn";
import { useGetLeaseId, useOptimizedLedgerResponse } from "../../../../hooks";
import { useTenantLedgerStore } from "../../../../tenant-ledger-store.context";
import { LedgerEventAttachments } from "../../../ledger-event-attachments/ledger-event-attachments";
import { LedgerCancelInflightACHButton } from "./ledger-cancel-inflight-ach-button";
import { LedgerEventPrintBtn } from "./ledger-event-print-btn";
import { LedgerEventReverseBtn } from "./ledger-event-reverse-btn";
import { LedgerEventReverseCreditButton } from "./ledger-event-reverse-credit-button/ledger-event-reverse-credit-button";
import { ReversedPaymentForm } from "./reversed-payment-form";

import styles from "./ledger-payment.module.css";

// TODO: 2024-12-26 DRY and modernize this file: https://ender-1337.atlassian.net/browse/ENDER-22435

const ReverseRefundFormSchema = Schema.Struct({
  date: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Reversal Date is required." }),
  ),
});

type ReverseRefundFormValues = Schema.Schema.Type<
  typeof ReverseRefundFormSchema
>;

type ReverseRefundButtonProps = {
  close: () => void;
};

function ReverseRefundButton(props: ReverseRefundButtonProps) {
  const { close } = props;
  const leaseId = useGetLeaseId();
  const { hasPermissions } = useContext(UserContext);
  const hasTenantRefundPermission = hasPermissions(
    FunctionalPermissionEnum.REFUND_TENANT,
  );
  const tenantLedgerStore = useTenantLedgerStore();

  const [refundModalOpen, refundModalOpenHandlers] = useBoolean();

  const { selectedLedgerEvent } = useStore(tenantLedgerStore, (state) => ({
    selectedLedgerEvent: state.selectedLedgerEvent,
  }));

  const form = useEffectSchemaForm({
    defaultValues: {
      date: LocalDate$.parse(
        selectedLedgerEvent.pipe(
          O.map((event) => event.generalLedgerDate),
          O.getOrElse(() => NULL),
        ),
      ),
    },
    schema: ReverseRefundFormSchema,
  });

  async function handleReverseRefund(values: ReverseRefundFormValues) {
    const { date } = values;
    const _selectedLedgerEvent = selectedLedgerEvent.pipe(O.getOrUndefined);
    if (P.isNullable(_selectedLedgerEvent)) {
      return;
    }

    try {
      await TenantLedgerAPI.reverseTenantRefund({
        leaseId,
        refundMoneyTransferId: _selectedLedgerEvent.id,
        reversalDate: O.getOrThrow(date).toJSON(),
      });
      close();
      refundModalOpenHandlers.setFalse();
      showSuccessNotification({
        message: "Refund reversed successfully",
      });
      window.location.reload();
    } catch (err) {
      fail(err);
    }
  }

  const canReverseRefund =
    hasTenantRefundPermission &&
    selectedLedgerEvent.pipe(
      O.map(
        (event) =>
          !event.isReversedCredit &&
          !event.isReversedCharge &&
          !event.isReversed &&
          !event.isCreditReversal &&
          !event.isChargeReversal,
      ),
      O.getOrElse(() => false),
    );

  const disabledRefundTooltip = useMemo(() => {
    if (!hasTenantRefundPermission) {
      return "You do not have permission to reverse";
    }
    return selectedLedgerEvent.pipe(
      O.flatMap((event) => {
        if (
          event.isReversedCredit ||
          event.isReversedCharge ||
          event.isReversed
        ) {
          return O.some("Refund has already been reversed.");
        } else if (event.isCreditReversal || event.isChargeReversal) {
          return O.some(
            "This transaction reverses a previous one and cannot be reversed again.",
          );
        } else {
          return O.none();
        }
      }),
      O.getOrUndefined,
    );
  }, [hasTenantRefundPermission, selectedLedgerEvent]);

  return (
    <>
      <Modal
        opened={refundModalOpen}
        onClose={refundModalOpenHandlers.setFalse}
        title="Reverse Refund">
        <Form form={form} onSubmit={handleReverseRefund}>
          <Stack>
            <Text size={FontSize.sm}>
              Processing a reversal will create offsetting entries for this
              refund. The reversing entry will appear on both the General Ledger
              and the Tenant Ledger
            </Text>
            <Text size={FontSize.sm}>
              This action will revert the associated payable to the last step of
              the AP approval chain. It will also remove the Ender Txn row from
              bank rec.
            </Text>
            <FormDateInput label="Reversal Date" name="date" form={form} />
            <Group justify={Justify.end}>
              <Button type="submit">Confirm</Button>
            </Group>
          </Stack>
        </Form>
      </Modal>
      <Button
        variant={ButtonVariant.outlined}
        color={Color.red}
        disabledTooltip={disabledRefundTooltip}
        disabled={!canReverseRefund}
        leftSection={<IconArrowBackUp />}
        onClick={refundModalOpenHandlers.setTrue}>
        Reverse Refund
      </Button>
    </>
  );
}

type FormAllocation = Pick<
  OptimizedLedgerEventAllocation,
  "amount" | "glCategoryId"
> & {
  uuid: EnderId;
};
type FormAllocationWithCategoryInfo = FormAllocation & {
  endingBalanceForCategory: number | Money$.Money;
};

function LedgerPaymentView({ closeRightRail }: { closeRightRail: () => void }) {
  const { categories, prepaymentCategory, endingPrepaymentsBalance } =
    useOptimizedLedgerResponse();

  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [
    isUpdatingAllocations,
    {
      setTrue: setIsUpdatingAllocationsTrue,
      setFalse: setIsUpdatingAllocationsFalse,
    },
  ] = useBoolean();
  const [
    isReversePaymentModalOpen,
    { setTrue: openReversePaymentModal, setFalse: closeReversePayment },
  ] = useBoolean(false);
  const leaseId = useGetLeaseId();
  const tenantLedgerStore = useTenantLedgerStore();

  const { selectedLedgerEvent: selectedLedgerEventEffectOption } = useStore(
    tenantLedgerStore,
    (state) => ({
      selectedLedgerEvent: state.selectedLedgerEvent,
    }),
  );
  const ledgerEvent = selectedLedgerEventEffectOption.pipe(O.getOrUndefined);

  const firstMatchingPrepaymentCategory = useMemo(() => {
    if (P.isNullable(ledgerEvent)) {
      return UNDEFINED;
    }

    return (
      ledgerEvent.allocations
        .map((allocation) =>
          categories.find(({ id }) => id === allocation.glCategoryId),
        )
        .find((_category) => P.isNotUndefined(_category)) ?? UNDEFINED
    );
  }, [ledgerEvent, categories]);

  const { prepaymentAmount, nonPrepaymentAllocations } = useMemo(() => {
    if (P.isNullable(ledgerEvent)) {
      return {
        nonPrepaymentAllocations: [],
        prepaymentAmount: Money$.zero(),
      };
    }

    return pipe(
      ledgerEvent.allocations,
      A.reduce<
        {
          prepaymentAmount: Money$.Money;
          nonPrepaymentAllocations: (OptimizedLedgerEventAllocation & {
            category: OptimizedLedgerCategory | Undefined;
          })[];
        },
        OptimizedLedgerEventAllocation
      >(
        {
          prepaymentAmount: Money$.zero(),
          nonPrepaymentAllocations: [],
        },
        (acc, allocation) => {
          const _category = categories.find(
            ({ id }) => id === allocation.glCategoryId,
          );

          return _category?.id === prepaymentCategory?.id
            ? {
                ...acc,
                prepaymentAmount: Money$.add(
                  acc.prepaymentAmount,
                  allocation.effectOnCategoryBalance,
                ),
              }
            : {
                ...acc,
                nonPrepaymentAllocations: [
                  ...acc.nonPrepaymentAllocations,
                  { ...allocation, category: _category },
                ],
              };
        },
      ),
    );
  }, [ledgerEvent, categories, prepaymentCategory?.id]);

  const form = useForm({
    initialValues: {
      allocations: [] as FormAllocation[],
    },
    validate: {
      allocations: {
        amount: (value) => {
          if (O.isNone(value)) {
            return "Amount is required";
          }
          if (O.exists(value, Money$.isNegative)) {
            return "Amount must be positive";
          }
          return NULL;
        },
        glCategoryId: (value) => {
          if (P.isNullable(value)) {
            return "Select a charge type";
          }
          return NULL;
        },
      },
    },
  });

  const {
    insertListItem,
    removeListItem,
    reset,
    values: { allocations },
  } = form;

  const allocationsRef = useRefLatest(allocations);

  // lazy useEffect
  useEffect(() => {
    setIsEditing(false);

    if (A.isNonEmptyArray(allocationsRef.current)) {
      A.forEach(allocationsRef.current, (_, i) => {
        removeListItem("allocations", i);
      });
    }
  }, [selectedLedgerEventEffectOption, removeListItem, allocationsRef]);

  // lazy useEffect
  useEffect(() => {
    if (!isEditing) {
      return;
    }

    for (const allocation of nonPrepaymentAllocations) {
      insertListItem("allocations", {
        amount: O.map(allocation.amount, Money$.abs),
        glCategoryId: allocation.glCategoryId,
        uuid: randomEnderId(),
      });
    }
  }, [nonPrepaymentAllocations, isEditing, insertListItem]);

  // lazy useEffect
  useEffect(() => {
    if (!isEditing) {
      reset();
    }
  }, [nonPrepaymentAllocations, isEditing, reset]);

  const {
    workingPrepaymentAmount,
    workingPrepaymentBalance,
    unusedNonPrepaymentCategories,
  } = useMemo(() => {
    if (P.isNullable(ledgerEvent)) {
      return {
        unusedNonPrepaymentCategories: [],
        workingPrepaymentAmount: Money$.zero(),
        workingPrepaymentBalance: O.none<Money$.Money>(),
      };
    }

    const _workingPrepaymentAmount = pipe(
      form.values.allocations,
      A.map((v) => v.amount),
      O.reduceCompact(
        //initial value
        pipe(
          ledgerEvent.amount,
          O.map((value) => value.abs()),
          O.getOrElse(F.constant(Money$.zero())),
        ),
        //reducer fn
        Money$.subtract,
      ),
    );

    const _workingPrepaymentBalance = F.pipe(
      O.fromNullable(endingPrepaymentsBalance),
      O.map(Money$.add(_workingPrepaymentAmount)),
      O.map(Money$.subtract(prepaymentAmount)),
    );

    return {
      unusedNonPrepaymentCategories: categories.filter(
        (category) =>
          category.id !== prepaymentCategory?.id &&
          form.values.allocations.every(
            ({ glCategoryId }) => glCategoryId !== category.id,
          ),
      ),
      workingPrepaymentAmount: _workingPrepaymentAmount,
      workingPrepaymentBalance: _workingPrepaymentBalance,
    };
  }, [
    form.values.allocations,
    categories,
    endingPrepaymentsBalance,
    prepaymentAmount,
    prepaymentCategory?.id,
    ledgerEvent,
  ]);

  const formAllocationsWithCategoryInfo: FormAllocationWithCategoryInfo[] =
    useMemo(
      () =>
        form.values.allocations.map(({ glCategoryId, amount, uuid }) => ({
          amount,
          endingBalanceForCategory:
            categories.find(({ id }) => id === glCategoryId)?.endingBalance ||
            0,
          glCategoryId,
          uuid,
        })),
      [categories, form.values.allocations],
    );

  const submitDisabledTooltipMessage = useMemo(() => {
    if (Money$.isNegative(workingPrepaymentAmount)) {
      return "Cannot save over-allocated payment";
    }

    return "";
  }, [workingPrepaymentAmount]);

  function onAllocationAmountChange(
    amount: O.Option<Money$.Money>,
    index: number,
  ) {
    form.clearFieldError(`allocations.${index}.amount`);
    form.setFieldValue(`allocations.${index}`, {
      ...form.values.allocations[index],
      amount,
    });
  }

  function onCategoryChange(
    value: O.Option<EnderId>,
    item: FormAllocation,
    index: number,
  ) {
    form.clearFieldError(`allocations.${index}.glCategoryId`);
    form.setFieldValue(`allocations.${index}`, {
      ...item,
      glCategoryId: value.pipe(O.getOrUndefined),
    });
  }

  // extract this
  function renderFormAllocationCategorySection(
    formAllocation: FormAllocation,
    index: number,
  ) {
    const { accountName } =
      categories.find(
        (cat) => cat.id === form.values.allocations[index].glCategoryId,
      ) ?? {};

    return (
      <Select
        value={O.fromNullable(form.values.allocations[index].glCategoryId)}
        onChange={(value) => onCategoryChange(value, formAllocation, index)}
        placeholder="Add Charge Type"
        data={[
          ...(form.values.allocations[index].glCategoryId && accountName
            ? [
                {
                  value: form.values.allocations[index].glCategoryId,
                  label: accountName,
                },
              ]
            : []),
          ...unusedNonPrepaymentCategories
            .filter(({ flags }) =>
              flags.some((flag) =>
                [
                  CategoryFlagEnum.TENANT_CHARGE_TYPE,
                  CategoryFlagEnum.SECURITY_DEPOSITS,
                ].includes(convertTitleCaseToSnakeCase(flag)),
              ),
            )
            .map(({ id, accountName }) => ({
              label: accountName,
              value: id,
            })),
        ]}
      />
    );
  }

  function onChangeAllocationsClick() {
    setIsEditing(true);
  }

  function onDeleteAllocationItem(index: number) {
    form.clearFieldError(`allocations.${index}.amount`);
    form.clearFieldError(`allocations.${index}.glCategoryId`);
    form.removeListItem("allocations", index);
  }

  function onAddChargeTypeClick() {
    form.insertListItem("allocations", {
      amount: O.fromNullable(Money$.zero()),
      glCategoryId: "",
      uuid: randomEnderId(),
    });
  }

  function onCancelClick() {
    setIsEditing(false);
  }

  async function refreshLedgerAllocations() {
    await queryClient.invalidateQueries(["getTenantLedger"]);
    closeRightRail();
  }

  async function onAllocationsSubmit() {
    if (P.isNullable(ledgerEvent)) {
      return;
    }
    if (form.validate().hasErrors) {
      return;
    }

    try {
      setIsUpdatingAllocationsTrue();
      const prepaymentAllocation = {
        amount: workingPrepaymentAmount?.toJSON(),
        categoryId: prepaymentCategory?.id,
        historical: false,
      };
      const allocations = [
        ...form.values.allocations.map(({ glCategoryId, amount }) => {
          return {
            amount: pipe(
              amount,
              O.map((m) => m.toJSON()),
              O.getOrThrow,
            ),
            categoryId: glCategoryId,
            historical: false,
          };
        }),
        prepaymentAllocation,
      ];
      await TenantLedgerAPI.manuallyAllocateTenantInflow({
        allocations,
        leaseId,
        modelId: ledgerEvent.id,
        modelType: ledgerEvent.ledgerEventType,
      });
      setIsEditing(false);
      await refreshLedgerAllocations();
    } catch (err) {
      fail(err);
    } finally {
      setIsUpdatingAllocationsFalse();
    }
  }

  const formPrepaymentRowClassNames = clsx({
    "ledger-payment__allocations-list-item": true,
    [styles.ledgerPaymentGridRow]: true,
    [styles.ledgerPaymentGridRowEdit]: true,
    "ledger-payment__over-allocated": Money$.isNegative(
      workingPrepaymentAmount,
    ),
  });

  const isRefund = O.contains(
    O.map((event: OptimizedLedgerEvent) => event.tenantLedgerEventType)(
      selectedLedgerEventEffectOption,
    ),
    TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.REFUND,
  );

  const isCredit = O.contains(
    O.map((event: OptimizedLedgerEvent) => event.tenantLedgerEventType)(
      selectedLedgerEventEffectOption,
    ),
    TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.CREDIT,
  );

  return (
    <Stack>
      <Stack>
        <Group spacing={Spacing.xs}>
          <LedgerEventPrintBtn />
          {/* @ts-expect-error The type from the store is nullish and  */}
          <LedgerEventAttachmentBtn ledgerEvent={ledgerEvent} />
          {O.contains(
            O.map((event: OptimizedLedgerEvent) => event.tenantLedgerEventType)(
              selectedLedgerEventEffectOption,
            ),
            TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.PAYMENT,
          ) && <LedgerEventReverseBtn onClick={openReversePaymentModal} />}
          {isRefund && <ReverseRefundButton close={closeReversePayment} />}
          {isCredit && <LedgerEventReverseCreditButton />}
          <FeatureFlagWrapper
            flagKey={FeatureFlagFlagKeyEnum.CANCEL_IN_FLIGHT_ACH_PAYMENTS}>
            <LedgerCancelInflightACHButton moneyTransferId={ledgerEvent?.id} />
          </FeatureFlagWrapper>
        </Group>
        <LedgerDetailsTuple
          amount={selectedLedgerEventEffectOption.pipe(
            O.flatMap((event) => {
              const { tenantLedgerEventType, isCreditReversal } = event;
              const _shouldNegateAmount = shouldNegateAmount(
                tenantLedgerEventType,
                isCreditReversal,
              );
              return F.pipe(
                event.amount,
                O.map(Money$.negateWhen(() => _shouldNegateAmount)),
              );
            }),
            O.getOrElse(() => Money$.zero()),
          )}
          transactionDate={selectedLedgerEventEffectOption.pipe(
            O.map((event) =>
              LocalDate$.toFormatted(
                LocalDate$.of(event.generalLedgerDate),
                LocalDate$.Formats.DEFAULT,
              ),
            ),
            O.getOrElse(() => ""),
          )}
          accountingDate={selectedLedgerEventEffectOption.pipe(
            O.map((event) => event.accountingDate),
            O.getOrElse(() => ""),
          )}
          systemDate={selectedLedgerEventEffectOption.pipe(
            O.map((event) =>
              LocalDate$.toFormatted(
                LocalDate$.of(event.systemDate),
                LocalDate$.Formats.DEFAULT,
              ),
            ),
            O.getOrElse(() => ""),
          )}
          description={selectedLedgerEventEffectOption.pipe(
            O.map((event) => event.description),
            O.getOrElse(() => ""),
          )}
          source={selectedLedgerEventEffectOption.pipe(
            O.map((event) => event.source),
            O.getOrElse(() => ""),
          )}
          author={selectedLedgerEventEffectOption.pipe(
            O.map((event) => event.authorDisplay),
            O.getOrElse(() => ""),
          )}
          debitedCategory={
            isCredit ? firstMatchingPrepaymentCategory : UNDEFINED
          }
        />
      </Stack>
      <div className={styles.ledgerPaymentViewList}>
        {!isEditing && (
          <div>
            <div
              className={`${styles.ledgerPaymentGridRow} ${styles.ledgerPaymentAllocationsHeader}`}>
              <div>Charge Type</div>
              <Group justify={Justify.end}>Allocations</Group>
            </div>
            <div className={styles.ledgerPaymentAllocationsList}>
              {nonPrepaymentAllocations.map((allocation) => (
                <div
                  key={allocation.id}
                  className={`${styles.ledgerPaymentAllocationsListItem} ${styles.ledgerPaymentGridRow}`}>
                  <div>{allocation.category?.accountName}</div>
                  <Group justify={Justify.end}>
                    <MoneyDisplay
                      value={O.map(allocation.amount, Money$.negate)}
                    />
                  </Group>
                </div>
              ))}

              <div
                className={`${styles.ledgerPaymentAllocationsListItem} ${styles.ledgerPaymentGridRow}`}>
                <div>{prepaymentCategory?.accountName}</div>
                <Group justify={Justify.end}>
                  <MoneyDisplay value={prepaymentAmount} />
                </Group>
              </div>
            </div>
            <Group justify={Justify.between}>
              <div />
              {selectedLedgerEventEffectOption.pipe(
                O.flatMap((event) => O.fromNullable(event.canBeReallocated)),
                O.getOrElse(() => false),
              ) ? (
                <Button onClick={onChangeAllocationsClick}>
                  Change Allocations
                </Button>
              ) : (
                <Button
                  disabled
                  disabledTooltip="The ability to change allocations for this ledger entry is temporarily disabled. Please contact Ender OPS to change these allocations.">
                  Change Allocations
                </Button>
              )}
            </Group>
          </div>
        )}
        {isUpdatingAllocations && (
          <LoadingIndicator message="Updating allocations..." />
        )}
        {isEditing && (
          <div>
            <div
              className={`${styles.ledgerPaymentAllocationsHeader} ${styles.ledgerPaymentGridRow} ${styles.ledgerPaymentGridRowEdit}`}>
              <div>Charge Type</div>
              <Group justify={Justify.end}>Allocations</Group>
              <Group justify={Justify.end}>Current Balance</Group>
            </div>
            <div className={styles.ledgerPaymentAllocationsList}>
              {formAllocationsWithCategoryInfo.map((item, index) => (
                <div
                  key={item.uuid}
                  className={`${styles.ledgerPaymentAllocationsListItem} ${styles.ledgerPaymentGridRow} ${styles.ledgerPaymentGridRowEdit}`}>
                  <div>{renderFormAllocationCategorySection(item, index)}</div>
                  <Group justify={Justify.end}>
                    <MoneyInput
                      value={item.amount}
                      onChange={(val) => onAllocationAmountChange(val, index)}
                    />
                  </Group>
                  <div className="numeric">
                    <Group justify={Justify.end}>
                      <MoneyDisplay
                        value={Money$.parse(item.endingBalanceForCategory)}
                      />
                    </Group>
                  </div>

                  <div
                    className={styles.ledgerPaymentListItemCloseBtn}
                    onClick={() => onDeleteAllocationItem(index)}>
                    <IconX />
                  </div>

                  <div className="error-message">
                    {form.errors[`allocations.${index}.glCategoryId`]}
                  </div>

                  <div className="error-message">
                    <Group justify={Justify.end}>
                      {form.errors[`allocations.${index}.amount`]}
                    </Group>
                  </div>
                </div>
              ))}

              {/* Prepayment */}
              <div className={formPrepaymentRowClassNames}>
                {/* Charge Type */}
                <div>
                  {!Money$.isNegative(workingPrepaymentAmount)
                    ? (prepaymentCategory?.accountName ??
                      "Unknown account Name")
                    : "Over Allocated"}
                </div>
                {/* Allocations */}
                <Group justify={Justify.end}>
                  <MoneyDisplay
                    value={workingPrepaymentAmount ?? Money$.zero}
                  />
                </Group>
                {/* Current Balance */}
                <Group justify={Justify.end}>
                  {!Money$.isNegative(workingPrepaymentAmount) && (
                    <MoneyDisplay
                      value={O.getOrElse(
                        workingPrepaymentBalance,
                        F.constant(Money$.zero()),
                      )}
                    />
                  )}
                </Group>
              </div>
            </div>
            <Group justify={Justify.between}>
              <Button onClick={onAddChargeTypeClick}>Add Charge Type</Button>
              <Group>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button
                  disabled={!!submitDisabledTooltipMessage}
                  disabledTooltip={submitDisabledTooltipMessage}
                  onClick={onAllocationsSubmit}>
                  Save Allocations
                </Button>
              </Group>
            </Group>
          </div>
        )}
        <LedgerEventAttachments />
      </div>
      <Modal
        opened={isReversePaymentModalOpen}
        onClose={closeReversePayment}
        title="Reverse Payment">
        <ReversedPaymentForm
          onSuccess={refreshLedgerAllocations}
          closeModal={closeReversePayment}
        />
      </Modal>
    </Stack>
  );
}

type LedgerPaymentProps = {
  closeRightRail: () => void;
};

function LedgerPayment(props: LedgerPaymentProps) {
  const { closeRightRail } = props;
  const { categories, prepaymentCategory, endingPrepaymentsBalance } =
    useOptimizedLedgerResponse();
  const tenantLedgerStore = useTenantLedgerStore();

  const { selectedLedgerEvent: _selectedLedgerEvent, ledgerRightRailView } =
    useStore(tenantLedgerStore, (state) => ({
      ledgerRightRailView: state.ledgerRightRailView,
      selectedLedgerEvent: state.selectedLedgerEvent,
    }));

  const selectedLedgerEvent = _selectedLedgerEvent.pipe(O.getOrUndefined);

  // Because of our current crappy architecture, we have to reactively respond to the value changes to close the right rail, otherwise we crash from
  // the undefined values. This is a hack to prevent that crash. We should fix this.
  useEffect(() => {
    if (
      (ledgerRightRailView !== LedgerRightRailViewEnum.NONE &&
        P.isNullable(selectedLedgerEvent)) ||
      P.isNullable(categories) ||
      P.isNullable(prepaymentCategory) ||
      P.isNullable(endingPrepaymentsBalance)
    ) {
      closeRightRail();
    }
  }, [
    selectedLedgerEvent,
    categories,
    prepaymentCategory,
    endingPrepaymentsBalance,
    ledgerRightRailView,
    closeRightRail,
  ]);

  if (
    P.isNullable(selectedLedgerEvent) ||
    P.isNullable(categories) ||
    P.isNullable(prepaymentCategory) ||
    P.isNullable(endingPrepaymentsBalance)
  ) {
    return NULL;
  }

  return <LedgerPaymentView closeRightRail={closeRightRail} />;
}

export { LedgerPayment };
