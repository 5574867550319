import type { ComponentType } from "react";

import { useFormContext } from "@ender/shared/forms/hooks/general";
import { cast } from "@ender/shared/types/cast";

/** @deprecated This should not be used anymore. */
type ContextAwareInputProps<T> = {
  Component: ComponentType<T>;
  props?: Partial<T>;
  name: string;
};

/** @deprecated This should not be used anymore. */
function ContextAwareInput<T, K>({
  Component,
  props = {},
  name,
}: ContextAwareInputProps<T>) {
  const _props = cast<T>(props);
  const form = useFormContext<K>();
  return <Component {..._props} {...form.getInputProps(name)} />;
}

export { ContextAwareInput };
export type { ContextAwareInputProps };
