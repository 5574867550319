/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/repairCalculations
 * @function getRepairCalculations
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPIGetRepairCalculationsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RepairCalculations | undefined>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { RepairCalculations } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnderwritingAPIGetRepairCalculationsPathParams = {
  dealId: EnderId;
};

type UnderwritingAPIGetRepairCalculationsSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPIGetRepairCalculationsPayload =
  UnderwritingAPIGetRepairCalculationsPathParams &
    UnderwritingAPIGetRepairCalculationsSearchParams;

function getRepairCalculationsUnsafeEffect(
  payload: UnderwritingAPIGetRepairCalculationsPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RepairCalculations | undefined>({
        body,
        decode: unsafeDecodeJsonResponse<RepairCalculations | undefined>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/repairCalculations`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRepairCalculations(
  payload: UnderwritingAPIGetRepairCalculationsPayload,
  options?: { signal?: AbortSignal },
): Promise<RepairCalculations | undefined> {
  return F.pipe(
    payload,
    getRepairCalculationsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRepairCalculations };
export type {
  UnderwritingAPIGetRepairCalculationsPathParams,
  UnderwritingAPIGetRepairCalculationsPayload,
  UnderwritingAPIGetRepairCalculationsSearchParams,
};
