/**
 * Returns a list like ["1234", "1236", "1237"] of used check-numbers for this bank account.
 * HTTP Method: GET
 * Pathname: /bankAccounts/{bankAccountId}/check-numbers
 * @function getBankAccountCheckNumbers
 * @memberof BankingAPI
 * @param {BankingAPIGetBankAccountCheckNumbersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetBankAccountCheckNumbersPathParams = {
  /**
   * should reference a firm-owned bank account.
   */
  bankAccountId: EnderId;
};

type BankingAPIGetBankAccountCheckNumbersSearchParams = {
  token?: string | undefined;
};

type BankingAPIGetBankAccountCheckNumbersPayload =
  BankingAPIGetBankAccountCheckNumbersPathParams &
    BankingAPIGetBankAccountCheckNumbersSearchParams;

function getBankAccountCheckNumbersUnsafeEffect(
  payload: BankingAPIGetBankAccountCheckNumbersPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string[]>({
        body,
        decode: unsafeDecodeJsonResponse<string[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/bankAccounts/${bankAccountId}/check-numbers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBankAccountCheckNumbers(
  payload: BankingAPIGetBankAccountCheckNumbersPayload,
  options?: { signal?: AbortSignal },
): Promise<string[]> {
  return F.pipe(
    payload,
    getBankAccountCheckNumbersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankAccountCheckNumbers };
export type {
  BankingAPIGetBankAccountCheckNumbersPathParams,
  BankingAPIGetBankAccountCheckNumbersPayload,
  BankingAPIGetBankAccountCheckNumbersSearchParams,
};
