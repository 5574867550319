import {
  IconChevronLeft,
  IconEdit,
  IconInfoCircle,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  pipe,
} from "effect";
import { useCallback, useMemo, useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H1, H3 } from "@ender/shared/ds/heading";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import {
  ApplicationsAPI,
  LeasingAPI,
  PetsAPI,
} from "@ender/shared/generated/ender.api.leasing";
import type {
  ApplicantApplicationGroupResponseApplicationResponse,
  GetApplicationGroupResponseApplicantUserResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type {
  ApplicationApplicantType,
  Pet,
  Vehicle,
} from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationApplicantTypeEnum,
  ApplicationRelationshipTypeEnum,
} from "@ender/shared/generated/ender.model.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useMediaQuery } from "@ender/shared/hooks/use-media-query";
import { noOpAsync } from "@ender/shared/utils/no-op";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { AddEditPetForm } from "@ender/widgets/leasing/add-edit-pet";
import {
  ConnectedAddVehicleForm,
  ConnectedEditVehicleForm,
} from "@ender/widgets/leasing/add-vehicle-form";
import { VehicleCard } from "@ender/widgets/leasing/vehicle-card";

import type { ApplicantFormOutput } from "../modals/add-edit-occupant";
import { AddOrEditApplicant } from "../modals/add-edit-occupant";
import type { ApplyStepProps } from "./step-props";

import commonStyles from "./common.module.css";
import styles from "./occupants.module.css";

const occupantStepDescription = `Add any additional occupants, pets, and guarantors.\n\nAll occupants 18+ must fill out an application. Each applicant will receive an email with a link to fill out their application. If email address is left blank, you will have the option to start their application from the final page of this application.`;

const financiallyResponsibleTooltip = `A financially responsible tenant may be held responsible for rent and other payments.`;
const additionalOccupantsTooltip = `Any occupants of the unit or property who are not financially responsible, including minors.`;
const petsTooltip = `Any and all pets expected to reside in the property or unit.`;
const guarantorsTooltip = `Someone who is able to co-sign your lease, but will not be an occupant. They are responsible for any rent payments that you cannot or do not make.`;

const occupantTypeStringMap: Record<ApplicationApplicantType | "PET", string> =
  {
    GUARANTOR: "Guarantor",
    OTHER_OCCUPANT: "Occupant",
    PET: "Pet",
    RESPONSIBLE_OCCUPANT: "Financially Responsible",
  };

type OccupantCardProps = {
  className?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  applicant: Pick<User, "firstName" | "lastName" | "email">;
  application: Pick<
    ApplicantApplicationGroupResponseApplicationResponse,
    "relationshipType" | "applicantType"
  >;
};

const OccupantCard = ({
  applicant,
  application,
  className,
  ...props
}: OccupantCardProps) => {
  const subtitle = occupantTypeStringMap[application.applicantType];

  return (
    <div className={className}>
      <Card>
        <Stack spacing={Spacing.sm}>
          <Text truncate size={FontSize.lg} weight={FontWeight.semibold}>
            {applicant.firstName} {applicant.lastName}
          </Text>
          <Text weight={FontWeight.medium}>{applicant.email || "-"}</Text>
          <Text size={FontSize.xs} color="slate-700">
            {subtitle}
          </Text>
          <Group spacing={Spacing.sm}>
            {P.isNotNullable(props.onDelete) &&
              //a person can only be deleted if they are not the current applicant or the main applicant
              (P.isNullable(application.relationshipType) ||
                application.relationshipType !==
                  ApplicationRelationshipTypeEnum.SELF) && (
                <ActionIcon onClick={props.onDelete}>
                  <IconTrash />
                </ActionIcon>
              )}
            {props.onEdit && (
              <ActionIcon onClick={props.onEdit}>
                <IconEdit />
              </ActionIcon>
            )}
          </Group>
        </Stack>
      </Card>
    </div>
  );
};

type PetCardProps = { pet: Pet } & Pick<
  OccupantCardProps,
  "onDelete" | "onEdit" | "className"
>;
const PetCard = ({ pet, ...props }: PetCardProps) => {
  const subtitle = `${pet.breed}, ${pet.weightInPounds}`;
  const isESA = P.isNotNullable(pet.emotionalSupportAnimalTime);
  return (
    <div className={props.className}>
      <Card>
        <Stack spacing={Spacing.xs}>
          <Text truncate size={FontSize.lg} weight={FontWeight.semibold}>
            {pet.name}
          </Text>
          <Text weight={FontWeight.medium}>{pet.species}</Text>
          <Text size={FontSize.xs} color="slate-700">
            {subtitle}
          </Text>
          {isESA && (
            <Text size={FontSize.xs} color="slate-700">
              Emotional Support Animal
            </Text>
          )}
          <Group spacing={Spacing.sm}>
            {P.isNotNullable(props.onDelete) && (
              <ActionIcon onClick={props.onDelete}>
                <IconTrash />
              </ActionIcon>
            )}
            {P.isNotNullable(props.onEdit) && (
              <ActionIcon onClick={props.onEdit}>
                <IconEdit />
              </ActionIcon>
            )}
          </Group>
        </Stack>
      </Card>
    </div>
  );
};

function ApplyStepOccupants({
  applications,
  user,
  applicationGroup,
  pets,
  refresh = noOpAsync,
  progress,
  titleRef,
  onRequestNext = F.constVoid,
  onRequestPrevious = F.constVoid,
}: ApplyStepProps) {
  const vehicles = useMemo(() => {
    return (
      applications?.flatMap((application) =>
        application.vehicles.map((vehicle) => ({
          ...vehicle,
          ownerId: application.applicant.userId,
        })),
      ) ?? []
    );
  }, [applications]);
  /**
   * given an object, can update or create a new applicant based on those values.
   * @param values an Applicant that was added or edited via the form
   */

  /**
   * the modal representing whether the user is adding or editing an occupant/pet
   */
  const [modalOpen, modalOpenHandlers] = useBoolean(false);
  const [whichForm, setWhichForm] = useState<
    | {
        formType: "occupant";
        occupant?: GetApplicationGroupResponseApplicantUserResponse;
        occupantType: ApplicationApplicantType;
      }
    | { formType: "pet"; pet?: Pet; ownerId: EnderId }
  >({
    formType: "occupant",
    occupantType: ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
  });
  const showForm = useCallback(
    (...args: Parameters<typeof setWhichForm>) => {
      setWhichForm(...args);
      modalOpenHandlers.setTrue();
    },
    [modalOpenHandlers],
  );

  const { mutateAsync: deleteVehicle, isLoading: isDeletingVehicle } =
    useMutation({
      mutationFn: LeasingAPI.deleteVehicle,
      mutationKey: ["LeasingAPI.deleteVehicle"] as const,
    });

  const [vehicleModalOpen, vehicleModalOpenHandlers] = useBoolean(false);
  const [editingVehicle, setEditingVehicle] = useState<Vehicle | undefined>();

  const { mutateAsync: addOccupant } = useMutation({
    mutationFn: ApplicationsAPI.addPersonToApplication,
    mutationKey: ["ApplicationsAPI.addPersonToApplication"] as const,
  });

  const handleAddOrEditOccupant = useCallback(
    async (values: ApplicantFormOutput) => {
      if (P.isNotNullable(applicationGroup?.id)) {
        await addOccupant({
          ...values,
          applicantType: O.getOrThrow(values.applicantType),
          birthday: pipe(
            values.birthday,
            O.map((v) => v.toJSON()),
            O.getOrThrow,
          ),
          applicationGroupId: applicationGroup?.id,
          relationship: ApplicationRelationshipTypeEnum.FRIEND,
        });
      }
      refresh();
      modalOpenHandlers.setFalse();
    },
    [addOccupant, applicationGroup, refresh, modalOpenHandlers],
  );

  const { mutateAsync: deletePet } = useMutation({
    mutationFn: PetsAPI.archivePet,
    mutationKey: ["PetsAPI.archivePet"] as const,
  });

  const handleDeletePet = useCallback(
    async (petId: EnderId) => {
      await deletePet({ petId });
      refresh();
    },
    [deletePet, refresh],
  );

  const applicationsByType = useMemo(() => {
    return applications?.reduce(
      (acc, cur) => {
        acc[cur.applicantType].push(cur);
        return acc;
      },
      {
        [ApplicationApplicantTypeEnum.GUARANTOR]: [],
        [ApplicationApplicantTypeEnum.OTHER_OCCUPANT]: [],
        [ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT]: [],
      } as Record<
        ApplicationApplicantType,
        ApplicantApplicationGroupResponseApplicationResponse[]
      >,
    );
  }, [applications]);

  const isDesktop = useMediaQuery(`(min-width: ${768}px)`);

  return (
    <div className={commonStyles.stepContainer}>
      <div className={styles.inner}>
        <div className={commonStyles.stepHeader} ref={titleRef}>
          <Stack spacing={Spacing.none}>
            <div className={commonStyles.navBack}>
              <Button
                variant={ButtonVariant.transparent}
                leftSection={<IconChevronLeft size={16} />}
                onClick={onRequestPrevious}>
                Back
              </Button>
            </div>
            <H1>Occupant Information</H1>
            {progress}
          </Stack>
        </div>
        <Text>{occupantStepDescription}</Text>
        {/* Financially Responsible Occupants section */}
        <Group spacing={Spacing.sm} align="center">
          <H3>Financially Responsible Occupants</H3>
          <Tooltip label={financiallyResponsibleTooltip}>
            <IconInfoCircle size={24} color="var(--color-primary-500)" />
          </Tooltip>
        </Group>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          {applicationsByType?.[
            ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT
          ].map((application) => (
            <OccupantCard
              applicant={application.applicant}
              application={application}
              key={application.applicant.userId}
            />
          ))}
        </div>
        <Button
          variant={ButtonVariant.outlined}
          leftSection={<IconPlus />}
          onClick={() =>
            showForm({
              formType: "occupant",
              occupantType: ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
            })
          }>
          Add
        </Button>
        {/* Additional occupants section */}
        <Group spacing={Spacing.sm} align="center">
          <H3>Additional Occupants</H3>
          <Tooltip label={additionalOccupantsTooltip}>
            <IconInfoCircle size={24} color="var(--color-primary-500)" />
          </Tooltip>
        </Group>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          {applicationsByType?.[
            ApplicationApplicantTypeEnum.OTHER_OCCUPANT
          ].map((application) => (
            <OccupantCard
              applicant={application.applicant}
              application={application}
              key={application.applicant.userId}
            />
          ))}
        </div>
        <Button
          variant={ButtonVariant.outlined}
          leftSection={<IconPlus />}
          onClick={() =>
            showForm({
              formType: "occupant",
              occupantType: ApplicationApplicantTypeEnum.OTHER_OCCUPANT,
            })
          }>
          Add
        </Button>
        {/* Guarantors section */}
        <Group spacing={Spacing.sm} align="center">
          <H3>Guarantors</H3>
          <Tooltip label={guarantorsTooltip}>
            <IconInfoCircle size={24} color="var(--color-primary-500)" />
          </Tooltip>
        </Group>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          {applicationsByType?.[ApplicationApplicantTypeEnum.GUARANTOR].map(
            (application) => (
              <OccupantCard
                applicant={application.applicant}
                application={application}
                key={application.applicant.userId}
              />
            ),
          )}
        </div>
        <Button
          variant={ButtonVariant.outlined}
          leftSection={<IconPlus />}
          onClick={() =>
            showForm({
              formType: "occupant",
              occupantType: ApplicationApplicantTypeEnum.GUARANTOR,
            })
          }>
          Add
        </Button>
        {/* Pets section */}
        <Group spacing={Spacing.sm} align="center">
          <H3>Pets</H3>
          <Tooltip label={petsTooltip}>
            <IconInfoCircle size={24} color="var(--color-primary-500)" />
          </Tooltip>
        </Group>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          {pets?.map((pet) => (
            <PetCard
              pet={pet}
              key={pet.id}
              onDelete={() => handleDeletePet(pet.id)}
            />
          ))}
        </div>
        {P.isNotNullable(user?.userId) && (
          <Button
            variant={ButtonVariant.outlined}
            leftSection={<IconPlus />}
            onClick={() => showForm({ formType: "pet", ownerId: user.userId })}>
            Add
          </Button>
        )}
        <H3>Vehicles</H3>
        {A.isNonEmptyArray(vehicles) && (
          <div className={commonStyles.spanFullWidth}>
            <Grid>
              {vehicles.map((vehicle) => (
                <VehicleCard
                  vehicle={vehicle}
                  key={vehicle.id}
                  onEditClick={() => {
                    setEditingVehicle(vehicle);
                    vehicleModalOpenHandlers.setTrue();
                  }}
                  isWorking={isDeletingVehicle}
                  isDeletable={vehicle.ownerId === user?.userId}
                  onDeleteClick={async () => {
                    await deleteVehicle({ vehicleId: vehicle.id });
                    refresh();
                  }}
                />
              ))}
            </Grid>
          </div>
        )}
        <Button
          variant={ButtonVariant.outlined}
          leftSection={<IconPlus />}
          onClick={() => {
            vehicleModalOpenHandlers.setTrue();
            setEditingVehicle(UNDEFINED);
          }}>
          Add
        </Button>
      </div>
      <div className={commonStyles.backButton}>
        <Button
          variant={ButtonVariant.transparent}
          leftSection={<IconChevronLeft size={16} />}
          onClick={onRequestPrevious}>
          Back
        </Button>
      </div>
      <div className={commonStyles.submitButton}>
        <Button onClick={onRequestNext}>Save & Continue</Button>
      </div>
      <Modal
        title=""
        size={ModalSize.md}
        fullscreen={!isDesktop}
        opened={modalOpen}
        onClose={modalOpenHandlers.setFalse}>
        {whichForm.formType === "occupant" && (
          <AddOrEditApplicant
            applicant={whichForm.occupant}
            applicantType={whichForm.occupantType}
            onSubmit={handleAddOrEditOccupant}
          />
        )}
        {whichForm.formType === "pet" && (
          <AddEditPetForm
            pet={whichForm.pet}
            ownerId={whichForm.ownerId}
            onSuccess={() => {
              showSuccessNotification({ message: "Pet saved successfully" });
              refresh();
              modalOpenHandlers.setFalse();
            }}
            onCancel={modalOpenHandlers.setFalse}
          />
        )}
      </Modal>
      <Modal
        title={P.isNotNullable(editingVehicle) ? "Edit Vehicle" : "Add Vehicle"}
        size={ModalSize.md}
        opened={vehicleModalOpen}
        onClose={vehicleModalOpenHandlers.setFalse}
        fullscreen={!isDesktop}>
        <Stack>
          {P.isNotNullable(editingVehicle) ? (
            <ConnectedEditVehicleForm
              vehicle={editingVehicle}
              onCancel={vehicleModalOpenHandlers.setFalse}
              onSuccess={() => {
                vehicleModalOpenHandlers.setFalse();
                refresh();
              }}
            />
          ) : (
            <>
              {P.isNotNullable(user?.userId) && (
                <ConnectedAddVehicleForm
                  ownerId={user?.userId}
                  onSuccess={() => {
                    vehicleModalOpenHandlers.setFalse();
                    refresh();
                  }}
                  onCancel={vehicleModalOpenHandlers.setFalse}
                />
              )}
            </>
          )}
        </Stack>
      </Modal>
    </div>
  );
}

export { ApplyStepOccupants, OccupantCard, PetCard };
