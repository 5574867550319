/**
 * Ender-only route for redriving accounting for a tenant payment whose accounting has been deleted or was never added
 (e.g. an imported payment).
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/rerunAccountingForPayment
 * @function rerunAccountingForPayment
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPIRerunAccountingForPaymentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LedgerEventLedgerEventType } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPIRerunAccountingForPaymentPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPIRerunAccountingForPaymentSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPIRerunAccountingForPaymentBodyParams = {
  modelId: EnderId;
  modelType: LedgerEventLedgerEventType;
};

type TenantLedgerAPIRerunAccountingForPaymentPayload =
  TenantLedgerAPIRerunAccountingForPaymentPathParams &
    TenantLedgerAPIRerunAccountingForPaymentSearchParams &
    TenantLedgerAPIRerunAccountingForPaymentBodyParams;

function rerunAccountingForPaymentUnsafeEffect(
  payload: TenantLedgerAPIRerunAccountingForPaymentPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/rerunAccountingForPayment`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function rerunAccountingForPayment(
  payload: TenantLedgerAPIRerunAccountingForPaymentPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    rerunAccountingForPaymentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { rerunAccountingForPayment };
export type {
  TenantLedgerAPIRerunAccountingForPaymentBodyParams,
  TenantLedgerAPIRerunAccountingForPaymentPathParams,
  TenantLedgerAPIRerunAccountingForPaymentPayload,
  TenantLedgerAPIRerunAccountingForPaymentSearchParams,
};
