import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import { useContext, useEffect } from "react";

import { Form, useForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { DealStatusPipelineStatus } from "@ender/shared/generated/com.ender.buy.model.misc";
import { DealStatusPipelineStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";
import { EnderAPI } from "@ender/shared/generated/ender.api.misc";
// useAsync removed as it's being replaced with useMutation
import { RightRail } from "@ender/shared/ui/right-rail";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { UnderwritingPageContext } from "../underwriting-queue-context/underwriting-page-context";

const NoBidFormSchema = Schema.Struct({
  terminationReason: Schema.String.pipe(
    Schema.OptionFromSelf,
    Schema.filter((val) => P.isNotNullable(O.getOrUndefined(val)), {
      message: () => "Reason is required",
    }),
  ),
});

type NoBidFormInput = Schema.Schema.Encoded<typeof NoBidFormSchema>;
type NoBidFormOutput = Schema.Schema.Type<typeof NoBidFormSchema>;

type NoBidRightRailProps = {
  opened: boolean;
  onClose: () => void;
};

function NoBidRightRail({ opened, onClose }: NoBidRightRailProps) {
  const { deal, dealId, gotoNextUnderwriting, setOpenedRightRail } = useContext(
    UnderwritingPageContext,
  );

  const { data: terminationReasons = [] } = useQuery({
    queryKey: ["EnderAPI.getEnumValues", "TerminationReason"] as const,
    queryFn: async ({ signal }) => {
      return EnderAPI.getEnumValues(
        {
          enumName: "TerminationReason",
        },
        { signal },
      );
    },
    select: (data) =>
      data
        .map(({ id, name }) => ({ label: name, value: id }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    staleTime: Number.MAX_SAFE_INTEGER,
  });

  const form = useForm<NoBidFormInput>({
    defaultValues: {
      terminationReason: O.none(),
    },
    resolver: effectTsResolver(NoBidFormSchema),
  });

  const { reset } = form;

  // lazy useEffect
  useEffect(() => {
    reset();
  }, [opened, reset]);

  // lazy useEffect
  useEffect(() => {
    onClose();
    reset();
  }, [dealId, onClose, reset]);

  const { mutateAsync: handleSubmit, isLoading } = useMutation({
    mutationKey: ["BuyAPI.setNoBidStatus"] as const,
    mutationFn: async (value: NoBidFormOutput) => {
      const newStatus: DealStatusPipelineStatus =
        deal?.status?.pipelineStatus?.id === DealStatusPipelineStatusEnum.LEAD
          ? DealStatusPipelineStatusEnum.ON_MARKET
          : DealStatusPipelineStatusEnum.TERMINATED;
      const terminationReason = O.getOrUndefined(value.terminationReason);

      await BuyAPI.setStatus({
        dealId,
        statusName: "pipelineStatus",
        statusValue: newStatus,
      });
      await BuyAPI.updateDeal({
        dealId,
        underwriterId: "" as EnderId,
        json: { terminationReason },
      });

      showSuccessNotification({
        message: `Property moved to ${newStatus === DealStatusPipelineStatusEnum.ON_MARKET ? "On Market" : "Terminated"} Status`,
      });
      gotoNextUnderwriting();
      setOpenedRightRail(undefined);
    },
  });

  return (
    <RightRail opened={opened} onClose={onClose} title="No Bid Reason">
      <Form form={form} onSubmit={(data) => handleSubmit(data)}>
        <Stack spacing={Spacing.sm}>
          <FormSelect
            label="Reason"
            name="terminationReason"
            data={terminationReasons || []}
            form={form}
          />
          <Group justify={Justify.end}>
            <Button type="submit" loading={isLoading}>
              Save
            </Button>
          </Group>
        </Stack>
      </Form>
    </RightRail>
  );
}

export { NoBidRightRail };
