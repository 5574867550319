import { clsx } from "clsx";
import { Function as F } from "effect";
import type { ElementRef, ReactNode } from "react";
import { forwardRef } from "react";

import { Checkbox } from "../../../../checkbox/src";

type ListCheckboxProps = {
  label?: ReactNode;
  value: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

const ListCheckbox = forwardRef<ElementRef<typeof Checkbox>, ListCheckboxProps>(
  function ListCheckbox(props, ref) {
    const { value, label, onChange = F.constVoid, disabled } = props;
    return (
      <label
        className={clsx(
          "hover:bg-primary-50 active:bg-primary-100 aria-disabled:bg-transparent",
          "text-start text-slate-900 aria-disabled:text-gray-200",
          "w-full px-4 py-1.5 flex gap-2 items-center",
        )}
        aria-disabled={disabled}>
        <Checkbox
          value={value}
          onChange={onChange}
          ref={ref}
          disabled={disabled}
        />
        {label}
      </label>
    );
  },
);

export { ListCheckbox };
export type { ListCheckboxProps };
