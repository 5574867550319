/**
 * HTTP Method: GET
 * Pathname: /bankAccountsForParty
 * @function getAccountsForParty
 * @memberof BankingAPI
 * @param {BankingAPIGetAccountsForPartyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BankAccount[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  BankAccount,
  Party,
} from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetAccountsForPartySearchParams = {
  token?: string | undefined;
  modelId: EnderId;
  party: Party;
};

type BankingAPIGetAccountsForPartyPayload =
  BankingAPIGetAccountsForPartySearchParams;

function getAccountsForPartyUnsafeEffect(
  payload: BankingAPIGetAccountsForPartyPayload,
) {
  const { token, modelId, party, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BankAccount[]>({
        body,
        decode: unsafeDecodeJsonResponse<BankAccount[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/bankAccountsForParty",
        searchParams: [
          ["token", token],
          ["modelId", modelId],
          ["party", party],
        ],
      }),
    ),
  );
}

function getAccountsForParty(
  payload: BankingAPIGetAccountsForPartyPayload,
  options?: { signal?: AbortSignal },
): Promise<BankAccount[]> {
  return F.pipe(
    payload,
    getAccountsForPartyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAccountsForParty };
export type {
  BankingAPIGetAccountsForPartyPayload,
  BankingAPIGetAccountsForPartySearchParams,
};
