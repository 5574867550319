import type { SelectItem } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import type { ForwardedRef, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { EnderIdSchema, randomEnderId } from "@ender/shared/core";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  VendorVendorStatusEnum,
  VendorVendorStatusValues,
} from "@ender/shared/generated/ender.model.core.vendor";
import { ApplicationGroupApplicationStatusValues } from "@ender/shared/generated/ender.model.leasing";
import { InvoiceInvoiceTypeValues } from "@ender/shared/generated/ender.model.payments.invoice";
import { FontSize, Text } from "@ender/shared/ds/text";
import type { InputHeight } from "@ender/shared/types/ender-general";
import {
  EmailSchema,
  InputHeightEnum,
  PhoneSchema,
} from "@ender/shared/types/ender-general";
import type { EnderAutocompleteProps } from "@ender/shared/ui/ender-autocomplete";
import { EnderAutocomplete } from "@ender/shared/ui/ender-autocomplete";
import { Soc2Hidden } from "@ender/shared/ui/soc2-hidden";
import { fail } from "@ender/shared/utils/error";
import { formatPhoneNumber } from "@ender/shared/utils/phone";
import { rest } from "@ender/shared/utils/rest";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { renderPrivateContact } from "@ender/shared/utils/user";
import { castEnum } from "@ender/shared/utils/zod";
import { Color } from "@ender/shared/utils/theming";

/**
 * See SearchService.SearchType in Java code for further explanation.  For use with the "omnisearch" API.
 *
 */
const SearchTypeValues = [
  "APPLICATION",
  "DEAL",
  "FIRM",
  "INVESTOR",
  "INVOICE",
  "JOURNAL_ENTRY",
  "LEASE",
  "MANAGER",
  "MARKET",
  "PAGE",
  "PORTFOLIO",
  "PROPERTY_SERVICE",
  "PROPERTY",
  "PROSPECT",
  "TASK",
  "TENANT",
  "UNIT",
  "VENDOR",
] as const;
const SearchTypeSchema = z.enum(SearchTypeValues);
type SearchType = z.infer<typeof SearchTypeSchema>;

const SearchTypeEnum = castEnum<SearchType>(SearchTypeSchema);

const SearchResultTypeValues = [
  ...SearchTypeValues,
  // INVOICE search type is returned as the InvoiceType Enum,
  ...InvoiceInvoiceTypeValues,
] as const;
const SearchResultTypeSchema = z
  .enum(SearchResultTypeValues)
  .exclude([SearchTypeEnum.INVOICE]);
type SearchResultType = z.infer<typeof SearchResultTypeSchema>;

const SearchResultTypeEnum = castEnum<SearchResultType>(SearchResultTypeSchema);

const BaseEnderSearchResultSchema = z.object({
  friendlyId: z.string().nullish(),
  id: EnderIdSchema,
  label: z.string(),
  name: z.string(),
  type: SearchResultTypeSchema,
  url: z.string(),
  value: EnderIdSchema,
});

const DefaultEnderSearchResultSchema = BaseEnderSearchResultSchema.extend({
  type: SearchResultTypeSchema.exclude([
    SearchTypeEnum.APPLICATION,
    SearchTypeEnum.DEAL,
    SearchTypeEnum.MANAGER,
    SearchTypeEnum.PROPERTY,
    SearchTypeEnum.PROSPECT,
    SearchTypeEnum.TENANT,
    SearchTypeEnum.UNIT,
    SearchTypeEnum.VENDOR,
  ]),
});

const VendorSearchResultSchema = BaseEnderSearchResultSchema.extend({
  status: z.enum(VendorVendorStatusValues),
  type: z.literal(SearchTypeEnum.VENDOR),
});

type VendorSearchResult = z.infer<typeof VendorSearchResultSchema>;

const PropertySearchResultSchema = BaseEnderSearchResultSchema.extend({
  address: z.string(),
  propertyName: z.string(),
  type: z.literal(SearchTypeEnum.PROPERTY),
});

type PropertySearchResult = z.infer<typeof PropertySearchResultSchema>;

const UnitSearchResultSchema = PropertySearchResultSchema.extend({
  address: z.string(),
  type: z.literal(SearchTypeEnum.UNIT),
  unitName: z.string(),
});

type UnitSearchResult = z.infer<typeof UnitSearchResultSchema>;

const UserSearchResultSchema = BaseEnderSearchResultSchema.extend({
  email: EmailSchema,
  firstName: z.string(),
  lastName: z.string(),
  phone: PhoneSchema.nullish(),
});

const ManagerSearchResultSchema = UserSearchResultSchema.extend({
  type: z.literal(SearchTypeEnum.MANAGER),
});

type ManagerSearchResult = z.infer<typeof ManagerSearchResultSchema>;

const TenantSearchResultSchema = UserSearchResultSchema.merge(
  UnitSearchResultSchema,
).extend({
  type: z.literal(SearchTypeEnum.TENANT),
});

type TenantSearchResult = z.infer<typeof TenantSearchResultSchema>;

const DealSearchResultSchema = BaseEnderSearchResultSchema.extend({
  address: z.string().nullish(),
  name: z.never(),
  propertyName: z.string(),
  type: z.literal(SearchTypeEnum.DEAL),
});

type DealSearchResult = z.infer<typeof DealSearchResultSchema>;

const ApplicantSearchResultSchema = TenantSearchResultSchema.extend({
  status: z.enum(ApplicationGroupApplicationStatusValues),
  type: z.literal(SearchTypeEnum.APPLICATION),
});

type ApplicantSearchResult = z.infer<typeof ApplicantSearchResultSchema>;

const ProspectSearchResultSchema = UserSearchResultSchema.extend({
  propertyName: z.string(),
  type: z.literal(SearchTypeEnum.PROSPECT),
});

type ProspectSearchResult = z.infer<typeof ProspectSearchResultSchema>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EnderSearchResultSchema = z.discriminatedUnion("type", [
  DefaultEnderSearchResultSchema,
  ApplicantSearchResultSchema,
  DealSearchResultSchema,
  ManagerSearchResultSchema,
  PropertySearchResultSchema,
  ProspectSearchResultSchema,
  TenantSearchResultSchema,
  UnitSearchResultSchema,
  VendorSearchResultSchema,
]);

type EnderSearchResult = z.infer<typeof EnderSearchResultSchema>;

type SearchResultItemProps<T extends EnderSearchResult = EnderSearchResult> = {
  result: T;
  showType?: boolean;
  showDetails?: boolean;
};

function DefaultSearchResultItem({ result }: SearchResultItemProps) {
  return <Text>{result.name}</Text>;
}

function VendorSearchResultItem({
  result,
}: SearchResultItemProps<VendorSearchResult>) {
  return (
    <Text>
      {result.name}
      {result.status === VendorVendorStatusEnum.DEACTIVATED && " (Deactivated)"}
    </Text>
  );
}

function PropertySearchResultItem({
  result,
  showDetails,
}: SearchResultItemProps<PropertySearchResult>) {
  return (
    <>
      <Text>
        {result.name}
        {result.friendlyId && ` (${result.friendlyId})`}
      </Text>
      {showDetails && result.address && (
        <Text size={FontSize.sm} color={`${Color.slate}-500`}>
          {result.address}
        </Text>
      )}
    </>
  );
}

function UnitSearchResultItem({
  result,
  showDetails,
}: SearchResultItemProps<UnitSearchResult>) {
  return (
    <>
      <Text>{result.name}</Text>
      {showDetails && result.propertyName && (
        <Text size={FontSize.sm} color={`${Color.slate}-500`}>
          {result.propertyName}
        </Text>
      )}
    </>
  );
}

function ManagerSearchResultItem({
  result,
}: SearchResultItemProps<ManagerSearchResult>) {
  return (
    <>
      <Text>{renderPrivateContact(result)}</Text>
      {result.phone && (
        <span className="ml-2">
          <Text color={`${Color.slate}-500`}>
            {formatPhoneNumber(result.phone)}
          </Text>
        </span>
      )}
    </>
  );
}

function TenantSearchResultItem({
  result,
  showDetails,
}: SearchResultItemProps<TenantSearchResult>) {
  const { email, propertyName, unitName } = result;

  return (
    <>
      <Text>{renderPrivateContact(result)}</Text>
      {email && (
        <span className="ml-2">
          <Text
            size={FontSize.sm}
            color={`${Color.slate}-500`}>
            <Soc2Hidden>{email}</Soc2Hidden>
          </Text>
        </span>
      )}
      {result.phone && (
        <Text color={`${Color.slate}-500`}>
          {formatPhoneNumber(result.phone)}
        </Text>
      )}
      {showDetails && (propertyName || unitName) && (
        <Text size={FontSize.sm} color={`${Color.slate}-500`}>
          {propertyName}
          {propertyName && unitName && ", "}
          {unitName}
        </Text>
      )}
    </>
  );
}

function DealSearchResultItem({
  result,
  showDetails,
}: SearchResultItemProps<DealSearchResult>) {
  return (
    <>
      <Text>
        {result.propertyName}
        {result.friendlyId && ` (${result.friendlyId})`}
      </Text>
      {showDetails && result.address && (
        <Text size={FontSize.sm} color={`${Color.slate}-500`}>
          {result.address}
        </Text>
      )}
    </>
  );
}

function ApplicantSearchResultItem({
  result,
  showDetails,
}: SearchResultItemProps<ApplicantSearchResult>) {
  return (
    <>
      <Text>{renderPrivateContact(result)}</Text>
      {result.status && (
        <span className="ml-2">
          <Text
            size={FontSize.sm}
            color={`${Color.slate}-500`}>
            {convertSnakeCaseToTitleCase(result.status)}
          </Text>
        </span>
      )}
      {showDetails && result.phone && (
        <span className="ml-2">
          <Text color={`${Color.slate}-500`}>
            {formatPhoneNumber(result.phone)}
          </Text>
        </span>
      )}
      {showDetails && result.unitName && (
        <Text size={FontSize.sm} color={`${Color.slate}-500`}>
          {result.propertyName}, {result.unitName}
        </Text>
      )}
    </>
  );
}

function ProspectSearchResultItem({
  result,
  showDetails,
}: SearchResultItemProps<ProspectSearchResult>) {
  return (
    <>
      <Text>
        {result.name.trim().length > 0 ? result.name : "NO NAME"}
      </Text>
      {showDetails && (
        <>
          <Text size={FontSize.sm} color={`${Color.slate}-500`}>
            {result.propertyName}
          </Text>
        </>
      )}
    </>
  );
}

type AutocompleteItem = {
  key: string;
  value: string;
  result: EnderSearchResult;
};

function getSearchResultValue(searchResult: EnderSearchResult) {
  if (searchResult.type === SearchTypeEnum.PROSPECT) {
    // prospect is searchable by phone and email
    return `${searchResult.name} ${searchResult.phone || ""} ${searchResult.email || ""}`;
  }

  if (
    searchResult.type === SearchTypeEnum.PROPERTY &&
    searchResult.friendlyId
  ) {
    return `${searchResult.name} (${searchResult.friendlyId})`;
  }

  return searchResult.name;
}

function searchResultToAutoCompleteItem(
  searchResult: EnderSearchResult,
): AutocompleteItem {
  return {
    key: randomEnderId(),
    result: searchResult,
    value: getSearchResultValue(searchResult),
  };
}

function getSearchResultItemByType(
  result: EnderSearchResult,
  showDetails: boolean,
) {
  switch (result.type) {
    case SearchResultTypeEnum.APPLICATION:
      return (
        <ApplicantSearchResultItem result={result} showDetails={showDetails} />
      );
    case SearchResultTypeEnum.DEAL:
      return <DealSearchResultItem result={result} showDetails={showDetails} />;
    case SearchResultTypeEnum.MANAGER:
      return (
        <ManagerSearchResultItem result={result} showDetails={showDetails} />
      );
    case SearchResultTypeEnum.PROPERTY:
      return (
        <PropertySearchResultItem result={result} showDetails={showDetails} />
      );
    case SearchResultTypeEnum.PROSPECT:
      return (
        <ProspectSearchResultItem result={result} showDetails={showDetails} />
      );
    case SearchResultTypeEnum.TENANT:
      return (
        <TenantSearchResultItem result={result} showDetails={showDetails} />
      );
    case SearchResultTypeEnum.UNIT:
      return <UnitSearchResultItem result={result} showDetails={showDetails} />;
    case SearchResultTypeEnum.VENDOR:
      return (
        <VendorSearchResultItem result={result} showDetails={showDetails} />
      );
    default:
      return (
        <DefaultSearchResultItem result={result} showDetails={showDetails} />
      );
  }
}

function SearchResultItemWithRef(
  props: SearchResultItemProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { result, showDetails = true, showType = true, ...divProps } = props;

  return (
    <div ref={ref} {...divProps}>
      {showType && (
        <span className="uppercase">
          <Text size={FontSize.sm} color={`${Color.slate}-500`}>
            {result.type}
          </Text>
        </span>
      )}
      {getSearchResultItemByType(result, showDetails)}
    </div>
  );
}

const SearchResultItem = forwardRef(SearchResultItemWithRef);

type EnderSearchProps = Omit<EnderAutocompleteProps, "data"> & {
  controlledResult?: AutocompleteItem[];
  filterNewCustomField?: boolean;
  height?: InputHeight;
  initialResult?: AutocompleteItem[];
  mapFn?: (searchResult: EnderSearchResult) => AutocompleteItem;
  onSelect?: (result: AutocompleteItem) => void;
  requestParams?: object;
  requestType?: "POST" | "GET";
  resultsOnEmptySearchValue?: boolean;
  route?: string;
  showOptionDetails?: boolean;
  showOptionResultType?: boolean;
  testId?: string;
  useKeyword?: boolean;
  /**
   * warning: value will be truncated to 64 characters
   */
  value?: string;
};

function fetchSearchResults(
  requestType: "POST" | "GET",
  route: string,
  body: Record<string, unknown>,
): Promise<EnderSearchResult[]> {
  return requestType === "POST" ? rest.post(route, body) : rest.get(route);
}

/**
 * @note requires all route results to include unique name values
 * non unique values after the first will be discarded
 *
 * EnderSearch is best used in conjunction with {@link useEnderSearch} or {@link useEnderSearchForm}
 * @deprecated
 */
function EnderSearchWithRef(
  props: EnderSearchProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    className,
    clearButtonTabIndex,
    controlledResult,
    disabled,
    error,
    height = InputHeightEnum.TALL,
    initialResult,
    label,
    mapFn = searchResultToAutoCompleteItem,
    name,
    onChange = F.constVoid,
    onClear,
    onPaste,
    onSelect = F.constVoid,
    placeholder,
    requestParams = {},
    requestType = "POST",
    resultsOnEmptySearchValue = true,
    route = "/search",
    showOptionDetails = false,
    showOptionResultType = false,
    testId,
    useKeyword = false,
    value,
  } = props;

  const [hasFocused, setHasFocused] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState<AutocompleteItem>();

  // lazy useEffect
  useEffect(() => {
    if (P.isNotNullable(value) && value.length > 64) {
      console.warn(
        'The "value" prop in the Search component is longer than 64 characters and will be truncated.',
      );
    }
  }, [value]);

  const searchBody = useMemo(() => {
    if (!useKeyword) {
      return { ...requestParams, keyword: "" };
    }

    // @ts-expect-error requestParams isn't strongly typed
    const keyword = requestParams.types?.includes(ModelTypeEnum.PROPERTY)
      ? `"${value?.substring(0, 64)}"`
      : (value?.substring(0, 64) ?? "");

    return { ...requestParams, keyword };
  }, [requestParams, useKeyword, value]);

  const onItemSubmit = useCallback(
    (item: AutocompleteItem) => {
      onSelect(item);
      setLastSelectedItem(item);
    },
    [onSelect],
  );

  const onBlur = useCallback(() => {
    setHasFocused(false);

    const lastValidValue = lastSelectedItem?.value || "";
    if (value && value !== lastValidValue) {
      onChange(lastValidValue);
    }
  }, [lastSelectedItem?.value, onChange, value]);

  //Enable query only if use keyword is false or value has a length
  const showEmptyResults = !useKeyword || Boolean(value?.length);
  const shouldSearchOnEmpty = resultsOnEmptySearchValue && hasFocused;
  const queryEnabled = !disabled && showEmptyResults && shouldSearchOnEmpty;

  const { data, isFetching } = useQuery<
    EnderSearchResult[],
    unknown,
    AutocompleteItem[]
  >(
    [requestType, route, searchBody, hasFocused] as const,
    () => fetchSearchResults(requestType, route, searchBody),
    {
      enabled: queryEnabled,
      onError: fail,
      select: (result) => result.map(mapFn).filter(Boolean).flat(Infinity),
    },
  );

  const autoCompleteData = useMemo(
    () => data || controlledResult || initialResult || [],
    [controlledResult, data, initialResult],
  );

  const ItemComponent = useMemo(
    () =>
      forwardRef<HTMLDivElement, SelectItem & { result: EnderSearchResult }>(
        function ItemComponent(props, ref) {
          return (
            <SearchResultItem
              {...props}
              showDetails={showOptionDetails}
              showType={showOptionResultType}
              ref={ref}
            />
          );
        },
      ),
    [showOptionResultType, showOptionDetails],
  );

  const nothingFound = useMemo(() => {
    if (isFetching) {
      return "Loading...";
    }

    return showEmptyResults ? undefined : "";
  }, [isFetching, showEmptyResults]);

  return (
    <EnderAutocomplete
      className={className}
      clearButtonTabIndex={clearButtonTabIndex}
      data={autoCompleteData}
      disabled={disabled}
      error={error}
      height={height}
      itemComponent={ItemComponent}
      label={label}
      name={name}
      nothingFound={nothingFound}
      onBlur={onBlur}
      onChange={onChange}
      onClear={onClear}
      onFocus={() => setHasFocused(true)}
      onItemSubmit={onItemSubmit}
      onPaste={onPaste}
      placeholder={placeholder}
      ref={ref}
      value={value}
      wrapperProps={{
        "data-testid": testId,
      }}
      filter={() => true}
    />
  );
}

type EnderSearchGeneric = ((props: EnderSearchProps) => ReactNode) & {
  displayName?: string;
};

/**
 * @deprecated use entities/search-input
 */
const EnderSearch: EnderSearchGeneric = forwardRef<
  HTMLInputElement,
  EnderSearchProps
>(EnderSearchWithRef);

EnderSearch.displayName = "EnderSearch";

export {
  EnderSearch,
  SearchResultItem,
  SearchTypeEnum,
  SearchTypeSchema,
  SearchTypeValues,
};
export type { AutocompleteItem, EnderSearchResult, SearchType };
