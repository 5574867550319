import { formatWithOptions } from "date-fns/fp";
import { enUS } from "date-fns/locale";
import { Option as O, pipe } from "effect";

import { LocalDate$ } from "@ender/shared/core";

const SHORT_MONTH_YEAR_FORMAT = "MMM yyyy";

const shortMonthYearFormat = formatWithOptions(
  { locale: enUS },
  SHORT_MONTH_YEAR_FORMAT,
);

/**
 * Converts LocalDate$ to a string showing the abbreviated month and full year"
 * @returns {string} Formatted as "MMM yyyy"
 * @example "Jan 2022"
 */

function toShortMonthYearString(input: LocalDate$.LocalDate | string): string {
  const localDate = LocalDate$.parse(input);

  return pipe(
    localDate,
    O.map((date) => shortMonthYearFormat(date.toDate())),
    O.getOrThrow,
  );
}

export { toShortMonthYearString };
