import { IconBuildingCommunity } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";

import type { CustomField } from "@ender/shared/api/factors";
import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import { useOverflowCheck } from "@ender/shared/hooks/use-overflow-check";
import { FactorOutputTypeEnum } from "@ender/shared/types/custom-factors";

import { CustomFieldsMenu } from "./custom-fields-menu";

import "./custom-fields.scss";

function CustomFieldText({ value }: { value: string }) {
  const customFieldTextRef = useRef<HTMLDivElement>(NULL);
  const isOverflow = useOverflowCheck(customFieldTextRef.current);

  return (
    <Tooltip label={value} disabled={!isOverflow}>
      <div ref={customFieldTextRef}>{value}</div>
    </Tooltip>
  );
}

function FieldDisplayValue({ field }: { field: CustomField }) {
  const { value, factor } = field;
  if (!value) {
    return "-";
  }

  switch (factor.outputType) {
    case FactorOutputTypeEnum.BOOLEAN: {
      return value === "true" ? "Yes" : "No";
    }
    case FactorOutputTypeEnum.MONEY: {
      return <MoneyDisplay showSymbol value={Money$.parse(value)} />;
    }
    case FactorOutputTypeEnum.DATE: {
      return <DateDisplay value={LocalDate$.parse(value)} />;
    }
    default: {
      return <CustomFieldText value={value} />;
    }
  }
}

type CustomFieldsProps = {
  modelId: EnderId;
  modelType: ModelType;
};

function getSectionTitle(modelType: ModelType) {
  switch (modelType) {
    case ModelTypeEnum.LEASE: {
      return {
        emptyMessage: "There is no additional information for this tenant.",
        sectionTitle: "Other Info",
      };
    }
    case ModelTypeEnum.VENDOR: {
      return {
        emptyMessage: "There are no custom fields for this vendor.",
        sectionTitle: "Custom Fields",
      };
    }
    default: {
      throw new Error(
        `The value "${modelType}" is not valid for CustomFields modelType prop`,
      );
    }
  }
}

/**
 * @name CustomFields
 */
function CustomFields({ modelId, modelType }: CustomFieldsProps) {
  const { data: customFields, refetch: refetchCustomFields } = useQuery<
    CustomField[]
  >({
    queryFn: ({ signal }) =>
      FactorsAPI.getCustomFields({ modelId, modelType }, { signal }),
    queryKey: ["FactorsAPI.getCustomFields", { modelId, modelType }] as const,
  });

  const { sectionTitle, emptyMessage } = getSectionTitle(modelType);

  if (!customFields) {
    return <></>;
  }

  return (
    <Stack>
      <Group justify={Justify.between}>
        <Group spacing={Spacing.sm}>
          <IconBuildingCommunity size={24} color="var(--color-slate-600)" />
          <H2>{sectionTitle}</H2>
        </Group>
        <CustomFieldsMenu
          customFields={customFields}
          modelId={modelId}
          modelType={modelType}
          refetchCustomFields={refetchCustomFields}
        />
      </Group>
      {customFields.length === 0 && (
        <Text size={FontSize.md}>{emptyMessage}</Text>
      )}

      {customFields.map((customField) => (
        <Tuple
          key={customField.factor.id}
          label={customField.factor.name}
          value={<FieldDisplayValue field={customField} />}
        />
      ))}
    </Stack>
  );
}

export { CustomFields };
