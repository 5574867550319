import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Option as O, pipe } from "effect";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { BuyAPI, MarketsAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { Market } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  DealChannelEnum,
  DealChannelValues,
  DealOccupancyStatusValues,
  DealPoolTypeValues,
} from "@ender/shared/generated/com.ender.buy.model.misc";
import { PropertyHomeTypeValues } from "@ender/shared/generated/ender.model.core.property";
import { useDocumentTitle } from "@ender/shared/hooks/use-document-title";
import { useSetHeader } from "@ender/shared/hooks/use-set-header";
import { StateAbbreviationValues } from "@ender/shared/types/branded/state";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

import { DISTRICT_RATINGS } from "./buy-app-utils";

import styles from "./add-home.module.css";

const poolTypes = [
  { label: "None", value: "NONE" },
  { label: "Community", value: "COMMUNITY" },
  { label: "Private", value: "PRIVATE" },
  { label: "Above Ground", value: "ABOVE_GROUND" },
  { label: "In Ground", value: "IN_GROUND" },
  { label: "Both", value: "BOTH" },
  { label: "Unknown", value: "UNKNOWN" },
];

const propertyTypes = [
  { label: "Apartment", value: "APARTMENT" },
  { label: "Duplex", value: "DUPLEX" },
  { label: "Manufactured Home", value: "MANUFACTURED_HOME" },
  { label: "Mobile Home", value: "MOBILE_HOME" },
  { label: "Quadruplex", value: "QUADRUPLEX" },
  { label: "Triplex", value: "TRIPLEX" },
  { label: "Townhouse", value: "TOWNHOUSE" },
  { label: "Undeveloped Land", value: "UNDEVELOPED_LAND" },
  { label: "Paper Lot", value: "PAPER_LOT" },
  { label: "Single Family Detached", value: "SINGLE_FAMILY_DETACHED" },
  { label: "Community Homes", value: "COMMUNITY_HOME" },
];

const channelTypes = [
  { label: "MLS", value: DealChannelEnum.MLS },
  { label: "Off Market", value: DealChannelEnum.OFF_MARKET },
  { label: "Bulk", value: DealChannelEnum.BULK },
  { label: "Build to Rent", value: DealChannelEnum.BUILD_TO_RENT },
];

const headerConfig = {
  breadcrumbs: [
    { href: "/buy", title: "Buy Properties" },
    { title: "Add Deal" },
  ],
};

const AddDealFormSchema = Schema.Struct({
  assessedValue: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  channel: Schema.Literal(...DealChannelValues).pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Channel is required" }),
  ),
  city: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "City is required" }),
  ),
  county: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "County is required" }),
  ),
  currentListPrice: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  inPlaceRent: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  levels: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Levels is required" }),
  ),
  lotSqft: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Lot Sq Ft is required" }),
  ),
  marketId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Market is required" }),
  ),
  medianIncome: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  notes: Schema.String,
  numBaths: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Beds is required" }),
  ),
  numBeds: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Baths is required" }),
  ),
  occupancyStatus: Schema.Literal(...DealOccupancyStatusValues).pipe(
    Schema.OptionFromSelf,
  ),
  parkingInfo: Schema.String,
  poolType: Schema.Literal(...DealPoolTypeValues).pipe(Schema.OptionFromSelf),
  schoolRating: Schema.Literal(
    ...DISTRICT_RATINGS.map(({ rating }) => rating),
  ).pipe(Schema.OptionFromSelf),
  sqft: Schema.Number.pipe(Schema.OptionFromSelf),
  state: Schema.Literal(...StateAbbreviationValues).pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "State is required" }),
  ),
  street: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Street is required" }),
  ),
  type: Schema.Literal(...PropertyHomeTypeValues).pipe(Schema.OptionFromSelf),
  unit: Schema.String,
  yearBuilt: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Year Built is required" }),
  ),
  zipcode: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Zip Code is required" }),
  ),
}).pipe(
  Schema.filter((values) => {
    if (
      O.isSome(values.lotSqft) &&
      O.isSome(values.sqft) &&
      values.sqft.value > values.lotSqft.value
    ) {
      return {
        message: "Lot Sq Ft must be greater than or equal to Sq Ft",
        path: ["lotSqft"],
      };
    }
  }),
);

type AddDealFormValues = Schema.Schema.Type<typeof AddDealFormSchema>;

function AddDeal() {
  const confirmation = useConfirmationContext();
  const { data: markets } = useQuery({
    initialData: [],
    queryFn: ({ signal }) => MarketsAPI.getMarkets({}, { signal }),
    queryKey: ["MarketsAPI.getMarkets"] as const,
  });
  const marketOptions = markets.map((market: Market) => ({
    label: market.name,
    value: market.id,
  }));

  const history = useHistory();

  useSetHeader(headerConfig);
  useDocumentTitle("Add Deal - Ender");

  const form = useEffectSchemaForm({
    defaultValues: {
      assessedValue: O.none(),
      channel: O.none(),
      city: "",
      county: "",
      currentListPrice: O.none(),
      inPlaceRent: O.none(),
      levels: O.none(),
      lotSqft: O.none(),
      marketId: O.none(),
      medianIncome: O.none(),
      notes: "",
      numBaths: O.none(),
      numBeds: O.none(),
      occupancyStatus: O.none(),
      parkingInfo: "",
      poolType: O.none(),
      schoolRating: O.none(),
      sqft: O.none(),
      state: O.none(),
      street: "",
      type: O.none(),
      unit: "",
      yearBuilt: O.none(),
      zipcode: "",
    },
    schema: AddDealFormSchema,
  });

  const addDealWithWarnings = withWarningHandler(BuyAPI.addDeal, (warnings) =>
    confirmation(
      {
        content: warnings.join("\n"),
        title: "Warning!",
      },
      { confirmButtonProps: { color: Color.red } },
    ),
  );

  const { isLoading: isSubmitting, mutateAsync: addDeal } = useMutation({
    mutationFn: async (values: AddDealFormValues) => {
      await addDealWithWarnings({
        ...values,
        assessedValue: pipe(
          values.assessedValue,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        channel: pipe(values.channel, O.getOrThrow),
        city: values.city,
        county: values.county,
        currentListPrice: pipe(
          values.currentListPrice,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        dealName: "",
        inPlaceRent: pipe(
          values.inPlaceRent,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        levels: pipe(values.levels, O.getOrThrow),
        lotSqft: pipe(values.lotSqft, O.getOrThrow),
        marketId: pipe(values.marketId, O.getOrThrow),
        medianIncome: pipe(
          values.medianIncome,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        mlsId: "",
        notes: values.notes,
        numBaths: pipe(values.numBaths, O.getOrUndefined),
        numBeds: pipe(values.numBeds, O.getOrUndefined),
        occupancyStatus: pipe(values.occupancyStatus, O.getOrUndefined),
        parkingInfo: values.parkingInfo,
        poolType: pipe(values.poolType, O.getOrUndefined),
        schoolRating: pipe(values.schoolRating, O.getOrUndefined),
        sqft: pipe(values.sqft, O.getOrUndefined),
        state: pipe(values.state, O.getOrThrow),
        street: values.street,
        type: pipe(values.type, O.getOrUndefined),
        unit: values.unit,
        yearBuilt: pipe(values.yearBuilt, O.getOrThrow),
        zipcode: values.zipcode,
      });
      showSuccessNotification({ message: "Property Added" });
      history.push("/buy");
    },
    mutationKey: ["BuyAPI.addDeal"] as const,
  });

  const onSubmit = useCallback(
    async (values: AddDealFormValues) => {
      await addDeal(values);
    },
    [addDeal],
  );

  return (
    <Form onSubmit={onSubmit} form={form}>
      <Group>
        <div>
          <div className={styles.columnHeader}>Property Details</div>
          <div className={styles.addHomeColumn}>
            <Stack spacing={Spacing.sm}>
              <FormTextInput label="Address" name="street" form={form} />
              <FormTextInput label="Unit" name="unit" form={form} />
              <FormTextInput label="City" name="city" form={form} />
              <FormSelect
                label="State"
                name="state"
                data={StateAbbreviationValues.map((state) => ({
                  label: state,
                  value: state,
                }))}
                form={form}
              />
              <FormTextInput label="Zip Code" name="zipcode" form={form} />
              <FormTextInput label="County" name="county" form={form} />
              <FormSelect
                label="Property Type"
                name="type"
                form={form}
                data={propertyTypes}
                clearable
              />
              <FormNumberInput
                label="Beds"
                name="numBeds"
                form={form}
                precision={0}
              />
              <FormNumberInput
                label="Baths"
                name="numBaths"
                form={form}
                step={0.5}
                precision={1}
              />
              <FormNumberInput
                label="Sq Ft"
                name="sqft"
                form={form}
                precision={0}
              />
              <FormNumberInput
                label="Lot Sq Ft"
                name="lotSqft"
                form={form}
                precision={0}
              />
              <FormNumberInput
                label="Year Built"
                name="yearBuilt"
                form={form}
                precision={0}
              />
              <FormSelect
                label="Pool"
                name="poolType"
                form={form}
                data={poolTypes}
                clearable
              />
              <FormTextInput
                label="Parking Info"
                name="parkingInfo"
                form={form}
              />
              <FormNumberInput
                label="Levels"
                name="levels"
                form={form}
                precision={0}
              />
              <FormSelect
                label="Occupancy Status"
                name="occupancyStatus"
                form={form}
                data={DealOccupancyStatusValues.map((status) => ({
                  label: convertSnakeCaseToTitleCase(status),
                  value: status,
                }))}
                clearable
              />
              <FormMoneyInput
                label="In Place Rent"
                name="inPlaceRent"
                form={form}
              />
              <FormMoneyInput
                label="Median Income"
                name="medianIncome"
                form={form}
              />
              <FormMoneyInput
                label="Assessed Value"
                name="assessedValue"
                form={form}
              />
              <FormSelect
                label="School Score"
                name="schoolRating"
                form={form}
                data={DISTRICT_RATINGS.map(({ rating }) => ({
                  label: rating,
                  value: rating,
                }))}
                clearable
              />
            </Stack>
          </div>
        </div>

        <div>
          <div className={styles.columnHeader}>Deal Source</div>
          <div className={styles.addHomeColumn}>
            <Stack spacing={Spacing.sm}>
              <FormSelect
                label="Market"
                name="marketId"
                form={form}
                data={marketOptions}
              />
              <FormSelect
                label="Channel"
                name="channel"
                form={form}
                data={channelTypes}
              />
              <FormMoneyInput
                label="Current List Price"
                name="currentListPrice"
                form={form}
              />
            </Stack>
          </div>
        </div>
      </Group>
      <Button type="submit" loading={isSubmitting}>
        Save Deal
      </Button>
    </Form>
  );
}

export { AddDeal };
