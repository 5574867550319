import type { ComponentType, LazyExoticComponent } from "react";
import { lazy } from "react";

function lazyImport<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [name: string]: ComponentType<any> },
  K extends keyof T,
>(factory: () => Promise<T>, exportName: K): LazyExoticComponent<T[K]> {
  return lazy(async () => {
    const module = await factory();
    return { default: module[exportName] };
  });
}

export { lazyImport };
