import { Boolean as B, Option as O, flow } from "effect";
import { z } from "zod";

import { Money$ } from "@ender/shared/core";
import { EmailSchema, PhoneSchema } from "@ender/shared/types/ender-general";
import {
  CurrencySchema,
  LocalDateZodSchema,
  OptionSchema,
} from "@ender/shared/utils/zod";

const NewLeaseFormDepositSchema = z.object({
  amount: OptionSchema(CurrencySchema).refine(
    flow(
      O.exists(Money$.isNegative),
      B.not,
      //expected: if value is non-negative or doesn't exist, return true
    ),
    { message: "Amount cannot be negative" },
  ),
  glCategory: z.string(),
});

type NewLeaseFormDepositInput = z.input<typeof NewLeaseFormDepositSchema>;
type NewLeaseFormDepositOutput = z.output<typeof NewLeaseFormDepositSchema>;

const NewLeaseFormTenantsSchema = z.object({
  tags: z
    .object({
      financiallyResponsible: z.boolean().nullish(),
      occupant: z.boolean().nullish(),
      primaryLeaseContact: z.boolean().nullish(),
    })
    .refine(
      ({ financiallyResponsible, occupant, primaryLeaseContact }) =>
        financiallyResponsible || occupant || primaryLeaseContact,
      { message: "At least one tag must be selected" },
    ),
  firstName: z.string().min(1, "First Name is required"),
  lastName: z.string().min(1, "Last Name is required"),
  dateOfBirth: OptionSchema(LocalDateZodSchema).refine(O.isSome, {
    message: "Date of Birth is required",
  }),
  email: EmailSchema.nullish(),
  phone: PhoneSchema.nullish(),
  driverLicenseNumber: z.string().nullish(),
});

type NewLeaseFormTenantsInput = z.input<typeof NewLeaseFormTenantsSchema>;
type NewLeaseFormTenantsOutput = z.output<typeof NewLeaseFormTenantsSchema>;

const NewLeaseFormSchema = z.object({
  startDate: OptionSchema(LocalDateZodSchema).refine(O.isSome, {
    message: "Start Date is required",
  }),
  endDate: OptionSchema(LocalDateZodSchema),
  monthToMonth: z.boolean(),
  expectedMoveOutDate: OptionSchema(LocalDateZodSchema),
  lateFee: OptionSchema(CurrencySchema),
  graceDays: z.number().int().nullish(),
  achReversalFee: OptionSchema(CurrencySchema),
  deposits: z.record(z.string(), NewLeaseFormDepositSchema),
  depositsHeldExternally: z.boolean(),
  tenants: NewLeaseFormTenantsSchema.array().nonempty(
    "Must have at least one tenant",
  ),
});

type NewLeaseFormInput = z.input<typeof NewLeaseFormSchema>;
type NewLeaseFormOutput = z.output<typeof NewLeaseFormSchema>;

export {
  NewLeaseFormDepositSchema,
  NewLeaseFormSchema,
  NewLeaseFormTenantsSchema,
};
export type {
  NewLeaseFormDepositInput,
  NewLeaseFormDepositOutput,
  NewLeaseFormInput,
  NewLeaseFormOutput,
  NewLeaseFormTenantsInput,
  NewLeaseFormTenantsOutput,
};
