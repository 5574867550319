/**
 * HTTP Method: GET
 * Pathname: /cbsas
 * @function getCBSAs
 * @memberof MarketsAPI
 * @param {MarketsAPIGetCBSAsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarketsAPIGetCBSAsResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MarketsAPIGetCBSAsResponse } from "../markets-api-get-c-b-s-as-response";

type MarketsAPIGetCBSAsSearchParams = {
  token?: string | undefined;
};

type MarketsAPIGetCBSAsPayload = MarketsAPIGetCBSAsSearchParams;

function getCBSAsUnsafeEffect(payload: MarketsAPIGetCBSAsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarketsAPIGetCBSAsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<MarketsAPIGetCBSAsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/cbsas",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCBSAs(
  payload: MarketsAPIGetCBSAsPayload,
  options?: { signal?: AbortSignal },
): Promise<MarketsAPIGetCBSAsResponse> {
  return F.pipe(
    payload,
    getCBSAsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCBSAs };
export type { MarketsAPIGetCBSAsPayload, MarketsAPIGetCBSAsSearchParams };
