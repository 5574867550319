/**
 * HTTP Method: POST
 * Pathname: /prospects/search
 * @function searchProspects
 * @memberof LeasingAPI
 * @param {LeasingAPISearchProspectsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchProspectsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { SearchProspectsRequest } from "@ender/shared/generated/ender.api.leasing.request";
import type { SearchProspectsResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISearchProspectsSearchParams = {
  token?: string | undefined;
};

type LeasingAPISearchProspectsBodyParams = {
  request: SearchProspectsRequest;
};

type LeasingAPISearchProspectsPayload = LeasingAPISearchProspectsSearchParams &
  LeasingAPISearchProspectsBodyParams;

function searchProspectsUnsafeEffect(
  payload: LeasingAPISearchProspectsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchProspectsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<SearchProspectsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/prospects/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchProspects(
  payload: LeasingAPISearchProspectsPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchProspectsResponse> {
  return F.pipe(
    payload,
    searchProspectsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchProspects };
export type {
  LeasingAPISearchProspectsBodyParams,
  LeasingAPISearchProspectsPayload,
  LeasingAPISearchProspectsSearchParams,
};
