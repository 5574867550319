import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P } from "effect";
import { useMemo, useState } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { RadioGroupData } from "@ender/shared/ds/radio-group";
import { RadioGroup } from "@ender/shared/ds/radio-group";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { LeaseIntention } from "@ender/shared/generated/ender.model.leasing";
import { LeaseIntentionEnum } from "@ender/shared/generated/ender.model.leasing";
import { fail } from "@ender/shared/utils/error";

import type { LeaseActionProps, LeaseActionType } from "./lease-actions.types";

const LeaseAction: LeaseActionType = () => {
  return <></>;
};

/* This user experience is strange overall. Update Lease Intention occurs for a user, the right rail opens, and there are these three radios. 
A user clicks a radio, and then the api call is made.  The user has no idea what's happening.  They must then manually close the right rail.

*/
function LeaseIntention_({ lease, onSuccess = F.constVoid }: LeaseActionProps) {
  const confirmation = useConfirmationContext();
  const { mutate } = useMutation({
    mutationKey: ["LeasingAPI.updateLease.intention"] as const,
    mutationFn: async ({
      intention,
      allowOnlinePayments,
    }: {
      intention: LeaseIntention;
      allowOnlinePayments: boolean;
    }) => {
      await LeasingAPI.updateLease({
        allowOnlinePayments,
        intention,
        leaseId: lease.id,
      });
    },
    onSuccess: () => {
      onSuccess();
    },
  });

  const [radioValue, setRadioValue] = useState<O.Option<LeaseIntention>>(
    O.fromNullable(lease.intention),
  );

  const onChangeFn = async (intention: O.Option<LeaseIntention>) => {
    setRadioValue(intention);
    if (P.isNullable(lease) || O.isNone(intention) || O.isNone(radioValue)) {
      return;
    }

    if (lease.intention === LeaseIntentionEnum.EVICT) {
      try {
        await confirmation({
          cancelButtonLabel: "No",
          confirmButtonLabel: "Yes",
          content:
            "Would you like to re-enable electronic payments? Tenants will receive an email informing them that they now have the ability to pay through their Ender portal.",
          title: "Enable Tenant Electronic Payments?",
        });
        mutate({
          allowOnlinePayments: true,
          intention: O.getOrThrow(intention),
        });
      } catch (e) {
        fail(e);
      }
    } else {
      if (O.getOrUndefined(intention) === LeaseIntentionEnum.EVICT) {
        mutate({
          allowOnlinePayments: false,
          intention: O.getOrThrow(intention),
        });
      } else {
        mutate({
          allowOnlinePayments: lease.allowOnlinePayments,
          intention: O.getOrThrow(intention),
        });
      }
    }
  };

  const availableIntentions: RadioGroupData<LeaseIntention>[] = useMemo(
    () => [
      { label: "Intend to renew", value: LeaseIntentionEnum.FRIENDLY },
      { label: "Intend to not renew", value: LeaseIntentionEnum.NO_RENEW },
      { label: "Intend to evict", value: LeaseIntentionEnum.INTEND_TO_EVICT },
      { label: "In evictions", value: LeaseIntentionEnum.EVICT },
    ],
    [],
  );

  return (
    <RadioGroup
      value={radioValue}
      onChange={onChangeFn}
      data={availableIntentions}
    />
  );
}

LeaseAction.displayName = "Lease Action";
LeaseAction.Intention = LeaseIntention_;

export { LeaseAction };
