/**
 * HTTP Method: POST
 * Pathname: /getRecurringGLJournalEntries
 * @function getRecurringGLJournalEntries
 * @memberof AccountingAPI
 * @param {AccountingAPIGetRecurringGLJournalEntriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetRecurringGLJournalEntriesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetRecurringGLJournalEntriesResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIGetRecurringGLJournalEntriesSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetRecurringGLJournalEntriesBodyParams = {
  approvalStatus?: ApprovableApprovalStatus | undefined;
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
};

type AccountingAPIGetRecurringGLJournalEntriesPayload =
  AccountingAPIGetRecurringGLJournalEntriesSearchParams &
    AccountingAPIGetRecurringGLJournalEntriesBodyParams;

function getRecurringGLJournalEntriesUnsafeEffect(
  payload: AccountingAPIGetRecurringGLJournalEntriesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetRecurringGLJournalEntriesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetRecurringGLJournalEntriesResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getRecurringGLJournalEntries",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRecurringGLJournalEntries(
  payload: AccountingAPIGetRecurringGLJournalEntriesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetRecurringGLJournalEntriesResponse> {
  return F.pipe(
    payload,
    getRecurringGLJournalEntriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRecurringGLJournalEntries };
export type {
  AccountingAPIGetRecurringGLJournalEntriesBodyParams,
  AccountingAPIGetRecurringGLJournalEntriesPayload,
  AccountingAPIGetRecurringGLJournalEntriesSearchParams,
};
