// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { AutocompleteItem } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import {
  Autocomplete,
  ScrollArea,
  useComponentDefaultProps,
} from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";
import { Function as F } from "effect";
import type { ComponentProps, ForwardedRef } from "react";
import { forwardRef, useMemo } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import type { InputHeight } from "@ender/shared/types/ender-general";
import {
  InputHeightEnum,
  generateInputHeight,
} from "@ender/shared/types/ender-general";

type EnderAutocompleteProps = Omit<
  ComponentProps<typeof Autocomplete>,
  "limit" | "hoverOnSearchChange" | "onSelect"
> & {
  onClear?: () => void;
  clearButtonTabIndex?: number;
  height?: InputHeight;
};

const defaultProps = {
  data: [],
  dropdownComponent: ScrollArea,
  height: InputHeightEnum.TALL,
  hoverOnSearchChange: true,
  icon: <IconSearch color={EnderThemeColorEnum.SLATE_600} />,
  maxDropdownHeight: "40vh",
  nothingFound: "No Results",
  onClear: F.constVoid,
  onChange: F.constVoid,
} as const;

function EnderAutocompleteWithRef(
  props: EnderAutocompleteProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    onClear,
    itemComponent: _itemComponent,
    clearButtonTabIndex,
    data,
    height,
    ...autocompleteProps
  } = useComponentDefaultProps("EnderAutocomplete", defaultProps, props);
  const firstItem = data[0];

  const itemKeys = useMemo<string[]>(() => {
    return typeof firstItem === "object" ? Object.keys(firstItem) : [];
  }, [firstItem]);

  const itemComponent = useMemo(() => {
    if (_itemComponent) {
      return _itemComponent;
    }

    return forwardRef<HTMLDivElement, AutocompleteItem>(
      function DefaultEnderAutocomplete(itemProps, ref) {
        /* Mantine passes all of the event functions like onClick and onMouseOver as props.
         * Mantine also passes all of the properties on each data item as props.
         * We need to remove the data props and leave only the html event handle props.
         * https://mantine.dev/core/autocomplete/#custom-item-component
         */
        const { value, label, ...others } = itemProps;
        itemKeys.forEach((key) => delete others[key]);

        return (
          <div ref={ref} {...others}>
            {label || value}
          </div>
        );
      },
    );
  }, [itemKeys, _itemComponent]);

  const ClearIcon = useMemo(
    () =>
      autocompleteProps.value ? (
        <Button
          variant={ButtonVariant.transparent}
          type="button"
          disabled={autocompleteProps.disabled}
          onClick={onClear}>
          <IconX />
        </Button>
      ) : (
        <></>
      ),
    [autocompleteProps.disabled, autocompleteProps.value, onClear],
  );

  return (
    <Autocomplete
      {...autocompleteProps}
      data={data}
      itemComponent={itemComponent}
      limit={data.length}
      ref={ref}
      rightSection={ClearIcon}
      styles={generateInputHeight(height)}
    />
  );
}

/**
 * @deprecated use Select from @ender/shared/ds/select
 */
const EnderAutocomplete = forwardRef(EnderAutocompleteWithRef);

export { EnderAutocomplete };
export type { EnderAutocompleteProps };
