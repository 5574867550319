/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}/zones
 * @function createZone
 * @memberof InspectionAPI
 * @param {InspectionAPICreateZonePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionZoneResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionZoneResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPICreateZonePathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
};

type InspectionAPICreateZoneSearchParams = {
  token?: string | undefined;
};

type InspectionAPICreateZoneBodyParams = {
  description: string;
  templateZoneId: EnderId;
};

type InspectionAPICreateZonePayload = InspectionAPICreateZonePathParams &
  InspectionAPICreateZoneSearchParams &
  InspectionAPICreateZoneBodyParams;

function createZoneUnsafeEffect(payload: InspectionAPICreateZonePayload) {
  const { inspectionId, areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionZoneResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionZoneResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}/zones`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createZone(
  payload: InspectionAPICreateZonePayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionZoneResponse> {
  return F.pipe(
    payload,
    createZoneUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createZone };
export type {
  InspectionAPICreateZoneBodyParams,
  InspectionAPICreateZonePathParams,
  InspectionAPICreateZonePayload,
  InspectionAPICreateZoneSearchParams,
};
