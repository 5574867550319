import { Schema } from "@effect/schema";
// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Indicator, Root } from "@radix-ui/react-progress";
import { cva } from "class-variance-authority";
import { forwardRef } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { ColorSchema, SizeSchema } from "@ender/shared/utils/theming";

const ProgressBarColorSchema = ColorSchema.pipe(
  Schema.pickLiteral("primary", "yellow", "green", "red", "slate"),
);
const ProgressBarColorValues = ProgressBarColorSchema.literals;
type ProgressBarColors = Schema.Schema.Type<typeof ProgressBarColorSchema>;
const ProgressBarColor = castEnum(ProgressBarColorSchema);

const ProgressBarSizeSchema = SizeSchema.pipe(
  Schema.pickLiteral("sm", "md", "lg"),
);
const ProgressBarSizeValues = ProgressBarSizeSchema.literals;
type ProgressBarSizes = Schema.Schema.Type<typeof ProgressBarSizeSchema>;
const ProgressBarSize = castEnum(ProgressBarSizeSchema);

const ProgressBarVariantGenerator = cva(["rounded-full overflow-hidden"], {
  defaultVariants: {
    color: ProgressBarColor.slate,
    size: ProgressBarSize.md,
  },
  variants: {
    color: {
      [ProgressBarColor.green]:
        "bg-green-200 [--progress-color:--color-green-500]",
      [ProgressBarColor.primary]:
        "bg-primary-200 [--progress-color:--color-primary-500]",
      [ProgressBarColor.red]: "bg-red-200 [--progress-color:--color-red-500]",
      [ProgressBarColor.slate]:
        "bg-slate-200 [--progress-color:--color-slate-500]",
      [ProgressBarColor.yellow]:
        "bg-yellow-200 [--progress-color:--color-yellow-500]",
    },
    size: {
      [ProgressBarSize.lg]: "h-4",
      [ProgressBarSize.md]: "h-2",
      [ProgressBarSize.sm]: "h-1",
    },
  },
});

type ProgressBarProps = {
  value: number;
  max: number;
  color?: ProgressBarColors;
  size?: ProgressBarSizes;
};

const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  function ProgressBar(props, ref) {
    const { value, max } = props;
    return (
      <Root
        value={value}
        max={max}
        className={ProgressBarVariantGenerator(props)}
        ref={ref}>
        <Indicator
          className="h-full rounded-full bg-[--progress-color] transition-transform"
          style={{ transform: `translateX(-${100 - (100 * value) / max}%)` }}
        />
      </Root>
    );
  },
);

export {
  ProgressBar,
  ProgressBarColor,
  ProgressBarColorSchema,
  ProgressBarColorValues,
  ProgressBarSize,
  ProgressBarSizeSchema,
  ProgressBarSizeValues,
};

export type { ProgressBarColors, ProgressBarProps, ProgressBarSizes };
