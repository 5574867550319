import type {
  GetNextPageParamFunction,
  QueryFunction,
  QueryFunctionContext,
} from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Array as A, Function as F, pipe } from "effect";
import { useCallback, useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { LeasingMiddleLayerAPISearchProspectsTablePayload } from "@ender/shared/generated/com.ender.middle";
import { LeasingMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type {
  SearchProspectsTableResponse,
  SearchProspectsTableResponseSearchProspectsTableResponseItem,
} from "@ender/shared/generated/com.ender.middle.response";
import type { ProspectSortBy } from "@ender/shared/generated/ender.api.leasing.request";
import { ProspectSortByEnum } from "@ender/shared/generated/ender.api.leasing.request";
import type { EnderTableSortingParams } from "@ender/shared/ui/table-tanstack";

import type { ProspectsListTab } from "../prospects.models";
import { ProspectsListTabEnum } from "../prospects.models";
import type { ProspectsFilterState } from "./prospects-table.types";

const PAGE_SIZE = 100;

const sortByPayloadValueMap: Record<string, ProspectSortBy> = {
  followUpTime: ProspectSortByEnum.FOLLOW_UP,
  latestMessageDateTime: ProspectSortByEnum.LAST_MESSAGE_TIME,
  marketName: ProspectSortByEnum.MARKET_NAME,
  moveInDate: ProspectSortByEnum.PROSPECT_MOVE_IN,
  propertyName: ProspectSortByEnum.PROPERTY_NAME,
  unitName: ProspectSortByEnum.UNIT_NAME,
} as const;

type SearchQueryKey = readonly [
  "LeasingMiddleLayerAPI.searchProspectsTable",
  LeasingMiddleLayerAPISearchProspectsTablePayload,
];
type SearchPageParam = Pick<
  LeasingMiddleLayerAPISearchProspectsTablePayload,
  "offset"
>;

const searchQueryFn: QueryFunction<
  SearchProspectsTableResponse,
  SearchQueryKey,
  SearchPageParam
> = (
  context: QueryFunctionContext<SearchQueryKey, SearchPageParam | undefined>,
): Promise<SearchProspectsTableResponse> => {
  const { queryKey, pageParam, signal } = context;
  const [, partialPayload] = queryKey;
  const { offset } = pageParam ?? {};

  return LeasingMiddleLayerAPI.searchProspectsTable(
    {
      ...partialPayload,
      limit: PAGE_SIZE,
      offset,
    },
    { signal },
  );
};

const searchQueryGetNextPageParam: GetNextPageParamFunction<
  SearchProspectsTableResponse
> = (
  lastPage: SearchProspectsTableResponse,
  allPages: SearchProspectsTableResponse[],
): SearchPageParam | undefined => {
  if (lastPage.count <= allPages.length * PAGE_SIZE) {
    return UNDEFINED;
  }
  return { offset: allPages.length * PAGE_SIZE };
};

type UseProspectsTableDataProps = {
  filterState: ProspectsFilterState;
  activeTab: ProspectsListTab;
  sorting: EnderTableSortingParams;
};

type UseProspectsTableDataReturn = {
  data: SearchProspectsTableResponseSearchProspectsTableResponseItem[];
  totalResults: number;
  isFetching: boolean;
  fetchNextPage: () => Promise<void>;
  refetch: () => Promise<void>;
};

function useProspectsTableData(
  props: UseProspectsTableDataProps,
): UseProspectsTableDataReturn {
  const { filterState, activeTab, sorting } = props;
  const {
    nameKeyword,
    markets,
    properties,
    units,
    archiveKeyword,
    showOnlyNeedsFollowUp,
    unitStatus,
  } = filterState;

  const sortProps = useMemo<{
    sortBy?: ProspectSortBy;
    sortAscending?: boolean;
  }>(() => {
    if (A.isEmptyArray(sorting.sorting)) {
      // if showOnlyNeedsFollowUp is true, we must sort by followUp (BE condition)
      return showOnlyNeedsFollowUp
        ? {
            sortBy: ProspectSortByEnum.FOLLOW_UP,
            sortAscending: true,
          }
        : {};
    }
    const { desc, id } = sorting.sorting[0];

    const sortBy = sortByPayloadValueMap[id];
    if (sortBy === ProspectSortByEnum.FOLLOW_UP && desc) {
      // Sorting by follow up in descending order is not supported
      sorting.onSortingChange([]);
      return {};
    }
    return {
      sortAscending: !desc,
      sortBy,
    };
  }, [showOnlyNeedsFollowUp, sorting]);

  const queryKey = useMemo((): SearchQueryKey => {
    const payload: LeasingMiddleLayerAPISearchProspectsTablePayload = {
      archiveKeyword: archiveKeyword ?? "",
      isFollowUpNeeded: showOnlyNeedsFollowUp,
      marketIds: markets,
      propertyIds: pipe(
        properties ?? [],
        A.map((itm) => itm.value),
      ),
      prospectKeyword: nameKeyword ?? "",
      showActive: activeTab === ProspectsListTabEnum.CURRENT,
      showArchived: activeTab === ProspectsListTabEnum.ARCHIVED,
      unitIds: pipe(
        units ?? [],
        A.map((itm) => itm.value),
      ),
      unitStatus: unitStatus ?? [],
      ...sortProps,
    };

    return ["LeasingMiddleLayerAPI.searchProspectsTable", payload];
  }, [
    archiveKeyword,
    nameKeyword,
    showOnlyNeedsFollowUp,
    markets,
    properties,
    units,
    unitStatus,
    activeTab,
    sortProps,
  ]);

  const {
    data: pagedData,
    isFetching,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery<
    SearchProspectsTableResponse,
    unknown,
    SearchProspectsTableResponse,
    SearchQueryKey
  >({
    getNextPageParam: searchQueryGetNextPageParam,
    queryFn: searchQueryFn,
    queryKey,
  });

  const flattenedData = useMemo(() => {
    const pages = pagedData?.pages ?? [];
    return pages.flatMap((page) => page.rows);
  }, [pagedData]);

  const totalResults = useMemo(() => {
    const pages = pagedData?.pages ?? [];
    const lastIndex = Math.max(0, pages.length - 1);
    return pages[lastIndex]?.count ?? 0;
  }, [pagedData]);

  return {
    data: flattenedData,
    /**
     * function wrapper to eat/void the return type as we don't care about it
     *
     * useCallback is then required to make the function referentially stable again
     */
    fetchNextPage: useCallback(
      () => fetchNextPage().then(F.constVoid),
      [fetchNextPage],
    ),
    isFetching,
    /**
     * function wrapper to eat/void the return type as we don't care about it
     *
     * useCallback is then required to make the function referentially stable again
     */
    refetch: useCallback(() => refetch().then(F.constVoid), [refetch]),
    totalResults,
  };
}

export { useProspectsTableData };
