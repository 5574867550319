import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  CollectionsTypeEnum,
  GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
} from "../collections.types";

// Actions taken from the delinquency table or right rail may cause leases to move from one status to another, or for the available actions or "action needed" to change.
// When this happens, we need to invalidate the data across certain statuses to ensure the table is up to date
function useInvalidateDelinquencyTableData() {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries([
      GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
      CollectionsTypeEnum.ALL,
    ]);
    await queryClient.invalidateQueries([
      GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
      CollectionsTypeEnum.ALL_ACTIVE_LEASES,
    ]);
    await queryClient.invalidateQueries([
      GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
      CollectionsTypeEnum.EXCLUDED,
    ]);
    await queryClient.invalidateQueries([
      GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
      CollectionsTypeEnum.STABLE,
    ]);
    await queryClient.invalidateQueries([
      GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
      CollectionsTypeEnum.PAYMENT_PLANS,
    ]);
  }, [queryClient]);
}

export { useInvalidateDelinquencyTableData };
