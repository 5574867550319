import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { IconEdit } from "@tabler/icons-react";
import { Option as O } from "effect";
import type { ElementRef, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useId } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Inset } from "@ender/shared/ds/inset";
import { Stack } from "@ender/shared/ds/stack";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { noOpAsync } from "@ender/shared/utils/no-op";

import { DisplayableApplicantRentalHistory } from "./display-applicant-rental-history-fields";
import type { ApplicantRentalHistoryFormOutput } from "./edit-applicant-rental-history-fields";
import {
  ApplicantRentalHistoryFormSchema,
  EditableApplicantRentalHistory,
} from "./edit-applicant-rental-history-fields";

type ApplicationRentalHistoryCardProps = {
  application: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "landlordName" | "landlordEmail" | "landlordPhone" | "firstTimeRenter"
  >;
  header?: ReactNode;
  isEditable?: boolean;
  onSubmit?: (data: ApplicantRentalHistoryFormOutput) => Promise<void>;
  isWorking?: boolean;
};

const ApplicationRentalHistoryCard = forwardRef<
  ElementRef<typeof Card>,
  ApplicationRentalHistoryCardProps
>(function ApplicationRentalHistoryCard(props, ref) {
  const {
    application,
    header,
    isEditable,
    onSubmit = noOpAsync,
    isWorking,
  } = props;
  const { landlordName, landlordEmail, landlordPhone, firstTimeRenter } =
    application;

  const [editing, editingHandlers] = useBoolean(false);
  const form = useForm({
    defaultValues: {
      firstTimeRenter: O.fromNullable(firstTimeRenter),
      landlordEmail,
      landlordName,
      landlordPhone,
    },
    mode: "onSubmit",
    resolver: effectTsResolver(ApplicantRentalHistoryFormSchema),
  });

  const formRef = useRefLatest(form);

  /**
   * Synchronize the form values with the incoming props
   */
  useEffect(() => {
    const newValues = {
      firstTimeRenter: O.fromNullable(firstTimeRenter),
      landlordEmail,
      landlordName,
      landlordPhone,
    };
    formRef.current.reset(newValues);
  }, [formRef, landlordName, landlordEmail, landlordPhone, firstTimeRenter]);

  const handleSubmit = useCallback(
    async (values: ApplicantRentalHistoryFormOutput) => {
      await onSubmit(values);
      editingHandlers.setFalse();
    },
    [onSubmit, editingHandlers],
  );

  const { reset: resetForm } = formRef.current;

  const handleCancel = useCallback(() => {
    editingHandlers.setFalse();
    resetForm();
  }, [resetForm, editingHandlers]);

  const headingId = useId();
  return (
    <Card ref={ref} labelledBy={headingId}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Group justify={Justify.between} align={Align.center}>
            <span id={headingId}>{header}</span>
            {isEditable && (
              <Inset
                r={editing ? Spacing.none : Spacing.sm}
                t={Spacing.sm}
                b={Spacing.sm}>
                {editing ? (
                  <Group>
                    <Button
                      variant={ButtonVariant.transparent}
                      onClick={handleCancel}
                      disabled={isWorking}>
                      Cancel
                    </Button>
                    <Button type="submit" loading={isWorking}>
                      Save
                    </Button>
                  </Group>
                ) : (
                  <ActionIcon
                    variant={ButtonVariant.transparent}
                    onClick={editingHandlers.setTrue}>
                    <IconEdit />
                  </ActionIcon>
                )}
              </Inset>
            )}
          </Group>
          <Grid spacingY={Spacing.none} underline="uneven">
            {editing ? (
              <EditableApplicantRentalHistory form={form} />
            ) : (
              <DisplayableApplicantRentalHistory form={form} />
            )}
          </Grid>
        </Stack>
      </Form>
    </Card>
  );
});

export { ApplicationRentalHistoryCard };

export type { ApplicationRentalHistoryCardProps };
