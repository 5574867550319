/**
 * Creates a new DRAFTING lease.
 * HTTP Method: POST
 * Pathname: /units/{unitId}/leases
 * @function newLease
 * @memberof LeasingAPI
 * @param {LeasingAPINewLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<NewLeaseResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { NewLeaseResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPINewLeasePathParams = {
  unitId: EnderId;
};

type LeasingAPINewLeaseSearchParams = {
  token?: string | undefined;
};

type LeasingAPINewLeaseBodyParams = {
  achReversalFee?: SerializesInto<Money> | undefined;
  companyName: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  deposits: any[];
  depositsHeldExternally?: boolean | undefined;
  expectedMoveOutDate?: SerializesInto<LocalDate> | undefined;
  graceDays?: number | undefined;
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  lateFee?: SerializesInto<Money> | undefined;
  securityDeposit?: SerializesInto<Money> | undefined;
  startDate: SerializesInto<LocalDate>;
};

type LeasingAPINewLeasePayload = LeasingAPINewLeasePathParams &
  LeasingAPINewLeaseSearchParams &
  LeasingAPINewLeaseBodyParams;

function newLeaseUnsafeEffect(payload: LeasingAPINewLeasePayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, NewLeaseResponse>({
        body,
        decode: unsafeDecodeJsonResponse<NewLeaseResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/leases`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function newLease(
  payload: LeasingAPINewLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<NewLeaseResponse> {
  return F.pipe(
    payload,
    newLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { newLease };
export type {
  LeasingAPINewLeaseBodyParams,
  LeasingAPINewLeasePathParams,
  LeasingAPINewLeasePayload,
  LeasingAPINewLeaseSearchParams,
};
