import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const EnderSystemStatusServiceDBStatusValues = [
  "READ_WRITE",
  "READ_ONLY",
] as const;

const EnderSystemStatusServiceDBStatusEffectSchema = Schema.Literal(
  ...EnderSystemStatusServiceDBStatusValues,
);

type EnderSystemStatusServiceDBStatus = Schema.Schema.Type<
  typeof EnderSystemStatusServiceDBStatusEffectSchema
>;

const EnderSystemStatusServiceDBStatusEnum = castEnum(
  EnderSystemStatusServiceDBStatusEffectSchema,
);

function randomEnderSystemStatusServiceDBStatus(): EnderSystemStatusServiceDBStatus {
  return rand(EnderSystemStatusServiceDBStatusValues);
}

export {
  EnderSystemStatusServiceDBStatusEffectSchema,
  EnderSystemStatusServiceDBStatusEnum,
  EnderSystemStatusServiceDBStatusValues,
  randomEnderSystemStatusServiceDBStatus,
};
export type { EnderSystemStatusServiceDBStatus };
