/**
 * Update single lead data from given lead
 * HTTP Method: POST
 * Pathname: /leads/{leadId}
 * @function updateLead
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdateLeadPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Instant,
  LocalDate,
  SerializesInto,
} from "@ender/shared/core";
import type { LeadLeadSource } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdateLeadPathParams = {
  leadId: EnderId;
};

type LeasingAPIUpdateLeadSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdateLeadBodyParams = {
  email?: string | undefined;
  firstName?: string | undefined;
  followUpTime?: SerializesInto<Instant> | undefined;
  lastName?: string | undefined;
  moveInDate?: SerializesInto<LocalDate> | undefined;
  phone?: string | undefined;
  propertyId?: EnderId | undefined;
  source?: LeadLeadSource | undefined;
  unitId?: EnderId | undefined;
};

type LeasingAPIUpdateLeadPayload = LeasingAPIUpdateLeadPathParams &
  LeasingAPIUpdateLeadSearchParams &
  LeasingAPIUpdateLeadBodyParams;

function updateLeadUnsafeEffect(payload: LeasingAPIUpdateLeadPayload) {
  const { leadId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leads/${leadId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateLead(
  payload: LeasingAPIUpdateLeadPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateLeadUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateLead };
export type {
  LeasingAPIUpdateLeadBodyParams,
  LeasingAPIUpdateLeadPathParams,
  LeasingAPIUpdateLeadPayload,
  LeasingAPIUpdateLeadSearchParams,
};
