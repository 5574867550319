import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CountertopsCountertopMaterialValues = [
  "GRANITE",
  "QUARTZ",
  "CULTURED_MARBLE",
  "FORMICA",
] as const;

const CountertopsCountertopMaterialEffectSchema = Schema.Literal(
  ...CountertopsCountertopMaterialValues,
);

type CountertopsCountertopMaterial = Schema.Schema.Type<
  typeof CountertopsCountertopMaterialEffectSchema
>;

const CountertopsCountertopMaterialEnum = castEnum(
  CountertopsCountertopMaterialEffectSchema,
);

function randomCountertopsCountertopMaterial(): CountertopsCountertopMaterial {
  return rand(CountertopsCountertopMaterialValues);
}

export {
  CountertopsCountertopMaterialEffectSchema,
  CountertopsCountertopMaterialEnum,
  CountertopsCountertopMaterialValues,
  randomCountertopsCountertopMaterial,
};
export type { CountertopsCountertopMaterial };
