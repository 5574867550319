import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const MarketUserRoleMarketRoleValues = [
  "BUYSIDE_BROKER",
  "CONSTRUCTION_MANAGER",
] as const;

const MarketUserRoleMarketRoleEffectSchema = Schema.Literal(
  ...MarketUserRoleMarketRoleValues,
);

type MarketUserRoleMarketRole = Schema.Schema.Type<
  typeof MarketUserRoleMarketRoleEffectSchema
>;

const MarketUserRoleMarketRoleEnum = castEnum(
  MarketUserRoleMarketRoleEffectSchema,
);

function randomMarketUserRoleMarketRole(): MarketUserRoleMarketRole {
  return rand(MarketUserRoleMarketRoleValues);
}

export {
  MarketUserRoleMarketRoleEffectSchema,
  MarketUserRoleMarketRoleEnum,
  MarketUserRoleMarketRoleValues,
  randomMarketUserRoleMarketRole,
};
export type { MarketUserRoleMarketRole };
