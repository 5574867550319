import { Option as O, Order, pipe } from "effect";
import type { ReactNode } from "react";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { DateRangeInput } from "@ender/shared/ds/date-range-input";
import { Group } from "@ender/shared/ds/group";

type DateFilterProps = {
  label?: ReactNode;
  startDate: LocalDate;
  endDate: LocalDate;
  updateStartDate: (date: LocalDate | null) => void;
  updateEndDate: (date: LocalDate | null) => void;
};

function DateFilters(props: DateFilterProps) {
  const { label, startDate, endDate, updateEndDate, updateStartDate } = props;

  const updateDateRange = (
    dateRange: [O.Option<LocalDate$.LocalDate>, O.Option<LocalDate$.LocalDate>],
  ) => {
    updateStartDate(dateRange[0].pipe(O.getOrNull)?.toJSON() ?? NULL);
    updateEndDate(dateRange[1].pipe(O.getOrNull)?.toJSON() ?? NULL);
  };

  const value: [
    O.Option<LocalDate$.LocalDate>,
    O.Option<LocalDate$.LocalDate>,
  ] = [LocalDate$.parse(startDate), LocalDate$.parse(endDate)];

  const isEndDateInPast = Order.lessThan(LocalDate$.Order)(
    LocalDate$.of(endDate),
    LocalDate$.today(),
  );
  const isStartDateAfterEndDate = Order.lessThan(LocalDate$.Order)(
    LocalDate$.of(endDate),
    LocalDate$.of(startDate),
  );

  return (
    <Group>
      <DateRangeInput
        error={pipe(
          isStartDateAfterEndDate
            ? O.some("Start date is after End date")
            : O.none(),
          O.orElse(() =>
            isEndDateInPast ? O.some("End date is in the past") : O.none(),
          ),
          O.getOrElse(() => UNDEFINED),
        )}
        value={value}
        label={label}
        onChange={updateDateRange}
        clearable
        fromPlaceholder="Start Date"
        toPlaceholder="End Date"
      />
    </Group>
  );
}

export { DateFilters };
