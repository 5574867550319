import { IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { fail } from "@ender/shared/utils/error";

type FileData = {
  fileName: string;
  id: EnderId;
  s3Url: string;
  timestamp: string;
  uploaderName: string;
};

type DeleteBaiUploadProps = {
  fileData: FileData;
  onSuccess: () => void;
};

function DeleteBaiUpload({ fileData, onSuccess }: DeleteBaiUploadProps) {
  const { accountId } = useParams<{ accountId: EnderId }>();
  const confirmation = useConfirmationContext();

  const { mutateAsync: deleteBAIUpload, isLoading } = useMutation({
    mutationFn: BankingAPI.deleteBAIUpload,
    mutationKey: ["BankingAPI.deleteBAIUpload"] as const,
  });

  async function handleDeleteBaiUpload(baiUpload: FileData) {
    await confirmation({
      title: `Are you sure you would like to remove ${baiUpload?.fileName} from bank reconciliation?`,
    });
    try {
      await deleteBAIUpload({
        baiUploadId: baiUpload.id,
        bankAccountId: accountId,
      });
    } catch {
      //This fail might not be necessary if the message is coming straight from the BE. If you see double fail
      //notifications in the UI, delete this one.
      fail({
        message:
          "Ender does not support removing a BAI upload that already has matched transactions on bank reconciliation. If you would still like to remove this upload, please contact Ender OPS and we will assist you.",
      });
    }

    onSuccess();
  }

  return (
    <ActionIcon
      onClick={() => handleDeleteBaiUpload(fileData)}
      loading={isLoading}>
      <IconTrash />
    </ActionIcon>
  );
}

export { DeleteBaiUpload };
export type { FileData };
