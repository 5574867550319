import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import { PropertyPropertyTypeValues } from "@ender/shared/generated/ender.model.core.property";
import { CurrencySchema, castEnum } from "@ender/shared/utils/zod";

import { FirmSchema } from "./firm";
import { UnitSchema } from "./unit";

const AddressSchema = z.object({
  city: z.string(),
  fullDisplay: z.string(),
  id: EnderIdSchema,
  latitude: z.number(),
  longitude: z.number(),
  name: z.string(),
  placeId: z.string(),
  state: z.string(),
  street: z.string(),
  timezone: z.string().optional(),
  unit: z.string(),
  zipcode: z.string(),
});

const PhotoSchema = z.object({
  comment: z.string(),
  id: EnderIdSchema,
  isPrimary: z.boolean(),
  large: z.string(),
  modelId: EnderIdSchema,
  priority: z.number(),
  s3Url: z.string(),
  secondaryModelId: EnderIdSchema.optional(),
  small: z.string(),
});

const HomeTypeValues = [
  "APARTMENT",
  "DUPLEX",
  "MANUFACTURED_HOME",
  "MOBILE_HOME",
  "QUADRUPLEX",
  "TRIPLEX",
  "TOWNHOUSE",
  "UNDEVELOPED_LAND",
  "PAPER_LOT",
  "SINGLE_FAMILY_DETACHED",
  "CONDOMINIUM",
  "MULTI_FAMILY",
  "COMMUNITY_HOME",
] as const;
const HomeTypeSchema = z.enum(HomeTypeValues);
type HomeType = z.infer<typeof HomeTypeSchema>;

const HomeTypeEnum = castEnum<HomeType>(HomeTypeSchema);
const HomeTypeDisplayEnum: Record<HomeType, string> = {
  APARTMENT: "Apartment",
  COMMUNITY_HOME: "Community Home",
  CONDOMINIUM: "Condominium",
  DUPLEX: "Duplex",
  MANUFACTURED_HOME: "Manufactured Home",
  MOBILE_HOME: "Mobile Home",
  MULTI_FAMILY: "Multi Family",
  PAPER_LOT: "Paper Lot",
  QUADRUPLEX: "Quadruplex",
  SINGLE_FAMILY_DETACHED: "Single Family Detached",
  TOWNHOUSE: "Townhouse",
  TRIPLEX: "Triplex",
  UNDEVELOPED_LAND: "Undeveloped Land",
} as const;

const PropertySchema = z.object({
  address: AddressSchema.optional(),
  addressId: EnderIdSchema,
  amountOwed: CurrencySchema.optional(),
  contactEmail: z.string(),
  contactPhone: z.string(),
  dealId: EnderIdSchema.optional(),
  declaredNumUnits: z.number().default(0),
  firm: FirmSchema.optional(),
  firmId: EnderIdSchema,
  friendlyId: z.string().optional(),
  hasStripeAccount: z.boolean().optional(),
  homeType: HomeTypeSchema.optional(),
  id: EnderIdSchema,
  isMultiUnit: z.boolean().optional(),
  lateFee: z.string().optional(),
  leasingStatus: z.string().optional(),
  marketId: EnderIdSchema.optional(),
  marketName: z.string(),
  marketNumber: z.number().optional(),
  name: z.string(),
  numGraceDays: z.number().optional(),
  photo: PhotoSchema.optional(),
  pmId: EnderIdSchema,
  type: z.enum(PropertyPropertyTypeValues),
  units: z.array(UnitSchema).optional(),
});

type Property = z.infer<typeof PropertySchema>;

export {
  HomeTypeDisplayEnum,
  HomeTypeEnum,
  HomeTypeSchema,
  HomeTypeValues,
  PhotoSchema,
  PropertySchema,
};
export type { HomeType, Property };
