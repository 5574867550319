import { IconEdit, IconFileDollar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useContext } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import { Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Card } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Switch } from "@ender/shared/ds/switch";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { RightRail } from "@ender/shared/ui/right-rail";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { EditNsfForm } from "./edit-nsf-form";

type TenantPaymentControlsProps = {
  lease: LeaseSerializerLeaseResponse;
};

function TenantPaymentControls(props: TenantPaymentControlsProps) {
  const { lease } = props;

  const { hasPermissions, userPM } = useContext(UserContext);
  const confirmation = useConfirmationContext();

  const { mutateAsync: toggleAllowOnlinePayments } = useMutation({
    mutationFn: async () => {
      await LeasingAPI.updateLease({
        leaseId: lease.id,
        allowOnlinePayments: Boolean(!lease.allowOnlinePayments),
      });
    },
  });
  const { mutateAsync: toggleAllowACHPayments } = useMutation({
    mutationFn: async () => {
      await LeasingAPI.updateLease({
        leaseId: lease.id,
        allowACHPayments: Boolean(!lease.allowACHPayments),
      });
    },
  });

  const switchDisabled = !hasPermissions(
    FunctionalPermissionEnum.TOGGLE_ELECTRONIC_PAYMENTS,
  );

  const [
    isEditNsfRailOpen,
    { setTrue: openEditNsfRail, setFalse: closeEditNsfRail },
  ] = useBoolean(false);

  const queryClient = useQueryClient();

  const invalidateLeaseDetailsQuery = () => {
    queryClient.invalidateQueries([
      "LeasingAPI.getLeaseDetails",
      { leaseId: lease.id },
    ]);
    queryClient.invalidateQueries(["LeasingAPI.getLeaseDetails", lease.id]); // TODO: REMOVE ONCE CONTEXT IS GONE
  };

  async function onToggleOnlinePayments() {
    await confirmation({
      content: (
        <Text size={FontSize.sm}>
          {lease?.allowOnlinePayments
            ? "Are you sure you wish to disable card payments?"
            : "Are you sure you wish to enable card payments?"}
        </Text>
      ),
      title: `${lease?.allowOnlinePayments ? "Disable Tenant Card Payments?" : "Enable Tenant Card Payments?"}`,
    });
    await toggleAllowOnlinePayments();
    invalidateLeaseDetailsQuery();
    showSuccessNotification({
      message: lease?.allowOnlinePayments
        ? "Tenant card payments disabled."
        : "Tenant card payments enabled.",
    });
  }

  async function onToggleACHPayments() {
    await confirmation({
      content: (
        <Text size={FontSize.sm}>
          {lease?.allowACHPayments
            ? "Are you sure you wish to disable ACH payments?"
            : "Are you sure you wish to enable ACH payments?"}
        </Text>
      ),
      title: `${lease?.allowACHPayments ? "Disable Tenant ACH Payments?" : "Enable Tenant ACH Payments?"}`,
    });
    await toggleAllowACHPayments();
    invalidateLeaseDetailsQuery();
    showSuccessNotification({
      message: lease?.allowACHPayments
        ? "Tenant ACH payments disabled."
        : "Tenant ACH payments enabled.",
    });
  }

  if (P.isNullable(lease)) {
    return <></>;
  }

  const defaultAchReversalFee = Money$.of(userPM?.achReversalFee) ?? UNDEFINED;

  return (
    <>
      <Stack spacing={Spacing.md}>
        <Group spacing={Spacing.sm}>
          <IconFileDollar size={24} color="var(--color-slate-600)" />
          <H2>Payment Controls</H2>
        </Group>
        <Grid>
          <Card>
            <Stack spacing={Spacing.md}>
              <Text size={FontSize.md} weight={FontWeight.medium}>
                Tenant Electronic Payments
              </Text>
              <Switch
                label={
                  <Tooltip
                    label="You do not have permission"
                    disabled={!switchDisabled}>
                    <Text>Allow Card Payments</Text>
                  </Tooltip>
                }
                value={lease?.allowOnlinePayments ?? false}
                onChange={onToggleOnlinePayments}
                disabled={switchDisabled}
              />
              <Switch
                label={
                  <Tooltip
                    label="You do not have permission"
                    disabled={!switchDisabled}>
                    <Text>Allow ACH Payments</Text>
                  </Tooltip>
                }
                value={lease?.allowACHPayments ?? false}
                onChange={onToggleACHPayments}
                disabled={switchDisabled}
              />
            </Stack>
          </Card>
          <Card>
            <Stack spacing={Spacing.md}>
              <Group justify={Justify.between}>
                <Text size={FontSize.md} weight={FontWeight.medium}>
                  NSF Fee
                </Text>
                <ActionIcon variant="transparent" onClick={openEditNsfRail}>
                  <IconEdit />
                </ActionIcon>
              </Group>
              <Text>
                {P.isNotNullable(lease.achReversalFee)
                  ? lease.achReversalFee
                  : (userPM.achReversalFee ?? "Not Configured")}
              </Text>
            </Stack>
          </Card>
        </Grid>
      </Stack>
      <RightRail
        title="Edit NSF Fee"
        opened={isEditNsfRailOpen}
        onClose={closeEditNsfRail}>
        <EditNsfForm
          leaseId={lease.id}
          achReversalFee={
            P.isNotNullable(lease?.achReversalFee)
              ? Money$.of(lease.achReversalFee)
              : defaultAchReversalFee
          }
          closeRail={closeEditNsfRail}
          refetchLease={invalidateLeaseDetailsQuery}
          defaultReversalFee={defaultAchReversalFee}
        />
      </RightRail>
    </>
  );
}

export { TenantPaymentControls };
