import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BasementBasementFinishValues = [
  "FINISHED",
  "UNFINISHED",
  "PARTIALLY_FINISHED",
] as const;

const BasementBasementFinishEffectSchema = Schema.Literal(
  ...BasementBasementFinishValues,
);

type BasementBasementFinish = Schema.Schema.Type<
  typeof BasementBasementFinishEffectSchema
>;

const BasementBasementFinishEnum = castEnum(BasementBasementFinishEffectSchema);

function randomBasementBasementFinish(): BasementBasementFinish {
  return rand(BasementBasementFinishValues);
}

export {
  BasementBasementFinishEffectSchema,
  BasementBasementFinishEnum,
  BasementBasementFinishValues,
  randomBasementBasementFinish,
};
export type { BasementBasementFinish };
