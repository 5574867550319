/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/videos/search
 * @function searchVideos
 * @memberof InspectionAPI
 * @param {InspectionAPISearchVideosPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Video[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Video } from "@ender/shared/generated/ender.model.files";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPISearchVideosPathParams = {
  inspectionId: EnderId;
};

type InspectionAPISearchVideosSearchParams = {
  token?: string | undefined;
};

type InspectionAPISearchVideosBodyParams = {
  areaId?: EnderId | undefined;
  taskId?: EnderId | undefined;
  zoneId?: EnderId | undefined;
};

type InspectionAPISearchVideosPayload = InspectionAPISearchVideosPathParams &
  InspectionAPISearchVideosSearchParams &
  InspectionAPISearchVideosBodyParams;

function searchVideosUnsafeEffect(payload: InspectionAPISearchVideosPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Video[]>({
        body,
        decode: unsafeDecodeJsonResponse<Video[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/videos/search`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchVideos(
  payload: InspectionAPISearchVideosPayload,
  options?: { signal?: AbortSignal },
): Promise<Video[]> {
  return F.pipe(
    payload,
    searchVideosUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchVideos };
export type {
  InspectionAPISearchVideosBodyParams,
  InspectionAPISearchVideosPathParams,
  InspectionAPISearchVideosPayload,
  InspectionAPISearchVideosSearchParams,
};
