import { addMoneyTransfersToBanking } from "./add-money-transfers-to-banking";
import { approveAutopayment } from "./approve-autopayment";
import { cancelACHPayment } from "./cancel-a-c-h-payment";
import { cancelAutopay } from "./cancel-autopay";
import { cancelDeposit } from "./cancel-deposit";
import { createCardPayment } from "./create-card-payment";
import { createDeposit } from "./create-deposit";
import { createInternalDwollaTransfer } from "./create-internal-dwolla-transfer";
import { createInternalMarkPaidTransfer } from "./create-internal-mark-paid-transfer";
import { createStripePaymentMethod } from "./create-stripe-payment-method";
import { editReceipt } from "./edit-receipt";
import { getAutopaySettings } from "./get-autopay-settings";
import { getAutopaymentStatus } from "./get-autopayment-status";
import { getCard } from "./get-card";
import { getCheckBatch } from "./get-check-batch";
import { getDeposits } from "./get-deposits";
import { getInternalTransfers } from "./get-internal-transfers";
import { getInvoicesForMoneyTransfers } from "./get-invoices-for-money-transfers";
import { getMoneyTransfer } from "./get-money-transfer";
import { getOrCreatePaymentIntent } from "./get-or-create-payment-intent";
import { getPaymentRouting } from "./get-payment-routing";
import { getReceipts } from "./get-receipts";
import { getStripeFee } from "./get-stripe-fee";
import { getStripePublicKey } from "./get-stripe-public-key";
import { getWireTransfer } from "./get-wire-transfer";
import { handleStripeEvent } from "./handle-stripe-event";
import { listCards } from "./list-cards";
import { makeRentPaymentByACH } from "./make-rent-payment-by-a-c-h";
import { markReversedPayment } from "./mark-reversed-payment";
import { nameCard } from "./name-card";
import { payEnder } from "./pay-ender";
import { printCheck } from "./print-check";
import { printCheckBatch } from "./print-check-batch";
import { removeCard } from "./remove-card";
import { removeMoneyTransfersFromBanking } from "./remove-money-transfers-from-banking";
import { searchMoneyTransfers } from "./search-money-transfers";
import { setAutopaySettings } from "./set-autopay-settings";

const PaymentsAPI = {
  addMoneyTransfersToBanking,
  approveAutopayment,
  cancelACHPayment,
  cancelAutopay,
  cancelDeposit,
  createCardPayment,
  createDeposit,
  createInternalDwollaTransfer,
  createInternalMarkPaidTransfer,
  createStripePaymentMethod,
  editReceipt,
  getAutopaySettings,
  getAutopaymentStatus,
  getCard,
  getCheckBatch,
  getDeposits,
  getInternalTransfers,
  getInvoicesForMoneyTransfers,
  getMoneyTransfer,
  getOrCreatePaymentIntent,
  getPaymentRouting,
  getReceipts,
  getStripeFee,
  getStripePublicKey,
  getWireTransfer,
  handleStripeEvent,
  listCards,
  makeRentPaymentByACH,
  markReversedPayment,
  nameCard,
  payEnder,
  printCheck,
  printCheckBatch,
  removeCard,
  removeMoneyTransfersFromBanking,
  searchMoneyTransfers,
  setAutopaySettings,
};

export { PaymentsAPI };
