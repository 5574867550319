/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/categoryRules
 * @function setCategoryRules
 * @memberof LeasingAPI
 * @param {LeasingAPISetCategoryRulesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SetCategoryRulesRequest } from "@ender/shared/generated/ender.api.leasing.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISetCategoryRulesPathParams = {
  leaseId: EnderId;
};

type LeasingAPISetCategoryRulesSearchParams = {
  token?: string | undefined;
};

type LeasingAPISetCategoryRulesBodyParams = {
  rules: SetCategoryRulesRequest[];
};

type LeasingAPISetCategoryRulesPayload = LeasingAPISetCategoryRulesPathParams &
  LeasingAPISetCategoryRulesSearchParams &
  LeasingAPISetCategoryRulesBodyParams;

function setCategoryRulesUnsafeEffect(
  payload: LeasingAPISetCategoryRulesPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/categoryRules`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setCategoryRules(
  payload: LeasingAPISetCategoryRulesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setCategoryRulesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setCategoryRules };
export type {
  LeasingAPISetCategoryRulesBodyParams,
  LeasingAPISetCategoryRulesPathParams,
  LeasingAPISetCategoryRulesPayload,
  LeasingAPISetCategoryRulesSearchParams,
};
