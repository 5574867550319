import { DecisionEnum } from "@ender/shared/api/approval";
import { NULL } from "@ender/shared/constants/general";
import { cast } from "@ender/shared/types/cast";
import type {
  ColumnDef,
  EnderTableSortingParams,
} from "@ender/shared/ui/table-tanstack";
import { EnderTableTanstack, useTable } from "@ender/shared/ui/table-tanstack";
import { EnderDate } from "@ender/shared/utils/ender-date";

import type { TimelineStep } from "./types";

type AuditHistoryTableRow = TimelineStep;

const columns: ColumnDef<AuditHistoryTableRow>[] = [
  {
    accessorFn: (row) => EnderDate.of(row.timestamp).toTimestampString(),
    header: "Date & Time",
    id: "dateAndTime",
  },
  {
    accessorKey: "decision",
    header: "Action",
    minSize: 75,
  },
  {
    accessorKey: "user.name",
    header: "User",
    id: "user",
  },
  {
    accessorFn: (row) => {
      if (row.decision === DecisionEnum.Created) {
        return NULL;
      }
      if (row.decision === DecisionEnum.Rejected) {
        return row.comment;
      }
      return row.step;
    },
    header: "Details",
    id: "details",
  },
];

type AuditHistoryTableProps = {
  timeline?: TimelineStep[];
};

function AuditHistoryTable({ timeline = [] }: AuditHistoryTableProps) {
  const table = useTable<AuditHistoryTableRow>({
    columns,
    data: timeline,
    sorting: cast<EnderTableSortingParams>({ enableSorting: false }),
  });

  return <EnderTableTanstack table={table} title="Audit History" />;
}

export { AuditHistoryTable };
