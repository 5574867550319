import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApprovalProcessTypeValues = [
  "DEAL",
  "OTHER",
  "PAYABLE",
  "RECEIVABLE",
  "GL_JOURNAL_ENTRY",
  "RECURRING_GL_JOURNAL_ENTRY",
  "INVOICE_AMOUNT",
  "TITLE_COMPANY",
  "VENDOR_BANK_ACCOUNT",
  "ESTIMATE",
  "TASK",
  "TASK_COST",
] as const;

const ApprovalProcessTypeEffectSchema = Schema.Literal(
  ...ApprovalProcessTypeValues,
);

type ApprovalProcessType = Schema.Schema.Type<
  typeof ApprovalProcessTypeEffectSchema
>;

const ApprovalProcessTypeEnum = castEnum(ApprovalProcessTypeEffectSchema);

function randomApprovalProcessType(): ApprovalProcessType {
  return rand(ApprovalProcessTypeValues);
}

export {
  ApprovalProcessTypeEffectSchema,
  ApprovalProcessTypeEnum,
  ApprovalProcessTypeValues,
  randomApprovalProcessType,
};
export type { ApprovalProcessType };
