import { clsx } from "clsx";
import type { ReactElement } from "react";
import { memo } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { Button, ButtonSize } from "@ender/shared/ds/button";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { RightRail } from "@ender/shared/ui/right-rail";
import type { ScreenRange } from "@ender/shared/ui/screen-size";
import { CreateInvoice } from "@ender/widgets/finance/create-invoice";

import {
  InvoiceFileCarousel,
  InvoiceFileCarouselPure,
  useInvoiceFileCarouselState,
} from "./invoice-file-carousel";
import { InvoiceFileUpload } from "./invoice-file-upload";
import type { InvoiceInboxDesktopProps } from "./invoice-inbox-desktop";
import { UploadedByFilter } from "./uploaded-by-filter";

import styles from "./invoice-inbox.module.css";

type InvoiceInboxMobileProps = InvoiceInboxDesktopProps & {
  mobileStep: number;
  screenRange: ScreenRange;
  pathname: string;
};

const InvoiceInboxMobile = memo(function InvoiceInboxMobile({
  apEmailAddress,
  arEmailAddress,
  baseKey,
  invoicePageList,
  invoiceType,
  mobileStep,
  onDeleteInvoicePage,
  onDeselectAll,
  onInvoiceCreated,
  onSelectInvoicePage,
  onUploadInvoicePage,
  pathname,
  pmId,
  screenRange,
  selectedInvoicePageList,
  setUploadedByUserId,
  uploadedByUserId,
}: InvoiceInboxMobileProps): ReactElement | Null {
  const [isRailOpen, { setTrue: openRail, setFalse: closeRail }] =
    useBoolean(false);
  const {
    isMobile,
    selectedInvoicePageIdSet,
    currentIndex,
    onBack,
    onForward,
  } = useInvoiceFileCarouselState({
    invoicePageCount: selectedInvoicePageList.length,
    screenRange,
    selectedInvoicePageList,
  });

  const step1 = (
    <>
      <InvoiceFileUpload
        key={`${baseKey}_InvoiceFileUpload`}
        pmId={pmId}
        invoiceType={invoiceType}
        apEmailAddress={apEmailAddress}
        arEmailAddress={arEmailAddress}
        selectedInvoiceCount={selectedInvoicePageList.length}
        onDeselectAll={onDeselectAll}
        onUploadInvoicePage={onUploadInvoicePage}
      />
      <div style={{ gridArea: "uploadedBy" }}>
        <UploadedByFilter
          value={uploadedByUserId}
          onChange={setUploadedByUserId}
        />
      </div>
      <p className={styles.categorizeInfo}>
        You have {invoicePageList.length || 0}{" "}
        {`page${invoicePageList.length !== 1 ? "s" : ""}`} left to categorize!
      </p>
      <InvoiceFileCarousel
        key={`${baseKey}_AvailableFileCarousel`}
        invoicePageList={invoicePageList}
        selectedInvoicePageList={selectedInvoicePageList}
        onSelectInvoicePage={onSelectInvoicePage}
        onDeleteInvoicePage={onDeleteInvoicePage}
      />
    </>
  );
  const step2 = (
    <>
      {mobileStep !== 1 || isRailOpen ? (
        NULL
      ) : (
        <div className="fixed z-10 self-end right-5">
          <div className=" -rotate-90 origin-top-right">
            <Button size={ButtonSize.sm} onClick={openRail}>
              Document(s)
            </Button>
          </div>
        </div>
      )}
      <CreateInvoice
        key={`${baseKey}_CreateInvoice`}
        invoiceType={invoiceType}
        selectedPages={selectedInvoicePageList}
        onInvoiceSuccessfullyCreated={onInvoiceCreated}
      />{" "}
      <RightRail
        title="Preview selected document(s)"
        opened={isRailOpen}
        onClose={closeRail}>
        <InvoiceFileCarouselPure
          key={`${baseKey}_SelectedFileCarousel`}
          invoicePageList={selectedInvoicePageList}
          isMobile={isMobile}
          selectedInvoicePageIdSet={selectedInvoicePageIdSet}
          singleItemScroll
          emptyContent={
            <Stack spacing={Spacing.none} align={Align.center}>
              <H2>No Document Selected</H2>
              <p>Invoice will generate without any attachments</p>
            </Stack>
          }
          currentIndex={currentIndex}
          onBack={onBack}
          onForward={onForward}
        />
      </RightRail>
    </>
  );

  return (
    <>
      <div
        className={clsx(
          { [styles.hidden]: mobileStep !== 0 },
          styles.mobileStepContainer,
        )}>
        {step1}
      </div>
      <div
        className={clsx(
          { [styles.hidden]: mobileStep !== 1 },
          styles.mobileStepContainer,
        )}>
        {step2}
      </div>
      <div className={styles.mobileNavGroup}>
        {mobileStep > 0 ? (
          <EnderLink
            className={styles.mobileBackButton}
            to={`${pathname}?mobileStep=${mobileStep - 1}`}>
            Back
          </EnderLink>
        ) : (
          NULL
        )}
        {mobileStep < 1 ? (
          <EnderLink
            className={styles.mobileNextButton}
            to={`${pathname}?mobileStep=${mobileStep + 1}`}>
            Next Step
          </EnderLink>
        ) : (
          NULL
        )}
      </div>
    </>
  );
});

export { InvoiceInboxMobile };
