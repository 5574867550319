import { Predicate as P } from "effect";

import { Money$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { RouterLink } from "@ender/shared/ds/router-link";
import type { InvoiceResponseAllocationResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { PropertySerializerPropertyResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { Ellipsis } from "@ender/shared/ui/ellipsis";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";

import styles from "./allocations-table.module.css";

type AllocationsTableProps = {
  property: Pick<
    PropertySerializerPropertyResponse,
    "id" | "friendlyId" | "name"
  >;
} & Pick<
  InvoiceResponseAllocationResponse,
  | "accountName"
  | "accountNumber"
  | "payableCategoryName"
  | "payableSubCategoryName"
  | "amount"
>;

function labelForSingleProperty(
  property: Pick<PropertySerializerPropertyResponse, "friendlyId" | "name">,
) {
  return property.friendlyId || property.name;
}

function PropertyCell({
  property,
}: {
  property: Pick<
    PropertySerializerPropertyResponse,
    "id" | "friendlyId" | "name"
  >;
}) {
  if (P.isNullable(property)) {
    return "--";
  }

  return (
    <RouterLink
      href={`/properties/${property.id}`}
      target="_blank"
      rel="noreferrer">
      <span className="nowrap">{labelForSingleProperty(property)}</span>
    </RouterLink>
  );
}

function GLAccountCell({
  accountName,
  accountNumber,
}: {
  accountName: string;
  accountNumber: string;
}) {
  return (
    <Group spacing={Spacing.xs}>
      <Ellipsis>
        {P.isNotNullable(accountNumber) && <span>{`${accountNumber} `}</span>}
        <span>{accountName}</span>
      </Ellipsis>
    </Group>
  );
}

const columns: ColumnDef<AllocationsTableProps>[] = [
  {
    accessorKey: "property",
    cell: (props) => <PropertyCell property={props.getValue()} />,
    header: "Property",
  },
  {
    accessorFn: ({ accountName, accountNumber }) => ({
      accountName,
      accountNumber,
    }),
    cell: (props) => (
      <GLAccountCell
        accountName={props.getValue().accountName}
        accountNumber={props.getValue().accountNumber}
      />
    ),
    header: () => <span style={{ whiteSpace: "nowrap" }}>GL Account</span>,
    id: "glAccount",
  },
  {
    accessorKey: "amount",
    cell: (props) => <MoneyDisplay value={Money$.parse(props.getValue())} />,
    className: styles.amountColumn,
    header: "Amount",
  },
  {
    accessorKey: "payableCategoryName",
    cell: (props) => <Ellipsis>{props.getValue()}</Ellipsis>,
    header: () => (
      <span style={{ whiteSpace: "nowrap" }}>Payables Category</span>
    ),
  },
  {
    accessorKey: "payableSubCategoryName",
    cell: (props) => <Ellipsis>{props.getValue()}</Ellipsis>,
    header: () => <span style={{ whiteSpace: "nowrap" }}>Sub-Category</span>,
  },
];

export { columns, GLAccountCell, PropertyCell };
export type { AllocationsTableProps };
