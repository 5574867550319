import { Array as A, Function as F, Predicate as P } from "effect";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { MoneyTransferTransferStatusEnum } from "@ender/shared/generated/ender.model.payments";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import type {
  BankRecRow,
  BankRecRowType,
} from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";
import { isMultiple } from "@ender/shared/utils/is";

import type { BankingDetailListTableData } from "./banking-detail-list/banking-detail-list-columns/banking-detail-list-columns";
import { getBankingDetailViewData } from "./banking-detail-list/get-banking-detail-view-data";
import { getMatchedMessage } from "./banking-detail-list/get-matched-message";
import { isEnderBatchItem } from "./banking-detail.utils";

const UnmatchedBankRecRowTypes: BankRecRowType[] = [
  BankRecRowTypeEnum.UNMATCHED_ENDER,
  BankRecRowTypeEnum.ENDER_EXCLUDED,
];

function transformToBankRecTableRow(
  row: BankRecRow,
  i: number,
  isPM: boolean,
  refresh: () => void,
): BankingDetailListTableData {
  const {
    bankTransaction,
    description,
    enderBatch,
    isIgnored,
    linkedInvoice,
    isMultiInvoice,
    rowType,
    runningBalance,
  } = row;

  const isUnmatchedRow = UnmatchedBankRecRowTypes.includes(rowType);
  const matchedMessage = getMatchedMessage({
    description,
    enderBatch,
    isIgnored,
    linkedInvoice,
    rowType,
  });

  // TODO: hook up to api fetch to get invoices for linked money transfers when clicking on invoice icon to open modal
  const multiplePaymentInvoices = Object.values<Invoice[]>({}).flat();
  const displayedRunningBalance = isUnmatchedRow
    ? NULL
    : (runningBalance ?? NULL);
  const transactionDetails = isUnmatchedRow ? enderBatch : bankTransaction;
  const isBankExcluded = rowType === BankRecRowTypeEnum.MATCHED && isIgnored;
  const isBatchDeposit = isMultiple(enderBatch?.moneyTransfers);
  const isEnderTxnBatch =
    rowType === BankRecRowTypeEnum.UNMATCHED_ENDER &&
    (isMultiInvoice || isBatchDeposit);
  const canRemoveRow =
    isPM &&
    isEnderBatchItem(transactionDetails) &&
    A.isNonEmptyArray(transactionDetails?.moneyTransfers ?? []);
  const { badgeColor, invoiceIcon, reconciliationStatus, tooltipContent } =
    getBankingDetailViewData({
      hasLinkedInvoice: P.isNotNullable(linkedInvoice),
      inReceipts:
        isEnderBatchItem(transactionDetails) &&
        A.isNonEmptyArray(
          transactionDetails.moneyTransfers.filter(
            (mt) => mt.status === MoneyTransferTransferStatusEnum.IN_RECEIPTS,
          ),
        ),
      isEnderTxnBatch,
      isForcedMatched:
        !isEnderBatchItem(transactionDetails) &&
        transactionDetails?.isForcedMatched,
      isIgnored,
      matchedMessage,
      rowType,
    });

  return {
    ...row,
    ...(isBankExcluded && {
      rowType: BankRecRowTypeEnum.BANK_EXCLUDED,
      status: "bankExcluded",
    }),
    badgeColor,
    canRemoveRow,
    displayedRunningBalance,
    invoiceIcon,
    isEnderTxnBatch,
    isFirstLine: i === 0,
    isForAllTenants: P.isNotNullable(linkedInvoice)
      ? linkedInvoice.isForAllTenants
      : UNDEFINED,
    isMultiInvoice,
    multiplePaymentInvoices: multiplePaymentInvoices,
    reconciliationStatus: reconciliationStatus,
    refreshBankTransactions: refresh,
    startBatchMatching: F.constVoid,
    tooltipContent,
    transactionDetails,
  };
}

export { transformToBankRecTableRow };
