import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P, Record as R, flow } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ender/shared/ds/accordion";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { UnitAreasAPI } from "@ender/shared/generated/ender.api.core";

import { AddUnitAreaButton } from "./add-unit-area-button";
import { DeleteUnitAreaActionIcon } from "./delete-unit-area-action-icon";
import { InspectionZoneCards } from "./inspection-zone-cards";
import { UnitAreaSummaryCard } from "./unit-area-summary-card";
import type { UnitAreaDiscriminant } from "./unit-area.types";

type AreaAccordionItemProps = {
  area: UnitAreaDiscriminant;
};

function AreaAccordionItem(props: AreaAccordionItemProps) {
  const { area } = props;

  return (
    <AccordionItem value={area.id}>
      <AccordionTrigger
        actions={
          <Group spacing={Spacing.xs}>
            <DeleteUnitAreaActionIcon unitId={area.unitId} areaId={area.id} />
          </Group>
        }>
        {area.name}
      </AccordionTrigger>
      <AccordionContent variant="bordered">
        <InspectionZoneCards area={area} />
      </AccordionContent>
    </AccordionItem>
  );
}

type AreasListProps = {
  unitId: EnderId;
};

function AreasList(props: AreasListProps) {
  const { unitId } = props;
  const { data: areas = [] } = useQuery({
    // @ts-expect-error
    queryKey: ["UnitAreasAPI.getUnitAreas", { unitId }] as const,
    queryFn: () => UnitAreasAPI.getUnitAreas({ unitId }),
    select: flow(
      R.reduce([] as UnitAreaDiscriminant[], (acc, val) =>
        P.isNotNullable(val) ? A.appendAll(acc, val) : acc,
      ),
    ),
  });

  return (
    <>
      <Group justify={Justify.between}>
        <H2>Area Details</H2>
        <AddUnitAreaButton unitId={unitId} />
      </Group>
      <UnitAreaSummaryCard unitId={unitId} />
      <Accordion>
        {areas.map((area) => (
          <AreaAccordionItem key={area.id} area={area} />
        ))}
      </Accordion>
    </>
  );
}

export { AreasList };
