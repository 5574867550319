import { clsx } from "clsx";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import "./ender-link.scss";

type ExtendedLinkProps = LinkProps & {
  download?: boolean | string;
};

type EnderLinkProps = Partial<
  Pick<ExtendedLinkProps, "to" | "onClick" | "download">
> &
  Pick<ExtendedLinkProps, "target" | "className" | "children"> & {
    large?: boolean;
    semibold?: boolean;
    disabled?: boolean;
    rel?: string;
  };

/**
 * @deprecated use RouterLink from `shared/ds/router-link`.
 * Migration Guide:
 *
 * ### IMPORTANT
 * First, verify that what you want is actually a router link.
 * In the majority of cases, what you may want is `<Button variant="transparent">`
 *
 *  Once you're sure you need a router link:
 * `download` has been removed entirely. Don't use this for downloads.
 * `to` is now a `href` property and only accepts strings. This is to align better with the eventual Next migration
 * `className` is no longer extant. Do not customize the component.
 * `large` and `semibold` are now combined into one `size` attribute that takes sm, md, and lg
 * `onClick` is still supported in the event of extraneous behavior, but again, please verify if you need this over a Button
 */
const EnderLink = forwardRef(
  (props: EnderLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const {
      children,
      className,
      disabled,
      large,
      semibold,
      target,
      to,
      ...linkProps
    } = props;

    if (props.download) {
      throw new Error(
        "Do not use EnderLink for downloads; issues with prefixing relative paths and Content-Disposition overriding desired filename",
      );
    }

    const enderLinkClassNames = clsx(
      {
        disabled,
        "ender-link": true,
        large,
        semibold,
      },
      className,
    );

    return to ? (
      <Link
        to={to}
        target={target}
        className={enderLinkClassNames}
        {...linkProps}
        ref={ref}>
        {children}
      </Link>
    ) : (
      <span className={enderLinkClassNames} {...linkProps} ref={ref}>
        {children}
      </span>
    );
  },
);
EnderLink.displayName = "EnderLink";

export { EnderLink };
