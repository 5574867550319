import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { useQueryParamsEnderId } from "@ender/shared/hooks/use-query-params";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { EllipsisCell } from "@ender/shared/ui/table-tanstack";

import type {
  JournalEntryApprovalsTableRow,
  RecurringJournalEntryApprovalsTableRow,
} from "./gltx-approvals-table.types";

const createCellWithParams = (displayKey: string) => {
  const CellWithParams = (props: {
    row: {
      original: {
        id: EnderId;
        [key: string]: string | boolean;
      };
    };
  }) => {
    const { id } = props.row.original;
    const displayValue = props.row.original[displayKey];
    const [_, setId] = useQueryParamsEnderId("id");

    return (
      <Button onClick={() => setId(id)} variant="transparent">
        {displayValue}
      </Button>
    );
  };

  CellWithParams.displayName = `CellWithParams_${displayKey}`;
  return CellWithParams;
};

const journalEntryColumns: ColumnDef<JournalEntryApprovalsTableRow>[] = [
  {
    accessorKey: "id",
    cell: createCellWithParams("id"),
    header: "ID",
    maxSize: 90,
    minSize: 90,
  },
  {
    accessorKey: "step",
    cell: EllipsisCell,
    header: "Approval Step",
    maxSize: 180,
  },
  {
    accessorKey: "transactionType",
    header: "Transaction Type",
    maxSize: 140,
    minSize: 140,
  },
  {
    accessorKey: "propertyDisplay",
    cell: EllipsisCell,
    header: "Property",
    maxSize: 150,
  },
  {
    accessorKey: "firmDisplay",
    header: "Firm",
    maxSize: 180,
  },
  {
    accessorKey: "memoDisplay",
    cell: createCellWithParams("memoDisplay"),
    header: "Memo",
    maxSize: 180,
  },
  {
    accessorKey: "ledgerDateDisplay",
    header: "Txn Date",
    maxSize: 93,
    minSize: 93,
  },
  {
    accessorKey: "accountingDateDisplay",
    header: "Period",
    maxSize: 93,
    minSize: 93,
  },
  {
    accessorKey: "systemDateDisplay",
    header: "System Date",
    maxSize: 112,
    minSize: 112,
  },
  {
    accessorKey: "authorDisplay",
    header: "Author",
    maxSize: 180,
  },
];

const recurringEntryColumns: ColumnDef<RecurringJournalEntryApprovalsTableRow>[] =
  [
    {
      accessorKey: "id",
      cell: createCellWithParams("id"),
      header: "RJE ID",
      maxSize: 90,
      minSize: 90,
    },
    {
      accessorKey: "step",
      cell: EllipsisCell,
      header: "Approval Step",
      maxSize: 180,
    },
    {
      accessorKey: "propertyDisplay",
      cell: EllipsisCell,
      header: "Property",
      maxSize: 150,
    },
    {
      accessorKey: "firmDisplay",
      header: "Firm",
      maxSize: 180,
    },
    {
      accessorKey: "memoDisplay",
      cell: createCellWithParams("memoDisplay"),
      header: "Description",
      maxSize: 180,
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
      maxSize: 93,
      minSize: 93,
    },
    {
      accessorKey: "startDateDisplay",
      header: "Start Date",
      maxSize: 93,
      minSize: 93,
    },
    {
      accessorKey: "endDateDisplay",
      header: "End Date",
      maxSize: 93,
      minSize: 93,
    },
    {
      accessorKey: "systemDateDisplay",
      header: "System Date",
      maxSize: 93,
      minSize: 93,
    },
    {
      accessorKey: "authorDisplay",
      header: "Author",
      maxSize: 180,
    },
  ];

export { journalEntryColumns, recurringEntryColumns };
