import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { IconInfoCircle } from "@tabler/icons-react";
import { Option as O, Predicate as P, pipe } from "effect";
import { useWatch } from "react-hook-form";
import { z } from "zod";
import { useStore } from "zustand";

import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormRadioGroup } from "@ender/shared/ds/radio-group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { PaymentsAPI } from "@ender/shared/generated/ender.api.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";
import { castEnum } from "@ender/shared/utils/zod";

import { useTenantLedgerStore } from "../../../../tenant-ledger-store.context";

const ReversedPaymentTypeEnum = {
  ACCOUNTING_AND_TRANSFER: "ACCOUNTING_AND_TRANSFER",
  ACCOUNTING_ONLY: "ACCOUNTING_ONLY",
} as const;
const ReversedPaymentTypeValues = [
  ReversedPaymentTypeEnum.ACCOUNTING_AND_TRANSFER,
  ReversedPaymentTypeEnum.ACCOUNTING_ONLY,
] as const;
const ReversedPaymentTypeSchema = z.enum(ReversedPaymentTypeValues);
type ReversedPaymentType = z.infer<typeof ReversedPaymentTypeSchema>;
const ReversedPaymentEnum = castEnum<ReversedPaymentType>(
  ReversedPaymentTypeSchema,
);

const ReversePaymentFormSchema = Schema.Struct({
  reversalDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((v): v is O.Option<LocalDate$.LocalDate> => O.isSome(v), {
      message: () => "Reversal Date is required",
    }),
  ),
  reversalType: Schema.Enums(ReversedPaymentEnum).pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<ReversedPaymentType> => O.isSome(input),
      {
        message: () => "You must choose a reversal type to proceed",
      },
    ),
  ),
});

type ReversePaymentFormInput = Schema.Schema.Encoded<
  typeof ReversePaymentFormSchema
>;

type ReversedPaymentFormProps = {
  closeModal: () => void;
  onSuccess: () => Promise<void>;
};

type RadioOptionProps = {
  label: string;
  tooltipLabel: string;
};

function RadioOption({ label, tooltipLabel }: RadioOptionProps) {
  return (
    <Group spacing={Spacing.sm} justify={Justify.center}>
      <Text size={FontSize.md}>{label}</Text>
      <Tooltip label={tooltipLabel}>
        <IconInfoCircle />
      </Tooltip>
    </Group>
  );
}

function ReversedPaymentForm({
  closeModal,
  onSuccess,
}: ReversedPaymentFormProps) {
  const [isProcessing, setIsProcessing] = useBoolean(false);
  const tenantLedgerStore = useTenantLedgerStore();

  const { selectedLedgerEvent } = useStore(tenantLedgerStore, (state) => ({
    selectedLedgerEvent: state.selectedLedgerEvent,
  }));

  const form = useForm<ReversePaymentFormInput>({
    defaultValues: {
      reversalDate: LocalDate$.parse(LocalDate$.today()),
      reversalType: O.some(ReversedPaymentTypeEnum.ACCOUNTING_AND_TRANSFER),
    },
    mode: "onSubmit",
    resolver: effectTsResolver(ReversePaymentFormSchema),
  });

  const reversalDate = useWatch({
    control: form.control,
    name: "reversalDate",
  });

  const reversalType = useWatch({
    control: form.control,
    name: "reversalType",
  });

  const confirmation = useConfirmationContext();
  const markReversedPaymentWithWarnings = withWarningHandler(
    PaymentsAPI.markReversedPayment,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );

  async function onSubmit() {
    try {
      const _selectedLedgerEvent = selectedLedgerEvent.pipe(O.getOrUndefined);
      if (!_selectedLedgerEvent) {
        return;
      }
      const { id: modelId } = _selectedLedgerEvent;

      setIsProcessing.setTrue();

      const response = await markReversedPaymentWithWarnings({
        moneyTransferId: modelId,
        reversalDate: reversalDate.pipe(
          O.map((date) => date.toJSON()),
          O.getOrThrow,
        ),
        showOnBankRecAndTL:
          pipe(reversalType, O.getOrThrow) ===
          ReversedPaymentTypeEnum.ACCOUNTING_AND_TRANSFER,
      });
      //OK response can sometimes be null
      if (P.isNotNullable(response) || P.isNull(response)) {
        showSuccessNotification({ message: "Payment has been reversed." });
        await onSuccess();
        closeModal();
      }
    } catch (err) {
      fail(err);
    } finally {
      setIsProcessing.setFalse();
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        <Text size={FontSize.sm}>
          Processing a reversal will create offsetting entries for this payment.
        </Text>
        <FormRadioGroup
          data={[
            {
              label: (
                <RadioOption
                  label="Ordinary Reversal"
                  tooltipLabel="Records that, although money made it to your bank account, it was later withdrawn from the bank account."
                />
              ),
              value: ReversedPaymentTypeEnum.ACCOUNTING_AND_TRANSFER,
            },
            {
              label: (
                <RadioOption
                  label="Absolute Reversal"
                  tooltipLabel="Indicates that money never made it to your bank account. So there will be neither a deposit nor a withdrawal to reconcile in banking. However, we will still record GL transactions for the payment and reversal."
                />
              ),
              value: ReversedPaymentTypeEnum.ACCOUNTING_ONLY,
            },
          ]}
          form={form}
          name="reversalType"
        />
        <FormDateInput form={form} label="Reversal Date" name="reversalDate" />
        <Group justify={Justify.end}>
          <Button type="submit" loading={isProcessing}>
            Submit
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { ReversedPaymentForm, ReversedPaymentTypeEnum };
