import { Predicate as P } from "effect";
import * as R from "effect/Record";
import { useEffect, useMemo } from "react";

import { uploadFiles } from "@ender/shared/api/files";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { useUserStore } from "@ender/shared/stores/user-store";
import { fail } from "@ender/shared/utils/error";
import type { AttachmentsViewFile } from "@ender/widgets/shared/attachments";
import { AttachmentsView } from "@ender/widgets/shared/attachments";

function getAttachmentAuthorIds(files: FilesClientEnderFile[]) {
  return [
    ...new Set(
      files?.map((file) => file.authorId).filter(P.isNotNullable) || [],
    ),
  ];
}

function useFileAttachmentsAuthorUsers(files: FilesClientEnderFile[]) {
  const authorIds = getAttachmentAuthorIds(files);
  const userStore = useUserStore();

  // lazy useEffect
  useEffect(() => {
    async function setUserMapFromAuthorIds(authorIds: EnderId[]) {
      await userStore.getUsers(authorIds);
    }

    if (authorIds) {
      void setUserMapFromAuthorIds(authorIds);
    }
  }, [authorIds, userStore]);
}

function getAttachmentAuthorIdsFromInvoice(
  invoice: InvoiceSerializerInvoiceResponse,
) {
  return [
    ...new Set(
      invoice?.files?.map((file) => file.authorId).filter(P.isNotNullable) ||
        [],
    ),
  ];
}

type UseUserMapFromInvoiceFilesAuthorIdsProps = {
  invoice: InvoiceSerializerInvoiceResponse;
};

function useLoadInvoiceAttachmentAuthorUsers({
  invoice,
}: UseUserMapFromInvoiceFilesAuthorIdsProps) {
  const authorIds = getAttachmentAuthorIdsFromInvoice(invoice);
  const userStore = useUserStore();

  // lazy useEffect
  useEffect(() => {
    async function setUserMapFromAuthorIds(authorIds: EnderId[]) {
      await userStore.getUsers(authorIds);
    }

    if (authorIds) {
      void setUserMapFromAuthorIds(authorIds);
    }
  }, [authorIds, userStore]);
}

type InvoiceAttachmentsProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess: () => void;
};

function InvoiceAttachments({ onSuccess, invoice }: InvoiceAttachmentsProps) {
  useLoadInvoiceAttachmentAuthorUsers({ invoice });
  const { files } = invoice;
  useFileAttachmentsAuthorUsers(files);
  const { users: userMap } = useUserStore();

  function uploadFilesForInvoice(files: File[]) {
    return uploadFiles({
      files,
      modelId: invoice.id,
      modelType: ModelTypeEnum.INVOICE,
    });
  }

  const confirmation = useConfirmationContext();

  async function deleteAttachment({ id }: { id: EnderId }) {
    try {
      await confirmation({
        title: "Are you sure you would like to delete this attachment?",
      });
      await WebserverFilesAPI.deleteFile({
        fileId: id,
        modelId: invoice.id,
        modelType: ModelTypeEnum.INVOICE,
      });
      onSuccess();
    } catch (e) {
      fail(e);
    }
  }

  const _files: (FilesClientEnderFile & AttachmentsViewFile)[] = useMemo(
    () =>
      files.map((file) => {
        const { authorId, s3Url, path } = file;
        const pathParts = path.split("/");
        return {
          ...file,
          authorName:
            !R.isEmptyRecord(userMap) && authorId
              ? userMap[authorId]?.name
              : "",
          key: s3Url,
          name: pathParts[pathParts.length - 1],
        };
      }),
    [files, userMap],
  );

  return (
    <AttachmentsView<FilesClientEnderFile & AttachmentsViewFile>
      files={_files}
      onClickDelete={deleteAttachment}
      onSuccess={onSuccess}
      uploadFiles={uploadFilesForInvoice}
    />
  );
}

export { InvoiceAttachments };
