/**
 * Gets the data needed to render the content of this widget.
 * HTTP Method: GET
 * Pathname: /widgets/{widgetId}/data
 * @function getWidgetData
 * @memberof ReportsAPI
 * @param {ReportsAPIGetWidgetDataPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<WidgetDataJobResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LocalDateInterval } from "@ender/shared/generated/com.ender.common.arch.time";
import type { WidgetDataJobResponse } from "@ender/shared/generated/ender.service.reports.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIGetWidgetDataPathParams = {
  widgetId: EnderId;
};

type ReportsAPIGetWidgetDataSearchParams = {
  token?: string | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
  reportInterval?: LocalDateInterval | undefined;
};

type ReportsAPIGetWidgetDataPayload = ReportsAPIGetWidgetDataPathParams &
  ReportsAPIGetWidgetDataSearchParams;

function getWidgetDataUnsafeEffect(payload: ReportsAPIGetWidgetDataPayload) {
  const { widgetId, token, limit, offset, reportInterval, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, WidgetDataJobResponse>({
        body,
        decode: unsafeDecodeJsonResponse<WidgetDataJobResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/widgets/${widgetId}/data`,
        searchParams: [
          ["token", token],
          ["limit", limit],
          ["offset", offset],
          ["reportInterval", reportInterval],
        ],
      }),
    ),
  );
}

function getWidgetData(
  payload: ReportsAPIGetWidgetDataPayload,
  options?: { signal?: AbortSignal },
): Promise<WidgetDataJobResponse> {
  return F.pipe(
    payload,
    getWidgetDataUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getWidgetData };
export type {
  ReportsAPIGetWidgetDataPathParams,
  ReportsAPIGetWidgetDataPayload,
  ReportsAPIGetWidgetDataSearchParams,
};
