import { Option as O, Predicate as P } from "effect";
import { useMemo } from "react";

import type { LocalDate, Money } from "@ender/shared/core";
import { LocalDate$, Money$, Percent$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { PercentDisplay } from "@ender/shared/ds/percent-display";
import type { LeaseMoveOutReason } from "@ender/shared/generated/ender.model.leasing";
import {
  EnderTableTanstackV3,
  InstantCell,
  MoneyCell,
  asColumnDef,
  useTable,
  useTablePagination,
} from "@ender/shared/ui/table-tanstack";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

import type { RenewalOffer, RenewalOfferRow } from "./renewals.types";
import { getOfferDurationFromDates } from "./renewals.utils";

const columns = [
  asColumnDef<RenewalOfferRow, LocalDate>({
    accessorKey: "newStartDate",
    cell: (props) =>
      getOfferDurationFromDates(
        props.row.original.newStartDate,
        props.row.original.newEndDate,
      ),
    enableSorting: false,
    header: "Duration",
  }),
  asColumnDef<RenewalOfferRow, LocalDate>({
    accessorKey: "newEndDate",
    cell: (props) =>
      O.getOrUndefined(
        O.map(LocalDate$.parse(props.getValue()), (newEndDate) =>
          LocalDate$.toFormatted(
            LocalDate$.add(newEndDate, { days: -1 }),
            LocalDate$.Formats.DEFAULT,
          ),
        ),
      ),
    enableSorting: false,
    header: "End Date",
  }),
  asColumnDef<RenewalOfferRow, Money>({
    accessorKey: "newRent",
    cell: (props) => (
      <Group spacing={Spacing.none}>
        <MoneyCell {...props} />
        <span>
          (
          <PercentDisplay
            value={O.map(
              O.fromNullable(props.row.original.newRentPercentage),
              Percent$.of,
            )}
            complementary
            showSign
          />
          )
        </span>
      </Group>
    ),
    enableSorting: false,
    header: "Rent Amount",
  }),
  {
    accessorKey: "processingFee",
    cell: MoneyCell,
    enableSorting: false,
    header: "Processing Fee",
  },
  {
    accessorKey: "timestamp",
    cell: InstantCell,
    enableSorting: false,
    header: "Created",
  },
  {
    accessorKey: "sentTimestamp",
    cell: InstantCell,
    enableSorting: false,
    header: "Sent",
  },
  {
    accessorKey: "rejectedTimestamp",
    cell: InstantCell,
    enableSorting: false,
    header: "Rejected",
  },
  asColumnDef<RenewalOfferRow, LeaseMoveOutReason>({
    accessorKey: "rejectedReason",
    cell: (props) =>
      P.isNotNullable(props.getValue())
        ? convertSnakeCaseToTitleCase(props.getValue())
        : "",
    enableSorting: false,
    header: "Rejection Reason",
  }),
];

function transformRenewalPackageOffer(offer: RenewalOffer) {
  return {
    ...offer,
    newRent: Money$.of(offer.newRent),
    processingFee: O.match(O.fromNullable(offer.processingFee), {
      onNone: () => Money$.zero(),
      onSome: Money$.of,
    }),
  };
}

type RenewalOfferTableProps = {
  offers: RenewalOffer[];
};

function RenewalOfferTable(props: RenewalOfferTableProps) {
  const { offers } = props;

  const data = useMemo(
    () => offers.map((offer) => transformRenewalPackageOffer(offer)),
    [offers],
  );
  const pagination = useTablePagination({ manualPagination: true });

  const table = useTable<RenewalOfferRow>({
    columns,
    data,
    pagination,
  });

  return <EnderTableTanstackV3 table={table} />;
}

export { RenewalOfferTable };
