import type { QueryFunctionContext } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  HashMap as HM,
  Match,
  Number as Num,
  Option as O,
  String as Str,
} from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useMemo, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { TriggerButton, TriggerButtonSize } from "@ender/shared/ds/menu";
import type { SelectOption } from "@ender/shared/ds/select";
import type { MarketsAPIGetMarketsPayload } from "@ender/shared/generated/com.ender.buy.api";
import { MarketsAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { Market } from "@ender/shared/generated/com.ender.buy.model.misc";
import { MultiFilter } from "@ender/widgets/filters/multi-filter";

type GetMarketsResponse = Market[];
type GetMarketsQueryKey = readonly [
  "MarketsAPI.getMarkets",
  MarketsAPIGetMarketsPayload,
];

function getMarketsQueryFn(
  context: QueryFunctionContext<GetMarketsQueryKey>,
): Promise<GetMarketsResponse> {
  const { queryKey, signal } = context;
  const [, payload] = queryKey;
  return MarketsAPI.getMarkets(payload, { signal });
}

function getMarketsSelectFn(data: Market[]): SelectOption<EnderId, Market>[] {
  return F.pipe(
    data ?? [],
    A.map((meta) => {
      const { name: label, id: value } = meta;
      return { label, meta, value };
    }),
  );
}

type MarketFilterProps = {
  value: EnderId[];
  onChange: (value: EnderId[], markets: Market[]) => void;
  firmId?: EnderId;
  disabled?: boolean;
};

const MarketFilter = forwardRef<
  ElementRef<typeof MultiFilter>,
  MarketFilterProps
>(function MarketFilter(props, ref) {
  const { value, onChange, firmId, disabled } = props;

  const { data: options = [], isFetching } = useQuery({
    queryFn: getMarketsQueryFn,
    queryKey: ["MarketsAPI.getMarkets", { firmId }],
    select: getMarketsSelectFn,
  });
  const idToOption = useMemo(() => {
    return F.pipe(
      options,
      A.map((option): readonly [EnderId, SelectOption<EnderId, Market>] => {
        return [option.value, option];
      }),
      HM.fromIterable,
    );
  }, [options]);

  const [keyword, setKeywordValue] = useState<string>("");
  const filteredOptions = useMemo(() => {
    const searchText = Str.toUpperCase(keyword);
    return F.pipe(
      options,
      A.filter((opt) =>
        F.pipe(opt.label, Str.toUpperCase, Str.includes(searchText)),
      ),
    );
  }, [keyword, options]);

  const selected = useMemo(
    () =>
      F.pipe(
        value,
        A.flatMap((id) => F.pipe(idToOption, HM.get(id), O.toArray)),
      ),
    [value, idToOption],
  );
  const handleChange = useCallback(
    (selected: SelectOption<EnderId, Market>[]) => {
      const selectedIds = F.pipe(
        selected,
        A.map((opt) => opt.value),
      );
      const selectedMarkets = F.pipe(
        selected,
        A.flatMap((opt) => F.pipe(opt.meta, O.fromNullable, O.toArray)),
      );
      onChange(selectedIds, selectedMarkets);
    },
    [onChange],
  );

  const triggerLabel = useMemo(() => {
    const headLabel = F.pipe(
      selected,
      A.head,
      O.map((opt) => `: ${opt.label}`),
      O.getOrElse(F.constant("")),
    );
    const countLabel = Match.value(A.length(selected)).pipe(
      Match.when(Num.greaterThan(1), (size) => ` +${size - 1}`),
      Match.orElse(F.constant("")),
    );
    return `Market${headLabel}${countLabel}`;
  }, [selected]);

  return (
    <MultiFilter
      label="Market Filter"
      data={filteredOptions}
      disabled={disabled}
      keyword={keyword}
      isLoading={isFetching}
      onChange={handleChange}
      onKeywordChange={setKeywordValue}
      ref={ref}
      trigger={
        <TriggerButton
          rounded={false}
          disabled={disabled}
          size={TriggerButtonSize.sm}>
          {triggerLabel}
        </TriggerButton>
      }
      value={selected}
    />
  );
});

export { MarketFilter };
export type { MarketFilterProps };
