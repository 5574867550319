import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionLineItemLineItemRevisionActionValues = [
  "PHOTOS_ADDED",
  "COMMENT_ADDED",
  "PRICE_UPDATED",
] as const;

const InspectionLineItemLineItemRevisionActionEffectSchema = Schema.Literal(
  ...InspectionLineItemLineItemRevisionActionValues,
);

type InspectionLineItemLineItemRevisionAction = Schema.Schema.Type<
  typeof InspectionLineItemLineItemRevisionActionEffectSchema
>;

const InspectionLineItemLineItemRevisionActionEnum = castEnum(
  InspectionLineItemLineItemRevisionActionEffectSchema,
);

function randomInspectionLineItemLineItemRevisionAction(): InspectionLineItemLineItemRevisionAction {
  return rand(InspectionLineItemLineItemRevisionActionValues);
}

export {
  InspectionLineItemLineItemRevisionActionEffectSchema,
  InspectionLineItemLineItemRevisionActionEnum,
  InspectionLineItemLineItemRevisionActionValues,
  randomInspectionLineItemLineItemRevisionAction,
};
export type { InspectionLineItemLineItemRevisionAction };
