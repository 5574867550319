/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/getEndingBalancesForLeases
 * @function getEndingBalancesForLeases
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIGetEndingBalancesForLeasesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Partial<Record<EnderId, Money>>>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIGetEndingBalancesForLeasesSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIGetEndingBalancesForLeasesBodyParams = {
  leaseIds: EnderId[];
};

type LeasingMiddleLayerAPIGetEndingBalancesForLeasesPayload =
  LeasingMiddleLayerAPIGetEndingBalancesForLeasesSearchParams &
    LeasingMiddleLayerAPIGetEndingBalancesForLeasesBodyParams;

function getEndingBalancesForLeasesUnsafeEffect(
  payload: LeasingMiddleLayerAPIGetEndingBalancesForLeasesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Partial<Record<EnderId, Money>>>({
        body,
        decode: unsafeDecodeJsonResponse<Partial<Record<EnderId, Money>>>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/leasing/getEndingBalancesForLeases",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getEndingBalancesForLeases(
  payload: LeasingMiddleLayerAPIGetEndingBalancesForLeasesPayload,
  options?: { signal?: AbortSignal },
): Promise<Partial<Record<EnderId, Money>>> {
  return F.pipe(
    payload,
    getEndingBalancesForLeasesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getEndingBalancesForLeases };
export type {
  LeasingMiddleLayerAPIGetEndingBalancesForLeasesBodyParams,
  LeasingMiddleLayerAPIGetEndingBalancesForLeasesPayload,
  LeasingMiddleLayerAPIGetEndingBalancesForLeasesSearchParams,
};
