import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { IconEdit, IconTrash, IconUpload } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P } from "effect";
import * as S from "effect/String";
import type { ElementRef, ReactNode } from "react";
import { forwardRef, useCallback, useId } from "react";

import { ApplicantFile } from "@ender/entities/application/applicant-file";
import { Form, useForm } from "@ender/form-system/base";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Inset } from "@ender/shared/ds/inset";
import { Stack } from "@ender/shared/ds/stack";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { PetsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";

import { DisplayablePetInfo } from "./display-pet-info-fields";
import type { PetFormInput, PetFormOutput } from "./edit-pet-info-fields";
import { EditablePetInfo, PetFormSchema } from "./edit-pet-info-fields";

type ApplicationPetCardProps = {
  pet: Pet;
  isEditable?: boolean;
  isDeletable?: boolean;
  /**
   * invoked when the delete operation completes successfully.
   */
  onDeleteSuccess?: () => void;
  /**
   * invoked when the edit operation completes successfully.
   */
  onEditSuccess?: () => void;
  header?: ReactNode;
  onUploadClick?: () => void;

  canDeleteDocuments?: boolean;
  onDeletePhoto?: (file: FilesClientEnderFile) => void;
  onEditFileClick?: (file: FilesClientEnderFile) => void;
};

const ApplicationPetCard = forwardRef<
  ElementRef<typeof Card>,
  ApplicationPetCardProps
>(function ApplicationPetCard(props, ref) {
  const {
    isEditable = false,
    isDeletable = false,
    pet,
    onDeleteSuccess = F.constVoid,
    onEditSuccess = F.constVoid,
    onUploadClick = F.constVoid,
    header,
    canDeleteDocuments = false,
    onDeletePhoto = F.constVoid,
    onEditFileClick = F.constVoid,
  } = props;
  const { id: petId, emotionalSupportAnimalTime } = pet;

  const [editing, editingHandlers] = useBoolean(false);

  const form = useForm<PetFormInput>({
    defaultValues: {
      ...pet,
      species: O.fromNullable(pet.species),
      isEmotionalSupportAnimal: O.fromNullable(
        P.isNotNullable(emotionalSupportAnimalTime),
      ),
      age: O.fromNullable(pet.age),
      weightInPounds: O.fromNullable(pet.weightInPounds),
      isNeutered: O.fromNullable(pet.isNeutered),
    },
    resolver: effectTsResolver(PetFormSchema),
  });

  const { mutateAsync: deletePet } = useMutation({
    mutationFn: PetsAPI.archivePet,
    mutationKey: ["PetsAPI.archivePet"] as const,
  });
  const { data: petFiles = [] } = useQuery(
    [
      "WebserverFilesAPI.getFiles",
      {
        modelType: ModelTypeEnum.PET,
        modelId: petId,
        uploadType: WebserverFilesServiceFileUploadTypeEnum.PET_DOCUMENT,
      },
    ] as const,
    ({ queryKey: [, payload] }) => WebserverFilesAPI.getFiles(payload),
    {
      select: (data) => data.files,
      enabled: P.isNotNullable(pet.documentUploadedTime),
    },
  );

  const confirmation = useConfirmationContext();
  const handleDeletePet = useCallback(async () => {
    await confirmation({
      content: `${pet.name} - ${pet.species}${S.isNonEmpty(pet.breed) ? `, ${pet.breed}` : ""}`,
      title: "Are you sure you want to remove the following pet?",
    });
    await deletePet({ petId: pet.id });
    showSuccessNotification({ message: "Pet removed." });
    onDeleteSuccess();
  }, [confirmation, deletePet, pet, onDeleteSuccess]);

  const { mutateAsync: updatePet } = useMutation({
    mutationFn: PetsAPI.updatePet,
    mutationKey: ["PetsAPI.updatePet"] as const,
  });
  const handleUpdatePet = useCallback(
    async (values: PetFormOutput) => {
      await updatePet({
        ...values,
        age: O.getOrUndefined(values.age),
        isEmotionalSupportAnimal: O.getOrElse(
          values.isEmotionalSupportAnimal,
          F.constFalse,
        ),
        isNeutered: O.getOrElse(values.isNeutered, F.constFalse),
        petId,
        species: O.getOrUndefined(values.species),
        weightInPounds: O.getOrUndefined(values.weightInPounds),
      });
      onEditSuccess();
      editingHandlers.setFalse();
    },
    [petId, onEditSuccess, editingHandlers, updatePet],
  );
  const headingId = useId();

  const { reset: resetForm } = form;
  const handleCancelClick = useCallback(() => {
    editingHandlers.setFalse();
    resetForm();
  }, [editingHandlers, resetForm]);

  return (
    <Card ref={ref} labelledBy={headingId}>
      <Form form={form} onSubmit={handleUpdatePet}>
        <Stack>
          <Group justify={Justify.between}>
            <span id={headingId}>{header}</span>
            <Inset r={Spacing.sm} t={Spacing.sm} b={Spacing.sm}>
              <Group align={Align.start} spacing={Spacing.xs}>
                {isEditable && (
                  <>
                    {editing ? (
                      <Group>
                        <Button
                          variant={ButtonVariant.transparent}
                          onClick={handleCancelClick}>
                          Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                      </Group>
                    ) : (
                      <ActionIcon
                        variant={ButtonVariant.transparent}
                        onClick={editingHandlers.setTrue}>
                        <IconEdit />
                      </ActionIcon>
                    )}
                  </>
                )}
                {isDeletable && (
                  <ActionIcon
                    color={Color.red}
                    variant={ButtonVariant.transparent}
                    onClick={handleDeletePet}>
                    <IconTrash />
                  </ActionIcon>
                )}
              </Group>
            </Inset>
          </Group>
          <Grid underline="uneven" spacingY={Spacing.none}>
            {editing ? (
              <EditablePetInfo form={form} />
            ) : (
              <DisplayablePetInfo form={form} />
            )}
          </Grid>
          <Group justify={Justify.between}>
            <span>Pet Documents</span>
            <Inset r={Spacing.sm} t={Spacing.sm} b={Spacing.sm}>
              <ActionIcon
                variant={ButtonVariant.transparent}
                onClick={onUploadClick}>
                <IconUpload />
              </ActionIcon>
            </Inset>
          </Group>
          <Grid underline="none" spacingY={Spacing.none}>
            {petFiles.map((file) => (
              <ApplicantFile
                file={file}
                key={file.id}
                canDelete={canDeleteDocuments}
                onDelete={onDeletePhoto}
                canEdit={canDeleteDocuments}
                onEdit={onEditFileClick}
              />
            ))}
          </Grid>
        </Stack>
      </Form>
    </Card>
  );
});

export { ApplicationPetCard };

export type { ApplicationPetCardProps };
