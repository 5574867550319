/**
 * HTTP Method: POST
 * Pathname: /setCustomField
 * @function setCustomField
 * @memberof FactorsAPI
 * @param {FactorsAPISetCustomFieldPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPISetCustomFieldSearchParams = {
  token?: string | undefined;
};

type FactorsAPISetCustomFieldBodyParams = {
  factorId: EnderId;
  modelId: EnderId;
  modelType: ModelType;
  value: string;
};

type FactorsAPISetCustomFieldPayload = FactorsAPISetCustomFieldSearchParams &
  FactorsAPISetCustomFieldBodyParams;

function setCustomFieldUnsafeEffect(payload: FactorsAPISetCustomFieldPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/setCustomField",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setCustomField(
  payload: FactorsAPISetCustomFieldPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setCustomFieldUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setCustomField };
export type {
  FactorsAPISetCustomFieldBodyParams,
  FactorsAPISetCustomFieldPayload,
  FactorsAPISetCustomFieldSearchParams,
};
