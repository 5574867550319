import type { Option as O } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import type { Money$ } from "@ender/shared/core";
import { Divider } from "@ender/shared/ds/divider";
import { Spacing } from "@ender/shared/ds/flex";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";

type BankAccountDetailsProps = {
  selectedBankAccount: BankAccount | Undefined;
  amount: O.Option<Money$.Money>;
};

function BankAccountDetails({
  selectedBankAccount,
  amount,
}: BankAccountDetailsProps) {
  return (
    <Stack spacing={Spacing.xs}>
      {selectedBankAccount && (
        <>
          <Tuple label="Account Name" value={selectedBankAccount.name} />
          <Tuple
            label="Account Number"
            value={`••••${selectedBankAccount.mask}`}
          />
          <Tuple
            label="Account Institution"
            value={selectedBankAccount.institution || "--"}
          />
        </>
      )}
      <Tuple
        label="Amount"
        value={<MoneyDisplay showSymbol value={amount} />}
      />
      <Divider />
    </Stack>
  );
}

export { BankAccountDetails };
