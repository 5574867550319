/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/communityData
 * @function getCommunityData
 * @memberof BuyAPI
 * @param {BuyAPIGetCommunityDataPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetCommunityDataResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetCommunityDataResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetCommunityDataPathParams = {
  dealId: EnderId;
};

type BuyAPIGetCommunityDataSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetCommunityDataPayload = BuyAPIGetCommunityDataPathParams &
  BuyAPIGetCommunityDataSearchParams;

function getCommunityDataUnsafeEffect(payload: BuyAPIGetCommunityDataPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetCommunityDataResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetCommunityDataResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/communityData`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCommunityData(
  payload: BuyAPIGetCommunityDataPayload,
  options?: { signal?: AbortSignal },
): Promise<GetCommunityDataResponse> {
  return F.pipe(
    payload,
    getCommunityDataUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCommunityData };
export type {
  BuyAPIGetCommunityDataPathParams,
  BuyAPIGetCommunityDataPayload,
  BuyAPIGetCommunityDataSearchParams,
};
