import { Schema as S } from "@effect/schema";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { Option as O, String as Str } from "effect";
import { useCallback, useContext, useMemo } from "react";
import { useWatch } from "react-hook-form";

import { Form, FormField, useEffectSchemaForm } from "@ender/form-system/base";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, ParseEnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align } from "@ender/shared/ds/flex";
import { InputWrapper } from "@ender/shared/ds/input";
import type { SelectValue } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { TemplatesAPIRenderTemplateResponse } from "@ender/shared/generated/ender.api.documents";
import { TemplatesAPI } from "@ender/shared/generated/ender.api.documents";
import type { RenewalsAPISendRenewalOffersPayload } from "@ender/shared/generated/ender.api.leasing";
import { RenewalsAPI } from "@ender/shared/generated/ender.api.leasing";
import { TextTemplateTemplateTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { RichTextEditor } from "@ender/shared/ui/rich-text-editor";
import {
  showLoadingNotification,
  showSuccessNotification,
} from "@ender/shared/utils/notifications";

import type { RenewalActionsProps } from "../renewals.types";

const SendOffersFormSchema = S.Struct({
  templateId: S.OptionFromSelf(EnderIdFormSchema),
  message: S.String.pipe(
    S.nonEmptyString({ message: () => "Message is required" }),
  ),
});

type SendOffersFormValues = S.Schema.Type<typeof SendOffersFormSchema>;

type SendRenewalOfferFormProps = Pick<
  RenewalActionsProps,
  "lease" | "refetchRenewalPackage"
>;

function SendRenewalOfferForm(props: SendRenewalOfferFormProps) {
  const { lease, refetchRenewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);

  const form = useEffectSchemaForm({
    defaultValues: {
      templateId: O.none(),
      message: "",
    },
    schema: SendOffersFormSchema,
  });

  const { data: templateOptions = [] } = useQuery({
    queryFn: () =>
      TemplatesAPI.listTemplates({
        type: TextTemplateTemplateTypeEnum.LEASE_RENEWAL,
      }),
    queryKey: [
      "TemplatesAPI.listTemplates",
      TextTemplateTemplateTypeEnum.LEASE_RENEWAL,
    ],
    select: (data) =>
      data.map((template: { id: EnderId; name: string }) => ({
        key: template.id,
        label: template.name,
        value: template.id,
      })),
  });

  const [formTemplateId, formMessage] = useWatch({
    control: form.control,
    name: ["templateId", "message"],
  });

  const renderedTemplates = useQueries({
    queries: templateOptions.map(({ value: templateId }) => ({
      queryFn: () =>
        TemplatesAPI.renderTemplate({
          modelId: lease.id,
          modelType: ModelTypeEnum.LEASE,
          templateId,
        }),
      queryKey: [
        "TemplatesAPI.renderTemplate",
        { templateId, modelId: lease.id },
      ] as const,
    })),
  });

  const templateMap = useMemo(() => {
    return templateOptions.reduce(
      (acc, { value: templateId }, index) => {
        acc[templateId] = renderedTemplates[index].data;
        return acc;
      },
      {} as Record<EnderId, TemplatesAPIRenderTemplateResponse>,
    );
  }, [templateOptions, renderedTemplates]);

  const onTemplateChange = useCallback(
    (value: O.Option<SelectValue>) => {
      const newTemplateId = O.map(value, (v) => ParseEnderId(v.toString()));

      form.setValue("templateId", newTemplateId);

      if (O.isSome(newTemplateId)) {
        form.setValue(
          "message",
          newTemplateId.pipe(
            O.map((id) => templateMap[id].html ?? templateMap[id].body),
            O.getOrElse(() => ""),
          ),
        );
      }
    },
    [form, templateMap],
  );

  const { mutateAsync: sendRenewalOffers, isLoading: isSendOffersLoading } =
    useMutation({
      mutationFn: (payload: RenewalsAPISendRenewalOffersPayload) =>
        RenewalsAPI.sendRenewalOffers(payload).then(() =>
          refetchRenewalPackage(),
        ),
      mutationKey: ["RenewalsAPI.sendRenewalOffers", refetchRenewalPackage],
    });

  const onSendOffers = useCallback(
    async (values: SendOffersFormValues) => {
      const [, clearLoadingNotification] = showLoadingNotification({
        message: `Sending Renewal Offers`,
      });

      try {
        await sendRenewalOffers({
          leaseIds: [lease.id],
          message: values.message,
        });

        showSuccessNotification({
          message: "Renewal Offers Sent",
        });
      } finally {
        clearLoadingNotification();
      }
    },
    [sendRenewalOffers, lease.id],
  );

  return (
    <Form form={form} onSubmit={onSendOffers}>
      <Stack align={Align.start}>
        <Select
          clearable
          value={formTemplateId}
          name="templateId"
          label="Renewal Offer Template"
          placeholder="Select Template"
          data={templateOptions}
          disabled={isSendOffersLoading}
          onChange={onTemplateChange}
        />
        <FormField name="message" form={form}>
          {({ field, error }) => (
            <InputWrapper
              inputId=""
              labelId=""
              errorId=""
              label="Message"
              error={error}
              {...field}>
              <RichTextEditor
                value={formMessage}
                onChange={(value) => form.setValue("message", value)}
                options={{
                  autoresize: true,
                  max_height: 600,
                  width: "100%",
                }}
                isReadOnly={
                  isSendOffersLoading ||
                  renderedTemplates.some(({ isLoading }) => isLoading)
                }
              />
            </InputWrapper>
          )}
        </FormField>
        <Button
          type="submit"
          loading={isSendOffersLoading}
          disabled={
            !hasPermissions(FunctionalPermissionEnum.SEND_RENEWAL_OFFER) ||
            !(O.isSome(formTemplateId) || Str.isNonEmpty(formMessage))
          }>
          Send Offers
        </Button>
      </Stack>
    </Form>
  );
}

export { SendRenewalOfferForm };
