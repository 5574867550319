import { Array as A, Predicate as P } from "effect";
import { create } from "zustand";

import type { EnderId } from "@ender/shared/core";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { cast } from "@ender/shared/types/cast";

type UserStoreState = {
  users: Record<EnderId, User>;
  getUser: (userId: EnderId) => Promise<User | undefined>;
  getUsers: (userIds: EnderId[]) => Promise<Record<EnderId, User>>;
  fetch: (userId: EnderId) => Promise<void>;
};

const useUserStore = create<UserStoreState>()((set, get) => ({
  fetch: async (userId: EnderId) => {
    const user = await UsersAPI.getUser({ targetId: userId });
    set((state) => ({
      users: {
        ...state.users,
        [userId]: user,
      },
    }));
  },
  getUser: async (userId: EnderId): Promise<User | undefined> => {
    const user = get().users[userId];
    if (P.isNotNullable(user)) {
      await get().fetch(userId);
    }
    return get().users[userId];
  },
  getUsers: async (userIds: EnderId[]): Promise<Record<EnderId, User>> => {
    const validUserIds = userIds.filter(P.isNotNullable); // protection for ENDER-17665
    const alreadyUsedIds = new Set();
    validUserIds.reduce((acc, userId) => {
      const user = get().users[userId];
      if (P.isNotNullable(user)) {
        acc[userId] = user;
        alreadyUsedIds.add(userId);
      }
      return acc;
    }, cast<Record<EnderId, User>>({}));
    const filteredIds = validUserIds.filter(
      (userId) => !alreadyUsedIds.has(userId),
    );
    if (A.isNonEmptyArray(filteredIds)) {
      await Promise.all(filteredIds.map((userId) => get().fetch(cast(userId))));
    }
    return get().users;
  },
  users: {},
}));

export { useUserStore };
