/**
 * HTTP Method: DELETE
 * Pathname: /buy/deals/{dealId}/inspections/{inspectionId}
 * @function deleteInspection
 * @memberof BuyAPI
 * @param {BuyAPIDeleteInspectionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIDeleteInspectionPathParams = {
  dealId: EnderId;
  inspectionId: EnderId;
};

type BuyAPIDeleteInspectionSearchParams = {
  token?: string | undefined;
};

type BuyAPIDeleteInspectionPayload = BuyAPIDeleteInspectionPathParams &
  BuyAPIDeleteInspectionSearchParams;

function deleteInspectionUnsafeEffect(payload: BuyAPIDeleteInspectionPayload) {
  const { dealId, inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/buy/deals/${dealId}/inspections/${inspectionId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteInspection(
  payload: BuyAPIDeleteInspectionPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteInspectionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteInspection };
export type {
  BuyAPIDeleteInspectionPathParams,
  BuyAPIDeleteInspectionPayload,
  BuyAPIDeleteInspectionSearchParams,
};
