import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { LocalDate$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { DateInput } from "@ender/shared/ds/date-input";
import { Option as O, pipe } from "effect";

import { MoveInStep } from "../move-in-step";
import type { ChecklistStepComponentProps } from "./checklist-steps.types";

function DateStep(props: ChecklistStepComponentProps) {
  const {
    completed,
    capturedData = "",
    completedTime,
    onChange,
    ...stepProps
  } = props;

  return (
    <MoveInStep {...stepProps} completedTime={completedTime}>
      <Group spacing={Spacing.none} noWrap>
        <DateInput
          disabled={completed}
          clearable={false}
          aria-label="Select Date"
          value={O.fromNullable(capturedData).pipe(
            O.filter((data) => data.length > 0),
            O.map((data) => LocalDate$.parse(data)),
            O.flatten
          )}
          onChange={(date: O.Option<LocalDate$.LocalDate>) =>
            onChange({
              capturedData: pipe(
                date,
                O.map((d) => d.toJSON()),
                O.getOrElse(() => "")
              ),
              completed: O.isSome(date),
            })
          }
        />
        {Boolean(completedTime) && (
          <Button
            variant={ButtonVariant.transparent}
            onClick={() => onChange({ capturedData: "", completed: false })}
            disabled={completed}>
            Clear
          </Button>
        )}
      </Group>
    </MoveInStep>
  );
}

export { DateStep };
