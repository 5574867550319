import { cva } from "class-variance-authority";
import { Match, Option as O, String as Str, pipe } from "effect";
import { forwardRef } from "react";

import type { SignFormats } from "@ender/shared/core";
import { CURRENCY_FORMATS, Money$, SignFormat } from "@ender/shared/core";

function toDisplay({
  format = "DEFAULT",
  signFormat = SignFormat.parenthesis,
  showSymbol = false,
}: {
  format?: "DEFAULT" | "DOLLARS";
  signFormat?: SignFormats;
  showSymbol?: boolean;
} = {}) {
  const signFormatter = Match.value(signFormat).pipe(
    Match.when(SignFormat.parenthesis, () => (v: string) => `(${v})`),
    Match.when(SignFormat.minus, () => (v: string) => `-${v}`),
    Match.orElseAbsurd,
  );

  return (value: Money$.Money) => {
    const isNegative = Money$.isNegative(value);
    return pipe(
      value,
      Money$.abs,
      Money$.toFormatted(format),
      Str.replace("$", ""),
      (v) => (isNegative ? signFormatter(v) : v),
      (v) => (showSymbol ? `$${v}` : v),
    );
  };
}

type MoneyDisplayProps = {
  /**
   * The amount to display
   */
  value?: Money$.Money | O.Option<Money$.Money>;
  /**
   * prefix the value with a "$".
   * @default false
   */
  showSymbol?: boolean;
  /**
   * show cents if true, rounded dollars if false.
   * @default true
   */
  showDecimals?: boolean;
  /**
   * How to represent negative numbers.
   *
   * to represent the amount "-123":
   *
   * ```
   * SignFormat.parenthesis:  (100)
   * SignFormat.minus:        -100
   * ```
   *
   * @default SignFormat.parenthesis
   */
  signFormat?: SignFormats;
};

const MoneyVariantGenerator = cva([], {
  defaultVariants: {},
  variants: {},
});

const MoneyVariantGeneratorConstant = MoneyVariantGenerator();

const MoneyDisplay = forwardRef<HTMLSpanElement, MoneyDisplayProps>(
  function MoneyDisplay(props, ref) {
    const {
      value,
      showDecimals = true,
      showSymbol = false,
      signFormat = SignFormat.parenthesis,
    } = props;

    const _value = O.isOption(value) ? value : O.fromNullable(value);
    const format = showDecimals
      ? CURRENCY_FORMATS.DEFAULT
      : CURRENCY_FORMATS.DOLLARS;

    return (
      <span ref={ref} className={MoneyVariantGeneratorConstant}>
        {_value.pipe(
          O.map(toDisplay({ format, showSymbol, signFormat })),
          O.getOrNull,
        )}
      </span>
    );
  },
);

export { MoneyDisplay };

export type { MoneyDisplayProps };
