/**
 * HTTP Method: GET
 * Pathname: /listings/{listingId}
 * @function getListingDetailsByListingId
 * @memberof LeasingAPI
 * @param {LeasingAPIGetListingDetailsByListingIdPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeasingServiceListingResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeasingServiceListingResponse } from "@ender/shared/generated/ender.service.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetListingDetailsByListingIdPathParams = {
  listingId: EnderId;
};

type LeasingAPIGetListingDetailsByListingIdSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetListingDetailsByListingIdPayload =
  LeasingAPIGetListingDetailsByListingIdPathParams &
    LeasingAPIGetListingDetailsByListingIdSearchParams;

function getListingDetailsByListingIdUnsafeEffect(
  payload: LeasingAPIGetListingDetailsByListingIdPayload,
) {
  const { listingId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeasingServiceListingResponse>({
        body,
        decode: unsafeDecodeJsonResponse<LeasingServiceListingResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/listings/${listingId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getListingDetailsByListingId(
  payload: LeasingAPIGetListingDetailsByListingIdPayload,
  options?: { signal?: AbortSignal },
): Promise<LeasingServiceListingResponse> {
  return F.pipe(
    payload,
    getListingDetailsByListingIdUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getListingDetailsByListingId };
export type {
  LeasingAPIGetListingDetailsByListingIdPathParams,
  LeasingAPIGetListingDetailsByListingIdPayload,
  LeasingAPIGetListingDetailsByListingIdSearchParams,
};
