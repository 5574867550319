import { zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import { isEmpty } from "effect/String";
import { useCallback, useState } from "react";
import { ZodIssueCode, z } from "zod";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { PhoneInput } from "@ender/shared/ds/phone-input";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { useForm } from "@ender/shared/forms/hooks/general";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationApplicantType } from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationApplicantTypeEnum,
  ApplicationApplicantTypeValues,
} from "@ender/shared/generated/ender.model.leasing";
import { cast } from "@ender/shared/types/cast";
import { PhoneSchema } from "@ender/shared/types/ender-general";
import { EnderDatePicker } from "@ender/shared/ui/ender-date-picker";
import { Select } from "@ender/shared/ui/select";
import { EnderDate, EnderDateSchema } from "@ender/shared/utils/ender-date";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

function getTotalResponsibleOccupants(
  applications: GetApplicationGroupResponseApplicationResponse[],
): number {
  return applications.reduce(
    (total, p) =>
      p.applicantType === ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT
        ? total + 1
        : total,
    0,
  );
}

function getTotalContactableOccupants(
  applications: GetApplicationGroupResponseApplicationResponse[],
): number {
  return applications.reduce(
    (total, p) => (isEmpty(p.applicant.email) ? total : total + 1),
    0,
  );
}

const responsibleApplicantTypes: readonly ApplicationApplicantType[] = [
  ApplicationApplicantTypeEnum.GUARANTOR,
  ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
] as const;

const AddUpdateApplicantSchema = z
  .object({
    applicantType: z
      .enum(ApplicationApplicantTypeValues)
      .nullable()
      .refine(P.isNotNullable, {
        message: "Required",
      }),
    birthday: EnderDateSchema.nullable().refine(P.isNotNullable, {
      message: "Required",
    }),
    email: z.string(),
    firstName: z.string().min(2, "First Name must be at least two characters"),
    id: EnderIdSchema.nullable(),
    lastName: z.string().min(2, "Last Name must be at least two characters"),
    phone: PhoneSchema.or(z.literal("")),
  })
  .refine(
    (schema) => {
      return (
        P.isNullable(schema.birthday) ||
        P.isNullable(schema.applicantType) ||
        !responsibleApplicantTypes.includes(schema.applicantType) ||
        !EnderDate.isBirthdayMinor(schema.birthday)
      );
    },
    {
      message: "Financially responsible applicants cannot be minors",
      path: ["birthday"],
    },
  );

type AddUpdateApplicantFormInput = z.input<typeof AddUpdateApplicantSchema>;
type AddUpdateApplicantFormOutput = z.output<typeof AddUpdateApplicantSchema>;

const applicantTypeValues = [
  {
    label: "Responsible Occupant",
    value: ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
  },
  {
    label: "Other Occupant",
    value: ApplicationApplicantTypeEnum.OTHER_OCCUPANT,
  },
  { label: "Guarantor", value: ApplicationApplicantTypeEnum.GUARANTOR },
] as const;

type CreateUpdateApplicantFormProps = {
  application?: GetApplicationGroupResponseApplicationResponse;
  applicationGroupId: EnderId;
  applications: GetApplicationGroupResponseApplicationResponse[];
  isEditable?: boolean;
  onCancel?: () => void;
  onSuccess?: () => void;
};

function CreateUpdateApplicantForm({
  application,
  applicationGroupId,
  applications,
  isEditable = false,
  onCancel = F.constVoid,
  onSuccess = F.constVoid,
}: CreateUpdateApplicantFormProps) {
  const { applicant } = application ?? {};

  const form = useForm<AddUpdateApplicantFormInput>({
    initialValues: {
      applicantType: application?.applicantType || NULL,
      birthday: applicant?.birthday ? EnderDate.of(applicant.birthday) : NULL,
      email: applicant?.email || cast(""),
      firstName: applicant?.firstName || "",
      id: applicant?.userId ?? NULL,
      lastName: applicant?.lastName || "",
      phone: applicant?.phone || cast(""),
    },
    validate: zodResolver(
      AddUpdateApplicantSchema.superRefine((schema, ctx) => {
        const applicationsWithoutApplicant = applications.filter(
          (a) => a.applicant?.userId !== schema.id,
        );
        const responsibleOtherApplicants = applicationsWithoutApplicant.filter(
          (a) => responsibleApplicantTypes.includes(a.applicantType),
        );
        //if the applicant type is not responsible occupant
        if (!responsibleApplicantTypes.includes(schema.applicantType)) {
          // if there are no other responsible occupants at this time.
          // this is a backup check to ensure that the last responsible occupant cannot be removed
          if (
            getTotalResponsibleOccupants(applicationsWithoutApplicant) === 0
          ) {
            ctx.addIssue({
              message: "You must have at least one responsible occupant",
              code: ZodIssueCode.custom,
              path: ["applicantType"],
            });
          } else if (
            getTotalContactableOccupants(responsibleOtherApplicants) === 0
          ) {
            ctx.addIssue({
              message:
                "You may not remove the last contactable responsible occupant",
              code: ZodIssueCode.custom,
              path: ["applicantType"],
            });
          }
          //if the applicant is responsible but has no email
        } else if (isEmpty(schema.email)) {
          if (getTotalContactableOccupants(responsibleOtherApplicants) === 0) {
            ctx.addIssue({
              message:
                "At least one responsible occupant must have contact information",
              code: ZodIssueCode.custom,
              path: ["email"],
            });
          }
        }
      }),
    ),
  });

  const [isMinor, setIsMinor] = useState(
    applicant?.birthday ? EnderDate.isBirthdayMinor(applicant.birthday) : false,
  );
  const { mutateAsync: createApplicant } = useMutation({
    mutationFn: (values: AddUpdateApplicantFormOutput) =>
      //TODO use LocalDate instead of EnderDate here
      ApplicationsAPI.addPersonToApplication({
        applicationGroupId,
        ...values,
        //@ts-expect-error EnderDate serializes to a valid payload for the API
        type: values.applicantType,
      }),
    mutationKey: ["ApplicationsAPI.addPersonToApplication"] as const,
  });
  const { mutateAsync: saveApplicant, isLoading } = useMutation({
    mutationKey: [
      "UsersAPI.updateUser",
      "ApplicationsAPI.changeOccupantRole",
    ] as const,
    mutationFn: async (values: AddUpdateApplicantFormOutput) => {
      if (P.isNotNullable(values.id) && P.isNotNullable(application)) {
        const { applicantType, ...json } = values;
        await UsersAPI.updateUser({ json, targetUserId: values.id });
        if (isEditable) {
          await ApplicationsAPI.changeOccupantRole({
            applicationGroupId,
            applicationId: application.applicationId,
            relationship: application?.relationshipType,
            type: applicantType,
          });
        }
      }
    },
  });

  const handleSubmit = useCallback(
    async (values: AddUpdateApplicantFormOutput) => {
      if (P.isNotNullable(values.id)) {
        await saveApplicant(values);
        showSuccessNotification({ message: "Applicant Updated" });
      } else {
        await createApplicant(values);
        showSuccessNotification({ message: "Applicant Added" });
      }
      onSuccess();
    },
    [createApplicant, saveApplicant, onSuccess],
  );

  function onBirthdayBlur() {
    const { birthday } = form.values;
    const _isMinor = birthday ? EnderDate.isBirthdayMinor(birthday) : false;
    setIsMinor(_isMinor);
    if (_isMinor) {
      form.setFieldValue("email", "");
      form.setFieldValue("phone", "");
    }
  }

  const disableSubmit =
    form.values.applicantType !==
      ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT &&
    application?.applicantType ===
      ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT &&
    getTotalResponsibleOccupants(applications) < 2;

  return (
    //@ts-expect-error handleSubmit accepts a form's TransformedValues but zod output is preferable
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Select
          data={applicantTypeValues}
          disabled={!isEditable}
          label="Applicant Type"
          placeholder="Select Type"
          {...form.getInputProps("applicantType")}
        />
        <TextInput
          label="First Name"
          placeholder="Enter First Name"
          {...form.getInputProps("firstName")}
        />
        <TextInput
          label="Last Name"
          placeholder="Enter Last Name"
          {...form.getInputProps("lastName")}
        />
        <EnderDatePicker
          defaultValue={undefined}
          label="Date of Birth"
          onBlur={onBirthdayBlur}
          styles={{ wrapper: { width: "100%" } }}
          {...form.getInputProps("birthday")}
        />
        <TextInput
          disabled={isMinor}
          label="Email Address"
          placeholder="Enter Email Address"
          {...form.getInputProps("email")}
        />
        <PhoneInput
          disabled={isMinor}
          label="Phone Number (optional)"
          placeholder="Enter Phone Number"
          {...form.getInputProps("phone")}
        />

        <Group justify={Justify.end}>
          <Button
            onClick={onCancel}
            type="button"
            variant={ButtonVariant.transparent}>
            Cancel
          </Button>
          <Button
            disabled={disableSubmit}
            disabledTooltip="There must be at least one responsible occupant for the application"
            loading={isLoading}
            type="submit">
            {P.isNotNullable(applicant) ? "Save" : "Add Applicant"}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

export { CreateUpdateApplicantForm };
