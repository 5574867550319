import { IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import type { ReactNode } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";

type DeleteStepButtonProps = {
  disabled?: boolean;
  onSuccess: () => Promise<void>;
  stepId: EnderId;
  tooltip?: ReactNode;
};

function DeleteStepButton({
  disabled,
  onSuccess,
  stepId,
  tooltip,
}: DeleteStepButtonProps) {
  const confirmation = useConfirmationContext();

  const { mutate: deleteStep, isLoading: isDeleteInProgress } = useMutation({
    mutationFn: async () => {
      await confirmation(
        {
          confirmButtonLabel: "Delete",
          title: "Are you sure you want to delete this step?",
        },
        { confirmButtonProps: { color: Color.red } },
      );
      await ApprovalsAPI.archiveStep({ stepId });
      onSuccess();
      showSuccessNotification({ message: "Successfully deleted step." });
    },
  });

  return (
    <>
      <Group align={Align.center} justify={Justify.end}>
        <Button
          variant={ButtonVariant.transparent}
          color={Color.red}
          onClick={() => deleteStep()}
          loading={isDeleteInProgress}
          disabled={disabled}
          disabledTooltip={tooltip}
          tooltip={tooltip}
          aria-label="remove step">
          <IconTrash />
        </Button>
      </Group>
    </>
  );
}

export { DeleteStepButton };
