import type { z } from "zod";

import { InputInfoPropsSchema } from "@ender/shared/forms/types/general";
import { ContextAwareInput } from "@ender/shared/forms/ui/context-aware-input";
import {
  RangeSliderBasePropsSchema as EnderSliderRangeBasePropsSchema_,
  RangeSlider as EnderSliderRange_,
} from "@ender/shared/ui/range-slider";

/** @deprecated This should not be used anymore. */
const EnderSliderRangePropsSchema =
  EnderSliderRangeBasePropsSchema_.merge(InputInfoPropsSchema);
/** @deprecated This should not be used anymore. */
type EnderSliderRangeProps = z.infer<typeof EnderSliderRangePropsSchema>;

/** @deprecated This should not be used anymore. */
function EnderSliderRange(props: EnderSliderRangeProps & { name: string }) {
  const { name, ...rest } = props;
  return (
    <ContextAwareInput Component={EnderSliderRange_} name={name} props={rest} />
  );
}

export { EnderSliderRange, EnderSliderRangePropsSchema };
export type { EnderSliderRangeProps };
