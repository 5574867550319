import { useId } from "react";

import type { FormSubSectionReference } from "@ender/form-system/base";
import { FormSection } from "@ender/form-system/base";
import { LocalDate$ } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { FormSelect } from "@ender/shared/ds/select";
import { FontSize, Text } from "@ender/shared/ds/text";
import { LeaseMoveOutReasonValues } from "@ender/shared/generated/ender.model.leasing";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

import type {
  MoveOutFormInput,
  PhysicalMoveOutSectionFormInput,
} from "./move-out-form.schema";

const LeaseMoveOutOptions = LeaseMoveOutReasonValues.map((value) => ({
  label: convertSnakeCaseToTitleCase(value),
  value,
}));

type PhysicalMoveOutSectionProps = FormSubSectionReference<
  MoveOutFormInput,
  PhysicalMoveOutSectionFormInput
>;

function PhysicalMoveOutSection(props: PhysicalMoveOutSectionProps) {
  const { form, name } = props;
  const id = useId();
  return (
    <Card labelledBy={id}>
      <H2 id={id}>Physical Move Out Date</H2>
      <Text size={FontSize.sm}>
        This is the date the tenant returned their keys and relinquished control
        of the unit.
      </Text>
      <Group
        spacing={Spacing.xl}
        align={Align.start}
        noWrap
        justify={Justify.center}>
        <FormSection name={name} form={form}>
          {({ section }) => (
            <>
              <FormDateInput
                form={form}
                label="Expected Move Out Date"
                name={section.expectedMoveOutDate}
              />
              <FormDateInput
                form={form}
                label="Confirmed Move Out Date"
                maxDate={LocalDate$.today()}
                name={section.actualMoveOutDate}
              />
              <FormSelect
                data={LeaseMoveOutOptions}
                form={form}
                label="Move Out Reason"
                name={section.moveOutReason}
              />
            </>
          )}
        </FormSection>
      </Group>
    </Card>
  );
}

export { PhysicalMoveOutSection };
