/**
 * HTTP Method: POST
 * Pathname: /applications/{appGroupId}/people/{applicationId}/resendIncomeVerificationInvitation
 * @function resendIncomeVerificationInvitation
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIResendIncomeVerificationInvitationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIResendIncomeVerificationInvitationPathParams = {
  appGroupId: EnderId;
  applicationId: EnderId;
};

type ApplicationsAPIResendIncomeVerificationInvitationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIResendIncomeVerificationInvitationPayload =
  ApplicationsAPIResendIncomeVerificationInvitationPathParams &
    ApplicationsAPIResendIncomeVerificationInvitationSearchParams;

function resendIncomeVerificationInvitationUnsafeEffect(
  payload: ApplicationsAPIResendIncomeVerificationInvitationPayload,
) {
  const { appGroupId, applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${appGroupId}/people/${applicationId}/resendIncomeVerificationInvitation`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function resendIncomeVerificationInvitation(
  payload: ApplicationsAPIResendIncomeVerificationInvitationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    resendIncomeVerificationInvitationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { resendIncomeVerificationInvitation };
export type {
  ApplicationsAPIResendIncomeVerificationInvitationPathParams,
  ApplicationsAPIResendIncomeVerificationInvitationPayload,
  ApplicationsAPIResendIncomeVerificationInvitationSearchParams,
};
