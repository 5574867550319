import { IconPlus } from "@tabler/icons-react";
import { Predicate as P } from "effect";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Modal } from "@ender/shared/ds/modal";
import type {
  ApprovalProcessType,
  ApprovalStepApprovalPhase,
} from "@ender/shared/generated/ender.model.approvals";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import type { ApprovalProcessHybridId } from "../types";
import { NEW_APPROVAL_PROCESS_HYBRID_ID } from "../types";
import { CreateUpdateStepForm } from "./create-update-step-form";

type AddStepButtonProps = {
  approvalProcessHybridId: ApprovalProcessHybridId;
  approvalProcessPhase?: ApprovalStepApprovalPhase;
  disableAddStepButton?: boolean;
  label?: string;
  missingEditPermissions?: ReactNode;
  onSuccess: () => Promise<void>;
  type: ApprovalProcessType;
};

function AddStepButton({
  approvalProcessHybridId,
  approvalProcessPhase,
  disableAddStepButton,
  label = "Add Approval Step",
  onSuccess,
  missingEditPermissions,
  type,
}: AddStepButtonProps) {
  const hasEditPermission = P.isNullable(missingEditPermissions);
  const isNewApprovalProcess =
    approvalProcessHybridId === NEW_APPROVAL_PROCESS_HYBRID_ID;

  const { disabled, disabledTooltip } = useMemo(() => {
    const disabled =
      !hasEditPermission || isNewApprovalProcess || disableAddStepButton;
    let disabledTooltip: ReactNode = "";
    if (!hasEditPermission) {
      disabledTooltip = missingEditPermissions;
    } else if (isNewApprovalProcess) {
      disabledTooltip = `Create a new process first`;
    }

    return {
      disabled,
      disabledTooltip,
    };
  }, [
    hasEditPermission,
    isNewApprovalProcess,
    missingEditPermissions,
    disableAddStepButton,
  ]);

  const [isModalOpen, { setTrue: _openModal, setFalse: closeModal }] =
    useBoolean();
  const openModal = useCallback(() => {
    if (!hasEditPermission) {
      return;
    }
    _openModal();
  }, [_openModal, hasEditPermission]);

  return (
    <>
      <Button
        variant={ButtonVariant.transparent}
        disabled={disabled}
        disabledTooltip={disabledTooltip}
        onClick={openModal}
        leftSection={<IconPlus />}>
        {label}
      </Button>
      <Modal title="Add Step" opened={isModalOpen} onClose={closeModal}>
        <CreateUpdateStepForm
          approvalProcessHybridId={approvalProcessHybridId}
          approvalProcessPhase={approvalProcessPhase}
          approvalProcessType={type}
          onSuccess={() => {
            onSuccess();
            closeModal();
          }}
        />
      </Modal>
    </>
  );
}

export { AddStepButton };
