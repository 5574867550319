import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P, pipe } from "effect";
import { useCallback } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { UNDEFINED } from "@ender/shared/constants/general";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { AccountingAPIEditGLJournalEntryPayload } from "@ender/shared/generated/ender.api.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { JournalEntryAllocationsFormList } from "../../../../widgets/journal-entries/journal-entry-allocations-form-list";
import type {
  AllocationType,
  JournalEntryFormAllocationOutput,
  OptimizedGetJournalEntryByIdResponse,
  PropertyIdWithAllocationsOutput,
} from "../../../reports/journal-entry-form-right-rail/journal-entry-right-rail.types";
import {
  AllocationTypeEnum,
  PropertyIdWithAllocationsSchema,
} from "../../../reports/journal-entry-form-right-rail/journal-entry-right-rail.types";

/*
 * validation helper function
 */
const isDebitsAndCreditsBalanced = (
  input: readonly PropertyIdWithAllocationsOutput[],
) => {
  const totalDebits = input
    .flatMap(({ allocations }) => allocations)
    .filter(({ glCategoryId }) => O.isSome(glCategoryId))
    .reduce(
      (acc, curr) =>
        Money$.add(acc, curr.debits.pipe(O.getOrElse(() => Money$.zero()))),
      Money$.zero(),
    );

  const totalCredits = input
    .flatMap(({ allocations }) => allocations)
    .filter(({ glCategoryId }) => O.isSome(glCategoryId))
    .reduce(
      (acc, curr) =>
        Money$.add(acc, curr.credits.pipe(O.getOrElse(() => Money$.zero()))),
      Money$.zero(),
    );

  return Money$.isZero(Money$.subtract(totalDebits, totalCredits));
};

const AllocationsFormSchema = Schema.Struct({
  allocationsWithProperties: Schema.Array(PropertyIdWithAllocationsSchema).pipe(
    Schema.filter((input) => isDebitsAndCreditsBalanced(input), {
      message: () => "Debits and credits must be balanced",
    }),
  ),
});

type AllocationsFormOutput = Schema.Schema.Type<typeof AllocationsFormSchema>;

/*
 * component helper functions
 */
const calculateTotal = (
  allocations: readonly PropertyIdWithAllocationsOutput[],
  type: "debits" | "credits",
) =>
  allocations
    .flatMap(({ allocations }) =>
      allocations.reduce(
        (acc, curr) =>
          Money$.add(acc, curr[type].pipe(O.getOrElse(() => Money$.zero()))),
        Money$.zero(),
      ),
    )
    .reduce((acc, curr) => Money$.add(acc, curr), Money$.zero());

const mapAllocations = (
  allocationsWithProperties: AllocationsFormOutput["allocationsWithProperties"],
  type: AllocationType,
) =>
  allocationsWithProperties.flatMap(({ allocations, propertyId }) =>
    allocations
      .filter((allocation) => allocation.type === type)
      .map(({ credits, debits, description, glCategoryId }) => ({
        amount: O.getOrThrow(
          type === AllocationTypeEnum.CREDIT ? credits : debits,
        ).toJSON(),
        categoryId: O.getOrThrow(glCategoryId),
        description,
        propertyId: O.getOrThrow(propertyId),
      })),
  );

const buildMutationPayload = (
  values: AllocationsFormOutput,
  journalEntry: O.Option<OptimizedGetJournalEntryByIdResponse>,
  recurringJournalEntry: O.Option<GetRecurringGLJournalEntryDetailsResponse>,
) => {
  const credits = mapAllocations(
    values.allocationsWithProperties,
    AllocationTypeEnum.CREDIT,
  );
  const debits = mapAllocations(
    values.allocationsWithProperties,
    AllocationTypeEnum.DEBIT,
  );

  return O.isSome(journalEntry)
    ? {
        credits,
        debits,
        journalEntryId: journalEntry.pipe(
          O.map((j) => j.id),
          O.getOrThrow,
        ),
      }
    : {
        credits: credits.map((credit) => ({
          ...credit,
          transactionDirection: AllocationTypeEnum.CREDIT,
        })),
        debits: debits.map((debit) => ({
          ...debit,
          transactionDirection: AllocationTypeEnum.DEBIT,
        })),
        journalEntryId: recurringJournalEntry.pipe(
          O.map((r) => r.id),
          O.getOrThrow,
        ),
      };
};

/*
 * component
 */
type GeneralLedgerTransactionApprovalsEditTransactionAllocationsProps = {
  journalEntry: O.Option<OptimizedGetJournalEntryByIdResponse>;
  recurringJournalEntry: O.Option<GetRecurringGLJournalEntryDetailsResponse>;
  onClose: () => void;
  refreshTransactionData: () => void;
};

function GeneralLedgerTransactionApprovalsEditTransactionAllocations({
  journalEntry,
  recurringJournalEntry,
  onClose,
  refreshTransactionData,
}: GeneralLedgerTransactionApprovalsEditTransactionAllocationsProps) {
  const [isLoading, setIsLoading] = useBoolean(false);

  //maps property ids from each allocation
  const allocationPropertyIds = [
    ...new Set(
      O.isSome(journalEntry)
        ? journalEntry.pipe(
            O.map((j) => j.txs.map(({ propertyId }) => propertyId)),
            O.getOrElse(() => []),
          )
        : recurringJournalEntry.pipe(
            O.map((r) => r.allocations.map(({ propertyId }) => propertyId)),
            O.getOrElse(() => []),
          ),
    ),
  ];

  //formats RJE or SJE allocations for the form list
  const allocationsWithProperties: PropertyIdWithAllocationsOutput[] =
    allocationPropertyIds.map((propertyId) => {
      return {
        allocations: O.isSome(journalEntry)
          ? journalEntry
              .pipe(
                O.map((j) => j.txs),
                O.getOrElse(() => []),
              )
              .reduce<JournalEntryFormAllocationOutput[]>(
                (
                  allocations,
                  {
                    propertyId: allocationPropertyId,
                    amount,
                    description,
                    glCategoryId,
                    id,
                  },
                ) => {
                  if (propertyId === allocationPropertyId) {
                    const creditAllocation = journalEntry
                      .pipe(
                        O.map((j) => j.credits),
                        O.getOrElse(() => []),
                      )
                      .find(({ id: creditId }) => id === creditId);
                    const allocationType = P.isNotNullable(creditAllocation)
                      ? AllocationTypeEnum.CREDIT
                      : AllocationTypeEnum.DEBIT;

                    allocations.push({
                      amount: pipe(
                        Money$.parse(amount),
                        O.map((input) =>
                          Money$.negateWhen(input, () =>
                            P.isNotNullable(creditAllocation),
                          ),
                        ),
                      ),
                      credits: Money$.parse(
                        pipe(Money$.of(amount), Money$.abs),
                      ),
                      debits: Money$.parse(pipe(Money$.of(amount), Money$.abs)),
                      description,
                      glCategoryId: O.fromNullable(glCategoryId),
                      isParentCategory: false,
                      type: allocationType,
                    });
                  }
                  return allocations;
                },
                [],
              )
          : recurringJournalEntry.pipe(
              O.map((r) =>
                r.allocations
                  .filter((allocation) => propertyId === allocation.propertyId)
                  .map((allocation) => ({
                    ...allocation,
                    amount: pipe(
                      Money$.parse(allocation.amount),
                      O.map((input) =>
                        Money$.negateWhen(
                          input,
                          () =>
                            allocation.transactionDirection ===
                            AllocationTypeEnum.CREDIT,
                        ),
                      ),
                    ),
                    credits: Money$.parse(allocation.amount),
                    debits: Money$.parse(allocation.amount),
                    glCategoryId: O.fromNullable(allocation.categoryId),
                    isParentCategory: false,
                    type: allocation.transactionDirection,
                  })),
              ),
              O.getOrElse(() => []),
            ),
        propertyId: O.fromNullable(propertyId),
      };
    });

  const form = useForm({
    defaultValues: {
      allocationsWithProperties,
    },
    resolver: effectTsResolver(AllocationsFormSchema),
  });

  const { allocationsWithProperties: formAllocationsWithProperties } =
    form.watch();

  //reformats values and calls API (RJE & SJE have slightly different payloads)
  const { mutateAsync: updateJournalEntry } = useMutation({
    mutationFn: (values: AccountingAPIEditGLJournalEntryPayload) => {
      if (O.isSome(journalEntry)) {
        return AccountingAPI.editGLJournalEntry({
          credits: values.credits,
          debits: values.debits,
          journalEntryId: journalEntry.pipe(
            O.map((j) => j.id),
            O.getOrThrow,
          ),
        });
      } else {
        const { frequency, id, startDate, title } = recurringJournalEntry.pipe(
          O.getOrThrow,
        );
        return AccountingAPI.updateRecurringGLJournalEntry({
          frequency,
          id,
          startDate,
          title,
          txs: [
            ...values.credits.map((credit) => ({
              ...credit,
              transactionDirection: AllocationTypeEnum.CREDIT,
            })),
            ...values.debits.map((debit) => ({
              ...debit,
              transactionDirection: AllocationTypeEnum.DEBIT,
            })),
          ],
        });
      }
    },
    mutationKey: ["editJournalEntry", journalEntry, recurringJournalEntry],
  });

  const handleSubmit = useCallback(
    async (values: AllocationsFormOutput) => {
      try {
        setIsLoading.setTrue();
        await updateJournalEntry(
          buildMutationPayload(values, journalEntry, recurringJournalEntry),
        );
        refreshTransactionData();
        onClose();
        showSuccessNotification({
          message: "Journal Entry allocations updated",
        });
      } catch (error) {
        fail(error);
      } finally {
        setIsLoading.setFalse();
      }
    },
    [
      updateJournalEntry,
      refreshTransactionData,
      onClose,
      setIsLoading,
      journalEntry,
      recurringJournalEntry,
    ],
  );

  return (
    <Stack>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <JournalEntryAllocationsFormList
            form={form}
            isEditing={false}
            isReadonly={false}
          />
          <Stack spacing={Spacing.none}>
            <H3>Total Across All Entries</H3>
            <Tuple
              label="Debits"
              value={Money$.toFormatted(
                calculateTotal(formAllocationsWithProperties, "debits"),
                Money$.Formats.DEFAULT,
              )}
            />
            <Tuple
              label="Credits"
              value={Money$.toFormatted(
                calculateTotal(formAllocationsWithProperties, "credits"),
                Money$.Formats.DEFAULT,
              )}
            />
            <Tuple
              label="Difference"
              value={
                <Text
                  color={
                    Money$.isZero(
                      Money$.subtract(
                        calculateTotal(formAllocationsWithProperties, "debits"),
                        calculateTotal(
                          formAllocationsWithProperties,
                          "credits",
                        ),
                      ),
                    )
                      ? UNDEFINED
                      : "red-500"
                  }
                  weight={FontWeight.medium}>
                  {Money$.toFormatted(
                    Money$.subtract(
                      calculateTotal(formAllocationsWithProperties, "debits"),
                      calculateTotal(formAllocationsWithProperties, "credits"),
                    ),
                    Money$.Formats.DEFAULT,
                  )}
                </Text>
              }
            />
          </Stack>
          <Divider />
          <Group justify={Justify.end}>
            <Button variant={ButtonVariant.transparent} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={
                !Money$.isZero(
                  Money$.subtract(
                    calculateTotal(formAllocationsWithProperties, "debits"),
                    calculateTotal(formAllocationsWithProperties, "credits"),
                  ),
                )
              }
              disabledTooltip="Debits and credits must be balanced"
              type="submit">
              Save
            </Button>
          </Group>
        </Stack>
      </Form>
    </Stack>
  );
}

export { GeneralLedgerTransactionApprovalsEditTransactionAllocations };
