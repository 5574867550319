import { useMutation } from "@tanstack/react-query";

import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { BankRecRow } from "@ender/shared/generated/ender.service.accounting.banking";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";
import { Color } from "@ender/shared/utils/theming";

type TransactionsTableTransaction = {
  id: string;
  date: string;
  description: string;
  draw?: string;
  deposit?: string;
};

type TransactionsTableProps = {
  title: string;
  transactions: TransactionsTableTransaction[];
};

function TransactionsTable({ title, transactions }: TransactionsTableProps) {
  return (
    <Group spacing={Spacing.sm}>
      <H2>{title}</H2>
      <EnderTable>
        <colgroup>
          <col />
          <col style={{ width: "50%" }} />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Draw</th>
            <th>Deposit</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((row) => {
            return (
              <tr key={row.id}>
                <td>{row.date}</td>
                <td>{row.description}</td>
                <td>{row.draw}</td>
                <td>{row.deposit}</td>
              </tr>
            );
          })}
        </tbody>
      </EnderTable>
    </Group>
  );
}

type UnmatchModalProps = {
  accountId: EnderId;
  transaction: BankRecRow;
  refreshTransactions: () => void;
  closeModal: () => void;
};

function UnmatchModal({
  accountId,
  transaction,
  refreshTransactions,
  closeModal,
}: UnmatchModalProps) {
  const { mutateAsync: unmatchEnderBatch } = useMutation({
    mutationFn: BankingAPI.unmatchEnderBatch,
    mutationKey: ["BankingAPI.unmatchEnderBatch"] as const,
  });
  const handleClick = async () => {
    if (transaction.enderBatch) {
      await unmatchEnderBatch({
        bankAccountId: accountId,
        enderBatchId: transaction.enderBatch.id,
      });
    } else {
      fail("No Ender batch ID found for transaction, unable to unmatch.");
    }
    closeModal();
    refreshTransactions();
  };

  let glTransactionsTableComponent = null;
  if (transaction.enderBatch) {
    const glTransactions: TransactionsTableTransaction[] =
      transaction.enderBatch.moneyTransfers.map((moneyTransfer) => {
        return {
          ...moneyTransfer,
          date: EnderDate.of(
            moneyTransfer.generalLedgerDate,
          ).toLongSlashDateString(),
        };
      });
    glTransactionsTableComponent = (
      <TransactionsTable
        title="General Ledger Transactions"
        transactions={glTransactions}
      />
    );
  }

  let transactionsTableComponent = null;
  if (transaction.bankTransaction) {
    const { date, amount } = transaction.bankTransaction;
    const amountKey = transaction.draw ? "draw" : "deposit";
    const amountValue = Money$.of(+amount.replace(/[^\d-]/g, ""))
      .abs()
      .toFormatted();
    const bankTransactions: TransactionsTableTransaction[] = [
      {
        ...transaction.bankTransaction,
        [amountKey]: amountValue,
        date: EnderDate.of(date).toLongSlashDateString(),
      },
    ];
    transactionsTableComponent = (
      <TransactionsTable
        title="Bank Transactions"
        transactions={bankTransactions}
      />
    );
  }

  return (
    <Stack spacing={Spacing.md}>
      <Text size={FontSize.md}>
        If you think the automatic reconciler made a mistake, you can unmatch
        this transaction.
      </Text>
      {glTransactionsTableComponent}
      {transactionsTableComponent}
      <Group justify={Justify.end} spacing={Spacing.md}>
        <Button variant={ButtonVariant.outlined} onClick={closeModal}>
          Cancel
        </Button>
        <Button color={Color.red} onClick={handleClick}>
          Unmatch
        </Button>
      </Group>
    </Stack>
  );
}

export { UnmatchModal };
