/**
 * HTTP Method: GET
 * Pathname: /accounting/categories
 * @function getCategoriesTree
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetCategoriesTreePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GLCategorySerializerGLCategoryTreeNode[]>}
 */
import { Effect, Function as F } from "effect";

import type { GLCategorySerializerGLCategoryTreeNode } from "@ender/shared/generated/ender.arch.accounting.txsearch";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetCategoriesTreeSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetCategoriesTreePayload =
  GeneralLedgerAPIGetCategoriesTreeSearchParams;

function getCategoriesTreeUnsafeEffect(
  payload: GeneralLedgerAPIGetCategoriesTreePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GLCategorySerializerGLCategoryTreeNode[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          GLCategorySerializerGLCategoryTreeNode[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/accounting/categories",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCategoriesTree(
  payload: GeneralLedgerAPIGetCategoriesTreePayload,
  options?: { signal?: AbortSignal },
): Promise<GLCategorySerializerGLCategoryTreeNode[]> {
  return F.pipe(
    payload,
    getCategoriesTreeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCategoriesTree };
export type {
  GeneralLedgerAPIGetCategoriesTreePayload,
  GeneralLedgerAPIGetCategoriesTreeSearchParams,
};
