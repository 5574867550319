import type { VisibilityState } from "@tanstack/react-table";
import { Predicate as P, String as S } from "effect";

import type {
  SearchApplicationsPageRequestApplicationsPageTab as AllApplicationsTableTab,
  SearchApplicationsPageRequestApplicationsPageSortKey as SortKeyCol,
  SearchApplicationsPageRequestApplicationsPageTab as TableTab,
} from "@ender/shared/generated/com.ender.middle.request";
import {
  SearchApplicationsPageRequestApplicationsPageTabEnum as AllApplicationsTableTabEnum,
  SearchApplicationsPageRequestApplicationsPageSortKeyEnum as SortKeyColEnum,
  SearchApplicationsPageRequestApplicationsPageTabEnum as TableTabEnum,
} from "@ender/shared/generated/com.ender.middle.request";

import type { AllApplicationsRow } from "./all-applications-table.types";

const ApplicationsTableReadableTabs: Record<TableTab, string> = {
  [TableTabEnum.IN_PROGRESS]: "In Progress",
  [TableTabEnum.SUBMITTED]: "Submitted",
  [TableTabEnum.UNDER_REVIEW]: "Under Review",
  [TableTabEnum.READY_FOR_REVIEW]: "Ready for Review",
  [TableTabEnum.ALL_SUBMITTED]: "All Submitted",
  [TableTabEnum.REASSIGN_PROPERTY]: "Reassign Property",
  [TableTabEnum.FINAL_REVIEW]: "Final Review",
  [TableTabEnum.ACCEPTED]: "Accepted",
  [TableTabEnum.DENIED]: "Denied",
  [TableTabEnum.ARCHIVED]: "Archived",
  [TableTabEnum.ALL]: "All",
};

const ApplicationsTabColumnVisibility: Record<TableTab, VisibilityState> = {
  [TableTabEnum.IN_PROGRESS]: {
    condition: false,
    itemsReviewed: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.SUBMITTED]: {
    condition: false,
    itemsSubmitted: false,
    itemsReviewed: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.UNDER_REVIEW]: {
    condition: false,
    itemsSubmitted: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.ALL_SUBMITTED]: {
    condition: false,
    itemsSubmitted: false,
    itemsReviewed: false,
    leaseDrafted: false,
  },
  [TableTabEnum.READY_FOR_REVIEW]: {
    condition: false,
    itemsSubmitted: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.REASSIGN_PROPERTY]: {
    condition: false,
    itemsSubmitted: false,
    itemsReviewed: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.FINAL_REVIEW]: {
    condition: false,
    itemsReviewed: false,
    itemsSubmitted: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.ACCEPTED]: {
    itemsSubmitted: false,
    itemsReviewed: false,
  },
  [TableTabEnum.DENIED]: {
    actionsRequired: false,
    condition: false,
    itemsReviewed: false,
    itemsSubmitted: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.ARCHIVED]: {
    actionsRequired: false,
    condition: false,
    itemsReviewed: false,
    itemsSubmitted: false,
    leaseDraftedAt: false,
    leaseSentAt: false,
  },
  [TableTabEnum.ALL]: {
    itemsReviewed: false,
    itemsSubmitted: false,
  },
} as const;

function getAllApplicationsTableColumnVisibility(
  tab: TableTab,
  data?: AllApplicationsRow[],
): VisibilityState {
  if (P.isNullable(data)) {
    return ApplicationsTabColumnVisibility[tab];
  }

  const showUnitColumn = data.some(
    (row) => P.isNotNullable(row.unitName) && S.isNonEmpty(row.unitName),
  );
  return { unitName: showUnitColumn, ...ApplicationsTabColumnVisibility[tab] };
}

const ColumnIdToSortKey: Record<string, SortKeyCol> = {
  actionsRequired: SortKeyColEnum.ACTION_REQUIRED,
  dateStarted: SortKeyColEnum.DATE_STARTED,
};

function getVisibleTabs({
  twoStepReview,
  allowMultipleInReview,
}: {
  twoStepReview: boolean;
  allowMultipleInReview: boolean;
}): AllApplicationsTableTab[] {
  const tabs: AllApplicationsTableTab[] = [
    AllApplicationsTableTabEnum.IN_PROGRESS,
  ];

  if (twoStepReview && allowMultipleInReview) {
    tabs.push(
      AllApplicationsTableTabEnum.READY_FOR_REVIEW,
      AllApplicationsTableTabEnum.FINAL_REVIEW,
    );
  } else if (twoStepReview && !allowMultipleInReview) {
    tabs.push(
      AllApplicationsTableTabEnum.SUBMITTED,
      AllApplicationsTableTabEnum.READY_FOR_REVIEW,
      AllApplicationsTableTabEnum.FINAL_REVIEW,
    );
  } else if (!twoStepReview && allowMultipleInReview) {
    tabs.push(AllApplicationsTableTabEnum.ALL_SUBMITTED);
  } else {
    tabs.push(
      AllApplicationsTableTabEnum.SUBMITTED,
      AllApplicationsTableTabEnum.ALL_SUBMITTED,
    );
  }

  tabs.push(
    AllApplicationsTableTabEnum.UNDER_REVIEW,
    AllApplicationsTableTabEnum.ACCEPTED,
    AllApplicationsTableTabEnum.REASSIGN_PROPERTY,
    AllApplicationsTableTabEnum.DENIED,
    AllApplicationsTableTabEnum.ARCHIVED,
    AllApplicationsTableTabEnum.ALL,
  );

  return tabs;
}

export {
  ApplicationsTableReadableTabs,
  ColumnIdToSortKey,
  getAllApplicationsTableColumnVisibility,
  getVisibleTabs,
};
