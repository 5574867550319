import { useQuery } from "@tanstack/react-query";
import { Option as O } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Select } from "@ender/shared/ds/select";
import type { SelectOption } from "@ender/shared/ds/select";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { Party } from "@ender/shared/generated/ender.model.payments";

// Custom SelectOption type including `party`
type PartySelectOption = SelectOption<EnderId> & { party: Party };

type InvoicePartySelectProps = {
  disabled?: boolean | Undefined;
  error?: string | Undefined;
  label: string;
  value: EnderId | Undefined | "EXTERNAL";
  onChange: (selectedOption: PartySelectOption | Undefined) => void;
};

function InvoicePartySelect({
  error,
  label,
  value,
  onChange,
}: InvoicePartySelectProps) {
  const { data: activePayees = [] } = useQuery({
    queryFn: ({ signal }) => InvoicesAPI.searchActivePayees({}, { signal }),
    queryKey: ["InvoicesAPI.searchActivePayees"] as const,
  });

  return (
    <Select
      data={activePayees.map((item) => ({
        label: item.name,
        party: item.party,
        value: item.id,
      }))}
      label={label}
      value={O.fromNullable(value)}
      onChange={(val, item) => {
        const selectedId = O.getOrNull(val);
        const selectedOption = O.getOrNull(item) as
          | PartySelectOption
          | Undefined;

        if (selectedId && selectedOption) {
          onChange(selectedOption);
        } else {
          onChange(UNDEFINED);
        }
      }}
      error={error}
      clearable
      placeholder="Search vendor, property manager, equity partner"
    />
  );
}

export { InvoicePartySelect };
