/**
 * HTTP Method: GET
 * Pathname: /widgets/{widgetId}/data.xlsx
 * @function exportWidgetToExcel
 * @memberof ReportsAPI
 * @param {ReportsAPIExportWidgetToExcelPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIExportWidgetToExcelPathParams = {
  widgetId: EnderId;
};

type ReportsAPIExportWidgetToExcelSearchParams = {
  token?: string | undefined;
};

type ReportsAPIExportWidgetToExcelPayload =
  ReportsAPIExportWidgetToExcelPathParams &
    ReportsAPIExportWidgetToExcelSearchParams;

function exportWidgetToExcelUnsafeEffect(
  payload: ReportsAPIExportWidgetToExcelPayload,
) {
  const { widgetId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/widgets/${widgetId}/data.xlsx`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function exportWidgetToExcel(
  payload: ReportsAPIExportWidgetToExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    exportWidgetToExcelUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { exportWidgetToExcel };
export type {
  ReportsAPIExportWidgetToExcelPathParams,
  ReportsAPIExportWidgetToExcelPayload,
  ReportsAPIExportWidgetToExcelSearchParams,
};
