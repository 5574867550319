/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/createListingTemplate
 * @function createListingTemplate
 * @memberof LeasingAPI
 * @param {LeasingAPICreateListingTemplatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ListingTemplate>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { ListingTemplate } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPICreateListingTemplatePathParams = {
  propertyId: EnderId;
};

type LeasingAPICreateListingTemplateSearchParams = {
  token?: string | undefined;
};

type LeasingAPICreateListingTemplateBodyParams = {
  applicationFee: SerializesInto<Money>;
  marketingBody: string;
  marketingTitle: string;
  name: string;
};

type LeasingAPICreateListingTemplatePayload =
  LeasingAPICreateListingTemplatePathParams &
    LeasingAPICreateListingTemplateSearchParams &
    LeasingAPICreateListingTemplateBodyParams;

function createListingTemplateUnsafeEffect(
  payload: LeasingAPICreateListingTemplatePayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ListingTemplate>({
        body,
        decode: unsafeDecodeJsonResponse<ListingTemplate>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/createListingTemplate`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createListingTemplate(
  payload: LeasingAPICreateListingTemplatePayload,
  options?: { signal?: AbortSignal },
): Promise<ListingTemplate> {
  return F.pipe(
    payload,
    createListingTemplateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createListingTemplate };
export type {
  LeasingAPICreateListingTemplateBodyParams,
  LeasingAPICreateListingTemplatePathParams,
  LeasingAPICreateListingTemplatePayload,
  LeasingAPICreateListingTemplateSearchParams,
};
