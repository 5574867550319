import type { SpotlightAction } from "@mantine/spotlight";
import { SpotlightProvider } from "@mantine/spotlight";
import { useQuery } from "@tanstack/react-query";
import type { PropsWithChildren, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { Group } from "@ender/shared/ds/group";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import type { SearchAPIOmnisearchPayload } from "@ender/shared/generated/ender.api.misc";
import { SearchAPI } from "@ender/shared/generated/ender.api.misc";
import type { EnderSearchResult } from "@ender/shared/ui/ender-search";
import { SearchResultItem } from "@ender/shared/ui/ender-search";
import { debounce } from "@ender/shared/utils/debounce";

import styles from "./ender-spotlight-provider.module.css";

type EnderSpotlightActionProps = {
  //TODO remove this union in favor of a single strong type
  action: SpotlightAction | EnderSearchResult;
  onTrigger: () => void;
};

/**
 * @name EnderSpotlightAction
 * @param {object} props
 * @returns {React.ReactNode}
 */
function EnderSpotlightAction({
  action,
  onTrigger,
  ...others
}: EnderSpotlightActionProps) {
  return (
    <button
      className={styles.spotlightItem}
      tabIndex={-1}
      onMouseDown={(event) => event.preventDefault()}
      onClick={onTrigger}
      {...others}>
      <Group noWrap>
        <SearchResultItem result={action as EnderSearchResult} />
      </Group>
    </button>
  );
}

function EnderSpotlightProvider({ children }: PropsWithChildren) {
  const history = useHistory();
  const [nothingFoundMessage, setNothingFoundMessage] = useState<ReactNode>("");
  const [query, setQuery] = useState("");

  function routeChange(path: string) {
    history.push(path);
  }

  const searchPayload = useMemo(
    () => ({ keyword: query, resultsOnEmpty: false }),
    [query],
  );

  const { data: results = [], isFetching } = useQuery({
    queryFn: async ({ queryKey: [, payload], signal }) => {
      if (query.length === 0) {
        return [];
      }

      // ensure the keyword doesn't get double wrapped in quotes
      payload.keyword = payload.keyword.replaceAll('"', "");

      const fetchedResults = await SearchAPI.omnisearch(
        { ...payload, keyword: `"${payload.keyword}"` },
        { signal },
      );

      // See ENDER-9902 / ENDER-9842 for more context
      if (query !== payload.keyword) {
        return;
      }

      const resultsWithTrigger = fetchedResults.map((result) => {
        return {
          ...result,
          id: undefined,
          onTrigger: () => routeChange(result.url),
        };
      });

      return resultsWithTrigger;
    },
    queryKey: ["search", searchPayload] as [string, SearchAPIOmnisearchPayload],
  });

  // lazy useEffect
  useEffect(() => {
    if (isFetching) {
      setNothingFoundMessage(<LoadingSpinner />);
    } else {
      setNothingFoundMessage("Nothing found...");
    }
  }, [isFetching]);

  const debounceQueryUpdate = debounce(setQuery, 750);

  return (
    <SpotlightProvider
      actions={results}
      searchPlaceholder="Search..."
      filter={(_, _actions) => _actions}
      nothingFoundMessage={nothingFoundMessage}
      shortcut="mod + K"
      onQueryChange={debounceQueryUpdate}
      actionComponent={EnderSpotlightAction}>
      {children}
    </SpotlightProvider>
  );
}

export { EnderSpotlightProvider };
