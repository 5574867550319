import { zodResolver } from "@mantine/form";
import { Predicate as P } from "effect";
import { z } from "zod";

import type { Undefined } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align } from "@ender/shared/ds/flex";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Textarea } from "@ender/shared/ds/textarea";
import { useForm } from "@ender/shared/forms/hooks/general";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { Color } from "@ender/shared/utils/theming";

import styles from "./pipeline-rejection-form.module.css";

type OnRejectProps = {
  comment: string;
  step?: EnderId;
};

type RelevantSteps =
  | {
      currentStep: GetApprovalProcessResponseStep;
      previousStep?: GetApprovalProcessResponseStep;
      nextStep?: GetApprovalProcessResponseStep;
    }
  | {
      currentStep: { name: string; approvers: User[] };
      nextStep: Undefined;
      previousStep: Undefined;
    };

type RejectHomePurchaseFormProps = {
  isFirstStep: boolean;
  onReject: (values: OnRejectProps) => void;
  relevantSteps?: RelevantSteps;
};

function RejectHomePurchaseForm({
  isFirstStep,
  onReject,
  relevantSteps,
}: RejectHomePurchaseFormProps) {
  const form = useForm({
    initialValues: {
      comment: "",
    },

    validate: zodResolver(
      z.object({
        comment: z.string().nonempty("Comment is required for rejections"),
      }),
    ),
  });

  function handleReject(values: OnRejectProps) {
    if (form.validate().hasErrors) {
      return;
    }

    onReject(values);
  }

  return (
    <Stack>
      <H2>Reject</H2>
      <form
        onSubmit={form.onSubmit((values) =>
          handleReject({ comment: values.comment }),
        )}>
        <Stack>
          <Textarea
            label="Comment"
            name="comment"
            {...form.getInputProps("comment")}
            data-autofocus
          />
          <Stack align={Align.end}>
            {!isFirstStep && (
              <div className={styles.rejectToPreviousStepButtonWrapper}>
                <Button
                  color={Color.red}
                  onClick={() =>
                    P.isNotNullable(relevantSteps) &&
                    P.isNotNullable(relevantSteps.previousStep) &&
                    handleReject({
                      comment: form.values.comment,
                      step: relevantSteps.previousStep.id,
                    })
                  }>
                  Reject back to {relevantSteps?.previousStep?.name}
                </Button>
              </div>
            )}
            <Button color={Color.red} type="submit">
              Reject Fully
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export { RejectHomePurchaseForm };
