import type { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";

import type { UseFormReturn } from "../types";
import { useForm } from "./use-form";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseEffectFormParams<S extends Schema.Schema<any, any, any>> = {
  defaultValues: Schema.Schema.Encoded<S>;
  schema: S;
};

/**
 * a resolver-based useForm
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useEffectSchemaForm<S extends Schema.Schema<any, any, any>>(
  params: UseEffectFormParams<S>,
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseFormReturn<Schema.Schema.Encoded<S>, any, Schema.Schema.Type<S>> {
  return useForm<
    Schema.Schema.Encoded<S>,
    Schema.Schema.Context<S>,
    Schema.Schema.Type<S>
  >({
    ...params,
    defaultValues: params.defaultValues,
    //@ts-expect-error effectTsResolver expects a narrower type, but `params` does satisfy the type
    resolver: effectTsResolver(params.schema),
  });
}

export { useEffectSchemaForm };
