/**
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}
 * @function updateBank
 * @memberof BankingAPI
 * @param {BankingAPIUpdateBankPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIUpdateBankPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIUpdateBankSearchParams = {
  token?: string | undefined;
};

type BankingAPIUpdateBankBodyParams = {
  accountNumber?: string | undefined;
  cashCategoryId?: EnderId | undefined;
  isOperating?: boolean | undefined;
  name?: string | undefined;
  reconciliationStartDate?: SerializesInto<LocalDate> | undefined;
  reconciliationStartingBalance?: SerializesInto<Money> | undefined;
  routingNumber?: string | undefined;
};

type BankingAPIUpdateBankPayload = BankingAPIUpdateBankPathParams &
  BankingAPIUpdateBankSearchParams &
  BankingAPIUpdateBankBodyParams;

function updateBankUnsafeEffect(payload: BankingAPIUpdateBankPayload) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateBank(
  payload: BankingAPIUpdateBankPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateBankUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateBank };
export type {
  BankingAPIUpdateBankBodyParams,
  BankingAPIUpdateBankPathParams,
  BankingAPIUpdateBankPayload,
  BankingAPIUpdateBankSearchParams,
};
