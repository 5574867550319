import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const VendorVendorOnboardingStatusValues = [
  "PENDING_ONBOARDING_MESSAGE",
  "PENDING_VERIFICATION",
  "PENDING_BANK_DETAILS",
  "FULLY_ONBOARDED",
] as const;

const VendorVendorOnboardingStatusEffectSchema = Schema.Literal(
  ...VendorVendorOnboardingStatusValues,
);

type VendorVendorOnboardingStatus = Schema.Schema.Type<
  typeof VendorVendorOnboardingStatusEffectSchema
>;

const VendorVendorOnboardingStatusEnum = castEnum(
  VendorVendorOnboardingStatusEffectSchema,
);

function randomVendorVendorOnboardingStatus(): VendorVendorOnboardingStatus {
  return rand(VendorVendorOnboardingStatusValues);
}

export {
  randomVendorVendorOnboardingStatus,
  VendorVendorOnboardingStatusEffectSchema,
  VendorVendorOnboardingStatusEnum,
  VendorVendorOnboardingStatusValues,
};
export type { VendorVendorOnboardingStatus };
