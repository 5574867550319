import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useCallback } from "react";
import { z } from "zod";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { ChargeSchedulesAPI } from "@ender/shared/generated/ender.api.leasing";
import { castEnum } from "@ender/shared/utils/zod";

import type { TransformedSchedule } from "./transform-scheduled-charges";
import { transformSchedule } from "./transform-scheduled-charges";

// Adjust the path as necessary
const ScheduledChargesRightRailViewValues = [
  "CLOSED",
  "CREATE",
  "DETAIL",
  "EDIT",
] as const;
const ScheduledChargesRightRailViewSchema = z.enum(
  ScheduledChargesRightRailViewValues,
);
type ScheduledChargesRightRailView = z.infer<
  typeof ScheduledChargesRightRailViewSchema
>;

const ScheduledChargesRightRailViewEnum =
  castEnum<ScheduledChargesRightRailView>(ScheduledChargesRightRailViewSchema);

// Function to fetch scheduled charges
async function fetchScheduledCharges(
  leaseId: EnderId,
  signal?: AbortSignal,
): Promise<TransformedSchedule[] | null> {
  try {
    const scheduledChargesRes =
      await ChargeSchedulesAPI.getLeaseChargeSchedules({ leaseId }, { signal });
    return await Promise.all(scheduledChargesRes.map(transformSchedule));
  } catch (error) {
    // Log error and return null
    console.error("Failed to fetch scheduled charges:", error);
    return NULL;
  }
}

type UseGetScheduledChargesOptions = {
  enabled?: boolean;
};

// React Query hook
function useGetScheduledCharges(
  leaseId: EnderId,
  options: UseGetScheduledChargesOptions = { enabled: true },
) {
  const { enabled } = options;
  const {
    data: scheduledCharges = [],
    error,
    isLoading: isLoading,
    refetch: refetchScheduledCharges,
  } = useQuery<TransformedSchedule[] | null>({
    enabled: P.isNotNullable(leaseId) && enabled, // This ensures the query only runs when leaseId is defined
    queryFn: ({ signal }) => fetchScheduledCharges(leaseId, signal),
    queryKey: [
      "ChargeSchedulesAPI.getTransformedChargeSchedules",
      leaseId,
    ] as const,
  });

  const findScheduledCharge = useCallback(
    function findScheduledCharge(scheduleId: EnderId) {
      return scheduledCharges?.find(({ id }) => id === scheduleId);
    },
    [scheduledCharges],
  );

  return {
    error,
    findScheduledCharge,
    isLoading,
    refetchScheduledCharges,
    scheduledCharges,
  };
}

export {
  fetchScheduledCharges,
  ScheduledChargesRightRailViewEnum,
  ScheduledChargesRightRailViewSchema,
  ScheduledChargesRightRailViewValues,
  useGetScheduledCharges,
};
export type { ScheduledChargesRightRailView };
