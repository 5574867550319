/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/paymentPlans
 * @function createPaymentPlan
 * @memberof LeasingAPI
 * @param {LeasingAPICreatePaymentPlanPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { CreatePaymentPlanRequestPaymentPlanInstallmentRequest } from "@ender/shared/generated/ender.api.leasing.request";
import type { PaymentPlanPaymentPlanType } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPICreatePaymentPlanPathParams = {
  leaseId: EnderId;
};

type LeasingAPICreatePaymentPlanSearchParams = {
  token?: string | undefined;
};

type LeasingAPICreatePaymentPlanBodyParams = {
  installments: CreatePaymentPlanRequestPaymentPlanInstallmentRequest[];
  startDate: SerializesInto<LocalDate>;
  type: PaymentPlanPaymentPlanType;
};

type LeasingAPICreatePaymentPlanPayload =
  LeasingAPICreatePaymentPlanPathParams &
    LeasingAPICreatePaymentPlanSearchParams &
    LeasingAPICreatePaymentPlanBodyParams;

function createPaymentPlanUnsafeEffect(
  payload: LeasingAPICreatePaymentPlanPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/paymentPlans`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createPaymentPlan(
  payload: LeasingAPICreatePaymentPlanPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId> {
  return F.pipe(
    payload,
    createPaymentPlanUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createPaymentPlan };
export type {
  LeasingAPICreatePaymentPlanBodyParams,
  LeasingAPICreatePaymentPlanPathParams,
  LeasingAPICreatePaymentPlanPayload,
  LeasingAPICreatePaymentPlanSearchParams,
};
