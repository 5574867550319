/**
 * HTTP Method: DELETE
 * Pathname: /vehicles/{vehicleId}
 * @function deleteVehicle
 * @memberof LeasingAPI
 * @param {LeasingAPIDeleteVehiclePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIDeleteVehiclePathParams = {
  vehicleId: EnderId;
};

type LeasingAPIDeleteVehicleSearchParams = {
  token?: string | undefined;
};

type LeasingAPIDeleteVehiclePayload = LeasingAPIDeleteVehiclePathParams &
  LeasingAPIDeleteVehicleSearchParams;

function deleteVehicleUnsafeEffect(payload: LeasingAPIDeleteVehiclePayload) {
  const { vehicleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/vehicles/${vehicleId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function deleteVehicle(
  payload: LeasingAPIDeleteVehiclePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteVehicleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteVehicle };
export type {
  LeasingAPIDeleteVehiclePathParams,
  LeasingAPIDeleteVehiclePayload,
  LeasingAPIDeleteVehicleSearchParams,
};
