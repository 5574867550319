import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CategoryFlagValues = [
  "LATE_FEES",
  "ACH_REVERSAL_FEES",
  "DISPUTE_FEE_EXPENSE",
  "DISPUTE_FEE_INCOME",
  "APPLICATION_FEES",
  "HOLDING_FEES",
  "HOLDING_FEE_INCOME",
  "RENT",
  "RENTAL_ASSISTANCE",
  "SECURITY_DEPOSITS",
  "SECURITY_DEPOSIT_CLEARING",
  "DEFAULT_OPERATIONS_EXPENSE",
  "ACCOUNTS_PAYABLE",
  "ACCOUNTS_RECEIVABLE",
  "CURRENT_EARNINGS",
  "MONTH_TO_MONTH_RENT",
  "NON_TENANT_ACCOUNTS_RECEIVABLE",
  "RETAINED_EARNINGS",
  "PREPAID_RENT",
  "BAD_DEBT_INCOME",
  "BAD_DEBT_WRITE_OFF",
  "EXCLUDE_FROM_WATERFALL_ALLOCATIONS",
  "EXCLUDE_FROM_TOTAL_PAYMENT_REQUIREMENT",
  "HOUSING_ASSISTANCE_INCOME",
  "HOUSING_ASSISTANCE_PREPAID_RENT",
  "TENANT_CHARGE_TYPE",
  "TRIPLE_NET_CHARGE_TYPE",
  "LEASING_FEE_EXPENSE",
  "TPT_ELIGIBLE",
  "COLLECTIONS_ELIGIBLE",
  "TPT_CHARGE_LIABILITY",
  "PROCESSING_FEE",
  "EARNEST_MONEY_DEPOSIT",
  "ACQUISITION_FEES",
  "CLOSING_WIRE",
  "TENANT_REFUND_CLEARING",
] as const;

const CategoryFlagEffectSchema = Schema.Literal(...CategoryFlagValues);

type CategoryFlag = Schema.Schema.Type<typeof CategoryFlagEffectSchema>;

const CategoryFlagEnum = castEnum(CategoryFlagEffectSchema);

function randomCategoryFlag(): CategoryFlag {
  return rand(CategoryFlagValues);
}

export {
  CategoryFlagEffectSchema,
  CategoryFlagEnum,
  CategoryFlagValues,
  randomCategoryFlag,
};
export type { CategoryFlag };
