import { IconPercentage } from "@tabler/icons-react";
import { Option as O } from "effect";
import type { ForwardedRef } from "react";
import { forwardRef, useCallback } from "react";

import { Percent$ } from "@ender/shared/core";

import type { InputBaseProps } from "../../../input/src";
import { NumberInput } from "../../../number-input/src";

type PercentInputProps = {
  value: O.Option<Percent$>;
  onChange: (value: O.Option<Percent$>) => void;
  precision?: number;
} & Omit<InputBaseProps, "rightSection" | "value" | "onChange">;

function PercentInput(
  props: PercentInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    borderless,
    label,
    error,
    description,
    placeholder,
    disabled = false,
    leftSection,
    value,
    size,
    precision = 2,
    onChange,
    name,
  } = props;

  /**
   * handles the user typing. What this achieves:
   * 1. sanitize and compute the number value based on the user's input
   * 3. update the Number value via the onChange prop
   */
  const handleNumberInput = useCallback(
    (val: O.Option<number>) => {
      onChange(O.map(val, (val) => Percent$.of(val / 100)));
    },
    [onChange],
  );

  return (
    <NumberInput
      borderless={borderless}
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      leftSection={leftSection}
      onChange={handleNumberInput}
      placeholder={placeholder}
      ref={ref}
      rightSection={<IconPercentage />}
      size={size}
      value={O.map(value, (val) => val.percentValue(precision))}
      name={name}
    />
  );
}
const ForwardedPercentInput = forwardRef<HTMLInputElement, PercentInputProps>(
  PercentInput,
);

export { ForwardedPercentInput as PercentInput };

export type { PercentInputProps };
