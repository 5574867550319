import { Predicate as P } from "effect";
import type { ElementRef } from "react";
import { forwardRef } from "react";

import type { BadgeProps } from "@ender/shared/ds/badge";
import { Badge } from "@ender/shared/ds/badge";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { Color } from "@ender/shared/utils/theming";

type ApplicationReviewStatusBadgeProps = {
  status?: ApprovableApprovalStatus;
  completion?: {
    completedIdentityVerification: boolean;
    completedPayment: boolean;
    completedPaystubAndId: boolean;
    completedPersonal: boolean;
    completedPets?: boolean;
  };
};

const badgeLabelFromStatus = (
  status: ApprovableApprovalStatus,
  completion?: ApplicationReviewStatusBadgeProps["completion"],
): string => {
  // If any completion check is false, show "In Progress"
  if (
    completion &&
    (!completion.completedIdentityVerification ||
      !completion.completedPayment ||
      !completion.completedPaystubAndId ||
      !completion.completedPersonal ||
      completion.completedPets === false)
  ) {
    return "In Progress";
  }

  switch (status) {
    case ApprovableApprovalStatusEnum.APPROVED:
      return "Approved";
    case ApprovableApprovalStatusEnum.REJECTED:
      return "Rejected";
    case ApprovableApprovalStatusEnum.NEW:
    default:
      return "Submitted";
  }
};

const badgeColorFromStatus = (
  status: ApprovableApprovalStatus,
  completion?: ApplicationReviewStatusBadgeProps["completion"],
): BadgeProps["color"] => {
  // If any completion check is false, use yellow color for "In Progress"
  if (
    completion &&
    (!completion.completedIdentityVerification ||
      !completion.completedPayment ||
      !completion.completedPaystubAndId ||
      !completion.completedPersonal ||
      completion.completedPets === false)
  ) {
    return Color.slate;
  }

  switch (status) {
    case ApprovableApprovalStatusEnum.APPROVED:
      return Color.green;
    case ApprovableApprovalStatusEnum.REJECTED:
      return Color.red;
    case ApprovableApprovalStatusEnum.NEW:
    default:
      return Color.yellow;
  }
};

const ApplicationReviewStatusBadge = forwardRef<
  ElementRef<typeof Badge>,
  ApplicationReviewStatusBadgeProps
>(function ApplicationReviewStatusBadge(props, ref) {
  const { status = ApprovableApprovalStatusEnum.NEW, completion } = props;

  return (
    <Badge color={badgeColorFromStatus(status, completion)} size="sm" ref={ref}>
      {P.isNotNullable(status) && badgeLabelFromStatus(status, completion)}
    </Badge>
  );
});

export { ApplicationReviewStatusBadge };

export type { ApplicationReviewStatusBadgeProps };
