import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ReconTypeFilterUnmatchedEnderTransactionTypeValues = [
  "ENDER_TRANSACTION",
  "PENDING_DEPOSIT",
] as const;

const ReconTypeFilterUnmatchedEnderTransactionTypeEffectSchema = Schema.Literal(
  ...ReconTypeFilterUnmatchedEnderTransactionTypeValues,
);

type ReconTypeFilterUnmatchedEnderTransactionType = Schema.Schema.Type<
  typeof ReconTypeFilterUnmatchedEnderTransactionTypeEffectSchema
>;

const ReconTypeFilterUnmatchedEnderTransactionTypeEnum = castEnum(
  ReconTypeFilterUnmatchedEnderTransactionTypeEffectSchema,
);

function randomReconTypeFilterUnmatchedEnderTransactionType(): ReconTypeFilterUnmatchedEnderTransactionType {
  return rand(ReconTypeFilterUnmatchedEnderTransactionTypeValues);
}

export {
  randomReconTypeFilterUnmatchedEnderTransactionType,
  ReconTypeFilterUnmatchedEnderTransactionTypeEffectSchema,
  ReconTypeFilterUnmatchedEnderTransactionTypeEnum,
  ReconTypeFilterUnmatchedEnderTransactionTypeValues,
};
export type { ReconTypeFilterUnmatchedEnderTransactionType };
