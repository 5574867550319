import { createContext } from "react";

type FormSectionContextType = {};

/**
 * Available but unused. Eventually we may want this as a way to do error boundary enforcement
 * at the form section level.
 */
const FormSectionContext = createContext<FormSectionContextType>({});

export { FormSectionContext };
export type { FormSectionContextType };
