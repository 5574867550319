import { Array as A, Predicate as P } from "effect";
import type { ReactNode } from "react";

import { NULL } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId, Money } from "@ender/shared/core";
import type { BadgeColors } from "@ender/shared/ds/badge";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { PaymentsAPIAddMoneyTransfersToBankingPayload } from "@ender/shared/generated/ender.api.accounting";
import {
  BankingAPI,
  PaymentsAPI,
} from "@ender/shared/generated/ender.api.accounting";
import type { GetBankAccountResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { BankTransaction } from "@ender/shared/generated/ender.model.payments";
import type {
  BankRecRow,
  BankRecRowEnderBatchResponse,
  BankRecRowType,
} from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";

import type { ReconciliationStatus } from "../../../banking.types";
import { ReconcileModal } from "../../../modals/reconcile-modal/reconcile-modal";
import { UnmatchModal } from "../../../modals/unmatch-modal";
import { isEnderBatchItem } from "../../banking-detail.utils";
import { BankingDetailListItemStatusBadge } from "./banking-detail-list-item-status";

type BankingDetailStatusBadgeWithModalsProps = {
  badgeColor: BadgeColors;
  bankTransaction?: BankTransaction;
  canRemoveRow: boolean;
  deposit: Money;
  details?: GetBankAccountResponse;
  draw: Money;
  getBankTransactions: () => void; // TODO this would rather be handled with useQuery
  isIgnored: boolean;
  reconciliationStatus: ReconciliationStatus;
  rowType: BankRecRowType;
  startBatchMatching: (value: EnderId) => void;
  tooltipContent?: ReactNode;
  transaction: BankRecRow;
  transactionDetails: BankTransaction | BankRecRowEnderBatchResponse;
};

function BankingDetailStatusBadgeWithModals({
  badgeColor,
  bankTransaction,
  canRemoveRow,
  deposit,
  details,
  draw,
  getBankTransactions,
  isIgnored,
  reconciliationStatus,
  rowType,
  startBatchMatching,
  tooltipContent,
  transaction,
  transactionDetails,
}: BankingDetailStatusBadgeWithModalsProps) {
  const confirmation = useConfirmationContext();
  const [
    isReconcileModalOpen,
    { setTrue: openReconcileModal, setFalse: closeReconcileModal },
  ] = useBoolean();
  const [
    isUnmatchModalOpen,
    { setTrue: openUnmatchModal, setFalse: closeUnmatchModal },
  ] = useBoolean();

  async function excludeEnderTxn() {
    try {
      await confirmation({
        title:
          "Are you sure you would like to exclude this Ender Txn? You can always revert your change.",
      });
      // Not all transactionDetails have moneyTransfers.
      if (!isEnderBatchItem(transactionDetails)) {
        fail(
          "Should not happen: transaction details do not have money transfers",
        );
        return;
      }
      const moneyTransferIds = transactionDetails?.moneyTransfers.map(
        (moneyTransfer) => moneyTransfer.id,
      );
      const bankAccountId = transactionDetails.bankAccountId;
      await PaymentsAPI.removeMoneyTransfersFromBanking({
        bankAccountId,
        moneyTransferIds,
      });
      getBankTransactions();
    } catch {
      // don't show an error message when user cancels confirmation
    }
  }

  async function onReIncludeEnderExcludedTransaction({
    bankAccountId,
    moneyTransferIds,
  }: PaymentsAPIAddMoneyTransfersToBankingPayload) {
    try {
      await confirmation({
        confirmButtonLabel: "Re-Include",
        title: "Are you sure you would like to re-include this Ender Txn?",
      });
      try {
        await PaymentsAPI.addMoneyTransfersToBanking({
          bankAccountId,
          moneyTransferIds,
        });
        getBankTransactions();
      } catch (err) {
        fail(err);
      }
    } catch {
      // don't show an error message when user cancels confirmation
    }
  }

  async function reIncludeTransaction() {
    if (P.isNullable(bankTransaction)) {
      return;
    }

    try {
      await confirmation({
        content:
          "It will appear as Not Matched on your bank reconciliation report.",
        title: "Are you sure you would like to re-include this transaction?",
      });
      try {
        await BankingAPI.unmatchBankTransaction({
          bankTransactionId: bankTransaction?.id,
        });
        getBankTransactions();
      } catch (err) {
        fail(err);
      }
    } catch {
      // don't show an error message when user cancels confirmation
    }
  }

  function handleRowStatusClick() {
    if (rowType === BankRecRowTypeEnum.UNMATCHED_BANK) {
      openReconcileModal();
      return;
    }

    if (rowType === BankRecRowTypeEnum.UNMATCHED_ENDER && canRemoveRow) {
      excludeEnderTxn();
      return;
    }

    if (rowType === BankRecRowTypeEnum.ENDER_EXCLUDED) {
      if (!isEnderBatchItem(transactionDetails)) {
        return;
      }
      const { bankAccountId, moneyTransfers } = transactionDetails;
      const moneyTransferIds = moneyTransfers?.map(
        (moneyTransfer) => moneyTransfer.id,
      );

      if (
        P.isNotNullable(bankAccountId) &&
        A.isNonEmptyArray(moneyTransferIds)
      ) {
        onReIncludeEnderExcludedTransaction({
          bankAccountId,
          moneyTransferIds,
        });
      }
      return;
    }

    if (isIgnored) {
      reIncludeTransaction();
      return;
    }

    if (
      rowType === BankRecRowTypeEnum.MATCHED ||
      rowType === BankRecRowTypeEnum.MATCHED_LATER_ENDER ||
      rowType === BankRecRowTypeEnum.MATCHED_LATER_BANK
    ) {
      openUnmatchModal();
    }
  }

  if (P.isNullable(details)) {
    return NULL;
  }

  return (
    <>
      <Tooltip label={tooltipContent} disabled={!tooltipContent}>
        <BankingDetailListItemStatusBadge
          badgeColor={badgeColor}
          rowType={rowType}
          reconciliationStatus={reconciliationStatus}
          onClick={handleRowStatusClick}
          canRemoveRow={canRemoveRow}
        />
      </Tooltip>
      <Modal
        title="Unmatch transaction"
        opened={isUnmatchModalOpen}
        onClose={closeUnmatchModal}
        size={ModalSize.lg}>
        <UnmatchModal
          accountId={details?.id}
          transaction={transaction}
          refreshTransactions={getBankTransactions}
          closeModal={closeUnmatchModal}
        />
      </Modal>
      <Modal
        opened={isReconcileModalOpen}
        onClose={closeReconcileModal}
        borderless
        title="Reconcile Transaction">
        {P.isNotNullable(bankTransaction) && (
          <ReconcileModal
            closeModal={closeReconcileModal}
            {...transactionDetails}
            accountDetails={details}
            draw={draw}
            deposit={deposit}
            bankTransaction={bankTransaction}
            associatedProperties={details.properties}
            refreshTransactions={getBankTransactions}
            startBatchMatching={startBatchMatching}
          />
        )}
      </Modal>
    </>
  );
}

export { BankingDetailStatusBadgeWithModals };
