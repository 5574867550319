import { Function as F, Option as O } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";

import type { RefundRecipientInput } from "./types";
import { AllFinanciallyResponsibleTenantsSwitch } from "./v2/all-financially-responsible-tenants-switch";

type TenantAllocationsProps = {
  allTenantsAmount: Money$.Money;
  balanceAmount: Money$.Money;
  financiallyResponsibleTenants: { id: EnderId; name: string }[];
  handleChangeIsForAllTenants: (value: boolean) => void;
  handleCurrencyInputChange: (
    value: O.Option<Money$.Money>,
    oldValue: RefundRecipientInput,
    refundRecipientsPosition: number,
  ) => void;
  isForAllTenants: boolean;
  refundRecipients: readonly RefundRecipientInput[];
  refundTotal: Money$.Money;
};

function TenantAllocations({
  allTenantsAmount,
  balanceAmount,
  financiallyResponsibleTenants,
  handleChangeIsForAllTenants,
  handleCurrencyInputChange,
  isForAllTenants,
  refundRecipients,
  refundTotal,
}: TenantAllocationsProps) {
  return (
    <Stack>
      <AllFinanciallyResponsibleTenantsSwitch
        onChange={handleChangeIsForAllTenants}
        value={isForAllTenants}
      />
      {isForAllTenants ? (
        <MoneyInput
          disabled
          value={O.fromNullable(allTenantsAmount)}
          onChange={F.constVoid}
          label="Refund Amount"
          name="allTenantsRefundAmount"
        />
      ) : (
        <>
          <Text size={FontSize.sm}>
            Please set an amount to refund one or more tenants. A unique invoice
            will be created for each tenant.
          </Text>
          {refundRecipients.map((recipient, index) => {
            return (
              <Group key={index} justify={Justify.between} align={Align.center}>
                <Select
                  disabled
                  data={financiallyResponsibleTenants.map((tenant) => ({
                    label: tenant.name,
                    value: tenant.id,
                  }))}
                  value={recipient.id}
                  onChange={F.constVoid}
                />
                <MoneyInput
                  value={recipient.amount}
                  onChange={(newCur) =>
                    handleCurrencyInputChange(newCur, recipient, index)
                  }
                  label="Refund Amount"
                  name={`refundRecipients.${index}`}
                  error={
                    Money$.Equivalence(refundTotal, Money$.abs(balanceAmount))
                      ? ""
                      : "Total refund amount must equal remaining balance."
                  }
                />
              </Group>
            );
          })}
        </>
      )}
      <Tuple
        label={
          <Text size={FontSize.lg} weight={FontWeight.semibold}>
            Total Refund Amount
          </Text>
        }
        value={
          <Text size={FontSize.lg} weight={FontWeight.semibold}>
            <MoneyDisplay
              showSymbol
              value={Money$.parse(refundTotal.toFormatted())}
            />
          </Text>
        }
      />
    </Stack>
  );
}

export { TenantAllocations };
