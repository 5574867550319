import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TextTemplateTemplateTypeValues = [
  "LEASE",
  "LEASE_STATE_ADDENDUM",
  "LEASE_OPTIONAL_ADDENDUM",
  "LETTER",
  "LEASE_RENEWAL",
  "COLLECTIONS",
  "PROSPECT_CHAT",
  "APPLICATION",
  "APPLICATION_CHAT",
  "TENANT_CHAT",
  "PROPERTY_ANNOUNCEMENT",
  "APPLICATION_ANNOUNCEMENT",
  "PROSPECT_ANNOUNCEMENT",
  "PROSPECT_NOTES",
  "APPLICATION_NOTES",
  "TENANT_NOTES",
] as const;

const TextTemplateTemplateTypeEffectSchema = Schema.Literal(
  ...TextTemplateTemplateTypeValues,
);

type TextTemplateTemplateType = Schema.Schema.Type<
  typeof TextTemplateTemplateTypeEffectSchema
>;

const TextTemplateTemplateTypeEnum = castEnum(
  TextTemplateTemplateTypeEffectSchema,
);

function randomTextTemplateTemplateType(): TextTemplateTemplateType {
  return rand(TextTemplateTemplateTypeValues);
}

export {
  randomTextTemplateTemplateType,
  TextTemplateTemplateTypeEffectSchema,
  TextTemplateTemplateTypeEnum,
  TextTemplateTemplateTypeValues,
};
export type { TextTemplateTemplateType };
