import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import { InvoiceInvoiceTypeValues } from "@ender/shared/generated/ender.model.payments.invoice";
import { ApprovableApprovalStatusValues } from "@ender/shared/generated/ender.service.approvals";
import {
  IsoDateSchema,
  LedgerEventTypeSchema,
  MoneyTransferSchema,
} from "@ender/shared/types/ender-general";
import type { Options } from "@ender/shared/utils/rest";
import { makeUseMutation, rest } from "@ender/shared/utils/rest";
import { CurrencySchema } from "@ender/shared/utils/zod";

const ReverseTenantCreditPayloadSchema = z.object({
  ledgerEventId: EnderIdSchema,
  ledgerEventType: LedgerEventTypeSchema,
  reversalDate: IsoDateSchema.or(z.string()),
});
type ReverseTenantCreditPayload = z.infer<
  typeof ReverseTenantCreditPayloadSchema
>;

const ReverseTenantCreditResponseSchema = z.object({
  amount: CurrencySchema,
  amountApprovalStatus: z.enum(ApprovableApprovalStatusValues).optional(),
  canApprove: z.boolean().optional(),
  canRefund: z.boolean().optional(),
  description: z.string(),
  externalInvoiceId: z.string().nullish(),
  id: EnderIdSchema,
  payee: z.string().optional(),
  paymentInfo: MoneyTransferSchema.optional(),
  status: z.enum(ApprovableApprovalStatusValues),
  task: z
    .object({
      description: z.string(),
      id: EnderIdSchema,
      internalAssigneeName: z.string().optional(),
      shortName: z.string(),
      url: z.string(),
    })
    .optional(),
  type: z.enum(InvoiceInvoiceTypeValues),
});
type ReverseTenantCreditResponse = z.infer<
  typeof ReverseTenantCreditResponseSchema
>;

/**
 * In time, this API will abstract from being for credits only to handling all reversals.
 * At the moment, the BE is only set up for handling credit reversals in this manner.
 */
async function reverseTenantCredit(
  params: ReverseTenantCreditPayload,
  options: Options = {},
): Promise<ReverseTenantCreditResponse> {
  const { ledgerEventType, ledgerEventId, ...body } =
    ReverseTenantCreditPayloadSchema.parse(params);
  const result = await rest.post(
    `/ledgerEvents/${ledgerEventType}/${ledgerEventId}/reverse`,
    body,
    options,
  );
  return ReverseTenantCreditResponseSchema.parse(result);
}

const useReverseTenantCreditMutation = makeUseMutation<
  ReverseTenantCreditPayload,
  ReverseTenantCreditResponse
>("reverseTenantCredit", reverseTenantCredit);

export {
  reverseTenantCredit,
  ReverseTenantCreditPayloadSchema,
  ReverseTenantCreditResponseSchema,
  useReverseTenantCreditMutation,
};
export type { ReverseTenantCreditPayload, ReverseTenantCreditResponse };
