/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/underwriting
 * @function saveUnderwritingModel
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPISaveUnderwritingModelPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnderwritingModel>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import type { UnderwritingModel } from "@ender/shared/generated/com.ender.buy.model.misc";
import type { PropertyHOAFeeFrequency } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnderwritingAPISaveUnderwritingModelPathParams = {
  dealId: EnderId;
};

type UnderwritingAPISaveUnderwritingModelSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPISaveUnderwritingModelBodyParams = {
  arv: SerializesInto<Money>;
  arvReason: string;
  brokerMaxOffer: SerializesInto<Money>;
  closingCost?: SerializesInto<Money> | undefined;
  creditLossPercentOverride?: SerializesInto<Percent> | undefined;
  hoaFeeFrequency?: PropertyHOAFeeFrequency | undefined;
  hoaFees: SerializesInto<Money>;
  insuranceRateOverride?: SerializesInto<Percent> | undefined;
  leasingOverride?: SerializesInto<Percent> | undefined;
  offerPrice: SerializesInto<Money>;
  oneTimeRepairs: SerializesInto<Money>;
  petRentOverride?: SerializesInto<Money> | undefined;
  pmFeePercentOverride?: SerializesInto<Percent> | undefined;
  rent: SerializesInto<Money>;
  /**
   * A list of ids for the comps that were selected for the rent price.
   */
  rentComps: string[];
  rentReason: string;
  rentalComparablesId?: EnderId | undefined;
  repairsAndMaintenanceOverride?: SerializesInto<Money> | undefined;
  sellerCredits?: SerializesInto<Money> | undefined;
  sellerTaxes: SerializesInto<Money>;
  taxes: SerializesInto<Money>;
  turnoverOverride?: SerializesInto<Money> | undefined;
  vacancyPercentOverride?: SerializesInto<Percent> | undefined;
  /**
   * A list of ids for the comps that were selected for home value.
   */
  valueComps: string[];
};

type UnderwritingAPISaveUnderwritingModelPayload =
  UnderwritingAPISaveUnderwritingModelPathParams &
    UnderwritingAPISaveUnderwritingModelSearchParams &
    UnderwritingAPISaveUnderwritingModelBodyParams;

function saveUnderwritingModelUnsafeEffect(
  payload: UnderwritingAPISaveUnderwritingModelPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnderwritingModel>({
        body,
        decode: unsafeDecodeJsonResponse<UnderwritingModel>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/underwriting`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function saveUnderwritingModel(
  payload: UnderwritingAPISaveUnderwritingModelPayload,
  options?: { signal?: AbortSignal },
): Promise<UnderwritingModel> {
  return F.pipe(
    payload,
    saveUnderwritingModelUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { saveUnderwritingModel };
export type {
  UnderwritingAPISaveUnderwritingModelBodyParams,
  UnderwritingAPISaveUnderwritingModelPathParams,
  UnderwritingAPISaveUnderwritingModelPayload,
  UnderwritingAPISaveUnderwritingModelSearchParams,
};
