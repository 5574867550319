/**
 * Attempts to ARCHIVE passed MoneyTransfers from a bank account, but DOES NOT remove them from their specific
 ledgers, nor their associated transactions from the general ledger.

 Can fail if the MoneyTransfer is matched in the given bank account.

 If the MoneyTransfer is part of a declared banking batch, will remove the banking_batch.

 Note,  <code> bankAccountId </code>  specifies the bank account from which the MoneyTransfer is being removed. This is
 always required and is useful for internal transfers where a MoneyTransfer might be between two actively reconciled
 bank accounts A -> B, where it is desired to remove it from one but not the other.
 * HTTP Method: POST
 * Pathname: /moneyTransfers/removeFromBanking
 * @function removeMoneyTransfersFromBanking
 * @memberof PaymentsAPI
 * @param {PaymentsAPIRemoveMoneyTransfersFromBankingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIRemoveMoneyTransfersFromBankingSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIRemoveMoneyTransfersFromBankingBodyParams = {
  bankAccountId: EnderId;
  moneyTransferIds: EnderId[];
};

type PaymentsAPIRemoveMoneyTransfersFromBankingPayload =
  PaymentsAPIRemoveMoneyTransfersFromBankingSearchParams &
    PaymentsAPIRemoveMoneyTransfersFromBankingBodyParams;

function removeMoneyTransfersFromBankingUnsafeEffect(
  payload: PaymentsAPIRemoveMoneyTransfersFromBankingPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/moneyTransfers/removeFromBanking",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeMoneyTransfersFromBanking(
  payload: PaymentsAPIRemoveMoneyTransfersFromBankingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeMoneyTransfersFromBankingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeMoneyTransfersFromBanking };
export type {
  PaymentsAPIRemoveMoneyTransfersFromBankingBodyParams,
  PaymentsAPIRemoveMoneyTransfersFromBankingPayload,
  PaymentsAPIRemoveMoneyTransfersFromBankingSearchParams,
};
