import { useUrlSyncedTabs } from "@ender/shared/hooks/use-url-synced-tabs";

import type { InvoiceTableTabs } from "../invoice-table-tabs.types";
import {
  InvoiceTableTabsEnum,
  InvoiceTableTabsSchema,
} from "../invoice-table-tabs.types";

type UseInvoicesTableTabsProps = {
  defaultValue: InvoiceTableTabs;
};

function useInvoicesTableTabs(
  { defaultValue }: UseInvoicesTableTabsProps = {
    defaultValue: InvoiceTableTabsEnum.ASSIGNED_TO_ME,
  },
) {
  return useUrlSyncedTabs({
    defaultValue,
    schema: InvoiceTableTabsSchema,
  });
}

export { useInvoicesTableTabs };
export type { UseInvoicesTableTabsProps };
