import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyHomeTypeValues = [
  "APARTMENT",
  "DUPLEX",
  "MANUFACTURED_HOME",
  "MOBILE_HOME",
  "QUADRUPLEX",
  "TRIPLEX",
  "TOWNHOUSE",
  "UNDEVELOPED_LAND",
  "PAPER_LOT",
  "SINGLE_FAMILY_DETACHED",
  "CONDOMINIUM",
  "MULTI_FAMILY",
  "COMMUNITY_HOME",
] as const;

const PropertyHomeTypeEffectSchema = Schema.Literal(...PropertyHomeTypeValues);

type PropertyHomeType = Schema.Schema.Type<typeof PropertyHomeTypeEffectSchema>;

const PropertyHomeTypeEnum = castEnum(PropertyHomeTypeEffectSchema);

function randomPropertyHomeType(): PropertyHomeType {
  return rand(PropertyHomeTypeValues);
}

export {
  PropertyHomeTypeEffectSchema,
  PropertyHomeTypeEnum,
  PropertyHomeTypeValues,
  randomPropertyHomeType,
};
export type { PropertyHomeType };
