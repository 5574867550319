/**
 * HTTP Method: GET
 * Pathname: /buy/investors/{firmId}/settings
 * @function getInvestorSettings
 * @memberof BuyAPI
 * @param {BuyAPIGetInvestorSettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InvestorSettingResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvestorSettingResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetInvestorSettingsPathParams = {
  firmId: EnderId;
};

type BuyAPIGetInvestorSettingsSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetInvestorSettingsPayload = BuyAPIGetInvestorSettingsPathParams &
  BuyAPIGetInvestorSettingsSearchParams;

function getInvestorSettingsUnsafeEffect(
  payload: BuyAPIGetInvestorSettingsPayload,
) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InvestorSettingResponse>({
        body,
        decode: unsafeDecodeJsonResponse<InvestorSettingResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/investors/${firmId}/settings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvestorSettings(
  payload: BuyAPIGetInvestorSettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<InvestorSettingResponse> {
  return F.pipe(
    payload,
    getInvestorSettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvestorSettings };
export type {
  BuyAPIGetInvestorSettingsPathParams,
  BuyAPIGetInvestorSettingsPayload,
  BuyAPIGetInvestorSettingsSearchParams,
};
