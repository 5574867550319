import { Predicate as P } from "effect";

import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";

type GetCanEditAllocationsParams = {
  hasEditInvoiceAllocationsPermission: boolean;
  invoice: InvoiceSerializerInvoiceResponse;
  needsMyApproval: boolean;
};

function getEditAllocationsMessage({
  hasEditInvoiceAllocationsPermission,
  invoice,
  needsMyApproval,
}: GetCanEditAllocationsParams): string {
  if (!hasEditInvoiceAllocationsPermission) {
    return "You don't have permission to edit allocations of an approved invoice.";
  }

  if (P.isUndefined(invoice) || invoice?.currentState?.isApproved) {
    return "";
  }

  if (invoice?.currentState?.isFullyRejected) {
    return "You cannot update allocations for rejected invoices.";
  }

  if (!needsMyApproval) {
    return "You cannot reallocate this invoice until it is on your step in the approval process.";
  }

  return "";
}

export { getEditAllocationsMessage };
