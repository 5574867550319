/**
 * HTTP Method: POST
 * Pathname: /systemReports/Delinquency Report/run
 * @function runDelinquencyReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunDelinquencyReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<DelinquencyReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { DelinquencyReportResponse } from "@ender/shared/generated/ender.api.model.finance";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIRunDelinquencyReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunDelinquencyReportBodyParams = {
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  propertyIds: EnderId[];
  showMovedOutTenants?: boolean | undefined;
  showNonDelinquentTenants?: boolean | undefined;
};

type ReportsAPIRunDelinquencyReportPayload =
  ReportsAPIRunDelinquencyReportSearchParams &
    ReportsAPIRunDelinquencyReportBodyParams;

function runDelinquencyReportUnsafeEffect(
  payload: ReportsAPIRunDelinquencyReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, DelinquencyReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<DelinquencyReportResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/Delinquency Report/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runDelinquencyReport(
  payload: ReportsAPIRunDelinquencyReportPayload,
  options?: { signal?: AbortSignal },
): Promise<DelinquencyReportResponse> {
  return F.pipe(
    payload,
    runDelinquencyReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runDelinquencyReport };
export type {
  ReportsAPIRunDelinquencyReportBodyParams,
  ReportsAPIRunDelinquencyReportPayload,
  ReportsAPIRunDelinquencyReportSearchParams,
};
