import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SQLReportParamTypeValues = [
  "PM",
  "PROPERTY",
  "FIRM",
  "VENDOR",
  "DATE",
] as const;

const SQLReportParamTypeEffectSchema = Schema.Literal(
  ...SQLReportParamTypeValues,
);

type SQLReportParamType = Schema.Schema.Type<
  typeof SQLReportParamTypeEffectSchema
>;

const SQLReportParamTypeEnum = castEnum(SQLReportParamTypeEffectSchema);

function randomSQLReportParamType(): SQLReportParamType {
  return rand(SQLReportParamTypeValues);
}

export {
  randomSQLReportParamType,
  SQLReportParamTypeEffectSchema,
  SQLReportParamTypeEnum,
  SQLReportParamTypeValues,
};
export type { SQLReportParamType };
