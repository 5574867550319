import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Function as F,
  Option as O,
  Order,
  Predicate as P,
  String as S,
  pipe,
} from "effect";
import { useEffect, useState } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Money$, Percent$, PercentFormSchema } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormMultiSelect } from "@ender/shared/ds/multi-select";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import { FormPercentInput } from "@ender/shared/ds/percent-input";
import { FormRadioGroup } from "@ender/shared/ds/radio-group";
import type { SelectOption } from "@ender/shared/ds/select";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { BuyBoxAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { Market } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  BuyBoxBuyBoxTypeEffectSchema,
  BuyBoxBuyBoxTypeEnum,
  BuyBoxBuyBoxTypeValues,
} from "@ender/shared/generated/com.ender.buy.model.misc";
import { ReportsAPI } from "@ender/shared/generated/ender.api.reports";
import type { PropertyHomeType } from "@ender/shared/generated/ender.model.core.property";
import { PropertyHomeTypeEffectSchema } from "@ender/shared/generated/ender.model.core.property";
import { ReportFilterOperatorEnum } from "@ender/shared/generated/ender.model.reports";
import { cast } from "@ender/shared/types/cast";
import type { LabelValue } from "@ender/shared/types/label-value";
import {
  convertSnakeCaseToTitleCase,
  convertToNumber,
} from "@ender/shared/utils/string";

import type { BuyBoxDetails } from "./buy-box";
import { Factor } from "./underwriting-queue/underwriting-queue-table/filter-fields/filter-types";
import { getWidget } from "./underwriting-queue/underwriting-queue-table/widget-helpers";
import { widgetToUpdateRequest } from "./widget-helpers";

const schoolScores = [
  "A+",
  "A",
  "A-",
  "B+",
  "B",
  "B-",
  "C+",
  "C",
  "C-",
  "D+",
  "D",
  "D-",
  "F",
] as const;
type SchoolScore = (typeof schoolScores)[number];
const schoolScoreOptions = schoolScores.map<LabelValue<SchoolScore>>(
  (value) => ({ label: value, value }),
);

const CreateUpdateBuyBoxFormSchema = Schema.Struct({
  excludeCities: Schema.String.pipe(Schema.Array),
  excludeZipcodes: Schema.String.pipe(Schema.Array),
  maxListPrice: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.exists<Money$.Money>(Money$.isPositive), {
      message: () => "Max List Price is required",
    }),
  ),
  minBaths: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min Baths is required" }),
  ),
  minBeds: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min Beds is required" }),
  ),
  minCapRate: PercentFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min Cap Rate is required" }),
  ),
  minListPrice: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min List Price is required" }),
  ),
  minMedianIncome: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min Median Income is required" }),
  ),
  minRent: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min Rent is required" }),
  ),
  minSchoolRating: Schema.Literal(...schoolScores).pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min School Score is required" }),
  ),
  minSqft: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Min Sqft is required" }),
  ),
  minYearBuilt: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Min Year Built is required" }),
    Schema.pattern(/\d{4}/, {
      message: () => "Min Year Built must be a 4 digit year",
    }),
  ),
  name: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Name is required" }),
  ),
  propertyTypes: PropertyHomeTypeEffectSchema.pipe(
    Schema.Array,
    Schema.minItems(1, { message: () => "Property Types are required" }),
  ),
  type: BuyBoxBuyBoxTypeEffectSchema.pipe(Schema.OptionFromSelf),
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];
    if (
      O.isSome(values.minListPrice) &&
      O.isSome(values.maxListPrice) &&
      Order.lessThan(O.getOrder(Money$.Order))(
        values.maxListPrice,
        values.minListPrice,
      )
    ) {
      issues.push({
        message: "Max List Price must be greater than Min List Price",
        path: ["maxListPrice"],
      });
    }
    return issues;
  }),
);

type CreateUpdateBuyBoxFormOutput = Schema.Schema.Type<
  typeof CreateUpdateBuyBoxFormSchema
>;

type CreateUpdateBuyBoxFormProps = {
  buyBox: BuyBoxDetails | null;
  firmId: EnderId;
  market: Market;
  marketId: EnderId;
  onSuccess?: () => void;
  propertyTypeOptions: LabelValue<PropertyHomeType>[];
};

function CreateUpdateBuyBoxForm({
  onSuccess = F.constVoid,
  buyBox,
  market,
  marketId,
  firmId,
  propertyTypeOptions,
}: CreateUpdateBuyBoxFormProps) {
  const [zipcodes, setZipcodes] = useState<SelectOption<string>[]>([]);
  const [cities, setCities] = useState<SelectOption<string>[]>([]);

  const form = useEffectSchemaForm({
    defaultValues: {
      excludeCities: buyBox?.excludeCities ?? [],
      excludeZipcodes: buyBox?.excludeZipcodes ?? [],
      maxListPrice: buyBox?.maxListPrice
        ? O.some(Money$.of(buyBox.maxListPrice))
        : O.none(),
      minBaths: O.fromNullable(buyBox?.minBaths),
      minBeds: O.fromNullable(buyBox?.minBeds),
      minCapRate: buyBox?.minCapRate
        ? O.some(Percent$.of(buyBox.minCapRate))
        : O.some(Percent$.of("5%")),
      minListPrice: buyBox?.minListPrice
        ? O.some(Money$.of(buyBox.minListPrice))
        : O.some(Money$.of("0")),
      minMedianIncome: buyBox?.minMedianIncome
        ? O.some(Money$.of(buyBox.minMedianIncome))
        : O.some(Money$.of("0")),
      minRent: buyBox?.minRent
        ? O.some(Money$.of(buyBox.minRent))
        : O.some(Money$.of("0")),
      minSchoolRating: O.fromNullable(buyBox?.minSchoolRating as SchoolScore),
      minSqft: O.fromNullable(buyBox?.minSqft),
      minYearBuilt: `${buyBox?.minYearBuilt ?? ""}`,
      name: buyBox?.name || "",
      propertyTypes: buyBox?.propertyTypes || [],
      type: pipe(
        O.fromNullable(buyBox?.type),
        O.orElseSome(() => BuyBoxBuyBoxTypeEnum.STANDALONE_DEAL),
      ),
    },
    schema: CreateUpdateBuyBoxFormSchema,
  });

  // lazy useEffect
  useEffect(() => {
    setZipcodes(
      buyBox?.excludeZipcodes.map((value) => ({ label: value, value })) || [],
    );
    setCities(
      buyBox?.excludeCities.map((value) => ({ label: value, value })) || [],
    );
  }, [buyBox]);

  const confirmation = useConfirmationContext();

  const { mutateAsync: updateBuyBox, isLoading: updatingBuyBox } = useMutation({
    mutationFn: BuyBoxAPI.updateBuyBox,
    mutationKey: ["BuyBoxAPI.updateBuyBox"] as const,
  });
  const { mutateAsync: createBuyBox, isLoading: creatingBuyBox } = useMutation({
    mutationFn: BuyBoxAPI.createBuyBox,
    mutationKey: ["BuyBoxAPI.createBuyBox"] as const,
  });

  function isBuyBoxBreadthReduced(values: CreateUpdateBuyBoxFormOutput) {
    if (P.isNull(buyBox)) {
      return false;
    }
    const buyBoxMap = new Map(Object.entries(buyBox));

    const fieldsAndValues = Object.entries(values);
    return fieldsAndValues.some(([field, value]) => {
      const buyBoxValue = buyBoxMap.get(field);

      if (
        field === "minSchoolRating" &&
        P.isString(value) &&
        P.isString(buyBoxValue)
      ) {
        return (
          cast<string[]>(schoolScores).indexOf(value) <
          cast<string[]>(schoolScores).indexOf(buyBoxValue)
        );
      }

      if (field === "propertyTypes") {
        return buyBox.propertyTypes.some(
          (propertyType) => !cast<string[]>(value).includes(propertyType),
        );
      }

      if (
        field === "maxListPrice" &&
        P.isString(buyBoxValue) &&
        S.isNonEmpty(buyBoxValue)
      ) {
        return (
          Money$.Order(cast<Money$.Money>(value), Money$.of(buyBoxValue)) < 0
        );
      }

      if (P.isNumber(value) && P.isNumber(buyBoxValue)) {
        return value > buyBoxValue;
      }
      if (
        Money$.isMoney(value) &&
        P.isString(buyBoxValue) &&
        S.isNonEmpty(buyBoxValue)
      ) {
        return Money$.Order(value, Money$.of(buyBoxValue)) > 0;
      }

      if (P.isString(value) && P.isString(buyBoxValue)) {
        return convertToNumber(value) > convertToNumber(buyBoxValue);
      }
      return false;
    });
  }

  const { data: widget } = useQuery({
    enabled: S.isNonEmpty(market.name),
    queryFn: async ({ signal }) => {
      const widget = await getWidget(
        "buy-box-market-cities",
        {
          requiredYFactors: {
            factorNames: [Factor.CITY, Factor.MARKET, Factor.ZIPCODE],
          },
        },
        signal,
      );
      const updatedWidget = { ...widget };
      const marketFactor = widget.yFactors.find(
        ({ name }) => name === Factor.MARKET,
      );
      if (P.isUndefined(marketFactor)) {
        return undefined;
      }

      updatedWidget.filters = [
        {
          factor: marketFactor,
          operator: ReportFilterOperatorEnum.IN,
          values: [market.name],
        },
      ];
      await ReportsAPI.updateWidget({
        widgetId: widget.id,
        ...widgetToUpdateRequest(updatedWidget),
      });
      return widget;
    },
    queryKey: ["buy-box-market-cities-widget", marketId] as const,
  });

  useQuery({
    enabled: P.isNotNullable(widget),
    queryFn: async ({ signal }) => {
      if (P.isNullable(widget)) {
        return;
      }

      const cityFactor = widget.yFactors.find(
        ({ name }) => name === Factor.CITY,
      );
      const zipFactor = widget.yFactors.find(
        ({ name }) => name === Factor.ZIPCODE,
      );
      if (P.isUndefined(cityFactor) || P.isUndefined(zipFactor)) {
        return [];
      }

      const data = await ReportsAPI.getFactorMetadataForWidget(
        {
          factorIds: [cityFactor.id, zipFactor.id],
          widgetId: widget.id,
        },
        { signal },
      );
      const zipOptions = data[zipFactor.id]
        .map(({ value }: { value: string }) => ({ label: value, value }))
        .sort(
          (
            a: { label: string; value: string },
            b: { label: string; value: string },
          ) => (a.label > b.label ? 1 : -1),
        );
      setZipcodes((z) => [...z, ...zipOptions]);

      const cityOptions = data[cityFactor.id]
        .map(({ value }: { value: string }) => ({ label: value, value }))
        .sort(
          (
            a: { label: string; value: string },
            b: { label: string; value: string },
          ) => (a.label > b.label ? 1 : -1),
        );
      setCities((c) => [...c, ...cityOptions]);
      return data;
    },
    queryKey: ["ReportsAPI.getFactorMetadataForWidget", widget] as const,
  });

  async function handleSubmit(values: CreateUpdateBuyBoxFormOutput) {
    const showWarning = isBuyBoxBreadthReduced(values);

    if (showWarning) {
      await confirmation({
        title:
          "Making this change may lead to some leads being lost. Are you certain you want to make this change?",
      });
    }

    const formattedFormValues = {
      ...values,
      minCapRate: pipe(
        values.minCapRate,
        O.map((percent) => percent.value),
        O.getOrNull,
      ),
      maxListPrice: O.getOrNull(values.maxListPrice),
      minListPrice: O.getOrNull(values.minListPrice),
      minMedianIncome: O.getOrNull(values.minMedianIncome),
      minRent: O.getOrNull(values.minRent),
      minBeds: O.getOrNull(values.minBeds),
      minBaths: O.getOrNull(values.minBaths),
      minSqft: O.getOrNull(values.minSqft),
      minSchoolRating: O.getOrNull(values.minSchoolRating),
      type: O.getOrNull(values.type),
    };

    const apiRequest = buyBox
      ? () =>
          updateBuyBox({
            buyBoxId: buyBox.id,
            ...formattedFormValues,
            //@ts-expect-error readonly/not readonly
            excludeCities: values.excludeCities,
          })
      : () =>
          createBuyBox({
            firmId,
            marketId,
            ...formattedFormValues,
            //@ts-expect-error readonly/not readonly
            excludeCities: values.excludeCities,
          });

    await apiRequest();
    onSuccess();
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput
          form={form}
          label="Name"
          name="name"
          disabled={!!buyBox}
        />
        <FormRadioGroup
          form={form}
          name="type"
          data={BuyBoxBuyBoxTypeValues.map((value) => ({
            label: convertSnakeCaseToTitleCase(value),
            value,
          }))}
        />
        <FormNumberInput label="Min Beds" name="minBeds" form={form} />
        <FormNumberInput label="Min Baths" name="minBaths" form={form} />
        <FormNumberInput label="Min Sqft" name="minSqft" form={form} />
        <FormTextInput label="Min Year Built" name="minYearBuilt" form={form} />
        <FormMoneyInput label="Min UW Rent" name="minRent" form={form} />
        <FormMoneyInput
          label="Min List Price"
          name="minListPrice"
          form={form}
        />
        <FormMoneyInput
          label="Max List Price"
          name="maxListPrice"
          form={form}
        />
        <FormMoneyInput
          label="Min Median Income"
          name="minMedianIncome"
          form={form}
        />
        <FormPercentInput label="Min Cap Rate" name="minCapRate" form={form} />
        <FormSelect
          label="Min School Score"
          data={schoolScoreOptions}
          name="minSchoolRating"
          form={form}
        />
        <FormMultiSelect<PropertyHomeType>
          form={form}
          label="Property Types"
          name="propertyTypes"
          data={propertyTypeOptions}
        />
        <FormMultiSelect<string>
          form={form}
          name="excludeCities"
          label="Exclude Cities"
          data={cities}
        />
        <FormMultiSelect<string>
          form={form}
          name="excludeZipcodes"
          label="Exclude Zip Codes"
          data={zipcodes}
        />
        <Group justify={Justify.end}>
          <Button type="submit" loading={creatingBuyBox || updatingBuyBox}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { CreateUpdateBuyBoxForm };
