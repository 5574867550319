/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/paymentPlans/{paymentPlanId}/delete
 * @function deletePaymentPlan
 * @memberof LeasingAPI
 * @param {LeasingAPIDeletePaymentPlanPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIDeletePaymentPlanPathParams = {
  leaseId: EnderId;
  paymentPlanId: EnderId;
};

type LeasingAPIDeletePaymentPlanSearchParams = {
  token?: string | undefined;
};

type LeasingAPIDeletePaymentPlanPayload =
  LeasingAPIDeletePaymentPlanPathParams &
    LeasingAPIDeletePaymentPlanSearchParams;

function deletePaymentPlanUnsafeEffect(
  payload: LeasingAPIDeletePaymentPlanPayload,
) {
  const { leaseId, paymentPlanId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/paymentPlans/${paymentPlanId}/delete`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deletePaymentPlan(
  payload: LeasingAPIDeletePaymentPlanPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deletePaymentPlanUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deletePaymentPlan };
export type {
  LeasingAPIDeletePaymentPlanPathParams,
  LeasingAPIDeletePaymentPlanPayload,
  LeasingAPIDeletePaymentPlanSearchParams,
};
