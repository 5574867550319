/**
 * List GL journal entries for the current invoker's pm company.
 * HTTP Method: GET
 * Pathname: /accounting/journal-entries
 * @function getJournalEntries
 * @memberof AccountingAPI
 * @param {AccountingAPIGetJournalEntriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingAPIGetJournalEntriesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AccountingAPIGetJournalEntriesResponse } from "../accounting-api-get-journal-entries-response";

type AccountingAPIGetJournalEntriesSearchParams = {
  token?: string | undefined;
  /**
   * Show imported GL entries only.
   */
  importedEntriesOnly?: boolean | undefined;
};

type AccountingAPIGetJournalEntriesPayload =
  AccountingAPIGetJournalEntriesSearchParams;

function getJournalEntriesUnsafeEffect(
  payload: AccountingAPIGetJournalEntriesPayload,
) {
  const { token, importedEntriesOnly, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingAPIGetJournalEntriesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          AccountingAPIGetJournalEntriesResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/accounting/journal-entries",
        searchParams: [
          ["token", token],
          ["importedEntriesOnly", importedEntriesOnly],
        ],
      }),
    ),
  );
}

function getJournalEntries(
  payload: AccountingAPIGetJournalEntriesPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingAPIGetJournalEntriesResponse[]> {
  return F.pipe(
    payload,
    getJournalEntriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getJournalEntries };
export type {
  AccountingAPIGetJournalEntriesPayload,
  AccountingAPIGetJournalEntriesSearchParams,
};
