import type { WithRequired } from "@tanstack/react-query";
import { z } from "zod";

import { EnderIdSchema, LocalTimeSchema } from "@ender/shared/core";
import type { CollectionsStep } from "@ender/shared/generated/ender.model.misc";
import {
  CollectionsStepChargeTypeValues,
  CollectionsStepCollectionsActionTypeEnum,
  CollectionsStepCollectionsActionTypeValues,
  CollectionsStepProcessTypeValues,
} from "@ender/shared/generated/ender.model.misc";
import { CurrencySchema, castEnum } from "@ender/shared/utils/zod";

const CollectionsPauseStatusValues = ["PAUSED", "UNPAUSED"] as const;
const CollectionsPauseStatusSchema = z.enum(CollectionsPauseStatusValues);
type CollectionsPauseStatus = z.infer<typeof CollectionsPauseStatusSchema>;

const CollectionsPauseStatusEnum = castEnum<CollectionsPauseStatus>(
  CollectionsPauseStatusSchema,
);

const CollectionsActionTypeForDisplayMap = {
  [CollectionsStepCollectionsActionTypeEnum.CREATE_TASK]: "Create a Task",
  [CollectionsStepCollectionsActionTypeEnum.EMAIL]: "Send an Email",
  [CollectionsStepCollectionsActionTypeEnum.LATE_FEE]: "Charge Late Fee",
  [CollectionsStepCollectionsActionTypeEnum.INTEND_TO_EVICT]: "Intend to Evict",
  [CollectionsStepCollectionsActionTypeEnum.MOVE_TO_EVICT]: "Move to Evictions",
  [CollectionsStepCollectionsActionTypeEnum.CERTIFIED_MAIL]:
    "Send Certified Mail",
  [CollectionsStepCollectionsActionTypeEnum.PHONE_CALL]: "Log a Phone Call",
  [CollectionsStepCollectionsActionTypeEnum.SMS]: "Send an SMS",
} as const;

const TemplatedCollectionsStepSchema = z.object({
  actionTime: LocalTimeSchema,
  actionType: z.enum(CollectionsStepCollectionsActionTypeValues),
  automatedAfterNHours: z.number().optional(),
  collectionsProcessId: EnderIdSchema.optional(),
  exclusiveStepSetId: EnderIdSchema.optional(),
  id: EnderIdSchema,
  isDeleted: z.boolean(),
  maxBalanceDue: CurrencySchema.optional(),
  maxChargeScheduleBasedThresholdMonthsCount: z.number().optional(),
  maxChargeScheduleBasedThresholdType: z
    .enum(CollectionsStepChargeTypeValues)
    .optional(),
  minBalanceDue: CurrencySchema.optional(),
  minChargeScheduleBasedThresholdMonthsCount: z.number().optional(),
  minChargeScheduleBasedThresholdType: z
    .enum(CollectionsStepChargeTypeValues)
    .optional(),
  numDaysPastRentDue: z.number(),
  ordering: z.number(),
  processType: z.enum(CollectionsStepProcessTypeValues),
  taskTitle: z.string(),
  templateId: EnderIdSchema,
});

type TemplatedCollectionsStep = WithRequired<CollectionsStep, "templateId">;

export {
  CollectionsActionTypeForDisplayMap,
  CollectionsPauseStatusEnum,
  CollectionsPauseStatusSchema,
  CollectionsPauseStatusValues,
  TemplatedCollectionsStepSchema,
};
export type { CollectionsPauseStatus, TemplatedCollectionsStep };
