/**
 * This API will return the SSN view history of all viewers of the invoker's PM company.
 * HTTP Method: GET
 * Pathname: /ssn/viewHistory
 * @function getSSNViewHistory
 * @memberof LeasingAPI
 * @param {LeasingAPIGetSSNViewHistoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetSSNViewHistoryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetSSNViewHistoryResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetSSNViewHistorySearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetSSNViewHistoryPayload =
  LeasingAPIGetSSNViewHistorySearchParams;

function getSSNViewHistoryUnsafeEffect(
  payload: LeasingAPIGetSSNViewHistoryPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetSSNViewHistoryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetSSNViewHistoryResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/ssn/viewHistory",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSSNViewHistory(
  payload: LeasingAPIGetSSNViewHistoryPayload,
  options?: { signal?: AbortSignal },
): Promise<GetSSNViewHistoryResponse[]> {
  return F.pipe(
    payload,
    getSSNViewHistoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSSNViewHistory };
export type {
  LeasingAPIGetSSNViewHistoryPayload,
  LeasingAPIGetSSNViewHistorySearchParams,
};
