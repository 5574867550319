import { Predicate as P } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import type { ApplicationGroup } from "@ender/shared/generated/ender.model.leasing";
import { ApplicationGroupApplicationStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import type { ApplicationRightRailValue } from "@ender/widgets/utils/application-page-right-rail";
import { ApplicationPageRightRailEnum } from "@ender/widgets/utils/application-page-right-rail";

type RecommendedActionProps = {
  leaseId?: EnderId;
  applicationGroup: Pick<ApplicationGroup, "status" | "archiveTime">;
  setRightRail: (rightRail: ApplicationRightRailValue) => void;
};
/**
 * TODO this file is mostly scaffolding, and i don't like it very much.
 * I think what I'd prefer is that we define all of our Application Actions as an array of keyed, isolated functions-
 * and then pass them to this component as a prop. That way, we can choose the function from that list that we want to render,
 * and invoke it on button press- no additional logic to execute functions should be present in this file.
 *
 * it's pretty ugly right now, but time constraints make it tomorrow's problem.
 */
function ApplicationGroupRecommendedAction(props: RecommendedActionProps) {
  const { leaseId, applicationGroup, setRightRail } = props;

  const hasLease = P.isNotNullable(leaseId);
  const isArchived = P.isNotNullable(applicationGroup.archiveTime);

  switch (applicationGroup.status) {
    case ApplicationGroupApplicationStatusEnum.IN_PROGRESS: {
      return null;
    }
    case ApplicationGroupApplicationStatusEnum.COMPLETE: {
      return null;
    }
    case ApplicationGroupApplicationStatusEnum.INITIAL_ACCEPTED: {
      return null;
    }
    case ApplicationGroupApplicationStatusEnum.ACCEPTED: {
      return (
        !hasLease &&
        !isArchived && (
          <Button
            onClick={() =>
              setRightRail({ type: ApplicationPageRightRailEnum.DRAFT_LEASE })
            }>
            Draft Lease
          </Button>
        )
      );
    }
    case ApplicationGroupApplicationStatusEnum.INITIAL_REJECTED: {
      /**
       * <>
          <Button>Send Back for Initial Review</Button>
          <Button>Give Final Denial</Button>
        </>
       */
      return null;
    }
    case ApplicationGroupApplicationStatusEnum.REJECTED: {
      return null;
    }
    default:
      return null;
  }
}

export { ApplicationGroupRecommendedAction };
