import { Array as A } from "effect";

const DISTRICT_RATINGS = [
  { rating: "A+", value: 4.25 },
  { rating: "A", value: 4 },
  { rating: "A-", value: 3.75 },
  { rating: "B+", value: 3.25 },
  { rating: "B", value: 3 },
  { rating: "B-", value: 2.75 },
  { rating: "C+", value: 2.25 },
  { rating: "C", value: 2 },
  { rating: "C-", value: 1.75 },
  { rating: "D+", value: 1.25 },
  { rating: "D", value: 1 },
  { rating: "D-", value: 0.75 },
  { rating: "F", value: 0 },
] as const;

// used to calculate School Score within Buy App
function averageDistrictRatings(districts: { rating?: string }[] = []) {
  //TODO filter only to districts whose rating is a valid rating from the DISTRICT_RATINGS const
  const districtsWithRating = districts.filter(
    (d): d is { rating: string } => !!d.rating,
  );
  const total = districtsWithRating.reduce(
    (acc, d) =>
      acc +
      (DISTRICT_RATINGS.find(({ rating }) => rating === d.rating.toUpperCase())
        ?.value ?? 0),
    0,
  );
  const average = A.isNonEmptyArray(districtsWithRating)
    ? total / districtsWithRating.length
    : 0;
  //TODO for safety, sort DISTRICT_RATINGS array by value highest -> lowest before finding the first rating that is <= average
  //find will always return at least F because 0<=average. optional chaining is just to placate typescript
  return (
    [...DISTRICT_RATINGS].find(({ value }) => value <= average)?.rating ?? "F"
  );
}

export { averageDistrictRatings, DISTRICT_RATINGS };
