/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/pets
 * @function getPets
 * @memberof PetsAPI
 * @param {PetsAPIGetPetsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Pet[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIGetPetsPathParams = {
  leaseId: EnderId;
};

type PetsAPIGetPetsSearchParams = {
  token?: string | undefined;
  includeArchived?: boolean | undefined;
};

type PetsAPIGetPetsPayload = PetsAPIGetPetsPathParams &
  PetsAPIGetPetsSearchParams;

function getPetsUnsafeEffect(payload: PetsAPIGetPetsPayload) {
  const { leaseId, token, includeArchived, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Pet[]>({
        body,
        decode: unsafeDecodeJsonResponse<Pet[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/pets`,
        searchParams: [
          ["token", token],
          ["includeArchived", includeArchived],
        ],
      }),
    ),
  );
}

function getPets(
  payload: PetsAPIGetPetsPayload,
  options?: { signal?: AbortSignal },
): Promise<Pet[]> {
  return F.pipe(
    payload,
    getPetsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPets };
export type {
  PetsAPIGetPetsPathParams,
  PetsAPIGetPetsPayload,
  PetsAPIGetPetsSearchParams,
};
