import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitAreaUnitAreaTypeValues = [
  "BASEMENT",
  "BEDROOM",
  "BONUS_ROOM",
  "CARPORT",
  "DINING_ROOM",
  "ENTRY",
  "EXTERIOR_FRONT",
  "EXTERIOR_BACK",
  "EXTERIOR_LEFT",
  "EXTERIOR_RIGHT",
  "FAMILY_ROOM",
  "FULL_BATHROOM",
  "GARAGE",
  "HALF_BATHROOM",
  "HALLWAY",
  "HVAC",
  "KITCHEN",
  "LAUNDRY_ROOM",
  "LIVING_ROOM",
  "POOL_AREA",
  "PROPERTY",
  "ROOF_AREA",
  "STAIRS",
] as const;

const UnitAreaUnitAreaTypeEffectSchema = Schema.Literal(
  ...UnitAreaUnitAreaTypeValues,
);

type UnitAreaUnitAreaType = Schema.Schema.Type<
  typeof UnitAreaUnitAreaTypeEffectSchema
>;

const UnitAreaUnitAreaTypeEnum = castEnum(UnitAreaUnitAreaTypeEffectSchema);

function randomUnitAreaUnitAreaType(): UnitAreaUnitAreaType {
  return rand(UnitAreaUnitAreaTypeValues);
}

export {
  randomUnitAreaUnitAreaType,
  UnitAreaUnitAreaTypeEffectSchema,
  UnitAreaUnitAreaTypeEnum,
  UnitAreaUnitAreaTypeValues,
};
export type { UnitAreaUnitAreaType };
