// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { ComponentType } from "react";

import {
  NavLogoDarkMarkOnlySvg,
  NavLogoDarkSvg,
  NavLogoMarkOnlySvg,
  NavLogoSvg,
} from "@ender/shared/assets";
import { useBrandingStore } from "@ender/shared/stores/branding-store";
import type { Branding, LogoVariant } from "@ender/shared/types/branding";

import type { ImageProps } from "./image-props";

/** @description The accepted variants for logos */
const ImageVariantBrandingMap: { [variant in LogoVariant]: keyof Branding } = {
  LARGE: "logoLarge",
  LARGE_ALT: "logoLargeAlt",
  SMALL: "logoSmall",
  SMALL_ALT: "logoSmallAlt",
} as const;

/**
 * @description The dimensions for the accepted variants of logos.
 * @todo Revisit once LiteralEnumsType is merged
 */
const ImageVariantDimensionMap: { [variant in LogoVariant]: [number, number] } =
  {
    LARGE: [88, 28],
    LARGE_ALT: [88, 28],
    SMALL: [28, 28],
    SMALL_ALT: [28, 28],
  } as const;

/** @description The default src value for the accepted variants of logos */
const ImageVariantSrcMap = {
  LARGE: NavLogoDarkSvg,
  LARGE_ALT: NavLogoSvg,
  SMALL: NavLogoDarkMarkOnlySvg,
  SMALL_ALT: NavLogoMarkOnlySvg,
} as const;

/**
 * @deprecated this should be located in a Branding package or directly co-located in the nav directory.
 *
 * it should also be combined with logo-image
 */
function LogoImageBase({
  fit = "contain",
  ImageComponent,
  src: inlineSrc,
  variant,
}: {
  fit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  ImageComponent: ComponentType<ImageProps>;
  /** @description An image src value which overrides the default and white label branded logos */
  src?: string | { src: string; width: number; height: number };
  /** @description The variant of the logo to display */
  variant: LogoVariant;
}) {
  const branding = useBrandingStore(({ branding }) => branding);
  const isBrandingEnabled = useBrandingStore(({ isEnabled }) => isEnabled);

  const logoAltText = `${!branding || !branding?.logoAltText ? "Ender" : branding.logoAltText}`;

  const [imageWidth, imageHeight] = ImageVariantDimensionMap[variant];

  const brandingLogoSrc = (
    inlineSrc ? inlineSrc : branding?.[ImageVariantBrandingMap[variant]]
  ) as string;
  const logoVariantSrc = ImageVariantSrcMap[variant];
  const logoSrc = isBrandingEnabled
    ? brandingLogoSrc || logoVariantSrc
    : logoVariantSrc;

  return (
    <ImageComponent
      src={logoSrc}
      width={imageWidth}
      height={imageHeight}
      alt={logoAltText}
      fit={fit}
    />
  );
}

export { LogoImageBase };
