/**
 * HTTP Method: POST
 * Pathname: /widgets/{widgetId}
 * @function updateWidget
 * @memberof ReportsAPI
 * @param {ReportsAPIUpdateWidgetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportsAPIUpdateWidgetResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type {
  ReportFilterRequest,
  WidgetLocationRequest,
} from "@ender/shared/generated/ender.api.reports.request";
import type { ReportWidgetSortOrder } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ReportsAPIUpdateWidgetResponse } from "../reports-api-update-widget-response";

type ReportsAPIUpdateWidgetPathParams = {
  widgetId: EnderId;
};

type ReportsAPIUpdateWidgetSearchParams = {
  token?: string | undefined;
};

type ReportsAPIUpdateWidgetBodyParams = {
  baseModelType?: ModelType | undefined;
  filters?: ReportFilterRequest[] | undefined;
  location?: WidgetLocationRequest | undefined;
  sortByFactor?: EnderId | undefined;
  sortOrder?: ReportWidgetSortOrder | undefined;
  xFactors?: EnderId[] | undefined;
  yFactors?: EnderId[] | undefined;
};

type ReportsAPIUpdateWidgetPayload = ReportsAPIUpdateWidgetPathParams &
  ReportsAPIUpdateWidgetSearchParams &
  ReportsAPIUpdateWidgetBodyParams;

function updateWidgetUnsafeEffect(payload: ReportsAPIUpdateWidgetPayload) {
  const { widgetId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportsAPIUpdateWidgetResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ReportsAPIUpdateWidgetResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/widgets/${widgetId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateWidget(
  payload: ReportsAPIUpdateWidgetPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIUpdateWidgetResponse> {
  return F.pipe(
    payload,
    updateWidgetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateWidget };
export type {
  ReportsAPIUpdateWidgetBodyParams,
  ReportsAPIUpdateWidgetPathParams,
  ReportsAPIUpdateWidgetPayload,
  ReportsAPIUpdateWidgetSearchParams,
};
