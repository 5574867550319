import type { EnderId } from "@ender/shared/core";
import type { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";

import type { PipelineStatus } from "../../../types";

const Factor = {
  BATHS: "# Bathrooms",
  BEDS: "# Bedrooms",
  CHANNEL: "Channel",
  CITY: "City",
  FILE_NAME: "File Name",
  ESTIMATE_RENT: "Estimated Rent",
  ESTIMATE_VALUE: "Estimated Value",
  INVESTOR: "Investor",
  LIST_DATE: "List Date",
  LIST_PRICE: "List Price",
  MARKET: "Market",
  NAME: "Name",
  OFFER_DATE: "Offer Date",
  OFFER_PRICE: "Offer Price",
  OWNER: "Owner",
  PROPERTY: "Property",
  SOURCE: "Source",
  SQFT: "Sqft",
  STATUS: "Deal Status",
  STREET: "Street",
  UNDERWRITER: "Underwriter",
  UNDERWRITING_SCORE: "Underwriting Score",
  UPLOAD_DATE: "Upload Date",
  YEAR_BUILT: "Year Built",
  ZIPCODE: "Zipcode",
  DUP_ADDRESS: "Duplicate Address Imported Recently",
} as const;

type FactorValue = (typeof Factor)[keyof typeof Factor];

type WidgetFilterOperator =
  (typeof DynamicTableOperatorsEnum)[keyof typeof DynamicTableOperatorsEnum];

type WidgetFactor = {
  id: EnderId;
  inputTypes: string[];
  name: FactorValue;
  outputType: string;
  parameterizedName: string;
};

type WidgetFilter = {
  factor: WidgetFactor;
  operator: WidgetFilterOperator;
  values: (string | number | { status: PipelineStatus; subStatus?: string })[];
};

type Widget = {
  filters: WidgetFilter[];
  id: EnderId;
  sortByFactor: WidgetFactor;
  sortOrder: "ASCENDING" | "DESCENDING";
  type: "TABLE";
  xFactors: WidgetFactor[];
  yFactors: WidgetFactor[];
};

type FilterComponentProps = {
  disabled?: boolean;
  factor?: WidgetFactor;
  metadata?: Record<string, number>;
  setHasError?: (hasError: boolean) => void;
  updateFilters: (
    filterName: FactorValue,
    filters: WidgetFilter[] | undefined,
  ) => void;
  widget: Widget;
};

export { Factor };
export type {
  FactorValue,
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
  WidgetFilterOperator,
};
