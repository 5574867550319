import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetRenewalsRequestRenewalSortKeyValues = [
  "EXPIRATION_DATE",
  "CURRENT_RENT",
] as const;

const GetRenewalsRequestRenewalSortKeyEffectSchema = Schema.Literal(
  ...GetRenewalsRequestRenewalSortKeyValues,
);

type GetRenewalsRequestRenewalSortKey = Schema.Schema.Type<
  typeof GetRenewalsRequestRenewalSortKeyEffectSchema
>;

const GetRenewalsRequestRenewalSortKeyEnum = castEnum(
  GetRenewalsRequestRenewalSortKeyEffectSchema,
);

function randomGetRenewalsRequestRenewalSortKey(): GetRenewalsRequestRenewalSortKey {
  return rand(GetRenewalsRequestRenewalSortKeyValues);
}

export {
  GetRenewalsRequestRenewalSortKeyEffectSchema,
  GetRenewalsRequestRenewalSortKeyEnum,
  GetRenewalsRequestRenewalSortKeyValues,
  randomGetRenewalsRequestRenewalSortKey,
};
export type { GetRenewalsRequestRenewalSortKey };
