import { Predicate as P } from "effect";
import { z } from "zod";

import { UNDEFINED } from "@ender/shared/constants/general";
import { Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { InsurancePolicyInsuredPartyEnum } from "@ender/shared/generated/ender.model.core.vendor";
import { CustomFields } from "@ender/shared/ui/custom-fields2";
import { noOpAsync } from "@ender/shared/utils/no-op";
import { castEnum } from "@ender/shared/utils/zod";
import { InsurancePolicies } from "@ender/widgets/leasing/insurance";

import { TenantDocuments } from "./documents/tenant-documents";
import { LeaseContacts } from "./lease-contacts/lease-contacts";
import { TenantPaymentControls } from "./payment-controls/tenant-payment-controls";
import { TenantPets } from "./pets/tenant-pets";
import { TenantSecurityDeposits } from "./security-deposits/tenant-security-deposits";
import { UnitInfo } from "./unit-info/unit-info";
import { TenantVehicles } from "./vehicles/tenant-vehicles";

/**
 * a vessel by which we can communicate the kind of success that has occurred
 * from any of these. We can use it to determine what to do in the parent after a successful update or resource creation
 */
const SuccessTypesSchema = z.enum(["UPDATED_TENANT_SECURITY_DEPOSITS"]);
type SuccessTypes = z.infer<typeof SuccessTypesSchema>;
const SuccessTypeEnum = castEnum<SuccessTypes>(SuccessTypesSchema);

type TenantPageDetailsProps = {
  lease: LeaseSerializerLeaseResponse;
  /**
   * invoked when a successful update mutation has occurred from any of the children
   * @param kind what just succeeded
   * @returns
   */
  onSuccess?: (kind: SuccessTypes) => Promise<unknown>;
};

function TenantPageDetails(props: TenantPageDetailsProps) {
  const { lease, onSuccess = noOpAsync } = props;
  if (P.isNullable(lease?.id)) {
    return UNDEFINED;
  }

  return (
    <Stack spacing={Spacing.xl}>
      <UnitInfo lease={lease} />
      <LeaseContacts lease={lease} />
      <TenantPaymentControls lease={lease} />
      <InsurancePolicies
        insuredPartyId={lease.id}
        insuredPartyType={InsurancePolicyInsuredPartyEnum.TENANT}
      />
      <TenantVehicles lease={lease} />
      <TenantPets lease={lease} />
      <TenantSecurityDeposits
        lease={lease}
        onSuccess={() =>
          onSuccess(SuccessTypeEnum.UPDATED_TENANT_SECURITY_DEPOSITS)
        }
      />
      <CustomFields modelId={lease.id} modelType={ModelTypeEnum.LEASE} />
      <TenantDocuments lease={lease} />
    </Stack>
  );
}

export { TenantPageDetails };
