/**
 * HTTP Method: POST
 * Pathname: /chargePMFees
 * @function chargePMFees
 * @memberof InvoicesAPI
 * @param {InvoicesAPIChargePMFeesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice>}
 */
import { Effect, Function as F } from "effect";

import type { LocalDate, SerializesInto } from "@ender/shared/core";
import type { GLAllocation } from "@ender/shared/generated/ender.arch.accounting";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIChargePMFeesSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIChargePMFeesBodyParams = {
  allocations: GLAllocation[];
  date: SerializesInto<LocalDate>;
  description: string;
};

type InvoicesAPIChargePMFeesPayload = InvoicesAPIChargePMFeesSearchParams &
  InvoicesAPIChargePMFeesBodyParams;

function chargePMFeesUnsafeEffect(payload: InvoicesAPIChargePMFeesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/chargePMFees",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function chargePMFees(
  payload: InvoicesAPIChargePMFeesPayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice> {
  return F.pipe(
    payload,
    chargePMFeesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { chargePMFees };
export type {
  InvoicesAPIChargePMFeesBodyParams,
  InvoicesAPIChargePMFeesPayload,
  InvoicesAPIChargePMFeesSearchParams,
};
