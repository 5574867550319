import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SignedDocumentSignatureStatusValues = [
  "DRAFTING",
  "CREATING_PDF",
  "ERROR",
  "OUT_FOR_SIGNATURES",
  "FINAL_SIGNATURE",
  "COMPLETE",
  "CANCELED",
] as const;

const SignedDocumentSignatureStatusEffectSchema = Schema.Literal(
  ...SignedDocumentSignatureStatusValues,
);

type SignedDocumentSignatureStatus = Schema.Schema.Type<
  typeof SignedDocumentSignatureStatusEffectSchema
>;

const SignedDocumentSignatureStatusEnum = castEnum(
  SignedDocumentSignatureStatusEffectSchema,
);

function randomSignedDocumentSignatureStatus(): SignedDocumentSignatureStatus {
  return rand(SignedDocumentSignatureStatusValues);
}

export {
  randomSignedDocumentSignatureStatus,
  SignedDocumentSignatureStatusEffectSchema,
  SignedDocumentSignatureStatusEnum,
  SignedDocumentSignatureStatusValues,
};
export type { SignedDocumentSignatureStatus };
