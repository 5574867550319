import { z } from "zod";

import type { Undefined } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { FileRequest } from "@ender/shared/generated/ender.api.files.request";
import type { RenewalsAPIBulkUploadRenewalsPayload } from "@ender/shared/generated/ender.api.leasing";
import type {
  FileExportAPIArAgingReportExcelPayload,
  FileExportAPIArPaymentAllocationReportExcelPayload,
  FileExportAPIChargeSchedulesReportExcelPayload,
  FileExportAPICollectionsReportExcelPayload,
  FileExportAPIExpandedMoveOutReportExcelPayload,
  FileExportAPIExportAllocationReportPayload,
  FileExportAPIExportBalanceSheetCSVPayload,
  FileExportAPIExportBalanceSheetExcelPayload,
  FileExportAPIExportBalanceSheetExcelV2Payload,
  FileExportAPIExportBankRecReportPayload,
  FileExportAPIExportChartOfAccountsPayload,
  FileExportAPIExportDelinquencyReportPayload,
  FileExportAPIExportDepositsReportPayload,
  FileExportAPIExportGeneralLedgerCSVPayload,
  FileExportAPIExportGeneralLedgerExcelPayload,
  FileExportAPIExportIncomeStatementCSVPayload,
  FileExportAPIExportIncomeStatementExcelV2Payload,
  FileExportAPIExportIncomeStatementPayload,
  FileExportAPIExportInternalTransfersReportPayload,
  FileExportAPIExportInvestmentApprovalPayload,
  FileExportAPIExportLeasesNeedingRenewalsExcelPayload,
  FileExportAPIExportOfferReportPayload,
  FileExportAPIExportPMLevelDelinquencyMetricsPayload,
  FileExportAPIExportPayablesDashboardPayload,
  FileExportAPIExportPayablesReportPayload,
  FileExportAPIExportRentRollReportPayload,
  FileExportAPIExportScheduledMoveOutsPayload,
  FileExportAPIExportTieOutReportPayload,
  FileExportAPIExportTrialBalanceExcelV2Payload,
  FileExportAPIExportTrialBalanceExcelV3Payload,
  FileExportAPIExportUnderwrittenHomesReportPayload,
  FileExportAPIExportWorkingTrialBalancePayload,
  FileExportAPIExportYearOverYearReportPayload,
  FileExportAPIGrossPotentialRentReportExcelPayload,
  FileExportAPIJournalEntriesReportExcelPayload,
  FileExportAPILeaseAnalysisReportExcelPayload,
  FileExportAPIPropertyBreakdownWTBReportExcelPayload,
  FileExportAPIPropertyCashReconciliationReportExcelPayload,
  FileExportAPIPropertyExpensesReportExcelPayload,
  ReportsAPIExportSQLReportCSVPayload,
  ReportsAPIGetSqlReportsPayload,
  ReportsAPIRunSystemReportPayload,
  ReportsAPIRunSystemReportResponse,
} from "@ender/shared/generated/ender.api.reports";
import type { SQLReport } from "@ender/shared/generated/ender.model.reports";
import type { WebserverFilesServiceFileUploadType } from "@ender/shared/generated/ender.service.files";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { environmentStore } from "@ender/shared/stores/environment-store";
import { rest } from "@ender/shared/utils/rest";

type PresignedItem = Pick<FileRequest, "bucket" | "path"> & {
  id: EnderId;
  uploadUrl: string;
};

type ModelInformation = {
  modelType: ModelType;
  modelId: EnderId;
  secondaryModelId?: EnderId;
};

type FileApiParameters = ModelInformation & {
  subFolder?: "PUBLIC" | "PRIVATE";
  uploadType?: WebserverFilesServiceFileUploadType;
};

type UploadFileApiParameters = FileApiParameters & {
  files: File[];
};

function exportPMLevelDelinquencyMetrics(
  payload: FileExportAPIExportPMLevelDelinquencyMetricsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/pmLevelDelinquencyMetrics.xlsx`, payload, options);
}

function getSqlReports(
  payload: ReportsAPIGetSqlReportsPayload,
  options?: { signal?: AbortSignal },
): Promise<SQLReport[]> {
  return rest.get(`/sqlReports`, payload, options);
}

function runSystemReport(
  payload: ReportsAPIRunSystemReportPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIRunSystemReportResponse> {
  const { reportId } = payload;
  return rest.post(`/systemReports/${reportId}/run`, payload, options);
}

function exportSQLReportCSV(
  payload: ReportsAPIExportSQLReportCSVPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  const { reportId } = payload;
  return rest.post(`/systemReports/${reportId}/csv.zip`, payload, options);
}

function exportTieOutReport(
  payload: FileExportAPIExportTieOutReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/tieOutReport.txt`, payload, options);
}

function exportChartOfAccounts(
  payload: FileExportAPIExportChartOfAccountsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/chartOfAccounts.xlsx`, payload, options);
}

function exportScheduledMoveOutReport(
  payload: FileExportAPIExportScheduledMoveOutsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/leasing-center/scheduledMoveOuts.xlsx`, payload, options);
}

function exportLeasesNeedingRenewalsExcel(
  payload: FileExportAPIExportLeasesNeedingRenewalsExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(
    `/leasing-center/leasesNeedingRenewals.xlsx`,
    payload,
    options,
  );
}

function exportYearOverYearReport(
  payload: FileExportAPIExportYearOverYearReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/yearOverYearReport.xlsx`, payload, options);
}

function exportWorkingTrialBalance(
  payload: FileExportAPIExportWorkingTrialBalancePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/workingTrialBalance.xlsx`, payload, options);
}

function exportRentRollReport(
  payload: FileExportAPIExportRentRollReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/rentRollReport.xlsx`, payload, options);
}

function exportPayablesReport(
  payload: FileExportAPIExportPayablesReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/payablesReport.xlsx`, payload, options);
}

function grossPotentialRentReportExcel(
  payload: FileExportAPIGrossPotentialRentReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/grossPotentialRent.xlsx`, payload, options);
}

function expandedMoveOutReportExcel(
  payload: FileExportAPIExpandedMoveOutReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/expandedMoveOut.xlsx`, payload, options);
}

function chargeSchedulesReportExcel(
  payload: FileExportAPIChargeSchedulesReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/chargeSchedules.xlsx`, payload, options);
}

function arPaymentAllocationReportExcel(
  payload: FileExportAPIArPaymentAllocationReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/arPaymentAllocationReport.xlsx`, payload, options);
}

function leaseAnalysisReportExcel(
  payload: FileExportAPILeaseAnalysisReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/leaseAnalysis.xlsx`, payload, options);
}

function journalEntriesReportExcel(
  payload: FileExportAPIJournalEntriesReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/journalEntriesReport.xlsx`, payload, options);
}

function exportIncomeStatement(
  payload: FileExportAPIExportIncomeStatementPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/incomeStatement.xlsx`, payload, options);
}

function exportIncomeStatementCSV(
  payload: FileExportAPIExportIncomeStatementCSVPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/incomeStatement.csv`, payload, options);
}

function exportTrialBalanceExcelV2(
  payload: FileExportAPIExportTrialBalanceExcelV2Payload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/trialBalanceV2.xlsx`, payload, options);
}

function exportTrialBalanceExcelV3(
  payload: FileExportAPIExportTrialBalanceExcelV3Payload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/trialBalanceV3.xlsx`, payload, options);
}

function exportIncomeStatementExcelV2(
  payload: FileExportAPIExportIncomeStatementExcelV2Payload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/incomeStatementV2.xlsx`, payload, options);
}

function exportBalanceSheetExcelV2(
  payload: FileExportAPIExportBalanceSheetExcelV2Payload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/balanceSheetV2.xlsx`, payload, options);
}

function exportDelinquencyReport(
  payload: FileExportAPIExportDelinquencyReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/delinquencyReport.xlsx`, payload, options);
}

function collectionsReportExcel(
  payload: FileExportAPICollectionsReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/collections.xlsx`, payload, options);
}

function exportInternalTransfersReport(
  payload: FileExportAPIExportInternalTransfersReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/internalTransfers.xlsx`, payload, options);
}

function exportDepositsReport(
  payload: FileExportAPIExportDepositsReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/depositsReport.xlsx`, payload, options);
}

function propertyCashReconciliationReportExcel(
  payload: FileExportAPIPropertyCashReconciliationReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/propertyCashReconciliation.xlsx`, payload, options);
}

function exportBankRecReport(
  payload: FileExportAPIExportBankRecReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/bankRecReport.xlsx`, payload, options);
}

function exportUnderwrittenHomesReport(
  payload: FileExportAPIExportUnderwrittenHomesReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.get(`/buy/underwrittenHomes.xlsx`, payload, options);
}

function exportOfferReport(
  payload: FileExportAPIExportOfferReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.get(`/buy/offerReport.xlsx`, payload, options);
}

function exportInvestmentApproval(
  payload: FileExportAPIExportInvestmentApprovalPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  const { dealId } = payload;
  return rest.post(
    `/buy/deals/${dealId}/investmentApproval.xlsx`,
    payload,
    options,
  );
}

function exportAllocationReport(
  payload: FileExportAPIExportAllocationReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/buy/allocationReport.xlsx`, payload, options);
}

function exportPayablesDashboardReport(
  payload: FileExportAPIExportPayablesDashboardPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/payablesDashboard.xlsx`, payload, options);
}

function propertyBreakdownWTBReportExcel(
  payload: FileExportAPIPropertyBreakdownWTBReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/propertyBreakdownWTB.xlsx`, payload, options);
}

function arAgingReportExcel(
  payload: FileExportAPIArAgingReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/arAgingReport.xlsx`, payload, options);
}

function exportGeneralLedgerExcel(
  payload: FileExportAPIExportGeneralLedgerExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/generalLedger.xlsx`, payload, options);
}

function exportGeneralLedgerCSV(
  payload: FileExportAPIExportGeneralLedgerCSVPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/generalLedger.csv`, payload, options);
}

function exportBalanceSheetCSV(
  payload: FileExportAPIExportBalanceSheetCSVPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/balanceSheet.csv`, payload, options);
}

function exportBalanceSheetExcel(
  payload: FileExportAPIExportBalanceSheetExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/accounting/balanceSheet.xlsx`, payload, options);
}

/** @description The API endpoint to delete a file by Model type and ID */
function deleteFile(
  data: ModelInformation & {
    fileId: string;
    path: string;
    subFolder?: "PUBLIC" | "PRIVATE";
  },
): Promise<void> {
  return rest.delete(`/files/${data.fileId}`, data);
}

function propertyExpensesReportExcel(
  payload: FileExportAPIPropertyExpensesReportExcelPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/propertyExpenses.xlsx`, payload, options);
}

function bulkUploadRenewals(
  payload: RenewalsAPIBulkUploadRenewalsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return rest.post(`/renewalOffers/fileUpload`, payload, options);
}

function moveFile(
  data: ModelInformation & {
    from: string;
    to: string;
  },
): Promise<void> {
  const formData = new FormData();
  formData.append("json", JSON.stringify(data));
  return rest.post("/moveFile", formData);
}

function convertFileName(file: File): string {
  if (file.type === "image/heic") {
    return file.name.replace(".heic", ".jpg");
  }

  return file.name;
}

async function convertHeicToJpeg(file: File): Promise<Blob> {
  const heic2any = (await import("heic2any")).default;
  return heic2any({
    blob: file,
    toType: "image/jpeg",
  }).then((jpegBlob) => {
    return jpegBlob instanceof Blob ? jpegBlob : jpegBlob[0];
  });
}

async function uploadFiles({
  files,
  modelId,
  modelType,
  secondaryModelId,
  subFolder = "PRIVATE",
  uploadType = WebserverFilesServiceFileUploadTypeEnum.DOCUMENT,
}: UploadFileApiParameters): Promise<void> {
  const formData = new FormData();

  for (const file of files) {
    if (file.type === "image/heic") {
      const converted = await convertHeicToJpeg(file);
      formData.append("file", converted, file.name.replace(".heic", ".jpg"));
    } else {
      formData.append("file", file);
    }
  }

  const jsonData = JSON.stringify({
    modelId,
    secondaryModelId,
    modelType,
    uploadType,
    subFolder,
  });

  formData.append("json", jsonData);

  return rest.post("/uploadFiles", formData);
}

function getFilesApiHostName() {
  const { isProduction } = environmentStore.getState();
  if (isProduction) {
    return "files.ender.com";
  }

  return "files-sandbox.ender.com";
}

/** @description Get presigned request data for S3 uploads, along with EnderIds for linking to DB */
function getPresignedItems({
  numFiles,
  userId,
}: {
  numFiles: number;
  userId: EnderId;
}): Promise<PresignedItem[]> {
  const { environment } = environmentStore.getState();
  // token: "limited" is required for FilesAPI handler as a form of security. NOT directly related to AWS.
  return rest.post(
    `https://${getFilesApiHostName()}/createPresignedUploadRequests`,
    {
      authorId: userId,
      environment,
      numFiles,
      token: "limited",
    },
  );
}

async function uploadFilesToS3({
  files,
  userId,
}: {
  files: File[];
  userId: EnderId;
}): Promise<FileRequest[]> {
  const presignedItems = await getPresignedItems({
    numFiles: files.length,
    userId,
  });

  const fileS3ToDBDataItems: FileRequest[] = [];

  for (let i = 0; i < presignedItems.length; i++) {
    const item = presignedItems[i];
    const file = files[i];

    const formData = new FormData();

    // @ts-expect-error formFields is missing on type PresignedItem
    item.formFields.forEach((field) => {
      const [key, value] = Object.entries(field)[0];
      // @ts-expect-error FormData append can take any value
      formData.append(key, value);
    });

    if (file.type === "image/heic") {
      const converted = await convertHeicToJpeg(file);

      formData.append("Content-Type", "image/jpeg");
      formData.append("file", converted, file.name.replace(".heic", ".jpg"));
    } else {
      formData.append("Content-Type", file.type);
      formData.append("file", file);
    }

    await rest.post(item.uploadUrl, formData);
    fileS3ToDBDataItems.push({
      bucket: item.bucket,
      path: item.path,
      filename: convertFileName(file),
    });
  }

  return fileS3ToDBDataItems;
}

const UploadFilesResponseSchema = z.object({
  fileName: z.string(),
  model: z.unknown().optional(),
  modelId: EnderIdSchema.optional(),
  s3Url: z.string(),
});

type UploadFilesResponse = z.infer<typeof UploadFilesResponseSchema>;

function linkS3FilesToDB({
  asMessage = false,
  modelType,
  modelId,
  uploadType = WebserverFilesServiceFileUploadTypeEnum.DOCUMENT,
  files,
  subFolder = "PRIVATE",
}: FileApiParameters & {
  asMessage?: boolean;

  /** @description File data can be handled as either Files or FileRequests */
  files: File[] | FileRequest[];
}): Promise<UploadFilesResponse[]> {
  const linkFormData = new FormData();

  linkFormData.append(
    "json",
    JSON.stringify({
      asMessage,
      files,
      modelId,
      modelType,
      subFolder: subFolder,
      uploadType,
    }),
  );

  return rest.post("/uploadFiles", linkFormData);
}

async function uploadFilesDirect({
  asMessage = false,
  files,
  modelId,
  modelType,
  subFolder = "PRIVATE",
  uploadType = WebserverFilesServiceFileUploadTypeEnum.DOCUMENT,
  userId,
}: UploadFileApiParameters & {
  asMessage?: boolean;
  userId: EnderId;
}): Promise<UploadFilesResponse[]> {
  const s3Files = await uploadFilesToS3({ files, userId });
  const response = await linkS3FilesToDB({
    asMessage,
    files: s3Files,
    modelId,
    modelType,
    subFolder,
    uploadType,
  });

  return UploadFilesResponseSchema.array().parse(response);
}

/** @description Array of possible tag strings */
type PossibleTagsResponse = Promise<string[]>;

function getPossibleTags({
  modelType,
}: {
  modelType: ModelType;
}): PossibleTagsResponse {
  return rest.get(`/possibleTags?modelType=${modelType}`);
}

type DownloadPhotoProps = {
  inspectionId?: EnderId | Undefined;
};
function downloadPhotosPDF({
  inspectionId,
}: DownloadPhotoProps): Promise<void> {
  return rest.get(`/construction/inspections/${inspectionId}/photos.pdf`);
}
function downloadPhotosZIP({
  inspectionId,
}: DownloadPhotoProps): Promise<void> {
  return rest.get(`/construction/inspections/${inspectionId}/photos.zip`);
}
function downloadTaskPhotosZIP({
  taskId,
  taskName,
}: {
  taskId: EnderId;
  taskName?: string;
}): Promise<void> {
  return rest.get(`/tasks/${taskId}/downloadPhotosZIP`, { taskName });
}

export {
  UploadFilesResponseSchema,
  arAgingReportExcel,
  arPaymentAllocationReportExcel,
  bulkUploadRenewals,
  chargeSchedulesReportExcel,
  collectionsReportExcel,
  convertHeicToJpeg,
  deleteFile,
  downloadPhotosPDF,
  downloadPhotosZIP,
  downloadTaskPhotosZIP,
  expandedMoveOutReportExcel,
  exportAllocationReport,
  exportBalanceSheetCSV,
  exportBalanceSheetExcel,
  exportBalanceSheetExcelV2,
  exportBankRecReport,
  exportChartOfAccounts,
  exportDelinquencyReport,
  exportDepositsReport,
  exportGeneralLedgerCSV,
  exportGeneralLedgerExcel,
  exportIncomeStatement,
  exportIncomeStatementCSV,
  exportIncomeStatementExcelV2,
  exportInternalTransfersReport,
  exportInvestmentApproval,
  exportLeasesNeedingRenewalsExcel,
  exportOfferReport,
  exportPMLevelDelinquencyMetrics,
  exportPayablesDashboardReport,
  exportPayablesReport,
  exportRentRollReport,
  exportSQLReportCSV,
  exportScheduledMoveOutReport,
  exportTieOutReport,
  exportTrialBalanceExcelV2,
  exportTrialBalanceExcelV3,
  exportUnderwrittenHomesReport,
  exportWorkingTrialBalance,
  exportYearOverYearReport,
  getPossibleTags,
  getSqlReports,
  grossPotentialRentReportExcel,
  journalEntriesReportExcel,
  leaseAnalysisReportExcel,
  linkS3FilesToDB,
  moveFile,
  propertyBreakdownWTBReportExcel,
  propertyCashReconciliationReportExcel,
  propertyExpensesReportExcel,
  runSystemReport,
  uploadFiles,
  uploadFilesDirect,
  uploadFilesToS3,
};
export type {
  FileApiParameters,
  ModelInformation,
  UploadFileApiParameters,
  UploadFilesResponse,
};
