"use client";

import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { Color, Size, SizeSchema } from "@ender/shared/utils/theming";

const TextColorSchema = Schema.Literal(
  "inherit",
  "white",
  `${Color.slate}-900`,
  `${Color.slate}-700`,
  `${Color.slate}-500`,
  `${Color.primary}-500`,
  `${Color.primary}-800`,
  `${Color.green}-400`,
  `${Color.green}-600`,
  `${Color.red}-300`,
  `${Color.red}-400`,
  `${Color.red}-500`,
  `${Color.yellow}-400`,
  `${Color.yellow}-800`,
  `${Color.orange}-600`,
);
const TextColorValues = TextColorSchema.literals;
type TextColors = Schema.Schema.Type<typeof TextColorSchema>;
const TextColor = castEnum(TextColorSchema);

const FontSizeSchema = Schema.Literal(
  ...SizeSchema.pipe(Schema.pickLiteral(Size.xs, Size.sm, Size.md, Size.lg))
    .literals,
  "inherit",
);
const FontSizeValues = FontSizeSchema.literals;
type FontSizes = Schema.Schema.Type<typeof FontSizeSchema>;
const FontSize = castEnum(FontSizeSchema);

const FontWeightSchema = Schema.Literal(
  "normal",
  "medium",
  "semibold",
  "bold",
  "inherit",
);
const FontWeightValues = FontWeightSchema.literals;
type FontWeights = Schema.Schema.Type<typeof FontWeightSchema>;
const FontWeight = castEnum(FontWeightSchema);

const TextAlignSchema = Schema.Literal("left", "right", "center");
const TextAlignValues = TextAlignSchema.literals;
type TextAlignments = Schema.Schema.Type<typeof TextAlignSchema>;
const TextAlign = castEnum(TextAlignSchema);

type TextProps = {
  /**
   * sizes map to the following:
   * xs: 10px
   * sm: 12px
   * md: 14px
   * lg: 16px
   */
  size?: FontSizes;
  /**
   * weights map to the following:
   * normal: 400
   * medium: 500
   * semibold: 600
   * bold: 700
   */
  weight?: FontWeights;
  align?: TextAlignments;
  color?: TextColors;
  /**
   * prevents text wrapping and cuts off the text with ellipsis based on the parent container.
   */
  truncate?: boolean;
};

const TextVariantGenerator = cva([""], {
  compoundVariants: [],
  defaultVariants: {
    align: TextAlign.left,
    color: `${Color.slate}-900`,
    size: FontSize.inherit,
    truncate: false,
    weight: FontWeight.normal,
  },
  variants: {
    align: {
      [TextAlign.left]: "text-left",
      [TextAlign.right]: "text-right",
      [TextAlign.center]: "text-center",
    },
    color: {
      [TextColor.inherit]: "text-inherit",
      [TextColor.white]: "text-white",
      [TextColor["slate-900"]]: "text-slate-900",
      [TextColor["slate-700"]]: "text-slate-700",
      [TextColor["slate-500"]]: "text-slate-500",
      [TextColor["primary-500"]]: "text-primary-500",
      [TextColor["primary-800"]]: "text-primary-800",
      [TextColor["green-400"]]: "text-green-400",
      [TextColor["green-600"]]: "text-green-600",
      [TextColor["red-300"]]: "text-red-300",
      [TextColor["red-400"]]: "text-red-400",
      [TextColor["red-500"]]: "text-red-500",
      [TextColor["yellow-400"]]: "text-yellow-400",
      [TextColor["yellow-800"]]: "text-yellow-800",
      [TextColor["orange-600"]]: "text-orange-600",
    },
    size: {
      [FontSize.xs]: "text-xxs",
      [FontSize.sm]: "text-xs/normal",
      [FontSize.md]: "text-sm",
      [FontSize.lg]: "text-base",
      [FontSize.inherit]: "",
    },
    truncate: {
      false: "",
      true: "truncate max-w-full",
    },
    weight: {
      [FontWeight.inherit]: "font-inherit",
      [FontWeight.normal]: "font-normal",
      [FontWeight.medium]: "font-medium",
      [FontWeight.semibold]: "font-semibold",
      [FontWeight.bold]: "font-bold",
    },
  },
});

/**
 * within this element, you are permitted to wrap contents in these tags:
 * - `<b>` - Bold text
 * - `<strong>` - Important text
 * - `<i>` - Italic text
 * - `<em>` - Emphasized text
 * - `<mark>` - Marked text
 * - `<small>` - Smaller text
 * - `<del>` - Deleted text
 * - `<ins>` - Inserted text
 * - `<sub>` - Subscript text
 * - `<sup>` - Superscript text
 * - `<u>` - unarticulated text
 * - `<span className="underline">` - tailwind-styled inline content
 * - `<span className="line-through">` - tailwind-styled inline content
 * - `<span className="italic">` - tailwind-styled inline content
 */
const Text = forwardRef<HTMLParagraphElement, PropsWithChildren<TextProps>>(
  function Text(props, ref) {
    const { children } = props;
    return (
      <p ref={ref} className={TextVariantGenerator(props)}>
        {children}
      </p>
    );
  },
);

export {
  FontSize,
  FontSizeValues,
  FontWeight,
  FontWeightValues,
  Text,
  TextAlign,
  TextAlignValues,
  TextColor,
  TextColorValues,
};

export type { FontSizes, FontWeights, TextAlignments, TextColors, TextProps };
