import { Array as A, Predicate as P } from "effect";
import { useEffect, useState } from "react";

import type { GetDealResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import { DealStatusPipelineStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { GetPMResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { ChatChannelParams } from "@ender/widgets/chat";
import { Chat } from "@ender/widgets/chat";

const nonBuysideBrokerChatStatuses: string[] = [
  DealStatusPipelineStatusEnum.NOT_FOR_SALE,
  DealStatusPipelineStatusEnum.ON_MARKET,
  DealStatusPipelineStatusEnum.LEAD,
];

type PurchasablePropertyChatProps = {
  invoker: User;
  deal: GetDealResponse;
  pm: GetPMResponse;
};

function PurchasablePropertyChat({
  invoker,
  deal,
  pm,
}: PurchasablePropertyChatProps) {
  const [chatChannels, setChatChannels] = useState<ChatChannelParams[]>([]);

  const isBuysideBrokerChatVisible =
    P.isNotNullable(deal.buySideBroker) &&
    !deal.hideFromBrokers &&
    !nonBuysideBrokerChatStatuses.includes(deal.status.pipelineStatus.id);

  // lazy useEffect
  useEffect(() => {
    const _chatChannels: ChatChannelParams[] = [];

    if (isBuysideBrokerChatVisible) {
      _chatChannels.push({
        chatTitle: invoker.isPM ? "Buy-side Broker" : "Chat about this Deal",
        isInternal: false,
        modelParams: [{ modelId: deal.id, modelType: ModelTypeEnum.DEAL }],
        modelType: ModelTypeEnum.MULTI_MODEL_CHAT,
      });
    }

    if (invoker.isPM && !!pm) {
      _chatChannels.push({
        chatTitle: "Internal Chat",
        isInternal: false,
        modelParams: [
          { modelId: deal.id, modelType: ModelTypeEnum.DEAL },
          { modelId: pm.id, modelType: ModelTypeEnum.PROPERTY_MANAGER },
        ],
        modelType: ModelTypeEnum.MULTI_MODEL_CHAT,
      });
    }
    if (A.isEmptyArray(_chatChannels)) {
      return;
    }

    setChatChannels(_chatChannels);
  }, [deal, invoker.isPM, isBuysideBrokerChatVisible, pm]);

  return <Chat title="Deal Chat" channels={chatChannels} />;
}

export { PurchasablePropertyChat };
