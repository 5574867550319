import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import * as A from "effect/Array";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { InvoiceResponseBillBackResponse } from "@ender/shared/generated/ender.arch.accounting";
import { cast } from "@ender/shared/types/cast";
import type {
  ColumnDef,
  EnderTableSortingParams,
} from "@ender/shared/ui/table-tanstack";
import { EnderTableTanstack, useTable } from "@ender/shared/ui/table-tanstack";

import { EmptyMessage } from "./empty-message";
import { LeaseTenants } from "./lease-tenants";

import styles from "./tenant-billbacks-table.module.css";

type PropertyCellProps = {
  propertyId: EnderId;
};

function PropertyCell({ propertyId }: PropertyCellProps) {
  const { data: property } = useQuery({
    queryFn: ({ signal }) =>
      PropertiesAPI.getProperty({ propertyId }, { signal }),
    queryKey: ["PropertiesAPI.getProperty", propertyId] as const,
  });

  return property?.friendlyId || property?.name || NULL;
}

type TenantBillbacksTableRow = InvoiceResponseBillBackResponse;
const columns: ColumnDef<TenantBillbacksTableRow>[] = [
  {
    accessorFn: (row) => row.lease?.property?.id,
    cell: (props) =>
      props.getValue() ? <PropertyCell propertyId={props.getValue()} /> : NULL,
    header: "Property",
    id: "propertyId",
  },
  {
    accessorFn: (row) => row.lease?.id,
    cell: (props) =>
      P.isNotNullable(props.getValue()) ? (
        <LeaseTenants leaseId={props.getValue()} />
      ) : (
        "--"
      ),
    header: "Tenant",
    id: "leaseId",
  },
  {
    accessorFn: (row) =>
      `${row.chargeType.offsetCategory.accountNumber} ${row.chargeType.name}`,
    header: "Offset Account",
    id: "offsetAccount",
  },
  {
    accessorKey: "amount",
    cell: (props) => (
      <MoneyDisplay showSymbol value={Money$.parse(props.getValue())} />
    ),
    className: styles.amountColumn,
    header: "Amount",
  },
];

type TenantBillbacksTableProps = {
  billbacks?: InvoiceResponseBillBackResponse[];
};

function TenantBillbacksTable({ billbacks = [] }: TenantBillbacksTableProps) {
  const table = useTable<TenantBillbacksTableRow>({
    columns,
    data: billbacks,
    sorting: cast<EnderTableSortingParams>({ enableSorting: false }),
  });

  if (A.isEmptyArray(billbacks)) {
    return <EmptyMessage>No Tenant Billbacks</EmptyMessage>;
  }

  return (
    <EnderTableTanstack
      className={styles.tenantBillbacksTable}
      table={table}
      title="Tenant Billbacks"
    />
  );
}

export { TenantBillbacksTable };
