import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";

import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { H3 } from "@ender/shared/ds/heading";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import type { SelectProps } from "@ender/shared/ui/select";
import { Select } from "@ender/shared/ui/select";

import styles from "./uploaded-by-filter.module.css";

type UploadedByFilterProps = {
  onChange: SelectProps<EnderId>["onChange"];
  value: SelectProps<EnderId>["value"];
};

function UploadedByFilter({ onChange, value }: UploadedByFilterProps) {
  const { userPM } = useContext(UserContext);

  const { data: users = [], isLoading } = useQuery({
    queryFn: () => UsersAPI.getUsersForPM({ pmId: userPM?.id }),
    queryKey: ["UsersAPI.getUsersForPM", userPM?.id],
  });

  const data =
    useMemo(
      () => users.map((user) => ({ label: user.name, value: user.id })),
      [users],
    ) || [];

  return (
    <div className={styles.uploadedByFilterContainer}>
      <H3>Uploaded by</H3>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Select
          data={data}
          onChange={onChange}
          searchable
          value={value}
          // 2023-10-12 Geoff & Kyle: We should not have to use zIndex here at all,
          // but invoice-file-preview is implemented with mantine Popover
          // which uses the same z-index 300 as this mantine Select component
          // which causes the invoice-file-preview's checkbox to render over this Select dropdown.
          // https://enderhub.slack.com/archives/C0287L77LHY/p1697142888220959
          zIndex={301}
        />
      )}
    </div>
  );
}

export { UploadedByFilter };
export type { UploadedByFilterProps };
