/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/listingTemplates
 * @function getListingTemplates
 * @memberof LeasingAPI
 * @param {LeasingAPIGetListingTemplatesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ListingTemplate[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ListingTemplate } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetListingTemplatesPathParams = {
  propertyId: EnderId;
};

type LeasingAPIGetListingTemplatesSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetListingTemplatesPayload =
  LeasingAPIGetListingTemplatesPathParams &
    LeasingAPIGetListingTemplatesSearchParams;

function getListingTemplatesUnsafeEffect(
  payload: LeasingAPIGetListingTemplatesPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ListingTemplate[]>({
        body,
        decode: unsafeDecodeJsonResponse<ListingTemplate[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/listingTemplates`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getListingTemplates(
  payload: LeasingAPIGetListingTemplatesPayload,
  options?: { signal?: AbortSignal },
): Promise<ListingTemplate[]> {
  return F.pipe(
    payload,
    getListingTemplatesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getListingTemplates };
export type {
  LeasingAPIGetListingTemplatesPathParams,
  LeasingAPIGetListingTemplatesPayload,
  LeasingAPIGetListingTemplatesSearchParams,
};
