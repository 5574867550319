import { IconTrash } from "@tabler/icons-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useContext, useState } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { LedgerContext } from "@ender/shared/contexts/ledger";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import styles from "./ledger-event-attachments.module.css";

function LedgerEventAttachments() {
  const [showDeleteIcon, setShowDeleteIcon] = useState<string>();

  const { selectedLedgerEvent } = useContext(LedgerContext);
  const queryClient = useQueryClient();

  // TODO: Don't throw an inline error.
  if (P.isNullable(selectedLedgerEvent)) {
    throw new Error("Ledger event is null");
  }

  const { data: attachments, isLoading } = useQuery({
    queryFn: () =>
      WebserverFilesAPI.getFiles({
        modelId: selectedLedgerEvent.id,
        // @ts-expect-error the types of upload eventType and ledgerEventType don't fully overlap
        modelType: selectedLedgerEvent.ledgerEventType,
      }),
    queryKey: ["WebserverFilesAPI.getFiles"],
    select: (res) => res.files,
  });

  function onThumbnailClick(s3Url: string) {
    window.open(s3Url);
  }

  const confirmation = useConfirmationContext();

  async function deleteAttachment(attachment: FilesClientEnderFile) {
    await confirmation({
      confirmButtonLabel: "Delete",
      content: `Are you sure you want to remove "${attachment?.filename}"?`,
      title: "Delete Attachment",
    });
    if (P.isNullable(selectedLedgerEvent) || P.isNullable(attachment)) {
      throw new Error("Ledger event or attachment to delete is null");
    }
    try {
      await WebserverFilesAPI.deleteFile({
        fileId: attachment.id,
        modelId: selectedLedgerEvent.id,
        // @ts-expect-error the types of upload eventType and ledgerEventType don't fully overlap
        modelType: selectedLedgerEvent.ledgerEventType,
      });
      showSuccessNotification({ message: "Document deleted." });
      await queryClient.invalidateQueries(["ledgerEventAttachments"]);
    } catch (err) {
      fail(err);
    }
  }

  if (!attachments?.length || isLoading) {
    return null;
  }

  return (
    <div className={styles.ledgerEventAttachments}>
      <div className={styles.ledgerEventAttachmentsTitle}>Attachments</div>
      <div className={styles.ledgerEventAttachmentsThumbnailList}>
        {attachments.map((attachment) => (
          <div
            key={attachment.s3Url}
            className={styles.ledgerEventAttachmentsCThumbnail}>
            <div
              key={attachment.id}
              className={styles.ledgerEventAttachmentsThumbnail}
              onMouseOver={() => setShowDeleteIcon(attachment.id)}
              onMouseLeave={() => setShowDeleteIcon(undefined)}
              onClick={() => onThumbnailClick(attachment.s3Url)}>
              <Tooltip label={attachment.filename}>
                <img src={attachment.s3Url} alt="" />
              </Tooltip>
              {showDeleteIcon === attachment.id && (
                <div className={styles.deleteAttachmentOverlay}>
                  <ActionIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteAttachment(attachment);
                    }}>
                    <IconTrash />
                  </ActionIcon>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export { LedgerEventAttachments };
