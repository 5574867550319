import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TubTubTypeValues = ["INSERT", "TILE_SURROUND"] as const;

const TubTubTypeEffectSchema = Schema.Literal(...TubTubTypeValues);

type TubTubType = Schema.Schema.Type<typeof TubTubTypeEffectSchema>;

const TubTubTypeEnum = castEnum(TubTubTypeEffectSchema);

function randomTubTubType(): TubTubType {
  return rand(TubTubTypeValues);
}

export {
  randomTubTubType,
  TubTubTypeEffectSchema,
  TubTubTypeEnum,
  TubTubTypeValues,
};
export type { TubTubType };
