"use client";

import { useEffect } from "react";

import { Modal } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { MultiFactorAuthenticationFormController } from "../form/multi-factor-authentication-form.controller";
import { useNeedsMfa } from "./use-needs-mfa";

type MultiFactorVerificationModalViewProps = {
  onClose?: () => void;
  onDone: () => void;
};

function MultiFactorVerificationModal(
  props: MultiFactorVerificationModalViewProps,
) {
  const { onClose, onDone } = props;
  const [opened, openedHandlers] = useBoolean();
  const needsMfa = useNeedsMfa();

  // We have to react to needsMfa/active to execute onSuccess
  useEffect(() => {
    if (needsMfa) {
      openedHandlers.setTrue();
      return;
    }
    onDone();
    openedHandlers.setFalse();
  }, [needsMfa, onDone, openedHandlers]);

  return (
    <Modal onClose={onClose} title="Two Factor" opened={opened}>
      <MultiFactorAuthenticationFormController onDone={onDone} />
    </Modal>
  );
}

export { MultiFactorVerificationModal };
