import {
  IconChevronLeft,
  IconChevronRight,
  IconSelector,
} from "@tabler/icons-react";
import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import type { CaptionProps } from "react-day-picker";

import { Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";

type CalendarHeaderProps = {
  labelText?: string;
  //The period of time that the calendar is displaying.
  //used for the accessibility label of the buttons
  period?: string;
  onLabelClick?: () => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
} & Pick<CaptionProps, "id">;

const CalendarHeader = (props: CalendarHeaderProps) => {
  const {
    id,
    labelText,
    period = "",
    onPrevClick = F.constVoid,
    onNextClick = F.constVoid,
    onLabelClick = F.constVoid,
    prevDisabled = false,
    nextDisabled = false,
  } = props;

  return (
    <Group spacing={Spacing.none}>
      <button
        onClick={onPrevClick}
        disabled={prevDisabled || P.isNullable(props.onPrevClick)}
        aria-label={`Previous ${period}`}
        className={clsx(
          "h-9 w-9 rounded flex justify-center items-center text-slate-600",
          "hover:bg-primary-50 active:bg-primary-100",
          "disabled:text-gray-200 disabled:pointer-events-none",
        )}>
        <IconChevronLeft size={16} />
      </button>
      <button
        onClick={onLabelClick}
        id={id}
        className={clsx("grow flex gap-2 rounded justify-center items-center", {
          "cursor-default": P.isNullable(props.onLabelClick),
          "hover:bg-primary-50 active:bg-primary-100": P.isNotNullable(
            props.onLabelClick,
          ),
        })}>
        {labelText}
        {P.isNotNullable(props.onLabelClick) && (
          <IconSelector size={16} className="text-slate-600" />
        )}
      </button>
      <button
        onClick={onNextClick}
        disabled={nextDisabled || P.isNullable(props.onNextClick)}
        aria-label={`Next ${period}`}
        className={clsx(
          "h-9 w-9 rounded flex justify-center items-center text-slate-600",
          "hover:bg-primary-50 active:bg-primary-100",
          "disabled:text-gray-200 disabled:pointer-events-none",
        )}>
        <IconChevronRight size={16} />
      </button>
    </Group>
  );
};

export { CalendarHeader };
export type { CalendarHeaderProps };
