import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetMoveOutTableRequestMoveOutSortKeyValues = [
  "ACTUAL_MOVE_OUT_DATE",
  "EXPECTED_MOVE_OUT_DATE",
] as const;

const GetMoveOutTableRequestMoveOutSortKeyEffectSchema = Schema.Literal(
  ...GetMoveOutTableRequestMoveOutSortKeyValues,
);

type GetMoveOutTableRequestMoveOutSortKey = Schema.Schema.Type<
  typeof GetMoveOutTableRequestMoveOutSortKeyEffectSchema
>;

const GetMoveOutTableRequestMoveOutSortKeyEnum = castEnum(
  GetMoveOutTableRequestMoveOutSortKeyEffectSchema,
);

function randomGetMoveOutTableRequestMoveOutSortKey(): GetMoveOutTableRequestMoveOutSortKey {
  return rand(GetMoveOutTableRequestMoveOutSortKeyValues);
}

export {
  GetMoveOutTableRequestMoveOutSortKeyEffectSchema,
  GetMoveOutTableRequestMoveOutSortKeyEnum,
  GetMoveOutTableRequestMoveOutSortKeyValues,
  randomGetMoveOutTableRequestMoveOutSortKey,
};
export type { GetMoveOutTableRequestMoveOutSortKey };
