import type { EnderId } from "@ender/shared/core";
import { ReportsAPI } from "@ender/shared/generated/ender.api.reports";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";

import {
  ASSIGNED_UNDERWRITINGS,
  AssignedFactorOrder,
} from "./assigned-underwriting-queue/assigned-underwriting-queue";
import { Factor } from "./underwriting-queue-table/filter-fields/filter-types";
import { DEFAULT_STATUSES } from "./underwriting-queue-table/underwriting-widget-helpers";
import { getWidget } from "./underwriting-queue-table/widget-helpers";

async function getAssignedPropertyIds(
  userId: string,
  signal?: AbortSignal,
): Promise<EnderId[]> {
  const widget = await getWidget(
    ASSIGNED_UNDERWRITINGS,
    {
      requiredFilters: [
        {
          factorName: Factor.STATUS,
          operator: DynamicTableOperatorsEnum.IN,
          values: [...DEFAULT_STATUSES],
        },
        {
          factorName: Factor.UNDERWRITER,
          operator: DynamicTableOperatorsEnum.IN,
          values: [userId],
        },
      ],
      requiredYFactors: {
        factorNames: AssignedFactorOrder,
        keepOrder: true,
      },
    },
    signal,
  );

  const sortedData = await ReportsAPI.getWidgetData({
    limit: 1000,
    offset: 0,
    widgetId: widget.id,
  });
  const dealIds = new Set<EnderId>(
    sortedData.root?.children.map(({ model }) => model.id),
  );

  return Array.from<EnderId>(dealIds);
}

export { getAssignedPropertyIds };
