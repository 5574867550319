/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/people/{applicationId}/otherIncome/delete
 * @function deleteOtherIncome
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIDeleteOtherIncomePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIDeleteOtherIncomePathParams = {
  applicationGroupId: EnderId;
  applicationId: EnderId;
};

type ApplicationsAPIDeleteOtherIncomeSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIDeleteOtherIncomeBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type ApplicationsAPIDeleteOtherIncomePayload =
  ApplicationsAPIDeleteOtherIncomePathParams &
    ApplicationsAPIDeleteOtherIncomeSearchParams &
    ApplicationsAPIDeleteOtherIncomeBodyParams;

function deleteOtherIncomeUnsafeEffect(
  payload: ApplicationsAPIDeleteOtherIncomePayload,
) {
  const { applicationGroupId, applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/people/${applicationId}/otherIncome/delete`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteOtherIncome(
  payload: ApplicationsAPIDeleteOtherIncomePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteOtherIncomeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteOtherIncome };
export type {
  ApplicationsAPIDeleteOtherIncomeBodyParams,
  ApplicationsAPIDeleteOtherIncomePathParams,
  ApplicationsAPIDeleteOtherIncomePayload,
  ApplicationsAPIDeleteOtherIncomeSearchParams,
};
