import {
  IconChevronLeft,
  IconCoin,
  IconHomeEdit,
  IconReceipt2,
  IconReport,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import { isEmptyArray } from "effect/Array";
import { useCallback, useContext, useMemo } from "react";

import { ApplicationGroupStatusBadge } from "@ender/entities/application-group-status-badge";
import type { ApplicationProgressIconProps } from "@ender/entities/application-progress-icon";
import { ApplicationProgressIcon } from "@ender/entities/application-progress-icon";
import { computeBulkApprovalStatus } from "@ender/entities/utils/application-utils";
import { UNDEFINED } from "@ender/shared/constants/general";
import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Badge } from "@ender/shared/ds/badge";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { ApplicationGroupApprovalResponseApplicationApprovalResponse } from "@ender/shared/generated/com.ender.middle.response";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  GetApplicationGroupResponseApplicationResponse,
  SearchApplicationsResponseApplicantResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import type {
  ApplicationGroup,
  ApplicationGroupApplicationStatus,
} from "@ender/shared/generated/ender.model.leasing";
import { ApplicationGroupApplicationStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";
import { ApplicationGroupActionsMenu } from "@ender/widgets/leasing/applications/application-group-actions-menu";
import type { ApplicationRightRailValue } from "@ender/widgets/utils/application-page-right-rail";

const ALL_APPLICATION_PAGE_URL =
  "/leasing-center/applications?tab=ALL_APPLICATIONS";

const approvalColorMap: Record<
  ApprovableApprovalStatus,
  ApplicationProgressIconProps["color"]
> = {
  [ApprovableApprovalStatusEnum.APPROVED]: "green",
  [ApprovableApprovalStatusEnum.REJECTED]: "red",
  [ApprovableApprovalStatusEnum.NEW]: "gray",
};

function getApplicationGroupName(
  applicants: SearchApplicationsResponseApplicantResponse[],
): string {
  const [first, ...rest] = applicants;
  return `${first.firstName} ${first.lastName}${isEmptyArray(rest) ? "" : ` +${rest.length}`}`;
}

type ApplicationPageHeaderProps = {
  applicationApprovals?: Record<
    EnderId,
    ApplicationGroupApprovalResponseApplicationApprovalResponse
  >;
  applicationGroup?: ApplicationGroup;
  /**
   * only provide the applications you want to consider for the application group status and progress indicators.
   */
  applications?: Pick<
    GetApplicationGroupResponseApplicationResponse,
    | "applicant"
    | "applicationScreened"
    | "applicationId"
    | "completedApplication"
    | "housingChoiceVoucherTimestamp"
  >[];
  approvalStatusByApplication?: Record<EnderId, ApprovableApprovalStatus>;
  incomeApprovalStatus?: ApprovableApprovalStatus;
  isEditable?: boolean;
  leaseId?: EnderId;
  needsReassignment?: boolean;
  refetchApplication?: () => void;
  setRightRail: (rightRail: ApplicationRightRailValue) => void;
};

function NeedsReassignment() {
  return (
    <Group align={Align.center} spacing={Spacing.xs}>
      <IconHomeEdit className="text-lg text-red-300" />
      <Text color="red-300" size="sm" weight="medium">
        Needs Reassignment
      </Text>
    </Group>
  );
}

function ApplicationPageHeader(props: ApplicationPageHeaderProps) {
  const {
    applicationApprovals,
    applicationGroup,
    applications = [],
    approvalStatusByApplication = {},
    incomeApprovalStatus,
    isEditable = false,
    leaseId,
    needsReassignment,
    refetchApplication = F.constVoid,
    setRightRail,
  } = props;

  const confirmation = useContext(ConfirmationContext);

  const reviewableStatuses: ApplicationGroupApplicationStatus[] = [
    ApplicationGroupApplicationStatusEnum.COMPLETE,
    ApplicationGroupApplicationStatusEnum.IN_PROGRESS,
  ];

  const canMarkAsUnderReview =
    P.isNotNullable(applicationGroup) &&
    reviewableStatuses.includes(applicationGroup.status);

  const { mutateAsync: markUnderReview } = useMutation({
    mutationFn: ApplicationsAPI.markUnderReview,
    mutationKey: ["ApplicationsAPI.markUnderReview"],
  });

  const onMarkAsUnderReviewClick = useCallback(async () => {
    if (!canMarkAsUnderReview) {
      return;
    }
    try {
      await confirmation({
        confirmButtonLabel: "Mark Under Review",
        content:
          "Are you sure you wish to mark this application as Under Review?",
        title: "Mark Application Under Review",
      });
      await markUnderReview({ applicationGroupId: applicationGroup.id });
      showSuccessNotification({ message: "Application marked Under Review" });
      refetchApplication();
    } catch (err) {
      fail(err);
    }
  }, [
    applicationGroup,
    confirmation,
    markUnderReview,
    canMarkAsUnderReview,
    refetchApplication,
  ]);

  const approvedCount = useMemo(
    () =>
      applications.reduce((acc, cur) => {
        if (
          approvalStatusByApplication[cur.applicationId] ===
          ApprovableApprovalStatusEnum.APPROVED
        ) {
          acc++;
        }
        return acc;
      }, 0),
    [applications, approvalStatusByApplication],
  );

  const reportApprovalStatus = useMemo(() => {
    return computeBulkApprovalStatus(
      applications.flatMap((application) => {
        const approval = applicationApprovals?.[application.applicationId];
        if (P.isNullable(approval)) {
          return [];
        }
        return [
          approval.creditReportApproval,
          approval.criminalReportApproval,
          approval.evictionReportApproval,
        ].filter(P.isNotNullable);
      }),
    );
  }, [applications, applicationApprovals]);

  /**
   * a single status that represents the overall status of all applications considered.
   * Used to determine whether the overall application group status is approved, rejected, or new.
   */
  const applicationsBulkStatus = useMemo(() => {
    if (
      applications.some(
        (app) =>
          approvalStatusByApplication[app.applicationId] ===
          ApprovableApprovalStatusEnum.REJECTED,
      )
    ) {
      return ApprovableApprovalStatusEnum.REJECTED;
    } else if (
      applications.every(
        (app) =>
          approvalStatusByApplication[app.applicationId] ===
          ApprovableApprovalStatusEnum.APPROVED,
      )
    ) {
      return ApprovableApprovalStatusEnum.APPROVED;
    } else {
      return ApprovableApprovalStatusEnum.NEW;
    }
  }, [applications, approvalStatusByApplication]);

  const PageTitle = useMemo(() => {
    if (isEmptyArray(applications)) {
      return "";
    }

    return getApplicationGroupName(
      applications.map((application) => application.applicant),
    );
  }, [applications]);

  const usingHousingChoiceVoucher = useMemo(() => {
    if (isEmptyArray(applications)) {
      return false;
    }
    return applications.some((application) =>
      P.isNotNullable(application.housingChoiceVoucherTimestamp),
    );
  }, [applications]);

  if (P.isNullable(applicationGroup)) {
    return null;
  }

  return (
    <Stack spacing={Spacing.sm}>
      <Group>
        <RouterLink href={ALL_APPLICATION_PAGE_URL}>
          <Button
            leftSection={<IconChevronLeft />}
            variant={ButtonVariant.transparent}>
            All Applications
          </Button>
        </RouterLink>
      </Group>
      <Group justify={Justify.between}>
        <Group align={Align.center}>
          <H1>{PageTitle}</H1>
          {needsReassignment && <NeedsReassignment />}
          <ApplicationGroupStatusBadge status={applicationGroup.status} />
          {P.isNotNullable(applicationGroup.archiveTime) && (
            <Badge color={Color.slate}>Archived</Badge>
          )}
          <Group align={Align.end}>
            {applicationGroup.status ===
            ApplicationGroupApplicationStatusEnum.IN_PROGRESS ? (
              <>
                {applications.map((application) => (
                  <Tooltip
                    label={`${application.applicant.firstName} ${application.applicant.lastName}`}
                    key={application.applicationId}>
                    <ApplicationProgressIcon
                      indicator={
                        application.completedApplication ? "check" : UNDEFINED
                      }
                      disabled={!application.completedApplication}>
                      <IconUser />
                    </ApplicationProgressIcon>
                  </Tooltip>
                ))}
              </>
            ) : (
              <>
                <Tooltip
                  label={`Occupants reviewed: ${approvedCount}/${applications.length}`}>
                  <ApplicationProgressIcon
                    color={approvalColorMap[applicationsBulkStatus]}
                    indicator={
                      applicationsBulkStatus ===
                      ApprovableApprovalStatusEnum.APPROVED
                        ? "check"
                        : UNDEFINED
                    }>
                    <IconUsers />
                  </ApplicationProgressIcon>
                </Tooltip>
                <Tooltip label="Income Review">
                  <ApplicationProgressIcon
                    color={
                      P.isNotNullable(incomeApprovalStatus)
                        ? approvalColorMap[incomeApprovalStatus]
                        : UNDEFINED
                    }
                    disabled={P.isNullable(incomeApprovalStatus)}
                    indicator={
                      incomeApprovalStatus ===
                      ApprovableApprovalStatusEnum.APPROVED
                        ? "check"
                        : UNDEFINED
                    }>
                    <IconReceipt2 />
                  </ApplicationProgressIcon>
                </Tooltip>
                <Tooltip label="Reports">
                  <ApplicationProgressIcon
                    color={
                      P.isNotNullable(reportApprovalStatus)
                        ? approvalColorMap[reportApprovalStatus]
                        : UNDEFINED
                    }
                    disabled={applications.some(
                      ({ applicationScreened }) => !applicationScreened,
                    )}>
                    <IconReport />
                  </ApplicationProgressIcon>
                </Tooltip>
              </>
            )}
            {usingHousingChoiceVoucher && (
              <Tooltip label="Housing Choice Voucher">
                <ApplicationProgressIcon>
                  <IconCoin />
                </ApplicationProgressIcon>
              </Tooltip>
            )}
          </Group>
        </Group>
        <Group>
          {canMarkAsUnderReview && (
            <Button onClick={onMarkAsUnderReviewClick}>
              Mark Application as Under Review
            </Button>
          )}
          <ApplicationGroupActionsMenu
            applicationGroup={applicationGroup}
            isEditable={isEditable}
            leaseId={leaseId}
            refetchApplication={refetchApplication}
            setRightRail={setRightRail}
          />
        </Group>
      </Group>
    </Stack>
  );
}

export { ApplicationPageHeader };
