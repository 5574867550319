import { cva } from "class-variance-authority";
import type { MouseEventHandler, PropsWithChildren } from "react";
import { forwardRef } from "react";

import type { Colors } from "@ender/shared/utils/theming";
import { Color } from "@ender/shared/utils/theming";

type ListButtonProps = {
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  color?: Extract<Colors, "red" | "green" | "primary" | "slate">;
};

const ListButtonVariantGenerator = cva(
  "w-full px-4 py-1.5 text-start disabled:text-gray-200 disabled:bg-transparent",
  {
    variants: {
      color: {
        [Color.primary]:
          "text-slate-900 hover:bg-primary-50 active:bg-primary-100",
        [Color.green]: "text-green-500 hover:bg-green-50 active:bg-green-100",
        [Color.red]: "text-red-500 hover:bg-red-50 active:bg-red-100",
        [Color.slate]: "text-slate-900 hover:bg-slate-50 active:bg-slate-100",
      },
    },
    defaultVariants: {
      color: Color.primary,
    },
  },
);

const ListButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ListButtonProps>
>(function ListButton(props, ref) {
  const { onClick, children, disabled } = props;
  return (
    <button
      className={ListButtonVariantGenerator(props)}
      onClick={onClick}
      tabIndex={-1}
      ref={ref}
      disabled={disabled}>
      {children}
    </button>
  );
});

export { ListButton };
export type { ListButtonProps };
