/**
 * Get a batch of checks by checkBatchId - returns a list of moneyTransfers
 * HTTP Method: GET
 * Pathname: /checkBatch/{checkBatchId}
 * @function getCheckBatch
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetCheckBatchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransferSerializerMoneyTransferResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { MoneyTransferSerializerMoneyTransferResponse } from "@ender/shared/generated/ender.arch.accounting";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetCheckBatchPathParams = {
  checkBatchId: EnderId;
};

type PaymentsAPIGetCheckBatchSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetCheckBatchPayload = PaymentsAPIGetCheckBatchPathParams &
  PaymentsAPIGetCheckBatchSearchParams;

function getCheckBatchUnsafeEffect(payload: PaymentsAPIGetCheckBatchPayload) {
  const { checkBatchId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransferSerializerMoneyTransferResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          MoneyTransferSerializerMoneyTransferResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/checkBatch/${checkBatchId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCheckBatch(
  payload: PaymentsAPIGetCheckBatchPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransferSerializerMoneyTransferResponse[]> {
  return F.pipe(
    payload,
    getCheckBatchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCheckBatch };
export type {
  PaymentsAPIGetCheckBatchPathParams,
  PaymentsAPIGetCheckBatchPayload,
  PaymentsAPIGetCheckBatchSearchParams,
};
