import type { KeywordSearch } from "@ender/entities/search-input";
import type { EnderId } from "@ender/shared/core";
import { SearchAPI } from "@ender/shared/generated/ender.api.misc";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { SearchServiceSearchTypeEnum } from "@ender/shared/generated/ender.service.search";

const searchManagers: (params: {
  excludeIds: EnderId[];
}) => KeywordSearch<EnderId> =
  ({ excludeIds }) =>
  async (keyword: string) => {
    const res = await SearchAPI.omnisearch({
      excludeIds,
      keyword,
      limit: 25,
      propertyIds: [],
      resultsOnEmpty: true,
      types: [SearchServiceSearchTypeEnum.MANAGER],
    });
    return res.map((user: User) => ({
      label: `${user.firstName} ${user.lastName}`,
      meta: user,
      value: user.id,
    }));
  };

export { searchManagers };
