/**
 * HTTP Method: GET
 * Pathname: /collections/lease/{leaseId}/actions
 * @function getLeaseActions
 * @memberof CollectionsAPI
 * @param {CollectionsAPIGetLeaseActionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsActionsLogResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { CollectionsActionsLogResponse } from "@ender/shared/generated/ender.api.finance.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIGetLeaseActionsPathParams = {
  leaseId: EnderId;
};

type CollectionsAPIGetLeaseActionsSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIGetLeaseActionsPayload =
  CollectionsAPIGetLeaseActionsPathParams &
    CollectionsAPIGetLeaseActionsSearchParams;

function getLeaseActionsUnsafeEffect(
  payload: CollectionsAPIGetLeaseActionsPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsActionsLogResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsActionsLogResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/collections/lease/${leaseId}/actions`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeaseActions(
  payload: CollectionsAPIGetLeaseActionsPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsActionsLogResponse[]> {
  return F.pipe(
    payload,
    getLeaseActionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeaseActions };
export type {
  CollectionsAPIGetLeaseActionsPathParams,
  CollectionsAPIGetLeaseActionsPayload,
  CollectionsAPIGetLeaseActionsSearchParams,
};
