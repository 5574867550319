import { clsx } from "clsx";
import { Array as A, Predicate as P } from "effect";
import type { HTMLProps } from "react";
import { useState } from "react";

import type { EmptyObject } from "@ender/shared/types/general";
import { EmptyTableRow } from "@ender/shared/ui/empty-table-row";

import { TableHeader } from "./table-header";
import { TableRow } from "./table-row";
import type { EnderTable } from "./table.types";
import { useIntersectionObserver } from "./use-intersection-observer";

import styles from "./table.v3.module.css";

function Table(props: HTMLProps<HTMLTableElement>) {
  const { className, ...tableProps } = props;
  return (
    <table className={clsx(styles.enderTable, className)} {...tableProps} />
  );
}

type EnderTableTanstackProps<RowData, Meta extends object> = {
  table: EnderTable<RowData, Meta>;
  className?: string;
  maxHeight?: string;
};

function EnderTableTanstack<RowData, Meta extends object = EmptyObject>(
  props: EnderTableTanstackProps<RowData, Meta>,
) {
  const { table, className, maxHeight } = props;
  const { title, isLoading, errorMessage, footer } = table.options.meta;
  const rows = table.getRowModel().rows;

  const [rootElement, setRootElement] = useState<Element | null>();
  const setObservedElement = useIntersectionObserver({
    onIntersect: table.options.meta.fetchNextPage,
    root: rootElement,
    rootMargin: "0px 0px 500px 0px",
  });

  const pageSize = table.getState().pagination?.pageSize;
  if (P.isNullable(pageSize)) {
    console.warn(
      "EnderTableTanstackV3 should have a pageSize defined for infinite scrolling to work.",
    );
  }

  return (
    <div
      className={styles.enderTableContainer}
      style={{ maxHeight }}
      ref={setRootElement}>
      <Table
        aria-label={`${title} Table`}
        className={clsx(styles.enderTable, className)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              aria-label={`Header Row ${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <TableHeader key={header.id} table={table} header={header} />
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {rows.map((row, i) => {
            const isLastRow = Math.max(rows.length - 1 - i, 0) === 0;
            const ref = isLastRow ? setObservedElement : undefined;
            return <TableRow key={row.id} row={row} rowNum={i} ref={ref} />;
          })}
          {isLoading && (
            <EmptyTableRow
              message="Loading..."
              colSpan={table.getVisibleLeafColumns().length}
            />
          )}
          {!isLoading && A.isEmptyArray(rows) && (
            <EmptyTableRow
              message={errorMessage}
              colSpan={table.getVisibleLeafColumns().length}
            />
          )}
        </tbody>
        {P.isNotNullable(footer) && (
          <tfoot>
            <tr aria-label="Footer">
              {footer.map(({ cell: Cell, colSpan = 1 }, i) => (
                <th key={i} colSpan={colSpan}>
                  <Cell table={table} />
                </th>
              ))}
            </tr>
          </tfoot>
        )}
      </Table>
    </div>
  );
}

export { EnderTableTanstack, Table };
