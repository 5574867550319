import {
  IconBath,
  IconBed,
  IconCurrencyDollar,
  IconDimensions,
} from "@tabler/icons-react";
import { String as S } from "effect";

import { Money$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import type {
  GetApplyInfoResponseListingResponse,
  GetApplyInfoResponseUnitResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import { EnderGroup } from "@ender/shared/ui/ender-group";
import { LogoImage } from "@ender/shared/ui/logo-image";
import { pluralize } from "@ender/shared/utils/string";

import styles from "./apply-header.module.css";

type ApplyWorkflowHeaderProps = {
  unit?: GetApplyInfoResponseUnitResponse;
  propertyName?: string;
  listing?: GetApplyInfoResponseListingResponse;
};

function ApplyWorkflowHeader({
  unit,
  propertyName,
  listing,
}: ApplyWorkflowHeaderProps) {
  const title = [propertyName ?? unit?.address.street, unit?.name]
    .filter((val) => S.isNonEmpty(val ?? ""))
    .join(", ");
  return (
    <EnderGroup className={styles.root} noWrap>
      <div className={styles.logo}>
        <LogoImage variant="LARGE" fit="scale-down" />
      </div>
      <LogoImage variant="SMALL" fit="scale-down" />
      <EnderGroup
        position="apart"
        align="center"
        spacing={0}
        className={styles.info}
        pos="relative">
        <Stack spacing={Spacing.none}>
          <H4>{title}</H4>
          <div className={styles.address}>
            <Text size={FontSize.md}>{unit?.address.fullDisplay}</Text>
          </div>
        </Stack>
        <EnderGroup align="center" className={styles.unitInfo}>
          <Group align="center" spacing="sm">
            <IconBed size={24} />{" "}
            {`${unit?.bedrooms ?? ""} ${pluralize("bed", unit?.bedrooms)}`}
          </Group>
          <Group align="center" spacing="sm">
            <IconBath size={24} /> {unit?.bathrooms ?? ""} bath
          </Group>
          <Group align="center" spacing="sm">
            <IconDimensions size={24} /> {unit?.sqft ?? ""} sqft
          </Group>
          {listing && (
            <Group align="center" spacing="sm">
              <IconCurrencyDollar size={24} />{" "}
              {Money$.ofOrNull(listing.advertisedRent)?.toFormatted()} / mo
            </Group>
          )}
        </EnderGroup>
      </EnderGroup>
    </EnderGroup>
  );
}

export { ApplyWorkflowHeader };
