import { Predicate as P } from "effect";
import { isEmptyArray } from "effect/Array";
import { isEmpty } from "effect/String";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type {
  GetApplicationGroupResponseApplicationResponse,
  GetApplyInfoResponseUnitResponse,
  GetRenewalsReportResponsePropertyResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationGroup } from "@ender/shared/generated/ender.model.leasing";
import { RightRail } from "@ender/shared/ui/right-rail";
import { AddEditPetForm } from "@ender/widgets/leasing/add-edit-pet";
import { ConnectedAddVehicleForm } from "@ender/widgets/leasing/add-vehicle-form";
import { TransferApplicantForm } from "@ender/widgets/leasing/applications/transfer-applicant-form";
import { CreateShowingForm } from "@ender/widgets/leasing/showings/create-showing-form";
import type {
  ApplicationPageRightRailType,
  ApplicationRightRailValue,
} from "@ender/widgets/utils/application-page-right-rail";
import {
  ApplicantFileTypeEnum,
  ApplicationPageRightRailEnum,
} from "@ender/widgets/utils/application-page-right-rail";

import { AddIncomeSourceForm } from "./add-income-source-form";
import { CreateUpdateApplicantForm } from "./create-update-applicant-form";
import type { DraftLeaseFormOnSuccessProps } from "./draft-lease-form";
import { DraftLeaseForm } from "./draft-lease-form";
import { EnderIFrame } from "./ender-iframe";
import { RenameFileForm } from "./rename-file-form";
import { UpdateApplicationPropertyUnitForm } from "./update-application-property-unit-form";
import { UpdateEmploymentForm } from "./update-employment-form";
import { UpdateMoveInForm } from "./update-move-in-form";
import { UpdateRentalHistoryForm } from "./update-rental-history-form";
import { UploadApplicantFiles } from "./upload-applicant-files";
import { UploadPetDocuments } from "./upload-pet-documents";

const rightRailTypeTitleMap = {
  [ApplicationPageRightRailEnum.ADD_APPLICANT]: "Add Applicant",
  [ApplicationPageRightRailEnum.ADD_PET]: "Add Pet",
  [ApplicationPageRightRailEnum.ADD_SHOWING]: "Add Showing",
  [ApplicationPageRightRailEnum.ADD_VEHICLE]: "Add Vehicle",
  [ApplicationPageRightRailEnum.CHANGE_PROPERTY_UNIT]: "Change Property/Unit",
  [ApplicationPageRightRailEnum.CLOSED]: "",
  [ApplicationPageRightRailEnum.EDIT_APPLICANT]: "Edit Applicant Info",
  [ApplicationPageRightRailEnum.EDIT_EMPLOYMENT]: "Edit Employment",
  [ApplicationPageRightRailEnum.EDIT_FILE]: "Edit File",
  [ApplicationPageRightRailEnum.EDIT_MOVE_IN]: "Edit Move-in Request Date",
  [ApplicationPageRightRailEnum.EDIT_PET]: "Edit Pet",
  [ApplicationPageRightRailEnum.EDIT_RENTAL_HISTORY]: "Edit Rental History",
  [ApplicationPageRightRailEnum.ADD_INCOME_SOURCE]: "Add Income Source",
  [ApplicationPageRightRailEnum.TRANSFER_APPLICANT]: "Transfer Applicant",
  [ApplicationPageRightRailEnum.UPLOAD_PET_DOCUMENTS]:
    "Upload Pet Documentation",
  [ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_ID]:
    "Upload Proof of Identification",
  [ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_INCOME]:
    "Upload Proof of Income",
  [ApplicationPageRightRailEnum.VIEW_APPLICANT_REPORT]: "View Screening Report",
} as const;

const isAddingOrEditingPet = (
  rightRail: ApplicationRightRailValue,
): rightRail is Extract<
  ApplicationRightRailValue,
  | { type: typeof ApplicationPageRightRailEnum.ADD_PET }
  | { type: typeof ApplicationPageRightRailEnum.EDIT_PET }
> => {
  return (
    [
      ApplicationPageRightRailEnum.ADD_PET,
      ApplicationPageRightRailEnum.EDIT_PET,
    ] as ApplicationPageRightRailType[]
  ).includes(rightRail.type);
};
const isAddingOccupant = (
  rightRail: ApplicationRightRailValue,
): rightRail is Extract<
  ApplicationRightRailValue,
  | { type: typeof ApplicationPageRightRailEnum.ADD_APPLICANT }
  | { type: typeof ApplicationPageRightRailEnum.EDIT_APPLICANT }
> => {
  return (
    [
      ApplicationPageRightRailEnum.ADD_APPLICANT,
      ApplicationPageRightRailEnum.EDIT_APPLICANT,
    ] as ApplicationPageRightRailType[]
  ).includes(rightRail.type);
};

function getRightRailProps(
  rightRail: ApplicationRightRailValue,
  applicationGroup: ApplicationGroup | undefined,
  property: GetRenewalsReportResponsePropertyResponse,
  unit: GetApplyInfoResponseUnitResponse,
) {
  if (!applicationGroup) {
    return { title: "" };
  }

  if (rightRail.type === ApplicationPageRightRailEnum.DRAFT_LEASE) {
    return {
      title: `Lease For ${property.name}${unit.name ? ` ${unit.name}` : ""}`,
      eyebrow: "Set Up Lease",
    };
  }

  if (rightRail.type === ApplicationPageRightRailEnum.UPLOAD_PET_DOCUMENTS) {
    return {
      title: `File Upload ${!P.isNullable(rightRail.petName) && !isEmpty(rightRail.petName) ? `- ${rightRail.petName}` : ""}`,
      eyebrow: "Upload Pet Documentation",
    };
  }

  if (rightRail.type === ApplicationPageRightRailEnum.VIEW_APPLICANT_REPORT) {
    return { title: rightRail.report.label, eyebrow: "View Screening Report" };
  }

  return { title: rightRailTypeTitleMap[rightRail.type] };
}

type ApplicationPageRightRailProps = {
  applicationGroup: ApplicationGroup;
  applications: GetApplicationGroupResponseApplicationResponse[];
  /**
   * TODO we might want to get rid of this and just fetch the property directly
   */
  property: GetRenewalsReportResponsePropertyResponse;
  /**
   * TODO we might want to get rid of this and just fetch the unit directly
   */
  unit: GetApplyInfoResponseUnitResponse;
  rightRail: ApplicationRightRailValue;
  setRightRail: (rightRail: ApplicationRightRailValue) => void;
  refetchApplication: () => void;
  isEditable: boolean;
  leaseId?: EnderId;
};

function ApplicationPageRightRail(props: ApplicationPageRightRailProps) {
  const {
    applicationGroup,
    applications,
    rightRail,
    setRightRail,
    refetchApplication,
    isEditable = false,
    property,
    unit,
    leaseId,
  } = props;

  const closeAndRefetch = useCallback(() => {
    refetchApplication();
    setRightRail({ type: ApplicationPageRightRailEnum.CLOSED });
  }, [refetchApplication, setRightRail]);

  const history = useHistory();

  const closeAndOpenLeasePage = useCallback(
    (leaseId?: EnderId) => {
      closeAndRefetch();

      if (P.isNotNullable(leaseId)) {
        history.push(`/leases/${leaseId}`);
      }
    },
    [closeAndRefetch, history],
  );

  const close = useCallback(() => {
    setRightRail({ type: ApplicationPageRightRailEnum.CLOSED });
  }, [setRightRail]);

  return (
    <RightRail
      opened={rightRail.type !== ApplicationPageRightRailEnum.CLOSED}
      onClose={() =>
        setRightRail({ type: ApplicationPageRightRailEnum.CLOSED })
      }
      {...getRightRailProps(rightRail, applicationGroup, property, unit)}>
      {isAddingOrEditingPet(rightRail) && (
        <AddEditPetForm
          onCancel={close}
          onSuccess={closeAndRefetch}
          ownerId={rightRail.ownerId}
          pet={rightRail.pet}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.ADD_SHOWING && (
        <CreateShowingForm
          applicants={applications.map((application) => application.applicant)}
          applicationGroup={applicationGroup}
          onCancel={close}
          onSuccess={closeAndRefetch}
          userId={
            isEmptyArray(applications)
              ? UNDEFINED
              : applications[0].applicant.userId
          }
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.ADD_VEHICLE && (
        <ConnectedAddVehicleForm
          onCancel={close}
          onSuccess={closeAndRefetch}
          ownerId={rightRail.ownerId}
        />
      )}
      {isAddingOccupant(rightRail) && (
        <CreateUpdateApplicantForm
          application={rightRail.application}
          applicationGroupId={applicationGroup.id}
          applications={applications}
          isEditable={isEditable}
          onCancel={close}
          onSuccess={closeAndRefetch}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.CHANGE_PROPERTY_UNIT && (
        <UpdateApplicationPropertyUnitForm
          applicationGroup={applicationGroup}
          onCancel={close}
          onSuccess={closeAndRefetch}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.EDIT_EMPLOYMENT && (
        <UpdateEmploymentForm
          application={rightRail.application}
          applicationGroupId={applicationGroup.id}
          onCancel={close}
          onSuccess={closeAndRefetch}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.EDIT_MOVE_IN && (
        <UpdateMoveInForm
          applicationGroup={applicationGroup}
          onCancel={close}
          onSuccess={closeAndRefetch}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.ADD_INCOME_SOURCE && (
        <AddIncomeSourceForm
          applicationGroupId={applicationGroup.id}
          applicationId={rightRail.applicationId}
          onCancel={close}
          onSuccess={closeAndRefetch}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.EDIT_RENTAL_HISTORY && (
        <UpdateRentalHistoryForm
          application={rightRail.application}
          applicationGroupId={applicationGroup.id}
          onCancel={close}
          onSuccess={closeAndRefetch}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.TRANSFER_APPLICANT && (
        <TransferApplicantForm
          applicationGroupId={applicationGroup.id}
          applicationId={rightRail.applicationId}
          applications={applications}
          onSuccess={closeAndRefetch}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.UPLOAD_PET_DOCUMENTS && (
        <UploadPetDocuments
          onSuccess={closeAndRefetch}
          petId={rightRail.petId}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_ID && (
        <UploadApplicantFiles
          applicationId={rightRail.applicationId}
          onSuccess={closeAndRefetch}
          type={ApplicantFileTypeEnum.PROOF_OF_ID}
        />
      )}
      {rightRail.type ===
        ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_INCOME && (
        <UploadApplicantFiles
          applicationId={rightRail.applicationId}
          onSuccess={closeAndRefetch}
          type={ApplicantFileTypeEnum.PROOF_OF_INCOME}
        />
      )}
      {rightRail.type ===
        ApplicationPageRightRailEnum.VIEW_APPLICANT_REPORT && (
        <EnderIFrame>{rightRail.report.html}</EnderIFrame>
      )}
      {rightRail.type === ApplicationPageRightRailEnum.DRAFT_LEASE && (
        <DraftLeaseForm
          applicationGroup={applicationGroup}
          leaseId={leaseId}
          onCancel={close}
          onSuccess={(props: DraftLeaseFormOnSuccessProps) => {
            props.openLeasePage
              ? closeAndOpenLeasePage(props.leaseId)
              : closeAndRefetch();
          }}
        />
      )}
      {rightRail.type === ApplicationPageRightRailEnum.EDIT_FILE && (
        <RenameFileForm
          applicationId={rightRail.applicationId}
          file={rightRail.file}
          onCancel={close}
          onSuccess={closeAndRefetch}
        />
      )}
    </RightRail>
  );
}

export { ApplicationPageRightRail };
