/**
 * HTTP Method: POST
 * Pathname: /collections/processes
 * @function createCollectionsProcess
 * @memberof CollectionsAPI
 * @param {CollectionsAPICreateCollectionsProcessPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SelectionCriteriaRequest } from "@ender/shared/generated/ender.api.finance.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPICreateCollectionsProcessSearchParams = {
  token?: string | undefined;
};

type CollectionsAPICreateCollectionsProcessBodyParams = {
  criteria: SelectionCriteriaRequest[];
  name: string;
};

type CollectionsAPICreateCollectionsProcessPayload =
  CollectionsAPICreateCollectionsProcessSearchParams &
    CollectionsAPICreateCollectionsProcessBodyParams;

function createCollectionsProcessUnsafeEffect(
  payload: CollectionsAPICreateCollectionsProcessPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/collections/processes",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createCollectionsProcess(
  payload: CollectionsAPICreateCollectionsProcessPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId> {
  return F.pipe(
    payload,
    createCollectionsProcessUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createCollectionsProcess };
export type {
  CollectionsAPICreateCollectionsProcessBodyParams,
  CollectionsAPICreateCollectionsProcessPayload,
  CollectionsAPICreateCollectionsProcessSearchParams,
};
