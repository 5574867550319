/**
 * HTTP Method: POST
 * Pathname: /leads/{leadId}/pets
 * @function addPetToLead
 * @memberof PetsAPI
 * @param {PetsAPIAddPetToLeadPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Pet>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIAddPetToLeadPathParams = {
  leadId: EnderId;
};

type PetsAPIAddPetToLeadSearchParams = {
  token?: string | undefined;
};

type PetsAPIAddPetToLeadBodyParams = {
  age: number;
  breed: string;
  isEmotionalSupportAnimal?: boolean | undefined;
  isNeutered: boolean;
  name: string;
  species: string;
  weightInPounds: number;
};

type PetsAPIAddPetToLeadPayload = PetsAPIAddPetToLeadPathParams &
  PetsAPIAddPetToLeadSearchParams &
  PetsAPIAddPetToLeadBodyParams;

function addPetToLeadUnsafeEffect(payload: PetsAPIAddPetToLeadPayload) {
  const { leadId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Pet>({
        body,
        decode: unsafeDecodeJsonResponse<Pet>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leads/${leadId}/pets`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function addPetToLead(
  payload: PetsAPIAddPetToLeadPayload,
  options?: { signal?: AbortSignal },
): Promise<Pet> {
  return F.pipe(
    payload,
    addPetToLeadUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addPetToLead };
export type {
  PetsAPIAddPetToLeadBodyParams,
  PetsAPIAddPetToLeadPathParams,
  PetsAPIAddPetToLeadPayload,
  PetsAPIAddPetToLeadSearchParams,
};
