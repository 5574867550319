/**
 * HTTP Method: POST
 * Pathname: /buy/assessorLookup
 * @function getAssessorData2
 * @memberof BuyAPI
 * @param {BuyAPIGetAssessorData2Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetAssessorDataResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetAssessorDataResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetAssessorData2SearchParams = {
  token?: string | undefined;
};

type BuyAPIGetAssessorData2BodyParams = {
  city: string;
  state: string;
  street: string;
  zipcode: string;
};

type BuyAPIGetAssessorData2Payload = BuyAPIGetAssessorData2SearchParams &
  BuyAPIGetAssessorData2BodyParams;

function getAssessorData2UnsafeEffect(payload: BuyAPIGetAssessorData2Payload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetAssessorDataResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetAssessorDataResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/buy/assessorLookup",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAssessorData2(
  payload: BuyAPIGetAssessorData2Payload,
  options?: { signal?: AbortSignal },
): Promise<GetAssessorDataResponse> {
  return F.pipe(
    payload,
    getAssessorData2UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAssessorData2 };
export type {
  BuyAPIGetAssessorData2BodyParams,
  BuyAPIGetAssessorData2Payload,
  BuyAPIGetAssessorData2SearchParams,
};
