import {
  IconFileTypeDoc,
  IconFileTypePdf,
  IconFileTypeXls,
  IconFileTypeZip,
  IconPhoto,
} from "@tabler/icons-react";
import { Predicate as P } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type { PropertyPropertyType } from "@ender/shared/generated/ender.model.core.property";
import { PropertyPropertyTypeEnum } from "@ender/shared/generated/ender.model.core.property";
import type { ObjectValues } from "@ender/shared/types/general";

const convertToCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

type FileTypeSchema = "IMAGE" | "PDF" | "EXCEL" | "WORD" | "ZIP";

const fileTypeExtensionMap: Record<string, FileTypeSchema> = {
  ai: "IMAGE",
  arw: "IMAGE",
  bmp: "IMAGE",
  cr2: "IMAGE",
  csv: "EXCEL",
  dib: "IMAGE",
  doc: "WORD",
  docm: "WORD",
  docx: "WORD",
  dot: "WORD",
  dotm: "WORD",
  dotx: "WORD",
  eps: "IMAGE",
  gif: "IMAGE",
  heic: "IMAGE",
  heif: "IMAGE",
  ind: "IMAGE",
  indd: "IMAGE",
  indt: "IMAGE",
  j2k: "IMAGE",
  jfi: "IMAGE",
  jfif: "IMAGE",
  jif: "IMAGE",
  jp2: "IMAGE",
  jpe: "IMAGE",
  jpeg: "IMAGE",
  jpf: "IMAGE",
  jpg: "IMAGE",
  jpm: "IMAGE",
  jpx: "IMAGE",
  k25: "IMAGE",
  lxs: "EXCEL",
  mj2: "IMAGE",
  nrw: "IMAGE",
  pdf: "PDF",
  png: "IMAGE",
  psd: "IMAGE",
  raw: "IMAGE",
  svg: "IMAGE",
  svgz: "IMAGE",
  tif: "IMAGE",
  tiff: "IMAGE",
  txt: "WORD",
  webp: "IMAGE",
  xla: "EXCEL",
  xlam: "EXCEL",
  xlr: "EXCEL",
  xls: "EXCEL",
  xlsb: "EXCEL",
  xslm: "EXCEL",
  xlt: "EXCEL",
  xltm: "EXCEL",
  xltx: "EXCEL",
  xlw: "EXCEL",
  xml: "EXCEL",
  zip: "ZIP",
  zipX: "ZIP",
} as const;

const filenameExtensionRegex = /\.([0-9a-z]+)(?:[?#]|$)/im;

function getFileTypeByFileName(
  fileName: string,
): ObjectValues<typeof fileTypeExtensionMap> | "" {
  /**
   * the [0] index is the full match, including the leading "."
   * the [1] index of the match response is the capture group with the file extension.
   */
  const extension = fileName.match(filenameExtensionRegex)?.[1].toLowerCase();

  return extension && P.isNotNullable(fileTypeExtensionMap[extension])
    ? fileTypeExtensionMap[extension]
    : "";
}

function FileIcon({ fileName }: { fileName: string }) {
  const fileType = getFileTypeByFileName(fileName);
  switch (fileType) {
    case "PDF":
      return <IconFileTypePdf />;
    case "WORD":
      return <IconFileTypeDoc />;
    case "EXCEL":
      return <IconFileTypeXls />;
    case "ZIP":
      return <IconFileTypeZip />;
    case "IMAGE":
    default:
      return <IconPhoto />;
  }
}

type FileBase = {
  filename: string;
  iconUrl?: string;
  id: EnderId;
  name: string;
  path: string;
  s3Url: string;
  timestamp: string;
};

/**
 * @description gives a readable unit string based on the property type
 */
function getUnitTypeByPropertyType(
  propertyType: PropertyPropertyType | Undefined,
) {
  if (propertyType == PropertyPropertyTypeEnum.COMMERCIAL) {
    return "Suite";
  } else if (propertyType == PropertyPropertyTypeEnum.RESIDENTIAL) {
    return "Unit";
  } else if (propertyType == PropertyPropertyTypeEnum.STORAGE) {
    return "Storage Unit";
  }

  return "";
}

export {
  FileIcon,
  convertToCurrency,
  getFileTypeByFileName,
  getUnitTypeByPropertyType,
};
export type { FileBase, FileTypeSchema };
