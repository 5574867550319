/**
 * HTTP Method: DELETE
 * Pathname: /collections/steps/{stepId}
 * @function deleteStep
 * @memberof CollectionsAPI
 * @param {CollectionsAPIDeleteStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIDeleteStepPathParams = {
  stepId: EnderId;
};

type CollectionsAPIDeleteStepSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIDeleteStepPayload = CollectionsAPIDeleteStepPathParams &
  CollectionsAPIDeleteStepSearchParams;

function deleteStepUnsafeEffect(payload: CollectionsAPIDeleteStepPayload) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/collections/steps/${stepId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteStep(
  payload: CollectionsAPIDeleteStepPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteStep };
export type {
  CollectionsAPIDeleteStepPathParams,
  CollectionsAPIDeleteStepPayload,
  CollectionsAPIDeleteStepSearchParams,
};
