import { zodResolver } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { useMantineTheme } from "@mantine/styles";
import {
  IconBath,
  IconBed,
  IconCurrencyDollar,
  IconDimensions,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { clsx } from "clsx";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  String as S,
} from "effect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { GoogleMap } from "@ender/entities/google-map";
import { NULL } from "@ender/shared/constants/general";
import { EnderIdSchema } from "@ender/shared/core";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Button } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { PhoneInput } from "@ender/shared/ds/phone-input";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { useForm } from "@ender/shared/forms/hooks/general";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { CreateApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { CreateApplicationResponseCreateApplicationStatusEnum } from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationApplicantType } from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationApplicantTypeEnum,
  ApplicationIdentityVerificationResultEnum,
} from "@ender/shared/generated/ender.model.leasing";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import { useResizeObserver } from "@ender/shared/hooks/use-resize-observer";
import { PhoneSchema } from "@ender/shared/types/ender-general";
import { EnderDatePicker } from "@ender/shared/ui/ender-date-picker";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { EnderDate, EnderDateSchema } from "@ender/shared/utils/ender-date";
import { showWarningNotification } from "@ender/shared/utils/notifications";
import { formatPhoneNumber } from "@ender/shared/utils/phone";
import { pluralize } from "@ender/shared/utils/string";

import { TosForm } from "../modals/tos-form";
import type { ApplyStepProps } from "./step-props";

import commonStyles from "./common.module.css";
import styles from "./personal.module.css";
import { Color } from "@ender/shared/utils/theming";

const awaitingEmail = [
  `We sent a confirmation email to the email address you provided.\nTo continue with the application, please follow the link in the email received from `,
  `. You may need to check your spam folder.`,
] as const;
const awaitingSms = `We sent a confirmation text to the phone number you provided.\nTo continue with the application, please follow the link provided in the text. If you wish to continue the application on this page, refresh the page after you have clicked the link.\nIf you did not receive a text, please contact the property manager to add an email for the existing user or use a different phone number with this applicant.`;
const awaitingMFA = [
  `We sent a one-time code to `,
  `. Please enter the code below to continue.`,
] as const;

const responsibleApplicantTypes: readonly ApplicationApplicantType[] = [
  ApplicationApplicantTypeEnum.GUARANTOR,
  ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
] as const;

// should satisfy Omit<ApplicationsAPICreateOrFindExistingUserForApplicationPayload, "invoker">
const PersonalFormSchema = z.object({
  birthday: EnderDateSchema.nullable()
    .refine(P.isNotNullable, "Date of Birth is required")
    .refine(
      (val) => P.isNullable(val) || val.isOnOrBeforeToday(),
      "Date of Birth must not be in the future",
    ),
  /**
   * MFA code
   */
  code: z.string().optional(),
  email: z.string().min(1, "Email is required").email().or(z.literal("")),
  firstName: z.string().min(1, "First Name is required"),
  isActiveMilitary: z.boolean().optional(),
  lastName: z.string().min(1, "Last Name is required").optional(),
  middleName: z.string().optional(),
  /**
   * not only do we want to require this field (with user-friendly messages)
   * we want to enforce that it is in the future, also with friendly messages
   */
  moveInDate: EnderDateSchema.nullable()
    .superRefine((date, ctx) => {
      if (P.isNullable(date)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          fatal: true,
          message: "Move-in Date is required",
        });
        return z.NEVER;
      }
      return date;
    })
    .refine(
      (date) => EnderDate.isDateInFuture(date),
      "Move-in Date must occur in the future",
    ),
  phone: PhoneSchema,
  unitId: EnderIdSchema.optional(),
  userId: EnderIdSchema.optional(),
});

type PersonalFormInput = z.input<typeof PersonalFormSchema>;
type PersonalFormOutput = z.output<typeof PersonalFormSchema>;

function ApplyStepPersonal({
  unit,
  user,
  listing,
  progress,
  titleRef,
  onRequestNext = F.constVoid,
  hasRecentSubmittedApplications = false,
  applicationGroup,
  applications,
  pm,
}: ApplyStepProps) {
  const [awaitingVerification, setAwaitingVerification] =
    useState<CreateApplicationResponse>();
  const application = applications?.find(
    ({ applicant }) => applicant.userId === user?.userId,
  );

  const applicationsWithoutApplicant =
    applications?.filter((a) => a.applicant?.userId !== user?.userId) ?? [];
  const responsibleOtherApplicants = applicationsWithoutApplicant.filter((a) =>
    responsibleApplicantTypes.includes(a.applicantType),
  );

  const canEditForm =
    P.isNullable(application?.identityVerificationResult) ||
    application?.identityVerificationResult ===
      ApplicationIdentityVerificationResultEnum.FAILED;

  const form = useForm<PersonalFormInput>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      unitId: unit?.unitId,
      //appGroup.applicationId corresponds to the currently active application, and the appgroup itself is populated with fields
      //from that application. An application is associated with a prospective tenant.
      ...(application?.applicant ?? user),
      birthday: P.isNotNullable(application?.applicant.birthday)
        ? EnderDate.of(application.applicant.birthday)
        : NULL,
      moveInDate: P.isNotNullable(applicationGroup?.moveInDate)
        ? EnderDate.of(applicationGroup.moveInDate)
        : NULL,
    },
    validate: zodResolver(
      PersonalFormSchema.extend({
        birthday: PersonalFormSchema.shape.birthday.refine(
          //if no birthday has been entered it should fall back on the validation message from the base schema
          (val) => P.isNullable(val) || !EnderDate.isBirthdayMinor(val),
          "You must be 18 years or older to apply",
        ),
        email: PersonalFormSchema.shape.email.refine((val) => {
          return (
            S.isNonEmpty(val ?? "") ||
            (A.isNonEmptyArray(responsibleOtherApplicants) &&
              responsibleOtherApplicants.some((a) =>
                S.isNonEmpty(a.applicant.email ?? ""),
              ))
          );
        }, "Required"),
      }),
    ),
  });

  const [tosPromise, setTosPromise] = useState<{
    resolve: () => void;
    reject: () => void;
  }>();
  const tos = useCallback(async () => {
    return new Promise<void>((resolve, reject) => {
      /**
       * wraps a function. Forwards arguments to that function, but additionally
       * clears the tosPromise
       */
      const andClose =
        <T extends unknown[] = []>(fn: (...args: T) => void) =>
        (...args: T) => {
          setTosPromise(undefined);
          return fn(...args);
        };
      setTosPromise({
        reject: andClose(() =>
          reject("To continue, please agree to the Terms of Service"),
        ),
        resolve: andClose(resolve),
      });
    });
  }, []);

  const { mutateAsync: createApplication, isLoading: creatingApplication } =
    useMutation({
      mutationFn: ApplicationsAPI.createOrFindExistingUserForApplication,
      mutationKey: [
        "ApplicationsAPI.createOrFindExistingUserForApplication",
      ] as const,
    });
  const { mutateAsync: updateApplication, isLoading: updatingApplication } =
    useMutation({
      mutationFn: ApplicationsAPI.updateApplication,
      mutationKey: ["ApplicationsAPI.updateApplication"] as const,
    });

  const handleSubmit = useCallback(
    async (values: PersonalFormOutput) => {
      const { moveInDate, ...createAppProps } = values;
      /**
       * if the applicant user has not agreed to the terms, show the tos modal
       * if the form is being resubmitted for MFA, the user has already agreed to the terms
       */
      if (
        !application?.applicant?.agreedToTerms &&
        P.isNullable(createAppProps.code)
      ) {
        await tos();
      }

      /**
       * skip creating or editing the User if the user is not allowed to edit the form
       */
      if (canEditForm) {
        //@ts-expect-error EnderDate serializes to LocalDate
        const response = await createApplication({
          ...createAppProps,
          //if tos has succeeded, the user has agreed to the terms
          agreeToTerms: true,
        });

        if (
          response.status !==
          CreateApplicationResponseCreateApplicationStatusEnum.SUCCESS
        ) {
          setAwaitingVerification(response);
          return;
        }

        if (P.isNotNullable(response.applicationGroupId)) {
          await updateApplication({
            applicationGroupId: response.applicationGroupId,
            //@ts-expect-error EnderDate serializes to LocalDate
            moveInDate,
          });
        }
      } else {
        /**
         * this isDefined is here to make the linter happy.
         * We know that if you _can't_ edit the form, you must have progressed
         * far enough to get an IDV result, which means `applicationGroup` must be defined.
         */
        if (P.isNotNullable(applicationGroup)) {
          await updateApplication({
            applicationGroupId: applicationGroup.id,
            //@ts-expect-error EnderDate serializes to LocalDate
            moveInDate,
          });
        }
      }
      onRequestNext();
    },
    [
      tos,
      createApplication,
      updateApplication,
      application?.applicant.agreedToTerms,
      onRequestNext,
      canEditForm,
      applicationGroup,
    ],
  );

  const [paragraphBox, setParagraphBox] = useState({ height: 0, width: 0 });

  const handleResize = useCallback((entry: ResizeObserverEntry) => {
    const { height, width } = entry.contentRect;
    setParagraphBox({ height, width });
  }, []);

  const title = [unit?.address.street, unit?.name]
    .filter(P.isNotNullable)
    .join(", ");

  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

  const [descriptionLines, setDescriptionLines] =
    useState<{ x: number; y: number; width: number; height: number }[]>();
  const description = useResizeObserver<HTMLParagraphElement>(handleResize);

  /**
   * a complex calculation that finds the end of the third line of text
   * and positions a 'read more' button there.
   */
  const reactToSize = useDebounce(function (el: HTMLElement) {
    const text = el?.firstChild;
    if (P.isNullable(text) || text.nodeType !== 3) {
      return;
    }

    const range = document.createRange();
    range.setStart(text, 0);
    range.setEnd(text, text.textContent?.length ?? 0);

    const { left, top } = el.getBoundingClientRect();
    const bboxes = Array.from(range.getClientRects());
    setDescriptionLines(
      bboxes
        .filter((bbox) => bbox.x === bboxes[0].x)
        .map((rect) => {
          return {
            height: rect.height,
            width: rect.width,
            x: rect.x - left,
            y: rect.y - top,
          };
        }),
    );
  }, 100);

  // lazy useEffect
  useEffect(() => {
    if (P.isNotNullable(description.current)) {
      reactToSize(description.current);
    }
  }, [paragraphBox.width, paragraphBox.height, reactToSize, description]);

  //default to not showing full listing when in mobile view
  const [showFullListing, setShowFullListing] = useState<boolean>(false);

  const pmShorthand = useMemo(() => {
    if (pm?.name.match(/gold/gi)) {
      return "GPM";
    }

    if (pm?.name.match(/master/gi)) {
      return "MP";
    }

    if (pm?.name.match(/ever/gi)) {
      return "EG";
    }
  }, [pm]);

  // lazy useEffect
  useEffect(() => {
    if (
      pm?.notifyLeadOfSubmittedApplication &&
      !application?.applicant?.agreedToTerms &&
      hasRecentSubmittedApplications
    ) {
      showWarningNotification({
        autoClose: false,
        message: (
          <Text>
            This home already has a submitted application. Please see the{" "}
            <a href={pm?.websiteUrl}>{`${pm?.name} website`}</a> to see a list
            of all available homes.
          </Text>
        ),
        title: "Warning",
      });
    }
  }, [
    application?.applicant?.agreedToTerms,
    hasRecentSubmittedApplications,
    pm,
  ]);

  if (
    P.isNotNullable(awaitingVerification) &&
    awaitingVerification.status !==
      CreateApplicationResponseCreateApplicationStatusEnum.SUCCESS
  ) {
    return (
      <div className={commonStyles.stepContainer}>
        <div className={commonStyles.indented}>
          <H1>Verify Your Information</H1>
          <div className={commonStyles.stepDescription} style={{ maxWidth: "none" }}>
            <Text>
              {awaitingVerification.status ===
                CreateApplicationResponseCreateApplicationStatusEnum.SENT_EMAIL && (
                <>
                  {awaitingEmail[0]}
                  <b>support@ender.com</b>
                  {awaitingEmail[1]}
                </>
              )}
              {awaitingVerification.status ===
                CreateApplicationResponseCreateApplicationStatusEnum.SENT_SMS &&
                awaitingSms}
            </Text>
          </div>
          {awaitingVerification.status ===
            CreateApplicationResponseCreateApplicationStatusEnum.SENT_SMS_VERIFICATION && (
            <form
              // @ts-expect-error handleSubmit expects FormOutput, but form is typed with FormInput.
              onSubmit={form.onSubmit(handleSubmit)}
              className={commonStyles.spanFullWidth}>
              <Stack>
                <Text size={FontSize.inherit}>
                  {awaitingMFA[0]}
                  <b>{formatPhoneNumber(form.values.phone)}</b>
                  {awaitingMFA[1]}
                </Text>
                <Group>
                  <TextInput {...form.getInputProps("code")} />
                </Group>
                <div
                  className={commonStyles.submitButton}
                  style={{ display: "grid" }}>
                  <Button type="submit">Submit</Button>
                </div>
              </Stack>
            </form>
          )}
        </div>
      </div>
    );
  }

  const minMoveInDate = EnderDate.tomorrow;
  const maxMoveInDate = pm?.name.match(/ever/gi)
    ? EnderDate.getDaysInFuture(16)
    : undefined;

  return (
    <div className={commonStyles.stepContainer}>
      <div className={styles.listingPanel}>
        <Stack>
          <div className={styles.mapGrid}>
            <Card padding="none">
              <GoogleMap
                location={O.all({
                  lat: O.fromNullable(unit?.address.latitude),
                  lng: O.fromNullable(unit?.address.longitude),
                })}
              />
            </Card>
            {hasRecentSubmittedApplications && (
              <div className={styles.hotBadge}>
                <Tooltip label="This property has received an application recently">
                  <Badge color={BadgeColor.red}>HOT</Badge>
                </Tooltip>
              </div>
            )}
            <Card padding="none">
              <img
                style={{ objectFit: "cover" }}
                src={listing?.photoUrls?.[0]}
                alt="listing"
              />
            </Card>
          </div>
          <Stack>
            <H1>{title}</H1>
            <Group align={Align.center}>
              <Group align={Align.center} spacing={Spacing.xs}>
                <IconBed size={24} />{" "}
                {`${unit?.bedrooms ?? ""} ${pluralize("bed", unit?.bedrooms)}`}
              </Group>
              <Group align={Align.center} spacing={Spacing.xs}>
                <IconBath size={24} /> {unit?.bathrooms ?? ""} bath
              </Group>
              <Group align={Align.center} spacing={Spacing.xs}>
                <IconDimensions size={24} /> {unit?.sqft ?? ""} sqft
              </Group>
              {listing && (
                <Group align={Align.center} spacing={Spacing.xs}>
                  <IconCurrencyDollar size={24} /> {listing.advertisedRent} / mo
                </Group>
              )}
            </Group>
            {listing && (
              <div
                style={{
                  height: isDesktop || showFullListing ? "auto" : 54,
                  overflow: "hidden",
                  position: "relative",
                }}>
                <div className={styles.listingDescription} ref={description}>
                  <Text size={FontSize.md}>
                    {listing?.marketingBody}
                  </Text>
                </div>
                {!isDesktop && (
                  <span
                    className={styles.readMore}
                    style={{
                      left: showFullListing
                        ? 0
                        : (descriptionLines?.[2]?.width || -4) + 4,
                    }}>
                    <EnderLink
                      onClick={() => setShowFullListing((prev) => !prev)}
                      className={styles.readMoreLabel}>
                      {showFullListing ? "Read Less" : "Read More"}
                    </EnderLink>
                  </span>
                )}
              </div>
            )}
          </Stack>
        </Stack>
      </div>
      <form
        // @ts-expect-error handleSubmit expects FormOutput, but form is typed with FormInput.
        onSubmit={form.onSubmit(handleSubmit)}
        style={{ gridColumn: "span 5/-1" }}>
        <Stack>
          <div
            className={clsx(
              commonStyles.spanFullWidth,
              commonStyles.stepHeader,
            )}
            ref={titleRef}>
            <H1>Personal Information</H1>
            {progress}
          </div>
          <div className={commonStyles.spanFullWidth}>
            <Text weight={FontWeight.medium}>
              Only one person per household should fill out this application.
              Additional occupants will receive an email to fill out their
              information.
              {!canEditForm && (
                <Text color={`${Color.red}-500`} weight={FontWeight.medium}>
                  <br />
                  You cannot change your personal information after your identity
                  has been verified.
                </Text>
              )}
            </Text>
          </div>
          <TextInput
            label="First Name"
            autoComplete="given-name"
            {...form.getInputProps("firstName")}
            disabled={!canEditForm}
          />
          <TextInput
            label="Last Name"
            autoComplete="family-name"
            {...form.getInputProps("lastName")}
            disabled={!canEditForm}
          />
          <EnderDatePicker
            label="Date of Birth"
            autoComplete="bday"
            {...form.getInputProps("birthday")}
            disabled={!canEditForm}
          />
          <TextInput
            inputMode="email"
            label="Email Address"
            autoComplete="email"
            {...form.getInputProps("email")}
            disabled={!canEditForm}
          />
          <PhoneInput
            label="Phone Number"
            autoComplete="tel-national"
            {...form.getInputProps("phone")}
            disabled={!canEditForm}
          />
          {/* temporarily hide the select for home/mobile phone */}
          {/* <EnderSelect data={["Home", "Mobile"]} label="Phone Type" style={{ gridColumn: "span 2" }} /> */}
          <EnderDatePicker
            label="What date would you like to move in?"
            initialMonth={
              (form.values.moveInDate &&
                EnderDate.of(form.values.moveInDate)) ||
              minMoveInDate
            }
            minDate={minMoveInDate}
            maxDate={maxMoveInDate}
            {...form.getInputProps("moveInDate")}
          />
          <Button
            type="submit"
            loading={creatingApplication || updatingApplication}>
            Save & Continue
          </Button>
        </Stack>
      </form>
      <Modal
        opened={P.isNotNullable(tosPromise)}
        fullscreen={!isDesktop}
        title="Terms of Service"
        onClose={() => {
          tosPromise?.reject();
        }}>
        <TosForm
          pm={pmShorthand}
          terms={pm?.applicationTerms}
          onReject={() => tosPromise?.reject()}
          onAccept={() => tosPromise?.resolve()}
          isDesktop={isDesktop}
        />
      </Modal>
    </div>
  );
}

export { ApplyStepPersonal };
