/**
 * Creates a new bank account funding source in Dwolla and a corresponding object in the Ender database. The bank
 account will be created in status NEW. This API initiates microdeposits for the newly-created bank account.
 * HTTP Method: POST
 * Pathname: /linkBankWithMicrodeposits
 * @function linkBankWithMicrodeposits
 * @memberof DwollaAPI
 * @param {DwollaAPILinkBankWithMicrodepositsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPILinkBankWithMicrodepositsSearchParams = {
  token?: string | undefined;
};

type DwollaAPILinkBankWithMicrodepositsBodyParams = {
  accountName: string;
  accountNumber: string;
  customerId: EnderId;
  customerType: ModelType;
  routingNumber: string;
};

type DwollaAPILinkBankWithMicrodepositsPayload =
  DwollaAPILinkBankWithMicrodepositsSearchParams &
    DwollaAPILinkBankWithMicrodepositsBodyParams;

function linkBankWithMicrodepositsUnsafeEffect(
  payload: DwollaAPILinkBankWithMicrodepositsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/linkBankWithMicrodeposits",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function linkBankWithMicrodeposits(
  payload: DwollaAPILinkBankWithMicrodepositsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    linkBankWithMicrodepositsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { linkBankWithMicrodeposits };
export type {
  DwollaAPILinkBankWithMicrodepositsBodyParams,
  DwollaAPILinkBankWithMicrodepositsPayload,
  DwollaAPILinkBankWithMicrodepositsSearchParams,
};
