import { IconDots } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import * as S from "effect/String";
import type { ElementRef, MouseEvent } from "react";
import { forwardRef, useCallback, useContext } from "react";

import { isBirthdayMinor } from "@ender/entities/utils/application-utils";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  GetApplicationGroupResponse,
  GetApplicationGroupResponseApplicationResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import { ApplicationApplicantTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import { useClipboard } from "@ender/shared/hooks/use-clipboard";
import { fail } from "@ender/shared/utils/error";
import { capitalize } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

function getTotalResponsibleOccupants(
  applications: GetApplicationGroupResponseApplicationResponse[],
): number {
  return applications.reduce(
    (total, p) =>
      p.applicantType === ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT
        ? total + 1
        : total,
    0,
  );
}

type ApplicantActionsMenuProps = {
  applicationGroupId: EnderId;
  application: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "applicationUrl" | "applicationId" | "applicantType"
  > & {
    applicant: Pick<
      GetApplicationGroupResponseApplicationResponse["applicant"],
      "agreedToTerms" | "userId" | "firstName" | "lastName" | "birthday"
    >;
  };
  /**
   * necessary because some actions for a single applicant are dependent on the entire group of applications.
   * For example, removing an applicant from the group is not possible if they are the only applicant or the last responsible applicant.
   */
  applications: GetApplicationGroupResponseApplicationResponse[];
  onSuccess?: () => void;
  onRequestTransfer?: () => void;
};

const ApplicantActionsMenu = forwardRef<
  ElementRef<typeof MenuContent>,
  ApplicantActionsMenuProps
>(function ApplicantActionsMenu(props, ref) {
  const {
    application,
    applicationGroupId,
    onSuccess = F.constVoid,
    applications,
    onRequestTransfer = F.constVoid,
  } = props;
  const { applicationUrl, applicant, applicationId, applicantType } =
    application;
  const queryClient = useQueryClient();
  const confirmation = useConfirmationContext();

  /**
   * applications not including the current application whose menu is open
   */
  const otherApplications = applications.filter(
    (a) => a.applicationId !== applicationId,
  );

  const { userPM } = useContext(UserContext);
  const hasTerms = S.isNonEmpty(userPM.applicationTerms);
  const isMinor = isBirthdayMinor(LocalDate$.parse(applicant.birthday));
  const removeApplicantButtonLabel = !isMinor
    ? `Remove ${capitalize(applicantType)}`
    : "Remove Minor";

  const clipboard = useClipboard();

  function copyToClipboard(e: MouseEvent) {
    e.stopPropagation();
    clipboard.copy(applicationUrl);
  }

  const { mutateAsync: removeApplicant, isLoading: removingApplicant } =
    useMutation({
      mutationKey: ["ApplicationsAPI.removePersonFromApplication"] as const,
      mutationFn: ApplicationsAPI.removePersonFromApplication,
      onMutate: async ({ applicationId }) => {
        await queryClient.cancelQueries({
          queryKey: ["ApplicationsAPI.getApplication", applicationGroupId],
        });
        // Snapshot the previous value
        const previousApplicationGroup =
          queryClient.getQueryData<GetApplicationGroupResponse>([
            "ApplicationsAPI.getApplication",
            applicationGroupId,
          ]);
        // @ts-expect-error the above query can return undefined, but that's fine for this behavior
        const newApplicationGroup: GetApplicationGroupResponse = {
          ...previousApplicationGroup,
          applications:
            previousApplicationGroup?.applications.filter(
              (app) => app.applicationId !== applicationId,
            ) ?? [],
        };
        // Optimistically update to the new value
        queryClient.setQueryData(
          ["ApplicationsAPI.getApplication", applicationGroupId],
          () => newApplicationGroup,
        );
        // Return a context object with the snapshotted value
        return { previousApplicationGroup };
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, _, context) => {
        fail(err);
        queryClient.setQueryData(
          ["ApplicationsAPI.getApplication", applicationGroupId],
          context?.previousApplicationGroup,
        );
      },
    });
  const handleRemoveApplicant = useCallback(async () => {
    await confirmation({
      title: `Remove ${capitalize(applicantType)}`,
      content: `${applicant.firstName} ${applicant.lastName} will be removed from the application. Are you sure?`,
    });
    await removeApplicant({
      appGroupId: applicationGroupId,
      applicationId,
    });
    onSuccess();
  }, [
    applicationId,
    applicationGroupId,
    removeApplicant,
    onSuccess,
    confirmation,
    applicant,
    applicantType,
  ]);

  return (
    <Menu>
      <MenuTrigger>
        <ActionIcon
          variant={ButtonVariant.transparent}
          loading={removingApplicant}>
          <IconDots />
        </ActionIcon>
      </MenuTrigger>
      <MenuContent ref={ref}>
        {!isMinor && P.isNotNullable(applicationUrl) && (
          <>
            <MenuButton onClick={copyToClipboard} preventClose>
              {clipboard.copied ? "Copied!" : "Copy Application Link"}
            </MenuButton>
            <Divider />
          </>
        )}
        {(applicantType !== ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT ||
          getTotalResponsibleOccupants(otherApplications) >= 1) && (
          <MenuButton color={Color.red} onClick={handleRemoveApplicant}>
            {removeApplicantButtonLabel}
          </MenuButton>
        )}
        {(applicantType !== ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT ||
          getTotalResponsibleOccupants(otherApplications) >= 1) && (
          <MenuButton color={Color.red} onClick={onRequestTransfer}>
            Transfer Applicant
          </MenuButton>
        )}
        {!isMinor && hasTerms && applicant.agreedToTerms && (
          <MenuButton
            onClick={() =>
              window.open(
                `/leasing-center/applications/${applicationGroupId}/${applicationId}/agreement-pdf`,
                "_blank",
              )
            }>
            Print Applicant Agreement
          </MenuButton>
        )}
        {!isMinor && (
          <MenuButton
            onClick={() =>
              window.open(
                `/leasing-center/applications/${applicationGroupId}/${applicant.userId}/print-application-answers`,
                "_blank",
              )
            }>
            Print Applicant Response
          </MenuButton>
        )}
      </MenuContent>
    </Menu>
  );
});

export { ApplicantActionsMenu };

export type { ApplicantActionsMenuProps };
