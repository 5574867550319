/**
 * HTTP Method: GET
 * Pathname: /reports/{reportId}
 * @function getReport
 * @memberof ReportsAPI
 * @param {ReportsAPIGetReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ReportResponse } from "@ender/shared/generated/ender.api.reports.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIGetReportPathParams = {
  reportId: EnderId;
};

type ReportsAPIGetReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIGetReportPayload = ReportsAPIGetReportPathParams &
  ReportsAPIGetReportSearchParams;

function getReportUnsafeEffect(payload: ReportsAPIGetReportPayload) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ReportResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/reports/${reportId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getReport(
  payload: ReportsAPIGetReportPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportResponse> {
  return F.pipe(
    payload,
    getReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getReport };
export type {
  ReportsAPIGetReportPathParams,
  ReportsAPIGetReportPayload,
  ReportsAPIGetReportSearchParams,
};
