/**
 * Posts the eligible late fee to the tenant ledger.
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/postLateFee
 * @function postLateFee
 * @memberof LeasingAPI
 * @param {LeasingAPIPostLateFeePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIPostLateFeePathParams = {
  leaseId: EnderId;
};

type LeasingAPIPostLateFeeSearchParams = {
  token?: string | undefined;
};

type LeasingAPIPostLateFeeBodyParams = {
  amount: SerializesInto<Money>;
};

type LeasingAPIPostLateFeePayload = LeasingAPIPostLateFeePathParams &
  LeasingAPIPostLateFeeSearchParams &
  LeasingAPIPostLateFeeBodyParams;

function postLateFeeUnsafeEffect(payload: LeasingAPIPostLateFeePayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/postLateFee`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function postLateFee(
  payload: LeasingAPIPostLateFeePayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice> {
  return F.pipe(
    payload,
    postLateFeeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { postLateFee };
export type {
  LeasingAPIPostLateFeeBodyParams,
  LeasingAPIPostLateFeePathParams,
  LeasingAPIPostLateFeePayload,
  LeasingAPIPostLateFeeSearchParams,
};
