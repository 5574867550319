/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}/zones
 * @function getZones
 * @memberof InspectionAPI
 * @param {InspectionAPIGetZonesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionZoneResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionZoneResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetZonesPathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
};

type InspectionAPIGetZonesSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetZonesPayload = InspectionAPIGetZonesPathParams &
  InspectionAPIGetZonesSearchParams;

function getZonesUnsafeEffect(payload: InspectionAPIGetZonesPayload) {
  const { inspectionId, areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionZoneResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionZoneResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}/zones`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getZones(
  payload: InspectionAPIGetZonesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionZoneResponse[]> {
  return F.pipe(
    payload,
    getZonesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getZones };
export type {
  InspectionAPIGetZonesPathParams,
  InspectionAPIGetZonesPayload,
  InspectionAPIGetZonesSearchParams,
};
