import { IconEdit } from "@tabler/icons-react";
import { String as S } from "effect";
import type { MouseEvent } from "react";
import { useContext, useState } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Modal } from "@ender/shared/ds/modal";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";

import { EditInvoiceAllocationsFormAsModalContent } from "./edit-invoice-allocations-form/invoice-allocations-form";
import { getEditAllocationsMessage } from "./get-edit-allocations-message";
import { getNeedsMyApproval } from "./get-needs-my-approval";

type EditInvoiceAllocationsButtonProps = {
  approvalSteps?: GetApprovalProcessResponse;
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess: () => void;
};

function EditInvoiceAllocationsButton({
  approvalSteps,
  invoice,
  onSuccess,
}: EditInvoiceAllocationsButtonProps) {
  const { hasPermissions, user } = useContext(UserContext);
  const needsMyApproval = getNeedsMyApproval({ approvalSteps, invoice, user });
  const hasEditInvoiceAllocationsPermission = hasPermissions(
    FunctionalPermissionEnum.EDIT_INVOICE_ALLOCATIONS,
  );
  const editAllocationsMessage = getEditAllocationsMessage({
    hasEditInvoiceAllocationsPermission,
    invoice: invoice,
    needsMyApproval,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const confirmation = useConfirmationContext();

  async function handleUpdateAllocationsClick(
    e: MouseEvent<HTMLButtonElement>,
  ) {
    e.stopPropagation();
    try {
      if (invoice.currentState.isApproved) {
        await confirmation({
          confirmButtonLabel: "Continue",
          content: "This will modify transactions on the general ledger.",
          title:
            "Are you sure you want to update the allocations of a paid invoice?",
        });
      }

      setIsModalOpen(true);
    } catch {}
  }

  return (
    <>
      <ActionIcon
        label="Edit Allocations"
        disabledTooltip={editAllocationsMessage}
        disabled={S.isNonEmpty(editAllocationsMessage)}
        onClick={handleUpdateAllocationsClick}>
        <IconEdit />
      </ActionIcon>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Set Allocations">
        <EditInvoiceAllocationsFormAsModalContent
          closeModal={() => setIsModalOpen(false)}
          invoiceId={invoice.id}
          onSuccess={onSuccess}
        />
      </Modal>
    </>
  );
}

export { EditInvoiceAllocationsButton };
