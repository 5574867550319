import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Function as F, Predicate as P } from "effect";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import type { Appliance } from "@ender/shared/generated/ender.model.core.unit";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

function ApplianceTable({
  appliances,
  isLoading,
  openEdit,
  openDelete,
}: {
  appliances: Appliance[];
  isLoading: boolean;
  openEdit: (appliance: Appliance) => void;
  openDelete: (appliance: Appliance) => void;
}) {
  if (!isLoading && (P.isNullable(appliances) || A.isEmptyArray(appliances))) {
    return <Text size={FontSize.sm}>No unit appliances to show</Text>;
  }

  return (
    <Skeleton visible={isLoading}>
      <EnderTable>
        <thead>
          <tr>
            <th>Type</th>
            <th>Brand</th>
            <th>Model</th>
            <th>Serial #</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {appliances.map((appliance) => (
            <tr key={appliance.id}>
              <td>{convertSnakeCaseToTitleCase(appliance.type)}</td>
              <td>{appliance.brand || "-"}</td>
              <td>{appliance.model || "-"}</td>
              <td>{appliance.serialCode || "-"}</td>
              <td>
                <Group
                  spacing={Spacing.sm}
                  align={Align.center}
                  justify={Justify.end}>
                  <ActionIcon
                    variant={ButtonVariant.transparent}
                    onClick={() => openEdit(appliance)}>
                    <IconEdit />
                  </ActionIcon>
                  <ActionIcon
                    variant={ButtonVariant.transparent}
                    onClick={() => openDelete(appliance)}>
                    <IconTrash />
                  </ActionIcon>
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </EnderTable>
    </Skeleton>
  );
}

type AppliancesCardProps = {
  unitId?: EnderId;
  onEdit?: (appliance?: Appliance) => void;
  onAdd?: () => void;
};

function AppliancesCard(props: AppliancesCardProps) {
  const { unitId, onEdit = F.constVoid, onAdd = F.constVoid } = props;

  const {
    data: appliances,
    refetch: fetchAppliances,
    isLoading,
  } = useQuery({
    enabled: P.isNotNullable(unitId),
    queryFn: ({ signal }) => {
      if (unitId) {
        return UnitsAPI.getUnitAppliances({ unitId }, { signal });
      }
    },
    queryKey: ["UnitsAPI.getUnitAppliances", unitId] as const,
  });

  const confirmation = useConfirmationContext();
  const { mutateAsync: deleteAppliance } = useMutation({
    mutationFn: UnitsAPI.deleteAppliance,
    mutationKey: ["UnitsAPI.deleteAppliance"] as const,
  });

  async function handleDelete(appliance: Appliance) {
    await confirmation({
      content: "This action cannot be undone",
      title: "Are you sure you want to delete this appliance?",
    });
    try {
      await deleteAppliance({
        applianceId: appliance.id,
      });
      await fetchAppliances();
      showSuccessNotification({ message: "Appliance deleted" });
    } catch (error) {
      fail(error);
    }
  }

  return (
    <Stack>
      <Group justify={Justify.between}>
        <H2>Appliances</H2>
        <ActionIcon label="Add Appliance" onClick={onAdd}>
          <IconPlus />
        </ActionIcon>
      </Group>
      <ApplianceTable
        appliances={appliances ?? []}
        isLoading={isLoading}
        openEdit={onEdit}
        openDelete={handleDelete}
      />
    </Stack>
  );
}

export { AppliancesCard };
