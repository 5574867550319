import { Predicate as P, String as S } from "effect";
import { useContext, useMemo } from "react";

import { UserContext } from "@ender/shared/contexts/user";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";
import { EnderDate } from "@ender/shared/utils/ender-date";
import type { ChatChannelParams } from "@ender/widgets/chat";
import { Chat } from "@ender/widgets/chat";

type LeaseChatProps = {
  lease: LeaseSerializerLeaseResponse;
};

function LeaseChat({ lease }: LeaseChatProps) {
  const { user } = useContext(UserContext);

  const chatChannels = useMemo(() => {
    if (P.isNullable(lease) || P.isNullable(lease.contacts)) {
      return [];
    }

    const financiallyResponsible =
      lease.contacts.filter((contact) =>
        contact.roles.includes(
          LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
        ),
      ) ?? [];

    const contactableTenants = lease.contacts.filter(
      (contact) =>
        //do they have at least one valid form of contact?
        (S.isNonEmpty(contact.email) || S.isNonEmpty(contact.phone)) &&
        //are they not a minor?
        (P.isNullable(contact.birthday) ||
          !EnderDate.isBirthdayMinor(contact.birthday)),
    );

    const arr: ChatChannelParams[] =
      financiallyResponsible.length > 1
        ? [
            {
              isInternal: false,
              modelId: lease?.id,
              modelType: ModelTypeEnum.LEASE,
              chatTitle: "All Financially Responsible",
            },
          ]
        : [];

    return arr.concat([
      ...contactableTenants.map<ChatChannelParams>((contact) => ({
        chatTitle: `${contact.firstName} ${contact.lastName}`,
        isInternal: false,
        modelType: ModelTypeEnum.MULTI_MODEL_CHAT,
        modelParams: [
          { modelType: ModelTypeEnum.LEASE, modelId: lease.id },
          { modelType: ModelTypeEnum.USER, modelId: contact.id },
        ],
      })),
      {
        chatTitle: "Maintenance Chat",
        modelType: ModelTypeEnum.MULTI_MODEL_CHAT,
        isInternal: false,
        modelParams: [
          { modelType: ModelTypeEnum.LEASE, modelId: lease.id },
          { modelType: ModelTypeEnum.PROPERTY_MANAGER, modelId: user.pmId },
        ],
      },
    ]);
  }, [lease, user.pmId]);

  return <Chat channels={chatChannels} />;
}

export { LeaseChat };
export type { LeaseChatProps };
