import { IconSearch } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Function as F, Predicate as P, String as S } from "effect";
import type { ForwardedRef } from "react";
import { forwardRef, useCallback, useRef, useState } from "react";

import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import type { ModelType } from "@ender/shared/types/ender-general";
import type { LabelValue } from "@ender/shared/types/label-value";
import type { SelectProps } from "@ender/shared/ui/select";
import { Select } from "@ender/shared/ui/select";
import { capitalize } from "@ender/shared/utils/string";

type SearchInputFn<T extends string> = (
  keyword: string,
) => Promise<LabelValue<T>[]>;
type SearchInputHydrateFn<T extends string> = (
  value: T,
) => Promise<LabelValue<T>[]>;

type SearchInputProps<T extends string> = {
  /**
   * how long between user inputs should the search be triggered
   * @default 300 ms
   */
  debounce?: number;
  /**
   * takes a function that can be used to populate the value of the select based on the known selected value.
   * This is because the selected value is an ID, but the select needs a label to display.
   *
   * You can return multiple values if you want to display multiple select options before the user has searched.
   */
  hydrate?: SearchInputHydrateFn<T>;
  /**
   * The promise function that this component will run when searching.
   * should always return an array of {label:string, value:string} objects.
   * T is typically an EnderId but can be any string-based type
   */
  search: SearchInputFn<T>;
  searchOnEmpty?: boolean;
  /**
   * required for providing an accurate key to the useQuery hook.
   * we use ModelType to ensure consistency in keys
   */
  searchType: ModelType;
} & Pick<
  SelectProps<T>,
  | "error"
  | "value"
  | "onChange"
  | "disabled"
  | "label"
  | "placeholder"
  | "clearable"
>;

function SearchInputBase<T extends string = string>(
  props: SearchInputProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    clearable,
    debounce = 300,
    disabled,
    error,
    hydrate,
    label,
    onChange = F.constVoid,
    placeholder,
    search,
    searchOnEmpty,
    searchType,
    value,
  } = props;

  // const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState("");

  const isFetchedRef = useRef(false);
  const changedFromSelect = useRef(P.isNotNullable(value));

  const { data: hydratedData } = useQuery({
    enabled:
      P.isNotNullable(value) &&
      !isFetchedRef.current &&
      P.isNotNullable(hydrate),
    //@ts-expect-error value is T | null but this can only be called if `value` is defined, so it won't ever be null
    queryFn: () => hydrate?.(value),
    queryKey: [`hydrate${capitalize(searchType)}`, value],
  });

  const {
    data: searchResultData = [],
    isFetched,
    isFetching,
  } = useQuery({
    enabled:
      !changedFromSelect.current && (searchOnEmpty || S.isNonEmpty(keyword)),
    placeholderData: (S.isEmpty(keyword) && hydratedData) || [],
    queryFn: () => search(keyword),
    queryKey: [`search${capitalize(searchType)}`, keyword],
  });
  isFetchedRef.current = isFetched;

  const debounceSearch = useDebounce(setKeyword, debounce);

  /**
   * clears the keyword. Can be triggered on blur and when the user selects or clears the search field
   */
  const clearSearchData = useCallback(() => {
    if (!value) {
      setKeyword("");
    }
  }, [value]);

  /**
   * handle selecting item from the dropdown
   */
  const handleChange = useCallback<typeof onChange>(
    (_value) => {
      changedFromSelect.current = true;
      onChange(_value);
      clearSearchData();
    },
    [onChange, clearSearchData],
  );

  /**
   * called when the user types in the search field
   * or when a user selects an item from the dropdown
   */
  const handleSearch = useCallback(
    (keyword: string) => {
      if (
        (searchOnEmpty || S.isNonEmpty(keyword)) &&
        !changedFromSelect.current
      ) {
        debounceSearch(keyword);
      }
    },
    [searchOnEmpty, debounceSearch],
  );

  const resetSearchState = useCallback(() => {
    changedFromSelect.current = false;
  }, []);

  return (
    <Select<T>
      ref={ref}
      error={error}
      label={label}
      placeholder={placeholder}
      leftSection={isFetching ? <LoadingSpinner /> : <IconSearch />}
      nothingFound={isFetched ? "No results found" : "Search to see results."}
      data={searchResultData}
      onKeyDown={resetSearchState}
      onBlur={clearSearchData}
      searchable
      clearable={clearable}
      disabled={disabled}
      showRightSection={false}
      onSearchChange={handleSearch}
      onChange={handleChange}
      value={value}
    />
  );
}

/**
 * renders a searchable select whose data is powered by a search api
 * and dynamically fetched as the user types. Behaves the same way as a Select
 *
 * @deprecated use SearchInput from @ender/entities/search-input
 */
const SearchInput = forwardRef(SearchInputBase) as <T extends string>(
  //eslint-disable-next-line
  props: SearchInputProps<T> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof SearchInputBase>;

export { SearchInput };
export type { SearchInputFn, SearchInputHydrateFn, SearchInputProps };
