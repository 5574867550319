import { useCallback, useState } from "react";

type UseSelectProps<T> = {
  value?: T | (() => T) | null;
  onSelect?: (val: T | null) => void;
};

type UseSelectReturn<T> = {
  value: T | null;
  onChange: (val: T | null) => void;
  searchValue: string;
  onSearchChange: (val: string) => void;
};

function useSelect<T>({
  value: _value = null,
  onSelect,
}: UseSelectProps<T> = {}): UseSelectReturn<T> {
  const [value, setValue] = useState<T | null>(_value);
  const [searchValue, onSearchChange] = useState("");
  const onChange = useCallback(
    (val: T | null) => {
      setValue(val);
      onSelect?.(val);
    },
    [onSelect],
  );

  return { onChange, onSearchChange, searchValue, value };
}

export { useSelect };
export type { UseSelectProps, UseSelectReturn };
