import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CollectionsStepProcessTypeValues = ["STABLE", "EVICTION"] as const;

const CollectionsStepProcessTypeEffectSchema = Schema.Literal(
  ...CollectionsStepProcessTypeValues,
);

type CollectionsStepProcessType = Schema.Schema.Type<
  typeof CollectionsStepProcessTypeEffectSchema
>;

const CollectionsStepProcessTypeEnum = castEnum(
  CollectionsStepProcessTypeEffectSchema,
);

function randomCollectionsStepProcessType(): CollectionsStepProcessType {
  return rand(CollectionsStepProcessTypeValues);
}

export {
  CollectionsStepProcessTypeEffectSchema,
  CollectionsStepProcessTypeEnum,
  CollectionsStepProcessTypeValues,
  randomCollectionsStepProcessType,
};
export type { CollectionsStepProcessType };
