import { Array as A, Predicate as P } from "effect";
import type { TupleOfAtLeast } from "effect/Types";

const isMultiple = <T extends unknown>(
  val: T,
  //@ts-expect-error T could be instantiated with a different subtype of constraint unknown
): val is TupleOfAtLeast<2, A.ReadonlyArray.Infer<T>> =>
  P.compose(P.isTupleOfAtLeast(2))(A.isArray)(val);

export { isMultiple };
