import { Schema } from "@effect/schema";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Array as A, Option as O, Order, Predicate as P } from "effect";
import { useMemo, useState } from "react";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import {
  FormSearchInput,
  hydrateProperty,
  searchProperties,
} from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  BankingAPI,
  PaymentsAPI,
} from "@ender/shared/generated/ender.api.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { BankAccountAccountStatusEnum } from "@ender/shared/generated/ender.model.payments";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

type InternalTransferACHModalProps = {
  onSuccess: () => void;
  onClose: () => void;
};

const InternalTransferACHFormSchema = Schema.Struct({
  amount: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (v): v is O.Some<Money$.Money> => O.exists(v, Money$.isPositive),
      {
        message: () => "Amount must be greater than 0.",
      },
    ),
    Schema.filter(
      (v): v is O.Some<Money$.Money> =>
        O.exists(
          v,
          Order.lessThanOrEqualTo(Money$.Order)(Money$.fromDollars(250_000)),
        ),
      {
        message: () => "Max ACH transfer amount is $250,000",
      },
    ),
  ),
  fromBankAccountId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Bank Account is required" }),
  ),
  memo: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Memo is required" }),
  ),
  periodId: Schema.Struct({
    id: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
  propertyId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Property is required" }),
  ),
  toBankAccountId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Bank Account is required" }),
  ),
}).pipe(
  Schema.filter((values) => {
    if (
      O.isSome(values.fromBankAccountId) &&
      O.isSome(values.toBankAccountId) &&
      values.fromBankAccountId.value === values.toBankAccountId.value
    ) {
      return {
        message: "Transfer from and Transfer to must differ",
        path: ["toBankAccountId"],
      };
    }
  }),
);

type InternalTransferACHFormType = Schema.Schema.Type<
  typeof InternalTransferACHFormSchema
>;

function InternalTransferACHModal({
  onSuccess,
  onClose,
}: InternalTransferACHModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useEffectSchemaForm({
    defaultValues: {
      amount: O.none(),
      fromBankAccountId: O.none(),
      memo: "",
      periodId: O.none(),
      propertyId: O.none(),
      toBankAccountId: O.none(),
    },
    schema: InternalTransferACHFormSchema,
  });

  const propertyId = form.watch("propertyId");
  const queryClient = useQueryClient();

  const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } =
    useQuery({
      queryKey: ["BankingAPI.searchBankAccountsByFirm", propertyId] as const,
      queryFn: async ({ signal }) => {
        const response = await BankingAPI.searchBankAccountsByFirm(
          {
            filters: [],
            firmIds: [],
            fundIds: [],
            propertyIds: [propertyId.pipe(O.getOrThrow)],
          },
          { signal },
        );
        form.setValue("fromBankAccountId", O.none());
        form.setValue("toBankAccountId", O.none());
        return response;
      },
      enabled: O.isSome(propertyId),
    });

  const bankAccountsList = useMemo(() => {
    if (P.isNullable(bankAccounts) || A.isEmptyArray(bankAccounts)) {
      return [];
    }

    return bankAccounts
      .filter(
        (bankAccount) =>
          bankAccount.dwollaStatus === BankAccountAccountStatusEnum.VERIFIED,
      )
      .map((bankAccount) => ({
        label: bankAccount.name,
        value: bankAccount.id,
      }));
  }, [bankAccounts]);

  const confirmation = useConfirmationContext();
  const createInternalDwollaTransferWithWarnings = withWarningHandler(
    PaymentsAPI.createInternalDwollaTransfer,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );

  async function handleSubmitForm(values: InternalTransferACHFormType) {
    try {
      setIsSubmitting(true);
      await createInternalDwollaTransferWithWarnings({
        amount: values.amount.pipe(
          O.map((val) => val.toJSON()),
          O.getOrThrow,
        ),
        fromBankAccountId: values.fromBankAccountId.pipe(O.getOrThrow),
        memo: values.memo,
        periodId: values.periodId.pipe(
          O.map((val) => val.id),
          O.getOrElse(() => UNDEFINED),
        ),
        propertyId: values.propertyId.pipe(O.getOrThrow),
        toBankAccountId: values.toBankAccountId.pipe(O.getOrThrow),
      });
      showSuccessNotification({
        message:
          "Your ACH transfer has been sent. Please allow 2-3 days for the transaction to appear on your bank statement.",
      });
      onSuccess();
      queryClient.invalidateQueries(["getInternalTransfers"]);
    } catch (err) {
      fail(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Form form={form} onSubmit={handleSubmitForm}>
      <Stack spacing={Spacing.lg}>
        <H1>ACH Transfer</H1>
        <FormSearchInput<EnderId, typeof form>
          clearable
          form={form}
          hydrate={hydrateProperty}
          label="Property"
          modelType={ModelTypeEnum.PROPERTY}
          name="propertyId"
          placeholder="Select Property"
          search={searchProperties}
        />
        <FormMoneyInput
          label="Amount"
          name="amount"
          form={form}
          placeholder="0.00"
        />
        <FormSelect
          label="Transfer from"
          placeholder="Select bank account"
          disabled={
            isLoadingBankAccounts ||
            A.isEmptyArray(bankAccountsList) ||
            O.isNone(propertyId)
          }
          data={bankAccountsList ?? []}
          form={form}
          name="fromBankAccountId"
        />
        <FormSelect
          label="Transfer to"
          placeholder="Select bank account"
          disabled={
            isLoadingBankAccounts ||
            A.isEmptyArray(bankAccountsList) ||
            O.isNone(propertyId)
          }
          data={bankAccountsList ?? []}
          form={form}
          name="toBankAccountId"
        />
        <FormAccountingPeriodSelector
          data-test-id="internal-transfer-accounting-period"
          form={form}
          label="Accounting Period (optional)"
          name="periodId"
          periodType={AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER}
        />
        <FormTextInput form={form} name="memo" label="Memo" />
        <Group justify={Justify.end}>
          <Button
            variant={ButtonVariant.outlined}
            onClick={onClose}
            loading={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" loading={isSubmitting}>
            Confirm ACH Transfer
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { InternalTransferACHModal };
