/**
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}/refreshPlaidTransactions
 * @function refreshPlaidTransactions
 * @memberof BankingAPI
 * @param {BankingAPIRefreshPlaidTransactionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BankingAPIRefreshPlaidTransactionsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BankingAPIRefreshPlaidTransactionsResponse } from "../banking-api-refresh-plaid-transactions-response";

type BankingAPIRefreshPlaidTransactionsPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIRefreshPlaidTransactionsSearchParams = {
  token?: string | undefined;
};

type BankingAPIRefreshPlaidTransactionsPayload =
  BankingAPIRefreshPlaidTransactionsPathParams &
    BankingAPIRefreshPlaidTransactionsSearchParams;

function refreshPlaidTransactionsUnsafeEffect(
  payload: BankingAPIRefreshPlaidTransactionsPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BankingAPIRefreshPlaidTransactionsResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<BankingAPIRefreshPlaidTransactionsResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}/refreshPlaidTransactions`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function refreshPlaidTransactions(
  payload: BankingAPIRefreshPlaidTransactionsPayload,
  options?: { signal?: AbortSignal },
): Promise<BankingAPIRefreshPlaidTransactionsResponse> {
  return F.pipe(
    payload,
    refreshPlaidTransactionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { refreshPlaidTransactions };
export type {
  BankingAPIRefreshPlaidTransactionsPathParams,
  BankingAPIRefreshPlaidTransactionsPayload,
  BankingAPIRefreshPlaidTransactionsSearchParams,
};
