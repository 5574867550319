import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { PayableCategorySelectProps } from "./payable-category-select";
import { PayableCategorySelect } from "./payable-category-select";

type FormPayableCategorySelectProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<PayableCategorySelectProps, Form, Name>;

function FormPayableCategorySelect<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormPayableCategorySelectProps<Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field }) => {
        return <PayableCategorySelect {...rest} {...field} />;
      }}
    </FormField>
  );
}

export { FormPayableCategorySelect };
export type { FormPayableCategorySelectProps };
