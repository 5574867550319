/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/scheduledCharges
 * @function getScheduledChargesForLease
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPIGetScheduledChargesForLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ChargeSchedulesAPIGetScheduledChargesForLeaseResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ChargeSchedulesAPIGetScheduledChargesForLeaseResponse } from "../charge-schedules-api-get-scheduled-charges-for-lease-response";

type ChargeSchedulesAPIGetScheduledChargesForLeasePathParams = {
  leaseId: EnderId;
};

type ChargeSchedulesAPIGetScheduledChargesForLeaseSearchParams = {
  token?: string | undefined;
  upcoming?: boolean | undefined;
};

type ChargeSchedulesAPIGetScheduledChargesForLeasePayload =
  ChargeSchedulesAPIGetScheduledChargesForLeasePathParams &
    ChargeSchedulesAPIGetScheduledChargesForLeaseSearchParams;

function getScheduledChargesForLeaseUnsafeEffect(
  payload: ChargeSchedulesAPIGetScheduledChargesForLeasePayload,
) {
  const { leaseId, token, upcoming, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ChargeSchedulesAPIGetScheduledChargesForLeaseResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ChargeSchedulesAPIGetScheduledChargesForLeaseResponse>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/scheduledCharges`,
        searchParams: [
          ["token", token],
          ["upcoming", upcoming],
        ],
      }),
    ),
  );
}

function getScheduledChargesForLease(
  payload: ChargeSchedulesAPIGetScheduledChargesForLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<ChargeSchedulesAPIGetScheduledChargesForLeaseResponse> {
  return F.pipe(
    payload,
    getScheduledChargesForLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getScheduledChargesForLease };
export type {
  ChargeSchedulesAPIGetScheduledChargesForLeasePathParams,
  ChargeSchedulesAPIGetScheduledChargesForLeasePayload,
  ChargeSchedulesAPIGetScheduledChargesForLeaseSearchParams,
};
