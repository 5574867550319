import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const MonthValues = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
] as const;

const MonthEffectSchema = Schema.Literal(...MonthValues);

type Month = Schema.Schema.Type<typeof MonthEffectSchema>;

const MonthEnum = castEnum(MonthEffectSchema);

function randomMonth(): Month {
  return rand(MonthValues);
}

export { MonthEffectSchema, MonthEnum, MonthValues, randomMonth };
export type { Month };
