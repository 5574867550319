// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { SelectItem } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { ActionIcon, Select as MantineSelect } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { MantineTheme } from "@mantine/styles";
import { IconChevronDown, IconX } from "@tabler/icons-react";
import { Function as F } from "effect";
import type { ForwardedRef, ReactNode, Ref, RefObject } from "react";
import {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";

import { NULL } from "@ender/shared/constants/general";
import { useMergedRef } from "@ender/shared/hooks/use-merged-ref";
import { cast } from "@ender/shared/types/cast";
import { InputHeightEnum } from "@ender/shared/types/ender-general";
import { ENDER_INPUT_WRAPPER_ORDER } from "@ender/shared/utils/mantine";
import { genTestId } from "@ender/shared/utils/string";

import { filterLabelValueItems } from "./frontend-shared-utils-label-value";
import type { SelectProps } from "./select.types";
import { selectStylesGenTheme } from "./select.utils";

function useInputSelectOnInteraction(inputRef: RefObject<HTMLInputElement>) {
  useLayoutEffect(() => {
    const { current: searchInput } = inputRef;

    function handleFocusInputField() {
      searchInput?.select();
    }

    searchInput?.addEventListener("focus", handleFocusInputField);
    searchInput?.addEventListener("click", handleFocusInputField);

    return () => {
      searchInput?.removeEventListener("focus", handleFocusInputField);
      searchInput?.removeEventListener("click", handleFocusInputField);
    };
  }, [inputRef]);
}

/**
 * @deprecated use Select from shared/ds/select
 */
const Select = forwardRef(function Select<T extends string>(
  props: SelectProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    autoComplete,
    clearable = true,
    creatable = false,
    data,
    description,
    disabled,
    error,
    filter,
    getCreateLabel,
    height = InputHeightEnum.TALL,
    itemComponent,
    label,
    leftSection,
    nothingFound,
    onBlur,
    onChange = F.constVoid,
    onCreate,
    onFocus,
    onKeyDown,
    onSearchChange = F.constVoid,
    placeholder,
    required,
    searchable = false,
    searchValue,
    showRightSection = false,
    testId,
    toFilter,
    value,
    zIndex,
  } = props;
  const inputRef = useRef<HTMLInputElement>(NULL);
  const mergedRef = useMergedRef<HTMLInputElement>(ref, inputRef);
  useInputSelectOnInteraction(inputRef);

  const shouldShowClearButton = useMemo(() => {
    return Boolean((value || searchValue) && clearable);
  }, [clearable, searchValue, value]);

  const finalData = useMemo(() => {
    return filterLabelValueItems<T>(cast(data), toFilter);
  }, [data, toFilter]);

  const finalOnChange = useCallback(
    (val: T) => {
      const toFilterSet = new Set(toFilter);
      if (!toFilterSet?.has(val)) {
        onChange(val);
      }
    },
    [onChange, toFilter],
  );

  const finalStylesGen = useCallback(
    (theme: MantineTheme) => {
      return selectStylesGenTheme({
        height,
        shouldClear: shouldShowClearButton,
        showRightSection,
      })(theme);
    },
    [height, shouldShowClearButton, showRightSection],
  );

  return (
    <MantineSelect
      allowDeselect={false}
      autoComplete={autoComplete}
      clearable={clearable}
      creatable={creatable}
      data={cast<SelectItem[]>(finalData)}
      data-testid={genTestId("ender-select", testId)}
      description={description}
      disabled={disabled}
      error={error}
      filter={cast<(value: string, item: SelectItem) => boolean>(filter)}
      getCreateLabel={getCreateLabel}
      hoverOnSearchChange
      icon={leftSection}
      inputWrapperOrder={ENDER_INPUT_WRAPPER_ORDER}
      itemComponent={itemComponent}
      label={label}
      nothingFound={nothingFound}
      onBlur={onBlur}
      onChange={cast<(val: string | null) => void>(finalOnChange)}
      onCreate={onCreate}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onSearchChange={onSearchChange}
      placeholder={placeholder}
      ref={mergedRef}
      required={required}
      rightSection={
        shouldShowClearButton ? (
          <ActionIcon
            aria-label={`Clear ${label}`}
            disabled={disabled}
            onClick={() => {
              onChange(null);
              onSearchChange("");
            }}
            variant="transparent">
            <IconX size={14} />
          </ActionIcon>
        ) : (
          <IconChevronDown size={14} />
        )
      }
      searchable={searchable}
      searchValue={searchValue}
      selectOnBlur={false}
      styles={finalStylesGen}
      value={cast<string>(value) ?? ""}
      zIndex={zIndex}
    />
  );
}) as <T extends string>(
  // eslint-disable-next-line no-use-before-define
  props: SelectProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReactNode;

export { Select };
export type { SelectProps };
