/**
 * HTTP Method: POST
 * Pathname: /moveInConfigSteps/{stepId}
 * @function updateConfigStep
 * @memberof MoveInAPI
 * @param {MoveInAPIUpdateConfigStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoveInConfigRow>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { MoveInConfigRow } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MoveInAPIUpdateConfigStepPathParams = {
  stepId: EnderId;
};

type MoveInAPIUpdateConfigStepSearchParams = {
  token?: string | undefined;
};

type MoveInAPIUpdateConfigStepBodyParams = {
  description?: string | undefined;
  isRequired?: boolean | undefined;
  ordering?: number | undefined;
  title?: string | undefined;
};

type MoveInAPIUpdateConfigStepPayload = MoveInAPIUpdateConfigStepPathParams &
  MoveInAPIUpdateConfigStepSearchParams &
  MoveInAPIUpdateConfigStepBodyParams;

function updateConfigStepUnsafeEffect(
  payload: MoveInAPIUpdateConfigStepPayload,
) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoveInConfigRow>({
        body,
        decode: unsafeDecodeJsonResponse<MoveInConfigRow>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/moveInConfigSteps/${stepId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateConfigStep(
  payload: MoveInAPIUpdateConfigStepPayload,
  options?: { signal?: AbortSignal },
): Promise<MoveInConfigRow> {
  return F.pipe(
    payload,
    updateConfigStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateConfigStep };
export type {
  MoveInAPIUpdateConfigStepBodyParams,
  MoveInAPIUpdateConfigStepPathParams,
  MoveInAPIUpdateConfigStepPayload,
  MoveInAPIUpdateConfigStepSearchParams,
};
