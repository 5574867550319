import { addChild } from "./add-child";
import { createGLCategoryGrouping } from "./create-g-l-category-grouping";
import { deleteCategory } from "./delete-category";
import { deleteGLCategoryGrouping } from "./delete-g-l-category-grouping";
import { editCategory } from "./edit-category";
import { editGLCategoryGrouping } from "./edit-g-l-category-grouping";
import { getAllFlags } from "./get-all-flags";
import { getCategoriesTree } from "./get-categories-tree";
import { getCategory } from "./get-category";
import { getChargeScheduleCategories } from "./get-charge-schedule-categories";
import { getChartOfAccounts } from "./get-chart-of-accounts";
import { getGLCategoryGroupings } from "./get-g-l-category-groupings";
import { getRequiredFlagsCategories } from "./get-required-flags-categories";
import { getTxCategories } from "./get-tx-categories";
import { moveCategory } from "./move-category";
import { updateTenantPaymentAllocationWaterfall } from "./update-tenant-payment-allocation-waterfall";

const GeneralLedgerAPI = {
  addChild,
  createGLCategoryGrouping,
  deleteCategory,
  deleteGLCategoryGrouping,
  editCategory,
  editGLCategoryGrouping,
  getAllFlags,
  getCategoriesTree,
  getCategory,
  getChargeScheduleCategories,
  getChartOfAccounts,
  getGLCategoryGroupings,
  getRequiredFlagsCategories,
  getTxCategories,
  moveCategory,
  updateTenantPaymentAllocationWaterfall,
};

export { GeneralLedgerAPI };
