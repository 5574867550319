/**
 * HTTP Method: POST
 * Pathname: /getCustomFields
 * @function getCustomFields
 * @memberof FactorsAPI
 * @param {FactorsAPIGetCustomFieldsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FactorsAPIGetCustomFieldsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { FactorsAPIGetCustomFieldsResponse } from "../factors-api-get-custom-fields-response";

type FactorsAPIGetCustomFieldsSearchParams = {
  token?: string | undefined;
};

type FactorsAPIGetCustomFieldsBodyParams = {
  modelId?: EnderId | undefined;
  modelType: ModelType;
};

type FactorsAPIGetCustomFieldsPayload = FactorsAPIGetCustomFieldsSearchParams &
  FactorsAPIGetCustomFieldsBodyParams;

function getCustomFieldsUnsafeEffect(
  payload: FactorsAPIGetCustomFieldsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FactorsAPIGetCustomFieldsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<FactorsAPIGetCustomFieldsResponse[]>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getCustomFields",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCustomFields(
  payload: FactorsAPIGetCustomFieldsPayload,
  options?: { signal?: AbortSignal },
): Promise<FactorsAPIGetCustomFieldsResponse[]> {
  return F.pipe(
    payload,
    getCustomFieldsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCustomFields };
export type {
  FactorsAPIGetCustomFieldsBodyParams,
  FactorsAPIGetCustomFieldsPayload,
  FactorsAPIGetCustomFieldsSearchParams,
};
