import { Schema } from "@effect/schema";
import { forwardRef } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { Vehicle } from "@ender/shared/generated/ender.model.leasing";

const EditVehicleFormSchema = Schema.Struct({
  color: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Color is required" }),
  ),
  licensePlateNumber: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "License Plate is required" }),
  ),
  make: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Make is required" }),
  ),
  model: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Model is required" }),
  ),
});
type EditVehicleFormOutput = Schema.Schema.Type<typeof EditVehicleFormSchema>;

type EditVehicleFormProps = {
  vehicle: Pick<
    Vehicle,
    "color" | "make" | "model" | "licensePlateNumber" | "id"
  >;
  onSubmit: (values: EditVehicleFormOutput) => void;
  onCancel: () => void;
  loading?: boolean;
};

const EditVehicleForm = forwardRef<HTMLFormElement, EditVehicleFormProps>(
  function EditVehicleForm(props, _ref) {
    const {
      onSubmit: handleSubmit,
      onCancel,
      vehicle,
      loading = false,
    } = props;
    const form = useEffectSchemaForm({
      defaultValues: vehicle,
      schema: EditVehicleFormSchema,
    });

    return (
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <FormTextInput
            form={form}
            name="make"
            label="Make"
            placeholder="Toyota"
          />
          <FormTextInput
            form={form}
            name="model"
            label="Model"
            placeholder="Tacoma"
          />
          <FormTextInput
            form={form}
            name="color"
            label="Color"
            placeholder="Blue"
          />
          <FormTextInput
            form={form}
            name="licensePlateNumber"
            label="License Plate"
            placeholder="1234567890"
          />
          <Group justify={Justify.end}>
            <Button variant={ButtonVariant.transparent} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Update Vehicle
            </Button>
          </Group>
        </Stack>
      </Form>
    );
  },
);

export { EditVehicleForm };

export type { EditVehicleFormOutput, EditVehicleFormProps };
