import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskTaskStatusValues = [
  "PM_CONFIRMING_TASK",
  "PM_ASSIGNING_VENDOR",
  "VENDOR_ACCEPTING",
  "SCHEDULING",
  "AWAITING_WORK_TO_BEGIN",
  "IN_PROGRESS",
  "PM_CONFIRMING_WORK",
  "VENDOR_ADDING_INVOICE",
  "PM_APPROVING_INVOICE",
  "PM_SENDING_PAYMENT",
  "CLOSED",
] as const;

const TaskTaskStatusEffectSchema = Schema.Literal(...TaskTaskStatusValues);

type TaskTaskStatus = Schema.Schema.Type<typeof TaskTaskStatusEffectSchema>;

const TaskTaskStatusEnum = castEnum(TaskTaskStatusEffectSchema);

function randomTaskTaskStatus(): TaskTaskStatus {
  return rand(TaskTaskStatusValues);
}

export {
  randomTaskTaskStatus,
  TaskTaskStatusEffectSchema,
  TaskTaskStatusEnum,
  TaskTaskStatusValues,
};
export type { TaskTaskStatus };
