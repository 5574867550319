import type { LeaseUserRoleLeaseUserFlag } from "@ender/shared/generated/ender.model.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";

const LeaseUserRoleLeaseUserFlagMap: Record<
  LeaseUserRoleLeaseUserFlag,
  string
> = {
  [LeaseUserRoleLeaseUserFlagEnum.TENANT]: "Tenant",
  [LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE]:
    "Financially Responsible",
  [LeaseUserRoleLeaseUserFlagEnum.EMERGENCY]: "Emergency",
  [LeaseUserRoleLeaseUserFlagEnum.PRIMARY_CONTACT]: "Primary Contact",
  [LeaseUserRoleLeaseUserFlagEnum.IN_STORE]: "In Store",
  [LeaseUserRoleLeaseUserFlagEnum.CORPORATE]: "Corporate",
  [LeaseUserRoleLeaseUserFlagEnum.SALES_FIGURES]: "Sales Figures",
  [LeaseUserRoleLeaseUserFlagEnum.GUARANTOR]: "Guarantor",
  [LeaseUserRoleLeaseUserFlagEnum.FACILITIES]: "Facilities",
  [LeaseUserRoleLeaseUserFlagEnum.ACCOUNTS_PAYABLE]: "Accounts Payable",
  [LeaseUserRoleLeaseUserFlagEnum.LEGAL]: "Legal",
  [LeaseUserRoleLeaseUserFlagEnum.HOUSING_CHOICE_VOUCHER]:
    "Housing Choice Voucher",
} as const;

export { LeaseUserRoleLeaseUserFlagMap };
