import { Predicate } from "effect";
import { useMemo } from "react";

import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";

import { InvoiceBatcher } from "./invoice-batcher";
import type { BatchableInvoice, InvoiceBatchV2 } from "./invoice-batches.types";
import { useBankAccountsByOwedToPartyIdForInvoices } from "./use-bank-accounts-by-invoice-owed-to-party";

function getBatchableInvoicesFromInvoiceSerializerInvoiceResponseInvoicesAdapter(
  invoices: InvoiceSerializerInvoiceResponse[],
): BatchableInvoice[] {
  return invoices.filter((invoice) => {
    // For AP invoices (paying external merchants), we only require owedByParty.id
    if (invoice.type === InvoiceInvoiceTypeEnum.PAYABLE) {
      return Predicate.isNotNullable(invoice.owedByParty?.id);
    }
    // For other invoice types, require both party IDs
    return (
      Predicate.isNotNullable(invoice.owedByParty?.id) &&
      Predicate.isNotNullable(invoice.owedToParty?.id)
    );
  });
}

/**
 * 2025-01-29 Geoffrey: adapted to InvoiceBatchV2 (BatchableInvoice)
 * but for now may continue to just use InvoiceSerializedInvoiceResponse in this hook
 */
function useInvoiceBatches<T extends BatchableInvoice>({
  invoices,
}: {
  invoices: T[];
}): {
  invoiceBatches: InvoiceBatchV2<T>[];
} {
  const bankAccountsByOwedToPartyId =
    useBankAccountsByOwedToPartyIdForInvoices(invoices);

  const batchableInvoices: T[] = invoices.filter((invoice) => {
    // For AP invoices (paying external merchants), we only require owedByParty.id
    if (invoice.type === InvoiceInvoiceTypeEnum.PAYABLE) {
      return Predicate.isNotNullable(invoice.owedByParty?.id);
    }
    // For other invoice types, require both party IDs
    return (
      Predicate.isNotNullable(invoice.owedByParty?.id) &&
      Predicate.isNotNullable(invoice.owedToParty?.id)
    );
  });

  const invoiceBatches = useMemo(() => {
    const batches = InvoiceBatcher.of(
      batchableInvoices,
      bankAccountsByOwedToPartyId,
    ).getBatches();
    return batches.sort((a, b) =>
      a.owedToPartyName.localeCompare(b.owedToPartyName),
    );
  }, [bankAccountsByOwedToPartyId, batchableInvoices]);

  return {
    invoiceBatches,
  };
}

export {
  getBatchableInvoicesFromInvoiceSerializerInvoiceResponseInvoicesAdapter,
  useInvoiceBatches,
};
