import type { ForwardedRef } from "react";
import { forwardRef } from "react";

import type { InputBaseProps } from "../../../input/src";
import { Input } from "../../../input/src";

type TextInputProps = {
  value: string;
  onChange: (value: string) => void;
} & InputBaseProps;

function TextInput(props: TextInputProps, ref: ForwardedRef<HTMLInputElement>) {
  const {
    borderless,
    value,
    onChange,
    onPaste,
    label,
    error,
    description,
    disabled,
    placeholder,
    leftSection,
    rightSection,
    size,
    name,
    "data-private": _dataPrivate,
    textAlign,
    role,
    inputMode,
    inputType,
  } = props;

  return (
    <Input
      borderless={borderless}
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      leftSection={leftSection}
      onChange={onChange}
      onPaste={onPaste}
      placeholder={placeholder}
      ref={ref}
      rightSection={rightSection}
      size={size}
      value={value}
      name={name}
      data-private={_dataPrivate}
      textAlign={textAlign}
      role={role}
      inputMode={inputMode}
      inputType={inputType}
    />
  );
}
const ForwardedTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  TextInput,
);

export { ForwardedTextInput as TextInput };

export type { TextInputProps };
