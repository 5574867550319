/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/search
 * @function inspectionSearch
 * @memberof InspectionAPI
 * @param {InspectionAPIInspectionSearchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InspectionSearchResponse>}
 */
import { Effect, Function as F } from "effect";

import type { InspectionSearchFilterByRequest } from "@ender/shared/generated/com.ender.construction.api.inspection.request";
import type { InspectionSearchResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import type { InspectionDBSearchSortBy } from "@ender/shared/generated/com.ender.construction.db.inspection";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIInspectionSearchSearchParams = {
  token?: string | undefined;
};

type InspectionAPIInspectionSearchBodyParams = {
  filterBy?: InspectionSearchFilterByRequest | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
  sortBy: InspectionDBSearchSortBy[];
};

type InspectionAPIInspectionSearchPayload =
  InspectionAPIInspectionSearchSearchParams &
    InspectionAPIInspectionSearchBodyParams;

function inspectionSearchUnsafeEffect(
  payload: InspectionAPIInspectionSearchPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InspectionSearchResponse>({
        body,
        decode: unsafeDecodeJsonResponse<InspectionSearchResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/construction/inspections/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function inspectionSearch(
  payload: InspectionAPIInspectionSearchPayload,
  options?: { signal?: AbortSignal },
): Promise<InspectionSearchResponse> {
  return F.pipe(
    payload,
    inspectionSearchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { inspectionSearch };
export type {
  InspectionAPIInspectionSearchBodyParams,
  InspectionAPIInspectionSearchPayload,
  InspectionAPIInspectionSearchSearchParams,
};
