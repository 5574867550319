/**
 * Get all BAI file uploads for a provided bank account
 * HTTP Method: GET
 * Pathname: /bankAccounts/{bankAccountId}/baiUploads
 * @function getBAIUploadsForBank
 * @memberof BankingAPI
 * @param {BankingAPIGetBAIUploadsForBankPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetBAIUploadsForBankResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetBAIUploadsForBankResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetBAIUploadsForBankPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIGetBAIUploadsForBankSearchParams = {
  token?: string | undefined;
};

type BankingAPIGetBAIUploadsForBankPayload =
  BankingAPIGetBAIUploadsForBankPathParams &
    BankingAPIGetBAIUploadsForBankSearchParams;

function getBAIUploadsForBankUnsafeEffect(
  payload: BankingAPIGetBAIUploadsForBankPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetBAIUploadsForBankResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetBAIUploadsForBankResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/bankAccounts/${bankAccountId}/baiUploads`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBAIUploadsForBank(
  payload: BankingAPIGetBAIUploadsForBankPayload,
  options?: { signal?: AbortSignal },
): Promise<GetBAIUploadsForBankResponse[]> {
  return F.pipe(
    payload,
    getBAIUploadsForBankUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBAIUploadsForBank };
export type {
  BankingAPIGetBAIUploadsForBankPathParams,
  BankingAPIGetBAIUploadsForBankPayload,
  BankingAPIGetBAIUploadsForBankSearchParams,
};
