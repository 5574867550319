import { Option as O, pipe } from "effect";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { LocalDate$ } from "@ender/shared/core";
import type { LeaseTerm } from "@ender/shared/types/ender-general";
import { LeaseTermEnum } from "@ender/shared/types/ender-general";

import type { NewLeaseFormTenantsInput } from "./new-lease.types";

function computeSuggestedEndDate(
  startDate: O.Option<LocalDate$.LocalDate>,
  endDate: O.Option<LocalDate$.LocalDate>,
  leaseTerm: LeaseTerm,
): O.Option<LocalDate$.LocalDate> {
  if (leaseTerm === LeaseTermEnum.EIGHTEEN_MONTHS) {
    return pipe(
      startDate,
      O.map((v) => v.add({ months: 18, days: -1 })),
    );
  }

  if (leaseTerm === LeaseTermEnum.TWELVE_MONTHS) {
    return pipe(
      startDate,
      O.map((v) => v.add({ months: 12, days: -1 })),
    );
  }

  if (leaseTerm === LeaseTermEnum.SIX_MONTHS) {
    return pipe(
      startDate,
      O.map((v) => v.add({ months: 6, days: -1 })),
    );
  }

  if (leaseTerm === LeaseTermEnum.MONTH_TO_MONTH) {
    return O.none();
  }

  if (leaseTerm === LeaseTermEnum.CUSTOM) {
    return endDate;
  }

  return O.none();
}

function generateNewTenant(): NewLeaseFormTenantsInput {
  return {
    tags: {
      financiallyResponsible: false,
      occupant: false,
      primaryLeaseContact: false,
    },
    firstName: "",
    lastName: "",
    dateOfBirth: O.none(),
    email: UNDEFINED,
    phone: UNDEFINED,
    driverLicenseNumber: UNDEFINED,
  };
}

export { computeSuggestedEndDate, generateNewTenant };
