import { Schema } from "@effect/schema";
import type { PropsWithChildren } from "react";
import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { FileInputProps } from "./shared-ds-file-input";
import { FileInput } from "./shared-ds-file-input";

const FileEffectSchema = Schema.instanceOf(File);

type FormFileInputProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<FileInputProps, Form, Name>;

function FormFileInput<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: PropsWithChildren<FormFileInputProps<Form, Name>>) {
  const { form, name, children, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field }) => {
        return (
          <FileInput {...rest} {...field}>
            {children}
          </FileInput>
        );
      }}
    </FormField>
  );
}

export { FileEffectSchema, FormFileInput };
export type { FormFileInputProps };
