/**
 * Edit a GL category in the chart of accounts.
 * HTTP Method: POST
 * Pathname: /accounting/categories/{categoryId}
 * @function editCategory
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIEditCategoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { CategoryFlag } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIEditCategoryPathParams = {
  categoryId: EnderId;
};

type GeneralLedgerAPIEditCategorySearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIEditCategoryBodyParams = {
  accountName?: string | undefined;
  accountNumber?: string | undefined;
  contraCategoryId?: EnderId | undefined;
  flags: CategoryFlag[];
  tenantFriendlyName?: string | undefined;
};

type GeneralLedgerAPIEditCategoryPayload =
  GeneralLedgerAPIEditCategoryPathParams &
    GeneralLedgerAPIEditCategorySearchParams &
    GeneralLedgerAPIEditCategoryBodyParams;

function editCategoryUnsafeEffect(
  payload: GeneralLedgerAPIEditCategoryPayload,
) {
  const { categoryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accounting/categories/${categoryId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function editCategory(
  payload: GeneralLedgerAPIEditCategoryPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    editCategoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { editCategory };
export type {
  GeneralLedgerAPIEditCategoryBodyParams,
  GeneralLedgerAPIEditCategoryPathParams,
  GeneralLedgerAPIEditCategoryPayload,
  GeneralLedgerAPIEditCategorySearchParams,
};
