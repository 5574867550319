/**
 * HTTP Method: POST
 * Pathname: /setCustomFields
 * @function setCustomFields
 * @memberof FactorsAPI
 * @param {FactorsAPISetCustomFieldsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPISetCustomFieldsSearchParams = {
  token?: string | undefined;
};

type FactorsAPISetCustomFieldsBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
  modelId: EnderId;
  modelType: ModelType;
};

type FactorsAPISetCustomFieldsPayload = FactorsAPISetCustomFieldsSearchParams &
  FactorsAPISetCustomFieldsBodyParams;

function setCustomFieldsUnsafeEffect(
  payload: FactorsAPISetCustomFieldsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/setCustomFields",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setCustomFields(
  payload: FactorsAPISetCustomFieldsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setCustomFieldsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setCustomFields };
export type {
  FactorsAPISetCustomFieldsBodyParams,
  FactorsAPISetCustomFieldsPayload,
  FactorsAPISetCustomFieldsSearchParams,
};
