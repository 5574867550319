import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TermsAcceptanceDocumentTypeValues = [
  "ENDER_TERMS",
  "ENDER_PRIVACY",
  "DWOLLA_TERMS",
  "DWOLLA_PRIVACY",
  "TRANSUNION",
] as const;

const TermsAcceptanceDocumentTypeEffectSchema = Schema.Literal(
  ...TermsAcceptanceDocumentTypeValues,
);

type TermsAcceptanceDocumentType = Schema.Schema.Type<
  typeof TermsAcceptanceDocumentTypeEffectSchema
>;

const TermsAcceptanceDocumentTypeEnum = castEnum(
  TermsAcceptanceDocumentTypeEffectSchema,
);

function randomTermsAcceptanceDocumentType(): TermsAcceptanceDocumentType {
  return rand(TermsAcceptanceDocumentTypeValues);
}

export {
  randomTermsAcceptanceDocumentType,
  TermsAcceptanceDocumentTypeEffectSchema,
  TermsAcceptanceDocumentTypeEnum,
  TermsAcceptanceDocumentTypeValues,
};
export type { TermsAcceptanceDocumentType };
