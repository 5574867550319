import { useQuery } from "@tanstack/react-query";
import { Array as A, Option as O, Predicate as P } from "effect";
import { useEffect, useId } from "react";
import { useWatch } from "react-hook-form";

import type { UseFormReturn } from "@ender/form-system/base";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import type { SelectOption } from "@ender/shared/ds/select";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { Tuple } from "@ender/shared/ds/tuple";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type {
  PropertyCatPolicy,
  PropertyDogPolicy,
} from "@ender/shared/generated/ender.model.core.property";
import {
  PropertyCatPolicyEnum,
  PropertyDogPolicyEnum,
} from "@ender/shared/generated/ender.model.core.property";
import type { LeasingServiceListingResponse } from "@ender/shared/generated/ender.service.leasing";
import { cast } from "@ender/shared/types/cast";
import {
  AmenityTypeDisplayEnum,
  CoolingSystemDisplayEnum,
  HeatingSystemDisplayEnum,
  LaundryTypeDisplayEnum,
  ParkingTypeDisplayEnum,
} from "@ender/shared/types/ender-general";

import type { UpdateListingFormInput } from "../listing-page-schema";

const catPolicyData: SelectOption<PropertyCatPolicy>[] = [
  { label: "No Cats Allowed", value: PropertyCatPolicyEnum.NO_CATS_ALLOWED },
  { label: "Cats Allowed", value: PropertyCatPolicyEnum.CATS_ALLOWED },
];

const dogPolicyData: SelectOption<PropertyDogPolicy>[] = [
  { label: "No Dogs Allowed", value: PropertyDogPolicyEnum.NO_DOGS_ALLOWED },
  {
    label: "Small Dogs Allowed",
    value: PropertyDogPolicyEnum.SMALL_DOGS_ALLOWED,
  },
  { label: "Dogs Allowed", value: PropertyDogPolicyEnum.DOGS_ALLOWED },
];

type ListingUnitInfoProps = {
  listing: Pick<LeasingServiceListingResponse, "unit" | "property">;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<UpdateListingFormInput, any, any>;
};

function ListingUnitInfo(props: ListingUnitInfoProps) {
  const { listing, form } = props;
  const { setValue } = form;
  const bathString = `${listing?.unit.fullBaths ?? 0}${
    listing?.unit.halfBaths ? ` Full, ${listing?.unit.halfBaths} Half` : ""
  }`;
  const { unit } = listing ?? {};
  const { data: propertyAmenities = [] } = useQuery({
    enabled: P.isNotNullable(listing?.property.id),
    queryFn: ({ signal }) =>
      PropertiesAPI.getAmenities(
        { propertyId: listing.property.id },
        { signal },
      ),
    queryKey: ["PropertiesAPI.getAmenities", listing?.property.id] as const,
  });

  const [dogPolicy, catPolicy] = useWatch({
    control: form.control,
    name: ["dogPolicy", "catPolicy"],
  });

  const petNumberInputDisabled =
    O.contains(dogPolicy, PropertyDogPolicyEnum.NO_DOGS_ALLOWED) &&
    O.contains(catPolicy, PropertyCatPolicyEnum.NO_CATS_ALLOWED);

  // lazy useEffect
  useEffect(() => {
    if (petNumberInputDisabled) {
      setValue("maxNumPets", O.none());
    }
  }, [setValue, petNumberInputDisabled]);

  const id = useId();
  const specsCardId = `${id}-specs`;
  const amenitiesCardId = `${id}-amenities`;
  const additionalCardId = `${id}-additional-info`;

  return (
    <Stack>
      <H3>Unit Info</H3>
      <Card labelledBy={specsCardId}>
        <Stack>
          <H3 id={specsCardId}>Specs</H3>
          <Grid underline="uneven" spacingY={Spacing.none}>
            <Tuple label="Sqft" value={listing?.unit.sqft} />
            <Tuple label="Beds" value={listing?.unit.bedrooms} />
            <Tuple label="Baths" value={bathString} />
            <Tuple
              label="Dogs"
              value={
                <FormSelect
                  form={form}
                  name="dogPolicy"
                  placeholder="Select Policy"
                  data={dogPolicyData}
                />
              }
            />
            <Tuple
              label="Cats"
              value={
                <FormSelect
                  form={form}
                  name="catPolicy"
                  placeholder="Select Policy"
                  data={catPolicyData}
                />
              }
            />
            <Tuple
              label="Number of Pets Allowed"
              value={
                <FormNumberInput
                  form={form}
                  name="maxNumPets"
                  precision={0}
                  disabled={petNumberInputDisabled}
                />
              }
            />
            <Tuple
              label="Number of Vehicles Allowed"
              value={
                <FormNumberInput
                  form={form}
                  name="maxNumVehicles"
                  precision={0}
                />
              }
            />
          </Grid>
        </Stack>
      </Card>
      <Card labelledBy={amenitiesCardId}>
        <Stack>
          <Stack spacing={Spacing.sm}>
            <H3 id={amenitiesCardId}>Amenities</H3>
            <Text size={FontSize.sm}>
              All amenities of the property are automatically populated from the
              Property Page.
            </Text>
          </Stack>
          <Group>
            {propertyAmenities?.map((amenity) => (
              <Badge color={BadgeColor.slate} key={amenity}>
                {
                  AmenityTypeDisplayEnum[
                    cast<keyof typeof AmenityTypeDisplayEnum>(amenity)
                  ]
                }
              </Badge>
            ))}
            {A.isEmptyArray(propertyAmenities) && "No amenities found"}
          </Group>
          {/* re-enable if listings have separate amenities from the property
        {listing?.amenities?.map((amenity) => (
          <div className="chip" key={amenity}>
            {amenity.replace("_", " ").toLowerCase()}
          </div>
        ))}
        {!listing?.amenities || (listing.amenities.length === 0 && "No amenities found")} */}

          <Grid underline="uneven" spacingY={Spacing.none}>
            <Tuple
              label="Heating"
              value={
                (unit?.heatingSystem &&
                  HeatingSystemDisplayEnum[unit.heatingSystem]) ||
                "--"
              }
            />
            <Tuple
              label="Cooling"
              value={
                (unit?.coolingSystem &&
                  CoolingSystemDisplayEnum[unit.coolingSystem]) ||
                "--"
              }
            />
            <Tuple
              label="Laundry"
              value={
                (unit?.laundryType &&
                  LaundryTypeDisplayEnum[unit.laundryType]) ||
                "--"
              }
            />
            <Tuple
              label="Parking"
              value={
                (unit?.parkingType &&
                  ParkingTypeDisplayEnum[unit.parkingType]) ||
                "--"
              }
            />
          </Grid>
        </Stack>
      </Card>
      <Card labelledBy={additionalCardId}>
        <Stack>
          <H3 id={additionalCardId}>Additional Information</H3>
          <FormTextarea
            form={form}
            name="appliances"
            label="Unit Appliances"
            minRows={4}
            maxRows={8}
          />
          <FormTextarea
            form={form}
            name="hoaInformation"
            label="HOA Information"
            minRows={4}
            maxRows={8}
          />
        </Stack>
      </Card>
    </Stack>
  );
}

export { ListingUnitInfo };
export type { ListingUnitInfoProps };
