import type { EnderId } from "@ender/shared/core";

function openCheck(moneyTransferId: EnderId) {
  window.open(
    `/checks/${moneyTransferId}/print`,
    "_blank",
    "noopener,noreferrer",
  );
}

function openCheckForBatch(checkBatchId: EnderId) {
  window.open(
    `/checks/${checkBatchId}/print-batch`,
    "_blank",
    "noopener,noreferrer",
  );
}

export { openCheck, openCheckForBatch };
