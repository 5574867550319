import { Predicate as P, String as S } from "effect";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { LocalDate$ } from "@ender/shared/core";
import type { GetPayablesReceivablesResponsePayableReceivable } from "@ender/shared/generated/com.ender.middle.response";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import type { UseTableSortingResult } from "@ender/shared/ui/table-tanstack";
import {
  useTable,
  useTableColumnVisibility,
  useTableRowSelection,
} from "@ender/shared/ui/table-tanstack";

import type { InvoiceTableTabs } from "../invoice-table-tabs.types";
import { InvoiceTableTabsEnum } from "../invoice-table-tabs.types";
import type { InvoiceRow, InvoicesTableMeta } from "../invoices-table-types";
import { columns } from "./invoices-table-columns";

function getToDisplayForPayables(toDisplay: string, toParty: string) {
  if (toParty === "EXTERNAL" && S.isEmpty(toDisplay)) {
    return "External Merchant";
  }
  return toDisplay;
}

const INITIAL_NUM_INVOICES_DISPLAY = 10;

const TabsWithRowSelectionHidden = new Set<InvoiceTableTabs>([
  InvoiceTableTabsEnum.ALL,
  InvoiceTableTabsEnum.PAID,
  InvoiceTableTabsEnum.REJECTED,
]);
const TabsWithPaidDateColumn = new Set<InvoiceTableTabs>([
  InvoiceTableTabsEnum.PAID,
  InvoiceTableTabsEnum.ALL,
]);

type UseInvoicesTableParams = {
  fetchNextPage: () => Promise<unknown>;
  invoiceType: InvoiceInvoiceType;
  isLoading: boolean;
  payablesReceivables: GetPayablesReceivablesResponsePayableReceivable[];
  sorting: UseTableSortingResult;
  tab: InvoiceTableTabs;
  totalResults?: number;
};

function useInvoicesTable({
  fetchNextPage,
  invoiceType,
  isLoading,
  payablesReceivables,
  sorting,
  tab,
  totalResults,
}: UseInvoicesTableParams) {
  const isPayables = invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;

  const invoicesTableRows: InvoiceRow[] = useMemo(() => {
    return payablesReceivables.map((entry) => {
      const {
        amount,
        closedPeriodMessage,
        externalInvoiceId,
        fromDisplay,
        invoiceId,
        ledgerDate,
        dueDate,
        paidDate,
        paymentDisplay,
        periodDisplay,
        propertyDisplay,
        statusDisplay,
        toDisplay,
        toParty,
      } = entry;

      return {
        amount,
        closedPeriodMessage,
        counterPartyName: isPayables
          ? getToDisplayForPayables(toDisplay, toParty)
          : fromDisplay,
        date: ledgerDate,
        dueDate: dueDate,
        externalInvoiceId,
        firmName: isPayables ? fromDisplay : toDisplay,
        id: invoiceId,
        paidDate: P.isNotNullable(paidDate)
          ? LocalDate$.of(paidDate).toJSON()
          : NULL,
        paymentMethodDisplay: paymentDisplay,
        periodDisplay,
        propertyDisplay,
        statusDisplay,
        toParty,
      };
    });
  }, [payablesReceivables, isPayables]);

  const _rowSelection = useTableRowSelection<InvoiceRow, InvoicesTableMeta>({
    // Do not allow Tenant Refund rows to be selected, as the payable toParty will be the LEASE itself, see ENDER-22873
    enableRowSelection: (row) => row.original.toParty !== PartyEnum.LEASE,
  });
  const { onRowSelectionChange } = _rowSelection;
  const hideRowSelection = TabsWithRowSelectionHidden.has(tab);
  // TODO rowSelection = UNDEFINED will result in table.getSelectedRowModel().rows crashing; is this a table system defect?
  const rowSelection = useMemo(
    () => (hideRowSelection ? UNDEFINED : _rowSelection),
    [hideRowSelection, _rowSelection],
  );

  const isTabAllOrPaid = TabsWithPaidDateColumn.has(tab);
  const columnVisibility = useTableColumnVisibility({
    initialVisibility: { paidDate: isTabAllOrPaid, period: isPayables },
  });
  const columnVisibilityRef = useRef(columnVisibility);
  columnVisibilityRef.current = columnVisibility;
  // when tab changes, reactively update visibility of paidDate column
  useEffect(() => {
    columnVisibilityRef.current?.onColumnVisibilityChange((prev) => ({
      ...prev,
      accountingPeriod: isPayables,
      paidDate: isTabAllOrPaid,
    }));
  }, [columnVisibilityRef, isPayables, isTabAllOrPaid]);

  const unselectAll = useCallback(() => {
    onRowSelectionChange({});
  }, [onRowSelectionChange]);

  const table = useTable<InvoiceRow, InvoicesTableMeta>({
    columnVisibility: columnVisibilityRef.current,
    columns,
    data: invoicesTableRows,
    fetchNextPage,
    getRowId: (row: InvoiceRow) => row.id,
    isLoading,
    meta: {
      counterPartyNameHeader: isPayables ? "Payee" : "Payer",
      invoiceType,
    },
    rowSelection,
    sorting,
    title: "Invoices",
    totalResults,
  });

  return { table, unselectAll };
}

export {
  INITIAL_NUM_INVOICES_DISPLAY,
  TabsWithRowSelectionHidden,
  useInvoicesTable,
};
