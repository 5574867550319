import { cva } from "class-variance-authority";
import type { ForwardedRef, ReactNode } from "react";
import { forwardRef, useId } from "react";

import { Align, Justify } from "../../../flex/src";
import { Group } from "../../../group/src";

type TupleProps = {
  /**
   * the label element of the tuple
   */
  label?: ReactNode;
  /**
   * the value element of the tuple
   */
  value?: ReactNode;
  filled?: boolean;
  borderless?: boolean;
};

const TupleVariantGenerator = cva(
  ["px-4 py-2.5 text-slate-900 display:inline-grid"],
  {
    compoundVariants: [],
    defaultVariants: {
      borderless: false,
      filled: false,
    },
    variants: {
      borderless: {
        false: "border-slate-200",
        true: "border-transparent",
      },
      filled: {
        false: "border-t",
        true: "border rounded-md bg-slate-50",
      },
    },
  },
);

function Tuple(props: TupleProps, ref: ForwardedRef<HTMLDivElement>) {
  const { label, value } = props;
  const id = useId();
  return (
    <dl className={TupleVariantGenerator(props)}>
      <Group justify={Justify.between} align={Align.center} ref={ref} noWrap>
        <dt id={id} className="text-slate-500">
          {label}
        </dt>
        <dd aria-labelledby={id} className="font-medium">
          {value}
        </dd>
      </Group>
    </dl>
  );
}
const ForwardedTuple = forwardRef<HTMLDivElement, TupleProps>(Tuple);

export { ForwardedTuple as Tuple };
export type { TupleProps };
