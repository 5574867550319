import { useQuery } from "@tanstack/react-query";
import type { Option as O } from "effect";
import { Array as A } from "effect";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, LocalDate, Money } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { FontSize } from "@ender/shared/ds/text";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { PeriodFilterFilterTypeEnum } from "@ender/shared/generated/ender.api.model";
import type { Party } from "@ender/shared/generated/ender.model.payments";
import { useAccountingFiltersStore } from "@ender/shared/stores/accounting-filters-store";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { LoadingIndicator } from "@ender/shared/utils/general";

import styles from "./balances.module.css";

type BalanceProps = {
  label: string;
  value: O.Option<Money$.Money>;
  isLoading: boolean;
};

function Balance({ label, value, isLoading }: BalanceProps) {
  return (
    <div className={styles.bankingAccountCardBalance}>
      <span>{label}</span>
      <span className="right">
        {isLoading ? (
          <LoadingIndicator message="Loading..." size={FontSize.md} />
        ) : (
          <MoneyDisplay showSymbol value={value} />
        )}
      </span>
    </div>
  );
}

type BalancesProps = {
  endDate?: LocalDate;
  id: EnderId;
  ownerType?: Party;
  startDate?: LocalDate;
  propertyFilterValue: EnderId[];
};

function Balances({
  endDate,
  id,
  ownerType,
  startDate,
  propertyFilterValue,
}: BalancesProps) {
  const isValidDateRange = EnderDate.isValidDateRange(startDate, endDate);
  const { periodFilter } = useAccountingFiltersStore();

  const {
    data: {
      endingAdjustedBalance,
      endingStatementBalance,
      glCashCategoryEndingBalance,
    } = {},
    isFetching,
  } = useQuery({
    queryKey: [
      "BankingAPI.getTransactions",
      id,
      periodFilter,
      startDate,
      endDate,
      propertyFilterValue,
    ] as const,
    queryFn: ({ signal }) =>
      BankingAPI.getTransactions(
        {
          bankAccountId: id,
          inclusiveEndDate: endDate,
          periodFilter: A.isNonEmptyArray(periodFilter)
            ? {
                customFilter: periodFilter.map((p) =>
                  LocalDate$.of(p).toJSON(),
                ),
                type: PeriodFilterFilterTypeEnum.CUSTOM,
              }
            : UNDEFINED,
          propertyIds: propertyFilterValue,
          startDate,
          includeRows: false,
        },
        { signal },
      ),
    enabled:
      isValidDateRange &&
      (ownerType === ModelTypeEnum.PROPERTY_MANAGER ||
        ownerType === ModelTypeEnum.FIRM),
    select: (data): Record<string, Money | undefined> => ({
      endingAdjustedBalance: data.bankDetails.endingAdjustedBalance,
      endingStatementBalance: data.bankDetails.endingStatementBalance,
      glCashCategoryEndingBalance: data.glCashCategoryEndingBalance,
    }),
    staleTime: 900000,
  });

  return (
    <div>
      <Balance
        label="Statement Balance: "
        value={Money$.parse(endingStatementBalance)}
        isLoading={isFetching}
      />
      <Balance
        label="Adjusted Balance: "
        value={Money$.parse(endingAdjustedBalance)}
        isLoading={isFetching}
      />
      <Balance
        label="GL Cash Balance: "
        value={Money$.parse(glCashCategoryEndingBalance)}
        isLoading={isFetching}
      />
    </div>
  );
}

export { Balance, Balances };
