/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/rejectv2
 * @function rejectApplicationV2
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIRejectApplicationV2Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIRejectApplicationV2PathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIRejectApplicationV2SearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIRejectApplicationV2Payload =
  ApplicationsAPIRejectApplicationV2PathParams &
    ApplicationsAPIRejectApplicationV2SearchParams;

function rejectApplicationV2UnsafeEffect(
  payload: ApplicationsAPIRejectApplicationV2Payload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/rejectv2`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function rejectApplicationV2(
  payload: ApplicationsAPIRejectApplicationV2Payload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    rejectApplicationV2UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { rejectApplicationV2 };
export type {
  ApplicationsAPIRejectApplicationV2PathParams,
  ApplicationsAPIRejectApplicationV2Payload,
  ApplicationsAPIRejectApplicationV2SearchParams,
};
