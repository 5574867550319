import type { Undefined } from "@ender/shared/constants/general";
import { Tuple } from "@ender/shared/ds/tuple";
import {
  UploadRailHeader,
  UploadRailHeaderTuple,
} from "@ender/shared/ui/upload-rail";

type InvoiceUploadRailHeaderProps = {
  isPayableInvoice?: boolean | Undefined;
  isReceivableInvoice?: boolean | Undefined;
};

function InvoiceUploadRailHeader(props: InvoiceUploadRailHeaderProps) {
  const { isPayableInvoice, isReceivableInvoice } = props;
  const sampleFileName = isReceivableInvoice
    ? "/sample-receivable-invoice-upload.csv"
    : "/sample-payable-invoice-upload.csv";
  return (
    <UploadRailHeader
      header="Upload one or multiple invoices as a single .csv file."
      sampleFileUrl={sampleFileName}>
      <>
        {isReceivableInvoice ? (
          <UploadRailHeaderTuple
            label="Payer"
            value="Valid Vendor name"
            infoTooltip="Use “External Merchant” if vendor is not in the system."
          />
        ) : (
          <UploadRailHeaderTuple
            label="Payee"
            value="Valid Vendor name"
            infoTooltip="Use “External Merchant” if vendor is not in the system."
          />
        )}
        <Tuple label="GL Account" value="Valid GL account number" />
        {isReceivableInvoice ? (
          <>
            <Tuple label="Charge Description" value="Text string" />
            <Tuple label="Charge Date" value="YYYY-MM-DD" />
          </>
        ) : (
          <>
            <Tuple label="Invoice Description" value="Text string" />
            <Tuple label="Invoice Date" value="YYYY-MM-DD" />
          </>
        )}
        <Tuple label="Period" value="YYYY-MM" />
        <Tuple label="Amount" value="Dollar amount, no symbols" />
        <Tuple label="Property Code" value="Valid Property Code" />
        {isReceivableInvoice ? (
          <UploadRailHeaderTuple
            label="Charge Number"
            value="Alphanumeric string"
            infoTooltip="If you leave this blank Ender will generate a number for you."
          />
        ) : (
          <UploadRailHeaderTuple
            label="Invoice Number"
            value="Alphanumeric string"
            infoTooltip="If you leave this blank Ender will generate a number for you."
          />
        )}
        <UploadRailHeaderTuple
          label="Purchase Order Number"
          value="Numeric String, four-digit value"
          infoTooltip="Multiple entries are separated by commas"
        />
        <UploadRailHeaderTuple
          label="Type"
          value="Valid Type"
          infoTooltip="If you leave this blank it will default to General."
        />
        {isPayableInvoice && (
          <>
            <Tuple label="Category" value="Valid Category" />
            <Tuple label="Subcategory" value="Valid Subcategory" />
          </>
        )}
      </>
    </UploadRailHeader>
  );
}

export { InvoiceUploadRailHeader };
