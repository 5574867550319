/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/schoolDistricts
 * @function getSchoolDistricts
 * @memberof BuyAPI
 * @param {BuyAPIGetSchoolDistrictsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetSchoolDistrictsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetSchoolDistrictsResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetSchoolDistrictsPathParams = {
  dealId: EnderId;
};

type BuyAPIGetSchoolDistrictsSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetSchoolDistrictsPayload = BuyAPIGetSchoolDistrictsPathParams &
  BuyAPIGetSchoolDistrictsSearchParams;

function getSchoolDistrictsUnsafeEffect(
  payload: BuyAPIGetSchoolDistrictsPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetSchoolDistrictsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetSchoolDistrictsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/schoolDistricts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSchoolDistricts(
  payload: BuyAPIGetSchoolDistrictsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetSchoolDistrictsResponse> {
  return F.pipe(
    payload,
    getSchoolDistrictsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSchoolDistricts };
export type {
  BuyAPIGetSchoolDistrictsPathParams,
  BuyAPIGetSchoolDistrictsPayload,
  BuyAPIGetSchoolDistrictsSearchParams,
};
