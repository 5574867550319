/**
 * HTTP Method: POST
 * Pathname: /text-templates
 * @function createTemplate
 * @memberof TemplatesAPI
 * @param {TemplatesAPICreateTemplatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TextTemplate>}
 */
import { Effect, Function as F } from "effect";

import type {
  TextTemplate,
  TextTemplateTemplateType,
} from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TemplatesAPICreateTemplateSearchParams = {
  token?: string | undefined;
};

type TemplatesAPICreateTemplateBodyParams = {
  body: string;
  name: string;
  subject: string;
  type: TextTemplateTemplateType;
};

type TemplatesAPICreateTemplatePayload =
  TemplatesAPICreateTemplateSearchParams & TemplatesAPICreateTemplateBodyParams;

function createTemplateUnsafeEffect(
  payload: TemplatesAPICreateTemplatePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TextTemplate>({
        body,
        decode: unsafeDecodeJsonResponse<TextTemplate>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/text-templates",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createTemplate(
  payload: TemplatesAPICreateTemplatePayload,
  options?: { signal?: AbortSignal },
): Promise<TextTemplate> {
  return F.pipe(
    payload,
    createTemplateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createTemplate };
export type {
  TemplatesAPICreateTemplateBodyParams,
  TemplatesAPICreateTemplatePayload,
  TemplatesAPICreateTemplateSearchParams,
};
