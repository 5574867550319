import { IconSearch } from "@tabler/icons-react";
import { Array as A, Predicate as P } from "effect";
import { useMemo, useState } from "react";

import type { Null } from "@ender/shared/constants/general";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2, H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import type {
  LeaseSerializerLeaseContact,
  LeaseSerializerLeaseResponse,
} from "@ender/shared/generated/ender.arch.serializer.leasing";
import {
  EnderTableTanstackV3,
  useTable,
  useTableSorting,
} from "@ender/shared/ui/table-tanstack";

import type { TaggedLeases } from "../unit-page-lease.types";
import { columns } from "./previous-leases-table.columns";

function PreviousLeasesTable({
  leases,
}: {
  leases?: LeaseSerializerLeaseResponse[] | Null;
}) {
  const sorting = useTableSorting();

  const table = useTable<LeaseSerializerLeaseResponse>({
    data: leases ?? [],
    columns,
    sorting,
  });

  return <EnderTableTanstackV3 table={table} />;
}

type PreviousLeasesProps = {
  leases: TaggedLeases;
};

function PreviousLeases(props: PreviousLeasesProps) {
  const { leases } = props;

  const [searchQuery, setSearchQuery] = useState("");

  const leaseList = useMemo(() => {
    if (searchQuery === "") {
      return leases?.past;
    }

    return leases?.past?.filter((lease: LeaseSerializerLeaseResponse) => {
      let allNames = "";
      lease.contacts?.forEach(
        (contact: LeaseSerializerLeaseContact) =>
          (allNames += `${contact.name.toLowerCase()} `),
      );

      return allNames.includes(searchQuery.toLowerCase());
    });
  }, [leases?.past, searchQuery]);

  if (A.isEmptyArray(leases?.past ?? []) || P.isNullable(leases?.past)) {
    return <H3>No previous leases to show</H3>;
  }

  return (
    <Stack spacing={Spacing.md}>
      <H2>Previous Leases</H2>
      <Group>
        <TextInput
          placeholder="Search Tenants"
          value={searchQuery}
          leftSection={<IconSearch />}
          onChange={setSearchQuery}
        />
      </Group>
      <PreviousLeasesTable leases={leaseList} />
    </Stack>
  );
}

export { PreviousLeases };
