import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApprovableApprovalStatusValues = ["NEW", "APPROVED", "REJECTED"] as const;

const ApprovableApprovalStatusEffectSchema = Schema.Literal(
  ...ApprovableApprovalStatusValues,
);

type ApprovableApprovalStatus = Schema.Schema.Type<
  typeof ApprovableApprovalStatusEffectSchema
>;

const ApprovableApprovalStatusEnum = castEnum(
  ApprovableApprovalStatusEffectSchema,
);

function randomApprovableApprovalStatus(): ApprovableApprovalStatus {
  return rand(ApprovableApprovalStatusValues);
}

export {
  ApprovableApprovalStatusEffectSchema,
  ApprovableApprovalStatusEnum,
  ApprovableApprovalStatusValues,
  randomApprovableApprovalStatus,
};
export type { ApprovableApprovalStatus };
