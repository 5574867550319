import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationGroupCreditRecommendationValues = [
  "ACCEPT",
  "LOW_ACCEPT",
  "CONDITIONAL",
  "DECLINE",
  "REFER",
  "PENDING",
  "ERROR",
] as const;

const ApplicationGroupCreditRecommendationEffectSchema = Schema.Literal(
  ...ApplicationGroupCreditRecommendationValues,
);

type ApplicationGroupCreditRecommendation = Schema.Schema.Type<
  typeof ApplicationGroupCreditRecommendationEffectSchema
>;

const ApplicationGroupCreditRecommendationEnum = castEnum(
  ApplicationGroupCreditRecommendationEffectSchema,
);

function randomApplicationGroupCreditRecommendation(): ApplicationGroupCreditRecommendation {
  return rand(ApplicationGroupCreditRecommendationValues);
}

export {
  ApplicationGroupCreditRecommendationEffectSchema,
  ApplicationGroupCreditRecommendationEnum,
  ApplicationGroupCreditRecommendationValues,
  randomApplicationGroupCreditRecommendation,
};
export type { ApplicationGroupCreditRecommendation };
