import { useMantineTheme } from "@mantine/core";
import { Predicate as P } from "effect";
import { useMemo } from "react";

import { NULL } from "@ender/shared/constants/general";
import { useViewportSize } from "@ender/shared/hooks/use-viewport-size";

import { ScreenRangeEnum } from "./types";

function useScreenRange() {
  const theme = useMantineTheme();
  const { width } = useViewportSize();

  const screenRanges = useMemo(
    () => [
      {
        maxPx: theme.breakpoints.sm,
        minPx: 1,
        name: ScreenRangeEnum.SMALL,
      },
      {
        maxPx: theme.breakpoints.md,
        minPx: theme.breakpoints.sm,
        name: ScreenRangeEnum.MEDIUM,
      },
      {
        maxPx: Number.POSITIVE_INFINITY,
        minPx: theme.breakpoints.md,
        name: ScreenRangeEnum.LARGE,
      },
    ],
    [theme.breakpoints.md, theme.breakpoints.sm],
  );

  return useMemo(() => {
    if (!width) {
      return null;
    }

    const currentRange = screenRanges.find((range) => {
      return width >= range.minPx && width <= range.maxPx;
    });

    return P.isNotNullable(currentRange) ? currentRange.name : NULL;
  }, [width, screenRanges]);
}

export { useScreenRange };
