import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BatchedInvoiceActionResponseStatusValues = [
  "SUCCESS",
  "FAILURE",
] as const;

const BatchedInvoiceActionResponseStatusEffectSchema = Schema.Literal(
  ...BatchedInvoiceActionResponseStatusValues,
);

type BatchedInvoiceActionResponseStatus = Schema.Schema.Type<
  typeof BatchedInvoiceActionResponseStatusEffectSchema
>;

const BatchedInvoiceActionResponseStatusEnum = castEnum(
  BatchedInvoiceActionResponseStatusEffectSchema,
);

function randomBatchedInvoiceActionResponseStatus(): BatchedInvoiceActionResponseStatus {
  return rand(BatchedInvoiceActionResponseStatusValues);
}

export {
  BatchedInvoiceActionResponseStatusEffectSchema,
  BatchedInvoiceActionResponseStatusEnum,
  BatchedInvoiceActionResponseStatusValues,
  randomBatchedInvoiceActionResponseStatus,
};
export type { BatchedInvoiceActionResponseStatus };
