import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ReportFilterOperatorValues = [
  "CONTAINS_ANY",
  "IN",
  "NOT_IN",
  "LESS_THAN",
  "GREATER_THAN",
  "LESS_THAN_OR_EQUAL",
  "GREATER_THAN_OR_EQUAL",
] as const;

const ReportFilterOperatorEffectSchema = Schema.Literal(
  ...ReportFilterOperatorValues,
);

type ReportFilterOperator = Schema.Schema.Type<
  typeof ReportFilterOperatorEffectSchema
>;

const ReportFilterOperatorEnum = castEnum(ReportFilterOperatorEffectSchema);

function randomReportFilterOperator(): ReportFilterOperator {
  return rand(ReportFilterOperatorValues);
}

export {
  randomReportFilterOperator,
  ReportFilterOperatorEffectSchema,
  ReportFilterOperatorEnum,
  ReportFilterOperatorValues,
};
export type { ReportFilterOperator };
