import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P, String as S } from "effect";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FontWeight, Text } from "@ender/shared/ds/text";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import { TasksAPI } from "@ender/shared/generated/ender.api.misc";
import type { TaskSearchResponseTaskSearchResponseRow } from "@ender/shared/generated/ender.api.misc.response";
import type { TaskTaskStatusGrouping } from "@ender/shared/generated/ender.model.task";
import {
  TaskAssignmentAssignmentStatusEnum,
  TaskTaskStatusGroupingEnum as TaskStatusGroupingEnum,
  TaskTaskStatusGroupingValues as TaskStatusGroupingValues,
  TaskTaskTypeEnum as TaskTypeEnum,
} from "@ender/shared/generated/ender.model.task";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { Table } from "@ender/shared/ui/table-tanstack";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { NewTaskForm } from "@ender/widgets/maintenance";

import { WorkOrderListItem } from "./work-order-list-item";
import type { TaskTypeFilterKey } from "./work-orders-filters";
import { WorkOrdersFilters } from "./work-orders-filters";

const PAGE_SIZE = 1000;

const typeMap = new Map<
  TaskTypeFilterKey,
  { heading: string; errorPart: string }
>([
  ["ALL", { errorPart: "tasks", heading: "All Tasks" }],
  [
    TaskTypeEnum.INTERNAL,
    { errorPart: "internal tasks", heading: "Internal Tasks" },
  ],
  [
    TaskTypeEnum.WORK_ORDER,
    { errorPart: "work orders", heading: "Work Orders" },
  ],
]);

type WorkOrder = TaskSearchResponseTaskSearchResponseRow;

function byKeyword(keyword: string): (workOrder: WorkOrder) => boolean {
  keyword = keyword.trim();

  if (S.isEmpty(keyword)) {
    return () => true;
  }

  return (workOrder: WorkOrder) => {
    const { internalUserAssignee, vendorAssignee } = workOrder;
    const vendorAccepted =
      vendorAssignee?.assignmentStatus ===
      TaskAssignmentAssignmentStatusEnum.ACCEPTED;
    const taskAssigneesString =
      P.isNotNullable(vendorAssignee) && vendorAccepted
        ? vendorAssignee.name
        : (internalUserAssignee?.displayName ?? "");
    return Boolean(
      taskAssigneesString.toLowerCase().includes(keyword.toLowerCase()),
    );
  };
}

function getWorkOrdersEmptyMessage(
  modelType: ModelType,
  filters: {
    type: TaskTypeFilterKey;
    keyword: string;
    status: TaskTaskStatusGrouping;
  },
) {
  const { type, keyword, status } = filters;

  return `There are no ${status.toUpperCase()} ${typeMap.get(type)?.errorPart ?? "<UNKNOWN_TYPE>"} for this ${
    modelType === ModelTypeEnum.PROPERTY ? "property" : "unit"
  }${keyword ? " matching the assignee keyword" : ""}.`;
}

function sortWorkOrdersByStatusGrouping(a: WorkOrder, b: WorkOrder): number {
  return (
    TaskStatusGroupingValues.indexOf(a.statusGrouping) -
    TaskStatusGroupingValues.indexOf(b.statusGrouping)
  );
}

type WorkOrdersProps = {
  modelType: ModelType;
  modelId?: EnderId;
};

function WorkOrders(props: WorkOrdersProps) {
  const { modelType, modelId } = props;
  const [newTaskModalOpened, newTaskModalHandlers] = useBoolean(false);
  const [taskTypeFilter, setTaskTypeFilter] =
    useState<TaskTypeFilterKey>("ALL");
  const [keyword, setKeyword] = useState<string>("");
  const [status, setStatus] = useState<TaskTaskStatusGrouping>(
    TaskStatusGroupingEnum.OPEN,
  );

  const unitId = modelType === ModelTypeEnum.UNIT ? modelId : undefined;

  const { data: propertyId } = useQuery({
    enabled: modelType !== ModelTypeEnum.PROPERTY,
    initialData: modelId,
    queryFn: () =>
      unitId &&
      UnitsAPI.getUnitDetails({ unitId }).then(({ property }) => property.id),
    queryKey: ["propertyId", modelId],
  });

  const {
    data: workOrders = [],
    isSuccess,
    isLoading,
    refetch: refetchWorkOrders,
  } = useQuery({
    queryFn: ({ signal }) =>
      TasksAPI.searchTasks(
        {
          filterBy: {
            assigneeIds: [],
            costEstimateApprovalStatus: [],
            firmIds: [],
            keyword: "",
            marketIds: [],
            ownershipGroupIds: [],
            priorities: [],
            propertyIds: [propertyId].filter((val) => !P.isNullable(val)),
            shortNames: [],
            statusGroupings: [status],
            statuses: [],
            types: taskTypeFilter === "ALL" ? [] : [taskTypeFilter],
            unitIds: [unitId].filter((val) => !P.isNullable(val)),
            vendorIds: [],
          },
          limit: PAGE_SIZE,
          sortBy: [],
        },
        { signal },
      ),
    queryKey: [
      "TasksAPI.searchTasks",
      taskTypeFilter,
      propertyId,
      unitId,
      status,
    ] as const,
    select: (data) => data.results.sort(sortWorkOrdersByStatusGrouping),
  });
  const history = useHistory();
  const handleSuccess = (taskId: EnderId) => {
    refetchWorkOrders();
    newTaskModalHandlers.setFalse();
    showSuccessNotification({
      title: "Work order was successfully created!",
      message: (
        <Group spacing={Spacing.md} justify={Justify.center}>
          <Button onClick={newTaskModalHandlers.setTrue}>Create Another</Button>
          <Button onClick={() => history.push(`/tasks-v2/${taskId}`)}>
            View Work Order
          </Button>
        </Group>
      ),
    });
  };

  const visibleWorkOrders = useMemo(
    () => workOrders.filter(byKeyword(keyword)),
    [keyword, workOrders],
  );

  if (!isLoading && !isSuccess) {
    return NULL;
  }

  return (
    <Stack>
      <Modal
        size={ModalSize.sm}
        title="Create New Task"
        opened={newTaskModalOpened}
        onClose={newTaskModalHandlers.setFalse}>
        <NewTaskForm
          propertyId={propertyId}
          unitId={unitId}
          onSuccess={handleSuccess}
        />
      </Modal>
      <H2>{typeMap.get(taskTypeFilter)?.heading ?? "<UNKNOWN_TYPE>"}</H2>
      <Group justify={Justify.between}>
        <WorkOrdersFilters
          type={{ onChange: setTaskTypeFilter, value: taskTypeFilter }}
          status={{ onChange: setStatus, value: status }}
          keyword={{ onChange: setKeyword, value: keyword }}
        />
        <Stack>
          <ActionIcon
            aria-label="New Work Order"
            tooltip="New Work Order"
            onClick={newTaskModalHandlers.setTrue}>
            <IconPlus />
          </ActionIcon>
        </Stack>
      </Group>
      {isLoading && <LoadingSpinner />}
      {visibleWorkOrders.length === 0 && (
        <Text weight={FontWeight.medium}>
          {isSuccess &&
            getWorkOrdersEmptyMessage(modelType, {
              keyword,
              status,
              type: taskTypeFilter,
            })}
        </Text>
      )}
      {A.isNonEmptyArray(visibleWorkOrders) && (
        <Table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Type</th>
              <th>Status</th>
              {status === TaskStatusGroupingEnum.CLOSED && (
                <th>Close Reason</th>
              )}
              <th>Created</th>
              <th>Description</th>
              <th>Assigned</th>
              <th className="right">Invoices</th>
            </tr>
          </thead>
          <tbody>
            {visibleWorkOrders.map((workOrder: WorkOrder) => (
              <WorkOrderListItem key={workOrder.id} workOrder={workOrder} />
            ))}
          </tbody>
        </Table>
      )}
    </Stack>
  );
}

export { WorkOrders };
export type { WorkOrder };
