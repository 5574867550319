import { formatWithOptions } from "date-fns/fp";
import { enUS } from "date-fns/locale";
import { Option as O, pipe } from "effect";

import { LocalDate$ } from "@ender/shared/core";

const SLASH_STRING_FORMAT = "MM/dd/yy";

const slashStringFormat = formatWithOptions(
  { locale: enUS },
  SLASH_STRING_FORMAT,
);

/**
 * Converts LocalDate$ to a string divided by slashes and the last two digits of the year"
 * @returns {string} Formatted as "MM/dd/yy"
 * @example "11/13/24"
 */
function toSlashString(input: LocalDate$.LocalDate | string): string {
  const localDate = LocalDate$.parse(input);

  return pipe(
    localDate,
    O.map((date) => slashStringFormat(date.toDate())),
    O.getOrThrow,
  );
}

export { toSlashString };
