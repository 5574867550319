// See: FilesClient::toJson
import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";

import { UserSchema } from "./user";

// See Also: WebserverFilesAPI::getFiles
const UploadedFileSchema = z.object({
  author: UserSchema.optional(),
  authorId: EnderIdSchema.optional(),
  filename: z.string(),
  id: EnderIdSchema,
  name: z.string(),
  path: z.string(),
  s3Url: z.string(),
  tags: z.record(z.string(), z.string()).optional(),
  timestamp: z.string(),
  uuid: z.string().optional(),
});

type UploadedFile = z.infer<typeof UploadedFileSchema>;

export { UploadedFileSchema };
export type { UploadedFile };
