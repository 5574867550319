import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TenantLedgerReportLedgerEntryTenantLedgerEventTypeValues = [
  "PAYMENT",
  "REVERSAL",
  "REFUND",
  "CREDIT",
  "CHARGE",
  "PREPAYMENT_ALLOCATION",
] as const;

const TenantLedgerReportLedgerEntryTenantLedgerEventTypeEffectSchema =
  Schema.Literal(...TenantLedgerReportLedgerEntryTenantLedgerEventTypeValues);

type TenantLedgerReportLedgerEntryTenantLedgerEventType = Schema.Schema.Type<
  typeof TenantLedgerReportLedgerEntryTenantLedgerEventTypeEffectSchema
>;

const TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum = castEnum(
  TenantLedgerReportLedgerEntryTenantLedgerEventTypeEffectSchema,
);

function randomTenantLedgerReportLedgerEntryTenantLedgerEventType(): TenantLedgerReportLedgerEntryTenantLedgerEventType {
  return rand(TenantLedgerReportLedgerEntryTenantLedgerEventTypeValues);
}

export {
  randomTenantLedgerReportLedgerEntryTenantLedgerEventType,
  TenantLedgerReportLedgerEntryTenantLedgerEventTypeEffectSchema,
  TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum,
  TenantLedgerReportLedgerEntryTenantLedgerEventTypeValues,
};
export type { TenantLedgerReportLedgerEntryTenantLedgerEventType };
