import type { TransformedValues } from "@mantine/form";
import { IconInfoCircle } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { uploadFiles } from "@ender/shared/api/files";
// import type { Null } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { DateInput } from "@ender/shared/ds/date-input";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { useForm } from "@ender/shared/forms/hooks/general";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { GetDealResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import type { DealDealSource } from "@ender/shared/generated/com.ender.buy.model.misc";
import { DealDealSourceValues } from "@ender/shared/generated/com.ender.buy.model.misc";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { PropertyHOAFeeFrequencyValues } from "@ender/shared/generated/ender.model.core.property";

import { MoneyInput } from "@ender/shared/ds/money-input";
import { FileUploadForm } from "@ender/shared/ui/modals";
import { Select } from "@ender/shared/ui/select";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { capitalize } from "@ender/shared/utils/string";

const DealSourceDisplayEnum: Record<DealDealSource, string> = {
  AUCTION_COM: "Auction.com",
  DR_HORTON: "DR Horton",
  FLIPOS: "FlipOS",
  LENNAR: "Lennar",
  LGI: "LGI",
  MARK_SPAIN: "Mark Spain",
  MLS: "MLS",
  OFFERPAD: "OfferPad",
  OPENDOOR: "OpenDoor",
  ORCHARD: "Orchard",
  REDFIN: "Redfin",
  SELL2RENT: "Sell2Rent",
  SUNDAE: "Sundae",
  SWIFT: "Swift",
  YELLOWBIRD: "YellowBird",
  ZILLOW: "Zillow",
} as const;

const dealSourceOptions = DealDealSourceValues.map((value) => ({
  label: DealSourceDisplayEnum[value],
  value,
}));

const hoaFrequencyOptions = PropertyHOAFeeFrequencyValues.map((value) => ({
  label: capitalize(value),
  value,
}));

type SystemFieldsProps = {
  deal: GetDealResponse;
  refetchDeal: () => unknown;
};

function SystemFields({ deal, refetchDeal }: SystemFieldsProps) {
  const modelType = ModelTypeEnum.DEAL;
  const modelId = deal.id;
  const [priceAmendmentData, setPriceAmendmentData] = useState<{
    finalPurchasePrice: O.Option<Money$.Money>;
  }>();
  const [dateAmendmentData, setDateAmendmentData] = useState<{
    currentCloseDate: O.Option<LocalDate$.LocalDate>;
  }>();

  const { data: vendors = [] } = useQuery({
    queryKey: [
      "ApprovalsAPI.getApprovalPipeline",
      ApprovalProcessTypeEnum.TITLE_COMPANY,
    ] as const,
    queryFn: async ({ signal }) =>
      ApprovalsAPI.getApprovalPipeline(
        { approvalProcessType: ApprovalProcessTypeEnum.TITLE_COMPANY },
        { signal },
      ),
    select: (data) =>
      data
        .filter(({ currentState }) => currentState.isApproved)
        // @ts-expect-error need to strongly type ApprovalsAPI
        .map(({ model: { id, name } }) => ({ label: name, value: id })),
  });

  const form = useForm({
    initialValues: {
      acquisitionsApprovedToCloseDate: LocalDate$.parse(
        deal.acquisitionsApprovedToCloseDate,
      ),
      acquisitionsInspectionReviewedDate: LocalDate$.parse(
        deal.acquisitionsInspectionReviewedDate,
      ),
      closedAndFundedDate: LocalDate$.parse(deal.closedAndFundedDate),
      contractAcceptanceDate: LocalDate$.parse(deal.contractAcceptanceDate),
      contractProcessed: deal.contractProcessed,
      contractTerminated: deal.contractTerminated,
      contractTerminatedDate: LocalDate$.parse(deal.contractTerminatedDate),
      currentCloseDate: LocalDate$.parse(deal.currentCloseDate),
      earnestMoneyDueDate: LocalDate$.parse(deal.earnestMoneyDueDate),
      finalPurchasePrice: Money$.parse(deal.finalPurchasePrice),
      hasHOA: deal.hasHOA ?? false,
      hasWiringInstructions: deal.hasWiringInstructions ?? false,
      hoaFeeFrequency: deal.hoaFeeFrequency ?? "",
      hoaFees: Money$.parse(deal.hoaFees),
      hoaName: deal.hoaName ?? "",
      initialCloseDate: LocalDate$.parse(deal.initialCloseDate),
      inspectionReviewedDate: LocalDate$.parse(deal.inspectionReviewedDate),
      isClosingWireSent: deal.isClosingWireSent ?? false,
      isDoubleClose: deal.isDoubleClose ?? false,
      isDueDiligenceWaived: deal.isDueDiligenceWaived ?? false,
      isEarnestMoneySent: deal.isEarnestMoneySent ?? false,
      isEscrowOpened: deal.isEscrowOpened ?? false,
      isLeaseback: deal.isLeaseback ?? false,
      isSellerForeign: deal.isSellerForeign ?? false,
      isTenantInPlace: deal.isTenantInPlace ?? false,
      leasebackEndDate: LocalDate$.parse(deal.leasebackEndDate),
      source: deal.source ?? "",
      titleCompanyId: (deal.titleCompanyId ?? "") as string,
      titleCompanyName: deal.titleCompanyName ?? "",
      underContractPrice: Money$.parse(deal.underContractPrice),
    },
  });

  function closePriceAmendmentUploadModal() {
    setPriceAmendmentData(UNDEFINED);
    refetchDeal();
  }

  function closeDateAmendmentUploadModal() {
    setDateAmendmentData(UNDEFINED);
    refetchDeal();
  }

  function tagFiles({
    files,
    tag,
  }: {
    files: FilesClientEnderFile[];
    tag: string;
  }) {
    for (const file of files) {
      WebserverFilesAPI.tagFile({
        fileId: file.id,
        key: "documentType",
        modelId,
        modelType,
        value: tag,
      });
    }
  }

  const confirmation = useConfirmationContext();

  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };

  const updateDealWithWarnings = withWarningHandler(
    BuyAPI.updateDeal,
    onWarnings,
  );

  const { mutateAsync: updateDeal } = useMutation({
    mutationFn: updateDealWithWarnings,
    mutationKey: ["BuyApi.updateDeal"],
  });

  const { mutateAsync: handleDateAmendmentUpload } = useMutation({
    mutationFn: async (files: File[]) => {
      const numUploadedFiles = files.length;

      await uploadFiles({
        files,
        modelId,
        modelType,
      });

      const { files: _files } = (await WebserverFilesAPI.getFiles({
        modelId,
        modelType: ModelTypeEnum.DEAL,
      })) as { files: FilesClientEnderFile[] };

      await tagFiles({
        files: _files
          .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
          .slice(-numUploadedFiles),
        tag: "Close Date Amendment",
      });

      await updateDeal({
        dealId: deal.id,
        json: {
          currentCloseDate: dateAmendmentData?.currentCloseDate,
        },
      });

      setDateAmendmentData(UNDEFINED);
    },
    mutationKey: ["handleDateAmendmentUpload", deal.id],
  });

  const { mutateAsync: handlePriceAmendmentUpload } = useMutation({
    mutationFn: async (files: File[]) => {
      const numUploadedFiles = files.length;

      await uploadFiles({
        files,
        modelId,
        modelType,
      });

      const { files: _files } = (await WebserverFilesAPI.getFiles({
        modelId,
        modelType: ModelTypeEnum.DEAL,
      })) as { files: FilesClientEnderFile[] };

      await tagFiles({
        files: _files
          .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
          .slice(-numUploadedFiles),
        tag: "Price Amendment",
      });

      await updateDeal({
        dealId: deal.id,
        json: {
          finalPurchasePrice: priceAmendmentData?.finalPurchasePrice,
        },
      });

      setPriceAmendmentData(UNDEFINED);
    },
    mutationKey: ["handlePriceAmendmentUpload", deal.id],
  });

  const { mutateAsync: handleSaveTriggerFields } = useMutation({
    mutationFn: async (values: TransformedValues<typeof form>) => {
      const { finalPurchasePrice, currentCloseDate } = values;
      // boolean to avoid refetching when amendment docs need to be uploaded
      let shouldRefetchDeal = true;

      // set the titleCompanyName on deal if titleCompanyId is changed
      if (form.isDirty("titleCompanyId")) {
        const titleCompanyId = form.values.titleCompanyId;
        let titleCompanyName = "";

        if (form.values.titleCompanyId) {
          titleCompanyName = vendors.find(
            (vendor) => vendor.value === titleCompanyId,
          )?.label;
        }

        form.setFieldValue("titleCompanyName", titleCompanyName);
      }

      // if there is a finalPurchasePrice and it's changed in the form, an amendment is needed
      // else the user can change the finalPurchasePrice
      const needsPriceAmendment =
        P.isNotNullable(deal.finalPurchasePrice) &&
        form.isDirty("finalPurchasePrice") &&
        !O.getEquivalence(Money$.Equivalence)(
          Money$.parse(deal.finalPurchasePrice),
          finalPurchasePrice
        );

      if (needsPriceAmendment) {
        shouldRefetchDeal = false;
        setPriceAmendmentData({
          finalPurchasePrice,
        });
        form.setFieldValue(
          "finalPurchasePrice",
          Money$.parse(deal.finalPurchasePrice),
        );
      } else {
        form.setFieldValue("finalPurchasePrice", finalPurchasePrice);
      }

      // if there is a currentCloseDate and it's changed in the form, an amendment is needed
      // else the user can change the currentCloseDate
      const needsDateAmendment =
        P.isNotNullable(deal.currentCloseDate) &&
        form.isDirty("currentCloseDate");
      if (needsDateAmendment) {
        shouldRefetchDeal = false;
        setDateAmendmentData({
          currentCloseDate: LocalDate$.parse(deal.currentCloseDate),
        });
        form.setFieldValue(
          "currentCloseDate",
          LocalDate$.parse(deal.currentCloseDate),
        );
      } else {
        form.setFieldValue("currentCloseDate", currentCloseDate);
      }

      const updatePayload = {
        dealId: deal.id,
        json: {
          ...form.values,
          currentCloseDate: needsDateAmendment
            ? deal.currentCloseDate
            : form.values.currentCloseDate.pipe(
                O.map((date) => date.toJSON()),
                O.getOrUndefined,
              ),
          finalPurchasePrice: needsPriceAmendment
            ? deal.finalPurchasePrice
            : finalPurchasePrice,
        },
      };
      await updateDeal(updatePayload);

      if (shouldRefetchDeal) {
        refetchDeal();
      }
    },
    mutationKey: ["handleSaveTriggerFields", deal.id],
  });
  const handleOnSubmit = useCallback(
    (values: TransformedValues<typeof form>) => {
      handleSaveTriggerFields(values);
    },
    [handleSaveTriggerFields],
  );
  return (
    <Card>
      <Stack spacing={Spacing.md}>
        <Modal
          title="Close Date Amendment"
          opened={!!dateAmendmentData}
          onClose={closeDateAmendmentUploadModal}>
          <FileUploadForm
            title="Please upload DATE AMENDMENT documents to change Current Close Date"
            uploadFiles={handleDateAmendmentUpload}
            onSuccess={closeDateAmendmentUploadModal}
          />
        </Modal>
        <Modal
          title="Purchase Price Amendment"
          opened={!!priceAmendmentData && !dateAmendmentData}
          onClose={closePriceAmendmentUploadModal}>
          <FileUploadForm
            title="Please upload PRICE AMENDMENT documents to change Final Purchase Price"
            uploadFiles={handlePriceAmendmentUpload}
            onSuccess={closePriceAmendmentUploadModal}
          />
        </Modal>
        <form onSubmit={form.onSubmit(handleOnSubmit)}>
          <Stack>
            <H3>System Fields</H3>
            <Group align={Align.center} spacing={Spacing.sm}>
              <Checkbox
                label="Contract Processed"
                {...form.getInputProps("contractProcessed")}
              />
              {deal.contractProcessed && (
                <Tooltip label="Go to Approval Pipeline">
                  <Link to={`/buy/deal-approval-pipeline/${deal.id}`}>
                    <IconInfoCircle
                      color={EnderThemeColorEnum.PRIMARY}
                      size="14"
                    />
                  </Link>
                </Tooltip>
              )}
            </Group>
            <Checkbox
              label="Contract Terminated"
              {...form.getInputProps("contractTerminated")}
            />
            <Checkbox
              label="Double Close"
              {...form.getInputProps("isDoubleClose")}
            />
            <Checkbox
              label="Foreign Seller"
              {...form.getInputProps("isSellerForeign")}
            />
            <Checkbox
              label="Escrow Opened"
              {...form.getInputProps("isEscrowOpened")}
            />
            <Checkbox
              label="Wiring Instructions Uploaded"
              {...form.getInputProps("hasWiringInstructions")}
            />
            <Checkbox
              label="Earnest Money Sent"
              {...form.getInputProps("isEarnestMoneySent")}
            />
            <Checkbox
              label="Closing Wire Sent"
              {...form.getInputProps("isClosingWireSent")}
            />
            <Checkbox label="Has HOA" {...form.getInputProps("hasHOA")} />
            <Checkbox
              label="Due Diligence Waived"
              {...form.getInputProps("isDueDiligenceWaived")}
            />
            <Checkbox
              label="Leaseback"
              {...form.getInputProps("isLeaseback")}
            />
            <Checkbox
              label="Tenant in Place"
              {...form.getInputProps("isTenantInPlace")}
            />
            <DateInput
              label="Earnest Money Due Date"
              {...form.getInputProps("earnestMoneyDueDate")}
            />
            <DateInput
              label="Current Close Date"
              {...form.getInputProps("currentCloseDate")}
            />
            <MoneyInput
              label="Final Purchase Price"
              {...form.getInputProps("finalPurchasePrice")}
            />
            <TextInput label="HOA Name" {...form.getInputProps("hoaName")} />
            <MoneyInput
              label="HOA Fee"
              name="hoaFees"
              {...form.getInputProps("hoaFees")}
            />
            <Select
              label="HOA Fee Frequency"
              name="hoaFeeFrequency"
              {...form.getInputProps("hoaFeeFrequency")}
              data={hoaFrequencyOptions}
            />
            <Select
              label="Title Company"
              data={vendors}
              {...form.getInputProps("titleCompanyId")}
              searchable
            />
            <DateInput
              label="Acquisitions Inspection Reviewed Date"
              {...form.getInputProps("acquisitionsInspectionReviewedDate")}
            />
            <DateInput
              label="Acquisitions Approved to Close Date"
              {...form.getInputProps("acquisitionsApprovedToCloseDate")}
            />
            <DateInput
              label="Inspection Reviewed Date"
              {...form.getInputProps("inspectionReviewedDate")}
            />
            <DateInput
              label="Closed and Funded Date"
              {...form.getInputProps("closedAndFundedDate")}
            />
            <DateInput
              label="Contract Acceptance Date"
              {...form.getInputProps("contractAcceptanceDate")}
            />
            <MoneyInput
              label="Under Contract Price"
              {...form.getInputProps("underContractPrice")}
            />
            <DateInput
              label="Initial Close Date"
              {...form.getInputProps("initialCloseDate")}
            />
            <Select
              label="Source"
              data={dealSourceOptions}
              {...form.getInputProps("source")}
            />
            <DateInput
              label="Leaseback End Date"
              {...form.getInputProps("leasebackEndDate")}
            />
            <DateInput
              label="Contract Termination Date"
              {...form.getInputProps("contractTerminatedDate")}
            />
            <Button type="submit">Save Changes</Button>
          </Stack>
        </form>
      </Stack>
    </Card>
  );
}

export { SystemFields };
