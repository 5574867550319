import { Function as F, Predicate as P } from "effect";
import type {
  ComponentType,
  ErrorInfo,
  PropsWithChildren,
  ReactNode,
} from "react";
import { Component } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { H2 } from "@ender/shared/ds/heading";
import { cast } from "@ender/shared/types/cast";
import { showErrorNotification } from "@ender/shared/utils/notifications";

type FallbackProps = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

/** Specify and pass additional props if you want to use them */
function DefaultErrorFallback(_props: FallbackProps) {
  return (
    <div
      className="error-boundary"
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
      aria-label="Something went wrong. Try refreshing the page."
      role="alert">
      <H2>Something went wrong. Try refreshing the page.</H2>
    </div>
  );
}

type ErrorBoundaryProps = PropsWithChildren<{
  showErrorNotice?: boolean;
  renderFallback?: (props: FallbackProps) => ReactNode;
}> &
  RouteComponentProps;

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
  stopListening: () => void;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false,
      stopListening: F.constVoid,
    };
  }

  static getDerivedStateFromError(/* error */) {
    return { hasError: true };
  }

  componentDidMount(): void {
    const stopListening = this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });

    this.setState({
      stopListening,
    });
  }

  componentWillUnmount(): void {
    this.state.stopListening();
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { showErrorNotice = false } = this.props;
    this.setState({ error, errorInfo, hasError: true });
    showErrorNotice && showErrorNotification({ message: error.message });
  }

  render() {
    const { children, renderFallback } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError && P.isNotNullable(renderFallback)) {
      return renderFallback({ error, errorInfo });
    } else if (hasError) {
      return <DefaultErrorFallback error={error} errorInfo={errorInfo} />;
    }

    return children;
  }
}

/**
 * @deprecated will be implemented using error.tsx files in a file-based routing setup.
 * In general, this should be accomplished at a page layout level, and maybe exported from the `nav` package instead of
 * shared/ui.
 *
 * For now, it should live in the app-shell package and not be directly exposed for developer use
 */
const EnderErrorBoundary: ComponentType<
  PropsWithChildren<{
    showErrorNotice?: boolean;
    renderFallback?: (props: FallbackProps) => ReactNode;
  }>
> = cast(withRouter(ErrorBoundary));

export { EnderErrorBoundary };
