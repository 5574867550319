import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyHOAFeeFrequencyValues = [
  "MONTHLY",
  "BI_MONTHLY",
  "QUARTERLY",
  "BI_YEARLY",
  "YEARLY",
] as const;

const PropertyHOAFeeFrequencyEffectSchema = Schema.Literal(
  ...PropertyHOAFeeFrequencyValues,
);

type PropertyHOAFeeFrequency = Schema.Schema.Type<
  typeof PropertyHOAFeeFrequencyEffectSchema
>;

const PropertyHOAFeeFrequencyEnum = castEnum(
  PropertyHOAFeeFrequencyEffectSchema,
);

function randomPropertyHOAFeeFrequency(): PropertyHOAFeeFrequency {
  return rand(PropertyHOAFeeFrequencyValues);
}

export {
  PropertyHOAFeeFrequencyEffectSchema,
  PropertyHOAFeeFrequencyEnum,
  PropertyHOAFeeFrequencyValues,
  randomPropertyHOAFeeFrequency,
};
export type { PropertyHOAFeeFrequency };
