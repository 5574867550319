import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationsAPIApplicationApprovalTypeValues = [
  "PERSONAL",
  "INCOME",
  "RENTAL_HISTORY",
  "CRIMINAL_REPORT",
  "CREDIT_REPORT",
  "EVICTION_REPORT",
] as const;

const ApplicationsAPIApplicationApprovalTypeEffectSchema = Schema.Literal(
  ...ApplicationsAPIApplicationApprovalTypeValues,
);

type ApplicationsAPIApplicationApprovalType = Schema.Schema.Type<
  typeof ApplicationsAPIApplicationApprovalTypeEffectSchema
>;

const ApplicationsAPIApplicationApprovalTypeEnum = castEnum(
  ApplicationsAPIApplicationApprovalTypeEffectSchema,
);

function randomApplicationsAPIApplicationApprovalType(): ApplicationsAPIApplicationApprovalType {
  return rand(ApplicationsAPIApplicationApprovalTypeValues);
}

export {
  ApplicationsAPIApplicationApprovalTypeEffectSchema,
  ApplicationsAPIApplicationApprovalTypeEnum,
  ApplicationsAPIApplicationApprovalTypeValues,
  randomApplicationsAPIApplicationApprovalType,
};
export type { ApplicationsAPIApplicationApprovalType };
