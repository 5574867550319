import type { FunctionType } from "@ender/shared/types/general";
import { fail } from "@ender/shared/utils/error";
import { showLoadingNotification } from "@ender/shared/utils/notifications";

type DownloadBlobDataParams = {
  data: Blob;
  filename?: string;
};

function downloadBlobFromUrl(url: string, filename: string) {
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => console.error("Download failed", err));
}

function downloadBlobData({
  data,
  filename = "download",
}: DownloadBlobDataParams) {
  const url = URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = url;
  /**
   * value of a.download is the default filename that the browser will download the file as
   * default is "download" if not set. file extension will be appended automatically
   */
  a.download = filename;
  a.click();
}

type BlobDataOptions = {
  autoClose?: number | boolean;
  filenameOverride?: string;
  message?: string;
};

async function getAndDownloadBlobData(
  inputFn: FunctionType,
  {
    message = "Downloading...",
    filenameOverride,
    autoClose,
  }: BlobDataOptions = {},
) {
  const [, clearNotification] = showLoadingNotification({ message, autoClose });
  try {
    const { blob, filename } = await inputFn();
    downloadBlobData({
      data: await blob,
      filename: filenameOverride ?? filename,
    });
  } catch (err) {
    fail(err);
    return;
  } finally {
    clearNotification();
  }
}

async function getAndDownloadXlsx(
  inputFn: () => Promise<void | Blob>,
  {
    message = "Downloading XLSX...",
    filenameOverride,
    autoClose,
  }: BlobDataOptions = {},
): Promise<void> {
  await getAndDownloadBlobData(inputFn, {
    autoClose,
    filenameOverride,
    message,
  });
}
async function getAndDownloadPDF(
  inputFn: () => Promise<void | Blob>,
  {
    message = "Downloading PDF...",
    filenameOverride,
    autoClose,
  }: BlobDataOptions = {},
): Promise<void> {
  await getAndDownloadBlobData(inputFn, {
    autoClose,
    filenameOverride,
    message,
  });
}
async function getAndDownloadZIP(
  inputFn: () => Promise<void | Blob>,
  {
    message = "Downloading ZIP...",
    filenameOverride,
    autoClose,
  }: BlobDataOptions = {},
): Promise<void> {
  await getAndDownloadBlobData(inputFn, {
    autoClose,
    filenameOverride,
    message,
  });
}

export {
  downloadBlobData,
  downloadBlobFromUrl,
  getAndDownloadBlobData,
  getAndDownloadPDF,
  getAndDownloadXlsx,
  getAndDownloadZIP,
};
export type { BlobDataOptions, DownloadBlobDataParams };
