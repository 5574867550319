/**
 * HTTP Method: POST
 * Pathname: /users/{userId}/setName
 * @function setName
 * @memberof LeasingAPI
 * @param {LeasingAPISetNamePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISetNamePathParams = {
  userId: EnderId;
};

type LeasingAPISetNameSearchParams = {
  token?: string | undefined;
};

type LeasingAPISetNameBodyParams = {
  firstName: string;
  lastName: string;
};

type LeasingAPISetNamePayload = LeasingAPISetNamePathParams &
  LeasingAPISetNameSearchParams &
  LeasingAPISetNameBodyParams;

function setNameUnsafeEffect(payload: LeasingAPISetNamePayload) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${userId}/setName`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setName(
  payload: LeasingAPISetNamePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setNameUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setName };
export type {
  LeasingAPISetNameBodyParams,
  LeasingAPISetNamePathParams,
  LeasingAPISetNamePayload,
  LeasingAPISetNameSearchParams,
};
