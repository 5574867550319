import { Option as O } from "effect";
import { z } from "zod";

import { LocalDate$ } from "@ender/shared/core";

const LocalDateZodSchema = z.custom<LocalDate$.LocalDate>((v) =>
  O.isSome(LocalDate$.parse(v)),
);

export { LocalDateZodSchema };
