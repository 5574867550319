import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CollectionsStepChargeTypeValues = [
  "MONTHLY_RENT",
  "MONTHLY_SCHEDULE_CHARGES",
] as const;

const CollectionsStepChargeTypeEffectSchema = Schema.Literal(
  ...CollectionsStepChargeTypeValues,
);

type CollectionsStepChargeType = Schema.Schema.Type<
  typeof CollectionsStepChargeTypeEffectSchema
>;

const CollectionsStepChargeTypeEnum = castEnum(
  CollectionsStepChargeTypeEffectSchema,
);

function randomCollectionsStepChargeType(): CollectionsStepChargeType {
  return rand(CollectionsStepChargeTypeValues);
}

export {
  CollectionsStepChargeTypeEffectSchema,
  CollectionsStepChargeTypeEnum,
  CollectionsStepChargeTypeValues,
  randomCollectionsStepChargeType,
};
export type { CollectionsStepChargeType };
