import { zodResolver } from "@mantine/form";
import type { UseFormInput } from "@mantine/form/lib/types";
import { z } from "zod";

import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { EnderInputText } from "@ender/shared/forms/ui/ender-input-text";
import { ManagedForm } from "@ender/shared/forms/ui/managed-form";

import styles from "./view-ssn.module.css";

type ViewReasonFormValues = {
  reason: string;
};

const viewReasonFormSchema = z.object({
  reason: z.string().nonempty(),
});

type ViewSSNReasonModalProps = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
};

function ViewSSNReasonModal({
  opened,
  onClose,
  onSubmit,
}: ViewSSNReasonModalProps) {
  const formProps: UseFormInput<ViewReasonFormValues> = {
    initialValues: { reason: "" },
    validate: zodResolver(viewReasonFormSchema),
  } as const;

  function handleSubmit(formValues: ViewReasonFormValues) {
    onSubmit(formValues.reason);
  }

  return (
    <Modal
      title="Enter reason for viewing SSN"
      size={ModalSize.sm}
      opened={opened}
      onClose={onClose}>
      <ManagedForm formProps={formProps} onSubmit={handleSubmit}>
        <div className={styles.viewReasonForm}>
          <EnderInputText label=" " name="reason" data-autofocus />
          <Group justify={Justify.end}>
            <Button type="submit">View SSN</Button>
          </Group>
        </div>
      </ManagedForm>
    </Modal>
  );
}

export { ViewSSNReasonModal };
export type { ViewSSNReasonModalProps };
