import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";

const FirmSchema = z.object({
  hasDwollaAccount: z.boolean().optional(),
  hasStripeAccount: z.boolean().optional(),
  id: EnderIdSchema,
  legalEntityName: z.string().optional(),
  name: z.string().optional(),
});
type Firm = z.infer<typeof FirmSchema>;

export { FirmSchema };
export type { Firm };
