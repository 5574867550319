import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useCallback } from "react";

import { Form, useForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";

type ApprovalStepFormValues = {
  name: string;
};

type ApprovalStepFormProps = {
  onSuccess: () => void;
};

type CreateApprovalStepFormProps = {
  approvalProcessType: ApprovalProcessType;
  stepId?: undefined;
  originalStepName?: undefined;
} & ApprovalStepFormProps;

type UpdateApprovalStepFormProps = {
  approvalProcessType?: undefined;
  stepId: EnderId;
  originalStepName: string;
} & ApprovalStepFormProps;

/**
 * POSSIBLE DUPLICATE OF
 * libs/shared/ui/approval-process/src/lib/steps/create-update-step-form.tsx
 *
 * This file seems to have a lot in common with create-update-step-form.
 * Maybe this needs to be elevated out to a shared, generic form that can be used in place of both
 */
function CreateUpdateApprovalStepForm(
  props: CreateApprovalStepFormProps | UpdateApprovalStepFormProps,
) {
  const { originalStepName: name = "", onSuccess } = props;
  const { stepId } = props as UpdateApprovalStepFormProps;
  const { approvalProcessType } = props as CreateApprovalStepFormProps;

  const ApprovalStepFormSchema = Schema.Struct({
    name: Schema.String.pipe(
      Schema.nonEmptyString({ message: () => "Step Name is required" }),
    ),
  });

  const form = useForm<ApprovalStepFormValues>({
    defaultValues: {
      name,
    },
    mode: "onSubmit",
    resolver: effectTsResolver(ApprovalStepFormSchema),
  });

  const { mutateAsync: updateStepName, isLoading: updatingStepName } =
    useMutation({
      mutationFn: ApprovalsAPI.updateStepName,
      mutationKey: ["ApprovalsAPI.updateStepName"] as const,
    });
  const { mutateAsync: addApprovalStep, isLoading: addingApprovalStep } =
    useMutation({
      mutationFn: ApprovalsAPI.addApprovalStep,
      mutationKey: ["ApprovalsAPI.addApprovalStep"] as const,
    });

  const handleSubmit = useCallback(
    async ({ name }: ApprovalStepFormValues) => {
      const apiCall = P.isNotNullable(stepId)
        ? () => updateStepName({ stepId, name })
        : () => addApprovalStep({ name, approvalProcessType });

      await apiCall();
      onSuccess();
    },
    [stepId, onSuccess, updateStepName, addApprovalStep, approvalProcessType],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput label="Name" name="name" form={form} />
        <Group justify={Justify.end}>
          <Button
            type="submit"
            loading={updatingStepName || addingApprovalStep}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { CreateUpdateApprovalStepForm };
