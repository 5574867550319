import { Schema } from "@effect/schema";
import { useQueryClient } from "@tanstack/react-query";
import { Option as O } from "effect";
import { isNotNullable, isNull } from "effect/Predicate";
import { useCallback } from "react";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { NULL } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { EnderIdFormSchema, LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetJournalEntriesReportResponseJournalEntryReportRow } from "@ender/shared/generated/ender.api.reports.request";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

const ReversalJournalEntryFormSchema = Schema.Struct({
  accountingPeriod: Schema.Struct({
    id: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
  reversalDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Reversal Date is required" }),
  ),
});

type ReversalJournalEntryFormOutput = Schema.Schema.Type<
  typeof ReversalJournalEntryFormSchema
>;

type ReverseJournalEntryFormProps = {
  row: GetJournalEntriesReportResponseJournalEntryReportRow;
  closeModal: () => void;
  refreshReversalData?: () => void;
  refreshTableData?: () => void;
  refreshTransactionData?: () => void;
};

function ReverseJournalEntryForm({
  row,
  closeModal,
  refreshReversalData,
  refreshTableData,
  refreshTransactionData,
}: ReverseJournalEntryFormProps) {
  const { journalEntryId, ledgerDate } = row;
  const [isLoading, isLoadingHandlers] = useBoolean();
  const confirmation = useConfirmationContext();

  const reversalDate = isNotNullable(ledgerDate) ? ledgerDate : NULL;
  const queryClient = useQueryClient();

  const form = useEffectSchemaForm({
    defaultValues: {
      accountingPeriod: O.none(),
      reversalDate: LocalDate$.parse(reversalDate),
    },
    schema: ReversalJournalEntryFormSchema,
  });

  const reverseJournalEntryWithWarnings = withWarningHandler(
    AccountingAPI.reverseJournalEntry,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );

  const handleSubmit = useCallback(
    async ({
      accountingPeriod,
      reversalDate,
    }: ReversalJournalEntryFormOutput) => {
      isLoadingHandlers.setTrue();
      try {
        const response = await reverseJournalEntryWithWarnings({
          journalEntryId,
          periodId: accountingPeriod.pipe(
            O.map((v) => v.id),
            O.getOrUndefined,
          ),
          reversalDate: reversalDate.pipe(
            O.map((v) => v.toJSON()),
            O.getOrThrow,
          ),
        });

        if (isNotNullable(response) || isNull(response)) {
          await queryClient.invalidateQueries(["fetchTxs"]);
          await queryClient.invalidateQueries(["fetchJournalEntries"]);
          await queryClient.invalidateQueries(["run-journal-entry-report"]);
          if (isNotNullable(refreshTransactionData)) {
            refreshTransactionData();
          }
          if (isNotNullable(refreshReversalData)) {
            refreshReversalData();
          }
          showSuccessNotification({
            message: "Journal entry reversed successfully",
          });
          closeModal();
          if (isNotNullable(refreshTableData)) {
            refreshTableData();
          }
        }
      } catch (e) {
        fail(e);
      } finally {
        isLoadingHandlers.setFalse();
      }
    },
    [
      closeModal,
      journalEntryId,
      isLoadingHandlers,
      queryClient,
      refreshReversalData,
      refreshTableData,
      refreshTransactionData,
      reverseJournalEntryWithWarnings,
    ],
  );

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Text size={FontSize.sm}>
            Processing a reversal will create offsetting entries for this
            charge. The reversing entry will appear on the General Ledger.
          </Text>
          <FormDateInput
            label="Reversal Date"
            minDate={LocalDate$.of(reversalDate)}
            form={form}
            name="reversalDate"
          />
          <FormAccountingPeriodSelector
            form={form}
            label="Accounting Period (optional)"
            description="Ender will assign an accounting period based on the transaction date if you do not specify one."
            name="accountingPeriod"
            periodType={AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER}
          />
          <Group justify={Justify.end}>
            <Button loading={isLoading} type="submit">
              Confirm
            </Button>
          </Group>
        </Stack>
      </Form>
    </>
  );
}

export { ReverseJournalEntryForm };
