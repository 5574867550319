import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import type { LocalDate$ } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import { InvoicePaymentMethodEnum } from "@ender/shared/generated/ender.model.payments.invoice";

// CommonBatchSchema is sufficient for ACH but PrintCheckBatchSchema needs additional fields
const CommonBatchSchema = Schema.Struct({
  accountingPeriodId: Schema.Struct({
    id: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
  amount: MoneyEffectSchema,
  invoiceIds: Schema.Array(EnderIdFormSchema),
});

// PrintCheckBatchSchema needs to extend CommonBatchSchema with additional fields
const PrintCheckBatchSchema = Schema.Struct({
  ...CommonBatchSchema.fields,
  checkNumber: Schema.Trimmed.pipe(
    Schema.nonEmptyString({ message: () => "Check Number is required" }),
    Schema.maxLength(7, {
      message: () => "Check number must be 7 characters or less",
    }),
  ),
  date: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => O.isSome(input),
      {
        message: () => "Payment Date is required",
      },
    ),
  ),
  memo: Schema.String.pipe(
    Schema.maxLength(60, {
      message: () => "Memo must be 60 characters or less",
    }),
  ),
  recipientName: Schema.Trimmed.pipe(
    Schema.nonEmptyString({
      message: () => "Recipient Name is required",
    }),
  ),
  senderName: Schema.Trimmed.pipe(
    Schema.nonEmptyString({
      message: () => "Sender Name is required",
    }),
  ),
});

const BatchPayACHFormSchema = Schema.Struct({
  bankAccountId: Schema.OptionFromSelf(EnderIdFormSchema),
  batches: Schema.Array(CommonBatchSchema), // No additional fields for ACH
  paymentMethod: Schema.Literal(InvoicePaymentMethodEnum.ACH),
});

const BatchPayPrintCheckFormSchema = Schema.Struct({
  bankAccountId: Schema.OptionFromSelf(EnderIdFormSchema),
  batches: Schema.Array(PrintCheckBatchSchema),
  paymentMethod: Schema.Literal(InvoicePaymentMethodEnum.PRINT_CHECK),
});

// Union of payment-type based valid form schemas
const BatchPayFormSchema = Schema.Union(
  BatchPayPrintCheckFormSchema,
  BatchPayACHFormSchema,
);

type BatchPayPrintCheckFormValues = Schema.Schema.Type<
  typeof BatchPayPrintCheckFormSchema
>;
type BatchPayFormValues = Schema.Schema.Type<typeof BatchPayFormSchema>;

export { BatchPayFormSchema, BatchPayFormValues, BatchPayPrintCheckFormValues };
