import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Textarea } from "@ender/shared/ds/textarea";

import { MoveInStep } from "../move-in-step";
import type { ChecklistStepComponentProps } from "./checklist-steps.types";

function TextStep(props: ChecklistStepComponentProps) {
  const {
    completed,
    capturedData = "",
    description,
    completedTime,
    onChange,
    ...stepProps
  } = props;

  return (
    <MoveInStep {...stepProps} completedTime={completedTime}>
      <Textarea
        disabled={completed}
        value={capturedData}
        onChange={(value: string) =>
          onChange({
            capturedData: value,
            completed: value.length > 0,
          })
        }
        placeholder={description}
      />
      {Boolean(completedTime) && (
        <Button
          variant={ButtonVariant.transparent}
          onClick={() => onChange({ capturedData: "", completed: false })}
          disabled={completed}>
          Clear
        </Button>
      )}
    </MoveInStep>
  );
}

export { TextStep };
