import { IconShieldCheck } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { InsuranceAPI } from "@ender/shared/generated/ender.api.finance";
import type {
  InsurancePolicy,
  InsurancePolicyInsuredParty,
} from "@ender/shared/generated/ender.model.core.vendor";
import { InsurancePolicyInsuredPartyEnum } from "@ender/shared/generated/ender.model.core.vendor";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { CreateUpdateInsurancePolicyModal } from "@ender/shared/ui/modals";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { capitalize } from "@ender/shared/utils/string";

import { InsurancePolicyCard } from "./insurance-policy-card";
import type { UpdatedInsurancePolicy } from "./insurance.types";

type InsurancePoliciesProps = {
  insuredPartyId: EnderId;
  insuredPartyType: InsurancePolicyInsuredParty;
  vendorDisabled?: boolean;
};

const getSectionTitleAndEmptyMessage = (
  insuredPartyType: InsurancePolicyInsuredParty,
) => {
  switch (insuredPartyType) {
    case InsurancePolicyInsuredPartyEnum.TENANT: {
      return {
        emptyMessage: "There are no insurance policies for this tenant.",
        sectionTitle: "Tenant Insurance Policies",
      };
    }
    case ModelTypeEnum.VENDOR: {
      return {
        emptyMessage: "There are no insurance policies for this vendor.",
        sectionTitle: "Vendor Insurance Policies",
      };
    }
    case InsurancePolicyInsuredPartyEnum.PROPERTY: {
      return {
        emptyMessage: "There are no insurance policies for this property.",
        sectionTitle: "Property Insurance Policies",
      };
    }
    default: {
      throw Error(`insuredPartyType "${insuredPartyType}" is invalid`);
    }
  }
};

function InsurancePolicies({
  insuredPartyId,
  insuredPartyType,
  vendorDisabled,
}: InsurancePoliciesProps) {
  const [
    isCreatePolicyModalOpen,
    { setTrue: openCreatePolicyModal, setFalse: closeCreatePolicyModal },
  ] = useBoolean(false);
  const { sectionTitle, emptyMessage } =
    getSectionTitleAndEmptyMessage(insuredPartyType);

  const { data: insurancePolicies, refetch: _getInsurancePolicies } = useQuery<
    InsurancePolicy[],
    unknown,
    UpdatedInsurancePolicy[]
  >({
    queryFn: ({ signal }) =>
      InsuranceAPI.getInsurancePolicies(
        {
          insuredParty: insuredPartyType,
          insuredPartyId,
        },
        { signal },
      ),
    queryKey: [
      "InsuranceAPI.getInsurancePolicies",
      insuredPartyId,
      insuredPartyType,
    ] as const,
    select: (insurancePolicies) => {
      return insurancePolicies.map((insurancePolicy) => ({
        ...insurancePolicy,
        effectiveDate: LocalDate$.parse(insurancePolicy.effectiveDate),
        expirationDate: LocalDate$.parse(insurancePolicy.expirationDate),
      }));
    },
  });

  function handleCreatePolicy() {
    showSuccessNotification({ message: "Insurance policy was created." });
    closeCreatePolicyModal();
    _getInsurancePolicies();
  }

  if (P.isUndefined(insurancePolicies)) {
    return NULL;
  }

  return (
    <Stack spacing={Spacing.md}>
      <Modal
        title={`Add ${capitalize(insuredPartyType)} Insurance Policy`}
        size={ModalSize.sm}
        opened={isCreatePolicyModalOpen}
        onClose={closeCreatePolicyModal}>
        <CreateUpdateInsurancePolicyModal
          insuredPartyId={insuredPartyId}
          insuredPartyType={insuredPartyType}
          onSuccess={handleCreatePolicy}
        />
      </Modal>
      <Group justify={Justify.between}>
        <Group spacing={Spacing.sm}>
          <IconShieldCheck size={24} color="var(--color-slate-600)" />
          <H2>{sectionTitle}</H2>
        </Group>
        {!vendorDisabled && (
          <Button
            variant={ButtonVariant.outlined}
            onClick={openCreatePolicyModal}>
            Add Policy
          </Button>
        )}
      </Group>
      {A.isEmptyArray(insurancePolicies) && (
        <Text size={FontSize.md} weight={FontWeight.medium}>
          {emptyMessage}
        </Text>
      )}
      {insurancePolicies.map((policy) => (
        <InsurancePolicyCard
          key={policy.id}
          policy={policy}
          refetchInsurancePolicies={_getInsurancePolicies}
        />
      ))}
    </Stack>
  );
}

export { InsurancePolicies };
