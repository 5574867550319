/**
 * HTTP Method: POST
 * Pathname: /leads/{leadId}/archive
 * @function archiveAction
 * @memberof LeasingAPI
 * @param {LeasingAPIArchiveActionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIArchiveActionPathParams = {
  leadId: EnderId;
};

type LeasingAPIArchiveActionSearchParams = {
  token?: string | undefined;
};

type LeasingAPIArchiveActionPayload = LeasingAPIArchiveActionPathParams &
  LeasingAPIArchiveActionSearchParams;

function archiveActionUnsafeEffect(payload: LeasingAPIArchiveActionPayload) {
  const { leadId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leads/${leadId}/archive`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function archiveAction(
  payload: LeasingAPIArchiveActionPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    archiveActionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { archiveAction };
export type {
  LeasingAPIArchiveActionPathParams,
  LeasingAPIArchiveActionPayload,
  LeasingAPIArchiveActionSearchParams,
};
