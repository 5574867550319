import { String as S } from "effect";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type UseQueryParamsReturn = {
  setQueryParams: (query: Record<string, string>, replace?: boolean) => void;
} & Record<string, string>;

function useQueryParams(): UseQueryParamsReturn {
  const history = useHistory();
  const location = useLocation();

  const queryEntries = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );

  const setQueryParams = useCallback(
    (searchQuery: Record<string, string>, replace = false) => {
      const queryParams = new URLSearchParams(location.search);
      Object.entries(searchQuery).forEach(([key, val]) => {
        if (S.isEmpty(val)) {
          queryParams.delete(key);
        } else {
          queryParams.set(key, val);
        }
      });

      const historyParams = {
        search: queryParams.toString(),
      };

      if (replace) {
        history.replace(historyParams);
      } else {
        history.push(historyParams);
      }
    },
    [history, location.search],
  );

  return Object.assign(
    {
      setQueryParams,
    },
    queryEntries,
  );
}

export { useQueryParams };
