import type { ColumnFiltersState, FilterFnOption } from "@tanstack/react-table";
import { useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";

import type { EnderTableFilterParams } from "../table.types";

type UseTableFilterParams<RowData> = {
  initialColumnFilters?: ColumnFiltersState;
  initialGlobalFilter?: string;
  globalFilterFn?: FilterFnOption<RowData>;
  enableGlobalFilter?: boolean;
  globalFilterPlaceholder?: string;
  manualFiltering?: boolean;
};

function useTableFilter<RowData>(
  params: UseTableFilterParams<RowData> = {},
): EnderTableFilterParams<RowData> {
  const {
    enableGlobalFilter = true,
    globalFilterFn = enableGlobalFilter ? "includesString" : UNDEFINED,
    initialColumnFilters = [],
    initialGlobalFilter = enableGlobalFilter ? "" : UNDEFINED,
    manualFiltering = false,
  } = params;

  const [columnFilters, onColumnFiltersChange] =
    useState<ColumnFiltersState>(initialColumnFilters);
  const [globalFilter, onGlobalFilterChange] = useState<string | undefined>(
    initialGlobalFilter,
  );

  return {
    columnFilters,
    enableGlobalFilter,
    globalFilter,
    globalFilterFn,
    manualFiltering,
    onColumnFiltersChange,
    onGlobalFilterChange,
  };
}

export { useTableFilter };
