/**
 * HTTP Method: POST
 * Pathname: /stripe/paymentMethods/cards/{cardId}/remove
 * @function removeCard
 * @memberof PaymentsAPI
 * @param {PaymentsAPIRemoveCardPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIRemoveCardPathParams = {
  cardId: EnderId;
};

type PaymentsAPIRemoveCardSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIRemoveCardBodyParams = {
  overrideWarnings?: boolean | undefined;
};

type PaymentsAPIRemoveCardPayload = PaymentsAPIRemoveCardPathParams &
  PaymentsAPIRemoveCardSearchParams &
  PaymentsAPIRemoveCardBodyParams;

function removeCardUnsafeEffect(payload: PaymentsAPIRemoveCardPayload) {
  const { cardId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/stripe/paymentMethods/cards/${cardId}/remove`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeCard(
  payload: PaymentsAPIRemoveCardPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeCardUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeCard };
export type {
  PaymentsAPIRemoveCardBodyParams,
  PaymentsAPIRemoveCardPathParams,
  PaymentsAPIRemoveCardPayload,
  PaymentsAPIRemoveCardSearchParams,
};
