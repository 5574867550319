/**
 * HTTP Method: POST
 * Pathname: /getFilteredLeadsWithProspects
 * @function getFilteredLeadsWithProspects
 * @memberof LeasingAPI
 * @param {LeasingAPIGetFilteredLeadsWithProspectsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FilteredProspectResponse[]>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ProspectSortBy } from "@ender/shared/generated/ender.api.leasing.request";
import type { FilteredProspectResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { UnitUnitStatus } from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetFilteredLeadsWithProspectsSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetFilteredLeadsWithProspectsBodyParams = {
  archiveKeyword: string;
  isFollowUpNeeded?: boolean | undefined;
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  propertyIds: EnderId[];
  prospectKeyword: string;
  showActive?: boolean | undefined;
  showArchived?: boolean | undefined;
  sortAscending?: boolean | undefined;
  sortBy?: ProspectSortBy | undefined;
  unitIds: EnderId[];
  unitStatus: UnitUnitStatus[];
};

type LeasingAPIGetFilteredLeadsWithProspectsPayload =
  LeasingAPIGetFilteredLeadsWithProspectsSearchParams &
    LeasingAPIGetFilteredLeadsWithProspectsBodyParams;

function getFilteredLeadsWithProspectsUnsafeEffect(
  payload: LeasingAPIGetFilteredLeadsWithProspectsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FilteredProspectResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<FilteredProspectResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getFilteredLeadsWithProspects",
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function getFilteredLeadsWithProspects(
  payload: LeasingAPIGetFilteredLeadsWithProspectsPayload,
  options?: { signal?: AbortSignal },
): Promise<FilteredProspectResponse[]> {
  return F.pipe(
    payload,
    getFilteredLeadsWithProspectsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFilteredLeadsWithProspects };
export type {
  LeasingAPIGetFilteredLeadsWithProspectsBodyParams,
  LeasingAPIGetFilteredLeadsWithProspectsPayload,
  LeasingAPIGetFilteredLeadsWithProspectsSearchParams,
};
