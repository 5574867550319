/**
 * Get the "audit trail" for an accounting period. This is a list of AccountingPeriod objects representing each
 snapshot at which it changed. Each contains an `author` User object if one exists.
 * HTTP Method: GET
 * Pathname: /accountingPeriods/{accountingPeriodId}/history
 * @function getAccountingPeriodHistory
 * @memberof AccountingAPI
 * @param {AccountingAPIGetAccountingPeriodHistoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingAPIGetAccountingPeriodHistoryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AccountingAPIGetAccountingPeriodHistoryResponse } from "../accounting-api-get-accounting-period-history-response";

type AccountingAPIGetAccountingPeriodHistoryPathParams = {
  accountingPeriodId: EnderId;
};

type AccountingAPIGetAccountingPeriodHistorySearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetAccountingPeriodHistoryPayload =
  AccountingAPIGetAccountingPeriodHistoryPathParams &
    AccountingAPIGetAccountingPeriodHistorySearchParams;

function getAccountingPeriodHistoryUnsafeEffect(
  payload: AccountingAPIGetAccountingPeriodHistoryPayload,
) {
  const { accountingPeriodId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingAPIGetAccountingPeriodHistoryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          AccountingAPIGetAccountingPeriodHistoryResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/accountingPeriods/${accountingPeriodId}/history`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAccountingPeriodHistory(
  payload: AccountingAPIGetAccountingPeriodHistoryPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingAPIGetAccountingPeriodHistoryResponse[]> {
  return F.pipe(
    payload,
    getAccountingPeriodHistoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAccountingPeriodHistory };
export type {
  AccountingAPIGetAccountingPeriodHistoryPathParams,
  AccountingAPIGetAccountingPeriodHistoryPayload,
  AccountingAPIGetAccountingPeriodHistorySearchParams,
};
