import { IconCheck, IconX } from "@tabler/icons-react";
import { Option as O } from "effect";
import { useMemo } from "react";

import type { LocalDate, Money } from "@ender/shared/core";
import { LocalDate$, Money$, Percent$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { PercentDisplay } from "@ender/shared/ds/percent-display";
import {
  DateCell,
  EnderTableTanstackV3,
  MoneyCell,
  asColumnDef,
  useTable,
  useTablePagination,
} from "@ender/shared/ui/table-tanstack";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

import type { RenewalOffer, RenewalOfferRow } from "./renewals.types";
import { getOfferDurationFromDates } from "./renewals.utils";

type AcceptedRenewalOfferRow = RenewalOfferRow & {
  acceptedTimestamp?: string;
  rescindedTimestamp?: string;
  rejectedTimestamp?: string;
};

const formatDate = (timestamp: string | undefined) => {
  if (!timestamp) {
    return "--";
  }

  const noTimeDate = new Date(timestamp);
  return LocalDate$.toFormatted(
    LocalDate$.of(noTimeDate),
    LocalDate$.Formats.DEFAULT,
  );
};

const columns = [
  asColumnDef<AcceptedRenewalOfferRow, LocalDate>({
    accessorKey: "newStartDate",
    cell: (props) =>
      getOfferDurationFromDates(
        props.row.original.newStartDate,
        props.row.original.newEndDate,
      ),
    enableSorting: false,
    header: "Duration",
  }),
  asColumnDef<AcceptedRenewalOfferRow, Money>({
    accessorKey: "newRent",
    cell: (props) => (
      <Group spacing={Spacing.none}>
        <MoneyCell {...props} />
        <span>
          (
          <PercentDisplay
            value={Percent$.parse(props.row.original.newRentPercentage)}
            complementary
            showSign
          />
          )
        </span>
      </Group>
    ),
    enableSorting: false,
    header: "Rent Amount",
  }),
  {
    accessorKey: "processingFee",
    cell: MoneyCell,
    enableSorting: false,
    header: "Processing Fee",
  },
  {
    accessorKey: "sentTimestamp",
    cell: DateCell,
    enableSorting: false,
    header: "Sent Date",
  },
  asColumnDef<AcceptedRenewalOfferRow, string>({
    accessorKey: "response",
    cell: (props) => {
      const rejectedReason = props.row.original.rejectedReason
        ? convertSnakeCaseToTitleCase(props.row.original.rejectedReason)
        : undefined;

      if (props.row.original.acceptedTimestamp) {
        return (
          <Group spacing={Spacing.sm}>
            <IconCheck color="var(--color-green-500)" />
            Accepted {formatDate(props.row.original.acceptedTimestamp)}
          </Group>
        );
      }
      if (props.row.original.rescindedTimestamp) {
        return (
          <Group spacing={Spacing.sm}>
            <IconX color="var(--color-red-500)" />
            Rejected {formatDate(props.row.original.rescindedTimestamp)}{" "}
            {rejectedReason}
          </Group>
        );
      }
      if (props.row.original.rejectedTimestamp) {
        return (
          <Group spacing={Spacing.sm}>
            <IconX color="var(--color-red-500)" />
            Rejected {formatDate(props.row.original.rejectedTimestamp)}{" "}
            {rejectedReason}
          </Group>
        );
      }
      return "--";
    },
    enableSorting: false,
    header: "Response",
  }),
];

function transformRenewalPackageOffer(offer: RenewalOffer) {
  return {
    ...offer,
    newRent: Money$.of(offer.newRent),
    processingFee: O.getOrElse(Money$.parse(offer.processingFee), () =>
      Money$.zero(),
    ),
  };
}

type RenewalOfferAcceptedTableProps = {
  offers: RenewalOffer[];
};

function RenewalOfferAcceptedTable(props: RenewalOfferAcceptedTableProps) {
  const { offers } = props;
  const data = useMemo(
    () => offers.map((offer) => transformRenewalPackageOffer(offer)),
    [offers],
  );
  const pagination = useTablePagination({ manualPagination: true });

  const table = useTable<RenewalOfferRow>({
    columns,
    data,
    pagination,
  });

  return <EnderTableTanstackV3 table={table} />;
}

export { RenewalOfferAcceptedTable };
