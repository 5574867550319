import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TieOutTypeValues = [
  "BANK_REC",
  "SECURITY_DEPOSIT",
  "PREPAYMENTS",
  "ACCOUNTS_RECEIVABLE",
  "ACCOUNTS_PAYABLE",
] as const;

const TieOutTypeEffectSchema = Schema.Literal(...TieOutTypeValues);

type TieOutType = Schema.Schema.Type<typeof TieOutTypeEffectSchema>;

const TieOutTypeEnum = castEnum(TieOutTypeEffectSchema);

function randomTieOutType(): TieOutType {
  return rand(TieOutTypeValues);
}

export {
  randomTieOutType,
  TieOutTypeEffectSchema,
  TieOutTypeEnum,
  TieOutTypeValues,
};
export type { TieOutType };
