/**
 * HTTP Method: POST
 * Pathname: /showings/{showingId}
 * @function updateShowing
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdateShowingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  LocalTime,
  SerializesInto,
} from "@ender/shared/core";
import type { ShowingReservationStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdateShowingPathParams = {
  showingId: EnderId;
};

type LeasingAPIUpdateShowingSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdateShowingBodyParams = {
  showingDate?: SerializesInto<LocalDate> | undefined;
  showingTime?: SerializesInto<LocalTime> | undefined;
  status?: ShowingReservationStatus | undefined;
};

type LeasingAPIUpdateShowingPayload = LeasingAPIUpdateShowingPathParams &
  LeasingAPIUpdateShowingSearchParams &
  LeasingAPIUpdateShowingBodyParams;

function updateShowingUnsafeEffect(payload: LeasingAPIUpdateShowingPayload) {
  const { showingId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/showings/${showingId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateShowing(
  payload: LeasingAPIUpdateShowingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateShowingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateShowing };
export type {
  LeasingAPIUpdateShowingBodyParams,
  LeasingAPIUpdateShowingPathParams,
  LeasingAPIUpdateShowingPayload,
  LeasingAPIUpdateShowingSearchParams,
};
