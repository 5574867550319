import { Schema } from "@effect/schema";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Array as A, Option as O, String as S, pipe } from "effect";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import {
  FormSearchInput,
  hydrateProperty,
  searchProperties,
} from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import { EnderIdFormSchema, LocalDate$, Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { TabButton, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  BankingAPI,
  PaymentsAPI,
} from "@ender/shared/generated/ender.api.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type InternalTransferMarkedAsModalProps = {
  onSuccess: () => void;
  onCancel: () => void;
};

const InternalTransferFormSchema = Schema.Struct({
  amount: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (value): value is O.Some<Money$.Money> =>
        O.exists(value, Money$.isPositive),
      {
        message: () => "Amount must be greater than 0.",
      },
    ),
  ),
  checkDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  checkNumber: Schema.String.pipe(
    Schema.pattern(/^[0-9a-zA-Z.]*$/, {
      message: () => "Invalid check number",
    }),
  ),
  fromBankAccountId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  ledgerDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  memo: Schema.String,
  period: Schema.Struct({
    id: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
  propertyId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  toBankAccountId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  transferType: Schema.Literal(
    MoneyTransferTransferTypeEnum.BANK_TRANSFER,
    MoneyTransferTransferTypeEnum.MARK_PAID_CHECK,
  ),
}).pipe(
  Schema.filter((schema) => {
    const issues: Schema.FilterIssue[] = [];
    if (schema.transferType === MoneyTransferTransferTypeEnum.MARK_PAID_CHECK) {
      if (O.isNone(schema.checkDate)) {
        issues.push({
          message: "Required",
          path: ["checkDate"],
        });
      }
      if (S.isEmpty(schema.checkNumber)) {
        issues.push({
          message: "Required",
          path: ["checkNumber"],
        });
      }
    }
    if (
      O.getEquivalence(S.Equivalence)(
        schema.fromBankAccountId,
        schema.toBankAccountId,
      )
    ) {
      issues.push({
        message: "Transfer from and Transfer to must be different",
        path: ["toBankAccountId"],
      });
    }
    return issues;
  }),
);

type InternalTransferFormOutput = Schema.Schema.Type<
  typeof InternalTransferFormSchema
>;

function MarkAsTransferredForm(props: InternalTransferMarkedAsModalProps) {
  const { onSuccess, onCancel } = props;
  const queryClient = useQueryClient();
  const form = useEffectSchemaForm({
    defaultValues: {
      amount: O.none(),
      checkDate: O.none(),
      checkNumber: "",
      fromBankAccountId: O.none(),
      ledgerDate: O.some(LocalDate$.today()),
      memo: "",
      period: O.none(),
      propertyId: O.none(),
      toBankAccountId: O.none(),
      transferType: MoneyTransferTransferTypeEnum.BANK_TRANSFER,
    },
    schema: InternalTransferFormSchema,
  });

  const { setValue } = form;
  const [transferType, propertyId] = useWatch({
    control: form.control,
    name: ["transferType", "propertyId"],
  });

  const {
    data: bankAccountsList = [],
    isLoading: isLoadingBankAccounts,
    refetch: refetchBankAccounts,
  } = useQuery({
    enabled: O.isSome(propertyId),
    queryFn: () =>
      BankingAPI.searchBankAccountsByFirm({
        filters: [],
        firmIds: [],
        fundIds: [],
        propertyIds: O.toArray(propertyId),
      }),
    queryKey: [
      "BankingAPI.searchBankAccountsByFirm",
      {
        filters: [],
        firmIds: [],
        fundIds: [],
        propertyIds: O.toArray(propertyId),
      },
    ],
    select: (bankAccounts) =>
      bankAccounts.map((bankAccount) => ({
        label: bankAccount.name,
        value: bankAccount.id,
      })),
  });

  // lazy useEffect
  useEffect(() => {
    if (O.isNone(propertyId)) {
      setValue("fromBankAccountId", O.none());
      setValue("toBankAccountId", O.none());
      return;
    }
  }, [propertyId, refetchBankAccounts, setValue]);

  const { mutateAsync: createInternalMarkPaidTransfer, isLoading } =
    useMutation({
      mutationFn: PaymentsAPI.createInternalMarkPaidTransfer,
    });

  async function handleSubmit(values: InternalTransferFormOutput) {
    await createInternalMarkPaidTransfer({
      amount: pipe(
        values.amount,
        O.map((val) => val.toJSON()),
        O.getOrThrow,
      ),
      fromBankAccountId: O.getOrThrow(values.fromBankAccountId),
      periodId: pipe(
        values.period,
        O.map((val) => val.id),
        O.getOrUndefined,
      ),
      propertyId: O.getOrThrow(values.propertyId),
      toBankAccountId: O.getOrThrow(values.toBankAccountId),
      transferDetails: {
        checkDate: pipe(
          values.checkDate,
          O.map((val) => val.toJSON()),
          O.getOrUndefined,
        ),
        checkNumber: values.checkNumber,
        invoiceIds: [],
        ledgerDate: pipe(
          values.ledgerDate,
          O.map((val) => val.toJSON()),
          O.getOrThrow,
        ),
        memo: values.memo,
        transferType: values.transferType,
      },
    });
    showSuccessNotification({ message: "Transfer Recorded Successfully." });
    onSuccess();
    queryClient.invalidateQueries(["getInternalTransfers"]);
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack spacing={Spacing.lg}>
        <H1>Mark as Transferred</H1>

        <Tabs
          value={transferType}
          onChange={(value) => setValue("transferType", value)}>
          <TabsList variant="outlined">
            <TabButton value={MoneyTransferTransferTypeEnum.BANK_TRANSFER}>
              Bank Transfer
            </TabButton>
            <TabButton value={MoneyTransferTransferTypeEnum.MARK_PAID_CHECK}>
              Check
            </TabButton>
          </TabsList>
        </Tabs>

        <FormSearchInput
          modelType={ModelTypeEnum.PROPERTY}
          form={form}
          name="propertyId"
          label="Property"
          placeholder="Select Property"
          search={searchProperties}
          hydrate={hydrateProperty}
        />
        <FormMoneyInput form={form} name="amount" label="Amount" />
        <FormSelect
          form={form}
          name="fromBankAccountId"
          label="Transfer from"
          placeholder="Select bank account"
          disabled={
            isLoadingBankAccounts ||
            A.isEmptyArray(bankAccountsList) ||
            O.isNone(propertyId)
          }
          data={bankAccountsList}
        />
        <FormSelect
          form={form}
          name="toBankAccountId"
          label="Transfer to"
          placeholder="Select bank account"
          disabled={
            isLoadingBankAccounts ||
            A.isEmptyArray(bankAccountsList) ||
            O.isNone(propertyId)
          }
          data={bankAccountsList}
        />
        {transferType === MoneyTransferTransferTypeEnum.MARK_PAID_CHECK && (
          <>
            <FormTextInput
              form={form}
              name="checkNumber"
              label="Check number"
            />
            <FormDateInput form={form} name="checkDate" label="Check date" />
          </>
        )}
        <FormDateInput form={form} name="ledgerDate" label="Transfer date" />
        <FormAccountingPeriodSelector
          form={form}
          name="period"
          label="Accounting Period (optional)"
          periodType={AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER}
        />
        <FormTextarea form={form} name="memo" label="Memo (optional)" />

        <Group justify={Justify.end}>
          <Button variant={ButtonVariant.outlined} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" loading={isLoading}>
            Confirm Transfer
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { MarkAsTransferredForm };
