/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/approve
 * @function approveInspectionBudget
 * @memberof InspectionAPI
 * @param {InspectionAPIApproveInspectionBudgetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIApproveInspectionBudgetPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIApproveInspectionBudgetSearchParams = {
  token?: string | undefined;
};

type InspectionAPIApproveInspectionBudgetPayload =
  InspectionAPIApproveInspectionBudgetPathParams &
    InspectionAPIApproveInspectionBudgetSearchParams;

function approveInspectionBudgetUnsafeEffect(
  payload: InspectionAPIApproveInspectionBudgetPayload,
) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/approve`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function approveInspectionBudget(
  payload: InspectionAPIApproveInspectionBudgetPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    approveInspectionBudgetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { approveInspectionBudget };
export type {
  InspectionAPIApproveInspectionBudgetPathParams,
  InspectionAPIApproveInspectionBudgetPayload,
  InspectionAPIApproveInspectionBudgetSearchParams,
};
