/**
 * HTTP Method: GET
 * Pathname: /builtInFactor
 * @function getBuiltInFactor
 * @memberof FactorsAPI
 * @param {FactorsAPIGetBuiltInFactorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FactorsAPIGetBuiltInFactorResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { FactorsAPIGetBuiltInFactorResponse } from "../factors-api-get-built-in-factor-response";

type FactorsAPIGetBuiltInFactorSearchParams = {
  token?: string | undefined;
  factorType: string;
};

type FactorsAPIGetBuiltInFactorPayload = FactorsAPIGetBuiltInFactorSearchParams;

function getBuiltInFactorUnsafeEffect(
  payload: FactorsAPIGetBuiltInFactorPayload,
) {
  const { token, factorType, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FactorsAPIGetBuiltInFactorResponse>({
        body,
        decode: unsafeDecodeJsonResponse<FactorsAPIGetBuiltInFactorResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/builtInFactor",
        searchParams: [
          ["token", token],
          ["factorType", factorType],
        ],
      }),
    ),
  );
}

function getBuiltInFactor(
  payload: FactorsAPIGetBuiltInFactorPayload,
  options?: { signal?: AbortSignal },
): Promise<FactorsAPIGetBuiltInFactorResponse> {
  return F.pipe(
    payload,
    getBuiltInFactorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBuiltInFactor };
export type {
  FactorsAPIGetBuiltInFactorPayload,
  FactorsAPIGetBuiltInFactorSearchParams,
};
