/**
 * HTTP Method: POST
 * Pathname: /accounting/journal-entries/{journalEntryId}
 * @function editGLJournalEntry
 * @memberof AccountingAPI
 * @param {AccountingAPIEditGLJournalEntryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { CreateJournalEntryRequestGLTx } from "@ender/shared/generated/ender.model.accounting.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIEditGLJournalEntryPathParams = {
  journalEntryId: EnderId;
};

type AccountingAPIEditGLJournalEntrySearchParams = {
  token?: string | undefined;
};

type AccountingAPIEditGLJournalEntryBodyParams = {
  approverId?: EnderId | undefined;
  credits: CreateJournalEntryRequestGLTx[];
  debits: CreateJournalEntryRequestGLTx[];
  ledgerDate?: SerializesInto<LocalDate> | undefined;
  period?: SerializesInto<LocalDate> | undefined;
  reversalDate?: SerializesInto<LocalDate> | undefined;
  reversalPeriod?: SerializesInto<LocalDate> | undefined;
  title?: string | undefined;
};

type AccountingAPIEditGLJournalEntryPayload =
  AccountingAPIEditGLJournalEntryPathParams &
    AccountingAPIEditGLJournalEntrySearchParams &
    AccountingAPIEditGLJournalEntryBodyParams;

function editGLJournalEntryUnsafeEffect(
  payload: AccountingAPIEditGLJournalEntryPayload,
) {
  const { journalEntryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accounting/journal-entries/${journalEntryId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function editGLJournalEntry(
  payload: AccountingAPIEditGLJournalEntryPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    editGLJournalEntryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { editGLJournalEntry };
export type {
  AccountingAPIEditGLJournalEntryBodyParams,
  AccountingAPIEditGLJournalEntryPathParams,
  AccountingAPIEditGLJournalEntryPayload,
  AccountingAPIEditGLJournalEntrySearchParams,
};
