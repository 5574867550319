import * as S from "@effect/schema/Schema";
import * as O from "effect/Option";

import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { EnderIdFormSchema, LocalDateBrandSchema } from "@ender/shared/core";
import { PartyEffectSchema } from "@ender/shared/generated/ender.model.payments";
import { InvoicePayableTypeEffectSchema } from "@ender/shared/generated/ender.model.payments.invoice";

const PayeeSchema = S.Struct({
  id: EnderIdFormSchema,
  name: S.optional(S.String),
  party: PartyEffectSchema,
});

const AccountingPeriodSchema = S.Struct({
  startDate: S.typeSchema(LocalDateBrandSchema),
});

const EditInvoiceFormSchema = S.Struct({
  accountingPeriod: AccountingPeriodSchema.pipe(S.OptionFromSelf),
  checkMemo: S.String,
  checkNumber: S.String.pipe(
    S.nonEmptyString({ message: () => "Check Number is required." }),
  ),
  description: S.String.pipe(
    S.nonEmptyString({ message: () => "Description is required." }),
  ),
  externalInvoiceId: S.String.pipe(
    S.nonEmptyString({ message: () => "Invoice Number is required." }),
  ),
  ledgerDate: S.OptionFromSelf(LocalDateEffectSchema).pipe(
    S.filter(O.isSome, { message: () => "Invoice Number is required." }),
  ),
  payableType: InvoicePayableTypeEffectSchema.pipe(S.OptionFromSelf),
  payee: S.optional(PayeeSchema),
  poNumbers: S.String.pipe(
    S.filter(
      (value) => {
        if (!value) {
          return true;
        }
        const poNumbers = value.split(",").map((n) => Number(n.trim()));
        return poNumbers.every((n) => n <= 9999);
      },
      {
        message: () =>
          "Each Purchase Order Number must be no more than 4 numeric digits.",
      },
    ),
  ),
});
type CreateEditInvoiceFormSchemaProps = {
  isCheckInfoEditable: boolean;
};

const createEditInvoiceFormSchema = (props: CreateEditInvoiceFormSchemaProps) =>
  S.Struct({
    accountingPeriod: AccountingPeriodSchema.pipe(S.OptionFromSelf),
    checkMemo: S.String,
    checkNumber: props.isCheckInfoEditable
      ? S.String.pipe(
          S.nonEmptyString({ message: () => "Check Number is required." }),
        )
      : S.String,
    description: S.String.pipe(
      S.nonEmptyString({ message: () => "Description is required." }),
    ),
    externalInvoiceId: S.String.pipe(
      S.nonEmptyString({ message: () => "Invoice Number is required." }),
    ),
    ledgerDate: S.OptionFromSelf(LocalDateEffectSchema).pipe(
      S.filter(O.isSome, { message: () => "Invoice Number is required." }),
    ),
    payableType: InvoicePayableTypeEffectSchema.pipe(S.OptionFromSelf),
    payee: S.optional(PayeeSchema),
    poNumbers: S.String.pipe(
      S.filter(
        (value) => {
          if (!value) {
            return true;
          }
          const poNumbers = value.split(",").map((n) => Number(n.trim()));
          return poNumbers.every((n) => n <= 9999);
        },
        {
          message: () =>
            "Each Purchase Order Number must be no more than 4 numeric digits.",
        },
      ),
    ),
  });

type EditInvoiceFormInput = S.Schema.Encoded<typeof EditInvoiceFormSchema>;
type EditInvoiceFormOutput = S.Schema.Type<typeof EditInvoiceFormSchema>;

export { createEditInvoiceFormSchema, EditInvoiceFormSchema };
export type { EditInvoiceFormInput, EditInvoiceFormOutput };
