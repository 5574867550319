import { String as S } from "effect";
import type { ElementRef, ForwardedRef, ReactNode, Ref } from "react";
import { forwardRef, useCallback, useMemo, useState } from "react";

import type { SelectValue } from "@ender/shared/ds/select";
import type { MultiFilterProps } from "@ender/widgets/filters/multi-filter";
import { MultiFilter } from "@ender/widgets/filters/multi-filter";

type MultiFilterSyncProps<
  V extends SelectValue = SelectValue,
  M = unknown,
> = Omit<MultiFilterProps<V, M>, "keyword" | "onKeywordChange">;

/**
 * MultiFilterSync is a wrapper around the original MultiFilter component.
 * It manages internal state for keyword filtering and passes the filtered data
 * down to the MultiFilter.
 * - Note: In order to use this component, the full set of data needs to be known ahead of time.
 * - This does NOT support BE filtering.
 */
const MultiFilterSync = forwardRef(function MultiFilterSync<
  V extends SelectValue = SelectValue,
  M = unknown,
>(
  props: MultiFilterSyncProps<V, M>,
  ref: ForwardedRef<ElementRef<typeof MultiFilter>>,
) {
  const [keyword, setKeyword] = useState<string>("");

  const handleKeywordChange = useCallback((newKeyword: string) => {
    setKeyword(newKeyword);
  }, []);

  const { data } = props;
  const filteredData = useMemo(() => {
    const searchText = keyword.trim().toLowerCase();
    return S.isNonEmpty(searchText)
      ? data.filter((option) => option.label.toLowerCase().includes(searchText))
      : data;
  }, [data, keyword]);

  return (
    <MultiFilter
      {...props}
      data={filteredData}
      keyword={keyword}
      onKeywordChange={handleKeywordChange}
      ref={ref}
    />
  );
}) as <V extends SelectValue = SelectValue, M = unknown>(
  // eslint-disable-next-line no-use-before-define
  props: MultiFilterSyncProps<V, M> & {
    ref?: Ref<ElementRef<typeof MultiFilter>>;
  },
) => ReactNode;

export { MultiFilterSync };

export type { MultiFilterSyncProps };
