import * as S from "effect/String";

import { UNDEFINED } from "@ender/shared/constants/general";
import { Text } from "@ender/shared/ds/text";
import type { TimelineEntry } from "@ender/shared/types/ender-general";
import { Color } from "@ender/shared/utils/theming";

import { Bubble } from "../bubble/bubble";
import { ChatDocument } from "./chat-document";
import { ChatInvoice } from "./chat-invoice";
import { ChatPhoneCall } from "./chat-phone-call";

const TimelineEntryComponent = (
  props: TimelineEntry & { previous?: TimelineEntry; error?: string },
) => {
  const timeDifference =
    (props.dateTime.unix - (props.previous?.dateTime.unix ?? 0)) / 1000;
  const sendersEqual = props.author?.id === props.previous?.author?.id;
  const header = S.isNonEmpty(props.error ?? "") ? (
    <Text
      //@ts-expect-error text does not yet support color
      color={Color.red}>
      Message Unable to send
      <br />
      {props.error}
    </Text>
  ) : (
    UNDEFINED
  );
  switch (props.command) {
    case "chat": {
      const color = props.author.isMe ? Color.primary : undefined;
      return (
        <Bubble
          sender={props.author.name}
          header={header}
          hideHeader={
            S.isEmpty(props.error ?? "") && sendersEqual && timeDifference < 60
          }
          timestamp={props.dateTime.unix}
          message={props.text}
          right={props.author.isMe}
          corner={props.author.isMe ? "bottom-right" : "top-left"}
          color={S.isNonEmpty(props.error ?? "") ? Color.red : color}
        />
      );
    }
    case "document":
      return (
        <Bubble
          sender={props.author?.name}
          header={header}
          hideHeader={sendersEqual && timeDifference < 60}
          timestamp={props.dateTime.unix}
          message={<ChatDocument {...props} />}
          right={props.author?.isMe}
          image
          corner={props.author?.isMe ? "bottom-right" : "top-left"}
          color={props.author?.isMe ? "primary" : undefined}
        />
      );
    case "invoice":
      return (
        <Bubble
          sender={props.author?.name}
          header={header}
          hideHeader={sendersEqual && timeDifference < 60}
          timestamp={props.dateTime.unix}
          message={<ChatInvoice {...props} />}
          right={props.author?.isMe}
          corner={props.author?.isMe ? "bottom-right" : "top-left"}
          color={props.author?.isMe ? "primary" : undefined}
        />
      );
    case "call":
      return (
        <Bubble
          image
          sender={props.author?.name}
          header={header}
          hideHeader={sendersEqual && timeDifference < 60}
          timestamp={props.dateTime.unix}
          message={<ChatPhoneCall />}
        />
      );
    case "task":
      return (
        <Bubble
          sender={props.author?.name}
          header={header}
          timestamp={props.dateTime.unix}
          message={<a href={props.url}>{props.description}</a>}
        />
      );
  }
};

export { TimelineEntryComponent as ChatTimelineEntry };
