import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ChargeScheduleFrequencyValues = ["ONCE", "MONTHLY", "ANNUALLY"] as const;

const ChargeScheduleFrequencyEffectSchema = Schema.Literal(
  ...ChargeScheduleFrequencyValues,
);

type ChargeScheduleFrequency = Schema.Schema.Type<
  typeof ChargeScheduleFrequencyEffectSchema
>;

const ChargeScheduleFrequencyEnum = castEnum(
  ChargeScheduleFrequencyEffectSchema,
);

function randomChargeScheduleFrequency(): ChargeScheduleFrequency {
  return rand(ChargeScheduleFrequencyValues);
}

export {
  ChargeScheduleFrequencyEffectSchema,
  ChargeScheduleFrequencyEnum,
  ChargeScheduleFrequencyValues,
  randomChargeScheduleFrequency,
};
export type { ChargeScheduleFrequency };
