/**
 * Removes a bank account from the system. The DB row is not deleted, just transitioned to a status which implies 'not
 in use' to hide it from the UI.
 * HTTP Method: DELETE
 * Pathname: /bankAccounts/{bankAccountId}
 * @function deleteBankAccount
 * @memberof BankingAPI
 * @param {BankingAPIDeleteBankAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIDeleteBankAccountPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIDeleteBankAccountSearchParams = {
  token?: string | undefined;
};

type BankingAPIDeleteBankAccountBodyParams = {
  overrideWarnings?: boolean | undefined;
};

type BankingAPIDeleteBankAccountPayload =
  BankingAPIDeleteBankAccountPathParams &
    BankingAPIDeleteBankAccountSearchParams &
    BankingAPIDeleteBankAccountBodyParams;

function deleteBankAccountUnsafeEffect(
  payload: BankingAPIDeleteBankAccountPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/bankAccounts/${bankAccountId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteBankAccount(
  payload: BankingAPIDeleteBankAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteBankAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteBankAccount };
export type {
  BankingAPIDeleteBankAccountBodyParams,
  BankingAPIDeleteBankAccountPathParams,
  BankingAPIDeleteBankAccountPayload,
  BankingAPIDeleteBankAccountSearchParams,
};
