import { IconSearch } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import * as S from "effect/String";
import type { ElementRef, ForwardedRef, ReactNode, Ref } from "react";
import { forwardRef, useRef, useState } from "react";

import type { InputBaseProps } from "@ender/shared/ds/input";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import type { SelectOption, SelectValue } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import { capitalize } from "@ender/shared/utils/string";

/**
 * A function that takes a keyword and returns a promise of an array of SelectOption objects.
 */
type KeywordSearch<T extends SelectValue> = (
  keyword: string,
) => Promise<SelectOption<T>[]>;
/**
 * A function that takes a value and returns a promise of an array of SelectOption objects.
 * This is used to hydrate the select with a label when the value is known.
 */
type ValueHydrate<T extends SelectValue> = (
  value: T,
) => Promise<SelectOption<T>[]>;

type SearchInputElement<T extends string> = ElementRef<typeof Select<T>>;
type SearchInputProps<T extends SelectValue = SelectValue> = {
  value: O.Option<T>;
  onChange: (value: O.Option<T>, item: O.Option<SelectOption<T>>) => void;
  /**
   * The promise function that this component will run when searching.
   * should always return an array of {label:string, value:string} objects.
   * T is typically an EnderId but can be any string-based type
   */
  search: KeywordSearch<T>;
  /**
   * takes a function that can be used to populate the value of the select based on the known selected value.
   * This is because the selected value is an ID, but the select needs a label to display.
   *
   * You can return multiple values if you want to display multiple select options before the user has searched.
   */
  hydrate?: ValueHydrate<T>;
  /**
   * required for providing an accurate key to the useQuery hook.
   * we use ModelType to ensure consistency in keys
   */
  modelType: ModelType;
  /**
   * how long between user inputs should the search be triggered
   * @default 300 ms
   */
  debounce?: number;
  searchOnEmpty?: boolean;
  clearable?: boolean;
} & Omit<InputBaseProps, "leftSection" | "rightSection">;

const SearchInput = forwardRef(function SearchInput<T extends string>(
  props: SearchInputProps<T>,
  ref: ForwardedRef<SearchInputElement<T>>,
) {
  const {
    borderless,
    debounce = 300,
    search,
    disabled,
    label,
    value,
    placeholder,
    searchOnEmpty,
    modelType,
    onChange,
    hydrate,
    clearable,
    error,
    description,
    size,
  } = props;

  const [keyword, setKeyword] = useState("");
  const debounceSearch = useDebounce(setKeyword, debounce);

  //whether the data has been _actually_ fetched from search, not just hydrated
  const isFetchedRef = useRef(false);

  const { data: hydratedData = [] } = useQuery({
    enabled:
      O.isSome(value) && !isFetchedRef.current && P.isNotNullable(hydrate),
    queryFn: () => hydrate?.(O.getOrThrow(value)),
    queryKey: [`hydrate${capitalize(modelType)}`, value],
  });

  const {
    data: searchResultData = hydratedData,
    isFetched,
    isFetching,
  } = useQuery(
    [`search${capitalize(modelType)}`, keyword],
    () => search(keyword),
    {
      enabled: searchOnEmpty || S.isNonEmpty(keyword),
      placeholderData: (S.isEmpty(keyword) && hydratedData) || [],
    },
  );
  isFetchedRef.current = isFetched;

  return (
    <Select
      borderless={borderless}
      ref={ref}
      leftSection={isFetching ? <LoadingSpinner /> : <IconSearch size={16} />}
      //do no filtering. Filtering/sorting is done on the server during the request
      filterFn={() => true}
      data={searchResultData}
      value={value}
      onChange={onChange}
      onKeywordChange={debounceSearch}
      disabled={disabled}
      label={label}
      error={error}
      description={description}
      placeholder={placeholder}
      clearable={clearable}
      role="searchbox"
      size={size}
    />
  );
}) as <T extends string>(
  // eslint-disable-next-line no-use-before-define
  props: SearchInputProps<T> & { ref?: Ref<SearchInputElement<T>> },
) => ReactNode;

export { SearchInput };

export type { KeywordSearch, SearchInputProps, ValueHydrate };
