/**
 * HTTP Method: POST
 * Pathname: /chargeSchedules/{chargeScheduleId}/replace
 * @function replaceChargeSchedule
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPIReplaceChargeSchedulePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ChargeSchedulesAPIReplaceChargeScheduleResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { ChargeScheduleFrequency } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ChargeSchedulesAPIReplaceChargeScheduleResponse } from "../charge-schedules-api-replace-charge-schedule-response";

type ChargeSchedulesAPIReplaceChargeSchedulePathParams = {
  chargeScheduleId: EnderId;
};

type ChargeSchedulesAPIReplaceChargeScheduleSearchParams = {
  token?: string | undefined;
};

type ChargeSchedulesAPIReplaceChargeScheduleBodyParams = {
  amount: SerializesInto<Money>;
  chargeForTerminatedLease?: boolean | undefined;
  frequency: ChargeScheduleFrequency;
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  overrideWarnings?: boolean | undefined;
  startDate: SerializesInto<LocalDate>;
};

type ChargeSchedulesAPIReplaceChargeSchedulePayload =
  ChargeSchedulesAPIReplaceChargeSchedulePathParams &
    ChargeSchedulesAPIReplaceChargeScheduleSearchParams &
    ChargeSchedulesAPIReplaceChargeScheduleBodyParams;

function replaceChargeScheduleUnsafeEffect(
  payload: ChargeSchedulesAPIReplaceChargeSchedulePayload,
) {
  const { chargeScheduleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ChargeSchedulesAPIReplaceChargeScheduleResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ChargeSchedulesAPIReplaceChargeScheduleResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/chargeSchedules/${chargeScheduleId}/replace`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function replaceChargeSchedule(
  payload: ChargeSchedulesAPIReplaceChargeSchedulePayload,
  options?: { signal?: AbortSignal },
): Promise<ChargeSchedulesAPIReplaceChargeScheduleResponse> {
  return F.pipe(
    payload,
    replaceChargeScheduleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { replaceChargeSchedule };
export type {
  ChargeSchedulesAPIReplaceChargeScheduleBodyParams,
  ChargeSchedulesAPIReplaceChargeSchedulePathParams,
  ChargeSchedulesAPIReplaceChargeSchedulePayload,
  ChargeSchedulesAPIReplaceChargeScheduleSearchParams,
};
