import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O, String as Str, pipe } from "effect";

import { Form, useForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { CollectionsAPI } from "@ender/shared/generated/ender.api.finance";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import type { CollectionsRowWithNextStep } from "../collections.types";

type BulkSkipDelinquencyActionsModalProps = {
  onClose: () => unknown;
  onSuccess: () => unknown;
  selectedRows: CollectionsRowWithNextStep[];
};

const BulkSkipDelinquencyActionsFormSchema = Schema.Struct({
  notes: Schema.String,
});
type BulkSkipDelinquencyActionsFormValues = Schema.Schema.Type<
  typeof BulkSkipDelinquencyActionsFormSchema
>;

function BulkSkipDelinquencyActionsForm({
  onClose,
  onSuccess,
  selectedRows,
}: BulkSkipDelinquencyActionsModalProps) {
  const form = useForm({
    defaultValues: {
      notes: "",
    },
    resolver: effectTsResolver(BulkSkipDelinquencyActionsFormSchema),
  });

  const { isLoading, mutateAsync: bulkSkipDelinquencyActions } = useMutation({
    mutationFn: CollectionsAPI.bulkSkipAction,
    mutationKey: ["CollectionsAPI.bulkSkipAction"],
  });

  async function handleFormSubmit({
    notes,
  }: BulkSkipDelinquencyActionsFormValues) {
    const stepIdToLeaseIds = selectedRows.reduce<
      Partial<Record<EnderId, EnderId[]>>
    >((acc, item) => {
      const stepId = item.nextStep.id;
      const leaseId = item.leaseId;

      acc[stepId] = acc[stepId] ?? [];
      acc[stepId].push(leaseId);

      return acc;
    }, {});

    await bulkSkipDelinquencyActions({
      notes: pipe(
        notes,
        O.some,
        O.map(Str.trim),
        O.filter(Str.isNonEmpty),
        O.getOrUndefined,
      ),
      stepIdToLeaseIds,
    });

    onClose();
    showSuccessNotification({
      message: `Skipped Delinquency Actions for ${selectedRows.length} leases.`,
    });
    onSuccess();
  }

  return (
    <Stack spacing={Spacing.sm}>
      <Form form={form} onSubmit={handleFormSubmit}>
        <Stack spacing={Spacing.xl}>
          <FormTextarea label="Notes" name="notes" form={form} />
          <Group justify={Justify.end}>
            <Button
              variant={ButtonVariant.outlined}
              type="button"
              onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" loading={isLoading}>
              Skip Actions
            </Button>
          </Group>
        </Stack>
      </Form>
    </Stack>
  );
}

export { BulkSkipDelinquencyActionsForm };
