import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyCatPolicyValues = ["NO_CATS_ALLOWED", "CATS_ALLOWED"] as const;

const PropertyCatPolicyEffectSchema = Schema.Literal(
  ...PropertyCatPolicyValues,
);

type PropertyCatPolicy = Schema.Schema.Type<
  typeof PropertyCatPolicyEffectSchema
>;

const PropertyCatPolicyEnum = castEnum(PropertyCatPolicyEffectSchema);

function randomPropertyCatPolicy(): PropertyCatPolicy {
  return rand(PropertyCatPolicyValues);
}

export {
  PropertyCatPolicyEffectSchema,
  PropertyCatPolicyEnum,
  PropertyCatPolicyValues,
  randomPropertyCatPolicy,
};
export type { PropertyCatPolicy };
