import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Function as F } from "effect";
import { useCallback } from "react";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { ApprovalStepComponent } from "./approval-step";
import { CreateUpdateApprovalStepForm } from "./create-update-approval-step-form";

type ApprovalProcessProps = {
  approvalProcessType: ApprovalProcessType;
  onProcessUpdated?: () => void;
};

function ApprovalProcess({
  approvalProcessType,
  onProcessUpdated = F.constVoid,
}: ApprovalProcessProps) {
  const {
    data: { steps } = { steps: [] },
    isLoading,
    refetch: fetchSteps,
  } = useQuery({
    queryKey: [
      "ApprovalsAPI.getDefaultApprovalProcess",
      approvalProcessType,
    ] as const,
    queryFn: ({ signal }) =>
      ApprovalsAPI.getDefaultApprovalProcess(
        { approvalProcessType },
        { signal },
      ),
  });
  const [isAddApprovalStepModalOpen, approvalStepModalHandlers] =
    useBoolean(false);

  const onApprovalProcessChange = useCallback(() => {
    onProcessUpdated();
    approvalStepModalHandlers.setFalse();
    fetchSteps();
  }, [onProcessUpdated, fetchSteps, approvalStepModalHandlers]);

  return (
    <Skeleton visible={isLoading}>
      <Stack>
        <Modal
          title="Add Step"
          opened={isAddApprovalStepModalOpen}
          onClose={approvalStepModalHandlers.setFalse}>
          <CreateUpdateApprovalStepForm
            onSuccess={onApprovalProcessChange}
            approvalProcessType={approvalProcessType}
          />
        </Modal>
        {steps.map((step, idx) => (
          <Group key={step.id}>
            <Group align={Align.center}>{`${idx}.`}</Group>
            <div style={{ flexGrow: 1 }}>
              <ApprovalStepComponent
                step={step}
                onStepUpdated={onApprovalProcessChange}
                canDelete={steps.length > 1}
                key={step.id}
              />
            </div>
          </Group>
        ))}

        <Button
          variant={ButtonVariant.transparent}
          onClick={approvalStepModalHandlers.setTrue}
          leftSection={<IconPlus />}>
          Add Approval Step
        </Button>
      </Stack>
    </Skeleton>
  );
}

export { ApprovalProcess };
