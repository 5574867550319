// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { openSpotlight } from "@mantine/spotlight";
import {
  IconHelpHexagon,
  IconMenu2,
  IconSettings,
  IconX,
} from "@tabler/icons-react";
import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Option as O, Predicate as P } from "effect";
import type { SyntheticEvent } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { handleLogout, useAuthActor } from "@ender/features/auth";
import { UserContext } from "@ender/shared/contexts/user";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import { useViewportSize } from "@ender/shared/hooks/use-viewport-size";
import { useEnvironmentStore } from "@ender/shared/stores/environment-store";
import { EnderDrawer } from "@ender/shared/ui/ender-drawer";
import { LogoImage } from "@ender/shared/ui/logo-image";
import type { NavIconLinkProps } from "@ender/shared/ui/nav-icon-link";
import { NavIconLink } from "@ender/shared/ui/nav-icon-link";
import { lazyImport } from "@ender/shared/utils/lazy-import";
import { Size } from "@ender/shared/utils/theming";

import styles from "./nav.module.css";

const PMLinks = lazyImport(
  () => import("../(routes)/system/pm/pm.routes"),
  "PMLinks",
);
const TenantPortalLinks = lazyImport(
  () => import("../(routes)/tenant-portal/tenant-portal.routes"),
  "TenantPortalLinks",
);
const VendorPortalLinks = lazyImport(
  () => import("../(routes)/maintenance/vendor-portal.routes"),
  "VendorPortalLinks",
);
const BrokerPortalLinks = lazyImport(
  () => import("../(routes)/finance/broker-portal.routes"),
  "BrokerPortalLinks",
);
const OwnerPortalLinks = lazyImport(
  () => import("../(routes)/maintenance/owner-portal.routes"),
  "OwnerPortalLinks",
);

type FooterLinksProps = {
  mobile?: boolean;
};

type LinksProps = Pick<NavIconLinkProps, "mobile"> & {
  user: User;
  permissions: Record<FunctionalPermission, boolean>;
};

function NavIconAdmin({ color = "currentColor" }: IconProps) {
  return (
    <svg
      fill={color}
      height="22"
      viewBox="0 0 25 22"
      width="25"
      xmlns="http://www.w3.org/2000/svg">
      <path d="m12.4912 21.8737h-11.43912c-1.35586 0-1.428585-2.0304-.058565-2.114h.036835c.35562-.0142.68998-.2239.8345-.5583.23236-.5072.40519-1.4139-.16719-3.09-.31877-.9208-.12324-2.4.60923-3.6553.57233-.986 1.96499-2.69042 4.8014-3.04604.4562-.05808.90674-.08642 1.33462-.08642 2.30129 0 3.80989.83236 4.97529 1.47536.1354.0747.2662.1469.393.2149 1.3277.7112 3.3962.5653 4.7521-1.08104.2097-.25505.1955-.54406.0793-.76224-2.0599-1.53712-3.8379-6.4264-4.5123-8.486417-.1304-.413708.2607-.797661.6673-.6531472 1.4664.5215602 4.2008 1.9213242 5.2308 5.2805242.588 1.91534.5653 3.54747.5441 3.80108 1.9914.41302 2.3492.87683 3.2018 1.98204l.0043.0057.0248.032c.168.2172.356.4602.5774.7303.4491.544.6446.9351.6163 1.2255-.0213.2309-.9649 1.2326-1.3276 1.2326-.0126 0-.0611-.0059-.1379-.0153-.5369-.0655-2.458-.2998-3.2128.1315-1.1022.639-1.7851 1.1037-1.9948 3.4824-.051.5158.3627 2.1833.7466 2.699.3854.5228.0227 1.2553-.6234 1.2553h-.3697c-.3557 0-.69-.1658-.8997-.4491-2.9963-4.0846-7.32608-1.6251-6.7027-1.6251 1.0003 0 1.8135.333 2.4298.7466.588.3996.3046 1.3276-.4137 1.3276z" />
    </svg>
  );
}

/**
 * nav links specific to Ender users.
 */
function EnderLinks({ mobile = false }: Partial<LinksProps>) {
  return (
    <NavIconLink
      label="Admin"
      href="/admin"
      icon={<NavIconAdmin />}
      mobile={mobile}
    />
  );
}

function Links({ user, mobile = false }: LinksProps) {
  return (
    <div style={{ paddingLeft: 4, paddingRight: 4 }}>
      <Stack spacing={Spacing.sm}>
        {mobile && user.isPM && (
          <div className={styles.link} onClick={() => openSpotlight()}>
            <Text color="inherit" size={FontSize.lg} align="center">
              Search
            </Text>
          </div>
        )}
        {user?.isEnder && <EnderLinks mobile={mobile} />}
        {user?.isInvestor && <OwnerPortalLinks mobile={mobile} />}
        {user?.isBroker && <BrokerPortalLinks mobile={mobile} />}
        {user?.isPM && <PMLinks mobile={mobile} />}
        {user?.isVendor && <VendorPortalLinks mobile={mobile} />}
        {user?.isTenant && <TenantPortalLinks mobile={mobile} />}
      </Stack>
    </div>
  );
}

function FooterLinks({ mobile = false }: FooterLinksProps) {
  const { user } = useContext(UserContext);

  const { isPM, isVendor } = user;

  const onSignOutClick = useCallback((ev: SyntheticEvent<unknown>) => {
    ev.preventDefault();
    handleLogout();
  }, []);

  return (
    <div style={{ paddingLeft: 4, paddingRight: 4 }}>
      <Stack spacing={Spacing.sm}>
        {mobile && isPM && (
          <Link className={styles.link} to="/inbox">
            <Text color="inherit" size={FontSize.lg} align="center">
              Inbox
            </Text>
          </Link>
        )}
        {mobile && (
          <a className={styles.link} href="/profile">
            <Text color="inherit" size={FontSize.lg} align="center">
              Edit Profile
            </Text>
          </a>
        )}
        {isPM && (
          <>
            <NavIconLink
              label="Support"
              href="/customer-support"
              icon={<IconHelpHexagon />}
              mobile={mobile}
            />
            <NavIconLink
              label="Settings"
              href="/firm-settings"
              icon={<IconSettings />}
              mobile={mobile}
            />
          </>
        )}
        {isVendor && (
          <NavIconLink
            label="Settings"
            href="/vendor-settings"
            icon={<IconSettings />}
            mobile={mobile}
          />
        )}
        {mobile && (
          <a
            href="/login"
            className={styles.link}
            onClick={onSignOutClick}
            style={{ cursor: "pointer" }}>
            <Text color="inherit" size={FontSize.lg} align="center">
              Sign Out
            </Text>
          </a>
        )}
      </Stack>
    </div>
  );
}

type NavProps = {
  user: User;
  permissions: Record<FunctionalPermission, boolean>;
};

function NavLeft({ user, permissions }: NavProps) {
  return (
    <>
      <div className={styles.navBg} />
      <div className={styles.navCorner}>
        <Group justify={Justify.center} align={Align.center}>
          <a href="/app" className={styles.logo}>
            <LogoImage variant="SMALL_ALT" />
          </a>
        </Group>
        <Divider />
      </div>
      <nav className={styles.navLeft}>
        <Links user={user} permissions={permissions} />
        <Divider />
        <FooterLinks />
      </nav>
    </>
  );
}

function NavMobile({ user, permissions }: NavProps) {
  const [opened, setOpened] = useState(false);
  const [pathName, setPathName] = useState("");

  const { location } = useHistory();

  // lazy useEffect
  useEffect(() => {
    if (location.pathname !== pathName) {
      setPathName(location.pathname);
      setOpened(false);
    }
  }, [location, pathName]);

  return (
    <div
      className={styles.navTop}
      style={{ paddingLeft: "24px", paddingRight: "14px" }}>
      <Group justify={Justify.between} align={Align.center}>
        <EnderDrawer
          withCloseButton={false}
          opened={opened}
          onClose={() => setOpened(false)}
          classNames={{ drawer: styles.navDrawer }}
          position="right"
          size="full">
          <ActionIcon
            variant={ButtonVariant.transparent}
            size={Size.sm}
            onClick={() => setOpened(false)}>
            <IconX size={24} color="white" />
          </ActionIcon>
          <nav>
            <Stack spacing={Spacing.sm}>
              <Links user={user} permissions={permissions} mobile />
              <FooterLinks mobile />
            </Stack>
          </nav>
        </EnderDrawer>

        <a href="/app">
          <LogoImage variant="LARGE_ALT" />
        </a>
        <ActionIcon
          variant="transparent"
          size="xl"
          onClick={() => setOpened(true)}>
          <IconMenu2 size={24} color="white" />
        </ActionIcon>
      </Group>
    </div>
  );
}

function Nav() {
  const [authSnapshot] = useAuthActor();
  const session = O.getOrUndefined(authSnapshot.context.session);

  const { user, originalUser, permissions } = useContext(UserContext);
  const isProduction = useEnvironmentStore((state) => state.isProduction);

  const { width: viewportWidth } = useViewportSize();
  const isMobile = viewportWidth <= 1080;

  const isMasquerading =
    P.isNotNullable(user) &&
    P.isNotNullable(originalUser) &&
    originalUser.id !== user.id;
  const showAdmin = user?.isEnder || isMasquerading;
  const dangermode = isMasquerading && session && !session.readOnly;

  const location = useLocation();
  const locationPath = location.pathname;

  // lazy useEffect
  useEffect(() => {
    const navLinks = document.querySelectorAll("nav .links a");
    navLinks.forEach((link) => {
      const linkHref = link.getAttribute("href");
      link.classList.remove("selected");

      if (
        linkHref &&
        locationPath !== "/" &&
        linkHref !== "/" &&
        locationPath.startsWith(linkHref)
      ) {
        link.classList.add("selected");
      }
    });
  }, [locationPath]);

  return (
    <div
      className={clsx("contents group/nav", {
        [styles.dangerMode]: isProduction && showAdmin && dangermode,
      })}>
      {isMobile ? (
        <NavMobile user={user} permissions={permissions} />
      ) : (
        <NavLeft user={user} permissions={permissions} />
      )}
    </div>
  );
}

export { Nav };
