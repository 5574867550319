import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyAreaWasteWaterTypeValues = ["SEWER", "SEPTIC"] as const;

const PropertyAreaWasteWaterTypeEffectSchema = Schema.Literal(
  ...PropertyAreaWasteWaterTypeValues,
);

type PropertyAreaWasteWaterType = Schema.Schema.Type<
  typeof PropertyAreaWasteWaterTypeEffectSchema
>;

const PropertyAreaWasteWaterTypeEnum = castEnum(
  PropertyAreaWasteWaterTypeEffectSchema,
);

function randomPropertyAreaWasteWaterType(): PropertyAreaWasteWaterType {
  return rand(PropertyAreaWasteWaterTypeValues);
}

export {
  PropertyAreaWasteWaterTypeEffectSchema,
  PropertyAreaWasteWaterTypeEnum,
  PropertyAreaWasteWaterTypeValues,
  randomPropertyAreaWasteWaterType,
};
export type { PropertyAreaWasteWaterType };
