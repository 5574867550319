import { Predicate as P } from "effect";
import { useEffect, useRef } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

type UseMediaQueryOptions = {
  getInitialValueInEffect?: boolean;
};

type MediaQueryCallback = (event: { matches: boolean; media: string }) => void;

function attachMediaListener(
  query: MediaQueryList,
  callback: MediaQueryCallback,
) {
  try {
    query.addEventListener("change", callback);
    return () => query.removeEventListener("change", callback);
  } catch {
    query.addListener(callback);
    return () => query.removeListener(callback);
  }
}

function getInitialValue(query: string, initialValue?: boolean) {
  if (P.isBoolean(initialValue)) {
    return initialValue;
  }

  if (P.isNotNullable(window) && "matchMedia" in window) {
    return window.matchMedia(query).matches;
  }

  return false;
}

function useMediaQuery(
  query: string,
  initialValue?: boolean,
  { getInitialValueInEffect = true }: UseMediaQueryOptions = {},
): boolean {
  const [matches, { toggle: setMatches }] = useBoolean(
    getInitialValueInEffect
      ? (initialValue ?? false)
      : getInitialValue(query, initialValue),
  );
  const queryRef = useRef<MediaQueryList | Null>(NULL);

  // To manage and clean up media query listeners based on component lifecycle and query changes.
  useEffect(() => {
    if (P.isNullable(window) || !("matchMedia" in window)) {
      return UNDEFINED;
    }

    queryRef.current = window.matchMedia(query);
    setMatches(queryRef.current.matches);
    const listenerCleanup = attachMediaListener(queryRef.current, (event) =>
      setMatches(event.matches),
    );
    return listenerCleanup;
  }, [query, setMatches]);

  return matches;
}

export { useMediaQuery };
export type { UseMediaQueryOptions };
