import { Money$ } from "@ender/shared/core";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import type { BankRecRowEnderBatchResponse } from "@ender/shared/generated/ender.service.accounting.banking";
import { EnderDate } from "@ender/shared/utils/ender-date";

type MatchCandidate = BankRecRowEnderBatchResponse & { description: string };

type BatchMatchCandidateRowType = {
  deselect: () => void;
  isSelected: boolean;
  matchCandidate: MatchCandidate;
  select: () => void;
  showDeposits: boolean;
  showDraws: boolean;
};

const BatchMatchCandidateRow = ({
  matchCandidate,
  isSelected,
  select,
  deselect,
  showDraws,
  showDeposits,
}: BatchMatchCandidateRowType) => {
  const amount = Money$.of(matchCandidate.amount);

  // Bank signing convention: deposit == negative.

  return (
    <div
      className={`grid-table__row${isSelected ? " selected-row" : ""}`}
      role="row">
      <div className="numeric date" role="cell">
        {EnderDate.of(matchCandidate.date).toSlashDateString()}
      </div>
      <div className="description" role="cell">
        {matchCandidate.description}
      </div>
      {showDraws && (
        <div className="amount" role="cell">
          {Money$.isPositive(amount) && (
            <MoneyDisplay value={Money$.abs(amount)} />
          )}
        </div>
      )}
      {showDeposits && (
        <div className="amount" role="cell">
          {Money$.isNegative(amount) && (
            <MoneyDisplay value={Money$.abs(amount)} />
          )}
        </div>
      )}
      <div className="checkbox" role="cell">
        <Checkbox
          onChange={isSelected ? deselect : select}
          value={isSelected}
        />
      </div>
    </div>
  );
};

export { BatchMatchCandidateRow };
