/**
 * HTTP Method: POST
 * Pathname: /invoices/{invoiceId}/approve-and-pay
 * @function approveAndPayInvoice
 * @memberof InvoicesAPI
 * @param {InvoicesAPIApproveAndPayInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TransferDetails } from "@ender/shared/generated/ender.api.accounting.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIApproveAndPayInvoicePathParams = {
  invoiceId: EnderId;
};

type InvoicesAPIApproveAndPayInvoiceSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIApproveAndPayInvoiceBodyParams = {
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
  transferDetails: TransferDetails;
};

type InvoicesAPIApproveAndPayInvoicePayload =
  InvoicesAPIApproveAndPayInvoicePathParams &
    InvoicesAPIApproveAndPayInvoiceSearchParams &
    InvoicesAPIApproveAndPayInvoiceBodyParams;

function approveAndPayInvoiceUnsafeEffect(
  payload: InvoicesAPIApproveAndPayInvoicePayload,
) {
  const { invoiceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/invoices/${invoiceId}/approve-and-pay`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function approveAndPayInvoice(
  payload: InvoicesAPIApproveAndPayInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    approveAndPayInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { approveAndPayInvoice };
export type {
  InvoicesAPIApproveAndPayInvoiceBodyParams,
  InvoicesAPIApproveAndPayInvoicePathParams,
  InvoicesAPIApproveAndPayInvoicePayload,
  InvoicesAPIApproveAndPayInvoiceSearchParams,
};
