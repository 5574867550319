import { IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { FontSize, Text } from "@ender/shared/ds/text";
import type { TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow } from "@ender/shared/generated/com.ender.middle.response";
import { PaymentsAPI } from "@ender/shared/generated/ender.api.accounting";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import type { CellProps } from "@ender/shared/ui/table-tanstack";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

function getDisabledTooltip(
  deposit: TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow,
) {
  if (deposit.isMatched) {
    return "You cannot remove a deposit that has already been matched in bank rec. Please manually un-match this transaction to proceed.";
  }

  if (
    deposit.paymentType === MoneyTransferTransferTypeEnum.DWOLLA_TRANSFER ||
    deposit.paymentType === MoneyTransferTransferTypeEnum.STRIPE_PAYMENT
  ) {
    return "You cannot remove a deposit for an electronic payment.";
  }
}

function CheckDepositsActionsCell(
  props: CellProps<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
) {
  const {
    row: { original: deposit },
    table,
  } = props;

  const { hasPermissions } = useContext(UserContext);
  const hasRequiredPermissions = hasPermissions(
    FunctionalPermissionEnum.BANK_RECONCILIATION,
  );
  const confirmation = useContext(ConfirmationContext);
  const { isLoading, mutateAsync: confirmAndCancelDeposit } = useMutation({
    mutationFn: async () => {
      await confirmation({
        confirmButtonLabel: "Reverse Deposit",
        content: (
          <Text size={FontSize.sm}>
            This deposit will be canceled. Any receipts which were part of this
            deposit will reappear in the Receipts page.
          </Text>
        ),
        title: "Are you sure you would like to reverse this deposit?",
      });
      return await PaymentsAPI.cancelDeposit({
        bankingBatchId: deposit?.bankingBatchId as EnderId,
      });
    },
    mutationKey: ["PaymentsAPI.cancelDeposit"] as const,
  });
  return (
    <Group spacing={Spacing.sm} justify={Justify.between}>
      {hasRequiredPermissions && (
        <ActionIcon
          variant={ButtonVariant.transparent}
          aria-label="Delete tenant deposit"
          disabledTooltip={getDisabledTooltip(deposit)}
          disabled={
            isLoading ||
            deposit.isMatched ||
            deposit.paymentType ===
              MoneyTransferTransferTypeEnum.DWOLLA_TRANSFER ||
            deposit.paymentType === MoneyTransferTransferTypeEnum.STRIPE_PAYMENT
          }
          size={ButtonSize.sm}
          onClick={async () => {
            try {
              await confirmAndCancelDeposit();
              showSuccessNotification({
                message: "Deposit successfully reversed",
              });
              await table.options.meta.refetch();
            } catch (err) {
              fail(err);
            }
          }}>
          {isLoading ? <LoadingSpinner /> : <IconTrash />}
        </ActionIcon>
      )}
    </Group>
  );
}

export { CheckDepositsActionsCell };
