/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/archive
 * @function archiveApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIArchiveApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIArchiveApplicationPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIArchiveApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIArchiveApplicationPayload =
  ApplicationsAPIArchiveApplicationPathParams &
    ApplicationsAPIArchiveApplicationSearchParams;

function archiveApplicationUnsafeEffect(
  payload: ApplicationsAPIArchiveApplicationPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/archive`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function archiveApplication(
  payload: ApplicationsAPIArchiveApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    archiveApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { archiveApplication };
export type {
  ApplicationsAPIArchiveApplicationPathParams,
  ApplicationsAPIArchiveApplicationPayload,
  ApplicationsAPIArchiveApplicationSearchParams,
};
