/**
 * HTTP Method: GET
 * Pathname: /users/{userId}/lease
 * @function getTenantLease
 * @memberof LeasingAPI
 * @param {LeasingAPIGetTenantLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTenantLeaseResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetTenantLeaseResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetTenantLeasePathParams = {
  userId: EnderId;
};

type LeasingAPIGetTenantLeaseSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetTenantLeasePayload = LeasingAPIGetTenantLeasePathParams &
  LeasingAPIGetTenantLeaseSearchParams;

function getTenantLeaseUnsafeEffect(payload: LeasingAPIGetTenantLeasePayload) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTenantLeaseResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetTenantLeaseResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/users/${userId}/lease`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTenantLease(
  payload: LeasingAPIGetTenantLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<GetTenantLeaseResponse> {
  return F.pipe(
    payload,
    getTenantLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTenantLease };
export type {
  LeasingAPIGetTenantLeasePathParams,
  LeasingAPIGetTenantLeasePayload,
  LeasingAPIGetTenantLeaseSearchParams,
};
