import { HoverCard } from "@mantine/core";
import { differenceInCalendarDays } from "date-fns";
import { Option as O, Predicate as P, String as S, pipe } from "effect";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Select } from "@ender/shared/ds/select";
import type { GetDealResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import type {
  BuyOfferOfferSource,
  BuyOfferOfferStatus,
  BuyOffer as BuyOfferType,
} from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  BuyOfferOfferStatusEnum,
  BuyOfferOfferTypeEnum,
} from "@ender/shared/generated/com.ender.buy.model.misc";
import { BrokersServiceBrokerTypeEnum } from "@ender/shared/generated/com.ender.buy.service";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { asColumnDef } from "@ender/shared/ui/table-tanstack";

// TODO remove OfferStatus when Java is updated
type OfferStatus = {
  id: BuyOfferOfferStatus;
  name: string;
};

// TODO remove this redefinition of BuyOffer when Java is updated
type BuyOfferRow = Omit<BuyOfferType, "status"> & {
  status: OfferStatus;
};

const OFFER_TYPE_MAP = {
  [BuyOfferOfferTypeEnum.BROKER]: "Broker",
  [BuyOfferOfferTypeEnum.BUYER]: "Buyer",
  [BuyOfferOfferTypeEnum.BUYER_CONCESSION_REQUEST]: "Buyer Concession Request",
  [BuyOfferOfferTypeEnum.SELLER_CONCESSION_RESPONSE]:
    "Seller Concession Response",
  [BuyOfferOfferTypeEnum.SELLER_COUNTER]: "Seller Counter",
};

type DetermineIsHighestAndBestOfferProps = {
  source: BuyOfferOfferSource;
  isHighestAndBestOffer: boolean;
};

function determineIsHighestAndBestOffer({
  source,
  isHighestAndBestOffer,
}: DetermineIsHighestAndBestOfferProps) {
  if (source === BrokersServiceBrokerTypeEnum.SELLSIDE) {
    return "N/A";
  }

  return isHighestAndBestOffer ? "Yes" : "No";
}

type BuyOfferTableMeta = {
  onStatusChange: ({
    offerId,
    status,
  }: {
    offerId: EnderId;
    status: BuyOfferOfferStatus;
  }) => Promise<void>;
  deal: GetDealResponse;
};

const columns: ColumnDef<BuyOfferRow, unknown, unknown, BuyOfferTableMeta>[] = [
  asColumnDef({
    accessorKey: "timestamp",
    cell: (props) => (
      <DateDisplay value={LocalDate$.parse(props.row.original.timestamp)} />
    ),
    header: "Date",
  }),
  asColumnDef({
    accessorKey: "type",
    cell: (props) => OFFER_TYPE_MAP[props.row.original.type],
    header: "Source",
  }),
  asColumnDef({
    accessorKey: "amount",
    cell: (props) => (
      <MoneyDisplay
        value={Money$.parse(props.row.original.amount)}
        showSymbol
      />
    ),
    header: "Amount",
  }),
  asColumnDef({
    cell: (props) => {
      const { deal } = props.table.options.meta;
      return pipe(
        LocalDate$.parse(props.row.original.timestamp),
        O.map((v) =>
          differenceInCalendarDays(
            v.toDate(),
            LocalDate$.of(deal.importDate).toDate(),
          ),
        ),
        O.map((v) => `${v} days`),
        O.getOrElse(() => "N/A"),
      );
    },
    header: "Time on Market",
  }),
  asColumnDef({
    header: "Highest and Best Requested",
    cell: (props) => {
      const { isHighestAndBestOffer, source } = props.row.original;
      return determineIsHighestAndBestOffer({ isHighestAndBestOffer, source });
    },
  }),
  asColumnDef({
    accessorKey: "status",
    cell: (props) => {
      const { onStatusChange, deal } = props.table.options.meta;
      const { status, id: offerId, amount, source } = props.row.original;

      return props.row.index === props.table.options.data.length - 1 ? (
        <Select
          value={O.fromNullable(status.id)}
          data={[
            { label: "Pending", value: BuyOfferOfferStatusEnum.PENDING },
            source === BrokersServiceBrokerTypeEnum.SELLSIDE &&
            O.getOrder(Money$.Order)(
              Money$.parse(amount),
              Money$.parse(deal.brokerMaxOffer),
            ) > 1
              ? {
                  label: "Accepted",
                  value: BuyOfferOfferStatusEnum.ACCEPTED,
                }
              : UNDEFINED,
            { label: "Rejected", value: BuyOfferOfferStatusEnum.REJECTED },
            {
              label: "Re-Negotiating",
              value: BuyOfferOfferStatusEnum.RENEGOTIATING,
            },
          ].filter(P.isNotNullable)}
          onChange={(e) =>
            onStatusChange({
              offerId,
              //we do getOrThrow here because as long as Select is not clearable, this will always be a Some
              status: pipe(e, O.getOrThrow),
            })
          }
        />
      ) : (
        <span>{status.name}</span>
      );
    },
    header: "Status",
  }),
  asColumnDef({
    accessorKey: "comment",
    cell: (props) => {
      const comment = props.row.original.comment;
      return S.length(comment) < 30 ? (
        comment
      ) : (
        <HoverCard width={300}>
          <HoverCard.Target>
            <div className="comment">{comment}</div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <div className="comment-hover-card">{comment}</div>
          </HoverCard.Dropdown>
        </HoverCard>
      );
    },
    header: "Comments",
  }),
];

export { columns };
export type { BuyOfferRow, BuyOfferTableMeta };
