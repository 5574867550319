import type { UseQueryResult } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import * as S from "effect/String";
import type { ReactNode } from "react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import type { UploadFilesResponse } from "@ender/shared/api/files";
import { uploadFilesDirect } from "@ender/shared/api/files";
import type { Undefined } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { randomEnderId } from "@ender/shared/core";
import type { EnderAPIGetChatInfoPayload } from "@ender/shared/generated/ender.api.misc";
import { EnderAPI } from "@ender/shared/generated/ender.api.misc";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useEventListener } from "@ender/shared/hooks/use-event-listener";
import type {
  ChatFileAttachment,
  ChatInfo,
  LiveUser,
  TimelineEntry,
  UserGroup,
} from "@ender/shared/types/ender-general";
import { SocketStatusEnum } from "@ender/shared/types/ender-general";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";
import { showWarningNotification } from "@ender/shared/utils/notifications";

import { ChatSocket } from "./chat-socket";

type PendingTimelineEntry = TimelineEntry & {
  acknowledgmentId?: string;
  error?: string;
  failed?: boolean;
};
/**
 * an map of messages by date.
 * each date points to an id-map of messages, ordered by insertion.
 */
type TimelineByDate = [string, TimelineEntry[]];
type ChatClient = {
  /**
   * the currently selected channel.
   * Contains information about chat members, previous messages, etc.
   * @todo make this an ID or index instead of the entire object
   */
  activeChannel?: ChatInfo;
  channels: UseQueryResult<ChatInfo>[];
  isOnPage: boolean;
  isOnline: boolean;
  liveUsers: LiveUser[];
  /**
   * invoke this function to mark the current channel as seen.
   * This should be called when the user is on the page and has scrolled to the bottom of the timeline.
   */
  markSeen: () => void;
  /**
   * the outgoing messages that have not yet been acknowledged by the server.
   * if an "error" alert is received from the server, the error will be attached to the pending message.
   */
  pending: PendingTimelineEntry[];
  refreshTimeline: () => unknown;
  sendChatMessage: (
    message: string,
    attachments?: ChatFileAttachment[],
  ) => unknown;
  sendDocuments: (files: File[]) => Promise<UploadFilesResponse[]>;
  setChatChannel: (channel?: ChatInfo) => unknown;
  /**
   * the timeline of messages for the currently selected channel.
   * formatted as an array of date-organized ordered messages
   */
  timeline: TimelineByDate[];
  users: UserGroup[];
};

type ChatChannelParams = {
  chatTitle?: ReactNode;
} & EnderAPIGetChatInfoPayload;

type ChatClientOptions = {
  channels?: ChatChannelParams[];
  readOnly?: boolean;
};

function useChatClient({
  channels = [],
  readOnly = false,
}: ChatClientOptions = {}): ChatClient {
  const { user, originalUser } = useContext(UserContext);
  const isMasquerading =
    originalUser && !originalUser.isEnder && user.id !== originalUser?.id;

  const socket = useMemo(() => new ChatSocket(), []);
  const {
    // status: socketStatus,
    isReadOnly: isSocketReadOnly,
    setSocketChannel,
    sendChatMessage: _sendChatMessage,
    markSeen: _markSeen,
    refreshTimeline,
  } = socket;

  const [users, setUsers] = useState<UserGroup[]>([]);
  const [liveUsers, setLiveUsers] = useState<LiveUser[]>([]);
  const [timeline, setTimeline] = useState<TimelineByDate[]>([]);
  const [pending, setPending] = useState<PendingTimelineEntry[]>([]);
  const [isOnline, { setTrue: setOnline, setFalse: setOffline }] =
    useBoolean(true);
  const [isOnPage, { setTrue: setOnPage, setFalse: setOffPage }] =
    useBoolean(true);

  /**
   * gets us individual loading, error, data states for each channel
   */
  const chatChannels = useQueries({
    queries: channels.map(({ chatTitle: _chatTitle, ...channel }) => ({
      queryFn: () => EnderAPI.getChatInfo(channel),
      queryKey: [
        "getChatInfo",
        channel.modelType,
        channel.modelId,
        channel.modelParams,
        channel.isInternal,
      ],
    })),
  });

  const markSeen = useCallback(() => {
    if (!isOnPage) {
      // if the user is not on the page, we don't want to mark the messages as seen
      return;
    }

    if (!isMasquerading) {
      _markSeen();
    }
  }, [_markSeen, isMasquerading, isOnPage]);

  const disconnect = useCallback(() => {
    if (
      socket &&
      (socket.status === SocketStatusEnum.CONNECTED ||
        socket.status === SocketStatusEnum.CONNECTING)
    ) {
      socket.disconnect();
    }
  }, [socket]);

  /**
   * adds an entry to the timeline. Does not mark it as read or seen by default.
   * The 'read' behavior should be handled by the message renderer and scroll position of the timeline.
   * @param message
   */
  const appendMessage = useCallback((message: TimelineEntry) => {
    setPending((prev) => prev.filter((p) => p.id !== message.id));
    setTimeline((prev) => {
      const copy = [...prev];
      const msgDate = EnderDate.of(message.dateTime.unix).toLocalISOString();

      const index = copy.findIndex(([groupDate]) => msgDate === groupDate);
      if (index === -1) {
        // append the message to a new date section of the timeline
        copy.push([msgDate, [message]]);
      } else {
        // append the message to the existing date section of the timeline
        copy[index][1].push(message);
      }

      return copy;
    });
  }, []);

  // lazy useEffect
  useEffect(() => {
    socket.on("timeline", (command) => {
      //[date, TimelineEntry[]][]
      const timelineSections: TimelineByDate[] = [];
      for (const entry of command.timeline) {
        const msgDate = EnderDate.of(entry.dateTime.unix).toLocalISOString();
        //previous date in the timeline
        const lastDate = timelineSections[timelineSections.length - 1]?.[0];
        if (msgDate !== lastDate) {
          timelineSections.push([msgDate, [entry]]);
        } else {
          timelineSections[timelineSections.length - 1][1].push(entry);
        }
      }
      setTimeline(timelineSections);
      setPending([]);
    });
    socket.on("chat", appendMessage);
    socket.on("call", appendMessage);
    socket.on("document", appendMessage);
    socket.on("invoice", appendMessage);
    socket.on("usersList", (command) => setUsers(command.usersList));
    socket.on("liveUsers", (command) => setLiveUsers(command.liveUsers));
    socket.on("alert", (command) => {
      if (S.isNonEmpty(command.ackId ?? "")) {
        setPending((prev) => {
          const failedMsg = prev.find(
            (p) => p.acknowledgmentId === command.ackId,
          );
          if (failedMsg) {
            failedMsg.error = command.alert;
            failedMsg.failed = true;
          }
          return [...prev];
        });
      } else {
        showWarningNotification({ title: "Alert", message: command.alert });
      }
    });
    socket.on("acknowledge", (command) => {
      //TODO the BE should still include the ackId in the message so that if the message is received before the acknowledgment
      //we can still clear the pending message. Right now we are assuming that the message will be received _after_ the acknowledgment
      //and to account for the alternative would add needless complexity to the client.
      setPending((prev) => {
        const msg = prev.find((p) => p.acknowledgmentId === command.ackId);
        if (msg) {
          msg.id = command.messageId;
        }
        return prev;
      });
    });
  }, [socket, appendMessage]);

  // lazy useEffect
  useEffect(() => {
    // Effect responsible for closing the socket when the providers are released
    return disconnect;
  }, [disconnect]);

  const [activeChannel, setActiveChannel] = useState<ChatInfo | undefined>();
  /**
   * the most recently active channel. Should only ever be undefined at the beginning
   * and then will always be defined once the user has chosen a channel
   */
  const mostRecentChannel = useRef<ChatInfo | undefined>();
  const setChatChannel = useCallback(
    (
      newChannel?: ChatInfo,
      shouldClear = P.isNotNullable(newChannel) &&
        (newChannel.channel.modelId !==
          mostRecentChannel.current?.channel.modelId ||
          newChannel.channel.isInternal !==
            mostRecentChannel.current?.channel.isInternal),
    ) => {
      if (
        newChannel?.channel.modelId === activeChannel?.channel.modelId &&
        newChannel?.channel.isInternal === activeChannel?.channel.isInternal
      ) {
        return;
      }

      /**
       * clear the timeline when switching channels.
       * this ensures that the newly rendered channel will be clear of prior messages and artifacts.
       *
       * Keep in mind that setting the socket to the same channel it is already watching
       * will not trigger a timeline refresh- so clearing the timeline should only be done if
       * we _know_ the user is switching channels.
       */
      shouldClear && setTimeline([]);
      shouldClear && setPending([]);
      newChannel && setSocketChannel(newChannel);
      newChannel && (mostRecentChannel.current = newChannel);
      setActiveChannel(newChannel);
    },
    [setSocketChannel, activeChannel],
  );

  /**
   * automatically choose the default channel
   * based on some criteria
   */
  useEffect(() => {
    if (!activeChannel && chatChannels.length === 1) {
      setChatChannel(chatChannels[0]?.data);
    }
  }, [chatChannels, setChatChannel, activeChannel]);

  const handleSendMessage = useCallback(
    (
      message: string,
      attachments?: ChatFileAttachment[],
    ): EnderId | Undefined => {
      if (readOnly || isSocketReadOnly || isMasquerading) {
        return;
      }
      const acknowledgmentId = randomEnderId();
      const today = EnderDate.today;
      setPending((prev) => [
        ...prev,
        {
          command: "chat",
          dateTime: {
            date: "today",
            iso: today.toISOString(),
            time: today.to12HourString(),
            unix: today.valueOf(),
          },
          acknowledgmentId,
          author: { id: user.id, isMe: true, name: user.name },
          id: acknowledgmentId,
          medium: "WEBSITE",
          text: message,
        },
      ]);
      _sendChatMessage({ acknowledgmentId, message }, attachments);
      return acknowledgmentId;
    },
    [
      _sendChatMessage,
      isMasquerading,
      isSocketReadOnly,
      readOnly,
      user.id,
      user.name,
    ],
  );

  const handleSendDocuments = useCallback(
    async (files: File[]): Promise<UploadFilesResponse[]> => {
      if (P.isNullable(activeChannel)) {
        return [];
      }
      try {
        const attachedFiles = await uploadFilesDirect({
          //don't send a direct message with the file. The uploaded files will be sent over the socket later.
          asMessage: false,
          files,
          modelId: activeChannel?.channel.modelId,
          modelType: activeChannel?.channel.modelType,
          subFolder: activeChannel?.channel.isInternal ? "PRIVATE" : "PUBLIC",
          userId: user.id,
        });
        // refreshTimeline();
        return attachedFiles;
      } catch (err) {
        fail(err);
      }

      return [];
    },
    [activeChannel, user.id],
  );

  useEventListener({
    eventName: "online",
    handler: () => {
      refreshTimeline();
      setOnline();
    },
    target: window,
  });

  useEventListener({
    eventName: "offline",
    handler: setOffline,
    target: window,
  });

  useEventListener({
    eventName: "visibilitychange",
    handler: () => {
      const { hidden } = document;
      if (hidden) {
        setOffPage();
      } else {
        setOnPage();
      }
    },
    target: window,
  });

  return {
    activeChannel,
    channels: chatChannels,
    isOnline,
    isOnPage,
    liveUsers,
    markSeen,
    pending,
    refreshTimeline,
    sendChatMessage: handleSendMessage,
    sendDocuments: handleSendDocuments,
    setChatChannel,
    timeline,
    users,
  };
}

export { useChatClient };
export type { ChatChannelParams, ChatClient, ChatClientOptions };
