import { useMemo } from "react";

import { Badge, BadgeColor, BadgeSize } from "@ender/shared/ds/badge";
import type { BankAccountAccountStatus } from "@ender/shared/generated/ender.model.payments";
import { BankAccountAccountStatusEnum } from "@ender/shared/generated/ender.model.payments";

const dwollaStatuses: Record<string, Set<BankAccountAccountStatus>> = {
  enabled: new Set([BankAccountAccountStatusEnum.VERIFIED]),
  notSetUp: new Set([
    BankAccountAccountStatusEnum.NOT_REGISTERED,
    BankAccountAccountStatusEnum.REMOVED,
  ]),
  notVerified: new Set([
    BankAccountAccountStatusEnum.NEW,
    BankAccountAccountStatusEnum.RECEIVE_ONLY,
    BankAccountAccountStatusEnum.VERIFICATION_FAILED,
    BankAccountAccountStatusEnum.ERRORED,
  ]),
};

function getDwollaLabel(status: BankAccountAccountStatus) {
  if (dwollaStatuses.enabled.has(status)) {
    return "Enabled";
  }

  if (dwollaStatuses.notSetUp.has(status)) {
    return "Not Set Up";
  }

  if (dwollaStatuses.notVerified.has(status)) {
    return "Not Verified";
  }

  return "Unknown";
}

function getDwollaColor(status: BankAccountAccountStatus) {
  if (dwollaStatuses.enabled.has(status)) {
    return BadgeColor.green;
  }

  if (dwollaStatuses.notSetUp.has(status)) {
    return BadgeColor.red;
  }

  if (dwollaStatuses.notVerified.has(status)) {
    return BadgeColor.yellow;
  }

  return BadgeColor.slate;
}

function DwollaStatusBadge({ status }: { status: BankAccountAccountStatus }) {
  const [label, color] = useMemo(() => {
    return [getDwollaLabel(status), getDwollaColor(status)];
  }, [status]);

  return (
    <Badge size={BadgeSize.sm} color={color}>
      {label}
    </Badge>
  );
}

export { DwollaStatusBadge, dwollaStatuses, getDwollaColor, getDwollaLabel };
