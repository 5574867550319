import { IconPaperclip, IconSend } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Function as F } from "effect";
import type { PropsWithChildren, ReactNode } from "react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Textarea } from "@ender/shared/ds/textarea";
import { Color, Size } from "@ender/shared/utils/theming";

type ChatInputProps = {
  onSendMessage?: (message?: string) => void;
  onAttach?: () => void;
  disabled?: boolean;
  actions?: ReactNode;
};

type ChatInputRef = {
  setValue: (value: string) => void;
  element: HTMLTextAreaElement | null;
};

/**
 * a combined widget of the multiline input, attach button, and the send button
 */
const ChatInput = forwardRef<ChatInputRef, PropsWithChildren<ChatInputProps>>(
  function ChatInput(props, ref) {
    const {
      disabled,
      onSendMessage = F.constVoid,
      children,
      actions,
      onAttach,
    } = props;
    const inputRef = useRef<HTMLTextAreaElement>(null);

    /**
     * the user-typed message
     */
    const [value, setValue] = useState("");

    const handleSend = useCallback(() => {
      onSendMessage(value);
      setValue("");
    }, [value, onSendMessage]);

    useImperativeHandle(ref, () => ({
      element: inputRef.current,
      setValue,
    }));

    return (
      <div className="p-2 pt-0">
        <div
          className={clsx(
            "border border-slate-100 flex flex-col grow rounded-lg overflow-auto",
            {
              "bg-gray-50": disabled,
              "bg-slate-50": !disabled,
            },
          )}>
          <Textarea
            borderless
            placeholder="Send a message..."
            size={Size.md}
            minRows={2}
            maxRows={4}
            //TODO enable once we want to send on enter
            // onKeyDown={onKeyEvent(KeyEnum.Enter, handleSend)}
            ref={inputRef}
            disabled={disabled}
            value={value}
            onChange={setValue}
          />
          {children}
          <div className="pl-1.5 pr-3 pb-2">
            <Group spacing={Spacing.none} justify={Justify.between}>
              <Group spacing={Spacing.xs}>
                <ActionIcon
                  variant={ButtonVariant.transparent}
                  color={Color.primary}
                  onClick={onAttach}
                  label="Upload"
                  disabled={disabled}>
                  <IconPaperclip size={16} />
                </ActionIcon>
                {actions}
              </Group>
              <ActionIcon
                variant={ButtonVariant.filled}
                onClick={handleSend}
                label="Send"
                disabled={disabled}>
                <IconSend size={16} />
              </ActionIcon>
            </Group>
          </div>
        </div>
      </div>
    );
  },
);

export { ChatInput };
export type { ChatInputProps, ChatInputRef };
