import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext } from "react";

import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { navigateToApp } from "@ender/shared/utils/navigation";

/**
 * @deprecated move this into a domain-specific package, such as the vendor portal or the nav itself
 */
function VendorSelect() {
  const {
    activeVendorId,
    user: { party },
    userVendors,
  } = useContext(UserContext);

  const [isMenuOpen, { toggle: toggleMenu }] = useBoolean(false);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: AuthAPI.changeActiveVendor,
  });

  const onVendorClick = useCallback(
    (vendorId: EnderId) => {
      mutateAsync({ vendorId })
        .then(() => {
          navigateToApp();
        })
        .catch(fail);
    },
    [mutateAsync],
  );

  if (userVendors.length === 1) {
    return null;
  }

  return (
    <Menu onOpenChange={toggleMenu}>
      <MenuTrigger>
        <Button
          variant={ButtonVariant.transparent}
          rightSection={isMenuOpen ? <IconChevronUp /> : <IconChevronDown />}>
          {party?.name}
        </Button>
      </MenuTrigger>
      <MenuContent align="end">
        {userVendors
          .filter((vendor) => vendor.id !== activeVendorId)
          .map((vendor) => (
            <MenuButton
              key={vendor.id}
              onClick={() => onVendorClick(vendor.id)}
              disabled={isLoading}>
              {vendor.name}
            </MenuButton>
          ))}
      </MenuContent>
    </Menu>
  );
}

export { VendorSelect };
