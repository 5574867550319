import { formatWithOptions } from "date-fns/fp";
import { enUS } from "date-fns/locale";
import { Option as O, pipe } from "effect";

import { Instant$ } from "@ender/shared/core";

const TIMESTAMP_12_HOUR_FORMAT = "MM/dd/yyyy hh:mm a";
const TIMESTAMP_24_HOUR_FORMAT = "MM/dd/yyyy HH:mm";

const timestamp12HourFormat = formatWithOptions(
  { locale: enUS },
  TIMESTAMP_12_HOUR_FORMAT,
);
const timestamp24HourFormat = formatWithOptions(
  { locale: enUS },
  TIMESTAMP_24_HOUR_FORMAT,
);
/**
 * Converts an Instant$ or string input into a formatted timestamp string
 * with either 12-hour or 24-hour format.
 *
 * @param {Instant$ | string} input - The input date to format. Can be an Instant$ object or a string.
 * @param {Object} options - Formatting options.
 * @param {boolean} options.hour24 - If true, formats the timestamp in 24-hour format ("MM/dd/yyyy HH:mm").
 *                                   If false, formats in 12-hour format ("MM/dd/yyyy hh:mm a").
 * @returns {string} The formatted timestamp string.
 * @throws {Error} If the input cannot be parsed into a valid date.
 *
 * @example
 * // Example with 12-hour format
 * toTimestampString("2024-12-06T14:30:00Z", { hour24: false });
 * // Output: "12/06/2024 02:30 PM"
 *
 * @example
 * // Example with 24-hour format
 * toTimestampString("2024-12-06T14:30:00Z", { hour24: true });
 * // Output: "12/06/2024 14:30"
 */

function toTimestampString(
  input: Instant$ | string,
  options: { hour24: boolean },
): string {
  const localDate = Instant$.parse(input);
  return pipe(
    localDate,
    O.map((date) =>
      options?.hour24
        ? timestamp24HourFormat(date.toDate())
        : timestamp12HourFormat(date.toDate()),
    ),
    O.getOrThrow,
  );
}

export { toTimestampString };
