import type { FactorValue } from "./filter-fields/filter-types";
import { Factor } from "./filter-fields/filter-types";

const HIDDEN_Y_FACTORS: FactorValue[] = [
  Factor.CHANNEL,
  Factor.DUP_ADDRESS,
  Factor.STATUS,
  Factor.ZIPCODE,
];

export { HIDDEN_Y_FACTORS };
