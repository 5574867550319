import { clsx } from "clsx";
import type { PropsWithChildren } from "react";
import { useContext } from "react";

import { EnderErrorBoundary } from "@ender/shared/ui/ender-error-boundary";
import { RightRailContext } from "@ender/shared/ui/right-rail";

import styles from "./ender-container.module.css";

type EnderContainerProps = {};

function EnderContainer(props: PropsWithChildren<EnderContainerProps>) {
  const { children } = props;
  const [opened] = useContext(RightRailContext);

  return (
    <div
      className={clsx(
        "ender-container",
        { [styles.rightRailOpen]: opened },
        styles.enderContainer,
      )}>
      <EnderErrorBoundary>
        <div className={clsx("ender-inner-container", styles.innerContainer)}>
          {children}
        </div>
      </EnderErrorBoundary>
    </div>
  );
}

export { EnderContainer };
