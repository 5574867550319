import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BuyOfferOfferTypeValues = [
  "BUYER",
  "BROKER",
  "SELLER_COUNTER",
  "BUYER_CONCESSION_REQUEST",
  "SELLER_CONCESSION_RESPONSE",
] as const;

const BuyOfferOfferTypeEffectSchema = Schema.Literal(
  ...BuyOfferOfferTypeValues,
);

type BuyOfferOfferType = Schema.Schema.Type<
  typeof BuyOfferOfferTypeEffectSchema
>;

const BuyOfferOfferTypeEnum = castEnum(BuyOfferOfferTypeEffectSchema);

function randomBuyOfferOfferType(): BuyOfferOfferType {
  return rand(BuyOfferOfferTypeValues);
}

export {
  BuyOfferOfferTypeEffectSchema,
  BuyOfferOfferTypeEnum,
  BuyOfferOfferTypeValues,
  randomBuyOfferOfferType,
};
export type { BuyOfferOfferType };
