import type { UseFormReturnType } from "@mantine/form";

import type { GetApplyInfoResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { Select } from "@ender/shared/ui/select";

type IdentityQuestionsProps = {
  questions?: GetApplyInfoResponse["identityVerificationQuestions"];
  form: UseFormReturnType<Record<string, unknown>>;
};

type TuIdvQuestion = {
  choices: { choiceKeyName: string; choiceDisplayName: string }[];
  questionKeyName: string;
  questionDisplayName: string;
};

/**
 * Sub-step if the application returns with identity questions needed
 */
function IdentityQuestions({ questions = [], form }: IdentityQuestionsProps) {
  return (
    <>
      {questions.map((question: TuIdvQuestion) => (
        <div
          style={{ gridColumn: "span 5", alignSelf: "flex-end" }}
          key={question.questionKeyName}>
          <Select
            data={question.choices.map((choice) => ({
              value: choice.choiceKeyName,
              label: choice.choiceDisplayName,
            }))}
            label={question.questionDisplayName}
            placeholder="Select an answer"
            {...form.getInputProps(question.questionKeyName)}
          />
        </div>
      ))}
    </>
  );
}

export { IdentityQuestions };
