/**
 * HTTP Method: POST
 * Pathname: /bankingBatch/{batchId}/unbatch
 * @function unbatch
 * @memberof BankingAPI
 * @param {BankingAPIUnbatchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIUnbatchPathParams = {
  batchId: EnderId;
};

type BankingAPIUnbatchSearchParams = {
  token?: string | undefined;
};

type BankingAPIUnbatchPayload = BankingAPIUnbatchPathParams &
  BankingAPIUnbatchSearchParams;

function unbatchUnsafeEffect(payload: BankingAPIUnbatchPayload) {
  const { batchId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankingBatch/${batchId}/unbatch`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function unbatch(
  payload: BankingAPIUnbatchPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    unbatchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { unbatch };
export type {
  BankingAPIUnbatchPathParams,
  BankingAPIUnbatchPayload,
  BankingAPIUnbatchSearchParams,
};
