/**
 * HTTP Method: POST
 * Pathname: /middleLayer/get-gl-balances
 * @function getGLBalances
 * @memberof AccountingMiddleLayerAPI
 * @param {AccountingMiddleLayerAPIGetGLBalancesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetGLBalancesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetGLBalanceRequest } from "@ender/shared/generated/com.ender.middle.request";
import type { GetGLBalancesResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingMiddleLayerAPIGetGLBalancesSearchParams = {
  token?: string | undefined;
};

type AccountingMiddleLayerAPIGetGLBalancesBodyParams = {
  request: GetGLBalanceRequest;
};

type AccountingMiddleLayerAPIGetGLBalancesPayload =
  AccountingMiddleLayerAPIGetGLBalancesSearchParams &
    AccountingMiddleLayerAPIGetGLBalancesBodyParams;

function getGLBalancesUnsafeEffect(
  payload: AccountingMiddleLayerAPIGetGLBalancesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetGLBalancesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetGLBalancesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/get-gl-balances",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getGLBalances(
  payload: AccountingMiddleLayerAPIGetGLBalancesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetGLBalancesResponse> {
  return F.pipe(
    payload,
    getGLBalancesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getGLBalances };
export type {
  AccountingMiddleLayerAPIGetGLBalancesBodyParams,
  AccountingMiddleLayerAPIGetGLBalancesPayload,
  AccountingMiddleLayerAPIGetGLBalancesSearchParams,
};
