/**
 * HTTP Method: GET
 * Pathname: /accounting/categories/flags
 * @function getAllFlags
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetAllFlagsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string[]>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetAllFlagsSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetAllFlagsPayload =
  GeneralLedgerAPIGetAllFlagsSearchParams;

function getAllFlagsUnsafeEffect(payload: GeneralLedgerAPIGetAllFlagsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string[]>({
        body,
        decode: unsafeDecodeJsonResponse<string[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/accounting/categories/flags",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAllFlags(
  payload: GeneralLedgerAPIGetAllFlagsPayload,
  options?: { signal?: AbortSignal },
): Promise<string[]> {
  return F.pipe(
    payload,
    getAllFlagsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAllFlags };
export type {
  GeneralLedgerAPIGetAllFlagsPayload,
  GeneralLedgerAPIGetAllFlagsSearchParams,
};
