/**
 * 1. If `id` is in the JSON body, then this API associates the user (must be an existing user, `isTenant` must be
 true) with the lease.

 2. If `id` is not present but the email/phone matches an existing user, then this API associates the existing user
 with the lease.

 3. If `id` is present and email/phone doesn't match an existing user, then this API creates a new user and
 associates it with the lease.
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/contacts
 * @function addLeaseContact
 * @memberof LeasingAPI
 * @param {LeasingAPIAddLeaseContactPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<User>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIAddLeaseContactPathParams = {
  leaseId: EnderId;
};

type LeasingAPIAddLeaseContactSearchParams = {
  token?: string | undefined;
};

type LeasingAPIAddLeaseContactBodyParams = {
  birthday?: SerializesInto<LocalDate> | undefined;
  driversLicense?: string | undefined;
  email?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phone?: string | undefined;
  tenantId?: EnderId | undefined;
};

type LeasingAPIAddLeaseContactPayload = LeasingAPIAddLeaseContactPathParams &
  LeasingAPIAddLeaseContactSearchParams &
  LeasingAPIAddLeaseContactBodyParams;

function addLeaseContactUnsafeEffect(
  payload: LeasingAPIAddLeaseContactPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, User>({
        body,
        decode: unsafeDecodeJsonResponse<User>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/contacts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addLeaseContact(
  payload: LeasingAPIAddLeaseContactPayload,
  options?: { signal?: AbortSignal },
): Promise<User> {
  return F.pipe(
    payload,
    addLeaseContactUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addLeaseContact };
export type {
  LeasingAPIAddLeaseContactBodyParams,
  LeasingAPIAddLeaseContactPathParams,
  LeasingAPIAddLeaseContactPayload,
  LeasingAPIAddLeaseContactSearchParams,
};
