/**
 * HTTP Method: GET
 * Pathname: /money_transfers/wires/{moneyTransferId}/wellsFargo.xlsx
 * @function getWireTransfer
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetWireTransferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetWireTransferPathParams = {
  moneyTransferId: EnderId;
};

type PaymentsAPIGetWireTransferSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetWireTransferPayload = PaymentsAPIGetWireTransferPathParams &
  PaymentsAPIGetWireTransferSearchParams;

function getWireTransferUnsafeEffect(
  payload: PaymentsAPIGetWireTransferPayload,
) {
  const { moneyTransferId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/money_transfers/wires/${moneyTransferId}/wellsFargo.xlsx`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getWireTransfer(
  payload: PaymentsAPIGetWireTransferPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    getWireTransferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getWireTransfer };
export type {
  PaymentsAPIGetWireTransferPathParams,
  PaymentsAPIGetWireTransferPayload,
  PaymentsAPIGetWireTransferSearchParams,
};
