import { Array as A, Option as O, pipe } from "effect";
import type { ReactNode } from "react";
import { useMemo } from "react";

import { Money$, randomEnderId } from "@ender/shared/core";
import { H2 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple, TupleList } from "@ender/shared/ds/tuple";
import type { TenantLedgerReportResponse } from "@ender/shared/generated/ender.service.reports.builtin";
import type { LabelValue } from "@ender/shared/types/label-value";

function getRemainingCharges(ledgerData: TenantLedgerReportResponse) {
  const _remainingCharges = [];
  const _chargesTotalAmount = Money$.fromDollars(0);

  const { ledgerEvents } = ledgerData;

  const ledgerBalance = pipe(
    ledgerEvents,
    A.head,
    O.flatMap((v) => Money$.parse(v.runningBalanceForLedger)),
    O.getOrElse(Money$.zero),
  );

  if (!Money$.isZero(ledgerBalance)) {
    _remainingCharges.push({
      description: Money$.isPositive(ledgerBalance)
        ? "Unpaid Ledger Balance"
        : "Unallocated Ledger Credit",
      id: randomEnderId(),
      remainingAmount: ledgerBalance,
    });
  }
  return {
    chargesTotalAmount: Money$.add(_chargesTotalAmount, ledgerBalance),
    remainingCharges: _remainingCharges,
  };
}

type RemainingChargesProps = {
  remainingCharges: {
    description: string;
    remainingAmount: Money$.Money;
  }[];
  chargesTotalAmount: Money$.Money;
};

function RemainingCharges(props: RemainingChargesProps) {
  const { remainingCharges, chargesTotalAmount } = props;

  //@ts-expect-error LabelValue does not like ReactNode
  const tuples: LabelValue<ReactNode>[] = useMemo(() => {
    return remainingCharges.map((chargeItem) => {
      return {
        label: chargeItem.description,
        value: <MoneyDisplay value={chargeItem.remainingAmount} />,
      };
    });
  }, [remainingCharges]);

  return (
    <Stack>
      <Tuple
        label={<H2>All Remaining Charges</H2>}
        value={
          <H2>
            <MoneyDisplay showSymbol value={chargesTotalAmount} />
          </H2>
        }
      />
      <TupleList entries={tuples} />
    </Stack>
  );
}

export { getRemainingCharges, RemainingCharges };
