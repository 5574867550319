/**
 * HTTP Method: GET
 * Pathname: /getVehiclesForOwner/{ownerId}
 * @function getVehiclesForOwner
 * @memberof LeasingAPI
 * @param {LeasingAPIGetVehiclesForOwnerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Vehicle[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Vehicle } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetVehiclesForOwnerPathParams = {
  ownerId: EnderId;
};

type LeasingAPIGetVehiclesForOwnerSearchParams = {
  token?: string | undefined;
  includeArchived?: boolean | undefined;
};

type LeasingAPIGetVehiclesForOwnerPayload =
  LeasingAPIGetVehiclesForOwnerPathParams &
    LeasingAPIGetVehiclesForOwnerSearchParams;

function getVehiclesForOwnerUnsafeEffect(
  payload: LeasingAPIGetVehiclesForOwnerPayload,
) {
  const { ownerId, token, includeArchived, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Vehicle[]>({
        body,
        decode: unsafeDecodeJsonResponse<Vehicle[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/getVehiclesForOwner/${ownerId}`,
        searchParams: [
          ["token", token],
          ["includeArchived", includeArchived],
        ],
      }),
    ),
  );
}

function getVehiclesForOwner(
  payload: LeasingAPIGetVehiclesForOwnerPayload,
  options?: { signal?: AbortSignal },
): Promise<Vehicle[]> {
  return F.pipe(
    payload,
    getVehiclesForOwnerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVehiclesForOwner };
export type {
  LeasingAPIGetVehiclesForOwnerPathParams,
  LeasingAPIGetVehiclesForOwnerPayload,
  LeasingAPIGetVehiclesForOwnerSearchParams,
};
