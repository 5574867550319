// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import { format } from "date-fns";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  Tuple as T,
  pipe,
} from "effect";
import type { FormatFunction, FormatFunctionResult } from "input-format";
import { templateFormatter, templateParser } from "input-format";
import ReactInput from "input-format/react";
import type { ChangeEvent } from "react";
import { forwardRef, useCallback, useId, useRef, useState } from "react";

import { LocalDate$ } from "@ender/shared/core";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { DayPicker } from "../../../calendar/src";
import { fromUserInput } from "../../../date-input/src";
import { Divider } from "../../../divider/src";
import type { InputBaseProps } from "../../../input/src";
import { InputSize, InputWrapper } from "../../../input/src";
import { isEmptyReactNode } from "../../../utils";

const TEMPLATE = "xx/xx/xxxx";
const parseInput = templateParser(TEMPLATE, (...args) => {
  return /\d/.test(args[0]) ? args[0] : "";
});
const _formatInput = templateFormatter(TEMPLATE);
const formatInput: FormatFunction = (value?: string): FormatFunctionResult => {
  /**
   * because value is going to be a formatted (slash-separated) date string, it's ok to remove all non-numeric characters before
   * applying the template.
   */
  const { text, template } = _formatInput(value?.replace(/\D/g, ""));
  return {
    template,
    text,
  };
};

const InputVariantGenerator = cva(
  [
    "px-4",
    "grow text-sm/standard placeholder:text-slate-300 rounded outline-none focus:outline-none focus-visible:outline-none",
    "text-slate-900 disabled:bg-gray-50 disabled:text-gray-300",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      borderless: false,
      size: InputSize.md,
      textAlign: "left",
    },
    variants: {
      borderless: {
        false: [
          "border border-slate-200",
          "enabled:focus:border-primary-500 enabled:aria-invalid:border-red-500 disabled:border-gray-100",
          "enabled:data-[active=true]:border-primary-500 enabled:data-[active=true]:aria-invalid:border-red-500",
        ],
        true: "border border-transparent",
      },
      size: {
        [InputSize.sm]: "py-[calc(0.375rem-1px)]",
        [InputSize.md]: "py-[calc(0.625rem-1px)]",
        [InputSize.lg]: "py-3.5",
      },
      textAlign: {
        left: "",
        right: "text-right",
      },
    },
  },
);

type DateRangeInputProps = {
  value: [O.Option<LocalDate$.LocalDate>, O.Option<LocalDate$.LocalDate>];
  onChange: (
    value: [O.Option<LocalDate$.LocalDate>, O.Option<LocalDate$.LocalDate>],
  ) => void;
  clearable?: boolean;
  closeOnChange?: boolean;
  minDate?: LocalDate$.LocalDate;
  maxDate?: LocalDate$.LocalDate;
  /**
   * the placeholder text for the first date of the range
   */
  fromPlaceholder?: string;
  /**
   * the placeholder text for the last date of the range
   */
  toPlaceholder?: string;
} & Omit<
  InputBaseProps,
  "leftSection" | "rightSection" | "placeholder" | "name"
>;

const DateRangeInput = forwardRef<HTMLDivElement, DateRangeInputProps>(
  function DateRangeInput(props, ref) {
    const {
      description,
      disabled,
      error,
      label,
      onChange,
      fromPlaceholder,
      toPlaceholder,
      role = "textbox",
      value,
      minDate,
      maxDate,
      "data-private": _private,
    } = props;

    /**
     * the display string representing the user's typed input.
     */
    const [display, setDisplay] = useState(() =>
      pipe(
        value,
        A.map((date) =>
          pipe(
            date,
            O.map((val) => format(val.toDate(), "MM/dd/yyyy")),
            O.getOrElse(() => ""),
          ),
        ),
      ),
    );

    /**
     * a reference to the root element of the popover component.
     * Used to check if a click event occurred inside the popover component, so that the popover does not close when the user clicks
     * any of the relevant elements within.
     */
    const popoverRoot = useRef<HTMLDivElement>(null);

    /**
     * handler used for typed inputs
     */
    const handleChange = useCallback(
      (idx: number) => {
        return (text: string | undefined | ChangeEvent = "") => {
          if (!P.isString(text)) {
            return;
          }
          const copy: typeof value = [...value];
          try {
            setDisplay(([...prev]) => {
              prev[idx] = text ?? "";
              return prev;
            });
            copy[idx] = fromUserInput(text, minDate, maxDate);
            onChange(copy);
          } catch {
            copy[idx] = O.none();
            onChange(copy);
          }
        };
      },
      [value, onChange, minDate, maxDate],
    );

    const [activeIndex, setActiveIndex] = useState<number>(0);
    /**
     * handler used for calendar selection
     */
    const handleCalendarDayClick = useCallback(
      (dayClicked: Date) => {
        const arr: [
          O.Option<LocalDate$.LocalDate>,
          O.Option<LocalDate$.LocalDate>,
        ] = [...value];
        arr[activeIndex] = LocalDate$.parse(dayClicked);

        const sorted = pipe(
          arr,
          //sort the dates, leaving Nones in place
          A.sortBy((a, b) =>
            O.isNone(a) || O.isNone(b) ? 0 : O.getOrder(LocalDate$.Order)(a, b),
          ),
          A.take(2),
        );
        if (T.isTupleOf(2)(sorted)) {
          onChange(sorted);
          setDisplay(
            pipe(
              sorted,
              A.map((date) =>
                pipe(
                  date,
                  O.map((val) => format(val.toDate(), "MM/dd/yyyy")),
                  O.getOrElse(() => ""),
                ),
              ),
            ),
          );
        }

        //if we did not swap the dates, we need to change the active index to the other date.
        if (
          A.getEquivalence(O.getEquivalence(LocalDate$.Equivalence))(
            arr,
            sorted,
          )
        ) {
          setActiveIndex((prev) => (prev === 0 ? 1 : 0));
        }
      },
      [value, onChange, activeIndex],
    );

    /**
     * maps the current range value to the months that should be displayed in the calendar.
     * For the first month, it can be displayed as is.
     * For the second month, we subtract 1 month from it- this is because we want to make the second month correspond with the second calendar in the dropdown
     */
    const monthsFromValue: Record<number, LocalDate$.LocalDate | undefined> =
      pipe(value, A.getSomes, (a) => ({
        0: a[0],
        1: pipe(
          O.fromNullable(a[1]),
          O.map(LocalDate$.add({ months: -1 })),
          O.map(LocalDate$.clamp({ minimum: a[0] })),
          O.getOrUndefined,
        ),
      }));
    const [activeMonth, setActiveMonth] = useState(
      monthsFromValue[activeIndex] ?? LocalDate$.today(),
    );

    const [open, openHandlers] = useBoolean(false);

    const idBase = useId();
    const labelId = `${idBase}-label`;
    const descriptionId = `${idBase}-description`;
    const errorId = `${idBase}-error`;
    const describedBy = `${!isEmptyReactNode(error) ? errorId : ""} ${!isEmptyReactNode(description) ? descriptionId : ""}`;

    return (
      <Popover open={!disabled && open}>
        <PopoverTrigger
          onFocus={openHandlers.setTrue}
          asChild
          disabled={disabled}>
          <div
            ref={popoverRoot}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Escape" || e.key === "Tab") {
                openHandlers.setFalse();
                (e.target as HTMLElement).blur?.();
              }
            }}>
            <InputWrapper
              label={label}
              error={error}
              description={description}
              inputId={idBase}
              labelId={labelId}
              descriptionId={descriptionId}
              errorId={errorId}>
              <div
                role="group"
                className="flex"
                aria-labelledby={labelId}
                aria-describedby={describedBy}
                id={idBase}
                ref={ref}>
                <ReactInput
                  value={display[0]}
                  onChange={handleChange(0)}
                  parse={parseInput}
                  format={formatInput}
                  className={clsx(
                    InputVariantGenerator(props),
                    "rounded-r-none border-r-0",
                  )}
                  onFocus={() => {
                    setActiveIndex(0);
                    setActiveMonth(monthsFromValue[0] ?? LocalDate$.today());
                  }}
                  data-active={open && activeIndex === 0}
                  aria-invalid={!isEmptyReactNode(error)}
                  role={role}
                  placeholder={fromPlaceholder}
                  data-private={_private}
                  disabled={disabled}
                  aria-label="Range Start"
                />
                <Divider orientation="vertical" />
                <ReactInput
                  value={display[1]}
                  onChange={handleChange(1)}
                  parse={parseInput}
                  format={formatInput}
                  className={clsx(
                    InputVariantGenerator(props),
                    "rounded-l-none border-l-0",
                  )}
                  onFocus={() => {
                    setActiveIndex(1);
                    setActiveMonth(monthsFromValue[1] ?? LocalDate$.today());
                  }}
                  data-active={open && activeIndex === 1}
                  aria-invalid={!isEmptyReactNode(error)}
                  role={role}
                  placeholder={toPlaceholder}
                  data-private={_private}
                  disabled={disabled}
                  aria-label="Range End"
                />
              </div>
            </InputWrapper>
          </div>
        </PopoverTrigger>
        <PopoverContent
          sideOffset={4}
          onOpenAutoFocus={(event) => event.preventDefault()}
          onCloseAutoFocus={(event) => event.preventDefault()}
          className={clsx(
            "bg-white will-change-transform border border-primary-300 rounded max-h-80 overflow-auto z-10",
            "data-[state=open]:block data-[state=closed]:hidden",
          )}
          onInteractOutside={(e) => {
            if (
              P.isNotNullable(e.currentTarget) &&
              popoverRoot.current?.contains(e.currentTarget as Node)
            ) {
              return;
            }
            openHandlers.setFalse();
          }}>
          <DayPicker
            numberOfMonths={2}
            value={value}
            onDayClick={handleCalendarDayClick}
            onChange={F.constVoid}
            mode="range"
            activeValueIndex={activeIndex}
            month={activeMonth}
            onMonthChange={setActiveMonth}
          />
        </PopoverContent>
      </Popover>
    );
  },
);

export { DateRangeInput };

export type { DateRangeInputProps };
