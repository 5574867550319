import { IconEdit } from "@tabler/icons-react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Modal } from "@ender/shared/ds/modal";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { EditInvoiceForm } from "./edit-invoice-form";

type EditInvoiceButtonProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess: () => void;
};

function EditInvoiceButton({ invoice, onSuccess }: EditInvoiceButtonProps) {
  const [
    isEditInvoiceModalOpen,
    { setTrue: openEditInvoiceModal, setFalse: closeEditInvoiceModal },
  ] = useBoolean();

  return (
    <>
      <ActionIcon
        aria-label="Edit Invoice"
        tooltip="Edit Invoice"
        onClick={openEditInvoiceModal}>
        <IconEdit />
      </ActionIcon>
      <Modal
        title="Edit Invoice Details"
        opened={isEditInvoiceModalOpen}
        onClose={closeEditInvoiceModal}>
        <EditInvoiceForm
          invoice={invoice}
          onSuccess={onSuccess}
          closeModal={closeEditInvoiceModal}
        />
      </Modal>
    </>
  );
}

export { EditInvoiceButton };
