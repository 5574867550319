import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P, Record as R, flow } from "effect";

import type { EnderId } from "@ender/shared/core";
import { UnitZonesAPI } from "@ender/shared/generated/ender.api.core";
import type { UnitZonesResponse } from "@ender/shared/generated/ender.api.core.response";

import { InspectionZoneFields } from "./inspection-zone";

type FormDiscriminant = A.ReadonlyArray.Infer<
  NonNullable<UnitZonesResponse[keyof UnitZonesResponse]>
>;

type ZonesListProps = {
  /**
   * TODO eventually we will probably want to fetch zones from an `areaId` rather than a unitId.
   * fetching by unitId requires more lift on FE to do business logic around showing specific zones for the selected Area
   */
  unitId: EnderId;
  areaId: EnderId;
};

function ZonesList(props: ZonesListProps) {
  const { unitId, areaId } = props;
  const { data: zones = [] } = useQuery({
    queryKey: ["UnitZonesAPI.getUnitZones", { unitId }] as const,
    queryFn: ({ signal }) => UnitZonesAPI.getUnitZones({ unitId }, { signal }),
    select: flow(
      R.reduce([] as FormDiscriminant[], (acc, val) =>
        P.isNotNullable(val) && Array.isArray(val)
          ? [...acc, ...(val as FormDiscriminant[])]
          : acc,
      ),
      A.filter<FormDiscriminant>((a) => a.unitAreaId === areaId),
    ),
  });

  return (
    <>
      {zones.map((zone) => (
        <InspectionZoneFields key={zone.id} zone={zone} />
      ))}
    </>
  );
}

export { ZonesList };
