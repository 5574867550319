/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/createListing
 * @function createListing
 * @memberof LeasingAPI
 * @param {LeasingAPICreateListingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateListingResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { CreateListingResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type {
  AmenityAmenityType,
  PropertyCatPolicy,
  PropertyDogPolicy,
} from "@ender/shared/generated/ender.model.core.property";
import type { ListingListingStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPICreateListingPathParams = {
  unitId: EnderId;
};

type LeasingAPICreateListingSearchParams = {
  token?: string | undefined;
};

type LeasingAPICreateListingBodyParams = {
  advertisedRent?: SerializesInto<Money> | undefined;
  amenities: AmenityAmenityType[];
  appliances?: string | undefined;
  applicationFee?: SerializesInto<Money> | undefined;
  catPolicy?: PropertyCatPolicy | undefined;
  contactEmail?: string | undefined;
  contactPhone?: string | undefined;
  dogPolicy?: PropertyDogPolicy | undefined;
  hoaInformation?: string | undefined;
  isFeatured?: boolean | undefined;
  marketingBody?: string | undefined;
  marketingTitle?: string | undefined;
  maxNumPets?: number | undefined;
  maxNumVehicles?: number | undefined;
  minimumLeaseDuration?: number | undefined;
  moveInReadyDate?: SerializesInto<LocalDate> | undefined;
  offerDescription?: string | undefined;
  offerEndDate?: SerializesInto<LocalDate> | undefined;
  offerStartDate?: SerializesInto<LocalDate> | undefined;
  securityDeposit?: SerializesInto<Money> | undefined;
  status?: ListingListingStatus | undefined;
  tourReadyDate?: SerializesInto<LocalDate> | undefined;
  videoUrl?: string | undefined;
};

type LeasingAPICreateListingPayload = LeasingAPICreateListingPathParams &
  LeasingAPICreateListingSearchParams &
  LeasingAPICreateListingBodyParams;

function createListingUnsafeEffect(payload: LeasingAPICreateListingPayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateListingResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateListingResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/createListing`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createListing(
  payload: LeasingAPICreateListingPayload,
  options?: { signal?: AbortSignal },
): Promise<CreateListingResponse> {
  return F.pipe(
    payload,
    createListingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createListing };
export type {
  LeasingAPICreateListingBodyParams,
  LeasingAPICreateListingPathParams,
  LeasingAPICreateListingPayload,
  LeasingAPICreateListingSearchParams,
};
