/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/renewalOffers
 * @function createOffer
 * @memberof RenewalsAPI
 * @param {RenewalsAPICreateOfferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeaseRenewalOffer>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { LeaseRenewalOffer } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPICreateOfferPathParams = {
  leaseId: EnderId;
};

type RenewalsAPICreateOfferSearchParams = {
  token?: string | undefined;
};

type RenewalsAPICreateOfferBodyParams = {
  expirationDate: SerializesInto<LocalDate>;
  newEndDate: SerializesInto<LocalDate>;
  newRent: SerializesInto<Money>;
};

type RenewalsAPICreateOfferPayload = RenewalsAPICreateOfferPathParams &
  RenewalsAPICreateOfferSearchParams &
  RenewalsAPICreateOfferBodyParams;

function createOfferUnsafeEffect(payload: RenewalsAPICreateOfferPayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeaseRenewalOffer>({
        body,
        decode: unsafeDecodeJsonResponse<LeaseRenewalOffer>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/renewalOffers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createOffer(
  payload: RenewalsAPICreateOfferPayload,
  options?: { signal?: AbortSignal },
): Promise<LeaseRenewalOffer> {
  return F.pipe(
    payload,
    createOfferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createOffer };
export type {
  RenewalsAPICreateOfferBodyParams,
  RenewalsAPICreateOfferPathParams,
  RenewalsAPICreateOfferPayload,
  RenewalsAPICreateOfferSearchParams,
};
