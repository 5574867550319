import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Function as F } from "effect";
import { useCallback } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const UnitNameFormSchema = Schema.Struct({
  name: Schema.String,
});
type UnitNameFormOutput = Schema.Schema.Type<typeof UnitNameFormSchema>;

type EditUnitNameFormProps = {
  unit: Pick<UnitSerializerUnitResponse, "name" | "id">;
  onSuccess?: () => void;
  onCancel?: () => void;
};

function EditUnitNameForm(props: EditUnitNameFormProps) {
  const { unit, onSuccess = F.constVoid, onCancel } = props;

  const form = useEffectSchemaForm({
    defaultValues: {
      name: unit.name || "",
    },
    schema: UnitNameFormSchema,
  });

  const { mutateAsync: updateUnit } = useMutation({
    mutationFn: UnitsAPI.updateUnit,
    mutationKey: ["UnitsAPI.updateUnit"] as const,
  });

  const handleSubmit = useCallback(
    async (values: UnitNameFormOutput) => {
      await updateUnit({
        unitId: unit.id,
        name: values.name,
      });
      showSuccessNotification({ message: "Unit successfully updated" });
      onSuccess();
    },
    [onSuccess, unit.id, updateUnit],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput label="Unit Name" form={form} name="name" />
        <Group justify={Justify.end} align={Align.center}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditUnitNameForm };
