// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Close, Content, Portal, Root, Trigger } from "@radix-ui/react-popover";
import { cva } from "class-variance-authority";
import type { MouseEvent, PropsWithChildren } from "react";
import { forwardRef } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";

type PopoverProps = {
  opened?: boolean;
  onOpenedChange?: (open: boolean) => void;
};

const PanelVariantGenerator = cva([
  "bg-white will-change-transform border border-primary-300 rounded overflow-auto z-10 flex flex-col",
  "max-h-[--radix-popover-content-available-height]",
]);

function Popover(props: PropsWithChildren<PopoverProps>) {
  const { opened, onOpenedChange, children } = props;

  return (
    <Root open={opened} onOpenChange={onOpenedChange}>
      {children}
    </Root>
  );
}

// type PopoverCloseProps = ComponentPropsWithoutRef<typeof Close>;
function PopoverClose(props: PropsWithChildren) {
  const { children } = props;

  return (
    <Close asChild>
      <span>{children}</span>
    </Close>
  );
}

type PopoverTriggerProps = {
  disabled?: boolean;
  label?: string;
};

function PopoverTrigger(props: PropsWithChildren<PopoverTriggerProps>) {
  const { children, disabled, label } = props;
  const preventOpen = (e: MouseEvent) => {
    e.preventDefault();
  };

  return (
    <Trigger
      asChild
      disabled={disabled}
      onClick={disabled ? preventOpen : UNDEFINED}
      aria-label={label}>
      {children}
    </Trigger>
  );
}

type PopoverContentProps = {
  /**
   * the label of the popover's rendered dialog element. Will be used as the accessible name for the dialog element.
   * Can be located by screen readers and unit tests.
   */
  label?: string;
};

const PopoverContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PopoverContentProps>
>(function Popover(props, ref) {
  const { children, label } = props;

  return (
    <Portal>
      <Content
        ref={ref}
        align="start"
        sideOffset={4}
        collisionPadding={16}
        className={PanelVariantGenerator()}
        aria-label={label}>
        {children}
      </Content>
    </Portal>
  );
});

export { Popover, PopoverClose, PopoverContent, PopoverTrigger };

export type { PopoverProps };
