import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P } from "effect";
import * as S from "effect/String";
import { useWatch } from "react-hook-form";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { UNDEFINED } from "@ender/shared/constants/general";
import {
  INVOICE_PAYABLE_TYPE_DISPLAY_NAMES,
  MIN_CREATE_EDIT_INVOICE_DATE,
} from "@ender/shared/constants/invoices";
import {
  MONTH_NAMES_LONG,
  MONTH_NAMES_SHORT,
} from "@ender/shared/constants/string";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { InvoicesAPIEditInvoicesPayload } from "@ender/shared/generated/ender.api.accounting";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { CreateInvoiceRequestPayee } from "@ender/shared/generated/ender.api.accounting.request";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import {
  InvoiceInvoiceTypeEnum,
  InvoicePayableTypeEnum,
  InvoicePayableTypeValues,
} from "@ender/shared/generated/ender.model.payments.invoice";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { InvoicePartySelect } from "@ender/widgets/finance/create-invoice";

import { DuplicateInvoiceIcon } from "../duplicate-invoice-icon";
import type { EditInvoiceFormOutput } from "./edit-invoice-form.schema";
import { createEditInvoiceFormSchema } from "./edit-invoice-form.schema";

function getMonthNumberByShortName(month: string): string {
  const monthIndex = MONTH_NAMES_SHORT.findIndex(
    (m) => m.toLowerCase() === month.toLowerCase(),
  );
  if (monthIndex === -1) {
    const newIndex = MONTH_NAMES_LONG.findIndex(
      (m) => m.toLowerCase() === month.toLowerCase(),
    );
    return (newIndex + 1).toString().padStart(2, "0");
  }

  return (monthIndex + 1).toString().padStart(2, "0");
}

function convertAccountingPeriodToLocalDate$(
  accountingPeriod: string,
): LocalDate$.LocalDate {
  const _accountingPeriodArr = accountingPeriod.split(" ");
  _accountingPeriodArr[0] = getMonthNumberByShortName(_accountingPeriodArr[0]);
  _accountingPeriodArr.reverse();
  _accountingPeriodArr.push("01");

  const dateString = _accountingPeriodArr.join("-");

  return LocalDate$.of(dateString);
}

function validateDateRange(
  startDate: O.Option<LocalDate$.LocalDate>,
  endDate: O.Option<LocalDate$.LocalDate>,
): boolean {
  if (O.isNone(startDate) || O.isNone(endDate)) {
    return true;
  }

  const startDateValue = startDate.pipe(O.getOrThrow);
  const endDateValue = endDate.pipe(O.getOrThrow);

  return startDateValue.isBeforeOrEqual(endDateValue);
}

type EditInvoicePayee = CreateInvoiceRequestPayee & { name?: string };

type EditInvoiceFormProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess: () => void;
  closeModal: () => void;
};

function EditInvoiceForm({
  invoice,
  onSuccess,
  closeModal,
}: EditInvoiceFormProps) {
  const queryClient = useQueryClient();
  const confirmation = useConfirmationContext();
  const { isLoading: isSavingInvoice, mutateAsync: editInvoices } = useMutation(
    {
      mutationFn: (payload: InvoicesAPIEditInvoicesPayload) =>
        InvoicesAPI.editInvoices(payload),
      mutationKey: ["InvoicesAPI.editInvoices", invoice.id] as const,
    },
  );

  const isPayableInvoice =
    invoice.type === InvoiceInvoiceTypeEnum.PAYABLE ||
    invoice.invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;

  const invoiceHasOpenAccountingPeriods =
    isPayableInvoice &&
    invoice.accountingPeriods.some((period) => !period.isClosed);

  const accountingPeriodType = isPayableInvoice
    ? AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE
    : AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE;

  // @ts-expect-error The generated type has owedByParty | owedToParty as optionally supplied so id can be undefined
  const originalParty: EditInvoicePayee = isPayableInvoice
    ? {
        id: invoice.owedToParty.id || invoice.owedToParty.party,
        name:
          invoice.owedToParty.name ||
          invoice.owedToParty.companyName ||
          "External Merchant",
        party: invoice.owedToParty.party,
      }
    : {
        id: invoice.owedByParty.id || invoice.owedByParty.party,
        name:
          invoice.owedByParty.name ||
          invoice.owedByParty.companyName ||
          "External Merchant",
        party: invoice.owedByParty.party,
      };
  const isCheckInfoEditable =
    invoice.status === ApprovableApprovalStatusEnum.APPROVED &&
    (invoice?.paymentInfo?.type === MoneyTransferTransferTypeEnum.PRINT_CHECK ||
      invoice?.paymentInfo?.type ===
        MoneyTransferTransferTypeEnum.MARK_PAID_CHECK);
  const isPayPartyEditable =
    invoice.status === ApprovableApprovalStatusEnum.NEW;
  const payPartyLabel = isPayableInvoice ? "Owed To" : "Owed By";

  // Keep separate from form state to re-use for comparison onSubmit
  const initialValues = {
    accountingPeriod:
      invoiceHasOpenAccountingPeriods &&
      P.isNotUndefined(invoice.accountingPeriod)
        ? O.some({
            startDate: convertAccountingPeriodToLocalDate$(
              invoice.accountingPeriod,
            ).toJSON(),
          })
        : O.none(),
    checkMemo: invoice.paymentInfo?.details?.memo ?? "",
    checkNumber: invoice.paymentInfo?.details?.checkNumber ?? "",
    description: invoice.description,
    externalInvoiceId: invoice.externalInvoiceId,
    ledgerDate: LocalDate$.parse(invoice.date),
    payableType:
      O.fromNullable(invoice.payableType) ??
      O.fromNullable(InvoicePayableTypeEnum.GENERAL),
    payee: originalParty,
    poNumbers: invoice.poNumbers.join(", "),
  };
  const form = useEffectSchemaForm({
    defaultValues: initialValues,
    schema: createEditInvoiceFormSchema({ isCheckInfoEditable }),
  });

  async function editInvoiceWithFormData(values: EditInvoiceFormOutput) {
    const isLedgerDateUpdated = F.pipe(
      O.Do,
      O.bind("initial", () => initialValues.ledgerDate),
      O.bind("current", () => values.ledgerDate),
      O.map(({ initial, current }) => LocalDate$.Order(initial, current) !== 0),
      O.getOrElse(() => false),
    );

    const data = {
      ...(invoiceHasOpenAccountingPeriods &&
        initialValues.accountingPeriod !== values.accountingPeriod &&
        P.isNotUndefined(values.accountingPeriod) && {
          accountingPeriod: O.getOrUndefined(values.accountingPeriod)
            ?.startDate,
        }),

      ...(initialValues.externalInvoiceId !== values.externalInvoiceId && {
        externalInvoiceId: values.externalInvoiceId,
      }),
      ...(isLedgerDateUpdated && {
        ledgerDate: values.ledgerDate.pipe(
          O.match({ onSome: (v) => v.toJSON(), onNone: () => UNDEFINED }),
        ),
      }),
      ...(isPayableInvoice &&
        initialValues.payableType !== values.payableType && {
          payableType: O.getOrUndefined(values.payableType),
        }),
      ...(isPayPartyEditable &&
        initialValues.payee !== values.payee && { payee: values.payee }),
      poNumbers: S.isNonEmpty(values.poNumbers ?? "")
        ? (values.poNumbers?.split(",").map((val) => parseInt(val)) ?? [])
        : [],
    };

    await editInvoices({
      checkMemo: isCheckInfoEditable ? values.checkMemo : "",
      checkNumber: isCheckInfoEditable ? values.checkNumber : "",
      description: values.description,
      invoiceIds: [invoice.id],
      ...data,
    });
    await queryClient.invalidateQueries(["InvoicesAPI.getInvoice", invoice.id]);
    showSuccessNotification({ message: "Invoice details updated." });
    onSuccess();
    closeModal();
  }

  const ledgerDate = useWatch({ control: form.control, name: "ledgerDate" });

  async function handleSubmit(values: EditInvoiceFormOutput) {
    // is accrual date prior to pay date (ENDER-7033)

    if (
      P.isNotUndefined(invoice.paymentInfo?.generalLedgerDate) &&
      !validateDateRange(
        ledgerDate,
        LocalDate$.parse(invoice.paymentInfo?.generalLedgerDate),
      )
    ) {
      try {
        await confirmation({
          confirmButtonLabel: "Yes, proceed",
          content: (
            <Text>
              The accrual date is before the payment date for this invoice. Are
              you sure you would like to proceed?
            </Text>
          ),
        });
        await editInvoiceWithFormData(values);
      } catch {}
    } else {
      await editInvoiceWithFormData(values);
    }
  }

  const payee = useWatch({ control: form.control, name: "payee" });
  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput
          form={form}
          name="externalInvoiceId"
          label={
            <Group spacing={Spacing.xs}>
              <Text size={FontSize.sm}>Invoice Number</Text>
              {invoice.isDupExternalId && <DuplicateInvoiceIcon />}
            </Group>
          }
          disabled={isSavingInvoice}
        />
        {isPayableInvoice && (
          <FormSelect
            name="payableType"
            form={form}
            label="Invoice Type"
            data={InvoicePayableTypeValues.map((value) => ({
              label: INVOICE_PAYABLE_TYPE_DISPLAY_NAMES[value],
              value: value,
            }))}
          />
        )}
        <FormTextInput
          label="Description"
          name="description"
          form={form}
          disabled={isSavingInvoice}
        />
        <FormDateInput
          label="Invoice Date"
          name="ledgerDate"
          form={form}
          disabled={isSavingInvoice || invoice.currentState.isApproved}
          minDate={MIN_CREATE_EDIT_INVOICE_DATE}
        />
        {isPayableInvoice && (
          <>
            <FormAccountingPeriodSelector
              label="Accounting Period"
              periodType={accountingPeriodType}
              name="accountingPeriod"
              form={form}
              disabled={
                !invoiceHasOpenAccountingPeriods ||
                isSavingInvoice ||
                invoice.currentState.isFullyRejected
              }
            />
            {/* Tyler - 11/14/2024 - this pattern of altering an array within a single TextInput  is not good to follow, but no better component exists */}
            <Tooltip label="When entering multiple purchase orders, separate the numbers with a comma (e.g. 1234, 5678).">
              <FormTextInput
                form={form}
                label="Purchase Order Number(s) (optional)"
                name="poNumbers"
                disabled={isSavingInvoice}
              />
            </Tooltip>
          </>
        )}
        {isPayPartyEditable && (
          <InvoicePartySelect
            label={payPartyLabel}
            disabled={isSavingInvoice}
            value={payee?.id}
            onChange={(selectedOption) => {
              if (selectedOption) {
                const { label: selectedLabel, party, value } = selectedOption;
                form.setValue("payee", {
                  id: value,
                  name: selectedLabel,
                  party,
                });
              } else {
                form.setValue("payee", UNDEFINED);
              }
            }}
          />
        )}
        {/* 2024-02-15 Geoffrey: These should probably be removed cc: Jack Anne */}
        {isCheckInfoEditable && (
          <>
            <FormTextInput
              label="Check Number"
              name="checkNumber"
              form={form}
            />
            <FormTextInput label="Memo" name="checkMemo" form={form} />
          </>
        )}
        <Group justify={Justify.end}>
          <Button type="submit" loading={isSavingInvoice}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditInvoiceForm };
