/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}
 * @function getInspection
 * @memberof InspectionAPI
 * @param {InspectionAPIGetInspectionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetInspectionPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIGetInspectionSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetInspectionPayload = InspectionAPIGetInspectionPathParams &
  InspectionAPIGetInspectionSearchParams;

function getInspectionUnsafeEffect(payload: InspectionAPIGetInspectionPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInspection(
  payload: InspectionAPIGetInspectionPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionResponse> {
  return F.pipe(
    payload,
    getInspectionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInspection };
export type {
  InspectionAPIGetInspectionPathParams,
  InspectionAPIGetInspectionPayload,
  InspectionAPIGetInspectionSearchParams,
};
