/**
 * HTTP Method: POST
 * Pathname: /factors/{factorId}
 * @function updateCustomFactor
 * @memberof FactorsAPI
 * @param {FactorsAPIUpdateCustomFactorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIUpdateCustomFactorPathParams = {
  factorId: EnderId;
};

type FactorsAPIUpdateCustomFactorSearchParams = {
  token?: string | undefined;
};

type FactorsAPIUpdateCustomFactorBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type FactorsAPIUpdateCustomFactorPayload =
  FactorsAPIUpdateCustomFactorPathParams &
    FactorsAPIUpdateCustomFactorSearchParams &
    FactorsAPIUpdateCustomFactorBodyParams;

function updateCustomFactorUnsafeEffect(
  payload: FactorsAPIUpdateCustomFactorPayload,
) {
  const { factorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/factors/${factorId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateCustomFactor(
  payload: FactorsAPIUpdateCustomFactorPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateCustomFactorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateCustomFactor };
export type {
  FactorsAPIUpdateCustomFactorBodyParams,
  FactorsAPIUpdateCustomFactorPathParams,
  FactorsAPIUpdateCustomFactorPayload,
  FactorsAPIUpdateCustomFactorSearchParams,
};
