import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeadLeadSourceValues = [
  "ZILLOW",
  "APARTMENTS_COM",
  "COMPANY_WEBSITE",
  "ZUMPER",
  "OTHER",
] as const;

const LeadLeadSourceEffectSchema = Schema.Literal(...LeadLeadSourceValues);

type LeadLeadSource = Schema.Schema.Type<typeof LeadLeadSourceEffectSchema>;

const LeadLeadSourceEnum = castEnum(LeadLeadSourceEffectSchema);

function randomLeadLeadSource(): LeadLeadSource {
  return rand(LeadLeadSourceValues);
}

export {
  LeadLeadSourceEffectSchema,
  LeadLeadSourceEnum,
  LeadLeadSourceValues,
  randomLeadLeadSource,
};
export type { LeadLeadSource };
