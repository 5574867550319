import { IconArrowBackUp } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { Option as O, pipe } from "effect";
import type { HTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";

import { Instant$ } from "@ender/shared/core";
import { Align, Overflow, Spacing } from "@ender/shared/ds/flex";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import type { ChatUserFinderUserResponse } from "@ender/shared/generated/com.ender.chat";
import type { GetChatInfoResponseLastItemResponse } from "@ender/shared/generated/ender.api.misc.response";

const ChannelButtonVariantGenerator = cva(
  [
    "px-3 py-2 border-b border-slate-100",
    "hover:bg-primary-50 active:bg-primary-100",
    "aria-selected:bg-primary-100 aria-selected:hover:bg-primary-100",
  ],
  {
    variants: {},
  },
);

type ChannelPreviewProps = {
  title?: ReactNode;
  selected?: boolean;
  lastMessage?: Pick<
    GetChatInfoResponseLastItemResponse,
    "text" | "command"
  > & {
    author?: Pick<
      NonNullable<GetChatInfoResponseLastItemResponse["author"]>,
      "isMe" | "name"
    >;
    dateTime: Pick<GetChatInfoResponseLastItemResponse["dateTime"], "unix">;
  };
  usersInChat?: ChatUserFinderUserResponse[];
  loading?: boolean;
} & Pick<HTMLAttributes<HTMLButtonElement>, "onClick">;

const getLastMessageText = (
  lastMessage: Pick<GetChatInfoResponseLastItemResponse, "command" | "text">,
): JSX.Element => {
  switch (lastMessage.command) {
    case "chat":
      return <>{lastMessage.text}</>;
    case "document":
      return <em>Sent an attachment</em>;
    case "invoice":
      return <em>Sent an invoice</em>;
    case "call":
      return <em>A call occurred</em>;
    default:
      return <></>;
  }
};

const ChannelPreview = forwardRef<HTMLButtonElement, ChannelPreviewProps>(
  function ChannelPreview(props, ref) {
    const {
      title,
      selected,
      lastMessage,
      usersInChat,
      loading = false,
      onClick,
    } = props;
    return (
      <button
        className={ChannelButtonVariantGenerator(props)}
        aria-selected={selected}
        ref={ref}
        disabled={loading}
        onClick={onClick}>
        <Stack
          spacing={Spacing.xs}
          align={Align.start}
          overflow={Overflow.auto}>
          <Text weight={FontWeight.medium}>{title}</Text>
          {lastMessage ? (
            <>
              <Text size={FontSize.sm} color="slate-700">
                {pipe(
                  Instant$.parse(lastMessage.dateTime.unix),
                  O.map((v) => v.toString()),
                  O.getOrElse(() => ""), //maybe throw here? If `lastMessage` is defined it should also be parsable
                )}
              </Text>
              <Text size={FontSize.sm} truncate color="slate-700">
                {lastMessage.author?.isMe ? (
                  <IconArrowBackUp
                    color="var(--color-slate-500)"
                    className="-mr-1 align-bottom"
                  />
                ) : (
                  `${(usersInChat?.length ?? 0) > 2 ? `${lastMessage.author?.name.split(/\s/)[0]}: ` : ""}`
                )}
                {getLastMessageText(lastMessage)}
              </Text>
            </>
          ) : (
            <Skeleton visible={loading}>
              <Text size={FontSize.sm}>No Messages</Text>
            </Skeleton>
          )}
        </Stack>
      </button>
    );
  },
);

export { ChannelPreview };
export type { ChannelPreviewProps };
