import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InvestorAllocationAllocationStatusValues = ["ACTIVE", "PAUSED"] as const;

const InvestorAllocationAllocationStatusEffectSchema = Schema.Literal(
  ...InvestorAllocationAllocationStatusValues,
);

type InvestorAllocationAllocationStatus = Schema.Schema.Type<
  typeof InvestorAllocationAllocationStatusEffectSchema
>;

const InvestorAllocationAllocationStatusEnum = castEnum(
  InvestorAllocationAllocationStatusEffectSchema,
);

function randomInvestorAllocationAllocationStatus(): InvestorAllocationAllocationStatus {
  return rand(InvestorAllocationAllocationStatusValues);
}

export {
  InvestorAllocationAllocationStatusEffectSchema,
  InvestorAllocationAllocationStatusEnum,
  InvestorAllocationAllocationStatusValues,
  randomInvestorAllocationAllocationStatus,
};
export type { InvestorAllocationAllocationStatus };
