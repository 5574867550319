import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionAreaAreaInspectionStatusValues = [
  "COMPLETED",
  "NEEDS_INSPECTION",
  "NOT_APPLICABLE",
] as const;

const InspectionAreaAreaInspectionStatusEffectSchema = Schema.Literal(
  ...InspectionAreaAreaInspectionStatusValues,
);

type InspectionAreaAreaInspectionStatus = Schema.Schema.Type<
  typeof InspectionAreaAreaInspectionStatusEffectSchema
>;

const InspectionAreaAreaInspectionStatusEnum = castEnum(
  InspectionAreaAreaInspectionStatusEffectSchema,
);

function randomInspectionAreaAreaInspectionStatus(): InspectionAreaAreaInspectionStatus {
  return rand(InspectionAreaAreaInspectionStatusValues);
}

export {
  InspectionAreaAreaInspectionStatusEffectSchema,
  InspectionAreaAreaInspectionStatusEnum,
  InspectionAreaAreaInspectionStatusValues,
  randomInspectionAreaAreaInspectionStatus,
};
export type { InspectionAreaAreaInspectionStatus };
