import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const ScreenRangeValues = ["SMALL", "MEDIUM", "LARGE"] as const;
const ScreenRangeSchema = z.enum(ScreenRangeValues);
type ScreenRange = z.infer<typeof ScreenRangeSchema>;

const ScreenRangeEnum = castEnum<ScreenRange>(ScreenRangeSchema);

export { ScreenRangeEnum, ScreenRangeSchema, ScreenRangeValues };
export type { ScreenRange };
