import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import { useState } from "react";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Align } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type {
  BankAccount,
  MoneyTransferTransferType,
} from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";

import { FieldsForPaymentType } from "../shared/fields-for-payment-type";
import { SelectBankAccount } from "../shared/select-bank-account";
import { SelectPaymentType } from "../shared/select-payment-type";
import { SubmitButton } from "../shared/submit-button";
import type { MarkAsPaidReceivedSingleFormValues } from "./use-mark-as-paid-received-single-invoice-form";
import { useMarkAsPaidReceivedSingleForm } from "./use-mark-as-paid-received-single-invoice-form";
import {
  getApiArgumentsForSingleInvoice,
  useMarkPaidWithWarnings,
} from "./utils";

type MarkAsPaidReceivedSingleInvoiceFormProps = {
  bankAccounts: BankAccount[];
  invoice: {
    amount: InvoiceSerializerInvoiceResponse["amount"];
    id: EnderId;
    type: InvoiceInvoiceType;
  };
  operatingAccountId?: EnderId;
  onSuccess?: () => void;
};

function MarkAsPaidReceivedSingleInvoiceForm({
  bankAccounts,
  invoice,
  operatingAccountId,
  onSuccess = F.constVoid,
}: MarkAsPaidReceivedSingleInvoiceFormProps) {
  const [paymentType, setPaymentType] = useState<MoneyTransferTransferType>(
    MoneyTransferTransferTypeEnum.BANK_TRANSFER,
  );

  const { form } = useMarkAsPaidReceivedSingleForm({
    bankAccountId: operatingAccountId || bankAccounts[0]?.id,
    paymentType,
  });

  const markPaidWithWarnings = useMarkPaidWithWarnings();

  const {
    isLoading: isProcessingSubmission,
    mutateAsync: markAsPaidReceivedAction,
  } = useMutation({
    mutationFn: async (values: MarkAsPaidReceivedSingleFormValues) => {
      const response = await markPaidWithWarnings(
        getApiArgumentsForSingleInvoice({
          paymentType,
          values,
          invoiceId: invoice.id,
        }),
      );
      if (P.isNotNullable(response)) {
        onSuccess();
      }
      return response;
    },
    mutationKey: ["markAsPaidReceivedSingleInvoice"],
  });

  async function onSubmit(values: MarkAsPaidReceivedSingleFormValues) {
    await markAsPaidReceivedAction(values);
  }

  if (P.isNullable(bankAccounts) || bankAccounts.length === 0) {
    return NULL;
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <SelectPaymentType value={paymentType} onChange={setPaymentType} />
        <SelectBankAccount
          bankAccounts={bankAccounts}
          inputProps={form.getInputProps("bankAccountId")}
          selectedBankAccountId={form.values.bankAccountId}
        />
        <FieldsForPaymentType<MarkAsPaidReceivedSingleFormValues>
          form={form}
          formFieldsPrefix=""
          hideSeparatePaymentsCheckbox
          invoiceType={invoice.type}
          paymentType={paymentType}
        />
        <Stack align={Align.end}>
          <SubmitButton
            amount={invoice.amount}
            disabled={isProcessingSubmission || P.isNullable(invoice.type)}
            paymentType={paymentType}
          />
        </Stack>
      </Stack>
    </form>
  );
}

export { MarkAsPaidReceivedSingleInvoiceForm };
