import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DealPoolTypeValues = [
  "NONE",
  "BOTH",
  "PRIVATE",
  "ABOVE_GROUND",
  "IN_GROUND",
  "COMMUNITY",
  "UNKNOWN",
] as const;

const DealPoolTypeEffectSchema = Schema.Literal(...DealPoolTypeValues);

type DealPoolType = Schema.Schema.Type<typeof DealPoolTypeEffectSchema>;

const DealPoolTypeEnum = castEnum(DealPoolTypeEffectSchema);

function randomDealPoolType(): DealPoolType {
  return rand(DealPoolTypeValues);
}

export {
  DealPoolTypeEffectSchema,
  DealPoolTypeEnum,
  DealPoolTypeValues,
  randomDealPoolType,
};
export type { DealPoolType };
