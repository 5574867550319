import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyPropertyTypeValues = [
  "RESIDENTIAL",
  "COMMERCIAL",
  "STORAGE",
  "MIXED",
] as const;

const PropertyPropertyTypeEffectSchema = Schema.Literal(
  ...PropertyPropertyTypeValues,
);

type PropertyPropertyType = Schema.Schema.Type<
  typeof PropertyPropertyTypeEffectSchema
>;

const PropertyPropertyTypeEnum = castEnum(PropertyPropertyTypeEffectSchema);

function randomPropertyPropertyType(): PropertyPropertyType {
  return rand(PropertyPropertyTypeValues);
}

export {
  PropertyPropertyTypeEffectSchema,
  PropertyPropertyTypeEnum,
  PropertyPropertyTypeValues,
  randomPropertyPropertyType,
};
export type { PropertyPropertyType };
