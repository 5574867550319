/**
 * HTTP Method: POST
 * Pathname: /invoices/batchMarkPaid
 * @function getRecentInvoicesForProperties
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetRecentInvoicesForPropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertyInvoicesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PropertyInvoicesResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { ReportWidgetSortOrder } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetRecentInvoicesForPropertiesSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetRecentInvoicesForPropertiesBodyParams = {
  limit?: number | undefined;
  order?: ReportWidgetSortOrder | undefined;
  propertyIds: EnderId[];
  sortBy?: string | undefined;
};

type InvoicesAPIGetRecentInvoicesForPropertiesPayload =
  InvoicesAPIGetRecentInvoicesForPropertiesSearchParams &
    InvoicesAPIGetRecentInvoicesForPropertiesBodyParams;

function getRecentInvoicesForPropertiesUnsafeEffect(
  payload: InvoicesAPIGetRecentInvoicesForPropertiesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertyInvoicesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<PropertyInvoicesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/batchMarkPaid",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRecentInvoicesForProperties(
  payload: InvoicesAPIGetRecentInvoicesForPropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertyInvoicesResponse> {
  return F.pipe(
    payload,
    getRecentInvoicesForPropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRecentInvoicesForProperties };
export type {
  InvoicesAPIGetRecentInvoicesForPropertiesBodyParams,
  InvoicesAPIGetRecentInvoicesForPropertiesPayload,
  InvoicesAPIGetRecentInvoicesForPropertiesSearchParams,
};
