/**
 * Adds a bank account that is not registered with a payment provider like Dwolla. Such accounts cannot be used to
 initiate an ACH from Ender. They can only be used for the `Mark Paid/Received` flows where a PM manually enters the
 details of a check hitting the account.
 * HTTP Method: POST
 * Pathname: /bankAccounts/linkUnregistered
 * @function createUnlinkedAccount
 * @memberof BankingAPI
 * @param {BankingAPICreateUnlinkedAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BankAccount>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPICreateUnlinkedAccountSearchParams = {
  token?: string | undefined;
};

type BankingAPICreateUnlinkedAccountBodyParams = {
  accountNumber: string;
  customerId: EnderId;
  customerType: ModelType;
  isForWire?: boolean | undefined;
  name?: string | undefined;
  routingNumber: string;
};

type BankingAPICreateUnlinkedAccountPayload =
  BankingAPICreateUnlinkedAccountSearchParams &
    BankingAPICreateUnlinkedAccountBodyParams;

function createUnlinkedAccountUnsafeEffect(
  payload: BankingAPICreateUnlinkedAccountPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BankAccount>({
        body,
        decode: unsafeDecodeJsonResponse<BankAccount>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/bankAccounts/linkUnregistered",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createUnlinkedAccount(
  payload: BankingAPICreateUnlinkedAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<BankAccount> {
  return F.pipe(
    payload,
    createUnlinkedAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createUnlinkedAccount };
export type {
  BankingAPICreateUnlinkedAccountBodyParams,
  BankingAPICreateUnlinkedAccountPayload,
  BankingAPICreateUnlinkedAccountSearchParams,
};
