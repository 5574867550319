/**
 * HTTP Method: GET
 * Pathname: /middleLayer/categories/{categoryId}/hasGltxs
 * @function glCategoryHasGltxs
 * @memberof AccountingMiddleLayerAPI
 * @param {AccountingMiddleLayerAPIGlCategoryHasGltxsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingMiddleLayerAPIGLCategoryHasGltxs>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AccountingMiddleLayerAPIGLCategoryHasGltxs } from "../accounting-middle-layer-api-g-l-category-has-gltxs";

type AccountingMiddleLayerAPIGlCategoryHasGltxsPathParams = {
  categoryId: EnderId;
};

type AccountingMiddleLayerAPIGlCategoryHasGltxsSearchParams = {
  token?: string | undefined;
};

type AccountingMiddleLayerAPIGlCategoryHasGltxsPayload =
  AccountingMiddleLayerAPIGlCategoryHasGltxsPathParams &
    AccountingMiddleLayerAPIGlCategoryHasGltxsSearchParams;

function glCategoryHasGltxsUnsafeEffect(
  payload: AccountingMiddleLayerAPIGlCategoryHasGltxsPayload,
) {
  const { categoryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingMiddleLayerAPIGLCategoryHasGltxs>({
        body,
        decode:
          unsafeDecodeJsonResponse<AccountingMiddleLayerAPIGLCategoryHasGltxs>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/middleLayer/categories/${categoryId}/hasGltxs`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function glCategoryHasGltxs(
  payload: AccountingMiddleLayerAPIGlCategoryHasGltxsPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingMiddleLayerAPIGLCategoryHasGltxs> {
  return F.pipe(
    payload,
    glCategoryHasGltxsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { glCategoryHasGltxs };
export type {
  AccountingMiddleLayerAPIGlCategoryHasGltxsPathParams,
  AccountingMiddleLayerAPIGlCategoryHasGltxsPayload,
  AccountingMiddleLayerAPIGlCategoryHasGltxsSearchParams,
};
