/**
 * HTTP Method: POST
 * Pathname: /factorGroups/lock
 * @function lockFactorGroup
 * @memberof FactorsAPI
 * @param {FactorsAPILockFactorGroupPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPILockFactorGroupSearchParams = {
  token?: string | undefined;
};

type FactorsAPILockFactorGroupBodyParams = {
  factorGroup: string;
  modelId: EnderId;
  modelType: ModelType;
};

type FactorsAPILockFactorGroupPayload = FactorsAPILockFactorGroupSearchParams &
  FactorsAPILockFactorGroupBodyParams;

function lockFactorGroupUnsafeEffect(
  payload: FactorsAPILockFactorGroupPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/factorGroups/lock",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function lockFactorGroup(
  payload: FactorsAPILockFactorGroupPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    lockFactorGroupUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { lockFactorGroup };
export type {
  FactorsAPILockFactorGroupBodyParams,
  FactorsAPILockFactorGroupPayload,
  FactorsAPILockFactorGroupSearchParams,
};
