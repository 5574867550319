import type { Record } from "effect";

import type { BadgeColors } from "@ender/shared/ds/badge";
import { BadgeColor } from "@ender/shared/ds/badge";
import type {
  CollectionsStepCollectionsActionType,
  PaymentPlanPaymentPlanStatus,
  PaymentPlanPaymentPlanType,
} from "@ender/shared/generated/ender.model.misc";
import {
  CollectionsStepChargeTypeEnum,
  PaymentPlanPaymentPlanStatusEnum,
  PaymentPlanPaymentPlanTypeEnum,
} from "@ender/shared/generated/ender.model.misc";

const ActionTypeToLabelMap: Record<
  CollectionsStepCollectionsActionType,
  string
> = {
  CERTIFIED_MAIL: "Send Certified Mail",
  CREATE_TASK: "Create Task",
  EMAIL: "Send Email",
  INTEND_TO_EVICT: "Intend to Evict",
  LATE_FEE: "Charge Late Fee",
  MOVE_TO_EVICT: "Move to Evict",
  PHONE_CALL: "Send Phone Call",
  SMS: "Send SMS",
};

const PaymentPlanTypeToLabelMap: Record<PaymentPlanPaymentPlanType, string> = {
  [PaymentPlanPaymentPlanTypeEnum.HOUSING_AUTHORITY]: "Housing Authority",
  [PaymentPlanPaymentPlanTypeEnum.PM]: "Property Manager",
};

const PaymentPlanStatusToBadgeColorMap: Record<
  PaymentPlanPaymentPlanStatus,
  BadgeColors
> = {
  [PaymentPlanPaymentPlanStatusEnum.ACTIVE]: BadgeColor.green,
  [PaymentPlanPaymentPlanStatusEnum.MISSED]: BadgeColor.red,
  [PaymentPlanPaymentPlanStatusEnum.COMPLETE]: BadgeColor.slate,
};

const NO_EDIT_PERMISSION_TOOLTIP =
  "You do not have permission to edit the Delinquency Manager Timeline. Please reach out to your system admin for this permission.";

const ChargeType = {
  FIXED: "fixed",
  PROPORTIONAL: "proportional",
} as const;

type ChargeTypeValue = (typeof ChargeType)[keyof typeof ChargeType];

const numberOfMonthOptions = [
  { label: "1 month", value: 1 },
  { label: "2 months", value: 2 },
  { label: "3 months", value: 3 },
];

const chargeTypeOptions = [
  {
    label: "Rent Charges Only",
    value: CollectionsStepChargeTypeEnum.MONTHLY_RENT,
  },
  {
    label: "All Scheduled Charges",
    value: CollectionsStepChargeTypeEnum.MONTHLY_SCHEDULE_CHARGES,
  },
];

export {
  ActionTypeToLabelMap,
  ChargeType,
  chargeTypeOptions,
  NO_EDIT_PERMISSION_TOOLTIP,
  numberOfMonthOptions,
  PaymentPlanStatusToBadgeColorMap,
  PaymentPlanTypeToLabelMap,
};

export type { ChargeTypeValue };
