import { bulkUploadLeaseCharges } from "./bulk-upload-lease-charges";
import { createLeaseCharges } from "./create-lease-charges";
import { createLeaseCredit } from "./create-lease-credit";
import { createLeasePayoutInvoice } from "./create-lease-payout-invoice";
import { createLeasePayoutInvoices } from "./create-lease-payout-invoices";
import { createLeaseReceipt } from "./create-lease-receipt";
import { getTenantLedger } from "./get-tenant-ledger";
import { ledgerExport } from "./ledger-export";
import { manuallyAllocateTenantInflow } from "./manually-allocate-tenant-inflow";
import { rerunAccountingForPayment } from "./rerun-accounting-for-payment";
import { reverseTenantCharge } from "./reverse-tenant-charge";
import { reverseTenantLedgerEntry } from "./reverse-tenant-ledger-entry";
import { reverseTenantRefund } from "./reverse-tenant-refund";
import { writeOffBadDebt } from "./write-off-bad-debt";

const TenantLedgerAPI = {
  bulkUploadLeaseCharges,
  createLeaseCharges,
  createLeaseCredit,
  createLeasePayoutInvoice,
  createLeasePayoutInvoices,
  createLeaseReceipt,
  getTenantLedger,
  ledgerExport,
  manuallyAllocateTenantInflow,
  rerunAccountingForPayment,
  reverseTenantCharge,
  reverseTenantLedgerEntry,
  reverseTenantRefund,
  writeOffBadDebt,
};

export { TenantLedgerAPI };
