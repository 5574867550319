import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import type { ElementRef } from "react";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { ButtonVariant } from "@ender/shared/ds/button";
import { MoneyInput } from "@ender/shared/ds/money-input";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseOtherSourcesOfIncomeResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { ApplicationEmploymentStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import { Color } from "@ender/shared/utils/theming";

import type { ApplicantEmploymentFormOutput } from "./employment-fields/edit-applicant-employment-fields";
import type { ApplicationApplicantEmploymentCardProps } from "./widgets-application-applicant-employment-card";
import { ApplicationApplicantEmploymentCard } from "./widgets-application-applicant-employment-card";

type ConnectedApplicationApplicantEmploymentCardProps = Pick<
  ApplicationApplicantEmploymentCardProps,
  | "application"
  | "isEditable"
  | "header"
  | "onUploadClick"
  | "onEditFileClick"
  | "onAddIncomeClick"
> & {
  onSuccess?: () => void;
  applicationGroupId: EnderId;
  applicantUserId: EnderId;
};

const ConnectedApplicationApplicantEmploymentCard = forwardRef<
  ElementRef<typeof ApplicationApplicantEmploymentCard>,
  ConnectedApplicationApplicantEmploymentCardProps
>(function ConnectedApplicationApplicantEmploymentCard(props, ref) {
  const {
    application,
    isEditable,
    header,
    onSuccess = F.constVoid,
    applicationGroupId,
    applicantUserId,
    onUploadClick,
    onEditFileClick = F.constVoid,
    onAddIncomeClick,
  } = props;

  const { hasPermissions } = useContext(UserContext);
  const canDeleteDocuments = hasPermissions(
    FunctionalPermissionEnum.DELETE_DOCUMENTS,
  );
  const confirmation = useConfirmationContext();

  const [validatedThreeMonthIncome, setValidatedThreeMonthIncome] = useState<
    O.Option<Money$.Money>
  >(Money$.parse(application.validatedThreeMonthIncome));
  /**
   * update the validatedThreeMonthIncome value if it changes from props
   */
  useEffect(() => {
    setValidatedThreeMonthIncome(
      Money$.parse(application.validatedThreeMonthIncome),
    );
  }, [application.validatedThreeMonthIncome]);

  const { mutateAsync: updateApplication, isLoading: isSavingApplication } =
    useMutation({
      mutationFn: ApplicationsAPI.updateApplication,
      mutationKey: ["ApplicationsAPI.updateApplication"] as const,
    });
  const { mutateAsync: deleteFile } = useMutation({
    mutationFn: WebserverFilesAPI.deleteFile,
    mutationKey: ["WebserverFilesAPI.deleteFile"] as const,
  });

  const handleSubmit = useCallback(
    async ({
      employer,
      employmentStatus,
      annualSalary,
      managerName,
      managerPhone,
      housingChoiceVoucher,
      housingChoiceVoucherCaseWorkerName,
      housingChoiceVoucherCaseWorkerEmail,
    }: ApplicantEmploymentFormOutput) => {
      const employmentStatusValue = employmentStatus.pipe(O.getOrUndefined);
      const isEmployed =
        employmentStatusValue === ApplicationEmploymentStatusEnum.EMPLOYED ||
        employmentStatusValue === ApplicationEmploymentStatusEnum.SELF_EMPLOYED;

      const payload = {
        annualSalary: isEmployed
          ? annualSalary.pipe(
              O.map((val) => val.toJSON()),
              O.getOrElse(() => Money$.zero().toJSON()),
            )
          : Money$.zero().toJSON(),
        applicationGroupId,
        employer: isEmployed ? employer : "",
        employmentStatus: employmentStatus.pipe(O.getOrUndefined),
        housingChoiceVoucher,
        managerName: isEmployed ? managerName : "",
        managerPhone: isEmployed ? managerPhone : "",
        housingChoiceVoucherCaseWorkerName,
        housingChoiceVoucherCaseWorkerEmail,
        targetUserId: applicantUserId,
      };

      await updateApplication(payload);
      onSuccess();
    },
    [updateApplication, onSuccess, applicationGroupId, applicantUserId],
  );

  const { mutateAsync: deleteOtherIncome } = useMutation({
    mutationFn: ApplicationsAPI.deleteOtherIncome,
    mutationKey: ["ApplicationsAPI.deleteOtherIncome"] as const,
  });
  const handleDeleteOtherIncome = useCallback(
    async ({
      yearlyEstimate,
      description,
    }: GetApplicationGroupResponseOtherSourcesOfIncomeResponse) => {
      await confirmation(
        {
          cancelButtonLabel: "Cancel",
          confirmButtonLabel: "Yes, Delete Additional Income Source",
          content: "This action cannot be undone.",
          title: "Are you sure you want to delete additional income source?",
        },
        {
          cancelButtonProps: { variant: ButtonVariant.transparent },
          confirmButtonProps: { color: Color.red },
        },
      );
      await deleteOtherIncome({
        applicationGroupId,
        applicationId: application.applicationId,
        json: { description, yearlyEstimate },
      });
      onSuccess();
    },
    [
      deleteOtherIncome,
      application.applicationId,
      applicationGroupId,
      confirmation,
      onSuccess,
    ],
  );

  const handleDeletePaystub = useCallback(
    async (file: FilesClientEnderFile) => {
      await confirmation({
        confirmButtonLabel: "Yes, delete",
        content: file.name,
        title: "Are you sure you want to delete this paystub?",
      });
      await deleteFile({
        fileId: file.id,
        modelId: application.applicationId,
        modelType: ModelTypeEnum.APPLICATION,
      });
      onSuccess();
    },
    [deleteFile, application.applicationId, confirmation, onSuccess],
  );

  const autoSaveThreeMonthIncome = useDebounce(
    (value: O.Option<Money$.Money>) =>
      updateApplication({
        applicationGroupId,
        targetUserId: applicantUserId,
        validatedThreeMonthIncome: value.pipe(
          O.map((val) => val.toJSON()),
          O.getOrElse(() => Money$.zero().toJSON()),
        ),
      }).then(() => onSuccess()),
    500,
  );

  const handleChangeThreeMonthIncome = useCallback(
    (value: O.Option<Money$.Money>) => {
      autoSaveThreeMonthIncome(value);
      setValidatedThreeMonthIncome(value);
    },
    [autoSaveThreeMonthIncome],
  );

  return (
    <ApplicationApplicantEmploymentCard
      onSubmit={handleSubmit}
      isWorking={isSavingApplication}
      application={application}
      isEditable={isEditable}
      header={header}
      ref={ref}
      canDeleteDocuments={canDeleteDocuments}
      onUploadClick={onUploadClick}
      onDeletePaystub={handleDeletePaystub}
      onEditFileClick={onEditFileClick}
      onAddIncomeClick={onAddIncomeClick}
      onDeleteIncomeClick={handleDeleteOtherIncome}>
      <MoneyInput
        label="Validate 3 Month Income"
        value={validatedThreeMonthIncome}
        onChange={handleChangeThreeMonthIncome}
      />
    </ApplicationApplicantEmploymentCard>
  );
});

export { ConnectedApplicationApplicantEmploymentCard };

export type { ConnectedApplicationApplicantEmploymentCardProps };
