import { Predicate as P } from "effect";
import { useState } from "react";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

type BathsFilterProps = FilterComponentProps & {
  metadata: {
    baths: number[];
  };
};

function getBathsFilter(
  value: string | Null,
  factor?: WidgetFactor,
): WidgetFilter[] | Undefined {
  if (P.isNullable(value) || P.isNull(value) || !factor) {
    return undefined;
  }

  return [
    {
      factor,
      operator: DynamicTableOperatorsEnum.IN,
      values: [value.toString()],
    },
  ];
}

function getDefaultValue(widget: Widget): string | null {
  const defaultValue = widget.filters.find(
    ({ factor }) => factor.name === Factor.BATHS,
  )?.values?.[0];
  return P.isNotNullable(defaultValue) ? defaultValue.toString() : null;
}

function BathsFilter({
  factor,
  metadata,
  updateFilters,
  widget,
}: BathsFilterProps) {
  const [selected, setSelected] = useState<string | null>(
    getDefaultValue(widget),
  );

  function handleChange(value: string | null) {
    setSelected(value);

    const filters = getBathsFilter(value, factor);
    updateFilters(Factor.BATHS, filters);
  }

  return (
    <Select
      label="Baths"
      data={metadata.baths.map((value) => `${value}`) || []}
      clearable
      searchable
      placeholder="Select Baths"
      value={selected}
      onChange={handleChange}
    />
  );
}

export { BathsFilter };
