import { z } from "zod";

import type { EnderId } from "@ender/shared/core";
import type { SearchPropertiesResponseSearchPropertiesResult } from "@ender/shared/generated/ender.api.core.response";
import type { Property } from "@ender/shared/generated/ender.model.core.property";
import { castEnum } from "@ender/shared/utils/zod";

type Author = {
  id: EnderId;
  name: string;
};

type JournalEntriesReport = {
  author: Author;
  description: string;
  hasAttachments: boolean;
  id: EnderId;
  ledgerDate: string;
  periodDate?: string;
  propertiesMap: Record<
    EnderId,
    SearchPropertiesResponseSearchPropertiesResult
  >;
  property?: Property;
  reversedById?: EnderId;
  reversesId?: EnderId;
  timestamp: string;
};

const JournalEntryTypeValues = [
  "JOURNAL_ENTRY",
  "RECURRING_JOURNAL_ENTRY",
] as const;
const JournalEntryTypeSchema = z.enum(JournalEntryTypeValues);
type JournalEntryType = z.infer<typeof JournalEntryTypeSchema>;
const JournalEntryTypeEnum = castEnum<JournalEntryType>(JournalEntryTypeSchema);

export { JournalEntryTypeEnum, JournalEntryTypeSchema };
export type { Author, JournalEntriesReport, JournalEntryType };
