import { Array as A, Predicate as P } from "effect";
import type { TupleOf } from "effect/Types";

const isSingle = <T extends unknown>(
  val: T,
  //@ts-expect-error T could be instantiated with a different subtype of constraint unknown
): val is TupleOf<1, A.ReadonlyArray.Infer<T>> =>
  P.compose(P.isTupleOf(1))(A.isArray)(val);

export { isSingle };
