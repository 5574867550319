// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { useComponentDefaultProps } from "@mantine/core";
import { Function as F } from "effect";
import { useEffect, useMemo, useRef } from "react";

import type { EnderDrawerProps } from "@ender/shared/ui/ender-drawer";
import { EnderDrawer } from "@ender/shared/ui/ender-drawer";
import { ScreenRangeEnum, useScreenRange } from "@ender/shared/ui/screen-size";

import { useRightRailContext } from "./right-rail-context";
import type { RightRailHeaderProps } from "./right-rail-header";
import { RightRailHeader } from "./right-rail-header";

/**
 * We shouldn't send a closed signal until the component is mounted.
 * We should send an opened signal once either when opened or mounted in an open state.
 *
 * @todo: This is a temporary solution to fix right-rail-context counting.
 * We'd prefer a better solution, for example:
 * Add a new prop for a uuid (title?) for any given right rail,
 * but for now we need to add mounted logic to fix right-rail-context counting.
 * - Geoff & Kyle, 2024-03-20
 * 2024-08-16: better solution is to use query params
 */

type UseSetOpenedParams = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
};

function useSetOpened({ opened, setOpened }: UseSetOpenedParams) {
  const mounted = useRef(false);

  // lazy useEffect
  useEffect(() => {
    if (mounted.current || (!mounted.current && opened)) {
      setOpened(opened);
    }

    mounted.current = true;

    // close right rail when component unmounts
    return () => setOpened(false);
  }, [mounted, opened, setOpened]);
}

const RightRailSize = {
  LG: 816,
  MD: 608,
  SM: 400,
} as const;

type RightRailProps = EnderDrawerProps &
  RightRailHeaderProps & { headerPaddingBottom?: number | string };

function RightRail(props: RightRailProps) {
  const setOpened = useRightRailContext();
  useSetOpened({ opened: props.opened, setOpened });

  const { headerPaddingBottom, ..._props } = props;

  const screenRange = useScreenRange();

  const styles = useMemo(() => {
    const drawerMargin = screenRange === ScreenRangeEnum.LARGE ? 72 : 48;
    return {
      body: {
        display: "grid",
        flexGrow: 1,
        overflow: "auto",
      },
      closeButton: {
        "& > svg": {
          height: 20,
          width: 20,
        },
        color: "var(--color-black)",
        position: "absolute",
        right: 0,
        top: 12,
      },
      drawer: {
        boxShadow: "-3px 0 4px rgba(0, 0, 0, 0.06)",
        display: "flex",
        flexDirection: "column",
        marginTop: drawerMargin,
        overflowY: "auto",
        paddingBottom: "24px !important",
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
      },
      header: {
        backgroundColor: "var(--color-white)",
        marginBottom: "0",
        paddingBottom: headerPaddingBottom ?? 24,
        paddingTop: 24,
        position: "sticky",
        top: 0,
        zIndex: 299,
      },
      title: {
        marginRight: 0,
        width: "100%",
      },
    };
  }, [headerPaddingBottom, screenRange]);

  const defaultProps = useMemo(
    () => ({
      onClose: F.constVoid,
      overlayOpacity: 0.28,
      position: "right",
      size: RightRailSize.MD,
      styles,
      trapFocus: true,
      withCloseButton: true,
      withOverlay: false,
    }),
    [styles],
  );

  const {
    actions,
    eyebrow,
    subtitle,
    title = "Title Needed",
    ...drawerProps
  } = useComponentDefaultProps<RightRailProps>(
    "RightRail",
    defaultProps,
    _props,
  );

  return (
    <EnderDrawer
      {...drawerProps}
      title={
        <RightRailHeader
          eyebrow={eyebrow}
          title={title}
          subtitle={subtitle}
          actions={actions}
        />
      }
    />
  );
}

export { RightRail, RightRailSize };
export type { RightRailProps };
