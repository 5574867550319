/**
 * HTTP Method: GET
 * Pathname: /collections/summary
 * @function getCollectionsSummary
 * @memberof CollectionsAPI
 * @param {CollectionsAPIGetCollectionsSummaryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsSummaryResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money } from "@ender/shared/core";
import type { CollectionsSummaryResponse } from "@ender/shared/generated/ender.api.finance.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIGetCollectionsSummarySearchParams = {
  token?: string | undefined;
  firmIds: EnderId[];
  fundIds: EnderId[];
  marketIds: EnderId[];
  minimumAmountOwed?: Money | undefined;
  propertyIds: EnderId[];
};

type CollectionsAPIGetCollectionsSummaryPayload =
  CollectionsAPIGetCollectionsSummarySearchParams;

function getCollectionsSummaryUnsafeEffect(
  payload: CollectionsAPIGetCollectionsSummaryPayload,
) {
  const {
    token,
    firmIds,
    fundIds,
    marketIds,
    minimumAmountOwed,
    propertyIds,
    ...body
  } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsSummaryResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsSummaryResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/collections/summary",
        searchParams: [
          ["token", token],
          ["firmIds", firmIds],
          ["fundIds", fundIds],
          ["marketIds", marketIds],
          ["minimumAmountOwed", minimumAmountOwed],
          ["propertyIds", propertyIds],
        ],
      }),
    ),
  );
}

function getCollectionsSummary(
  payload: CollectionsAPIGetCollectionsSummaryPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsSummaryResponse> {
  return F.pipe(
    payload,
    getCollectionsSummaryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCollectionsSummary };
export type {
  CollectionsAPIGetCollectionsSummaryPayload,
  CollectionsAPIGetCollectionsSummarySearchParams,
};
