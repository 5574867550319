import { Predicate as P } from "effect";
import * as A from "effect/Array";
import { useMemo } from "react";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { isMultiple } from "@ender/shared/utils/is";
import { makeUseQuery } from "@ender/shared/utils/rest";

type TenantCellProps = {
  leaseId: EnderId;
};

/**
 * TODO this should be a shared component
 */
function LeaseTenants({ leaseId }: TenantCellProps) {
  const { data: contacts } = makeUseQuery(
    "getLeaseContacts",
    LeasingAPI.getLeaseContacts,
  )({ leaseId });

  const tenantDisplayName = useMemo(() => {
    if (P.isNotNullable(contacts) && A.isNonEmptyArray(contacts)) {
      const primary =
        contacts.find((contact) =>
          contact.roles.includes(
            LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
          ),
        ) || contacts[0];
      const postFix = isMultiple(contacts) ? ` + ${contacts.length - 1}` : "";
      return `${primary.name}${postFix}`;
    }
  }, [contacts]);

  if (P.isNullable(tenantDisplayName)) {
    return NULL;
  }

  return (
    <EnderLink target="_blank" to={`/leases/${leaseId}`}>
      {tenantDisplayName}
    </EnderLink>
  );
}

export { LeaseTenants };
