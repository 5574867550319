import { Array as A } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  ChatFileAttachment,
  ChatInfo,
  ClientPayload,
  Medium,
  ServerCommand,
  ServerPayload,
  SocketCommand,
  SocketStatus,
} from "@ender/shared/types/ender-general";
import {
  ClientCommandEnum,
  SocketStatusEnum,
} from "@ender/shared/types/ender-general";
import { fail } from "@ender/shared/utils/error";
import { delay, getDeferred } from "@ender/shared/utils/promise";

/**
 * The goal of this file is to construct a WebSocket. The eventual intent is to replace this entire file with a streamlined
 * third party library such as `uWebSocket`, which is why the event handlers have been constructed in this way.
 */

type ChatSocketCommandHandlers = {
  [T in ServerCommand]?: (data: Extract<ServerPayload, { command: T }>) => void;
};

class ChatSocket {
  handlers: ChatSocketCommandHandlers = {};

  protected _autoReconnect = true;
  protected _connectionDeferred = getDeferred<void>();
  protected _hasError = false;
  protected _isReadOnly = false;
  protected _socket?: WebSocket;
  protected _url = "";
  protected _waitTime = 1;

  constructor() {
    // Bind class methods so we don't need to worry about "TypeError: this is undefined".
    Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach(
      (propertyKey) => {
        const propertyValue = this[propertyKey as keyof typeof this];
        if (
          propertyKey !== "constructor" &&
          typeof propertyValue === "function"
        ) {
          Object.defineProperty(this, propertyKey, {
            value: propertyValue.bind(this),
          });
        }
      },
    );
  }

  connect(url: string) {
    // Ensure existing socket is closed and event listeners are removed
    this._closeSocket();

    this._url = url ?? this._url;
    this._autoReconnect = true;
    this._hasError = false;
    this._connectionDeferred = Promise.withResolvers<void>();

    this._socket = new WebSocket(this._url);
    this._socket.addEventListener("open", this.onSocketOpen);
    this._socket.addEventListener("close", this.onSocketClose);
    this._socket.addEventListener("message", this.onSocketMessage);
    this._socket.addEventListener("error", this.onSocketError);

    return this;
  }

  disconnect() {
    this._autoReconnect = false;
    this._closeSocket();
    return this;
  }

  on<T extends ServerCommand>(
    command: T,
    handler: (data: Extract<ServerPayload, SocketCommand<T>>) => void,
  ) {
    /**
     * A similar limitation of TS- we can force the association of a command + handler, but then
     * TS also believes that T is wider than it is when invoking that handler. From outside of this file, the syntax of
     * socket.on("chat", handleChat) is enforced
     */
    // @ts-expect-error per above comment
    this.handlers[command] = handler;
  }

  get url(): string {
    return this._url;
  }

  get status(): SocketStatus {
    if (this._socket == null) {
      return SocketStatusEnum.NO_CONNECTION;
    }

    if (this._hasError) {
      return SocketStatusEnum.ERROR;
    }

    switch (this._socket.readyState) {
      case WebSocket.CONNECTING:
        return SocketStatusEnum.CONNECTING;
      case WebSocket.OPEN: // OPEN
        return SocketStatusEnum.CONNECTED;
      case WebSocket.CLOSING: // CLOSING
        return SocketStatusEnum.CLOSING;
      case WebSocket.CLOSED: // CLOSED
        return SocketStatusEnum.DISCONNECTED;
    }
    return SocketStatusEnum.UNKNOWN;
  }

  get isReadOnly() {
    return this._isReadOnly;
  }

  get isConnected() {
    return this.status === SocketStatusEnum.CONNECTED;
  }

  setSocketChannel(chatInfo: ChatInfo) {
    const { channel, isSendingAllowed, socketUrl } = chatInfo;

    let firstConnect = false;
    if (!this.isConnected || this._url !== socketUrl) {
      firstConnect = true;
      // Open a new socket when the url changes
      this.connect(socketUrl);
    }

    this._isReadOnly = !isSendingAllowed;
    this._send({
      channel: channel,
      command: ClientCommandEnum.SET_CHANNEL,
      firstConnect,
    });
  }

  sendChatMessage(
    payload: { message: string; acknowledgmentId?: EnderId },
    attachments?: ChatFileAttachment[],
    notificationMedium?: Medium,
  ) {
    const { message, acknowledgmentId } = payload;
    this._send({
      acknowledgmentId,
      attachments,
      command: ClientCommandEnum.SEND_CHAT,
      medium: notificationMedium,
      text: message,
    });
  }

  markSeen() {
    this._send({
      command: ClientCommandEnum.MARK_SEEN,
    });
  }

  refreshTimeline() {
    this._send({
      command: ClientCommandEnum.REFRESH_TIMELINE,
    });
  }

  log(source: string, message: string) {
    this._send({
      command: ClientCommandEnum.LOG,
      payload: {
        data: JSON.stringify(message),
        logSource: source,
      },
    });
  }

  protected async _send(command: ClientPayload) {
    const message = JSON.stringify(command);
    try {
      await this._connectionDeferred.promise;
      this._socket?.send(message);
    } catch (err) {
      fail(err);
    }

    return this;
  }

  protected async _reconnect() {
    console.info(`Attempting to reconnect in ${this._waitTime} seconds.`);
    await delay(this._waitTime * 1000);
    this.connect(this._url);
  }

  /**
   * The open event is fired when a connection with a WebSocket is opened.
   */
  protected onSocketOpen() {
    this._waitTime = 1;
    this._hasError = false;
    this._connectionDeferred.resolve();
  }

  /**
   * The close event is fired when a connection with a WebSocket is closed.
   */
  protected onSocketClose() {
    this._hasError = false;

    if (this._autoReconnect) {
      this._reconnect();
      this._waitTime *= 2;
    }
  }

  /**
   * The message event is fired when data is received through a WebSocket.
   */
  protected onSocketMessage(msg: MessageEvent<string>) {
    this._hasError = false;

    let messageList: ServerPayload[];
    const parsed: ServerPayload | ServerPayload[] = JSON.parse(msg.data);
    if (A.isArray(parsed)) {
      messageList = parsed;
    } else {
      messageList = [parsed];
    }

    messageList.forEach((message) => {
      /**
       * this acts as a discriminator. We can trust that if `this.handlers` has a key for `message.command` then the
       * same handler is able to accept the message as a parameter. The error is a known limitation of TypeScript.
       */
      const handler = this.handlers[message.command];
      if (handler) {
        // @ts-expect-error per above comment
        handler(message);
      } else {
        fail(`Unhandled chat message: ${JSON.stringify(message)}`);
      }
    });
  }

  /**
   * The error event is fired when a connection with a WebSocket has been closed due to an error. eg. Some data couldn't be sent
   */
  protected onSocketError() {
    this._hasError = true;
    this._connectionDeferred.reject();

    if (this._autoReconnect) {
      this._reconnect();
      this._waitTime *= 2;
    }
  }

  protected _closeSocket() {
    if (this._socket == null) {
      return;
    }

    this._socket.removeEventListener("open", this.onSocketOpen);
    this._socket.removeEventListener("close", this.onSocketClose);
    this._socket.removeEventListener("message", this.onSocketMessage);
    this._socket.removeEventListener("error", this.onSocketError);
    this._socket.close();
    this._connectionDeferred.reject();
  }
}

export { ChatSocket };
export type { ChatFileAttachment };
