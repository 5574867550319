import { IconFileDollar } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Array as A, Predicate as P, pipe } from "effect";
import { useCallback, useMemo } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { Money$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Switch } from "@ender/shared/ds/switch";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { noOpAsync } from "@ender/shared/utils/no-op";
import { showLoadingNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";

import { SecurityDepositCard } from "./security-deposit-card";

type TenantSecurityDepositsProps = {
  lease: LeaseSerializerLeaseResponse;
  /**
   * invoked when a successful update mutation has occurred
   */
  onSuccess?: () => Promise<unknown>;
};

function TenantSecurityDeposits(props: TenantSecurityDepositsProps) {
  const { lease, onSuccess = noOpAsync } = props;

  const leaseId = lease?.id;
  const securityDeposits = useMemo(() => {
    return lease.deposits.filter(({ amount }) =>
      pipe(amount, Money$.of, Money$.isPositive),
    );
  }, [lease]);

  const confirmation = useConfirmationContext();
  const { mutateAsync: updateLease } = useMutation({
    mutationFn: LeasingAPI.updateLease,
    mutationKey: ["LeasingAPI.updateLease"] as const,
  });

  const handleDepositsChange = useCallback(
    async (value: boolean) => {
      if (P.isNullable(leaseId)) {
        return;
      }

      await confirmation(
        {
          confirmButtonLabel: value
            ? "Deposits Held Externally"
            : "Deposits Not Held Externally",
          content: (
            <Text>
              {value
                ? "Are you sure you would like to mark that this lease has deposits held externally?"
                : "Are you sure you would like to mark that this lease does not have deposits held externally?"}
            </Text>
          ),
          title: `${value ? "Deposits Held Externally" : "Deposits Not Held Externally"}`,
        },
        { confirmButtonProps: { color: Color.red } },
      );
      const [, clearLoadingNotification] = showLoadingNotification({
        message: `Updating Lease`,
      });
      try {
        await updateLease({ leaseId, depositsHeldExternally: value });
        await onSuccess();
      } finally {
        clearLoadingNotification();
      }
    },
    [confirmation, leaseId, updateLease, onSuccess],
  );

  return (
    <Stack>
      <Group spacing={Spacing.sm}>
        <IconFileDollar size={24} color="var(--color-slate-600)" />
        <H2>Security Deposits</H2>
      </Group>
      {(A.isEmptyArray(securityDeposits) || lease.depositsHeldExternally) && (
        <Switch
          label="Deposits Held Externally"
          value={lease.depositsHeldExternally}
          onChange={handleDepositsChange}
        />
      )}
      {A.isEmptyArray(securityDeposits) && !lease.depositsHeldExternally && (
        <Text size={FontSize.md} weight={FontWeight.medium}>
          There are no security deposits for this lease
        </Text>
      )}
      {A.isNonEmptyArray(securityDeposits) && (
        <Grid>
          {securityDeposits.map((securityDeposit, index) => (
            <SecurityDepositCard
              key={index}
              securityDeposit={securityDeposit}
            />
          ))}
        </Grid>
      )}
    </Stack>
  );
}

export { TenantSecurityDeposits };
