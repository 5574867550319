// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Center } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { Array as A, Predicate as P } from "effect";
import type { ReactNode } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type {
  GetApprovalProcessResponse,
  GetApprovalProcessResponseStep,
} from "@ender/shared/generated/ender.api.misc.response";
import type {
  ApprovalProcessType,
  ApprovalStepApprovalPhase,
} from "@ender/shared/generated/ender.model.approvals";

import type { ApprovalProcessHybridId } from "../types";
import { AddStepButton } from "./add-step-button";
import { Step } from "./step";

type ApprovalProcessRuleStepsProps = {
  addButtonLabel?: string;
  approvalProcess: GetApprovalProcessResponse & { id: ApprovalProcessHybridId };
  approvalProcessPhase?: ApprovalStepApprovalPhase;
  areStepsOptional?: boolean;
  disableAddStepButton?: boolean;
  missingEditPermissions?: ReactNode;
  onStepActionSuccess: () => Promise<void>;
  title?: ReactNode | string;
  titleTooltip?: string;
  type: ApprovalProcessType;
};

function ApprovalProcessRuleSteps({
  addButtonLabel,
  approvalProcess,
  approvalProcessPhase,
  areStepsOptional = false,
  disableAddStepButton = false,
  missingEditPermissions,
  onStepActionSuccess,
  title,
  titleTooltip,
  type,
}: ApprovalProcessRuleStepsProps) {
  const steps =
    approvalProcess.steps?.filter(
      ({ phase }) => phase === approvalProcessPhase,
    ) || [];

  return (
    <Stack>
      {P.isNotNullable(title) && (
        <H4>
          <Group spacing={Spacing.xs}>
            {title}
            {P.isNotNullable(titleTooltip) && (
              <Tooltip label={titleTooltip}>
                <IconInfoCircle color={EnderThemeColorEnum.PRIMARY} size={16} />
              </Tooltip>
            )}
          </Group>
        </H4>
      )}
      {A.isEmptyArray(steps) && (
        <Group align={Align.center} grow>
          <Text color="slate-500" size={FontSize.md}>
            No Approval Steps Added
          </Text>
        </Group>
      )}
      {steps.map((step: GetApprovalProcessResponseStep, idx: number) => (
        <Step
          key={step.id}
          step={step}
          stepNumber={idx + 1}
          refetchData={onStepActionSuccess}
          canDelete={areStepsOptional || steps.length > 1}
          missingEditPermissions={missingEditPermissions}
        />
      ))}
      <Group grow>
        <Center>
          <AddStepButton
            label={addButtonLabel}
            approvalProcessHybridId={approvalProcess.id}
            approvalProcessPhase={approvalProcessPhase}
            type={type}
            onSuccess={onStepActionSuccess}
            missingEditPermissions={missingEditPermissions}
            disableAddStepButton={disableAddStepButton && steps.length >= 1}
          />
        </Center>
      </Group>
    </Stack>
  );
}

export { ApprovalProcessRuleSteps };
