import { Option as O } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApplicationGroupApplicationStatus } from "@ender/shared/generated/ender.model.leasing";
import type { LabelValue } from "@ender/shared/types/label-value";

type ApplicationsFilterState = {
  applicantName: string;
  markets: LabelValue<EnderId>[];
  onlyNeedsFollowUp: boolean;
  ownershipGroups: LabelValue<EnderId>[];
  properties: LabelValue<EnderId>[];
  statuses: LabelValue<ApplicationGroupApplicationStatus>[];
  usingHcv: O.Option<boolean>;
};

type ApplicationsFilterActions = {
  reset: () => void;
  setApplicantName: (applicantName: string) => void;
  setMarkets: (markets: LabelValue<EnderId>[]) => void;
  setOnlyNeedsFollowUp: (onlyNeedsFollowUp: boolean) => void;
  setOwnershipGroups: (ownershipGroups: LabelValue<EnderId>[]) => void;
  setProperties: (properties: LabelValue<EnderId>[]) => void;
  setStatuses: (
    statuses: LabelValue<ApplicationGroupApplicationStatus>[],
  ) => void;
  setUsingHcv: (usingHcv: O.Option<boolean>) => void;
};

const defaultApplicationsFilters: ApplicationsFilterState = {
  applicantName: "",
  markets: [],
  onlyNeedsFollowUp: false,
  ownershipGroups: [],
  properties: [],
  statuses: [],
  usingHcv: O.none(),
};

export { defaultApplicationsFilters };
export type { ApplicationsFilterActions, ApplicationsFilterState };
