import { useContext, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";

import { useHasOngoingInspectionQuery } from "./use-has-ongoing-inspection-query";

function useCanEditAreasAndZones(unitId: EnderId) {
  const { path } = useRouteMatch();
  const isUnitPage = path === "/units/:unitId";

  const { hasPermissions } = useContext(UserContext);
  const hasEditAreaPermission = hasPermissions(
    FunctionalPermissionEnum.EDIT_UNIT_AREAS_AND_ZONES,
  );
  const hasOngoingInspection = useHasOngoingInspectionQuery(unitId);

  const disabled =
    !hasEditAreaPermission || (hasOngoingInspection && isUnitPage);

  const disabledTooltip = useMemo(() => {
    if (!hasEditAreaPermission) {
      return "You do not have the permission to edit areas and zones.";
    }
    if (hasOngoingInspection && isUnitPage) {
      return "The property is currently under inspection. Changes to areas and zones are not allowed until inspection is complete.";
    }
    return UNDEFINED;
  }, [hasEditAreaPermission, hasOngoingInspection, isUnitPage]);

  return { disabled, disabledTooltip };
}

export { useCanEditAreasAndZones };
