/**
 * HTTP Method: POST
 * Pathname: /moneyTransfers/invoices
 * @function getInvoicesForMoneyTransfers
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetInvoicesForMoneyTransfersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetInvoicesForMoneyTransfersSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetInvoicesForMoneyTransfersBodyParams = {
  moneyTransferIds: EnderId[];
};

type PaymentsAPIGetInvoicesForMoneyTransfersPayload =
  PaymentsAPIGetInvoicesForMoneyTransfersSearchParams &
    PaymentsAPIGetInvoicesForMoneyTransfersBodyParams;

function getInvoicesForMoneyTransfersUnsafeEffect(
  payload: PaymentsAPIGetInvoicesForMoneyTransfersPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice[]>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/moneyTransfers/invoices",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoicesForMoneyTransfers(
  payload: PaymentsAPIGetInvoicesForMoneyTransfersPayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice[]> {
  return F.pipe(
    payload,
    getInvoicesForMoneyTransfersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoicesForMoneyTransfers };
export type {
  PaymentsAPIGetInvoicesForMoneyTransfersBodyParams,
  PaymentsAPIGetInvoicesForMoneyTransfersPayload,
  PaymentsAPIGetInvoicesForMoneyTransfersSearchParams,
};
