import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ReconTypeFilterExcludedTransactionTypeValues = [
  "EXCLUDED_BANK_TRANSACTION",
  "EXCLUDED_ENDER_TRANSACTION",
] as const;

const ReconTypeFilterExcludedTransactionTypeEffectSchema = Schema.Literal(
  ...ReconTypeFilterExcludedTransactionTypeValues,
);

type ReconTypeFilterExcludedTransactionType = Schema.Schema.Type<
  typeof ReconTypeFilterExcludedTransactionTypeEffectSchema
>;

const ReconTypeFilterExcludedTransactionTypeEnum = castEnum(
  ReconTypeFilterExcludedTransactionTypeEffectSchema,
);

function randomReconTypeFilterExcludedTransactionType(): ReconTypeFilterExcludedTransactionType {
  return rand(ReconTypeFilterExcludedTransactionTypeValues);
}

export {
  randomReconTypeFilterExcludedTransactionType,
  ReconTypeFilterExcludedTransactionTypeEffectSchema,
  ReconTypeFilterExcludedTransactionTypeEnum,
  ReconTypeFilterExcludedTransactionTypeValues,
};
export type { ReconTypeFilterExcludedTransactionType };
