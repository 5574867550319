import { Option as O, pipe } from "effect";
import type { ElementRef, FocusEvent } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { LocalTime$ } from "@ender/shared/core";

import type { InputBaseProps } from "../../../input/src";
import { Input } from "../../../input/src";

type TimeInputProps = {
  value: O.Option<LocalTime$>;
  onChange: (value: O.Option<LocalTime$>) => void;
} & Omit<
  InputBaseProps,
  "leftSection" | "textAlign" | "role" | "inputType" | "inputMode"
>;

const TimeInput = forwardRef<ElementRef<typeof Input>, TimeInputProps>(
  function TimeInput(props, ref) {
    const {
      value,
      onChange,
      label,
      error,
      description,
      borderless,
      size,
      rightSection,
      disabled,
      "data-private": _dataPrivate,
      name,
      placeholder,
    } = props;
    /**
     * whether the user is currently typing in the field or not.
     * If this is true, we want to prevent incoming value changes
     * from replacing the user's typed input
     */
    const isEditing = useRef(false);

    const [display, setDisplay] = useState(() =>
      pipe(
        value,
        O.map((val) => val.toJSON()),
        O.getOrElse(() => ""),
      ),
    );

    /**
     * react to value changes from the parent state management
     */
    useEffect(() => {
      if (!isEditing.current) {
        setDisplay(
          pipe(
            value,
            O.map((val) => val.toJSON()),
            O.getOrElse(() => ""),
          ),
        );
      }
    }, [value]);

    const handleInput = useCallback(
      (val: string) => {
        try {
          setDisplay(val);
          onChange(LocalTime$.parse(val));
        } catch {
          onChange(O.none());
        }
      },
      [onChange],
    );

    //the only purpose of this is to reformat the displayValue when the user clicks away. The value is unchanged.
    const handleBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        isEditing.current = false;
        const val = pipe(
          value,
          O.map((val) => val.toJSON()),
          O.getOrElse(() => ""),
        );
        setDisplay(val);
        // necessary because for some reason setting the display value doesn't work for time inputs
        e.currentTarget.value = val;
      },
      [value],
    );

    function handleFocus(_e: FocusEvent<HTMLInputElement>) {
      isEditing.current = true;
    }

    return (
      <Input
        // causes mobile keyboards to display time-specific keyboards for easier input
        inputType="time"
        value={display}
        onChange={handleInput}
        placeholder={placeholder}
        ref={ref}
        label={label}
        error={error}
        description={description}
        borderless={borderless}
        size={size}
        rightSection={rightSection}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-private={_dataPrivate}
        name={name}
      />
    );
  },
);

export { TimeInput };

export type { TimeInputProps };
