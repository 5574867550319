import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { AccountingPeriodSelectorProps } from "./accounting-period-selector";
import { AccountingPeriodSelector } from "./accounting-period-selector";

type FormAccountingPeriodSelectorProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<AccountingPeriodSelectorProps, Form, Name>;

function FormAccountingPeriodSelector<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormAccountingPeriodSelectorProps<Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field }) => {
        return <AccountingPeriodSelector {...rest} {...field} />;
      }}
    </FormField>
  );
}

export { FormAccountingPeriodSelector };

export type { FormAccountingPeriodSelectorProps };
