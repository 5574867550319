import { Schema } from "@effect/schema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useCallback } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { UnitAreasAPI } from "@ender/shared/generated/ender.api.core";
import {
  UnitAreaUnitAreaTypeEffectSchema,
  UnitAreaUnitAreaTypeValues,
} from "@ender/shared/generated/ender.model.core.unit.area";
import { capitalize } from "@ender/shared/utils/string";

const AddUnitAreaFormSchema = Schema.Struct({
  description: Schema.String.pipe(Schema.trimmed()),
  type: UnitAreaUnitAreaTypeEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Area is required." }),
  ),
});

type AddUnitAreaFormValues = Schema.Schema.Type<typeof AddUnitAreaFormSchema>;

const areaOptions = UnitAreaUnitAreaTypeValues.map((value) => ({
  label: capitalize(value),
  value,
}));

type AddUnitAreaFormProps = {
  onClose: () => unknown;
  unitId: EnderId;
};

function AddUnitAreaForm({ onClose, unitId }: AddUnitAreaFormProps) {
  const queryClient = useQueryClient();
  const form = useEffectSchemaForm({
    defaultValues: {
      description: "",
      type: O.none(),
    },
    schema: AddUnitAreaFormSchema,
  });

  const { mutateAsync: createUnitArea, isLoading } = useMutation({
    mutationFn: UnitAreasAPI.createUnitArea,
    mutationKey: ["UnitAreasAPI.createUnitArea"],
  });

  const areaType = form.watch("type").pipe(O.getOrUndefined, capitalize);

  const onSubmit = useCallback(
    async (values: AddUnitAreaFormValues) => {
      await createUnitArea({
        description: values.description,
        type: O.getOrThrow(values.type),
        unitId,
      });
      queryClient.invalidateQueries(["UnitAreasAPI.getUnitAreas", { unitId }]);
      queryClient.invalidateQueries([
        "UnitAreasZonesMiddleLayerAPI.getUnitAreaSummary",
        { unitId },
      ]);
      onClose();
    },
    [createUnitArea, onClose, queryClient, unitId],
  );

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        <FormSelect form={form} label="Area" name="type" data={areaOptions} />
        <FormTextInput
          form={form}
          name="description"
          label="Area Name"
          placeholder="Optional Descriptor"
          leftSection={
            <Text
              size={FontSize.md}
              weight={FontWeight.medium}
              color="slate-500">
              {areaType}
            </Text>
          }
          disabled={O.isNone(form.watch("type"))}
        />
        <Group justify={Justify.end}>
          <Button variant={ButtonVariant.transparent} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={isLoading}>
            Add Area
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { AddUnitAreaForm };
