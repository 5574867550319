import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DwollaCustomerDwollaStatusValues = [
  "NEW",
  "RETRY",
  "DOCUMENT",
  "VERIFIED",
  "SUSPENDED",
] as const;

const DwollaCustomerDwollaStatusEffectSchema = Schema.Literal(
  ...DwollaCustomerDwollaStatusValues,
);

type DwollaCustomerDwollaStatus = Schema.Schema.Type<
  typeof DwollaCustomerDwollaStatusEffectSchema
>;

const DwollaCustomerDwollaStatusEnum = castEnum(
  DwollaCustomerDwollaStatusEffectSchema,
);

function randomDwollaCustomerDwollaStatus(): DwollaCustomerDwollaStatus {
  return rand(DwollaCustomerDwollaStatusValues);
}

export {
  DwollaCustomerDwollaStatusEffectSchema,
  DwollaCustomerDwollaStatusEnum,
  DwollaCustomerDwollaStatusValues,
  randomDwollaCustomerDwollaStatus,
};
export type { DwollaCustomerDwollaStatus };
