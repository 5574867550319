import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DealTerminationReasonValues = [
  "NON_COMPETITIVE_BID",
  "HOUSING_TYPE",
  "HOUSE_CONDITION",
  "DOUBLE_YELLOW_ROAD",
  "ADJACENT_TO_CRE",
  "RURAL",
  "NEIGHBORHOOD",
  "OTHER",
] as const;

const DealTerminationReasonEffectSchema = Schema.Literal(
  ...DealTerminationReasonValues,
);

type DealTerminationReason = Schema.Schema.Type<
  typeof DealTerminationReasonEffectSchema
>;

const DealTerminationReasonEnum = castEnum(DealTerminationReasonEffectSchema);

function randomDealTerminationReason(): DealTerminationReason {
  return rand(DealTerminationReasonValues);
}

export {
  DealTerminationReasonEffectSchema,
  DealTerminationReasonEnum,
  DealTerminationReasonValues,
  randomDealTerminationReason,
};
export type { DealTerminationReason };
