import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const VendorVendorStatusValues = [
  "ACTIVE",
  "PENDING_APPROVAL",
  "DEACTIVATED",
] as const;

const VendorVendorStatusEffectSchema = Schema.Literal(
  ...VendorVendorStatusValues,
);

type VendorVendorStatus = Schema.Schema.Type<
  typeof VendorVendorStatusEffectSchema
>;

const VendorVendorStatusEnum = castEnum(VendorVendorStatusEffectSchema);

function randomVendorVendorStatus(): VendorVendorStatus {
  return rand(VendorVendorStatusValues);
}

export {
  randomVendorVendorStatus,
  VendorVendorStatusEffectSchema,
  VendorVendorStatusEnum,
  VendorVendorStatusValues,
};
export type { VendorVendorStatus };
