import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DealMLSStatusValues = [
  "ACTIVE",
  "ACTIVE_UNDER_CONTRACT",
  "CANCELED",
  "CLOSED",
  "COMING_SOON",
  "EXPIRED",
  "HOLD",
  "INCOMPLETE",
  "OFF_MARKET",
  "PENDING",
  "WITHDRAWN",
] as const;

const DealMLSStatusEffectSchema = Schema.Literal(...DealMLSStatusValues);

type DealMLSStatus = Schema.Schema.Type<typeof DealMLSStatusEffectSchema>;

const DealMLSStatusEnum = castEnum(DealMLSStatusEffectSchema);

function randomDealMLSStatus(): DealMLSStatus {
  return rand(DealMLSStatusValues);
}

export {
  DealMLSStatusEffectSchema,
  DealMLSStatusEnum,
  DealMLSStatusValues,
  randomDealMLSStatus,
};
export type { DealMLSStatus };
