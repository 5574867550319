/**
 * HTTP Method: POST
 * Pathname: /renewalOffers/{offerId}/rescind
 * @function rescindOffer
 * @memberof RenewalsAPI
 * @param {RenewalsAPIRescindOfferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPIRescindOfferPathParams = {
  offerId: EnderId;
};

type RenewalsAPIRescindOfferSearchParams = {
  token?: string | undefined;
};

type RenewalsAPIRescindOfferPayload = RenewalsAPIRescindOfferPathParams &
  RenewalsAPIRescindOfferSearchParams;

function rescindOfferUnsafeEffect(payload: RenewalsAPIRescindOfferPayload) {
  const { offerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/renewalOffers/${offerId}/rescind`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function rescindOffer(
  payload: RenewalsAPIRescindOfferPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    rescindOfferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { rescindOffer };
export type {
  RenewalsAPIRescindOfferPathParams,
  RenewalsAPIRescindOfferPayload,
  RenewalsAPIRescindOfferSearchParams,
};
