/**
 * HTTP Method: POST
 * Pathname: /invoices/new
 * @function createInvoice
 * @memberof InvoicesAPI
 * @param {InvoicesAPICreateInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  CreateInvoiceRequestBankTransaction,
  CreateInvoiceRequestJob,
  CreateInvoiceRequestPayee,
} from "@ender/shared/generated/ender.api.accounting.request";
import type { GLAllocation } from "@ender/shared/generated/ender.arch.accounting";
import type {
  Invoice,
  InvoiceInvoiceType,
  InvoicePayableType,
} from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPICreateInvoiceSearchParams = {
  token?: string | undefined;
};

type InvoicesAPICreateInvoiceBodyParams = {
  allocations: GLAllocation[];
  amount?: SerializesInto<Money> | undefined;
  bankAccountId?: EnderId | undefined;
  bankTransaction?: CreateInvoiceRequestBankTransaction | undefined;
  description: string;
  externalInvoiceDate?: SerializesInto<LocalDate> | undefined;
  instantApprove?: boolean | undefined;
  invoiceNumber?: string | undefined;
  invoiceType: InvoiceInvoiceType;
  job?: CreateInvoiceRequestJob | undefined;
  ledgerDate: SerializesInto<LocalDate>;
  overrideWarnings?: boolean | undefined;
  pageIds: EnderId[];
  payableType?: InvoicePayableType | undefined;
  payee: CreateInvoiceRequestPayee;
  paymentDate?: SerializesInto<LocalDate> | undefined;
  periodId?: EnderId | undefined;
  poNumbers: number[];
  propertyId?: EnderId | undefined;
  unitId?: EnderId | undefined;
};

type InvoicesAPICreateInvoicePayload = InvoicesAPICreateInvoiceSearchParams &
  InvoicesAPICreateInvoiceBodyParams;

function createInvoiceUnsafeEffect(payload: InvoicesAPICreateInvoicePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/new",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createInvoice(
  payload: InvoicesAPICreateInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice> {
  return F.pipe(
    payload,
    createInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createInvoice };
export type {
  InvoicesAPICreateInvoiceBodyParams,
  InvoicesAPICreateInvoicePayload,
  InvoicesAPICreateInvoiceSearchParams,
};
