// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { IconChevronDown, IconX } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import { useCombobox, useMultipleSelection } from "downshift";
import { Function as F, Record as R } from "effect";
import * as A from "effect/Array";
import * as S from "effect/String";
import type { FocusEvent, ForwardedRef, ReactNode, Ref } from "react";
import { forwardRef, useEffect, useId, useMemo, useState } from "react";

import { Color } from "@ender/shared/utils/theming";

import { ActionIcon } from "../../../action-icon/src";
import { ButtonVariant } from "../../../button/src";
import type { InputBaseProps } from "../../../input/src";
import { InputSize, InputWrapper } from "../../../input/src";
import type {
  SelectOption,
  SelectValue,
} from "../../../select/src/lib/shared-ds-select";
import { isEmptyReactNode } from "../../../utils";





const InputVariantGenerator = cva(
  [
    "grow text-sm/standard placeholder:text-slate-300 outline-none focus:outline-none focus-visible:outline-none",
    "text-slate-900 disabled:text-gray-300 px-1 bg-transparent",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      size: "sm",
      textAlign: "left",
    },
    variants: {
      size: {
        [InputSize.sm]: "py-[calc(0.375rem-1px)]",
        [InputSize.md]: "py-[calc(0.625rem-1px)]",
        [InputSize.lg]: "py-3.5",
      },
      textAlign: {
        left: "",
        right: "text-right",
      },
    },
  },
);

const InputContainerVariantGenerator = cva(
  [
    "group/popover",
    "relative grow flex rounded border flex-wrap",
    "pl-3 gap-x-2",
  ],
  {
    defaultVariants: {
      borderless: false,
    },
    variants: {
      borderless: {
        false: [
          "border-slate-200",
          "has-[:disabled]:bg-gray-50 has-[:disabled]:border-gray-100",
          "has-[:focus]:border-primary-500 has-[:enabled]:has-[[aria-invalid=true]]:border-red-500",
        ],
        true: "border-transparent",
      },
    },
  },
);

function generateSelectData<T extends SelectValue>(
  input: readonly T[] | T[],
): SelectOption<T>[] {
  // Map over the array and return data in the required format
  return input.map((item) => ({
    label: item.toString(),
    value: item,
  }));
}

type FilterFn<V extends SelectValue = SelectValue, M = unknown> = (
  item: SelectOption<V, M>,
  keyword: string,
  selectedItems: SelectOption<V, M>[],
) => boolean;

const defaultFilterFn: FilterFn = (
  item: SelectOption,
  keyword: string,
  selectedItems: SelectOption[],
) =>
  item.label.toLowerCase().includes(keyword.toLowerCase()) &&
  !selectedItems.some((i) => item.value === i.value);

type MultiSelectProps<V extends SelectValue, M = unknown> = {
  value: V[];
  /**
   * the callback invoked when an item is selected from the dropdown
   */
  onChange: (value: V[], item: SelectOption<V, M>[]) => void;
  /**
   * the callback invoked when the user types in the input, and _only_ then.
   * Not invoked when the keyword is set by the user selecting an item from the dropdown.
   */
  onKeywordChange?: (keyword: string) => void;
  /**
   * how items should be filtered from the dropdown list.
   * defaults to a string partial match against the label of the item.
   */
  filterFn?: FilterFn<V, M>;
  data: SelectOption<V, M>[];
  /**
   * Whether the input can be reset to an empty state. If false, the input will always have a value,
   * and blurring the input will reset the value to the previous value.
   */
  clearable?: boolean;
} & Omit<InputBaseProps, "rightSection">;

const MultiSelect = forwardRef(function MultiSelect<
  V extends SelectValue,
  M = unknown,
>(props: MultiSelectProps<V, M>, ref: ForwardedRef<HTMLInputElement>) {
  const {
    borderless = false,
    clearable = false,
    data,
    description,
    disabled = false,
    error,
    label,
    leftSection,
    onChange,
    onKeywordChange = F.constVoid,
    placeholder = "",
    role = "combobox",
    size = InputSize.md,
    textAlign,
    value: selectedItems,
    filterFn = defaultFilterFn,
    name,
  } = props;

  // @ts-expect-error v.value is perfectly fine to use as a record key
  const dataMap = useMemo(() => R.fromIterableBy(data, (v) => v.value), [data]);

  const selectedOptions = selectedItems
    //@ts-expect-error V can access the dataMap
    .map<SelectOption<V, M>>((v) => dataMap[v])
    .filter(Boolean);
  /**
   * a reference to the previous value of the input.
   * Used to reset the input value when the user closes the popover without selecting an item.
   */
  // const selectedItemRef = useRefLatest(
  //   Array.findFirst(data, (option) => option.value === valueOrNull),
  // );

  const [filteredItems, setFilteredItems] = useState(data);

  const {
    getSelectedItemProps,
    addSelectedItem,
    getDropdownProps,
    removeSelectedItem,
    reset,
  } = useMultipleSelection({
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionReset:
          onChange(
            newSelectedItems?.map((v) => v.value) ?? [],
            newSelectedItems ?? [],
          );
          break;
        default:
          break;
      }
    },
    selectedItems: selectedOptions,
  });

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    openMenu,
    setInputValue,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    itemToString(item) {
      return item ? item.label : "";
    },
    items: filteredItems,
    onInputValueChange({ inputValue, type }) {
      if (type === useCombobox.stateChangeTypes.InputChange) {
        //only filter on typing. Don't change filter when you select an item
        //or when the state changes for some other reason (clearing the input, etc.)
        setFilteredItems(
          data.filter((item) => filterFn(item, inputValue, selectedOptions)),
        );
        onKeywordChange(inputValue);
      }
    },
    onIsOpenChange({ isOpen }) {
      if (!isOpen) {
        //clear filters when you close. This means when you open it again, you see all the items
        setFilteredItems([...data]);
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            addSelectedItem(newSelectedItem);
            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue ?? "");
          break;
        default:
          break;
      }
    },
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return { ...changes, inputValue: state.selectedItem?.label ?? "" };
        default:
          return changes;
      }
    },
  });

  /**
   * checks that the `data` prop does not contain duplicates, and logs an error if it does.
   * sets the Data prop when it changes.
   */
  useEffect(() => {
    if (new Set(data.map((d) => d.value)).size !== data.length) {
      console.warn(
        "Select input data prop contains duplicate values. Please make sure each value is unique.",
      );
    }
    setFilteredItems(data);
  }, [data, setInputValue]);

  const idBase = useId();
  const labelId = `${idBase}-label`;
  const descriptionId = `${idBase}-description`;
  const errorId = `${idBase}-error`;
  const describedBy = `${!isEmptyReactNode(error) ? errorId : ""} ${!isEmptyReactNode(description) ? descriptionId : ""}`;

  /**
   * if clearable, the input can be cleared and won't reset on blur.
   * otherwise, the 'clear' action only applies to the typed text, not the stored value
   *
   * for the 'clearable' case, observe what happens if you backspace the input to empty-
   * you are still presented with the 'clear' action. This is because the text is empty,
   * but the stored value is not.
   */
  const showX = clearable
    ? (Array.isArray(selectedItems) && selectedItems.length > 0) ||
      S.isNonEmpty(inputValue)
    : S.isNonEmpty(inputValue);

  const closeButtonId = useId();

  const attributes = {
    borderless,
    leftSection: !isEmptyReactNode(leftSection),
    size,
    textAlign,
  };
  return (
    <Popover open={!disabled && isOpen}>
      <InputWrapper
        label={label}
        description={description}
        error={error}
        inputId={idBase}
        labelId={labelId}
        descriptionId={descriptionId}
        errorId={errorId}>
        <PopoverTrigger asChild>
          <div className={InputContainerVariantGenerator({ borderless })}>
            {!isEmptyReactNode(leftSection) && (
              <div className="pl-3 -mr-1 flex items-center text-slate-500 text-base">
                {leftSection}
              </div>
            )}
            {selectedOptions.map((item, index) => (
              <div
                {...getSelectedItemProps({
                  index,
                  selectedItem: item,
                })}
                key={`${item.value}`}
                className="bg-slate-100 rounded-sm px-2 py-1 mt-1.5 mb-auto text-xs flex gap-2">
                <span>{item.label}</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(item);
                  }}
                  className="-my-2 -mx-1.5 p-1 text-base">
                  <IconX />
                </button>
              </div>
            ))}
            <input
              {...getInputProps(
                getDropdownProps({
                  "aria-describedby": describedBy,
                  "aria-invalid": !isEmptyReactNode(error),
                  "aria-label": isEmptyReactNode(label)
                    ? placeholder
                    : undefined,
                  "aria-labelledby": labelId,
                  "aria-owns": closeButtonId,
                  className: InputVariantGenerator(attributes),
                  disabled,
                  id: idBase,
                  name,
                  onFocus: (e: FocusEvent<HTMLInputElement>) =>
                    e.currentTarget.select(),
                  placeholder,
                  preventKeyAction: isOpen,
                  ref,
                  role,
                }),
              )}
            />
            <div className="-ml-1 pr-3 flex items-center text-slate-500 text-base">
              {showX ? (
                <ActionIcon
                  variant={ButtonVariant.transparent}
                  disabled={disabled}
                  color={Color.slate}
                  label="Clear"
                  onClick={() => {
                    openMenu();
                    setInputValue("");
                    clearable && reset();
                  }}
                  //@ts-expect-error this is a 'secret' prop of ActionIcon
                  id={closeButtonId}>
                  <IconX />
                </ActionIcon>
              ) : (
                <IconChevronDown
                  className={clsx(
                    "mr-1.5 text-lg pointer-events-none group-data-[state=open]/popover:rotate-180",
                    {
                      "rotate-180": isOpen,
                    },
                  )}
                />
              )}
            </div>
          </div>
        </PopoverTrigger>
      </InputWrapper>
      <PopoverPortal>
        <PopoverContent
          className={clsx(
            "w-[--radix-popover-trigger-width] z-10",
            "bg-white will-change-transform border border-primary-300 rounded max-h-80 overflow-auto",
            "data-[state=open]:block data-[state=closed]:hidden",
          )}
          sideOffset={4}
          onOpenAutoFocus={(event) => event.preventDefault()}
          onCloseAutoFocus={(event) => event.preventDefault()}
          align="start"
          /*suppressRefError needs to happen because the popover can be unmounted, which annoys Downshift*/
          {...getMenuProps(
            { "aria-labelledby": labelId },
            { suppressRefError: true },
          )}>
          {filteredItems.map((item, index) => (
            <li
              key={`${item.value}`}
              //this is the value that is used to fuzzy match the search input
              value={item.label}
              className={clsx(
                "pl-3 pr-4 py-2.5 text-sm outline-none flex gap-2.5",
                "aria-selected:bg-primary-100 aria-selected:text-primary-500",
              )}
              {...getItemProps({
                "aria-selected": index === highlightedIndex,
                index,
                item,
              })}>
              {item.label}
            </li>
          ))}
          {A.isEmptyArray(filteredItems) && (
            <li className="pl-3 pr-4 py-2.5 text-sm list-none text-slate-300">
              No matches.
            </li>
          )}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}) as <V extends SelectValue, M = unknown>(
  // eslint-disable-next-line no-use-before-define
  props: MultiSelectProps<V, M> & { ref?: Ref<HTMLInputElement> },
) => ReactNode;

export { generateSelectData, MultiSelect };

export type { MultiSelectProps };
