/**
 * HTTP Method: GET
 * Pathname: /middleLayer/leasing/{leaseId}/getMoveOutFlow
 * @function getMoveOutFlow
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIGetMoveOutFlowPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetMoveOutFlowResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetMoveOutFlowResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIGetMoveOutFlowPathParams = {
  leaseId: EnderId;
};

type LeasingMiddleLayerAPIGetMoveOutFlowSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIGetMoveOutFlowPayload =
  LeasingMiddleLayerAPIGetMoveOutFlowPathParams &
    LeasingMiddleLayerAPIGetMoveOutFlowSearchParams;

function getMoveOutFlowUnsafeEffect(
  payload: LeasingMiddleLayerAPIGetMoveOutFlowPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetMoveOutFlowResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetMoveOutFlowResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/middleLayer/leasing/${leaseId}/getMoveOutFlow`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMoveOutFlow(
  payload: LeasingMiddleLayerAPIGetMoveOutFlowPayload,
  options?: { signal?: AbortSignal },
): Promise<GetMoveOutFlowResponse> {
  return F.pipe(
    payload,
    getMoveOutFlowUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMoveOutFlow };
export type {
  LeasingMiddleLayerAPIGetMoveOutFlowPathParams,
  LeasingMiddleLayerAPIGetMoveOutFlowPayload,
  LeasingMiddleLayerAPIGetMoveOutFlowSearchParams,
};
