import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DrivewayDrivewaySurfaceTypeValues = [
  "CONCRETE",
  "ASPHALT",
  "GRAVEL",
  "DIRT",
  "OTHER",
] as const;

const DrivewayDrivewaySurfaceTypeEffectSchema = Schema.Literal(
  ...DrivewayDrivewaySurfaceTypeValues,
);

type DrivewayDrivewaySurfaceType = Schema.Schema.Type<
  typeof DrivewayDrivewaySurfaceTypeEffectSchema
>;

const DrivewayDrivewaySurfaceTypeEnum = castEnum(
  DrivewayDrivewaySurfaceTypeEffectSchema,
);

function randomDrivewayDrivewaySurfaceType(): DrivewayDrivewaySurfaceType {
  return rand(DrivewayDrivewaySurfaceTypeValues);
}

export {
  DrivewayDrivewaySurfaceTypeEffectSchema,
  DrivewayDrivewaySurfaceTypeEnum,
  DrivewayDrivewaySurfaceTypeValues,
  randomDrivewayDrivewaySurfaceType,
};
export type { DrivewayDrivewaySurfaceType };
