import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PayableCategoryPayableReasonValues = ["REPAIR", "REPLACE"] as const;

const PayableCategoryPayableReasonEffectSchema = Schema.Literal(
  ...PayableCategoryPayableReasonValues,
);

type PayableCategoryPayableReason = Schema.Schema.Type<
  typeof PayableCategoryPayableReasonEffectSchema
>;

const PayableCategoryPayableReasonEnum = castEnum(
  PayableCategoryPayableReasonEffectSchema,
);

function randomPayableCategoryPayableReason(): PayableCategoryPayableReason {
  return rand(PayableCategoryPayableReasonValues);
}

export {
  PayableCategoryPayableReasonEffectSchema,
  PayableCategoryPayableReasonEnum,
  PayableCategoryPayableReasonValues,
  randomPayableCategoryPayableReason,
};
export type { PayableCategoryPayableReason };
