import { acceptOffer } from "./accept-offer";
import { bulkUploadRenewals } from "./bulk-upload-renewals";
import { createDraftingRenewalOffers } from "./create-drafting-renewal-offers";
import { createOffer } from "./create-offer";
import { deleteOffer } from "./delete-offer";
import { getOffers } from "./get-offers";
import { noRenew } from "./no-renew";
import { rejectAllOffers } from "./reject-all-offers";
import { rejectOffer } from "./reject-offer";
import { rescindAllOffers } from "./rescind-all-offers";
import { rescindOffer } from "./rescind-offer";
import { sendRenewalOffers } from "./send-renewal-offers";
import { unRescindRecentOffers } from "./un-rescind-recent-offers";
import { updateOffer } from "./update-offer";

const RenewalsAPI = {
  acceptOffer,
  bulkUploadRenewals,
  createDraftingRenewalOffers,
  createOffer,
  deleteOffer,
  getOffers,
  noRenew,
  rejectAllOffers,
  rejectOffer,
  rescindAllOffers,
  rescindOffer,
  sendRenewalOffers,
  unRescindRecentOffers,
  updateOffer,
};

export { RenewalsAPI };
