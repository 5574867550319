import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Modal } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import type { PayByACHFormProps } from "./pay-by-ach-form";
import { PayByACHForm } from "./pay-by-ach-form";

type PayAchButtonProps = {
  disabled: boolean;
  disabledTooltip: string;
} & PayByACHFormProps;

function PayAchButton({
  bankAccounts,
  disabled,
  disabledTooltip,
  invoice,
  onSuccess,
  operatingAccountId,
}: PayAchButtonProps) {
  const [opened, { setTrue: onClick, setFalse: onClose }] = useBoolean();

  return (
    <>
      <Button
        variant={ButtonVariant.outlined}
        disabled={disabled || opened}
        onClick={onClick}
        disabledTooltip={disabledTooltip}>
        Pay ACH
      </Button>
      <Modal opened={opened} onClose={onClose} title="">
        <PayByACHForm
          invoice={invoice}
          bankAccounts={bankAccounts}
          onSuccess={onSuccess}
          operatingAccountId={operatingAccountId}
        />
      </Modal>
    </>
  );
}

export { PayAchButton };
