import type {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

type QueryKey<Params> = Readonly<[string, Params]>;
type QueryFn<Params, Data> = (
  params: Params,
  options?: { signal?: AbortSignal },
) => Promise<Data>;

/**
 * This is a factory function which creates a `useQuery` hook where part of the `queryKey` and `queryFn` have already been set.
 * @param {string} key The key to use for the query, This should be unique for each endpoint.
 *     - This is added as the first element of the `queryKey`.
 *     - The `requestFn` `params` will be the second element added when you're using the resulting hook.
 *     - `useQuery` uses the `queryKey` to derive(stable hash) then run and automatically update the result when hash changes (as long as enabled is not set to false).
 *     See (Query Keys)[https://tanstack.com/query/v4/docs/react/guides/query-keys] for more information.
 * @param requestFn The function used to request the data.
 *     - This function will receive two parameters the user defined `Params` object and optional `options` which currently only supports AbortSignal,
 *       but future expansion is possible here.
 *     - `useQuery` sends a `QueryFunctionContext` to it's `queryFn` which contains the `Params` and `signal`, we extract those and pass them to
 *       your `queryFn` as the first parameter and options.
 * @returns A `useQuery` hook with part of the `queryKey` and `queryFn` already set
 *
 * @example
 * // Create the custom hook using the factory outside a Component
 * type RequestParams = { foo: string };
 * type ResponseObject = { bar: string };
 * function queryWithParams(params: RequestParams, options: AuthorOptions = {}): Promise<ResponseObject> {
 *   return rest.post("/some/endpoint", params, options);
 * }
 * const useWithParamsQuery = makeUseQuery<ResponseObject, RequestParams>("queryWithParams", queryWithParams);
 * // Use the custom hook inside a Component
 * const result = useWithParamsQuery({ foo: "bar" }); // Note: The result is exactly what `useQuery` would return
 *
 * @example
 * // Usage without  Params
 * type ResponseObject = { bar: string };
 * function queryWithoutParams(): Promise<ResponseObject> {
 *   return rest.get("/some/endpoint");
 * }
 * const useWithoutParamsQuery = makeUseQuery<ResponseObject, void>("queryWithoutParams", queryWithoutParams);
 * // Use the custom hook inside a Component
 * const result = useWithoutParamsQuery(null); // Note: null is required here because the API expects at least one param we are working on a solution for this
 */
const makeUseQuery = function makeUseQuery<
  Data = unknown,
  Params = unknown,
  Error = unknown,
>(key: Readonly<string>, requestFn: QueryFn<Params, Data>) {
  // The query function responsible for getting the params and signal from the `QueryFunctionContext` and passing them to the user defined `requestFn`
  const queryFn: QueryFunction<Data, QueryKey<Params>> = async ({
    queryKey: [, params],
    signal,
  }) => {
    return requestFn(params, { signal });
  };
  return function useWrappedQuery<SelectData = Data>(
    params: Params,
    options: Omit<
      UseQueryOptions<Data, Error, SelectData, QueryKey<Params>>,
      "queryKey" | "queryFn"
    > = {},
  ): UseQueryResult<SelectData, Error> {
    // Here we ensure the `queryKey` contains the `key` and `params` we need to make the request
    const queryKey = [key, params] as const;
    return useQuery<Data, Error, SelectData, QueryKey<Params>>({
      queryFn,
      queryKey,
      ...options,
    });
  };
};

export { makeUseQuery };
