import { useMutation } from "@tanstack/react-query";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";

type ApproveButtonProps = {
  disabled?: boolean;
  invoiceId: EnderId;
  isAccountingPeriodClosed: boolean;
  isNextStepInTreasuryPhase: boolean;
  onSuccess: () => void;
};

function ApproveButton({
  disabled = false,
  invoiceId,
  isAccountingPeriodClosed,
  isNextStepInTreasuryPhase,
  onSuccess,
}: ApproveButtonProps) {
  const confirmation = useConfirmationContext();

  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };

  const { mutateAsync: approveInvoice } = useMutation({
    mutationFn: withWarningHandler(ApprovalsAPI.approve, onWarnings),
  });

  async function handleApproveInvoiceClick() {
    await approveInvoice({
      modelId: invoiceId,
      modelType: ModelTypeEnum.INVOICE,
    });
    showSuccessNotification({
      message: "Invoice approved to the next approval step.",
    });
    onSuccess();
  }

  return (
    <Button
      onClick={handleApproveInvoiceClick}
      variant={ButtonVariant.outlined}
      //Since the button can be disabled for other reasons, we only show the disabledTooltip under certain conditions
      disabledTooltip={
        isAccountingPeriodClosed &&
        isNextStepInTreasuryPhase &&
        "This invoice is in a period that is closed for your user type.  Please update the period date or reopen the accounting period to approve."
      }
      disabled={
        disabled || (isAccountingPeriodClosed && isNextStepInTreasuryPhase)
      }>
      Approve
    </Button>
  );
}

export { ApproveButton };
