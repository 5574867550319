import type { UnitUnitStatus } from "@ender/shared/generated/ender.model.core.unit";
import { UnitUnitStatusEnum } from "@ender/shared/generated/ender.model.core.unit";

const UnitStatusDisplayEnum: Record<UnitUnitStatus, string> = {
  [UnitUnitStatusEnum.OCCUPIED_TIP]: "Occupied (TIP)",
  [UnitUnitStatusEnum.ADVERSELY_OCCUPIED]: "Adversely Occupied",
  [UnitUnitStatusEnum.MARKETING_DOWN]: "Marketing Down",
  [UnitUnitStatusEnum.HELD_FOR_SALE]: "Held For Sale",
  [UnitUnitStatusEnum.MARKETED]: "Marketed",
  [UnitUnitStatusEnum.OCCUPIED]: "Occupied",
  [UnitUnitStatusEnum.PENDING_LISTING_CREATION]: "Pending Listing Creation",
  [UnitUnitStatusEnum.PENDING_MARKETING_CERTIFICATE]:
    "Pending Marketing Certificate",
  [UnitUnitStatusEnum.RENOVATING]: "Renovating",
  [UnitUnitStatusEnum.RETIRED]: "Retired",
  [UnitUnitStatusEnum.SOLD]: "Sold",
  [UnitUnitStatusEnum.SPECIAL_DAMAGE_RENOVATION]: "Special Damage Renovation",
  [UnitUnitStatusEnum.STAGING]: "Staging",
  [UnitUnitStatusEnum.TURN]: "Turn",
  [UnitUnitStatusEnum.VACANT_LEASED]: "Vacant Leased",
} as const;

function getBathsDistributionText(
  fullBaths: number = 0,
  halfBaths: number = 0,
) {
  let text = ""; // `${fullBaths || "-"} full baths + ${halfBaths + || "-"} half baths`
  if (fullBaths === 1) {
    text += "1 full bath";
  } else if (fullBaths > 1) {
    text += `${fullBaths} full baths`;
  }

  if (halfBaths && fullBaths) {
    text += " + ";
  }

  if (halfBaths === 1) {
    text += "1 half bath";
  } else if (halfBaths > 1) {
    text += `${halfBaths} half baths`;
  }

  return text;
}

export { getBathsDistributionText, UnitStatusDisplayEnum };
