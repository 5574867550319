// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { ActionIcon, useComponentDefaultProps } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type {
  DatePickerProps,
  DatePickerStylesNames,
  DayModifiers,
} from "@mantine/dates";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { DatePicker } from "@mantine/dates";
import { IconCalendar, IconX } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import type { CSSProperties, RefAttributes } from "react";
import { useCallback } from "react";

import { NULL } from "@ender/shared/constants/general";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { useUncontrolled } from "@ender/shared/hooks/use-uncontrolled";
import type { InputHeight } from "@ender/shared/types/ender-general";
import {
  InputHeightEnum,
  generateInputHeight,
} from "@ender/shared/types/ender-general";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { ENDER_INPUT_WRAPPER_ORDER } from "@ender/shared/utils/mantine";

import styles from "./ender-date-picker.module.css";

const defaultClassNames: Partial<Record<DatePickerStylesNames, string>> = {
  calendarHeaderControl: `${styles.text} ${styles.hoverable}`,
  calendarHeaderLevel: `${styles.text} ${styles.hoverable}`,
  day: `${styles.text} ${styles.day} ${styles.hoverable}`,
  dropdown: styles.dropdown,
  monthPickerControl: `${styles.text} ${styles.monthPickerControl} ${styles.hoverable}`,
  monthPickerControlActive: styles.monthPickerControlActive,
  yearPickerControl: `${styles.text} ${styles.yearPickerControl} ${styles.hoverable}`,
  yearPickerControlActive: styles.yearPickerControlActive,
};

const dayStyle = (date: Date, modifiers: DayModifiers): CSSProperties => {
  if (modifiers.disabled) {
    return { color: EnderThemeColorEnum.GRAY_100 };
  }

  if (modifiers.selected) {
    return { color: EnderThemeColorEnum.WHITE };
  }

  if (modifiers.outside) {
    if (EnderDate.of(date).isToday()) {
      return { color: EnderThemeColorEnum.SLATE_300 };
    }

    return { color: EnderThemeColorEnum.GRAY_100 };
  }

  if (modifiers.weekend) {
    return { color: EnderThemeColorEnum.SLATE_900 };
  }

  return {};
};

type EnderDatePickerProps = Omit<
  DatePickerProps,
  "value" | "onChange" | "dateParser"
> &
  RefAttributes<HTMLInputElement> & {
    value?: EnderDate | null;
    onChange?: (value: EnderDate | null) => void;
    dateParser?: (value: string) => EnderDate;
    /**
     * Note: the `showTodayButton` works with the `minDate` and `maxDate` props to avoid setting
     * today's date if it is outside the range of the min and max dates. However, the button will still appear and be clickable
     * so be aware of that when using this prop with the `minDate` and `maxDate` props.
     */
    showTodayButton?: boolean;
    height?: InputHeight;
  };

const dayClassName = (date: Date, modifiers: DayModifiers): string =>
  clsx({
    [styles.today]: EnderDate.of(date).isToday(),
    [styles.outside]: modifiers.outside,
    [styles.weekend]: modifiers.weekend,
    [styles.selected]: modifiers.selected,
  });

const datePickerDefaultProps: Partial<EnderDatePickerProps> = {
  allowFreeInput: true,
  classNames: defaultClassNames,
  // @ts-expect-error TODO: Fix this type mismatch
  dateParser: EnderDate.fromUserInput,
  dayClassName,
  dayStyle,
  firstDayOfWeek: "sunday",
  inputFormat: "MM/DD/YYYY",
  inputWrapperOrder: ENDER_INPUT_WRAPPER_ORDER,
  onKeyPress: (e) => {
    e.key === "Enter" && e.preventDefault();
  },
  placeholder: "MM/DD/YYYY",
};

const parseInitial = (value?: string | Date | null): EnderDate | null => {
  if (value) {
    const parsed = EnderDate.of(value);
    return parsed.isInvalid() ? NULL : parsed;
  }

  return NULL;
};

/**
 * @deprecated use DateInput from @ender/shared/ds/date-input
 * this will require using Option of LocalDate$ in all forms that this component plugs in to.
 *
 * LocalDate$.parse(value) is the correct way to parse an API value into an Option of LocalDate$, and should be used while initializing the form.
 */
function EnderDatePicker(props: EnderDatePickerProps) {
  const {
    clearable = true,
    defaultValue,
    height = InputHeightEnum.TALL,
    icon = <IconCalendar />,
    onChange = F.constVoid,
    showTodayButton = false,
    value,
    wrapperProps,
    ...other
  } = useComponentDefaultProps(
    "EnderDatePicker",
    datePickerDefaultProps,
    props,
  );

  const [_value, handleChange] = useUncontrolled({
    value: parseInitial(value),
    defaultValue: parseInitial(defaultValue),
    finalValue: NULL, //intentionally a null so that the datepicker displays an empty input if neither value or defaultValue are provided
    onChange: (value) => {
      P.isNullable(value) ? onChange(NULL) : onChange(EnderDate.of(value));
    },
  });

  // TODO: include useStyles to incorporate theme values in this component:
  // const { classes } = useStyles(undefined, { classNames, name: "EnderDatePicker" });

  const setToday = useCallback(() => {
    const afterMinDate = other.minDate
      ? EnderDate.of(other.minDate).isOnOrBeforeToday()
      : true;
    const beforeMaxDate = other.maxDate
      ? EnderDate.of(other.maxDate).isOnOrAfterToday()
      : true;
    if (afterMinDate && beforeMaxDate) {
      handleChange(EnderDate.today);
    }
  }, [other.minDate, other.maxDate, handleChange]);

  return (
    <DatePicker
      wrapperProps={{ ...wrapperProps }}
      {...other}
      icon={
        showTodayButton ? (
          <Tooltip label="Set to Today">
            <ActionIcon
              color="primary"
              onClick={setToday}
              disabled={other.disabled}
              data-testid="today-button">
              {icon}
            </ActionIcon>
          </Tooltip>
        ) : (
          icon
        )
      }
      styles={{
        icon: {
          cursor: "default",
          pointerEvents: showTodayButton ? "auto" : "none",
        },
        ...generateInputHeight(height),
      }}
      rightSection={
        clearable ? (
          <ActionIcon onClick={() => handleChange(NULL)}>
            <IconX />
          </ActionIcon>
        ) : undefined
      }
      clearable={false}
      value={_value}
      openDropdownOnClear={false}
      onChange={handleChange}
    />
  );
}

export { EnderDatePicker, datePickerDefaultProps, defaultClassNames };
export type { EnderDatePickerProps };
