import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DwollaTransferDwollaTransferStatusValues = [
  "NEW",
  "PENDING",
  "TRANSFERRING",
  "FAILED",
  "CANCELLED",
  "COMPLETE",
] as const;

const DwollaTransferDwollaTransferStatusEffectSchema = Schema.Literal(
  ...DwollaTransferDwollaTransferStatusValues,
);

type DwollaTransferDwollaTransferStatus = Schema.Schema.Type<
  typeof DwollaTransferDwollaTransferStatusEffectSchema
>;

const DwollaTransferDwollaTransferStatusEnum = castEnum(
  DwollaTransferDwollaTransferStatusEffectSchema,
);

function randomDwollaTransferDwollaTransferStatus(): DwollaTransferDwollaTransferStatus {
  return rand(DwollaTransferDwollaTransferStatusValues);
}

export {
  DwollaTransferDwollaTransferStatusEffectSchema,
  DwollaTransferDwollaTransferStatusEnum,
  DwollaTransferDwollaTransferStatusValues,
  randomDwollaTransferDwollaTransferStatus,
};
export type { DwollaTransferDwollaTransferStatus };
