import { useQuery } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import type { InputBaseProps } from "@ender/shared/ds/input";
import { InputSize } from "@ender/shared/ds/input";
import { TextInput } from "@ender/shared/ds/text-input";
import type { PlaceResult } from "@ender/shared/utils/google-maps";
import { GoogleMapsLoader } from "@ender/shared/utils/google-maps";

import "./entities-place-input.css";

type PlaceInputProps = {
  value: O.Option<PlaceResult>;
  onChange: (place: O.Option<PlaceResult>) => void;
} & InputBaseProps;

const PlaceInput = forwardRef<HTMLDivElement, PlaceInputProps>(
  function PlaceInput(props, _ref) {
    const {
      //TODO wire this up to fetch the place and show the address in the input
      value,
      onChange,
      description,
      disabled = false,
      error,
      label,
      leftSection,
      placeholder = "",
      rightSection,
      role = "combobox",
      size = InputSize.md,
      textAlign,
      borderless = false,
      "data-private": _dataPrivate,
      name,
    } = props;

    const googleMapsObserverRef = useRef<MutationObserver | Null>(NULL);
    const containerRef = useRef<HTMLDivElement>(NULL);
    const [addressInput, setAddressInput] = useState<HTMLInputElement | Null>(
      NULL,
    );
    /**
     * the text within the input
     */
    const [text, setText] = useState(
      pipe(
        value,
        O.map((place) => place.formatted_address ?? ""),
        O.getOrElse(F.constant("")),
      ),
    );

    const { data: googleMapsPlaces } = useQuery({
      queryFn: () => GoogleMapsLoader.importLibrary("places"),
      queryKey: ["GoogleMapsLoader.importLibrary", "places"],
    });

    /**
     * Google Maps autocomplete suggestions load in a div with the classname "pac-container",
     * which is appended to the body by default.
     *
     * To render it properly within the DS Modal (portal) and allow item selection,
     * we need to re-append it using JavaScript.
     *
     * The div is appended to the input wrapper to enable correct positioning.
     */
    const movePacContainer = useCallback(() => {
      const pacContainer = document.querySelector(".pac-container");
      if (P.isNotNullable(pacContainer) && P.isNotNullable(addressInput)) {
        const inputWrapper = addressInput.closest(".place-input-wrapper");
        if (
          P.isNotNullable(inputWrapper) &&
          !inputWrapper.contains(pacContainer)
        ) {
          inputWrapper.appendChild(pacContainer);
        }
      }
    }, [addressInput]);

    const observerCallback = useCallback(
      (mutations: MutationRecord[]) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (
              node instanceof HTMLElement &&
              node.classList.contains("pac-container")
            ) {
              movePacContainer();
            }
          });
        });
      },
      [movePacContainer],
    );

    /**load google into the address input */
    useEffect(() => {
      if (!googleMapsPlaces || !addressInput) {
        return;
      }
      const autocomplete = new googleMapsPlaces.Autocomplete(addressInput, {
        componentRestrictions: { country: ["US"] },
        fields: [
          "address_components",
          "geometry",
          "formatted_address",
          "place_id",
        ],
        types: ["address"],
      });
      const listener = autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        setText(place.formatted_address ?? "");
        addressInput.value = place.formatted_address ?? "";
        onChange(O.fromNullable(place));
      });

      /** Set up the observer if not already created */
      if (!googleMapsObserverRef.current) {
        googleMapsObserverRef.current = new MutationObserver(observerCallback);
        googleMapsObserverRef.current.observe(document.body, {
          childList: true,
          subtree: true,
        });
      }

      // Clean up on unmount
      return () => {
        googleMapsObserverRef.current?.disconnect();
        googleMapsObserverRef.current = NULL;
        listener.remove();
        const pacContainer = document.querySelector(".pac-container");
        if (P.isNotNullable(pacContainer) && P.isNotNullable(addressInput)) {
          const inputWrapper = addressInput.closest(".place-input-wrapper");
          if (
            P.isNotNullable(inputWrapper) &&
            inputWrapper.contains(pacContainer)
          ) {
            inputWrapper.removeChild(pacContainer);
          }
        }
      };
    }, [
      addressInput,
      googleMapsPlaces,
      movePacContainer,
      observerCallback,
      onChange,
    ]);

    const onInput = useCallback(
      (e: string) => {
        setText(e);
        if (e === "") {
          onChange(O.none());
        }
      },
      [onChange],
    );

    return (
      <div ref={containerRef} className="place-input-wrapper">
        <TextInput
          value={text}
          ref={setAddressInput}
          onChange={onInput}
          borderless={borderless}
          description={description}
          disabled={disabled}
          error={error}
          label={label}
          leftSection={leftSection}
          placeholder={placeholder}
          rightSection={rightSection}
          size={size}
          name={name}
          role={role}
          textAlign={textAlign}
          data-private={_dataPrivate}
        />
      </div>
    );
  },
);

export { PlaceInput };

export type { PlaceInputProps };
