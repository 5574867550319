import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationsAPIApplicationSortKeyValues = [
  "APPLICATION_GROUP_ID",
  "MOVE_IN_DATE",
  "LAST_ACTIVE_TIME",
  "START_TIME",
  "FOLLOW_UP",
] as const;

const ApplicationsAPIApplicationSortKeyEffectSchema = Schema.Literal(
  ...ApplicationsAPIApplicationSortKeyValues,
);

type ApplicationsAPIApplicationSortKey = Schema.Schema.Type<
  typeof ApplicationsAPIApplicationSortKeyEffectSchema
>;

const ApplicationsAPIApplicationSortKeyEnum = castEnum(
  ApplicationsAPIApplicationSortKeyEffectSchema,
);

function randomApplicationsAPIApplicationSortKey(): ApplicationsAPIApplicationSortKey {
  return rand(ApplicationsAPIApplicationSortKeyValues);
}

export {
  ApplicationsAPIApplicationSortKeyEffectSchema,
  ApplicationsAPIApplicationSortKeyEnum,
  ApplicationsAPIApplicationSortKeyValues,
  randomApplicationsAPIApplicationSortKey,
};
export type { ApplicationsAPIApplicationSortKey };
