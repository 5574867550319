import { uploadFiles } from "@ender/shared/api/files";
import type { EnderId } from "@ender/shared/core";
import { Modal } from "@ender/shared/ds/modal";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { FileUploadForm } from "@ender/shared/ui/modals";

/**
 * UploadBaiModal component.
 */
function UploadBaiModal({
  opened,
  onClose,
  accountId,
  refreshTransactions,
}: {
  opened: boolean;
  accountId: EnderId;
  onClose: () => void;
  refreshTransactions: () => void;
}) {
  function uploadBai2File(files: File[]) {
    return uploadFiles({
      files,
      modelId: accountId,
      modelType: ModelTypeEnum.BANK_ACCOUNT,
      uploadType: WebserverFilesServiceFileUploadTypeEnum.BANK_TRANSACTIONS,
    });
  }

  return (
    <Modal title="Upload BAI2 File" opened={opened} onClose={onClose}>
      <FileUploadForm
        onSuccess={() => {
          onClose();
          refreshTransactions();
        }}
        uploadFiles={uploadBai2File}
      />
    </Modal>
  );
}

export { UploadBaiModal };
