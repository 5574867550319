import { useCallback } from "react";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { TextInput } from "@ender/shared/ds/text-input";

import { MoveInStep } from "../move-in-step";
import type { ChecklistStepComponentProps } from "./checklist-steps.types";

function NumberStep(props: ChecklistStepComponentProps) {
  const {
    completed,
    capturedData = "",
    completedTime,
    onChange,
    ...stepProps
  } = props;

  const _onChange = useCallback(
    (value: string) =>
      onChange({ capturedData: value.replace(/\D/g, ""), completed: true }),
    [onChange],
  );

  return (
    <MoveInStep {...stepProps} completedTime={completedTime}>
      <Group spacing={Spacing.none} noWrap>
        <TextInput
          disabled={completed}
          value={capturedData}
          onChange={_onChange}
        />
        {Boolean(completedTime) && (
          <Button
            variant={ButtonVariant.transparent}
            onClick={() => onChange({ capturedData: "", completed: false })}
            disabled={completed}>
            Clear
          </Button>
        )}
      </Group>
    </MoveInStep>
  );
}

export { NumberStep };
