import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PeriodFilterFilterTypeValues = [
  "ALL",
  "ALL_OPEN",
  "ALL_CLOSED",
  "UP_TO_CURRENT_PERIOD",
  "CUSTOM",
] as const;

const PeriodFilterFilterTypeEffectSchema = Schema.Literal(
  ...PeriodFilterFilterTypeValues,
);

type PeriodFilterFilterType = Schema.Schema.Type<
  typeof PeriodFilterFilterTypeEffectSchema
>;

const PeriodFilterFilterTypeEnum = castEnum(PeriodFilterFilterTypeEffectSchema);

function randomPeriodFilterFilterType(): PeriodFilterFilterType {
  return rand(PeriodFilterFilterTypeValues);
}

export {
  PeriodFilterFilterTypeEffectSchema,
  PeriodFilterFilterTypeEnum,
  PeriodFilterFilterTypeValues,
  randomPeriodFilterFilterType,
};
export type { PeriodFilterFilterType };
