import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskTaskTypeValues = [
  "MANUAL",
  "INTERNAL",
  "WORK_ORDER",
  "CONSTRUCTION_PURCHASE_ORDER",
  "CONSTRUCTION_LINE_ITEM",
  "RESPOND",
  "BIRTHDAY_WISH",
  "HANDLE_OPS_EMAIL",
  "CHANGE_HVAC_FILTER",
  "SMARTHUB_TROUBLESHOOTING",
  "CLEAN_UNIT",
  "RENEW_LEASE",
  "REPORT_SALES_FIGURES",
  "CLOSE_TENANT_ACCOUNT",
  "PM_INSURANCE_RENEWAL",
  "TENANT_INSURANCE_RENEWAL",
  "EVICT",
  "LATE_RENT",
  "BUDGET_REFORECAST",
  "TENANT_CHARGEBACK",
  "PRE_MOVE_IN_WALK",
] as const;

const TaskTaskTypeEffectSchema = Schema.Literal(...TaskTaskTypeValues);

type TaskTaskType = Schema.Schema.Type<typeof TaskTaskTypeEffectSchema>;

const TaskTaskTypeEnum = castEnum(TaskTaskTypeEffectSchema);

function randomTaskTaskType(): TaskTaskType {
  return rand(TaskTaskTypeValues);
}

export {
  randomTaskTaskType,
  TaskTaskTypeEffectSchema,
  TaskTaskTypeEnum,
  TaskTaskTypeValues,
};
export type { TaskTaskType };
