import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { SortingState } from "@tanstack/react-table";
import { Array as A } from "effect";
import { useCallback, useMemo } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { InvoicesMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type {
  GetPayablesReceivablesAdditionalInfoResponseInvoice,
  GetPayablesReceivablesResponsePayableReceivable,
} from "@ender/shared/generated/com.ender.middle.response";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";

import { useInvoicesPayload } from "./use-invoices-payload";

function isApiError(error: unknown): error is { status: number } {
  return typeof error === "object" && error !== null && "status" in error;
}

const retry = (failureCount: number, error: { status: number }) => {
  if (isApiError(error) && error.status === 404) {
    return false;
  }
  return failureCount < 5;
};

const retryDelay = (retryAttempt: number) =>
  Math.min(2000 * retryAttempt, 10000);

type PayableReceivableWithAdditionalInfo =
  GetPayablesReceivablesResponsePayableReceivable & {
    additionalInfo: GetPayablesReceivablesAdditionalInfoResponseInvoice | Null;
  };

function useInvoicesData({
  invoiceType,
  limit,
  tableSorting,
}: {
  invoiceType: InvoiceInvoiceType;
  limit: number;
  tableSorting?: SortingState;
}) {
  const invoicesAPIGetInvoicesPayload = useInvoicesPayload({
    invoiceType,
    tableSorting,
  });

  const {
    data: fetchedPayablesReceivables,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingPayablesReceivables,
    refetch: refetchPayablesReceivables,
  } = useInfiniteQuery({
    queryKey: [
      "InvoicesMiddleLayerAPI.getPayablesReceivables",
      { invoicesAPIGetInvoicesPayload, limit },
    ] as const,
    queryFn: ({ pageParam = UNDEFINED, signal }) =>
      InvoicesMiddleLayerAPI.getPayablesReceivables(
        {
          ...invoicesAPIGetInvoicesPayload,
          limit,
          startAfterInvoiceId: pageParam,
        },
        { signal },
      ),
    getNextPageParam: (lastPage) => {
      if (lastPage.entries.length === limit) {
        return lastPage.entries[lastPage.entries.length - 1].invoiceId;
      }
      return UNDEFINED;
    },
    refetchOnMount: true,
    retry,
    retryDelay,
  });

  const invoiceIds = useMemo(() => {
    if (!fetchedPayablesReceivables) {
      return [];
    }
    return fetchedPayablesReceivables.pages.flatMap((page) =>
      page.entries.map((entry) => entry.invoiceId),
    );
  }, [fetchedPayablesReceivables]);
  const {
    data: fetchedInvoicesAdditionalInfo,
    isFetching: isFetchingAdditionalInfo,
    refetch: refetchAdditionalInfo,
  } = useQuery({
    queryKey: [
      "InvoicesMiddleLayerAPI.getPayablesReceivablesAdditionalInfo",
      invoiceType,
      invoiceIds,
    ] as const,
    queryFn: ({ signal }) =>
      InvoicesMiddleLayerAPI.getPayablesReceivablesAdditionalInfo(
        {
          invoiceIds,
          invoiceType,
        },
        { signal },
      ),
    enabled: A.isNonEmptyArray(invoiceIds),
    refetchOnMount: true,
    retry,
    retryDelay,
  });
  const additionalInfoMap = useMemo(() => {
    if (
      !fetchedInvoicesAdditionalInfo ||
      !A.isArray(fetchedInvoicesAdditionalInfo.invoices)
    ) {
      return new Map();
    }

    return new Map(
      fetchedInvoicesAdditionalInfo.invoices.map((invoice) => [
        invoice.id,
        invoice,
      ]),
    );
  }, [fetchedInvoicesAdditionalInfo]);
  const payablesReceivablesWithAdditionalInfo: PayableReceivableWithAdditionalInfo[] =
    useMemo(() => {
      if (!fetchedPayablesReceivables) {
        return [];
      }

      return fetchedPayablesReceivables.pages.flatMap((page) =>
        page.entries.map((entry) => ({
          ...entry,
          additionalInfo: additionalInfoMap.get(entry.invoiceId) || NULL,
        })),
      );
    }, [fetchedPayablesReceivables, additionalInfoMap]);

  const payablesReceivables = useMemo(() => {
    return (
      fetchedPayablesReceivables?.pages.flatMap((page) => page.entries) ?? []
    );
  }, [fetchedPayablesReceivables]);

  const {
    data: invoicesSummary,
    isFetching: isFetchingInvoicesSummary,
    refetch: refetchInvoicesSummary,
  } = useQuery({
    queryKey: [
      "InvoicesAPI.getInvoicesSummary",
      { invoicesAPIGetInvoicesPayload },
    ] as const,
    queryFn: ({ signal }) =>
      InvoicesAPI.getInvoicesSummary(invoicesAPIGetInvoicesPayload, { signal }),
  });
  const totalResults = useMemo(() => {
    return invoicesSummary?.totalMatches ?? UNDEFINED;
  }, [invoicesSummary]);

  /**
   * combination and memoization
   */
  const isFetching = useMemo(
    () =>
      isFetchingAdditionalInfo ||
      isFetchingInvoicesSummary ||
      isFetchingPayablesReceivables,
    [
      isFetchingAdditionalInfo,
      isFetchingInvoicesSummary,
      isFetchingPayablesReceivables,
    ],
  );
  const refetch = useCallback(() => {
    refetchAdditionalInfo();
    refetchInvoicesSummary;
    refetchPayablesReceivables();
  }, [
    refetchAdditionalInfo,
    refetchInvoicesSummary,
    refetchPayablesReceivables,
  ]);

  return useMemo(
    () => ({
      fetchNextPage,
      hasNextPage,
      invoicesAPIGetInvoicesPayload,
      isFetching,
      payablesReceivables,
      payablesReceivablesWithAdditionalInfo,
      refetch,
      totalResults,
    }),
    [
      fetchNextPage,
      hasNextPage,
      isFetching,
      payablesReceivables,
      payablesReceivablesWithAdditionalInfo,
      refetch,
      totalResults,
      invoicesAPIGetInvoicesPayload,
    ],
  );
}

export { useInvoicesData };
export type { PayableReceivableWithAdditionalInfo };
