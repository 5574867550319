import { IconEdit, IconTrash } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Function as F } from "effect";
import type { MouseEventHandler } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Inset } from "@ender/shared/ds/inset";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";

import { Tooltip } from "@ender/shared/ds/tooltip";
import type { Party } from "@ender/shared/generated/ender.model.payments";

import { Balances } from "./balances";

import styles from "./bank-account-card.module.css";

type BankAccountCardProps = {
  bankAccountNumber: string;
  deleteButtonTooltip?: string;
  disableDelete?: boolean;
  endDate?: LocalDate;
  hideBalances?: boolean;
  id: EnderId;
  institution?: string;
  isDeleteButtonEnabled: boolean;
  isLink?: boolean;
  isMasquerading: boolean;
  linkHandler: () => void;
  name?: string;
  needsVerification: boolean;
  openDeleteModel: MouseEventHandler<HTMLDivElement>;
  openEditModal: MouseEventHandler<HTMLButtonElement>;
  openVerifyModal: MouseEventHandler<HTMLDivElement>;
  ownerType?: Party;
  startDate?: LocalDate;
  propertyFilterValue: EnderId[];
};

function BankAccountCard({
  bankAccountNumber,
  deleteButtonTooltip,
  disableDelete = false,
  endDate,
  hideBalances,
  id,
  institution,
  isDeleteButtonEnabled,
  isLink,
  isMasquerading,
  linkHandler,
  name,
  needsVerification,
  openDeleteModel,
  openEditModal,
  openVerifyModal,
  ownerType,
  startDate,
  propertyFilterValue,
}: BankAccountCardProps) {
  const deleteButtonColor = isDeleteButtonEnabled
    ? EnderThemeColorEnum.RED_700
    : EnderThemeColorEnum.GRAY_300;
  const hasDeleteButtonTooltip = Boolean(deleteButtonTooltip);
  const isDeleteTooltipDisabled =
    isDeleteButtonEnabled || !hasDeleteButtonTooltip;
  const onDeleteButtonClick = isDeleteButtonEnabled
    ? openDeleteModel
    : F.constVoid;
  const shouldShowDeleteButton = !disableDelete && !isMasquerading;

  return (
    <div className={styles.bankAccountCard}>
      <div
        className={styles.bankAccountCardInner}
        style={{ cursor: isLink ? "pointer" : "" }}
        onClick={linkHandler}>
        <div className={styles.bankAccountCardActions}>
          <Group spacing={Spacing.xs} align={Align.start}>
            <Inset t="sm">
              <ActionIcon
                label="Edit Bank Account"
                size={ButtonSize.sm}
                onClick={openEditModal}
                variant={ButtonVariant.transparent}>
                <IconEdit color="white" />
              </ActionIcon>
            </Inset>
            {shouldShowDeleteButton && (
              <Tooltip
                disabled={isDeleteTooltipDisabled}
                label={deleteButtonTooltip}>
                <div
                  onClick={onDeleteButtonClick}
                  className={clsx(styles.bankAccountCardAction, "cursor")}
                  aria-label="Delete bank account card">
                  <IconTrash color={deleteButtonColor} size={12} />
                </div>
              </Tooltip>
            )}
          </Group>
        </div>
        <div className={`${styles.bankAccountCardInnerHeader}`}>
          <Text
            weight={FontWeight.bold}
            size={FontSize.sm}
            color="inherit">
            {institution || "Bank Account"}
          </Text>
        </div>
        <div>{bankAccountNumber}</div>
        <div className={styles.bankAccountCardName}>
          {name || "Unnamed Account"}
        </div>

        {!needsVerification ? (
          !hideBalances && (
            <Balances
              endDate={endDate}
              id={id}
              ownerType={ownerType}
              startDate={startDate}
              propertyFilterValue={propertyFilterValue}
            />
          )
        ) : (
          <div
            className={clsx(styles.bankAccountCardMessage, "cursor")}
            onClick={openVerifyModal}>
            Verify Account
          </div>
        )}
      </div>
    </div>
  );
}

export { BankAccountCard };
