/**
 * HTTP Method: POST
 * Pathname: /middleLayer/batch-mark-paid-invoices
 * @function getBatchMarkPaidInvoicesDetails
 * @memberof InvoicesMiddleLayerAPI
 * @param {InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchMarkPaidInvoicesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchMarkPaidInvoicesResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsSearchParams = {
  token?: string | undefined;
};

type InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsBodyParams = {
  invoiceIds: EnderId[];
};

type InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsPayload =
  InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsSearchParams &
    InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsBodyParams;

function getBatchMarkPaidInvoicesDetailsUnsafeEffect(
  payload: InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchMarkPaidInvoicesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchMarkPaidInvoicesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/batch-mark-paid-invoices",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBatchMarkPaidInvoicesDetails(
  payload: InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<BatchMarkPaidInvoicesResponse> {
  return F.pipe(
    payload,
    getBatchMarkPaidInvoicesDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBatchMarkPaidInvoicesDetails };
export type {
  InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsBodyParams,
  InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsPayload,
  InvoicesMiddleLayerAPIGetBatchMarkPaidInvoicesDetailsSearchParams,
};
