import type { TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow } from "@ender/shared/generated/com.ender.middle.response";
import type { CellProps } from "@ender/shared/ui/table-tanstack";

function CheckDepositsPaymentTypeCell(
  props: CellProps<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
) {
  const paymentType = props.getValue();

  return paymentType.includes("Check") ? "Check" : paymentType;
}

export { CheckDepositsPaymentTypeCell };
