/**
 * HTTP Method: POST
 * Pathname: /investors/{firmId}/allocations
 * @function setAllocation
 * @memberof BuyAPI
 * @param {BuyAPISetAllocationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPISetAllocationPathParams = {
  firmId: EnderId;
};

type BuyAPISetAllocationSearchParams = {
  token?: string | undefined;
};

type BuyAPISetAllocationBodyParams = {
  capitalAllocation: SerializesInto<Money>;
  investmentPeriodInDays: number;
  marketId: EnderId;
  minAllocation?: SerializesInto<Percent> | undefined;
};

type BuyAPISetAllocationPayload = BuyAPISetAllocationPathParams &
  BuyAPISetAllocationSearchParams &
  BuyAPISetAllocationBodyParams;

function setAllocationUnsafeEffect(payload: BuyAPISetAllocationPayload) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/investors/${firmId}/allocations`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setAllocation(
  payload: BuyAPISetAllocationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setAllocationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setAllocation };
export type {
  BuyAPISetAllocationBodyParams,
  BuyAPISetAllocationPathParams,
  BuyAPISetAllocationPayload,
  BuyAPISetAllocationSearchParams,
};
