import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const MultiDBQueriesGetInvoicesSortableFieldsValues = [
  "INVOICE_NUMBER",
  "PAYEE",
  "INVOICE_DATE",
  "PROPERTY",
  "FIRM",
  "PAYMENT",
  "AMOUNT",
  "PAID_DATE",
] as const;

const MultiDBQueriesGetInvoicesSortableFieldsEffectSchema = Schema.Literal(
  ...MultiDBQueriesGetInvoicesSortableFieldsValues,
);

type MultiDBQueriesGetInvoicesSortableFields = Schema.Schema.Type<
  typeof MultiDBQueriesGetInvoicesSortableFieldsEffectSchema
>;

const MultiDBQueriesGetInvoicesSortableFieldsEnum = castEnum(
  MultiDBQueriesGetInvoicesSortableFieldsEffectSchema,
);

function randomMultiDBQueriesGetInvoicesSortableFields(): MultiDBQueriesGetInvoicesSortableFields {
  return rand(MultiDBQueriesGetInvoicesSortableFieldsValues);
}

export {
  MultiDBQueriesGetInvoicesSortableFieldsEffectSchema,
  MultiDBQueriesGetInvoicesSortableFieldsEnum,
  MultiDBQueriesGetInvoicesSortableFieldsValues,
  randomMultiDBQueriesGetInvoicesSortableFields,
};
export type { MultiDBQueriesGetInvoicesSortableFields };
