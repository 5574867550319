/**
 * HTTP Method: POST
 * Pathname: /announcements/sendToProspects
 * @function sendProspectAnnouncement
 * @memberof AnnouncementAPI
 * @param {AnnouncementAPISendProspectAnnouncementPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AnnouncementAPISendProspectAnnouncementSearchParams = {
  token?: string | undefined;
};

type AnnouncementAPISendProspectAnnouncementBodyParams = {
  body?: string | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  leadsWithProspects: any[];
  renderBody?: boolean | undefined;
  subject?: string | undefined;
  templateId?: EnderId | undefined;
  useNoReplyEmail?: boolean | undefined;
  useNoReplyPhone?: boolean | undefined;
};

type AnnouncementAPISendProspectAnnouncementPayload =
  AnnouncementAPISendProspectAnnouncementSearchParams &
    AnnouncementAPISendProspectAnnouncementBodyParams;

function sendProspectAnnouncementUnsafeEffect(
  payload: AnnouncementAPISendProspectAnnouncementPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/announcements/sendToProspects",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendProspectAnnouncement(
  payload: AnnouncementAPISendProspectAnnouncementPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendProspectAnnouncementUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendProspectAnnouncement };
export type {
  AnnouncementAPISendProspectAnnouncementBodyParams,
  AnnouncementAPISendProspectAnnouncementPayload,
  AnnouncementAPISendProspectAnnouncementSearchParams,
};
