import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const FireplaceFireplaceTypeValues = ["GAS", "WOOD", "ELECTRIC"] as const;

const FireplaceFireplaceTypeEffectSchema = Schema.Literal(
  ...FireplaceFireplaceTypeValues,
);

type FireplaceFireplaceType = Schema.Schema.Type<
  typeof FireplaceFireplaceTypeEffectSchema
>;

const FireplaceFireplaceTypeEnum = castEnum(FireplaceFireplaceTypeEffectSchema);

function randomFireplaceFireplaceType(): FireplaceFireplaceType {
  return rand(FireplaceFireplaceTypeValues);
}

export {
  FireplaceFireplaceTypeEffectSchema,
  FireplaceFireplaceTypeEnum,
  FireplaceFireplaceTypeValues,
  randomFireplaceFireplaceType,
};
export type { FireplaceFireplaceType };
