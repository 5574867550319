/**
 * Adds a Plaid-connected bank account to an Ender user and their associated "Dwolla Customer Object". Invoker must
 have write-permission. (customerType, customerId) should specify a bank linkable model on our platform. That bank
 linkable model should already have a Dwolla Customer ID associated with it *before* making a call to this API.
 * HTTP Method: POST
 * Pathname: /createPlaidDwollaLinkedBankAccount
 * @function linkPlaidBankAccount
 * @memberof DwollaAPI
 * @param {DwollaAPILinkPlaidBankAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<DwollaAPILinkPlaidBankAccountResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { DwollaAPILinkPlaidBankAccountResponse } from "../dwolla-api-link-plaid-bank-account-response";

type DwollaAPILinkPlaidBankAccountSearchParams = {
  token?: string | undefined;
};

type DwollaAPILinkPlaidBankAccountBodyParams = {
  customerId: EnderId;
  /**
   * is a ModelType. VENDOR, FIRM, USER (for tenants), PROPERTY_MANAGER
   */
  customerType: ModelType;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
  /**
 * Optional. Can be used for customerType: FIRM. If set to true, this means that the Bank Account
                     will be associated with the property management company's Dwolla Customer Account, rather than
                     the Firm's. This is appropriate when the PM controls the bank account and therefore doesn't
                     need the firm to go through KYC.
 */
  linkToPM?: boolean | undefined;
};

type DwollaAPILinkPlaidBankAccountPayload =
  DwollaAPILinkPlaidBankAccountSearchParams &
    DwollaAPILinkPlaidBankAccountBodyParams;

function linkPlaidBankAccountUnsafeEffect(
  payload: DwollaAPILinkPlaidBankAccountPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, DwollaAPILinkPlaidBankAccountResponse>({
        body,
        decode: unsafeDecodeJsonResponse<DwollaAPILinkPlaidBankAccountResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/createPlaidDwollaLinkedBankAccount",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function linkPlaidBankAccount(
  payload: DwollaAPILinkPlaidBankAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<DwollaAPILinkPlaidBankAccountResponse> {
  return F.pipe(
    payload,
    linkPlaidBankAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { linkPlaidBankAccount };
export type {
  DwollaAPILinkPlaidBankAccountBodyParams,
  DwollaAPILinkPlaidBankAccountPayload,
  DwollaAPILinkPlaidBankAccountSearchParams,
};
