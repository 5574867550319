/**
 * HTTP Method: GET
 * Pathname: /middleLayer/leasing/{leaseId}/getRenewalChargeSchedules
 * @function getRenewalChargeSchedules
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIGetRenewalChargeSchedulesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetRenewalChargeScheduleResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetRenewalChargeScheduleResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIGetRenewalChargeSchedulesPathParams = {
  leaseId: EnderId;
};

type LeasingMiddleLayerAPIGetRenewalChargeSchedulesSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIGetRenewalChargeSchedulesPayload =
  LeasingMiddleLayerAPIGetRenewalChargeSchedulesPathParams &
    LeasingMiddleLayerAPIGetRenewalChargeSchedulesSearchParams;

function getRenewalChargeSchedulesUnsafeEffect(
  payload: LeasingMiddleLayerAPIGetRenewalChargeSchedulesPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetRenewalChargeScheduleResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetRenewalChargeScheduleResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/middleLayer/leasing/${leaseId}/getRenewalChargeSchedules`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRenewalChargeSchedules(
  payload: LeasingMiddleLayerAPIGetRenewalChargeSchedulesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetRenewalChargeScheduleResponse[]> {
  return F.pipe(
    payload,
    getRenewalChargeSchedulesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRenewalChargeSchedules };
export type {
  LeasingMiddleLayerAPIGetRenewalChargeSchedulesPathParams,
  LeasingMiddleLayerAPIGetRenewalChargeSchedulesPayload,
  LeasingMiddleLayerAPIGetRenewalChargeSchedulesSearchParams,
};
