import type { ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";

import type { FlexProps } from "../../../flex/src";
import { Direction, Flex } from "../../../flex/src";

type StackProps = Omit<FlexProps, "direction">;

function Stack(
  props: PropsWithChildren<StackProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { children, noWrap = true, ...other } = props;
  return (
    <Flex ref={ref} direction={Direction.vertical} noWrap={noWrap} {...other}>
      {children}
    </Flex>
  );
}
const ForwardedStack = forwardRef<
  HTMLDivElement,
  PropsWithChildren<StackProps>
>(Stack);

export { ForwardedStack as Stack };
export type { StackProps };
