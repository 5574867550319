/**
 * HTTP Method: POST
 * Pathname: /middleLayer/renewals/leases/{leaseId}/createRenewedLease
 * @function createRenewedLease
 * @memberof RenewalsMiddleLayerAPI
 * @param {RenewalsMiddleLayerAPICreateRenewedLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RenewLeaseResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { RenewLeaseResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsMiddleLayerAPICreateRenewedLeasePathParams = {
  leaseId: EnderId;
};

type RenewalsMiddleLayerAPICreateRenewedLeaseSearchParams = {
  token?: string | undefined;
};

type RenewalsMiddleLayerAPICreateRenewedLeaseBodyParams = {
  overrideEndDate?: SerializesInto<LocalDate> | undefined;
  overrideStartDate?: SerializesInto<LocalDate> | undefined;
  overrideWarnings?: boolean | undefined;
};

type RenewalsMiddleLayerAPICreateRenewedLeasePayload =
  RenewalsMiddleLayerAPICreateRenewedLeasePathParams &
    RenewalsMiddleLayerAPICreateRenewedLeaseSearchParams &
    RenewalsMiddleLayerAPICreateRenewedLeaseBodyParams;

function createRenewedLeaseUnsafeEffect(
  payload: RenewalsMiddleLayerAPICreateRenewedLeasePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RenewLeaseResponse>({
        body,
        decode: unsafeDecodeJsonResponse<RenewLeaseResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/middleLayer/renewals/leases/${leaseId}/createRenewedLease`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createRenewedLease(
  payload: RenewalsMiddleLayerAPICreateRenewedLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<RenewLeaseResponse> {
  return F.pipe(
    payload,
    createRenewedLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createRenewedLease };
export type {
  RenewalsMiddleLayerAPICreateRenewedLeaseBodyParams,
  RenewalsMiddleLayerAPICreateRenewedLeasePathParams,
  RenewalsMiddleLayerAPICreateRenewedLeasePayload,
  RenewalsMiddleLayerAPICreateRenewedLeaseSearchParams,
};
