import { useQuery } from "@tanstack/react-query";
import { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { NULL } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { TabButton, TabContent, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type {
  GetApprovalPipelineResponse,
  GetApprovalProcessResponse,
} from "@ender/shared/generated/ender.api.misc.response";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useDocumentTitle } from "@ender/shared/hooks/use-document-title";
import { useSetHeader } from "@ender/shared/hooks/use-set-header";
import { ApprovalProcess } from "@ender/widgets/finance/approval";

import { TitleCompanyApprovalTable } from "./title-company-approval-table";

const approvalProcessType = ApprovalProcessTypeEnum.TITLE_COMPANY;

type TitleCompanyPipelineItem = Omit<GetApprovalPipelineResponse, "model"> & {
  model: {
    id: EnderId;
    name: string;
  };
};

const headerConfig = {
  breadcrumbs: [
    { title: "Buy Properties", href: "/buy" },
    { title: "Title Company Approval Pipeline" },
  ],
};

function TitleCompanyApprovalPipeline() {
  const { hasPermissions, user } = useContext(UserContext);
  const history = useHistory();

  const canEditApprovalProcess = hasPermissions(
    FunctionalPermissionEnum.EDIT_TITLE_COMPANY_APPROVAL_PROCESS,
  );

  const {
    data = [],
    isInitialLoading,
    isError,
    refetch: refetchData,
  } = useQuery<
    [unknown, GetApprovalProcessResponse],
    unknown,
    [TitleCompanyPipelineItem[], GetApprovalProcessResponse]
  >({
    queryFn: async ({ signal }) =>
      await Promise.all([
        ApprovalsAPI.getApprovalPipeline({ approvalProcessType }, { signal }),
        ApprovalsAPI.getDefaultApprovalProcess(
          { approvalProcessType },
          { signal },
        ),
      ]),
    queryKey: [
      "ApprovalsAPI.getApprovalPipeline",
      "ApprovalsAPI.getDefaultApprovalProcess",
      approvalProcessType,
    ] as const,
    select: (data) => [data[0] as TitleCompanyPipelineItem[], data[1]],
  });

  const [pipeline = [], { steps } = { steps: [] }] = data;
  const [tabState, setTabState] = useState<string | EnderId>("all");

  useSetHeader(headerConfig);
  useDocumentTitle("Title Approval - Ender");

  const handleTabChange = useCallback(
    (val: string | EnderId) => {
      setTabState(val);
      history.push("/buy/title-approval-pipeline");
    },
    [history],
  );

  if (isError) {
    return NULL;
  }

  return (
    <Skeleton visible={isInitialLoading}>
      <Tabs value={tabState} onChange={handleTabChange}>
        <TabsList>
          {steps.map(({ id, name }) => (
            <TabButton key={id} value={id}>
              {name}
            </TabButton>
          ))}
          <TabButton key="approved" value="approved">
            Approved
          </TabButton>
          <TabButton key="rejected" value="rejected">
            Rejected
          </TabButton>
          <TabButton key="all" value="all">
            All
          </TabButton>
          {canEditApprovalProcess && (
            <TabButton value="steps">Approval Process</TabButton>
          )}
        </TabsList>
        {steps.map(({ id, approvers }, idx) => (
          <TabContent key={id} value={id}>
            <TitleCompanyApprovalTable
              items={pipeline?.filter(
                ({ currentState }) => currentState.stepId === tabState,
              )}
              needsMyApproval={approvers.some(
                (approver) => approver.id === user.id,
              )}
              isFirstStep={idx === 0}
              stepId={id}
              steps={steps}
              refetchData={refetchData}
            />
          </TabContent>
        ))}
        <TabContent key="approved" value="approved">
          <TitleCompanyApprovalTable
            items={pipeline.filter(
              ({ currentState }) => currentState.isApproved,
            )}
            refetchData={refetchData}
          />
        </TabContent>
        <TabContent key="rejected" value="rejected">
          <TitleCompanyApprovalTable
            items={pipeline.filter(
              ({ currentState }) => currentState.isFullyRejected,
            )}
            refetchData={refetchData}
          />
        </TabContent>
        <TabContent key="all" value="all">
          <TitleCompanyApprovalTable
            items={pipeline}
            refetchData={refetchData}
          />
        </TabContent>
        {canEditApprovalProcess && (
          <TabContent value="steps">
            <ApprovalProcess
              approvalProcessType={approvalProcessType}
              onProcessUpdated={refetchData}
            />
          </TabContent>
        )}
      </Tabs>
    </Skeleton>
  );
}

export { TitleCompanyApprovalPipeline };
