import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import { PartyValues } from "@ender/shared/generated/ender.model.payments";
import { CurrencySchema } from "@ender/shared/utils/zod";

import { ModelTypeSchema, TransferTypeSchema } from "./ender-enums";

const BatchMarkInvoicesAsPaidTransferDetailsSchema = z.object({
  addressId: EnderIdSchema.optional(),
  amount: z.number().or(CurrencySchema).optional(),
  bankAccountId: EnderIdSchema.optional(),
  checkDate: z.string().optional(), // Assuming LocalDate is a string
  checkNumber: z.string().optional(),
  fboId: EnderIdSchema.optional(),
  fboParty: z.enum(PartyValues).optional(),
  invoiceIds: EnderIdSchema.array(),
  ledgerDate: z.string().optional(), // Assuming LocalDate is a string
  memo: z.string().optional(),
  periodId: EnderIdSchema.optional(),
  recipientName: z.string().optional(),
  senderName: z.string().optional(),
  shouldPrint: z.boolean().optional(),
  sourceAccount: EnderIdSchema.optional(),
  tenantToRefund: EnderIdSchema.optional(),
  toBankAccountId: EnderIdSchema.optional(),
  transferType: TransferTypeSchema,
});
type BatchMarkInvoicesAsPaidTransferDetails = z.infer<
  typeof BatchMarkInvoicesAsPaidTransferDetailsSchema
>;

const BatchMarkInvoicesAsPaidPayloadSchema = z.object({
  overrideWarnings: z.boolean().optional(),
  transferDetails: BatchMarkInvoicesAsPaidTransferDetailsSchema.array(),
});
type BatchMarkInvoicesAsPaidPayload = z.infer<
  typeof BatchMarkInvoicesAsPaidPayloadSchema
>;

const BatchMarkInvoicesAsPaidResponseSchema = z.object({
  checkBatchId: EnderIdSchema,
});
type BatchMarkInvoicesAsPaidResponse = z.infer<
  typeof BatchMarkInvoicesAsPaidResponseSchema
>;

const CreateInvoiceBodyJobSchema = z.object({
  id: EnderIdSchema,
  type: ModelTypeSchema,
});
type CreateInvoiceBodyJob = z.infer<typeof CreateInvoiceBodyJobSchema>;

export {
  BatchMarkInvoicesAsPaidPayloadSchema,
  BatchMarkInvoicesAsPaidResponseSchema,
  BatchMarkInvoicesAsPaidTransferDetailsSchema,
  CreateInvoiceBodyJobSchema,
};
export type {
  BatchMarkInvoicesAsPaidPayload,
  BatchMarkInvoicesAsPaidResponse,
  BatchMarkInvoicesAsPaidTransferDetails,
  CreateInvoiceBodyJob,
};
