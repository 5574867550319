import { IconEdit } from "@tabler/icons-react";
import { useContext, useId } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { UserContext } from "@ender/shared/contexts/user";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Tuple } from "@ender/shared/ds/tuple";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";

type MarketRentCardProps = {
  unit: UnitSerializerUnitResponse;
  onEdit?: () => void;
};

function MarketRentCard(props: MarketRentCardProps) {
  const { onEdit, unit } = props;
  const { hasPermissions } = useContext(UserContext);

  const canEditMarketRent = hasPermissions(
    FunctionalPermissionEnum.SET_MARKET_RENT,
  );

  const headingId = useId();

  return (
    <Card labelledBy={headingId}>
      <Group justify={Justify.between}>
        <H3 id={headingId}>Market Rent</H3>
        <ActionIcon
          variant={ButtonVariant.transparent}
          onClick={onEdit}
          disabled={!canEditMarketRent}>
          <IconEdit
            color={
              canEditMarketRent
                ? EnderThemeColorEnum.PRIMARY
                : EnderThemeColorEnum.GRAY_400
            }
          />
        </ActionIcon>
      </Group>
      <Grid spacingY={Spacing.none}>
        <Tuple label="Market Rent" value={unit?.marketRent} />
      </Grid>
    </Card>
  );
}

export { MarketRentCard };
