import type { BaseSelectStylesNames } from "@mantine/core/lib/Select/types";
import type { CSSObject, MantineTheme } from "@mantine/styles";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { InputHeight } from "@ender/shared/types/ender-general";
import { generateInputHeight } from "@ender/shared/types/ender-general";

type SelectStylesOptions = {
  backgroundColor?: string;
  height: InputHeight;
  shouldClear?: boolean;
  showRightSection?: boolean;
  theme?: MantineTheme;
};

const selectStylesGen = (
  props: SelectStylesOptions,
): Partial<Record<BaseSelectStylesNames, CSSObject>> => ({
  ...generateInputHeight(props.height),
  item: {
    "&[data-hovered=true]:not([data-selected=true])": {
      backgroundColor: props.backgroundColor,
      color: EnderThemeColorEnum.PRIMARY,
    },
    borderRadius: "0",
    padding: "10px 16px",
  },
  itemsWrapper: {
    padding: "0",
  },
  rightSection: {
    display: "flex",
    pointerEvents: props.shouldClear ? "all" : "none",
  },
});

// TODO: remove nullity chaining after adding mantine theme to vitest setup
const selectStylesGenTheme =
  (props: SelectStylesOptions) => (theme: MantineTheme) =>
    selectStylesGen({ theme, ...props });

export { selectStylesGen, selectStylesGenTheme };
