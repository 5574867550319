import type { Option as O } from "effect";
import { Function as F } from "effect";
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext, useReducer } from "react";
import { z } from "zod";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type {
  TenantLedgerReportAllocation,
  TenantLedgerReportCategory,
  TenantLedgerReportLedgerEntry,
} from "@ender/shared/generated/ender.arch.accounting";
import { TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum as TenantLedgerEventTypeEnum } from "@ender/shared/generated/ender.arch.accounting";
import { CategoryFlagEnum } from "@ender/shared/generated/ender.model.accounting";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import type { TenantLedgerReportResponse } from "@ender/shared/generated/ender.service.reports.builtin";
import { fail } from "@ender/shared/utils/error";
import { convertTitleCaseToSnakeCase } from "@ender/shared/utils/string";
import { castEnum } from "@ender/shared/utils/zod";

const LedgerActionsValues = [
  "FETCH_LEDGER",
  "SET_LEDGER",
  "SET_LEDGER_RIGHT_RAIL_VIEW",
  "SET_SELECTED_LEDGER_EVENT",
  "SET_SELECTED_CATEGORY",
  "CLOSE_RIGHT_RAIL",
] as const;
const LedgerActionsSchema = z.enum(LedgerActionsValues);
type LedgerActionsType = z.infer<typeof LedgerActionsSchema>;

const LedgerActions = castEnum<LedgerActionsType>(LedgerActionsSchema);

const LedgerRightRailViewValues = [
  "CATEGORY",
  "CHARGE",
  "CREDIT",
  "HOUSING_ASSISTANCE_PREPAYMENTS",
  "LEDGER_SUMMARY",
  "NONE",
  "PAYMENT",
  "PREPAYMENT",
  "REFUND",
  "TENANT_REFUND_CLEARING",
] as const;

const LedgerRightRailViewSchema = z.enum(LedgerRightRailViewValues);
type LedgerRightRailView = z.infer<typeof LedgerRightRailViewSchema>;

const LedgerRightRailViewEnum = castEnum<LedgerRightRailView>(
  LedgerRightRailViewSchema,
);

type OptimizedLedgerCategory = Omit<
  TenantLedgerReportCategory,
  "endingBalance" | "totalCharges" | "totalCredits" | "totalPayments"
> & {
  endingBalance: Money$.Money;
  isOnLedger: boolean;
  totalCharges: Money$.Money;
  totalCredits: Money$.Money;
  totalPayments: Money$.Money;
};

type OptimizedLedgerEventAllocation = Omit<
  TenantLedgerReportAllocation,
  "amount" | "effectOnCategoryBalance" | "runningBalanceForCategory"
> & {
  amount: O.Option<Money$.Money>;
  effectOnCategoryBalance: Money$.Money;
  runningBalanceForCategory: Money$.Money;
};

type OptimizedLedgerEvent = Pick<
  TenantLedgerReportLedgerEntry,
  | "accountingDate"
  | "authorDisplay"
  | "canBeReallocated"
  | "chargePaidAmount"
  | "chargePaidStatus"
  | "description"
  | "effectOnLedgerBalance"
  | "generalLedgerDate"
  | "id"
  | "isAutopayment"
  | "isChargeReversal"
  | "isCreditReversal"
  | "isReversedCharge"
  | "isReversedCredit"
  | "ledgerEventType"
  | "payeeName"
  | "pmId"
  | "runningBalanceForLedger"
  | "source"
  | "specificLedgerDate"
  | "stripeFee"
  | "systemDate"
  | "tenantLedgerEventType"
  | "timestamp"
> & {
  allocations: OptimizedLedgerEventAllocation[];
  amount: O.Option<Money$.Money>;
  displayDescription: string;
  isReversed?: boolean;
  type?: MoneyTransferTransferType;
};

type LedgerContextValue = {
  categories: TenantLedgerReportLedgerEntry[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: (action: { type: LedgerActionsType; payload: any }) => void;
  endingBalance: Money$.Money | Null;
  endingHousingAssistancePrepaymentsBalance: Money$.Money | Null;
  endingPrepaymentsBalance: Money$.Money | Null;
  endingRefundClearingBalance: Money$.Money | Null;
  isLedgerItemEditing: boolean;
  ledgerEvents: OptimizedLedgerEvent[];
  ledgerRightRailView: LedgerRightRailView;
  ledgerState: string;
  prepaymentCategory: TenantLedgerReportLedgerEntry | Null;
  selectedLedgerCategory: EnderId | Null;
  selectedLedgerEvent: TenantLedgerReportLedgerEntry | Null;
  totalCharges: Money$.Money | Null;
  totalPayments: Money$.Money | Null;
};

const initialState: LedgerContextValue = {
  categories: [],
  dispatch: F.constVoid,
  endingBalance: NULL,
  endingHousingAssistancePrepaymentsBalance: NULL,
  endingPrepaymentsBalance: NULL,
  endingRefundClearingBalance: NULL,
  isLedgerItemEditing: false,
  ledgerEvents: [],
  ledgerRightRailView: LedgerRightRailViewEnum.NONE,
  ledgerState: "INITIAL",
  prepaymentCategory: NULL,
  selectedLedgerCategory: NULL,
  selectedLedgerEvent: NULL,
  totalCharges: NULL,
  totalPayments: NULL,
};

const LedgerContext = createContext<LedgerContextValue>(initialState);

function addDataToChargeCategories(
  categories: OptimizedLedgerCategory[],
  allocations: OptimizedLedgerEventAllocation[],
) {
  allocations.forEach(({ glCategoryId }) => {
    const category = categories.find(({ id }) => id === glCategoryId);
    if (!category) {
      return;
    }

    category.isOnLedger = true;
  });
}

function getLedgerEventDisplayDescription(
  ledgerEvent: TenantLedgerReportLedgerEntry,
) {
  if (ledgerEvent.description) {
    return ledgerEvent.description;
  }

  if (ledgerEvent.allocations.length > 0) {
    return ledgerEvent.allocations[0].description;
  }

  return "";
}

/**
 * Sort tenant ledger events in chronological order descending, and wrap amounts in Currency objects.
 */
function optimizeLedgerResponseData(
  ledgerResponseData: TenantLedgerReportResponse,
  user: User,
) {
  const isPM = user.isPM;
  const optimizedLedgerEvents: OptimizedLedgerEvent[] = [];
  const {
    categories,
    endingHousingAssistancePrepaymentsBalance,
    endingPrepaymentsBalance,
    endingRefundClearingBalance,
    ledgerEvents,
    numCharges,
    numCredits,
    totalCharges,
    totalCredits,
    totalPayments,
  } = ledgerResponseData;

  const optimizedCategories: OptimizedLedgerCategory[] = [];
  if (isPM) {
    categories.forEach((category) => {
      optimizedCategories.push({
        ...category,
        endingBalance: Money$.of(category.endingBalance),
        isOnLedger: false,
        totalCharges: Money$.of(category.totalCharges),
        totalCredits: Money$.of(category.totalCredits),
        totalPayments: Money$.of(category.totalPayments),
      });
    });
  }

  ledgerEvents.forEach((ledgerEvent) => {
    const optimizedLedgerEvent: OptimizedLedgerEvent = {
      ...ledgerEvent,
      allocations: ledgerEvent.allocations.reverse().map((allocation) => ({
        ...allocation,
        amount: Money$.parse(allocation.effectOnCategoryBalance),
        effectOnCategoryBalance: Money$.of(allocation.effectOnCategoryBalance),
        runningBalanceForCategory: Money$.of(
          allocation.runningBalanceForCategory,
        ),
      })),
      amount: Money$.parse(ledgerEvent.actualEffectOnLedgerBalance),
      displayDescription: getLedgerEventDisplayDescription(ledgerEvent),
    };

    if (isPM) {
      addDataToChargeCategories(
        optimizedCategories,
        optimizedLedgerEvent.allocations,
      );
    }

    optimizedLedgerEvents.push(optimizedLedgerEvent);
  });

  return {
    categories: optimizedCategories,
    endingBalance: Money$.of(ledgerResponseData.endingBalance),
    endingHousingAssistancePrepaymentsBalance: Money$.of(
      endingHousingAssistancePrepaymentsBalance,
    ),
    endingPrepaymentsBalance: Money$.of(endingPrepaymentsBalance),
    endingRefundClearingBalance: Money$.of(endingRefundClearingBalance),
    ledgerEvents: optimizedLedgerEvents,
    numCharges: Object.entries(numCharges).map(([categoryId, count]) => ({
      categoryId,
      count,
    })),
    numCredits: Object.entries(numCredits).map(([categoryId, count]) => ({
      categoryId,
      count,
    })),
    tenantOutstandingBalance: ledgerResponseData.tenantOutstandingBalance,
    totalCharges: Money$.of(totalCharges),
    totalCredits: Money$.of(totalCredits),
    totalPayments: Money$.of(totalPayments),
  };
}

function ledgerReducer(
  state: LedgerContextValue,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { payload: any; type: LedgerActionsType },
) {
  const { type, payload } = action;
  switch (type) {
    case LedgerActions.SET_LEDGER: {
      let selectedLedgerEvent = state.selectedLedgerEvent;
      if (payload.ledgerState === "LOADED" && state.selectedLedgerEvent) {
        selectedLedgerEvent = payload.ledgerEvents.find(
          ({ id }: { id: EnderId }) => id === state.selectedLedgerEvent?.id,
        );
      }

      return {
        ...state,
        ...payload,
        selectedLedgerEvent,
      };
    }

    case LedgerActions.SET_SELECTED_LEDGER_EVENT: {
      let ledgerRightRailView: LedgerRightRailView =
        LedgerRightRailViewEnum.NONE;

      if (payload?.tenantLedgerEventType === TenantLedgerEventTypeEnum.CHARGE) {
        ledgerRightRailView = LedgerRightRailViewEnum.CHARGE;
      } else if (payload) {
        ledgerRightRailView = LedgerRightRailViewEnum.PAYMENT;
      }

      return {
        ...state,
        ledgerRightRailView,
        selectedLedgerEvent: payload,
      };
    }

    case LedgerActions.SET_LEDGER_RIGHT_RAIL_VIEW: {
      return {
        ...state,
        ledgerRightRailView: payload,
        selectedLedgerEvent: null,
      };
    }

    case LedgerActions.CLOSE_RIGHT_RAIL: {
      return {
        ...state,
        ledgerRightRailView: LedgerRightRailViewEnum.NONE,
        selectedLedgerCategory: null,
        selectedLedgerEvent: null,
      };
    }

    case LedgerActions.SET_SELECTED_CATEGORY: {
      return {
        ...state,
        ledgerRightRailView: LedgerRightRailViewEnum.CATEGORY,
        selectedLedgerCategory: payload,
      };
    }

    default: {
      throw new Error(type);
    }
  }
}

function LedgerProvider(props: PropsWithChildren) {
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(ledgerReducer, initialState);

  const asyncDispatch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (action: { payload: any; type: LedgerActionsType }) => {
      if (action.type === LedgerActions.FETCH_LEDGER) {
        dispatch({
          payload: {
            ledgerState: "FETCHING",
          },
          type: LedgerActions.SET_LEDGER,
        });

        try {
          const ledgerRes = await TenantLedgerAPI.getTenantLedger(
            action.payload,
          );

          const {
            categories,
            endingBalance,
            endingHousingAssistancePrepaymentsBalance,
            endingPrepaymentsBalance,
            endingRefundClearingBalance,
            ledgerEvents,
            numCharges,
            numCredits,
            totalCharges,
            totalCredits,
            totalPayments,
          } = optimizeLedgerResponseData(ledgerRes, user);

          const prepaymentCategory = categories.find(({ flags }) =>
            flags.some(
              (flag) =>
                convertTitleCaseToSnakeCase(flag) ===
                CategoryFlagEnum.PREPAID_RENT,
            ),
          );

          dispatch({
            payload: {
              categories,
              endingBalance,
              endingHousingAssistancePrepaymentsBalance,
              endingPrepaymentsBalance,
              endingRefundClearingBalance,
              ledgerEvents,
              ledgerState: "LOADED",
              numCharges,
              numCredits,
              prepaymentCategory,
              totalCharges,
              totalCredits,
              totalPayments,
            },
            type: LedgerActions.SET_LEDGER,
          });
        } catch (err) {
          fail(err);
          dispatch({
            payload: {
              categories: [],
              endingBalance: null,
              endingHousingAssistancePrepaymentsBalance: null,
              endingPrepaymentsBalance: null,
              endingRefundClearingBalance: null,
              ledgerEvents: [],
              ledgerState: "FAIL",
              totalChargesAmount: null,
              totalPaymentsAmount: null,
            },
            type: LedgerActions.SET_LEDGER,
          });
        }

        return;
      }
      dispatch(action);
    },
    [user],
  );

  return (
    <LedgerContext.Provider value={{ ...state, dispatch: asyncDispatch }}>
      {props.children}
    </LedgerContext.Provider>
  );
}

export {
  LedgerActions,
  LedgerActionsValues,
  LedgerContext,
  LedgerProvider,
  LedgerRightRailViewEnum,
  LedgerRightRailViewSchema,
  optimizeLedgerResponseData,
  TenantLedgerEventTypeEnum,
};
export type {
  LedgerRightRailView,
  OptimizedLedgerCategory,
  OptimizedLedgerEvent,
  OptimizedLedgerEventAllocation,
};
