/**
 * HTTP Method: POST
 * Pathname: /moneyTransfers/search
 * @function searchMoneyTransfers
 * @memberof PaymentsAPI
 * @param {PaymentsAPISearchMoneyTransfersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransferSerializerMoneyTransferResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { MoneyTransferSerializerMoneyTransferResponse } from "@ender/shared/generated/ender.arch.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPISearchMoneyTransfersSearchParams = {
  token?: string | undefined;
};

type PaymentsAPISearchMoneyTransfersBodyParams = {
  leaseId: EnderId;
  propertyId?: EnderId | undefined;
  type: string;
};

type PaymentsAPISearchMoneyTransfersPayload =
  PaymentsAPISearchMoneyTransfersSearchParams &
    PaymentsAPISearchMoneyTransfersBodyParams;

function searchMoneyTransfersUnsafeEffect(
  payload: PaymentsAPISearchMoneyTransfersPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransferSerializerMoneyTransferResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<MoneyTransferSerializerMoneyTransferResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/moneyTransfers/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchMoneyTransfers(
  payload: PaymentsAPISearchMoneyTransfersPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransferSerializerMoneyTransferResponse> {
  return F.pipe(
    payload,
    searchMoneyTransfersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchMoneyTransfers };
export type {
  PaymentsAPISearchMoneyTransfersBodyParams,
  PaymentsAPISearchMoneyTransfersPayload,
  PaymentsAPISearchMoneyTransfersSearchParams,
};
