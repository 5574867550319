import { Function as F, Option as O, Record as R } from "effect";
import { isNotNullable } from "effect/Predicate";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import type { EnderId, LocalDate, Money$ } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyInput } from "@ender/shared/ds/money-input";
import type { SelectOption } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import type { MinimalPropertyResponse } from "@ender/shared/generated/ender.api.model";
import { FirmFirmTypeEnum } from "@ender/shared/generated/ender.model.core";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import {
  DEFAULT_ACCOUNTING_END_DATE,
  DEFAULT_ACCOUNTING_START_DATE,
  useAccountingFiltersStore,
} from "@ender/shared/stores/accounting-filters-store";
import type { UseTableReturn } from "@ender/shared/ui/table-tanstack";
import { AccountingPeriodFilter } from "@ender/widgets/filters/accounting-period-filter";
import { FirmFilter } from "@ender/widgets/filters/firm-filter";
import { PropertyFilter } from "@ender/widgets/filters/property-filter";

import {
  getFilterValueFromState,
  setColumnFilter,
} from "../../../../shared/utils/accounting-utils";
import { DateFilters } from "../../options/date-filters";
import type {
  CheckReceiptsTableMeta,
  CheckReceiptsTableRow,
} from "./check-receipts-table.types";

type InvoiceAmountFilterProps = {
  value: O.Option<Money$.Money>;
  setValue: (value: O.Option<Money$.Money>) => void;
};

function InvoiceAmountFilter({ value, setValue }: InvoiceAmountFilterProps) {
  const [internalAmount, setInternalAmount] =
    useState<O.Option<Money$.Money>>(value);

  // MANAGE STORE VALUE ON DEBOUNCE | This prevents updating filter data onKeyPress
  const onDebounceChange = useDebounce((val: O.Option<Money$.Money>) => {
    setValue(val);
  }, 750);

  // MANAGE INTERNAL VALUE STATE | This allow for the filter input to update correctly
  const onAmountChange = useCallback(
    (val: O.Option<Money$.Money>) => {
      setInternalAmount(val);
      onDebounceChange(val);
    },
    [onDebounceChange],
  );

  const updateInternalState = useCallback(
    (val: O.Option<Money$.Money>) => {
      if (O.getOrNull(val) === O.getOrNull(internalAmount)) {
        return;
      }

      setInternalAmount(val);
    },
    [internalAmount],
  );

  // Update the local state when the external `value` prop changes
  useEffect(() => {
    updateInternalState(value);
  }, [updateInternalState, value]);

  return (
    <MoneyInput
      label="Invoice Amount"
      placeholder="Search amount"
      value={internalAmount}
      onChange={onAmountChange}
    />
  );
}

const paymentTypesList = [
  { label: "ACH", value: MoneyTransferTransferTypeEnum.DWOLLA_TRANSFER },
  { label: "Credit Card", value: MoneyTransferTransferTypeEnum.STRIPE_PAYMENT },
  { label: "Check", value: MoneyTransferTransferTypeEnum.MARK_PAID_CHECK },
];

type PaymentTypeFilterProps = {
  value: O.Option<MoneyTransferTransferType>;
  setValue: (value: O.Option<MoneyTransferTransferType>) => void;
};

function PaymentTypeFilter({ value, setValue }: PaymentTypeFilterProps) {
  return (
    <Select
      label="Payment Type"
      placeholder="Filter by Payment Type"
      data={paymentTypesList}
      onChange={setValue}
      value={value}
      clearable
    />
  );
}

function CheckReceiptsTableFiltersMedium(props: {
  table: UseTableReturn<CheckReceiptsTableRow, CheckReceiptsTableMeta>;
}): ReactNode {
  const {
    updateAccountingPropertyFilter,
    updateAmount,
    updateEndDate,
    updateFirmFilter,
    updateFundFilter,
    updatePaymentType,
    updatePeriodFilter,
    updateStartDate,
  } = useAccountingFiltersStore();
  const { table } = props;
  const { columnFilters: columnFiltersState } = table.getState();
  const setColumnFiltersState = table.options.onColumnFiltersChange;

  const columnIdToFilterState: Record<string, unknown> = useMemo(() => {
    return F.pipe(
      columnFiltersState,
      R.fromIterableWith(({ id, value }) => [id, value]),
    );
  }, [columnFiltersState]);
  const accountingPeriod = useMemo(
    () =>
      getFilterValueFromState<LocalDate[]>("period", columnIdToFilterState, []),
    [columnIdToFilterState],
  );
  const setAccountingPeriod = useCallback(
    (value: LocalDate$.LocalDate[]) => {
      updatePeriodFilter(value.map((period) => period.toJSON()));
      setColumnFiltersState?.(
        setColumnFilter(
          "period",
          value.map((period) => period.toJSON()),
        ),
      );
    },
    [setColumnFiltersState, updatePeriodFilter],
  );
  const amountFilterValue = useMemo(() => {
    return O.fromNullable(
      getFilterValueFromState<Money$.Money | Null>(
        "amount",
        columnIdToFilterState,
        NULL,
      ),
    );
  }, [columnIdToFilterState]);
  const setAmountFilterValue = useCallback(
    (value: O.Option<Money$.Money>) => {
      updateAmount(O.getOrNull(value));
      setColumnFiltersState?.(setColumnFilter("amount", O.getOrNull(value)));
    },
    [setColumnFiltersState, updateAmount],
  );
  const receiptEndDate = useMemo(
    () =>
      getFilterValueFromState<LocalDate>(
        "receiptEndDate",
        columnIdToFilterState,
        DEFAULT_ACCOUNTING_END_DATE,
      ),
    [columnIdToFilterState],
  );
  const setReceiptEndDate = useCallback(
    (value: LocalDate) => {
      updateEndDate(value);
      setColumnFiltersState?.(setColumnFilter("receiptEndDate", value));
    },
    [setColumnFiltersState, updateEndDate],
  );
  const receiptStartDate = useMemo(
    () =>
      getFilterValueFromState<LocalDate>(
        "receiptStartDate",
        columnIdToFilterState,
        DEFAULT_ACCOUNTING_START_DATE,
      ),
    [columnIdToFilterState],
  );
  const setReceiptStartDate = useCallback(
    (value: LocalDate) => {
      updateStartDate(value);
      setColumnFiltersState?.(setColumnFilter("receiptStartDate", value));
    },
    [setColumnFiltersState, updateStartDate],
  );

  const paymentTypeFilterValue = useMemo(
    () =>
      O.fromNullable(
        getFilterValueFromState<MoneyTransferTransferType | Null>(
          "paymentType",
          columnIdToFilterState,
          NULL,
        ),
      ),
    [columnIdToFilterState],
  );
  const setPaymentTypeFilterValue = useCallback(
    (value: O.Option<MoneyTransferTransferType>) => {
      updatePaymentType(O.getOrNull(value));
      setColumnFiltersState?.(
        setColumnFilter("paymentType", O.getOrNull(value)),
      );
    },
    [setColumnFiltersState, updatePaymentType],
  );

  const propertyFilterValue = useMemo(() => {
    const filter = getFilterValueFromState<MinimalPropertyResponse[]>(
      "propertyName",
      columnIdToFilterState,
      [],
    );
    return filter.map((property) => ({
      label: property.name,
      meta: property,
      value: property.id,
    }));
  }, [columnIdToFilterState]);

  const firmFilterValue = useMemo(
    () => getFilterValueFromState<EnderId[]>("firm", columnIdToFilterState, []),
    [columnIdToFilterState],
  );
  const fundFilterValue = useMemo(
    () => getFilterValueFromState<EnderId[]>("fund", columnIdToFilterState, []),
    [columnIdToFilterState],
  );

  const selectFirms = useCallback(
    (value: EnderId[]) => {
      updateFirmFilter(value);
      setColumnFiltersState?.(setColumnFilter("firm", value));
    },
    [setColumnFiltersState, updateFirmFilter],
  );

  const selectFunds = useCallback(
    (value: EnderId[]) => {
      updateFundFilter(value);
      setColumnFiltersState?.(setColumnFilter("fund", value));
    },
    [setColumnFiltersState, updateFundFilter],
  );

  const setPropertyFilterValue = useCallback(
    (properties: SelectOption<EnderId, MinimalPropertyResponse>[]) => {
      const selectedProperties: MinimalPropertyResponse[] = properties.reduce<
        MinimalPropertyResponse[]
      >((acc, prop) => {
        if (isNotNullable(prop?.meta)) {
          acc.push(prop.meta);
        }
        return acc;
      }, []);
      setColumnFiltersState?.(
        setColumnFilter("propertyName", selectedProperties),
      );
      updateAccountingPropertyFilter(selectedProperties);
    },
    [setColumnFiltersState, updateAccountingPropertyFilter],
  );
  return (
    <Stack>
      <Group align={Align.end}>
        <FirmFilter
          onChange={selectFunds}
          value={fundFilterValue}
          firmType={FirmFirmTypeEnum.FUND}
        />
        <FirmFilter onChange={selectFirms} value={firmFilterValue} />
        <PropertyFilter
          value={propertyFilterValue}
          onChange={setPropertyFilterValue}
        />
        <InvoiceAmountFilter
          value={amountFilterValue}
          setValue={setAmountFilterValue}
        />
        <PaymentTypeFilter
          value={paymentTypeFilterValue}
          setValue={setPaymentTypeFilterValue}
        />
      </Group>
      <Group align={Align.end}>
        <AccountingPeriodFilter
          value={accountingPeriod.map((period) => LocalDate$.of(period))}
          onChange={setAccountingPeriod}
        />
        <DateFilters
          label="Receipt Date"
          endDate={receiptEndDate}
          startDate={receiptStartDate}
          updateEndDate={(value) => setReceiptEndDate(value as LocalDate)}
          updateStartDate={(value) => setReceiptStartDate(value as LocalDate)}
        />
      </Group>
    </Stack>
  );
}

export { CheckReceiptsTableFiltersMedium };
