import { Predicate as P } from "effect";

import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";

type PossibleInvoiceActions = {
  canMarkPaid: boolean;
  canPayByACH: boolean;
  canPrintCheck: boolean;
  markPaidMessage: string;
  payByACHMessage: string;
  printCheckMessage: string;
  showApproveButton: boolean;
  showMarkPaidButton: boolean;
  showPayByACHButton: boolean;
  showPrintCheckButton: boolean;
  showRejectButton: boolean;
  showReprintCheckButton: boolean;
};

const INITIAL_POSSIBLE_INVOICE_ACTIONS: PossibleInvoiceActions = {
  canMarkPaid: false,
  canPayByACH: false,
  canPrintCheck: false,
  markPaidMessage: "",
  payByACHMessage: "",
  printCheckMessage: "",
  showApproveButton: false,
  showMarkPaidButton: false,
  showPayByACHButton: false,
  showPrintCheckButton: false,
  showRejectButton: false,
  showReprintCheckButton: false,
} as const;

type GetPossibleInvoiceActionsParams = {
  hasEditInvoiceAllocationsPermission: boolean;
  hasInstantApprovalPermission: boolean;
  invoice?: InvoiceSerializerInvoiceResponse;
  needsMyApproval: boolean;
};

function getPossibleInvoiceActions({
  hasInstantApprovalPermission,
  hasEditInvoiceAllocationsPermission,
  invoice,
  needsMyApproval,
}: GetPossibleInvoiceActionsParams): PossibleInvoiceActions {
  const possibilities = { ...INITIAL_POSSIBLE_INVOICE_ACTIONS };

  if (P.isNullable(invoice)) {
    return possibilities;
  }
  // Invoice is defined...

  possibilities.payByACHMessage = invoice.payByACHMessage;
  possibilities.printCheckMessage = invoice.printCheckMessage;
  possibilities.markPaidMessage = invoice.markPaidMessage;

  const { currentState, invoiceType, paymentInfo } = invoice;
  const { isApproved, isLastStep, isFullyRejected } = currentState || {};

  // already paid by check, show Re-print button
  if (
    paymentInfo?.type === MoneyTransferTransferTypeEnum.PRINT_CHECK &&
    paymentInfo?.pdfUrl
  ) {
    possibilities.showReprintCheckButton = true;
  }

  possibilities.showMarkPaidButton = hasInstantApprovalPermission;
  possibilities.canMarkPaid =
    invoice.markPaidMessage === "" && hasInstantApprovalPermission;

  if (
    isApproved ||
    isFullyRejected ||
    !hasEditInvoiceAllocationsPermission ||
    !needsMyApproval
  ) {
    return possibilities;
  }
  // Needs My Approval...

  possibilities.showRejectButton = true;

  if (!isLastStep) {
    possibilities.showApproveButton = true;
    return possibilities;
  }
  // Invoice is on last step...

  possibilities.showMarkPaidButton = true;
  possibilities.canMarkPaid = invoice.markPaidMessage === "";

  if (invoiceType !== InvoiceInvoiceTypeEnum.PAYABLE) {
    return possibilities;
  }
  // Invoice is Payable...

  possibilities.showPayByACHButton = true;
  possibilities.showPrintCheckButton = true;
  possibilities.canPayByACH = invoice.payByACHMessage === "";
  possibilities.canPrintCheck = invoice.printCheckMessage === "";

  return possibilities;
}

export { getPossibleInvoiceActions };
