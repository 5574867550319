/**
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}/connectToDwollaWithMicrodeposits
 * @function connectBankToDwollaWithMicrodeposits
 * @memberof BankingAPI
 * @param {BankingAPIConnectBankToDwollaWithMicrodepositsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIConnectBankToDwollaWithMicrodepositsPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIConnectBankToDwollaWithMicrodepositsSearchParams = {
  token?: string | undefined;
};

type BankingAPIConnectBankToDwollaWithMicrodepositsPayload =
  BankingAPIConnectBankToDwollaWithMicrodepositsPathParams &
    BankingAPIConnectBankToDwollaWithMicrodepositsSearchParams;

function connectBankToDwollaWithMicrodepositsUnsafeEffect(
  payload: BankingAPIConnectBankToDwollaWithMicrodepositsPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}/connectToDwollaWithMicrodeposits`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function connectBankToDwollaWithMicrodeposits(
  payload: BankingAPIConnectBankToDwollaWithMicrodepositsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    connectBankToDwollaWithMicrodepositsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { connectBankToDwollaWithMicrodeposits };
export type {
  BankingAPIConnectBankToDwollaWithMicrodepositsPathParams,
  BankingAPIConnectBankToDwollaWithMicrodepositsPayload,
  BankingAPIConnectBankToDwollaWithMicrodepositsSearchParams,
};
