import type { FormErrors } from "@mantine/form";
import { zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { Array as A, Predicate as P, String as S } from "effect";
import { useCallback, useMemo } from "react";
import { z } from "zod";

import { LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { useForm } from "@ender/shared/forms/hooks/general";
import { EnderInputText } from "@ender/shared/forms/ui/ender-input-text";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  LeaseSerializerLeaseContact,
  LeaseSerializerLeaseResponse,
} from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { cast } from "@ender/shared/types/cast";
import { EnderDatePicker } from "@ender/shared/ui/ender-date-picker";
import { EnderInputWrapper } from "@ender/shared/ui/ender-input-wrapper";
import { SsnInput } from "@ender/shared/ui/ssn-input";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";
import { isFinanciallyResponsibleContact } from "@ender/shared/utils/lease";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { isPossiblePhoneNumber } from "@ender/shared/utils/phone";
import { convertTitleCaseToSnakeCase } from "@ender/shared/utils/string";

const SSN_REDACTED = "<REDACTED>";

function isContactMinor(
  contact: LeaseSerializerLeaseContact | undefined,
): boolean {
  if (P.isNotNullable(contact?.birthday)) {
    return LocalDate$.of(contact.birthday).isAfter(
      LocalDate$.today().add({ years: -18 }),
    );
  }

  return false;
}

function getTotalFinanciallyResponsible(
  leaseContacts: LeaseSerializerLeaseContact[],
): number {
  return (
    leaseContacts.reduce(
      (total, c) =>
        A.contains(
          c.roles,
          LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
        )
          ? total + 1
          : total,
      0,
    ) || 0
  );
}

const LeaseContactFormRolesSchema = z
  .object({
    [LeaseUserRoleLeaseUserFlagEnum.PRIMARY_CONTACT]: z.boolean(),
    [LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE]: z.boolean(),
    [LeaseUserRoleLeaseUserFlagEnum.TENANT]: z.boolean(),
    [LeaseUserRoleLeaseUserFlagEnum.HOUSING_CHOICE_VOUCHER]: z.boolean(),
  })
  .refine(
    (values) => {
      return Object.entries(values).some(
        ([role, isChecked]) =>
          isChecked &&
          A.contains(
            [
              LeaseUserRoleLeaseUserFlagEnum.PRIMARY_CONTACT,
              LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
              LeaseUserRoleLeaseUserFlagEnum.TENANT,
            ],
            role,
          ),
      );
    },
    {
      message: `At least one role of type "Primary Contact", "Financially Responsible", or "Tenant" must be selected`,
    },
  );
type LeaseContactFormRoles = z.infer<typeof LeaseContactFormRolesSchema>;

const LeaseContactFormPartialSchema = z.object({
  birthday: z
    .union([z.date(), z.instanceof(EnderDate), z.undefined()])
    .refine((b) => Boolean(b), { message: "Required" }),
  roles: LeaseContactFormRolesSchema,
});

const LeaseContactFormSchema = z.intersection(
  z.object({
    driversLicense: z
      .string()
      .regex(
        /^[0-9a-zA-Z.-]*$/,
        "Only alphanumeric characters and hyphens allowed",
      ),
    email: z.string().email().or(z.literal("")),
    firstName: z.string().min(2, "First Name must be at least two characters"),
    lastName: z.string().min(2, "Last Name must be at least two characters"),
    phone: z
      .string()
      .refine(isPossiblePhoneNumber, "Must be a valid phone number")
      .or(z.literal(""))
      .nullish(),
    ssn: z
      .string()
      .refine((value) => /^\d{3}-\d{2}-\d{4}$/.test(value), {
        message: "SSN must contain 9 numbers and be in XXX-XX-XXXX format",
      })
      .or(z.literal("")),
  }),
  LeaseContactFormPartialSchema,
);

type LeaseContactFormValues = z.infer<typeof LeaseContactFormSchema>;

function transformValues(values: LeaseContactFormValues) {
  return {
    ...values,
    birthday: EnderDate.of(values.birthday).toLocalISOString(),
    roles: Object.entries(values.roles)
      .filter(([, val]) => val)
      .map(([key]) => convertTitleCaseToSnakeCase(key)),
    phone: values.phone || "",
  };
}

function initializeFormValuesByExistingUserOrContact(
  contact: User | LeaseSerializerLeaseContact | undefined,
  isNewContactMinor: boolean | undefined,
  newContactEmail: string | undefined,
): LeaseContactFormValues {
  const roles: LeaseContactFormRoles = {
    [LeaseUserRoleLeaseUserFlagEnum.PRIMARY_CONTACT]: false,
    [LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE]: false,
    [LeaseUserRoleLeaseUserFlagEnum.TENANT]: false,
    [LeaseUserRoleLeaseUserFlagEnum.HOUSING_CHOICE_VOUCHER]: false,
  } as const;

  if (isNewContactMinor) {
    roles[LeaseUserRoleLeaseUserFlagEnum.TENANT] = true;
  } else if (contact && "roles" in contact) {
    contact.roles.forEach((role) => {
      // @ts-expect-error
      roles[role] = true;
    });
  }

  return {
    firstName: contact?.firstName || "",
    lastName: contact?.lastName || "",
    birthday: contact?.birthday ? EnderDate.of(contact.birthday) : undefined,
    email: contact?.email || newContactEmail || "",
    phone: contact?.phone || "",
    //@ts-expect-error doesn't matter if driversLicense doesn't exist on User type, we'll just initialize with ""
    driversLicense: contact?.driversLicense || "",
    roles,
    ssn: contact?.ssn && contact.ssn !== SSN_REDACTED ? contact.ssn : "",
  };
}

type CreateUpdateLeaseContactFormProps = {
  contact?: LeaseSerializerLeaseContact;
  isNewContactMinor?: boolean;
  lease: LeaseSerializerLeaseResponse;
  newContactEmail?: string;
  newContactExistingUser?: User;
  onCancel: () => void;
  onSuccess: () => void;
};

function CreateUpdateLeaseContactForm({
  contact,
  isNewContactMinor,
  lease,
  newContactEmail,
  newContactExistingUser,
  onCancel,
  onSuccess,
}: CreateUpdateLeaseContactFormProps) {
  const [isMinor, isMinorHandlers] = useBoolean(
    isNewContactMinor || isContactMinor(contact),
  );
  const form = useForm<LeaseContactFormValues>({
    initialValues: initializeFormValuesByExistingUserOrContact(
      newContactExistingUser || contact,
      isNewContactMinor,
      newContactEmail,
    ),
    validate: (values: LeaseContactFormValues): FormErrors => {
      const result = zodResolver(LeaseContactFormSchema)(values);
      if (
        !result.roles &&
        contact &&
        !values.roles[LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE] &&
        isFinanciallyResponsibleContact(lease, contact) &&
        getTotalFinanciallyResponsible(cast(lease.contacts)) < 2
      ) {
        result.roles =
          "At least one financially responsible individual must be on a lease at all times";
      }

      return result;
    },
  });

  const { mutate: onSubmit } = useMutation({
    mutationFn: async (values: LeaseContactFormValues) => {
      const { roles, ...userValues } = transformValues(values);

      if (contact) {
        await UsersAPI.updateUser({
          json: userValues,
          targetUserId: contact.id,
        });
        await LeasingAPI.setLeaseContactRoles({
          leaseId: lease.id,
          roles: cast(roles),
          tenantId: contact.id,
        });
        return;
      }

      let newContact;

      if (newContactExistingUser) {
        newContact = newContactExistingUser;
        await UsersAPI.updateUser({
          json: userValues,
          targetUserId: newContactExistingUser.id,
        });
        await LeasingAPI.addLeaseContact({
          leaseId: lease.id,
          tenantId: newContactExistingUser.id,
        });
      } else {
        newContact = await LeasingAPI.addLeaseContact({
          leaseId: lease.id,
          ...userValues,
          birthday: cast(userValues.birthday),
        });
      }

      if (A.isNonEmptyArray(roles)) {
        await LeasingAPI.setLeaseContactRoles({
          leaseId: lease.id,
          roles: cast(roles),
          tenantId: newContact.id,
        });
      }
    },
    mutationKey: [
      "createEditLeaseContact",
      { contactId: contact?.id, leaseId: lease.id },
    ] as const,
    onError: (err) => fail(err),
    onSuccess: () => {
      onSuccess();
      showSuccessNotification({
        message: `Lease contact ${contact ? "updated" : "added"}`,
      });
    },
  });

  const onBirthdayChange = useCallback(
    (value?: Date | null) => {
      form.setFieldValue("birthday", value || undefined);
    },
    [form],
  );

  const onBirthdayBlur = useCallback(() => {
    const { birthday } = form.values;
    const _isMinor = birthday ? EnderDate.isBirthdayMinor(birthday) : false;
    isMinorHandlers.toggle(_isMinor);
    if (_isMinor) {
      form.setFieldValue("email", "");
      form.setFieldValue("phone", "");
      form.setFieldValue("driversLicense", "");
      form.setFieldValue(
        `roles.${LeaseUserRoleLeaseUserFlagEnum.TENANT}`,
        true,
      );
      form.setFieldValue(
        `roles.${LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE}`,
        false,
      );
      form.setFieldValue(
        `roles.${LeaseUserRoleLeaseUserFlagEnum.PRIMARY_CONTACT}`,
        false,
      );
    }
  }, [form, isMinorHandlers]);

  const birthdayMinDate = useMemo(() => {
    if (!isNewContactMinor) {
      return undefined;
    }

    const date = EnderDate.today;
    date.setFullYear(date.getFullYear() - 18);
    return date;
  }, [isNewContactMinor]);

  const isSSNDisabled =
    S.isNonEmpty(contact?.ssn ?? "") ||
    !form.values.roles[LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE];

  return (
    <UnmanagedForm form={form} onSubmit={onSubmit}>
      <Stack>
        <EnderInputText label="First Name" name="firstName" />
        <EnderInputText label="Last Name" name="lastName" />
        <EnderDatePicker
          label="Date of Birth"
          {...form.getInputProps("birthday")}
          minDate={birthdayMinDate}
          maxDate={EnderDate.today}
          onChange={onBirthdayChange}
          onBlur={onBirthdayBlur}
          defaultValue={null}
        />
        <EnderInputText
          label="Email Address"
          disabled={Boolean(
            newContactExistingUser || isMinor || newContactEmail,
          )}
          name="email"
        />
        <EnderInputText
          label="Phone (optional)"
          disabled={isMinor}
          placeholder="Phone"
          name="phone"
        />
        <EnderInputText
          label="Driver's License # (optional)"
          disabled={isMinor}
          placeholder="Driver's License #"
          name="driversLicense"
        />
        <EnderInputWrapper label="Lease Roles" error={form.errors.roles}>
          <Stack>
            <Checkbox
              label="Primary Contact"
              disabled={isMinor}
              {...form.getInputProps(
                `roles.${LeaseUserRoleLeaseUserFlagEnum.PRIMARY_CONTACT}`,
              )}
              onChange={(val) =>
                form.setFieldValue(
                  `roles.${LeaseUserRoleLeaseUserFlagEnum.PRIMARY_CONTACT}`,
                  val,
                )
              }
            />
            <Checkbox
              label="Tenant"
              disabled={isMinor}
              {...form.getInputProps(
                `roles.${LeaseUserRoleLeaseUserFlagEnum.TENANT}`,
              )}
              onChange={(val) =>
                form.setFieldValue(
                  `roles.${LeaseUserRoleLeaseUserFlagEnum.TENANT}`,
                  val,
                )
              }
            />
            <Checkbox
              label="Financially Responsible"
              disabled={isMinor}
              {...form.getInputProps(
                `roles.${LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE}`,
              )}
              onChange={(val) =>
                form.setFieldValue(
                  `roles.${LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE}`,
                  val,
                )
              }
            />
            <Checkbox
              label="Housing Choice Voucher (HCV)"
              disabled={isMinor}
              {...form.getInputProps(
                `roles.${LeaseUserRoleLeaseUserFlagEnum.HOUSING_CHOICE_VOUCHER}`,
              )}
              onChange={(val) =>
                form.setFieldValue(
                  `roles.${LeaseUserRoleLeaseUserFlagEnum.HOUSING_CHOICE_VOUCHER}`,
                  val,
                )
              }
            />
          </Stack>
        </EnderInputWrapper>
        {form.values.roles[
          LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE
        ] && (
          <EnderInputWrapper
            label="Social Security Number"
            error={form.errors.ssn}>
            <SsnInput
              disabled={isSSNDisabled}
              {...form.getInputProps("ssn", { withError: false })}
              disableToggle={isSSNDisabled}
            />
          </EnderInputWrapper>
        )}
        <Group justify={Justify.between}>
          <Button
            variant={ButtonVariant.transparent}
            type="button"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </UnmanagedForm>
  );
}

export { CreateUpdateLeaseContactForm };
