import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { TextareaProps } from "./shared-ds-textarea";
import { Textarea } from "./shared-ds-textarea";

type FormTextareaProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<TextareaProps, Form, Name>;

function FormTextarea<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormTextareaProps<Form, Name>) {
  const { form, name, ...rest } = props;
  return (
    <FormField name={name} form={form}>
      {({ field, error }) => {
        return <Textarea {...rest} {...field} error={error} />;
      }}
    </FormField>
  );
}

export { FormTextarea };

export type { FormTextareaProps };
