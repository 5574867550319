import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import { RouterLink } from "@ender/shared/ds/router-link";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";

type TenantCellProps = {
  leaseId: EnderId;
};

/**
 * TODO this should be a shared component
 */
function LeaseTenants({ leaseId }: TenantCellProps) {
  const { data: contacts } = useQuery({
    queryFn: ({ signal }) =>
      LeasingAPI.getLeaseContacts({ leaseId }, { signal }),
    queryKey: ["LeasingAPI.getLeaseContacts", leaseId] as const,
  });

  const tenantDisplayName = useMemo(() => {
    if (contacts) {
      const primary =
        contacts.find((contact) =>
          contact.roles.includes(
            LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
          ),
        ) || contacts[0];
      const postFix = contacts.length > 1 ? ` + ${contacts.length - 1}` : "";
      return `${primary.name}${postFix}`;
    }
  }, [contacts]);

  if (P.isNullable(tenantDisplayName)) {
    return "--";
  }

  return (
    <RouterLink target="_blank" href={`/leases/${leaseId}`}>
      {tenantDisplayName}
    </RouterLink>
  );
}

export { LeaseTenants };
