// eslint-disable-next-line ender-rules/deprecated-import-libraries
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { InputWrapperProps } from "@mantine/core";
import { Input } from "@mantine/core";
import type { PropsWithChildren } from "react";



import { ENDER_INPUT_WRAPPER_ORDER } from "@ender/shared/utils/mantine";





type EnderInputWrapperProps = Pick<
  InputWrapperProps,
  "label" | "description" | "descriptionProps" | "error" | "className"
> & {
  htmlFor?: string;
};

/**
 * @name EnderInputWrapper
 * @description Wraps any input with the proper wrapping; Provides a label, error output, etc
 * @todo Import types from Mantine for the component
 * @param {import("@mantine/core").InputWrapperProps & { htmlFor?: string }} props
 * @returns {React.ReactElement} React.ReactElement
 *
 * @todo label passed to EnderInputWrapper is not clickable-into-field, also notice https://mantine.dev/core/input/#disclaimer
 * @deprecated flag any use of this component. Surface to @kfalicov and design team for replacement with an appropriate DS component.
 * This is frequently used to grant error display or label props to arbitrary things, which isn't appropriate. error/label should be
 * built in to most components used
 */
function EnderInputWrapper(props: PropsWithChildren<EnderInputWrapperProps>) {
  const {
    children,
    label,
    htmlFor,
    description,
    descriptionProps,
    error,
    className,
  } = props;

  return (
    <Input.Wrapper
      label={label}
      description={description}
      className={className}
      error={error}
      descriptionProps={descriptionProps}
      inputWrapperOrder={ENDER_INPUT_WRAPPER_ORDER}
      id={htmlFor}>
      {children}
    </Input.Wrapper>
  );
}

export { EnderInputWrapper };
export type { EnderInputWrapperProps };
