import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { useCallback } from "react";

import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Badge } from "@ender/shared/ds/badge";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { ApprovalsAPIRemoveApproverPayload } from "@ender/shared/generated/ender.api.misc";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import { Size } from "@ender/shared/utils/theming";
import { renderPrivateContact } from "@ender/shared/utils/user";

type Approver = {
  id: EnderId;
  firstName: string;
  lastName: string;
  [key: string]: unknown;
};

type ApproverBadgeProps = {
  approver: Approver;
  disabled?: boolean;
  onDeleteSuccess: () => void;
  stepId: EnderId;
  tooltip?: ReactNode;
};

function ApproverBadge({
  approver,
  disabled,
  onDeleteSuccess,
  stepId,
  tooltip,
}: ApproverBadgeProps) {
  const queryClient = useQueryClient();
  const removeApproverMutation = useMutation({
    mutationFn: (payload: ApprovalsAPIRemoveApproverPayload) =>
      ApprovalsAPI.removeApprover(payload),
    mutationKey: ["ApprovalsAPI.removeApprover"] as const,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ApprovalsAPI.removeApprover"],
      });
      onDeleteSuccess();
    },
  });
  const onClick = useCallback(
    async function deleteApprover() {
      if (disabled) {
        return;
      }

      await removeApproverMutation.mutateAsync({
        approverId: approver.id,
        stepId,
      });
    },
    [approver.id, disabled, removeApproverMutation, stepId],
  );

  return (
    <Badge>
      <Group aria-label="Approver Name">
        <span>{renderPrivateContact(approver)}</span>
        <Tooltip label={tooltip}>
          <ActionIcon
            disabled={disabled}
            onClick={onClick}
            aria-label="remove approver"
            size={Size.sm}>
            <IconX />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Badge>
  );
}

export { ApproverBadge, type Approver };
