import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeasingServiceMoveOutStatusValues = [
  "MOVED_OUT",
  "MOVE_OUT_OVERDUE",
  "POST_TERM",
  "MOVE_OUT_PENDING_DEPOSIT_ACCOUNTING",
  "ON_NOTICE",
  "EXPIRING",
] as const;

const LeasingServiceMoveOutStatusEffectSchema = Schema.Literal(
  ...LeasingServiceMoveOutStatusValues,
);

type LeasingServiceMoveOutStatus = Schema.Schema.Type<
  typeof LeasingServiceMoveOutStatusEffectSchema
>;

const LeasingServiceMoveOutStatusEnum = castEnum(
  LeasingServiceMoveOutStatusEffectSchema,
);

function randomLeasingServiceMoveOutStatus(): LeasingServiceMoveOutStatus {
  return rand(LeasingServiceMoveOutStatusValues);
}

export {
  LeasingServiceMoveOutStatusEffectSchema,
  LeasingServiceMoveOutStatusEnum,
  LeasingServiceMoveOutStatusValues,
  randomLeasingServiceMoveOutStatus,
};
export type { LeasingServiceMoveOutStatus };
