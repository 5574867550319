import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskCloseReasonValues = [
  "COMPLETED",
  "VENDOR_ASSIGNED",
  "WARRANTY",
  "DUPLICATE",
  "TROUBLESHOOT",
  "PAID_INVOICE",
  "OTHER",
] as const;

const TaskCloseReasonEffectSchema = Schema.Literal(...TaskCloseReasonValues);

type TaskCloseReason = Schema.Schema.Type<typeof TaskCloseReasonEffectSchema>;

const TaskCloseReasonEnum = castEnum(TaskCloseReasonEffectSchema);

function randomTaskCloseReason(): TaskCloseReason {
  return rand(TaskCloseReasonValues);
}

export {
  randomTaskCloseReason,
  TaskCloseReasonEffectSchema,
  TaskCloseReasonEnum,
  TaskCloseReasonValues,
};
export type { TaskCloseReason };
