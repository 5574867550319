import { IconPhone } from "@tabler/icons-react";

import styles from "./chat-phone-call.module.css";

const ChatPhoneCall = () => {
  return (
    <div className={styles.attachment}>
      <IconPhone /> Phone Call Placed
    </div>
  );
};

export { ChatPhoneCall };
