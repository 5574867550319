import type { ElementRef, PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

import { Align, Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";
import type { BadgeProps } from "./shared-ds-badge";
import { Badge } from "./shared-ds-badge";

type ActionBadgeProps = {
  onClick: () => void;
  icon: ReactNode;
} & BadgeProps;

const ActionBadge = forwardRef<
  ElementRef<typeof Badge>,
  PropsWithChildren<ActionBadgeProps>
>(function ActionBadge(props, ref) {
  const { icon, onClick, children, ...rest } = props;
  return (
    <Badge {...rest} ref={ref}>
      <Group align={Align.center} spacing={Spacing.sm}>
        <div>{children}</div>
        <button className="-my-2 -mx-1.5 p-1 text-base" onClick={onClick}>
          {icon}
        </button>
      </Group>
    </Badge>
  );
});

export { ActionBadge };
export type { ActionBadgeProps };
