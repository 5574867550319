import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LedgerEventLedgerEventTypeValues = [
  "INVOICE",
  "MONEY_TRANSFER",
  "LEDGER_ALLOCATION",
  "JOURNAL_ENTRY",
] as const;

const LedgerEventLedgerEventTypeEffectSchema = Schema.Literal(
  ...LedgerEventLedgerEventTypeValues,
);

type LedgerEventLedgerEventType = Schema.Schema.Type<
  typeof LedgerEventLedgerEventTypeEffectSchema
>;

const LedgerEventLedgerEventTypeEnum = castEnum(
  LedgerEventLedgerEventTypeEffectSchema,
);

function randomLedgerEventLedgerEventType(): LedgerEventLedgerEventType {
  return rand(LedgerEventLedgerEventTypeValues);
}

export {
  LedgerEventLedgerEventTypeEffectSchema,
  LedgerEventLedgerEventTypeEnum,
  LedgerEventLedgerEventTypeValues,
  randomLedgerEventLedgerEventType,
};
export type { LedgerEventLedgerEventType };
