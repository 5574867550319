import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import { castEnum } from "@ender/shared/utils/zod";

const ARAgingReportTypeValues = [
  "CREDIT_LOSS",
  "CHARGE_LEVEL",
  "LEASE_LEVEL",
  "PROPERTY_LEVEL",
] as const;
const ARAgingReportTypeSchema = z.enum(ARAgingReportTypeValues);
type ARAgingReportType = z.infer<typeof ARAgingReportTypeSchema>;

/**
 * @enum {string} ARAgingReportTypeEnum
 * @description AR Aging Report Type Enum
 */
const ARAgingReportTypeEnum = castEnum<ARAgingReportType>(
  ARAgingReportTypeSchema,
);

const GetARAgingReportXlsxByPropertyIdSchema = z.object({
  propertyIds: EnderIdSchema.array(),
  inclusiveEnd: z.string().optional(),
  inclusiveEndPeriod: z.string().optional(),
  type: ARAgingReportTypeSchema.optional(),
});

type GetARAgingReportXlsxByPropertyIdType = z.infer<
  typeof GetARAgingReportXlsxByPropertyIdSchema
>;

export {
  ARAgingReportTypeEnum,
  ARAgingReportTypeSchema,
  GetARAgingReportXlsxByPropertyIdSchema,
};
export type { ARAgingReportType, GetARAgingReportXlsxByPropertyIdType };
