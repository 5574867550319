/**
 * Make a payment from PM to Ender.
 * HTTP Method: POST
 * Pathname: /payEnder
 * @function payEnder
 * @memberof PaymentsAPI
 * @param {PaymentsAPIPayEnderPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { Money, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIPayEnderSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIPayEnderBodyParams = {
  amount: SerializesInto<Money>;
};

type PaymentsAPIPayEnderPayload = PaymentsAPIPayEnderSearchParams &
  PaymentsAPIPayEnderBodyParams;

function payEnderUnsafeEffect(payload: PaymentsAPIPayEnderPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/payEnder",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function payEnder(
  payload: PaymentsAPIPayEnderPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    payEnderUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { payEnder };
export type {
  PaymentsAPIPayEnderBodyParams,
  PaymentsAPIPayEnderPayload,
  PaymentsAPIPayEnderSearchParams,
};
