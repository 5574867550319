import { randPhoneNumber } from "@ngneat/falso";
import { z } from "zod";

import { isPossiblePhoneNumber } from "@ender/shared/utils/phone";

/**
 * built-in error messaging if the field is required.
 * Can be used as `PhoneSchema.optional()` in other schema
 * in which case it will only pursue the other validations
 * if the field is present and at least 1 character long.
 */
const PhoneSchema = z
  .string({ required_error: "Phone is required" })
  .trim()
  .refine(
    (value) => isPossiblePhoneNumber(value),
    "Must be a valid phone number",
  )
  .brand("Phone");

type Phone = z.infer<typeof PhoneSchema>;

function ParsePhone(input: string): Phone {
  return PhoneSchema.parse(input);
}

function randomPhone(): Phone {
  return ParsePhone(randPhoneNumber({ countryCode: "US" }));
}

export { ParsePhone, PhoneSchema, randomPhone };
export type { Phone };
