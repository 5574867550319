import { Schema } from "@effect/schema";
import { startOfDecade, startOfYear } from "date-fns";
import { Array as A, Match as M, Option as O, pipe } from "effect";
import { useCallback, useEffect, useState } from "react";

import { LocalDate$ } from "@ender/shared/core";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { castEnum } from "@ender/shared/utils/effect";

import type { DayPickerProps } from "./day-picker/shared-ds-calendar-day";
import { DayPicker } from "./day-picker/shared-ds-calendar-day";
import { MonthPicker } from "./month-picker/shared-ds-calendar-months";
import { YearPicker } from "./year-picker/shared-ds-calendar-years";

const CalendarLevelSchema = Schema.Literal("day", "month", "year");
const CalendarLevelValues = CalendarLevelSchema.literals;
type CalendarLevel = Schema.Schema.Type<typeof CalendarLevelSchema>;
const CalendarLevelEnum = castEnum(CalendarLevelSchema);

type CalendarProps = {
  /**
   * The reason this is an array of `Option` instead of just an array of LocalDate is because this pattern supports:
   * - single date selection (single Some)
   * - multiple date selection (array of multiple Some)
   * - range selection where the first or last date is not yet selected
   *
   * Array of Option of LocalDate represents the lowest common denominator of all of these use cases.
   */
  value: O.Option<LocalDate$.LocalDate>[];
  onChange: (value: A.NonEmptyArray<O.Option<LocalDate$.LocalDate>>) => void;
  /**
   * the lower bound of the date range that the user can select. Dates, months, and years before this date are disabled
   * Changing this value will not affect the currently selected date if it is out of bounds.
   * It is expected that whatever triggers moving the minDate can also update the value to be within the new bounds
   */
  minDate?: LocalDate$.LocalDate;
  /**
   * the upper bound of the date range that the user can select. Dates, months, and years after this date are disabled
   * Changing this value will not affect the currently selected date if it is out of bounds.
   * It is expected that whatever triggers moving the maxDate can also update the value to be within the new bounds
   */
  maxDate?: LocalDate$.LocalDate;
  level?: CalendarLevel;
} & Pick<DayPickerProps, "mode">;

const Calendar = (props: CalendarProps) => {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    level = CalendarLevelEnum.day,
    mode = "single",
  } = props;
  const onChangeRef = useRefLatest(onChange);

  const getOrElseClampedToday = useCallback(
    (value: O.Option<LocalDate$.LocalDate>) =>
      O.getOrElse(value, () =>
        LocalDate$.clamp(LocalDate$.today(), {
          minimum: minDate,
          maximum: maxDate,
        }),
      ),
    [minDate, maxDate],
  );

  //the active date that the calendar should be focused around. Defaults to today
  const [activeDate, setActiveDate] = useState(
    getOrElseClampedToday(pipe(A.head(value), O.flatten)),
  );

  /**
   * 0 for day, 1 for month, 2 for year
   */
  const [currentLevel, setCurrentLevel] = useState<CalendarLevel>(level);

  /**
   * update the active date when the value changes from the controlled input.
   * Based on the level, we set the active date to the start of the year or decade
   * so that the user sees the date in context of the larger time period
   */
  useEffect(() => {
    setCurrentLevel(level);

    setActiveDate((prev) => {
      const activeValueAtLevel = M.value(level).pipe(
        M.when("day", () => O.some(prev)),
        M.when("month", () => LocalDate$.parse(startOfYear(prev.toDate()))),
        M.when("year", () => LocalDate$.parse(startOfDecade(prev.toDate()))),
        M.orElseAbsurd,
      );
      return getOrElseClampedToday(activeValueAtLevel);
    });
  }, [value, getOrElseClampedToday, level]);

  const handlePickYear = useCallback(
    (
      dates: O.Option<LocalDate$.LocalDate>[],
      lastPicked: O.Option<LocalDate$.LocalDate>,
    ) => {
      if (level !== CalendarLevelEnum.year) {
        setActiveDate(getOrElseClampedToday(lastPicked));
        //go back to month picker
        setCurrentLevel(CalendarLevelEnum.month);
      } else {
        if (A.isNonEmptyArray(dates)) {
          onChangeRef.current(dates);
        } else {
          onChangeRef.current([O.none()]);
        }
      }
    },
    [level, getOrElseClampedToday, onChangeRef],
  );

  /**
   * when a month is picked, we descend to the day picker if it is allowed.
   * Otherwise, we just update the value and stay at the month picker.
   * The most recently active date is set from the clicked date
   */
  const handlePickMonth = useCallback(
    (
      dates: O.Option<LocalDate$.LocalDate>[],
      lastPicked: O.Option<LocalDate$.LocalDate>,
    ) => {
      setActiveDate(O.getOrElse(() => LocalDate$.today())(lastPicked));
      if (level === CalendarLevelEnum.day) {
        //go back to day picker
        setCurrentLevel(CalendarLevelEnum.day);
      } else {
        if (A.isNonEmptyArray(dates)) {
          onChangeRef.current(dates);
        } else {
          onChangeRef.current([O.none()]);
        }
      }
    },
    [level, onChangeRef],
  );

  const decreaseSpecificity = useCallback(() => {
    setCurrentLevel(
      (prev) =>
        CalendarLevelValues[
          (CalendarLevelValues.indexOf(prev) + 1) % CalendarLevelValues.length
        ],
    );
    //clamp the active date to the min and max dates. This is because when you go up or down a level,
    //the active date may be out of bounds since it is just an arbitrary date within the interval
    setActiveDate((prev) =>
      LocalDate$.clamp({ minimum: minDate, maximum: maxDate })(prev),
    );
  }, [minDate, maxDate]);

  switch (currentLevel) {
    case CalendarLevelEnum.year:
      return (
        <YearPicker
          decade={activeDate}
          setDecade={setActiveDate}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={handlePickYear}
          //TODO if mode is range, set values to be all the dates in the range
          mode={mode === "range" ? "multiple" : mode}
        />
      );
    case CalendarLevelEnum.month:
      return (
        <MonthPicker
          year={activeDate}
          setYear={setActiveDate}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={handlePickMonth}
          goUp={decreaseSpecificity}
          //TODO if mode is range, set values to be all the dates in the range
          mode={mode === "range" ? "multiple" : mode}
        />
      );
    case CalendarLevelEnum.day:
    default:
      return (
        <DayPicker
          month={activeDate}
          onMonthChange={setActiveDate}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(val) => onChange(val)}
          goUp={decreaseSpecificity}
          mode={mode}
        />
      );
  }
};
Calendar.displayName = "Calendar";

export {
  Calendar,
  CalendarLevelEnum,
  CalendarLevelSchema,
  CalendarLevelValues,
};

export type { CalendarLevel, CalendarProps };
