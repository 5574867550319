import { Schema } from "@effect/schema";
import { useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P, String as S } from "effect";
import { useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { Divider } from "@ender/shared/ds/divider";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { TabButton, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetGLJournalEntryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import {
  AccountingPeriodAccountingModuleEnum,
  AccountingPeriodPeriodStatusEnum,
} from "@ender/shared/generated/ender.model.accounting";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { castEnum } from "@ender/shared/utils/effect";
import { toShortMonthYearString } from "@ender/shared/utils/local-date";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { AuditHistory } from "@ender/widgets/finance/approval";

import { JournalEntryView } from "../../../reports/journal-entry-view/journal-entry-view";
import { GeneralLedgerTransactionApprovalsTransactionAllocations } from "./gltx-approvals-transaction-allocations";
import { GeneralLedgerTransactionApprovalsTransactionApprovalDetails } from "./gltx-approvals-transaction-approval-details";
import { GeneralLedgerTransactionApprovalsTransactionApprovalMenu } from "./gltx-approvals-transaction-approval-menu";
import { GeneralLedgerTransactionApprovalsTransactionDetails } from "./gltx-approvals-transaction-details";
import { JournalEntryApprovalStatus } from "./gltx-journal-entry-approval-status";

const TransactionDetailsTabValues = [
  "ALLOCATIONS",
  "AUDIT_HISTORY",
  "ATTACHMENTS",
] as const;

const TransactionDetailsTabEffectSchema = Schema.Literal(
  ...TransactionDetailsTabValues,
);

type TransactionDetailsTab = Schema.Schema.Type<
  typeof TransactionDetailsTabEffectSchema
>;

const TransactionDetailsTabEnum = castEnum(TransactionDetailsTabEffectSchema);

type TransactionDetailsProps = {
  approvalProcessSteps: GetApprovalProcessResponseStep[];
  openEditDrawer: () => void;
  refreshReversalData: () => void;
  refreshTransactionData: () => void;
  reversedById?: EnderId;
  reversesId?: EnderId;
  journalEntry?: GetGLJournalEntryResponse;
  recurringJournalEntry?: GetRecurringGLJournalEntryDetailsResponse;
};

function GeneralLedgerTransactionApprovalsTransactionContainer({
  approvalProcessSteps,
  openEditDrawer,
  refreshReversalData,
  refreshTransactionData,
  reversedById,
  reversesId,
  journalEntry,
  recurringJournalEntry,
}: TransactionDetailsProps) {
  const [activeTab, setActiveTab] = useState<TransactionDetailsTab>(
    TransactionDetailsTabEnum.ALLOCATIONS,
  );

  const formattedJournalEntryTimeline = journalEntry?.timeline.map((entry) => ({
    ...entry,
    step: journalEntry.approvalStatus.approvalStepName,
    user: { name: entry.userName } as User,
  }));

  const formattedRecurringJournalEntryTimeline =
    recurringJournalEntry?.auditHistory.map((entry) => ({
      archived: false,
      comment: entry.commentDisplay,
      date: LocalDate$.of(entry.timestamp).toJSON(),
      decision: entry.actionDisplay,
      isFinalStep: !S.isEmpty(recurringJournalEntry.approvalStepDisplay),
      step: recurringJournalEntry.approvalStepDisplay,
      timestamp: Number(entry.timestamp),
      user: { name: entry.userDisplay } as User,
    }));

  const {
    data: isAccountingPeriodClosed,
    isLoading: isLoadingAccountingPeriodClosed,
  } = useQuery({
    enabled: P.isNotNullable(journalEntry),
    queryFn: () =>
      AccountingAPI.getAccountingPeriods({
        module: AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER,
      }),
    queryKey: ["AccountingAPI.getJournalEntry"],
    select: (data) => {
      const accountingPeriod = data.find(
        (period) =>
          toShortMonthYearString(period.startDate) ===
          journalEntry?.accountingPeriod,
      );
      return (
        accountingPeriod?.status === AccountingPeriodPeriodStatusEnum.CLOSED
      );
    },
  });

  return (
    <Stack spacing={Spacing.lg} aria-label="transaction-view">
      <Group justify={Justify.between}>
        <H1>
          <Tooltip
            label={
              journalEntry
                ? journalEntry.description
                : recurringJournalEntry?.title
            }>
            <Group justify={Justify.between}>
              {journalEntry?.description ?? recurringJournalEntry?.title}
              <JournalEntryApprovalStatus
                approvalStatus={
                  journalEntry?.approvalStatus ??
                  recurringJournalEntry?.approvalStatus
                }
                approvalStepDisplay={recurringJournalEntry?.approvalStepDisplay}
              />
            </Group>
          </Tooltip>
        </H1>
        <GeneralLedgerTransactionApprovalsTransactionApprovalMenu
          approvalProcessSteps={approvalProcessSteps}
          isAccountingPeriodClosed={P.isTruthy(isAccountingPeriodClosed)}
          refreshReversalData={refreshReversalData}
          refreshTransactionData={refreshTransactionData}
          reversedById={reversedById}
          reversesId={reversesId}
          journalEntry={O.fromNullable(journalEntry)}
          recurringJournalEntry={O.fromNullable(recurringJournalEntry)}
        />
      </Group>
      <Card>
        <Skeleton
          visible={
            isLoadingAccountingPeriodClosed && P.isNotNullable(journalEntry)
          }>
          <GeneralLedgerTransactionApprovalsTransactionDetails
            isAccountingPeriodClosed={P.isTruthy(isAccountingPeriodClosed)}
            isEditable={P.isNullable(reversedById)}
            openEditDrawer={openEditDrawer}
            reversedById={reversedById}
            reversesId={reversesId}
            journalEntry={O.fromNullable(journalEntry)}
            recurringJournalEntry={O.fromNullable(recurringJournalEntry)}
          />
        </Skeleton>
      </Card>
      {P.isNotNullable(approvalProcessSteps) && (
        <Card>
          <GeneralLedgerTransactionApprovalsTransactionApprovalDetails
            approvalProcess={approvalProcessSteps}
            journalEntry={O.fromNullable(journalEntry)}
            recurringJournalEntry={O.fromNullable(recurringJournalEntry)}
          />
        </Card>
      )}
      <Tabs value={activeTab} onChange={setActiveTab}>
        <TabsList>
          {TransactionDetailsTabValues.map((tab) => (
            <TabButton key={tab} value={tab}>
              {convertSnakeCaseToTitleCase(tab)}
            </TabButton>
          ))}
        </TabsList>
      </Tabs>
      <Divider />
      {activeTab === TransactionDetailsTabEnum.ALLOCATIONS && (
        <GeneralLedgerTransactionApprovalsTransactionAllocations
          journalEntry={journalEntry}
          recurringJournalEntry={recurringJournalEntry}
          refreshTransactionData={refreshTransactionData}
        />
      )}
      {activeTab === TransactionDetailsTabEnum.AUDIT_HISTORY && (
        <AuditHistory
          onlyShowTable
          timeline={
            journalEntry
              ? formattedJournalEntryTimeline
              : formattedRecurringJournalEntryTimeline
          }
        />
      )}
      {activeTab === TransactionDetailsTabEnum.ATTACHMENTS &&
        P.isNotNullable(journalEntry) && (
          <JournalEntryView id={journalEntry.id} approvalsDetailView />
        )}
    </Stack>
  );
}

export { GeneralLedgerTransactionApprovalsTransactionContainer };
