import { Predicate as P } from "effect";
import type { PropsWithChildren, ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import type { ButtonProps } from "@ender/shared/ds/button";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { ModalProps } from "@ender/shared/ds/modal";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

type ConfirmDialogOptions = {
  cancelButtonProps?: Partial<ButtonProps>;
  confirmButtonProps?: Partial<ButtonProps>;
  modalProps?: Omit<ModalProps, "opened" | "onClose" | "title">;
};
const defaultOptions: ConfirmDialogOptions = {};

type ConfirmationComponents = {
  /**
   * The text to display on the cancel button. Defaults to "Cancel"
   */
  cancelButtonLabel?: ReactNode;
  /**
   * The text to display on the confirmation button. Defaults to "Confirm"
   */
  confirmButtonLabel?: ReactNode;
  /**
   * The content to display in the confirmation dialog as the modal body
   */
  content?: ReactNode;
  /**
   * The content to display in the confirmation dialog as the modal title. Can be NULLable, but if undefined, defaults to "Are you sure?"
   */
  title?: string;
};
type FinalConfirmationComponents = Omit<ConfirmationComponents, "title"> & {
  title: string;
};

const defaultComponents = {
  cancelButtonLabel: "Cancel",
  confirmButtonLabel: "Confirm",
  title: "Are you sure?",
};

/**
 * @description A function that opens a configurable, async confirmation dialog
 * @param components - the components to render in the dialog
 * @param options - Options to customize the dialog.
 * @returns a promise that resolves when the user confirms the action, or throws when the user cancels the action.
 */
type ConfirmationFunction = (
  components?: ConfirmationComponents,
  options?: ConfirmDialogOptions,
) => Promise<void>;

/**
 * Allows for forms and submit buttons to wait on confirmation from the user.
 * Provides a handy interface to open the dialog modal with specific content.
 */
const ConfirmationContext = createContext<ConfirmationFunction>(() =>
  Promise.resolve(),
);

const useConfirmationContext = () => {
  return useContext(ConfirmationContext);
};

const ConfirmationContextProvider = ({ children }: PropsWithChildren) => {
  const [open, openHandlers] = useBoolean(false);
  const [nodes, setNodes] = useState<ConfirmationComponents | null>();
  const [options, setOptions] = useState<ConfirmDialogOptions>();
  const promiseRef = useRef<{ reject: () => void; resolve: () => void }>();

  /**
   * opens the confirmation modal. Only returns when the confirmation
   * modal has been dismissed.
   */
  const confirmation = useCallback<ConfirmationFunction>(
    (
      {
        title,
        content,
        confirmButtonLabel,
        cancelButtonLabel,
      }: ConfirmationComponents = {},
      options: ConfirmDialogOptions = defaultOptions,
    ) => {
      const finalComponents: FinalConfirmationComponents = {
        cancelButtonLabel,
        confirmButtonLabel,
        content,
        title: P.isNotNullable(title) ? title : defaultComponents.title,
      };
      setNodes(finalComponents);
      openHandlers.setTrue();
      setOptions(options);
      //if a promise was left over from another confirmation modal, reject it
      promiseRef.current?.reject();
      //TODO replace with Promise.withResolvers when it becomes available
      return new Promise((resolve, reject) => {
        promiseRef.current = { resolve, reject };
      });
    },
    [openHandlers],
  );

  const rejectAndClose = useCallback(() => {
    promiseRef.current?.reject();
    openHandlers.setFalse();
  }, [openHandlers]);

  const acceptAndClose = useCallback(() => {
    promiseRef.current?.resolve();
    openHandlers.setFalse();
  }, [openHandlers]);

  return (
    <ConfirmationContext.Provider value={confirmation}>
      {children}
      <Modal
        {...options?.modalProps}
        title={nodes?.title || defaultComponents.title}
        opened={open}
        size={ModalSize.sm}
        onClose={rejectAndClose}>
        {nodes?.content}
        <div style={{ marginTop: `1.5rem` }}>
          <Group justify={Justify.end}>
            <Button
              variant={ButtonVariant.outlined}
              {...options?.cancelButtonProps}
              onClick={rejectAndClose}>
              {nodes?.cancelButtonLabel || defaultComponents.cancelButtonLabel}
            </Button>
            <Button {...options?.confirmButtonProps} onClick={acceptAndClose}>
              {nodes?.confirmButtonLabel ||
                defaultComponents.confirmButtonLabel}
            </Button>
          </Group>
        </div>
      </Modal>
    </ConfirmationContext.Provider>
  );
};

export {
  ConfirmationContext,
  ConfirmationContextProvider,
  useConfirmationContext,
};
export type { ConfirmDialogOptions };
