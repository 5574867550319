/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/submit
 * @function submitApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPISubmitApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<IdentityVerificationResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { IdentityVerificationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPISubmitApplicationPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPISubmitApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPISubmitApplicationBodyParams = {
  birthday: SerializesInto<LocalDate>;
  email?: string | undefined;
  phone?: string | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  place?: any | undefined;
  ssn?: string | undefined;
};

type ApplicationsAPISubmitApplicationPayload =
  ApplicationsAPISubmitApplicationPathParams &
    ApplicationsAPISubmitApplicationSearchParams &
    ApplicationsAPISubmitApplicationBodyParams;

function submitApplicationUnsafeEffect(
  payload: ApplicationsAPISubmitApplicationPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, IdentityVerificationResponse>({
        body,
        decode: unsafeDecodeJsonResponse<IdentityVerificationResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/submit`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function submitApplication(
  payload: ApplicationsAPISubmitApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<IdentityVerificationResponse> {
  return F.pipe(
    payload,
    submitApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { submitApplication };
export type {
  ApplicationsAPISubmitApplicationBodyParams,
  ApplicationsAPISubmitApplicationPathParams,
  ApplicationsAPISubmitApplicationPayload,
  ApplicationsAPISubmitApplicationSearchParams,
};
