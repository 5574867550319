/**
 * HTTP Method: POST
 * Pathname: /applications/{appGroupId}/people/{applicationId}/delete
 * @function removePersonFromApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIRemovePersonFromApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIRemovePersonFromApplicationPathParams = {
  appGroupId: EnderId;
  applicationId: EnderId;
};

type ApplicationsAPIRemovePersonFromApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIRemovePersonFromApplicationPayload =
  ApplicationsAPIRemovePersonFromApplicationPathParams &
    ApplicationsAPIRemovePersonFromApplicationSearchParams;

function removePersonFromApplicationUnsafeEffect(
  payload: ApplicationsAPIRemovePersonFromApplicationPayload,
) {
  const { appGroupId, applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${appGroupId}/people/${applicationId}/delete`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removePersonFromApplication(
  payload: ApplicationsAPIRemovePersonFromApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removePersonFromApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removePersonFromApplication };
export type {
  ApplicationsAPIRemovePersonFromApplicationPathParams,
  ApplicationsAPIRemovePersonFromApplicationPayload,
  ApplicationsAPIRemovePersonFromApplicationSearchParams,
};
