import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyAreaLotInclineTypeValues = [
  "POSITIVE",
  "NEGATIVE",
  "NONE",
] as const;

const PropertyAreaLotInclineTypeEffectSchema = Schema.Literal(
  ...PropertyAreaLotInclineTypeValues,
);

type PropertyAreaLotInclineType = Schema.Schema.Type<
  typeof PropertyAreaLotInclineTypeEffectSchema
>;

const PropertyAreaLotInclineTypeEnum = castEnum(
  PropertyAreaLotInclineTypeEffectSchema,
);

function randomPropertyAreaLotInclineType(): PropertyAreaLotInclineType {
  return rand(PropertyAreaLotInclineTypeValues);
}

export {
  PropertyAreaLotInclineTypeEffectSchema,
  PropertyAreaLotInclineTypeEnum,
  PropertyAreaLotInclineTypeValues,
  randomPropertyAreaLotInclineType,
};
export type { PropertyAreaLotInclineType };
