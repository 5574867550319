import { IconFilter } from "@tabler/icons-react";

import { Button } from "@ender/shared/ds/button";

function ShowFiltersButton(props: { setFilterRailOpen: () => void }) {
  const { setFilterRailOpen } = props;

  return (
    <Button variant="transparent" onClick={setFilterRailOpen}>
      <IconFilter /> Filters
    </Button>
  );
}

export { ShowFiltersButton };
