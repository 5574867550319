import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";

function getAllocationsFromAllocationsByProperty(
  allocationsByProperty: InvoiceSerializerInvoiceResponse["currentState"]["allocationsByProperty"],
): InvoiceSerializerInvoiceResponse["currentState"]["allocationsByProperty"][0]["allocations"] {
  const allocations = [];
  for (const propertyAllocations of allocationsByProperty) {
    allocations.push(...propertyAllocations.allocations);
  }
  return allocations;
}

export { getAllocationsFromAllocationsByProperty };
