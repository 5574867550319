import type {
  AccessorFn,
  CellContext,
  Column,
  ColumnDefTemplate,
  ColumnFiltersState,
  ColumnFiltersTableState,
  ColumnPinningState,
  ColumnPinningTableState,
  ExpandedState,
  ExpandedTableState,
  FilterFnOption,
  GlobalFilterTableState,
  PaginationState,
  PaginationTableState,
  Row,
  RowSelectionState,
  RowSelectionTableState,
  SortDirection,
  SortingState,
  SortingTableState,
  Table,
  AccessorColumnDef as TanstackAccessorColumnDef,
  DisplayColumnDef as TanstackDisplayColumnDef,
  GroupColumnDef as TanstackGroupColumnDef,
  VisibilityState,
  VisibilityTableState,
} from "@tanstack/react-table";
import type { Dispatch, ReactNode, SetStateAction } from "react";

import type { ButtonVariants } from "@ender/shared/ds/button";
import type { EmptyObject, NestedKeysOf } from "@ender/shared/types/general";

import type { FilterComponent } from "./column-sort-filter";

type EnderTableState = SortingTableState &
  ColumnFiltersTableState &
  GlobalFilterTableState &
  VisibilityTableState &
  ColumnPinningTableState &
  RowSelectionTableState &
  ExpandedTableState &
  Partial<PaginationTableState>;

type EnderTableSortingParams = {
  sorting: SortingState;
  onSortingChange: Dispatch<SetStateAction<SortingState>>;
  enableSorting: boolean;
  enableSortingRemoval: boolean;
  enableMultiRemove: boolean;
  enableMultiSort: boolean;
  manualSorting: boolean;
  maxMultiSortColCount: number;
  isMultiSortEvent: (e: unknown) => boolean;
};

type EnderTableFilterParams<RowData> = {
  columnFilters: ColumnFiltersState;
  onColumnFiltersChange: Dispatch<SetStateAction<ColumnFiltersState>>;
  globalFilter: string | undefined;
  enableGlobalFilter?: boolean;
  onGlobalFilterChange: Dispatch<SetStateAction<string | undefined>>;
  globalFilterFn?: FilterFnOption<RowData>;
  manualFiltering?: boolean;
};

type EnderTableColumnVisibilityParams = {
  columnVisibility: VisibilityState;
  onColumnVisibilityChange: Dispatch<SetStateAction<VisibilityState>>;
};

type EnderTableRowSelectionParams<
  RowData,
  Meta extends object = EmptyObject,
> = {
  rowSelection: RowSelectionState;
  enableSelectAll: boolean;
  onRowSelectionChange: Dispatch<SetStateAction<RowSelectionState>>;
  enableRowSelection:
    | boolean
    // eslint-disable-next-line no-use-before-define
    | ((row: Row<RowData>, table: EnderTable<RowData, Meta>) => boolean);
  enableMultiRowSelection:
    | boolean
    // eslint-disable-next-line no-use-before-define
    | ((row: Row<RowData>, table: EnderTable<RowData, Meta>) => boolean);
};

type EnderTableRowExpansionParams<RowData> = {
  expanded: ExpandedState;
  getRowCanExpand?: (row: Row<RowData>) => boolean;
  onExpandedChange: Dispatch<SetStateAction<ExpandedState>>;
  getSubRows: (row: RowData) => RowData[];
  onExpandRows?: (args: {
    expanded: Row<RowData>[];
    collapsed: Row<RowData>[];
  }) => void;
};

type EnderTablePaginationParams = {
  pagination: PaginationState;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  pageCount?: number;
  autoResetPageIndex: boolean;
  manualPagination?: boolean;
};

type EnderTableColumnPinningParams = {
  columnPinning: ColumnPinningState;
};

type BadgeRemoveFilterFnParams<RowData, ColumnData> = {
  index: number;
  column: Column<RowData, ColumnData>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ColumnMeta<RowData, ColumnData = any, FilterData = any> = {
  className?: string;
  filterComponent?: FilterComponent<RowData, ColumnData, FilterData>;
  /**
   * determines how the badge should be rendered. If not provided the badge will attempt to render the filter as a string
   * @param value the value currently stored in the filter
   * @param table
   */

  getFilterLabel?: (
    value: FilterData,
    table: Table<RowData>,
  ) => string[] | string;
  badgeRemoveFilterFn?: (
    params: BadgeRemoveFilterFnParams<RowData, ColumnData>,
  ) => void;
  getNextSortingOrder?: (
    prevOrder: SortDirection | false,
  ) => SortDirection | false;
};

// eslint-disable-next-line
type ColumnDefIdentifier<RowData, ColumnData = any> =
  | {
      id?: string;
      accessorKey: NestedKeysOf<RowData>;
      accessorFn?: AccessorFn<RowData, ColumnData>;
    }
  | {
      id: string;
      accessorKey?: NestedKeysOf<RowData>;
      accessorFn?: AccessorFn<RowData, ColumnData>;
    }
  | {
      id: string;
      accessorKey?: never;
      accessorFn: AccessorFn<RowData, ColumnData>;
    };

// Redefine AccessorColumnDef to require our ColumnDefIdentifier type our ColumnMeta
type AccessorColumnDef<
  RowData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterData = any,
> = ColumnDefIdentifier<RowData, ColumnData> &
  ColumnMeta<RowData, ColumnData, FilterData> &
  // TODO[@EnderHub/fe-table-management]: Try to see if we can enable using accessorFn somehow, we rely on the key in
  //   right now we rely on the Id/Key for our sort,filter ect... hooks
  Omit<
    TanstackAccessorColumnDef<RowData, ColumnData>,
    "id" | "accessorKey" | "accessorFn" | "meta" | "cell" | "footer"
  >;

// Redefine DisplayColumnDef to type our ColumnMeta
// eslint-disable-next-line
type DisplayColumnDef<RowData, ColumnData = any, FilterData = any> = ColumnMeta<
  RowData,
  ColumnData,
  FilterData
> &
  Omit<TanstackDisplayColumnDef<RowData, ColumnData>, "meta" | "cell">;

// Redefine GroupColumnDef to type our ColumnMeta
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GroupColumnDef<RowData, ColumnData = any, FilterData = any> = ColumnMeta<
  RowData,
  ColumnData,
  FilterData
> &
  Omit<TanstackGroupColumnDef<RowData, ColumnData>, "meta" | "cell">;

type CellDefTemplate<
  RowData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  MetaTable extends object = EmptyObject,
> = ColumnDefTemplate<
  // eslint-disable-next-line no-use-before-define
  CellProps<RowData, ColumnData, MetaTable>
>;

type ColumnDefCell<
  RowData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  MetaTable extends object = EmptyObject,
> = {
  cell?: CellDefTemplate<RowData, ColumnData, MetaTable>;
};

// NOTE: MetaTable here must be specified as any or the types for useTable `columns` won't match specified separately
// TODO[@EnderHub/fe-table-management]: Add Examples using Display & Group Columns
type ColumnDef<
  RowData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterData = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MetaTable extends object = any,
> = ColumnDefCell<RowData, ColumnData, MetaTable> &
  (
    | AccessorColumnDef<RowData, ColumnData, FilterData>
    | GroupColumnDef<RowData, ColumnData, FilterData>
    | DisplayColumnDef<RowData, ColumnData, FilterData>
  );

/**
 * @deprecated Use the normal `ColumnDef`
 */
type ColumnDefMeta<
  RowData,
  Meta extends object = Record<string, never>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterData = any,
> = ColumnDefCell<RowData, ColumnData, Meta> &
  (
    | AccessorColumnDef<RowData, ColumnData, FilterData>
    | GroupColumnDef<RowData, ColumnData, FilterData>
    | DisplayColumnDef<RowData, ColumnData, FilterData>
  );

// NOTE: MetaTable here must be any or the `ColumnDef[]`
function asColumnDef<
  RowData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterData = any,
  MetaTable extends object = EmptyObject,
>(
  column: ColumnDef<RowData, ColumnData, FilterData, MetaTable> &
    ColumnMeta<RowData, ColumnData, FilterData>,
): ColumnDef<RowData, ColumnData, FilterData, MetaTable> &
  ColumnMeta<RowData, ColumnData, FilterData> {
  return column;
}

/**
 * @deprecated Use the normal `asColumnDef`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function asColumnDefWithMeta<
  RowData,
  Meta extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterData = any,
>(
  column: ColumnDefMeta<RowData, Meta, ColumnData> &
    ColumnMeta<RowData, ColumnData, FilterData>,
): ColumnDefMeta<RowData, Meta, ColumnData> &
  ColumnMeta<RowData, ColumnData, FilterData> {
  return column;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function asColumnMeta<RowData, ColumnData = any, FilterData = any>(
  meta: ColumnMeta<RowData, ColumnData, FilterData> | undefined,
): ColumnMeta<RowData, ColumnData, FilterData> {
  return meta ?? {};
}

type EnderTableFooter<RowData, Meta extends object = EmptyObject> = {
  // eslint-disable-next-line no-use-before-define
  cell: (props: { table: EnderTable<RowData, Meta> }) => ReactNode;
  colSpan?: number;
}[];

type EnderTableMeta<RowData, Meta extends object = EmptyObject> = Meta & {
  title: string;
  isLoading: boolean;
  errorMessage?: string;
  totalResults?: number;
  hideResults?: boolean;
  // eslint-disable-next-line no-use-before-define
  actions?: EnderTableTopAction<RowData, Meta>[];
  refetch: () => Promise<void>;
  fetchNextPage: () => Promise<void>;

  footer?: EnderTableFooter<RowData, Meta>;
  onClearFilters?: () => void;
};

type EnderTable<RowData, Meta extends object = EmptyObject> = Table<RowData> & {
  options: Table<RowData>["options"] & {
    meta: EnderTableMeta<RowData, Meta>;
  };
};

type OnEnderTableTopAction<
  RowData,
  Meta extends object = EmptyObject,
> = (args: { key: string; table: EnderTable<RowData, Meta> }) => void;

type EnderTableTopAction<RowData, Meta extends object = EmptyObject> = {
  key: string;
  label: ReactNode;
  variant?: ButtonVariants;
  loading?: boolean;
  disabled?: boolean;
  tooltip?: string;
  onAction: OnEnderTableTopAction<RowData, Meta>;
  // NOTE: These are functions to mitigate a performance issue we get when passing in ReactNode directly
  leftIcon?: () => ReactNode;
  rightIcon?: () => ReactNode;
};

// eslint-disable-next-line
type CellProps<
  RowData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnData = any,
  MetaTable extends object = EmptyObject,
> = Omit<CellContext<RowData, ColumnData>, "table"> & {
  table: EnderTable<RowData, MetaTable>;
};

export { asColumnDef, asColumnDefWithMeta, asColumnMeta };
export type {
  AccessorColumnDef,
  BadgeRemoveFilterFnParams,
  CellProps,
  ColumnDef,
  ColumnDefCell,
  ColumnDefIdentifier,
  ColumnDefMeta,
  ColumnMeta,
  DisplayColumnDef,
  EnderTable,
  EnderTableColumnPinningParams,
  EnderTableColumnVisibilityParams,
  EnderTableFilterParams,
  EnderTableFooter,
  EnderTableMeta,
  EnderTablePaginationParams,
  EnderTableRowExpansionParams,
  EnderTableRowSelectionParams,
  EnderTableSortingParams,
  EnderTableState,
  EnderTableTopAction,
  GroupColumnDef,
  OnEnderTableTopAction,
};
