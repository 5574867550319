import { allocateInvoiceOffsets } from "./allocate-invoice-offsets";
import { approveAndPayBatch } from "./approve-and-pay-batch";
import { approveAndPayBatches } from "./approve-and-pay-batches";
import { approveAndPayInvoice } from "./approve-and-pay-invoice";
import { batchApprove } from "./batch-approve";
import { batchReject } from "./batch-reject";
import { bulkUploadPayableInvoices } from "./bulk-upload-payable-invoices";
import { bulkUploadReceivableInvoices } from "./bulk-upload-receivable-invoices";
import { chargePMFees } from "./charge-p-m-fees";
import { createBillback } from "./create-billback";
import { createInvoice } from "./create-invoice";
import { editInvoices } from "./edit-invoices";
import { getInvoice } from "./get-invoice";
import { getInvoiceAllocationHistory } from "./get-invoice-allocation-history";
import { getInvoicePages } from "./get-invoice-pages";
import { getInvoices } from "./get-invoices";
import { getInvoicesForBatchMarkPaid } from "./get-invoices-for-batch-mark-paid";
import { getInvoicesForBatchPay } from "./get-invoices-for-batch-pay";
import { getInvoicesOnStep } from "./get-invoices-on-step";
import { getInvoicesOnTaskForPM } from "./get-invoices-on-task-for-p-m";
import { getInvoicesOnTaskForVendor } from "./get-invoices-on-task-for-vendor";
import { getInvoicesSummary } from "./get-invoices-summary";
import { getNextInvoiceNumber } from "./get-next-invoice-number";
import { getRecentInvoicesForProperties } from "./get-recent-invoices-for-properties";
import { markPaid } from "./mark-paid";
import { markPaidBatch } from "./mark-paid-batch";
import { markReceivedBatch } from "./mark-received-batch";
import { searchActivePayees } from "./search-active-payees";
import { searchInvoices } from "./search-invoices";
import { searchPayees } from "./search-payees";
import { trashInvoicePage } from "./trash-invoice-page";
import { voidPayment } from "./void-payment";

const InvoicesAPI = {
  allocateInvoiceOffsets,
  approveAndPayBatch,
  approveAndPayBatches,
  approveAndPayInvoice,
  batchApprove,
  batchReject,
  bulkUploadPayableInvoices,
  bulkUploadReceivableInvoices,
  chargePMFees,
  createBillback,
  createInvoice,
  editInvoices,
  getInvoice,
  getInvoiceAllocationHistory,
  getInvoicePages,
  getInvoices,
  getInvoicesForBatchMarkPaid,
  getInvoicesForBatchPay,
  getInvoicesOnStep,
  getInvoicesOnTaskForPM,
  getInvoicesOnTaskForVendor,
  getInvoicesSummary,
  getNextInvoiceNumber,
  getRecentInvoicesForProperties,
  markPaid,
  markPaidBatch,
  markReceivedBatch,
  searchActivePayees,
  searchInvoices,
  searchPayees,
  trashInvoicePage,
  voidPayment,
};

export { InvoicesAPI };
