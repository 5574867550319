import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const FirmFirmTypeValues = ["STANDARD", "FUND"] as const;

const FirmFirmTypeEffectSchema = Schema.Literal(...FirmFirmTypeValues);

type FirmFirmType = Schema.Schema.Type<typeof FirmFirmTypeEffectSchema>;

const FirmFirmTypeEnum = castEnum(FirmFirmTypeEffectSchema);

function randomFirmFirmType(): FirmFirmType {
  return rand(FirmFirmTypeValues);
}

export {
  FirmFirmTypeEffectSchema,
  FirmFirmTypeEnum,
  FirmFirmTypeValues,
  randomFirmFirmType,
};
export type { FirmFirmType };
