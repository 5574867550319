/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/moveInSteps
 * @function getMoveInStepsForLease
 * @memberof MoveInAPI
 * @param {MoveInAPIGetMoveInStepsForLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoveInAPIGetMoveInStepsForLeaseResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MoveInAPIGetMoveInStepsForLeaseResponse } from "../move-in-api-get-move-in-steps-for-lease-response";

type MoveInAPIGetMoveInStepsForLeasePathParams = {
  leaseId: EnderId;
};

type MoveInAPIGetMoveInStepsForLeaseSearchParams = {
  token?: string | undefined;
};

type MoveInAPIGetMoveInStepsForLeasePayload =
  MoveInAPIGetMoveInStepsForLeasePathParams &
    MoveInAPIGetMoveInStepsForLeaseSearchParams;

function getMoveInStepsForLeaseUnsafeEffect(
  payload: MoveInAPIGetMoveInStepsForLeasePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoveInAPIGetMoveInStepsForLeaseResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          MoveInAPIGetMoveInStepsForLeaseResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/moveInSteps`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMoveInStepsForLease(
  payload: MoveInAPIGetMoveInStepsForLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<MoveInAPIGetMoveInStepsForLeaseResponse[]> {
  return F.pipe(
    payload,
    getMoveInStepsForLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMoveInStepsForLease };
export type {
  MoveInAPIGetMoveInStepsForLeasePathParams,
  MoveInAPIGetMoveInStepsForLeasePayload,
  MoveInAPIGetMoveInStepsForLeaseSearchParams,
};
