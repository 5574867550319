import { useEffect } from "react";

import type { ObjectValues } from "@ender/shared/types/general";
import { fail } from "@ender/shared/utils/error";
import { showErrorNotification } from "@ender/shared/utils/notifications";

const PartiallySupportedFeaturesEnum = {
  OFFSCREEN_CANVAS: "OffscreenCanvas",
};

type PartiallySupportedFeature = ObjectValues<
  typeof PartiallySupportedFeaturesEnum
>;

const ENDER_SUPPORT_EMAIL = "support@ender.com";
const ENDER_SUPPORT_PHONE = "(737) 232-9168";
const CONTACT_ENDER_MESSAGE = `Contact Ender for technical support at ${ENDER_SUPPORT_EMAIL} or ${ENDER_SUPPORT_PHONE}.`;

/**
 * @description checks to ensure the browser supports all browser-based features used by Ender. If not, an appropriate error message will show.
 */
function useBrowserFeaturesSupportedCheck() {
  // lazy useEffect
  useEffect(() => {
    // Browser features used by Ender that may not be supported by some older browsers (within reason).
    const partiallySupportedFeatures: PartiallySupportedFeature[] = [
      PartiallySupportedFeaturesEnum.OFFSCREEN_CANVAS,
    ];
    const featuresUnsupportedByBrowser = partiallySupportedFeatures.filter(
      (feature) => !(feature in window),
    );

    if (featuresUnsupportedByBrowser.length > 0) {
      const errorMessageMap = {
        [PartiallySupportedFeaturesEnum.OFFSCREEN_CANVAS]: `This browser does not support the feature "OffscreenCanvas" which is used by Ender. If you are on a Mac computer and this browser is Safari, please ensure your Operating System is updated to the latest stable version.`,
      };
      const errorMessages = [
        ...featuresUnsupportedByBrowser.map(
          (feature) => errorMessageMap[feature],
        ),
        CONTACT_ENDER_MESSAGE,
      ];
      console.error(
        `The following Ender Browser Features are not supported by the current browser:
        ${featuresUnsupportedByBrowser.join("\n")}`,
      );
      showErrorNotification({
        title: "Browser Out of Date!",
        message: errorMessages.join("\n"),
      });
      fail(errorMessages.join("\n"), { showNotification: false });
    }
  }, []);
}

export { useBrowserFeaturesSupportedCheck };
