/**
 * HTTP Method: POST
 * Pathname: /pets/{ownerId}
 * @function getPetsForOwner
 * @memberof PetsAPI
 * @param {PetsAPIGetPetsForOwnerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Pet[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIGetPetsForOwnerPathParams = {
  ownerId: EnderId;
};

type PetsAPIGetPetsForOwnerSearchParams = {
  token?: string | undefined;
};

type PetsAPIGetPetsForOwnerBodyParams = {
  includeArchived?: boolean | undefined;
};

type PetsAPIGetPetsForOwnerPayload = PetsAPIGetPetsForOwnerPathParams &
  PetsAPIGetPetsForOwnerSearchParams &
  PetsAPIGetPetsForOwnerBodyParams;

function getPetsForOwnerUnsafeEffect(payload: PetsAPIGetPetsForOwnerPayload) {
  const { ownerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Pet[]>({
        body,
        decode: unsafeDecodeJsonResponse<Pet[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/pets/${ownerId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPetsForOwner(
  payload: PetsAPIGetPetsForOwnerPayload,
  options?: { signal?: AbortSignal },
): Promise<Pet[]> {
  return F.pipe(
    payload,
    getPetsForOwnerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPetsForOwner };
export type {
  PetsAPIGetPetsForOwnerBodyParams,
  PetsAPIGetPetsForOwnerPathParams,
  PetsAPIGetPetsForOwnerPayload,
  PetsAPIGetPetsForOwnerSearchParams,
};
