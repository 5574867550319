/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/mlsStatusHistory
 * @function getMLSStatusHistory
 * @memberof BuyAPI
 * @param {BuyAPIGetMLSStatusHistoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIGetMLSStatusHistoryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIGetMLSStatusHistoryResponse } from "../buy-api-get-m-l-s-status-history-response";

type BuyAPIGetMLSStatusHistoryPathParams = {
  dealId: EnderId;
};

type BuyAPIGetMLSStatusHistorySearchParams = {
  token?: string | undefined;
};

type BuyAPIGetMLSStatusHistoryPayload = BuyAPIGetMLSStatusHistoryPathParams &
  BuyAPIGetMLSStatusHistorySearchParams;

function getMLSStatusHistoryUnsafeEffect(
  payload: BuyAPIGetMLSStatusHistoryPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIGetMLSStatusHistoryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<BuyAPIGetMLSStatusHistoryResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/mlsStatusHistory`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMLSStatusHistory(
  payload: BuyAPIGetMLSStatusHistoryPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIGetMLSStatusHistoryResponse[]> {
  return F.pipe(
    payload,
    getMLSStatusHistoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMLSStatusHistory };
export type {
  BuyAPIGetMLSStatusHistoryPathParams,
  BuyAPIGetMLSStatusHistoryPayload,
  BuyAPIGetMLSStatusHistorySearchParams,
};
