import { useForm, zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { isEmpty } from "effect/String";
import { useCallback } from "react";
import { z } from "zod";

import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { PhoneInput } from "@ender/shared/ds/phone-input";
import { Stack } from "@ender/shared/ds/stack";
import { Switch } from "@ender/shared/ds/switch";
import { EnderInputText } from "@ender/shared/forms/ui/ender-input-text";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import type { ApplicationsAPIUpdateApplicationPayload } from "@ender/shared/generated/ender.api.leasing";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { isPossiblePhoneNumber } from "@ender/shared/utils/phone";

const FormSchema = z
  .object({
    firstTimeRenter: z.boolean(),
    landlordName: z.string().optional(),
    landlordEmail: z.string().email().or(z.literal("")).optional(),
    landlordPhone: z
      .string()
      .refine(
        (value) =>
          P.isNullable(value) ||
          !isEmpty(value) ||
          isPossiblePhoneNumber(value),
        {
          message: "Not a valid phone number",
        },
      )
      .optional(),
  })
  .refine(
    ({ firstTimeRenter, landlordName }) =>
      !(
        !firstTimeRenter &&
        (P.isNullable(landlordName) || (landlordName && isEmpty(landlordName)))
      ),
    {
      message: "Required if not a first time renter",
      path: ["landlordName"],
    },
  )
  .refine(
    ({ firstTimeRenter, landlordPhone, landlordEmail }) => {
      if (
        !firstTimeRenter &&
        (P.isNullable(landlordPhone) ||
          (landlordPhone && isEmpty(landlordPhone))) &&
        (P.isNullable(landlordEmail) ||
          (landlordEmail && isEmpty(landlordEmail)))
      ) {
        return false;
      }

      return true;
    },
    {
      message: "Either email or phone is required if not a first time renter",
      path: ["landlordEmail"],
    },
  );

type FormValues = z.infer<typeof FormSchema>;

type UpdateRentalHistoryFormProps = {
  application: GetApplicationGroupResponseApplicationResponse;
  applicationGroupId: EnderId;
  onCancel: () => void;
  onSuccess: () => void;
};

/**
 * @deprecated use widgets-application-rental-history-card
 */
function UpdateRentalHistoryForm({
  applicationGroupId,
  application,
  onSuccess,
  onCancel,
}: UpdateRentalHistoryFormProps) {
  const { applicant } = application;
  const { mutateAsync: updateApplication, isLoading } = useMutation({
    mutationFn: (values: ApplicationsAPIUpdateApplicationPayload) =>
      ApplicationsAPI.updateApplication(values),
    mutationKey: ["updateApplication", applicationGroupId],
  });
  const { landlordName, landlordEmail, landlordPhone, firstTimeRenter } =
    application;

  const form = useForm<FormValues>({
    initialValues: {
      firstTimeRenter,
      landlordEmail: landlordEmail || "",
      landlordName: landlordName || "",
      landlordPhone: landlordPhone || "",
    },
    validate: zodResolver(FormSchema),
  });

  const onFirstTimeRenterChange = useCallback(
    (e: boolean) => {
      if (e) {
        form.setValues({
          firstTimeRenter: true,
          landlordEmail: "",
          landlordName: "",
          landlordPhone: "",
        });
      } else {
        form.setFieldValue("firstTimeRenter", false);
      }
    },
    [form],
  );

  const onFormSubmit = useCallback(
    async (values: FormValues) => {
      const name = `${applicant.firstName} ${applicant.lastName}`.trim();
      //HACK API doesn't like getting values along with firstTimeRenter: true
      const valuesToSave = values.firstTimeRenter
        ? { firstTimeRenter: values.firstTimeRenter }
        : { ...values };

      await updateApplication({
        applicationGroupId: applicationGroupId,
        targetUserId: applicant.userId,
        ...valuesToSave,
      });
      showSuccessNotification({
        message: `Rental History for ${name} updated`,
      });
      onSuccess();
    },
    [updateApplication, applicant, applicationGroupId, onSuccess],
  );

  const isFieldsDisabled = isLoading || form.values.firstTimeRenter;

  return (
    <Stack>
      <UnmanagedForm form={form} onSubmit={onFormSubmit}>
        <Switch
          label="Applicant is a first time renter"
          value={form.values.firstTimeRenter}
          onChange={onFirstTimeRenterChange}
        />
        <Stack>
          <EnderInputText
            name="landlordName"
            label="Landlord Name"
            disabled={isFieldsDisabled}
          />
          <EnderInputText
            name="landlordEmail"
            label="Landlord Email"
            disabled={isFieldsDisabled}
          />
          <PhoneInput
            name="landlordPhone"
            label="Landlord Phone"
            disabled={isFieldsDisabled}
            {...form.getInputProps("landlordPhone")}
          />
          <Group justify={Justify.end} align={Align.center}>
            <Button
              onClick={onCancel}
              type="button"
              variant={ButtonVariant.transparent}>
              Cancel
            </Button>
            <Button loading={isLoading} type="submit">
              Save
            </Button>
          </Group>
        </Stack>
      </UnmanagedForm>
    </Stack>
  );
}

export { UpdateRentalHistoryForm };
