import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const VendorUserTagVendorUserTagTypeValues = [
  "PRINCIPAL",
  "ACCOUNTING",
  "PROJECT_ASSIGNMENT",
  "REMITTANCE_RECIPIENT",
  "TRUSTED_CONTACT",
] as const;

const VendorUserTagVendorUserTagTypeEffectSchema = Schema.Literal(
  ...VendorUserTagVendorUserTagTypeValues,
);

type VendorUserTagVendorUserTagType = Schema.Schema.Type<
  typeof VendorUserTagVendorUserTagTypeEffectSchema
>;

const VendorUserTagVendorUserTagTypeEnum = castEnum(
  VendorUserTagVendorUserTagTypeEffectSchema,
);

function randomVendorUserTagVendorUserTagType(): VendorUserTagVendorUserTagType {
  return rand(VendorUserTagVendorUserTagTypeValues);
}

export {
  randomVendorUserTagVendorUserTagType,
  VendorUserTagVendorUserTagTypeEffectSchema,
  VendorUserTagVendorUserTagTypeEnum,
  VendorUserTagVendorUserTagTypeValues,
};
export type { VendorUserTagVendorUserTagType };
