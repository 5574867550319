/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/getMoveOuts
 * @function upcomingMoveOuts
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIUpcomingMoveOutsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetMoveOutTableResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type {
  GetMoveOutTableRequestMoveOutSortKey,
  GetMoveOutTableRequestMoveOutTab,
} from "@ender/shared/generated/com.ender.middle.request";
import type { GetMoveOutTableResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIUpcomingMoveOutsSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIUpcomingMoveOutsBodyParams = {
  ascending?: boolean | undefined;
  leaseIds: EnderId[];
  limit?: number | undefined;
  marketIds: EnderId[];
  moveOutDateEnd?: SerializesInto<LocalDate> | undefined;
  moveOutDateStart?: SerializesInto<LocalDate> | undefined;
  moveOutTab: GetMoveOutTableRequestMoveOutTab;
  offset?: number | undefined;
  ownershipGroupIds: EnderId[];
  propertyIds: EnderId[];
  sortKey?: GetMoveOutTableRequestMoveOutSortKey | undefined;
};

type LeasingMiddleLayerAPIUpcomingMoveOutsPayload =
  LeasingMiddleLayerAPIUpcomingMoveOutsSearchParams &
    LeasingMiddleLayerAPIUpcomingMoveOutsBodyParams;

function upcomingMoveOutsUnsafeEffect(
  payload: LeasingMiddleLayerAPIUpcomingMoveOutsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetMoveOutTableResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetMoveOutTableResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/leasing/getMoveOuts",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function upcomingMoveOuts(
  payload: LeasingMiddleLayerAPIUpcomingMoveOutsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetMoveOutTableResponse> {
  return F.pipe(
    payload,
    upcomingMoveOutsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { upcomingMoveOuts };
export type {
  LeasingMiddleLayerAPIUpcomingMoveOutsBodyParams,
  LeasingMiddleLayerAPIUpcomingMoveOutsPayload,
  LeasingMiddleLayerAPIUpcomingMoveOutsSearchParams,
};
