/**
 * Creates a lead and prospect user
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/lead
 * @function addLead
 * @memberof LeasingAPI
 * @param {LeasingAPIAddLeadPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AddLeadResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Instant,
  LocalDate,
  SerializesInto,
} from "@ender/shared/core";
import type { AddLeadResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { LeadLeadSource } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIAddLeadPathParams = {
  propertyId: EnderId;
};

type LeasingAPIAddLeadSearchParams = {
  token?: string | undefined;
};

type LeasingAPIAddLeadBodyParams = {
  email: string;
  firstName: string;
  followUpTime?: SerializesInto<Instant> | undefined;
  lastName: string;
  moveInDate?: SerializesInto<LocalDate> | undefined;
  phone: string;
  source?: LeadLeadSource | undefined;
  unitId?: EnderId | undefined;
};

type LeasingAPIAddLeadPayload = LeasingAPIAddLeadPathParams &
  LeasingAPIAddLeadSearchParams &
  LeasingAPIAddLeadBodyParams;

function addLeadUnsafeEffect(payload: LeasingAPIAddLeadPayload) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AddLeadResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AddLeadResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/lead`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addLead(
  payload: LeasingAPIAddLeadPayload,
  options?: { signal?: AbortSignal },
): Promise<AddLeadResponse> {
  return F.pipe(
    payload,
    addLeadUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addLead };
export type {
  LeasingAPIAddLeadBodyParams,
  LeasingAPIAddLeadPathParams,
  LeasingAPIAddLeadPayload,
  LeasingAPIAddLeadSearchParams,
};
