import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Function as F } from "effect";
import type { ElementRef, ReactNode } from "react";
import { forwardRef, useId } from "react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { Inset } from "@ender/shared/ds/inset";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type { Vehicle } from "@ender/shared/generated/ender.model.leasing";
import { Color } from "@ender/shared/utils/theming";

type VehicleCardProps = {
  vehicle: Vehicle;
  isEditable?: boolean;
  onEditClick?: () => void;
  isDeletable?: boolean;
  onDeleteClick?: () => void;
  isWorking?: boolean;
  header?: ReactNode;
};

const VehicleCard = forwardRef<ElementRef<typeof Card>, VehicleCardProps>(
  function ApplicationVehicleCard(props, ref) {
    const {
      vehicle: { color, licensePlateNumber, make, model },
      isEditable,
      onEditClick = F.constVoid,
      isDeletable,
      onDeleteClick = F.constVoid,
      isWorking,
      header = <H4>{`${make} ${model}`}</H4>,
    } = props;

    const headingId = useId();
    return (
      <Card ref={ref} labelledBy={headingId}>
        <Stack>
          <Group justify={Justify.between}>
            <span id={headingId}>{header}</span>
            <Inset r={Spacing.sm} t={Spacing.sm} b={Spacing.sm}>
              <Group spacing={Spacing.xs}>
                {isDeletable && (
                  <ActionIcon
                    onClick={onDeleteClick}
                    variant={ButtonVariant.transparent}
                    color={Color.red}
                    loading={isWorking}>
                    <IconTrash />
                  </ActionIcon>
                )}
                {isEditable && (
                  <ActionIcon
                    onClick={onEditClick}
                    variant={ButtonVariant.transparent}
                    loading={isWorking}>
                    <IconEdit />
                  </ActionIcon>
                )}
              </Group>
            </Inset>
          </Group>
          <Grid spacingY={Spacing.none} underline="uneven">
            <Tuple label="Color" value={color} />
            <Tuple label="License Plate" value={licensePlateNumber} />
          </Grid>
        </Stack>
      </Card>
    );
  },
);

export { VehicleCard };

export type { VehicleCardProps };
