import { useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import * as A from "effect/Array";
import type { ReactElement, ReactNode } from "react";
import { useEffect, useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import { Select } from "@ender/shared/ds/select";
import { Tuple } from "@ender/shared/ds/tuple";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { LoadingIndicator } from "@ender/shared/utils/general";

import type { BankAccount } from "./types";

async function fetchBankAccounts(
  propertyIds: EnderId[],
  signal?: AbortSignal,
): Promise<BankAccount[]> {
  const _bankAccounts: BankAccount[] =
    await BankingAPI.searchBankAccountsByFirm(
      {
        propertyIds,
        firmIds: [],
        filters: [],
        fundIds: [],
      },
      { signal },
    );

  if (A.isEmptyArray(_bankAccounts)) {
    throw new Error(
      "There are no linked bank accounts associated with the properties for this invoice",
    );
  }

  const verifiedAccounts = _bankAccounts.filter(
    (account) =>
      !account.needsDwollaVerification && !account.needsLobVerification,
  );

  if (A.isEmptyArray(verifiedAccounts)) {
    throw new Error("The associated bank accounts have not yet been verified");
  }

  return verifiedAccounts;
}

type SelectBankAccountProps = {
  error?: ReactNode | null;
  invoiceType?: InvoiceInvoiceType;
  onChange: (accountId: O.Option<EnderId>) => void;
  propertyIds: EnderId[];
  value: O.Option<EnderId>;
};

function SelectBankAccount({
  invoiceType,
  ...props
}: SelectBankAccountProps): ReactElement {
  const { onChange, propertyIds, value } = props;

  const { data: bankAccounts = [], isFetching } = useQuery({
    queryKey: ["BankingAPI.searchBankAccountsByFirm", propertyIds] as const,
    queryFn: ({ signal: _signal }) => fetchBankAccounts(propertyIds, _signal),
    enabled: A.isNonEmptyArray(propertyIds),
  });

  const { data: bankAccountIdData, isLoading: isLoadingBankAccountIdData } =
    useQuery({
      queryKey: [
        "PropertiesAPI.getOperatingAccountIdByPropertyId",
        { invoiceType: invoiceType, propertyIds: propertyIds },
      ] as const,
      queryFn: ({ signal }) =>
        invoiceType &&
        PropertiesAPI.getOperatingAccountIdByPropertyId(
          {
            invoiceType: invoiceType,
            propertyIds: propertyIds,
          },
          { signal },
        ),
      enabled: P.isNotNullable(invoiceType),
    });

  // lazy useEffect
  useEffect(() => {
    if (A.isNonEmptyArray(bankAccounts) && O.isNone(value)) {
      // We cannot assume property ids are nonempty, safely accessing the first element
      const firstBankAccountId =
        bankAccountIdData?.[propertyIds?.[0]] || bankAccounts[0].id;
      onChange(O.some(firstBankAccountId));
    }
  }, [value, bankAccountIdData, bankAccounts, onChange, propertyIds]);

  const selectedBankAccount = useMemo<BankAccount | undefined>(() => {
    return bankAccounts?.find((account) => account.id === O.getOrNull(value));
  }, [bankAccounts, value]);

  if (isFetching || isLoadingBankAccountIdData) {
    return <LoadingIndicator message="Loading bank accounts..." />;
  }

  return (
    <>
      <Tuple
        label="Bank Account"
        value={
          <Select
            data={
              bankAccounts?.map((bankAccount) => ({
                label: bankAccount.name,
                value: bankAccount.id,
              })) ?? []
            }
            {...props}
          />
        }
      />
      <Tuple
        label="Account Number"
        value={`••••${selectedBankAccount?.mask}`}
      />
      <Tuple
        label="Account Institution"
        value={selectedBankAccount?.institution}
      />
    </>
  );
}

export { SelectBankAccount };
export type { SelectBankAccountProps };
