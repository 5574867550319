import {
  add,
  differenceInCalendarDays,
  endOfMonth,
  format,
  getDate,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  String as S,
} from "effect";
import { z } from "zod";

import { NULL } from "@ender/shared/constants/general";
import type { Instant, LocalDate, LocalTime } from "@ender/shared/core";
import { Instant$, LocalDate$, LocalTime$ } from "@ender/shared/core";
import { convertToNumber } from "@ender/shared/utils/string";

const InvalidDate = new Date(NaN);
// Date Formats
const DateSlashFormat = "MM/dd/yy";
const DateLongSlashFormat = "MM/dd/yyyy";
const DateShortFormat = "MMM d, yyyy";
const DateLongFormat = "MMMM d, yyyy";
const DateLongMonthFormat = "MMMM, yyyy";
const DateISOYearFormat = "yyyy";
const DateISOMonthFormat = "yyyy-MM";
const DateISOFormat = "yyyy-MM-dd";
// Time Formats
const Time12HourFormat = "h:mm a";
const TimeISOHourFormat = "HH";
const TimeISOMinuteFormat = "HH:mm";
const TimeISOSecondFormat = "HH:mm:ss";
const TimeISOFormat = "HH:mm:ss.SSS";
// DateTime Formats
const Timestamp24HourFormat = "MM/dd/yyyy HH:mm";
const Timestamp12HourFormat = "MM/dd/yyyy hh:mm a";
// Display only format no parse support
const DateLongMonthNameFormat = "MMMM";
const DateShortMonthNameFormat = "MMM";
const DateLongWeekdayNameFormat = "EEEE";
const DateShortWeekdayNameFormat = "EEE";
const userInputRX = /(\d{2})\/?(\d{1,2})?\/?(\d{2,4})?/;

type EnderDateTuple = [
  year: number,
  monthIndex: number,
  date?: number,
  hours?: number,
  minutes?: number,
  seconds?: number,
  ms?: number,
];
type DateParams = ConstructorParameters<typeof Date>;
type EnderDateValue = DateParams[0] | null | undefined;

type EnderDateParams =
  | [] // Empty Params
  | [value: EnderDateValue] // Single param
  | EnderDateTuple; // Multi param

/**
 * @deprecated Use Instant$, LocalDate$ or LocalTime$ as appropriate!
 */
class EnderDate extends Date {
  static of(...args: EnderDateParams): EnderDate {
    // eslint-disable-next-line ender-rules/no-new-ender-date
    return new EnderDate(...args);
  }

  /**
   * Extends the native JavaScript Date constructor to handle multiple types of input
   * and initialize the date accordingly.
   * @param {...EnderDateParams} args - Different types of parameters to initialize the date.
   */
  constructor(...args: EnderDateParams) {
    let dateTuple: EnderDateTuple;
    if (
      args.length <= 1 &&
      (P.isNullable(args[0]) ||
        (P.isString(args[0]) && S.isEmpty(args[0])) ||
        (P.isNumber(args[0]) && isNaN(args[0])))
    ) {
      // EmptyParams | "" | NaN | null | undefined
      super();
      return;
    } else if (args.length === 1 && P.isNumber(args[0])) {
      // EnderDateValue --> number
      super(args[0]);
      return;
    } else if (args.length === 1 && args[0] instanceof Date) {
      // EnderDateValue --> Extends Date
      super(args[0].getTime());
      return;
    } else if (args.length === 1 && P.isString(args[0])) {
      /**
       * When the timezone information is absent, date-only forms are interpreted as a UTC time and date-time forms are
       * interpreted as local time.
       * This is due to a historical spec error that was not consistent with ISO 8601 but could not be changed due to
       * web compatibility.
       * By parsing with date-fns TimeZone information is sourced from the reference date.
       * See: https://maggiepint.com/2017/04/11/fixing-javascript-date-web-compatibility-and-reality/
       */
      const dateStr: string = args[0];
      dateTuple = F.pipe(
        [
          DateSlashFormat,
          DateLongSlashFormat,
          DateShortFormat,
          DateLongFormat,
          DateLongMonthFormat,
          DateISOYearFormat,
          DateISOMonthFormat,
          DateISOFormat,
          Time12HourFormat,
          TimeISOHourFormat,
          TimeISOMinuteFormat,
          TimeISOSecondFormat,
          TimeISOFormat,
          Timestamp24HourFormat,
          Timestamp12HourFormat,
        ],
        A.map((formatString) => parse(dateStr, formatString, 0)),
        A.findFirst((date) => !isNaN(date.getTime())),
        O.orElse((): O.Option<Date> => {
          const maybeDate = parseISO(dateStr);
          return isNaN(maybeDate.getTime()) ? O.none() : O.some(maybeDate);
        }),
        O.getOrElse(F.constant(InvalidDate)),
        (date) => [
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
          date.getMilliseconds(),
        ],
      );
    } else {
      // Dates constructed with individual date components have no timezone information and are interpreted as local time.
      dateTuple = args as EnderDateTuple;
    }

    const [year, month, date = 1, hours = 0, minutes = 0, seconds = 0, ms = 0] =
      dateTuple;
    super(year, month, date, hours, minutes, seconds, ms);
  }

  toInstant$(): Instant$ {
    return Instant$.of(this.valueOf());
  }

  toLocalDate$(): LocalDate$.LocalDate {
    return LocalDate$.of(this.toISOString());
  }

  toLocalTime$(): LocalTime$ {
    return LocalTime$.of(this.valueOf());
  }

  static fromInstant$(instant: Instant$): EnderDate {
    return EnderDate.of(instant.valueOf());
  }

  static fromLocalDate$(localDate: LocalDate$.LocalDate): EnderDate {
    return EnderDate.of(localDate.value);
  }

  static fromLocalTime$(localTime: LocalTime$): EnderDate {
    return EnderDate.of(localTime.valueOf());
  }

  /**
   * Adds a specified number of days to the date and returns a new EnderDate instance.
   * @param {number} days - The number of days to add.
   * @returns {EnderDate} A new EnderDate instance with the added days.
   */
  addDays(days: number): EnderDate {
    const newDate = add(this, { days });
    return EnderDate.of(newDate);
  }

  /**
   * Adds a specified number of months to the date and returns a new EnderDate instance.
   * @param {number} months - The number of months to add.
   * @returns {EnderDate} A new EnderDate instance with the added months.
   */
  addMonths(months: number): EnderDate {
    const newDate = add(this, { months });
    return EnderDate.of(newDate);
  }

  /**
   * Formats the date into a short format with slashes (MM/dd/yy).
   * @returns {string} The formatted date string.
   */
  toSlashDateString(): string {
    return format(this, DateSlashFormat);
  }

  /**
   * Formats the date into a long format with slashes (MM/dd/yyyy).
   * @returns {string} The formatted date string.
   */
  toLongSlashDateString(): string {
    return format(this, DateLongSlashFormat);
  }

  /**
   * Formats the date into a short text string showing the month and day (MMM d, yyyy).
   * @returns {string} The formatted date string.
   */
  toShortDateString(): string {
    return format(this, DateShortFormat);
  }

  /**
   * Formats the date into a long text string showing the full month and day (MMMM d, yyyy).
   * @returns {string} The formatted date string.
   */
  toLongDateString(): string {
    return format(this, DateLongFormat);
  }

  /**
   * Formats the date to an ISO string showing only the year and month (yyyy-MM).
   * @returns {string} The ISO month string.
   */
  toMonthISOString(): string {
    return format(this, DateISOMonthFormat);
  }

  /**
   * Formats the date to a localized ISO string (yyyy-MM-DD).
   * @returns {string} The formatted date string.
   */
  toLocalISOString(): string {
    return format(this, DateISOFormat);
  }

  /**
   * Checks if the instance date is today.
   * @returns {boolean} True if the date is today, otherwise false.
   */
  isToday(): boolean {
    // NOTE: Using DateFns isToday() method does not allow easy Unit Testing
    return this.toLocalDateString() === EnderDate.today.toLocalDateString();
  }

  /**
   * Static accessor for today's date as an EnderDate instance.
   * @returns {EnderDate} An EnderDate instance representing today.
   * @deprecated use LocalDate$.today
   */
  static get today(): EnderDate {
    return EnderDate.of();
  }

  /**
   * Static accessor for tomorrow's date as an EnderDate instance.
   * @returns {EnderDate} An EnderDate instance representing tomorrow.
   * @deprecated use LocalDate$.today().add({days: 1})
   */
  static get tomorrow(): EnderDate {
    const newDate = add(EnderDate.today, { days: 1 });
    return EnderDate.of(newDate);
  }

  /**
   * Static accessor for yesterday's date as an EnderDate instance.
   * @returns {EnderDate} An EnderDate instance representing yesterday.
   * @deprecated use LocalDate$.today().add({days: -1})
   */
  static get yesterday(): EnderDate {
    const newDate = add(EnderDate.today, { days: -1 });
    return EnderDate.of(newDate);
  }

  /**
   * Static getter for the current year.
   * @returns {number} The current year.
   * @deprecated use LocalDate$.today().year
   */
  static get currentYear(): number {
    return EnderDate.today.getFullYear();
  }

  /**
   * Calculates the date a certain number of days in the future from today.
   * @param {number} days - Number of days in the future.
   * @returns {EnderDate} An EnderDate instance representing the future date.
   * @deprecated use LocalDate$.today().add({days: 1})
   */
  static getDaysInFuture(days: number): EnderDate {
    const newDate = add(EnderDate.today, { days });
    return EnderDate.of(newDate);
  }

  /**
   * Formats the input as a year string with 4 digits.
   * If the year has 2 digits, it's considered to be after 2000
   * (unless a different prefix is provided).
   * Returns current year if the string is invalid.
   * @returns string
   * @deprecated use this in a <DateDisplay> component or create a separate year-formatting util outside of LocalDate$
   */
  static formatYearString(yearInput: string, prefix = "20"): string {
    const year = convertToNumber(yearInput);
    if (!isNaN(year)) {
      const yearStr = `${year}`;
      if (yearStr.length === 4) {
        return yearStr;
      }
      if (yearStr.length === 2) {
        return `${prefix}${yearStr}`;
      }
      if (yearStr.length === 1) {
        return `${prefix}0${yearStr}`;
      }
    }
    return EnderDate.currentYear.toString();
  }

  /**
   * Converts a user input string into an EnderDate instance, or returns null if the input is invalid.
   * The input string is expected to match a specific regex pattern to extract the date components.
   * @param {string} dateInput - The user input string containing date information.
   * @returns {EnderDate | null} A new EnderDate instance or null if input is invalid.
   * @deprecated no replacement. Parsing direct user input should involve a DateInput
   */
  static fromUserInput(dateInput: string): EnderDate | null {
    const matches = dateInput.match(userInputRX);
    if (!matches) {
      return null;
    }
    const [, month, dayInput, yearInput] = matches;
    const day = dayInput || "01";
    const year = EnderDate.formatYearString(yearInput);
    const dateString = `${year}-${month}-${day}`;
    return EnderDate.fromLocalDate(dateString as LocalDate);
  }

  /**
   * Checks if two EnderDate instances represent the same calendar day.
   * @param {EnderDate} date1 - The first date to compare.
   * @param {EnderDate} date2 - The second date to compare.
   * @returns {boolean} True if both dates are the same day, otherwise false.
   *
   * @deprecated use LocalDate$'s compareTo, isEqual, or Equivalence/Order from local-date.utils
   */
  static areDaysEqual(date1: EnderDate, date2: EnderDate): boolean {
    return date1.toLocalISOString() === date2.toLocalISOString();
  }

  /**
   * Checks if the instance date is the same day as another EnderDate instance.
   * @param {EnderDate} date - The date to compare with the instance date.
   * @returns {boolean} True if both dates are the same day, otherwise false.
   *
   * @deprecated use LocalDate$.isEqual(other)
   */
  isSameDayAs(date: EnderDate): boolean {
    return EnderDate.areDaysEqual(this, date);
  }

  /**
   * Determines if a given birthday date is of a minor (under 18 years).
   * @param {EnderDateValue} birthday - The birthday to check.
   * @returns {boolean} True if the birthday is of a minor, otherwise false.
   *
   * @deprecated use pipe(birthday, LocalDate$.parse, O.map((val) => val.isAfter(LocalDate$.today().add({years: -18}))), O.getOrElse(() => false))
   */
  static isBirthdayMinor(birthday: EnderDateValue): boolean {
    const majorityDate = add(EnderDate.of(birthday), { years: 18 });
    return EnderDate.of(majorityDate).isOnOrAfterToday();
  }

  /**
   * Checks if a date is in the future relative to today.
   * @param {EnderDateValue} date - The date to check.
   * @returns {boolean} True if the date is in the future, otherwise false.
   *
   * @deprecated use pipe(val, LocalDate$.parse, O.map((v) => v.isAfter(LocalDate$.today()), O.getOrElse(() => false))
   */
  static isDateInFuture(date: EnderDateValue): boolean {
    return isAfter(EnderDate.of(date), EnderDate.today);
  }

  /**
   * Checks if a date is in the past relative to today.
   * @param {EnderDateValue} date - The date to check.
   * @returns {boolean} True if the date is in the past, otherwise false.
   *
   * @deprecated use pipe(val, LocalDate$.parse, O.map((v) => v.isBefore(LocalDate$.today()), O.getOrElse(() => false))
   */
  static isDateInPast(date: EnderDateValue): boolean {
    return isBefore(EnderDate.of(date), EnderDate.today);
  }

  /**
   * Checks if one date is on or before another date.
   * @param {EnderDateValue} startDate - The start date.
   * @param {EnderDateValue} endDate - The end date.
   * @returns {boolean} True if the start date is on or before the end date.
   *
   * @deprecated `LocalDate$.parse()` both dates- if either is None, return False.
   * Otherwise, compare the contents of the two options using Order or Equivalence
   */
  static isValidDateRange(
    startDate: EnderDateValue,
    endDate: EnderDateValue,
  ): boolean {
    return (
      differenceInCalendarDays(
        EnderDate.of(endDate),
        EnderDate.of(startDate),
      ) >= 0
    );
  }

  /**
   * Returns an EnderDate instance representing one month before today.
   * @returns {EnderDate} An EnderDate instance of one month before today.
   *
   * @deprecated use LocalDate$.today().add({months: -1})
   */
  static getOneMonthBeforeToday(): EnderDate {
    return EnderDate.of(add(EnderDate.today, { months: -1 }));
  }

  /**
   * Returns the ISO string representing one month before today.
   * @returns {string} ISO string of one month before today.
   *
   * @deprecated ISO format doesn't make sense for a LocalDate representation. use Instant$'s toJSON instead
   */
  static getOneMonthBeforeTodayISO(): string {
    return EnderDate.getOneMonthBeforeToday().toLocalISOString();
  }

  /**
   * Returns an EnderDate instance representing the start of the next month.
   * @returns {EnderDate} An EnderDate instance of the start of the next month.
   *
   * @deprecated use LocalDate$.parse(startOfMonth(LocalDate$.today().add({months: 1}).toDate()));
   * startOfMonth can be found in `date-fns`
   */
  static getStartOfNextMonth(): EnderDate {
    return EnderDate.of(startOfMonth(add(EnderDate.today, { months: 1 })));
  }

  /**
   * Returns an EnderDate instance representing the end of the last month.
   * @returns {EnderDate} An EnderDate instance of the end of the last month.
   *
   * @deprecated use LocalDate$.parse(endOfMonth(LocalDate$.today().add({months: -1}).toDate()));
   * endOfMonth can be found in `date-fns`
   */
  static getEndOfLastMonth(): EnderDate {
    return EnderDate.of(endOfMonth(add(EnderDate.today, { months: -1 })));
  }

  /**
   * Converts a 24-hour time string into an EnderDate, setting the time on a specified base date.
   * @param {string | null} timeString - The 24-hour time string (e.g., "14:30").
   * @param {Date} baseDate - The base date to which the time should be set.
   * @returns {EnderDate} An EnderDate instance with the specified time set.
   *
   * @deprecated use `Instant$.parse()` and then `toLocalDate()`
   */
  static from24HourTime(
    timeString?: string | null,
    baseDate = EnderDate.today,
  ): EnderDate {
    if (!timeString) {
      return EnderDate.of(baseDate);
    }
    const [hours, minutes = 0, seconds = 0, milliseconds = 0] = timeString
      .split(/[:.]/)
      .map(Number);
    return EnderDate.of(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate(),
      hours,
      minutes,
      seconds,
      milliseconds,
    );
  }

  /**
   * Converts a 12-hour time string into an EnderDate, setting the time on a specified base date.
   * @returns {EnderDate} An EnderDate instance with the specified time set.
   *
   * @deprecated use `Instant$.parse()` and then `toLocalDate()`
   */
  static from12HourTime(
    timeString?: string | null,
    baseDate = EnderDate.today,
  ): EnderDate {
    if (!timeString) {
      return EnderDate.of(baseDate);
    }
    const [time, period] = timeString.split(" ");

    let [hours, minutes = 0, seconds = 0, milliseconds = 0] = time
      .split(/[:.]/)
      .map(Number);
    if (period.toLowerCase() === "pm" && hours < 12) {
      hours += 12;
    }
    if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }
    return EnderDate.of(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate(),
      hours,
      minutes,
      seconds,
      milliseconds,
    );
  }

  /**
   * use the LocalDate$'s deserialization to create a Date,
   * and then create the EnderDate from that
   * @param date
   * @returns
   *
   * @deprecated no need for this any more
   */
  static fromLocalDate(date: LocalDate): EnderDate | null {
    if (S.isEmpty(date)) {
      return NULL;
    }
    return EnderDate.of(date);
  }

  /**
   * Converts the date and time of this EnderDate instance to a 24-hour time string.
   * @returns {string} The 24-hour formatted time string.
   *
   * @deprecated use Instant and an InstantDisplay
   */
  to24HourString(): string {
    return EnderDate.to24HourString(this);
  }

  /**
   * Converts the given EnderDateValue into a formatted date and time string.
   * @param {EnderDateValue} value - The date value to convert.
   * @returns {string} The formatted date and time string.
   *
   * @deprecated use Instant and an InstantDisplay
   */
  static convertToDateTimeString(value?: EnderDateValue): string {
    const dateObj = EnderDate.of(value);
    const date = dateObj.toLongSlashDateString();
    const timeString = dateObj.toLocaleTimeString();
    const [hours, minutes] = timeString.split(":");
    const meridiem = timeString.split(" ")[1];
    const time = `${hours}:${minutes}${meridiem}`;
    return `${date} ${time}`;
  }

  /**
   * Formats the date to a string showing the full month and year.
   * @returns {string} Formatted string as "MMMM, yyyy".
   *
   * @deprecated use DateDisplay or `format(date.toDate(), "MMMM, yyyy")
   */
  toLongMonthString(): string {
    return format(this, DateLongMonthFormat);
  }

  /**
   * Formats the date to a string showing the full month and year without a comma.
   * @returns {string} Formatted string as "MMMM yyyy".
   *
   * @deprecated use DateDisplay or `format(date.toDate(), "MMMM, yyyy")
   */
  toLongMonthYearString(): string {
    return format(this, DateLongMonthFormat);
  }

  /**
   * Formats the date to a string showing the abbreviated month and full year.
   * @returns {string} Formatted string as "MMM yyyy".
   * @deprecated Use toShortMonthYearString from "@ender/shared/utils/local-date" instead
   */
  toShortMonthYearString(): string {
    return format(this, "MMM yyyy");
  }

  /**
   * Formats the date to an ISO string representing the first day of the current month.
   * @returns {string} ISO string of the first day of the current month.
   *
   * @deprecated should not be needed
   */
  toFirstDayOfThisMonthISOString(): string {
    return startOfMonth(this).toLocalISOString();
  }

  /**
   * Formats the date to an ISO string representing the first day of the current year.
   * @returns {string} ISO string of the first day of the current year.
   *
   * @deprecated should not be needed
   */
  toFirstDayOfThisYearISOString(): string {
    return startOfYear(this).toLocalISOString();
  }

  /**
   * Formats the date to a string suitable for timestamps using the local date format.
   * @returns {string} The formatted timestamp date string.
   *
   * @deprecated use <DateDisplay> or <InstantDisplay>
   */
  toTimestampDateString(): string {
    return format(this, DateLongSlashFormat);
  }

  /**
   * Checks if the date is before another specified date.
   * @param {EnderDateValue} date - The date to compare against.
   * @returns {boolean} True if before the specified date, otherwise false.
   *
   */
  isBefore(date: EnderDateValue): boolean {
    return isBefore(this, EnderDate.of(date));
  }

  /**
   * Checks if the date is on or before another specified date.
   * @param {EnderDateValue} date - The date to compare against.
   * @returns {boolean} True if on or before the specified date, otherwise false.
   */
  isOnOrBefore(date: EnderDateValue): boolean {
    return (
      isBefore(this, EnderDate.of(date)) || isSameDay(this, EnderDate.of(date))
    );
  }

  /**
   * Checks if the date is on or before today.
   * @returns {boolean} True if on or before today, otherwise false.
   */
  isOnOrBeforeToday(): boolean {
    return this.isOnOrBefore(EnderDate.today);
  }

  /**
   * Checks if the date is on or after another specified date.
   * @param {EnderDateValue} date - The date to compare against.
   * @returns {boolean} True if on or after the specified date, otherwise false.
   */
  isOnOrAfter(date: EnderDateValue): boolean {
    return (
      isAfter(this, EnderDate.of(date)) || isSameDay(this, EnderDate.of(date))
    );
  }

  /**
   * Checks if the date is on or after today.
   * @returns {boolean} True if on or after today, otherwise false.
   */
  isOnOrAfterToday(): boolean {
    return this.isOnOrAfter(EnderDate.today);
  }

  /**
   * Formats the time portion of the date to a string suitable for timestamps, using 24-hour or 12-hour format based on options.
   * @param {Object} options - Configuration options for time format.
   * @returns {string} The formatted timestamp time string.
   */
  toTimestampTimeString(options?: { hour24: boolean }): string {
    const formatString = options?.hour24
      ? TimeISOMinuteFormat
      : Time12HourFormat;
    return format(this, formatString);
  }

  /**
   * Combines the date and time into a single timestamp string.
   * @param {Object} options - Configuration options for time format.
   * @returns {string} The combined timestamp string.
   */
  toTimestampString(options?: { hour24: boolean }): string {
    const formatString = options?.hour24
      ? Timestamp24HourFormat
      : Timestamp12HourFormat;
    return format(this, formatString);
  }

  /**
   * Checks if the current date instance is invalid.
   * @returns {boolean} True if the date is invalid, otherwise false.
   */
  isInvalid(): boolean {
    return isNaN(this.getTime());
  }

  /**
   * An utility method to get the localized time in a user friendly format.
   * Ex:
   * Input = EnderDate.of(12453454346).getTimeSince()
   * Output = "4 hours ago"
   * @returns {string} Localized time stamp formatted based on a language
   */
  getTimeSince(
    options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
    cultureName = "en-US",
  ): string {
    const currentDateEpoch = Date.now();
    const minuteInMs = 60 * 1000;
    const hourInMs = 60 * minuteInMs;
    const dayInMs = 24 * hourInMs;
    const weekInMs = 7 * dayInMs;
    const timeDiffInMs = currentDateEpoch - this.getTime();
    const startOfYesterdayEpoch = startOfDay(
      add(currentDateEpoch, { days: -1 }),
    ).getTime();

    if (timeDiffInMs < minuteInMs) {
      return "less than a minute ago";
    }

    if (timeDiffInMs < hourInMs) {
      return "less than an hour ago";
    }

    if (timeDiffInMs >= hourInMs && timeDiffInMs < dayInMs) {
      return `${Math.floor(timeDiffInMs / hourInMs)} hours ago`;
    }

    if (this.getTime() >= startOfYesterdayEpoch) {
      return "Yesterday";
    }

    if (timeDiffInMs < weekInMs) {
      return `${Math.ceil(timeDiffInMs / dayInMs)} days ago`;
    }

    return this.toLocaleDateString(cultureName, options);
  }

  /**
   * Returns the full name of the month for the date.
   * @returns {string} The full month name.
   */
  getMonthName(): string {
    return format(this, DateLongMonthNameFormat);
  }

  /**
   * Returns the abbreviated name of the month for the date.
   * @returns {string} The abbreviated month name.
   */
  getMonthShortName(): string {
    return format(this, DateShortMonthNameFormat);
  }

  /**
   * Returns the full name of the weekday for the date.
   * @returns {string} The full weekday name.
   */
  getWeekdayName(): string {
    return format(this, DateLongWeekdayNameFormat);
  }

  /**
   * Returns the abbreviated name of the weekday for the date.
   * @returns {string} The abbreviated weekday name.
   */
  getWeekdayShortName(): string {
    return format(this, DateShortWeekdayNameFormat);
  }

  /**
   * Returns the day of the month with the appropriate ordinal suffix.
   * @returns {string} The day of the month with its suffix.
   */
  getDayOfMonth(): string {
    const dayOfMonth = getDate(this);
    return `${dayOfMonth}${this.daySuffix(dayOfMonth)}`;
  }

  /**
   * Splits the date string by either "T" or ":" based on the provided value.
   * @param {string} value - The delimiter to use for splitting ("T" or ":").
   * @returns {string[]} The split parts of the date string.
   */
  split(value: "T" | ":"): string[] {
    if (value === ":") {
      return this.to24HourString().split(":");
    }
    return this.toISOString().split("T");
  }

  // Utility method for day suffixes
  private daySuffix(day: number): string {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  /**
   * Returns the ISO string of the previous month from the current date.
   * @returns {string} The ISO month string of the previous month.
   */
  toPreviousMonthISOString(): string {
    return EnderDate.of(this.setDate(0)).toMonthISOString();
  }

  /**
   * Returns the time in 12-hour format.
   * @returns {string} Formatted time in 12-hour notation with AM/PM.
   */
  to12HourString(): string {
    return EnderDate.to12HourString(this);
  }

  /**
   * Converts a given date into a local ISO string ().
   * @param {number | string | Date} maybeDate - The date to convert.
   * @returns {string} Local ISO formatted string.
   */
  static toLocalISOString(maybeDate: number | string | Date): string {
    const date = EnderDate.of(maybeDate);
    return format(date, DateISOFormat);
  }

  /**
   * Formats a Date object into a 12-hour string with AM/PM.
   * @param {Date} date - The date to format.
   * @returns {string} Formatted time string with AM/PM.
   */
  static to12HourString(date: Date): string {
    return format(date, Time12HourFormat);
  }

  /**
   * Converts any given date value to a 24-hour formatted time string.
   * @param {number | string | Date} maybeDate - The date to format.
   * @returns {string} Formatted 24-hour time string.
   */
  static to24HourString(maybeDate: number | string | Date): string {
    const date =
      maybeDate instanceof Date ? maybeDate : EnderDate.of(maybeDate);
    const timeString = date.toTimeString();
    const [hoursVal, minutesVal] = timeString.split(":");
    const formattedHours = `0${hoursVal}`.slice(-2);
    const formattedMinutes = `0${minutesVal}`.slice(-2);
    return `${formattedHours}:${formattedMinutes}`;
  }

  /**
   * Converts a time string from 24-hour format to 12-hour format with AM/PM.
   * @param {string | Date} maybeDate - The time string or date object to convert.
   * @returns {string} The time in 12-hour format.
   */
  static convert24HourTimeTo12HourTime(maybeDate?: string | Date): string {
    if (typeof maybeDate === "string") {
      return format(
        parse(maybeDate, TimeISOMinuteFormat, EnderDate.today),
        Time12HourFormat,
      );
    } else if (maybeDate instanceof Date) {
      return format(maybeDate, Time12HourFormat);
    }
    return "";
  }

  /**
   * Calculates the difference in calendar days between two dates.
   * @param {EnderDateValue} startDate - The start date.
   * @param {EnderDateValue} endDate - The end date.
   * @returns {number} The number of days between the start and end date.
   */
  static calculateDifferenceBetweenDatesInDays(
    startDate: EnderDateValue,
    endDate: EnderDateValue,
  ): number {
    const start = EnderDate.of(startDate);
    const end = EnderDate.of(endDate);
    return differenceInCalendarDays(start, end);
  }

  /**
   * Converts a date string which can be deserialized by the BE into a Java Instant
   * @returns {string} The string representation expected by the Java Instant deserializer.
   */
  toInstantString(): Instant {
    return this.toISOString() as Instant;
  }

  /**
   * Converts a date string which can be deserialized by the BE into a Java LocalDate
   * @returns {string} The string representation expected by the Java LocalDate deserializer.
   */
  toLocalDateString(): LocalDate {
    return format(this, DateISOFormat) as LocalDate;
  }

  /**
   * Converts a date string which can be deserialized by the BE into a Java LocalTime
   * @returns {string} The string representation expected by the Java LocalTime deserializer.
   */
  toLocalTimeString(): LocalTime {
    return format(this, TimeISOFormat) as LocalTime;
  }

  /**
   * Serializes the date object to JSON in ISO format.
   * @returns {string} The ISO string of the date.
   */
  override toJSON(): string {
    // Since the vast majority of the time we want the local date, we'll use that as the default
    return this.toLocalDateString();
  }

  /**
   * Overrides the default toStringTag to provide a custom class name.
   */
  get [Symbol.toStringTag]() {
    return "EnderDate";
  }
}

const EnderDateSchema = z.instanceof(EnderDate);

export { EnderDate, EnderDateSchema };
export type { DateParams, EnderDateParams, EnderDateValue };
