/**
 * HTTP Method: POST
 * Pathname: /middleLayer/paid-check-invoice
 * @function createInvoiceWithPaidCheck
 * @memberof InvoicesMiddleLayerAPI
 * @param {InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice>}
 */
import { Effect, Function as F } from "effect";

import type {
  CreateInvoiceRequest,
  TransferDetails,
} from "@ender/shared/generated/ender.api.accounting.request";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckSearchParams = {
  token?: string | undefined;
};

type InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckBodyParams = {
  request: CreateInvoiceRequest;
  transferDetails: TransferDetails;
};

type InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckPayload =
  InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckSearchParams &
    InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckBodyParams;

function createInvoiceWithPaidCheckUnsafeEffect(
  payload: InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/paid-check-invoice",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createInvoiceWithPaidCheck(
  payload: InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckPayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice> {
  return F.pipe(
    payload,
    createInvoiceWithPaidCheckUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createInvoiceWithPaidCheck };
export type {
  InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckBodyParams,
  InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckPayload,
  InvoicesMiddleLayerAPICreateInvoiceWithPaidCheckSearchParams,
};
