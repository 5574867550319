/**
 * HTTP Method: GET
 * Pathname: /stripe/paymentMethods/cards/{cardId}
 * @function getCard
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetCardPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreditCard>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { CreditCard } from "@ender/shared/generated/ender.model.payments.card";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetCardPathParams = {
  cardId: EnderId;
};

type PaymentsAPIGetCardSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetCardPayload = PaymentsAPIGetCardPathParams &
  PaymentsAPIGetCardSearchParams;

function getCardUnsafeEffect(payload: PaymentsAPIGetCardPayload) {
  const { cardId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreditCard>({
        body,
        decode: unsafeDecodeJsonResponse<CreditCard>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/stripe/paymentMethods/cards/${cardId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCard(
  payload: PaymentsAPIGetCardPayload,
  options?: { signal?: AbortSignal },
): Promise<CreditCard> {
  return F.pipe(
    payload,
    getCardUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCard };
export type {
  PaymentsAPIGetCardPathParams,
  PaymentsAPIGetCardPayload,
  PaymentsAPIGetCardSearchParams,
};
