import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback } from "react";

import { Money$ } from "@ender/shared/core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationGroup } from "@ender/shared/generated/ender.model.leasing";

import type { ApplicationFormOutput } from "./widgets-application-summary-card";
import { ApplicationSummaryCard } from "./widgets-application-summary-card";

type ApplicationSummaryCardProps = {
  applications: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "applicantType"
  >[];
  applicationGroup: Pick<ApplicationGroup, "feeAmount" | "moveInDate" | "id">;
  isEditable?: boolean;
  onSuccess?: () => void;
};

const ConnectedApplicationSummaryCard = forwardRef<
  ElementRef<typeof ApplicationSummaryCard>,
  ApplicationSummaryCardProps
>(function ConnectedApplicationSummaryCard(props, ref) {
  const {
    applications,
    applicationGroup,
    isEditable = false,
    onSuccess = F.constVoid,
  } = props;

  const { mutateAsync: updateApplication, isLoading: isWorking } = useMutation({
    mutationFn: ApplicationsAPI.updateApplication,
  });

  const handleSubmit = useCallback(
    async (values: ApplicationFormOutput) => {
      await updateApplication({
        applicationGroupId: applicationGroup.id,
        ...values,
        applicationFeeAmount: values.applicationFeeAmount.pipe(
          O.map((val) => val.toJSON()),
          O.getOrElse(() => Money$.zero().toJSON()),
        ),
        moveInDate: O.getOrThrow(values.moveInDate).toJSON(),
      });
      onSuccess();
    },
    [applicationGroup.id, onSuccess, updateApplication],
  );

  return (
    <ApplicationSummaryCard
      applicationGroup={applicationGroup}
      applications={applications}
      isEditable={isEditable}
      onSubmit={handleSubmit}
      isWorking={isWorking}
      ref={ref}
    />
  );
});

export { ConnectedApplicationSummaryCard };

export type { ApplicationSummaryCardProps };
