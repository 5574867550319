import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Group } from "@ender/shared/ds/group";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";

type BankingDetailMenuProps = {
  isValidDateRange: boolean;
  openBaiHistoryModal: () => void;
  openConfigModal: () => void;
  openUploadBaiModal: () => void;
};

/**
 * 2023-12-15 Geoff: This was a quick step extracting the menu from the BankingDetail component.
 * But it's incomplete.  The BankingDetail component has a lot of logic that probably can and
 * should be extracted here instead of passed as props.
 */
function BankingDetailMenu({
  isValidDateRange,
  openBaiHistoryModal,
  openConfigModal,
  openUploadBaiModal,
}: BankingDetailMenuProps) {
  return (
    <Group>
      <Button variant={ButtonVariant.outlined} onClick={openUploadBaiModal}>
        Upload BAI file
      </Button>
      <Menu>
        <MenuTrigger>
          <Button variant={ButtonVariant.outlined}>Actions</Button>
        </MenuTrigger>
        <MenuContent align="end">
          {/* TODO probably have to fix a test */}
          <MenuButton onClick={openBaiHistoryModal}>Upload History</MenuButton>
          <MenuButton disabled={!isValidDateRange} onClick={openConfigModal}>
            Enter Bank Rec Info
          </MenuButton>
        </MenuContent>
      </Menu>
    </Group>
  );
}

export { BankingDetailMenu };
