/**
 * HTTP Method: POST
 * Pathname: /invoicePages/{invoicePageId}/trash
 * @function trashInvoicePage
 * @memberof InvoicesAPI
 * @param {InvoicesAPITrashInvoicePagePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPITrashInvoicePagePathParams = {
  invoicePageId: EnderId;
};

type InvoicesAPITrashInvoicePageSearchParams = {
  token?: string | undefined;
};

type InvoicesAPITrashInvoicePagePayload =
  InvoicesAPITrashInvoicePagePathParams &
    InvoicesAPITrashInvoicePageSearchParams;

function trashInvoicePageUnsafeEffect(
  payload: InvoicesAPITrashInvoicePagePayload,
) {
  const { invoicePageId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/invoicePages/${invoicePageId}/trash`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function trashInvoicePage(
  payload: InvoicesAPITrashInvoicePagePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    trashInvoicePageUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { trashInvoicePage };
export type {
  InvoicesAPITrashInvoicePagePathParams,
  InvoicesAPITrashInvoicePagePayload,
  InvoicesAPITrashInvoicePageSearchParams,
};
