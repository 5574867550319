/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/actualMoveOut/warnings
 * @function updateLeaseActualMoveOutDateWithWarnings
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UpdateLeaseWithWarningsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { UpdateLeaseWithWarningsResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPathParams = {
  leaseId: EnderId;
};

type LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsBodyParams = {
  actualMoveOutDate: SerializesInto<LocalDate>;
};

type LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPayload =
  LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPathParams &
    LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsSearchParams &
    LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsBodyParams;

function updateLeaseActualMoveOutDateWithWarningsUnsafeEffect(
  payload: LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UpdateLeaseWithWarningsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<UpdateLeaseWithWarningsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/actualMoveOut/warnings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateLeaseActualMoveOutDateWithWarnings(
  payload: LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPayload,
  options?: { signal?: AbortSignal },
): Promise<UpdateLeaseWithWarningsResponse> {
  return F.pipe(
    payload,
    updateLeaseActualMoveOutDateWithWarningsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateLeaseActualMoveOutDateWithWarnings };
export type {
  LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsBodyParams,
  LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPathParams,
  LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsPayload,
  LeasingAPIUpdateLeaseActualMoveOutDateWithWarningsSearchParams,
};
