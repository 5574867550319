import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  Record as R,
  pipe,
} from "effect";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useWatch } from "react-hook-form";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import {
  EnderIdFormSchema,
  LocalDate$,
  Money$,
  MoneyFormSchema,
} from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { DateInput, FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import type { RadioGroupData } from "@ender/shared/ds/radio-group";
import { RadioGroup } from "@ender/shared/ds/radio-group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FormSwitch } from "@ender/shared/ds/switch";
import { GeneralLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import {
  ApplicationsAPI,
  LeasingAPI,
} from "@ender/shared/generated/ender.api.leasing";
import { CategoryFlagEnum } from "@ender/shared/generated/ender.model.accounting";
import type { ApplicationGroup } from "@ender/shared/generated/ender.model.leasing";
import { castEnum } from "@ender/shared/utils/effect";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { computeSuggestedEndDate } from "../../new-lease/new-lease.utils";

const LeaseTermValues = [
  "CUSTOM",
  "MONTH_TO_MONTH",
  "SIX_MONTHS",
  "TWELVE_MONTHS",
  "EIGHTEEN_MONTHS",
] as const;
const LeaseTermEffectSchema = Schema.Literal(...LeaseTermValues);
type LeaseTerm = Schema.Schema.Type<typeof LeaseTermEffectSchema>;
const LeaseTermEnum = castEnum(LeaseTermEffectSchema);

const LeaseTermRadioGroupData: RadioGroupData<LeaseTerm>[] = [
  { label: "18 Months", value: LeaseTermEnum.EIGHTEEN_MONTHS },
  { label: "12 Months", value: LeaseTermEnum.TWELVE_MONTHS },
  { label: "6 Months", value: LeaseTermEnum.SIX_MONTHS },
  { label: "Month to Month", value: LeaseTermEnum.MONTH_TO_MONTH },
  { label: "Custom", value: LeaseTermEnum.CUSTOM },
] as const;

type OnSuccessProps = {
  openLeasePage: boolean;
  leaseId?: EnderId;
};

const LeaseFormSchema = Schema.Struct({
  achReversalFee: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  deposits: Schema.Record({
    key: EnderIdFormSchema,
    value: MoneyFormSchema.pipe(Schema.OptionFromSelf),
  }),
  depositsHeldExternally: Schema.Boolean,
  firstPaymentDueDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (date) => O.isNone(date) || date.value.isAfterOrEqual(LocalDate$.today()),
      { message: () => "Must be today or later" },
    ),
  ),
  inclusiveEndDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  lateFee: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  leaseTerm: Schema.Literal(
    LeaseTermEnum.EIGHTEEN_MONTHS,
    LeaseTermEnum.TWELVE_MONTHS,
    LeaseTermEnum.SIX_MONTHS,
    LeaseTermEnum.MONTH_TO_MONTH,
    LeaseTermEnum.CUSTOM,
  ),
  numGraceDays: Schema.Number.pipe(Schema.OptionFromSelf),
  rent: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  startDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
});

type LeaseFormOutput = Schema.Schema.Type<typeof LeaseFormSchema>;

type DraftLeaseFormProps = {
  applicationGroup: ApplicationGroup;
  leaseId?: EnderId;
  onCancel?: () => void;
  onSuccess?: (props: OnSuccessProps) => void;
};

function DraftLeaseForm(props: DraftLeaseFormProps) {
  const {
    applicationGroup,
    onCancel = F.constVoid,
    onSuccess = F.constVoid,
    leaseId: existingLeaseId,
  } = props;

  const { data: property } = useQuery({
    queryFn: ({ signal }) =>
      PropertiesAPI.getProperty(
        { propertyId: applicationGroup.propertyId },
        { signal },
      ),
    queryKey: [
      "PropertiesAPI.getProperty",
      applicationGroup.propertyId,
    ] as const,
  });

  const { data: listing } = useQuery({
    queryFn: ({ signal }) =>
      LeasingAPI.searchListings(
        {
          marketIds: [],
          ownershipGroupIds: [],
          propertyIds: [],
          unitIds: [applicationGroup.unitId],
        },
        { signal },
      ),
    queryKey: ["LeasingAPI.searchListings", applicationGroup.unitId] as const,
    select: (data) => data[0],
  });

  const {
    data: securityDepositCategories = [],
    isInitialLoading: isSecurityDepositCategoriesInitialLoading,
  } = useQuery({
    queryFn: ({ queryKey: [, params], signal }) => {
      const { keyword, categoryFlags } = params;
      return GeneralLedgerAPI.getTxCategories(
        {
          categoryFlags: [...categoryFlags],
          keyword,
        },
        { signal },
      );
    },
    queryKey: [
      "GeneralLedgerAPI.getTxCategories",
      { categoryFlags: [CategoryFlagEnum.SECURITY_DEPOSITS], keyword: "" },
    ] as const,
  });

  const { userPM } = useContext(UserContext);

  const initialLateFee = useMemo(() => {
    return pipe(
      Money$.parse(property?.lateFee),
      O.orElse(() => Money$.parse(userPM?.lateFee)),
      O.orElseSome(() => Money$.zero()),
    );
  }, [property?.lateFee, userPM.lateFee]);

  const form = useEffectSchemaForm({
    defaultValues: {
      achReversalFee: O.none(),
      deposits: {},
      depositsHeldExternally: false,
      firstPaymentDueDate: O.none(),
      inclusiveEndDate: computeSuggestedEndDate(
        LocalDate$.parse(applicationGroup?.moveInDate),
        O.none(),
        LeaseTermEnum.TWELVE_MONTHS,
      ),
      lateFee: initialLateFee,
      leaseTerm: LeaseTermEnum.TWELVE_MONTHS,
      numGraceDays: O.fromNullable(
        property?.numGraceDays ?? userPM?.numGraceDays,
      ),
      rent: Money$.parse(listing?.advertisedRent),
      startDate: LocalDate$.parse(applicationGroup.moveInDate),
    },
    schema: LeaseFormSchema,
  });

  const formDeposits = useWatch({
    control: form.control,
    name: "deposits",
  });

  // Prevent race condition where the form is initialized before the security deposit categories are loaded
  useEffect(() => {
    if (isSecurityDepositCategoriesInitialLoading) {
      return;
    }

    if (
      R.isEmptyRecord(formDeposits) &&
      A.isNonEmptyArray(securityDepositCategories)
    ) {
      const formDeposits = R.fromIterableWith(
        securityDepositCategories,
        (category) => [category.id, O.none()],
      );
      form.setValue("deposits", formDeposits);
    }
  }, [
    securityDepositCategories,
    formDeposits,
    form,
    isSecurityDepositCategoriesInitialLoading,
  ]);

  const [leaseTerm, startDate, inclusiveEndDate, depositsHeldExternally] =
    useWatch({
      control: form.control,
      name: [
        "leaseTerm",
        "startDate",
        "inclusiveEndDate",
        "depositsHeldExternally",
      ],
    });

  /**
   * This useEffect is used to populate the form with some known suggestion values
   * if the form is NOT dirty. (This is to prevent overwriting the user's changes)
   * property and listing are async so may be populated after the form is initialized
   * which justifies the useEffect
   */
  useEffect(() => {
    if (!form.formState.isDirty) {
      if (P.isNotNullable(listing)) {
        const rent = Money$.parse(listing.advertisedRent);
        form.setValue("rent", rent);
        // form.resetDirty({ ...form.values, rent });
      }
      if (P.isNotNullable(property)) {
        const lateFee = Money$.parse(property.lateFee ?? userPM?.lateFee);
        const numGraceDays = property.numGraceDays || userPM?.numGraceDays;
        form.setValue("lateFee", lateFee);
        form.setValue("numGraceDays", O.some(numGraceDays));
        // form.resetDirty({ ...form.values, lateFee, numGraceDays });
      }
    }
  }, [form, listing, property, userPM]);

  function onLeaseTermChange(value: O.Option<string>) {
    const termEnum = pipe(
      value,
      O.filter((v) => LeaseTermValues.includes(v as LeaseTerm)),
      O.map((v) => LeaseTermEnum[v as LeaseTerm]),
      O.getOrElse(() => LeaseTermEnum.TWELVE_MONTHS),
    );

    form.setValue("leaseTerm", termEnum);
    if (termEnum === LeaseTermEnum.MONTH_TO_MONTH) {
      form.setValue("inclusiveEndDate", O.none());
    } else {
      form.setValue(
        "inclusiveEndDate",
        computeSuggestedEndDate(startDate, inclusiveEndDate, termEnum),
      );
    }
  }

  function onStartDateChange(value: O.Option<LocalDate$.LocalDate>) {
    if (O.isSome(value)) {
      form.setValue(
        "inclusiveEndDate",
        computeSuggestedEndDate(value, inclusiveEndDate, leaseTerm),
      );
    }
    form.setValue("startDate", value);
  }

  const { mutateAsync: createLeaseDraft, isLoading: isSubmitting } =
    useMutation({
      mutationFn: ApplicationsAPI.draftLease,
      mutationKey: [
        "ApplicationsAPI.draftLease",
        applicationGroup?.id,
      ] as const,
    });

  const { mutateAsync: updateLease, isLoading: isUpdating } = useMutation({
    mutationFn: LeasingAPI.updateLease,
    mutationKey: ["LeasingAPI.updateLease"] as const,
  });

  const openLeasePageRef = useRef<boolean>(false);

  const handleSubmit = useCallback(
    async (values: LeaseFormOutput) => {
      let leaseId = existingLeaseId;
      if (P.isNullable(leaseId) && P.isNotNullable(applicationGroup?.id)) {
        const { id } = await createLeaseDraft({
          applicationGroupId: applicationGroup.id,
          startDate: pipe(
            values.startDate,
            O.map((v) => v.toJSON()),
            O.getOrThrow,
          ),
        });
        leaseId = id;
        showSuccessNotification({
          message: `Lease Draft created successfully`,
        });
      }

      if (P.isNullable(leaseId)) {
        fail("Failed to create lease draft");
        return;
      }

      await updateLease({
        ...values,
        achReversalFee: pipe(
          values.achReversalFee,
          O.filter(P.not(Money$.isZero)),
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        //do not provide the achReversalFee if it is zero or O.none
        deposits: values.depositsHeldExternally
          ? UNDEFINED
          : pipe(
              values.deposits,
              R.filterMap((value) =>
                O.exists(value, Money$.isPositive)
                  ? O.some(
                      pipe(
                        value,
                        O.map((v) => v.toJSON()),
                        O.getOrThrow,
                      ),
                    )
                  : O.none(),
              ),
              R.toEntries,
              A.map(([key, value]) => ({
                amount: value,
                glCategoryId: key,
              })),
            ),
        firstPaymentDueDate: pipe(
          values.firstPaymentDueDate,
          O.orElse(() =>
            pipe(
              values.startDate,
              //at minimum, first payment due date must be in the future
              O.map((v) =>
                LocalDate$.clamp(v, {
                  minimum: LocalDate$.today().add({ days: 1 }),
                }),
              ),
            ),
          ),
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
        inclusiveEndDate: pipe(
          values.inclusiveEndDate,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        lateFee: pipe(
          values.lateFee,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        leaseId,
        numGraceDays: O.getOrUndefined(values.numGraceDays),
        rent: pipe(
          values.rent,
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
        //default to start date if first payment due date is not set explicitly
        startDate: pipe(
          values.startDate,
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
      });

      onSuccess({
        leaseId,
        openLeasePage: openLeasePageRef.current,
      });
    },
    [
      existingLeaseId,
      applicationGroup.id,
      updateLease,
      onSuccess,
      createLeaseDraft,
    ],
  );

  return (
    <Skeleton visible={isSecurityDepositCategoriesInitialLoading}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Stack spacing={Spacing.xs}>
            <label>Lease Term</label>
            <RadioGroup
              data={LeaseTermRadioGroupData}
              onChange={onLeaseTermChange}
              value={O.some(leaseTerm)}
              horizontal
            />
          </Stack>
          <Grid>
            <DateInput
              label="Start Date"
              placeholder="Enter Start Date"
              name="startDate"
              value={startDate}
              onChange={onStartDateChange}
            />
            <FormDateInput
              form={form}
              label="End Date"
              placeholder="Enter End Date"
              disabled={leaseTerm !== LeaseTermEnum.CUSTOM}
              clearable={leaseTerm === LeaseTermEnum.CUSTOM}
              name="inclusiveEndDate"
            />
            <FormMoneyInput
              form={form}
              label="Rent"
              placeholder="Enter Monthly Rent"
              name="rent"
            />
            <FormSwitch
              form={form}
              label="Deposits Held Externally"
              name="depositsHeldExternally"
            />
            {!R.isEmptyRecord(formDeposits) &&
              securityDepositCategories?.map((category) => {
                return (
                  <FormMoneyInput
                    form={form}
                    name={`deposits.${category.id}`}
                    key={category.id}
                    label={category.accountName}
                    placeholder={`Enter ${category.accountName}`}
                    disabled={depositsHeldExternally}
                  />
                );
              })}
            <FormMoneyInput
              form={form}
              label="Late Fee"
              placeholder="Enter Late Fee"
              name="lateFee"
            />
            <FormDateInput
              form={form}
              label="First Payment Due"
              minDate={LocalDate$.today().add({ days: 1 })}
              name="firstPaymentDueDate"
              placeholder={pipe(
                startDate,
                O.map((v) =>
                  LocalDate$.clamp(v, {
                    minimum: LocalDate$.today().add({ days: 1 }),
                  }),
                ),
                O.map(LocalDate$.toFormatted(LocalDate$.Formats.DEFAULT)),
                O.getOrElse(() => "Enter First Payment Due Date"),
              )}
            />
            <FormNumberInput
              form={form}
              label="Grace Period (Days)"
              placeholder="Enter Grace Period"
              // min={0}
              precision={0}
              name="numGraceDays"
            />
            <FormMoneyInput
              form={form}
              label="NSF Fee"
              placeholder="Enter ACH Reversal Fee"
              name="achReversalFee"
            />
          </Grid>
          <Group justify={Justify.end}>
            <Button
              onClick={onCancel}
              type="button"
              variant={ButtonVariant.transparent}>
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.outlined}
              type="submit"
              loading={isSubmitting || isUpdating}
              onClick={() => {
                openLeasePageRef.current = false;
              }}>
              Confirm & Close
            </Button>
            <Button
              type="submit"
              onClick={() => {
                openLeasePageRef.current = true;
              }}
              loading={isSubmitting || isUpdating}>
              Confirm & Open Lease Page
            </Button>
          </Group>
        </Stack>
      </Form>
    </Skeleton>
  );
}

export { DraftLeaseForm };
export type { OnSuccessProps as DraftLeaseFormOnSuccessProps };
