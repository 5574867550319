import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useCallback } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import type { EnderId, Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const IncomeSourceFormSchema = Schema.Struct({
  description: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Required" }),
  ),
  yearlyEstimate: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<Money$.Money> => O.isSome(input), {
      message: () => "Required",
    }),
  ),
});

type IncomeSourceFormInput = Schema.Schema.Encoded<
  typeof IncomeSourceFormSchema
>;
type IncomeSourceFormOutput = Schema.Schema.Type<typeof IncomeSourceFormSchema>;

type AddIncomeSourceFormProps = {
  applicationId: EnderId;
  applicationGroupId: EnderId;
  onSuccess: () => void;
  onCancel: () => void;
};

function AddIncomeSourceForm({
  applicationId,
  onSuccess,
  onCancel,
  applicationGroupId,
}: AddIncomeSourceFormProps) {
  const form = useForm<IncomeSourceFormInput>({
    defaultValues: {
      description: "",
      yearlyEstimate: O.none(),
    },
    mode: "onSubmit",
    resolver: effectTsResolver(IncomeSourceFormSchema),
  });

  const { mutateAsync: saveIncome, isLoading } = useMutation({
    mutationFn: async (values: IncomeSourceFormOutput) => {
      const { yearlyEstimate, ...otherValue } = values;
      await ApplicationsAPI.addOtherIncome({
        applicationGroupId,
        applicationId,
        yearlyEstimate: O.getOrThrow(yearlyEstimate).toJSON(),
        ...otherValue,
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: IncomeSourceFormOutput) => {
      await saveIncome(values);
      showSuccessNotification({ message: "Additional Income Saved" });
      onSuccess();
    },
    [onSuccess, saveIncome],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput
          label="Description"
          placeholder="Enter Description"
          form={form}
          name="description"
        />
        <FormMoneyInput
          label="Earnings Per Year"
          placeholder="Enter Earnings Per Year"
          form={form}
          name="yearlyEstimate"
        />
        <Group justify={Justify.end} align={Align.center}>
          <Button
            variant={ButtonVariant.transparent}
            type="button"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { AddIncomeSourceForm };
