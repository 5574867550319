import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyAreaApplianceFinishTypeValues = [
  "WHITE",
  "BLACK",
  "STAINLESS_STEEL",
  "MIXED",
] as const;

const PropertyAreaApplianceFinishTypeEffectSchema = Schema.Literal(
  ...PropertyAreaApplianceFinishTypeValues,
);

type PropertyAreaApplianceFinishType = Schema.Schema.Type<
  typeof PropertyAreaApplianceFinishTypeEffectSchema
>;

const PropertyAreaApplianceFinishTypeEnum = castEnum(
  PropertyAreaApplianceFinishTypeEffectSchema,
);

function randomPropertyAreaApplianceFinishType(): PropertyAreaApplianceFinishType {
  return rand(PropertyAreaApplianceFinishTypeValues);
}

export {
  PropertyAreaApplianceFinishTypeEffectSchema,
  PropertyAreaApplianceFinishTypeEnum,
  PropertyAreaApplianceFinishTypeValues,
  randomPropertyAreaApplianceFinishType,
};
export type { PropertyAreaApplianceFinishType };
