import type { ReactNode } from "react";
import { createStore } from "zustand/vanilla";

import { UNDEFINED } from "@ender/shared/constants/general";
import { createBoundUseStoreWithSelectors } from "@ender/shared/utils/store";

type Breadcrumb = {
  title: ReactNode;
  href?: string;
};

type SubRoute = {
  path: string;
  //TODO rename to title or label
  subRoute: string;
};

type HeaderStoreState = {
  breadcrumbs: Breadcrumb[];
  pageActionButton: ReactNode;
  subMenu: SubRoute[];
};

type HeaderStoreActions = {
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  /**
   * TODO maybe remove this in favor of a more portal-y approach with general content
   */
  setPageActionButton: (pageActionButton: ReactNode) => void;
  setSubMenu: (subMenu: SubRoute[]) => void;
};

type HeaderStore = HeaderStoreState & HeaderStoreActions;

const headerStore = createStore<HeaderStore>()((set, _get) => ({
  breadcrumbs: [],
  pageActionButton: UNDEFINED,
  setBreadcrumbs: (breadcrumbs) =>
    set(() => ({
      breadcrumbs,
    })),
  setPageActionButton: (pageActionButton) =>
    set(() => ({
      pageActionButton,
    })),
  setSubMenu: (subMenu) =>
    set(() => ({
      subMenu,
    })),
  subMenu: [],
}));

const useHeaderStore =
  createBoundUseStoreWithSelectors<HeaderStore>(headerStore);

export { headerStore, useHeaderStore };
export type { Breadcrumb, HeaderStoreState, SubRoute };
