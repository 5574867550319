/**
 * HTTP Method: GET
 * Pathname: /accounting/glCategoryGroupings
 * @function getGLCategoryGroupings
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetGLCategoryGroupingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetGLCategoryGroupingsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetGLCategoryGroupingsResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetGLCategoryGroupingsSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetGLCategoryGroupingsPayload =
  GeneralLedgerAPIGetGLCategoryGroupingsSearchParams;

function getGLCategoryGroupingsUnsafeEffect(
  payload: GeneralLedgerAPIGetGLCategoryGroupingsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetGLCategoryGroupingsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetGLCategoryGroupingsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/accounting/glCategoryGroupings",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getGLCategoryGroupings(
  payload: GeneralLedgerAPIGetGLCategoryGroupingsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetGLCategoryGroupingsResponse> {
  return F.pipe(
    payload,
    getGLCategoryGroupingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getGLCategoryGroupings };
export type {
  GeneralLedgerAPIGetGLCategoryGroupingsPayload,
  GeneralLedgerAPIGetGLCategoryGroupingsSearchParams,
};
