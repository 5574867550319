import { useQuery } from "@tanstack/react-query";
import { Array as A } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceAllocationHistoryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import { EnderTableTanstack, useTable } from "@ender/shared/ui/table-tanstack";

import { columns } from "./allocation-history-table-columns";
import type { AllocationsTablePropsAllocation } from "./allocations-table";

import styles from "./allocations-table.module.css";

function AllocationHistoryTable({
  invoiceId,
  allocations,
}: {
  invoiceId: EnderId;
  allocations: AllocationsTablePropsAllocation[];
}) {
  const { data: histories = [], isLoading } = useQuery({
    queryFn: () => InvoicesAPI.getInvoiceAllocationHistory({ invoiceId }),
    queryKey: ["InvoicesAPI.getInvoiceAllocationHistory", invoiceId],
  });

  const table = useTable<InvoiceAllocationHistoryResponse>({
    columns,
    data: histories,
  });

  if (A.isEmptyArray(allocations)) {
    return <div>No allocations set</div>;
  }

  return (
    <Skeleton visible={isLoading}>
      <EnderTableTanstack
        className={styles.allocationsTable}
        table={table}
        title="AllocationHistory"
      />
    </Skeleton>
  );
}

export { AllocationHistoryTable };
