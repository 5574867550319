/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/repairCalculations
 * @function saveRepairCalculation
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPISaveRepairCalculationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnderwritingAPISaveRepairCalculationPathParams = {
  dealId: EnderId;
};

type UnderwritingAPISaveRepairCalculationSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPISaveRepairCalculationBodyParams = {
  adjustmentAmount?: SerializesInto<Money> | undefined;
  adjustmentReason?: string | undefined;
  appliancesCost?: SerializesInto<Money> | undefined;
  countertopCost?: SerializesInto<Money> | undefined;
  exteriorCost?: SerializesInto<Money> | undefined;
  flooringPercentToReplace?: SerializesInto<Percent> | undefined;
  foundationCost?: SerializesInto<Money> | undefined;
  hvacCost?: SerializesInto<Money> | undefined;
  kitchenCabinetsCost?: SerializesInto<Money> | undefined;
  roofCost?: SerializesInto<Money> | undefined;
};

type UnderwritingAPISaveRepairCalculationPayload =
  UnderwritingAPISaveRepairCalculationPathParams &
    UnderwritingAPISaveRepairCalculationSearchParams &
    UnderwritingAPISaveRepairCalculationBodyParams;

function saveRepairCalculationUnsafeEffect(
  payload: UnderwritingAPISaveRepairCalculationPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/repairCalculations`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function saveRepairCalculation(
  payload: UnderwritingAPISaveRepairCalculationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    saveRepairCalculationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { saveRepairCalculation };
export type {
  UnderwritingAPISaveRepairCalculationBodyParams,
  UnderwritingAPISaveRepairCalculationPathParams,
  UnderwritingAPISaveRepairCalculationPayload,
  UnderwritingAPISaveRepairCalculationSearchParams,
};
