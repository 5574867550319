import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const InputHeightValues = ["TALL", "SHORT"] as const;
const InputHeightSchema = z.enum(InputHeightValues);
type InputHeight = z.infer<typeof InputHeightSchema>;

const InputHeightEnum = castEnum<InputHeight>(InputHeightSchema);

function generateInputHeight(height: InputHeight) {
  return {
    input: {
      height: height === "TALL" ? "40px" : "32px",
      minHeight: height === "TALL" ? "40px" : "32px",
    },
  };
}

export {
  generateInputHeight,
  InputHeightEnum,
  InputHeightSchema,
  InputHeightValues,
};
export type { InputHeight };
