import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitParkingTypeValues = [
  "NONE",
  "CARPORT",
  "ATTACHED_GARAGE",
  "DETACHED_GARAGE",
  "OFF_STREET",
  "ON_STREET",
] as const;

const UnitParkingTypeEffectSchema = Schema.Literal(...UnitParkingTypeValues);

type UnitParkingType = Schema.Schema.Type<typeof UnitParkingTypeEffectSchema>;

const UnitParkingTypeEnum = castEnum(UnitParkingTypeEffectSchema);

function randomUnitParkingType(): UnitParkingType {
  return rand(UnitParkingTypeValues);
}

export {
  randomUnitParkingType,
  UnitParkingTypeEffectSchema,
  UnitParkingTypeEnum,
  UnitParkingTypeValues,
};
export type { UnitParkingType };
