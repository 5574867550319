import type { BadgeColors } from "@ender/shared/ds/badge";
import { BadgeColor } from "@ender/shared/ds/badge";
import type {
  PaymentPlanPaymentPlanStatus,
  PaymentPlanPaymentPlanType,
} from "@ender/shared/generated/ender.model.misc";
import {
  PaymentPlanPaymentPlanStatusEnum,
  PaymentPlanPaymentPlanTypeEnum,
} from "@ender/shared/generated/ender.model.misc";

const PaymentPlanTypeToLabelMap: Record<PaymentPlanPaymentPlanType, string> = {
  [PaymentPlanPaymentPlanTypeEnum.HOUSING_AUTHORITY]: "Housing Authority",
  [PaymentPlanPaymentPlanTypeEnum.PM]: "Property Manager",
};

const PaymentPlanStatusToBadgeColorMap: Record<
  PaymentPlanPaymentPlanStatus,
  BadgeColors
> = {
  [PaymentPlanPaymentPlanStatusEnum.ACTIVE]: BadgeColor.green,
  [PaymentPlanPaymentPlanStatusEnum.MISSED]: BadgeColor.red,
  [PaymentPlanPaymentPlanStatusEnum.COMPLETE]: BadgeColor.slate,
};

type InstallmentBadgeMeta = {
  color: BadgeColors;
  displayText: string;
};

const InstallmentStatusToBadgeDisplayMap: Record<
  PaymentPlanPaymentPlanStatus,
  InstallmentBadgeMeta
> = {
  [PaymentPlanPaymentPlanStatusEnum.ACTIVE]: {
    color: BadgeColor.slate,
    displayText: "Upcoming",
  },
  [PaymentPlanPaymentPlanStatusEnum.MISSED]: {
    color: BadgeColor.red,
    displayText: "Missed",
  },
  [PaymentPlanPaymentPlanStatusEnum.COMPLETE]: {
    color: BadgeColor.green,
    displayText: "Paid",
  },
};

export {
  InstallmentStatusToBadgeDisplayMap,
  PaymentPlanStatusToBadgeColorMap,
  PaymentPlanTypeToLabelMap,
};
