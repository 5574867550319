import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const DecisionValues = ["Approved", "Created", "Rejected"] as const;
const DecisionSchema = z.enum(DecisionValues);
type Decision = z.infer<typeof DecisionSchema>;
const DecisionEnum = castEnum<Decision>(DecisionSchema);

export { DecisionEnum, DecisionSchema };
export type { Decision };
