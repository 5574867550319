/**
 * HTTP Method: POST
 * Pathname: /stripe/fee
 * @function getStripeFee
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetStripeFeePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetStripeFeeResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { GetStripeFeeResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetStripeFeeSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetStripeFeeBodyParams = {
  amount: SerializesInto<Money>;
  cardId: EnderId;
};

type PaymentsAPIGetStripeFeePayload = PaymentsAPIGetStripeFeeSearchParams &
  PaymentsAPIGetStripeFeeBodyParams;

function getStripeFeeUnsafeEffect(payload: PaymentsAPIGetStripeFeePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetStripeFeeResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetStripeFeeResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/stripe/fee",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getStripeFee(
  payload: PaymentsAPIGetStripeFeePayload,
  options?: { signal?: AbortSignal },
): Promise<GetStripeFeeResponse> {
  return F.pipe(
    payload,
    getStripeFeeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getStripeFee };
export type {
  PaymentsAPIGetStripeFeeBodyParams,
  PaymentsAPIGetStripeFeePayload,
  PaymentsAPIGetStripeFeeSearchParams,
};
