/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/contacts
 * @function getLeaseContacts
 * @memberof LeasingAPI
 * @param {LeasingAPIGetLeaseContactsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeaseSerializerLeaseContact[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeaseSerializerLeaseContact } from "@ender/shared/generated/ender.arch.serializer.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetLeaseContactsPathParams = {
  leaseId: EnderId;
};

type LeasingAPIGetLeaseContactsSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetLeaseContactsPayload = LeasingAPIGetLeaseContactsPathParams &
  LeasingAPIGetLeaseContactsSearchParams;

function getLeaseContactsUnsafeEffect(
  payload: LeasingAPIGetLeaseContactsPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeaseSerializerLeaseContact[]>({
        body,
        decode: unsafeDecodeJsonResponse<LeaseSerializerLeaseContact[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/contacts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeaseContacts(
  payload: LeasingAPIGetLeaseContactsPayload,
  options?: { signal?: AbortSignal },
): Promise<LeaseSerializerLeaseContact[]> {
  return F.pipe(
    payload,
    getLeaseContactsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeaseContacts };
export type {
  LeasingAPIGetLeaseContactsPathParams,
  LeasingAPIGetLeaseContactsPayload,
  LeasingAPIGetLeaseContactsSearchParams,
};
