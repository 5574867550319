import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";

import styles from "./shared-ds-skeleton.module.css";

type SkeletonProps = {
  height?: "half" | "full";
  /**
   * corresponds with TailwindCSS border-radius classes.
   * default "normal". Affects all corners.
   */
  rounded?: "none" | "normal" | "lg" | "full";
  visible?: boolean;
  //
};

const SkeletonVariantGenerator = cva(
  [styles.positioner, "*:invisible *:after:visible *:after:skeleton"],
  {
    defaultVariants: {
      height: "full",
      rounded: "normal",
    },
    variants: {
      height: {
        full: "*:after:h-full",
        half: "*:after:h-1/2",
      },
      rounded: {
        full: "*:after:rounded-full",
        lg: "*:after:rounded-lg",
        none: "*:after:rounded-0",
        normal: "*:after:rounded",
      },
    },
  },
);

const Skeleton = forwardRef<HTMLDivElement, PropsWithChildren<SkeletonProps>>(
  function Skeleton(props, ref) {
    const { visible = false, children } = props;

    return (
      <span
        className={clsx("contents", {
          [SkeletonVariantGenerator(props)]: visible,
        })}
        role={visible ? "progressbar" : UNDEFINED}
        ref={ref}>
        {children}
      </span>
    );
  },
);

export { Skeleton };

export type { SkeletonProps };
