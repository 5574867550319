/**
 * HTTP Method: POST
 * Pathname: /middleLayer/ap-ar-results
 * @function getPayablesReceivables
 * @memberof InvoicesMiddleLayerAPI
 * @param {InvoicesMiddleLayerAPIGetPayablesReceivablesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPayablesReceivablesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GetPayablesReceivablesResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { GetInvoicesRequestBodyApprovalStatus } from "@ender/shared/generated/ender.api.accounting.request";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { MultiDBQueriesGetInvoicesSortableFields } from "@ender/shared/generated/ender.db.multi";
import type {
  MoneyTransferTransferType,
  Party,
} from "@ender/shared/generated/ender.model.payments";
import type {
  InvoiceInvoiceType,
  InvoicePayableType,
  InvoicePaymentMethod,
} from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesMiddleLayerAPIGetPayablesReceivablesSearchParams = {
  token?: string | undefined;
};

type InvoicesMiddleLayerAPIGetPayablesReceivablesBodyParams = {
  amountMatch?: string | undefined;
  approvalStatus: GetInvoicesRequestBodyApprovalStatus[];
  assignedToMe?: boolean | undefined;
  bankAccountId?: EnderId | undefined;
  bestPaymentMethodType?: InvoicePaymentMethod | undefined;
  bulkUploadFriendlyId?: string | undefined;
  descending?: boolean | undefined;
  earliestPeriodDateFilter?: PeriodFilter | undefined;
  externalInvoiceId?: string | undefined;
  firmIds: EnderId[];
  fundIds: EnderId[];
  inclusiveEndInvoiceDueDate?: SerializesInto<LocalDate> | undefined;
  inclusiveEndInvoiceLedgerDate?: SerializesInto<LocalDate> | undefined;
  inclusiveEndPaidDate?: SerializesInto<LocalDate> | undefined;
  invoiceType: InvoiceInvoiceType;
  limit?: number | undefined;
  marketIds: EnderId[];
  owedByIds: EnderId[];
  owedByParty?: Party | undefined;
  owedToIds: EnderId[];
  owedToParty?: Party | undefined;
  payableTypes: InvoicePayableType[];
  paymentMethodType?: MoneyTransferTransferType | undefined;
  propertyIds: EnderId[];
  sortBy?: MultiDBQueriesGetInvoicesSortableFields | undefined;
  startAfterInvoiceId?: EnderId | undefined;
  startInvoiceDueDate?: SerializesInto<LocalDate> | undefined;
  startInvoiceLedgerDate?: SerializesInto<LocalDate> | undefined;
  startPaidDate?: SerializesInto<LocalDate> | undefined;
};

type InvoicesMiddleLayerAPIGetPayablesReceivablesPayload =
  InvoicesMiddleLayerAPIGetPayablesReceivablesSearchParams &
    InvoicesMiddleLayerAPIGetPayablesReceivablesBodyParams;

function getPayablesReceivablesUnsafeEffect(
  payload: InvoicesMiddleLayerAPIGetPayablesReceivablesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPayablesReceivablesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPayablesReceivablesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/ap-ar-results",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPayablesReceivables(
  payload: InvoicesMiddleLayerAPIGetPayablesReceivablesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPayablesReceivablesResponse> {
  return F.pipe(
    payload,
    getPayablesReceivablesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPayablesReceivables };
export type {
  InvoicesMiddleLayerAPIGetPayablesReceivablesBodyParams,
  InvoicesMiddleLayerAPIGetPayablesReceivablesPayload,
  InvoicesMiddleLayerAPIGetPayablesReceivablesSearchParams,
};
