import type { PropsWithChildren } from "react";
import { forwardRef, useContext, useMemo, useState } from "react";

import { UserContext } from "@ender/shared/contexts/user";
import { Card } from "@ender/shared/ds/card";
import { Overflow } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import type { SearchApplicationsPageRequestApplicationsPageTab } from "@ender/shared/generated/com.ender.middle.request";
import { SearchApplicationsPageRequestApplicationsPageTabEnum } from "@ender/shared/generated/com.ender.middle.request";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { RightRail } from "@ender/shared/ui/right-rail";
import {
  EnderTableTanstackV2,
  TableTop,
} from "@ender/shared/ui/table-tanstack";
import { ConnectedApplicationsAnnouncementForm } from "@ender/widgets/leasing/applications/applications-announcement-form";

import type { ApplicationsFilterState } from "../applications-filters/applications-filters.types";
import { getVisibleTabs } from "./all-applications-table.utils";
import { AllApplicationsTableTabs } from "./tabs/all-applications-table-tabs";
import { useAllApplicationsTable } from "./use-all-applications-table";
import { useTabCounts } from "./use-tab-counts-data";

type AllApplicationsTableProps = Omit<ApplicationsFilterState, "statuses">;

const AllApplicationsTable = forwardRef<
  HTMLDivElement,
  PropsWithChildren<AllApplicationsTableProps>
>(function AllApplicationsTable(props, ref) {
  const {
    applicantName,
    ownershipGroups,
    markets,
    properties,
    onlyNeedsFollowUp,
    usingHcv,
  } = props;

  const [statusTab, setStatusTab] =
    useState<SearchApplicationsPageRequestApplicationsPageTab>(
      SearchApplicationsPageRequestApplicationsPageTabEnum.IN_PROGRESS,
    );

  const { userPM } = useContext(UserContext);
  const visibleTabs = getVisibleTabs({
    allowMultipleInReview: Boolean(userPM?.allowMultipleApplicationsInReview),
    twoStepReview: Boolean(userPM?.twoStepReviewForApplications),
  });

  const filters = useMemo(() => {
    return {
      applicantName,
      ownershipGroups,
      markets,
      onlyNeedsFollowUp,
      properties,
      usingHcv,
    };
  }, [
    applicantName,
    markets,
    onlyNeedsFollowUp,
    ownershipGroups,
    properties,
    usingHcv,
  ]);

  const [
    isMessageRailOpen,
    { setTrue: openMessageRail, setFalse: closeMessageRail },
  ] = useBoolean(false);

  const { table } = useAllApplicationsTable({
    filters,
    tab: statusTab,
    openMessageRail,
    isMessageRailOpen,
  });

  const onStatusTabChange = (
    tab: SearchApplicationsPageRequestApplicationsPageTab,
  ) => {
    table.resetRowSelection();
    setStatusTab(tab);
  };

  const { tabCounts, isLoading: isTabCountsLoading } = useTabCounts({
    filterState: filters,
    visibleTabs,
  });

  return (
    <Card borderless ref={ref}>
      <RightRail
        title="Send Group Message"
        opened={isMessageRailOpen}
        onClose={closeMessageRail}>
        <ConnectedApplicationsAnnouncementForm
          applicationGroupIds={
            table
              .getSelectedRowModel()
              .rows.map((row) => row.original.applicationGroupId) ?? []
          }
          onSuccess={closeMessageRail}
        />
      </RightRail>
      <Stack overflow={Overflow.auto}>
        <AllApplicationsTableTabs
          activeTab={statusTab}
          setActiveTab={onStatusTabChange}
          visibleTabs={visibleTabs}
          tabCounts={tabCounts ?? {}}
          isLoading={isTabCountsLoading}
        />
        <TableTop table={table} />
        <EnderTableTanstackV2 table={table} maxHeight="60vh" />
      </Stack>
    </Card>
  );
});

export { AllApplicationsTable };
