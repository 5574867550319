// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  Content,
  Header,
  Item,
  Root,
  Trigger,
} from "@radix-ui/react-accordion";
import { IconChevronDown } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Array as A, Option as O } from "effect";
import type { PropsWithChildren, ReactNode } from "react";
import { forwardRef, useCallback, useState } from "react";

function useSingleAccordionState(value?: string) {
  const [values, setValues] = useState(
    O.fromNullable(value).pipe(O.map(A.of), O.getOrElse(A.empty)),
  );

  const onChange = useCallback((accordionValues: string[]) => {
    setValues(A.last(accordionValues).pipe(O.map(A.of), O.getOrElse(A.empty)));
  }, []);

  return [values, onChange] as const;
}

type AccordionProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
};

const Accordion = forwardRef<HTMLDivElement, PropsWithChildren<AccordionProps>>(
  function Accordion(props, ref) {
    const { children, value, onChange, disabled } = props;

    return (
      <Root
        value={value}
        onValueChange={onChange}
        disabled={disabled}
        type="multiple"
        ref={ref}>
        {children}
      </Root>
    );
  },
);

type AccordionItemProps = {
  disabled?: boolean;
  /**
   * the unique value of the accordion item (within this accordion)
   */
  value: string;
};

/**
 * the item which can be expanded or collapsed. If you need a tailwind class to target the state of the item, use
 * ```
 * group-data-[state=open]/accordion
 * ```
 *
 * This will allow you to target the open state of the accordion item.
 * See AccordionChevron for an example of how this is used for styling.
 */
const AccordionItem = forwardRef<
  HTMLDivElement,
  PropsWithChildren<AccordionItemProps>
>(function AccordionItem(props, ref) {
  const { children, disabled = false, value } = props;
  return (
    <Item
      value={value}
      disabled={disabled}
      ref={ref}
      className="group/accordion">
      {children}
    </Item>
  );
});

type AccordionTriggerProps = {
  actions?: ReactNode;
  borderless?: boolean;
};

const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<AccordionTriggerProps>
>(function AccordionTrigger(props, ref) {
  const { children, actions, borderless } = props;
  return (
    <Header asChild>
      <div
        className={clsx(
          "group/accordion-header w-full flex sticky top-0 pr-2 z-10 bg-white",
          {
            "border-t border-slate-200": !borderless,
          },
        )}>
        <Trigger
          ref={ref}
          className={clsx(
            "py-3 px-2 text-start text-sm/standard",
            "flex items-center grow gap-2",
            "group/accordion-trigger",
          )}>
          <IconChevronDown
            size={24}
            className={clsx(
              /**
               * if the closest ancestor of `group/accordion` is closed,
               * that should take precedence over a distant ancestor having the open state
               */
              "group-data-[state=open]/accordion-trigger:rotate-180 group-data-[state=closed]/accordion-trigger:rotate-0",
              "group-data-[disabled]/accordion-trigger:invisible",
              "text-slate-500 shrink-0",
            )}
          />
          <div className="relative grow">{children}</div>
        </Trigger>
        {actions}
      </div>
    </Header>
  );
});

type AccordionContentProps = {
  variant?: "default" | "bordered";
};

const AccordionContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<AccordionContentProps>
>(function AccordionContent(props, ref) {
  const { variant = "default", children } = props;
  return (
    <Content
      ref={ref}
      className={clsx("py-4 pr-6 relative z-0", {
        "pl-6 border-l-2 border-l-primary-500 bg-slate-50":
          variant === "bordered",
        "pl-9": variant === "default",
      })}>
      {children}
    </Content>
  );
});

export {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  useSingleAccordionState,
};

export type { AccordionContentProps, AccordionProps };
