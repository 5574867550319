import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitZoneUnitZoneTypeValues = [
  "ADDRESS_NUMBERS",
  "AIR_FILTER",
  "ATTIC_INSULATION",
  "ATTIC_LADDER",
  "CABINETS",
  "CEILING_FAN",
  "CEILING",
  "CLOSET",
  "CONDENSER",
  "COUNTERTOPS",
  "DECK",
  "DISHWASHER",
  "DOORBELL",
  "DOORS",
  "DRAIN",
  "DRIVEWAY",
  "DRYER_VENT",
  "DRYER",
  "DUCT_WORK",
  "ELECTRIC_METER",
  "ELECTRICAL_PANEL",
  "EXHAUST_FAN",
  "FENCE_AND_GATE",
  "FIREPLACE",
  "FLOORING",
  "FOUNDATION_DRAIN",
  "FRIDGE",
  "FURNACE_AND_AIR_HANDLER",
  "GARAGE_DOOR_MOTOR",
  "GARAGE_DOOR",
  "GAS_METER",
  "GUTTERS",
  "HANDRAIL",
  "HARDWARE",
  "KITCHEN_ISLAND",
  "LANDSCAPE",
  "LIGHT_FIXTURES",
  "MAILBOX",
  "MICROWAVE",
  "MIRROR",
  "NEIGHBOR",
  "OVEN",
  "PANTRY",
  "PATIO",
  "POOL_EQUIPMENT",
  "POOL",
  "PORCH",
  "RANGE",
  "ROOF",
  "SHED",
  "SHELVING",
  "SHOWER_TUB_COMBO",
  "SHOWER",
  "SIDEWALK",
  "SIDING",
  "SINK",
  "SKYLIGHT",
  "SMOKE_ALARM",
  "STOVETOP",
  "STREET",
  "SUMP_PUMP",
  "SUPPLY_PLUMBING",
  "SWITCHES_AND_OUTLETS",
  "THERMOSTAT",
  "TOILET",
  "TUB",
  "UNDERNEATH_SINK_CABINET",
  "VANITY",
  "VENT_HOOD",
  "WALLS",
  "WASHER_BOX",
  "WATER_HEATER",
  "WATER_METER",
  "WINDOWS_AND_BLINDS",
  "OTHER_EXTERIOR",
  "OTHER_HVAC",
  "OTHER_INTERIOR",
  "OTHER_PROPERTY",
] as const;

const UnitZoneUnitZoneTypeEffectSchema = Schema.Literal(
  ...UnitZoneUnitZoneTypeValues,
);

type UnitZoneUnitZoneType = Schema.Schema.Type<
  typeof UnitZoneUnitZoneTypeEffectSchema
>;

const UnitZoneUnitZoneTypeEnum = castEnum(UnitZoneUnitZoneTypeEffectSchema);

function randomUnitZoneUnitZoneType(): UnitZoneUnitZoneType {
  return rand(UnitZoneUnitZoneTypeValues);
}

export {
  randomUnitZoneUnitZoneType,
  UnitZoneUnitZoneTypeEffectSchema,
  UnitZoneUnitZoneTypeEnum,
  UnitZoneUnitZoneTypeValues,
};
export type { UnitZoneUnitZoneType };
