// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { TimeInputProps } from "@mantine/dates";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { TimeInput } from "@mantine/dates";





/**
 * @deprecated use TimeInput from `shared/ds/time-input`
 */
function EnderTimeInput({
  wrapperProps,
  format = "12",
  clearable = true,
  ...props
}: TimeInputProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <TimeInput
      wrapperProps={wrapperProps}
      format={format}
      clearable={clearable}
      {...props}
    />
  );
}

export { EnderTimeInput };
