import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GroupedGLCategoryBalancesReportProviderGroupByIntervalValues = [
  "DAY",
  "WEEK",
  "MONTH",
  "PERIOD",
  "QUARTER",
  "YEAR",
] as const;

const GroupedGLCategoryBalancesReportProviderGroupByIntervalEffectSchema =
  Schema.Literal(
    ...GroupedGLCategoryBalancesReportProviderGroupByIntervalValues,
  );

type GroupedGLCategoryBalancesReportProviderGroupByInterval =
  Schema.Schema.Type<
    typeof GroupedGLCategoryBalancesReportProviderGroupByIntervalEffectSchema
  >;

const GroupedGLCategoryBalancesReportProviderGroupByIntervalEnum = castEnum(
  GroupedGLCategoryBalancesReportProviderGroupByIntervalEffectSchema,
);

function randomGroupedGLCategoryBalancesReportProviderGroupByInterval(): GroupedGLCategoryBalancesReportProviderGroupByInterval {
  return rand(GroupedGLCategoryBalancesReportProviderGroupByIntervalValues);
}

export {
  GroupedGLCategoryBalancesReportProviderGroupByIntervalEffectSchema,
  GroupedGLCategoryBalancesReportProviderGroupByIntervalEnum,
  GroupedGLCategoryBalancesReportProviderGroupByIntervalValues,
  randomGroupedGLCategoryBalancesReportProviderGroupByInterval,
};
export type { GroupedGLCategoryBalancesReportProviderGroupByInterval };
