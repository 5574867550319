/**
 * HTTP Method: GET
 * Pathname: /stripe/publicKey
 * @function getStripePublicKey
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetStripePublicKeyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetStripePublicKeyResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetStripePublicKeyResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetStripePublicKeySearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetStripePublicKeyPayload =
  PaymentsAPIGetStripePublicKeySearchParams;

function getStripePublicKeyUnsafeEffect(
  payload: PaymentsAPIGetStripePublicKeyPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetStripePublicKeyResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetStripePublicKeyResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/stripe/publicKey",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getStripePublicKey(
  payload: PaymentsAPIGetStripePublicKeyPayload,
  options?: { signal?: AbortSignal },
): Promise<GetStripePublicKeyResponse> {
  return F.pipe(
    payload,
    getStripePublicKeyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getStripePublicKey };
export type {
  PaymentsAPIGetStripePublicKeyPayload,
  PaymentsAPIGetStripePublicKeySearchParams,
};
