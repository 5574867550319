import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetInvoicesRequestBodyApprovalStatusValues = [
  "NEW",
  "APPROVED",
  "PAID",
  "REJECTED",
] as const;

const GetInvoicesRequestBodyApprovalStatusEffectSchema = Schema.Literal(
  ...GetInvoicesRequestBodyApprovalStatusValues,
);

type GetInvoicesRequestBodyApprovalStatus = Schema.Schema.Type<
  typeof GetInvoicesRequestBodyApprovalStatusEffectSchema
>;

const GetInvoicesRequestBodyApprovalStatusEnum = castEnum(
  GetInvoicesRequestBodyApprovalStatusEffectSchema,
);

function randomGetInvoicesRequestBodyApprovalStatus(): GetInvoicesRequestBodyApprovalStatus {
  return rand(GetInvoicesRequestBodyApprovalStatusValues);
}

export {
  GetInvoicesRequestBodyApprovalStatusEffectSchema,
  GetInvoicesRequestBodyApprovalStatusEnum,
  GetInvoicesRequestBodyApprovalStatusValues,
  randomGetInvoicesRequestBodyApprovalStatus,
};
export type { GetInvoicesRequestBodyApprovalStatus };
