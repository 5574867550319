/**
 * HTTP Method: GET
 * Pathname: /leases/securityDeposits
 * @function getSecurityDeposits
 * @memberof LeasingAPI
 * @param {LeasingAPIGetSecurityDepositsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeasingServiceSecurityDepositsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeasingServiceSecurityDepositsResponse } from "@ender/shared/generated/ender.service.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetSecurityDepositsSearchParams = {
  token?: string | undefined;
  propertyId?: EnderId | undefined;
};

type LeasingAPIGetSecurityDepositsPayload =
  LeasingAPIGetSecurityDepositsSearchParams;

function getSecurityDepositsUnsafeEffect(
  payload: LeasingAPIGetSecurityDepositsPayload,
) {
  const { token, propertyId, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeasingServiceSecurityDepositsResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<LeasingServiceSecurityDepositsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/leases/securityDeposits",
        searchParams: [
          ["token", token],
          ["propertyId", propertyId],
        ],
      }),
    ),
  );
}

function getSecurityDeposits(
  payload: LeasingAPIGetSecurityDepositsPayload,
  options?: { signal?: AbortSignal },
): Promise<LeasingServiceSecurityDepositsResponse> {
  return F.pipe(
    payload,
    getSecurityDepositsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSecurityDeposits };
export type {
  LeasingAPIGetSecurityDepositsPayload,
  LeasingAPIGetSecurityDepositsSearchParams,
};
