import { Fragment } from "react";

import {
  FormSearchInput,
  hydrateProperty,
  searchProperties,
} from "@ender/entities/search-input";
import type { FormSubSectionReference } from "@ender/form-system/base";
import { FormList, FormSection } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Divider } from "@ender/shared/ds/divider";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { isMultiple } from "@ender/shared/utils/is";

import { AllocationsSummary } from "./allocations-summary";
import { DebitCreditAllocationListItem } from "./debit-credit-allocation-list-item";
import { emptyJournalEntryAllocationsWithProperty } from "./journal-entry-right-rail.constants";
import type {
  JournalEntryFormOutput,
  PropertyIdWithAllocationsInput,
} from "./journal-entry-right-rail.types";

type PropertyWithAllocationsProps = {
  canRemove: boolean;
  isEditing: boolean;
  isReadonly: boolean;
  onRemoveProperty: () => void;
} & FormSubSectionReference<
  JournalEntryFormOutput,
  PropertyIdWithAllocationsInput
>;

/** Displays a fieldset panel containing a Property and its associated Allocations */
function PropertyWithAllocations(props: PropertyWithAllocationsProps) {
  const { canRemove, isEditing, isReadonly, form, name, onRemoveProperty } =
    props;
  const allocationError =
    form.formState?.errors.allocationsWithProperties?.root?.message;
  return (
    <FormSection form={form} name={name}>
      {({ section }) => {
        return (
          <Card>
            <Group justify={Justify.end}>
              <Button
                variant={ButtonVariant.transparent}
                onClick={onRemoveProperty}
                disabled={!canRemove || props.isReadonly}>
                Remove Property
              </Button>
            </Group>
            <Stack>
              <Tooltip
                label="Editing the property for a journal entry is not yet supported on Ender."
                disabled={!isEditing}>
                <FormSearchInput<
                  EnderId,
                  typeof form,
                  typeof section.propertyId
                >
                  disabled={isReadonly}
                  label="Property"
                  search={searchProperties}
                  hydrate={hydrateProperty}
                  modelType={ModelTypeEnum.PROPERTY}
                  form={form}
                  name={section.propertyId}
                  placeholder="Choose Properties"
                  clearable
                />
              </Tooltip>
              <Divider />
              <FormList form={form} name={section.allocations}>
                {({ list, arrayMethods }) => (
                  <>
                    {list.map(({ name, key }, id) => {
                      const isLastAllocation = id === list.length - 1;
                      return (
                        <Fragment key={key}>
                          <Stack>
                            <DebitCreditAllocationListItem
                              form={form}
                              name={name}
                              canRemove={isMultiple(list)}
                              isLast={isLastAllocation}
                              isReadonly={props.isReadonly}
                              onRemove={() => arrayMethods.remove(id)}
                            />
                            {!isLastAllocation && <Divider />}
                          </Stack>
                          {isLastAllocation && (
                            <Group>
                              <Button
                                variant={ButtonVariant.transparent}
                                onClick={() =>
                                  arrayMethods.append(
                                    emptyJournalEntryAllocationsWithProperty
                                      .allocations[0],
                                  )
                                }
                                disabled={props.isReadonly}>
                                + Add Allocation
                              </Button>
                            </Group>
                          )}
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </FormList>
              <AllocationsSummary
                name={name}
                form={form}
                error={allocationError}
              />
            </Stack>
          </Card>
        );
      }}
    </FormSection>
  );
}

export { PropertyWithAllocations };
