/**
 * HTTP Method: POST
 * Pathname: /systemReports/Year Over Year/run
 * @function runYearOverYearReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunYearOverYearReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<YearOverYearReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { YearOverYearReportResponse } from "@ender/shared/generated/ender.service.reports.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIRunYearOverYearReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunYearOverYearReportBodyParams = {
  endYear: number;
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  propertyIds: EnderId[];
  startYear: number;
};

type ReportsAPIRunYearOverYearReportPayload =
  ReportsAPIRunYearOverYearReportSearchParams &
    ReportsAPIRunYearOverYearReportBodyParams;

function runYearOverYearReportUnsafeEffect(
  payload: ReportsAPIRunYearOverYearReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, YearOverYearReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<YearOverYearReportResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/Year Over Year/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runYearOverYearReport(
  payload: ReportsAPIRunYearOverYearReportPayload,
  options?: { signal?: AbortSignal },
): Promise<YearOverYearReportResponse> {
  return F.pipe(
    payload,
    runYearOverYearReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runYearOverYearReport };
export type {
  ReportsAPIRunYearOverYearReportBodyParams,
  ReportsAPIRunYearOverYearReportPayload,
  ReportsAPIRunYearOverYearReportSearchParams,
};
