import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";
import { Group } from "@ender/shared/ds/group";
import type { TriggerButtonSizes } from "@ender/shared/ds/menu";
import { TriggerButton } from "@ender/shared/ds/menu";
import type { SelectValue } from "@ender/shared/ds/select";

import type { MultiFilterSyncProps } from "./multi-filter-sync";
import { MultiFilterSync } from "./multi-filter-sync";

type FormMultiFilterSyncProps<
  V extends SelectValue,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
  M = unknown,
> = MakeFormPropsFromInputProps<MultiFilterSyncProps<V, M>, Form, Name> & {
  label: string;
  size?: TriggerButtonSizes;
};

function FormMultiFilterSync<
  V extends SelectValue,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
  M = unknown,
>(props: FormMultiFilterSyncProps<V, Form, Name, M>) {
  const { form, name, label, data, size, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field }) => {
        const filterTrigger = (
          <TriggerButton disabled={rest.disabled} size={size} rounded={false}>
            <Group>{label}</Group>
          </TriggerButton>
        );

        return (
          <MultiFilterSync
            {...rest}
            {...field}
            data={data}
            trigger={filterTrigger}
          />
        );
      }}
    </FormField>
  );
}

export { FormMultiFilterSync };

export type { FormMultiFilterSyncProps };
