import { useMutation } from "@tanstack/react-query";
import type { ElementRef } from "react";
import { forwardRef, useCallback } from "react";

import type { EnderId } from "@ender/shared/core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";

import type {
  AddVehicleFormOutput,
  AddVehicleFormProps,
} from "./widgets-leasing-add-vehicle-form";
import { AddVehicleForm } from "./widgets-leasing-add-vehicle-form";

type ConnectedAddVehicleFormProps = Pick<AddVehicleFormProps, "onCancel"> & {
  onSuccess: () => void;
  ownerId: EnderId;
};

const ConnectedAddVehicleForm = forwardRef<
  ElementRef<typeof AddVehicleForm>,
  ConnectedAddVehicleFormProps
>(function ConnectedAddVehicleForm(props, ref) {
  const { onSuccess, onCancel, ownerId } = props;

  const { mutateAsync: addVehicle } = useMutation({
    mutationFn: LeasingAPI.addVehicleToOwner,
    mutationKey: ["LeasingAPI.addVehicleToOwner"] as const,
  });

  const handleSubmit = useCallback(
    async (values: AddVehicleFormOutput) => {
      await addVehicle({ ...values, ownerId });
      onSuccess();
    },
    [addVehicle, onSuccess, ownerId],
  );

  return (
    <AddVehicleForm onCancel={onCancel} onSubmit={handleSubmit} ref={ref} />
  );
});

export { ConnectedAddVehicleForm };

export type { ConnectedAddVehicleFormProps };
