import { Schema } from "@effect/schema";
import { Array as A, Option as O } from "effect";

import { EnderIdFormSchema } from "@ender/shared/core";
import {
  SelectionCriteriaFilterTypeEnum,
  SelectionCriteriaFilterTypeValues,
  SelectionCriteriaLeaseStatusEnum,
} from "@ender/shared/generated/ender.model.misc";

const StatusSelectOptionSchema = Schema.Struct({
  label: Schema.String,
  value: Schema.Enums(SelectionCriteriaLeaseStatusEnum),
});

const IdSelectOptionSchema = Schema.Struct({
  label: Schema.String,
  value: EnderIdFormSchema,
});

const criteriaSchema = Schema.Struct({
  filterType: Schema.Literal(
    ...SelectionCriteriaFilterTypeValues,
    "PROPERTY_LIST",
  ).pipe(Schema.OptionFromSelf),
  ids: Schema.mutable(Schema.Array(IdSelectOptionSchema)),
  leaseStatuses: Schema.Array(StatusSelectOptionSchema).pipe(Schema.mutable),
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];

    if (O.isNone(values.filterType)) {
      issues.push({
        message: "Please select a parameter",
        path: ["filterType"],
      });
    }

    if (O.isSome(values.filterType)) {
      const filterTypeValue = O.getOrUndefined(values.filterType);

      if (
        filterTypeValue === SelectionCriteriaFilterTypeEnum.LEASE_STATUS &&
        A.isEmptyArray(values.leaseStatuses)
      ) {
        issues.push({
          message: "One or more lease statuses are required",
          path: ["leaseStatuses"],
        });
      }

      if (
        filterTypeValue !== SelectionCriteriaFilterTypeEnum.LEASE_STATUS &&
        A.isEmptyArray(values.ids)
      ) {
        issues.push({
          message: "One or more options are required",
          path: ["ids"],
        });
      }
    }

    return issues;
  }),
);

const SelectionCriteriaRequestSchema = Schema.Struct({
  criteria: Schema.mutable(Schema.Array(criteriaSchema)),
});

export {
  criteriaSchema,
  IdSelectOptionSchema,
  SelectionCriteriaRequestSchema,
  StatusSelectOptionSchema,
};
