import { Option as O } from "effect";
import { z } from "zod";

z.getErrorMap();

function NoneSchema<T>() {
  return z.custom<O.None<T>>((val) => O.isNone(val), {
    message: "Expected None",
  });
}

/**
 * Use this when you want to guarantee that the value is Some.
 * The type signature is still Option<T> but the value is guaranteed to be Some after validation.
 */
function SomeSchema<T>(schema: z.ZodType<T>, message: string = "Invalid Some") {
  return z.custom<O.Option<T>>(
    (val) =>
      O.isSome<T>(val) && val.pipe(O.getOrThrow, schema.safeParse).success,
    (val) => ({
      message: val.pipe(
        O.map((val) => schema.safeParse(val).error?.errors[0].message),
        O.getOrElse(() => message),
      ),
    }),
  );
}

/**
 * Validates an Option type.
 *
 * The purpose of the Pipe is:
 * - if the valid is Some, it will validate the value inside the Some
 * - if the value is None, it will return true (since None can't be validated further)
 *
 */
function OptionSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.custom<O.Option<z.infer<T>>>(
    (val: O.Option<z.infer<T>>) => {
      return (
        O.isNone(val) ||
        (O.isSome(val) &&
          val.pipe(
            O.map((val) => schema.safeParse(val).success),
            O.getOrElse(() => true),
          ))
      );
    },
    (val) => ({
      message: val.pipe(
        O.map((val) => schema.safeParse(val).error?.errors[0].message),
        O.getOrElse(() => "Invalid Option"),
      ),
    }),
  );
}

export { NoneSchema, OptionSchema, SomeSchema };
