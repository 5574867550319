/**
 * HTTP Method: PATCH
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}
 * @function updateArea
 * @memberof InspectionAPI
 * @param {InspectionAPIUpdateAreaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionAreaResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionAreaResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import type { InspectionAreaAreaNotApplicableReason } from "@ender/shared/generated/com.ender.construction.model.inspection";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIUpdateAreaPathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
};

type InspectionAPIUpdateAreaSearchParams = {
  token?: string | undefined;
};

type InspectionAPIUpdateAreaBodyParams = {
  notApplicableReason?: InspectionAreaAreaNotApplicableReason | undefined;
  notes?: string | undefined;
};

type InspectionAPIUpdateAreaPayload = InspectionAPIUpdateAreaPathParams &
  InspectionAPIUpdateAreaSearchParams &
  InspectionAPIUpdateAreaBodyParams;

function updateAreaUnsafeEffect(payload: InspectionAPIUpdateAreaPayload) {
  const { inspectionId, areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionAreaResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionAreaResponse>({}),
        encode: encodeJsonBody({ method: "PATCH" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateArea(
  payload: InspectionAPIUpdateAreaPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionAreaResponse> {
  return F.pipe(
    payload,
    updateAreaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateArea };
export type {
  InspectionAPIUpdateAreaBodyParams,
  InspectionAPIUpdateAreaPathParams,
  InspectionAPIUpdateAreaPayload,
  InspectionAPIUpdateAreaSearchParams,
};
