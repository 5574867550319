import { uploadFiles, uploadFilesDirect } from "@ender/shared/api/files";
import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";

type AddFilesToInvoiceProps = {
  files: File[];
  invoiceId: EnderId;
};

function addFilesToInvoice({ files, invoiceId }: AddFilesToInvoiceProps) {
  return uploadFiles({
    files,
    modelId: invoiceId,
    modelType: "INVOICE",
    uploadType: "DOCUMENT",
  });
}

type AddFilesToLedgerEventProps = {
  files: File[];
  eventId: EnderId;
  eventType: ModelType;
  userId: EnderId;
};

function addFilesToLedgerEvent({
  files,
  eventId,
  eventType,
  userId,
}: AddFilesToLedgerEventProps) {
  return uploadFilesDirect({
    files,
    modelId: eventId,
    modelType: eventType,
    uploadType: WebserverFilesServiceFileUploadTypeEnum.DOCUMENT,
    userId,
  });
}

export { addFilesToInvoice, addFilesToLedgerEvent };
