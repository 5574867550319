import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PartyValues = [
  "VENDOR",
  "USER",
  "LEASE",
  "PROPERTY_MANAGER",
  "FIRM",
  "SOFTWARE_PROVIDER",
  "EXTERNAL",
] as const;

const PartyEffectSchema = Schema.Literal(...PartyValues);

type Party = Schema.Schema.Type<typeof PartyEffectSchema>;

const PartyEnum = castEnum(PartyEffectSchema);

function randomParty(): Party {
  return rand(PartyValues);
}

export { PartyEffectSchema, PartyEnum, PartyValues, randomParty };
export type { Party };
