import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";

type MarkAsPaidReceivedSingleFormValues = {
  bankAccountId: EnderId;
  checkDate?: string;
  checkNumber?: string;
  ledgerDate: string;
  memo?: string;
  periodId?: EnderId;
  separatePayments?: boolean;
};

function useMarkAsPaidReceivedSingleForm({
  bankAccountId,
  paymentType,
}: {
  bankAccountId: EnderId;
  paymentType: MoneyTransferTransferType;
}) {
  const initialValues: MarkAsPaidReceivedSingleFormValues = {
    bankAccountId,
    ledgerDate: LocalDate$.today().toJSON(),
    memo: UNDEFINED,
    periodId: UNDEFINED,
    checkDate: UNDEFINED,
    checkNumber: UNDEFINED,
    separatePayments: false,
  };

  const baseSchema = z.object({
    bankAccountId: z.string(),
    ledgerDate: z.string(),
  });

  const checkSchema = baseSchema.extend({
    checkNumber: z.string().nonempty("Check number is required"),
  });

  const validationSchema =
    paymentType === MoneyTransferTransferTypeEnum.MARK_PAID_CHECK
      ? checkSchema
      : baseSchema;

  const form = useForm<MarkAsPaidReceivedSingleFormValues>({
    initialValues,
    validate: zodResolver(validationSchema),
  });

  return { form };
}

export { useMarkAsPaidReceivedSingleForm };
export type { MarkAsPaidReceivedSingleFormValues };
