import {
  IconArrowLeft,
  IconFile,
  IconTemplate,
  IconX,
} from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { clsx } from "clsx";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { Predicate as P } from "effect";
import * as A from "effect/Array";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { Align, Justify, Overflow, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { TemplatesAPI } from "@ender/shared/generated/ender.api.documents";
import type { ListTemplatesResponse } from "@ender/shared/generated/ender.api.documents.response";
import type { TextTemplateTemplateType } from "@ender/shared/generated/ender.model.leasing";
import { TextTemplateTemplateTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import { isMultiple } from "@ender/shared/utils/is";
import { Color } from "@ender/shared/utils/theming";

import { ChannelPreview, ChatInput, ChatTimelineEntry } from "../components";
import type { ChatInputRef } from "../components/chat-input/chat-input";
import type { ChatChannelParams, ChatClientOptions } from "./chat-client";
import { useChatClient } from "./chat-client";

import styles from "./ender-chat.module.css";

dayjs.extend(calendar);

const getChatType = (modelType?: ModelType): TextTemplateTemplateType => {
  switch (modelType) {
    case ModelTypeEnum.LEASE:
      return TextTemplateTemplateTypeEnum.TENANT_CHAT;
    case ModelTypeEnum.APPLICATION:
    case ModelTypeEnum.APPLICATION_GROUP:
      return TextTemplateTemplateTypeEnum.APPLICATION_CHAT;
    case ModelTypeEnum.LEAD:
      return TextTemplateTemplateTypeEnum.PROSPECT_CHAT;
    default:
      return TextTemplateTemplateTypeEnum.TENANT_CHAT;
  }
};

const formats = {
  lastDay: "[Yesterday]",
  lastWeek: "MMM D",
  sameDay: "[Today]",
  sameElse: "MMM D",
} as const;

type ChatProps = {
  channels?: ChatClientOptions["channels"];
  title?: string;
  disableTemplates?: boolean;
};

function getChannelKey(channel: ChatChannelParams | undefined, index: number) {
  return P.isNotNullable(channel?.modelId)
    ? `${channel?.modelId}-${channel?.isInternal ? "Internal" : "Public"}`
    : `chat-channel-${index}`;
}

const Chat = forwardRef<HTMLDivElement, ChatProps>(
  function EnderChat(props, ref) {
    const { title, disableTemplates = false } = props;
    const chatClient = useChatClient({ channels: props.channels });

    /**
     * properties which allow for attaching and sending files
     */
    const [attachments, setAttachments] = useState<File[]>([]);
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop(newFiles) {
        setAttachments((prev) => prev.concat(newFiles));
      },
    });

    // lazy useEffect
    useEffect(() => {
      let dragCounter = 0;
      const dropzoneWrapper = document.getElementsByClassName(
        styles.dropzoneWrapper,
      )[0] as HTMLDivElement;
      const handleDragEnter = () => {
        dragCounter++;
        dropzoneWrapper.style.display = dragCounter ? "block" : "none";
      };
      const handleDragLeave = () => {
        dragCounter--;
        dropzoneWrapper.style.display = dragCounter ? "block" : "none";
      };
      const handleDragEnd = () => {
        dragCounter = 0;
        dropzoneWrapper.style.display = "none";
      };

      window.addEventListener("dragenter", handleDragEnter);
      window.addEventListener("dragleave", handleDragLeave);
      window.addEventListener("dragend", handleDragEnd);
      window.addEventListener("drop", handleDragEnd);
      return () => {
        dragCounter = 0;
        dropzoneWrapper.style.display = "none";
        window.removeEventListener("dragenter", handleDragEnter);
        window.removeEventListener("dragleave", handleDragLeave);
        window.removeEventListener("dragend", handleDragEnd);
        window.removeEventListener("drop", handleDragEnd);
      };
    }, []);

    const { data: templates } = useQuery<ListTemplatesResponse[], unknown>({
      enabled:
        !disableTemplates &&
        P.isNotNullable(chatClient.activeChannel?.channel.modelType),
      queryFn: ({ signal }) =>
        TemplatesAPI.listTemplates(
          { type: getChatType(chatClient.activeChannel?.channel.modelType) },
          { signal },
        ),
      queryKey: [
        "TemplatesAPI.listTemplates",
        getChatType(chatClient.activeChannel?.channel.modelType),
      ] as const,
    });

    /**
     * this ref is used to live-track whether the user should be scrolled to the bottom of the chat.
     * - true on initial render + loading of timeline
     * - false when the user scrolls up (they no longer intend to stay scrolled to the bottom for new messages)
     * - true when the user changes channels (they should be scrolled to the bottom of the new channel)
     */
    const shouldBeAtEnd = useRef<boolean>(true);
    const scrollBottomAnchor = useRef<HTMLDivElement>(null);
    /**
     * this ref is used to track whether the timeline has loaded once or not.
     * it will be set to true on the first render where the timeline is non-empty.
     */
    const hasLoadedTimeline = useRef<boolean>(false);

    /**
     * handle scrolling to the bottom. When this item re-renders as the result of a new message,
     * we want to scroll to the bottom. However, if the user has scrolled up, we don't want to
     * return them to the end of the chat automatically when a new message comes in.
     */
    useEffect(() => {
      if (!scrollBottomAnchor.current) {
        return;
      }
      if (shouldBeAtEnd.current) {
        /**
         * scrollBottomAnchor exists, and we also should be at the end (current conditional)
         * The `hasLoadedTimeline` boolean is used for smooth scroll- if the timeline has never loaded,
         * we want to snap to the bottom when it finally does
         */
        const timelineEl = scrollBottomAnchor.current.parentElement;
        if (P.isNullable(timelineEl)) {
          return;
        }
        timelineEl.scrollTo({
          behavior: hasLoadedTimeline.current ? "smooth" : undefined,
          top: timelineEl.scrollHeight,
        });
      }

      // whenever the timeline updates we set this boolean to ensure that it is up to date.
      hasLoadedTimeline.current = chatClient.timeline.length > 0;
    }, [chatClient.timeline, chatClient.pending]);

    const usersInChat = useMemo(() => {
      return Object.entries(
        chatClient.activeChannel?.usersInChat?.reduce<Record<string, string[]>>(
          (acc, cur) => {
            const displayTag = cur.isPM
              ? (cur.pmCompanyName as string)
              : cur.name;
            if (!acc[displayTag]) {
              acc[displayTag] = [];
            }

            acc[displayTag].push(cur.name);
            return acc;
          },
          {},
        ) ?? {},
      );
    }, [chatClient.activeChannel]);

    const handleSendMessage = async (messageText = "") => {
      let attachedFiles;

      if (attachments.length > 0) {
        attachedFiles = await chatClient.sendDocuments(attachments);
        setAttachments([]);
      }

      chatClient.sendChatMessage(
        messageText,
        attachedFiles?.map((file) => ({
          filename: file.fileName,
          url: file.s3Url,
        })),
      );
    };

    const inputRef = useRef<ChatInputRef>(null);

    const { mutateAsync: renderTemplate, isLoading: templateRendering } =
      useMutation({ mutationFn: TemplatesAPI.renderTemplate });

    async function chooseTemplate(id: EnderId) {
      if (inputRef.current) {
        inputRef.current.setValue("");
      }
      if (P.isNullable(chatClient.activeChannel)) {
        return;
      }

      const { modelId, modelType } = chatClient.activeChannel.channel;

      const template = await renderTemplate({
        modelId,
        modelType,
        templateId: id,
      });
      if (template.text && inputRef.current) {
        inputRef.current.setValue(template.text.trim());
      }
    }

    const lastTimeline =
      chatClient.timeline[chatClient.timeline.length - 1]?.[1] ?? [];
    const lastMessage = lastTimeline[lastTimeline.length - 1];

    return (
      <section
        ref={ref}
        {...getRootProps({ className: "h-full relative overflow-hidden z-0" })}
        aria-labelledby="chat-title"
        aria-label={title}>
        <div
          className={clsx(
            "absolute w-full h-full z-10 bg-white top-0 transition-transform",
            {
              "-translate-x-full": P.isNotNullable(chatClient.activeChannel),
            },
          )}
          aria-hidden={P.isNotNullable(chatClient.activeChannel)}>
          <Stack spacing={Spacing.none} overflow={Overflow.auto} fullHeight>
            {chatClient.channels.map(({ isLoading, data }, index) => (
              <ChannelPreview
                key={getChannelKey(data?.channel, index)}
                title={props.channels?.[index].chatTitle}
                usersInChat={data?.usersInChat ?? []}
                lastMessage={data?.lastItem}
                /**
                 * @todo make selected channel more reliable by assigning IDs to channels
                 * or using channel index instead of `activeChannel`
                 */
                selected={
                  data?.channel.modelId ===
                  chatClient.activeChannel?.channel.modelId
                }
                onClick={() => data && chatClient.setChatChannel(data)}
                loading={isLoading}
              />
            ))}
          </Stack>
        </div>
        {/* <Divider color="gray.1" orientation="vertical" /> */}
        <Stack
          spacing={Spacing.none}
          noWrap
          grow
          overflow={Overflow.auto}
          fullHeight>
          {isMultiple(chatClient.channels) &&
            P.isNotNullable(chatClient.activeChannel) && (
              <div className="px-4">
                <Button
                  variant={ButtonVariant.transparent}
                  leftSection={<IconArrowLeft size={16} />}
                  onClick={() => {
                    chatClient.setChatChannel();
                    /** clear the inputRef when switching channels
                     * @todo //TODO we can store the typed value in a state as a 'draft' and restore it when switching back
                     */
                    inputRef.current?.setValue("");
                  }}>
                  Select Chat
                </Button>
              </div>
            )}
          {/* style={{ height: 32, padding: "9px 16px", flexShrink: 0 }} */}
          <div className="text-xxs">
            {usersInChat.map(([name, users], i) => [
              !!i && ", ",
              <Tooltip
                label={users.join(", ")}
                disabled={users.length <= 1}
                key={name}>
                <span>
                  {name}
                  {P.isTupleOfAtLeast(users, 2) && ` (${users.length})`}
                </span>
              </Tooltip>,
            ])}
          </div>
          <Divider />
          <div className={styles.timelineWrapper}>
            <div className={styles.timeline}>
              <Stack spacing={Spacing.none}>
                {chatClient.timeline.map(([day, timeline]) => (
                  <div key={day}>
                    <div className={styles.dateBoundary}>
                      <Group justify={Justify.center} align={Align.center} grow>
                        <Text size={FontSize.xs}>
                          {dayjs(day).calendar(null, formats)}
                        </Text>
                      </Group>
                    </div>
                    <div className={styles.timelineSection} role="log">
                      <Stack>
                        {timeline.map((entry, index, entries) => {
                          const previous = entries[index - 1];
                          return (
                            <ChatTimelineEntry
                              key={entry.id}
                              {...entry}
                              previous={previous}
                            />
                          );
                        })}
                      </Stack>
                    </div>
                  </div>
                ))}
                {A.isNonEmptyArray(chatClient.pending) && (
                  <div
                    className={styles.timelineSection}
                    style={{ marginTop: -16 }}
                    role="log">
                    <Stack>
                      {chatClient.pending.map((entry, index, entries) => {
                        const previous = entries[index - 1] ?? lastMessage;
                        return (
                          <ChatTimelineEntry
                            key={entry.id}
                            {...entry}
                            previous={previous}
                          />
                        );
                      })}
                    </Stack>
                  </div>
                )}
              </Stack>
              <div ref={scrollBottomAnchor} style={{ height: 1 }} />
            </div>
            <div className={styles.dropzoneWrapper} style={{ display: "none" }}>
              <Text
                //@ts-expect-error text does not support color yet
                color={Color.slate}
                size={FontSize.lg}
                weight={FontWeight.semibold}
                className={clsx(styles.dropzone, {
                  [styles.dropzoneActive]: isDragActive,
                })}>
                Upload to Chat
              </Text>
            </div>
          </div>
          <ChatInput
            onAttach={open}
            onSendMessage={handleSendMessage}
            disabled={!chatClient.activeChannel}
            actions={
              <>
                {!disableTemplates && (templates?.length ?? 0) > 0 && (
                  <Menu>
                    <MenuTrigger>
                      <ActionIcon
                        variant={ButtonVariant.transparent}
                        color={Color.primary}
                        disabled={!chatClient.activeChannel}>
                        <IconTemplate />
                      </ActionIcon>
                    </MenuTrigger>
                    <MenuContent>
                      {templates?.map((template) => (
                        <MenuButton
                          key={template.id}
                          onClick={() => chooseTemplate(template.id)}>
                          {template.name}
                        </MenuButton>
                      ))}
                    </MenuContent>
                  </Menu>
                )}
              </>
            }
            ref={inputRef}>
            {templateRendering && (
              <div
                style={{
                  padding: "5px 12px",
                  marginTop: -26,
                  position: "relative",
                }}>
                {/* magic number "-26": line-height of textinput, plus bottom padding of textinput */}
                <Skeleton rounded="full">
                  <span className="w-full h-2.5" />
                </Skeleton>
                <Skeleton
                  // mt={10}
                  rounded="full">
                  <span className="w-3/5 h-2.5" />
                </Skeleton>
              </div>
            )}
            {attachments.length > 0 && (
              <Group spacing={Spacing.sm}>
                {attachments.map((attachment, index) => {
                  if (attachment.type.includes("image")) {
                    return (
                      <div
                        className="relative h-10"
                        key={`${attachment.name}_${index}`}>
                        <img
                          src={URL.createObjectURL(attachment)}
                          className="h-full rounded-lg"
                          alt=""
                        />
                        <ActionIcon
                          size={ButtonSize.sm}
                          variant={ButtonVariant.filled}
                          color={Color.slate}
                          // className={styles.attachmentRemove}
                          onClick={() =>
                            setAttachments((prev) =>
                              prev.filter((_, i) => i !== index),
                            )
                          }>
                          <IconX color="var(--color-slate-900)" />
                        </ActionIcon>
                      </div>
                    );
                  }
                  return (
                    <a
                      href={URL.createObjectURL(attachment)}
                      key={`${attachment.name}_${index}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      // TODO use an Attachment component or File component that we use in the upload
                      className={clsx(styles.attachment, styles.preSend)}>
                      <Group spacing={Spacing.sm}>
                        <ActionIcon
                          size={ButtonSize.sm}
                          variant={ButtonVariant.filled}
                          color={Color.slate}
                          // className={styles.attachmentRemove}
                          onClick={(e) => {
                            setAttachments((prev) =>
                              prev.filter((_, i) => i !== index),
                            );
                            e.preventDefault();
                          }}>
                          <IconX color="var(--color-slate-900)" />
                        </ActionIcon>
                        <IconFile /> <Text>{attachment.name}</Text>
                      </Group>
                    </a>
                  );
                })}
              </Group>
            )}
            {/* mount a hidden input to the dom. This is so that the attachment open dialog has a target for
                 uploading the files. */}
            <input {...getInputProps({ "aria-hidden": true, role: "none" })} />
          </ChatInput>
        </Stack>
      </section>
    );
  },
);

export { Chat };
export type { ChatProps };
