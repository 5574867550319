import type { StripeElementsOptions } from "@stripe/stripe-js";
import { Predicate as P } from "effect";

import type {
  ApplicantApplicationGroupResponseApplicationResponse,
  PaymentInformationAppFeeStatus,
} from "@ender/shared/generated/ender.api.leasing.response";
import { PaymentInformationAppFeeStatusEnum } from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationIdentityVerificationResult } from "@ender/shared/generated/ender.model.leasing";
import { ApplicationIdentityVerificationResultEnum } from "@ender/shared/generated/ender.model.leasing";

type ApplyStep = "PERSONAL" | "OCCUPANTS" | "INCOME" | "FEE" | "CREDIT";

/**
 * @description Retrieves the value of a CSS variable
 * @param {string} cssVariable The CSS variable you wish to get the value from including the hyphens at the beginning
 * @param {HtmlHeadElement | HTMLElement} attachmentPoint The HTML element which is parent to the style tag containing the CSS variable
 */
function getCssVariableValue(
  cssVariable: string,
  attachmentPoint: HTMLElement = document.head,
): string {
  const cssVariableValue =
    getComputedStyle(attachmentPoint).getPropertyValue(cssVariable);

  if (!cssVariableValue) {
    console.warn(`${cssVariable} has no value on ${attachmentPoint.nodeName}`);
  }

  return cssVariableValue;
}

const CompletedFeeStatuses: PaymentInformationAppFeeStatus[] = [
  PaymentInformationAppFeeStatusEnum.PAID,
  PaymentInformationAppFeeStatusEnum.PROCESSING,
];
const CompletedIdvStatuses: ApplicationIdentityVerificationResult[] = [
  ApplicationIdentityVerificationResultEnum.PASSED,
  ApplicationIdentityVerificationResultEnum.SKIPPED,
];
/**
 * a series of functions that we can use to determine if a given applicant has completed any given step.
 * In this case, steps are independent of one another, and we only care about figuring out
 * the criteria for an ApplyStep to count as done.
 *
 * TODO this may not need the appGroup if the application fee is per-applicant, or if the 'paidApplicationFee' is a global flag.
 * As it stands now, this is the only thing that we require the appGroup for.
 */
const isStepComplete: Record<
  ApplyStep,
  (application: ApplicantApplicationGroupResponseApplicationResponse) => boolean
> = {
  PERSONAL: (application) => Boolean(application.applicant.agreedToTerms),
  //the first occupant is technically added during the initial Personal phase, and adding any others are optional
  OCCUPANTS: (application) => Boolean(application.applicant.agreedToTerms),
  /**
   * Income has been provided when the applicant has submitted an employment status.
   * Employment status should not be submittable without including some additional info such as salary and manager name,
   * but for the purpose of unlocking the next step the user just has to specify employment status.
   */
  INCOME: (application) => P.isNotNullable(application.employmentStatus),
  /**
   * whether the application has completed the application fee.
   * This does not need to check for the presence of a fee- that check is done prior to verifying the completion of this step.
   */
  FEE: (application) =>
    (CompletedFeeStatuses.includes(application.paymentInformation.feeStatus) ||
      P.isNotNullable(application.paymentInformation.feePaymentMethodId)) &&
    application.paymentInformation.feeStatus !==
      PaymentInformationAppFeeStatusEnum.FAILED,
  /**
   * step is complete if an application has an IDV result of PASSED or SKIPPED
   */
  CREDIT: (application) =>
    CompletedIdvStatuses.includes(application.identityVerificationResult),
};

/**
 * checks the application progress for a given application
 * @param application the application whose status we are checking
 * @param steps the steps to check for completion
 * @returns an array of booleans representing completed steps. True means the step is completed. 1:1 with the input 'steps' array.
 */
function findCompletedSteps(
  application: ApplicantApplicationGroupResponseApplicationResponse,
  steps: ApplyStep[],
): boolean[] {
  //completed steps are true, incompleted steps are false
  return steps.map((step) => isStepComplete[step](application));
}

/**
 * Based on the provided application and ordered steps, it will determine
 * how far the current application got in the process
 *
 * @param application the application
 * @param steps the order-specific list of application steps, from which the furthest step will be calculated
 * @returns an index representing the first incomplete step, steps.length if all steps are completed
 */
function findFirstIncompleteStep(
  application: ApplicantApplicationGroupResponseApplicationResponse | undefined,
  steps: ApplyStep[],
): number {
  if (P.isNotNullable(application)) {
    //completed steps are true, incomplete steps are false
    const stepCompletionStatuses = steps.map((step) =>
      isStepComplete[step](application),
    );
    //find first falsy value in the list
    const earliestIncompleteStep = stepCompletionStatuses.findIndex(
      (value) => !value,
    );
    if (earliestIncompleteStep === -1) {
      return steps.length;
    }

    return earliestIncompleteStep;
  }

  //defaults to the first step provided
  return 0;
}

const stripeStyles: Pick<StripeElementsOptions, "fonts" | "appearance"> = {
  fonts: [
    {
      weight: "400",
      family: "Inter",
      style: "normal",
      src: `url("/fonts/inter.woff2") format("woff2")`,
      display: "swap",
    },
    {
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@100&display=swap",
    },
  ],
  appearance: {
    theme: undefined,
    variables: {
      fontFamily: getCssVariableValue("--theme-font-family"),
      fontSizeBase: "14px",
      fontSizeSm: "12px",
      fontWeightNormal: "500",
      fontLineHeight: "20px",
      colorText: getCssVariableValue("--color-slate-900"),
      colorPrimary: getCssVariableValue("--color-primary-500"), //--color-purple-500
      colorTextPlaceholder: getCssVariableValue("--color-gray-300"),
      spacingGridColumn: "16px",
      spacingGridRow: "16px",
      colorDanger: getCssVariableValue("--color-red"),
      colorDangerText: getCssVariableValue("--color-red"),
    },
    rules: {
      ".Input": {
        border: `1px solid ${getCssVariableValue("--color-gray-200")}`,
        fontWeight: "500",
        padding: "10px 16px",
        lineHeight: "20px",
      },
      ".Label": {
        fontWeight: "400",
        marginBottom: "4px",
        lineHeight: "18px",
      },
      ".Input--invalid": {
        borderColor: "var(--colorDanger)",
      },
      ".Label--invalid": {
        color: "var(--colorDanger)",
      },
      ".Error": {
        fontSize: "10px",
        lineHeight: "1.2",
      },
      ".TermsText": {
        color: "var(--colorText)",
        fontSize: "var(--fontSizeSm)",
      },
    },
  },
};

export {
  findCompletedSteps,
  findFirstIncompleteStep,
  isStepComplete,
  stripeStyles,
};
export type { ApplyStep };
