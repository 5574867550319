import { IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { UnitAreasAPI } from "@ender/shared/generated/ender.api.core";

import { useCanEditAreasAndZones } from "./use-can-edit-areas-and-zones";

type DeleteUnitAreaActionIconProps = {
  areaId: EnderId;
  unitId: EnderId;
};

function DeleteUnitAreaActionIcon({
  areaId,
  unitId,
}: DeleteUnitAreaActionIconProps) {
  const confirmation = useConfirmationContext();
  const queryClient = useQueryClient();
  const { disabled, disabledTooltip } = useCanEditAreasAndZones(unitId);

  const { mutateAsync: deleteUnitArea } = useMutation({
    mutationFn: async () => {
      await confirmation(
        {
          confirmButtonLabel: "Delete",
          content:
            "Are you sure you want to delete this area? This action cannot be undone.",
          title: "Delete Area?",
        },
        { confirmButtonProps: { color: "red" } },
      );
      await UnitAreasAPI.deleteUnitArea({ areaId });
    },
    mutationKey: ["UnitAreasAPI.deleteUnitArea", { areaId }],
  });

  const onDeleteClick = useCallback(async () => {
    await deleteUnitArea();
    queryClient.invalidateQueries(["UnitAreasAPI.getUnitAreas", { unitId }]);
    queryClient.invalidateQueries([
      "UnitAreasZonesMiddleLayerAPI.getUnitAreaSummary",
      { unitId },
    ]);
  }, [deleteUnitArea, queryClient, unitId]);

  return (
    <ActionIcon
      onClick={onDeleteClick}
      variant={ButtonVariant.transparent}
      color="red"
      disabled={disabled}
      disabledTooltip={disabledTooltip}>
      <IconTrash />
    </ActionIcon>
  );
}

export { DeleteUnitAreaActionIcon };
