// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Root, Thumb } from "@radix-ui/react-switch";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import type { ReactNode } from "react";
import { forwardRef, useId } from "react";

import { Align, Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";
import { isEmptyReactNode } from "../../../utils";

type SwitchProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: ReactNode;
  disabled?: boolean;
  name?: string;
};

const SwitchTrackVariantGenerator = cva(
  [
    "peer h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
    "disabled:bg-gray-200 disabled:cursor-default",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      value: false,
    },
    variants: {
      value: {
        false: ["bg-slate-200"],
        true: ["bg-primary-500"],
      },
    },
  },
);

const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  function Switch(props, ref) {
    //setting default `value` to false helps surface an error case where Mantine getInputProps is using type:"checkbox" and the value is undefined.
    //If you are here because your switch won't toggle, check the value prop is being set correctly! don't use `type:"checkbox"` in your getInputProps!
    const { value = false, onChange, label, disabled = false, name } = props;
    const id = useId();
    return (
      <Group spacing={Spacing.sm} align={Align.center}>
        <Root
          className={SwitchTrackVariantGenerator(props)}
          checked={value}
          onCheckedChange={onChange}
          disabled={disabled}
          ref={ref}
          id={id}
          name={name}>
          <Thumb
            className={clsx(
              "pointer-events-none block h-4 w-4 rounded-full bg-white ring-0 transition-transform",
              "data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0",
            )}
          />
        </Root>
        {!isEmptyReactNode(label) && (
          <label
            htmlFor={id}
            className="text-slate-900 peer-disabled:text-gray-200">
            {label}
          </label>
        )}
      </Group>
    );
  },
);

export { Switch };

export type { SwitchProps };
