import { Predicate as P } from "effect";
import type { PropsWithChildren } from "react";
import {
  Suspense,
  cloneElement,
  createContext,
  createElement,
  isValidElement,
  useContext,
  useSyncExternalStore,
} from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { randomEnderId } from "@ender/shared/core";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import type { EmptyObject } from "@ender/shared/types/general";
import { EnderModal } from "@ender/shared/ui/ender-modal";
import { fail } from "@ender/shared/utils/error";

type CloseModal = () => void;
type OpenModalResult = { closeModal: CloseModal };

type ModalProps = {
  className?: string;
  preventClose?: boolean;
  onClose?: CloseModal;
  [key: string]: unknown;
};
type Modal<T extends ModalProps> = Readonly<{
  modalId: string;
  modalElement: JSX.Element;
  closeModal: CloseModal;
  modalProps?: T;
}>;
type EnderModalContextType<T extends ModalProps> = Readonly<{
  readonly openModalId: string | undefined;
  readonly stack: Modal<T>[];
  readonly stackSize: number;
  setOpen(id: string): void;
  openModal(
    modalElement: JSX.Element | JSX.ElementType,
    modalProps?: T,
  ): OpenModalResult;
  closeModal(id: string): void;
  closeAllModals(): void;
}>;

const ModalContextStore = (() => {
  const subscriberMap = new Map<string, () => void>();

  const modalMap = new Map<string, Modal<ModalProps>>();
  const modalStack: string[] = [];
  let cache:
    | {
        id: string | undefined;
        stackSize: number;
        context: EnderModalContextType<ModalProps>;
      }
    | undefined = UNDEFINED;
  let openModalId: string | undefined = UNDEFINED;

  const notifySubscribers = () => {
    subscriberMap.forEach((onUpdate, subscriberId) => {
      try {
        onUpdate();
      } catch (e) {
        fail(e, { showNotification: false }); // Error notifying modal subscriber ${subscriberId}`
        subscriberMap.delete(subscriberId);
      }
    });
  };

  // Add & Remove Modals don't call notifySubscribers so we can batch notify for multiple changes eg. closeAllModals
  const addModal = (
    modalId: string,
    modalElement: JSX.Element,
    closeModal: CloseModal,
    modalProps?: ModalProps,
  ) => {
    const modal = Object.freeze({
      modalId,
      modalElement,
      closeModal,
      modalProps,
    });
    modalMap.set(modalId, modal);
    modalStack.unshift(modalId);
    openModalId = modalId;
  };
  const removeModal = (id: string) => {
    const index = modalStack.findIndex((modalId) => modalId === id);
    if (index < 0) {
      return;
    }

    modalMap.delete(id);
    modalStack.splice(index, 1);
    openModalId = modalStack.length > 0 ? modalStack[0] : UNDEFINED;
  };

  const api = Object.freeze({
    closeAllModals() {
      modalStack.forEach((id) => {
        const { closeModal } = modalMap.get(id) ?? {};
        closeModal && closeModal();
      });
      modalStack.splice(0, Infinity);
      modalMap.clear();
      notifySubscribers();
    },
    closeModal(id: string) {
      if (!modalMap.has(id)) {
        return;
      }

      const { closeModal } = modalMap.get(id) ?? {};
      closeModal && closeModal();
      notifySubscribers();
    },
    get openModalId(): string | undefined {
      return openModalId;
    },
    openModal(
      modalElement: JSX.Element | JSX.ElementType,
      props?: ModalProps,
    ): OpenModalResult {
      const id = randomEnderId();
      const closeModal = () => {
        removeModal(id);
        props?.onClose && props.onClose();
        notifySubscribers();
      };

      let element: JSX.Element;
      if (!isValidElement(modalElement)) {
        element = createElement(modalElement as JSX.ElementType, {
          ...props,
          closeModal,
        });
      } else {
        element = cloneElement(modalElement as JSX.Element, {
          ...props,
          closeModal,
        });
      }

      addModal(id, element, closeModal, props);
      notifySubscribers();

      return { closeModal };
    },
    setOpen(id: string) {
      const index = modalStack.findIndex((modalId) => modalId === id);
      if (index < 0) {
        return;
      }

      const removedModal = modalStack.splice(index, 1);
      modalStack.unshift(removedModal[0]);
      openModalId = id;

      notifySubscribers();
    },
    get stack(): Modal<ModalProps>[] {
      return modalStack.map((id) => modalMap.get(id)).filter(P.isNotNullable);
    },
    get stackSize(): number {
      return modalStack.length;
    },
  });

  return Object.freeze({
    subscribe(onUpdate: () => void) {
      const subscriberId = randomEnderId();
      subscriberMap.set(subscriberId, onUpdate);
      return () => {
        subscriberMap.delete(subscriberId);
      };
    },
    getSnapshot(): EnderModalContextType<ModalProps> {
      if (
        !cache ||
        cache.id !== openModalId ||
        modalStack.length !== cache.stackSize
      ) {
        cache = {
          id: openModalId,
          stackSize: modalStack.length,
          context: Object.freeze({ ...api }),
        };
      }

      return cache.context;
    },
  });
})();

/**
 * @deprecated Please don't use this directly, use useEnderModalContext instead. This is only exported for old openModal usage.
 */
const getEnderModalContext = () => {
  return ModalContextStore.getSnapshot();
};

const EnderModalContext = createContext<EnderModalContextType<ModalProps>>(
  ModalContextStore.getSnapshot(),
);

const useEnderModalContext = () => {
  return useContext(EnderModalContext);
};

type ProviderProps = PropsWithChildren<EmptyObject>;

const EnderModalContextProvider = function EnderModalContextProvider({
  children,
}: ProviderProps): JSX.Element {
  const context = useSyncExternalStore(
    ModalContextStore.subscribe,
    ModalContextStore.getSnapshot,
    ModalContextStore.getSnapshot,
  );

  const { stack, closeModal } = context;
  return (
    <EnderModalContext.Provider value={context}>
      {children}
      {stack.map(
        ({ modalId, modalElement, modalProps, closeModal: _closeModal }) => {
          const onClose = () => {
            closeModal(modalId);
            _closeModal && _closeModal();
          };
          return (
            <EnderModal
              key={modalId ?? "no-modal"}
              className={modalProps?.className}
              opened
              onClose={onClose}
              withCloseButton={!modalProps?.preventClose}>
              <Suspense fallback={<LoadingSpinner />}>{modalElement}</Suspense>
            </EnderModal>
          );
        },
      )}
    </EnderModalContext.Provider>
  );
};

export {
  EnderModalContextProvider,
  getEnderModalContext,
  useEnderModalContext,
};
export type { EnderModalContextType, ModalProps };
