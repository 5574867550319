import type { ElementRef } from "react";
import { forwardRef } from "react";

import type { BadgeProps } from "@ender/shared/ds/badge";
import { Badge } from "@ender/shared/ds/badge";
import type { ApplicationGroupApplicationStatus } from "@ender/shared/generated/ender.model.leasing";
import { ApplicationGroupApplicationStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import { Color } from "@ender/shared/utils/theming";

const applicationGroupStatusToColorMap: Record<
  ApplicationGroupApplicationStatus,
  BadgeProps["color"]
> = {
  [ApplicationGroupApplicationStatusEnum.IN_PROGRESS]: Color.slate,
  [ApplicationGroupApplicationStatusEnum.COMPLETE]: Color.yellow,
  [ApplicationGroupApplicationStatusEnum.UNDER_REVIEW]: Color.yellow,
  [ApplicationGroupApplicationStatusEnum.INITIAL_ACCEPTED]: Color.green,
  [ApplicationGroupApplicationStatusEnum.ACCEPTED]: Color.green,
  [ApplicationGroupApplicationStatusEnum.INITIAL_REJECTED]: Color.red,
  [ApplicationGroupApplicationStatusEnum.REJECTED]: Color.red,
  [ApplicationGroupApplicationStatusEnum.WITHDRAWN]: Color.slate,
};

type ApplicationGroupStatusBadgeProps = {
  status: ApplicationGroupApplicationStatus;
};

/**
 * @description using a direct status-to-string map is preferable over a function.
 * The reason for this is that a direct map object is something we would eventually be able to get from a
 * controlled vocabulary API, and may represent the best-case scenario for our app's localization and PM-specific
 * verbiage for various statuses
 */
const applicationGroupDisplayStatus: Record<
  ApplicationGroupApplicationStatus,
  string
> = {
  [ApplicationGroupApplicationStatusEnum.IN_PROGRESS]: "In Progress",
  [ApplicationGroupApplicationStatusEnum.COMPLETE]: "Ready for Review",
  [ApplicationGroupApplicationStatusEnum.UNDER_REVIEW]: "Under Review",
  [ApplicationGroupApplicationStatusEnum.INITIAL_ACCEPTED]:
    "Initially Approved",
  [ApplicationGroupApplicationStatusEnum.ACCEPTED]: "Accepted",
  [ApplicationGroupApplicationStatusEnum.INITIAL_REJECTED]: "Initially Denied",
  [ApplicationGroupApplicationStatusEnum.REJECTED]: "Denied",
  [ApplicationGroupApplicationStatusEnum.WITHDRAWN]: "Withdrawn",
};

const ApplicationGroupStatusBadge = forwardRef<
  ElementRef<typeof Badge>,
  ApplicationGroupStatusBadgeProps
>(function ApplicationGroupStatusBadge(props, ref) {
  const { status } = props;

  return (
    <Badge
      color={applicationGroupStatusToColorMap[status] ?? Color.slate}
      ref={ref}>
      {applicationGroupDisplayStatus[status]}
    </Badge>
  );
});

export { applicationGroupDisplayStatus, ApplicationGroupStatusBadge };

export type { ApplicationGroupStatusBadgeProps };
