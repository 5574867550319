import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import { useContext, useState } from "react";

import { uploadFilesDirect } from "@ender/shared/api/files";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import { randomEnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { FileInput } from "@ender/shared/ds/file-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { PhotosAPI } from "@ender/shared/generated/ender.api.misc";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import type { Photo } from "@ender/shared/generated/ender.model.files";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { useListState } from "@ender/shared/hooks/use-list-state";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { UnitImage } from "./sub-components/unit-image";

type ImageUploadProps = {
  unit: UnitSerializerUnitResponse;
  images: Photo[];
  onSuccess: () => void;
  onCancel: () => void;
};

function ImageUpload(props: ImageUploadProps) {
  const { unit, onCancel, onSuccess = F.constVoid, images: baseImages } = props;
  const { user } = useContext(UserContext);

  const [images, imageHandler] = useListState<Omit<Photo, "modelId">>(
    baseImages ?? [],
  );
  //The delete queue. Used on save to determine which images to remove
  const [imagesToDelete, imageDeleteHandler] = useListState<
    Omit<Photo, "modelId">
  >([]);

  const confirmation = useConfirmationContext();

  const [selection, setSelection] = useState<Record<string, boolean>>({});

  const anySelected = Object.values(selection).some((selected) => selected);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const from = images?.findIndex(({ id }) => id === active?.id);
      const to = images?.findIndex(({ id }) => id === over?.id);
      imageHandler.reorder({ from, to });
    }
  }

  async function handleDeleteSelection() {
    try {
      if (unit?.activeListingId) {
        await confirmation({
          content:
            "Deleting Unit images will remove them from all active listings.",
        });
      }

      const deleted = images.filter(({ id }) => selection[id]);
      imageDeleteHandler.append(...deleted);
      imageHandler.filter(({ id }) => !selection[id]);
      setSelection({});
    } catch {
      //do nothing, confirmation was rejected
    }
  }

  const { mutateAsync, isLoading: isUploadingImages } = useMutation({
    mutationFn: async ({ files }: { files: File[] }) => {
      if (P.isNullable(unit)) {
        throw new Error("Unit is missing");
      }
      return await uploadFilesDirect({
        files,
        modelId: unit.id,
        modelType: ModelTypeEnum.UNIT,
        uploadType: WebserverFilesServiceFileUploadTypeEnum.PHOTO,
        userId: user.id,
      });
    },
    mutationKey: ["uploadFilesDirect", unit?.id] as const,
  });

  async function handleUnitImageUpload(files: File[]) {
    const response = await mutateAsync({ files });
    if (!response) {
      return;
    }
    /**
     * automatically append the uploaded images to the listing
     */
    imageHandler.append(
      ...response.map((image) => ({
        ...image,
        comment: "",
        id: randomEnderId(),
        isPrimary: false,
        large: image.s3Url,
        priority: 0,
        small: image.s3Url,
      })),
    );
  }

  async function handleSave() {
    try {
      await Promise.all(
        imagesToDelete.map((photo) =>
          PhotosAPI.deletePhoto({ photoId: photo.id })
            .then(() => imageDeleteHandler.filter(({ id }) => id !== photo.id))
            .catch((err) => {
              /*
               * restore the photo that failed to delete
               */
              imageHandler.append(photo);
              imageDeleteHandler.filter(({ id }) => id !== photo.id);
              throw err;
            }),
        ),
      );
      if (P.isNullable(unit)) {
        return;
      }
      await PhotosAPI.updatePhotoOrdering({
        unitId: unit.id,
        ordering: images.map(({ id }) => id),
      });
      showSuccessNotification({ message: "Images successfully updated" });
      onSuccess();
    } catch (e) {
      fail(e);
    }
  }

  return (
    <Stack spacing={Spacing.lg}>
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext items={images}>
          <Stack>
            <Group justify={Justify.end}>
              <ActionIcon
                disabled={!anySelected}
                onClick={handleDeleteSelection}>
                <IconTrash />
              </ActionIcon>
            </Group>
            <Group>
              {images.map((photo) => (
                <UnitImage
                  src={photo.small}
                  id={photo.id}
                  key={photo.id}
                  selected={selection[photo.id]}
                  onChange={() => {
                    setSelection((prev) => ({
                      ...prev,
                      [photo.id]: !prev[photo.id],
                    }));
                  }}
                />
              ))}
            </Group>
          </Stack>
        </SortableContext>
      </DndContext>
      <Skeleton visible={isUploadingImages} aria-busy={isUploadingImages}>
        <FileInput value={[]} onChange={handleUnitImageUpload} />
      </Skeleton>
      <Divider />
      <Group justify={Justify.end} align={Align.center}>
        <Button variant={ButtonVariant.transparent} onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </Group>
    </Stack>
  );
}

export { ImageUpload };
