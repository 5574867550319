import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const FunctionalPermissionValues = [
  "VIEW_ACCOUNTING",
  "VIEW_APPROVAL_PROCESS_PIPELINE",
  "EDIT_APPROVAL_PROCESS_PIPELINE",
  "BANK_RECONCILIATION",
  "CREATE_JOURNAL_ENTRIES",
  "EDIT_INVOICE_ALLOCATIONS",
  "EDIT_CHART_OF_ACCOUNTS",
  "VIEW_COLLECTIONS_TIMELINE",
  "EDIT_COLLECTIONS_TIMELINE",
  "EDIT_ACCOUNTING_PERIOD_FOR_POSTED_TXS",
  "EXCLUDE_FROM_BANK_REC",
  "MANUAL_BANK_REC",
  "SPECIFY_JOURNAL_ENTRY_APPROVER",
  "CLOSE_BOOKS",
  "CHOOSE_ACCOUNTING_PERIOD",
  "VIEW_BANK_ACCOUNT_NUMBERS",
  "LINK_BANK_ACCOUNT",
  "EDIT_PARTIALLY_OPEN_BOOKS",
  "INSTANT_APPROVE_INVOICE",
  "VOID_PAYMENT",
  "VIEW_LEASING",
  "CREATE_LISTING",
  "RUN_CONSUMER_REPORTS",
  "EDIT_LEASE_DATE",
  "REVIEW_MARKETING_CERTIFICATE",
  "VIEW_CONSUMER_REPORTS",
  "CREATE_LEASE",
  "ACCEPT_APPLICATION",
  "APPLICATION_REVIEWER",
  "FINAL_APPLICATION_REVIEWER",
  "SIGN_LEASES",
  "EVICTION",
  "SEND_RENEWAL_OFFER",
  "SET_RENEWAL_RENT",
  "SET_MARKET_RENT",
  "EDIT_TEMPLATES",
  "SEND_TENANT_ANNOUNCEMENTS",
  "SEND_VENDOR_ANNOUNCEMENTS",
  "SEND_ACH_INTERNAL_TRANSFER",
  "SEND_WIRE_TRANSFER",
  "CHARGE_TENANT",
  "CREATE_TENANT_RECEIPT",
  "CREDIT_TENANT",
  "REFUND_TENANT",
  "REVERSE_TENANT_PAYMENT",
  "CREATE_TASKS",
  "EDIT_TASK_BUDGET",
  "COMPLETE_TASKS",
  "OVERRIDE_TASK_STATUS",
  "REVIEW_TASK_INVOICE",
  "ASSIGN_NON_COMPLIANT_VENDOR",
  "VIEW_VENDORS",
  "CREATE_VENDORS",
  "LINK_VENDOR_BANK_ACCOUNT",
  "UPDATE_VENDOR_BUSINESS",
  "UPDATE_VENDOR_ADDRESS",
  "UPDATE_VENDOR_W9",
  "UPDATE_PRINCIPAL_USER_CONTACT_DETAIL",
  "UPDATE_ACCOUNTING_USER_CONTACT_DETAIL",
  "UPDATE_PROJECT_ASSIGNMENT_USER_CONTACT_DETAIL",
  "CREATE_TASK_INVOICE",
  "UPDATE_PM_JOB_CAPACITY",
  "EDIT_PM_PERMISSIONS",
  "VIEW_DELINQUENCY_METRICS",
  "BULK_SKIP_DELINQUENCY_ACTIONS",
  "EDIT_VENDOR_PERMISSIONS",
  "VIEW_REPORTS",
  "EDIT_BRANDING",
  "VIEW_SOCIAL_SECURITY_NUMBER",
  "MANAGE_UTILITIES",
  "BUY_PROPERTIES",
  "UNDERWRITE_PROPERTIES",
  "EDIT_HOME_PURCHASE_APPROVAL_PROCESS",
  "EDIT_TITLE_COMPANY_APPROVAL_PROCESS",
  "DELETE_PROPERTIES",
  "REALLOCATE_HOME",
  "ARCHIVE_CONSTRUCTION_INSPECTIONS",
  "ASSIGN_CONSTRUCTION_MANAGERS",
  "CONSTRUCTION_BUDGET_REVIEW",
  "MANAGE_PURCHASE_ORDERS",
  "UPDATE_CONSTRUCTION_STATUS",
  "LOCK_FIELDS",
  "EDIT_CUSTOM_FIELDS",
  "DELETE_DOCUMENTS",
  "WRITE_OFF_BAD_DEBT",
  "EDIT_ESUSU",
  "TOGGLE_ELECTRONIC_PAYMENTS",
  "EDIT_HOLDING_FEES",
  "EDIT_UNIT_AREAS_AND_ZONES",
  "CANCEL_IN_FLIGHT_TENANT_PAYMENTS",
] as const;

const FunctionalPermissionEffectSchema = Schema.Literal(
  ...FunctionalPermissionValues,
);

type FunctionalPermission = Schema.Schema.Type<
  typeof FunctionalPermissionEffectSchema
>;

const FunctionalPermissionEnum = castEnum(FunctionalPermissionEffectSchema);

function randomFunctionalPermission(): FunctionalPermission {
  return rand(FunctionalPermissionValues);
}

export {
  FunctionalPermissionEffectSchema,
  FunctionalPermissionEnum,
  FunctionalPermissionValues,
  randomFunctionalPermission,
};
export type { FunctionalPermission };
