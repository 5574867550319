import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  String as S,
} from "effect";
import { useContext, useEffect } from "react";

import {
  FormSearchInput,
  hydrateProperty,
  searchAssignableUsers,
  searchAssignableVendors,
  searchProperties,
} from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { uploadFiles } from "@ender/shared/api/files";
import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { FormFileInput } from "@ender/shared/ds/file-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { InputWrapper } from "@ender/shared/ds/input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormSwitch } from "@ender/shared/ds/switch";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import type { TasksAPICreateTaskPayload } from "@ender/shared/generated/ender.api.misc";
import { TasksAPI } from "@ender/shared/generated/ender.api.misc";
import type { AddTaskTagRequest } from "@ender/shared/generated/ender.api.misc.request";
import type {
  TaskTagSystemTag,
  TaskTaskType,
} from "@ender/shared/generated/ender.model.task";
import {
  TaskTagSystemTagEnum,
  TaskTagSystemTagValues,
  TaskTaskPriorityEffectSchema,
  TaskTaskPriorityEnum,
  TaskTaskTypeEffectSchema,
  TaskTaskTypeEnum,
  TaskTaskTypeValues,
} from "@ender/shared/generated/ender.model.task";
import { isMultiple } from "@ender/shared/utils/is";
import { capitalize } from "@ender/shared/utils/string";
import {
  FormMultiFilterSync,
  formatMultiFilterLabel,
} from "@ender/widgets/filters/multi-filter-sync";

const constructTagsPayload = (
  tags: TaskTagSystemTag[],
  note: string,
): AddTaskTagRequest[] =>
  tags.reduce((acc, tag) => {
    acc.push({
      tag,
      note: tag === TaskTagSystemTagEnum.OTHER ? note : UNDEFINED,
    });
    return acc;
  }, [] as AddTaskTagRequest[]);

const taskPrioritySelectData = Object.entries(TaskTaskPriorityEnum)
  .map(([, value]) => ({
    label: capitalize(value),
    value,
  }))
  .reverse();

const tagOptions = [...TaskTagSystemTagValues].sort().map((tag) => ({
  label: capitalize(tag),
  value: tag as string,
}));

const taskTypeSelectData = TaskTaskTypeValues.filter(
  (type) =>
    type === TaskTaskTypeEnum.INTERNAL || type === TaskTaskTypeEnum.WORK_ORDER,
).map((type) => ({
  label: capitalize(type),
  value: type as TaskTaskType,
}));

const NewTaskFormSchema = Schema.Struct({
  description: Schema.String.pipe(
    Schema.nonEmptyString({
      message: () => "Description is required",
    }),
  ),
  files: Schema.Array(Schema.instanceOf(File)).pipe(Schema.mutable),
  internalAssigneeId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  otherTagNote: Schema.String,
  permissionToEnter: Schema.Boolean,
  priority: TaskTaskPriorityEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Priority is required" }),
  ),
  propertyId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Property is required" }),
  ),
  tags: Schema.Array(
    Schema.Struct({
      label: Schema.String,
      value: Schema.Enums(TaskTagSystemTagEnum),
    }),
  ).pipe(Schema.mutable),
  targetDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  type: TaskTaskTypeEffectSchema.pipe(Schema.OptionFromSelf),
  unitId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  vendorId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
}).pipe(
  Schema.filter((values) => {
    if (
      values.tags.some((tag) => tag.value === TaskTagSystemTagEnum.OTHER) &&
      S.isEmpty(values.otherTagNote)
    ) {
      return {
        message: "Note is required",
        path: ["otherTagNote"],
      };
    }
  }),
);

type NewTaskFormValues = Schema.Schema.Type<typeof NewTaskFormSchema>;

type NewTaskModalProps = {
  propertyId?: EnderId;
  unitId?: EnderId;
  onSuccess?: (taskId: EnderId) => void;
  onFail?: (error: unknown) => void;
  type?: TaskTaskType;
};

function NewTaskForm(props: NewTaskModalProps) {
  const {
    onSuccess = F.constVoid,
    propertyId,
    type = TaskTaskTypeEnum.WORK_ORDER,
    unitId,
  } = props;
  const { userPM } = useContext(UserContext);

  const form = useEffectSchemaForm({
    defaultValues: {
      description: "",
      files: [],
      internalAssigneeId: O.none(),
      otherTagNote: "",
      permissionToEnter: false,
      priority: O.fromNullable(TaskTaskPriorityEnum.LOW),
      propertyId: O.fromNullable(propertyId),
      tags: [],
      targetDate: O.none(),
      type: O.fromNullable(type),
      unitId: O.fromNullable(unitId),
      vendorId: O.none(),
    },
    schema: NewTaskFormSchema,
  });

  const formTaskType = O.getOrUndefined(form.watch("type"));
  const formPropertyId = O.getOrUndefined(form.watch("propertyId"));
  const formTags = form.watch("tags");

  const isPropertySelected =
    P.isNotNullable(formPropertyId) && S.isNonEmpty(formPropertyId);

  const isOtherTagSelected = formTags.some(
    ({ value }) => value === TaskTagSystemTagEnum.OTHER,
  );

  const tagsLabel = formatMultiFilterLabel(
    formTags.map(({ label }) => label),
    "Task Tags",
  );

  const { data: units = [] } = useQuery({
    enabled: isPropertySelected,
    queryFn: () =>
      UnitsAPI.getUnits({
        propertyIds: isPropertySelected ? [formPropertyId] : [],
      }),
    queryKey: ["UnitsAPI.getUnits", { propertyId: formPropertyId }],
  });

  // reset the unitId every time `units` changes (as a result of propertyId changing)
  useEffect(() => {
    if (units.length === 1) {
      form.setValue("unitId", O.fromNullable(units[0].id));
    } else {
      form.setValue("unitId", O.none());
    }
  }, [form, units]);

  const { mutateAsync: createTask, isLoading: isSubmitting } = useMutation({
    mutationFn: TasksAPI.createTask,
    mutationKey: ["TasksAPI.createTask"],
  });

  const onSubmit = async (values: NewTaskFormValues) => {
    const {
      description,
      files = [],
      internalAssigneeId,
      otherTagNote,
      permissionToEnter,
      priority,
      propertyId,
      tags,
      targetDate,
      type,
      unitId,
      vendorId,
    } = values;

    const payload: TasksAPICreateTaskPayload = {
      addTagRequests:
        formTaskType === TaskTaskTypeEnum.INTERNAL
          ? constructTagsPayload(
              tags.map(({ value }) => value),
              otherTagNote,
            )
          : UNDEFINED,
      description,
      internalAssigneeId: O.getOrUndefined(internalAssigneeId),
      permissionToEnter,
      priority: O.getOrUndefined(priority),
      propertyId: O.getOrUndefined(propertyId),
      targetDate: targetDate.pipe(
        O.map((v) => v.toJSON()),
        O.getOrUndefined,
      ),
      type: O.getOrUndefined(type),
      unitId: O.getOrUndefined(unitId),
      vendorId: O.getOrUndefined(vendorId),
    };

    const { id: taskId } = await createTask(payload);

    if (A.isNonEmptyArray(files)) {
      await uploadFiles({
        files,
        modelId: taskId,
        modelType: ModelTypeEnum.TASK,
        subFolder: "PUBLIC",
      });
    }
    onSuccess(taskId);
  };

  return (
    <Form onSubmit={onSubmit} form={form}>
      <Stack>
        <FormTextarea
          form={form}
          name="description"
          label="Description"
          maxRows={10}
          minRows={6}
        />
        <FormSearchInput<EnderId, typeof form>
          form={form}
          name="propertyId"
          modelType={ModelTypeEnum.PROPERTY}
          label="Property"
          placeholder="Select Property"
          search={searchProperties}
          hydrate={hydrateProperty}
          clearable
        />
        {isPropertySelected && isMultiple(units) && (
          <FormSelect
            form={form}
            name="unitId"
            clearable
            data={units.map((unit) => ({ label: unit.name, value: unit.id }))}
            label="Unit"
            placeholder="Search Unit"
          />
        )}
        {userPM.enablePermissionToEnter && (
          <FormSwitch
            form={form}
            name="permissionToEnter"
            label="Permission to enter?"
          />
        )}
        <FormSelect
          form={form}
          name="priority"
          data={taskPrioritySelectData}
          label="Priority"
          placeholder="Select priority"
        />
        <FormSelect
          form={form}
          name="type"
          data={taskTypeSelectData}
          label="Type"
          placeholder="Select task type"
        />
        {formTaskType === TaskTaskTypeEnum.INTERNAL && (
          <>
            <InputWrapper
              label="Task Tags (Optional)"
              labelId=""
              errorId=""
              inputId="">
              <FormMultiFilterSync
                form={form}
                name="tags"
                data={tagOptions}
                label={tagsLabel}
              />
            </InputWrapper>
            {isOtherTagSelected && (
              <FormTextInput
                name="otherTagNote"
                label="Note for 'Other' Task Tag"
                form={form}
              />
            )}
          </>
        )}
        <FormSearchInput<EnderId, typeof form>
          form={form}
          name="internalAssigneeId"
          label="Internal Assignee (Optional)"
          placeholder="Search"
          modelType={ModelTypeEnum.USER}
          search={searchAssignableUsers}
          clearable
        />
        {formTaskType === TaskTaskTypeEnum.WORK_ORDER && (
          <FormSearchInput<EnderId, typeof form>
            form={form}
            name="vendorId"
            label="Vendor Assignee (Optional)"
            placeholder="Search"
            modelType={ModelTypeEnum.VENDOR}
            search={searchAssignableVendors}
            clearable
          />
        )}
        <FormDateInput
          form={form}
          name="targetDate"
          label="Due Date (Optional)"
          minDate={LocalDate$.today()}
        />
        <FormFileInput name="files" form={form} />
        <Group justify={Justify.end}>
          <Button type="submit" disabled={isSubmitting}>
            Create New Task
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { NewTaskForm };
