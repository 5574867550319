import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetMoveInTableRequestMoveInSortKeyValues = [
  "MOVE_IN_DATE",
  "NUM_COMPLETED_REQUIRED_STEPS",
  "DEPOSIT_PENDING",
  "CONDITION_STATEMENT_SENT_TIME",
  "ONBOARDING_LINK_SENT",
] as const;

const GetMoveInTableRequestMoveInSortKeyEffectSchema = Schema.Literal(
  ...GetMoveInTableRequestMoveInSortKeyValues,
);

type GetMoveInTableRequestMoveInSortKey = Schema.Schema.Type<
  typeof GetMoveInTableRequestMoveInSortKeyEffectSchema
>;

const GetMoveInTableRequestMoveInSortKeyEnum = castEnum(
  GetMoveInTableRequestMoveInSortKeyEffectSchema,
);

function randomGetMoveInTableRequestMoveInSortKey(): GetMoveInTableRequestMoveInSortKey {
  return rand(GetMoveInTableRequestMoveInSortKeyValues);
}

export {
  GetMoveInTableRequestMoveInSortKeyEffectSchema,
  GetMoveInTableRequestMoveInSortKeyEnum,
  GetMoveInTableRequestMoveInSortKeyValues,
  randomGetMoveInTableRequestMoveInSortKey,
};
export type { GetMoveInTableRequestMoveInSortKey };
