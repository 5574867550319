import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useStore } from "zustand";

import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import { TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum } from "@ender/shared/generated/ender.arch.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { LedgerEventReversalButton } from "@ender/widgets/finance/ledger-event-reversal-button";

import { useGetLeaseId } from "../../../../hooks";
import { useTenantLedgerStore } from "../../../../tenant-ledger-store.context";

type ReverseChargeButtonProps = {
  isReversal: boolean;
  isReversed: boolean;
  openReverseModal: () => void;
};

function ReverseChargeButton({
  isReversal,
  isReversed,
  openReverseModal,
}: ReverseChargeButtonProps) {
  const disabledTooltip = useMemo(() => {
    if (isReversed) {
      return "This entry has already been reversed.";
    }

    if (isReversal) {
      return "This transaction reverses a previous one and cannot be reversed again.";
    }
  }, [isReversal, isReversed]);

  const isReversible = !isReversed && !isReversal;

  return (
    <LedgerEventReversalButton
      onClick={openReverseModal}
      disabled={!isReversible}
      disabledTooltip={disabledTooltip}>
      Reverse
    </LedgerEventReversalButton>
  );
}

const ReverseChargeFormSchema = Schema.Struct({
  reversalDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((v): v is O.Option<LocalDate$.LocalDate> => O.isSome(v), {
      message: () => "Reversal Date is required",
    }),
  ),
  reversalReason: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Reversal reason is required" }),
  ),
});

type ReverseChargeFormInput = Schema.Schema.Encoded<
  typeof ReverseChargeFormSchema
>;

type ReverseChargeFormProps = {
  onSuccess: () => void;
  reversalDate: LocalDate | undefined;
  reversalReason: string;
};

function ReverseChargeForm({
  onSuccess,
  reversalDate,
  reversalReason,
}: ReverseChargeFormProps) {
  const leaseId = useGetLeaseId();
  const tenantLedgerStore = useTenantLedgerStore();
  const { selectedLedgerEvent } = useStore(tenantLedgerStore, (state) => ({
    selectedLedgerEvent: state.selectedLedgerEvent,
  }));

  const { mutateAsync: reverseTenantCharge, isLoading: isReversingCharge } =
    useMutation({
      mutationFn: TenantLedgerAPI.reverseTenantCharge,
      mutationKey: ["TenantLedgerAPI.reverseTenantCharge"] as const,
    });
  const { mutateAsync: reverseTenantCredit, isLoading: isReversingCredit } =
    useMutation({
      mutationFn: TenantLedgerAPI.reverseTenantLedgerEntry,
      mutationKey: ["TenantLedgerAPI.reverseTenantLedgerEntry"] as const,
    });
  const isReversing = useMemo(() => {
    return isReversingCharge || isReversingCredit;
  }, [isReversingCharge, isReversingCredit]);

  const ledgerEntryTypeLabel = selectedLedgerEvent.pipe(
    O.filter(
      (event) =>
        event.tenantLedgerEventType ===
        TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.CREDIT,
    ),
    O.match({
      onNone: () => "Charge",
      onSome: () => "Credit",
    }),
  );

  const form = useForm<ReverseChargeFormInput>({
    defaultValues: {
      reversalDate: LocalDate$.parse(reversalDate),
      reversalReason,
    },
    mode: "onSubmit",
    resolver: effectTsResolver(ReverseChargeFormSchema),
  });

  const reversalDateInputValue = useWatch({
    control: form.control,
    name: "reversalDate",
  });

  const reversalReasonInputValue = useWatch({
    control: form.control,
    name: "reversalReason",
  });

  async function reverseCharge() {
    const _selectedLedgerEvent = selectedLedgerEvent.pipe(O.getOrUndefined);
    if (P.isNullable(_selectedLedgerEvent)) {
      return;
    }

    await reverseTenantCharge({
      chargeId: _selectedLedgerEvent.id,
      leaseId,
      reversalDate: reversalDateInputValue.pipe(
        O.map((date) => date.toJSON()),
        O.getOrThrow,
      ),
      reversalReason: reversalReasonInputValue,
    });
    onSuccess();
    showSuccessNotification({ message: "Charge successfully reversed." });
  }

  async function reverseCredit() {
    const _selectedLedgerEvent = selectedLedgerEvent.pipe(O.getOrUndefined);
    if (P.isNullable(_selectedLedgerEvent)) {
      return;
    }

    await reverseTenantCredit({
      ledgerEventId: _selectedLedgerEvent.id,
      ledgerEventType: _selectedLedgerEvent.ledgerEventType,
      reversalDate: reversalDateInputValue.pipe(
        O.map((date) => date.toJSON()),
        O.getOrThrow,
      ),
    });
    onSuccess();
    showSuccessNotification({ message: "Credit successfully reversed." });
  }

  function handleFormSubmit() {
    const _selectedLedgerEvent = selectedLedgerEvent.pipe(O.getOrUndefined);
    if (P.isNullable(_selectedLedgerEvent)) {
      return;
    }

    if (
      _selectedLedgerEvent.tenantLedgerEventType ===
      TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.CREDIT
    ) {
      return reverseCredit();
    }

    return reverseCharge();
  }

  const minReversalDate2 = selectedLedgerEvent.pipe(
    O.map((event) => LocalDate$.of(event.specificLedgerDate)),
    O.getOrElse(() => UNDEFINED),
  );

  return (
    <Form form={form} onSubmit={handleFormSubmit}>
      <Stack>
        <H2>Reverse {ledgerEntryTypeLabel}</H2>
        <Text size={FontSize.md}>
          Processing a reversal will create offsetting entries for this{" "}
          {ledgerEntryTypeLabel.toLowerCase()}. The reversing entry will appear
          on both the General Ledger and the Tenant Ledger
        </Text>
        <FormDateInput
          minDate={minReversalDate2}
          label="Reversal Date"
          name="reversalDate"
          form={form}
        />
        <FormTextInput
          label="Reversal Reason"
          name="reversalReason"
          form={form}
        />
        <Group justify={Justify.end}>
          <Button type="submit" loading={isReversing}>
            Confirm
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

type LedgerEventChargeReverseBtnType = {
  onSuccess: () => void;
  description: string;
};

function LedgerEventChargeReverseBtn({
  onSuccess,
  description,
}: LedgerEventChargeReverseBtnType) {
  const queryClient = useQueryClient();
  const [
    isReverseModalOpen,
    { setTrue: openReverseModal, setFalse: closeReverseModal },
  ] = useBoolean(false);
  const tenantLedgerStore = useTenantLedgerStore();

  const { selectedLedgerEvent, setLedgerEvent } = useStore(
    tenantLedgerStore,
    (state) => ({
      selectedLedgerEvent: state.selectedLedgerEvent,
      setLedgerEvent: state.setLedgerEvent,
    }),
  );

  const handleSuccess = async () => {
    setLedgerEvent(O.none());
    await queryClient.invalidateQueries(["getTenantLedger"]);
    closeReverseModal();
    onSuccess();
  };

  return (
    <>
      <ReverseChargeButton
        openReverseModal={openReverseModal}
        isReversed={selectedLedgerEvent.pipe(
          O.map((event) => event.isReversedCharge || event.isReversedCredit),
          O.getOrElse(() => false),
        )}
        isReversal={selectedLedgerEvent.pipe(
          O.map((event) => event.isChargeReversal || event.isCreditReversal),
          O.getOrElse(() => false),
        )}
      />
      <Modal onClose={closeReverseModal} opened={isReverseModalOpen} title="">
        <ReverseChargeForm
          onSuccess={handleSuccess}
          reversalDate={selectedLedgerEvent.pipe(
            O.map((event) => event.specificLedgerDate),
            O.getOrElse(() => UNDEFINED),
          )}
          reversalReason={`Reversal of ${description}`}
        />
      </Modal>
    </>
  );
}

export { LedgerEventChargeReverseBtn };
