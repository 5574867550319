import type { UseFormReturnType } from "@mantine/form";
import { createFormContext } from "@mantine/form";
import type { UseForm, UseFormInput } from "@mantine/form/lib/types";
import type { PropsWithChildren } from "react";

import { cast } from "@ender/shared/types/cast";

// Create a form context using the `createFormContext` function from the `@mantine/form` package
const [MantineFormProvider, _useFormContext, _useForm] = createFormContext();

/**
 * Returns the form context for the current form.
 * @deprecated - There is no replacement for this hook.
 * @returns {UseFormReturnType} The form context.
 */
function useFormContext<T>(): UseFormReturnType<T> {
  // Cast the `_useFormContext` function to the correct type and call it to get the form context
  return cast<() => UseFormReturnType<T, (values: T) => T>>(_useFormContext)();
}

/**
 * Returns a form object with the specified props.
 * @deprecated - Use `useEffectSchemaForm` from `@ender/form-system/base` instead
 * @param {UseFormInput} props - The props to use for the form.
 * @returns {UseFormReturnType} The form object.
 */
function useForm<T>(props: UseFormInput<T>): UseFormReturnType<T> {
  // Cast the `_useForm` function to the correct type and call it with the specified props to get the form object
  return cast<UseForm<T, (values: T) => T>>(_useForm)(props);
}

/**
 * @deprecated - There is no replacement for this provider.
 */
function FormProvider<T>({
  children,
  form,
}: PropsWithChildren<{ form: UseFormReturnType<T> }>) {
  return (
    <MantineFormProvider form={cast(form)}>{children}</MantineFormProvider>
  );
}

export { FormProvider, useForm, useFormContext };

export type { UseFormInput, UseFormReturnType };
