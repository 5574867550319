/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/securityDeposits
 * @function getLeaseSecurityDeposits
 * @memberof LeasingAPI
 * @param {LeasingAPIGetLeaseSecurityDepositsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetLeaseSecurityDepositsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetLeaseSecurityDepositsResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetLeaseSecurityDepositsPathParams = {
  leaseId: EnderId;
};

type LeasingAPIGetLeaseSecurityDepositsSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetLeaseSecurityDepositsPayload =
  LeasingAPIGetLeaseSecurityDepositsPathParams &
    LeasingAPIGetLeaseSecurityDepositsSearchParams;

function getLeaseSecurityDepositsUnsafeEffect(
  payload: LeasingAPIGetLeaseSecurityDepositsPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetLeaseSecurityDepositsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetLeaseSecurityDepositsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/securityDeposits`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeaseSecurityDeposits(
  payload: LeasingAPIGetLeaseSecurityDepositsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetLeaseSecurityDepositsResponse> {
  return F.pipe(
    payload,
    getLeaseSecurityDepositsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeaseSecurityDeposits };
export type {
  LeasingAPIGetLeaseSecurityDepositsPathParams,
  LeasingAPIGetLeaseSecurityDepositsPayload,
  LeasingAPIGetLeaseSecurityDepositsSearchParams,
};
