import { IconMessage } from "@tabler/icons-react";
import { Predicate as P } from "effect";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Group } from "@ender/shared/ds/group";
import { FontSize, Text } from "@ender/shared/ds/text";

type ActionsRequiredCellProps = {
  needsReply: boolean;
  followUpDate?: LocalDate;
};

function ActionsRequiredCell({
  needsReply,
  followUpDate,
}: ActionsRequiredCellProps) {
  if (needsReply) {
    return (
      <Group>
        <IconMessage color={EnderThemeColorEnum.RED_300} />
        <Text color="red-400" size={FontSize.sm}>
          Needs Reply
        </Text>
      </Group>
    );
  }

  if (
    P.isNotNullable(followUpDate) &&
    LocalDate$.today().isAfter(LocalDate$.of(followUpDate))
  ) {
    return (
      <Text color="red-400">
        <DateDisplay value={LocalDate$.parse(followUpDate)} />
      </Text>
    );
  }

  return <DateDisplay value={LocalDate$.parse(followUpDate)} />;
}

export { ActionsRequiredCell };
