import type { WithRequired } from "@tanstack/react-query";
import { z } from "zod";

import type { EnderId, Money$ } from "@ender/shared/core";
import type { CollectionsAPIGetLeasesInCollectionsPayload } from "@ender/shared/generated/ender.api.finance";
import type {
  CollectionsStep,
  CollectionsStepCollectionsActionType,
} from "@ender/shared/generated/ender.model.misc";
import type { CollectionsReportCollectionsReportRow } from "@ender/shared/generated/ender.service.payments.collections";
import type { LabelValue } from "@ender/shared/types/label-value";
import { castEnum } from "@ender/shared/utils/zod";

type ActionsNeededFilterValue = {
  enabled: boolean;
  value: CollectionsStepCollectionsActionType[];
};

type CollectionsTableRow = Pick<
  CollectionsReportCollectionsReportRow,
  | "leaseId"
  | "marketId"
  | "marketName"
  | "phone"
  | "propertyId"
  | "propertyName"
  | "tenantName"
  | "unitName"
> & {
  lateFee: Money$.Money;
  lateFeeEligible: Money$.Money;
  lateFeeEligibleBalance: Money$.Money;
  totalBalance: Money$.Money;
  totalRentBalance: Money$.Money;
} & Pick<CollectionsReportCollectionsReportRow, "nextStep"> & {
    numDaysRentLate: number;
  };

type CollectionsQueryOptions = {
  keepPreviousData: false;
  select: (
    collections: CollectionsReportCollectionsReportRow[],
  ) => CollectionsTableRow[];
  staleTime: number;
};

type CollectionsRowWithNextStep = WithRequired<CollectionsTableRow, "nextStep">;

const CollectionsTypeValues = [
  "ALL_ACTIVE_LEASES",
  "ALL",
  "ASSISTED_RENT_DUE",
  "EVICTIONS",
  "EXCLUDED",
  "INTEND_TO_EVICT",
  "MOVED_OUT",
  "NON_DELINQUENT",
  "PAYMENT_PLANS",
  "STABLE",
  "UPCOMING",
] as const;
const CollectionsTypeSchema = z.enum(CollectionsTypeValues);
type CollectionsType = z.infer<typeof CollectionsTypeSchema>;

const CollectionsTypeEnum = castEnum<CollectionsType>(CollectionsTypeSchema);

/** Placed here to prevent circular dependencies */
type ChargeLateFeeEntry = {
  leaseId: EnderId;
  tenantName: string;
  lateFeeAmount: Money$.Money;
};
type OpenChargeLateFeeModalProps = {
  lateFees: ChargeLateFeeEntry[];
  step: CollectionsStep;
};
type OpenPauseCollectionsFormProps = {
  leaseId: EnderId;
  onClose: () => void;
  tenantName?: string;
};
type OpenRightRailProps = {
  onClose?: () => void;
  onSuccess: () => void;
  step: CollectionsStep | "bulk";
  leaseId?: EnderId;
  tenantName?: string;
};
type OpenSkipCollectionsStepModalProps = {
  leaseId: EnderId;
  step: CollectionsStep;
};
type OpenLogACallModalProps = {
  leaseId: EnderId;
  stepId: EnderId;
  tenantName: string;
  onSuccess: () => void;
};

type CollectionsTableMeta = {
  actionTypes: CollectionsStepCollectionsActionType[];
  actionsNeededOptions: LabelValue<CollectionsStepCollectionsActionType>[];
  collectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload;
  errorMessage: string;
  firmOptions: LabelValue<EnderId>[];
  isIncludeCompleteMoveOutAccountingEnabled: boolean;
  isIncludePendingMoveOutAccountingEnabled: boolean;
  marketOptions: LabelValue<EnderId>[];
  onCompleteMoveoutAccountingChange: (val: boolean) => void;
  onOnlyShowActionsNeeded: (val: boolean) => void;
  onPendingMoveoutAccountingChange: (val: boolean) => void;
  onlyShowActionsNeeded: boolean;
  openChargeLateFeeModal: (props: OpenChargeLateFeeModalProps) => void;
  openLogACallModal: (props: OpenLogACallModalProps) => void;
  openPauseCollectionsModal: (props: OpenPauseCollectionsFormProps) => void;
  openRightRail: (props: OpenRightRailProps) => void;
  openSkipCollectionsStepModal: (
    props: OpenSkipCollectionsStepModalProps,
  ) => void;
  propertyFirms: Record<EnderId, EnderId>;
  propertyOptions: LabelValue<EnderId>[];
  setActionTypes: (val: CollectionsStepCollectionsActionType[]) => void;
  title: string;
};

const ACTION_NEEDED_FILTER_QUERY_KEY = "actionNeeded";
const FIRM_FILTER_QUERY_KEY = "firm";
const FUND_FILTER_QUERY_KEY = "fund";
const GET_LEASES_IN_COLLECTIONS_QUERY_KEY =
  "CollectionsAPI.getLeasesInCollections";
const MARKET_FILTER_QUERY_KEY = "market";
const MINIMUM_COLLECTABLE_AMOUNT_FILTER_QUERY_KEY = "minAmount";
const ONLY_ACTION_NEEDED_FILTER_QUERY_KEY = "anyActionNeeded";
const PROPERTY_FILTER_QUERY_KEY = "property";
const RESIDENT_NAME_FILTER_QUERY_KEY = "residentName";

export {
  ACTION_NEEDED_FILTER_QUERY_KEY,
  CollectionsTypeEnum,
  CollectionsTypeSchema,
  CollectionsTypeValues,
  FIRM_FILTER_QUERY_KEY,
  FUND_FILTER_QUERY_KEY,
  GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
  MARKET_FILTER_QUERY_KEY,
  MINIMUM_COLLECTABLE_AMOUNT_FILTER_QUERY_KEY,
  ONLY_ACTION_NEEDED_FILTER_QUERY_KEY,
  PROPERTY_FILTER_QUERY_KEY,
  RESIDENT_NAME_FILTER_QUERY_KEY,
};

export type {
  ActionsNeededFilterValue,
  ChargeLateFeeEntry,
  CollectionsQueryOptions,
  CollectionsRowWithNextStep,
  CollectionsTableMeta,
  CollectionsTableRow,
  CollectionsType,
  OpenChargeLateFeeModalProps,
  OpenLogACallModalProps,
  OpenPauseCollectionsFormProps,
  OpenRightRailProps,
  OpenSkipCollectionsStepModalProps,
};
