/**
 * HTTP Method: POST
 * Pathname: /collections/actions/bulk-skip
 * @function bulkSkipAction
 * @memberof CollectionsAPI
 * @param {CollectionsAPIBulkSkipActionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsAction[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { CollectionsAction } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIBulkSkipActionSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIBulkSkipActionBodyParams = {
  notes?: string | undefined;
  stepIdToLeaseIds: Partial<Record<EnderId, EnderId[]>>;
};

type CollectionsAPIBulkSkipActionPayload =
  CollectionsAPIBulkSkipActionSearchParams &
    CollectionsAPIBulkSkipActionBodyParams;

function bulkSkipActionUnsafeEffect(
  payload: CollectionsAPIBulkSkipActionPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsAction[]>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsAction[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/collections/actions/bulk-skip",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function bulkSkipAction(
  payload: CollectionsAPIBulkSkipActionPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsAction[]> {
  return F.pipe(
    payload,
    bulkSkipActionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { bulkSkipAction };
export type {
  CollectionsAPIBulkSkipActionBodyParams,
  CollectionsAPIBulkSkipActionPayload,
  CollectionsAPIBulkSkipActionSearchParams,
};
