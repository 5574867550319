import { useInfiniteQuery } from "@tanstack/react-query";
import { Option as O, String as S } from "effect";
import { useCallback, useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, LocalDate } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import { AccountingMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type {
  GetGLJournalEntryApprovalRowsResponse,
  GetRecurringGLJournalEntryApprovalRowsResponse,
} from "@ender/shared/generated/com.ender.middle.response";
import type { MinimalPropertyResponse } from "@ender/shared/generated/ender.api.model";
import type { RecurringGLJournalEntryFrequency } from "@ender/shared/generated/ender.model.accounting";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { useAccountingFiltersStore } from "@ender/shared/stores/accounting-filters-store";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import {
  useTable,
  useTableColumnVisibility,
  useTableRowSelection,
  useTableSorting,
} from "@ender/shared/ui/table-tanstack";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

import {
  journalEntryColumns,
  recurringEntryColumns,
} from "./gltx-approvals-table-columns";
import type {
  GeneralLedgerTransactionApprovalsTableTabsType,
  JournalEntryApprovalsTableRow,
  RecurringJournalEntryApprovalsTableRow,
} from "./gltx-approvals-table.types";
import { GeneralLedgerTransactionApprovalsTableTabsEnum } from "./gltx-approvals-table.types";

const PAGE_SIZE = 20;

type UseGeneralLedgerTransactionApprovalsTableProps = {
  approvalStatusTab: GeneralLedgerTransactionApprovalsTableTabsType;
  tableTypeTab: ApprovalProcessType;
};

function useGeneralLedgerTransactionApprovalsTable({
  approvalStatusTab,
  tableTypeTab,
}: UseGeneralLedgerTransactionApprovalsTableProps) {
  const sorting = useTableSorting({
    initialSorting: [{ desc: true, id: "transactionDate" }],
  });

  const {
    accountingProperties,
    authors,
    dateValidationState,
    endDate,
    endDateRangeEndDate,
    endDateRangeStartDate,
    endDateRangeValidationState,
    firmFilter,
    frequencies,
    fundFilter,
    periodFilter,
    recurringJournalEntryId,
    startDate,
    startDateRangeEndDate,
    startDateRangeStartDate,
    startDateRangeValidationState,
    systemDateValidationState,
    systemEndDate,
    systemStartDate,
  } = useAccountingFiltersStore();

  const rowSelection = useTableRowSelection<
    JournalEntryApprovalsTableRow | RecurringJournalEntryApprovalsTableRow
  >({
    enableRowSelection: true,
  });
  const { onRowSelectionChange } = rowSelection;

  const unselectAll = useCallback(() => {
    onRowSelectionChange({});
  }, [onRowSelectionChange]);

  //temporarily hide authorDisplay column https://ender-1337.atlassian.net/browse/ENDER-25597
  const hideAuthorDisplayColumn = true;
  const columnVisibility = useTableColumnVisibility({
    initialVisibility: {
      authorDisplay: false,
    },
  });

  function approvalStatusFilter(
    tab: GeneralLedgerTransactionApprovalsTableTabsType,
  ) {
    if (
      tab ===
      GeneralLedgerTransactionApprovalsTableTabsEnum.APPROVED_TRANSACTIONS
    ) {
      return [ApprovableApprovalStatusEnum.APPROVED];
    } else if (
      tab ===
      GeneralLedgerTransactionApprovalsTableTabsEnum.REJECTED_TRANSACTIONS
    ) {
      return [ApprovableApprovalStatusEnum.REJECTED];
    } else if (
      tab === GeneralLedgerTransactionApprovalsTableTabsEnum.ALL_UNAPPROVED ||
      tab === GeneralLedgerTransactionApprovalsTableTabsEnum.ASSIGNED_TO_ME
    ) {
      return [ApprovableApprovalStatusEnum.NEW];
    } else {
      return [];
    }
  }

  const approvalStatuses = useMemo(
    () => approvalStatusFilter(approvalStatusTab),
    [approvalStatusTab],
  );

  const {
    data: { pages = [] } = {},
    isFetching: isFetchingJournalEntries,
    fetchNextPage: fetchNextPageJournalEntries,
    refetch: refetchJournalEntries,
  } = useInfiniteQuery<
    GetGLJournalEntryApprovalRowsResponse,
    unknown,
    GetGLJournalEntryApprovalRowsResponse,
    [
      string,
      {
        PAGE_SIZE: number;
        accountingProperties: MinimalPropertyResponse[];
        approvalStatuses: ApprovableApprovalStatus[];
        approvalStatusTab: GeneralLedgerTransactionApprovalsTableTabsType;
        dateValidationState: {
          isValidDateRange: boolean;
          isValidMonthRange: boolean;
        };
        endDate: LocalDate;
        firmFilter: EnderId[];
        fundFilter: EnderId[];
        periodFilter: LocalDate[];
        startDate: LocalDate;
      },
    ]
  >({
    enabled:
      tableTypeTab === ApprovalProcessTypeEnum.GL_JOURNAL_ENTRY &&
      dateValidationState.isValidDateRange,
    getNextPageParam: (lastPage: GetGLJournalEntryApprovalRowsResponse) => {
      if (lastPage?.rows?.length > 0) {
        return lastPage.rows[lastPage.rows.length - 1].id;
      }
      return undefined;
    },
    keepPreviousData: true,
    queryFn: ({ queryKey: [, payload], pageParam = "", signal }) =>
      AccountingMiddleLayerAPI.getGLJournalEntryApprovalRows(
        {
          accountingPeriods: payload.periodFilter,
          approvalStatuses: payload.approvalStatuses,
          assignedToMe:
            payload.approvalStatusTab ===
              GeneralLedgerTransactionApprovalsTableTabsEnum.ASSIGNED_TO_ME ||
            UNDEFINED,
          filters: [],
          firmIds: payload.firmFilter,
          fundIds: payload.fundFilter,
          inclusiveEndLedgerDate: payload.endDate ?? UNDEFINED,
          limit: payload.PAGE_SIZE,
          offsetId: pageParam,
          propertyIds: payload.accountingProperties.map(
            (property) => property.id,
          ),
          startLedgerDate: payload.startDate ?? UNDEFINED,
        },
        { signal },
      ),
    queryKey: [
      "AccountingMiddleLayerAPI.getGLJournalEntryApprovalRows",
      {
        PAGE_SIZE,
        accountingProperties,
        approvalStatusTab,
        approvalStatuses,
        dateValidationState,
        endDate,
        firmFilter,
        fundFilter,
        periodFilter,
        startDate,
      },
    ],
  });

  const {
    data: { pages: recurringEntriesPages = [] } = {},
    isFetching: isFetchingRecurringEntries,
    fetchNextPage: fetchNextPageRecurringEntries,
    refetch: refetchRecurringEntries,
  } = useInfiniteQuery<
    GetRecurringGLJournalEntryApprovalRowsResponse,
    unknown,
    GetRecurringGLJournalEntryApprovalRowsResponse,
    [
      string,
      {
        PAGE_SIZE: number;
        accountingProperties: MinimalPropertyResponse[];
        approvalStatuses: ApprovableApprovalStatus[];
        approvalStatusTab: GeneralLedgerTransactionApprovalsTableTabsType;
        authors: SelectOption<EnderId, string>[];
        endDate: LocalDate;
        endDateRangeEndDate: LocalDate;
        endDateRangeStartDate: LocalDate;
        endDateRangeValidationState: {
          isValidDateRange: boolean;
          isValidMonthRange: boolean;
        };
        firmFilter: EnderId[];
        frequencies: SelectOption<RecurringGLJournalEntryFrequency, string>[];
        fundFilter: EnderId[];
        hideAuthorDisplayColumn: boolean;
        periodFilter: LocalDate[];
        recurringJournalEntryId: O.Option<EnderId>;
        startDate: LocalDate;
        startDateRangeEndDate: LocalDate;
        startDateRangeStartDate: LocalDate;
        startDateRangeValidationState: {
          isValidDateRange: boolean;
          isValidMonthRange: boolean;
        };
        systemDateValidationState: {
          isValidDateRange: boolean;
          isValidMonthRange: boolean;
        };
        systemEndDate: LocalDate;
        systemStartDate: LocalDate;
      },
    ]
  >({
    enabled:
      tableTypeTab === ApprovalProcessTypeEnum.RECURRING_GL_JOURNAL_ENTRY &&
      endDateRangeValidationState.isValidDateRange &&
      startDateRangeValidationState.isValidDateRange &&
      systemDateValidationState.isValidDateRange,
    getNextPageParam: (
      lastPage: GetRecurringGLJournalEntryApprovalRowsResponse,
    ) => {
      if (lastPage.rows.length > 0) {
        return lastPage.rows[lastPage.rows.length - 1].id;
      }
    },
    keepPreviousData: true,
    queryFn: ({ queryKey: [, payload], pageParam = "", signal }) =>
      AccountingMiddleLayerAPI.getRecurringGLJournalEntryApprovalRows(
        {
          approvalStatuses,
          assignedToMe:
            payload.approvalStatusTab ===
              GeneralLedgerTransactionApprovalsTableTabsEnum.ASSIGNED_TO_ME ||
            UNDEFINED,
          authorIds: payload.hideAuthorDisplayColumn
            ? []
            : payload.authors.map((author) => author.value),
          filters: [],
          firmIds: payload.firmFilter,
          frequencies: payload.frequencies.map((frequency) => frequency.value),
          fundIds: payload.fundFilter,
          inclusiveEndEndDate: payload.endDateRangeEndDate ?? UNDEFINED,
          inclusiveEndStartDate: payload.startDateRangeEndDate ?? UNDEFINED,
          inclusiveEndSystemDate: payload.systemEndDate ?? UNDEFINED,
          limit: payload.PAGE_SIZE,
          offsetId: pageParam,
          propertyIds: payload.accountingProperties.map(
            (property) => property.id,
          ),
          recurringGLJournalEntryIds: payload.recurringJournalEntryId.pipe(
            O.map((id) => [id]),
            O.getOrElse(() => []),
          ),
          startEndDate: payload.endDateRangeStartDate ?? UNDEFINED,
          startStartDate: payload.startDateRangeStartDate ?? UNDEFINED,
          startSystemDate: payload.systemStartDate ?? UNDEFINED,
        },
        { signal },
      ),
    queryKey: [
      "AccountingMiddleLayerAPI.getRecurringGLJournalEntryApprovalRows",
      {
        PAGE_SIZE,
        accountingProperties,
        approvalStatusTab,
        approvalStatuses,
        authors,
        endDate,
        endDateRangeEndDate,
        endDateRangeStartDate,
        endDateRangeValidationState,
        firmFilter,
        frequencies,
        fundFilter,
        hideAuthorDisplayColumn,
        periodFilter,
        recurringJournalEntryId,
        startDate,
        startDateRangeEndDate,
        startDateRangeStartDate,
        startDateRangeValidationState,
        systemDateValidationState,
        systemEndDate,
        systemStartDate,
      },
    ],
  });

  const formattedJournalEntryData = pages
    .flatMap((page) => page.rows)
    .map((transaction) => ({
      ...transaction,
      step: S.isNonEmpty(transaction.approvalStepDisplay)
        ? transaction.approvalStepDisplay
        : convertSnakeCaseToTitleCase(transaction.approvalStatus),
      transactionType: "Journal Entry",
    }));

  const formattedRecurringEntriesData = useMemo(
    () =>
      recurringEntriesPages
        .flatMap((page) => page.rows)
        .map((transaction) => ({
          ...transaction,
          frequency: convertSnakeCaseToTitleCase(transaction.frequency),
          step:
            transaction.approvalStepDisplay ||
            convertSnakeCaseToTitleCase(transaction.approvalStatus),
        })),
    [recurringEntriesPages],
  );

  const tableData = useMemo(() => {
    if (tableTypeTab === ApprovalProcessTypeEnum.GL_JOURNAL_ENTRY) {
      return {
        columns: journalEntryColumns,
        data: formattedJournalEntryData,
        fetchNextPage: fetchNextPageJournalEntries,
        isFetching: isFetchingJournalEntries,
        refetch: refetchJournalEntries,
      };
    } else {
      return {
        columns: recurringEntryColumns,
        data: formattedRecurringEntriesData,
        fetchNextPage: fetchNextPageRecurringEntries,
        isFetching: isFetchingRecurringEntries,
        refetch: refetchRecurringEntries,
      };
    }
  }, [
    formattedRecurringEntriesData,
    fetchNextPageJournalEntries,
    fetchNextPageRecurringEntries,
    formattedJournalEntryData,
    isFetchingJournalEntries,
    isFetchingRecurringEntries,
    refetchJournalEntries,
    refetchRecurringEntries,
    tableTypeTab,
  ]);

  const table = useTable({
    columnVisibility,
    columns: tableData.columns as ColumnDef<
      JournalEntryApprovalsTableRow | RecurringJournalEntryApprovalsTableRow
    >[],
    data: tableData.data,
    enableQueryParams: true,
    fetchNextPage: tableData.fetchNextPage,
    getRowId: (row) => row.id,
    isLoading: tableData.isFetching,
    refetch: tableData.refetch,
    rowSelection,
    sorting,
    title: "General Ledger Transaction Approvals",
  });

  return {
    isFetching: tableData.isFetching,
    refetch: tableData.refetch,
    table,
    unselectAll,
  };
}

export { useGeneralLedgerTransactionApprovalsTable };
