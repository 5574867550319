import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useState } from "react";
import { z } from "zod";

import { Card } from "@ender/shared/ds/card";
import { Stack } from "@ender/shared/ds/stack";
import { TabButton, TabContent, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { LeaseLeaseStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import { castEnum } from "@ender/shared/utils/zod";
import { Chat } from "@ender/widgets/chat";
import { WorkOrders } from "@ender/widgets/leasing/work-orders";

import type { UnitPageRightRailValue } from "../unit-page-rail/unit-page-rail";
import { CurrentLeases } from "./current-leases";
import { UnitPageDetails } from "./details/unit-page-details";
import { PreviousLeases } from "./previous-leases/previous-leases";
import { UnitPageDocuments } from "./unit-page-documents";
import {
  activeLeaseSubstatuses,
  pastLeaseSubstatuses,
  pendingLeaseSubstatuses,
  upcomingLeaseSubstatuses,
} from "./unit-page-lease.types";

const UnitPageTabValues = [
  "CURRENT_LEASES",
  "PREVIOUS_LEASES",
  "TASKS",
  "DETAILS",
  "DOCUMENTS",
  "NOTES",
] as const;
const UnitPageTabsSchema = z.enum(UnitPageTabValues);
type UnitPageTab = z.infer<typeof UnitPageTabsSchema>;
const UnitPageTabEnum = castEnum<UnitPageTab>(UnitPageTabsSchema);

type UnitPageTabsProps = {
  unit?: UnitSerializerUnitResponse;
  setRightRail: (value: UnitPageRightRailValue) => void;
};

function UnitPageTabs(props: UnitPageTabsProps) {
  const { unit, setRightRail } = props;
  const { id: unitId } = unit ?? {};
  const [tab, setTab] = useState<UnitPageTab>(UnitPageTabEnum.CURRENT_LEASES);

  const {
    data: partitionedLeases = {
      active: [],
      error: [],
      past: [],
      pending: [],
      upcoming: [],
    },
  } = useQuery({
    enabled: P.isNotNullable(unitId),
    queryFn: ({ signal }) =>
      LeasingAPI.searchLeases(
        {
          keyword: "",
          marketIds: [],
          propertyIds: [],
          unitIds: A.fromNullable(unitId),
        },
        { signal },
      ),
    queryKey: ["LeasingAPI.searchLeases", unitId] as const,
    select: (data) => ({
      active:
        data?.filter((lease) =>
          activeLeaseSubstatuses.includes(lease.substatus),
        ) ?? [],
      error:
        data?.filter((lease) => lease.status === LeaseLeaseStatusEnum.ERROR) ??
        [],
      past:
        data?.filter((lease) =>
          pastLeaseSubstatuses.includes(lease.substatus),
        ) ?? [],
      pending:
        data?.filter((lease) =>
          pendingLeaseSubstatuses.includes(lease.substatus),
        ) ?? [],
      upcoming:
        data?.filter((lease) =>
          upcomingLeaseSubstatuses.includes(lease.substatus),
        ) ?? [],
    }),
  });

  return (
    <Tabs value={tab} onChange={setTab}>
      <Stack>
        <TabsList>
          <TabButton value={UnitPageTabEnum.CURRENT_LEASES}>
            Current Leases
          </TabButton>
          <TabButton value={UnitPageTabEnum.PREVIOUS_LEASES}>
            Previous Leases
          </TabButton>
          <TabButton value={UnitPageTabEnum.TASKS}>Tasks</TabButton>
          <TabButton value={UnitPageTabEnum.DETAILS}>Details</TabButton>
          <TabButton value={UnitPageTabEnum.DOCUMENTS}>Documents</TabButton>
          <TabButton value={UnitPageTabEnum.NOTES}>Notes</TabButton>
        </TabsList>

        <TabContent value={UnitPageTabEnum.CURRENT_LEASES}>
          <CurrentLeases leases={partitionedLeases} />
        </TabContent>
        <TabContent value={UnitPageTabEnum.PREVIOUS_LEASES}>
          <PreviousLeases leases={partitionedLeases} />
        </TabContent>
        <TabContent value={UnitPageTabEnum.TASKS}>
          {P.isNotNullable(unit) && (
            <WorkOrders modelType={ModelTypeEnum.UNIT} modelId={unit.id} />
          )}
        </TabContent>
        <TabContent value={UnitPageTabEnum.DETAILS}>
          {P.isNotNullable(unit) && (
            <UnitPageDetails unit={unit} setRightRail={setRightRail} />
          )}
        </TabContent>
        <TabContent value={UnitPageTabEnum.DOCUMENTS}>
          {P.isNotNullable(unitId) && <UnitPageDocuments unitId={unitId} />}
        </TabContent>
        <TabContent value={UnitPageTabEnum.NOTES}>
          {P.isNotNullable(unit) && (
            <Card padding="none" shrink grow>
              <Chat
                title="Notes"
                channels={[
                  {
                    chatTitle: "Notes",
                    isInternal: true,
                    modelId: unit.id,
                    modelType: ModelTypeEnum.UNIT,
                  },
                ]}
              />
            </Card>
          )}
        </TabContent>
      </Stack>
    </Tabs>
  );
}

export { UnitPageTabs };
