import { IconAlertCircle, IconEdit } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import { useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { FontWeight, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { TupleList } from "@ender/shared/ds/tuple";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetGLJournalEntryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RecurringGLJournalEntryFrequencyEnum,
  RecurringGLJournalEntryTxTransactionDirectionEnum,
} from "@ender/shared/generated/ender.model.accounting";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { useQueryParamsEnderId } from "@ender/shared/hooks/use-query-params";
import { Ellipsis } from "@ender/shared/ui/ellipsis";
import { toLongMonthYearString } from "@ender/shared/utils/local-date";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

import { useTableTypeTabs } from "../gltx-approvals-table/use-gltx-approvals-table-tabs";

type AccountingPeriodValueProps = {
  isAccountingPeriodClosed: boolean;
  value: string;
};

function AccountingPeriodValue({
  isAccountingPeriodClosed,
  value,
}: AccountingPeriodValueProps) {
  const textColor = isAccountingPeriodClosed ? "red-500" : UNDEFINED;
  return (
    <Tooltip
      disabled={!isAccountingPeriodClosed}
      label="This accounting period is closed for your user type">
      <Group align={Align.center} spacing={Spacing.xs}>
        <Text color={textColor} weight={FontWeight.medium}>
          {value}
        </Text>
        {isAccountingPeriodClosed && <IconAlertCircle color={Color.red} />}
      </Group>
    </Tooltip>
  );
}

type PropertyValueProps = {
  label: string;
  propertyId?: EnderId;
};

function PropertyValue({ label, propertyId }: PropertyValueProps) {
  if (P.isNullable(propertyId)) {
    return label;
  }

  return (
    <RouterLink href={`/properties/${propertyId}`} target="_blank">
      {label}
    </RouterLink>
  );
}

type LinkedEntryProps = {
  label: string;
  id: EnderId;
  isRecurring?: boolean;
};

function LinkedEntry({ label, id, isRecurring = false }: LinkedEntryProps) {
  const [, setTableTypeTab] = useTableTypeTabs();
  const [, setId] = useQueryParamsEnderId("id");
  return (
    <Button
      variant="transparent"
      onClick={() => {
        if (P.isTruthy(isRecurring)) {
          setTableTypeTab(
            O.some(ApprovalProcessTypeEnum.RECURRING_GL_JOURNAL_ENTRY),
          );
        }
        setId(id);
      }}>
      <Ellipsis>{label}</Ellipsis>
    </Button>
  );
}

type TransactionDetailsProps = {
  journalEntry: O.Option<GetGLJournalEntryResponse>;
  recurringJournalEntry: O.Option<GetRecurringGLJournalEntryDetailsResponse>;
  isAccountingPeriodClosed: boolean;
  isEditable?: boolean;
  openEditDrawer: () => void;
  reversedById?: EnderId;
  reversesId?: EnderId;
};

function GeneralLedgerTransactionApprovalsTransactionDetails({
  journalEntry,
  recurringJournalEntry,
  isAccountingPeriodClosed,
  isEditable = true,
  openEditDrawer,
  reversedById,
  reversesId,
}: TransactionDetailsProps) {
  const { data: reverseEntry, isLoading: isLoadingReverseEntry } = useQuery({
    enabled: P.isNotNullable(reversedById) || P.isNotNullable(reversesId),
    queryFn: () =>
      AccountingAPI.getJournalEntry({
        //@ts-expect-error: Type '(string & Brand<"EnderId">) | undefined' is not assignable to type 'string & Brand<"EnderId">'. Query only enabled if defined.
        journalEntryId: reversedById ?? reversesId,
      }),
    queryKey: ["AccountingAPI.getJournalEntry", reversedById, reversesId],
    select: (transaction) => ({
      description: transaction.description,
      transactionDate: transaction.ledgerDate,
    }),
  });

  const amountPerEntry = useMemo(() => {
    if (O.isSome(recurringJournalEntry)) {
      const { allocations } = recurringJournalEntry.pipe(
        O.map((r) => r),
        O.getOrThrow,
      );

      const creditTotal = allocations.reduce((total, allocation) => {
        if (
          allocation.transactionDirection ===
          RecurringGLJournalEntryTxTransactionDirectionEnum.CREDIT
        ) {
          return Money$.add(total, Money$.of(allocation.amount));
        }
        return total;
      }, Money$.of(0));

      return Money$.parse(creditTotal);
    }

    return O.none();
  }, [recurringJournalEntry]);

  const totalAmount = useMemo(() => {
    if (
      O.isSome(recurringJournalEntry) &&
      P.isTruthy(recurringJournalEntry.value.endDate)
    ) {
      const amountPerEntryValue = amountPerEntry.pipe(
        O.map((a) => a),
        O.getOrThrow,
      );
      const { startDate, endDate, frequency } = recurringJournalEntry.pipe(
        O.map((r) => r),
        O.getOrThrow,
      );

      const start = LocalDate$.of(startDate);
      const end = LocalDate$.of(endDate);

      // Helper functions for each frequency type
      const calculateMonthlyTimesPosted = (
        start: LocalDate$.LocalDate,
        end: LocalDate$.LocalDate,
      ) => {
        const monthsDiff =
          (LocalDate$.year(end) - LocalDate$.year(start)) * 12 +
          (LocalDate$.month(end) - LocalDate$.month(start)) +
          (LocalDate$.date(end) >= LocalDate$.date(start) ? 1 : 0);
        return Math.max(1, monthsDiff);
      };

      const calculateQuarterlyTimesPosted = (
        start: LocalDate$.LocalDate,
        end: LocalDate$.LocalDate,
      ) => {
        const monthsDiff =
          (LocalDate$.year(end) - LocalDate$.year(start)) * 12 +
          (LocalDate$.month(end) - LocalDate$.month(start));
        return Math.max(1, Math.ceil(monthsDiff / 3));
      };

      const calculateYearlyTimesPosted = (
        start: LocalDate$.LocalDate,
        end: LocalDate$.LocalDate,
      ) => {
        const yearsDiff = LocalDate$.year(end) - LocalDate$.year(start);
        return Math.max(
          1,
          yearsDiff +
            (LocalDate$.month(end) > LocalDate$.month(start) ||
            (LocalDate$.month(end) === LocalDate$.month(start) &&
              LocalDate$.date(end) >= LocalDate$.date(start))
              ? 1
              : 0),
        );
      };
      const timesPostedCalculators = {
        [RecurringGLJournalEntryFrequencyEnum.MONTHLY]:
          calculateMonthlyTimesPosted,
        [RecurringGLJournalEntryFrequencyEnum.QUARTERLY]:
          calculateQuarterlyTimesPosted,
        [RecurringGLJournalEntryFrequencyEnum.YEARLY]:
          calculateYearlyTimesPosted,
      };

      // Calculate # of times the entry will be posted using the appropriate function
      const timesPosted = timesPostedCalculators[frequency](start, end);

      const total = Money$.multiply(amountPerEntryValue, timesPosted);

      return O.some(total);
    }

    return O.none();
  }, [amountPerEntry, recurringJournalEntry]);

  //helper tuple functions
  const createDateTuple = (label: string, date: string | undefined) => ({
    label,
    value: P.isNotNullable(date) ? (
      <DateDisplay value={LocalDate$.of(date)} />
    ) : (
      ""
    ),
  });

  const createTextTuple = (label: string, value: string | undefined) => ({
    label,
    value: P.isNotNullable(value) ? value : "",
  });

  const createPropertyTuple = (
    label: string,
    name: string | undefined,
    id: EnderId | undefined,
  ) => ({
    label,
    value: <PropertyValue label={name || ""} propertyId={id} />,
  });

  const createMoneyTuple = (label: string, value: O.Option<Money$.Money>) => ({
    label,
    value: <MoneyDisplay value={value} showSymbol />,
  });

  const createBlankTuple = (label: string) => ({
    label,
    value: "",
  });

  function getLinkedEntry(
    id: EnderId | undefined,
    description: string | undefined,
    isRecurring?: boolean,
  ) {
    if (P.isNullable(id) || P.isNullable(description)) {
      return "";
    }
    return (
      <LinkedEntry id={id} label={description} isRecurring={isRecurring} />
    );
  }

  function getReversalValue() {
    if (isLoadingReverseEntry) {
      return "";
    }

    if (P.isNotNullable(reversedById)) {
      return getLinkedEntry(reversedById, reverseEntry?.description);
    }

    if (P.isNotNullable(reversesId)) {
      return getLinkedEntry(reversesId, reverseEntry?.description);
    }

    return "";
  }

  // Get tuples based on entry type
  const getTuples = () => {
    if (O.isSome(journalEntry)) {
      const je = journalEntry.pipe(
        O.map((j) => j),
        O.getOrThrow,
      );
      // Journal entry tuples in specified order
      return [
        createTextTuple("Journal Entry ID", je.id),
        createDateTuple("Transaction Date", je.transactionDate),
        createTextTuple("Firm", je.firmName),
        {
          label: "Accounting Period",
          value: (
            <AccountingPeriodValue
              isAccountingPeriodClosed={isAccountingPeriodClosed}
              value={je.accountingPeriod}
            />
          ),
        },
        createBlankTuple("Fund"),
        {
          label: P.isNotNullable(reversesId)
            ? "Reversed Entry"
            : "Reversing Entry",
          value: getReversalValue(),
        },
        createPropertyTuple(
          "Property",
          je.propertyResponse.name,
          je.propertyResponse.id,
        ),
        P.isNotNullable(reversedById)
          ? createDateTuple("Reversing Date", reverseEntry?.transactionDate)
          : createBlankTuple("Reversing Date"),
        createTextTuple("Memo", je.description),
        {
          label: "Recurring Entry",
          value: getLinkedEntry(
            je.recurringJournalEntryId,
            je.recurringGLJournalEntryTitle,
            true,
          ),
        },
      ];
    } else {
      return recurringJournalEntry.pipe(
        O.map((r) => [
          createTextTuple("Recurring Journal Entry ID", r.id),
          createMoneyTuple("Amount per Entry", amountPerEntry),
          O.isSome(totalAmount)
            ? createMoneyTuple("Total Amount", totalAmount)
            : createTextTuple("Total Amount", "N/A"),
          createTextTuple(
            "Frequency",
            convertSnakeCaseToTitleCase(r.frequency),
          ),
          createTextTuple("Firm", r.firmDisplay),
          createDateTuple("Start Date", r.startDate),
          createBlankTuple("Fund"),
          createDateTuple("End Date", r.endDate),
          createPropertyTuple("Property", r.propertyDisplay, r.propertyId),
          createDateTuple("Next Post Date", r.nextPostDate),
          createTextTuple("Memo", r.title),
          {
            label: "Next Post Period",
            value: r.nextPostDate
              ? toLongMonthYearString(LocalDate$.of(r.nextPostDate))
              : "",
          },
        ]),
        O.getOrElse(() => []),
      );
    }
  };

  const tuplesRow = getTuples();

  return (
    <>
      <Stack spacing={Spacing.sm}>
        <Group noWrap justify={Justify.between} align={Align.center}>
          <H3>Transaction Details</H3>
          {isEditable && (
            <ActionIcon
              label="Edit transaction"
              onClick={openEditDrawer}
              variant={ButtonVariant.transparent}
              size={ButtonSize.lg}>
              <IconEdit />
            </ActionIcon>
          )}
        </Group>
        <div className="grid content-start grid-cols-1 md:grid-cols-2">
          <TupleList entries={tuplesRow} />
        </div>
      </Stack>
    </>
  );
}

export { GeneralLedgerTransactionApprovalsTransactionDetails };
