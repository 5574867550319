/**
 * HTTP Method: POST
 * Pathname: /accounting/{propertyId}/retainEarnings
 * @function retainEarnings
 * @memberof AccountingAPI
 * @param {AccountingAPIRetainEarningsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GLJournalEntry>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GLJournalEntry } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIRetainEarningsPathParams = {
  propertyId: EnderId;
};

type AccountingAPIRetainEarningsSearchParams = {
  token?: string | undefined;
};

type AccountingAPIRetainEarningsBodyParams = {
  dateExclusive: SerializesInto<LocalDate>;
};

type AccountingAPIRetainEarningsPayload =
  AccountingAPIRetainEarningsPathParams &
    AccountingAPIRetainEarningsSearchParams &
    AccountingAPIRetainEarningsBodyParams;

function retainEarningsUnsafeEffect(
  payload: AccountingAPIRetainEarningsPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GLJournalEntry>({
        body,
        decode: unsafeDecodeJsonResponse<GLJournalEntry>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accounting/${propertyId}/retainEarnings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function retainEarnings(
  payload: AccountingAPIRetainEarningsPayload,
  options?: { signal?: AbortSignal },
): Promise<GLJournalEntry> {
  return F.pipe(
    payload,
    retainEarningsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { retainEarnings };
export type {
  AccountingAPIRetainEarningsBodyParams,
  AccountingAPIRetainEarningsPathParams,
  AccountingAPIRetainEarningsPayload,
  AccountingAPIRetainEarningsSearchParams,
};
