import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetChargeScheduleResponseChargingScheduleStatusValues = [
  "ACTIVE",
  "EXPIRED",
  "DRAFTING",
  "UPCOMING",
] as const;

const GetChargeScheduleResponseChargingScheduleStatusEffectSchema =
  Schema.Literal(...GetChargeScheduleResponseChargingScheduleStatusValues);

type GetChargeScheduleResponseChargingScheduleStatus = Schema.Schema.Type<
  typeof GetChargeScheduleResponseChargingScheduleStatusEffectSchema
>;

const GetChargeScheduleResponseChargingScheduleStatusEnum = castEnum(
  GetChargeScheduleResponseChargingScheduleStatusEffectSchema,
);

function randomGetChargeScheduleResponseChargingScheduleStatus(): GetChargeScheduleResponseChargingScheduleStatus {
  return rand(GetChargeScheduleResponseChargingScheduleStatusValues);
}

export {
  GetChargeScheduleResponseChargingScheduleStatusEffectSchema,
  GetChargeScheduleResponseChargingScheduleStatusEnum,
  GetChargeScheduleResponseChargingScheduleStatusValues,
  randomGetChargeScheduleResponseChargingScheduleStatus,
};
export type { GetChargeScheduleResponseChargingScheduleStatus };
