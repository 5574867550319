import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { FirmsAPI } from "@ender/shared/generated/ender.api.core";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const AddInvestorFormSchema = Schema.Struct({
  name: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Name is required" }),
  ),
});

type AddInvestorFormValues = Schema.Schema.Type<typeof AddInvestorFormSchema>;

type AddInvestorFormProps = {
  onSuccess: () => unknown;
};

function AddInvestorForm({ onSuccess }: AddInvestorFormProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const form = useForm<AddInvestorFormValues>({
    defaultValues: {
      name: "",
    },
    resolver: effectTsResolver(AddInvestorFormSchema),
  });

  const { mutateAsync: handleFormSubmit, isLoading: isSubmitting } =
    useMutation({
      mutationFn: ({ name }: AddInvestorFormValues) =>
        FirmsAPI.newFirm({
          name,
        }),
      onSuccess: () => {
        onSuccess();
        setIsModalOpen(false);
        showSuccessNotification({ message: "Investor added." });
      },
    });

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Add Investor</Button>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Investor">
        <Form form={form} onSubmit={(values) => handleFormSubmit(values)}>
          <Stack spacing={Spacing.xl}>
            <FormTextInput name="name" form={form} label="Investor Name" />
            <Group justify={Justify.end}>
              <Button type="submit" loading={isSubmitting}>
                Save Investor
              </Button>
            </Group>
          </Stack>
        </Form>
      </Modal>
    </>
  );
}

export { AddInvestorForm };
