import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeaseLeaseSubstatusValues = [
  "DRAFTING_RENEWAL",
  "ACTIVE",
  "ACTIVE_EVICTING",
  "ACTIVE_NON_RENEWABLE",
  "ACTIVE_RENEWED",
  "EXPIRING",
  "MOVED_OUT",
  "MOVE_OUT_BALANCE",
  "MOVE_OUT_OVERDUE",
  "MOVE_OUT_PENDING_DEPOSIT_ACCOUNTING",
  "MOVE_OUT_REFUND",
  "MONTH_TO_MONTH",
  "MONTH_TO_MONTH_POST_TERM",
  "ON_NOTICE",
  "ON_NOTICE_EVICTING",
  "RENEWED",
  "UPCOMING",
  "DRAFTING",
  "CREATING_PDF",
  "OUT_FOR_SIGNATURES",
  "FINAL_SIGNATURE",
  "ERROR",
  "CANCELED",
] as const;

const LeaseLeaseSubstatusEffectSchema = Schema.Literal(
  ...LeaseLeaseSubstatusValues,
);

type LeaseLeaseSubstatus = Schema.Schema.Type<
  typeof LeaseLeaseSubstatusEffectSchema
>;

const LeaseLeaseSubstatusEnum = castEnum(LeaseLeaseSubstatusEffectSchema);

function randomLeaseLeaseSubstatus(): LeaseLeaseSubstatus {
  return rand(LeaseLeaseSubstatusValues);
}

export {
  LeaseLeaseSubstatusEffectSchema,
  LeaseLeaseSubstatusEnum,
  LeaseLeaseSubstatusValues,
  randomLeaseLeaseSubstatus,
};
export type { LeaseLeaseSubstatus };
