/**
 * HTTP Method: POST
 * Pathname: /buy/investors/{firmId}/settings
 * @function setAcquisitionFee
 * @memberof BuyAPI
 * @param {BuyAPISetAcquisitionFeePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Percent, SerializesInto } from "@ender/shared/core";
import type { DealChannel } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPISetAcquisitionFeePathParams = {
  firmId: EnderId;
};

type BuyAPISetAcquisitionFeeSearchParams = {
  token?: string | undefined;
};

type BuyAPISetAcquisitionFeeBodyParams = {
  acquisitionFee: SerializesInto<Percent>;
  channel: DealChannel;
};

type BuyAPISetAcquisitionFeePayload = BuyAPISetAcquisitionFeePathParams &
  BuyAPISetAcquisitionFeeSearchParams &
  BuyAPISetAcquisitionFeeBodyParams;

function setAcquisitionFeeUnsafeEffect(
  payload: BuyAPISetAcquisitionFeePayload,
) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/investors/${firmId}/settings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setAcquisitionFee(
  payload: BuyAPISetAcquisitionFeePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setAcquisitionFeeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setAcquisitionFee };
export type {
  BuyAPISetAcquisitionFeeBodyParams,
  BuyAPISetAcquisitionFeePathParams,
  BuyAPISetAcquisitionFeePayload,
  BuyAPISetAcquisitionFeeSearchParams,
};
