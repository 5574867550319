import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useEffect, useMemo, useState } from "react";

import type { CustomField } from "@ender/shared/api/factors";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { useForm } from "@ender/shared/forms/hooks/general";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { EnderInputWrapper } from "@ender/shared/ui/ender-input-wrapper";
import { EnderSearch, useEnderSearchForm } from "@ender/shared/ui/ender-search";
import { FactorFieldInput } from "@ender/shared/ui/factor-field-input";
import { NewCustomFactorFormController } from "@ender/shared/ui/new-custom-factor-form";
import type { EnderDate } from "@ender/shared/utils/ender-date";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const NEW_CUSTOM_FIELD_NAME = "New Custom Field";

type AddCustomFieldFormValues = {
  factor?: CustomField["factor"];
  factorId?: EnderId;
  modelId: EnderId;
  modelType: ModelType;
  value?: string | number | Money$.Money | EnderDate | boolean | null;
};

type ValidAddCustomFieldFormValues = {
  factorId: EnderId;
  modelId: EnderId;
  modelType: ModelType;
  value: string;
};

type AddCustomFieldFormProps = {
  excludedFields: CustomField[];
  modelId: EnderId;
  modelType: ModelType;
  onSuccess: () => void;
};

function AddCustomFieldForm({
  excludedFields,
  modelId,
  modelType,
  onSuccess,
}: AddCustomFieldFormProps) {
  const [isNewFactorModalOpen, setIsNewFactorModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const form = useForm<AddCustomFieldFormValues>({
    initialValues: {
      factor: UNDEFINED,
      factorId: UNDEFINED,
      modelId,
      modelType,
      value: "",
    },
    validate: {
      factorId: (value) => (value ? null : "This field is required"),
      value: (value) => (value ? null : "This field is required"),
    },
  });

  const formRef = useRefLatest(form);

  // lazy useEffect
  useEffect(() => {
    if (!form.values.factor) {
      formRef.current.setFieldValue("customFieldValue", NULL);
      return;
    }

    const { factor } = formRef.current.values;
    if (P.isNotNullable(factor) && factor.name === NEW_CUSTOM_FIELD_NAME) {
      setIsNewFactorModalOpen(true);
    }
  }, [formRef, form.values.factor]);

  const { mutateAsync: submitForm } = useMutation({
    mutationFn: (values: AddCustomFieldFormValues) =>
      FactorsAPI.setCustomField(values as ValidAddCustomFieldFormValues).then(
        () => onSuccess(),
      ),
    mutationKey: ["FactorsAPI.setCustomField", { modelId, modelType }] as const,
  });

  const enderSearchParams = useMemo(
    () => ({
      allowFactorCreation: true,
      builtInFactors: false,
      customFactors: true,
      excludeIds: excludedFields.map((field) => field.factor.id),
      inputTypes: [modelType],
      resultsOnEmpty: false,
    }),
    [excludedFields, modelType],
  );

  function closeNewFactorModal() {
    setIsNewFactorModalOpen(false);
  }

  function onNewFactorCreated() {
    closeNewFactorModal();
    form.setFieldValue("factor", undefined);
    queryClient.invalidateQueries([
      "POST",
      "/factors/search",
      enderSearchParams,
    ]);
    showSuccessNotification({
      title: "New Field Created",
      message: "You can now select the created field.",
    });
  }

  function onFieldValueChange(val: {
    factorId: EnderId;
    value:
      | string
      | number
      | Money$.Money
      | EnderDate
      | boolean
      | undefined
      | null;
  }) {
    form.setFieldValue("factorId", val.factorId);
    form.setFieldValue("value", val.value);
  }

  const factorSearchProps = useEnderSearchForm({ name: "factor", form });

  return (
    <>
      <Modal
        opened={isNewFactorModalOpen}
        onClose={closeNewFactorModal}
        title="New Custom Field">
        <NewCustomFactorFormController
          modelType={modelType}
          closeModal={closeNewFactorModal}
          onSuccess={onNewFactorCreated}
        />
      </Modal>
      <Stack>
        <EnderSearch
          label="Field Name"
          route="/factors/search"
          placeholder="Select field to add"
          requestParams={enderSearchParams}
          onSelect={factorSearchProps.onSelect}
          onChange={factorSearchProps.onChange}
          onClear={factorSearchProps.onClear}
          value={factorSearchProps.value}
        />
        <UnmanagedForm form={form} onSubmit={submitForm}>
          <Stack>
            <Stack>
              {form.values.factor &&
                form.values.factor.name !== NEW_CUSTOM_FIELD_NAME && (
                  <EnderInputWrapper
                    label="Field Value"
                    error={form.errors.customFieldValue}>
                    <FactorFieldInput
                      factorId={form.values.factor.id}
                      outputType={form.values.factor.outputType}
                      value={form.values.value}
                      onChange={onFieldValueChange}
                    />
                  </EnderInputWrapper>
                )}
            </Stack>
            <Group justify={Justify.end}>
              <Button type="submit">Add Field</Button>
            </Group>
          </Stack>
        </UnmanagedForm>
      </Stack>
    </>
  );
}

export { AddCustomFieldForm };
