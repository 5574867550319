import { Array as A, pipe } from "effect";
import { useMemo } from "react";

import { Money$ } from "@ender/shared/core";
import { H2 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple, TupleList } from "@ender/shared/ds/tuple";

import type { AugmentedMoveOutLease } from "../../../../widgets/move-out/types";

function getDeposits(lease: AugmentedMoveOutLease) {
  const { securityDeposits } = lease;
  const { balances } = securityDeposits;

  const [depositsTotalAmount, deposits] = pipe(
    balances,
    A.mapAccum(Money$.zero(), (acc, d) => [
      Money$.add(acc, Money$.of(d.amount)),
      {
        description: d.type,
        id: d.categoryId,
        remainingAmount: Money$.of(d.amount),
      },
    ]),
  );

  return {
    deposits,
    depositsTotalAmount,
  };
}

type DepositsProps = {
  deposits: {
    description: string;
    remainingAmount: Money$.Money;
  }[];
  depositsTotalAmount: Money$.Money;
};

function Deposits(props: DepositsProps) {
  const { deposits, depositsTotalAmount } = props;

  const tuples = useMemo(() => {
    return deposits.map((depositItem) => {
      return {
        label: depositItem.description,
        value: <MoneyDisplay showSymbol value={depositItem.remainingAmount} />,
      };
    });
  }, [deposits]);

  return (
    <Stack>
      <Tuple
        label={<H2>Deposits Withheld</H2>}
        value={
          <H2>
            <MoneyDisplay showSymbol value={depositsTotalAmount} />
          </H2>
        }
      />
      <TupleList entries={tuples} />
    </Stack>
  );
}

export { Deposits, getDeposits };
