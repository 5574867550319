/**
 * HTTP Method: POST
 * Pathname: /brokers/search
 * @function getBrokers
 * @memberof BuyAPI
 * @param {BuyAPIGetBrokersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIGetBrokersResponse>}
 */
import { Effect, Function as F } from "effect";

import type { BrokersServiceBrokerType } from "@ender/shared/generated/com.ender.buy.service";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIGetBrokersResponse } from "../buy-api-get-brokers-response";

type BuyAPIGetBrokersSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetBrokersBodyParams = {
  type?: BrokersServiceBrokerType | undefined;
};

type BuyAPIGetBrokersPayload = BuyAPIGetBrokersSearchParams &
  BuyAPIGetBrokersBodyParams;

function getBrokersUnsafeEffect(payload: BuyAPIGetBrokersPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIGetBrokersResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BuyAPIGetBrokersResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/brokers/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBrokers(
  payload: BuyAPIGetBrokersPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIGetBrokersResponse> {
  return F.pipe(
    payload,
    getBrokersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBrokers };
export type {
  BuyAPIGetBrokersBodyParams,
  BuyAPIGetBrokersPayload,
  BuyAPIGetBrokersSearchParams,
};
