import { Array as A, Predicate as P, String as S } from "effect";

import {
  exportBankRecReport,
  propertyCashReconciliationReportExcel,
} from "@ender/shared/api/files";
import type { Undefined } from "@ender/shared/constants/general";
import {
  DATE_STRING_DISTANT_PAST,
  DATE_STRING_FAR_FUTURE,
} from "@ender/shared/constants/string";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { MenuButton } from "@ender/shared/ds/menu";
import type { ReconTypeFilter } from "@ender/shared/generated/ender.api.accounting.request";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import { PeriodFilterFilterTypeEnum } from "@ender/shared/generated/ender.api.model";
import { getAndDownloadBlobData } from "@ender/shared/utils/general";
import { DownloadMenu } from "@ender/widgets/download-menu";

type BankingDetailDownloadMenuProps = {
  accountId: EnderId;
  amountFilter: string;
  descriptionFilter: string;
  inclusiveEndDate: LocalDate;
  periodFilter: PeriodFilter["customFilter"];
  propertyFilter: EnderId[];
  showDeposits: boolean;
  showDraws: boolean;
  // TODO typeFilter is not accepted by FileExportAPI methods payload.  Does not appear used in BE.
  startDate: LocalDate;
  typeFilter: ReconTypeFilter | Undefined;
};

function BankingDetailDownloadMenu({
  accountId,
  amountFilter,
  descriptionFilter,
  inclusiveEndDate,
  periodFilter,
  propertyFilter,
  showDeposits,
  showDraws,
  startDate,
  typeFilter,
}: BankingDetailDownloadMenuProps) {
  function handleExcelClick() {
    const _inclusiveEndDate = P.isNullable(inclusiveEndDate)
      ? LocalDate$.of(DATE_STRING_FAR_FUTURE).toJSON()
      : inclusiveEndDate;
    const _startDate = P.isNullable(startDate)
      ? LocalDate$.of(DATE_STRING_DISTANT_PAST).toJSON()
      : startDate;
    getAndDownloadBlobData(
      () =>
        //temporary workaround for Werner's REST system fixes
        exportBankRecReport({
          accountId,
          ...(S.isNonEmpty(amountFilter) && { amountMatch: amountFilter }),
          ...(S.isNonEmpty(descriptionFilter) && {
            description: descriptionFilter,
          }),
          // ...(!isEmpty(_inclusiveEndDate) && { inclusiveEndDate: _inclusiveEndDate }),
          inclusiveEndDate: _inclusiveEndDate,
          ...(A.isNonEmptyArray(periodFilter) && {
            periodFilter: {
              customFilter: periodFilter,
              type: PeriodFilterFilterTypeEnum.CUSTOM,
            },
          }),
          propertyIds: propertyFilter,
          showDeposits,
          showDraws,
          // ...(!isEmpty(_startDate) && { startDate: _startDate }),
          startDate: _startDate,
          typeFilter,
        }),
      { message: "Downloading XLSX..." },
    );
  }

  function handleDownloadPropertyCashTransactionXLSX() {
    getAndDownloadBlobData(
      () =>
        //temporary workaround for Werner's REST system fixes
        propertyCashReconciliationReportExcel({
          bankAccountId: accountId,
          inclusiveEndDate,
          startDate,
          // @ts-expect-error - payload error - BE needs to integrate typeFilter
          typeFilter,
        }),
      { message: "Downloading XLSX..." },
    );
  }

  return (
    <DownloadMenu>
      <MenuButton onClick={handleExcelClick}>Download XLSX</MenuButton>
      <MenuButton onClick={handleDownloadPropertyCashTransactionXLSX}>
        Property Cash Transaction XLSX
      </MenuButton>
    </DownloadMenu>
  );
}

export { BankingDetailDownloadMenu };
