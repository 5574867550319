import { Predicate as P, String as S } from "effect";

import { NULL } from "@ender/shared/constants/general";
import type { BadgeColors } from "@ender/shared/ds/badge";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import type { GetGLJournalEntryResponseApprovalStatusResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

type JournalEntryApprovalStatusProps = {
  approvalStatus?:
    | GetGLJournalEntryResponseApprovalStatusResponse
    | ApprovableApprovalStatus;
  approvalStepDisplay?: string;
};

const getBadgeProps = (
  status: JournalEntryApprovalStatusProps["approvalStatus"],
  stepName?: string,
): { children: string; color: BadgeColors } | typeof NULL => {
  if (P.isObject(status)) {
    // Handle JournalEntry case
    if (status.isApproved) {
      return { children: "Approved", color: BadgeColor.green };
    } else if (status.isFullyRejected) {
      return { children: "Rejected", color: BadgeColor.red };
    } else {
      return { children: status.approvalStepName, color: BadgeColor.yellow };
    }
  } else if (S.isString(status)) {
    // Handle RecurringJournalEntry case
    if (status === ApprovableApprovalStatusEnum.APPROVED) {
      return { children: "Approved", color: BadgeColor.green };
    } else if (status === ApprovableApprovalStatusEnum.REJECTED) {
      return { children: "Rejected", color: BadgeColor.red };
    } else {
      return { children: stepName ?? "", color: BadgeColor.yellow };
    }
  }

  return NULL;
};

function JournalEntryApprovalStatus({
  approvalStatus,
  approvalStepDisplay,
}: JournalEntryApprovalStatusProps) {
  const badgeProp = getBadgeProps(approvalStatus, approvalStepDisplay) || NULL;

  return badgeProp !== NULL ? (
    <Badge color={badgeProp.color}>{badgeProp.children}</Badge>
  ) : (
    NULL
  );
}

export { JournalEntryApprovalStatus };
