import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import { format as formatDate } from "date-fns";
import { Option as O, pipe } from "effect";
import { forwardRef } from "react";

import type { LocalDate$ } from "@ender/shared/core";
import { castEnum } from "@ender/shared/utils/effect";

const DateFormatSchema = Schema.Literal("hyphenated", "slashed", "words");
const DateFormatValues = DateFormatSchema.literals;
type DateFormats = Schema.Schema.Type<typeof DateFormatSchema>;
const DateFormat = castEnum(DateFormatSchema);

const templates: Record<DateFormats, string> = {
  [DateFormat.hyphenated]: "yyyy-MM-dd",
  [DateFormat.slashed]: "MM/dd/yyyy",
  [DateFormat.words]: "MMMM dd, yyyy",
};

type DateDisplayProps = {
  /**
   * The percent to display
   */
  value?: LocalDate$.LocalDate | O.Option<LocalDate$.LocalDate>;
  /**
   * The format to display the date in.
   * hyphenated - YYYY-MM-DD
   * slashed - MM/DD/YYYY
   * words - Month Day, Year
   *
   * @default "slashed"
   */
  format?: DateFormats;
};

const DateVariantGenerator = cva([], {
  defaultVariants: {},
  variants: {},
});

const DateDisplay = forwardRef<HTMLSpanElement, DateDisplayProps>(
  function DateDisplay(props, ref) {
    const { value, format = DateFormat.slashed } = props;

    const _value = O.isOption(value) ? value : O.fromNullable(value);
    return (
      <span ref={ref} className={DateVariantGenerator()}>
        {pipe(
          _value,
          O.map((v) => formatDate(v.toDate(), templates[format])),
          O.getOrNull,
        )}
      </span>
    );
  },
);

export { DateDisplay, DateFormat, DateFormatSchema, DateFormatValues };

export type { DateDisplayProps, DateFormats };
