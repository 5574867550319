import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const VanityVanityTypeValues = [
  "GRANITE",
  "QUARTZ",
  "CULTURED_MARBLE",
  "FORMICA",
  "PEDESTAL",
  "TILE",
] as const;

const VanityVanityTypeEffectSchema = Schema.Literal(...VanityVanityTypeValues);

type VanityVanityType = Schema.Schema.Type<typeof VanityVanityTypeEffectSchema>;

const VanityVanityTypeEnum = castEnum(VanityVanityTypeEffectSchema);

function randomVanityVanityType(): VanityVanityType {
  return rand(VanityVanityTypeValues);
}

export {
  randomVanityVanityType,
  VanityVanityTypeEffectSchema,
  VanityVanityTypeEnum,
  VanityVanityTypeValues,
};
export type { VanityVanityType };
