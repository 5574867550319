import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const InvoiceTableTabValues = [
  "ALL",
  "APPROVED_NOT_PAID",
  "ASSIGNED_TO_ME",
  "PAID",
  "REJECTED",
  "UNAPPROVED",
  "UNPAID",
] as const;
const InvoiceTableTabsSchema = z.enum(InvoiceTableTabValues);
type InvoiceTableTabs = z.infer<typeof InvoiceTableTabsSchema>;

const InvoiceTableTabsEnum = castEnum<InvoiceTableTabs>(InvoiceTableTabsSchema);

export { InvoiceTableTabsEnum, InvoiceTableTabsSchema, InvoiceTableTabValues };
export type { InvoiceTableTabs };
