import { eachMonthOfInterval, format, interval } from "date-fns";
import { Array as A, Function as F, Option as O, pipe } from "effect";
import { forwardRef, useCallback, useMemo, useState } from "react";

import { MONTH_NAMES_SHORT } from "@ender/shared/constants/string";
import { LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Calendar, CalendarLevelEnum } from "@ender/shared/ds/calendar";
import { Card } from "@ender/shared/ds/card";
import { Divider } from "@ender/shared/ds/divider";
import { Justify, Overflow, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { TriggerButtonSizes } from "@ender/shared/ds/menu";
import { ListButton, TriggerButton } from "@ender/shared/ds/menu";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@ender/shared/ds/popover";
import { Stack } from "@ender/shared/ds/stack";
import type { AccountingPeriod } from "@ender/shared/generated/ender.model.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { useAccountingPeriods } from "@ender/shared/hooks/use-accounting-periods";
import { Color } from "@ender/shared/utils/theming";

type AccountingPeriodPresetFunction = {
  label: string;

  /**
   * @param setYear optionally, during the function, you can call this callback, which will update the year displayed by the calendar
   * @param periods periods the LocalDate$ representation of currently-selected periods, to be used as references for relative computations
   * @param meta any additional information that may be relevant for period function computation
   * @returns the accounting periods as an array of YYYY-MM strings
   */
  fn: (
    setYear: (year: LocalDate$.LocalDate) => void,
    periods?: LocalDate$.LocalDate[],
    meta?: {
      allPeriods: AccountingPeriod[];
    },
  ) => LocalDate$.LocalDate[];
};

const defaultAccountingPeriodPresets: AccountingPeriodPresetFunction[] = [
  {
    fn: (setYear) => {
      const previousMonthDate = pipe(
        LocalDate$.today(),
        LocalDate$.add({ months: -1 }),
        LocalDate$.startOfMonth,
      );
      setYear(previousMonthDate);
      return [previousMonthDate];
    },
    label: "Previous Period",
  },
  {
    fn: (setYear) => {
      setYear(LocalDate$.today());
      return [LocalDate$.startOfMonth(LocalDate$.today())];
    },
    label: "Current Period",
  },
  {
    fn: (setYear) => {
      const nextMonthDate = pipe(
        LocalDate$.today(),
        LocalDate$.add({ months: 1 }),
        LocalDate$.startOfMonth,
      );
      setYear(nextMonthDate);
      return [nextMonthDate];
    },
    label: "Next Period",
  },
  {
    fn: (setYear, _, meta = { allPeriods: [] }) => {
      const previousMonthDate = pipe(
        LocalDate$.today(),
        LocalDate$.add({ months: -1 }),
        LocalDate$.startOfMonth,
      );
      const allPeriodsStartDates = meta.allPeriods.map((p) =>
        LocalDate$.of(p.startDate),
      );
      setYear(previousMonthDate);
      const sorted = pipe(allPeriodsStartDates, A.sort(LocalDate$.Order));
      const startDate = sorted?.[0];
      const endDate = previousMonthDate;
      const intvl = interval(startDate?.toDate(), endDate.toDate());
      return eachMonthOfInterval(intvl).map((date) => LocalDate$.of(date));
    },
    label: "Up to Current Period",
  },
  {
    fn: (setYear) => {
      const currentYear = LocalDate$.startOfYear(LocalDate$.today());
      setYear(currentYear);

      const intvl = interval(currentYear.toDate(), LocalDate$.today().toDate());
      return eachMonthOfInterval(intvl).map((date) => LocalDate$.of(date));
    },
    label: "YTD",
  },
  {
    fn: (setYear) => {
      const currentYear = LocalDate$.startOfYear(LocalDate$.today());
      setYear(currentYear);

      const intvl = interval(
        currentYear.toDate(),
        currentYear.add({ months: 11 }).toDate(),
      );
      return eachMonthOfInterval(intvl).map((date) => LocalDate$.of(date));
    },
    label: "This Year",
  },
  {
    fn: (setYear) => {
      const lastYear = pipe(
        LocalDate$.today(),
        LocalDate$.add({ years: -1 }),
        LocalDate$.startOfYear,
      );
      setYear(lastYear);

      const intvl = interval(
        lastYear.toDate(),
        lastYear.add({ months: 11 }).toDate(),
      );
      return eachMonthOfInterval(intvl).map((date) => LocalDate$.of(date));
    },
    label: "Last Year",
  },
];

/**
 *  @description Determines whether an array of dates is consecutive by month
 * @param order - an array of LocalDate$ objects. Expects them to be sorted.
 */
function isConsecutive(order: LocalDate$.LocalDate[]): boolean {
  const intvl = interval(order[0].toDate(), order[order.length - 1].toDate());
  return eachMonthOfInterval(intvl).length === order.length;
}

type AccountingPeriodFilterProps = {
  value: LocalDate$.LocalDate[];
  onChange: (value: LocalDate$.LocalDate[]) => void;
  disabled?: boolean;
  /**
   * the size to use for the trigger button
   */
  size?: TriggerButtonSizes;
};

const AccountingPeriodFilter = forwardRef<
  HTMLDivElement,
  AccountingPeriodFilterProps
>(function AccountingPeriodFilter(props, ref) {
  const { disabled, value, onChange, size } = props;

  const [localValue, setLocalValue] = useState(value);

  const handleChange = useCallback((val: O.Option<LocalDate$.LocalDate>[]) => {
    setLocalValue(pipe(val, A.filterMap(F.identity)));
  }, []);

  const handleApply = useCallback(() => {
    onChange(localValue);
  }, [onChange, localValue]);

  /**
   * the string to display in the trigger button
   */
  const triggerLabel = useMemo(() => {
    if (localValue.length === 0) {
      return "Choose Period";
    }

    if (localValue.length === 1) {
      return format(localValue[0].toDate(), "MMMM yyyy");
    }

    const sorted = pipe(localValue, A.sort(LocalDate$.Order));
    if (isConsecutive(sorted)) {
      const info = A.map(sorted, (v) => ({
        month: LocalDate$.month(v),
        year: LocalDate$.year(v),
      }));
      const { month: firstMonth, year: firstYear } = info[0];
      const { month: lastMonth, year: lastYear } = info[info.length - 1];

      // IF MULTIPLE YEARS ARE SELECTED
      if (firstYear !== lastYear) {
        return `${MONTH_NAMES_SHORT[firstMonth - 1]} ${firstYear} - ${MONTH_NAMES_SHORT[lastMonth - 1]} ${lastYear}`;
      }

      return `${MONTH_NAMES_SHORT[firstMonth - 1]} - ${MONTH_NAMES_SHORT[lastMonth - 1]} ${firstYear}`;
    }

    return `${localValue.length} Acct. Periods selected`;
  }, [localValue]);

  const { data: allPeriods } = useAccountingPeriods({
    periodType: AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER,
  });

  return (
    <Popover onOpenedChange={() => setLocalValue(value)}>
      <PopoverTrigger disabled={disabled}>
        <TriggerButton
          rounded={false}
          selected={A.isNonEmptyArray(localValue)}
          size={size}>
          {triggerLabel}
        </TriggerButton>
      </PopoverTrigger>
      <PopoverContent ref={ref} label="Accounting Period Selector">
        <Stack spacing={Spacing.none}>
          <Group spacing={Spacing.none} overflow={Overflow.auto} grow>
            <div className="max-h-48 overflow-auto">
              <Stack spacing={Spacing.none} overflow={Overflow.auto}>
                {defaultAccountingPeriodPresets.map(({ label, fn }) => (
                  <ListButton
                    color={Color.primary}
                    key={label}
                    onClick={() => {
                      const months = fn(F.constVoid, localValue, {
                        allPeriods: allPeriods,
                      });
                      setLocalValue(months);
                    }}>
                    {label}
                  </ListButton>
                ))}
              </Stack>
            </div>
            <Divider orientation="vertical" />
            <Card borderless padding="sm">
              <Calendar
                value={localValue.map(O.fromNullable)}
                onChange={handleChange}
                level={CalendarLevelEnum.month}
                mode="multiple"
              />
            </Card>
          </Group>
          <Divider />
          <Card borderless padding="sm">
            <Group justify={Justify.between}>
              <Button
                variant={ButtonVariant.transparent}
                onClick={() => handleChange([])}>
                Clear Selection
              </Button>
              <Group spacing={Spacing.md} justify={Justify.end}>
                <PopoverClose>
                  <Button variant={ButtonVariant.transparent}>Cancel</Button>
                </PopoverClose>
                <PopoverClose>
                  <Button onClick={handleApply}>Apply</Button>
                </PopoverClose>
              </Group>
            </Group>
          </Card>
        </Stack>
      </PopoverContent>
    </Popover>
  );
});

export { AccountingPeriodFilter };

export type { AccountingPeriodFilterProps };
