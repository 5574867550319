/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/accept
 * @function acceptApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIAcceptApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApplicationGroupConditionalAcceptanceReason } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIAcceptApplicationPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIAcceptApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIAcceptApplicationBodyParams = {
  conditionalAcceptanceReason?:
    | ApplicationGroupConditionalAcceptanceReason
    | undefined;
  message: string;
};

type ApplicationsAPIAcceptApplicationPayload =
  ApplicationsAPIAcceptApplicationPathParams &
    ApplicationsAPIAcceptApplicationSearchParams &
    ApplicationsAPIAcceptApplicationBodyParams;

function acceptApplicationUnsafeEffect(
  payload: ApplicationsAPIAcceptApplicationPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/accept`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function acceptApplication(
  payload: ApplicationsAPIAcceptApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    acceptApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { acceptApplication };
export type {
  ApplicationsAPIAcceptApplicationBodyParams,
  ApplicationsAPIAcceptApplicationPathParams,
  ApplicationsAPIAcceptApplicationPayload,
  ApplicationsAPIAcceptApplicationSearchParams,
};
