// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { GetInputProps } from "@mantine/form/lib/types";
import { IconTrash } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import * as S from "effect/String";
import { useCallback, useMemo } from "react";
import { z } from "zod";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema, Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { GeneralLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GLCategory as GeneratedGlCategory } from "@ender/shared/generated/ender.model.accounting";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { Select, useSelect } from "@ender/shared/ui/select";
import { CurrencySchema, OptionSchema } from "@ender/shared/utils/zod";

import { PayableCategorySelect } from "./payable-category-select/payable-category-select";

//TODO: modernize & DRY component, addressing all comments below - https://ender-1337.atlassian.net/browse/ENDER-25502 2025-02-18

const GLAllocationSchema = z.object({
  amount: CurrencySchema,
  categoryId: EnderIdSchema,
  description: z.string().optional(),
  payableCategoryId: EnderIdSchema.optional(),
  propertyId: EnderIdSchema,
});

/**
 * probably shouldn't extend the generated schema. This should be based on FE form validation requirements alone
 */
const AllocationListItemSchema = GLAllocationSchema.extend({
  amount: OptionSchema(CurrencySchema)
    .refine(O.isSome, { message: "Amount is required." })
    .refine(O.exists(Money$.isPositive), "Amount must be greater than zero."),
  //TODO it is likely this will eventually be an OptionSchema of an EnderId instance, rather than an EnderIdSchema.
  //It also likely needs a preprocess or some kind of guard to ensure that it is _always_ an EnderId from the get-go
  categoryId: EnderIdSchema.optional().refine(
    (value): value is EnderId => S.isNonEmpty(value ?? ""),
    {
      message: "Category is required.",
    },
  ),
});
type AllocationListItemInput = z.input<typeof AllocationListItemSchema>;
type AllocationListItemOutput = z.output<typeof AllocationListItemSchema>;

type GLCategory = GeneratedGlCategory & { name: string; isParent: boolean };

function useGLTxCategories() {
  return useQuery<GLCategory[]>({
    queryFn: ({ signal }) => {
      return GeneralLedgerAPI.getTxCategories(
        {
          excludeParentCategories: true,
          includeBankAccountCategories: true,
          includeRoots: false,
          keyword: "",
        },
        { signal },
      );
    },
    queryKey: ["GeneralLedgerAPI.getTxCategories"] as const,
    staleTime: 300000,
  });
}

type AllocationListItemProps = {
  allocation: AllocationListItemInput;
  canDeleteAllocation: boolean;
  clearButtonTabIndex: number;
  getInputProps: GetInputProps<AllocationListItemInput>;
  handleAllocationChange: (allocation: AllocationListItemInput) => void;
  hidePayableCategorySelect?: boolean;

  onRemoveAllocation: () => void;
  routeParams: Record<string, boolean | EnderId[]>;
};

function AllocationListItem({
  allocation,
  canDeleteAllocation,
  getInputProps,
  handleAllocationChange,
  hidePayableCategorySelect = false,

  onRemoveAllocation,
}: AllocationListItemProps) {
  const { data: categories = [] } = useGLTxCategories();
  const categoryOptions = useMemo(
    () => categories.map(({ id, name }) => ({ label: name, value: id })),
    [categories],
  );

  const handleCategoryChange = useCallback(
    (categoryId: EnderId | Null) => {
      const category = categories.find((c) => c.id === categoryId);

      const updatedAllocation: AllocationListItemOutput = {
        ...allocation,
        // @ts-expect-error TODO: Should not be using the generated type if making categoryId optional
        categoryId: P.isNotNullable(category?.id) ? category?.id : UNDEFINED,
        payableCategoryId: UNDEFINED,
      };
      handleAllocationChange(updatedAllocation);
    },
    [allocation, categories, handleAllocationChange],
  );

  const glCategorySelectProps = useSelect({
    onSelect: handleCategoryChange,
    value: allocation.categoryId as EnderId | Undefined,
  });

  const { onChange: onAmountChange, ...amountInputProps } =
    getInputProps("amount");

  const handleAllocationPayableCategoryChange = useCallback(
    (
      payableCategoryId: O.Option<AllocationListItemInput["payableCategoryId"]>,
    ) => {
      const updatedAllocation = {
        ...allocation,
        payableCategoryId: O.getOrUndefined(payableCategoryId),
      };
      handleAllocationChange(updatedAllocation);
    },
    [allocation, handleAllocationChange],
  );

  return (
    <Stack>
      <div className="allocation-list-item" role="row">
        <Group noWrap align={Align.start}>
          {/* move this into allocations-by-property-input then DRY across libs/widgets/finance/invoice-allocations/src/lib/allocations-by-property-input/allocations-by-property-input.tsx */}
          <Select
            clearable
            data={categoryOptions}
            error={getInputProps("categoryId").error}
            height="SHORT"
            label="GL Category"
            onChange={glCategorySelectProps.onChange}
            onSearchChange={glCategorySelectProps.onSearchChange}
            searchable
            searchValue={glCategorySelectProps.searchValue}
            testId="allocation-list-ender-search"
            value={glCategorySelectProps.value}
          />
          <MoneyInput
            label="Amount"
            error={amountInputProps.error}
            onChange={onAmountChange}
            value={allocation.amount}
          />
          <div tabIndex={-1} className="mt-5">
            <ActionIcon
              label="Remove this row"
              variant={ButtonVariant.transparent}
              disabled={!canDeleteAllocation}
              onClick={onRemoveAllocation}>
              <IconTrash />
            </ActionIcon>
          </div>
        </Group>
      </div>
      {!hidePayableCategorySelect && (
        <Stack>
          <PayableCategorySelect
            disabled={S.isEmpty(allocation.categoryId ?? "")}
            glCategoryId={O.fromNullable(glCategorySelectProps.value)}
            onChange={handleAllocationPayableCategoryChange}
            value={O.fromNullable(allocation.payableCategoryId)}
          />
        </Stack>
      )}
      <Divider />
    </Stack>
  );
}

export { AllocationListItem, AllocationListItemSchema };
export type { AllocationListItemInput, AllocationListItemOutput };
