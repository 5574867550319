import type { FormErrors } from "@mantine/form/lib/types";
import type { PropsWithChildren } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { UseFormReturnType } from "@ender/shared/forms/hooks/general";
import { FormProvider } from "@ender/shared/forms/hooks/general";

/** @deprecated This should not be used anymore. */
type BaseFormProps<T> = {
  onSubmit: (values: T) => void;
  onValidationFailure?: (errors: FormErrors) => void;
};

/** @deprecated This should not be used anymore. */
type UnmanagedFormProps<T> = BaseFormProps<T> & {
  /** @description Determine whether or not the form inputs will allow the browser to use autocomplete suggestions */
  noAutoComplete?: boolean;

  form: UseFormReturnType<T>;
};

/** @deprecated This should not be used anymore. */
function UnmanagedForm<T>({
  children,
  form,
  noAutoComplete = false,
  onSubmit,
  onValidationFailure,
}: PropsWithChildren<UnmanagedFormProps<T>>) {
  return (
    <FormProvider<T> form={form}>
      <form
        onSubmit={form.onSubmit(onSubmit, onValidationFailure)}
        autoComplete={noAutoComplete ? "off" : UNDEFINED}
        noValidate>
        {children}
      </form>
    </FormProvider>
  );
}

export { UnmanagedForm };
export type { BaseFormProps, UnmanagedFormProps };
