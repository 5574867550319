import { useQuery } from "@tanstack/react-query";
import { Array as A, Option as O, pipe } from "effect";
import { useCallback, useMemo, useState } from "react";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { DateInput } from "@ender/shared/ds/date-input";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { Select } from "@ender/shared/ds/select";
import { TextInput } from "@ender/shared/ds/text-input";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import { cast } from "@ender/shared/types/cast";
import type { FactorOutputType } from "@ender/shared/types/custom-factors";
import { FactorOutputTypeEnum } from "@ender/shared/types/custom-factors";
import type { EnderDate } from "@ender/shared/utils/ender-date";
import { isNaN } from "@ender/shared/utils/is";
import { convertToNumber } from "@ender/shared/utils/string";

type FactorFieldInputOnChange = (props: {
  factorId: EnderId;
  value:
    | string
    | number
    | Money$.Money
    | EnderDate
    | boolean
    | undefined
    | null;
}) => void;

type FactorFieldInputProps = {
  disabled?: boolean;
  factorId: EnderId;
  name?: string;
  label?: string;
  onChange: FactorFieldInputOnChange;
  outputType: FactorOutputType;
  value:
    | string
    | number
    | Money$.Money
    | EnderDate
    | boolean
    | undefined
    | null;
};

function FactorFieldEnumInput({
  factorId,
  name,
  label,
  onChange,
  value,
  outputType,
  disabled,
}: FactorFieldInputProps) {
  const { data: enumValues = [] } = useQuery<string[], unknown>({
    enabled: outputType === FactorOutputTypeEnum.ENUM,
    queryFn: ({ signal }) =>
      FactorsAPI.getPossibleEnumValues(
        { customFactorId: factorId },
        { signal },
      ),
    queryKey: ["FactorsAPI.getPossibleEnumValues", factorId] as const,
  });

  const data = useMemo(
    () =>
      A.isEmptyArray(enumValues)
        ? [{ label: "No Default Set", value: "" }]
        : enumValues.map((val) => ({ label: val, value: val })),
    [enumValues],
  );

  return (
    <Select
      label={label}
      value={O.fromNullable(value).pipe(O.map((val) => val.toString()))}
      name={name}
      onChange={(val) =>
        onChange({ factorId: factorId, value: O.getOrThrow(val) })
      }
      data={data}
      disabled={disabled}
    />
  );
}

function FactoFieldTextInput({
  factorId,
  name,
  label,
  onChange,
  value,
  outputType,
  disabled,
}: FactorFieldInputProps) {
  const [text, setText] = useState<string>(cast(value));

  const onDebounceChange = useDebounce(onChange, 500);

  const onTextChange = useCallback(
    (val: string) => {
      setText(val);
      if (val !== value) {
        onDebounceChange({ factorId: factorId, value: val });
      }
    },
    [factorId, onDebounceChange, setText, value],
  );

  if (outputType !== FactorOutputTypeEnum.STRING) {
    return NULL;
  }

  return (
    <TextInput
      name={name}
      label={label}
      //preserving this field so we know once factor fields get a dedicated DS rework
      // inputType="text"
      placeholder="Enter text..."
      autoComplete="off"
      value={text}
      onChange={onTextChange}
      disabled={disabled}
    />
  );
}

/**
 * @name FactorFieldInput
 * @description The field for the custom factor
 */
function FactorFieldInput({
  disabled = false,
  factorId,
  name = "value",
  label,
  onChange,
  outputType,
  value,
}: FactorFieldInputProps) {
  switch (outputType) {
    case FactorOutputTypeEnum.STRING: {
      return (
        <FactoFieldTextInput
          factorId={factorId}
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          outputType={outputType}
          disabled={disabled}
        />
      );
    }
    case FactorOutputTypeEnum.MONEY: {
      return (
        <MoneyInput
          label={label}
          name={name}
          value={Money$.parse(value as Money$.Money)}
          onChange={(val) =>
            onChange({ factorId: factorId, value: pipe(val, O.getOrNull) })
          }
          disabled={disabled}
        />
      );
    }
    case FactorOutputTypeEnum.NUMBER: {
      return (
        <TextInput
          label={label}
          name={name}
          onChange={(e) => {
            if (isNaN(convertToNumber(e)) && e !== "") {
              return;
            }

            onChange({ factorId: factorId, value: e });
          }}
          disabled={disabled}
          value={cast(value)}
        />
      );
    }
    case FactorOutputTypeEnum.DATE: {
      return (
        <DateInput
          label={label}
          name={name}
          value={LocalDate$.parse(value as string)}
          onChange={(val) =>
            onChange({
              factorId: factorId,
              value: val.pipe(
                O.map((date) => date.toJSON()),
                O.getOrUndefined,
              ),
            })
          }
          disabled={disabled}
        />
      );
    }
    case FactorOutputTypeEnum.BOOLEAN: {
      return (
        <Select
          label={label}
          value={O.fromNullable(value).pipe(O.map((val) => val.toString()))}
          onChange={(val) =>
            onChange({ factorId: factorId, value: O.getOrThrow(val) })
          }
          data={[
            { label: "No Default Set", value: "" },
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
          disabled={disabled}
        />
      );
    }
    case FactorOutputTypeEnum.ENUM: {
      return (
        <FactorFieldEnumInput
          label={label}
          factorId={factorId}
          name={name}
          onChange={onChange}
          value={value}
          outputType={outputType}
          disabled={disabled}
        />
      );
    }
    default: {
      const _exhaustiveCheck: never = outputType;
      return _exhaustiveCheck;
    }
  }
}

export { FactorFieldInput };
export type { FactorFieldInputOnChange, FactorFieldInputProps };
