import { useMutation } from "@tanstack/react-query";
import type { ElementRef } from "react";
import { forwardRef, useCallback } from "react";

import type { EnderId } from "@ender/shared/core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import type { ApplicantReportCardProps } from "./widgets-application-applicant-report-card";
import { ApplicantReportCard } from "./widgets-application-applicant-report-card";

type ConnectedApplicantReportCardProps = Pick<
  ApplicantReportCardProps,
  "application" | "isLoading" | "canScreen" | "onViewReport" | "header"
> & {
  applicationGroupId: EnderId;
  onSuccess: () => void;
};

const ConnectedApplicantReportCard = forwardRef<
  ElementRef<typeof ApplicantReportCard>,
  ConnectedApplicantReportCardProps
>(function ConnectedApplicantReportCard(props, ref) {
  const {
    application,
    applicationGroupId,
    onSuccess,
    isLoading,
    canScreen,
    onViewReport,
    header,
  } = props;
  const { applicant, applicationId } = application;

  const { mutateAsync: screenApplicant, isLoading: isIndividualScreening } =
    useMutation({
      mutationFn: ApplicationsAPI.screenApplicant,
      mutationKey: ["ApplicationsAPI.screenApplicant"] as const,
    });
  const { mutateAsync: reverifyIdentity, isLoading: isSendingReverification } =
    useMutation({
      mutationFn: ApplicationsAPI.reverifyIdentity,
      mutationKey: ["ApplicationsAPI.reverifyIdentity"] as const,
    });
  const handleScreenApplicant = useCallback(async () => {
    const name = `${applicant.firstName} ${applicant.lastName}`.trim();
    await screenApplicant({ appGroupId: applicationGroupId, applicationId });
    showSuccessNotification({ message: `Reports run for ${name}.` });
    onSuccess();
  }, [
    screenApplicant,
    applicant,
    applicationGroupId,
    applicationId,
    onSuccess,
  ]);

  const handleResendIdv = useCallback(async () => {
    await reverifyIdentity({ appGroupId: applicationGroupId, applicationId });
    showSuccessNotification({ message: `Re-verification request sent.` });
    onSuccess();
  }, [applicationGroupId, applicationId, onSuccess, reverifyIdentity]);

  return (
    <ApplicantReportCard
      application={application}
      onScreenApplicant={handleScreenApplicant}
      onResendIDV={handleResendIdv}
      isLoading={isLoading || isIndividualScreening || isSendingReverification}
      canScreen={canScreen}
      onViewReport={onViewReport}
      ref={ref}
      header={header}
    />
  );
});

export { ConnectedApplicantReportCard };

export type { ConnectedApplicantReportCardProps };
