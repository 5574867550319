import { IconPhone } from "@tabler/icons-react";
import type { E164Number } from "libphonenumber-js";
import { forwardRef, useCallback } from "react";
import ReactPhoneNumberInput from "react-phone-number-input/input";

import type { Undefined } from "@ender/shared/constants/general";
import { ReactPhoneInputMetadata } from "@ender/shared/utils/phone";

import type { InputBaseProps } from "../../../input/src";
import { Input } from "../../../input/src";

type PhoneInputProps = {
  value: string;
  onChange: (value: string) => void;
} & Omit<InputBaseProps, "leftSection" | "textAlign" | "role" | "inputType">;

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  function PhoneInput(props, ref) {
    const {
      label,
      description,
      error,
      placeholder,
      value,
      onChange,
      autoComplete,
      borderless,
      size,
      rightSection,
      disabled,
      name,
      "data-private": _dataPrivate,
    } = props;

    const handleInput = useCallback(
      (val: E164Number | Undefined) => {
        onChange(val ?? "");
      },
      [onChange],
    );

    return (
      <ReactPhoneNumberInput
        // @ts-expect-error Input is a valid component for the inputComponent prop
        inputComponent={Input}
        leftSection={<IconPhone />}
        // countrySelectProps={{ unicodeFlags: true }}
        placeholder={placeholder}
        defaultCountry="US"
        metadata={ReactPhoneInputMetadata}
        value={value}
        label={label}
        error={error}
        description={description}
        borderless={borderless}
        size={size}
        rightSection={rightSection}
        disabled={disabled}
        onChange={handleInput}
        ref={ref}
        data-private={_dataPrivate}
        name={name}
        autoComplete={autoComplete}
      />
    );
  },
);

export { PhoneInput };

export type { PhoneInputProps };
