import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Option as O } from "effect";
import { useCallback, useMemo } from "react";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId, Money } from "@ender/shared/core";
import { Money$, randomEnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { MoneyInput } from "@ender/shared/ds/money-input";
import type { SelectOption } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { LeasingMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import { GeneralLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import { ChargeSchedulesAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetRenewalsRequestRenewalTableTab } from "@ender/shared/generated/ender.api.leasing.request";
import { GetRenewalsRequestRenewalTableTabEnum } from "@ender/shared/generated/ender.api.leasing.request";
import type {
  ChargeSchedule,
  ChargeScheduleFrequency,
} from "@ender/shared/generated/ender.model.payments";
import {
  ChargeScheduleFrequencyEnum,
  ChargeScheduleFrequencyValues,
} from "@ender/shared/generated/ender.model.payments";
import { useDebounceState } from "@ender/shared/hooks/use-debounce";
import { useListState } from "@ender/shared/hooks/use-list-state";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { Size } from "@ender/shared/utils/theming";

import type { RenewalPackageType } from "./renewals.types";

const ChargeScheduleFrequencyOptions: SelectOption<ChargeScheduleFrequency>[] =
  ChargeScheduleFrequencyValues.map((value) => ({
    key: value,
    label: convertSnakeCaseToTitleCase(value),
    value,
  }));

type AdditionalChargeProps = {
  categoryOptions: SelectOption<EnderId>[];
  charge?: ChargeSchedule;
  onCreate?: (categoryId: EnderId) => void;
  onUpdate?: (charge: {
    chargeScheduleId: EnderId;
    amount?: Money;
    frequency?: ChargeScheduleFrequency;
  }) => void;
  onDelete?: (chargeScheduleId: EnderId) => void;
  isLoading?: boolean;
};

function AdditionalCharge(props: AdditionalChargeProps) {
  const {
    categoryOptions,
    charge: maybeCharge,
    onCreate,
    onDelete,
    onUpdate,
    isLoading,
  } = props;
  const charge = O.fromNullable(maybeCharge);

  const onChargeUpdate = useCallback(
    (fields: { amount?: Money; frequency?: ChargeScheduleFrequency }) =>
      O.map(charge, (charge) =>
        onUpdate?.({ chargeScheduleId: charge.id, ...charge, ...fields }),
      ),
    [charge, onUpdate],
  );

  const onChargeDelete = useCallback(
    () =>
      charge.pipe(
        O.map((charge) => charge.id),
        O.getOrElse(randomEnderId),
        (chargeId) => onDelete?.(chargeId),
      ),
    [charge, onDelete],
  );

  const onCategorySelect = useCallback(
    (categoryId: O.Option<EnderId>) =>
      O.map(categoryId, (categoryId) => onCreate?.(categoryId)),
    [onCreate],
  );

  const onFrequencySelect = useCallback(
    (frequency: O.Option<ChargeScheduleFrequency>) =>
      O.map(frequency, (frequency) => onChargeUpdate?.({ frequency })),
    [onChargeUpdate],
  );

  const [amount, onAmountChange] = useDebounceState<O.Option<Money$.Money>>({
    initialValue: O.map(charge, (charge) => Money$.of(charge.amount)),
    onDebounceValueChange: (
      value: O.Option<Money$.Money>,
      previousValue: O.Option<Money$.Money>,
    ) => {
      if (!O.getEquivalence(Money$.Equivalence)(value, previousValue)) {
        O.map(value, (amount) => onChargeUpdate({ amount: amount.toJSON() }));
      }
    },
  });

  const categoryName = charge.pipe(
    O.flatMap((charge) =>
      A.findFirst(
        categoryOptions,
        (category) => category.value === charge.chargeTypeId,
      ),
    ),
    O.map((category) => category.label),
    O.getOrElse(() => ""),
  );

  return (
    <Card>
      <Stack>
        <Group justify={Justify.between} align={Align.center} noWrap>
          {O.isNone(charge) ? (
            <Select
              placeholder="Category"
              data={categoryOptions}
              value={O.none()}
              onChange={onCategorySelect}
              disabled={isLoading}
            />
          ) : (
            <H3>{categoryName}</H3>
          )}
          <Button
            variant={ButtonVariant.transparent}
            leftSection={<IconTrash />}
            onClick={onChargeDelete}
            disabled={isLoading}>
            Delete
          </Button>
        </Group>
        <MoneyInput
          label="Amount"
          disabled={O.isNone(charge) || isLoading}
          value={amount}
          onChange={onAmountChange}
        />
        <Select
          label="Frequency"
          disabled={O.isNone(charge) || isLoading}
          data={ChargeScheduleFrequencyOptions}
          value={O.map(charge, (charge) => charge.frequency)}
          onChange={onFrequencySelect}
        />
      </Stack>
    </Card>
  );
}

type AdditionalChargesProps = {
  renewalPackage: RenewalPackageType;
  leaseId: EnderId;
};

const AdditionalChargesStatus = new Set<GetRenewalsRequestRenewalTableTab>([
  GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS,
  GetRenewalsRequestRenewalTableTabEnum.AWAITING_RESPONSE,
  GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING,
  GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED,
]);

function AdditionalCharges(props: AdditionalChargesProps) {
  const { renewalPackage, leaseId } = props;
  const [newCharges, newChargesHandlers] = useListState<EnderId>([]);

  // need to refetch on renewal package status changes because it adds new charges on the BE
  const {
    data: renewalChargeSchedules = [],
    refetch,
    isFetching,
  } = useQuery({
    queryFn: () => LeasingMiddleLayerAPI.getRenewalChargeSchedules({ leaseId }),
    queryKey: [
      "LeasingMiddleLayerAPI.getRenewalChargeSchedules",
      leaseId,
      renewalPackage.renewalStatus,
    ],
  });

  const { data: chargeScheduleCategories = [] } = useQuery({
    queryFn: ({ signal }) =>
      GeneralLedgerAPI.getChargeScheduleCategories({ keyword: "" }, { signal }),
    queryKey: ["GeneralLedgerAPI.getChargeScheduleCategories"],
  });

  const { mutateAsync: createRenewalChargeSchedule, isLoading: isCreating } =
    useMutation({
      mutationFn: (charge: {
        amount: Money;
        chargeTypeId: EnderId;
        frequency: ChargeScheduleFrequency;
      }) =>
        ChargeSchedulesAPI.createRenewalChargeSchedule({ leaseId, ...charge }),
      mutationKey: ["ChargeSchedulesAPI.createRenewalChargeSchedule", leaseId],
    });

  const { mutateAsync: updateRenewalChargeSchedule, isLoading: isUpdating } =
    useMutation({
      mutationFn: ChargeSchedulesAPI.updateRenewalChargeSchedule,
      mutationKey: ["ChargeSchedulesAPI.updateRenewalChargeSchedule"],
    });

  const { mutateAsync: deleteChargeSchedule, isLoading: isDeleting } =
    useMutation({
      mutationFn: ChargeSchedulesAPI.deleteChargeSchedule,
      mutationKey: ["ChargeSchedulesAPI.deleteChargeSchedule"],
    });

  const categoryOptions = useMemo(
    () =>
      chargeScheduleCategories.map((category) => ({
        key: category.id,
        label: category.name,
        value: category.id,
      })),
    [chargeScheduleCategories],
  );

  if (!AdditionalChargesStatus.has(renewalPackage.renewalStatus)) {
    return NULL;
  }

  const isLoading = isFetching || isCreating || isUpdating || isDeleting;

  return (
    <Stack>
      <H3>Additional Charges</H3>
      {(A.isNonEmptyArray(renewalChargeSchedules ?? []) ||
        A.isNonEmptyArray(newCharges ?? [])) && (
        <Grid columnSize={Size.lg} spacingX={Spacing.md} spacingY={Spacing.md}>
          {renewalChargeSchedules.map((charge) => (
            <AdditionalCharge
              key={charge.id}
              categoryOptions={categoryOptions}
              charge={charge}
              onUpdate={(charge) =>
                updateRenewalChargeSchedule(charge).then(() => {
                  refetch();
                  showSuccessNotification({
                    autoClose: 2000,
                    message: "Charge updated",
                  });
                })
              }
              onDelete={(chargeScheduleId) =>
                deleteChargeSchedule({ chargeScheduleId }).then(() => refetch())
              }
              isLoading={isLoading}
            />
          ))}
          {newCharges.map((charge, index) => (
            <AdditionalCharge
              key={charge}
              categoryOptions={categoryOptions}
              onCreate={(categoryId) =>
                createRenewalChargeSchedule({
                  amount: Money$.of(100).toJSON(),
                  chargeTypeId: categoryId,
                  frequency: ChargeScheduleFrequencyEnum.MONTHLY,
                })
                  .then(() => refetch())
                  .then(() => newChargesHandlers.remove(index))
              }
              onDelete={() => newChargesHandlers.remove(index)}
              isLoading={isLoading}
            />
          ))}
        </Grid>
      )}
      <Group>
        <Button
          variant={ButtonVariant.transparent}
          leftSection={<IconPlus />}
          onClick={() => newChargesHandlers.append(randomEnderId())}>
          Add Charge
        </Button>
      </Group>
    </Stack>
  );
}

export { AdditionalCharges };
