import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Equivalence,
  Function as F,
  Option as O,
  Predicate as P,
  pipe,
} from "effect";
import { forwardRef, useCallback, useState } from "react";

import { SearchInput, hydrateApplication } from "@ender/entities/search-input";
import type { KeywordSearch } from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { H3 } from "@ender/shared/ds/heading";
import { FormRadioGroup } from "@ender/shared/ds/radio-group";
import type { SelectOption } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { ApplicationGroupApplicationStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import type {
  ApplicationApplicantType,
  ApplicationGroupApplicationStatus,
} from "@ender/shared/generated/ender.model.leasing";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { capitalize } from "@ender/shared/utils/string";

const searchableStatuses: Partial<ApplicationGroupApplicationStatus>[] = [
  ApplicationGroupApplicationStatusEnum.INITIAL_ACCEPTED,
  ApplicationGroupApplicationStatusEnum.IN_PROGRESS,
  ApplicationGroupApplicationStatusEnum.COMPLETE,
  ApplicationGroupApplicationStatusEnum.UNDER_REVIEW,
] as const;

const applicationSearch: KeywordSearch<EnderId> = (keyword: string) =>
  ApplicationsAPI.queryFilteredApplications({
    applicantNameOrPhone: keyword,
    limit: 25,
    marketIds: [],
    propertyIds: [],
    statuses: searchableStatuses,
    unitIds: [],
  }).then((res) => {
    return pipe(
      res,
      A.filter((item) => !item.archived),
      A.flatMap((item) =>
        item.applicants.map((applicant) => ({
          label: `${applicant.firstName} ${applicant.lastName}`,
          meta: { ...applicant, applicationGroupId: item.applicationGroupId },
          value: applicant.userId,
        })),
      ),
    );
  });

type OmnisearchApplicationResponse = {
  applicationGroupId: EnderId;
  name: string;
  propertyName: string;
  unitName: string;
  status: ApplicationGroupApplicationStatus;
  userId: EnderId;
  firstName: string;
  lastName: string;
  applicantType: ApplicationApplicantType;
  type: "APPLICATION";
  url: string;
};

const TransferApplicantFormSchema = Schema.Struct({
  sourceApplicationGroupId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  targetApplicationGroupId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Destination Application Group is required",
    }),
  ),
  applicationToMoveId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Choose an applicant to transfer",
    }),
  ),
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];

    if (
      O.getEquivalence(Equivalence.string)(
        values.sourceApplicationGroupId,
        values.targetApplicationGroupId,
      )
    ) {
      issues.push({
        message:
          "Cannot transfer to the application the applicant is already on",
        path: ["targetApplicationGroupId"],
      });
    }

    return issues;
  }),
);
type TransferApplicantFormOutput = Schema.Schema.Type<
  typeof TransferApplicantFormSchema
>;

type TransferApplicantFormProps = {
  /**
   * the application id of the selected applicant, if one was selected
   * to open this form
   */
  applicationId?: EnderId;
  /**
   * the applications shown by default to represent "this app group"
   * Optional because you can always find them via search
   */
  applications?: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "applicant" | "applicationId"
  >[];
  /**
   * the current application group
   */
  applicationGroupId: EnderId;
  onSuccess?: () => void;
};

const TransferApplicantForm = forwardRef<
  HTMLDivElement,
  TransferApplicantFormProps
>(function TransferApplicantForm(props, _ref) {
  const {
    applicationId,
    applications = [],
    applicationGroupId,
    onSuccess = F.constVoid,
  } = props;

  const form = useEffectSchemaForm({
    defaultValues: {
      sourceApplicationGroupId: O.fromNullable(applicationGroupId),
      targetApplicationGroupId: O.fromNullable(applicationGroupId),
      applicationToMoveId: O.fromNullable(applicationId),
    },
    schema: TransferApplicantFormSchema,
  });
  const { setValue, formState, clearErrors, watch } = form;
  const sourceApplicationGroupId = watch("sourceApplicationGroupId");

  const [fromApplication, setFromApplication] = useState<
    O.Option<SelectOption<EnderId, OmnisearchApplicationResponse>>
  >(O.none());
  const [toApplication, setToApplication] = useState<
    O.Option<SelectOption<EnderId, OmnisearchApplicationResponse>>
  >(O.none());

  const handleSelectOriginApplication = useCallback(
    (
      _v: unknown,
      option: O.Option<SelectOption<EnderId, OmnisearchApplicationResponse>>,
    ) => {
      /**
       * clear the selected applicant, we are now looking at a new group
       * and a new selection should be required
       */
      setValue("applicationToMoveId", O.none());
      setValue(
        "sourceApplicationGroupId",
        pipe(
          option,
          O.filterMap((v) => O.fromNullable(v.meta?.applicationGroupId)),
        ),
      );
      /**
       * reset destination back to this application group
       */
      setValue("targetApplicationGroupId", O.fromNullable(applicationGroupId));
      /**
       * clear any field errors as we are now in a new selection state
       */
      clearErrors(["applicationToMoveId", "targetApplicationGroupId"]);

      /**
       * synchronize the from and to Application objects
       */
      setFromApplication(option);
      setToApplication(O.none());
    },
    [setValue, clearErrors, applicationGroupId],
  );
  const hasValidOriginApplication = O.exists(fromApplication, (option) =>
    P.isNotNullable(option.meta?.applicationGroupId),
  );

  const {
    data: originApplicationApplicants = applications,
    isFetching: isFetchingOrigin,
  } = useQuery(
    ["ApplicationsAPI.getApplication", fromApplication] as const,
    () =>
      ApplicationsAPI.getApplication({
        appGroupId: pipe(
          fromApplication,
          O.map((v) => v.meta?.applicationGroupId),
          O.filter(P.isNotNullable),
          O.getOrThrow,
        ),
      }),
    { enabled: hasValidOriginApplication, select: (data) => data.applications },
  );

  const handleSelectDestinationApplication = useCallback(
    (
      _v: unknown,
      option: O.Option<SelectOption<EnderId, OmnisearchApplicationResponse>>,
    ) => {
      /**
       * if we were previously transferring in from a different application,
       * reset the source to this application and clear the selected applicant
       */
      if (!O.contains(sourceApplicationGroupId, applicationGroupId)) {
        setValue(
          "sourceApplicationGroupId",
          O.fromNullable(applicationGroupId),
        );
        setValue("applicationToMoveId", O.none());
      }
      setValue(
        "targetApplicationGroupId",
        O.filterMap(option, (v) => O.fromNullable(v.meta?.applicationGroupId)),
      );
      /**
       * synchronize the from and to Application objects
       */
      setFromApplication(O.none());
      setToApplication(option);
    },
    [sourceApplicationGroupId, setValue, applicationGroupId],
  );

  const hasValidDestinationApplication = O.exists(toApplication, (option) =>
    P.isNotNullable(option.meta?.applicationGroupId),
  );
  const {
    data: destinationApplicationDetails,
    isFetching: isFetchingDestination,
  } = useQuery(
    ["ApplicationsAPI.getApplication", toApplication] as const,
    () =>
      ApplicationsAPI.getApplication({
        appGroupId: pipe(
          toApplication,
          O.map((v) => v.meta?.applicationGroupId),
          O.filter(P.isNotNullable),
          O.getOrThrow,
        ),
      }),
    { enabled: hasValidDestinationApplication },
  );

  const { mutateAsync: transferApplicant, isLoading } = useMutation({
    mutationFn: ApplicationsAPI.moveApplication,
    mutationKey: ["ApplicationsAPI.moveApplication"] as const,
  });
  const handleSubmit = useCallback(
    async (values: TransferApplicantFormOutput) => {
      await transferApplicant({
        targetApplicationGroupId: pipe(
          values.targetApplicationGroupId,
          O.getOrThrow,
        ),
        applicationToMoveId: pipe(values.applicationToMoveId, O.getOrThrow),
      });
      showSuccessNotification({ message: "Applicant has been transferred" });
      onSuccess();
    },
    [transferApplicant, onSuccess],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <H3>From</H3>
        <SearchInput<EnderId>
          modelType={ModelTypeEnum.APPLICATION}
          label="Choose Application"
          value={pipe(
            fromApplication,
            O.map((option) => option.meta?.userId),
            O.filter(P.isNotNullable),
          )}
          //@ts-expect-error SearchInput is currently stupid about `meta`
          onChange={handleSelectOriginApplication}
          search={applicationSearch}
          hydrate={hydrateApplication}
          placeholder="Current Application"
          clearable
        />
        <Skeleton visible={isFetchingOrigin}>
          <Card>
            <Text>Applicant to Transfer</Text>
            <FormRadioGroup
              form={form}
              data={originApplicationApplicants.map((application) => ({
                value: application.applicationId,
                label: `${application.applicant.firstName} ${application.applicant.lastName}`,
              }))}
              name="applicationToMoveId"
            />
            {P.isNotNullable(formState.errors.applicationToMoveId) && (
              <Text color="red-500">
                {formState.errors.applicationToMoveId.message}
              </Text>
            )}
          </Card>
        </Skeleton>
        <H3>To</H3>
        <SearchInput<EnderId>
          modelType={ModelTypeEnum.APPLICATION}
          label="Choose Application"
          placeholder="Current Application"
          search={applicationSearch}
          hydrate={hydrateApplication}
          value={pipe(
            toApplication,
            O.map((option) => option.meta?.userId),
            O.filter(P.isNotNullable),
          )}
          //@ts-expect-error SearchInput is currently stupid about `meta`
          onChange={handleSelectDestinationApplication}
          error={formState.errors.targetApplicationGroupId?.message}
          clearable
        />
        {hasValidDestinationApplication && (
          <Skeleton visible={isFetchingDestination}>
            <Card>
              <Text>Destination Application Group</Text>
              <Stack spacing={Spacing.none}>
                {destinationApplicationDetails?.applications.map(
                  (application) => (
                    <Tuple
                      key={application.applicationId}
                      label={`${application.applicant.firstName} ${application.applicant.lastName}`}
                      value={capitalize(application.applicantType)}
                    />
                  ),
                )}
              </Stack>
            </Card>
          </Skeleton>
        )}
        <Button type="submit" loading={isLoading}>
          Transfer
        </Button>
      </Stack>
    </Form>
  );
});

export { TransferApplicantForm };

export type { TransferApplicantFormProps };
