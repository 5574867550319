/**
 * HTTP Method: POST
 * Pathname: /announcements/sendToTenants
 * @function sendPropertyAnnouncement
 * @memberof AnnouncementAPI
 * @param {AnnouncementAPISendPropertyAnnouncementPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AnnouncementAPISendPropertyAnnouncementSearchParams = {
  token?: string | undefined;
};

type AnnouncementAPISendPropertyAnnouncementBodyParams = {
  body?: string | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  files: any[];
  propertyIds: EnderId[];
  subject?: string | undefined;
  templateId?: EnderId | undefined;
  useNoReplyEmail?: boolean | undefined;
  useNoReplyPhone?: boolean | undefined;
};

type AnnouncementAPISendPropertyAnnouncementPayload =
  AnnouncementAPISendPropertyAnnouncementSearchParams &
    AnnouncementAPISendPropertyAnnouncementBodyParams;

function sendPropertyAnnouncementUnsafeEffect(
  payload: AnnouncementAPISendPropertyAnnouncementPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/announcements/sendToTenants",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendPropertyAnnouncement(
  payload: AnnouncementAPISendPropertyAnnouncementPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendPropertyAnnouncementUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendPropertyAnnouncement };
export type {
  AnnouncementAPISendPropertyAnnouncementBodyParams,
  AnnouncementAPISendPropertyAnnouncementPayload,
  AnnouncementAPISendPropertyAnnouncementSearchParams,
};
