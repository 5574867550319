import { useQuery } from "@tanstack/react-query";
import { Option as O } from "effect";
import { forwardRef } from "react";

import type { EnderId } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import { PayableCategorizationMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type { PayableCategory } from "@ender/shared/generated/ender.model.accounting";

import { PurePayableCategorySelect } from "./pure-payable-category-select";

type PayableCategorySelectProps = {
  disabled: boolean;
  glCategoryId: O.Option<EnderId>;
  onChange: (payableCategoryId: O.Option<EnderId>) => void;
  value: O.Option<EnderId>;
};

const PayableCategorySelect = forwardRef<
  HTMLInputElement,
  PayableCategorySelectProps
>(function PayableCategorySelect(props, ref) {
  const { disabled, glCategoryId, onChange, value } = props;

  const { data: payableCategories = [] } = useQuery({
    queryFn: ({ queryKey: [, params], signal }) =>
      PayableCategorizationMiddleLayerAPI.getCategoryNamesAndSubCategoryNames(
        params,
        { signal },
      ),
    queryKey: [
      "getCategoryNamesAndSubCategoryNames",
      { glCategoryId: O.getOrUndefined(glCategoryId) },
    ] as const,
    select: (data): SelectOption<string, PayableCategory[]>[] => {
      return Object.entries(data).map(([key, value]) => ({
        label: key,
        meta: value,
        value: key,
      }));
    },
  });

  return (
    <PurePayableCategorySelect
      ref={ref}
      disabled={disabled}
      onChangePayableCategoryId={onChange}
      payableCategoryId={value}
      payableCategories={payableCategories}
    />
  );
});

export { PayableCategorySelect };
export type { PayableCategorySelectProps };
