import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SearchApplicationsPageRequestApplicationsPageTabValues = [
  "ALL",
  "IN_PROGRESS",
  "SUBMITTED",
  "ALL_SUBMITTED",
  "READY_FOR_REVIEW",
  "UNDER_REVIEW",
  "REASSIGN_PROPERTY",
  "FINAL_REVIEW",
  "ACCEPTED",
  "DENIED",
  "ARCHIVED",
] as const;

const SearchApplicationsPageRequestApplicationsPageTabEffectSchema =
  Schema.Literal(...SearchApplicationsPageRequestApplicationsPageTabValues);

type SearchApplicationsPageRequestApplicationsPageTab = Schema.Schema.Type<
  typeof SearchApplicationsPageRequestApplicationsPageTabEffectSchema
>;

const SearchApplicationsPageRequestApplicationsPageTabEnum = castEnum(
  SearchApplicationsPageRequestApplicationsPageTabEffectSchema,
);

function randomSearchApplicationsPageRequestApplicationsPageTab(): SearchApplicationsPageRequestApplicationsPageTab {
  return rand(SearchApplicationsPageRequestApplicationsPageTabValues);
}

export {
  randomSearchApplicationsPageRequestApplicationsPageTab,
  SearchApplicationsPageRequestApplicationsPageTabEffectSchema,
  SearchApplicationsPageRequestApplicationsPageTabEnum,
  SearchApplicationsPageRequestApplicationsPageTabValues,
};
export type { SearchApplicationsPageRequestApplicationsPageTab };
