import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const VendorMarketMappingRmisComplianceStatusValues = [
  "PENDING_SYNCHRONIZATION",
  "COMPLIANT",
  "NON_COMPLIANT",
] as const;

const VendorMarketMappingRmisComplianceStatusEffectSchema = Schema.Literal(
  ...VendorMarketMappingRmisComplianceStatusValues,
);

type VendorMarketMappingRmisComplianceStatus = Schema.Schema.Type<
  typeof VendorMarketMappingRmisComplianceStatusEffectSchema
>;

const VendorMarketMappingRmisComplianceStatusEnum = castEnum(
  VendorMarketMappingRmisComplianceStatusEffectSchema,
);

function randomVendorMarketMappingRmisComplianceStatus(): VendorMarketMappingRmisComplianceStatus {
  return rand(VendorMarketMappingRmisComplianceStatusValues);
}

export {
  randomVendorMarketMappingRmisComplianceStatus,
  VendorMarketMappingRmisComplianceStatusEffectSchema,
  VendorMarketMappingRmisComplianceStatusEnum,
  VendorMarketMappingRmisComplianceStatusValues,
};
export type { VendorMarketMappingRmisComplianceStatus };
