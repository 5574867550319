import type { Justifications, Spacings } from "@ender/shared/ds/flex";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import type { FontSizes, FontWeights } from "@ender/shared/ds/text";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";

type LoadingIndicatorProps = {
  message: string;
  justify?: Justifications;
  spacing?: Spacings;
  weight?: FontWeights;
  size?: FontSizes;
};

function LoadingIndicator({
  message,
  justify = Justify.center,
  spacing = Spacing.sm,
  weight = FontWeight.semibold,
  size = FontSize.lg,
}: LoadingIndicatorProps) {
  return (
    <Group justify={justify} spacing={spacing}>
      <LoadingSpinner />
      <Text weight={weight} size={size}>
        {message}
      </Text>
    </Group>
  );
}

export { LoadingIndicator };
