/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/approval
 * @function applicationGroupApproval
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIApplicationGroupApprovalPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIApplicationGroupApprovalPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIApplicationGroupApprovalSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIApplicationGroupApprovalBodyParams = {
  approvalStatus: ApprovableApprovalStatus;
};

type ApplicationsAPIApplicationGroupApprovalPayload =
  ApplicationsAPIApplicationGroupApprovalPathParams &
    ApplicationsAPIApplicationGroupApprovalSearchParams &
    ApplicationsAPIApplicationGroupApprovalBodyParams;

function applicationGroupApprovalUnsafeEffect(
  payload: ApplicationsAPIApplicationGroupApprovalPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/approval`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function applicationGroupApproval(
  payload: ApplicationsAPIApplicationGroupApprovalPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    applicationGroupApprovalUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { applicationGroupApproval };
export type {
  ApplicationsAPIApplicationGroupApprovalBodyParams,
  ApplicationsAPIApplicationGroupApprovalPathParams,
  ApplicationsAPIApplicationGroupApprovalPayload,
  ApplicationsAPIApplicationGroupApprovalSearchParams,
};
