import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitZoneApplianceFinishTypeValues = [
  "WHITE",
  "BLACK",
  "STAINLESS_STEEL",
] as const;

const UnitZoneApplianceFinishTypeEffectSchema = Schema.Literal(
  ...UnitZoneApplianceFinishTypeValues,
);

type UnitZoneApplianceFinishType = Schema.Schema.Type<
  typeof UnitZoneApplianceFinishTypeEffectSchema
>;

const UnitZoneApplianceFinishTypeEnum = castEnum(
  UnitZoneApplianceFinishTypeEffectSchema,
);

function randomUnitZoneApplianceFinishType(): UnitZoneApplianceFinishType {
  return rand(UnitZoneApplianceFinishTypeValues);
}

export {
  randomUnitZoneApplianceFinishType,
  UnitZoneApplianceFinishTypeEffectSchema,
  UnitZoneApplianceFinishTypeEnum,
  UnitZoneApplianceFinishTypeValues,
};
export type { UnitZoneApplianceFinishType };
