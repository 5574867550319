import { Option as O } from "effect";
import * as A from "effect/Array";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { fail } from "@ender/shared/utils/error";
import { getNextAvailableCheckNumbers } from "@ender/shared/utils/invoice-utils";
import { isMultiple } from "@ender/shared/utils/is";

import { BankAccountDetails } from "./bank-account-details";
import { GenerateCheckFormSchema } from "./generate-check-form.schema";
import type { GenerateCheckFormProps } from "./types";
import { getBankAccountsList } from "./utils";

function GenerateCheckForm({
  amount,
  bankAccounts,
  handlePrintCheck,
  isProcessing,
  memo,
  operatingAccountId,
  recipientName,
  senderName,
}: GenerateCheckFormProps) {
  const initialBankAccountId = A.isNonEmptyArray(bankAccounts)
    ? bankAccounts[0].id
    : O.none();

  const form = useEffectSchemaForm({
    defaultValues: {
      amount: Money$.parse(amount),
      bankAccountId: operatingAccountId || initialBankAccountId,
      checkDate: LocalDate$.parse(LocalDate$.today()),
      checkNumber: "",
      memo,
      periodId: O.none(),
      recipientName,
      senderName,
    },
    schema: GenerateCheckFormSchema,
  });
  const bankAccountId = useWatch({
    control: form.control,
    name: "bankAccountId",
  });

  const selectedBankAccount = useMemo(
    () =>
      bankAccounts.find(
        (account) => account.id === O.getOrThrow(bankAccountId),
      ),
    [bankAccounts, bankAccountId],
  );

  const { setValue } = form;

  // Setting the next available check number based on the bank account
  useEffect(() => {
    if (selectedBankAccount) {
      getNextAvailableCheckNumbers(selectedBankAccount.id)
        .then(([nextAvailableCheckNumber]) => {
          setValue("checkNumber", nextAvailableCheckNumber.toString());
        })
        .catch(fail);
    }
  }, [selectedBankAccount, setValue]);

  const bankAccountsList = useMemo(
    () => getBankAccountsList(bankAccounts),
    [bankAccounts],
  );

  const bankAccountDetailsAmount = useWatch({
    control: form.control,
    name: "amount",
  });

  return (
    <Form form={form} onSubmit={handlePrintCheck}>
      <Stack spacing={Spacing.sm}>
        {isMultiple(bankAccounts) && (
          <FormSelect
            label="Bank Account"
            data={bankAccountsList}
            name="bankAccountId"
            form={form}
            clearable
          />
        )}
        <BankAccountDetails
          selectedBankAccount={selectedBankAccount}
          amount={bankAccountDetailsAmount || ""}
        />
        <FormTextInput
          label="Sender Name"
          name="senderName"
          form={form}
          disabled
        />
        <FormTextInput
          label="Recipient Name"
          name="recipientName"
          form={form}
          disabled
        />
        <FormDateInput label="Date" name="checkDate" form={form} />
        <FormAccountingPeriodSelector
          periodType={AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE}
          name="periodId"
          form={form}
          label="Accounting Period (optional)"
        />
        <FormTextInput label="Check Number" name="checkNumber" form={form} />
        <FormTextInput
          label="Memo (60 Character Limit)"
          name="memo"
          form={form}
        />
        <Group justify={Justify.end}>
          <Button
            disabledTooltip="Our system does not have a bank account or routing number for this property. Please contact Ender OPS to complete the setup for check printing."
            disabled={
              !selectedBankAccount?.hasAccountNumber ||
              !selectedBankAccount?.hasRoutingNumber
            }
            loading={isProcessing}
            type="submit">
            Generate Check
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { GenerateCheckForm };
