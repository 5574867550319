/**
 * HTTP Method: POST
 * Pathname: /moveInConfigSteps
 * @function addMoveInConfigStep
 * @memberof MoveInAPI
 * @param {MoveInAPIAddMoveInConfigStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoveInConfigRow>}
 */
import { Effect, Function as F } from "effect";

import type {
  MoveInConfigRow,
  MoveInConfigRowCapturedDataType,
} from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MoveInAPIAddMoveInConfigStepSearchParams = {
  token?: string | undefined;
};

type MoveInAPIAddMoveInConfigStepBodyParams = {
  capturedDataType: MoveInConfigRowCapturedDataType;
  description?: string | undefined;
  isRequired?: boolean | undefined;
  ordering?: number | undefined;
  title: string;
};

type MoveInAPIAddMoveInConfigStepPayload =
  MoveInAPIAddMoveInConfigStepSearchParams &
    MoveInAPIAddMoveInConfigStepBodyParams;

function addMoveInConfigStepUnsafeEffect(
  payload: MoveInAPIAddMoveInConfigStepPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoveInConfigRow>({
        body,
        decode: unsafeDecodeJsonResponse<MoveInConfigRow>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/moveInConfigSteps",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addMoveInConfigStep(
  payload: MoveInAPIAddMoveInConfigStepPayload,
  options?: { signal?: AbortSignal },
): Promise<MoveInConfigRow> {
  return F.pipe(
    payload,
    addMoveInConfigStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addMoveInConfigStep };
export type {
  MoveInAPIAddMoveInConfigStepBodyParams,
  MoveInAPIAddMoveInConfigStepPayload,
  MoveInAPIAddMoveInConfigStepSearchParams,
};
