import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { ColorSchema, SizeSchema } from "@ender/shared/utils/theming";

const BadgeSizeSchema = SizeSchema.pipe(Schema.pickLiteral("sm", "md"));
const BadgeSizeValues = BadgeSizeSchema.literals;
type BadgeSizes = Schema.Schema.Type<typeof BadgeSizeSchema>;
const BadgeSize = castEnum(BadgeSizeSchema);

const BadgeColorSchema = ColorSchema.pipe(
  Schema.pickLiteral("green", "yellow", "red", "slate"),
);
const BadgeColorValues = BadgeColorSchema.literals;
type BadgeColors = Schema.Schema.Type<typeof BadgeColorSchema>;
const BadgeColor = castEnum(BadgeColorSchema);

const BadgeVariantGenerator = cva(
  ["rounded-full inline-block", "font-medium text-center"],
  {
    defaultVariants: {
      color: BadgeColor.slate,
      dark: false,
      size: BadgeSize.md,
    },
    variants: {
      color: {
        [BadgeColor.green]: [
          "[--badge-bg-light:--color-green-100] [--badge-text-light:--color-green-700]",
          "[--badge-bg-dark:--color-green-500] [--badge-text-dark:white]",
        ],
        [BadgeColor.red]: [
          "[--badge-bg-light:--color-red-300] [--badge-text-light:white]",
          "[--badge-bg-dark:--color-red-500] [--badge-text-dark:white]",
        ],
        [BadgeColor.slate]: [
          "[--badge-bg-light:--color-slate-100] [--badge-text-light:--color-slate-700]",
          "[--badge-bg-dark:--color-slate-500] [--badge-text-dark:white]",
        ],
        [BadgeColor.yellow]: [
          "[--badge-bg-light:--color-yellow-100] [--badge-text-light:--color-yellow-700]",
          "[--badge-bg-dark:--color-yellow-500] [--badge-text-dark:white]",
        ],
      },
      dark: {
        false: "bg-[--badge-bg-light] text-[--badge-text-light]",
        true: "bg-[--badge-bg-dark] text-[--badge-text-dark]",
      },
      size: {
        [BadgeSize.sm]: "py-0.5 min-w-5 px-1.5 text-xxs",
        [BadgeSize.md]: "py-1 min-w-6 px-2 text-xs",
      },
    },
  },
);

type BadgeProps = {
  color?: BadgeColors;
  size?: BadgeSizes;
  dark?: boolean;
};

const Badge = forwardRef<HTMLDivElement, PropsWithChildren<BadgeProps>>(
  function Badge(props, ref) {
    const { children } = props;
    return (
      <div role="status" className={BadgeVariantGenerator(props)} ref={ref}>
        {children}
      </div>
    );
  },
);

export {
  Badge,
  BadgeColor,
  BadgeColorSchema,
  BadgeColorValues,
  BadgeSize,
  BadgeSizeSchema,
  BadgeSizeValues,
};
export type { BadgeColors, BadgeProps, BadgeSizes };
