import { InvoiceFiltersStorePaymentTypeFilter } from "../../../../widgets/invoice-filters/invoice-filters-store-payment-type-filter";
import type { InvoiceTableTabs } from "../invoice-table-tabs.types";
import { ValidTabsForBestPaymentMethodType } from "../utils/invoices-data/get-common-invoices-payload";
import { ValidTabsForPaymentMethodType } from "../utils/invoices-data/get-guarded-payment-method-type";

const TabsWithValidUseCaseForPaymentTypeFilter = [
  ...new Set([
    ...ValidTabsForPaymentMethodType,
    ...ValidTabsForBestPaymentMethodType,
  ]),
];

function InvoiceTabDependentPaymentTypeFilter({
  tab,
}: {
  tab: InvoiceTableTabs;
}) {
  return (
    <InvoiceFiltersStorePaymentTypeFilter
      disabled={!TabsWithValidUseCaseForPaymentTypeFilter.includes(tab)}
    />
  );
}

export { InvoiceTabDependentPaymentTypeFilter };
