import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UserVendorUserOnboardingStatusValues = [
  "PENDING_ONBOARDING_MESSAGE",
  "PENDING_VERIFICATION",
  "FULLY_ONBOARDED",
] as const;

const UserVendorUserOnboardingStatusEffectSchema = Schema.Literal(
  ...UserVendorUserOnboardingStatusValues,
);

type UserVendorUserOnboardingStatus = Schema.Schema.Type<
  typeof UserVendorUserOnboardingStatusEffectSchema
>;

const UserVendorUserOnboardingStatusEnum = castEnum(
  UserVendorUserOnboardingStatusEffectSchema,
);

function randomUserVendorUserOnboardingStatus(): UserVendorUserOnboardingStatus {
  return rand(UserVendorUserOnboardingStatusValues);
}

export {
  randomUserVendorUserOnboardingStatus,
  UserVendorUserOnboardingStatusEffectSchema,
  UserVendorUserOnboardingStatusEnum,
  UserVendorUserOnboardingStatusValues,
};
export type { UserVendorUserOnboardingStatus };
