import { Array as A } from "effect";
import type { PropsWithChildren, ReactElement, ReactNode } from "react";
import { Children, Fragment, cloneElement } from "react";

/**
 * .breadcrumbs {
  color: var(--color-breadcrumbs);
  font-weight: 500;
  font-size: 16px;
}

.breadcrumb {
  line-height: 18px;
}

.breadcrumb:last-child:not(:first-child) {
  font-weight: 300;
}
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isElement(value: any): value is ReactElement {
  if (A.isArray(value) || value === null) {
    return false;
  }

  if (typeof value === "object") {
    if (value.type === Fragment) {
      return false;
    }

    return true;
  }

  return false;
}

type BreadcrumbsProps = {
  separator: ReactNode;
};

function Breadcrumbs(props: PropsWithChildren<BreadcrumbsProps>) {
  const { separator, children } = props;
  const items = Children.toArray(children).reduce<ReactNode[]>(
    (acc, child, index, array) => {
      const item = isElement(child) ? (
        cloneElement(child, {
          key: index,
        })
      ) : (
        <div key={index}>{child}</div>
      );

      acc.push(item);

      if (index !== array.length - 1) {
        acc.push(
          <div
            key={`separator-${index}`}
            className="w-8 flex justify-center items-center text-xs text-gray-700">
            {separator}
          </div>,
        );
      }

      return acc;
    },
    [],
  );
  return (
    <div className="self-center flex font-medium last:[&:not(:first-child)]:*:font-light">
      {items}
    </div>
  );
}

export { Breadcrumbs };
