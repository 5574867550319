import { useMutation } from "@tanstack/react-query";
import { isEmptyArray } from "effect/Array";
import { useContext, useState } from "react";

import { uploadFilesDirect } from "@ender/shared/api/files";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import type { ApplicantFileType } from "@ender/widgets/utils/application-page-right-rail";
import { ApplicantFileTypeEnum } from "@ender/widgets/utils/application-page-right-rail";

type UploadApplicantFilesProps = {
  applicationId: EnderId;
  onSuccess: () => void;
  type: ApplicantFileType;
};

function UploadApplicantFiles({
  applicationId,
  type,
  onSuccess,
}: UploadApplicantFilesProps) {
  const [files, setFiles] = useState<File[]>([]);
  const { user } = useContext(UserContext);

  const { mutate: uploadFiles, isLoading: isUploading } = useMutation({
    mutationFn: async () => {
      if (type === ApplicantFileTypeEnum.PROOF_OF_ID) {
        await uploadFilesDirect({
          files,
          modelId: applicationId,
          modelType: ModelTypeEnum.APPLICATION,
          subFolder: "PUBLIC",
          uploadType: WebserverFilesServiceFileUploadTypeEnum.PHOTO_ID,
          userId: user.id,
        });
      } else if (type === ApplicantFileTypeEnum.PROOF_OF_INCOME) {
        await uploadFilesDirect({
          files,
          modelId: applicationId,
          modelType: ModelTypeEnum.APPLICATION,
          subFolder: "PUBLIC",
          uploadType: WebserverFilesServiceFileUploadTypeEnum.PAYSTUB,
          userId: user.id,
        });
      }
    },
    onSuccess: () => {
      showSuccessNotification({
        message: `Proof of ${type === ApplicantFileTypeEnum.PROOF_OF_ID ? "Identification" : "Income"} uploaded.`,
      });
      onSuccess();
    },
  });

  return (
    <Stack>
      <FileInput onChange={setFiles} value={files} />

      <Group justify={Justify.end}>
        <Button
          disabled={isEmptyArray(files)}
          loading={isUploading}
          onClick={() => uploadFiles()}>
          Upload
        </Button>
      </Group>
    </Stack>
  );
}

export { UploadApplicantFiles };
