import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";

import { Form, useForm } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { Tooltip } from "@ender/shared/ds/tooltip";

const LogACallFormSchema = Schema.Struct({
  notes: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Notes required" }),
  ),
});

type LogACallFormValues = Schema.Schema.Type<typeof LogACallFormSchema>;

type LogACallFormProps = {
  submitDisabled?: boolean;
  isLoading?: boolean;
  handleFormSubmit: (values: LogACallFormValues) => void;
  onCancel: () => void;
};

function LogACallForm(props: LogACallFormProps) {
  const {
    handleFormSubmit,
    onCancel,
    isLoading = false,
    submitDisabled = false,
  } = props;
  const form = useForm<LogACallFormValues>({
    defaultValues: {
      notes: "",
    },
    mode: "onChange",
    resolver: effectTsResolver(LogACallFormSchema),
  });

  return (
    <Form form={form} onSubmit={handleFormSubmit}>
      <Stack spacing={Spacing.xl}>
        <Stack spacing={Spacing.sm}>
          <FormTextarea
            name="notes"
            form={form}
            label="Notes from Call"
            placeholder="Notes from Call"
          />
        </Stack>
        <Group justify={Justify.end}>
          <Button
            variant={ButtonVariant.outlined}
            type="button"
            onClick={onCancel}>
            Cancel
          </Button>
          <Tooltip
            disabled={!submitDisabled}
            label="You cannot log calls while masquerading.">
            <Button
              type="submit"
              disabled={!form.formState.isValid || submitDisabled}
              loading={isLoading}>
              Log Call
            </Button>
          </Tooltip>
        </Group>
      </Stack>
    </Form>
  );
}

export { LogACallForm, LogACallFormSchema };
export type { LogACallFormProps, LogACallFormValues };
