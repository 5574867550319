import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const PaymentStatusValues = ["PAID", "PENDING", "REJECTED", "VOIDED"] as const;
const PaymentStatusSchema = z.enum(PaymentStatusValues);
type PaymentStatus = z.infer<typeof PaymentStatusSchema>;

const PaymentStatusEnum = castEnum<PaymentStatus>(PaymentStatusSchema);

export { PaymentStatusEnum, PaymentStatusSchema, PaymentStatusValues };
export type { PaymentStatus };
