/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/startNextApplication
 * @function startNextApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIStartNextApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIStartNextApplicationPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIStartNextApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIStartNextApplicationBodyParams = {
  userId: EnderId;
};

type ApplicationsAPIStartNextApplicationPayload =
  ApplicationsAPIStartNextApplicationPathParams &
    ApplicationsAPIStartNextApplicationSearchParams &
    ApplicationsAPIStartNextApplicationBodyParams;

function startNextApplicationUnsafeEffect(
  payload: ApplicationsAPIStartNextApplicationPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/startNextApplication`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function startNextApplication(
  payload: ApplicationsAPIStartNextApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    startNextApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { startNextApplication };
export type {
  ApplicationsAPIStartNextApplicationBodyParams,
  ApplicationsAPIStartNextApplicationPathParams,
  ApplicationsAPIStartNextApplicationPayload,
  ApplicationsAPIStartNextApplicationSearchParams,
};
