import { useQuery } from "@tanstack/react-query";
import type { SortingState } from "@tanstack/react-table";
import { Option as O, Predicate as P, pipe } from "effect";
import { useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import type { CollectionsAPIGetLeasesInCollectionsPayload } from "@ender/shared/generated/ender.api.finance";
import { CollectionsAPI } from "@ender/shared/generated/ender.api.finance";
import type { CollectionsSortByCollectionsSortColumn } from "@ender/shared/generated/ender.api.finance.request";
import { LeaseIntentionEnum } from "@ender/shared/generated/ender.model.leasing";
import type { CollectionsStepCollectionsActionType } from "@ender/shared/generated/ender.model.misc";
import type { CollectionsReportCollectionsReportRow } from "@ender/shared/generated/ender.service.payments.collections";

import type {
  CollectionsQueryOptions,
  CollectionsTableRow,
  CollectionsType,
} from "../collections.types";
import {
  CollectionsTypeEnum,
  GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
} from "../collections.types";
import {
  COLLECTIONS_API_STALE_TIME,
  doesCollectionsTypeSupportActions,
} from "../collections.utils";
import { useCollectionsRoutes } from "./use-collections-routes";

function processGetCollectionsResponse(
  collections: CollectionsReportCollectionsReportRow[],
): CollectionsTableRow[] {
  return collections.map(
    ({
      nextStep,
      leaseId,
      tenantName,
      phone,
      propertyId,
      propertyName,
      lateFee,
      lateFeeEligible,
      lateFeeEligibleBalance,
      marketId,
      marketName,
      daysLate,
      totalBalance,
      totalRentBalance,
      unitName,
    }) => {
      return {
        lateFee: Money$.of(lateFee),
        lateFeeEligible: Money$.of(lateFeeEligible),
        lateFeeEligibleBalance: Money$.of(lateFeeEligibleBalance),
        leaseId,
        marketId,
        marketName,
        nextStep,
        numDaysRentLate: daysLate,
        phone,
        propertyId,
        propertyName,
        tenantName,
        totalBalance: Money$.of(totalBalance),
        totalRentBalance: Money$.of(totalRentBalance),
        unitName,
      };
    },
  );
}

/** @description Reusable options for all GetCollections hooks */
const baseUseGetCollectionsQueryOptions: CollectionsQueryOptions = {
  keepPreviousData: false,
  select: processGetCollectionsResponse,
  staleTime: COLLECTIONS_API_STALE_TIME,
};

const defaultCollectionsPayloadValues = {
  collectionActionTypes: [],
  filters: [],
  firmIds: [],
  fundIds: [],
  marketIds: [],
  propertyIds: [],
};

const allActiveLeasesCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
  {
    ...defaultCollectionsPayloadValues,
    intentions: [],
    movedOut: false,
  };

const stableCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload = {
  ...defaultCollectionsPayloadValues,
  hasPaymentPlan: false,
  intentions: [LeaseIntentionEnum.FRIENDLY, LeaseIntentionEnum.NO_RENEW],
  movedOut: false,
  onlyPaused: false,
  upcomingLeases: false,
};

const upcomingCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
  {
    ...defaultCollectionsPayloadValues,
    hasPaymentPlan: false,
    intentions: [LeaseIntentionEnum.FRIENDLY, LeaseIntentionEnum.NO_RENEW],
    movedOut: false,
    onlyPaused: false,
    upcomingLeases: true,
  };

const allCollectionsPayload = {
  ...defaultCollectionsPayloadValues,
  intentions: [],
};

const intendToEvictCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
  {
    ...defaultCollectionsPayloadValues,
    hasPaymentPlan: false,
    intentions: [LeaseIntentionEnum.INTEND_TO_EVICT],
    movedOut: false,
    onlyPaused: false,
  };

const evictedCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload = {
  ...defaultCollectionsPayloadValues,
  hasPaymentPlan: false,
  intentions: [LeaseIntentionEnum.EVICT],
  movedOut: false,
  onlyPaused: false,
};

const paymentPlansCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
  {
    ...defaultCollectionsPayloadValues,
    hasPaymentPlan: true,
    intentions: [],
    movedOut: false,
    onlyPaused: false,
  };

const movedOutCollectionsPayload = {
  ...defaultCollectionsPayloadValues,
  hasPaymentPlan: false,
  intentions: [],
  movedOut: true,
  onlyPaused: false,
};

const excludedCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
  {
    ...defaultCollectionsPayloadValues,
    hasPaymentPlan: false,
    intentions: [],
    movedOut: false,
    onlyPaused: true,
  };

const assistedRentDueCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
  {
    ...defaultCollectionsPayloadValues,
    hasPaymentPlan: false,
    hasOnlyUnpaidAssistedRentCharges: true,
    intentions: [],
    onlyPaused: false,
  };

const nonDelinquentCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
  {
    ...defaultCollectionsPayloadValues,
    hasPaymentPlan: false,
    intentions: [],
    movedOut: false,
    onlyNondelinquentLeases: true,
    onlyPaused: false,
  };

type UseCollectionsDataProps = {
  collectionActionTypes: CollectionsStepCollectionsActionType[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  isIncludeCompleteMoveOutAccountingEnabled: boolean;
  isIncludePendingMoveOutAccountingEnabled: boolean;
  marketIds: EnderId[];
  minimumCollectableAmount: O.Option<Money$.Money>;
  onlyShowActionsNeeded: boolean;
  propertyIds: EnderId[];
  residentNameFilter: string;
  tableSorting: SortingState;
};

function useCollectionsData({
  collectionActionTypes,
  firmIds,
  fundIds,
  isIncludeCompleteMoveOutAccountingEnabled,
  isIncludePendingMoveOutAccountingEnabled,
  marketIds,
  minimumCollectableAmount,
  onlyShowActionsNeeded,
  propertyIds,
  residentNameFilter,
  tableSorting,
}: UseCollectionsDataProps) {
  const { activeType } = useCollectionsRoutes();

  /** @description The payload of the currently active collections type */
  const activeTypeCollectionsPayload: CollectionsAPIGetLeasesInCollectionsPayload =
    useMemo(() => {
      const CollectionsPayloadMap: Record<
        CollectionsType,
        CollectionsAPIGetLeasesInCollectionsPayload
      > = {
        [CollectionsTypeEnum.ALL_ACTIVE_LEASES]:
          allActiveLeasesCollectionsPayload,
        [CollectionsTypeEnum.STABLE]: stableCollectionsPayload,
        [CollectionsTypeEnum.UPCOMING]: upcomingCollectionsPayload,
        [CollectionsTypeEnum.ALL]: allCollectionsPayload,
        [CollectionsTypeEnum.INTEND_TO_EVICT]: intendToEvictCollectionsPayload,
        [CollectionsTypeEnum.EVICTIONS]: evictedCollectionsPayload,
        [CollectionsTypeEnum.MOVED_OUT]: movedOutCollectionsPayload,
        [CollectionsTypeEnum.PAYMENT_PLANS]: paymentPlansCollectionsPayload,
        [CollectionsTypeEnum.EXCLUDED]: excludedCollectionsPayload,
        [CollectionsTypeEnum.ASSISTED_RENT_DUE]:
          assistedRentDueCollectionsPayload,
        [CollectionsTypeEnum.NON_DELINQUENT]: nonDelinquentCollectionsPayload,
      } as const;

      const sortState = tableSorting?.[0];

      return {
        ...CollectionsPayloadMap[activeType],
        collectionActionTypes,
        filters: [],
        firmIds,
        fundIds,
        marketIds,
        minimumCollectableAmount: pipe(
          minimumCollectableAmount,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
        propertyIds,
        tenantName: residentNameFilter || "",
        ...(doesCollectionsTypeSupportActions(activeType) &&
          onlyShowActionsNeeded && { onlyActionNeeded: true }),
        ...(activeType === CollectionsTypeEnum.MOVED_OUT && {
          showMoveoutAccountingComplete:
            isIncludeCompleteMoveOutAccountingEnabled,
          showPendingMoveoutAccounting:
            isIncludePendingMoveOutAccountingEnabled,
        }),
        ...(P.isNotNullable(sortState)
          ? {
              sortBy: {
                // Casting due to the table sorting not accepting a sort column type
                column: sortState.id as CollectionsSortByCollectionsSortColumn,
                descending: sortState.desc,
              },
            }
          : {}),
      };
    }, [
      activeType,
      collectionActionTypes,
      firmIds,
      fundIds,
      isIncludeCompleteMoveOutAccountingEnabled,
      isIncludePendingMoveOutAccountingEnabled,
      marketIds,
      minimumCollectableAmount,
      propertyIds,
      onlyShowActionsNeeded,
      residentNameFilter,
      tableSorting,
    ]);

  const {
    data = [],
    isError,
    isFetching,
    isLoading,
    refetch: refetchCollections,
  } = useQuery<
    CollectionsReportCollectionsReportRow[],
    unknown,
    CollectionsTableRow[],
    [string, CollectionsType, CollectionsAPIGetLeasesInCollectionsPayload]
  >({
    queryKey: [
      GET_LEASES_IN_COLLECTIONS_QUERY_KEY,
      activeType,
      activeTypeCollectionsPayload,
    ],
    queryFn: ({ queryKey: [, , params], signal }) => {
      return CollectionsAPI.getLeasesInCollections(params, { signal });
    },
    ...baseUseGetCollectionsQueryOptions,
  });

  return {
    activeTypeCollectionsPayload,
    data,
    isError,
    isFetching,
    isIncludeCompleteMoveOutAccountingEnabled,
    isIncludePendingMoveOutAccountingEnabled,
    isLoading,
    refetchCollections,
    stableCollectionsPayload,
  };
}

export { useCollectionsData };
