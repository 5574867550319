import type { ReactNode } from "react";

import type { LabelValue } from "@ender/shared/types/label-value";

import type { TupleProps } from "./shared-ds-tuple";
import { Tuple } from "./shared-ds-tuple";

type TupleListProps = {
  /**
   * Tuples are keyed by the entry label
   */
  //@ts-expect-error LabelValue does not like ReactNode
  entries: LabelValue<ReactNode>[];
} & Pick<TupleProps, "filled" | "borderless">;

/**
 * Tuples are mapped from the provided label/value data.
 * Display props of the tuples can be controlled with the `filled` and `borderless` props.
 */
function TupleList(props: TupleListProps) {
  const { entries, filled, borderless } = props;
  return (
    <>
      {entries.map(({ label, value }) => (
        <Tuple
          key={label}
          label={label}
          value={value}
          filled={filled}
          borderless={borderless}
        />
      ))}
    </>
  );
}

export { TupleList };

export type { TupleListProps };
