/**
 * Add a child to a GL category on the chart of accounts.
 * HTTP Method: POST
 * Pathname: /accounting/categories/{categoryId}/children
 * @function addChild
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIAddChildPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GLCategory>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GLCategory } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIAddChildPathParams = {
  categoryId: EnderId;
};

type GeneralLedgerAPIAddChildSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIAddChildBodyParams = {
  name: string;
};

type GeneralLedgerAPIAddChildPayload = GeneralLedgerAPIAddChildPathParams &
  GeneralLedgerAPIAddChildSearchParams &
  GeneralLedgerAPIAddChildBodyParams;

function addChildUnsafeEffect(payload: GeneralLedgerAPIAddChildPayload) {
  const { categoryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GLCategory>({
        body,
        decode: unsafeDecodeJsonResponse<GLCategory>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accounting/categories/${categoryId}/children`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addChild(
  payload: GeneralLedgerAPIAddChildPayload,
  options?: { signal?: AbortSignal },
): Promise<GLCategory> {
  return F.pipe(
    payload,
    addChildUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addChild };
export type {
  GeneralLedgerAPIAddChildBodyParams,
  GeneralLedgerAPIAddChildPathParams,
  GeneralLedgerAPIAddChildPayload,
  GeneralLedgerAPIAddChildSearchParams,
};
