import { IconInfoCircle } from "@tabler/icons-react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Switch } from "@ender/shared/ds/switch";
import { Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";

type AllFinanciallyResponsibleTenantsSwitchProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

function AllFinanciallyResponsibleTenantsSwitch({
  value,
  onChange,
}: AllFinanciallyResponsibleTenantsSwitchProps) {
  return (
    <Switch
      label={
        <Group spacing={Spacing.xs} align={Align.center}>
          <Text>Create a refund to all Financially Responsible Tenants</Text>
          <Tooltip label="All tenants marked Financially Responsible will be included as the recipient of this refund.">
            <IconInfoCircle
              color={EnderThemeColorEnum.PRIMARY}
              style={{ display: "inline", marginLeft: 5 }}
            />
          </Tooltip>
        </Group>
      }
      onChange={onChange}
      value={value}
    />
  );
}

export { AllFinanciallyResponsibleTenantsSwitch };
export type { AllFinanciallyResponsibleTenantsSwitchProps };
