/**
 * Gets custom reports (not built-in)
 * HTTP Method: POST
 * Pathname: /reports/search
 * @function searchReports
 * @memberof ReportsAPI
 * @param {ReportsAPISearchReportsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportSearchResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { ReportSearchResponse } from "@ender/shared/generated/ender.api.reports.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPISearchReportsSearchParams = {
  token?: string | undefined;
};

type ReportsAPISearchReportsPayload = ReportsAPISearchReportsSearchParams;

function searchReportsUnsafeEffect(payload: ReportsAPISearchReportsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportSearchResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<ReportSearchResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/reports/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchReports(
  payload: ReportsAPISearchReportsPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportSearchResponse[]> {
  return F.pipe(
    payload,
    searchReportsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchReports };
export type {
  ReportsAPISearchReportsPayload,
  ReportsAPISearchReportsSearchParams,
};
