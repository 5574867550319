import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P, pipe } from "effect";
import type { ReactNode } from "react";
import { useCallback, useContext } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextarea } from "@ender/shared/ds/textarea";
import type { UnderwritingAPIComputeUnderwritingPreviewPayload } from "@ender/shared/generated/com.ender.buy.api";
import {
  BuyAPI,
  UnderwritingAPI,
} from "@ender/shared/generated/com.ender.buy.api";
import type { UnderwritingModel } from "@ender/shared/generated/com.ender.buy.model.misc";
import { DealStatusPipelineStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";
import { BrokersServiceBrokerTypeEnum } from "@ender/shared/generated/com.ender.buy.service";
import { cast } from "@ender/shared/types/cast";
import { RightRail } from "@ender/shared/ui/right-rail";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertToNumber } from "@ender/shared/utils/string";

import { OfferTypeEnum } from "../../types";
import { UnderwritingPageContext } from "../underwriting-queue-context/underwriting-page-context";
import { GenericSubStatusEnum } from "../underwriting-queue.utils";

import styles from "./allocate-home-right-rail.module.css";

const ALLOCATABLE_STATUSES = [
  DealStatusPipelineStatusEnum.LEAD,
  DealStatusPipelineStatusEnum.OFFER,
] as const;

function OfferWrapper({
  offerValue,
  children,
}: {
  offerValue: O.Option<Money$.Money>;
  children: ReactNode;
}) {
  const { deal } = useContext(UnderwritingPageContext);

  function calculatePercentOfListPrice(offer: O.Option<Money$.Money>) {
    if (!P.isNotNullable(deal?.currentListPrice)) {
      return "";
    }

    const percent =
      pipe(offer, O.getOrElse(Money$.zero), Money$.toDollars) /
      convertToNumber(deal?.currentListPrice || "1");
    if (percent === 0) {
      return "";
    }

    return `${(percent * 100).toFixed(0)}%`;
  }

  const percent = calculatePercentOfListPrice(offerValue);
  return (
    <Stack spacing={Spacing.sm}>
      {children}
      {percent && (
        <div className={styles.percentOfListPrice}>{percent} of List Price</div>
      )}
    </Stack>
  );
}

const AllocateHomeRightRailFormSchema = Schema.Struct({
  brokerMaxOffer: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Broker Max Offer is required" }),
  ),
  comment: Schema.String,
  maxOffer: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  offerPrice: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Offer price is required" }),
  ),
}).pipe(
  Schema.filter(({ maxOffer, brokerMaxOffer, offerPrice }) => {
    if (O.isSome(maxOffer) && O.isSome(brokerMaxOffer)) {
      if (brokerMaxOffer.value > maxOffer.value) {
        return {
          message: "Broker Max Offer cannot be greater than Max Offer Price",
          path: ["brokerMaxOffer"],
        };
      }
    }
    if (O.isSome(brokerMaxOffer) && O.isSome(offerPrice)) {
      if (offerPrice.value > brokerMaxOffer.value) {
        return {
          message: "Offer Price cannot be greater than Broker Max Offer",
          path: ["offerPrice"],
        };
      }
    }
  }),
);

type AllocateHomeRightRailFormInput = Schema.Schema.Encoded<
  typeof AllocateHomeRightRailFormSchema
>;

type AllocateHomeRightRailProps = {
  onClose: () => void;
  opened: boolean;
  saveUnderwriting: (preview: UnderwritingModel) => Promise<void>;
};

function AllocateHomeRightRail({
  onClose,
  opened,
  saveUnderwriting,
}: AllocateHomeRightRailProps) {
  const {
    gotoNextUnderwriting,
    deal,
    dealId,
    underwritingPreview,
    updateUnderwritingPreview,
  } = useContext(UnderwritingPageContext);

  const status = deal?.status?.["pipelineStatus"]?.id;
  const isAllocatableStatus = ALLOCATABLE_STATUSES.some((s) => s === status);

  const form = useEffectSchemaForm({
    defaultValues: {
      brokerMaxOffer: Money$.parse(underwritingPreview?.brokerMaxOffer),
      comment: "",
      maxOffer: Money$.parse(underwritingPreview?.maxOffer),
      offerPrice: Money$.parse(underwritingPreview?.offerPrice),
    },
    schema: AllocateHomeRightRailFormSchema,
  });

  async function computeUnderwriting({
    brokerMaxOffer,
    offerPrice,
  }: Pick<AllocateHomeRightRailFormInput, "brokerMaxOffer" | "offerPrice">) {
    // in order for taxes to be updated with changing offer price, we need to manually set taxes to 0 in payload
    const response = await UnderwritingAPI.computeUnderwritingPreview(
      cast<UnderwritingAPIComputeUnderwritingPreviewPayload>({
        ...underwritingPreview,
        dealId,
        brokerMaxOffer: brokerMaxOffer.pipe(
          O.map((val) => val.toJSON()),
          O.getOrThrow,
        ),
        offerPrice: offerPrice.pipe(
          O.map((val) => val.toJSON()),
          O.getOrThrow,
        ),
        ...(form.formState.dirtyFields.offerPrice && { taxes: 0 }),
      }),
    );

    // if taxes in response are 0, we want to keep the taxes from the original underwriting tax value
    // @ts-expect-error Fix UnderwritingPageContext types
    updateUnderwritingPreview({
      ...response,
      taxes:
        convertToNumber(response.taxes) === 0
          ? underwritingPreview?.taxes
          : response.taxes,
    });

    return response;
  }

  const { mutateAsync: handleSubmit, isLoading } = useMutation({
    mutationFn: async ({
      brokerMaxOffer,
      comment,
      offerPrice,
    }: AllocateHomeRightRailFormInput) => {
      const preview = await computeUnderwriting({ brokerMaxOffer, offerPrice });
      await saveUnderwriting(preview);

      if (isAllocatableStatus) {
        await BuyAPI.setStatus({
          dealId,
          overrideWarnings: true,
          statusName: "pipelineStatus",
          statusValue: DealStatusPipelineStatusEnum.OFFER,
        });
        await BuyAPI.setStatus({
          dealId,
          overrideWarnings: true,
          statusName: "genericSubStatus",
          statusValue: GenericSubStatusEnum.WAITING_ON_RESPONSE,
        });
      }

      const offerType = isAllocatableStatus
        ? OfferTypeEnum.BUYER
        : OfferTypeEnum.BUYER_CONCESSION_REQUEST;

      await BuyAPI.createOffer({
        amount: O.getOrThrow(offerPrice).toJSON(),
        comment,
        dealId,
        source: BrokersServiceBrokerTypeEnum.BUYSIDE,
        type: offerType,
      });
      showSuccessNotification({
        message: "Added Offer Successfully",
      });

      onClose();
      gotoNextUnderwriting();
    },
    mutationKey: ["handleSubmit"] as const,
  });

  const onSubmit = useCallback(
    (values: AllocateHomeRightRailFormInput) => handleSubmit(values),
    [handleSubmit],
  );

  return (
    <RightRail opened={opened} onClose={onClose} title="Offer">
      <Form onSubmit={onSubmit} form={form}>
        <Stack spacing={Spacing.sm}>
          <OfferWrapper offerValue={form.watch("maxOffer")}>
            <FormMoneyInput
              label="Max Offer"
              name="maxOffer"
              form={form}
              disabled
            />
          </OfferWrapper>
          <OfferWrapper offerValue={form.watch("brokerMaxOffer")}>
            <FormMoneyInput
              label="Broker Max Offer"
              name="brokerMaxOffer"
              form={form}
            />
          </OfferWrapper>
          <OfferWrapper offerValue={form.watch("offerPrice")}>
            <FormMoneyInput label="Offer Price" name="offerPrice" form={form} />
          </OfferWrapper>
          <FormTextarea label="Comments" name="comment" form={form} />
          <Group justify={Justify.end}>
            <Button type="submit" loading={isLoading}>
              Add Offer
            </Button>
          </Group>
        </Stack>
      </Form>
    </RightRail>
  );
}

export { ALLOCATABLE_STATUSES, AllocateHomeRightRail };
