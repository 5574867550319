/**
 * HTTP Method: GET
 * Pathname: /collections/steps
 * @function getSteps
 * @memberof CollectionsAPI
 * @param {CollectionsAPIGetStepsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsStepResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { CollectionsStepResponse } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIGetStepsSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIGetStepsPayload = CollectionsAPIGetStepsSearchParams;

function getStepsUnsafeEffect(payload: CollectionsAPIGetStepsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsStepResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsStepResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/collections/steps",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSteps(
  payload: CollectionsAPIGetStepsPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsStepResponse[]> {
  return F.pipe(
    payload,
    getStepsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSteps };
export type {
  CollectionsAPIGetStepsPayload,
  CollectionsAPIGetStepsSearchParams,
};
