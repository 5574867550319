import { Button } from "@ender/shared/ds/button";
import { FontSize, Text } from "@ender/shared/ds/text";

function PlaidSyncButton({ onClick }: { onClick: () => void }) {
  return (
    <div>
      <div className="danger">
        <Text size={FontSize.md}>Plaid credentials are out of sync</Text>
      </div>
      <Button onClick={onClick}>Update Plaid</Button>
    </div>
  );
}

export { PlaidSyncButton };
