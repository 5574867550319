import { Button } from "@ender/shared/ds/button";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";

type SubmitButtonProps = {
  amount?: string;
  disabled: boolean;
  paymentType: MoneyTransferTransferType;
};

function SubmitButton({ amount, disabled, paymentType }: SubmitButtonProps) {
  const amountString = amount ? ` ${amount}` : "";
  const paymentTypeString =
    paymentType === MoneyTransferTransferTypeEnum.MARK_PAID_CHECK
      ? "Check"
      : "Bank Transfer";
  return (
    <Button type="submit" disabled={disabled}>
      {`Confirm ${paymentTypeString}${amountString}`}
    </Button>
  );
}

export { SubmitButton };
