/**
 * HTTP Method: POST
 * Pathname: /brokers
 * @function saveBroker
 * @memberof BuyAPI
 * @param {BuyAPISaveBrokerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<User>}
 */
import { Effect, Function as F } from "effect";

import type { User } from "@ender/shared/generated/ender.model.core.user";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPISaveBrokerSearchParams = {
  token?: string | undefined;
};

type BuyAPISaveBrokerBodyParams = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

type BuyAPISaveBrokerPayload = BuyAPISaveBrokerSearchParams &
  BuyAPISaveBrokerBodyParams;

function saveBrokerUnsafeEffect(payload: BuyAPISaveBrokerPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, User>({
        body,
        decode: unsafeDecodeJsonResponse<User>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/brokers",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function saveBroker(
  payload: BuyAPISaveBrokerPayload,
  options?: { signal?: AbortSignal },
): Promise<User> {
  return F.pipe(
    payload,
    saveBrokerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { saveBroker };
export type {
  BuyAPISaveBrokerBodyParams,
  BuyAPISaveBrokerPayload,
  BuyAPISaveBrokerSearchParams,
};
