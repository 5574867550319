import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

type GetNeedsMyApprovalParams = {
  approvalSteps?: GetApprovalProcessResponse;
  invoice: InvoiceSerializerInvoiceResponse;
  user: User;
};

function getNeedsMyApproval({
  approvalSteps,
  invoice,
  user,
}: GetNeedsMyApprovalParams): boolean {
  if (
    invoice?.status === ApprovableApprovalStatusEnum.APPROVED ||
    invoice?.status === ApprovableApprovalStatusEnum.REJECTED
  ) {
    return false;
  }

  const currentApprovalStep = approvalSteps?.steps.find(
    (step) => step.id === invoice?.currentState?.stepId,
  );
  return (
    currentApprovalStep?.approvers.some(
      (approver) => approver.id === user?.id,
    ) ?? false
  );
}

export { getNeedsMyApproval };
