import type { ReactNode } from "react";

import type { Money } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import type { EmptyObject } from "@ender/shared/types/general";

import type { CellProps } from "../table.types";

function MoneyCell<RowData, Meta extends object = EmptyObject>({
  getValue,
  row: { index },
}: CellProps<
  RowData,
  string | number | Money | Money$.Money | null | undefined,
  Meta
>): ReactNode {
  const value = getValue();
  return (
    value && (
      <MoneyDisplay showSymbol={index === 0} value={Money$.parse(value)} />
    )
  );
}

export { MoneyCell };
