/**
 * HTTP Method: POST
 * Pathname: /factors/search
 * @function searchFactors
 * @memberof FactorsAPI
 * @param {FactorsAPISearchFactorsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FactorsAPISearchFactorsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { CustomFactorFactorOutputType } from "@ender/shared/generated/ender.model.factor";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { FactorsAPISearchFactorsResponse } from "../factors-api-search-factors-response";

type FactorsAPISearchFactorsSearchParams = {
  token?: string | undefined;
};

type FactorsAPISearchFactorsBodyParams = {
  allowFactorCreation?: boolean | undefined;
  builtInFactors?: boolean | undefined;
  customFactors?: boolean | undefined;
  excludeIds?: EnderId[] | undefined;
  inputTypes?: ModelType[] | undefined;
  /**
 * If this is set, will only return Factors which have 'isBucketingFactor' that matches this
                          boolean input.
 */
  isBucketingFactor?: boolean | undefined;
  isFavorite?: boolean | undefined;
  keyword?: string | undefined;
  outputTypes?: CustomFactorFactorOutputType[] | undefined;
  showHidden?: boolean | undefined;
};

type FactorsAPISearchFactorsPayload = FactorsAPISearchFactorsSearchParams &
  FactorsAPISearchFactorsBodyParams;

function searchFactorsUnsafeEffect(payload: FactorsAPISearchFactorsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FactorsAPISearchFactorsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<FactorsAPISearchFactorsResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/factors/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchFactors(
  payload: FactorsAPISearchFactorsPayload,
  options?: { signal?: AbortSignal },
): Promise<FactorsAPISearchFactorsResponse[]> {
  return F.pipe(
    payload,
    searchFactorsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchFactors };
export type {
  FactorsAPISearchFactorsBodyParams,
  FactorsAPISearchFactorsPayload,
  FactorsAPISearchFactorsSearchParams,
};
