import { Option as O } from "effect";

import { AllocationTypeEnum } from "@ender/shared/types/ender-general";

import type { PropertyIdWithAllocationsOutput } from "./journal-entry-right-rail.types";

/*
   Tyler 11-15-24 -- Please note, credits and debits are not part of the payload.  
    These are fields which exist prior to form submission and effectively become the amount field. 
    This is necessary because the previous configuration used a single amount field but within the form system,
    this causes the Credits and Debits fields below to update simultaneously
  */

// Used for building the initial value for PropertyIdWithAllocations
const emptyJournalEntryAllocationsWithProperty: PropertyIdWithAllocationsOutput =
  {
    allocations: [
      {
        amount: O.none(),
        credits: O.none(),
        debits: O.none(),
        description: "",
        glCategoryId: O.none(),
        isParentCategory: false,
        type: AllocationTypeEnum.DEBIT,
      },
      {
        amount: O.none(),
        credits: O.none(),
        debits: O.none(),
        description: "",
        glCategoryId: O.none(),
        isParentCategory: false,
        type: AllocationTypeEnum.CREDIT,
      },
    ],
    propertyId: O.none(),
  };

export { emptyJournalEntryAllocationsWithProperty };
