import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UtilitiesPolicyUtilitiesTypeValues = [
  "GAS",
  "ELECTRICITY",
  "WATER",
  "SEWER",
  "TRASH",
  "CABLE",
  "PEST_CONTROL",
  "LANDSCAPE_WATERING",
  "LANDSCAPE_MAINTENANCE",
] as const;

const UtilitiesPolicyUtilitiesTypeEffectSchema = Schema.Literal(
  ...UtilitiesPolicyUtilitiesTypeValues,
);

type UtilitiesPolicyUtilitiesType = Schema.Schema.Type<
  typeof UtilitiesPolicyUtilitiesTypeEffectSchema
>;

const UtilitiesPolicyUtilitiesTypeEnum = castEnum(
  UtilitiesPolicyUtilitiesTypeEffectSchema,
);

function randomUtilitiesPolicyUtilitiesType(): UtilitiesPolicyUtilitiesType {
  return rand(UtilitiesPolicyUtilitiesTypeValues);
}

export {
  randomUtilitiesPolicyUtilitiesType,
  UtilitiesPolicyUtilitiesTypeEffectSchema,
  UtilitiesPolicyUtilitiesTypeEnum,
  UtilitiesPolicyUtilitiesTypeValues,
};
export type { UtilitiesPolicyUtilitiesType };
