import { Option as O } from "effect";
import { useWatch } from "react-hook-form";

import type { UseFormReturn } from "@ender/form-system/base";
import { Tuple } from "@ender/shared/ds/tuple";

import type { PetFormInput } from "./edit-pet-info-fields";

type DisplayablePetInfoProps = {
  form: UseFormReturn<PetFormInput>;
};

function DisplayablePetInfo(props: DisplayablePetInfoProps) {
  const { form } = props;
  const [
    age,
    breed,
    isEmotionalSupportAnimal,
    isNeutered,
    name,
    species,
    weightInPounds,
  ] = useWatch({
    control: form.control,
    name: [
      "age",
      "breed",
      "isEmotionalSupportAnimal",
      "isNeutered",
      "name",
      "species",
      "weightInPounds",
    ],
  });
  return (
    <>
      <Tuple label="Name" value={name} />
      <Tuple label="Species" value={O.getOrUndefined(species)} />
      <Tuple label="Breed" value={breed} />
      <Tuple
        label="Neutered/Spayed"
        value={O.getOrUndefined(isNeutered) ? "Yes" : "No"}
      />
      <Tuple
        label="Age"
        value={age.pipe(
          O.map((val) => `${val.toString()}`),
          O.getOrElse(() => "No value"),
        )}
      />
      <Tuple
        label="Weight"
        value={`${weightInPounds.pipe(
          O.map((val) => `${val.toString()}`),
          O.getOrElse(() => "No value"),
        )} lbs`}
      />
      <Tuple
        label="ESA/Service Animal"
        value={O.getOrUndefined(isEmotionalSupportAnimal) ? "Yes" : "No"}
      />
    </>
  );
}

export { DisplayablePetInfo };
