/**
 * HTTP Method: GET
 * Pathname: /text-templates
 * @function listTemplates
 * @memberof TemplatesAPI
 * @param {TemplatesAPIListTemplatesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ListTemplatesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { ListTemplatesResponse } from "@ender/shared/generated/ender.api.documents.response";
import type { TextTemplateTemplateType } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TemplatesAPIListTemplatesSearchParams = {
  token?: string | undefined;
  includeArchived?: boolean | undefined;
  type?: TextTemplateTemplateType | undefined;
};

type TemplatesAPIListTemplatesPayload = TemplatesAPIListTemplatesSearchParams;

function listTemplatesUnsafeEffect(payload: TemplatesAPIListTemplatesPayload) {
  const { token, includeArchived, type, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ListTemplatesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<ListTemplatesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/text-templates",
        searchParams: [
          ["token", token],
          ["includeArchived", includeArchived],
          ["type", type],
        ],
      }),
    ),
  );
}

function listTemplates(
  payload: TemplatesAPIListTemplatesPayload,
  options?: { signal?: AbortSignal },
): Promise<ListTemplatesResponse[]> {
  return F.pipe(
    payload,
    listTemplatesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { listTemplates };
export type {
  TemplatesAPIListTemplatesPayload,
  TemplatesAPIListTemplatesSearchParams,
};
