/**
 * HTTP Method: DELETE
 * Pathname: /leads/{leadId}/pets/{petId}
 * @function deletePetFromLead
 * @memberof PetsAPI
 * @param {PetsAPIDeletePetFromLeadPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIDeletePetFromLeadPathParams = {
  leadId: EnderId;
  petId: EnderId;
};

type PetsAPIDeletePetFromLeadSearchParams = {
  token?: string | undefined;
};

type PetsAPIDeletePetFromLeadPayload = PetsAPIDeletePetFromLeadPathParams &
  PetsAPIDeletePetFromLeadSearchParams;

function deletePetFromLeadUnsafeEffect(
  payload: PetsAPIDeletePetFromLeadPayload,
) {
  const { leadId, petId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/leads/${leadId}/pets/${petId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function deletePetFromLead(
  payload: PetsAPIDeletePetFromLeadPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deletePetFromLeadUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deletePetFromLead };
export type {
  PetsAPIDeletePetFromLeadPathParams,
  PetsAPIDeletePetFromLeadPayload,
  PetsAPIDeletePetFromLeadSearchParams,
};
