import * as O from "effect/Option";
import { create } from "zustand";
import type { PersistOptions } from "zustand/middleware";
import { persist } from "zustand/middleware";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import type { SearchPropertiesResponseSearchPropertiesResult } from "@ender/shared/generated/ender.api.core.response";
import type {
  InvoicePayableType,
  InvoicePaymentMethod,
} from "@ender/shared/generated/ender.model.payments.invoice";

import type { PartyFilterValue } from "./party-filter-value";

// In-memory state type (with Optionals)
type InvoiceFiltersStore = {
  accountingPeriodsFilter: LocalDate$.LocalDate[];
  amountFilter: string | Undefined;
  endDateFilter: O.Option<LocalDate$.LocalDate>;
  endDueDateFilter: O.Option<LocalDate$.LocalDate>;
  firmFilter: EnderId[];
  fundFilter: EnderId[];
  inclusiveEndPaidDate: O.Option<LocalDate$.LocalDate>;
  invoiceNumberFilter: string | Undefined;
  marketFilter: EnderId[];
  partyFilter: PartyFilterValue | Undefined;
  payableTypeFilter: O.Option<InvoicePayableType[]>;
  paymentTypeFilter: InvoicePaymentMethod | Undefined;
  propertiesFilter: SelectOption<
    EnderId,
    Pick<SearchPropertiesResponseSearchPropertiesResult, "name" | "id">
  >[];
  setAccountingPeriodsFilter: (value: LocalDate$.LocalDate[]) => void;
  setAmountFilter: (value: string | Undefined) => void;
  setEndDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setEndDueDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setFirmFilter: (value: EnderId[]) => void;
  setFundFilter: (value: EnderId[]) => void;
  setInclusiveEndPaidDate: (value: O.Option<LocalDate$.LocalDate>) => void;
  setInvoiceNumberFilter: (value: string | Undefined) => void;
  setMarketFilter: (value: EnderId[]) => void;
  setPartyFilter: (value: PartyFilterValue | Undefined) => void;
  setPayableTypeFilter: (value: O.Option<InvoicePayableType[]>) => void;
  setPaymentTypeFilter: (value: InvoicePaymentMethod | Undefined) => void;
  setPropertiesFilter: (
    value: SelectOption<
      EnderId,
      Pick<SearchPropertiesResponseSearchPropertiesResult, "name" | "id">
    >[],
  ) => void;
  setStartDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setStartDueDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setStartPaidDate: (value: O.Option<LocalDate$.LocalDate>) => void;
  startDateFilter: O.Option<LocalDate$.LocalDate>;
  startDueDateFilter: O.Option<LocalDate$.LocalDate>;
  startPaidDate: O.Option<LocalDate$.LocalDate>;
};

// Serialized state type (without Optionals)
type SerializedInvoiceFiltersState = Omit<
  InvoiceFiltersStore,
  | "setAccountingPeriodsFilter"
  | "setAmountFilter"
  | "setEndDateFilter"
  | "setEndDueDateFilter"
  | "setFirmFilter"
  | "setFundFilter"
  | "setInclusiveEndPaidDate"
  | "setInvoiceNumberFilter"
  | "setMarketFilter"
  | "setPartyFilter"
  | "setPayableTypeFilter"
  | "setPaymentTypeFilter"
  | "setPropertiesFilter"
  | "setStartDateFilter"
  | "setStartDueDateFilter"
  | "setStartPaidDate"
> & {
  accountingPeriodsFilter: LocalDate$.LocalDate[];
  endDateFilter: LocalDate$.LocalDate | Null;
  endDueDateFilter: LocalDate$.LocalDate | Null;
  inclusiveEndPaidDate: LocalDate$.LocalDate | Null;
  payableTypeFilter: InvoicePayableType[];
  startDateFilter: LocalDate$.LocalDate | Null;
  startPaidDate: LocalDate$.LocalDate | Null;
  startDueDateFilter: LocalDate$.LocalDate | Null;
};

const persistOptions: PersistOptions<InvoiceFiltersStore> = {
  merge: (
    persistedState: unknown,
    currentState: InvoiceFiltersStore,
  ): InvoiceFiltersStore => {
    const typedPersistedState =
      persistedState as Partial<SerializedInvoiceFiltersState>;
    return {
      ...currentState,
      ...typedPersistedState,
      accountingPeriodsFilter:
        typedPersistedState.accountingPeriodsFilter !== undefined
          ? typedPersistedState.accountingPeriodsFilter.map((val) =>
              LocalDate$.of(val),
            )
          : currentState.accountingPeriodsFilter,
      endDateFilter:
        typedPersistedState.endDateFilter !== undefined
          ? LocalDate$.parse(typedPersistedState.endDateFilter)
          : currentState.endDateFilter,
      endDueDateFilter:
        typedPersistedState.endDueDateFilter !== undefined
          ? LocalDate$.parse(typedPersistedState.endDueDateFilter)
          : currentState.endDueDateFilter,
      inclusiveEndPaidDate:
        typedPersistedState.inclusiveEndPaidDate !== undefined
          ? LocalDate$.parse(typedPersistedState.inclusiveEndPaidDate)
          : currentState.inclusiveEndPaidDate,
      payableTypeFilter:
        typedPersistedState.payableTypeFilter !== undefined
          ? O.fromNullable(typedPersistedState.payableTypeFilter)
          : currentState.payableTypeFilter,
      startDateFilter:
        typedPersistedState.startDateFilter !== undefined
          ? LocalDate$.parse(typedPersistedState.startDateFilter)
          : currentState.startDateFilter,
      startDueDateFilter:
        typedPersistedState.startDueDateFilter !== undefined
          ? LocalDate$.parse(typedPersistedState.startDueDateFilter)
          : currentState.startDueDateFilter,
      startPaidDate:
        typedPersistedState.startPaidDate !== undefined
          ? LocalDate$.parse(typedPersistedState.startPaidDate)
          : currentState.startPaidDate,
    };
  },
  migrate: (
    persisted: unknown,
    version,
  ): InvoiceFiltersStore | Promise<InvoiceFiltersStore> => {
    if (version < 3) {
      return {} as InvoiceFiltersStore;
    }
    return persisted as Promise<InvoiceFiltersStore>;
  },
  name: "invoice-filters-storage",
  partialize: (state): InvoiceFiltersStore => ({
    ...state,
    accountingPeriodsFilter: state.accountingPeriodsFilter,
    // @ts-expect-error
    endDateFilter: O.getOrNull(state.endDateFilter),
    // @ts-expect-error
    endDueDateFilter: O.getOrNull(state.endDueDateFilter),
    // @ts-expect-error
    inclusiveEndPaidDate: O.getOrNull(state.inclusiveEndPaidDate),
    // @ts-expect-error
    payableTypeFilter: O.getOrNull(state.payableTypeFilter),
    // @ts-expect-error
    startDateFilter: O.getOrNull(state.startDateFilter),
    // @ts-expect-error
    startDueDateFilter: O.getOrNull(state.startDueDateFilter),
    // @ts-expect-error
    startPaidDate: O.getOrNull(state.startPaidDate),
  }),

  version: 3,
};

const useInvoiceFiltersStore = create<InvoiceFiltersStore>()(
  persist(
    (set) => ({
      accountingPeriodsFilter: [],
      amountFilter: UNDEFINED,
      endDateFilter: O.none(),
      endDueDateFilter: O.none(),
      firmFilter: [],
      fundFilter: [],
      inclusiveEndPaidDate: O.none(),
      invoiceNumberFilter: UNDEFINED,
      marketFilter: [],
      partyFilter: UNDEFINED,
      payableTypeFilter: O.none(),
      paymentTypeFilter: UNDEFINED,
      propertiesFilter: [],
      setAccountingPeriodsFilter: (value) =>
        set({ accountingPeriodsFilter: value }),
      setAmountFilter: (value) => set({ amountFilter: value }),
      setEndDateFilter: (value) => set({ endDateFilter: value }),
      setEndDueDateFilter: (value) => set({ endDueDateFilter: value }),
      setFirmFilter: (value) => set({ firmFilter: value }),
      setFundFilter: (value) => set({ fundFilter: value }),
      setInclusiveEndPaidDate: (value) => set({ inclusiveEndPaidDate: value }),
      setInvoiceNumberFilter: (value) => set({ invoiceNumberFilter: value }),
      setMarketFilter: (value) => set({ marketFilter: value }),
      setPartyFilter: (value) => set({ partyFilter: value }),
      setPayableTypeFilter: (value) => set({ payableTypeFilter: value }),
      setPaymentTypeFilter: (value) => set({ paymentTypeFilter: value }),
      setPropertiesFilter: (value) => set({ propertiesFilter: value }),
      setStartDateFilter: (value) => set({ startDateFilter: value }),
      setStartDueDateFilter: (value) => set({ startDueDateFilter: value }),
      setStartPaidDate: (value) => set({ startPaidDate: value }),
      startDateFilter: O.none(),
      startDueDateFilter: O.none(),
      startPaidDate: O.none(),
    }),
    persistOptions,
  ),
);

type UseInvoiceFiltersResult = {
  accountingPeriodsFilter: LocalDate$.LocalDate[];
  amountFilter: string | Undefined;
  clearAllFilters: () => void;
  endDateFilter: O.Option<LocalDate$.LocalDate>;
  endDueDateFilter: O.Option<LocalDate$.LocalDate>;
  firmFilter: EnderId[];
  fundFilter: EnderId[];
  inclusiveEndPaidDate: O.Option<LocalDate$.LocalDate>;
  invoiceNumberFilter: string | Undefined;
  marketFilter: EnderId[];
  partyFilter: PartyFilterValue | Undefined;
  payableTypeFilter: O.Option<InvoicePayableType[]>;
  paymentTypeFilter: InvoicePaymentMethod | Undefined;
  propertiesFilter: SelectOption<
    EnderId,
    Pick<SearchPropertiesResponseSearchPropertiesResult, "name" | "id">
  >[];
  setAccountingPeriodsFilter: (value: LocalDate$.LocalDate[]) => void;
  setAmountFilter: (value: string | Undefined) => void;
  setEndDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setEndDueDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setFirmFilter: (value: EnderId[]) => void;
  setFundFilter: (value: EnderId[]) => void;
  setInclusiveEndPaidDate: (value: O.Option<LocalDate$.LocalDate>) => void;
  setInvoiceNumberFilter: (value: string | Undefined) => void;
  setMarketFilter: (value: EnderId[]) => void;
  setPartyFilter: (value: PartyFilterValue | Undefined) => void;
  setPayableTypeFilter: (value: O.Option<InvoicePayableType[]>) => void;
  setPaymentTypeFilter: (value: InvoicePaymentMethod | Undefined) => void;
  setPropertiesFilter: (
    value: SelectOption<
      EnderId,
      Pick<SearchPropertiesResponseSearchPropertiesResult, "name" | "id">
    >[],
  ) => void;
  setStartDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setStartDueDateFilter: (value: O.Option<LocalDate$.LocalDate>) => void;
  setStartPaidDate: (value: O.Option<LocalDate$.LocalDate>) => void;
  startDateFilter: O.Option<LocalDate$.LocalDate>;
  startDueDateFilter: O.Option<LocalDate$.LocalDate>;
  startPaidDate: O.Option<LocalDate$.LocalDate>;
};

function useInvoiceFilters(): UseInvoiceFiltersResult {
  const {
    accountingPeriodsFilter,
    amountFilter,
    endDateFilter,
    endDueDateFilter,
    firmFilter,
    fundFilter,
    inclusiveEndPaidDate,
    invoiceNumberFilter,
    marketFilter,
    partyFilter,
    payableTypeFilter,
    paymentTypeFilter,
    propertiesFilter,
    setAccountingPeriodsFilter,
    setAmountFilter,
    setEndDateFilter,
    setEndDueDateFilter,
    setFirmFilter,
    setFundFilter,
    setInclusiveEndPaidDate,
    setInvoiceNumberFilter,
    setMarketFilter,
    setPartyFilter,
    setPayableTypeFilter,
    setPaymentTypeFilter,
    setPropertiesFilter,
    setStartDateFilter,
    setStartDueDateFilter,
    setStartPaidDate,
    startDateFilter,
    startDueDateFilter,
    startPaidDate,
  } = useInvoiceFiltersStore();

  const clearAllFilters = () => {
    setAccountingPeriodsFilter([]);
    setAmountFilter(UNDEFINED);
    setEndDateFilter(O.none());
    setEndDueDateFilter(O.none());
    setFirmFilter([]);
    setFundFilter([]);
    setInclusiveEndPaidDate(O.none());
    setInvoiceNumberFilter(UNDEFINED);
    setMarketFilter([]);
    setPartyFilter(UNDEFINED);
    setPayableTypeFilter(O.none());
    setPaymentTypeFilter(UNDEFINED);
    setPropertiesFilter([]);
    setStartDateFilter(O.none());
    setStartDueDateFilter(O.none());
    setStartPaidDate(O.none());
  };

  return {
    accountingPeriodsFilter,
    amountFilter,
    clearAllFilters,
    endDateFilter,
    endDueDateFilter,
    firmFilter,
    fundFilter,
    inclusiveEndPaidDate,
    invoiceNumberFilter,
    marketFilter,
    partyFilter,
    payableTypeFilter,
    paymentTypeFilter,
    propertiesFilter,
    setAccountingPeriodsFilter,
    setAmountFilter,
    setEndDateFilter,
    setEndDueDateFilter,
    setFirmFilter,
    setFundFilter,
    setInclusiveEndPaidDate,
    setInvoiceNumberFilter,
    setMarketFilter,
    setPartyFilter,
    setPayableTypeFilter,
    setPaymentTypeFilter,
    setPropertiesFilter,
    setStartDateFilter,
    setStartDueDateFilter,
    setStartPaidDate,
    startDateFilter,
    startDueDateFilter,
    startPaidDate,
  };
}

export { useInvoiceFiltersStore as invoiceFiltersStore, useInvoiceFilters };
