import type { TenantLedgerReportLedgerEntryTenantLedgerEventType as TenantLedgerEventType } from "@ender/shared/generated/ender.arch.accounting";
import { TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum as TenantLedgerEventTypeEnum } from "@ender/shared/generated/ender.arch.accounting";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

import {
  MappedLedgerEventPaymentTypeSchema,
  isMappedLedgerEventPaymentType,
} from "./ledger.types";

const MappedPaymentTypesEnum = MappedLedgerEventPaymentTypeSchema.Enum;

const LedgerEventPaymentTypeDisplayMap = {
  [MappedPaymentTypesEnum.DWOLLA_TRANSFER]: "ACH",
  [MappedPaymentTypesEnum.MARK_PAID_CHECK]: "Check",
  [MappedPaymentTypesEnum.STRIPE_PAYMENT]: "Credit Card",
  [MappedPaymentTypesEnum.PAYABLE]: "-",
  [MappedPaymentTypesEnum.OTHER]: "",
} as const;

/**
 * @description Converts payment type enum value to a displayable value
 */
function convertPaymentTypeForDisplay(
  paymentType: MoneyTransferTransferType,
  isAutopayment: boolean,
): string {
  const autopaymentLabel = isAutopayment ? " (Auto)" : "";
  const paymentTypeForRender = isMappedLedgerEventPaymentType(paymentType)
    ? LedgerEventPaymentTypeDisplayMap[paymentType]
    : convertSnakeCaseToTitleCase(paymentType);

  return `${paymentTypeForRender}${autopaymentLabel}`;
}

function shouldNegateAmount(
  tenantLedgerEventType: TenantLedgerEventType,
  isCreditReversal: boolean,
): boolean {
  if (tenantLedgerEventType === TenantLedgerEventTypeEnum.PAYMENT) {
    return true;
  }
  return (
    tenantLedgerEventType === TenantLedgerEventTypeEnum.REFUND ||
    tenantLedgerEventType === TenantLedgerEventTypeEnum.REVERSAL ||
    (tenantLedgerEventType === TenantLedgerEventTypeEnum.CREDIT &&
      isCreditReversal)
  );
}

function isAmountInChargeColumn(
  tenantLedgerEventType: TenantLedgerEventType,
): boolean {
  return (
    tenantLedgerEventType === TenantLedgerEventTypeEnum.CHARGE ||
    tenantLedgerEventType === TenantLedgerEventTypeEnum.CREDIT
  );
}

export {
  convertPaymentTypeForDisplay,
  isAmountInChargeColumn,
  shouldNegateAmount,
};
