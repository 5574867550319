/**
 * Edit a (not-yet-deposited) tenant receipt.
 * HTTP Method: POST
 * Pathname: /receipts/{moneyTransferId}
 * @function editReceipt
 * @memberof PaymentsAPI
 * @param {PaymentsAPIEditReceiptPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIEditReceiptPathParams = {
  moneyTransferId: EnderId;
};

type PaymentsAPIEditReceiptSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIEditReceiptBodyParams = {
  checkNumber: string;
  memo: string;
};

type PaymentsAPIEditReceiptPayload = PaymentsAPIEditReceiptPathParams &
  PaymentsAPIEditReceiptSearchParams &
  PaymentsAPIEditReceiptBodyParams;

function editReceiptUnsafeEffect(payload: PaymentsAPIEditReceiptPayload) {
  const { moneyTransferId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/receipts/${moneyTransferId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function editReceipt(
  payload: PaymentsAPIEditReceiptPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    editReceiptUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { editReceipt };
export type {
  PaymentsAPIEditReceiptBodyParams,
  PaymentsAPIEditReceiptPathParams,
  PaymentsAPIEditReceiptPayload,
  PaymentsAPIEditReceiptSearchParams,
};
