import { Schema } from "@effect/schema";

import { MoneyEffectSchema } from "@ender/form-system/schema";
import { EnderIdFormSchema } from "@ender/shared/core";

/**
 * TODO - move-out is currently using a locally defined Effect schema that matches this schema.
 */
const RefundRecipientSchema = Schema.Struct({
  amount: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  id: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  name: Schema.String,
});

type RefundRecipientInput = Schema.Schema.Encoded<typeof RefundRecipientSchema>;
type RefundRecipientOutput = Schema.Schema.Type<typeof RefundRecipientSchema>;

export { RefundRecipientSchema };
export type { RefundRecipientInput, RefundRecipientOutput };
