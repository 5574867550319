import { Array as A, Function as F, Predicate as P, pipe } from "effect";
import { useMemo } from "react";

import type { OptimizedLedgerCategory } from "@ender/shared/contexts/ledger";
import type { Money$ } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Spacing } from "@ender/shared/ds/flex";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { TupleList } from "@ender/shared/ds/tuple";
import { fromScreamingSnakeCaseToSpaceCase } from "@ender/shared/utils/string";

type LedgerDetailsTupleProps = {
  amount: Money$.Money;
  transactionDate: string;
  accountingDate: string;
  systemDate: string;
  description: string;
  source: string;
  author: string;
  debitedCategory?: OptimizedLedgerCategory | undefined;
};

function LedgerDetailsTuple(props: LedgerDetailsTupleProps) {
  const {
    amount,
    transactionDate,
    accountingDate,
    systemDate,
    description,
    source,
    author,
    debitedCategory,
  } = props;

  const tupleList = useMemo(
    () =>
      pipe(
        A.fromIterable([
          { label: "Amount", value: <MoneyDisplay value={amount} /> },
          {
            label: "Transaction Date",
            value: <DateDisplay value={LocalDate$.of(transactionDate)} />,
          },
          {
            label: "Accounting Period",
            value: <DateDisplay value={LocalDate$.of(accountingDate)} />,
          },
          {
            label: "System Date",
            value: <DateDisplay value={LocalDate$.of(systemDate)} />,
          },
          { label: "Description", value: description },
          { label: "Source", value: fromScreamingSnakeCaseToSpaceCase(source) },
          { label: "Author", value: author },
        ]),
        P.isNotUndefined(debitedCategory)
          ? A.append({
              label: "Debited Account",
              value: `${debitedCategory.accountNumber} ${debitedCategory.accountName}`,
            })
          : F.identity,
      ),
    [
      amount,
      transactionDate,
      accountingDate,
      systemDate,
      description,
      source,
      author,
      debitedCategory,
    ],
  );

  return (
    <Stack spacing={Spacing.none}>
      <TupleList entries={tupleList} />
    </Stack>
  );
}

export { LedgerDetailsTuple };
export type { LedgerDetailsTupleProps };
