import type { Predicate as P } from "effect";
import type { ReactElement } from "react";
import { isValidElement } from "react";

function isReactElement<T>(
  maybeElement: unknown,
  isValidProps: P.Refinement<unknown, T>,
): maybeElement is ReactElement<T> {
  return isValidElement(maybeElement) && isValidProps(maybeElement.props);
}

export { isReactElement };
