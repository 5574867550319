import { IconPaperclip } from "@tabler/icons-react";
import { useContext } from "react";

import { addFilesToLedgerEvent } from "@ender/shared/api/ledger";
import type { OptimizedLedgerEvent } from "@ender/shared/contexts/ledger";
import { UserContext } from "@ender/shared/contexts/user";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Modal } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { FileUploadForm } from "@ender/shared/ui/modals";
import { noOpAsync } from "@ender/shared/utils/no-op";

type LedgerEventAttachmentBtnProps = {
  ledgerEvent: OptimizedLedgerEvent;
  onSuccess?: () => Promise<void>;
};

function LedgerEventAttachmentBtn(props: LedgerEventAttachmentBtnProps) {
  const { ledgerEvent, onSuccess = noOpAsync } = props;
  const { user } = useContext(UserContext);
  const [
    isFileUploadModalOpened,
    { setTrue: openFileUploadModal, setFalse: closeFileUploadModal },
  ] = useBoolean();

  async function handleFilesUpload(files: File[]) {
    await addFilesToLedgerEvent({
      eventId: ledgerEvent.id,
      // @ts-expect-error the types of upload eventType and ledgerEventType don't fully overlap
      eventType: ledgerEvent.ledgerEventType,
      files,
      userId: user.id,
    });
  }

  async function handleFilesUploadSuccess() {
    await onSuccess();
    closeFileUploadModal();
  }

  return (
    <>
      <ActionIcon label="Add attachment" onClick={openFileUploadModal}>
        <IconPaperclip />
      </ActionIcon>
      <Modal
        title="Upload Payment Images"
        opened={isFileUploadModalOpened}
        onClose={closeFileUploadModal}>
        <FileUploadForm
          uploadFiles={handleFilesUpload}
          onSuccess={handleFilesUploadSuccess}
        />
      </Modal>
    </>
  );
}

export { LedgerEventAttachmentBtn };
