/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/pets
 * @function addPetToApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIAddPetToApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Pet>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIAddPetToApplicationPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIAddPetToApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIAddPetToApplicationBodyParams = {
  age: number;
  breed: string;
  isEmotionalSupportAnimal?: boolean | undefined;
  isNeutered: boolean;
  name: string;
  species: string;
  weight: number;
};

type ApplicationsAPIAddPetToApplicationPayload =
  ApplicationsAPIAddPetToApplicationPathParams &
    ApplicationsAPIAddPetToApplicationSearchParams &
    ApplicationsAPIAddPetToApplicationBodyParams;

function addPetToApplicationUnsafeEffect(
  payload: ApplicationsAPIAddPetToApplicationPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Pet>({
        body,
        decode: unsafeDecodeJsonResponse<Pet>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/pets`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function addPetToApplication(
  payload: ApplicationsAPIAddPetToApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<Pet> {
  return F.pipe(
    payload,
    addPetToApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addPetToApplication };
export type {
  ApplicationsAPIAddPetToApplicationBodyParams,
  ApplicationsAPIAddPetToApplicationPathParams,
  ApplicationsAPIAddPetToApplicationPayload,
  ApplicationsAPIAddPetToApplicationSearchParams,
};
