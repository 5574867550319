import { Predicate as P } from "effect";
import { useState } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

type ChannelFilterProps = FilterComponentProps & {
  metadata: {
    channels: string[];
  };
};

function getChannelFilter(
  value: string,
  factor?: WidgetFactor,
): WidgetFilter[] | Undefined {
  if (value === undefined || value === null || !factor) {
    return undefined;
  }

  return [
    {
      factor,
      operator: DynamicTableOperatorsEnum.IN,
      values: [value],
    },
  ];
}

const CHANNEL_MAPPER: Record<string, string> = {
  MLS: "MLS",
  OFF_MARKET: "Off Market",
  BULK: "Bulk",
  BUILD_TO_RENT: "Build to Rent",
};

function getDefaultValue(widget: Widget) {
  return (
    widget.filters
      .find(({ factor }) => factor.name === Factor.CHANNEL)
      ?.values?.[0].toString() || NULL
  );
}

function ChannelFilter({
  factor,
  metadata,
  updateFilters,
  widget,
}: ChannelFilterProps) {
  const [selected, setSelected] = useState<string | null>(
    getDefaultValue(widget),
  );

  function handleChange(value: string | null) {
    if (P.isNull(value)) {
      setSelected(NULL);
      updateFilters(Factor.CHANNEL, UNDEFINED);
      return;
    }
    setSelected(value);

    const filters = getChannelFilter(value, factor);
    updateFilters(Factor.CHANNEL, filters);
  }

  return (
    <Select
      label="Channel"
      data={
        metadata.channels?.map((value) => ({
          label: CHANNEL_MAPPER[value] || value,
          value: `${value}`,
        })) || []
      }
      clearable
      searchable
      placeholder="Select Channel"
      value={selected}
      onChange={handleChange}
    />
  );
}

export { ChannelFilter };
