import type { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

type LinkIfProps = {
  if: boolean;
  to: string;
  className?: string;
};

/**
 * @typedef {object} LinkIfProps
 * @property {boolean} if
 * @property {string} to
 *
 * @name LinkIf
 * @param {LinkIfProps & ReactProps} props
 * @returns {React.ReactElement}
 *
 * @deprecated move this into the `nav` package
 */
function LinkIf({
  children,
  if: _if,
  to,
  className,
}: PropsWithChildren<LinkIfProps>) {
  const content = children || to;

  if (!_if || !to) {
    return <span className={className}>{content}</span>;
  }

  return (
    <Link to={to} className={className}>
      {content}
    </Link>
  );
}

export { LinkIf };
export type { LinkIfProps };
