/**
 * HTTP Method: POST
 * Pathname: /buy/deals
 * @function addDeal
 * @memberof BuyAPI
 * @param {BuyAPIAddDealPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Deal>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  Deal,
  DealChannel,
  DealOccupancyStatus,
  DealPoolType,
} from "@ender/shared/generated/com.ender.buy.model.misc";
import type {
  PropertyHOAFeeFrequency,
  PropertyHomeType,
} from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIAddDealSearchParams = {
  token?: string | undefined;
};

type BuyAPIAddDealBodyParams = {
  assessedValue?: SerializesInto<Money> | undefined;
  buyerFirmId?: EnderId | undefined;
  channel: DealChannel;
  city: string;
  county: string;
  currentListPrice?: SerializesInto<Money> | undefined;
  dateBid?: SerializesInto<LocalDate> | undefined;
  dealName: string;
  hoaFee?: SerializesInto<Money> | undefined;
  hoaFeeFrequency?: PropertyHOAFeeFrequency | undefined;
  inPlaceRent?: SerializesInto<Money> | undefined;
  levels: number;
  listDate?: SerializesInto<LocalDate> | undefined;
  lotSqft: number;
  marketId: EnderId;
  medianIncome?: SerializesInto<Money> | undefined;
  mlsId: string;
  notes: string;
  numBaths?: number | undefined;
  numBeds?: number | undefined;
  occupancyStatus?: DealOccupancyStatus | undefined;
  originalListPrice?: SerializesInto<Money> | undefined;
  overrideWarnings?: boolean | undefined;
  parkingInfo: string;
  poolType?: DealPoolType | undefined;
  schoolRating?: string | undefined;
  sqft?: number | undefined;
  state: string;
  street: string;
  type?: PropertyHomeType | undefined;
  unit: string;
  yearBuilt: number;
  zipcode: string;
};

type BuyAPIAddDealPayload = BuyAPIAddDealSearchParams & BuyAPIAddDealBodyParams;

function addDealUnsafeEffect(payload: BuyAPIAddDealPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Deal>({
        body,
        decode: unsafeDecodeJsonResponse<Deal>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/buy/deals",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addDeal(
  payload: BuyAPIAddDealPayload,
  options?: { signal?: AbortSignal },
): Promise<Deal> {
  return F.pipe(
    payload,
    addDealUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addDeal };
export type {
  BuyAPIAddDealBodyParams,
  BuyAPIAddDealPayload,
  BuyAPIAddDealSearchParams,
};
