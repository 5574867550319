import { useForm } from "@mantine/form";
import { IconInfoCircle } from "@tabler/icons-react";
import { Option as O, Predicate as P, pipe } from "effect";
import { useState } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { Money } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { PaymentsAPI } from "@ender/shared/generated/ender.api.accounting";
import type { TenantLedgerReportLedgerEntry } from "@ender/shared/generated/ender.arch.accounting";
import { MantineSizeEnum } from "@ender/shared/types/ender-general";
import { DateInput } from "@ender/shared/ds/date-input";
import { EnderRadio, EnderRadioGroup } from "@ender/shared/ui/ender-radio";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

const ReversedPaymentTypeEnum = {
  ACCOUNTING_AND_TRANSFER: "ACCOUNTING_AND_TRANSFER",
  ACCOUNTING_ONLY: "ACCOUNTING_ONLY",
} as const;

type ReversedPaymentFormValues = {
  reversalDate: O.Option<LocalDate$.LocalDate>;
  reversalType: string | undefined;
};

type OptimizedLedgerEventAllocation = Omit<
  TenantLedgerReportLedgerEntry,
  "amount" | "effectOnCategoryBalance" | "runningBalanceForCategory"
> & {
  amount: Money;
  effectOnCategoryBalance: Money;
  runningBalanceForCategory: Money;
};

type OptimizedLedgerEvent = Pick<
  TenantLedgerReportLedgerEntry,
  | "id"
  | "ledgerEventType"
  | "specificLedgerDate"
  | "tenantLedgerEventType"
  | "isReversedCharge"
  | "isReversedCredit"
  | "canBeReallocated"
> & {
  amount: Money | null;
  displayDescription: string;
  allocations: OptimizedLedgerEventAllocation[];
};

type ReversedPaymentModalProps = {
  ledgerEntry: OptimizedLedgerEvent;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
};

function ReversedPaymentModal({
  ledgerEntry,
  closeModal,
  onSuccess,
}: ReversedPaymentModalProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { id: modelId } = ledgerEntry;

  const form = useForm<ReversedPaymentFormValues>({
    initialValues: {
      reversalDate: O.some(LocalDate$.today()),
      reversalType: undefined,
    },
    validate: {
      reversalDate: (value) => (O.isSome(value) ? null : "Reversal date is required"),
      reversalType: (value) =>
        value ? null : "You must choose a reversal type to proceed.",
    },
  });

  const confirmation = useConfirmationContext();
  const markReversedPaymentWithWarnings = withWarningHandler(
    PaymentsAPI.markReversedPayment,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );

  const onSubmit = form.onSubmit(async (values: ReversedPaymentFormValues) => {
    try {
      setIsProcessing(true);
      const response = await markReversedPaymentWithWarnings({
        moneyTransferId: modelId,
        reversalDate: pipe(
          values.reversalDate,
          O.map((date) => date.toJSON()),
          O.getOrThrow
        ),
        showOnBankRecAndTL:
          values.reversalType ===
          ReversedPaymentTypeEnum.ACCOUNTING_AND_TRANSFER,
      });

      if (P.isNotNullable(response)) {
        showSuccessNotification({ message: "Payment has been reversed." });
        await onSuccess();
        closeModal();
      }
    } catch (err) {
      fail(err);
    } finally {
      setIsProcessing(false);
    }
  });

  const accountingAndTransferLabel = (
    <Group spacing={Spacing.sm} justify={Justify.center}>
      <Text size={FontSize.md}>Ordinary Reversal</Text>
      <Tooltip label="Records that, although money made it to your bank account, it was later withdrawn from the bank account.">
        <IconInfoCircle />
      </Tooltip>
    </Group>
  );
  const accountingOnlyLabel = (
    <Group spacing={Spacing.sm} justify={Justify.center}>
      <Text size={FontSize.md}>Absolute Reversal</Text>
      <Tooltip label="Indicates that money never made it to your bank account.  So there will be neither a deposit nor a withdrawal to reconcile in banking. However, we will still record GL transactions for the payment and reversal.">
        <IconInfoCircle size={20} className="flex-middle" />
      </Tooltip>
    </Group>
  );

  return (
    <form
      onSubmit={onSubmit}
      className="flex-column flex-16"
      style={{ width: "100%" }}>
      <H1>Reverse Payment</H1>
      <Text size={FontSize.sm}>
        Processing a reversal will create offsetting entries for this payment.
      </Text>
      <EnderRadioGroup {...form.getInputProps("reversalType")}>
        <Stack>
          <EnderRadio
            label={accountingAndTransferLabel}
            size={MantineSizeEnum.SM}
            value={ReversedPaymentTypeEnum.ACCOUNTING_AND_TRANSFER}
          />
          <EnderRadio
            label={accountingOnlyLabel}
            size={MantineSizeEnum.SM}
            value={ReversedPaymentTypeEnum.ACCOUNTING_ONLY}
          />
        </Stack>
      </EnderRadioGroup>
      <DateInput
        label="Reversal Date"
        {...form.getInputProps("reversalDate")}
      />
      <Group justify={Justify.end}>
        <Button type="submit" disabled={isProcessing}>
          Submit
        </Button>
      </Group>
    </form>
  );
}

export { ReversedPaymentModal };
