/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/credits
 * @function createLeaseCredit
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPICreateLeaseCreditPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPICreateLeaseCreditPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPICreateLeaseCreditSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPICreateLeaseCreditBodyParams = {
  amount: SerializesInto<Money>;
  autoallocate?: boolean | undefined;
  debitCategoryId: EnderId;
  description: string;
  ledgerDate: SerializesInto<LocalDate>;
  offsetCategoryId?: EnderId | undefined;
  periodId?: EnderId | undefined;
  requestId: string;
};

type TenantLedgerAPICreateLeaseCreditPayload =
  TenantLedgerAPICreateLeaseCreditPathParams &
    TenantLedgerAPICreateLeaseCreditSearchParams &
    TenantLedgerAPICreateLeaseCreditBodyParams;

function createLeaseCreditUnsafeEffect(
  payload: TenantLedgerAPICreateLeaseCreditPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/credits`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createLeaseCredit(
  payload: TenantLedgerAPICreateLeaseCreditPayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice> {
  return F.pipe(
    payload,
    createLeaseCreditUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createLeaseCredit };
export type {
  TenantLedgerAPICreateLeaseCreditBodyParams,
  TenantLedgerAPICreateLeaseCreditPathParams,
  TenantLedgerAPICreateLeaseCreditPayload,
  TenantLedgerAPICreateLeaseCreditSearchParams,
};
