import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Order, Predicate as P } from "effect";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import type { EnderId, LocalDate, Money } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";

const BankReconciliationConfigureFormSchema = Schema.Struct({
  reconciliationStartDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => {
        return O.isSome(input) && input.value.isBefore(LocalDate$.today());
      },
      {
        message: () => "Date must be before today",
      },
    ),
  ),
  reconciliationStartingBalance: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Some<Money$.Money> => {
        return O.exists(
          input,
          Order.greaterThanOrEqualTo(Money$.Order)(Money$.zero()),
        );
      },
      { message: () => "Amount must be greater than or equal to $0.00" },
    ),
  ),
});

type BankReconciliationConfigureFormValues = Schema.Schema.Type<
  typeof BankReconciliationConfigureFormSchema
>;

type BankReconciliationConfigureModalProps = {
  bankAccountId: EnderId;
  closeModal: () => void;
  onSuccess: () => void;
  reconciliationStartingBalance: Money;
  startDate: LocalDate;
};

function BankReconciliationConfigureModal({
  bankAccountId,
  closeModal,
  onSuccess,
  reconciliationStartingBalance,
  startDate,
}: BankReconciliationConfigureModalProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      reconciliationStartDate: LocalDate$.parse(startDate),
      reconciliationStartingBalance: Money$.parse(
        reconciliationStartingBalance,
      ),
    },
    schema: BankReconciliationConfigureFormSchema,
  });

  const { mutateAsync: updateBank } = useMutation({
    mutationFn: BankingAPI.updateBank,
    mutationKey: ["BankingAPI.updateBank"] as const,
  });

  async function saveAccount(values: BankReconciliationConfigureFormValues) {
    const { reconciliationStartDate, reconciliationStartingBalance } = values;

    if (P.isNull(reconciliationStartingBalance)) {
      return;
    }

    await updateBank({
      bankAccountId,
      reconciliationStartDate: O.getOrThrow(reconciliationStartDate).toJSON(),
      reconciliationStartingBalance: O.getOrThrow(
        reconciliationStartingBalance,
      ).toJSON(),
    });
    onSuccess();
    closeModal();
  }

  return (
    <Form onSubmit={saveAccount} form={form}>
      <Stack>
        <H2>Configure Bank Reconciliation Info</H2>
        <FormDateInput
          name="reconciliationStartDate"
          label="Reconciliation Start Date"
          form={form}
        />
        <FormMoneyInput
          name="reconciliationStartingBalance"
          label="Reconciliation Starting Balance"
          form={form}
        />
        <Group justify={Justify.end}>
          <Button type="submit">Confirm</Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { BankReconciliationConfigureModal };
