import { Predicate as P } from "effect";
import { useState } from "react";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

function getSqftFilters(
  value?: string,
  factor?: WidgetFactor,
): WidgetFilter[] | Undefined {
  if (!value || value === ":" || !factor) {
    return undefined;
  }

  const filters: WidgetFilter[] = [];
  const [min, max] = value.split(":");

  if (min) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
      values: [min],
    });
  }

  if (max) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
      values: [max],
    });
  }

  return filters;
}

function getDefaultValue(widget: Widget) {
  const min =
    widget.filters.find(
      ({ factor, operator }) =>
        factor.name === Factor.SQFT &&
        operator === DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
    )?.values?.[0] || "";
  const max =
    widget.filters.find(
      ({ factor, operator }) =>
        factor.name === Factor.SQFT &&
        operator === DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
    )?.values?.[0] || "";

  return `${min}:${max}`;
}

function SqftFilter({ updateFilters, widget, factor }: FilterComponentProps) {
  const [selected, setSelected] = useState<string | Null>(
    getDefaultValue(widget),
  );

  if (!widget) {
    return null;
  }

  function handleChange(value: string | Null) {
    if (P.isNull(value)) {
      setSelected(NULL);
      updateFilters(Factor.SQFT, UNDEFINED);
      return;
    }
    setSelected(value);

    const filters = getSqftFilters(value.toString(), factor);
    updateFilters(Factor.SQFT, filters);
  }

  return (
    <Select
      label="Square Feet"
      data={[
        { label: "< 800 sqft", value: ":800" },
        { label: "800 - 1500 sqft", value: "800:1500" },
        { label: "1500 - 2000 sqft", value: "1500:2000" },
        { label: "2000 - 2500 sqft", value: "2000:2500" },
        { label: "> 2500 sqft", value: "2500:" },
      ]}
      clearable
      placeholder="Select Sqft"
      value={selected}
      onChange={handleChange}
    />
  );
}

export { SqftFilter };
