import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { IconEyeOff } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import { useContext, useEffect, useMemo, useState } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  BankingAPI,
  GeneralLedgerAPI,
} from "@ender/shared/generated/ender.api.accounting";
import type {
  BankAccountAccountStatus,
  Party,
} from "@ender/shared/generated/ender.model.payments";
import {
  BankAccountAccountStatusEnum,
  PartyEnum,
} from "@ender/shared/generated/ender.model.payments";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { cast } from "@ender/shared/types/cast";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";
import { initPlaidCredentials } from "@ender/shared/utils/plaid";

const validOwnerTypes: Party[] = [PartyEnum.FIRM, PartyEnum.PROPERTY_MANAGER];

type BankAccountConfigureFormProps = {
  bankAccountId: EnderId;
  /**
   * @deprecated we should be handling close behavior in the `onSuccess` callback. Items should not have control over closing themselves.
   */
  closeModal?: () => void;
  hasBankAccountRevealOption?: boolean;
  canViewBankAccountSensitiveFields?: boolean;
  /** we should probably just replace this with `cashCategoryId` and alias it to 'initial' if needed. */
  initialCashCategoryId: EnderId;
  initialName: string;

  /**
   * @todo strongly type this
   */
  lobStatus: unknown;
  /**
   * @todo figure out whether this should be named `dwolla` if it does not use the dwolla status enum
   */
  dwollaStatus: BankAccountAccountStatus;
  mask: string;
  /**
   * invoked when the bank account is successfully saved/updated
   */
  onSuccess?: () => void;
  ownerId: EnderId;
  ownerType: Party;
  routingNumber: string;
  hasPlaid: boolean;
};

const BankAccountConfigureFormSchema = Schema.Struct({
  bankAccountNumber: Schema.String,
  cashCategoryId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  name: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Account Name is required" }),
  ),
  routingNumber: Schema.String,
});

type BankAccountConfigureFormValues = Schema.Schema.Type<
  typeof BankAccountConfigureFormSchema
>;

type TransformedBankAccountConfigureFormValues = {
  accountNumber?: string;
  cashCategoryId?: EnderId;
} & Omit<BankAccountConfigureFormValues, "cashCategoryId">;

function BankAccountConfigureForm({
  bankAccountId,
  closeModal = F.constVoid,
  hasBankAccountRevealOption,
  canViewBankAccountSensitiveFields,
  initialCashCategoryId,
  initialName,
  lobStatus,
  mask,
  onSuccess = F.constVoid,
  ownerId,
  ownerType,
  routingNumber,
  dwollaStatus,
  hasPlaid,
}: BankAccountConfigureFormProps) {
  const initialBankAccountNumberForDisplay = `••••${mask}`;
  const { hasPermissions, user } = useContext(UserContext);
  const isVendorAndCantViewAccountNumber =
    ownerType === ModelTypeEnum.VENDOR && !canViewBankAccountSensitiveFields;
  const canViewFullBankAccountNumber =
    hasPermissions(FunctionalPermissionEnum.VIEW_BANK_ACCOUNT_NUMBERS) &&
    (validOwnerTypes.includes(ownerType) ||
      (ownerType === ModelTypeEnum.VENDOR &&
        canViewBankAccountSensitiveFields));

  const tooltipMessage =
    isVendorAndCantViewAccountNumber &&
    hasPermissions(FunctionalPermissionEnum.VIEW_BANK_ACCOUNT_NUMBERS)
      ? "Cannot view account information uploaded by vendor."
      : "This account does not have permission to view bank account numbers.";

  const confirmation = useConfirmationContext();
  const [loading, setLoading] = useState(false);

  /**
   * full bank account number (may be undefined if the user does not have permission to view it)
   */
  const { data: fullBankAccountNumber } = useQuery({
    queryKey: [
      "BankingAPI.getBankAccountSensitiveFields",
      bankAccountId,
    ] as const,
    queryFn: ({ signal }) =>
      BankingAPI.getBankAccountSensitiveFields({ bankAccountId }, { signal }),
    enabled: canViewFullBankAccountNumber,
    select: (data) => data.accountNumber,
  });
  const initialValues = useMemo(
    () => ({
      bankAccountNumber: initialBankAccountNumberForDisplay,
      cashCategoryId: O.fromNullable(initialCashCategoryId),
      name: initialName,
      routingNumber,
    }),
    [
      initialCashCategoryId,
      initialName,
      routingNumber,
      initialBankAccountNumberForDisplay,
    ],
  );

  const form = useForm<BankAccountConfigureFormValues>({
    defaultValues: initialValues,
    mode: "onSubmit",
    resolver: effectTsResolver(BankAccountConfigureFormSchema),
  });

  const bankAccountNumberIsDirty =
    !!form.formState.dirtyFields.bankAccountNumber;
  const needsConfirmation =
    dwollaStatus === BankAccountAccountStatusEnum.VERIFIED &&
    (bankAccountNumberIsDirty || !!form.formState.dirtyFields.routingNumber);

  const { data: canModifyGLAccount, isLoading: transactionsLoading } = useQuery(
    {
      queryKey: ["BankingAPI.getTransactions", bankAccountId] as const,
      queryFn: ({ signal }) =>
        BankingAPI.getTransactions(
          {
            bankAccountId,
            inclusiveEndDate: cast(EnderDate.today.toLocalISOString()),
            propertyIds: [],
            startDate: cast(EnderDate.today.toLocalISOString()),
          },
          { signal },
        ),
      enabled: cast<string[]>([
        ModelTypeEnum.PROPERTY_MANAGER,
        ModelTypeEnum.FIRM,
      ]).includes(ownerType),
      select: (data) => data?.canModifyGLAccount ?? false,
    },
  );

  const { data: categories = [], isLoading: fetchingCategories } = useQuery({
    queryKey: ["GeneralLedgerAPI.getTxCategories"] as const,
    queryFn: ({ signal }) =>
      GeneralLedgerAPI.getTxCategories(
        {
          categoryTypes: ["ASSET"],
          includeBankAccountCategories: true,
          keyword: "",
        },
        { signal },
      ),
    enabled: user.isPM,
  });

  /**
   * If the full bank account # comes back, update the form field with it
   * and reset the form dirty state.
   */
  useEffect(() => {
    if (canViewFullBankAccountNumber && fullBankAccountNumber) {
      form.setValue("bankAccountNumber", fullBankAccountNumber);
      // reset the dirty state if the full value is available
      form.reset({
        ...initialValues,
        bankAccountNumber: fullBankAccountNumber,
      });
    }
    // TODO figure out the proper dependency here. using form or setFieldValue causes the field to reset on every input change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, canViewFullBankAccountNumber, fullBankAccountNumber]);

  async function saveAccount(values: BankAccountConfigureFormValues) {
    const transformedValues: TransformedBankAccountConfigureFormValues = {
      accountNumber: values.bankAccountNumber,
      bankAccountNumber: values.bankAccountNumber,
      cashCategoryId: O.getOrUndefined(values.cashCategoryId),
      name: values.name,
      routingNumber: values.routingNumber,
    };

    setLoading(true);
    try {
      if (needsConfirmation) {
        await confirmation({
          content:
            "The bank account and routing number you entered do not match our records for this account. Are you sure you would like to proceed?",
          title: "Confirm",
        });
      }

      if (!canViewFullBankAccountNumber || !bankAccountNumberIsDirty) {
        delete transformedValues.accountNumber;
      }

      await BankingAPI.updateBank({ bankAccountId, ...transformedValues });
      onSuccess();
      closeModal();
    } catch (err) {
      fail(err);
    } finally {
      setLoading(false);
    }
  }

  function linkToPlaid() {
    initPlaidCredentials({
      bankAccountId: bankAccountId,
      customerId: ownerId,
      customerType: ownerType,
      onSuccess: () => {
        onSuccess();
        closeModal();
      },
      onlyLinkToPlaid: true,
    });
  }

  // ENDER-13062: I opted to preserve the legacy disabled logic here in case it is needed in the future.
  // const areNumberFieldsDisabled = loading || !canViewFullBankAccountNumber || ownerType === ModelTypeEnum.VENDOR;
  const areNumberFieldsDisabled = true;

  return (
    <Form form={form} onSubmit={saveAccount}>
      <Stack>
        <FormTextInput
          label="Account Name"
          name="name"
          disabled={loading}
          form={form}
        />
        {user.isPM &&
          (hasBankAccountRevealOption || canViewBankAccountSensitiveFields) && (
            <>
              <FormTextInput
                label={
                  <Group spacing={Spacing.xs}>
                    <span>Bank Account Number</span>
                    {(isVendorAndCantViewAccountNumber ||
                      !canViewFullBankAccountNumber) && (
                      <Tooltip label={tooltipMessage}>
                        <IconEyeOff />
                      </Tooltip>
                    )}
                  </Group>
                }
                name="bankAccountNumber"
                data-private
                disabled={areNumberFieldsDisabled}
                form={form}
              />

              <FormTextInput
                label="Routing Number"
                name="routingNumber"
                disabled={areNumberFieldsDisabled}
                form={form}
              />
            </>
          )}

        {/* GL Category only makes sense for a Firm-owned bank account.  If this is a vendor 
        bank account (for example) it does not make sense to select a cash category for it. */}
        {user.isPM && ownerType === ModelTypeEnum.FIRM && (
          <>
            <Skeleton visible={fetchingCategories}>
              <FormSelect<EnderId, typeof form>
                label="GL Category"
                name="cashCategoryId"
                disabled={loading || transactionsLoading || !canModifyGLAccount}
                placeholder="Choose Category"
                data={categories.map((category) => ({
                  label: category.name,
                  value: category.id,
                }))}
                form={form}
              />
            </Skeleton>
            {lobStatus === "VERIFIED" && (
              <div>This account is set up to send checks via postal mail.</div>
            )}
          </>
        )}

        <Group justify={Justify.end}>
          {/* TODO <EnderButton>Link Plaid</EnderButton> */}
          <>
            {user.isPM && !hasPlaid && (
              <Button
                variant={ButtonVariant.outlined}
                disabled={loading}
                onClick={linkToPlaid}>
                Link to Plaid
              </Button>
            )}
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </>
        </Group>
      </Stack>
    </Form>
  );
}

export { BankAccountConfigureForm };
