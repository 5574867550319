/**
 * HTTP Method: POST
 * Pathname: /markets/{marketId}
 * @function updateMarket
 * @memberof MarketsAPI
 * @param {MarketsAPIUpdateMarketPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Percent, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MarketsAPIListingUrl } from "../markets-api-listing-url";
import type { MarketsAPIOperatingAccountConfiguration } from "../markets-api-operating-account-configuration";

type MarketsAPIUpdateMarketPathParams = {
  marketId: EnderId;
};

type MarketsAPIUpdateMarketSearchParams = {
  token?: string | undefined;
};

type MarketsAPIUpdateMarketBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  cbsaCodes?: any | undefined;
  code?: string | undefined;
  description?: string | undefined;
  hideFromListings?: boolean | undefined;
  listingDisclaimer?: string | undefined;
  listingDisplayName?: string | undefined;
  listingUrls?: MarketsAPIListingUrl[] | undefined;
  name?: string | undefined;
  operatingAccountConfigurations?:
    | MarketsAPIOperatingAccountConfiguration[]
    | undefined;
  taxRate?: SerializesInto<Percent> | undefined;
};

type MarketsAPIUpdateMarketPayload = MarketsAPIUpdateMarketPathParams &
  MarketsAPIUpdateMarketSearchParams &
  MarketsAPIUpdateMarketBodyParams;

function updateMarketUnsafeEffect(payload: MarketsAPIUpdateMarketPayload) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/markets/${marketId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateMarket(
  payload: MarketsAPIUpdateMarketPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateMarketUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateMarket };
export type {
  MarketsAPIUpdateMarketBodyParams,
  MarketsAPIUpdateMarketPathParams,
  MarketsAPIUpdateMarketPayload,
  MarketsAPIUpdateMarketSearchParams,
};
