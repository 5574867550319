// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { IconCheck, IconChevronDown, IconX } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import { useCombobox } from "downshift";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  String as S,
  pipe,
} from "effect";
import type { FocusEvent, ForwardedRef, ReactNode, Ref } from "react";
import { forwardRef, useEffect, useId, useState } from "react";

import { NULL } from "@ender/shared/constants/general";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { Color } from "@ender/shared/utils/theming";

import { ActionIcon } from "../../../action-icon/src";
import { ButtonVariant } from "../../../button/src";
import type { InputBaseProps } from "../../../input/src";
import { InputSize, InputWrapper } from "../../../input/src";
import { isEmptyReactNode } from "../../../utils";





const InputVariantGenerator = cva(
  [
    "w-full text-sm/standard placeholder:text-slate-300 outline-none focus:outline-none focus-visible:outline-none",
    "text-slate-900 disabled:text-gray-300 px-4 bg-transparent",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      size: "sm",
      textAlign: "left",
    },
    variants: {
      size: {
        [InputSize.lg]: "py-3.5",
        [InputSize.md]: "py-[calc(0.625rem-1px)]",
        [InputSize.sm]: "py-[calc(0.375rem-1px)]",
      },
      textAlign: {
        left: "",
        right: "text-right",
      },
    },
  },
);

const InputContainerVariantGenerator = cva(
  ["group/popover", "relative grow flex rounded border"],
  {
    defaultVariants: {
      borderless: false,
    },
    variants: {
      borderless: {
        false: [
          "border-slate-200",
          "has-[:disabled]:bg-gray-50 has-[:disabled]:border-gray-100",
          "has-[:focus]:border-primary-500 has-[:enabled]:has-[[aria-invalid=true]]:border-red-500",
        ],
        true: "border-transparent",
      },
    },
  },
);

/**
 * this represents the default set of allowed values for the Select component.
 */
type SelectValue = string | number | boolean;

/**
 * the Options provided to the searchable Select component.
 * Values must be unique and comparable
 */
type SelectOption<V extends SelectValue = SelectValue, M = unknown> = {
  /**
   * the value that will be selected, and should be used to control
   * the state of the Select.
   */
  value: V;
  /**
   * required to be a String, as strings are searchable
   */
  label: string;
  /**
   * any additional data that should be associated with the item.
   * Will be provided in the `onSelect` callback as well as in the filter function.
   */
  meta?: M;
};

function generateSelectData<T extends SelectValue>(
  input: readonly T[] | T[],
): SelectOption<T>[] {
  // Map over the array and return data in the required format
  return input.map((item) => ({
    label: item.toString(),
    value: item,
  }));
}

type FilterFn<V extends SelectValue = SelectValue, M = unknown> = (
  item: SelectOption<V, M>,
  keyword: string,
) => boolean;

const defaultFilterFn: FilterFn = (item: SelectOption, keyword: string) =>
  item.label.toLowerCase().includes(keyword.toLowerCase());

type SelectProps<V extends SelectValue, M = unknown> = {
  value: O.Option<V>;
  /**
   * the callback invoked when an item is selected from the dropdown
   */
  onChange: (value: O.Option<V>, item: O.Option<SelectOption<V, M>>) => void;
  /**
   * the callback invoked when the user types in the input, and _only_ then.
   * Not invoked when the keyword is set by the user selecting an item from the dropdown.
   */
  onKeywordChange?: (keyword: string) => void;
  /**
   * how items should be filtered from the dropdown list.
   * defaults to a string partial match against the label of the item.
   */
  filterFn?: FilterFn<V, M>;
  data: SelectOption<V, M>[];
  /**
   * Whether the input can be reset to an empty state. If false, the input will always have a value,
   * and blurring the input will reset the value to the previous value.
   */
  clearable?: boolean;
} & Omit<InputBaseProps, "rightSection">;

const Select = forwardRef(function Select<V extends SelectValue, M = unknown>(
  props: SelectProps<V, M>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    borderless = false,
    clearable = false,
    data,
    description,
    disabled = false,
    error,
    label,
    leftSection,
    onChange,
    onKeywordChange = F.constVoid,
    placeholder = "",
    role = "combobox",
    size = InputSize.md,
    textAlign,
    value,
    filterFn = defaultFilterFn,
    name,
  } = props;

  // using null because combobox uses null to represent no value
  const valueOrNull = O.getOrNull(value);

  /**
   * a reference to the previous value of the input.
   * Used to reset the input value when the user closes the popover without selecting an item.
   */
  const selectedItemRef = useRefLatest(
    A.findFirst(
      data,
      (option: SelectOption<V, M>) => option.value === valueOrNull,
    ),
  );

  const [filteredItems, setFilteredItems] = useState(data);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    openMenu,
    setInputValue,
    selectItem,
  } = useCombobox({
    defaultHighlightedIndex: O.getOrUndefined(
      A.findFirstIndex(
        data,
        (option: SelectOption<V, M>) => option.value === valueOrNull,
      ),
    ),
    initialInputValue: O.match(selectedItemRef.current, {
      onNone: () => "",
      onSome: (item: SelectOption<V, M>) => item.label,
    }),
    itemToString(item) {
      return item ? item.label : "";
    },
    items: filteredItems,
    onInputValueChange({ inputValue, type }) {
      if (type === useCombobox.stateChangeTypes.InputChange) {
        //only filter on typing. Don't change filter when you select an item
        //or when the state changes for some other reason (clearing the input, etc.)
        setFilteredItems(data.filter((item) => filterFn(item, inputValue)));
        onKeywordChange(inputValue);
      }
    },
    onIsOpenChange({ isOpen }) {
      if (!isOpen) {
        //clear filters when you close. This means when you open it again, you see all the items
        setFilteredItems([...data]);
      }
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem?.value !== valueOrNull) {
        onChange(
          O.fromNullable(selectedItem?.value),
          O.fromNullable(selectedItem),
        );
      }
    },
    selectedItem: O.getOrNull(selectedItemRef.current),
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return { ...changes, inputValue: state.selectedItem?.label ?? "" };
        default:
          return changes;
      }
    },
  });

  /**
   * set the search text if the value changes from outside
   */
  useEffect(() => {
    pipe(
      selectedItemRef.current,
      O.map((item: SelectOption<V, M>) => item.label),
      O.getOrElse(() => ""),
      setInputValue,
    );
  }, [valueOrNull, selectedItemRef, setInputValue]);

  /**
   * checks that the `data` prop does not contain duplicates, and logs an error if it does.
   * sets the Data prop when it changes.
   *
   */
  useEffect(() => {
    if (new Set(data.map((d) => d.value)).size !== data.length) {
      console.warn(
        "Select input data prop contains duplicate values. Please make sure each value is unique.",
      );
    }
    setFilteredItems(data);
  }, [data, selectedItemRef, setInputValue]);

  const idBase = useId();
  const labelId = `${idBase}-label`;
  const descriptionId = `${idBase}-description`;
  const errorId = `${idBase}-error`;
  const describedBy = `${!isEmptyReactNode(error) ? errorId : ""} ${!isEmptyReactNode(description) ? descriptionId : ""}`;

  /**
   * if clearable, the input can be cleared and won't reset on blur.
   * otherwise, the 'clear' action only applies to the typed text, not the stored value
   *
   * for the 'clearable' case, observe what happens if you backspace the input to empty-
   * you are still presented with the 'clear' action. This is because the text is empty,
   * but the stored value is not.
   */
  const showX = clearable
    ? P.isNotNullable(valueOrNull) || S.isNonEmpty(inputValue)
    : S.isNonEmpty(inputValue);

  const closeButtonId = useId();

  const attributes = {
    borderless,
    leftSection: !isEmptyReactNode(leftSection),
    size,
    textAlign,
  };
  return (
    <Popover open={!disabled && isOpen}>
      <InputWrapper
        label={label}
        description={description}
        error={error}
        inputId={idBase}
        labelId={labelId}
        descriptionId={descriptionId}
        errorId={errorId}>
        <PopoverTrigger asChild>
          <div className={InputContainerVariantGenerator({ borderless })}>
            {!isEmptyReactNode(leftSection) && (
              <div className="pl-3 -mr-1 flex items-center text-slate-500 text-base">
                {leftSection}
              </div>
            )}
            <input
              {...getInputProps({
                "aria-describedby": describedBy,
                "aria-invalid": !isEmptyReactNode(error),
                "aria-label": isEmptyReactNode(label) ? placeholder : undefined,
                "aria-labelledby": labelId,
                "aria-owns": closeButtonId,
                className: InputVariantGenerator(attributes),
                disabled,
                id: idBase,
                name,
                onFocus: (e: FocusEvent<HTMLInputElement>) =>
                  e.currentTarget.select(),
                placeholder,
                ref,
                role,
              })}
            />
            <div className="-ml-1 pr-3 flex items-center text-slate-500 text-base">
              {showX ? (
                <ActionIcon
                  variant={ButtonVariant.transparent}
                  disabled={disabled}
                  color={Color.slate}
                  label="Clear"
                  onClick={() => {
                    openMenu();
                    setInputValue("");
                    clearable && selectItem(NULL);
                  }}
                  //@ts-expect-error this is a 'secret' prop of ActionIcon
                  id={closeButtonId}>
                  <IconX />
                </ActionIcon>
              ) : (
                <IconChevronDown
                  className={clsx(
                    "mr-1.5 text-lg pointer-events-none group-data-[state=open]/popover:rotate-180",
                    {
                      "rotate-180": isOpen,
                    },
                  )}
                />
              )}
            </div>
          </div>
        </PopoverTrigger>
      </InputWrapper>
      <PopoverPortal>
        <PopoverContent
          className={clsx(
            "w-[--radix-popover-trigger-width] z-10",
            "bg-white will-change-transform border border-primary-300 rounded max-h-80 overflow-auto",
            "data-[state=open]:block data-[state=closed]:hidden",
          )}
          sideOffset={4}
          onOpenAutoFocus={(event) => event.preventDefault()}
          onCloseAutoFocus={(event) => event.preventDefault()}
          align="start"
          /*suppressRefError needs to happen because the popover can be unmounted, which annoys Downshift*/
          {...getMenuProps(
            { "aria-labelledby": labelId },
            { suppressRefError: true },
          )}>
          {filteredItems.map((item, index) => (
            <li
              key={`${item.value}`}
              //this is the value that is used to fuzzy match the search input
              value={item.label}
              className={clsx(
                "pl-3 pr-4 py-2.5 text-sm outline-none flex gap-2.5",
                "aria-selected:bg-primary-100 aria-selected:text-primary-500",
              )}
              {...getItemProps({
                "aria-selected": index === highlightedIndex,
                index,
                item,
                onTouchStart: () => selectItem(item),
              })}>
              {item.value === valueOrNull && (
                <IconCheck className="text-base h-5" />
              )}
              {item.label}
            </li>
          ))}
          {A.isEmptyArray(filteredItems) && (
            <li className="pl-3 pr-4 py-2.5 text-sm list-none text-slate-300">
              No matches.
            </li>
          )}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}) as <V extends SelectValue, M = unknown>(
  // eslint-disable-next-line no-use-before-define
  props: SelectProps<V, M> & { ref?: Ref<HTMLInputElement> },
) => ReactNode;

export { Select, generateSelectData };

export type { SelectOption, SelectProps, SelectValue };
