import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import { useCallback, useContext } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { UserContext } from "@ender/shared/contexts/user";
import { Money$, MoneyFormSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const MarketRentFormSchema = Schema.Struct({
  marketRent: MoneyFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Amount is required" }),
    Schema.filter(O.exists<Money$.Money>(Money$.isPositive), {
      message: () => "Amount must be greater than $0.00",
    }),
  ),
});
type MarketRentFormOutput = Schema.Schema.Type<typeof MarketRentFormSchema>;

type EditMarketRentFormProps = {
  unit: Pick<UnitSerializerUnitResponse, "id" | "marketRent">;
  onCancel?: () => void;
  onSuccess?: () => void;
};

function EditMarketRentForm(props: EditMarketRentFormProps) {
  const { unit, onCancel, onSuccess = F.constVoid } = props;

  const { hasPermissions } = useContext(UserContext);
  /**
   * locks the editability of the form behind this permission.
   * Is an extra measure of security, the first being to disable the button which even allows for this form
   * to be displayed
   */
  const canEditMarketRent = hasPermissions(
    FunctionalPermissionEnum.SET_MARKET_RENT,
  );

  const form = useEffectSchemaForm({
    defaultValues: {
      marketRent: Money$.parse(unit?.marketRent),
    },
    schema: MarketRentFormSchema,
  });

  const { mutateAsync: updateUnit, isLoading } = useMutation({
    mutationFn: UnitsAPI.updateUnit,
    mutationKey: ["UnitsAPI.updateUnit"] as const,
  });

  const handleSubmit = useCallback(
    async (values: MarketRentFormOutput) => {
      const { marketRent } = values;
      if (O.isNone(marketRent) || P.isNullable(unit) || !canEditMarketRent) {
        return;
      }

      await updateUnit({
        marketRent: pipe(
          marketRent,
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
        unitId: unit.id,
      });
      showSuccessNotification({ message: "Market rent successfully updated" });
      onSuccess();
    },
    [canEditMarketRent, onSuccess, unit, updateUnit],
  );

  const formMarketRent = form.watch("marketRent");

  // checking formState.dirtyFields.marketRent doesn't work for normalized Money objects
  const isMarketRentUnchanged = O.getEquivalence(Money$.Equivalence)(
    Money$.parse(unit.marketRent),
    formMarketRent,
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormMoneyInput
          form={form}
          name="marketRent"
          label="Market Rent"
          disabled={!canEditMarketRent}
        />
        <Group justify={Justify.end} align={Align.center}>
          <Button
            variant={ButtonVariant.transparent}
            onClick={onCancel}
            loading={isLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            loading={isLoading}
            disabled={
              P.isNullable(unit) ||
              O.isNone(formMarketRent) ||
              isMarketRentUnchanged
            }>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditMarketRentForm };
