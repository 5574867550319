import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DwollaClientBusinessTypeValues = [
  "CORPORATION",
  "LLC",
  "PARTNERSHIP",
] as const;

const DwollaClientBusinessTypeEffectSchema = Schema.Literal(
  ...DwollaClientBusinessTypeValues,
);

type DwollaClientBusinessType = Schema.Schema.Type<
  typeof DwollaClientBusinessTypeEffectSchema
>;

const DwollaClientBusinessTypeEnum = castEnum(
  DwollaClientBusinessTypeEffectSchema,
);

function randomDwollaClientBusinessType(): DwollaClientBusinessType {
  return rand(DwollaClientBusinessTypeValues);
}

export {
  DwollaClientBusinessTypeEffectSchema,
  DwollaClientBusinessTypeEnum,
  DwollaClientBusinessTypeValues,
  randomDwollaClientBusinessType,
};
export type { DwollaClientBusinessType };
