/**
 * HTTP Method: POST
 * Pathname: /middleLayer/getRecurringGLJournalEntryDetails
 * @function getRecurringGLJournalEntryDetails
 * @memberof AccountingMiddleLayerAPI
 * @param {AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetRecurringGLJournalEntryDetailsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsSearchParams = {
  token?: string | undefined;
};

type AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsBodyParams = {
  recurringGLJournalEntryId: EnderId;
};

type AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsPayload =
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsSearchParams &
    AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsBodyParams;

function getRecurringGLJournalEntryDetailsUnsafeEffect(
  payload: AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetRecurringGLJournalEntryDetailsResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<GetRecurringGLJournalEntryDetailsResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/getRecurringGLJournalEntryDetails",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRecurringGLJournalEntryDetails(
  payload: AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetRecurringGLJournalEntryDetailsResponse> {
  return F.pipe(
    payload,
    getRecurringGLJournalEntryDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRecurringGLJournalEntryDetails };
export type {
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsBodyParams,
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsPayload,
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryDetailsSearchParams,
};
