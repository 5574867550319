import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";

import styles from "./tos-form.module.css";

type PMCompany = "GPM" | "MP" | "EG";

const termsByCompany: Record<
  PMCompany,
  { tosStart?: ReactNode; tosPoints?: ReactNode[]; tosEnd?: ReactNode }
> = {
  GPM: {
    tosStart: `PLEASE READ ALL APPLICATION TERMS AND CONDITIONS BEFORE SUBMITTING AN APPLICATION

  The following Application Agreement will be signed by all applicants prior to signing a lease contract. While some of the information below may not yet be applicable to your situation, there are some provisions that may become applicable prior to signing a lease contract. In order to continue with this online application, you’ll need to review the Application Agreement carefully and acknowledge that you accept its terms.\n`,
    tosPoints: [
      "Applications expire 30 days after being submitted.",
      "Each adult, 18 years old or older, who will live in the unit must complete a separate application.",
      "This Agency does not discriminate and will not evaluate rental applications on the basis of race, color, religion, familial status, handicap, sexual preference, national origin or ancestry.",
      "This application is preliminary only and does not obligate the Owner or the Agency to execute a lease or deliver possession of the Property.",
      "We reserve the right to deny your application. Decisions on denied applications are final and may not be appealed.",
      "Please be aware that the minimum credit score for this apartment is 650.",
      "No collection accounts for utilities and clean rental history are required.",
      "An Application will not be considered “completed” and will not be processed until all of the above have been provided to us.",
    ],
    tosEnd:
      "In addition to this rental application, you will also be required to provide a copy of a valid form of government issued identification and proof of income.",
  },
  MP: {
    tosStart: `Thank you for your interest in applying! PLEASE READ ALL APPLICATION TERMS AND CONDITIONS BEFORE SUBMITTING AN APPLICATION:
    
    The following Application Agreement will be signed by all applicants prior to signing a lease contract. While some of the information below may not yet be applicable to your situation, there are some provisions that may become applicable prior to signing a lease contract. In order to continue with this online application, you’ll need to review the Application Agreement carefully and acknowledge that you accept its terms.`,
    tosPoints: [
      "Anyone over the age of 18 that will be living in the unit must submit an application",
      "Monthly gross income must equal 3 times the amount of the rent",
      "Must have a 600+ credit score or higher, no judgments, evictions or collections",
      "Clean criminal background",
    ],
  },
  EG: {
    tosStart:
      "The following rental application pages contain various requests for information that must be submitted in order for your application to be processed. Your application will not be processed until all information requested on the application has been provided (including, but not limited to, providing required copies of state or federal identification, pay stubs and/or any other necessary documents or information).\n\nBefore continuing with your application, please carefully review the following information: \n",
    tosPoints: [
      <p key="0">
        Review the rental screening/acceptance criteria guidelines to ensure you
        meet the necessary qualifications. The rental screening/acceptance
        criteria guidelines can be found at{" "}
        <a href="https://www.evergreenLive.com/requirements/" target="_blank">
          <Text color="primary-500">www.EvergreenLive.com/requirements/</Text>
        </a>
        .
      </p>,
      <div key="1">
        <b>Credit Check:</b>
        <ul>
          <li>
            We conduct a credit check to evaluate your creditworthiness. We are
            looking for a responsible credit history and the ability to meet
            your financial obligations.
          </li>
        </ul>
      </div>,
      <div key="2">
        <b>*Background Check:</b>
        <ul>
          <li>
            We conduct a criminal background check. Please see below for further
            details.
          </li>
        </ul>
      </div>,
      <div key="3">
        <b>Rental History:</b>
        <ul>
          <li>
            We will contact your previous landlords to verify your history of
            paying on time.
          </li>
        </ul>
      </div>,
      <div key="4">
        <b>Income Requirements:</b>
        <ul>
          <li>
            You must have a verifiable source of income that is at least three
            times the monthly rent. We require proof of income, such as recent
            pay stubs or tax returns.
          </li>
        </ul>
      </div>,
      <p key="5">
        Verify that the property is still available and meets your specific
        needs by calling Evergreen Live at{" "}
        <a href="tel:+1855-806-6400" target="_blank">
          855-806-6400
        </a>
        .
      </p>,
      "Evergreen Live does not make any representations or warranties as to the accuracy or completeness of any information made available to third-party screening providers/services by applicants during the application and screening process.",
      "A non-refundable application fee of $50.00 will be charged upon the submission of your application.",
    ],
    tosEnd: (
      <div>
        <p>
          As noted above, upon submission of your application, you will be
          required to pay the application fee. We accept American Express,
          Discover, Mastercard and Visa credit cards. If payment information is
          not provided, your application will not be processed. By clicking the
          “I agree” checkbox below, you acknowledge that you have read and
          understood, and that you re-affirm your agreement to Evergreen Live’s
          Terms of Service and Privacy Policy, each of which are available for
          review at{" "}
          <a href="https://evergreenLive.com" target="_blank">
            EvergreenLive.com
          </a>
          . Clicking the "I agree" checkbox below indicates that you have had
          the opportunity to review the landlord’s tenant selection criteria.
          The tenant selection criteria may include factors such as criminal
          history, credit history, current income, and rental history. If you do
          not meet the selection criteria, or if you provide inaccurate or
          incomplete information, your application may be rejected and your
          application fee will not be refunded.
        </p>
        <br />
        <p>
          *Applicants will be subject to screening for criminal history.
          Criminal histories involving weapons, drugs, violence, or any other
          actions posing a threat to the safety of the community, Evergreen
          personnel, service providers or property may result in an application
          being denied. Evaluation of criminal history will consider factors
          such as the nature, severity, circumstances, and recency of the
          offense. Conviction of manufacturing or distributing controlled
          substances will result in automatic application denial.
        </p>
        <br />
        <p>
          These measures do not ensure, and Evergreen makes no guarantees or
          representations, that current residents or occupants of Evergreen
          managed properties have no prior convictions or deferred adjudications
          for felonies, misdemeanors, or sex offenses requiring registration
          under applicable law. Our ability to verify such information is
          limited to the data provided by applicants and third-party screening
          services.
        </p>
        <br />
        <p>
          Denied applicants have the option to request reconsideration by
          providing additional details regarding their criminal history for
          further review by Evergreen. Criminal background screening is subject
          to applicable federal, state, and local laws.
        </p>
        <br />
        <p>
          If you have any questions about our rental screening criteria, please
          don't hesitate to contact us. We are committed to providing a
          transparent and fair rental application process, and we look forward
          to helping you find your next home with Evergreen Live.
        </p>
      </div>
    ),
  },
};

const termsArr = [
  {
    href: "/terms",
    label: "Ender's Terms of Service",
  },
  {
    href: "/privacy",
    label: "Ender's Privacy Policy",
  },
  {
    href: "https://www.dwolla.com/legal/tos",
    label: "Dwolla's Terms of Service",
  },
  {
    href: "https://www.dwolla.com/legal/privacy",
    label: "Dwolla's Privacy Policy",
  },
  {
    href: "https://ender-public.s3.us-east-2.amazonaws.com/SAR+Terms+and+Conditions+-5.2020.pdf",
    label: "TransUnion's Terms of Service",
  },
];

type TosFormProps = {
  pm?: PMCompany;
  //TODO use this
  terms?: string;
  onAccept?: () => void;
  onReject?: () => void;
  isDesktop?: boolean;
};

function TosForm({ pm, onAccept, onReject, isDesktop }: TosFormProps) {
  const [read, setRead] = useState<boolean>(false);

  const TermsToAcceptLabel = useMemo(
    () => (
      <>
        I agree to{" "}
        {termsArr.map((term, index) => (
          <>
            {index !== 0 && ", "}
            {index + 1 === termsArr.length && "and "}
            <a href={term.href} key={term.label} target="_blank">
              {term.label}
            </a>
          </>
        ))}
        .
      </>
    ),
    [],
  );

  /**
   * a scroll listening element that toggles a flag when it enters or leaves the screen.
   * The listening element behaves as a ref, so the `null` is required by the dom
   */
  const [scrollListener, setScrollListener] = useState<HTMLDivElement | null>(
    null,
  );
  // lazy useEffect
  useEffect(() => {
    /**
     * the desired behavior is specific to mobile screens, so we can ignore it on desktop.
     * the criteria for desktop is that the checkbox is clicked.
     */
    if (isDesktop || !scrollListener) {
      return;
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        //the item is fully on the page
        if (e.isIntersecting) {
          setRead(true);
        } else {
          //the item is partly or fully off the page
          // e.target.classList.add("stuck");
        }
      },
      { threshold: [1] },
    );
    observer.observe(scrollListener);

    return () => observer.disconnect();
  }, [scrollListener, isDesktop]);

  const {
    tosStart = "",
    tosPoints = [],
    tosEnd = "",
  } = pm ? termsByCompany[pm] : {};

  return (
    <Stack>
      <div className={styles.scrollable}>
        <span className={styles.textSettings}>
          <Text size={FontSize.lg}>
            {tosStart}
            <br />
            <ul>
              {tosPoints.map((point) => (
                <li key={point?.toString()}>{point}</li>
              ))}
            </ul>
            <br />
            {tosEnd}
          </Text>
        </span>
        <div ref={setScrollListener} />
      </div>
      <Checkbox
        label={TermsToAcceptLabel}
        value={read}
        onChange={(value) => {
          setRead(value);
        }}
      />
      <Group justify={Justify.end}>
        {!isDesktop && (
          <Button
            variant={ButtonVariant.outlined}
            disabled={!read}
            onClick={onReject}>
            Decline
          </Button>
        )}
        <Button disabled={!read} onClick={onAccept}>
          {isDesktop ? "Confirm" : "Agree"}
        </Button>
      </Group>
    </Stack>
  );
}

export { TosForm };
