import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const WebserverFilesServiceFileUploadTypeValues = [
  "DOCUMENT",
  "DWOLLA_DOCUMENT",
  "PHOTO",
  "AP_IMAGE",
  "AR_IMAGE",
  "PRINT_CHECK_IMAGE",
  "PAYSTUB",
  "PHOTO_ID",
  "PET_DOCUMENT",
  "MARKET_DISCLAIMER",
  "BANK_TRANSACTIONS",
  "SECURITY_DEPOSIT_NOTIFICATION_LETTER",
  "DELINQUENCY_PHYSICAL_MAILS",
  "VIDEO",
] as const;

const WebserverFilesServiceFileUploadTypeEffectSchema = Schema.Literal(
  ...WebserverFilesServiceFileUploadTypeValues,
);

type WebserverFilesServiceFileUploadType = Schema.Schema.Type<
  typeof WebserverFilesServiceFileUploadTypeEffectSchema
>;

const WebserverFilesServiceFileUploadTypeEnum = castEnum(
  WebserverFilesServiceFileUploadTypeEffectSchema,
);

function randomWebserverFilesServiceFileUploadType(): WebserverFilesServiceFileUploadType {
  return rand(WebserverFilesServiceFileUploadTypeValues);
}

export {
  randomWebserverFilesServiceFileUploadType,
  WebserverFilesServiceFileUploadTypeEffectSchema,
  WebserverFilesServiceFileUploadTypeEnum,
  WebserverFilesServiceFileUploadTypeValues,
};
export type { WebserverFilesServiceFileUploadType };
