import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PersonalCheckCheckStatusValues = [
  "QUEUED",
  "EN_ROUTE",
  "ARRIVED",
  "FAILURE",
] as const;

const PersonalCheckCheckStatusEffectSchema = Schema.Literal(
  ...PersonalCheckCheckStatusValues,
);

type PersonalCheckCheckStatus = Schema.Schema.Type<
  typeof PersonalCheckCheckStatusEffectSchema
>;

const PersonalCheckCheckStatusEnum = castEnum(
  PersonalCheckCheckStatusEffectSchema,
);

function randomPersonalCheckCheckStatus(): PersonalCheckCheckStatus {
  return rand(PersonalCheckCheckStatusValues);
}

export {
  PersonalCheckCheckStatusEffectSchema,
  PersonalCheckCheckStatusEnum,
  PersonalCheckCheckStatusValues,
  randomPersonalCheckCheckStatus,
};
export type { PersonalCheckCheckStatus };
