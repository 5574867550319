import type { PropsWithChildren } from "react";

import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Text } from "@ender/shared/ds/text";

function EmptyMessage({ children }: PropsWithChildren) {
  return (
    <Group justify={Justify.center}>
      <Text color="slate-500">{children}</Text>
    </Group>
  );
}

export { EmptyMessage };
