/**
 * HTTP Method: POST
 * Pathname: /invoices/batchReject
 * @function batchReject
 * @memberof InvoicesAPI
 * @param {InvoicesAPIBatchRejectPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchedInvoiceActionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchedInvoiceActionResponse } from "@ender/shared/generated/ender.model.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIBatchRejectSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIBatchRejectBodyParams = {
  comment?: string | undefined;
  completelyReject: boolean;
  invoiceIds: EnderId[];
  overrideWarnings?: boolean | undefined;
};

type InvoicesAPIBatchRejectPayload = InvoicesAPIBatchRejectSearchParams &
  InvoicesAPIBatchRejectBodyParams;

function batchRejectUnsafeEffect(payload: InvoicesAPIBatchRejectPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchedInvoiceActionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchedInvoiceActionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/batchReject",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function batchReject(
  payload: InvoicesAPIBatchRejectPayload,
  options?: { signal?: AbortSignal },
): Promise<BatchedInvoiceActionResponse> {
  return F.pipe(
    payload,
    batchRejectUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { batchReject };
export type {
  InvoicesAPIBatchRejectBodyParams,
  InvoicesAPIBatchRejectPayload,
  InvoicesAPIBatchRejectSearchParams,
};
