import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  pipe,
} from "effect";
import { Fragment, useContext, useMemo } from "react";
import { useWatch } from "react-hook-form";

import { Form, FormList } from "@ender/form-system/base";
import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { Firm } from "@ender/shared/generated/ender.model.core";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import { SQLParamCardinalityEnum } from "@ender/shared/generated/ender.model.reports";
import { useGetAllProperties } from "@ender/shared/hooks/use-get-all-properties";
import { LoadingIndicator } from "@ender/shared/utils/general";
import { getIsMultiPropertyInvoice } from "@ender/shared/utils/invoice-utils";
import { isMultiple } from "@ender/shared/utils/is";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { getPropertyNameWithFriendlyId } from "@ender/shared/utils/property/get-property-name-with-friendly-id";

import { AllocationsByPropertyInput } from "./allocations-by-property-input/allocations-by-property-input";
import {
  emptyAllocationsWithProperty,
  useEditInvoiceAllocationsForm,
} from "./use-edit-invoice-allocations-form";
import { useInvoiceAllocationsHandleSubmit } from "./use-invoice-allocations-handle-submit";

function EnderTextRedNoPropertyOptions({ userName }: { userName: string }) {
  return (
    <Text color="red-500" size={FontSize.sm}>
      Hi {userName},
      <br />
      You do not have access to properties for this invoice.
      <br />
      No additional property options for this firm will be available for
      selection.
      <br />
      You will be able to select properties only from those already associated
      with this invoice.
      <br />
      And you will not be able to access any information about the properties
      besides their id.
      <br />
      Try logging in as a different user to access the properties.
    </Text>
  );
}

function useInvoiceFirm({
  invoice,
}: {
  invoice: InvoiceSerializerInvoiceResponse;
}) {
  const parties = P.isNotNullable(invoice)
    ? [invoice.owedByParty, invoice.owedToParty]
    : [];
  const firmParties = parties.filter((party) => party.type === PartyEnum.FIRM);
  if (A.isEmptyArray(firmParties)) {
    console.warn("Cannot find firm for invoice");
    return UNDEFINED;
  }
  if (isMultiple(firmParties)) {
    console.warn("Multiple firms found for invoice");
    return UNDEFINED;
  }
  return firmParties[0];
}

/**
 * Property options should come from the single firm associated with the invoice.
 * If we can't find the firm, we'll just use the property ids from the invoice.
 */
function usePropertyOptions({
  invoice,
}: {
  invoice: InvoiceSerializerInvoiceResponse;
}) {
  const { allProperties } = useGetAllProperties();
  const firm = useInvoiceFirm({ invoice }) as Firm | undefined;
  const firmProperties = P.isNotNullable(firm)
    ? allProperties.filter((property) => property.firmId === firm.id)
    : [];
  const firmPropertyOptions = firmProperties.map((property) => ({
    label: getPropertyNameWithFriendlyId(property),
    value: property.id,
  }));

  const invoicePropertyIds = invoice.currentState.allocationsByProperty.map(
    (property) => property.propertyId,
  );
  const fallbackPropertyOptionsWithJustIdLabels = invoicePropertyIds.map(
    (id) => ({
      label: id,
      value: id,
    }),
  );

  const propertyOptions = A.isNonEmptyArray(firmPropertyOptions)
    ? firmPropertyOptions
    : fallbackPropertyOptionsWithJustIdLabels;
  return { firm, firmProperties, propertyOptions };
}

// ENDER-10437: ideally some of these invoice-allocations-form components would be shared with create-invoice form
function EditInvoiceAllocationsForm({
  invoice,
  onCancel,
  onSuccess,
}: {
  invoice: InvoiceSerializerInvoiceResponse;
  onCancel: () => void;
  onSuccess: () => void;
}) {
  const { user } = useContext(UserContext);
  const { firm, firmProperties, propertyOptions } = usePropertyOptions({
    invoice,
  });
  const isMultiPropertyInvoice = getIsMultiPropertyInvoice(invoice);

  const { form } = useEditInvoiceAllocationsForm({
    invoice,
  });

  const [allocationsByProperty, invoiceAmount] = useWatch({
    control: form.control,
    name: ["allocationsByProperty", "invoiceAmount"],
  });

  const { handleSubmit, loading } = useInvoiceAllocationsHandleSubmit({
    invoiceId: invoice.id,
    onSuccess,
    values: { allocationsByProperty, invoiceAmount },
  });

  const allocations = allocationsByProperty.flatMap(
    (propertyAllocations) => propertyAllocations.allocations,
  );

  const allocationsTotal = useMemo(() => {
    return pipe(
      allocations,
      A.map((a) => a.amount),
      O.reduceCompact(Money$.zero(), Money$.add),
    );
  }, [allocations]);

  const remainderToAllocate = useMemo(() => {
    if (O.isNone(invoiceAmount)) {
      return 0;
    }
    const diff = O.getOrThrow(invoiceAmount).subtract(allocationsTotal);
    return diff.valueInCents;
  }, [allocationsTotal, invoiceAmount]);

  const missingGLCategoryErrorMessage: string | Undefined = useMemo(() => {
    const hasMissingGLCategory = allocations.some((allocation) =>
      O.isNone(allocation.accountId),
    );
    return hasMissingGLCategory
      ? "Must set a GL Category for all allocations."
      : undefined;
  }, [allocations]);

  const multiPropertyChangeErrorMessage: string | Undefined = useMemo(() => {
    const currentPropertyType = isMultiPropertyInvoice
      ? SQLParamCardinalityEnum.MULTIPLE
      : SQLParamCardinalityEnum.SINGLE;
    const newPropertyType = isMultiple(form.getValues().allocationsByProperty)
      ? SQLParamCardinalityEnum.MULTIPLE
      : SQLParamCardinalityEnum.SINGLE;

    if (currentPropertyType !== newPropertyType) {
      return `Cannot change a ${currentPropertyType.toLocaleLowerCase()}-property invoice to a ${newPropertyType.toLocaleLowerCase()}-property invoice.`;
    }
  }, [isMultiPropertyInvoice, form]);

  const canSubmit = useMemo(
    () =>
      O.isSome(invoiceAmount) &&
      remainderToAllocate === 0 &&
      P.isNullable(missingGLCategoryErrorMessage) &&
      P.isNullable(multiPropertyChangeErrorMessage),
    [
      missingGLCategoryErrorMessage,
      invoiceAmount,
      multiPropertyChangeErrorMessage,
      remainderToAllocate,
    ],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormMoneyInput
          label="Invoice Amount"
          form={form}
          name="invoiceAmount"
        />
        <Tuple label="Firm" value={firm?.name} />
        {user?.isEnder && A.isEmptyArray(firmProperties) && (
          <EnderTextRedNoPropertyOptions userName={user.firstName} />
        )}

        <FormList form={form} name="allocationsByProperty">
          {({ list, arrayMethods }) => {
            return list.map(({ name, key }, id) => {
              return (
                <Fragment key={key}>
                  <AllocationsByPropertyInput
                    form={form}
                    name={name}
                    removeProperty={() => arrayMethods.remove(id)}
                    canRemoveProperty={isMultiple(list)}
                    propertyOptions={propertyOptions}
                  />
                  {isMultiPropertyInvoice && (
                    <Group>
                      <Button
                        onClick={() =>
                          arrayMethods.append(emptyAllocationsWithProperty)
                        }
                        variant={ButtonVariant.outlined}
                        leftSection={<IconPlus />}>
                        Add Property
                      </Button>
                    </Group>
                  )}
                </Fragment>
              );
            });
          }}
        </FormList>
        <Divider />
        <Group noWrap align={Align.center} justify={Justify.between}>
          <Text
            color={remainderToAllocate !== 0 ? "red-500" : UNDEFINED}
            size={FontSize.sm}
            weight={FontWeight.semibold}>
            Remainder to Allocate:{" "}
            <MoneyDisplay
              showSymbol
              value={Money$.parse(remainderToAllocate)}
            />
          </Text>
          <Group noWrap>
            <Button
              disabled={loading}
              onClick={onCancel}
              variant={ButtonVariant.transparent}>
              Cancel
            </Button>
            <Button
              disabled={!canSubmit}
              loading={loading}
              type="submit"
              disabledTooltip={
                multiPropertyChangeErrorMessage || missingGLCategoryErrorMessage
              }>
              Save Changes
            </Button>
          </Group>
        </Group>
      </Stack>
    </Form>
  );
}

function EditInvoiceAllocationsFormInvoiceLoaderWrapper({
  invoiceId,
  onCancel,
  onSuccess,
}: {
  invoiceId: EnderId;
  onCancel: () => void;
  onSuccess: () => void;
}) {
  const { data: invoice, isFetching } = useQuery({
    queryFn: ({ signal }) => InvoicesAPI.getInvoice({ invoiceId }, { signal }),
    queryKey: ["InvoicesAPI.getInvoice", invoiceId] as const,
  });

  if (isFetching || P.isNullable(invoice)) {
    return <LoadingIndicator message="Loading invoice allocations..." />;
  }

  return (
    <EditInvoiceAllocationsForm
      invoice={invoice}
      onCancel={onCancel}
      onSuccess={onSuccess}
    />
  );
}

// This component name expresses the modal-like interface of this wrapper
function EditInvoiceAllocationsFormAsModalContent({
  closeModal,
  invoiceId,
  onClose = F.constVoid,
  onSuccess = F.constVoid,
}: {
  closeModal: () => void;
  invoiceId: EnderId;
  onClose?: () => void;
  onSuccess?: () => void;
}) {
  return (
    <EditInvoiceAllocationsFormInvoiceLoaderWrapper
      invoiceId={invoiceId}
      onCancel={closeModal}
      onSuccess={() => {
        showSuccessNotification({ message: "Allocations saved successfully" });
        onSuccess();
        onClose();
        closeModal();
      }}
    />
  );
}

export { EditInvoiceAllocationsFormAsModalContent };
