/**
 * HTTP Method: POST
 * Pathname: /markets/{marketId}/assignUsers
 * @function assignUsersToMarket
 * @memberof MarketsAPI
 * @param {MarketsAPIAssignUsersToMarketPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { MarketUserRoleMarketRole } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MarketsAPIAssignUsersToMarketPathParams = {
  marketId: EnderId;
};

type MarketsAPIAssignUsersToMarketSearchParams = {
  token?: string | undefined;
};

type MarketsAPIAssignUsersToMarketBodyParams = {
  role: MarketUserRoleMarketRole;
  userIds: EnderId[];
};

type MarketsAPIAssignUsersToMarketPayload =
  MarketsAPIAssignUsersToMarketPathParams &
    MarketsAPIAssignUsersToMarketSearchParams &
    MarketsAPIAssignUsersToMarketBodyParams;

function assignUsersToMarketUnsafeEffect(
  payload: MarketsAPIAssignUsersToMarketPayload,
) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/markets/${marketId}/assignUsers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function assignUsersToMarket(
  payload: MarketsAPIAssignUsersToMarketPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    assignUsersToMarketUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { assignUsersToMarket };
export type {
  MarketsAPIAssignUsersToMarketBodyParams,
  MarketsAPIAssignUsersToMarketPathParams,
  MarketsAPIAssignUsersToMarketPayload,
  MarketsAPIAssignUsersToMarketSearchParams,
};
