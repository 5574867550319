import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { Spacing } from "@ender/shared/ds/flex";
import { H2 } from "@ender/shared/ds/heading";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { getPropertyShortNameFromInvoice } from "@ender/shared/utils/invoice-utils";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { PayeePayerAccountNumberAmount } from "@ender/widgets/finance/payee-payer-account-number-amount";

type PaymentDetailsSingleInvoiceProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  overridePropertyName?: ReactNode;
};

function PaymentDetailsSingleInvoice({
  invoice,
  overridePropertyName,
}: PaymentDetailsSingleInvoiceProps) {
  const [invoiceDetails, setInvoiceDetails] = useState<
    InvoiceSerializerInvoiceResponse | Undefined
  >();
  const [isLoading, isLoadingHandler] = useBoolean(true);

  // lazy useEffect
  useEffect(() => {
    (async () => {
      const data = await InvoicesAPI.getInvoice({ invoiceId: invoice.id });
      setInvoiceDetails(data);
      isLoadingHandler.setFalse();
    })();
  }, [invoice.id, isLoadingHandler]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!invoiceDetails?.paymentInfo) {
    return <>No payment info available for this invoice.</>;
  }

  const { invoiceType, owedToParty, paymentInfo, task, owedByParty } =
    invoiceDetails;
  const {
    amount: totalAmount,
    details,
    specificLedgerDate,
    generalLedgerDate,
  } = paymentInfo;
  const ledgerDate = specificLedgerDate || generalLedgerDate;

  const payable = invoiceType !== InvoiceInvoiceTypeEnum.RECEIVABLE;
  // TODO can we simplify this logic because we can make the assumption that this feature is always for accounts payable?
  // We seem to be making a similar assumption for 'payee' much below
  const bankAccount = payable
    ? paymentInfo?.fromBankAccount
    : paymentInfo?.toBankAccount;
  const accountNumber = bankAccount && `****${bankAccount.mask}`;
  const accountName = bankAccount?.name;
  const checkNumber = details?.checkNumber;
  const memo = details?.memo;
  const payeeName = owedToParty.name;
  const payerName = owedByParty.name;
  const propertyName = getPropertyShortNameFromInvoice(invoiceDetails);

  return (
    <Stack>
      <H2>Payment Details</H2>
      <Stack spacing={Spacing.none}>
        <Tuple
          label="Payment Type"
          value={convertSnakeCaseToTitleCase(paymentInfo.type)}
        />
        {/* ENDER-13600 for more information; Payment Date should include `paymentDate &&` */}
        <Tuple
          label="Payment Date"
          value={
            /*paymentDate && */ EnderDate.of(ledgerDate).toLongSlashDateString()
          }
        />
        <PayeePayerAccountNumberAmount
          accountName={accountName}
          accountNumber={accountNumber}
          checkNumber={checkNumber}
          memo={memo}
          payeeName={payeeName}
          payerName={payerName}
          totalAmount={totalAmount}
        />
        <Tuple label="Property" value={overridePropertyName || propertyName} />
        {task && <Tuple label="Task" value={<span>{task.description}</span>} />}
      </Stack>
    </Stack>
  );
}

export { PaymentDetailsSingleInvoice };
export type { PaymentDetailsSingleInvoiceProps };
