import { IconEdit } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useId } from "react";

import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Tuple } from "@ender/shared/ds/tuple";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";

type SpecificationsCardProps = {
  unit: UnitSerializerUnitResponse;
  onEdit?: () => void;
};

function SpecificationsCard(props: SpecificationsCardProps) {
  const { onEdit, unit } = props;

  const { data: unitTypes = [], isInitialLoading } = useQuery({
    queryKey: ["PropertiesAPI.getPropertyUnitTypes", unit?.property.id],
    queryFn: () =>
      PropertiesAPI.getPropertyUnitTypes({
        propertyId: unit?.property.id as EnderId,
      }),
    enabled: P.isNotNullable(unit?.property.id),
  });

  const bathsText =
    P.isNotNullable(unit?.fullBaths) || P.isNotNullable(unit?.halfBaths)
      ? `${unit?.fullBaths ?? 0} Full ${unit?.halfBaths ?? 0} Half`
      : "--";

  const id = useId();

  return (
    <Card labelledBy={id}>
      <Group justify={Justify.between}>
        <H3 id={id}>Specifications</H3>
        <ActionIcon variant={ButtonVariant.transparent} onClick={onEdit}>
          <IconEdit />
        </ActionIcon>
      </Group>
      <Skeleton visible={isInitialLoading}>
        <Grid spacingY={Spacing.none}>
          <Tuple label="Beds" value={unit?.bedrooms} />
          <Tuple label="Baths" value={bathsText} />
          <Tuple label="Actual Sqft" value={unit?.sqft ?? "--"} />
          <Tuple label="Floor #" value={unit?.floorNum ?? "--"} />
          <Tuple
            label="Unit Type"
            value={
              unitTypes.find((unitType) => unitType.id === unit?.unitTypeId)
                ?.name
            }
          />
        </Grid>
      </Skeleton>
    </Card>
  );
}

export { SpecificationsCard };
