import { IconX } from "@tabler/icons-react";
import { Function as F } from "effect";

import { Color } from "@ender/shared/utils/theming";

import { ActionIcon } from "../../../action-icon/src";
import { ButtonVariant } from "../../../button/src";
import { Tuple } from "../../../tuple/src";

type FileTupleProps = {
  file: Pick<File, "name">;
  onDelete?: () => void;
};
function FileTuple(props: FileTupleProps) {
  const { file, onDelete = F.constVoid } = props;
  return (
    <div
      className="bg-slate-100 border-slate-300 border rounded text-slate-900 cursor-default"
      onClick={(e) => e.stopPropagation()}>
      <Tuple
        borderless
        label={file.name}
        value={
          <ActionIcon
            variant={ButtonVariant.transparent}
            color={Color.slate}
            label="Remove"
            onClick={(e) => {
              onDelete();
              e.stopPropagation();
            }}>
            <IconX />
          </ActionIcon>
        }
      />
    </div>
  );
}

export { FileTuple };
export type { FileTupleProps };
