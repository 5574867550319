import { useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P, pipe } from "effect";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Skeleton } from "@ender/shared/ds/skeleton";
import type { LatLngLiteral } from "@ender/shared/utils/google-maps";
import { GoogleMapsLoader } from "@ender/shared/utils/google-maps";

import { MapPinIcon } from "./map-pin-icon";

type GoogleMapProps = {
  location: O.Option<LatLngLiteral>;
};

const GoogleMap = forwardRef<HTMLDivElement, GoogleMapProps>(
  function GoogleMap(props, _ref) {
    const { location: unstableLocation } = props;
    const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
    const pin = useRef<SVGSVGElement>(null);

    const { lat, lng } = pipe(
      unstableLocation,
      O.getOrElse(() => ({ lat: undefined, lng: undefined })),
    );

    /**
     * a referentially stable location option
     * that only changes when the lat and lng change
     */
    const location = useMemo(() => {
      if (P.isNotNullable(lat) && P.isNotNullable(lng)) {
        return O.some({ lat, lng });
      }
      return O.none();
    }, [lat, lng]);

    const { data: maps, isInitialLoading: loadingMaps } = useQuery(
      ["GoogleMapsLoader.importLibrary", "maps"],
      () => GoogleMapsLoader.importLibrary("maps"),
    );
    const { data: marker, isInitialLoading: loadingMarker } = useQuery(
      ["GoogleMapsLoader.importLibrary", "marker"],
      () => GoogleMapsLoader.importLibrary("marker"),
    );

    const loadMap = useCallback(
      (loc: LatLngLiteral) => {
        if (
          P.isNullable(maps) ||
          P.isNullable(marker) ||
          P.isNullable(mapRef)
        ) {
          return;
        }

        const map = new maps.Map(mapRef, {
          center: loc,
          zoom: 14,
          mapTypeControl: false,
          scrollwheel: false,
          //TODO we need a valid Map ID from GCP
          mapId: "DEMO_MAP_ID",
          styles: [
            {
              featureType: "poi",
              elementType: "labels.icon",
              stylers: [{ path: "asdf" }],
            },
          ],
        });

        new marker.AdvancedMarkerElement({
          map,
          content: pin.current,
          position: loc,
        });
        return map;
      },
      [maps, marker, mapRef],
    );

    // lazy useEffect
    useEffect(() => {
      if (loadingMaps || loadingMarker || O.isNone(location)) {
        return;
      }

      loadMap(pipe(location, O.getOrThrow));
    }, [loadingMaps, loadingMarker, location, loadMap]);

    return (
      <>
        <Skeleton visible={loadingMaps || loadingMarker}>
          <div ref={setMapRef} className="h-full" />
        </Skeleton>
        <div className="hidden">
          <MapPinIcon ref={pin} />
        </div>
      </>
    );
  },
);

export { GoogleMap };

export type { GoogleMapProps };
