import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useContext, useState } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { ApplicationApplicantType } from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";

import type { ApplicantInfoFormOutput } from "./edit-applicant-info-fields";
import type { ApplicationApplicantInfoCardProps } from "./widgets-application-applicant-info-card";
import { ApplicationApplicantInfoCard } from "./widgets-application-applicant-info-card";

type ConnectedApplicationApplicantInfoCardProps = Pick<
  ApplicationApplicantInfoCardProps,
  | "applicant"
  | "applicantType"
  | "currentAddress"
  | "header"
  | "isEditable"
  | "photoIds"
  | "onUploadClick"
  | "onEditFileClick"
> & {
  onSuccess?: () => void;
  applicationGroupId: EnderId;
  applicationId: EnderId;
};

const ConnectedApplicationApplicantInfoCard = forwardRef<
  ElementRef<typeof ApplicationApplicantInfoCard>,
  ConnectedApplicationApplicantInfoCardProps
>(function ConnectedApplicationApplicantInfoCard(props, ref) {
  const {
    applicantType: _applicantType,
    currentAddress,
    applicant,
    isEditable,
    header,
    onSuccess = F.constVoid,
    applicationGroupId,
    applicationId,
    photoIds,
    onUploadClick,
    onEditFileClick = F.constVoid,
  } = props;

  const { hasPermissions } = useContext(UserContext);
  const canDeleteDocuments = hasPermissions(
    FunctionalPermissionEnum.DELETE_DOCUMENTS,
  );
  const confirmation = useConfirmationContext();
  const [applicantType, setApplicantType] =
    useState<O.Option<ApplicationApplicantType>>(_applicantType);

  const { mutateAsync: updateUser, isLoading: isSavingUser } = useMutation({
    mutationFn: UsersAPI.updateUser,
    mutationKey: ["UsersAPI.updateUser"] as const,
  });
  const { mutateAsync: updateApplicationType, isLoading: isSavingApplication } =
    useMutation({
      mutationFn: ApplicationsAPI.changeOccupantRole,
      mutationKey: ["ApplicationsAPI.changeOccupantRole"] as const,
    });
  const { mutateAsync: deleteFile } = useMutation({
    mutationFn: WebserverFilesAPI.deleteFile,
    mutationKey: ["WebserverFilesAPI.deleteFile"] as const,
  });

  const handleSubmit = useCallback(
    async ({
      birthday,
      firstName,
      lastName,
      phone,
      email,
    }: ApplicantInfoFormOutput) => {
      await updateUser({
        json: {
          birthday: O.getOrNull(birthday),
          email,
          firstName,
          lastName,
          phone,
        },
        targetUserId: applicant.userId,
      });
      onSuccess();
    },
    [updateUser, onSuccess, applicant],
  );

  const handleRoleChange = useCallback(
    async (applicantType: O.Option<ApplicationApplicantType>) => {
      setApplicantType(applicantType);
      try {
        await updateApplicationType({
          applicationGroupId,
          applicationId,
          type: O.getOrUndefined(applicantType),
        });
        onSuccess();
      } catch {
        setApplicantType(_applicantType);
      }
    },
    [
      _applicantType,
      applicationGroupId,
      applicationId,
      onSuccess,
      updateApplicationType,
    ],
  );

  const handleDeletePhoto = useCallback(
    async (file: FilesClientEnderFile) => {
      await confirmation({
        confirmButtonLabel: "Yes, delete",
        content: file.name,
        title: "Are you sure you want to delete this photo ID?",
      });
      await deleteFile({
        fileId: file.id,
        modelId: applicationId,
        modelType: ModelTypeEnum.APPLICATION,
      });
      onSuccess();
    },
    [deleteFile, applicationId, confirmation, onSuccess],
  );

  return (
    <ApplicationApplicantInfoCard
      onSubmit={handleSubmit}
      isWorking={isSavingUser || isSavingApplication}
      applicantType={applicantType}
      currentAddress={currentAddress}
      applicant={applicant}
      isEditable={isEditable}
      header={header}
      photoIds={photoIds}
      onUploadClick={onUploadClick}
      ref={ref}
      canDeleteDocuments={canDeleteDocuments}
      onDeletePhoto={handleDeletePhoto}
      onApplicantTypeChange={handleRoleChange}
      onEditFileClick={onEditFileClick}
    />
  );
});

export { ConnectedApplicationApplicantInfoCard };

export type { ConnectedApplicationApplicantInfoCardProps };
