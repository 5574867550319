/**
 * HTTP Method: POST
 * Pathname: /invoices/{invoiceId}/mark-paid
 * @function markPaid
 * @memberof InvoicesAPI
 * @param {InvoicesAPIMarkPaidPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TransferDetails } from "@ender/shared/generated/ender.api.accounting.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIMarkPaidPathParams = {
  invoiceId: EnderId;
};

type InvoicesAPIMarkPaidSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIMarkPaidBodyParams = {
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
  transferDetails: TransferDetails;
};

type InvoicesAPIMarkPaidPayload = InvoicesAPIMarkPaidPathParams &
  InvoicesAPIMarkPaidSearchParams &
  InvoicesAPIMarkPaidBodyParams;

function markPaidUnsafeEffect(payload: InvoicesAPIMarkPaidPayload) {
  const { invoiceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/invoices/${invoiceId}/mark-paid`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function markPaid(
  payload: InvoicesAPIMarkPaidPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId> {
  return F.pipe(
    payload,
    markPaidUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { markPaid };
export type {
  InvoicesAPIMarkPaidBodyParams,
  InvoicesAPIMarkPaidPathParams,
  InvoicesAPIMarkPaidPayload,
  InvoicesAPIMarkPaidSearchParams,
};
