import { Function as F, Option as O, pipe } from "effect";
import type { UseFormReturn } from "react-hook-form";
import { useWatch } from "react-hook-form";

import { applicantEmploymentDisplayMap } from "@ender/entities/utils/application-utils";
import { Tuple } from "@ender/shared/ds/tuple";
import { ApplicationEmploymentStatusEnum } from "@ender/shared/generated/ender.model.leasing";

import type { ApplicantEmploymentFormInput } from "./edit-applicant-employment-fields";

type DisplayableApplicantEmploymentProps = {
  form: UseFormReturn<ApplicantEmploymentFormInput>;
};

function DisplayableApplicantEmployment(
  props: DisplayableApplicantEmploymentProps,
) {
  const { form } = props;
  const [
    annualSalary,
    employmentStatus,
    employer,
    housingChoiceVoucher,
    managerName,
    managerPhone,
    housingChoiceVoucherCaseWorkerName,
    housingChoiceVoucherCaseWorkerEmail,
  ] = useWatch({
    control: form.control,
    name: [
      "annualSalary",
      "employmentStatus",
      "employer",
      "housingChoiceVoucher",
      "managerName",
      "managerPhone",
      "housingChoiceVoucherCaseWorkerName",
      "housingChoiceVoucherCaseWorkerEmail",
    ],
  });
  const employmentStatusValue = employmentStatus.pipe(O.getOrUndefined);
  const isEmployed =
    employmentStatusValue === ApplicationEmploymentStatusEnum.EMPLOYED ||
    employmentStatusValue === ApplicationEmploymentStatusEnum.SELF_EMPLOYED;
  return (
    <>
      <Tuple
        label="Employment Status"
        value={pipe(
          employmentStatus,
          O.map((val) => applicantEmploymentDisplayMap[val]),
          O.getOrElse(F.constant("--")),
        )}
      />
      {isEmployed && (
        <>
          <Tuple label="Employer" value={employer || "--"} />
          <Tuple
            label="Salary"
            value={annualSalary.pipe(
              O.map((val) => `${val.toFormatted()}`),
              O.getOrElse(() => "--"),
            )}
          />
          <Tuple label="Manager" value={managerName || "--"} />
          <Tuple label="Manager Phone" value={managerPhone || "--"} />
        </>
      )}
      <Tuple
        label="Using Housing Choice Vouchers"
        value={housingChoiceVoucher ? "Yes" : "No"}
      />
      {housingChoiceVoucher && (
        <>
          <Tuple
            label="Case Worker Name"
            value={housingChoiceVoucherCaseWorkerName || "--"}
          />
          <Tuple
            label="Case Worker Email"
            value={housingChoiceVoucherCaseWorkerEmail || "--"}
          />
        </>
      )}
    </>
  );
}

export { DisplayableApplicantEmployment };
