// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { NumberInput as MantineNumberInput } from "@mantine/core";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import { z } from "zod";

import { generateGeneralMantinePropsSchema } from "@ender/shared/forms/types/general";
import {
  InputHeightEnum,
  InputHeightSchema,
  generateInputHeight,
} from "@ender/shared/types/ender-general";
import { ENDER_INPUT_WRAPPER_ORDER } from "@ender/shared/utils/mantine";

const NumberInputBasePropsSchema = z.object({
  height: InputHeightSchema.optional(),
  hideControls: z.boolean().optional(),
  max: z.number().optional(),
  min: z.number().optional(),
  precision: z.number().optional(),
});
const NumberInputPropsSchema = generateGeneralMantinePropsSchema(
  z.number(),
).merge(NumberInputBasePropsSchema);

type NumberInputProps = z.infer<typeof NumberInputPropsSchema>;

/**
 * @deprecated use NumberInput from shared/ds/number-input
 */
const NumberInput = forwardRef(function NumberInput(
  {
    label,
    min,
    max,
    onChange,
    onBlur,
    onFocus,
    value,
    error,
    size,
    disabled,
    hideControls,
    placeholder,
    precision,
    height = InputHeightEnum.TALL,
  }: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <MantineNumberInput
      error={error}
      inputWrapperOrder={ENDER_INPUT_WRAPPER_ORDER}
      label={label}
      disabled={disabled}
      precision={precision}
      max={max}
      min={min}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      ref={ref}
      value={value}
      size={size}
      placeholder={placeholder}
      hideControls={hideControls}
      styles={generateInputHeight(height)}
    />
  );
});

export { NumberInput, NumberInputBasePropsSchema, NumberInputPropsSchema };
export type { NumberInputProps };
