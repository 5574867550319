/**
 * Reverse existing journal entry, if the journal entry is in NEW status, delete it.
 * HTTP Method: POST
 * Pathname: /accounting/journal-entries/{journalEntryId}/reverse
 * @function reverseJournalEntry
 * @memberof AccountingAPI
 * @param {AccountingAPIReverseJournalEntryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GLJournalEntry | undefined>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GLJournalEntry } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIReverseJournalEntryPathParams = {
  journalEntryId: EnderId;
};

type AccountingAPIReverseJournalEntrySearchParams = {
  token?: string | undefined;
};

type AccountingAPIReverseJournalEntryBodyParams = {
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
  reversalDate?: SerializesInto<LocalDate> | undefined;
};

type AccountingAPIReverseJournalEntryPayload =
  AccountingAPIReverseJournalEntryPathParams &
    AccountingAPIReverseJournalEntrySearchParams &
    AccountingAPIReverseJournalEntryBodyParams;

function reverseJournalEntryUnsafeEffect(
  payload: AccountingAPIReverseJournalEntryPayload,
) {
  const { journalEntryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GLJournalEntry | undefined>({
        body,
        decode: unsafeDecodeJsonResponse<GLJournalEntry | undefined>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accounting/journal-entries/${journalEntryId}/reverse`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function reverseJournalEntry(
  payload: AccountingAPIReverseJournalEntryPayload,
  options?: { signal?: AbortSignal },
): Promise<GLJournalEntry | undefined> {
  return F.pipe(
    payload,
    reverseJournalEntryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { reverseJournalEntry };
export type {
  AccountingAPIReverseJournalEntryBodyParams,
  AccountingAPIReverseJournalEntryPathParams,
  AccountingAPIReverseJournalEntryPayload,
  AccountingAPIReverseJournalEntrySearchParams,
};
