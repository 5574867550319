import { useQuery } from "@tanstack/react-query";
import { Array as A } from "effect";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Align } from "@ender/shared/ds/flex";
import { H2 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { toLongSlashString } from "@ender/shared/utils/local-date";

import type { FileData } from "./delete-bai-upload";
import { DeleteBaiUpload } from "./delete-bai-upload";

type BaiUploadHistoryProps = {
  onSuccess: () => void;
};

function BaiUploadHistory({ onSuccess }: BaiUploadHistoryProps) {
  const { accountId } = useParams<{ accountId: EnderId }>();

  const {
    data: items,
    refetch: fetchBaiHistory,
    isLoading,
  } = useQuery<FileData[]>({
    queryFn: ({ signal }) =>
      BankingAPI.getBAIUploadsForBank({ bankAccountId: accountId }, { signal }),
    queryKey: ["BankingAPI.getBAIUploadsForBank", accountId] as const,
  });

  const sortedItems = useMemo(
    () =>
      items?.sort((a, b) => {
        const dateA = Number(new Date(a.timestamp));
        const dateB = Number(new Date(b.timestamp));
        return dateB - dateA;
      }) || [],
    [items],
  );

  return (
    <Stack align={Align.center}>
      <H2>BAI Upload History</H2>
      <EnderTable>
        <colgroup>
          <col style={{ width: "8em" }} />
          <col />
          <col />
          <col style={{ width: "53px" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Date</th>
            <th>Uploaded By</th>
            <th>File Name</th>
            <th />
          </tr>
        </thead>
        <Skeleton visible={isLoading}>
          <tbody>
            {sortedItems.map((fileData) => {
              const { id, uploaderName, fileName, timestamp, s3Url } = fileData;
              return (
                <tr key={id}>
                  <td>{toLongSlashString(LocalDate$.of(timestamp))}</td>
                  <td>{uploaderName}</td>
                  <td>
                    <Tooltip label={fileName}>
                      <a href={s3Url}>{fileName}</a>
                    </Tooltip>
                  </td>
                  <td>
                    <DeleteBaiUpload
                      fileData={fileData}
                      onSuccess={() => {
                        fetchBaiHistory();
                        onSuccess();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            {A.isEmptyArray(sortedItems) && (
              <tr>
                <td colSpan={4}>
                  <span className="center">No results to display.</span>
                </td>
              </tr>
            )}
          </tbody>
        </Skeleton>
      </EnderTable>
    </Stack>
  );
}

export { BaiUploadHistory };
