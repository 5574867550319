/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/execute
 * @function executeLease
 * @memberof LeasingAPI
 * @param {LeasingAPIExecuteLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ExecuteLeaseResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ExecuteLeaseResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIExecuteLeasePathParams = {
  leaseId: EnderId;
};

type LeasingAPIExecuteLeaseSearchParams = {
  token?: string | undefined;
};

type LeasingAPIExecuteLeaseBodyParams = {
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
};

type LeasingAPIExecuteLeasePayload = LeasingAPIExecuteLeasePathParams &
  LeasingAPIExecuteLeaseSearchParams &
  LeasingAPIExecuteLeaseBodyParams;

function executeLeaseUnsafeEffect(payload: LeasingAPIExecuteLeasePayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ExecuteLeaseResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ExecuteLeaseResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/execute`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function executeLease(
  payload: LeasingAPIExecuteLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<ExecuteLeaseResponse> {
  return F.pipe(
    payload,
    executeLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { executeLease };
export type {
  LeasingAPIExecuteLeaseBodyParams,
  LeasingAPIExecuteLeasePathParams,
  LeasingAPIExecuteLeasePayload,
  LeasingAPIExecuteLeaseSearchParams,
};
