import { acceptApplication } from "./accept-application";
import { addOtherIncome } from "./add-other-income";
import { addPersonToApplication } from "./add-person-to-application";
import { addPetToApplication } from "./add-pet-to-application";
import { answerIdentityQuestions } from "./answer-identity-questions";
import { applicationApproval } from "./application-approval";
import { applicationGroupApproval } from "./application-group-approval";
import { archiveApplication } from "./archive-application";
import { changeOccupantRole } from "./change-occupant-role";
import { createFakeApp } from "./create-fake-app";
import { createOrFindExistingUserForApplication } from "./create-or-find-existing-user-for-application";
import { deleteOtherIncome } from "./delete-other-income";
import { deletePetFromApplication } from "./delete-pet-from-application";
import { draftLease } from "./draft-lease";
import { getApplication } from "./get-application";
import { getApplicationUser } from "./get-application-user";
import { markUnderReview } from "./mark-under-review";
import { moveApplication } from "./move-application";
import { payApplicationFee } from "./pay-application-fee";
import { queryFilteredApplications } from "./query-filtered-applications";
import { refreshIncomeVerificationReportUrl } from "./refresh-income-verification-report-url";
import { rejectApplicationV2 } from "./reject-application-v2";
import { removePersonFromApplication } from "./remove-person-from-application";
import { resendIncomeVerificationInvitation } from "./resend-income-verification-invitation";
import { residentScreeningReport } from "./resident-screening-report";
import { reverifyIdentity } from "./reverify-identity";
import { screenApplicant } from "./screen-applicant";
import { screenApplication } from "./screen-application";
import { setPaymentMethod } from "./set-payment-method";
import { startNextApplication } from "./start-next-application";
import { submitApplication } from "./submit-application";
import { unarchiveApplication } from "./unarchive-application";
import { unmarkUnderReview } from "./unmark-under-review";
import { unsetDecision } from "./unset-decision";
import { updateApplication } from "./update-application";
import { updateTransunionInformation } from "./update-transunion-information";
import { verifyApplicantIncome } from "./verify-applicant-income";
import { verifyApplicantIncomes } from "./verify-applicant-incomes";

const ApplicationsAPI = {
  acceptApplication,
  addOtherIncome,
  addPersonToApplication,
  addPetToApplication,
  answerIdentityQuestions,
  applicationApproval,
  applicationGroupApproval,
  archiveApplication,
  changeOccupantRole,
  createFakeApp,
  createOrFindExistingUserForApplication,
  deleteOtherIncome,
  deletePetFromApplication,
  draftLease,
  getApplication,
  getApplicationUser,
  markUnderReview,
  moveApplication,
  payApplicationFee,
  queryFilteredApplications,
  refreshIncomeVerificationReportUrl,
  rejectApplicationV2,
  removePersonFromApplication,
  resendIncomeVerificationInvitation,
  residentScreeningReport,
  reverifyIdentity,
  screenApplicant,
  screenApplication,
  setPaymentMethod,
  startNextApplication,
  submitApplication,
  unarchiveApplication,
  unmarkUnderReview,
  unsetDecision,
  updateApplication,
  updateTransunionInformation,
  verifyApplicantIncome,
  verifyApplicantIncomes,
};

export { ApplicationsAPI };
