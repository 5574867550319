/**
 * HTTP Method: GET
 * Pathname: /buy/mlsPublicProperties
 * @function getMLSPublicProperties
 * @memberof BuyAPI
 * @param {BuyAPIGetMLSPublicPropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetMLSPublicPropertiesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetMLSPublicPropertiesResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetMLSPublicPropertiesSearchParams = {
  token?: string | undefined;
  accessToken: string;
};

type BuyAPIGetMLSPublicPropertiesPayload =
  BuyAPIGetMLSPublicPropertiesSearchParams;

function getMLSPublicPropertiesUnsafeEffect(
  payload: BuyAPIGetMLSPublicPropertiesPayload,
) {
  const { token, accessToken, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetMLSPublicPropertiesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetMLSPublicPropertiesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/buy/mlsPublicProperties",
        searchParams: [
          ["token", token],
          ["accessToken", accessToken],
        ],
      }),
    ),
  );
}

function getMLSPublicProperties(
  payload: BuyAPIGetMLSPublicPropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetMLSPublicPropertiesResponse[]> {
  return F.pipe(
    payload,
    getMLSPublicPropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMLSPublicProperties };
export type {
  BuyAPIGetMLSPublicPropertiesPayload,
  BuyAPIGetMLSPublicPropertiesSearchParams,
};
