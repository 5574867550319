import { IconReceipt } from "@tabler/icons-react";
import { Option as O, Predicate as P } from "effect";
import { useStore } from "zustand";

import { ActionIcon } from "@ender/shared/ds/action-icon";

import { useGetLeaseId } from "../../../../hooks";
import { useTenantLedgerStore } from "../../../../tenant-ledger-store.context";

function LedgerEventPrintBtn() {
  const leaseId = useGetLeaseId();
  const tenantLedgerStore = useTenantLedgerStore();

  const { selectedLedgerEvent } = useStore(tenantLedgerStore, (state) => ({
    selectedLedgerEvent: state.selectedLedgerEvent,
  }));

  async function handlePrintReceipt() {
    const ledgerEvent = selectedLedgerEvent.pipe(O.getOrUndefined);
    if (P.isNullable(ledgerEvent)) {
      return;
    }

    window.open(
      `/leases/${leaseId}/ledger-item/${ledgerEvent.id}/print-receipt`,
    );
  }

  const ledgerEventTypeLabel = selectedLedgerEvent.pipe(
    O.map((event) =>
      event.tenantLedgerEventType.toLowerCase().replace("_", " "),
    ),
    O.getOrUndefined,
  );

  return (
    <ActionIcon
      aria-label="Print receipt credit"
      onClick={handlePrintReceipt}
      label={`Print receipt of tenant ${ledgerEventTypeLabel}`}>
      <IconReceipt color="#0C0D4F" size="24px" />
    </ActionIcon>
  );
}

export { LedgerEventPrintBtn };
