import { useForm, zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import {
  Array as A,
  Effect,
  Function as F,
  Option as O,
  Predicate as P,
  String as Str,
} from "effect";
import { useCallback, useMemo, useState } from "react";
import { z } from "zod";

import { SearchInput } from "@ender/entities/search-input";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { SelectOption, SelectValue } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { PropertySerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import type { ApplicationGroup } from "@ender/shared/generated/ender.model.leasing";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { OptionSchema } from "@ender/shared/utils/zod";

const PropertyUnitFormSchema = z
  .object({
    propertyId: OptionSchema(EnderIdSchema),
    unitId: OptionSchema(EnderIdSchema),
  })
  .superRefine(({ propertyId, unitId }, ctx) => {
    if (O.isNone(propertyId)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["propertyId"],
      });
    }

    if (O.isNone(propertyId) && O.isNone(unitId)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Unit required for multi-unit property",
        path: ["unitId"],
      });
    }
  });

type PropertyUnitFormValues = z.infer<typeof PropertyUnitFormSchema>;
type SelectedPropertyMeta =
  | { units: PropertySerializerUnitResponse[] }
  | undefined;

function searchSelectOption<T extends SelectValue>(keyword: string) {
  const search = keyword.toUpperCase();
  return A.filter<SelectOption<T>>(({ label }) => {
    return Str.isEmpty(keyword) || label.toUpperCase().includes(search);
  });
}
function findSelectOption(id: EnderId) {
  return A.filter<SelectOption<EnderId>>(({ value }) => value === id);
}

function fetchProperties() {
  return F.pipe(
    Effect.tryPromise(() =>
      PropertiesAPI.searchPropertiesv2({
        includeUnits: true,
        ownershipGroupIds: [],
      }),
    ),
    Effect.andThen((response) => response.properties),
    Effect.andThen(
      A.map((property): SelectOption<EnderId> => {
        const { id, name, friendlyId, units } = property;
        return {
          label: `${name}${P.isNullable(friendlyId) || Str.isEmpty(friendlyId) ? "" : ` (${friendlyId})`}`,
          meta: { units },
          value: id,
        };
      }),
    ),
  );
}
const propertiesCache = Effect.cachedWithTTL("30 seconds");
function propertySearchFn(keyword: string): Promise<SelectOption<EnderId>[]> {
  const cached = F.pipe(fetchProperties(), propertiesCache);
  const search = cached.pipe(
    Effect.flatMap(Effect.andThen(searchSelectOption(keyword))),
  );
  return Effect.runPromise(search);
}
function propertyHydrateFn(id: EnderId) {
  const cached = F.pipe(fetchProperties(), propertiesCache);
  const search = cached.pipe(
    Effect.flatMap(Effect.andThen(findSelectOption(id))),
  );
  return Effect.runPromise(search);
}

function removeUnitPrefix(unitName: string) {
  return unitName.replace("Unit ", "");
}
function fetchUnits({ propertyId }: { propertyId: O.Option<EnderId> }) {
  return F.pipe(
    Effect.tryPromise(() =>
      F.pipe(
        propertyId,
        O.map((propertyId) =>
          PropertiesAPI.getUnitsForProperty({ propertyId }),
        ),
        O.getOrElse(() => Promise.resolve([])),
      ),
    ),
    Effect.andThen(
      A.map((unit, index): SelectOption<EnderId> => {
        const { id, name } = unit;
        return {
          label: Str.isEmpty(name) ? `${index + 1}` : removeUnitPrefix(name),
          value: id,
        };
      }),
    ),
  );
}

const unitsCache = Effect.cachedWithTTL("30 seconds");
function getUnitSearchFn(args: { propertyId: O.Option<EnderId> }) {
  const cached = F.pipe(fetchUnits(args), unitsCache);
  return function unitSearchFn(
    keyword: string,
  ): Promise<SelectOption<EnderId>[]> {
    const search = cached.pipe(
      Effect.flatMap(Effect.andThen(searchSelectOption(keyword))),
    );
    return Effect.runPromise(search);
  };
}
function getUnitHydrateFn(args: { propertyId: O.Option<EnderId> }) {
  const cached = F.pipe(fetchUnits(args), unitsCache);
  return function unitHydrateFn(id: EnderId): Promise<SelectOption<EnderId>[]> {
    const search = cached.pipe(
      Effect.flatMap(Effect.andThen(findSelectOption(id))),
    );
    return Effect.runPromise(search);
  };
}

type UpdateApplicationPropertyUnitFormProps = {
  applicationGroup: ApplicationGroup;
  onCancel: () => void;
  onSuccess: () => void;
};
function UpdateApplicationPropertyUnitForm(
  props: UpdateApplicationPropertyUnitFormProps,
) {
  const { applicationGroup, onSuccess, onCancel } = props;

  const { id: applicationGroupId } = applicationGroup;
  const form = useForm<PropertyUnitFormValues>({
    initialValues: {
      propertyId: O.fromNullable(applicationGroup.propertyId),
      unitId: O.fromNullable(applicationGroup.unitId),
    },
    validate: zodResolver(PropertyUnitFormSchema),
  });
  const {
    values: { propertyId },
    setFieldValue,
  } = form;

  const unitSearchFn = useMemo(
    () => getUnitSearchFn({ propertyId }),
    [propertyId],
  );
  const unitHydrateFn = useMemo(
    () => getUnitHydrateFn({ propertyId }),
    [propertyId],
  );

  const [selectedPropertyMeta, setSelectedPropertyMeta] =
    useState<SelectedPropertyMeta>();
  const { onChange: onPropertyIdChange, ...propertyInputProps } =
    form.getInputProps("propertyId");
  const _onPropertyIdChange = useCallback(
    (value: O.Option<EnderId>, item: O.Option<SelectOption<EnderId>>) => {
      onPropertyIdChange(value);

      const meta = F.pipe(
        item,
        O.map((i) => i.meta as SelectedPropertyMeta),
        O.getOrUndefined,
      );
      setSelectedPropertyMeta(meta);

      const { units = [] } = meta ?? {};
      if (units.length === 1) {
        const [{ id: unitId }] = units;
        setFieldValue("unitId", O.some(unitId));
      }
    },
    [onPropertyIdChange, setSelectedPropertyMeta, setFieldValue],
  );

  const { mutateAsync: updateApplication, isLoading } = useMutation({
    mutationFn: async ({ unitId }: { unitId: EnderId }) =>
      ApplicationsAPI.updateApplication({ applicationGroupId, unitId }),
    mutationKey: ["updateApplication", applicationGroupId],
  });

  const handleSubmit = useCallback(
    async ({ unitId }: PropertyUnitFormValues) => {
      if (O.isNone(unitId)) {
        return;
      }
      await updateApplication({ unitId: O.getOrThrow(unitId) });
      showSuccessNotification({
        message: "Property & Unit information updated",
      });
      onSuccess();
    },
    [onSuccess, updateApplication],
  );

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <SearchInput
          hydrate={propertyHydrateFn}
          label="Property"
          modelType={ModelTypeEnum.PROPERTY}
          onChange={_onPropertyIdChange}
          search={propertySearchFn}
          searchOnEmpty
          {...propertyInputProps}
        />
        {O.isSome(propertyId) &&
          (selectedPropertyMeta?.units?.length ?? 0) > 1 && (
            <SearchInput
              hydrate={unitHydrateFn}
              label="Unit"
              modelType={ModelTypeEnum.UNIT}
              search={unitSearchFn}
              searchOnEmpty
              {...form.getInputProps("unitId")}
            />
          )}
        <Group justify={Justify.end} align={Align.center}>
          <Button
            onClick={onCancel}
            type="button"
            variant={ButtonVariant.transparent}>
            Cancel
          </Button>
          <Button loading={isLoading} type="submit">
            Update Property/Unit
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

export { UpdateApplicationPropertyUnitForm };
