import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeasingServiceLeaseDisplayStatusValues = [
  "DRAFTING",
  "OUT_FOR_SIGNATURES",
  "FINAL_SIGNATURE",
  "CANCELED",
  "UPCOMING",
  "INACTIVE",
  "ACTIVE",
] as const;

const LeasingServiceLeaseDisplayStatusEffectSchema = Schema.Literal(
  ...LeasingServiceLeaseDisplayStatusValues,
);

type LeasingServiceLeaseDisplayStatus = Schema.Schema.Type<
  typeof LeasingServiceLeaseDisplayStatusEffectSchema
>;

const LeasingServiceLeaseDisplayStatusEnum = castEnum(
  LeasingServiceLeaseDisplayStatusEffectSchema,
);

function randomLeasingServiceLeaseDisplayStatus(): LeasingServiceLeaseDisplayStatus {
  return rand(LeasingServiceLeaseDisplayStatusValues);
}

export {
  LeasingServiceLeaseDisplayStatusEffectSchema,
  LeasingServiceLeaseDisplayStatusEnum,
  LeasingServiceLeaseDisplayStatusValues,
  randomLeasingServiceLeaseDisplayStatus,
};
export type { LeasingServiceLeaseDisplayStatus };
