class Percent {
  public static readonly ZERO = Percent.of(0);
  public static readonly ONE_HUNDRED = Percent.of(1);

  private readonly value: number;

  constructor(value: number) {
    this.value = value;
  }

  /**
   * Creates a new Percent instance with the given value.
   * Value should be provided as a fractional representation of the percent.
   * @param value The value of the percent.
   * @returns A new Percent instance.
   */
  public static of(value: number): Percent {
    return Percent.fromFractional(value);
  }

  /**
   * Creates a new Percent instance with the given value.
   * Value should be provided as a fractional representation of the percent.
   * (e.g. 0.5 means 50%)
   * @param value
   * @returns
   */
  public static fromFractional(value: number): Percent {
    return new Percent(value);
  }

  /**
   * Creates a new Percent instance with the given value.
   * Value should be provided as a literal representation of the percent
   * (e.g. 50 means 50%).
   * @param value
   * @returns
   */
  public static fromLiteral(value: number): Percent {
    return new Percent(value / 100);
  }

  /**
   * Gets the value of the percent.
   * @returns The value of the percent.
   */
  public getValue(): number {
    return this.value;
  }

  /**
   * Checks if the percent is zero.
   * @returns True if the percent is zero, false otherwise.
   */
  public isZero(): boolean {
    return this.value === 0;
  }

  /**
   * Checks if the percent is one hundred.
   * @returns True if the percent is one hundred, false otherwise.
   */
  public isOneHundred(): boolean {
    return this.value === 1;
  }

  /**
   * Checks if the percent is greater than the given percent.
   * @param other The other percent to compare.
   * @returns True if the percent is greater than the given percent, false otherwise.
   */
  public isGreaterThan(other: Percent): boolean {
    return this.value > other.value;
  }

  /**
   * Checks if the percent is less than the given percent.
   * @param other The other percent to compare.
   * @returns True if the percent is less than the given percent, false otherwise.
   */
  public isLessThan(other: Percent): boolean {
    return this.value < other.value;
  }

  /**
   * Checks if the percent is positive.
   * @returns True if the percent is positive, false otherwise.
   */
  public isPositive(): boolean {
    return this.isGreaterThan(Percent.ZERO);
  }

  /**
   * Checks if the percent is negative.
   * @returns True if the percent is negative, false otherwise.
   */
  public isNegative(): boolean {
    return this.isLessThan(Percent.ZERO);
  }

  /**
   * Compares the percent with the given percent.
   * @param other The other percent to compare.
   * @returns -1 if the percent is less than the given percent, 0 if they are equal, 1 if the percent is greater.
   */
  public compareTo(other: Percent): number {
    if (this.value < other.value) {
      return -1;
    }
    if (this.value > other.value) {
      return 1;
    }
    return 0;
  }

  /**
   * Returns a string representation of the percent without decimals.
   * @returns A string representation of the percent without decimals.
   */
  public toString(): string {
    return this.formatWithDecimals();
  }

  /**
   * Formats the percent as a string without decimals.
   * @returns The formatted percent string without decimals.
   */
  public formatNoDecimals(): string {
    return `${(this.value * 100).toFixed(0)}%`;
  }

  /**
   * Formats the percent as a string with the specified number of decimals.
   * @param nDecimals The number of decimals to include in the formatted string.
   * @returns The formatted percent string with the specified number of decimals.
   */
  public formatWithDecimals(nDecimals = 2): string {
    return `${(this.value * 100).toLocaleString(undefined, {
      maximumFractionDigits: nDecimals,
      minimumFractionDigits: nDecimals,
    })}%`;
  }

  /**
   * Checks if the percent is within the range of 0 to 1.
   * @returns True if the percent is within the range, false otherwise.
   */
  public isWithinRange(): boolean {
    return this.value >= 0 && this.value <= 1;
  }

  /**
   * Returns the complement of the percent.
   * @returns The complement of the percent.
   */
  public complement(): Percent {
    return Percent.of(1 - this.value);
  }

  /**
   * Returns the inverse of the percent.
   * @returns The inverse of the percent.
   */
  public inverse(): Percent {
    if (this.value === 0) {
      throw new Error("Cannot perform inverse operation on zero value.");
    }
    return Percent.of(1 / this.value);
  }

  /**
   * Adds the given percent to the current percent.
   * @param p The percent to add.
   * @returns A new Percent instance representing the sum of the two percents.
   */
  public add(p: Percent): Percent {
    return Percent.of(this.value + p.value);
  }

  /**
   * Subtracts the given percent from the current percent.
   * @param p The percent to subtract.
   * @returns A new Percent instance representing the difference between the two percents.
   */
  public subtract(p: Percent): Percent {
    return Percent.of(this.value - p.value);
  }

  /**
   * Multiplies the given percent with the current percent.
   * @param p The percent to multiply with.
   * @returns A new Percent instance representing the product of the two percents.
   */
  public multiply(p: Percent): Percent {
    return Percent.of(this.value * p.value);
  }

  /**
   * Multiplies the current percent with the given number.
   * @param n The number to multiply with.
   * @returns A new Percent instance representing the product of the percent and the number.
   */
  public multiplyNumber(n: number): Percent {
    return Percent.of(this.value * n);
  }

  /**
   * Checks if the current percent is equal to the given object.
   * @param obj The object to compare.
   * @returns True if the current percent is equal to the given object, false otherwise.
   */
  public equals(obj: Percent): boolean {
    return this.value === obj.value;
  }

  /**
   * Creates a new Percent instance from the given string.
   * @param s The string representation of the percent.
   * @returns A new Percent instance created from the string.
   * @throws Error if the string is not in the format 'X%', where X is a number.
   */
  public static fromString(s: string): Percent {
    const regex = /^(\d+(\.\d+)?)%$/;
    if (!regex.test(s)) {
      throw new Error(
        "Invalid format. String must be in the format 'X%', where X is a number.",
      );
    }
    const numberValue = parseFloat(s.replace("%", "")) / 100;
    return Percent.of(numberValue);
  }

  /**
   * Returns a JSON representation of the percent.
   * @returns A JSON representation of the percent.
   */
  public toJSON(): string {
    return this.formatWithDecimals(2);
  }

  /**
   * Creates a new Percent instance from the given JSON string.
   * @param json The JSON string representing the percent.
   * @returns A new Percent instance created from the JSON string.
   * @throws Error if the JSON string is not in the format 'X%', where X is a number.
   */
  public static fromJSON(json: string): Percent {
    return Percent.fromString(json);
  }
}

export { Percent };
