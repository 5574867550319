import { Option as O, Predicate as P } from "effect";
import { useContext, useMemo } from "react";
import { useStore } from "zustand";

import { UserContext } from "@ender/shared/contexts/user";
import { Button } from "@ender/shared/ds/button";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";

import { useTenantLedgerStore } from "../../../../tenant-ledger-store.context";

type LedgerEventReverseBtnParams = {
  onClick: () => void;
};

function LedgerEventReverseBtn({ onClick }: LedgerEventReverseBtnParams) {
  const { hasPermissions } = useContext(UserContext);
  const userHasReversePaymentPermissions = hasPermissions(
    FunctionalPermissionEnum.REVERSE_TENANT_PAYMENT,
  );

  const tenantLedgerStore = useTenantLedgerStore();
  const { selectedLedgerEvent: selectedLedgerEventEffectOption } = useStore(
    tenantLedgerStore,
    (state) => ({
      selectedLedgerEvent: state.selectedLedgerEvent,
    }),
  );
  const ledgerEvent = selectedLedgerEventEffectOption.pipe(O.getOrUndefined);

  const reversePaymentDisabledTooltip = useMemo(() => {
    if (P.isNullable(ledgerEvent)) {
      return;
    }
    if (ledgerEvent.isCreditReversal || ledgerEvent.isChargeReversal) {
      return "This transaction reverses a previous one and cannot be reversed again.";
    }
    if (
      ledgerEvent.isReversedCredit ||
      ledgerEvent.isReversedCharge ||
      ledgerEvent.isReversed
    ) {
      return "This payment was already marked reversed.";
    }
    if (!userHasReversePaymentPermissions) {
      return "You do not have permission to reverse this payment";
    }
  }, [ledgerEvent, userHasReversePaymentPermissions]);

  return (
    <Button
      color="red"
      disabledTooltip={reversePaymentDisabledTooltip}
      disabled={P.isNotNullable(reversePaymentDisabledTooltip)}
      onClick={onClick}
      variant="outlined">
      Reverse
    </Button>
  );
}

export { LedgerEventReverseBtn };
