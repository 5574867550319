/**
 * Create a deposit from one or more receipts.

 This transitions the MoneyTransfers from IN_RECEIPTS to NORMAL. It is expected that the GLTx's for the
 MoneyTransfers were PENDING. This operation transitions them to POSTED on the deposit date. This operation will
 always create a persisted batch (see  {@code MoneyTransfer#bankingBatchId} ).
 * HTTP Method: POST
 * Pathname: /deposits
 * @function createDeposit
 * @memberof PaymentsAPI
 * @param {PaymentsAPICreateDepositPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateDepositResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { CreateDepositResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPICreateDepositSearchParams = {
  token?: string | undefined;
};

type PaymentsAPICreateDepositBodyParams = {
  /**
   * The date of the deposit.
   */
  date: SerializesInto<LocalDate>;
  /**
   * An optional memo to be associated with the batch deposit.
   */
  memo: string;
  newToBankAccountId?: EnderId | undefined;
  /**
 * The user-facing user-friendly deposit ID. Optional. If not given, then a deposit ID will be
                 assigned by the Banking Service. See 
 */
  niceId: string;
  /**
   * Optional, to specify what accounting period to which to write the GLTx's.
   */
  periodId?: EnderId | undefined;
  receiptIds: EnderId[];
};

type PaymentsAPICreateDepositPayload = PaymentsAPICreateDepositSearchParams &
  PaymentsAPICreateDepositBodyParams;

function createDepositUnsafeEffect(payload: PaymentsAPICreateDepositPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateDepositResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateDepositResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/deposits",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createDeposit(
  payload: PaymentsAPICreateDepositPayload,
  options?: { signal?: AbortSignal },
): Promise<CreateDepositResponse> {
  return F.pipe(
    payload,
    createDepositUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createDeposit };
export type {
  PaymentsAPICreateDepositBodyParams,
  PaymentsAPICreateDepositPayload,
  PaymentsAPICreateDepositSearchParams,
};
