import { cast } from "@ender/shared/types/cast";

type FromScreamingSnakeCaseToSpaceCase<S extends string> =
  S extends `${infer P}_${infer Q}`
    ? `${Capitalize<Lowercase<P>>} ${FromScreamingSnakeCaseToSpaceCase<Q>}`
    : Capitalize<Lowercase<S>>;

function fromScreamingSnakeCaseToSpaceCase<T extends string>(
  input: T,
): FromScreamingSnakeCaseToSpaceCase<T> {
  return cast(
    input
      .split("_")
      .map(
        (segment) =>
          segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase(),
      )
      .join(" "),
  );
}

export { fromScreamingSnakeCaseToSpaceCase };
export type { FromScreamingSnakeCaseToSpaceCase };
