import type { ColumnFiltersState } from "@tanstack/react-table";
import { Predicate as P } from "effect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import type { Null } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import type { SelectOption } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import type { ReconTypeFilter } from "@ender/shared/generated/ender.api.accounting.request";
import {
  ReconTypeFilterExcludedTransactionTypeEnum,
  ReconTypeFilterMatchTypeEnum,
  ReconTypeFilterUnmatchedBankTransactionTypeEnum,
  ReconTypeFilterUnmatchedEnderTransactionTypeEnum,
} from "@ender/shared/generated/ender.api.accounting.request";
import type { MinimalPropertyResponse } from "@ender/shared/generated/ender.api.model";
import type { BankRecRowType } from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useDocumentTitle } from "@ender/shared/hooks/use-document-title";
import { useQueryParams } from "@ender/shared/hooks/use-query-params";
import { useAccountingFiltersStore } from "@ender/shared/stores/accounting-filters-store";
import { useHeaderStore } from "@ender/shared/stores/header-store";
import { useTableFilter } from "@ender/shared/ui/table-tanstack";
import { toLongSlashString } from "@ender/shared/utils/local-date";
import { updatePlaidCredentials } from "@ender/shared/utils/plaid";
import { queryClient } from "@ender/shared/utils/query-client";
import { BankAccountCard } from "@ender/widgets/finance/bank-account-card";

import { BaiUploadHistory } from "./bai-upload-history";
import { BankReconciliationConfigureModal } from "./bank-reconciliation-configure-modal";
import { BankingDetailBatchMatcher } from "./banking-detail-batch-matcher/banking-detail-batch-matcher";
import { BankingDetailCategories } from "./banking-detail-categories";
import { BankingDetailDownloadMenu } from "./banking-detail-download-menu";
import {
  BankingDetailFilters,
  TransactionTypeEnum,
  transactionTypeConfig,
} from "./banking-detail-filters/banking-detail-filters";
import type { TransactionType } from "./banking-detail-filters/banking-detail-filters";
import { BankingDetailList } from "./banking-detail-list/banking-detail-list";
import type { BankingDetailListTableData } from "./banking-detail-list/banking-detail-list-columns/banking-detail-list-columns";
import { BankingDetailMenu } from "./banking-detail-menu";
import { PlaidAutoUpdate } from "./plaid-auto-update";
import { UploadBaiModal } from "./upload-bai-modal";
import { useBankingDetailData } from "./use-banking-detail-data";
import { useBankingDetailTable } from "./use-banking-detail-table";

type UserFilterStateProps = {
  columnId: string;
  defaultValue: unknown;
  columnFilters: ColumnFiltersState;
  onColumnFiltersChange: React.Dispatch<
    React.SetStateAction<ColumnFiltersState>
  >;
};

/**
 * Custom hook to manage filter state for a specific column.
 * @returns A tuple containing the current filter value and a setter function.
 * @param props
 */
function useFilterState<T>(props: UserFilterStateProps) {
  const { columnId, defaultValue, columnFilters, onColumnFiltersChange } =
    props;

  const columnIdToFilterState = useMemo(
    () => Object.fromEntries(columnFilters.map(({ id, value }) => [id, value])),
    [columnFilters],
  );

  const value = useMemo(
    () => (columnIdToFilterState[columnId] ?? defaultValue) as T,
    [columnIdToFilterState, columnId, defaultValue],
  );

  const setValue = useCallback(
    (newValue: T) => {
      onColumnFiltersChange((currentState: ColumnFiltersState) => {
        const filterIndex = currentState.findIndex(
          (filter) => filter.id === columnId,
        );
        if (filterIndex !== -1) {
          return currentState.map((filter) =>
            filter.id === columnId ? { ...filter, value: newValue } : filter,
          );
        }
        return [...currentState, { id: columnId, value: newValue }];
      });
    },
    [onColumnFiltersChange, columnId],
  );

  return [value, setValue] as const;
}

/**
 * Base reconciliation type filter.
 */
const BaseReconTypeFilter: ReconTypeFilter = {
  excludedTransactionTypes: [],
  matchTypes: [],
  unmatchedBankTransactionTypes: [],
  unmatchedEnderTransactionTypes: [],
};

/**
 * Map bank reconciliation row types to filters.
 */
const BankRecRowTypeReconTypeMap: Partial<
  Record<BankRecRowType, ReconTypeFilter>
> = {
  [BankRecRowTypeEnum.MATCHED]: {
    ...BaseReconTypeFilter,
    matchTypes: [ReconTypeFilterMatchTypeEnum.MATCHED],
  },
  [BankRecRowTypeEnum.UNMATCHED_ENDER]: {
    ...BaseReconTypeFilter,
    unmatchedEnderTransactionTypes: [
      ReconTypeFilterUnmatchedEnderTransactionTypeEnum.ENDER_TRANSACTION,
      ReconTypeFilterUnmatchedEnderTransactionTypeEnum.PENDING_DEPOSIT,
    ],
  },
  [BankRecRowTypeEnum.UNMATCHED_BANK]: {
    ...BaseReconTypeFilter,
    unmatchedBankTransactionTypes: [
      ReconTypeFilterUnmatchedBankTransactionTypeEnum.BANK_TRANSACTION,
      ReconTypeFilterUnmatchedBankTransactionTypeEnum.PENDING_INVOICE,
    ],
  },
  [BankRecRowTypeEnum.BANK_EXCLUDED]: {
    ...BaseReconTypeFilter,
    excludedTransactionTypes: [
      ReconTypeFilterExcludedTransactionTypeEnum.EXCLUDED_BANK_TRANSACTION,
    ],
  },
  [BankRecRowTypeEnum.ENDER_EXCLUDED]: {
    ...BaseReconTypeFilter,
    excludedTransactionTypes: [
      ReconTypeFilterExcludedTransactionTypeEnum.EXCLUDED_ENDER_TRANSACTION,
    ],
  },
};

/**
 * Initial column filters.
 */
const initialColumnFilters = [
  { id: "transactionType", value: TransactionTypeEnum.DRAWS_AND_DEPOSITS },
  { id: "amountSearchString", value: "" },
  { id: "description", value: "" },
  { id: "propertyIds", value: [] },
];

/**
 * BankingDetail component.
 */
function BankingDetail() {
  useDocumentTitle("Bank Details - Ender");
  const { status } = useQueryParams();
  const history = useHistory();
  const { accountId } = useParams<{ accountId: EnderId }>();

  const columnFilters = useTableFilter<BankingDetailListTableData>({
    enableGlobalFilter: false,
    initialColumnFilters: [
      ...initialColumnFilters,
      {
        id: "reconTypeFilter",
        value: P.isNotNullable(status)
          ? BankRecRowTypeReconTypeMap[status as BankRecRowType]
          : UNDEFINED,
      },
    ],
    manualFiltering: true,
  });

  // Use custom hook to manage filter states.
  const [amountFilterValue, setAmountFilterValue] = useFilterState<string>({
    columnFilters: columnFilters.columnFilters,
    columnId: "amountSearchString",
    defaultValue: "",
    onColumnFiltersChange: columnFilters.onColumnFiltersChange,
  });
  const [descriptionFilterValue, setDescriptionFilterValue] =
    useFilterState<string>({
      columnFilters: columnFilters.columnFilters,
      columnId: "description",
      defaultValue: "",
      onColumnFiltersChange: columnFilters.onColumnFiltersChange,
    });
  const [propertyFilterValue, setPropertyFilterValue] = useFilterState<
    SelectOption<EnderId, MinimalPropertyResponse>[]
  >({
    columnFilters: columnFilters.columnFilters,
    columnId: "propertyIds",
    defaultValue: [],
    onColumnFiltersChange: columnFilters.onColumnFiltersChange,
  });
  const [reconTypeFilter, setReconTypeFilterValue] = useFilterState<
    ReconTypeFilter | undefined
  >({
    columnFilters: columnFilters.columnFilters,
    columnId: "reconTypeFilter",
    defaultValue: undefined,
    onColumnFiltersChange: columnFilters.onColumnFiltersChange,
  });
  const [transactionType, setTransactionType] = useFilterState<TransactionType>(
    {
      columnFilters: columnFilters.columnFilters,
      columnId: "transactionType",
      defaultValue: TransactionTypeEnum.DRAWS_AND_DEPOSITS,
      onColumnFiltersChange: columnFilters.onColumnFiltersChange,
    },
  );

  const setBreadcrumbs = useHeaderStore.use.setBreadcrumbs();

  const {
    startDate,
    endDate,
    dateValidationState: { isValidDateRange },
    periodFilter,
    updateEndDate,
    updatePeriodFilter,
    updateStartDate,
  } = useAccountingFiltersStore();

  const onClearFilters = useCallback(() => {
    // Reset filters to default values.
    setAmountFilterValue("");
    setDescriptionFilterValue("");
    setPropertyFilterValue([]);
    setReconTypeFilterValue(undefined);
    setTransactionType(TransactionTypeEnum.DRAWS_AND_DEPOSITS);
    history.push(`/accounting/banking/accounts/${accountId}`);

    // Reset date filters.
    // @ts-expect-error AccountingFiltersStore expects LocalDate, but the filter sets NULL when cleared.
    updateEndDate(NULL);
    updatePeriodFilter([]);
    // @ts-expect-error AccountingFiltersStore expects LocalDate, but the filter sets NULL when cleared.
    updateStartDate(NULL);
  }, [
    accountId,
    history,
    setAmountFilterValue,
    setDescriptionFilterValue,
    setPropertyFilterValue,
    setReconTypeFilterValue,
    setTransactionType,
    updateEndDate,
    updatePeriodFilter,
    updateStartDate,
  ]);

  const [batchMatcherTransactionId, setBatchMatcherTransactionId] = useState<
    EnderId | Null
  >(NULL);
  const closeBatchMatcherModal = () => setBatchMatcherTransactionId(NULL);
  const [
    isBaiUploadHistoryModalOpen,
    { setTrue: openBaiHistoryModal, setFalse: closeBaiHistoryModal },
  ] = useBoolean();
  const [
    isUploadBaiModalOpen,
    { setTrue: openUploadBaiModal, setFalse: closeUploadBaiModal },
  ] = useBoolean();
  const [
    isConfigModalOpen,
    { setTrue: openConfigModal, setFalse: closeConfigModal },
  ] = useBoolean(false);

  const showDeposits =
    transactionType === TransactionTypeEnum.DRAWS_AND_DEPOSITS ||
    transactionType === TransactionTypeEnum.DEPOSITS;
  const showDraws =
    transactionType === TransactionTypeEnum.DRAWS_AND_DEPOSITS ||
    transactionType === TransactionTypeEnum.DRAWS;

  const {
    bankAccountTransactions,
    bankDetails,
    isLoadingAccountDetails,
    isTransactionsQueryRunning,
    getAccountDetails,
    fetchNextPageOfTransactions,
    bankAccountStats,
  } = useBankingDetailData({
    accountId,
    amountFilterValue,
    descriptionFilterValue,
    endDate,
    isValidDateRange,
    periodFilter,
    propertyFilterValue: propertyFilterValue.map((property) => property.value),
    reconTypeFilter,
    showDeposits,
    showDraws,
    startDate,
  });

  // Keep header updated.
  useEffect(() => {
    if (P.isUndefined(bankDetails)) {
      return;
    }

    setBreadcrumbs([
      { href: "/accounting", title: "Accounting" },
      { href: "/accounting/banking", title: "Banking" },
      { title: bankDetails.name || `••••${bankDetails.mask}` },
    ]);
  }, [bankDetails, setBreadcrumbs]);

  const invalidateTransactionsQuery = useCallback(async () => {
    await queryClient.invalidateQueries(["BankingAPI.getTransactions"]);
  }, []);

  const refreshTransactions = useCallback(async () => {
    await getAccountDetails();
    if (isValidDateRange) {
      invalidateTransactionsQuery();
    }
  }, [getAccountDetails, invalidateTransactionsQuery, isValidDateRange]);

  const { table: bankRecTable } = useBankingDetailTable({
    bankAccountStats,
    columnFilters,
    data: bankAccountTransactions,
    details: bankDetails,
    fetchNextPageOfTransactions,
    isFetching: isTransactionsQueryRunning,
    onClearFilters,
    refreshTransactions,
    setBatchMatcherTransactionId,
  });

  // Sync table filter status with URL parameter.
  useEffect(() => {
    setReconTypeFilterValue(
      P.isNotNullable(status)
        ? BankRecRowTypeReconTypeMap[status as BankRecRowType]
        : undefined,
    );
  }, [status, setReconTypeFilterValue]);

  // re-fetch transactions when the date range changes.
  useEffect(() => {
    if (isValidDateRange) {
      queryClient.invalidateQueries(["BankingAPI.getTransactions"]);
    }
  }, [isValidDateRange]);

  // re-fetch transactions when reconciliation type changes.
  useEffect(() => {
    queryClient.invalidateQueries(["BankingAPI.getTransactions"]);
  }, [reconTypeFilter]);

  function handleTransactionTypeChange(val: TransactionType | Null) {
    if (P.isNotNull(val) && val in transactionTypeConfig) {
      setTransactionType(val);
      bankRecTable
        .getColumn("deposit")
        ?.setFilterValue(transactionTypeConfig[val].deposit);
      bankRecTable
        .getColumn("draw")
        ?.setFilterValue(transactionTypeConfig[val].draw);
    }
  }

  async function onPlaidUpdateClick() {
    await updatePlaidCredentials({
      accountId,
      onSuccess: () => {
        refreshTransactions();
      },
    });
  }

  function onAccountRemoved() {
    globalThis.location.assign("/accounting/banking");
  }

  return (
    <>
      <Skeleton visible={isLoadingAccountDetails}>
        <Stack spacing={Spacing.xs}>
          <Group align={Align.start} justify={Justify.between}>
            <Group>
              {P.isNotUndefined(bankDetails) && (
                <BankAccountCard
                  {...bankDetails}
                  fetchBankAccounts={getAccountDetails}
                  hasBankAccountRevealOption
                  onAccountRemoved={onAccountRemoved}
                  onPlaidUpdateClick={onPlaidUpdateClick}
                  ownerType={bankDetails.ownerType}
                  startDate={startDate}
                  endDate={endDate}
                  propertyFilterValue={propertyFilterValue.map(
                    (property) => property.value,
                  )}
                />
              )}
              <BankingDetailCategories
                amountSearchString={amountFilterValue}
                description={descriptionFilterValue}
                periodFilter={periodFilter}
                propertyIds={propertyFilterValue.map(
                  (property) => property.value,
                )}
                showDeposits={showDeposits}
                showDraws={showDraws}
              />
            </Group>
            <BankingDetailMenu
              isValidDateRange={isValidDateRange}
              openBaiHistoryModal={openBaiHistoryModal}
              openConfigModal={openConfigModal}
              openUploadBaiModal={openUploadBaiModal}
            />
          </Group>

          <Group align={Align.center} justify={Justify.between}>
            <BankingDetailFilters
              amountFilterValue={amountFilterValue}
              descriptionFilterValue={descriptionFilterValue}
              handleTransactionTypeChange={handleTransactionTypeChange}
              transactionType={transactionType}
              onAmountFilterChange={setAmountFilterValue}
              onDescriptionFilterChange={setDescriptionFilterValue}
              onPropertyFilterChange={setPropertyFilterValue}
              propertyFilterValue={propertyFilterValue}
            />
            <Stack>
              {P.isNotUndefined(bankDetails) && (
                <Text>
                  Reconciliation Start Date:{" "}
                  {P.isNotNullable(bankDetails.reconciliationStartDate) ? (
                    toLongSlashString(bankDetails.reconciliationStartDate)
                  ) : (
                    <Button
                      variant={ButtonVariant.transparent}
                      onClick={openConfigModal}>
                      None Configured
                    </Button>
                  )}
                </Text>
              )}
              <Group justify={Justify.end} spacing={Spacing.sm}>
                <PlaidAutoUpdate
                  details={bankDetails}
                  accountId={accountId}
                  refreshTransactions={refreshTransactions}
                />
                <BankingDetailDownloadMenu
                  accountId={accountId}
                  amountFilter={amountFilterValue}
                  descriptionFilter={descriptionFilterValue}
                  inclusiveEndDate={endDate}
                  typeFilter={reconTypeFilter}
                  periodFilter={periodFilter}
                  propertyFilter={propertyFilterValue.map(
                    (property) => property.value,
                  )}
                  showDraws={
                    transactionType ===
                      TransactionTypeEnum.DRAWS_AND_DEPOSITS ||
                    transactionType === TransactionTypeEnum.DRAWS
                  }
                  showDeposits={
                    transactionType ===
                      TransactionTypeEnum.DRAWS_AND_DEPOSITS ||
                    transactionType === TransactionTypeEnum.DEPOSITS
                  }
                  startDate={startDate}
                />
              </Group>
            </Stack>
          </Group>

          <BankingDetailList
            getBankTransactions={refreshTransactions}
            table={bankRecTable}
          />
        </Stack>
      </Skeleton>

      <Modal
        title=""
        opened={isBaiUploadHistoryModalOpen}
        onClose={closeBaiHistoryModal}>
        <BaiUploadHistory onSuccess={refreshTransactions} />
      </Modal>

      {batchMatcherTransactionId && (
        <Modal
          title=""
          opened={P.isNotNullable(batchMatcherTransactionId)}
          onClose={closeBatchMatcherModal}>
          <BankingDetailBatchMatcher
            bankAccountId={accountId}
            startDate={startDate}
            endDate={endDate}
            bankTransactionId={batchMatcherTransactionId}
            closeModal={closeBatchMatcherModal}
            onSuccess={refreshTransactions}
            propertyFilterValue={propertyFilterValue.map(
              (property) => property.value,
            )}
          />
        </Modal>
      )}

      {P.isNotNullable(bankDetails) && (
        <Modal title="" opened={isConfigModalOpen} onClose={closeConfigModal}>
          <BankReconciliationConfigureModal
            bankAccountId={bankDetails.id}
            closeModal={closeConfigModal}
            onSuccess={refreshTransactions}
            reconciliationStartingBalance={
              bankDetails.reconciliationStartingBalance
            }
            startDate={bankDetails.reconciliationStartDate}
          />
        </Modal>
      )}

      <UploadBaiModal
        opened={isUploadBaiModalOpen}
        onClose={closeUploadBaiModal}
        accountId={accountId}
        refreshTransactions={refreshTransactions}
      />
    </>
  );
}

export { BankingDetail };
