/**
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}/forcedMatches
 * @function createForcedMatches
 * @memberof BankingAPI
 * @param {BankingAPICreateForcedMatchesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ForcedReconMatch } from "@ender/shared/generated/ender.api.accounting.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPICreateForcedMatchesPathParams = {
  bankAccountId: EnderId;
};

type BankingAPICreateForcedMatchesSearchParams = {
  token?: string | undefined;
};

type BankingAPICreateForcedMatchesBodyParams = {
  matches: ForcedReconMatch[];
};

type BankingAPICreateForcedMatchesPayload =
  BankingAPICreateForcedMatchesPathParams &
    BankingAPICreateForcedMatchesSearchParams &
    BankingAPICreateForcedMatchesBodyParams;

function createForcedMatchesUnsafeEffect(
  payload: BankingAPICreateForcedMatchesPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}/forcedMatches`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createForcedMatches(
  payload: BankingAPICreateForcedMatchesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    createForcedMatchesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createForcedMatches };
export type {
  BankingAPICreateForcedMatchesBodyParams,
  BankingAPICreateForcedMatchesPathParams,
  BankingAPICreateForcedMatchesPayload,
  BankingAPICreateForcedMatchesSearchParams,
};
