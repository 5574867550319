import type { KeywordSearch } from "@ender/entities/search-input";
import type { EnderId } from "@ender/shared/core";
import { SearchAPI } from "@ender/shared/generated/ender.api.misc";
import { SearchServiceSearchTypeEnum } from "@ender/shared/generated/ender.service.search";

const searchTenants: KeywordSearch<EnderId> = (keyword: string) =>
  SearchAPI.omnisearch({
    excludeIds: [],
    keyword,
    propertyIds: [],
    resultsOnEmpty: true,
    types: [SearchServiceSearchTypeEnum.TENANT],
  }).then((res: { firstName: string; lastName: string; id: EnderId }[]) =>
    res.map((tenant) => ({
      label: `${tenant.firstName} ${tenant.lastName}`,
      meta: tenant,
      value: tenant.id,
    })),
  );

export { searchTenants };
