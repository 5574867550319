import { zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { Option as O, pipe } from "effect";
import { z } from "zod";

import { LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { DateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { Textarea } from "@ender/shared/ds/textarea";
import { useForm } from "@ender/shared/forms/hooks/general";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { LocalDateZodSchema, OptionSchema } from "@ender/shared/utils/zod";

import type { OpenPauseCollectionsFormProps } from "../collections.types";
import { useInvalidateDelinquencyTableData } from "../hooks/use-invalidate-delinquency-table-data";

const PauseCollectionsFormSchema = z.object({
  collectionsPausedNote: z.string().min(1),
  collectionsPausedUntilDate: OptionSchema(LocalDateZodSchema).refine(
    O.isSome,
    {
      message: "Exclude Until Date is required.",
    },
  ),
});

type PauseCollectionsInput = z.input<typeof PauseCollectionsFormSchema>;
type PauseCollectionsOutput = z.output<typeof PauseCollectionsFormSchema>;

function PauseCollectionsForm({
  leaseId,
  onClose,
}: OpenPauseCollectionsFormProps) {
  const invalidateDelinquencyTableData = useInvalidateDelinquencyTableData();

  const form = useForm<PauseCollectionsInput>({
    initialValues: {
      collectionsPausedNote: "",
      collectionsPausedUntilDate: O.none(),
    },
    validate: zodResolver(PauseCollectionsFormSchema),
  });

  const { isLoading, mutateAsync: pauseOrUnpauseCollections } = useMutation({
    mutationFn: ({
      collectionsPausedNote,
      collectionsPausedUntilDate,
    }: PauseCollectionsOutput) =>
      LeasingAPI.updateLease({
        collectionsPausedNote,
        collectionsPausedUntilDate: pipe(
          collectionsPausedUntilDate,
          O.map((val) => val.toJSON()),
          O.getOrThrow,
        ),
        leaseId,
      }),
    mutationKey: ["LeasingAPI.updateLease"] as const,
    onSuccess: async () => {
      showSuccessNotification({
        message: "Excluded lease from delinquency actions.",
      });
      onClose();
      invalidateDelinquencyTableData();
    },
  });

  async function handleFormSubmit(values: PauseCollectionsOutput) {
    await pauseOrUnpauseCollections(values);
  }

  return (
    <Stack spacing={Spacing.sm}>
      {/* @ts-expect-error Necessary expectation for onSubmit */}
      <UnmanagedForm form={form} onSubmit={handleFormSubmit}>
        <Stack spacing={Spacing.xl}>
          <Stack spacing={Spacing.sm}>
            <DateInput
              label="Exclude Until Date"
              {...form.getInputProps("collectionsPausedUntilDate")}
              minDate={LocalDate$.today().add({ days: 1 })}
            />
            <Textarea
              label="Notes for Exclusion"
              placeholder="Notes for Exclusion"
              {...form.getInputProps("collectionsPausedNote")}
            />
          </Stack>
          <Group justify={Justify.end}>
            <Button
              variant={ButtonVariant.outlined}
              type="button"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!form.isValid()}
              loading={isLoading}>
              Exclude from Delinquency Actions
            </Button>
          </Group>
        </Stack>
      </UnmanagedForm>
    </Stack>
  );
}

export { PauseCollectionsForm };
