import { Schema } from "@effect/schema";
import { Option as O, Predicate as P, String as S, pipe } from "effect";

import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import type { Undefined } from "@ender/shared/constants/general";
import type {
  EnderId,
  Instant,
  LocalDate,
  LocalDate$,
} from "@ender/shared/core";
import { EnderIdFormSchema, Money$ } from "@ender/shared/core";
import type { AccountingAPIGetJournalEntryResponse } from "@ender/shared/generated/ender.api.accounting";
import type {
  GetGLJournalEntryResponse,
  GetGLJournalEntryResponseAllocationResponse,
} from "@ender/shared/generated/ender.api.accounting.response";
import type { PropertySerializerDeepPropertyResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import type {
  AccountingPeriod,
  GLCategory,
  LedgerEventLedgerEventType,
} from "@ender/shared/generated/ender.model.accounting";
import { RecurringGLJournalEntryFrequencyEffectSchema } from "@ender/shared/generated/ender.model.accounting";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { castEnum } from "@ender/shared/utils/effect";

// This needs to be generated
const AllocationTypeValues = ["DEBIT", "CREDIT"] as const;
const AllocationTypeEffectSchema = Schema.Literal(...AllocationTypeValues);
type AllocationType = Schema.Schema.Type<typeof AllocationTypeEffectSchema>;
const AllocationTypeEnum = castEnum(AllocationTypeEffectSchema);
/*
   Tyler 11-15-24 -- Please note, credits and debits are not part of the payload.  
    These are fields which exist prior to form submission and effectively become the amount field. 
    This is necessary because the previous configuration used a single amount field but within the form system,
    this causes the Credits and Debits fields below to update simultaneously
  */
const JournalEntryFormAllocationSchema = Schema.Struct({
  amount: Schema.OptionFromSelf(MoneyEffectSchema),
  credits: Schema.OptionFromSelf(MoneyEffectSchema),
  debits: Schema.OptionFromSelf(MoneyEffectSchema),
  description: Schema.String,
  glCategoryId: Schema.OptionFromSelf(EnderIdFormSchema),
  isParentCategory: Schema.Boolean,
  type: AllocationTypeEffectSchema.pipe(
    Schema.filter((input): input is AllocationType => P.isNotNullable(input), {
      message: () => "Type is required.",
    }),
    Schema.optional,
  ),
});

type JournalEntryFormAllocationInput = Schema.Schema.Encoded<
  typeof JournalEntryFormAllocationSchema
>;
type JournalEntryFormAllocationOutput = Schema.Schema.Type<
  typeof JournalEntryFormAllocationSchema
>;

const PropertyIdWithAllocationsSchema = Schema.Struct({
  allocations: Schema.Array(JournalEntryFormAllocationSchema),
  propertyId: Schema.OptionFromSelf(EnderIdFormSchema),
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];
    if (O.isNone(values.propertyId)) {
      issues.push({ message: "Property is required.", path: ["propertyId"] });
    }
    values.allocations.forEach((allocation, index) => {
      if (O.isNone(allocation.glCategoryId)) {
        issues.push({
          message: "GL Category is required",
          path: [`allocations.${index}.glCategoryId`],
        });
      }
      if (
        O.isNone(allocation.debits) &&
        O.isNone(allocation.credits) &&
        O.isSome(allocation.glCategoryId)
      ) {
        issues.push({
          message: "Credit or Debit is required",
          path: [`allocations.${index}.credits`],
        });
        issues.push({
          message: "Credit or Debit is required",
          path: [`allocations.${index}.debits`],
        });
      }
    });

    return issues;
  }),
);

type PropertyIdWithAllocationsInput = Schema.Schema.Encoded<
  typeof PropertyIdWithAllocationsSchema
>;
type PropertyIdWithAllocationsOutput = Schema.Schema.Type<
  typeof PropertyIdWithAllocationsSchema
>;

const isDebitsAndCreditsBalanced = (
  input: readonly PropertyIdWithAllocationsOutput[],
) => {
  const sortedAllocations = input
    .flatMap(({ allocations }) => allocations)
    .filter(({ glCategoryId }) => O.isSome(glCategoryId))
    .map((allocation) =>
      O.map(
        allocation.amount,
        Money$.negateWhen(() => allocation.type === AllocationTypeEnum.CREDIT),
      ),
    );

  return pipe(
    sortedAllocations,
    O.reduceCompact(Money$.zero(), Money$.add),
    Money$.isZero,
  );
};

const JournalEntryFormSchema = Schema.Struct({
  allocationsWithProperties: Schema.Array(PropertyIdWithAllocationsSchema).pipe(
    Schema.filter((input) => isDebitsAndCreditsBalanced(input), {
      message: () => "Debits and credits must be balanced",
    }),
  ),
  endDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  frequency: RecurringGLJournalEntryFrequencyEffectSchema.pipe(
    Schema.OptionFromSelf,
  ),
  approver: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  ledgerDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => O.isSome(input),
      {
        message: () => "Date is required",
      },
    ),
  ),
  periodId: Schema.Struct({
    id: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
  title: Schema.String.pipe(
    Schema.filter(S.isNonEmpty, { message: () => "Entry Title is required" }),
  ),
}).pipe(
  Schema.filter((input) => {
    if (
      O.isSome(input.ledgerDate) &&
      O.isSome(input.endDate) &&
      input.endDate.value.isBefore(input.ledgerDate.value)
    ) {
      return {
        message: "End date must be after start date",
        path: ["endDate"],
      };
    }
  }),
);

type JournalEntryFormOutput = Schema.Schema.Type<typeof JournalEntryFormSchema>;

type JournalEntryAllocation = GetGLJournalEntryResponseAllocationResponse & {
  propertyId: EnderId;
  amount: string;
  description: string;
  glCategoryId: EnderId;
  id: EnderId;
};

type JournalEntryResponse = Pick<
  AccountingAPIGetJournalEntryResponse,
  | "approvalStatus"
  | "categories"
  | "credits"
  | "debits"
  | "description"
  | "generalLedgerDate"
  | "id"
  | "ledgerDate"
  | "LedgerEventType"
  | "niceId"
  | "period"
  | "pmId"
  | "property"
  | "specificLedgerDate"
  | "timestamp"
  | "title"
  | "txs"
> & {
  approvalStatus: ApprovableApprovalStatus;
  categories: GLCategory[];
  credits: JournalEntryAllocation[];
  debits: JournalEntryAllocation[];
  description: string;
  generalLedgerDate: LocalDate;
  id: EnderId;
  ledgerDate: LocalDate;
  ledgerEventType: LedgerEventLedgerEventType;
  niceId: string;
  period?: AccountingPeriod | Undefined;
  pmId: EnderId;
  property: PropertySerializerDeepPropertyResponse;
  specificLedgerDate: LocalDate;
  timestamp: Instant;
  title: string;
  txs: JournalEntryAllocation[];
};

type OptimizedGetJournalEntryByIdResponse = Omit<
  GetGLJournalEntryResponse,
  "generalLedgerDate" | "ledgerDate" | "specificLedgerDate"
> & {
  generalLedgerDate: LocalDate;
  ledgerDate: LocalDate;
  specificLedgerDate: LocalDate;
  txs: JournalEntryAllocation[];
  credits: JournalEntryAllocation[];
  debits: JournalEntryAllocation[];
  period?: { id: EnderId };
  title: string;
};

export {
  AllocationTypeEnum,
  JournalEntryFormAllocationSchema,
  JournalEntryFormSchema,
  PropertyIdWithAllocationsSchema,
};
export type {
  AllocationType,
  JournalEntryAllocation,
  JournalEntryFormAllocationInput,
  JournalEntryFormAllocationOutput,
  JournalEntryFormOutput,
  JournalEntryResponse,
  OptimizedGetJournalEntryByIdResponse,
  PropertyIdWithAllocationsInput,
  PropertyIdWithAllocationsOutput,
};
