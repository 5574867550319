/**
 * HTTP Method: POST
 * Pathname: /middleLayer/property-invoices
 * @function getPropertyInvoicesDetails
 * @memberof InvoicesMiddleLayerAPI
 * @param {InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertyInvoicesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PropertyInvoicesResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { ReportWidgetSortOrder } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsSearchParams = {
  token?: string | undefined;
};

type InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsBodyParams = {
  limit?: number | undefined;
  order?: ReportWidgetSortOrder | undefined;
  propertyIds: EnderId[];
  sortBy?: string | undefined;
};

type InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsPayload =
  InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsSearchParams &
    InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsBodyParams;

function getPropertyInvoicesDetailsUnsafeEffect(
  payload: InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertyInvoicesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<PropertyInvoicesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/property-invoices",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPropertyInvoicesDetails(
  payload: InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertyInvoicesResponse> {
  return F.pipe(
    payload,
    getPropertyInvoicesDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPropertyInvoicesDetails };
export type {
  InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsBodyParams,
  InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsPayload,
  InvoicesMiddleLayerAPIGetPropertyInvoicesDetailsSearchParams,
};
