import { IconPlus } from "@tabler/icons-react";
import { Fragment } from "react/jsx-runtime";

import { FormList } from "@ender/form-system/base";
import type { UseFormReturn } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { isMultiple } from "@ender/shared/utils/is";

import { emptyJournalEntryAllocationsWithProperty } from "../../pages/reports/journal-entry-form-right-rail/journal-entry-right-rail.constants";
import { PropertyWithAllocations } from "../../pages/reports/journal-entry-form-right-rail/property-with-allocations";

type JournalEntryAllocationsFormListProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  isEditing: boolean;
  isReadonly: boolean;
};

function JournalEntryAllocationsFormList({
  form,
  isEditing,
  isReadonly,
}: JournalEntryAllocationsFormListProps) {
  return (
    <Stack>
      <FormList form={form} name="allocationsWithProperties">
        {({ list, arrayMethods }) => {
          return list.map(({ name, key }, id) => {
            return (
              <Fragment key={key}>
                <PropertyWithAllocations
                  form={form}
                  name={name}
                  canRemove={isMultiple(list)}
                  isEditing={isEditing}
                  isReadonly={isEditing || isReadonly}
                  onRemoveProperty={() => arrayMethods.remove(id)}
                />
                <Group>
                  <Button
                    variant={ButtonVariant.outlined}
                    onClick={() =>
                      arrayMethods.append(
                        emptyJournalEntryAllocationsWithProperty,
                      )
                    }
                    disabled={isEditing || isReadonly}
                    leftSection={<IconPlus />}>
                    Add Property
                  </Button>
                </Group>
              </Fragment>
            );
          });
        }}
      </FormList>
    </Stack>
  );
}

export { JournalEntryAllocationsFormList };
