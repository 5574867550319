import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const RecurringServiceScheduleTypeValues = ["FIXED", "RELATIVE"] as const;

const RecurringServiceScheduleTypeEffectSchema = Schema.Literal(
  ...RecurringServiceScheduleTypeValues,
);

type RecurringServiceScheduleType = Schema.Schema.Type<
  typeof RecurringServiceScheduleTypeEffectSchema
>;

const RecurringServiceScheduleTypeEnum = castEnum(
  RecurringServiceScheduleTypeEffectSchema,
);

function randomRecurringServiceScheduleType(): RecurringServiceScheduleType {
  return rand(RecurringServiceScheduleTypeValues);
}

export {
  randomRecurringServiceScheduleType,
  RecurringServiceScheduleTypeEffectSchema,
  RecurringServiceScheduleTypeEnum,
  RecurringServiceScheduleTypeValues,
};
export type { RecurringServiceScheduleType };
