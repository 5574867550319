/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/moved-out-complete
 * @function moveOutComplete
 * @memberof LeasingAPI
 * @param {LeasingAPIMoveOutCompletePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { LeaseMoveOutReason } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIMoveOutCompletePathParams = {
  leaseId: EnderId;
};

type LeasingAPIMoveOutCompleteSearchParams = {
  token?: string | undefined;
};

type LeasingAPIMoveOutCompleteBodyParams = {
  actualMoveOutDate?: SerializesInto<LocalDate> | undefined;
  moveOutReason?: LeaseMoveOutReason | undefined;
  overrideWarnings?: boolean | undefined;
};

type LeasingAPIMoveOutCompletePayload = LeasingAPIMoveOutCompletePathParams &
  LeasingAPIMoveOutCompleteSearchParams &
  LeasingAPIMoveOutCompleteBodyParams;

function moveOutCompleteUnsafeEffect(
  payload: LeasingAPIMoveOutCompletePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/moved-out-complete`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function moveOutComplete(
  payload: LeasingAPIMoveOutCompletePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    moveOutCompleteUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { moveOutComplete };
export type {
  LeasingAPIMoveOutCompleteBodyParams,
  LeasingAPIMoveOutCompletePathParams,
  LeasingAPIMoveOutCompletePayload,
  LeasingAPIMoveOutCompleteSearchParams,
};
