/**
 * HTTP Method: POST
 * Pathname: /factorGroups/getLockedGroups
 * @function getLockedGroups
 * @memberof FactorsAPI
 * @param {FactorsAPIGetLockedGroupsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIGetLockedGroupsSearchParams = {
  token?: string | undefined;
};

type FactorsAPIGetLockedGroupsBodyParams = {
  modelId: EnderId;
  modelType: ModelType;
};

type FactorsAPIGetLockedGroupsPayload = FactorsAPIGetLockedGroupsSearchParams &
  FactorsAPIGetLockedGroupsBodyParams;

function getLockedGroupsUnsafeEffect(
  payload: FactorsAPIGetLockedGroupsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string[]>({
        body,
        decode: unsafeDecodeJsonResponse<string[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/factorGroups/getLockedGroups",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLockedGroups(
  payload: FactorsAPIGetLockedGroupsPayload,
  options?: { signal?: AbortSignal },
): Promise<string[]> {
  return F.pipe(
    payload,
    getLockedGroupsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLockedGroups };
export type {
  FactorsAPIGetLockedGroupsBodyParams,
  FactorsAPIGetLockedGroupsPayload,
  FactorsAPIGetLockedGroupsSearchParams,
};
