import { useMutation } from "@tanstack/react-query";
import type { FC } from "react";

import type { EnderId } from "@ender/shared/core";
import { MoveInAPI } from "@ender/shared/generated/ender.api.leasing";
import { MoveInConfigRowCapturedDataTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import type { ChecklistStep } from "@ender/shared/types/move-in";

import type {
  ChecklistStepComponentProps,
  UpdateChecklistStepProps,
} from "./checklist-steps.types";
import { BooleanStep } from "./move-in-boolean-step";
import { DateStep } from "./move-in-date-step";
import { DefaultStep } from "./move-in-default-step";
import { MoneyStep } from "./move-in-money-step";
import { NumberStep } from "./move-in-number-step";
import { TextStep } from "./move-in-text-step";

const ChecklistStepMap = {
  [MoveInConfigRowCapturedDataTypeEnum.NONE]: DefaultStep,
  [MoveInConfigRowCapturedDataTypeEnum.BOOLEAN]: BooleanStep,
  [MoveInConfigRowCapturedDataTypeEnum.DATE]: DateStep,
  [MoveInConfigRowCapturedDataTypeEnum.MONEY]: MoneyStep,
  [MoveInConfigRowCapturedDataTypeEnum.NUMBER]: NumberStep,
  [MoveInConfigRowCapturedDataTypeEnum.TEXT]: TextStep,
} as const;

type GenericChecklistStepProps = {
  moveInComplete: boolean;
  checklistStep: ChecklistStep;
  updateLocalChecklistStep: (props: UpdateChecklistStepProps) => void;
  refetchSectionStatus: () => void;
};

type ChecklistStepComponent = FC<ChecklistStepComponentProps>;

function GenericChecklistStep(props: GenericChecklistStepProps) {
  const {
    moveInComplete,
    checklistStep,
    updateLocalChecklistStep,
    refetchSectionStatus,
  } = props;
  const { id, capturedDataType, ...stepProps } = checklistStep;

  const Step = ChecklistStepMap[capturedDataType] as ChecklistStepComponent;

  const { mutateAsync: updateChecklistStep, isLoading: isUpdateStepLoading } =
    useMutation({
      mutationFn: ({
        stepId,
        completed,
        capturedData,
      }: {
        stepId: EnderId;
        completed: boolean;
        capturedData?: string;
      }) => MoveInAPI.updateMoveInStep({ stepId, completed, capturedData }),
      mutationKey: ["MoveInAPI.updateMoveInStep"] as const,
    });

  async function handleChange(props: UpdateChecklistStepProps) {
    updateLocalChecklistStep(props);
    await updateChecklistStep({ stepId: id, ...props });
    refetchSectionStatus();
  }

  return (
    <Step
      completed={moveInComplete}
      {...stepProps}
      onChange={handleChange}
      isLoading={isUpdateStepLoading}
      aria-label="Move In Checklist Step"
    />
  );
}

export { GenericChecklistStep };
export type {
  ChecklistStepComponentProps,
  GenericChecklistStepProps,
  UpdateChecklistStepProps,
};
