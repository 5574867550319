import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Function as F } from "effect";
import type { MouseEvent } from "react";
import { forwardRef } from "react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Inset } from "@ender/shared/ds/inset";
import { Text } from "@ender/shared/ds/text";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { Color } from "@ender/shared/utils/theming";

type ApplicantFileProps = {
  file: FilesClientEnderFile;
  canDelete?: boolean;
  onDelete?: (file: FilesClientEnderFile) => void;
  canEdit?: boolean;
  onEdit?: (file: FilesClientEnderFile) => void;
};

/**
 * //TODO this component should be replaced with a generalized "FileDisplay" component non-specific to applicants.
 * It should be able to display files with the ability to view, edit, and delete them. The specific behavior needs
 * to be defined by design and needs to be reconciled with the existing "FileTuple" used within the File Upload component.
 */
const ApplicantFile = forwardRef<HTMLAnchorElement, ApplicantFileProps>(
  function ApplicantFile(props, ref) {
    const {
      file,
      canDelete,
      onDelete = F.constVoid,
      canEdit,
      onEdit = F.constVoid,
    } = props;
    const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onDelete(file);
    };
    const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onEdit(file);
    };
    return (
      <a
        target="_blank"
        className="border border-slate-200 rounded bg-slate-50 px-4 py-2 cursor-pointer"
        href={file.s3Url}
        ref={ref}>
        <Group justify={Justify.between} noWrap>
          <Text>{file.name}</Text>
          <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.sm}>
            <Group noWrap>
              {canEdit && (
                <ActionIcon
                  onClick={handleEdit}
                  variant={ButtonVariant.transparent}
                  color={Color.slate}>
                  <IconEdit />
                </ActionIcon>
              )}
              {canDelete && (
                <ActionIcon
                  onClick={handleDelete}
                  variant={ButtonVariant.transparent}
                  color={Color.slate}>
                  <IconTrash />
                </ActionIcon>
              )}
            </Group>
          </Inset>
        </Group>
      </a>
    );
  },
);

export { ApplicantFile };

export type { ApplicantFileProps };
