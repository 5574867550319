import { Predicate as P } from "effect";
import type { ReactNode } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { RerenderOnDepsChange } from "@ender/shared/ui/rerender-on-deps-change";

import type { ApprovalProcessHybridId } from "../types";
import { DEFAULT_APPROVAL_PROCESS_HYBRID_ID } from "../types";
import { ApprovalProcessRuleActionIconsMenu } from "./approval-process-rule-action-icons-menu";
import { ApprovalProcessEstimateRuleFiltersForm } from "./estimate-filters-form";
import { ApprovalProcessRuleFiltersForm } from "./filters-form";

import styles from "./filters.module.css";

type ApprovalProcessRuleFiltersProps = {
  approvalProcess: GetApprovalProcessResponse & { id: ApprovalProcessHybridId };
  ownershipGroupId?: EnderId;
  missingEditPermissions?: ReactNode;
  onCreateSuccess: ({
    approvalProcessId,
  }: {
    approvalProcessId: ApprovalProcessHybridId;
  }) => void;
  onDeleteApprovalProcessSuccess: () => void;
  refetchData: () => Promise<void>;
  isDeleting?: boolean | Undefined;
  setIsDeleting?: (val: boolean) => void;
  type: ApprovalProcessType;
};

function ApprovalProcessRuleFilters({
  approvalProcess,
  ownershipGroupId,
  missingEditPermissions,
  onCreateSuccess,
  onDeleteApprovalProcessSuccess,
  refetchData,
  isDeleting,
  setIsDeleting,
  type,
}: ApprovalProcessRuleFiltersProps) {
  const isDefaultApprovalProcess =
    approvalProcess?.id === DEFAULT_APPROVAL_PROCESS_HYBRID_ID;
  const hasEditPermission = P.isNullable(missingEditPermissions);

  const isTaskEstimateApprovalProcess =
    type === ApprovalProcessTypeEnum.ESTIMATE &&
    P.isNotNullable(ownershipGroupId);

  return (
    <div className={styles.rule}>
      <Stack>
        <Group justify={Justify.between} align={Align.center}>
          <H2>Filters</H2>
          <ApprovalProcessRuleActionIconsMenu
            approvalProcessId={approvalProcess.id}
            hasEditPermission={hasEditPermission}
            onDeleteApprovalProcessSuccess={onDeleteApprovalProcessSuccess}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
          />
        </Group>
        <>
          {isDefaultApprovalProcess && (
            <div>
              Any {isTaskEstimateApprovalProcess ? "estimate" : "invoice"} that
              does not fall under a custom rule will default to this approval
              process.
            </div>
          )}
          {!isDefaultApprovalProcess &&
            !hasEditPermission &&
            missingEditPermissions}
          {!isDefaultApprovalProcess && hasEditPermission && (
            <RerenderOnDepsChange deps={[approvalProcess]}>
              {!isTaskEstimateApprovalProcess && (
                <ApprovalProcessRuleFiltersForm
                  refetchData={refetchData}
                  approvalProcess={approvalProcess}
                  type={type}
                  onCreateSuccess={onCreateSuccess}
                />
              )}
              {isTaskEstimateApprovalProcess && (
                <ApprovalProcessEstimateRuleFiltersForm
                  refetchData={refetchData}
                  approvalProcess={approvalProcess}
                  type={type}
                  ownershipGroupId={ownershipGroupId}
                  onCreateSuccess={onCreateSuccess}
                />
              )}
            </RerenderOnDepsChange>
          )}
        </>
      </Stack>
    </div>
  );
}

export { ApprovalProcessRuleFilters };
