import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Array as A, Predicate as P, pipe } from "effect";
import type { CSSProperties, ReactElement, ReactNode } from "react";
import { memo, useCallback, useMemo, useState } from "react";

import type { InvoicePage } from "@ender/shared/api/invoices";
import type { EnderId } from "@ender/shared/core";
import type { ScreenRange } from "@ender/shared/ui/screen-size";
import { ScreenRangeEnum, useScreenRange } from "@ender/shared/ui/screen-size";

import { InvoiceFilePreview } from "./invoice-file-preview";

import styles from "./invoice-file-carousel.module.css";

type HookProps = {
  selectedInvoicePageList?: InvoicePage | InvoicePage[];
  screenRange: ScreenRange;
  invoicePageCount: number;
};
type PureProps = {
  invoicePageList: InvoicePage[];
  isMobile?: boolean;
  selectedInvoicePageIdSet: Set<EnderId>;
  singleItemScroll?: boolean;
  emptyContent?: ReactNode;
  currentIndex?: number;
  onBack: () => unknown;
  onForward: () => unknown;
  onSelectInvoicePage?: (args: { page: InvoicePage }) => void;
  onDeleteInvoicePage?: () => void;
  style?: { gridArea?: CSSProperties["gridArea"] };
};
type ImpureProps = {
  invoicePageList: InvoicePage[];
  selectedInvoicePageList?: InvoicePage | InvoicePage[];
  singleItemScroll?: boolean;
  emptyContent?: ReactNode;
  onSelectInvoicePage?: (args: { page: InvoicePage }) => void;
  onDeleteInvoicePage?: () => void;
  style?: { gridArea?: CSSProperties["gridArea"] };
};

const useInvoiceFileCarouselState = ({
  screenRange,
  invoicePageCount,
  selectedInvoicePageList,
}: HookProps): Pick<
  PureProps,
  | "isMobile"
  | "selectedInvoicePageIdSet"
  | "currentIndex"
  | "onBack"
  | "onForward"
> => {
  const isMobile = screenRange !== ScreenRangeEnum.LARGE;
  const selectedInvoicePageIdSet = useMemo(
    () =>
      new Set<EnderId>(
        pipe(
          A.ensure(selectedInvoicePageList),
          A.filter((val) => !P.isNullable(val)),
          A.map((sip) => sip.id),
        ),
      ),
    [selectedInvoicePageList],
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const onBack = useCallback(
    () =>
      setCurrentIndex((index) => (index > 0 ? index : invoicePageCount) - 1),
    [invoicePageCount, setCurrentIndex],
  );
  const onForward = useCallback(
    () => setCurrentIndex((index) => (index + 1) % invoicePageCount),
    [invoicePageCount, setCurrentIndex],
  );

  return useMemo(
    () => ({
      isMobile,
      selectedInvoicePageIdSet,
      currentIndex,
      onBack,
      onForward,
    }),
    [currentIndex, isMobile, onBack, onForward, selectedInvoicePageIdSet],
  );
};

const InvoiceFileCarouselPure = memo<PureProps>(function InvoiceFileCarousel({
  invoicePageList,
  isMobile = false,
  selectedInvoicePageIdSet,
  emptyContent = null,
  singleItemScroll = false,
  currentIndex = 0,
  onBack,
  onForward,
  onSelectInvoicePage,
  onDeleteInvoicePage,
  style = {},
}: PureProps): ReactElement<PureProps> {
  return (
    <div
      className={clsx(
        { [styles.singleItemScroll]: singleItemScroll },
        styles.invoiceFileCarousel,
      )}
      style={style}>
      {invoicePageList.map((invoicePage, index) => (
        <InvoiceFilePreview
          key={invoicePage.id}
          invoicePage={invoicePage}
          defaultShowActions={isMobile}
          isSelected={!!selectedInvoicePageIdSet.has(invoicePage.id)}
          isHidden={singleItemScroll && index !== currentIndex}
          onSelectInvoicePage={onSelectInvoicePage}
          onDeleteInvoicePage={onDeleteInvoicePage}
          style={{ gridArea: "content" }}
        />
      ))}
      {!singleItemScroll || invoicePageList.length <= 1 ? null : (
        <div className={styles.chevronWrapper}>
          <IconChevronLeft
            className={styles.leftChevron}
            size="3rem"
            onClick={onBack}
          />
          <IconChevronRight
            className={styles.rightChevron}
            size="3rem"
            onClick={onForward}
          />
        </div>
      )}
      {emptyContent == null || invoicePageList.length > 0 ? null : (
        <div className={styles.emptyPage}>{emptyContent}</div>
      )}
    </div>
  );
});

function InvoiceFileCarousel({
  invoicePageList,
  selectedInvoicePageList,
  singleItemScroll,
  emptyContent,
  onSelectInvoicePage,
  onDeleteInvoicePage,
  style,
}: ImpureProps): ReactElement<ImpureProps> {
  const screenRange = useScreenRange();
  const {
    isMobile,
    selectedInvoicePageIdSet,
    currentIndex,
    onBack,
    onForward,
  } = useInvoiceFileCarouselState({
    screenRange: screenRange ?? ScreenRangeEnum.LARGE,
    invoicePageCount: invoicePageList.length,
    selectedInvoicePageList,
  });
  return (
    <InvoiceFileCarouselPure
      invoicePageList={invoicePageList}
      isMobile={isMobile}
      selectedInvoicePageIdSet={selectedInvoicePageIdSet}
      emptyContent={emptyContent}
      singleItemScroll={singleItemScroll}
      currentIndex={currentIndex}
      onBack={onBack}
      onForward={onForward}
      onSelectInvoicePage={onSelectInvoicePage}
      onDeleteInvoicePage={onDeleteInvoicePage}
      style={style}
    />
  );
}

export {
  InvoiceFileCarousel,
  InvoiceFileCarouselPure,
  useInvoiceFileCarouselState,
};
