import { IconTrash } from "@tabler/icons-react";
import { Array as A, Option as O, Record as R, pipe } from "effect";
import { useWatch } from "react-hook-form";

import { Form, FormList } from "@ender/form-system/base";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H1, H3 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { PaymentPlanPaymentPlanTypeValues } from "@ender/shared/generated/ender.model.misc";
import { isMultiple } from "@ender/shared/utils/is";
import { capitalize } from "@ender/shared/utils/string";

import { PaymentPlanTypeToLabelMap } from "./payment-plans.types";
import type { CreatePaymentPlanFormOutput } from "./use-create-payment-plan-form";
import {
  emptyInstallment,
  useCreatePaymentPlanForm,
} from "./use-create-payment-plan-form";
import { useLeaseUpcomingCharges } from "./use-lease-upcoming-charges";

const paymentPlanProposedByOptions = PaymentPlanPaymentPlanTypeValues.map(
  (value) => ({
    label: PaymentPlanTypeToLabelMap[value],
    value,
  }),
);

type CreatePaymentPlanFormProps = {
  balanceDue: Money$.Money;
  leaseId: EnderId;
  isCreatingPaymentPlan: boolean;
  onFormSubmit: (values: CreatePaymentPlanFormOutput) => void;
  onCancelClick: () => unknown;
  showHeading: boolean;
};

function CreatePaymentPlanForm({
  balanceDue,
  leaseId,
  isCreatingPaymentPlan,
  onFormSubmit,
  onCancelClick,
  showHeading,
}: CreatePaymentPlanFormProps) {
  const { form } = useCreatePaymentPlanForm();

  const [installments, startDate] = useWatch({
    control: form.control,
    name: ["installments", "startDate"],
  });

  const { relevantCharges, totalExpectedAmount } = useLeaseUpcomingCharges({
    balanceDue,
    installments,
    leaseId,
    startDate,
    paymentPlanStartDate: O.none(),
  });

  const sumOfPayments = pipe(
    installments,
    A.map((i) => i.amount),
    O.reduceCompact(Money$.zero(), Money$.add),
  );

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack spacing={Spacing.lg}>
        {showHeading && <H1>Create Payment Plan</H1>}
        <FormSelect
          form={form}
          label="Proposed By"
          name="type"
          data={paymentPlanProposedByOptions}
        />
        <FormDateInput form={form} label="Start Date" name="startDate" />
        <FormList form={form} name="installments">
          {({ list, arrayMethods }) => (
            <>
              <Group justify={Justify.between} align={Align.end}>
                <H3>Payments</H3>
                <Button
                  variant={ButtonVariant.outlined}
                  onClick={() => arrayMethods.append(emptyInstallment)}>
                  Add Payment
                </Button>
              </Group>
              {list.map(({ name, key }, idx) => {
                return (
                  <Card key={key}>
                    <Stack spacing={Spacing.sm}>
                      <Group justify={Justify.between}>
                        <Text size={FontSize.lg} weight={FontWeight.medium}>
                          Payment {idx + 1}
                        </Text>
                        <ActionIcon
                          tooltip="Delete Installment"
                          disabled={
                            !isMultiple([...form.getValues().installments])
                          }
                          disabledTooltip="At least one installment is required"
                          variant={ButtonVariant.transparent}
                          onClick={() => arrayMethods.remove(idx)}>
                          <IconTrash color={EnderThemeColorEnum.RED_500} />
                        </ActionIcon>
                      </Group>
                      <Grid spacingY={Spacing.xs}>
                        <Tuple
                          label="Amount"
                          value={
                            <FormMoneyInput
                              name={`${name}.amount`}
                              form={form}
                            />
                          }
                        />
                        <Tuple
                          label="Due Date"
                          value={
                            <FormDateInput
                              name={`${name}.dueDate`}
                              form={form}
                              minDate={LocalDate$.today().add({ days: 1 })}
                            />
                          }
                        />
                      </Grid>
                    </Stack>
                  </Card>
                );
              })}
            </>
          )}
        </FormList>
        <Stack spacing={Spacing.sm}>
          <Tuple
            label="Current Balance"
            value={<MoneyDisplay value={balanceDue} />}
          />
          {pipe(
            R.toEntries(relevantCharges),
            A.map(([month, amount]) => (
              <Tuple
                key={month}
                label={`${capitalize(month)} Charges`}
                value={<MoneyDisplay value={amount} />}
              />
            )),
          )}
          <Tuple
            label="Total Expected on Due Date"
            value={<MoneyDisplay value={totalExpectedAmount} />}
          />
          <Tuple
            label="Sum of Payments"
            value={<MoneyDisplay value={sumOfPayments} />}
          />
          <Tuple
            label="Difference"
            value={
              <Text
                weight={FontWeight.medium}
                color={
                  !Money$.Equivalence(totalExpectedAmount, sumOfPayments)
                    ? "red-500"
                    : "slate-900"
                }>
                <MoneyDisplay
                  value={Money$.subtract(totalExpectedAmount, sumOfPayments)}
                />
              </Text>
            }
          />
        </Stack>
        <Group justify={Justify.end}>
          <Button variant={ButtonVariant.transparent} onClick={onCancelClick}>
            Cancel
          </Button>
          <Button
            type="submit"
            loading={isCreatingPaymentPlan}
            disabled={!form.formState.isValid}
            disabledTooltip="Please fill in all required fields before submitting.">
            Create
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { CreatePaymentPlanForm };

export type { CreatePaymentPlanFormProps };
