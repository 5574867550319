import { IconPlus, IconSearch } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import type { FilterFn } from "@tanstack/react-table";
import { Array as A, Option as O, String as S, pipe } from "effect";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Inset } from "@ender/shared/ds/inset";
import { Stack } from "@ender/shared/ds/stack";
import { Switch } from "@ender/shared/ds/switch";
import { TabButton, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { TextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { MarketsAPI } from "@ender/shared/generated/com.ender.buy.api";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { OwnershipGroupsAPI } from "@ender/shared/generated/ender.api.misc";
import type { ListingListingStatus } from "@ender/shared/generated/ender.model.leasing";
import { ListingListingStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import type { LeasingServiceListingResponse } from "@ender/shared/generated/ender.service.leasing";
import { cast } from "@ender/shared/types/cast";
import type { LabelValue } from "@ender/shared/types/label-value";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { EnderMultiSelect } from "@ender/shared/ui/ender-multi-select";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import {
  EnderTableTanstack,
  MoneyCell,
  asColumnDef,
  useTable,
  useTableColumnVisibility,
  useTableFilter,
  useTableSorting,
} from "@ender/shared/ui/table-tanstack";

import styles from "./pages-leasing-listings-page.module.css";

const columns: ColumnDef<LeasingServiceListingResponse>[] = [
  asColumnDef({
    accessorKey: "id",
    cell: (props) => {
      return (
        <EnderLink to={`/listings/${props.getValue()}`}>View Listing</EnderLink>
      );
    },
    className: styles.noWrap,
    enableSorting: false,
    header: "Listing",
  }),
  asColumnDef({
    header: "Ownership Group",
    id: "ownershipGroup",
    accessorKey: "ownershipGroupName",
    cell: (props) => props.getValue() ?? "-",
  }),
  asColumnDef({
    header: "Market",
    id: "market",
    accessorKey: "property.marketName",
  }),
  asColumnDef({
    header: "Property",
    accessorKey: "property",
    filterFn: "includesString",
    id: "property",
    className: styles.noWrap,
    cell: (props) => (
      <EnderLink to={`/properties/${props.row.original.property.id}`}>
        {props.row.original.property.name}
      </EnderLink>
    ),
    sortingFn: (a, b) =>
      a.original.property.name.localeCompare(b.original.property.name),
  }),
  asColumnDef({
    header: "Unit",
    accessorKey: "unit",
    className: styles.noWrap,
    enableColumnFilter: false,
    cell: (props) => (
      <EnderLink to={`/units/${props.row.original.unit.id}`}>
        {props.row.original.unit.name}
      </EnderLink>
    ),
    sortingFn: (a, b) =>
      a.original.unit.name.localeCompare(b.original.unit.name),
  }),
  asColumnDef({
    header: "Asking Rent",
    accessorKey: "advertisedRent",
    cell: MoneyCell<LeasingServiceListingResponse>,
    className: styles.amount,
    sortingFn: (a, b) => {
      const rentA = Money$.of(a.original.advertisedRent);
      const rentB = Money$.of(b.original.advertisedRent);
      return Money$.Order(rentA, rentB);
    },
  }),
  asColumnDef({
    header: "Beds",
    accessorKey: "bedrooms",
  }),
  asColumnDef({
    header: "Baths",
    accessorKey: "baths",
    sortingFn: (a, b) => {
      const bathsA = a.original.fullBaths + a.original.halfBaths / 2;
      const bathsB = b.original.fullBaths + b.original.halfBaths / 2;
      return bathsA - bathsB;
    },
    cell: (props) => {
      const full = props.row.original.fullBaths;
      const half = props.row.original.halfBaths;
      const label = full ? [`${full} Full`] : [];
      half && label.push(`${half} Half`);
      return (
        <Tooltip label={label.join(", ")}>
          <div>{full + half / 2}</div>
        </Tooltip>
      );
    },
  }),
  asColumnDef({
    header: "Sqft",
    accessorKey: "sqft",
  }),
  asColumnDef({
    header: "Days Listed",
    accessorKey: "daysListed",
  }),
  asColumnDef({
    header: "Days Vacant",
    accessorKey: "daysVacant",
  }),
  asColumnDef({
    header: "Prospects",
    accessorKey: "numProspects",
  }),
  asColumnDef({
    header: "Applications",
    accessorKey: "numSubmittedApplications",
  }),
  asColumnDef({
    header: "Showings",
    accessorKey: "numShowings",
  }),
];

const globalFilterFn: FilterFn<LeasingServiceListingResponse> = (
  row,
  _,
  filter,
) => {
  const { id, property, unit, advertisedRent } = row.original;
  return [
    id,
    property.name,
    unit.name,
    pipe(
      advertisedRent,
      Money$.parse,
      O.map(Money$.toDollars),
      O.getOrUndefined,
    ),
  ].some((value) =>
    `${value ?? ""}`.toLowerCase().includes(filter.toLowerCase()),
  );
};

const tabLabels = [
  { label: "Active Listings", value: ListingListingStatusEnum.LISTED },
  { label: "Inactive Listings", value: ListingListingStatusEnum.UNLISTED },
  { label: "Drafts", value: ListingListingStatusEnum.DRAFTING },
] as const;

function useGetMarketsLabelValues(): LabelValue<EnderId>[] {
  const { data: markets = [] } = useQuery({
    queryKey: ["MarketsAPI.getMarkets"] as const,
    queryFn: ({ signal }) => MarketsAPI.getMarkets({}, { signal }),
  });

  return useMemo(
    () =>
      // @ts-expect-error - data is not typed
      markets.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [markets],
  );
}

function ListingsPage() {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState<ListingListingStatus>(
    ListingListingStatusEnum.LISTED,
  );

  /**
   * market column should be invisible by default and only shown if there are listings that have it
   */
  const columnVisibility = useTableColumnVisibility({
    initialVisibility: { market: false, unit: false },
  });

  const { data: ownershipGroups = [] } = useQuery({
    queryKey: ["OwnershipGroupsAPI.getOwnershipGroups"] as const,
    queryFn: ({ signal }) =>
      OwnershipGroupsAPI.getOwnershipGroups({}, { signal }),
    select: (resp) => resp.ownershipGroups,
  });
  const [ownershipGroupIds, setOwnershipGroupIds] = useState<EnderId[]>([]);

  const marketOptions = useGetMarketsLabelValues();
  const [marketIds, setMarketIds] = useState<EnderId[]>([]);
  const [isFeatured, setIsFeatured] = useState<boolean>(false);

  const { data = [], isLoading: isTableDataFetching } = useQuery({
    queryKey: [
      "LeasingAPI.searchListings",
      {
        isFeatured: isFeatured || undefined, //HACK if isFeatured isn't set, we want it to be ignored. This means the true boolean state is "true | undefined"
        marketIds,
        ownershipGroupIds,
        status: activeTab,
      },
    ] as const,
    queryFn: ({ signal, queryKey: [, params] }) =>
      LeasingAPI.searchListings(
        { ...params, propertyIds: [], unitIds: [] },
        { signal },
      ),
  });

  //necessary to replace onSuccess in useQuery
  useEffect(() => {
    if (A.isEmptyArray(data)) {
      return;
    }

    const hasMFR = data.some(
      (listing) => (listing.property.declaredNumUnits ?? 0) > 1,
    );
    columnVisibility.onColumnVisibilityChange((val) => ({
      ...val,
      unit: hasMFR,
      market: data.some((listing) => S.isNonEmpty(listing.property.marketName)),
    }));
  }, [data, columnVisibility]);

  const sorting = useTableSorting({
    // manualSorting: true,
  });

  const columnFilters = useTableFilter<LeasingServiceListingResponse>({
    // enableGlobalFilter: true,
    initialGlobalFilter: "",
    globalFilterFn,
  });

  const table = useTable<LeasingServiceListingResponse>({
    columnFilters,
    columnVisibility,
    columns,
    data,
    sorting,
  });

  return (
    <Stack spacing={Spacing.lg}>
      <H1>Listings</H1>
      <Group align={Align.center}>
        <TextInput
          label="Filter by Property, Unit, or Advertised Rent"
          placeholder="Search"
          leftSection={<IconSearch />}
          onChange={table.setGlobalFilter}
          value={columnFilters.globalFilter ?? ""}
        />
        {A.isNonEmptyArray(ownershipGroups) && (
          <EnderMultiSelect
            label="Ownership Groups"
            data={ownershipGroups.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            onChange={(values) => setOwnershipGroupIds(cast(values))}
            placeholder="All Funds"
            clearable
            searchable
          />
        )}
        {marketOptions.length > 0 && (
          <EnderMultiSelect
            label="Markets"
            data={marketOptions}
            value={marketIds}
            onChange={(values) => setMarketIds(cast(values))}
            placeholder="All Markets"
            clearable
            searchable
          />
        )}
        <Inset b="lg">
          <Switch
            label="Featured"
            value={isFeatured}
            onChange={(e) => setIsFeatured(e)}
          />
        </Inset>
      </Group>
      <Tabs onChange={setActiveTab} value={activeTab}>
        <TabsList>
          {tabLabels.map(({ label, value }) => (
            <TabButton value={value} key={value}>
              {label}
            </TabButton>
          ))}
        </TabsList>
      </Tabs>
      <EnderTableTanstack
        hideGlobalFilter
        title="Listings"
        table={table}
        isLoading={isTableDataFetching}
        actions={
          <ActionIcon
            onClick={() => history.push("/leasing-center/listings/new")}
            label="Add Listing">
            <IconPlus />
          </ActionIcon>
        }
      />
    </Stack>
  );
}

export { ListingsPage };
