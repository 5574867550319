import { useQuery } from "@tanstack/react-query";
import { Array as A, Option as O, Predicate as P, pipe } from "effect";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";

import type { UseFormReturn } from "@ender/form-system/base";
import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1, H2, H3 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Select } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { TemplatesAPI } from "@ender/shared/generated/ender.api.documents";
import { TextTemplateTemplateTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import { RichTextEditor } from "@ender/shared/ui/rich-text-editor";
import { fail } from "@ender/shared/utils/error";
import type { RefundRecipientInput } from "@ender/widgets/finance/tenant-allocations";
import { TenantAllocations } from "@ender/widgets/finance/tenant-allocations";

import type { AugmentedMoveOutLease } from "../../../../widgets/move-out/types";
import type { MoveOutFormInput } from "../move-out-form.schema";
import { useMoveOut } from "../use-move-out";
import { Deposits } from "./deposits";
import { RemainingCharges } from "./remaining-charges";

type MoveOutBalanceSectionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<MoveOutFormInput, any, any>;
  lease?: AugmentedMoveOutLease;
};

function saveMoveOutNotificationLetter(
  leaseId: EnderId,
  notificationLetter: string,
) {
  const key = `notificationLetter:${leaseId}`;
  localStorage.setItem(key, notificationLetter);
}

const MoveOutBalanceSection = forwardRef(function MoveOutBalanceSection(
  props: MoveOutBalanceSectionProps,
  _ref,
) {
  const { lease, form } = props;
  const {
    balanceAmount,
    chargesTotalAmount,
    deposits,
    depositsTotalAmount,
    financiallyResponsibleTenants,
    ledgerData,
    primaryTenant,
    remainingCharges,
  } = useMoveOut({
    lease,
  });

  const { data: templateOptions } = useQuery({
    queryKey: ["TemplatesAPI.listTemplates"] as const,
    queryFn: async ({ signal }) => {
      const templates = await TemplatesAPI.listTemplates(
        {
          type: TextTemplateTemplateTypeEnum.LETTER,
        },
        { signal },
      );
      return templates.map((template) => ({
        label: template.name,
        value: template.id,
      }));
    },
  });
  const [notificationTemplate, setNotificationTemplate] = useState<
    EnderId | Null
  >(NULL);
  const onNotificationTemplateChange = useCallback(
    async (value: EnderId | Null) => {
      if (P.isNull(value)) {
        return;
      }

      try {
        setNotificationTemplate(value ?? "");
        if (!value || P.isNullable(lease?.id)) {
          return;
        }

        const template = await TemplatesAPI.renderTemplate({
          modelId: lease.id,
          modelType: ModelTypeEnum.LEASE,
          templateId: value,
        });

        form.setValue("notificationLetter", template.html);
        if (lease?.id) {
          saveMoveOutNotificationLetter(lease.id, template.html);
        }
      } catch (err) {
        fail(err);
      }
    },
    [form, lease?.id],
  );

  const [
    refundRecipients,
    isForAllTenants,
    allTenantsAmount,
    notificationLetter,
    actualMoveOutDate,
  ] = useWatch({
    control: form.control,
    name: [
      "refundRecipients",
      "isForAllTenants",
      "allTenantsAmount",
      "notificationLetter",
      "physicalMoveOut.actualMoveOutDate",
    ],
  });

  const refundTotal = useMemo(
    () =>
      isForAllTenants
        ? allTenantsAmount
        : Money$.sum([
            ...refundRecipients.map(
              (recipient) => O.getOrNull(recipient.amount) ?? Money$.zero(),
            ),
          ]),
    [allTenantsAmount, isForAllTenants, refundRecipients],
  );

  const { setValue } = form;
  const handleNotificationLetterChange = useCallback(
    (value: string) => {
      setValue("notificationLetter", value);
      if (lease?.id) {
        saveMoveOutNotificationLetter(lease.id, value);
      }
    },
    [setValue, lease],
  );

  const handleCurrencyInputChange = useCallback(
    (
      value: O.Option<Money$.Money>,
      oldValue: RefundRecipientInput,
      position: number,
    ) => {
      setValue(`refundRecipients.${position}`, {
        ...oldValue,
        amount: value ?? O.fromNullable(Money$.zero()),
      });
    },
    [setValue],
  );
  const resetAllIndividualRecipientsAmounts = useCallback(() => {
    refundRecipients.forEach((_, position) => {
      setValue(
        `refundRecipients.${position}.amount`,
        position === 0
          ? O.fromNullable(balanceAmount.abs())
          : O.fromNullable(Money$.zero()),
      );
    });
  }, [balanceAmount, refundRecipients, setValue]);
  const setAllIndividualRecipientsAmountsToZero = useCallback(() => {
    refundRecipients.forEach((_, position) => {
      setValue(
        `refundRecipients.${position}.amount`,
        O.fromNullable(Money$.zero()),
      );
    });
  }, [refundRecipients, setValue]);

  const handleChangeIsForAllTenants = useCallback(
    (isForAllTenants: boolean) => {
      setValue("isForAllTenants", isForAllTenants);
      if (isForAllTenants) {
        setValue("allTenantsAmount", balanceAmount.abs());
        setAllIndividualRecipientsAmountsToZero();
      } else {
        resetAllIndividualRecipientsAmounts();
        setValue("allTenantsAmount", Money$.zero());
      }
    },
    [
      balanceAmount,
      resetAllIndividualRecipientsAmounts,
      setAllIndividualRecipientsAmountsToZero,
      setValue,
    ],
  );

  return (
    <Card>
      <Stack>
        <Skeleton
          visible={P.isNullable(templateOptions) || P.isNullable(ledgerData)}>
          <H1>Balance</H1>
          {ledgerData && (
            <>
              <RemainingCharges
                remainingCharges={remainingCharges}
                chargesTotalAmount={chargesTotalAmount}
              />
              <Deposits
                deposits={deposits}
                depositsTotalAmount={depositsTotalAmount}
              />
              <Group justify={Justify.between}>
                <H3>
                  {Money$.isNegative(balanceAmount)
                    ? "Owed to Tenant"
                    : "Tenant Owes"}
                </H3>
                <H3>
                  <MoneyDisplay
                    showSymbol
                    value={Money$.parse(balanceAmount.abs())}
                  />
                </H3>
              </Group>
            </>
          )}
          {Money$.isNegative(balanceAmount) && (
            <Stack>
              <H2>Refund Recipient</H2>
              <Text size={FontSize.sm}>
                The property owner owes {primaryTenant.name} a total of{" "}
                {pipe(
                  balanceAmount,
                  Money$.abs,
                  Money$.toFormatted(Money$.Formats.DEFAULT),
                )}
                . Please select how you would like to refund the tenant.
              </Text>
              <Tuple
                label="Accrual Date"
                value={<DateDisplay value={actualMoveOutDate} />}
              />
              <TenantAllocations
                allTenantsAmount={allTenantsAmount}
                balanceAmount={balanceAmount}
                financiallyResponsibleTenants={financiallyResponsibleTenants}
                handleChangeIsForAllTenants={handleChangeIsForAllTenants}
                handleCurrencyInputChange={handleCurrencyInputChange}
                isForAllTenants={isForAllTenants}
                refundRecipients={refundRecipients}
                refundTotal={refundTotal}
              />
              <Tuple
                label={
                  <Text size={FontSize.lg}>Amount Remaining to Allocate</Text>
                }
                value={
                  <Text size={FontSize.lg}>
                    <MoneyDisplay
                      showSymbol
                      value={Money$.parse(
                        balanceAmount.abs().subtract(refundTotal).toFormatted(),
                      )}
                    />
                  </Text>
                }
              />
            </Stack>
          )}
          <Card>
            <Stack>
              <H2>Security Deposit Notification</H2>
              <Group justify={Justify.center}>
                <Stack>
                  <div style={{ width: "300px" }}>
                    {P.isNotNullable(templateOptions) &&
                      A.isNonEmptyArray(templateOptions) && (
                        <Select<EnderId>
                          value={O.fromNullable(notificationTemplate)}
                          onChange={(val) =>
                            void onNotificationTemplateChange(O.getOrNull(val))
                          }
                          data={templateOptions ?? []}
                          label="Select a template"
                          clearable
                        />
                      )}
                  </div>
                  <RichTextEditor
                    value={notificationLetter}
                    onChange={handleNotificationLetterChange}
                  />
                </Stack>
              </Group>
            </Stack>
          </Card>
        </Skeleton>
      </Stack>
    </Card>
  );
});

export { MoveOutBalanceSection };
