/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/prospect/{userId}/applicationUrl
 * @function getApplicationUrl
 * @memberof LeasingAPI
 * @param {LeasingAPIGetApplicationUrlPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetApplicationUrlResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetApplicationUrlResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetApplicationUrlPathParams = {
  unitId: EnderId;
  userId: EnderId;
};

type LeasingAPIGetApplicationUrlSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetApplicationUrlPayload =
  LeasingAPIGetApplicationUrlPathParams &
    LeasingAPIGetApplicationUrlSearchParams;

function getApplicationUrlUnsafeEffect(
  payload: LeasingAPIGetApplicationUrlPayload,
) {
  const { unitId, userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetApplicationUrlResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetApplicationUrlResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/prospect/${userId}/applicationUrl`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getApplicationUrl(
  payload: LeasingAPIGetApplicationUrlPayload,
  options?: { signal?: AbortSignal },
): Promise<GetApplicationUrlResponse> {
  return F.pipe(
    payload,
    getApplicationUrlUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getApplicationUrl };
export type {
  LeasingAPIGetApplicationUrlPathParams,
  LeasingAPIGetApplicationUrlPayload,
  LeasingAPIGetApplicationUrlSearchParams,
};
