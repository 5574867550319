import { useForm } from "@mantine/form";
import { IconEdit, IconList } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Option as O, Order as Od, Predicate as P } from "effect";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { NULL } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId, Money } from "@ender/shared/core";
import { Instant$, LocalDate$, Money$, Percent$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { DateInput } from "@ender/shared/ds/date-input";
import { Divider } from "@ender/shared/ds/divider";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type {
  RenewalsMiddleLayerAPICreateRenewedLeasePayload,
  RenewalsMiddleLayerAPISendLimitedOffersPayload,
} from "@ender/shared/generated/com.ender.middle";
import { RenewalsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import { TemplatesAPI } from "@ender/shared/generated/ender.api.documents";
import type {
  LeasingAPIUpdateLeasePayload,
  RenewalsAPIAcceptOfferPayload,
  RenewalsAPICreateDraftingRenewalOffersPayload,
  RenewalsAPINoRenewPayload,
  RenewalsAPIRejectAllOffersPayload,
  RenewalsAPIRescindAllOffersPayload,
  RenewalsAPIUnRescindRecentOffersPayload,
} from "@ender/shared/generated/ender.api.leasing";
import {
  LeasingAPI,
  RenewalsAPI,
} from "@ender/shared/generated/ender.api.leasing";
import type { GetRenewalsRequestRenewalTableTab } from "@ender/shared/generated/ender.api.leasing.request";
import { GetRenewalsRequestRenewalTableTabEnum } from "@ender/shared/generated/ender.api.leasing.request";
import type { LeaseMoveOutReason } from "@ender/shared/generated/ender.model.leasing";
import {
  LeaseIntentionEnum,
  LeaseMoveOutReasonValues,
  TextTemplateTemplateTypeEnum,
} from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import {
  convertSnakeCaseToTitleCase,
  replacePath,
} from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

import { EditRenewalOffers } from "./edit-renewal-offers";
import { SendRenewalOfferForm } from "./renewal-actions/send-renewal-offer-form";
import { RenewalOfferTable } from "./renewal-offer-table";
import type { RenewalActionsProps, RenewalPackageType } from "./renewals.types";
import {
  getOfferDurationFromDates,
  updateNewRentPercentage,
} from "./renewals.utils";

type RenewalActionProps = {
  heading?: ReactNode;
  description?: ReactNode;
};

function RenewalAction(props: PropsWithChildren<RenewalActionProps>) {
  const { heading, description, children } = props;

  return (
    <Stack spacing={Spacing.sm}>
      {!heading ? <Divider /> : <H3>{heading}</H3>}
      {description && <p style={{ maxWidth: "40rem" }}>{description}</p>}
      {children}
    </Stack>
  );
}

function SetOffers(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchRenewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);

  const { mutateAsync: createDraftingRenewalOffers, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPICreateDraftingRenewalOffersPayload) =>
      RenewalsAPI.createDraftingRenewalOffers(payload).then(() =>
        refetchRenewalPackage(),
      ),
    mutationKey: [
      "RenewalsAPI.createDraftingRenewalOffers",
      refetchRenewalPackage,
    ],
  });

  const onSetOffers = useCallback(() => {
    createDraftingRenewalOffers({
      draftingRenewalOffers: renewalPackage.newRenewalOffers,
      leaseId: lease.id,
    });
  }, [createDraftingRenewalOffers, lease.id, renewalPackage.newRenewalOffers]);

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.NEEDS_OFFERS
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Set Offers"
      description="Set the offers once all desired offers have been created. This will lock the offers and allow the leasing agent to send the renewal package to the resident.">
      <Group>
        <Button
          onClick={onSetOffers}
          loading={isLoading}
          disabled={
            !hasPermissions(FunctionalPermissionEnum.SET_RENEWAL_RENT) ||
            A.isEmptyArray(renewalPackage.newRenewalOffers)
          }>
          Set Offers
        </Button>
      </Group>
    </RenewalAction>
  );
}

function SendOffers(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchRenewalPackage } = props;

  const initialOrFollowUpOffer = A.isEmptyArray(
    renewalPackage.previousRenewalOffers,
  );

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS
  ) {
    return NULL;
  }

  const initialOrFollowUpString = initialOrFollowUpOffer
    ? "Initial Renewal"
    : "Follow-Up Renewal";
  return (
    <RenewalAction
      heading={`Send ${initialOrFollowUpString} Offers`}
      description="If the renewal package and additional charges are correct, send offers to resident(s).">
      <Group>
        <SendRenewalOfferForm
          lease={lease}
          refetchRenewalPackage={refetchRenewalPackage}
        />
      </Group>
    </RenewalAction>
  );
}

const RequestNewOfferStatuses = new Set<GetRenewalsRequestRenewalTableTab>([
  GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS,
  GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING,
]);

// type RequestNewOfferFormValues = {
//   leaseId: EnderId;
//   notes: string;
// };

function RequestNewOffer(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchRenewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);
  // const [opened, setOpened] = useBoolean(false);

  // const form = useForm<RequestNewOfferFormValues>({
  //   initialValues: {
  //     leaseId: lease.id,
  //     notes: "",
  //   },
  //   validate: {
  //     notes: (value) => !S.isNonEmpty(value) && "Note is required",
  //   },
  // });

  // const onRequestNewOffer = useCallback(
  //   (values: RequestNewOfferFormValues) => {
  //     rescindAllOffers(values).then(setOpened.setFalse);
  //   },
  //   [rescindAllOffers, setOpened.setFalse],
  // );

  const { mutateAsync: rescindAllOffers, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPIRescindAllOffersPayload) =>
      RenewalsAPI.rescindAllOffers(payload).then(() => refetchRenewalPackage()),
    mutationKey: ["RenewalsAPI.rescindAllOffers", refetchRenewalPackage],
  });

  if (!RequestNewOfferStatuses.has(renewalPackage.renewalStatus)) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Request New Offers"
      description="If you think the the renewal package should be adjusted, send back to cost analysis.">
      <Group>
        <Button
          variant={ButtonVariant.outlined}
          loading={isLoading}
          disabled={
            !hasPermissions(FunctionalPermissionEnum.SEND_RENEWAL_OFFER)
          }
          onClick={() => rescindAllOffers({ leaseId: lease.id })}>
          Request New Offers
        </Button>
      </Group>
      {/* <EnderModal opened={opened} onClose={setOpened.setFalse} title="Request New Offer">
        <form onSubmit={form.onSubmit(onRequestNewOffer)}>
          <Stack>
            <p>
              This will send a notification to the Cost Analyst to consider reducing the offer package to below the
              $30.00 reduction.
            </p>
            <Textarea
              label="Note for Cost Analyst"
              placeholder="Enter input here"
              {...form.getInputProps("notes")}
              loading={isLoading}
            />
            <Group justify={Justify.end}>
              <Button variant={ButtonVariant.transparent} onClick={setOpened.setFalse} loading={isLoading}>
                Cancel
              </Button>
              <Button type="submit" loading={isLoading}>
                Request New Offer
              </Button>
            </Group>
          </Stack>
        </form>
      </EnderModal> */}
    </RenewalAction>
  );
}

type ChangeIntentionFormValues = {
  leaseId: EnderId;
  notes: string;
};

function ChangeIntention(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchLease, refetchRenewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);
  const [opened, setOpened] = useBoolean(false);

  const form = useForm<ChangeIntentionFormValues>({
    initialValues: {
      leaseId: lease.id,
      notes: "",
    },
  });

  const { mutateAsync: changeIntention, isLoading } = useMutation({
    mutationFn: () =>
      LeasingAPI.updateLease({
        intention: LeaseIntentionEnum.NO_RENEW,
        leaseId: lease.id,
      }).then(() => Promise.all([refetchRenewalPackage(), refetchLease()])),
    mutationKey: ["RenewalsAPI.noRenew", refetchLease, lease.id],
  });

  const onIntendNotToRenew = useCallback(() => {
    changeIntention().then(setOpened.setFalse);
  }, [changeIntention, setOpened.setFalse]);

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Change Intention of Renewal"
      description='You can change intention of the lease renewal to "do not renew," if you would no longer like to renew this lease. This will change the status to "intended to not renew."'>
      <Group>
        <Button
          color={Color.red}
          variant={ButtonVariant.outlined}
          loading={isLoading}
          disabled={
            !hasPermissions(FunctionalPermissionEnum.SEND_RENEWAL_OFFER)
          }
          onClick={setOpened.setTrue}>
          Mark as Intended not to Renew
        </Button>
      </Group>
      <Modal
        opened={opened}
        onClose={setOpened.setFalse}
        title="Mark as Intent to Not Renew">
        <form onSubmit={form.onSubmit(onIntendNotToRenew)}>
          <Stack>
            <p>
              Intend to not Renew if you would no longer like to renew this
              lease. This will change the status to “intend to not renew.”
            </p>
            {/* <Textarea
              label="Note for not renewing (optional)"
              placeholder="Enter input here"
              disabled={isLoading}
              {...form.getInputProps("notes")}
            /> */}
            <Group justify={Justify.end}>
              <Button
                variant={ButtonVariant.transparent}
                onClick={setOpened.setFalse}
                disabled={isLoading}>
                Cancel
              </Button>

              <Button color={Color.red} type="submit" loading={isLoading}>
                Intend to Not Renew
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </RenewalAction>
  );
}

function ResidentAccepted(props: RenewalActionsProps) {
  const { renewalPackage, refetchLease, refetchRenewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);
  const [opened, setOpened] = useBoolean(false);

  const { mutateAsync: acceptOffer, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPIAcceptOfferPayload) =>
      RenewalsAPI.acceptOffer(payload).then(() =>
        Promise.all([refetchRenewalPackage(), refetchLease()]),
      ),
    mutationKey: ["RenewalsAPI.acceptOffer", refetchLease],
  });

  const onAcceptOffer = useCallback(
    (offerId: EnderId) => {
      acceptOffer({ offerId }).then(setOpened.setFalse);
    },
    [acceptOffer, setOpened.setFalse],
  );

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.AWAITING_RESPONSE
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Resident Accepted"
      description="If the Resident has communicated the acceptance of the renewal offer, mark as accepted.">
      <Group>
        <Button
          color={Color.green}
          variant={ButtonVariant.outlined}
          disabled={
            !hasPermissions(FunctionalPermissionEnum.SEND_RENEWAL_OFFER)
          }
          onClick={setOpened.setTrue}>
          Accept Offer
        </Button>
      </Group>
      <Modal
        opened={opened}
        onClose={setOpened.setFalse}
        title="Select Which Offer was accepted">
        <Stack>
          {renewalPackage.currentRenewalOffers.map((offer) => (
            <Card key={offer.id}>
              <Stack>
                <Stack spacing={Spacing.none}>
                  <Tuple
                    label="Duration"
                    value={getOfferDurationFromDates(
                      offer.newStartDate,
                      offer.newEndDate,
                    )}
                    borderless
                  />
                  {P.isNotNullable(offer.newEndDate) && (
                    <Tuple
                      label="End Date"
                      value={
                        <DateDisplay
                          value={LocalDate$.add(
                            LocalDate$.of(offer.newEndDate),
                            { days: -1 },
                          )}
                        />
                      }
                    />
                  )}
                  <Tuple label="Rent Amount" value={offer.newRent} />
                  <Tuple label="Processing Fee" value={offer.processingFee} />
                  <Divider />
                </Stack>
                <Group justify={Justify.end}>
                  <Button
                    color={Color.green}
                    variant={ButtonVariant.outlined}
                    onClick={() => onAcceptOffer(offer.id)}
                    loading={isLoading}>
                    Accept Offer
                  </Button>
                </Group>
              </Stack>
            </Card>
          ))}
        </Stack>
      </Modal>
    </RenewalAction>
  );
}

type ResidentRejectedFormValues = {
  leaseId: EnderId;
  moveOutReason: O.Option<LeaseMoveOutReason>;
};

const MoveOutReasonOptions = LeaseMoveOutReasonValues.map((value) => ({
  key: value,
  label: convertSnakeCaseToTitleCase(value),
  value,
}));

function RejectModal(
  props: RenewalActionsProps & ComponentProps<typeof Modal>,
) {
  const { lease, refetchRenewalPackage, opened, onClose } = props;

  const form = useForm<ResidentRejectedFormValues>({
    initialValues: {
      leaseId: lease.id,
      moveOutReason: O.none(),
    },
    validate: {
      moveOutReason: (value) =>
        O.isNone(value) && "Reason for Rejection is required",
    },
  });

  const { mutateAsync: rejectAllOffers, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPIRejectAllOffersPayload) =>
      RenewalsAPI.rejectAllOffers(payload).then(() => refetchRenewalPackage()),
    mutationKey: ["RenewalsAPI.rejectAllOffers", refetchRenewalPackage],
  });

  const onResidentRejected = useCallback(
    (values: ResidentRejectedFormValues) => {
      rejectAllOffers({
        leaseId: values.leaseId,
        moveOutReason: O.getOrThrow(values.moveOutReason),
      }).then(onClose);
    },
    [rejectAllOffers, onClose],
  );

  return (
    <Modal opened={opened} onClose={onClose} title="Resident Rejects Offer">
      <form onSubmit={form.onSubmit(onResidentRejected)}>
        <Stack>
          <Select
            label="Reason for Rejection"
            placeholder="Select Dropdown"
            data={MoveOutReasonOptions}
            disabled={isLoading}
            {...form.getInputProps("moveOutReason")}
          />
          <Group justify={Justify.end}>
            <Button
              variant={ButtonVariant.transparent}
              onClick={onClose}
              disabled={isLoading}>
              Cancel
            </Button>
            <Button color={Color.red} type="submit" loading={isLoading}>
              Reject Offers
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

function ResidentRejected(props: RenewalActionsProps) {
  const { renewalPackage } = props;
  const [opened, setOpened] = useBoolean(false);
  const { hasPermissions } = useContext(UserContext);

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.AWAITING_RESPONSE
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Resident Rejected"
      description=" If the Resident has communicated that they have rejected all offers and they are not willing to accept any others, mark as rejected.">
      <Group>
        <Button
          color={Color.red}
          variant={ButtonVariant.outlined}
          disabled={
            !hasPermissions(FunctionalPermissionEnum.SEND_RENEWAL_OFFER)
          }
          onClick={setOpened.setTrue}>
          Mark Offer Rejected
        </Button>
      </Group>
      <RejectModal
        title="Resident Rejects Offer"
        opened={opened}
        onClose={setOpened.setFalse}
        {...props}
      />
    </RenewalAction>
  );
}

function getNewRentMap(
  renewalPackage: RenewalPackageType,
  limitedOfferAmount: Money$.Money,
): Record<EnderId, Money> {
  return renewalPackage.currentRenewalOffers.reduce(
    (newRents, offer) => ({
      ...newRents,
      [offer.id]: Money$.of(offer.newRent)
        .subtract(limitedOfferAmount)
        .toJSON(),
    }),
    {},
  );
}

function getProcessingFeeMap(
  renewalPackage: RenewalPackageType,
  newRents: Record<EnderId, Money>,
  processingFeePercent: Percent$,
): Record<EnderId, Money> {
  return renewalPackage.currentRenewalOffers.reduce((fees, offer) => {
    const newRent = Money$.of(newRents[offer.id]);
    const currentProcessingFee = Money$.of(offer.processingFee ?? 0);
    const discountedProcessingFee = Money$.of(
      newRent.valueInCents * processingFeePercent.value,
    );
    const newProcessingFee = Od.min(Money$.Order)(
      discountedProcessingFee,
      currentProcessingFee,
    );

    return { ...fees, [offer.id]: newProcessingFee.toJSON() };
  }, {});
}

function SendLimitedOffers(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchRenewalPackage } = props;
  const { hasPermissions, userPM } = useContext(UserContext);
  const canEdit = hasPermissions(FunctionalPermissionEnum.SEND_RENEWAL_OFFER);
  const [editing, setEditing] = useBoolean(false);

  const limitedOfferAmount = useMemo(
    () => Money$.of(userPM.limitedRenewalOfferAmount ?? "$30.00"),
    [userPM.limitedRenewalOfferAmount],
  );

  const processingFeePercent = useMemo(
    () => Percent$.of(userPM.renewalOfferProcessingFeePercent),
    [userPM.renewalOfferProcessingFeePercent],
  );

  const newRents = useMemo(
    () => getNewRentMap(renewalPackage, limitedOfferAmount),
    [renewalPackage, limitedOfferAmount],
  );

  const [processingFees, setProcessingFees] = useState(
    getProcessingFeeMap(renewalPackage, newRents, processingFeePercent),
  );

  // update the processingFees if the currentRenewalOffers change
  useEffect(() => {
    setProcessingFees(
      getProcessingFeeMap(renewalPackage, newRents, processingFeePercent),
    );
  }, [renewalPackage, newRents, processingFeePercent]);

  const { mutateAsync: sendLimitedOffers, isLoading } = useMutation({
    mutationFn: (payload: RenewalsMiddleLayerAPISendLimitedOffersPayload) =>
      RenewalsMiddleLayerAPI.sendLimitedOffers(payload).then(() =>
        refetchRenewalPackage(),
      ),
    mutationKey: ["sendLimitedOffers", refetchRenewalPackage],
  });

  const onSendLimitedOffers = useCallback(
    () => sendLimitedOffers({ leaseId: lease.id, processingFees }),
    [lease.id, sendLimitedOffers, processingFees],
  );

  const limitedOffers = useMemo(
    () =>
      renewalPackage.currentRenewalOffers.map((offer) =>
        updateNewRentPercentage(
          {
            ...offer,
            newRent: newRents[offer.id],
            processingFee: processingFees[offer.id],
          },
          renewalPackage.currentRent,
        ),
      ),
    [
      renewalPackage.currentRenewalOffers,
      renewalPackage.currentRent,
      newRents,
      processingFees,
    ],
  );

  if (
    renewalPackage.renewalStatus !==
      GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING ||
    renewalPackage.isLimitedOfferSent
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Limited New Offers"
      description={`You may send a new offer of ${limitedOfferAmount.toJSON()} less than the original offer without sending back for cost analysis. Lower offers will need to be sent back to Cost Analysis.`}>
      <br />
      <Group justify={Justify.end}>
        {canEdit &&
          (editing ? (
            <Button
              variant={ButtonVariant.transparent}
              leftSection={<IconList />}
              onClick={setEditing.setFalse}>
              List
            </Button>
          ) : (
            <Button
              variant={ButtonVariant.transparent}
              leftSection={<IconEdit />}
              onClick={setEditing.setTrue}>
              Edit
            </Button>
          ))}
      </Group>
      {editing ? (
        <EditRenewalOffers
          leaseEndDate={renewalPackage.leaseEndDate}
          offers={limitedOffers}
          onProcessingFeeUpdate={(offer) =>
            setProcessingFees((offers) => ({
              ...offers,
              [offer.id]: offer.processingFee,
            }))
          }
        />
      ) : (
        <RenewalOfferTable offers={limitedOffers} />
      )}
      <br />
      <Group>
        <Button
          variant={ButtonVariant.outlined}
          onClick={onSendLimitedOffers}
          loading={isLoading}
          disabled={!canEdit}>
          Send Limited Offers
        </Button>
      </Group>
    </RenewalAction>
  );
}

type SetMoveOutDateFormValues = {
  leaseId: EnderId;
  expectedMoveOutDate: O.Option<LocalDate$.LocalDate>;
  noticeOfMoveOutDate: O.Option<LocalDate$.LocalDate>;
};

function SetMoveOut(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchLease, refetchRenewalPackage } = props;

  const moveOutForm = useForm<SetMoveOutDateFormValues>({
    initialValues: {
      leaseId: lease.id,
      expectedMoveOutDate: LocalDate$.parse(
        lease.expectedMoveOutDate ?? lease.inclusiveEndDate,
      ),
      noticeOfMoveOutDate: O.orElse(
        LocalDate$.parse(lease.noticeOfMoveOutDate),
        () =>
          O.some(
            Instant$.of(
              renewalPackage.currentRenewalOffers[0]?.rejectedTimestamp,
            ).toLocalDate(),
          ),
      ),
    },
  });

  const { mutateAsync: updateLease, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: (payload: LeasingAPIUpdateLeasePayload) =>
      LeasingAPI.updateLease(payload).then(() =>
        Promise.all([refetchRenewalPackage(), refetchLease()]),
      ),
    mutationKey: ["LeasingAPI.updateLease", refetchLease, lease.id],
  });

  const onSetMoveOut = useCallback(
    (values: SetMoveOutDateFormValues) => {
      updateLease({
        leaseId: values.leaseId,
        expectedMoveOutDate: O.getOrThrow(values.expectedMoveOutDate).toJSON(),
        noticeOfMoveOutDate: O.getOrThrow(values.noticeOfMoveOutDate).toJSON(),
      });
    },
    [updateLease],
  );

  if (
    renewalPackage.renewalStatus !==
      GetRenewalsRequestRenewalTableTabEnum.NEEDS_MOVE_OUT &&
    renewalPackage.renewalStatus !==
      GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Set Move Out"
      description="If no new offer can be agreed to, set the resident(s) move out date.">
      <Group>
        <form onSubmit={moveOutForm.onSubmit(onSetMoveOut)}>
          <Stack>
            <DateInput
              label="Move Out Date"
              {...moveOutForm.getInputProps("expectedMoveOutDate")}
            />
            <DateInput
              label="Notice Provided Date"
              maxDate={LocalDate$.today()}
              {...moveOutForm.getInputProps("noticeOfMoveOutDate")}
            />
            <Group>
              <Button
                type="submit"
                loading={isLoadingUpdate}
                disabled={
                  O.isNone(moveOutForm.values.expectedMoveOutDate) ||
                  O.isNone(moveOutForm.values.noticeOfMoveOutDate)
                }>
                Set Move Out
              </Button>
            </Group>
          </Stack>
        </form>
      </Group>
    </RenewalAction>
  );
}

function SendBackWithNewOffer(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchRenewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);

  const { mutateAsync: createDraftingRenewalOffers, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPICreateDraftingRenewalOffersPayload) =>
      RenewalsAPI.createDraftingRenewalOffers(payload).then(() =>
        refetchRenewalPackage(),
      ),
    mutationKey: [
      "RenewalsAPI.createDraftingRenewalOffers",
      refetchRenewalPackage,
    ],
  });

  const onSetOffers = useCallback(() => {
    createDraftingRenewalOffers({
      draftingRenewalOffers: renewalPackage.newRenewalOffers,
      leaseId: lease.id,
    });
  }, [createDraftingRenewalOffers, lease.id, renewalPackage.newRenewalOffers]);

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Send Back With New Offer"
      description="Set the offers once all desired offers have been created. This will lock the offers and allow the leasing agent to send the renewal package to the resident.">
      <Group>
        <Button
          loading={isLoading}
          disabled={
            !hasPermissions(FunctionalPermissionEnum.SET_RENEWAL_RENT) ||
            A.isEmptyArray(renewalPackage.newRenewalOffers)
          }
          onClick={onSetOffers}>
          Set Offers
        </Button>
      </Group>
    </RenewalAction>
  );
}

function SendBackWithoutNewOffer(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchRenewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);

  const { mutateAsync: unRescindRecentOffers, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPIUnRescindRecentOffersPayload) =>
      RenewalsAPI.unRescindRecentOffers(payload).then(() =>
        refetchRenewalPackage(),
      ),
    mutationKey: ["RenewalsAPI.unRescindRecentOffers", refetchRenewalPackage],
  });

  const onSetOffers = useCallback(() => {
    unRescindRecentOffers({ leaseId: lease.id });
  }, [unRescindRecentOffers, lease.id]);

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Send Back Without New Offer"
      description="If no new reasonable offer can be set, send back without setting a new offer.">
      <Group>
        <Button
          color={Color.red}
          variant={ButtonVariant.outlined}
          onClick={onSetOffers}
          loading={isLoading}
          disabled={!hasPermissions(FunctionalPermissionEnum.SET_RENEWAL_RENT)}>
          Send Back Without New Offers
        </Button>
      </Group>
    </RenewalAction>
  );
}

type RenewLeaseFormValues = {
  leaseId: EnderId;
};

function RenewLease(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchLease, refetchRenewalPackage } = props;
  const acceptedOffer = renewalPackage.currentRenewalOffers.find((offer) =>
    P.isNotNullable(offer.acceptedTimestamp),
  );
  const confirmation = useConfirmationContext();
  const createRenewedLeaseWithWarnings = withWarningHandler(
    RenewalsMiddleLayerAPI.createRenewedLease,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );

  const form = useForm<RenewLeaseFormValues>({
    initialValues: {
      leaseId: lease.id,
    },
  });

  const { mutateAsync: createRenewedLease, isLoading } = useMutation({
    mutationFn: (payload: RenewalsMiddleLayerAPICreateRenewedLeasePayload) =>
      createRenewedLeaseWithWarnings(payload).then(() =>
        Promise.all([refetchRenewalPackage(), refetchLease()]),
      ),
    mutationKey: ["RenewalsMiddleLayerAPI.createRenewedLease", refetchLease],
  });

  const onRenewLease = useCallback(
    (values: RenewLeaseFormValues) => createRenewedLease(values),
    [createRenewedLease],
  );

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.ACCEPTED
  ) {
    return NULL;
  }

  return (
    <RenewalAction heading="Draft Lease">
      <form onSubmit={form.onSubmit(onRenewLease)}>
        <Stack align={Align.start}>
          <Stack spacing={Spacing.none}>
            <Tuple
              label="Current Lease End"
              value={
                <DateDisplay value={LocalDate$.parse(lease.inclusiveEndDate)} />
              }
            />
            <Tuple
              label="New Lease Start"
              value={
                <DateDisplay
                  value={LocalDate$.parse(acceptedOffer?.newStartDate)}
                />
              }
            />
            <Tuple
              label="New Lease End"
              value={
                <DateDisplay
                  value={LocalDate$.add(
                    LocalDate$.of(acceptedOffer?.newEndDate),
                    { days: -1 },
                  )}
                />
              }
            />
            <Divider />
          </Stack>
          <Button type="submit" loading={isLoading}>
            Draft New Lease
          </Button>
        </Stack>
      </form>
    </RenewalAction>
  );
}

function ResidentRejectAfterAccepted(props: RenewalActionsProps) {
  const { renewalPackage } = props;
  const [opened, setOpened] = useBoolean(false);

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.ACCEPTED
  ) {
    return NULL;
  }

  return (
    <RenewalAction description="Has the resident has changed their mind and has decided to not renew their lease?">
      <Group>
        <Button
          variant={ButtonVariant.transparent}
          color={Color.red}
          onClick={setOpened.setTrue}>
          Resident Rejects Offers
        </Button>
      </Group>
      <RejectModal
        title="Resident Rejects Offer"
        opened={opened}
        onClose={setOpened.setFalse}
        {...props}
      />
    </RenewalAction>
  );
}

type SendNoticeToVacateFormValues = {
  leaseId: EnderId;
  templateId: O.Option<EnderId>;
};

function SendNoticeToVacate(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchLease } = props;
  const { userPM } = useContext(UserContext);

  const form = useForm<SendNoticeToVacateFormValues>({
    initialValues: {
      leaseId: lease.id,
      templateId: O.fromNullable(userPM.defaultNonRenewalTemplate),
    },
  });

  const { mutateAsync: noRenew, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPINoRenewPayload) =>
      RenewalsAPI.noRenew(payload).then(() => refetchLease()),
    mutationKey: ["RenewalsAPI.noRenew", refetchLease, lease.id],
  });

  const onNoticeToVacate = useCallback(
    (values: SendNoticeToVacateFormValues) =>
      noRenew({
        leaseId: values.leaseId,
        templateId: O.getOrUndefined(values.templateId),
      }).then(() =>
        showSuccessNotification({ message: "Notice of non-renewal was sent." }),
      ),
    [noRenew],
  );

  const { data: templateOptions = [] } = useQuery({
    queryFn: () =>
      TemplatesAPI.listTemplates({
        type: TextTemplateTemplateTypeEnum.LEASE_RENEWAL,
      }),
    queryKey: [
      "TemplatesAPI.listTemplates",
      TextTemplateTemplateTypeEnum.LEASE_RENEWAL,
    ],
    select: (data) =>
      data.map((template: { id: EnderId; name: string }) => ({
        key: template.id,
        label: template.name,
        value: template.id,
      })),
  });

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.NO_RENEW
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="Send Notice to Vacate"
      description="If you intend to not renew, send the resident(s) notice to vacate.">
      <Group>
        <form onSubmit={form.onSubmit(onNoticeToVacate)}>
          <Stack>
            <Select
              label="Notice to Vacate Template"
              placeholder="Select Template"
              data={templateOptions}
              disabled={isLoading}
              {...form.getInputProps("templateId")}
            />
            <Group>
              <Button
                type="submit"
                loading={isLoading}
                disabled={O.isNone(form.values.templateId)}>
                Send Notice to Vacate
              </Button>
            </Group>
          </Stack>
        </form>
      </Group>
    </RenewalAction>
  );
}

function ChangeIntentionToRenew(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchLease, refetchRenewalPackage } = props;

  const { mutateAsync: updateLease, isLoading } = useMutation({
    mutationFn: () =>
      LeasingAPI.updateLease({
        leaseId: lease.id,
        intention: LeaseIntentionEnum.FRIENDLY,
      }).then(() => Promise.all([refetchRenewalPackage(), refetchLease()])),
    mutationKey: ["LeasingAPI.updateLease", refetchLease, lease.id],
  });

  const onRenewLease = useCallback(() => updateLease(), [updateLease]);

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.NO_RENEW
  ) {
    return NULL;
  }

  return (
    <RenewalAction description="Would you like to change the intention for the resident’s renewal?">
      <Group>
        <Button
          variant={ButtonVariant.outlined}
          onClick={onRenewLease}
          loading={isLoading}>
          Intend to Renew
        </Button>
      </Group>
    </RenewalAction>
  );
}

function ResidentReconsider(props: RenewalActionsProps) {
  const { lease, renewalPackage, refetchLease, refetchRenewalPackage } = props;

  const rejectForm = useForm<ResidentRejectedFormValues>({
    initialValues: {
      leaseId: lease.id,
      moveOutReason: O.none(),
    },
  });

  const { mutateAsync: rejectAllOffers, isLoading } = useMutation({
    mutationFn: (payload: RenewalsAPIRejectAllOffersPayload) =>
      RenewalsAPI.rejectAllOffers(payload).then(() =>
        Promise.all([refetchRenewalPackage(), refetchLease()]),
      ),
    mutationKey: ["RenewalsAPI.rejectAllOffers", refetchLease],
  });

  const onRejected = useCallback(
    (values: ResidentRejectedFormValues) => {
      rejectAllOffers({
        leaseId: values.leaseId,
        moveOutReason: O.getOrThrow(values.moveOutReason),
      });
    },
    [rejectAllOffers],
  );

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.NEEDS_MOVE_OUT
  ) {
    return NULL;
  }

  return (
    <RenewalAction description="Has the resident changed their mind and reconsidered renewing their lease? Update the reason for rejection and send back for negotiation.">
      <Group>
        <form onSubmit={rejectForm.onSubmit(onRejected)}>
          <Stack>
            <Select
              label="Reason for Negotiation"
              placeholder="Select Dropdown"
              clearable
              data={MoveOutReasonOptions}
              disabled={isLoading}
              {...rejectForm.getInputProps("moveOutReason")}
            />
            <Group>
              <Button
                type="submit"
                variant={ButtonVariant.outlined}
                loading={isLoading}
                disabled={O.isNone(rejectForm.values.moveOutReason)}>
                Set To Negotiation
              </Button>
            </Group>
          </Stack>
        </form>
      </Group>
    </RenewalAction>
  );
}

function DraftedLease(props: RenewalActionsProps) {
  const { renewalPackage } = props;
  const { url } = useRouteMatch();
  const history = useHistory();

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.DRAFTED_LEASES
  ) {
    return NULL;
  }

  return (
    <RenewalAction
      heading="New Lease Page Created"
      description="A new lease has been created in the Lease Chain. To execute the new lease please go to the new Lease Page.">
      <Group>
        <Button
          onClick={() =>
            history.push(
              replacePath({
                url,
                path: `${renewalPackage.draftingRenewalLeaseId}/renewals`,
                depth: 2,
              }),
            )
          }>
          Go To New Lease Page
        </Button>
      </Group>
    </RenewalAction>
  );
}

const ActionComponents = [
  SetOffers,
  SendBackWithNewOffer,
  SendBackWithoutNewOffer,
  SendOffers,
  SendLimitedOffers,
  ResidentAccepted,
  RequestNewOffer,
  ChangeIntention,
  ResidentRejected,
  SetMoveOut,
  RenewLease,
  ResidentRejectAfterAccepted,
  SendNoticeToVacate,
  ChangeIntentionToRenew,
  ResidentReconsider,
  DraftedLease,
] as const;

function RenewalActions(props: RenewalActionsProps) {
  return (
    <Stack spacing={Spacing.lg}>
      {ActionComponents.map((ActionComponent) => (
        <ActionComponent key={ActionComponent.name} {...props} />
      ))}
    </Stack>
  );
}

export { RenewalActions };
