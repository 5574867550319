/**
 * Supports the "Big 3" accounting reports, GL, Income Statement, Balance Sheet.
 
  <code> 
 {
   query: {
     keyword: "bonnie", // Any keyword, can be missing or empty
     
     // GENERAL_LEDGER, INCOME_STATEMENT, BALANCE_SHEET
     categoryTypeFilter: "GENERAL_LEDGER", 
        
     // array of propertyIds or don't include to show all properties
     propertyIds: ["abc123xyz"], 
     timeInterval: {
       start: "2023-03-01",
       inclusiveEnd: "2023-03-31"
     }
     
     type: "CASH", // CASH or ACCRUAL (accounting basis, defaults to cash).
     
     // These three parameters control the GLTx's that are returned.  If these aren't set, 
     // the report still works but there won't be any GLTx data in the nodes.
     categoryIds: ["abd124xyz"], 
     categoryFilterIds,
     txOffset,
     txCount
   }
 }
  </code>
 * HTTP Method: POST
 * Pathname: /accounting/v2/search
 * @function search
 * @memberof AccountingAPI
 * @param {AccountingAPISearchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingAPISearchResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { AccountingReportType } from "@ender/shared/generated/ender.arch.accounting";
import type { GLTxTxType } from "@ender/shared/generated/ender.model.accounting";
import type { ReportWidgetSortOrder } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AccountingAPISearchResponse } from "../accounting-api-search-response";

type AccountingAPISearchSearchParams = {
  token?: string | undefined;
};

type AccountingAPISearchBodyParams = {
  categoryFilterIds?: EnderId[] | undefined;
  categoryIds?: EnderId[] | undefined;
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  hideEmptyAccounts?: boolean | undefined;
  inclusiveEndSystemDate?: SerializesInto<LocalDate> | undefined;
  inclusiveEndTransactionDate?: SerializesInto<LocalDate> | undefined;
  periodFilter?: PeriodFilter | undefined;
  propertyIds: EnderId[];
  reportType: AccountingReportType;
  startSystemDate?: SerializesInto<LocalDate> | undefined;
  startTransactionDate?: SerializesInto<LocalDate> | undefined;
  txAmount?: SerializesInto<Money> | undefined;
  txCount?: number | undefined;
  txDescription?: string | undefined;
  txOffset?: number | undefined;
  txSortOrder?: ReportWidgetSortOrder | undefined;
  txType: GLTxTxType;
};

type AccountingAPISearchPayload = AccountingAPISearchSearchParams &
  AccountingAPISearchBodyParams;

function searchUnsafeEffect(payload: AccountingAPISearchPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingAPISearchResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AccountingAPISearchResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/v2/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function search(
  payload: AccountingAPISearchPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingAPISearchResponse> {
  return F.pipe(
    payload,
    searchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { search };
export type {
  AccountingAPISearchBodyParams,
  AccountingAPISearchPayload,
  AccountingAPISearchSearchParams,
};
