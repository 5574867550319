/**
 * HTTP Method: GET
 * Pathname: /markets
 * @function getMarkets
 * @memberof MarketsAPI
 * @param {MarketsAPIGetMarketsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarketsAPIGetMarketsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MarketsAPIGetMarketsResponse } from "../markets-api-get-markets-response";

type MarketsAPIGetMarketsSearchParams = {
  token?: string | undefined;
  firmId?: EnderId | undefined;
};

type MarketsAPIGetMarketsPayload = MarketsAPIGetMarketsSearchParams;

function getMarketsUnsafeEffect(payload: MarketsAPIGetMarketsPayload) {
  const { token, firmId, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarketsAPIGetMarketsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<MarketsAPIGetMarketsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/markets",
        searchParams: [
          ["token", token],
          ["firmId", firmId],
        ],
      }),
    ),
  );
}

function getMarkets(
  payload: MarketsAPIGetMarketsPayload,
  options?: { signal?: AbortSignal },
): Promise<MarketsAPIGetMarketsResponse> {
  return F.pipe(
    payload,
    getMarketsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMarkets };
export type { MarketsAPIGetMarketsPayload, MarketsAPIGetMarketsSearchParams };
