/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/photos/search
 * @function searchPhotos
 * @memberof InspectionAPI
 * @param {InspectionAPISearchPhotosPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Photo[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Photo } from "@ender/shared/generated/ender.model.files";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPISearchPhotosPathParams = {
  inspectionId: EnderId;
};

type InspectionAPISearchPhotosSearchParams = {
  token?: string | undefined;
};

type InspectionAPISearchPhotosBodyParams = {
  areaId?: EnderId | undefined;
  taskId?: EnderId | undefined;
  zoneId?: EnderId | undefined;
};

type InspectionAPISearchPhotosPayload = InspectionAPISearchPhotosPathParams &
  InspectionAPISearchPhotosSearchParams &
  InspectionAPISearchPhotosBodyParams;

function searchPhotosUnsafeEffect(payload: InspectionAPISearchPhotosPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Photo[]>({
        body,
        decode: unsafeDecodeJsonResponse<Photo[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/photos/search`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchPhotos(
  payload: InspectionAPISearchPhotosPayload,
  options?: { signal?: AbortSignal },
): Promise<Photo[]> {
  return F.pipe(
    payload,
    searchPhotosUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchPhotos };
export type {
  InspectionAPISearchPhotosBodyParams,
  InspectionAPISearchPhotosPathParams,
  InspectionAPISearchPhotosPayload,
  InspectionAPISearchPhotosSearchParams,
};
