import { Function as F, Predicate as P } from "effect";
import { useCallback, useEffect, useMemo, useState } from "react";

import { NULL } from "@ender/shared/constants/general";
import { randomEnderId } from "@ender/shared/core";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";

import "./rich-text-editor.scss";

type TinyMCEEditor = {
  on: (event: string, callback: () => void) => void;
  getContent: () => string;
  setContent: (content: string) => void;
  setMode: (mode: "readonly" | "design") => void;
  destroy: () => void;
};
declare global {
  // Window must be an interface, not a type
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    tinymce: {
      init: (options: Record<string, unknown>) => Promise<TinyMCEEditor[]>;
    };
  }
}

function loadTinyMCE() {
  return new Promise((resolve, reject) => {
    if (window.tinymce) {
      resolve(window.tinymce);
      return;
    }

    function onLoad() {
      setTimeout(() => {
        resolve(window.tinymce);
      }, 0);
    }

    const tinymceSrc =
      "https://cdn.jsdelivr.net/npm/tinymce@5.8.2/tinymce.min.js";
    const existingScript = document.querySelector(
      `script [src="${tinymceSrc}"]`,
    );

    if (existingScript) {
      existingScript.addEventListener("load", onLoad);
      existingScript.addEventListener("error", reject);
      return;
    }

    const script = document.createElement("script");
    script.src = tinymceSrc;
    script.addEventListener("load", onLoad);
    script.addEventListener("error", reject);
    document.head.appendChild(script);
  });
}

type RichTextEditorProps = {
  isReadOnly?: boolean;
  onChange?: (value: string) => void;
  options?: Record<string, unknown>;
  value: string;
};

/**
 * @temporary Continue using this component for forms until widgets/rich-text becomes a controlled component or an equivalent solution is found.
 * Move into `widgets/rich-text`
 */
function RichTextEditor({
  isReadOnly = false,
  onChange = F.constVoid,
  options = {},
  value,
}: RichTextEditorProps) {
  const [editor, setEditor] = useState<TinyMCEEditor | null>(NULL);
  const editorRef = useRefLatest(editor);
  const htmlId = useMemo(() => `markdown-editor-${randomEnderId()}`, []);

  /**
   * This ref is used to keep track of the latest value
   * so that the tinyMceEditor can always access the current value without needing
   * new event listeners to be added.
   */
  const valueRef = useRefLatest(value);

  const addEventListenersToEditor = useCallback(
    (tinyMceEditor: TinyMCEEditor) => {
      tinyMceEditor.on("init", () => {
        tinyMceEditor.setContent(valueRef.current);
      });

      tinyMceEditor.on("change keyup", () => {
        const content = tinyMceEditor.getContent();
        if (content !== valueRef.current) {
          onChange(content);
        }
      });
    },
    [valueRef, onChange],
  );

  const initTinyMCE = useCallback(async () => {
    await loadTinyMCE();

    const [newEditor] = await window.tinymce.init({
      branding: false,
      elementpath: false,
      height: 500,
      menubar: false,
      plugins: "autolink lists autoresize hr",
      selector: `textarea#${htmlId}`,
      // this setup is ignored in favor of one provided in `options`. This means that the onChange might not be used at all
      setup: addEventListenersToEditor,
      statusbar: false,
      toolbar:
        "undo redo | fontselect fontsizeselect | bold italic underline | bullist numlist | alignleft aligncenter alignright | table add-variable conditional",
      toolbar_mode: "wrap",
      toolbar_sticky: true,
      width: 650,
      ...options,
    });

    if (P.isNotNullable(newEditor)) {
      setEditor(newEditor);
    }
    return newEditor;
  }, [htmlId, options, addEventListenersToEditor, setEditor]);

  // cleanup the editor when the component is unmounted
  useEffect(
    () => () => {
      if (P.isNotNullable(editorRef.current)) {
        editorRef.current.destroy();
      }
    },
    [editorRef],
  );

  // calls the asynchronous init function to init TinyMCE
  useEffect(() => {
    initTinyMCE();
  }, [initTinyMCE]);

  // update the editor content to match the incoming value-
  // effectively allows the RTE to be a controlled input
  useEffect(() => {
    if (P.isNullable(editor)) {
      return;
    }

    const content = editor.getContent();
    if (value !== content) {
      editor.setContent(value);
    }
  }, [value, editor]);

  // lazy useEffect
  useEffect(() => {
    if (P.isNullable(editor)) {
      return;
    }

    if (isReadOnly) {
      editor.setMode("readonly");
    } else {
      editor.setMode("design");
    }
  }, [isReadOnly, editor]);

  return <textarea id={htmlId}></textarea>;
}

export { loadTinyMCE, RichTextEditor };
