/**
 * Ender-only route to get autopayment and charge status for each tenant.
 * HTTP Method: GET
 * Pathname: /autopaymentStatus
 * @function getAutopaymentStatus
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetAutopaymentStatusPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PaymentsServiceAutopaymentStatusResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PaymentsServiceAutopaymentStatusResponse } from "@ender/shared/generated/ender.service.payments";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetAutopaymentStatusSearchParams = {
  token?: string | undefined;
  pmId?: EnderId | undefined;
};

type PaymentsAPIGetAutopaymentStatusPayload =
  PaymentsAPIGetAutopaymentStatusSearchParams;

function getAutopaymentStatusUnsafeEffect(
  payload: PaymentsAPIGetAutopaymentStatusPayload,
) {
  const { token, pmId, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PaymentsServiceAutopaymentStatusResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          PaymentsServiceAutopaymentStatusResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/autopaymentStatus",
        searchParams: [
          ["token", token],
          ["pmId", pmId],
        ],
      }),
    ),
  );
}

function getAutopaymentStatus(
  payload: PaymentsAPIGetAutopaymentStatusPayload,
  options?: { signal?: AbortSignal },
): Promise<PaymentsServiceAutopaymentStatusResponse[]> {
  return F.pipe(
    payload,
    getAutopaymentStatusUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAutopaymentStatus };
export type {
  PaymentsAPIGetAutopaymentStatusPayload,
  PaymentsAPIGetAutopaymentStatusSearchParams,
};
