/**
 * Supports adding a journal entry, i.e. a balanced collection of debits and credits, to a general ledger.
 * HTTP Method: POST
 * Pathname: /accounting/journal-entries
 * @function createJournalEntry
 * @memberof AccountingAPI
 * @param {AccountingAPICreateJournalEntryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GLJournalEntry>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GLJournalEntry } from "@ender/shared/generated/ender.model.accounting";
import type { CreateJournalEntryRequestGLTx } from "@ender/shared/generated/ender.model.accounting.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPICreateJournalEntrySearchParams = {
  token?: string | undefined;
};

type AccountingAPICreateJournalEntryBodyParams = {
  approverId?: EnderId | undefined;
  /**
   * A JSON array of journal entry transactions as defined above.
   */
  credits: CreateJournalEntryRequestGLTx[];
  /**
   * A JSON array of journal entry transactions as defined above.
   */
  debits: CreateJournalEntryRequestGLTx[];
  ledgerDate: SerializesInto<LocalDate>;
  /**
   * Optional, if set, can override the default accounting period to a user-selected one.
   */
  periodId?: EnderId | undefined;
  reversalDate?: SerializesInto<LocalDate> | undefined;
  reversingPeriodId?: EnderId | undefined;
  title: string;
};

type AccountingAPICreateJournalEntryPayload =
  AccountingAPICreateJournalEntrySearchParams &
    AccountingAPICreateJournalEntryBodyParams;

function createJournalEntryUnsafeEffect(
  payload: AccountingAPICreateJournalEntryPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GLJournalEntry>({
        body,
        decode: unsafeDecodeJsonResponse<GLJournalEntry>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/journal-entries",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createJournalEntry(
  payload: AccountingAPICreateJournalEntryPayload,
  options?: { signal?: AbortSignal },
): Promise<GLJournalEntry> {
  return F.pipe(
    payload,
    createJournalEntryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createJournalEntry };
export type {
  AccountingAPICreateJournalEntryBodyParams,
  AccountingAPICreateJournalEntryPayload,
  AccountingAPICreateJournalEntrySearchParams,
};
