import { Predicate as P } from "effect";
import { useMemo } from "react";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type {
  GetApprovalProcessResponse,
  GetApprovalProcessesResponse,
} from "@ender/shared/generated/ender.api.misc.response";

import type { ApprovalProcessHybridId } from "./types";
import { DEFAULT_APPROVAL_PROCESS_HYBRID_ID } from "./types";

type UseApprovalProcessProps = {
  approvalProcesses: GetApprovalProcessesResponse | Undefined;
  approvalProcessHybridId: ApprovalProcessHybridId | Null;
};

type UseApprovalProcessResponse = GetApprovalProcessResponse & {
  id: ApprovalProcessHybridId;
};

function useApprovalProcess({
  approvalProcesses,
  approvalProcessHybridId,
}: UseApprovalProcessProps): UseApprovalProcessResponse | Null {
  // TODO see if can simplify this or even just continue passing lesser parameters like approvalProcessHybridId
  return useMemo(() => {
    if (
      P.isNullable(approvalProcesses) ||
      P.isNullable(approvalProcessHybridId)
    ) {
      return NULL;
    }

    if (approvalProcessHybridId === DEFAULT_APPROVAL_PROCESS_HYBRID_ID) {
      return {
        ...approvalProcesses.defaultApprovalProcess,
        id: DEFAULT_APPROVAL_PROCESS_HYBRID_ID,
      };
    }

    return {
      ...approvalProcesses.approvalProcessById[
        approvalProcessHybridId as EnderId
      ],
      id: approvalProcessHybridId,
    } as GetApprovalProcessResponse & { id: ApprovalProcessHybridId };
  }, [approvalProcesses, approvalProcessHybridId]);
}

export { useApprovalProcess };
export type { UseApprovalProcessResponse };
