import {
  IconBuilding,
  IconBuildingArch,
  IconChevronLeft,
} from "@tabler/icons-react";
import { Function as F, Predicate as P } from "effect";
import { useContext } from "react";
import { NavLink } from "react-router-dom";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { ListingListingStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import type { LeasingServiceListingResponse } from "@ender/shared/generated/ender.service.leasing";
import { Color } from "@ender/shared/utils/theming";

const ListingStatusBadgeColorMap = {
  [ListingListingStatusEnum.DRAFTING]: BadgeColor.yellow,
  [ListingListingStatusEnum.LISTED]: BadgeColor.green,
  [ListingListingStatusEnum.UNLISTED]: BadgeColor.slate,
} as const;
const ListingStatusDisplayMap = {
  [ListingListingStatusEnum.DRAFTING]: "Drafting",
  [ListingListingStatusEnum.LISTED]: "Listed",
  [ListingListingStatusEnum.UNLISTED]: "Inactive",
} as const;

type ListingHeaderProps = {
  listing?: Pick<
    LeasingServiceListingResponse,
    | "id"
    | "daysListed"
    | "daysVacant"
    | "numProspects"
    | "numApplications"
    | "numShowings"
    | "unit"
    | "status"
    | "property"
  >;
  label?: string;
  refresh?: () => void;
};

function ListingHeader({
  refresh = F.constVoid,
  listing,
  label = "",
}: ListingHeaderProps) {
  const confirmation = useContext(ConfirmationContext);

  const statuses = [
    {
      label: "Days Listed",
      value: listing?.daysListed,
    },
    { label: "Days Vacant", value: listing?.daysVacant },
    // { label: "Market", value: "-" },
    {
      label: "Prospects",
      link: `/leasing-center/prospect-messaging?unitId=${listing?.unit.id}`,
      value: listing?.numProspects,
    },
    { label: "Showings", value: listing?.numShowings },
    {
      label: "Applications",
      link: `/leasing-center/applications?unitId=${listing?.unit.id}`,
      value: listing?.numApplications,
    },
  ];

  /**
   * the button which invokes this function
   * is only displayed if `listing` is defined. This means that
   * we can safely assume that `listing` is defined here.
   */
  async function deactivateListing() {
    if (!listing) {
      return;
    }

    await confirmation({
      confirmButtonLabel: "Deactivate",
      content: "This listing will move to Inactive Listings.",
      title: `Are you sure you would like to deactivate ${listing.unit.name || listing.property.name}?`,
    });
    //@ts-expect-error amenities should be optional in the API
    await LeasingAPI.updateListing({
      listingId: listing.id,
      status: ListingListingStatusEnum.UNLISTED,
    });
    refresh();
  }

  const trimmedName = listing?.unit.name.replace(/^unit/gi, "") ?? "";

  return (
    <Stack spacing={Spacing.sm}>
      <RouterLink href="/leasing-center/listings">
        <Button
          variant={ButtonVariant.transparent}
          leftSection={<IconChevronLeft />}>
          All Listings
        </Button>
      </RouterLink>
      <Group align={Align.center}>
        <Skeleton visible={P.isNullable(listing)}>
          <H1>{label}</H1>
        </Skeleton>
        {P.isNotNullable(listing) && P.isNotNullable(listing.status) && (
          <Badge color={ListingStatusBadgeColorMap[listing.status]}>
            {ListingStatusDisplayMap[listing.status]}
          </Badge>
        )}
      </Group>
      <Group>
        {/* TODO get the property ID for linking */}
        <NavLink to={`/properties/${listing?.property.id}`}>
          <Button
            variant={ButtonVariant.transparent}
            color={Color.slate}
            leftSection={
              <IconBuilding color={EnderThemeColorEnum.SLATE_600} />
            }>
            {listing?.property.name}
          </Button>
        </NavLink>
        {listing?.unit.id && (
          // TODO get unit ID for link
          <NavLink
            to={`/units/${listing?.unit.id}`}
            className="flex-center flex-nowrap">
            <Button
              variant={ButtonVariant.transparent}
              color={Color.slate}
              leftSection={
                <IconBuildingArch color={EnderThemeColorEnum.SLATE_600} />
              }>
              Unit {trimmedName}
            </Button>
          </NavLink>
        )}
        {/* <NavLink to={`/leasing-center/listings?unitId=${listing?.unit.id}`} className="flex-center">
          <IconListDetails color={ColorEnum.SLATE_600} />
          Unit's Inactive Listings
        </NavLink> */}
        <Group grow justify={Justify.end}>
          {listing?.status === "LISTED" && (
            <Button
              variant={ButtonVariant.outlined}
              onClick={deactivateListing}>
              Deactivate Listing
            </Button>
          )}
        </Group>
      </Group>
      <Grid spacingY={Spacing.sm}>
        {statuses.map(({ label, value, link }) => (
          <Tuple
            key={label}
            filled
            label={label}
            value={
              <Group spacing={Spacing.xs}>
                <Text>{value}</Text>
                {link && <RouterLink href={link}>View</RouterLink>}
              </Group>
            }
          />
        ))}
      </Grid>
      <Divider />
    </Stack>
  );
}

export { ListingHeader };
export type { ListingHeaderProps };
