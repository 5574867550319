import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DealChannelValues = [
  "MLS",
  "OFF_MARKET",
  "BULK",
  "BUILD_TO_RENT",
] as const;

const DealChannelEffectSchema = Schema.Literal(...DealChannelValues);

type DealChannel = Schema.Schema.Type<typeof DealChannelEffectSchema>;

const DealChannelEnum = castEnum(DealChannelEffectSchema);

function randomDealChannel(): DealChannel {
  return rand(DealChannelValues);
}

export {
  DealChannelEffectSchema,
  DealChannelEnum,
  DealChannelValues,
  randomDealChannel,
};
export type { DealChannel };
