import type { UseFormReturnType } from "@mantine/form";
import { Array as A } from "effect";

import { isNaN } from "@ender/shared/utils/is";

function nameSplitter(name: string): (string | number)[] {
  const split = name.split(".");
  const result: (string | number)[] = [];
  for (const part of split) {
    const parsed = Number.parseInt(part);
    if (isNaN(parsed)) {
      result.push(part);
    } else {
      result.push(parsed);
    }
  }
  return result;
}

function getFinalValue<T>(form: UseFormReturnType<T>, name: string): unknown[] {
  const split = nameSplitter(name);

  let value: unknown;
  for (const part of split) {
    value = value
      ? (value as Record<string, unknown>)[part]
      : (form.values as Record<string, unknown>)[part];
  }

  if (!value) {
    throw new Error(`FormList: value for ${name} is undefined`);
  }

  if (!A.isArray(value)) {
    throw new Error(`FormList: value for ${name} is not an array`);
  }

  return value;
}

export { getFinalValue };
