import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Paper, Text } from "@mantine/core";
import { IconEqual, IconTrash } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Predicate as P } from "effect";
import { useContext, useMemo } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { MoveInSettingsContext } from "@ender/shared/contexts/move-in";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { TextInput } from "@ender/shared/ds/text-input";
import { MoveInAPI } from "@ender/shared/generated/ender.api.leasing";
import type { ChecklistConfigStep } from "@ender/shared/types/move-in";
import { Select } from "@ender/shared/ui/select";
import { debounce } from "@ender/shared/utils/debounce";

import {
  capturedDataTypeSelectOptions,
  reorderChecklistConfigSteps,
  updateChecklistConfigStep,
} from "./move-in.utils";

import styles from "./move-in.module.css";

type Props = {
  configStep: ChecklistConfigStep;
  index: number;
};

function deleteChecklistConfigStep(checklistConfigStep: ChecklistConfigStep) {
  return MoveInAPI.deleteConfigStep({ stepId: checklistConfigStep.id });
}

function MoveInSettingsConfigStep(props: Props) {
  const { configStep, index } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: configStep.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const {
    refetch,
    checklistConfigSteps: [configSteps, configStepHandlers],
  } = useContext(MoveInSettingsContext);

  const updateRemoteConfigStep = useMemo<
    (configStep: ChecklistConfigStep) => void
  >(
    () =>
      debounce((configStep: ChecklistConfigStep) =>
        updateChecklistConfigStep(configStep).catch(refetch),
      ),
    [refetch],
  );

  function onDeleteChecklistConfigStep() {
    configStepHandlers.remove(index);
    return reorderChecklistConfigSteps(
      configSteps,
      index,
      configSteps.length - 1,
    )
      .then(() => deleteChecklistConfigStep(configStep))
      .catch(refetch);
  }

  function onUpdateConfigStepProp<
    K extends keyof ChecklistConfigStep,
    U extends ChecklistConfigStep[K],
  >(prop: K, value: U) {
    configStepHandlers.setItemProp(index, prop, value);
    updateRemoteConfigStep({ ...configStep, [prop]: value });
  }

  return (
    <div ref={setNodeRef} style={style}>
      <Group>
        <Text className={styles.checklistConfigStepOrder}>{index + 1}.</Text>
        <Paper
          withBorder
          radius="md"
          shadow="md"
          className={clsx(
            { [styles.isDragging]: isDragging },
            styles.checklistConfigStep,
          )}>
          <Group spacing={Spacing.none} align={Align.stretch}>
            <div
              className={styles.checklistConfigStepHandle}
              ref={setActivatorNodeRef}
              {...attributes}
              {...listeners}>
              <IconEqual color={EnderThemeColorEnum.GRAY_700} size={24} />
            </div>
            <div className={styles.checklistConfigStepForm}>
              <Grid>
                <TextInput
                  aria-label="Checklist Item Name"
                  placeholder="Checklist Item Name"
                  value={configStep.title}
                  onChange={(e) => onUpdateConfigStepProp("title", e)}
                />
                <TextInput
                  aria-label="Description"
                  placeholder="Description"
                  value={configStep.description}
                  onChange={(e) => onUpdateConfigStepProp("description", e)}
                />
                <Group justify={Justify.end}>
                  <ActionIcon
                    variant={ButtonVariant.transparent}
                    onClick={onDeleteChecklistConfigStep}>
                    <IconTrash size="20px" />
                  </ActionIcon>
                </Group>
                <Checkbox
                  label="Required field"
                  value={configStep.isRequired}
                  onChange={(value) =>
                    onUpdateConfigStepProp("isRequired", value)
                  }
                />
                <Select
                  disabled
                  aria-label="Checklist Item Type"
                  value={configStep.capturedDataType}
                  data={capturedDataTypeSelectOptions}
                  onChange={(selectedValue) =>
                    P.isNotNullable(selectedValue) &&
                    onUpdateConfigStepProp("capturedDataType", selectedValue)
                  }
                />
              </Grid>
            </div>
          </Group>
        </Paper>
      </Group>
    </div>
  );
}

export { MoveInSettingsConfigStep };
