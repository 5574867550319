import { Function as F, Predicate as P } from "effect";

import { openModal } from "@ender/shared/contexts/modal";
import { WarningsModal } from "@ender/shared/ui/modals";
import { EnderError } from "@ender/shared/utils/error";

/**
 * @function transformWarningsAndErrors
 * @description Transforms the data into workable warnings or errors for processing
 * @param {Array<object>} valueToTransform The value to transform
 * @returns {Array<string>} Transformed Value
 */
function transformWarningsAndErrors(valueToTransform: object[] | undefined) {
  if (P.isNullable(valueToTransform)) {
    return [];
  }

  return valueToTransform.map((dataObj) => dataObj);
}

type Response = {
  json?: { errors: string[]; warnings: string[] };
  errors?: { [key: string]: string }[];
  warnings?: { [key: string]: string }[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiFunction = (...args: any) => Promise<any>;

type HandleFetchWithWarningsConfiguration = {
  confirmBtnText?: string;
  onCancelOverrideWarnings?: () => void;
  onConfirmOverrideWarnings?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (response: any) => void;
  /**
   * onUnhandledError is a catch-all for errors that fall through all conditional returns.
   * In a good system, onUnhandledError should probably not be necessary.
   * In our current system, there are error cases that fall through all attemptApiRequest conditional returns.
   */
  onUnhandledError?: (err: unknown) => void; // insane that this is ever necessary
  overrideWarnings?: boolean;
  showLoading?: boolean;
  loadingMessage?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiArguments = any[];

/**
 * @deprecated use withWarningHandler instead
 * This function is deprecated.  It is incompatible with the new confirmation context.
 * It is incompatible with the DS system.
 */
function handleFetchWithWarnings(
  apiFunction: ApiFunction,
  {
    confirmBtnText = "Continue",
    onCancelOverrideWarnings = F.constVoid,
    onConfirmOverrideWarnings = F.constVoid,
    onSuccess = F.constVoid,
    onUnhandledError = F.constVoid,
    overrideWarnings = false,
    showLoading = false,
  }: HandleFetchWithWarningsConfiguration,
  ...apiArguments: ApiArguments
): Promise<Response | undefined> {
  /**
   * @function addWaitForWithResponse
   * @description Wraps apiFunction request with waitForWithResponse
   * @param {Function} request The apiFunction which the user is attempting to process
   * @returns {Function} Wrapped apiFunction
   */
  function addWaitForWithResponse(request: ApiFunction) {
    function waitForRequest() {
      return request(...arguments);
    }

    return waitForRequest;
  }

  /**
   * @function getErrorsAndWarnings
   * @description Extract the errors and warnings from an object
   * @param {Response} obj The response object to process
   * @returns {{errors: Array<string>, warnings: Array<string>}}
   */
  function getErrorsAndWarnings(obj: Response) {
    if (P.isNullable(obj)) {
      return {
        errors: [],
        warnings: [],
      };
    }

    if (P.isNotNullable(obj.json)) {
      // Variable Scope required aliasing
      const { warnings: _warnings, errors: _errors } = obj.json;
      return { errors: _errors, warnings: _warnings };
    }

    /*
     * Alexa - SEP-12-2024
     * When Effect.RunPromise rejects, it stuffs the failure cause here.  The error object is not a Effect.Cause type,
     * and I don't know the 'canonical' way to access this failure.  This works, but it's not ideal.
     * My best guess is we use runPromiseExit, but thats a much larger refactor than I'm comfortable doing without sign-off from others.
     */
    // @ts-expect-error type of err is unknown and we're already overloading it above
    const effectError = obj[Symbol.for("effect/Runtime/FiberFailure/Cause")];

    if (P.isNotNullable(effectError)) {
      const { warnings, errors } = effectError.error;
      return {
        errors,
        warnings,
      };
    }

    const { warnings, errors } = obj;

    return {
      errors: transformWarningsAndErrors(errors),
      warnings: transformWarningsAndErrors(warnings),
    };
  }

  /**
   * @function openWarningsModal
   * @description Opens a WarningsModal to allow the user to confirm or cancel attempting overriding warnings
   * @param {Array<string>} warnings
   * @param {Function} resolve Promise.resolve
   * @param {Function} reject Promise.reject
   */
  function openWarningsModal(
    warnings: string[],
    resolve: (response?: Response) => void,
    reject: (err: unknown) => void,
  ) {
    openModal(
      WarningsModal,
      {
        confirmBtnText,
        onCancelClick: () => {
          onCancelOverrideWarnings();
          resolve();
        },
        onConfirm: async (closeModal: () => void) => {
          onConfirmOverrideWarnings();
          // eslint-disable-next-line no-use-before-define
          await attemptApiRequest(resolve, reject, true);
          closeModal();
        },
        warnings,
      },
      "warnings-modal__with-image",
    );
  }

  /**
   * @function attemptApiRequest
   * @description Attempt to process the api request
   * @param {Function} resolve Promise.resolve
   * @param {Function} reject Promise.reject
   * @param {boolean} overrideWarnings Should the warnings be ignored
   */
  async function attemptApiRequest(
    resolve: (response?: Response) => void,
    reject: (err: unknown) => void,
    overrideWarnings: boolean,
  ) {
    try {
      let apiRequest = apiFunction;

      // DETERMINE IF WE NEED TO USE waitForWithResponse
      if (showLoading) {
        apiRequest = addWaitForWithResponse(apiRequest);
      }

      /**
       * 2024-03-25 Geoff:
       * Our new generated APIs pass overrideWarnings as part of the payload object
       * which should consistently be the first argument in the API call.
       * Yes, we should probably refactor the API for this handleFetchWithWarnings function,
       * but the extent to which we should refactor or deprecated the function is still up for debate.
       */
      if (apiArguments[0] && typeof apiArguments[0] !== "object") {
        throw new Error("apiArguments[0] is not an object");
      }
      apiArguments[0].overrideWarnings = overrideWarnings;
      const response = await apiRequest(...apiArguments);

      await onSuccess(response);
      resolve(response);
    } catch (err) {
      if (overrideWarnings) {
        reject(err);
        return;
      }

      // @ts-expect-error type of err is unknown but expected param type of getErrorsAndWarnings is Response
      const { errors, warnings } = getErrorsAndWarnings(err);

      if (errors?.length) {
        const error = new EnderError({ json: { errors } });
        reject(error);
        return;
      }

      if (warnings?.length) {
        openWarningsModal(warnings, resolve, reject);
        return;
      }

      onUnhandledError(err);
    }
  }

  return new Promise((resolve: (response?: Response) => void, reject) =>
    attemptApiRequest(resolve, reject, overrideWarnings),
  );
}

export { handleFetchWithWarnings };
