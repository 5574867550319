/**
 * HTTP Method: POST
 * Pathname: /pets/{petId}/unarchive
 * @function unarchivePet
 * @memberof PetsAPI
 * @param {PetsAPIUnarchivePetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIUnarchivePetPathParams = {
  petId: EnderId;
};

type PetsAPIUnarchivePetSearchParams = {
  token?: string | undefined;
};

type PetsAPIUnarchivePetPayload = PetsAPIUnarchivePetPathParams &
  PetsAPIUnarchivePetSearchParams;

function unarchivePetUnsafeEffect(payload: PetsAPIUnarchivePetPayload) {
  const { petId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/pets/${petId}/unarchive`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function unarchivePet(
  payload: PetsAPIUnarchivePetPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    unarchivePetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { unarchivePet };
export type {
  PetsAPIUnarchivePetPathParams,
  PetsAPIUnarchivePetPayload,
  PetsAPIUnarchivePetSearchParams,
};
