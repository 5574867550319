import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const AccountingPeriodAccountingModuleValues = [
  "ACCOUNTS_PAYABLE",
  "ACCOUNTS_RECEIVABLE",
  "GENERAL_LEDGER",
] as const;

const AccountingPeriodAccountingModuleEffectSchema = Schema.Literal(
  ...AccountingPeriodAccountingModuleValues,
);

type AccountingPeriodAccountingModule = Schema.Schema.Type<
  typeof AccountingPeriodAccountingModuleEffectSchema
>;

const AccountingPeriodAccountingModuleEnum = castEnum(
  AccountingPeriodAccountingModuleEffectSchema,
);

function randomAccountingPeriodAccountingModule(): AccountingPeriodAccountingModule {
  return rand(AccountingPeriodAccountingModuleValues);
}

export {
  AccountingPeriodAccountingModuleEffectSchema,
  AccountingPeriodAccountingModuleEnum,
  AccountingPeriodAccountingModuleValues,
  randomAccountingPeriodAccountingModule,
};
export type { AccountingPeriodAccountingModule };
