import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$, randomEnderId } from "@ender/shared/core";
import { ChargeSchedulesAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  GetChargeScheduleResponse,
  GetChargesForChargeScheduleResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

type TransformedScheduledCharge = Omit<
  GetChargesForChargeScheduleResponse,
  "amount" | "date"
> & {
  amount: Money$.Money;
  date: LocalDate$.LocalDate;
  uuid: EnderId;
};

function transformCharge(
  charge: GetChargesForChargeScheduleResponse,
): TransformedScheduledCharge {
  return {
    ...charge,
    amount: Money$.of(charge.amount),
    date: LocalDate$.of(charge.date),
    uuid: randomEnderId(),
  };
}

async function getChargesForSchedule(
  scheduleId: EnderId,
): Promise<TransformedScheduledCharge[]> {
  const chargesRes = await ChargeSchedulesAPI.getChargesForChargeSchedule({
    chargeScheduleId: scheduleId,
  });
  return chargesRes.map(transformCharge);
}

type TransformedSchedule = Omit<
  GetChargeScheduleResponse,
  "amount" | "inclusiveEndDate" | "startDate"
> & {
  amount: Money$.Money;
  charges: TransformedScheduledCharge[];
  displayFrequency: string;
  inclusiveEndDate?: LocalDate$.LocalDate;
  nextChargeDate?: LocalDate$.LocalDate;
  startDate: LocalDate$.LocalDate;
  uuid: EnderId;
};

/**
 * @name transformSchedule
 * @description transforms the response from GET /leases/[LEASE_ID]/chargeSchedules for optimized FE integration
 */
async function transformSchedule(
  schedule: GetChargeScheduleResponse,
): Promise<TransformedSchedule> {
  const { amount, startDate, inclusiveEndDate, frequency } = schedule;

  const charges = await getChargesForSchedule(schedule.id);

  const _nextCharge = charges.find(({ date }) =>
    date.isAfter(LocalDate$.today()),
  );

  return {
    ...schedule,
    amount: Money$.of(amount),
    displayFrequency: convertSnakeCaseToTitleCase(frequency),
    inclusiveEndDate: inclusiveEndDate
      ? LocalDate$.of(inclusiveEndDate)
      : UNDEFINED,
    startDate: LocalDate$.of(startDate),
    uuid: randomEnderId(),
    charges,
    nextChargeDate: _nextCharge?.date,
  };
}

export { transformSchedule };
export type { TransformedSchedule, TransformedScheduledCharge };
