import { Predicate as P } from "effect";
import type { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { Children, cloneElement } from "react";

import { isReactElement } from "@ender/shared/utils/is";

import type { ScreenRange } from "./types";
import { ScreenRangeEnum, ScreenRangeSchema } from "./types";
import { useScreenRange } from "./use-screen-range";

const ScreenRangeEnumerations: Record<ScreenRange, number> = {
  [ScreenRangeEnum.SMALL]: 0,
  [ScreenRangeEnum.MEDIUM]: 1,
  [ScreenRangeEnum.LARGE]: 2,
} as const;

type ComparisonProps = PropsWithChildren<{
  size: ScreenRange;
  screenRange?: ScreenRange;
}>;
type ComparisonExportProps = Exclude<ComparisonProps, "screenRange">;

function isComparisonExportProps(
  props: unknown,
): props is ComparisonExportProps {
  return (
    P.isRecord(props) &&
    "size" in props &&
    ScreenRangeSchema.safeParse(props.size).success
  );
}

function LessThanComparison(props: ComparisonProps): ReactNode {
  const { size, screenRange, children } = props;
  if (
    P.isNotNullable(screenRange) &&
    ScreenRangeEnumerations[screenRange] < ScreenRangeEnumerations[size]
  ) {
    return children;
  }
  return null;
}

function LessThanEqualComparison(props: ComparisonProps): ReactNode {
  const { size, screenRange, children } = props;
  if (
    P.isNotNullable(screenRange) &&
    ScreenRangeEnumerations[screenRange] <= ScreenRangeEnumerations[size]
  ) {
    return children;
  }
  return null;
}

function EqualComparison(props: ComparisonProps): ReactNode {
  const { size, screenRange, children } = props;
  if (size === screenRange) {
    return children;
  }
  return null;
}

function GreaterThanEqualComparison(props: ComparisonProps): ReactNode {
  const { size, screenRange, children } = props;
  if (
    P.isNotNullable(screenRange) &&
    ScreenRangeEnumerations[screenRange] >= ScreenRangeEnumerations[size]
  ) {
    return children;
  }
  return null;
}

function GreaterThanComparison(props: ComparisonProps): ReactNode {
  const { size, screenRange, children } = props;
  if (
    P.isNotNullable(screenRange) &&
    ScreenRangeEnumerations[screenRange] > ScreenRangeEnumerations[size]
  ) {
    return children;
  }
  return null;
}

type ScreenSizeProps = PropsWithChildren<{
  fallback?: ReactNode;
}>;

/**
 * @deprecated move this into Maintenance. It's not a pattern we should make universal as each app will likely require its own
 * adoption of media queries/tailwind unique for their use case/layout scenario.
 */
function ScreenSize(props: ScreenSizeProps): ReactNode {
  const { fallback = null, children } = props;
  const screenRange = useScreenRange();

  if (P.isNullable(screenRange)) {
    return fallback;
  }

  return Children.map(children, (child) => {
    if (isReactElement<ComparisonExportProps>(child, isComparisonExportProps)) {
      return cloneElement<ComparisonProps>(child, {
        screenRange,
      });
    }

    return child;
  });
}

const LessThan = LessThanComparison as FunctionComponent<ComparisonExportProps>;
ScreenSize.LessThan = LessThan;

const LessThanEqual =
  LessThanEqualComparison as FunctionComponent<ComparisonExportProps>;
ScreenSize.LessThanEqual = LessThanEqual;

const Equal = EqualComparison as FunctionComponent<ComparisonExportProps>;
ScreenSize.Equal = Equal;

const GreaterThanEqual =
  GreaterThanEqualComparison as FunctionComponent<ComparisonExportProps>;
ScreenSize.GreaterThanEqual = GreaterThanEqual;

const GreaterThan =
  GreaterThanComparison as FunctionComponent<ComparisonExportProps>;
ScreenSize.GreaterThan = GreaterThan;

export {
  /**
   * @deprecated move this into Maintenance. It's not a pattern we should make universal as each app will likely require its own
   * adoption of media queries/tailwind unique for their use case/layout scenario.
   */
  ScreenSize,
};
export type { ScreenRange };
