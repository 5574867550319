import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import type { CounterpartyInfo } from "@ender/shared/generated/ender.service.accounting.banking";
import { Ellipsis } from "@ender/shared/ui/ellipsis";

type CounterpartyProps = {
  counterpartyInfo: CounterpartyInfo;
};

function Counterparty({ counterpartyInfo }: CounterpartyProps) {
  let content: JSX.Element;

  switch (counterpartyInfo.party) {
    case PartyEnum.LEASE:
      content = (
        <a href={`/leases/${counterpartyInfo.partyId}`}>
          <Ellipsis>
            {counterpartyInfo.payeeName ||
              counterpartyInfo.companyName ||
              `${counterpartyInfo.unit?.name} Tenant`}
          </Ellipsis>
        </a>
      );
      break;
    case PartyEnum.EXTERNAL:
      content = <Ellipsis>Other entity</Ellipsis>;
      break;
    case PartyEnum.VENDOR:
      content = (
        <a href={`/vendors/${counterpartyInfo.id}`}>
          <Ellipsis>
            {counterpartyInfo.payeeName || counterpartyInfo.name}
          </Ellipsis>
        </a>
      );
      break;
    case PartyEnum.PROPERTY_MANAGER:
      content = <Ellipsis>{counterpartyInfo.payeeName}</Ellipsis>;
      break;
    case PartyEnum.FIRM:
      content = <Ellipsis>{counterpartyInfo.payeeName}</Ellipsis>;
      break;
    default:
      content = <Ellipsis>Unknown Party</Ellipsis>; // Default case to handle unknown parties
  }

  return content;
}

export { Counterparty };
