/**
 * HTTP Method: GET
 * Pathname: /applications/{applicationGroupId}/approvals
 * @function getApplicationApprovals
 * @memberof ApplicationsMiddleLayerAPI
 * @param {ApplicationsMiddleLayerAPIGetApplicationApprovalsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ApplicationGroupApprovalResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApplicationGroupApprovalResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsMiddleLayerAPIGetApplicationApprovalsPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsMiddleLayerAPIGetApplicationApprovalsSearchParams = {
  token?: string | undefined;
};

type ApplicationsMiddleLayerAPIGetApplicationApprovalsPayload =
  ApplicationsMiddleLayerAPIGetApplicationApprovalsPathParams &
    ApplicationsMiddleLayerAPIGetApplicationApprovalsSearchParams;

function getApplicationApprovalsUnsafeEffect(
  payload: ApplicationsMiddleLayerAPIGetApplicationApprovalsPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ApplicationGroupApprovalResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ApplicationGroupApprovalResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/applications/${applicationGroupId}/approvals`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getApplicationApprovals(
  payload: ApplicationsMiddleLayerAPIGetApplicationApprovalsPayload,
  options?: { signal?: AbortSignal },
): Promise<ApplicationGroupApprovalResponse> {
  return F.pipe(
    payload,
    getApplicationApprovalsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getApplicationApprovals };
export type {
  ApplicationsMiddleLayerAPIGetApplicationApprovalsPathParams,
  ApplicationsMiddleLayerAPIGetApplicationApprovalsPayload,
  ApplicationsMiddleLayerAPIGetApplicationApprovalsSearchParams,
};
