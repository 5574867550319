import { H2 } from "@ender/shared/ds/heading";

type CreateInvoiceHeaderLabelProps = {
  numSelectedPages: number;
  isPayable: boolean;
};

function CreateInvoiceHeaderLabel({
  numSelectedPages,
  isPayable,
}: CreateInvoiceHeaderLabelProps) {
  const hasSelectedPages = numSelectedPages > 0;
  const hasMultipleSelectedPages = numSelectedPages > 1;
  const fromPagesText = hasMultipleSelectedPages
    ? `from ${numSelectedPages} Pages`
    : "from Page";
  return (
    <H2>
      {hasSelectedPages && isPayable && <>Create Invoice {fromPagesText}</>}
      {hasSelectedPages && !isPayable && <>Create Receivable {fromPagesText}</>}
      {!hasSelectedPages && "Create Invoice"}
    </H2>
  );
}

export { CreateInvoiceHeaderLabel };
