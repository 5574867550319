/**
 * HTTP Method: POST
 * Pathname: /invoices/batchApprove
 * @function batchApprove
 * @memberof InvoicesAPI
 * @param {InvoicesAPIBatchApprovePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchedInvoiceActionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchedInvoiceActionResponse } from "@ender/shared/generated/ender.model.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIBatchApproveSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIBatchApproveBodyParams = {
  comment?: string | undefined;
  invoiceIds: EnderId[];
  overrideWarnings?: boolean | undefined;
};

type InvoicesAPIBatchApprovePayload = InvoicesAPIBatchApproveSearchParams &
  InvoicesAPIBatchApproveBodyParams;

function batchApproveUnsafeEffect(payload: InvoicesAPIBatchApprovePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchedInvoiceActionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchedInvoiceActionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/batchApprove",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function batchApprove(
  payload: InvoicesAPIBatchApprovePayload,
  options?: { signal?: AbortSignal },
): Promise<BatchedInvoiceActionResponse> {
  return F.pipe(
    payload,
    batchApproveUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { batchApprove };
export type {
  InvoicesAPIBatchApproveBodyParams,
  InvoicesAPIBatchApprovePayload,
  InvoicesAPIBatchApproveSearchParams,
};
