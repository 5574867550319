import { Option as O } from "effect";
import type { FocusEvent, ForwardedRef } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { isEnderId } from "@ender/shared/core";

import type { InputBaseProps } from "../../../input/src";
import { Input } from "../../../input/src";

type EnderIdInputProps = {
  value: O.Option<EnderId>;
  onChange: (value: O.Option<EnderId>) => void;
} & InputBaseProps;

function EnderIdInput(
  props: EnderIdInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    borderless,
    description = "Please paste a valid ID number.",
    disabled = false,
    error,
    label = "ID",
    leftSection,
    onChange,
    placeholder,
    rightSection,
    size,
    value,
    name,
  } = props;

  /**
   * whether the user is currently typing in the field or not.
   * If this is true, we want to prevent incoming value changes
   * from replacing the user's typed input
   */
  const isEditing = useRef(false);

  const [display, setDisplay] = useState(() => O.getOrElse(() => "")(value));

  /**
   * react to value changes from the parent state management
   */
  useEffect(() => {
    if (!isEditing.current) {
      setDisplay(O.getOrElse(() => "")(value));
    }
  }, [value]);

  /**
   * handles the user typing. What this achieves:
   * 1. check if the input is a valid EnderId. If not, value will be O.none but display will still reflect what user has typed.
   * This also prevents unnecessary state changes that may trigger API calls with invalid EnderId
   * 2. update the value via the onChange prop
   */
  const handleInput = useCallback(
    (val: string) => {
      try {
        setDisplay(val);
        onChange(O.liftPredicate(isEnderId)(val));
      } catch {
        onChange(O.none());
      }
    },
    [onChange],
  );

  //the only purpose of this is to reformat the displayValue when the user clicks away. The value is unchanged.
  const handleBlur = useCallback(() => {
    isEditing.current = false;
    setDisplay(O.getOrElse(() => "")(value));
  }, [value]);

  function handleFocus(_e: FocusEvent<HTMLInputElement>) {
    isEditing.current = true;
  }

  return (
    <Input
      borderless={borderless}
      description={description}
      disabled={disabled}
      error={error}
      label={label}
      inputType="text"
      leftSection={leftSection}
      onBlur={handleBlur}
      onChange={handleInput}
      onFocus={handleFocus}
      placeholder={placeholder}
      ref={ref}
      rightSection={rightSection}
      size={size}
      value={display}
      name={name}
    />
  );
}

const ForwardedEnderIdInput = forwardRef<HTMLInputElement, EnderIdInputProps>(
  EnderIdInput,
);

export { ForwardedEnderIdInput as EnderIdInput };

export type { EnderIdInputProps };
