import { useQuery } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { RightRail } from "@ender/shared/ui/right-rail";

import { InvoiceView } from "./invoice-view";
import { useApprovalProcessSteps } from "./use-approval-process-steps";

type InvoiceRightRailProps = {
  invoiceId?: EnderId;
  isEditable?: boolean;
  hideApprovalMenu?: boolean;
  loadNextInvoice?: () => void;
  onClose?: () => void;
  opened: boolean;
  refreshData?: () => void;
};

function InvoiceRightRail({
  invoiceId,
  isEditable,
  hideApprovalMenu = false,
  loadNextInvoice = F.constVoid,
  onClose = F.constVoid,
  opened,
  refreshData,
}: InvoiceRightRailProps) {
  const { data: invoice } = useQuery({
    enabled: P.isNotNullable(invoiceId),
    queryFn: ({ signal }) => {
      if (P.isNullable(invoiceId)) {
        return UNDEFINED;
      }
      return InvoicesAPI.getInvoice({ invoiceId }, { signal });
    },
    queryKey: ["InvoicesAPI.getInvoice", invoiceId] as const,
  });

  const approvalProcessSteps = useApprovalProcessSteps(
    invoice?.type as InvoiceInvoiceType,
  );

  return (
    <RightRail
      eyebrow={invoice?.type}
      headerPaddingBottom={0}
      key={invoiceId}
      onClose={onClose}
      opened={opened}
      title="">
      {
        // Added this to fix TS check
        P.isNotNullable(invoiceId) && (
          <InvoiceView
            approvalProcessSteps={approvalProcessSteps}
            closeModal={onClose}
            invoiceId={invoiceId}
            loadNextInvoice={loadNextInvoice}
            refreshData={refreshData}
            isEditable={isEditable}
            hideApprovalMenu={hideApprovalMenu}
          />
        )
      }
    </RightRail>
  );
}

export { InvoiceRightRail };
