import { Predicate as P } from "effect";
import type { MutableRefObject, Ref } from "react";
import { useCallback } from "react";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { cast } from "@ender/shared/types/cast";

type PossibleRef<T> = Ref<T> | Undefined;

function assignRef<T>(ref: PossibleRef<T>, value: T) {
  const refNotNull = !P.isNull(ref);

  if (P.isFunction(ref)) {
    ref(value);
  } else if (P.isRecord(ref) && refNotNull && "current" in ref) {
    cast<MutableRefObject<T>>(ref).current = value;
  }
}

function mergeRefs<T>(...refs: PossibleRef<T>[]) {
  return (node: T | Null) => {
    refs.forEach((ref) => assignRef(ref, node));
  };
}

function useMergedRef<T>(...refs: PossibleRef<T>[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(mergeRefs(...refs), refs);
}

export { assignRef, mergeRefs, useMergedRef };
