/**
 * HTTP Method: PATCH
 * Pathname: /construction/inspections/{inspectionId}
 * @function updateInspection
 * @memberof InspectionAPI
 * @param {InspectionAPIUpdateInspectionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIUpdateInspectionPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIUpdateInspectionSearchParams = {
  token?: string | undefined;
};

type InspectionAPIUpdateInspectionBodyParams = {
  name: string;
};

type InspectionAPIUpdateInspectionPayload =
  InspectionAPIUpdateInspectionPathParams &
    InspectionAPIUpdateInspectionSearchParams &
    InspectionAPIUpdateInspectionBodyParams;

function updateInspectionUnsafeEffect(
  payload: InspectionAPIUpdateInspectionPayload,
) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionResponse>({}),
        encode: encodeJsonBody({ method: "PATCH" }),
        pathname: `/construction/inspections/${inspectionId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateInspection(
  payload: InspectionAPIUpdateInspectionPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionResponse> {
  return F.pipe(
    payload,
    updateInspectionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateInspection };
export type {
  InspectionAPIUpdateInspectionBodyParams,
  InspectionAPIUpdateInspectionPathParams,
  InspectionAPIUpdateInspectionPayload,
  InspectionAPIUpdateInspectionSearchParams,
};
