/**
 * HTTP Method: PATCH
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}/zones/{zoneId}
 * @function updateZone
 * @memberof InspectionAPI
 * @param {InspectionAPIUpdateZonePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionZoneResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionZoneResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIUpdateZonePathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
  zoneId: EnderId;
};

type InspectionAPIUpdateZoneSearchParams = {
  token?: string | undefined;
};

type InspectionAPIUpdateZoneBodyParams = {
  notes?: string | undefined;
};

type InspectionAPIUpdateZonePayload = InspectionAPIUpdateZonePathParams &
  InspectionAPIUpdateZoneSearchParams &
  InspectionAPIUpdateZoneBodyParams;

function updateZoneUnsafeEffect(payload: InspectionAPIUpdateZonePayload) {
  const { inspectionId, areaId, zoneId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionZoneResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionZoneResponse>({}),
        encode: encodeJsonBody({ method: "PATCH" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}/zones/${zoneId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateZone(
  payload: InspectionAPIUpdateZonePayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionZoneResponse> {
  return F.pipe(
    payload,
    updateZoneUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateZone };
export type {
  InspectionAPIUpdateZoneBodyParams,
  InspectionAPIUpdateZonePathParams,
  InspectionAPIUpdateZonePayload,
  InspectionAPIUpdateZoneSearchParams,
};
