import { IconTrash } from "@tabler/icons-react";
import { Function as F } from "effect";
import { useContext } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { FontSize, Text } from "@ender/shared/ds/text";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";

type DeleteApprovalProcessButtonProps = {
  approvalProcessId: EnderId;
  onSuccess: () => void;
  isDeleting?: boolean | Undefined;
  setIsDeleting?: (val: boolean) => void;
};

function DeleteApprovalProcessButton({
  approvalProcessId,
  onSuccess,
  isDeleting,
  setIsDeleting = F.constVoid,
}: DeleteApprovalProcessButtonProps) {
  const confirmation = useContext(ConfirmationContext);
  async function handleDeleteApprovalProcess() {
    try {
      await confirmation(
        {
          confirmButtonLabel: "Delete",
          content: (
            <Text size={FontSize.sm}>
              Are you sure you want to delete this approval chain? Any invoices
              currently in this chain will be moved to the default approval
              chain.
            </Text>
          ),
          title: "Delete this Approval Process?",
        },
        { confirmButtonProps: { color: Color.red } },
      );
      setIsDeleting(true);
      await ApprovalsAPI.deleteApprovalProcess({ approvalProcessId });
      showSuccessNotification({
        message: "Successfully deleted approval process.",
      });
      onSuccess();
    } catch (err) {
      fail(err);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <Button
      variant={ButtonVariant.transparent}
      aria-label="Delete approval process"
      onClick={handleDeleteApprovalProcess}
      disabled={isDeleting}>
      {isDeleting ? <LoadingSpinner /> : <IconTrash />}
    </Button>
  );
}

export { DeleteApprovalProcessButton };
