/**
 * HTTP Method: POST
 * Pathname: /reports/{reportId}/widgets
 * @function createWidget
 * @memberof ReportsAPI
 * @param {ReportsAPICreateWidgetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportWidget>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { WidgetLocationRequest } from "@ender/shared/generated/ender.api.reports.request";
import type {
  ReportWidget,
  ReportWidgetWidgetType,
} from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPICreateWidgetPathParams = {
  reportId: EnderId;
};

type ReportsAPICreateWidgetSearchParams = {
  token?: string | undefined;
};

type ReportsAPICreateWidgetBodyParams = {
  baseModelType: ModelType;
  location: WidgetLocationRequest;
  pageId: EnderId;
  type: ReportWidgetWidgetType;
};

type ReportsAPICreateWidgetPayload = ReportsAPICreateWidgetPathParams &
  ReportsAPICreateWidgetSearchParams &
  ReportsAPICreateWidgetBodyParams;

function createWidgetUnsafeEffect(payload: ReportsAPICreateWidgetPayload) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportWidget>({
        body,
        decode: unsafeDecodeJsonResponse<ReportWidget>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/reports/${reportId}/widgets`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createWidget(
  payload: ReportsAPICreateWidgetPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportWidget> {
  return F.pipe(
    payload,
    createWidgetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createWidget };
export type {
  ReportsAPICreateWidgetBodyParams,
  ReportsAPICreateWidgetPathParams,
  ReportsAPICreateWidgetPayload,
  ReportsAPICreateWidgetSearchParams,
};
