/**
 * HTTP Method: GET
 * Pathname: /bankAccounts/{bankAccountId}/sensitive-fields
 * @function getBankAccountSensitiveFields
 * @memberof BankingAPI
 * @param {BankingAPIGetBankAccountSensitiveFieldsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetBankAccountSensitiveFieldsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetBankAccountSensitiveFieldsResponse } from "@ender/shared/generated/ender.model.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetBankAccountSensitiveFieldsPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIGetBankAccountSensitiveFieldsSearchParams = {
  token?: string | undefined;
};

type BankingAPIGetBankAccountSensitiveFieldsPayload =
  BankingAPIGetBankAccountSensitiveFieldsPathParams &
    BankingAPIGetBankAccountSensitiveFieldsSearchParams;

function getBankAccountSensitiveFieldsUnsafeEffect(
  payload: BankingAPIGetBankAccountSensitiveFieldsPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetBankAccountSensitiveFieldsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetBankAccountSensitiveFieldsResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/bankAccounts/${bankAccountId}/sensitive-fields`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBankAccountSensitiveFields(
  payload: BankingAPIGetBankAccountSensitiveFieldsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetBankAccountSensitiveFieldsResponse> {
  return F.pipe(
    payload,
    getBankAccountSensitiveFieldsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankAccountSensitiveFields };
export type {
  BankingAPIGetBankAccountSensitiveFieldsPathParams,
  BankingAPIGetBankAccountSensitiveFieldsPayload,
  BankingAPIGetBankAccountSensitiveFieldsSearchParams,
};
