import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UtilitiesPolicyBillingMethodValues = [
  "SUBMETERING",
  "AUTHORIZED_OCCUPANT",
  "FACTORED_OCCUPANT",
  "DIVIDED_EQUALLY",
  "CONSERVICE",
] as const;

const UtilitiesPolicyBillingMethodEffectSchema = Schema.Literal(
  ...UtilitiesPolicyBillingMethodValues,
);

type UtilitiesPolicyBillingMethod = Schema.Schema.Type<
  typeof UtilitiesPolicyBillingMethodEffectSchema
>;

const UtilitiesPolicyBillingMethodEnum = castEnum(
  UtilitiesPolicyBillingMethodEffectSchema,
);

function randomUtilitiesPolicyBillingMethod(): UtilitiesPolicyBillingMethod {
  return rand(UtilitiesPolicyBillingMethodValues);
}

export {
  randomUtilitiesPolicyBillingMethod,
  UtilitiesPolicyBillingMethodEffectSchema,
  UtilitiesPolicyBillingMethodEnum,
  UtilitiesPolicyBillingMethodValues,
};
export type { UtilitiesPolicyBillingMethod };
