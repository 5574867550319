import { Predicate as P } from "effect";
import type { ReactNode } from "react";
import { useContext, useMemo, useState } from "react";

import type { Null } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import { Divider } from "@ender/shared/ds/divider";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import {
  ApprovalProcessTypeEnum,
  ApprovalStepApprovalPhaseEnum,
} from "@ender/shared/generated/ender.model.approvals";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";

import type { ApprovalProcessHybridId } from "../../../widgets/approval-process";
import {
  ApprovalProcessRuleFilters,
  ApprovalProcessRuleSelect,
  ApprovalProcessRuleSteps,
  DEFAULT_APPROVAL_PROCESS_HYBRID_ID,
  useApprovalProcess,
  useApprovalProcesses,
} from "../../../widgets/approval-process";
import { useInvoicesData } from "../invoices-table-page/utils/invoices-data/use-invoices-data-v2";
import { MissingApprovalProcessEditPermissions } from "./missing-approval-process-edit-permissions";

const REQUIRED_EDIT_PERMISSIONS = [
  FunctionalPermissionEnum.EDIT_APPROVAL_PROCESS_PIPELINE,
];

function PayablesApprovalProcessesPage() {
  const { getMissingPermissions } = useContext(UserContext);
  const missingEditPermissions = useMemo<ReactNode>(() => {
    const missingPermissions = getMissingPermissions(REQUIRED_EDIT_PERMISSIONS);
    if (missingPermissions.size < 1) {
      return UNDEFINED;
    }
    return (
      <MissingApprovalProcessEditPermissions
        missingPermissions={missingPermissions}
      />
    );
  }, [getMissingPermissions]);

  const [approvalProcessHybridId, setApprovalProcessHybridId] = useState<
    ApprovalProcessHybridId | Null
  >(DEFAULT_APPROVAL_PROCESS_HYBRID_ID);
  const { approvalProcesses, refetchApprovalProcesses } = useApprovalProcesses({
    approvalProcessType: ApprovalProcessTypeEnum.PAYABLE,
  });
  const approvalProcess = useApprovalProcess({
    approvalProcessHybridId,
    approvalProcesses,
  });
  const { refetch: refetchInvoicesData } = useInvoicesData({
    invoiceType: ApprovalProcessTypeEnum.PAYABLE,
    limit: 100,
  });

  function onDeleteApprovalProcessSuccess() {
    setApprovalProcessHybridId(DEFAULT_APPROVAL_PROCESS_HYBRID_ID);
  }

  function onCreateSuccess({
    approvalProcessId,
  }: {
    approvalProcessId: ApprovalProcessHybridId;
  }) {
    setApprovalProcessHybridId(approvalProcessId);
  }

  async function onSuccess() {
    await refetchApprovalProcesses();
    await refetchInvoicesData();
  }

  return (
    <Stack>
      <H2>Payables Approval Process</H2>
      <ApprovalProcessRuleSelect
        type={ApprovalProcessTypeEnum.PAYABLE}
        approvalProcessHybridId={approvalProcessHybridId}
        onChange={setApprovalProcessHybridId}
      />
      {P.isNotNullable(approvalProcess) && (
        <>
          <ApprovalProcessRuleFilters
            approvalProcess={approvalProcess}
            onCreateSuccess={onCreateSuccess}
            onDeleteApprovalProcessSuccess={onDeleteApprovalProcessSuccess}
            refetchData={onSuccess}
            missingEditPermissions={missingEditPermissions}
            type={ApprovalProcessTypeEnum.PAYABLE}
          />
          <ApprovalProcessRuleSteps
            approvalProcess={approvalProcess}
            approvalProcessPhase={
              ApprovalStepApprovalPhaseEnum.ACCOUNTS_PAYABLE
            }
            areStepsOptional
            missingEditPermissions={missingEditPermissions}
            onStepActionSuccess={onSuccess}
            title="Approvals"
            titleTooltip="Invoices in these steps are not posted on the General Ledger"
            type={ApprovalProcessTypeEnum.PAYABLE}
          />

          <Divider />
          <ApprovalProcessRuleSteps
            addButtonLabel="Add Treasury Step"
            approvalProcess={approvalProcess}
            approvalProcessPhase={ApprovalStepApprovalPhaseEnum.TREASURY}
            missingEditPermissions={missingEditPermissions}
            onStepActionSuccess={onSuccess}
            title="Treasury"
            titleTooltip="Invoices in these steps are posted on the General Ledger"
            type={ApprovalProcessTypeEnum.PAYABLE}
          />
        </>
      )}
    </Stack>
  );
}

export { PayablesApprovalProcessesPage };
