import { useCallback, useEffect, useState } from "react";
import type { z } from "zod";

import { useQueryParams } from "@ender/shared/hooks/use-query-params";

type UseTabsProps<TabEnum extends z.ZodEnum<[string, ...string[]]>> = {
  defaultValue: z.infer<TabEnum>;
  schema: TabEnum;
};

// Define the return type of the hook using the inferred type from the ZodEnum schema
type UseUrlSyncedTabsReturn<TabType extends string> = [
  TabType,
  (value: TabType) => void,
];

function useUrlSyncedTabs<TabEnum extends z.ZodEnum<[string, ...string[]]>>({
  defaultValue,
  schema,
}: UseTabsProps<TabEnum>): UseUrlSyncedTabsReturn<z.infer<TabEnum>> {
  const { tab: activeTabQuery, setQueryParams } = useQueryParams();
  const safeParseResult = schema.safeParse(activeTabQuery);
  const defaultTab = safeParseResult.success
    ? safeParseResult.data
    : defaultValue;
  const [activeTab, setActiveTab] = useState(defaultTab);

  // lazy useEffect
  useEffect(() => {
    if (activeTabQuery) {
      const parseResult = schema.safeParse(activeTabQuery);
      if (parseResult.success && activeTab !== parseResult.data) {
        setActiveTab(parseResult.data);
      } else if (!parseResult.success) {
        setQueryParams({ tab: defaultValue }, true);
      }
    } else {
      setQueryParams({ tab: activeTab }, true);
    }
  }, [
    activeTab,
    activeTabQuery,
    setQueryParams,
    defaultValue,
    schema,
    setActiveTab,
  ]);

  const finalSetTab = useCallback(
    (newTab: z.infer<TabEnum>) => {
      setActiveTab(newTab);
      setQueryParams({ tab: newTab }, true);
    },
    [setActiveTab, setQueryParams],
  );

  return [activeTab, finalSetTab];
}

export { useUrlSyncedTabs };
export type { UseUrlSyncedTabsReturn };
