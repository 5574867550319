import { IconCheck, IconCopy } from "@tabler/icons-react";
import type { MouseEvent, PropsWithChildren } from "react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import type { Justifications } from "@ender/shared/ds/flex";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { useClipboard } from "@ender/shared/hooks/use-clipboard";

import styles from "./copy-to-clipboard.module.css";

type CopyToClipboardProps = {
  tooltip?: string;

  /** @description The amount of time (in MS) to keep clipboard in copied state */
  timeout?: number;

  /** @description The text to be copied */
  value: string;
  useTransparent?: boolean;
  justify?: Justifications;
};

/** @description Adds the ability to copy text to clipboard
 *
 * @deprecated this behavior is not re-used often enough to justify a component.
 * Current recommendation is to use the `useClipboard` hook + a DS Button or ActionIcon where needed, and
 * remove this component entirely
 * */
function CopyToClipboard({
  value,
  timeout = 1000,
  tooltip = "Copy",
  useTransparent = false,
  justify = Justify.start,
  children,
}: PropsWithChildren<CopyToClipboardProps>) {
  const clipboard = useClipboard({ timeout });

  /**
   * @function onCopyTextClick
   * @description Handles the Copy Text click
   * @param {*} e The click event
   */
  function onCopyTextClick(e: MouseEvent) {
    e.stopPropagation();
    clipboard.copy(value);
  }

  const variant = useTransparent
    ? ButtonVariant.transparent
    : ButtonVariant.outlined;

  return (
    <Group spacing={Spacing.xs} align={Align.center} noWrap justify={justify}>
      <div className={styles.copyContent}>{children || value}</div>
      <ActionIcon
        onClick={onCopyTextClick}
        variant={variant}
        size={ButtonSize.lg}
        tooltip={tooltip}>
        <Group>
          {clipboard.copied ? <IconCheck color="green" /> : <IconCopy />}
        </Group>
      </ActionIcon>
    </Group>
  );
}

export { CopyToClipboard };
