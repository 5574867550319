import { IconTrash } from "@tabler/icons-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Array as A, Option as O, Predicate as P } from "effect";
import type { MouseEvent } from "react";
import { useStore } from "zustand";

import { NULL } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { useTenantLedgerStore } from "../../tenant-ledger-store.context";

import styles from "./ledger-event-attachments.module.css";

function LedgerEventAttachments() {
  const confirmation = useConfirmationContext();
  const queryClient = useQueryClient();
  const tenantLedgerStore = useTenantLedgerStore();
  const { selectedLedgerEvent } = useStore(tenantLedgerStore, (state) => ({
    selectedLedgerEvent: state.selectedLedgerEvent,
  }));

  const { data: attachments, isLoading } = useQuery({
    queryKey: ["WebserverFilesAPI.getFiles", "ledgerEventAttachments"] as const,
    queryFn: ({ signal }) =>
      WebserverFilesAPI.getFiles(
        {
          modelId: selectedLedgerEvent.pipe(
            O.map((e) => e.id),
            O.getOrThrow,
          ),
          // @ts-expect-error the types of upload eventType and ledgerEventType don't fully overlap
          modelType: selectedLedgerEvent.pipe(
            O.map((e) => e.ledgerEventType),
            O.getOrThrow,
          ),
        },
        { signal },
      ),
    enabled: O.isSome(selectedLedgerEvent),
    select: (res) => res.files,
  });

  async function deleteAttachment(attachmentToDelete: FilesClientEnderFile) {
    const _selectedLedgerEvent = selectedLedgerEvent.pipe(O.getOrThrow);
    if (P.isNullable(_selectedLedgerEvent)) {
      return;
    }

    try {
      await WebserverFilesAPI.deleteFile({
        fileId: attachmentToDelete.id,
        modelId: _selectedLedgerEvent.id,
        // @ts-expect-error the types of upload eventType and ledgerEventType don't fully overlap
        modelType: _selectedLedgerEvent.ledgerEventType,
      });
      showSuccessNotification({ message: "Document deleted." });
      await queryClient.invalidateQueries(["ledgerEventAttachments"]);
    } catch (err) {
      fail(err);
    }
  }

  if (A.isEmptyArray(attachments ?? [])) {
    return NULL;
  }

  return (
    <Stack>
      <H2>Attachments</H2>
      <Skeleton visible={isLoading}>
        <Group>
          {(attachments ?? []).map((attachment) => (
            <div
              key={attachment.s3Url}
              className={styles.ledgerEventAttachmentsThumbnail}
              onClick={() => window.open(attachment.s3Url)}>
              <Group justify={Justify.center} align={Align.center}>
                <Tooltip label={attachment.filename}>
                  <img src={attachment.s3Url} alt="charge attachment" />
                </Tooltip>
                <div className={styles.deleteAttachmentOverlay}>
                  <IconTrash
                    onClick={async (e: MouseEvent<SVGSVGElement>) => {
                      e.stopPropagation();
                      await confirmation({
                        confirmButtonLabel: "Delete",
                        title: `Are you sure you want to remove "${attachment?.filename}"?`,
                      });
                      await deleteAttachment(attachment);
                    }}
                  />
                </div>
              </Group>
            </div>
          ))}
        </Group>
      </Skeleton>
    </Stack>
  );
}

export { LedgerEventAttachments };
