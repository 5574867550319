/**
 * HTTP Method: POST
 * Pathname: /invoices/mark-paid-batch
 * @function markPaidBatch
 * @memberof InvoicesAPI
 * @param {InvoicesAPIMarkPaidBatchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarkPaidBatchResponse>}
 */
import { Effect, Function as F } from "effect";

import type { TransferDetails } from "@ender/shared/generated/ender.api.accounting.request";
import type { MarkPaidBatchResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIMarkPaidBatchSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIMarkPaidBatchBodyParams = {
  overrideWarnings?: boolean | undefined;
  transferDetails: TransferDetails[];
};

type InvoicesAPIMarkPaidBatchPayload = InvoicesAPIMarkPaidBatchSearchParams &
  InvoicesAPIMarkPaidBatchBodyParams;

function markPaidBatchUnsafeEffect(payload: InvoicesAPIMarkPaidBatchPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarkPaidBatchResponse>({
        body,
        decode: unsafeDecodeJsonResponse<MarkPaidBatchResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/mark-paid-batch",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function markPaidBatch(
  payload: InvoicesAPIMarkPaidBatchPayload,
  options?: { signal?: AbortSignal },
): Promise<MarkPaidBatchResponse> {
  return F.pipe(
    payload,
    markPaidBatchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { markPaidBatch };
export type {
  InvoicesAPIMarkPaidBatchBodyParams,
  InvoicesAPIMarkPaidBatchPayload,
  InvoicesAPIMarkPaidBatchSearchParams,
};
