import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Modal } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { AddUnitAreaForm } from "./add-unit-area-form";
import { useCanEditAreasAndZones } from "./use-can-edit-areas-and-zones";

type AddUnitAreaButtonProps = {
  unitId: EnderId;
};

function AddUnitAreaButton({ unitId }: AddUnitAreaButtonProps) {
  const { disabled, disabledTooltip } = useCanEditAreasAndZones(unitId);
  const [isAddUnitAreaModalOpen, setIsAddUnitAreaModalOpen] = useBoolean(false);

  return (
    <>
      <Button
        onClick={setIsAddUnitAreaModalOpen.setTrue}
        variant={ButtonVariant.outlined}
        disabled={disabled}
        disabledTooltip={disabledTooltip}>
        Add Area to Property
      </Button>
      <Modal
        opened={isAddUnitAreaModalOpen}
        onClose={setIsAddUnitAreaModalOpen.setFalse}
        title="Add Area">
        <AddUnitAreaForm
          onClose={setIsAddUnitAreaModalOpen.setFalse}
          unitId={unitId}
        />
      </Modal>
    </>
  );
}

export { AddUnitAreaButton };
