import { Schema } from "@effect/schema";
import { Option as O, Predicate as P, pipe } from "effect";
import type { ElementRef } from "react";
import { forwardRef } from "react";

import { isBirthdayMinor } from "@ender/entities/utils/application-utils";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import {
  EmailEffectSchema,
  LocalDateEffectSchema,
  PhoneEffectSchema,
} from "@ender/form-system/schema";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Grid } from "@ender/shared/ds/grid";
import { H1 } from "@ender/shared/ds/heading";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { ApplicationApplicantType } from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationApplicantTypeEnum,
  ApplicationApplicantTypeValues,
  ApplicationRelationshipTypeValues,
} from "@ender/shared/generated/ender.model.leasing";
import { noOpAsync } from "@ender/shared/utils/no-op";
import { capitalize } from "@ender/shared/utils/string";

const ApplicantFormSchema = Schema.Struct({
  firstName: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Required" }),
    Schema.minLength(2, { message: () => "Must be at least 2 characters" }),
  ),
  lastName: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Required" }),
    Schema.minLength(2, { message: () => "Must be at least 2 characters" }),
  ),
  email: EmailEffectSchema,
  phone: PhoneEffectSchema,
  middleName: Schema.String,
  applicantType: Schema.Literal(...ApplicationApplicantTypeValues).pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  // not currently required by API as parameter, but a checkNotNull does occur
  birthday: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
    Schema.filter(
      O.exists<LocalDate$.LocalDate>((v) =>
        v.isBeforeOrEqual(LocalDate$.today()),
      ),
      { message: () => "Date of Birth must not be in the future" },
    ),
  ),
  relationship: Schema.Literal(...ApplicationRelationshipTypeValues).pipe(
    Schema.OptionFromSelf,
  ),
}).pipe(
  Schema.filter(({ birthday, applicantType }) => {
    const issues: Schema.FilterIssue[] = [];
    if (
      !O.contains(applicantType, "OTHER_OCCUPANT") &&
      isBirthdayMinor(birthday)
    ) {
      issues.push({
        message: `${pipe(applicantType, O.map(capitalize), O.getOrThrow)} must not be a minor`,
        path: ["birthday"],
      });
    }
    return issues;
  }),
);

type ApplicantFormInput = Schema.Schema.Encoded<typeof ApplicantFormSchema>;
type ApplicantFormOutput = Schema.Schema.Type<typeof ApplicantFormSchema>;

const occupantTypeStringMap: Record<ApplicationApplicantType, string> = {
  RESPONSIBLE_OCCUPANT: "Financially Responsible Occupant",
  OTHER_OCCUPANT: "Occupant",
  GUARANTOR: "Guarantor",
};

const defaultPerson: ApplicantFormInput = {
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  birthday: O.none(),
  email: "",
  applicantType: O.none(),
  relationship: O.none(),
};

type AddOrEditApplicantProps = {
  /**
   * All fields of Applicant are used as default values for the edit form
   */
  applicant?: Partial<User>;
  /**
   * the ID of the user who is currently editing the occupant
   */
  userId?: EnderId;
  applicantType: ApplicationApplicantType;
  onSubmit?: (values: ApplicantFormOutput) => Promise<void>;
};

/**
 * Displays a form which allows to add or edit an application's occupants/applicants.
 * If the passed in 'applicant' contains no ID, the form will use verbiage that indicates it is creating a new occupant.
 * Otherwise, the form will assume that it is editing an existing applicant.
 *
 * to be rendered in a modal.
 */
const AddOrEditApplicant = forwardRef<
  ElementRef<typeof Form>,
  AddOrEditApplicantProps
>(function AddOrEditApplicant(
  { userId, applicant, applicantType, onSubmit: handleSubmit = noOpAsync },
  _ref,
) {
  const isMainApplicant = P.isNotNullable(userId) && applicant?.id === userId;
  const occupantType = isMainApplicant
    ? "Main Occupant"
    : occupantTypeStringMap[applicantType];

  const form = useEffectSchemaForm({
    defaultValues: {
      ...defaultPerson,
      ...applicant,
      applicantType: O.fromNullable(applicantType),
      birthday: LocalDate$.parse(applicant?.birthday),
    },
    schema: ApplicantFormSchema,
  });

  const isMinor = isBirthdayMinor(form.watch("birthday"));

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <H1>
          {P.isNotNullable(applicant?.id)
            ? `Edit ${occupantType}`
            : `Add ${occupantType}`}
        </H1>
        <Grid>
          <FormTextInput form={form} label="First Name" name="firstName" />
          <FormTextInput form={form} label="Last Name" name="lastName" />
          <FormDateInput form={form} label="Date of Birth" name="birthday" />
          <FormTextInput
            form={form}
            disabled={
              applicantType === ApplicationApplicantTypeEnum.OTHER_OCCUPANT &&
              isMinor
            }
            label="Email Address"
            name="email"
          />
          <FormPhoneInput
            form={form}
            disabled={
              applicantType === ApplicationApplicantTypeEnum.OTHER_OCCUPANT &&
              isMinor
            }
            label="Phone Number"
            name="phone"
          />
          {/* TODO uncomment once we can actually save phone type */}
          {/* <EnderSelect
          disabled={isMinor}
          value={"Mobile"}
          data={["Home", "Mobile"]}
          label="Phone Type"
        /> */}
        </Grid>
        <Button type="submit">
          {applicant?.id ? "Save" : "Add"}{" "}
          {applicantType === ApplicationApplicantTypeEnum.GUARANTOR
            ? "Guarantor"
            : "Occupant"}
        </Button>
      </Stack>
    </Form>
  );
});

export { AddOrEditApplicant };
export type { ApplicantFormOutput };
