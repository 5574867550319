import type { UseFormReturnType } from "@mantine/form";
import { Option as O, Predicate as P, pipe } from "effect";

import { AccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { NULL } from "@ender/shared/constants/general";
import { LocalDate$ } from "@ender/shared/core";
import { DateInput } from "@ender/shared/ds/date-input";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { Textarea } from "@ender/shared/ds/textarea";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";

import { SeparatePaymentsCheckbox } from "./separate-payments-checkbox";

type FieldsForPaymentTypeProps<TFormValues> = {
  form: UseFormReturnType<TFormValues>;
  formFieldsPrefix: string;
  hideSeparatePaymentsCheckbox?: boolean;
  invoiceType: InvoiceInvoiceType;
  paymentType: MoneyTransferTransferType;
};

function FieldsForPaymentType<TFormValues>({
  form,
  formFieldsPrefix,
  hideSeparatePaymentsCheckbox,
  invoiceType,
  paymentType,
}: FieldsForPaymentTypeProps<TFormValues>) {
  const accountingPeriodType =
    invoiceType === InvoiceInvoiceTypeEnum.RECEIVABLE
      ? AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE
      : AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE;

  if (P.isNullable(invoiceType) || P.isNullable(paymentType)) {
    return NULL;
  }

  const stringToLocalDateOption = (dateString?: string) =>
    O.fromNullable(dateString).pipe(O.map((str) => LocalDate$.of(str)));

  const periodIdProps = form.getInputProps(`${formFieldsPrefix}periodId`);

  return (
    <Stack>
      <DateInput
        label="Date (required for Accounting)"
        name="ledgerDate"
        onChange={(value) => {
          const dateString = value.pipe(
            O.map((ld) => ld.toJSON()), // Convert LocalDate$ to ISO string
            O.getOrUndefined,
          );
          form
            .getInputProps(`${formFieldsPrefix}ledgerDate`)
            .onChange(dateString);
        }}
        value={stringToLocalDateOption(
          form.getInputProps(`${formFieldsPrefix}ledgerDate`).value,
        )}
      />

      <AccountingPeriodSelector
        label="Accounting Period (optional)"
        periodType={accountingPeriodType}
        {...form.getInputProps(`${formFieldsPrefix}periodId`)}
        value={pipe(
          O.fromNullable(periodIdProps.value), // O.Option<string>
          O.map((id) => ({ id })), // O.Option<MinimalAccountingPeriod>
        )}
        onChange={(maybePeriod) => {
          // maybePeriod is O.Option<AccountingPeriod>
          // Extract the ID or undefined from it:
          const newId = pipe(
            maybePeriod,
            O.map((p) => p.id),
            O.getOrUndefined,
          );
          periodIdProps.onChange(newId);
        }}
      />

      {paymentType === MoneyTransferTransferTypeEnum.MARK_PAID_CHECK && (
        <>
          <DateInput
            label="Date on Check (optional)"
            name="checkDate"
            onChange={(value) => {
              const dateString = value.pipe(
                O.map((ld) => ld.toJSON()),
                O.getOrUndefined,
              );
              form
                .getInputProps(`${formFieldsPrefix}checkDate`)
                .onChange(dateString);
            }}
            value={stringToLocalDateOption(
              form.getInputProps(`${formFieldsPrefix}checkDate`).value,
            )}
          />

          <TextInput
            label="Check Number"
            name="checkNumber"
            {...form.getInputProps(`${formFieldsPrefix}checkNumber`)}
          />
        </>
      )}

      <Textarea
        label="Memo (optional)"
        name="memo"
        {...form.getInputProps(`${formFieldsPrefix}memo`)}
      />

      {!hideSeparatePaymentsCheckbox &&
        paymentType === MoneyTransferTransferTypeEnum.BANK_TRANSFER && (
          <SeparatePaymentsCheckbox
            {...form.getInputProps(`${formFieldsPrefix}separatePayments`)}
          />
        )}
    </Stack>
  );
}

export { FieldsForPaymentType };
