import { useCallback, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import type { CollectionsType } from "../collections.types";
import { CollectionsTypeEnum } from "../collections.types";

type CollectionsDividerGroups = "Group A" | "Group B" | "Group C"; // used visually group the tabs with a divider between groups

type CollectionsRoute = {
  label: string;
  description: string;
  path: string;
  type: CollectionsType;
  group: CollectionsDividerGroups;
};

function useCollectionsRoutes() {
  const { type } = useParams<Record<string, string>>();
  const history = useHistory();
  const { search: queryParams } = useLocation();

  const navigate = useCallback(
    (path: string) => {
      history.push(`${path}${queryParams}`);
    },
    [history, queryParams],
  );

  /** @description The URL from collectionsTabs that matches the current URL Collections Type */
  const { activeRoute, activeType, collectionsRoutes, typeForResponse } =
    useMemo(() => {
      const collectionsRoutes: CollectionsRoute[] = [
        {
          //the below will be replaced with 'Stable' once the PR is merged in. To avoid breaking the current tests which I already fixed here:
          // https://github.com/EnderHub/ender/pull/15415, I'm leaving the below as is. once that's merged in we can delete & update description.
          description:
            "All Active Leases with a balance. Includes Stable, Excluded, Upcoming, and Evictions.",
          group: "Group A",
          label: "All Active",
          path: "/props/delinquency-manager/all-active",
          type: CollectionsTypeEnum.ALL_ACTIVE_LEASES,
        },
        {
          description:
            "All Active Leases with a balance that are not Excluded, Upcoming, or Evictions.",
          group: "Group A",
          label: "Stable",
          path: "/props/delinquency-manager/stable",
          type: CollectionsTypeEnum.STABLE,
        },

        {
          description:
            "All Leases with a balance that have a future start date.",
          group: "Group A",
          label: "Upcoming Leases",
          path: "/props/delinquency-manager/upcoming",
          type: CollectionsTypeEnum.UPCOMING,
        },
        {
          description: 'All Active Leases marked "Intend to Evict".',
          group: "Group A",
          label: "Notice Sent",
          path: "/props/delinquency-manager/intend-to-evict",
          type: CollectionsTypeEnum.INTEND_TO_EVICT,
        },
        {
          description: "All Active Leases in Evictions.",
          group: "Group A",
          label: "Petition Filed",
          path: "/props/delinquency-manager/evictions",
          type: CollectionsTypeEnum.EVICTIONS,
        },
        {
          description: "All Leases with an active Payment Plan.",
          group: "Group A",
          label: "Payment Plans",
          path: "/props/delinquency-manager/payment-plans",
          type: CollectionsTypeEnum.PAYMENT_PLANS,
        },
        {
          description:
            'All Active Leases marked as "Excluded" from Delinquency Actions.',
          group: "Group A",
          label: "Excluded",
          path: "/props/delinquency-manager/excluded",
          type: CollectionsTypeEnum.EXCLUDED,
        },
        {
          description:
            "Leases with balances where rent is owed by a housing assistance program.",
          group: "Group A",
          label: "Assisted Rent Due",
          path: "/props/delinquency-manager/assisted-rent-due",
          type: CollectionsTypeEnum.ASSISTED_RENT_DUE,
        },
        {
          description:
            "All Moved Out Leases with a balance after Move-out Accounting is completed.",
          group: "Group B",
          label: "Moved Out",
          path: "/props/delinquency-manager/moved-out",
          type: CollectionsTypeEnum.MOVED_OUT,
        },
        // {
        //   label: "Collections",
        //   description: "Leases with a balance that have been handed over to Collections for further action.",
        //   path: "/props/delinquency-manager/collections",
        //   group: "Group B",
        //   type: CollectionsTypeEnum.COLLECTIONS,
        // },
        {
          description: "All Leases with a balance.",
          group: "Group B",
          label: "All",
          path: "/props/delinquency-manager/all",
          type: CollectionsTypeEnum.ALL,
        },
        {
          description: "All Leases without a balance.",
          group: "Group C",
          label: "Non-Delinquent",
          path: "/props/delinquency-manager/non-delinquent",
          type: CollectionsTypeEnum.NON_DELINQUENT,
        },
      ];

      // Hack to match the base URL
      const _typeToMatch = type ?? "delinquency-manager";

      const matchingPath = collectionsRoutes
        .map(({ path, type }) => ({ path, type }))
        .find(({ path }) => _typeToMatch === path.split("/").slice(-1)[0]);

      const { matchingSubRoute, matchingType } = {
        matchingSubRoute: matchingPath?.path ?? collectionsRoutes[0].path,
        matchingType: matchingPath?.type ?? CollectionsTypeEnum.STABLE,
      };

      return {
        activeRoute: matchingSubRoute,
        activeType: matchingType,
        collectionsRoutes,
        typeForResponse: type ?? "collections",
      };
    }, [type]);

  return {
    activeRoute,
    activeType,
    navigate,
    routes: collectionsRoutes,
    type: typeForResponse,
  };
}

export { useCollectionsRoutes };

export type { CollectionsDividerGroups, CollectionsRoute };
