import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const AccountingPeriodPeriodStatusValues = [
  "OPEN",
  "PARTIALLY_OPEN",
  "CLOSED",
] as const;

const AccountingPeriodPeriodStatusEffectSchema = Schema.Literal(
  ...AccountingPeriodPeriodStatusValues,
);

type AccountingPeriodPeriodStatus = Schema.Schema.Type<
  typeof AccountingPeriodPeriodStatusEffectSchema
>;

const AccountingPeriodPeriodStatusEnum = castEnum(
  AccountingPeriodPeriodStatusEffectSchema,
);

function randomAccountingPeriodPeriodStatus(): AccountingPeriodPeriodStatus {
  return rand(AccountingPeriodPeriodStatusValues);
}

export {
  AccountingPeriodPeriodStatusEffectSchema,
  AccountingPeriodPeriodStatusEnum,
  AccountingPeriodPeriodStatusValues,
  randomAccountingPeriodPeriodStatus,
};
export type { AccountingPeriodPeriodStatus };
