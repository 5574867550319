import {
  Array as A,
  Function as F,
  Match,
  Number as Num,
  Option as O,
  String as Str,
} from "effect";
import { useCallback, useMemo, useState } from "react";
import { useStore } from "zustand";

import { DateInput } from "@ender/shared/ds/date-input";
import { Group } from "@ender/shared/ds/group";
import { TriggerButton, TriggerButtonSize } from "@ender/shared/ds/menu";
import type { SelectOption } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import { TextInput } from "@ender/shared/ds/text-input";
import type { TenantLedgerReportLedgerEntryTenantLedgerEventType } from "@ender/shared/generated/ender.arch.accounting";
import {
  TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum,
  TenantLedgerReportLedgerEntryTenantLedgerEventTypeValues,
} from "@ender/shared/generated/ender.arch.accounting";
import { useDebounceState } from "@ender/shared/hooks/use-debounce";
import { fromScreamingSnakeCaseToSpaceCase } from "@ender/shared/utils/string";
import { Size } from "@ender/shared/utils/theming";
import { AccountingPeriodFilter } from "@ender/widgets/filters/accounting-period-filter";
import { MultiFilter } from "@ender/widgets/filters/multi-filter";

import { useOptimizedLedgerResponse } from "../hooks";
import { useTenantLedgerStore } from "../tenant-ledger-store.context";

type TenantLedgerFiltersProps = {
  setFilteredChargeTypes: (value: string[]) => void;
  filteredChargeTypes: string[];
};

const transactionTypeOptions =
  TenantLedgerReportLedgerEntryTenantLedgerEventTypeValues.reduce<
    SelectOption<TenantLedgerReportLedgerEntryTenantLedgerEventType>[]
  >((acc, value) => {
    if (
      value !==
      TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.PREPAYMENT_ALLOCATION
    ) {
      acc.push({
        label: fromScreamingSnakeCaseToSpaceCase(value),
        value,
      });
    }
    return acc;
  }, []);

function TenantLedgerFilters(props: TenantLedgerFiltersProps) {
  const [keyword, setKeywordValue] = useState<string>("");
  const { setFilteredChargeTypes, filteredChargeTypes } = props;
  const tenantLedgerStore = useTenantLedgerStore();

  const accountingPeriod = useStore(
    tenantLedgerStore,
    (state) => state.accountingPeriod,
  );
  const endDate = useStore(tenantLedgerStore, (state) => state.endDate);
  const setAccountingPeriod = useStore(
    tenantLedgerStore,
    (state) => state.setAccountingPeriod,
  );
  const setEndDate = useStore(tenantLedgerStore, (state) => state.setEndDate);
  const setStartDate = useStore(
    tenantLedgerStore,
    (state) => state.setStartDate,
  );
  const setTransactionDescription = useStore(
    tenantLedgerStore,
    (state) => state.setTransactionDescription,
  );
  const setTransactionType = useStore(
    tenantLedgerStore,
    (state) => state.setTransactionType,
  );
  const startDate = useStore(tenantLedgerStore, (state) => state.startDate);
  const transactionDescription = useStore(
    tenantLedgerStore,
    (state) => state.transactionDescription,
  );
  const transactionType = useStore(
    tenantLedgerStore,
    (state) => state.transactionType,
  );

  const { ledgerEvents } = useOptimizedLedgerResponse();

  const [descriptionInput, setDescriptionInput] = useDebounceState<string>({
    initialValue: transactionDescription,
    milliseconds: 750,
    onDebounceValueChange: (val) => {
      setTransactionDescription(val);
    },
  });

  const chargeTypeFilterOptions: SelectOption<string, string>[] =
    useMemo(() => {
      // Helper function to check if the event type is either CHARGE or CREDIT
      const isChargeOrCredit = (
        eventType: TenantLedgerReportLedgerEntryTenantLedgerEventType,
      ): boolean =>
        (
          [
            TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.CHARGE,
            TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.CREDIT,
          ] as TenantLedgerReportLedgerEntryTenantLedgerEventType[]
        ).includes(eventType);

      // Extract unique category names for CHARGE and CREDIT events
      const uniqueCategoryNames = ledgerEvents.reduce(
        (acc: Set<string>, event) => {
          const { allocations, tenantLedgerEventType } = event;

          if (
            isChargeOrCredit(tenantLedgerEventType) &&
            A.isNonEmptyArray(allocations)
          ) {
            acc.add(allocations[0].categoryName);
          }

          return acc;
        },
        new Set<string>(),
      );

      // Convert the Set of category names into an array of select items
      return Array.from(uniqueCategoryNames).map((categoryName) => ({
        label: categoryName,
        value: categoryName,
      }));
    }, [ledgerEvents]);

  const filteredOptions = useMemo(() => {
    const searchText = Str.toUpperCase(keyword);
    return F.pipe(
      chargeTypeFilterOptions,
      A.filter((opt) =>
        F.pipe(opt.label, Str.toUpperCase, Str.includes(searchText)),
      ),
    );
  }, [keyword, chargeTypeFilterOptions]);

  const triggerLabel = useMemo(() => {
    const headLabel = F.pipe(
      filteredChargeTypes,
      A.head,
      O.map((opt) => `: ${opt}`),
      O.getOrElse(F.constant("")),
    );
    const countLabel = Match.value(A.length(filteredChargeTypes)).pipe(
      Match.when(Num.greaterThan(1), (size) => ` +${size - 1}`),
      Match.orElse(F.constant("")),
    );
    return `Charge Type${headLabel}${countLabel}`;
  }, [filteredChargeTypes]);

  const handleChange = useCallback(
    (selectedOptions: { label: string; value: string }[]) => {
      const selectedValues = selectedOptions.map((opt) => opt.value);
      setFilteredChargeTypes(selectedValues);
    },
    [setFilteredChargeTypes],
  );

  const isEmptyChargeTypeOptions = A.isEmptyArray(chargeTypeFilterOptions);

  return (
    <Group>
      <div style={{ width: "300px" }}>
        <TextInput
          placeholder="Transaction Description"
          value={descriptionInput}
          onChange={setDescriptionInput}
          size={Size.sm}
        />
      </div>
      <DateInput
        placeholder="Start Date"
        value={startDate}
        onChange={setStartDate}
      />
      <DateInput placeholder="End Date" value={endDate} onChange={setEndDate} />
      <Select<TenantLedgerReportLedgerEntryTenantLedgerEventType>
        value={transactionType}
        onChange={setTransactionType}
        data={transactionTypeOptions}
        placeholder="Transaction Type"
        clearable
      />
      {!isEmptyChargeTypeOptions && (
        <MultiFilter
          label="Charge Type Filter "
          data={filteredOptions}
          keyword={keyword}
          onKeywordChange={setKeywordValue}
          onChange={handleChange}
          value={filteredChargeTypes.map((type) => ({
            label: type,
            value: type,
          }))}
          disabled={isEmptyChargeTypeOptions}
          trigger={
            <TriggerButton
              rounded={false}
              disabled={isEmptyChargeTypeOptions}
              size={TriggerButtonSize.sm}>
              {triggerLabel}
            </TriggerButton>
          }
        />
      )}
      <AccountingPeriodFilter
        value={accountingPeriod}
        onChange={setAccountingPeriod}
        size={TriggerButtonSize.sm}
      />
    </Group>
  );
}

export { TenantLedgerFilters };
