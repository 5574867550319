import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { String as S } from "effect";

import { Form, useForm } from "@ender/form-system/base";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { CollectionsAPI } from "@ender/shared/generated/ender.api.finance";

import { CollectionsStepTitle } from "../collections-step-title";
import type { OpenSkipCollectionsStepModalProps } from "../collections.types";
import { useInvalidateDelinquencyTableData } from "../hooks/use-invalidate-delinquency-table-data";

type SkipCollectionsStepModalProps = OpenSkipCollectionsStepModalProps & {
  closeModal: () => void;
};

const SkipCollectionsStepFormSchema = Schema.Struct({
  collectionsSkippedNote: Schema.String,
  leaseId: EnderIdFormSchema,
});
type SkipCollectionsStepFormValues = Schema.Schema.Type<
  typeof SkipCollectionsStepFormSchema
>;

function SkipCollectionsStepModal(props: SkipCollectionsStepModalProps) {
  const { closeModal, leaseId, step } = props;
  const queryClient = useQueryClient();
  const invalidateDelinquencyTableData = useInvalidateDelinquencyTableData();

  const form = useForm({
    defaultValues: {
      collectionsSkippedNote: "",
      leaseId,
    },
    mode: "onSubmit",
    resolver: effectTsResolver(SkipCollectionsStepFormSchema),
  });

  const { isLoading, mutateAsync: skipCollectionsStep } = useMutation({
    mutationFn: CollectionsAPI.takeAction,
    mutationKey: ["executeCollectionsAction"],
  });

  async function handleFormSubmit(values: SkipCollectionsStepFormValues) {
    await skipCollectionsStep({
      isSkipAction: true,
      leaseId,
      notes: S.isEmpty(values.collectionsSkippedNote)
        ? "No reason provided"
        : values.collectionsSkippedNote,
      stepId: step.id,
    });

    closeModal();
    invalidateDelinquencyTableData();
    queryClient.invalidateQueries([
      "CollectionsAPI.getLeaseSummary",
      { leaseId },
    ]);
  }

  return (
    <Stack spacing={Spacing.sm}>
      <H2>
        Skip <CollectionsStepTitle step={step} useBrevity /> Step
      </H2>
      <Form form={form} onSubmit={handleFormSubmit}>
        <Stack spacing={Spacing.xl}>
          <FormTextarea
            label="Skip Step Notes"
            name="collectionsSkippedNote"
            form={form}
          />
          <Group justify={Justify.end}>
            <Button
              variant={ButtonVariant.outlined}
              type="button"
              onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" loading={isLoading}>
              Skip Collections Step
            </Button>
          </Group>
        </Stack>
      </Form>
    </Stack>
  );
}

export { SkipCollectionsStepModal };
