/**
 * Unmatch a bankTransaction. Can be used to "un-ignore" an "ignored" (or "excluded") bankTransaction.
 
 Requires BANK_RECONCILIATION functional permission.
 * HTTP Method: POST
 * Pathname: /bankTransactions/{bankTransactionId}/unmatch
 * @function unmatchBankTransaction
 * @memberof BankingAPI
 * @param {BankingAPIUnmatchBankTransactionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIUnmatchBankTransactionPathParams = {
  /**
   * The BankTransaction being unmatched (or un-ignored).
   */
  bankTransactionId: EnderId;
};

type BankingAPIUnmatchBankTransactionSearchParams = {
  token?: string | undefined;
};

type BankingAPIUnmatchBankTransactionPayload =
  BankingAPIUnmatchBankTransactionPathParams &
    BankingAPIUnmatchBankTransactionSearchParams;

function unmatchBankTransactionUnsafeEffect(
  payload: BankingAPIUnmatchBankTransactionPayload,
) {
  const { bankTransactionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankTransactions/${bankTransactionId}/unmatch`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function unmatchBankTransaction(
  payload: BankingAPIUnmatchBankTransactionPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    unmatchBankTransactionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { unmatchBankTransaction };
export type {
  BankingAPIUnmatchBankTransactionPathParams,
  BankingAPIUnmatchBankTransactionPayload,
  BankingAPIUnmatchBankTransactionSearchParams,
};
