/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/people/{applicationId}/role
 * @function changeOccupantRole
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIChangeOccupantRolePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  ApplicationApplicantType,
  ApplicationRelationshipType,
} from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIChangeOccupantRolePathParams = {
  applicationGroupId: EnderId;
  applicationId: EnderId;
};

type ApplicationsAPIChangeOccupantRoleSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIChangeOccupantRoleBodyParams = {
  relationship?: ApplicationRelationshipType | undefined;
  type?: ApplicationApplicantType | undefined;
};

type ApplicationsAPIChangeOccupantRolePayload =
  ApplicationsAPIChangeOccupantRolePathParams &
    ApplicationsAPIChangeOccupantRoleSearchParams &
    ApplicationsAPIChangeOccupantRoleBodyParams;

function changeOccupantRoleUnsafeEffect(
  payload: ApplicationsAPIChangeOccupantRolePayload,
) {
  const { applicationGroupId, applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/people/${applicationId}/role`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function changeOccupantRole(
  payload: ApplicationsAPIChangeOccupantRolePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    changeOccupantRoleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { changeOccupantRole };
export type {
  ApplicationsAPIChangeOccupantRoleBodyParams,
  ApplicationsAPIChangeOccupantRolePathParams,
  ApplicationsAPIChangeOccupantRolePayload,
  ApplicationsAPIChangeOccupantRoleSearchParams,
};
