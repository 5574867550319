import { Option as O } from "effect";
import { isNotNullable } from "effect/Predicate";
import { createJSONStorage, persist } from "zustand/middleware";
import { createStore } from "zustand/vanilla";

import type { Null } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, LocalDate, Money$ } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import type { MinimalPropertyResponse } from "@ender/shared/generated/ender.api.model";
import type { CounterpartyRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { GLCategorySerializerGLCategoryTreeNode } from "@ender/shared/generated/ender.arch.accounting.txsearch";
import type {
  GLCategory,
  GLTxTxType,
} from "@ender/shared/generated/ender.model.accounting";
import { GLTxTxTypeEnum } from "@ender/shared/generated/ender.model.accounting";
import type { CustomFactor } from "@ender/shared/generated/ender.model.factor";
import type { LeasingServiceLeaseDisplayStatus } from "@ender/shared/generated/ender.service.leasing";
import type {
  GroupedGLCategoryBalancesReportProviderGroupByInterval,
  GroupedGLCategoryBalancesReportProviderGroupByType,
} from "@ender/shared/generated/ender.service.reports.builtin";
import {
  GroupedGLCategoryBalancesReportProviderGroupByIntervalEnum,
  GroupedGLCategoryBalancesReportProviderGroupByTypeEnum,
} from "@ender/shared/generated/ender.service.reports.builtin";

type SortOrder = "ASCENDING" | "DESCENDING";

type ReportFiltersStorePayee = CounterpartyRequest & {
  name: string;
};

type ReportFiltersStoreState = {
  accountingPeriods: LocalDate[];
  amount: Money$.Money | Null;
  authors: SelectOption<EnderId, string>[];
  basis: GLTxTxType;
  consolidatedRows: boolean;
  customFactor: string;
  date: O.Option<LocalDate$.LocalDate>;
  description: string;
  endDate: O.Option<LocalDate$.LocalDate>;
  endYear: number;
  firmIds: EnderId[];
  fundIds: EnderId[];
  glCategories: GLCategorySerializerGLCategoryTreeNode[];
  glCategory: GLCategory | undefined;
  groupByInterval: GroupedGLCategoryBalancesReportProviderGroupByInterval;
  groupByType: GroupedGLCategoryBalancesReportProviderGroupByType;
  hideEmptyAccounts: boolean;
  inclusiveEndPaidDate: O.Option<LocalDate$.LocalDate>;
  invoiceDate: O.Option<LocalDate$.LocalDate>;
  invoiceDateText: string;
  invoiceDueDate: string;
  isAllPropertiesSelected: boolean;
  isJournalTabOpen: boolean;
  journalEntryId: EnderId | undefined;
  journalEntryIdFilter: O.Option<EnderId>;
  leaseStatuses: LeasingServiceLeaseDisplayStatus[];
  marketIds: EnderId[];
  monthlyActivityArray: LocalDate[];
  NOIGroups: SelectOption<string, EnderId>[];
  paymentTerms: number;
  pmBalanceEndDate: O.Option<LocalDate$.LocalDate>;
  pmBalanceStartDate: O.Option<LocalDate$.LocalDate>;
  pmId: EnderId | undefined;
  propertyList: O.Option<CustomFactor>;
  selectedPayees: ReportFiltersStorePayee[];
  selectedProperties: SelectOption<EnderId, MinimalPropertyResponse>[];
  showApprovedInvoices: boolean;
  showMovedOutTenants: boolean;
  showNonDelinquentTenants: boolean;
  showOnlyActiveChargeScheduleLeases: boolean;
  showPaidInvoices: boolean;
  showRejectedInvoices: boolean;
  sortOrder: SortOrder;
  startDate: O.Option<LocalDate$.LocalDate>;
  startPaidDate: O.Option<LocalDate$.LocalDate>;
  startYear: number;
  systemEndDate: O.Option<LocalDate$.LocalDate>;
  systemStartDate: O.Option<LocalDate$.LocalDate>;
};

type ReportFiltersStoreActions = {
  reset: () => void;
  setAccountingPeriods: (accountingPeriods: LocalDate[]) => void;
  setAmount: (amount: Money$.Money | undefined) => void;
  setAuthors: (authorIds: SelectOption<EnderId, string>[]) => void;
  setBasis: (basis: GLTxTxType) => void;
  setConsolidatedRows: (consolidatedRows: boolean) => void;
  setCustomFactor: (customFactor: string) => void;
  setDate: (date: O.Option<LocalDate$.LocalDate>) => void;
  setDescription: (description: string) => void;
  setEndDate: (endDate: O.Option<LocalDate$.LocalDate>) => void;
  setInclusiveEndPaidDate: (
    inclusiveEndPaidDateDate: O.Option<LocalDate$.LocalDate>,
  ) => void;
  setEndYear: (endYear: number) => void;
  setFirmIds: (value: EnderId[]) => void;
  setFundIds: (value: EnderId[]) => void;
  setGlCategories: (
    glCategories: GLCategorySerializerGLCategoryTreeNode[],
  ) => void;
  setGlCategory: (glCategory: GLCategory | undefined) => void;
  setGroupByInterval: (
    groupColumnsBy: GroupedGLCategoryBalancesReportProviderGroupByInterval,
  ) => void;
  setGroupByType: (
    columnType: GroupedGLCategoryBalancesReportProviderGroupByType,
  ) => void;
  setHideEmptyAccounts: (hideEmptyAccounts: boolean) => void;
  setInvoiceDate: (invoiceDate: O.Option<LocalDate$.LocalDate>) => void;
  setInvoiceDateText: (invoiceDateText: string) => void;
  setInvoiceDueDate: (invoiceDueDate: string) => void;
  setIsAllPropertiesSelected: (isAllPropertiesSelected: boolean) => void;
  setIsJournalTabOpen: (isJournalTabOpen: boolean) => void;
  setJournalEntryId: (journalEntryId: EnderId | undefined) => void;
  setJournalEntryIdFilter: (journalEntryIdFilter: O.Option<EnderId>) => void;
  setLeaseStatuses: (leaseStatuses: LeasingServiceLeaseDisplayStatus[]) => void;
  setMarketIds: (marketIds: EnderId[]) => void;
  setMonthlyActivityArray: (monthlyActivityArray: LocalDate[]) => void;
  setNOIGroups: (NOIGroups: SelectOption<string, EnderId>[]) => void;
  setPaymentTerms: (paymentTerms: number) => void;
  setPMBalanceEndDate: (
    pmBalanceEndDate: O.Option<LocalDate$.LocalDate>,
  ) => void;
  setPMBalanceStartDate: (
    pmBalanceStartDate: O.Option<LocalDate$.LocalDate>,
  ) => void;
  setPMId: (pmId: EnderId | undefined) => void;
  setPropertyList: (propertyList: O.Option<CustomFactor>) => void;
  setSelectedPayees: (selectedPayees: ReportFiltersStorePayee[]) => void;
  setSelectedProperties: (
    selectedProperties: SelectOption<EnderId, MinimalPropertyResponse>[],
  ) => void;
  setShowApprovedInvoices: (showApprovedInvoices: boolean) => void;
  setShowMovedOutTenants: (showMovedOutTenants: boolean) => void;
  setShowNonDelinquentTenants: (showNonDelinquentTenants: boolean) => void;
  setShowOnlyActiveChargeScheduleLeases: (
    showOnlyActiveChargeScheduleLeases: boolean,
  ) => void;
  setShowPaidInvoices: (showPaidInvoices: boolean) => void;
  setShowRejectedInvoices: (showRejectedInvoices: boolean) => void;
  setSortOrder: (sortOrder: SortOrder) => void;
  setStartDate: (startDate: O.Option<LocalDate$.LocalDate>) => void;
  setStartPaidDate: (startPaidDate: O.Option<LocalDate$.LocalDate>) => void;
  setStartYear: (startYear: number) => void;
  setSystemEndDate: (systemEndDate: O.Option<LocalDate$.LocalDate>) => void;
  setSystemStartDate: (systemStartDate: O.Option<LocalDate$.LocalDate>) => void;
};

type ReportFiltersStore = ReportFiltersStoreState & ReportFiltersStoreActions;

const initialState: ReportFiltersStoreState = {
  accountingPeriods: [],
  amount: NULL,
  authors: [],
  basis: GLTxTxTypeEnum.CASH,
  consolidatedRows: false,
  customFactor: "",
  date: LocalDate$.parse(LocalDate$.today()),
  description: "",
  endDate: O.none(),
  endYear: new Date().getFullYear(),
  firmIds: [],
  fundIds: [],
  glCategories: [],
  glCategory: UNDEFINED,
  groupByInterval:
    GroupedGLCategoryBalancesReportProviderGroupByIntervalEnum.YEAR,
  groupByType: GroupedGLCategoryBalancesReportProviderGroupByTypeEnum.PROPERTY,
  hideEmptyAccounts: true,
  inclusiveEndPaidDate: O.none(),
  invoiceDate: LocalDate$.parse(LocalDate$.today()),
  invoiceDateText: "",
  invoiceDueDate: "",
  isAllPropertiesSelected: false,
  isJournalTabOpen: false,
  journalEntryId: UNDEFINED,
  journalEntryIdFilter: O.none(),
  leaseStatuses: [],
  marketIds: [],
  monthlyActivityArray: [],
  NOIGroups: [],
  paymentTerms: 30,
  pmBalanceEndDate: LocalDate$.parse(LocalDate$.today().add({ days: -1 })),
  pmBalanceStartDate: LocalDate$.parse(LocalDate$.today().add({ months: -1 })),
  pmId: UNDEFINED,
  propertyList: O.none(),
  selectedPayees: [],
  selectedProperties: [],
  showApprovedInvoices: true,
  showMovedOutTenants: false,
  showNonDelinquentTenants: false,
  showOnlyActiveChargeScheduleLeases: false,
  showPaidInvoices: false,
  showRejectedInvoices: false,
  sortOrder: "DESCENDING",
  startDate: O.none(),
  startPaidDate: O.none(),
  startYear: new Date().getFullYear() - 1,
  systemEndDate: O.none(),
  systemStartDate: O.none(),
};

const reportFiltersStore = createStore<ReportFiltersStore>()(
  persist(
    (set) => ({
      ...initialState,
      reset: () => {
        set({ ...initialState });
      },
      setAccountingPeriods: (accountingPeriods) => {
        set({ accountingPeriods });
      },
      setAmount: (amount) => {
        set({ amount });
      },
      setAuthors: (authors) => {
        set({ authors });
      },
      setBasis: (basis) => {
        set({ basis });
      },
      setConsolidatedRows: (consolidatedRows) => {
        set({ consolidatedRows });
      },
      setCustomFactor: (customFactor) => {
        set({ customFactor });
      },
      setDate: (date) => {
        set({ date });
      },
      setDescription: (description) => {
        set({ description });
      },
      setEndDate: (endDate) => {
        set({ endDate });
      },
      setEndYear: (endYear) => {
        set({ endYear });
      },
      setFirmIds: (firmIds) => {
        set({ firmIds });
      },
      setFundIds: (fundIds) => {
        set({ fundIds });
      },
      setGlCategories: (glCategories) => {
        set({ glCategories });
      },
      setGlCategory: (glCategory) => {
        set({ glCategory });
      },
      setGroupByInterval: (groupByInterval) => {
        set({ groupByInterval });
      },
      setGroupByType: (groupByType) => {
        set({ groupByType });
      },
      setHideEmptyAccounts: (hideEmptyAccounts) => {
        set({ hideEmptyAccounts });
      },
      setInclusiveEndPaidDate: (inclusiveEndPaidDate) => {
        set({ inclusiveEndPaidDate });
      },
      setInvoiceDate: (invoiceDate) => {
        set({ invoiceDate });
      },
      setInvoiceDateText: (invoiceDateText) => {
        set({ invoiceDateText });
      },
      setInvoiceDueDate: (invoiceDueDate) => {
        set({ invoiceDueDate });
      },
      setIsAllPropertiesSelected: (isAllPropertiesSelected) => {
        set({ isAllPropertiesSelected });
      },
      setIsJournalTabOpen: (isJournalTabOpen) => {
        set({ isJournalTabOpen });
      },
      setJournalEntryId: (journalEntryId) => {
        set({ journalEntryId });
      },
      setJournalEntryIdFilter: (journalEntryIdFilter) => {
        set({ journalEntryIdFilter });
      },
      setLeaseStatuses: (leaseStatuses) => {
        set({ leaseStatuses });
      },
      setMarketIds: (marketIds) => {
        set({ marketIds });
      },
      setMonthlyActivityArray: (monthlyActivityArray) => {
        set({ monthlyActivityArray });
      },
      setNOIGroups: (NOIGroups) => {
        set({ NOIGroups });
      },
      setPMBalanceEndDate: (pmBalanceEndDate) => {
        set({ pmBalanceEndDate });
      },
      setPMBalanceStartDate: (pmBalanceStartDate) => {
        set({ pmBalanceStartDate });
      },
      setPMId: (pmId: EnderId | undefined) => set({ pmId }),
      setPaymentTerms: (paymentTerms) => {
        set({ paymentTerms });
      },
      setPropertyList: (propertyList) => {
        set({ propertyList });
      },
      setSelectedPayees: (selectedPayees) => {
        set({ selectedPayees });
      },
      setSelectedProperties: (selectedProperties) => {
        set({ selectedProperties });
      },
      setShowApprovedInvoices: (showApprovedInvoices) => {
        set({ showApprovedInvoices });
      },
      setShowMovedOutTenants: (showMovedOutTenants) => {
        set({ showMovedOutTenants });
      },
      setShowNonDelinquentTenants: (showNonDelinquentTenants) => {
        set({ showNonDelinquentTenants });
      },
      setShowOnlyActiveChargeScheduleLeases: (
        showOnlyActiveChargeScheduleLeases,
      ) => {
        set({ showOnlyActiveChargeScheduleLeases });
      },
      setShowPaidInvoices: (showPaidInvoices) => {
        set({ showPaidInvoices });
      },
      setShowRejectedInvoices: (showRejectedInvoices) => {
        set({ showRejectedInvoices });
      },
      setSortOrder: (sortOrder) => {
        set({ sortOrder });
      },
      setStartDate: (startDate) => {
        set({ startDate });
      },
      setStartPaidDate: (startPaidDate) => {
        set({ startPaidDate });
      },
      setStartYear: (startYear) => {
        set({ startYear });
      },
      setSystemEndDate: (systemEndDate) => {
        set({ systemEndDate });
      },
      setSystemStartDate: (systemStartDate) => {
        set({ systemStartDate });
      },
    }),
    {
      name: "reportFilters",
      version: 1,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          delete persistedState.authorIds;
          delete persistedState.firm;
          delete persistedState.fundId;
          delete persistedState.firmId;
          delete persistedState.properties;
          return {
            ...persistedState,
            authors: [],
            date: LocalDate$.parse(LocalDate$.today()),
            endDate: O.none(),
            firmIds: [],
            fundIds: [],
            inclusiveEndPaidDate: O.none(),
            invoiceDate: LocalDate$.parse(LocalDate$.today()),
            pmBalanceEndDate: LocalDate$.parse(
              LocalDate$.today().add({ days: -1 }),
            ),
            pmBalanceStartDate: LocalDate$.parse(
              LocalDate$.today().add({ months: -1 }),
            ),
            startDate: O.none(),
            startPaidDate: O.none(),
            systemEndDate: O.none(),
            systemStartDate: O.none(),
          };
        }
        return persistedState;
      },
      storage: createJSONStorage(() => localStorage, {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reviver: (key: string, value: any) => {
          switch (key) {
            case "date":
            case "invoiceDate":
              return LocalDate$.parse(value.value);

            case "endDate":
            case "startDate":
            case "systemEndDate":
            case "systemStartDate":
            case "startPaidDate":
            case "inclusiveEndPaidDate":
            case "pmBalanceStartDate":
            case "pmBalanceEndDate":
              return isNotNullable(value.value)
                ? LocalDate$.parse(value.value)
                : O.none();

            default:
              return value;
          }
        },
      }),
    },
  ),
);

export { reportFiltersStore };
export type { ReportFiltersStorePayee, ReportFiltersStoreState, SortOrder };
