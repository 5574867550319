/**
 * HTTP Method: POST
 * Pathname: /collections/steps/{stepId}/ungroup
 * @function removeFromExclusiveGroup
 * @memberof CollectionsAPI
 * @param {CollectionsAPIRemoveFromExclusiveGroupPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIRemoveFromExclusiveGroupPathParams = {
  stepId: EnderId;
};

type CollectionsAPIRemoveFromExclusiveGroupSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIRemoveFromExclusiveGroupPayload =
  CollectionsAPIRemoveFromExclusiveGroupPathParams &
    CollectionsAPIRemoveFromExclusiveGroupSearchParams;

function removeFromExclusiveGroupUnsafeEffect(
  payload: CollectionsAPIRemoveFromExclusiveGroupPayload,
) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/collections/steps/${stepId}/ungroup`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeFromExclusiveGroup(
  payload: CollectionsAPIRemoveFromExclusiveGroupPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeFromExclusiveGroupUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeFromExclusiveGroup };
export type {
  CollectionsAPIRemoveFromExclusiveGroupPathParams,
  CollectionsAPIRemoveFromExclusiveGroupPayload,
  CollectionsAPIRemoveFromExclusiveGroupSearchParams,
};
