import { useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { Overflow } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import {
  TabButton,
  TabButtonVariant,
  TabContent,
  Tabs,
  TabsList,
} from "@ender/shared/ds/tabs";
import type { InvoiceResponseAllocationResponse } from "@ender/shared/generated/ender.arch.accounting";

import { AllocationHistoryTable } from "./allocations-table/allocation-history-table";
import { AllocationsTable } from "./allocations-table/allocations-table";

const AllocationTabs = {
  ALLOCATION_HISTORY: "ALLOCATION_HISTORY",
  INVOICE_ALLOCATIONS: "INVOICE_ALLOCATIONS",
} as const;

function InvoiceAllocationsTabs({
  invoiceId,
  allocations,
}: {
  invoiceId: EnderId;
  allocations: InvoiceResponseAllocationResponse[];
}) {
  const [tab, setTab] = useState<keyof typeof AllocationTabs>(
    AllocationTabs.INVOICE_ALLOCATIONS,
  );

  return (
    <Tabs
      value={tab}
      onChange={(newTab) => setTab(newTab as keyof typeof AllocationTabs)}>
      <Stack overflow={Overflow.auto}>
        <TabsList variant={TabButtonVariant.outlined}>
          <TabButton value={AllocationTabs.INVOICE_ALLOCATIONS}>
            Invoice Allocations
          </TabButton>
          <TabButton value={AllocationTabs.ALLOCATION_HISTORY}>
            Allocation History
          </TabButton>
        </TabsList>

        <TabContent value={AllocationTabs.INVOICE_ALLOCATIONS}>
          <AllocationsTable allocations={allocations} />
        </TabContent>
        <TabContent value={AllocationTabs.ALLOCATION_HISTORY}>
          <AllocationHistoryTable
            invoiceId={invoiceId}
            allocations={allocations}
          />
        </TabContent>
      </Stack>
    </Tabs>
  );
}

export { InvoiceAllocationsTabs };
