"use client";

import { Option as O } from "effect";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Text, TextAlign, TextColor } from "@ender/shared/ds/text";
import { TextInput } from "@ender/shared/ds/text-input";

type MultiFactorCodeFormViewProps = {
  code: string;
  errorMessage: O.Option<string>;
  isCodeResend: boolean;
  isResendFetching: boolean;
  isVerifyDisabled: boolean;
  isVerifyFetching: boolean;
  message: string;
  onCodeChange: (code: string) => void;
  onResendCodeClick: () => void;
  onResetChannelClick: () => void;
  onVerifyCodeClick: () => void;
};

function MultiFactorCodeFormView(props: MultiFactorCodeFormViewProps) {
  const {
    code,
    errorMessage,
    isCodeResend,
    isResendFetching,
    isVerifyDisabled,
    isVerifyFetching,
    message,
    onCodeChange,
    onResendCodeClick,
    onResetChannelClick,
    onVerifyCodeClick,
  } = props;

  return (
    <>
      <H1>Enter Verification Code</H1>
      <Text align={TextAlign.center}>{message}</Text>

      <Group>
        <Button
          variant={ButtonVariant.transparent}
          onClick={onResendCodeClick}
          loading={isResendFetching}
          disabled={isResendFetching}>
          {isCodeResend ? "Resend" : "Send"} Verification Code
        </Button>
        <Button
          variant={ButtonVariant.transparent}
          onClick={onResetChannelClick}>
          Reset Channel
        </Button>
      </Group>

      <Stack spacing={Spacing.xl} align={Align.center}>
        <TextInput
          value={code}
          onChange={onCodeChange}
          name="verificationCode"
        />
        {O.isSome(errorMessage) && (
          <Text color={TextColor["red-500"]}>{O.getOrThrow(errorMessage)}</Text>
        )}
        <Button
          onClick={onVerifyCodeClick}
          loading={isVerifyFetching}
          disabled={isVerifyDisabled || isVerifyFetching}>
          Verify
        </Button>
      </Stack>
    </>
  );
}

export { MultiFactorCodeFormView };
