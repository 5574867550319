import { Predicate as P } from "effect";

import { UNDEFINED } from "@ender/shared/constants/general";

import type {
  VendorCounterpartyTag,
  VendorTag,
  VendorTradeType,
} from "./vendor-enums";
import {
  VendorCounterpartyTagSchema,
  VendorSystemTagValues,
  VendorTagSchema,
  VendorTradeTypeSchema,
} from "./vendor-enums";

const toCounterpartyTags = (tags: string[]): VendorCounterpartyTag[] =>
  tags
    .map((tag) => {
      const parsed = VendorCounterpartyTagSchema.safeParse(tag.toUpperCase());
      return parsed.success ? parsed.data : UNDEFINED;
    })
    .filter((val) => !P.isNullable(val));

const toVendorTags = (tags: string[]): VendorTag[] =>
  tags
    .map((tag) => {
      const parsed = VendorTagSchema.safeParse(tag.toUpperCase());
      return parsed.success ? parsed.data : UNDEFINED;
    })
    .filter((val) => !P.isNullable(val));
const toTradeTypes = (tags: string[]): VendorTradeType[] =>
  tags
    .map((tag) => {
      const parsed = VendorTradeTypeSchema.safeParse(tag.toUpperCase());
      return parsed.success ? parsed.data : UNDEFINED;
    })
    .filter((val) => !P.isNullable(val));
const systemTagSet = new Set<string>(VendorSystemTagValues);
const toCustomTags = (tags: string[]): string[] =>
  tags.filter((tag) => !systemTagSet.has(tag.toUpperCase()));

export { toCounterpartyTags, toCustomTags, toTradeTypes, toVendorTags };
