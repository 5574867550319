import { useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import type { ElementRef, ReactNode } from "react";
import { forwardRef, useId } from "react";

import type { Null } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";

type ApplicationGroupIncomeToRentCardProps = {
  listingId?: EnderId;
  applications?: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "validatedThreeMonthIncome"
  >[];
  header?: ReactNode;
};

const ratio = (
  numerator?: Money$.Money | Null,
  denominator?: Money$.Money | null,
) => {
  if (
    P.isNullable(numerator) ||
    P.isNullable(denominator) ||
    Money$.isZero(denominator)
  ) {
    return "--";
  }
  /**
   * ¯\_(ツ)_/¯
   * floating point behavior of toFixed rounds 0.05 and 1.05 up to .1 and 1.1
   * but rounds 2.05 and 3.05 down to 2 and 3
   *
   * this can be fixed by multiplying by 100 before rounding, or by adding a small number (like 0.000001)
   * before doing toFixed. but it's not a big deal for this use case to have exact precision
   */
  return +(numerator.valueInCents / denominator.valueInCents).toFixed(1);
};

const ApplicationGroupIncomeToRentCard = forwardRef<
  ElementRef<typeof Card>,
  ApplicationGroupIncomeToRentCardProps
>(function IncomeToRentCard(props, ref) {
  const { applications, header, listingId } = props;

  /**
   * this whole thing is just to get the current rent. oof
   */
  const { data: listing, isInitialLoading: loadingListing } = useQuery(
    ["LeasingAPI.getListingDetailsByListingId", listingId] as const,
    ({ queryKey: [, listingId] }) => {
      if (P.isNotNullable(listingId)) {
        return LeasingAPI.getListingDetailsByListingId({ listingId });
      }
    },
    { enabled: P.isNotNullable(listingId) },
  );

  const rent = O.getOrNull(Money$.parse(listing?.advertisedRent));

  const totalThreeMonthIncome = applications?.reduce((acc, application) => {
    return Money$.add(acc, Money$.of(application.validatedThreeMonthIncome));
  }, Money$.zero());
  const headingId = useId();
  return (
    <Card labelledBy={headingId} ref={ref}>
      <Stack>
        <Group>
          <span id={headingId}>{header}</span>
        </Group>
        <Grid spacingY={Spacing.none}>
          <Tuple
            label="Rent"
            value={
              <Skeleton visible={loadingListing}>
                {rent?.toFormatted() ?? "--"}
              </Skeleton>
            }
          />
          <Tuple
            label="Validated Monthly Income"
            value={totalThreeMonthIncome?.divideBy(3).toFormatted()}
          />
          <Tuple
            label="Income To Rent Ratio"
            value={
              <Skeleton visible={loadingListing}>
                {ratio(totalThreeMonthIncome?.divideBy(3), rent)}
              </Skeleton>
            }
          />
        </Grid>
      </Stack>
    </Card>
  );
});

export { ApplicationGroupIncomeToRentCard };

export type { ApplicationGroupIncomeToRentCardProps };
