/**
 * HTTP Method: POST
 * Pathname: /systemReports/Working Trial Balance V3/run
 * @function runWorkingTrialBalanceV3Report
 * @memberof ReportsAPI
 * @param {ReportsAPIRunWorkingTrialBalanceV3ReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<WorkingTrialBalanceV3ReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { WorkingTrialBalanceV3ReportResponse } from "@ender/shared/generated/ender.api.reports.response";
import type { GLTxTxType } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIRunWorkingTrialBalanceV3ReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunWorkingTrialBalanceV3ReportBodyParams = {
  asOfDate: SerializesInto<LocalDate>;
  consolidated?: boolean | undefined;
  firmIds: EnderId[];
  fundIds: EnderId[];
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  periodFilter?: PeriodFilter | undefined;
  propertyIds: EnderId[];
  txType: GLTxTxType;
};

type ReportsAPIRunWorkingTrialBalanceV3ReportPayload =
  ReportsAPIRunWorkingTrialBalanceV3ReportSearchParams &
    ReportsAPIRunWorkingTrialBalanceV3ReportBodyParams;

function runWorkingTrialBalanceV3ReportUnsafeEffect(
  payload: ReportsAPIRunWorkingTrialBalanceV3ReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, WorkingTrialBalanceV3ReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<WorkingTrialBalanceV3ReportResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/Working Trial Balance V3/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runWorkingTrialBalanceV3Report(
  payload: ReportsAPIRunWorkingTrialBalanceV3ReportPayload,
  options?: { signal?: AbortSignal },
): Promise<WorkingTrialBalanceV3ReportResponse> {
  return F.pipe(
    payload,
    runWorkingTrialBalanceV3ReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runWorkingTrialBalanceV3Report };
export type {
  ReportsAPIRunWorkingTrialBalanceV3ReportBodyParams,
  ReportsAPIRunWorkingTrialBalanceV3ReportPayload,
  ReportsAPIRunWorkingTrialBalanceV3ReportSearchParams,
};
