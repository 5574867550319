/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}/zones/{zoneId}/notApplicable
 * @function markZoneNotApplicable
 * @memberof InspectionAPI
 * @param {InspectionAPIMarkZoneNotApplicablePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionZoneResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionZoneResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import type { InspectionZoneZoneNotApplicableReason } from "@ender/shared/generated/com.ender.construction.model.inspection";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIMarkZoneNotApplicablePathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
  zoneId: EnderId;
};

type InspectionAPIMarkZoneNotApplicableSearchParams = {
  token?: string | undefined;
};

type InspectionAPIMarkZoneNotApplicableBodyParams = {
  notApplicableReason?: InspectionZoneZoneNotApplicableReason | undefined;
};

type InspectionAPIMarkZoneNotApplicablePayload =
  InspectionAPIMarkZoneNotApplicablePathParams &
    InspectionAPIMarkZoneNotApplicableSearchParams &
    InspectionAPIMarkZoneNotApplicableBodyParams;

function markZoneNotApplicableUnsafeEffect(
  payload: InspectionAPIMarkZoneNotApplicablePayload,
) {
  const { inspectionId, areaId, zoneId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionZoneResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionZoneResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}/zones/${zoneId}/notApplicable`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function markZoneNotApplicable(
  payload: InspectionAPIMarkZoneNotApplicablePayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionZoneResponse> {
  return F.pipe(
    payload,
    markZoneNotApplicableUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { markZoneNotApplicable };
export type {
  InspectionAPIMarkZoneNotApplicableBodyParams,
  InspectionAPIMarkZoneNotApplicablePathParams,
  InspectionAPIMarkZoneNotApplicablePayload,
  InspectionAPIMarkZoneNotApplicableSearchParams,
};
