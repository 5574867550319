import { Array as A, Predicate as P } from "effect";
import { useState } from "react";

import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import type { PipelineStatus } from "../../../types";
import { PipelineStatusEnum } from "../../../types";
import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

type FilterValues = {
  status: PipelineStatus;
  subStatus?: string;
};

type StatusFilterProps = Omit<FilterComponentProps, "factor"> & {
  factor?: WidgetFactor;
};

function getStatusFilter(
  status: PipelineStatus | undefined | null,
  factor: WidgetFactor | undefined,
): WidgetFilter[] | undefined {
  if (P.isNullable(factor)) {
    return undefined;
  }

  let values: FilterValues[];
  switch (status) {
    case PipelineStatusEnum.LEAD:
      values = [{ status: PipelineStatusEnum.LEAD }];
      break;
    case PipelineStatusEnum.OFFER:
      values = [
        { status: PipelineStatusEnum.OFFER, subStatus: "Need to Underwrite" },
      ];
      break;
    default:
      values = [
        { status: PipelineStatusEnum.LEAD },
        { status: PipelineStatusEnum.OFFER, subStatus: "Need to Underwrite" },
      ];
      break;
  }

  return [
    {
      factor,
      operator: DynamicTableOperatorsEnum.IN,
      values,
    },
  ];
}

function getDefaultValue(widget: Widget): PipelineStatus | null {
  const initialStatus =
    widget.filters.find(({ factor }) => factor.name === Factor.STATUS)
      ?.values ?? [];
  if (
    A.isEmptyArray(initialStatus) ||
    A.isNonEmptyArray(initialStatus?.slice(1))
  ) {
    return null;
  }

  const firstValue = initialStatus[0];
  if (
    typeof firstValue === "object" &&
    Object.prototype.hasOwnProperty.call(firstValue, "status")
  ) {
    return firstValue.status;
  }

  return null;
}

function StatusFilter({ factor, updateFilters, widget }: StatusFilterProps) {
  const [status, setStatus] = useState<PipelineStatus | null>(
    getDefaultValue(widget),
  );

  function handleChange(newStatusId: PipelineStatus | null) {
    setStatus(newStatusId);

    const filters = getStatusFilter(newStatusId, factor);

    updateFilters(Factor.STATUS, filters);
  }

  return (
    <Select
      label="Status"
      data={[
        { label: "Lead", value: PipelineStatusEnum.LEAD },
        { label: "Offer", value: PipelineStatusEnum.OFFER },
      ]}
      searchable
      value={status}
      clearable
      placeholder="Select Status"
      onChange={handleChange}
    />
  );
}

export { StatusFilter };
export type { PipelineStatus };
