import type { EnderId, Instant, LocalDate } from "@ender/shared/core";
import {
  randomEnderId,
  randomInstant,
  randomLocalDate,
} from "@ender/shared/core";

import type { AccountingPeriodAccountingModule } from "./accounting-period-accounting-module";
import { randomAccountingPeriodAccountingModule } from "./accounting-period-accounting-module";
import type { AccountingPeriodPeriodStatus } from "./accounting-period-period-status";
import { randomAccountingPeriodPeriodStatus } from "./accounting-period-period-status";

type AccountingPeriod = {
  authorId?: EnderId | undefined;
  endDate: LocalDate;
  id: EnderId;
  module: AccountingPeriodAccountingModule;
  pmId: EnderId;
  startDate: LocalDate;
  status: AccountingPeriodPeriodStatus;
  timestamp: Instant;
  versionEnd?: Instant | undefined;
  versionStart: Instant;
};

function randomAccountingPeriod(
  params: Partial<AccountingPeriod> = {},
): AccountingPeriod {
  const {
    authorId = undefined,
    endDate = randomLocalDate().toJSON(),
    id = randomEnderId(),
    module = randomAccountingPeriodAccountingModule(),
    pmId = randomEnderId(),
    startDate = randomLocalDate().toJSON(),
    status = randomAccountingPeriodPeriodStatus(),
    timestamp = randomInstant().toJSON(),
    versionEnd = undefined,
    versionStart = randomInstant().toJSON(),
  } = params;

  return {
    authorId,
    endDate,
    id,
    module,
    pmId,
    startDate,
    status,
    timestamp,
    versionEnd,
    versionStart,
  };
}

export { randomAccountingPeriod };
export type { AccountingPeriod };
