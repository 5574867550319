/**
 * HTTP Method: POST
 * Pathname: /markets/{marketId}/addState
 * @function addStateToMarket
 * @memberof MarketsAPI
 * @param {MarketsAPIAddStateToMarketPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<StateMarketMapping>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { StateMarketMapping } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MarketsAPIAddStateToMarketPathParams = {
  marketId: EnderId;
};

type MarketsAPIAddStateToMarketSearchParams = {
  token?: string | undefined;
};

type MarketsAPIAddStateToMarketBodyParams = {
  state: string;
};

type MarketsAPIAddStateToMarketPayload = MarketsAPIAddStateToMarketPathParams &
  MarketsAPIAddStateToMarketSearchParams &
  MarketsAPIAddStateToMarketBodyParams;

function addStateToMarketUnsafeEffect(
  payload: MarketsAPIAddStateToMarketPayload,
) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, StateMarketMapping>({
        body,
        decode: unsafeDecodeJsonResponse<StateMarketMapping>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/markets/${marketId}/addState`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addStateToMarket(
  payload: MarketsAPIAddStateToMarketPayload,
  options?: { signal?: AbortSignal },
): Promise<StateMarketMapping> {
  return F.pipe(
    payload,
    addStateToMarketUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addStateToMarket };
export type {
  MarketsAPIAddStateToMarketBodyParams,
  MarketsAPIAddStateToMarketPathParams,
  MarketsAPIAddStateToMarketPayload,
  MarketsAPIAddStateToMarketSearchParams,
};
