import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ProspectSortByValues = [
  "FOLLOW_UP",
  "PROPERTY_NAME",
  "UNIT_NAME",
  "MARKET_NAME",
  "PROSPECT_MOVE_IN",
  "LEAD_ID",
  "LAST_ACTIVITY_TIME",
  "LAST_MESSAGE_TIME",
] as const;

const ProspectSortByEffectSchema = Schema.Literal(...ProspectSortByValues);

type ProspectSortBy = Schema.Schema.Type<typeof ProspectSortByEffectSchema>;

const ProspectSortByEnum = castEnum(ProspectSortByEffectSchema);

function randomProspectSortBy(): ProspectSortBy {
  return rand(ProspectSortByValues);
}

export {
  ProspectSortByEffectSchema,
  ProspectSortByEnum,
  ProspectSortByValues,
  randomProspectSortBy,
};
export type { ProspectSortBy };
