// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { MultiSelectProps, SelectItem } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { MultiSelect } from "@mantine/core";
import { Predicate as P } from "effect";
import type { ForwardedRef } from "react";
import { forwardRef, useCallback, useMemo } from "react";

import type { LabelValue } from "@ender/shared/types/label-value";

type EnderMultiSelectProps = Omit<
  MultiSelectProps,
  "data" | "value" | "onChange"
> & {
  data: LabelValue[];
  value: LabelValue[];
  onChange: (value: LabelValue[], selectedItem?: LabelValue) => void;
};

function EnderMultiSelect(
  props: EnderMultiSelectProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { data, value, onChange, ...rest } = props;

  const dataMap = useMemo(() => {
    const map = new Map<string | number, LabelValue>();
    data.forEach((item) => {
      map.set(item.value, item);
    });
    return map;
  }, [data]);

  const _onChange = useCallback(
    (value: string[]) => {
      const selectedItems = value.map((v) => dataMap.get(v));
      onChange(selectedItems.filter(P.isNotNullable));
    },
    [dataMap, onChange],
  );

  const values = useMemo(() => value.map((v) => String(v.value)), [value]);
  const transformedData = useMemo(
    () => data.map((item) => ({ label: item.label, value: item.value })),
    [data],
  ) as (string | SelectItem)[];

  return (
    <MultiSelect
      data={transformedData}
      value={values}
      onChange={_onChange}
      {...rest}
      ref={ref}
    />
  );
}

const ForwardedMultiSelect = forwardRef<
  HTMLInputElement,
  EnderMultiSelectProps
>(EnderMultiSelect);

export { ForwardedMultiSelect as EnderMultiSelect };
