// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { DrawerProps } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Drawer } from "@mantine/core";
import { Function as F } from "effect";





type EnderDrawerProps = DrawerProps;

/**
 * @deprecated use @ender/shared/ds/drawer
 */
function EnderDrawer(props: EnderDrawerProps) {
  const { onClose = F.constVoid, ...drawerProps } = props;

  return (
    <Drawer
      onClose={onClose}
      closeButtonLabel="Close Drawer"
      withinPortal={false}
      {...drawerProps}
    />
  );
}

export { EnderDrawer };
export type { EnderDrawerProps };
