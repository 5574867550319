import * as A from "effect/Array";
import { useState } from "react";

import type { CustomField } from "@ender/shared/api/factors";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Group } from "@ender/shared/ds/group";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { RightRail } from "@ender/shared/ui/right-rail";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { AddCustomFieldForm } from "./add-custom-field-form";
import { CustomFieldsActionsEnum } from "./custom-fields-actions-enum";
import { EditCustomFieldsForm } from "./edit-custom-fields-form";

type CustomFieldMenuProps = {
  customFields: CustomField[];
  modelId: EnderId;
  modelType: ModelType;
  refetchCustomFields: () => void;
};

function CustomFieldsMenu({
  customFields,
  modelId,
  modelType,
  refetchCustomFields,
}: CustomFieldMenuProps) {
  const [menuAction, setMenuAction] = useState(CustomFieldsActionsEnum.NONE);

  function setMenuActionToNone() {
    setMenuAction(CustomFieldsActionsEnum.NONE);
  }

  function onEditFieldsClick() {
    setMenuAction(CustomFieldsActionsEnum.EDIT);
  }

  function onAddFieldClick() {
    setMenuAction(CustomFieldsActionsEnum.ADD);
  }

  function onFieldAdded() {
    refetchCustomFields();
    setMenuActionToNone();
    showSuccessNotification({ message: "Field added." });
  }

  function onFieldsEdited() {
    refetchCustomFields();
    setMenuActionToNone();
    showSuccessNotification({ message: "Fields updated." });
  }

  return (
    <Group>
      <RightRail
        opened={menuAction !== CustomFieldsActionsEnum.NONE}
        onClose={setMenuActionToNone}
        title={
          menuAction === CustomFieldsActionsEnum.EDIT
            ? "Edit Custom Fields"
            : "Add Field"
        }>
        {menuAction === CustomFieldsActionsEnum.EDIT && (
          <EditCustomFieldsForm
            modelType={modelType}
            modelId={modelId}
            customFields={customFields}
            onSuccess={onFieldsEdited}
          />
        )}
        {menuAction === CustomFieldsActionsEnum.ADD && (
          <AddCustomFieldForm
            excludedFields={customFields}
            modelId={modelId}
            modelType={modelType}
            onSuccess={onFieldAdded}
          />
        )}
      </RightRail>
      <Button
        variant={ButtonVariant.outlined}
        aria-label="Edit Custom Field"
        disabled={A.isEmptyArray(customFields)}
        disabledTooltip="There are no custom fields to edit."
        onClick={onEditFieldsClick}>
        Edit
      </Button>
      <Button
        variant={ButtonVariant.outlined}
        aria-label="Add Custom Field"
        onClick={onAddFieldClick}>
        Add
      </Button>
    </Group>
  );
}

export { CustomFieldsMenu };
