import { IconCheck, IconX } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Predicate as P } from "effect";
import type { ElementRef, PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

import { Align, Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";

type Indicator = "check" | "x";

type ApplicationProgressIconProps = {
  color?: "red" | "green" | "yellow" | "gray";
  indicator?: Indicator;
  disabled?: boolean;
};

const IndicatorMap: Record<Indicator, ReactNode> = {
  check: <IconCheck size={10} strokeWidth={4} />,
  x: <IconX size={10} strokeWidth={4} />,
};

const ApplicationProgressIcon = forwardRef<
  ElementRef<typeof Stack>,
  PropsWithChildren<ApplicationProgressIconProps>
>(function ApplicationProgressIcon(props, ref) {
  const { children, disabled = false, indicator, color = "gray" } = props;
  return (
    <span
      aria-disabled={disabled}
      className={clsx("aria-disabled:text-slate-200", {
        "text-slate-500": color === "gray",
        "text-red-500": color === "red",
        "text-green-500": color === "green",
        "text-yellow-500": color === "yellow",
      })}>
      <Stack align={Align.center} spacing={Spacing.none} ref={ref}>
        {P.isNotNullable(indicator) && IndicatorMap[indicator]}
        {children}
      </Stack>
    </span>
  );
});

export { ApplicationProgressIcon };

export type { ApplicationProgressIconProps };
