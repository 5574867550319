import type { ReactElement } from "react";

import type { User } from "@ender/shared/generated/ender.model.core.user";
import { Soc2Hidden } from "@ender/shared/ui/soc2-hidden";

function renderPrivateContact(
  user: Pick<User, "firstName" | "lastName">,
  includeComma?: boolean,
): ReactElement {
  return (
    <span key={user.firstName + user.lastName}>
      {user.firstName} <Soc2Hidden inline>{user.lastName}</Soc2Hidden>
      {includeComma && ", "}
    </span>
  );
}

function renderPrivateContacts(
  contacts: Pick<User, "firstName" | "lastName">[],
): ReactElement[] {
  return contacts.map((contact, i) =>
    renderPrivateContact(contact, i < contacts.length - 1),
  );
}

export { renderPrivateContact, renderPrivateContacts };
