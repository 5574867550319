/**
 * HTTP Method: GET
 * Pathname: /accounting/txs/{txId}/ledger-event
 * @function getLinkedEvent
 * @memberof AccountingAPI
 * @param {AccountingAPIGetLinkedEventPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingAPIGetLinkedEventResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AccountingAPIGetLinkedEventResponse } from "../accounting-api-get-linked-event-response";

type AccountingAPIGetLinkedEventPathParams = {
  txId: EnderId;
};

type AccountingAPIGetLinkedEventSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetLinkedEventPayload =
  AccountingAPIGetLinkedEventPathParams &
    AccountingAPIGetLinkedEventSearchParams;

function getLinkedEventUnsafeEffect(
  payload: AccountingAPIGetLinkedEventPayload,
) {
  const { txId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingAPIGetLinkedEventResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AccountingAPIGetLinkedEventResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/accounting/txs/${txId}/ledger-event`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLinkedEvent(
  payload: AccountingAPIGetLinkedEventPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingAPIGetLinkedEventResponse> {
  return F.pipe(
    payload,
    getLinkedEventUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLinkedEvent };
export type {
  AccountingAPIGetLinkedEventPathParams,
  AccountingAPIGetLinkedEventPayload,
  AccountingAPIGetLinkedEventSearchParams,
};
