/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/paymentPlans
 * @function getPaymentPlans
 * @memberof LeasingAPI
 * @param {LeasingAPIGetPaymentPlansPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PaymentPlanResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PaymentPlanResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetPaymentPlansPathParams = {
  leaseId: EnderId;
};

type LeasingAPIGetPaymentPlansSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetPaymentPlansPayload = LeasingAPIGetPaymentPlansPathParams &
  LeasingAPIGetPaymentPlansSearchParams;

function getPaymentPlansUnsafeEffect(
  payload: LeasingAPIGetPaymentPlansPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PaymentPlanResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<PaymentPlanResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/paymentPlans`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPaymentPlans(
  payload: LeasingAPIGetPaymentPlansPayload,
  options?: { signal?: AbortSignal },
): Promise<PaymentPlanResponse[]> {
  return F.pipe(
    payload,
    getPaymentPlansUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPaymentPlans };
export type {
  LeasingAPIGetPaymentPlansPathParams,
  LeasingAPIGetPaymentPlansPayload,
  LeasingAPIGetPaymentPlansSearchParams,
};
