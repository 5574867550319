import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const FormInputFormInputTypeValues = ["TEXT", "SELECT"] as const;

const FormInputFormInputTypeEffectSchema = Schema.Literal(
  ...FormInputFormInputTypeValues,
);

type FormInputFormInputType = Schema.Schema.Type<
  typeof FormInputFormInputTypeEffectSchema
>;

const FormInputFormInputTypeEnum = castEnum(FormInputFormInputTypeEffectSchema);

function randomFormInputFormInputType(): FormInputFormInputType {
  return rand(FormInputFormInputTypeValues);
}

export {
  FormInputFormInputTypeEffectSchema,
  FormInputFormInputTypeEnum,
  FormInputFormInputTypeValues,
  randomFormInputFormInputType,
};
export type { FormInputFormInputType };
