import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import { useCallback } from "react";
import { useWatch } from "react-hook-form";

import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";

type LeaseDatesModalProps = {
  lease: LeaseSerializerLeaseResponse;
  onSuccess?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LeaseDatesFormSchema = Schema.Struct({
  inclusiveEndDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  markedMonthToMonth: Schema.Boolean,
  startDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
});
type LeaseDatesFormInput = Schema.Schema.Encoded<typeof LeaseDatesFormSchema>;
type LeaseDatesFormOutput = Schema.Schema.Type<typeof LeaseDatesFormSchema>;

/**
 * @name LeaseDatesModal
 * @description Allows for the modification of Lease Dates as well as managing the Month to Month setting
 */

function LeaseDatesModal({
  lease,
  onSuccess = F.constVoid,
}: LeaseDatesModalProps) {
  const form = useForm<LeaseDatesFormInput, unknown, LeaseDatesFormOutput>({
    defaultValues: {
      inclusiveEndDate: LocalDate$.parse(lease.inclusiveEndDate),
      markedMonthToMonth: P.isNullable(lease.endDate),
      startDate: LocalDate$.parse(lease.startDate),
    },
    mode: "onSubmit",
  });

  const confirmation = useConfirmationContext();

  const { mutateAsync: updateLease, isLoading: isUpdateLoading } = useMutation({
    mutationFn: LeasingAPI.updateLease,
    mutationKey: ["LeasingAPI.updateLease"] as const,
  });

  const {
    mutateAsync: markMonthToMonth,
    isLoading: isMarkMonthToMonthLoading,
  } = useMutation({
    mutationFn: LeasingAPI.markMonthToMonth,
    mutationKey: ["LeasingAPI.markMonthToMonth"] as const,
  });

  const handleLeaseUpdate = useCallback(
    async (values: LeaseDatesFormOutput) => {
      const { startDate, inclusiveEndDate, markedMonthToMonth } = values;

      await updateLease({
        startDate: O.getOrThrow(startDate).toJSON(),
        inclusiveEndDate: markedMonthToMonth
          ? UNDEFINED
          : O.getOrUndefined(inclusiveEndDate)?.toJSON(),
        leaseId: lease.id,
      });

      if (markedMonthToMonth) {
        await markMonthToMonth({
          leaseId: lease.id,
          markedMonthToMonth,
        });
      }

      onSuccess();
    },
    [lease.id, markMonthToMonth, onSuccess, updateLease],
  );

  const handleSubmit = useCallback(
    async (values: LeaseDatesFormOutput) => {
      const {
        startDate: newStartDate,
        inclusiveEndDate,
        markedMonthToMonth,
      } = values;
      try {
        // newStartDate is defined && is before lease.startDate
        const startDateMovedBack = pipe(
          lease.startDate,
          LocalDate$.parse,
          O.map((oldStartDate) =>
            O.exists(newStartDate, (newStartDate) =>
              newStartDate.isBefore(oldStartDate),
            ),
          ),
          O.getOrElse(() => false),
        );

        if (startDateMovedBack) {
          await confirmation({
            content:
              "Charges for the period from the new start date to the previous start date must be manually added",
            title: "Change Lease Dates?",
          });
        }

        if (
          !lease.markedMonthToMonth &&
          (markedMonthToMonth || O.isNone(inclusiveEndDate))
        ) {
          await confirmation({
            confirmButtonLabel: "Mark lease as month-to-month",
            content:
              "By entering no lease end date, you are marking this lease as month-to-month. Please enter an end date or continue with the lease as month-to-month.",
            title: "Warning!",
          });
        }

        handleLeaseUpdate(values);
      } catch {
        //
      }
    },
    [
      confirmation,
      handleLeaseUpdate,
      lease.markedMonthToMonth,
      lease.startDate,
    ],
  );

  const monthToMonthValue = useWatch({
    control: form.control,
    name: "markedMonthToMonth",
  });

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormDateInput
          form={form}
          name="startDate"
          description="Editing the start date of a lease does not retroactively create charge schedules in the past. Charges must be added manually for the corresponding period in the tenant ledger."
          label="Start Date"
        />
        <FormDateInput
          form={form}
          name="inclusiveEndDate"
          label="End Date (optional)"
          disabled={monthToMonthValue}
          description={
            monthToMonthValue
              ? "Month to month leases have no set end date"
              : ""
          }
        />
        <FormCheckbox
          form={form}
          name="markedMonthToMonth"
          label="Set as month-to-month"
        />
        <Group justify={Justify.end}>
          <Button
            type="submit"
            loading={isUpdateLoading || isMarkMonthToMonthLoading}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { LeaseDatesModal };
