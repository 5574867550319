import type { ColumnFiltersState, SortingState } from "@tanstack/react-table";
import { Array as A, Predicate as P } from "effect";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { UNDEFINED } from "@ender/shared/constants/general";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";

import type { ColumnDef, EnderTableState } from "../table.types";
import { getColumnIdentifier } from "../utils/table-utils";

function safeParse<T>(value: string | null | undefined): T | undefined {
  try {
    return P.isNotNullable(value) ? JSON.parse(value) : UNDEFINED;
  } catch {
    return UNDEFINED;
  }
}

type QueryParamsTableState = Pick<
  EnderTableState,
  "sorting" | "columnFilters" | "globalFilter"
>;

type GetTableStateFromQueryParamsParams<RowData> = {
  id?: string;
  columns: ColumnDef<RowData>[];
  queryParams: URLSearchParams;
};

function getTableStateFromQueryParams<RowData>(
  params: GetTableStateFromQueryParamsParams<RowData>,
): Partial<QueryParamsTableState> {
  const { id = "", columns, queryParams } = params;
  const filterColumnIds = columns
    .filter((column) => column.enableColumnFilter !== false)
    .map(getColumnIdentifier)
    .filter((val) => !P.isNullable(val));
  const sortColumnIds = columns
    .filter((column) => column.enableSorting !== false)
    .map(getColumnIdentifier)
    .filter((val) => !P.isNullable(val));

  const sortString = queryParams.get(`${id}sort`);
  const filterString = queryParams.get(`${id}filter`);
  const searchString = queryParams.get(`${id}search`);

  let sorting = safeParse<SortingState>(sortString);
  let columnFilters = safeParse<ColumnFiltersState>(filterString);
  const globalFilter = safeParse<string>(searchString);

  sorting = sorting?.filter(({ id }) => sortColumnIds.includes(id));
  columnFilters = columnFilters?.filter(({ id }) =>
    filterColumnIds.includes(id),
  );

  return {
    columnFilters,
    globalFilter,
    sorting,
  };
}

type UseSyncQueryParamsParams<RowData> = {
  id?: string;
  columns: ColumnDef<RowData>[];
  enableQueryParams: boolean;
  setSortingState?: Dispatch<SetStateAction<SortingState>>;
  setColumnFiltersState?: Dispatch<SetStateAction<ColumnFiltersState>>;
  setGlobalFilterState?: Dispatch<SetStateAction<string | undefined>>;
};

function useSyncQueryParams<RowData>(
  params: UseSyncQueryParamsParams<RowData>,
): {
  onSortingChange?: Dispatch<SetStateAction<SortingState>>;
  onColumnFiltersChange?: Dispatch<SetStateAction<ColumnFiltersState>>;
  onGlobalFilterChange?: Dispatch<SetStateAction<string | undefined>>;
} {
  const {
    columns,
    enableQueryParams,
    id = "",
    setColumnFiltersState,
    setGlobalFilterState,
    setSortingState,
  } = params;

  const { search } = useLocation();
  const history = useHistory();
  const historyRef = useRefLatest(history);
  const queryParamsRef = useRefLatest(new URLSearchParams(search));

  // lazy useEffect
  useEffect(() => {
    if (!enableQueryParams || !P.isNotNullable(queryParamsRef.current)) {
      return;
    }

    const { sorting, columnFilters, globalFilter } =
      getTableStateFromQueryParams({
        id,
        columns,
        queryParams: queryParamsRef.current,
      });

    sorting && setSortingState && setSortingState(sorting);
    columnFilters &&
      setColumnFiltersState &&
      setColumnFiltersState(columnFilters);
    globalFilter && setGlobalFilterState && setGlobalFilterState(globalFilter);
  }, [
    id,
    columns,
    enableQueryParams,
    queryParamsRef,
    setColumnFiltersState,
    setGlobalFilterState,
    setSortingState,
  ]);

  const setQueryParam = useCallback(
    (key: string, value: unknown) => {
      const queryParams = queryParamsRef.current;
      const history = historyRef.current;
      if (!queryParams || !history) {
        return;
      }

      if (P.isNotNullable(value)) {
        queryParams.set(key, JSON.stringify(value));

        if (Array.isArray(value) && A.isEmptyArray(value)) {
          queryParams.delete(key);
        }
      } else {
        queryParams.delete(key);
      }

      history.replace({ search: queryParams.toString() });
    },
    [queryParamsRef, historyRef],
  );

  const onSortingChange = useCallback(
    (updater: SetStateAction<SortingState>) => {
      setSortingState?.((oldState) => {
        const nextState = P.isFunction(updater) ? updater(oldState) : updater;
        enableQueryParams && setQueryParam(`${id}sort`, nextState);
        return nextState;
      });
    },
    [id, enableQueryParams, setSortingState, setQueryParam],
  );

  const onColumnFiltersChange = useCallback(
    (updater: SetStateAction<ColumnFiltersState>) => {
      setColumnFiltersState?.((oldState) => {
        const nextState = P.isFunction(updater) ? updater(oldState) : updater;
        enableQueryParams && setQueryParam(`${id}filter`, nextState);
        return nextState;
      });
    },
    [id, enableQueryParams, setColumnFiltersState, setQueryParam],
  );

  const onGlobalFilterChange = useCallback(
    (updater: SetStateAction<string | undefined>) => {
      setGlobalFilterState?.((oldState) => {
        const nextState = P.isFunction(updater) ? updater(oldState) : updater;
        enableQueryParams && setQueryParam(`${id}search`, nextState);
        return nextState;
      });
    },
    [id, enableQueryParams, setGlobalFilterState, setQueryParam],
  );

  return {
    onColumnFiltersChange: setColumnFiltersState && onColumnFiltersChange,
    onGlobalFilterChange: setGlobalFilterState && onGlobalFilterChange,
    onSortingChange: setSortingState && onSortingChange,
  };
}

export { useSyncQueryParams };
