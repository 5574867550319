import { z } from "zod";

import { EnderIdSchema, InstantSchema } from "@ender/shared/core";
import {
  UserInvestorUserOnboardingStatusValues,
  UserUserTimezoneValues,
} from "@ender/shared/generated/ender.model.core.user";
import { LocalDateZodSchema } from "@ender/shared/utils/zod";

const UserSchema = z.object({
  birthday: LocalDateZodSchema.optional(),
  email: z.string(),
  emailVerifiedTimestamp: InstantSchema.optional(),
  esusuEnrolled: z.boolean().optional(),
  firmId: EnderIdSchema.optional(),
  firstName: z.string(),
  hasPassword: z.boolean(),
  id: EnderIdSchema,
  investorOnboardingStatus: z
    .enum(UserInvestorUserOnboardingStatusValues)
    .optional(),
  isActiveMilitary: z.boolean().optional(),
  isBroker: z.boolean(),
  isEnder: z.boolean(),
  isInvestor: z.boolean(),
  isPM: z.boolean(),
  isTenant: z.boolean(),
  isVendor: z.boolean(),
  lastName: z.string(),
  lastOnline: z.string().optional(),
  name: z.string(),
  onboarded: z.string().optional(),
  onboardingTimestamp: InstantSchema.optional(),
  phone: z.string(),
  phoneIsLandline: z.boolean().optional(),
  pmId: EnderIdSchema.optional(),
  ssn: z.string().optional(),
  timezone: z.enum(UserUserTimezoneValues),
});

export { UserSchema };
