import { useCallback, useEffect, useState } from "react";

import { TextInput } from "@ender/shared/ds/text-input";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import { Size } from "@ender/shared/utils/theming";

type AmountFilterProps = {
  onChange: (value: string) => void;
  value: string;
};

/**
 * 2024-05-07 Geoff: This was based heavily on invoices-table-page/invoices-filters/invoices-filters.tsx components
 * Which should probably be refactored into shared components and DRY'd w shared base components
 */
function AmountFilter({ onChange, value = "" }: AmountFilterProps) {
  const [amount, setAmount] = useState(value);

  // Update the local state when the external `value` prop changes
  useEffect(() => {
    setAmount(value);
  }, [value]);

  const onDebounceChange = useDebounce((debouncedAmount: string) => {
    onChange(debouncedAmount);
  }, 750);

  const onAmountChange = useCallback(
    (val: string) => {
      setAmount(val);
      onDebounceChange(val);
    },
    [onDebounceChange],
  );

  return (
    // This should probably be CurrencyInput
    <TextInput
      label="Amount"
      name="amount_filter"
      onChange={onAmountChange}
      placeholder="Search Amount"
      value={amount}
      size={Size.sm}
    />
  );
}

export { AmountFilter };
