/**
 * HTTP Method: POST
 * Pathname: /systemReports/Payables Report/run
 * @function runPayablesReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunPayablesReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PayablesReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type {
  CounterpartyRequest,
  ReportFilterRequest,
} from "@ender/shared/generated/ender.api.reports.request";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import type { InvoicePayableType } from "@ender/shared/generated/ender.model.payments.invoice";
import type { PayablesReportResponse } from "@ender/shared/generated/ender.service.reports.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIRunPayablesReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunPayablesReportBodyParams = {
  amountMatch?: string | undefined;
  categoryFilterIds: EnderId[];
  counterparties: CounterpartyRequest[];
  endDate?: SerializesInto<LocalDate> | undefined;
  externalInvoiceId?: string | undefined;
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  inclusiveEndPaidDate?: SerializesInto<LocalDate> | undefined;
  limit?: number | undefined;
  marketIds: EnderId[];
  payableTypes: InvoicePayableType[];
  paymentMethodType?: MoneyTransferTransferType | undefined;
  periods?: PeriodFilter | undefined;
  propertyIds: EnderId[];
  showApprovedInvoices?: boolean | undefined;
  showPaidInvoices?: boolean | undefined;
  showRejectedInvoices?: boolean | undefined;
  startAfterInvoiceId?: EnderId | undefined;
  startDate?: SerializesInto<LocalDate> | undefined;
  startPaidDate?: SerializesInto<LocalDate> | undefined;
};

type ReportsAPIRunPayablesReportPayload =
  ReportsAPIRunPayablesReportSearchParams &
    ReportsAPIRunPayablesReportBodyParams;

function runPayablesReportUnsafeEffect(
  payload: ReportsAPIRunPayablesReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PayablesReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<PayablesReportResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/Payables Report/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runPayablesReport(
  payload: ReportsAPIRunPayablesReportPayload,
  options?: { signal?: AbortSignal },
): Promise<PayablesReportResponse> {
  return F.pipe(
    payload,
    runPayablesReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runPayablesReport };
export type {
  ReportsAPIRunPayablesReportBodyParams,
  ReportsAPIRunPayablesReportPayload,
  ReportsAPIRunPayablesReportSearchParams,
};
