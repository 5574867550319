import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useParams } from "react-router-dom";

import type { EnderId } from "@ender/shared/core";
import { H2 } from "@ender/shared/ds/heading";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import { FactorFieldInput } from "@ender/shared/ui/factor-field-input";

import styles from "./underwriting-custom-fields.module.css";

type FactorOutputType =
  | "STRING"
  | "MONEY"
  | "NUMBER"
  | "DATE"
  | "BOOLEAN"
  | "ENUM";

type SafeCustomFactor = {
  factorId: EnderId;
  outputType: FactorOutputType;
  value: string;
  name: string;
};
const modelType = ModelTypeEnum.DEAL;
const UNDERWRITING_GROUPING = "Underwriting";
const NUMBER_OF_COLUMNS = 2;

function UnderwritingCustomFields() {
  const { dealId: modelId } = useParams<{ dealId: EnderId }>();

  const {
    data: underwritingCustomFieldGroups,
    refetch: refetchUnderwritingCustomFieldGroups,
  } = useQuery<
    {
      factor: {
        id: EnderId;
        outputType: FactorOutputType;
        name: string;
        grouping: string;
      };
      value: string;
    }[],
    Error,
    Partial<SafeCustomFactor>[][]
  >({
    initialData: [],
    queryFn: ({ signal }) =>
      FactorsAPI.getCustomFields({ modelId, modelType }, { signal }),
    queryKey: ["FactorsAPI.getCustomFields", modelType, modelId] as const,
    select: (customFields) => {
      const underwritingCustomFields = customFields
        .filter(({ factor }) => factor.grouping === UNDERWRITING_GROUPING)
        .map((field) => ({
          factorId: field.factor.id,
          name: field.factor.name,
          outputType: field.factor.outputType,
          value: field.value,
        }));

      const itemsPerColumn = Math.ceil(
        underwritingCustomFields.length / NUMBER_OF_COLUMNS,
      );
      return Array.from(
        { length: Math.ceil(underwritingCustomFields.length / itemsPerColumn) },
        (_, i) =>
          underwritingCustomFields.slice(
            i * itemsPerColumn,
            (i + 1) * itemsPerColumn,
          ),
      ) as Partial<SafeCustomFactor>[][];
    },
  });

  const { mutateAsync: handleChange } = useMutation({
    mutationFn: async ({
      factorId,
      value,
    }: {
      factorId: EnderId;
      value: unknown;
    }) => {
      await FactorsAPI.setCustomField({
        factorId,
        modelId,
        modelType,
        value: value as string,
      });
      refetchUnderwritingCustomFieldGroups();
    },
    mutationKey: ["FactorsAPI.setCustomField", modelType, modelId] as const,
  });

  const groups = underwritingCustomFieldGroups ?? [];
  if (A.isEmptyArray(groups)) {
    return null;
  }

  return (
    <div>
      <div className="header">
        <H2>Underwriting Custom Fields</H2>
      </div>
      <div className={styles.underwritingCustomFields}>
        {groups.map((underwritingCustomFields, i) => (
          <div key={i} className={styles.factorFieldGroup}>
            {underwritingCustomFields.map((field) => (
              <div key={field.factorId} className={styles.factorField}>
                <label>{field.name}</label>
                {P.isNotNullable(field.factorId) &&
                  P.isNotNullable(field.outputType) && (
                    <FactorFieldInput
                      factorId={field.factorId}
                      outputType={field.outputType}
                      value={field.value}
                      label=""
                      onChange={(field) => handleChange(field)}
                    />
                  )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export { UnderwritingCustomFields };
