/**
 * HTTP Method: POST
 * Pathname: /middleLayer/batch-pay-invoices
 * @function getBatchPayInvoicesDetails
 * @memberof InvoicesMiddleLayerAPI
 * @param {InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchPayInvoicesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchPayInvoicesResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsSearchParams = {
  token?: string | undefined;
};

type InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsBodyParams = {
  invoiceIds: EnderId[];
};

type InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsPayload =
  InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsSearchParams &
    InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsBodyParams;

function getBatchPayInvoicesDetailsUnsafeEffect(
  payload: InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchPayInvoicesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchPayInvoicesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/batch-pay-invoices",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBatchPayInvoicesDetails(
  payload: InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<BatchPayInvoicesResponse> {
  return F.pipe(
    payload,
    getBatchPayInvoicesDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBatchPayInvoicesDetails };
export type {
  InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsBodyParams,
  InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsPayload,
  InvoicesMiddleLayerAPIGetBatchPayInvoicesDetailsSearchParams,
};
