/**
 * HTTP Method: POST
 * Pathname: /offers/{offerId}/status
 * @function setOfferStatus
 * @memberof BuyAPI
 * @param {BuyAPISetOfferStatusPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BuyOfferOfferStatus } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPISetOfferStatusPathParams = {
  offerId: EnderId;
};

type BuyAPISetOfferStatusSearchParams = {
  token?: string | undefined;
};

type BuyAPISetOfferStatusBodyParams = {
  status: BuyOfferOfferStatus;
};

type BuyAPISetOfferStatusPayload = BuyAPISetOfferStatusPathParams &
  BuyAPISetOfferStatusSearchParams &
  BuyAPISetOfferStatusBodyParams;

function setOfferStatusUnsafeEffect(payload: BuyAPISetOfferStatusPayload) {
  const { offerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/offers/${offerId}/status`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setOfferStatus(
  payload: BuyAPISetOfferStatusPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setOfferStatusUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setOfferStatus };
export type {
  BuyAPISetOfferStatusBodyParams,
  BuyAPISetOfferStatusPathParams,
  BuyAPISetOfferStatusPayload,
  BuyAPISetOfferStatusSearchParams,
};
