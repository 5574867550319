/**
 * Ender-only (really, dev-only) API for re-importing GLTx's for a property from the Appfolio CSV file format. See
 this example:
 
 https://drive.google.com/file/d/1qazFa0PmuicJvnkON4837L5qePodZGF5/view?usp=share_link
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/gltx-import
 * @function importHistoricalGLTxsForProperty
 * @memberof AccountingAPI
 * @param {AccountingAPIImportHistoricalGLTxsForPropertyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIImportHistoricalGLTxsForPropertyPathParams = {
  propertyId: EnderId;
};

type AccountingAPIImportHistoricalGLTxsForPropertySearchParams = {
  token?: string | undefined;
};

type AccountingAPIImportHistoricalGLTxsForPropertyBodyParams = {
  /**
   * If present, then Tx's will be filtered out which fall on or after this date.
   */
  exclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  fileUrl: string;
};

type AccountingAPIImportHistoricalGLTxsForPropertyPayload =
  AccountingAPIImportHistoricalGLTxsForPropertyPathParams &
    AccountingAPIImportHistoricalGLTxsForPropertySearchParams &
    AccountingAPIImportHistoricalGLTxsForPropertyBodyParams;

function importHistoricalGLTxsForPropertyUnsafeEffect(
  payload: AccountingAPIImportHistoricalGLTxsForPropertyPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/gltx-import`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function importHistoricalGLTxsForProperty(
  payload: AccountingAPIImportHistoricalGLTxsForPropertyPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    importHistoricalGLTxsForPropertyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { importHistoricalGLTxsForProperty };
export type {
  AccountingAPIImportHistoricalGLTxsForPropertyBodyParams,
  AccountingAPIImportHistoricalGLTxsForPropertyPathParams,
  AccountingAPIImportHistoricalGLTxsForPropertyPayload,
  AccountingAPIImportHistoricalGLTxsForPropertySearchParams,
};
