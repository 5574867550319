import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeaseMoveOutReasonValues = [
  "DISSATISFIED_WITH_AMENITIES",
  "DISSATISFIED_WITH_MAINTENANCE_RESPONSE_TIMES",
  "DISSATISFIED_WITH_NEIGHBORS_OR_NEIGHBORHOOD",
  "EVICTION",
  "MILITARY_TRANSFER",
  "NEED_SMALLER_OR_LARGER_HOME",
  "OTHER",
  "PREFER_DIFFERENT_LEASE_LENGTH_TERMS",
  "MOVING_TO_A_NEW_CITY",
  "RENT_INCREASE_IS_TOO_HIGH",
  "BUILDING_A_HOME",
] as const;

const LeaseMoveOutReasonEffectSchema = Schema.Literal(
  ...LeaseMoveOutReasonValues,
);

type LeaseMoveOutReason = Schema.Schema.Type<
  typeof LeaseMoveOutReasonEffectSchema
>;

const LeaseMoveOutReasonEnum = castEnum(LeaseMoveOutReasonEffectSchema);

function randomLeaseMoveOutReason(): LeaseMoveOutReason {
  return rand(LeaseMoveOutReasonValues);
}

export {
  LeaseMoveOutReasonEffectSchema,
  LeaseMoveOutReasonEnum,
  LeaseMoveOutReasonValues,
  randomLeaseMoveOutReason,
};
export type { LeaseMoveOutReason };
