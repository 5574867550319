import { Schema } from "@effect/schema";
import { IconChevronLeft, IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  String as S,
  pipe,
} from "effect";
import { useCallback } from "react";

import {
  Form,
  FormList,
  FormSection,
  useEffectSchemaForm,
} from "@ender/form-system/base";
import {
  EmailEffectSchema,
  LocalDateEffectSchema,
  MoneyEffectSchema,
  PhoneEffectSchema,
} from "@ender/form-system/schema";
import { uploadFilesDirect } from "@ender/shared/api/files";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1, H3 } from "@ender/shared/ds/heading";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FormSwitch } from "@ender/shared/ds/switch";
import { Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { ApplicationEmploymentStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationEmploymentStatusEffectSchema,
  ApplicationEmploymentStatusEnum,
} from "@ender/shared/generated/ender.model.leasing";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import type { LabelValue } from "@ender/shared/types/label-value";
import { fail } from "@ender/shared/utils/error";

import type { ApplyStepProps } from "./step-props";

import commonStyles from "./common.module.css";
import styles from "./income.module.css";

const EmploymentStatusDisplayMap: Record<ApplicationEmploymentStatus, string> =
  {
    EMPLOYED: "Employed",
    RETIRED: "Retired",
    SELF_EMPLOYED: "Self Employed",
    STUDENT: "Student",
    UNEMPLOYED: "Unemployed",
  } as const;

const EmploymentStatusData: LabelValue<ApplicationEmploymentStatus>[] =
  Object.entries(EmploymentStatusDisplayMap).map(([value, label]) => ({
    label,
    value: value as ApplicationEmploymentStatus,
  }));

const AdditionalIncomeSchema = Schema.Struct({
  description: Schema.String,
  saved: Schema.Boolean,
  yearlyEstimate: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
});
type AdditionalIncome = Schema.Schema.Encoded<typeof AdditionalIncomeSchema>;

type DocumentRequestPromptProps = {
  employmentStatus?: O.Option<ApplicationEmploymentStatus>;
  employer?: string;
  additionalIncome: readonly AdditionalIncome[];
  housingChoiceVoucher: boolean;
};

function DocumentRequestPrompt({
  employmentStatus = O.none(),
  employer,
  additionalIncome,
  housingChoiceVoucher,
}: DocumentRequestPromptProps) {
  const hasSourceOfIncome =
    O.exists(employmentStatus, (v) =>
      (
        [
          ApplicationEmploymentStatusEnum.EMPLOYED,
          ApplicationEmploymentStatusEnum.SELF_EMPLOYED,
        ] as ApplicationEmploymentStatus[]
      ).includes(v),
    ) ||
    A.isNonEmptyArray(
      additionalIncome.filter(({ description }) =>
        S.isNonEmpty(description ?? ""),
      ),
    ) ||
    housingChoiceVoucher;

  if (!hasSourceOfIncome) {
    return "Please add at least one source of income above.";
  }

  const showThreeMonthsOfPaystubs =
    O.exists(employmentStatus, (v) =>
      (
        [
          ApplicationEmploymentStatusEnum.EMPLOYED,
          ApplicationEmploymentStatusEnum.SELF_EMPLOYED,
        ] as ApplicationEmploymentStatus[]
      ).includes(v),
    ) && S.isNonEmpty(employer ?? "");

  return (
    <>
      Please upload the following documents: <br />
      <ul>
        {showThreeMonthsOfPaystubs && (
          <li>Last 3 months of Paystubs from {employer}</li>
        )}
        {additionalIncome.map((income) =>
          income.description ? (
            <li key={income.description}>
              Proof of income for {income.description}
            </li>
          ) : (
            ""
          ),
        )}
        {housingChoiceVoucher && (
          <li>
            Voucher with expiration date (Required)
            <ul>
              <li>Payout sheet (Required)</li>
              <li>Rent to Family Assistance (RTFA) packet</li>
            </ul>
          </li>
        )}
      </ul>
    </>
  );
}

const employedStatuses: ApplicationEmploymentStatus[] = [
  ApplicationEmploymentStatusEnum.EMPLOYED,
  ApplicationEmploymentStatusEnum.SELF_EMPLOYED,
] as const;

const IncomeFormSchema = Schema.Struct({
  additionalIncome: AdditionalIncomeSchema.pipe(Schema.Array),
  annualSalary: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  employer: Schema.String,
  employmentStartDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  employmentStatus: ApplicationEmploymentStatusEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  firstTimeRenter: Schema.Boolean,
  housingChoiceVoucher: Schema.Boolean,
  housingChoiceVoucherCaseWorkerEmail: EmailEffectSchema,
  housingChoiceVoucherCaseWorkerName: Schema.String,
  landlordEmail: EmailEffectSchema,
  landlordName: Schema.String,
  landlordPhone: PhoneEffectSchema,
  managerName: Schema.String,
  managerPhone: PhoneEffectSchema,
}).pipe(
  Schema.filter((schema) => {
    const issues: Schema.FilterIssue[] = [];
    if (
      pipe(
        schema.employmentStatus,
        O.exists((v) => employedStatuses.includes(v)),
      )
    ) {
      if (!S.isNonEmpty(schema.employer ?? "")) {
        issues.push({
          message: "Employer is required",
          path: ["employer"],
        });
      }
      if (O.isNone(schema.annualSalary)) {
        issues.push({
          message: "Annual Salary is required",
          path: ["annualSalary"],
        });
      }
    }
    if (
      O.contains(
        schema.employmentStatus,
        ApplicationEmploymentStatusEnum.EMPLOYED,
      )
    ) {
      if (!S.isNonEmpty(schema.managerName ?? "")) {
        issues.push({
          message: "Manager Name is required",
          path: ["managerName"],
        });
      }
    }
    if (schema.housingChoiceVoucher) {
      if (!S.isNonEmpty(schema.housingChoiceVoucherCaseWorkerName ?? "")) {
        issues.push({
          message: "Case Worker Name is required",
          path: ["housingChoiceVoucherCaseWorkerName"],
        });
      }
      if (!S.isNonEmpty(schema.housingChoiceVoucherCaseWorkerEmail ?? "")) {
        issues.push({
          message: "Case Worker Email is required",
          path: ["housingChoiceVoucherCaseWorkerEmail"],
        });
      }
    }
    if (!schema.firstTimeRenter) {
      if (!S.isNonEmpty(schema.landlordName ?? "")) {
        issues.push({
          message: "Landlord Name is required",
          path: ["landlordName"],
        });
      }
      if (!S.isNonEmpty(schema.landlordEmail ?? "")) {
        issues.push({
          message: "Landlord Email is required",
          path: ["landlordEmail"],
        });
      }
    }
    return issues;
  }),
);

type IncomeFormOutput = Schema.Schema.Type<typeof IncomeFormSchema>;

function ApplyStepIncome({
  user,
  onRequestNext = F.constVoid,
  onRequestPrevious = F.constVoid,
  progress,
  titleRef,
  applicationGroup,
  applications,
}: ApplyStepProps) {
  const application = applications?.find(
    ({ applicant }) => applicant.userId === user?.userId,
  );

  const form = useEffectSchemaForm({
    defaultValues: {
      additionalIncome: (application?.otherSourcesOfIncome ?? []).map(
        (val) => ({
          ...val,
          saved: true,
          yearlyEstimate: Money$.parse(val.yearlyEstimate),
        }),
      ),
      annualSalary: Money$.parse(application?.annualSalary),
      employer: application?.employer ?? "",
      employmentStartDate: LocalDate$.parse(application?.employmentStartDate),
      employmentStatus: O.fromNullable(application?.employmentStatus),
      firstTimeRenter: application?.firstTimeRenter ?? false,
      housingChoiceVoucher: P.isNotNullable(
        application?.housingChoiceVoucherTimestamp,
      ),
      housingChoiceVoucherCaseWorkerEmail:
        application?.housingChoiceVoucherCaseWorkerEmail ?? "",
      housingChoiceVoucherCaseWorkerName:
        application?.housingChoiceVoucherCaseWorkerName ?? "",
      landlordEmail: application?.landlordEmail ?? "",
      landlordName: application?.landlordName ?? "",
      landlordPhone: application?.landlordPhone ?? "",
      managerName: application?.managerName ?? "",
      managerPhone: application?.managerPhone ?? "",
    },
    schema: IncomeFormSchema,
  });

  const {
    data: paystubs,
    isLoading: isLoadingPaystubs,
    refetch: refetchPaystubs,
  } = useQuery({
    queryKey: [
      "WebserverFilesAPI.getFiles",
      application?.applicationId,
      WebserverFilesServiceFileUploadTypeEnum.PAYSTUB,
    ] as const,
    queryFn: ({ signal }) => {
      if (P.isNotNullable(application)) {
        return WebserverFilesAPI.getFiles(
          {
            modelId: application.applicationId,
            modelType: ModelTypeEnum.APPLICATION,
            uploadType: WebserverFilesServiceFileUploadTypeEnum.PAYSTUB,
          },
          { signal },
        );
      }
    },
    enabled: P.isNotNullable(application),
  });

  const {
    data: photoIds,
    isInitialLoading: isLoadingPhotoIds,
    refetch: refetchPhotoIds,
  } = useQuery({
    queryKey: [
      "WebserverFilesAPI.getFiles",
      application?.applicationId,
      WebserverFilesServiceFileUploadTypeEnum.PHOTO_ID,
    ] as const,
    queryFn: ({ signal }) => {
      if (P.isNotNullable(application)) {
        return WebserverFilesAPI.getFiles(
          {
            modelId: application?.applicationId,
            modelType: ModelTypeEnum.APPLICATION,
            uploadType: WebserverFilesServiceFileUploadTypeEnum.PHOTO_ID,
          },
          { signal },
        );
      }
    },
    enabled: P.isNotNullable(application),
  });

  async function handleDeleteOtherIncome({
    yearlyEstimate,
    description,
  }: AdditionalIncome) {
    try {
      if (
        P.isNotNullable(applicationGroup?.id) &&
        P.isNotNullable(application?.applicationId)
      ) {
        await ApplicationsAPI.deleteOtherIncome({
          applicationGroupId: applicationGroup?.id,
          applicationId: application?.applicationId,
          json: { description, yearlyEstimate },
        });
      }
    } catch (err) {
      fail(err);
    }
  }

  const { mutateAsync: updateApplication, isLoading: updatingApplication } =
    useMutation({
      mutationFn: ApplicationsAPI.updateApplication,
      mutationKey: ["ApplicationsAPI.updateApplication"] as const,
    });
  const { mutateAsync: saveOtherIncome, isLoading: savingOtherIncome } =
    useMutation({
      mutationFn: ({
        otherSourcesOfIncome,
      }: {
        otherSourcesOfIncome: AdditionalIncome[];
      }) => {
        return Promise.all(
          otherSourcesOfIncome.map(({ yearlyEstimate, description }) => {
            if (P.isNullable(applicationGroup) || P.isNullable(application)) {
              throw "Application Group is not defined";
            }
            return ApplicationsAPI.addOtherIncome({
              applicationGroupId: applicationGroup.id,
              applicationId: application.applicationId,
              description,
              yearlyEstimate: pipe(
                yearlyEstimate,
                O.map((v) => v.toJSON()),
                O.getOrThrow,
              ),
            });
          }),
        );
      },
      mutationKey: ["ApplicationsAPI.addOtherIncome"] as const,
    });

  const [
    housingChoiceVoucher,
    employmentStatus,
    firstTimeRenter,
    additionalIncome,
    employer,
  ] = form.watch([
    "housingChoiceVoucher",
    "employmentStatus",
    "firstTimeRenter",
    "additionalIncome",
    "employer",
  ]);

  const { mutateAsync: uploadFiles, isLoading: uploadingFiles } = useMutation({
    mutationFn: uploadFilesDirect,
    mutationKey: ["uploadFilesDirect"] as const,
  });

  const handleSubmit = useCallback(
    async (values: IncomeFormOutput) => {
      const {
        additionalIncome: otherSourcesOfIncome,
        employmentStatus,
        employer,
        employmentStartDate,
        firstTimeRenter,
        annualSalary,
        managerName,
        managerPhone,
        landlordName,
        landlordPhone,
        landlordEmail,
        housingChoiceVoucher,
        housingChoiceVoucherCaseWorkerName,
        housingChoiceVoucherCaseWorkerEmail,
      } = values;

      await saveOtherIncome({
        otherSourcesOfIncome: otherSourcesOfIncome.filter((val) => !val.saved),
      });
      if (P.isNotNullable(applicationGroup?.id)) {
        const preprocessed = {
          annualSalary: pipe(
            annualSalary,
            O.map((v) => v.toJSON()),
            O.getOrUndefined,
          ),
          applicationGroupId: applicationGroup?.id,
          employer,
          employmentStartDate: pipe(
            employmentStartDate,
            O.map((v) => v.toJSON()),
            O.getOrUndefined,
          ),
          employmentStatus: pipe(employmentStatus, O.getOrThrow),
          firstTimeRenter,
          housingChoiceVoucher,
          housingChoiceVoucherCaseWorkerEmail,
          housingChoiceVoucherCaseWorkerName,
          landlordEmail,
          landlordName,
          landlordPhone,
          managerName,
          managerPhone,
        };
        const payload = preprocessed.firstTimeRenter
          ? {
              ...preprocessed,
              landlordEmail: undefined,
              landlordName: undefined,
              landlordPhone: undefined,
            }
          : preprocessed;
        await updateApplication(payload);
      } else {
        throw new Error("Application Group is not defined");
      }

      onRequestNext();
    },
    [applicationGroup?.id, onRequestNext, saveOtherIncome, updateApplication],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <div className={commonStyles.stepContainer}>
        <div className={styles.inner}>
          <div className={commonStyles.stepHeader} ref={titleRef}>
            <Stack spacing={Spacing.none}>
              <div className={commonStyles.navBack}>
                <Button
                  variant={ButtonVariant.transparent}
                  leftSection={<IconChevronLeft size={16} />}
                  onClick={onRequestPrevious}>
                  Back
                </Button>
              </div>
              <H1>Income & Identity</H1>
              {progress}
            </Stack>
          </div>
          <H3>Income & Financial Information</H3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormSelect
              form={form}
              name="employmentStatus"
              placeholder="Select"
              data={EmploymentStatusData}
              label="Employment Status"
            />
            <FormCheckbox
              form={form}
              name="housingChoiceVoucher"
              label="Using Housing Choice Voucher"
            />
            {housingChoiceVoucher && (
              <>
                <FormTextInput
                  form={form}
                  name="housingChoiceVoucherCaseWorkerName"
                  label="Case Worker Name"
                />
                <FormTextInput
                  form={form}
                  name="housingChoiceVoucherCaseWorkerEmail"
                  label="Case Worker E-mail"
                />
              </>
            )}
            {O.exists(employmentStatus, (v) =>
              ["EMPLOYED", "SELF_EMPLOYED"].includes(v),
            ) && (
              <>
                <FormTextInput form={form} name="employer" label="Employer" />
                <FormDateInput
                  form={form}
                  name="employmentStartDate"
                  label="Start Date"
                />
                <FormMoneyInput
                  form={form}
                  name="annualSalary"
                  label="Annual Salary"
                />
              </>
            )}
            {O.contains(employmentStatus, "EMPLOYED") && (
              <>
                <FormTextInput
                  form={form}
                  name="managerName"
                  label="Manager's Name"
                  description="We may use this contact to confirm the employment information provided"
                />
                <FormPhoneInput
                  form={form}
                  name="managerPhone"
                  label="Manager's Phone Number"
                />
              </>
            )}
          </div>
          <H3>Additional Income Sources</H3>
          <Text>
            Additional income sources may include child support, government
            assistance, etc.
          </Text>
          <FormList form={form} name="additionalIncome">
            {({ list, arrayMethods }) => (
              <>
                {list.map((item, index) => (
                  <Card key={item.key}>
                    <FormSection form={form} name={item.name}>
                      {({ section }) => {
                        const otherIncome = form.watch(item.name);
                        return (
                          <Stack spacing={Spacing.sm}>
                            <Group justify={Justify.between}>
                              <H3>Additional Income Source {index + 1}</H3>
                              <ActionIcon
                                onClick={() => {
                                  arrayMethods.remove(index);
                                  if (otherIncome.saved) {
                                    handleDeleteOtherIncome(otherIncome);
                                  }
                                }}>
                                <IconTrash />
                              </ActionIcon>
                            </Group>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <FormTextInput
                                form={form}
                                name={section.description}
                                label="Description"
                                disabled={otherIncome.saved}
                              />
                              <FormMoneyInput
                                form={form}
                                name={section.yearlyEstimate}
                                label="Estimated Annual Earnings"
                                disabled={otherIncome.saved}
                              />
                            </div>
                          </Stack>
                        );
                      }}
                    </FormSection>
                  </Card>
                ))}
                <Button
                  leftSection={<IconPlus />}
                  variant={ButtonVariant.outlined}
                  onClick={() =>
                    arrayMethods.append({
                      description: "",
                      saved: false,
                      yearlyEstimate: O.none(),
                    })
                  }>
                  Add
                </Button>
              </>
            )}
          </FormList>
          <H3>Proof of Income</H3>
          <Text>
            <DocumentRequestPrompt
              employmentStatus={employmentStatus}
              employer={employer}
              additionalIncome={additionalIncome}
              housingChoiceVoucher={housingChoiceVoucher}
            />
            {`\n\n*If you do not have proof of income readily available you may skip this step and return later.`}
          </Text>
          <Skeleton visible={isLoadingPaystubs}>
            <Group>
              {paystubs?.files.map((file: FilesClientEnderFile) => (
                <a href={file.s3Url} key={file.id} className={styles.fileLink}>
                  {file.path.split("/").pop()}
                </a>
              ))}
            </Group>
            <FileInput
              value={[]}
              onChange={async (paystubFiles) => {
                if (P.isNullable(user) || P.isNullable(application)) {
                  throw "Unknown user cannot upload files";
                }
                await uploadFiles({
                  files: paystubFiles,
                  modelId: application.applicationId,
                  modelType: ModelTypeEnum.APPLICATION,
                  subFolder: "PUBLIC",
                  uploadType: WebserverFilesServiceFileUploadTypeEnum.PAYSTUB,
                  userId: user.userId,
                });
                await refetchPaystubs();
              }}
            />
          </Skeleton>

          <H3>Proof of Identity</H3>
          <Text>
            Please upload a photo of the front and back of your government
            issued ID.
            <br />
            <br />
            *If you do not have proof of identity readily available you may skip
            this step and return later.
          </Text>
          <Skeleton visible={isLoadingPhotoIds}>
            <Group>
              {photoIds?.files.map((file: FilesClientEnderFile) => (
                <a href={file.s3Url} key={file.id} className={styles.fileLink}>
                  {file.path.split("/").pop()}
                </a>
              ))}
            </Group>
            <FileInput
              value={[]}
              onChange={async (photoIdFiles) => {
                if (P.isNullable(user) || P.isNullable(application)) {
                  throw "Unknown user cannot upload files";
                }
                await uploadFiles({
                  files: photoIdFiles,
                  modelId: application.applicationId,
                  modelType: ModelTypeEnum.APPLICATION,
                  subFolder: "PUBLIC",
                  uploadType: WebserverFilesServiceFileUploadTypeEnum.PHOTO_ID,
                  userId: user.userId,
                });
                await refetchPhotoIds();
              }}
            />
          </Skeleton>
          {A.isNonEmptyArray(application?.pets ?? []) && (
            <Stack>
              {application?.pets.map((pet) => (
                <>
                  <H3>Pet Documents - {pet.name}</H3>
                  <FileInput
                    value={[]}
                    onChange={async (petFiles) => {
                      if (P.isNullable(user) || P.isNullable(application)) {
                        throw "Unknown user cannot upload files";
                      }
                      await uploadFiles({
                        files: petFiles,
                        modelId: pet.id,
                        modelType: ModelTypeEnum.PET,
                        uploadType:
                          WebserverFilesServiceFileUploadTypeEnum.PET_DOCUMENT,
                        userId: user.userId,
                      });
                      //TODO refetch pet files
                    }}
                  />
                </>
              ))}
            </Stack>
          )}
          <H3>Renting Experience</H3>
          <Text>
            Please enter the contact information of your current or most recent
            Landlord.
            <br />
            If this is your first time renting, please select I’m a first time
            renter.
          </Text>
          <FormSwitch
            form={form}
            name="firstTimeRenter"
            label="I’m a first time renter"
          />
          {!firstTimeRenter && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormTextInput
                form={form}
                name="landlordName"
                label="Landlord Name"
              />
              <FormPhoneInput
                form={form}
                name="landlordPhone"
                label="Landlord Phone Number"
              />
              <FormTextInput
                form={form}
                name="landlordEmail"
                label="Landlord Email"
              />
            </div>
          )}
        </div>
        <div className={commonStyles.backButton}>
          <Button
            leftSection={<IconChevronLeft size={16} />}
            onClick={onRequestPrevious}>
            Back
          </Button>
        </div>
        <div className={commonStyles.submitButton}>
          <Button
            loading={updatingApplication || uploadingFiles || savingOtherIncome}
            type="submit">
            Save & Continue
          </Button>
        </div>
      </div>
    </Form>
  );
}
export { ApplyStepIncome };
