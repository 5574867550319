/**
 * HTTP Method: GET
 * Pathname: /chargeSchedules/{chargeScheduleId}/charges
 * @function getChargesForChargeSchedule
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPIGetChargesForChargeSchedulePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetChargesForChargeScheduleResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetChargesForChargeScheduleResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ChargeSchedulesAPIGetChargesForChargeSchedulePathParams = {
  chargeScheduleId: EnderId;
};

type ChargeSchedulesAPIGetChargesForChargeScheduleSearchParams = {
  token?: string | undefined;
};

type ChargeSchedulesAPIGetChargesForChargeSchedulePayload =
  ChargeSchedulesAPIGetChargesForChargeSchedulePathParams &
    ChargeSchedulesAPIGetChargesForChargeScheduleSearchParams;

function getChargesForChargeScheduleUnsafeEffect(
  payload: ChargeSchedulesAPIGetChargesForChargeSchedulePayload,
) {
  const { chargeScheduleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetChargesForChargeScheduleResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetChargesForChargeScheduleResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/chargeSchedules/${chargeScheduleId}/charges`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getChargesForChargeSchedule(
  payload: ChargeSchedulesAPIGetChargesForChargeSchedulePayload,
  options?: { signal?: AbortSignal },
): Promise<GetChargesForChargeScheduleResponse[]> {
  return F.pipe(
    payload,
    getChargesForChargeScheduleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getChargesForChargeSchedule };
export type {
  ChargeSchedulesAPIGetChargesForChargeSchedulePathParams,
  ChargeSchedulesAPIGetChargesForChargeSchedulePayload,
  ChargeSchedulesAPIGetChargesForChargeScheduleSearchParams,
};
