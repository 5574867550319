import { useQuery } from "@tanstack/react-query";

import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";

function useGetAllProperties(
  showHidden = false,
  showWithGLTxs = false,
  showUnpurchased = false,
) {
  const { data: allProperties = [], isLoading } = useQuery({
    queryFn: () =>
      PropertiesAPI.searchPropertiesv2({
        limit: 99999,
        ownershipGroupIds: [],
        showHidden,
        showUnpurchased,
        showWithGLTxs,
      }),
    queryKey: ["fetchAllProperties", showHidden, showWithGLTxs],
    select: (data) =>
      data?.properties.map((property) => ({
        value: property.name,
        ...property,
      })),
  });

  return { allProperties, isLoading };
}

export { useGetAllProperties };
