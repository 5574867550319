import { Array as A, Predicate as P } from "effect";
import { useEffect, useState } from "react";

import { Skeleton } from "@ender/shared/ds/skeleton";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { GetMLSPublicPropertiesResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import { useQueryParams } from "@ender/shared/hooks/use-query-params";
import { fail } from "@ender/shared/utils/error";

import styles from "./mls-public-page.module.css";

type DealWithAddress = GetMLSPublicPropertiesResponse;

function MLSPublicPage() {
  const { accessToken } = useQueryParams();
  const [deals, setDeals] = useState<DealWithAddress[] | undefined>(undefined);

  // lazy useEffect
  useEffect(() => {
    BuyAPI.getMLSPublicProperties({ accessToken }).then(setDeals).catch(fail);
  }, [accessToken]);

  return (
    <Skeleton visible={P.isNullable(deals)}>
      <div className={styles.mlsPublicPage}>
        <table className="table--default">
          <thead>
            <tr>
              <th>MLS ID</th>
              <th>Street</th>
              <th>City</th>
              <th>State</th>
              <th>Zipcode</th>
              <th>County</th>
              <th>List Price</th>
              <th># Beds</th>
              <th># Baths</th>
              <th>Sqft</th>
              <th>Year Built</th>
            </tr>
          </thead>
          <tbody>
            {A.isEmptyArray(deals ?? []) && (
              <tr>
                <td colSpan={11}>No Properties</td>
              </tr>
            )}
            {deals?.map((deal) => (
              <tr key={deal.id}>
                <td>{deal.mlsId}</td>
                <td>{deal.address.street}</td>
                <td>{deal.address.city}</td>
                <td>{deal.address.state}</td>
                <td>{deal.address.zipcode}</td>
                <td>{deal.county}</td>
                <td>{deal.currentListPrice}</td>
                <td>{deal.numBeds}</td>
                <td>{deal.numBaths}</td>
                <td>{deal.sqft}</td>
                <td>{deal.yearBuilt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Skeleton>
  );
}

export { MLSPublicPage };
