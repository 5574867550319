"use client";

import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import * as S from "effect/String";
import { useRouter } from "next/navigation";
import type { SyntheticEvent } from "react";
import { useCallback, useMemo } from "react";

import { Form, useForm } from "@ender/form-system/base";
import {
  EmailEffectSchema,
  PhoneEffectSchema,
} from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { Group } from "@ender/shared/ds/group";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { Stack } from "@ender/shared/ds/stack";
import { Text, TextColor } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import { AuthAPI, EnderAPI } from "@ender/shared/generated/ender.api.misc";
import { Soc2Hidden } from "@ender/shared/ui/soc2-hidden";
import { fail } from "@ender/shared/utils/error";

import { handleLogout } from "../shared-utils-logout";
import { FormPasswordInput } from "./form-password-input";

// TODO This password schema is a temporary patch we will bring in https://github.com/zxcvbn-ts/zxcvbn for ENDER-22564
const PasswordSchema = Schema.String.pipe(
  Schema.trimmed({
    message: () => "Password cannot start or end with an empty space",
  }),
  Schema.nonEmptyString({ message: () => "Password is required" }),
  Schema.minLength(8, {
    message: () => "Password must be at least 8 characters long",
  }),
  Schema.pattern(/[!@#$%^&*]/, {
    message: () =>
      "Password must contain at least one special character !@#$%^&*",
  }),
);

const WelcomePageFormSchema = Schema.Struct({
  agreeToTerms: Schema.Boolean,
  confirmPassword: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Confirm Password is required" }),
  ),
  email: EmailEffectSchema.pipe(
    Schema.nonEmptyString({ message: () => "Email is required" }),
  ),
  emailVerifiedTimestamp: Schema.UndefinedOr(Schema.String),
  firstName: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "First Name is required" }),
  ),
  lastName: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Last Name is required" }),
  ),
  password: PasswordSchema,
  phone: PhoneEffectSchema.pipe(
    Schema.nonEmptyString({ message: () => "Phone is required" }),
  ),
  userId: EnderIdFormSchema,
  userPhone: PhoneEffectSchema,
}).pipe(
  Schema.filter((values) => {
    if (values.password !== values.confirmPassword) {
      return {
        message: "Passwords do not match",
        path: ["confirmPassword"],
      };
    }
    return true;
  }),
);

type WelcomePageFormValues = Schema.Schema.Type<typeof WelcomePageFormSchema>;

const agreeToTermsLabel = (
  <div>
    <span>I agree to </span>
    <a href="/terms" target="_blank" rel="noreferrer">
      Ender's Terms of Service
    </a>
    <span>, </span>
    <a href="/privacy" target="_blank" rel="noreferrer">
      Ender's Privacy Policy
    </a>
    <span>, </span>
    <a href="https://www.dwolla.com/legal/tos" target="_blank" rel="noreferrer">
      Dwolla's Terms of Service
    </a>
    <span>, </span>
    <a
      href="https://www.dwolla.com/legal/privacy"
      target="_blank"
      rel="noreferrer">
      Dwolla's Privacy Policy
    </a>
    <span>, and </span>
    <a
      href="https://ender-public.s3.us-east-2.amazonaws.com/SAR+Terms+and+Conditions+-5.2020.pdf"
      target="_blank"
      rel="noreferrer">
      TransUnion's Terms of Service
    </a>
  </div>
);

type WelcomeFormProps = {
  email: string;
  emailVerifiedTimestamp?: string;
  firstName: string;
  lastName: string;
  phone: string;
  userId: EnderId;
  redirectUrl: string;
};

function WelcomeForm(props: WelcomeFormProps) {
  const {
    email,
    emailVerifiedTimestamp,
    firstName,
    lastName,
    userId,
    phone,
    redirectUrl,
  } = props;

  const router = useRouter();

  const {
    mutateAsync: signUpAsync,
    error,
    isLoading,
  } = useMutation({
    mutationFn: async (values: WelcomePageFormValues) => {
      await UsersAPI.updateUser({ json: values, targetUserId: values.userId });
      await EnderAPI.agreeToTerms({});
      if (values.emailVerifiedTimestamp) {
        await AuthAPI.sendVerificationEmail({}).catch(() => {
          // TODO: Properly handle `BatchValidationError`s which happen if the Email is already verified
          return UNDEFINED;
        });
      }
    },
  });

  const handleSignUpSuccess = useCallback(() => {
    router.replace(redirectUrl);
  }, [redirectUrl, router]);

  const form = useForm<WelcomePageFormValues>({
    defaultValues: {
      agreeToTerms: false,
      confirmPassword: "",
      email: email || "",
      emailVerifiedTimestamp: emailVerifiedTimestamp,
      firstName: firstName || "",
      lastName: lastName || "",
      password: "",
      phone: phone || "",
      userId: userId,
      userPhone: phone,
    },
    mode: "onSubmit",
    resolver: effectTsResolver(WelcomePageFormSchema),
  });

  const errorMessage: O.Option<string> = useMemo(() => {
    if (P.isNotNullable(error)) {
      return O.some(`${error}`);
    }
    return O.none();
  }, [error]);

  const signUp = useCallback(
    (values: WelcomePageFormValues) => {
      signUpAsync(values)
        .then(() => handleSignUpSuccess())
        .catch(fail);
    },
    [signUpAsync, handleSignUpSuccess],
  );

  const onSubmit = useCallback(
    (values: WelcomePageFormValues) => {
      // TODO: FIX WarningsModal in Next.js Apps
      // if (!values.userPhone.includes(values.phone)) {
      //   openModal(
      //     WarningsModal,
      //     {
      //       onConfirm: (closeModal: () => void) => {
      //         closeModal();
      //         signUp(values);
      //       },
      //       warnings: [`Phone number ${values.phone} does not match the one on file`],
      //     },
      //     "warnings-modal__with-image",
      //   );
      // }
      signUp(values);
    },
    [signUp],
  );

  const onSwitchUserClick = useCallback((ev: SyntheticEvent<unknown>) => {
    ev.preventDefault();
    handleLogout();
  }, []);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        {S.isEmpty(lastName) && (
          <>
            <Soc2Hidden>
              <FormTextInput name="firstName" form={form} label="First Name" />
            </Soc2Hidden>
            <Soc2Hidden>
              <FormTextInput name="lastName" form={form} label="Last Name" />
            </Soc2Hidden>
          </>
        )}
        {S.isEmpty(email) && (
          <Soc2Hidden>
            <FormTextInput name="email" form={form} label="Email" />
          </Soc2Hidden>
        )}
        <FormPhoneInput name="phone" label="Phone #" form={form} />
        <FormPasswordInput label="Password" name="password" form={form} />
        <FormPasswordInput
          label="Confirm Password"
          name="confirmPassword"
          form={form}
        />
        <FormCheckbox
          name="agreeToTerms"
          label={agreeToTermsLabel}
          form={form}
        />
        {O.isSome(errorMessage) && (
          <Text color={TextColor["red-500"]}>{O.getOrThrow(errorMessage)}</Text>
        )}
        <Group>
          <Button
            type="submit"
            disabled={isLoading || !form.watch("agreeToTerms")}
            disabledTooltip="You must agree to terms before continuing">
            Continue
          </Button>
          <Button disabled={isLoading} onClick={onSwitchUserClick}>
            Switch Users
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { WelcomeForm };
