/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/comps/homeValue
 * @function getHomeValueComps
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPIGetHomeValueCompsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnderwritingAPIGetHomeValueCompsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { UnderwritingAPIGetHomeValueCompsResponse } from "../underwriting-api-get-home-value-comps-response";

type UnderwritingAPIGetHomeValueCompsPathParams = {
  dealId: EnderId;
};

type UnderwritingAPIGetHomeValueCompsSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPIGetHomeValueCompsBodyParams = {
  maxBathDiff: number;
  maxBedDiff: number;
  maxDistanceMiles: number;
  maxSqftDiff: number;
  ownedByUs: boolean;
  soldCutoffDate: SerializesInto<LocalDate>;
};

type UnderwritingAPIGetHomeValueCompsPayload =
  UnderwritingAPIGetHomeValueCompsPathParams &
    UnderwritingAPIGetHomeValueCompsSearchParams &
    UnderwritingAPIGetHomeValueCompsBodyParams;

function getHomeValueCompsUnsafeEffect(
  payload: UnderwritingAPIGetHomeValueCompsPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnderwritingAPIGetHomeValueCompsResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<UnderwritingAPIGetHomeValueCompsResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/comps/homeValue`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getHomeValueComps(
  payload: UnderwritingAPIGetHomeValueCompsPayload,
  options?: { signal?: AbortSignal },
): Promise<UnderwritingAPIGetHomeValueCompsResponse> {
  return F.pipe(
    payload,
    getHomeValueCompsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getHomeValueComps };
export type {
  UnderwritingAPIGetHomeValueCompsBodyParams,
  UnderwritingAPIGetHomeValueCompsPathParams,
  UnderwritingAPIGetHomeValueCompsPayload,
  UnderwritingAPIGetHomeValueCompsSearchParams,
};
