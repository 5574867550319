import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const MoneyTransferTransferTypeValues = [
  "STRIPE_PAYMENT",
  "DWOLLA_TRANSFER",
  "MARK_PAID_CHECK",
  "PRINT_CHECK",
  "LOB_CHECK",
  "BANK_TRANSFER",
  "WIRE",
  "OTHER",
] as const;

const MoneyTransferTransferTypeEffectSchema = Schema.Literal(
  ...MoneyTransferTransferTypeValues,
);

type MoneyTransferTransferType = Schema.Schema.Type<
  typeof MoneyTransferTransferTypeEffectSchema
>;

const MoneyTransferTransferTypeEnum = castEnum(
  MoneyTransferTransferTypeEffectSchema,
);

function randomMoneyTransferTransferType(): MoneyTransferTransferType {
  return rand(MoneyTransferTransferTypeValues);
}

export {
  MoneyTransferTransferTypeEffectSchema,
  MoneyTransferTransferTypeEnum,
  MoneyTransferTransferTypeValues,
  randomMoneyTransferTransferType,
};
export type { MoneyTransferTransferType };
