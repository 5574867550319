import { addPhotos } from "./add-photos";
import { approveInspectionBudget } from "./approve-inspection-budget";
import { archiveInspection } from "./archive-inspection";
import { completeInspectionMaintenance } from "./complete-inspection-maintenance";
import { createArea } from "./create-area";
import { createInspection } from "./create-inspection";
import { createZone } from "./create-zone";
import { deleteArea } from "./delete-area";
import { deletePhotos } from "./delete-photos";
import { deleteZone } from "./delete-zone";
import { downloadPhotosPDF } from "./download-photos-p-d-f";
import { downloadPhotosZIP } from "./download-photos-z-i-p";
import { getAreas } from "./get-areas";
import { getInspection } from "./get-inspection";
import { getInspectionEligibleProperties } from "./get-inspection-eligible-properties";
import { getInspectionPropertyDetails } from "./get-inspection-property-details";
import { getUnitAreas } from "./get-unit-areas";
import { getUnitZones } from "./get-unit-zones";
import { getZone } from "./get-zone";
import { getZones } from "./get-zones";
import { inspectionSearch } from "./inspection-search";
import { markAreaApplicable } from "./mark-area-applicable";
import { markAreaNotApplicable } from "./mark-area-not-applicable";
import { markZoneApplicable } from "./mark-zone-applicable";
import { markZoneNotApplicable } from "./mark-zone-not-applicable";
import { rejectInspectionBudget } from "./reject-inspection-budget";
import { searchPhotos } from "./search-photos";
import { searchVideos } from "./search-videos";
import { startInspectionMaintenance } from "./start-inspection-maintenance";
import { submitInspection } from "./submit-inspection";
import { unarchiveInspection } from "./unarchive-inspection";
import { updateArea } from "./update-area";
import { updateInspection } from "./update-inspection";
import { updateInspectionProjectManager } from "./update-inspection-project-manager";
import { updateZone } from "./update-zone";

const InspectionAPI = {
  addPhotos,
  approveInspectionBudget,
  archiveInspection,
  completeInspectionMaintenance,
  createArea,
  createInspection,
  createZone,
  deleteArea,
  deletePhotos,
  deleteZone,
  downloadPhotosPDF,
  downloadPhotosZIP,
  getAreas,
  getInspection,
  getInspectionEligibleProperties,
  getInspectionPropertyDetails,
  getUnitAreas,
  getUnitZones,
  getZone,
  getZones,
  inspectionSearch,
  markAreaApplicable,
  markAreaNotApplicable,
  markZoneApplicable,
  markZoneNotApplicable,
  rejectInspectionBudget,
  searchPhotos,
  searchVideos,
  startInspectionMaintenance,
  submitInspection,
  unarchiveInspection,
  updateArea,
  updateInspection,
  updateInspectionProjectManager,
  updateZone,
};

export { InspectionAPI };
