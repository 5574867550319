import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CarportStructureTypeValues = [
  "ATTACHED",
  "DETACHED",
  "ATTACHED_AND_DETACHED",
] as const;

const CarportStructureTypeEffectSchema = Schema.Literal(
  ...CarportStructureTypeValues,
);

type CarportStructureType = Schema.Schema.Type<
  typeof CarportStructureTypeEffectSchema
>;

const CarportStructureTypeEnum = castEnum(CarportStructureTypeEffectSchema);

function randomCarportStructureType(): CarportStructureType {
  return rand(CarportStructureTypeValues);
}

export {
  CarportStructureTypeEffectSchema,
  CarportStructureTypeEnum,
  CarportStructureTypeValues,
  randomCarportStructureType,
};
export type { CarportStructureType };
