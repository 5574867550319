import type { ColumnFiltersState } from "@tanstack/react-table";
import { Function as F, Option as O, Record as R } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { AccountingPeriod } from "@ender/shared/generated/ender.model.accounting";
import { cast } from "@ender/shared/types/cast";

// All from accounting-api.ts

function processDate(date: string): LocalDate {
  const split = date.split("-");
  return cast(`${split[0]}-${split[1]}-01`);
}

function getPeriodFilterProp(
  periodFilter: LocalDate[],
): PeriodFilter | Undefined {
  if (periodFilter.length === 0) {
    return UNDEFINED;
  }

  return {
    type: "CUSTOM",
    customFilter: periodFilter.map(processDate),
  };
}

function findMatchingPeriod(
  periods: AccountingPeriod[],
  date: LocalDate,
): AccountingPeriod | Undefined {
  for (const period of periods) {
    const searchDate = LocalDate$.of(date);
    const startDate = LocalDate$.of(period.startDate);
    const endDate = LocalDate$.of(period.endDate);

    if (
      searchDate.isAfterOrEqual(startDate) &&
      searchDate.isBeforeOrEqual(endDate)
    ) {
      return period;
    }
  }
  return UNDEFINED;
}

function getFilterValueFromState<T>(
  columnName: string,
  columnIdToFilterState: Record<string, unknown>,
  defaultValue: T,
): T {
  return F.pipe(
    columnIdToFilterState,
    R.get(columnName),
    O.map((items: unknown) => items as T),
    O.getOrElse(F.constant(defaultValue)),
  );
}

function setColumnFilter<T = unknown>(columnName: string, value: T) {
  // returns an update callback to be used within setColumnFiltersState
  return (currentState: ColumnFiltersState): ColumnFiltersState => {
    // DETERMINE IF THE FILTER IS IN THE currentState
    const filterIndex = currentState.findIndex(
      (filter) => filter.id === columnName,
    );
    if (filterIndex !== -1) {
      return currentState.map((filter) => {
        if (filter.id === columnName) {
          return { ...filter, value };
        }

        return filter;
      });
    }

    return [...currentState, { id: columnName, value }];
  };
}

export {
  findMatchingPeriod,
  getFilterValueFromState,
  getPeriodFilterProp,
  processDate,
  setColumnFilter,
};
