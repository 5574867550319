/**
 * HTTP Method: POST
 * Pathname: /middleLayer/renewals/leases/{leaseId}/sendLimitedOffers
 * @function sendLimitedOffers
 * @memberof RenewalsMiddleLayerAPI
 * @param {RenewalsMiddleLayerAPISendLimitedOffersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsMiddleLayerAPISendLimitedOffersPathParams = {
  leaseId: EnderId;
};

type RenewalsMiddleLayerAPISendLimitedOffersSearchParams = {
  token?: string | undefined;
};

type RenewalsMiddleLayerAPISendLimitedOffersBodyParams = {
  processingFees: Partial<Record<EnderId, Money>>;
};

type RenewalsMiddleLayerAPISendLimitedOffersPayload =
  RenewalsMiddleLayerAPISendLimitedOffersPathParams &
    RenewalsMiddleLayerAPISendLimitedOffersSearchParams &
    RenewalsMiddleLayerAPISendLimitedOffersBodyParams;

function sendLimitedOffersUnsafeEffect(
  payload: RenewalsMiddleLayerAPISendLimitedOffersPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/middleLayer/renewals/leases/${leaseId}/sendLimitedOffers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendLimitedOffers(
  payload: RenewalsMiddleLayerAPISendLimitedOffersPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendLimitedOffersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendLimitedOffers };
export type {
  RenewalsMiddleLayerAPISendLimitedOffersBodyParams,
  RenewalsMiddleLayerAPISendLimitedOffersPathParams,
  RenewalsMiddleLayerAPISendLimitedOffersPayload,
  RenewalsMiddleLayerAPISendLimitedOffersSearchParams,
};
