/**
 * HTTP Method: GET
 * Pathname: /accounting/journal-entries/{journalEntryId}/attachments
 * @function getGLJournalEntryAttachments
 * @memberof AccountingAPI
 * @param {AccountingAPIGetGLJournalEntryAttachmentsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetGLJournalEntryAttachmentsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetGLJournalEntryAttachmentsResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIGetGLJournalEntryAttachmentsPathParams = {
  journalEntryId: EnderId;
};

type AccountingAPIGetGLJournalEntryAttachmentsSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetGLJournalEntryAttachmentsPayload =
  AccountingAPIGetGLJournalEntryAttachmentsPathParams &
    AccountingAPIGetGLJournalEntryAttachmentsSearchParams;

function getGLJournalEntryAttachmentsUnsafeEffect(
  payload: AccountingAPIGetGLJournalEntryAttachmentsPayload,
) {
  const { journalEntryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetGLJournalEntryAttachmentsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetGLJournalEntryAttachmentsResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/accounting/journal-entries/${journalEntryId}/attachments`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getGLJournalEntryAttachments(
  payload: AccountingAPIGetGLJournalEntryAttachmentsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetGLJournalEntryAttachmentsResponse> {
  return F.pipe(
    payload,
    getGLJournalEntryAttachmentsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getGLJournalEntryAttachments };
export type {
  AccountingAPIGetGLJournalEntryAttachmentsPathParams,
  AccountingAPIGetGLJournalEntryAttachmentsPayload,
  AccountingAPIGetGLJournalEntryAttachmentsSearchParams,
};
