/**
 * A list of all MoneyTransfers where a Firm is transferring money "to itself".
 * HTTP Method: POST
 * Pathname: /moneyTransfers/internalTransfers
 * @function getInternalTransfers
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetInternalTransfersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InternalTransfersReportRow[]>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { PostInternalTransfersReportRequestInternalTransfersSortBy } from "@ender/shared/generated/ender.api.model";
import type { InternalTransfersReportRow } from "@ender/shared/generated/ender.service.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetInternalTransfersSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetInternalTransfersBodyParams = {
  amountMatch?: SerializesInto<Money> | undefined;
  endDate?: SerializesInto<LocalDate> | undefined;
  firmIds: EnderId[];
  fundIds: EnderId[];
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  propertyIds: EnderId[];
  sortBy?:
    | PostInternalTransfersReportRequestInternalTransfersSortBy
    | undefined;
  startDate?: SerializesInto<LocalDate> | undefined;
};

type PaymentsAPIGetInternalTransfersPayload =
  PaymentsAPIGetInternalTransfersSearchParams &
    PaymentsAPIGetInternalTransfersBodyParams;

function getInternalTransfersUnsafeEffect(
  payload: PaymentsAPIGetInternalTransfersPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InternalTransfersReportRow[]>({
        body,
        decode: unsafeDecodeJsonResponse<InternalTransfersReportRow[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/moneyTransfers/internalTransfers",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInternalTransfers(
  payload: PaymentsAPIGetInternalTransfersPayload,
  options?: { signal?: AbortSignal },
): Promise<InternalTransfersReportRow[]> {
  return F.pipe(
    payload,
    getInternalTransfersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInternalTransfers };
export type {
  PaymentsAPIGetInternalTransfersBodyParams,
  PaymentsAPIGetInternalTransfersPayload,
  PaymentsAPIGetInternalTransfersSearchParams,
};
