import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import { Option as O, pipe } from "effect";
import { forwardRef } from "react";

import { Percent$ } from "@ender/shared/core";
import { castEnum } from "@ender/shared/utils/effect";
import { Color, ColorSchema } from "@ender/shared/utils/theming";

const PercentColorSchema = Schema.Literal(
  ...ColorSchema.pipe(Schema.pickLiteral(Color.red, Color.green)).literals,
  "auto",
  "inverse",
  "none",
);
const PercentColorValues = PercentColorSchema.literals;
type PercentColors = Schema.Schema.Type<typeof PercentColorSchema>;
const PercentColor = castEnum(PercentColorSchema);

type PercentDisplayProps = {
  /**
   * The percent to display
   */
  value?: Percent$ | O.Option<Percent$>;
  /**
   * The text color. Options: "red", "green", "auto", "inverse", "none".
   * @default "auto"
   */
  color?: PercentColors;
  /**
   * Show the "+" for positive numbers.
   * @default false
   */
  showSign?: boolean;
  /**
   * Shows the percentage relative to 100%. For example, 70% will display as -30% and 120% will display as +20%.
   * @default false
   */
  complementary?: boolean;
  /**
   * Number of decimals to render
   * @default 2
   */
  precision?: number;
};

const PercentVariantGenerator = cva([], {
  defaultVariants: {
    color: PercentColor.auto,
    negative: false,
  },
  variants: {
    color: {
      [PercentColor.green]: "text-green-500",
      [PercentColor.red]: "text-red-500",
      [PercentColor.auto]: "text-[--auto-color]",
      [PercentColor.inverse]: "text-[--inverse-color]",
      [PercentColor.none]: [],
    },
    negative: {
      false:
        "[--auto-color:--color-green-500] [--inverse-color:--color-red-500]",
      true: "[--auto-color:--color-red-500] [--inverse-color:--color-green-500]",
    },
  },
});

const PercentDisplay = forwardRef<HTMLSpanElement, PercentDisplayProps>(
  function PercentDisplay(props, ref) {
    const {
      value,
      showSign = false,
      complementary = false,
      precision = 2,
    } = props;

    const _value = O.isOption(value) ? value : O.fromNullable(value);
    const numericValue = O.map(_value, (percent) =>
      complementary ? percent.value - 1 : percent.value,
    );
    const isNegative = numericValue.pipe(
      O.map((v) => v < 0),
      O.getOrElse(() => true),
    );
    const prefix = !showSign || isNegative ? "" : "+";

    return (
      <span
        ref={ref}
        className={PercentVariantGenerator({
          color: props.color,
          negative: isNegative,
        })}>
        {pipe(
          numericValue,
          O.map((v) => prefix + Percent$.of(v).toFixed(precision)),
          O.getOrNull,
        )}
      </span>
    );
  },
);

export { PercentColor, PercentColorSchema, PercentColorValues, PercentDisplay };

export type { PercentColors, PercentDisplayProps };
