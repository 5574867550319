import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const RecurringGLJournalEntryTxTransactionDirectionValues = [
  "DEBIT",
  "CREDIT",
] as const;

const RecurringGLJournalEntryTxTransactionDirectionEffectSchema =
  Schema.Literal(...RecurringGLJournalEntryTxTransactionDirectionValues);

type RecurringGLJournalEntryTxTransactionDirection = Schema.Schema.Type<
  typeof RecurringGLJournalEntryTxTransactionDirectionEffectSchema
>;

const RecurringGLJournalEntryTxTransactionDirectionEnum = castEnum(
  RecurringGLJournalEntryTxTransactionDirectionEffectSchema,
);

function randomRecurringGLJournalEntryTxTransactionDirection(): RecurringGLJournalEntryTxTransactionDirection {
  return rand(RecurringGLJournalEntryTxTransactionDirectionValues);
}

export {
  randomRecurringGLJournalEntryTxTransactionDirection,
  RecurringGLJournalEntryTxTransactionDirectionEffectSchema,
  RecurringGLJournalEntryTxTransactionDirectionEnum,
  RecurringGLJournalEntryTxTransactionDirectionValues,
};
export type { RecurringGLJournalEntryTxTransactionDirection };
