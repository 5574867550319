/**
 * HTTP Method: POST
 * Pathname: /construction/inspections
 * @function createInspection
 * @memberof InspectionAPI
 * @param {InspectionAPICreateInspectionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPICreateInspectionSearchParams = {
  token?: string | undefined;
};

type InspectionAPICreateInspectionBodyParams = {
  inspectionTemplateId: EnderId;
  name: string;
  projectManagerId?: EnderId | undefined;
  propertyId: EnderId;
  unitId: EnderId;
};

type InspectionAPICreateInspectionPayload =
  InspectionAPICreateInspectionSearchParams &
    InspectionAPICreateInspectionBodyParams;

function createInspectionUnsafeEffect(
  payload: InspectionAPICreateInspectionPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/construction/inspections",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createInspection(
  payload: InspectionAPICreateInspectionPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionResponse> {
  return F.pipe(
    payload,
    createInspectionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createInspection };
export type {
  InspectionAPICreateInspectionBodyParams,
  InspectionAPICreateInspectionPayload,
  InspectionAPICreateInspectionSearchParams,
};
