/**
 * HTTP Method: DELETE
 * Pathname: /factors/{factorId}
 * @function deleteFactor
 * @memberof FactorsAPI
 * @param {FactorsAPIDeleteFactorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIDeleteFactorPathParams = {
  factorId: EnderId;
};

type FactorsAPIDeleteFactorSearchParams = {
  token?: string | undefined;
};

type FactorsAPIDeleteFactorPayload = FactorsAPIDeleteFactorPathParams &
  FactorsAPIDeleteFactorSearchParams;

function deleteFactorUnsafeEffect(payload: FactorsAPIDeleteFactorPayload) {
  const { factorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/factors/${factorId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteFactor(
  payload: FactorsAPIDeleteFactorPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteFactorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteFactor };
export type {
  FactorsAPIDeleteFactorPathParams,
  FactorsAPIDeleteFactorPayload,
  FactorsAPIDeleteFactorSearchParams,
};
