import type { TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow } from "@ender/shared/generated/com.ender.middle.response";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import {
  DateCell,
  EllipsisCell,
  MoneyCell,
  asColumnDef,
} from "@ender/shared/ui/table-tanstack";

import { CheckDepositsActionsCell } from "./cells/check-deposits-actions-cell";
import { CheckDepositsPaymentTypeCell } from "./cells/check-deposits-payment-type-cell";

const columns: ColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>[] =
  [
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "amount",
        cell: MoneyCell<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
        header: "Amount",
        size: 126,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "bankAccount.name",
        cell: EllipsisCell<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
        header: "Bank Account",
        maxSize: 200,
        minSize: 118,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "checkNumber",
        header: "Check Number",
        minSize: 125,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "depositDate",
        cell: DateCell<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
        header: "Deposit Date",
        size: 113,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "depositId",
        enableSorting: false,
        header: "Deposit ID",
        maxSize: 150,
        minSize: 100,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "displayPaymentType",
        cell: CheckDepositsPaymentTypeCell,
        header: "Payment Type",
        size: 120,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "memo",
        cell: EllipsisCell<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
        enableSorting: false,
        header: "Memo",
        minSize: 91,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "propertyName",
        cell: EllipsisCell<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
        header: "Property",
        maxSize: 200,
        minSize: 120,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "receiptDate",
        cell: DateCell<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
        header: "Receipt Date",
        size: 113,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "tenantName",
        cell: EllipsisCell<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>,
        header: "Tenant",
        maxSize: 200,
        minSize: 90,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        accessorKey: "unitName",
        header: "Unit",
        maxSize: 90,
        minSize: 65,
      },
    ),
    asColumnDef<TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow>(
      {
        cell: CheckDepositsActionsCell,
        id: "actions",
        size: 80,
      },
    ),
  ];

export { columns };
