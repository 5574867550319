/**
 * HTTP Method: POST
 * Pathname: /buy/attom/assessor.csv.zip
 * @function exportAssessorFile
 * @memberof BuyAPI
 * @param {BuyAPIExportAssessorFilePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { Money, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIExportAssessorFileSearchParams = {
  token?: string | undefined;
};

type BuyAPIExportAssessorFileBodyParams = {
  cbsaCode: string;
  maxTaxAssessedValue: SerializesInto<Money>;
  minTaxAssessedValue: SerializesInto<Money>;
};

type BuyAPIExportAssessorFilePayload = BuyAPIExportAssessorFileSearchParams &
  BuyAPIExportAssessorFileBodyParams;

function exportAssessorFileUnsafeEffect(
  payload: BuyAPIExportAssessorFilePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/buy/attom/assessor.csv.zip",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function exportAssessorFile(
  payload: BuyAPIExportAssessorFilePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    exportAssessorFileUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { exportAssessorFile };
export type {
  BuyAPIExportAssessorFileBodyParams,
  BuyAPIExportAssessorFilePayload,
  BuyAPIExportAssessorFileSearchParams,
};
