/**
 * Gets a filtered set of GL accounts in the property manager's chart of accounts.
 
 Only one of category_flag or category_type can be specified in a request.

 Common usage:
 
 To get income categories search for `categoryType:"INCOME"`
 
 To get all journal-able categories do not specify any search criteria
 
 To get all GL accounts corresponding to tenant charge types, set categoryFlags = ["TENANT_CHARGE_TYPE"]
 * HTTP Method: POST
 * Pathname: /accounting/categories
 * @function getTxCategories
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetTxCategoriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTxCategoriesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetTxCategoriesResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type {
  CategoryFlag,
  GLCategoryCategoryType,
} from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetTxCategoriesSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetTxCategoriesBodyParams = {
  /**
   * If present, filter for categories which have ALL of these flags.
   */
  categoryFlags?: CategoryFlag[] | undefined;
  categoryTypes?: GLCategoryCategoryType[] | undefined;
  /**
   * List of ids of categories to exclude from the return results.
   */
  excludeIds?: EnderId[] | undefined;
  excludeParentCategories?: boolean | undefined;
  /**
 * Set to true to return GLCategories which are the cash account for a bank
                                     account. By default we do not return such categories.
 */
  includeBankAccountCategories?: boolean | undefined;
  /**
 * Optional, toggle to include root categories. By default root categories are not
                                     returned.
 */
  includeRoots?: boolean | undefined;
  /**
   * Return categories with EnderId name containing keyword
   */
  keyword: string;
};

type GeneralLedgerAPIGetTxCategoriesPayload =
  GeneralLedgerAPIGetTxCategoriesSearchParams &
    GeneralLedgerAPIGetTxCategoriesBodyParams;

function getTxCategoriesUnsafeEffect(
  payload: GeneralLedgerAPIGetTxCategoriesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTxCategoriesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetTxCategoriesResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/categories",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTxCategories(
  payload: GeneralLedgerAPIGetTxCategoriesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetTxCategoriesResponse[]> {
  return F.pipe(
    payload,
    getTxCategoriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTxCategories };
export type {
  GeneralLedgerAPIGetTxCategoriesBodyParams,
  GeneralLedgerAPIGetTxCategoriesPayload,
  GeneralLedgerAPIGetTxCategoriesSearchParams,
};
