import { IconEdit, IconInfoCircle, IconX } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useMemo } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { Money$, Percent$ } from "@ender/shared/core";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { PercentDisplay } from "@ender/shared/ds/percent-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import { BuyBoxAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { BuyBoxBuyBoxType } from "@ender/shared/generated/com.ender.buy.model.misc";
import type { PropertyHomeType } from "@ender/shared/generated/ender.model.core.property";
import type { LabelValue } from "@ender/shared/types/label-value";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

/**
 * BuyBoxAPIGetBuyBoxesResponse is any
 */
type BuyBoxDetails = {
  minBeds: number;
  minBaths: number;
  minSqft: number;
  minYearBuilt: number;
  minRent: Money$.Money;
  minListPrice: Money$.Money;
  maxListPrice: Money$.Money;
  minMedianIncome: Money$.Money;
  minCapRate: string;
  minSchoolRating: string;
  propertyTypes: PropertyHomeType[];
  excludeCities: string[];
  excludeZipcodes: string[];
  name: string;
  marketId: EnderId;
  id: EnderId;
  type: BuyBoxBuyBoxType;
};

type BuyBoxProps = {
  buyBox: BuyBoxDetails;
  fetchBuyBoxes: () => void;
  toggleBuyBoxModal: (buyBox: BuyBoxDetails) => void;
  propertyTypeOptions: LabelValue<PropertyHomeType>[];
};

function BuyBox({
  buyBox,
  fetchBuyBoxes,
  toggleBuyBoxModal,
  propertyTypeOptions,
}: BuyBoxProps) {
  const confirmation = useConfirmationContext();

  function openEditBuyBoxForm() {
    toggleBuyBoxModal(buyBox);
  }

  const { mutate: _deleteBuyBox } = useMutation({
    mutationFn: async () => {
      await confirmation({
        confirmButtonLabel: "Delete",
        title: "Are you sure you would like to delete this Buy Box?",
      });
      await BuyBoxAPI.deleteBuyBox({ buyBoxId: buyBox.id });
      showSuccessNotification({ message: `${buyBox.name} Buy Box deleted` });
      fetchBuyBoxes();
    },
    mutationKey: ["BuyBoxAPI.deleteBuyBox"] as const,
  });

  const propertyTypesValue = useMemo(() => {
    if (P.isNullable(buyBox.propertyTypes)) {
      return "-";
    }
    if (buyBox.propertyTypes.length === propertyTypeOptions.length) {
      return "All";
    }
    return (
      <Tooltip label={buyBox.propertyTypes.join(", ")}>
        <IconInfoCircle />
      </Tooltip>
    );
  }, [buyBox.propertyTypes, propertyTypeOptions]);

  return (
    <Card shrink>
      <Stack spacing={Spacing.sm}>
        <Group justify={Justify.between} align={Align.center}>
          <H4>{buyBox.name}</H4>
          <Group
            spacing={Spacing.sm}
            justify={Justify.end}
            align={Align.center}>
            <ActionIcon label="Edit Buy Box" onClick={openEditBuyBoxForm}>
              <IconEdit />
            </ActionIcon>
            <ActionIcon label="Delete Buy Box" onClick={() => _deleteBuyBox()}>
              <IconX />
            </ActionIcon>
          </Group>
        </Group>
        <Stack spacing={Spacing.none}>
          <Tuple
            label="Type"
            value={convertSnakeCaseToTitleCase(buyBox.type)}
          />
          <Tuple label="Beds" value={buyBox.minBeds} />
          <Tuple label="Baths" value={buyBox.minBaths} />
          <Tuple label="Sq Ft" value={buyBox.minSqft} />
          <Tuple label="Year Built" value={buyBox.minYearBuilt} />
          <Tuple
            label="UW Rent"
            value={<MoneyDisplay value={Money$.parse(buyBox.minRent)} />}
          />
          <Tuple
            label="Min List Price"
            value={<MoneyDisplay value={Money$.parse(buyBox.minListPrice)} />}
          />
          <Tuple
            label="Max List Price"
            value={<MoneyDisplay value={Money$.parse(buyBox.maxListPrice)} />}
          />
          <Tuple
            label="Median Income"
            value={
              <MoneyDisplay value={Money$.parse(buyBox.minMedianIncome)} />
            }
          />
          <Tuple
            label="Cap Rate"
            value={<PercentDisplay value={Percent$.of(buyBox.minCapRate)} />}
          />
          <Tuple label="School Score" value={buyBox.minSchoolRating} />
          <Tuple label="Property Types" value={propertyTypesValue} />
          <Tuple
            label="Excluded Cities"
            value={
              A.isNonEmptyArray(buyBox?.excludeCities)
                ? buyBox.excludeCities.sort().join(", ")
                : "None"
            }
          />
          <Tuple
            label="Excluded Zip Codes"
            value={
              A.isNonEmptyArray(buyBox?.excludeZipcodes)
                ? buyBox.excludeZipcodes.sort().join(", ")
                : "None"
            }
          />
        </Stack>
      </Stack>
    </Card>
  );
}

export { BuyBox };
export type { BuyBoxDetails };
