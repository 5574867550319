import { useQuery } from "@tanstack/react-query";

import type { EnderId } from "@ender/shared/core";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type {
  GetApprovalProcessResponse,
  GetApprovalProcessesResponse as _GetApprovalProcessesResponse,
} from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";

type GetApprovalProcessesResponse = Pick<
  _GetApprovalProcessesResponse,
  "approvalProcessById" | "defaultApprovalProcess"
> & {
  approvalProcessById: Record<EnderId, GetApprovalProcessResponse>;
};

type UseInvoiceApprovalProcessesProps = {
  disabled?: boolean;
  invoice?: InvoiceSerializerInvoiceResponse;
  invoiceType?: InvoiceInvoiceType;
};

function useInvoiceApprovalProcesses({
  disabled = false,
  invoice,
  invoiceType,
}: UseInvoiceApprovalProcessesProps = {}) {
  const { data, isFetching } = useQuery({
    enabled: !disabled,
    queryFn: ({ signal }) => {
      const currentInvoiceType = invoice?.type;
      const _invoiceType = currentInvoiceType ?? invoiceType;
      return ApprovalsAPI.searchApprovalProcesses(
        {
          type:
            _invoiceType === InvoiceInvoiceTypeEnum.PAYABLE
              ? ApprovalProcessTypeEnum.PAYABLE
              : ApprovalProcessTypeEnum.RECEIVABLE,
        },
        { signal },
      );
    },
    queryKey: [
      "ApprovalsAPI.searchApprovalProcesses",
      invoice?.type,
      invoiceType,
    ] as const,
  });

  return { data, isFetching };
}

export { useInvoiceApprovalProcesses };
export type { GetApprovalProcessesResponse };
