/**
 * HTTP Method: GET
 * Pathname: /files/{modelType}/{modelId}/allFiles.zip
 * @function downloadFilesAsZip
 * @memberof WebserverFilesAPI
 * @param {WebserverFilesAPIDownloadFilesAsZipPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type WebserverFilesAPIDownloadFilesAsZipPathParams = {
  modelType: ModelType;
  modelId: EnderId;
};

type WebserverFilesAPIDownloadFilesAsZipSearchParams = {
  token?: string | undefined;
};

type WebserverFilesAPIDownloadFilesAsZipPayload =
  WebserverFilesAPIDownloadFilesAsZipPathParams &
    WebserverFilesAPIDownloadFilesAsZipSearchParams;

function downloadFilesAsZipUnsafeEffect(
  payload: WebserverFilesAPIDownloadFilesAsZipPayload,
) {
  const { modelType, modelId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/files/${modelType}/${modelId}/allFiles.zip`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function downloadFilesAsZip(
  payload: WebserverFilesAPIDownloadFilesAsZipPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    downloadFilesAsZipUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { downloadFilesAsZip };
export type {
  WebserverFilesAPIDownloadFilesAsZipPathParams,
  WebserverFilesAPIDownloadFilesAsZipPayload,
  WebserverFilesAPIDownloadFilesAsZipSearchParams,
};
