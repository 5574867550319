import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import { AppProviders } from "@ender/shared/contexts/app-providers";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { EffectRuntimeBrowserInitializer } from "@ender/shared/initializers/effect-runtime-browser-initializer";
import { queryClient } from "@ender/shared/utils/query-client";

import { EnderRoutes } from "../(routes)/system/ender.routes";
import { useBrowserFeaturesSupportedCheck } from "./use-browser-features-supported-check";

import styles from "./app.module.css";

function AppRouter() {
  useBrowserFeaturesSupportedCheck();

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <EnderRoutes />
    </Suspense>
  );
}

function App() {
  return (
    <EffectRuntimeBrowserInitializer>
      <BrowserRouter>
        <AppProviders queryClient={queryClient}>
          <div className={styles.app}>
            <AppRouter />
          </div>
        </AppProviders>
      </BrowserRouter>
    </EffectRuntimeBrowserInitializer>
  );
}

export { App };
