import { Predicate as P } from "effect";
import { useState } from "react";

type UseUncontrolledInput<T> = {
  /** Value for controlled state */
  value?: T;

  /** Initial value for uncontrolled state */
  defaultValue?: T;

  /** Final value for uncontrolled state when value and defaultValue are not provided */
  finalValue?: T;

  /** Controlled state onChange handler */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: T, ...payload: any[]) => void;
};

function useUncontrolled<T>({
  value,
  defaultValue,
  finalValue,
  onChange = () => {},
}: UseUncontrolledInput<T>): [
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: T, ...payload: any[]) => void,
  boolean,
] {
  const [uncontrolledValue, setUncontrolledValue] = useState(
    !P.isNullable(defaultValue) ? defaultValue : finalValue,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUncontrolledChange = (val: T, ...payload: any[]) => {
    setUncontrolledValue(val);
    onChange?.(val, ...payload);
  };

  if (!P.isNullable(value)) {
    return [value as T, onChange, true];
  }

  return [uncontrolledValue as T, handleUncontrolledChange, false];
}

export { useUncontrolled };
