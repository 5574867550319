import { IconPlus } from "@tabler/icons-react";
import { useHistory } from "react-router-dom";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonSize, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { NewTaskForm } from "./new-task-form";

type Props = {
  asIcon?: boolean;
  onSuccess?: () => void;
  propertyId?: EnderId;
  unitId?: EnderId;
};

function NewTaskButton({
  asIcon = false,
  onSuccess,
  propertyId = UNDEFINED,
  unitId = UNDEFINED,
}: Props) {
  const [newTaskModalOpened, newTaskModalHandlers] = useBoolean(false);

  const history = useHistory();

  function handleSuccess(taskId: EnderId) {
    if (onSuccess) {
      onSuccess();
    }
    showSuccessNotification({
      title: "Your task was successfully created!",
      message: (
        <Group justify={Justify.between}>
          <Button
            variant={ButtonVariant.transparent}
            onClick={newTaskModalHandlers.setTrue}>
            Create Another Task
          </Button>
          <Button
            variant={ButtonVariant.transparent}
            onClick={() => history.push(`/tasks/${taskId}`)}>
            View Task
          </Button>
        </Group>
      ),
    });
    newTaskModalHandlers.setFalse();
  }

  function handleError(err: unknown) {
    fail(err instanceof Error ? err : String(err));
    newTaskModalHandlers.setFalse();
  }

  return (
    <>
      {asIcon ? (
        <ActionIcon size={ButtonSize.sm} onClick={newTaskModalHandlers.setTrue}>
          <IconPlus />
        </ActionIcon>
      ) : (
        <Button onClick={newTaskModalHandlers.setTrue}>New Task</Button>
      )}
      <Modal
        title="Create New Task"
        size={ModalSize.sm}
        opened={newTaskModalOpened}
        onClose={newTaskModalHandlers.setFalse}>
        <NewTaskForm
          onSuccess={handleSuccess}
          onFail={handleError}
          propertyId={propertyId}
          unitId={unitId}
        />
      </Modal>
    </>
  );
}

export { NewTaskButton };
