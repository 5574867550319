/**
 * HTTP Method: POST
 * Pathname: /text-templates/bulk-render-lease
 * @function bulkRenderLeaseTemplates
 * @memberof TemplatesAPI
 * @param {TemplatesAPIBulkRenderLeaseTemplatesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BulkRenderedTemplateResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BulkRenderedTemplateResponse } from "@ender/shared/generated/ender.api.documents.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TemplatesAPIBulkRenderLeaseTemplatesSearchParams = {
  token?: string | undefined;
};

type TemplatesAPIBulkRenderLeaseTemplatesBodyParams = {
  leaseId: EnderId;
  pmSignerId?: EnderId | undefined;
  templateIds: EnderId[];
  withMargin?: boolean | undefined;
};

type TemplatesAPIBulkRenderLeaseTemplatesPayload =
  TemplatesAPIBulkRenderLeaseTemplatesSearchParams &
    TemplatesAPIBulkRenderLeaseTemplatesBodyParams;

function bulkRenderLeaseTemplatesUnsafeEffect(
  payload: TemplatesAPIBulkRenderLeaseTemplatesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BulkRenderedTemplateResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BulkRenderedTemplateResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/text-templates/bulk-render-lease",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function bulkRenderLeaseTemplates(
  payload: TemplatesAPIBulkRenderLeaseTemplatesPayload,
  options?: { signal?: AbortSignal },
): Promise<BulkRenderedTemplateResponse> {
  return F.pipe(
    payload,
    bulkRenderLeaseTemplatesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { bulkRenderLeaseTemplates };
export type {
  TemplatesAPIBulkRenderLeaseTemplatesBodyParams,
  TemplatesAPIBulkRenderLeaseTemplatesPayload,
  TemplatesAPIBulkRenderLeaseTemplatesSearchParams,
};
