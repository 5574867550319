import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useCallback } from "react";

import { SearchInput, searchProperties } from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { PropertiesAPIGetUnitsForPropertyPayload } from "@ender/shared/generated/ender.api.core";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { GetUnitsForPropertyResponse } from "@ender/shared/generated/ender.api.core.response";
import type { LeasingAPIUpdateLeadPayload } from "@ender/shared/generated/ender.api.leasing";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetLeadResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { SelectOption } from "@ender/shared/generated/ender.model.forms.inputs";
import { hydratePropertyWithFriendlyId } from "@ender/shared/ui/search-input";

import { ListingInfoWidget } from "../../widgets/listing-info/listing-info";

const PropertyOfInterestFormSchema = Schema.Struct({
  propertyId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Property is required" }),
  ),
  unitId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
});

type PropertyOfInterestFormValues = Schema.Schema.Type<
  typeof PropertyOfInterestFormSchema
>;

type PropertyOfInterestProps = {
  prospect: GetLeadResponse;
  onProspectUpdated: () => void;
};

function PropertyOfInterest(props: PropertyOfInterestProps) {
  const { prospect, onProspectUpdated } = props;

  const form = useEffectSchemaForm({
    defaultValues: {
      propertyId: O.some(prospect.propertyId),
      unitId: O.fromNullable(prospect.unitId),
    },
    schema: PropertyOfInterestFormSchema,
  });

  const [formPropertyId, formUnitId] = form.watch(["propertyId", "unitId"]);

  const { data: units = [] } = useQuery<
    GetUnitsForPropertyResponse[],
    unknown,
    SelectOption[],
    [string, PropertiesAPIGetUnitsForPropertyPayload]
  >({
    queryKey: [
      "PropertiesAPI.getUnitsForProperty",
      {
        propertyId: O.getOrElse(formPropertyId, () => prospect.propertyId),
        includeLeaseDetails: false,
      },
    ] as const,
    queryFn: ({ queryKey: [, params] }) =>
      PropertiesAPI.getUnitsForProperty(params),
    enabled: O.isSome(formPropertyId),
    select: (data) =>
      data.map((unit) => ({
        label: unit.name,
        value: unit.id,
      })),
  });

  const { mutateAsync: updateLead, isLoading: isUpdatingLead } = useMutation({
    mutationFn: LeasingAPI.updateLead,
    mutationKey: ["LeasingAPI.updateLead"] as const,
  });

  const onSubmit = useCallback(
    async (values: PropertyOfInterestFormValues) => {
      const payload: LeasingAPIUpdateLeadPayload = {
        leadId: prospect.leadId,
        propertyId: O.getOrUndefined(values.propertyId),
        unitId: O.getOrUndefined(values.unitId),
      };
      await updateLead(payload);
      await onProspectUpdated();
    },
    [prospect.leadId, updateLead, onProspectUpdated],
  );

  const onPropertyChange = useCallback(
    (value: O.Option<EnderId>) => {
      form.setValue("unitId", O.none());
      form.setValue("propertyId", value);
    },
    [form],
  );

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        <Stack>
          <SearchInput<EnderId>
            clearable
            name="propertyId"
            search={searchProperties}
            hydrate={hydratePropertyWithFriendlyId}
            label="Property Interested In"
            modelType={ModelTypeEnum.PROPERTY}
            value={formPropertyId}
            onChange={onPropertyChange}
          />
          {O.isSome(formPropertyId) && units?.length > 1 && (
            <FormSelect form={form} name="unitId" data={units} clearable />
          )}
          <ListingInfoWidget
            propertyId={O.getOrElse(formPropertyId, () => prospect.propertyId)}
            unitId={O.getOrUndefined(formUnitId)}
            borderless={false}
          />
        </Stack>
        <Group justify={Justify.end}>
          <Button
            variant={ButtonVariant.transparent}
            disabled={!form.formState.isDirty || isUpdatingLead}
            onClick={() => form.reset()}>
            Cancel
          </Button>
          <Button type="submit" loading={isUpdatingLead}>
            Save Prospect
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { PropertyOfInterest };
