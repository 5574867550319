/**
 * HTTP Method: POST
 * Pathname: /announcements/getCountForTenantAnnouncement
 * @function getCountForTenantAnnouncement
 * @memberof AnnouncementAPI
 * @param {AnnouncementAPIGetCountForTenantAnnouncementPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<number>}
 */
import { Effect, Function as F } from "effect";

import type { FilterTenantAnnouncementRequest } from "@ender/shared/generated/ender.api.comm.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AnnouncementAPIGetCountForTenantAnnouncementSearchParams = {
  token?: string | undefined;
};

type AnnouncementAPIGetCountForTenantAnnouncementBodyParams = {
  request: FilterTenantAnnouncementRequest;
};

type AnnouncementAPIGetCountForTenantAnnouncementPayload =
  AnnouncementAPIGetCountForTenantAnnouncementSearchParams &
    AnnouncementAPIGetCountForTenantAnnouncementBodyParams;

function getCountForTenantAnnouncementUnsafeEffect(
  payload: AnnouncementAPIGetCountForTenantAnnouncementPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, number>({
        body,
        decode: unsafeDecodeJsonResponse<number>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/announcements/getCountForTenantAnnouncement",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCountForTenantAnnouncement(
  payload: AnnouncementAPIGetCountForTenantAnnouncementPayload,
  options?: { signal?: AbortSignal },
): Promise<number> {
  return F.pipe(
    payload,
    getCountForTenantAnnouncementUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCountForTenantAnnouncement };
export type {
  AnnouncementAPIGetCountForTenantAnnouncementBodyParams,
  AnnouncementAPIGetCountForTenantAnnouncementPayload,
  AnnouncementAPIGetCountForTenantAnnouncementSearchParams,
};
