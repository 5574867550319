/**
 * HTTP Method: POST
 * Pathname: /text-templates/{templateId}/unarchive
 * @function unarchiveTemplate
 * @memberof TemplatesAPI
 * @param {TemplatesAPIUnarchiveTemplatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TemplatesAPIUnarchiveTemplatePathParams = {
  templateId: EnderId;
};

type TemplatesAPIUnarchiveTemplateSearchParams = {
  token?: string | undefined;
};

type TemplatesAPIUnarchiveTemplatePayload =
  TemplatesAPIUnarchiveTemplatePathParams &
    TemplatesAPIUnarchiveTemplateSearchParams;

function unarchiveTemplateUnsafeEffect(
  payload: TemplatesAPIUnarchiveTemplatePayload,
) {
  const { templateId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/text-templates/${templateId}/unarchive`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function unarchiveTemplate(
  payload: TemplatesAPIUnarchiveTemplatePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    unarchiveTemplateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { unarchiveTemplate };
export type {
  TemplatesAPIUnarchiveTemplatePathParams,
  TemplatesAPIUnarchiveTemplatePayload,
  TemplatesAPIUnarchiveTemplateSearchParams,
};
