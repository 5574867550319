import { Schema as S } from "@effect/schema";
import { Option as O, Predicate as P, Record as R } from "effect";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import { FormRadioGroup } from "@ender/shared/ds/radio-group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { BatchedInvoiceActionResponseStatus } from "@ender/shared/generated/ender.model.accounting.response";
import { BatchedInvoiceActionResponseStatusEnum } from "@ender/shared/generated/ender.model.accounting.response";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

import type { ApprovalProcessHybridId, ParameterType } from "../types";
import {
  ModeEnum,
  ModeSchema,
  NEW_APPROVAL_PROCESS_HYBRID_ID,
  PARAMETER_VALUES,
  ParameterEnum,
  ParameterSchema,
} from "../types";

function isValidInvoiceAmount(value: O.Option<Money$.Money>): boolean {
  return Money$.isPositive(O.getOrElse(value, () => Money$.zero()));
}

const ApprovalProcessEstimateRuleFiltersFormSchema = S.Struct({
  maxAmount: MoneyEffectSchema.pipe(S.OptionFromSelf),
  minAmount: MoneyEffectSchema.pipe(S.OptionFromSelf),
  mode: ModeSchema.pipe(S.OptionFromSelf),
  parameter: ParameterSchema.pipe(S.OptionFromSelf),
  trailingPeriodNumDays: S.Number.pipe(S.OptionFromSelf),
  trailingPeriodThreshold: MoneyEffectSchema.pipe(S.OptionFromSelf),
}).pipe(
  S.filter((schema) => {
    const issues: S.FilterIssue[] = [];
    // if not cumulative(trailing period), parameter is less than, and minAmount is not valid
    if (
      O.getOrUndefined(schema.mode) !== ModeEnum.CUMULATIVE &&
      O.getOrUndefined(schema.parameter) === ParameterEnum.LESS_THAN &&
      !isValidInvoiceAmount(schema.maxAmount)
    ) {
      issues.push({
        message:
          "Please enter a valid number greater than zero for maximum amount.",
        path: ["maxAmount"],
      });
    }
    // if not cumulative(trailing period), parameter is more than, and maxAmount is not valid
    if (
      O.getOrUndefined(schema.mode) !== ModeEnum.CUMULATIVE &&
      O.getOrUndefined(schema.parameter) === ParameterEnum.MORE_THAN &&
      !isValidInvoiceAmount(schema.minAmount)
    ) {
      issues.push({
        message:
          "Please enter a valid number equal to or greater than zero for minimum amount.",
        path: ["minAmount"],
      });
    }
    // if not cumulative(trailing period), parameter is between, and maxAmount is not greater than minAmount
    if (
      O.getOrUndefined(schema.mode) !== ModeEnum.CUMULATIVE &&
      O.getOrUndefined(schema.parameter) === ParameterEnum.BETWEEN &&
      Money$.isNegative(
        Money$.subtract(
          O.getOrElse(schema.maxAmount, () => Money$.zero()),
          O.getOrElse(schema.minAmount, () => Money$.zero()),
        ),
      )
    ) {
      issues.push({
        message: "Maximum amount must be greater than minimum amount.",
        path: ["maxAmount"],
      });
    }
    // if it IS cumulative(trailing period), both trailingPeriodThreshold and trailingPeriodNumDays must be provided
    if (
      O.getOrUndefined(schema.mode) === ModeEnum.CUMULATIVE &&
      (O.isNone(schema.trailingPeriodThreshold) ||
        O.isNone(schema.trailingPeriodNumDays))
    ) {
      issues.push({
        message:
          "Please provide both trailing period threshold and number of days.",
        path: ["trailingPeriodThreshold", "trailingPeriodNumDays"],
      });
    }
    return issues;
  }),
);

type ApprovalProcessEstimateRuleFiltersFormInput = S.Schema.Encoded<
  typeof ApprovalProcessEstimateRuleFiltersFormSchema
>;
type ApprovalProcessEstimateRuleFiltersFormOutput = S.Schema.Type<
  typeof ApprovalProcessEstimateRuleFiltersFormSchema
>;

function getInitialValues(
  approvalProcess: GetApprovalProcessResponse & { id: ApprovalProcessHybridId },
): ApprovalProcessEstimateRuleFiltersFormInput {
  // get parameter from approvalProcess
  let parameter: O.Option<ParameterType> = O.some(ParameterEnum.MORE_THAN);

  // if no existing process, return default values
  if (approvalProcess?.id === NEW_APPROVAL_PROCESS_HYBRID_ID) {
    return {
      maxAmount: Money$.parse(0),
      minAmount: Money$.parse(0),
      parameter: parameter,
      mode: O.some(ModeEnum.FIXED),
      trailingPeriodNumDays: O.none(),
      trailingPeriodThreshold: O.none(),
    };
  }

  // if a Cumulative budget, return trailingPeriodThreshold and trailingPeriodNumDays
  if (P.isNotNullable(approvalProcess.trailingPeriodThreshold)) {
    return {
      mode: O.some(ModeEnum.CUMULATIVE),
      trailingPeriodNumDays: O.fromNullable(
        approvalProcess.trailingPeriodNumDays,
      ),
      trailingPeriodThreshold: Money$.parse(
        approvalProcess.trailingPeriodThreshold,
      ),
      maxAmount: O.none(),
      minAmount: O.none(),
      parameter: O.none(),
    };
  }

  // else return minAmount, maxAmount, and computed parameter
  const { minAmount, maxAmount } = approvalProcess;
  if (P.isNotUndefined(minAmount) && P.isNotUndefined(maxAmount)) {
    parameter = O.some(ParameterEnum.BETWEEN);
  } else if (P.isNotUndefined(maxAmount)) {
    parameter = O.some(ParameterEnum.LESS_THAN);
  } else {
    parameter = O.some(ParameterEnum.MORE_THAN);
  }

  return {
    maxAmount: Money$.parse(approvalProcess?.maxAmount),
    minAmount: Money$.parse(approvalProcess?.minAmount),
    mode: O.some(ModeEnum.FIXED),
    parameter,
    trailingPeriodNumDays: O.none(),
    trailingPeriodThreshold: O.none(),
  };
}

/**
 * This allows us to retain user-set values in the form object
 * when the user switches "parameter" from "between" to "less than" or "more than"
 * but not accidentally send the wrong payload to the API.
 */
function getAmountPartOfPayload({
  mode,
  maxAmount,
  minAmount,
  parameter,
  trailingPeriodThreshold,
  trailingPeriodNumDays,
}: ApprovalProcessEstimateRuleFiltersFormOutput) {
  if (O.getOrUndefined(mode) === ModeEnum.CUMULATIVE) {
    return {
      trailingPeriodNumDays: O.getOrUndefined(trailingPeriodNumDays),
      trailingPeriodThreshold: O.getOrUndefined(
        trailingPeriodThreshold,
      )?.toJSON(),
    };
  }

  if (O.getOrUndefined(parameter) === ParameterEnum.BETWEEN) {
    return {
      maxAmount: O.getOrUndefined(maxAmount)?.toJSON(),
      minAmount: O.getOrUndefined(minAmount)?.toJSON(),
    };
  } else if (O.getOrUndefined(parameter) === ParameterEnum.LESS_THAN) {
    return {
      maxAmount: O.getOrUndefined(maxAmount)?.toJSON(),
    };
  } else {
    return {
      minAmount: O.getOrUndefined(minAmount)?.toJSON(),
    };
  }
}

function getPayloadCommonToCreateAndUpdate(
  values: ApprovalProcessEstimateRuleFiltersFormOutput,
) {
  const {
    minAmount,
    maxAmount,
    parameter,
    mode,
    trailingPeriodNumDays,
    trailingPeriodThreshold,
  } = values;

  return {
    ...getAmountPartOfPayload({
      maxAmount,
      minAmount,
      mode,
      parameter,
      trailingPeriodNumDays,
      trailingPeriodThreshold,
    }),
    payableTypes: [],
  };
}

async function handleResponse(
  response: {
    status: BatchedInvoiceActionResponseStatus;
    overlappingApprovalProcessId?: string;
  },
  successMessage: string,
  onSuccess: () => Promise<void>,
) {
  if (response?.status === BatchedInvoiceActionResponseStatusEnum.FAILURE) {
    let message = response.status as string;
    if (response.overlappingApprovalProcessId) {
      message += ` overlaps with existing approval process (id: ${response.overlappingApprovalProcessId})`;
    }
    fail(message);
  } else {
    showSuccessNotification({ message: successMessage });
    if (onSuccess) {
      await onSuccess();
    }
  }
}

type ApprovalProcessEstimateRuleFiltersFormProps = {
  approvalProcess: GetApprovalProcessResponse & { id: ApprovalProcessHybridId };
  type: ApprovalProcessType;
  ownershipGroupId: EnderId;
  onCreateSuccess: ({
    approvalProcessId,
  }: {
    approvalProcessId: ApprovalProcessHybridId;
  }) => void;
  refetchData: () => Promise<void>;
};

function ApprovalProcessEstimateRuleFiltersForm({
  approvalProcess,
  type,
  ownershipGroupId,
  onCreateSuccess,
  refetchData,
}: ApprovalProcessEstimateRuleFiltersFormProps) {
  const isUpdate = useMemo(
    () =>
      approvalProcess?.id &&
      approvalProcess?.id !== NEW_APPROVAL_PROCESS_HYBRID_ID,
    [approvalProcess],
  );

  const defaultValues = useMemo(
    () => getInitialValues(approvalProcess),
    [approvalProcess],
  );

  const form = useEffectSchemaForm({
    defaultValues,
    schema: ApprovalProcessEstimateRuleFiltersFormSchema,
  });

  const [mode, parameter] = useWatch({
    control: form.control,
    name: ["mode", "parameter"],
  });

  // lazy useEffect
  useEffect(() => {
    const {
      mode: _mode,
      parameter: _parameter,
      ...initialValuesSansMode
    } = defaultValues;
    R.toEntries(initialValuesSansMode).forEach(([key, value]) => {
      form.setValue(key, value);
    });
  }, [mode, form.setValue, defaultValues, form]);

  async function handleSubmit(
    values: ApprovalProcessEstimateRuleFiltersFormOutput,
  ) {
    const commonPayload = getPayloadCommonToCreateAndUpdate(values);
    if (isUpdate) {
      try {
        const response = await ApprovalsAPI.updateApprovalProcess({
          approvalProcessId: approvalProcess.id as EnderId,
          ...commonPayload,
          payableTypes: [],
        });
        // Added responseStatus check because response is of type ApprovalProcess which has no status
        // BatchedInvoiceActionResponseStatusEnum is not domain appropriate here
        const responseStatus = P.isNotNullable(response)
          ? { status: BatchedInvoiceActionResponseStatusEnum.SUCCESS }
          : { status: BatchedInvoiceActionResponseStatusEnum.FAILURE };
        handleResponse(
          responseStatus,
          "Successfully updated rule",
          refetchData,
        );
      } catch (err) {
        fail(err);
      }
    } else {
      try {
        const response = await ApprovalsAPI.createApprovalProcess({
          ...commonPayload,
          ownershipGroupId,
          type,
          payableTypes: [],
        });
        await refetchData();
        // Added responseStatus check because response is of type ApprovalProcess which has no status
        // BatchedInvoiceActionResponseStatusEnum is not domain appropriate here
        const responseStatus = P.isNotNullable(response)
          ? { status: BatchedInvoiceActionResponseStatusEnum.SUCCESS }
          : { status: BatchedInvoiceActionResponseStatusEnum.FAILURE };
        handleResponse(
          responseStatus,
          "Successfully created new rule",
          async () => {
            await refetchData();
            onCreateSuccess({ approvalProcessId: response.id });
          },
        );
      } catch (err) {
        fail(err);
      }
    }
  }

  const showFixedAmountFields = useMemo(
    () => O.getOrUndefined(mode) === ModeEnum.FIXED,
    [mode],
  );
  const showCumulativeFields = useMemo(
    () => O.getOrUndefined(mode) === ModeEnum.CUMULATIVE,
    [mode],
  );

  const showMoreThan = useMemo(() => {
    return (
      O.getOrUndefined(parameter) === ParameterEnum.MORE_THAN ||
      O.getOrUndefined(parameter) === ParameterEnum.BETWEEN
    );
  }, [parameter]);

  const showLessThan = useMemo(() => {
    return (
      O.getOrUndefined(parameter) === ParameterEnum.LESS_THAN ||
      O.getOrUndefined(parameter) === ParameterEnum.BETWEEN
    );
  }, [parameter]);

  const amountType =
    type === ApprovalProcessTypeEnum.ESTIMATE ? "Estimate" : "Invoice amount";

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <Group spacing={Spacing.xs} align={Align.start}>
          <FormRadioGroup
            form={form}
            name="mode"
            data={[
              {
                label: "Amount per Task",
                value: ModeEnum.FIXED,
              },
              { label: "Cumulative Budget", value: ModeEnum.CUMULATIVE },
            ]}
          />
        </Group>
        {showFixedAmountFields && (
          <Group spacing={Spacing.xs} align={Align.center}>
            <span>{amountType} is</span>
            <FormSelect
              form={form}
              name="parameter"
              aria-label="Select Parameter"
              clearable={false}
              data={PARAMETER_VALUES.map((value) => ({
                label: convertSnakeCaseToTitleCase(value),
                value,
              }))}
              placeholder="Select Parameter"
            />
            {showMoreThan && (
              <FormMoneyInput
                form={form}
                name="minAmount"
                aria-label="Minimum Amount"
                placeholder="Minimum Amount"
              />
            )}
            {O.getOrUndefined(parameter) === ParameterEnum.BETWEEN && (
              <span>and</span>
            )}
            {showLessThan && (
              <FormMoneyInput
                form={form}
                name="maxAmount"
                aria-label="Maximum Amount"
                placeholder="Maximum Amount"
              />
            )}
          </Group>
        )}
        {showCumulativeFields && (
          <Group spacing={Spacing.xs} align={Align.center}>
            <span>More than</span>
            <FormMoneyInput
              form={form}
              name="trailingPeriodThreshold"
              placeholder="Cumulative Amount"
            />
            <span>in</span>
            <FormNumberInput
              form={form}
              name="trailingPeriodNumDays"
              placeholder="Number of Days"
              precision={0}
            />
            <span>days</span>
          </Group>
        )}
        <div className="right">
          <Button type="submit">{isUpdate ? "Update" : "Create"}</Button>
        </div>
      </Stack>
    </Form>
  );
}

export { ApprovalProcessEstimateRuleFiltersForm };
