import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { Function as F, Option as O } from "effect";
import { useContext, useState } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import { EnderIdFormSchema, LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { Lease } from "@ender/shared/generated/ender.model.leasing";
import {
  LeaseMoveOutReasonEffectSchema,
  LeaseMoveOutReasonValues,
} from "@ender/shared/generated/ender.model.leasing";
import { fail } from "@ender/shared/utils/error";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

const LeaseMoveOutOptions = LeaseMoveOutReasonValues.map((value) => ({
  label: convertSnakeCaseToTitleCase(value),
  value,
}));

const MoveOutDateFormSchema = Schema.Struct({
  leaseId: EnderIdFormSchema,
  expectedMoveOutDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((v): v is O.Option<LocalDate$.LocalDate> => O.isSome(v), {
      message: () => "Please choose a Move Out Date.",
    }),
  ),
  noticeOfMoveOutDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((v): v is O.Option<LocalDate$.LocalDate> => O.isSome(v), {
      message: () => "Please choose a Notice Provided Date.",
    }),
    Schema.filter(
      (input) =>
        O.exists(input, (value) => value.isBeforeOrEqual(LocalDate$.today())),
      {
        message: () => "Notice Provided Date cannot be in the future.",
      },
    ),
  ),
  moveOutReason: LeaseMoveOutReasonEffectSchema.pipe(Schema.OptionFromSelf),
});

type MoveOutDateFormInput = Schema.Schema.Encoded<typeof MoveOutDateFormSchema>;
type MoveOutDateFormOutput = Schema.Schema.Type<typeof MoveOutDateFormSchema>;

type MoveOutDateFormProps = {
  lease: Lease;
  onSuccess?: () => void;
};

function MoveOutDateForm(props: MoveOutDateFormProps) {
  const {
    lease: {
      id: leaseId,
      expectedMoveOutDate,
      noticeOfMoveOutDate,
      moveOutReason,
    },
    onSuccess = F.constVoid,
  } = props;
  const [loading, setLoading] = useState(false);
  const confirmation = useContext(ConfirmationContext);

  const form = useForm<MoveOutDateFormInput>({
    defaultValues: {
      leaseId,
      expectedMoveOutDate: LocalDate$.parse(
        expectedMoveOutDate ?? LocalDate$.today(),
      ),
      noticeOfMoveOutDate: LocalDate$.parse(
        noticeOfMoveOutDate ?? LocalDate$.today(),
      ),
      moveOutReason: O.fromNullable(moveOutReason),
    },
    mode: "onSubmit",
    resolver: effectTsResolver(MoveOutDateFormSchema),
  });

  async function handleSubmit(values: MoveOutDateFormOutput) {
    setLoading(true);
    const expectedMoveOutDate = O.getOrThrow(values.expectedMoveOutDate);
    const noticeOfMoveOutDate = O.getOrThrow(values.noticeOfMoveOutDate);

    try {
      if (expectedMoveOutDate.isBefore(LocalDate$.today())) {
        await confirmation({
          confirmButtonLabel: "Update Move Out Date",
          title:
            "The current lease move out date has already passed. Are you sure you would set a new move out date?",
        });
      }

      await LeasingAPI.editLeaseExpectedMoveOutDate({
        leaseId: values.leaseId,
        expectedMoveOutDate: expectedMoveOutDate.toJSON(),
        noticeOfMoveOutDate: noticeOfMoveOutDate.toJSON(),
        moveOutReason: O.getOrUndefined(values.moveOutReason),
      });
      onSuccess();
    } catch (err) {
      fail(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <H2>{expectedMoveOutDate ? "Update" : "Set"} Move Out Date</H2>
        <FormDateInput
          label="Move Out Date"
          disabled={loading}
          name="expectedMoveOutDate"
          form={form}
        />
        <FormDateInput
          label="Notice Provided Date"
          disabled={loading}
          name="noticeOfMoveOutDate"
          form={form}
        />
        <FormSelect
          data={LeaseMoveOutOptions}
          form={form}
          clearable
          label="Move Out Reason"
          name="moveOutReason"
          disabled={loading}
        />
        <Group justify={Justify.end}>
          <Button type="submit" disabled={loading}>
            Save Move Out Date
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { MoveOutDateForm };
