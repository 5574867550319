import { cva } from "class-variance-authority";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

import type { Spacings } from "../../../flex/src";
import { Spacing } from "../../../flex/src";

type InsetProps = {
  b?: Spacings;
  l?: Spacings;
  r?: Spacings;
  t?: Spacings;
};

const InsetVariantGenerator = cva(["inline-flex"], {
  variants: {
    b: {
      [Spacing.none]: "mb-0",
      [Spacing.xs]: "-mb-1",
      [Spacing.sm]: "-mb-2",
      [Spacing.md]: "-mb-4",
      [Spacing.lg]: "-mb-6",
      [Spacing.xl]: "-mb-8",
    },
    l: {
      [Spacing.none]: "ml-0",
      [Spacing.xs]: "-ml-1",
      [Spacing.sm]: "-ml-2",
      [Spacing.md]: "-ml-4",
      [Spacing.lg]: "-ml-6",
      [Spacing.xl]: "-ml-8",
    },
    r: {
      [Spacing.none]: "mr-0",
      [Spacing.xs]: "-mr-1",
      [Spacing.sm]: "-mr-2",
      [Spacing.md]: "-mr-4",
      [Spacing.lg]: "-mr-6",
      [Spacing.xl]: "-mr-8",
    },
    t: {
      [Spacing.none]: "mt-0",
      [Spacing.xs]: "-mt-1",
      [Spacing.sm]: "-mt-2",
      [Spacing.md]: "-mt-4",
      [Spacing.lg]: "-mt-6",
      [Spacing.xl]: "-mt-8",
    },
  },
});

/**
 * @deprecated this should never be used anywhere except internally for DS components
 */
const Inset = forwardRef<HTMLDivElement, PropsWithChildren<InsetProps>>(
  function Inset(props, ref) {
    const { children } = props;
    return (
      <div className={InsetVariantGenerator(props)} ref={ref}>
        {children}
      </div>
    );
  },
);

export { Inset };

export type { InsetProps };
