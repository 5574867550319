import { Function as F, Predicate as P } from "effect";
import { useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { BadgeColors } from "@ender/shared/ds/badge";
import { Badge } from "@ender/shared/ds/badge";
import type { BankRecRowType } from "@ender/shared/generated/ender.service.accounting.banking";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import type { ReconciliationStatus } from "../../../banking.types";
import {
  ReconciliationStatusEnum,
  ReconciliationStatusValuesLabelsEnum,
} from "../../../banking.types";

const reconciliationStatusActionMap = {
  "Bank Excluded": "Re-include",
  "Bank Txn": "Reconcile",
  "Ender Excluded": "Re-include",
  "Ender Txn": "Exclude",
  Manual: "Unreconcile",
  Matched: "Unmatch",
  "Matched Later - Bank": "Unmatch",
  "Matched Later - Ender": "Unmatch",
  "Pending Deposit": UNDEFINED,
  "Pending Invoice": UNDEFINED,
} as const;

type BankingDetailListItemStatusProps = {
  badgeColor: BadgeColors;
  rowType?: BankRecRowType;
  onClick?: () => void;
  reconciliationStatus?: ReconciliationStatus;
  canRemoveRow?: boolean;
};

function BankingDetailListItemStatusBadge({
  reconciliationStatus,
  badgeColor,
  onClick = F.constVoid,
  canRemoveRow,
}: BankingDetailListItemStatusProps) {
  const [
    isMouseOver,
    { setFalse: handleMouseLeave, setTrue: handleMouseEnter },
  ] = useBoolean();

  const hoverText = useMemo(() => {
    // exceptional case
    if (
      reconciliationStatus === ReconciliationStatusEnum["Ender Txn"] &&
      !canRemoveRow
    ) {
      return reconciliationStatus;
    }
    // map
    const action =
      reconciliationStatusActionMap[
        reconciliationStatus as keyof typeof reconciliationStatusActionMap
      ];
    if (P.isNotNullable(action)) {
      return action;
    }
    // no change of text on hover
    return reconciliationStatus;
  }, [reconciliationStatus, canRemoveRow]);
  const statusLabel = reconciliationStatus
    ? ReconciliationStatusValuesLabelsEnum[reconciliationStatus]
    : reconciliationStatus;

  return (
    <div
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Badge dark={false} size="md" color={badgeColor}>
        {isMouseOver && hoverText ? hoverText : statusLabel}
      </Badge>
    </div>
  );
}

export { BankingDetailListItemStatusBadge };
