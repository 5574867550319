/**
 * HTTP Method: POST
 * Pathname: /invoices/search
 * @function searchInvoices
 * @memberof InvoicesAPI
 * @param {InvoicesAPISearchInvoicesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InvoiceSerializerInvoiceResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { ReportWidgetSortOrder } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPISearchInvoicesSearchParams = {
  token?: string | undefined;
};

type InvoicesAPISearchInvoicesBodyParams = {
  assigneeIds: EnderId[];
  invoiceIds: EnderId[];
  limit?: number | undefined;
  order?: ReportWidgetSortOrder | undefined;
  propertyIds: EnderId[];
  propertyServiceIds: EnderId[];
  sortBy?: string | undefined;
};

type InvoicesAPISearchInvoicesPayload = InvoicesAPISearchInvoicesSearchParams &
  InvoicesAPISearchInvoicesBodyParams;

function searchInvoicesUnsafeEffect(payload: InvoicesAPISearchInvoicesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InvoiceSerializerInvoiceResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<InvoiceSerializerInvoiceResponse[]>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchInvoices(
  payload: InvoicesAPISearchInvoicesPayload,
  options?: { signal?: AbortSignal },
): Promise<InvoiceSerializerInvoiceResponse[]> {
  return F.pipe(
    payload,
    searchInvoicesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchInvoices };
export type {
  InvoicesAPISearchInvoicesBodyParams,
  InvoicesAPISearchInvoicesPayload,
  InvoicesAPISearchInvoicesSearchParams,
};
