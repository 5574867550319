import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import type { ReactNode } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { Button } from "@ender/shared/ds/button";
import { FileEffectSchema, FormFileInput } from "@ender/shared/ds/file-input";
import { Stack } from "@ender/shared/ds/stack";

const UploadFormSchema = Schema.Struct({
  files: Schema.Array(FileEffectSchema).pipe(Schema.mutable),
});

type UploadFormPayload = Schema.Schema.Encoded<typeof UploadFormSchema>;
type UploadFormValues = Schema.Schema.Type<typeof UploadFormSchema>;

type UploadFormProps = {
  children?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  onError?: (error: Error) => void;
  onSubmit: (files: File[]) => Promise<void>;
  onSuccess: () => void;
  submitButtonDisabled?: boolean;
};

/**
 * @deprecated move this into `widgets` as a reusable form. Combine this with `file-upload-form` from shared/ui/modals
 * there only needs to be one
 */
function UploadForm({
  children,
  footer,
  header,
  onError,
  onSubmit,
  onSuccess,
  submitButtonDisabled,
}: UploadFormProps) {
  const { mutate, isLoading } = useMutation({
    mutationFn: onSubmit,
    onError: (err: Error) => onError?.(err),
    onSuccess: () => {
      onSuccess();
    },
  });

  function submitForm(values: UploadFormValues) {
    mutate(values.files);
  }

  const form = useForm<UploadFormPayload>({
    defaultValues: { files: [] },
    mode: "onSubmit",
    resolver: effectTsResolver(UploadFormSchema),
  });

  const files = form.watch("files");
  const showButton = files.length > 0;

  return (
    <Form form={form} onSubmit={submitForm}>
      <Stack>
        {header}
        <FormFileInput name="files" form={form} />
        {showButton && (
          <>
            {children}
            <Button
              disabled={submitButtonDisabled || isLoading}
              loading={isLoading}
              type="submit">
              Upload
            </Button>
          </>
        )}
        {footer}
      </Stack>
    </Form>
  );
}

export { UploadForm, UploadFormProps };
