import { convertHeicToJpeg } from "@ender/shared/api/files";
import type { EnderId } from "@ender/shared/core";
import { rest } from "@ender/shared/utils/rest";

async function createGLJournalEntryAttachments(
  files: File[],
  journalEntryId: EnderId,
): Promise<void> {
  const formData = new FormData();

  for (const file of files) {
    if (file.type === "image/heic") {
      const converted = await convertHeicToJpeg(file);
      formData.append("file", converted, file.name.replace(".heic", ".jpg"));
    } else {
      formData.append("file", file);
    }
  }
  formData.append("json", JSON.stringify({ journalEntryId }));

  return rest.post("/accounting/journal-entries/attachment", formData);
}

export { createGLJournalEntryAttachments };
