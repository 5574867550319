/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/getUpcomingMoveIns
 * @function upcomingMoveIns
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIUpcomingMoveInsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetMoveInTableResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type {
  GetMoveInTableRequestMoveInSortKey,
  GetMoveInTableRequestMoveInTab,
} from "@ender/shared/generated/com.ender.middle.request";
import type { GetMoveInTableResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIUpcomingMoveInsSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIUpcomingMoveInsBodyParams = {
  ascending?: boolean | undefined;
  leaseIds: EnderId[];
  limit?: number | undefined;
  marketIds: EnderId[];
  moveInDateEnd?: SerializesInto<LocalDate> | undefined;
  moveInDateStart?: SerializesInto<LocalDate> | undefined;
  moveInTab: GetMoveInTableRequestMoveInTab;
  offset?: number | undefined;
  ownershipGroupIds: EnderId[];
  propertyIds: EnderId[];
  sortKey?: GetMoveInTableRequestMoveInSortKey | undefined;
};

type LeasingMiddleLayerAPIUpcomingMoveInsPayload =
  LeasingMiddleLayerAPIUpcomingMoveInsSearchParams &
    LeasingMiddleLayerAPIUpcomingMoveInsBodyParams;

function upcomingMoveInsUnsafeEffect(
  payload: LeasingMiddleLayerAPIUpcomingMoveInsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetMoveInTableResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetMoveInTableResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/leasing/getUpcomingMoveIns",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function upcomingMoveIns(
  payload: LeasingMiddleLayerAPIUpcomingMoveInsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetMoveInTableResponse> {
  return F.pipe(
    payload,
    upcomingMoveInsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { upcomingMoveIns };
export type {
  LeasingMiddleLayerAPIUpcomingMoveInsBodyParams,
  LeasingMiddleLayerAPIUpcomingMoveInsPayload,
  LeasingMiddleLayerAPIUpcomingMoveInsSearchParams,
};
