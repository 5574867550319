import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useCallback, useMemo } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { TemplatesAPI } from "@ender/shared/generated/ender.api.documents";
import type { TextTemplate } from "@ender/shared/generated/ender.model.leasing";
import type {
  CollectionsStep,
  CollectionsStepCollectionsActionType,
} from "@ender/shared/generated/ender.model.misc";
import { CollectionsStepCollectionsActionTypeEnum } from "@ender/shared/generated/ender.model.misc";
import { pluralize } from "@ender/shared/utils/string";

import { getCollectionsActionNeededForDisplay } from "./collections.utils";

function getCollectionsTimelineDaysLabel(numDaysPastRentDue: number) {
  if (numDaysPastRentDue === 0) {
    return "On Due Date";
  }

  const timeInstanceLabel = numDaysPastRentDue > 0 ? "after" : "before";

  return `${Math.abs(numDaysPastRentDue)} ${pluralize("day", numDaysPastRentDue)} ${timeInstanceLabel}`;
}

type CollectionsStepTitleProps = {
  step: CollectionsStep | "bulk";

  /**
   * @description Determines to use CollectionsActionNeeded's short label if false or full label if true
   * @example "Call" as a short label vs "Call 2 days after" as a full label
   * @see {@link getCollectionsActionNeededForDisplay}
   */
  useBrevity?: boolean;
};

type TemplatedStepTitleProps = Omit<CollectionsStepTitleProps, "step"> & {
  /** @description A custom template provided.  Should include [label] where content should be replaced */
  overrideLabel?: string;
  step: CollectionsStep;
};

/** @description Component used to make it easy to display a message-templated collections step's display title */
function TemplatedStepTitle(props: TemplatedStepTitleProps) {
  const { overrideLabel, step, useBrevity = false } = props;
  const { actionType, numDaysPastRentDue, taskTitle, templateId } = step;
  const labelType = useBrevity ? "labelShort" : "labelFull";

  // Get CollectionsActionLabel info if no overrideLabel was provided
  const labelInfo = P.isNullable(overrideLabel)
    ? getCollectionsActionNeededForDisplay(actionType)
    : { [labelType]: overrideLabel };
  const { [labelType]: label } = labelInfo;

  const { data: template, isFetching: isLoadingTemplate } = useQuery<
    TextTemplate | Undefined
  >({
    queryKey: ["TemplatesAPI.getTemplate", templateId] as const,
    queryFn: async ({ signal }) => {
      if (P.isNullable(templateId)) {
        return;
      }
      return TemplatesAPI.getTemplate({ templateId }, { signal });
    },
    enabled: P.isNotNullable(templateId),
    staleTime: 900000, // 15 minutes
  });

  /** @description Returns the content which should populate the CollectionsActionLabel */
  const getStepActionLabel = useCallback(
    (_actionType: CollectionsStepCollectionsActionType) => {
      const StepActionLabelMap: Record<
        CollectionsStepCollectionsActionType,
        string
      > = {
        [CollectionsStepCollectionsActionTypeEnum.CREATE_TASK]: taskTitle ?? "",
        [CollectionsStepCollectionsActionTypeEnum.EMAIL]:
          template?.name ??
          `email ${getCollectionsTimelineDaysLabel(numDaysPastRentDue)}`,
        [CollectionsStepCollectionsActionTypeEnum.LATE_FEE]:
          getCollectionsTimelineDaysLabel(numDaysPastRentDue),
        [CollectionsStepCollectionsActionTypeEnum.INTEND_TO_EVICT]:
          getCollectionsTimelineDaysLabel(numDaysPastRentDue),
        [CollectionsStepCollectionsActionTypeEnum.MOVE_TO_EVICT]:
          getCollectionsTimelineDaysLabel(numDaysPastRentDue),
        [CollectionsStepCollectionsActionTypeEnum.PHONE_CALL]:
          getCollectionsTimelineDaysLabel(numDaysPastRentDue),
        [CollectionsStepCollectionsActionTypeEnum.CERTIFIED_MAIL]:
          template?.name ??
          `letter ${getCollectionsTimelineDaysLabel(numDaysPastRentDue)}`,
        [CollectionsStepCollectionsActionTypeEnum.SMS]:
          template?.name ??
          `SMS ${getCollectionsTimelineDaysLabel(numDaysPastRentDue)}`,
      } as const;

      return StepActionLabelMap[_actionType];
    },
    [numDaysPastRentDue, template, taskTitle],
  );

  /** @description The final templated title */
  const formattedLabel = useMemo(() => {
    const templateLabel = getStepActionLabel(actionType);
    return P.isNotNullable(templateLabel)
      ? label.replace("[label]", getStepActionLabel(actionType))
      : label;
  }, [actionType, getStepActionLabel, label]);
  const loadingTemplate = label.replace("[label]", "...");

  if (isLoadingTemplate) {
    return <>{loadingTemplate}</>;
  }

  return <>{formattedLabel}</>;
}

/**
 * @description
 * Component used to make it easy to render a step's display title based on it's action type.
 * It is a wrapper component to simplify string-typed step (bulk) and useQuery based on step.templateId
 */
function CollectionsStepTitle(props: CollectionsStepTitleProps) {
  const { step, useBrevity = false } = props;

  // DETERMINE IF ATTEMPTING TO BULK PROCESS ACTIONS | Bulk comes in as a string whereas other steps are objects
  if (P.isString(step)) {
    return "Bulk Process Actions";
  }

  if (P.isNullable(step)) {
    return NULL;
  }

  return <TemplatedStepTitle step={step} useBrevity={useBrevity} />;
}

export { CollectionsStepTitle, TemplatedStepTitle };
