import { Predicate as P } from "effect";

import type { BadgeColors } from "@ender/shared/ds/badge";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { LeaseLeaseSubstatus } from "@ender/shared/generated/ender.model.leasing";
import { cast } from "@ender/shared/types/cast";
import type { LeaseDisplayStatus } from "@ender/shared/types/ender-general";
import { LeaseDisplayStatusEnum } from "@ender/shared/types/ender-general";
import {
  getLeaseDisplayStatus,
  getLeaseDisplayStatusFromSubstatus,
} from "@ender/shared/utils/lease";

//@ts-expect-error this entirely needs a refactor. Why are we using display values as keys? Why not just use status enum values?
const LeaseDisplayStatusToColorMap: Record<LeaseDisplayStatus, BadgeColors> = {
  [LeaseDisplayStatusEnum.ACTIVE]: BadgeColor.green,
  [LeaseDisplayStatusEnum.CANCELED]: BadgeColor.slate,
  [LeaseDisplayStatusEnum.CREATING_PDF]: BadgeColor.yellow,
  [LeaseDisplayStatusEnum.DRAFTING]: BadgeColor.yellow,
  [LeaseDisplayStatusEnum.INACTIVE]: BadgeColor.slate,
  [LeaseDisplayStatusEnum.NEEDS_FINAL_SIGNATURE]: BadgeColor.yellow,
  [LeaseDisplayStatusEnum.OUT_FOR_SIGNATURES]: BadgeColor.yellow,
  [LeaseDisplayStatusEnum.UPCOMING]: BadgeColor.yellow,
  [LeaseDisplayStatusEnum.ERROR]: BadgeColor.red,
} as const;

type LeaseStatusBadgeProps = {
  lease?: Pick<
    LeaseSerializerLeaseResponse,
    | "substatus"
    | "isUpcoming"
    | "hasRenewal"
    | "endDate"
    | "actualMoveOutDate"
    | "expectedMoveOutDate"
  >;
  leaseSubstatus?: LeaseLeaseSubstatus;
};

function LeaseStatusBadge({ lease, leaseSubstatus }: LeaseStatusBadgeProps) {
  const displayStatus = P.isNotNullable(lease)
    ? getLeaseDisplayStatus(lease)
    : getLeaseDisplayStatusFromSubstatus(cast(leaseSubstatus));
  const color = LeaseDisplayStatusToColorMap[displayStatus];

  return (
    <Badge size="md" color={color}>
      {displayStatus}
    </Badge>
  );
}

export { LeaseStatusBadge };
