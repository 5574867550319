import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useCallback, useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import type { AnnouncementAPIAppGroupApplicantPair } from "@ender/shared/generated/ender.api.comm";
import { AnnouncementAPI } from "@ender/shared/generated/ender.api.comm";
import { TemplatesAPI } from "@ender/shared/generated/ender.api.documents";
import type { ListTemplatesResponse } from "@ender/shared/generated/ender.api.documents.response";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { TextTemplateTemplateType } from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationApplicantTypeEnum,
  TextTemplateTemplateTypeEnum,
} from "@ender/shared/generated/ender.model.leasing";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { pluralize } from "@ender/shared/utils/string";

import { ApplicationsAnnouncementForm } from "./applications-announcement-form";
import type { ApplicationsAnnouncementFormOutput } from "./applications-announcement-form.types";

type ConnectedApplicationsAnnouncementFormProps = {
  applicationGroupIds: EnderId[];
  onSuccess: () => void;
};

function ConnectedApplicationsAnnouncementForm(
  props: ConnectedApplicationsAnnouncementFormProps,
) {
  const { applicationGroupIds, onSuccess } = props;

  const applicationGroupQueryResponses = useQueries<
    GetApplicationGroupResponse[]
  >({
    queries: applicationGroupIds.map((applicationGroupId) => ({
      queryKey: [
        "ApplicationsAPI.getApplication",
        { appGroupId: applicationGroupId },
      ],
      queryFn: () =>
        ApplicationsAPI.getApplication({ appGroupId: applicationGroupId }),
    })),
  }) as UseQueryResult<GetApplicationGroupResponse>[];

  const { data: templates } = useQuery<
    ListTemplatesResponse[],
    unknown,
    ListTemplatesResponse[],
    [string, { type: TextTemplateTemplateType }]
  >({
    queryKey: [
      "TemplatesAPI.listTemplates",
      { type: TextTemplateTemplateTypeEnum.APPLICATION_ANNOUNCEMENT },
    ],
    queryFn: ({ queryKey: [, props] }) => TemplatesAPI.listTemplates(props),
  });

  const recipients = useMemo<string[]>(() => {
    if (applicationGroupQueryResponses[0].isLoading) {
      return [];
    }

    const _recipients: string[] = [];

    applicationGroupQueryResponses.forEach(({ data }) => {
      data?.applications.forEach((application) => {
        if (
          application.applicantType ===
          ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT
        ) {
          const name = `${application.applicant.firstName} ${application.applicant.lastName}`;
          _recipients.push(name);
        }
      });
    });

    return _recipients;
  }, [applicationGroupQueryResponses]);

  const { mutateAsync: sendAnnouncement, isLoading: isSendingAnnouncement } =
    useMutation({
      mutationFn: AnnouncementAPI.sendApplicantAnnouncement,
      mutationKey: ["AnnouncementAPI.sendApplicantAnnouncement"] as const,
    });

  const onSubmit = useCallback(
    async (values: ApplicationsAnnouncementFormOutput) => {
      const _recipients: AnnouncementAPIAppGroupApplicantPair[] = [];

      applicationGroupQueryResponses.forEach(({ data }) => {
        data?.applications.forEach((application) => {
          if (
            application.applicantType ===
            ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT
          ) {
            _recipients.push({
              applicationGroupId: data.applicationGroup.id,
              userId: application.applicant.userId,
            });
          }
        });
      });

      await sendAnnouncement({
        applicationsWithApplicants: _recipients,
        body: values.body,
        subject: values.subject,
        files: [],
        templateId: O.getOrUndefined(values.templateId),
      });

      onSuccess();
      showSuccessNotification({
        message: `Message sent to ${_recipients.length} ${pluralize("Applicant", _recipients.length)}`,
      });
    },
    [onSuccess, applicationGroupQueryResponses, sendAnnouncement],
  );

  return (
    <ApplicationsAnnouncementForm
      recipients={recipients}
      onSubmit={onSubmit}
      isLoadingRecipients={applicationGroupQueryResponses.some(
        (response) => response.isLoading,
      )}
      isSubmitting={isSendingAnnouncement}
      templates={templates ?? []}
    />
  );
}

export { ConnectedApplicationsAnnouncementForm };
