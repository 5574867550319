import { IconCheck, IconX } from "@tabler/icons-react";
import { Function as F, Option as O } from "effect";
import { forwardRef } from "react";

import { Instant$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonSize } from "@ender/shared/ds/button";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Text } from "@ender/shared/ds/text";
import type { ApplicationGroupApprovalResponseApprovalStatusResponse } from "@ender/shared/generated/com.ender.middle.response";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { capitalize } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

type ApplicationApprovalProps = {
  approval: ApplicationGroupApprovalResponseApprovalStatusResponse;
  onApprove?: () => void;
  onReject?: () => void;
  /**
   * intent to reset the approval status
   */
  onClear?: () => void;
  disabled?: boolean;
};

const ApplicationApproval = forwardRef<
  HTMLDivElement,
  ApplicationApprovalProps
>(function Approval(props, ref) {
  const {
    approval,
    onApprove = F.constVoid,
    onReject = F.constVoid,
    onClear = F.constVoid,
    disabled = false,
  } = props;
  if (approval.status === ApprovableApprovalStatusEnum.NEW) {
    return (
      <Group align={Align.center} ref={ref}>
        <ActionIcon
          color={Color.red}
          size={ButtonSize.sm}
          onClick={onReject}
          disabled={disabled}
          label="Reject">
          <IconX />
        </ActionIcon>
        <ActionIcon
          color={Color.green}
          size={ButtonSize.sm}
          onClick={onApprove}
          disabled={disabled}
          label="Approve">
          <IconCheck />
        </ActionIcon>
      </Group>
    );
  }

  const approver = `${approval.firstName} ${approval.lastName}`;
  const approvalTimestamp = Instant$.parse(approval.approvalTimestamp).pipe(
    O.map((val) => val.toString()),
    O.getOrElse(() => ""),
  );

  return (
    <Group spacing={Spacing.sm}>
      <Text>
        {capitalize(approval.status)} by {approver} on {approvalTimestamp}
      </Text>
      <button
        onClick={onClear}
        type="button"
        className="text-primary-500 leading-standard">
        Clear
      </button>
    </Group>
  );
});

export { ApplicationApproval };

export type { ApplicationApprovalProps };
