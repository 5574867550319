import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import { IsoDateSchema, UserSchema } from "@ender/shared/types/ender-general";

import { DecisionSchema } from "./decision";

const GetApprovalPipelineResponseSchema = z.object({
  model: z.object({ id: EnderIdSchema }).and(z.record(z.unknown())),
  currentState: z.object({
    isApproved: z.boolean(),
    isFirstStep: z.boolean(),
    isFullyRejected: z.boolean(),
    isLastStep: z.boolean(),
    stepId: EnderIdSchema.nullish(),
    timeline: z
      .object({
        archived: z.boolean(),
        comment: z.string(),
        date: IsoDateSchema,
        decision: DecisionSchema,
        isFinalStep: z.boolean().nullish(),
        step: z.string().nullish(),
        stepId: EnderIdSchema.nullish(),
        timestamp: z.string().datetime(),
        user: UserSchema.nullish(),
      })
      .array(),
  }),
});

type GetApprovalPipelineResponse = z.infer<
  typeof GetApprovalPipelineResponseSchema
>;

export { GetApprovalPipelineResponseSchema };

export type { GetApprovalPipelineResponse };
