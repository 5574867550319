import { IconReceipt2 } from "@tabler/icons-react";

function InvoiceIconGray() {
  return <IconReceipt2 color="var(--color-gray-500)" />;
}
function InvoiceIconGreen() {
  return <IconReceipt2 color="var(--color-green-500)" />;
}
function InvoiceIconYellow() {
  return <IconReceipt2 color="var(--color-yellow-500)" />;
}
function InvoiceIconRed() {
  return <IconReceipt2 color="var(--color-red-500)" />;
}

export { InvoiceIconGray, InvoiceIconGreen, InvoiceIconRed, InvoiceIconYellow };
