import { useCallback, useEffect } from "react";

import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";

type Props = {
  target: EventTarget;
  eventName: string;

  handler: EventListener;
};

function useEventListener({ target, eventName, handler }: Props) {
  // useRefLatest prevents continuous re-render and Add/Remove thrash by storing the latest handler in a ref this means
  // users of useEventListener don't need to wrap their handler in useCallback.
  const latestHandlerRef = useRefLatest(handler);

  const onEvent: EventListener = useCallback(
    (event: Event) => {
      latestHandlerRef.current && latestHandlerRef.current(event);
    },
    [latestHandlerRef],
  );
  // lazy useEffect
  useEffect(() => {
    target.addEventListener(eventName, onEvent);
    return () => {
      target.removeEventListener(eventName, onEvent);
    };
  }, [eventName, onEvent, target]);
}

export { useEventListener };
export type { Props };
