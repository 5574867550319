/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}/zones/{zoneId}/applicable
 * @function markZoneApplicable
 * @memberof InspectionAPI
 * @param {InspectionAPIMarkZoneApplicablePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionZoneResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionZoneResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIMarkZoneApplicablePathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
  zoneId: EnderId;
};

type InspectionAPIMarkZoneApplicableSearchParams = {
  token?: string | undefined;
};

type InspectionAPIMarkZoneApplicablePayload =
  InspectionAPIMarkZoneApplicablePathParams &
    InspectionAPIMarkZoneApplicableSearchParams;

function markZoneApplicableUnsafeEffect(
  payload: InspectionAPIMarkZoneApplicablePayload,
) {
  const { inspectionId, areaId, zoneId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionZoneResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionZoneResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}/zones/${zoneId}/applicable`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function markZoneApplicable(
  payload: InspectionAPIMarkZoneApplicablePayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionZoneResponse> {
  return F.pipe(
    payload,
    markZoneApplicableUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { markZoneApplicable };
export type {
  InspectionAPIMarkZoneApplicablePathParams,
  InspectionAPIMarkZoneApplicablePayload,
  InspectionAPIMarkZoneApplicableSearchParams,
};
