import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { TimeInputProps } from "./time-input";
import { TimeInput } from "./time-input";

type FormTimeInputProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<TimeInputProps, Form, Name>;

function FormTimeInput<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormTimeInputProps<Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field, error }) => {
        return <TimeInput {...rest} {...field} error={error} />;
      }}
    </FormField>
  );
}

export { FormTimeInput };

export type { FormTimeInputProps };
