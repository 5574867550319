/**
 * HTTP Method: GET
 * Pathname: /markets/{marketId}/usersByRole
 * @function getUsersByRole
 * @memberof MarketsAPI
 * @param {MarketsAPIGetUsersByRolePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarketsAPIGetUsersByRoleResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MarketsAPIGetUsersByRoleResponse } from "../markets-api-get-users-by-role-response";

type MarketsAPIGetUsersByRolePathParams = {
  marketId: EnderId;
};

type MarketsAPIGetUsersByRoleSearchParams = {
  token?: string | undefined;
};

type MarketsAPIGetUsersByRolePayload = MarketsAPIGetUsersByRolePathParams &
  MarketsAPIGetUsersByRoleSearchParams;

function getUsersByRoleUnsafeEffect(payload: MarketsAPIGetUsersByRolePayload) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarketsAPIGetUsersByRoleResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<MarketsAPIGetUsersByRoleResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/markets/${marketId}/usersByRole`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUsersByRole(
  payload: MarketsAPIGetUsersByRolePayload,
  options?: { signal?: AbortSignal },
): Promise<MarketsAPIGetUsersByRoleResponse[]> {
  return F.pipe(
    payload,
    getUsersByRoleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUsersByRole };
export type {
  MarketsAPIGetUsersByRolePathParams,
  MarketsAPIGetUsersByRolePayload,
  MarketsAPIGetUsersByRoleSearchParams,
};
