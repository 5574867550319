import type {
  GetNextPageParamFunction,
  QueryFunction,
  QueryFunctionContext,
} from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  Record as R,
  pipe,
} from "effect";
import { useCallback, useEffect, useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload } from "@ender/shared/generated/com.ender.middle";
import { ApplicationsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type { SearchApplicationsPageRequestApplicationsPageTab } from "@ender/shared/generated/com.ender.middle.request";
import { SearchApplicationsPageRequestApplicationsPageTabEnum } from "@ender/shared/generated/com.ender.middle.request";
import type { SearchApplicationsPageResponse } from "@ender/shared/generated/com.ender.middle.response";
import type {
  EnderTableTopAction,
  UseTableParams,
} from "@ender/shared/ui/table-tanstack";
import {
  useTable,
  useTableColumnVisibility,
  useTableRowSelection,
  useTableSorting,
} from "@ender/shared/ui/table-tanstack";

import type { ApplicationsFilterState } from "../applications-filters/applications-filters.types";
import { allApplicationsTableColumns } from "./all-applications-table.columns";
import type {
  AllApplicationsRow,
  AllApplicationsTableMeta,
} from "./all-applications-table.types";
import {
  ColumnIdToSortKey,
  getAllApplicationsTableColumnVisibility,
} from "./all-applications-table.utils";
import { useBulkArchive } from "./use-bulk-archive";

type SearchQueryKey = readonly [
  "searchApplicationsPageTable",
  ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload,
];
type SearchPageParam = Pick<
  ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload,
  "offset"
>;

const searchQueryFn: QueryFunction<
  SearchApplicationsPageResponse,
  SearchQueryKey,
  SearchPageParam
> = (
  context: QueryFunctionContext<SearchQueryKey, SearchPageParam | undefined>,
): Promise<SearchApplicationsPageResponse> => {
  const { queryKey, pageParam, signal } = context;
  const [, partialPayload] = queryKey;
  const { offset } = pageParam ?? {};

  return ApplicationsMiddleLayerAPI.searchApplicationsPageTable(
    {
      ...partialPayload,
      offset,
      limit: 50,
    },
    { signal },
  );
};

const searchQueryGetNextPageParam: GetNextPageParamFunction<
  SearchApplicationsPageResponse
> = (
  lastPage: SearchApplicationsPageResponse,
  allPages: SearchApplicationsPageResponse[],
): SearchPageParam | undefined => {
  if (lastPage.count < allPages.length) {
    return UNDEFINED;
  }
  return { offset: allPages.reduce((acc, p) => acc + p.items.length, 0) };
};

type UseAllApplicationsTableProps = {
  filters: Omit<ApplicationsFilterState, "statuses">;
  tab: SearchApplicationsPageRequestApplicationsPageTab;
  isMessageRailOpen: boolean;
  openMessageRail: () => void;
};

function useAllApplicationsTable({
  filters,
  tab,
  isMessageRailOpen,
  openMessageRail,
}: UseAllApplicationsTableProps) {
  const sorting = useTableSorting({
    manualSorting: true,
    initialSorting: [{ id: "actionsRequired", desc: true }],
  });

  const sortParams = useMemo(() => {
    if (P.isNullable(sorting?.sorting[0])) {
      return {};
    }

    const { id: col, desc } = sorting.sorting[0];
    return { sortKey: ColumnIdToSortKey[col], descending: desc };
  }, [sorting]);

  const rowSelection = useTableRowSelection<
    AllApplicationsRow,
    AllApplicationsTableMeta
  >({
    enableRowSelection: (_row) =>
      tab !== SearchApplicationsPageRequestApplicationsPageTabEnum.ARCHIVED,
  });

  const {
    applicantName,
    ownershipGroups,
    markets,
    onlyNeedsFollowUp,
    properties,
    usingHcv,
  } = filters;
  const queryKey = useMemo((): SearchQueryKey => {
    const payload: ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload =
      {
        applicantNameOrPhone: applicantName,
        marketIds: F.pipe(
          markets ?? [],
          A.map((itm) => itm.value),
        ),
        onlyShowNeedsFollowup: onlyNeedsFollowUp,
        ownershipGroupIds: F.pipe(
          ownershipGroups ?? [],
          A.map((itm) => itm.value),
        ),
        propertyIds: F.pipe(
          properties ?? [],
          A.map((itm) => itm.value),
        ),
        ...sortParams,
        tab,
        usingHousingChoiceVoucher: pipe(
          usingHcv,
          O.match({
            onNone: () => undefined,
            onSome: (val) => val === true,
          }),
        ),
      };
    return ["searchApplicationsPageTable", payload];
  }, [
    applicantName,
    markets,
    onlyNeedsFollowUp,
    ownershipGroups,
    properties,
    sortParams,
    tab,
    usingHcv,
  ]);

  const {
    data: pagedData,
    isFetching,
    fetchNextPage: _fetchNextPage,
    refetch: _refetch,
  } = useInfiniteQuery<
    SearchApplicationsPageResponse,
    unknown,
    SearchApplicationsPageResponse,
    SearchQueryKey
  >({
    getNextPageParam: searchQueryGetNextPageParam,
    queryFn: searchQueryFn,
    queryKey,
    onSuccess: (_data) => {
      rowSelection.onRowSelectionChange({});
    },
  });

  const fetchNextPage = useCallback(async (): Promise<void> => {
    await _fetchNextPage();
  }, [_fetchNextPage]);

  const refetch = useCallback(async (): Promise<void> => {
    await _refetch();
  }, [_refetch]);

  const { bulkArchive, isBulkArchiveLoading } = useBulkArchive({
    clearRowSelection: () => rowSelection.onRowSelectionChange({}),
    refetchTable: refetch,
  });

  const totalResults = useMemo(() => {
    const pages = pagedData?.pages ?? [];
    const lastIndex = Math.max(0, pages.length - 1);
    return pages[lastIndex]?.count ?? 0;
  }, [pagedData]);

  const columnVisibility = useTableColumnVisibility({
    initialVisibility: getAllApplicationsTableColumnVisibility(tab, UNDEFINED),
  });
  //destructure the onColumnVisibilityChange function from the columnVisibility object, because it's referentially stable
  const { onColumnVisibilityChange } = columnVisibility;

  const data = useMemo(() => {
    const pages = pagedData?.pages ?? [];
    const _data = pages.flatMap((page) => page.items);
    return _data;
  }, [pagedData]);

  /**
   * react to data changes and set the visible columns based on the data.
   */
  useEffect(() => {
    onColumnVisibilityChange(
      getAllApplicationsTableColumnVisibility(tab, data),
    );
  }, [data, onColumnVisibilityChange, tab]);

  const tableActions = useMemo<
    UseTableParams<AllApplicationsRow, AllApplicationsTableMeta>["actions"]
  >(() => {
    const bulkMessageAction: EnderTableTopAction<
      AllApplicationsRow,
      AllApplicationsTableMeta
    > = {
      key: "bulkMessage",
      label: "Bulk Message",
      onAction: () => openMessageRail(),
      disabled: R.isEmptyRecord(rowSelection.rowSelection) || isMessageRailOpen,
    };

    const bulkArchiveAction: EnderTableTopAction<
      AllApplicationsRow,
      AllApplicationsTableMeta
    > = {
      key: "bulkArchive",
      label: "Bulk Archive",
      onAction: ({ table }) => {
        bulkArchive(
          table.getSelectedRowModel().rows.map((row) => row.original),
        );
      },
      disabled:
        R.isEmptyRecord(rowSelection.rowSelection) || isBulkArchiveLoading,
    };

    if (tab === SearchApplicationsPageRequestApplicationsPageTabEnum.ARCHIVED) {
      return [bulkMessageAction, bulkArchiveAction];
    }

    return [bulkMessageAction];
  }, [
    bulkArchive,
    isBulkArchiveLoading,
    isMessageRailOpen,
    openMessageRail,
    rowSelection.rowSelection,
    tab,
  ]);

  const table = useTable<AllApplicationsRow, AllApplicationsTableMeta>({
    actions: tableActions,
    columnVisibility,
    columns: allApplicationsTableColumns,
    data,
    enableQueryParams: true,
    fetchNextPage,
    hideResults: false,
    isLoading: isFetching,
    refetch,
    rowSelection,
    sorting,
    totalResults,
  });

  return { table };
}

export { useAllApplicationsTable };
