/**
 * HTTP Method: PATCH
 * Pathname: /leases/{leaseId}/paymentPlans/{paymentPlanId}
 * @function updatePaymentPlan
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdatePaymentPlanPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PaymentPlanPaymentPlanType } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdatePaymentPlanPathParams = {
  leaseId: EnderId;
  paymentPlanId: EnderId;
};

type LeasingAPIUpdatePaymentPlanSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdatePaymentPlanBodyParams = {
  startDate?: SerializesInto<LocalDate> | undefined;
  type?: PaymentPlanPaymentPlanType | undefined;
};

type LeasingAPIUpdatePaymentPlanPayload =
  LeasingAPIUpdatePaymentPlanPathParams &
    LeasingAPIUpdatePaymentPlanSearchParams &
    LeasingAPIUpdatePaymentPlanBodyParams;

function updatePaymentPlanUnsafeEffect(
  payload: LeasingAPIUpdatePaymentPlanPayload,
) {
  const { leaseId, paymentPlanId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId>({}),
        encode: encodeJsonBody({ method: "PATCH" }),
        pathname: `/leases/${leaseId}/paymentPlans/${paymentPlanId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updatePaymentPlan(
  payload: LeasingAPIUpdatePaymentPlanPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId> {
  return F.pipe(
    payload,
    updatePaymentPlanUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updatePaymentPlan };
export type {
  LeasingAPIUpdatePaymentPlanBodyParams,
  LeasingAPIUpdatePaymentPlanPathParams,
  LeasingAPIUpdatePaymentPlanPayload,
  LeasingAPIUpdatePaymentPlanSearchParams,
};
