import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GLTxTxTypeValues = ["ACCRUAL", "CASH"] as const;

const GLTxTxTypeEffectSchema = Schema.Literal(...GLTxTxTypeValues);

type GLTxTxType = Schema.Schema.Type<typeof GLTxTxTypeEffectSchema>;

const GLTxTxTypeEnum = castEnum(GLTxTxTypeEffectSchema);

function randomGLTxTxType(): GLTxTxType {
  return rand(GLTxTxTypeValues);
}

export {
  GLTxTxTypeEffectSchema,
  GLTxTxTypeEnum,
  GLTxTxTypeValues,
  randomGLTxTxType,
};
export type { GLTxTxType };
