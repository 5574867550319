import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

// -------------------
// Counterparty Types
// Tags we use to indicate what type of counterparty the vendor is.
// -------------------
const VendorCounterpartyTagValues = [
  "VENDOR",
  "INVESTOR",
  "TITLE_COMPANY",
  "HOME_INSPECTOR",
  "UTILITY",
  "CORPORATE",
  "MUNICIPALITY",
] as const;
const VendorCounterpartyTagSchema = z.enum(VendorCounterpartyTagValues);
type VendorCounterpartyTag = z.infer<typeof VendorCounterpartyTagSchema>;

const VendorCounterpartyTagEnum = castEnum<VendorCounterpartyTag>(
  VendorCounterpartyTagSchema,
);

// -------------------
// Vendor Tags
// Tags used for the VENDOR counterparty.
// -------------------
const VendorTagValues = [
  "AFTER_HOURS",
  "MAINTENANCE",
  "RECURRING",
  "REHAB",
  "TURNOVER",
] as const;
const VendorTagSchema = z.enum(VendorTagValues);
type VendorTag = z.infer<typeof VendorTagSchema>;

const VendorTagEnum = castEnum<VendorTag>(VendorTagSchema);

// -------------------
// Trade Types
// Tags we use to indicate that a vendor supports a certain kind of trade.
// -------------------
const VendorTradeTypeValues = [
  "CLEANING",
  "ELECTRICAL",
  "FLOORING",
  "FOUNDATION",
  "GENERAL_CONTRACTOR",
  "HVAC",
  "LANDSCAPE",
  "PEST_CONTROL",
  "PLUMBING",
  "POOL",
  "ROOFING",
] as const;
const VendorTradeTypeSchema = z.enum(VendorTradeTypeValues);
type VendorTradeType = z.infer<typeof VendorTradeTypeSchema>;

const VendorTradeTypeEnum = castEnum<VendorTradeType>(VendorTradeTypeSchema);

const VendorSystemTagValues = [
  ...VendorCounterpartyTagValues,
  ...VendorTagValues,
  ...VendorTradeTypeValues,
] as const;
const VendorSystemTagSchema = z.enum(VendorSystemTagValues);
type VendorSystemTag = z.infer<typeof VendorSystemTagSchema>;

const VendorOnboardingStatusValues = [
  "PENDING_ONBOARDING_MESSAGE",
  "PENDING_VERIFICATION",
  "PENDING_BANK_DETAILS",
  "FULLY_ONBOARDED",
] as const;
const VendorOnboardingStatusSchema = z.enum(VendorOnboardingStatusValues);
type VendorOnboardingStatus = z.infer<typeof VendorOnboardingStatusSchema>;

/**
 * @enum {string} VendorOnboardingStatus
 * @description Vendor::VendorUserOnboardingStatus from model/core/vendor/Vendor.java
 */
const VendorOnboardingStatusEnum = castEnum<VendorOnboardingStatus>(
  VendorOnboardingStatusSchema,
);
//  .filter((vendor) => vendor.status !== VendorStatusEnum.PENDING_APPROVAL)

export {
  VendorCounterpartyTagEnum,
  VendorCounterpartyTagSchema,
  VendorCounterpartyTagValues,
  VendorOnboardingStatusEnum,
  VendorOnboardingStatusSchema,
  VendorOnboardingStatusValues,
  VendorSystemTagSchema,
  VendorSystemTagValues,
  VendorTagEnum,
  VendorTagSchema,
  VendorTagValues,
  VendorTradeTypeEnum,
  VendorTradeTypeSchema,
  VendorTradeTypeValues,
};
export type {
  VendorCounterpartyTag,
  VendorSystemTag,
  VendorTag,
  VendorTradeType,
};
