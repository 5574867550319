import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DealDealSourceValues = [
  "AUCTION_COM",
  "DR_HORTON",
  "FLIPOS",
  "LGI",
  "LENNAR",
  "MLS",
  "MARK_SPAIN",
  "OFFERPAD",
  "OPENDOOR",
  "ORCHARD",
  "REDFIN",
  "SELL2RENT",
  "SUNDAE",
  "SWIFT",
  "YELLOWBIRD",
  "ZILLOW",
] as const;

const DealDealSourceEffectSchema = Schema.Literal(...DealDealSourceValues);

type DealDealSource = Schema.Schema.Type<typeof DealDealSourceEffectSchema>;

const DealDealSourceEnum = castEnum(DealDealSourceEffectSchema);

function randomDealDealSource(): DealDealSource {
  return rand(DealDealSourceValues);
}

export {
  DealDealSourceEffectSchema,
  DealDealSourceEnum,
  DealDealSourceValues,
  randomDealDealSource,
};
export type { DealDealSource };
