import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import { useQueryParamsEnum } from "@ender/shared/hooks/use-query-params";
import { useUrlSyncedTabs } from "@ender/shared/hooks/use-url-synced-tabs";

import type { GeneralLedgerTransactionApprovalsTableTabsType } from "./gltx-approvals-table.types";
import {
  GeneralLedgerTransactionApprovalsTableTabsEnum,
  GeneralLedgerTransactionApprovalsTableTabsSchema,
} from "./gltx-approvals-table.types";

type UseGeneralLedgerTransactionApprovalsTableTabsProps = {
  defaultValue: GeneralLedgerTransactionApprovalsTableTabsType;
};

function useGeneralLedgerTransactionApprovalsTableTabs(
  { defaultValue }: UseGeneralLedgerTransactionApprovalsTableTabsProps = {
    defaultValue: GeneralLedgerTransactionApprovalsTableTabsEnum.ASSIGNED_TO_ME,
  },
) {
  return useUrlSyncedTabs({
    defaultValue,
    schema: GeneralLedgerTransactionApprovalsTableTabsSchema,
  });
}

const TableTypeTabSchema = Schema.Literal(
  ApprovalProcessTypeEnum.RECURRING_GL_JOURNAL_ENTRY,
  ApprovalProcessTypeEnum.GL_JOURNAL_ENTRY,
);
type TableTypeTabType = typeof TableTypeTabSchema.Type;

function useTableTypeTabs() {
  return useQueryParamsEnum("tableType", {
    initialValue: O.some(ApprovalProcessTypeEnum.GL_JOURNAL_ENTRY),
    schema: TableTypeTabSchema,
  });
}

export { useGeneralLedgerTransactionApprovalsTableTabs, useTableTypeTabs };
export type {
  TableTypeTabType,
  UseGeneralLedgerTransactionApprovalsTableTabsProps,
};
