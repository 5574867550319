import { IconInfoCircle } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import * as A from "effect/Array";
import { useContext } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { isEnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { DateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { SelectOption } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import type { InvoicesAPICreateBillbackPayload } from "@ender/shared/generated/ender.api.accounting";
import {
  GeneralLedgerAPI,
  InvoicesAPI,
} from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { GLCategory as GeneratedGlCategory } from "@ender/shared/generated/ender.model.accounting";
import type { GLCategory as LegacyGLCategory } from "@ender/shared/types/ender-general";
import { EnderInputWrapper } from "@ender/shared/ui/ender-input-wrapper";
import { fail } from "@ender/shared/utils/error";
import { isMultiple, isSingle } from "@ender/shared/utils/is";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { AllocationList } from "@ender/widgets/finance/allocation-list";

import type { UseNewBillbackFormOutput } from "./use-new-billback-form";
import { useNewBillbackForm } from "./use-new-billback-form";

type GLCategory = GeneratedGlCategory &
  Pick<LegacyGLCategory, "name" | "isParent">;

function useGLTxCategories() {
  return useQuery<GLCategory[]>({
    queryFn: ({ signal }) => {
      return GeneralLedgerAPI.getTxCategories(
        {
          includeBankAccountCategories: true,
          includeRoots: false,
          keyword: "",
        },
        { signal },
      );
    },
    queryKey: ["GeneralLedgerAPI.getTxCategories"] as const,
    staleTime: 300000,
  });
}

function OffsetAllocationsLabel() {
  return (
    <>
      <span>Allocations</span>
      <Tooltip
        label={`This determines the credits that will appear on your general ledger when the tenant pays this billback. Tenant
          payment can result in debits to prepaid rent or cash, so it does not make sense to select debits here.`}>
        <IconInfoCircle color={EnderThemeColorEnum.PRIMARY} />
      </Tooltip>
    </>
  );
}

type NewBillbackFormProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  leases: LeaseSerializerLeaseResponse[];
  onSuccess?: () => void;
};

function NewBillbackForm({
  invoice,
  leases,
  onSuccess = F.constVoid,
}: NewBillbackFormProps) {
  const { data: categories = [] } = useGLTxCategories();
  const { userPM } = useContext(UserContext);
  const confirmation = useConfirmationContext();

  const isOnlyOneAssociatedLease = isSingle(leases);
  const onlyLeaseAssociatedWithInvoice = isSingle(leases)
    ? leases[0]
    : UNDEFINED;
  const canAddTenants = !isOnlyOneAssociatedLease;

  const {
    form,
    onAddLease,
    onRemoveLease,
    onRemoveLeaseAllocation,
    onLeaseResultSelect,
    onUpdateAllocations,
  } = useNewBillbackForm({
    initialLease: isOnlyOneAssociatedLease ? leases[0] : UNDEFINED,
  });

  const leaseSelectData = leases.map((l) => ({
    label: `${l.contacts[0].name}${isMultiple(l.contacts) ? ` +${l.contacts.length - 1}` : ""}`,
    value: l.id,
  })) as SelectOption<EnderId>[];

  async function handleSubmit(values: UseNewBillbackFormOutput) {
    const date = pipe(
      values.date,
      O.map((date) => date.toJSON()),
      O.getOrThrow,
    );
    const invoiceId = invoice.id;

    const requestObjects = values.leaseAllocations.reduce<
      InvoicesAPICreateBillbackPayload[]
    >((acc, { leaseId, allocations }) => {
      const leaseAllocations = allocations.reduce<
        InvoicesAPICreateBillbackPayload[]
      >((acc, { amount, categoryId }) => {
        if (isEnderId(categoryId) && O.isSome(amount)) {
          acc.push({
            amount: pipe(
              amount,
              O.map((val) => val.toJSON()),
              O.getOrThrow,
            ),
            chargeTypeId: categoryId,
            date,
            invoiceId,
            leaseId,
          });
        }

        return acc;
      }, []);

      return acc.concat(leaseAllocations);
    }, []);
    const isParentCategorySelected = values.leaseAllocations.some(
      (leaseAllocation) => {
        return leaseAllocation.allocations.some((allocation) =>
          categories.find((c) => c.id === allocation.categoryId && c.isParent),
        );
      },
    );

    if (isParentCategorySelected && userPM.confirmParentAllocations) {
      await confirmation({
        title:
          "You have selected to allocate to a parent category. Are you sure you would like to proceed?",
        confirmButtonLabel: "Yes, use category",
      });
    }
    try {
      await Promise.all(
        requestObjects.map((reqData) => InvoicesAPI.createBillback(reqData)),
      );
      showSuccessNotification({
        message: "Billback has been successfully added.",
      });
      onSuccess();
    } catch (err) {
      fail(err);
    }
  }

  return (
    <form
      //@ts-expect-error form will be called with UseNewBillbackFormOutput
      onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Stack spacing={Spacing.none}>
          <Tuple
            label="Invoice"
            value={invoice.description || "No description"}
          />
          <Tuple label="Invoice Amount" value={invoice.amount} />
          {P.isNotNullable(onlyLeaseAssociatedWithInvoice?.companyName) && (
            <Tuple
              label="Lease"
              value={onlyLeaseAssociatedWithInvoice?.companyName}
            />
          )}
        </Stack>
        <DateInput label="Billback Date" {...form.getInputProps("date")} />
        {form.values.leaseAllocations.map((leaseAllocation, leaseIndex) => {
          const { allocations } = leaseAllocation;
          const canRemoveTenant = A.isNonEmptyArray(
            form.values.leaseAllocations,
          );
          const propertyId = leaseAllocation.allocations[0].propertyId;

          return (
            <div
              key={leaseIndex}
              style={{
                border: "1px solid lightgrey",
                borderRadius: "8px",
                padding: "1em",
              }}>
              <Stack>
                {canRemoveTenant && (
                  <Group justify={Justify.end}>
                    {/* TODO make more pretty like design */}
                    <Button onClick={() => onRemoveLease(leaseIndex)}>
                      Remove Tenant
                    </Button>
                  </Group>
                )}
                <Select<EnderId>
                  data={leaseSelectData}
                  label="Tenant"
                  onChange={(optionalLeaseId) => {
                    const leaseId = optionalLeaseId.pipe(O.getOrThrow);
                    const propertyId = leases.find(
                      (l) => l.id === leaseId,
                    )?.propertyId;
                    if (P.isNullable(propertyId)) {
                      throw new Error(
                        "Should not happen: Property ID is undefined",
                      );
                    }
                    onLeaseResultSelect(leaseIndex, { leaseId, propertyId });
                  }}
                  placeholder="Select lease"
                  // @ts-expect-error new z.input/z.output is insufficient
                  value={O.some(leaseAllocation.leaseId)}
                />
                <EnderInputWrapper
                  label={<OffsetAllocationsLabel />}
                  {...form.getInputProps(
                    `leaseAllocations.${leaseIndex}.allocations`,
                  )}
                  error={form.errors.allocations}>
                  <AllocationList
                    allocations={allocations}
                    updateAllocations={(allocations) =>
                      onUpdateAllocations(leaseIndex, allocations)
                    }
                    getInputProps={(index, field) =>
                      form.getInputProps(
                        `leaseAllocations.${leaseIndex}.allocations.${index}.${field}`,
                      )
                    }
                    propertyId={propertyId}
                    onRemoveAllocation={(index) =>
                      onRemoveLeaseAllocation(leaseIndex, index)
                    }
                  />
                </EnderInputWrapper>
              </Stack>
            </div>
          );
        })}
        {/* TODO make more pretty like design */}
        <Group justify={Justify.end}>
          {canAddTenants && <Button onClick={onAddLease}>Add Tenant</Button>}
          <Button type="submit">Create Billback</Button>
        </Group>
      </Stack>
    </form>
  );
}

export { NewBillbackForm };
