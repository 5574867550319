/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/searchProspectsTable
 * @function searchProspectsTable
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPISearchProspectsTablePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchProspectsTableResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SearchProspectsTableResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { ProspectSortBy } from "@ender/shared/generated/ender.api.leasing.request";
import type { UnitUnitStatus } from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPISearchProspectsTableSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPISearchProspectsTableBodyParams = {
  archiveKeyword?: string | undefined;
  isFollowUpNeeded?: boolean | undefined;
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  propertyIds: EnderId[];
  prospectKeyword?: string | undefined;
  showActive?: boolean | undefined;
  showArchived?: boolean | undefined;
  sortAscending?: boolean | undefined;
  sortBy?: ProspectSortBy | undefined;
  unitIds: EnderId[];
  unitStatus: UnitUnitStatus[];
};

type LeasingMiddleLayerAPISearchProspectsTablePayload =
  LeasingMiddleLayerAPISearchProspectsTableSearchParams &
    LeasingMiddleLayerAPISearchProspectsTableBodyParams;

function searchProspectsTableUnsafeEffect(
  payload: LeasingMiddleLayerAPISearchProspectsTablePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchProspectsTableResponse>({
        body,
        decode: unsafeDecodeJsonResponse<SearchProspectsTableResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/leasing/searchProspectsTable",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchProspectsTable(
  payload: LeasingMiddleLayerAPISearchProspectsTablePayload,
  options?: { signal?: AbortSignal },
): Promise<SearchProspectsTableResponse> {
  return F.pipe(
    payload,
    searchProspectsTableUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchProspectsTable };
export type {
  LeasingMiddleLayerAPISearchProspectsTableBodyParams,
  LeasingMiddleLayerAPISearchProspectsTablePayload,
  LeasingMiddleLayerAPISearchProspectsTableSearchParams,
};
