import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationGroupOverallRecommendationValues = [
  "ACCEPT",
  "DECLINE",
  "REFER",
  "RECOMMENDATION_PENDING",
  "SEARCH_PENDING",
  "ERROR",
] as const;

const ApplicationGroupOverallRecommendationEffectSchema = Schema.Literal(
  ...ApplicationGroupOverallRecommendationValues,
);

type ApplicationGroupOverallRecommendation = Schema.Schema.Type<
  typeof ApplicationGroupOverallRecommendationEffectSchema
>;

const ApplicationGroupOverallRecommendationEnum = castEnum(
  ApplicationGroupOverallRecommendationEffectSchema,
);

function randomApplicationGroupOverallRecommendation(): ApplicationGroupOverallRecommendation {
  return rand(ApplicationGroupOverallRecommendationValues);
}

export {
  ApplicationGroupOverallRecommendationEffectSchema,
  ApplicationGroupOverallRecommendationEnum,
  ApplicationGroupOverallRecommendationValues,
  randomApplicationGroupOverallRecommendation,
};
export type { ApplicationGroupOverallRecommendation };
