/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/monthToMonth
 * @function markMonthToMonth
 * @memberof LeasingAPI
 * @param {LeasingAPIMarkMonthToMonthPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIMarkMonthToMonthPathParams = {
  leaseId: EnderId;
};

type LeasingAPIMarkMonthToMonthSearchParams = {
  token?: string | undefined;
};

type LeasingAPIMarkMonthToMonthBodyParams = {
  markedMonthToMonth: boolean;
};

type LeasingAPIMarkMonthToMonthPayload = LeasingAPIMarkMonthToMonthPathParams &
  LeasingAPIMarkMonthToMonthSearchParams &
  LeasingAPIMarkMonthToMonthBodyParams;

function markMonthToMonthUnsafeEffect(
  payload: LeasingAPIMarkMonthToMonthPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/monthToMonth`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function markMonthToMonth(
  payload: LeasingAPIMarkMonthToMonthPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    markMonthToMonthUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { markMonthToMonth };
export type {
  LeasingAPIMarkMonthToMonthBodyParams,
  LeasingAPIMarkMonthToMonthPathParams,
  LeasingAPIMarkMonthToMonthPayload,
  LeasingAPIMarkMonthToMonthSearchParams,
};
