import { z } from "zod";

import type { EnderId } from "@ender/shared/core";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import type { ObjectValues } from "@ender/shared/types/general";
import { castEnum } from "@ender/shared/utils/zod";
import type { ApplicantScreeningReport } from "@ender/widgets/leasing/applications/applicant-report-card";

const ApplicationPageRightRailValues = [
  "ADD_APPLICANT",
  "ADD_INCOME_SOURCE",
  "ADD_PET",
  "ADD_SHOWING",
  "ADD_VEHICLE",
  "CHANGE_PROPERTY_UNIT",
  "CLOSED",
  "DRAFT_LEASE",
  "EDIT_APPLICANT",
  "EDIT_EMPLOYMENT",
  "EDIT_FILE",
  "EDIT_MOVE_IN",
  "EDIT_PET",
  "EDIT_RENTAL_HISTORY",
  "TRANSFER_APPLICANT",
  "UPLOAD_PROOF_OF_ID",
  "UPLOAD_PROOF_OF_INCOME",
  "UPLOAD_PET_DOCUMENTS",
  "VIEW_APPLICANT_REPORT",
] as const;

const ApplicationPageRightRailSchema = z.enum(ApplicationPageRightRailValues);
type ApplicationPageRightRailType = z.infer<
  typeof ApplicationPageRightRailSchema
>;
const ApplicationPageRightRailEnum = castEnum<ApplicationPageRightRailType>(
  ApplicationPageRightRailSchema,
);

type ApplicationPageRightRailMap = {
  [ApplicationPageRightRailEnum.ADD_APPLICANT]: { application?: undefined };
  [ApplicationPageRightRailEnum.EDIT_APPLICANT]: {
    application: GetApplicationGroupResponseApplicationResponse;
  };
  [ApplicationPageRightRailEnum.ADD_INCOME_SOURCE]: { applicationId: EnderId };
  [ApplicationPageRightRailEnum.ADD_PET]: { pet?: undefined; ownerId: EnderId };
  [ApplicationPageRightRailEnum.ADD_VEHICLE]: { ownerId: EnderId };
  [ApplicationPageRightRailEnum.ADD_SHOWING]: unknown;
  [ApplicationPageRightRailEnum.CHANGE_PROPERTY_UNIT]: unknown;
  [ApplicationPageRightRailEnum.CLOSED]: unknown;
  [ApplicationPageRightRailEnum.DRAFT_LEASE]: unknown;
  [ApplicationPageRightRailEnum.EDIT_EMPLOYMENT]: {
    application: GetApplicationGroupResponseApplicationResponse;
  };
  [ApplicationPageRightRailEnum.EDIT_FILE]: {
    applicationId: EnderId;
    file: FilesClientEnderFile;
  };
  [ApplicationPageRightRailEnum.EDIT_MOVE_IN]: unknown;
  [ApplicationPageRightRailEnum.EDIT_PET]: { pet: Pet; ownerId: EnderId };
  [ApplicationPageRightRailEnum.EDIT_RENTAL_HISTORY]: {
    application: GetApplicationGroupResponseApplicationResponse;
  };
  [ApplicationPageRightRailEnum.TRANSFER_APPLICANT]: {
    applicationId?: EnderId;
  };
  [ApplicationPageRightRailEnum.UPLOAD_PET_DOCUMENTS]: {
    petId: EnderId;
    petName?: string;
  };
  [ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_ID]: { applicationId: EnderId };
  [ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_INCOME]: {
    applicationId: EnderId;
  };
  [ApplicationPageRightRailEnum.VIEW_APPLICANT_REPORT]: {
    report: ApplicantScreeningReport;
  };
};

type ApplicationRightRailValue = {
  [K in keyof ApplicationPageRightRailMap]: {
    type: K;
  } & ApplicationPageRightRailMap[K];
}[keyof ApplicationPageRightRailMap];

const ApplicantFileTypeEnum = {
  PROOF_OF_ID: "PROOF_OF_ID",
  PROOF_OF_INCOME: "PROOF_OF_INCOME",
} as const;

type ApplicantFileType = ObjectValues<typeof ApplicantFileTypeEnum>;

export { ApplicantFileTypeEnum, ApplicationPageRightRailEnum };
export type {
  ApplicantFileType,
  ApplicationPageRightRailType,
  ApplicationRightRailValue,
};
