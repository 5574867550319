import { cva } from "class-variance-authority";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";
import type { LinkProps } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";

import type { Sizes } from "@ender/shared/utils/theming";
import { Size } from "@ender/shared/utils/theming";

const RouterLinkVariantGenerator = cva("font-medium", {
  defaultVariants: {
    disabled: false,
    size: Size.md,
  },
  variants: {
    disabled: {
      false: "text-primary-500 hover:underline",
      true: "pointer-events-none text-slate-200",
    },
    size: {
      [Size.lg]: "text-base", //16px
      [Size.md]: "text-sm", //14px
      [Size.sm]: "text-xs", //12px
    },
  },
});

type RouterLinkProps = Pick<LinkProps, "onClick" | "target" | "rel"> & {
  href: string;
  size?: Extract<Sizes, "sm" | "md" | "lg">;
  disabled?: boolean;
};

/**
 * A component built for use in React Router. Intended to eventually be replaced with Link from `shared/ds/link`
 * once we are on next
 */
const RouterLink = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<RouterLinkProps>
>((props, ref) => {
  const { href, target, disabled, children, onClick, rel } = props;

  return (
    <ReactRouterLink
      to={href}
      target={target}
      className={RouterLinkVariantGenerator(props)}
      aria-disabled={disabled}
      onClick={onClick}
      rel={rel}
      ref={ref}>
      {children}
    </ReactRouterLink>
  );
});
RouterLink.displayName = "EnderLink";

export { RouterLink };
export type { RouterLinkProps };
