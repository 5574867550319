import { Predicate as P } from "effect";
import { z } from "zod";

import type { Undefined } from "@ender/shared/constants/general";
import { ALL_FINANCIALLY_RESPONSIBLE_TENANTS } from "@ender/shared/constants/string";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import type { PartyResponse } from "@ender/shared/generated/ender.api.core.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { isMultiple } from "@ender/shared/utils/is";
import { castEnum } from "@ender/shared/utils/zod";

function CounterpartyTupleValue({
  isMultipleUsers,
  isPayable,
  party,
}: {
  isMultipleUsers: boolean;
  isPayable: boolean;
  party?: PartyResponse;
}) {
  if (P.isNullable(party) || P.isNullable(party.type)) {
    return <div>No info</div>;
  }

  const partyName = party.name || "Name not found";

  switch (party.type) {
    case PartyEnum.EXTERNAL:
      return (
        <Tooltip
          label={
            isPayable
              ? "Owed to an external party"
              : "Owed by an external party"
          }>
          <span>External Merchant</span>
        </Tooltip>
      );

    case PartyEnum.LEASE:
      return (
        <EnderLink to={`/leases/${party.id}`}>
          {isPayable && isMultipleUsers
            ? ALL_FINANCIALLY_RESPONSIBLE_TENANTS
            : partyName}
        </EnderLink>
      );

    case PartyEnum.VENDOR:
      return <EnderLink to={`/vendors/${party.id}`}>{partyName}</EnderLink>;

    default:
      return <div>{partyName}</div>;
  }
}

const CounterpartyLabelValues = ["Payee", "Payer"] as const;
const CounterpartyLabelSchema = z.enum(CounterpartyLabelValues);
type CounterpartyLabel = z.infer<typeof CounterpartyLabelSchema>;

const CounterpartyLabelEnum = castEnum<CounterpartyLabel>(
  CounterpartyLabelSchema,
);

/**
 * Invoice Counterparty Tuple
 *
 * Counterparty is "them".
 * In AP, counterparty label is "Payee".
 * In AR, counterparty label is "Payer".
 */
function InvoiceCounterpartyTuple({
  invoice,
}: {
  invoice: Pick<
    InvoiceSerializerInvoiceResponse,
    "invoiceType" | "owedByParty" | "owedToParty" | "users"
  >;
}) {
  const { invoiceType, owedByParty, owedToParty } = invoice;
  const isPayable = invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;
  const label = isPayable
    ? CounterpartyLabelEnum.Payee
    : CounterpartyLabelEnum.Payer;
  const party: PartyResponse | Undefined = isPayable
    ? owedToParty
    : owedByParty;

  return (
    <Tuple
      label={label}
      value={
        <CounterpartyTupleValue
          isMultipleUsers={isMultiple(invoice.users)}
          isPayable={isPayable}
          party={party}
        />
      }
    />
  );
}

export { InvoiceCounterpartyTuple };
