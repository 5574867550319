import { IconSearch } from "@tabler/icons-react";
import {
  Array as A,
  Function as F,
  Hash as H,
  HashMap as HM,
  Match,
  Predicate as P,
} from "effect";
import type {
  ClipboardEvent,
  ElementRef,
  ForwardedRef,
  ReactNode,
  Ref,
} from "react";
import { forwardRef, useCallback, useEffect, useState } from "react";

import { Badge } from "@ender/shared/ds/badge";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Divider } from "@ender/shared/ds/divider";
import { Justify, Overflow, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { ListCheckbox, TriggerButton } from "@ender/shared/ds/menu";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@ender/shared/ds/popover";
import type { SelectOption, SelectValue } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { TextInput } from "@ender/shared/ds/text-input";

function toHashMap<V extends SelectValue = SelectValue, M = unknown>(
  options: SelectOption<V, M>[],
): HM.HashMap<number, SelectOption<V, M>> {
  return F.pipe(
    options,
    A.map((option): readonly [number, SelectOption<V, M>] => {
      return [H.hash(option.value), option];
    }),
    HM.fromIterable,
  );
}

type MultiFilterProps<V extends SelectValue = SelectValue, M = unknown> = {
  data: SelectOption<V, M>[];
  disabled?: boolean;
  keyword: string;
  isLoading?: boolean;
  label?: string;
  onChange: (selected: SelectOption<V, M>[]) => void;
  onKeywordChange: (keyword: string) => void;
  trigger?: ReactNode;
  value: SelectOption<V, M>[];
  onPaste?: (pastedText: string) => Promise<SelectOption<V, M>[]>;
};

/**
 * The difference between a MultiFilter and a MultiSelect is that the MultiFilter requires
 * a distinct confirmation in order to apply the changes the Apply button.
 */
const MultiFilter = forwardRef(function MultiFilter<
  V extends SelectValue = SelectValue,
  M = unknown,
>(
  props: MultiFilterProps<V, M>,
  ref: ForwardedRef<ElementRef<typeof PopoverContent>>,
) {
  const {
    data,
    disabled,
    keyword,
    isLoading = false,
    label,
    onChange,
    onKeywordChange,
    trigger = (
      <TriggerButton rounded={false} disabled={disabled}>
        Filter
      </TriggerButton>
    ),
    value,
    onPaste,
  } = props;

  const [selectedMap, setSelectedMap] = useState(() => toHashMap(value));
  const resetToValue = useCallback(() => {
    setSelectedMap(toHashMap(value));
  }, [value]);

  const [open, setOpened] = useState(false);
  const handleOpenedChange = useCallback(
    (open: boolean) => {
      if (!open) {
        // We need to re-sync the `selectedMap` so the temporary internal state is consistent when re-opened with
        // no change to the external value
        resetToValue();
      }
      setOpened(open);
    },
    [resetToValue],
  );

  /*
   * This side effect is required because we have an internal temporary representation of the value which needs to be
   * synced if the external value is changed while the dialog is closed
   */
  useEffect(() => {
    if (open) {
      return;
    }
    resetToValue();
  }, [open, resetToValue]);

  const handleSelect = useCallback(
    (key: number, option: SelectOption<V, M>) => {
      setSelectedMap(F.flow(HM.set(key, option)));
    },
    [],
  );
  const handleDeselect = useCallback((key: number) => {
    setSelectedMap(F.flow(HM.remove(key)));
  }, []);
  const handleCheckboxChange = useCallback(
    (key: number, option: SelectOption<V, M>) => (selected: boolean) => {
      if (selected) {
        handleSelect(key, option);
        return;
      }
      handleDeselect(key);
    },
    [handleDeselect, handleSelect],
  );
  const handleClearSelection = useCallback(() => {
    setSelectedMap(HM.fromIterable([]));
  }, [setSelectedMap]);

  const handleCancel = useCallback(() => {
    setSelectedMap(toHashMap(value));
  }, [value]);
  const handleApply = useCallback(() => {
    onChange(F.pipe(HM.values(selectedMap), A.fromIterable));
  }, [onChange, selectedMap]);

  const handlePaste = useCallback(
    async (e: ClipboardEvent<HTMLInputElement>) => {
      if (P.isNullable(onPaste)) {
        return;
      }
      const pasted = e.clipboardData?.getData("text/plain");
      const options = await onPaste(pasted);
      if (A.isNonEmptyArray(options)) {
        setSelectedMap((prevMap) =>
          options.reduce((map, option) => {
            const key = H.hash(option.value);
            return HM.set(key, option)(map);
          }, prevMap),
        );
      }
    },
    [onPaste],
  );

  return (
    <Popover opened={open} onOpenedChange={handleOpenedChange}>
      <PopoverTrigger disabled={disabled} label={label}>
        {trigger}
      </PopoverTrigger>
      <PopoverContent ref={ref}>
        <div className="w-96 flex overflow-auto">
          <Stack spacing={Spacing.none} overflow={Overflow.auto} grow>
            <TextInput
              value={keyword}
              onChange={onKeywordChange}
              onPaste={handlePaste}
              leftSection={isLoading ? <LoadingSpinner /> : <IconSearch />}
              borderless
            />
            {Match.value(selectedMap).pipe(
              Match.when(HM.isEmpty, F.constNull),
              Match.orElse((map) => {
                return (
                  <>
                    <Divider />
                    <Card borderless padding="sm">
                      <div className="max-h-36 overflow-auto">
                        <Group>
                          {F.pipe(
                            HM.toEntries(map),
                            A.map(([key, option]) => (
                              <Badge key={key}>{option.label}</Badge>
                            )),
                          )}
                        </Group>
                      </div>
                    </Card>
                  </>
                );
              }),
            )}
            <Divider />
            <div className="max-h-72 overflow-auto">
              <Stack spacing={Spacing.none} grow>
                {Match.value({ isLoading, options: data }).pipe(
                  Match.when(
                    { isLoading: true, options: A.isEmptyArray },
                    () => {
                      return <LoadingSpinner />;
                    },
                  ),
                  Match.when(
                    { isLoading: false, options: A.isEmptyArray },
                    () => {
                      return (
                        <Card padding="sm" borderless>
                          <Text>No Results</Text>
                        </Card>
                      );
                    },
                  ),
                  Match.orElse(({ options }) => {
                    return A.map(options, (option) => {
                      const key = H.hash(option.value);
                      return (
                        <ListCheckbox
                          key={key}
                          label={option.label}
                          onChange={handleCheckboxChange(key, option)}
                          value={F.pipe(selectedMap, HM.has(key))}
                        />
                      );
                    });
                  }),
                )}
              </Stack>
            </div>
            <Divider />
            <Card borderless padding="sm">
              <Group justify={Justify.between}>
                <Button
                  variant={ButtonVariant.transparent}
                  onClick={handleClearSelection}>
                  Clear Selection
                </Button>
                <Group spacing={Spacing.md}>
                  <PopoverClose>
                    <Button
                      variant={ButtonVariant.transparent}
                      onClick={handleCancel}>
                      Cancel
                    </Button>
                  </PopoverClose>
                  <PopoverClose>
                    <Button onClick={handleApply}>Apply</Button>
                  </PopoverClose>
                </Group>
              </Group>
            </Card>
          </Stack>
        </div>
      </PopoverContent>
    </Popover>
  );
}) as <V extends SelectValue = SelectValue, M = unknown>(
  // eslint-disable-next-line no-use-before-define
  props: MultiFilterProps<V, M> & {
    ref?: Ref<ElementRef<typeof PopoverContent>>;
  },
) => ReactNode;

export { MultiFilter };
export type { MultiFilterProps };
