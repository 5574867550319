import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P, String as S } from "effect";
import { useCallback } from "react";
import { useWatch } from "react-hook-form";

import { Form, useForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
  PhoneEffectSchema,
} from "@ender/form-system/schema";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationEmploymentStatus } from "@ender/shared/generated/ender.model.leasing";
import { ApplicationEmploymentStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import type { LabelValue } from "@ender/shared/types/label-value";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const EmploymentStatusDisplayMap: Record<ApplicationEmploymentStatus, string> =
  {
    EMPLOYED: "Employed",
    RETIRED: "Retired",
    SELF_EMPLOYED: "Self Employed",
    STUDENT: "Student",
    UNEMPLOYED: "Unemployed",
  } as const;

const EmploymentStatusData: LabelValue<ApplicationEmploymentStatus>[] =
  Object.entries(EmploymentStatusDisplayMap).map(([value, label]) => ({
    label,
    value: value as ApplicationEmploymentStatus,
  }));

const employedStatuses = new Set<ApplicationEmploymentStatus>([
  ApplicationEmploymentStatusEnum.EMPLOYED,
  ApplicationEmploymentStatusEnum.SELF_EMPLOYED,
]);

const EmploymentFormSchema = Schema.Struct({
  annualSalary: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  employer: Schema.String,
  employmentStartDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  employmentStatus: Schema.Enums(ApplicationEmploymentStatusEnum).pipe(
    Schema.OptionFromSelf,
    // This custom predicate can be replaced or removed once form types are fixed
    Schema.filter(
      (input): input is O.Option<ApplicationEmploymentStatus> =>
        O.isSome(input),
      {
        message: () => "Required",
      },
    ),
  ),
  housingChoiceVoucher: Schema.Boolean,
  managerName: Schema.String,
  managerPhone: PhoneEffectSchema,
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];

    if (
      O.exists(values.employmentStatus, (val) => employedStatuses.has(val)) &&
      (O.isNone(values.annualSalary) ||
        O.exists(values.annualSalary, P.not(Money$.isPositive)))
    ) {
      issues.push({
        message: "Required",
        path: ["annualSalary"],
      });
    }

    if (
      O.exists(values.employmentStatus, (val) => employedStatuses.has(val)) &&
      S.isEmpty(values.employer)
    ) {
      issues.push({
        message: "Required",
        path: ["employer"],
      });
    }

    if (
      O.exists(
        values.employmentStatus,
        (val) => val === ApplicationEmploymentStatusEnum.EMPLOYED,
      ) &&
      S.isEmpty(values.managerName)
    ) {
      issues.push({
        message: "Required",
        path: ["managerName"],
      });
    }
    return issues;
  }),
);

type EmploymentFormInput = Schema.Schema.Encoded<typeof EmploymentFormSchema>;
type EmploymentFormOutput = Schema.Schema.Type<typeof EmploymentFormSchema>;

type UpdateEmploymentFormProps = {
  application: GetApplicationGroupResponseApplicationResponse;
  applicationGroupId: EnderId;
  onCancel: () => void;
  onSuccess: () => void;
};

/**
 * @deprecated use widgets-application-applicant-employment-card
 */
function UpdateEmploymentForm({
  application,
  applicationGroupId,
  onCancel,
  onSuccess,
}: UpdateEmploymentFormProps) {
  const { applicant } = application;
  const form = useForm<EmploymentFormInput>({
    defaultValues: {
      annualSalary: Money$.parse(application.annualSalary),
      employer: application.employer,
      employmentStartDate: LocalDate$.parse(application.employmentStartDate),
      employmentStatus: O.fromNullable(application.employmentStatus),
      housingChoiceVoucher: P.isNotNullable(
        application?.housingChoiceVoucherTimestamp,
      ),
      managerName: application?.managerName ?? "",
      managerPhone: application?.managerPhone ?? "",
    },
    resolver: effectTsResolver(EmploymentFormSchema),
  });
  const watchedEmploymentStatus = useWatch({
    control: form.control,
    name: "employmentStatus",
  });

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (values: EmploymentFormOutput) => {
      const { annualSalary, employmentStartDate, employmentStatus, ...rest } =
        values;
      return ApplicationsAPI.updateApplication({
        annualSalary: O.getOrThrow(annualSalary).toJSON(),
        applicationGroupId,
        employmentStartDate: O.getOrThrow(employmentStartDate).toJSON(),
        employmentStatus: O.getOrThrow(employmentStatus),
        targetUserId: applicant.userId,
        ...rest,
      });
    },
    mutationKey: ["saveApplication", applicationGroupId],
  });

  const handleSubmit = useCallback(
    async (values: EmploymentFormOutput) => {
      const name = `${applicant?.firstName} ${applicant?.lastName}`.trim();
      await mutateAsync(values);
      showSuccessNotification({ message: `Employment for ${name} updated` });
      onSuccess();
    },
    [applicant.firstName, applicant.lastName, mutateAsync, onSuccess],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormSelect
          data={EmploymentStatusData}
          form={form}
          label="Employment Status"
          name="employmentStatus"
          placeholder="Select Employment Status"
        />
        {P.isNotNullable(O.getOrUndefined(watchedEmploymentStatus)) &&
          employedStatuses.has(O.getOrThrow(watchedEmploymentStatus)) && (
            <>
              <FormTextInput
                form={form}
                label="Employer"
                name="employer"
                placeholder="Enter Employer"
              />
              <FormMoneyInput
                form={form}
                label="Annual Salary"
                name="annualSalary"
                placeholder="Enter Annual Salary"
              />
              <FormDateInput
                form={form}
                label="Start Date"
                name="employmentStartDate"
              />
            </>
          )}
        {O.getOrThrow(watchedEmploymentStatus) ===
          ApplicationEmploymentStatusEnum.EMPLOYED && (
          <>
            <FormTextInput
              form={form}
              label="Manager's Name"
              name="managerName"
              placeholder="Enter Manager's Name"
            />
            <FormPhoneInput
              form={form}
              label="Manager's Phone"
              name="managerPhone"
              placeholder="Enter Manager's Phone"
            />
          </>
        )}
        <FormCheckbox
          form={form}
          label="Using Housing Choice Vouchers"
          name="housingChoiceVoucher"
        />
        <Group align={Align.center} justify={Justify.end}>
          <Button
            onClick={onCancel}
            type="button"
            variant={ButtonVariant.transparent}>
            Cancel
          </Button>
          <Button loading={isLoading} type="submit">
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { UpdateEmploymentForm };
