import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O, pipe } from "effect";
import { useCallback } from "react";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetGLJournalEntryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import type { CreateJournalEntryRequestGLTx } from "@ender/shared/generated/ender.model.accounting.request";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type JournalEntryEditFormProps = {
  credits: CreateJournalEntryRequestGLTx[];
  debits: CreateJournalEntryRequestGLTx[];
  transaction: GetGLJournalEntryResponse;
  onSuccess: () => void;
  onCancel: () => void;
};

const AccountingPeriodSchema = Schema.Struct({
  startDate: Schema.String,
});

const JournalEntryEditFormSchema = Schema.Struct({
  accountingPeriod: Schema.OptionFromSelf(AccountingPeriodSchema),
  description: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Description is required" }),
  ),
  transactionDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
});

type JournalEntryEditFormInput = Schema.Schema.Encoded<
  typeof JournalEntryEditFormSchema
>;
type JournalEntryEditFormOutput = Schema.Schema.Type<
  typeof JournalEntryEditFormSchema
>;

function GeneralLedgerTransactionApprovalsEditJournalEntry({
  credits,
  debits,
  transaction,
  onSuccess,
  onCancel,
}: JournalEntryEditFormProps) {
  const { description, id, transactionDate } = transaction;

  const form = useForm<JournalEntryEditFormInput>({
    defaultValues: {
      accountingPeriod: O.none(),
      description: description,
      transactionDate: LocalDate$.parse(transactionDate),
    },
    mode: "onSubmit",
    resolver: effectTsResolver(JournalEntryEditFormSchema),
  });

  const { mutateAsync: updateJournalEntry, isLoading } = useMutation({
    mutationFn: (values: JournalEntryEditFormOutput) =>
      AccountingAPI.editGLJournalEntry({
        credits: credits,
        debits: debits,
        journalEntryId: id,
        ledgerDate: values.transactionDate.pipe(
          O.map((date) => date.toJSON()),
          O.getOrUndefined,
        ),
        period: pipe(
          values.accountingPeriod,
          O.map((period) => LocalDate$.of(period.startDate).toJSON()),
          O.getOrUndefined,
        ),
        title: values.description,
      }),
    mutationKey: ["updateJournalEntry"],
  });

  const handleSubmit = useCallback(
    async (values: JournalEntryEditFormOutput) => {
      await updateJournalEntry(values);
      showSuccessNotification({ message: "Transaction updated" });
      await onSuccess();
    },
    [onSuccess, updateJournalEntry],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack spacing={Spacing.md}>
        <FormDateInput
          form={form}
          label="Transaction Date"
          name="transactionDate"
        />
        <FormAccountingPeriodSelector
          form={form}
          description="Transaction will retain the existing accounting period if you do not specify one."
          label="Accounting Period"
          //@ts-expect-error type 'string' is not assignable to type 'never'.
          name="accountingPeriod"
          periodType={AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER}
        />
        <FormTextInput form={form} label="Description" name="description" />
        <Group spacing={Spacing.sm}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="submit">
            Save Changes
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { GeneralLedgerTransactionApprovalsEditJournalEntry };
