/**
 * Create a Dwolla transfer between two bank accounts owned by the same Firm (n.b. you cannot use a PM-controlled
 Escrow account for this).
 * HTTP Method: POST
 * Pathname: /moneyTransfers/internalDwollaTransfer
 * @function createInternalDwollaTransfer
 * @memberof PaymentsAPI
 * @param {PaymentsAPICreateInternalDwollaTransferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransfer>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { MoneyTransfer } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPICreateInternalDwollaTransferSearchParams = {
  token?: string | undefined;
};

type PaymentsAPICreateInternalDwollaTransferBodyParams = {
  amount: SerializesInto<Money>;
  fromBankAccountId: EnderId;
  memo: string;
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
  propertyId: EnderId;
  toBankAccountId: EnderId;
};

type PaymentsAPICreateInternalDwollaTransferPayload =
  PaymentsAPICreateInternalDwollaTransferSearchParams &
    PaymentsAPICreateInternalDwollaTransferBodyParams;

function createInternalDwollaTransferUnsafeEffect(
  payload: PaymentsAPICreateInternalDwollaTransferPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransfer>({
        body,
        decode: unsafeDecodeJsonResponse<MoneyTransfer>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/moneyTransfers/internalDwollaTransfer",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createInternalDwollaTransfer(
  payload: PaymentsAPICreateInternalDwollaTransferPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransfer> {
  return F.pipe(
    payload,
    createInternalDwollaTransferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createInternalDwollaTransfer };
export type {
  PaymentsAPICreateInternalDwollaTransferBodyParams,
  PaymentsAPICreateInternalDwollaTransferPayload,
  PaymentsAPICreateInternalDwollaTransferSearchParams,
};
