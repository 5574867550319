/**
 * Gets all "in-use" (see BankAccount.java for formal definition) bank accounts associated with the firms.
 * HTTP Method: POST
 * Pathname: /bankAccounts
 * @function searchBankAccountsByFirm
 * @memberof BankingAPI
 * @param {BankingAPISearchBankAccountsByFirmPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchBankAccountResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { SearchBankAccountResponse } from "@ender/shared/generated/ender.model.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPISearchBankAccountsByFirmSearchParams = {
  token?: string | undefined;
};

type BankingAPISearchBankAccountsByFirmBodyParams = {
  filters: ReportFilterRequest[];
  /**
   * Optional, to directly specify firms.
   */
  firmIds: EnderId[];
  fundIds: EnderId[];
  /**
   * Optional, can be used for convenience to specify firms by the properties they own.
   */
  propertyIds: EnderId[];
};

type BankingAPISearchBankAccountsByFirmPayload =
  BankingAPISearchBankAccountsByFirmSearchParams &
    BankingAPISearchBankAccountsByFirmBodyParams;

function searchBankAccountsByFirmUnsafeEffect(
  payload: BankingAPISearchBankAccountsByFirmPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchBankAccountResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<SearchBankAccountResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/bankAccounts",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchBankAccountsByFirm(
  payload: BankingAPISearchBankAccountsByFirmPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchBankAccountResponse[]> {
  return F.pipe(
    payload,
    searchBankAccountsByFirmUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchBankAccountsByFirm };
export type {
  BankingAPISearchBankAccountsByFirmBodyParams,
  BankingAPISearchBankAccountsByFirmPayload,
  BankingAPISearchBankAccountsByFirmSearchParams,
};
