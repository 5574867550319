// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O, pipe, Predicate as P } from "effect";
import { Schema } from "@effect/schema";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, LocalDate$, LocalDateEffectSchema, Money$, MoneyFormSchema, ParseEnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { FormSelect } from "@ender/shared/ds/select";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormDateInput } from "@ender/shared/ds/date-input";


import type {
  InsuranceAPIAddInsurancePayload,
  InsuranceAPIUpdateInsurancePayload,
} from "@ender/shared/generated/ender.api.finance";
import { InsuranceAPI } from "@ender/shared/generated/ender.api.finance";
import type {
  InsurancePolicy,
  InsurancePolicyInsuredParty,
} from "@ender/shared/generated/ender.model.core.vendor";
import { InsurancePolicyInsuredPartyValues } from "@ender/shared/generated/ender.model.core.vendor";
import { fail } from "@ender/shared/utils/error";
import { castEnum } from "@ender/shared/utils/effect";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";

const InsurancePolicyTypeValues = [
  "BUILDING_CODE_COVERAGE",
  "ERRORS_AND_OMISSIONS",
  "FLOOD",
  "GUARANTEED_INCOME_INSURANCE",
  "HEALTH",
  "LIABILITY",
  "PROPERTY",
  "RENTERS",
  "WORKERS_COMPENSATION",
  "AUTOMOTIVE",
  "UMBRELLA",
] as const;
const InsurancePolicyTypeSchema = Schema.Literal(...InsurancePolicyTypeValues);

const InsurancePolicyTypeEnum = castEnum(InsurancePolicyTypeSchema);

const POLICY_TYPE_OPTIONS = [
  { label: "Automotive", value: InsurancePolicyTypeEnum.AUTOMOTIVE },
  {
    label: "Building Code Coverage",
    value: InsurancePolicyTypeEnum.BUILDING_CODE_COVERAGE,
  },
  {
    label: "Errors and Omissions",
    value: InsurancePolicyTypeEnum.ERRORS_AND_OMISSIONS,
  },
  { label: "Flood", value: InsurancePolicyTypeEnum.FLOOD },
  {
    label: "Guaranteed Income Insurance",
    value: InsurancePolicyTypeEnum.GUARANTEED_INCOME_INSURANCE,
  },
  { label: "Health", value: InsurancePolicyTypeEnum.HEALTH },
  { label: "Liability", value: InsurancePolicyTypeEnum.LIABILITY },
  { label: "Property", value: InsurancePolicyTypeEnum.PROPERTY },
  { label: "Renter's", value: InsurancePolicyTypeEnum.RENTERS },
  { label: "Umbrella", value: InsurancePolicyTypeEnum.UMBRELLA },
  {
    label: "Worker's Compensation",
    value: InsurancePolicyTypeEnum.WORKERS_COMPENSATION,
  },
] as const;

const FormSchema = Schema.Struct({
  carrier: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Carrier is required" })
  ),
  coverageAmount: MoneyFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Coverage Amount is required" }),
  ),
  deductible: MoneyFormSchema.pipe(
    Schema.OptionFromSelf,
  ),
  effectiveDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Effective Date is required" }),
  ),
  expirationDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Expiration Date is required" }),
  ),
  insuranceId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
  ),
  insuranceType: InsurancePolicyTypeSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Insurance Type is required" }),
  ),
  monthlyPremium: MoneyFormSchema.pipe(
    Schema.OptionFromSelf,
  ),
  policyNumber: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Policy Number is required" })
  ),
})

type FormValues = Schema.Schema.Encoded<typeof FormSchema>;

// TODO this can be removed when InsurancePolicy.type is typed correctly.
function validatePolicyType(value: string | undefined) {
  return (
    Object.values(InsurancePolicyTypeEnum).find((type) => type === value) ||
    UNDEFINED
  );
}

function getInitialValues(policy?: InsurancePolicy): FormValues {
  return {
    carrier: policy?.carrier || "",
    coverageAmount: Money$.parse(policy?.coverageAmount),
    deductible: Money$.parse(policy?.coverageAmount),
    effectiveDate: LocalDate$.parse(policy?.effectiveDate),
    expirationDate: LocalDate$.parse(policy?.expirationDate),
    insuranceId: O.fromNullable(policy?.id),
    insuranceType: O.fromNullable(validatePolicyType(policy?.type)),
    monthlyPremium: Money$.parse(policy?.monthlyPremium),
    policyNumber: policy?.policyNumber || "",
  };
}

function transformToCreatePayload(props: {
  values: FormValues;
  insuredPartyId: EnderId;
  insuredPartyType: InsurancePolicyInsuredParty;
}): InsuranceAPIAddInsurancePayload {
  const { values, insuredPartyId, insuredPartyType} = props;

  return {
    carrier: values.carrier,
    coverageAmount: pipe(
      values.coverageAmount,
      O.getOrThrow,
    ),
    deductible: pipe(
      values.deductible,
      O.getOrUndefined,
    ),
    effectiveDate: pipe(
      values.effectiveDate,
      O.getOrThrow,
    ),
    expirationDate: pipe(
      values.expirationDate,
      O.getOrThrow,
    ),
    insuranceType: pipe(
      values.insuranceType,
      O.getOrThrow,
    ),
    insuredPartyId: ParseEnderId(insuredPartyId),
    insuredPartyType,
    monthlyPremium: pipe(
      values.deductible,
      O.getOrUndefined,
    ),
    policyNumber: values.policyNumber,
  };
}

function transformToUpdatePayload(
  values: FormValues,
): InsuranceAPIUpdateInsurancePayload {
  return {
    carrier: values.carrier,
    coverageAmount: pipe(
      values.coverageAmount,
      O.getOrThrow,
    ),
    deductible: pipe(
      values.deductible,
      O.getOrUndefined,
    ),
    effectiveDate: pipe(
      values.effectiveDate,
      O.getOrThrow,
    ),
    expirationDate: pipe(
      values.expirationDate,
      O.getOrThrow,
    ),
    insuranceId: pipe(
      values.insuranceId,
      O.getOrThrow
    ),
    insuranceType: pipe(
      values.insuranceType,
      O.getOrThrow
    ),
    monthlyPremium: pipe(
      values.monthlyPremium,
      O.getOrUndefined,
    ),
    policyNumber: values.policyNumber,
  };
}

type CreateUpdateInsurancePolicyModalProps = {
  onSuccess?: () => void;
  policy?: InsurancePolicy;
  insuredPartyId?: EnderId;
  insuredPartyType?: InsurancePolicyInsuredParty;
};

/**
 * @deprecated move this out of /ui/ and into the insurance package where it is used.
 * this is not a shared component
 */
function CreateUpdateInsurancePolicyModal({
  policy,
  insuredPartyId,
  insuredPartyType,
  onSuccess = F.constVoid,
}: CreateUpdateInsurancePolicyModalProps) {
  const form = useEffectSchemaForm({
    defaultValues: getInitialValues(policy),
    schema: FormSchema,
  });

  const { mutateAsync: updatePolicy } = useMutation({
    mutationFn: (policy: InsuranceAPIUpdateInsurancePayload) =>
      InsuranceAPI.updateInsurance(policy),
    mutationKey: ["InsuranceAPI.updateInsurance"] as const,
  });

  const { mutateAsync: createPolicy } = useMutation({
    mutationFn: (policy: InsuranceAPIAddInsurancePayload) =>
      InsuranceAPI.addInsurance(policy),
    mutationKey: ["InsuranceAPI.addInsurance"] as const,
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      if (policy) {
        await updatePolicy(transformToUpdatePayload(values));
      } else {
        if (P.isUndefined(insuredPartyId)) {
          throw new Error("Missing insuredPartyId")
        }
        else if (P.isUndefined(insuredPartyType) || !InsurancePolicyInsuredPartyValues.includes(insuredPartyType)) {
          throw new Error("Invalid or missing insuredPartyType");
        }
        else {
          await createPolicy(
            transformToCreatePayload({
              insuredPartyId,
              insuredPartyType,
              values,
            }),
          );
        }
      }
      onSuccess();
    } catch (err) {
      fail(err);
    }
  };

  
  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput
          form={form}
          label="Carrier"
          name="carrier"
        />
        <FormTextInput
          form={form}
          autoComplete="off"
          label="Policy Number"
          name="policyNumber"
        />
        <FormSelect
          form={form}
          data={[...POLICY_TYPE_OPTIONS]}
          label="Policy Type"
          name="insuranceType"
          placeholder="Choose Policy Type"
        />
        <FormMoneyInput
          form={form}
          label="Coverage Amount"
          name="coverageAmount"
        />
        <FormMoneyInput
          form={form}
          label="Monthly Premium (optional)"
          name="monthlyPremium"
        />
        <FormMoneyInput
          form={form}
          label="Deductible (optional)"
          name="deductible"
        />
        <FormDateInput
          form={form}
          label="Effective Date"
          name="effectiveDate"
        />
        <FormDateInput
          form={form}
          label="Expiration Date"
          name="expirationDate"
        />
        <Group justify={Justify.end}>
          <Button type="submit">{policy ? "Save" : "Add Policy"}</Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { CreateUpdateInsurancePolicyModal };
