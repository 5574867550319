import { forwardRef } from "react";
import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";
import type { InputProps } from "@ender/shared/ds/input";
import { Input } from "@ender/shared/ds/input";

type PasswordInputProps = {
  value: string;
  onChange: (value: string) => void;
} & Omit<
  InputProps,
  "value" | "onChange" | "onBlur" | "onFocus" | "data-private" | "inputType"
>;

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(props, ref) {
    const {
      borderless,
      value,
      onChange,
      label,
      error,
      description,
      disabled,
      placeholder,
      leftSection,
      rightSection,
      size,
      name,
      textAlign,
      role,
    } = props;

    return (
      <Input
        borderless={borderless}
        data-private
        description={description}
        disabled={disabled}
        error={error}
        inputType="password"
        label={label}
        leftSection={leftSection}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        rightSection={rightSection}
        role={role}
        size={size}
        textAlign={textAlign}
        value={value}
      />
    );
  },
);

type FormPasswordInputProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<PasswordInputProps, Form, Name>;

function FormPasswordInput<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormPasswordInputProps<Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field, error }) => (
        <PasswordInput {...rest} {...field} error={error} />
      )}
    </FormField>
  );
}

export { FormPasswordInput };

export type { FormPasswordInputProps };
