import { useMutation } from "@tanstack/react-query";
import { Predicate as P, String as S } from "effect";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { NULL } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Overflow } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { TabButton, TabContent, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  PropertiesAPI,
  UnitsAPI,
} from "@ender/shared/generated/ender.api.core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetLeadResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useClipboard } from "@ender/shared/hooks/use-clipboard";
import { useEnvironmentStore } from "@ender/shared/stores/environment-store";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { castEnum } from "@ender/shared/utils/zod";
import { Chat } from "@ender/widgets/chat";

import { CreateUpdateProspectForm } from "./create-update-prospect/create-update-prospect-form";
import { PropertyOfInterest } from "./property-of-interest";
import { ProspectShowingModal } from "./prospect-showing-modal";

const ProspectTabValues = [
  "MESSAGES",
  "NOTES",
  "PROSPECT_INFO",
  "PROPERTY_OF_INTEREST",
] as const;

const ProspectTabSchema = z.enum(ProspectTabValues);

type ProspectTab = z.infer<typeof ProspectTabSchema>;

const ProspectTabEnum = castEnum<ProspectTab>(ProspectTabSchema);

// TODO: Logic for retrieving URL's that are PMCompany dependent should live in BE
const EVERGREEN = "evergreen";
const EVERGREEN_PROD_SHOWING_URL = `https://evergreen.behome247.com/app/tours/schedule/`; // for use in production
const EVERGREEN_TEST_SHOWING_URL = `https://ender.behome247.com/app/tours/schedule/`; //for non-prod environments

type ProspectDetailsProps = {
  prospect: GetLeadResponse;
  onProspectUpdated: () => void;
  onArchiveProspect: () => void;
};

function ProspectDetails({
  prospect,
  onProspectUpdated,
  onArchiveProspect,
}: ProspectDetailsProps) {
  const [isShowingModalOpen, setIsShowingModalOpen] = useState(false);
  const clipboard = useClipboard({ timeout: 2000 });
  const [unitApplicationUrl, setUnitApplicationUrl] = useState("");
  const [showingUrl, setShowingUrl] = useState("");
  const { userPM } = useContext(UserContext);
  const [isApplicationSent, applicationSentHandlers] = useBoolean(false);
  const [tab, setTab] = useState(ProspectTabEnum.MESSAGES);

  const isProduction = useEnvironmentStore.use.isProduction();

  const isUserEvergreen = useMemo(() => {
    return userPM.shortName === EVERGREEN;
  }, [userPM]);

  function closeShowingModal() {
    setIsShowingModalOpen(false);
  }

  function onTourScheduled() {
    showSuccessNotification({ message: `Tour Scheduled for ${prospect.name}` });
    closeShowingModal();
  }

  const { mutateAsync: sendApplication, isLoading: isSendingApplication } =
    useMutation({
      mutationKey: ["LeasingAPI.sendApplicationUrl"] as const,
      mutationFn: LeasingAPI.sendApplicationUrl,
      onSuccess: () => {
        applicationSentHandlers.toggle(true);
        showSuccessNotification({ message: "Application sent" });
      },
      onError: (err) => fail(err),
    });

  function onCopyShowingURLClick() {
    clipboard.copy(showingUrl);
  }

  const loadProspectUnitApplicationUrl = useCallback(async () => {
    if (P.isUndefined(prospect.unitId)) {
      return;
    }
    try {
      const unitDetails = await UnitsAPI.getUnitDetails({
        unitId: prospect.unitId,
      });
      setUnitApplicationUrl(unitDetails.applicationUrl);
    } catch (err) {
      fail(err);
    }
  }, [prospect.unitId]);

  const loadShowingUrl = useCallback(async () => {
    if (P.isNullable(prospect.propertyId)) {
      return;
    }
    try {
      const property = await PropertiesAPI.getProperty({
        propertyId: prospect.propertyId,
      });
      const { units } = property;
      if (units?.length === 1) {
        const urlPartial = isProduction
          ? EVERGREEN_PROD_SHOWING_URL
          : EVERGREEN_TEST_SHOWING_URL;
        setShowingUrl(`${urlPartial}${units[0].id}`);
      }
    } catch (err) {
      fail(err);
    }
  }, [prospect.propertyId, isProduction]);

  // lazy useEffect
  useEffect(() => {
    if (P.isNotNullable(prospect.unitId)) {
      loadProspectUnitApplicationUrl();
    } else {
      setUnitApplicationUrl("");
    }

    if (P.isNotNullable(prospect.propertyId) && isUserEvergreen) {
      loadShowingUrl();
    } else {
      setShowingUrl("");
    }
  }, [
    prospect,
    isUserEvergreen,
    loadShowingUrl,
    loadProspectUnitApplicationUrl,
  ]);

  return (
    <>
      <Stack grow overflow={Overflow.auto} key={prospect.id}>
        <div>
          {prospect.isArchived ? (
            NULL
          ) : (
            <Group>
              {S.isNonEmpty(unitApplicationUrl) && (
                <Button
                  variant={ButtonVariant.outlined}
                  disabled={isSendingApplication || isApplicationSent}
                  onClick={() =>
                    P.isNotNullable(prospect.unitId) &&
                    sendApplication({
                      unitId: prospect.unitId,
                      userId: prospect.userId,
                    })
                  }>
                  {isApplicationSent ? "Application Sent!" : "Send Application"}
                </Button>
              )}
              {S.isNonEmpty(showingUrl) && (
                <Button
                  variant={ButtonVariant.outlined}
                  onClick={onCopyShowingURLClick}>
                  {clipboard.copied
                    ? "Copied to clipboard!"
                    : "Copy Showing URL"}
                </Button>
              )}
              {!userPM.beHomeEnabled && (
                <Button
                  variant={ButtonVariant.outlined}
                  onClick={() => setIsShowingModalOpen(true)}>
                  Schedule Tour
                </Button>
              )}
              {!prospect.isArchived && (
                <Button
                  variant={ButtonVariant.outlined}
                  onClick={onArchiveProspect}>
                  Archive
                </Button>
              )}
            </Group>
          )}
        </div>
        <div className="grid h-full min-h-0">
          <Tabs value={tab} onChange={setTab}>
            <Stack overflow={Overflow.auto}>
              <TabsList>
                <TabButton value={ProspectTabEnum.MESSAGES}>Messages</TabButton>
                <TabButton value={ProspectTabEnum.NOTES}>Notes</TabButton>
                <TabButton value={ProspectTabEnum.PROSPECT_INFO}>
                  Prospect Info
                </TabButton>
                <TabButton value={ProspectTabEnum.PROPERTY_OF_INTEREST}>
                  Property of Interest
                </TabButton>
              </TabsList>
              <Card shrink grow>
                <TabContent value={ProspectTabEnum.MESSAGES}>
                  <Chat
                    title="Chat with Prospect"
                    channels={[
                      {
                        chatTitle: "Chat with Prospect",
                        isInternal: false,
                        modelId: prospect.leadId,
                        modelType: ModelTypeEnum.LEAD,
                      },
                    ]}
                  />
                </TabContent>
                <TabContent value={ProspectTabEnum.NOTES}>
                  <Chat
                    title="Notes"
                    channels={[
                      {
                        chatTitle: "Notes",
                        isInternal: true,
                        modelId: prospect.leadId,
                        modelType: ModelTypeEnum.LEAD,
                      },
                    ]}
                  />
                </TabContent>
                <TabContent value={ProspectTabEnum.PROSPECT_INFO}>
                  <CreateUpdateProspectForm
                    prospect={prospect}
                    onSuccess={onProspectUpdated}
                  />
                </TabContent>
                <TabContent value={ProspectTabEnum.PROPERTY_OF_INTEREST}>
                  <PropertyOfInterest
                    prospect={prospect}
                    onProspectUpdated={onProspectUpdated}
                  />
                </TabContent>
              </Card>
            </Stack>
          </Tabs>
        </div>
      </Stack>
      <Modal
        title={`Schedule Tour for ${prospect.name || prospect.phone || prospect.email}`}
        opened={isShowingModalOpen}
        onClose={closeShowingModal}>
        <ProspectShowingModal prospect={prospect} onSuccess={onTourScheduled} />
      </Modal>
    </>
  );
}

export { ProspectDetails };
