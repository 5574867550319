import type { Undefined } from "@ender/shared/constants/general";
import type { UnitAreaSummaryResponse } from "@ender/shared/generated/com.ender.middle.response";
import { capitalize } from "@ender/shared/utils/string";

const UnitAreaSummaryDisplayLabelMap: Record<
  keyof UnitAreaSummaryResponse,
  string
> = {
  applianceFinishType: "Appliance Finish",
  foundationType: "Foundation Type",
  hasBasement: "Basement",
  hasCarport: "Carport",
  hasFence: "Fence",
  hasFireplace: "Fireplace",
  hasGarage: "Garage",
  hasLaundryHookups: "Washer/Dryer Hookups",
  hasPool: "Pool",
  homeType: "Home Style",
  neighborhoodRating: "Neighborhood Score",
  numBathroomAreas: "Bath Count",
  numBedroomAreas: "Bed Count",
  numCeilingFans: "# of Ceiling Fans",
  numCoveredParkings: "Covered Parking Spots",
  numDoors: "# of Doors",
  numStories: "Stories",
  wasteWaterType: "Sewer/Septic",
  yearBuilt: "Year Built",
};

function getTupleDisplayValue(
  value: boolean | number | string | Undefined,
): string {
  switch (typeof value) {
    case "boolean":
      return value ? "Yes" : "No";
    case "number":
      return value.toString();
    case "string":
      return capitalize(value);
    case "undefined":
    default:
      return "";
  }
}
export { getTupleDisplayValue, UnitAreaSummaryDisplayLabelMap };
