/**
 * HTTP Method: POST
 * Pathname: /deleteRecurringGLJournalEntry
 * @function deleteRecurringGLJournalEntry
 * @memberof AccountingAPI
 * @param {AccountingAPIDeleteRecurringGLJournalEntryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIDeleteRecurringGLJournalEntrySearchParams = {
  token?: string | undefined;
};

type AccountingAPIDeleteRecurringGLJournalEntryBodyParams = {
  id: EnderId;
};

type AccountingAPIDeleteRecurringGLJournalEntryPayload =
  AccountingAPIDeleteRecurringGLJournalEntrySearchParams &
    AccountingAPIDeleteRecurringGLJournalEntryBodyParams;

function deleteRecurringGLJournalEntryUnsafeEffect(
  payload: AccountingAPIDeleteRecurringGLJournalEntryPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/deleteRecurringGLJournalEntry",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteRecurringGLJournalEntry(
  payload: AccountingAPIDeleteRecurringGLJournalEntryPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteRecurringGLJournalEntryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteRecurringGLJournalEntry };
export type {
  AccountingAPIDeleteRecurringGLJournalEntryBodyParams,
  AccountingAPIDeleteRecurringGLJournalEntryPayload,
  AccountingAPIDeleteRecurringGLJournalEntrySearchParams,
};
