/**
 * HTTP Method: GET
 * Pathname: /middleLayer/payableCategories
 * @function getCategoryNamesAndSubCategoryNames
 * @memberof PayableCategorizationMiddleLayerAPI
 * @param {PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Partial<Record<string, PayableCategory[]>>>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PayableCategory } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesSearchParams =
  {
    token?: string | undefined;
    glCategoryId?: EnderId | undefined;
  };

type PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesPayload =
  PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesSearchParams;

function getCategoryNamesAndSubCategoryNamesUnsafeEffect(
  payload: PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesPayload,
) {
  const { token, glCategoryId, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Partial<Record<string, PayableCategory[]>>>({
        body,
        decode: unsafeDecodeJsonResponse<
          Partial<Record<string, PayableCategory[]>>
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/middleLayer/payableCategories",
        searchParams: [
          ["token", token],
          ["glCategoryId", glCategoryId],
        ],
      }),
    ),
  );
}

function getCategoryNamesAndSubCategoryNames(
  payload: PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesPayload,
  options?: { signal?: AbortSignal },
): Promise<Partial<Record<string, PayableCategory[]>>> {
  return F.pipe(
    payload,
    getCategoryNamesAndSubCategoryNamesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCategoryNamesAndSubCategoryNames };
export type {
  PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesPayload,
  PayableCategorizationMiddleLayerAPIGetCategoryNamesAndSubCategoryNamesSearchParams,
};
