import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useMemo, useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { GetBankTransactionsRequestMatchFilterEnum } from "@ender/shared/generated/ender.api.model";
import type { BankRecRowEnderBatchResponse } from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";
import { Text, TextAlign } from "@ender/shared/ds/text";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { handleFetchWithWarnings } from "@ender/shared/utils/handle-fetch-with-warnings";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { BatchMatchCandidateRow } from "../batch-match-candidate-row/batch-match-candidate-row";

import styles from "./banking-detail-batch-matcher.module.css";
import { Color } from "@ender/shared/utils/theming";

const EMPTY_TRANSACTION = {
  isDeposit: false,
  targetAmount: UNDEFINED,
  targetBankTransaction: UNDEFINED,
};

type EnderIdArray = EnderId[];

type SelectAllCheckboxProps = {
  selectedValues: EnderIdArray;
  allValues: EnderIdArray;
  onChange: (val: EnderIdArray) => void;
};

type MatchCandidate = BankRecRowEnderBatchResponse & { description: string };

type ResponseErrorArray = { [key: string]: string }[];

/**
 * @deprecated use Checkbox directly
 */
function SelectAllCheckbox({
  selectedValues,
  allValues,
  onChange,
}: SelectAllCheckboxProps) {
  return (
    <Checkbox
      value={A.isNonEmptyArray(selectedValues)}
      indeterminate={
        A.isNonEmptyArray(selectedValues) &&
        selectedValues.length !== allValues.length
      }
      onChange={() =>
        onChange(selectedValues.length === allValues.length ? [] : allValues)
      }
    />
  );
}

type BankingDetailBatchMatcherProps = {
  bankAccountId: EnderId;
  bankTransactionId: EnderId;
  endDate: LocalDate;
  onSuccess: () => void;
  startDate: LocalDate;
  closeModal: () => void;
  propertyFilterValue: EnderIdArray;
};

function BankingDetailBatchMatcher({
  bankAccountId,
  bankTransactionId,
  endDate,
  onSuccess,
  startDate,
  closeModal,
  propertyFilterValue,
}: BankingDetailBatchMatcherProps) {
  const { data: allBankTransactions, isLoading: isLoadingBankTransactions } =
    useQuery({
      queryFn: ({ signal }) =>
        BankingAPI.getTransactions(
          {
            bankAccountId,
            inclusiveEndDate: endDate,
            limit: 100000, // Arbitrary limit to get all transactions. | if no limit, be sets 25 as the limit
            matchFilter: GetBankTransactionsRequestMatchFilterEnum.UNMATCHED,
            propertyIds: propertyFilterValue,
            startDate,
          },
          { signal },
        ),
      queryKey: ["BankingAPI.getTransactions", bankAccountId] as const,
      select: (data) => data.bankDetails.rows?.results ?? [],
    });

  const { isDeposit, targetAmount, targetBankTransaction } = useMemo(() => {
    if (P.isNullable(allBankTransactions)) {
      return EMPTY_TRANSACTION;
    }
    const _bankTransaction = allBankTransactions.find(
      ({ bankTransaction }) => bankTransaction?.id === bankTransactionId,
    )?.bankTransaction;

    if (P.isNullable(_bankTransaction)) {
      return EMPTY_TRANSACTION;
    }

    const targetAmount = Money$.of(_bankTransaction.amount);

    return {
      isDeposit: Money$.isNegative(targetAmount),
      targetAmount,
      targetBankTransaction: _bankTransaction,
    };
  }, [allBankTransactions, bankTransactionId]);

  const [showDeposits, setShowDeposits] = useState(isDeposit);
  const [showDraws, setShowDraws] = useState(!isDeposit);
  const matchCandidates = useMemo<MatchCandidate[]>(() => {
    if (P.isNullable(allBankTransactions)) {
      return [];
    }

    // List of singleton banking batch rows.
    const filteredBankTransactions = allBankTransactions.filter((row) => {
      if (
        row.rowType !== BankRecRowTypeEnum.UNMATCHED_ENDER ||
        row.isMatchedEver ||
        P.isNullable(row.enderBatch)
      ) {
        return false;
      }

      return (
        (!Money$.isZero(Money$.of(row.draw)) && showDraws) ||
        (!Money$.isZero(Money$.of(row.deposit)) && showDeposits)
      );
    }) as { enderBatch: BankRecRowEnderBatchResponse; description: string }[];

    return filteredBankTransactions.map(({ enderBatch, description }) => ({
      ...enderBatch,
      description,
    }));
  }, [allBankTransactions, showDeposits, showDraws]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState<EnderIdArray>(
    [],
  );
  const [errors, setErrors] = useState<ResponseErrorArray>([]);

  const selectedTransactions = useMemo(
    () =>
      selectedCandidates
        .map((id) => matchCandidates.find((candidate) => candidate.id === id))
        .filter(P.isNotNullable),
    [matchCandidates, selectedCandidates],
  );
  const totalSelectedBalance = useMemo(() => {
    if (A.isEmptyArray(selectedTransactions)) {
      return Money$.zero();
    }

    return selectedTransactions
      .map((c) => Money$.of(c.amount))
      .reduce(Money$.add, Money$.zero());
  }, [selectedTransactions]);

  function handleChangeShowDraws(checked: boolean) {
    if (!checked && !showDeposits) {
      return;
    }

    setShowDraws(checked);
  }

  function handleChangeShowDeposits(checked: boolean) {
    if (!checked && !showDraws) {
      return;
    }

    setShowDeposits(checked);
  }

  const select = (candidate: MatchCandidate) => {
    setSelectedCandidates((oldSelectedCandidates) => [
      ...oldSelectedCandidates,
      candidate.id,
    ]);
    setErrors([]);
  };

  const maybeNegate = (money: Money$.Money) =>
    isDeposit ? money.negate() : money;

  const deselect = (candidate: MatchCandidate) => {
    setSelectedCandidates(
      selectedCandidates.filter(
        (selectedCandidate) => selectedCandidate !== candidate.id,
      ),
    );
    setErrors([]);
  };

  function onSelectAll(val: EnderIdArray) {
    setSelectedCandidates(val);
    setErrors([]);
  }

  const { mutateAsync: manualMatchMutation } = useMutation({
    mutationKey: ["BankingAPI.manualMatch"] as const,
    mutationFn: BankingAPI.manualMatch,
  });
  const submitBatchMatch = async () => {
    setIsProcessing(true);
    handleFetchWithWarnings(
      manualMatchMutation,
      {
        loadingMessage: "Submitting batch match",
        onSuccess: () => {
          onSuccess();
          setIsProcessing(false);
          showSuccessNotification({
            message: "Transaction has been reconciled.",
          });
          closeModal();
        },
        onUnhandledError: () => setIsProcessing(false),
        showLoading: true,
      },

      {
        bankTransactionId,
        enderBatchIds: selectedCandidates,
        overrideWarnings: false,
      },
    ).catch((failedRequest) => {
      setIsProcessing(false);
      setErrors(failedRequest.json.errors);
    });
  };

  function submitIsDisabled() {
    return (
      !Money$.Equivalence(
        totalSelectedBalance,
        targetAmount ?? Money$.zero(),
      ) ||
      isProcessing ||
      A.isNonEmptyArray(errors)
    );
  }

  if (
    isLoadingBankTransactions ||
    P.isNullable(targetBankTransaction) ||
    P.isNullable(targetAmount)
  ) {
    return (
      <Group align={Align.center} justify={Justify.center}>
        <LoadingSpinner />
      </Group>
    );
  }

  return (
    <Stack>
      <H2>Reconciliation Batch Matcher</H2>
      <div className="center">
        <div className={styles.amounts}>
          <div className="grid-table grid-table--flat grid-table--transparent">
            <div className={styles.body}>
              <div className={styles.row}>
                <div>Transaction Amount:</div>
                <div className="right">
                  <MoneyDisplay value={maybeNegate(targetAmount)} showSymbol />
                </div>
              </div>
              <div className={styles.row}>
                <div>Total applied:</div>
                <Group justify={Justify.end}>
                  <MoneyDisplay
                    value={maybeNegate(totalSelectedBalance)}
                    showSymbol
                  />
                </Group>
              </div>
              <div className={styles.row}>
                <div>Amount left to reconcile</div>
                <Group justify={Justify.end}>
                  <MoneyDisplay
                    value={maybeNegate(
                      targetAmount.subtract(totalSelectedBalance),
                    )}
                    showSymbol
                  />
                </Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={Spacing.md}>
        <div className={styles.transactions}>
          <div style={{ marginTop: "16px" }}>
            <Group spacing={Spacing.md}>
              <div>Show Draws</div>
              <Checkbox value={showDraws} onChange={handleChangeShowDraws} />
              <div />
              <div>Show Deposits</div>
              <Checkbox
                value={showDeposits}
                onChange={handleChangeShowDeposits}
              />
            </Group>
          </div>
          <div className="grid-table__row">
            <div className="date" />
            <div className="description" />
            {showDraws && (
              <div className="amount" style={{ marginRight: "1em" }}>
                Draw
              </div>
            )}
            {showDeposits && (
              <div className="amount" style={{ marginRight: "1em" }}>
                Deposit
              </div>
            )}
            <div className="checkbox" />
          </div>
          <div className="grid-table__header">
            <div className="grid-table__row">
              <div className="numeric date">
                {EnderDate.of(targetBankTransaction.date).toSlashDateString()}
              </div>
              <div className="description">
                {targetBankTransaction.description}
              </div>
              <div className="amount">
                <MoneyDisplay value={maybeNegate(targetAmount)} showSymbol />
              </div>
              <div>
                <SelectAllCheckbox
                  selectedValues={selectedCandidates}
                  allValues={matchCandidates.map((candidate) => candidate.id)}
                  onChange={onSelectAll}
                />
              </div>
            </div>
          </div>
          <div className="grid-table__body">
            {matchCandidates.map((candidate) => {
              return (
                <BatchMatchCandidateRow
                  key={candidate.id}
                  showDraws={showDraws}
                  showDeposits={showDeposits}
                  matchCandidate={candidate}
                  select={() => select(candidate)}
                  deselect={() => deselect(candidate)}
                  isSelected={selectedCandidates.includes(candidate.id)}
                />
              );
            })}
          </div>
        </div>
        {A.isEmptyArray(matchCandidates) ? (
          <div className="center">No transactions available to match</div>
        ) : (
          ""
        )}
        <div className={styles.submitAndErrors}>
          {A.isNonEmptyArray(errors) &&
            errors.map((error, index) => (
              <Text key={index} color={`${Color.red}-500`} align={TextAlign.center}>
                {JSON.stringify(error)}
              </Text>
            ))}
          <Group justify={Justify.end}>
            <Button onClick={submitBatchMatch} disabled={submitIsDisabled()}>
              Reconcile
            </Button>
          </Group>
        </div>
      </Stack>
    </Stack>
  );
}

export { BankingDetailBatchMatcher };
