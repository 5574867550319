/**
 * HTTP Method: POST
 * Pathname: /text-templates/{templateId}/render
 * @function renderTemplate
 * @memberof TemplatesAPI
 * @param {TemplatesAPIRenderTemplatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TemplatesAPIRenderTemplateResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TemplatesAPIRenderTemplateResponse } from "../templates-api-render-template-response";

type TemplatesAPIRenderTemplatePathParams = {
  templateId: EnderId;
};

type TemplatesAPIRenderTemplateSearchParams = {
  token?: string | undefined;
};

type TemplatesAPIRenderTemplateBodyParams = {
  modelId: EnderId;
  modelType: ModelType;
  pmSignerId?: EnderId | undefined;
};

type TemplatesAPIRenderTemplatePayload = TemplatesAPIRenderTemplatePathParams &
  TemplatesAPIRenderTemplateSearchParams &
  TemplatesAPIRenderTemplateBodyParams;

function renderTemplateUnsafeEffect(
  payload: TemplatesAPIRenderTemplatePayload,
) {
  const { templateId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TemplatesAPIRenderTemplateResponse>({
        body,
        decode: unsafeDecodeJsonResponse<TemplatesAPIRenderTemplateResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/text-templates/${templateId}/render`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function renderTemplate(
  payload: TemplatesAPIRenderTemplatePayload,
  options?: { signal?: AbortSignal },
): Promise<TemplatesAPIRenderTemplateResponse> {
  return F.pipe(
    payload,
    renderTemplateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { renderTemplate };
export type {
  TemplatesAPIRenderTemplateBodyParams,
  TemplatesAPIRenderTemplatePathParams,
  TemplatesAPIRenderTemplatePayload,
  TemplatesAPIRenderTemplateSearchParams,
};
