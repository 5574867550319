import {
  IconCurrencyDollar,
  IconDogBowl,
  IconEyeSearch,
  IconId,
  IconUserCircle,
} from "@tabler/icons-react";
import { Predicate as P } from "effect";
import { forwardRef } from "react";

import { ApplicationProgressIcon } from "@ender/entities/application-progress-icon";
import { UNDEFINED } from "@ender/shared/constants/general";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";

type ApplicationApplicantProgressProps = {
  /**
   * we only need a few props of application
   */
  application: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "completion"
  >;
};

const ApplicationApplicantProgress = forwardRef<
  HTMLDivElement,
  ApplicationApplicantProgressProps
>(function ApplicationApplicantProgress(props, ref) {
  const { application } = props;
  const { completion } = application;
  return (
    <Group align={Align.end} ref={ref}>
      {/* <span>
          <Text>Not Started</Text>
          <Text>Send Reminder</Text>
        </span> */}
      <Group align={Align.end}>
        {P.isNotNullable(completion?.completedPersonal) && (
          <Tooltip label="Personal info and TOS agreement">
            <ApplicationProgressIcon
              color="gray"
              indicator={completion.completedPersonal ? "check" : UNDEFINED}
              disabled={!completion.completedPersonal}>
              <IconUserCircle />
            </ApplicationProgressIcon>
          </Tooltip>
        )}
        {P.isNotNullable(completion?.completedPets) && (
          <Tooltip label="Pet info and documents">
            <ApplicationProgressIcon
              color="gray"
              indicator={completion.completedPets ? "check" : UNDEFINED}
              disabled={!completion.completedPets}>
              <IconDogBowl />
            </ApplicationProgressIcon>
          </Tooltip>
        )}
        {P.isNotNullable(completion?.completedPaystubAndId) && (
          <Tooltip label="Employment and Income">
            <ApplicationProgressIcon
              color="gray"
              indicator={completion.completedPaystubAndId ? "check" : UNDEFINED}
              disabled={!completion.completedPaystubAndId}>
              <IconId />
            </ApplicationProgressIcon>
          </Tooltip>
        )}
        {P.isNotNullable(completion?.completedIdentityVerification) && (
          <Tooltip label="Identity Verification">
            <ApplicationProgressIcon
              color="gray"
              indicator={
                completion.completedIdentityVerification ? "check" : UNDEFINED
              }
              disabled={!completion.completedIdentityVerification}>
              <IconEyeSearch />
            </ApplicationProgressIcon>
          </Tooltip>
        )}
        {P.isNotNullable(completion?.completedPayment) && (
          <Tooltip label="Application Fee payment">
            <ApplicationProgressIcon
              color="gray"
              indicator={completion.completedPayment ? "check" : UNDEFINED}
              disabled={!completion.completedPayment}>
              <IconCurrencyDollar />
            </ApplicationProgressIcon>
          </Tooltip>
        )}
      </Group>
    </Group>
  );
});

export { ApplicationApplicantProgress };

export type { ApplicationApplicantProgressProps };
