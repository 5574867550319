/**
 * HTTP Method: POST
 * Pathname: /chargeSchedules/{chargeScheduleId}/deactivate
 * @function deactivateChargeSchedule
 * @memberof ChargeSchedulesAPI
 * @param {ChargeSchedulesAPIDeactivateChargeSchedulePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ChargeSchedule>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { ChargeSchedule } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ChargeSchedulesAPIDeactivateChargeSchedulePathParams = {
  chargeScheduleId: EnderId;
};

type ChargeSchedulesAPIDeactivateChargeScheduleSearchParams = {
  token?: string | undefined;
};

type ChargeSchedulesAPIDeactivateChargeScheduleBodyParams = {
  inclusiveEndDate: SerializesInto<LocalDate>;
};

type ChargeSchedulesAPIDeactivateChargeSchedulePayload =
  ChargeSchedulesAPIDeactivateChargeSchedulePathParams &
    ChargeSchedulesAPIDeactivateChargeScheduleSearchParams &
    ChargeSchedulesAPIDeactivateChargeScheduleBodyParams;

function deactivateChargeScheduleUnsafeEffect(
  payload: ChargeSchedulesAPIDeactivateChargeSchedulePayload,
) {
  const { chargeScheduleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ChargeSchedule>({
        body,
        decode: unsafeDecodeJsonResponse<ChargeSchedule>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/chargeSchedules/${chargeScheduleId}/deactivate`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deactivateChargeSchedule(
  payload: ChargeSchedulesAPIDeactivateChargeSchedulePayload,
  options?: { signal?: AbortSignal },
): Promise<ChargeSchedule> {
  return F.pipe(
    payload,
    deactivateChargeScheduleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deactivateChargeSchedule };
export type {
  ChargeSchedulesAPIDeactivateChargeScheduleBodyParams,
  ChargeSchedulesAPIDeactivateChargeSchedulePathParams,
  ChargeSchedulesAPIDeactivateChargeSchedulePayload,
  ChargeSchedulesAPIDeactivateChargeScheduleSearchParams,
};
