import { MoveInAPI } from "@ender/shared/generated/ender.api.leasing";
import type { MoveInConfigRowCapturedDataType } from "@ender/shared/generated/ender.model.leasing";
import { MoveInConfigRowCapturedDataTypeEnum } from "@ender/shared/generated/ender.model.leasing";
import type { LabelValue } from "@ender/shared/types/label-value";
import type { ChecklistConfigStep } from "@ender/shared/types/move-in";

function updateChecklistConfigStep(
  checklistConfigStep: Partial<ChecklistConfigStep> &
    Pick<ChecklistConfigStep, "id">,
) {
  return MoveInAPI.updateConfigStep({
    ...checklistConfigStep,
    stepId: checklistConfigStep.id,
  });
}

/**
 * given an array, and the index to move from/to,
 * calls the API to update the item's orders.
 */
function reorderChecklistConfigSteps(
  checklistConfigSteps: ChecklistConfigStep[],
  from: number,
  to: number,
) {
  const movedItem = checklistConfigSteps[from];
  const promises = [];

  /**
   * if we are not moving at all, skip
   */
  if (from !== to) {
    /**
     * delta determines whether we are iterating forward or backward in the array.
     * Delta also determines which direction to 'shift' each item's ordering
     */
    const delta = from < to ? 1 : -1;

    /**
     * start at the from value, iterate until we reach the to value (non-inclusive)
     * get the next or prior item, and set it to this index (based on delta direction)
     * at the end, we are effectively re-inserting the moved item at the new "to" index
     */
    for (let i = from; i !== to; i += delta) {
      const { id } = checklistConfigSteps[i + delta];
      promises.push(updateChecklistConfigStep({ id, ordering: i }));
    }
    promises.push(
      updateChecklistConfigStep({ id: movedItem.id, ordering: to }),
    );
  }

  return Promise.all(promises);
}

const capturedDataTypeSelectOptions: LabelValue<MoveInConfigRowCapturedDataType>[] =
  [
    {
      value: MoveInConfigRowCapturedDataTypeEnum.NONE,
      label: "“Done” Button (Default)",
    },
    { value: MoveInConfigRowCapturedDataTypeEnum.TEXT, label: "Text" },
    { value: MoveInConfigRowCapturedDataTypeEnum.MONEY, label: "Money" },
    { value: MoveInConfigRowCapturedDataTypeEnum.NUMBER, label: "Number" },
    { value: MoveInConfigRowCapturedDataTypeEnum.DATE, label: "Date" },
    { value: MoveInConfigRowCapturedDataTypeEnum.BOOLEAN, label: "Yes/No" },
  ];

export {
  capturedDataTypeSelectOptions,
  reorderChecklistConfigSteps,
  updateChecklistConfigStep,
};
