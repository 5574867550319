"use client";

import { useEffect } from "react";

function useWindowEvent<K extends string>(
  type: K,
  listener: K extends keyof WindowEventMap
    ? (this: Window, ev: WindowEventMap[K]) => void
    : (this: Window, ev: CustomEvent) => void,
  options?: boolean | AddEventListenerOptions,
) {
  // useEffect required to manage listener correctly
  useEffect(() => {
    window.addEventListener(
      type as keyof WindowEventMap,
      listener as EventListener,
      options,
    );
    return () =>
      window.removeEventListener(
        type as keyof WindowEventMap,
        listener as EventListener,
        options,
      );
  }, [type, listener, options]);
}

export { useWindowEvent };
