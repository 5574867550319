/**
 * HTTP Method: POST
 * Pathname: /invoices/summary
 * @function getInvoicesSummary
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoicesSummaryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInvoicesSummaryResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GetInvoicesRequestBodyApprovalStatus } from "@ender/shared/generated/ender.api.accounting.request";
import type { GetInvoicesSummaryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { MultiDBQueriesGetInvoicesSortableFields } from "@ender/shared/generated/ender.db.multi";
import type {
  MoneyTransferTransferType,
  Party,
} from "@ender/shared/generated/ender.model.payments";
import type {
  InvoiceInvoiceType,
  InvoicePayableType,
  InvoicePaymentMethod,
} from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoicesSummarySearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetInvoicesSummaryBodyParams = {
  amountMatch?: string | undefined;
  approvalStatus: GetInvoicesRequestBodyApprovalStatus[];
  assignedToMe?: boolean | undefined;
  bankAccountId?: EnderId | undefined;
  bestPaymentMethodType?: InvoicePaymentMethod | undefined;
  bulkUploadFriendlyId?: string | undefined;
  descending?: boolean | undefined;
  earliestPeriodDateFilter?: PeriodFilter | undefined;
  externalInvoiceId?: string | undefined;
  firmIds: EnderId[];
  fundIds: EnderId[];
  inclusiveEndInvoiceDueDate?: SerializesInto<LocalDate> | undefined;
  inclusiveEndInvoiceLedgerDate?: SerializesInto<LocalDate> | undefined;
  inclusiveEndPaidDate?: SerializesInto<LocalDate> | undefined;
  invoiceType: InvoiceInvoiceType;
  limit?: number | undefined;
  marketIds: EnderId[];
  owedByIds: EnderId[];
  owedByParty?: Party | undefined;
  owedToIds: EnderId[];
  owedToParty?: Party | undefined;
  payableTypes: InvoicePayableType[];
  paymentMethodType?: MoneyTransferTransferType | undefined;
  propertyIds: EnderId[];
  sortBy?: MultiDBQueriesGetInvoicesSortableFields | undefined;
  startAfterInvoiceId?: EnderId | undefined;
  startInvoiceDueDate?: SerializesInto<LocalDate> | undefined;
  startInvoiceLedgerDate?: SerializesInto<LocalDate> | undefined;
  startPaidDate?: SerializesInto<LocalDate> | undefined;
};

type InvoicesAPIGetInvoicesSummaryPayload =
  InvoicesAPIGetInvoicesSummarySearchParams &
    InvoicesAPIGetInvoicesSummaryBodyParams;

function getInvoicesSummaryUnsafeEffect(
  payload: InvoicesAPIGetInvoicesSummaryPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInvoicesSummaryResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInvoicesSummaryResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/summary",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoicesSummary(
  payload: InvoicesAPIGetInvoicesSummaryPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInvoicesSummaryResponse> {
  return F.pipe(
    payload,
    getInvoicesSummaryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoicesSummary };
export type {
  InvoicesAPIGetInvoicesSummaryBodyParams,
  InvoicesAPIGetInvoicesSummaryPayload,
  InvoicesAPIGetInvoicesSummarySearchParams,
};
