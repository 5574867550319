import { NULL } from "@ender/shared/constants/general";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { Option as O } from "effect";

import { MoveInStep } from "../move-in-step";
import type { ChecklistStepComponentProps } from "./checklist-steps.types";

function MoneyStep(props: ChecklistStepComponentProps) {
  const {
    completed,
    capturedData = NULL,
    completedTime,
    onChange,
    ...stepProps
  } = props;

  return (
    <MoveInStep {...stepProps} completedTime={completedTime}>
      <Group spacing={Spacing.none} noWrap>
        <MoneyInput
          disabled={completed}
          value={Money$.parse(capturedData)}
          onChange={(value) =>
            onChange({
              capturedData: O.isSome(value)
                ? O.getOrThrow(value).toFormatted()
                : "",
              completed: O.isSome(value),
            })
          }
        />
        {Boolean(completedTime) && (
          <Button
            variant={ButtonVariant.transparent}
            onClick={() => onChange({ capturedData: "", completed: false })}
            disabled={completed}>
            Clear
          </Button>
        )}
      </Group>
    </MoveInStep>
  );
}

export { MoneyStep };
