import { Function as F } from "effect";
import type { Dispatch, PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useId,
  useMemo,
  useState,
} from "react";

type RightRailContextValue = [boolean, (opened: boolean, id?: string) => void];

const RightRailContext = createContext<RightRailContextValue>([
  false,
  F.constVoid,
]);

function RightRailProvider(props: PropsWithChildren) {
  const [trackedRightRails, setRightRailsOpened] = useState<
    Record<string, number>
  >({});
  const railsOpened = useMemo(() => {
    return Object.values(trackedRightRails).reduce(
      (acc, curr) => acc + curr,
      0,
    );
  }, [trackedRightRails]);

  const setOpened = useCallback((opened: boolean, id = "unknown") => {
    setRightRailsOpened((prev) => {
      return {
        ...prev,
        [id]: Math.max(0, (prev[id] ?? 0) + (opened ? 1 : -1)),
      };
    });
  }, []);

  return (
    <RightRailContext.Provider value={[railsOpened > 0, setOpened]}>
      {props.children}
    </RightRailContext.Provider>
  );
}

/**
 * This is intended to be used by items which modify the state of the right rail context.
 */
function useRightRailContext(): Dispatch<boolean> {
  const [, setOpened] = useContext(RightRailContext);
  const id = useId();
  return useCallback(
    (opened: boolean) => {
      setOpened(opened, id);
    },
    [setOpened, id],
  );
}

export { RightRailContext, RightRailProvider, useRightRailContext };
