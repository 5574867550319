import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const WebserverFilesServiceSubFolderValues = ["PUBLIC", "PRIVATE"] as const;

const WebserverFilesServiceSubFolderEffectSchema = Schema.Literal(
  ...WebserverFilesServiceSubFolderValues,
);

type WebserverFilesServiceSubFolder = Schema.Schema.Type<
  typeof WebserverFilesServiceSubFolderEffectSchema
>;

const WebserverFilesServiceSubFolderEnum = castEnum(
  WebserverFilesServiceSubFolderEffectSchema,
);

function randomWebserverFilesServiceSubFolder(): WebserverFilesServiceSubFolder {
  return rand(WebserverFilesServiceSubFolderValues);
}

export {
  randomWebserverFilesServiceSubFolder,
  WebserverFilesServiceSubFolderEffectSchema,
  WebserverFilesServiceSubFolderEnum,
  WebserverFilesServiceSubFolderValues,
};
export type { WebserverFilesServiceSubFolder };
