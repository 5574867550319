import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P, String as S } from "effect";
import { useMemo, useState } from "react";

import type { GetApprovalPipelineResponse } from "@ender/shared/api/approval";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Divider } from "@ender/shared/ds/divider";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { VendorsAPI } from "@ender/shared/generated/ender.api.core";
import { DwollaAPI } from "@ender/shared/generated/ender.api.finance";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import { VendorUserTagVendorUserTagTypeEnum } from "@ender/shared/generated/ender.model.core.vendor";
import { EmailDisplay, PhoneDisplay } from "@ender/shared/ui/contact-card";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { EnderModal } from "@ender/shared/ui/ender-modal";
import { Color } from "@ender/shared/utils/theming";
import { ApprovalTimeline } from "@ender/widgets/finance/approval";

import { ApproveHomePurchaseForm } from "./pipeline-approval-form";
import { RejectHomePurchaseForm } from "./pipeline-rejection-form";

const HomeApprovalModalEnum = {
  APPROVE: "APPROVE",
  CLOSED: "CLOSED",
  REINSTATE: "REINSTATE",
  REJECT: "REJECT",
};

type TitleCompanyApprovalMenuProps = {
  item: Omit<GetApprovalPipelineResponse, "model"> & {
    model: {
      id: EnderId;
      name: string;
    };
  };
  currentState?: GetApprovalPipelineResponse["currentState"];
  needsMyApproval: boolean;
  steps: GetApprovalProcessResponseStep[];
  isFirstStep: boolean;
  stepId?: EnderId;
  onSuccess: () => void;
};

function TitleCompanyApprovalMenu({
  item,
  currentState,
  needsMyApproval,
  steps,
  isFirstStep,
  stepId,
  onSuccess,
}: TitleCompanyApprovalMenuProps) {
  const modelType = ModelTypeEnum.VENDOR;
  const [modal, setModal] = useState(HomeApprovalModalEnum.CLOSED);

  const titleCompanyId: EnderId = item.model.id;

  const { data: titleCompany } = useQuery({
    queryFn: () => VendorsAPI.getVendor({ vendorId: titleCompanyId }),
    queryKey: ["VendorsAPI.getVendor", titleCompanyId],
  });

  const { data: trustedContacts } = useQuery({
    queryFn: () => VendorsAPI.getVendorUsers({ vendorId: titleCompanyId }),
    queryKey: ["VendorsAPI.getVendorUsers", titleCompanyId],
    select: (data) =>
      data.filter(({ tags }) =>
        tags.includes(VendorUserTagVendorUserTagTypeEnum.TRUSTED_CONTACT),
      ),
  });

  const { data: bankAccount } = useQuery({
    queryFn: () =>
      DwollaAPI.getBankLinkInfoByModelV1({
        modelId: titleCompanyId,
        modelType,
      }),
    queryKey: ["DwollaAPI.getBankLinkInfoByModelV1", titleCompanyId],
    select: (data) => data.bankLinkInfo.bankAccounts[0],
  });

  const relevantSteps = useMemo(() => {
    if (A.isEmptyArray(steps ?? []) || S.isEmpty(stepId ?? "")) {
      return undefined;
    }

    const currentIndex = steps.findIndex((step) => step.id === stepId);
    return {
      currentStep: steps[currentIndex],
      nextStep:
        currentIndex < steps.length - 1 ? steps[currentIndex + 1] : undefined,
      previousStep: currentIndex > 0 ? steps[currentIndex - 1] : undefined,
    };
  }, [steps, stepId]);

  const { mutateAsync: onApprove } = useMutation({
    mutationFn: async (comment: string) => {
      await ApprovalsAPI.approve({
        comment,
        modelId: item.model.id,
        modelType,
      });
      onSuccess();
    },
    mutationKey: ["ApprovalsAPI.approve"] as const,
  });

  const { mutateAsync: onReject } = useMutation({
    mutationFn: async ({
      step,
      comment,
    }: {
      step?: EnderId;
      comment: string;
    }) => {
      await ApprovalsAPI.reject({
        comment,
        modelId: item.model.id,
        modelType,
        rejectedToStepId: step,
      });
      onSuccess();
    },
    mutationKey: ["ApprovalsAPI.reject"] as const,
  });

  if (P.isNullable(titleCompany) || P.isNullable(currentState)) {
    return;
  }

  function closeModal() {
    setModal(HomeApprovalModalEnum.CLOSED);
  }

  function openApprovalModal() {
    setModal(HomeApprovalModalEnum.APPROVE);
  }

  function openRejectModal() {
    setModal(HomeApprovalModalEnum.REJECT);
  }

  return (
    <Stack>
      <Group justify={Justify.between}>
        <Text size={FontSize.sm}>Title Company</Text>
        <Text size={FontSize.sm}>
          <EnderLink to={`/vendors/${titleCompany.id}`}>
            {titleCompany.name}
          </EnderLink>
        </Text>
      </Group>
      <Divider />
      <Group justify={Justify.between}>
        <Text size={FontSize.sm}>Trusted Contacts</Text>
        <div>
          {A.isEmptyArray(trustedContacts ?? []) ? (
            "None"
          ) : (
            <table className="table--default table--small">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {trustedContacts?.map(({ name, email, phone }) => (
                  <tr key={name}>
                    <td>{name}</td>
                    <td>
                      <PhoneDisplay value={phone} />
                    </td>
                    <td>
                      <EmailDisplay value={email} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Group>
      <Divider />
      <Group justify={Justify.between}>
        <Text size={FontSize.sm}>Bank Routing Number</Text>
        <Text size={FontSize.sm}>{bankAccount?.routingNumber || "None"}</Text>
      </Group>
      <Divider />
      <Group justify={Justify.between}>
        <Text size={FontSize.sm}>Bank Account Number</Text>
        <Text size={FontSize.sm}>
          {bankAccount?.mask ? `••••${bankAccount?.mask}` : "None"}
        </Text>
      </Group>
      <Divider />
      {needsMyApproval && (
        <>
          <Group spacing={Spacing.sm}>
            <Button color={Color.red} onClick={openRejectModal}>
              Reject
            </Button>
            <Button onClick={openApprovalModal}>Approve</Button>
          </Group>
          <Divider />
        </>
      )}

      <ApprovalTimeline timeline={currentState.timeline} />
      <EnderModal
        opened={modal !== HomeApprovalModalEnum.CLOSED}
        onClose={closeModal}>
        {modal === HomeApprovalModalEnum.APPROVE && (
          <ApproveHomePurchaseForm onApprove={onApprove} />
        )}
        {modal === HomeApprovalModalEnum.REJECT && (
          <RejectHomePurchaseForm
            onReject={onReject}
            isFirstStep={isFirstStep}
            relevantSteps={relevantSteps}
          />
        )}
      </EnderModal>
    </Stack>
  );
}

export { TitleCompanyApprovalMenu };
