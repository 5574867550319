import { z } from "zod";

import type {
  GetGLJournalEntryApprovalRowsResponseGLJournalEntryApprovalRow,
  GetRecurringGLJournalEntryApprovalRowsResponseGLRecurringJournalEntryApprovalRow,
} from "@ender/shared/generated/com.ender.middle.response";
import { castEnum } from "@ender/shared/utils/zod";

type JournalEntryApprovalsTableRow = Pick<
  GetGLJournalEntryApprovalRowsResponseGLJournalEntryApprovalRow,
  | "accountingDateDisplay"
  | "approvalStatus"
  | "authorDisplay"
  | "assignedToMe"
  | "firmDisplay"
  | "id"
  | "ledgerDateDisplay"
  | "memoDisplay"
  | "propertyDisplay"
  | "systemDateDisplay"
> & {
  step?: string;
  transactionType?: string;
};

type RecurringJournalEntryApprovalsTableRow = Pick<
  GetRecurringGLJournalEntryApprovalRowsResponseGLRecurringJournalEntryApprovalRow,
  | "approvalStatus"
  | "assignedToMe"
  | "authorDisplay"
  | "endDateDisplay"
  | "firmDisplay"
  | "id"
  | "memoDisplay"
  | "nextDateDisplay"
  | "propertyDisplay"
  | "startDateDisplay"
  | "systemDateDisplay"
> & {
  frequency: string;
  step?: string;
};

const GeneralLedgerTransactionApprovalsTableTabsValues = [
  "ASSIGNED_TO_ME",
  "ALL_UNAPPROVED",
  "APPROVED_TRANSACTIONS",
  "REJECTED_TRANSACTIONS",
  "ALL_TRANSACTIONS",
] as const;
const GeneralLedgerTransactionApprovalsTableTabsSchema = z.enum(
  GeneralLedgerTransactionApprovalsTableTabsValues,
);
type GeneralLedgerTransactionApprovalsTableTabsType = z.infer<
  typeof GeneralLedgerTransactionApprovalsTableTabsSchema
>;
const GeneralLedgerTransactionApprovalsTableTabsEnum =
  castEnum<GeneralLedgerTransactionApprovalsTableTabsType>(
    GeneralLedgerTransactionApprovalsTableTabsSchema,
  );

export {
  GeneralLedgerTransactionApprovalsTableTabsEnum,
  GeneralLedgerTransactionApprovalsTableTabsSchema,
  GeneralLedgerTransactionApprovalsTableTabsValues,
};
export type {
  GeneralLedgerTransactionApprovalsTableTabsType,
  JournalEntryApprovalsTableRow,
  RecurringJournalEntryApprovalsTableRow,
};
