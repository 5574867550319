/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/inspections/{inspectionId}
 * @function updateInspection
 * @memberof BuyAPI
 * @param {BuyAPIUpdateInspectionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertyInspection>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PropertyInspection } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIUpdateInspectionPathParams = {
  dealId: EnderId;
  inspectionId: EnderId;
};

type BuyAPIUpdateInspectionSearchParams = {
  token?: string | undefined;
};

type BuyAPIUpdateInspectionBodyParams = {
  inspectionDate?: SerializesInto<LocalDate> | undefined;
  scheduledInspectionDate?: SerializesInto<LocalDate> | undefined;
  vendorId?: EnderId | undefined;
};

type BuyAPIUpdateInspectionPayload = BuyAPIUpdateInspectionPathParams &
  BuyAPIUpdateInspectionSearchParams &
  BuyAPIUpdateInspectionBodyParams;

function updateInspectionUnsafeEffect(payload: BuyAPIUpdateInspectionPayload) {
  const { dealId, inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertyInspection>({
        body,
        decode: unsafeDecodeJsonResponse<PropertyInspection>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/inspections/${inspectionId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateInspection(
  payload: BuyAPIUpdateInspectionPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertyInspection> {
  return F.pipe(
    payload,
    updateInspectionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateInspection };
export type {
  BuyAPIUpdateInspectionBodyParams,
  BuyAPIUpdateInspectionPathParams,
  BuyAPIUpdateInspectionPayload,
  BuyAPIUpdateInspectionSearchParams,
};
