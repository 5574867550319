import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import { Function as F } from "effect";
import type {
  AriaRole,
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
  HTMLAttributes,
  HTMLInputAutoCompleteAttribute,
  HTMLInputTypeAttribute,
  ReactNode,
} from "react";
import { forwardRef, useCallback, useId } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { SizeSchema } from "@ender/shared/utils/theming";

import { isEmptyReactNode } from "../../../utils";
import type { InputWrapperProps } from "./shared-ds-input-wrapper";
import { InputWrapper } from "./shared-ds-input-wrapper";

const InputSizeSchema = SizeSchema.pipe(Schema.pickLiteral("sm", "md", "lg"));
const InputSizeValues = InputSizeSchema.literals;
type InputSizes = Schema.Schema.Type<typeof InputSizeSchema>;
const InputSize = castEnum(InputSizeSchema);

type InputProps = {
  borderless?: boolean;
  value: string;
  onChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  textAlign?: "left" | "right";
  autoComplete?: HTMLInputAutoCompleteAttribute;
  inputType?: Extract<
    HTMLInputTypeAttribute,
    "text" | "number" | "password" | "time"
  >;
  inputMode?: HTMLAttributes<HTMLInputElement>["inputMode"];
  size?: InputSizes;
  role?: AriaRole;
  "data-private"?: boolean;
} & Pick<InputWrapperProps, "error" | "label" | "description">;

/**
 * intended for use in extending the Input component.
 * Omits `value` and `onChange` because it is assumed that the extending component will override their type signatures.
 */
type InputBaseProps = Omit<
  InputProps,
  "value" | "onChange" | "onBlur" | "onFocus"
>;

const InputVariantGenerator = cva(
  [
    "w-full text-sm/standard placeholder:text-slate-300 outline-none focus:outline-none focus-visible:outline-none",
    "text-slate-900 disabled:text-gray-300 px-4 bg-transparent",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      size: "sm",
      textAlign: "left",
    },
    variants: {
      size: {
        [InputSize.sm]: "py-[calc(0.375rem-1px)]",
        [InputSize.md]: "py-[calc(0.625rem-1px)]",
        [InputSize.lg]: "py-3.5",
      },
      textAlign: {
        left: "",
        right: "text-right",
      },
    },
  },
);

const InputContainerVariantGenerator = cva(
  ["relative grow flex rounded border"],
  {
    variants: {
      borderless: {
        false: [
          "border-slate-200",
          "has-[:disabled]:bg-gray-50 has-[:disabled]:border-gray-100",
          "has-[:focus]:border-primary-500 has-[:enabled]:has-[[aria-invalid=true]]:border-red-500",
        ],
        true: "border-transparent",
      },
    },
    defaultVariants: {
      borderless: false,
    },
  },
);

const Input = forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    const {
      description,
      disabled = false,
      error,
      label,
      leftSection,
      onBlur = F.constVoid,
      onChange,
      onFocus = F.constVoid,
      onPaste = F.constVoid,
      placeholder = "",
      rightSection,
      role = "textbox",
      size = InputSize.md,
      textAlign,
      value,
      inputType,
      inputMode,
      borderless = false,
      "data-private": _dataPrivate,
      name,
    } = props;

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value, e);
      },
      [onChange],
    );

    const idBase = useId();
    const labelId = `${idBase}-label`;
    const descriptionId = `${idBase}-description`;
    const errorId = `${idBase}-error`;
    const describedBy = !isEmptyReactNode(description) ? descriptionId : "";

    const attributes = {
      borderless,
      leftSection: !isEmptyReactNode(leftSection),
      rightSection: !isEmptyReactNode(rightSection),
      size,
      textAlign,
    };
    return (
      <InputWrapper
        inputId={idBase}
        labelId={labelId}
        descriptionId={descriptionId}
        errorId={errorId}
        label={label}
        error={error}
        description={description}>
        <div className={InputContainerVariantGenerator({ borderless })}>
          {!isEmptyReactNode(leftSection) && (
            <div className="pl-3 -mr-1 flex items-center text-slate-500 text-base">
              {leftSection}
            </div>
          )}
          <input
            className={InputVariantGenerator(attributes)}
            placeholder={placeholder}
            value={value}
            aria-labelledby={labelId}
            aria-describedby={describedBy}
            // cannot use this due to limitations in the testing environment
            // aria-description={description}
            aria-label={isEmptyReactNode(label) ? placeholder : undefined}
            aria-errormessage={errorId}
            aria-invalid={!isEmptyReactNode(error)}
            disabled={disabled}
            id={idBase}
            inputMode={inputMode}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onPaste={onPaste}
            ref={ref}
            role={role}
            type={inputType}
            data-private={_dataPrivate}
            name={name}
          />
          {!isEmptyReactNode(rightSection) && (
            <div className="-ml-1 pr-3 flex items-center text-slate-500 text-base">
              {rightSection}
            </div>
          )}
        </div>
      </InputWrapper>
    );
  },
);

export { Input, InputSize, InputSizeSchema, InputSizeValues };

export type { InputBaseProps, InputProps, InputSizes };
