import { Function as F } from "effect";

import type { RepairCalculation, Underwriting } from "@ender/shared/api/buy";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, Money } from "@ender/shared/core";
import { PropertyHOAFeeFrequencyEnum } from "@ender/shared/generated/ender.model.core.property";
import { noOpAsync } from "@ender/shared/utils/no-op";

import type { UnderwritingPageContextProps } from "./underwriting-page-context-types";

const DEFAULT_CONTEXT: UnderwritingPageContextProps = {
  canAddOffer: true,
  currentImage: UNDEFINED,
  dataLoading: true,
  gotoNextUnderwriting: F.constVoid,
  imageIndex: 0,
  nextImage: F.constVoid,
  previousImage: F.constVoid,
  propertiesAssignedToMe: [],
  deal: UNDEFINED,
  dealId: "" as EnderId,
  propertyImages: [],
  readonly: true,
  refetchDeal: F.constVoid,
  repairs: UNDEFINED,
  saveImageComment: noOpAsync,
  setOpenedRightRail: F.constVoid,
  underwritingPreview: UNDEFINED,
  unsavedChanges: false,
  updateRepairs: F.constVoid,
  updateUnderwritingPreview: F.constVoid,
};

const DEFAULT_UNDERWRITING: Underwriting = {
  acquisitionFee: "" as Money,
  arv: NULL,
  arvReason: "",
  blendedCapRate: "",
  brokerMaxOffer: NULL,
  buyerFirmId: "" as EnderId,
  closingCost: NULL,
  costBasis: "" as Money,
  costBasisByARV: "",
  creditLoss: "" as Money,
  developmentFee: "" as Money,
  egi: "" as Money,
  gpr: "" as Money,
  hoaFeeFrequency: PropertyHOAFeeFrequencyEnum.YEARLY,
  hoaFees: NULL,
  hoaYearlyFees: NULL,
  id: "" as EnderId,
  insurance: "" as Money,
  leasingFee: "" as Money,
  maxOffer: NULL,
  negCarry: "" as Money,
  netCapAtExternalMax: "",
  netCapAtInternalMax: "",
  netCapAtList: "",
  netCapAtOffer: "",
  noi: "" as Money,
  offerPercentOfList: "",
  offerPrice: "0" as Money,
  oneTimeRepairs: "" as Money,
  petRent: "" as Money,
  pmFee: "" as Money,
  rent: NULL,
  rentComps: [],
  rentReason: "",
  repairsAndMaintenance: "" as Money,
  sellerCredits: NULL,
  sellerTaxes: "0" as Money,
  shouldOffer: false,
  taxes: "0" as Money,
  totalExpenses: "" as Money,
  totalRevenue: "" as Money,
  turnover: "" as Money,
  vacancyLoss: "" as Money,
  valueComps: [],
};

const DEFAULT_REPAIR_OPTIONS: RepairCalculation = {
  flooringPercentToReplace: "25%",
  countertopCost: "0.00" as Money,
  appliancesCost: "0.00" as Money,
  kitchenCabinetsCost: "0.00" as Money,
  roofCost: "0.00" as Money,
  hvacCost: "0.00" as Money,
  foundationCost: "0.00" as Money,
  exteriorCost: "0.00" as Money,
  adjustmentAmount: "0.00" as Money,
  adjustmentReason: "",
  dollarsPerSqftAssumption: "" as Money,
  fixedCostAssumption: "" as Money,
  flooringCost: "" as Money,
  propertySqft: 0,
  propertyYearBuilt: 0,
  totalRepairCost: "0.00" as Money,
};

export { DEFAULT_CONTEXT, DEFAULT_REPAIR_OPTIONS, DEFAULT_UNDERWRITING };
