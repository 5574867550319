import { flexRender } from "@tanstack/react-table";
import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import type { ReactNode, RefObject } from "react";
import { Fragment, useMemo, useState } from "react";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import type { EmptyObject } from "@ender/shared/types/general";
import { EmptyTableRow } from "@ender/shared/ui/empty-table-row";
import { EnderGroup } from "@ender/shared/ui/ender-group";
import { EnderPagination } from "@ender/shared/ui/ender-pagination";
import { RightRail } from "@ender/shared/ui/right-rail";
import { ScreenRangeEnum, ScreenSize } from "@ender/shared/ui/screen-size";

import { ColumnSortFilter } from "./column-sort-filter";
import { ClearFiltersButtonOld } from "./filtering/clear-filters-button";
import { FilterBadges } from "./filtering/filter-badges";
import { GlobalFilter } from "./filtering/filters/global-filter";
import { ShowFiltersButton } from "./filtering/show-filters-button";
import { TableHeader } from "./table-header";
import { TableRow } from "./table-row";
import { TableTopResultsOld } from "./table-top-results";
import type { ColumnMeta, EnderTable } from "./table.types";
import { useIntersectionObserver } from "./use-intersection-observer";

import styles from "./table.module.css";

type EnderTableTanstackProps<RowData, Meta extends object> = {
  title: string;
  table: EnderTable<RowData, Meta>;
  filters?: ReactNode;
  actions?: ReactNode;
  className?: string;
  maxHeight?: string;
  hideFilters?: boolean;
  hideGlobalFilter?: boolean;

  /** @description Error status of the table's data; To be used to show any time the data has an error */
  isError?: boolean;
  errorMessage?: string;

  /** @description Loading status of the table's data; To be used to show any time the data is being fetched/re-fetched */
  isLoading?: boolean;
  loadingMessage?: string;

  /**
   * an optional function to call when the user scrolls to the end of the table.
   * Can be used to enable infinite scroll.
   */
  onScrollToEnd?: () => void;

  infiniteScrollRef?: RefObject<HTMLDivElement>;

  /**
   * Custom Classnames
   */
  cx?: Partial<{
    tableContainer: string;
    filtersAndActions: string;
  }>;
};

function EnderTableTanstack<RowData, Meta extends object = EmptyObject>(
  props: EnderTableTanstackProps<RowData, Meta>,
) {
  const {
    title,
    table,
    filters,
    actions,
    className,
    maxHeight,
    infiniteScrollRef,
    isError = false,
    isLoading = false,
    hideFilters = false,
    hideGlobalFilter = false,
    onScrollToEnd = F.constVoid,
    errorMessage = "",
    loadingMessage = "Loading...",
    cx = {},
  } = props;
  /**
   * ref as a state so it triggers a re-render when set the first time.
   * this ensures that the infinite scroll observer is initialized with the correct root.
   */
  const [tableScrollWrapper, setTableScrollWrapper] =
    useState<HTMLDivElement | null>(NULL);
  const { globalFilter, pagination } = table.getState();
  const [
    isFilterRailOpen,
    { setTrue: openFilterRail, setFalse: closeFilterRail },
  ] = useBoolean(false);
  const rows = table.getRowModel().rows;

  const setObservedElement = useIntersectionObserver({
    onIntersect: onScrollToEnd,
    root: tableScrollWrapper,
    rootMargin: "0px 0px 500px 0px",
  });

  const emptyRowMessage = useMemo(() => {
    if (isLoading) {
      return loadingMessage;
    }

    if (isError) {
      return errorMessage;
    }

    return UNDEFINED;
  }, [isLoading, isError, errorMessage, loadingMessage]);

  return (
    <>
      <div
        className={clsx(styles.tableContainer, cx.tableContainer)}
        style={{ display: "flex", flexDirection: "column" }}>
        {(P.isNotNullable(globalFilter) ||
          P.isNotNullable(filters) ||
          P.isNotNullable(actions)) && (
          <EnderGroup
            position="apart"
            className={clsx(styles.filtersAndActions, cx.filtersAndActions)}>
            <EnderGroup className={styles.filters}>
              {!hideGlobalFilter && <GlobalFilter table={table} />}
              {P.isNotNullable(filters) && filters}
            </EnderGroup>

            <EnderGroup className={styles.actions} position="right">
              {P.isNotNullable(actions) && actions}
              <ScreenSize>
                <ScreenSize.LessThan size={ScreenRangeEnum.MEDIUM}>
                  <ShowFiltersButton setFilterRailOpen={openFilterRail} />
                </ScreenSize.LessThan>
              </ScreenSize>
            </EnderGroup>
          </EnderGroup>
        )}

        <Group spacing="sm" justify="between">
          <Group>
            {!isError && (
              <TableTopResultsOld
                numResults={table.getFilteredRowModel().rows.length}
                isLoading={isLoading}
              />
            )}
            {!hideFilters && <FilterBadges table={table} />}
          </Group>
          <ClearFiltersButtonOld table={table} />
        </Group>

        <div
          className={clsx(styles.tableWrapper, className)}
          style={{ maxHeight }}
          ref={setTableScrollWrapper}>
          <table aria-label={`${title} Table`} className={styles.enderTable}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeader
                      key={header.id}
                      table={table}
                      header={header}
                    />
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {rows.length > 0 ? (
                rows.map((row, i) => {
                  const _infiniteScrollRef =
                    i === rows.length - 1 ? infiniteScrollRef : undefined;
                  return (
                    <TableRow
                      infiniteScrollRef={_infiniteScrollRef}
                      key={row.id}
                      row={row}
                      rowNum={i}
                    />
                  );
                })
              ) : (
                <EmptyTableRow
                  message={emptyRowMessage}
                  colSpan={table.getVisibleLeafColumns().length}
                />
              )}
            </tbody>
          </table>
          {/* unmount this while things are loading. This guarantees that it will re-compute its intersection status after results are loaded */}
          {!isLoading && (
            <div
              style={{ height: 1, position: "relative" }}
              ref={setObservedElement}
            />
          )}
        </div>

        {pagination && (
          <EnderPagination
            page={pagination.pageIndex + 1}
            total={table.getPageCount()}
            onChange={(index) => table.setPageIndex(index - 1)}
          />
        )}
      </div>

      <RightRail
        title={`${title} Filters`}
        opened={isFilterRailOpen}
        onClose={closeFilterRail}>
        <Stack spacing={Spacing.xl}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Fragment key={headerGroup.id}>
              {headerGroup.headers
                .filter(
                  (header) =>
                    header.column.getCanSort() || header.column.getCanFilter(),
                )
                .map((header) => {
                  const column = header.column;
                  const meta = column.columnDef.meta as ColumnMeta<RowData>;

                  return (
                    <Stack key={header.id} spacing={Spacing.xs}>
                      <H3>
                        {flexRender(
                          column.columnDef.header,
                          header.getContext(),
                        )}
                      </H3>
                      <Stack>
                        <ColumnSortFilter
                          table={table}
                          column={column}
                          // sort
                          canSort={column.getCanSort()}
                          sortDir={column.getIsSorted()}
                          clearSorting={column.clearSorting}
                          toggleSorting={column.toggleSorting}
                          // filter
                          filterValue={column.getFilterValue()}
                          onFilterChange={column.setFilterValue}
                          filterComponent={meta.filterComponent}
                        />
                      </Stack>
                    </Stack>
                  );
                })}
            </Fragment>
          ))}
        </Stack>
      </RightRail>
    </>
  );
}

export { EnderTableTanstack };
