import { useMutation } from "@tanstack/react-query";
import { Option as O, pipe } from "effect";
import { useCallback, useMemo } from "react";

import type { EnderId, LocalDate } from "@ender/shared/core";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { ApprovalsAPIRejectPayload } from "@ender/shared/generated/ender.api.misc";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";

import type { RejectInvoiceFormFormOutput } from "./shared/reject-invoice-form";
import { RejectInvoiceForm } from "./shared/reject-invoice-form";

type RejectInvoiceModalProps = {
  closeModal: () => void;
  invoiceId: EnderId;
  invoiceType: InvoiceInvoiceType;
  invoiceTransactionDate: LocalDate;
  isFullyRejected: boolean;
  isRejectingToSamePhase: boolean;
  onSuccess?: () => void;
  stepId: EnderId;
  stepName: string;
};

function ConnectedRejectInvoiceForm({
  closeModal,
  invoiceId,
  invoiceTransactionDate,
  invoiceType,
  isFullyRejected,
  isRejectingToSamePhase,
  onSuccess,
  stepId,
  stepName,
}: RejectInvoiceModalProps) {
  const rejectButtonLabel = useMemo(() => {
    if (isFullyRejected) {
      return "Reject Completely";
    }

    if (isRejectingToSamePhase) {
      return `Reject back to ${stepName}`;
    }

    return `Reject back to Payables`;
  }, [isFullyRejected, isRejectingToSamePhase, stepName]);

  const { isLoading, mutateAsync: _rejectInvoice } = useMutation({
    mutationFn: ApprovalsAPI.reject,
    mutationKey: ["rejectInvoice", invoiceId],
  });

  const rejectInvoice = useCallback(
    async (payload: ApprovalsAPIRejectPayload) => {
      await _rejectInvoice(payload);
      closeModal();
      onSuccess?.();
    },
    [_rejectInvoice, closeModal, onSuccess],
  );

  async function handleSubmit(values: RejectInvoiceFormFormOutput) {
    const payload = {
      comment: values.comment,
      modelId: invoiceId,
      modelType: ModelTypeEnum.INVOICE,
      periodDate: pipe(
        values.periodDate,
        O.map((v) => v.startDate),
        O.getOrUndefined,
      ),
      transactionDate: pipe(
        values.transactionDate,
        O.map((val) => val.toJSON()),
        O.getOrThrow,
      ),
    };
    await rejectInvoice(
      isFullyRejected ? payload : { ...payload, rejectedToStepId: stepId },
    );
  }

  return (
    <RejectInvoiceForm
      invoiceType={invoiceType}
      invoiceTransactionDate={invoiceTransactionDate}
      label={rejectButtonLabel}
      loading={isLoading}
      onSubmit={handleSubmit}
    />
  );
}

export { ConnectedRejectInvoiceForm };
