/**
 * HTTP Method: POST
 * Pathname: /systemReports/PM Balance Report/run
 * @function runPMBalanceReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunPMBalanceReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportsAPIRunPMBalanceReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ReportsAPIRunPMBalanceReportResponse } from "../reports-api-run-p-m-balance-report-response";

type ReportsAPIRunPMBalanceReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunPMBalanceReportBodyParams = {
  endDateInclusive: SerializesInto<LocalDate>;
  startDate: SerializesInto<LocalDate>;
};

type ReportsAPIRunPMBalanceReportPayload =
  ReportsAPIRunPMBalanceReportSearchParams &
    ReportsAPIRunPMBalanceReportBodyParams;

function runPMBalanceReportUnsafeEffect(
  payload: ReportsAPIRunPMBalanceReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportsAPIRunPMBalanceReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ReportsAPIRunPMBalanceReportResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/PM Balance Report/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runPMBalanceReport(
  payload: ReportsAPIRunPMBalanceReportPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIRunPMBalanceReportResponse> {
  return F.pipe(
    payload,
    runPMBalanceReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runPMBalanceReport };
export type {
  ReportsAPIRunPMBalanceReportBodyParams,
  ReportsAPIRunPMBalanceReportPayload,
  ReportsAPIRunPMBalanceReportSearchParams,
};
