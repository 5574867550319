import { Option as O } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import { Select } from "@ender/shared/ds/select";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { InvoicePaymentMethod } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoicePaymentMethodEnum } from "@ender/shared/generated/ender.model.payments.invoice";

import { useInvoiceFilters } from "./invoice-filters-store";

type PaymentTypeFilterProps = {
  disabled?: boolean;
  onChange: (value: InvoicePaymentMethod | Undefined) => void;
  tooltip?: string | undefined;
  value: string | Undefined;
};

function PaymentTypeFilter({
  disabled,
  onChange,
  tooltip,
  value,
}: PaymentTypeFilterProps) {
  const data = [
    { label: "ACH", value: InvoicePaymentMethodEnum.ACH },
    { label: "Print Check", value: InvoicePaymentMethodEnum.PRINT_CHECK },
    { label: "Mark as Paid", value: InvoicePaymentMethodEnum.MARK_PAID },
  ];

  return (
    <Tooltip label={tooltip} disabled={!tooltip}>
      <Select
        data={data}
        disabled={disabled}
        label="Payment Type"
        onChange={(string) => {
          const value = string.pipe(
            O.map((string) => string as InvoicePaymentMethod),
            O.getOrUndefined,
          );
          onChange(value);
        }}
        placeholder="Select Payment Type"
        value={O.fromNullable(value)}
        clearable
      />
    </Tooltip>
  );
}

function InvoiceFiltersStorePaymentTypeFilter({
  disabled,
  tooltip,
}: Pick<PaymentTypeFilterProps, "disabled" | "tooltip">) {
  const { paymentTypeFilter, setPaymentTypeFilter } = useInvoiceFilters();

  return (
    <PaymentTypeFilter
      disabled={disabled}
      onChange={setPaymentTypeFilter}
      tooltip={tooltip}
      value={paymentTypeFilter}
    />
  );
}

export { InvoiceFiltersStorePaymentTypeFilter };
