/**
 * HTTP Method: GET
 * Pathname: /factors/{factorId}
 * @function getFactor
 * @memberof FactorsAPI
 * @param {FactorsAPIGetFactorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Factor>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Factor } from "@ender/shared/generated/ender.service.factors";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIGetFactorPathParams = {
  factorId: EnderId;
};

type FactorsAPIGetFactorSearchParams = {
  token?: string | undefined;
};

type FactorsAPIGetFactorPayload = FactorsAPIGetFactorPathParams &
  FactorsAPIGetFactorSearchParams;

function getFactorUnsafeEffect(payload: FactorsAPIGetFactorPayload) {
  const { factorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Factor>({
        body,
        decode: unsafeDecodeJsonResponse<Factor>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/factors/${factorId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFactor(
  payload: FactorsAPIGetFactorPayload,
  options?: { signal?: AbortSignal },
): Promise<Factor> {
  return F.pipe(
    payload,
    getFactorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFactor };
export type {
  FactorsAPIGetFactorPathParams,
  FactorsAPIGetFactorPayload,
  FactorsAPIGetFactorSearchParams,
};
