import { IconArrowBarDown, IconArrowBarUp } from "@tabler/icons-react";
import type { Column, SortDirection, Table } from "@tanstack/react-table";
import { Function as F, Predicate as P } from "effect";
import type { Dispatch, FC } from "react";
import { useCallback } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { Button } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";

type FilterComponentProps<RowData, ColumnData, FilterData> = {
  table: Table<RowData>;
  column: Column<RowData, ColumnData>;
  value: FilterData | undefined;
  onChange: (value?: FilterData) => void;
};

type FilterComponent<RowData, ColumnData, FilterData> = FC<
  FilterComponentProps<RowData, ColumnData, FilterData>
>;

type ColumnSortFilterProps<RowData, ColumnData, FilterData> = {
  table: Table<RowData>;
  column: Column<RowData, ColumnData>;
  // sort
  canSort: boolean;
  sortDir: SortDirection | false;
  toggleSorting?: (desc?: boolean, isMultiSort?: boolean) => void;
  clearSorting?: () => void;
  // filter
  filterValue?: FilterData;
  onFilterChange: Dispatch<FilterData | undefined>;
  filterComponent?: FilterComponent<RowData, ColumnData, FilterData>;
};

function ColumnSortFilter<RowData, ColumnData = unknown, FilterData = unknown>(
  props: ColumnSortFilterProps<RowData, ColumnData, FilterData>,
) {
  const {
    table,
    column,
    // sort
    canSort,
    sortDir,
    toggleSorting = F.constVoid,
    clearSorting = F.constVoid,
    //filter
    filterValue,
    onFilterChange,
    filterComponent: FilterComponent,
  } = props;
  const canFilter = P.isNotNullable(FilterComponent);

  const handleAscendingSortClick = useCallback(() => {
    if (sortDir !== "asc") {
      toggleSorting(false, true);
    } else {
      clearSorting();
    }
  }, [clearSorting, sortDir, toggleSorting]);

  const handleDescendingSortClick = useCallback(() => {
    if (sortDir !== "desc") {
      toggleSorting(true, true);
    } else {
      clearSorting();
    }
  }, [clearSorting, sortDir, toggleSorting]);

  const onChange = useCallback(
    (value?: FilterData | undefined) => {
      onFilterChange(value ?? UNDEFINED);
    },
    [onFilterChange],
  );

  return (
    <Stack spacing={Spacing.xs}>
      {canSort && (
        <Stack spacing={Spacing.none}>
          <Text size={FontSize.sm}>Sort by</Text>
          <Group spacing={Spacing.sm}>
            <Button
              variant={sortDir === "asc" ? "filled" : "transparent"}
              leftSection={<IconArrowBarUp />}
              onClick={handleAscendingSortClick}>
              Ascending
            </Button>
            <Button
              variant={sortDir === "desc" ? "filled" : "transparent"}
              leftSection={<IconArrowBarDown />}
              onClick={handleDescendingSortClick}>
              Descending
            </Button>
          </Group>
        </Stack>
      )}
      {canFilter && (
        <FilterComponent
          table={table}
          column={column}
          value={filterValue}
          onChange={onChange}
        />
      )}
    </Stack>
  );
}

export { ColumnSortFilter };
export type { ColumnSortFilterProps, FilterComponent, FilterComponentProps };
