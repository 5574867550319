/**
 * HTTP Method: POST
 * Pathname: /linkBankToPlaid
 * @function linkBankToPlaid
 * @memberof DwollaAPI
 * @param {DwollaAPILinkBankToPlaidPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPILinkBankToPlaidSearchParams = {
  token?: string | undefined;
};

type DwollaAPILinkBankToPlaidBodyParams = {
  bankAccountId: EnderId;
  /**
   * <code>
   */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type DwollaAPILinkBankToPlaidPayload = DwollaAPILinkBankToPlaidSearchParams &
  DwollaAPILinkBankToPlaidBodyParams;

function linkBankToPlaidUnsafeEffect(payload: DwollaAPILinkBankToPlaidPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/linkBankToPlaid",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function linkBankToPlaid(
  payload: DwollaAPILinkBankToPlaidPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    linkBankToPlaidUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { linkBankToPlaid };
export type {
  DwollaAPILinkBankToPlaidBodyParams,
  DwollaAPILinkBankToPlaidPayload,
  DwollaAPILinkBankToPlaidSearchParams,
};
