import { useQuery } from "@tanstack/react-query";
import { Array as A, Function as F, Record as R } from "effect";
import { useMemo } from "react";

import type { Null } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import type { LabelValue } from "@ender/shared/types/label-value";
import { Select } from "@ender/shared/ui/select";

import type { ApprovalProcessHybridId } from "./types";
import {
  DEFAULT_APPROVAL_PROCESS_HYBRID_ID,
  NEW_APPROVAL_PROCESS_HYBRID_ID,
} from "./types";

type ApprovalProcessRuleSelectProps = {
  approvalProcessHybridId: ApprovalProcessHybridId | Null;
  type: ApprovalProcessType;
  ownershipGroupId?: EnderId;
  onChange: (val: ApprovalProcessHybridId | Null) => void;
};

function ApprovalProcessRuleSelect({
  approvalProcessHybridId,
  type,
  ownershipGroupId,
  onChange,
}: ApprovalProcessRuleSelectProps) {
  const { data: approvalProcesses } = useQuery({
    queryFn: ({ queryKey: [, params] }) =>
      ApprovalsAPI.searchApprovalProcesses(params),
    queryKey: ["searchApprovalProcesses", { ownershipGroupId, type }] as const,
  });

  const data: LabelValue<ApprovalProcessHybridId>[] = useMemo(() => {
    const processMap = (approvalProcesses?.approvalProcessById ?? {}) as Record<
      EnderId,
      GetApprovalProcessResponse
    >;
    return F.pipe(
      processMap,
      R.toEntries,
      A.map(([id, approvalProcess]) => {
        return { label: approvalProcess.name, value: id };
      }),
      A.prependAll([
        { label: "Add New Rule", value: NEW_APPROVAL_PROCESS_HYBRID_ID },
        { label: "Default", value: DEFAULT_APPROVAL_PROCESS_HYBRID_ID },
      ]),
    );
  }, [approvalProcesses]);

  return (
    <div style={{ maxWidth: 300 }}>
      <Select
        clearable={false}
        data={data}
        label="Select Rule"
        name="approvalProcess"
        onChange={onChange}
        value={approvalProcessHybridId}
      />
    </div>
  );
}

export { ApprovalProcessRuleSelect };
