import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { Option as O, Predicate as P, pipe } from "effect";
import { useEffect, useState } from "react";

import { SearchInput, searchProperties } from "@ender/entities/search-input";
import { Form, useForm } from "@ender/form-system/base";
import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import {
  EnderIdFormSchema,
  LocalDate$,
  LocalDateEffectSchema,
} from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { DateInput } from "@ender/shared/ds/date-input";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GLJournalEntry } from "@ender/shared/generated/ender.model.accounting";
import { useDocumentTitle } from "@ender/shared/hooks/use-document-title";
import { hydratePropertyWithFriendlyId } from "@ender/shared/ui/search-input";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const RetainedEarningsFormSchema = Schema.Struct({
  date: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => O.isSome(input),
      {
        message: () => "Please select a DATE",
      },
    ),
  ),
  propertyId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<EnderId> => O.isSome(input), {
      message: () => "Please select a property",
    }),
  ),
});

type RetainedEarningsFormValues = Schema.Schema.Type<
  typeof RetainedEarningsFormSchema
>;

async function fetchLastRetainedDateForProperty(id: EnderId) {
  const rows: GLJournalEntry[] =
    await AccountingAPI.getRetainedEarningsJournalEntries({
      propertyId: id,
    });
  const str = rows
    ?.map(({ ledgerDate }) => ledgerDate)
    .sort()
    .reverse()[0];
  if (!str) {
    return UNDEFINED;
  }
  return O.getOrUndefined(LocalDate$.parse(str));
}

function buildConfirmMessage(values: RetainedEarningsFormValues) {
  const { propertyId, date } = values;
  const p = O.isSome(propertyId) ? O.getOrThrow(propertyId) : "(none)";
  const d = pipe(
    date,
    O.map((v) => LocalDate$.toFormatted(v, LocalDate$.Formats.DEFAULT)),
    O.getOrElse(() => "(none)"),
  );
  return `Are you sure you want to retain earnings for property [${p}] on [${d}]? This action cannot be reversed in this dashboard.`;
}

function RetainedEarnings() {
  useDocumentTitle("Retained Earnings - Ender");
  const [minDate, setMinDate] = useState<LocalDate$.LocalDate | undefined>();

  const form = useForm<RetainedEarningsFormValues>({
    defaultValues: {
      date: O.none(),
      propertyId: O.none(),
    },
    resolver: effectTsResolver(RetainedEarningsFormSchema),
  });

  const confirmation = useConfirmationContext();

  async function requestRetainEarnings(values: RetainedEarningsFormValues) {
    await confirmation({
      content: buildConfirmMessage(values),
      title: "Retain Earnings",
    });
    const { propertyId, date } = form.getValues();
    if (O.isNone(propertyId) || O.isNone(date)) {
      return;
    }
    if (minDate && date.value.isBefore(minDate)) {
      form.setError("date", {
        message: "You have already retained earnings for this date.",
      });
      return;
    }
    const dateExclusive = date.value.add({ days: 1 }).toJSON();
    try {
      await AccountingAPI.retainEarnings({
        dateExclusive,
        propertyId: propertyId.value,
      });
      const newMinDate = await fetchLastRetainedDateForProperty(
        propertyId.value,
      );
      setMinDate(newMinDate);
      showSuccessNotification({
        message: "Retained earnings posted successfully",
      });
    } catch (err) {
      fail(err);
    }
  }

  //
  useEffect(() => {
    const pid = form.watch("propertyId");
    if (O.isSome(pid)) {
      fetchLastRetainedDateForProperty(pid.value)
        .then((res) => setMinDate(res))
        .catch((err) => fail(err));
    } else {
      setMinDate(undefined);
    }
  }, [form]);

  //
  useEffect(() => {
    const d = form.watch("date");
    if (P.isNullable(minDate) || O.isNone(d)) {
      return;
    }
    if (d.value.isBefore(minDate)) {
      form.setError("date", {
        message: "You have already retained earnings for this date.",
      });
    }
  }, [form, minDate]);

  return (
    <>
      <Form onSubmit={requestRetainEarnings} form={form}>
        <Stack>
          <SearchInput
            clearable
            error={form.formState.errors.propertyId?.message}
            hydrate={hydratePropertyWithFriendlyId}
            label="Choose Property"
            modelType={ModelTypeEnum.PROPERTY}
            onChange={(opt) => form.setValue("propertyId", opt)}
            placeholder="Select a property"
            search={searchProperties}
            searchOnEmpty
            value={form.watch("propertyId")}
          />
          <DateInput
            error={form.formState.errors.date?.message}
            label="Choose Date"
            onChange={(val) => form.setValue("date", val)}
            value={form.watch("date")}
          />
          <Group justify="end">
            <Button type="submit">Retain Earnings</Button>
          </Group>
        </Stack>
      </Form>

      {minDate && (
        <Text size={FontSize.sm}>
          Last retained earnings date:{" "}
          {LocalDate$.toFormatted(minDate, LocalDate$.Formats.DEFAULT)}
        </Text>
      )}
    </>
  );
}

export { RetainedEarnings };
