/**
 * HTTP Method: POST
 * Pathname: /renewals-table
 * @function renewalsTableView
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIRenewalsTableViewPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetRenewalsReportResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GetRenewalsReportRequestLeaseType } from "@ender/shared/generated/ender.api.leasing.request";
import type { GetRenewalsReportResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { RenewalReportRenewalTab } from "@ender/shared/generated/ender.service.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIRenewalsTableViewSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIRenewalsTableViewBodyParams = {
  expireEndDate?: SerializesInto<LocalDate> | undefined;
  expireStartDate?: SerializesInto<LocalDate> | undefined;
  leaseType?: GetRenewalsReportRequestLeaseType | undefined;
  marketIds: EnderId[];
  propertyIds: EnderId[];
  renewalTab: RenewalReportRenewalTab;
  unitIds: EnderId[];
};

type LeasingMiddleLayerAPIRenewalsTableViewPayload =
  LeasingMiddleLayerAPIRenewalsTableViewSearchParams &
    LeasingMiddleLayerAPIRenewalsTableViewBodyParams;

function renewalsTableViewUnsafeEffect(
  payload: LeasingMiddleLayerAPIRenewalsTableViewPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetRenewalsReportResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetRenewalsReportResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/renewals-table",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function renewalsTableView(
  payload: LeasingMiddleLayerAPIRenewalsTableViewPayload,
  options?: { signal?: AbortSignal },
): Promise<GetRenewalsReportResponse[]> {
  return F.pipe(
    payload,
    renewalsTableViewUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { renewalsTableView };
export type {
  LeasingMiddleLayerAPIRenewalsTableViewBodyParams,
  LeasingMiddleLayerAPIRenewalsTableViewPayload,
  LeasingMiddleLayerAPIRenewalsTableViewSearchParams,
};
