/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/ledger.xlsx
 * @function ledgerExport
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPILedgerExportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { TenantLedgerReportServiceTenantLedgerSortKey } from "@ender/shared/generated/ender.service.reports.builtin";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPILedgerExportPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPILedgerExportSearchParams = {
  token?: string | undefined;
  ascending?: boolean | undefined;
  periodFilter?: PeriodFilter | undefined;
  sortKey?: TenantLedgerReportServiceTenantLedgerSortKey | undefined;
};

type TenantLedgerAPILedgerExportPayload =
  TenantLedgerAPILedgerExportPathParams &
    TenantLedgerAPILedgerExportSearchParams;

function ledgerExportUnsafeEffect(payload: TenantLedgerAPILedgerExportPayload) {
  const { leaseId, token, ascending, periodFilter, sortKey, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/ledger.xlsx`,
        searchParams: [
          ["token", token],
          ["ascending", ascending],
          ["periodFilter", periodFilter],
          ["sortKey", sortKey],
        ],
      }),
    ),
  );
}

function ledgerExport(
  payload: TenantLedgerAPILedgerExportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    ledgerExportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { ledgerExport };
export type {
  TenantLedgerAPILedgerExportPathParams,
  TenantLedgerAPILedgerExportPayload,
  TenantLedgerAPILedgerExportSearchParams,
};
