import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";

import { NULL } from "@ender/shared/constants/general";
import { Divider } from "@ender/shared/ds/divider";
import { Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import { RenewalsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type { GetRenewalsRequestRenewalTableTab } from "@ender/shared/generated/ender.api.leasing.request";
import { GetRenewalsRequestRenewalTableTabEnum } from "@ender/shared/generated/ender.api.leasing.request";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { replacePath } from "@ender/shared/utils/string";

import { AdditionalCharges } from "./additional-charges";
import { RenewalActions } from "./renewal-actions";
import { DraftedRenewalPackage, RenewalPackage } from "./renewal-package";
import type { RenewalOffer } from "./renewals.types";
import { getLatestOffers, shouldShowRenewalsTab } from "./renewals.utils";

const NewRenewalOffersStatus = new Set<GetRenewalsRequestRenewalTableTab>([
  GetRenewalsRequestRenewalTableTabEnum.NEEDS_OFFERS,
  GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED,
]);

const emptyArray: RenewalOffer[] = [];

type RenewalsTabProps = {
  lease: LeaseSerializerLeaseResponse;
};

function RenewalsTab({ lease }: RenewalsTabProps) {
  const { url } = useRouteMatch();

  const { data: renewalPackageResponse, refetch: refetchRenewalPackage } =
    useQuery({
      queryFn: () =>
        RenewalsMiddleLayerAPI.getRenewalPackage({ leaseId: lease.id }),
      queryKey: ["RenewalsMiddleLayerAPI.getRenewalPackage", lease.id],
    });

  const [newRenewalOffers, setNewRenewalsOffers] =
    useState<RenewalOffer[]>(emptyArray);

  const renewalPackage = useMemo(() => {
    return (
      renewalPackageResponse && {
        newRenewalOffers,
        ...renewalPackageResponse,
        // renewalStatus: GetRenewalsRequestRenewalTableTabEnum.NO_RENEW,
      }
    );
  }, [newRenewalOffers, renewalPackageResponse]);

  // clear newRenewalOffers when renewalPackage is not in new offers statuses
  useEffect(() => {
    if (P.isNotNullable(renewalPackageResponse)) {
      if (!NewRenewalOffersStatus.has(renewalPackageResponse.renewalStatus)) {
        setNewRenewalsOffers((newOffers) =>
          A.isEmptyArray(newOffers) ? newOffers : [],
        );
      } else if (
        renewalPackageResponse.renewalStatus ===
        GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED
      ) {
        setNewRenewalsOffers((newOffers) =>
          A.isEmptyArray(newOffers)
            ? getLatestOffers(renewalPackageResponse)
            : newOffers,
        );
      }
    }
  }, [setNewRenewalsOffers, renewalPackageResponse]);

  const queryClient = useQueryClient();

  const refetchLease = useCallback(
    () => queryClient.invalidateQueries(["LeaseDetailsAPI.getLease", lease.id]),
    [queryClient, lease.id],
  );

  if (!shouldShowRenewalsTab(lease)) {
    return <Redirect to={replacePath({ url })} />;
  }

  if (P.isNullable(renewalPackage)) {
    return NULL;
  }

  if (lease.isDrafting) {
    return (
      <DraftedRenewalPackage
        lease={lease}
        renewalPackage={renewalPackage}
        onNewOffersUpdate={setNewRenewalsOffers}
        refetchLease={refetchLease}
        refetchRenewalPackage={refetchRenewalPackage}
      />
    );
  }

  return (
    <Stack spacing={Spacing.lg}>
      <RenewalPackage
        lease={lease}
        renewalPackage={renewalPackage}
        onNewOffersUpdate={setNewRenewalsOffers}
        refetchLease={refetchLease}
        refetchRenewalPackage={refetchRenewalPackage}
      />
      <AdditionalCharges leaseId={lease.id} renewalPackage={renewalPackage} />
      <Divider />
      <RenewalActions
        lease={lease}
        renewalPackage={renewalPackage}
        refetchLease={refetchLease}
        refetchRenewalPackage={refetchRenewalPackage}
      />
    </Stack>
  );
}

export { RenewalsTab };
