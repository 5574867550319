import { z } from "zod";

import { TextInput } from "@ender/shared/ds/text-input";
import {
  DataPropsSchema,
  InputInfoPropsSchema,
  ReactNodeSchema,
} from "@ender/shared/forms/types/general";
import { ContextAwareInput } from "@ender/shared/forms/ui/context-aware-input";

/** @deprecated This should not be used anymore. */
const TextInputBasePropsSchema = z.object({
  height: z.enum(["xs", "sm", "md", "lg", "xl"]).optional(),
  icon: ReactNodeSchema.optional(),
  maxLength: z.number().positive().optional(),
  minLength: z.number().positive().optional(),
  rightSection: ReactNodeSchema.optional(),
  type: z.enum(["text", "email", "password", "tel"]).optional(),
});

/** @deprecated This should not be used anymore. */
const EnderInputTextPropsSchema =
  TextInputBasePropsSchema.merge(InputInfoPropsSchema).and(DataPropsSchema);
type EnderInputTextProps = z.infer<typeof EnderInputTextPropsSchema>;

/** @deprecated This should not be used anymore. */
function EnderInputText(props: EnderInputTextProps & { name: string }) {
  const { name, ...rest } = props;
  return <ContextAwareInput Component={TextInput} name={name} props={rest} />;
}

export { EnderInputText, EnderInputTextPropsSchema };
export type { EnderInputTextProps };
