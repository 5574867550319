import type { Undefined } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";

/**
 * Get Invoice Party Ids
 * is a convenience function for translating both invoiceType and fund logic into a standardized InvoicePartyIds object.
 * - the standardized object seeks to resolve ambiguity/complexity for any from/to us/them payable/receivables context
 * - supports only PAYABLE or RECEIVABLE invoice types
 * - if ourFundId is available it takes precedence over any other ourPartyId
 */
type InvoicePartyIds = {
  fromId: EnderId | Undefined; // from
  ourId: EnderId | Undefined; // us
  theirId: EnderId | Undefined; // them
  toId: EnderId | Undefined; // to
};

type GetInvoicePartyIdsProps = {
  fromPartyId: EnderId | Undefined;
  invoiceType: InvoiceInvoiceType;
  ourFundId?: EnderId | Undefined;
  toPartyId: EnderId | Undefined;
};

function getInvoicePartyIds({
  fromPartyId,
  invoiceType,
  ourFundId,
  toPartyId,
}: GetInvoicePartyIdsProps): InvoicePartyIds {
  // Type guard to ensure only supported types are processed
  if (
    invoiceType !== InvoiceInvoiceTypeEnum.PAYABLE &&
    invoiceType !== InvoiceInvoiceTypeEnum.RECEIVABLE
  ) {
    throw new Error(
      `Invalid invoice type: ${invoiceType}. Only PAYABLE and RECEIVABLE are supported.`,
    );
  }

  if (invoiceType === InvoiceInvoiceTypeEnum.PAYABLE) {
    const ourFromId = ourFundId || fromPartyId;
    const theirToId = toPartyId;
    return {
      fromId: ourFromId,
      ourId: ourFromId,
      theirId: theirToId,
      toId: theirToId,
    };
  }

  // Must be RECEIVABLE at this point
  const ourToId = ourFundId || toPartyId;
  const theirFromId = fromPartyId;
  return {
    fromId: theirFromId,
    ourId: ourToId,
    theirId: theirFromId,
    toId: ourToId,
  };
}

export { getInvoicePartyIds };
export type { GetInvoicePartyIdsProps };
