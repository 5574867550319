import { useMutation } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useCallback, useContext } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { BankTransaction } from "@ender/shared/generated/ender.model.payments";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { CreateInvoice } from "@ender/widgets/finance/create-invoice";

type ReconcileSolutionProps = {
  bankTransaction?: BankTransaction;
  isDeposit: boolean;
  onBatchMatchClick: () => void;
  onConfirmExclude: () => void;
  onExcludeClick?: () => void;
  onInvoiceSuccessfullyCreated: (value: string) => void;
  property?: {
    id: EnderId;
    name: string;
  };
  reconcileTransactionAmount?: string;
  refreshTransactions: () => void;
};

function ReconcileSolution({
  bankTransaction,
  isDeposit,
  onBatchMatchClick,
  onConfirmExclude,
  onInvoiceSuccessfullyCreated,
  property,
  reconcileTransactionAmount,
  refreshTransactions,
}: ReconcileSolutionProps) {
  const { hasPermissions } = useContext(UserContext);
  const canExcludeFromBankRec = hasPermissions(
    FunctionalPermissionEnum.EXCLUDE_FROM_BANK_REC,
  );
  const canManualMatch = hasPermissions(
    FunctionalPermissionEnum.MANUAL_BANK_REC,
  );
  const invoiceType = isDeposit
    ? InvoiceInvoiceTypeEnum.RECEIVABLE
    : InvoiceInvoiceTypeEnum.PAYABLE;

  const { mutateAsync: ignoreBankTransaction } = useMutation({
    mutationFn: BankingAPI.ignoreBankTransaction,
    mutationKey: ["BankingAPI.ignoreBankTransaction"] as const,
  });

  const confirmation = useConfirmationContext();

  const handleExclude = useCallback(async () => {
    if (P.isNullable(bankTransaction)) {
      return;
    }
    await confirmation({
      content: "The total will still be included in your starting balance.",
      title: "Are you sure you would like to exclude this bank transaction?",
    });
    onConfirmExclude();
    await ignoreBankTransaction({ bankTransactionId: bankTransaction?.id });
    refreshTransactions();
  }, [
    bankTransaction,
    confirmation,
    ignoreBankTransaction,
    onConfirmExclude,
    refreshTransactions,
  ]);

  /**
   * Herein, Create Invoice is a special case.
   * In the normal case, we create an invoice to be properly processed and approved before the transaction occurs.
   * In this special case, the bank transaction is present, meaning the money has already left the account.
   * We are creating a record for this transaction.
   * A well-permissioned user can instantly mark the invoice as paid.
   * We do not allow the selection of a bank account.
   * We create the invoice, immediately mark it as paid, and immediately match it on the bank rec to turn the row green.
   */
  return (
    <>
      <CreateInvoice
        bankTransaction={bankTransaction}
        invoiceType={invoiceType}
        onInvoiceSuccessfullyCreated={onInvoiceSuccessfullyCreated}
        property={property}
        selectedPages={UNDEFINED}
        hideUploadInvoice
        reconcileTransactionAmount={reconcileTransactionAmount}
      />
      <Stack align={Align.center} spacing={Spacing.sm}>
        <Text size={FontSize.md}>Or you can try to</Text>
        <Group justify={Justify.between} align={Align.center}>
          <Button
            disabledTooltip="You do not have permission to exclude a bank transaction."
            disabled={!canExcludeFromBankRec}
            onClick={handleExclude}>
            Exclude
          </Button>
          <Button
            disabled={!canManualMatch}
            disabledTooltip="You do not have permission to manually reconcile Ender transactions. Please contact your system administrator to gain this permission."
            onClick={onBatchMatchClick}>
            Manually Match
          </Button>
        </Group>
      </Stack>
    </>
  );
}

export { ReconcileSolution };
