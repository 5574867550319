import { IconDots } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Array as A, Predicate as P, String as S } from "effect";
import type { MouseEvent } from "react";
import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import type {
  LeaseSerializerLeaseContact,
  LeaseSerializerLeaseResponse,
} from "@ender/shared/generated/ender.arch.serializer.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { fail } from "@ender/shared/utils/error";
import { navigateToApp } from "@ender/shared/utils/navigation";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type LeaseContactMenuProps = {
  lease: LeaseSerializerLeaseResponse;
  contact: LeaseSerializerLeaseContact;
  onEditContactClick: (contact: LeaseSerializerLeaseContact) => void;
  refetchContacts: () => Promise<void>;
};

function LeaseContactMenu({
  lease,
  contact,
  onEditContactClick,
  refetchContacts,
}: LeaseContactMenuProps) {
  const confirmation = useContext(ConfirmationContext);

  const history = useHistory();

  const { userPM, hasPermissions } = useContext(UserContext);
  const { mutateAsync: loginAs } = useMutation({
    mutationFn: AuthAPI.loginAs,
    mutationKey: ["AuthAPI.loginAs"] as const,
  });

  /**
   * @function loginAsContact
   * @description Masquerades the current user as the lease contact
   */
  async function loginAsContact(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    await loginAs({ userId: contact.id }).catch(fail);
    navigateToApp();
  }

  /**
   * @function onboardContact
   * @description Handles attempting to onboard the contact
   */
  async function onboardContact() {
    try {
      await UsersAPI.onboardTenant({ targetId: contact.id });
      showSuccessNotification({
        message: `Onboarding Link sent to ${contact.name}.`,
      });
    } catch (err) {
      fail(err);
    }
  }

  const { mutate: removeContact } = useMutation({
    mutationFn: async () => {
      await LeasingAPI.removeLeaseContact({
        leaseId: lease.id,
        tenantId: contact.id,
      });
      showSuccessNotification({ message: "Contact Removed" });
      await refetchContacts();
    },
    mutationKey: ["LeasingAPI.removeLeaseContact"] as const,
  });

  const onRemoveContactClick = useCallback(async () => {
    try {
      await confirmation({
        title: `Are you sure you wish to remove ${contact.name} from the lease?`,
      });
    } catch {
      return;
    }

    removeContact();
  }, [removeContact, confirmation, contact.name]);

  const isOnboardingDisabled = !contact.email && !contact.phone;

  const disabledReasonLabel =
    A.length(lease.contacts ?? []) === 1
      ? "The last contact on a lease cannot be removed."
      : "";

  const { mutateAsync: updateUserEsusu } = useMutation({
    mutationFn: async () => {
      await UsersAPI.updateUser({
        json: { esusuEnrolled: !contact.esusuEnrolled },
        targetUserId: contact.id,
      });
    },
    mutationKey: ["UsersAPI.updateUser"] as const,
  });

  async function onEsusuOptClick() {
    try {
      await confirmation({
        title: `Are you sure you wish to ${contact.esusuEnrolled ? "opt out" : "opt in"} of Esusu on behalf of ${
          contact.name
        }?`,
      });
    } catch {
      return;
    }
    await updateUserEsusu();
    await refetchContacts();
  }

  const showEsusuAction =
    userPM.enableEsusuReporting &&
    contact.roles.includes(
      LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
    ) &&
    hasPermissions([FunctionalPermissionEnum.EDIT_ESUSU]);

  return (
    <Menu>
      <MenuTrigger>
        <ActionIcon label="Open Contact Menu">
          <IconDots />
        </ActionIcon>
      </MenuTrigger>
      <MenuContent align="end">
        <MenuButton onClick={() => onEditContactClick(contact)}>
          Edit Contact
        </MenuButton>

        <Tooltip
          disabled={S.isEmpty(disabledReasonLabel)}
          label={disabledReasonLabel}>
          <MenuButton
            disabled={S.isNonEmpty(disabledReasonLabel)}
            onClick={onRemoveContactClick}>
            Remove Contact
          </MenuButton>
        </Tooltip>

        {showEsusuAction && (
          <MenuButton onClick={() => onEsusuOptClick()}>
            {contact.esusuEnrolled ? "Opt out of Esusu" : "Opt in to Esusu"}
          </MenuButton>
        )}

        <Tooltip
          label="Contact must have an email and/or phone number before a link can be sent."
          disabled={!isOnboardingDisabled}>
          <MenuButton disabled={isOnboardingDisabled} onClick={onboardContact}>
            {contact.onboarded
              ? "Resend Onboarding Link"
              : "Send Onboarding Link"}
          </MenuButton>
        </Tooltip>
        {P.isNotNullable(lease?.applicationGroupId) && (
          <MenuButton
            onClick={() =>
              history.push(
                `/leasing-center/applications/${lease.applicationGroupId}`,
              )
            }>
            View Application
          </MenuButton>
        )}
        <MenuButton onClick={loginAsContact}>Sign In As Contact</MenuButton>
      </MenuContent>
    </Menu>
  );
}

export { LeaseContactMenu };
