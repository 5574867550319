/**
 * HTTP Method: POST
 * Pathname: /files/{fileId}/tag
 * @function tagFile
 * @memberof WebserverFilesAPI
 * @param {WebserverFilesAPITagFilePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type WebserverFilesAPITagFilePathParams = {
  fileId: EnderId;
};

type WebserverFilesAPITagFileSearchParams = {
  token?: string | undefined;
};

type WebserverFilesAPITagFileBodyParams = {
  key: string;
  modelId: EnderId;
  modelType: ModelType;
  overrideWarnings?: boolean | undefined;
  value: string;
};

type WebserverFilesAPITagFilePayload = WebserverFilesAPITagFilePathParams &
  WebserverFilesAPITagFileSearchParams &
  WebserverFilesAPITagFileBodyParams;

function tagFileUnsafeEffect(payload: WebserverFilesAPITagFilePayload) {
  const { fileId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/files/${fileId}/tag`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function tagFile(
  payload: WebserverFilesAPITagFilePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    tagFileUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { tagFile };
export type {
  WebserverFilesAPITagFileBodyParams,
  WebserverFilesAPITagFilePathParams,
  WebserverFilesAPITagFilePayload,
  WebserverFilesAPITagFileSearchParams,
};
