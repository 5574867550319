import { Predicate as P } from "effect";
import { useState } from "react";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

type DealNameFilterProps = FilterComponentProps & {
  metadata: {
    dealNames: string[];
  };
};

function getDealNameFilter(
  value: string,
  factor?: WidgetFactor,
): WidgetFilter[] | Undefined {
  if (P.isNullable(value) || P.isNullable(factor)) {
    return UNDEFINED;
  }

  return [
    {
      factor,
      operator: DynamicTableOperatorsEnum.IN,
      values: [value],
    },
  ];
}

function getDefaultValue(widget: Widget) {
  const result = widget.filters
    .find(({ factor }) => factor.name === Factor.NAME)
    ?.values?.[0].toString();
  if (P.isNullable(result)) {
    return NULL;
  }
  return result;
}

function DealNameFilter({
  factor,
  metadata,
  updateFilters,
  widget,
}: DealNameFilterProps) {
  const [selected, setSelected] = useState<string | Null>(
    getDefaultValue(widget),
  );

  function handleChange(value: string | Null) {
    if (P.isNull(value)) {
      setSelected(NULL);
      updateFilters(Factor.NAME, UNDEFINED);
    } else {
      setSelected(value);
      const filters = getDealNameFilter(value, factor);
      updateFilters(Factor.NAME, filters);
    }
  }

  return (
    <Select
      label="Deal Name"
      data={
        metadata.dealNames?.map((value) => ({
          label: value || "--",
          value: value,
        })) || []
      }
      clearable
      searchable
      placeholder="Select Deal Name"
      value={selected}
      onChange={handleChange}
    />
  );
}

export { DealNameFilter };
