import { z } from "zod";

import { cast } from "@ender/shared/types/cast";
import type { LabelValue } from "@ender/shared/types/label-value";

const AmenityTypeValues = [
  /*
   * Old Types
   */
  "POOL",
  "GYM",
  "FIRE_PIT",
  "SAUNA",
  "ELEVATOR",
  "COVERED_PARKING",
  /*
   * A/C Related
   */
  "IN_WALL_AC",
  "CENTRAL_AC",
  /*
   * Laundry Related
   */
  "ON_SITE_LAUNDRY",
  "IN_UNIT_LAUNDRY",
  "IN_UNIT_LAUNDRY_HOOKUP",
  /*
   * Storage Related
   */
  "GARAGE_STORAGE",
  "STORAGE_UNIT",
  /*
   * Outdoors Related
   */
  "PLAYGROUND",
  "GREEN_AREA",
  "BACK_YARD",
  "WALKING_TRAIL",
  /*
   * Common Areas Related
   */
  "BALCONY",
  "CLUBHOUSE",
  "ROOFTOP_DECK",
  /*
   * Property Misc
   */
  "PET_FRIENDLY",
  "PRIVATE_ENTRANCE",
  "GARAGE",
  "FINISHED_BASEMENT",
  "PRIVATE_POOL",
  "AIR_CONDITIONING",
  "LAUNDRY_ROOM",
  "WASHER_DRYER_HOOKUPS",
  "WASHER_DRYER",
  "SMART_HOME_TECHNOLOGY",
  "STAINLESS_STEEL_APPLIANCES",
  "COVERED_PATIO",
  "ONE_CAR_GARAGE",
  "TWO_CAR_GARAGE",
  "ONE_STORY_HOME",
  "TWO_STORY_HOME",
  "GAS_FIREPLACE",
  "ELECTRIC_FIREPLACE",
  "CEILING_FANS",
  /*
   * Unit Misc
   */
  "PRIVATE_BALCONY",
] as const;
const AmenityTypeSchema = z.enum(AmenityTypeValues);
type AmenityType = z.infer<typeof AmenityTypeSchema>;

const AmenityTypeDisplayEnum: Record<AmenityType, string> = {
  AIR_CONDITIONING: "Air Conditioning",
  BACK_YARD: "Backyard",
  BALCONY: "Balcony",
  CEILING_FANS: "Ceiling Fans",
  CENTRAL_AC: "Central AC",
  CLUBHOUSE: "Clubhouse",
  COVERED_PARKING: "Covered Parking",
  COVERED_PATIO: "Covered Patio",
  ELECTRIC_FIREPLACE: "Electric Fireplace",
  ELEVATOR: "Elevator",
  FINISHED_BASEMENT: "Finished Basement",
  FIRE_PIT: "Fire Pit",
  GARAGE: "Garage",
  GARAGE_STORAGE: "Garage Storage",
  GAS_FIREPLACE: "Gas Fireplace",
  GREEN_AREA: "Green Area",
  GYM: "Gym",
  IN_UNIT_LAUNDRY: "In-Unit Laundry",
  IN_UNIT_LAUNDRY_HOOKUP: "In-Unit Laundry Hookup",
  IN_WALL_AC: "In-Wall AC",
  LAUNDRY_ROOM: "Laundry Room",
  ONE_CAR_GARAGE: "One-Car Garage",
  ONE_STORY_HOME: "One-Story Home",
  ON_SITE_LAUNDRY: "On-Site Laundry",
  PET_FRIENDLY: "Pet Friendly",
  PLAYGROUND: "Playground",
  POOL: "Pool",
  PRIVATE_BALCONY: "Private Balcony",
  PRIVATE_ENTRANCE: "Private Entrance",
  PRIVATE_POOL: "Private Pool",
  ROOFTOP_DECK: "Rooftop Deck",
  SAUNA: "Sauna",
  SMART_HOME_TECHNOLOGY: "Smart Home Technology",
  STAINLESS_STEEL_APPLIANCES: "Stainless Steel Appliances",
  STORAGE_UNIT: "Storage Unit",
  TWO_CAR_GARAGE: "Two-Car Garage",
  TWO_STORY_HOME: "Two-Story Home",
  WALKING_TRAIL: "Walking Trail",
  WASHER_DRYER: "Washer/Dryer",
  WASHER_DRYER_HOOKUPS: "Washer/Dryer Hookups",
} as const;

/**
 * Amenity data used for Select dropdowns
 * //TODO this might belong somewhere else as it isn't a type. It isn't an enum, either. Do we need a 'data' folder in shared?
 */
const AmenityData: LabelValue<AmenityType>[] = Object.entries(
  AmenityTypeDisplayEnum,
).map(([value, label]) => ({
  label,
  value: cast<AmenityType>(value),
}));

type MantineSelectItem = {
  group?: string;
  label: string;
  uuid?: string;
  value: unknown;
};

export type { AmenityType, MantineSelectItem };

export { AmenityData, AmenityTypeDisplayEnum, AmenityTypeSchema };
