import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaxMethodTaxMethodTypeValues = [
  "ASSESSED_VALUE",
  "OFFER_PRICE",
  "SELLER_TAXES",
] as const;

const TaxMethodTaxMethodTypeEffectSchema = Schema.Literal(
  ...TaxMethodTaxMethodTypeValues,
);

type TaxMethodTaxMethodType = Schema.Schema.Type<
  typeof TaxMethodTaxMethodTypeEffectSchema
>;

const TaxMethodTaxMethodTypeEnum = castEnum(TaxMethodTaxMethodTypeEffectSchema);

function randomTaxMethodTaxMethodType(): TaxMethodTaxMethodType {
  return rand(TaxMethodTaxMethodTypeValues);
}

export {
  randomTaxMethodTaxMethodType,
  TaxMethodTaxMethodTypeEffectSchema,
  TaxMethodTaxMethodTypeEnum,
  TaxMethodTaxMethodTypeValues,
};
export type { TaxMethodTaxMethodType };
