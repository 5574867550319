import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { TextInputProps } from "./shared-ds-text-input";
import { TextInput } from "./shared-ds-text-input";

type FormTextInputProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<TextInputProps, Form, Name>;

function FormTextInput<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormTextInputProps<Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field, error }) => {
        return <TextInput {...rest} {...field} error={error} />;
      }}
    </FormField>
  );
}

export { FormTextInput };

export type { FormTextInputProps };
