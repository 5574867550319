import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetBankTransactionsRequestMatchFilterValues = [
  "ALL",
  "MATCHED",
  "UNMATCHED",
] as const;

const GetBankTransactionsRequestMatchFilterEffectSchema = Schema.Literal(
  ...GetBankTransactionsRequestMatchFilterValues,
);

type GetBankTransactionsRequestMatchFilter = Schema.Schema.Type<
  typeof GetBankTransactionsRequestMatchFilterEffectSchema
>;

const GetBankTransactionsRequestMatchFilterEnum = castEnum(
  GetBankTransactionsRequestMatchFilterEffectSchema,
);

function randomGetBankTransactionsRequestMatchFilter(): GetBankTransactionsRequestMatchFilter {
  return rand(GetBankTransactionsRequestMatchFilterValues);
}

export {
  GetBankTransactionsRequestMatchFilterEffectSchema,
  GetBankTransactionsRequestMatchFilterEnum,
  GetBankTransactionsRequestMatchFilterValues,
  randomGetBankTransactionsRequestMatchFilter,
};
export type { GetBankTransactionsRequestMatchFilter };
