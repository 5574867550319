import { IconSearch } from "@tabler/icons-react";
import type { Table } from "@tanstack/react-table";
import { useCallback, useRef, useState } from "react";

import { NULL } from "@ender/shared/constants/general";
import { TextInput } from "@ender/shared/ds/text-input";

function GlobalFilter<RowData>(props: { table: Table<RowData> }) {
  const { table } = props;
  const { getState, options, setGlobalFilter } = table;
  const { enableGlobalFilter } = options;

  const timerIdRef = useRef<ReturnType<typeof setTimeout>>();
  const [value, setValue] = useState(() => getState().globalFilter);
  const onChange = useCallback(
    (nextValue: string) => {
      setValue(nextValue);

      // Wait till the user stops typing for 400MS before we update the table's state
      clearTimeout(timerIdRef.current);
      timerIdRef.current = setTimeout(() => {
        setGlobalFilter(nextValue);
      }, 400);
    },
    [setGlobalFilter],
  );

  return enableGlobalFilter ? (
    <TextInput
      placeholder="Search"
      leftSection={<IconSearch />}
      value={value}
      onChange={onChange}
      autoComplete="off"
    />
  ) : (
    NULL
  );
}

export { GlobalFilter };
