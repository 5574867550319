/**
 * Delete a GL category.
 * HTTP Method: DELETE
 * Pathname: /accounting/categories/{categoryId}
 * @function deleteCategory
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIDeleteCategoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIDeleteCategoryPathParams = {
  /**
   * The ID of the category.
   */
  categoryId: EnderId;
};

type GeneralLedgerAPIDeleteCategorySearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIDeleteCategoryPayload =
  GeneralLedgerAPIDeleteCategoryPathParams &
    GeneralLedgerAPIDeleteCategorySearchParams;

function deleteCategoryUnsafeEffect(
  payload: GeneralLedgerAPIDeleteCategoryPayload,
) {
  const { categoryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/accounting/categories/${categoryId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteCategory(
  payload: GeneralLedgerAPIDeleteCategoryPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteCategoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteCategory };
export type {
  GeneralLedgerAPIDeleteCategoryPathParams,
  GeneralLedgerAPIDeleteCategoryPayload,
  GeneralLedgerAPIDeleteCategorySearchParams,
};
