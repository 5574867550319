/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/assessorData
 * @function getAssessorData
 * @memberof BuyAPI
 * @param {BuyAPIGetAssessorDataPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetAssessorDataResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetAssessorDataResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetAssessorDataPathParams = {
  dealId: EnderId;
};

type BuyAPIGetAssessorDataSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetAssessorDataPayload = BuyAPIGetAssessorDataPathParams &
  BuyAPIGetAssessorDataSearchParams;

function getAssessorDataUnsafeEffect(payload: BuyAPIGetAssessorDataPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetAssessorDataResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetAssessorDataResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/assessorData`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAssessorData(
  payload: BuyAPIGetAssessorDataPayload,
  options?: { signal?: AbortSignal },
): Promise<GetAssessorDataResponse> {
  return F.pipe(
    payload,
    getAssessorDataUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAssessorData };
export type {
  BuyAPIGetAssessorDataPathParams,
  BuyAPIGetAssessorDataPayload,
  BuyAPIGetAssessorDataSearchParams,
};
