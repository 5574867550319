import { useMutation } from "@tanstack/react-query";

import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import type { GetFilesResponseFileResponse } from "@ender/shared/generated/ender.api.files.response";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { EnderDocumentsTable } from "@ender/shared/ui/ender-documents-table";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { useFetchLeaseDocuments } from "./use-fetch-lease-documents";

type TenantDocumentsProps = {
  lease: LeaseSerializerLeaseResponse;
};

function TenantDocuments({ lease }: TenantDocumentsProps) {
  const {
    leaseDocuments,
    refetchLeaseDocuments,
    isDocumentsFetching,
    isInitialLoading,
  } = useFetchLeaseDocuments(lease);

  async function handleUploadSuccess() {
    showSuccessNotification({
      message: "Lease documents successfully uploaded",
    });
    refetchLeaseDocuments();
  }

  const { mutateAsync: deleteDocument } = useMutation({
    mutationFn: async (document: GetFilesResponseFileResponse) => {
      await WebserverFilesAPI.deleteFile({
        fileId: document.id,
        modelId: document.modelId,
        modelType: document.modelType,
        subFolder: document.subFolder,
      });

      refetchLeaseDocuments();
    },
  });

  if (isDocumentsFetching || isInitialLoading) {
    return null;
  }

  return (
    <EnderDocumentsTable
      modelId={lease.id}
      modelType={ModelTypeEnum.LEASE}
      documents={leaseDocuments ?? []}
      onUploadSuccess={handleUploadSuccess}
      onDeleteDocument={async (document) => deleteDocument(document)}
    />
  );
}

export { TenantDocuments };
