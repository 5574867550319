import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationIdentityVerificationResultValues = [
  "SKIPPED",
  "PASSED",
  "FAILED",
  "MANUAL_VERIFICATION_REQUIRED",
] as const;

const ApplicationIdentityVerificationResultEffectSchema = Schema.Literal(
  ...ApplicationIdentityVerificationResultValues,
);

type ApplicationIdentityVerificationResult = Schema.Schema.Type<
  typeof ApplicationIdentityVerificationResultEffectSchema
>;

const ApplicationIdentityVerificationResultEnum = castEnum(
  ApplicationIdentityVerificationResultEffectSchema,
);

function randomApplicationIdentityVerificationResult(): ApplicationIdentityVerificationResult {
  return rand(ApplicationIdentityVerificationResultValues);
}

export {
  ApplicationIdentityVerificationResultEffectSchema,
  ApplicationIdentityVerificationResultEnum,
  ApplicationIdentityVerificationResultValues,
  randomApplicationIdentityVerificationResult,
};
export type { ApplicationIdentityVerificationResult };
