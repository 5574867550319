import { Option as O } from "effect";
import { useWatch } from "react-hook-form";

import {
  FormSearchInput,
  hydrateCategory,
  searchGetTxCategories,
} from "@ender/entities/search-input";
import type { FormSubSectionReference } from "@ender/form-system/base";
import { FormSection } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { FormPayableCategorySelect } from "@ender/widgets/finance/allocation-list";

import type {
  AllocationSchemaInput,
  EditInvoiceAllocationsFormOutput,
} from "../use-edit-invoice-allocations-form";

type AllocationListItemProps = {
  canRemove: boolean;
  isReadonly: boolean;
  removeAllocation: () => void;
} & FormSubSectionReference<
  EditInvoiceAllocationsFormOutput,
  AllocationSchemaInput
>;

function AllocationListItem(props: AllocationListItemProps) {
  const { canRemove, isReadonly, removeAllocation, form, name } = props;
  const canDeleteAllocation = canRemove && !isReadonly;

  const glCategoryId: O.Option<EnderId> = useWatch({
    control: form.control,
    name: `${name}.accountId`,
  });

  return (
    <FormSection form={form} name={name}>
      {({ section }) => {
        return (
          <>
            <Group justify={Justify.end}>
              <Button
                disabled={!canDeleteAllocation}
                onClick={removeAllocation}
                variant={ButtonVariant.transparent}>
                Remove Allocation
              </Button>
            </Group>
            <FormSearchInput<EnderId, typeof form, typeof section.accountId>
              disabled={isReadonly}
              label="GL Category"
              search={searchGetTxCategories}
              hydrate={hydrateCategory}
              searchOnEmpty
              modelType={ModelTypeEnum.GL_CATEGORY}
              form={form}
              name={section.accountId}
              clearable
            />
            <FormTextInput
              label="Description"
              form={form}
              name={section.description}
            />
            <FormMoneyInput label="Amount" form={form} name={section.amount} />
            <FormPayableCategorySelect
              name={section.payableCategoryId}
              form={form}
              disabled={O.isNone(glCategoryId)}
              glCategoryId={glCategoryId}
            />
          </>
        );
      }}
    </FormSection>
  );
}

export { AllocationListItem };
