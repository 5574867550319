import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ShowingReservationStatusValues = [
  "WILL_NOTIFY",
  "NOTIFIED",
  "CANNOT_NOTIFY",
  "CONFIRMED",
  "RESCHEDULED",
  "CODE_SENT",
  "ACCESSED",
  "COMPLETED",
  "REVIEWED",
  "NOT_INTERESTED",
  "NO_SHOW",
  "CANCELLED",
  "ERROR",
] as const;

const ShowingReservationStatusEffectSchema = Schema.Literal(
  ...ShowingReservationStatusValues,
);

type ShowingReservationStatus = Schema.Schema.Type<
  typeof ShowingReservationStatusEffectSchema
>;

const ShowingReservationStatusEnum = castEnum(
  ShowingReservationStatusEffectSchema,
);

function randomShowingReservationStatus(): ShowingReservationStatus {
  return rand(ShowingReservationStatusValues);
}

export {
  randomShowingReservationStatus,
  ShowingReservationStatusEffectSchema,
  ShowingReservationStatusEnum,
  ShowingReservationStatusValues,
};
export type { ShowingReservationStatus };
