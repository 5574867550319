import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeaseCategoryRuleAllocationStrategyValues = [
  "PRORATA_AMONG_ALL_UNITS",
  "PRORATA_AMONG_OCCUPIED_UNITS",
] as const;

const LeaseCategoryRuleAllocationStrategyEffectSchema = Schema.Literal(
  ...LeaseCategoryRuleAllocationStrategyValues,
);

type LeaseCategoryRuleAllocationStrategy = Schema.Schema.Type<
  typeof LeaseCategoryRuleAllocationStrategyEffectSchema
>;

const LeaseCategoryRuleAllocationStrategyEnum = castEnum(
  LeaseCategoryRuleAllocationStrategyEffectSchema,
);

function randomLeaseCategoryRuleAllocationStrategy(): LeaseCategoryRuleAllocationStrategy {
  return rand(LeaseCategoryRuleAllocationStrategyValues);
}

export {
  LeaseCategoryRuleAllocationStrategyEffectSchema,
  LeaseCategoryRuleAllocationStrategyEnum,
  LeaseCategoryRuleAllocationStrategyValues,
  randomLeaseCategoryRuleAllocationStrategy,
};
export type { LeaseCategoryRuleAllocationStrategy };
