import type { SortingState } from "@tanstack/react-table";
import { Array as A, Option as O, Predicate as P, String as S } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import type { InvoicesMiddleLayerAPIGetPayablesReceivablesPayload } from "@ender/shared/generated/com.ender.middle";
import type {
  InvoicesAPIGetInvoicesPayload,
  InvoicesAPIGetInvoicesSummaryPayload,
} from "@ender/shared/generated/ender.api.accounting";
import type { SearchPropertiesResponseSearchPropertiesResult } from "@ender/shared/generated/ender.api.core.response";
import { PeriodFilterFilterTypeEnum } from "@ender/shared/generated/ender.api.model";
import type { MultiDBQueriesGetInvoicesSortableFields } from "@ender/shared/generated/ender.db.multi";
import { MultiDBQueriesGetInvoicesSortableFieldsEnum } from "@ender/shared/generated/ender.db.multi";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import type {
  InvoiceInvoiceType,
  InvoicePayableType,
  InvoicePaymentMethod,
} from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";

import type { PartyFilterValue } from "../../../../../widgets/invoice-filters/party-filter-value";
import type { InvoiceTableTabs } from "../../invoice-table-tabs.types";
import { InvoiceTableTabsEnum } from "../../invoice-table-tabs.types";
import { getAmountMatchForPayload } from "./get-amount-match-for-payload";
import { getApprovalStatusForPayload } from "./get-approval-status-for-payload";
import { getGuardedPaymentMethodType } from "./get-guarded-payment-method-type";

type CreateCommonInvoicesPayloadParams = {
  accountingPeriodsFilter: LocalDate$.LocalDate[];
  amountMatch?: string;
  endDate: O.Option<LocalDate$.LocalDate>;
  inclusiveEndInvoiceDueDate: O.Option<LocalDate$.LocalDate>;
  externalInvoiceId?: string;
  firmFilter: EnderId[];
  fundFilter: EnderId[];
  inclusiveEndPaidDate: O.Option<LocalDate$.LocalDate>;
  invoiceType: InvoiceInvoiceType;
  limit?: number;
  marketIds?: EnderId[];
  party?: PartyFilterValue;
  payableTypes: O.Option<InvoicePayableType[]>;
  paymentTypeFilter?: InvoicePaymentMethod;
  properties: SelectOption<
    EnderId,
    Pick<SearchPropertiesResponseSearchPropertiesResult, "name" | "id">
  >[];
  startDate: O.Option<LocalDate$.LocalDate>;
  startInvoiceDueDate: O.Option<LocalDate$.LocalDate>;
  startPaidDate: O.Option<LocalDate$.LocalDate>;
  tab: InvoiceTableTabs;
  tableSorting?: SortingState;
};

function createDateFilters(
  params: Pick<
    CreateCommonInvoicesPayloadParams,
    | "startDate"
    | "startInvoiceDueDate"
    | "endDate"
    | "inclusiveEndInvoiceDueDate"
    | "startPaidDate"
    | "inclusiveEndPaidDate"
  >,
) {
  const formatDate = (
    date: O.Option<LocalDate$.LocalDate | LocalDate$.Serialized>,
  ): LocalDate | Undefined => {
    const value = O.getOrNull(date);
    if (value === null) {
      return UNDEFINED;
    }
    if (typeof value === "string") {
      return LocalDate$.of(value).toJSON();
    }
    return value.toJSON();
  };

  return {
    inclusiveEndInvoiceDueDate: formatDate(params.inclusiveEndInvoiceDueDate),
    inclusiveEndInvoiceLedgerDate: formatDate(params.endDate),
    inclusiveEndPaidDate: formatDate(params.inclusiveEndPaidDate),
    startInvoiceDueDate: formatDate(params.startInvoiceDueDate),
    startInvoiceLedgerDate: formatDate(params.startDate),
    startPaidDate: formatDate(params.startPaidDate),
  };
}

function getOwedByIds(
  invoiceType: InvoiceInvoiceType,
  partyFilter?: PartyFilterValue,
) {
  if (
    invoiceType === InvoiceInvoiceTypeEnum.PAYABLE ||
    P.isNullable(partyFilter) ||
    partyFilter?.party === PartyEnum.EXTERNAL
  ) {
    return [];
  }

  return [partyFilter?.id];
}

function getOwedToIds(
  invoiceType: InvoiceInvoiceType,
  partyFilter?: PartyFilterValue,
) {
  if (
    invoiceType !== InvoiceInvoiceTypeEnum.PAYABLE ||
    P.isNullable(partyFilter) ||
    partyFilter?.party === PartyEnum.EXTERNAL
  ) {
    return [];
  }
  return [partyFilter?.id];
}

function createPartyFilters(
  invoiceType: InvoiceInvoiceType,
  party?: PartyFilterValue,
) {
  const isPayable = invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;
  return {
    owedByIds: getOwedByIds(invoiceType, party),
    owedByParty: isPayable ? UNDEFINED : party?.party,
    owedToIds: getOwedToIds(invoiceType, party),
    owedToParty: isPayable ? party?.party : UNDEFINED,
  };
}

const SortColumnToSortableFieldMap = {
  amount: MultiDBQueriesGetInvoicesSortableFieldsEnum.AMOUNT,
  counterPartyName: MultiDBQueriesGetInvoicesSortableFieldsEnum.PAYEE,
  date: MultiDBQueriesGetInvoicesSortableFieldsEnum.INVOICE_DATE,
  externalInvoiceId: MultiDBQueriesGetInvoicesSortableFieldsEnum.INVOICE_NUMBER,
  firmName: MultiDBQueriesGetInvoicesSortableFieldsEnum.FIRM,
  paidDate: MultiDBQueriesGetInvoicesSortableFieldsEnum.PAID_DATE,
  paymentMethodDisplay: MultiDBQueriesGetInvoicesSortableFieldsEnum.PAYMENT,
  propertyDisplay: MultiDBQueriesGetInvoicesSortableFieldsEnum.PROPERTY,
} as const;

function getSortByColumn(
  sortColumn: keyof typeof SortColumnToSortableFieldMap | Undefined,
): MultiDBQueriesGetInvoicesSortableFields | Undefined {
  if (P.isUndefined(sortColumn)) {
    return UNDEFINED;
  }

  return SortColumnToSortableFieldMap[sortColumn];
}

function createSortParams(sortState?: SortingState[0]) {
  if (P.isNullable(sortState)) {
    return {};
  }
  return {
    descending: sortState.desc,
    sortBy: getSortByColumn(
      sortState.id as keyof typeof SortColumnToSortableFieldMap,
    ),
  };
}

const ValidTabsForBestPaymentMethodType = [
  InvoiceTableTabsEnum.ALL,
  InvoiceTableTabsEnum.APPROVED_NOT_PAID,
  InvoiceTableTabsEnum.ASSIGNED_TO_ME,
  InvoiceTableTabsEnum.UNAPPROVED,
  InvoiceTableTabsEnum.UNPAID,
] as InvoiceTableTabs[];

function getBestPaymentMethodType(
  tab: InvoiceTableTabs,
  paymentTypeFilter?: InvoicePaymentMethod,
) {
  return ValidTabsForBestPaymentMethodType.includes(tab) && paymentTypeFilter
    ? paymentTypeFilter
    : UNDEFINED;
}

function getCommonInvoicesPayload({
  accountingPeriodsFilter,
  amountMatch,
  endDate,
  inclusiveEndInvoiceDueDate,
  externalInvoiceId,
  firmFilter,
  fundFilter,
  inclusiveEndPaidDate,
  invoiceType,
  limit,
  marketIds,
  party,
  payableTypes,
  paymentTypeFilter,
  properties,
  startDate,
  startInvoiceDueDate,
  startPaidDate,
  tab,
  tableSorting,
}: CreateCommonInvoicesPayloadParams): InvoicesAPIGetInvoicesPayload &
  InvoicesAPIGetInvoicesSummaryPayload &
  InvoicesMiddleLayerAPIGetPayablesReceivablesPayload {
  return {
    ...(limit && { limit: limit }),
    ...createDateFilters({
      endDate,
      inclusiveEndInvoiceDueDate,
      inclusiveEndPaidDate,
      startDate,
      startInvoiceDueDate,
      startPaidDate,
    }),
    ...createPartyFilters(invoiceType, party),
    ...createSortParams(tableSorting?.[0]),
    amountMatch: getAmountMatchForPayload({ amountMatch }),
    approvalStatus: getApprovalStatusForPayload({ tab }),
    assignedToMe: tab === InvoiceTableTabsEnum.ASSIGNED_TO_ME,
    bestPaymentMethodType: getBestPaymentMethodType(tab, paymentTypeFilter),
    ...(A.isNonEmptyArray(accountingPeriodsFilter) && {
      earliestPeriodDateFilter: {
        customFilter: accountingPeriodsFilter.map((val) => val.toJSON()),
        type: PeriodFilterFilterTypeEnum.CUSTOM,
      },
    }),
    externalInvoiceId:
      P.isNotNullable(externalInvoiceId) && S.isNonEmpty(externalInvoiceId)
        ? externalInvoiceId
        : UNDEFINED,
    firmIds: firmFilter,
    fundIds: fundFilter,
    invoiceType: invoiceType,
    marketIds:
      P.isNotNullable(marketIds) && A.isNonEmptyArray(marketIds)
        ? marketIds
        : [],
    payableTypes: O.match<InvoicePayableType[], InvoicePayableType[]>({
      onNone: () => [],
      onSome: (value) => value,
    })(payableTypes),
    paymentMethodType: getGuardedPaymentMethodType(tab, paymentTypeFilter),
    propertyIds: A.isEmptyArray(properties)
      ? []
      : properties.map((property) => property.value),
  };
}

export { getCommonInvoicesPayload, ValidTabsForBestPaymentMethodType };
export type { CreateCommonInvoicesPayloadParams };
