import { Option as O, Predicate as P } from "effect";
import { isNull } from "effect/Predicate";
import type { StateCreator } from "zustand";
import { create } from "zustand";
import type { PersistOptions } from "zustand/middleware";
import { persist } from "zustand/middleware";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import type { MinimalPropertyResponse } from "@ender/shared/generated/ender.api.model";
import type { RecurringGLJournalEntryFrequency } from "@ender/shared/generated/ender.model.accounting";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";

const DEFAULT_ACCOUNTING_END_DATE = LocalDate$.today().toJSON();
const DEFAULT_ACCOUNTING_START_DATE = LocalDate$.today()
  .add({ months: -1 })
  .toJSON();

type AccountingFiltersStorePersistedData = {
  endDate: LocalDate;
  startDate: LocalDate;
  dateValidationState: {
    isValidMonthRange: boolean;
    isValidDateRange: boolean;
  };
  systemDateValidationState: {
    isValidMonthRange: boolean;
    isValidDateRange: boolean;
  };
  systemEndDate: LocalDate;
  systemStartDate: LocalDate;
  endDateRangeValidationState: {
    isValidMonthRange: boolean;
    isValidDateRange: boolean;
  };
  startDateRangeValidationState: {
    isValidMonthRange: boolean;
    isValidDateRange: boolean;
  };
  endDateRangeEndDate: LocalDate;
  endDateRangeStartDate: LocalDate;
  startDateRangeEndDate: LocalDate;
  startDateRangeStartDate: LocalDate;
};

type AccountingFiltersStoreNonPersistedData = {
  accountingProperties: MinimalPropertyResponse[];
  accountingPropertyFilter: MinimalPropertyResponse[];
  accountingPropertyFilterByFactor: MinimalPropertyResponse[];
  amount: number | Null;
  authors: SelectOption<EnderId, string>[];
  firmFilter: EnderId[];
  frequencies: SelectOption<RecurringGLJournalEntryFrequency, string>[];
  fundFilter: EnderId[];
  paymentType: MoneyTransferTransferType | Null;
  periodFilter: LocalDate[];
  recurringJournalEntryId: O.Option<EnderId>;
  selectedPayees: string[];
};

type AccountingFiltersStoreData = AccountingFiltersStorePersistedData &
  AccountingFiltersStoreNonPersistedData;

type AccountingFiltersStoreMethods = {
  setEndDate: (date: LocalDate) => void;
  setStartDate: (startDate: LocalDate) => void;
  setSystemEndDate: (systemEndDate: LocalDate) => void;
  setSystemStartDate: (systemStartDate: LocalDate) => void;
  setEndDateRangeEndDate: (endDateRangeEndDate: LocalDate) => void;
  setEndDateRangeStartDate: (endDateRangeStartDate: LocalDate) => void;
  setStartDateRangeEndDate: (startDateRangeEndDate: LocalDate) => void;
  setStartDateRangeStartDate: (startDateRangeStartDate: LocalDate) => void;
  validateDateRange: () => void;
  validateEndDateRange: () => void;
  validateStartDateRange: () => void;
  validateSystemDateRange: () => void;
  setAccountingProperties: (properties: MinimalPropertyResponse[]) => void;
  setAccountingPropertyFilter: (filter: MinimalPropertyResponse[]) => void;
  setAccountingPropertyFilterByFactor: (
    filter: MinimalPropertyResponse[],
  ) => void;
  setAmount: (amount: Money$.Money | Null) => void;
  setAuthors: (authorIds: SelectOption<EnderId, string>[]) => void;
  setFirmFilter: (firm: EnderId[]) => void;
  setFrequencies: (
    frequency: SelectOption<RecurringGLJournalEntryFrequency, string>[],
  ) => void;
  setFundFilter: (fund: EnderId[]) => void;
  setPaymentType: (paymentType: MoneyTransferTransferType | Null) => void;
  setPeriodFilter: (periods: LocalDate[]) => void;
  setRecurringJournalEntryId: (
    recurringJournalEntryId: O.Option<EnderId>,
  ) => void;
  setSelectedPayees: (payees: string[]) => void;
};

type AccountingFiltersStore = AccountingFiltersStoreData &
  AccountingFiltersStoreMethods;

type AccountingStorePersist = (
  config: StateCreator<AccountingFiltersStore>,
  options: PersistOptions<AccountingFiltersStorePersistedData>,
) => StateCreator<AccountingFiltersStore>;

const useAccountingFiltersStoreBase = create<AccountingFiltersStore>(
  (persist as unknown as AccountingStorePersist)(
    (set, get) => ({
      accountingProperties: [],
      accountingPropertyFilter: [],
      accountingPropertyFilterByFactor: [],
      amount: NULL,
      authors: [],
      dateValidationState: {
        isValidDateRange: true,
        isValidMonthRange: true,
      },
      endDate: DEFAULT_ACCOUNTING_END_DATE,
      endDateRangeEndDate: DEFAULT_ACCOUNTING_END_DATE,
      endDateRangeStartDate: DEFAULT_ACCOUNTING_START_DATE,
      endDateRangeValidationState: {
        isValidDateRange: true,
        isValidMonthRange: true,
      },
      firmFilter: [],
      frequencies: [],
      fundFilter: [],
      paymentType: NULL,
      periodFilter: [],
      recurringJournalEntryId: O.none(),
      selectedPayees: [],
      setAccountingProperties: (properties) =>
        set({ accountingProperties: properties }),
      setAccountingPropertyFilter: (filter) =>
        set({ accountingPropertyFilter: filter }),
      setAccountingPropertyFilterByFactor: (filter) =>
        set({ accountingPropertyFilterByFactor: filter }),
      setAmount: (amount) => {
        const _amount = !isNull(amount) ? amount.valueInCents : NULL;
        set({ amount: _amount });
      },
      setAuthors: (authors) => set({ authors }),
      setFirmFilter: (firm) => set({ firmFilter: firm }),
      setFrequencies: (frequencies) => set({ frequencies }),
      setFundFilter: (fund) => set({ fundFilter: fund }),
      setPaymentType: (paymentType) => set({ paymentType }),
      setPeriodFilter: (periods) => set({ periodFilter: periods }),
      setRecurringJournalEntryId: (recurringJournalEntryId) =>
        set({ recurringJournalEntryId }),
      setSelectedPayees: (payees) => set({ selectedPayees: payees }),
      setEndDate: (date: LocalDate) => {
        set({ endDate: date });
        get().validateDateRange();
      },
      setEndDateRangeEndDate: (endDateRangeEndDate: LocalDate) => {
        set({ endDateRangeEndDate });
        get().validateEndDateRange();
      },
      setEndDateRangeStartDate: (endDateRangeStartDate: LocalDate) => {
        set({ endDateRangeStartDate });
        get().validateEndDateRange();
      },
      setStartDate: (startDate: LocalDate) => {
        set({ startDate });
        get().validateDateRange();
      },
      setStartDateRangeEndDate: (startDateRangeEndDate: LocalDate) => {
        set({ startDateRangeEndDate });
        get().validateStartDateRange();
      },
      setStartDateRangeStartDate: (startDateRangeStartDate: LocalDate) => {
        set({ startDateRangeStartDate });
        get().validateStartDateRange();
      },
      setSystemEndDate: (systemEndDate: LocalDate) => {
        set({ systemEndDate });
        get().validateSystemDateRange();
      },
      setSystemStartDate: (systemStartDate: LocalDate) => {
        set({ systemStartDate });
        get().validateSystemDateRange();
      },
      startDate: DEFAULT_ACCOUNTING_START_DATE,
      startDateRangeEndDate: DEFAULT_ACCOUNTING_END_DATE,
      startDateRangeStartDate: DEFAULT_ACCOUNTING_START_DATE,
      startDateRangeValidationState: {
        isValidDateRange: true,
        isValidMonthRange: true,
      },
      systemDateValidationState: {
        isValidDateRange: true,
        isValidMonthRange: true,
      },
      systemEndDate: DEFAULT_ACCOUNTING_END_DATE,
      systemStartDate: DEFAULT_ACCOUNTING_START_DATE,
      validateDateRange: () => {
        const { startDate, endDate } = get();
        set({
          dateValidationState: {
            isValidDateRange:
              isNull(startDate) ||
              LocalDate$.of(startDate).isBeforeOrEqual(LocalDate$.of(endDate)),
            isValidMonthRange: true, // Example placeholder; implement actual month range validation as needed
          },
        });
      },
      validateEndDateRange: () => {
        const { endDateRangeStartDate, endDateRangeEndDate } = get();
        set({
          endDateRangeValidationState: {
            isValidDateRange:
              isNull(endDateRangeStartDate) ||
              LocalDate$.of(endDateRangeStartDate).isBeforeOrEqual(
                LocalDate$.of(endDateRangeEndDate),
              ),
            isValidMonthRange: true, // Example placeholder; implement actual month range validation as needed
          },
        });
      },
      validateStartDateRange: () => {
        const { startDateRangeStartDate, startDateRangeEndDate } = get();
        set({
          startDateRangeValidationState: {
            isValidDateRange:
              isNull(startDateRangeStartDate) ||
              LocalDate$.of(startDateRangeStartDate).isBeforeOrEqual(
                LocalDate$.of(startDateRangeEndDate),
              ),
            isValidMonthRange: true, // Example placeholder; implement actual month range validation as needed
          },
        });
      },
      validateSystemDateRange: () => {
        const { systemStartDate, systemEndDate } = get();
        set({
          systemDateValidationState: {
            isValidDateRange:
              isNull(systemStartDate) ||
              LocalDate$.of(systemStartDate).isBeforeOrEqual(
                LocalDate$.of(systemEndDate),
              ),
            isValidMonthRange: true, // Example placeholder; implement actual month range validation as needed
          },
        });
      },
    }),

    {
      name: "accountingFilters",
      version: 1,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          // Migrate date values and firmFilter & fundFilter from EnderId | Null to EnderId[]
          return {
            ...persistedState,

            endDate: !P.isTruthy(persistedState.endDate)
              ? DEFAULT_ACCOUNTING_END_DATE
              : persistedState.endDate,
            firmFilter: persistedState.firmFilter
              ? [persistedState.firmFilter]
              : [],
            fundFilter: persistedState.fundFilter
              ? [persistedState.fundFilter]
              : [],
            startDate: !P.isTruthy(persistedState.startDate)
              ? DEFAULT_ACCOUNTING_START_DATE
              : persistedState.startDate,
            systemEndDate: !P.isTruthy(persistedState.systemEndDate)
              ? DEFAULT_ACCOUNTING_END_DATE
              : persistedState.systemEndDate,
            systemStartDate: !P.isTruthy(persistedState.systemStartDate)
              ? DEFAULT_ACCOUNTING_START_DATE
              : persistedState.systemStartDate,
          };
        }
        return persistedState;
      },
      partialize: (state) => ({
        dateValidationState: state.dateValidationState,
        endDate: state.endDate,
        endDateRangeEndDate: state.endDateRangeEndDate,
        endDateRangeStartDate: state.endDateRangeStartDate,
        endDateRangeValidationState: state.endDateRangeValidationState,
        startDate: state.startDate,
        startDateRangeEndDate: state.startDateRangeEndDate,
        startDateRangeStartDate: state.startDateRangeStartDate,
        startDateRangeValidationState: state.startDateRangeValidationState,
        systemDateValidationState: state.systemDateValidationState,
        systemEndDate: state.systemEndDate,
        systemStartDate: state.systemStartDate,
      }),
    },
  ),
);

function useAccountingFiltersStore() {
  const {
    accountingProperties,
    accountingPropertyFilter,
    accountingPropertyFilterByFactor,
    amount,
    authors,
    dateValidationState,
    endDate,
    endDateRangeEndDate,
    endDateRangeStartDate,
    endDateRangeValidationState,
    firmFilter,
    frequencies,
    fundFilter,
    paymentType,
    periodFilter,
    recurringJournalEntryId,
    selectedPayees,
    setAccountingProperties,
    setAccountingPropertyFilter,
    setAccountingPropertyFilterByFactor,
    setAmount,
    setAuthors,
    setEndDate,
    setEndDateRangeEndDate,
    setEndDateRangeStartDate,
    setFirmFilter,
    setFrequencies,
    setFundFilter,
    setPaymentType,
    setPeriodFilter,
    setRecurringJournalEntryId,
    setSelectedPayees,
    setStartDate,
    setStartDateRangeEndDate,
    setStartDateRangeStartDate,
    setSystemEndDate,
    setSystemStartDate,
    startDate,
    startDateRangeEndDate,
    startDateRangeStartDate,
    startDateRangeValidationState,
    systemDateValidationState,
    systemEndDate,
    systemStartDate,
  } = useAccountingFiltersStoreBase();

  return {
    accountingProperties,
    accountingPropertyFilter,
    accountingPropertyFilterByFactor,
    amount: !isNull(amount) ? Money$.makeFromCents(amount) : NULL,
    authors,
    dateValidationState,
    endDate,
    endDateRangeEndDate,
    endDateRangeStartDate,
    endDateRangeValidationState,
    firmFilter,
    frequencies,
    fundFilter,
    paymentType,
    periodFilter,
    recurringJournalEntryId,
    selectedPayees,
    startDate,
    startDateRangeEndDate,
    startDateRangeStartDate,
    startDateRangeValidationState,
    systemDateValidationState,
    systemEndDate,
    systemStartDate,
    updateAccountingProperties: setAccountingProperties,
    updateAccountingPropertyFilter: setAccountingPropertyFilter,
    updateAccountingPropertyFilterByFactor: setAccountingPropertyFilterByFactor,
    updateAmount: setAmount,
    updateAuthors: setAuthors,
    updateEndDate: setEndDate,
    updateEndDateRangeEndDate: setEndDateRangeEndDate,
    updateEndDateRangeStartDate: setEndDateRangeStartDate,
    updateFirmFilter: setFirmFilter,
    updateFrequencies: setFrequencies,
    updateFundFilter: setFundFilter,
    updatePaymentType: setPaymentType,
    updatePeriodFilter: setPeriodFilter,
    updateRecurringJournalEntryId: setRecurringJournalEntryId,
    updateSelectedPayees: setSelectedPayees,
    updateStartDate: setStartDate,
    updateStartDateRangeEndDate: setStartDateRangeEndDate,
    updateStartDateRangeStartDate: setStartDateRangeStartDate,
    updateSystemEndDate: setSystemEndDate,
    updateSystemStartDate: setSystemStartDate,
  };
}

export {
  DEFAULT_ACCOUNTING_END_DATE,
  DEFAULT_ACCOUNTING_START_DATE,
  useAccountingFiltersStore,
  useAccountingFiltersStoreBase,
};
