import { useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { ChatChannelParams } from "@ender/widgets/chat";
import { Chat } from "@ender/widgets/chat";

type ApplicationPrivateNotesProps = {
  applicationGroupId?: EnderId;
  leaseId?: EnderId;
};

function ApplicationPrivateNotes({
  applicationGroupId,
  leaseId,
}: ApplicationPrivateNotesProps) {
  const channels = useMemo<ChatChannelParams[]>(() => {
    const chatChannels: ChatChannelParams[] = [];

    if (applicationGroupId) {
      chatChannels.push({
        modelType: ModelTypeEnum.APPLICATION_GROUP,
        modelId: applicationGroupId,
        isInternal: true,
      });
    }

    if (leaseId) {
      chatChannels.push({
        modelType: ModelTypeEnum.LEASE,
        modelId: leaseId,
        isInternal: true,
      });
    }

    return chatChannels;
  }, [applicationGroupId, leaseId]);

  return <Chat title="Private Notes" channels={channels} />;
}

export { ApplicationPrivateNotes };
