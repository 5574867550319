import type { InputWrapperBaseProps } from "@mantine/core";

/** @description Used to sort the input wrapper components */
const ENDER_INPUT_WRAPPER_ORDER: InputWrapperBaseProps["inputWrapperOrder"] = [
  "label",
  "description",
  "input",
  "error",
];

export { ENDER_INPUT_WRAPPER_ORDER };
