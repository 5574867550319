/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/moveOutDate
 * @function editLeaseExpectedMoveOutDate
 * @memberof LeasingAPI
 * @param {LeasingAPIEditLeaseExpectedMoveOutDatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { LeaseMoveOutReason } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIEditLeaseExpectedMoveOutDatePathParams = {
  leaseId: EnderId;
};

type LeasingAPIEditLeaseExpectedMoveOutDateSearchParams = {
  token?: string | undefined;
};

type LeasingAPIEditLeaseExpectedMoveOutDateBodyParams = {
  expectedMoveOutDate: SerializesInto<LocalDate>;
  moveOutReason?: LeaseMoveOutReason | undefined;
  noticeOfMoveOutDate: SerializesInto<LocalDate>;
};

type LeasingAPIEditLeaseExpectedMoveOutDatePayload =
  LeasingAPIEditLeaseExpectedMoveOutDatePathParams &
    LeasingAPIEditLeaseExpectedMoveOutDateSearchParams &
    LeasingAPIEditLeaseExpectedMoveOutDateBodyParams;

function editLeaseExpectedMoveOutDateUnsafeEffect(
  payload: LeasingAPIEditLeaseExpectedMoveOutDatePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/moveOutDate`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function editLeaseExpectedMoveOutDate(
  payload: LeasingAPIEditLeaseExpectedMoveOutDatePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    editLeaseExpectedMoveOutDateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { editLeaseExpectedMoveOutDate };
export type {
  LeasingAPIEditLeaseExpectedMoveOutDateBodyParams,
  LeasingAPIEditLeaseExpectedMoveOutDatePathParams,
  LeasingAPIEditLeaseExpectedMoveOutDatePayload,
  LeasingAPIEditLeaseExpectedMoveOutDateSearchParams,
};
