/**
 * HTTP Method: POST
 * Pathname: /leases/search
 * @function searchLeases
 * @memberof LeasingAPI
 * @param {LeasingAPISearchLeasesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeaseSerializerLeaseResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISearchLeasesSearchParams = {
  token?: string | undefined;
};

type LeasingAPISearchLeasesBodyParams = {
  /**
   * inclusive date
   */
  expireEndDate?: SerializesInto<LocalDate> | undefined;
  /**
   * inclusive date
   */
  expireStartDate?: SerializesInto<LocalDate> | undefined;
  keyword: string;
  marketIds: EnderId[];
  /**
   * Only return the most relevant Lease for each Unit
   */
  mostRelevantLeasePerUnit?: boolean | undefined;
  propertyIds: EnderId[];
  showArchived?: boolean | undefined;
  showDraftingRenewals?: boolean | undefined;
  unitIds: EnderId[];
};

type LeasingAPISearchLeasesPayload = LeasingAPISearchLeasesSearchParams &
  LeasingAPISearchLeasesBodyParams;

function searchLeasesUnsafeEffect(payload: LeasingAPISearchLeasesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeaseSerializerLeaseResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<LeaseSerializerLeaseResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/leases/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchLeases(
  payload: LeasingAPISearchLeasesPayload,
  options?: { signal?: AbortSignal },
): Promise<LeaseSerializerLeaseResponse[]> {
  return F.pipe(
    payload,
    searchLeasesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchLeases };
export type {
  LeasingAPISearchLeasesBodyParams,
  LeasingAPISearchLeasesPayload,
  LeasingAPISearchLeasesSearchParams,
};
