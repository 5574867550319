import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P, pipe } from "effect";
import { useContext } from "react";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { UserContext } from "@ender/shared/contexts/user";
import { EnderIdFormSchema, LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2, H3 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tuple } from "@ender/shared/ds/tuple";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { getPropertyNameWithFriendlyId } from "@ender/shared/utils/property/get-property-name-with-friendly-id";
import { Color } from "@ender/shared/utils/theming";

const AccountingPeriodSchema = Schema.Struct({
  id: EnderIdFormSchema,
});

const VoidTenantRefundFormSchema = Schema.Struct({
  description: Schema.String,
  periodId: Schema.OptionFromSelf(AccountingPeriodSchema),
  reversalDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
});

type VoidTenantRefundFormValues = Schema.Schema.Type<
  typeof VoidTenantRefundFormSchema
>;

/**
 * Void Tenant Refund Form
 * - 2024-07-18 Geoffrey: There are 2 other legacy versions of this button/modal/form set,
 *   one in move-in and one in TL.  Neither are immediately suitable for reuse nor replacement.
 */
function VoidTenantRefundForm({
  invoice,
  closeModal,
}: {
  invoice: InvoiceSerializerInvoiceResponse;
  closeModal: () => void;
}) {
  const transactionDateOptional = LocalDate$.parse(invoice.transactionDate);
  const transactionDescription = `Reversal of ${invoice.description}`;
  const form = useForm<VoidTenantRefundFormValues>({
    defaultValues: {
      description: transactionDescription,
      periodId: O.none(),
      reversalDate: transactionDateOptional,
    },
    resolver: effectTsResolver(VoidTenantRefundFormSchema),
  });

  const { mutate: reverseRefund, isLoading } = useMutation({
    mutationFn: TenantLedgerAPI.reverseTenantRefund,
    onError: (error) => {
      fail(error);
    },
    onSuccess: () => {
      showSuccessNotification({
        message: "Tenant refund reversed successfully",
      });
      closeModal();
    },
  });

  function handleSubmit(values: VoidTenantRefundFormValues) {
    if (P.isNullable(invoice.lease?.id)) {
      throw new Error("Should not happen: invoice.lease.id is undefined");
    }
    if (P.isNullable(invoice.paymentInfo?.id)) {
      throw new Error("Should not happen: invoice.paymentInfo.id is undefined");
    }
    const reversalDate = O.getOrThrowWith(
      () => new Error("Should not happen: values.reversalDate is ungettable"),
    )(values.reversalDate).toJSON();
    const periodId = pipe(
      values.periodId,
      O.map((period) => period.id),
      O.getOrUndefined,
    );
    const reverseTenantRefundPayload = {
      description: transactionDescription,
      leaseId: invoice.lease.id,
      periodId,
      refundMoneyTransferId: invoice.paymentInfo.id,
      reversalDate,
    };
    reverseRefund(reverseTenantRefundPayload);
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <H2>Void Payment</H2>
        {/* TODO opportunity: dry this x3 as VoidTenantRefundFormText */}
        <Text>
          Voiding this payment will process a reversal, creating offsetting
          entries for this refund. The reversing entry will appear on both the
          General Ledger and the Tenant Ledger.
        </Text>
        <Text>
          This action will revert the associated payable to the last step of the
          AP approval chain. It will also remove the Ender Txn row from bank
          reconciliation.
        </Text>
        <H3>Original Transaction Details</H3>
        <Stack spacing={Spacing.none}>
          <Tuple
            label="Property"
            value={
              P.isNotNullable(invoice.property)
                ? getPropertyNameWithFriendlyId(invoice.property)
                : ""
            }
          />
          <Tuple label="Description" value={invoice.paymentInfo?.description} />
          <Tuple
            label="Transaction Date"
            value={pipe(
              transactionDateOptional,
              O.map((date) =>
                LocalDate$.toFormatted(date, LocalDate$.Formats.DEFAULT),
              ),
              O.getOrElse(() => ""),
            )}
          />
          <Tuple label="Accounting Period" value={invoice.accountingPeriod} />
        </Stack>
        <H3>Reversal Details</H3>
        <FormTextInput name="description" label="Description" form={form} />
        <FormDateInput name="reversalDate" label="Reversal Date" form={form} />
        <FormAccountingPeriodSelector
          form={form}
          name="periodId"
          label="Accounting Period"
          placeholder="Select period"
          periodType={AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE}
        />
        <Group align={Align.end} justify={Justify.end}>
          <Button type="submit" disabled={isLoading}>
            Void Payment
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

function VoidTenantRefundButton({
  invoice,
}: {
  invoice: InvoiceSerializerInvoiceResponse;
}) {
  const { hasPermissions } = useContext(UserContext);
  const hasVoidPaymentPermission = hasPermissions(
    FunctionalPermissionEnum.VOID_PAYMENT,
  );
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean();

  if (!hasVoidPaymentPermission) {
    return;
  }

  return (
    <>
      <Button color={Color.red} onClick={openModal}>
        Void Payment
      </Button>
      <Modal title="" opened={isModalOpen} onClose={closeModal}>
        <VoidTenantRefundForm invoice={invoice} closeModal={closeModal} />
      </Modal>
    </>
  );
}

export { VoidTenantRefundButton, VoidTenantRefundForm };
