/**
 * HTTP Method: POST
 * Pathname: /getJournalEntriesReport
 * @function getJournalEntriesReport
 * @memberof ReportsAPI
 * @param {ReportsAPIGetJournalEntriesReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetJournalEntriesReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type {
  GetJournalEntriesReportResponse,
  ReportFilterRequest,
} from "@ender/shared/generated/ender.api.reports.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIGetJournalEntriesReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIGetJournalEntriesReportBodyParams = {
  authorFilter: EnderId[];
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  journalEntryId?: EnderId | undefined;
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  periodFilter?: PeriodFilter | undefined;
  propertyIds: EnderId[];
  startDate?: SerializesInto<LocalDate> | undefined;
};

type ReportsAPIGetJournalEntriesReportPayload =
  ReportsAPIGetJournalEntriesReportSearchParams &
    ReportsAPIGetJournalEntriesReportBodyParams;

function getJournalEntriesReportUnsafeEffect(
  payload: ReportsAPIGetJournalEntriesReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetJournalEntriesReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetJournalEntriesReportResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getJournalEntriesReport",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getJournalEntriesReport(
  payload: ReportsAPIGetJournalEntriesReportPayload,
  options?: { signal?: AbortSignal },
): Promise<GetJournalEntriesReportResponse> {
  return F.pipe(
    payload,
    getJournalEntriesReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getJournalEntriesReport };
export type {
  ReportsAPIGetJournalEntriesReportBodyParams,
  ReportsAPIGetJournalEntriesReportPayload,
  ReportsAPIGetJournalEntriesReportSearchParams,
};
