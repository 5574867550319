import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const VendorTagSystemTagValues = [
  "VENDOR",
  "INVESTOR",
  "TITLE_COMPANY",
  "HOME_INSPECTOR",
  "UTILITY",
  "CORPORATE",
  "MUNICIPALITY",
  "AFTER_HOURS",
  "MAINTENANCE",
  "RECURRING",
  "REHAB",
  "TURNOVER",
  "CLEANING",
  "ELECTRICAL",
  "FLOORING",
  "FOUNDATION",
  "GENERAL_CONTRACTOR",
  "HVAC",
  "LANDSCAPE",
  "PEST_CONTROL",
  "PLUMBING",
  "POOL",
  "ROOFING",
] as const;

const VendorTagSystemTagEffectSchema = Schema.Literal(
  ...VendorTagSystemTagValues,
);

type VendorTagSystemTag = Schema.Schema.Type<
  typeof VendorTagSystemTagEffectSchema
>;

const VendorTagSystemTagEnum = castEnum(VendorTagSystemTagEffectSchema);

function randomVendorTagSystemTag(): VendorTagSystemTag {
  return rand(VendorTagSystemTagValues);
}

export {
  randomVendorTagSystemTag,
  VendorTagSystemTagEffectSchema,
  VendorTagSystemTagEnum,
  VendorTagSystemTagValues,
};
export type { VendorTagSystemTag };
