import * as A from "effect/Array";

import { NULL } from "@ender/shared/constants/general";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";

import { AddBillBackButton } from "./add-billback-button";
import { TenantBillbacksTable } from "./tenant-billbacks-table/tenant-billbacks-table";

type TenantBillbacksProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess: () => void;
};

function TenantBillbacks({ invoice, onSuccess }: TenantBillbacksProps) {
  const hasBillbacks = A.isNonEmptyArray(invoice?.billbacks);
  const tenantBillbackTitle = hasBillbacks
    ? "Tenant Billbacks"
    : "No Tenant Billbacks";
  const isEditable = !invoice?.currentState.isFullyRejected;

  if (!isEditable && !hasBillbacks) {
    return NULL;
  }

  return (
    <Stack>
      <Group justify={Justify.between}>
        <H3>{tenantBillbackTitle}</H3>
        {isEditable && (
          <AddBillBackButton invoice={invoice} onSuccess={onSuccess} />
        )}
      </Group>
      <TenantBillbacksTable billbacks={invoice.billbacks} />
    </Stack>
  );
}

export { TenantBillbacks };
