import { useQuery } from "@tanstack/react-query";
import { clsx } from "clsx";
import { Array as A, Function as F, Predicate as P, String as S } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { H1 } from "@ender/shared/ds/heading";
import { Tuple } from "@ender/shared/ds/tuple";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { ApplicantApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { Text } from "@ender/shared/ds/text";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { fail } from "@ender/shared/utils/error";

import type { ApplyStep } from "../apply-utils";
import { findCompletedSteps } from "../apply-utils";
import type { ApplyStepProps } from "./step-props";

import commonStyles from "./common.module.css";
import styles from "./summary.module.css";

const summaryStepDescription =
  "Your application has been submitted for review. We have sent you an email with a link to return back to your application at any time. \n\nAll occupants 18+ have also received an email with a link to access the application. Each adult occupant will need to fill out their personal information for the application to be complete.";

const incompleteStatusStrings: Record<ApplyStep, string> = {
  CREDIT: "Missing credit or background information",
  FEE: "Has not paid application fee",
  INCOME: "Missing employment information",
  OCCUPANTS: "", //not a status we need to inform applicants about
  PERSONAL: "Not Started",
};

const getAppStatus = (
  application: ApplicantApplicationGroupResponseApplicationResponse,
  data: {
    applicationId: EnderId;
    paystubs: FilesClientEnderFile[];
    photoIds: FilesClientEnderFile[];
  }[],
  orderedSteps: ApplyStep[],
): { message: string; earliestIncomplete: number } => {
  const completedSteps = findCompletedSteps(application, orderedSteps);
  const earliestIncomplete = completedSteps.findIndex((value) => !value);

  const { paystubs, photoIds } = data?.find(
    ({ applicationId }) => application.applicationId === applicationId,
  ) ?? {
    paystubs: [],
    photoIds: [],
  };
  const needsPaystubs = A.isEmptyArray(paystubs ?? []);
  const needsPhotoIds = A.isEmptyArray(photoIds ?? []);

  if (
    P.isNullable(application.applicant.birthday) ||
    EnderDate.isBirthdayMinor(application.applicant.birthday)
  ) {
    return { message: "No action required", earliestIncomplete: -1 };
  }

  if (earliestIncomplete !== -1) {
    if (
      earliestIncomplete === 0 &&
      S.isNonEmpty(application.applicant.email ?? "")
    ) {
      return {
        message: `Application link emailed to ${application.applicant.email}`,
        earliestIncomplete,
      };
    }

    return {
      message: incompleteStatusStrings[orderedSteps[earliestIncomplete]],
      earliestIncomplete,
    };
  }

  if (needsPaystubs || needsPhotoIds) {
    return {
      message: "Missing paystubs or photo ID",
      earliestIncomplete: orderedSteps.indexOf("INCOME"),
    };
  }

  return { message: "Completed", earliestIncomplete };
};

type ApplyStepSummaryProps = ApplyStepProps & {
  applicationGroupId?: EnderId;
  onEdit?: (step: number) => void;
  orderedSteps: ApplyStep[];
};

function ApplyStepSummary({
  titleRef,
  applications,
  user,
  applicationGroupId,
  onEdit = F.constVoid,
  orderedSteps = [],
}: ApplyStepSummaryProps) {
  const [loadingNextApplication, loadingHandlers] = useBoolean(false);
  async function onStartApplicationClick(userId: EnderId) {
    loadingHandlers.setTrue();
    try {
      if (P.isNotNullable(applicationGroupId)) {
        await ApplicationsAPI.startNextApplication({
          applicationGroupId,
          userId,
        });

        // FORCE PAGE TO THE NEW LOGIN | removes querystring token if it was used to arrive here
        globalThis.location.assign(globalThis.location.pathname);
      }
    } catch (err) {
      fail(err);
    } finally {
      loadingHandlers.setFalse();
    }
  }

  const { data = [], isLoading } = useQuery({
    queryKey: [
      "WebserverFilesAPI.getFiles.batch",
      "paystubsAndPhotoIds",
    ] as const,
    queryFn: ({ signal }) => {
      return Promise.all(
        (applications ?? []).map((application) => {
          return Promise.all([
            WebserverFilesAPI.getFiles(
              {
                modelType: ModelTypeEnum.APPLICATION,
                modelId: application.applicationId,
                uploadType: WebserverFilesServiceFileUploadTypeEnum.PAYSTUB,
              },
              { signal },
            ).then((res) => res.files),
            WebserverFilesAPI.getFiles(
              {
                modelType: ModelTypeEnum.APPLICATION,
                modelId: application.applicationId,
                uploadType: WebserverFilesServiceFileUploadTypeEnum.PHOTO_ID,
              },
              { signal },
            ).then((res) => res.files),
          ])
            .then(([paystubs, photoIds]) => ({
              applicationId: application.applicationId,
              paystubs,
              photoIds,
            }))
            .catch(() => ({
              applicationId: application.applicationId,
              paystubs: [],
              photoIds: [],
            }));
        }),
      );
    },
    enabled: P.isNotNullable(applications),
  });

  return (
    <div className={commonStyles.stepContainer}>
      <div className={commonStyles.indented}>
        <div
          className={clsx(commonStyles.spanFullWidth, commonStyles.stepHeader)}
          ref={titleRef}>
          <H1>Thank You for Applying</H1>
        </div>
        <div className={commonStyles.stepDescription}>
          <Text>
            {summaryStepDescription}
          </Text>
        </div>
        <div
          className={clsx(commonStyles.spanFullWidth, styles.applicantTable)}>
          <div className={styles.applicantTableHeader}>
            <span>Applicant Name</span>
            <span>Email Address</span>
            <span>Application Status</span>
            <span />
          </div>
          {applications?.map((application) => {
            const { applicant } = application;
            const { message, earliestIncomplete } = getAppStatus(
              application,
              data,
              orderedSteps,
            );

            return (
              <div className={styles.occupant} key={applicant.userId}>
                <span className={styles.occupantName}>
                  {applicant.firstName} {applicant.lastName}
                </span>
                <Tuple label="Email" value={applicant.email} />
                <Tuple label="Status" value={!isLoading && message} />
                <span>
                  {applicant.userId === user?.userId && (
                    <Button
                      variant={ButtonVariant.outlined}
                      onClick={() =>
                        onEdit(
                          earliestIncomplete !== -1 ? earliestIncomplete : 0,
                        )
                      }>
                      Edit
                    </Button>
                  )}
                  {applicant.userId !== user?.userId &&
                    !applicant.email &&
                    applicant.birthday &&
                    !EnderDate.isBirthdayMinor(applicant.birthday) && (
                      <Button
                        loading={loadingNextApplication}
                        onClick={() =>
                          onStartApplicationClick(applicant.userId)
                        }>
                        {applicant.agreedToTerms ? "Continue" : "Start"}{" "}
                        Application
                      </Button>
                    )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export { ApplyStepSummary };
