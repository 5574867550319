import { IconExternalLink } from "@tabler/icons-react";
import { Option as O, Predicate as P } from "effect";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card, CardPadding } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import type { ListingDetailsWidgetResponse } from "@ender/shared/generated/com.ender.middle.response";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

function ListingInfoWidgetView(
  props: ListingDetailsWidgetResponse & { borderless?: boolean },
) {
  const {
    baths,
    beds,
    investor,
    listed,
    listingId,
    market,
    propertyName,
    rent,
    sqft,
    submittedApplicationCount,
    unitName,
    unitStatus,
    borderless = true,
  } = props;

  return (
    <Card
      borderless={borderless}
      padding={borderless ? CardPadding.none : CardPadding.md}>
      <Stack spacing={Spacing.sm}>
        <Group justify={Justify.between}>
          <Text>Listing Info</Text>
          <RouterLink
            href={`/listings/${listingId}`}
            target="_blank"
            disabled={P.isNullable(listingId)}>
            <Button
              disabled={P.isNullable(listingId)}
              leftSection={<IconExternalLink />}
              variant={ButtonVariant.transparent}>
              Go to Listing
            </Button>
          </RouterLink>
        </Group>
        <Grid underline="uneven" columnSize="lg" spacingY="none">
          <Tuple label="Property" value={propertyName} />
          {P.isNotUndefined(unitName) && (
            <Tuple label="Unit" value={unitName} />
          )}
          <Tuple label="Market" value={market} />
          <Tuple label="Investor" value={investor} />
          <Tuple label="Beds" value={beds ?? "--"} />
          <Tuple label="Baths" value={baths || "--"} />
          <Tuple label="Sqft" value={sqft ?? "--"} />
          <Tuple
            label="Listed"
            value={O.fromNullable(listed).pipe(
              O.map((listed) => (listed ? "Yes" : "No")),
              O.getOrUndefined,
            )}
          />
          <Tuple
            label="Unit Status"
            value={O.fromNullable(unitStatus).pipe(
              O.map(convertSnakeCaseToTitleCase),
              O.getOrElse(() => "--"),
            )}
          />
          <Tuple label="Rent Amount" value={rent ?? "--"} />
          <Tuple
            label="# of Submitted Applications"
            value={submittedApplicationCount ?? "--"}
          />
        </Grid>
      </Stack>
    </Card>
  );
}

export { ListingInfoWidgetView };
