import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O, pipe } from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback } from "react";

import type { EnderId } from "@ender/shared/core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";

import type { ApplicantRentalHistoryFormOutput } from "./edit-applicant-rental-history-fields";
import type { ApplicationRentalHistoryCardProps } from "./widgets-application-rental-history-card";
import { ApplicationRentalHistoryCard } from "./widgets-application-rental-history-card";

type ConnectedApplicationRentalHistoryCardProps = Pick<
  ApplicationRentalHistoryCardProps,
  "application" | "isEditable" | "header"
> & {
  onSuccess?: () => void;
  applicationGroupId: EnderId;
  applicantUserId: EnderId;
};

const ConnectedApplicationRentalHistoryCard = forwardRef<
  ElementRef<typeof ApplicationRentalHistoryCard>,
  ConnectedApplicationRentalHistoryCardProps
>(function ConnectedApplicationRentalHistoryCard(props, ref) {
  const {
    application,
    isEditable,
    header,
    onSuccess = F.constVoid,
    applicationGroupId,
    applicantUserId,
  } = props;

  const { mutateAsync: updateApplication, isLoading: isSavingApplication } =
    useMutation({
      mutationFn: ApplicationsAPI.updateApplication,
      mutationKey: ["ApplicationsAPI.updateApplication"] as const,
    });

  const handleSubmit = useCallback(
    async ({
      landlordEmail,
      landlordName,
      landlordPhone,
      firstTimeRenter: _firstTimeRenter,
    }: ApplicantRentalHistoryFormOutput) => {
      const firstTimeRenter = pipe(_firstTimeRenter, O.getOrThrow);
      const payload = firstTimeRenter
        ? { firstTimeRenter }
        : { firstTimeRenter, landlordEmail, landlordName, landlordPhone };
      await updateApplication({
        applicationGroupId,
        targetUserId: applicantUserId,
        ...payload,
      });
      onSuccess();
    },
    [updateApplication, onSuccess, applicationGroupId, applicantUserId],
  );

  return (
    <ApplicationRentalHistoryCard
      onSubmit={handleSubmit}
      isWorking={isSavingApplication}
      application={application}
      isEditable={isEditable}
      header={header}
      ref={ref}
    />
  );
});

export { ConnectedApplicationRentalHistoryCard };

export type { ConnectedApplicationRentalHistoryCardProps };
