/**
 * Updates all lease roles for a given lease and user based on a provided list
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/setContactRoles
 * @function setLeaseContactRoles
 * @memberof LeasingAPI
 * @param {LeasingAPISetLeaseContactRolesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeaseUserRoleLeaseUserFlag } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISetLeaseContactRolesPathParams = {
  leaseId: EnderId;
};

type LeasingAPISetLeaseContactRolesSearchParams = {
  token?: string | undefined;
};

type LeasingAPISetLeaseContactRolesBodyParams = {
  roles: LeaseUserRoleLeaseUserFlag[];
  tenantId: EnderId;
};

type LeasingAPISetLeaseContactRolesPayload =
  LeasingAPISetLeaseContactRolesPathParams &
    LeasingAPISetLeaseContactRolesSearchParams &
    LeasingAPISetLeaseContactRolesBodyParams;

function setLeaseContactRolesUnsafeEffect(
  payload: LeasingAPISetLeaseContactRolesPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/setContactRoles`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setLeaseContactRoles(
  payload: LeasingAPISetLeaseContactRolesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setLeaseContactRolesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setLeaseContactRoles };
export type {
  LeasingAPISetLeaseContactRolesBodyParams,
  LeasingAPISetLeaseContactRolesPathParams,
  LeasingAPISetLeaseContactRolesPayload,
  LeasingAPISetLeaseContactRolesSearchParams,
};
