import type { QueryFunctionContext } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  HashMap as HM,
  Match,
  Number as Num,
  Option as O,
  String as Str,
} from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useMemo, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { TriggerButton } from "@ender/shared/ds/menu";
import type { SelectOption } from "@ender/shared/ds/select";
import { GeneralLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GLCategorySerializerGLCategoryTreeNode } from "@ender/shared/generated/ender.arch.accounting.txsearch";
import { MultiFilterSync } from "@ender/widgets/filters/multi-filter-sync";

type GetGLCategoriesQueryKey = readonly ["GeneralLedgerAPI.getCategoriesTree"];

function getGLCategoriesQueryFn(
  context: QueryFunctionContext<GetGLCategoriesQueryKey>,
): Promise<GLCategorySerializerGLCategoryTreeNode[]> {
  const { signal } = context;
  return GeneralLedgerAPI.getCategoriesTree({}, { signal });
}

function getGLCategoriesSelectFn(
  data: GLCategorySerializerGLCategoryTreeNode[],
): SelectOption<EnderId, GLCategorySerializerGLCategoryTreeNode>[] {
  // Function to recursively flatten the category tree
  function flatten(
    category: GLCategorySerializerGLCategoryTreeNode,
  ): GLCategorySerializerGLCategoryTreeNode[] {
    const { children = [] } = category;

    let flattenedCats: GLCategorySerializerGLCategoryTreeNode[] = [category];

    for (const childCat of children) {
      flattenedCats = flattenedCats.concat(flatten(childCat));
    }

    return flattenedCats;
  }

  // Flatten all categories
  const flattenedCategories = data
    .flatMap(({ children }) => children ?? [])
    .reduce((acc, category) => {
      const flattened = flatten(category);
      return acc.concat(flattened);
    }, [] as GLCategorySerializerGLCategoryTreeNode[]);

  // Map flattened categories to SelectOption format
  return flattenedCategories.map((category) => ({
    label: category.name,
    value: category.id,
    meta: category,
  }));
}

type GLCategoryFilterProps = {
  value: GLCategorySerializerGLCategoryTreeNode[];
  onChange: (value: GLCategorySerializerGLCategoryTreeNode[]) => void;
  disabled?: boolean;
};

const GLCategoryFilter = forwardRef<
  ElementRef<typeof MultiFilterSync>,
  GLCategoryFilterProps
>(function GLCategoryFilterComponent(props, ref) {
  const { value, onChange, disabled } = props;

  const { data: options = [], isLoading } = useQuery({
    queryFn: getGLCategoriesQueryFn,
    queryKey: ["GeneralLedgerAPI.getCategoriesTree"] as const,
    select: getGLCategoriesSelectFn,
  });

  const idToOption = useMemo(() => {
    return F.pipe(
      options,
      A.map(
        (
          option,
        ): readonly [
          EnderId,
          SelectOption<EnderId, GLCategorySerializerGLCategoryTreeNode>,
        ] => {
          return [option.value, option];
        },
      ),
      HM.fromIterable,
    );
  }, [options]);

  const [keyword] = useState<string>("");

  const filteredOptions = useMemo(() => {
    const searchText = Str.toUpperCase(keyword);
    return F.pipe(
      options,
      A.filter((opt) =>
        F.pipe(opt.label, Str.toUpperCase, Str.includes(searchText)),
      ),
    );
  }, [keyword, options]);

  const selected = useMemo(
    () =>
      F.pipe(
        value,
        A.flatMap((category) =>
          F.pipe(idToOption, HM.get(category.id), O.toArray),
        ),
      ),
    [value, idToOption],
  );

  const handleChange = useCallback(
    (
      selectedOptions: SelectOption<
        EnderId,
        GLCategorySerializerGLCategoryTreeNode
      >[],
    ) => {
      const selectedCategories: GLCategorySerializerGLCategoryTreeNode[] =
        selectedOptions
          .map((option) => option.meta)
          .filter(
            (meta): meta is GLCategorySerializerGLCategoryTreeNode => !!meta,
          );
      onChange(selectedCategories);
    },
    [onChange],
  );

  const triggerLabel = useMemo(() => {
    const headLabel = F.pipe(
      selected,
      A.head,
      O.map((opt) => `: ${opt.label}`),
      O.getOrElse(() => ""),
    );
    const countLabel = Match.value(A.length(selected)).pipe(
      Match.when(Num.greaterThan(1), (size) => ` +${size - 1}`),
      Match.orElse(() => ""),
    );
    return `GL Category${headLabel}${countLabel}`;
  }, [selected]);

  return (
    <MultiFilterSync
      label="GL Category Filter"
      trigger={
        <TriggerButton rounded={false} disabled={disabled}>
          {triggerLabel}
        </TriggerButton>
      }
      value={selected}
      onChange={handleChange}
      data={filteredOptions}
      disabled={disabled}
      isLoading={isLoading}
      ref={ref}
    />
  );
});

export { GLCategoryFilter };
export type { GLCategoryFilterProps };
