import { Option as O, flow, pipe } from "effect";

import type {
  OptimizedLedgerEvent,
  OptimizedLedgerEventAllocation,
} from "@ender/shared/contexts/ledger";
import { Money$ } from "@ender/shared/core";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { TenantLedgerEventTypeEnum } from "@ender/shared/types/ender-general";
import { EnderLink } from "@ender/shared/ui/ender-link";
import type { ColumnDefMeta } from "@ender/shared/ui/table-tanstack";
import { asColumnDefWithMeta } from "@ender/shared/ui/table-tanstack";
import { EnderDate } from "@ender/shared/utils/ender-date";

const chargeColumnTypes = new Set<string>([
  TenantLedgerEventTypeEnum.CHARGE,
  TenantLedgerEventTypeEnum.CREDIT,
]);
const negatedPaymentTypes = new Set<string>([
  TenantLedgerEventTypeEnum.REFUND,
  TenantLedgerEventTypeEnum.REVERSAL,
  TenantLedgerEventTypeEnum.CREDIT,
]);

type CombinedCategoryAllocationType = OptimizedLedgerEventAllocation &
  OptimizedLedgerEvent;
type CategoryHistoryTableMeta = {
  openAllocation: (ledgerEvent: OptimizedLedgerEvent) => void;
};
const columns: ColumnDefMeta<
  CombinedCategoryAllocationType,
  CategoryHistoryTableMeta
>[] = [
  asColumnDefWithMeta({
    cell: (props) =>
      EnderDate.of(props.row.original.ledgerEventDate).toLongSlashDateString(),
    header: "Date",
    minSize: 100,
  }),
  asColumnDefWithMeta({
    cell: (props) => (
      <EnderLink
        onClick={() =>
          props.table.options.meta.openAllocation(props.row.original)
        }>
        {props.row.original.description}
      </EnderLink>
    ),
    header: "Name",
  }),
  asColumnDefWithMeta({
    cell: (props) => {
      const isCharge = chargeColumnTypes.has(
        props.row.original.tenantLedgerEventType,
      );
      const amount = isCharge
        ? props.row.original.amount
        : pipe(
            props.row.original.amount,
            O.map(
              flow(
                Money$.abs,
                Money$.negateWhen(() =>
                  negatedPaymentTypes.has(
                    props.row.original.tenantLedgerEventType,
                  ),
                ),
              ),
            ),
          );
      return (
        <Group justify={Justify.end}>
          {isCharge &&
          O.exists(props.row.original.amount, Money$.isPositive) ? (
            <MoneyDisplay value={amount} />
          ) : (
            "-"
          )}
        </Group>
      );
    },
    header: "Charges",
  }),
  asColumnDefWithMeta({
    cell: (props) => {
      const isCharge = chargeColumnTypes.has(
        props.row.original.tenantLedgerEventType,
      );
      const amount = isCharge
        ? props.row.original.amount
        : pipe(
            props.row.original.amount,
            O.map(
              flow(
                Money$.abs,
                Money$.negateWhen(() =>
                  negatedPaymentTypes.has(
                    props.row.original.tenantLedgerEventType,
                  ),
                ),
              ),
            ),
          );
      return (
        <Group justify={Justify.end}>
          {isCharge &&
          O.exists(props.row.original.amount, Money$.isNegative) ? (
            <MoneyDisplay value={amount} />
          ) : (
            "-"
          )}
        </Group>
      );
    },
    header: "Credits",
  }),
  asColumnDefWithMeta({
    cell: (props) => {
      const isCharge = chargeColumnTypes.has(
        props.row.original.tenantLedgerEventType,
      );
      const amount = isCharge
        ? props.row.original.amount
        : pipe(
            props.row.original.amount,
            O.map(
              flow(
                Money$.abs,
                Money$.negateWhen(() =>
                  negatedPaymentTypes.has(
                    props.row.original.tenantLedgerEventType,
                  ),
                ),
              ),
            ),
          );
      return (
        <Group justify={Justify.end}>
          {!isCharge ? <MoneyDisplay value={amount} /> : "-"}
        </Group>
      );
    },
    header: "Payments",
  }),
  asColumnDefWithMeta({
    accessorKey: "ownerName",
    cell: (props) => {
      return (
        <Group justify={Justify.end}>
          <MoneyDisplay
            // @ts-expect-error
            value={Money$.parse(props.row.original.runningBalance)}
          />
        </Group>
      );
    },
    enableSorting: false,
    header: "Category Balance",
  }),
];

export { chargeColumnTypes, columns, negatedPaymentTypes };
export type { CombinedCategoryAllocationType };
