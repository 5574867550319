import { Predicate as P } from "effect";

import { Align, Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import { TabButton, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { FontSize, Text } from "@ender/shared/ds/text";
import type { SearchApplicationsPageRequestApplicationsPageTab } from "@ender/shared/generated/com.ender.middle.request";
import { SearchApplicationsPageRequestApplicationsPageTabEnum } from "@ender/shared/generated/com.ender.middle.request";
import type { ApplicationsTableStatusCountResponse } from "@ender/shared/generated/com.ender.middle.response";
import { cast } from "@ender/shared/types/cast";

import { ApplicationsTableReadableTabs } from "../all-applications-table.utils";

type TableTabButtonProps = {
  tab: SearchApplicationsPageRequestApplicationsPageTab;
  totalCount?: number;
  actionNeededCount: number;
  isLoading: boolean;
};

function TableTabButton({
  tab,
  totalCount,
  actionNeededCount,
  isLoading,
}: TableTabButtonProps) {
  if (isLoading) {
    return (
      <TabButton variant="outlined" value={tab}>
        <Stack align={Align.start} spacing={Spacing.xs}>
          <span>{ApplicationsTableReadableTabs[tab]}</span>
          <Text color="slate-500" size={FontSize.sm}>
            Loading count...
          </Text>
        </Stack>
      </TabButton>
    );
  }

  function isActionNeededTab(
    tab: SearchApplicationsPageRequestApplicationsPageTab,
  ) {
    return (
      tab !== SearchApplicationsPageRequestApplicationsPageTabEnum.DENIED &&
      tab !== SearchApplicationsPageRequestApplicationsPageTabEnum.ARCHIVED
    );
  }

  return (
    <TabButton variant="outlined" value={tab}>
      <Stack align={Align.start} spacing={Spacing.xs}>
        <span>
          {ApplicationsTableReadableTabs[tab]}{" "}
          {P.isNotNullable(totalCount) && <>({totalCount})</>}
        </span>
        {isActionNeededTab(tab) && (
          <Text
            color={actionNeededCount > 0 ? "red-400" : "slate-500"}
            size={FontSize.sm}>
            {`${actionNeededCount} Actions Needed`}
          </Text>
        )}
      </Stack>
    </TabButton>
  );
}

type AllApplicationsTableTabsProps = {
  activeTab: SearchApplicationsPageRequestApplicationsPageTab;
  setActiveTab: (tab: SearchApplicationsPageRequestApplicationsPageTab) => void;
  visibleTabs: SearchApplicationsPageRequestApplicationsPageTab[];
  tabCounts?: Partial<
    Record<
      SearchApplicationsPageRequestApplicationsPageTab,
      ApplicationsTableStatusCountResponse
    >
  >;
  isLoading: boolean;
};

function AllApplicationsTableTabs({
  activeTab,
  setActiveTab,
  visibleTabs,
  tabCounts,
  isLoading,
}: AllApplicationsTableTabsProps) {
  return (
    <Tabs
      value={activeTab}
      onChange={(value: string) => setActiveTab(cast(value))}>
      <TabsList noWrap>
        {visibleTabs.map((tab) => (
          <TableTabButton
            key={tab}
            tab={tab}
            totalCount={tabCounts?.[tab]?.total}
            actionNeededCount={tabCounts?.[tab]?.actionNeeded ?? 0}
            isLoading={isLoading}
          />
        ))}
      </TabsList>
    </Tabs>
  );
}

export { AllApplicationsTableTabs };
