import { clsx } from "clsx";
import { Function as F, Option as O, Predicate as P } from "effect";
import type { MouseEvent } from "react";
import { useCallback, useContext } from "react";

import { handleLogout, useAuthActor } from "@ender/features/auth";
import { UserContext } from "@ender/shared/contexts/user";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { useEnvironmentStore } from "@ender/shared/stores/environment-store";

import styles from "./admin-tools.module.css";

function AdminTools() {
  const [authSnapshot] = useAuthActor();
  const {
    context: { session },
  } = authSnapshot;
  const readOnly = F.pipe(
    session,
    O.map((s) => s.readOnly),
    O.getOrElse(() => false),
  );

  const { user, originalUser } = useContext(UserContext);
  const isProduction = useEnvironmentStore.use.isProduction();

  const isMasquerading =
    P.isNotNullable(user) &&
    P.isNotNullable(originalUser) &&
    originalUser.id !== user.id;
  const dangerMessage = readOnly
    ? "Read Only Production"
    : "D A N G E R M O D E ! You're on production!";

  const handleLogoutClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleLogout();
  }, []);

  return (
    <div
      className={clsx(styles.root, {
        [styles.prod]: isProduction && !readOnly,
      })}>
      <div className="text-white text-xs/normal">
        {isMasquerading ? (
          <>
            {isProduction && `${dangerMessage} • `}
            {originalUser?.name}
            {" ➜ "}
            <Tooltip label="Sign out" side="left">
              <button className="underline" onClick={handleLogoutClick}>
                {user?.name}
              </button>
            </Tooltip>
          </>
        ) : (
          <Tooltip label="Sign out" side="left">
            <button className="underline" onClick={handleLogoutClick}>
              {user?.name}
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export { AdminTools };
