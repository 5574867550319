/**
 * HTTP Method: DELETE
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}
 * @function deleteArea
 * @memberof InspectionAPI
 * @param {InspectionAPIDeleteAreaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionAreaResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionAreaResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIDeleteAreaPathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
};

type InspectionAPIDeleteAreaSearchParams = {
  token?: string | undefined;
};

type InspectionAPIDeleteAreaPayload = InspectionAPIDeleteAreaPathParams &
  InspectionAPIDeleteAreaSearchParams;

function deleteAreaUnsafeEffect(payload: InspectionAPIDeleteAreaPayload) {
  const { inspectionId, areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionAreaResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionAreaResponse>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteArea(
  payload: InspectionAPIDeleteAreaPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionAreaResponse> {
  return F.pipe(
    payload,
    deleteAreaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteArea };
export type {
  InspectionAPIDeleteAreaPathParams,
  InspectionAPIDeleteAreaPayload,
  InspectionAPIDeleteAreaSearchParams,
};
