/**
 * HTTP Method: POST
 * Pathname: /invoices/{invoiceId}/billback
 * @function createBillback
 * @memberof InvoicesAPI
 * @param {InvoicesAPICreateBillbackPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPICreateBillbackPathParams = {
  invoiceId: EnderId;
};

type InvoicesAPICreateBillbackSearchParams = {
  token?: string | undefined;
};

type InvoicesAPICreateBillbackBodyParams = {
  amount: SerializesInto<Money>;
  chargeTypeId: EnderId;
  date: SerializesInto<LocalDate>;
  leaseId: EnderId;
};

type InvoicesAPICreateBillbackPayload = InvoicesAPICreateBillbackPathParams &
  InvoicesAPICreateBillbackSearchParams &
  InvoicesAPICreateBillbackBodyParams;

function createBillbackUnsafeEffect(payload: InvoicesAPICreateBillbackPayload) {
  const { invoiceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/invoices/${invoiceId}/billback`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createBillback(
  payload: InvoicesAPICreateBillbackPayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice> {
  return F.pipe(
    payload,
    createBillbackUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createBillback };
export type {
  InvoicesAPICreateBillbackBodyParams,
  InvoicesAPICreateBillbackPathParams,
  InvoicesAPICreateBillbackPayload,
  InvoicesAPICreateBillbackSearchParams,
};
