import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitLaundryTypeValues = ["NONE", "IN_UNIT", "SHARED"] as const;

const UnitLaundryTypeEffectSchema = Schema.Literal(...UnitLaundryTypeValues);

type UnitLaundryType = Schema.Schema.Type<typeof UnitLaundryTypeEffectSchema>;

const UnitLaundryTypeEnum = castEnum(UnitLaundryTypeEffectSchema);

function randomUnitLaundryType(): UnitLaundryType {
  return rand(UnitLaundryTypeValues);
}

export {
  randomUnitLaundryType,
  UnitLaundryTypeEffectSchema,
  UnitLaundryTypeEnum,
  UnitLaundryTypeValues,
};
export type { UnitLaundryType };
