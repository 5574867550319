"use client";

import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P, String as Str } from "effect";
import { useCallback, useMemo, useState } from "react";

import type { TwilioClientVerificationCodeChannel } from "@ender/shared/generated/com.ender.common.arch.client";
import { TwilioClientVerificationCodeChannelEnum } from "@ender/shared/generated/com.ender.common.arch.client";
import type { AuthAPILoginPayload } from "@ender/shared/generated/ender.api.misc";
import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import type { LoginPayload } from "../machine/auth.types";
import { MultiFactorCodeFormView } from "./multi-factor-code-form.view";
import { toErrorMessage } from "./utils";

type MultiFactorLoginPayload = Required<
  Pick<AuthAPILoginPayload, "email" | "password" | "code" | "channel">
>;

async function multiFactorLogin(payload: MultiFactorLoginPayload) {
  return await AuthAPI.login({ force2Fa: true, ...payload });
}

type MultiFactorCodeFormControllerProps = {
  channel: TwilioClientVerificationCodeChannel;
  email: string;
  initialIsCodeResend?: boolean;
  loginPayload?: LoginPayload;
  onDone: (session: EnderSessionResponse | undefined) => void;
  onResetChannel: () => void;
  phone: string;
};

function MultiFactorCodeFormController(
  props: MultiFactorCodeFormControllerProps,
) {
  const {
    channel,
    email,
    initialIsCodeResend = false,
    loginPayload,
    onDone,
    onResetChannel,
    phone,
  } = props;

  const {
    mutateAsync: sendVerificationCodeAsync,
    isLoading: isSendVerificationCodeLoading,
  } = useMutation({
    mutationFn: AuthAPI.sendVerificationCode,
  });

  const {
    mutateAsync: multiFactorLoginAsync,
    error: multiFactorLoginError,
    isLoading: isMultiFactorLoginLoading,
  } = useMutation({ mutationFn: multiFactorLogin });
  const multiFactorLoginErrorMessage = useMemo(
    () => toErrorMessage(multiFactorLoginError),
    [multiFactorLoginError],
  );

  const {
    mutateAsync: checkVerificationCodeAsync,
    error: checkVerificationCodeError,
    isLoading: isCheckVerificationCodeLoading,
  } = useMutation({ mutationFn: AuthAPI.checkVerificationCode });
  const checkVerificationCodeErrorMessage = useMemo(
    () => toErrorMessage(checkVerificationCodeError),
    [checkVerificationCodeError],
  );

  const message = useMemo(() => {
    if (channel === TwilioClientVerificationCodeChannelEnum.SMS) {
      return `An SMS message with your verification code has been sent to ${phone}`;
    }
    return `An email with your verification code has been sent to ${email}`;
  }, [channel, email, phone]);

  const [isCodeResend, setIsCodeResend] = useState(initialIsCodeResend);
  const [code, setCode] = useState("");
  const [
    isHandleVerifyCodeLoading,
    { setTrue: setVerifyToLoading, setFalse: setVerifyToEnabled },
  ] = useBoolean();
  const isVerifyDisabled = Str.isEmpty(code);
  const isVerifyLoading =
    isHandleVerifyCodeLoading ||
    isMultiFactorLoginLoading ||
    isCheckVerificationCodeLoading;

  const handleResendCodeClick = useCallback(() => {
    sendVerificationCodeAsync({ channel })
      .then(() => {
        setIsCodeResend(true);
      })
      .catch(() => {
        onResetChannel();
      });
  }, [sendVerificationCodeAsync, channel, setIsCodeResend, onResetChannel]);

  const handleVerifyCodeClick = useCallback(async () => {
    if (isVerifyDisabled) {
      return;
    }
    setVerifyToLoading();

    try {
      const result = P.isNullable(loginPayload)
        ? await checkVerificationCodeAsync({
            channel,
            code,
          })
        : await multiFactorLoginAsync({ ...loginPayload, channel, code });
      onDone(result);
    } catch {
      setVerifyToEnabled();
    }
  }, [
    isVerifyDisabled,
    loginPayload,
    checkVerificationCodeAsync,
    code,
    channel,
    multiFactorLoginAsync,
    onDone,
    setVerifyToLoading,
    setVerifyToEnabled,
  ]);

  return (
    <MultiFactorCodeFormView
      code={code}
      errorMessage={O.firstSomeOf([
        checkVerificationCodeErrorMessage,
        multiFactorLoginErrorMessage,
      ])}
      isCodeResend={isCodeResend}
      isResendFetching={isSendVerificationCodeLoading}
      isVerifyDisabled={isVerifyDisabled}
      isVerifyFetching={isVerifyLoading}
      message={message}
      onCodeChange={setCode}
      onResendCodeClick={handleResendCodeClick}
      onResetChannelClick={onResetChannel}
      onVerifyCodeClick={handleVerifyCodeClick}
    />
  );
}

export { MultiFactorCodeFormController };
