import { zodResolver } from "@mantine/form";
import { Option as O, Predicate as P, String as S } from "effect";
import type { ReactNode } from "react";
import { z } from "zod";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema, LocalDate$ } from "@ender/shared/core";
import { useForm } from "@ender/shared/forms/hooks/general";
import type { Lease } from "@ender/shared/generated/ender.model.leasing";
import { cast } from "@ender/shared/types/cast";
import { LocalDateZodSchema, OptionSchema } from "@ender/shared/utils/zod";
import type { AllocationListItemInput } from "@ender/widgets/finance/allocation-list";
import { AllocationListItemSchema } from "@ender/widgets/finance/allocation-list";

const AllocationsByLeaseSchema = z.object({
  allocations: z
    .array(AllocationListItemSchema)
    .min(1, { message: "At least one allocation is required" }),
  leaseId: z.preprocess(
    (value) => (P.isNullable(value) ? "" : value),
    EnderIdSchema.refine((value) => S.isNonEmpty(value), {
      message: "Lease is required.",
    }),
  ),
});

const UseNewBillbackFormSchema = z.object({
  date: OptionSchema(LocalDateZodSchema).refine(O.isSome, {
    message: "Date is required.",
  }),
  leaseAllocations: AllocationsByLeaseSchema.array(),
});
type UseNewBillbackFormInput = z.input<typeof UseNewBillbackFormSchema>;
type UseNewBillbackFormOutput = z.output<typeof UseNewBillbackFormSchema>;

type UseNewBillbackFormParams = {
  initialLease: Lease | Undefined;
};

function useNewBillbackForm({ initialLease }: UseNewBillbackFormParams) {
  const emptyLeaseAllocations = {
    allocations: [
      {
        amount: O.none(),
        categoryId: cast<EnderId>(UNDEFINED),
        payableCategoryId: UNDEFINED,
        propertyId: cast<EnderId>(initialLease?.propertyId ?? ""),
      },
    ],
    leaseId: initialLease?.id || cast<EnderId>(UNDEFINED),
  };

  const initialValues = {
    date: LocalDate$.parse(LocalDate$.today()),
    leaseAllocations: [emptyLeaseAllocations],
  };

  const form = useForm<UseNewBillbackFormInput>({
    initialValues,
    validate: zodResolver(UseNewBillbackFormSchema),
  });

  function getFormErrors(keyToMatch: string): Record<string, ReactNode> {
    // USE reduce TO PREVENT LOOPING TWICE | e.g., filter -> map
    return Object.entries(form.errors).reduce(
      (acc: Record<string, ReactNode>, [key, value]) => {
        if (key.startsWith(keyToMatch)) {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, ReactNode>,
    );
  }

  function onLeaseResultSelect(
    leaseIndex: number,
    result: { leaseId: EnderId; propertyId: EnderId },
  ) {
    form.setFieldValue(
      `leaseAllocations.${leaseIndex}.leaseId`,
      result?.leaseId || cast<EnderId>(UNDEFINED),
    );

    const currentAllocations =
      form.values.leaseAllocations[leaseIndex].allocations;
    const updatedAllocations = currentAllocations.map((allocation) => ({
      ...allocation,
      propertyId: result.propertyId,
    }));

    form.setFieldValue(
      `leaseAllocations.${leaseIndex}.allocations`,
      updatedAllocations,
    );
  }

  function onUpdateAllocations(
    leaseIndex: number,
    allocations: AllocationListItemInput[],
  ) {
    form.setFieldValue(
      `leaseAllocations.${leaseIndex}.allocations`,
      allocations,
    );
  }

  function onAddLease() {
    form.insertListItem("leaseAllocations", emptyLeaseAllocations);
  }

  function onRemoveLease(leaseIndex: number) {
    form.removeListItem("leaseAllocations", leaseIndex);
  }

  function onRemoveLeaseAllocation(
    leaseIndex: number,
    allocationIndex: number,
  ) {
    form.removeListItem(
      `leaseAllocations.${leaseIndex}.allocations`,
      allocationIndex,
    );
  }
  return {
    form,
    getFormErrors,
    onAddLease,
    onLeaseResultSelect,
    onRemoveLease,
    onRemoveLeaseAllocation,
    onUpdateAllocations,
  };
}

export { useNewBillbackForm };
export type { UseNewBillbackFormInput, UseNewBillbackFormOutput };
