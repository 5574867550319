import type { Row } from "@tanstack/react-table";
import { Predicate } from "effect";
import type { ReactNode } from "react";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { ALL_FINANCIALLY_RESPONSIBLE_TENANTS } from "@ender/shared/constants/string";
import type { EnderId, Money } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import type { BadgeColors } from "@ender/shared/ds/badge";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { RouterLink } from "@ender/shared/ds/router-link";
import type { GetBankAccountResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import type { BankRecRow } from "@ender/shared/generated/ender.service.accounting.banking";
import { Ellipsis } from "@ender/shared/ui/ellipsis";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { asColumnDef } from "@ender/shared/ui/table-tanstack";
import { toSlashString } from "@ender/shared/utils/local-date";

import type { ReconciliationStatus } from "../../../banking.types";
import { BankingDetailInvoiceIconAndModals } from "./banking-detail-invoice-icon-and-modals";
import { BankingDetailStatusBadgeWithModals } from "./banking-detail-status-badge-with-modals";
import { Counterparty } from "./counterparty";
import { SplitUnmatchedEnderTxnButton } from "./split-unmatched-ender-txn-button";

import styles from "./banking-detail-list-columns.module.css";

type BankingDetailListTableData = BankRecRow & {
  badgeColor: BadgeColors;
  canRemoveRow: boolean;
  displayedRunningBalance: Money | Null;
  invoiceIcon: ReactNode;
  isEnderTxnBatch: boolean;
  isForAllTenants: boolean | Undefined;
  isFirstLine: boolean;
  isMultiInvoice: boolean;
  multiplePaymentInvoices?: Invoice[];
  reconciliationStatus: ReconciliationStatus;
  refreshBankTransactions: () => void;
  startBatchMatching: () => void;
  tooltipContent?: ReactNode;
  // TODO: Strongly type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactionDetails: any;
};

type BankingDetailsListTableMeta = {
  details?: GetBankAccountResponse;
  refreshTransactions: () => void;
  setBatchMatcherTransactionId: (value: EnderId | null) => void;
};

const columns: ColumnDef<
  BankingDetailListTableData,
  unknown,
  unknown,
  BankingDetailsListTableMeta
>[] = [
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "date",
    cell: (props) =>
      Predicate.isNotNullable(props.row.original.date) &&
      toSlashString(LocalDate$.of(props.row.original.date)),
    className: styles.date,
    header: "Date",
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "property",
    cell: (props) => <Ellipsis>{props.row.original.property}</Ellipsis>,
    className: styles.property,
    header: "Property",
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "counterpartyInfo",
    cell: (props) => {
      const counterpartyInfo = props.row.original.counterpartyInfo;

      if (props.row.original.isForAllTenants) {
        return (
          <RouterLink href={`/leases/${counterpartyInfo?.partyId}`}>
            {ALL_FINANCIALLY_RESPONSIBLE_TENANTS}
          </RouterLink>
        );
      }

      if (Predicate.isNotNullable(counterpartyInfo)) {
        return <Counterparty counterpartyInfo={counterpartyInfo} />;
      }
    },
    className: styles.counterparty,
    header: "Counterparty",
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "description",
    cell: (props) => (
      <div aria-label="Item description">
        <Ellipsis>{props.row.original.description}</Ellipsis>
      </div>
    ),
    className: styles.description,
    header: "Description",
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "draw",
    cell: (props) => (
      <div aria-label="Draw amount">
        <MoneyDisplay
          showSymbol={props.row.original.isFirstLine}
          value={Money$.parse(props.row.original.draw)}
        />
      </div>
    ),
    className: styles.draw,
    enableColumnFilter: false,
    filterFn: (
      row: Row<BankingDetailListTableData>,
      _,
      filterValue: boolean,
    ) => {
      return filterValue || Predicate.isUndefined(row.original.draw);
    },
    header: "Draw",
    maxSize: 200,
    minSize: 100,
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "deposit",
    cell: (props) => (
      <div aria-label="Deposit amount">
        <MoneyDisplay
          showSymbol={props.row.original.isFirstLine}
          value={Money$.parse(props.row.original.deposit)}
        />
      </div>
    ),
    className: styles.deposit,
    enableColumnFilter: false,
    filterFn: (
      row: Row<BankingDetailListTableData>,
      _,
      filterValue: boolean,
    ) => {
      return filterValue || Predicate.isUndefined(row.original.deposit);
    },
    header: "Deposit",
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "displayedRunningBalance",
    cell: (props) => (
      <MoneyDisplay
        showSymbol={props.row.original.isFirstLine}
        value={Money$.parse(props.row.original.displayedRunningBalance)}
      />
    ),
    className: styles.bankBalance,
    header: "Bank Balance",
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "runningAdjustedBalance",
    cell: (props) => (
      <MoneyDisplay
        showSymbol={props.row.original.isFirstLine}
        value={Money$.parse(props.row.original.runningAdjustedBalance)}
      />
    ),
    className: styles.adjustedBalance,
    header: "Adjusted Balance",
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "splitUnmatchedEnderTxn",
    cell: (props) => {
      const canSplit = props.row.original.canSplit;
      const bankAccountId = props.row.original.bankTransaction?.bankAccountId;
      const bankingBatchId = props.row.original.enderBatch?.id;
      const onSuccess = props.table.options.meta.refreshTransactions;
      if (
        !canSplit ||
        Predicate.isUndefined(bankAccountId) ||
        Predicate.isUndefined(bankingBatchId) ||
        Predicate.isUndefined(onSuccess)
      ) {
        return NULL;
      }
      return (
        <SplitUnmatchedEnderTxnButton
          bankAccountId={bankAccountId}
          bankingBatchId={bankingBatchId}
          onSuccess={onSuccess}
        />
      );
    },
    className: styles.splitUnmatchedEnderTxn,
    enableSorting: false,
    header: "",
    size: 52,
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "invoiceIcon",
    cell: (props) => {
      const {
        refreshBankTransactions,
        invoiceIcon,
        isMultiInvoice,
        linkedInvoice,
        enderBatch,
      } = props.row.original;

      return (
        <BankingDetailInvoiceIconAndModals
          refreshBankTransactions={refreshBankTransactions}
          invoiceIcon={invoiceIcon}
          isMultiInvoice={isMultiInvoice}
          linkedInvoice={linkedInvoice}
          enderBatch={enderBatch}
        />
      );
    },
    className: styles.invoiceIcon,
    enableSorting: false,
    header: "",
    size: 52,
  }),
  asColumnDef<
    BankingDetailListTableData,
    unknown,
    unknown,
    BankingDetailsListTableMeta
  >({
    accessorKey: "rowType",
    cell: (props) => {
      const {
        badgeColor,
        bankTransaction,
        canRemoveRow,
        deposit,
        draw,
        isIgnored,
        reconciliationStatus,
        rowType,
        tooltipContent,
        transactionDetails,
      } = props.row.original;

      return (
        <BankingDetailStatusBadgeWithModals
          badgeColor={badgeColor}
          bankTransaction={bankTransaction}
          canRemoveRow={canRemoveRow}
          deposit={deposit}
          details={props.table.options.meta.details}
          draw={draw}
          getBankTransactions={props.table.options.meta.refreshTransactions}
          isIgnored={isIgnored}
          reconciliationStatus={reconciliationStatus}
          rowType={rowType}
          startBatchMatching={
            props.table.options.meta.setBatchMatcherTransactionId
          }
          tooltipContent={tooltipContent}
          transaction={props.row.original}
          transactionDetails={transactionDetails}
        />
      );
    },
    className: styles.reconciliation,
    enableColumnFilter: false,
    filterFn: "equals",
    header: "Reconciliation",
  }),
];

const sharedColumns = [
  "date",
  "property",
  "counterpartyInfo",
  "description",
  "draw",
  "deposit",
  "displayedRunningBalance",
  "runningAdjustedBalance",
  "splitUnmatchedEnderTxn",
  "invoiceIcon",
];

const bankingDetailsListColumnsOrder = [
  ...sharedColumns,
  "rowType", // Reconciliation column at the end
];

const bankingDetailsListColumnsSmallScreenOrder = [
  "rowType", // Reconciliation column at the start
  ...sharedColumns,
];

const columnsLookup = Object.fromEntries(
  // @ts-expect-error col is type of BankingDetailListTableData but it does have an accessorKey
  columns.map((col) => [col.accessorKey, col]),
);

// Map the column order to the actual column definitions
const bankingDetailsListColumns = bankingDetailsListColumnsOrder.map(
  (key) => columnsLookup[key],
);
const bankingDetailsListColumnsSmallScreen =
  bankingDetailsListColumnsSmallScreenOrder.map((key) => columnsLookup[key]);

export { bankingDetailsListColumns, bankingDetailsListColumnsSmallScreen };
export type { BankingDetailListTableData, BankingDetailsListTableMeta };
