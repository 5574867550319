/**
 * HTTP Method: POST
 * Pathname: /getBankLinkInfoByModelV2
 * @function getBankLinkInfoByModelV2
 * @memberof DwollaAPI
 * @param {DwollaAPIGetBankLinkInfoByModelV2Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetBankLinkInfoResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { GetBankLinkInfoResponse } from "@ender/shared/generated/ender.api.finance.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPIGetBankLinkInfoByModelV2SearchParams = {
  token?: string | undefined;
};

type DwollaAPIGetBankLinkInfoByModelV2BodyParams = {
  modelId: EnderId;
  modelType: ModelType;
};

type DwollaAPIGetBankLinkInfoByModelV2Payload =
  DwollaAPIGetBankLinkInfoByModelV2SearchParams &
    DwollaAPIGetBankLinkInfoByModelV2BodyParams;

function getBankLinkInfoByModelV2UnsafeEffect(
  payload: DwollaAPIGetBankLinkInfoByModelV2Payload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetBankLinkInfoResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetBankLinkInfoResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getBankLinkInfoByModelV2",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBankLinkInfoByModelV2(
  payload: DwollaAPIGetBankLinkInfoByModelV2Payload,
  options?: { signal?: AbortSignal },
): Promise<GetBankLinkInfoResponse> {
  return F.pipe(
    payload,
    getBankLinkInfoByModelV2UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankLinkInfoByModelV2 };
export type {
  DwollaAPIGetBankLinkInfoByModelV2BodyParams,
  DwollaAPIGetBankLinkInfoByModelV2Payload,
  DwollaAPIGetBankLinkInfoByModelV2SearchParams,
};
