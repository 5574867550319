/**
 * HTTP Method: POST
 * Pathname: /markets
 * @function createMarket
 * @memberof MarketsAPI
 * @param {MarketsAPICreateMarketPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Market>}
 */
import { Effect, Function as F } from "effect";

import type { Market } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MarketsAPICreateMarketSearchParams = {
  token?: string | undefined;
};

type MarketsAPICreateMarketBodyParams = {
  code: string;
  name: string;
};

type MarketsAPICreateMarketPayload = MarketsAPICreateMarketSearchParams &
  MarketsAPICreateMarketBodyParams;

function createMarketUnsafeEffect(payload: MarketsAPICreateMarketPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Market>({
        body,
        decode: unsafeDecodeJsonResponse<Market>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/markets",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createMarket(
  payload: MarketsAPICreateMarketPayload,
  options?: { signal?: AbortSignal },
): Promise<Market> {
  return F.pipe(
    payload,
    createMarketUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createMarket };
export type {
  MarketsAPICreateMarketBodyParams,
  MarketsAPICreateMarketPayload,
  MarketsAPICreateMarketSearchParams,
};
