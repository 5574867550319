import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const RecurringServiceScheduleFrequencyValues = [
  "WEEKLY",
  "MONTHLY",
  "YEARLY",
] as const;

const RecurringServiceScheduleFrequencyEffectSchema = Schema.Literal(
  ...RecurringServiceScheduleFrequencyValues,
);

type RecurringServiceScheduleFrequency = Schema.Schema.Type<
  typeof RecurringServiceScheduleFrequencyEffectSchema
>;

const RecurringServiceScheduleFrequencyEnum = castEnum(
  RecurringServiceScheduleFrequencyEffectSchema,
);

function randomRecurringServiceScheduleFrequency(): RecurringServiceScheduleFrequency {
  return rand(RecurringServiceScheduleFrequencyValues);
}

export {
  randomRecurringServiceScheduleFrequency,
  RecurringServiceScheduleFrequencyEffectSchema,
  RecurringServiceScheduleFrequencyEnum,
  RecurringServiceScheduleFrequencyValues,
};
export type { RecurringServiceScheduleFrequency };
