/**
 * HTTP Method: DELETE
 * Pathname: /stateMarketMapping/{stateMarketMappingId}
 * @function deleteStateFromMarket
 * @memberof MarketsAPI
 * @param {MarketsAPIDeleteStateFromMarketPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MarketsAPIDeleteStateFromMarketPathParams = {
  stateMarketMappingId: EnderId;
};

type MarketsAPIDeleteStateFromMarketSearchParams = {
  token?: string | undefined;
};

type MarketsAPIDeleteStateFromMarketPayload =
  MarketsAPIDeleteStateFromMarketPathParams &
    MarketsAPIDeleteStateFromMarketSearchParams;

function deleteStateFromMarketUnsafeEffect(
  payload: MarketsAPIDeleteStateFromMarketPayload,
) {
  const { stateMarketMappingId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/stateMarketMapping/${stateMarketMappingId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteStateFromMarket(
  payload: MarketsAPIDeleteStateFromMarketPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteStateFromMarketUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteStateFromMarket };
export type {
  MarketsAPIDeleteStateFromMarketPathParams,
  MarketsAPIDeleteStateFromMarketPayload,
  MarketsAPIDeleteStateFromMarketSearchParams,
};
