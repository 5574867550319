import { Option as O } from "effect";

import type { InvoiceResponsePropertyResponse } from "@ender/shared/generated/ender.arch.accounting";

type GetPropertyNameWithFriendlyIdParams = Pick<
  InvoiceResponsePropertyResponse,
  "name"
> &
  Partial<Pick<InvoiceResponsePropertyResponse, "friendlyId">>;

function getPropertyNameWithFriendlyId(
  property: GetPropertyNameWithFriendlyIdParams,
): string {
  return `${property.name}${O.fromNullable(property.friendlyId).pipe(
    O.filter((f) => f.length > 0),
    O.map((friendlyId) => ` (${friendlyId})`),
    O.getOrElse(() => ""),
  )}`;
}

export { getPropertyNameWithFriendlyId };
export type { GetPropertyNameWithFriendlyIdParams };
