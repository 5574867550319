import { useMutation } from "@tanstack/react-query";

import type { CustomField } from "@ender/shared/api/factors";
import type { EnderId, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Stack } from "@ender/shared/ds/stack";
import { useForm } from "@ender/shared/forms/hooks/general";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import { EnderInputWrapper } from "@ender/shared/ui/ender-input-wrapper";
import { FactorFieldInput } from "@ender/shared/ui/factor-field-input";
import type { EnderDate } from "@ender/shared/utils/ender-date";

type FormValues = {
  fields: { factorId: EnderId; value: string }[];
};

type EditCustomFieldsFormProps = {
  customFields: CustomField[];
  modelType: ModelType;
  modelId: EnderId;
  onSuccess: () => void;
};

function EditCustomFieldsForm({
  customFields,
  modelType,
  modelId,
  onSuccess,
}: EditCustomFieldsFormProps) {
  const form = useForm({
    initialValues: {
      fields: customFields.map((field) => ({
        factorId: field.factor.id,
        outputType: field.factor.outputType,
        value: field.value,
        name: field.factor.name,
      })),
    },
  });

  function onChange(
    value:
      | string
      | number
      | Money$.Money
      | EnderDate
      | boolean
      | undefined
      | null,
    i: number,
  ) {
    form.setFieldValue(`fields.${i}.value`, value);
  }

  const { mutate: submitForm } = useMutation({
    mutationFn: async (values: FormValues) => {
      const finalizedValues = values.fields.map(({ factorId, value }) => ({
        factorId,
        value,
      }));
      await FactorsAPI.setCustomFields({
        modelId,
        modelType,
        json: { fields: finalizedValues },
      });
    },
    onSuccess,
  });

  return (
    <form onSubmit={form.onSubmit((values) => submitForm(values))}>
      <Stack spacing="lg">
        <Stack>
          {form.values.fields.map((field, i) => (
            <EnderInputWrapper key={field.factorId} label={field.name}>
              <FactorFieldInput
                factorId={field.factorId}
                outputType={field.outputType}
                value={field.value}
                onChange={(field) => onChange(field.value, i)}
              />
            </EnderInputWrapper>
          ))}
        </Stack>
        <div className="right">
          <Button type="submit">Save</Button>
        </div>
      </Stack>
    </form>
  );
}

export { EditCustomFieldsForm };
