/**
 * HTTP Method: POST
 * Pathname: /splitUnmatchedEnderTxn
 * @function splitUnmatchedEnderTxn
 * @memberof BankingAPI
 * @param {BankingAPISplitUnmatchedEnderTxnPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPISplitUnmatchedEnderTxnSearchParams = {
  token?: string | undefined;
};

type BankingAPISplitUnmatchedEnderTxnBodyParams = {
  bankAccountId: EnderId;
  bankingBatchId: EnderId;
};

type BankingAPISplitUnmatchedEnderTxnPayload =
  BankingAPISplitUnmatchedEnderTxnSearchParams &
    BankingAPISplitUnmatchedEnderTxnBodyParams;

function splitUnmatchedEnderTxnUnsafeEffect(
  payload: BankingAPISplitUnmatchedEnderTxnPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/splitUnmatchedEnderTxn",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function splitUnmatchedEnderTxn(
  payload: BankingAPISplitUnmatchedEnderTxnPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    splitUnmatchedEnderTxnUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { splitUnmatchedEnderTxn };
export type {
  BankingAPISplitUnmatchedEnderTxnBodyParams,
  BankingAPISplitUnmatchedEnderTxnPayload,
  BankingAPISplitUnmatchedEnderTxnSearchParams,
};
