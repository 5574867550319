import { z } from "zod";

import { EnderIdSchema, InstantSchema } from "@ender/shared/core";

const ReportSchema = z.object({
  authorId: EnderIdSchema,
  id: EnderIdSchema,
  name: z.string(),
  pmId: EnderIdSchema,
  timestamp: InstantSchema,
});

const BALANCE_SHEET = "Balance Sheet" as const;
const CASHFLOW = "Cashflow" as const;
const DELINQUENCY_MANAGER_METRICS = "Delinquency Manager Metrics" as const;
const EXPANDED_BALANCE_SHEET = "Expanded Balance Sheet" as const;
const EXPANDED_INCOME_STATEMENT = "Expanded Income Statement" as const;
const EXPANDED_WORKING_TRIAL_BALANCE =
  "Expanded Working Trial Balance" as const;
const GENERAL_LEDGER = "General Ledger" as const;
const INCOME_STATEMENT = "Income Statement" as const;
const JOURNAL_ENTRIES = "Journal Entries" as const;
const PAYABLES_REPORT = "Payables Report" as const;
const PM_BALANCE_REPORT = "PM Balance Report" as const;
const RENT_ROLL = "Rent Roll" as const;
const WORKING_TRIAL_BALANCE = "Working Trial Balance" as const;
const YEAR_OVER_YEAR_REPORT = "Year Over Year Report" as const;

const ReportTypeValues = [
  BALANCE_SHEET,
  CASHFLOW,
  DELINQUENCY_MANAGER_METRICS,
  EXPANDED_BALANCE_SHEET,
  EXPANDED_INCOME_STATEMENT,
  EXPANDED_WORKING_TRIAL_BALANCE,
  GENERAL_LEDGER,
  INCOME_STATEMENT,
  JOURNAL_ENTRIES,
  PAYABLES_REPORT,
  PM_BALANCE_REPORT,
  RENT_ROLL,
  WORKING_TRIAL_BALANCE,
  YEAR_OVER_YEAR_REPORT,
] as const;

const ReportTypeSchema = z.enum(ReportTypeValues);

type ReportType = z.infer<typeof ReportTypeSchema>;

const SystemReportSchema = ReportSchema.extend({});

type SystemReport = z.infer<typeof SystemReportSchema>;

export {
  BALANCE_SHEET,
  CASHFLOW,
  DELINQUENCY_MANAGER_METRICS,
  EXPANDED_BALANCE_SHEET,
  EXPANDED_INCOME_STATEMENT,
  EXPANDED_WORKING_TRIAL_BALANCE,
  GENERAL_LEDGER,
  INCOME_STATEMENT,
  JOURNAL_ENTRIES,
  PAYABLES_REPORT,
  PM_BALANCE_REPORT,
  RENT_ROLL,
  ReportTypeSchema,
  SystemReportSchema,
  WORKING_TRIAL_BALANCE,
  YEAR_OVER_YEAR_REPORT,
};
export type { ReportType, SystemReport };
