/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}
 * @function updateApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIUpdateApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { ApplicationEmploymentStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIUpdateApplicationPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIUpdateApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIUpdateApplicationBodyParams = {
  annualSalary?: SerializesInto<Money> | undefined;
  applicationFeeAmount?: SerializesInto<Money> | undefined;
  convictedCriminalDescription?: string | undefined;
  employer?: string | undefined;
  employmentStartDate?: SerializesInto<LocalDate> | undefined;
  employmentStatus?: ApplicationEmploymentStatus | undefined;
  firstTimeRenter?: boolean | undefined;
  holdingFeeAmount?: SerializesInto<Money> | undefined;
  housingChoiceVoucher?: boolean | undefined;
  housingChoiceVoucherCaseWorkerEmail?: string | undefined;
  housingChoiceVoucherCaseWorkerName?: string | undefined;
  isConvictedCriminal?: boolean | undefined;
  landlordEmail?: string | undefined;
  landlordName?: string | undefined;
  landlordPhone?: string | undefined;
  managerName?: string | undefined;
  managerPhone?: string | undefined;
  moveInDate?: SerializesInto<LocalDate> | undefined;
  targetUserId?: EnderId | undefined;
  unitId?: EnderId | undefined;
  validatedThreeMonthIncome?: SerializesInto<Money> | undefined;
};

type ApplicationsAPIUpdateApplicationPayload =
  ApplicationsAPIUpdateApplicationPathParams &
    ApplicationsAPIUpdateApplicationSearchParams &
    ApplicationsAPIUpdateApplicationBodyParams;

function updateApplicationUnsafeEffect(
  payload: ApplicationsAPIUpdateApplicationPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateApplication(
  payload: ApplicationsAPIUpdateApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateApplication };
export type {
  ApplicationsAPIUpdateApplicationBodyParams,
  ApplicationsAPIUpdateApplicationPathParams,
  ApplicationsAPIUpdateApplicationPayload,
  ApplicationsAPIUpdateApplicationSearchParams,
};
