import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BankRecRowTypeValues = [
  "MATCHED",
  "UNMATCHED_ENDER",
  "UNMATCHED_BANK",
  "MATCHED_LATER_ENDER",
  "MATCHED_LATER_BANK",
  "ENDER_EXCLUDED",
  "BANK_EXCLUDED",
] as const;

const BankRecRowTypeEffectSchema = Schema.Literal(...BankRecRowTypeValues);

type BankRecRowType = Schema.Schema.Type<typeof BankRecRowTypeEffectSchema>;

const BankRecRowTypeEnum = castEnum(BankRecRowTypeEffectSchema);

function randomBankRecRowType(): BankRecRowType {
  return rand(BankRecRowTypeValues);
}

export {
  BankRecRowTypeEffectSchema,
  BankRecRowTypeEnum,
  BankRecRowTypeValues,
  randomBankRecRowType,
};
export type { BankRecRowType };
