/**
 * Creates a new bank account funding source in Dwolla and a corresponding object in the Ender database. The bank
 account will be created in status RECEIVE_ONLY, meaning it will never have to go through microdeposits, but also
 cannot be used to send funds.
 * HTTP Method: POST
 * Pathname: /linkReceiveOnlyBank
 * @function linkReceiveOnlyBank
 * @memberof DwollaAPI
 * @param {DwollaAPILinkReceiveOnlyBankPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPILinkReceiveOnlyBankSearchParams = {
  token?: string | undefined;
};

type DwollaAPILinkReceiveOnlyBankBodyParams = {
  accountName: string;
  accountNumber: string;
  /**
   * the EnderID of the internal model (NOT a Dwolla Customer ID!)
   */
  customerId: EnderId;
  /**
   * Really just a ModelType. Must be a bank linkable model.
   */
  customerType: ModelType;
  routingNumber: string;
};

type DwollaAPILinkReceiveOnlyBankPayload =
  DwollaAPILinkReceiveOnlyBankSearchParams &
    DwollaAPILinkReceiveOnlyBankBodyParams;

function linkReceiveOnlyBankUnsafeEffect(
  payload: DwollaAPILinkReceiveOnlyBankPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/linkReceiveOnlyBank",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function linkReceiveOnlyBank(
  payload: DwollaAPILinkReceiveOnlyBankPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    linkReceiveOnlyBankUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { linkReceiveOnlyBank };
export type {
  DwollaAPILinkReceiveOnlyBankBodyParams,
  DwollaAPILinkReceiveOnlyBankPayload,
  DwollaAPILinkReceiveOnlyBankSearchParams,
};
