import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const RoofRoofMaterialValues = [
  "METAL",
  "THREE_TAB",
  "ARCH",
  "TILE",
  "FLAT",
] as const;

const RoofRoofMaterialEffectSchema = Schema.Literal(...RoofRoofMaterialValues);

type RoofRoofMaterial = Schema.Schema.Type<typeof RoofRoofMaterialEffectSchema>;

const RoofRoofMaterialEnum = castEnum(RoofRoofMaterialEffectSchema);

function randomRoofRoofMaterial(): RoofRoofMaterial {
  return rand(RoofRoofMaterialValues);
}

export {
  randomRoofRoofMaterial,
  RoofRoofMaterialEffectSchema,
  RoofRoofMaterialEnum,
  RoofRoofMaterialValues,
};
export type { RoofRoofMaterial };
