import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyTenancyValues = ["SINGLE_UNIT", "MULTI_UNIT"] as const;

const PropertyTenancyEffectSchema = Schema.Literal(...PropertyTenancyValues);

type PropertyTenancy = Schema.Schema.Type<typeof PropertyTenancyEffectSchema>;

const PropertyTenancyEnum = castEnum(PropertyTenancyEffectSchema);

function randomPropertyTenancy(): PropertyTenancy {
  return rand(PropertyTenancyValues);
}

export {
  PropertyTenancyEffectSchema,
  PropertyTenancyEnum,
  PropertyTenancyValues,
  randomPropertyTenancy,
};
export type { PropertyTenancy };
