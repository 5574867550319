import { Money$ } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import type { LeaseSerializerDeposit } from "@ender/shared/generated/ender.arch.serializer.leasing";

type SecurityDepositCardProps = {
  securityDeposit: LeaseSerializerDeposit;
};

function SecurityDepositCard({ securityDeposit }: SecurityDepositCardProps) {
  return (
    <Card>
      <Stack spacing={Spacing.md}>
        <Text size={FontSize.md} weight={FontWeight.medium}>
          {securityDeposit.glCategory.accountName}
        </Text>
        <Text size={FontSize.md}>
          <MoneyDisplay
            showSymbol
            value={Money$.parse(securityDeposit.amount)}
          />
        </Text>
      </Stack>
    </Card>
  );
}

export { SecurityDepositCard };
