import { IconEdit, IconTrash } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import type { MouseEvent, PropsWithChildren } from "react";
import { forwardRef } from "react";

import { Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import type { ButtonSizes } from "@ender/shared/ds/button";
import { ButtonSize } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { FontSize, Text } from "@ender/shared/ds/text";
import type { Sizes } from "@ender/shared/utils/theming";
import { Size } from "@ender/shared/utils/theming";

import styles from "./bank-card.module.css";

type AccountCardProps = PropsWithChildren<{
  /**
   * "Credit Card", "Bank Account", "Mastercard", Visa, etc.
   */
  title?: string;
  /**
   * Last 4 digits of the card number. used as a mask
   */
  mask: string;
  /**
   * the remaining length of the card number, padded before the mask
   */
  accountNumberLength?: number;
  /**
   * User-defined nickname for the card
   */
  nickname?: string;
  /**
   * What happens when the card's edit button is clicked.
   */
  onEdit?: () => void;
  /**
   * What happens when the card's delete button is clicked.
   * If not provided, the delete button will not be rendered.
   */
  onDelete?: () => void;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  size?: Sizes;
  type?: "credit" | "bank";
}>;

const sizeRamp: Record<Sizes, ButtonSizes> = {
  [Size.xs]: ButtonSize.sm,
  [Size.sm]: ButtonSize.sm,
  [Size.md]: ButtonSize.md,
  [Size.lg]: ButtonSize.md,
  [Size.xl]: ButtonSize.md,
};

const fullNumber = (type: "bank" | "credit", mask: string | number) => {
  return type === "bank"
    ? `${mask}`.padStart(9, "•")
    : `${mask}`.padStart(16, "•").replace(/(.{4})/g, " $1");
};

/**
 * the default account number length to use when displaying a masked account number
 * •••••5309, for example
 */
const accountNumberLength = 9;

const AccountCard = forwardRef<HTMLDivElement, AccountCardProps>(
  function AccountCard(props, ref) {
    const {
      children,
      title = "Account",
      mask,
      type = "bank",
      nickname,
      onEdit = F.constVoid,
      onDelete = F.constVoid,
      size = "md",
      ...others
    } = props;
    const isTooSmall = ["xs", "sm"].includes(size);

    const accountNumber =
      props.accountNumberLength || isTooSmall
        ? mask.padStart(accountNumberLength, "•")
        : fullNumber(type, mask);

    return (
      <div className={clsx(styles.root, styles[size])}>
        <Stack
          spacing={isTooSmall ? Spacing.none : Spacing.xs}
          ref={ref}
          {...others}>
          <Group spacing={Spacing.xs}>
            {props.onEdit && (
              <ActionIcon
                onClick={onEdit}
                color="primary"
                size={sizeRamp[size]}
                variant="transparent">
                <IconEdit />
              </ActionIcon>
            )}
            {props.onDelete && (
              <ActionIcon
                onClick={onDelete}
                color="red"
                size={sizeRamp[size]}
                variant="transparent">
                <IconTrash size={20} />
              </ActionIcon>
            )}
          </Group>
          <Text
            truncate
            color="primary-500"
            weight="semibold">
            {title}
          </Text>
          <div className={styles.accountNumber}>
            <Text size={FontSize.md}>
              {accountNumber}
            </Text>
          </div>
          <Text size={FontSize.md} truncate>
            {nickname || `Unnamed ${title}`}
          </Text>
          {children}
        </Stack>
      </div>
    );
  },
);

type CreditCardProps = {
  expMonth: number;
  expYear: number;
} & Omit<AccountCardProps, "type">;

const CreditCard = forwardRef<HTMLDivElement, CreditCardProps>(
  function CreditCard(props, ref) {
    const { expMonth, expYear, title = "Credit Card", ...others } = props;
    return (
      <AccountCard title={title} {...others} type="credit" ref={ref}>
        <Text>{`${expMonth ?? ""} / ${expYear ?? ""}`}</Text>
      </AccountCard>
    );
  },
);

type BankAccountProps = {
  endingStatementBalance?: number;
  endingAdjustedBalance?: number;
  glCashCategoryEndingBalance?: number;
} & Omit<AccountCardProps, "type">;

const BankAccountCard = forwardRef<HTMLDivElement, BankAccountProps>(
  function BankAccountCard(props, ref) {
    const {
      endingStatementBalance,
      endingAdjustedBalance,
      glCashCategoryEndingBalance,
      title = "Bank Account",
      ...others
    } = props;
    const isTooSmall = others.size === "xs";

    return (
      <AccountCard title={title} {...others} type="bank" ref={ref}>
        <Tuple
          label={isTooSmall ? "Stmt. Balance:" : "Statement Balance:"}
          value={
            <Skeleton
              visible={P.isNotNullable(endingStatementBalance)}
              rounded="full">
              <MoneyDisplay
                value={Money$.parse(endingStatementBalance)}
                showSymbol
              />
            </Skeleton>
          }
        />
        <Tuple
          label={isTooSmall ? "Adj. Balance:" : "Adjusted Balance:"}
          value={
            <Skeleton
              visible={P.isNotNullable(endingAdjustedBalance)}
              rounded="full">
              <MoneyDisplay
                value={Money$.parse(endingAdjustedBalance)}
                showSymbol
              />
            </Skeleton>
          }
        />
        <Tuple
          label={isTooSmall ? "Cash Balance:" : "GL Cash Balance:"}
          value={
            <Skeleton
              visible={P.isNotNullable(glCashCategoryEndingBalance)}
              rounded="full">
              <MoneyDisplay
                value={Money$.parse(glCashCategoryEndingBalance)}
                showSymbol
              />
            </Skeleton>
          }
        />
      </AccountCard>
    );
  },
);

export { AccountCard, BankAccountCard, CreditCard };
