import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionZoneZoneInspectionStatusValues = [
  "COMPLETED",
  "NEEDS_INSPECTION",
  "NOT_APPLICABLE",
] as const;

const InspectionZoneZoneInspectionStatusEffectSchema = Schema.Literal(
  ...InspectionZoneZoneInspectionStatusValues,
);

type InspectionZoneZoneInspectionStatus = Schema.Schema.Type<
  typeof InspectionZoneZoneInspectionStatusEffectSchema
>;

const InspectionZoneZoneInspectionStatusEnum = castEnum(
  InspectionZoneZoneInspectionStatusEffectSchema,
);

function randomInspectionZoneZoneInspectionStatus(): InspectionZoneZoneInspectionStatus {
  return rand(InspectionZoneZoneInspectionStatusValues);
}

export {
  InspectionZoneZoneInspectionStatusEffectSchema,
  InspectionZoneZoneInspectionStatusEnum,
  InspectionZoneZoneInspectionStatusValues,
  randomInspectionZoneZoneInspectionStatus,
};
export type { InspectionZoneZoneInspectionStatus };
