/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/missingDocuments
 * @function getMissingDocuments
 * @memberof BuyAPI
 * @param {BuyAPIGetMissingDocumentsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetMissingDocumentsPathParams = {
  dealId: EnderId;
};

type BuyAPIGetMissingDocumentsSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetMissingDocumentsPayload = BuyAPIGetMissingDocumentsPathParams &
  BuyAPIGetMissingDocumentsSearchParams;

function getMissingDocumentsUnsafeEffect(
  payload: BuyAPIGetMissingDocumentsPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string[]>({
        body,
        decode: unsafeDecodeJsonResponse<string[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/missingDocuments`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMissingDocuments(
  payload: BuyAPIGetMissingDocumentsPayload,
  options?: { signal?: AbortSignal },
): Promise<string[]> {
  return F.pipe(
    payload,
    getMissingDocumentsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMissingDocuments };
export type {
  BuyAPIGetMissingDocumentsPathParams,
  BuyAPIGetMissingDocumentsPayload,
  BuyAPIGetMissingDocumentsSearchParams,
};
