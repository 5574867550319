/**
 * HTTP Method: POST
 * Pathname: /announcements/tenantAnnouncementDetails
 * @function getAnnouncementDetails
 * @memberof AnnouncementAPI
 * @param {AnnouncementAPIGetAnnouncementDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AnnouncementAPIGetAnnouncementDetailsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AnnouncementAPIGetAnnouncementDetailsResponse } from "../announcement-api-get-announcement-details-response";

type AnnouncementAPIGetAnnouncementDetailsSearchParams = {
  token?: string | undefined;
};

type AnnouncementAPIGetAnnouncementDetailsBodyParams = {
  propertyIds: EnderId[];
};

type AnnouncementAPIGetAnnouncementDetailsPayload =
  AnnouncementAPIGetAnnouncementDetailsSearchParams &
    AnnouncementAPIGetAnnouncementDetailsBodyParams;

function getAnnouncementDetailsUnsafeEffect(
  payload: AnnouncementAPIGetAnnouncementDetailsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AnnouncementAPIGetAnnouncementDetailsResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<AnnouncementAPIGetAnnouncementDetailsResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/announcements/tenantAnnouncementDetails",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAnnouncementDetails(
  payload: AnnouncementAPIGetAnnouncementDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<AnnouncementAPIGetAnnouncementDetailsResponse> {
  return F.pipe(
    payload,
    getAnnouncementDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAnnouncementDetails };
export type {
  AnnouncementAPIGetAnnouncementDetailsBodyParams,
  AnnouncementAPIGetAnnouncementDetailsPayload,
  AnnouncementAPIGetAnnouncementDetailsSearchParams,
};
