import { Predicate as P } from "effect";
import { z } from "zod";

import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";
import type { GetApplicationGroupResponseApplicantUserResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type {
  ApplicationGroup,
  Showing,
} from "@ender/shared/generated/ender.model.leasing";
import { EnderDate } from "@ender/shared/utils/ender-date";

type NewShowingFormProps = {
  showing?: Showing;
  applicationGroup?: ApplicationGroup;
  applicants?: GetApplicationGroupResponseApplicantUserResponse[];
  userId?: EnderId;
  onSuccess: () => void;
  onCancel: () => void;
};

const NewShowingFormBaseSchema = z.object({
  //TODO use a real schema for this
  prospectOrApplicant: z.object({ leadId: EnderIdSchema }).optional(),
  userId: EnderIdSchema,
  leadId: EnderIdSchema.optional(),
  applicationGroupId: EnderIdSchema.optional(),
  propertyId: EnderIdSchema,
  unitId: EnderIdSchema,
  date: z
    .date()
    .refine(
      (val) => P.isNotNullable(val),
      (_val) => ({ message: "Showing Date is required" }),
    )
    .refine(
      (val) => EnderDate.of(val).isOnOrAfterToday(),
      (_val) => ({ message: "Showing Date must be in the future" }),
    ),
  time: z.date().refine(
    (val) => P.isNotNullable(val),
    (_val) => ({ message: "Showing Time is required" }),
  ),
});

const NewShowingFormSchema = NewShowingFormBaseSchema.refine(
  ({ leadId, applicationGroupId }) =>
    P.isNotNullable(leadId) || P.isNotNullable(applicationGroupId),
  {
    path: ["prospectOrApplicant", "applicant"],
    message: "Required",
  },
).refine(
  ({ date, time }) => {
    if (EnderDate.of(date).isToday()) {
      return EnderDate.of(time).getTime() > EnderDate.today.getTime();
    }
    return true;
  },
  {
    path: ["time"],
    message: "Showing Time must be in the future",
  },
);

type NewShowingFormValues = z.infer<typeof NewShowingFormSchema>;

export { NewShowingFormBaseSchema, NewShowingFormSchema };
export type { NewShowingFormProps, NewShowingFormValues };
