import { Predicate as P } from "effect";
import { useCallback, useEffect, useState } from "react";

import type { Null } from "@ender/shared/constants/general";

type IsEllipsisActiveProps = {
  offsetWidth: number;
  scrollWidth: number;
};
const isEllipsisActive = (e: IsEllipsisActiveProps) => {
  return e.offsetWidth < e.scrollWidth;
};

function useOverflowCheck(node: HTMLElement | Null) {
  const [isOverflow, setIsOverflow] = useState(false);

  const onMouseOver = useCallback(() => {
    if (!P.isNull(node)) {
      setIsOverflow(isEllipsisActive(node));
    }
  }, [node]);

  // lazy useEffect
  useEffect(() => {
    if (node) {
      node.addEventListener("mouseover", onMouseOver);

      return () => node.removeEventListener("mouseover", onMouseOver);
    }
  }, [node, onMouseOver]);

  return isOverflow;
}

export { useOverflowCheck };
