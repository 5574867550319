import { useQueryClient } from "@tanstack/react-query";
import { Predicate as P, pipe } from "effect";
import { useCallback, useContext, useMemo } from "react";

import type { Null } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import {
  BankingAPI,
  PaymentsAPI,
} from "@ender/shared/generated/ender.api.accounting";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import type { UseTableReturn } from "@ender/shared/ui/table-tanstack";
import {
  EnderTableTanstackV3,
  TableTop,
} from "@ender/shared/ui/table-tanstack";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { pluralize } from "@ender/shared/utils/string";

import { isEnderBatchItem } from "../banking-detail.utils";
import { BankingDetailHeader } from "./banking-detail-header/banking-detail-header";
import type {
  BankingDetailListTableData,
  BankingDetailsListTableMeta,
} from "./banking-detail-list-columns/banking-detail-list-columns";
import { ReconcileModal } from "./reconcile-modal/reconcile-modal";

import styles from "./banking-detail-list.module.css";

// Constants
const ERRORS = {
  INVALID_TRANSACTION:
    "Should not happen: One or more transaction details do not have money transfers",
  MISSING_BANK_ACCOUNT: "No bank account ID found for reconciliation",
} as const;

const NOTIFICATIONS = {
  SUCCESS: (count: number) =>
    `${count} ${pluralize("Transaction", count)} Reconciled`,
} as const;

const TABLE_CONFIG = {
  MAX_HEIGHT: "60vh",
  TITLE: "Banking Details",
} as const;

type BankingDetailListProps = {
  getBankTransactions: () => void;
  table: UseTableReturn<
    BankingDetailListTableData,
    BankingDetailsListTableMeta
  >;
};

type BankingDetailListFormValues = {
  memo: string;
};

type ExcludeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: BankingDetailListTableData[];
  totalAmount: Money$.Money;
  onSubmit: () => Promise<void>;
};

// Exclude Modal Component
function ExcludeModal({
  isOpen,
  onClose,
  selectedRows,
  totalAmount,
  onSubmit,
}: ExcludeModalProps) {
  return (
    <Modal
      title={`Exclude ${pluralize("Transaction", selectedRows.length)}`}
      opened={isOpen}
      onClose={onClose}>
      <Stack>
        <Text>
          You are excluding {selectedRows.length}{" "}
          {pluralize("transaction", selectedRows.length)} totaling{" "}
          {Money$.toFormatted(totalAmount, "DEFAULT")}, are you sure you would
          like to continue?
        </Text>
        <Group justify={Justify.end}>
          <Button onClick={onClose} variant={ButtonVariant.outlined}>
            Go Back
          </Button>
          <Button onClick={onSubmit}>Exclude</Button>
        </Group>
      </Stack>
    </Modal>
  );
}

type BankingDetailTableProps = {
  table: UseTableReturn<
    BankingDetailListTableData,
    BankingDetailsListTableMeta
  >;
};

// Utility function to calculate total amount
function getSelectedRowsTotalAmount(
  rows: BankingDetailListTableData[],
): Money$.Money {
  return pipe(
    rows,
    (arr: BankingDetailListTableData[]) =>
      arr.map(
        ({ draw, deposit }: { draw: string | Null; deposit: string | Null }) =>
          pipe(Money$.of(draw || deposit || "0"), (amount) =>
            Money$.negateWhen(amount, () => Boolean(draw)),
          ),
      ),
    Money$.sum,
  );
}

// Table Component
function BankingDetailTable({ table }: BankingDetailTableProps) {
  return (
    <div className={styles.tableContainer}>
      <TableTop table={table} />
      <EnderTableTanstackV3 table={table} maxHeight={TABLE_CONFIG.MAX_HEIGHT} />
    </div>
  );
}

// Custom Hook for Actions
function useBankingDetailActions(
  selectedRows: BankingDetailListTableData[],
  options: {
    onSuccess: () => void;
    onClose: () => void;
    table: UseTableReturn<
      BankingDetailListTableData,
      BankingDetailsListTableMeta
    >;
  },
) {
  const queryClient = useQueryClient();
  const { onSuccess, onClose, table } = options;

  const handleSubmitForceExclude = useCallback(async () => {
    if (
      !selectedRows.every((row) => isEnderBatchItem(row.transactionDetails))
    ) {
      fail(ERRORS.INVALID_TRANSACTION);
    }

    const moneyTransferIds = selectedRows.flatMap(
      (row) => row.transactionDetails.moneyTransferIds,
    );
    const bankAccountId = selectedRows[0].transactionDetails.bankAccountId;

    await PaymentsAPI.removeMoneyTransfersFromBanking({
      bankAccountId,
      moneyTransferIds,
    });

    onSuccess();
    showSuccessNotification({ message: "Transactions Excluded" });
    await queryClient.invalidateQueries([
      "BankingAPI.getBankAccount",
      "BankingAPI.getTransactions",
    ]);
    table.resetRowSelection();
    onClose();
  }, [selectedRows, onSuccess, onClose, queryClient, table]);

  const handleSubmitForceReconcile = useCallback(
    async (values: BankingDetailListFormValues) => {
      const bankAccountId = selectedRows[0].enderBatch?.bankAccountId;
      if (P.isNullable(bankAccountId)) {
        throw new Error(ERRORS.MISSING_BANK_ACCOUNT);
      }

      try {
        const matches = selectedRows
          .map((row) => ({
            description: values.memo,
            enderBatchId: row.enderBatch?.id,
          }))
          .filter(
            (match): match is { enderBatchId: EnderId; description: string } =>
              !P.isNullable(match.enderBatchId),
          );

        await BankingAPI.createForcedMatches({
          bankAccountId,
          matches,
        });

        showSuccessNotification({
          message: NOTIFICATIONS.SUCCESS(selectedRows.length),
        });
        onSuccess();
        await queryClient.invalidateQueries([
          "BankingAPI.getBankAccount",
          "BankingAPI.getTransactions",
        ]);
        table.resetRowSelection();
      } catch (err) {
        fail(err);
      } finally {
        onClose();
      }
    },
    [selectedRows, onSuccess, onClose, queryClient, table],
  );

  return {
    handleSubmitForceExclude,
    handleSubmitForceReconcile,
  };
}

// Main Component
function BankingDetailList({
  getBankTransactions,
  table,
}: BankingDetailListProps) {
  const [manualReconcileModal, manualReconcileModalHandlers] = useBoolean();
  const [manualExcludeModal, manualExcludeModalHandlers] = useBoolean();
  const { hasPermissions } = useContext(UserContext);

  const permissions = {
    canManuallyExclude: hasPermissions(
      FunctionalPermissionEnum.EXCLUDE_FROM_BANK_REC,
    ),
    canManuallyReconcile: hasPermissions(
      FunctionalPermissionEnum.MANUAL_BANK_REC,
    ),
  };

  const selectedRows = table
    .getSelectedRowModel()
    .rows.map(({ original }) => original);

  const selectedRowsTotalAmount = useMemo(
    () => getSelectedRowsTotalAmount(selectedRows),
    [selectedRows],
  );

  const { handleSubmitForceExclude, handleSubmitForceReconcile } =
    useBankingDetailActions(selectedRows, {
      onClose: () => {
        manualReconcileModalHandlers.setFalse();
        manualExcludeModalHandlers.setFalse();
      },
      onSuccess: getBankTransactions,
      table,
    });

  return (
    <>
      <BankingDetailHeader
        selectedRows={selectedRows}
        selectedRowsTotalAmount={selectedRowsTotalAmount}
        onExclude={manualExcludeModalHandlers.setTrue}
        onReconcile={manualReconcileModalHandlers.setTrue}
        permissions={permissions}
      />
      <BankingDetailTable table={table} />
      <ReconcileModal
        isOpen={manualReconcileModal}
        onClose={manualReconcileModalHandlers.setFalse}
        selectedRows={selectedRows}
        totalAmount={selectedRowsTotalAmount}
        onSubmit={handleSubmitForceReconcile}
      />
      <ExcludeModal
        isOpen={manualExcludeModal}
        onClose={manualExcludeModalHandlers.setFalse}
        selectedRows={selectedRows}
        totalAmount={selectedRowsTotalAmount}
        onSubmit={handleSubmitForceExclude}
      />
    </>
  );
}

export { BankingDetailList, BankingDetailTable };
