import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CustomFactorFactorOutputTypeValues = [
  "STRING",
  "MONEY",
  "NUMBER",
  "DATE",
  "BOOLEAN",
  "ENUM",
] as const;

const CustomFactorFactorOutputTypeEffectSchema = Schema.Literal(
  ...CustomFactorFactorOutputTypeValues,
);

type CustomFactorFactorOutputType = Schema.Schema.Type<
  typeof CustomFactorFactorOutputTypeEffectSchema
>;

const CustomFactorFactorOutputTypeEnum = castEnum(
  CustomFactorFactorOutputTypeEffectSchema,
);

function randomCustomFactorFactorOutputType(): CustomFactorFactorOutputType {
  return rand(CustomFactorFactorOutputTypeValues);
}

export {
  CustomFactorFactorOutputTypeEffectSchema,
  CustomFactorFactorOutputTypeEnum,
  CustomFactorFactorOutputTypeValues,
  randomCustomFactorFactorOutputType,
};
export type { CustomFactorFactorOutputType };
