import {
  IconDownload,
  IconFileDescription,
  IconTrash,
  IconUpload,
} from "@tabler/icons-react";
import { Array as A } from "effect";
import { useCallback, useContext, useState } from "react";

import { uploadFilesDirect } from "@ender/shared/api/files";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Instant$, randomEnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify, Overflow, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { InstantDisplay } from "@ender/shared/ds/instant-display";
import { Stack } from "@ender/shared/ds/stack";
import { FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { GetFilesResponseFileResponse } from "@ender/shared/generated/ender.api.files.response";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useListState } from "@ender/shared/hooks/use-list-state";
import { usePagination } from "@ender/shared/hooks/use-pagination";
import { EnderPagination } from "@ender/shared/ui/ender-pagination";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { RightRail } from "@ender/shared/ui/right-rail";
import { fail } from "@ender/shared/utils/error";
import { downloadBlobFromUrl } from "@ender/shared/utils/general";
import { pluralize } from "@ender/shared/utils/string";
import { renderPrivateContact } from "@ender/shared/utils/user";

import styles from "./ender-documents-table.module.scss";

type EnderDocumentsTableProps = {
  modelId: EnderId;
  modelType: ModelType;
  documents: GetFilesResponseFileResponse[];
  onUploadSuccess: () => void;
  onDeleteDocument: (document: GetFilesResponseFileResponse) => Promise<void>;
};

function EnderDocumentsTable({
  modelId,
  modelType,
  documents,
  onUploadSuccess,
  onDeleteDocument,
}: EnderDocumentsTableProps) {
  const { user, hasPermissions } = useContext(UserContext);
  const [
    isUploadModalOpen,
    { setTrue: openUploadModal, setFalse: closeUploadModal },
  ] = useBoolean(false);
  const { activePage, setActivePage, totalPages, visibleItems } = usePagination(
    {
      items: documents,
      maxVisible: 10,
    },
  );
  const confirmation = useConfirmationContext();

  const handleDeleteDocument = useCallback(
    async (document: GetFilesResponseFileResponse) => {
      await confirmation({
        title: "Delete Document",
        content: `Are you sure you want to remove "${document?.name}"?`,
        confirmButtonLabel: "Delete",
      });
      await onDeleteDocument(document);
    },
    [confirmation, onDeleteDocument],
  );

  const [files, fileHandlers] = useListState<{ uuid: string; file: File }>([]);
  const [loading, setLoading] = useState(false);

  async function handleUploadFiles() {
    setLoading(true);
    try {
      await uploadFilesDirect({
        asMessage: false,
        files: files.map(({ file }) => file),
        modelId,
        modelType,
        userId: user.id,
      });
      onUploadSuccess();
    } catch (err) {
      fail(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Stack>
        <Group justify={Justify.between}>
          <Group spacing={Spacing.sm}>
            <IconFileDescription
              color={EnderThemeColorEnum.SLATE_600}
              size={24}
            />
            <H2>Documents</H2>
          </Group>
          <ActionIcon
            tooltip="Upload Documents"
            onClick={openUploadModal}
            aria-label="Upload Documents">
            <IconUpload />
          </ActionIcon>
        </Group>

        {A.isEmptyArray(documents) ? (
          <Text weight={FontWeight.medium}>
            There are no documents to display.
          </Text>
        ) : (
          <>
            <EnderTable>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Date Uploaded</th>
                  <th>Uploaded By</th>
                  <th className={styles.rightColumn}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {visibleItems.map((document) => (
                  <tr key={document.id}>
                    <td>{document.name}</td>
                    <td>
                      <InstantDisplay value={Instant$.of(document.timestamp)} />
                    </td>
                    <td>
                      {document.author?.name
                        ? renderPrivateContact(document.author)
                        : "Anonymous"}
                    </td>
                    <td className={styles.rightColumn}>
                      <Group spacing={Spacing.none}>
                        <ActionIcon
                          variant={ButtonVariant.transparent}
                          tooltip={
                            hasPermissions(
                              FunctionalPermissionEnum.DELETE_DOCUMENTS,
                            )
                              ? "Delete Document"
                              : "You do not have permission to delete documents"
                          }
                          disabled={
                            !hasPermissions(
                              FunctionalPermissionEnum.DELETE_DOCUMENTS,
                            )
                          }
                          onClick={() => handleDeleteDocument(document)}>
                          <IconTrash />
                        </ActionIcon>
                        <ActionIcon
                          variant={ButtonVariant.transparent}
                          onClick={() =>
                            downloadBlobFromUrl(document.s3Url, document.name)
                          }>
                          <IconDownload />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </EnderTable>
            {totalPages > 1 && (
              <EnderPagination
                page={activePage}
                total={totalPages}
                onChange={setActivePage}
              />
            )}
          </>
        )}
      </Stack>
      <RightRail
        title={`Upload ${ModelTypeEnum[modelType]} ${pluralize("Document", documents?.length)}`}
        opened={isUploadModalOpen}
        onClose={closeUploadModal}>
        <Stack>
          <Stack spacing={Spacing.none} overflow={Overflow.auto}>
            {files.map(({ uuid, file }, index) => (
              <Tuple
                label={file.name}
                value={
                  <ActionIcon
                    label="Remove"
                    onClick={() => fileHandlers.remove(index)}>
                    <IconTrash />
                  </ActionIcon>
                }
                key={uuid}
              />
            ))}
          </Stack>
          <FileInput
            value={[]}
            onChange={(uploadedFiles) =>
              fileHandlers.append(
                ...uploadedFiles.map((file) => ({
                  file,
                  uuid: randomEnderId(),
                })),
              )
            }
          />
          <Group justify={Justify.end}>
            <Button onClick={handleUploadFiles} loading={loading}>
              Upload
            </Button>
          </Group>
        </Stack>
      </RightRail>
    </>
  );
}

export { EnderDocumentsTable };
