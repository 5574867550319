import { useQuery } from "@tanstack/react-query";

import { FeatureFlagAPI } from "@ender/shared/generated/ender.api.misc";
import type { FeatureFlagFlagKey } from "@ender/shared/generated/ender.model.misc";

type UseGetFeatureFlagValueResult = {
  enabled: boolean | undefined;
  isLoading: boolean;
};

function useGetFeatureFlagValue(
  flagKey: FeatureFlagFlagKey,
): UseGetFeatureFlagValueResult {
  const { data, isLoading } = useQuery({
    queryKey: ["FeatureFlagAPI.getFeatureFlagValue", { flagKey }] as const,
    queryFn: ({ queryKey: [, params] }) =>
      FeatureFlagAPI.getFeatureFlagValue(params),
  });

  return { enabled: data?.enabled, isLoading };
}

export { useGetFeatureFlagValue };
