import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CatalogLineItemQuantityTypeValues = [
  "SINGLE",
  "MULTIPLE",
  "SQFT",
  "SQYD",
  "FEET",
  "PER_SQUARE",
  "PER_YARD",
  "QUOTE",
] as const;

const CatalogLineItemQuantityTypeEffectSchema = Schema.Literal(
  ...CatalogLineItemQuantityTypeValues,
);

type CatalogLineItemQuantityType = Schema.Schema.Type<
  typeof CatalogLineItemQuantityTypeEffectSchema
>;

const CatalogLineItemQuantityTypeEnum = castEnum(
  CatalogLineItemQuantityTypeEffectSchema,
);

function randomCatalogLineItemQuantityType(): CatalogLineItemQuantityType {
  return rand(CatalogLineItemQuantityTypeValues);
}

export {
  CatalogLineItemQuantityTypeEffectSchema,
  CatalogLineItemQuantityTypeEnum,
  CatalogLineItemQuantityTypeValues,
  randomCatalogLineItemQuantityType,
};
export type { CatalogLineItemQuantityType };
