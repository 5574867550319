import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UtilitiesPolicyResponsiblePartyValues = ["TENANT", "LANDLORD"] as const;

const UtilitiesPolicyResponsiblePartyEffectSchema = Schema.Literal(
  ...UtilitiesPolicyResponsiblePartyValues,
);

type UtilitiesPolicyResponsibleParty = Schema.Schema.Type<
  typeof UtilitiesPolicyResponsiblePartyEffectSchema
>;

const UtilitiesPolicyResponsiblePartyEnum = castEnum(
  UtilitiesPolicyResponsiblePartyEffectSchema,
);

function randomUtilitiesPolicyResponsibleParty(): UtilitiesPolicyResponsibleParty {
  return rand(UtilitiesPolicyResponsiblePartyValues);
}

export {
  randomUtilitiesPolicyResponsibleParty,
  UtilitiesPolicyResponsiblePartyEffectSchema,
  UtilitiesPolicyResponsiblePartyEnum,
  UtilitiesPolicyResponsiblePartyValues,
};
export type { UtilitiesPolicyResponsibleParty };
