/**
 * HTTP Method: GET
 * Pathname: /paymentRouting
 * @function getPaymentRouting
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetPaymentRoutingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPaymentRoutingResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetPaymentRoutingResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetPaymentRoutingSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetPaymentRoutingPayload =
  PaymentsAPIGetPaymentRoutingSearchParams;

function getPaymentRoutingUnsafeEffect(
  payload: PaymentsAPIGetPaymentRoutingPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPaymentRoutingResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPaymentRoutingResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/paymentRouting",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPaymentRouting(
  payload: PaymentsAPIGetPaymentRoutingPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPaymentRoutingResponse> {
  return F.pipe(
    payload,
    getPaymentRoutingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPaymentRouting };
export type {
  PaymentsAPIGetPaymentRoutingPayload,
  PaymentsAPIGetPaymentRoutingSearchParams,
};
