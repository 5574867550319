import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ReconTypeFilterMatchTypeValues = ["MATCHED"] as const;

const ReconTypeFilterMatchTypeEffectSchema = Schema.Literal(
  ...ReconTypeFilterMatchTypeValues,
);

type ReconTypeFilterMatchType = Schema.Schema.Type<
  typeof ReconTypeFilterMatchTypeEffectSchema
>;

const ReconTypeFilterMatchTypeEnum = castEnum(
  ReconTypeFilterMatchTypeEffectSchema,
);

function randomReconTypeFilterMatchType(): ReconTypeFilterMatchType {
  return rand(ReconTypeFilterMatchTypeValues);
}

export {
  randomReconTypeFilterMatchType,
  ReconTypeFilterMatchTypeEffectSchema,
  ReconTypeFilterMatchTypeEnum,
  ReconTypeFilterMatchTypeValues,
};
export type { ReconTypeFilterMatchType };
