import { useMutation, useQuery } from "@tanstack/react-query";
import * as P from "effect/Predicate";

import type { Undefined } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { PaymentsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import { PaymentsAPI } from "@ender/shared/generated/ender.api.accounting";
import { DwollaTransferDwollaTransferStatusEnum } from "@ender/shared/generated/ender.model.payments";
import { Color } from "@ender/shared/utils/theming";

type LedgerCancelInflightACHButtonProps = {
  moneyTransferId?: EnderId | Undefined;
};

function LedgerCancelInflightACHButton(
  props: LedgerCancelInflightACHButtonProps,
) {
  const confirmation = useConfirmationContext();
  const { moneyTransferId } = props;

  const { data } = useQuery({
    enabled: P.isNotUndefined(moneyTransferId),
    queryFn: async ({ signal }) => {
      if (P.isNotUndefined(moneyTransferId)) {
        return await PaymentsMiddleLayerAPI.getDwollaTransferStatus(
          {
            moneyTransferId: moneyTransferId,
          },
          { signal },
        );
      }
    },
    queryKey: [
      "PaymentsMiddleLayerAPI.getDwollaTransferStatus",
      moneyTransferId,
    ] as const,
  });

  const { mutateAsync: asyncCancelACHPayment } = useMutation({
    mutationFn: async () => {
      if (P.isUndefined(moneyTransferId)) {
        return;
      }
      return await PaymentsAPI.cancelACHPayment({
        moneyTransferId: moneyTransferId,
      });
    },
    mutationKey: ["PaymentsAPI.cancelACHPayment", moneyTransferId] as const,
  });

  async function handleCancelACHPayment() {
    if (
      P.isNotUndefined(data) &&
      data.isCancellable &&
      (data.status === DwollaTransferDwollaTransferStatusEnum.PENDING ||
        data.status === DwollaTransferDwollaTransferStatusEnum.TRANSFERRING)
    ) {
      await confirmation({
        cancelButtonLabel: "No",
        confirmButtonLabel: "Yes, Cancel",
        content:
          "This action will cancel the in-flight payment by the tenant. The tenant will have to make a new payment. Are you sure you want to cancel this in-flight transaction? Please continue with this cancellation only if the tenant has requested this.",
      });
      await asyncCancelACHPayment();
    }
  }

  return (
    <Button
      onClick={handleCancelACHPayment}
      variant={ButtonVariant.outlined}
      color={Color.red}
      disabled={
        !data?.isCancellable ||
        data?.status === DwollaTransferDwollaTransferStatusEnum.COMPLETE
      }
      disabledTooltip="The payment has already been deposited into the bank and cannot be cancelled">
      Cancel ACH
    </Button>
  );
}

export { LedgerCancelInflightACHButton };
