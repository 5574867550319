import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyAreaFoundationTypeValues = [
  "PIER_AND_BEAM",
  "SLAB",
  "BASEMENT",
  "MANUFACTURED",
] as const;

const PropertyAreaFoundationTypeEffectSchema = Schema.Literal(
  ...PropertyAreaFoundationTypeValues,
);

type PropertyAreaFoundationType = Schema.Schema.Type<
  typeof PropertyAreaFoundationTypeEffectSchema
>;

const PropertyAreaFoundationTypeEnum = castEnum(
  PropertyAreaFoundationTypeEffectSchema,
);

function randomPropertyAreaFoundationType(): PropertyAreaFoundationType {
  return rand(PropertyAreaFoundationTypeValues);
}

export {
  PropertyAreaFoundationTypeEffectSchema,
  PropertyAreaFoundationTypeEnum,
  PropertyAreaFoundationTypeValues,
  randomPropertyAreaFoundationType,
};
export type { PropertyAreaFoundationType };
