import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { IconTemplate } from "@tabler/icons-react";
import { Option as O, pipe } from "effect";
import * as A from "effect/Array";
import { forwardRef, useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";

import { Form, FormField, useForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Badge } from "@ender/shared/ds/badge";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { InputWrapper } from "@ender/shared/ds/input";
import {
  Menu,
  MenuButton,
  MenuContent,
  MenuTrigger,
} from "@ender/shared/ds/menu";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { TemplatesAPI } from "@ender/shared/generated/ender.api.documents";
import type { ListTemplatesResponse } from "@ender/shared/generated/ender.api.documents.response";
import { RichTextEditor } from "@ender/shared/ui/rich-text-editor";
import { fail } from "@ender/shared/utils/error";
import { pluralize } from "@ender/shared/utils/string";

import type {
  ApplicationsAnnouncementFormInput,
  ApplicationsAnnouncementFormOutput,
} from "./applications-announcement-form.types";
import { ApplicationsAnnouncementFormSchema } from "./applications-announcement-form.types";

type ApplicationsAnnouncementFormProps = {
  isLoadingRecipients?: boolean;
  isSubmitting?: boolean;
  onSubmit: (values: ApplicationsAnnouncementFormOutput) => void;
  recipients: string[];
  templates: ListTemplatesResponse[];
};

const ApplicationsAnnouncementForm = forwardRef<
  HTMLDivElement,
  ApplicationsAnnouncementFormProps
>(function ApplicationsAnnouncementForm(props, ref) {
  const {
    recipients,
    onSubmit,
    isLoadingRecipients = false,
    isSubmitting = false,
    templates,
  } = props;

  const form = useForm<
    ApplicationsAnnouncementFormInput,
    unknown,
    ApplicationsAnnouncementFormOutput
  >({
    defaultValues: {
      subject: "",
      body: "",
      templateId: O.none(),
    },
    mode: "onSubmit",
    resolver: effectTsResolver(ApplicationsAnnouncementFormSchema),
  });

  const onSelectTemplate = useCallback(
    async (templateId: EnderId) => {
      try {
        const template = await TemplatesAPI.getTemplate({ templateId });

        form.setValue("templateId", O.some(templateId));
        form.setValue("body", template.body);
      } catch (e) {
        fail(e);
      }
    },
    [form],
  );

  const templateName = useMemo(
    () =>
      pipe(
        form.getValues("templateId"),
        O.map(
          (templateId) =>
            templates.find((template) => template.id === templateId)?.name,
        ),
        O.getOrElse(() => "UNNAMED"),
      ),
    [form, templates],
  );

  const bodyValue = useWatch({ control: form.control, name: "body" });
  const templateIdValue = useWatch({
    control: form.control,
    name: "templateId",
  });

  return (
    <Stack ref={ref}>
      <Text>Messages will be sent individually</Text>
      <Text>Recipients:</Text>
      <Skeleton visible={isLoadingRecipients}>
        <Group spacing={Spacing.xs}>
          {recipients.map((recipient) => (
            <Badge key={recipient}>{recipient}</Badge>
          ))}
        </Group>
      </Skeleton>
      <Form form={form} onSubmit={onSubmit}>
        <Stack>
          <FormTextInput form={form} name="subject" label="Subject" />
          <FormField name="body" form={form}>
            {({ field, error }) => (
              // TODO: create proper rich text form field component https://ender-1337.atlassian.net/browse/ENDER-23435
              <InputWrapper
                inputId=""
                labelId=""
                errorId=""
                label="Body"
                error={error}
                {...field}>
                <Group justify={Justify.center}>
                  <RichTextEditor
                    value={bodyValue}
                    onChange={(value) => form.setValue("body", value)}
                    options={{
                      autoresize: true,
                      max_height: 400,
                      width: "100%",
                    }}
                  />
                </Group>
              </InputWrapper>
            )}
          </FormField>
          {O.isSome(templateIdValue) && (
            <Text color="slate-500" size={FontSize.sm}>
              Currently using the template "{templateName}".{" "}
              <Button
                variant="transparent"
                onClick={() => {
                  form.setValue("templateId", O.none());
                  form.setValue("body", "");
                }}>
                Click here to clear the template.
              </Button>
            </Text>
          )}
          <Group justify={Justify.between}>
            <Menu>
              <MenuTrigger>
                <ActionIcon
                  variant={ButtonVariant.transparent}
                  label="Select Template"
                  disabled={A.isEmptyArray(templates)}
                  disabledTooltip="No templates available">
                  <IconTemplate />
                </ActionIcon>
              </MenuTrigger>
              <MenuContent align="start">
                {templates?.map((template) => (
                  <MenuButton
                    key={template.id}
                    onClick={() => onSelectTemplate(template.id)}>
                    {template.name}
                  </MenuButton>
                ))}
              </MenuContent>
            </Menu>
            <Button type="submit" loading={isSubmitting}>
              Send Message to {recipients.length}{" "}
              {pluralize("Applicant", recipients.length)}
            </Button>
          </Group>
        </Stack>
      </Form>
    </Stack>
  );
});

export { ApplicationsAnnouncementForm };

export type { ApplicationsAnnouncementFormProps };
