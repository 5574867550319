import { useMutation, useQuery } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P } from "effect";
import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import {
  FormSearchInput,
  hydrateProperty,
  searchProperties,
} from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { FormTimeInput } from "@ender/shared/ds/time-input";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetLeadResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { SelectOption } from "@ender/shared/generated/ender.model.forms.inputs";
import { LeadLeadSourceEnum } from "@ender/shared/generated/ender.model.leasing";
import { fail } from "@ender/shared/utils/error";
import { isMultiple } from "@ender/shared/utils/is";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import type { ProspectFormOutput } from "./create-update-prospect-form.types";
import {
  ProspectFormSchema,
  getInitialFormValues,
  transformFormValues,
} from "./create-update-prospect-form.types";

const sources: SelectOption[] = [
  { label: "Apartments.com", value: LeadLeadSourceEnum.APARTMENTS_COM },
  { label: "Zillow", value: LeadLeadSourceEnum.ZILLOW },
  { label: "Company Website", value: LeadLeadSourceEnum.COMPANY_WEBSITE },
  { label: "Zumper", value: LeadLeadSourceEnum.ZUMPER },
  { label: "Other", value: LeadLeadSourceEnum.OTHER },
];

type CreateUpdateProspectFormProps = {
  prospect?: GetLeadResponse;
  onSuccess?: (prospectId?: EnderId) => void;
};

function CreateUpdateProspectForm({
  prospect,
  onSuccess = F.constVoid,
}: CreateUpdateProspectFormProps) {
  const form = useEffectSchemaForm({
    schema: ProspectFormSchema,
    defaultValues: getInitialFormValues(prospect),
  });

  const { mutateAsync: updateLead, isLoading: isUpdatingLead } = useMutation({
    mutationKey: ["LeasingAPI.updateLead"],
    mutationFn: LeasingAPI.updateLead,
  });

  const { mutateAsync: addLead, isLoading: isAddingLead } = useMutation({
    mutationKey: ["LeasingAPI.addLead"],
    mutationFn: LeasingAPI.addLead,
  });

  async function handleSubmit(values: ProspectFormOutput) {
    let retId: EnderId | undefined;

    const payload = transformFormValues(values);

    try {
      if (P.isNotNullable(prospect)) {
        await updateLead({
          leadId: prospect.leadId,
          ...payload,
        });
        showSuccessNotification({
          message: "Prospect Updated",
        });
        onSuccess(prospect.leadId);
      } else {
        const resp = await addLead(payload);
        retId = resp.id;
        showSuccessNotification({
          message: "New Prospect Added",
        });
      }
    } catch (err) {
      fail(err);
      return;
    }

    onSuccess(retId);
  }

  const [formPropertyId] = useWatch({
    control: form.control,
    name: ["propertyId"],
  });

  const { data: unitOptions = [] } = useQuery({
    queryKey: [
      "PropertiesAPI.getUnitsForProperty",
      { propertyId: formPropertyId.pipe(O.getOrUndefined) },
    ] as const,
    enabled: O.isSome(formPropertyId),
    queryFn: ({ queryKey: [, params] }) => {
      const { propertyId } = params;
      if (P.isNotUndefined(propertyId)) {
        return PropertiesAPI.getUnitsForProperty({ propertyId });
      }
      return [];
    },
    select: (data) =>
      data.map((unit) => ({
        value: unit.id,
        label: unit.name,
      })),
  });

  const { setValue } = form;

  // Can be replaced by form onChange events
  useEffect(() => {
    setValue("unitCount", unitOptions.length);
  }, [setValue, unitOptions]);

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        {P.isNullable(prospect) && (
          <Stack>
            <H3>Property & Unit Info</H3>
            <FormSearchInput<EnderId, typeof form>
              label="Property"
              name="propertyId"
              placeholder="Search for property"
              modelType={ModelTypeEnum.PROPERTY}
              search={searchProperties}
              form={form}
              hydrate={hydrateProperty}
            />
            {isMultiple(unitOptions) && (
              <FormSelect<EnderId, typeof form>
                label="Unit"
                placeholder="Search for unit"
                disabled={O.isNone(formPropertyId)}
                data={unitOptions}
                form={form}
                name="unitId"
              />
            )}
          </Stack>
        )}
        <Stack>
          <H3>Prospect Info</H3>
          <FormTextInput label="First Name" name="firstName" form={form} />
          <FormTextInput label="Last Name" name="lastName" form={form} />
          <FormTextInput label="Email" name="email" form={form} />
          <FormPhoneInput label="Phone" name="phone" form={form} />
          <FormDateInput
            label="Requested Move-in"
            name="moveInDate"
            form={form}
          />
          <Group grow>
            <FormDateInput
              label="Follow-up Date"
              minDate={LocalDate$.today()}
              name="followUpDate"
              form={form}
            />
            <FormTimeInput
              label="Follow-up Time"
              name="followUpTime"
              form={form}
            />
          </Group>
          <FormSelect label="Source" name="source" form={form} data={sources} />
          {P.isNotNullable(prospect) ? (
            <Group justify={Justify.between}>
              <Button
                disabled={!form.formState.isDirty || isUpdatingLead}
                onClick={() => form.reset()}>
                Clear Changes
              </Button>
              <Button
                type="submit"
                disabled={!form.formState.isDirty}
                loading={isUpdatingLead}>
                Save Prospect
              </Button>
            </Group>
          ) : (
            <Group justify={Justify.end}>
              <Button type="submit" loading={isAddingLead}>
                Create New Prospect
              </Button>
            </Group>
          )}
        </Stack>
      </Stack>
    </Form>
  );
}

export { CreateUpdateProspectForm };
