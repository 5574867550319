import { IconEdit, IconTrash } from "@tabler/icons-react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { Modal } from "@ender/shared/ds/modal";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import type { CheckReceiptsTableRow } from "./check-receipts-table.types";
import { EditReceiptForm } from "./edit-receipt-form";

import styles from "./check-receipts-table.module.css";

type EditButtonProps = {
  onSuccess: () => void;
  receipt: CheckReceiptsTableRow;
};

function EditButton({ onSuccess, receipt }: EditButtonProps) {
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean(false);

  return (
    <span className={styles.actionsEditButton}>
      <Modal title="" opened={isModalOpen} onClose={closeModal}>
        <EditReceiptForm
          receipt={receipt}
          onSuccess={() => {
            closeModal();
            onSuccess();
          }}
        />
      </Modal>
      <span className="cursor edit" data-name="edit" onClick={openModal}>
        <IconEdit color={EnderThemeColorEnum.PRIMARY} />
      </span>
    </span>
  );
}

function TrashButton() {
  return (
    <Tooltip label="Ender does not support deleting tenant or general ledger data. We are working to replace all “remove” actions with reversal workflows. In the meantime, please make manual updates to reflect any changes.">
      <IconTrash color={EnderThemeColorEnum.GRAY_500} />
    </Tooltip>
  );
}

export { EditButton, TrashButton };
