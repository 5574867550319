import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";

import { openCheck, openCheckForBatch } from "../print-check/open-check";

function ViewCheckButton({ moneyTransferId }: { moneyTransferId: EnderId }) {
  return (
    <Button
      variant={ButtonVariant.outlined}
      onClick={() => openCheck(moneyTransferId)}>
      View Check
    </Button>
  );
}

function ViewCheckButtonForBatch({ checkBatchId }: { checkBatchId: EnderId }) {
  return (
    <Button
      variant={ButtonVariant.outlined}
      onClick={() => openCheckForBatch(checkBatchId)}>
      View Check
    </Button>
  );
}

export { ViewCheckButton, ViewCheckButtonForBatch };
