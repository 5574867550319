import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { NumberInputProps } from "./shared-ds-number-input";
import { NumberInput } from "./shared-ds-number-input";

type FormNumberInputProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<NumberInputProps, Form, Name>;

function FormNumberInput<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormNumberInputProps<Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field, error }) => {
        return <NumberInput {...rest} {...field} error={error} />;
      }}
    </FormField>
  );
}

export { FormNumberInput };

export type { FormNumberInputProps };
