// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { zodResolver } from "@mantine/form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Option as O, Predicate as P, pipe } from "effect";
import { useCallback, useState } from "react";
import { z } from "zod";

import { AccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import type { Undefined } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId, Money } from "@ender/shared/core";
import { EnderIdSchema, LocalDate$, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { DateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { useForm } from "@ender/shared/forms/hooks/general";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import type { TenantLedgerAPICreateLeasePayoutInvoicesPayload } from "@ender/shared/generated/ender.api.accounting";
import {
  GeneralLedgerAPI,
  TenantLedgerAPI,
} from "@ender/shared/generated/ender.api.accounting";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import {
  AccountingPeriodAccountingModuleEnum,
  CategoryFlagEnum,
} from "@ender/shared/generated/ender.model.accounting";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";
import {
  CurrencySchema,
  LocalDateZodSchema,
  OptionSchema,
} from "@ender/shared/utils/zod";
import { TenantAllocationsV2 } from "@ender/widgets/finance/tenant-allocations";

import { useGetCurrentLease } from "../../hooks";

function getLeaseTenantsDisplayName(
  lease: LeaseSerializerLeaseResponse | Undefined,
): string {
  if (P.isNullable(lease) || A.isEmptyArray(lease.contacts)) {
    return "";
  }
  const firstTenantName = lease.contacts[0].name;
  const numberOfTenants = A.length(lease.contacts);
  return pipe(
    numberOfTenants,
    (n) => (n > 1 ? O.some(n - 1) : O.none()), // number of Additional Tenants when more than 1
    O.map((n) => ` +${n}`),
    O.getOrElse(() => ""),
    (suffix) => `${firstTenantName}${suffix}`,
  );
}

const RefundTenantFormValuesSchema = z
  .object({
    accountingPeriod: OptionSchema(
      z.object({
        id: EnderIdSchema,
      }),
    ),
    amount: OptionSchema(CurrencySchema),
    refundRecipients: z.array(
      z.object({
        amount: OptionSchema(CurrencySchema),
        id: EnderIdSchema,
        name: z.string(),
      }),
    ),
    transactionDate: OptionSchema(LocalDateZodSchema).refine(
      (val) => O.isSome(val),
      {
        message: "Transaction Date is required",
      },
    ),
  })
  .refine(
    (val) =>
      O.isSome(val.amount) ||
      val.refundRecipients.every((recipient) => O.isSome(recipient.amount)),
  );

type RefundTenantFormInput = z.input<typeof RefundTenantFormValuesSchema>;
type RefundTenantFormOutput = z.output<typeof RefundTenantFormValuesSchema>;

const getInitialFormValues = (
  refundRecipients: { id: EnderId; name: string }[],
) => {
  return {
    accountingPeriod: O.none(),
    amount: O.some(Money$.zero()),
    refundRecipients: refundRecipients.map((recipient) => ({
      amount: O.some(Money$.zero()),
      id: recipient.id,
      name: recipient.name,
    })),
    transactionDate: O.some(LocalDate$.today()),
  };
};

type RefundTenantFormProps = {
  onSuccess: () => void;
};

function RefundTenantForm({ onSuccess }: RefundTenantFormProps) {
  const { lease } = useGetCurrentLease();
  const property = lease?.property;
  const [isForAllTenants, setIsForAllTenants] = useState(true);
  const confirmation = useConfirmationContext();

  const initialValues = getInitialFormValues(lease?.contacts ?? []);
  const form = useForm<RefundTenantFormInput>({
    initialValues,
    validate: zodResolver(RefundTenantFormValuesSchema),
  });

  const payoutInvoicesWarnings = withWarningHandler(
    TenantLedgerAPI.createLeasePayoutInvoices,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );
  const { mutateAsync: payoutInvoices, isLoading } = useMutation({
    mutationKey: ["TenantLedgerAPI.createLeasePayoutInvoices"],
    mutationFn: async (
      values: TenantLedgerAPICreateLeasePayoutInvoicesPayload,
    ) => await payoutInvoicesWarnings(values),
  });

  const { data: tenantRefundClearingAccount } = useQuery({
    queryKey: ["GeneralLedgerAPI.getTxCategories"] as const,
    queryFn: () => {
      return GeneralLedgerAPI.getTxCategories({
        categoryFlags: [CategoryFlagEnum.TENANT_REFUND_CLEARING],
        keyword: "",
      });
    },
    select: (data) => data[0],
  });

  const handleOnSubmit = useCallback(
    async (formValues: RefundTenantFormOutput) => {
      if (P.isNullable(lease?.id)) {
        throw new Error("Should never happen: Lease ID is undefined");
      }

      const allTenantsAmountMoneyClassOrUndefined = pipe(
        formValues.amount,
        O.getOrElse(() => Money$.zero()),
      );
      const periodId = pipe(
        formValues.accountingPeriod,
        O.map((period) => period.id),
        O.getOrUndefined,
      );
      const transactionDate = pipe(
        formValues.transactionDate,
        O.map((date) => date.toJSON()),
        O.getOrThrowWith(
          () => new Error("Should never happen: Transaction date is undefined"),
        ),
      );
      const result = await payoutInvoices({
        ...(isForAllTenants && {
          allTenantsAmount: allTenantsAmountMoneyClassOrUndefined.toJSON(),
        }),
        isForMoveOut: false,
        leaseId: lease.id,
        ledgerDate: transactionDate,
        ...(periodId && { periodId }),
        userIdToAmount: isForAllTenants
          ? {}
          : formValues.refundRecipients.reduce(
              (acc, recipient) => {
                return {
                  ...acc,
                  [recipient.id]: pipe(
                    recipient.amount,
                    O.map((money) => money.toJSON()),
                    O.getOrThrow,
                  ),
                };
              },
              {} as Record<EnderId, Money>,
            ),
      });

      if (P.isNotNullable(result)) {
        showSuccessNotification({ message: "Refund successfully generated" });
        onSuccess();
      }
    },
    [onSuccess, lease?.id, payoutInvoices, isForAllTenants],
  );

  return (
    //TODO: convert to new form system https://ender-1337.atlassian.net/browse/ENDER-22433 2025-02-06
    // @ts-expect-error has obscure issue w transactionDate type but everything works fine
    <UnmanagedForm form={form} onSubmit={handleOnSubmit}>
      <Stack>
        <H1>Refund Tenant</H1>
        <Stack spacing={Spacing.none}>
          <Tuple label="Tenant" value={getLeaseTenantsDisplayName(lease)} />
          <Tuple label="Property" value={property?.name} />
          <Tuple
            label="GL Category"
            value={tenantRefundClearingAccount?.accountName}
          />
        </Stack>
        <DateInput
          label="Transaction Date"
          name="transactionDate"
          {...form.getInputProps("transactionDate")}
        />
        <AccountingPeriodSelector
          label="Accounting Period (optional)"
          name="accountingPeriod"
          periodType={AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE}
          {...form.getInputProps("accountingPeriod")}
        />
        <TenantAllocationsV2
          allTenantsAmount={form.values.amount}
          handleChangeAllTenantsAmount={(value) =>
            form.setValues({ amount: value })
          }
          handleChangeIsForAllTenants={setIsForAllTenants}
          handleCurrencyInputChange={(
            value,
            oldValue,
            refundRecipientsPosition,
          ) => {
            form.setValues({
              refundRecipients: form.values.refundRecipients.map(
                (recipient, index) => {
                  if (index === refundRecipientsPosition) {
                    return { ...recipient, amount: value };
                  }
                  return recipient;
                },
              ),
            });
          }}
          isForAllTenants={isForAllTenants}
          refundRecipients={form.values.refundRecipients}
        />
        <Group justify={Justify.end}>
          <Button type="submit" loading={isLoading}>
            Generate Invoice
          </Button>
        </Group>
      </Stack>
    </UnmanagedForm>
  );
}

export { RefundTenantForm };
