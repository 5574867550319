import { addLead } from "./add-lead";
import { addLeaseContact } from "./add-lease-contact";
import { addShowing } from "./add-showing";
import { addVehicle } from "./add-vehicle";
import { addVehicleToOwner } from "./add-vehicle-to-owner";
import { allowPartialPayments } from "./allow-partial-payments";
import { archiveAction } from "./archive-action";
import { archiveLease } from "./archive-lease";
import { archiveProspects } from "./archive-prospects";
import { archiveVehicle } from "./archive-vehicle";
import { cancelLease } from "./cancel-lease";
import { createLeaseWithTenants } from "./create-lease-with-tenants";
import { createListing } from "./create-listing";
import { createListingTemplate } from "./create-listing-template";
import { createPaymentPlan } from "./create-payment-plan";
import { creditSecurityDeposit } from "./credit-security-deposit";
import { deleteLease } from "./delete-lease";
import { deletePaymentPlan } from "./delete-payment-plan";
import { deleteVehicle } from "./delete-vehicle";
import { editLeaseExpectedMoveOutDate } from "./edit-lease-expected-move-out-date";
import { executeLease } from "./execute-lease";
import { getApplicationUrl } from "./get-application-url";
import { getFilteredLeadsWithProspects } from "./get-filtered-leads-with-prospects";
import { getLead } from "./get-lead";
import { getLeaseContacts } from "./get-lease-contacts";
import { getLeaseDetails } from "./get-lease-details";
import { getLeaseSecurityDeposits } from "./get-lease-security-deposits";
import { getListingDetailsByListingId } from "./get-listing-details-by-listing-id";
import { getListingTemplates } from "./get-listing-templates";
import { getMostRelevantLeaseForUnit } from "./get-most-relevant-lease-for-unit";
import { getPaymentPlans } from "./get-payment-plans";
import { getSSN } from "./get-s-s-n";
import { getSSNViewHistory } from "./get-s-s-n-view-history";
import { getSecurityDeposits } from "./get-security-deposits";
import { getShowings } from "./get-showings";
import { getTenantLease } from "./get-tenant-lease";
import { getVehicles } from "./get-vehicles";
import { getVehiclesForOwner } from "./get-vehicles-for-owner";
import { markMonthToMonth } from "./mark-month-to-month";
import { markSpam } from "./mark-spam";
import { moveOutComplete } from "./move-out-complete";
import { newLease } from "./new-lease";
import { postLateFee } from "./post-late-fee";
import { predictNextMessage } from "./predict-next-message";
import { removeLeaseContact } from "./remove-lease-contact";
import { removeLeaseExpectedMoveOutDate } from "./remove-lease-expected-move-out-date";
import { renewLease } from "./renew-lease";
import { saveSalesData } from "./save-sales-data";
import { searchLeaseChains } from "./search-lease-chains";
import { searchLeases } from "./search-leases";
import { searchListings } from "./search-listings";
import { searchProspects } from "./search-prospects";
import { sendApplicationUrl } from "./send-application-url";
import { setCategoryRules } from "./set-category-rules";
import { setLeaseContactRoles } from "./set-lease-contact-roles";
import { setName } from "./set-name";
import { setNegotiatingStatus } from "./set-negotiating-status";
import { setupLeasing } from "./setup-leasing";
import { unarchiveVehicle } from "./unarchive-vehicle";
import { updateLead } from "./update-lead";
import { updateLease } from "./update-lease";
import { updateLeaseActualMoveOutDate } from "./update-lease-actual-move-out-date";
import { updateLeaseActualMoveOutDateWithWarnings } from "./update-lease-actual-move-out-date-with-warnings";
import { updateListing } from "./update-listing";
import { updatePaymentPlan } from "./update-payment-plan";
import { updatePaymentPlanInstallments } from "./update-payment-plan-installments";
import { updateShowing } from "./update-showing";
import { updateVehicle } from "./update-vehicle";

const LeasingAPI = {
  addLead,
  addLeaseContact,
  addShowing,
  addVehicle,
  addVehicleToOwner,
  allowPartialPayments,
  archiveAction,
  archiveLease,
  archiveProspects,
  archiveVehicle,
  cancelLease,
  createLeaseWithTenants,
  createListing,
  createListingTemplate,
  createPaymentPlan,
  creditSecurityDeposit,
  deleteLease,
  deletePaymentPlan,
  deleteVehicle,
  editLeaseExpectedMoveOutDate,
  executeLease,
  getApplicationUrl,
  getFilteredLeadsWithProspects,
  getLead,
  getLeaseContacts,
  getLeaseDetails,
  getLeaseSecurityDeposits,
  getListingDetailsByListingId,
  getListingTemplates,
  getMostRelevantLeaseForUnit,
  getPaymentPlans,
  getSSN,
  getSSNViewHistory,
  getSecurityDeposits,
  getShowings,
  getTenantLease,
  getVehicles,
  getVehiclesForOwner,
  markMonthToMonth,
  markSpam,
  moveOutComplete,
  newLease,
  postLateFee,
  predictNextMessage,
  removeLeaseContact,
  removeLeaseExpectedMoveOutDate,
  renewLease,
  saveSalesData,
  searchLeaseChains,
  searchLeases,
  searchListings,
  searchProspects,
  sendApplicationUrl,
  setCategoryRules,
  setLeaseContactRoles,
  setName,
  setNegotiatingStatus,
  setupLeasing,
  unarchiveVehicle,
  updateLead,
  updateLease,
  updateLeaseActualMoveOutDate,
  updateLeaseActualMoveOutDateWithWarnings,
  updateListing,
  updatePaymentPlan,
  updatePaymentPlanInstallments,
  updateShowing,
  updateVehicle,
};

export { LeasingAPI };
