import { Predicate as P } from "effect";

import type { EnderId } from "@ender/shared/core";

function getInvoicePropertyIds(invoice?: {
  currentState: {
    allocationsByProperty: { propertyId: EnderId }[];
  };
}): EnderId[] {
  if (P.isNullable(invoice)) {
    return [];
  }

  const propertyIds =
    invoice?.currentState?.allocationsByProperty?.map(
      (allocation) => allocation.propertyId,
    ) ?? [];
  return Array.from(new Set(propertyIds));
}

export { getInvoicePropertyIds };
