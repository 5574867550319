import { IconPlus, IconUserSquare } from "@tabler/icons-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { z } from "zod";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  LeaseSerializerLeaseContact,
  LeaseSerializerLeaseResponse,
} from "@ender/shared/generated/ender.arch.serializer.leasing";
import { RightRail } from "@ender/shared/ui/right-rail";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { castEnum } from "@ender/shared/utils/zod";

import { AddLeaseContact } from "./add-lease-contact";
import { CreateUpdateLeaseContactForm } from "./create-update-lease-contact-form";
import { LeaseContactCard } from "./lease-contact-card";

const RightRailViewValues = ["ADD_CONTACT", "EDIT_CONTACT", "CLOSED"] as const;
const RightRailViewSchema = z.enum(RightRailViewValues);
type RightRailView = z.infer<typeof RightRailViewSchema>;

const RightRailViewEnum = castEnum<RightRailView>(RightRailViewSchema);

type LeaseContactsProps = {
  lease: LeaseSerializerLeaseResponse;
};

function LeaseContacts({ lease }: LeaseContactsProps) {
  const {
    data: contacts,
    isFetching: isContactsFetching,
    refetch: refetchContacts,
  } = useQuery({
    queryFn: ({ queryKey: [, params], signal }) =>
      LeasingAPI.getLeaseContacts(params, { signal }),
    queryKey: ["LeasingAPI.getLeaseContacts", { leaseId: lease.id }] as const,
  });

  const queryClient = useQueryClient();

  const [rightRailView, setRightRailView] = useState<RightRailView>(
    RightRailViewEnum.CLOSED,
  );
  const [selectedContact, setSelectedContact] = useState<
    LeaseSerializerLeaseContact | undefined
  >();

  const onAddContactClick = useCallback(() => {
    setRightRailView(RightRailViewEnum.ADD_CONTACT);
  }, []);

  const onContactAddedUpdated = useCallback(() => {
    setRightRailView(RightRailViewEnum.CLOSED);
    refetchContacts();
    queryClient.invalidateQueries([
      "LeasingAPI.getLeaseContacts",
      { leaseId: lease.id },
    ]);
    queryClient.invalidateQueries(["LeasingAPI.getLeaseContacts", lease.id]); // TODO: remove once context is gone gone
  }, [lease.id, queryClient, refetchContacts]);

  const closeRightRail = useCallback(() => {
    setRightRailView(RightRailViewEnum.CLOSED);
  }, []);

  const onEditContactClick = useCallback(
    (contact: LeaseSerializerLeaseContact) => {
      setSelectedContact(contact);
      setRightRailView(RightRailViewEnum.EDIT_CONTACT);
    },
    [],
  );

  const refetchLeaseContacts = async () => {
    await refetchContacts();
  };

  if (isContactsFetching) {
  }

  if (!contacts) {
    return null;
  }

  return (
    <>
      <RightRail
        title={convertSnakeCaseToTitleCase(rightRailView)}
        opened={rightRailView !== RightRailViewEnum.CLOSED}
        onClose={closeRightRail}>
        {rightRailView === RightRailViewEnum.ADD_CONTACT && (
          <AddLeaseContact
            lease={lease}
            onContactAdded={onContactAddedUpdated}
            onClose={closeRightRail}
          />
        )}
        {rightRailView === RightRailViewEnum.EDIT_CONTACT &&
          Boolean(selectedContact) && (
            <CreateUpdateLeaseContactForm
              lease={lease}
              contact={selectedContact}
              onCancel={closeRightRail}
              onSuccess={onContactAddedUpdated}
            />
          )}
      </RightRail>
      <Stack>
        <Group justify={Justify.between}>
          <Group spacing={Spacing.sm}>
            <IconUserSquare size={24} color="var(--color-slate-600)" />
            <H2>Contacts</H2>
          </Group>
          <ActionIcon tooltip="Add Contact" onClick={onAddContactClick}>
            <IconPlus />
          </ActionIcon>
        </Group>
        {contacts.map((contact) => (
          <LeaseContactCard
            key={contact.id}
            contact={contact}
            onEditContactClick={onEditContactClick}
            refetchLeaseContacts={refetchLeaseContacts}
            lease={lease}
          />
        ))}
      </Stack>
    </>
  );
}

export { LeaseContacts };
