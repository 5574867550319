import { cva } from "class-variance-authority";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

import type { Sizes } from "@ender/shared/utils/theming";
import { Size } from "@ender/shared/utils/theming";

import { Divider } from "../../../divider/src";
import type { Spacings } from "../../../flex/src";
import { Spacing } from "../../../flex/src";
import { useGridDimensions } from "./shared-ds-grid-utils";

const computeUnderlines = (
  underline: "none" | "uneven" | "all",
  columns: number,
  numChildren: number,
): number => {
  switch (underline) {
    case "none":
      return 0;
    case "uneven":
      return (columns - (numChildren % columns)) % columns;
    case "all":
      return columns;
  }
};

type GridProps = {
  /**
   * the minimum column size. This is used to determine how many columns to display.
   *
   * If "lg" columns are requested, the grid will usually display fewer columns than if "sm" columns are requested.
   */
  columnSize?: Extract<Sizes, "sm" | "md" | "lg">;
  /**
   * it's like that rocket company
   */
  spacingX?: Spacings;
  /**
   * spacing y not
   */
  spacingY?: Spacings;
  /**
   * add underlines to the last items in the grid.
   *
   * - "none" - no underlines
   * - "uneven" - underlines items not in the final row
   * - "all" - underlines all items that have nothing below them
   *
   * @default "none"
   */
  underline?: "none" | "uneven" | "all";
};

const GridVariantGenerator = cva(["grid content-start"], {
  defaultVariants: {
    columnSize: "md",
    spacingX: Spacing.md,
    spacingY: Spacing.md,
  },
  variants: {
    columnSize: {
      [Size.sm]: "grid-cols-[repeat(auto-fill,minmax(min(15rem,100%),1fr))]",
      [Size.md]: "grid-cols-[repeat(auto-fill,minmax(min(20rem,100%),1fr))]",
      [Size.lg]: "grid-cols-[repeat(auto-fill,minmax(min(25rem,100%),1fr))]",
    },
    spacingX: {
      [Spacing.none]: "gap-x-0",
      [Spacing.xs]: "gap-x-1",
      [Spacing.sm]: "gap-x-2",
      [Spacing.md]: "gap-x-4",
      [Spacing.lg]: "gap-x-6",
      [Spacing.xl]: "gap-x-8",
    },
    spacingY: {
      [Spacing.none]: "gap-y-0",
      [Spacing.xs]: "gap-y-1",
      [Spacing.sm]: "gap-y-2",
      [Spacing.md]: "gap-y-4",
      [Spacing.lg]: "gap-y-6",
      [Spacing.xl]: "gap-y-8",
    },
  },
});

const Grid = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>(
  function Grid(props, ref) {
    const { children, underline = "none" } = props;
    const { dimensions, domChildCount, setRef } = useGridDimensions();

    const underlineCount = computeUnderlines(
      underline,
      dimensions.columns,
      domChildCount,
    );
    const filler = [...new Array(Math.max(underlineCount, 0)).fill("")];

    return (
      //TODO if the `setRef` is used we need to merge it with the ref prop
      <div
        className={GridVariantGenerator(props)}
        ref={underline === "none" ? ref : setRef}>
        {children}
        {filler.map((_, index) => (
          <Divider key={index} />
        ))}
      </div>
    );
  },
);

export { Grid };

export type { GridProps };
