import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CompsServiceComparableDataSourceValues = ["ALTOS", "OPENDOOR"] as const;

const CompsServiceComparableDataSourceEffectSchema = Schema.Literal(
  ...CompsServiceComparableDataSourceValues,
);

type CompsServiceComparableDataSource = Schema.Schema.Type<
  typeof CompsServiceComparableDataSourceEffectSchema
>;

const CompsServiceComparableDataSourceEnum = castEnum(
  CompsServiceComparableDataSourceEffectSchema,
);

function randomCompsServiceComparableDataSource(): CompsServiceComparableDataSource {
  return rand(CompsServiceComparableDataSourceValues);
}

export {
  CompsServiceComparableDataSourceEffectSchema,
  CompsServiceComparableDataSourceEnum,
  CompsServiceComparableDataSourceValues,
  randomCompsServiceComparableDataSource,
};
export type { CompsServiceComparableDataSource };
