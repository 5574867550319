import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Predicate as P, String as S } from "effect";
import { useCallback, useState } from "react";
import { useWatch } from "react-hook-form";

import { Form, useForm } from "@ender/form-system/base";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { ObjectValues } from "@ender/shared/types/general";
import { fail } from "@ender/shared/utils/error";

import { CreateUpdateLeaseContactForm } from "./create-update-lease-contact-form";

const AddContactViewEnum = {
  EMAIL_CHECK: "EMAIL_CHECK",
  FORM_FIELDS: "FORM_FIELDS",
} as const;

type AddContactView = ObjectValues<typeof AddContactViewEnum>;

function isUserOnLease(user: User, lease: { contacts?: { id: EnderId }[] }) {
  return lease.contacts?.some(({ id }) => id === user.id) || false;
}

const EmailCheckFormSchema = Schema.Struct({
  email: Schema.String,
  isMinor: Schema.Boolean,
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];

    if (!S.isNonEmpty(values.email) && !values.isMinor) {
      issues.push({
        message: "Email is required for non-minors",
        path: ["email"],
      });
    }

    return issues;
  }),
);

type EmailCheckFormInput = Schema.Schema.Encoded<typeof EmailCheckFormSchema>;
type EmailCheckFormOutput = Schema.Schema.Type<typeof EmailCheckFormSchema>;

type AddLeaseContactProps = {
  lease: LeaseSerializerLeaseResponse;
  onContactAdded: () => void;
  onClose: () => void;
};

function AddLeaseContact({
  lease,
  onContactAdded,
  onClose,
}: AddLeaseContactProps) {
  const [existingUser, setExistingUser] = useState<User | undefined>();
  const [currentView, setCurrentView] = useState<AddContactView>(
    AddContactViewEnum.EMAIL_CHECK,
  );
  const form = useForm<EmailCheckFormInput>({
    defaultValues: {
      email: "",
      isMinor: false,
    },
    resolver: effectTsResolver(EmailCheckFormSchema),
  });

  const resetAll = useCallback(() => {
    setCurrentView(AddContactViewEnum.EMAIL_CHECK);
    setExistingUser(UNDEFINED);
    form.reset();
  }, [setCurrentView, setExistingUser, form]);

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation({
    mutationFn: async (values: EmailCheckFormOutput) => {
      const { email, isMinor } = values;
      if (isMinor) {
        return { action: "proceedToFormFields" };
      }

      const filteredUser = await UsersAPI.getFilteredUser({ email });
      if (P.isNullable(filteredUser?.id)) {
        return { action: "proceedToFormFields" };
      }

      const matchingUser = filteredUser as User;

      if (!matchingUser.isTenant) {
        return {
          action: "setError",
          message:
            "Email belongs to non-tenant user. Please reach out to Ender Support if you wish to add this user to the lease.",
        };
      }

      if (isUserOnLease(matchingUser, lease)) {
        return {
          action: "setError",
          message: "Email belongs to contact that is already on this lease",
        };
      }

      return { action: "setExistingUser", user: matchingUser };
    },
    mutationKey: ["checkEmailForExistingUser"] as const,
    onSuccess: (data) => {
      switch (data.action) {
        case "proceedToFormFields":
          setCurrentView(AddContactViewEnum.FORM_FIELDS);
          break;
        case "setError":
          form.setError("email", {
            type: "manual",
            message: data.message,
          });
          break;
        case "setExistingUser":
          setExistingUser(data.user);
          setCurrentView(AddContactViewEnum.FORM_FIELDS);
          break;
        default:
          break;
      }
    },
    onError: (err) => fail(err),
  });

  const [isMinor, email] = useWatch({
    control: form.control,
    name: ["isMinor", "email"],
  });

  return (
    <>
      {currentView === AddContactViewEnum.EMAIL_CHECK && (
        <Form
          form={form}
          onSubmit={(val: EmailCheckFormOutput) => onSubmit(val)}>
          <Stack>
            <Text size={FontSize.sm}>
              Enter email address of new contact to check if they are in the
              system
            </Text>
            <FormCheckbox
              name="isMinor"
              disabled={isSubmitting}
              label="Contact is a minor"
              form={form}
            />
            <FormTextInput
              label="Email Address"
              name="email"
              form={form}
              placeholder="Enter email address here"
              disabled={isMinor || isSubmitting}
            />
            <Group justify={Justify.between}>
              <Button
                variant={ButtonVariant.transparent}
                type="button"
                onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                Next
              </Button>
            </Group>
          </Stack>
        </Form>
      )}
      {currentView === AddContactViewEnum.FORM_FIELDS && (
        <Stack>
          <Group justify={Justify.start}>
            <Button variant={ButtonVariant.transparent} onClick={resetAll}>
              {"< Email Check"}
            </Button>
          </Group>
          {isMinor && <Text size={FontSize.sm}>Contact is a minor</Text>}
          <CreateUpdateLeaseContactForm
            lease={lease}
            newContactExistingUser={existingUser}
            newContactEmail={existingUser || isMinor ? "" : email}
            isNewContactMinor={isMinor}
            onCancel={onClose}
            onSuccess={onContactAdded}
          />
        </Stack>
      )}
    </>
  );
}

export { AddLeaseContact };
