import { IconUpload } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { Array } from "effect";
import type { ForwardedRef, PropsWithChildren } from "react";
import {
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useState,
} from "react";
import type { DropzoneOptions, FileRejection } from "react-dropzone";
import { ErrorCode, useDropzone } from "react-dropzone";

import { showErrorNotification } from "@ender/shared/utils/notifications";

import { Align, Spacing } from "../../../flex/src";
import { Stack } from "../../../stack/src";
import { FileTuple } from "./shared-ds-file-tuple";

const siUnits = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] as const;
const binaryUnits = [
  "KiB",
  "MiB",
  "GiB",
  "TiB",
  "PiB",
  "EiB",
  "ZiB",
  "YiB",
] as const;

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param precision Number of decimal places to display.
 *
 * @return Formatted string.
 */
function readableFileSize(bytes: number, si = false, precision = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  let u = -1;
  const r = 10 ** precision;
  const units = si ? siUnits : binaryUnits;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(precision)}${units[u]}`;
}

const FileInputVariantGenerator = cva(
  [
    "relative",
    "py-6 px-4 rounded-md",
    "cursor-pointer hover:bg-slate-100 active:bg-slate-200",
    "before:inset-0 before:absolute before:rounded-md before:border before:pointer-events-none",
  ],
  {
    variants: {
      accept: {
        false: "",
        true: "",
      },
      disabled: {
        false: "",
        true: "bg-slate-50 before:border-transparent pointer-events-none text-slate-200",
      },
      ready: {
        false: "",
        true: "",
      },
    },
    compoundVariants: [
      {
        accept: false,
        className: "bg-slate-50 before:border-slate-300",
        ready: false,
      },
      {
        accept: false,
        className:
          "bg-slate-50 before:border-primary-500 before:border-2 before:border-dashed",
        ready: true,
      },
      {
        accept: true,
        className:
          "bg-primary-100 before:border-primary-500 before:border-2 before:border-dashed",
        ready: [true, false],
      },
    ],
    defaultVariants: {
      accept: false,
      disabled: false,
      ready: false,
    },
  },
);

const fileErrorMessages: Record<string, string> = {
  [ErrorCode.FileInvalidType]: "Invalid file type",
  [ErrorCode.FileTooLarge]: "File too large",
  [ErrorCode.FileTooSmall]: "File too small",
  [ErrorCode.TooManyFiles]: "Too many files",
};

function onFilesRejected(fileRejections: FileRejection[]) {
  showErrorNotification({
    title: "File upload failed",
    message: (
      <ul>
        {fileRejections.map((rejection) => (
          <li key={rejection.file.name}>
            {rejection.file.name} -{" "}
            {rejection.errors
              .map((error) => fileErrorMessages[error.code])
              .join(", ")}
          </li>
        ))}
      </ul>
    ),
  });
}

function onFilesError(e: Error) {
  showErrorNotification({
    title: "File upload failed",
    message: e.message,
  });
}

type FileInputProps = {
  /**
   * @param files all files maintained by this component.
   */
  onChange: (files: File[]) => void;
  value: File[];
  name?: string;
  multiple?: boolean;
} & Pick<
  DropzoneOptions,
  "maxFiles" | "maxSize" | "disabled" | "accept" | "multiple"
>;

const FileInput = forwardRef<
  HTMLInputElement,
  PropsWithChildren<FileInputProps>
>(
  (
    props: PropsWithChildren<FileInputProps>,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const {
      accept,
      disabled = false,
      maxSize = 30 * 1000 * 1000, // 20MB in SI units
      children = (
        <Stack align={Align.center} spacing={Spacing.sm}>
          <IconUpload
            size={24}
            className="text-primary-500 aria-disabled:text-slate-200"
            aria-disabled={disabled}
          />
          <Stack align={Align.center} spacing={Spacing.xs}>
            <span
              className="text-sm font-medium text-slate-900 aria-disabled:text-slate-200"
              aria-disabled={disabled}>
              Drop your files here or{" "}
              <a
                className="text-primary-500 aria-disabled:text-slate-200"
                aria-disabled={disabled}>
                Browse
              </a>
            </span>
            <span
              className="text-xs text-slate-600 leading-normal font-normal aria-disabled:text-slate-200"
              aria-disabled={disabled}>
              Max file size: {readableFileSize(maxSize, true, 0)}
            </span>
          </Stack>
        </Stack>
      ),
      maxFiles = Number.POSITIVE_INFINITY,
      multiple = true,
      name,
      onChange,
      value: files,
    } = props;

    const onDrop = useCallback(
      (newFiles: File[]) => {
        const updatedFiles = [...files, ...newFiles];
        onChange(updatedFiles);
      },
      [onChange, files],
    );

    const { getRootProps, getInputProps, isDragAccept, inputRef } = useDropzone(
      {
        onDrop,
        maxSize,
        maxFiles,
        disabled,
        accept,
        multiple,
        onDropRejected: onFilesRejected,
        onError: onFilesError,
      },
    );

    //@ts-expect-error null is a valid value for the ref
    useImperativeHandle(ref, () => inputRef.current, [inputRef]);

    const labelId = useId();

    const [isDraggingFilesOverWindow, setIsDraggingFilesOverWindow] =
      useState(false);

    /**
     * Handle dragging files into the browser window. This is used to show a visual indicator that the user is dragging
     * and that the file input is a valid drop target for the action.
     */
    useEffect(() => {
      let dragCounter = 0;
      const handleDragEnter = () => {
        dragCounter++;
        setIsDraggingFilesOverWindow(dragCounter > 0);
      };
      const handleDragLeave = () => {
        dragCounter--;
        setIsDraggingFilesOverWindow(dragCounter > 0);
      };
      const handleDragEnd = () => {
        dragCounter = 0;
        setIsDraggingFilesOverWindow(false);
      };

      window.addEventListener("dragenter", handleDragEnter);
      window.addEventListener("dragleave", handleDragLeave);
      window.addEventListener("dragend", handleDragEnd);
      window.addEventListener("drop", handleDragEnd);
      return () => {
        dragCounter = 0;
        window.removeEventListener("dragenter", handleDragEnter);
        window.removeEventListener("dragleave", handleDragLeave);
        window.removeEventListener("dragend", handleDragEnd);
        window.removeEventListener("drop", handleDragEnd);
      };
    }, []);

    const attributes = {
      disabled,
      ready: isDraggingFilesOverWindow,
      accept: isDragAccept,
    };
    return (
      <div
        {...getRootProps({
          className: FileInputVariantGenerator(attributes),
          //do not include `onClick` that stops input propagation here
          tabIndex: 0,
          "aria-label": "drag-and-drop zone",
        })}>
        <input
          {...getInputProps({
            "aria-labelledby": labelId,
            disabled,
            className: "sr-only",
            style: {},
            onClick: (e) => {
              // Prevent the event from bubbling up to the parent element, and having the file dialog open twice in some
              // situations because of the `getRootProps` from `useDropzone`
              e.stopPropagation();
            },
            name,
          })}
        />
        <Stack>
          <label id={labelId} className="cursor-pointer">
            {children}
          </label>
          {Array.isNonEmptyArray(files) && (
            <Stack spacing={Spacing.sm}>
              {files.map((file, index) => (
                <FileTuple
                  key={file.name}
                  file={file}
                  onDelete={() => {
                    const updatedFiles = files.filter(
                      (el, idx) => idx !== index,
                    );
                    onChange(updatedFiles);
                  }}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </div>
    );
  },
);
FileInput.displayName = "FileInput";

export { FileInput };

export type { FileInputProps };
