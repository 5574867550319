import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import type {
  Appliance,
  ApplianceApplianceType,
} from "@ender/shared/generated/ender.model.core.unit";
import {
  ApplianceApplianceTypeEffectSchema,
  ApplianceApplianceTypeValues,
} from "@ender/shared/generated/ender.model.core.unit";
import type { LabelValue } from "@ender/shared/types/label-value";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

const applianceTypeLabelValues: LabelValue<ApplianceApplianceType>[] =
  ApplianceApplianceTypeValues.map((type) => ({
    label: convertSnakeCaseToTitleCase(type),
    value: type,
  }));

type ApplianceFormProps = {
  onSuccess?: (successType: "ADD_APPLIANCE" | "EDIT_APPLIANCE") => void;
  onCancel?: () => void;
  appliance?: Appliance;
  unitId: EnderId;
};

const ApplianceFormSchema = Schema.Struct({
  type: ApplianceApplianceTypeEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  brand: Schema.String,
  model: Schema.String,
  serialCode: Schema.String,
  id: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
});

type ApplianceFormOutput = Schema.Schema.Type<typeof ApplianceFormSchema>;

function ApplianceForm(props: ApplianceFormProps) {
  const { onSuccess = F.constVoid, onCancel, appliance, unitId } = props;

  const form = useEffectSchemaForm({
    defaultValues: {
      id: O.fromNullable(appliance?.id),
      type: O.fromNullable(appliance?.type),
      brand: appliance?.brand ?? "",
      model: appliance?.model ?? "",
      serialCode: appliance?.serialCode ?? "",
    },
    schema: ApplianceFormSchema,
  });

  const { mutateAsync: updateAppliance, isLoading: updatingAppliance } =
    useMutation({
      mutationFn: UnitsAPI.updateAppliance,
      mutationKey: ["UnitsAPI.updateAppliance"] as const,
    });
  const { mutateAsync: createAppliance, isLoading: creatingAppliance } =
    useMutation({
      mutationFn: UnitsAPI.createAppliance,
      mutationKey: ["UnitsAPI.createAppliance"] as const,
    });

  async function handleSubmit(values: ApplianceFormOutput) {
    if (O.isSome(values.id)) {
      await updateAppliance({
        applianceId: O.getOrThrow(values.id),
        brand: values.brand,
        model: values.model,
        serialCode: values.serialCode,
        type: O.getOrThrow(values.type),
      });
      onSuccess("EDIT_APPLIANCE");
    } else {
      // @ts-expect-error API generator doesn't handle single-value responses well
      const { id: applianceId } = await createAppliance({
        brand: values.brand,
        model: values.model,
        serialCode: values.serialCode,
        type: O.getOrThrow(values.type),
        unitId,
      });

      onSuccess("ADD_APPLIANCE");
      form.setValue("id", O.some(applianceId));
    }
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormSelect
          form={form}
          label="Type"
          name="type"
          data={applianceTypeLabelValues}
        />
        <FormTextInput
          form={form}
          label="Brand"
          inputMode="text"
          name="brand"
        />
        <FormTextInput
          form={form}
          label="Model #"
          inputMode="text"
          name="model"
        />
        <FormTextInput
          form={form}
          label="Serial #"
          inputMode="text"
          name="serialCode"
        />

        <Group justify={Justify.end} align={Align.center}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            onClick={F.constVoid}
            type="submit"
            loading={creatingAppliance || updatingAppliance}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { ApplianceForm };
