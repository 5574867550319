import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useWatch } from "react-hook-form";

import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import type { OptimizedLedgerEvent } from "@ender/shared/contexts/ledger";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const ReverseCreditFormSchema = Schema.Struct({
  reversalDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((v): v is O.Option<LocalDate$.LocalDate> => O.isSome(v), {
      message: () => "Reversal Date is required",
    }),
  ),
});

type ReverseCreditFormValues = Schema.Schema.Type<
  typeof ReverseCreditFormSchema
>;

type ReverseCreditFormProps = {
  ledgerEvent: OptimizedLedgerEvent;
  onSuccess: () => void;
};

function ReverseCreditForm(props: ReverseCreditFormProps) {
  const { ledgerEvent, onSuccess } = props;
  const { mutateAsync: reverseTenantCredit, isLoading } = useMutation({
    mutationFn: TenantLedgerAPI.reverseTenantLedgerEntry,
    mutationKey: ["TenantLedgerAPI.reverseTenantLedgerEntry"] as const,
  });

  const form = useForm<ReverseCreditFormValues>({
    defaultValues: {
      reversalDate: O.none(),
    },
    resolver: effectTsResolver(ReverseCreditFormSchema),
  });

  const reversalDate = useWatch({
    control: form.control,
    name: "reversalDate",
  });

  async function handleFormSubmit() {
    await reverseTenantCredit({
      ledgerEventId: ledgerEvent.id,
      ledgerEventType: ledgerEvent.ledgerEventType,
      reversalDate: reversalDate.pipe(
        O.map((date) => date.toJSON()),
        O.getOrThrow,
      ),
    });
    showSuccessNotification({ message: "Credit successfully reversed." });
    onSuccess();
  }

  return (
    <>
      <Skeleton visible={isLoading}>
        <Form form={form} onSubmit={handleFormSubmit}>
          <Stack spacing={Spacing.xs}>
            <Text size={FontSize.sm}>
              Processing a reversal will create offsetting entries for this
              lease credit. The reversing entry will appear on both the General
              Ledger and the Tenant Ledger
            </Text>
            <FormDateInput
              minDate={O.getOrUndefined(
                LocalDate$.parse(ledgerEvent.specificLedgerDate),
              )}
              label="Reversal Date"
              name="reversalDate"
              form={form}
            />
            <Group justify={Justify.end}>
              <Button type="submit" loading={isLoading}>
                Confirm
              </Button>
            </Group>
          </Stack>
        </Form>
      </Skeleton>
    </>
  );
}

export { ReverseCreditForm };
