"use client";

import { Predicate as P } from "effect";
import { useCallback, useState } from "react";

import { useWindowEvent } from "./use-window-event";

const eventListerOptions = {
  passive: true,
};

function useViewportSize() {
  const getSize = useCallback(
    () => ({ height: window.innerHeight || 0, width: window.innerWidth || 0 }),
    [],
  );
  const [windowSize, setWindowSize] = useState(getSize());

  const setSize = useCallback(() => {
    const width =
      (P.isNotNullable(window)
        ? window.innerWidth
        : document.documentElement?.offsetWidth) || 0;
    const height =
      (P.isNotNullable(window)
        ? window.innerHeight
        : document.documentElement?.offsetHeight) || 0;

    setWindowSize({ height, width });
  }, []);

  useWindowEvent("resize", setSize, eventListerOptions);
  useWindowEvent("orientationchange", setSize, eventListerOptions);

  return windowSize;
}

export { useViewportSize };
