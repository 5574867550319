import type { PropsWithChildren } from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ender/shared/ds/accordion";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { getAllocationsFromAllocationsByProperty } from "@ender/shared/utils/invoice-utils";

import { EditInvoiceAllocationsButton } from "./edit-invoice-allocations-button";
import { InvoiceAllocationsTabs } from "./invoice-allocations-tabs";

type InvoiceAllocationsProps = {
  approvalSteps?: GetApprovalProcessResponse;
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess: () => void;
};

function InvoiceAllocationsListHeader({
  approvalSteps,
  invoice,
  onSuccess,
}: InvoiceAllocationsProps) {
  return (
    <Group justify={Justify.between}>
      <H3>Allocations</H3>
      <EditInvoiceAllocationsButton
        approvalSteps={approvalSteps}
        invoice={invoice}
        onSuccess={onSuccess}
      />
    </Group>
  );
}

const ACCORDION_THRESHOLD = 10;

function InvoiceAllocationsAccordion({
  approvalSteps,
  children,
  invoice,
  onSuccess,
}: PropsWithChildren<InvoiceAllocationsProps>) {
  return (
    <Accordion>
      <AccordionItem value="test2">
        <AccordionTrigger>
          <InvoiceAllocationsListHeader
            approvalSteps={approvalSteps}
            invoice={invoice}
            onSuccess={onSuccess}
          />
        </AccordionTrigger>
        <AccordionContent>
          {/**
           * // TODO we don't want AccordionContent's padding here around rendered children (AllocationsTable)
           * // link to related slack thread: https://enderhub.slack.com/archives/C06469K9B2N/p1721861634382849
           */}
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function InvoiceAllocations({
  approvalSteps,
  invoice,
  onSuccess,
}: InvoiceAllocationsProps) {
  const allocations = getAllocationsFromAllocationsByProperty(
    invoice.currentState.allocationsByProperty,
  );

  if (allocations.length > ACCORDION_THRESHOLD) {
    return (
      <InvoiceAllocationsAccordion
        approvalSteps={approvalSteps}
        invoice={invoice}
        onSuccess={onSuccess}>
        <InvoiceAllocationsTabs
          invoiceId={invoice.id}
          allocations={allocations}
        />
      </InvoiceAllocationsAccordion>
    );
  }

  return (
    <Stack>
      <InvoiceAllocationsListHeader
        approvalSteps={approvalSteps}
        invoice={invoice}
        onSuccess={onSuccess}
      />
      <InvoiceAllocationsTabs
        invoiceId={invoice.id}
        allocations={allocations}
      />
    </Stack>
  );
}

export { InvoiceAllocations };
