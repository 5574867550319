import { IconInfoCircle } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import * as A from "effect/Array";
import { useId } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { LocalDate$ } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@ender/shared/ds/popover";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { ChargeSchedulesAPI } from "@ender/shared/generated/ender.api.leasing";
import type { GetChargeScheduleResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { LeaseLeaseSubstatus } from "@ender/shared/generated/ender.model.leasing";
import { LeaseLeaseSubstatusEnum } from "@ender/shared/generated/ender.model.leasing";
import { ChargeScheduleFrequencyEnum } from "@ender/shared/generated/ender.model.payments";
import type { LabelValue } from "@ender/shared/types/label-value";
import { getLeaseDisplayStatus } from "@ender/shared/utils/lease";
import { convertToNumber } from "@ender/shared/utils/string";

import { getLeaseTenantsString } from "./get-lease-tenants-string";

/**
 *
 * @description formats a number with comma delineation and optional decimal places
 */
function formatNumberWithCommas(num: number | "", showDecimals = true) {
  if (!showDecimals) {
    if (num === "") {
      return num;
    }

    return Math.round(num).toString();
  }

  return num.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

function localDateToFormattedString(date: LocalDate$.Serialized) {
  return LocalDate$.toFormatted(
    LocalDate$.of(date),
    LocalDate$.Formats.DEFAULT,
  );
}

const renewedStatus: LeaseLeaseSubstatus[] = [
  LeaseLeaseSubstatusEnum.RENEWED,
  LeaseLeaseSubstatusEnum.ACTIVE_RENEWED,
];

type DetailsPopoverProps = {
  title: string;
  dataList: LabelValue[];
};

/**
 * may need its own entity/widget at some point
 */
function DetailsPopover({ title, dataList }: DetailsPopoverProps) {
  return (
    <Popover>
      <PopoverTrigger>
        <IconInfoCircle
          color={EnderThemeColorEnum.PRIMARY}
          size={20}
          display="block"
        />
      </PopoverTrigger>
      <PopoverContent>
        <Card borderless padding="sm">
          <Text weight={FontWeight.medium} size={FontSize.sm}>
            {title}
          </Text>
          <div>
            {dataList.map((data, _i) => (
              <Tuple
                borderless
                label={data.label}
                value={data.value}
                key={data.label}
              />
            ))}
          </div>
        </Card>
      </PopoverContent>
    </Popover>
  );
}

type LeaseInfoCardProps = {
  lease: LeaseSerializerLeaseResponse;
};

function LeaseInfoCard({ lease }: LeaseInfoCardProps) {
  const leaseData = [];

  leaseData.push({ label: "Status", value: getLeaseDisplayStatus(lease) });

  const { data: scheduledCharges = [] } = useQuery({
    queryFn: ({ signal }) =>
      ChargeSchedulesAPI.getLeaseChargeSchedules(
        { leaseId: lease.id },
        { signal },
      ),
    queryKey: ["ChargeSchedulesAPI.getLeaseChargeSchedules", lease.id] as const,
  });

  if (A.isNonEmptyArray(scheduledCharges)) {
    const activeMonthlyCharges = scheduledCharges.filter(
      (charge: GetChargeScheduleResponse) =>
        charge.frequency === ChargeScheduleFrequencyEnum.MONTHLY &&
        charge.status === lease.substatus,
    );

    if (A.isNonEmptyArray(activeMonthlyCharges)) {
      const totalCharges = activeMonthlyCharges
        .map((charge: GetChargeScheduleResponse) =>
          convertToNumber(charge.amount),
        )
        .reduce((amount1: number, amount2: number) => amount1 + amount2, 0);

      const chargesBreakdown = activeMonthlyCharges.map(
        (charge: GetChargeScheduleResponse) => ({
          label: charge.chargeType.name,
          value: charge.amount,
        }),
      );

      const value = (
        <Group justify={Justify.end} spacing={Spacing.sm}>
          ${formatNumberWithCommas(totalCharges)}
          <DetailsPopover
            title="Total Charge Details"
            dataList={chargesBreakdown}
          />
        </Group>
      );

      leaseData.push({ label: "Total Charges", value });
    }
  }

  if (P.isNotNullable(lease.baseRent)) {
    leaseData.push({ label: "Base Rent", value: lease.baseRent });
  }

  if (A.isNonEmptyArray(lease.deposits)) {
    const depositsTotal = lease.deposits
      .map((deposit) => convertToNumber(deposit.amount))
      .reduce((amount1, amount2) => amount1 + amount2, 0);

    const depositsBreakdown = lease.deposits.map((deposit) => ({
      label: deposit.glCategory.accountName,
      value: deposit.amount,
    }));

    const value = (
      <Group justify={Justify.end} spacing={Spacing.sm}>
        ${formatNumberWithCommas(depositsTotal)}
        <DetailsPopover
          title="Total Deposit Details"
          dataList={depositsBreakdown}
        />
      </Group>
    );

    leaseData.push({ label: "Total Deposit Held", value });
  }

  if (
    P.isNotNullable(lease.startDate) &&
    P.isNotNullable(lease.inclusiveEndDate)
  ) {
    const startDate = localDateToFormattedString(lease.startDate);
    const endDate = localDateToFormattedString(lease.inclusiveEndDate);
    leaseData.push({ label: "Term", value: `${startDate}-${endDate}` });
  }

  if (P.isNotNullable(lease.moveInDate)) {
    leaseData.push({
      label: "Move In Date",
      value: localDateToFormattedString(lease.moveInDate),
    });
  }

  if (
    P.isNotNullable(lease.expectedMoveOutDate) &&
    !renewedStatus.includes(lease.substatus)
  ) {
    leaseData.push({
      label: "Move Out Date",
      value: localDateToFormattedString(lease.expectedMoveOutDate),
    });
  }

  const headingId = useId();

  return (
    <Card labelledBy={headingId}>
      <Stack>
        <H3 id={headingId}>
          <RouterLink href={`/leases/${lease.id}`}>
            {getLeaseTenantsString(lease)}
          </RouterLink>
        </H3>
        <Grid spacingY={Spacing.none}>
          {leaseData.map((item, i) => (
            <Tuple key={i} label={item.label} value={item.value} />
          ))}
        </Grid>
      </Stack>
    </Card>
  );
}

export { LeaseInfoCard };
export type { LeaseInfoCardProps };
