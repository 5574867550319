import type { SelectProps } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { Predicate as P } from "effect";
import type { ReactNode } from "react";
import { useContext, useMemo } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type {
  AccountingPeriod,
  AccountingPeriodAccountingModule,
  AccountingPeriodPeriodStatus,
} from "@ender/shared/generated/ender.model.accounting";
import { AccountingPeriodPeriodStatusEnum } from "@ender/shared/generated/ender.model.accounting";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useAccountingPeriods } from "@ender/shared/hooks/use-accounting-periods";
import { cast } from "@ender/shared/types/cast";
import type { LabelValue } from "@ender/shared/types/label-value";
import { Select } from "@ender/shared/ui/select";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { convertTitleCaseToSnakeCase } from "@ender/shared/utils/string";

/**
 * Used as a LabelValue pairing when handling bulk editing of accounting periods
 * Its purpose is to specify indicate to the user there are multiple accounting periods
 * currently set on the grouping of accounting periods being edited
 */
const MULTIPLE = "Multiple";

type AccountingPeriodSelectorOutputType = "ENDER_ID" | "DATE_STRING";

type AccountingPeriodSelectorProps = Omit<SelectProps, "data" | "onChange"> & {
  /**
   * Enables the Multiple option
   * Is used when bulk editing accounting periods to indicate there are multiple accounting periods selected
   */
  isHandlingBulk?: boolean;
} & {
  periodType: AccountingPeriodAccountingModule;
  disableTippy?: boolean;
  hideLabel?: boolean;
  onChange: (value: string | Null) => void;
  outputType?: AccountingPeriodSelectorOutputType;
};

/** @description Sorts Accounting Periods from oldest to newest */
function sortPeriods(
  periodA: AccountingPeriod,
  periodB: AccountingPeriod,
): -1 | 0 | 1 {
  if (periodA.startDate === periodB.startDate) {
    return 0;
  }

  return periodA.startDate < periodB.startDate ? -1 : 1;
}

function AccountingPeriodLabel({
  label,
  disableTippy = false,
}: {
  label: ReactNode;
  disableTippy: boolean;
}): ReactNode {
  return (
    <Group spacing={Spacing.xs}>
      {label ?? "Accounting Period (optional)"}
      {!disableTippy && (
        <Tooltip
          disabled={disableTippy}
          label="Ender will assign an accounting period based on the transaction date if you do not specify one.">
          <IconInfoCircle size={14} />
        </Tooltip>
      )}
    </Group>
  );
}

/**
 * @deprecated use the AccountingPeriodSelector component from @ender/entities/accounting-period-selector
 */
function AccountingPeriodSelector({
  disabled,
  disableTippy = false,
  hideLabel,
  isHandlingBulk = false,
  onChange,
  outputType = "ENDER_ID",
  periodType,
  value,
  ...props
}: AccountingPeriodSelectorProps) {
  const { permissions } = useContext(UserContext);
  const canChoosePeriod =
    permissions[FunctionalPermissionEnum.CHOOSE_ACCOUNTING_PERIOD] && !disabled;
  const canAccessPartiallyOpenPeriods =
    permissions[FunctionalPermissionEnum.EDIT_PARTIALLY_OPEN_BOOKS];
  const canChangeAccountingPeriod =
    permissions[FunctionalPermissionEnum.CHOOSE_ACCOUNTING_PERIOD];

  const labelWithTooltip = useMemo(() => {
    if (hideLabel) {
      return null;
    }

    if (props.required) {
      return props.label;
    }

    return (
      <AccountingPeriodLabel label={props.label} disableTippy={disableTippy} />
    );
  }, [hideLabel, props.required, props.label, disableTippy]);

  const { data: periods } = useAccountingPeriods({ periodType });

  const periodsList = useMemo(() => {
    if (!periods) {
      return [];
    }

    const statusesToKeep: AccountingPeriodPeriodStatus[] = [
      AccountingPeriodPeriodStatusEnum.OPEN,
    ];

    if (canAccessPartiallyOpenPeriods) {
      statusesToKeep.push(AccountingPeriodPeriodStatusEnum.PARTIALLY_OPEN);
    }

    const _accountingPeriods: (LabelValue<string> & { disabled?: boolean })[] =
      periods
        .filter(
          (period) =>
            statusesToKeep.includes(
              convertTitleCaseToSnakeCase(period.status),
            ) || period.id === value,
        )
        .sort(sortPeriods)
        .map((period): LabelValue<string> => {
          return {
            label: EnderDate.of(period.endDate).toLongMonthYearString(),
            value: outputType === "ENDER_ID" ? period.id : period.startDate,
          };
        });

    /**
     * If Bulk Editing accounting periods, this option allows for indicating to the user there are
     * multiple accounting periods currently set on the grouping of accounting periods being edited.
     * Once the value is modified, the option is removed from the list of accounting periods
     */
    if (isHandlingBulk && value === MULTIPLE) {
      _accountingPeriods.unshift({
        disabled: true,
        label: MULTIPLE,
        value: MULTIPLE,
      });
    }

    return _accountingPeriods;
  }, [
    canAccessPartiallyOpenPeriods,
    isHandlingBulk,
    outputType,
    periods,
    value,
  ]);

  if (!canChangeAccountingPeriod) {
    return NULL;
  }

  return (
    <Tooltip
      disabled={canChoosePeriod}
      label="If you do not select a period, Ender will apply the period that matches the transaction date. If the associated period is closed, Ender will choose the next closest future period.">
      <Select
        placeholder="Select period"
        {...props}
        autoComplete="off"
        clearable
        data={periodsList}
        disabled={!canChoosePeriod}
        // @ts-expect-error
        filter={cast(props.filter)}
        label={labelWithTooltip}
        onChange={onChange}
        searchable
        value={P.isNullable(value) ? NULL : value}
      />
    </Tooltip>
  );
}

export { AccountingPeriodSelector };
