import { IconEdit, IconUpload } from "@tabler/icons-react";
import type { Option as O } from "effect";
import { Array as A, Function as F } from "effect";
import type { ElementRef, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useId } from "react";
import { useWatch } from "react-hook-form";

import { ApplicantFile } from "@ender/entities/application/applicant-file";
import { isBirthdayMinor } from "@ender/entities/utils/application-utils";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDate$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Inset } from "@ender/shared/ds/inset";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import type {
  GetApplicationGroupResponseApplicantUserResponse,
  GetApplicationGroupResponseApplicationResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import type { ApplicationApplicantType } from "@ender/shared/generated/ender.model.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { noOpAsync } from "@ender/shared/utils/no-op";
import { SsnView } from "@ender/widgets/ssn-view";

import { DisplayableApplicantInfo } from "./display-applicant-info-fields";
import type {
  ApplicantInfoFieldset,
  ApplicantInfoFormOutput,
} from "./edit-applicant-info-fields";
import {
  ApplicantInfoFormSchema,
  EditableApplicantInfo,
} from "./edit-applicant-info-fields";

type ApplicationApplicantInfoCardProps = {
  applicantType: O.Option<ApplicationApplicantType>;
  onApplicantTypeChange: (v: O.Option<ApplicationApplicantType>) => void;
  currentAddress?: GetApplicationGroupResponseApplicationResponse["currentAddress"];
  photoIds?: GetApplicationGroupResponseApplicationResponse["photoIds"];
  applicant: Pick<
    GetApplicationGroupResponseApplicantUserResponse,
    | "firstName"
    | "lastName"
    | "birthday"
    | "phone"
    | "email"
    | "hasSSN"
    | "userId"
  >;
  isEditable?: boolean;
  onSubmit?: (data: ApplicantInfoFormOutput) => Promise<void>;
  canDeleteDocuments?: boolean;
  onUploadClick?: () => void;
  onDeletePhoto?: (file: FilesClientEnderFile) => void;
  isWorking?: boolean;
  header?: ReactNode;
  onEditFileClick?: (file: FilesClientEnderFile) => void;
};

/**
 * fields that can have their editability locked
 */
const lockableFields: ApplicantInfoFieldset[] = [
  "firstName",
  "lastName",
  "birthday",
  "phone",
  "email",
] as const;
/**
 * fields that are always editable
 */
const permanentFields: ApplicantInfoFieldset[] = ["applicantType"] as const;

const ApplicationApplicantInfoCard = forwardRef<
  ElementRef<typeof Card>,
  ApplicationApplicantInfoCardProps
>(function ApplicationApplicantInfoCard(props, ref) {
  const {
    applicantType,
    onApplicantTypeChange,
    applicant,
    photoIds = [],
    currentAddress,
    isEditable = false,
    onSubmit = noOpAsync,
    onUploadClick = F.constVoid,
    canDeleteDocuments = false,
    onDeletePhoto = F.constVoid,
    onEditFileClick = F.constVoid,
    header,
    isWorking,
  } = props;
  const { hasSSN } = applicant;
  const [editing, editingHandlers] = useBoolean(false);
  const form = useEffectSchemaForm({
    defaultValues: {
      ...applicant,
      birthday: LocalDate$.parse(applicant.birthday),
    },
    schema: ApplicantInfoFormSchema,
  });
  const birthday = useWatch({ control: form.control, name: "birthday" });
  const isMinor = isBirthdayMinor(birthday);
  const formRef = useRefLatest(form);

  /**
   * Synchronize the form values with the incoming props
   */
  useEffect(() => {
    const newValues = {
      ...applicant,
      birthday: LocalDate$.parse(applicant.birthday),
    };
    formRef.current.reset(newValues);
  }, [formRef, applicant]);

  const handleCancel = useCallback(() => {
    formRef.current.reset();
    editingHandlers.setFalse();
  }, [editingHandlers, formRef]);

  const handleSubmit = useCallback(
    async (values: ApplicantInfoFormOutput) => {
      await onSubmit(values);
      editingHandlers.setFalse();
    },
    [onSubmit, editingHandlers],
  );

  const headingId = useId();

  return (
    <Card ref={ref} labelledBy={headingId}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Group justify={Justify.between} align={Align.center}>
            <span id={headingId}>{header}</span>
            <Inset
              r={editing ? Spacing.none : Spacing.sm}
              t={Spacing.sm}
              b={Spacing.sm}>
              {editing ? (
                <Group>
                  <Button
                    variant={ButtonVariant.transparent}
                    onClick={handleCancel}
                    disabled={isWorking}>
                    Cancel
                  </Button>
                  <Button type="submit" loading={isWorking}>
                    Save
                  </Button>
                </Group>
              ) : (
                <ActionIcon
                  label="Edit"
                  variant={ButtonVariant.transparent}
                  onClick={editingHandlers.setTrue}>
                  <IconEdit />
                </ActionIcon>
              )}
            </Inset>
          </Group>
          <Grid spacingY={Spacing.none} underline="all">
            {editing ? (
              <EditableApplicantInfo
                editableFields={
                  isEditable
                    ? A.appendAll(lockableFields, permanentFields)
                    : permanentFields
                }
                isMinor={isMinor}
                form={form}
                applicantType={applicantType}
                onApplicantTypeChange={onApplicantTypeChange}
              />
            ) : (
              <DisplayableApplicantInfo
                isMinor={isMinor}
                form={form}
                applicantType={applicantType}
              />
            )}
            {!isMinor && (
              <Tuple
                label="SSN"
                value={
                  hasSSN ? <SsnView targetUserId={applicant.userId} /> : "--"
                }
              />
            )}
            {!isMinor && (
              <Tuple label="Current Address" value={currentAddress} />
            )}
          </Grid>
          <Group justify={Justify.between}>
            <span>Proof of Identity</span>
            <Inset r={Spacing.sm} t={Spacing.sm} b={Spacing.sm}>
              <ActionIcon
                label="Upload Proof of Identity"
                variant={ButtonVariant.transparent}
                onClick={onUploadClick}>
                <IconUpload />
              </ActionIcon>
            </Inset>
          </Group>
          {A.isEmptyArray(photoIds) ? (
            <Text>No photo IDs</Text>
          ) : (
            <Grid underline="none">
              {photoIds.map((file) => (
                <ApplicantFile
                  file={file}
                  key={file.id}
                  canDelete={canDeleteDocuments}
                  onDelete={onDeletePhoto}
                  canEdit={canDeleteDocuments}
                  onEdit={onEditFileClick}
                />
              ))}
            </Grid>
          )}
        </Stack>
      </Form>
    </Card>
  );
});

export { ApplicationApplicantInfoCard };

export type { ApplicationApplicantInfoCardProps };
