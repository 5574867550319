import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitHeatingSystemValues = [
  "NONE",
  "BASEBOARD",
  "FORCED_AIR",
  "HEAT_PUMP",
  "RADIANT",
  "STOVE",
  "WALL",
  "OTHER",
] as const;

const UnitHeatingSystemEffectSchema = Schema.Literal(
  ...UnitHeatingSystemValues,
);

type UnitHeatingSystem = Schema.Schema.Type<
  typeof UnitHeatingSystemEffectSchema
>;

const UnitHeatingSystemEnum = castEnum(UnitHeatingSystemEffectSchema);

function randomUnitHeatingSystem(): UnitHeatingSystem {
  return rand(UnitHeatingSystemValues);
}

export {
  randomUnitHeatingSystem,
  UnitHeatingSystemEffectSchema,
  UnitHeatingSystemEnum,
  UnitHeatingSystemValues,
};
export type { UnitHeatingSystem };
