import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Array as A,
  Number as N,
  Predicate as P,
  Record as R,
  flow,
} from "effect";
import { useCallback } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { FormsAPI } from "@ender/shared/generated/com.ender.middle";
import { UnitZonesAPI } from "@ender/shared/generated/ender.api.core";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { getTupleDisplayValue } from "./construction.utils";
import type { FormDiscriminant } from "./form-generator";
import { FormGenerator } from "./form-generator";
import type { UnitZoneDiscriminant } from "./unit-area.types";
import { useCanEditAreasAndZones } from "./use-can-edit-areas-and-zones";

type InspectionZoneProps = {
  zone: UnitZoneDiscriminant;
};

function InspectionZoneFields(props: InspectionZoneProps) {
  const queryClient = useQueryClient();
  const confirmation = useConfirmationContext();
  const { zone } = props;
  const { disabled, disabledTooltip } = useCanEditAreasAndZones(zone.unitId);
  const [isEditing, isEditingHandlers] = useBoolean(false);

  const { mutateAsync } = useMutation({
    mutationFn: UnitZonesAPI.updateUnitZone,
    mutationKey: ["UnitZonesAPI.updateUnitZone"],
  });

  const handleSubmit = useCallback(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: any) => {
      const { description, ...data } = values;
      return mutateAsync({ data, description, zoneId: zone.id }).then(() => {
        isEditingHandlers.setFalse();
        queryClient.invalidateQueries([
          "UnitZonesAPI.getUnitZones",
          { unitId: zone.unitId },
        ]);
      });
    },
    [isEditingHandlers, mutateAsync, queryClient, zone],
  );

  const { data: schema = [] } = useQuery({
    queryKey: ["FormsAPI.getForm", { formType: zone.zoneType }] as const,
    queryFn: ({ signal }) =>
      FormsAPI.getForm({ formType: zone.zoneType }, { signal }),
    enabled: P.isNotNullable(zone),
    select: flow(
      R.reduce([] as FormDiscriminant[], (acc, val) =>
        P.isNotNullable(val) && Array.isArray(val)
          ? [...acc, ...(val as FormDiscriminant[])]
          : acc,
      ),
      A.sort<FormDiscriminant>((a, b) => N.sign(a.order - b.order)),
    ),
  });

  const { mutateAsync: deleteUnitZone } = useMutation({
    mutationFn: () =>
      confirmation(
        {
          confirmButtonLabel: "Delete",
          content:
            "Are you sure you want to delete this zone? This action cannot be undone.",
          title: "Delete Zone?",
        },
        { confirmButtonProps: { color: "red" } },
      ).then(() => UnitZonesAPI.deleteUnitZone({ zoneId: zone.id })),
    mutationKey: ["UnitZonesAPI.deleteUnitZone", { zoneId: zone.id }],
  });

  const onDeleteClick = useCallback(
    () =>
      deleteUnitZone().then(() => {
        queryClient.invalidateQueries([
          "UnitZonesAPI.getUnitZones",
          { unitId: zone.unitId },
        ]);
        queryClient.invalidateQueries([
          "UnitAreasZonesMiddleLayerAPI.getUnitAreaSummary",
          { unitId: zone.unitId },
        ]);
      }),
    [deleteUnitZone, queryClient, zone],
  );

  return (
    <Card>
      {!isEditing ? (
        <Stack>
          <Group justify={Justify.between}>
            <H3>{zone.name}</H3>
            <Group spacing={Spacing.xs}>
              <ActionIcon
                variant={ButtonVariant.transparent}
                onClick={isEditingHandlers.setTrue}
                label="Edit"
                disabled={disabled}
                disabledTooltip={disabledTooltip}>
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                variant={ButtonVariant.transparent}
                color="red"
                onClick={onDeleteClick}
                label="Delete"
                disabled={disabled}
                disabledTooltip={disabledTooltip}>
                <IconTrash />
              </ActionIcon>
            </Group>
          </Group>
          <Grid spacingY={Spacing.none}>
            {schema.map((field) => (
              <Tuple
                key={field.label}
                label={field.label}
                // @ts-expect-error tis the nature of dynamic fields
                value={getTupleDisplayValue(zone[field.name])}
              />
            ))}
          </Grid>
        </Stack>
      ) : (
        <FormGenerator
          schema={schema}
          defaultValues={zone}
          onSubmit={handleSubmit}
          header={
            <Group justify={Justify.between}>
              <H3>{zone.name}</H3>
              <Group>
                <Button
                  variant={ButtonVariant.transparent}
                  onClick={isEditingHandlers.setFalse}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Group>
            </Group>
          }
        />
      )}
    </Card>
  );
}

export { InspectionZoneFields };
