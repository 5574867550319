/**
 * HTTP Method: POST
 * Pathname: /accountingPeriods/{accountingPeriodId}
 * @function updateAccountingPeriod
 * @memberof AccountingAPI
 * @param {AccountingAPIUpdateAccountingPeriodPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingPeriod>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  AccountingPeriod,
  AccountingPeriodPeriodStatus,
} from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIUpdateAccountingPeriodPathParams = {
  accountingPeriodId: EnderId;
};

type AccountingAPIUpdateAccountingPeriodSearchParams = {
  token?: string | undefined;
};

type AccountingAPIUpdateAccountingPeriodBodyParams = {
  status: AccountingPeriodPeriodStatus;
};

type AccountingAPIUpdateAccountingPeriodPayload =
  AccountingAPIUpdateAccountingPeriodPathParams &
    AccountingAPIUpdateAccountingPeriodSearchParams &
    AccountingAPIUpdateAccountingPeriodBodyParams;

function updateAccountingPeriodUnsafeEffect(
  payload: AccountingAPIUpdateAccountingPeriodPayload,
) {
  const { accountingPeriodId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingPeriod>({
        body,
        decode: unsafeDecodeJsonResponse<AccountingPeriod>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accountingPeriods/${accountingPeriodId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateAccountingPeriod(
  payload: AccountingAPIUpdateAccountingPeriodPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingPeriod> {
  return F.pipe(
    payload,
    updateAccountingPeriodUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateAccountingPeriod };
export type {
  AccountingAPIUpdateAccountingPeriodBodyParams,
  AccountingAPIUpdateAccountingPeriodPathParams,
  AccountingAPIUpdateAccountingPeriodPayload,
  AccountingAPIUpdateAccountingPeriodSearchParams,
};
