import { addMoveInConfigStep } from "./add-move-in-config-step";
import { deleteConfigStep } from "./delete-config-step";
import { getMoveInConfig } from "./get-move-in-config";
import { getMoveInStepsForLease } from "./get-move-in-steps-for-lease";
import { updateConfigStep } from "./update-config-step";
import { updateMoveInStep } from "./update-move-in-step";

const MoveInAPI = {
  addMoveInConfigStep,
  deleteConfigStep,
  getMoveInConfig,
  getMoveInStepsForLease,
  updateConfigStep,
  updateMoveInStep,
};

export { MoveInAPI };
