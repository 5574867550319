// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Schema } from "@effect/schema";
import { Option as O, Predicate as P } from "effect";
import React from "react";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { LocalDate } from "@ender/shared/core";
import {
  EnderIdFormSchema,
  LocalDate$,
  LocalDateBrandSchema,
  LocalDateEffectSchema,
} from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextarea } from "@ender/shared/ds/textarea";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { Color } from "@ender/shared/utils/theming";

const TransactionSymbol = Symbol.for("isTransactionDateRequired");

const RejectInvoiceFormFormSchema = Schema.Struct({
  comment: Schema.String.pipe(
    Schema.minLength(1, { message: () => "Must provide reason for rejecting" }),
  ),
  periodDate: Schema.Struct({
    id: EnderIdFormSchema,
    startDate: Schema.typeSchema(LocalDateBrandSchema),
  }).pipe(Schema.OptionFromSelf),
  transactionDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (v, _, { annotations }) => {
        if (annotations[TransactionSymbol]) {
          return O.isSome(v);
        }
        return true;
      },
      { message: () => "Date is required." },
    ),
  ),
});

type RejectInvoiceFormFormOutput = Schema.Schema.Type<
  typeof RejectInvoiceFormFormSchema
>;

type RejectInvoiceFormProps = {
  invoiceType?: InvoiceInvoiceType;
  invoiceTransactionDate?: LocalDate;
  isTransactionDateRequired?: boolean;
  label?: React.ReactNode;
  loading?: boolean;
  onSubmit: (values: RejectInvoiceFormFormOutput) => void;
};

function RejectInvoiceForm({
  invoiceType,
  invoiceTransactionDate,
  isTransactionDateRequired = true,
  label = "Reject",
  loading,
  onSubmit,
}: RejectInvoiceFormProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      comment: "",
      periodDate: O.none(),
      transactionDate: LocalDate$.parse(invoiceTransactionDate),
    },
    schema: RejectInvoiceFormFormSchema.annotations({
      [TransactionSymbol]: isTransactionDateRequired,
    }),
  });

  const isPayable = invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        {isTransactionDateRequired && (
          <FormDateInput
            form={form}
            name="transactionDate"
            label="Transaction Date"
          />
        )}
        {P.isNotNullable(invoiceType) && (
          <FormAccountingPeriodSelector
            label="Accounting Period (optional)"
            periodType={
              isPayable
                ? AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE
                : AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE
            }
            form={form}
            name="periodDate"
          />
        )}
        <FormTextarea
          label="Reason for rejection"
          form={form}
          name="comment"
          maxRows={7}
        />
        <Button type="submit" color={Color.red} loading={loading}>
          {label}
        </Button>
      </Stack>
    </Form>
  );
}

export { RejectInvoiceForm };
export type { RejectInvoiceFormFormOutput };
