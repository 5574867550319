/**
 * "Ignores" or "Excludes" a bank transaction, effectively telling Ender that it cannot and will not match any
 MoneyTransfers in bank reconciliation, allowing it to be displayed as such in the UI.
 * HTTP Method: POST
 * Pathname: /bankTransactions/{bankTransactionId}/ignore
 * @function ignoreBankTransaction
 * @memberof BankingAPI
 * @param {BankingAPIIgnoreBankTransactionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIIgnoreBankTransactionPathParams = {
  bankTransactionId: EnderId;
};

type BankingAPIIgnoreBankTransactionSearchParams = {
  token?: string | undefined;
};

type BankingAPIIgnoreBankTransactionPayload =
  BankingAPIIgnoreBankTransactionPathParams &
    BankingAPIIgnoreBankTransactionSearchParams;

function ignoreBankTransactionUnsafeEffect(
  payload: BankingAPIIgnoreBankTransactionPayload,
) {
  const { bankTransactionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankTransactions/${bankTransactionId}/ignore`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function ignoreBankTransaction(
  payload: BankingAPIIgnoreBankTransactionPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    ignoreBankTransactionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { ignoreBankTransaction };
export type {
  BankingAPIIgnoreBankTransactionPathParams,
  BankingAPIIgnoreBankTransactionPayload,
  BankingAPIIgnoreBankTransactionSearchParams,
};
