import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import * as A from "effect/Array";
import * as S from "effect/String";
import type { ElementRef, ReactNode } from "react";
import { forwardRef, useId } from "react";
import { z } from "zod";

import { Instant$ } from "@ender/shared/core";
import { Badge } from "@ender/shared/ds/badge";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import type {
  GetApplicationGroupResponseApplicantUserResponse,
  GetApplicationGroupResponseApplicationResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import { Color } from "@ender/shared/utils/theming";
import { castEnum } from "@ender/shared/utils/zod";
import { SsnView } from "@ender/widgets/ssn-view";

const ApplicantReportTypeValues = [
  "CREDIT",
  "CRIMINAL_HISTORY",
  "EVICTIONS",
] as const;
const ApplicantReportTypeSchema = z.enum(ApplicantReportTypeValues);
type ApplicantReportType = z.infer<typeof ApplicantReportTypeSchema>;
const ApplicantReportTypeEnum = castEnum<ApplicantReportType>(
  ApplicantReportTypeSchema,
);

type ApplicantScreeningReport = {
  html: string;
  label: string;
  type: ApplicantReportType;
};

function getReports(
  application: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "criminalReportHtml" | "creditReportHtml" | "evictionReportHtml"
  >,
) {
  const { criminalReportHtml, creditReportHtml, evictionReportHtml } =
    application;
  return [
    {
      label: "Criminal History",
      type: ApplicantReportTypeEnum.CRIMINAL_HISTORY,
      html: criminalReportHtml,
    },
    {
      label: "Credit",
      type: ApplicantReportTypeEnum.CREDIT,
      html: creditReportHtml,
    },
    {
      label: "Evictions",
      type: ApplicantReportTypeEnum.EVICTIONS,
      html: evictionReportHtml,
    },
  ].filter((report): report is ApplicantScreeningReport =>
    S.isNonEmpty(report.html ?? ""),
  );
}

type ApplicantReportCardProps = {
  application: Pick<
    GetApplicationGroupResponseApplicationResponse,
    | "transunionScreeningRequestId"
    | "applicationId"
    | "criminalReportHtml"
    | "creditReportHtml"
    | "evictionReportHtml"
    | "reportRequestedTime"
    | "identityVerificationExpiresOn"
    | "residentScore"
  > & {
    applicant: Pick<
      GetApplicationGroupResponseApplicantUserResponse,
      "firstName" | "lastName" | "hasSSN" | "userId"
    >;
  };
  /**
   * a callback invoked when the "run report" button is clicked
   */
  onScreenApplicant: () => void;
  /**
   * A callback invoked when the "Resend Identity Verification Exam Link" button is clicked
   */
  onResendIDV?: () => void;
  /**
   * a loading state boolean that determines when items should display loading indication
   * and disable certain buttons
   */
  isLoading?: boolean;
  /**
   * allows or disallows screening action
   */
  canScreen?: boolean;
  /**
   * callback invoked when an individual report is clicked
   * @param report the screening report that triggered the callback
   */
  onViewReport?: (report: ApplicantScreeningReport) => void;
  header?: ReactNode;
};

const ApplicantReportCard = forwardRef<
  ElementRef<typeof Card>,
  ApplicantReportCardProps
>(function ApplicantReportCard(props, ref) {
  const {
    application,
    onScreenApplicant,
    isLoading = false,
    canScreen,
    onViewReport = F.constVoid,
    onResendIDV = F.constVoid,
    header,
  } = props;
  const { applicant, reportRequestedTime, identityVerificationExpiresOn } =
    application;

  const idvExpired = pipe(
    Instant$.parse(identityVerificationExpiresOn),
    O.exists((v) => v.isBefore(Instant$.now)),
  );

  const reports = getReports(application);
  const headingId = useId();

  return (
    <Card ref={ref} labelledBy={headingId}>
      <Stack>
        <Group justify={Justify.between}>
          <span id={headingId}>{header}</span>
          <Group align={Align.center}>
            {idvExpired && <Badge color={Color.red}>IDV Expired</Badge>}
            {A.isEmptyArray(reports) &&
              P.isNotNullable(reportRequestedTime) && (
                <Tooltip label="Reports can take up to 10 days to retrieve depending on the jurisdiction. The requesting user will receive an email when the reports are available.">
                  <Badge color={Color.yellow}>Reports Pending</Badge>
                </Tooltip>
              )}

            {idvExpired && (
              <Button
                variant={ButtonVariant.transparent}
                onClick={onResendIDV}
                loading={isLoading}>
                Resend Identity Verification Exam Link
              </Button>
            )}
            {canScreen && (
              <Button
                variant={ButtonVariant.transparent}
                onClick={onScreenApplicant}
                loading={isLoading}
                disabled={P.isNotNullable(reportRequestedTime)}>
                Run Applicant Screening
              </Button>
            )}
          </Group>
        </Group>
        <Grid spacingY={Spacing.none} underline="uneven">
          <Tuple
            label="SSN"
            value={
              applicant.hasSSN ? (
                <SsnView targetUserId={applicant.userId} />
              ) : (
                "--"
              )
            }
          />
          <Tuple
            label="TransUnion Screening Request ID"
            value={application.transunionScreeningRequestId}
          />
          <Tuple
            label="TransUnion Resident Score"
            value={application.residentScore}
          />
          {reports.map((report) => (
            <Tuple
              key={report.type}
              label={report.label}
              value={
                <Button
                  variant={ButtonVariant.transparent}
                  onClick={() => onViewReport(report)}>
                  View Report
                </Button>
              }
            />
          ))}
        </Grid>
      </Stack>
    </Card>
  );
});

export { ApplicantReportCard };

export type { ApplicantReportCardProps, ApplicantScreeningReport };
