import { IconX } from "@tabler/icons-react";
import type { Table } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { Predicate as P } from "effect";
import * as A from "effect/Array";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { ActionBadge } from "@ender/shared/ds/badge";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";

import type { BadgeRemoveFilterFnParams } from "../table.types";
import { asColumnMeta } from "../table.types";

import styles from "../table.module.css";

function defaultGetFilterLabel<T>(value: T): string | string[] {
  return Array.isArray(value) ? value.map((v) => `${v}`) : `${value}`;
}

function defaultBadgeRemoveFilterFn<RowData, ColumnData>(
  params: BadgeRemoveFilterFnParams<RowData, ColumnData>,
) {
  const { index, column } = params;
  const filterValue = column.getFilterValue();

  if (Array.isArray(filterValue)) {
    const newColumnFilters = [...filterValue];
    newColumnFilters.splice(index, 1);
    column.setFilterValue(newColumnFilters);
  } else {
    column.setFilterValue(UNDEFINED);
  }
}

function FilterBadges<RowData>(props: { table: Table<RowData> }) {
  const { table } = props;

  return (
    <>
      {table.getAllColumns().map((column) => {
        const filterValue = column.getFilterValue();
        const header = table
          .getLeafHeaders()
          .find((header) => header.column.id === column.id);

        if (
          P.isNullable(header) ||
          !A.isArray(filterValue) ||
          A.isEmptyArray(filterValue)
        ) {
          return NULL;
        }

        const headerLabel = flexRender(
          column.columnDef.header,
          header.getContext(),
        );
        const {
          badgeRemoveFilterFn = defaultBadgeRemoveFilterFn,
          getFilterLabel = defaultGetFilterLabel,
        } = asColumnMeta<RowData>(column.columnDef.meta);
        const filterLabel = getFilterLabel(filterValue, table);
        const filterLabels = A.isArray(filterLabel)
          ? filterLabel
          : [filterLabel];

        return filterLabels.map((label, index) => {
          const onRemoveClick = () => {
            badgeRemoveFilterFn({
              index,
              column,
            });
          };

          return (
            <ActionBadge
              icon={<IconX />}
              key={column.id + label}
              onClick={onRemoveClick}>
              <Group spacing={Spacing.xs}>
                <span className={styles.filterBadgeLabel}>{headerLabel}:</span>
                <span className={styles.filterBadgeValue}>{`${label}`}</span>
              </Group>
            </ActionBadge>
          );
        });
      })}
    </>
  );
}

export { FilterBadges };
