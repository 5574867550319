/**
 * HTTP Method: POST
 * Pathname: /accounting/journal-entries/update
 * @function updateJournalEntriesFromFile
 * @memberof AccountingAPI
 * @param {AccountingAPIUpdateJournalEntriesFromFilePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIUpdateJournalEntriesFromFileSearchParams = {
  token?: string | undefined;
};

type AccountingAPIUpdateJournalEntriesFromFileBodyParams = {
  accountingPeriod: SerializesInto<LocalDate>;
};

type AccountingAPIUpdateJournalEntriesFromFilePayload =
  AccountingAPIUpdateJournalEntriesFromFileSearchParams &
    AccountingAPIUpdateJournalEntriesFromFileBodyParams;

function updateJournalEntriesFromFileUnsafeEffect(
  payload: AccountingAPIUpdateJournalEntriesFromFilePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/journal-entries/update",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateJournalEntriesFromFile(
  payload: AccountingAPIUpdateJournalEntriesFromFilePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateJournalEntriesFromFileUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateJournalEntriesFromFile };
export type {
  AccountingAPIUpdateJournalEntriesFromFileBodyParams,
  AccountingAPIUpdateJournalEntriesFromFilePayload,
  AccountingAPIUpdateJournalEntriesFromFileSearchParams,
};
