/**
 * Take a bank account owned by a Firm, PM, or Vendor which is Plaid-connected but not yet connected to Dwolla... and
 connect it to Dwolla!
 
 The owner of the bank account must already have a Dwolla Customer object created, see registerDwollaCustomer(),
 /dwolla/registerCustomer.
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}/linkToDwolla
 * @function linkPlaidConnectedBankToDwolla
 * @memberof DwollaAPI
 * @param {DwollaAPILinkPlaidConnectedBankToDwollaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPILinkPlaidConnectedBankToDwollaPathParams = {
  bankAccountId: EnderId;
};

type DwollaAPILinkPlaidConnectedBankToDwollaSearchParams = {
  token?: string | undefined;
};

type DwollaAPILinkPlaidConnectedBankToDwollaPayload =
  DwollaAPILinkPlaidConnectedBankToDwollaPathParams &
    DwollaAPILinkPlaidConnectedBankToDwollaSearchParams;

function linkPlaidConnectedBankToDwollaUnsafeEffect(
  payload: DwollaAPILinkPlaidConnectedBankToDwollaPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}/linkToDwolla`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function linkPlaidConnectedBankToDwolla(
  payload: DwollaAPILinkPlaidConnectedBankToDwollaPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    linkPlaidConnectedBankToDwollaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { linkPlaidConnectedBankToDwolla };
export type {
  DwollaAPILinkPlaidConnectedBankToDwollaPathParams,
  DwollaAPILinkPlaidConnectedBankToDwollaPayload,
  DwollaAPILinkPlaidConnectedBankToDwollaSearchParams,
};
