import { Option as O, Record as R } from "effect";

import { LocalDate$ } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import type {
  GetApplicationGroupResponseApplicantUserResponse,
  GetApplicationGroupResponseApplicationResponse,
} from "@ender/shared/generated/ender.api.leasing.response";
import type {
  ApplicationApplicantType,
  ApplicationEmploymentStatus,
} from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationApplicantTypeEnum,
  ApplicationEmploymentStatusEnum,
} from "@ender/shared/generated/ender.model.leasing";

const applicantTypeDisplayMap: Record<ApplicationApplicantType, string> = {
  [ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT]: "Responsible Occupant",
  [ApplicationApplicantTypeEnum.OTHER_OCCUPANT]: "Other Occupant",
  [ApplicationApplicantTypeEnum.GUARANTOR]: "Guarantor",
};

const responsibleApplicantTypes: ApplicationApplicantType[] = [
  ApplicationApplicantTypeEnum.GUARANTOR,
  ApplicationApplicantTypeEnum.RESPONSIBLE_OCCUPANT,
];

const applicantTypeOptions = R.toEntries(applicantTypeDisplayMap).map<
  SelectOption<ApplicationApplicantType>
>(([value, label]) => ({
  label,
  value,
}));

const applicantEmploymentDisplayMap: Record<
  ApplicationEmploymentStatus,
  string
> = {
  [ApplicationEmploymentStatusEnum.EMPLOYED]: "Employed",
  [ApplicationEmploymentStatusEnum.UNEMPLOYED]: "Unemployed",
  [ApplicationEmploymentStatusEnum.STUDENT]: "Student",
  [ApplicationEmploymentStatusEnum.RETIRED]: "Retired",
  [ApplicationEmploymentStatusEnum.SELF_EMPLOYED]: "Self Employed",
};

const applicantEmploymentOptions = Object.entries(
  applicantEmploymentDisplayMap,
).map<SelectOption<ApplicationEmploymentStatus>>(([value, label]) => ({
  label,
  value: value as ApplicationEmploymentStatus,
}));

/**
 * given an Option of Date, return true if the date is a minor.
 * If the date is None, make no assumptions and return false.
 */
function isBirthdayMinor(birthday: O.Option<LocalDate$.LocalDate>) {
  return birthday.pipe(
    O.map((b) => {
      const threshold = b.add({ years: 18 });
      return LocalDate$.today().isBefore(threshold);
    }),
    O.getOrElse(() => false),
  );
}

const applicantAccordionSummaryText = (
  applicant: Pick<GetApplicationGroupResponseApplicantUserResponse, "birthday">,
  applicantType: ApplicationApplicantType,
) => {
  if (isBirthdayMinor(LocalDate$.parse(applicant.birthday))) {
    return "Minor Occupant";
  }
  return `Applying as ${applicantTypeDisplayMap[applicantType]}`;
};

/**
 * @function applicationPriority
 * @description Sorting for applicants to place them in order by 1) Financial Responsibility, 2) Adult vs Minor, 3) Name
 * @note Returning 1 sorts applicant into higher index in the array; returning -1 sorts applicant into a lower index in the array.
 * @returns {number} The sorting index for applicantA
 */
function applicationPriority(
  {
    applicantType: typeA,
    applicant: { firstName: firstNameA, lastName: lastNameA, birthday: dobA },
  }: GetApplicationGroupResponseApplicationResponse,
  {
    applicantType: typeB,
    applicant: { firstName: firstNameB, lastName: lastNameB, birthday: dobB },
  }: GetApplicationGroupResponseApplicationResponse,
) {
  const nameA = `${firstNameA} ${lastNameA}`;
  const nameB = `${firstNameB} ${lastNameB}`;
  const isApplicantAMinor = !dobA || isBirthdayMinor(LocalDate$.parse(dobA));
  const isApplicantBMinor = !dobB || isBirthdayMinor(LocalDate$.parse(dobB));
  const isAFinanciallyResponsible = responsibleApplicantTypes.includes(typeA);
  const isBFinanciallyResponsible = responsibleApplicantTypes.includes(typeB);

  if (isAFinanciallyResponsible && !isBFinanciallyResponsible) {
    return -1;
  }

  if (!isAFinanciallyResponsible && isBFinanciallyResponsible) {
    return 1;
  }

  if (isApplicantAMinor && !isApplicantBMinor) {
    return 1;
  }

  if (!isApplicantAMinor && isApplicantBMinor) {
    return -1;
  }

  if (nameA.toUpperCase() > nameB.toUpperCase()) {
    return 1;
  }

  if (nameA.toUpperCase() < nameB.toUpperCase()) {
    return -1;
  }

  return 0;
}

export {
  applicantAccordionSummaryText,
  applicantEmploymentDisplayMap,
  applicantEmploymentOptions,
  applicantTypeDisplayMap,
  applicantTypeOptions,
  applicationPriority,
  isBirthdayMinor,
};
