import { Array as A, Predicate as P } from "effect";
import { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import type { GetApprovalPipelineResponse } from "@ender/shared/api/approval";
import type { EnderId } from "@ender/shared/core";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import { EmptyTableRow } from "@ender/shared/ui/empty-table-row";
import { RightRail } from "@ender/shared/ui/right-rail";

import { TitleCompanyApprovalMenu } from "./title-company-approval-menu";

type TitleCompanyApprovalTableItem = Omit<
  GetApprovalPipelineResponse,
  "model"
> & {
  model: {
    id: EnderId;
    name: string;
  };
};

type TitleCompanyApprovalTableProps = {
  items: TitleCompanyApprovalTableItem[];
  isFirstStep?: boolean;
  steps?: GetApprovalProcessResponseStep[];
  stepId?: EnderId;
  needsMyApproval?: boolean;
  refetchData: () => void;
};

function TitleCompanyApprovalTable({
  items,
  isFirstStep = false,
  steps = [],
  stepId,
  needsMyApproval = false,
  refetchData,
}: TitleCompanyApprovalTableProps) {
  const { vendorId } = useParams<{ vendorId: EnderId }>();
  const history = useHistory();

  const titleCompany = useMemo(
    () => items.find((item) => item.model.id === vendorId) || undefined,
    [vendorId, items],
  );

  const closeTitleCompanyApprovalMenu = useCallback(() => {
    history.push("/buy/title-approval-pipeline");
  }, [history]);

  function onSuccess() {
    closeTitleCompanyApprovalMenu();
    refetchData();
  }

  return (
    <div>
      <table className="table--default">
        <thead>
          <tr>
            <th>Title Company Name</th>
          </tr>
        </thead>
        <tbody>
          {A.isEmptyArray(items) && (
            <EmptyTableRow
              colSpan={1}
              message="No Title Companies to display"
            />
          )}
          {items.map((item) => (
            <tr
              key={item.model.id}
              className="cursor"
              onClick={() =>
                history.push(`/buy/title-approval-pipeline/${item.model.id}`)
              }>
              <td>{item.model.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <RightRail
        opened={P.isNotNullable(titleCompany)}
        onClose={closeTitleCompanyApprovalMenu}
        title="Title Company Approval Menu">
        {P.isNotNullable(titleCompany) && (
          <TitleCompanyApprovalMenu
            item={titleCompany as TitleCompanyApprovalTableItem}
            currentState={titleCompany?.currentState}
            needsMyApproval={needsMyApproval}
            steps={steps}
            isFirstStep={isFirstStep}
            stepId={stepId}
            onSuccess={onSuccess}
          />
        )}
      </RightRail>
    </div>
  );
}

export { TitleCompanyApprovalTable };
