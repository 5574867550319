import { Fragment, useMemo } from "react";

import { EnderInputNumber } from "@ender/shared/forms/ui/ender-input-number";
import { EnderInputText } from "@ender/shared/forms/ui/ender-input-text";
import { EnderSelect } from "@ender/shared/forms/ui/ender-select";
import { EnderSlider } from "@ender/shared/forms/ui/ender-slider";
import { EnderSliderRange } from "@ender/shared/forms/ui/ender-slider-range";
import { FormList } from "@ender/shared/forms/ui/form-list";
import { FormSection } from "@ender/shared/forms/ui/form-section";

import { FormInputTypeEnum } from "./form-generator.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenerateInputPropsType = any;

function GenerateList({ inputSchema }: GenerateInputPropsType) {
  const { props, name, initialValue } = inputSchema;

  const stringified = JSON.stringify(initialValue);
  const memoizedInitialValue = useMemo(() => {
    return JSON.parse(stringified);
  }, [stringified]);

  return (
    <FormList initialValue={memoizedInitialValue} name={name}>
      {props.map((val: { name: string; props: unknown }) => (
        // eslint-disable-next-line no-use-before-define
        <Fragment key={val.name}>{generateInput(val)}</Fragment>
      ))}
    </FormList>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateInput(inputSchema: any) {
  const { props, type, name } = inputSchema;
  if (type === FormInputTypeEnum.TEXT) {
    return <EnderInputText {...props} name={name} />;
  } else if (type === FormInputTypeEnum.NUMBER) {
    return <EnderInputNumber {...props} name={name} />;
  } else if (type === FormInputTypeEnum.SLIDER) {
    return <EnderSlider {...props} name={name} />;
  } else if (type === FormInputTypeEnum.SLIDER_RANGE) {
    return <EnderSliderRange {...props} name={name} />;
  } else if (type === FormInputTypeEnum.SELECT) {
    return <EnderSelect {...props} name={name} />;
  } else if (type === FormInputTypeEnum.LIST) {
    return <GenerateList inputSchema={inputSchema} />;
  } else if (type === FormInputTypeEnum.SECTION) {
    return (
      <FormSection name={name}>
        {props.map((val: { name: string; props: unknown }) => (
          <Fragment key={val.name}>{generateInput(val)}</Fragment>
        ))}
      </FormSection>
    );
  } else {
    throw new Error("Invalid input schema");
  }
}

function GenerateInput({ inputSchema }: GenerateInputPropsType) {
  return generateInput(inputSchema);
}

/** @deprecated This should not be used anymore. */
function FormGenerator({ formSchema }: { formSchema: unknown[] }) {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  return formSchema.map((inputSchema: any) => (
    <GenerateInput key={inputSchema.name} inputSchema={inputSchema} />
  ));
}

export { FormGenerator };
