import type { EnderId } from "@ender/shared/core";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";

const CHECK_NUMBER_SEED = 2000;

/**
 * @name getNextAvailableCheckNumbers
 * @description this is facade for functionality that should be handled by the backend
 * find unused integers closest to and equal to or greater than seed number
 * @todo do this in the back end [ENDER-4108]
 * @todo unit tests
 * @param {EnderId} accountId firm bank account id
 * @param {number=} quantity number of check numbers to return
 */
async function getNextAvailableCheckNumbers(accountId: EnderId, quantity = 1) {
  const existingCheckNumbers = (
    await BankingAPI.getBankAccountCheckNumbers({ bankAccountId: accountId })
  )
    .map(Number)
    .filter((checkNumber) => checkNumber >= CHECK_NUMBER_SEED)
    .sort();
  const availableCheckNumbers = [];
  let availableCheckNumber = CHECK_NUMBER_SEED;
  while (availableCheckNumbers.length < quantity) {
    while (existingCheckNumbers.indexOf(availableCheckNumber) > -1) {
      availableCheckNumber++;
    }
    availableCheckNumbers.push(availableCheckNumber);
    availableCheckNumber++;
  }
  return availableCheckNumbers;
}

export { getNextAvailableCheckNumbers };
