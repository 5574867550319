import { IconEdit, IconList } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { useCallback, useContext, useEffect } from "react";

import { NULL } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import { Badge } from "@ender/shared/ds/badge";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3, H4 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import type {
  RenewalsAPICreateDraftingRenewalOffersPayload,
  RenewalsAPIDeleteOfferPayload,
  RenewalsAPIUpdateOfferPayload,
} from "@ender/shared/generated/ender.api.leasing";
import {
  LeasingAPI,
  RenewalsAPI,
} from "@ender/shared/generated/ender.api.leasing";
import type { GetRenewalsRequestRenewalTableTab } from "@ender/shared/generated/ender.api.leasing.request";
import { GetRenewalsRequestRenewalTableTabEnum } from "@ender/shared/generated/ender.api.leasing.request";
import type { Lease } from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

import { EditRenewalOffers } from "./edit-renewal-offers";
import { RenewalOfferAcceptedTable } from "./renewal-offer-accepted-table";
import { RenewalOfferTable } from "./renewal-offer-table";
import { RenewalStatusBadge } from "./renewal-status-badge";
import type { RenewalOffer, RenewalPackageType } from "./renewals.types";
import {
  generateNewOffer,
  getAcceptedOfferDescription,
  updateNewRentPercentage,
} from "./renewals.utils";

const EditNewRenewalOffersStatus = new Set<GetRenewalsRequestRenewalTableTab>([
  GetRenewalsRequestRenewalTableTabEnum.NEEDS_OFFERS,
  GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED,
]);

type RenewalPackageProps = {
  lease: Lease;
  renewalPackage: RenewalPackageType;
  onNewOffersUpdate: Dispatch<SetStateAction<RenewalOffer[]>>;
  refetchLease: () => Promise<unknown>;
  refetchRenewalPackage: () => Promise<unknown>;
};

function EditNewRenewalOffers(props: RenewalPackageProps) {
  const { renewalPackage, onNewOffersUpdate } = props;

  const onCreate = useCallback(() => {
    onNewOffersUpdate((offers) => [
      ...offers,
      generateNewOffer(renewalPackage),
    ]);
  }, [onNewOffersUpdate, renewalPackage]);

  const onUpdate = useCallback(
    (offer: RenewalOffer) => {
      onNewOffersUpdate((offers) =>
        offers.map((o) =>
          o.id === offer.id
            ? updateNewRentPercentage(offer, renewalPackage.currentRent)
            : o,
        ),
      );
    },
    [onNewOffersUpdate, renewalPackage.currentRent],
  );

  const onDelete = useCallback(
    (offer: RenewalOffer) => {
      onNewOffersUpdate((offers) => offers.filter((o) => o.id !== offer.id));
    },
    [onNewOffersUpdate],
  );

  if (!EditNewRenewalOffersStatus.has(renewalPackage.renewalStatus)) {
    return NULL;
  }

  return (
    <Stack>
      {/* TODO: Need Notes from the BE */}
      {/* {renewalPackage.renewalStatus === GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED && (
        <Stack spacing={Spacing.xs}>
          <p style={{ color: "var(--color-gray-600)" }}>Notes from Leasing Agent</p>
          <p style={{ maxWidth: "40rem" }}>TODO: Need Notes from the BE</p>
        </Stack>
      )} */}
      {A.isNonEmptyArray(renewalPackage.previousRenewalOffers ?? []) && (
        <Stack>
          <H4>Offer History</H4>
          <RenewalOfferTable offers={renewalPackage.previousRenewalOffers} />
        </Stack>
      )}
      <Stack>
        <H4>New Offers</H4>
        <EditRenewalOffers
          leaseEndDate={renewalPackage.leaseEndDate}
          offers={renewalPackage.newRenewalOffers}
          onCreate={onCreate}
          onNewEndDateUpdate={onUpdate}
          onNewRentUpdate={onUpdate}
          onProcessingFeeUpdate={onUpdate}
          onDelete={onDelete}
        />
      </Stack>
    </Stack>
  );
}

function EditCurrentRenewalOffers(props: RenewalPackageProps) {
  const { lease, renewalPackage, refetchRenewalPackage } = props;

  const { mutateAsync: createOffers, isLoading: isCreating } = useMutation({
    mutationFn: (payload: RenewalsAPICreateDraftingRenewalOffersPayload) =>
      RenewalsAPI.createDraftingRenewalOffers(payload).then(() =>
        refetchRenewalPackage(),
      ),
    mutationKey: [
      "RenewalsAPI.createDraftingRenewalOffers",
      refetchRenewalPackage,
    ],
  });

  const { mutateAsync: updateOffer, isLoading: isUpdating } = useMutation({
    mutationFn: (payload: RenewalsAPIUpdateOfferPayload) =>
      RenewalsAPI.updateOffer(payload).then(() => {
        showSuccessNotification({ autoClose: 2000, message: "Offer updated" });
        return refetchRenewalPackage();
      }),
    mutationKey: ["RenewalsAPI.updateOffer", refetchRenewalPackage],
  });

  const { mutateAsync: deleteOffer, isLoading: isDeleting } = useMutation({
    mutationFn: (payload: RenewalsAPIDeleteOfferPayload) =>
      RenewalsAPI.deleteOffer(payload).then(() => refetchRenewalPackage()),
    mutationKey: ["RenewalsAPI.deleteOffer", refetchRenewalPackage],
  });

  const isLoading = isCreating || isUpdating || isDeleting;

  const onCreate = useCallback(() => {
    createOffers({
      draftingRenewalOffers: [generateNewOffer(renewalPackage)],
      leaseId: lease.id,
    });
  }, [createOffers, lease.id, renewalPackage]);
  const onUpdate = useCallback(
    (offer: RenewalOffer) => updateOffer({ offerId: offer.id, ...offer }),
    [updateOffer],
  );
  const onDelete = useCallback(
    (offer: RenewalOffer) => deleteOffer({ offerId: offer.id }),
    [deleteOffer],
  );

  return (
    <Stack>
      <EditRenewalOffers
        leaseEndDate={renewalPackage.leaseEndDate}
        offers={renewalPackage.currentRenewalOffers}
        onCreate={onCreate}
        onNewEndDateUpdate={onUpdate}
        onNewRentUpdate={onUpdate}
        onProcessingFeeUpdate={onUpdate}
        onDelete={onDelete}
        disabled={isLoading}
      />
    </Stack>
  );
}

const ListRenewalPackageStatuses = new Set<GetRenewalsRequestRenewalTableTab>([
  GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS,
  GetRenewalsRequestRenewalTableTabEnum.AWAITING_RESPONSE,
  GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING,
]);

const CurrentOffersLabel: Partial<
  Record<GetRenewalsRequestRenewalTableTab, ReactNode>
> = {
  [GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS]: "New Offers",
  [GetRenewalsRequestRenewalTableTabEnum.AWAITING_RESPONSE]: "Sent Offers",
  [GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING]: "Rejected Offers",
} as const;

function ListRenewalPackage(props: RenewalPackageProps) {
  const { renewalPackage } = props;
  const { hasPermissions } = useContext(UserContext);
  const canEdit = hasPermissions(FunctionalPermissionEnum.SET_RENEWAL_RENT);
  const [editing, setEditing] = useBoolean(false);

  const { setFalse } = setEditing;
  // toggle off editing when status changes
  useEffect(() => {
    if (
      renewalPackage.renewalStatus !==
      GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS
    ) {
      setFalse();
    }
  }, [renewalPackage.renewalStatus, setFalse]);

  if (!ListRenewalPackageStatuses.has(renewalPackage.renewalStatus)) {
    return NULL;
  }

  return (
    <Stack>
      {A.isNonEmptyArray(renewalPackage.previousRenewalOffers ?? []) && (
        <Stack>
          <H4>Offer History</H4>
          <RenewalOfferTable offers={renewalPackage.previousRenewalOffers} />
        </Stack>
      )}
      <Stack>
        <Group justify={Justify.between}>
          <H4>
            <Group>
              {CurrentOffersLabel[renewalPackage.renewalStatus]}
              {renewalPackage.renewalStatus ===
                GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING &&
                P.isNotNullable(renewalPackage.moveOutReason) && (
                  <Badge color={Color.red}>
                    {convertSnakeCaseToTitleCase(renewalPackage.moveOutReason)}
                  </Badge>
                )}
            </Group>
          </H4>
          {canEdit &&
            renewalPackage.renewalStatus ===
              GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS &&
            (editing ? (
              <Button
                variant={ButtonVariant.transparent}
                leftSection={<IconList />}
                onClick={setEditing.setFalse}>
                List
              </Button>
            ) : (
              <Button
                variant={ButtonVariant.transparent}
                leftSection={<IconEdit />}
                onClick={setEditing.setTrue}>
                Edit
              </Button>
            ))}
        </Group>
        {editing ? (
          <EditCurrentRenewalOffers {...props} />
        ) : (
          <RenewalOfferTable offers={renewalPackage.currentRenewalOffers} />
        )}
      </Stack>
    </Stack>
  );
}

function AcceptedOffer(props: RenewalPackageProps) {
  const { renewalPackage } = props;

  if (
    renewalPackage.renewalStatus !==
    GetRenewalsRequestRenewalTableTabEnum.ACCEPTED
  ) {
    return NULL;
  }

  const acceptedOffer: RenewalOffer | undefined =
    renewalPackage.currentRenewalOffers.find((offer) =>
      P.isNotNullable(offer.acceptedTimestamp),
    );

  const unacceptedOffers: RenewalOffer[] =
    renewalPackage.currentRenewalOffers.filter(
      (offer) => !P.isNotNullable(offer.acceptedTimestamp),
    );

  const sortedPreviousOffers = [...renewalPackage.previousRenewalOffers].sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
  );

  const allOffers: RenewalOffer[] = [
    acceptedOffer,
    ...unacceptedOffers,
    ...sortedPreviousOffers,
  ].filter(P.isNotNullable);

  return (
    <Stack>
      <H4>Offer Accepted: {getAcceptedOfferDescription(renewalPackage)}</H4>
      <RenewalOfferAcceptedTable offers={allOffers} />
    </Stack>
  );
}

function RenewalPackage(props: RenewalPackageProps) {
  return (
    <Stack>
      <Group align={Align.center}>
        <H3>Renewal Package</H3>
        <RenewalStatusBadge status={props.renewalPackage.renewalStatus} />
      </Group>
      <EditNewRenewalOffers {...props} />
      <ListRenewalPackage {...props} />
      <AcceptedOffer {...props} />
    </Stack>
  );
}

function DraftedRenewalPackage(props: RenewalPackageProps) {
  const { lease, refetchRenewalPackage } = props;
  const confirm = useConfirmationContext();

  const onExecuteLease = useCallback(() => {
    confirm({
      title: "Are you sure you want to execute this lease?",
      confirmButtonLabel: "Yes, execute lease",
      cancelButtonLabel: "No",
    }).then(() =>
      LeasingAPI.executeLease({ leaseId: lease.id })
        .then(() => refetchRenewalPackage())
        .then(() => showSuccessNotification({ message: "Lease executed" })),
    );
  }, [confirm, lease.id, refetchRenewalPackage]);

  return (
    <Stack>
      <Group align={Align.center}>
        <H3>Renewal Package</H3>
        <RenewalStatusBadge
          status={GetRenewalsRequestRenewalTableTabEnum.DRAFTED_LEASES}
        />
      </Group>
      <Group>
        <Button onClick={onExecuteLease}>Execute Lease</Button>
      </Group>
    </Stack>
  );
}

export { DraftedRenewalPackage, RenewalPackage };
