import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskTaskStatusGroupingValues = ["OPEN", "PAYING", "CLOSED"] as const;

const TaskTaskStatusGroupingEffectSchema = Schema.Literal(
  ...TaskTaskStatusGroupingValues,
);

type TaskTaskStatusGrouping = Schema.Schema.Type<
  typeof TaskTaskStatusGroupingEffectSchema
>;

const TaskTaskStatusGroupingEnum = castEnum(TaskTaskStatusGroupingEffectSchema);

function randomTaskTaskStatusGrouping(): TaskTaskStatusGrouping {
  return rand(TaskTaskStatusGroupingValues);
}

export {
  randomTaskTaskStatusGrouping,
  TaskTaskStatusGroupingEffectSchema,
  TaskTaskStatusGroupingEnum,
  TaskTaskStatusGroupingValues,
};
export type { TaskTaskStatusGrouping };
