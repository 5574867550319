/**
 * HTTP Method: GET
 * Pathname: /invoices/{invoiceId}/allocation/history
 * @function getInvoiceAllocationHistory
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoiceAllocationHistoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InvoiceAllocationHistoryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvoiceAllocationHistoryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoiceAllocationHistoryPathParams = {
  invoiceId: EnderId;
};

type InvoicesAPIGetInvoiceAllocationHistorySearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetInvoiceAllocationHistoryPayload =
  InvoicesAPIGetInvoiceAllocationHistoryPathParams &
    InvoicesAPIGetInvoiceAllocationHistorySearchParams;

function getInvoiceAllocationHistoryUnsafeEffect(
  payload: InvoicesAPIGetInvoiceAllocationHistoryPayload,
) {
  const { invoiceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InvoiceAllocationHistoryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<InvoiceAllocationHistoryResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/invoices/${invoiceId}/allocation/history`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoiceAllocationHistory(
  payload: InvoicesAPIGetInvoiceAllocationHistoryPayload,
  options?: { signal?: AbortSignal },
): Promise<InvoiceAllocationHistoryResponse[]> {
  return F.pipe(
    payload,
    getInvoiceAllocationHistoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoiceAllocationHistory };
export type {
  InvoicesAPIGetInvoiceAllocationHistoryPathParams,
  InvoicesAPIGetInvoiceAllocationHistoryPayload,
  InvoicesAPIGetInvoiceAllocationHistorySearchParams,
};
