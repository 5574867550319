import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const MoveInConfigRowCapturedDataTypeValues = [
  "NONE",
  "TEXT",
  "DATE",
  "BOOLEAN",
  "MONEY",
  "NUMBER",
] as const;

const MoveInConfigRowCapturedDataTypeEffectSchema = Schema.Literal(
  ...MoveInConfigRowCapturedDataTypeValues,
);

type MoveInConfigRowCapturedDataType = Schema.Schema.Type<
  typeof MoveInConfigRowCapturedDataTypeEffectSchema
>;

const MoveInConfigRowCapturedDataTypeEnum = castEnum(
  MoveInConfigRowCapturedDataTypeEffectSchema,
);

function randomMoveInConfigRowCapturedDataType(): MoveInConfigRowCapturedDataType {
  return rand(MoveInConfigRowCapturedDataTypeValues);
}

export {
  MoveInConfigRowCapturedDataTypeEffectSchema,
  MoveInConfigRowCapturedDataTypeEnum,
  MoveInConfigRowCapturedDataTypeValues,
  randomMoveInConfigRowCapturedDataType,
};
export type { MoveInConfigRowCapturedDataType };
