/**
 * HTTP Method: POST
 * Pathname: /setupLeasing
 * @function setupLeasing
 * @memberof LeasingAPI
 * @param {LeasingAPISetupLeasingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISetupLeasingSearchParams = {
  token?: string | undefined;
};

type LeasingAPISetupLeasingBodyParams = {
  city: string;
  line1: string;
  line2: string;
  phone?: string | undefined;
  state: string;
  userId: EnderId;
  zipcode: string;
};

type LeasingAPISetupLeasingPayload = LeasingAPISetupLeasingSearchParams &
  LeasingAPISetupLeasingBodyParams;

function setupLeasingUnsafeEffect(payload: LeasingAPISetupLeasingPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/setupLeasing",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setupLeasing(
  payload: LeasingAPISetupLeasingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setupLeasingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setupLeasing };
export type {
  LeasingAPISetupLeasingBodyParams,
  LeasingAPISetupLeasingPayload,
  LeasingAPISetupLeasingSearchParams,
};
