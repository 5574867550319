import { Schema } from "@effect/schema";
import { Array as A, Option as O, Order, Predicate as P } from "effect";
import { useEffect, useMemo } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import { INVOICE_PAYABLE_TYPE_DISPLAY_NAMES } from "@ender/shared/constants/invoices";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text, TextColor } from "@ender/shared/ds/text";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { BatchedInvoiceActionResponseStatus } from "@ender/shared/generated/ender.model.accounting.response";
import { BatchedInvoiceActionResponseStatusEnum } from "@ender/shared/generated/ender.model.accounting.response";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";
import {
  InvoicePayableTypeEnum,
  InvoicePayableTypeValues,
} from "@ender/shared/generated/ender.model.payments.invoice";
import { castEnum } from "@ender/shared/utils/effect";
import { fail } from "@ender/shared/utils/error";
import { isMultiple } from "@ender/shared/utils/is";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { FormMultiFilterSync } from "@ender/widgets/filters/multi-filter-sync";

import type { ApprovalProcessHybridId } from "../types";
import { NEW_APPROVAL_PROCESS_HYBRID_ID } from "../types";

const ParameterValues = ["LESS_THAN", "MORE_THAN", "BETWEEN"] as const;
const ParameterEffectSchema = Schema.Literal(...ParameterValues);
const ParameterEnum = castEnum(ParameterEffectSchema);

const PayableTypeSelectOptionSchema = Schema.Struct({
  label: Schema.String,
  value: Schema.Enums(InvoicePayableTypeEnum),
});

const ApprovalProcessRuleFiltersFormSchema = Schema.Struct({
  maxAmount: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  minAmount: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  parameter: ParameterEffectSchema.pipe(Schema.OptionFromSelf),
  payableTypes: Schema.Array(PayableTypeSelectOptionSchema).pipe(
    Schema.mutable,
  ),
}).pipe(
  Schema.filter((values) => {
    if (
      (values.parameter.pipe(O.getOrNull) === ParameterEnum.MORE_THAN ||
        values.parameter.pipe(O.getOrNull) === ParameterEnum.BETWEEN) &&
      (O.isNone(values.minAmount) ||
        values.minAmount.pipe(
          O.map((v) => Money$.isNegative(v)),
          O.getOrThrow,
        ))
    ) {
      return {
        message:
          "Please enter a valid number equal to or greater than zero for minimum amount.",
        path: ["minAmount"],
      };
    }
    if (
      (values.parameter.pipe(O.getOrNull) === ParameterEnum.LESS_THAN ||
        values.parameter.pipe(O.getOrNull) === ParameterEnum.BETWEEN) &&
      (O.isNone(values.maxAmount) ||
        values.maxAmount.pipe(
          O.map((v) => Money$.isNegative(v) || Money$.isZero(v)),
          O.getOrThrow,
        ))
    ) {
      return {
        message:
          "Please enter a valid number greater than zero for maximum amount.",
        path: ["maxAmount"],
      };
    }
    if (
      values.parameter.pipe(O.getOrNull) === ParameterEnum.BETWEEN &&
      values.maxAmount.pipe(
        O.map((v) =>
          Order.greaterThanOrEqualTo(Money$.Order)(
            values.minAmount.pipe(O.getOrThrow),
            v,
          ),
        ),
        O.getOrThrow,
      )
    ) {
      return {
        message: "Maximum amount must be greater than minimum amount.",
        path: ["maxAmount"],
      };
    }
  }),
);

type ApprovalProcessRuleFiltersFormValues = Schema.Schema.Type<
  typeof ApprovalProcessRuleFiltersFormSchema
>;

function getParameterFromAmounts(minAmount: unknown, maxAmount: unknown) {
  if (minAmount && maxAmount) {
    return O.some(ParameterEnum.BETWEEN);
  }
  if (maxAmount) {
    return O.some(ParameterEnum.LESS_THAN);
  }
  return O.some(ParameterEnum.MORE_THAN);
}

/**
 * This allows us to retain user-set values in the form object
 * when the user switches "parameter" from "between" to "less than" or "more than"
 * but not accidentally send the wrong payload to the API.
 */
function getAmountPartOfPayload({
  maxAmount,
  minAmount,
  parameter,
}: Pick<
  ApprovalProcessRuleFiltersFormValues,
  "minAmount" | "maxAmount" | "parameter"
>) {
  if (parameter.pipe(O.getOrNull) === ParameterEnum.BETWEEN) {
    return {
      maxAmount: maxAmount.pipe(
        O.map((v) => v.toJSON()),
        O.getOrThrow,
      ),
      minAmount: minAmount.pipe(
        O.map((v) => v.toJSON()),
        O.getOrThrow,
      ),
    };
  } else if (parameter.pipe(O.getOrNull) === ParameterEnum.LESS_THAN) {
    return {
      maxAmount: maxAmount.pipe(
        O.map((v) => v.toJSON()),
        O.getOrThrow,
      ),
      minAmount: UNDEFINED,
    };
  } else {
    return {
      minAmount: minAmount.pipe(
        O.map((v) => v.toJSON()),
        O.getOrThrow,
      ),
      maxAmount: UNDEFINED,
    };
  }
}

function getPayloadCommonToCreateAndUpdate(
  values: ApprovalProcessRuleFiltersFormValues,
) {
  const { minAmount, maxAmount, parameter, payableTypes } = values;

  const payablesTypeArray = payableTypes.map(
    (payableType) => payableType.value,
  );

  return {
    payableTypes: payablesTypeArray,
    ...getAmountPartOfPayload({ maxAmount, minAmount, parameter }),
  };
}

function getResponseStatus(response: unknown) {
  return P.isNotNullable(response)
    ? { status: BatchedInvoiceActionResponseStatusEnum.SUCCESS }
    : { status: BatchedInvoiceActionResponseStatusEnum.FAILURE };
}

async function handleResponse(
  response: {
    status: BatchedInvoiceActionResponseStatus;
    overlappingApprovalProcessId?: string;
  },
  successMessage: string,
  onSuccess: () => Promise<void>,
) {
  if (response?.status === BatchedInvoiceActionResponseStatusEnum.FAILURE) {
    let message = response.status as string;
    if (response.overlappingApprovalProcessId) {
      message += ` overlaps with existing approval process (id: ${response.overlappingApprovalProcessId})`;
    }
    fail(message);
  } else {
    showSuccessNotification({ message: successMessage });
    if (onSuccess) {
      await onSuccess();
    }
  }
}

type ApprovalProcessRuleFiltersFormProps = {
  approvalProcess: GetApprovalProcessResponse & { id: ApprovalProcessHybridId };
  type: ApprovalProcessType;
  onCreateSuccess: ({
    approvalProcessId,
  }: {
    approvalProcessId: ApprovalProcessHybridId;
  }) => void;
  refetchData: () => Promise<void>;
};

function ApprovalProcessRuleFiltersForm({
  approvalProcess,
  type,
  onCreateSuccess,
  refetchData,
}: ApprovalProcessRuleFiltersFormProps) {
  const isUpdate = useMemo(
    () =>
      approvalProcess?.id &&
      approvalProcess?.id !== NEW_APPROVAL_PROCESS_HYBRID_ID,
    [approvalProcess],
  );

  const form = useEffectSchemaForm({
    defaultValues: {
      maxAmount: Money$.parse(approvalProcess?.maxAmount),
      minAmount: Money$.parse(approvalProcess?.minAmount),
      parameter: getParameterFromAmounts(
        approvalProcess?.minAmount,
        approvalProcess?.maxAmount,
      ),
      payableTypes:
        approvalProcess?.payableTypes?.map((payableType) => ({
          label: convertSnakeCaseToTitleCase(payableType),
          value: payableType,
        })) ?? [],
    },
    schema: ApprovalProcessRuleFiltersFormSchema,
  });

  const { parameter, payableTypes } = form.watch();
  const { setValue } = form;

  // if parameter changes, reset minAmount and maxAmount to initialValues
  useEffect(() => {
    setValue("maxAmount", Money$.parse(approvalProcess?.maxAmount));
    setValue("minAmount", Money$.parse(approvalProcess?.minAmount));
  }, [parameter, setValue, approvalProcess]);

  async function handleSubmit(values: ApprovalProcessRuleFiltersFormValues) {
    const commonPayload = getPayloadCommonToCreateAndUpdate(values);
    try {
      const response = isUpdate
        ? await ApprovalsAPI.updateApprovalProcess({
            approvalProcessId: approvalProcess.id as EnderId,
            ...commonPayload,
          })
        : await ApprovalsAPI.createApprovalProcess({
            ...commonPayload,
            type,
          });

      // Added responseStatus check because response is of type ApprovalProcess which has no status
      // BatchedInvoiceActionResponseStatusEnum is not domain appropriate here
      const responseStatus = getResponseStatus(response);

      await handleResponse(
        responseStatus,
        isUpdate
          ? "Successfully updated rule"
          : "Successfully created new rule",
        async () => {
          await refetchData();
          if (!isUpdate && response) {
            onCreateSuccess({ approvalProcessId: response.id });
          }
        },
      );
    } catch (err) {
      fail(err);
    }
  }

  const payableTypesLabel = useMemo(() => {
    if (A.isNonEmptyArray(payableTypes)) {
      return isMultiple(payableTypes)
        ? `${payableTypes[0].label} +${payableTypes.length - 1}`
        : payableTypes[0].label;
    }
    return "Select Transaction Type";
  }, [payableTypes]);

  const showMoreThan = useMemo(() => {
    return (
      parameter.pipe(O.getOrNull) === ParameterEnum.MORE_THAN ||
      parameter.pipe(O.getOrNull) === ParameterEnum.BETWEEN
    );
  }, [parameter]);

  const showLessThan = useMemo(() => {
    return (
      parameter.pipe(O.getOrNull) === ParameterEnum.LESS_THAN ||
      parameter.pipe(O.getOrNull) === ParameterEnum.BETWEEN
    );
  }, [parameter]);

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        {type === ApprovalProcessTypeEnum.PAYABLE && (
          <Text color={TextColor["slate-500"]} size={FontSize.sm}>
            Please set at least one of the following options.
          </Text>
        )}
        {type === ApprovalProcessTypeEnum.PAYABLE && (
          <Group spacing={Spacing.xs} align={Align.center}>
            <span>Transaction type is</span>
            <FormMultiFilterSync
              data={InvoicePayableTypeValues.map((value) => ({
                label: INVOICE_PAYABLE_TYPE_DISPLAY_NAMES[value],
                value,
              }))}
              name="payableTypes"
              form={form}
              label={payableTypesLabel}
            />
          </Group>
        )}
        <Group spacing={Spacing.xs} align={Align.center}>
          <span>Invoice amount is</span>
          <FormSelect
            name="parameter"
            form={form}
            clearable={false}
            data={ParameterValues.map((value) => ({
              label: convertSnakeCaseToTitleCase(value),
              value,
            }))}
            placeholder="Select Parameter"
          />
          {showMoreThan && (
            <FormMoneyInput
              aria-label="Minimum Amount"
              name="minAmount"
              placeholder="Minimum Amount"
              form={form}
            />
          )}
          {parameter.pipe(O.getOrNull) === ParameterEnum.BETWEEN && (
            <span>and</span>
          )}
          {showLessThan && (
            <FormMoneyInput
              aria-label="Maximum Amount"
              name="maxAmount"
              placeholder="Maximum Amount"
              form={form}
            />
          )}
        </Group>
        <Group justify={Justify.end}>
          <Button
            type="submit"
            //disable button as MultiFilterSelect has no error field for proper validation
            disabled={
              type === ApprovalProcessTypeEnum.PAYABLE &&
              A.isEmptyArray(payableTypes)
            }
            disabledTooltip="Please select at least one transaction type.">
            {isUpdate ? "Update" : "Create"}
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { ApprovalProcessRuleFiltersForm };
