import { Option as O, Order, pipe } from "effect";

import { LocalDate$ } from "@ender/shared/core";
import type { ObjectValues } from "@ender/shared/types/general";

import type { UpdatedInsurancePolicy } from "./insurance.types";

/**
 * @enum {string} InsurancePolicyStatusEnum
 */
const InsurancePolicyStatusEnum = {
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
  UPCOMING: "UPCOMING",
} as const;

type InsurancePolicyStatus = ObjectValues<typeof InsurancePolicyStatusEnum>;

function getInsurancePolicyStatus(
  policy: UpdatedInsurancePolicy,
): InsurancePolicyStatus {
  const { effectiveDate, expirationDate } = policy;

  function isDateIn(
    val: O.Option<LocalDate$.LocalDate>,
    type: "PAST" | "FUTURE",
  ) {
    return pipe(
      val,
      O.map((date) =>
        type === "FUTURE"
          ? Order.greaterThan(LocalDate$.Order)(date, LocalDate$.today())
          : Order.greaterThan(LocalDate$.Order)(LocalDate$.today(), date),
      ),
      O.getOrThrow,
    );
  }

  if (isDateIn(effectiveDate, "FUTURE")) {
    return InsurancePolicyStatusEnum.UPCOMING;
  }

  if (isDateIn(expirationDate, "PAST")) {
    return InsurancePolicyStatusEnum.EXPIRED;
  }

  return InsurancePolicyStatusEnum.ACTIVE;
}

export { getInsurancePolicyStatus, InsurancePolicyStatusEnum };
export type { InsurancePolicyStatus };
