import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const AuditTrailServiceOperationValues = [
  "UPDATE",
  "CREATE",
  "DELETE",
] as const;

const AuditTrailServiceOperationEffectSchema = Schema.Literal(
  ...AuditTrailServiceOperationValues,
);

type AuditTrailServiceOperation = Schema.Schema.Type<
  typeof AuditTrailServiceOperationEffectSchema
>;

const AuditTrailServiceOperationEnum = castEnum(
  AuditTrailServiceOperationEffectSchema,
);

function randomAuditTrailServiceOperation(): AuditTrailServiceOperation {
  return rand(AuditTrailServiceOperationValues);
}

export {
  AuditTrailServiceOperationEffectSchema,
  AuditTrailServiceOperationEnum,
  AuditTrailServiceOperationValues,
  randomAuditTrailServiceOperation,
};
export type { AuditTrailServiceOperation };
