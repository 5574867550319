import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GroupedGLCategoryBalancesReportProviderGroupByTypeValues = [
  "FIRM",
  "MARKET",
  "CITY",
  "STATE",
  "ZIP_CODE",
  "PROPERTY",
  "UNIT",
  "LEASE",
  "FUND",
] as const;

const GroupedGLCategoryBalancesReportProviderGroupByTypeEffectSchema =
  Schema.Literal(...GroupedGLCategoryBalancesReportProviderGroupByTypeValues);

type GroupedGLCategoryBalancesReportProviderGroupByType = Schema.Schema.Type<
  typeof GroupedGLCategoryBalancesReportProviderGroupByTypeEffectSchema
>;

const GroupedGLCategoryBalancesReportProviderGroupByTypeEnum = castEnum(
  GroupedGLCategoryBalancesReportProviderGroupByTypeEffectSchema,
);

function randomGroupedGLCategoryBalancesReportProviderGroupByType(): GroupedGLCategoryBalancesReportProviderGroupByType {
  return rand(GroupedGLCategoryBalancesReportProviderGroupByTypeValues);
}

export {
  GroupedGLCategoryBalancesReportProviderGroupByTypeEffectSchema,
  GroupedGLCategoryBalancesReportProviderGroupByTypeEnum,
  GroupedGLCategoryBalancesReportProviderGroupByTypeValues,
  randomGroupedGLCategoryBalancesReportProviderGroupByType,
};
export type { GroupedGLCategoryBalancesReportProviderGroupByType };
