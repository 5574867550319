/**
 * HTTP Method: POST
 * Pathname: /buy/boxes
 * @function createBuyBox
 * @memberof BuyBoxAPI
 * @param {BuyBoxAPICreateBuyBoxPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import type { BuyBoxBuyBoxType } from "@ender/shared/generated/com.ender.buy.model.misc";
import type { PropertyHomeType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyBoxAPICreateBuyBoxSearchParams = {
  token?: string | undefined;
};

type BuyBoxAPICreateBuyBoxBodyParams = {
  excludeCities: string[];
  excludeZipcodes: string[];
  firmId: EnderId;
  marketId: EnderId;
  maxListPrice?: SerializesInto<Money> | undefined;
  maxLotSqft?: number | undefined;
  minBaths: number;
  minBeds: number;
  minCapRate: SerializesInto<Percent>;
  minListPrice: SerializesInto<Money>;
  minLotSqft?: number | undefined;
  minMedianIncome: SerializesInto<Money>;
  minRent: SerializesInto<Money>;
  /**
   * One of A+, A, A-, ... D+, D, D-, F
   */
  minSchoolRating: string;
  minSqft: number;
  minYearBuilt: number;
  name: string;
  propertyTypes: PropertyHomeType[];
  type?: BuyBoxBuyBoxType | undefined;
};

type BuyBoxAPICreateBuyBoxPayload = BuyBoxAPICreateBuyBoxSearchParams &
  BuyBoxAPICreateBuyBoxBodyParams;

function createBuyBoxUnsafeEffect(payload: BuyBoxAPICreateBuyBoxPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/buy/boxes",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createBuyBox(
  payload: BuyBoxAPICreateBuyBoxPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    createBuyBoxUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createBuyBox };
export type {
  BuyBoxAPICreateBuyBoxBodyParams,
  BuyBoxAPICreateBuyBoxPayload,
  BuyBoxAPICreateBuyBoxSearchParams,
};
