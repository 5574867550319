import type { ReactNode } from "react";

import { Tooltip } from "@ender/shared/ds/tooltip";

type EllipsisProps = {
  tooltip?: string;
  children: ReactNode;
};

/**
 * @deprecated this should be made into a utility that lives within tables
 * as its primary function is to control visibility of content within a cell
 */
function Ellipsis({ tooltip, children }: EllipsisProps) {
  return (
    <Tooltip label={tooltip || children}>
      <span className="truncate max-w-full text-xs/normal">{children}</span>
    </Tooltip>
  );
}

export { Ellipsis };
