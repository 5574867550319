/**
 * HTTP Method: POST
 * Pathname: /middleLayer/applications/applicationsTableStatusCount
 * @function applicationsTableStatusCount
 * @memberof ApplicationsMiddleLayerAPI
 * @param {ApplicationsMiddleLayerAPIApplicationsTableStatusCountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ApplicationsTableStatusCountResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SearchApplicationsPageRequestApplicationsPageTab } from "@ender/shared/generated/com.ender.middle.request";
import type { ApplicationsTableStatusCountResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsMiddleLayerAPIApplicationsTableStatusCountSearchParams = {
  token?: string | undefined;
};

type ApplicationsMiddleLayerAPIApplicationsTableStatusCountBodyParams = {
  applicantNameOrPhone?: string | undefined;
  marketIds?: EnderId[] | undefined;
  onlyShowNeedsFollowup?: boolean | undefined;
  ownershipGroupIds?: EnderId[] | undefined;
  propertyIds?: EnderId[] | undefined;
  tab: SearchApplicationsPageRequestApplicationsPageTab;
  usingHousingChoiceVoucher?: boolean | undefined;
};

type ApplicationsMiddleLayerAPIApplicationsTableStatusCountPayload =
  ApplicationsMiddleLayerAPIApplicationsTableStatusCountSearchParams &
    ApplicationsMiddleLayerAPIApplicationsTableStatusCountBodyParams;

function applicationsTableStatusCountUnsafeEffect(
  payload: ApplicationsMiddleLayerAPIApplicationsTableStatusCountPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ApplicationsTableStatusCountResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ApplicationsTableStatusCountResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/applications/applicationsTableStatusCount",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function applicationsTableStatusCount(
  payload: ApplicationsMiddleLayerAPIApplicationsTableStatusCountPayload,
  options?: { signal?: AbortSignal },
): Promise<ApplicationsTableStatusCountResponse> {
  return F.pipe(
    payload,
    applicationsTableStatusCountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { applicationsTableStatusCount };
export type {
  ApplicationsMiddleLayerAPIApplicationsTableStatusCountBodyParams,
  ApplicationsMiddleLayerAPIApplicationsTableStatusCountPayload,
  ApplicationsMiddleLayerAPIApplicationsTableStatusCountSearchParams,
};
