import { RingProgress, Stepper } from "@mantine/core";
import { IconCheck, IconQuestionMark } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { clsx } from "clsx";
import { Option as O, Predicate as P } from "effect";
import type { FC } from "react";
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRouteMatch } from "react-router-dom";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import { EnderAPI } from "@ender/shared/generated/ender.api.misc";
import { fail } from "@ender/shared/utils/error";

import { ApplyWorkflowHeader } from "./apply-header";
import type { ApplyStep } from "./apply-utils";
import { findFirstIncompleteStep } from "./apply-utils";
import { ApplyStepCredit } from "./steps/step-credit";
import { ApplyStepFee } from "./steps/step-fee";
import { ApplyStepIncome } from "./steps/step-income";
import { ApplyStepOccupants } from "./steps/step-occupants";
import { ApplyStepPersonal } from "./steps/step-personal";
import type { ApplyStepProps } from "./steps/step-props";
import { ApplyStepSummary } from "./steps/step-summary";

import styles from "./apply-page.module.css";

const ApplyStepLabelMap: Record<ApplyStep, string> = {
  PERSONAL: "Personal Information",
  OCCUPANTS: "Occupant Information",
  INCOME: "Income & Identity",
  FEE: "Application Fee",
  CREDIT: "Credit & Background",
};

const ApplyStepComponentsMap: Record<ApplyStep, FC<ApplyStepProps>> = {
  PERSONAL: ApplyStepPersonal,
  OCCUPANTS: ApplyStepOccupants,
  INCOME: ApplyStepIncome,
  FEE: ApplyStepFee,
  CREDIT: ApplyStepCredit,
};

const defaultOrderedSteps: ApplyStep[] = [
  "PERSONAL",
  "OCCUPANTS",
  "INCOME",
  "CREDIT",
  "FEE",
];

/**
 * mantine curves have a dumb calculation that doesn't produce the intended width of the svg:
 * radius = (size * 0.9 - thickness * 2) / 2
 * this equation attempts to reverse that so we get a circle with exact dimensions
 * radius is 22
 * thickness is 4.4
 */
const size = (44 + 4.4 * 2) / 0.9;

/**
 * @description Workflow enabling a user to apply to a unit
 * @returns {React.ReactElement}
 */
function ApplyPage() {
  const match = useRouteMatch<{ unitId: EnderId }>("/units/:unitId/apply");
  const { unitId } = match?.params ?? {};

  const [orderedSteps, setOrderedSteps] =
    useState<ApplyStep[]>(defaultOrderedSteps);
  /**
   * implicit flow:
   * currentStep represents the user's desired step. This can be set by navigating using the stepper,
   * or by completing individual steps.
   *
   * furthestAccessibleStep represents the highest step the user is able to view. If the user has expressed intent to view
   * a higher step than is allowed, we can use these two values to compute loading states or disabled state.
   *
   * The active step must always be equal to `Math.min(currentStep, furthestAccessibleStep)`
   * if currentStep > furthestAccessibleStep:
   * furthestAccessibleStep has a loading icon to indicate it is hanging
   */
  const [currentStep, setCurrentStep] = useState<number>(-1);

  const {
    data: applyInfo,
    isLoading: isFetching,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ["UnitsAPI.getApplyInfo", unitId] as const,
    //@ts-expect-error unitId is not nullable due to enabled property
    queryFn: ({ signal }) => UnitsAPI.getApplyInfo({ unitId }, { signal }),
    refetchOnWindowFocus: false,
    enabled: P.isNotNullable(unitId),
    select: (data) => {
      // if both the listing and the appGroup have no fee, we don't need to show the fee step
      const hasNoFee: boolean =
        P.isNullable(
          data.applicationGroupResponse?.applicationGroup.feeAmount,
        ) ||
        ((P.isNullable(
          data.applicationGroupResponse?.applicationGroup.feeAmount,
        ) ||
          O.isNone(
            Money$.parse(
              data.applicationGroupResponse.applicationGroup.feeAmount,
            ),
          )) &&
          (P.isNullable(data.listing?.applicationFee) ||
            O.isNone(Money$.parse(data.listing.applicationFee))));

      const stepsToUse: ApplyStep[] = hasNoFee
        ? ["PERSONAL", "OCCUPANTS", "INCOME", "CREDIT", "FEE"]
        : defaultOrderedSteps;

      const application = data.applicationGroupResponse?.applications.find(
        (application) => application.applicant.userId === data.invoker?.userId,
      );

      const step = findFirstIncompleteStep(application, stepsToUse);

      //on first load, sets to furthest step. subsequent requests don't change the step or the step order
      if (currentStep < 0) {
        setOrderedSteps(stepsToUse);
        setCurrentStep(step);
      }

      return {
        ...data,
        furthestAccessibleStep: step,
      };
    },
  });

  /**
   * pop the toast notification informing user that they are on multiple applications
   */
  useEffect(() => {
    if (isError) {
      fail(error);
    }
  }, [isError, error]);

  // Calculate furthest available step from applyInfo
  const furthestStep = applyInfo?.furthestAccessibleStep ?? 0;

  //TODO remove the unit and listing from the applyInfo- we should have dedicated endpoints for these
  //that return strongly typed applicant-facing data
  const {
    pm,
    applicationGroupResponse,
    unit,
    propertyName,
    hasRecentSubmittedApplication = false,
    listing,
    invoker,
    identityVerificationQuestions,
  } = applyInfo ?? {};

  const { data: pmSettings } = useQuery({
    queryKey: [
      "EnderAPI.getModelSettings",
      ModelTypeEnum.PROPERTY_MANAGER,
      applyInfo?.pm?.pmId,
    ] as const,
    queryFn: ({ signal }) =>
      EnderAPI.getModelSettings(
        {
          //@ts-expect-error applyInfo.pm is not nullable due to enabled property
          modelId: applyInfo.pm.pmId as EnderId,
          modelType: ModelTypeEnum.PROPERTY_MANAGER,
        },
        { signal },
      ),
    enabled: P.isNotNullable(applyInfo?.pm),
  });

  const stepTitle = useRef<Element>();

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([e]) => {
        //the item is fully on the page
        if (e.isIntersecting) {
          e.target.classList.remove(styles.stuck);
        } else {
          //the item is partly or fully off the page
          e.target.classList.add(styles.stuck);
        }
      },
      { rootMargin: "-13px 0px 0px 0px", threshold: [1] },
    );
  }, []);

  const handleStepTitle = useCallback(
    (el: Element | null) => {
      if (P.isNotNullable(stepTitle.current)) {
        observer.unobserve(stepTitle.current);
      }
      if (P.isNotNullable(el)) {
        observer.observe(el);
        stepTitle.current = el;
      }
    },
    [observer],
  );

  const progress = (
    <RingProgress
      sections={[
        {
          value: ((currentStep + 1) / orderedSteps.length) * 100,
          color: "green",
        },
      ]}
      roundCaps
      size={size}
      thickness={4.4}
      className={styles.ringProgress}
      label={
        <Group align={Align.center} justify={Justify.center}>
          <Text weight={FontWeight.bold} size={FontSize.md} color="green-600">
            {currentStep + 1}/{orderedSteps.length}
          </Text>
        </Group>
      }
    />
  );

  return (
    <>
      <ApplyWorkflowHeader
        unit={unit}
        propertyName={propertyName}
        listing={listing}
      />
      <div className={styles.content}>
        <Suspense>
          <Stepper
            color="green"
            iconSize={32}
            completedIcon={<IconCheck size="18px" />}
            active={Math.min(currentStep, furthestStep)}
            onStepClick={setCurrentStep}
            classNames={{
              content: styles.stepperContent,
              separator: styles.stepperSeparator,
              separatorActive: styles.stepperSeparatorActive,
              stepIcon: styles.stepperStepIcon,
              stepLabel: styles.stepperStepLabel,
              steps: styles.stepperSteps,
            }}>
            {orderedSteps.map((step, index) => {
              const label = ApplyStepLabelMap[step];
              const Component = ApplyStepComponentsMap[step];
              return (
                <Stepper.Step
                  label={label}
                  key={step}
                  className={clsx({
                    [styles.selectable]: index <= furthestStep,
                  })}
                  allowStepSelect={index <= furthestStep}
                  //if you are attempting to reach step 3, but only step 2 is reachable, it will display a loading icon for step 2
                  loading={
                    isFetching &&
                    currentStep > furthestStep &&
                    index === furthestStep
                  }>
                  <Component
                    applicationGroup={
                      applicationGroupResponse?.applicationGroup
                    }
                    applications={applicationGroupResponse?.applications}
                    user={invoker}
                    identityVerificationQuestions={
                      identityVerificationQuestions
                    }
                    pets={applicationGroupResponse?.pets}
                    unit={unit}
                    listing={listing}
                    pm={
                      P.isNotNullable(pm) && P.isNotNullable(pmSettings)
                        ? {
                            ...pm,
                            ...pmSettings,
                          }
                        : UNDEFINED
                    }
                    progress={progress}
                    isLastStep={index === orderedSteps.length - 1}
                    refresh={refetch}
                    onRequestNext={() => {
                      setCurrentStep(index + 1);
                      refetch().then(() => window.scrollTo(0, 0));
                    }}
                    onRequestPrevious={() => {
                      setCurrentStep(Math.max(index - 1, 0));
                    }}
                    hasRecentSubmittedApplications={
                      hasRecentSubmittedApplication
                    }
                    titleRef={handleStepTitle}
                  />
                </Stepper.Step>
              );
            })}
            <Stepper.Completed>
              <ApplyStepSummary
                applicationGroupId={
                  applicationGroupResponse?.applicationGroup.id
                }
                applications={applicationGroupResponse?.applications}
                onEdit={setCurrentStep}
                orderedSteps={orderedSteps}
                user={invoker}
              />
            </Stepper.Completed>
          </Stepper>
        </Suspense>
        {P.isNotNullable(unit) && (
          <a
            href={`mailto:${unit?.email}?subject=Need Help with ${unit?.name} Application`}
            style={{
              borderRadius: 20,
              bottom: 24,
              padding: 10,
              position: "fixed",
              right: 24,
            }}>
            <ActionIcon size="xl" label="Need Help">
              <IconQuestionMark />
            </ActionIcon>
          </a>
        )}
      </div>
    </>
  );
}

export { ApplyPage };
