"use client";

import { Schema } from "@effect/schema";
// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  Close,
  Content,
  Overlay,
  Portal,
  Root,
  Title,
} from "@radix-ui/react-dialog";
import { IconX } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import { Function as F } from "effect";
import type { PropsWithChildren } from "react";
import { forwardRef, useCallback } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { castEnum } from "@ender/shared/utils/effect";
import { Color, Size, SizeSchema } from "@ender/shared/utils/theming";

import { ActionIcon } from "../../../action-icon/src";
import { ButtonSize, ButtonVariant } from "../../../button/src";
import type { CardProps } from "../../../card/src";
import { Spacing } from "../../../flex/src";
import { Stack } from "../../../stack/src";

//done this way because extending an enum won't let us add values
const ModalSizeSchema = Schema.Literal(
  ...SizeSchema.pipe(Schema.pickLiteral(Size.sm, Size.md, Size.lg)).literals,
  "full",
);
const ModalSizeValues = ModalSizeSchema.literals;
type ModalSizes = Schema.Schema.Type<typeof ModalSizeSchema>;
const ModalSize = castEnum(ModalSizeSchema);

const CardVariantGenerator = cva(
  ["border relative overflow-auto bg-white max-h-full w-full", "p-6 pt-8"],
  {
    defaultVariants: {
      borderless: false,
      fullscreen: false,
      size: Size.md,
    },
    variants: {
      borderless: {
        false: "border-slate-200",
        true: "border-transparent",
      },
      fullscreen: {
        //used to generate a tailwind class with higher specificity
        false: "rounded-lg",
        true: "w-full h-full data-[state=open]:max-w-full max-h-full",
      },
      size: {
        [ModalSize.sm]: "max-w-[400px]",
        [ModalSize.md]: "max-w-[618px]",
        [ModalSize.lg]: "max-w-[816px]",
        [ModalSize.full]: "max-w-full",
      },
    },
  },
);

type ModalProps = {
  /**
   * the open state of the modal
   */
  opened?: boolean;
  onClose?: () => void;
  fullscreen?: boolean;
  title: string;
  /**
   * sm: 400px
   * md: 618px
   * lg: 816px
   * full: 100%
   */
  size?: ModalSizes;
  /**
   * whether the modal can be dismissed by:
   * - clicking outside of it
   * - clicking the close button
   * - pressing the escape key
   *
   * @default true
   */
  dismissible?: boolean;
  /**
   * whether the content should take up the full height of the modal
   *
   * @default false
   */
  fullHeight?: boolean;
} & Pick<CardProps, "borderless">;

const Modal = forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>(
  function Modal(props, ref) {
    const {
      children,
      opened,
      onClose = F.constVoid,
      fullHeight = false,
      fullscreen = false,
      title,
      dismissible = true,
    } = props;
    const handleOpenChange = useCallback(
      (opened: boolean) => {
        !opened && dismissible && onClose();
      },
      [onClose, dismissible],
    );
    return (
      <Root open={opened} onOpenChange={handleOpenChange}>
        <Portal>
          <Overlay
            className={clsx(
              "grid place-items-center fixed inset-0 bg-black/25 z-10",
              { "p-10": !fullscreen },
            )}>
            <Content
              className={CardVariantGenerator(props)}
              ref={ref}
              aria-describedby={UNDEFINED}>
              <Stack spacing={Spacing.sm} fullHeight={fullHeight}>
                <Title className="block text-slate-900 font-medium text-xl">
                  {title}
                </Title>
                {children}
              </Stack>
              <Close
                hidden={!dismissible}
                className="absolute top-1 right-1"
                tabIndex={0}
                asChild>
                <div>
                  <ActionIcon
                    size={ButtonSize.sm}
                    color={Color.slate}
                    label="Close"
                    variant={ButtonVariant.transparent}>
                    <IconX />
                  </ActionIcon>
                </div>
              </Close>
            </Content>
          </Overlay>
        </Portal>
      </Root>
    );
  },
);

export { Modal, ModalSize, ModalSizeSchema, ModalSizeValues };

export type { ModalProps, ModalSizes };
