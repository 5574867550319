import { useCallback, useRef, useState } from "react";

import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";

function useDebounce<T extends unknown[] = []>(
  func: (...args: T) => void,
  milliseconds = 400,
) {
  // eslint-disable-next-line no-undef -- NodeJS.Timeout is a global type
  const timer = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: T) => {
      if (timer) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }

      timer.current = setTimeout(func, milliseconds, ...args);
    },
    [func, milliseconds],
  );
}

function useDebounceState<T>(args: {
  initialValue: T | (() => T);
  milliseconds?: number;
  onDebounceValueChange?: (value: T, previousValue: T) => void;
}) {
  const { initialValue, milliseconds = 500, onDebounceValueChange } = args;

  const [value, _setValue] = useState<T>(initialValue);
  const [debouncedValue, _setDebouncedValue] = useState<T>(initialValue);

  const onDebounceValueChangeRef = useRefLatest(onDebounceValueChange);

  const handleDebouncedValueChange = useCallback(
    (value: T) => {
      _setDebouncedValue((previousValue) => {
        onDebounceValueChangeRef.current?.(value, previousValue);
        return value;
      });
    },
    [onDebounceValueChangeRef],
  );

  const setDebouncedValue = useDebounce(
    handleDebouncedValueChange,
    milliseconds,
  );

  const setValue = useCallback(
    (value: T) => {
      _setValue(value);
      setDebouncedValue(value);
    },
    [setDebouncedValue],
  );

  return [value, setValue, debouncedValue] as const;
}

export { useDebounce, useDebounceState };
