import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplianceApplianceTypeValues = [
  "REFRIGERATOR",
  "OVEN",
  "MICROWAVE",
  "WASHER",
  "DRYER",
  "STOVE",
  "DISPOSAL",
  "KITCHEN_SINK",
  "BATHROOM_SINK",
  "SHOWER",
  "OTHER",
] as const;

const ApplianceApplianceTypeEffectSchema = Schema.Literal(
  ...ApplianceApplianceTypeValues,
);

type ApplianceApplianceType = Schema.Schema.Type<
  typeof ApplianceApplianceTypeEffectSchema
>;

const ApplianceApplianceTypeEnum = castEnum(ApplianceApplianceTypeEffectSchema);

function randomApplianceApplianceType(): ApplianceApplianceType {
  return rand(ApplianceApplianceTypeValues);
}

export {
  ApplianceApplianceTypeEffectSchema,
  ApplianceApplianceTypeEnum,
  ApplianceApplianceTypeValues,
  randomApplianceApplianceType,
};
export type { ApplianceApplianceType };
