/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}
 * @function getLeaseDetails
 * @memberof LeasingAPI
 * @param {LeasingAPIGetLeaseDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeaseSerializerLeaseResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetLeaseDetailsPathParams = {
  leaseId: EnderId;
};

type LeasingAPIGetLeaseDetailsSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetLeaseDetailsPayload = LeasingAPIGetLeaseDetailsPathParams &
  LeasingAPIGetLeaseDetailsSearchParams;

function getLeaseDetailsUnsafeEffect(
  payload: LeasingAPIGetLeaseDetailsPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeaseSerializerLeaseResponse>({
        body,
        decode: unsafeDecodeJsonResponse<LeaseSerializerLeaseResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeaseDetails(
  payload: LeasingAPIGetLeaseDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<LeaseSerializerLeaseResponse> {
  return F.pipe(
    payload,
    getLeaseDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeaseDetails };
export type {
  LeasingAPIGetLeaseDetailsPathParams,
  LeasingAPIGetLeaseDetailsPayload,
  LeasingAPIGetLeaseDetailsSearchParams,
};
