import { Schema } from "@effect/schema";
import { Predicate as P } from "effect";
import { useCallback } from "react";

import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import type { Appliance } from "@ender/shared/generated/ender.model.core.unit";
import type { Photo } from "@ender/shared/generated/ender.model.files";
import { RightRail } from "@ender/shared/ui/right-rail";
import { castEnum } from "@ender/shared/utils/effect";

import { ApplianceForm } from "./appliance-form";
import { EditUnitAmenitiesForm } from "./edit-amenities-form";
import { ImageUpload } from "./edit-images";
import { EditMarketRentForm } from "./edit-market-rent-form";
import { EditSpecificationsForm } from "./edit-specifications-form";
import { EditUnitNameForm } from "./edit-unit-name-form";
import { VideoUpload } from "./edit-video";

const UnitPageRightRailSchema = Schema.Literal(
  "CLOSED",
  "EDIT_NAME",
  "EDIT_SPECIFICATIONS",
  "EDIT_AMENITIES",
  "EDIT_MARKET_RENT",
  "ADD_EDIT_APPLIANCES",
  "EDIT_IMAGES",
  "EDIT_VIDEOS",
);

type UnitPageRightRailType = Schema.Schema.Type<typeof UnitPageRightRailSchema>;
const UnitPageRightRailEnum = castEnum(UnitPageRightRailSchema);

type UnitPageRightRailMap = {
  [UnitPageRightRailEnum.CLOSED]: unknown;
  [UnitPageRightRailEnum.EDIT_NAME]: unknown;
  [UnitPageRightRailEnum.EDIT_SPECIFICATIONS]: unknown;
  [UnitPageRightRailEnum.EDIT_AMENITIES]: {
    unitAmenities: AmenityAmenityType[];
    propertyAmenities: AmenityAmenityType[];
  };
  [UnitPageRightRailEnum.EDIT_MARKET_RENT]: unknown;
  [UnitPageRightRailEnum.ADD_EDIT_APPLIANCES]: { appliance?: Appliance };
  [UnitPageRightRailEnum.EDIT_IMAGES]: { images: Photo[] };
  [UnitPageRightRailEnum.EDIT_VIDEOS]: unknown;
};

type UnitPageRightRailValue = {
  [K in keyof UnitPageRightRailMap]: { type: K } & UnitPageRightRailMap[K];
}[keyof UnitPageRightRailMap];

const rightRailTypeTitleMap = {
  [UnitPageRightRailEnum.CLOSED]: "",
  [UnitPageRightRailEnum.EDIT_NAME]: "Edit Unit Name",
  [UnitPageRightRailEnum.EDIT_SPECIFICATIONS]: "Edit Specifications",
  [UnitPageRightRailEnum.EDIT_AMENITIES]: "Edit Amenities",
  [UnitPageRightRailEnum.EDIT_MARKET_RENT]: "Edit Unit Market Rent",
  [UnitPageRightRailEnum.ADD_EDIT_APPLIANCES]: "Add or Edit Appliance",
  [UnitPageRightRailEnum.EDIT_IMAGES]: "Edit, Remove or Upload Images",
  [UnitPageRightRailEnum.EDIT_VIDEOS]: "Edit Unit Video",
} as const;

type UnitPageRailProps = {
  unit?: UnitSerializerUnitResponse;
  value: UnitPageRightRailValue;
  setRightRail: (value: UnitPageRightRailValue) => void;
  onSuccess: (operation: UnitPageRightRailType) => void;
};

function UnitPageRail(props: UnitPageRailProps) {
  const { value, unit, setRightRail, onSuccess } = props;

  const close = useCallback(() => {
    setRightRail({ type: UnitPageRightRailEnum.CLOSED });
  }, [setRightRail]);

  if (P.isNullable(unit)) {
    return null;
  }

  return (
    <RightRail
      opened={value.type !== UnitPageRightRailEnum.CLOSED}
      title={rightRailTypeTitleMap[value.type]}
      // subtitle={rail.railProps?.subtitle}
      onClose={close}>
      {value.type === UnitPageRightRailEnum.EDIT_SPECIFICATIONS && (
        <EditSpecificationsForm
          unit={unit}
          onSuccess={() => onSuccess(UnitPageRightRailEnum.EDIT_SPECIFICATIONS)}
          onCancel={close}
        />
      )}
      {value.type === UnitPageRightRailEnum.EDIT_NAME && (
        <EditUnitNameForm
          unit={unit}
          onSuccess={() => onSuccess(UnitPageRightRailEnum.EDIT_NAME)}
          onCancel={close}
        />
      )}
      {value.type === UnitPageRightRailEnum.ADD_EDIT_APPLIANCES && (
        <ApplianceForm
          onSuccess={(operation) => {
            if (operation === "ADD_APPLIANCE") {
              onSuccess(UnitPageRightRailEnum.ADD_EDIT_APPLIANCES);
            } else {
              onSuccess(UnitPageRightRailEnum.ADD_EDIT_APPLIANCES);
            }
          }}
          appliance={value.appliance}
          unitId={unit.id}
          onCancel={close}
        />
      )}
      {value.type === UnitPageRightRailEnum.EDIT_AMENITIES && (
        <EditUnitAmenitiesForm
          unit={unit}
          unitAmenities={value.unitAmenities}
          propertyAmenities={value.propertyAmenities}
          onSuccess={() => onSuccess(UnitPageRightRailEnum.EDIT_AMENITIES)}
          onCancel={close}
        />
      )}
      {value.type === UnitPageRightRailEnum.EDIT_IMAGES && (
        <ImageUpload
          unit={unit}
          onSuccess={() => onSuccess(UnitPageRightRailEnum.EDIT_IMAGES)}
          onCancel={close}
          images={value.images}
        />
      )}
      {value.type === UnitPageRightRailEnum.EDIT_VIDEOS && (
        <VideoUpload
          unit={unit}
          onSuccess={() => onSuccess(UnitPageRightRailEnum.EDIT_VIDEOS)}
          onCancel={close}
        />
      )}
      {value.type === UnitPageRightRailEnum.EDIT_MARKET_RENT && (
        <EditMarketRentForm
          unit={unit}
          onSuccess={() => {
            onSuccess(UnitPageRightRailEnum.EDIT_MARKET_RENT);
            close();
          }}
          onCancel={close}
        />
      )}
    </RightRail>
  );
}

export { UnitPageRail, UnitPageRightRailEnum };

export type { UnitPageRightRailType, UnitPageRightRailValue };
