import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UserUserTimezoneValues = [
  "PACIFIC",
  "MOUNTAIN",
  "CENTRAL",
  "EASTERN",
] as const;

const UserUserTimezoneEffectSchema = Schema.Literal(...UserUserTimezoneValues);

type UserUserTimezone = Schema.Schema.Type<typeof UserUserTimezoneEffectSchema>;

const UserUserTimezoneEnum = castEnum(UserUserTimezoneEffectSchema);

function randomUserUserTimezone(): UserUserTimezone {
  return rand(UserUserTimezoneValues);
}

export {
  randomUserUserTimezone,
  UserUserTimezoneEffectSchema,
  UserUserTimezoneEnum,
  UserUserTimezoneValues,
};
export type { UserUserTimezone };
