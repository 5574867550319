import { formatWithOptions } from "date-fns/fp";
import { enUS } from "date-fns/locale";
import { Option as O, pipe } from "effect";

import { LocalDate$ } from "@ender/shared/core";

const LONG_MONTH_YEAR_FORMAT = "MMMM yyyy";

const longMonthYearFormat = formatWithOptions(
  { locale: enUS },
  LONG_MONTH_YEAR_FORMAT,
);

/**
 * Converts LocalDate$ to a string showing the full month name and full year"
 * @returns {string} Formatted as "MMM yyyy"
 * @example "January 2022"
 */

function toLongMonthYearString(input: LocalDate$.LocalDate | string): string {
  const localDate = LocalDate$.parse(input);

  return pipe(
    localDate,
    O.map((date) => longMonthYearFormat(date.value)),
    O.getOrThrow,
  );
}

export { toLongMonthYearString };
