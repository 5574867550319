import { Instant$, LocalDate$ } from "@ender/shared/core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import type {
  BankRecRowEnderBatchResponse,
  BankRecRowType,
} from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";

type GetMatchedMessageParams = {
  description: string;
  enderBatch?: BankRecRowEnderBatchResponse;
  isIgnored: boolean;
  linkedInvoice?: Invoice;
  rowType: BankRecRowType;
};

function getMatchedMessage({
  description,
  enderBatch,
  isIgnored,
  linkedInvoice,
  rowType,
}: GetMatchedMessageParams) {
  if (rowType !== BankRecRowTypeEnum.MATCHED) {
    return;
  }

  if (isIgnored) {
    return <div>"This line item was ignored."</div>;
  }
  // BE linkedInvoice does not have .date property
  const linkedInvoiceFromInstantToLocalDate = LocalDate$.of(
    Instant$.of(linkedInvoice?.timestamp).toDate(),
  ).toJSON();
  return (
    <div>
      <div>This line item was matched to the following item on Ender:</div>
      <div>{`Description: ${linkedInvoice?.description || description}`}</div>
      <div>{`Date: ${linkedInvoiceFromInstantToLocalDate || enderBatch?.date}`}</div>
      <div>{`Amount: ${enderBatch?.amount}`}</div>
      {linkedInvoice && <div>Status: {linkedInvoice.status}</div>}
    </div>
  );
}

export { getMatchedMessage };
