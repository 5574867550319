/**
 * When the tenant clicks the button to pay their application fee, call this method to get the
 "paymentIntentClientSecret" that you'll need for sending to Stripe's API.
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/paymentIntent
 * @function getOrCreatePaymentIntent
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetOrCreatePaymentIntentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetOrCreatePaymentIntentResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetOrCreatePaymentIntentResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetOrCreatePaymentIntentPathParams = {
  applicationGroupId: EnderId;
};

type PaymentsAPIGetOrCreatePaymentIntentSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetOrCreatePaymentIntentPayload =
  PaymentsAPIGetOrCreatePaymentIntentPathParams &
    PaymentsAPIGetOrCreatePaymentIntentSearchParams;

function getOrCreatePaymentIntentUnsafeEffect(
  payload: PaymentsAPIGetOrCreatePaymentIntentPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetOrCreatePaymentIntentResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetOrCreatePaymentIntentResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/paymentIntent`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOrCreatePaymentIntent(
  payload: PaymentsAPIGetOrCreatePaymentIntentPayload,
  options?: { signal?: AbortSignal },
): Promise<GetOrCreatePaymentIntentResponse> {
  return F.pipe(
    payload,
    getOrCreatePaymentIntentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOrCreatePaymentIntent };
export type {
  PaymentsAPIGetOrCreatePaymentIntentPathParams,
  PaymentsAPIGetOrCreatePaymentIntentPayload,
  PaymentsAPIGetOrCreatePaymentIntentSearchParams,
};
