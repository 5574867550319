// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Root } from "@radix-ui/react-radio-group";
import { Function as F } from "effect";
import * as O from "effect/Option";
import type { ForwardedRef, ReactNode, Ref } from "react";
import { forwardRef, useId } from "react";

import { Direction, Flex, Spacing } from "../../../flex/src";
import { isEmptyReactNode } from "../../../utils";
import { Radio } from "./shared-ds-radio";

type RadioGroupData<T extends string> = { label: string | ReactNode; value: T };

type RadioGroupProps<T extends string> = {
  data: RadioGroupData<T>[];
  disabled?: boolean;
  error?: ReactNode;
  onChange: (value: O.Option<T>) => void;
  value: O.Option<T>;
  horizontal?: boolean;
};

function generateRadioData<T extends string>(
  input: readonly T[] | T[],
): RadioGroupData<T>[] {
  // Map over the array and return data in the required format
  return input.map((item) => ({
    label: item as ReactNode,
    value: item,
  }));
}

const RadioGroup = forwardRef(function RadioGroup<T extends string>(
  props: RadioGroupProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    value,
    onChange,
    disabled = false,
    data,
    error,
    horizontal = false,
  } = props;
  const idBase = useId();
  const errorId = `${idBase}-error`;

  return (
    <Root
      value={F.pipe(value, O.getOrElse(F.constant("")))}
      onValueChange={(value: T) =>
        value === "" ? onChange(O.none()) : onChange(O.some(value))
      }
      disabled={disabled}
      aria-invalid={!isEmptyReactNode(error)}
      aria-errormessage={errorId}
      ref={ref}>
      <Flex
        direction={horizontal ? Direction.horizontal : Direction.vertical}
        spacing={Spacing.sm}>
        {data.map((item) => (
          <Radio
            key={item.value.toString()}
            label={item.label}
            value={item.value}
          />
        ))}
        {!isEmptyReactNode(error) && (
          <p id={errorId} className="text-xs leading-normal text-red-500">
            {error}
          </p>
        )}
      </Flex>
    </Root>
  );
}) as <T extends string>(
  // eslint-disable-next-line no-use-before-define
  props: RadioGroupProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReactNode;

export { generateRadioData, RadioGroup };
export type { RadioGroupData, RadioGroupProps };
