import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BatchedJournalEntryActionResponseStatusValues = [
  "SUCCESS",
  "FAILURE",
] as const;

const BatchedJournalEntryActionResponseStatusEffectSchema = Schema.Literal(
  ...BatchedJournalEntryActionResponseStatusValues,
);

type BatchedJournalEntryActionResponseStatus = Schema.Schema.Type<
  typeof BatchedJournalEntryActionResponseStatusEffectSchema
>;

const BatchedJournalEntryActionResponseStatusEnum = castEnum(
  BatchedJournalEntryActionResponseStatusEffectSchema,
);

function randomBatchedJournalEntryActionResponseStatus(): BatchedJournalEntryActionResponseStatus {
  return rand(BatchedJournalEntryActionResponseStatusValues);
}

export {
  BatchedJournalEntryActionResponseStatusEffectSchema,
  BatchedJournalEntryActionResponseStatusEnum,
  BatchedJournalEntryActionResponseStatusValues,
  randomBatchedJournalEntryActionResponseStatus,
};
export type { BatchedJournalEntryActionResponseStatus };
