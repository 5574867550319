import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import type { Array as A } from "effect";
import { Function as F, Option as O } from "effect";
import type { PropsWithChildren, ReactNode } from "react";

import type { MakeFormPropsFromInputProps } from "@ender/form-system/base";
import { Form, useForm } from "@ender/form-system/base";
import { Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tuple } from "@ender/shared/ds/tuple";
import type { FormInputsResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { FormInputFormInputType } from "@ender/shared/generated/ender.model.forms.inputs";
import { FormInputFormInputTypeEnum } from "@ender/shared/generated/ender.model.forms.inputs";
import { Size } from "@ender/shared/utils/theming";

/**
 * For every type of input, there is a required form state type,
 * as well as a serialization method for that type
 */
const SchemaFormValidationMap = {
  [FormInputFormInputTypeEnum.SELECT]: Schema.Unknown.pipe(
    Schema.OptionFromSelf,
    Schema.transform(Schema.UndefinedOr(Schema.Unknown), {
      decode: O.getOrUndefined,
      encode: O.fromNullable,
    }),
  ),
  [FormInputFormInputTypeEnum.TEXT]: Schema.String,
} as const;

/**
 * for every type of input, we need to be able to decode any unknown value into a
 * valid "default" value that can be used in the input. The outputs of this _must_ satisfy the _encoded_ type of
 * SchemaFormValidationMap
 */
const SchemaDefaultValuesMap: {
  [T in keyof typeof SchemaFormValidationMap]: Schema.Schema<
    unknown,
    Schema.Schema.Encoded<(typeof SchemaFormValidationMap)[T]>
  >;
} = {
  //@ts-expect-error these schema outputs are what we want even if the types are wack
  [FormInputFormInputTypeEnum.SELECT]: Schema.transform(
    Schema.Unknown,
    Schema.encodedSchema(
      SchemaFormValidationMap[FormInputFormInputTypeEnum.SELECT],
    ),
    {
      decode: (v: unknown) => {
        const str = O.fromNullable(v);
        return str;
      },
      encode: O.getOrUndefined<unknown>,
    },
  ),
  //@ts-expect-error these schema outputs are what we want even if the types are wack
  [FormInputFormInputTypeEnum.TEXT]: Schema.transform(
    Schema.Unknown,
    Schema.encodedSchema(
      SchemaFormValidationMap[FormInputFormInputTypeEnum.TEXT],
    ),
    {
      decode: (v) => `${v ?? ""}`,
      encode: F.identity,
    },
  ),
};

type FormDiscriminant = A.ReadonlyArray.Infer<
  NonNullable<FormInputsResponse[keyof FormInputsResponse]>
>;

const FormFieldTypeMap: Record<
  FormInputFormInputType,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: MakeFormPropsFromInputProps<any, any, any>) => JSX.Element
> = {
  [FormInputFormInputTypeEnum.TEXT]: FormTextInput,
  //@ts-expect-error this component has an additional generic which confuses this map
  [FormInputFormInputTypeEnum.SELECT]: FormSelect,
};

type FormGeneratorProps = {
  header?: ReactNode;
  schema: FormDiscriminant[];
  defaultValues: {};
  onSubmit: (values: {}) => void;
  footer?: ReactNode;
};

/**
 * converts the provided schema to an Effectful validator of the form inputs
 * @param schema
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const genValidator = (schema: FormDiscriminant[]): Schema.Schema<any> => {
  return Schema.Struct({
    ...schema.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]: SchemaFormValidationMap[cur.inputType],
      }),
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as Record<string, Schema.Schema<any>>,
    ),
  });
};

/**
 * converts the provided schema to an Effectful transformation into an object which satisfies the default
 * values of the validator schema
 * @param schema
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const genInitial = (schema: FormDiscriminant[]): Schema.Schema<any> => {
  return Schema.Struct({
    ...schema.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]: SchemaDefaultValuesMap[cur.inputType],
      }),
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as Record<string, Schema.Schema<any>>,
    ),
  });
};

function FormGenerator(props: PropsWithChildren<FormGeneratorProps>) {
  const { schema, defaultValues, onSubmit, header, footer } = props;

  const validatorSchema = genValidator(schema);
  const decode = Schema.decodeUnknownSync(genInitial(schema));

  const form = useForm({
    defaultValues: decode(defaultValues),
    resolver: effectTsResolver(validatorSchema),
  });

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        {header}
        <Grid spacingY={Spacing.none}>
          {schema.map((field) => {
            const Component = FormFieldTypeMap[field.inputType];
            return (
              <Tuple
                key={field.name}
                label={field.label}
                value={
                  <Component
                    size={Size.sm}
                    form={form}
                    name={field.name}
                    description={field.description}
                    placeholder={field.placeholder}
                    clearable
                    //@ts-expect-error if field.data is undefined that's fine
                    data={field.data}
                  />
                }
              />
            );
          })}
        </Grid>
        {footer}
      </Stack>
    </Form>
  );
}

export { FormGenerator };

export type { FormDiscriminant, FormGeneratorProps };
