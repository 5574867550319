import { Schema } from "@effect/schema";
import { Option as O, Predicate as P } from "effect";

import {
  LocalTimeEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import { EnderIdFormSchema, Money$ } from "@ender/shared/core";
import {
  CollectionsStepChargeTypeEnum,
  CollectionsStepCollectionsActionTypeEnum,
} from "@ender/shared/generated/ender.model.misc";

const ChargeScheduleBasedThresholdSchema = Schema.Struct({
  chargeType: Schema.Enums(CollectionsStepChargeTypeEnum).pipe(
    Schema.OptionFromSelf,
  ),
  numberOfMonths: Schema.Number.pipe(Schema.OptionFromSelf),
});

const CreateCollectionsStepFormSchema = Schema.Struct({
  /** @description Days pre/post rent the step will occur */
  numDaysPastRentDue: Schema.Number.pipe(Schema.OptionFromSelf),
  /** @description The action the step will perform */
  actionType: Schema.Enums(CollectionsStepCollectionsActionTypeEnum).pipe(
    Schema.OptionFromSelf,
  ),
  /** @description Time of day the step should occur */
  actionTime: LocalTimeEffectSchema.pipe(Schema.OptionFromSelf),
  /**
   * @description
   * N # of hours after actionTime the step will be automated, if not manually performed first
   * If empty, no automation will occur
   */
  automatedAfterNHours: Schema.Number.pipe(Schema.OptionFromSelf),
  /** @description Order of step for the grouping of steps that occur on numDaysPastRentDue */
  ordering: Schema.Number.pipe(Schema.OptionFromSelf),
  /** @description Restricts the step to occur only when balance due is greater than this value */
  minBalanceDue: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): boolean =>
        O.isNone(input) ||
        (O.isSome(input) && O.getOrThrow(input) >= Money$.zero()),
      {
        message: () => "Minimum Balance Due must be positive",
      },
    ),
  ),
  minChargeScheduleBasedThreshold: Schema.OptionFromSelf(
    ChargeScheduleBasedThresholdSchema,
  ),
  /** @description Restricts the step to occur only when balance due is less than this value */
  maxBalanceDue: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): boolean =>
        O.isNone(input) ||
        (O.isSome(input) && O.getOrThrow(input) >= Money$.zero()),
      {
        message: () => "Minimum Balance Due must be positive",
      },
    ),
  ),
  maxChargeScheduleBasedThreshold: Schema.OptionFromSelf(
    ChargeScheduleBasedThresholdSchema,
  ),
  /** @description Title of the task used when actionType === CREATE_TASK */
  taskTitle: Schema.String,
  /** @description Template used for written communication steps */
  templateId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];

    // numDaysPastRentDue is required
    if (O.isNone(values.numDaysPastRentDue)) {
      issues.push({
        message: "Number of days is required",
        path: ["numDaysPastRentDue"],
      });
    }
    // actionType is required
    if (O.isNone(values.actionType)) {
      issues.push({
        message: "Step Action is required",
        path: ["actionType"],
      });
    }
    // Phone Calls cannot be automated
    {
      const isPhoneCall =
        O.getOrUndefined(values.actionType) ===
        CollectionsStepCollectionsActionTypeEnum.PHONE_CALL;
      const isAutomated = P.isNumber(
        O.getOrUndefined(values.automatedAfterNHours),
      );

      if (isPhoneCall && isAutomated) {
        issues.push({
          message: "Phone Calls cannot be automated",
          path: ["actionType", "automatedAfterNHours"],
        });
      }
    }
    // Minimum <= Maximum Balance
    {
      const minVal = O.getOrUndefined(values.minBalanceDue);
      const maxVal = O.getOrUndefined(values.maxBalanceDue);

      if (minVal && maxVal && Money$.Order(minVal, maxVal) > 0) {
        issues.push({
          message: "Minimum Balance Due must be less than Maximum Balance",
          path: ["minBalanceDue", "maxBalanceDue"],
        });
      }
    }
    // Task Title Requirements
    {
      const action = O.getOrUndefined(values.actionType);
      const title = values.taskTitle;
      const isCreateTask =
        action === CollectionsStepCollectionsActionTypeEnum.CREATE_TASK;

      if (isCreateTask && (P.isNullable(title) || title.trim().length === 0)) {
        issues.push({
          message: "Task Title is required",
          path: ["taskTitle"],
        });
      }

      if (!isCreateTask && P.isNotNullable(title) && title.trim().length > 0) {
        issues.push({
          message: "Task Title is not allowed",
          path: ["taskTitle"],
        });
      }
    }
    // LATE_FEE Requires Positive Min Balance
    {
      const action = O.getOrUndefined(values.actionType);
      const minVal = O.getOrElse(() => Money$.zero())(values.minBalanceDue);
      const threshold = O.getOrUndefined(
        values.minChargeScheduleBasedThreshold,
      );
      const hasValidThreshold =
        threshold &&
        O.isSome(threshold.chargeType) &&
        O.isSome(threshold.numberOfMonths);
      if (
        action === CollectionsStepCollectionsActionTypeEnum.LATE_FEE &&
        !Money$.isPositive(minVal) &&
        !hasValidThreshold
      ) {
        issues.push({
          message:
            "Charge Late Fee step requires Minimum Balance greater than $0.00",
          path: ["minBalanceDue"],
        });
      }
    }
    // MOVE_TO_EVICT Requires Positive Min Balance and a Time
    {
      const action = O.getOrUndefined(values.actionType);
      const minVal = O.getOrElse(() => Money$.zero())(values.minBalanceDue);
      const hasTime = O.isSome(values.actionTime);
      const threshold = O.getOrUndefined(
        values.minChargeScheduleBasedThreshold,
      );
      const hasValidThreshold =
        threshold &&
        O.isSome(threshold.chargeType) &&
        O.isSome(threshold.numberOfMonths);

      if (action === CollectionsStepCollectionsActionTypeEnum.MOVE_TO_EVICT) {
        if (!Money$.isPositive(minVal) && !hasValidThreshold) {
          issues.push({
            message:
              "Mark as Evicting step requires Minimum Balance greater than $0.00",
            path: ["minBalanceDue"],
          });
        }
        if (!hasTime) {
          issues.push({
            message: "Mark as Evicting step requires a time of day",
            path: ["actionTime"],
          });
        }
      }
    }
    // Template Requirements for Certain Actions
    {
      const action = O.getOrUndefined(values.actionType);
      const templateIsRequired =
        action === CollectionsStepCollectionsActionTypeEnum.EMAIL ||
        action === CollectionsStepCollectionsActionTypeEnum.CERTIFIED_MAIL ||
        action === CollectionsStepCollectionsActionTypeEnum.SMS;

      if (templateIsRequired && O.isNone(values.templateId)) {
        issues.push({
          message: "Selected step action requires a template",
          path: ["templateId"],
        });
      }
    }
    return issues;
  }),
);

type CreateCollectionsStepFormInput = Schema.Schema.Encoded<
  typeof CreateCollectionsStepFormSchema
>;
type CreateCollectionsStepFormOutput = Schema.Schema.Type<
  typeof CreateCollectionsStepFormSchema
>;

const UpdateCollectionsStepFormSchema = Schema.Struct({
  stepId: EnderIdFormSchema,
  numDaysPastRentDue: Schema.Number.pipe(Schema.OptionFromSelf),
  actionType: Schema.Enums(CollectionsStepCollectionsActionTypeEnum).pipe(
    Schema.OptionFromSelf,
  ),
  actionTime: LocalTimeEffectSchema.pipe(Schema.OptionFromSelf),
  automatedAfterNHours: Schema.Number.pipe(Schema.OptionFromSelf),
  ordering: Schema.Number.pipe(Schema.OptionFromSelf),
  minBalanceDue: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): boolean => O.isNone(input) || O.exists(input, Money$.isPositive),
      { message: () => "Minimum Balance Due must be positive" },
    ),
  ),
  minChargeScheduleBasedThreshold: Schema.OptionFromSelf(
    ChargeScheduleBasedThresholdSchema,
  ),
  maxBalanceDue: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): boolean => O.isNone(input) || O.exists(input, Money$.isPositive),
      { message: () => "Maximum Balance Due must be positive" },
    ),
  ),
  maxChargeScheduleBasedThreshold: Schema.OptionFromSelf(
    ChargeScheduleBasedThresholdSchema,
  ),
  taskTitle: Schema.String,
  templateId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];

    // Phone Calls cannot be automated
    {
      const action = O.getOrUndefined(values.actionType);
      const autoHours = O.getOrUndefined(values.automatedAfterNHours);
      const isPhoneCall =
        action === CollectionsStepCollectionsActionTypeEnum.PHONE_CALL;
      const isAutomated = P.isNumber(autoHours);

      if (isPhoneCall && isAutomated) {
        issues.push({
          message: "Phone Calls cannot be automated",
          path: ["actionType", "automatedAfterNHours"],
        });
      }
    }
    // Minimum <= Maximum Balance
    {
      const minVal = O.getOrUndefined(values.minBalanceDue);
      const maxVal = O.getOrUndefined(values.maxBalanceDue);

      if (minVal && maxVal && Money$.Order(minVal, maxVal) > 0) {
        issues.push({
          message: "Minimum Balance Due must be less than Maximum Balance",
          path: ["minBalanceDue", "maxBalanceDue"],
        });
      }
    }
    // Task Title required if CREATE_TASK
    {
      const action = O.getOrUndefined(values.actionType);
      const isCreateTaskType =
        action === CollectionsStepCollectionsActionTypeEnum.CREATE_TASK;
      const title = values.taskTitle;

      if (isCreateTaskType && (!title || title.trim().length === 0)) {
        issues.push({
          message: "Task Title is required",
          path: ["taskTitle"],
        });
      }
    }
    // Template required if EMAIL, CERTIFIED_MAIL, or SMS
    {
      const action = O.getOrUndefined(values.actionType);
      const templateIsRequired =
        action === CollectionsStepCollectionsActionTypeEnum.EMAIL ||
        action === CollectionsStepCollectionsActionTypeEnum.CERTIFIED_MAIL ||
        action === CollectionsStepCollectionsActionTypeEnum.SMS;

      if (templateIsRequired && O.isNone(values.templateId)) {
        issues.push({
          message: "Selected step action requires a template",
          path: ["templateId"],
        });
      }
    }
    return issues;
  }),
);

type UpdateCollectionsStepFormOutput = Schema.Schema.Type<
  typeof UpdateCollectionsStepFormSchema
>;

export { CreateCollectionsStepFormSchema, UpdateCollectionsStepFormSchema };
export type {
  CreateCollectionsStepFormInput,
  CreateCollectionsStepFormOutput,
  UpdateCollectionsStepFormOutput,
};
