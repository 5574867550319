import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import * as S from "effect/String";
import { useContext, useMemo } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import { Money$ } from "@ender/shared/core";
import { Group } from "@ender/shared/ds/group";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { SearchBankAccountResponse } from "@ender/shared/generated/ender.model.accounting.response";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import { getInvoicePropertyIds } from "@ender/shared/utils/invoice-utils";
import { MarkAsPaidButton } from "@ender/widgets/finance/invoice-mark-as-paid";
import { RejectInvoiceButton } from "@ender/widgets/finance/invoice-reject";

import { AmountApprovedMenu } from "./amount-approved-menu/amount-approved-menu";
import { ApproveButton } from "./approve-button";
import { getPossibleInvoiceActions } from "./get-possible-invoice-actions";
import { PayAchButton } from "./pay-ach/pay-ach-button";
import { PrintCheckButton } from "./print-check/print-check-button";

type InvoiceApprovalMenuProps = {
  approvalSteps: GetApprovalProcessResponse | Undefined;
  bankAccounts: SearchBankAccountResponse[];
  invoice: InvoiceSerializerInvoiceResponse;
  isAccountingPeriodClosed: boolean;
  isNextStepInTreasuryPhase: boolean;
  onApproveSuccess: () => void;
  onPaymentRejection: () => void;
  onPaymentSuccess: () => void;
  onVoidSuccess: () => void;
};

function InvoiceApprovalMenu({
  approvalSteps,
  bankAccounts,
  invoice,
  isAccountingPeriodClosed,
  isNextStepInTreasuryPhase,
  onApproveSuccess,
  onPaymentRejection,
  onPaymentSuccess,
  onVoidSuccess,
}: InvoiceApprovalMenuProps) {
  const { hasPermissions, user } = useContext(UserContext);

  const isPayable = invoice?.invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;

  const needsMyApproval = useMemo(() => {
    if (
      P.isNullable(invoice) ||
      P.isNullable(user) ||
      P.isNullable(approvalSteps)
    ) {
      return false;
    }

    if (
      P.isNullable(invoice.status) ||
      invoice.status === ApprovableApprovalStatusEnum.APPROVED ||
      invoice.status === ApprovableApprovalStatusEnum.REJECTED
    ) {
      return false;
    }

    const currentApprovalStep = approvalSteps.steps.find(
      (step) => step.id === invoice.currentState.stepId,
    );
    return !!currentApprovalStep?.approvers.some(
      (approver) => approver.id === user.id,
    );
  }, [approvalSteps, invoice, user]);

  const exemplaryPropertyId = getInvoicePropertyIds(invoice)[0];

  const { data: bankAccountIdData, isFetching: isLoadingBankAccountIdDetails } =
    useQuery({
      enabled: P.isNotNullable(invoice) && P.isNotNullable(exemplaryPropertyId),
      queryFn: () =>
        PropertiesAPI.getOperatingAccountIdByPropertyId({
          invoiceType: InvoiceInvoiceTypeEnum.PAYABLE,
          propertyIds: P.isNotNullable(exemplaryPropertyId)
            ? [exemplaryPropertyId]
            : [],
        }),
      queryKey: [
        "PropertiesAPI.getOperatingAccountIdByPropertyId",
        {
          invoiceType: InvoiceInvoiceTypeEnum.PAYABLE,
          propertyIds: [exemplaryPropertyId],
        },
      ],
    });

  const operatingAccountId =
    P.isNotNullable(exemplaryPropertyId) && P.isNotNullable(bankAccountIdData)
      ? bankAccountIdData[exemplaryPropertyId]
      : UNDEFINED;

  // TODO 2025-01-20 Geoffrey: this should be DRY w the implementation in batch pay
  const isAmountOverACHThreshold =
    Money$.Order(
      Money$.of(invoice.amount),
      Money$.makeFromCents(250_000_00), // $250,000
    ) > 0;

  const {
    canMarkPaid,
    canPayByACH,
    canPrintCheck,
    markPaidMessage,
    payByACHMessage,
    printCheckMessage,
    showApproveButton,
    showMarkPaidButton,
    showPayByACHButton,
    showPrintCheckButton,
    showRejectButton,
    showReprintCheckButton,
  } = getPossibleInvoiceActions({
    hasEditInvoiceAllocationsPermission: hasPermissions(
      FunctionalPermissionEnum.EDIT_INVOICE_ALLOCATIONS,
    ),
    hasInstantApprovalPermission: hasPermissions(
      FunctionalPermissionEnum.INSTANT_APPROVE_INVOICE,
    ),
    invoice,
    needsMyApproval,
  });

  if (
    !(
      showApproveButton ||
      showMarkPaidButton ||
      showPayByACHButton ||
      showPrintCheckButton ||
      showRejectButton ||
      showReprintCheckButton
    )
  ) {
    return NULL;
  }

  return (
    <Group>
      {showRejectButton && P.isNotNullable(approvalSteps) && (
        <RejectInvoiceButton
          approvalSteps={approvalSteps}
          invoice={invoice}
          onPaymentRejection={onPaymentRejection}
        />
      )}
      {showMarkPaidButton && (
        <Tooltip label={markPaidMessage} disabled={S.isEmpty(markPaidMessage)}>
          <span>
            <MarkAsPaidButton
              bankAccounts={bankAccounts}
              disabled={!canMarkPaid}
              invoice={invoice}
              onPaymentSuccess={onPaymentSuccess}>
              {isPayable ? "Mark as Paid" : "Mark as Received"}
            </MarkAsPaidButton>
          </span>
        </Tooltip>
      )}
      {showApproveButton && (
        <ApproveButton
          invoiceId={invoice.id}
          isAccountingPeriodClosed={isAccountingPeriodClosed}
          isNextStepInTreasuryPhase={isNextStepInTreasuryPhase}
          onSuccess={onApproveSuccess}
        />
      )}
      {showPrintCheckButton && (
        <PrintCheckButton
          bankAccounts={bankAccounts}
          enabled={canPrintCheck}
          invoice={invoice}
          onPaymentSuccess={onPaymentSuccess}
          tooltip={printCheckMessage}
        />
      )}
      {showPayByACHButton && (
        <PayAchButton
          bankAccounts={bankAccounts}
          disabled={
            !canPayByACH ||
            isLoadingBankAccountIdDetails ||
            isAmountOverACHThreshold
          }
          disabledTooltip={
            isAmountOverACHThreshold
              ? // TODO 2025-01-20 Geoffrey: this should be consolidated w isAmountOverACHThreshold)
                // TODO 2025-01-20 Geoffrey: this doesn't belong out here in the invoice-approval-menu JSX
                // TODO 2025-01-20 Geoffrey: this functionality should be DRY w batch pay
                "Ender does not allow ACH transfers greater than $250,000. Please use another payment method or break this invoice into smaller parts."
              : payByACHMessage
          }
          invoice={invoice}
          onSuccess={onPaymentSuccess}
          operatingAccountId={operatingAccountId}
        />
      )}
      {invoice?.amountApprovalStatus ===
        ApprovableApprovalStatusEnum.APPROVED &&
        P.isNotNullable(approvalSteps) && (
          <AmountApprovedMenu
            approvalSteps={approvalSteps}
            invoice={invoice}
            onVoidSuccess={onVoidSuccess}
          />
        )}
    </Group>
  );
}

export { InvoiceApprovalMenu };
