import { Array as A, Predicate as P } from "effect";
import { useEffect, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { BuyAPI, BuyBoxAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { GetDealResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import { DealStatusPipelineStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";
import { convertToNumber } from "@ender/shared/utils/string";

const CAN_ADD_OFFER_STATUSES: string[] = [
  DealStatusPipelineStatusEnum.LEAD,
  DealStatusPipelineStatusEnum.OFFER,
];

function useCanAddOffer(dealId: EnderId, deal?: GetDealResponse) {
  const [canAddOffer, setCanAddOffer] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  // lazy useEffect
  useEffect(() => {
    async function getErrorMessage() {
      if (!deal || deal.id !== dealId) {
        return;
      }

      if (!deal?.buyerFirmId) {
        setErrorMessage("No Investor");
        return;
      }

      const buyBoxes = await BuyBoxAPI.getPropertyBuyBoxes({ dealId });

      if (A.isEmptyArray(buyBoxes)) {
        setErrorMessage("No Available Buy Boxes");
        return;
      }

      const allocations = await BuyAPI.getInvestorAllocations({
        firmId: deal.buyerFirmId,
      });

      const market = allocations.find(
        ({ market }) => market.id === deal.market.id,
      );

      const marketMoneyLeft =
        convertToNumber(market?.capitalAllocation || "0") -
        convertToNumber(market?.totalSpend || "0");

      if (marketMoneyLeft < convertToNumber(deal.currentListPrice || "0")) {
        setErrorMessage("Market Budget Reached");
        return;
      }

      setErrorMessage(undefined);
    }

    getErrorMessage();
  }, [deal, dealId]);

  // lazy useEffect
  useEffect(() => {
    if (
      P.isNotNullable(deal) &&
      !CAN_ADD_OFFER_STATUSES.includes(deal?.status?.pipelineStatus?.id) &&
      !errorMessage
    ) {
      setCanAddOffer(false);
    } else {
      setCanAddOffer(true);
    }
  }, [deal, errorMessage]);

  return { errorMessage, canAddOffer };
}

export { CAN_ADD_OFFER_STATUSES, useCanAddOffer };
