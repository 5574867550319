/**
 * HTTP Method: DELETE
 * Pathname: /construction/inspections/{inspectionId}/areas/{areaId}/zones/{zoneId}
 * @function deleteZone
 * @memberof InspectionAPI
 * @param {InspectionAPIDeleteZonePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionZoneResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionZoneResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIDeleteZonePathParams = {
  inspectionId: EnderId;
  areaId: EnderId;
  zoneId: EnderId;
};

type InspectionAPIDeleteZoneSearchParams = {
  token?: string | undefined;
};

type InspectionAPIDeleteZonePayload = InspectionAPIDeleteZonePathParams &
  InspectionAPIDeleteZoneSearchParams;

function deleteZoneUnsafeEffect(payload: InspectionAPIDeleteZonePayload) {
  const { inspectionId, areaId, zoneId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionZoneResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionZoneResponse>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/construction/inspections/${inspectionId}/areas/${areaId}/zones/${zoneId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteZone(
  payload: InspectionAPIDeleteZonePayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionZoneResponse> {
  return F.pipe(
    payload,
    deleteZoneUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteZone };
export type {
  InspectionAPIDeleteZonePathParams,
  InspectionAPIDeleteZonePayload,
  InspectionAPIDeleteZoneSearchParams,
};
