import { Schema } from "@effect/schema";
// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Content, List, Root, Trigger } from "@radix-ui/react-tabs";
import { cva } from "class-variance-authority";
import type { ElementRef, PropsWithChildren, ReactElement, Ref } from "react";
import { createContext, forwardRef, useContext } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import { ColorSchema, SizeSchema } from "@ender/shared/utils/theming";

import type { Directions, Spacings } from "../../../flex/src";
import { Direction, Flex, Spacing } from "../../../flex/src";

import styles from "./shared-ds-tabs.module.css";

type TabsContextValue = {
  direction?: Directions;
};

const TabsContext = createContext<TabsContextValue>({
  direction: Direction.horizontal,
});

type TabsProps<T extends string = string> = {
  value: T;
  onChange: (value: T) => void;
  /**
   * The orientation the tabs are in. Mainly so keyboard arrow navigation is done correctly (left & right vs. up & down).
   *
   * Does affect tab list/panel layout, placing them across the axis specified.
   * e.g. `direction = "vertical"` means the tabs list itself is vertical, and the layout is horizontal, with the tabs
   * list next to the tab panel. vice versa when direction = "horizontal"
   */
  direction?: Directions;
  /**
   * Spacing of the tab layout. Uses the same values as Flex spacing.
   * @default Spacing.none
   */
  spacing?: Spacings;
};

/**
 * the stateful props of the Tabs component. Helpful if you want to make a bespoke Tabs implementation
 * and are tired of specifying `value` and `onChange` on your wrapper.
 */
type TabsBaseProps<T extends string = string> = Pick<
  TabsProps<T>,
  "value" | "onChange"
>;

const TabsLayoutVariantGenerator = cva("grid overflow-auto", {
  defaultVariants: {
    direction: Direction.horizontal,
    spacing: Spacing.none,
  },
  variants: {
    direction: {
      [Direction.horizontal]: styles.horizontal,
      [Direction.vertical]: styles.vertical,
    },
    spacing: {
      [Spacing.none]: "gap-0",
      [Spacing.xs]: "gap-1",
      [Spacing.sm]: "gap-2",
      [Spacing.md]: "gap-4",
      [Spacing.lg]: "gap-6",
      [Spacing.xl]: "gap-8",
    },
  },
});

const Tabs = forwardRef<ElementRef<typeof Root>, PropsWithChildren<TabsProps>>(
  function Tabs(props, ref) {
    const {
      value,
      onChange,
      direction = Direction.horizontal,
      children,
    } = props;

    return (
      <Root
        value={value}
        onValueChange={onChange}
        ref={ref}
        orientation={direction}
        className={TabsLayoutVariantGenerator(props)}>
        <TabsContext.Provider value={{ direction }}>
          {children}
        </TabsContext.Provider>
      </Root>
    );
  },
) as <T extends string = string>(
  // eslint-disable-next-line no-use-before-define
  props: PropsWithChildren<TabsProps<T>> & {
    ref?: Ref<ElementRef<typeof Root>>;
  },
) => ReactElement;

const TabButtonVariantSchema = Schema.Literal("underlined", "outlined");
const TabButtonVariantValues = TabButtonVariantSchema.literals;
type TabButtonVariants = Schema.Schema.Type<typeof TabButtonVariantSchema>;
const TabButtonVariant = castEnum(TabButtonVariantSchema);

const TabButtonSizeSchema = SizeSchema.pipe(Schema.pickLiteral("sm", "md"));
const TabButtonSizeValues = TabButtonSizeSchema.literals;
type TabButtonSizes = Schema.Schema.Type<typeof TabButtonSizeSchema>;
const TabButtonSize = castEnum(TabButtonSizeSchema);

const TabButtonColorSchema = ColorSchema.pipe(
  Schema.pickLiteral("primary", "yellow", "green"),
);
const TabButtonColorValues = TabButtonColorSchema.literals;
type TabButtonColors = Schema.Schema.Type<typeof TabButtonColorSchema>;
const TabButtonColor = castEnum(TabButtonColorSchema);

type TabsListContextValue = {
  variant?: TabButtonVariants;
  size?: TabButtonSizes;
  color?: TabButtonColors;
};

const TabsListContext = createContext<TabsListContextValue>({});

type TabsListProps = {
  variant?: TabButtonVariants;
  /**
   * sets size on all tabs within this list
   */
  size?: TabButtonSizes;
  /**
   * sets color on all tabs within this list
   */
  color?: TabButtonColors;
  noWrap?: boolean;
};
const TabsList = forwardRef<
  ElementRef<typeof List>,
  PropsWithChildren<TabsListProps>
>(function TabsList(props, ref) {
  const { children, noWrap, size, color, variant } = props;
  const { direction } = useContext(TabsContext);

  return (
    <List ref={ref} className="overflow-auto shrink-0">
      <Flex spacing={Spacing.md} direction={direction} noWrap={noWrap}>
        <TabsListContext.Provider value={{ color, size, variant }}>
          {children}
        </TabsListContext.Provider>
      </Flex>
    </List>
  );
});

const TabButtonVariantGenerator = cva(
  ["text-sm/normal font-medium shrink-0 cursor-pointer select-none"],
  {
    compoundVariants: [
      {
        className: "py-2",
        size: TabButtonSize.sm,
        variant: TabButtonVariant.outlined,
      },
      {
        className: "py-4",
        size: TabButtonSize.md,
        variant: TabButtonVariant.outlined,
      },
    ],
    defaultVariants: {
      color: TabButtonColor.primary,
      size: TabButtonSize.sm,
      variant: TabButtonVariant.underlined,
    },
    variants: {
      color: {
        [TabButtonColor.primary]: [
          "before:border-slate-200",
          "hover:before:border-primary-200",
        ],
        [TabButtonColor.yellow]: [
          "before:border-yellow-500",
          "hover:before:border-yellow-600",
        ],
        [TabButtonColor.green]: [
          "before:border-green-500",
          "hover:before:border-green-600",
        ],
      },
      size: {
        [TabButtonSize.sm]: "",
        [TabButtonSize.md]: "",
      },
      // As long as these styles only affect border color and not border visibility, they won't affect the "underlined" variant
      variant: {
        outlined: [
          "px-4 relative before:absolute before:inset-0 before:border before:rounded-lg", //base styles
          "data-[state=active]:before:border-primary-500 data-[state=active]:before:border-2", //actions and behaviors
        ],
        underlined: [
          "text-slate-600 px-1 pb-0.5 border-b-2 border-transparent", //base styles
          "hover:text-primary-500 data-[state=active]:border-primary-500 data-[state=active]:text-primary-500", //actions and behaviors
        ],
      },
    },
  },
);

type TabButtonProps = {
  value: string;
  variant?: TabButtonVariants;
  /**
   * affects the padded size of the tab button. defaults to "sm"
   */
  size?: TabButtonSizes;
  color?: TabButtonColors;
  /**
   * href for the tab button. If used, the tab button will act as a link and can be right-clicked to open in a new tab.
   * The default click behavior will be prevented.
   */
  href?: string;
  disabled?: boolean;
};

const TabButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<TabButtonProps>
>(function TabButton(props, ref) {
  const { children, value, href, disabled, ...variantProps } = props;
  const tabsListProps = useContext(TabsListContext);

  return (
    <Trigger
      value={value}
      ref={ref}
      className={TabButtonVariantGenerator({
        ...tabsListProps,
        ...variantProps,
      })}
      disabled={disabled}
      asChild>
      <a
        href={href}
        // prevent the default anchor following behavior. This means the anchor is only enabled for right-clicking to open in a new tab
        onClick={(e) => e.preventDefault()}>
        {children}
      </a>
    </Trigger>
  );
});

type TabContentProps = {
  value: string;
};

const TabContent = forwardRef<
  ElementRef<typeof Content>,
  PropsWithChildren<TabContentProps>
>(function TabContent(props, ref) {
  const { children, value } = props;

  return (
    <Content
      ref={ref}
      value={value}
      className="grow relative overflow-auto h-full data-[state=active]:grid"
      style={{ gridArea: "tabpanel" }}>
      {children}
    </Content>
  );
});

export {
  TabButton,
  TabButtonColor,
  TabButtonColorSchema,
  TabButtonColorValues,
  TabButtonSize,
  TabButtonSizeSchema,
  TabButtonSizeValues,
  TabButtonVariant,
  TabButtonVariantSchema,
  TabButtonVariantValues,
  TabContent,
  Tabs,
  TabsList,
};

export type {
  TabButtonProps,
  TabContentProps,
  TabsBaseProps,
  TabsListProps,
  TabsProps,
};
