import {
  ModalsProvider as MantineModalsProvider,
  useModals,
} from "@mantine/modals";
import { Function as F } from "effect";
import type { FC, PropsWithChildren, ReactNode } from "react";
import { createContext, useCallback, useContext } from "react";

import type { EmptyObject } from "@ender/shared/types/general";

import { EnderModalContextProvider } from "./ender-modal.context";

type OpenModalOptions<P> = {
  title?: ReactNode;
  modalProps: P;
};

type OpenModalReturn = {
  modal: string;
  close: () => void;
};

type ModalsContextValue = {
  openModal: <P extends EmptyObject>(
    Component: FC<P>,
    options: OpenModalOptions<P>,
  ) => OpenModalReturn;
};

function initFn() {
  return { modal: "", close: F.constVoid };
}

const initialValue = {
  openModal: initFn,
};

const ModalsContext = createContext<ModalsContextValue>(initialValue);

function InnerModalsProvider(props: PropsWithChildren) {
  const modals = useModals();

  const openModal = useCallback<ModalsContextValue["openModal"]>(
    (ModalInner, options) => {
      const { title = "", modalProps } = options;

      const modal = modals.openModal({
        title: title,
        classNames: { root: "modals-openModal-root" },
        children: <ModalInner {...modalProps} />,
      });

      return {
        modal,
        close: () => modals.closeModal(modal),
      };
    },
    [modals],
  );

  return (
    <ModalsContext.Provider value={{ openModal }}>
      {props.children}
    </ModalsContext.Provider>
  );
}

function EnderModalsProvider(props: PropsWithChildren) {
  const { children } = props;

  return (
    <MantineModalsProvider>
      <InnerModalsProvider>
        <EnderModalContextProvider>{children}</EnderModalContextProvider>
      </InnerModalsProvider>
    </MantineModalsProvider>
  );
}

function useEnderModals() {
  return useContext(ModalsContext);
}

export { EnderModalsProvider, useEnderModals };
