import { Schema } from "@effect/schema";
import { Function as F } from "effect";
import type { ElementRef } from "react";
import { forwardRef } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";

const AddVehicleFormSchema = Schema.Struct({
  make: Schema.String,
  model: Schema.String,
  color: Schema.String,
  licensePlateNumber: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "License Plate is required" }),
  ),
  parkingSpot: Schema.String,
});
type AddVehicleFormOutput = Schema.Schema.Type<typeof AddVehicleFormSchema>;

type AddVehicleFormProps = {
  onSubmit?: (values: AddVehicleFormOutput) => void;
  onCancel: () => void;
  loading?: boolean;
  isPM?: boolean;
};

const AddVehicleForm = forwardRef<ElementRef<typeof Form>, AddVehicleFormProps>(
  function AddVehicleForm(props, _ref) {
    const {
      onSubmit = F.constVoid,
      onCancel,
      isPM = false,
      loading = false,
    } = props;
    const form = useEffectSchemaForm({
      defaultValues: {
        make: "",
        model: "",
        color: "",
        licensePlateNumber: "",
        parkingSpot: "",
      },
      schema: AddVehicleFormSchema,
    });

    return (
      <Form form={form} onSubmit={onSubmit}>
        <Stack spacing={Spacing.md}>
          <FormTextInput
            form={form}
            name="make"
            label="Make"
            placeholder="Toyota"
          />
          <FormTextInput
            form={form}
            name="model"
            label="Model"
            placeholder="Tacoma"
          />
          <FormTextInput
            form={form}
            name="color"
            label="Color"
            placeholder="Blue"
          />
          <FormTextInput
            form={form}
            name="licensePlateNumber"
            label="License Plate"
            placeholder="ABC123"
          />
          {isPM && (
            <FormTextInput
              form={form}
              name="parkingSpot"
              label="Parking Spot"
            />
          )}

          <Group justify={Justify.end}>
            <Button variant={ButtonVariant.transparent} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </Group>
        </Stack>
      </Form>
    );
  },
);

export { AddVehicleForm };
export type { AddVehicleFormOutput, AddVehicleFormProps };
