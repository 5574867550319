const REDIRECT_QUERY_PARAM = "redirectUrl";

function navigateToApp() {
  const queryParams = new URLSearchParams(globalThis.location.search);
  const redirectUrl = queryParams.get(REDIRECT_QUERY_PARAM);

  if (redirectUrl) {
    try {
      const { pathname: curPathname } = globalThis.location;
      const { pathname: nextPathname } = new URL(redirectUrl);
      if (curPathname !== nextPathname) {
        return globalThis.location.assign(redirectUrl);
      }
    } catch {
      // Ignore the parse error since that means urls are invalid and we just want to got to `/app`
    }
  }

  return globalThis.location.assign("/app");
}

export { REDIRECT_QUERY_PARAM, navigateToApp };
