import { z } from "zod";

const MediumEnum = {
  EMAIL: "email",
  SMS: "sms",
  WEBSITE: "website",
} as const;
const MediumSchema = z.nativeEnum(MediumEnum);
type Medium = z.infer<typeof MediumSchema>;

export { MediumEnum, MediumSchema };
export type { Medium };
