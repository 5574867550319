import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InvoicePaymentMethodValues = [
  "NONE",
  "MARK_PAID",
  "PRINT_CHECK",
  "ACH",
] as const;

const InvoicePaymentMethodEffectSchema = Schema.Literal(
  ...InvoicePaymentMethodValues,
);

type InvoicePaymentMethod = Schema.Schema.Type<
  typeof InvoicePaymentMethodEffectSchema
>;

const InvoicePaymentMethodEnum = castEnum(InvoicePaymentMethodEffectSchema);

function randomInvoicePaymentMethod(): InvoicePaymentMethod {
  return rand(InvoicePaymentMethodValues);
}

export {
  InvoicePaymentMethodEffectSchema,
  InvoicePaymentMethodEnum,
  InvoicePaymentMethodValues,
  randomInvoicePaymentMethod,
};
export type { InvoicePaymentMethod };
