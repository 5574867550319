/**
 * HTTP Method: POST
 * Pathname: /reports/{reportId}/name
 * @function renameReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRenameReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIRenameReportPathParams = {
  reportId: EnderId;
};

type ReportsAPIRenameReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRenameReportBodyParams = {
  name: string;
};

type ReportsAPIRenameReportPayload = ReportsAPIRenameReportPathParams &
  ReportsAPIRenameReportSearchParams &
  ReportsAPIRenameReportBodyParams;

function renameReportUnsafeEffect(payload: ReportsAPIRenameReportPayload) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/reports/${reportId}/name`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function renameReport(
  payload: ReportsAPIRenameReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    renameReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { renameReport };
export type {
  ReportsAPIRenameReportBodyParams,
  ReportsAPIRenameReportPathParams,
  ReportsAPIRenameReportPayload,
  ReportsAPIRenameReportSearchParams,
};
