import { Option as O, Predicate as P, pipe } from "effect";
import { useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { Money$ } from "@ender/shared/core";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { convertToNumber } from "@ender/shared/utils/string";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

import styles from "./sliding-filter/sliding-chart.module.css";

type ListPriceInputFilterProps = FilterComponentProps & {
  setHasError: (value: boolean) => void;
};

function validPriceRange(min: number | undefined, max: number | undefined) {
  if (min && max) {
    return min <= max;
  }

  return true;
}

function getListPriceFilters(
  min: number | undefined,
  max: number | undefined,
  factor?: WidgetFactor,
): WidgetFilter[] | undefined {
  if ((!min && !max) || !factor) {
    return UNDEFINED;
  }

  const filters: WidgetFilter[] = [];

  if (min) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
      values: [min],
    });
  }

  if (max) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
      values: [max],
    });
  }

  return filters;
}

function getDefaultMinValue(widget: Widget): number | undefined {
  const min = widget.filters.find(
    ({ factor, operator }) =>
      factor.name === Factor.LIST_PRICE &&
      operator === DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
  )?.values?.[0];

  if (P.isNumber(min)) {
    return min;
  } else if (P.isRecord(min)) {
    return UNDEFINED;
  }
  return P.isNotNullable(min) ? convertToNumber(min) : UNDEFINED;
}

function getDefaultMaxValue(widget: Widget): number | undefined {
  const max = widget.filters.find(
    ({ factor, operator }) =>
      factor.name === Factor.LIST_PRICE &&
      operator === DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
  )?.values?.[0];

  if (P.isNumber(max)) {
    return max;
  } else if (P.isRecord(max)) {
    return UNDEFINED;
  }
  return P.isNotNullable(max) ? convertToNumber(max) : UNDEFINED;
}

function ListPriceInputFilter({
  factor,
  updateFilters,
  setHasError,
  widget,
}: ListPriceInputFilterProps) {
  const [min, setMin] = useState<number | undefined>(
    getDefaultMinValue(widget),
  );
  const [max, setMax] = useState<number | undefined>(
    getDefaultMaxValue(widget),
  );

  function handleMinChange(val: O.Option<Money$.Money>) {
    const value = pipe(val, O.map(Money$.toDollars), O.getOrUndefined);
    setMin(value);
    const filters = getListPriceFilters(value, max, factor);
    updateFilters(Factor.LIST_PRICE, filters);
    setHasError(!validPriceRange(value, max));
  }

  function handleMaxChange(val: O.Option<Money$.Money>) {
    const value = pipe(val, O.map(Money$.toDollars), O.getOrUndefined);

    setMax(value);

    const filters = getListPriceFilters(min, value, factor);
    updateFilters(Factor.LIST_PRICE, filters);
    setHasError(!validPriceRange(min, value));
  }

  return (
    <Group spacing={Spacing.sm} align={Align.end} justify={Justify.center}>
      <div style={{ flex: 1 }}>
        <MoneyInput
          label="Min List Price"
          value={Money$.parse(min)}
          onChange={handleMinChange}
          error={
            validPriceRange(min, max)
              ? undefined
              : "Must be less than max value"
          }
        />
      </div>
      <div className={styles.slidingChartInputDivider}>-</div>
      <div style={{ flex: 1 }}>
        {/* TODO use Currency Input here */}
        <MoneyInput
          label="Max List Price"
          onChange={handleMaxChange}
          value={Money$.parse(max)}
          error={
            validPriceRange(min, max)
              ? undefined
              : "Must be greater than min value"
          }
        />
      </div>
    </Group>
  );
}

export { ListPriceInputFilter };
