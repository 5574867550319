/**
 * Get check details
 * HTTP Method: GET
 * Pathname: /checks/{moneyTransferId}
 * @function getMoneyTransfer
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetMoneyTransferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransferSerializerMoneyTransferResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { MoneyTransferSerializerMoneyTransferResponse } from "@ender/shared/generated/ender.arch.accounting";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetMoneyTransferPathParams = {
  moneyTransferId: EnderId;
};

type PaymentsAPIGetMoneyTransferSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetMoneyTransferPayload =
  PaymentsAPIGetMoneyTransferPathParams &
    PaymentsAPIGetMoneyTransferSearchParams;

function getMoneyTransferUnsafeEffect(
  payload: PaymentsAPIGetMoneyTransferPayload,
) {
  const { moneyTransferId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransferSerializerMoneyTransferResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<MoneyTransferSerializerMoneyTransferResponse>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/checks/${moneyTransferId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMoneyTransfer(
  payload: PaymentsAPIGetMoneyTransferPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransferSerializerMoneyTransferResponse> {
  return F.pipe(
    payload,
    getMoneyTransferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMoneyTransfer };
export type {
  PaymentsAPIGetMoneyTransferPathParams,
  PaymentsAPIGetMoneyTransferPayload,
  PaymentsAPIGetMoneyTransferSearchParams,
};
