import { IconEdit } from "@tabler/icons-react";
import { Option as O, String as S } from "effect";
import { useId } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { RouterLink } from "@ender/shared/ds/router-link";
import { FontSize, Text } from "@ender/shared/ds/text";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";

type VideosCardProps = {
  onEdit: () => void;
  unit: Pick<UnitSerializerUnitResponse, "videoUrl">;
};

function VideosCard(props: VideosCardProps) {
  const { onEdit, unit } = props;

  const headingId = useId();
  return (
    <Card labelledBy={headingId}>
      <Group justify={Justify.between}>
        <H3 id={headingId}>Video for Listing</H3>
        <ActionIcon variant={ButtonVariant.transparent} onClick={onEdit}>
          <IconEdit color={EnderThemeColorEnum.PRIMARY} />
        </ActionIcon>
      </Group>
      {O.match(O.fromNullable(unit.videoUrl), {
        onNone: () => (
          <Text size={FontSize.sm}>No video added for this unit</Text>
        ),
        onSome: (url) =>
          S.isEmpty(url) ? (
            <Text size={FontSize.sm}>No video added for this unit</Text>
          ) : (
            <RouterLink href={url} target="_blank">
              {url}
            </RouterLink>
          ),
      })}
    </Card>
  );
}

export { VideosCard };
