import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TenantLedgerReportServiceTenantLedgerSortKeyValues = [
  "SPECIAL_LEDGER_DATE",
] as const;

const TenantLedgerReportServiceTenantLedgerSortKeyEffectSchema = Schema.Literal(
  ...TenantLedgerReportServiceTenantLedgerSortKeyValues,
);

type TenantLedgerReportServiceTenantLedgerSortKey = Schema.Schema.Type<
  typeof TenantLedgerReportServiceTenantLedgerSortKeyEffectSchema
>;

const TenantLedgerReportServiceTenantLedgerSortKeyEnum = castEnum(
  TenantLedgerReportServiceTenantLedgerSortKeyEffectSchema,
);

function randomTenantLedgerReportServiceTenantLedgerSortKey(): TenantLedgerReportServiceTenantLedgerSortKey {
  return rand(TenantLedgerReportServiceTenantLedgerSortKeyValues);
}

export {
  randomTenantLedgerReportServiceTenantLedgerSortKey,
  TenantLedgerReportServiceTenantLedgerSortKeyEffectSchema,
  TenantLedgerReportServiceTenantLedgerSortKeyEnum,
  TenantLedgerReportServiceTenantLedgerSortKeyValues,
};
export type { TenantLedgerReportServiceTenantLedgerSortKey };
