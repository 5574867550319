import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import { useEffectSchemaForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import { EnderIdFormSchema, LocalDate$, Money$ } from "@ender/shared/core";
import type { PaymentPlanResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  PaymentPlanPaymentPlanStatusEffectSchema,
  PaymentPlanPaymentPlanStatusEnum,
} from "@ender/shared/generated/ender.model.misc";

const emptyInstallment = {
  amount: O.none(),
  dueDate: O.none(),
  id: O.none(),
  status: PaymentPlanPaymentPlanStatusEnum.ACTIVE,
};

const InstallmentSchema = Schema.Struct({
  amount: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Some<Money$.Money> =>
        O.exists(input, Money$.isPositive),
      { message: () => "Amount must be greater than $0.00" },
    ),
  ),
  dueDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Due Date is required",
    }),
  ),
  id: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  status: PaymentPlanPaymentPlanStatusEffectSchema,
});

const UpdatePaymentPlanInstallmentsRequestSchema = Schema.Struct({
  installments: Schema.Array(InstallmentSchema),
  paymentPlanId: EnderIdFormSchema,
});

type UpdatePaymentPlanInstallmentsFormInput = Schema.Schema.Encoded<
  typeof UpdatePaymentPlanInstallmentsRequestSchema
>;
type UpdatePaymentPlanInstallmentsFormOutput = Schema.Schema.Type<
  typeof UpdatePaymentPlanInstallmentsRequestSchema
>;

const useUpdatePaymentPlanInstallmentsForm = ({
  paymentPlan,
}: {
  paymentPlan: PaymentPlanResponse;
}) => {
  const form = useEffectSchemaForm({
    defaultValues: {
      installments: paymentPlan.installments.map(
        ({ amount, dueDate, id, status }) => ({
          amount: Money$.parse(amount),
          dueDate: LocalDate$.parse(dueDate),
          id: O.fromNullable(id),
          status,
        }),
      ),
      paymentPlanId: paymentPlan.id,
    },
    schema: UpdatePaymentPlanInstallmentsRequestSchema,
  });

  return {
    form,
  };
};

export { emptyInstallment, useUpdatePaymentPlanInstallmentsForm };
export type {
  UpdatePaymentPlanInstallmentsFormInput,
  UpdatePaymentPlanInstallmentsFormOutput,
};
