import { z } from "zod";

import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { castEnum } from "@ender/shared/utils/zod";

const ReportsNavigationGroupValues = [
  "FINANCIAL",
  "PROPERTY_OPERATIONS",
  "CUSTOM_REPORTS",
] as const;
const ReportsNavigationGroupSchema = z.enum(ReportsNavigationGroupValues);
type ReportsNavigationGroup = z.infer<typeof ReportsNavigationGroupSchema>;
const ReportsNavigationGroupEnum = castEnum<ReportsNavigationGroup>(
  ReportsNavigationGroupSchema,
);

const ReportsNavigationGroupLabelMap = {
  [ReportsNavigationGroupEnum.FINANCIAL]: "Financial",
  [ReportsNavigationGroupEnum.PROPERTY_OPERATIONS]: "Property Operations",
  [ReportsNavigationGroupEnum.CUSTOM_REPORTS]: "Custom Reports",
} as const;

const FinancialReportValues = [
  "ANNUAL_PROPERTY_FIXED_EXPENSES",
  "BALANCE_SHEET",
  "INCOME_STATEMENT",
  "WORKING_TRIAL_BALANCE",
  "GENERAL_LEDGER",
  "PAYABLES_REPORT",
  "YEAR_OVER_YEAR_REPORT",
  "JOURNAL_ENTRIES",
  "EXPANDED_BALANCE_SHEET",
  "EXPANDED_INCOME_STATEMENT",
  "EXPANDED_WORKING_TRIAL_BALANCE",
] as const;

const GeneralLedgerSubValues = [
  "CREDIT_LOSS_REPORT",
  "AR_AGING_REPORT",
  "AR_AGING_LEASE_SUMMARY",
  "AR_AGING_PROPERTY_SUMMARY",
  "PROPERTY_BREAKDOWN_WTB",
] as const;

const RentRollSubValues = [
  "ACCOUNTING_LEASE_ANALYSIS",
  "AR_RESIDENT_PAYMENT_ALLOCATION",
  "CHARGE_SCHEDULE_REPORT",
  "EXPANDED_MOVE_OUT_SUMMARY",
  "GROSS_POTENTIAL_RENT",
] as const;

const ReportsWithSubOptionsValues = ["General Ledger", "Rent Roll"] as const;
const ReportsWithSubOptionsSchema = z.enum(ReportsWithSubOptionsValues);
type ReportsWithSubOptions = z.infer<typeof ReportsWithSubOptionsSchema>;
const ReportsWithSubOptionsEnum = castEnum<ReportsWithSubOptions>(
  ReportsWithSubOptionsSchema,
);

const FinancialReportOptionSchema = z.enum(FinancialReportValues);
type FinancialReportOption = z.infer<typeof FinancialReportOptionSchema>;
const FinancialReportOptionEnum = castEnum<FinancialReportOption>(
  FinancialReportOptionSchema,
);

const GeneralLedgerSubOptionSchema = z.enum(GeneralLedgerSubValues);
type GeneralLedgerSubOption = z.infer<typeof GeneralLedgerSubOptionSchema>;
const GeneralLedgerSubOptionEnum = castEnum<GeneralLedgerSubOption>(
  GeneralLedgerSubOptionSchema,
);

const RentRollSubOptionSchema = z.enum(RentRollSubValues);
type RentRollSubOption = z.infer<typeof RentRollSubOptionSchema>;
const RentRollSubOptionEnum = castEnum<RentRollSubOption>(
  RentRollSubOptionSchema,
);

const PropertyOperationsReportValues = [
  "DELINQUENCY_MANAGER_METRICS",
  "RENT_ROLL",
  "PM_BALANCE_REPORT",
] as const;
const PropertyOperationsReportOptionSchema = z.enum(
  PropertyOperationsReportValues,
);
type PropertyOperationsReportOption = z.infer<
  typeof PropertyOperationsReportOptionSchema
>;
const PropertyOperationsReportOptionEnum =
  castEnum<PropertyOperationsReportOption>(
    PropertyOperationsReportOptionSchema,
  );

const CustomReportValues = [
  "ACTUAL_MOVE_OUTS",
  "OVERDUE_MOVE_OUTS",
  "ONBOARDED_TENANTS",
  "EXPIRING_LEASES",
  "NONRENEWABLE_LEASES",
  "LEASE_RENEWAL_RATE",
  "LEASE_CREATION_REPORT",
  "WORK_ORDER_DETAILS",
  "VENDOR_OVERVIEW",
  "DEPRECATED",
  "PORTFOLIO_METRICS",
  "APPLICANT_REQUIRING_TRANSUNION_SUPPORT",
  "EXPIRED_APPLICANT_IDENTITY_VERIFICATION",
  "UNEXPIRED_APPLICANT_IDENTITY_VERIFICATION_NOT_SCREENED",
  "COMPLETED_MOVE_IN",
  "ACTIVE_LEASES_WITHOUT_INSURANCE",
  "LEASES_WITH_EXPIRING_INSURANCE",
] as const;
const CustomReportOptionSchema = z.enum(CustomReportValues);
type CustomReportOption = z.infer<typeof CustomReportOptionSchema>;
const CustomReportOptionEnum = castEnum<CustomReportOption>(
  CustomReportOptionSchema,
);

const FinancialReportOptionSystemReportMap: Record<
  FinancialReportOption,
  string
> = {
  [FinancialReportOptionEnum.GENERAL_LEDGER]: "General Ledger",
  [FinancialReportOptionEnum.WORKING_TRIAL_BALANCE]: "Working Trial Balance",
  [FinancialReportOptionEnum.PAYABLES_REPORT]: "Payables Report",
  [FinancialReportOptionEnum.JOURNAL_ENTRIES]: "Journal Entries",
  [FinancialReportOptionEnum.BALANCE_SHEET]: "Balance Sheet",
  [FinancialReportOptionEnum.INCOME_STATEMENT]: "Income Statement",
  [FinancialReportOptionEnum.YEAR_OVER_YEAR_REPORT]: "Year Over Year Report",
  [FinancialReportOptionEnum.EXPANDED_BALANCE_SHEET]: "Expanded Balance Sheet",
  [FinancialReportOptionEnum.EXPANDED_INCOME_STATEMENT]:
    "Expanded Income Statement",
  [FinancialReportOptionEnum.EXPANDED_WORKING_TRIAL_BALANCE]:
    "Expanded Working Trial Balance",
  [FinancialReportOptionEnum.ANNUAL_PROPERTY_FIXED_EXPENSES]:
    "Annual Property Fixed Expenses",
} as const;

const GeneralLedgerSubOptionSystemReportMap: Record<
  GeneralLedgerSubOption,
  string
> = {
  [GeneralLedgerSubOptionEnum.CREDIT_LOSS_REPORT]: "Credit Loss Report",
  [GeneralLedgerSubOptionEnum.AR_AGING_REPORT]: "AR Aging Report",
  [GeneralLedgerSubOptionEnum.AR_AGING_LEASE_SUMMARY]: "AR Aging Lease Summary",
  [GeneralLedgerSubOptionEnum.AR_AGING_PROPERTY_SUMMARY]:
    "AR Aging Property Summary",
  [GeneralLedgerSubOptionEnum.PROPERTY_BREAKDOWN_WTB]: "Property Breakdown WTB",
};

const PropertyOperationsReportOptionSystemReportMap: Record<
  PropertyOperationsReportOption,
  string
> = {
  [PropertyOperationsReportOptionEnum.DELINQUENCY_MANAGER_METRICS]:
    "Delinquency Manager Metrics",
  [PropertyOperationsReportOptionEnum.RENT_ROLL]: "Rent Roll",
  [PropertyOperationsReportOptionEnum.PM_BALANCE_REPORT]: "PM Balance Report",
} as const;

const RentRollSubOptionSystemReportMap: Record<RentRollSubOption, string> = {
  [RentRollSubOptionEnum.ACCOUNTING_LEASE_ANALYSIS]:
    "Accounting Lease Analysis",
  [RentRollSubOptionEnum.AR_RESIDENT_PAYMENT_ALLOCATION]:
    "AR Resident Payment Allocation",
  [RentRollSubOptionEnum.CHARGE_SCHEDULE_REPORT]: "Charge Schedule Report",
  [RentRollSubOptionEnum.EXPANDED_MOVE_OUT_SUMMARY]:
    "Expanded Move Out Summary",
  [RentRollSubOptionEnum.GROSS_POTENTIAL_RENT]: "Gross Potential Rent",
};

const CustomReportOptionSqlReportMap: Record<CustomReportOption, string> = {
  [CustomReportOptionEnum.ACTUAL_MOVE_OUTS]: "Actual Move Out",
  [CustomReportOptionEnum.OVERDUE_MOVE_OUTS]: "Overdue Move Outs",
  [CustomReportOptionEnum.ONBOARDED_TENANTS]: "Onboarded Tenants",
  [CustomReportOptionEnum.EXPIRING_LEASES]: "Expiring Leases",
  [CustomReportOptionEnum.NONRENEWABLE_LEASES]: "Nonrenewable Leases",
  [CustomReportOptionEnum.LEASE_RENEWAL_RATE]: "Lease Renewal Rate",
  [CustomReportOptionEnum.LEASE_CREATION_REPORT]: "Lease Creation Report",
  [CustomReportOptionEnum.WORK_ORDER_DETAILS]: "Work Order Details",
  [CustomReportOptionEnum.VENDOR_OVERVIEW]: "Vendor Overview",
  [CustomReportOptionEnum.DEPRECATED]: "Deprecated",
  [CustomReportOptionEnum.PORTFOLIO_METRICS]: "Portfolio Metrics",
  [CustomReportOptionEnum.APPLICANT_REQUIRING_TRANSUNION_SUPPORT]:
    "Applicants Requiring TransUnion Support",
  [CustomReportOptionEnum.EXPIRED_APPLICANT_IDENTITY_VERIFICATION]:
    "Expired Applicant Identity Verification",
  [CustomReportOptionEnum.UNEXPIRED_APPLICANT_IDENTITY_VERIFICATION_NOT_SCREENED]:
    "Unexpired Applicant Identity Verification Not Screened",
  [CustomReportOptionEnum.COMPLETED_MOVE_IN]: "Completed Move In",
  [CustomReportOptionEnum.ACTIVE_LEASES_WITHOUT_INSURANCE]:
    "Active Leases Without Insurance",
  [CustomReportOptionEnum.LEASES_WITH_EXPIRING_INSURANCE]:
    "Leases with Expiring Insurance",
} as const;

// Change report names if desired
const reportLabelOverrideMap: Partial<
  Record<
    CustomReportOption | FinancialReportOption | PropertyOperationsReportOption,
    string
  >
> = {
  [CustomReportOptionEnum.ACTUAL_MOVE_OUTS]: "Actual Move-Outs",
  [CustomReportOptionEnum.OVERDUE_MOVE_OUTS]: "Overdue Move-Outs",
} as const;

const reportPermissionsMap: Partial<
  Record<
    CustomReportOption | FinancialReportOption | PropertyOperationsReportOption,
    FunctionalPermission[]
  >
> = {
  [PropertyOperationsReportOptionEnum.DELINQUENCY_MANAGER_METRICS]: [
    FunctionalPermissionEnum.VIEW_DELINQUENCY_METRICS,
  ],
} as const;

/* SORT ORDER OF REPORTS */
const FinancialReportSortOrder: FinancialReportOption[][] = [
  [
    FinancialReportOptionEnum.GENERAL_LEDGER,
    FinancialReportOptionEnum.WORKING_TRIAL_BALANCE,
    FinancialReportOptionEnum.PAYABLES_REPORT,
    FinancialReportOptionEnum.JOURNAL_ENTRIES,
    FinancialReportOptionEnum.EXPANDED_INCOME_STATEMENT,
  ],
  [
    FinancialReportOptionEnum.BALANCE_SHEET,
    FinancialReportOptionEnum.INCOME_STATEMENT,
    FinancialReportOptionEnum.YEAR_OVER_YEAR_REPORT,
    FinancialReportOptionEnum.EXPANDED_BALANCE_SHEET,
    FinancialReportOptionEnum.EXPANDED_WORKING_TRIAL_BALANCE,
    FinancialReportOptionEnum.ANNUAL_PROPERTY_FIXED_EXPENSES,
  ],
];

const PropertyOperationsReportSortOrder: PropertyOperationsReportOption[][] = [
  [
    PropertyOperationsReportOptionEnum.DELINQUENCY_MANAGER_METRICS,
    PropertyOperationsReportOptionEnum.RENT_ROLL,
    PropertyOperationsReportOptionEnum.PM_BALANCE_REPORT,
  ],
];

const CustomReportsSortOrder: CustomReportOption[][] = [
  [
    CustomReportOptionEnum.ACTUAL_MOVE_OUTS,
    CustomReportOptionEnum.OVERDUE_MOVE_OUTS,
    CustomReportOptionEnum.COMPLETED_MOVE_IN,
    CustomReportOptionEnum.ONBOARDED_TENANTS,
  ],
  [
    CustomReportOptionEnum.EXPIRING_LEASES,
    CustomReportOptionEnum.NONRENEWABLE_LEASES,
    CustomReportOptionEnum.LEASE_RENEWAL_RATE,
    CustomReportOptionEnum.LEASE_CREATION_REPORT,
    CustomReportOptionEnum.ACTIVE_LEASES_WITHOUT_INSURANCE,
    CustomReportOptionEnum.LEASES_WITH_EXPIRING_INSURANCE,
  ],
  [
    CustomReportOptionEnum.WORK_ORDER_DETAILS,
    CustomReportOptionEnum.VENDOR_OVERVIEW,
    CustomReportOptionEnum.DEPRECATED,
    CustomReportOptionEnum.PORTFOLIO_METRICS,
  ],
  [
    CustomReportOptionEnum.APPLICANT_REQUIRING_TRANSUNION_SUPPORT,
    CustomReportOptionEnum.EXPIRED_APPLICANT_IDENTITY_VERIFICATION,
    CustomReportOptionEnum.UNEXPIRED_APPLICANT_IDENTITY_VERIFICATION_NOT_SCREENED,
  ],
];

// Filter out the General Ledger Report from the systemReports
const reportsToFilter: string[] = ["General Ledger Report"];

const extraFinancialReports: Record<string, string>[] = [
  {
    id: FinancialReportOptionSystemReportMap.GENERAL_LEDGER,
    name: FinancialReportOptionSystemReportMap.GENERAL_LEDGER,
  },
  {
    id: FinancialReportOptionSystemReportMap.BALANCE_SHEET,
    name: FinancialReportOptionSystemReportMap.BALANCE_SHEET,
  },
  {
    id: FinancialReportOptionSystemReportMap.INCOME_STATEMENT,
    name: FinancialReportOptionSystemReportMap.INCOME_STATEMENT,
  },
  {
    id: FinancialReportOptionSystemReportMap.EXPANDED_BALANCE_SHEET,
    name: FinancialReportOptionSystemReportMap.EXPANDED_BALANCE_SHEET,
  },
  {
    id: FinancialReportOptionSystemReportMap.EXPANDED_INCOME_STATEMENT,
    name: FinancialReportOptionSystemReportMap.EXPANDED_INCOME_STATEMENT,
  },
  {
    id: FinancialReportOptionSystemReportMap.EXPANDED_WORKING_TRIAL_BALANCE,
    name: FinancialReportOptionSystemReportMap.EXPANDED_WORKING_TRIAL_BALANCE,
  },
  {
    id: FinancialReportOptionSystemReportMap.ANNUAL_PROPERTY_FIXED_EXPENSES,
    name: FinancialReportOptionSystemReportMap.ANNUAL_PROPERTY_FIXED_EXPENSES,
  },
] as const;

export {
  CustomReportOptionEnum,
  CustomReportOptionSchema,
  CustomReportOptionSqlReportMap,
  CustomReportsSortOrder,
  CustomReportValues,
  extraFinancialReports,
  FinancialReportOptionEnum,
  FinancialReportOptionSchema,
  FinancialReportOptionSystemReportMap,
  FinancialReportSortOrder,
  FinancialReportValues,
  GeneralLedgerSubOptionEnum,
  GeneralLedgerSubOptionSchema,
  GeneralLedgerSubOptionSystemReportMap,
  GeneralLedgerSubValues,
  PropertyOperationsReportOptionEnum,
  PropertyOperationsReportOptionSchema,
  PropertyOperationsReportOptionSystemReportMap,
  PropertyOperationsReportSortOrder,
  PropertyOperationsReportValues,
  RentRollSubOptionEnum,
  RentRollSubOptionSchema,
  RentRollSubOptionSystemReportMap,
  RentRollSubValues,
  reportLabelOverrideMap,
  reportPermissionsMap,
  ReportsNavigationGroupEnum,
  ReportsNavigationGroupLabelMap,
  ReportsNavigationGroupSchema,
  ReportsNavigationGroupValues,
  reportsToFilter,
  ReportsWithSubOptionsEnum,
  ReportsWithSubOptionsSchema,
};

export type {
  CustomReportOption,
  FinancialReportOption,
  GeneralLedgerSubOption,
  PropertyOperationsReportOption,
  RentRollSubOption,
  ReportsNavigationGroup,
  ReportsWithSubOptions,
};
