import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PaymentInformationAppFeeStatusValues = [
  "PAID",
  "NOT_PAID",
  "AWAITING_PAYMENT",
  "PROCESSING",
  "FAILED",
] as const;

const PaymentInformationAppFeeStatusEffectSchema = Schema.Literal(
  ...PaymentInformationAppFeeStatusValues,
);

type PaymentInformationAppFeeStatus = Schema.Schema.Type<
  typeof PaymentInformationAppFeeStatusEffectSchema
>;

const PaymentInformationAppFeeStatusEnum = castEnum(
  PaymentInformationAppFeeStatusEffectSchema,
);

function randomPaymentInformationAppFeeStatus(): PaymentInformationAppFeeStatus {
  return rand(PaymentInformationAppFeeStatusValues);
}

export {
  PaymentInformationAppFeeStatusEffectSchema,
  PaymentInformationAppFeeStatusEnum,
  PaymentInformationAppFeeStatusValues,
  randomPaymentInformationAppFeeStatus,
};
export type { PaymentInformationAppFeeStatus };
