import { Schema } from "@effect/schema";
import { Option as O, Record as R,String as S } from "effect";

import { applicantTypeOptions, isBirthdayMinor } from "@ender/entities/utils/application-utils";
import type { UseFormReturn } from "@ender/form-system/base";
import {
  EmailEffectSchema,
  LocalDateEffectSchema,
} from "@ender/form-system/schema";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Spacing } from "@ender/shared/ds/flex";
import { Inset } from "@ender/shared/ds/inset";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { Select } from "@ender/shared/ds/select";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tuple } from "@ender/shared/ds/tuple";
import type { ApplicationApplicantType } from "@ender/shared/generated/ender.model.leasing";

const ApplicantInfoFormSchema = Schema.Struct({
  birthday: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,

    Schema.filter(O.isSome, { message: () => "Birthday is required" }),
  ),
  email: EmailEffectSchema,
  firstName: Schema.String,
  lastName: Schema.String,
  phone: Schema.String,
}).pipe(
  Schema.filter((schema) => {
    const issues: Schema.FilterIssue[] = [];
    if (!isBirthdayMinor(schema.birthday) && S.isEmpty(schema.email)) {
      issues.push({
        message: `Email is required`,
        path: ["email"],
      });
    }
    return issues;
  }),
);

type ApplicantInfoFormInput = Schema.Schema.Encoded<
  typeof ApplicantInfoFormSchema
>;
type ApplicantInfoFormOutput = Schema.Schema.Type<
  typeof ApplicantInfoFormSchema
>;

type ApplicantInfoFieldset = keyof ApplicantInfoFormInput | "applicantType";

type EditableApplicantInfoProps = {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<ApplicantInfoFormInput, any, ApplicantInfoFormOutput>;
  applicantType: O.Option<ApplicationApplicantType>;
  onApplicantTypeChange: (value: O.Option<ApplicationApplicantType>) => void;
  isMinor: boolean;
  editableFields: ApplicantInfoFieldset[];
};

function EditableApplicantInfo(props: EditableApplicantInfoProps) {
  const {
    form,
    isMinor,
    applicantType,
    onApplicantTypeChange,
    editableFields: _editableFields,
  } = props;

  const editableFields: Record<ApplicantInfoFieldset, boolean> =
    R.fromIterableWith(_editableFields, (f) => [f, true]);

  return (
    <>
      <Tuple
        label="First Name"
        value={
          <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
            <FormTextInput
              form={form}
              name="firstName"
              size="sm"
              disabled={!editableFields["firstName"]}
            />
          </Inset>
        }
      />
      <Tuple
        label="Last Name"
        value={
          <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
            <FormTextInput
              form={form}
              name="lastName"
              size="sm"
              disabled={!editableFields["lastName"]}
            />
          </Inset>
        }
      />
      <Tuple
        label="Role"
        value={
          <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
            <Select<ApplicationApplicantType>
              data={applicantTypeOptions}
              value={applicantType}
              onChange={onApplicantTypeChange}
              size="sm"
              disabled={!editableFields["applicantType"]}
            />
          </Inset>
        }
      />
      <Tuple
        label="Date of Birth"
        value={
          <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
            <FormDateInput
              form={form}
              name="birthday"
              size="sm"
              disabled={!editableFields["birthday"]}
            />
          </Inset>
        }
      />
      {!isMinor && (
        <Tuple
          label="Phone"
          value={
            <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
              <FormPhoneInput
                form={form}
                name="phone"
                disabled={!editableFields["phone"]}
              />
            </Inset>
          }
        />
      )}
      {!isMinor && (
        <Tuple
          label="Email"
          value={
            <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
              <FormTextInput
                form={form}
                name="email"
                size="sm"
                disabled={!editableFields["email"]}
              />
            </Inset>
          }
        />
      )}
    </>
  );
}

export { ApplicantInfoFormSchema, EditableApplicantInfo };
export type {
  ApplicantInfoFieldset,
  ApplicantInfoFormInput,
  ApplicantInfoFormOutput,
};
