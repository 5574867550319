import { Predicate as P } from "effect";

import { LocalDate$ } from "@ender/shared/core";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Switch } from "@ender/shared/ds/switch";
import { FontSize, Text } from "@ender/shared/ds/text";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type {
  AccountingPeriod,
  AccountingPeriodPeriodStatus,
} from "@ender/shared/generated/ender.model.accounting";
import { AccountingPeriodPeriodStatusEnum } from "@ender/shared/generated/ender.model.accounting";
import type { EnderError } from "@ender/shared/utils/error";
import { fail } from "@ender/shared/utils/error";
import { toLongMonthYearString } from "@ender/shared/utils/local-date";
import { fromScreamingSnakeCaseToSpaceCase } from "@ender/shared/utils/string";

type UpdatePeriodHelperProps = {
  period: AccountingPeriod;
  isAccountantsOpen: boolean;
  isClerksOpen: boolean;
  setStatus: (status: AccountingPeriodPeriodStatus) => void;
  updateCallback: () => void;
};

type PeriodCarProps = {
  period: AccountingPeriod;
  updateCallback: () => void;
};

function getPeriodStatus(isAccountantsOpen: boolean, isClerksOpen: boolean) {
  if (isAccountantsOpen && !isClerksOpen) {
    return AccountingPeriodPeriodStatusEnum.PARTIALLY_OPEN;
  }

  if (!isAccountantsOpen && !isClerksOpen) {
    return AccountingPeriodPeriodStatusEnum.CLOSED;
  }

  return AccountingPeriodPeriodStatusEnum.OPEN;
}

async function updatePeriodHelper({
  period,
  isAccountantsOpen,
  isClerksOpen,
  setStatus,
  updateCallback,
}: UpdatePeriodHelperProps) {
  const periodStatus = getPeriodStatus(isAccountantsOpen, isClerksOpen);

  if (periodStatus !== period.status) {
    try {
      const updatedPeriod = await AccountingAPI.updateAccountingPeriod({
        accountingPeriodId: period.id,
        status: periodStatus,
      });
      setStatus(updatedPeriod.status);
      period.status = updatedPeriod.status;
    } catch (error) {
      const enderError = error as EnderError;
      if (P.isNotNullable(enderError.json.errors)) {
        fail(enderError);
      }
    } finally {
      updateCallback();
    }
  }
}

function PeriodCard({ period, updateCallback }: PeriodCarProps) {
  const isAccountantsOpen =
    period.status !== AccountingPeriodPeriodStatusEnum.CLOSED;
  const isClerksOpen = period.status === AccountingPeriodPeriodStatusEnum.OPEN;

  const handleAccountantsSwitchChange = async (
    newIsAccountantsOpen: boolean,
  ) => {
    await updatePeriodHelper({
      isAccountantsOpen: newIsAccountantsOpen,
      isClerksOpen: isClerksOpen,
      period: period,
      setStatus: (newStatus) => {
        period.status = newStatus;
      },
      updateCallback: updateCallback,
    });
  };

  const handleClerksSwitchChange = async (newIsClerksOpen: boolean) => {
    await updatePeriodHelper({
      isAccountantsOpen: isAccountantsOpen,
      isClerksOpen: newIsClerksOpen,
      period: period,
      setStatus: (newStatus) => {
        period.status = newStatus;
      },
      updateCallback: updateCallback,
    });
  };

  const badgeColor = (() => {
    switch (period.status) {
      case AccountingPeriodPeriodStatusEnum.PARTIALLY_OPEN:
        return BadgeColor.yellow;
      case AccountingPeriodPeriodStatusEnum.CLOSED:
        return BadgeColor.red;
      case AccountingPeriodPeriodStatusEnum.OPEN:
        return BadgeColor.green;
      default:
        return BadgeColor.slate;
    }
  })();

  return (
    <Card>
      <Stack>
        <Stack spacing={Spacing.sm} noWrap>
          <Group align={Align.center} justify={Justify.between} noWrap>
            <H3>{toLongMonthYearString(LocalDate$.of(period.endDate))}</H3>
            <Badge color={badgeColor}>
              {fromScreamingSnakeCaseToSpaceCase(period.status)}
            </Badge>
          </Group>
          <Text size={FontSize.sm}>
            {LocalDate$.toFormatted(
              LocalDate$.of(period.startDate),
              LocalDate$.Formats.DEFAULT,
            )}{" "}
            -{" "}
            {LocalDate$.toFormatted(
              LocalDate$.of(period.endDate),
              LocalDate$.Formats.DEFAULT,
            )}
          </Text>
        </Stack>
        <Stack spacing={Spacing.sm}>
          <Switch
            label="Accountants"
            value={isAccountantsOpen}
            onChange={handleAccountantsSwitchChange}
            disabled={isClerksOpen}
          />
          <Switch
            label="Clerks"
            value={isClerksOpen}
            onChange={handleClerksSwitchChange}
            disabled={!isAccountantsOpen}
          />
        </Stack>
      </Stack>
    </Card>
  );
}

export { PeriodCard };
