import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BankAccountAccountStatusValues = [
  "NOT_REGISTERED",
  "NEW",
  "RECEIVE_ONLY",
  "VERIFIED",
  "VERIFICATION_FAILED",
  "ERRORED",
  "REMOVED",
] as const;

const BankAccountAccountStatusEffectSchema = Schema.Literal(
  ...BankAccountAccountStatusValues,
);

type BankAccountAccountStatus = Schema.Schema.Type<
  typeof BankAccountAccountStatusEffectSchema
>;

const BankAccountAccountStatusEnum = castEnum(
  BankAccountAccountStatusEffectSchema,
);

function randomBankAccountAccountStatus(): BankAccountAccountStatus {
  return rand(BankAccountAccountStatusValues);
}

export {
  BankAccountAccountStatusEffectSchema,
  BankAccountAccountStatusEnum,
  BankAccountAccountStatusValues,
  randomBankAccountAccountStatus,
};
export type { BankAccountAccountStatus };
