/**
 * HTTP Method: GET
 * Pathname: /bankAccounts/{bankAccountId}
 * @function getBankAccount
 * @memberof BankingAPI
 * @param {BankingAPIGetBankAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetBankAccountResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetBankAccountResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetBankAccountPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIGetBankAccountSearchParams = {
  token?: string | undefined;
};

type BankingAPIGetBankAccountPayload = BankingAPIGetBankAccountPathParams &
  BankingAPIGetBankAccountSearchParams;

function getBankAccountUnsafeEffect(payload: BankingAPIGetBankAccountPayload) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetBankAccountResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetBankAccountResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/bankAccounts/${bankAccountId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBankAccount(
  payload: BankingAPIGetBankAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<GetBankAccountResponse> {
  return F.pipe(
    payload,
    getBankAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankAccount };
export type {
  BankingAPIGetBankAccountPathParams,
  BankingAPIGetBankAccountPayload,
  BankingAPIGetBankAccountSearchParams,
};
