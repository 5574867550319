import type { ElementRef } from "react";
import { forwardRef } from "react";

import { isBirthdayMinor } from "@ender/entities/utils/application-utils";
import { LocalDate$ } from "@ender/shared/core";
import { Badge } from "@ender/shared/ds/badge";
import type { GetApplicationGroupResponseApplicationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import { Color } from "@ender/shared/utils/theming";

type ApplicantStatusBadgeProps = {
  application: Pick<
    GetApplicationGroupResponseApplicationResponse,
    "completedApplication"
  > & {
    applicant: Pick<
      GetApplicationGroupResponseApplicationResponse["applicant"],
      "birthday"
    >;
  };
};

const ApplicationStatusBadge = forwardRef<
  ElementRef<typeof Badge>,
  ApplicantStatusBadgeProps
>(function ApplicantStatusBadge(props, ref) {
  const { application } = props;
  const { completedApplication, applicant } = application;
  if (isBirthdayMinor(LocalDate$.parse(applicant.birthday))) {
    return null;
  }
  return (
    <Badge
      color={completedApplication ? Color.yellow : Color.slate}
      size="sm"
      ref={ref}>
      {completedApplication ? "Submitted" : "In Progress"}
    </Badge>
  );
});

export { ApplicationStatusBadge };

export type { ApplicantStatusBadgeProps };
