import { IconEdit, IconLock, IconLockOpen } from "@tabler/icons-react";
import type { QueryObserverResult } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useEffect, useMemo, useState } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { EnderId, Money, Percent } from "@ender/shared/core";
import { Money$, Percent$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { PercentDisplay } from "@ender/shared/ds/percent-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";
import type {
  InvestorAllocationAllocationStatus,
  Market,
} from "@ender/shared/generated/com.ender.buy.model.misc";
import { InvestorAllocationAllocationStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";
import { PropertyHomeTypeValues } from "@ender/shared/generated/ender.model.core.property";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { capitalize } from "@ender/shared/utils/string";

import type { BuyBoxDetails } from "./buy-box";
import { BuyBox } from "./buy-box";
import { CreateUpdateBuyBoxForm } from "./create-update-buy-box-form";
import { UpdateMarketAllocationsForm } from "./update-market-allocations-form";

const propertyTypeOptions = PropertyHomeTypeValues.map((homeType) => ({
  label: capitalize(homeType),
  value: homeType,
}));

type InvestorAllocation = {
  id: EnderId;
  pmId: EnderId;
  firmId: EnderId;
  market: Market;
  capitalAllocation: Money;
  investmentPeriodInDays: number;
  minAllocation: Percent;
  status: InvestorAllocationAllocationStatus;
  totalSpend: Money;
  maxSpend: Money;
  expectedSpend: Money;
  numHomesBought: number;
  amountInOfferStatus: Money;
  amountInDueDiligenceStatus: Money;
};
type MarketBuyBoxesProps = {
  market: Market;
  buyBoxes: BuyBoxDetails[];
  fetchBuyBoxes: () => Promise<QueryObserverResult<BuyBoxDetails[]>>;
  investorAllocations: InvestorAllocation[];
  fetchAllocations: () => Promise<QueryObserverResult<InvestorAllocation[]>>;
  investorId: EnderId;
  openRightRail: ({
    marketId,
    marketName,
  }: {
    marketId: EnderId;
    marketName: string | Null;
  }) => void;
  closeRightRail: () => void;
};

const MarketAllocationStatus = {
  ACTIVE: "Active",
  PAUSED: "Paused",
};
const { ACTIVE, PAUSED } = InvestorAllocationAllocationStatusEnum;

function MarketBuyBoxes({
  buyBoxes,
  fetchAllocations,
  fetchBuyBoxes,
  investorAllocations,
  investorId,
  market,
  openRightRail,
  closeRightRail,
}: MarketBuyBoxesProps) {
  const [isBuyBoxModalOpen, setIsBuyBoxModalOpen] = useState(false);
  const [isAllocationsModalOpen, setIsAllocationsModalOpen] = useState(false);
  const [selectedBuyBox, setSelectedBuyBox] = useState<BuyBoxDetails | null>(
    null,
  );

  // this useEffect triggers the right rail to close when the respective accordion unmounts
  useEffect(() => {
    return () => closeRightRail();
  }, [closeRightRail]);

  const marketAllocation = useMemo(() => {
    return investorAllocations.find(
      (allocation) => allocation.market.id === market.id,
    );
  }, [investorAllocations, market.id]);

  const buyBoxesForMarket = useMemo(() => {
    return buyBoxes.filter((buyBox) => buyBox.marketId === market.id);
  }, [buyBoxes, market.id]);

  async function toggleMarketAllocationStatus() {
    if (P.isNotNullable(marketAllocation)) {
      const newStatus =
        marketAllocation.status === MarketAllocationStatus.ACTIVE
          ? PAUSED
          : ACTIVE;
      await BuyAPI.updateAllocationStatus({
        allocationId: marketAllocation.id,
        status: newStatus,
      });
      fetchAllocations();
      showSuccessNotification({
        message: `Market Allocation has been ${newStatus === ACTIVE ? "set to active" : "paused"}`,
      });
    }
  }

  function toggleBuyBoxModal(buyBox: BuyBoxDetails | Null = NULL) {
    setSelectedBuyBox(buyBox);
    setIsBuyBoxModalOpen((prev) => !prev);
  }

  function toggleAllocationsModal() {
    setIsAllocationsModalOpen((prev) => !prev);
  }

  const handleBuyBoxSaveSuccess = () => {
    fetchBuyBoxes();
    showSuccessNotification({ message: "Buy Box updated successfully." });
    toggleBuyBoxModal();
  };

  const handleAllocationSaveSuccess = () => {
    fetchAllocations();
    showSuccessNotification({
      message: "Market allocation updated successfully.",
    });
    toggleAllocationsModal();
  };
  return (
    <div>
      <Modal
        size={ModalSize.sm}
        title={`${P.isNotNullable(selectedBuyBox) ? "Edit" : "Add"} Buy Box`}
        opened={isBuyBoxModalOpen}
        onClose={toggleBuyBoxModal}>
        <CreateUpdateBuyBoxForm
          onSuccess={handleBuyBoxSaveSuccess}
          buyBox={selectedBuyBox}
          firmId={investorId}
          marketId={market.id}
          market={market}
          propertyTypeOptions={propertyTypeOptions}
        />
      </Modal>
      <Modal
        title={`Update Allocations for ${market.name}`}
        size={ModalSize.sm}
        opened={isAllocationsModalOpen}
        onClose={toggleAllocationsModal}>
        <UpdateMarketAllocationsForm
          onSuccess={handleAllocationSaveSuccess}
          market={market}
          firmId={investorId}
          marketAllocation={marketAllocation}
        />
      </Modal>

      <Stack>
        <Group align={Align.center} spacing={Spacing.sm}>
          <ActionIcon
            label={
              marketAllocation?.status === MarketAllocationStatus.PAUSED
                ? "Activate Market Allocations"
                : "Pause Market Allocations"
            }
            disabled={!marketAllocation}
            disabledTooltip="No Buy Boxes to Pause"
            onClick={toggleMarketAllocationStatus}>
            {marketAllocation?.status === MarketAllocationStatus.PAUSED ? (
              <IconLock color={EnderThemeColorEnum.GRAY_800} />
            ) : (
              <IconLockOpen color={EnderThemeColorEnum.PRIMARY} />
            )}
          </ActionIcon>
          <ActionIcon
            label="Edit Market Allocation"
            onClick={toggleAllocationsModal}>
            <IconEdit />
          </ActionIcon>
          <div style={{ marginLeft: "50px" }}>
            <Button
              onClick={() =>
                openRightRail({ marketId: market.id, marketName: market.name })
              }>
              Market Assumptions
            </Button>
          </div>
        </Group>
        <Group spacing={Spacing.xl} align={Align.center}>
          <div style={{ width: "400px" }}>
            {!marketAllocation ? (
              <i>No Market Allocation</i>
            ) : (
              <>
                <Tuple
                  label="Current Budget:"
                  value={
                    <MoneyDisplay
                      value={Money$.parse(marketAllocation.capitalAllocation)}
                      showSymbol
                    />
                  }
                />
                <Tuple
                  label="Duration (days):"
                  value={marketAllocation.investmentPeriodInDays}
                />
                <Tuple
                  label="Minimum Allocation:"
                  value={
                    <PercentDisplay
                      value={Percent$.parse(marketAllocation.minAllocation)}
                    />
                  }
                />
                <Tuple
                  label="Total Spend:"
                  value={
                    <MoneyDisplay
                      value={Money$.parse(marketAllocation.totalSpend)}
                      showSymbol
                    />
                  }
                />
                <Tuple
                  label="Maximum Spend:"
                  value={
                    <MoneyDisplay
                      value={Money$.parse(marketAllocation.maxSpend)}
                      showSymbol
                    />
                  }
                />
                <Tuple
                  label="Expected Spend:"
                  value={
                    <MoneyDisplay
                      value={Money$.parse(marketAllocation.expectedSpend)}
                      showSymbol
                    />
                  }
                />
                <Tuple
                  label="Number of Homes Bought:"
                  value={marketAllocation.numHomesBought}
                />
                <Tuple
                  label="Total Amount in Offers:"
                  value={
                    <MoneyDisplay
                      value={Money$.parse(marketAllocation.amountInOfferStatus)}
                      showSymbol
                    />
                  }
                />
                <Tuple
                  label="Total Amount in Due Diligence:"
                  value={
                    <MoneyDisplay
                      value={Money$.parse(
                        marketAllocation.amountInDueDiligenceStatus,
                      )}
                      showSymbol
                    />
                  }
                />
              </>
            )}
          </div>
        </Group>
        <Group spacing={Spacing.md}>
          {buyBoxesForMarket.map((buyBox) => (
            <BuyBox
              key={buyBox.id}
              buyBox={buyBox}
              propertyTypeOptions={propertyTypeOptions}
              fetchBuyBoxes={fetchBuyBoxes}
              toggleBuyBoxModal={() => toggleBuyBoxModal(buyBox)}
            />
          ))}
          <Group align={Align.center}>
            <Button
              variant={ButtonVariant.outlined}
              onClick={() => toggleBuyBoxModal()}>
              Add Buy Box
            </Button>
          </Group>
        </Group>
      </Stack>
    </div>
  );
}

export { MarketBuyBoxes };
