import { useMutation } from "@tanstack/react-query";
import type { ElementRef } from "react";
import { forwardRef, useCallback } from "react";

import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";

import type {
  EditVehicleFormOutput,
  EditVehicleFormProps,
} from "./widgets-leasing-edit-vehicle-form";
import { EditVehicleForm } from "./widgets-leasing-edit-vehicle-form";

type ConnectedEditVehicleFormProps = Pick<
  EditVehicleFormProps,
  "onCancel" | "vehicle"
> & {
  onSuccess: () => void;
};

const ConnectedEditVehicleForm = forwardRef<
  ElementRef<typeof EditVehicleForm>,
  ConnectedEditVehicleFormProps
>(function ConnectedEditVehicleForm(props, ref) {
  const { onSuccess, onCancel, vehicle } = props;
  const { id: vehicleId } = vehicle;

  const { mutateAsync: updateVehicle } = useMutation({
    mutationFn: LeasingAPI.updateVehicle,
    mutationKey: ["LeasingAPI.updateVehicle"] as const,
  });

  const handleSubmit = useCallback(
    async (values: EditVehicleFormOutput) => {
      await updateVehicle({ parkingSpot: "", vehicleId, ...values });
      onSuccess();
    },
    [updateVehicle, onSuccess, vehicleId],
  );

  return (
    <EditVehicleForm
      onCancel={onCancel}
      onSubmit={handleSubmit}
      vehicle={vehicle}
      ref={ref}
    />
  );
});

export { ConnectedEditVehicleForm };

export type { ConnectedEditVehicleFormProps };
