import { Array as A, Option as O, flow } from "effect";
import type { Dispatch, SetStateAction } from "react";
import { useMemo, useState } from "react";

type UseListStateHandlers<T> = {
  setState: Dispatch<SetStateAction<T[]>>;
  append: (...items: T[]) => void;
  prepend: (...items: T[]) => void;
  insert: (index: number, ...items: T[]) => void;
  pop: () => void;
  shift: () => void;
  apply: (fn: (item: T, index: number) => T) => void;
  applyWhere: (
    condition: (item: T, index: number) => boolean,
    fn: (item: T, index: number) => T,
  ) => void;
  remove: (...indices: number[]) => void;
  reorder: ({ from, to }: { from: number; to: number }) => void;
  swap: ({ from, to }: { from: number; to: number }) => void;
  setItem: (index: number, item: T) => void;
  setItemProp: <K extends keyof T, U extends T[K]>(
    index: number,
    prop: K,
    value: U,
  ) => void;
  filter: (fn: (item: T, i: number) => boolean) => void;
};

type UseListState<T> = [T[], UseListStateHandlers<T>];

function useListState<T>(initialValue: T[] = []): UseListState<T> {
  const [state, setState] = useState(initialValue);

  const handlers = useMemo<UseListStateHandlers<T>>(
    () => ({
      append: (...items: T[]) => setState(A.appendAll(items)),
      apply: (fn: (item: T, index: number) => T) => setState(A.map(fn)),
      applyWhere: (
        condition: (item: T, index: number) => boolean,
        fn: (item: T, index: number) => T,
      ) =>
        setState((current) =>
          current.map((item, index) =>
            condition(item, index) ? fn(item, index) : item,
          ),
        ),
      filter: (fn: (item: T, i: number) => boolean) => {
        setState(A.filter(fn));
      },
      insert: (index: number, ...items: T[]) =>
        setState((current) => [
          ...current.slice(0, index),
          ...items,
          ...current.slice(index),
        ]),
      pop: () => setState(flow(A.init, O.getOrElse(A.empty))),
      prepend: (...items: T[]) => setState(A.prependAll(items)),
      remove: (...indices: number[]) =>
        setState((current) =>
          current.filter((_, index) => !indices.includes(index)),
        ),
      reorder: ({ from, to }: { from: number; to: number }) =>
        setState((current) => {
          const cloned = [...current];
          const item = current[from];

          cloned.splice(from, 1);
          cloned.splice(to, 0, item);

          return cloned;
        }),
      setItem: (index: number, item: T) => setState(A.replace(index, item)),
      setItemProp: <K extends keyof T, U extends T[K]>(
        index: number,
        prop: K,
        value: U,
      ) => setState(A.modify(index, (v) => ({ ...v, [prop]: value }))),
      setState,
      shift: () => setState(flow(A.tail, O.getOrElse(A.empty))),
      swap: ({ from, to }: { from: number; to: number }) =>
        setState((current) => {
          const cloned = [...current];
          const fromItem = cloned[from];
          const toItem = cloned[to];

          cloned.splice(to, 1, fromItem);
          cloned.splice(from, 1, toItem);

          return cloned;
        }),
    }),
    [],
  );

  return [state, handlers];
}

export { useListState };
export type { UseListState, UseListStateHandlers };
