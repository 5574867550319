// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { zodResolver } from "@mantine/form";
import { Option as O, Predicate as P, flow } from "effect";
import * as S from "effect/String";
import { z } from "zod";

import type { Null, Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema, LocalDate$, Money$ } from "@ender/shared/core";
import { useForm } from "@ender/shared/forms/hooks/general";
import {
  ModelTypeEnum,
  ModelTypeValues,
} from "@ender/shared/generated/com.ender.common.model";
import type { CreateInvoiceRequestJob } from "@ender/shared/generated/ender.api.accounting.request";
import type { Vendor } from "@ender/shared/generated/ender.model.core.vendor";
import type { BankTransaction } from "@ender/shared/generated/ender.model.payments";
import {
  PartyEnum,
  PartyValues,
} from "@ender/shared/generated/ender.model.payments";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  InvoiceInvoiceTypeValues,
  InvoicePayableTypeValues,
} from "@ender/shared/generated/ender.model.payments.invoice";
import type { Task } from "@ender/shared/generated/ender.model.task";
import { LocalDateZodSchema, OptionSchema } from "@ender/shared/utils/zod";
import { AllocationListItemSchema } from "@ender/widgets/finance/allocation-list";

const MinimalAccountingPeriodSchema = z.object({
  id: EnderIdSchema,
  startDate: z.string().optional(),
});

const DATE_RANGE_ERROR_MESSAGE =
  "You cannot book an entry more than ten years before or after today’s date.";
const isDateWithinTenYears = (date: Date | Null | Undefined): boolean => {
  // Skip validation if the value is null or undefined
  if (P.isNullable(date)) {
    return true;
  }

  const today = new Date();
  const pastLimit = new Date(
    today.getFullYear() - 10,
    today.getMonth(),
    today.getDate(),
  );
  const futureLimit = new Date(
    today.getFullYear() + 10,
    today.getMonth(),
    today.getDate(),
  );
  return date >= pastLimit && date <= futureLimit;
};

const AllocationsByPropertySchema = z.object({
  allocations: z
    .array(AllocationListItemSchema)
    .min(1, { message: "At least one allocation is required" }),
  propertyId: EnderIdSchema.optional().refine(
    (value): value is EnderId => S.isNonEmpty(value ?? ""),
    {
      message: "Property is required.",
    },
  ),
});
type AllocationsByProperty = z.input<typeof AllocationsByPropertySchema>;

const PayeeSchema = z
  .object({
    id: EnderIdSchema.or(z.literal("EXTERNAL")),
    name: z.string(),
    party: z.enum(PartyValues),
  })
  .optional()
  .refine(P.isNotNullable, { message: "Payee is required." });
type Payee = z.infer<typeof PayeeSchema>;

// TODO: this should probably be broken into a base schema and then a schema for PM and a schema for vendor
// then we can handle the allocationsByProperty validation differently and cleanly AND have the inferred types
const CreateInvoiceFormSchema = z
  .object({
    _mustConfirm: z.boolean().optional(),
    _shouldClose: z.boolean().optional(),
    allocationsByProperty: AllocationsByPropertySchema.array(),
    bankAccountId: OptionSchema(EnderIdSchema),
    bankTransaction: z.object({ id: EnderIdSchema }).nullish(),
    description: z.string().min(1, { message: "Description is required." }),
    instantApprove: z.boolean().default(false),
    invoiceNumber: z.string().optional(),
    invoiceType: z.enum(InvoiceInvoiceTypeValues),
    job: z
      .object({
        id: EnderIdSchema,
        type: z.enum(ModelTypeValues),
      })
      .nullish(),
    ledgerDate: OptionSchema(LocalDateZodSchema)
      .refine(O.isSome, { message: "Ledger Date is required." })
      .refine(
        flow(
          O.map((val) => val.toDate()),
          O.getOrNull,
          isDateWithinTenYears,
        ),
        {
          message: DATE_RANGE_ERROR_MESSAGE,
        },
      ),
    payableType: z.enum(InvoicePayableTypeValues).nullable(),
    payee: PayeeSchema.optional().refine(P.isNotNullable, {
      message: "Payee is required.",
    }),
    paymentDate: z.date().nullish(),
    periodId: OptionSchema(MinimalAccountingPeriodSchema),
    poNumbers: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (P.isNullable(val) || S.isEmpty(val)) {
            return true;
          }
          const poNumbers = val.split(",").map((num) => parseInt(num.trim()));
          return poNumbers.every(
            (poNumber) => /^\d+$/.test(poNumber.toString()) && poNumber <= 9999,
          );
        },
        {
          message:
            "Each Purchase Order Number must be no more than 4 numeric digits.",
        },
      ),
  })
  // VALIDATE PROPERTY EXISTS
  .refine(
    ({ allocationsByProperty }) => {
      return allocationsByProperty.some(({ propertyId }) =>
        P.isNotNullable(propertyId),
      );
    },
    {
      message: "Property is required.",
      path: ["allocationsByProperty.0.propertyId"],
    },
  )
  // VALIDATE BANK ACCOUNT IS SET IF INSTANT APPROVE
  .refine(
    ({ bankAccountId, instantApprove }) =>
      !instantApprove || P.isNotNullable(bankAccountId),
    {
      message: "Bank Account is required.",
      path: ["bankAccountId"],
    },
  )
  .refine(
    ({ paymentDate, instantApprove }) => {
      // Skip validation of paymentDate if instantApprove is false
      if (!instantApprove) {
        return true;
      }
      return P.isNotNullable(paymentDate) && isDateWithinTenYears(paymentDate);
    },
    {
      message: DATE_RANGE_ERROR_MESSAGE,
      path: ["paymentDate"],
    },
  );

type CreateInvoiceFormInput = z.input<typeof CreateInvoiceFormSchema>;
type CreateInvoiceFormOutput = z.output<typeof CreateInvoiceFormSchema>;

type EmptyPropertyWithAllocations = {
  allocations: {
    amount: O.Option<Money$.Money>;
    categoryId: EnderId;
    payableCategoryId: EnderId | Undefined;
    propertyId: EnderId;
  }[];
  propertyId: EnderId;
};

const emptyPropertyWithAllocations: EmptyPropertyWithAllocations = {
  allocations: [
    {
      amount: O.none(),
      // @ts-expect-error - Type 'undefined' is not assignable to type 'string & BRAND<"EnderId">'.
      categoryId: UNDEFINED,
      payableCategoryId: UNDEFINED,
      // @ts-expect-error - Type 'null' is not assignable to type 'string & BRAND<"EnderId">'.
      propertyId: NULL,
    },
  ],
  // @ts-expect-error - Type 'null' is not assignable to type 'string & BRAND<"EnderId">'.
  propertyId: NULL,
};

type GetEmptyAllocationsByPropertyParams = {
  bankTransaction?: BankTransaction;
  property?: {
    id: EnderId;
    name: string;
  };
};

function getEmptyAllocationsByProperty({
  bankTransaction,
  property,
}: GetEmptyAllocationsByPropertyParams = {}) {
  const amount = O.map(Money$.parse(bankTransaction?.amount), (v) => v.abs());
  const propertyWithAllocations = emptyPropertyWithAllocations;
  propertyWithAllocations.allocations[0].amount = amount;
  // @ts-expect-error - Type 'undefined' is not assignable to type 'string & BRAND<"EnderId">'.
  propertyWithAllocations.propertyId = P.isNotNullable(property)
    ? property?.id
    : UNDEFINED;
  return [propertyWithAllocations];
}

function determinePayee(vendor?: Vendor): Payee | Undefined {
  return P.isNotNullable(vendor)
    ? { id: vendor.id, name: vendor.name, party: PartyEnum.VENDOR }
    : UNDEFINED;
}

function determineJob(task?: Task, service?: CreateInvoiceRequestJob) {
  if (task) {
    return {
      id: task.id,
      name: ModelTypeEnum.TASK,
      status: task.status,
      type: ModelTypeEnum.TASK,
    };
  }

  if (service) {
    return {
      id: service.id,
      name: ModelTypeEnum.SERVICE,
      // @ts-expect-error
      status: service.status,
      type: ModelTypeEnum.SERVICE,
    };
  }

  return UNDEFINED;
}

function determineLedgerDate(
  bankTransaction?: BankTransaction,
): Date | Undefined {
  return P.isNotNullable(bankTransaction)
    ? LocalDate$.of(bankTransaction.date).toDate()
    : UNDEFINED;
}

type UseCreateInvoiceFormParams = {
  allocationCategoriesRequired: boolean;
  bankTransaction?: BankTransaction;
  invoiceType: InvoiceInvoiceType;
  property?: {
    id: EnderId;
    name: string;
  };
  service?: CreateInvoiceRequestJob;
  task?: Task;
  vendor?: Vendor;
};

function useCreateInvoiceForm({
  allocationCategoriesRequired = true,
  bankTransaction,
  invoiceType,
  property,
  service,
  task,
  vendor,
}: UseCreateInvoiceFormParams) {
  const initialValues: CreateInvoiceFormInput = {
    _mustConfirm: false,
    _shouldClose: false,
    allocationsByProperty: getEmptyAllocationsByProperty({
      bankTransaction,
      property,
    }),
    bankAccountId: P.isNotNullable(bankTransaction)
      ? O.some(bankTransaction.bankAccountId)
      : O.none(),
    bankTransaction,
    description: "",
    instantApprove: false,
    invoiceNumber: "",
    invoiceType,
    job: determineJob(task, service),
    ledgerDate: P.isNotNullable(bankTransaction)
      ? LocalDate$.parse(determineLedgerDate(bankTransaction))
      : LocalDate$.parse(LocalDate$.today()),
    payableType: NULL,
    payee: determinePayee(vendor),
    paymentDate: determineLedgerDate(bankTransaction) ?? new Date(),
    periodId: O.none(),
    poNumbers: UNDEFINED,
  };

  const form = useForm({
    initialValues,
    validate: (values) => {
      const zodErrors = zodResolver(CreateInvoiceFormSchema)(values);

      const additionalErrors: Record<string, string> = {};

      if (allocationCategoriesRequired) {
        values.allocationsByProperty.forEach((allocationByProperty, index) => {
          const { propertyId } = allocationByProperty;
          if (P.isNotNullable(propertyId)) {
            allocationByProperty.allocations.forEach(
              (allocation, allocIndex) => {
                if (
                  P.isNotNullable(allocation.amount) &&
                  P.isNullable(allocation.categoryId)
                ) {
                  const path = `allocationsByProperty.${index}.allocations.${allocIndex}.categoryId`;
                  additionalErrors[path] = "Category is required";
                }
              },
            );
          }
        });
      }

      const mergedErrors = { ...zodErrors, ...additionalErrors };
      return Object.keys(mergedErrors).length > 0 ? mergedErrors : {};
    },
  });

  function onAddProperty() {
    form.insertListItem("allocationsByProperty", emptyPropertyWithAllocations);
  }

  function onRemoveProperty(propertyIndex: number) {
    form.removeListItem("allocationsByProperty", propertyIndex);
  }

  function onRemovePropertyAllocation(
    propertyIndex: number,
    allocationIndex: number,
  ) {
    form.removeListItem(
      `allocationsByProperty.${propertyIndex}.allocations`,
      allocationIndex,
    );
  }

  return { form, onAddProperty, onRemoveProperty, onRemovePropertyAllocation };
}

export { useCreateInvoiceForm };
export type {
  AllocationsByProperty,
  CreateInvoiceFormInput,
  CreateInvoiceFormOutput,
};
