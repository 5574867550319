"use client";

import NextAdapterApp from "next-query-params/app";
import queryString from "query-string";
import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import { QueryParamProvider as LibQueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

// this should be used in the future when we are fully on nextjs
// https://nuqs.47ng.com/

const { parse, stringify } = queryString;

const options = {
  objectToSearchString: stringify,
  searchStringToObject: parse,
} as const;

type AdapterTypes = "react-router" | "next-js";

type QueryParamProviderProps = {
  adapter: AdapterTypes;
};

function QueryParamProvider(props: PropsWithChildren<QueryParamProviderProps>) {
  const { adapter, children } = props;

  const Adapter = useMemo(() => {
    return adapter === "react-router" ? ReactRouter5Adapter : NextAdapterApp;
  }, [adapter]);

  return (
    <LibQueryParamProvider adapter={Adapter} options={options}>
      {children}
    </LibQueryParamProvider>
  );
}

export { QueryParamProvider };
