import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SQLParamCardinalityValues = ["SINGLE", "MULTIPLE"] as const;

const SQLParamCardinalityEffectSchema = Schema.Literal(
  ...SQLParamCardinalityValues,
);

type SQLParamCardinality = Schema.Schema.Type<
  typeof SQLParamCardinalityEffectSchema
>;

const SQLParamCardinalityEnum = castEnum(SQLParamCardinalityEffectSchema);

function randomSQLParamCardinality(): SQLParamCardinality {
  return rand(SQLParamCardinalityValues);
}

export {
  randomSQLParamCardinality,
  SQLParamCardinalityEffectSchema,
  SQLParamCardinalityEnum,
  SQLParamCardinalityValues,
};
export type { SQLParamCardinality };
