import type { ReactNode } from "react";
import { z } from "zod";

import type { Null } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { BadgeColors } from "@ender/shared/ds/badge";
import { BadgeColor } from "@ender/shared/ds/badge";
import type { BankRecRowType } from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";
import { castEnum } from "@ender/shared/utils/zod";

import type { ReconciliationStatus } from "../../banking.types";
import { ReconciliationStatusEnum } from "../../banking.types";
import {
  InvoiceIconGray,
  InvoiceIconGreen,
  InvoiceIconRed,
  InvoiceIconYellow,
} from "./invoice-icon";

const BankingDetailListItemStatusValues = [
  "matched",
  "excluded",
  "not-matched",
  "awaiting-invoice",
  "pending",
  "ender-excluded",
  "bankExcluded",
] as const;

const BankingDetailListItemStatusSchema = z.enum(
  BankingDetailListItemStatusValues,
);
type BankingDetailListItemStatus = z.infer<
  typeof BankingDetailListItemStatusSchema
>;
const BankingDetailListItemStatusEnum = castEnum<BankingDetailListItemStatus>(
  BankingDetailListItemStatusSchema,
);

/**
 * +-------------------+-------------------+-------------------+
 * |                   |    Standard       |       Hover       |
 * +-------------------+-------------------+-------------------+
 * | Reconciled        | Matched           | Unmatch           |
 * |                   | Manual            | Unreconcile       |
 * +-------------------+-------------------+-------------------+
 * | Ender Transaction | Ender Txn         | Exclude           |
 * |                   | Pending Deposit   | Pending Deposit   |
 * +-------------------+-------------------+-------------------+
 * | Bank Transaction  | Bank Txn          | Reconcile         |
 * |                   | Pending Invoice   | Pending Invoice   |
 * +-------------------+-------------------+-------------------+
 * | Excluded          | Bank Excluded     | Re-include        |
 * |                   | Ender Excluded    | Re-include        |
 * +-------------------+-------------------+-------------------+
 */

type BankingDetailViewData = {
  badgeColor: BadgeColors;
  invoiceIcon?: ReactNode;
  reconciliationStatus: ReconciliationStatus;
  /**
   * 2024-02-15 Geoff:
   * Based on implementation and existing code, it seems that `status` is a superfluous interim that should be refactored out.
   * It can be derived from rowType and is ultimately used only for the banking-detail-list-item-status color.
   */
  status?: string;
  tooltipContent?: ReactNode | string | Null;
};

type GetBankingDetailViewDataParams = {
  hasLinkedInvoice: boolean;
  isEnderTxnBatch?: boolean;
  isForcedMatched?: boolean;
  isIgnored?: boolean;
  inReceipts?: boolean;
  matchedMessage: ReactNode;
  rowType: BankRecRowType;
};

function getBankingDetailViewData({
  hasLinkedInvoice,
  isForcedMatched,
  isIgnored,
  inReceipts,
  matchedMessage,
  rowType,
}: GetBankingDetailViewDataParams): BankingDetailViewData {
  if (rowType === BankRecRowTypeEnum.MATCHED) {
    return {
      badgeColor: BadgeColor.green,
      invoiceIcon: <InvoiceIconGreen />,
      reconciliationStatus: isForcedMatched
        ? ReconciliationStatusEnum.Manual
        : ReconciliationStatusEnum.Matched,
      status: !isIgnored
        ? BankingDetailListItemStatusEnum.matched
        : BankingDetailListItemStatusEnum.bankExcluded,
      tooltipContent: matchedMessage,
    };
  }
  if (rowType === BankRecRowTypeEnum.UNMATCHED_ENDER) {
    return {
      badgeColor: BadgeColor.yellow,
      invoiceIcon: <InvoiceIconYellow />,
      reconciliationStatus: inReceipts
        ? ReconciliationStatusEnum["Pending Deposit"]
        : ReconciliationStatusEnum["Ender Txn"],
      status: BankingDetailListItemStatusEnum.pending,
      tooltipContent:
        "This transfer was entered on Ender. A matching bank transaction has not yet been found on the bank statement.",
    };
  }
  if (rowType === BankRecRowTypeEnum.UNMATCHED_BANK) {
    return {
      badgeColor: BadgeColor.red,
      invoiceIcon: <InvoiceIconYellow />,
      /**
       * 2024-02-15 Geoff:
       * It is counter-intuitive to me that `hasLinkedInvoice` results in "Pending Invoice" status while `!hasLinkedInvoice` results in "Bank Txn" status.
       * Investigating: https://enderhub.slack.com/archives/C03ECNPA268/p1708012038460219
       */
      reconciliationStatus: hasLinkedInvoice
        ? ReconciliationStatusEnum["Pending Invoice"]
        : ReconciliationStatusEnum["Bank Txn"],
      status: BankingDetailListItemStatusEnum["not-matched"],
      tooltipContent: hasLinkedInvoice
        ? "This bank transaction will be reconciled when the invoice is approved and marked paid."
        : UNDEFINED,
    };
  }
  if (rowType === BankRecRowTypeEnum.BANK_EXCLUDED) {
    return {
      badgeColor: BadgeColor.slate,
      invoiceIcon: <InvoiceIconGreen />,
      reconciliationStatus: ReconciliationStatusEnum["Bank Excluded"],
      status: BankingDetailListItemStatusEnum.excluded,
      tooltipContent: `${matchedMessage}  Ender does not yet allow users to re-include transactions. Please reach out to Ender support if you want to re-include this row.`,
    };
  }
  if (rowType === BankRecRowTypeEnum.ENDER_EXCLUDED) {
    return {
      badgeColor: BadgeColor.slate,
      invoiceIcon: <InvoiceIconGray />,
      reconciliationStatus: ReconciliationStatusEnum["Ender Excluded"],
      status: BankingDetailListItemStatusEnum["ender-excluded"],
    };
  }
  if (rowType === BankRecRowTypeEnum.MATCHED_LATER_ENDER) {
    return {
      badgeColor: BadgeColor.yellow,
      invoiceIcon: <InvoiceIconYellow />,
      reconciliationStatus: ReconciliationStatusEnum["Matched Later - Ender"],
      status: BankingDetailListItemStatusEnum.matched,
      tooltipContent: matchedMessage,
    };
  }
  if (rowType === BankRecRowTypeEnum.MATCHED_LATER_BANK) {
    return {
      badgeColor: BadgeColor.red,
      invoiceIcon: <InvoiceIconRed />,
      reconciliationStatus: ReconciliationStatusEnum["Matched Later - Bank"],
      status: BankingDetailListItemStatusEnum.matched,
      tooltipContent: matchedMessage,
    };
  }

  return {} as BankingDetailViewData;
}

export { BankingDetailListItemStatusEnum, getBankingDetailViewData };
export type { BankingDetailListItemStatus, ReconciliationStatus };
