import { useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Option as O,
  Predicate as P,
  Record as R,
  pipe,
} from "effect";
import { forwardRef, useCallback, useContext, useMemo } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { LocalDate$, isEnderId } from "@ender/shared/core";
import type { InputBaseProps } from "@ender/shared/ds/input";
import type { SelectOption } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type {
  AccountingPeriod,
  AccountingPeriodAccountingModule,
  AccountingPeriodPeriodStatus,
} from "@ender/shared/generated/ender.model.accounting";
import { AccountingPeriodPeriodStatusEnum } from "@ender/shared/generated/ender.model.accounting";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { toLongMonthYearString } from "@ender/shared/utils/local-date";

/**
 * at minimum, we can refer to an accounting period by its ID or by its start date
 * and get all the rest of the information we want from it.
 */
type MinimalAccountingPeriod = Readonly<
  Partial<Pick<AccountingPeriod, "id" | "startDate">>
>;

type AccountingPeriodSelectorProps = {
  value: O.Option<MinimalAccountingPeriod>;
  onChange: (accountingPeriod: O.Option<AccountingPeriod>) => void;
  periodType: AccountingPeriodAccountingModule;
} & InputBaseProps;

/** @description Sorts SelectOptions based on their AccountingPeriod's startDate from oldest to newest */
function sortAccountingPeriodSelectOptions(
  optionA: SelectOption<EnderId, AccountingPeriod>,
  optionB: SelectOption<EnderId, AccountingPeriod>,
): 0 | 1 | -1 {
  if (
    P.isNullable(optionA.meta) ||
    P.isNullable(optionB.meta) ||
    optionA.meta.startDate === optionB.meta.startDate
  ) {
    return 0;
  }
  return optionA.meta.startDate < optionB.meta.startDate ? -1 : 1;
}

function enderIdPredicate(value: string | Undefined): value is EnderId {
  return P.isNotNullable(value) && isEnderId(value);
}

const AccountingPeriodSelector = forwardRef<
  HTMLInputElement,
  AccountingPeriodSelectorProps
>(function AccountingPeriodSelector(props, ref) {
  const {
    value,
    onChange,
    periodType,
    description = "Ender will assign an accounting period based on the transaction date if you do not specify one.",
    disabled,
  } = props;

  /**
   * TODO ideally, these permissions and flags should affect the `periods` we receive from the API call instead of
   * us having to manually filter them here.
   */
  const { hasPermissions } = useContext(UserContext);
  const canAccessPartiallyOpenPeriods = hasPermissions(
    FunctionalPermissionEnum.EDIT_PARTIALLY_OPEN_BOOKS,
  );

  const statusesToKeep = new Set<AccountingPeriodPeriodStatus>([
    AccountingPeriodPeriodStatusEnum.OPEN,
  ]);
  if (canAccessPartiallyOpenPeriods) {
    statusesToKeep.add(AccountingPeriodPeriodStatusEnum.PARTIALLY_OPEN);
  }

  const { data: selectOptions = [] } = useQuery({
    queryKey: ["AccountingAPI.getAccountingPeriods", periodType] as const,
    queryFn: ({ signal, queryKey: [, module] }) =>
      AccountingAPI.getAccountingPeriods({ module }, { signal }),
    select: (data) =>
      pipe(
        data,
        A.filter((v) => statusesToKeep.has(v.status)),
        A.map((v) => ({
          label: toLongMonthYearString(LocalDate$.of(v.endDate)),
          meta: v,
          value: v.id,
        })),
        A.sort(sortAccountingPeriodSelectOptions),
      ),
  });

  const biDirectionalMap = useMemo(() => {
    return selectOptions.reduce<Record<LocalDate | EnderId, AccountingPeriod>>(
      (acc, option) => {
        const monthAlignedPeriod = LocalDate$.startOfMonth(
          LocalDate$.of(option.meta.startDate),
        ).toJSON();
        acc[monthAlignedPeriod] = option.meta;
        acc[option.meta.id] = option.meta;
        return acc;
      },
      {} as Record<LocalDate | EnderId, AccountingPeriod>,
    );
  }, [selectOptions]);

  const handleChange = useCallback(
    (
      _selectedValue: O.Option<string>,
      selectedItem: O.Option<SelectOption<string, AccountingPeriod>>,
    ) => {
      pipe(
        selectedItem,
        O.flatMap((item) => O.fromNullable(item.meta)),
        onChange,
      );
    },
    [onChange],
  );

  /**
   * in order to work with the underlying select, we must have an EnderId.
   * the below memo extracts a valid ID from a provided `value`, which can either
   * contain an EnderId or a `startDate` or both.
   */
  const selectValue = useMemo(() => {
    return pipe(
      value,
      O.flatMap((value) => {
        const idOption = O.liftPredicate(value.id, enderIdPredicate);
        const dateOption = pipe(
          O.fromNullable(value.startDate),
          O.flatMap((val) => R.get(biDirectionalMap, val)),
          O.map((val) => val.id),
        );

        return pipe(
          idOption,
          O.orElse(() => dateOption),
        );
      }),
    );
  }, [value, biDirectionalMap]);

  return (
    <Select<EnderId, AccountingPeriod>
      placeholder="Select period"
      {...props}
      disabled={disabled}
      clearable
      data={selectOptions}
      onChange={handleChange}
      value={selectValue}
      description={description}
      ref={ref}
    />
  );
});

export { AccountingPeriodSelector };
export type { AccountingPeriodSelectorProps };
