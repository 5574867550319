/**
 * HTTP Method: POST
 * Pathname: /collections/search
 * @function getLeasesInCollections
 * @memberof CollectionsAPI
 * @param {CollectionsAPIGetLeasesInCollectionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsReportCollectionsReportRow[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { GetCollectionsReportRequestCollectionsSortBy } from "@ender/shared/generated/ender.api.finance.request";
import type { LeaseIntention } from "@ender/shared/generated/ender.model.leasing";
import type { CollectionsStepCollectionsActionType } from "@ender/shared/generated/ender.model.misc";
import type { CollectionsReportCollectionsReportRow } from "@ender/shared/generated/ender.service.payments.collections";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIGetLeasesInCollectionsSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIGetLeasesInCollectionsBodyParams = {
  collectionActionTypes: CollectionsStepCollectionsActionType[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  hasOnlyUnpaidAssistedRentCharges?: boolean | undefined;
  hasPaymentPlan?: boolean | undefined;
  intentions: LeaseIntention[];
  limit?: number | undefined;
  marketIds: EnderId[];
  minimumCollectableAmount?: SerializesInto<Money> | undefined;
  movedOut?: boolean | undefined;
  offsetId?: EnderId | undefined;
  onlyActionNeeded?: boolean | undefined;
  onlyNondelinquentLeases?: boolean | undefined;
  onlyPaused?: boolean | undefined;
  propertyIds: EnderId[];
  showMoveoutAccountingComplete?: boolean | undefined;
  showPendingMoveoutAccounting?: boolean | undefined;
  sortBy?: GetCollectionsReportRequestCollectionsSortBy | undefined;
  tenantName?: string | undefined;
  unitId?: EnderId | undefined;
  upcomingLeases?: boolean | undefined;
};

type CollectionsAPIGetLeasesInCollectionsPayload =
  CollectionsAPIGetLeasesInCollectionsSearchParams &
    CollectionsAPIGetLeasesInCollectionsBodyParams;

function getLeasesInCollectionsUnsafeEffect(
  payload: CollectionsAPIGetLeasesInCollectionsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsReportCollectionsReportRow[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          CollectionsReportCollectionsReportRow[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/collections/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeasesInCollections(
  payload: CollectionsAPIGetLeasesInCollectionsPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsReportCollectionsReportRow[]> {
  return F.pipe(
    payload,
    getLeasesInCollectionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeasesInCollections };
export type {
  CollectionsAPIGetLeasesInCollectionsBodyParams,
  CollectionsAPIGetLeasesInCollectionsPayload,
  CollectionsAPIGetLeasesInCollectionsSearchParams,
};
