import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import { forwardRef, useEffect } from "react";

import { Form, useForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  LocalTimeEffectSchema,
} from "@ender/form-system/schema";
import { LocalDate$, LocalTime$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormTimeInput } from "@ender/shared/ds/time-input";
import type { SearchShowingsTableResponseSearchShowingsTableResponseItem } from "@ender/shared/generated/com.ender.middle.response";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { ShowingReservationStatusEnum } from "@ender/shared/generated/ender.model.leasing";

type EditShowingFormProps = {
  showing: SearchShowingsTableResponseSearchShowingsTableResponseItem;
  onSuccess: () => void;
  onClose: () => void;
};

const EditShowingFormSchema = Schema.Struct({
  date: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  time: LocalTimeEffectSchema.pipe(Schema.OptionFromSelf),
});

type EditShowingFormValues = Schema.Schema.Type<typeof EditShowingFormSchema>;

export const EditShowingForm = forwardRef<HTMLDivElement, EditShowingFormProps>(
  function EditShowingForm({ showing, onSuccess, onClose }, ref) {
    const initialDate = O.some(LocalDate$.of(new Date(showing.showingTime)));
    const initialTime = O.some(
      LocalTime$.of(new Date(showing.showingTime).toTimeString().slice(0, 8)),
    );
    const form = useForm<EditShowingFormValues>({
      defaultValues: {
        date: initialDate,
        time: initialTime,
      },
      resolver: effectTsResolver(EditShowingFormSchema),
      mode: "onSubmit",
    });

    const { reset } = form;

    //reset form data when new showing is passed
    useEffect(() => {
      reset({
        date: O.some(LocalDate$.of(new Date(showing.showingTime))),
        time: O.some(
          LocalTime$.of(
            new Date(showing.showingTime).toTimeString().slice(0, 8),
          ),
        ),
      });
    }, [showing, reset]);

    const { mutateAsync: updateShowing, isLoading: isSubmitLoading } =
      useMutation({
        mutationKey: ["LeasingAPI.updateShowing"],
        mutationFn: LeasingAPI.updateShowing,
      });

    async function handleSubmit(values: EditShowingFormValues) {
      const convertedDate = O.getOrUndefined(values.date);
      const convertedTime = O.getOrUndefined(values.time);
      await updateShowing({
        showingId: showing.id,
        showingDate: convertedDate?.toJSON(),
        showingTime: convertedTime?.toJSON(),
        status: ShowingReservationStatusEnum.RESCHEDULED,
      });
      onSuccess();
      onClose();
    }

    return (
      <div ref={ref}>
        <Form form={form} onSubmit={handleSubmit}>
          <Stack>
            <Select
              label="Prospect or Applicant"
              value={O.some(showing.prospectId)}
              onChange={F.constVoid}
              disabled
              data={[
                { value: showing.prospectId, label: showing.prospectName },
              ]}
            />
            <Select
              label="Property"
              value={O.some(showing.propertyId)}
              onChange={F.constVoid}
              disabled
              data={[
                { value: showing.propertyId, label: showing.propertyName },
              ]}
            />
            <Select
              label="Unit"
              value={O.some(showing.unitId)}
              onChange={F.constVoid}
              disabled
              data={[{ value: showing.unitId, label: showing.unitName }]}
            />
            <FormDateInput label="Showing Date" form={form} name="date" />
            <FormTimeInput label="Showing Time" form={form} name="time" />
            <Group justify={Justify.end} align={Align.center}>
              <Button variant={ButtonVariant.outlined} onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" loading={isSubmitLoading}>
                Update Showing
              </Button>
            </Group>
          </Stack>
        </Form>
      </div>
    );
  },
);
