import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ReportWidgetWidgetTypeValues = [
  "TABLE",
  "BAR_CHART",
  "PIE_CHART",
  "LINE_CHART",
  "TEXT",
] as const;

const ReportWidgetWidgetTypeEffectSchema = Schema.Literal(
  ...ReportWidgetWidgetTypeValues,
);

type ReportWidgetWidgetType = Schema.Schema.Type<
  typeof ReportWidgetWidgetTypeEffectSchema
>;

const ReportWidgetWidgetTypeEnum = castEnum(ReportWidgetWidgetTypeEffectSchema);

function randomReportWidgetWidgetType(): ReportWidgetWidgetType {
  return rand(ReportWidgetWidgetTypeValues);
}

export {
  randomReportWidgetWidgetType,
  ReportWidgetWidgetTypeEffectSchema,
  ReportWidgetWidgetTypeEnum,
  ReportWidgetWidgetTypeValues,
};
export type { ReportWidgetWidgetType };
