// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { UseFormReturnType } from "@mantine/form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import * as S from "effect/String";
import { useCallback, useContext } from "react";

import type { Null } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import type { InvoicesAPICreateInvoicePayload } from "@ender/shared/generated/ender.api.accounting";
import {
  GeneralLedgerAPI,
  InvoicesAPI,
} from "@ender/shared/generated/ender.api.accounting";
import { TasksAPI } from "@ender/shared/generated/ender.api.misc";
import type { GLAllocation } from "@ender/shared/generated/ender.arch.accounting";
import type { GLCategory as GeneratedGlCategory } from "@ender/shared/generated/ender.model.accounting";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  InvoiceInvoiceTypeEnum,
  InvoicePayableTypeEnum,
} from "@ender/shared/generated/ender.model.payments.invoice";
import type { Task } from "@ender/shared/generated/ender.model.task";
import { fail } from "@ender/shared/utils/error";
import type { WithWarningHandlerParamsOnWarnings } from "@ender/shared/utils/rest";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

import type {
  CreateInvoiceFormInput,
  CreateInvoiceFormOutput,
} from "./use-create-invoice-form";

// TODO 2024-11-14 Geoffrey: this is duplicated 3x around the code base; DRY it out
type GLCategory = GeneratedGlCategory & { isParent: boolean };

// TODO 2024-11-14 Geoffrey: this is duplicated 3x around the code base; DRY it out
function useGLTxCategories() {
  return useQuery<GLCategory[]>({
    queryKey: ["GeneralLedgerAPI.getTxCategories"] as const,
    queryFn: ({ signal }) => {
      return GeneralLedgerAPI.getTxCategories(
        {
          includeBankAccountCategories: true,
          includeRoots: false,
          keyword: "",
        },
        { signal },
      );
    },
    staleTime: 300000,
  });
}

type CreateInvoiceFromFormValuesParams = {
  isVendor: boolean;
  onDone?: () => void;
  onSuccess: () => void;
  onWarnings: WithWarningHandlerParamsOnWarnings;
  selectedPages?: { id: EnderId }[] | null;
  task?: Task;
  values: CreateInvoiceFormOutput;
};

const createInvoiceFromFormValues = async ({
  isVendor = true,
  onDone = F.constVoid,
  onSuccess,
  onWarnings,
  selectedPages,
  task,
  values,
}: CreateInvoiceFromFormValuesParams) => {
  const transformedPONumbers =
    P.isNotNullable(values.poNumbers) && S.isNonEmpty(values.poNumbers)
      ? values.poNumbers.split(",").map((poNumber) => parseInt(poNumber))
      : UNDEFINED;
  const request: InvoicesAPICreateInvoicePayload = {
    allocations: [],
    bankAccountId: P.isNotNullable(values.bankTransaction)
      ? UNDEFINED
      : pipe(values.bankAccountId, O.getOrUndefined),
    bankTransaction: values.bankTransaction ?? UNDEFINED,
    description: values.description,
    instantApprove: values.instantApprove,
    invoiceNumber: values.invoiceNumber,
    invoiceType: values.invoiceType,
    job: values.job ?? UNDEFINED,
    ledgerDate: pipe(
      values.ledgerDate,
      O.map((val) => val.toJSON()),
      O.getOrThrow,
    ),
    pageIds: selectedPages?.map((selectedPage) => selectedPage.id) ?? [],
    //@ts-expect-error values.payee can have ID of EnderId or a const "EXTERNAL"
    payee: values.payee,
    paymentDate: LocalDate$.of(values.paymentDate).toJSON(),
    periodId: pipe(
      values.periodId,
      O.map((period) => period.id), // Extract just the ID from the period
      O.getOrUndefined,
    ),
    poNumbers: transformedPONumbers ?? [],
  };
  if (values.invoiceType === InvoiceInvoiceTypeEnum.PAYABLE) {
    request.payableType = values.payableType || InvoicePayableTypeEnum.GENERAL;
  }

  if (!isVendor) {
    request.allocations = values.allocationsByProperty.reduce<GLAllocation[]>(
      (acc, property) => {
        const newAllocations = property.allocations.reduce<GLAllocation[]>(
          (acc2, allocation) => {
            if (
              P.isNotNullable(allocation.categoryId) &&
              S.isNonEmpty(allocation.categoryId) &&
              pipe(
                allocation.amount,
                O.map((val) => val.valueInCents !== 0),
                O.getOrElse(() => false),
              )
            ) {
              const _glAllocation: GLAllocation = {
                amount: pipe(
                  allocation.amount,
                  O.map((val) => val.toJSON()),
                  O.getOrThrow,
                ),
                categoryId: allocation.categoryId,
                payableCategoryId: allocation.payableCategoryId ?? UNDEFINED,
                propertyId: property.propertyId,
              };
              acc2.push(_glAllocation);
            }
            return acc2;
          },
          [],
        );
        return acc.concat(newAllocations);
      },
      [],
    );
  }

  if (isVendor) {
    request.amount = pipe(
      values.allocationsByProperty[0].allocations[0].amount,
      O.map((val) => val.toJSON()),
      O.getOrThrow,
    );
    request.propertyId = values.allocationsByProperty[0].propertyId;
  }

  try {
    const createInvoiceWithWarnings = withWarningHandler(
      InvoicesAPI.createInvoice,
      onWarnings,
    );

    await createInvoiceWithWarnings(request);

    if (P.isNotNullable(task) && values._shouldClose) {
      await TasksAPI.closeTask({ taskId: task.id });
    }

    onSuccess();
  } catch (error) {
    fail(error);
  } finally {
    onDone();
  }
};

type UseCreateInvoiceSubmitParams = {
  form: UseFormReturnType<CreateInvoiceFormInput>;
  invoiceType: InvoiceInvoiceType;
  onClose: () => void;
  onInvoiceSuccessfullyCreated: (
    invoiceType: InvoiceInvoiceType,
    ops: { shouldCloseForm?: boolean; shouldCloseTask?: boolean },
  ) => Promise<void> | void;
  selectedPages?: { id: EnderId }[] | Null;
  setNewInvoiceNumber: () => void;
  shouldCloseFormOnSuccess: boolean;
  shouldCloseTask: boolean;
  task?: Task;
};

function useCreateInvoiceSubmit({
  form,
  invoiceType,
  onClose,
  onInvoiceSuccessfullyCreated,
  selectedPages,
  setNewInvoiceNumber,
  shouldCloseFormOnSuccess,
  shouldCloseTask,
  task,
}: UseCreateInvoiceSubmitParams) {
  const { user, userPM } = useContext(UserContext);
  const { data: categories = [], isLoading: isLoadingCategories } =
    useGLTxCategories();
  const {
    mutateAsync: createInvoiceFromFormValuesMutation,
    isLoading: isProcessing,
  } = useMutation({
    mutationFn: createInvoiceFromFormValues,
    mutationKey: ["InvoicesAPI.createInvoiceFromFormValues"] as const,
  });
  const confirmation = useConfirmationContext();

  const handleSubmit = useCallback(
    async (values: CreateInvoiceFormOutput) => {
      const isParentCategorySelected = values.allocationsByProperty?.some(
        (property) => {
          return property.allocations.some((allocation) =>
            categories.find(
              (c) => c.id === allocation.categoryId && c.isParent,
            ),
          );
        },
      );

      const confirmParentAllocations = Boolean(userPM.confirmParentAllocations);

      try {
        if (isParentCategorySelected && confirmParentAllocations) {
          await confirmation({
            confirmButtonLabel: "Yes, use category",
            title:
              "You have selected to allocate to a parent category. Are you sure you would like to proceed?",
          });
        }

        // TODO 2024-11-14 Geoffrey: them guts is duplicated many times around the code base; DRY it out
        const onWarnings = async (warnings: string[]) => {
          await confirmation(
            {
              content: warnings.join("\n"),
              title: "Warning!",
            },
            { confirmButtonProps: { color: Color.red } },
          );
        };

        const finalTransformedValues = {
          isVendor: user.isVendor,
          onSuccess: () => {
            onInvoiceSuccessfullyCreated(invoiceType, {
              shouldCloseForm: shouldCloseFormOnSuccess,
              shouldCloseTask: shouldCloseTask,
            });
            if (shouldCloseFormOnSuccess) {
              onClose();
            } else {
              form.reset();
              setNewInvoiceNumber();
            }
          },
          onWarnings,
          selectedPages,
          task,
          values,
        };

        await createInvoiceFromFormValuesMutation(finalTransformedValues);
      } catch (error) {
        fail(error);
      }
    },
    [
      categories,
      confirmation,
      createInvoiceFromFormValuesMutation,
      form,
      invoiceType,
      onClose,
      onInvoiceSuccessfullyCreated,
      selectedPages,
      setNewInvoiceNumber,
      shouldCloseFormOnSuccess,
      shouldCloseTask,
      task,
      user.isVendor,
      userPM.confirmParentAllocations,
    ],
  );

  return { handleSubmit, isProcessing: isProcessing || isLoadingCategories };
}

export { useCreateInvoiceSubmit };
