import { IconSearch } from "@tabler/icons-react";
import { Predicate as P } from "effect";

import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { TextInput } from "@ender/shared/ds/text-input";
import { TaskTaskTypeEnum } from "@ender/shared/generated/ender.model.task";
import type { TaskStatusGrouping } from "@ender/shared/types/ender-general";
import { TaskStatusGroupingEnum } from "@ender/shared/types/ender-general";
import { EnderRadio, EnderRadioGroup } from "@ender/shared/ui/ender-radio";
import { Select } from "@ender/shared/ui/select";

type TaskTypeFilterKey = "ALL" | "INTERNAL" | "WORK_ORDER";
type TaskTypeFilter = Readonly<{ value: TaskTypeFilterKey; label: string }>;

const taskTypeData: readonly TaskTypeFilter[] = [
  { value: "ALL", label: "All" },
  { value: TaskTaskTypeEnum.INTERNAL, label: "Internal Tasks" },
  { value: TaskTaskTypeEnum.WORK_ORDER, label: "Work Orders" },
] as const;

type WorkOrderFiltersProps = {
  type: {
    value: TaskTypeFilterKey;
    onChange: (value: TaskTypeFilterKey) => void;
  };
  status: {
    value: TaskStatusGrouping;
    onChange: (values: TaskStatusGrouping) => void;
  };
  keyword: {
    value: string;
    onChange: (value: string) => void;
  };
};

function WorkOrdersFilters(props: WorkOrderFiltersProps) {
  const { status, type, keyword } = props;

  const searchIcon = <IconSearch />;

  return (
    <Group spacing={Spacing.lg} align={Align.end}>
      <Select
        name="taskType"
        label="Type"
        value={type.value}
        data={taskTypeData}
        clearable={false}
        onChange={(value: TaskTypeFilterKey | null) =>
          P.isNotNullable(value) && type.onChange(value)
        }
      />
      <TextInput
        label="Assigned to"
        placeholder="Assignee name"
        value={keyword.value}
        leftSection={searchIcon}
        onChange={keyword.onChange}
      />
      <EnderRadioGroup
        label="Status"
        offset={8}
        value={status.value}
        onChange={status.onChange}>
        <EnderRadio label="Open" value={TaskStatusGroupingEnum.OPEN} />
        <EnderRadio label="Paying" value={TaskStatusGroupingEnum.PAYING} />
        <EnderRadio label="Closed" value={TaskStatusGroupingEnum.CLOSED} />
      </EnderRadioGroup>
    </Group>
  );
}

export { WorkOrdersFilters, taskTypeData };
export type { TaskTypeFilterKey };
