import { String as Str } from "effect";

import { formatPhoneNumber } from "@ender/shared/utils/phone";

type PhoneDisplayProps = {
  value?: string;
};

/**
 * displays a formatted phone number
 *
 * @deprecated This component should be relocated to a DS display
 */
function PhoneDisplay({ value = "" }: PhoneDisplayProps) {
  return Str.isNonEmpty(value) ? (
    <a data-private href={`tel:${value}`} onClick={(e) => e.stopPropagation()}>
      {formatPhoneNumber(value)}
    </a>
  ) : (
    "--"
  );
}

export { PhoneDisplay };
