import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionAreaAreaNotApplicableReasonValues = [
  "DOES_NOT_EXIST",
  "PROPERTY_MODIFIED",
  "ADDED_BY_MISTAKE",
  "OTHER",
] as const;

const InspectionAreaAreaNotApplicableReasonEffectSchema = Schema.Literal(
  ...InspectionAreaAreaNotApplicableReasonValues,
);

type InspectionAreaAreaNotApplicableReason = Schema.Schema.Type<
  typeof InspectionAreaAreaNotApplicableReasonEffectSchema
>;

const InspectionAreaAreaNotApplicableReasonEnum = castEnum(
  InspectionAreaAreaNotApplicableReasonEffectSchema,
);

function randomInspectionAreaAreaNotApplicableReason(): InspectionAreaAreaNotApplicableReason {
  return rand(InspectionAreaAreaNotApplicableReasonValues);
}

export {
  InspectionAreaAreaNotApplicableReasonEffectSchema,
  InspectionAreaAreaNotApplicableReasonEnum,
  InspectionAreaAreaNotApplicableReasonValues,
  randomInspectionAreaAreaNotApplicableReason,
};
export type { InspectionAreaAreaNotApplicableReason };
