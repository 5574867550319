// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { GetInputProps } from "@mantine/form/lib/types";
import { Option as O } from "effect";
import { useMemo } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";

import type { AllocationListItemInput } from "./allocation-list-item";
import { AllocationListItem } from "./allocation-list-item";

type AllocationListProps = {
  allocations: AllocationListItemInput[];
  getInputProps: (
    index: number,
    field: string,
  ) => ReturnType<GetInputProps<AllocationListItemInput>>;
  hidePayableCategorySelect?: boolean;
  onRemoveAllocation: (index: number) => void;
  propertyId: EnderId;
  updateAllocations: (allocations: AllocationListItemInput[]) => void;
};

function AllocationList({
  allocations = [],
  getInputProps,
  hidePayableCategorySelect = false,
  onRemoveAllocation,
  propertyId,
  updateAllocations,
}: AllocationListProps) {
  const clearButtonTabIndex = -1;

  function handleAllocationChange(
    allocation: AllocationListItemInput,
    index: number,
  ) {
    const updatedAllocations = [...allocations];
    updatedAllocations[index] = allocation;
    updateAllocations(updatedAllocations);
  }

  function addEmptyAllocation() {
    const emptyAllocation: AllocationListItemInput = {
      amount: O.none(),
      categoryId: UNDEFINED,
      payableCategoryId: UNDEFINED,
      propertyId,
    };
    updateAllocations([...allocations, emptyAllocation]);
  }

  const routeParamsWithExcludedIds = useMemo(() => {
    const excludeIds: EnderId[] = [];
    allocations.forEach((allocation) => {
      if (allocation.categoryId) {
        excludeIds.push(allocation.categoryId);
      }
    });

    return {
      categoryFlags: [],
      excludeIds,
      includeRoots: false,
      resultsOnEmpty: true,
    };
  }, [allocations]);

  return (
    <div role="group">
      <Stack>
        <Stack spacing={Spacing.sm}>
          {allocations.map((allocation, i) => (
            <AllocationListItem
              allocation={allocation}
              canDeleteAllocation={allocations.length > 1}
              clearButtonTabIndex={clearButtonTabIndex}
              getInputProps={(field) => getInputProps(i, field)}
              handleAllocationChange={(allocation) =>
                handleAllocationChange(allocation, i)
              }
              hidePayableCategorySelect={hidePayableCategorySelect}
              key={`${allocation.categoryId}${i}`}

              onRemoveAllocation={() => onRemoveAllocation(i)}
              routeParams={routeParamsWithExcludedIds}
            />
          ))}
          <Group>
            <Button
              onClick={addEmptyAllocation}
              variant={ButtonVariant.transparent}>
              + Add another row
            </Button>
          </Group>
        </Stack>
      </Stack>
    </div>
  );
}

export { AllocationList };
