import { Predicate as P } from "effect";
import { useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { TextInput } from "@ender/shared/ds/text-input";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { convertToNumber } from "@ender/shared/utils/string";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

import styles from "./sliding-filter/sliding-chart.module.css";

type YearBuiltInputFilterProps = FilterComponentProps & {
  setHasError: (value: boolean) => void;
};

function validYearRange(min: number | undefined, max: number | undefined) {
  if (P.isNotNullable(min) && P.isNotNullable(max)) {
    return min <= max;
  }

  return true;
}

function getYearBuiltFilters(
  min: number | undefined,
  max: number | undefined,
  factor?: WidgetFactor,
): WidgetFilter[] | undefined {
  if ((P.isNullable(min) && P.isNullable(max)) || P.isNullable(factor)) {
    return UNDEFINED;
  }

  const filters: WidgetFilter[] = [];

  if (P.isNotNullable(min)) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
      values: [min],
    });
  }

  if (P.isNotNullable(max)) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
      values: [max],
    });
  }

  return filters;
}

function getDefaultMinValue(widget: Widget): number | undefined {
  const min = widget.filters.find(
    ({ factor, operator }) =>
      factor.name === Factor.YEAR_BUILT &&
      operator === DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
  )?.values?.[0];

  if (P.isNumber(min)) {
    return min;
  } else if (P.isRecord(min)) {
    return UNDEFINED;
  }
  return P.isNotNullable(min) ? convertToNumber(min) : UNDEFINED;
}

function getDefaultMaxValue(widget: Widget): number | undefined {
  const max = widget.filters.find(
    ({ factor, operator }) =>
      factor.name === Factor.YEAR_BUILT &&
      operator === DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
  )?.values?.[0];

  if (P.isNumber(max)) {
    return max;
  } else if (P.isRecord(max)) {
    return UNDEFINED;
  }
  return P.isNotNullable(max) ? convertToNumber(max) : UNDEFINED;
}

function YearBuiltInputFilter({
  factor,
  updateFilters,
  setHasError,
  widget,
}: YearBuiltInputFilterProps) {
  const [min, setMin] = useState<number | undefined>(
    getDefaultMinValue(widget),
  );
  const [max, setMax] = useState<number | undefined>(
    getDefaultMaxValue(widget),
  );

  function handleMinChange(e: string) {
    const value = convertToNumber(e);
    const minYear = isNaN(value) ? UNDEFINED : value;

    setMin(minYear);

    const filters = getYearBuiltFilters(minYear, max, factor);
    updateFilters(Factor.YEAR_BUILT, filters);
    setHasError(!validYearRange(minYear, max));
  }

  function handleMaxChange(e: string) {
    const value = convertToNumber(e);
    const maxYear = isNaN(value) ? UNDEFINED : value;

    setMax(maxYear);

    const filters = getYearBuiltFilters(min, maxYear, factor);
    updateFilters(Factor.YEAR_BUILT, filters);
    setHasError(!validYearRange(min, maxYear));
  }

  return (
    <Group spacing={Spacing.sm} align={Align.end} justify={Justify.center}>
      <div style={{ flex: 1 }}>
        <TextInput
          label="Min Year Built"
          onChange={handleMinChange}
          value={min?.toString() ?? ""}
          error={
            validYearRange(min, max) ? UNDEFINED : "Must be less than max value"
          }
        />
      </div>
      <div className={styles.slidingChartInputDivider}>-</div>
      <div style={{ flex: 1 }}>
        <TextInput
          label="Max Year Built"
          onChange={handleMaxChange}
          value={max?.toString() ?? ""}
          error={
            validYearRange(min, max)
              ? UNDEFINED
              : "Must be greater than min value"
          }
        />
      </div>
    </Group>
  );
}

export { YearBuiltInputFilter };
