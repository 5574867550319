import { useQuery } from "@tanstack/react-query";

import type { ModelInformation } from "@ender/shared/api/files";
import type { EnderId } from "@ender/shared/core";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import type { GetFilesResponseFileResponse } from "@ender/shared/generated/ender.api.files.response";
import { InsuranceAPI } from "@ender/shared/generated/ender.api.finance";
import { EnderAPI } from "@ender/shared/generated/ender.api.misc";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { InsurancePolicyInsuredPartyEnum } from "@ender/shared/generated/ender.model.core.vendor";
import type { WebserverFilesServiceSubFolder } from "@ender/shared/generated/ender.service.files";
import { WebserverFilesServiceSubFolderEnum } from "@ender/shared/generated/ender.service.files";
import { fail } from "@ender/shared/utils/error";

type AdditionalInfo = {
  author?: User;
  modelId: EnderId;
  modelType: ModelType;
  subFolder?: WebserverFilesServiceSubFolder;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  tags: any;
};

// type AdditionalInfo = ModelInformation & { subFolder: WebserverFilesServiceSubFolder };
type FileWithAdditionalInfo = FilesClientEnderFile & AdditionalInfo;

function addAdditionalInfo(modelInfo: AdditionalInfo) {
  return function ({
    files,
  }: {
    files: FilesClientEnderFile[];
  }): GetFilesResponseFileResponse[] {
    return files.map((f) => Object.assign(f, modelInfo));
  };
}

const getFilesWithAdditionalInfo = ({
  modelId,
  modelType,
  subFolder,
}: Omit<AdditionalInfo, "tags">): Promise<GetFilesResponseFileResponse[]> => {
  const additionalInfo = Object.assign({ subFolder, modelId, modelType });
  return WebserverFilesAPI.getFiles(additionalInfo).then(
    addAdditionalInfo(additionalInfo),
  );
};

async function fetchLeaseDocumentsByLease(
  lease: LeaseSerializerLeaseResponse,
): Promise<FileWithAdditionalInfo[]> {
  try {
    const channels = [];

    for (const contact of lease.contacts || []) {
      const { channel } = await EnderAPI.getChatInfo({
        isInternal: true,
        modelParams: [
          { modelId: lease.id, modelType: ModelTypeEnum.LEASE },
          { modelId: contact.id, modelType: ModelTypeEnum.USER },
        ],
        modelType: ModelTypeEnum.MULTI_MODEL_CHAT,
      });

      channels.push(channel);
    }

    const leaseModelInfo: ModelInformation = {
      modelId: lease.id,
      modelType: ModelTypeEnum.LEASE,
    };

    const filePromises = [
      getFilesWithAdditionalInfo({
        ...leaseModelInfo,
        subFolder: WebserverFilesServiceSubFolderEnum.PUBLIC,
      }),
      getFilesWithAdditionalInfo({
        ...leaseModelInfo,
        subFolder: WebserverFilesServiceSubFolderEnum.PRIVATE,
      }),
    ];

    for (const channel of channels) {
      const channelModelInfo: ModelInformation = {
        modelId: channel.modelId,
        modelType: ModelTypeEnum.MULTI_MODEL_CHAT,
      };
      filePromises.push(
        getFilesWithAdditionalInfo({
          ...channelModelInfo,
          subFolder: WebserverFilesServiceSubFolderEnum.PUBLIC,
        }),
      );
    }

    const insurancePolicies = await InsuranceAPI.getInsurancePolicies({
      insuredParty: InsurancePolicyInsuredPartyEnum.TENANT,
      insuredPartyId: lease.id,
    });

    insurancePolicies.forEach((policy) => {
      filePromises.push(
        getFilesWithAdditionalInfo({
          modelId: policy.id,
          modelType: ModelTypeEnum.INSURANCE_POLICY,
          subFolder: WebserverFilesServiceSubFolderEnum.PUBLIC,
        }),
      );
    });

    const fileResults = await Promise.all(filePromises);
    const files = fileResults.flat();

    files.sort((a, b) => {
      return a.timestamp < b.timestamp ? 1 : -1;
    });

    return files;
  } catch (err) {
    fail(err);
  }

  return [];
}

type UseFetchLeaseDocumentsReturn = {
  leaseDocuments: FileWithAdditionalInfo[] | undefined;
  refetchLeaseDocuments: () => Promise<void>;
  isDocumentsFetching: boolean;
  isInitialLoading: boolean;
};

function useFetchLeaseDocuments(
  lease: LeaseSerializerLeaseResponse,
): UseFetchLeaseDocumentsReturn {
  const { data, isFetching, refetch, isInitialLoading } = useQuery({
    queryKey: ["fetchLeaseDocumentsByLease", { lease }] as const,
    queryFn: () => fetchLeaseDocumentsByLease(lease),
  });

  return {
    leaseDocuments: data,
    refetchLeaseDocuments: async () => {
      refetch();
    },
    isDocumentsFetching: isFetching,
    isInitialLoading,
  };
}
export { useFetchLeaseDocuments };
