import { useQuery } from "@tanstack/react-query";
import { Function as F } from "effect";
import { useContext } from "react";

import { createGLJournalEntryAttachments } from "@ender/shared/api/accounting";
import { ConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { Stack } from "@ender/shared/ds/stack";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetGLJournalEntryAttachmentsResponseAttachment } from "@ender/shared/generated/ender.api.accounting.response";
import { useUserStore } from "@ender/shared/stores/user-store";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import type { AttachmentsViewFile } from "@ender/widgets/shared/attachments";
import { AttachmentsView } from "@ender/widgets/shared/attachments";

type JournalEntryViewAttachmentType = Pick<
  GetGLJournalEntryAttachmentsResponseAttachment,
  "fileUploadId"
> &
  AttachmentsViewFile;

type JournalEntryViewProps = {
  id: EnderId;
  refreshParent?: () => void;
  approvalsDetailView?: boolean;
};

function JournalEntryView({
  id,
  refreshParent = F.constVoid,
  approvalsDetailView = false,
}: JournalEntryViewProps) {
  const userStore = useUserStore();

  const {
    data: attachments,
    isLoading,
    refetch,
  } = useQuery<JournalEntryViewAttachmentType[]>({
    queryFn: async ({ signal }) => {
      const response = await AccountingAPI.getGLJournalEntryAttachments(
        {
          journalEntryId: id,
        },
        { signal },
      );
      const { attachments } = response;
      const userMap = await userStore.getUsers(
        attachments.map((attachment) => attachment.uploaderId),
      );
      return attachments.map((attachment) => ({
        ...attachment,
        authorName: userMap[attachment.uploaderId]?.name,
        key: `/api/downloadFileUpload/${attachment.fileUploadId}`,
        name: attachment.fileName,
      }));
    },
    queryKey: ["AccountingAPI.getGLJournalEntryAttachments", id] as const,
  });

  const confirmation = useContext(ConfirmationContext);

  async function onClickDelete(file: JournalEntryViewAttachmentType) {
    try {
      await confirmation({
        confirmButtonLabel: "Confirm",
        title: "Are you sure you would like to delete this attachment?",
      });
      await AccountingAPI.deleteGLJournalEntryAttachment({
        fileUploadId: file.fileUploadId,
      });
      await refetch();
      if ((attachments?.length ?? 0) <= 1) {
        refreshParent();
      }
      showSuccessNotification({ message: "File deleted successfully" });
    } catch (err) {
      fail(err);
    }
  }

  async function uploadFiles(files: File[]) {
    await createGLJournalEntryAttachments(files, id);
  }

  async function onSuccess() {
    await refetch();
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Stack>
      <AttachmentsView
        approvalsDetailView={approvalsDetailView}
        files={attachments ?? []}
        onClickDelete={onClickDelete}
        onSuccess={onSuccess}
        uploadFiles={uploadFiles}
      />
    </Stack>
  );
}

export { JournalEntryView };
export type { JournalEntryViewProps };
