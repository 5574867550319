/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/vehicles
 * @function getVehicles
 * @memberof LeasingAPI
 * @param {LeasingAPIGetVehiclesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Vehicle[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Vehicle } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetVehiclesPathParams = {
  leaseId: EnderId;
};

type LeasingAPIGetVehiclesSearchParams = {
  token?: string | undefined;
  includeArchived?: boolean | undefined;
};

type LeasingAPIGetVehiclesPayload = LeasingAPIGetVehiclesPathParams &
  LeasingAPIGetVehiclesSearchParams;

function getVehiclesUnsafeEffect(payload: LeasingAPIGetVehiclesPayload) {
  const { leaseId, token, includeArchived, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Vehicle[]>({
        body,
        decode: unsafeDecodeJsonResponse<Vehicle[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/vehicles`,
        searchParams: [
          ["token", token],
          ["includeArchived", includeArchived],
        ],
      }),
    ),
  );
}

function getVehicles(
  payload: LeasingAPIGetVehiclesPayload,
  options?: { signal?: AbortSignal },
): Promise<Vehicle[]> {
  return F.pipe(
    payload,
    getVehiclesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVehicles };
export type {
  LeasingAPIGetVehiclesPathParams,
  LeasingAPIGetVehiclesPayload,
  LeasingAPIGetVehiclesSearchParams,
};
