import { useContext, useMemo } from "react";

import { NULL } from "@ender/shared/constants/general";
import type { OptimizedLedgerEvent } from "@ender/shared/contexts/ledger";
import { LedgerActions, LedgerContext } from "@ender/shared/contexts/ledger";
import type { EnderId } from "@ender/shared/core";
import { Modal } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { LedgerEventReversalButton } from "@ender/widgets/finance/ledger-event-reversal-button";

import { ReverseCreditForm } from "./reverse-credit-form";

type LedgerEventReverseCreditButtonProps = {
  leaseId: EnderId;
  ledgerEvent: OptimizedLedgerEvent;
};

function LedgerEventReverseCreditButton(
  props: LedgerEventReverseCreditButtonProps,
) {
  const { leaseId, ledgerEvent } = props;
  const { dispatch } = useContext(LedgerContext);
  const [
    isReverseModalOpen,
    { setTrue: openReverseModal, setFalse: closeReverseModal },
  ] = useBoolean();
  const { isCreditReversal, isReversedCredit } = ledgerEvent;

  const isReversible = !isReversedCredit && !isCreditReversal;

  function handleSuccess() {
    dispatch({
      type: LedgerActions.SET_SELECTED_LEDGER_EVENT,
      payload: NULL,
    });
    dispatch({
      type: LedgerActions.FETCH_LEDGER,
      payload: { leaseId },
    });
    closeReverseModal();
  }

  const disabledTooltip = useMemo(() => {
    if (isReversedCredit) {
      return "This credit has already been reversed.";
    }

    if (isCreditReversal) {
      return "This transaction reverses a previous one and cannot be reversed again.";
    }
  }, [isCreditReversal, isReversedCredit]);

  return (
    <>
      <LedgerEventReversalButton
        onClick={openReverseModal}
        disabled={!isReversible}
        disabledTooltip={disabledTooltip}>
        Reverse Credit
      </LedgerEventReversalButton>
      <Modal
        onClose={closeReverseModal}
        opened={isReverseModalOpen}
        title="Reverse Credit">
        <ReverseCreditForm
          ledgerEvent={ledgerEvent}
          onSuccess={handleSuccess}
        />
      </Modal>
    </>
  );
}

export { LedgerEventReverseCreditButton };
