import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import { format as formatDate } from "date-fns";
import { Option as O, pipe } from "effect";
import { forwardRef } from "react";

import type { Instant$ } from "@ender/shared/core";
import { castEnum } from "@ender/shared/utils/effect";

import type { DateFormats } from "../../../date-display/src";
import { DateFormat } from "../../../date-display/src";

const TimeFormatSchema = Schema.Literal("standard", "military");
const TimeFormatValues = TimeFormatSchema.literals;
type TimeFormats = Schema.Schema.Type<typeof TimeFormatSchema>;
const TimeFormat = castEnum(TimeFormatSchema);

const dateTemplates: Record<DateFormats, string> = {
  [DateFormat.hyphenated]: "yyyy-MM-dd",
  [DateFormat.slashed]: "MM/dd/yyyy",
  [DateFormat.words]: "MMMM dd, yyyy",
};

const timeTemplates: Record<TimeFormats, string> = {
  [TimeFormat.standard]: "hh:mm a",
  [TimeFormat.military]: "HH:mm",
};

type InstantDisplayProps = {
  /**
   * The percent to display
   */
  value?: Instant$ | O.Option<Instant$>;
  /**
   * The format to display the Instant in.
   * standard - 1:30 PM
   * military - 13:30
   *
   * @default "standard"
   */
  timeFormat?: TimeFormats;
  /**
   * The format to display the date in.
   * hyphenated - YYYY-MM-DD
   * slashed - MM/DD/YYYY
   * words - Month Day, Year
   *
   * @default "slashed"
   */
  dateFormat?: DateFormats;
};

const InstantVariantGenerator = cva([], {
  defaultVariants: {},
  variants: {},
});

const InstantDisplay = forwardRef<HTMLSpanElement, InstantDisplayProps>(
  function InstantDisplay(props, ref) {
    const {
      value,
      timeFormat = TimeFormat.standard,
      dateFormat = DateFormat.slashed,
    } = props;

    const _value = O.isOption(value) ? value : O.fromNullable(value);

    const formatString = [dateTemplates[dateFormat], timeTemplates[timeFormat]]
      .filter(Boolean)
      .join(" ");
    return (
      <span ref={ref} className={InstantVariantGenerator()}>
        {pipe(
          _value,
          O.map((v) => formatDate(v.toDate(), formatString)),
          O.getOrNull,
        )}
      </span>
    );
  },
);

export { InstantDisplay, TimeFormat, TimeFormatSchema, TimeFormatValues };

export type { InstantDisplayProps, TimeFormats };
