import { IconEdit } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Array as A, Function as F, Predicate as P } from "effect";
import { useId } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import {
  PropertiesAPI,
  UnitsAPI,
} from "@ender/shared/generated/ender.api.core";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import type { Unit } from "@ender/shared/generated/ender.model.core.unit";
import {
  CoolingSystemDisplayEnum,
  HeatingSystemDisplayEnum,
  LaundryTypeDisplayEnum,
  ParkingTypeDisplayEnum,
} from "@ender/shared/types/ender-general";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

type CardContentProps = {
  propertyAmenities: AmenityAmenityType[];
  unitAmenities: AmenityAmenityType[];
  unit?: Unit | null;
};

function CardContent({
  propertyAmenities,
  unitAmenities,
  unit,
}: CardContentProps) {
  return (
    <Stack>
      {propertyAmenities?.length ? (
        <Stack spacing={Spacing.sm}>
          <Text size={FontSize.sm} weight={FontWeight.medium}>
            Inherited from Property Page
          </Text>
          <Group spacing={Spacing.md}>
            {propertyAmenities.map((amenity) => (
              <Badge color={BadgeColor.slate} key={amenity}>
                {convertSnakeCaseToTitleCase(amenity)}
              </Badge>
            ))}
          </Group>
        </Stack>
      ) : (
        <Text size={FontSize.sm}>
          No amenities inherited from property page
        </Text>
      )}

      {A.isNonEmptyArray(unitAmenities) ? (
        <Stack spacing={Spacing.sm}>
          <Text size={FontSize.sm} weight={FontWeight.medium}>
            Unique to Unit
          </Text>
          <Group spacing={Spacing.md}>
            {unitAmenities.map((amenity) => (
              <Badge color={BadgeColor.slate} key={amenity}>
                {convertSnakeCaseToTitleCase(amenity)}
              </Badge>
            ))}
          </Group>
        </Stack>
      ) : (
        <Text size={FontSize.sm}>No amenities added for this unit</Text>
      )}
      <Grid spacingY={Spacing.none}>
        <Tuple
          label="Heating"
          value={
            unit?.heatingSystem && HeatingSystemDisplayEnum[unit.heatingSystem]
          }
        />
        <Tuple
          label="Cooling"
          value={
            unit?.coolingSystem && CoolingSystemDisplayEnum[unit.coolingSystem]
          }
        />
        <Tuple
          label="Laundry"
          value={unit?.laundryType && LaundryTypeDisplayEnum[unit.laundryType]}
        />
        <Tuple
          label="Parking"
          value={unit?.parkingType && ParkingTypeDisplayEnum[unit.parkingType]}
        />
      </Grid>
    </Stack>
  );
}

type AmenitiesCardProps = {
  unit: UnitSerializerUnitResponse;
  onEdit?: (values: {
    propertyAmenities: AmenityAmenityType[];
    unitAmenities: AmenityAmenityType[];
  }) => void;
};

function AmenitiesCard(props: AmenitiesCardProps) {
  const { unit, onEdit = F.constVoid } = props;
  const { id: unitId } = unit ?? {};

  const { data: propertyAmenities = [] } = useQuery({
    enabled: P.isNotNullable(unit),
    queryFn: () => PropertiesAPI.getAmenities({ propertyId: unit.property.id }),
    queryKey: ["PropertiesAPI.getAmenities", unit.property.id],
  });

  const { data: unitAmenities = [], isInitialLoading } = useQuery({
    enabled: P.isNotNullable(unitId),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => UnitsAPI.getUnitAmenities({ unitId }),
    queryKey: ["UnitsAPI.getUnitAmenities", unitId],
  });

  const id = useId();

  return (
    <Card labelledBy={id}>
      <Group justify={Justify.between} align={Align.center}>
        <H3 id={id}>Unit Amenities for Listing</H3>
        <ActionIcon
          variant={ButtonVariant.transparent}
          onClick={() => onEdit({ propertyAmenities, unitAmenities })}
          disabled={isInitialLoading}>
          <IconEdit color={EnderThemeColorEnum.PRIMARY} />
        </ActionIcon>
      </Group>
      <Skeleton visible={isInitialLoading}>
        <CardContent
          unit={unit}
          propertyAmenities={propertyAmenities}
          unitAmenities={unitAmenities}
        />
      </Skeleton>
    </Card>
  );
}

export { AmenitiesCard };
