// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { PaginationProps } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Pagination } from "@mantine/core";

import styles from "./ender-pagination.module.css";





type EnderPaginationProps = PaginationProps & {
  page: number;
  total: number;
  onChange: (page: number) => void;
};

/**
 * @typedef {object} EnderPaginationProps
 * @property {number} page
 * @property {number} total
 * @property {function} onChange
 *
 * @name EnderPagination
 * @param {EnderPaginationProps & ReactProps} props
 * @returns
 *
 * @deprecated this should be built in to the Table system and exported from that package instead of
 * shared/ui
 */
function EnderPagination({
  page,
  total,
  onChange,
  ...paginationProps
}: EnderPaginationProps) {
  return (
    <Pagination
      page={page}
      total={total}
      onChange={onChange}
      className={styles.root}
      classNames={{ item: styles.item }}
      {...paginationProps}
    />
  );
}

export { EnderPagination };
