import { Schema } from "@effect/schema";
import { IconChevronLeft, IconEdit } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P, String as S, pipe } from "effect";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  SearchInput,
  hydrateProperty,
  searchProperties,
} from "@ender/entities/search-input";
import { useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H1, H3 } from "@ender/shared/ds/heading";
import { InputWrapper } from "@ender/shared/ds/input";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Select } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { PropertySerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { PropertyHomeTypeEnum } from "@ender/shared/generated/ender.model.core.property";
import { ListingListingStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import { isMultiple } from "@ender/shared/utils/is";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const CreateListingSchema = Schema.Struct({
  advertisedRent: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(P.or(O.isNone, O.exists<Money$.Money>(Money$.isPositive)), {
      message: () => "Amount must be positive",
    }),
  ),
  applicationFee: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(P.or(O.isNone, O.exists<Money$.Money>(Money$.isPositive)), {
      message: () => "Amount must be positive",
    }),
  ),
  marketingBody: Schema.String,
  marketingTitle: Schema.String,
  securityDeposit: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(P.or(O.isNone, O.exists<Money$.Money>(Money$.isPositive)), {
      message: () => "Amount must be positive",
    }),
  ),
});

type CreateListingFormOutput = Schema.Schema.Type<typeof CreateListingSchema>;

function CreateListingPage() {
  const form = useEffectSchemaForm({
    defaultValues: {
      advertisedRent: O.none(),
      applicationFee: O.none(),
      marketingBody: "",
      marketingTitle: "",
      securityDeposit: O.none(),
    },
    schema: CreateListingSchema,
  });
  const { setValue } = form;
  const history = useHistory();
  const [propertyId, setPropertyId] = useState<O.Option<EnderId>>(O.none());
  const [unitId, setUnitId] = useState<O.Option<EnderId>>(O.none());

  const handleSetUnit = useCallback(
    (unit: PropertySerializerUnitResponse) => {
      /**
       * when Unit is set, we want to pre-populate some form values based on the unit
       */
      setValue(
        "advertisedRent",
        O.orElseSome(Money$.parse(unit.marketRent), () => Money$.zero()),
      );
      setValue(
        "securityDeposit",
        O.orElseSome(Money$.parse(unit.marketRent), () => Money$.zero()),
      );
      setUnitId(O.some(unit.id));
    },
    [setValue],
  );

  const { data: property, isLoading: isFetchingProperty } = useQuery({
    enabled: O.isSome(propertyId),
    queryFn: ({ signal }) => {
      if (O.isSome(propertyId)) {
        return PropertiesAPI.getProperty(
          {
            propertyId: pipe(propertyId, O.getOrThrow),
          },
          { signal },
        );
      }
    },
    queryKey: ["PropertiesAPI.getProperty", propertyId] as const,
  });

  // Handle single unit case
  useEffect(() => {
    if (property?.units.length === 1) {
      handleSetUnit(property.units[0]);
    }
  }, [property, handleSetUnit]);

  // Tyler 07-24-2024 - I added this API call because the BE expects amenities to be passed in the payload.
  // TODO determine if this is necessary and convert form 2025-02-24 https://ender-1337.atlassian.net/browse/ENDER-22376
  const { data: propertyAmenities = [], isLoading: fetchingPropertyAmenities } =
    useQuery({
      enabled: O.isSome(propertyId),
      queryFn: ({ signal }) => {
        if (O.isSome(propertyId)) {
          return PropertiesAPI.getAmenities(
            {
              propertyId: pipe(propertyId, O.getOrThrow),
            },
            { signal },
          );
        }
        return;
      },
      queryKey: ["PropertiesAPI.getAmenities", propertyId] as const,
    });

  const { mutateAsync: createListing, isLoading: posting } = useMutation({
    mutationFn: LeasingAPI.createListing,
  });

  async function handleSubmit(values: CreateListingFormOutput) {
    if (
      O.isNone(unitId) ||
      fetchingPropertyAmenities ||
      P.isNullable(propertyAmenities)
    ) {
      return;
    }
    const {
      advertisedRent,
      applicationFee,
      securityDeposit,
      marketingBody,
      marketingTitle,
    } = values;
    const listing = await createListing({
      advertisedRent: pipe(advertisedRent, O.getOrUndefined),
      amenities: propertyAmenities,
      applicationFee: pipe(applicationFee, O.getOrUndefined),
      marketingBody: S.isEmpty(marketingBody) ? UNDEFINED : marketingBody,
      marketingTitle: S.isEmpty(marketingTitle) ? UNDEFINED : marketingTitle,
      securityDeposit: pipe(securityDeposit, O.getOrUndefined),
      status: ListingListingStatusEnum.DRAFTING,
      unitId: O.getOrThrow(unitId),
    });
    history.push(`/listings/${listing.id}`);
    showSuccessNotification({
      message: "The Listing has been successfully created.",
    });
  }

  // @ts-expect-error Property isVacant does not exist on type Unit
  const vacantUnits = property?.units?.filter((unit) => unit.isVacant) ?? [];

  const propertyHasUnits =
    P.isNotNullable(property) &&
    P.isNotNullable(property.units) &&
    isMultiple(property.units);

  return (
    <Stack>
      <RouterLink href="/leasing-center/listings">
        <Button
          variant={ButtonVariant.transparent}
          leftSection={<IconChevronLeft />}>
          All Listings
        </Button>
      </RouterLink>

      <H1>Create a Listing</H1>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <H3>Select Property/Unit</H3>
        <Grid>
          <SearchInput<EnderId>
            search={searchProperties}
            hydrate={hydrateProperty}
            label="Property"
            modelType={ModelTypeEnum.PROPERTY}
            onChange={(propertyId) => {
              setPropertyId(propertyId);
              setUnitId(O.none());
            }}
            value={propertyId}
          />
          {propertyHasUnits && (
            <Skeleton visible={isFetchingProperty}>
              <Select
                label="Unit"
                data={property?.units?.map((unit, idx) => ({
                  label: S.isEmpty(unit.name)
                    ? `Unnamed Unit ${idx + 1}`
                    : unit.name,
                  value: unit.id,
                }))}
                value={unitId}
                onChange={setUnitId}
              />
            </Skeleton>
          )}
          {O.isSome(unitId) && (
            <Stack align={Align.start} justify={Justify.end}>
              <Button
                type="submit"
                loading={posting}
                disabled={P.isNullable(property?.homeType)}
                disabledTooltip="Home Type is required">
                Start Listing
              </Button>
            </Stack>
          )}
        </Grid>
      </form>
      {O.isSome(propertyId) && (
        <Stack>
          <H3>Selected Property Details</H3>
          <Skeleton visible={isFetchingProperty}>
            <Card>
              <Stack>
                <Group justify={Justify.between}>
                  <H3>{property?.name}</H3>
                  <a
                    href={`/properties/${property?.id}`}
                    target="_blank"
                    rel="noreferrer">
                    <ActionIcon
                      variant={ButtonVariant.transparent}
                      label="Edit Property">
                      <IconEdit />
                    </ActionIcon>
                  </a>
                </Group>
                <Grid>
                  <InputWrapper
                    error={
                      P.isNotNullable(property?.homeType)
                        ? UNDEFINED
                        : "Home Type is required"
                    }
                    inputId=""
                    labelId=""
                    errorId=""
                    descriptionId="">
                    <Tuple
                      label="Home Type"
                      value={
                        P.isNotNullable(property?.homeType)
                          ? PropertyHomeTypeEnum[property.homeType]
                          : "--"
                      }
                    />
                  </InputWrapper>
                  <Tuple
                    label="Units"
                    value={`${property?.units?.length} (${vacantUnits.length} Vacant)`}
                  />
                </Grid>
              </Stack>
            </Card>
          </Skeleton>
        </Stack>
      )}
    </Stack>
  );
}

export { CreateListingPage };
