import { useMutation, useQueryClient } from "@tanstack/react-query";
import { String as S } from "effect";
import { useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { useForm } from "@ender/shared/forms/hooks/general";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type {
  ApprovalProcessType,
  ApprovalStepApprovalPhase,
} from "@ender/shared/generated/ender.model.approvals";

import type { ApprovalProcessHybridId } from "../types";
import {
  DEFAULT_APPROVAL_PROCESS_HYBRID_ID,
  NEW_APPROVAL_PROCESS_HYBRID_ID,
} from "../types";

type CreateUpdateStepFormProps = {
  approvalProcessHybridId: ApprovalProcessHybridId;
  approvalProcessPhase?: ApprovalStepApprovalPhase;
  approvalProcessType: ApprovalProcessType;
  onSuccess: () => void;
  originalStepName?: string;
  stepId?: EnderId;
};

// TODO type and approvalProcessId is optional
/**
 * POSSIBLE DUPLICATE:
 * libs/widgets/finance/approval/src/lib/create-update-approval-step-form.tsx
 *
 * This file seems to have a lot in common with create-update-approval-step-form.
 * Maybe this needs to be elevated out to a shared, generic form that can be used in place of both
 */
function CreateUpdateStepForm({
  approvalProcessHybridId,
  approvalProcessPhase,
  approvalProcessType,
  onSuccess,
  originalStepName,
  stepId,
}: CreateUpdateStepFormProps) {
  const queryClient = useQueryClient();

  const approvalProcessId = useMemo(
    () =>
      approvalProcessHybridId === DEFAULT_APPROVAL_PROCESS_HYBRID_ID ||
      // SHOULD NEVER HAPPEN | Used to type guard the value passed to ApprovalsAPI.addApprovalStep
      approvalProcessHybridId === NEW_APPROVAL_PROCESS_HYBRID_ID
        ? undefined
        : approvalProcessHybridId,
    [approvalProcessHybridId],
  );

  const form = useForm({
    initialValues: {
      name: originalStepName || "",
    },
    validate: {
      name: (value) => {
        if (S.isEmpty(value)) {
          return "Step Name is required";
        }
      },
    },
  });
  const approvalsAPIMutation = useMutation({
    mutationFn: async (values: { name: string }) => {
      const { name } = values;
      if (stepId) {
        return await ApprovalsAPI.updateStepName({ name, stepId });
      } else {
        return await ApprovalsAPI.addApprovalStep({
          approvalProcessId,
          approvalProcessType,
          name,
          phase: approvalProcessPhase,
        });
      }
    },
    mutationKey: ["ApprovalsAPI.updateOrAddStep"] as const,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ApprovalsAPI.updateOrAddStep", { approvalProcessType }],
      });
      onSuccess();
    },
  });

  async function handleSubmit(values: { name: string }) {
    await approvalsAPIMutation.mutateAsync(values);
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput label="Name" name="name" {...form.getInputProps("name")} />
        <Group justify={Justify.end}>
          <Button type="submit" loading={approvalsAPIMutation.isLoading}>
            Save
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

export { CreateUpdateStepForm };
