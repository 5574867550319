/**
 * HTTP Method: POST
 * Pathname: /announcements/filteredSendToTenants
 * @function sendFilteredTenantAnnouncement
 * @memberof AnnouncementAPI
 * @param {AnnouncementAPISendFilteredTenantAnnouncementPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  FilterTenantAnnouncementRequest,
  SendTenantAnnouncementRequest,
} from "@ender/shared/generated/ender.api.comm.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AnnouncementAPISendFilteredTenantAnnouncementSearchParams = {
  token?: string | undefined;
};

type AnnouncementAPISendFilteredTenantAnnouncementBodyParams = {
  filterAnnouncementRequest: FilterTenantAnnouncementRequest;
  sendAnnouncementRequest: SendTenantAnnouncementRequest;
};

type AnnouncementAPISendFilteredTenantAnnouncementPayload =
  AnnouncementAPISendFilteredTenantAnnouncementSearchParams &
    AnnouncementAPISendFilteredTenantAnnouncementBodyParams;

function sendFilteredTenantAnnouncementUnsafeEffect(
  payload: AnnouncementAPISendFilteredTenantAnnouncementPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/announcements/filteredSendToTenants",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendFilteredTenantAnnouncement(
  payload: AnnouncementAPISendFilteredTenantAnnouncementPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendFilteredTenantAnnouncementUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendFilteredTenantAnnouncement };
export type {
  AnnouncementAPISendFilteredTenantAnnouncementBodyParams,
  AnnouncementAPISendFilteredTenantAnnouncementPayload,
  AnnouncementAPISendFilteredTenantAnnouncementSearchParams,
};
