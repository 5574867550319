import { createRoot } from "react-dom/client";

import { init } from "./init";
import { App } from "./src/app/app";

async function main() {
  await init();

  const root = document.querySelector("ender-app");
  if (root) {
    createRoot(root).render(<App />);
  }
}

void main();
