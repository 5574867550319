import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const FoundationDrainDrainMaterialValues = [
  "CAST_IRON",
  "PVC",
  "CLAY",
] as const;

const FoundationDrainDrainMaterialEffectSchema = Schema.Literal(
  ...FoundationDrainDrainMaterialValues,
);

type FoundationDrainDrainMaterial = Schema.Schema.Type<
  typeof FoundationDrainDrainMaterialEffectSchema
>;

const FoundationDrainDrainMaterialEnum = castEnum(
  FoundationDrainDrainMaterialEffectSchema,
);

function randomFoundationDrainDrainMaterial(): FoundationDrainDrainMaterial {
  return rand(FoundationDrainDrainMaterialValues);
}

export {
  FoundationDrainDrainMaterialEffectSchema,
  FoundationDrainDrainMaterialEnum,
  FoundationDrainDrainMaterialValues,
  randomFoundationDrainDrainMaterial,
};
export type { FoundationDrainDrainMaterial };
