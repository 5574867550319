/**
 * HTTP Method: POST
 * Pathname: /applications/{appGroupId}/refreshIncomeVerificationReportUrl
 * @function refreshIncomeVerificationReportUrl
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIRefreshIncomeVerificationReportUrlPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RefreshIncomeVerificationReportUrlResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { RefreshIncomeVerificationReportUrlResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIRefreshIncomeVerificationReportUrlPathParams = {
  appGroupId: EnderId;
};

type ApplicationsAPIRefreshIncomeVerificationReportUrlSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIRefreshIncomeVerificationReportUrlPayload =
  ApplicationsAPIRefreshIncomeVerificationReportUrlPathParams &
    ApplicationsAPIRefreshIncomeVerificationReportUrlSearchParams;

function refreshIncomeVerificationReportUrlUnsafeEffect(
  payload: ApplicationsAPIRefreshIncomeVerificationReportUrlPayload,
) {
  const { appGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RefreshIncomeVerificationReportUrlResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<RefreshIncomeVerificationReportUrlResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${appGroupId}/refreshIncomeVerificationReportUrl`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function refreshIncomeVerificationReportUrl(
  payload: ApplicationsAPIRefreshIncomeVerificationReportUrlPayload,
  options?: { signal?: AbortSignal },
): Promise<RefreshIncomeVerificationReportUrlResponse> {
  return F.pipe(
    payload,
    refreshIncomeVerificationReportUrlUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { refreshIncomeVerificationReportUrl };
export type {
  ApplicationsAPIRefreshIncomeVerificationReportUrlPathParams,
  ApplicationsAPIRefreshIncomeVerificationReportUrlPayload,
  ApplicationsAPIRefreshIncomeVerificationReportUrlSearchParams,
};
