// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { RangeSlider as MantineRangeSlider } from "@mantine/core";
import type { ForwardedRef } from "react";
import { forwardRef, useMemo } from "react";
import { z } from "zod";

import { generateGeneralMantinePropsSchema } from "@ender/shared/forms/types/general";
import type { LabelValue } from "@ender/shared/types/label-value";
import { EnderInputWrapper } from "@ender/shared/ui/ender-input-wrapper";





const SliderBasePropsSchema = z.object({
  inverted: z.boolean().optional(),
  marks: z.array(z.custom<LabelValue<number>>()).optional(),
  max: z.number(),
  min: z.number(),
  sliderLabel: z.string().optional(),
  step: z.number().optional(),
});

/**
 * Calculates the step size for a range of values.
 * @param {number} range - The range of values.
 * @returns {number} The step size.
 */
function getStep(range: number): number {
  if (range < 100) {
    return 1;
  } else if (range > 1000) {
    return 50;
  } else if (range >= 100) {
    return 10;
  }

  return 1;
}

/**
 * Generates an array of default marks for a slider.
 * @param {MinMax} param - An object containing the minimum and maximum values for the slider.
 * @param {number} param.min - The minimum value for the slider.
 * @param {number} param.max - The maximum value for the slider.
 * @returns {Array<{ value: number, label: string }>} An array of default marks.
 */
function genDefaultMarks({
  min,
  max,
}: {
  min: number;
  max: number;
}): LabelValue<number>[] {
  return [
    { label: `${min}`, value: min },
    { label: `${Math.round((max + min) / 2)}`, value: (max + min) / 2 },
    { label: `${max}`, value: max },
  ];
}

/**
 * Generates an array of default marks for a slider and memoizes the result.
 * @param {MinMax} param - An object containing the minimum and maximum values for the slider.
 * @param {number} param.min - The minimum value for the slider.
 * @param {number} param.max - The maximum value for the slider.
 * @returns {Array<{ value: number, label: string }>} An array of default marks.
 */
function useDefaultMarks({
  min,
  max,
}: {
  min: number;
  max: number;
}): LabelValue<number>[] {
  return useMemo(() => genDefaultMarks({ min, max }), [min, max]);
}

const RangeSliderBasePropsSchema = SliderBasePropsSchema.extend({
  maxRange: z.number().optional(),
  minRange: z.number().default(0).optional(),
});

const RangeSliderSchema = generateGeneralMantinePropsSchema(
  z.tuple([z.number(), z.number()]),
).merge(RangeSliderBasePropsSchema);

type RangeSliderProps = z.infer<typeof RangeSliderSchema>;

const enderSliderStyles = {
  label: { minWidth: 30, textAlign: "center" },
} as const;

/**
 * @deprecated co-locate this within the only package or page it's used
 */
const RangeSlider = forwardRef(function RangeSlider(
  props: RangeSliderProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    disabled,
    error,
    inverted,
    label,
    max,
    maxRange,
    min,
    minRange = 0,
    name,
    onChange,
    sliderLabel,
    value = [min, max],
  } = props;
  const step = getStep(max - min);
  const marks = useDefaultMarks({ min, max });
  const finalDisbaled =
    disabled ||
    (minRange === Number.MAX_SAFE_INTEGER &&
      maxRange === Number.MIN_SAFE_INTEGER);

  return (
    <EnderInputWrapper error={error} label={label}>
      <MantineRangeSlider
        disabled={finalDisbaled}
        inverted={inverted}
        label={sliderLabel}
        marks={!finalDisbaled ? marks : undefined}
        max={max}
        maxRange={maxRange}
        min={min}
        minRange={minRange}
        name={name}
        onChange={onChange}
        radius={8}
        ref={ref}
        step={step}
        styles={enderSliderStyles}
        value={value}
      />
    </EnderInputWrapper>
  );
});

export { RangeSlider, RangeSliderBasePropsSchema, RangeSliderSchema };
export type { RangeSliderProps };
