/**
 * Create a record of a transfer between two bank accounts associated with the same property.
 * HTTP Method: POST
 * Pathname: /moneyTransfers/internal
 * @function createInternalMarkPaidTransfer
 * @memberof PaymentsAPI
 * @param {PaymentsAPICreateInternalMarkPaidTransferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransfer>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { TransferDetails } from "@ender/shared/generated/ender.api.accounting.request";
import type { MoneyTransfer } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPICreateInternalMarkPaidTransferSearchParams = {
  token?: string | undefined;
};

type PaymentsAPICreateInternalMarkPaidTransferBodyParams = {
  amount: SerializesInto<Money>;
  fromBankAccountId: EnderId;
  /**
   * Can be used to override warning about adjusted bank balance.
   */
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
  propertyId: EnderId;
  toBankAccountId: EnderId;
  /**
   * {@link TransferDetails}
   */
  transferDetails: TransferDetails;
};

type PaymentsAPICreateInternalMarkPaidTransferPayload =
  PaymentsAPICreateInternalMarkPaidTransferSearchParams &
    PaymentsAPICreateInternalMarkPaidTransferBodyParams;

function createInternalMarkPaidTransferUnsafeEffect(
  payload: PaymentsAPICreateInternalMarkPaidTransferPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransfer>({
        body,
        decode: unsafeDecodeJsonResponse<MoneyTransfer>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/moneyTransfers/internal",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createInternalMarkPaidTransfer(
  payload: PaymentsAPICreateInternalMarkPaidTransferPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransfer> {
  return F.pipe(
    payload,
    createInternalMarkPaidTransferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createInternalMarkPaidTransfer };
export type {
  PaymentsAPICreateInternalMarkPaidTransferBodyParams,
  PaymentsAPICreateInternalMarkPaidTransferPayload,
  PaymentsAPICreateInternalMarkPaidTransferSearchParams,
};
