import type { PropsWithChildren, ReactElement } from "react";

import { NULL } from "@ender/shared/constants/general";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import type { FeatureFlagFlagKey } from "@ender/shared/generated/ender.model.misc";

import { useGetFeatureFlagValue } from "./use-get-feature-flag-value";

type FeatureFlagWrapperProps = PropsWithChildren<{
  flagKey: FeatureFlagFlagKey;
  nonFeaturedContent?: () => ReactElement;
}>;

function FeatureFlagWrapper(props: PropsWithChildren<FeatureFlagWrapperProps>) {
  const {
    flagKey,
    children,
    nonFeaturedContent: NonFeaturedContent = () => NULL,
  } = props;

  const { enabled, isLoading } = useGetFeatureFlagValue(flagKey);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return enabled ? (
    <>{children}</>
  ) : (
    <>{NonFeaturedContent ? <NonFeaturedContent /> : NULL}</>
  );
}

export { FeatureFlagWrapper };

export type { FeatureFlagWrapperProps };
