import { useQuery } from "@tanstack/react-query";
import { Array as A, Function as F, Option as O, pipe } from "effect";
import { useMemo, useState } from "react";

import type { LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Select } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { AccountingPeriodAccountingModule } from "@ender/shared/generated/ender.model.accounting";
import {
  AccountingPeriodAccountingModuleEnum,
  randomAccountingPeriod,
} from "@ender/shared/generated/ender.model.accounting";
import { useDocumentTitle } from "@ender/shared/hooks/use-document-title";
import { RightRail } from "@ender/shared/ui/right-rail";

import { PeriodCard } from "./period-card";
import { AuditHistoryList } from "./right-rail/audit-history-list";

/*
 * This only exists as a placeholder inside the Skeleton.
 * There'a a randomAccountingPeriod() called inside here which is only used to allow
 * the skeleton to load appropriately sized versions of the card
 */
function _LoadingPeriodCard() {
  return (
    <PeriodCard
      period={randomAccountingPeriod()}
      updateCallback={F.constVoid}
    />
  );
}

const periodTypeList = [
  {
    label: "General Ledger",
    value: AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER,
  },
  {
    label: "Accounts Payable",
    value: AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE,
  },
  {
    label: "Accounts Receivable",
    value: AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE,
  },
];

function sortPeriods(a: { startDate: LocalDate }, b: { startDate: LocalDate }) {
  return a.startDate.localeCompare(b.startDate);
}

function ManagePeriods() {
  useDocumentTitle("Manage Periods - Ender");

  const [periodType, setPeriodType] = useState<
    O.Option<AccountingPeriodAccountingModule>
  >(O.some(AccountingPeriodAccountingModuleEnum.GENERAL_LEDGER));

  const thisYearOption = pipe(LocalDate$.today(), LocalDate$.year, O.some);
  const [selectedYear, setSelectedYear] =
    useState<O.Option<number>>(thisYearOption);
  const [isAuditHistoryShowing, setIsAuditHistoryShowing] = useState(false);

  const {
    data = [],
    isInitialLoading,
    refetch,
  } = useQuery({
    keepPreviousData: true,
    queryFn: ({ signal }) =>
      AccountingAPI.getAccountingPeriods(
        { module: O.getOrThrow(periodType) },
        { signal },
      ),
    queryKey: ["AccountingAPI.getAccountingPeriods", periodType],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });

  const yearsList = useMemo(
    () =>
      pipe(
        data,
        A.map((p) => pipe(p.endDate, LocalDate$.of, LocalDate$.year)),
        (years) => new Set(years),
        (set) => Array.from(set),
        (uniqueYears) => uniqueYears.sort((a, b) => b - a),
        (sortedYears) =>
          sortedYears.map((year) => ({ label: year.toString(), value: year })),
      ),
    [data],
  );

  const filteredPeriods = useMemo(() => {
    const sorted = [...data].sort(sortPeriods);

    if (O.isSome(selectedYear)) {
      const filterYear = O.getOrThrow(selectedYear);
      return sorted.filter((p) =>
        LocalDate$.contains(LocalDate$.of(p.endDate), { year: filterYear }),
      );
    }
    return sorted;
  }, [data, selectedYear]);

  return (
    <div>
      <Stack>
        <Group justify={Justify.between}>
          <Group>
            <Select
              label="Select Period Type"
              placeholder="Filter by Payment Type"
              data={periodTypeList}
              value={periodType}
              onChange={(val) => setPeriodType(val)}
            />
            <Select
              label="Select Year"
              placeholder="Filter by Year"
              data={yearsList}
              value={selectedYear ?? thisYearOption}
              onChange={(val) => setSelectedYear(val)}
              clearable
            />
          </Group>
          <Group align={Align.center}>
            <Button onClick={() => setIsAuditHistoryShowing((prev) => !prev)}>
              Audit History
            </Button>
          </Group>
        </Group>

        <Grid>
          {isInitialLoading ? (
            <>
              {Array.from({ length: 12 }).map((_, idx) => (
                <Skeleton key={idx} visible>
                  <_LoadingPeriodCard />
                </Skeleton>
              ))}
            </>
          ) : (
            <>
              {filteredPeriods.map((period) => (
                <>
                  <PeriodCard
                    period={period}
                    updateCallback={() => refetch()}
                  />
                </>
              ))}
            </>
          )}
        </Grid>
      </Stack>

      <RightRail
        opened={isAuditHistoryShowing}
        onClose={() => setIsAuditHistoryShowing(false)}
        title="Audit History">
        <AuditHistoryList />
      </RightRail>
    </div>
  );
}

export { ManagePeriods };
