import { useMutation, useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useCallback } from "react";

import type { EnderId } from "@ender/shared/core";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import type { GetFilesResponseFileResponse } from "@ender/shared/generated/ender.api.files.response";
import { EnderDocumentsTable } from "@ender/shared/ui/ender-documents-table";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type UnitPageDocumentsProps = {
  unitId: EnderId;
};

function UnitPageDocuments(props: UnitPageDocumentsProps) {
  const { unitId } = props;

  const {
    data: documents = [],
    refetch: refetchDocuments,
    isLoading,
  } = useQuery({
    queryFn: ({ signal }) =>
      WebserverFilesAPI.getFiles(
        {
          modelId: unitId,
          modelType: ModelTypeEnum.UNIT,
        },
        { signal },
      ),
    queryKey: ["WebserverFilesAPI.getFiles", unitId] as const,
    select: (data) =>
      data.files.toSorted(
        (file1: { timestamp: string }, file2: { timestamp: string }) =>
          new Date(file2.timestamp).getTime() -
          new Date(file1.timestamp).getTime(),
      ),
  });

  async function handleUploadSuccess() {
    showSuccessNotification({
      message: "Unit documents successfully uploaded",
    });
    refetchDocuments();
  }

  const { mutateAsync: deleteFile } = useMutation({
    mutationFn: WebserverFilesAPI.deleteFile,
    mutationKey: ["WebserverFilesAPI.deleteFile"] as const,
  });

  const handleDeleteUnitDocument = useCallback(
    async (document: GetFilesResponseFileResponse) => {
      if (P.isNullable(unitId)) {
        return;
      }
      await deleteFile({
        fileId: document.id,
        modelId: unitId,
        modelType: ModelTypeEnum.UNIT,
      });
    },
    [unitId, deleteFile],
  );

  return (
    <Skeleton visible={isLoading}>
      <EnderDocumentsTable
        modelId={unitId}
        modelType={ModelTypeEnum.UNIT}
        documents={documents}
        onUploadSuccess={handleUploadSuccess}
        onDeleteDocument={handleDeleteUnitDocument}
      />
    </Skeleton>
  );
}

export { UnitPageDocuments };
