import {
  PaymentElement,
  Elements as StripeElements,
  useStripe,
  useElements as useStripeElements,
} from "@stripe/react-stripe-js";
import type {
  PaymentIntentOrSetupIntentResult,
  PaymentIntentResult,
  Stripe,
  StripeError,
} from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { IconChevronLeft, IconInfoCircle } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { CreditCard } from "@ender/pages/finance";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1, H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { PaymentsAPI } from "@ender/shared/generated/ender.api.accounting";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { CreditCard as CreditCardType } from "@ender/shared/generated/ender.model.payments.card";
import { EnderRadio } from "@ender/shared/ui/ender-radio";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { Text } from "@ender/shared/ds/text";
import { fail } from "@ender/shared/utils/error";
import { noOpAsync } from "@ender/shared/utils/no-op";

import { isStepComplete, stripeStyles } from "../apply-utils";
import type { ApplyStepProps } from "./step-props";

import commonStyles from "./common.module.css";

const holdingFeeDescription =
  "Holding fee is a refundable payment that a landlord or letting agency requests to \nreserve a property. This holding fee will be forfeit if the application is accepted but the \napplicant does not move in. This fee will be credited against any charges if the \napplicant moves in.";

type PaymentCaptureType = (
  isSetup?: boolean,
) => Promise<PaymentIntentOrSetupIntentResult>;

/**
 * A capturing element that can live within a Stripe Elements form and provide a way to execute that stripe payment.
 * wires into the Stripe context and outputs the payment function as the ref.
 * This allows us to put the 'execute payment' button anywhere we want
 */
const PaymentCapture = forwardRef<PaymentCaptureType>(
  function PaymentCapture(_, ref) {
    const stripe = useStripe();
    const elements = useStripeElements();
    useImperativeHandle(
      ref,
      function () {
        if (!stripe || !elements) {
          return () =>
            Promise.reject<PaymentIntentResult>(
              new Error(
                "No stripe instance found wrapping the payment elements",
              ),
            );
        }

        return (isSetup?: boolean) =>
          isSetup
            ? stripe.confirmSetup({ elements, redirect: "if_required" })
            : stripe.confirmPayment({ elements, redirect: "if_required" });
      },
      [stripe, elements],
    );
    return <></>;
  },
);

async function pollForPaymentProcessing(unitId?: EnderId, userId?: EnderId) {
  //this should never happen as once we are at this step we should have a unit and user id
  if (P.isNullable(unitId) || P.isNullable(userId)) {
    throw new Error("Unable to poll for payment processing");
  }
  return new Promise((resolve, reject) => {
    //poll every 5 seconds
    const interval = setInterval(async () => {
      try {
        const data = await UnitsAPI.getApplyInfo({ unitId });
        const application = data.applicationGroupResponse?.applications.find(
          ({ applicant }) => applicant.userId === userId,
        );
        if (
          application?.paymentInformation.feeStatus === "PAID" ||
          application?.paymentInformation.feeStatus === "PROCESSING"
        ) {
          clearInterval(interval);
          resolve(true);
        }
      } catch (e) {
        reject(e);
      }
    }, 5000);
    //check once immediately
    UnitsAPI.getApplyInfo({ unitId })
      .then((data) => {
        const application = data.applicationGroupResponse?.applications.find(
          ({ applicant }) => applicant.userId === userId,
        );
        if (
          application?.paymentInformation.feeStatus === "PAID" ||
          application?.paymentInformation.feeStatus === "PROCESSING"
        ) {
          clearInterval(interval);
          resolve(true);
        }
      })
      .catch(reject);
  });
}

async function pollForSetupProcessing(
  originalNumCards: number,
): Promise<CreditCardType[]> {
  return new Promise((resolve, reject) => {
    //poll every 5 seconds
    const interval = setInterval(async () => {
      try {
        const data = await PaymentsAPI.listCards({});
        if (data.length > originalNumCards) {
          clearInterval(interval);
          resolve(data);
        }
      } catch (e) {
        reject(e);
      }
    }, 5000);
    //check once immediately
    PaymentsAPI.listCards({})
      .then((data) => {
        if (data.length > originalNumCards) {
          clearInterval(interval);
          resolve(data);
        }
      })
      .catch(reject);
  });
}

type CommonStripeKeys = {
  publicKey: string;
  clientSecret: string;
  stripeAccount?: string;
};

function ApplyStepFee({
  unit,
  user,
  applicationGroup,
  stripePaymentInformation,
  applications,
  progress,
  titleRef,
  pm,
  refresh = noOpAsync,
  onRequestPrevious = F.constVoid,
  onRequestNext = F.constVoid,
}: ApplyStepProps) {
  const application = applications?.find(
    ({ applicant }) => applicant.userId === user?.userId,
  );

  const [loading, setLoading] = useState(false);

  const [stripe, setStripe] = useState<PromiseLike<Stripe | null>>();
  const { mutateAsync: setPaymentMethod } = useMutation({
    mutationFn: ApplicationsAPI.setPaymentMethod,
    mutationKey: ["ApplicationsAPI.setPaymentMethod"] as const,
  });
  const { data: stripeCards, refetch: refetchCards } = useQuery({
    queryFn: ({ signal }) => PaymentsAPI.listCards({}, { signal }),
    queryKey: ["PaymentsAPI.listCards"] as const,
  });

  const { data: stripeKeys, refetch: reinitializeIntent } = useQuery<
    CommonStripeKeys,
    Error
  >({
    enabled:
      !stripe && P.isNotNullable(applicationGroup) && P.isNotNullable(pm),
    queryFn: async ({ signal }): Promise<CommonStripeKeys> => {
      const { paymentIntentClientSecret, stripePublicKey, stripeAccountId } =
        stripePaymentInformation ?? {};
      let res: Partial<CommonStripeKeys> = {
        clientSecret: paymentIntentClientSecret,
        publicKey: stripePublicKey,
        stripeAccount: stripeAccountId,
      };
      if (P.isNotNullable(applicationGroup)) {
        if (pm?.chargeApplicationFeeOnScreening) {
          res = await PaymentsAPI.createStripePaymentMethod(
            {},
            { signal },
          ).then((res) => ({
            publicKey: res.publicKey,
            clientSecret: res.setupIntentClientSecret,
          }));
        } else if (P.isNullable(res.clientSecret)) {
          res = await PaymentsAPI.getOrCreatePaymentIntent(
            {
              applicationGroupId: applicationGroup?.id,
            },
            { signal },
          ).then((res) => ({
            clientSecret: res.paymentIntentClientSecret,
            stripeAccount: res.stripeAccountId,
            publicKey: res.stripePublicKey,
          }));
        }
      }
      if (P.isNullable(res.publicKey) || P.isNullable(res.clientSecret)) {
        throw new Error("Stripe public key not found");
      }
      const knownPubKey = res.publicKey;
      setStripe((prev) => {
        //use the known public key so that TS doesn't think the value can be unset before this closure is called
        return (
          prev ?? loadStripe(knownPubKey, { stripeAccount: res.stripeAccount })
        );
      });

      return {
        clientSecret: res.clientSecret,
        publicKey: res.publicKey,
        stripeAccount: res.stripeAccount,
      };
    },
    queryKey: ["PaymentsAPI.getStripeKeys", applicationGroup?.id] as const, //disable once stripe has been set. We don't want to re-init the stripe payment
  });

  const handleSelectCard = useCallback(
    (card: CreditCardType) => {
      if (P.isNotNullable(applicationGroup) && P.isNotNullable(application)) {
        setPaymentMethod({
          applicantUserId: application.applicant.userId,
          applicationGroupId: applicationGroup.id,
          paymentMethodId: card.id,
        });
        refresh();
      }
    },
    [setPaymentMethod, applicationGroup, application, refresh],
  );

  const [stripeInputComplete, setStripeInputComplete] =
    useState<boolean>(false);
  /**
   * pay is a function that will be populated by the PaymentCapture component.
   * it will be called when the 'make payment' button is clicked, and it will execute a confirmPayment or confirmSetup
   * depending on how the payment is being made.
   */
  const pay = useRef<PaymentCaptureType>(() => Promise.reject());

  /**
   * if the 'paidFee' status is false, the application will also include
   * stripe payment intent info to complete the uncaptured payment
   */
  const paidFee =
    P.isNotNullable(application) && isStepComplete.FEE(application);

  const [activePaymentOption] = useState<"bank" | "card" | null>("card");

  /**
   * expectation: this function can only be called when the 'make payment' button has been _enabled_
   * which only occurs when `stripeInputComplete` is true, or when `bankAccounts.length>0`.
   *
   * Since linking a BankAccount hides the credit card form, we need to perform the same conditional here
   * to avoid charging a card if the card fields are filled and then a bank account is linked- In that case, we should
   * prefer to use the BankAccount and clear the card form.
   */
  const handlePay = useCallback(async () => {
    //Skip trying to pay if already paid
    if (paidFee) {
      onRequestNext();
      return;
    }

    setLoading(true);
    try {
      if (activePaymentOption === "card") {
        if (P.isNullable(pay.current) || !stripeInputComplete) {
          throw new Error("Card input is incomplete");
        }

        const payment = await pay.current(pm?.chargeApplicationFeeOnScreening);

        if (
          payment.paymentIntent?.status === "succeeded" ||
          payment.error?.payment_intent?.status === "succeeded"
        ) {
          await pollForPaymentProcessing(unit?.unitId, user?.userId);
        } else if (
          payment.setupIntent?.status === "succeeded" ||
          payment.error?.setup_intent?.status === "succeeded"
        ) {
          let cards: CreditCardType[] = [];
          if (P.isNullable(payment.error)) {
            cards = await pollForSetupProcessing(stripeCards?.length ?? 0);
          }
          const newCard = cards.find(
            (card) => card.externalId === payment.setupIntent?.payment_method,
          );

          //reset the form and get ready to accept a new card.
          //This lets us use the same Stripe form for multiple setupIntents, but the new setupIntentId needs to be fetched first
          await reinitializeIntent();
          await refetchCards();

          //If we are missing any of the required data, we need to throw an error
          //This should never happen as by this point we should have all the required data
          //this block is to satisfy TS
          if (
            P.isNullable(applicationGroup) ||
            P.isNullable(application) ||
            P.isNullable(newCard)
          ) {
            throw new Error("Unable to set payment method");
          }

          await setPaymentMethod({
            applicantUserId: application?.applicant.userId,
            applicationGroupId: applicationGroup?.id,
            paymentMethodId: newCard?.id,
          });
        } else if (P.isNotNullable(payment.error)) {
          //If we errored, we need to throw the error
          throw new Error(payment.error.message ?? "Processing error");
        }

        onRequestNext();
      } else {
        throw new Error("No payment option is selected");
      }
    } catch (err) {
      fail(err);
      refresh();
    } finally {
      setLoading(false);
    }
  }, [
    activePaymentOption,
    application,
    applicationGroup,
    onRequestNext,
    paidFee,
    pm?.chargeApplicationFeeOnScreening,
    refetchCards,
    reinitializeIntent,
    setPaymentMethod,
    refresh,
    stripeCards?.length,
    stripeInputComplete,
    unit?.unitId,
    user,
  ]);

  const buttonText = paidFee
    ? "Continue"
    : `${pm?.chargeApplicationFeeOnScreening ? "Add Payment Method" : "Make Payment"}`;

  const total: Money$.Money = Money$.add(
    Money$.of(applicationGroup?.feeAmount),
    Money$.of(applicationGroup?.holdingFeeAmount),
  );

  return (
    <div className={commonStyles.stepContainer}>
      <div className={commonStyles.indented}>
        <div
          className={clsx(commonStyles.stepHeader, commonStyles.spanFullWidth)}
          ref={titleRef}>
          <div className={commonStyles.navBack}>
            <Button
              variant={ButtonVariant.transparent}
              leftSection={<IconChevronLeft size={16} />}
              onClick={onRequestPrevious}>
              Back
            </Button>
          </div>
          <H1>Application Fee</H1>
          {progress}
        </div>
        {paidFee ? (
          <div className={commonStyles.spanFullWidth}>
            <Text>
              {application?.paymentInformation.feeStatus === "PAID"
                ? "Your application fee has been processed."
                : "Your payment was submitted but has not yet been processed."}
            </Text>
          </div>
        ) : (
          <>
            <div
              className={clsx(
                commonStyles.stepDescription,
                commonStyles.spanFullWidth,
              )}>
              <Text>
                Upon submission of your application, you will be required to pay
                the application fee. We accept American Express, Discover,
                Mastercard and Visa credit cards. If payment information is not
                provided, your application will not be processed.
              </Text>
            </div>
            <EnderTable className={clsx(commonStyles.spanFullWidth)}>
              <thead>
                <tr>
                  <th>Charge Description</th>
                  <th>Amount Owed</th>
                </tr>
              </thead>
              <tbody style={{ fontWeight: 400 }}>
                <tr>
                  <td>Application Fee</td>
                  <td>
                    {Money$.ofOrNull(
                      applicationGroup?.feeAmount,
                    )?.toFormatted()}
                  </td>
                </tr>
                {P.isNotNullable(applicationGroup?.holdingFeeAmount) && (
                  <tr>
                    <td>
                      <Group spacing={Spacing.xs}>
                        Holding Fee
                        <Tooltip label={holdingFeeDescription}>
                          <IconInfoCircle color="var(--color-primary)" />
                        </Tooltip>
                      </Group>
                    </td>
                    <td>
                      {Money$.ofOrNull(
                        applicationGroup.holdingFeeAmount,
                      )?.toFormatted()}
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot style={{ fontWeight: 600 }}>
                <tr>
                  <td>Total</td>
                  <td>{total.toFormatted()}</td>
                </tr>
              </tfoot>
            </EnderTable>
            <div className={commonStyles.spanFullWidth}>
              <Card>
                <Group>
                  <EnderRadio
                    styles={{ inner: { alignSelf: "center" } }}
                    checked={activePaymentOption === "card"}
                    size="xs"
                  />
                  <H3>Credit/Debit Payment</H3>
                </Group>
                <div className={commonStyles.spanFullWidth}>
                  <Stack>
                    {stripeCards && (
                      <Group>
                        {stripeCards.map((card) => (
                          <div
                            style={{ position: "relative" }}
                            key={card.id}
                            onClick={() => handleSelectCard(card)}>
                            <CreditCard
                              size="sm"
                              mask={card.last4}
                              //@ts-expect-error toJson() makes the response different
                              expMonth={card.expMonth}
                              //@ts-expect-error toJson() makes the response different
                              expYear={card.expYear}
                              nickname={card.nickname}
                              title={card.brand}
                            />
                            <EnderRadio
                              checked={
                                card.id ===
                                application?.paymentInformation
                                  .feePaymentMethodId
                              }
                              style={{
                                pointerEvents: "none",
                                position: "absolute",
                                right: 8,
                                top: 8,
                              }}
                            />
                          </div>
                        ))}
                      </Group>
                    )}
                    {P.isNotNullable(stripe) && P.isNotNullable(stripeKeys) && (
                      <StripeElements
                        stripe={stripe}
                        options={{
                          ...stripeStyles,
                          clientSecret: stripeKeys.clientSecret,
                        }}>
                        <PaymentElement
                          onChange={({ complete }) => {
                            setStripeInputComplete(complete);
                          }}
                          onLoadError={(event: {
                            elementType: "payment";
                            error: StripeError;
                          }) => {
                            fail(event.error.message);
                          }}
                        />
                        {/* captures stripe elements context and sets the `pay` ref to point to the payment function */}
                        <PaymentCapture ref={pay} />
                      </StripeElements>
                    )}
                  </Stack>
                </div>
              </Card>
            </div>
          </>
        )}
      </div>
      <div className={commonStyles.backButton}>
        <Button
          variant={ButtonVariant.transparent}
          leftSection={<IconChevronLeft size={16} />}
          onClick={onRequestPrevious}>
          Back
        </Button>
      </div>
      <div className={commonStyles.submitButton}>
        <Button
          type="button"
          loading={loading}
          disabled={!paidFee && (!activePaymentOption || !stripeInputComplete)}
          onClick={handlePay}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export { ApplyStepFee };
