import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyUploadDataModeValues = [
  "OVERWRITE",
  "UPDATE",
  "MULTI_MSA",
  "VENDOR",
] as const;

const PropertyUploadDataModeEffectSchema = Schema.Literal(
  ...PropertyUploadDataModeValues,
);

type PropertyUploadDataMode = Schema.Schema.Type<
  typeof PropertyUploadDataModeEffectSchema
>;

const PropertyUploadDataModeEnum = castEnum(PropertyUploadDataModeEffectSchema);

function randomPropertyUploadDataMode(): PropertyUploadDataMode {
  return rand(PropertyUploadDataModeValues);
}

export {
  PropertyUploadDataModeEffectSchema,
  PropertyUploadDataModeEnum,
  PropertyUploadDataModeValues,
  randomPropertyUploadDataMode,
};
export type { PropertyUploadDataMode };
