import { IconDoor } from "@tabler/icons-react";
import { Predicate as P } from "effect";

import { Money$ } from "@ender/shared/core";
import { Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { getBathsDistributionText } from "@ender/shared/utils/unit";

function UnitInfo({ lease }: { lease: LeaseSerializerLeaseResponse }) {
  if (P.isNullable(lease.baseRent) || P.isNullable(lease.unit)) {
    return;
  }

  const { rentPerSqftPerMonth, rentPerSqftPerYear } = lease;

  const bedroomsAmountText = `${lease.unit.bedrooms} bed`;
  const bathsAmountText = getBathsDistributionText(
    lease.unit.fullBaths,
    lease.unit.halfBaths,
  );

  return (
    <Stack>
      <Group spacing={Spacing.sm}>
        <IconDoor size={24} color="var(--color-slate-600)" />
        <H2>Unit Info</H2>
      </Group>
      <Grid spacingY={Spacing.none}>
        <Tuple
          label="Property Name"
          value={lease?.property?.name || lease.unit.address.name}
        />
        {lease.property?.friendlyId ? (
          <Tuple label="Property ID" value={lease.property.friendlyId} />
        ) : (
          <Tuple label="Address" value={lease.unit.address.street} />
        )}
        <Tuple
          label="Bed/Bath"
          value={`${bedroomsAmountText} / ${bathsAmountText}`}
        />
        <Tuple label="Square Foot" value={lease.unit.sqft} />
        <Tuple
          label="sqft/month"
          value={
            <MoneyDisplay
              showSymbol
              value={Money$.parse(rentPerSqftPerMonth)}
            />
          }
        />
        <Tuple
          label="sqft/yr"
          value={
            <MoneyDisplay showSymbol value={Money$.parse(rentPerSqftPerYear)} />
          }
        />
      </Grid>
    </Stack>
  );
}

export { UnitInfo };
