import {
  TabButton,
  TabButtonVariant,
  Tabs,
  TabsList,
} from "@ender/shared/ds/tabs";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";

function SelectPaymentType({
  onChange,
  value,
}: {
  onChange: (val: MoneyTransferTransferType) => void;
  value: MoneyTransferTransferType;
}) {
  return (
    <Tabs onChange={onChange} value={value}>
      <TabsList variant={TabButtonVariant.outlined}>
        <TabButton value={MoneyTransferTransferTypeEnum.MARK_PAID_CHECK}>
          Check
        </TabButton>
        <TabButton value={MoneyTransferTransferTypeEnum.BANK_TRANSFER}>
          Bank Transfer
        </TabButton>
      </TabsList>
    </Tabs>
  );
}

export { SelectPaymentType };
