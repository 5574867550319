import { Schema } from "@effect/schema";
import { IconArrowBackUp, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clsx } from "clsx";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  pipe,
} from "effect";
import { useContext, useEffect, useMemo, useState } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type {
  OptimizedLedgerEvent,
  OptimizedLedgerEventAllocation,
} from "@ender/shared/contexts/ledger";
import {
  LedgerActions,
  LedgerContext,
  LedgerRightRailViewEnum,
} from "@ender/shared/contexts/ledger";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$, randomEnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal } from "@ender/shared/ds/modal";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { Text, TextColor } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { useForm } from "@ender/shared/forms/hooks/general";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type { TenantLedgerReportLedgerEntry } from "@ender/shared/generated/ender.arch.accounting";
import { TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum } from "@ender/shared/generated/ender.arch.accounting";
import type { GLCategory } from "@ender/shared/generated/ender.model.accounting";
import { CategoryFlagEnum } from "@ender/shared/generated/ender.model.accounting";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertTitleCaseToSnakeCase } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

import { LedgerDetailsTuple } from "../../../../widgets/ledger-details-tuple/widgets-finance-ledger-details-tuple";
import { LedgerEventAttachmentBtn } from "../../../../widgets/ledger-event-attachment-btn/ledger-event-attachment-btn";
import { LedgerEventAttachments } from "../ledger-event-attachments/ledger-event-attachments";
import { LedgerEventPrintBtn } from "./ledger-event-print-btn";
import { LedgerEventReverseCreditButton } from "./ledger-event-reverse-credit-button/ledger-event-reverse-credit-button";
import { ReversedPaymentModal } from "./reversed-payment-modal";

import styles from "./ledger-payment.module.css";

const ReverseRefundFormSchema = Schema.Struct({
  date: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Reversal Date is required." }),
  ),
});

type ReverseRefundFormValues = Schema.Schema.Type<
  typeof ReverseRefundFormSchema
>;

type ReverseRefundButtonProps = {
  leaseId: EnderId;
  close: () => void;
  selectedLedgerEvent: OptimizedLedgerEvent;
};

function ReverseRefundButton(props: ReverseRefundButtonProps) {
  const { leaseId, close, selectedLedgerEvent } = props;
  const { hasPermissions } = useContext(UserContext);
  const hasTenantRefundPermission = hasPermissions(
    FunctionalPermissionEnum.REFUND_TENANT,
  );
  const [refundModalOpen, refundModalOpenHandlers] = useBoolean();

  const form = useEffectSchemaForm({
    defaultValues: {
      date: LocalDate$.parse(LocalDate$.today()),
    },
    schema: ReverseRefundFormSchema,
  });

  async function handleReverseRefund(values: ReverseRefundFormValues) {
    if (P.isNull(selectedLedgerEvent?.id)) {
      fail("Selected ledger event is null");
      return;
    }
    await TenantLedgerAPI.reverseTenantRefund({
      leaseId,
      refundMoneyTransferId: selectedLedgerEvent.id,
      reversalDate: O.getOrThrow(values.date).toJSON(),
    });
    close();
    refundModalOpenHandlers.setFalse();
    showSuccessNotification({
      message: "Refund reversed successfully",
    });
    window.location.reload();
  }

  const canReverseRefund =
    hasTenantRefundPermission &&
    !selectedLedgerEvent.isReversed &&
    !selectedLedgerEvent.isCreditReversal &&
    !selectedLedgerEvent.isChargeReversal;

  const reverseRefundDisabledTooltip = useMemo(() => {
    if (!hasTenantRefundPermission) {
      return "You do not have permission to reverse this refund";
    }
    if (selectedLedgerEvent.isReversed) {
      return "Refund has already been reversed.";
    }
    if (
      selectedLedgerEvent.isCreditReversal ||
      selectedLedgerEvent.isChargeReversal
    ) {
      return "This transaction reverses a previous one and cannot be reversed again.";
    }
  }, [hasTenantRefundPermission, selectedLedgerEvent]);

  return (
    <>
      <Modal
        title="Reverse Refund"
        opened={refundModalOpen}
        onClose={refundModalOpenHandlers.setFalse}>
        <Form form={form} onSubmit={handleReverseRefund}>
          <Stack>
            <Text>
              Processing a reversal will create offsetting entries for this
              refund. The reversing entry will appear on both the General Ledger
              and the Tenant Ledger
            </Text>
            <Text>
              This action will revert the associated payable to the last step of
              the AP approval chain. It will also remove the Ender Txn row from
              bank rec.
            </Text>
            <FormDateInput label="Reversal Date" form={form} name="date" />
            <Group justify={Justify.end}>
              <Button type="submit">Confirm</Button>
            </Group>
          </Stack>
        </Form>
      </Modal>
      <Tooltip
        label="You do not have permission to reverse"
        disabled={hasTenantRefundPermission}>
        <Button
          variant={ButtonVariant.outlined}
          color={Color.red}
          disabled={!canReverseRefund}
          disabledTooltip={reverseRefundDisabledTooltip}
          leftSection={<IconArrowBackUp />}
          onClick={refundModalOpenHandlers.setTrue}>
          Reverse Refund
        </Button>
      </Tooltip>
    </>
  );
}

type LedgerPaymentViewProps = {
  selectedLedgerEvent: OptimizedLedgerEvent;
  categories: TenantLedgerReportLedgerEntry[];
  prepaymentCategory: GLCategory;
  endingPrepaymentsBalance: Money$.Money;
  onFormDirtyChange?: (isDirty: boolean) => void;
  leaseId: EnderId;
};

function LedgerPaymentView(props: LedgerPaymentViewProps) {
  const { dispatch } = useContext(LedgerContext);
  const {
    selectedLedgerEvent,
    categories,
    prepaymentCategory,
    onFormDirtyChange,
    endingPrepaymentsBalance,
    leaseId,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
  const { hasPermissions } = useContext(UserContext);
  const userHasReversePaymentPermissions = hasPermissions(
    FunctionalPermissionEnum.REVERSE_TENANT_PAYMENT,
  );

  const { prepaymentAmount, nonPrepaymentAllocations } = useMemo(() => {
    let _prepaymentAmount = Money$.zero();
    const _nonPrepaymentAllocations: ({
      category?: TenantLedgerReportLedgerEntry;
    } & OptimizedLedgerEventAllocation)[] = [];

    selectedLedgerEvent.allocations.forEach((allocation) => {
      const category = categories.find(
        ({ id }) => id === allocation.glCategoryId,
      );
      if (category?.id === prepaymentCategory?.id) {
        _prepaymentAmount = Money$.add(
          _prepaymentAmount,
          allocation.effectOnCategoryBalance,
        );
      } else {
        _nonPrepaymentAllocations.push({
          ...allocation,
          category,
        });
      }
    });

    return {
      nonPrepaymentAllocations: _nonPrepaymentAllocations,
      prepaymentAmount: _prepaymentAmount,
    };
  }, [selectedLedgerEvent.allocations, categories, prepaymentCategory?.id]);

  const form = useForm({
    initialValues: {
      allocations: [] as {
        amount: O.Option<Money$.Money>;
        glCategoryId: O.Option<EnderId>;
        uuid: EnderId;
      }[],
    },
    validate: {
      allocations: {
        amount: (value) => (O.isSome(value) ? null : "Amount is required"),
        glCategoryId: (value) =>
          O.isSome(value) ? null : "Select a charge type",
      },
    },
  });

  const {
    setFieldValue,
    values: { allocations },
  } = form;
  const allocationsRef = useRefLatest(allocations);

  // lazy useEffect
  useEffect(() => {
    setIsEditing(false);
    setFieldValue("allocations", []);
  }, [selectedLedgerEvent, allocationsRef, setFieldValue]);

  const { insertListItem } = form;
  // lazy useEffect
  useEffect(() => {
    if (!isEditing) {
      return;
    }

    for (const allocation of nonPrepaymentAllocations) {
      insertListItem("allocations", {
        amount: O.map(allocation.amount, Money$.abs),
        glCategoryId: allocation.glCategoryId,
        uuid: randomEnderId(),
      });
    }
  }, [nonPrepaymentAllocations, isEditing, insertListItem]);

  const { reset } = form;
  // lazy useEffect
  useEffect(() => {
    if (!isEditing) {
      reset();
    }
  }, [nonPrepaymentAllocations, isEditing, reset]);

  // lazy useEffect
  useEffect(() => {
    // @ts-expect-error can not take undefined as param
    onFormDirtyChange(form.isDirty());
  }, [form, onFormDirtyChange]);

  const {
    workingPrepaymentAmount,
    workingPrepaymentBalance,
    unusedNonPrepaymentCategories,
  } = useMemo(() => {
    // working category balance for an allocation row is:
    // Actual category balance + allocation amount before changes - form input amount

    // working balance for prepayments is:
    // Actual prepayments balance + prepayment allocation amount before changes - formPrepayment amount

    const _workingPrepaymentAmount = pipe(
      form.values.allocations,
      A.map((a) => a.amount),
      O.reduceCompact(
        O.getOrElse(selectedLedgerEvent.amount, Money$.zero),
        Money$.subtract,
      ),
    );

    const _workingPrepaymentBalance = pipe(
      endingPrepaymentsBalance,
      Money$.add(_workingPrepaymentAmount),
      Money$.subtract(prepaymentAmount),
    );

    return {
      unusedNonPrepaymentCategories: categories.filter(
        (category) =>
          category.id !== prepaymentCategory.id &&
          form.values.allocations.every(
            ({ glCategoryId }) => !O.contains(glCategoryId, category.id),
          ),
      ),
      workingPrepaymentAmount: _workingPrepaymentAmount,
      workingPrepaymentBalance: _workingPrepaymentBalance,
    };
  }, [
    form.values.allocations,
    categories,
    endingPrepaymentsBalance,
    prepaymentAmount,
    prepaymentCategory.id,
    selectedLedgerEvent.amount,
  ]);

  const formAllocationsWithCategoryInfo = useMemo(
    () =>
      form.values.allocations.map(({ glCategoryId, amount, uuid }) => ({
        amount,
        endingBalanceForCategory:
          // @ts-expect-error id prop does not exist in category
          categories.find(({ id }) => id === glCategoryId)?.endingBalance || 0,
        glCategoryId,
        uuid,
      })),
    [categories, form.values.allocations],
  );

  const submitDisabledTooltipMessage = useMemo(() => {
    if (Money$.isNegative(workingPrepaymentAmount)) {
      return "Cannot save over-allocated payment";
    }

    return "";
  }, [workingPrepaymentAmount]);

  function onAllocationAmountChange(
    amount: O.Option<Money$.Money>,
    index: number,
  ) {
    form.clearFieldError(`allocations.${index}.amount`);
    form.setFieldValue(`allocations.${index}`, {
      ...form.values.allocations[index],
      amount,
    });
  }

  // @ts-expect-error need to correctly define item prop
  function onCategoryChange(e, item, index) {
    form.clearFieldError(`allocations.${index}.glCategoryId`);
    form.setFieldValue(`allocations.${index}`, {
      ...item,
      glCategoryId: e.currentTarget.value,
    });
  }

  // @ts-expect-error need to correctly define formAllocation prop
  function renderFormAllocationCategorySection(formAllocation, index: number) {
    return (
      <div>
        <select
          value=""
          onChange={(e) => onCategoryChange(e, formAllocation, index)}>
          <option value="" disabled>
            {form.values.allocations[index].glCategoryId
              ? // @ts-expect-error object could be undefined
                categories.find(
                  (cat) =>
                    // @ts-expect-error object could be undefined
                    cat.id === form.values.allocations[index].glCategoryId,
                  // @ts-expect-error object could be undefined
                ).accountName
              : "Add Charge Type"}
          </option>

          {unusedNonPrepaymentCategories
            // TODO ENDER-4432 this needs to use enums.
            // @ts-expect-error flag prop does not exist in unusedNonPrepaymentCategories
            .filter(({ flags }) => {
              const { TENANT_CHARGE_TYPE, SECURITY_DEPOSITS } =
                CategoryFlagEnum;
              for (const flag of flags) {
                const snakeCaseFlag = convertTitleCaseToSnakeCase(flag);
                if (
                  [TENANT_CHARGE_TYPE, SECURITY_DEPOSITS].includes(
                    // @ts-expect-error type mismatch between snakeCaseFlag and type of CategoryFlagEnum
                    snakeCaseFlag,
                  )
                ) {
                  return true;
                }
              }
              return false;
            })
            .map((category) => (
              <option key={category.id} value={category.id}>
                {/* @ts-expect-error accountName prop does not exist in category */}
                {category.accountName}
              </option>
            ))}
        </select>
      </div>
    );
  }

  function onChangeAllocationsClick() {
    setIsEditing(true);
  }

  function onDeleteAllocationItem(index: number) {
    form.clearFieldError(`allocations.${index}.amount`);
    form.clearFieldError(`allocations.${index}.glCategoryId`);
    form.removeListItem("allocations", index);
  }

  function onAddChargeTypeClick() {
    form.insertListItem("allocations", {
      amount: "",
      glCategoryId: "",
      uuid: randomEnderId(),
    });
  }

  function onCancelClick() {
    setIsEditing(false);
  }

  const { mutateAsync: manuallyAllocateTenantInflow } = useMutation({
    mutationFn: TenantLedgerAPI.manuallyAllocateTenantInflow,
    mutationKey: ["TenantLedgerAPI.manuallyAllocateTenantInflow"] as const,
  });

  async function onAllocationsSubmit() {
    if (form.validate().hasErrors) {
      return;
    }

    await manuallyAllocateTenantInflow({
      allocations: [
        ...form.values.allocations.map(({ glCategoryId, amount }) => ({
          amount: pipe(
            amount,
            O.map((v) => v.toJSON()),
            O.getOrThrow,
          ),
          categoryId: O.getOrThrow(glCategoryId),
          historical: false,
        })),
        {
          amount:
            workingPrepaymentAmount?.toJSON() ?? ("" as Money$.Serialized),
          categoryId: prepaymentCategory.id,
          historical: false,
        },
      ],
      leaseId,
      modelId: selectedLedgerEvent.id,
      modelType: selectedLedgerEvent.ledgerEventType,
    });
    setIsEditing(false);
    dispatch({
      payload: { leaseId },
      type: LedgerActions.FETCH_LEDGER,
    });
  }

  const formPrepaymentRowClassNames = clsx({
    "ledger-payment__allocations-list-item": true,
    [styles.ledgerPaymentGridRow]: true,
    [styles.ledgerPaymentGridRowEdit]: true,
    "ledger-payment__over-allocated": Money$.isNegative(
      workingPrepaymentAmount,
    ),
  });

  const queryClient = useQueryClient();

  const reversePaymentDisabledTooltip = useMemo(() => {
    if (selectedLedgerEvent.isReversed) {
      return "This payment was already marked reversed.";
    }

    if (!userHasReversePaymentPermissions) {
      return "You do not have permission to reverse this payment";
    }
  }, [selectedLedgerEvent.isReversed, userHasReversePaymentPermissions]);

  const isRefund =
    selectedLedgerEvent?.tenantLedgerEventType ===
    TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.REFUND;

  return (
    <div>
      <Stack spacing={Spacing.lg}>
        <Group spacing={Spacing.xs}>
          <LedgerEventPrintBtn
            leaseId={leaseId}
            ledgerEvent={selectedLedgerEvent}
          />
          <LedgerEventAttachmentBtn
            onSuccess={async () => {
              dispatch({
                payload: { leaseId },
                type: LedgerActions.FETCH_LEDGER,
              });
              await queryClient.invalidateQueries(["ledgerEventAttachments"]);
            }}
            ledgerEvent={selectedLedgerEvent}
          />
          {isRefund && (
            <ReverseRefundButton
              close={close}
              leaseId={leaseId}
              selectedLedgerEvent={selectedLedgerEvent}
            />
          )}
          {selectedLedgerEvent?.tenantLedgerEventType ===
            TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.CREDIT && (
            <LedgerEventReverseCreditButton
              leaseId={leaseId}
              ledgerEvent={selectedLedgerEvent}
            />
          )}
        </Group>
        <LedgerDetailsTuple
          amount={O.getOrElse(selectedLedgerEvent?.amount, Money$.zero)}
          transactionDate={selectedLedgerEvent?.generalLedgerDate}
          accountingDate={selectedLedgerEvent?.accountingDate}
          systemDate={selectedLedgerEvent?.systemDate}
          description={selectedLedgerEvent?.description}
          source={selectedLedgerEvent?.source}
          author={selectedLedgerEvent?.authorDisplay}
        />
      </Stack>
      <div className={styles.ledgerPaymentViewList}>
        {!isEditing && (
          <div>
            <div
              className={`${styles.ledgerPaymentGridRow} ${styles.ledgerPaymentAllocationsHeader}`}>
              <div>Charge Type</div>
              <Group justify={Justify.end}>Allocations</Group>
            </div>
            <div className={styles.ledgerPaymentAllocationsList}>
              {nonPrepaymentAllocations.map((allocation) => (
                <div
                  key={allocation.id}
                  className={`${styles.ledgerPaymentAllocationsListItem} ${styles.ledgerPaymentGridRow}`}>
                  <div>
                    {
                      //@ts-expect-error possibly a mis-typed API or response
                      allocation.category?.accountName
                    }
                  </div>
                  <Group justify={Justify.end}>
                    <MoneyDisplay
                      value={O.map(allocation.amount, Money$.negate)}
                    />
                  </Group>
                </div>
              ))}

              <div
                className={`${styles.ledgerPaymentAllocationsListItem} ${styles.ledgerPaymentGridRow}`}>
                <div>{prepaymentCategory.accountName}</div>
                <Group justify={Justify.end}>
                  <MoneyDisplay value={prepaymentAmount} />
                </Group>
              </div>
            </div>
            <Group justify={Justify.between}>
              {selectedLedgerEvent.tenantLedgerEventType ===
              TenantLedgerReportLedgerEntryTenantLedgerEventTypeEnum.PAYMENT ? (
                <Button
                  disabled={!!reversePaymentDisabledTooltip}
                  disabledTooltip={reversePaymentDisabledTooltip}
                  onClick={open}>
                  Reverse Payment
                </Button>
              ) : (
                <div></div>
              )}
              {selectedLedgerEvent.canBeReallocated ? (
                <Button onClick={onChangeAllocationsClick}>
                  Change Allocations
                </Button>
              ) : (
                <Button
                  disabled
                  disabledTooltip="The ability to change allocations for this ledger entry is temporarily disabled. Please contact Ender OPS to change these allocations.">
                  Change Allocations
                </Button>
              )}
            </Group>
          </div>
        )}
        {isEditing && (
          <div>
            <div
              className={`${styles.ledgerPaymentAllocationsHeader} ${styles.ledgerPaymentGridRow} ${styles.ledgerPaymentGridRowEdit}`}>
              <div>Charge Type</div>
              <Group justify={Justify.end}>Allocations</Group>
              <Group justify={Justify.end}>Current Balance</Group>
            </div>
            <div className={styles.ledgerPaymentAllocationsList}>
              {formAllocationsWithCategoryInfo.map((item, index) => (
                <div
                  key={item.uuid}
                  className={`${styles.ledgerPaymentAllocationsListItem} ${styles.ledgerPaymentGridRow} ${styles.ledgerPaymentGridRowEdit}`}>
                  <div>{renderFormAllocationCategorySection(item, index)}</div>
                  <Group justify={Justify.end}>
                    <MoneyInput
                      value={item.amount}
                      onChange={(val) => onAllocationAmountChange(val, index)}
                    />
                  </Group>
                  <Group justify={Justify.end}>
                    <MoneyDisplay value={item.endingBalanceForCategory} />
                  </Group>

                  <div
                    className={styles.ledgerPaymentListItemCloseBtn}
                    onClick={() => onDeleteAllocationItem(index)}>
                    <IconX />
                  </div>

                  <div className="error-message">
                    {form.errors[`allocations.${index}.glCategoryId`]}
                  </div>

                  <Group justify={Justify.end}>
                    <Text size="sm" color={TextColor["red-500"]}>
                      {form.errors[`allocations.${index}.amount`]}
                    </Text>
                  </Group>
                </div>
              ))}
              <div className={formPrepaymentRowClassNames}>
                <div>
                  {!Money$.isNegative(workingPrepaymentAmount)
                    ? prepaymentCategory.accountName
                    : "Over Allocated"}
                </div>
                <Group justify={Justify.end}>
                  <MoneyDisplay value={workingPrepaymentAmount ?? UNDEFINED} />
                </Group>
                <Group justify={Justify.end}>
                  {!Money$.isNegative(workingPrepaymentAmount) && (
                    <MoneyDisplay value={workingPrepaymentBalance} />
                  )}
                </Group>
              </div>
            </div>
            <Group justify={Justify.between}>
              <Button onClick={onAddChargeTypeClick}>Add Charge Type</Button>
              <Group>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button
                  disabled={!!submitDisabledTooltipMessage}
                  disabledTooltip={submitDisabledTooltipMessage}
                  onClick={onAllocationsSubmit}>
                  Save Allocations
                </Button>
              </Group>
            </Group>
          </div>
        )}
        <LedgerEventAttachments />
      </div>
      <Modal title="" opened={isOpen} onClose={close}>
        <ReversedPaymentModal
          leaseId={leaseId}
          // @ts-expect-error type mismatching
          ledgerEntry={selectedLedgerEvent}
          onSuccess={async () => {
            dispatch({
              payload: { leaseId },
              type: LedgerActions.FETCH_LEDGER,
            });
          }}
          closeModal={close}
        />
      </Modal>
    </div>
  );
}

// TODO why is this file so big please stop
function LedgerPayment({
  onFormDirtyChange = F.constVoid,
  leaseId,
}: {
  onFormDirtyChange?: (isDirty: boolean) => void;
  leaseId: EnderId;
}) {
  const {
    selectedLedgerEvent,
    categories,
    prepaymentCategory,
    endingPrepaymentsBalance,
    dispatch,
    ledgerRightRailView,
  } = useContext(LedgerContext);

  // Because of our current crappy architecture, we have to reactively respond to the value changes to close the right rail, otherwise we crash from
  // the undefined values. This is a hack to prevent that crash. We should fix this.
  useEffect(() => {
    if (
      (ledgerRightRailView !== LedgerRightRailViewEnum.NONE &&
        P.isNullable(selectedLedgerEvent)) ||
      P.isNullable(categories) ||
      P.isNullable(prepaymentCategory) ||
      P.isNullable(endingPrepaymentsBalance)
    ) {
      dispatch({
        type: LedgerActions.CLOSE_RIGHT_RAIL,
        payload: {},
      });
    }
  }, [
    selectedLedgerEvent,
    categories,
    prepaymentCategory,
    endingPrepaymentsBalance,
    dispatch,
    ledgerRightRailView,
  ]);

  if (
    P.isNullable(selectedLedgerEvent) ||
    P.isNullable(categories) ||
    P.isNullable(prepaymentCategory) ||
    P.isNullable(endingPrepaymentsBalance)
  ) {
    return NULL;
  }

  return (
    <LedgerPaymentView
      // @ts-expect-error type mismatching
      selectedLedgerEvent={selectedLedgerEvent}
      categories={categories}
      // @ts-expect-error type mismatching
      prepaymentCategory={prepaymentCategory}
      endingPrepaymentsBalance={endingPrepaymentsBalance}
      onFormDirtyChange={onFormDirtyChange}
      leaseId={leaseId}
    />
  );
}

export { LedgerPayment };
