import { fail } from "@ender/shared/utils/error";
import { showLoadingNotification } from "@ender/shared/utils/notifications";
import { rest } from "@ender/shared/utils/rest";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Plaid: unknown;
    socket: {
      send: (data: unknown) => void;
    };
  }
}

function log(data: unknown, source: string) {
  const payload = {
    data: data,
    logSource: source,
  };

  if (typeof window.socket !== "undefined") {
    window.socket.send({
      command: "log",
      payload: payload,
    });
  } else {
    // TODO move this so it doesn't have a recursive import
    // api.post("/log", payload);
  }
}

// @ts-expect-error function props need to be correctly typed
function onExit(err, metadata) {
  if (err) {
    log(
      {
        err: err,
        metadata: metadata,
      },
      "@ender/plaid",
    );
  }
}

/// @ts-expect-error function props need to be correctly typed
function onEvent(eventName, metadata) {
  log(
    {
      eventName: eventName,
      metadata: metadata,
    },
    "@ender/plaid",
  );
}

// Import plaid if not already in window
function getPlaid() {
  return new Promise((resolve, reject) => {
    if (window.Plaid) {
      resolve(window.Plaid);
      return;
    }

    const plaidScript = document.createElement("script");
    plaidScript.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    plaidScript.id = "plaidScript";

    plaidScript.onload = () => {
      setTimeout(() => {
        resolve(window.Plaid);
      }, 0);
    };

    plaidScript.onerror = reject;
    document.head.appendChild(plaidScript);
  });
}

async function initPlaid(
  // @ts-expect-error function props need to be correctly typed
  Plaid,
  {
    // @ts-expect-error function props need to be correctly typed
    onSuccess,
    // @ts-expect-error function props need to be correctly typed
    customerId,
    // @ts-expect-error function props need to be correctly typed
    customerType,
    linkToPM = false,
    // @ts-expect-error function props need to be correctly typed
    bankAccountId,
    onlyLinkToPlaid = false,
  },
) {
  let linkToken;
  try {
    // @ts-expect-error linkToken is type of any
    ({ linkToken } = await rest.post("/plaid/createLinkToken"));
  } catch (err) {
    fail(err);
    return;
  }

  const plaidInit = Plaid.create({
    onEvent,
    onExit,
    // @ts-expect-error function props need to be correctly typed
    onSuccess: async function (_, metaData) {
      const linkReqBody = {
        ...metaData,
        customerId,
        customerType,
        linkToPM,
      };
      if (bankAccountId) {
        linkReqBody.bankAccountId = bankAccountId;
      }

      const [, closeNotification] = showLoadingNotification({
        message: "Linking bank account...",
      });
      try {
        const url = onlyLinkToPlaid
          ? "/linkBankToPlaid"
          : "/createPlaidDwollaLinkedBankAccount";
        await rest.post(url, linkReqBody);
      } catch (err) {
        fail(err);
      } finally {
        closeNotification();
      }

      if (onSuccess) {
        onSuccess();
      }
    },
    token: linkToken,
  });

  plaidInit.open();
}

// @ts-expect-error function props need to be correctly typed
async function initPlaidCredentials(data) {
  let Plaid;

  try {
    Plaid = await getPlaid();
  } catch (err) {
    fail(err);
    return;
  }

  await initPlaid(Plaid, data);
}

// @ts-expect-error function props need to be correctly typed
async function updatePlaid(Plaid, { onSuccess, accountId }) {
  let linkUpdateToken;

  try {
    // @ts-expect-error linkUpdateToken is type of any
    ({ linkUpdateToken } = await rest.post(
      `/bankAccounts/${accountId}/getLinkUpdateToken`,
    ));
  } catch (err) {
    fail(err);
    return;
  }

  const plaidUpdate = Plaid.create({
    onEvent,
    onExit,
    onSuccess: async function () {
      try {
        await rest.post(`/bankAccounts/${accountId}/onSuccessfulLinkUpdate`);
      } catch (err) {
        fail(err);
      }

      if (onSuccess) {
        onSuccess();
      }
    },
    token: linkUpdateToken,
  });

  plaidUpdate.open();
}

// @ts-expect-error function props need to be correctly typed
async function updatePlaidCredentials(data) {
  let Plaid;

  try {
    Plaid = await getPlaid();
  } catch (err) {
    fail(err);
    return;
  }

  await updatePlaid(Plaid, data);
}

export { initPlaidCredentials, updatePlaidCredentials };
