import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import { useEffectSchemaForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import { Money$ } from "@ender/shared/core";
import { PaymentPlanPaymentPlanTypeValues } from "@ender/shared/generated/ender.model.misc";

const PaymentPlanTypeSchema = Schema.Literal(
  ...PaymentPlanPaymentPlanTypeValues,
);

const emptyInstallment = { amount: O.none(), dueDate: O.none() };

const InstallmentSchema = Schema.Struct({
  amount: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Some<Money$.Money> =>
        O.exists(input, Money$.isPositive),
      { message: () => "Amount must be greater than $0.00" },
    ),
  ),
  dueDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Due Date is required",
    }),
  ),
});

const CreatePaymentPlanRequestSchema = Schema.Struct({
  type: PaymentPlanTypeSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Proposed By is required" }),
  ),
  startDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Start Date is required",
    }),
  ),
  installments: Schema.NonEmptyArray(InstallmentSchema),
});

type CreatePaymentPlanFormInput = Schema.Schema.Encoded<
  typeof CreatePaymentPlanRequestSchema
>;
type CreatePaymentPlanFormOutput = Schema.Schema.Type<
  typeof CreatePaymentPlanRequestSchema
>;

const useCreatePaymentPlanForm = () => {
  const form = useEffectSchemaForm({
    defaultValues: {
      type: O.none(),
      startDate: O.none(),
      installments: [emptyInstallment],
    },
    schema: CreatePaymentPlanRequestSchema,
  });

  return {
    form,
  };
};

export { emptyInstallment, useCreatePaymentPlanForm };
export type { CreatePaymentPlanFormInput, CreatePaymentPlanFormOutput };
