/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/showings
 * @function addShowing
 * @memberof LeasingAPI
 * @param {LeasingAPIAddShowingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  LocalTime,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIAddShowingPathParams = {
  unitId: EnderId;
};

type LeasingAPIAddShowingSearchParams = {
  token?: string | undefined;
};

type LeasingAPIAddShowingBodyParams = {
  date: SerializesInto<LocalDate>;
  leadId?: EnderId | undefined;
  prospectId: EnderId;
  time: SerializesInto<LocalTime>;
};

type LeasingAPIAddShowingPayload = LeasingAPIAddShowingPathParams &
  LeasingAPIAddShowingSearchParams &
  LeasingAPIAddShowingBodyParams;

function addShowingUnsafeEffect(payload: LeasingAPIAddShowingPayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/showings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addShowing(
  payload: LeasingAPIAddShowingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addShowingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addShowing };
export type {
  LeasingAPIAddShowingBodyParams,
  LeasingAPIAddShowingPathParams,
  LeasingAPIAddShowingPayload,
  LeasingAPIAddShowingSearchParams,
};
