import type { PropsWithChildren } from "react";
import {
  Children,
  cloneElement,
  createContext,
  isValidElement,
  useContext,
} from "react";
import { z } from "zod";

/** @deprecated This should not be used anymore. */
const FormSectionContextSchema = z.object({
  stack: z.string().array(),
});

/** @deprecated This should not be used anymore. */
type FormSectionContextType = z.infer<typeof FormSectionContextSchema>;

const FormSectionContext = createContext<FormSectionContextType>({ stack: [] });

/** @deprecated This should not be used anymore. */
const FormSectionPropsSchema = z.object({
  name: z.string(),
});

/** @deprecated This should not be used anymore. */
type FormSectionProps = z.infer<typeof FormSectionPropsSchema>;

function removeStackFromName(name: string, stack: string[]): string {
  for (const part of stack) {
    name = name.replace(`${part}.`, "");
  }
  return name;
}

/** @deprecated This should not be used anymore. */
function FormSection({ name, children }: PropsWithChildren<FormSectionProps>) {
  const { stack } = useContext(FormSectionContext);
  const newStack = [...stack, removeStackFromName(name, stack)];
  return (
    <FormSectionContext.Provider value={{ stack: newStack }}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            ...child.props,
            name: [...newStack, child.props.name].join("."),
          });
        }
        return child;
      })}
    </FormSectionContext.Provider>
  );
}

export { FormSection, FormSectionContextSchema, FormSectionPropsSchema };
export type { FormSectionContextType, FormSectionProps };
