import { Schema } from "@effect/schema";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import { Option as O, pipe } from "effect";
import type { ReactNode } from "react";
import { forwardRef } from "react";

import { Instant$ } from "@ender/shared/core";
import { castEnum } from "@ender/shared/utils/effect";
import { Color, ColorSchema } from "@ender/shared/utils/theming";

const BubbleColorSchema = ColorSchema.pipe(
  Schema.pickLiteral("primary", "red", "slate"),
);
const BubbleColorValues = BubbleColorSchema.literals;
type BubbleColors = Schema.Schema.Type<typeof BubbleColorSchema>;
const BubbleColor = castEnum(BubbleColorSchema);

const BubbleVariantGenerator = cva(
  "whitespace-pre-wrap text-left break-words rounded-lg",
  {
    defaultVariants: {
      color: Color.slate,
      corner: "none",
      image: false,
      right: false,
    },
    variants: {
      color: {
        [Color.primary]: "bg-primary-500 text-white",
        [Color.red]: "bg-red-500 text-white",
        [Color.slate]: "bg-slate-50 text-slate-900",
      },
      corner: {
        "bottom-left": "rounded-bl-none",
        "bottom-right": "rounded-br-none",
        none: "",
        "top-left": "rounded-tl-none",
        "top-right": "rounded-tr-none",
      },
      image: {
        false: "px-3 py-2",
        true: "p-0 overflow-hidden",
      },
      right: {
        false: "self-start",
        true: "self-end",
      },
    },
  },
);

type BubbleProps = {
  /**
   * what is shown above the message. Defaults to the sender and the timestamp
   */
  header?: ReactNode;
  message: ReactNode;
  /**
   * what is shown below the message
   */
  footer?: ReactNode;
  image?: boolean;
  /**
   * whether to hide the header. Also adds a negative margin to the top of the bubble
   * which serves to collapse the bubble with the previous one. Used for subsequent messages
   * from the same sender.
   */
  hideHeader?: boolean;
  sender?: string;
  corner?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  color?: BubbleColors;
  timestamp?: number | string;
  right?: boolean;
};

const Bubble = forwardRef<HTMLDivElement, BubbleProps>(
  function Bubble(props, ref) {
    const {
      message,
      sender,
      timestamp,
      header = (
        <>
          {sender} -{" "}
          {pipe(
            Instant$.parse(timestamp),
            O.map((v) => v.toString()),
            O.getOrElse(() => ""),
          )}
        </>
      ),
      footer,
      right,
      hideHeader = false,
    } = props;
    return (
      <div
        className={clsx("pb-0.5 max-w-3/4 flex flex-col", {
          ["-mt-4"]: hideHeader,
          ["self-end text-right"]: right,
        })}>
        {!hideHeader && header}
        <div ref={ref} className={BubbleVariantGenerator(props)}>
          {message}
        </div>
        {footer}
      </div>
    );
  },
);

export { Bubble, BubbleColor, BubbleColorSchema, BubbleColorValues };
export type { BubbleColors, BubbleProps };
