/**
 * HTTP Method: POST
 * Pathname: /markets/summaryStats
 * @function getMarketSummaryStats
 * @memberof MarketsAPI
 * @param {MarketsAPIGetMarketSummaryStatsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarketsAPIGetMarketSummaryStatsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MarketsAPIGetMarketSummaryStatsResponse } from "../markets-api-get-market-summary-stats-response";

type MarketsAPIGetMarketSummaryStatsSearchParams = {
  token?: string | undefined;
};

type MarketsAPIGetMarketSummaryStatsBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
  numDaysToLookback?: number | undefined;
};

type MarketsAPIGetMarketSummaryStatsPayload =
  MarketsAPIGetMarketSummaryStatsSearchParams &
    MarketsAPIGetMarketSummaryStatsBodyParams;

function getMarketSummaryStatsUnsafeEffect(
  payload: MarketsAPIGetMarketSummaryStatsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarketsAPIGetMarketSummaryStatsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          MarketsAPIGetMarketSummaryStatsResponse[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/markets/summaryStats",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMarketSummaryStats(
  payload: MarketsAPIGetMarketSummaryStatsPayload,
  options?: { signal?: AbortSignal },
): Promise<MarketsAPIGetMarketSummaryStatsResponse[]> {
  return F.pipe(
    payload,
    getMarketSummaryStatsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMarketSummaryStats };
export type {
  MarketsAPIGetMarketSummaryStatsBodyParams,
  MarketsAPIGetMarketSummaryStatsPayload,
  MarketsAPIGetMarketSummaryStatsSearchParams,
};
