import { Predicate as P } from "effect";
import { useCallback, useRef, useState } from "react";

const useGridDimensions = (): {
  dimensions: { columns: number; rows: number };
  domChildCount: number;
  setRef: (el: HTMLElement | null) => void;
} => {
  const [counts, setCounts] = useState({ columns: -1, rows: -1 });
  const domChildCount = useRef(0);

  const setRef = useCallback((node: HTMLElement | null) => {
    if (P.isNullable(node)) {
      return;
    }
    const cs = window.getComputedStyle(node);
    let lastColumnCount = cs
      .getPropertyValue("grid-template-columns")
      .split(" ").length;
    let lastRowCount = cs
      .getPropertyValue("grid-template-rows")
      .split(" ").length;
    setCounts({ columns: lastColumnCount, rows: lastRowCount });
    domChildCount.current = node.childNodes.length;

    const resizeObserver = new ResizeObserver(() => {
      const gridRowCount = cs
        .getPropertyValue("grid-template-rows")
        .split(" ").length;
      const gridColumnCount = cs
        .getPropertyValue("grid-template-columns")
        .split(" ").length;
      if (
        gridRowCount !== lastRowCount ||
        gridColumnCount !== lastColumnCount
      ) {
        lastColumnCount = gridColumnCount;
        lastRowCount = gridRowCount;
        setCounts({ columns: gridColumnCount, rows: gridRowCount });
      }
    });
    resizeObserver.observe(node);
  }, []);

  return { dimensions: counts, domChildCount: domChildCount.current, setRef };
};

export { useGridDimensions };
