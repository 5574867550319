import { useMantineTheme } from "@mantine/styles";
import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";

import type { Null } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type { GetBankAccountResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { BankingReportBankingReportStatsResponse } from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";
import { useMediaQuery } from "@ender/shared/hooks/use-media-query";
import type {
  EnderTableFilterParams,
  UseTableReturn,
} from "@ender/shared/ui/table-tanstack";
import {
  useTable,
  useTablePagination,
  useTableRowSelection,
  useTableSorting,
} from "@ender/shared/ui/table-tanstack";

import type {
  BankingDetailListTableData,
  BankingDetailsListTableMeta,
} from "./banking-detail-list/banking-detail-list-columns/banking-detail-list-columns";
import {
  bankingDetailsListColumns,
  bankingDetailsListColumnsSmallScreen,
} from "./banking-detail-list/banking-detail-list-columns/banking-detail-list-columns";

type UseBankingDetailTableProps = {
  bankAccountStats?: BankingReportBankingReportStatsResponse;
  data: BankingDetailListTableData[];
  columnFilters: EnderTableFilterParams<BankingDetailListTableData>;
  details?: GetBankAccountResponse;
  isFetching: boolean;
  onClearFilters: () => void;
  refreshTransactions: () => void;
  setBatchMatcherTransactionId: (value: EnderId | Null) => void;
  fetchNextPageOfTransactions: () => Promise<unknown>;
};

const PAGE_SIZE = 25;

function useBankingDetailTable(props: UseBankingDetailTableProps) {
  const {
    bankAccountStats,
    data,
    details,
    columnFilters,
    isFetching,
    onClearFilters,
    refreshTransactions,
    setBatchMatcherTransactionId,
    fetchNextPageOfTransactions,
  } = props;

  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const { rowSelection, ...rowSelectionRest } =
    useTableRowSelection<BankingDetailListTableData>();

  const tableRowSelection = useMemo(
    () => ({
      rowSelection,
      ...rowSelectionRest,
      enableMultiRowSelection: true,
      enableRowSelection: (row: Row<BankingDetailListTableData>) =>
        row.original.rowType === BankRecRowTypeEnum.UNMATCHED_ENDER,
    }),
    [rowSelection, rowSelectionRest],
  );

  const pagination = useTablePagination({
    initialPagination: { pageIndex: 0, pageSize: PAGE_SIZE },
    manualPagination: true,
  });

  const table: UseTableReturn<
    BankingDetailListTableData,
    BankingDetailsListTableMeta
  > = useTable<BankingDetailListTableData, BankingDetailsListTableMeta>({
    columnFilters,
    columns: isSmallScreen
      ? bankingDetailsListColumnsSmallScreen
      : bankingDetailsListColumns,
    data: data ?? [],
    fetchNextPage: fetchNextPageOfTransactions,
    isLoading: isFetching,
    meta: {
      details,
      refreshTransactions,
      setBatchMatcherTransactionId,
    },
    onClearFilters,
    pagination,
    rowSelection: tableRowSelection,
    sorting: useTableSorting(),
    totalResults: bankAccountStats?.total,
  });

  return { table };
}

export { PAGE_SIZE, useBankingDetailTable };
