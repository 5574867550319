/**
 * HTTP Method: POST
 * Pathname: /stripe/paymentMethods/cards/{cardId}/payment
 * @function createCardPayment
 * @memberof PaymentsAPI
 * @param {PaymentsAPICreateCardPaymentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateCardPaymentResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { CreateCardPaymentResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPICreateCardPaymentPathParams = {
  cardId: EnderId;
};

type PaymentsAPICreateCardPaymentSearchParams = {
  token?: string | undefined;
};

type PaymentsAPICreateCardPaymentBodyParams = {
  amount: SerializesInto<Money>;
  description: string;
  leaseId: string;
};

type PaymentsAPICreateCardPaymentPayload =
  PaymentsAPICreateCardPaymentPathParams &
    PaymentsAPICreateCardPaymentSearchParams &
    PaymentsAPICreateCardPaymentBodyParams;

function createCardPaymentUnsafeEffect(
  payload: PaymentsAPICreateCardPaymentPayload,
) {
  const { cardId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateCardPaymentResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateCardPaymentResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/stripe/paymentMethods/cards/${cardId}/payment`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createCardPayment(
  payload: PaymentsAPICreateCardPaymentPayload,
  options?: { signal?: AbortSignal },
): Promise<CreateCardPaymentResponse> {
  return F.pipe(
    payload,
    createCardPaymentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createCardPayment };
export type {
  PaymentsAPICreateCardPaymentBodyParams,
  PaymentsAPICreateCardPaymentPathParams,
  PaymentsAPICreateCardPaymentPayload,
  PaymentsAPICreateCardPaymentSearchParams,
};
