import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationApplicantTypeValues = [
  "RESPONSIBLE_OCCUPANT",
  "OTHER_OCCUPANT",
  "GUARANTOR",
] as const;

const ApplicationApplicantTypeEffectSchema = Schema.Literal(
  ...ApplicationApplicantTypeValues,
);

type ApplicationApplicantType = Schema.Schema.Type<
  typeof ApplicationApplicantTypeEffectSchema
>;

const ApplicationApplicantTypeEnum = castEnum(
  ApplicationApplicantTypeEffectSchema,
);

function randomApplicationApplicantType(): ApplicationApplicantType {
  return rand(ApplicationApplicantTypeValues);
}

export {
  ApplicationApplicantTypeEffectSchema,
  ApplicationApplicantTypeEnum,
  ApplicationApplicantTypeValues,
  randomApplicationApplicantType,
};
export type { ApplicationApplicantType };
