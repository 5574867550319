import { IconEdit } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import * as A from "effect/Array";

import { NULL } from "@ender/shared/constants/general";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Modal } from "@ender/shared/ds/modal";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { getInvoicePropertyIds } from "@ender/shared/utils/invoice-utils";
import { isMultiple } from "@ender/shared/utils/is";

import { NewBillbackForm } from "./new-billback-form/new-billback-form";

type AddBillBackButtonProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess: () => void;
};

function AddBillBackButton({ invoice, onSuccess }: AddBillBackButtonProps) {
  const [
    isNewBillbackFormOpen,
    { setTrue: openNewBillbackForm, setFalse: closeNewBillbackForm },
  ] = useBoolean();

  const unitId = invoice?.unit?.id;
  const propertyIds = getInvoicePropertyIds(invoice);
  const leaseRequestData = P.isNotNullable(unitId)
    ? { unitIds: [unitId] }
    : { propertyIds };

  const { data: leases } = useQuery({
    queryKey: ["LeasingAPI.searchLeases", leaseRequestData] as const,
    queryFn: ({ signal }) =>
      LeasingAPI.searchLeases(
        //@ts-expect-error TODO searchLeases expects marketIds, keyword properties which aren't assigned here
        {
          ...leaseRequestData,
          mostRelevantLeasePerUnit: true,
        },
        { signal },
      ),
  });

  const handleSuccess = () => {
    closeNewBillbackForm();
    onSuccess();
  };

  // TODO prefer disable with tooltip is isMultiple(propertyIds)
  if (
    P.isNullable(leases) ||
    A.isEmptyArray(leases) ||
    isMultiple(propertyIds)
  ) {
    return NULL;
  }

  return (
    <>
      <ActionIcon aria-label="Add Billback" onClick={openNewBillbackForm}>
        <IconEdit />
      </ActionIcon>
      <Modal
        title="New Tenant Billback"
        opened={isNewBillbackFormOpen}
        onClose={closeNewBillbackForm}>
        <NewBillbackForm
          invoice={invoice}
          leases={leases}
          onSuccess={handleSuccess}
        />
      </Modal>
    </>
  );
}

export { AddBillBackButton };
