/**
 * HTTP Method: POST
 * Pathname: /applications/moveApplication
 * @function moveApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIMoveApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIMoveApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIMoveApplicationBodyParams = {
  applicationToMoveId: EnderId;
  targetApplicationGroupId: EnderId;
};

type ApplicationsAPIMoveApplicationPayload =
  ApplicationsAPIMoveApplicationSearchParams &
    ApplicationsAPIMoveApplicationBodyParams;

function moveApplicationUnsafeEffect(
  payload: ApplicationsAPIMoveApplicationPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/applications/moveApplication",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function moveApplication(
  payload: ApplicationsAPIMoveApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    moveApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { moveApplication };
export type {
  ApplicationsAPIMoveApplicationBodyParams,
  ApplicationsAPIMoveApplicationPayload,
  ApplicationsAPIMoveApplicationSearchParams,
};
