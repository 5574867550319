/**
 * HTTP Method: POST
 * Pathname: /systemReports/Journal Entries/run
 * @function runJournalEntriesReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunJournalEntriesReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportsAPIRunJournalEntriesReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ReportsAPIRunJournalEntriesReportResponse } from "../reports-api-run-journal-entries-report-response";

type ReportsAPIRunJournalEntriesReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunJournalEntriesReportBodyParams = {
  authorFilter: EnderId[];
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  journalEntryId?: EnderId | undefined;
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  periodFilter?: PeriodFilter | undefined;
  propertyIds: EnderId[];
  startDate?: SerializesInto<LocalDate> | undefined;
};

type ReportsAPIRunJournalEntriesReportPayload =
  ReportsAPIRunJournalEntriesReportSearchParams &
    ReportsAPIRunJournalEntriesReportBodyParams;

function runJournalEntriesReportUnsafeEffect(
  payload: ReportsAPIRunJournalEntriesReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportsAPIRunJournalEntriesReportResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ReportsAPIRunJournalEntriesReportResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/Journal Entries/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runJournalEntriesReport(
  payload: ReportsAPIRunJournalEntriesReportPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIRunJournalEntriesReportResponse> {
  return F.pipe(
    payload,
    runJournalEntriesReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runJournalEntriesReport };
export type {
  ReportsAPIRunJournalEntriesReportBodyParams,
  ReportsAPIRunJournalEntriesReportPayload,
  ReportsAPIRunJournalEntriesReportSearchParams,
};
