import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SidingSidingMaterialValues = [
  "BRICK",
  "HARDIE",
  "VINYL",
  "STUCCO",
  "NATURAL_WOOD",
  "METAL",
  "OTHER",
] as const;

const SidingSidingMaterialEffectSchema = Schema.Literal(
  ...SidingSidingMaterialValues,
);

type SidingSidingMaterial = Schema.Schema.Type<
  typeof SidingSidingMaterialEffectSchema
>;

const SidingSidingMaterialEnum = castEnum(SidingSidingMaterialEffectSchema);

function randomSidingSidingMaterial(): SidingSidingMaterial {
  return rand(SidingSidingMaterialValues);
}

export {
  randomSidingSidingMaterial,
  SidingSidingMaterialEffectSchema,
  SidingSidingMaterialEnum,
  SidingSidingMaterialValues,
};
export type { SidingSidingMaterial };
