/**
 * HTTP Method: POST
 * Pathname: /vehicles/{vehicleId}/unarchive
 * @function unarchiveVehicle
 * @memberof LeasingAPI
 * @param {LeasingAPIUnarchiveVehiclePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUnarchiveVehiclePathParams = {
  vehicleId: EnderId;
};

type LeasingAPIUnarchiveVehicleSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUnarchiveVehiclePayload = LeasingAPIUnarchiveVehiclePathParams &
  LeasingAPIUnarchiveVehicleSearchParams;

function unarchiveVehicleUnsafeEffect(
  payload: LeasingAPIUnarchiveVehiclePayload,
) {
  const { vehicleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vehicles/${vehicleId}/unarchive`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function unarchiveVehicle(
  payload: LeasingAPIUnarchiveVehiclePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    unarchiveVehicleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { unarchiveVehicle };
export type {
  LeasingAPIUnarchiveVehiclePathParams,
  LeasingAPIUnarchiveVehiclePayload,
  LeasingAPIUnarchiveVehicleSearchParams,
};
