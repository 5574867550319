import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { FontSize, Text } from "@ender/shared/ds/text";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { fail } from "@ender/shared/utils/error";
import {
  showLoadingNotification,
  showSuccessNotification,
} from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";

type SplitUnmatchedEnderTxnButtonProps = {
  bankAccountId: EnderId;
  bankingBatchId: EnderId;
  onSuccess: () => void;
};

function SplitUnmatchedEnderTxnButton({
  bankAccountId,
  bankingBatchId,
  onSuccess,
}: SplitUnmatchedEnderTxnButtonProps) {
  const confirmation = useConfirmationContext();

  const { mutate: _splitUnmatchedEnderTxn, isLoading } = useMutation({
    mutationFn: async () => {
      await confirmation(
        {
          confirmButtonLabel: "Split Batch",
          content: (
            <Text size={FontSize.sm}>
              This action will split this batch into individual transactions for
              reconciliation purposes. Are you sure you would like to continue?
            </Text>
          ),
          title: "Split into individual transactions?",
        },
        {
          confirmButtonProps: { color: Color.red },
        },
      );

      const [, clearLoadingNotification] = showLoadingNotification({
        message: "Splitting batch...",
      });

      try {
        await BankingAPI.splitUnmatchedEnderTxn({
          bankAccountId,
          bankingBatchId,
        });
        showSuccessNotification({ message: "Successfully split batch." });
        onSuccess();
      } catch (error) {
        fail(error);
      } finally {
        clearLoadingNotification();
      }
    },
    mutationKey: ["BankingAPI.splitUnmatchedEnderTxn"] as const,
  });

  const onClick = useCallback(() => {
    if (isLoading) {
      return;
    }
    _splitUnmatchedEnderTxn();
  }, [_splitUnmatchedEnderTxn, isLoading]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ActionIcon
      tooltip="Split batch into individual transactions."
      variant={ButtonVariant.transparent}
      onClick={onClick}></ActionIcon>
  );
}

export { SplitUnmatchedEnderTxnButton };
