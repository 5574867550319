import type { Undefined } from "@ender/shared/constants/general";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import type { InvoicePaymentMethod } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoicePaymentMethodEnum } from "@ender/shared/generated/ender.model.payments.invoice";

const MapInvoicePaymentMethodToMoneyTransferTransferType: ReadonlyMap<
  InvoicePaymentMethod,
  MoneyTransferTransferType
> = new Map([
  [InvoicePaymentMethodEnum.ACH, MoneyTransferTransferTypeEnum.DWOLLA_TRANSFER],
  [
    InvoicePaymentMethodEnum.MARK_PAID,
    MoneyTransferTransferTypeEnum.MARK_PAID_CHECK,
  ],
  [
    InvoicePaymentMethodEnum.PRINT_CHECK,
    MoneyTransferTransferTypeEnum.PRINT_CHECK,
  ],
]);

function getPaymentMethodTypeFromPaymentType(
  paymentTypeFilter: InvoicePaymentMethod,
): MoneyTransferTransferType | Undefined {
  return MapInvoicePaymentMethodToMoneyTransferTransferType.get(
    paymentTypeFilter,
  );
}

export { getPaymentMethodTypeFromPaymentType };
