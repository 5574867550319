import { ApplicationGroupStatusBadge } from "@ender/entities/application-group-status-badge";
import { Group } from "@ender/shared/ds/group";
import type { ApplicationGroupApplicationStatus } from "@ender/shared/generated/ender.model.leasing";

function StatusCell({ status }: { status: ApplicationGroupApplicationStatus }) {
  return (
    <Group>
      <ApplicationGroupStatusBadge status={status} />
    </Group>
  );
}

export { StatusCell };
