import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DealStatusPipelineStatusValues = [
  "NOT_FOR_SALE",
  "ON_MARKET",
  "LEAD",
  "OFFER",
  "DUE_DILIGENCE",
  "PRE_REPAIR",
  "CONSTRUCTION",
  "MARKETING",
  "STABILIZED",
  "OCCUPIED",
  "TERMINATED",
] as const;

const DealStatusPipelineStatusEffectSchema = Schema.Literal(
  ...DealStatusPipelineStatusValues,
);

type DealStatusPipelineStatus = Schema.Schema.Type<
  typeof DealStatusPipelineStatusEffectSchema
>;

const DealStatusPipelineStatusEnum = castEnum(
  DealStatusPipelineStatusEffectSchema,
);

function randomDealStatusPipelineStatus(): DealStatusPipelineStatus {
  return rand(DealStatusPipelineStatusValues);
}

export {
  DealStatusPipelineStatusEffectSchema,
  DealStatusPipelineStatusEnum,
  DealStatusPipelineStatusValues,
  randomDealStatusPipelineStatus,
};
export type { DealStatusPipelineStatus };
