// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { RadioGroupProps, RadioProps, SelectItem } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Radio, createStyles } from "@mantine/core";
import type { ReactElement } from "react";
import { useMemo } from "react";

const useStyles = createStyles((theme, _, getStylesRef) => ({
  icon: { color: "white", zIndex: "inherit" },
  label: {
    paddingLeft: 10,
  },
  radio: {
    [`&:disabled`]: {
      background: theme.colors.gray[1],
      borderColor: theme.colors.gray[1],
      [`& + .${getStylesRef("icon")}`]: {
        color: theme.white,
      },
    },
  },
}));

/**
 * @typedef {object} EnderRadioProps
 * @property {object=} icon React.ReactElement via variable
 * @property {string} label The Radio Input label
 * @property {MantineSize=} size
 * @property {string} value The Radio Input value
 */

/**
 * @description Ender Abstraction for a form radio input
 * @deprecated use shared/ds/radio-group
 * */
function EnderRadio({ classNames, ...props }: RadioProps) {
  const { classes } = useStyles(undefined, { classNames, name: "EnderRadio" });
  return <Radio classNames={classes} {...props} />;
}

type EnderRadioGroupProps =
  | (RadioGroupProps & { options?: undefined })
  | (Omit<RadioGroupProps, "children"> & {
      options: SelectItem[];
      children?: undefined;
    });

/**
 * @description Ender abstraction for a form radio group
 * @todo Import types from Mantine for the component
 * @deprecated use shared/ds/radio-group
 */
function EnderRadioGroup(props: EnderRadioGroupProps): ReactElement {
  const { children, options, ...radioGroupProps } = props;

  const radioGroupContents = useMemo(() => {
    if (children) {
      return children;
    }

    if (!options) {
      return;
    }

    return options.map(({ uuid, ...optionProps }) => (
      <EnderRadio key={optionProps.value} {...optionProps} />
    ));
  }, [children, options]);

  return <Radio.Group {...radioGroupProps}>{radioGroupContents}</Radio.Group>;
}

export { EnderRadio, EnderRadioGroup };
