import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";

import { MoveInStep } from "../move-in-step";
import type { ChecklistStepComponentProps } from "./checklist-steps.types";

function DefaultStep(props: Omit<ChecklistStepComponentProps, "capturedData">) {
  const { completed, completedTime, onChange, ...stepProps } = props;

  return (
    <MoveInStep {...stepProps} completedTime={completedTime}>
      <Group spacing={Spacing.none} noWrap>
        <Button
          loading={stepProps.isLoading}
          variant={ButtonVariant.outlined}
          disabled={Boolean(completedTime) || completed}
          onClick={() => onChange({ completed: true })}>
          Done
        </Button>
        {Boolean(completedTime) && (
          <Button
            variant={ButtonVariant.transparent}
            onClick={() => onChange({ capturedData: "", completed: false })}
            disabled={completed}>
            Clear
          </Button>
        )}
      </Group>
    </MoveInStep>
  );
}

export { DefaultStep };
