import type { FunctionType } from "@ender/shared/types/general";

/**
 * https://css-tricks.com/debouncing-throttling-explained-examples/
 * debounce: Restrict a sudden burst of callbacks (like on keystroke) to a single callback after the delay has elapsed.
 * @todo deprecate this and use a library instead. Mantine probably includes one of these
 */
function debounce<T extends FunctionType>(
  func: T,
  delay = 1000,
): (...funcArgs: Parameters<T>) => void {
  let inDebounce: ReturnType<typeof setTimeout> | null;

  return function (this: unknown, ...args: Parameters<T>) {
    if (inDebounce !== null) {
      clearTimeout(inDebounce);
    }
    inDebounce = setTimeout(() => func.apply(this, args), delay);
  };
}

export { debounce };
