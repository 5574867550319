import { archiveTemplate } from "./archive-template";
import { bulkRenderLeaseTemplates } from "./bulk-render-lease-templates";
import { createTemplate } from "./create-template";
import { getTemplate } from "./get-template";
import { listTemplates } from "./list-templates";
import { renderTemplate } from "./render-template";
import { unarchiveTemplate } from "./unarchive-template";
import { updateTemplate } from "./update-template";

const TemplatesAPI = {
  archiveTemplate,
  bulkRenderLeaseTemplates,
  createTemplate,
  getTemplate,
  listTemplates,
  renderTemplate,
  unarchiveTemplate,
  updateTemplate,
};

export { TemplatesAPI };
