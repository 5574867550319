/**
 * HTTP Method: GET
 * Pathname: /middleLayer/payments/dwollaTransferStatus/{moneyTransferId}
 * @function getDwollaTransferStatus
 * @memberof PaymentsMiddleLayerAPI
 * @param {PaymentsMiddleLayerAPIGetDwollaTransferStatusPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetDwollaTransferStatusResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetDwollaTransferStatusResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsMiddleLayerAPIGetDwollaTransferStatusPathParams = {
  moneyTransferId: EnderId;
};

type PaymentsMiddleLayerAPIGetDwollaTransferStatusSearchParams = {
  token?: string | undefined;
};

type PaymentsMiddleLayerAPIGetDwollaTransferStatusPayload =
  PaymentsMiddleLayerAPIGetDwollaTransferStatusPathParams &
    PaymentsMiddleLayerAPIGetDwollaTransferStatusSearchParams;

function getDwollaTransferStatusUnsafeEffect(
  payload: PaymentsMiddleLayerAPIGetDwollaTransferStatusPayload,
) {
  const { moneyTransferId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetDwollaTransferStatusResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetDwollaTransferStatusResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/middleLayer/payments/dwollaTransferStatus/${moneyTransferId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getDwollaTransferStatus(
  payload: PaymentsMiddleLayerAPIGetDwollaTransferStatusPayload,
  options?: { signal?: AbortSignal },
): Promise<GetDwollaTransferStatusResponse> {
  return F.pipe(
    payload,
    getDwollaTransferStatusUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDwollaTransferStatus };
export type {
  PaymentsMiddleLayerAPIGetDwollaTransferStatusPathParams,
  PaymentsMiddleLayerAPIGetDwollaTransferStatusPayload,
  PaymentsMiddleLayerAPIGetDwollaTransferStatusSearchParams,
};
