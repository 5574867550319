import { Predicate as P } from "effect";

import { cast } from "@ender/shared/types/cast";
import type {
  LabelValue,
  ValuesOrLabelValues,
} from "@ender/shared/types/label-value";

/**
 * Type guard function that checks if an object is of type `LabelValue<T>`.
 * @param {unknown} obj - The object to check.
 * @returns {boolean} `true` if the object is of type `LabelValue<T>`, `false` otherwise.
 * @template T - The type of the `value` property in the `LabelValue<T>` object.
 */
function isLabelValue<T extends string>(obj: unknown): obj is LabelValue<T> {
  if (P.isRecord(obj)) {
    return "label" in obj && "value" in obj;
  } else {
    return false;
  }
}

/**
 * Filters an array of `LabelValue<T>` objects based on an array of filter values.
 * @param {LabelValue<T>[]} labelVals - The array of `LabelValue<T>` objects to filter.
 * @param {T[]} toFilter - The array of filter values.
 * @returns {LabelValue<T>[]} An array of `LabelValue<T>` objects that do not match any of the filter values.
 * @template T - The type of the values in the `LabelValue<T>` objects.
 */
function filterLabelValues<T extends string>(
  labelVals: readonly LabelValue<T>[],
  toFilter: readonly T[],
): readonly LabelValue<T>[] {
  const searchSet = new Set(toFilter);
  return labelVals.filter((labelVal) => {
    return !searchSet.has(labelVal.value);
  });
}

/**
 * Filters an array of values of type `T` based on an array of filter values.
 * @param {T[]} items - The array of values to filter.
 * @param {T[]} toFilter - The array of filter values.
 * @returns {T[]} An array of values that do not match any of the filter values.
 * @template T - The type of the values in the `items` and `toFilter` arrays.
 */
function filterItems<T>(
  items: readonly T[],
  toFilter: readonly T[],
): readonly T[] {
  const searchSet = new Set(toFilter);
  return items.filter((item) => {
    return !searchSet.has(item);
  });
}

/**
 * Filters an array of values of type `T` or `LabelValue<T>` based on an array of filter values.
 * @param {ValuesOrLabelValues<T>} toFilter - The array of values to filter.
 * @param {T[]} [filterValues] - The array of filter values.
 * @returns {T[] | LabelValue<T>[]} An array of values that do not match any of the filter values.
 * @template T - The type of the values in the `toFilter` array.
 */
function filterLabelValueItems<T extends string>(
  toFilter: ValuesOrLabelValues<T>,
  filterValues?: readonly T[],
): ValuesOrLabelValues<T> {
  if (!filterValues) {
    return toFilter;
  }

  if (isLabelValue(toFilter[0])) {
    return filterLabelValues(cast<LabelValue<T>[]>(toFilter), filterValues);
  } else {
    return filterItems(cast<T[]>(toFilter), filterValues);
  }
}

export { filterItems, filterLabelValueItems, isLabelValue };
