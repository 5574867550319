import { Array as A, Option as O, pipe } from "effect";

import { Money$ } from "@ender/shared/core";

import type { AllocationsByProperty } from "./use-create-invoice-form";

function getTotalAmountFromAllocations(
  allocations: AllocationsByProperty["allocations"],
) {
  return pipe(
    allocations,
    A.map((a) => a.amount),
    O.reduceCompact(Money$.zero(), Money$.add),
  );
}

function getTotalAmountFromAllocationsByProperty(
  allocationsByProperty: AllocationsByProperty[],
) {
  return pipe(
    allocationsByProperty,
    A.map((a) => getTotalAmountFromAllocations(a.allocations)),
    A.reduce(Money$.zero(), Money$.add),
  );
}

export { getTotalAmountFromAllocationsByProperty };
