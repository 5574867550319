import { useQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { Array as A, Option as O, Predicate as P } from "effect";

import { UNDEFINED } from "@ender/shared/constants/general";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import { AccountingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetGLJournalEntryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import { RecurringGLJournalEntryTxTransactionDirectionEnum } from "@ender/shared/generated/ender.model.accounting";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { RightRail } from "@ender/shared/ui/right-rail";
import { EnderTableTanstack, useTable } from "@ender/shared/ui/table-tanstack";

import { GeneralLedgerTransactionApprovalsEditTransactionAllocations } from "./gltx-approvals-edit-transaction-allocations";

type TransactionAllocationsProps = {
  journalEntry?: GetGLJournalEntryResponse;
  recurringJournalEntry?: GetRecurringGLJournalEntryDetailsResponse;
  refreshTransactionData: () => void;
};

type AllocationColumnProps = {
  property: string;
  category: string;
  debit: string | undefined;
  credit: string | undefined;
};

function AllocationsTable({
  journalEntry,
  recurringJournalEntry,
}: Pick<
  TransactionAllocationsProps,
  "journalEntry" | "recurringJournalEntry"
>) {
  const journalEntryAllocations = [
    ...(journalEntry?.allocations ?? []).map((allocation) => ({
      category: allocation.glAccountName,
      //candidate for pipe/matcher
      credit: Money$.isNegative(Money$.of(allocation.amount))
        ? Money$.of(allocation.amount).abs().toJSON()
        : UNDEFINED,
      //candidate for pipe/matcher
      debit: Money$.isPositive(Money$.of(allocation.amount))
        ? Money$.of(allocation.amount).toJSON()
        : UNDEFINED,
      property: allocation.propertyCode,
    })),
  ];

  const recurringJournalEntryAllocations = [
    ...(recurringJournalEntry?.allocations ?? []).map((allocation) => ({
      category: allocation.categoryDisplay,
      credit:
        allocation.transactionDirection ===
        RecurringGLJournalEntryTxTransactionDirectionEnum.CREDIT
          ? allocation.amount
          : UNDEFINED,
      debit:
        allocation.transactionDirection ===
        RecurringGLJournalEntryTxTransactionDirectionEnum.DEBIT
          ? allocation.amount
          : UNDEFINED,
      property: allocation.propertyDisplay,
    })),
  ];

  const columns: ColumnDef<AllocationColumnProps>[] = [
    {
      accessorKey: "property",
      header: "Property",
    },
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      accessorKey: "debit",
      header: "Debit",
    },
    {
      accessorKey: "credit",
      header: "Credit",
    },
  ];

  const table = useTable({
    columns,
    data: A.isEmptyArray(journalEntryAllocations)
      ? recurringJournalEntryAllocations
      : journalEntryAllocations,
  });

  if (
    A.isEmptyArray(journalEntryAllocations) &&
    A.isEmptyArray(recurringJournalEntryAllocations)
  ) {
    return <div>No allocations set</div>;
  }

  return <EnderTableTanstack table={table} title="Allocations" />;
}

function GeneralLedgerTransactionApprovalsTransactionAllocations({
  journalEntry,
  recurringJournalEntry,
  refreshTransactionData,
}: TransactionAllocationsProps) {
  const [isEditing, setIsEditing] = useBoolean(false);

  const { data: journalEntryAllocation, refetch } = useQuery({
    enabled: P.isNotNullable(journalEntry),
    queryFn: () =>
      // @ts-expect-error only enabled if journalEntry is not null
      AccountingAPI.getJournalEntry({ journalEntryId: journalEntry.id }),
    queryKey: ["AccountingAPI.getJournalEntry", journalEntry?.id],
  });

  return (
    <>
      <Stack>
        <Group justify={Justify.between}>
          <H3>Allocations</H3>
          <Button
            variant={ButtonVariant.outlined}
            onClick={setIsEditing.setTrue}>
            Edit Allocations
          </Button>
        </Group>
        <AllocationsTable
          journalEntry={journalEntry}
          recurringJournalEntry={recurringJournalEntry}
        />
      </Stack>
      <RightRail
        opened={isEditing}
        onClose={setIsEditing.setFalse}
        title="Edit Journal Entry Allocations">
        <GeneralLedgerTransactionApprovalsEditTransactionAllocations
          journalEntry={O.fromNullable(journalEntryAllocation)}
          recurringJournalEntry={O.fromNullable(recurringJournalEntry)}
          onClose={setIsEditing.setFalse}
          refreshTransactionData={
            P.isNotNullable(journalEntryAllocation)
              ? () => {
                  refreshTransactionData();
                  refetch();
                }
              : refreshTransactionData
          }
        />
      </RightRail>
    </>
  );
}

export { GeneralLedgerTransactionApprovalsTransactionAllocations };
