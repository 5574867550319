/**
 * HTTP Method: POST
 * Pathname: /middleLayer/applications/searchApplicationsPageTable
 * @function searchApplicationsPageTable
 * @memberof ApplicationsMiddleLayerAPI
 * @param {ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchApplicationsPageResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  SearchApplicationsPageRequestApplicationsPageSortKey,
  SearchApplicationsPageRequestApplicationsPageTab,
} from "@ender/shared/generated/com.ender.middle.request";
import type { SearchApplicationsPageResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsMiddleLayerAPISearchApplicationsPageTableSearchParams = {
  token?: string | undefined;
};

type ApplicationsMiddleLayerAPISearchApplicationsPageTableBodyParams = {
  applicantNameOrPhone?: string | undefined;
  descending?: boolean | undefined;
  limit?: number | undefined;
  marketIds?: EnderId[] | undefined;
  offset?: number | undefined;
  onlyShowNeedsFollowup?: boolean | undefined;
  ownershipGroupIds?: EnderId[] | undefined;
  propertyIds?: EnderId[] | undefined;
  sortKey?: SearchApplicationsPageRequestApplicationsPageSortKey | undefined;
  tab: SearchApplicationsPageRequestApplicationsPageTab;
  usingHousingChoiceVoucher?: boolean | undefined;
};

type ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload =
  ApplicationsMiddleLayerAPISearchApplicationsPageTableSearchParams &
    ApplicationsMiddleLayerAPISearchApplicationsPageTableBodyParams;

function searchApplicationsPageTableUnsafeEffect(
  payload: ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchApplicationsPageResponse>({
        body,
        decode: unsafeDecodeJsonResponse<SearchApplicationsPageResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/applications/searchApplicationsPageTable",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchApplicationsPageTable(
  payload: ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload,
  options?: { signal?: AbortSignal },
): Promise<SearchApplicationsPageResponse> {
  return F.pipe(
    payload,
    searchApplicationsPageTableUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchApplicationsPageTable };
export type {
  ApplicationsMiddleLayerAPISearchApplicationsPageTableBodyParams,
  ApplicationsMiddleLayerAPISearchApplicationsPageTablePayload,
  ApplicationsMiddleLayerAPISearchApplicationsPageTableSearchParams,
};
