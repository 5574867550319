/**
 * HTTP Method: POST
 * Pathname: /invoices/edit
 * @function editInvoices
 * @memberof InvoicesAPI
 * @param {InvoicesAPIEditInvoicesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { CreateInvoiceRequestPayee } from "@ender/shared/generated/ender.api.accounting.request";
import type { InvoicePayableType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIEditInvoicesSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIEditInvoicesBodyParams = {
  accountingPeriod?: SerializesInto<LocalDate> | undefined;
  checkMemo: string;
  checkNumber: string;
  description: string;
  externalInvoiceId?: string | undefined;
  glAccountId?: EnderId | undefined;
  invoiceIds: EnderId[];
  isBulkEdit?: boolean | undefined;
  ledgerDate?: SerializesInto<LocalDate> | undefined;
  payableCategoryId?: EnderId | undefined;
  payableType?: InvoicePayableType | undefined;
  payee?: CreateInvoiceRequestPayee | undefined;
  poNumbers: number[];
  taskId?: EnderId | undefined;
};

type InvoicesAPIEditInvoicesPayload = InvoicesAPIEditInvoicesSearchParams &
  InvoicesAPIEditInvoicesBodyParams;

function editInvoicesUnsafeEffect(payload: InvoicesAPIEditInvoicesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/edit",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function editInvoices(
  payload: InvoicesAPIEditInvoicesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    editInvoicesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { editInvoices };
export type {
  InvoicesAPIEditInvoicesBodyParams,
  InvoicesAPIEditInvoicesPayload,
  InvoicesAPIEditInvoicesSearchParams,
};
