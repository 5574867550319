import { Predicate as P } from "effect";
import { useState } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

type FileNameFilterProps = FilterComponentProps & {
  metadata: {
    fileNames: string[];
  };
};

function getFileNameFilter(
  value: string | null,
  factor?: WidgetFactor,
): WidgetFilter[] | Undefined {
  if (value === undefined || P.isNull(value) || !factor) {
    return undefined;
  }

  return [
    {
      factor,
      operator: DynamicTableOperatorsEnum.IN,
      values: [value],
    },
  ];
}

function getDefaultValue(widget: Widget) {
  return (
    widget.filters
      .find(({ factor }) => factor.name === Factor.FILE_NAME)
      ?.values?.[0].toString() || NULL
  );
}

function FileNameFilter({
  factor,
  metadata,
  updateFilters,
  widget,
}: FileNameFilterProps) {
  const [selected, setSelected] = useState<string | null>(
    getDefaultValue(widget),
  );

  function handleChange(value: string | null) {
    if (P.isNull(value)) {
      setSelected(null);
      updateFilters(Factor.FILE_NAME, UNDEFINED);
      return;
    }
    setSelected(value);

    const filters = getFileNameFilter(value.toString(), factor);
    updateFilters(Factor.FILE_NAME, filters);
  }

  return (
    <Select
      label="File Name"
      data={
        metadata.fileNames?.map((value) => ({
          label: value || "--",
          value: value,
        })) || []
      }
      clearable
      searchable
      placeholder="Select File Name"
      value={selected}
      onChange={handleChange}
    />
  );
}

export { FileNameFilter };
