import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const ChargeScheduleStatusValues = [
  "ACTIVE",
  "DRAFTING",
  "EXPIRED",
  "UPCOMING",
] as const;
const ChargeScheduleStatusSchema = z.enum(ChargeScheduleStatusValues);
type ChargeScheduleStatus = z.infer<typeof ChargeScheduleStatusSchema>;

const ChargeScheduleStatusEnum = castEnum<ChargeScheduleStatus>(
  ChargeScheduleStatusSchema,
);

export {
  ChargeScheduleStatusEnum,
  ChargeScheduleStatusSchema,
  ChargeScheduleStatusValues,
};
export type { ChargeScheduleStatus };
