import { IconPlus } from "@tabler/icons-react";
import * as A from "effect/Array";
import { useMemo } from "react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { FileUploadForm } from "@ender/shared/ui/modals";
import type { FileUploadModalProps } from "@ender/shared/ui/modals";
import {
  EnderTableTanstackV3,
  useTable,
} from "@ender/shared/ui/table-tanstack";

import type {
  AttachmentsViewFile,
  AttachmentsViewMeta,
} from "./attachments-view.columns";
import { columns } from "./attachments-view.columns";

type AttachmentsViewProps<FileType extends AttachmentsViewFile> = {
  files: FileType[];
  onClickDelete: (file: FileType) => Promise<void>;
  onSuccess: () => void;
  uploadFiles: FileUploadModalProps["uploadFiles"];
  approvalsDetailView?: boolean;
};

function AttachmentsView<FileType extends AttachmentsViewFile>({
  files,
  onClickDelete,
  onSuccess,
  uploadFiles,
  approvalsDetailView = false,
}: AttachmentsViewProps<FileType>) {
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean();

  const meta = useMemo<AttachmentsViewMeta>(
    () => ({
      //@ts-expect-error this AttachmentsView should eventually use a singular, non-generic type- likely FilesClientEnderFile
      onDelete: onClickDelete,
    }),
    [onClickDelete],
  );

  const table = useTable<AttachmentsViewFile, AttachmentsViewMeta>({
    columns,
    data: files,
    meta,
  });

  return (
    <Stack>
      <Group justify={approvalsDetailView ? Justify.end : Justify.between}>
        {!approvalsDetailView ? (
          <>
            <H3>Attachments</H3>
            <ActionIcon label="Upload Attachments" onClick={openModal}>
              <IconPlus />
            </ActionIcon>
          </>
        ) : (
          <Button onClick={openModal} variant={ButtonVariant.outlined}>
            Add Attachments
          </Button>
        )}
        <Modal
          title="Upload Invoice Attachments"
          opened={isModalOpen}
          onClose={closeModal}>
          <FileUploadForm
            uploadFiles={uploadFiles}
            onSuccess={() => {
              onSuccess();
              closeModal();
            }}
          />
        </Modal>
      </Group>
      {A.isEmptyArray(files) && (
        <Group justify={Justify.center}>
          <Text color="slate-500">No Attachments</Text>
        </Group>
      )}
      {A.isNonEmptyArray(files) && <EnderTableTanstackV3 table={table} />}
    </Stack>
  );
}

export { AttachmentsView };
export type { AttachmentsViewFile };
