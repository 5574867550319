import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Function as F } from "effect";
import { useCallback } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { moveFile } from "@ender/shared/api/files";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const renameFileFormSchema = Schema.Struct({
  name: Schema.String.pipe(
    Schema.transform(Schema.String, {
      decode: (s) => s.trim(),
      encode: (s) => s,
      strict: true,
    }),
    Schema.nonEmptyString({ message: () => "Required" }),
  ),
});

type RenameFileFormValues = Schema.Schema.Type<typeof renameFileFormSchema>;

type RenameFileFormProps = {
  applicationId: EnderId;
  file: FilesClientEnderFile;
  onCancel?: () => void;
  onSuccess?: () => void;
};

function RenameFileForm({
  file,
  applicationId,
  onCancel = F.constVoid,
  onSuccess = F.constVoid,
}: RenameFileFormProps) {
  const form = useForm<RenameFileFormValues>({
    defaultValues: {
      name: file.path.split("/").pop() || "",
    },
    mode: "onSubmit",
    resolver: effectTsResolver(renameFileFormSchema),
  });

  const { mutateAsync: handleRename, isLoading } = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const pathDirArr = file.path.split("/");
      pathDirArr.pop();

      const newPath = `${pathDirArr.join("/")}/${name}`;

      await moveFile({
        from: file.path,
        modelId: applicationId,
        modelType: ModelTypeEnum.APPLICATION,
        to: newPath,
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: RenameFileFormValues) => {
      await handleRename({ name: values.name });
      showSuccessNotification({ message: `File name changed` });
      await onSuccess();
    },
    [handleRename, onSuccess],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <H1>Rename File</H1>
        <FormTextInput
          form={form}
          label="File Name"
          name="name"
          placeholder="Enter File Name"
        />
        <Group align={Align.center} justify={Justify.end}>
          <Button
            onClick={onCancel}
            type="button"
            variant={ButtonVariant.transparent}>
            Cancel
          </Button>
          <Button disabled={isLoading} type="submit">
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { RenameFileForm };
