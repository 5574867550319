import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DayOfWeekValues = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
] as const;

const DayOfWeekEffectSchema = Schema.Literal(...DayOfWeekValues);

type DayOfWeek = Schema.Schema.Type<typeof DayOfWeekEffectSchema>;

const DayOfWeekEnum = castEnum(DayOfWeekEffectSchema);

function randomDayOfWeek(): DayOfWeek {
  return rand(DayOfWeekValues);
}

export {
  DayOfWeekEffectSchema,
  DayOfWeekEnum,
  DayOfWeekValues,
  randomDayOfWeek,
};
export type { DayOfWeek };
