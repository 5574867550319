import { IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import type { CSSProperties, MouseEvent, ReactElement } from "react";
import { useCallback } from "react";

import type { InvoicePage } from "@ender/shared/api/invoices";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";

import styles from "./invoice-file-preview.module.css";

type InvoiceFilePreviewProps = {
  invoicePage: InvoicePage;
  defaultShowActions?: boolean;
  isSelected?: boolean;
  isHidden?: boolean;
  style?: { gridArea?: CSSProperties["gridArea"] };
  onSelectInvoicePage?: (args: { page: InvoicePage }) => void;
  onDeleteInvoicePage?: () => void;
};

function InvoiceFilePreview({
  invoicePage,
  isSelected = false,
  isHidden = false,
  style,
  onSelectInvoicePage = F.constVoid,
  onDeleteInvoicePage = F.constVoid,
}: InvoiceFilePreviewProps): ReactElement<InvoiceFilePreviewProps> {
  const onChangeChecked = useCallback(() => {
    onSelectInvoicePage({ page: invoicePage });
  }, [invoicePage, onSelectInvoicePage]);

  const { mutateAsync: trashInvoicePage } = useMutation({
    mutationFn: InvoicesAPI.trashInvoicePage,
    mutationKey: ["InvoicesAPI.trashInvoicePage"] as const,
  });

  const confirmation = useConfirmationContext();

  const onClickDelete = useCallback<(event: MouseEvent) => void>(
    async (event) => {
      event?.stopPropagation && event.stopPropagation();
      await confirmation({
        confirmButtonLabel: "Delete Page",
        content: "Please confirm before proceeding.",
        title: "Delete this invoice page?",
      });
      await trashInvoicePage({ invoicePageId: invoicePage.id });
      onDeleteInvoicePage();
    },
    [confirmation, invoicePage.id, onDeleteInvoicePage, trashInvoicePage],
  );

  return (
    <div
      className={clsx(
        { [styles.isSelected]: isSelected, hidden: isHidden },
        styles.invoiceFilePreview,
      )}
      style={style}>
      <img
        className={styles.previewImage}
        width={124}
        src={invoicePage.url}
        onClick={onChangeChecked}
        alt="Invoice Preview"
      />
      <div className={styles.actions}>
        {P.isNotNullable(onDeleteInvoicePage) && (
          <ActionIcon
            variant={ButtonVariant.transparent}
            onClick={onClickDelete}>
            <IconTrash />
          </ActionIcon>
        )}
        {P.isNotNullable(onSelectInvoicePage) && (
          <Checkbox value={isSelected} onChange={onChangeChecked} />
        )}
      </div>
    </div>
  );
}

export { InvoiceFilePreview };
