import { useQuery } from "@tanstack/react-query";
import type { SortingState } from "@tanstack/react-table";
import { Array as A, Option as O, Predicate as P } from "effect";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "zustand";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { optimizeLedgerResponseData } from "@ender/shared/contexts/ledger";
import { useGetScheduledCharges } from "@ender/shared/contexts/scheduled-charges";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import type { TenantLedgerAPIGetTenantLedgerPayload } from "@ender/shared/generated/ender.api.accounting";
import {
  GeneralLedgerAPI,
  TenantLedgerAPI,
} from "@ender/shared/generated/ender.api.accounting";
import type { GetTxCategoriesResponse } from "@ender/shared/generated/ender.api.accounting.response";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { PeriodFilterFilterTypeEnum } from "@ender/shared/generated/ender.api.model";
import { CategoryFlagEnum } from "@ender/shared/generated/ender.model.accounting";
import type { TenantLedgerReportServiceTenantLedgerSortKey } from "@ender/shared/generated/ender.service.reports.builtin";
import { TenantLedgerReportServiceTenantLedgerSortKeyEnum } from "@ender/shared/generated/ender.service.reports.builtin";
import type { EnderTableSortingParams } from "@ender/shared/ui/table-tanstack";
import { convertTitleCaseToSnakeCase } from "@ender/shared/utils/string";

import { ChargeScheduleStatusEnum } from "./charge-schedule-status";
import { useTenantLedgerStore } from "./tenant-ledger-store.context";

function useGetLeaseId() {
  const { leaseId } = useParams<{ leaseId: EnderId }>();
  return leaseId;
}

function useGetLeaseById(leaseId: EnderId) {
  const { data: lease, isInitialLoading: isLoadingLease } = useQuery({
    queryFn: ({ queryKey: [, leaseId] }) => {
      return LeasingAPI.getLeaseDetails({ leaseId });
    },
    queryKey: ["LeasingAPI.getLeaseDetails", leaseId] as const, // leaseId needs to be defined and should be a string
  });

  return { isLoadingLease, lease };
}

function useGetCurrentLease() {
  const leaseId = useGetLeaseId();
  return useGetLeaseById(leaseId);
}

// @ts-expect-error we don't know these types yet because they go through a transformation and started as any
function compareScheduledCharges(a, b) {
  if (a.status !== b.status) {
    if (
      a.status === ChargeScheduleStatusEnum.ACTIVE ||
      b.status === ChargeScheduleStatusEnum.EXPIRED
    ) {
      return 1;
    } else if (
      b.status === ChargeScheduleStatusEnum.ACTIVE ||
      a.status === ChargeScheduleStatusEnum.EXPIRED
    ) {
      return -1;
    }
  }

  const aStartDateTime = new Date(a.startDate).getTime();
  const bStartDateTime = new Date(b.startDate).getTime();
  if (aStartDateTime < bStartDateTime) {
    return -1;
  } else if (aStartDateTime > bStartDateTime) {
    return 1;
  }

  if (a.amount < b.amount) {
    return -1;
  } else if (a.amount > b.amount) {
    return 1;
  }

  return 0;
}

function useCurrentScheduledCharges() {
  const {
    findScheduledCharge: _findScheduledCharge,
    scheduledCharges,
    ...rest
  } = useGetScheduledCharges(useGetLeaseId());

  const findScheduledCharge = useCallback(
    (scheduleId: O.Option<EnderId>) => {
      return scheduleId.pipe(O.flatMap(O.liftNullable(_findScheduledCharge)));
    },
    [_findScheduledCharge],
  );

  const sortedScheduledCharges = useMemo(() => {
    return scheduledCharges?.slice().sort(compareScheduledCharges);
  }, [scheduledCharges]);

  return {
    findScheduledCharge,
    scheduledCharges: O.fromNullable(sortedScheduledCharges),
    ...rest,
  };
}

function getSortKeyFromColumnId(
  columnId: string | undefined,
): TenantLedgerReportServiceTenantLedgerSortKey | undefined {
  if (columnId === "specificLedgerDate") {
    return TenantLedgerReportServiceTenantLedgerSortKeyEnum.SPECIAL_LEDGER_DATE;
  }

  return UNDEFINED;
}

function getQuerySortingFromTable(sortingState?: SortingState): {
  sortKey?: TenantLedgerReportServiceTenantLedgerSortKey;
  ascending?: boolean;
} {
  if (P.isNullable(sortingState) || A.isEmptyArray(sortingState)) {
    return {};
  }

  const sortKey = getSortKeyFromColumnId(sortingState[0]?.id);
  const ascending = P.isNotNullable(sortingState[0]?.desc)
    ? !sortingState[0].desc
    : UNDEFINED;

  return { sortKey, ascending };
}

function useOptimizedLedgerResponse(sorting?: EnderTableSortingParams) {
  const { user } = useContext(UserContext);
  const leaseId = useGetLeaseId();
  const tenantLedgerStore = useTenantLedgerStore();

  const {
    accountingPeriod,
    endDate,
    startDate,
    transactionDescription,
    transactionType,
  } = useStore(tenantLedgerStore, (state) => ({
    accountingPeriod: state.accountingPeriod,
    endDate: state.endDate,
    startDate: state.startDate,
    transactionDescription: state.transactionDescription,
    transactionType: state.transactionType,
  }));

  const { sortKey, ascending } = useMemo(() => {
    return getQuerySortingFromTable(sorting?.sorting);
  }, [sorting]);

  const tenantLedgerPayload: TenantLedgerAPIGetTenantLedgerPayload = useMemo(
    () => ({
      ascending,
      description: transactionDescription,
      endDate: O.getOrUndefined(endDate)?.toJSON(),
      leaseId,
      periodFilter: A.isNonEmptyArray(accountingPeriod)
        ? {
            customFilter: accountingPeriod.map((date) => date.toJSON()),
            type: PeriodFilterFilterTypeEnum.CUSTOM,
          }
        : UNDEFINED,
      selectedCategoryIds: [],
      startDate: O.getOrUndefined(startDate)?.toJSON(),
      type: O.getOrUndefined(transactionType),
      sortKey,
    }),
    [
      accountingPeriod,
      ascending,
      leaseId,
      startDate,
      sortKey,
      endDate,
      transactionType,
      transactionDescription,
    ],
  );

  const { data: ledgerResponse, isFetching } = useQuery({
    queryFn: ({ queryKey: [, _tenantLedgerPayload], signal }) =>
      TenantLedgerAPI.getTenantLedger(_tenantLedgerPayload, { signal }),
    queryKey: ["getTenantLedger", tenantLedgerPayload] as const,
  });

  const formattedLedgerResponse = useMemo(() => {
    if (ledgerResponse) {
      return optimizeLedgerResponseData(ledgerResponse, user);
    }
    return {
      categories: [],
      endingBalance: NULL,
      endingHousingAssistancePrepaymentsBalance: NULL,
      endingPrepaymentsBalance: Money$.zero(),
      endingRefundClearingBalance: NULL,
      ledgerEvents: [],
      numCharges: [],
      numCredits: [],
      totalCharges: Money$.zero(),
      totalCredits: Money$.zero(),
      totalPayments: Money$.zero(),
    };
  }, [ledgerResponse, user]);

  const namedCategories = useMemo(
    () => ({
      housingAssistancePrepaidRentCategory:
        formattedLedgerResponse.categories.find(({ flags }) =>
          flags.some(
            (flag) =>
              convertTitleCaseToSnakeCase(flag) ===
              CategoryFlagEnum.HOUSING_ASSISTANCE_PREPAID_RENT,
          ),
        ),
      prepaymentCategory: formattedLedgerResponse.categories.find(({ flags }) =>
        flags.some(
          (flag) =>
            convertTitleCaseToSnakeCase(flag) === CategoryFlagEnum.PREPAID_RENT,
        ),
      ),
      tenantRefundClearingCategory: formattedLedgerResponse.categories.find(
        ({ flags }) =>
          flags.some(
            (flag) =>
              convertTitleCaseToSnakeCase(flag) ===
              CategoryFlagEnum.TENANT_REFUND_CLEARING,
          ),
      ),
    }),
    [formattedLedgerResponse.categories],
  );

  return {
    ...formattedLedgerResponse,
    ...namedCategories,
    isFetching,
  };
}

type UseGetGlCategoriesReturn = {
  glCategories: GetTxCategoriesResponse[] | undefined;
  isGlCategoriesFetching: boolean;
};

type UseGetGlCategoriesProps = {
  excludeParentCategories?: boolean;
};

function useGetGlCategories({
  excludeParentCategories = true,
}: UseGetGlCategoriesProps = {}): UseGetGlCategoriesReturn {
  const { data: glCategories, isFetching: isGlCategoriesFetching } = useQuery({
    queryFn: ({ queryKey: [, params] }) =>
      GeneralLedgerAPI.getTxCategories(params),
    queryKey: [
      "GeneralLedgerAPI.getGlCategories",
      { excludeParentCategories: excludeParentCategories, keyword: "" },
    ] as const,
  });

  return { glCategories, isGlCategoriesFetching };
}

export {
  getQuerySortingFromTable,
  useCurrentScheduledCharges,
  useGetCurrentLease,
  useGetGlCategories,
  useGetLeaseById,
  useGetLeaseId,
  useOptimizedLedgerResponse,
};
