import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ReconTypeFilterUnmatchedBankTransactionTypeValues = [
  "BANK_TRANSACTION",
  "PENDING_INVOICE",
] as const;

const ReconTypeFilterUnmatchedBankTransactionTypeEffectSchema = Schema.Literal(
  ...ReconTypeFilterUnmatchedBankTransactionTypeValues,
);

type ReconTypeFilterUnmatchedBankTransactionType = Schema.Schema.Type<
  typeof ReconTypeFilterUnmatchedBankTransactionTypeEffectSchema
>;

const ReconTypeFilterUnmatchedBankTransactionTypeEnum = castEnum(
  ReconTypeFilterUnmatchedBankTransactionTypeEffectSchema,
);

function randomReconTypeFilterUnmatchedBankTransactionType(): ReconTypeFilterUnmatchedBankTransactionType {
  return rand(ReconTypeFilterUnmatchedBankTransactionTypeValues);
}

export {
  randomReconTypeFilterUnmatchedBankTransactionType,
  ReconTypeFilterUnmatchedBankTransactionTypeEffectSchema,
  ReconTypeFilterUnmatchedBankTransactionTypeEnum,
  ReconTypeFilterUnmatchedBankTransactionTypeValues,
};
export type { ReconTypeFilterUnmatchedBankTransactionType };
