// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  Content,
  Portal,
  Provider,
  Root,
  Trigger,
} from "@radix-ui/react-tooltip";
import { cva } from "class-variance-authority";
import type { ForwardedRef, PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

const TooltipVariantGenerator = cva(
  [
    "bg-slate-900 rounded-2xl px-2.5 py-1.5 z-10",
    "text-white text-xs/normal",
    "data-[state=closed]:hidden",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      disabled: false,
    },
    variants: {
      disabled: { true: "hidden" },
    },
  },
);

type TooltipProps = {
  /**
   * Allows for deliberate control of the tooltip's visibility.
   * If not provided, the tooltip will be controlled by the user's hover.
   */
  opened?: boolean;
  label: ReactNode;
  /**
   * the side that the tooltip will attempt to appear on.
   * If there is not enough space, it will flip to the opposite side
   */
  side?: "top" | "right" | "bottom" | "left";
  disabled?: boolean;
};

function Tooltip(
  props: PropsWithChildren<TooltipProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { children, opened, label, side = "top", disabled = false } = props;
  return (
    <Root open={opened}>
      {/* TODO for performance reasons, we should prevent the events entirely when `disabled` */}
      <Trigger asChild disabled={disabled}>
        <div className="inline-grid">{children}</div>
      </Trigger>
      <Portal>
        <Content
          ref={ref}
          className={TooltipVariantGenerator(props)}
          side={side}
          sideOffset={2}>
          {label}
        </Content>
      </Portal>
    </Root>
  );
}

const ForwardedTooltip = forwardRef<
  HTMLDivElement,
  PropsWithChildren<TooltipProps>
>(Tooltip);

export { ForwardedTooltip as Tooltip, Provider as TooltipProvider };

export type { TooltipProps };
