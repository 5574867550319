import { Array as A } from "effect";
import { useMemo } from "react";

import { Spacing } from "@ender/shared/ds/flex";
import { H2, H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { LeaseInfoCard } from "@ender/widgets/leasing/units/lease-info-card";

import type { TaggedLeases } from "./unit-page-lease.types";

type CurrentLeasesProps = {
  leases: TaggedLeases;
};

function CurrentLeases(props: CurrentLeasesProps) {
  const { leases } = props;

  const hasLeasesToShow = useMemo(() => {
    return (
      leases?.active?.length ||
      leases?.upcoming?.length ||
      leases?.pending?.length ||
      leases?.error?.length
    );
  }, [leases]);

  if (!hasLeasesToShow) {
    return <H3>No current leases to show</H3>;
  }

  return (
    <Stack spacing={Spacing.xl}>
      {A.isNonEmptyArray(leases?.active ?? []) && (
        <Stack spacing={Spacing.md}>
          <H2>Active Leases</H2>
          {leases?.active?.map((lease) => (
            <LeaseInfoCard key={lease.id} lease={lease} />
          ))}
        </Stack>
      )}
      {A.isNonEmptyArray(leases?.upcoming ?? []) && (
        <Stack spacing={Spacing.md}>
          <H2>Upcoming Leases</H2>
          {leases?.upcoming?.map((lease) => (
            <LeaseInfoCard key={lease.id} lease={lease} />
          ))}
        </Stack>
      )}
      {A.isNonEmptyArray(leases?.pending ?? []) && (
        <Stack spacing={Spacing.md}>
          <H2>Pending Leases</H2>
          {leases?.pending?.map((lease) => (
            <LeaseInfoCard key={lease.id} lease={lease} />
          ))}
        </Stack>
      )}
      {A.isNonEmptyArray(leases?.error ?? []) && (
        <Stack spacing={Spacing.md}>
          <H2>Error Leases</H2>
          {leases?.error?.map((lease) => (
            <LeaseInfoCard key={lease.id} lease={lease} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export { CurrentLeases };
