/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/underwriting/preview
 * @function computeUnderwritingPreview
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPIComputeUnderwritingPreviewPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnderwritingModel>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import type { UnderwritingModel } from "@ender/shared/generated/com.ender.buy.model.misc";
import type { PropertyHOAFeeFrequency } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnderwritingAPIComputeUnderwritingPreviewPathParams = {
  dealId: EnderId;
};

type UnderwritingAPIComputeUnderwritingPreviewSearchParams = {
  token?: string | undefined;
};

type UnderwritingAPIComputeUnderwritingPreviewBodyParams = {
  arv?: SerializesInto<Money> | undefined;
  arvReason: string;
  brokerMaxOffer?: SerializesInto<Money> | undefined;
  closingCost?: SerializesInto<Money> | undefined;
  creditLossPercentOverride?: SerializesInto<Percent> | undefined;
  hoaFeeFrequency?: PropertyHOAFeeFrequency | undefined;
  hoaFees?: SerializesInto<Money> | undefined;
  insuranceRateOverride?: SerializesInto<Percent> | undefined;
  leasingOverride?: SerializesInto<Percent> | undefined;
  offerPrice?: SerializesInto<Money> | undefined;
  oneTimeRepairs?: SerializesInto<Money> | undefined;
  petRentOverride?: SerializesInto<Money> | undefined;
  pmFeePercentOverride?: SerializesInto<Percent> | undefined;
  rent?: SerializesInto<Money> | undefined;
  rentComps: string[];
  rentReason: string;
  rentalComparablesId?: EnderId | undefined;
  repairsAndMaintenanceOverride?: SerializesInto<Money> | undefined;
  sellerCredits?: SerializesInto<Money> | undefined;
  sellerTaxes?: SerializesInto<Money> | undefined;
  taxes?: SerializesInto<Money> | undefined;
  turnoverOverride?: SerializesInto<Money> | undefined;
  vacancyPercentOverride?: SerializesInto<Percent> | undefined;
  valueComps: string[];
};

type UnderwritingAPIComputeUnderwritingPreviewPayload =
  UnderwritingAPIComputeUnderwritingPreviewPathParams &
    UnderwritingAPIComputeUnderwritingPreviewSearchParams &
    UnderwritingAPIComputeUnderwritingPreviewBodyParams;

function computeUnderwritingPreviewUnsafeEffect(
  payload: UnderwritingAPIComputeUnderwritingPreviewPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnderwritingModel>({
        body,
        decode: unsafeDecodeJsonResponse<UnderwritingModel>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/underwriting/preview`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function computeUnderwritingPreview(
  payload: UnderwritingAPIComputeUnderwritingPreviewPayload,
  options?: { signal?: AbortSignal },
): Promise<UnderwritingModel> {
  return F.pipe(
    payload,
    computeUnderwritingPreviewUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { computeUnderwritingPreview };
export type {
  UnderwritingAPIComputeUnderwritingPreviewBodyParams,
  UnderwritingAPIComputeUnderwritingPreviewPathParams,
  UnderwritingAPIComputeUnderwritingPreviewPayload,
  UnderwritingAPIComputeUnderwritingPreviewSearchParams,
};
