import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { IconPlus } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Option as O } from "effect";
import { useContext } from "react";

import { Form, useForm } from "@ender/form-system/base";
import { MoveInSettingsContext } from "@ender/shared/contexts/move-in";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { Switch } from "@ender/shared/ds/switch";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { MoveInAPI } from "@ender/shared/generated/ender.api.leasing";
import {
  MoveInConfigRowCapturedDataTypeEffectSchema,
  MoveInConfigRowCapturedDataTypeEnum,
} from "@ender/shared/generated/ender.model.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useSetHeader } from "@ender/shared/hooks/use-set-header";
import type { ChecklistConfigStep } from "@ender/shared/types/move-in";
import { RightRail } from "@ender/shared/ui/right-rail";

import { MoveInSettingsConfigStep } from "./move-in-settings-config-step";
import {
  capturedDataTypeSelectOptions,
  reorderChecklistConfigSteps,
} from "./move-in.utils";

import styles from "./move-in.module.css";

function addChecklistConfigStep(
  checklistConfigStep: Omit<ChecklistConfigStep, "id" | "pmId">,
) {
  return MoveInAPI.addMoveInConfigStep(checklistConfigStep);
}

const AddChecklistConfigFormSchema = Schema.Struct({
  capturedDataType: MoveInConfigRowCapturedDataTypeEffectSchema.pipe(
    Schema.OptionFromSelf,
  ),
  description: Schema.String,
  isRequired: Schema.Boolean,
  title: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Title is required" }),
  ),
});

type AddChecklistConfigFormInput = Schema.Schema.Encoded<
  typeof AddChecklistConfigFormSchema
>;
type AddChecklistConfigFormOutput = Schema.Schema.Type<
  typeof AddChecklistConfigFormSchema
>;

type RightRailProps = {
  opened: boolean;
  onClose: () => void;
};

function AddChecklistConfigRightRail(props: RightRailProps) {
  const { opened, onClose } = props;

  const {
    checklistConfigSteps: [checklistConfigSteps],
    refetch,
  } = useContext(MoveInSettingsContext);

  const form = useForm<AddChecklistConfigFormInput>({
    defaultValues: {
      capturedDataType: O.some(MoveInConfigRowCapturedDataTypeEnum.NONE),
      description: "",
      isRequired: false,
      title: "",
    },
    resolver: effectTsResolver(AddChecklistConfigFormSchema),
  });

  async function handleSubmit(values: AddChecklistConfigFormOutput) {
    const { ...otherValues } = values;

    return await addChecklistConfigStep({
      ...otherValues,
      capturedDataType: O.getOrElse(
        values.capturedDataType,
        () => MoveInConfigRowCapturedDataTypeEnum.NONE,
      ),
      ordering: checklistConfigSteps.length + 1,
    }).then(() => {
      onClose();
      refetch();
      form.reset();
    });
  }

  return (
    <RightRail title="New Checklist Item" opened={opened} onClose={onClose}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <FormTextInput label="Checklist Item Name" name="title" form={form} />
          <FormTextInput label="Description" name="description" form={form} />
          <FormCheckbox label="Required Field" name="isRequired" form={form} />
          <FormSelect
            aria-label="Checklist Item Type"
            name="capturedDataType"
            data={capturedDataTypeSelectOptions}
            form={form}
          />
          <Group justify={Justify.end}>
            <Button variant={ButtonVariant.outlined} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Group>
        </Stack>
      </Form>
    </RightRail>
  );
}

const headerConfig = {
  breadcrumbs: [
    { href: "/firm-settings", title: "Settings" },
    { title: "Move-In Checklist" },
  ],
};

function MoveInSettings() {
  const {
    settings: {
      setIncludeDepositReceipt,
      setElectronicPayments,
      setACHPayments,
      ...settings
    },
    checklistConfigSteps: [checklistConfigSteps, checklistConfigStepHandlers],
    refetch,
  } = useContext(MoveInSettingsContext);

  useSetHeader(headerConfig);

  const [rightRailOpened, { setTrue: openRail, setFalse: closeRail }] =
    useBoolean(false);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = checklistConfigSteps.findIndex(
        ({ id }) => id === active.id,
      );
      const newIndex = checklistConfigSteps.findIndex(
        ({ id }) => id === over?.id,
      );
      checklistConfigStepHandlers.reorder({ from: oldIndex, to: newIndex });
      reorderChecklistConfigSteps(
        checklistConfigSteps,
        oldIndex,
        newIndex,
      ).catch(refetch);
    }
  }

  return (
    <div className={clsx(styles.moveInSettings, "overflow-auto")}>
      <Stack spacing={Spacing.xl}>
        <H1>Global Move-In Settings</H1>
        <Stack spacing={Spacing.lg}>
          {/* <Stack spacing={Spacing.md}>
            <Text size={FontSize.lg} weight={FontWeight.semibold}>
              Checklist Options
            </Text>
            <Switch
              label="Include optional “Other” item at the end of checklist."
              value={settings.includeOther ?? false}
              onChange={(event) => setSettings({ includeOther: event })}
            />
          </Stack> */}
          <Stack spacing={Spacing.md}>
            <Text size={FontSize.lg} weight={FontWeight.semibold}>
              Tenant Portal Default Options
            </Text>
            <Switch
              label="Allow Card Payments in Tenant Portal"
              value={settings.electronicPayments ?? false}
              onChange={(event) => setElectronicPayments(event)}
            />
            <Switch
              label="Allow ACH Payments in Tenant Portal"
              value={settings.achPayments ?? false}
              onChange={(event) => setACHPayments(event)}
            />
          </Stack>
          <Stack spacing={Spacing.md}>
            <Text size={FontSize.lg} weight={FontWeight.semibold}>
              Deposit Receipt Options
            </Text>
            <Switch
              label="Include Security Deposit Receipt Signature"
              value={settings.includeDepositReceipt ?? false}
              onChange={(event) => setIncludeDepositReceipt(event)}
            />
          </Stack>
          {/* <Stack spacing={Spacing.md}>
            <Text size={FontSize.lg} weight={FontWeight.semibold}>
              Apartment Condition Statement
            </Text>
            <Switch
              label="Allow Digital Apartment Condition Statement"
              value={settings.digitalConditionStatement  ?? false}
              onChange={(event) => setSettings({ digitalConditionStatement: event })}
            />
          </Stack> */}
        </Stack>
        <Stack spacing={Spacing.md}>
          <Group justify={Justify.between}>
            <Text size={FontSize.lg} weight={FontWeight.semibold}>
              Create Checklist
            </Text>
            <Button onClick={openRail} leftSection={<IconPlus />}>
              Add New
            </Button>
          </Group>
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={checklistConfigSteps}>
              {checklistConfigSteps.map((step, index) => (
                <MoveInSettingsConfigStep
                  key={step.id}
                  configStep={step}
                  index={index}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Stack>
      </Stack>
      <AddChecklistConfigRightRail
        opened={rightRailOpened}
        onClose={closeRail}
      />
    </div>
  );
}

export { MoveInSettings };
