import { Predicate as P, String as S } from "effect";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";

function removeDecimals(amountMatch: string): string {
  return amountMatch.replace(/\./g, "");
}

type GetAmountMatchForPayloadParams = {
  amountMatch: string | Undefined;
};

/**
 * We want amountMatch to be string without decimal so BE can do fuzzy search
 */
function getAmountMatchForPayload({
  amountMatch,
}: GetAmountMatchForPayloadParams): string | Undefined {
  if (P.isString(amountMatch) && S.isNonEmpty(amountMatch)) {
    return removeDecimals(amountMatch);
  }
  return UNDEFINED;
}

export { getAmountMatchForPayload };
