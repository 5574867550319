/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}/unitZones
 * @function getUnitZones
 * @memberof InspectionAPI
 * @param {InspectionAPIGetUnitZonesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitZonesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitZonesResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetUnitZonesPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIGetUnitZonesSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetUnitZonesPayload = InspectionAPIGetUnitZonesPathParams &
  InspectionAPIGetUnitZonesSearchParams;

function getUnitZonesUnsafeEffect(payload: InspectionAPIGetUnitZonesPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitZonesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<UnitZonesResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}/unitZones`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnitZones(
  payload: InspectionAPIGetUnitZonesPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitZonesResponse> {
  return F.pipe(
    payload,
    getUnitZonesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitZones };
export type {
  InspectionAPIGetUnitZonesPathParams,
  InspectionAPIGetUnitZonesPayload,
  InspectionAPIGetUnitZonesSearchParams,
};
