import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const AutopaySettingsTenantPaymentMethodTypeValues = ["BANK", "CARD"] as const;

const AutopaySettingsTenantPaymentMethodTypeEffectSchema = Schema.Literal(
  ...AutopaySettingsTenantPaymentMethodTypeValues,
);

type AutopaySettingsTenantPaymentMethodType = Schema.Schema.Type<
  typeof AutopaySettingsTenantPaymentMethodTypeEffectSchema
>;

const AutopaySettingsTenantPaymentMethodTypeEnum = castEnum(
  AutopaySettingsTenantPaymentMethodTypeEffectSchema,
);

function randomAutopaySettingsTenantPaymentMethodType(): AutopaySettingsTenantPaymentMethodType {
  return rand(AutopaySettingsTenantPaymentMethodTypeValues);
}

export {
  AutopaySettingsTenantPaymentMethodTypeEffectSchema,
  AutopaySettingsTenantPaymentMethodTypeEnum,
  AutopaySettingsTenantPaymentMethodTypeValues,
  randomAutopaySettingsTenantPaymentMethodType,
};
export type { AutopaySettingsTenantPaymentMethodType };
