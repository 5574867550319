import { Predicate as P } from "effect";
import { useContext } from "react";

import { NULL } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import {
  MoneyTransferTransferTypeEnum,
  PartyEnum,
} from "@ender/shared/generated/ender.model.payments";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

import { ViewCheckButton } from "./view-check-button";
import { VoidPaymentButton } from "./void-payment/void-payment-button";
import { VoidTenantRefundButton } from "./void-tenant-refund-button";

const APPROVED_INVOICE_STATUSES = [
  "PAID", // TODO does the BE ever return "PAID" as a status?
  ApprovableApprovalStatusEnum.APPROVED,
] as ApprovableApprovalStatus[];

type AmountApprovedMenuProps = {
  approvalSteps: GetApprovalProcessResponse;
  invoice: InvoiceSerializerInvoiceResponse;
  onVoidSuccess: () => void;
};

function AmountApprovedMenu({
  approvalSteps,
  invoice,
  onVoidSuccess,
}: AmountApprovedMenuProps) {
  const { hasPermissions } = useContext(UserContext);

  const isTenantRefund = invoice.owedToParty.party === PartyEnum.LEASE;
  const canReverseTenantRefund =
    isTenantRefund &&
    !invoice.paymentInfo?.isReversed &&
    invoice.status === ApprovableApprovalStatusEnum.APPROVED;

  const hasVoidPaymentPermission = hasPermissions(
    FunctionalPermissionEnum.VOID_PAYMENT,
  );
  const showVoidPaymentButton =
    hasVoidPaymentPermission &&
    !canReverseTenantRefund &&
    P.isNotNullable(invoice?.paymentInfo);

  const isApproved = APPROVED_INVOICE_STATUSES.includes(invoice?.status);

  if (P.isNullable(approvalSteps)) {
    return NULL;
  }

  return (
    <>
      {canReverseTenantRefund && <VoidTenantRefundButton invoice={invoice} />}
      {showVoidPaymentButton && (
        <VoidPaymentButton invoice={invoice} onVoidSuccess={onVoidSuccess} />
      )}
      {invoice?.paymentInfo?.type ===
        MoneyTransferTransferTypeEnum.PRINT_CHECK &&
        isApproved && (
          /**
           * 2024-10-16 Geoffrey Hale ENDER-23315:
           *
           * batch-payment-modal requires use of ViewCheckButtonForBatch (/print-batch) and the `checkBatchId`.
           * But thereafter, any invoice that was part of that batch works with ViewCheckButton (/print-check) and its `moneyTransferId` (paymentInfo.id).
           * 1. Is this okay?
           * 2. Is this how it used to work?
           * 3. Is this known/expected?
           */
          <ViewCheckButton moneyTransferId={invoice.paymentInfo.id} />
        )}
    </>
  );
}

export { AmountApprovedMenu };
