import { IconCheck } from "@tabler/icons-react";
import { Predicate as P } from "effect";
import type { PropsWithChildren, ReactNode } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import type { EnderDate } from "@ender/shared/utils/ender-date";

type MoveInStepProps = PropsWithChildren<{
  title: ReactNode;
  description?: ReactNode;
  isRequired?: boolean;
  completed?: boolean;
  completedTime?: EnderDate;
  showDateOnly?: boolean;
  completedText?: string;
  isLoading?: boolean;
}>;

function MoveInStep(props: MoveInStepProps) {
  const {
    title,
    description,
    isRequired,
    completed,
    completedTime,
    showDateOnly = false,
    completedText = "Marked Complete",
    children,
  } = props;

  return (
    <Card>
      <Stack spacing={Spacing.xs}>
        <Stack spacing={Spacing.md}>
          <Text size={FontSize.md} weight={FontWeight.medium}>
            <Group spacing={Spacing.sm}>
              {(completedTime || completed) && (
                <IconCheck color={EnderThemeColorEnum.GREEN} />
              )}
              {title} {!isRequired && "(optional)"}
            </Group>
          </Text>
          {description && <Text size={FontSize.sm}>{description}</Text>}
          <div>{children}</div>
        </Stack>
        <Text color="slate-700" size={FontSize.xs}>
          {P.isNotNullable(completedTime) ? (
            `${completedText} ${showDateOnly ? completedTime.toLongSlashDateString() : completedTime.toTimestampString()}`
          ) : (
            <span>&nbsp;</span>
          )}
        </Text>
      </Stack>
    </Card>
  );
}

export { MoveInStep };
export type { MoveInStepProps };
