/**
 * HTTP Method: POST
 * Pathname: /getBankLinkInfoByModelV1
 * @function getBankLinkInfoByModelV1
 * @memberof DwollaAPI
 * @param {DwollaAPIGetBankLinkInfoByModelV1Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<DwollaAPIGetBankLinkInfoByModelV1Response>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { DwollaAPIGetBankLinkInfoByModelV1Response } from "../dwolla-api-get-bank-link-info-by-model-v1-response";

type DwollaAPIGetBankLinkInfoByModelV1SearchParams = {
  token?: string | undefined;
};

type DwollaAPIGetBankLinkInfoByModelV1BodyParams = {
  modelId: EnderId;
  modelType: ModelType;
};

type DwollaAPIGetBankLinkInfoByModelV1Payload =
  DwollaAPIGetBankLinkInfoByModelV1SearchParams &
    DwollaAPIGetBankLinkInfoByModelV1BodyParams;

function getBankLinkInfoByModelV1UnsafeEffect(
  payload: DwollaAPIGetBankLinkInfoByModelV1Payload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, DwollaAPIGetBankLinkInfoByModelV1Response>({
        body,
        decode:
          unsafeDecodeJsonResponse<DwollaAPIGetBankLinkInfoByModelV1Response>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getBankLinkInfoByModelV1",
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function getBankLinkInfoByModelV1(
  payload: DwollaAPIGetBankLinkInfoByModelV1Payload,
  options?: { signal?: AbortSignal },
): Promise<DwollaAPIGetBankLinkInfoByModelV1Response> {
  return F.pipe(
    payload,
    getBankLinkInfoByModelV1UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankLinkInfoByModelV1 };
export type {
  DwollaAPIGetBankLinkInfoByModelV1BodyParams,
  DwollaAPIGetBankLinkInfoByModelV1Payload,
  DwollaAPIGetBankLinkInfoByModelV1SearchParams,
};
