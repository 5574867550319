/**
 * HTTP Method: POST
 * Pathname: /middleLayer/getGLJournalEntryApprovalRows
 * @function getGLJournalEntryApprovalRows
 * @memberof AccountingMiddleLayerAPI
 * @param {AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetGLJournalEntryApprovalRowsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GetGLJournalEntryApprovalRowsResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsSearchParams = {
  token?: string | undefined;
};

type AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsBodyParams = {
  accountingPeriods: LocalDate[];
  approvalStatuses: ApprovableApprovalStatus[];
  assignedToMe?: boolean | undefined;
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  inclusiveEndLedgerDate?: SerializesInto<LocalDate> | undefined;
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  propertyIds: EnderId[];
  startLedgerDate?: SerializesInto<LocalDate> | undefined;
};

type AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsPayload =
  AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsSearchParams &
    AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsBodyParams;

function getGLJournalEntryApprovalRowsUnsafeEffect(
  payload: AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetGLJournalEntryApprovalRowsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetGLJournalEntryApprovalRowsResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/getGLJournalEntryApprovalRows",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getGLJournalEntryApprovalRows(
  payload: AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetGLJournalEntryApprovalRowsResponse> {
  return F.pipe(
    payload,
    getGLJournalEntryApprovalRowsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getGLJournalEntryApprovalRows };
export type {
  AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsBodyParams,
  AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsPayload,
  AccountingMiddleLayerAPIGetGLJournalEntryApprovalRowsSearchParams,
};
