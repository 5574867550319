import {
  IconCar,
  IconDogBowl,
  IconHistory,
  IconReceipt2,
  IconUserCircle,
} from "@tabler/icons-react";
import { Predicate as P } from "effect";
import * as A from "effect/Array";
import type { ElementRef } from "react";
import { forwardRef, useMemo } from "react";

import type { ApplicationProgressIconProps } from "@ender/entities/application-progress-icon";
import { ApplicationProgressIcon } from "@ender/entities/application-progress-icon";
import { computeBulkApprovalStatus } from "@ender/entities/utils/application-utils";
import { UNDEFINED } from "@ender/shared/constants/general";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { ApplicationGroupApprovalResponseApplicationApprovalResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

type ApplicationReviewProgressProps = {
  approval?: ApplicationGroupApprovalResponseApplicationApprovalResponse;
};

const colorMap: Record<
  ApprovableApprovalStatus,
  ApplicationProgressIconProps["color"]
> = {
  [ApprovableApprovalStatusEnum.APPROVED]: "green",
  [ApprovableApprovalStatusEnum.REJECTED]: "red",
  [ApprovableApprovalStatusEnum.NEW]: "yellow",
};

const indicatorMap: Record<
  ApprovableApprovalStatus,
  ApplicationProgressIconProps["indicator"]
> = {
  [ApprovableApprovalStatusEnum.APPROVED]: "check",
  [ApprovableApprovalStatusEnum.REJECTED]: "x",
  [ApprovableApprovalStatusEnum.NEW]: UNDEFINED,
};

const ApplicationReviewProgress = forwardRef<
  ElementRef<typeof Group>,
  ApplicationReviewProgressProps
>(function ReviewProgress(props, ref) {
  const { approval } = props;
  const {
    personalApproval,
    incomeApproval,
    rentalHistoryApproval,
    vehicleApprovals = [],
    petApprovals = [],
  } = approval ?? {};

  const vehicleApproval = useMemo(() => {
    return computeBulkApprovalStatus(
      vehicleApprovals.map(({ approvalStatus }) => approvalStatus),
    );
  }, [vehicleApprovals]);

  const petApproval = useMemo(() => {
    return computeBulkApprovalStatus(
      petApprovals.map(({ approvalStatus }) => approvalStatus),
    );
  }, [petApprovals]);
  return (
    <Group align={Align.end} ref={ref}>
      {P.isNotNullable(personalApproval) && (
        <ApplicationProgressIcon
          color={
            P.isNotNullable(personalApproval?.status)
              ? colorMap[personalApproval.status]
              : "gray"
          }
          indicator={
            P.isNotNullable(personalApproval?.status)
              ? indicatorMap[personalApproval?.status]
              : UNDEFINED
          }>
          <IconUserCircle />
        </ApplicationProgressIcon>
      )}
      {P.isNotNullable(incomeApproval) && (
        <ApplicationProgressIcon
          color={
            P.isNotNullable(incomeApproval?.status)
              ? colorMap[incomeApproval.status]
              : "gray"
          }
          indicator={
            P.isNotNullable(incomeApproval?.status)
              ? indicatorMap[incomeApproval?.status]
              : UNDEFINED
          }>
          <IconReceipt2 />
        </ApplicationProgressIcon>
      )}
      {P.isNotNullable(rentalHistoryApproval) && (
        <ApplicationProgressIcon
          color={
            P.isNotNullable(rentalHistoryApproval?.status)
              ? colorMap[rentalHistoryApproval?.status]
              : "gray"
          }
          indicator={
            P.isNotNullable(rentalHistoryApproval?.status)
              ? indicatorMap[rentalHistoryApproval?.status]
              : UNDEFINED
          }>
          <IconHistory />
        </ApplicationProgressIcon>
      )}
      {A.isNonEmptyArray(vehicleApprovals) && (
        <ApplicationProgressIcon
          color={colorMap[vehicleApproval]}
          indicator={indicatorMap[vehicleApproval]}>
          <IconCar />
        </ApplicationProgressIcon>
      )}
      {A.isNonEmptyArray(petApprovals) && (
        <ApplicationProgressIcon
          color={colorMap[petApproval]}
          indicator={indicatorMap[petApproval]}>
          <IconDogBowl />
        </ApplicationProgressIcon>
      )}
    </Group>
  );
});

export { ApplicationReviewProgress };

export type { ApplicationReviewProgressProps };
