import { UNDEFINED } from "@ender/shared/constants/general";
import { Overflow, Spacing } from "@ender/shared/ds/flex";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import type { Appliance } from "@ender/shared/generated/ender.model.core.unit";
import type { Photo } from "@ender/shared/generated/ender.model.files";
import { AreasList } from "@ender/widgets/maintenance";

import type { UnitPageRightRailValue } from "../../unit-page-rail/unit-page-rail";
import { UnitPageRightRailEnum } from "../../unit-page-rail/unit-page-rail";
import { AmenitiesCard } from "./amenities-card/amenities-card";
import { AppliancesCard } from "./appliances-card/appliances-card";
import { ImagesCard } from "./images-card/images-card";
import { MarketRentCard } from "./market-rent-card/market-rent-card";
import { SpecificationsCard } from "./specifications-card/specifications-card";
import { VideosCard } from "./videos-card/videos-card";

type UnitPageDetailsProps = {
  unit: UnitSerializerUnitResponse;
  setRightRail: (value: UnitPageRightRailValue) => void;
};

function UnitPageDetails(props: UnitPageDetailsProps) {
  const { unit, setRightRail } = props;

  const handleEditSpecifications = () => {
    setRightRail({
      type: UnitPageRightRailEnum.EDIT_SPECIFICATIONS,
    });
  };

  const handleEditAmenities = (values: {
    unitAmenities: AmenityAmenityType[];
    propertyAmenities: AmenityAmenityType[];
  }) => {
    setRightRail({
      type: UnitPageRightRailEnum.EDIT_AMENITIES,
      ...values,
    });
  };

  const handleEditAppliances = (appliance?: Appliance) => {
    setRightRail({
      appliance,
      type: UnitPageRightRailEnum.ADD_EDIT_APPLIANCES,
    });
  };
  const handleEditImages = (images: Photo[]) => {
    setRightRail({
      type: UnitPageRightRailEnum.EDIT_IMAGES,
      images,
    });
  };
  const handleEditVideos = () => {
    setRightRail({
      type: UnitPageRightRailEnum.EDIT_VIDEOS,
    });
  };
  const handleEditMarketRent = () => {
    setRightRail({
      type: UnitPageRightRailEnum.EDIT_MARKET_RENT,
    });
  };
  return (
    <Stack spacing={Spacing.xl}>
      <Stack overflow={Overflow.auto}>
        <H2>Details</H2>
        <SpecificationsCard unit={unit} onEdit={handleEditSpecifications} />
        <AmenitiesCard unit={unit} onEdit={handleEditAmenities} />
        <MarketRentCard unit={unit} onEdit={handleEditMarketRent} />
      </Stack>
      <Stack>
        <H2>Unit Media</H2>
        <ImagesCard onEdit={handleEditImages} unitId={unit.id} />
        <VideosCard onEdit={handleEditVideos} unit={unit} />
      </Stack>
      <AppliancesCard
        onEdit={handleEditAppliances}
        onAdd={() => handleEditAppliances(UNDEFINED)}
        unitId={unit.id}
      />
      <AreasList unitId={unit.id} />
    </Stack>
  );
}

export { UnitPageDetails };
