import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationGroupCriminalAndEvictionRecommendationValues = [
  "ACCEPT",
  "DECLINE",
  "REFER",
  "PENDING",
  "RECOMMENDATION_PENDING",
  "SEARCH_PENDING",
  "ERROR",
  "CANCELLED",
] as const;

const ApplicationGroupCriminalAndEvictionRecommendationEffectSchema =
  Schema.Literal(...ApplicationGroupCriminalAndEvictionRecommendationValues);

type ApplicationGroupCriminalAndEvictionRecommendation = Schema.Schema.Type<
  typeof ApplicationGroupCriminalAndEvictionRecommendationEffectSchema
>;

const ApplicationGroupCriminalAndEvictionRecommendationEnum = castEnum(
  ApplicationGroupCriminalAndEvictionRecommendationEffectSchema,
);

function randomApplicationGroupCriminalAndEvictionRecommendation(): ApplicationGroupCriminalAndEvictionRecommendation {
  return rand(ApplicationGroupCriminalAndEvictionRecommendationValues);
}

export {
  ApplicationGroupCriminalAndEvictionRecommendationEffectSchema,
  ApplicationGroupCriminalAndEvictionRecommendationEnum,
  ApplicationGroupCriminalAndEvictionRecommendationValues,
  randomApplicationGroupCriminalAndEvictionRecommendation,
};
export type { ApplicationGroupCriminalAndEvictionRecommendation };
