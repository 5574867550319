/**
 * Allocate a "tenant inflow", i.e. a tenant credit (Invoice) or payment (MoneyTransfer) to some GL categories. For
 example, a property manager could declare that a certain tenant credit is meant to pay off their Rent-type charges
 by allocating it to the Rental Income GL category.
 
 Any LedgerAllocations associated with this inflow are wiped out by this operation. Indeed, if a payment had been
 allocated to "Rental Income" and applied to a rent charge, and then subsequently this API endpoint was used to
 allocate the payment to "CAM" instead, it would not make sense for the payment to remain allocated to the rent
 charge. Hence, the allocations should be recomputed (or chosen by the user).
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/allocatePayment
 * @function manuallyAllocateTenantInflow
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPIManuallyAllocateTenantInflowPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TenantLedgerAllocationRequest } from "@ender/shared/generated/ender.api.model";
import type { LedgerEventLedgerEventType } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPIManuallyAllocateTenantInflowPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPIManuallyAllocateTenantInflowSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPIManuallyAllocateTenantInflowBodyParams = {
  /**
   * An array of the form
   */
  allocations: TenantLedgerAllocationRequest[];
  /**
   * The ID of the Invoice or MoneyTransfer
   */
  modelId: EnderId;
  /**
   * INVOICE or MONEY_TRANSFER
   */
  modelType: LedgerEventLedgerEventType;
};

type TenantLedgerAPIManuallyAllocateTenantInflowPayload =
  TenantLedgerAPIManuallyAllocateTenantInflowPathParams &
    TenantLedgerAPIManuallyAllocateTenantInflowSearchParams &
    TenantLedgerAPIManuallyAllocateTenantInflowBodyParams;

function manuallyAllocateTenantInflowUnsafeEffect(
  payload: TenantLedgerAPIManuallyAllocateTenantInflowPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/allocatePayment`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function manuallyAllocateTenantInflow(
  payload: TenantLedgerAPIManuallyAllocateTenantInflowPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    manuallyAllocateTenantInflowUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { manuallyAllocateTenantInflow };
export type {
  TenantLedgerAPIManuallyAllocateTenantInflowBodyParams,
  TenantLedgerAPIManuallyAllocateTenantInflowPathParams,
  TenantLedgerAPIManuallyAllocateTenantInflowPayload,
  TenantLedgerAPIManuallyAllocateTenantInflowSearchParams,
};
