import { useQuery } from "@tanstack/react-query";
import { Equal, Option as O, Predicate as P } from "effect";
import type { MouseEvent } from "react";
import { useContext, useEffect, useState } from "react";



import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import type { Undefined } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import { LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { DateRangeInput } from "@ender/shared/ds/date-range-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { ReportsAPI } from "@ender/shared/generated/ender.api.reports";
import { ReportFilterOperatorEnum } from "@ender/shared/generated/ender.model.reports";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { RightRail } from "@ender/shared/ui/right-rail";



import { removeFilter, widgetToUpdateRequest } from "../../widget-helpers";
import { QUERY_KEY } from "../underwriting-page-helpers";
import { BathsFilter } from "../underwriting-queue-table/filter-fields/baths-filter";
import { BedsFilter } from "../underwriting-queue-table/filter-fields/beds-filter";
import { ChannelFilter } from "../underwriting-queue-table/filter-fields/channel-filter";
import { DealNameFilter } from "../underwriting-queue-table/filter-fields/deal-name-filter";
import { FileNameFilter } from "../underwriting-queue-table/filter-fields/file-name-filter";
import type { FactorValue, Widget, WidgetFactor, WidgetFilter } from "../underwriting-queue-table/filter-fields/filter-types";
import { Factor } from "../underwriting-queue-table/filter-fields/filter-types";
import { ListPriceInputFilter } from "../underwriting-queue-table/filter-fields/list-price-input-filter";
import { SqftFilter } from "../underwriting-queue-table/filter-fields/sqft-filter";
import { YearBuiltInputFilter } from "../underwriting-queue-table/filter-fields/year-built-input-filter";
import { ZipcodeFieldFilter } from "../underwriting-queue-table/filter-fields/zipcode-field-filter";
import { DEFAULT_STATUSES } from "../underwriting-queue-table/underwriting-widget-helpers";
import { getWidget, getYFactor } from "../underwriting-queue-table/widget-helpers";



import styles from "./assigned-underwriting-queue.module.css";





const FILTERABLE_FACTOR_NAMES = [
  Factor.BATHS,
  Factor.BEDS,
  Factor.CHANNEL,
  Factor.NAME,
  Factor.FILE_NAME,
  Factor.MARKET,
  Factor.STATUS,
  Factor.UNDERWRITING_SCORE,
  Factor.UNDERWRITER,
];

// Factors whose metadata is not needed
const IGNORABLE_FILTER_METADATA: FactorValue[] = [
  Factor.STATUS,
  Factor.UNDERWRITING_SCORE,
  Factor.UNDERWRITER,
];

type Filters = {
  [key in FactorValue]?: WidgetFilter[] | undefined;
};

function getDateFilters(
  min: O.Option<LocalDate$.LocalDate>,
  max: O.Option<LocalDate$.LocalDate>,
  factor?: WidgetFactor,
): WidgetFilter[] | Undefined {
  if ((O.isNone(min) && O.isNone(max)) || P.isNullable(factor)) {
    return UNDEFINED;
  }

  const filters: WidgetFilter[] = [];
  if (O.isSome(min)) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
      values: [O.getOrThrow(min).toJSON()],
    });
  }

  if (O.isSome(max)) {
    filters.push({
      factor,
      operator: DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
      values: [O.getOrThrow(max).toJSON()],
    });
  }

  return filters;
}

function getDefaultMinValue(widget: Widget) {
  const min = widget.filters.find(
    ({ factor, operator }) =>
      (factor.name === Factor.LIST_DATE ||
        factor.name === Factor.UPLOAD_DATE) &&
      operator === DynamicTableOperatorsEnum.GREATER_THAN_OR_EQUAL,
  )?.values?.[0];

  return P.isNotNullable(min) ? LocalDate$.parse(min as string) : O.none();
}

function getDefaultMaxValue(widget: Widget) {
  const max = widget.filters.find(
    ({ factor, operator }) =>
      (factor.name === Factor.LIST_DATE ||
        factor.name === Factor.UPLOAD_DATE) &&
      operator === DynamicTableOperatorsEnum.LESS_THAN_OR_EQUAL,
  )?.values?.[0];

  return P.isNotNullable(max) ? LocalDate$.parse(max as string) : O.none();
}

function isValidDateRange(
  min: O.Option<LocalDate$.LocalDate>,
  max: O.Option<LocalDate$.LocalDate>,
): boolean {
  return !(O.isSome(min) && O.isSome(max) && min.value.isAfter(max.value));
}

type AssignedUnderwritingFiltersRailProps = {
  close: () => void;
  displayRightRail: boolean;
  onFilterChanged: () => void;
  widget: Widget;
};

function AssignedUnderwritingFiltersRail({
  close,
  displayRightRail,
  onFilterChanged,
  widget,
}: AssignedUnderwritingFiltersRailProps) {
  const [filters, setFilters] = useState<Filters>({});
  const [applyingFilters, setApplyingFilter] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { user } = useContext(UserContext);

  // lazy useEffect
  useEffect(() => {
    if (!widget) {
      return;
    }

    setFilters(
      widget.filters.reduce<Filters>((acc, filter) => {
        if (filter.factor.name === Factor.STATUS) {
          return acc;
        }

        if (!acc[filter.factor.name]) {
          acc[filter.factor.name] = [];
        }

        acc[filter.factor.name]?.push(filter);
        return {
          ...acc,
        };
      }, {}),
    );
  }, [widget]);

  const { data: filterWidget } = useQuery<Widget>({
    queryFn: async () => {
      const widget = await getWidget("assigned-underwriting-queue-filter", {
        requiredFilters: [
          {
            factorName: Factor.STATUS,
            operator: ReportFilterOperatorEnum.IN,
            values: [...DEFAULT_STATUSES],
          },
          {
            factorName: Factor.UNDERWRITER,
            operator: ReportFilterOperatorEnum.IN,
            values: [user.id],
          },
        ],
        requiredYFactors: {
          factorNames: FILTERABLE_FACTOR_NAMES,
        },
      });
      return widget;
    },
    queryKey: [QUERY_KEY.ASSIGNED_UNDERWRITING_FILTER_WIDGET] as const,
    refetchOnWindowFocus: false,
    staleTime: Number.MAX_SAFE_INTEGER,
  });

  const { data: filterMetadata = {} } = useQuery({
    queryKey: [
      QUERY_KEY.ASSIGNED_UNDERWRITING_FILTER_METADATA,
      filterWidget?.id,
    ] as const,
    queryFn: async ({ signal }) => {
      if (P.isUndefined(filterWidget)) {
        return {};
      }

      const filterableFactors = FILTERABLE_FACTOR_NAMES.map((factorName) =>
        filterWidget.yFactors.find(({ name }) => name === factorName),
      )
        .filter(P.isNotNullable)
        .filter(({ name }) => !IGNORABLE_FILTER_METADATA.includes(name));

      const metadata = await ReportsAPI.getFactorMetadataForWidget(
        {
          factorIds: filterableFactors.map(({ id }) => id),
          widgetId: filterWidget.id,
        },
        { signal },
      );

      return { metadata, filterableFactors };
    },
    select: ({ metadata, filterableFactors }) =>
      Object.entries(metadata).reduce((acc, [factorId, values]) => {
        const factor = filterableFactors?.find(({ id }) => id === factorId);
        if (!factor || !factor.name) {
          return acc;
        }
        return {
          ...acc,
          [factor.name]: {
            factor,
            // @ts-expect-error need to update type of values
            values: values.sort((a, b) => (a.value < b.value ? -1 : 1)),
          },
        };
      }, {}),
    staleTime: Number.MAX_SAFE_INTEGER,
    refetchOnWindowFocus: false,
    enabled: P.isNotNullable(filterWidget),
  });

  // lazy useEffect
  useEffect(() => {
    setApplyingFilter(false);
  }, [displayRightRail]);

  async function applyFilters(filterFields: Filters) {
    setApplyingFilter(true);

    const updatedWidget = { ...widget };

    Object.keys(filterFields).forEach((filterName) => {
      removeFilter(updatedWidget, filterName as FactorValue);

      const filters = filterFields[filterName as FactorValue];
      if (filters) {
        updatedWidget.filters.push(...filters);
      }
    });

    await ReportsAPI.updateWidget({
      widgetId: widget.id,
      ...widgetToUpdateRequest(updatedWidget),
    });

    onFilterChanged();
    close();
  }

  function handleReset(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const clearedFilters = widget.filters
      .filter(
        ({ factor }) =>
          factor.name !== Factor.STATUS && factor.name !== Factor.UNDERWRITER,
      )
      .reduce(
        (acc, { factor }) => ({
          ...acc,
          [factor.name]: UNDEFINED,
        }),
        {},
      );

    setFilters(clearedFilters);
    applyFilters(clearedFilters);
  }

  function updateFieldFilter(
    filterName: FactorValue,
    newFilters: WidgetFilter[] | undefined,
  ) {
    if (Equal.equals(filters[filterName], newFilters)) {
      return;
    }

    setFilters({
      ...filters,
      [filterName]: newFilters,
    });
  }

  function handleSubmit() {
    applyFilters(filters);
  }

  const [min, setMin] = useState<O.Option<LocalDate$.LocalDate>>(
    getDefaultMinValue(widget),
  );
  const [max, setMax] = useState<O.Option<LocalDate$.LocalDate>>(
    getDefaultMaxValue(widget),
  );

  const loading = !widget || !filterMetadata;

  if (loading) {
    return NULL;
  }

  return (
    <RightRail
      opened={displayRightRail}
      onClose={close}
      title="Filters"
      trapFocus={false}>
      <Skeleton visible={loading}>
        <Stack spacing={Spacing.md}>
          <div className={`${styles.filterGroup} ${styles.filterGroupTop}`}>
            <ListPriceInputFilter
              updateFilters={updateFieldFilter}
              widget={widget}
              factor={getYFactor(widget, Factor.LIST_PRICE)}
              setHasError={setHasError}
            />
            <DateRangeInput
              label="List Dates"
              value={[min, max]}
              onChange={([newMin, newMax]) => {
                setMin(newMin);
                setMax(newMax);
                const filters = getDateFilters(
                  newMin,
                  newMax,
                  getYFactor(widget, Factor.LIST_DATE),
                );
                updateFieldFilter(Factor.LIST_DATE, filters);
                setHasError(!isValidDateRange(newMin, newMax));
              }}
              fromPlaceholder="Start List Date"
              toPlaceholder="End List Date"
            />
          </div>
          <div className={styles.filterGroup}>
            <BedsFilter
              factor={getYFactor(widget, Factor.BEDS)}
              widget={widget}
              updateFilters={updateFieldFilter}
              metadata={{
                beds:
                  // @ts-expect-error need to update type of filterMetadata
                  filterMetadata[Factor.BEDS]?.values.map(
                    // @ts-expect-error need to update type of filterMetadata
                    ({ value }) => value,
                  ) || [],
              }}
            />
            <BathsFilter
              factor={getYFactor(widget, Factor.BATHS)}
              widget={widget}
              updateFilters={updateFieldFilter}
              metadata={{
                baths:
                  // @ts-expect-error need to update type of filterMetadata
                  filterMetadata[Factor.BATHS]?.values.map(
                    // @ts-expect-error need to update type of filterMetadata
                    ({ value }) => value,
                  ) || [],
              }}
            />
            <SqftFilter
              updateFilters={updateFieldFilter}
              widget={widget}
              factor={getYFactor(widget, Factor.SQFT)}
            />
            <YearBuiltInputFilter
              updateFilters={updateFieldFilter}
              widget={widget}
              factor={getYFactor(widget, Factor.YEAR_BUILT)}
              setHasError={setHasError}
            />
          </div>
          <div className={styles.filterGroup}>
            <ChannelFilter
              updateFilters={updateFieldFilter}
              widget={widget}
              factor={getYFactor(widget, Factor.CHANNEL)}
              metadata={{
                // @ts-expect-error need to update type of filterMetadata
                channels: filterMetadata[Factor.CHANNEL]?.values.map(
                  // @ts-expect-error need to update type of filterMetadata
                  ({ value }) => value,
                ),
              }}
            />
            <DealNameFilter
              updateFilters={updateFieldFilter}
              widget={widget}
              factor={getYFactor(widget, Factor.NAME)}
              metadata={{
                // @ts-expect-error need to update type of filterMetadata
                dealNames: filterMetadata[Factor.NAME]?.values.map(
                  // @ts-expect-error need to update type of filterMetadata
                  ({ value }) => value,
                ),
              }}
            />
            <ZipcodeFieldFilter
              updateFilters={updateFieldFilter}
              widget={widget}
              factor={getYFactor(widget, Factor.ZIPCODE)}
            />
          </div>
          <div className={styles.filterGroup}>
            <FileNameFilter
              updateFilters={updateFieldFilter}
              widget={widget}
              factor={getYFactor(widget, Factor.FILE_NAME)}
              metadata={{
                // @ts-expect-error need to update type of filterMetadata
                fileNames: filterMetadata[Factor.FILE_NAME]?.values.map(
                  // @ts-expect-error need to update type of filterMetadata
                  ({ value }) => value,
                ),
              }}
            />
            <DateRangeInput
              label="Upload Dates"
              value={[min, max]}
              onChange={([newMin, newMax]) => {
                setMin(newMin);
                setMax(newMax);
                setHasError(!isValidDateRange(newMin, newMax));
              }}
              fromPlaceholder="Start Date"
              toPlaceholder="End Date"
            />
          </div>
          <Group justify={Justify.end}>
            <Button
              variant={ButtonVariant.outlined}
              onClick={handleReset}
              disabled={applyingFilters}>
              Reset
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={applyingFilters || hasError}>
              Apply Filters
            </Button>
          </Group>
        </Stack>
      </Skeleton>
    </RightRail>
  );
}

export { AssignedUnderwritingFiltersRail, FILTERABLE_FACTOR_NAMES };