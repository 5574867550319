/**
 * HTTP Method: GET
 * Pathname: /collections/lease/{leaseId}/summary
 * @function getLeaseSummary
 * @memberof CollectionsAPI
 * @param {CollectionsAPIGetLeaseSummaryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeaseCollectionsSummaryResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeaseCollectionsSummaryResponse } from "@ender/shared/generated/ender.api.finance.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIGetLeaseSummaryPathParams = {
  leaseId: EnderId;
};

type CollectionsAPIGetLeaseSummarySearchParams = {
  token?: string | undefined;
};

type CollectionsAPIGetLeaseSummaryPayload =
  CollectionsAPIGetLeaseSummaryPathParams &
    CollectionsAPIGetLeaseSummarySearchParams;

function getLeaseSummaryUnsafeEffect(
  payload: CollectionsAPIGetLeaseSummaryPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeaseCollectionsSummaryResponse>({
        body,
        decode: unsafeDecodeJsonResponse<LeaseCollectionsSummaryResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/collections/lease/${leaseId}/summary`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeaseSummary(
  payload: CollectionsAPIGetLeaseSummaryPayload,
  options?: { signal?: AbortSignal },
): Promise<LeaseCollectionsSummaryResponse> {
  return F.pipe(
    payload,
    getLeaseSummaryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeaseSummary };
export type {
  CollectionsAPIGetLeaseSummaryPathParams,
  CollectionsAPIGetLeaseSummaryPayload,
  CollectionsAPIGetLeaseSummarySearchParams,
};
