/**
 * HTTP Method: DELETE
 * Pathname: /accounting/journal-entries/attachment/{fileUploadId}
 * @function deleteGLJournalEntryAttachment
 * @memberof AccountingAPI
 * @param {AccountingAPIDeleteGLJournalEntryAttachmentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIDeleteGLJournalEntryAttachmentPathParams = {
  fileUploadId: EnderId;
};

type AccountingAPIDeleteGLJournalEntryAttachmentSearchParams = {
  token?: string | undefined;
};

type AccountingAPIDeleteGLJournalEntryAttachmentPayload =
  AccountingAPIDeleteGLJournalEntryAttachmentPathParams &
    AccountingAPIDeleteGLJournalEntryAttachmentSearchParams;

function deleteGLJournalEntryAttachmentUnsafeEffect(
  payload: AccountingAPIDeleteGLJournalEntryAttachmentPayload,
) {
  const { fileUploadId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/accounting/journal-entries/attachment/${fileUploadId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteGLJournalEntryAttachment(
  payload: AccountingAPIDeleteGLJournalEntryAttachmentPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteGLJournalEntryAttachmentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteGLJournalEntryAttachment };
export type {
  AccountingAPIDeleteGLJournalEntryAttachmentPathParams,
  AccountingAPIDeleteGLJournalEntryAttachmentPayload,
  AccountingAPIDeleteGLJournalEntryAttachmentSearchParams,
};
