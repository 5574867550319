import {
  Array as A,
  Function as F,
  Match,
  Number as Num,
  Option as O,
  String as Str,
} from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useMemo, useState } from "react";

import { TriggerButton, TriggerButtonSize } from "@ender/shared/ds/menu";
import type { ShowingReservationStatus } from "@ender/shared/generated/ender.model.leasing";
import { ShowingReservationStatusEnum } from "@ender/shared/generated/ender.model.leasing";
import { fromScreamingSnakeCaseToSpaceCase } from "@ender/shared/utils/string";
import { MultiFilter } from "@ender/widgets/filters/multi-filter";

type StatusFilterProps = {
  disabled?: boolean;
  onChange: (value: ShowingReservationStatus[]) => void;
  value: ShowingReservationStatus[];
};

const StatusFilter = forwardRef<
  ElementRef<typeof MultiFilter>,
  StatusFilterProps
>(function StatusFilter({ disabled, onChange, value }, ref) {
  const [keyword, setKeywordValue] = useState<string>("");

  const statusOptions = useMemo(() => {
    return Object.values(ShowingReservationStatusEnum).map((status) => ({
      label: fromScreamingSnakeCaseToSpaceCase(status),
      value: status,
    }));
  }, []);

  const filteredOptions = useMemo(() => {
    const searchText = Str.toUpperCase(keyword);
    return F.pipe(
      statusOptions,
      A.filter((opt) =>
        F.pipe(opt.label, Str.toUpperCase, Str.includes(searchText)),
      ),
    );
  }, [keyword, statusOptions]);

  const onKeywordChange = useCallback((keyword: string) => {
    setKeywordValue(keyword);
  }, []);

  const triggerLabel = useMemo(() => {
    const headLabel = F.pipe(
      value,
      A.head,
      O.map((opt) => `: ${fromScreamingSnakeCaseToSpaceCase(opt)}`),
      O.getOrElse(F.constant("")),
    );
    const countLabel = Match.value(A.length(value)).pipe(
      Match.when(Num.greaterThan(1), (size) => ` +${size - 1}`),
      Match.orElse(F.constant("")),
    );
    return `Status${headLabel}${countLabel}`;
  }, [value]);

  const handleChange = useCallback(
    (selectedOptions: { label: string; value: ShowingReservationStatus }[]) => {
      const selectedValues = selectedOptions.map((opt) => opt.value);
      onChange(selectedValues);
    },
    [onChange],
  );

  return (
    <MultiFilter
      label="Status Filter"
      data={filteredOptions}
      disabled={disabled}
      keyword={keyword}
      isLoading={false}
      onChange={handleChange}
      onKeywordChange={onKeywordChange}
      ref={ref}
      trigger={
        <TriggerButton
          rounded={false}
          disabled={disabled}
          size={TriggerButtonSize.sm}>
          {triggerLabel}
        </TriggerButton>
      }
      value={value.map((status) => ({
        label: fromScreamingSnakeCaseToSpaceCase(status),
        value: status,
      }))}
    />
  );
});

export { StatusFilter };
export type { StatusFilterProps };
