import type { PropsWithChildren } from "react";

import { FormProvider } from "../contexts/form-context";
import { FormSectionContext } from "../contexts/form-section-context";
import type { UseFormHandleSubmit, UseFormReturn } from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormProps<Form extends UseFormReturn<any, any, any>> = {
  form: Form;
  onSubmit: Parameters<UseFormHandleSubmit<Form>>[0];
  onError?: Parameters<UseFormHandleSubmit<Form>>[1];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Form<T extends UseFormReturn<any, any, any>>({
  form,
  onSubmit,
  onError,
  children,
}: PropsWithChildren<FormProps<T>>) {
  const { handleSubmit } = form;

  return (
    <FormProvider form={form}>
      <FormSectionContext.Provider value={{}}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onSubmit, onError)(e);
          }}>
          {children}
        </form>
      </FormSectionContext.Provider>
    </FormProvider>
  );
}

export { Form };
export type { FormProps };
