import { Predicate as P } from "effect";
import type { ReactNode } from "react";

import type { Null } from "@ender/shared/constants/general";
import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type {
  LeaseSerializerLeaseContact,
  LeaseSerializerLeaseResponse,
} from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { LeaseLeaseSubstatus } from "@ender/shared/generated/ender.model.leasing";
import {
  LeaseLeaseStatusEnum,
  LeaseLeaseSubstatusEnum,
  LeaseUserRoleLeaseUserFlagEnum,
} from "@ender/shared/generated/ender.model.leasing";
import type { LeaseDisplayStatus } from "@ender/shared/types/ender-general";
import { LeaseDisplayStatusEnum } from "@ender/shared/types/ender-general";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { renderPrivateContact } from "@ender/shared/utils/user";

const displayLeaseStatusMap = new Map<LeaseLeaseSubstatus, LeaseDisplayStatus>([
  [
    LeaseLeaseSubstatusEnum.OUT_FOR_SIGNATURES,
    LeaseDisplayStatusEnum.OUT_FOR_SIGNATURES,
  ],
  [LeaseLeaseSubstatusEnum.CREATING_PDF, LeaseDisplayStatusEnum.CREATING_PDF],
  [
    LeaseLeaseSubstatusEnum.FINAL_SIGNATURE,
    LeaseDisplayStatusEnum.NEEDS_FINAL_SIGNATURE,
  ],
  [LeaseLeaseSubstatusEnum.DRAFTING, LeaseDisplayStatusEnum.DRAFTING],
  [LeaseLeaseSubstatusEnum.CANCELED, LeaseDisplayStatusEnum.CANCELED],
  [LeaseLeaseSubstatusEnum.DRAFTING_RENEWAL, LeaseDisplayStatusEnum.DRAFTING],
  [LeaseLeaseSubstatusEnum.ERROR, LeaseDisplayStatusEnum.ERROR],
]);

/**
 * @deprecated
 * @description Returns Lease display status based on lease sub-status (deprecated)
 */
function getLeaseDisplayStatusFromSubstatus(
  LeaseLeaseSubstatus: LeaseLeaseSubstatus,
): LeaseDisplayStatus {
  switch (LeaseLeaseSubstatus) {
    case LeaseLeaseSubstatusEnum.ACTIVE_EVICTING:
    case LeaseLeaseSubstatusEnum.ACTIVE_NON_RENEWABLE:
    case LeaseLeaseSubstatusEnum.ACTIVE_RENEWED:
    case LeaseLeaseSubstatusEnum.ACTIVE:
    case LeaseLeaseSubstatusEnum.EXPIRING:
    case LeaseLeaseSubstatusEnum.MOVE_OUT_OVERDUE:
    case LeaseLeaseSubstatusEnum.ON_NOTICE_EVICTING:
    case LeaseLeaseSubstatusEnum.ON_NOTICE:
    case LeaseLeaseSubstatusEnum.MONTH_TO_MONTH_POST_TERM:
    case LeaseLeaseSubstatusEnum.MONTH_TO_MONTH:
      return LeaseDisplayStatusEnum.ACTIVE;
    case LeaseLeaseSubstatusEnum.CANCELED:
      return LeaseDisplayStatusEnum.CANCELED;
    case LeaseLeaseSubstatusEnum.CREATING_PDF:
    case LeaseLeaseSubstatusEnum.DRAFTING_RENEWAL:
    case LeaseLeaseSubstatusEnum.DRAFTING:
      return LeaseDisplayStatusEnum.DRAFTING;
    case LeaseLeaseSubstatusEnum.ERROR:
    case LeaseLeaseSubstatusEnum.MOVE_OUT_BALANCE:
    case LeaseLeaseSubstatusEnum.MOVE_OUT_PENDING_DEPOSIT_ACCOUNTING:
    case LeaseLeaseSubstatusEnum.MOVE_OUT_REFUND:
    case LeaseLeaseSubstatusEnum.MOVED_OUT:
    case LeaseLeaseSubstatusEnum.RENEWED:
      return LeaseDisplayStatusEnum.INACTIVE;
    case LeaseLeaseSubstatusEnum.FINAL_SIGNATURE:
      return LeaseDisplayStatusEnum.NEEDS_FINAL_SIGNATURE;
    case LeaseLeaseSubstatusEnum.OUT_FOR_SIGNATURES:
      return LeaseDisplayStatusEnum.OUT_FOR_SIGNATURES;
    case LeaseLeaseSubstatusEnum.UPCOMING:
      return LeaseDisplayStatusEnum.UPCOMING;
    default:
      return LeaseDisplayStatusEnum.INACTIVE;
  }
}

/**
 * @typedef {import("@ender/shared/types/ender-general").LeaseDisplayStatusEnum} LeaseDisplayStatusEnum
 */

/**
 * @function getLeaseDisplayStatus
 * @description returns lease status text to be displayed in UI
 */
function getLeaseDisplayStatus(
  lease: Pick<
    LeaseSerializerLeaseResponse,
    | "substatus"
    | "isUpcoming"
    | "hasRenewal"
    | "endDate"
    | "actualMoveOutDate"
    | "expectedMoveOutDate"
  >,
): LeaseDisplayStatus {
  if (displayLeaseStatusMap.has(lease.substatus)) {
    return displayLeaseStatusMap.get(lease.substatus) as LeaseDisplayStatus;
  }

  if (lease.isUpcoming) {
    return LeaseDisplayStatusEnum.UPCOMING;
  }

  const { hasRenewal, actualMoveOutDate, expectedMoveOutDate } = lease;

  /**
   * If this lease has an executed renewal and, either:
   *
   * a) the scheduled move out date is in the past
   * b) the end date is in the past
   *
   * then this lease is inactive
   */
  if (
    hasRenewal &&
    P.isNotNullable(expectedMoveOutDate) &&
    EnderDate.isDateInPast(expectedMoveOutDate)
  ) {
    return LeaseDisplayStatusEnum.INACTIVE;
  }

  /**
   *  If this lease does _not_ have an executed renewal, and:
   *
   * the lease has an actual move out date in the past
   *
   * then this lease is inactive
   */
  if (
    !hasRenewal &&
    P.isNotNullable(actualMoveOutDate) &&
    EnderDate.isDateInPast(actualMoveOutDate)
  ) {
    return LeaseDisplayStatusEnum.INACTIVE;
  }

  return LeaseDisplayStatusEnum.ACTIVE;
}

/**
 * converts statuses into more readable strings. Incidentally, this also narrows the amount of
 * categories that statuses fall into, which makes it ideal for applying these values as css
 * to style the units.
 * @param {string} status from the getLeaseStatusClassName
 */
function prettifyStatus(status: string) {
  switch (status) {
    case "active":
    case "month-to-month":
      return "Occupied";
    case "on-notice":
      return "On Notice";
    case "active--expiring":
    case "month-to-month--expiring":
      return "Occupied, expiring";
    case "upcoming":
      return "Upcoming";
    case "drafting":
    case "final-signature":
    case "out-for-signatures":
      return "Drafting";
    case "moved-out":
    case "canceled":
    default:
      return "Vacant";
  }
}

/**
 * @function isFinanciallyResponsibleContact
 * @param {Lease} lease
 * @param {{ id: EnderId }} contact
 * @returns {boolean}
 */
function isFinanciallyResponsibleContact(
  lease: Pick<LeaseSerializerLeaseResponse, "contacts">,
  contact: { id: EnderId },
) {
  return lease?.contacts?.some(
    (leaseContact) =>
      leaseContact.id === contact.id &&
      leaseContact.roles.includes(
        LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
      ),
  );
}

/**
 * @function getMainContact
 * @param {Lease} lease
 * @note lease contacts should be returned from backend sorted by main contact requirements.
 * @returns {LeaseSerializerLeaseContact}
 */
function getMainContact(lease: {
  contacts: LeaseSerializerLeaseContact[];
}): LeaseSerializerLeaseContact | Null {
  if (lease?.contacts && lease.contacts[0]) {
    return lease.contacts[0];
  }
  return NULL;
}

function getLeaseTitle(
  lease: Pick<LeaseSerializerLeaseResponse, "contacts" | "companyName">,
): ReactNode {
  const { contacts, companyName } = lease;

  if (companyName) {
    return companyName;
  }

  if (contacts?.length === 0) {
    return "Lease";
  }

  const mainContact = getMainContact(lease);
  let mainContactName: ReactNode = "No Main Contact";

  if (mainContact?.name) {
    mainContactName = renderPrivateContact(mainContact);
  }

  if (contacts?.length === 1) {
    return mainContactName;
  }

  const numAdditionalContacts = contacts && contacts.length - 1;
  return (
    <>
      {mainContactName} + {numAdditionalContacts}
    </>
  );
}

const upcomingStatuses: LeaseLeaseSubstatus[] = [
  LeaseLeaseSubstatusEnum.UPCOMING,
  LeaseLeaseSubstatusEnum.OUT_FOR_SIGNATURES,
  LeaseLeaseSubstatusEnum.FINAL_SIGNATURE,
];

function isAtMoveInStage(lease: LeaseSerializerLeaseResponse) {
  const { moveInCompleteTime, status, substatus, previousRenewedLeaseId } =
    lease;
  return (
    !previousRenewedLeaseId &&
    !P.isNotNullable(moveInCompleteTime) &&
    status === LeaseLeaseStatusEnum.EXECUTED &&
    upcomingStatuses.includes(substatus)
  );
}

export {
  getLeaseDisplayStatus,
  getLeaseDisplayStatusFromSubstatus,
  getLeaseTitle,
  getMainContact,
  isAtMoveInStage,
  isFinanciallyResponsibleContact,
  prettifyStatus,
};
