import { createBuyBox } from "./create-buy-box";
import { deleteBuyBox } from "./delete-buy-box";
import { getBuyBoxes } from "./get-buy-boxes";
import { getPropertyBuyBoxes } from "./get-property-buy-boxes";
import { updateBuyBox } from "./update-buy-box";

const BuyBoxAPI = {
  createBuyBox,
  deleteBuyBox,
  getBuyBoxes,
  getPropertyBuyBoxes,
  updateBuyBox,
};

export { BuyBoxAPI };
