/**
 * HTTP Method: POST
 * Pathname: /accounting/journal-entries/details/{journalEntryId}
 * @function getJournalEntryDetails
 * @memberof AccountingAPI
 * @param {AccountingAPIGetJournalEntryDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetGLJournalEntryResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetGLJournalEntryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIGetJournalEntryDetailsPathParams = {
  journalEntryId: EnderId;
};

type AccountingAPIGetJournalEntryDetailsSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetJournalEntryDetailsPayload =
  AccountingAPIGetJournalEntryDetailsPathParams &
    AccountingAPIGetJournalEntryDetailsSearchParams;

function getJournalEntryDetailsUnsafeEffect(
  payload: AccountingAPIGetJournalEntryDetailsPayload,
) {
  const { journalEntryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetGLJournalEntryResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetGLJournalEntryResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accounting/journal-entries/details/${journalEntryId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getJournalEntryDetails(
  payload: AccountingAPIGetJournalEntryDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetGLJournalEntryResponse> {
  return F.pipe(
    payload,
    getJournalEntryDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getJournalEntryDetails };
export type {
  AccountingAPIGetJournalEntryDetailsPathParams,
  AccountingAPIGetJournalEntryDetailsPayload,
  AccountingAPIGetJournalEntryDetailsSearchParams,
};
