import { IconArrowsExchange, IconInfoCircle } from "@tabler/icons-react";
import { Option as O } from "effect";
import { useEffect, useRef } from "react";
import { useWatch } from "react-hook-form";

import {
  FormSearchInput,
  hydrateCategory,
  searchGetTxCategories,
} from "@ender/entities/search-input";
import type { FormSubSectionReference } from "@ender/form-system/base";
import { FormSection } from "@ender/form-system/base";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";

import { AllocationTypeEnum } from "./journal-entry-right-rail.types";
import type {
  JournalEntryFormAllocationInput,
  JournalEntryFormOutput,
} from "./journal-entry-right-rail.types";

import styles from "./debit-credit-allocation-list-item.module.css";

type DebitCreditAllocationListItemProps = {
  canRemove: boolean;
  isLast: boolean;
  isReadonly: boolean;
  onRemove: () => void;
} & FormSubSectionReference<
  JournalEntryFormOutput,
  JournalEntryFormAllocationInput
>;

function DebitCreditAllocationListItem(
  props: DebitCreditAllocationListItemProps,
) {
  const { canRemove, form, name, isReadonly, onRemove } = props;
  const isFirstRender = useRef(true);

  const type = useWatch({ control: form.control, name: `${name}.type` });
  const [debits, credits] = useWatch({
    control: form.control,
    name: [`${name}.debits`, `${name}.credits`],
  });

  const handleTypeChange = () => {
    const currentType =
      type === AllocationTypeEnum.DEBIT
        ? AllocationTypeEnum.CREDIT
        : AllocationTypeEnum.DEBIT;
    form.setValue(`${name}.type`, currentType);
    if (currentType === AllocationTypeEnum.CREDIT) {
      form.setValue(`${name}.credits`, debits);
      form.setValue(`${name}.debits`, O.none());
    } else if (currentType === AllocationTypeEnum.DEBIT) {
      form.setValue(`${name}.debits`, credits);
      form.setValue(`${name}.credits`, O.none());
    }
  };

  // Tyler 11-18-24 - This is a workaround to ensure error is cleared for debits if a value is entered in credits, or vise versa. Also sets the debits or credits to none based on type.
  useEffect(() => {
    if (O.isSome(debits)) {
      form.clearErrors(`${name}.credits`);
    }
    if (O.isSome(credits)) {
      form.clearErrors(`${name}.debits`);
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (type === AllocationTypeEnum.CREDIT) {
        form.setValue(`${name}.debits`, O.none());
      }
      if (type === AllocationTypeEnum.DEBIT) {
        form.setValue(`${name}.credits`, O.none());
      }
    }
  }, [form, name, debits, credits, type]);

  /*
   Tyler 11-15-24 -- Please note, credits and debits are not part of the payload.  
    These are fields which exist prior to form submission and effectively become the amount field. 
    This is necessary because the previous configuration used a single amount field but within the form system,
    this causes the Credits and Debits fields below to update simultaneously
  */

  return (
    <FormSection form={form} name={name}>
      {({ section }) => {
        return (
          <Stack>
            <Group justify={Justify.end}>
              <Button
                onClick={onRemove}
                variant={ButtonVariant.transparent}
                disabled={!canRemove || isReadonly}>
                Remove Allocation
              </Button>
            </Group>
            <FormSearchInput<EnderId, typeof form, typeof section.glCategoryId>
              disabled={isReadonly}
              label="GL Category"
              search={searchGetTxCategories}
              hydrate={hydrateCategory}
              searchOnEmpty
              modelType={ModelTypeEnum.GL_CATEGORY}
              form={form}
              name={section.glCategoryId}
              clearable
            />
            <div className={styles.allocationAmounts} style={{ width: "100%" }}>
              <FormMoneyInput
                label="Debit"
                name={section.debits}
                form={form}
                data-type={AllocationTypeEnum.DEBIT}
                disabled={isReadonly || type === AllocationTypeEnum.CREDIT}
              />
              <Group align={Align.start}>
                <div className={styles.allocationArrow}>
                  <ActionIcon
                    variant={ButtonVariant.transparent}
                    onClick={handleTypeChange}>
                    <IconArrowsExchange color={EnderThemeColorEnum.PRIMARY} />
                  </ActionIcon>
                </div>
              </Group>
              <FormMoneyInput
                label="Credit"
                name={section.credits}
                form={form}
                data-type={AllocationTypeEnum.CREDIT}
                disabled={isReadonly || type === AllocationTypeEnum.DEBIT}
              />
            </div>
            <FormTextInput
              name={section.description}
              form={form}
              label={
                <Group spacing={Spacing.xs}>
                  <Text size={FontSize.sm} weight={FontWeight.medium}>
                    Allocation Description (optional)
                  </Text>
                  <Tooltip label="The Journal Entry Title will be used if not provided.">
                    <IconInfoCircle
                      color={EnderThemeColorEnum.PRIMARY}
                      size="12"
                    />
                  </Tooltip>
                </Group>
              }
              disabled={isReadonly}
              placeholder="Enter a transaction description (optional)"
            />
          </Stack>
        );
      }}
    </FormSection>
  );
}

export { DebitCreditAllocationListItem };
