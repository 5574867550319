/**
 * HTTP Method: POST
 * Pathname: /buy/deals/{dealId}/inspections
 * @function createInspection
 * @memberof BuyAPI
 * @param {BuyAPICreateInspectionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertyInspection>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PropertyInspection } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPICreateInspectionPathParams = {
  dealId: EnderId;
};

type BuyAPICreateInspectionSearchParams = {
  token?: string | undefined;
};

type BuyAPICreateInspectionBodyParams = {
  inspectionDate?: SerializesInto<LocalDate> | undefined;
  scheduledInspectionDate?: SerializesInto<LocalDate> | undefined;
  vendorId?: EnderId | undefined;
};

type BuyAPICreateInspectionPayload = BuyAPICreateInspectionPathParams &
  BuyAPICreateInspectionSearchParams &
  BuyAPICreateInspectionBodyParams;

function createInspectionUnsafeEffect(payload: BuyAPICreateInspectionPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertyInspection>({
        body,
        decode: unsafeDecodeJsonResponse<PropertyInspection>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/deals/${dealId}/inspections`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createInspection(
  payload: BuyAPICreateInspectionPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertyInspection> {
  return F.pipe(
    payload,
    createInspectionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createInspection };
export type {
  BuyAPICreateInspectionBodyParams,
  BuyAPICreateInspectionPathParams,
  BuyAPICreateInspectionPayload,
  BuyAPICreateInspectionSearchParams,
};
