import { createNewPage } from "./create-new-page";
import { createNewReport } from "./create-new-report";
import { createWidget } from "./create-widget";
import { deleteWidget } from "./delete-widget";
import { exportSQLReportCSV } from "./export-s-q-l-report-c-s-v";
import { exportWidgetToExcel } from "./export-widget-to-excel";
import { filterProperties } from "./filter-properties";
import { getFactorMetadataForWidget } from "./get-factor-metadata-for-widget";
import { getJournalEntriesReport } from "./get-journal-entries-report";
import { getReport } from "./get-report";
import { getSqlReports } from "./get-sql-reports";
import { getSystemReports } from "./get-system-reports";
import { getWidget } from "./get-widget";
import { getWidgetData } from "./get-widget-data";
import { renameReport } from "./rename-report";
import { runDelinquencyReport } from "./run-delinquency-report";
import { runJournalEntriesReport } from "./run-journal-entries-report";
import { runPMBalanceReport } from "./run-p-m-balance-report";
import { runPayablesReport } from "./run-payables-report";
import { runRentRollReport } from "./run-rent-roll-report";
import { runSystemReport } from "./run-system-report";
import { runWorkingTrialBalanceReport } from "./run-working-trial-balance-report";
import { runWorkingTrialBalanceV3Report } from "./run-working-trial-balance-v3-report";
import { runYearOverYearReport } from "./run-year-over-year-report";
import { searchReports } from "./search-reports";
import { updateWidget } from "./update-widget";

const ReportsAPI = {
  createNewPage,
  createNewReport,
  createWidget,
  deleteWidget,
  exportSQLReportCSV,
  exportWidgetToExcel,
  filterProperties,
  getFactorMetadataForWidget,
  getJournalEntriesReport,
  getReport,
  getSqlReports,
  getSystemReports,
  getWidget,
  getWidgetData,
  renameReport,
  runDelinquencyReport,
  runJournalEntriesReport,
  runPMBalanceReport,
  runPayablesReport,
  runRentRollReport,
  runSystemReport,
  runWorkingTrialBalanceReport,
  runWorkingTrialBalanceV3Report,
  runYearOverYearReport,
  searchReports,
  updateWidget,
};

export { ReportsAPI };
