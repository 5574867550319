import { IconReceipt } from "@tabler/icons-react";

import type { OptimizedLedgerEvent } from "@ender/shared/contexts/ledger";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";

type LedgerEventPrintBtnProps = {
  leaseId: EnderId;
  ledgerEvent: OptimizedLedgerEvent;
};

function LedgerEventPrintBtn(props: LedgerEventPrintBtnProps) {
  const { leaseId, ledgerEvent } = props;

  async function handlePrintReceipt() {
    window.open(
      `/leases/${leaseId}/ledger-item/${ledgerEvent.id}/print-receipt`,
    );
  }

  const ledgerEventTypeLabel = ledgerEvent.tenantLedgerEventType
    .toLowerCase()
    .replace("_", " ");

  return (
    <>
      <ActionIcon
        onClick={handlePrintReceipt}
        label={`Print receipt of tenant ${ledgerEventTypeLabel}`}>
        <IconReceipt color="#0C0D4F" size="24px" />
      </ActionIcon>
    </>
  );
}

export { LedgerEventPrintBtn };
