/**
 * Register customer in Dwolla for a business or a user
 * HTTP Method: POST
 * Pathname: /dwolla/registerCustomer
 * @function registerDwollaCustomer
 * @memberof DwollaAPI
 * @param {DwollaAPIRegisterDwollaCustomerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPIRegisterDwollaCustomerSearchParams = {
  token?: string | undefined;
};

type DwollaAPIRegisterDwollaCustomerPayload =
  DwollaAPIRegisterDwollaCustomerSearchParams;

function registerDwollaCustomerUnsafeEffect(
  payload: DwollaAPIRegisterDwollaCustomerPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/dwolla/registerCustomer",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function registerDwollaCustomer(
  payload: DwollaAPIRegisterDwollaCustomerPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    registerDwollaCustomerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { registerDwollaCustomer };
export type {
  DwollaAPIRegisterDwollaCustomerPayload,
  DwollaAPIRegisterDwollaCustomerSearchParams,
};
