import { Option as O, pipe } from "effect";
import { forwardRef, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import type { PayableCategory } from "@ender/shared/generated/ender.model.accounting";
import { capitalize } from "@ender/shared/utils/string";

type PurePayableCategorySelectProps = {
  disabled: boolean;
  onChangePayableCategoryId: (category: O.Option<EnderId>) => void;
  payableCategoryId: O.Option<EnderId>;
  payableCategories: SelectOption<string, PayableCategory[]>[];
};

const PurePayableCategorySelect = forwardRef<
  HTMLInputElement,
  PurePayableCategorySelectProps
>(function PurePayableCategorySelect(
  {
    disabled,
    onChangePayableCategoryId,
    payableCategoryId,
    payableCategories,
  }: PurePayableCategorySelectProps,
  ref,
) {
  const initialCategoryName = payableCategories.reduce<O.Option<string>>(
    (acc, category) => {
      const subCategory = category.meta?.find(
        (sub) =>
          O.isSome(payableCategoryId) &&
          sub.id === O.getOrElse(() => "")(payableCategoryId),
      );
      if (subCategory) {
        return O.some(category.value);
      }
      return acc;
    },
    O.none(),
  );

  const initialSubCategoryData = payableCategories.reduce<
    SelectOption<EnderId, PayableCategory>[]
  >((acc, category) => {
    const subCategory = category.meta?.find(
      (sub) =>
        O.isSome(payableCategoryId) &&
        sub.id === O.getOrElse(() => "")(payableCategoryId),
    );
    if (subCategory) {
      return category.meta
        ? category.meta.map((sub) => ({
            label: `${sub.subCategoryName} (${capitalize(sub.reason)})`,
            value: sub.id,
          }))
        : [];
    }
    return acc;
  }, []);
  const [payableCategoryName, setPayableCategoryName] =
    useState<O.Option<string>>(initialCategoryName);
  const [subCategoryData, setSubCategoryData] = useState<
    SelectOption<EnderId, PayableCategory>[]
  >(initialSubCategoryData);

  const handleCategoryChange = (
    value: O.Option<string>,
    item: O.Option<SelectOption<string, PayableCategory[]>>,
  ) => {
    setPayableCategoryName(value);

    setSubCategoryData(
      pipe(
        item,
        O.map(
          (i) =>
            i.meta?.map((subCategory) => ({
              label: `${subCategory.subCategoryName} (${capitalize(subCategory.reason)})`,
              meta: subCategory,
              value: subCategory.id,
            })) ?? [],
        ),
        O.getOrElse(() => []),
      ),
    );

    onChangePayableCategoryId(O.none());
  };

  return (
    <>
      <Select
        label="Category (optional)"
        data={payableCategories}
        disabled={disabled}
        value={payableCategoryName}
        onChange={handleCategoryChange}
      />
      <Select
        ref={ref}
        label="Sub-Category (optional)"
        data={subCategoryData}
        disabled={disabled || O.isNone(payableCategoryName)}
        value={payableCategoryId}
        onChange={onChangePayableCategoryId}
      />
    </>
  );
});

export { PurePayableCategorySelect };
