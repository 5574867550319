import type { PropsWithChildren, ReactNode } from "react";

import type { EmptyObject } from "@ender/shared/types/general";
import { Ellipsis } from "@ender/shared/ui/ellipsis";

import type { CellProps } from "../table.types";

function EllipsisCell<RowData, Meta extends object = EmptyObject>(
  props: PropsWithChildren<CellProps<RowData, ReactNode, Meta>>,
) {
  return <Ellipsis>{props.children ?? props.getValue()}</Ellipsis>;
}

export { EllipsisCell };
