import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";

import { Form, useForm } from "@ender/form-system/base";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import { AmenityAmenityTypeEnum } from "@ender/shared/generated/ender.model.core.property";
import {
  UnitCoolingSystemEnum,
  UnitHeatingSystemEnum,
  UnitLaundryTypeEnum,
  UnitParkingTypeEnum,
} from "@ender/shared/generated/ender.model.core.unit";
import {
  AmenityData,
  CoolingSystemDisplayEnum,
  HeatingSystemDisplayEnum,
  LaundryTypeDisplayEnum,
  ParkingTypeDisplayEnum,
} from "@ender/shared/types/ender-general";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { FormMultiFilterSync } from "@ender/widgets/filters/multi-filter-sync";

const heatingSystems = Object.entries(HeatingSystemDisplayEnum).map(
  ([value, label]) => ({ label, value }),
);
const coolingSystems = Object.entries(CoolingSystemDisplayEnum).map(
  ([value, label]) => ({ label, value }),
);
const laundryTypes = Object.entries(LaundryTypeDisplayEnum).map(
  ([value, label]) => ({ label, value }),
);
const parkingTypes = Object.entries(ParkingTypeDisplayEnum).map(
  ([value, label]) => ({ label, value }),
);

const SelectOptionSchema = Schema.Struct({
  label: Schema.String,
  value: Schema.Enums(AmenityAmenityTypeEnum),
});

const EditUnitAmenitiesFormSchema = Schema.Struct({
  coolingSystem: Schema.Enums(UnitCoolingSystemEnum).pipe(
    Schema.OptionFromSelf,
  ),
  heatingSystem: Schema.Enums(UnitHeatingSystemEnum).pipe(
    Schema.OptionFromSelf,
  ),
  laundryType: Schema.Enums(UnitLaundryTypeEnum).pipe(Schema.OptionFromSelf),
  parkingType: Schema.Enums(UnitParkingTypeEnum).pipe(Schema.OptionFromSelf),
  unitAmenities: Schema.Array(SelectOptionSchema).pipe(Schema.mutable),
});

type EditUnitAmenitiesFormValues = Schema.Schema.Type<
  typeof EditUnitAmenitiesFormSchema
>;

type EditAmenitiesFormProps = {
  unit: UnitSerializerUnitResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  propertyAmenities: AmenityAmenityType[];
  unitAmenities: AmenityAmenityType[];
};

function EditUnitAmenitiesForm(props: EditAmenitiesFormProps) {
  const {
    propertyAmenities,
    unitAmenities,
    onSuccess = F.constVoid,
    onCancel,
    unit,
  } = props;

  const form = useForm({
    defaultValues: {
      coolingSystem: O.fromNullable(unit?.coolingSystem),
      heatingSystem: O.fromNullable(unit?.heatingSystem),
      laundryType: O.fromNullable(unit?.laundryType),
      parkingType: O.fromNullable(unit?.parkingType),
      unitAmenities: unitAmenities.map((amenity) => ({
        label: convertSnakeCaseToTitleCase(amenity),
        value: AmenityAmenityTypeEnum[amenity],
      })),
    },
    resolver: effectTsResolver(EditUnitAmenitiesFormSchema),
  });

  const { mutateAsync: updateUnit } = useMutation({
    mutationFn: UnitsAPI.updateUnit,
    mutationKey: ["UnitsAPI.updateUnit"] as const,
  });
  const { mutateAsync: setUnitAmenities } = useMutation({
    mutationFn: UnitsAPI.setUnitAmenities,
    mutationKey: ["UnitsAPI.setUnitAmenities"] as const,
  });

  async function handleSubmit(values: EditUnitAmenitiesFormValues) {
    if (!unit) {
      onSuccess();
      return;
    }
    const selectedAmenities = values.unitAmenities.map(
      (option) => option.value,
    );
    const valuesFromOptions = {
      coolingSystem: O.getOrUndefined(values.coolingSystem),
      heatingSystem: O.getOrUndefined(values.heatingSystem),
      laundryType: O.getOrUndefined(values.laundryType),
      parkingType: O.getOrUndefined(values.parkingType),
      unitAmenities: values.unitAmenities,
    };
    await updateUnit({ unitId: unit.id, ...valuesFromOptions });
    await setUnitAmenities({ amenities: selectedAmenities, unitId: unit.id });
    showSuccessNotification({ message: "Successfully updated unit amenities" });
    onSuccess();
  }

  const possibleAmenities = AmenityData.filter(({ value }) => {
    return !propertyAmenities.includes(value);
  });

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack spacing={Spacing.lg}>
        <Stack spacing={Spacing.sm}>
          <Text size={FontSize.sm} weight={FontWeight.medium}>
            Inherited from Property Page
          </Text>
          <Group spacing={Spacing.md}>
            {propertyAmenities.map((amenity) => (
              <Badge color={BadgeColor.slate} key={amenity}>
                {convertSnakeCaseToTitleCase(amenity)}
              </Badge>
            ))}
          </Group>
        </Stack>
        <Stack spacing={Spacing.sm}>
          <Text size={FontSize.sm} weight={FontWeight.medium}>
            Unique to Unit
          </Text>
          <FormMultiFilterSync
            name="unitAmenities"
            data={possibleAmenities}
            form={form}
            label="Choose Unit Amenities"
          />
          <FormSelect
            name="heatingSystem"
            label="Heating System"
            data={heatingSystems}
            form={form}
          />
          <FormSelect
            name="coolingSystem"
            label="Cooling System"
            data={coolingSystems}
            form={form}
          />
          <FormSelect
            name="laundryType"
            label="Laundry"
            data={laundryTypes}
            form={form}
          />
          <FormSelect
            name="parkingType"
            label="Parking"
            data={parkingTypes}
            form={form}
          />
        </Stack>
        <Group justify={Justify.end} align={Align.center}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={!form.formState.isDirty}>
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditUnitAmenitiesForm };
