/**
 * HTTP Method: POST
 * Pathname: /renewalOffers/{offerId}
 * @function updateOffer
 * @memberof RenewalsAPI
 * @param {RenewalsAPIUpdateOfferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPIUpdateOfferPathParams = {
  offerId: EnderId;
};

type RenewalsAPIUpdateOfferSearchParams = {
  token?: string | undefined;
};

type RenewalsAPIUpdateOfferBodyParams = {
  expirationDate?: SerializesInto<LocalDate> | undefined;
  newEndDate?: SerializesInto<LocalDate> | undefined;
  newRent?: SerializesInto<Money> | undefined;
  processingFee?: SerializesInto<Money> | undefined;
};

type RenewalsAPIUpdateOfferPayload = RenewalsAPIUpdateOfferPathParams &
  RenewalsAPIUpdateOfferSearchParams &
  RenewalsAPIUpdateOfferBodyParams;

function updateOfferUnsafeEffect(payload: RenewalsAPIUpdateOfferPayload) {
  const { offerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/renewalOffers/${offerId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateOffer(
  payload: RenewalsAPIUpdateOfferPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateOfferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateOffer };
export type {
  RenewalsAPIUpdateOfferBodyParams,
  RenewalsAPIUpdateOfferPathParams,
  RenewalsAPIUpdateOfferPayload,
  RenewalsAPIUpdateOfferSearchParams,
};
