/**
 * HTTP Method: GET
 * Pathname: /middleLayer/unitAreaSummary/{unitId}
 * @function getUnitAreaSummary
 * @memberof UnitAreasZonesMiddleLayerAPI
 * @param {UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitAreaSummaryResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitAreaSummaryResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPathParams = {
  unitId: EnderId;
};

type UnitAreasZonesMiddleLayerAPIGetUnitAreaSummarySearchParams = {
  token?: string | undefined;
};

type UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPayload =
  UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPathParams &
    UnitAreasZonesMiddleLayerAPIGetUnitAreaSummarySearchParams;

function getUnitAreaSummaryUnsafeEffect(
  payload: UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPayload,
) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitAreaSummaryResponse>({
        body,
        decode: unsafeDecodeJsonResponse<UnitAreaSummaryResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/middleLayer/unitAreaSummary/${unitId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnitAreaSummary(
  payload: UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitAreaSummaryResponse> {
  return F.pipe(
    payload,
    getUnitAreaSummaryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitAreaSummary };
export type {
  UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPathParams,
  UnitAreasZonesMiddleLayerAPIGetUnitAreaSummaryPayload,
  UnitAreasZonesMiddleLayerAPIGetUnitAreaSummarySearchParams,
};
