import { z } from "zod";

import { CategoryFlagValues } from "@ender/shared/generated/ender.model.accounting";
import { CurrencySchema, castEnum } from "@ender/shared/utils/zod";

import { GLCategorySchema } from "./general-ledger";

const TenantLedgerCategorySchema = GLCategorySchema.extend({
  endingBalance: CurrencySchema.optional(),
  flags: z.enum(CategoryFlagValues).array(),
  totalCharges: CurrencySchema.optional(),
  totalPayments: CurrencySchema.optional(),
});

type TenantLedgerCategory = z.infer<typeof TenantLedgerCategorySchema>;

const TenantLedgerChargePaidStatus = [
  "FULLY_PAID",
  "PARTIALLY_PAID",
  "UNPAID",
] as const;
const TenantLedgerChargePaidStatusSchema = z.enum(TenantLedgerChargePaidStatus);
type TenantLedgerChargePaidStatusStatus = z.infer<
  typeof TenantLedgerChargePaidStatusSchema
>;

const TenantLedgerChargePaidStatusEnum =
  castEnum<TenantLedgerChargePaidStatusStatus>(
    TenantLedgerChargePaidStatusSchema,
  );

const TenantLedgerEventTypeValues = [
  "CHARGE",
  "CREDIT",
  "PAYMENT",
  "REVERSAL",
  "PREPAYMENT_ALLOCATION",
  "REFUND",
] as const;

const TenantLedgerEventTypeSchema = z.enum(TenantLedgerEventTypeValues);
type TenantLedgerEventType = z.infer<typeof TenantLedgerEventTypeSchema>;

const TenantLedgerEventTypeEnum = castEnum<TenantLedgerEventType>(
  TenantLedgerEventTypeSchema,
);

export {
  TenantLedgerCategorySchema,
  TenantLedgerChargePaidStatus,
  TenantLedgerChargePaidStatusEnum,
  TenantLedgerChargePaidStatusSchema,
  TenantLedgerEventTypeEnum,
  TenantLedgerEventTypeSchema,
  TenantLedgerEventTypeValues,
};
export type {
  TenantLedgerCategory,
  TenantLedgerChargePaidStatusStatus,
  TenantLedgerEventType,
};
