/**
 * HTTP Method: POST
 * Pathname: /middleLayer/payments/tenant-receipts-deposits/search
 * @function tenantReceiptDepositSearch
 * @memberof PaymentsMiddleLayerAPI
 * @param {PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TenantReceiptDepositSearchResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  TenantReceiptDepositSearchRequestFilterBy,
  TenantReceiptDepositSearchRequestSortBy,
} from "@ender/shared/generated/com.ender.middle.request";
import type { TenantReceiptDepositSearchResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsMiddleLayerAPITenantReceiptDepositSearchSearchParams = {
  token?: string | undefined;
};

type PaymentsMiddleLayerAPITenantReceiptDepositSearchBodyParams = {
  filterBy: TenantReceiptDepositSearchRequestFilterBy;
  limit?: number | undefined;
  offset?: number | undefined;
  sortBy: TenantReceiptDepositSearchRequestSortBy[];
  startAfterId?: EnderId | undefined;
};

type PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload =
  PaymentsMiddleLayerAPITenantReceiptDepositSearchSearchParams &
    PaymentsMiddleLayerAPITenantReceiptDepositSearchBodyParams;

function tenantReceiptDepositSearchUnsafeEffect(
  payload: PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TenantReceiptDepositSearchResponse>({
        body,
        decode: unsafeDecodeJsonResponse<TenantReceiptDepositSearchResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/payments/tenant-receipts-deposits/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function tenantReceiptDepositSearch(
  payload: PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload,
  options?: { signal?: AbortSignal },
): Promise<TenantReceiptDepositSearchResponse> {
  return F.pipe(
    payload,
    tenantReceiptDepositSearchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { tenantReceiptDepositSearch };
export type {
  PaymentsMiddleLayerAPITenantReceiptDepositSearchBodyParams,
  PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload,
  PaymentsMiddleLayerAPITenantReceiptDepositSearchSearchParams,
};
