/**
 * HTTP Method: DELETE
 * Pathname: /construction/inspections/{inspectionId}/photos
 * @function deletePhotos
 * @memberof InspectionAPI
 * @param {InspectionAPIDeletePhotosPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIDeletePhotosPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIDeletePhotosSearchParams = {
  token?: string | undefined;
};

type InspectionAPIDeletePhotosBodyParams = {
  photoIds: EnderId[];
};

type InspectionAPIDeletePhotosPayload = InspectionAPIDeletePhotosPathParams &
  InspectionAPIDeletePhotosSearchParams &
  InspectionAPIDeletePhotosBodyParams;

function deletePhotosUnsafeEffect(payload: InspectionAPIDeletePhotosPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId[]>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId[]>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/construction/inspections/${inspectionId}/photos`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deletePhotos(
  payload: InspectionAPIDeletePhotosPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId[]> {
  return F.pipe(
    payload,
    deletePhotosUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deletePhotos };
export type {
  InspectionAPIDeletePhotosBodyParams,
  InspectionAPIDeletePhotosPathParams,
  InspectionAPIDeletePhotosPayload,
  InspectionAPIDeletePhotosSearchParams,
};
