import { zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useCallback, useMemo } from "react";
import { z } from "zod";

import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { DateInput } from "@ender/shared/ds/date-input";
import { Stack } from "@ender/shared/ds/stack";
import { useForm } from "@ender/shared/forms/hooks/general";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import type { LeasingAPIRenewLeasePayload } from "@ender/shared/generated/ender.api.leasing";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { fail } from "@ender/shared/utils/error";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";
import { LocalDateZodSchema, OptionSchema } from "@ender/shared/utils/zod";

const RenewLeaseFormSchema = z.intersection(
  z.object({
    startDate: OptionSchema(LocalDateZodSchema).refine(O.isSome, {
      message: "Start date is required",
    }),
  }),
  z
    .object({
      endDate: OptionSchema(LocalDateZodSchema),
      monthToMonth: z.boolean(),
    })
    .refine(
      ({ monthToMonth, endDate }) => monthToMonth || endDate.pipe(O.isSome),
      {
        message: "End Date is required if not month-to-month",
        path: ["endDate"],
      },
    ),
);
type RenewLeaseFormInput = z.input<typeof RenewLeaseFormSchema>;

type RenewLeaseModalProps = {
  leaseId: EnderId;
};

function transformValues(
  values: RenewLeaseFormInput,
): Omit<LeasingAPIRenewLeasePayload, "leaseId"> {
  return {
    ...values,
    endDate: values.monthToMonth
      ? UNDEFINED
      : values.endDate.pipe(
          O.match({
            onNone: () => UNDEFINED,
            onSome: (date) => date?.toJSON(),
          }),
        ),
    startDate: O.getOrThrow(values.startDate).toJSON(),
  };
}

function RenewLeaseModal({ leaseId }: RenewLeaseModalProps) {
  const form = useForm<RenewLeaseFormInput>({
    initialValues: {
      endDate: O.none(),
      monthToMonth: false,
      startDate: O.none(),
    },
    validate: zodResolver(RenewLeaseFormSchema),
  });

  const confirmation = useConfirmationContext();
  const renewLeaseWithWarnings = withWarningHandler(
    LeasingAPI.renewLease,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );
  const { mutateAsync: renewLease } = useMutation({
    mutationFn: async (payload: LeasingAPIRenewLeasePayload) => {
      const response = await renewLeaseWithWarnings(payload);
      if (response && response.id) {
        globalThis.location.assign(`/leases/${response.id}`);
      }
      return response;
    },
    mutationKey: ["LeasingAPI.renewLease"],
  });

  const onRenewLease = useCallback(
    async (values: RenewLeaseFormInput) => {
      try {
        await renewLease({ leaseId, ...transformValues(values) });
      } catch (e) {
        fail(e);
      }
    },
    [leaseId, renewLease],
  );

  const endDateDisabled = useMemo(() => {
    if (form.values.monthToMonth) {
      form.values.endDate = O.none();
      return true;
    }

    return false;
  }, [form.values]);
  return (
    <UnmanagedForm form={form} onSubmit={onRenewLease}>
      <Stack>
        <DateInput label="Start Date" {...form.getInputProps("startDate")} />
        <DateInput
          label="End Date"
          disabled={endDateDisabled}
          {...form.getInputProps("endDate")}
        />
        <Checkbox
          label="Set as month-to-month"
          {...form.getInputProps("monthToMonth")}
        />
        <Button type="submit">Renew Lease</Button>
      </Stack>
    </UnmanagedForm>
  );
}

export { RenewLeaseModal };
