import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const FeatureFlagFlagKeyValues = [
  "GLTX_APPROVAL_CHAIN",
  "USE_QUERY_DISPLAY_FAIL_TOAST",
  "RECURRING_JOURNAL_ENTRIES",
  "CANCEL_IN_FLIGHT_ACH_PAYMENTS",
  "HIDE_WORK_ORDER_REPORT",
] as const;

const FeatureFlagFlagKeyEffectSchema = Schema.Literal(
  ...FeatureFlagFlagKeyValues,
);

type FeatureFlagFlagKey = Schema.Schema.Type<
  typeof FeatureFlagFlagKeyEffectSchema
>;

const FeatureFlagFlagKeyEnum = castEnum(FeatureFlagFlagKeyEffectSchema);

function randomFeatureFlagFlagKey(): FeatureFlagFlagKey {
  return rand(FeatureFlagFlagKeyValues);
}

export {
  FeatureFlagFlagKeyEffectSchema,
  FeatureFlagFlagKeyEnum,
  FeatureFlagFlagKeyValues,
  randomFeatureFlagFlagKey,
};
export type { FeatureFlagFlagKey };
