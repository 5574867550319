import { IconTrash } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";

import type { FormSubSectionReference } from "@ender/form-system/base";
import { FormSection } from "@ender/form-system/base";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { InputSize } from "@ender/shared/ds/input";
import { TriggerButtonSize } from "@ender/shared/ds/menu";
import type { SelectOption } from "@ender/shared/ds/select";
import { FormSelect, Select } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { MarketsAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { Market } from "@ender/shared/generated/com.ender.buy.model.misc";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  FirmsAPI,
  PropertiesAPI,
} from "@ender/shared/generated/ender.api.core";
import type { SearchPropertiesResponseSearchPropertiesResult } from "@ender/shared/generated/ender.api.core.response";
import type { FactorsAPIGetCustomFieldsResponse } from "@ender/shared/generated/ender.api.reports";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import type { Firm } from "@ender/shared/generated/ender.model.core";
import type { SelectionCriteriaFilterType } from "@ender/shared/generated/ender.model.misc";
import {
  SelectionCriteriaFilterTypeEnum,
  SelectionCriteriaLeaseStatusEnum,
} from "@ender/shared/generated/ender.model.misc";
import { Color } from "@ender/shared/utils/theming";
import {
  FormMultiFilterSync,
  formatMultiFilterLabel,
} from "@ender/widgets/filters/multi-filter-sync";

import type {
  CollectionFormValuesInput,
  CriteriaValuesInput,
} from "../collections-timeline-settings.types";

type Section = {
  filterType: `criteria.${number}.filterType`;
  ids: `criteria.${number}.ids`;
  leaseStatuses: `criteria.${number}.leaseStatuses`;
};

type EnhancedSelectionCriteriaFilterType =
  | "FIRM"
  | "MARKET"
  | "PROPERTY"
  | "LEASE_STATUS"
  | "PROPERTY_LIST";

type CollectionsTimelineConditionCardProps = FormSubSectionReference<
  CollectionFormValuesInput,
  CriteriaValuesInput
> & {
  allowNounChange: boolean;
  disabled?: boolean;
  index: number;
  onDeleteCondition: () => void;
};

const nounData = [
  {
    value: SelectionCriteriaFilterTypeEnum.LEASE_STATUS,
    label: "Lease Status",
  },
  { value: SelectionCriteriaFilterTypeEnum.FIRM, label: "Entity" }, // Entity is the preferred word for firm or fund
  { value: SelectionCriteriaFilterTypeEnum.MARKET, label: "Market" },
  { value: SelectionCriteriaFilterTypeEnum.PROPERTY, label: "Property" },
  { value: "PROPERTY_LIST", label: "Property List" }, // SelectionCriteriaFilterTypeEnum does not contain PROPERTY_LIST
];

const nounDataWithoutLeaseStatus = [
  { value: SelectionCriteriaFilterTypeEnum.FIRM, label: "Entity" }, // Entity is the preferred word for firm or fund
  { value: SelectionCriteriaFilterTypeEnum.MARKET, label: "Market" },
  { value: SelectionCriteriaFilterTypeEnum.PROPERTY, label: "Property" },
  { value: "PROPERTY_LIST", label: "Property List" }, // SelectionCriteriaFilterTypeEnum does not contain PROPERTY_LIST
];

const leaseStatusData: SelectOption[] = [
  { value: SelectionCriteriaLeaseStatusEnum.STABLE, label: "Stable" },
  {
    value: SelectionCriteriaLeaseStatusEnum.MARKED_FOR_EVICTION,
    label: "Intend to Evict",
  },
  { value: SelectionCriteriaLeaseStatusEnum.IN_EVICTION, label: "In Eviction" },
  { value: SelectionCriteriaLeaseStatusEnum.MOVED_OUT, label: "Moved Out" },
];

function CollectionsTimelineConditionCard({
  allowNounChange,
  disabled,
  form,
  index,
  name,
  onDeleteCondition,
}: CollectionsTimelineConditionCardProps): JSX.Element {
  const selectableNounData =
    index === 0 ? nounData : nounDataWithoutLeaseStatus;

  // Define 'section' outside of JSX using the 'index'
  const section: Section = {
    filterType: `criteria.${index}.filterType`,
    ids: `criteria.${index}.ids`,
    leaseStatuses: `criteria.${index}.leaseStatuses`,
  };

  // triggers re-renders when the form updates
  const filterType =
    useWatch({
      control: form.control,
      name: section.filterType,
      exact: true,
    }) ?? O.none;

  const { data: firmDataOptions = [] } = useQuery({
    enabled: O.getOrNull(filterType) === SelectionCriteriaFilterTypeEnum.FIRM,
    queryFn: () => FirmsAPI.getFirms({}),
    queryKey: ["FirmsAPI.getFirms"] as const,
    select: (entityData) =>
      entityData?.map((firm: Firm) => ({
        label: firm.name,
        value: firm.id,
      })) ?? [],
    staleTime: 900000, // 15 minutes
  });

  const { data: marketDataOptions = [] } = useQuery({
    enabled: O.getOrNull(filterType) === SelectionCriteriaFilterTypeEnum.MARKET,
    queryFn: () => MarketsAPI.getMarkets({}),
    queryKey: ["MarketsAPI.getMarkets"] as const,
    select: (marketData) =>
      marketData?.map((market: Market) => ({
        label: market.name,
        value: market.id,
      })) ?? [],
    staleTime: 900000, // 15 minutes
  });

  const { data: propertyDataOptions = [] } = useQuery({
    enabled:
      O.getOrNull(filterType) === SelectionCriteriaFilterTypeEnum.PROPERTY,
    queryFn: () => PropertiesAPI.searchPropertiesv2({ ownershipGroupIds: [] }),
    queryKey: ["PropertiesAPI.searchPropertiesv2"] as const,
    select: (propertyData) =>
      propertyData?.properties.map(
        (property: SearchPropertiesResponseSearchPropertiesResult) => ({
          label: `${property.name} (${property.friendlyId})`,
          value: property.id,
        }),
      ) ?? [],
    staleTime: 900000, // 15 minutes
  });

  const { data: propertyListDataOptions = [] } = useQuery({
    enabled: O.getOrNull(filterType) === "PROPERTY_LIST",
    queryFn: () =>
      FactorsAPI.getCustomFields({ modelType: ModelTypeEnum.PROPERTY }),
    queryKey: ["FactorsAPI.getCustomFields"] as const,
    select: (customFields) =>
      customFields?.map((field: FactorsAPIGetCustomFieldsResponse) => ({
        label: field.factor.name,
        value: field.factor.id,
      })) ?? [],
    staleTime: 900000, // 15 minutes
  });

  const getMultiFilterValues = useCallback(
    (filterType: SelectionCriteriaFilterType | string): SelectOption[] => {
      switch (filterType) {
        case SelectionCriteriaFilterTypeEnum.LEASE_STATUS:
          return leaseStatusData;
        case SelectionCriteriaFilterTypeEnum.MARKET:
          return marketDataOptions;
        case SelectionCriteriaFilterTypeEnum.PROPERTY:
          return propertyDataOptions;
        case "PROPERTY_LIST":
          return propertyListDataOptions;
        case SelectionCriteriaFilterTypeEnum.FIRM:
          return firmDataOptions;
        default:
          return [];
      }
    },
    [
      marketDataOptions,
      propertyDataOptions,
      propertyListDataOptions,
      firmDataOptions,
    ],
  );

  const getMultiFilterLabel = (
    filterType: SelectionCriteriaFilterType | string | null,
  ): string => {
    switch (filterType) {
      case SelectionCriteriaFilterTypeEnum.LEASE_STATUS:
        return "Lease Statuses";
      case SelectionCriteriaFilterTypeEnum.MARKET:
        return "Markets";
      case SelectionCriteriaFilterTypeEnum.PROPERTY:
        return "Properties";
      case "PROPERTY_LIST":
        return "Properties";
      case SelectionCriteriaFilterTypeEnum.FIRM:
        return "Entities";
      default:
        return "";
    }
  };

  const multiFilterData = O.match(filterType, {
    onNone: () => [],
    onSome: (filterType) => getMultiFilterValues(filterType),
  });

  const getMultiFilterName = (
    filterType: O.Option<EnhancedSelectionCriteriaFilterType>,
    section: Section,
  ) => {
    return O.match(filterType, {
      onNone: () => section.leaseStatuses,
      onSome: (filterType) =>
        filterType === SelectionCriteriaFilterTypeEnum.LEASE_STATUS
          ? section.leaseStatuses
          : section.ids,
    });
  };

  const fieldName = getMultiFilterName(filterType, section);

  // Watch the field value to update the button label
  const fieldValue =
    useWatch({
      control: form.control,
      name: fieldName,
    }) ?? [];

  // Format the multiFilter label
  const multiFilterLabel = formatMultiFilterLabel(
    fieldValue.map(({ label }) => label),
    getMultiFilterLabel(O.getOrNull(filterType)),
  );

  const isMultiFilterEnabled = useMemo(() => {
    return disabled || O.isNone(filterType);
  }, [disabled, filterType]);

  return (
    <FormSection form={form} name={name}>
      {({ section }) => (
        <Card>
          {allowNounChange && (
            <Group justify={Justify.end}>
              <ActionIcon
                color={Color.red}
                disabled={disabled}
                onClick={onDeleteCondition}
                variant={ButtonVariant.transparent}>
                <IconTrash />
              </ActionIcon>
            </Group>
          )}
          <Skeleton visible={!marketDataOptions} />
          <Grid>
            <FormSelect
              clearable
              data={selectableNounData}
              disabled={!allowNounChange || disabled}
              form={form}
              name={section.filterType}
              placeholder="Select Parameter"
              size={InputSize.sm}
            />
            <Select
              data={[{ value: "IS", label: "is" }]}
              disabled={disabled}
              onChange={F.constVoid}
              size={InputSize.sm}
              value={O.some("IS")}
            />
            <FormMultiFilterSync
              data={multiFilterData}
              disabled={isMultiFilterEnabled}
              form={form}
              label={multiFilterLabel}
              name={fieldName}
              size={TriggerButtonSize.sm}
            />
          </Grid>
        </Card>
      )}
    </FormSection>
  );
}

export { CollectionsTimelineConditionCard };
