import type { PaginationState } from "@tanstack/react-table";
import { useState } from "react";

import type { EnderTablePaginationParams } from "../table.types";

const DEFAULT_PAGINATION: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

type UseTablePaginationParams = {
  initialPagination?: PaginationState;
  pageCount?: number;
  autoResetPageIndex?: boolean;
  manualPagination?: boolean;
};

function useTablePagination(
  params: UseTablePaginationParams = {},
): EnderTablePaginationParams {
  const {
    autoResetPageIndex = true,
    initialPagination = DEFAULT_PAGINATION,
    manualPagination = false,
    pageCount,
  } = params;

  const [pagination, onPaginationChange] =
    useState<PaginationState>(initialPagination);

  return {
    autoResetPageIndex,
    manualPagination,
    onPaginationChange,
    pageCount,
    pagination,
  };
}

export { useTablePagination };
