/**
 * HTTP Method: POST
 * Pathname: /accounting/approval-journal-entries
 * @function getApprovalGLJournalEntries
 * @memberof AccountingAPI
 * @param {AccountingAPIGetApprovalGLJournalEntriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GLJournalEntryApprovalRow[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GLJournalEntryApprovalRow } from "@ender/shared/generated/ender.api.accounting.response";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIGetApprovalGLJournalEntriesSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetApprovalGLJournalEntriesBodyParams = {
  approvalStatus?: ApprovableApprovalStatus | undefined;
  assignedToMe?: boolean | undefined;
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  inclusiveEndTxnDate?: SerializesInto<LocalDate> | undefined;
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  periods: LocalDate[];
  propertyIds: EnderId[];
  startAfterId?: EnderId | undefined;
  startTxnDate?: SerializesInto<LocalDate> | undefined;
};

type AccountingAPIGetApprovalGLJournalEntriesPayload =
  AccountingAPIGetApprovalGLJournalEntriesSearchParams &
    AccountingAPIGetApprovalGLJournalEntriesBodyParams;

function getApprovalGLJournalEntriesUnsafeEffect(
  payload: AccountingAPIGetApprovalGLJournalEntriesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GLJournalEntryApprovalRow[]>({
        body,
        decode: unsafeDecodeJsonResponse<GLJournalEntryApprovalRow[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/approval-journal-entries",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getApprovalGLJournalEntries(
  payload: AccountingAPIGetApprovalGLJournalEntriesPayload,
  options?: { signal?: AbortSignal },
): Promise<GLJournalEntryApprovalRow[]> {
  return F.pipe(
    payload,
    getApprovalGLJournalEntriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getApprovalGLJournalEntries };
export type {
  AccountingAPIGetApprovalGLJournalEntriesBodyParams,
  AccountingAPIGetApprovalGLJournalEntriesPayload,
  AccountingAPIGetApprovalGLJournalEntriesSearchParams,
};
