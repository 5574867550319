/**
 * HTTP Method: GET
 * Pathname: /invoicePages
 * @function getInvoicePages
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoicePagesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInvoicePagesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInvoicePagesResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoicePagesSearchParams = {
  token?: string | undefined;
  invoiceType: InvoiceInvoiceType;
  uploaderId?: EnderId | undefined;
};

type InvoicesAPIGetInvoicePagesPayload = InvoicesAPIGetInvoicePagesSearchParams;

function getInvoicePagesUnsafeEffect(
  payload: InvoicesAPIGetInvoicePagesPayload,
) {
  const { token, invoiceType, uploaderId, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInvoicePagesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetInvoicePagesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/invoicePages",
        searchParams: [
          ["token", token],
          ["invoiceType", invoiceType],
          ["uploaderId", uploaderId],
        ],
      }),
    ),
  );
}

function getInvoicePages(
  payload: InvoicesAPIGetInvoicePagesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInvoicePagesResponse[]> {
  return F.pipe(
    payload,
    getInvoicePagesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoicePages };
export type {
  InvoicesAPIGetInvoicePagesPayload,
  InvoicesAPIGetInvoicePagesSearchParams,
};
