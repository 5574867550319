import { Badge } from "@ender/shared/ds/badge";
import type { GetRenewalsRequestRenewalTableTab } from "@ender/shared/generated/ender.api.leasing.request";
import { GetRenewalsRequestRenewalTableTabEnum } from "@ender/shared/generated/ender.api.leasing.request";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { Color } from "@ender/shared/utils/theming";

function getStatusColor(status: GetRenewalsRequestRenewalTableTab) {
  switch (status) {
    case GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING:
    case GetRenewalsRequestRenewalTableTabEnum.NEEDS_MOVE_OUT:
      return Color.red;
    case GetRenewalsRequestRenewalTableTabEnum.ACCEPTED:
    case GetRenewalsRequestRenewalTableTabEnum.NO_RENEW:
    case GetRenewalsRequestRenewalTableTabEnum.DRAFTED_LEASES:
      return Color.green;
    case GetRenewalsRequestRenewalTableTabEnum.NEEDS_OFFERS:
    case GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS:
    case GetRenewalsRequestRenewalTableTabEnum.AWAITING_RESPONSE:
    case GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED:
    default:
      return Color.yellow;
  }
}

function getStatusLabel(status: GetRenewalsRequestRenewalTableTab) {
  switch (status) {
    case GetRenewalsRequestRenewalTableTabEnum.SEND_OFFERS:
      return "Ready to Send";
    case GetRenewalsRequestRenewalTableTabEnum.AWAITING_RESPONSE:
      return "Renewal In Progress";
    case GetRenewalsRequestRenewalTableTabEnum.ACCEPTED:
      return "Renewal Accepted";
    case GetRenewalsRequestRenewalTableTabEnum.NEGOTIATING:
      return "In Negotiation";
    case GetRenewalsRequestRenewalTableTabEnum.DRAFTED_LEASES:
      return "Lease Drafted";
    case GetRenewalsRequestRenewalTableTabEnum.NO_RENEW:
      return "Intend to Not Renew";
    case GetRenewalsRequestRenewalTableTabEnum.NEEDS_OFFERS:
    case GetRenewalsRequestRenewalTableTabEnum.NEW_OFFER_REQUESTED:
    case GetRenewalsRequestRenewalTableTabEnum.NEEDS_MOVE_OUT:
    default:
      return convertSnakeCaseToTitleCase(status);
  }
}

type RenewalStatusBadgeProps = {
  status: GetRenewalsRequestRenewalTableTab;
};

function RenewalStatusBadge(props: RenewalStatusBadgeProps) {
  const { status } = props;

  return <Badge color={getStatusColor(status)}>{getStatusLabel(status)}</Badge>;
}

export { RenewalStatusBadge };
