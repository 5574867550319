/**
 * Updates the 'ordering' on these CustomFactors to match the ordering of IDs passed in.
 * HTTP Method: POST
 * Pathname: /factors/ordering
 * @function updateCustomFactorOrdering
 * @memberof FactorsAPI
 * @param {FactorsAPIUpdateCustomFactorOrderingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIUpdateCustomFactorOrderingSearchParams = {
  token?: string | undefined;
};

type FactorsAPIUpdateCustomFactorOrderingBodyParams = {
  customFactorIds: EnderId[];
};

type FactorsAPIUpdateCustomFactorOrderingPayload =
  FactorsAPIUpdateCustomFactorOrderingSearchParams &
    FactorsAPIUpdateCustomFactorOrderingBodyParams;

function updateCustomFactorOrderingUnsafeEffect(
  payload: FactorsAPIUpdateCustomFactorOrderingPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/factors/ordering",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateCustomFactorOrdering(
  payload: FactorsAPIUpdateCustomFactorOrderingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateCustomFactorOrderingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateCustomFactorOrdering };
export type {
  FactorsAPIUpdateCustomFactorOrderingBodyParams,
  FactorsAPIUpdateCustomFactorOrderingPayload,
  FactorsAPIUpdateCustomFactorOrderingSearchParams,
};
