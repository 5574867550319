/**
 * HTTP Method: GET
 * Pathname: /accounting/categories/{categoryId}
 * @function getCategory
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetCategoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetCategoryResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetCategoryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetCategoryPathParams = {
  categoryId: EnderId;
};

type GeneralLedgerAPIGetCategorySearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetCategoryPayload =
  GeneralLedgerAPIGetCategoryPathParams &
    GeneralLedgerAPIGetCategorySearchParams;

function getCategoryUnsafeEffect(payload: GeneralLedgerAPIGetCategoryPayload) {
  const { categoryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetCategoryResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetCategoryResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/accounting/categories/${categoryId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCategory(
  payload: GeneralLedgerAPIGetCategoryPayload,
  options?: { signal?: AbortSignal },
): Promise<GetCategoryResponse> {
  return F.pipe(
    payload,
    getCategoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCategory };
export type {
  GeneralLedgerAPIGetCategoryPathParams,
  GeneralLedgerAPIGetCategoryPayload,
  GeneralLedgerAPIGetCategorySearchParams,
};
