import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Select } from "@ender/shared/ui/select";

import type { MoveInStepProps } from "../move-in-step";
import { MoveInStep } from "../move-in-step";
import type { UpdateChecklistStepProps } from "./checklist-steps.types";

type BooleanStepProps = MoveInStepProps & {
  capturedData?: string | null;
  onChange: (props: UpdateChecklistStepProps) => void;
};

function BooleanStep(props: BooleanStepProps) {
  const { capturedData, completedTime, onChange, ...stepProps } = props;

  return (
    <MoveInStep {...stepProps} completedTime={completedTime}>
      <Group spacing={Spacing.none} noWrap>
        <Select
          disabled={stepProps.completed}
          value={capturedData ?? ""}
          placeholder="Select"
          data={[
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
          ]}
          onChange={(value) =>
            onChange({ capturedData: value ?? "", completed: true })
          }
        />
        {Boolean(completedTime) && (
          <Button
            variant={ButtonVariant.transparent}
            onClick={() => onChange({ capturedData: "", completed: false })}
            disabled={Boolean(stepProps.completed)}>
            Clear
          </Button>
        )}
      </Group>
    </MoveInStep>
  );
}

export { BooleanStep };
