import { rest } from "@ender/shared/utils/rest";

// TODO ENDER-15902: file upload
function uploadTenantCharges(files: File[]): Promise<void> {
  const formData = new FormData();

  for (const file of files) {
    formData.append("file", file);
  }

  return rest.post(`/chargeFileUpload`, formData);
}

export { uploadTenantCharges };
