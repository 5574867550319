import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ARAgingReportProviderARAgingReportTypeValues = [
  "CREDIT_LOSS",
  "CHARGE_LEVEL",
  "LEASE_LEVEL",
  "PROPERTY_LEVEL",
] as const;

const ARAgingReportProviderARAgingReportTypeEffectSchema = Schema.Literal(
  ...ARAgingReportProviderARAgingReportTypeValues,
);

type ARAgingReportProviderARAgingReportType = Schema.Schema.Type<
  typeof ARAgingReportProviderARAgingReportTypeEffectSchema
>;

const ARAgingReportProviderARAgingReportTypeEnum = castEnum(
  ARAgingReportProviderARAgingReportTypeEffectSchema,
);

function randomARAgingReportProviderARAgingReportType(): ARAgingReportProviderARAgingReportType {
  return rand(ARAgingReportProviderARAgingReportTypeValues);
}

export {
  ARAgingReportProviderARAgingReportTypeEffectSchema,
  ARAgingReportProviderARAgingReportTypeEnum,
  ARAgingReportProviderARAgingReportTypeValues,
  randomARAgingReportProviderARAgingReportType,
};
export type { ARAgingReportProviderARAgingReportType };
