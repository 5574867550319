/**
 * Imports a spreadsheet of properties
 * HTTP Method: POST
 * Pathname: /uploadProperties
 * @function uploadProperties
 * @memberof BuyAPI
 * @param {BuyAPIUploadPropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIUploadPropertiesResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIUploadPropertiesResponse } from "../buy-api-upload-properties-response";

type BuyAPIUploadPropertiesSearchParams = {
  token?: string | undefined;
};

type BuyAPIUploadPropertiesPayload = BuyAPIUploadPropertiesSearchParams;

function uploadPropertiesUnsafeEffect(payload: BuyAPIUploadPropertiesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIUploadPropertiesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BuyAPIUploadPropertiesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/uploadProperties",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function uploadProperties(
  payload: BuyAPIUploadPropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIUploadPropertiesResponse> {
  return F.pipe(
    payload,
    uploadPropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { uploadProperties };
export type {
  BuyAPIUploadPropertiesPayload,
  BuyAPIUploadPropertiesSearchParams,
};
