import { Schema } from "@effect/schema";

import { castEnum } from "@ender/shared/utils/effect";

import type { ApplicationsFilterState } from "./applications-filters/applications-filters.types";
import { defaultApplicationsFilters } from "./applications-filters/applications-filters.types";

const ApplicationsPageTabValues = [
  "APPLICATION_INBOX",
  "ALL_APPLICATIONS",
] as const;
const ApplicationsPageTabSchema = Schema.Literal(...ApplicationsPageTabValues);
type ApplicationsPageTab = Schema.Schema.Type<typeof ApplicationsPageTabSchema>;
const ApplicationsPageTabEnum = castEnum(ApplicationsPageTabSchema);
const ApplicationsPageTabSchemaDecodeOption = Schema.decodeOption(
  ApplicationsPageTabSchema,
);

type ApplicationsPageUrlParams = {
  tab: ApplicationsPageTab;
} & ApplicationsFilterState;

const DefaultApplicationsPageUrlParams: ApplicationsPageUrlParams = {
  tab: ApplicationsPageTabEnum.APPLICATION_INBOX,
  ...defaultApplicationsFilters,
} as const;

export {
  ApplicationsPageTabEnum,
  ApplicationsPageTabSchema,
  ApplicationsPageTabSchemaDecodeOption,
  ApplicationsPageTabValues,
  DefaultApplicationsPageUrlParams,
};
export type { ApplicationsPageTab, ApplicationsPageUrlParams };
