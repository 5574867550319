import { Option as O } from "effect";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";

import { useSetTenantLedgerScheduledChargesRail } from "@ender/verticals-api/finance";

import { createTenantLedgerStore } from "./tenant-ledger.store";

type TenantLedgerStore = ReturnType<typeof createTenantLedgerStore>;

const TenantLedgerStoreContext = createContext<O.Option<TenantLedgerStore>>(
  O.none(),
);

function TenantLedgerStoreProvider({ children }: PropsWithChildren) {
  const [, setScheduledChargesRightRailView] =
    useSetTenantLedgerScheduledChargesRail();

  const [store] = useState(() =>
    O.some(createTenantLedgerStore({ setScheduledChargesRightRailView })),
  );

  return (
    <TenantLedgerStoreContext.Provider value={store}>
      {children}
    </TenantLedgerStoreContext.Provider>
  );
}

function useTenantLedgerStore() {
  return useContext(TenantLedgerStoreContext).pipe(O.getOrThrow);
}

export {
  TenantLedgerStoreContext,
  TenantLedgerStoreProvider,
  useTenantLedgerStore,
};
