import type { UseFormReturnType } from "@mantine/form";
import { IconPlus } from "@tabler/icons-react";
import { Predicate as P } from "effect";
import { useContext, useMemo } from "react";

import { searchPropertiesShowUnpurchased } from "@ender/entities/search-input";
import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { cast } from "@ender/shared/types/cast";

import { MoneyInput } from "@ender/shared/ds/money-input";
import { EnderInputWrapper } from "@ender/shared/ui/ender-input-wrapper";
import {
  SearchInput,
  hydratePropertyWithFriendlyId,
} from "@ender/shared/ui/search-input";
import type { AllocationListItemInput } from "@ender/widgets/finance/allocation-list";
import { AllocationList } from "@ender/widgets/finance/allocation-list";

import { getTotalAmountFromAllocationsByProperty } from "./get-total-amount-from-allocations-by-property";
import type { CreateInvoiceFormInput } from "./use-create-invoice-form";

type PropertiesAllocationsInputProps = {
  canChangeProperty: boolean;
  form: UseFormReturnType<CreateInvoiceFormInput>;
  hidePayableCategorySelect?: boolean;
  onAddProperty: () => void;
  onRemoveProperty: (propertyIndex: number) => void;
  onRemovePropertyAllocation: (
    propertyIndex: number,
    allocationIndex: number,
  ) => void;
};

function PropertiesAllocationsInput({
  canChangeProperty,
  form,
  hidePayableCategorySelect = false,
  onAddProperty,
  onRemoveProperty,
  onRemovePropertyAllocation,
}: PropertiesAllocationsInputProps) {
  const { user } = useContext(UserContext);

  const totalAllocationsAmount = getTotalAmountFromAllocationsByProperty(
    form.values.allocationsByProperty,
  );

  const canRemoveProperty = useMemo(
    () => form.values.allocationsByProperty.length > 1 && canChangeProperty,
    [form.values.allocationsByProperty.length, canChangeProperty],
  );

  function onPropertyIdChange(value: EnderId, propertyIndex: number) {
    form.setFieldValue("instantApprove", false);
    form
      .getInputProps(`allocationsByProperty.${propertyIndex}.propertyId`)
      .onChange(value);
    const updatedAllocations = form.values.allocationsByProperty[
      propertyIndex
    ].allocations.map((allocation) => ({
      ...allocation,
      propertyId: value,
    }));
    form.setFieldValue(
      `allocationsByProperty.${propertyIndex}.allocations`,
      updatedAllocations,
    );
  }

  return (
    <>
      {form.values.allocationsByProperty.map((property, propertyIndex) => {
        const { allocations, propertyId } = property;

        function updateAllocations(allocations: AllocationListItemInput[]) {
          const updatedAllocations = allocations.map((allocation) => ({
            ...allocation,
            propertyId,
          }));
          form.setFieldValue(
            `allocationsByProperty.${propertyIndex}.allocations`,
            updatedAllocations,
          );
        }

        /**
         * NOTE: the errors key should be `allocationsByProperty.${propertyIndex}.allocations.0.amount`
         * but the current form validation does not handle this structure, so below is what works best atm
         */
        const vendorAmountError =
          form.errors[
            `allocationsByProperty.${propertyIndex}.allocations.0.amount`
          ];
        return (
          <Card key={`${propertyId}${propertyIndex}`}>
            <Stack>
              {canRemoveProperty && (
                <Group justify={Justify.end}>
                  <Button
                    onClick={() => onRemoveProperty(propertyIndex)}
                    variant={ButtonVariant.transparent}>
                    Remove Property
                  </Button>
                </Group>
              )}
              {/* TODO @kfalicov, this is a candidate for entities */}
              <SearchInput<EnderId>
                error={
                  form.getInputProps(
                    `allocationsByProperty.${propertyIndex}.propertyId`,
                  )?.error
                }
                hydrate={hydratePropertyWithFriendlyId}
                label="Property"
                onChange={(value) =>
                  onPropertyIdChange(cast<EnderId>(value), propertyIndex)
                }
                placeholder="Search for a property"
                search={searchPropertiesShowUnpurchased}
                // searchOnEmpty // TODO @kfalicov, this isn't working as expected
                searchType={ModelTypeEnum.PROPERTY}
                //@ts-expect-error propertyId is a string that hasn't been validated as EnderId yet.
                value={propertyId}
              />
              {user.isPM && (
                <>
                  <EnderInputWrapper
                    key={`${propertyId}${propertyIndex}`}
                    label="Allocations"
                    {...form.getInputProps(
                      `allocationsByProperty.${propertyIndex}.allocations`,
                    )}
                    error={form.errors.allocations}>
                    <AllocationList
                      allocations={allocations}
                      getInputProps={(index, field) =>
                        form.getInputProps(
                          `allocationsByProperty.${propertyIndex}.allocations.${index}.${field}`,
                        )
                      }
                      hidePayableCategorySelect={hidePayableCategorySelect}
                      onRemoveAllocation={(index) =>
                        onRemovePropertyAllocation(propertyIndex, index)
                      }
                      //@ts-expect-error propertyId is a string that hasn't been validated as EnderId yet.
                      propertyId={propertyId}
                      updateAllocations={updateAllocations}
                    />
                  </EnderInputWrapper>
                </>
              )}

              {/* Vendors just choose an amount - they don't get to do accounting. */}
              {/* disable this for propertyId is undefined */}
              {user.isVendor && P.isNotNullable(propertyId) && (
                <MoneyInput
                  error={vendorAmountError}
                  label="Amount"
                  name="amount"
                  onChange={(val) => {
                    updateAllocations([
                      {
                        amount: val,
                        categoryId: UNDEFINED,
                        propertyId,
                      },
                    ]);
                  }}
                  value={allocations[0].amount}
                />
              )}
            </Stack>
          </Card>
        );
      })}

      {canChangeProperty && (
        <Group>
          <Button
            onClick={onAddProperty}
            variant={ButtonVariant.outlined}
            leftSection={<IconPlus />}>
            Add Property
          </Button>
        </Group>
      )}
      <Tuple
        label="Total Invoice Amount:"
        value={totalAllocationsAmount.toJSON()}
      />
    </>
  );
}

export { PropertiesAllocationsInput };
