import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PaymentPlanPaymentPlanStatusValues = [
  "ACTIVE",
  "COMPLETE",
  "MISSED",
] as const;

const PaymentPlanPaymentPlanStatusEffectSchema = Schema.Literal(
  ...PaymentPlanPaymentPlanStatusValues,
);

type PaymentPlanPaymentPlanStatus = Schema.Schema.Type<
  typeof PaymentPlanPaymentPlanStatusEffectSchema
>;

const PaymentPlanPaymentPlanStatusEnum = castEnum(
  PaymentPlanPaymentPlanStatusEffectSchema,
);

function randomPaymentPlanPaymentPlanStatus(): PaymentPlanPaymentPlanStatus {
  return rand(PaymentPlanPaymentPlanStatusValues);
}

export {
  PaymentPlanPaymentPlanStatusEffectSchema,
  PaymentPlanPaymentPlanStatusEnum,
  PaymentPlanPaymentPlanStatusValues,
  randomPaymentPlanPaymentPlanStatus,
};
export type { PaymentPlanPaymentPlanStatus };
