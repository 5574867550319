import type { KeywordSearch } from "@ender/entities/search-input";
import type { EnderId } from "@ender/shared/core";
import { GeneralLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import type { CategoryFlag } from "@ender/shared/generated/ender.model.accounting";

const searchGetTxCategories: (
  categoryFlags?: CategoryFlag[],
) => KeywordSearch<EnderId> =
  (categoryFlags?: CategoryFlag[]) => (keyword: string) =>
    GeneralLedgerAPI.getTxCategories({
      categoryFlags,
      excludeIds: [],
      excludeParentCategories: true,
      includeBankAccountCategories: true,
      includeRoots: false,
      keyword,
    }).then((res) =>
      res.map((category) => ({
        label: `${category.accountNumber} ${category.accountName}`,
        meta: category,
        value: category.id,
      })),
    );

export { searchGetTxCategories };
