/**
 * Delete a bank transaction permanently.
 * HTTP Method: DELETE
 * Pathname: /bankTransactions/{bankTransactionId}
 * @function deleteBankTransaction
 * @memberof BankingAPI
 * @param {BankingAPIDeleteBankTransactionPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIDeleteBankTransactionPathParams = {
  bankTransactionId: EnderId;
};

type BankingAPIDeleteBankTransactionSearchParams = {
  token?: string | undefined;
};

type BankingAPIDeleteBankTransactionPayload =
  BankingAPIDeleteBankTransactionPathParams &
    BankingAPIDeleteBankTransactionSearchParams;

function deleteBankTransactionUnsafeEffect(
  payload: BankingAPIDeleteBankTransactionPayload,
) {
  const { bankTransactionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/bankTransactions/${bankTransactionId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteBankTransaction(
  payload: BankingAPIDeleteBankTransactionPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteBankTransactionUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteBankTransaction };
export type {
  BankingAPIDeleteBankTransactionPathParams,
  BankingAPIDeleteBankTransactionPayload,
  BankingAPIDeleteBankTransactionSearchParams,
};
