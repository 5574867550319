import { assignToUnderwriter } from "./assign-to-underwriter";
import { computeRepairCalculationsPreview } from "./compute-repair-calculations-preview";
import { computeUnderwritingPreview } from "./compute-underwriting-preview";
import { getHomeValueComps } from "./get-home-value-comps";
import { getRentComps } from "./get-rent-comps";
import { getRepairCalculations } from "./get-repair-calculations";
import { getUnderwriting } from "./get-underwriting";
import { saveRepairCalculation } from "./save-repair-calculation";
import { saveUnderwritingModel } from "./save-underwriting-model";

const UnderwritingAPI = {
  assignToUnderwriter,
  computeRepairCalculationsPreview,
  computeUnderwritingPreview,
  getHomeValueComps,
  getRentComps,
  getRepairCalculations,
  getUnderwriting,
  saveRepairCalculation,
  saveUnderwritingModel,
};

export { UnderwritingAPI };
