/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/identityAnswers
 * @function answerIdentityQuestions
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIAnswerIdentityQuestionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<IdentityVerificationResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { IdentityVerificationResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIAnswerIdentityQuestionsPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIAnswerIdentityQuestionsSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIAnswerIdentityQuestionsBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  questions: any;
};

type ApplicationsAPIAnswerIdentityQuestionsPayload =
  ApplicationsAPIAnswerIdentityQuestionsPathParams &
    ApplicationsAPIAnswerIdentityQuestionsSearchParams &
    ApplicationsAPIAnswerIdentityQuestionsBodyParams;

function answerIdentityQuestionsUnsafeEffect(
  payload: ApplicationsAPIAnswerIdentityQuestionsPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, IdentityVerificationResponse>({
        body,
        decode: unsafeDecodeJsonResponse<IdentityVerificationResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/identityAnswers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function answerIdentityQuestions(
  payload: ApplicationsAPIAnswerIdentityQuestionsPayload,
  options?: { signal?: AbortSignal },
): Promise<IdentityVerificationResponse> {
  return F.pipe(
    payload,
    answerIdentityQuestionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { answerIdentityQuestions };
export type {
  ApplicationsAPIAnswerIdentityQuestionsBodyParams,
  ApplicationsAPIAnswerIdentityQuestionsPathParams,
  ApplicationsAPIAnswerIdentityQuestionsPayload,
  ApplicationsAPIAnswerIdentityQuestionsSearchParams,
};
