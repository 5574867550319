import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

/**
 * @description Operators that can be used in Dynamic Table Filters; defaults to "IN"
 */
const DynamicTableOperatorsValues = [
  "GREATER_THAN_OR_EQUAL",
  "GREATER_THAN",
  "IN",
  "LESS_THAN_OR_EQUAL",
  "LESS_THAN",
  "NOT_IN",
] as const;
const DynamicTableOperatorsSchema = z.enum(DynamicTableOperatorsValues);
type DynamicTableOperators = z.infer<typeof DynamicTableOperatorsSchema>;

const DynamicTableOperatorsEnum = castEnum<DynamicTableOperators>(
  DynamicTableOperatorsSchema,
);

export {
  DynamicTableOperatorsEnum,
  DynamicTableOperatorsSchema,
  DynamicTableOperatorsValues,
};
export type { DynamicTableOperators };
