import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import { useCallback } from "react";
import { useWatch } from "react-hook-form";
import { useStore } from "zustand";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { TransformedSchedule } from "@ender/shared/contexts/scheduled-charges";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import { FormSwitch } from "@ender/shared/ds/switch";
import { Text } from "@ender/shared/ds/text";
import { ChargeSchedulesAPI } from "@ender/shared/generated/ender.api.leasing";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";

import { useCurrentScheduledCharges } from "../hooks";
import { useTenantLedgerStore } from "../tenant-ledger-store.context";

const EditUpcomingScheduleSchema = Schema.Struct({
  amount: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Amount is required",
    }),
  ),
  inclusiveEndDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  isCustomEndDate: Schema.Boolean,
  startDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Start date is required",
    }),
  ),
}).pipe(
  Schema.filter((values) => {
    if (values.isCustomEndDate && O.isNone(values.inclusiveEndDate)) {
      return {
        message: "This field is required",
        path: ["inclusiveEndDate"],
      };
    }
    if (
      values.isCustomEndDate &&
      O.getOrder(LocalDate$.Order)(values.startDate, values.inclusiveEndDate) >=
        0
    ) {
      return {
        message: "End Date must be after Start Date",
        path: ["inclusiveEndDate"],
      };
    }
    if (O.exists(values.startDate, (v) => v.isBefore(LocalDate$.today()))) {
      return {
        message: "Start Date must be in the future",
        path: ["startDate"],
      };
    }
  }),
);

type EditUpcomingScheduleFormOutput = Schema.Schema.Type<
  typeof EditUpcomingScheduleSchema
>;

type EditUpcomingScheduleFormProps = {
  scheduledCharge: TransformedSchedule;
  onCancel: () => void;
};

function EditUpcomingScheduleForm({
  scheduledCharge,
  onCancel,
}: EditUpcomingScheduleFormProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      amount: Money$.parse(scheduledCharge.amount),
      inclusiveEndDate: LocalDate$.parse(scheduledCharge.inclusiveEndDate),
      isCustomEndDate: P.isNotNullable(scheduledCharge.inclusiveEndDate),
      startDate: LocalDate$.parse(scheduledCharge.startDate),
    },
    schema: EditUpcomingScheduleSchema,
  });

  const { refetchScheduledCharges } = useCurrentScheduledCharges();
  const tenantLedgerStore = useTenantLedgerStore();

  const { setSelectedScheduledChargeId } = useStore(
    tenantLedgerStore,
    (state) => ({
      setSelectedScheduledChargeId: state.setSelectedScheduledChargeId,
    }),
  );
  const confirmation = useConfirmationContext();

  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };

  const { mutateAsync: updateChargeScheduleWithWarnings, isLoading } =
    useMutation({
      mutationFn: withWarningHandler(
        ChargeSchedulesAPI.updateChargeSchedule,
        onWarnings,
      ),
    });

  const handleSubmit = useCallback(
    async (values: EditUpcomingScheduleFormOutput) => {
      await updateChargeScheduleWithWarnings({
        amount: O.getOrThrow(values.amount).toJSON(),
        chargeScheduleId: scheduledCharge.id,
        inclusiveEndDate: O.getOrUndefined(values.inclusiveEndDate)?.toJSON(),
        startDate: values.startDate.pipe(O.getOrThrow).toJSON(),
      });
      showSuccessNotification({
        message: "Scheduled charge successfully updated.",
      });
      await refetchScheduledCharges();
      setSelectedScheduledChargeId(O.some(scheduledCharge.id));
    },
    [
      updateChargeScheduleWithWarnings,
      scheduledCharge.id,
      refetchScheduledCharges,
      setSelectedScheduledChargeId,
    ],
  );

  const isCustomEndDate = useWatch({
    control: form.control,
    name: "isCustomEndDate",
  });

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormDateInput
          name="startDate"
          label="Start Date"
          form={form}
          placeholder="MM/DD/YYYY"
        />
        <Stack spacing={Spacing.sm}>
          <FormSwitch
            name="isCustomEndDate"
            label="Set Custom End Date"
            form={form}
          />
          <Text>
            Set custom end date if the scheduled charge will end before move out
          </Text>
        </Stack>
        {isCustomEndDate && (
          <FormDateInput
            name="inclusiveEndDate"
            label="End Date"
            form={form}
            placeholder="MM/DD/YYYY"
          />
        )}
        <FormMoneyInput
          name="amount"
          label="Amount"
          form={form}
          placeholder="0.00"
        />
        <Group justify={Justify.end} spacing={Spacing.md}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={isLoading} type="submit">
            Save Changes
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditUpcomingScheduleForm };
