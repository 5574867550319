/**
 * HTTP Method: GET
 * Pathname: /applications/{appGroupId}
 * @function getApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIGetApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetApplicationGroupResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetApplicationGroupResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIGetApplicationPathParams = {
  appGroupId: EnderId;
};

type ApplicationsAPIGetApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIGetApplicationPayload =
  ApplicationsAPIGetApplicationPathParams &
    ApplicationsAPIGetApplicationSearchParams;

function getApplicationUnsafeEffect(
  payload: ApplicationsAPIGetApplicationPayload,
) {
  const { appGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetApplicationGroupResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetApplicationGroupResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/applications/${appGroupId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getApplication(
  payload: ApplicationsAPIGetApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<GetApplicationGroupResponse> {
  return F.pipe(
    payload,
    getApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getApplication };
export type {
  ApplicationsAPIGetApplicationPathParams,
  ApplicationsAPIGetApplicationPayload,
  ApplicationsAPIGetApplicationSearchParams,
};
