import type { FocusEventHandler, ReactNode } from "react";
import { z } from "zod";

import type { MantineSize } from "@ender/shared/types/ender-general";

/**
 * @deprecated - This should no longer be used.
 */
type FormControlProps = "error" | "onBlur" | "onChange" | "onFocus" | "value";

/**
 * @deprecated - This should no longer be used.
 */
const generateFormControlPropsSchema = <T extends z.ZodTypeAny>(schema: T) => {
  return z.object({
    error: z.custom<ReactNode>().optional(),
    onBlur: z.custom<FocusEventHandler<HTMLInputElement>>().optional(),
    onChange: z.function().args(schema).optional(),
    onFocus: z.custom<FocusEventHandler<HTMLInputElement>>().optional(),
    value: schema,
  });
};

const DataAttributeKeySchema = z.custom<`data-${string}`>((val) => {
  return /^data-/.test(val as string);
});

/**
 * @deprecated - This should no longer be used.
 */
const DataPropsSchema = z.record(DataAttributeKeySchema, z.any());

/**
 * @deprecated - This should no longer be used.
 */
const InputInfoPropsSchema = z.object({
  "aria-label": z.string().optional(),
  autoComplete: z.string().optional(),
  autoFocus: z.boolean().optional(),
  description: z.string().optional(),
  disabled: z.boolean().optional(),
  id: z.string().optional(),
  label: z.custom<ReactNode>().optional(),
  placeholder: z.string().optional(),
  readOnly: z.boolean().optional(),
  required: z.boolean().optional(),
  withAsterisk: z.boolean().optional(),
});

/**
 * @deprecated - Do not use this type directly.
 */
type InputInfoProps = z.infer<typeof InputInfoPropsSchema>;

/**
 * @deprecated - This should no longer be used.
 */
const generateGeneralMantinePropsSchema = <T extends z.ZodTypeAny>(
  schema: T,
) => {
  return InputInfoPropsSchema.merge(
    generateFormControlPropsSchema(schema),
  ).extend({
    // name must be here and not in InputInfoPropsSchema because otherwise the
    // prop types in FormGenerator schema will have name required which shouldn't be allowed
    name: z.string().optional(),
    size: z.custom<MantineSize>().optional(), //TODO use an enum of supported size values here
    testId: z.string().optional(),
  });
};

/**
 * @deprecated - This should no longer be used.
 */
const ReactNodeSchema = z.union([z.custom<ReactNode>(), z.string()]);

export {
  DataPropsSchema,
  generateFormControlPropsSchema,
  generateGeneralMantinePropsSchema,
  InputInfoPropsSchema,
  ReactNodeSchema,
};

export type { FormControlProps, InputInfoProps };
