import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyDogPolicyValues = [
  "NO_DOGS_ALLOWED",
  "SMALL_DOGS_ALLOWED",
  "DOGS_ALLOWED",
] as const;

const PropertyDogPolicyEffectSchema = Schema.Literal(
  ...PropertyDogPolicyValues,
);

type PropertyDogPolicy = Schema.Schema.Type<
  typeof PropertyDogPolicyEffectSchema
>;

const PropertyDogPolicyEnum = castEnum(PropertyDogPolicyEffectSchema);

function randomPropertyDogPolicy(): PropertyDogPolicy {
  return rand(PropertyDogPolicyValues);
}

export {
  PropertyDogPolicyEffectSchema,
  PropertyDogPolicyEnum,
  PropertyDogPolicyValues,
  randomPropertyDogPolicy,
};
export type { PropertyDogPolicy };
