import { Predicate as P } from "effect";
import { z } from "zod";
import { create } from "zustand";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type { Branding } from "@ender/shared/types/branding";
import { fail } from "@ender/shared/utils/error";
import { rest } from "@ender/shared/utils/rest";
import { castEnum } from "@ender/shared/utils/zod";

const BrandingStateValues = [
  "UNINITIALIZED",
  "FAIL",
  "LOADING",
  "LOADED",
] as const;
const BrandingStateSchema = z.enum(BrandingStateValues);
type BrandingState = z.infer<typeof BrandingStateSchema>;
const BrandingStateEnum = castEnum<BrandingState>(BrandingStateSchema);

type BrandingStoreState = {
  branding: Branding | undefined;
  brandingState: BrandingState;
  isEnabled: boolean;

  attemptLoadingBranding(canImplementBranding: boolean): Promise<void>;
  setIsEnabled(isEnabled: boolean): void;
};

const useBrandingStore = create<BrandingStoreState>()((set) => {
  return {
    branding: UNDEFINED,
    brandingState: BrandingStateEnum.UNINITIALIZED,
    isEnabled: false,

    async attemptLoadingBranding(canImplementBranding: boolean): Promise<void> {
      const isSubdomain = window.location.hostname.endsWith(".ender.com");
      // If it's not a subdomain or canImplementBranding is false, skip the process
      if (!isSubdomain || !canImplementBranding) {
        set({ brandingState: BrandingStateEnum.FAIL });
        return;
      }

      // Proceed with the original logic as it's a subdomain and canImplementBranding is true
      set({ brandingState: BrandingStateEnum.LOADING });

      try {
        const subdomain = window.location.hostname.split(".ender.com")[0];

        const { id } = await rest.get<Promise<{ id: EnderId | undefined }>>(
          `/pmForSubdomain?subdomain=${subdomain}`,
        );
        if (P.isNullable(id)) {
          set({ brandingState: BrandingStateEnum.FAIL });
          return;
        }

        const branding = await rest.get<Promise<Branding>>(
          `/pms/${id}/whiteLabelConfig`,
        );
        set({ branding, brandingState: BrandingStateEnum.LOADED });
      } catch (e) {
        fail(e);
        set({ brandingState: BrandingStateEnum.FAIL });
      }
    },
    setIsEnabled(isEnabled: boolean): void {
      set({ isEnabled });
    },
  };
});

export { BrandingStateEnum, useBrandingStore };
export type { BrandingState, BrandingStoreState };
