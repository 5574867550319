//No RadioPageObject or tests as Radio must be used within RadioGroup
// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Indicator, Item } from "@radix-ui/react-radio-group";
import { cva } from "class-variance-authority";
import type { ForwardedRef, ReactNode, Ref } from "react";
import { forwardRef, useId } from "react";

import { Align, Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";
import { isEmptyReactNode } from "../../../utils";

type RadioProps<T extends string> = {
  label?: ReactNode;
  value: T;
  disabled?: boolean;
};
const RadioVariantGenerator = cva(
  [
    "peer w-4 h-4 rounded-full border border-slate-200 flex items-center justify-center",
    "data-[state=checked]:bg-primary-500 data-[state=checked]:border-primary-500",
    "disabled:data-[state=checked]:bg-gray-200 disabled:data-[state=checked]:border-gray-200",
    "disabled:border-gray-200 disabled:cursor-default",
  ],
  {
    variants: {},
  },
);

const Radio = forwardRef(function Radio<T extends string>(
  props: RadioProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { label, value, disabled } = props;
  const id = useId();
  return (
    <Group spacing={Spacing.sm} align={Align.center} noWrap>
      <Item
        value={value.toString()}
        ref={ref}
        disabled={disabled}
        id={id}
        className={RadioVariantGenerator(props)}>
        <Indicator className="block rounded-full bg-white w-2 h-2" />
      </Item>
      {!isEmptyReactNode(label) && (
        <label
          htmlFor={id}
          className="text-slate-900 peer-disabled:text-gray-200">
          {label}
        </label>
      )}
    </Group>
  );
}) as <T extends string>(
  // eslint-disable-next-line no-use-before-define
  props: RadioProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReactNode;
export { Radio };
export type { RadioProps };
