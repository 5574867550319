import { Schema as S } from "@effect/schema";

import type { EnderId } from "@ender/shared/core";
import type {
  GetApprovalProcessesResponse as GeneratedGetApprovalProcessesResponse,
  GetApprovalProcessResponseStep,
} from "@ender/shared/generated/ender.api.misc.response";
import type { ApprovalProcess } from "@ender/shared/generated/ender.model.approvals";
import { castEnum } from "@ender/shared/utils/effect";

const PARAMETER_VALUES = ["LESS_THAN", "MORE_THAN", "BETWEEN"] as const;
const ParameterSchema = S.Literal(...PARAMETER_VALUES);
const ParameterEnum = castEnum(ParameterSchema);
type ParameterType = S.Schema.Type<typeof ParameterSchema>;

const MODE_VALUES = ["FIXED", "CUMULATIVE"] as const;
const ModeSchema = S.Literal(...MODE_VALUES);
const ModeEnum = castEnum(ModeSchema);

const DEFAULT_APPROVAL_PROCESS_HYBRID_ID = "default" as const;
const NEW_APPROVAL_PROCESS_HYBRID_ID = "new" as const;

/**
 * This "HybridId" handles the two-part issue of:
 * 1. BE returns a default approval process (without id) and separate list of approval processes by id
 * 2. FE SelectApprovalProcess wants value for approval processes including default and a "new approval process" option
 */
type ApprovalProcessHybridId =
  | EnderId
  | typeof DEFAULT_APPROVAL_PROCESS_HYBRID_ID
  | typeof NEW_APPROVAL_PROCESS_HYBRID_ID;

type ApprovalProcessById = Pick<ApprovalProcess, "maxAmount" | "minAmount"> & {
  payableTypes: ApprovalProcess["payableType"];
  steps: GetApprovalProcessResponseStep[];
};

type GetApprovalProcessesResponse = GeneratedGetApprovalProcessesResponse &
  Pick<GeneratedGetApprovalProcessesResponse, "approvalProcessById"> & {
    approvalProcessById: Record<EnderId, ApprovalProcessById>;
  };

export {
  DEFAULT_APPROVAL_PROCESS_HYBRID_ID,
  MODE_VALUES,
  ModeEnum,
  ModeSchema,
  NEW_APPROVAL_PROCESS_HYBRID_ID,
  PARAMETER_VALUES,
  ParameterEnum,
  ParameterSchema,
};
export type {
  ApprovalProcessHybridId,
  GetApprovalProcessesResponse,
  ParameterType,
};
