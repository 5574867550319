import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import { useState } from "react";

import type { EnderId, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import { MoneyInput } from "@ender/shared/ds/money-input";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type EditNSFFormProps = {
  leaseId: EnderId;
  achReversalFee: Money$.Money | undefined;
  defaultReversalFee: Money$.Money | undefined;
  closeRail: () => void;
  refetchLease: () => void;
};

function EditNsfForm(props: EditNSFFormProps) {
  const { leaseId, achReversalFee, closeRail, refetchLease } = props;

  const [fee, setFee] = useState<O.Option<Money$.Money>>(O.fromNullable(achReversalFee));

  const { mutateAsync: updateNSFFee, isLoading } = useMutation({
    mutationFn: (fee: O.Option<Money$.Money>) =>
      LeasingAPI.updateLease({
        leaseId,
        achReversalFee: F.pipe(
          fee,
          O.map((m) => m.toJSON()),
          O.getOrUndefined,
        ),
      }),
  });

  async function handleSave() {
    await updateNSFFee(fee);
    closeRail();
    refetchLease();
    showSuccessNotification({ message: "NSF Fee updated" });
  }

  return (
    <Stack justify={Justify.between} align={Align.center}>
      <MoneyInput
        label="NSF Fee"
        value={fee}
        onChange={setFee}
        disabled={isLoading}
      />
      <Group justify={Justify.end}>
        <Button onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
      </Group>
    </Stack>
  );
}

export { EditNsfForm };
