import { Function as F, Predicate as P } from "effect";
import type { FocusEvent, ForwardedRef } from "react";
import { forwardRef, useEffect, useState } from "react";

import { TextInput } from "@ender/shared/ds/text-input";

type PercentInputProps = {
  decimalPrecision?: number;
  disabled?: boolean;
  label: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  value: string;
};

/**
 * @deprecated use shared-ds-percent-input
 */
const PercentInput_ = (
  {
    decimalPrecision = 3,
    disabled = false,
    onBlur = F.constVoid,
    onChange = F.constVoid,
    value,
    ...textInputProps
  }: PercentInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const [displayValue, setDisplayValue] = useState(
    P.isNotNullable(value) ? parseFloat(value).toFixed(decimalPrecision) : "",
  );

  // handle form values changing in parent component after mount
  useEffect(() => {
    setDisplayValue(() =>
      P.isNotNullable(value) ? parseFloat(value).toFixed(decimalPrecision) : "",
    );
  }, [decimalPrecision, value]);

  function handleChange(val: string) {
    setDisplayValue(val);
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    const parsedValue = parseFloat(e.target.value);
    const formattedValue = isNaN(parsedValue)
      ? ""
      : parsedValue.toFixed(decimalPrecision);
    setDisplayValue(formattedValue);
    onChange(formattedValue);
    onBlur(e);
  }

  return (
    <div style={{ display: "inline-block", minWidth: "10ch" }}>
      <TextInput
        value={displayValue}
        onChange={handleChange}
        //@ts-expect-error blur is not exposed through TextInput. use PercentInput instead
        onBlur={handleBlur}
        ref={ref}
        rightSection="%"
        disabled={disabled}
        {...textInputProps}
      />
    </div>
  );
};

/**
 * @deprecated use PercentInput from @ender/shared/ds/percent-input
 */
const PercentInput = forwardRef(PercentInput_);

export { PercentInput };
