/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/receipts
 * @function createLeaseReceipt
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPICreateLeaseReceiptPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransfer>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { TenantLedgerAllocationRequest } from "@ender/shared/generated/ender.api.model";
import type { MoneyTransfer } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPICreateLeaseReceiptPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPICreateLeaseReceiptSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPICreateLeaseReceiptBodyParams = {
  allocations: TenantLedgerAllocationRequest[];
  amount: SerializesInto<Money>;
  bankAccountId: EnderId;
  checkNumber: string;
  date: SerializesInto<LocalDate>;
  depositDate?: SerializesInto<LocalDate> | undefined;
  imported?: boolean | undefined;
  isDeposited?: boolean | undefined;
  memo: string;
  niceId: string;
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
};

type TenantLedgerAPICreateLeaseReceiptPayload =
  TenantLedgerAPICreateLeaseReceiptPathParams &
    TenantLedgerAPICreateLeaseReceiptSearchParams &
    TenantLedgerAPICreateLeaseReceiptBodyParams;

function createLeaseReceiptUnsafeEffect(
  payload: TenantLedgerAPICreateLeaseReceiptPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransfer>({
        body,
        decode: unsafeDecodeJsonResponse<MoneyTransfer>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/receipts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createLeaseReceipt(
  payload: TenantLedgerAPICreateLeaseReceiptPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransfer> {
  return F.pipe(
    payload,
    createLeaseReceiptUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createLeaseReceipt };
export type {
  TenantLedgerAPICreateLeaseReceiptBodyParams,
  TenantLedgerAPICreateLeaseReceiptPathParams,
  TenantLedgerAPICreateLeaseReceiptPayload,
  TenantLedgerAPICreateLeaseReceiptSearchParams,
};
