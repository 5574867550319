import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import { UnitAreasZonesMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type { UnitAreaSummaryResponse } from "@ender/shared/generated/com.ender.middle.response";

import {
  UnitAreaSummaryDisplayLabelMap,
  getTupleDisplayValue,
} from "./construction.utils";

function UnitAreaSummaryCard({ unitId }: { unitId: EnderId }) {
  const { data: summary } = useQuery({
    queryKey: ["UnitAreasZonesMiddleLayerAPI.getUnitAreaSummary", { unitId }],
    queryFn: () => UnitAreasZonesMiddleLayerAPI.getUnitAreaSummary({ unitId }),
  });

  if (P.isNullable(summary)) {
    return NULL;
  }

  return (
    <Card>
      <Stack spacing={Spacing.sm}>
        <H3>Property Summary</H3>
        <Stack spacing={Spacing.md}>
          <div style={{ maxWidth: "500px" }}>
            <Text size={FontSize.sm}>
              Summary details are automatically generated from the information
              recorded in the property’s areas and zones. To make updates, edit
              the relevant areas and zones below.
            </Text>
          </div>
          <Grid spacingY={Spacing.xs}>
            {Object.entries(summary).map(([key, value]) => (
              <Tuple
                key={key}
                label={
                  UnitAreaSummaryDisplayLabelMap[
                    key as keyof UnitAreaSummaryResponse
                  ]
                }
                value={getTupleDisplayValue(value)}
              />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Card>
  );
}

export { UnitAreaSummaryCard };
