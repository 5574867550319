import { zodResolver } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import { useCallback } from "react";
import { z } from "zod";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { EnderIdSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { useForm } from "@ender/shared/forms/hooks/general";
import type {
  PetsAPIAddPetToOwnerPayload,
  PetsAPIUpdatePetPayload,
} from "@ender/shared/generated/ender.api.leasing";
import { PetsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import { NumberInput } from "@ender/shared/ui/number-input";
import { Select } from "@ender/shared/ui/select";

const AddUpdatePetSchema = z.object({
  petId: EnderIdSchema.nullable(),
  name: z.string().min(1, "Name is required"),
  species: z.string().min(1, "Species is required"),
  breed: z.string(),
  age: z
    .number()
    .nullable()
    .refine(P.isNotNullable, { message: "Age is required" }),
  weightInPounds: z
    .number()
    .nullable()
    .refine(P.isNotNullable, { message: "Weight is required" }),
  isNeutered: z.boolean(),
  isEmotionalSupportAnimal: z.boolean(),
});
type AddEditPetFormInput = z.input<typeof AddUpdatePetSchema>;
type AddEditPetFormOutput = z.output<typeof AddUpdatePetSchema>;

type AddEditPetFormProps = {
  onSuccess?: () => void;
  onCancel?: () => void;
} & (
  | {
      //if a full pet object is provided, we are editing an existing pet
      pet: Pet;
      ownerId?: EnderId;
    }
  | {
      //if no pet object is provided, we are adding a new pet, and owner ID is required
      pet?: undefined;
      ownerId: EnderId;
    }
);

const petSpeciesOptions = ["Dog", "Cat", "Bird", "Other"] as const;

/**
 * a component that controls adding and editing pets to User owners
 */
function AddEditPetForm({
  ownerId,
  pet,
  onSuccess = F.constVoid,
  onCancel,
}: AddEditPetFormProps) {
  const { mutateAsync: addPet, isLoading: addingPet } = useMutation({
    mutationFn: (values: PetsAPIAddPetToOwnerPayload) =>
      PetsAPI.addPetToOwner(values),
    mutationKey: ["PetsAPI.addPetToOwner"] as const,
  });
  const { mutateAsync: updatePet, isLoading: updatingPet } = useMutation({
    mutationFn: (values: PetsAPIUpdatePetPayload) => PetsAPI.updatePet(values),
    mutationKey: ["PetsAPI.updatePet"] as const,
  });

  const form = useForm<AddEditPetFormInput>({
    initialValues: {
      petId: pet?.id ?? NULL,
      name: pet?.name ?? "",
      species: pet?.species ?? "",
      breed: pet?.breed ?? "",
      age: pet?.age ?? NULL,
      weightInPounds: pet?.weightInPounds ?? NULL,
      isNeutered: pet?.isNeutered || false,
      isEmotionalSupportAnimal: P.isNotNullable(
        pet?.emotionalSupportAnimalTime,
      ),
    },
    validate: zodResolver(AddUpdatePetSchema),
  });

  const handleSubmit = useCallback(
    async ({ petId, ...values }: AddEditPetFormOutput) => {
      if (P.isNotNullable(petId)) {
        await updatePet({ petId, ...values });
      } else {
        if (P.isNotNullable(ownerId)) {
          await addPet({ ownerId, ...values });
        } else {
          throw new Error("Owner is required to add a pet");
        }
      }
      onSuccess();
    },
    [addPet, ownerId, onSuccess, updatePet],
  );

  return (
    // @ts-expect-error handleSubmit is compatible with the form's validated output
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label="Name"
          name="name"
          placeholder="Enter Pet Name"
          {...form.getInputProps("name")}
        />
        <Select
          label="Species"
          data={petSpeciesOptions}
          name="species"
          placeholder="Select species"
          {...form.getInputProps("species")}
        />
        <TextInput
          label="Breed"
          name="breed"
          placeholder="Enter Breed"
          {...form.getInputProps("breed")}
        />
        <NumberInput
          label="Age"
          name="age"
          placeholder="Enter Current Age of Pet"
          min={0}
          {...form.getInputProps("age")}
        />
        <NumberInput
          label="Weight (lbs.)"
          name="weightInPounds"
          placeholder="Enter Weight in Pounds"
          min={0}
          {...form.getInputProps("weightInPounds")}
        />
        <Checkbox
          label="Neutered or Spayed?"
          name="isNeutered"
          {...form.getInputProps("isNeutered")}
        />
        <Checkbox
          label="ESA/Service Animal"
          name="isEmotionalSupportAnimal"
          {...form.getInputProps("isEmotionalSupportAnimal")}
        />
        <Group justify={Justify.end}>
          {P.isNotNullable(onCancel) && (
            <Button variant={ButtonVariant.outlined} onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button type="submit" loading={addingPet || updatingPet}>
            Save
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

export { AddEditPetForm };
