import { IconMessage, IconNote } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";

import { ApplicationStatusBadge } from "@ender/entities/application/applicant-status-badge";
import { ApplicationReviewStatusBadge } from "@ender/entities/application/application-review-status-badge";
import { ApprovableCardHeader } from "@ender/entities/application/approvable-card-header";
import {
  applicantAccordionSummaryText,
  applicationPriority,
  computeBulkApprovalStatus,
  isBirthdayMinor,
} from "@ender/entities/utils/application-utils";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ender/shared/ds/accordion";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Overflow, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { Modal } from "@ender/shared/ds/modal";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { TabButton, TabContent, Tabs, TabsList } from "@ender/shared/ds/tabs";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { ApplicationsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type { ApplicationGroupApprovalResponseApplicationApprovalResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  ApplicationsAPI,
  ApplicationsAPIApplicationApprovalTypeEnum,
  LeasingAPI,
} from "@ender/shared/generated/ender.api.leasing";
import type { ApplicationGroupApplicationStatus } from "@ender/shared/generated/ender.model.leasing";
import {
  ApplicationGroupApplicationStatusEnum,
  ApplicationIdentityVerificationResultEnum,
} from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { castEnum } from "@ender/shared/utils/zod";
import { ApplicantActionsMenu } from "@ender/widgets/leasing/applications/applicant-actions-menu";
import { ConnectedApplicationApplicantEmploymentCard } from "@ender/widgets/leasing/applications/applicant-employment-card";
import { ConnectedApplicationApplicantInfoCard } from "@ender/widgets/leasing/applications/applicant-info-card";
import { ApplicationApplicantProgress } from "@ender/widgets/leasing/applications/applicant-progress";
import {
  ApprovableReportCardHeader,
  ConnectedApplicantReportCard,
} from "@ender/widgets/leasing/applications/applicant-report-card";
import {
  ApplicationGroupIncomeToRentCard,
  ApprovableIncomeToRentCardHeader,
} from "@ender/widgets/leasing/applications/income-to-rent-card";
import { ConnectedApplicationListingInfoCard } from "@ender/widgets/leasing/applications/listing-info-card";
import {
  ApplicationPetCard,
  ApprovablePetCardHeader,
} from "@ender/widgets/leasing/applications/pet-card";
import { ConnectedApplicationRentalHistoryCard } from "@ender/widgets/leasing/applications/rental-history-card";
import { ApplicationReviewProgress } from "@ender/widgets/leasing/applications/review-progress";
import { ConnectedApplicationSummaryCard } from "@ender/widgets/leasing/applications/summary-card";
import { LeaseChat } from "@ender/widgets/leasing/lease-chat";
import {
  ApprovableVehicleCardHeader,
  VehicleCard,
} from "@ender/widgets/leasing/vehicle-card";
import type { ApplicationRightRailValue } from "@ender/widgets/utils/application-page-right-rail";
import { ApplicationPageRightRailEnum } from "@ender/widgets/utils/application-page-right-rail";

import { ApplicationChat } from "./application-chat";
import { ApplicationPrivateNotes } from "./application-notes";
import { ApplicationPageHeader } from "./application-page-header";
import { ApplicationPageRightRail } from "./application-page-right-rail/application-page-right-rail";

//ApplicationChatTabs
const ApplicationChatTabsValues = ["messages", "notes", "history"] as const;
const ApplicationChatTabsSchema = z.enum(ApplicationChatTabsValues);
type ApplicationChatTabs = z.infer<typeof ApplicationChatTabsSchema>;

const ApplicationChatTab = castEnum<ApplicationChatTabs>(
  ApplicationChatTabsSchema,
);

const editableStatuses: ApplicationGroupApplicationStatus[] = [
  ApplicationGroupApplicationStatusEnum.COMPLETE,
  ApplicationGroupApplicationStatusEnum.IN_PROGRESS,
  ApplicationGroupApplicationStatusEnum.UNDER_REVIEW,
  ApplicationGroupApplicationStatusEnum.INITIAL_ACCEPTED,
  ApplicationGroupApplicationStatusEnum.INITIAL_REJECTED,
  ApplicationGroupApplicationStatusEnum.REJECTED,
];

function ApplicationDetailsPage({
  applicationGroupId,
}: {
  applicationGroupId: EnderId;
}) {
  const { hasPermissions } = useContext(UserContext);
  const hasScreeningPermission = hasPermissions(
    FunctionalPermissionEnum.RUN_CONSUMER_REPORTS,
  );

  const { data: applicationGroupResponse, refetch: refetchApplicationGroup } =
    useQuery({
      queryFn: ({ signal }) =>
        ApplicationsAPI.getApplication(
          { appGroupId: applicationGroupId },
          { signal },
        ),
      queryKey: ["ApplicationsAPI.getApplication", applicationGroupId] as const,
    });

  const isReview = P.isNotNullable(
    applicationGroupResponse?.applicationGroup?.completedAt,
  );

  const { data: applicationGroupApprovalResponse, refetch: refetchApprovals } =
    useQuery({
      enabled: P.compose(
        P.isNotNullable<string | undefined>,
        (v) => v !== ApplicationGroupApplicationStatusEnum.IN_PROGRESS,
      )(applicationGroupResponse?.applicationGroup?.status),
      queryFn: ({ queryKey: [, params], signal }) =>
        ApplicationsMiddleLayerAPI.getApplicationApprovals(params, { signal }),
      queryKey: [
        "ApplicationsMiddleLayerAPI.getApplicationApprovals",
        { applicationGroupId },
      ] as const,
    });

  /**
   * refetch both application group and approvals, if we are in review mode
   */
  const handleRefetch = useCallback(() => {
    refetchApplicationGroup();
    isReview && refetchApprovals();
  }, [refetchApplicationGroup, refetchApprovals, isReview]);

  /**
   * allows us to look up approval statuses by applicationId
   * rather than relying on synchronized lists. Helps when we are independently sorting things
   */
  const applicationApprovalsById = useMemo(() => {
    return (
      applicationGroupApprovalResponse?.applicationApprovals.reduce(
        (acc, approval) => {
          acc[approval.applicationId] = approval;
          return acc;
        },
        {} as Record<
          EnderId,
          ApplicationGroupApprovalResponseApplicationApprovalResponse
        >,
      ) ?? {}
    );
  }, [applicationGroupApprovalResponse]);

  /**
   * takes the aggregate of all approval statuses for an application and returns the overall status.
   * Performs this for each application and returns a map of applicationId to application approval status.
   */
  const overallApplicantStatus = useMemo(() => {
    return applicationGroupApprovalResponse?.applicationApprovals.reduce(
      (acc, approvalResponse) => {
        const {
          applicationId,
          incomeApproval,
          personalApproval,
          petApprovals,
          rentalHistoryApproval,
          vehicleApprovals,
        } = approvalResponse;
        acc[applicationId] = computeBulkApprovalStatus(
          [
            incomeApproval,
            personalApproval,
            rentalHistoryApproval,
            ...vehicleApprovals.map(({ approvalStatus }) => approvalStatus),
            ...petApprovals.map(({ approvalStatus }) => approvalStatus),
          ].filter(P.isNotNullable),
        );
        return acc;
      },
      {} as Record<EnderId, ApprovableApprovalStatus>,
    );
  }, [applicationGroupApprovalResponse]);

  const { data: lease } = useQuery({
    enabled: P.isNotNullable(applicationGroupResponse?.leaseId),
    queryFn: ({ signal }) => {
      if (P.isNotNullable(applicationGroupResponse?.leaseId)) {
        return LeasingAPI.getLeaseDetails(
          {
            leaseId: applicationGroupResponse?.leaseId,
          },
          { signal },
        );
      }
    },
    queryKey: [
      "LeasingAPI.getLeaseDetails",
      applicationGroupResponse?.leaseId,
    ] as const,
  });

  const [isLeaseDraftedModalOpen, leaseDraftedModalHandlers] = useBoolean();

  const hasToasted = useRef(false);
  /**
   * show a toast if the application group has an associated lease. Only show on first fetch.
   */
  useEffect(() => {
    if (
      P.isNotNullable(applicationGroupResponse?.leaseId) &&
      !hasToasted.current &&
      P.isNotNullable(applicationGroupResponse?.applicationGroup.archiveTime)
    ) {
      hasToasted.current = true;
      leaseDraftedModalHandlers.setTrue();
    }
  }, [applicationGroupResponse, leaseDraftedModalHandlers]);

  const isEditable = useMemo(() => {
    return (
      P.isNotNullable(applicationGroupResponse?.applicationGroup) &&
      editableStatuses.includes(
        applicationGroupResponse?.applicationGroup.status,
      )
    );
  }, [applicationGroupResponse]);

  const { isLoading: isDeletingVehicle, mutateAsync: deleteVehicle } =
    useMutation({
      mutationFn: LeasingAPI.deleteVehicle,
      mutationKey: ["LeasingAPI.deleteVehicle"],
    });

  const { isLoading: isScreeningAll, mutateAsync: screenAllApplications } =
    useMutation({
      mutationFn: () =>
        ApplicationsAPI.screenApplication({ appGroupId: applicationGroupId }),
      mutationKey: ["ApplicationsAPI.screenApplication"],
    });
  const handleScreenAllApplications = useCallback(async () => {
    await screenAllApplications();
    showSuccessNotification({ message: `Reports run for all applicants.` });
  }, [screenAllApplications]);

  const [rightRail, setRightRail] = useState<ApplicationRightRailValue>({
    type: ApplicationPageRightRailEnum.CLOSED,
  });
  const [tab, setTab] = useState<ApplicationChatTabs>(ApplicationChatTab.notes);

  const sortedApplications = useMemo(() => {
    return (
      applicationGroupResponse?.applications.sort(applicationPriority) ?? []
    );
  }, [applicationGroupResponse?.applications]);

  const sortedNonMinorApplications = useMemo(
    () =>
      sortedApplications.filter(
        ({ applicant }) =>
          !isBirthdayMinor(LocalDate$.parse(applicant.birthday)),
      ),
    [sortedApplications],
  );

  if (P.isNullable(applicationGroupResponse)) {
    return (
      <Group spacing={Spacing.sm}>
        <LoadingSpinner />
        <Text weight={FontWeight.semibold} size={FontSize.lg}>
          Loading Application...
        </Text>
      </Group>
    );
  }

  return (
    <>
      <div
        className="grid h-full gap-4"
        style={{ gridTemplateRows: "auto minmax(0,1fr)" }}>
        <ApplicationPageHeader
          applicationApprovals={applicationApprovalsById}
          applicationGroup={applicationGroupResponse.applicationGroup}
          applications={sortedNonMinorApplications}
          approvalStatusByApplication={overallApplicantStatus}
          incomeApprovalStatus={
            applicationGroupApprovalResponse?.applicationGroupIncomeApproval
              .status
          }
          isEditable={isEditable}
          leaseId={applicationGroupResponse.leaseId}
          needsReassignment={P.isNullable(
            applicationGroupResponse?.unit.activeListingId,
          )}
          refetchApplication={refetchApplicationGroup}
          setRightRail={setRightRail}
        />
        <div
          className="grid gap-4"
          style={{ gridTemplateColumns: "minmax(0, 1fr) 24rem" }}>
          <Stack grow spacing={Spacing.xl} overflow={Overflow.auto}>
            <ConnectedApplicationSummaryCard
              applications={applicationGroupResponse.applications}
              applicationGroup={applicationGroupResponse.applicationGroup}
              isEditable={isEditable}
              onSuccess={refetchApplicationGroup}
            />
            <ConnectedApplicationListingInfoCard
              listingId={applicationGroupResponse.unit.activeListingId}
              unit={applicationGroupResponse.unit}
              property={applicationGroupResponse.property}
            />
            <Accordion>
              {/* the applicants mapped to their accordions. Shows applicant information */}
              {sortedApplications.map((application) => {
                const isMinor = isBirthdayMinor(
                  LocalDate$.parse(application.applicant.birthday),
                );

                const isApplicantEditable =
                  P.isNullable(application?.identityVerificationResult) ||
                  application.identityVerificationResult ===
                    ApplicationIdentityVerificationResultEnum.FAILED ||
                  application.applicationScreened;

                const isApplicationInReview = application.completedApplication;

                return (
                  <AccordionItem
                    key={application.applicant.userId}
                    value={application.applicant.userId}>
                    <AccordionTrigger
                      actions={
                        <ApplicantActionsMenu
                          application={application}
                          applications={sortedApplications}
                          applicationGroupId={applicationGroupId}
                          onSuccess={refetchApplicationGroup}
                          onRequestTransfer={() => {
                            setRightRail({
                              applicationId: application.applicationId,
                              type: ApplicationPageRightRailEnum.TRANSFER_APPLICANT,
                            });
                          }}
                        />
                      }>
                      <Group justify={Justify.between} align={Align.center}>
                        <Stack spacing={Spacing.xs}>
                          <Group align={Align.center} spacing={Spacing.sm}>
                            <H4>
                              {application.applicant.firstName}{" "}
                              {application.applicant.lastName}
                            </H4>
                            {isApplicationInReview ? (
                              <ApplicationReviewStatusBadge
                                status={
                                  overallApplicantStatus?.[
                                    application.applicationId
                                  ]
                                }
                                completion={application.completion}
                              />
                            ) : (
                              <ApplicationStatusBadge
                                application={application}
                              />
                            )}
                          </Group>
                          <Text size={FontSize.sm}>
                            {applicantAccordionSummaryText(
                              application.applicant,
                              application.applicantType,
                            )}
                          </Text>
                        </Stack>
                        <Group align={Align.center}>
                          {isApplicationInReview ? (
                            <ApplicationReviewProgress
                              approval={
                                applicationApprovalsById[
                                  application.applicationId
                                ]
                              }
                            />
                          ) : (
                            <ApplicationApplicantProgress
                              application={application}
                            />
                          )}
                        </Group>
                      </Group>
                    </AccordionTrigger>
                    <AccordionContent variant="bordered">
                      <Stack spacing={Spacing.lg}>
                        <ConnectedApplicationApplicantInfoCard
                          applicant={application.applicant}
                          applicationGroupId={applicationGroupId}
                          applicationId={application.applicationId}
                          applicantType={O.fromNullable(
                            application.applicantType,
                          )}
                          currentAddress={application.currentAddress}
                          header={
                            <ApprovableCardHeader
                              applicationGroupId={applicationGroupId}
                              applicationId={application.applicationId}
                              approval={
                                applicationApprovalsById[
                                  application.applicationId
                                ]?.personalApproval
                              }
                              approvalType={
                                ApplicationsAPIApplicationApprovalTypeEnum.PERSONAL
                              }
                              heading={<H4>Personal Info</H4>}
                              onSuccess={refetchApprovals}
                            />
                          }
                          onSuccess={refetchApplicationGroup}
                          photoIds={application.photoIds}
                          onUploadClick={() =>
                            setRightRail({
                              applicationId: application.applicationId,
                              type: ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_ID,
                            })
                          }
                          isEditable={isEditable && isApplicantEditable}
                          onEditFileClick={(file) =>
                            setRightRail({
                              applicationId: application.applicationId,
                              file,
                              type: ApplicationPageRightRailEnum.EDIT_FILE,
                            })
                          }
                        />
                        {!isMinor && (
                          <ConnectedApplicationApplicantEmploymentCard
                            applicantUserId={application.applicant.userId}
                            application={application}
                            applicationGroupId={applicationGroupId}
                            header={
                              <ApprovableCardHeader
                                applicationGroupId={applicationGroupId}
                                applicationId={application.applicationId}
                                approval={
                                  applicationApprovalsById[
                                    application.applicationId
                                  ]?.incomeApproval
                                }
                                approvalType={
                                  ApplicationsAPIApplicationApprovalTypeEnum.INCOME
                                }
                                disabled={P.isNullable(
                                  application.validatedThreeMonthIncome,
                                )}
                                heading={<H4>Employment & Income</H4>}
                                onSuccess={refetchApprovals}
                              />
                            }
                            isEditable={isEditable}
                            onAddIncomeClick={() =>
                              setRightRail({
                                applicationId: application.applicationId,
                                type: ApplicationPageRightRailEnum.ADD_INCOME_SOURCE,
                              })
                            }
                            onEditFileClick={(file) =>
                              setRightRail({
                                applicationId: application.applicationId,
                                file,
                                type: ApplicationPageRightRailEnum.EDIT_FILE,
                              })
                            }
                            onSuccess={refetchApplicationGroup}
                            onUploadClick={() =>
                              setRightRail({
                                applicationId: application.applicationId,
                                type: ApplicationPageRightRailEnum.UPLOAD_PROOF_OF_INCOME,
                              })
                            }
                          />
                        )}
                        {!isMinor && (
                          <ConnectedApplicationRentalHistoryCard
                            applicantUserId={application.applicant.userId}
                            application={application}
                            applicationGroupId={applicationGroupId}
                            header={
                              <ApprovableCardHeader
                                applicationGroupId={applicationGroupId}
                                applicationId={application.applicationId}
                                approval={
                                  applicationApprovalsById[
                                    application.applicationId
                                  ]?.rentalHistoryApproval
                                }
                                approvalType={
                                  ApplicationsAPIApplicationApprovalTypeEnum.RENTAL_HISTORY
                                }
                                heading={<H4>Rental History</H4>}
                                onSuccess={refetchApprovals}
                              />
                            }
                            isEditable={isEditable}
                            onSuccess={refetchApplicationGroup}
                          />
                        )}
                        {!isMinor &&
                          application.vehicles.map((vehicle, vehicleIndex) => (
                            <VehicleCard
                              header={
                                <ApprovableVehicleCardHeader
                                  approval={
                                    applicationApprovalsById[
                                      application.applicationId
                                    ]?.vehicleApprovals[vehicleIndex]
                                      .approvalStatus
                                  }
                                  heading={
                                    <H4>
                                      {vehicle.make} {vehicle.model}
                                    </H4>
                                  }
                                  onSuccess={refetchApprovals}
                                  vehicleId={vehicle.id}
                                />
                              }
                              isDeletable
                              isWorking={isDeletingVehicle}
                              key={vehicle.id}
                              onDeleteClick={() => {
                                deleteVehicle({ vehicleId: vehicle.id });
                                refetchApplicationGroup();
                              }}
                              vehicle={vehicle}
                            />
                          ))}
                        <Group>
                          <Button
                            onClick={() =>
                              setRightRail({
                                ownerId: application.applicant.userId,
                                type: ApplicationPageRightRailEnum.ADD_VEHICLE,
                              })
                            }
                            variant={ButtonVariant.outlined}>
                            Add Vehicle
                          </Button>
                        </Group>
                        {!isMinor &&
                          application.pets.map((pet, petIndex) => (
                            <ApplicationPetCard
                              header={
                                <ApprovablePetCardHeader
                                  approval={
                                    applicationApprovalsById[
                                      application.applicationId
                                    ]?.petApprovals[petIndex]?.approvalStatus
                                  }
                                  heading={<H4>{pet.name}</H4>}
                                  onSuccess={refetchApprovals}
                                  petId={pet.id}
                                />
                              }
                              isDeletable={isEditable}
                              isEditable={isEditable}
                              key={pet.id}
                              onDeleteSuccess={refetchApplicationGroup}
                              onEditSuccess={refetchApplicationGroup}
                              onUploadClick={() =>
                                setRightRail({
                                  petId: pet.id,
                                  petName: pet.name,
                                  type: ApplicationPageRightRailEnum.UPLOAD_PET_DOCUMENTS,
                                })
                              }
                              pet={pet}
                            />
                          ))}
                        {!isMinor && (
                          <Group>
                            <Button
                              onClick={() =>
                                setRightRail({
                                  ownerId: application.applicant.userId,
                                  type: ApplicationPageRightRailEnum.ADD_PET,
                                })
                              }
                              variant={ButtonVariant.outlined}>
                              Add Pet
                            </Button>
                          </Group>
                        )}
                      </Stack>
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
            <Stack spacing={Spacing.sm}>
              <H4>Income Review</H4>
              {isReview ? (
                <ApplicationGroupIncomeToRentCard
                  applications={applicationGroupResponse.applications}
                  header={
                    <ApprovableIncomeToRentCardHeader
                      applicationGroupId={applicationGroupId}
                      approval={
                        applicationGroupApprovalResponse?.applicationGroupIncomeApproval
                      }
                      heading={<H4>Income to Rent</H4>}
                      onSuccess={refetchApprovals}
                    />
                  }
                  listingId={applicationGroupResponse.unit.activeListingId}
                />
              ) : (
                <Text size={FontSize.sm}>
                  Income can be checked once all applicants have submitted their
                  applications
                </Text>
              )}
            </Stack>
            <Stack spacing={Spacing.sm}>
              <Group justify={Justify.between}>
                <H4>Reports</H4>
                {hasScreeningPermission &&
                  sortedNonMinorApplications.some(
                    (application) => !application.applicationScreened,
                  ) && (
                    <Button
                      loading={isScreeningAll}
                      onClick={handleScreenAllApplications}
                      variant={ButtonVariant.outlined}>
                      Run All Applicants Screenings
                    </Button>
                  )}
              </Group>

              <Stack>
                {sortedNonMinorApplications.map((application) => {
                  const canScreen =
                    hasScreeningPermission &&
                    application.completedApplication &&
                    !application.applicationScreened &&
                    application.identityVerificationResult ===
                      ApplicationIdentityVerificationResultEnum.PASSED;

                  return (
                    <ConnectedApplicantReportCard
                      application={application}
                      applicationGroupId={applicationGroupId}
                      canScreen={canScreen}
                      header={
                        <ApprovableReportCardHeader
                          approval={
                            applicationApprovalsById[application.applicationId]
                          }
                          applicationGroupId={applicationGroupId}
                          applicationId={application.applicationId}
                          disabled={!application.applicationScreened}
                          heading={
                            <H4>
                              {application.applicant.firstName}{" "}
                              {application.applicant.lastName}
                            </H4>
                          }
                          onSuccess={refetchApprovals}
                        />
                      }
                      isLoading={isScreeningAll}
                      key={application.applicationId}
                      onSuccess={refetchApplicationGroup}
                      onViewReport={(report) =>
                        setRightRail({
                          report,
                          type: ApplicationPageRightRailEnum.VIEW_APPLICANT_REPORT,
                        })
                      }
                    />
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
          <Card padding="none" shrink>
            <div className="grid h-full">
              {/* TODO this needs a proper Entity or widget. This file is getting too big */}
              <Tabs value={tab} onChange={setTab}>
                <div
                  className="border-b border-slate-200"
                  style={{ padding: "1.5rem 1rem 0rem" }}>
                  <TabsList>
                    <TabButton value={ApplicationChatTab.messages}>
                      <Group spacing={Spacing.xs}>
                        <IconMessage />
                        Messages
                      </Group>
                    </TabButton>
                    <TabButton value={ApplicationChatTab.notes}>
                      <Group spacing={Spacing.xs}>
                        <IconNote />
                        Notes
                      </Group>
                    </TabButton>
                  </TabsList>
                </div>
                <TabContent value={ApplicationChatTab.messages}>
                  {P.isNotNullable(lease) ? (
                    <LeaseChat lease={lease} />
                  ) : (
                    <ApplicationChat
                      applications={applicationGroupResponse.applications}
                      applicationGroup={
                        applicationGroupResponse.applicationGroup
                      }
                    />
                  )}
                </TabContent>
                <TabContent value={ApplicationChatTab.notes}>
                  <ApplicationPrivateNotes
                    applicationGroupId={applicationGroupId}
                  />
                </TabContent>
              </Tabs>
            </div>
          </Card>
        </div>
      </div>
      <ApplicationPageRightRail
        applicationGroup={applicationGroupResponse.applicationGroup}
        applications={applicationGroupResponse.applications}
        isEditable={isEditable}
        leaseId={applicationGroupResponse.leaseId}
        property={applicationGroupResponse.property}
        refetchApplication={handleRefetch}
        rightRail={rightRail}
        setRightRail={setRightRail}
        unit={applicationGroupResponse.unit}
      />
      <Modal
        onClose={leaseDraftedModalHandlers.setFalse}
        opened={isLeaseDraftedModalOpen}
        title="Lease has been created!">
        <Stack>
          <Text size={FontSize.sm}>
            Go to the lease page to send to applicants
          </Text>
          <Group justify={Justify.between}>
            <Button
              onClick={leaseDraftedModalHandlers.setFalse}
              variant={ButtonVariant.outlined}>
              Return to Application
            </Button>
            <RouterLink href={`/leases/${applicationGroupResponse.leaseId}`}>
              <Button variant={ButtonVariant.filled}>Open Lease Page</Button>
            </RouterLink>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}

export { ApplicationDetailsPage };
