import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ShowerShowerTypeValues = ["TILE_SURROUND", "INSERT"] as const;

const ShowerShowerTypeEffectSchema = Schema.Literal(...ShowerShowerTypeValues);

type ShowerShowerType = Schema.Schema.Type<typeof ShowerShowerTypeEffectSchema>;

const ShowerShowerTypeEnum = castEnum(ShowerShowerTypeEffectSchema);

function randomShowerShowerType(): ShowerShowerType {
  return rand(ShowerShowerTypeValues);
}

export {
  randomShowerShowerType,
  ShowerShowerTypeEffectSchema,
  ShowerShowerTypeEnum,
  ShowerShowerTypeValues,
};
export type { ShowerShowerType };
