import { Schema } from "@effect/schema";
import { IconInfoCircle } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { Option as O, Order, Predicate as P } from "effect";
import { useStore } from "zustand";

import type { KeywordSearch } from "@ender/entities/search-input";
import { FormSearchInput } from "@ender/entities/search-input";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId, Money$ } from "@ender/shared/core";
import {
  EnderIdFormSchema,
  LocalDate$,
  LocalDateEffectSchema,
  MoneyFormSchema,
} from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import type { SelectOption } from "@ender/shared/ds/select";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormSwitch } from "@ender/shared/ds/switch";
import { FontSize, Text } from "@ender/shared/ds/text";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { GeneralLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import { ChargeSchedulesAPI } from "@ender/shared/generated/ender.api.leasing";
import type {
  ChargeSchedule,
  ChargeScheduleFrequency,
} from "@ender/shared/generated/ender.model.payments";
import { ChargeScheduleFrequencyEnum } from "@ender/shared/generated/ender.model.payments";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

import { useCurrentScheduledCharges, useGetCurrentLease } from "../hooks";
import { useTenantLedgerStore } from "../tenant-ledger-store.context";

const CreateScheduledChargeSchema = Schema.Struct({
  amount: MoneyFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<Money$.Money> => O.isSome(input), {
      message: () => "Please enter an amount",
    }),
  ),
  chargeType: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<EnderId> => O.isSome(input), {
      message: () => "Please select a charge type",
    }),
  ),
  frequency: Schema.Enums(ChargeScheduleFrequencyEnum).pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<ChargeScheduleFrequency> => O.isSome(input),
      {
        message: () => "Please select a frequency",
      },
    ),
  ),
  inclusiveEndDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  isCustomEndDate: Schema.Boolean,
  startDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<LocalDate$.LocalDate> => O.isSome(input),
      {
        message: () => "Please select a start date",
      },
    ),
  ),
}).pipe(
  Schema.filter((values) => {
    if (values.isCustomEndDate && O.isNone(values.inclusiveEndDate)) {
      return {
        message: "Please select an end date",
        path: ["inclusiveEndDate"],
      };
    }
    if (
      values.isCustomEndDate &&
      O.isSome(values.inclusiveEndDate) &&
      O.isSome(values.startDate) &&
      values.startDate.value.isAfter(values.inclusiveEndDate.value)
    ) {
      return {
        message: "End Date must be after Start Date",
        path: ["inclusiveEndDate"],
      };
    }
  }),
);

type CreateScheduledChargeValues = Schema.Schema.Type<
  typeof CreateScheduledChargeSchema
>;

function transformFormValues(values: CreateScheduledChargeValues) {
  const {
    amount,
    chargeType,
    frequency,
    startDate,
    isCustomEndDate,
    inclusiveEndDate,
  } = values;
  return {
    amount: amount.pipe(O.getOrThrow).toJSON(),
    chargeTypeId: chargeType.pipe(O.getOrThrow),
    frequency: frequency.pipe(O.getOrThrow),
    inclusiveEndDate: isCustomEndDate
      ? inclusiveEndDate.pipe(O.getOrThrow).toJSON()
      : UNDEFINED,
    startDate: startDate.pipe(O.getOrThrow).toJSON(),
  };
}

function getInitialStartDateValue(
  leaseStartDate: LocalDate$.LocalDate,
): O.Option<LocalDate$.LocalDate> {
  if (Order.greaterThan(LocalDate$.Order)(leaseStartDate, LocalDate$.today())) {
    return LocalDate$.parse(leaseStartDate);
  }

  return LocalDate$.parse(LocalDate$.today());
}

function CreateScheduledCharge() {
  const { lease } = useGetCurrentLease();
  const { refetchScheduledCharges } = useCurrentScheduledCharges();
  const tenantLedgerStore = useTenantLedgerStore();
  const [isSubmitting, setIsSubmitting] = useBoolean(false);

  const { setSelectedScheduledChargeId } = useStore(
    tenantLedgerStore,
    (state) => ({
      setSelectedScheduledChargeId: state.setSelectedScheduledChargeId,
    }),
  );

  const form = useEffectSchemaForm({
    defaultValues: {
      amount: O.none(),
      chargeType: O.none(),
      frequency: O.none(),
      inclusiveEndDate: O.none(),
      isCustomEndDate: false,
      startDate: lease?.startDate
        ? getInitialStartDateValue(LocalDate$.of(lease.startDate))
        : O.none(),
    },
    schema: CreateScheduledChargeSchema,
  });

  const queryClient = useQueryClient();

  const confirmation = useConfirmationContext();
  const createChargeScheduleWithWarnings = withWarningHandler(
    ChargeSchedulesAPI.createChargeSchedule,
    (warnings) =>
      confirmation(
        {
          content: warnings.join("\n"),
          title: "Warning!",
        },
        { confirmButtonProps: { color: Color.red } },
      ),
  );

  const handleSubmit = async (values: CreateScheduledChargeValues) => {
    setIsSubmitting.setTrue();
    try {
      if (P.isNotNullable(lease)) {
        const chargeSchedule = await createChargeScheduleWithWarnings({
          ...transformFormValues(values),
          leaseId: lease.id,
        });
        await queryClient.invalidateQueries([
          "getLeaseChargeSchedules",
          { leaseId: lease?.id },
        ]);
        await queryClient.invalidateQueries(["getTenantLedger"]);
        if (chargeSchedule) {
          showSuccessNotification({
            message: "Scheduled charge successfully created.",
          });
          await refetchScheduledCharges();
          setSelectedScheduledChargeId(
            O.some((chargeSchedule as ChargeSchedule).id),
          );
        }
      }
    } catch (err) {
      fail(err);
    } finally {
      setIsSubmitting.setFalse();
    }
  };

  const searchChargeType: KeywordSearch<EnderId> = async (keyword: string) => {
    const res = await GeneralLedgerAPI.getChargeScheduleCategories({
      keyword,
    });
    return res.map((category) => ({
      label: `${category.name}`,
      meta: category,
      value: category.id,
    }));
  };

  const frequencyOptions = [
    { label: "Monthly", value: ChargeScheduleFrequencyEnum.MONTHLY },
    { label: "Annual", value: ChargeScheduleFrequencyEnum.ANNUALLY },
  ] as SelectOption[];

  const isCustomEndDate = form.watch("isCustomEndDate");
  const startDate = form.watch("startDate");
  const showWarning = O.isSome(startDate)
    ? startDate.pipe(O.getOrThrow).isEqual(LocalDate$.today())
    : false;
  return (
    <div className="create-scheduled-charge">
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Stack>
            <FormSearchInput<EnderId, typeof form>
              label="Charge Type"
              search={searchChargeType}
              modelType={ModelTypeEnum.LEASE}
              form={form}
              name="chargeType"
              placeholder="Select Charge Type"
              searchOnEmpty
              clearable
            />
            <FormMoneyInput
              name="amount"
              label="Amount"
              form={form}
              placeholder="0.00"
            />
            <FormSelect
              name="frequency"
              label="Frequency"
              form={form}
              data={frequencyOptions}
              placeholder="Select Frequency"
            />
            {showWarning && (
              <Text color="red-500" size={FontSize.sm}>
                This charge will post to the tenant ledger shortly as Ender’s
                automated charge creation service is run. This will not be
                instantaneous.
              </Text>
            )}
            <Group align={Align.end}>
              <FormDateInput
                name="startDate"
                label="Start Date"
                form={form}
                placeholder="MM/DD/YYYY"
              />
              <Button
                variant={ButtonVariant.transparent}
                tooltip="You must select a start date in the future. Ender’s rent roll service does not create charges for past dates.">
                <IconInfoCircle />
              </Button>
            </Group>
            <Stack spacing={Spacing.sm}>
              <FormSwitch
                name="isCustomEndDate"
                label="Set Custom End Date"
                form={form}
              />
              <Text size={FontSize.sm}>
                Set custom end date if the scheduled charge will end before move
                out
              </Text>
            </Stack>
            {isCustomEndDate && (
              <FormDateInput
                label="End Date"
                form={form}
                name="inclusiveEndDate"
                placeholder="MM/DD/YYYY"
              />
            )}
          </Stack>
          <Group justify={Justify.end}>
            <Button type="submit" loading={isSubmitting}>
              Create Scheduled Charge
            </Button>
          </Group>
        </Stack>
      </Form>
    </div>
  );
}

export { CreateScheduledCharge };
