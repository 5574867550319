import type { GetInvoicesRequestBodyApprovalStatus } from "@ender/shared/generated/ender.api.accounting.request";
import { GetInvoicesRequestBodyApprovalStatusEnum } from "@ender/shared/generated/ender.api.accounting.request";

import type { InvoiceTableTabs } from "../../invoice-table-tabs.types";
import { InvoiceTableTabsEnum } from "../../invoice-table-tabs.types";

const DEFAULT_APPROVAL_STATUS = [GetInvoicesRequestBodyApprovalStatusEnum.NEW];

const InvoiceTabToApprovalStatusMap: Record<
  InvoiceTableTabs,
  GetInvoicesRequestBodyApprovalStatus[]
> = {
  [InvoiceTableTabsEnum.ALL]: [],
  [InvoiceTableTabsEnum.APPROVED_NOT_PAID]: [
    GetInvoicesRequestBodyApprovalStatusEnum.APPROVED,
  ],
  [InvoiceTableTabsEnum.ASSIGNED_TO_ME]: [
    GetInvoicesRequestBodyApprovalStatusEnum.NEW,
    GetInvoicesRequestBodyApprovalStatusEnum.APPROVED,
  ],
  [InvoiceTableTabsEnum.PAID]: [GetInvoicesRequestBodyApprovalStatusEnum.PAID],
  [InvoiceTableTabsEnum.REJECTED]: [
    GetInvoicesRequestBodyApprovalStatusEnum.REJECTED,
  ],
  [InvoiceTableTabsEnum.UNAPPROVED]: [
    GetInvoicesRequestBodyApprovalStatusEnum.NEW,
  ],
  [InvoiceTableTabsEnum.UNPAID]: [
    GetInvoicesRequestBodyApprovalStatusEnum.NEW,
    GetInvoicesRequestBodyApprovalStatusEnum.APPROVED,
  ],
} as const;

type GetApprovalStatusForPayloadParams = {
  tab: InvoiceTableTabs;
};

function getApprovalStatusForPayload({
  tab,
}: GetApprovalStatusForPayloadParams): GetInvoicesRequestBodyApprovalStatus[] {
  return tab in InvoiceTabToApprovalStatusMap
    ? InvoiceTabToApprovalStatusMap[tab]
    : DEFAULT_APPROVAL_STATUS;
}

export { getApprovalStatusForPayload };
