/**
 * HTTP Method: POST
 * Pathname: /accountingPeriods
 * @function getAccountingPeriods
 * @memberof AccountingAPI
 * @param {AccountingAPIGetAccountingPeriodsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingPeriod[]>}
 */
import { Effect, Function as F } from "effect";

import type {
  AccountingPeriod,
  AccountingPeriodAccountingModule,
} from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIGetAccountingPeriodsSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetAccountingPeriodsBodyParams = {
  module: AccountingPeriodAccountingModule;
};

type AccountingAPIGetAccountingPeriodsPayload =
  AccountingAPIGetAccountingPeriodsSearchParams &
    AccountingAPIGetAccountingPeriodsBodyParams;

function getAccountingPeriodsUnsafeEffect(
  payload: AccountingAPIGetAccountingPeriodsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingPeriod[]>({
        body,
        decode: unsafeDecodeJsonResponse<AccountingPeriod[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accountingPeriods",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAccountingPeriods(
  payload: AccountingAPIGetAccountingPeriodsPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingPeriod[]> {
  return F.pipe(
    payload,
    getAccountingPeriodsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAccountingPeriods };
export type {
  AccountingAPIGetAccountingPeriodsBodyParams,
  AccountingAPIGetAccountingPeriodsPayload,
  AccountingAPIGetAccountingPeriodsSearchParams,
};
