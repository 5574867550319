import type { Path } from "react-hook-form";

import type {
  FormInput,
  MakeFormPropsFromInputProps,
  UseFormReturn,
} from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";

import type { DateInputProps } from "./shared-ds-date-input";
import { DateInput } from "./shared-ds-date-input";

type FormDateInputProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = MakeFormPropsFromInputProps<DateInputProps, Form, Name>;

function FormDateInput<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
>(props: FormDateInputProps<Form, Name>) {
  const { form, name, ...rest } = props;

  return (
    <FormField name={name} form={form}>
      {({ field, error }) => {
        return <DateInput {...rest} {...field} error={error} />;
      }}
    </FormField>
  );
}

export { FormDateInput };

export type { FormDateInputProps };
