import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useContext } from "react";

import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import type { GetBankAccountSensitiveFieldsResponse } from "@ender/shared/generated/ender.model.accounting.response";
import type { Party } from "@ender/shared/generated/ender.model.payments";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";

type BankAccount = {
  id: EnderId;
  mask: string;
  ownerType: Party;
};

const authorizedParties = new Set<Party>([
  PartyEnum.PROPERTY_MANAGER,
  PartyEnum.FIRM,
]);

function useFullBankAccountNumber(bankAccount: BankAccount) {
  const { id, mask = "", ownerType } = bankAccount;
  const { hasPermissions } = useContext(UserContext);

  const canViewFullBankAccountNumber =
    hasPermissions(FunctionalPermissionEnum.VIEW_BANK_ACCOUNT_NUMBERS) &&
    authorizedParties.has(ownerType);

  const {
    data: accountNumber,
    isError,
    error,
  } = useQuery<GetBankAccountSensitiveFieldsResponse, unknown, string>({
    enabled: P.isNotNullable(id) && canViewFullBankAccountNumber,
    queryFn: ({ signal }) =>
      BankingAPI.getBankAccountSensitiveFields(
        { bankAccountId: id },
        { signal },
      ),
    queryKey: ["BankingAPI.getBankAccountSensitiveFields", id] as const,
    select: (data) => data.accountNumber ?? `••••${mask}`,
  });

  if (isError) {
    console.error("Failed to fetch bank account details", error);
  }

  return P.isNotNullable(accountNumber) ? accountNumber : `••••${mask}`;
}

export { useFullBankAccountNumber };
