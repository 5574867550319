import type { VisibilityState } from "@tanstack/react-table";
import { useState } from "react";

import type { EnderTableColumnVisibilityParams } from "../table.types";

type UseTableColumnVisibilityParams = {
  initialVisibility?: VisibilityState;
};
type UseTableColumnVisibilityResult = EnderTableColumnVisibilityParams;

function useTableColumnVisibility(
  params: UseTableColumnVisibilityParams = {},
): UseTableColumnVisibilityResult {
  const { initialVisibility } = params;
  const [columnVisibility, onColumnVisibilityChange] =
    useState<VisibilityState>(() => initialVisibility ?? {});

  return {
    columnVisibility,
    onColumnVisibilityChange,
  };
}

export { useTableColumnVisibility };
export type { UseTableColumnVisibilityParams, UseTableColumnVisibilityResult };
