import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import {
  EnderIdFormSchema,
  LocalDate$,
  LocalDateEffectSchema,
  Money$,
  MoneyFormSchema,
} from "@ender/shared/core";
import type { LeaseSerializerLeaseContact } from "@ender/shared/generated/ender.arch.serializer.leasing";
import { LeaseMoveOutReasonEffectSchema } from "@ender/shared/generated/ender.model.leasing";
import {
  MoneyTransferTransferTypeEffectSchema,
  MoneyTransferTransferTypeEnum,
} from "@ender/shared/generated/ender.model.payments";
import { PlaceEffectSchema } from "@ender/shared/utils/google-maps";
import { RefundRecipientSchema } from "@ender/widgets/finance/tenant-allocations";

/**
 * Escrow Transfer subsection
 *
 * Create a checkNumber schema that takes an ownerType parameter (isFirm)
 * to determine the appropriate max length validation.
 */
const createCheckNumberSchema = (isFirm: boolean) =>
  Schema.String.pipe(
    Schema.filter((val) => /^[0-9a-zA-Z.-]*$/.test(val), {
      message: () => "Invalid Check Number",
    }),
    Schema.filter((val) => (isFirm ? val.length <= 7 : val.length <= 24), {
      message: () =>
        isFirm
          ? "AP check numbers must not exceed 7 characters."
          : "Check numbers must not exceed 24 characters.",
    }),
  );

/**
 * Base schema without the checkNumber field.
 */
const BaseEscrowTransferFormSchema = {
  escrowTransferToAccountId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  memo: Schema.String,
  period: Schema.Struct({
    id: EnderIdFormSchema.pipe(Schema.optional),
    name: Schema.String.pipe(Schema.optional),
  }).pipe(Schema.OptionFromSelf),
  transferDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Please select a date" }),
  ),
  transferType: MoneyTransferTransferTypeEffectSchema.pipe(
    Schema.nonEmptyString({ message: () => "Please select a transfer type" }),
  ),
};

/**
 * Function to create the EscrowTransferFormSchema with appropriate checkNumber validation
 * based on the owner type.
 */
const createEscrowTransferFormSchema = (isFirm: boolean) =>
  Schema.Struct({
    ...BaseEscrowTransferFormSchema,
    checkNumber: createCheckNumberSchema(isFirm),
  });

/**
 * Physical Move-out subsection
 */
const PhysicalMoveOutSectionFormSchema = Schema.Struct({
  actualMoveOutDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Actual Move Out Date is required",
    }),
    Schema.filter(
      (date) =>
        LocalDate$.today().isAfterOrEqual(LocalDate$.of(O.getOrThrow(date))),
      {
        message: () => "Actual move out date cannot be in the future",
      },
    ),
  ),
  expectedMoveOutDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Expected Move Out Date is required",
    }),
  ),
  moveOutReason: LeaseMoveOutReasonEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Move Out Reason is required" }),
  ),
});

/**
 * Tenant Information subsection
 */
const TenantInformationSectionFormSchema = Schema.Struct({
  forwardingAddress: PlaceEffectSchema.pipe(Schema.OptionFromSelf),
  primaryTenantId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Primary tenant is required" }),
  ),
  unit: Schema.String,
});

/**
 * Function to generate escrow transfer form values.
 */
function generateEscrowTransferFormValues() {
  return {
    checkNumber: "",
    escrowTransferToAccountId: O.none(),
    memo: "Internal Transfer for Move Out",
    period: O.none(),
    transferDate: O.some(LocalDate$.today()),
    transferType: MoneyTransferTransferTypeEnum.BANK_TRANSFER,
  };
}

type EscrowTransferFormInput = Schema.Schema.Encoded<
  ReturnType<typeof createEscrowTransferFormSchema>
>;
type EscrowTransferFormOutput = Schema.Schema.Type<
  ReturnType<typeof createEscrowTransferFormSchema>
>;

/**
 * Function to generate physical move out initial values.
 */
type PhysicalMoveOutSectionFormInput = Schema.Schema.Encoded<
  typeof PhysicalMoveOutSectionFormSchema
>;
type PhysicalMoveOutSectionFormOutput = Schema.Schema.Type<
  typeof PhysicalMoveOutSectionFormSchema
>;
function generatePhysicalMoveOutInitialValues(
  props: Partial<PhysicalMoveOutSectionFormInput>,
): PhysicalMoveOutSectionFormInput {
  const { expectedMoveOutDate, actualMoveOutDate } = props;
  return {
    actualMoveOutDate: actualMoveOutDate ?? O.none(),
    expectedMoveOutDate: expectedMoveOutDate ?? O.none(),
    moveOutReason: O.none(),
  };
}

/**
 * Function to generate tenant information initial values.
 */
type TenantInformationSectionFormInput = Schema.Schema.Encoded<
  typeof TenantInformationSectionFormSchema
>;
type TenantInformationSectionFormOutput = Schema.Schema.Type<
  typeof TenantInformationSectionFormSchema
>;
function generateTenantInformationInitialValues(
  props: Partial<TenantInformationSectionFormInput>,
): TenantInformationSectionFormInput {
  const { primaryTenantId } = props;
  return {
    forwardingAddress: O.none(),
    primaryTenantId: primaryTenantId ?? O.none(),
    unit: "",
  };
}

/**
 * Factory function to create the entire Move-out form schema dynamically using isFirm.
 */
const createMoveOutFormSchema = (isFirm: boolean) =>
  Schema.Struct({
    allTenantsAmount: MoneyFormSchema,
    balanceAmount: MoneyFormSchema,
    escrowTransferAmount: MoneyFormSchema,
    isForAllTenants: Schema.Boolean,
    notificationLetter: Schema.String.pipe(
      Schema.nonEmptyString({
        message: () => "Must upload a notification letter",
      }),
    ),
    refundRecipients: Schema.Array(RefundRecipientSchema),
    // Inject dynamic schema for escrowTransfer based on isFirm:
    escrowTransfer: createEscrowTransferFormSchema(isFirm),
    physicalMoveOut: PhysicalMoveOutSectionFormSchema,
    tenantInformation: TenantInformationSectionFormSchema,
  });

type MoveOutFormInput = Schema.Schema.Encoded<
  ReturnType<typeof createMoveOutFormSchema>
>;
type MoveOutFormOutput = Schema.Schema.Type<
  ReturnType<typeof createMoveOutFormSchema>
>;

/**
 * Generate the aggregate Move-out form initial values.
 */
function generateMoveOutInitialValues(
  props: Partial<MoveOutFormInput> & {
    primaryTenant: LeaseSerializerLeaseContact;
    financiallyResponsibleTenants: LeaseSerializerLeaseContact[];
  },
): MoveOutFormInput {
  const {
    physicalMoveOut,
    escrowTransferAmount = Money$.zero(),
    financiallyResponsibleTenants,
    primaryTenant,
    tenantInformation,
    balanceAmount = Money$.zero(),
  } = props;

  return {
    allTenantsAmount: escrowTransferAmount,
    balanceAmount,
    escrowTransfer: generateEscrowTransferFormValues(),
    escrowTransferAmount,
    isForAllTenants: true,
    notificationLetter: "",
    physicalMoveOut: generatePhysicalMoveOutInitialValues({
      ...physicalMoveOut,
    }),
    refundRecipients: [
      {
        amount: O.fromNullable(Money$.zero()),
        id: O.fromNullable(primaryTenant?.id),
        name: primaryTenant?.name,
      },
      ...financiallyResponsibleTenants
        .filter((tenant) => tenant.id !== primaryTenant?.id)
        .map((tenant) => ({
          amount: O.fromNullable(Money$.zero()),
          id: O.fromNullable(tenant.id),
          name: tenant.name,
        })),
    ],
    tenantInformation: generateTenantInformationInitialValues({
      ...tenantInformation,
      primaryTenantId: O.fromNullable(primaryTenant?.id),
    }),
  };
}

export { generateMoveOutInitialValues, createMoveOutFormSchema };
export type {
  EscrowTransferFormInput,
  EscrowTransferFormOutput,
  MoveOutFormInput,
  MoveOutFormOutput,
  PhysicalMoveOutSectionFormInput,
  PhysicalMoveOutSectionFormOutput,
  TenantInformationSectionFormInput,
  TenantInformationSectionFormOutput,
};
