/**
 * HTTP Method: POST
 * Pathname: /pets
 * @function addPet
 * @memberof PetsAPI
 * @param {PetsAPIAddPetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Pet>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIAddPetSearchParams = {
  token?: string | undefined;
};

type PetsAPIAddPetBodyParams = {
  age: number;
  breed: string;
  isEmotionalSupportAnimal?: boolean | undefined;
  isNeutered: boolean;
  leaseId: EnderId;
  name: string;
  species: string;
  weightInPounds: number;
};

type PetsAPIAddPetPayload = PetsAPIAddPetSearchParams & PetsAPIAddPetBodyParams;

function addPetUnsafeEffect(payload: PetsAPIAddPetPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Pet>({
        body,
        decode: unsafeDecodeJsonResponse<Pet>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/pets",
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function addPet(
  payload: PetsAPIAddPetPayload,
  options?: { signal?: AbortSignal },
): Promise<Pet> {
  return F.pipe(
    payload,
    addPetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addPet };
export type {
  PetsAPIAddPetBodyParams,
  PetsAPIAddPetPayload,
  PetsAPIAddPetSearchParams,
};
