import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LobClientMailTypeValues = ["USPS_STANDARD", "USPS_FIRST_CLASS"] as const;

const LobClientMailTypeEffectSchema = Schema.Literal(
  ...LobClientMailTypeValues,
);

type LobClientMailType = Schema.Schema.Type<
  typeof LobClientMailTypeEffectSchema
>;

const LobClientMailTypeEnum = castEnum(LobClientMailTypeEffectSchema);

function randomLobClientMailType(): LobClientMailType {
  return rand(LobClientMailTypeValues);
}

export {
  LobClientMailTypeEffectSchema,
  LobClientMailTypeEnum,
  LobClientMailTypeValues,
  randomLobClientMailType,
};
export type { LobClientMailType };
