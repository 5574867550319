import { Array as A, Function as F } from "effect";

import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import { capitalize, pluralize } from "@ender/shared/utils/string";

type MissingApprovalProcessEditPermissionsProps = {
  missingPermissions: Set<FunctionalPermission>;
};
function MissingApprovalProcessEditPermissions({
  missingPermissions: missingEditPermissions,
}: MissingApprovalProcessEditPermissionsProps) {
  return (
    <p>
      <span>
        To edit approval chains you are missing the following{" "}
        {pluralize("permission", missingEditPermissions.size)}{" "}
        {F.pipe(
          missingEditPermissions,
          A.fromIterable,
          A.flatMap((p) => [
            <strong key={p}>{capitalize(p)}</strong>,
            <span key={`${p}-join`}> and </span>,
          ]),
          A.dropRight(1),
        )}
        {". "}
      </span>
      <span>Please reach out to your system administrator.</span>
    </p>
  );
}

export { MissingApprovalProcessEditPermissions };
