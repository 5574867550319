/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/actualMoveOut
 * @function updateLeaseActualMoveOutDate
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdateLeaseActualMoveOutDatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdateLeaseActualMoveOutDatePathParams = {
  leaseId: EnderId;
};

type LeasingAPIUpdateLeaseActualMoveOutDateSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdateLeaseActualMoveOutDateBodyParams = {
  actualMoveOutDate: SerializesInto<LocalDate>;
  preflightValidationId: EnderId;
};

type LeasingAPIUpdateLeaseActualMoveOutDatePayload =
  LeasingAPIUpdateLeaseActualMoveOutDatePathParams &
    LeasingAPIUpdateLeaseActualMoveOutDateSearchParams &
    LeasingAPIUpdateLeaseActualMoveOutDateBodyParams;

function updateLeaseActualMoveOutDateUnsafeEffect(
  payload: LeasingAPIUpdateLeaseActualMoveOutDatePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/actualMoveOut`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateLeaseActualMoveOutDate(
  payload: LeasingAPIUpdateLeaseActualMoveOutDatePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateLeaseActualMoveOutDateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateLeaseActualMoveOutDate };
export type {
  LeasingAPIUpdateLeaseActualMoveOutDateBodyParams,
  LeasingAPIUpdateLeaseActualMoveOutDatePathParams,
  LeasingAPIUpdateLeaseActualMoveOutDatePayload,
  LeasingAPIUpdateLeaseActualMoveOutDateSearchParams,
};
