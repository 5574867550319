/**
 * HTTP Method: POST
 * Pathname: /journal-entries/batchReject
 * @function batchReject
 * @memberof AccountingAPI
 * @param {AccountingAPIBatchRejectPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchedJournalEntryActionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchedJournalEntryActionResponse } from "@ender/shared/generated/ender.model.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIBatchRejectSearchParams = {
  token?: string | undefined;
};

type AccountingAPIBatchRejectBodyParams = {
  comment?: string | undefined;
  completelyReject: boolean;
  journalEntryIds: EnderId[];
  overrideWarnings?: boolean | undefined;
};

type AccountingAPIBatchRejectPayload = AccountingAPIBatchRejectSearchParams &
  AccountingAPIBatchRejectBodyParams;

function batchRejectUnsafeEffect(payload: AccountingAPIBatchRejectPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchedJournalEntryActionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchedJournalEntryActionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/journal-entries/batchReject",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function batchReject(
  payload: AccountingAPIBatchRejectPayload,
  options?: { signal?: AbortSignal },
): Promise<BatchedJournalEntryActionResponse> {
  return F.pipe(
    payload,
    batchRejectUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { batchReject };
export type {
  AccountingAPIBatchRejectBodyParams,
  AccountingAPIBatchRejectPayload,
  AccountingAPIBatchRejectSearchParams,
};
