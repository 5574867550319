/**
 * HTTP Method: GET
 * Pathname: /bankAccounts/summaries
 * @function getBankSummariesForPropertyManager
 * @memberof BankingAPI
 * @param {BankingAPIGetBankSummariesForPropertyManagerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetBankSummaryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetBankSummaryResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetBankSummariesForPropertyManagerSearchParams = {
  token?: string | undefined;
};

type BankingAPIGetBankSummariesForPropertyManagerPayload =
  BankingAPIGetBankSummariesForPropertyManagerSearchParams;

function getBankSummariesForPropertyManagerUnsafeEffect(
  payload: BankingAPIGetBankSummariesForPropertyManagerPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetBankSummaryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetBankSummaryResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/bankAccounts/summaries",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBankSummariesForPropertyManager(
  payload: BankingAPIGetBankSummariesForPropertyManagerPayload,
  options?: { signal?: AbortSignal },
): Promise<GetBankSummaryResponse[]> {
  return F.pipe(
    payload,
    getBankSummariesForPropertyManagerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankSummariesForPropertyManager };
export type {
  BankingAPIGetBankSummariesForPropertyManagerPayload,
  BankingAPIGetBankSummariesForPropertyManagerSearchParams,
};
