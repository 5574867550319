// Be very careful in this file when adding Routes
// 1. There is no inheritance/extends for Routes even when used in more than one Role.
// 2. Roles must have an explicit set of Routes and must contain all the Routes needed for the Role.
// 3. The isPM block contains isEnder conditions because Ender users are (and must be) PMs.
// 4. Always verify the permissions for a given Route with BE and Product.
// TODO isEnder should probably be a proper permission called IS_ENDER
// TODO We may want to exclude (routes) instead of checking `requiredPermissions` if a given route's existence is somehow revealing:
//      Ex. /some-sensitive-third-party-relationship-page
/**
 * @description
 * The implementation of EnderMantineProvider within the UserProvider is to provide access to the user data
 * for the purpose of determining the user's role with respect to the Tenant Portal.
 */
import { Option as O, Predicate as P } from "effect";
import { useContext, useMemo } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { AuthTagEnum, useAuthActor } from "@ender/features/auth";
import { MLSPublicPage } from "@ender/pages/acquisitions";
import { ApplyPage } from "@ender/pages/leasing";
import { UserContext, UserProvider } from "@ender/shared/contexts/user";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { environmentStore } from "@ender/shared/stores/environment-store";
import { lazyImport } from "@ender/shared/utils/lazy-import";

import { AppShell } from "../../app-shell/ender-app-shell";

function navigateToAuthApp(path: string) {
  // For relative navigation we need to
  //  - Ensure `path` does not start with a "/"
  //  - Ensure `base` ends in a "/"
  const relativePath = path.replace(/^\//, "");
  const base = `${environmentStore.getState().authAppUrl}/`.replace(
    /\/\/$/,
    "/",
  );
  const redirectUrl = new URL(relativePath, base);

  globalThis.location.assign(redirectUrl);
}

const TenantPortalRoutes = lazyImport(
  () => import("../tenant-portal/tenant-portal.routes"),
  "TenantPortalRoutes",
);
const VendorPortalRoutes = lazyImport(
  () => import("../maintenance/vendor-portal.routes"),
  "VendorPortalRoutes",
);
const BrokerPortalRoutes = lazyImport(
  () => import("../finance/broker-portal.routes"),
  "BrokerPortalRoutes",
);
const OwnerPortalRoutes = lazyImport(
  () => import("../maintenance/owner-portal.routes"),
  "OwnerPortalRoutes",
);
const PMRoutes = lazyImport(() => import("./pm/pm.routes"), "PMRoutes");

function AuthRoutes() {
  const { user } = useContext(UserContext);

  if (user.isTenant) {
    return <TenantPortalRoutes />;
  }

  if (user.isVendor) {
    return <VendorPortalRoutes />;
  }

  if (user.isBroker) {
    return <BrokerPortalRoutes />;
  }

  if (user.isInvestor) {
    return <OwnerPortalRoutes />;
  }

  if (user.isPM) {
    return <PMRoutes />;
  }

  // this should never happen
  return <div>Unknown userType</div>;
}

function EnsureTermsAccepted() {
  const [authSnapshot] = useAuthActor();
  const {
    context: { session },
  } = authSnapshot;

  const { hasUser, isTermsAccepted } = useMemo(() => {
    return {
      hasUser: O.match(session, {
        onNone: () => false,
        onSome: (s) => P.isNotNullable(s.user),
      }),
      isTermsAccepted: O.match(session, {
        onNone: () => false,
        onSome: (s) => s.user.isTermsAccepted,
      }),
    };
  }, [session]);

  if (hasUser && !isTermsAccepted) {
    // Redirect to welcome if the User has yet to accept terms
    navigateToAuthApp(
      `/welcome?redirectUrl=${encodeURIComponent(location.pathname + location.search)}`,
    );
    return <LoadingSpinner />;
  }

  return (
    <AppShell>
      <AuthRoutes />
    </AppShell>
  );
}

function EnsureAuth() {
  const [authSnapshot] = useAuthActor();
  const location = useLocation();

  if (!authSnapshot.hasTag(AuthTagEnum.AUTHENTICATED)) {
    // Redirect the user to the login page & show loading spinner till redirect happens
    navigateToAuthApp(
      `/login?redirectUrl=${encodeURIComponent(location.pathname + location.search)}`,
    );
    return <LoadingSpinner />;
  }

  return (
    <UserProvider>
      <Switch>
        <Route
          path="/welcome"
          render={() => {
            // Redirect to welcome if the User has yet to accept terms
            navigateToAuthApp(
              `/welcome?redirectUrl=${encodeURIComponent(location.pathname + location.search)}`,
            );
            return <LoadingSpinner />;
          }}
        />

        <Route>
          <EnsureTermsAccepted />
        </Route>
      </Switch>
    </UserProvider>
  );
}

function EnderRoutes() {
  return (
    <Switch>
      <Route path="/mlsHomes">
        <MLSPublicPage />
      </Route>

      <Route path="/units/:unitId/apply">
        <ApplyPage />
      </Route>

      <Route>
        <EnsureAuth />
      </Route>
    </Switch>
  );
}

export { EnderRoutes };
