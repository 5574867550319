import { Predicate as P } from "effect";
import * as A from "effect/Array";

import type { EnderId } from "@ender/shared/core";
import type { CheckboxProps } from "@ender/shared/ds/checkbox";
import { Checkbox } from "@ender/shared/ds/checkbox";
import type { DateInputProps } from "@ender/shared/ds/date-input";
import { DateInput } from "@ender/shared/ds/date-input";
import { Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";

import type { SelectBankAccountProps } from "./select-bank-account";
import { SelectBankAccount } from "./select-bank-account";

type InstantlyMarkPaidInputProps = {
  canInstantApprove: boolean;
  formInputPropsBankAccountId: Omit<
    SelectBankAccountProps,
    "propertyIds" | "invoiceType"
  >;
  formInputPropsPaymentDate: DateInputProps;
  instantApproveCheckboxValue: boolean;
  invoiceType: InvoiceInvoiceType;
  label: string;
  onInstantApproveChange: CheckboxProps["onChange"];
  selectedPropertyIds: EnderId[];
  showBankAccountSelect: boolean;
};

function InstantlyMarkPaidInput({
  canInstantApprove,
  formInputPropsBankAccountId,
  formInputPropsPaymentDate,
  instantApproveCheckboxValue,
  invoiceType,
  label,
  onInstantApproveChange,
  selectedPropertyIds,
  showBankAccountSelect,
}: InstantlyMarkPaidInputProps) {
  return (
    <Stack>
      <Tooltip
        disabled={canInstantApprove}
        label={
          A.isNonEmptyArray(selectedPropertyIds)
            ? "Not eligible for instant approval"
            : "Must select property first"
        }>
        <span>
          <Checkbox
            disabled={!canInstantApprove}
            label={label}
            name="instantApprove"
            onChange={onInstantApproveChange}
            value={instantApproveCheckboxValue}
          />
        </span>
      </Tooltip>
      {canInstantApprove && instantApproveCheckboxValue && (
        <Stack spacing={Spacing.none}>
          {showBankAccountSelect &&
            P.isNotNullable(formInputPropsBankAccountId) && (
              <SelectBankAccount
                propertyIds={selectedPropertyIds}
                invoiceType={invoiceType}
                {...formInputPropsBankAccountId}
              />
            )}
          <Tuple
            label="Payment Date"
            value={<DateInput {...formInputPropsPaymentDate} />}
          />
        </Stack>
      )}
    </Stack>
  );
}

export { InstantlyMarkPaidInput };
