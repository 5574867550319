/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/people
 * @function addPersonToApplication
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIAddPersonToApplicationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type {
  ApplicationApplicantType,
  ApplicationRelationshipType,
} from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIAddPersonToApplicationPathParams = {
  applicationGroupId: EnderId;
};

type ApplicationsAPIAddPersonToApplicationSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIAddPersonToApplicationBodyParams = {
  applicantType: ApplicationApplicantType;
  birthday?: SerializesInto<LocalDate> | undefined;
  email?: string | undefined;
  firstName: string;
  lastName: string;
  middleName?: string | undefined;
  phone?: string | undefined;
  relationship?: ApplicationRelationshipType | undefined;
};

type ApplicationsAPIAddPersonToApplicationPayload =
  ApplicationsAPIAddPersonToApplicationPathParams &
    ApplicationsAPIAddPersonToApplicationSearchParams &
    ApplicationsAPIAddPersonToApplicationBodyParams;

function addPersonToApplicationUnsafeEffect(
  payload: ApplicationsAPIAddPersonToApplicationPayload,
) {
  const { applicationGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/people`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addPersonToApplication(
  payload: ApplicationsAPIAddPersonToApplicationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addPersonToApplicationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addPersonToApplication };
export type {
  ApplicationsAPIAddPersonToApplicationBodyParams,
  ApplicationsAPIAddPersonToApplicationPathParams,
  ApplicationsAPIAddPersonToApplicationPayload,
  ApplicationsAPIAddPersonToApplicationSearchParams,
};
