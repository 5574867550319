import type { NotificationProps } from "@mantine/notifications";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";

function showSuccessNotification({
  title = "Success",
  ...props
}: NotificationProps) {
  return showNotification({
    color: "green",
    icon: <IconCheck color={EnderThemeColorEnum.WHITE} />,
    title,
    ...props,
  });
}

export { showSuccessNotification };
