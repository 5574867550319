import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

// This is the Reconciliation Status Value which would match BE values
const ReconciliationStatusValueValues = [
  "MATCHED",
  "MATCHED_LATER_ENDER",
  "MATCHED_LATER_BANK",
  "ENDER_TRANSACTION",
  "PENDING_DEPOSIT",
  "BANK_TRANSACTION",
  "AWAITING_INVOICE",
  "EXCLUDED_BANK_TRANSACTION",
  "EXCLUDED_ENDER_TRANSACTION",
] as const;
const ReconciliationStatusValueSchema = z.enum(ReconciliationStatusValueValues);
type ReconciliationStatusValue = z.infer<
  typeof ReconciliationStatusValueSchema
>;

const ReconciliationStatusValueEnum = castEnum<ReconciliationStatusValue>(
  ReconciliationStatusValueSchema,
);

const ReconciliationStatusValues = [
  "Matched",
  "Manual",
  "Matched Later - Bank",
  "Matched Later - Ender",
  "Ender Txn",
  "Pending Deposit",
  "Bank Txn",
  "Pending Invoice",
  "Bank Excluded",
  "Ender Excluded",
] as const;
const ReconciliationStatusSchema = z.enum(ReconciliationStatusValues);
type ReconciliationStatus = z.infer<typeof ReconciliationStatusSchema>;

const ReconciliationStatusEnum = castEnum<ReconciliationStatus>(
  ReconciliationStatusSchema,
);

const ReconciliationStatusLabelEnum = {
  [ReconciliationStatusValueEnum.MATCHED]: "Matched",
  [ReconciliationStatusValueEnum.MATCHED_LATER_ENDER]: "Matched Later",
  [ReconciliationStatusValueEnum.MATCHED_LATER_BANK]: "Matched Later",
  [ReconciliationStatusValueEnum.ENDER_TRANSACTION]: "Ender Transaction",
  [ReconciliationStatusValueEnum.PENDING_DEPOSIT]: "Pending Deposit",
  [ReconciliationStatusValueEnum.AWAITING_INVOICE]: "Awaiting Invoice",
  [ReconciliationStatusValueEnum.EXCLUDED_BANK_TRANSACTION]:
    "Excluded Bank Transaction",
  [ReconciliationStatusValueEnum.EXCLUDED_ENDER_TRANSACTION]:
    "Excluded Ender Transaction",
} as const;

const ReconciliationStatusValuesLabelsEnum: Record<
  ReconciliationStatus,
  string
> = {
  "Bank Excluded": "Bank Excluded",
  "Bank Txn": "Bank Txn",
  "Ender Excluded": "Ender Excluded",
  "Ender Txn": " Ender Txn",
  Matched: "Matched",
  "Matched Later - Bank": "Matched Later",
  "Matched Later - Ender": "Matched Later",
  Manual: "Manual",
  "Pending Deposit": "Pending Deposit",
  "Pending Invoice": "Pending Invoice",
} as const;

export {
  ReconciliationStatusEnum,
  ReconciliationStatusLabelEnum,
  ReconciliationStatusSchema,
  ReconciliationStatusValueEnum,
  ReconciliationStatusValues,
  ReconciliationStatusValuesLabelsEnum,
};
export type { ReconciliationStatus, ReconciliationStatusValue };
