import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InvoiceInvoiceTypeValues = ["PAYABLE", "RECEIVABLE", "OTHER"] as const;

const InvoiceInvoiceTypeEffectSchema = Schema.Literal(
  ...InvoiceInvoiceTypeValues,
);

type InvoiceInvoiceType = Schema.Schema.Type<
  typeof InvoiceInvoiceTypeEffectSchema
>;

const InvoiceInvoiceTypeEnum = castEnum(InvoiceInvoiceTypeEffectSchema);

function randomInvoiceInvoiceType(): InvoiceInvoiceType {
  return rand(InvoiceInvoiceTypeValues);
}

export {
  InvoiceInvoiceTypeEffectSchema,
  InvoiceInvoiceTypeEnum,
  InvoiceInvoiceTypeValues,
  randomInvoiceInvoiceType,
};
export type { InvoiceInvoiceType };
