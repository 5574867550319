import type { z } from "zod";

import { InputInfoPropsSchema } from "@ender/shared/forms/types/general";
import { ContextAwareInput } from "@ender/shared/forms/ui/context-aware-input";
import {
  NumberInputBasePropsSchema as EnderInputNumberBasePropsSchema_,
  NumberInput as EnterInputNumber_,
} from "@ender/shared/ui/number-input";

/** @deprecated This should not be used anymore. */
const EnderInputNumberPropsSchema =
  EnderInputNumberBasePropsSchema_.merge(InputInfoPropsSchema);

/** @deprecated This should not be used anymore. */
type EnderInputNumberProps = z.infer<typeof EnderInputNumberPropsSchema>;

/** @deprecated This should not be used anymore. */
function EnderInputNumber(props: EnderInputNumberProps & { name: string }) {
  const { name, ...rest } = props;
  return (
    <ContextAwareInput Component={EnterInputNumber_} name={name} props={rest} />
  );
}

export { EnderInputNumber, EnderInputNumberPropsSchema };
export type { EnderInputNumberProps };
