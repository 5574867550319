import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import * as A from "effect/Array";
import type { ReactElement } from "react";
import { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { MultiFactorVerificationModal } from "@ender/features/auth";
import { Form, useForm } from "@ender/form-system/base";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import { fail } from "@ender/shared/utils/error";
import { withWarningHandler } from "@ender/shared/utils/rest";

const AccountingPeriodSchema = Schema.Struct({
  id: EnderIdFormSchema,
});

const PayByACHFormSchema = Schema.Struct({
  accountingPeriod: Schema.OptionFromSelf(AccountingPeriodSchema),
  bankAccountId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((input): input is O.Option<EnderId> => O.isSome(input), {
      message: () => "Please choose an account",
    }),
  ),
});

type PayByACHFormValues = Schema.Schema.Type<typeof PayByACHFormSchema>;

function useApproveAndPayInvoice({
  invoiceId,
  onSuccess,
}: {
  invoiceId: EnderId;
  onSuccess: () => void;
}) {
  const confirmation = useConfirmationContext();
  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };
  const approveAndPayInvoiceWithWarnings = withWarningHandler(
    InvoicesAPI.approveAndPayInvoice,
    onWarnings,
  );
  const { mutateAsync: approveAndPayInvoice, isLoading } = useMutation({
    mutationFn: async (values: PayByACHFormValues) => {
      const periodId = pipe(
        values.accountingPeriod,
        O.map((accountingPeriod) => accountingPeriod.id),
        O.getOrUndefined,
      );
      return await approveAndPayInvoiceWithWarnings({
        invoiceId,
        ...(P.isNotNullable(periodId) && { periodId }),
        transferDetails: {
          invoiceIds: [invoiceId],
          sourceAccount: O.getOrUndefined(values.bankAccountId),
          transferType: MoneyTransferTransferTypeEnum.DWOLLA_TRANSFER,
        },
      });
    },
    mutationKey: ["approveAndPayInvoiceWithWarnings"] as const,
    onError: (error) => {
      fail(error);
    },
    onSuccess,
  });
  return { approveAndPayInvoice, isLoading };
}

type PayByACHFormProps = {
  bankAccounts: BankAccount[];
  invoice: InvoiceSerializerInvoiceResponse;
  onSuccess?: () => void;
  operatingAccountId?: EnderId;
};

function PayByACHForm({
  bankAccounts,
  invoice,
  onSuccess = F.constVoid,
  operatingAccountId,
}: PayByACHFormProps): ReactElement {
  const { amount, transactionDate } = invoice;
  const [values, setValues] = useState<O.Option<PayByACHFormValues>>(O.none());
  const initialBankAccount = A.isNonEmptyArray(bankAccounts)
    ? bankAccounts[0]
    : UNDEFINED;
  const bankAccountSelectOptions = useMemo(
    () => bankAccounts.map(({ id: value, name: label }) => ({ label, value })),
    [bankAccounts],
  );
  const form = useForm<PayByACHFormValues>({
    defaultValues: {
      accountingPeriod: O.none(),
      bankAccountId:
        O.fromNullable(operatingAccountId) ||
        O.fromNullable(initialBankAccount?.id),
    },
    resolver: effectTsResolver(PayByACHFormSchema),
  });

  const bankAccountId = useWatch({
    control: form.control,
    name: "bankAccountId",
  });
  const selectedBankAccount = useMemo(() => {
    return bankAccounts.find(
      (account) => account.id === O.getOrUndefined(bankAccountId),
    );
  }, [bankAccountId, bankAccounts]);
  const [submitButtonText, setSubmitButtonText] = useState("Pay ACH");

  // lazy useEffect
  useEffect(() => {
    if (invoice) {
      setSubmitButtonText(`Confirm ACH Transfer of ${amount}`);
    }
  }, [amount, invoice]);

  const { approveAndPayInvoice, isLoading: isProcessing } =
    useApproveAndPayInvoice({
      invoiceId: invoice.id,
      onSuccess,
    });

  function handleSubmit(values: PayByACHFormValues) {
    setValues(O.some(values));
  }

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          {A.isNonEmptyArray(bankAccounts) && (
            <FormSelect
              label="Bank Account"
              name="bankAccountId"
              data={bankAccountSelectOptions}
              form={form}
            />
          )}
          <Stack spacing={Spacing.xs}>
            <Tuple
              label="Account Number"
              value={`••••${selectedBankAccount?.mask}`}
            />
            <Tuple
              label="Account Institution"
              value={selectedBankAccount?.institution}
            />
            <Tuple
              label="Transaction Date"
              value={<DateDisplay value={LocalDate$.of(transactionDate)} />}
            />
            <Tuple label="Amount" value={amount} />
          </Stack>
          <FormAccountingPeriodSelector
            form={form}
            label="Accounting Period (optional)"
            name="accountingPeriod"
            periodType={AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE}
          />
          <Group justify={Justify.end}>
            <Button type="submit" loading={isProcessing}>
              {submitButtonText}
            </Button>
          </Group>
        </Stack>
      </Form>

      {O.match(values, {
        onNone: () => NULL,
        onSome: (values: PayByACHFormValues) => (
          <MultiFactorVerificationModal
            onClose={() => setValues(O.none())}
            onDone={async () => {
              try {
                await approveAndPayInvoice(values);
              } catch (error) {
                fail(error);
              } finally {
                setValues(O.none());
              }
            }}
          />
        ),
      })}
    </>
  );
}

export { PayByACHForm };
export type { PayByACHFormProps };
