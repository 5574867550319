/**
 * HTTP Method: GET
 * Pathname: /applications/{applicationId}/applicationApproval
 * @function getSingleApplicationApproval
 * @memberof ApplicationsMiddleLayerAPI
 * @param {ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ApplicationGroupApprovalResponseApplicationApprovalResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApplicationGroupApprovalResponseApplicationApprovalResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPathParams = {
  applicationId: EnderId;
};

type ApplicationsMiddleLayerAPIGetSingleApplicationApprovalSearchParams = {
  token?: string | undefined;
};

type ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPayload =
  ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPathParams &
    ApplicationsMiddleLayerAPIGetSingleApplicationApprovalSearchParams;

function getSingleApplicationApprovalUnsafeEffect(
  payload: ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPayload,
) {
  const { applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<
        typeof body,
        ApplicationGroupApprovalResponseApplicationApprovalResponse
      >({
        body,
        decode:
          unsafeDecodeJsonResponse<ApplicationGroupApprovalResponseApplicationApprovalResponse>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/applications/${applicationId}/applicationApproval`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSingleApplicationApproval(
  payload: ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPayload,
  options?: { signal?: AbortSignal },
): Promise<ApplicationGroupApprovalResponseApplicationApprovalResponse> {
  return F.pipe(
    payload,
    getSingleApplicationApprovalUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSingleApplicationApproval };
export type {
  ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPathParams,
  ApplicationsMiddleLayerAPIGetSingleApplicationApprovalPayload,
  ApplicationsMiddleLayerAPIGetSingleApplicationApprovalSearchParams,
};
