/**
 * HTTP Method: GET
 * Pathname: /invoices/{invoiceId}
 * @function getInvoice
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InvoiceSerializerInvoiceResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoicePathParams = {
  invoiceId: EnderId;
};

type InvoicesAPIGetInvoiceSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetInvoicePayload = InvoicesAPIGetInvoicePathParams &
  InvoicesAPIGetInvoiceSearchParams;

function getInvoiceUnsafeEffect(payload: InvoicesAPIGetInvoicePayload) {
  const { invoiceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InvoiceSerializerInvoiceResponse>({
        body,
        decode: unsafeDecodeJsonResponse<InvoiceSerializerInvoiceResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/invoices/${invoiceId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoice(
  payload: InvoicesAPIGetInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<InvoiceSerializerInvoiceResponse> {
  return F.pipe(
    payload,
    getInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoice };
export type {
  InvoicesAPIGetInvoicePathParams,
  InvoicesAPIGetInvoicePayload,
  InvoicesAPIGetInvoiceSearchParams,
};
