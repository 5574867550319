import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { Option as O, pipe } from "effect";
import { useCallback } from "react";
import { z } from "zod";

import { Form, useForm } from "@ender/form-system/base";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { FormRadioGroup } from "@ender/shared/ds/radio-group";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Color } from "@ender/shared/utils/theming";
import { castEnum } from "@ender/shared/utils/zod";

const RejectTransactionTypeEnum = {
  REJECT_COMPLETELY: "REJECT_COMPLETELY",
  REJECT_TO_PREVIOUS_STEP: "REJECT_TO_PREVIOUS_STEP",
} as const;
const RejectTransactionTypeValues = [
  RejectTransactionTypeEnum.REJECT_COMPLETELY,
  RejectTransactionTypeEnum.REJECT_TO_PREVIOUS_STEP,
] as const;
const RejectTransactionTypeSchema = z.enum(RejectTransactionTypeValues);
type RejectTransactionType = z.infer<typeof RejectTransactionTypeSchema>;
const RejectTransactionEnum = castEnum<RejectTransactionType>(
  RejectTransactionTypeSchema,
);

const JournalEntryRejectFormSchema = Schema.Struct({
  description: Schema.String.pipe(
    Schema.nonEmptyString({
      message: () => "Must provide reason for rejecting",
    }),
  ),
  rejectType: Schema.Enums(RejectTransactionEnum).pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (input): input is O.Option<RejectTransactionType> => O.isSome(input),
      {
        message: () => "You must choose a rejection type to proceed",
      },
    ),
  ),
});

type JournalEntryRejectFormInput = Schema.Schema.Encoded<
  typeof JournalEntryRejectFormSchema
>;
type JournalEntryRejectFormOutput = Schema.Schema.Type<
  typeof JournalEntryRejectFormSchema
>;

type RadioOptionProps = {
  description: string;
  label: string;
};

function RadioOption({ description, label }: RadioOptionProps) {
  return (
    <Stack spacing={Spacing.sm}>
      <H4>{label}</H4>
      <Text>{description}</Text>
    </Stack>
  );
}

type JournalEntryRejectFormProps = {
  isFirstStep: boolean;
  isProcessing: boolean;
  onSuccess: (comment: string, completelyReject: boolean) => void;
};

function GeneralLedgerTransactionApprovalsRejectTransactionForm({
  isFirstStep,
  isProcessing,
  onSuccess,
}: JournalEntryRejectFormProps) {
  const form = useForm<JournalEntryRejectFormInput>({
    defaultValues: {
      description: "",
      rejectType: O.some(RejectTransactionTypeEnum.REJECT_COMPLETELY),
    },
    mode: "onSubmit",
    resolver: effectTsResolver(JournalEntryRejectFormSchema),
  });

  const handleSubmit = useCallback(
    (values: JournalEntryRejectFormOutput) => {
      const completelyReject =
        pipe(values.rejectType, O.getOrThrow) ===
        RejectTransactionTypeEnum.REJECT_COMPLETELY;
      onSuccess(values.description, completelyReject);
    },
    [onSuccess],
  );

  //only include option to reject to previous step if the transaction is not on the first step
  const radioGroupData = [
    {
      label: (
        <RadioOption
          description="Mark the selected transaction(s) as Rejected and remove from the approval pipeline"
          label="Reject Completely"
        />
      ),
      value: RejectTransactionTypeEnum.REJECT_COMPLETELY,
    },
    ...(!isFirstStep
      ? [
          {
            label: (
              <RadioOption
                description="Send the selected transaction(s) back to their previous approval step"
                label="Send back one approval step"
              />
            ),
            value: RejectTransactionTypeEnum.REJECT_TO_PREVIOUS_STEP,
          },
        ]
      : []),
  ];

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack spacing={Spacing.md}>
        <FormRadioGroup data={radioGroupData} form={form} name="rejectType" />
        <FormTextInput
          form={form}
          label="Rejection Reason"
          name="description"
        />
        <Group spacing={Spacing.sm} justify={Justify.end}>
          <Button loading={isProcessing} type="submit" color={Color.red}>
            Reject
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export {
  GeneralLedgerTransactionApprovalsRejectTransactionForm,
  RejectTransactionTypeEnum,
};
