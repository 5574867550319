import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CreateApplicationResponseCreateApplicationStatusValues = [
  "SENT_SMS_VERIFICATION",
  "SENT_EMAIL",
  "SENT_SMS",
  "SUCCESS",
] as const;

const CreateApplicationResponseCreateApplicationStatusEffectSchema =
  Schema.Literal(...CreateApplicationResponseCreateApplicationStatusValues);

type CreateApplicationResponseCreateApplicationStatus = Schema.Schema.Type<
  typeof CreateApplicationResponseCreateApplicationStatusEffectSchema
>;

const CreateApplicationResponseCreateApplicationStatusEnum = castEnum(
  CreateApplicationResponseCreateApplicationStatusEffectSchema,
);

function randomCreateApplicationResponseCreateApplicationStatus(): CreateApplicationResponseCreateApplicationStatus {
  return rand(CreateApplicationResponseCreateApplicationStatusValues);
}

export {
  CreateApplicationResponseCreateApplicationStatusEffectSchema,
  CreateApplicationResponseCreateApplicationStatusEnum,
  CreateApplicationResponseCreateApplicationStatusValues,
  randomCreateApplicationResponseCreateApplicationStatus,
};
export type { CreateApplicationResponseCreateApplicationStatus };
