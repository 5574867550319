import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const PropertyUploadParseStatusValues = [
  "PENDING",
  "NEEDS_CONFIRMATION",
  "FAILED",
  "SUCCESS",
] as const;

const PropertyUploadParseStatusEffectSchema = Schema.Literal(
  ...PropertyUploadParseStatusValues,
);

type PropertyUploadParseStatus = Schema.Schema.Type<
  typeof PropertyUploadParseStatusEffectSchema
>;

const PropertyUploadParseStatusEnum = castEnum(
  PropertyUploadParseStatusEffectSchema,
);

function randomPropertyUploadParseStatus(): PropertyUploadParseStatus {
  return rand(PropertyUploadParseStatusValues);
}

export {
  PropertyUploadParseStatusEffectSchema,
  PropertyUploadParseStatusEnum,
  PropertyUploadParseStatusValues,
  randomPropertyUploadParseStatus,
};
export type { PropertyUploadParseStatus };
