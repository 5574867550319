/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/rejectAllOffers
 * @function rejectAllOffers
 * @memberof RenewalsAPI
 * @param {RenewalsAPIRejectAllOffersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { LeaseMoveOutReason } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPIRejectAllOffersPathParams = {
  leaseId: EnderId;
};

type RenewalsAPIRejectAllOffersSearchParams = {
  token?: string | undefined;
};

type RenewalsAPIRejectAllOffersBodyParams = {
  moveOutReason?: LeaseMoveOutReason | undefined;
};

type RenewalsAPIRejectAllOffersPayload = RenewalsAPIRejectAllOffersPathParams &
  RenewalsAPIRejectAllOffersSearchParams &
  RenewalsAPIRejectAllOffersBodyParams;

function rejectAllOffersUnsafeEffect(
  payload: RenewalsAPIRejectAllOffersPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/rejectAllOffers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function rejectAllOffers(
  payload: RenewalsAPIRejectAllOffersPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    rejectAllOffersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { rejectAllOffers };
export type {
  RenewalsAPIRejectAllOffersBodyParams,
  RenewalsAPIRejectAllOffersPathParams,
  RenewalsAPIRejectAllOffersPayload,
  RenewalsAPIRejectAllOffersSearchParams,
};
