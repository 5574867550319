import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import { useCallback } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { UnitsAPI } from "@ender/shared/generated/ender.api.core";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const VideoUploadFormSchema = Schema.Struct({
  videoUrl: Schema.String,
});
type VideoUploadFormOutput = Schema.Schema.Type<typeof VideoUploadFormSchema>;

type VideoUploadProps = {
  onSuccess?: () => void;
  onCancel?: () => void;
  unit: Pick<UnitSerializerUnitResponse, "id" | "videoUrl">;
};

function VideoUpload(props: VideoUploadProps) {
  const { unit, onSuccess = F.constVoid, onCancel } = props;
  const { id: unitId, videoUrl = "" } = unit;

  const form = useEffectSchemaForm({
    defaultValues: {
      videoUrl,
    },
    schema: VideoUploadFormSchema,
  });

  const { mutateAsync: updateUnit } = useMutation({
    mutationFn: UnitsAPI.updateUnit,
    mutationKey: ["UnitsAPI.updateUnit"] as const,
  });

  const handleSubmit = useCallback(
    async (values: VideoUploadFormOutput) => {
      if (P.isNullable(unitId)) {
        return;
      }

      await updateUnit({ unitId, videoUrl: values.videoUrl });
      showSuccessNotification({ message: "Unit video successfully updated" });
      onSuccess();
    },
    [onSuccess, unitId, updateUnit],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput
          form={form}
          name="videoUrl"
          label="Video URL"
          placeholder="Video URL"
        />
        <Group justify={Justify.end} align={Align.center}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { VideoUpload };
