/**
 * HTTP Method: POST
 * Pathname: /factors
 * @function newFactor
 * @memberof FactorsAPI
 * @param {FactorsAPINewFactorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Factor>}
 */
import { Effect, Function as F } from "effect";

import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { CustomFactorFactorOutputType } from "@ender/shared/generated/ender.model.factor";
import type { Factor } from "@ender/shared/generated/ender.service.factors";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPINewFactorSearchParams = {
  token?: string | undefined;
};

type FactorsAPINewFactorBodyParams = {
  defaultValue?: string | undefined;
  enumValues?: string[] | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  factorJson?: any | undefined;
  factorType?: string | undefined;
  grouping?: string | undefined;
  inputType?: ModelType | undefined;
  isFavorite?: boolean | undefined;
  isRestricted?: boolean | undefined;
  name?: string | undefined;
  outputType?: CustomFactorFactorOutputType | undefined;
};

type FactorsAPINewFactorPayload = FactorsAPINewFactorSearchParams &
  FactorsAPINewFactorBodyParams;

function newFactorUnsafeEffect(payload: FactorsAPINewFactorPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Factor>({
        body,
        decode: unsafeDecodeJsonResponse<Factor>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/factors",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function newFactor(
  payload: FactorsAPINewFactorPayload,
  options?: { signal?: AbortSignal },
): Promise<Factor> {
  return F.pipe(
    payload,
    newFactorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { newFactor };
export type {
  FactorsAPINewFactorBodyParams,
  FactorsAPINewFactorPayload,
  FactorsAPINewFactorSearchParams,
};
