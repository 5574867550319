/**
 * HTTP Method: GET
 * Pathname: /factors/{customFactorId}/possibleEnumValues
 * @function getPossibleEnumValues
 * @memberof FactorsAPI
 * @param {FactorsAPIGetPossibleEnumValuesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIGetPossibleEnumValuesPathParams = {
  customFactorId: EnderId;
};

type FactorsAPIGetPossibleEnumValuesSearchParams = {
  token?: string | undefined;
};

type FactorsAPIGetPossibleEnumValuesPayload =
  FactorsAPIGetPossibleEnumValuesPathParams &
    FactorsAPIGetPossibleEnumValuesSearchParams;

function getPossibleEnumValuesUnsafeEffect(
  payload: FactorsAPIGetPossibleEnumValuesPayload,
) {
  const { customFactorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string[]>({
        body,
        decode: unsafeDecodeJsonResponse<string[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/factors/${customFactorId}/possibleEnumValues`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPossibleEnumValues(
  payload: FactorsAPIGetPossibleEnumValuesPayload,
  options?: { signal?: AbortSignal },
): Promise<string[]> {
  return F.pipe(
    payload,
    getPossibleEnumValuesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPossibleEnumValues };
export type {
  FactorsAPIGetPossibleEnumValuesPathParams,
  FactorsAPIGetPossibleEnumValuesPayload,
  FactorsAPIGetPossibleEnumValuesSearchParams,
};
