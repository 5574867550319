import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useId, useState } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { PhotosAPI } from "@ender/shared/generated/ender.api.misc";
import type { Photo } from "@ender/shared/generated/ender.model.files";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import styles from "./images-card.module.css";

type ImageModalProps = {
  image: Photo;
  fetchImages: () => void;
  setViewer: (image: Photo | null) => void;
};

function ImagePreview(props: ImageModalProps) {
  const { image, fetchImages, setViewer } = props;
  // const [isDeleteModalOpened, { setTrue: openDeleteModal, setFalse: closeDeleteModal }] = useBoolean(false);
  const confirmation = useConfirmationContext();

  async function handleSetCover() {
    try {
      await PhotosAPI.updatePhoto({ isPrimary: true, photoId: image.id });
      fetchImages();
      showSuccessNotification({
        message: "Image successfully set as cover photo",
      });
    } catch (err) {
      fail(err);
    }
  }

  async function deleteImage() {
    try {
      await confirmation({
        content: "Are you sure you want to delete this image?",
        title: "Delete Image",
      });
      await PhotosAPI.deletePhoto({ photoId: image.id });
      setViewer(null);
      fetchImages();
      showSuccessNotification({ message: "Image successfully deleted" });
    } catch (err) {
      fail(err);
    }
  }

  return (
    <Stack spacing={Spacing.md}>
      <Group align={Align.center}>
        {!image.isPrimary && (
          <Button variant={ButtonVariant.outlined} onClick={handleSetCover}>
            Set as Cover Image
          </Button>
        )}
        <ActionIcon tooltip="Delete Image" onClick={() => deleteImage()}>
          <IconTrash />
        </ActionIcon>
      </Group>
      <img className={styles.modalImage} src={image.large} alt="" />
    </Stack>
  );
}

type ImagesCardProps = {
  unitId: EnderId;
  onEdit: (images: Photo[]) => void;
};

function ImagesCard(props: ImagesCardProps) {
  const { onEdit, unitId } = props;
  const [viewer, setViewer] = useState<Photo | null>(null);

  const { data: images = [], refetch } = useQuery({
    queryFn: ({ signal }) => PhotosAPI.getPhotosForUnit({ unitId }, { signal }),
    queryKey: ["PhotosAPI.getPhotosForUnit", unitId] as const,
    select: (data) =>
      data.albums
        .flatMap((album) => album.photos)
        .sort((photo1, photo2) => photo1.priority - photo2.priority),
  });

  const headingId = useId();
  return (
    <>
      <Card labelledBy={headingId}>
        <Group justify={Justify.between}>
          <H3 id={headingId}>Images for Listing</H3>
          <ActionIcon
            variant={ButtonVariant.transparent}
            onClick={() => onEdit(images)}>
            <IconEdit color={EnderThemeColorEnum.PRIMARY} />
          </ActionIcon>
        </Group>
        {P.isNotNullable(images) && A.isNonEmptyArray(images) ? (
          <Group>
            {images.map((image) => (
              <div
                className={styles.unitImage}
                onClick={() => setViewer(image)}
                key={image.id}>
                {image.isPrimary && (
                  <div className={styles.coverOverlay}>C</div>
                )}
                <img src={image.small} alt="" loading="lazy" />
              </div>
            ))}
          </Group>
        ) : (
          <Text size={FontSize.sm}>No images uploaded for this unit</Text>
        )}
      </Card>
      <Modal
        title={P.isNotNullable(viewer) && viewer.isPrimary ? "Cover Image" : ""}
        size={ModalSize.lg}
        opened={P.isNotNullable(viewer)}
        onClose={() => setViewer(null)}>
        {P.isNotNullable(viewer) && (
          <ImagePreview
            image={viewer}
            fetchImages={refetch}
            setViewer={setViewer}
          />
        )}
      </Modal>
    </>
  );
}

export { ImagesCard };
