import { Schema } from "@effect/schema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Array as A, Function as F, Option as O, pipe } from "effect";
import { useContext, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useStore } from "zustand";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import {
  LocalDateEffectSchema,
  MoneyEffectSchema,
} from "@ender/form-system/schema";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, LocalDate$, Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormSwitch } from "@ender/shared/ds/switch";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { TenantLedgerAPICreateLeaseCreditPayload } from "@ender/shared/generated/ender.api.accounting";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import {
  AccountingPeriodAccountingModuleEnum,
  CategoryFlagEnum,
} from "@ender/shared/generated/ender.model.accounting";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { useGetGlCategories, useGetLeaseId } from "../../hooks";
import { useTenantLedgerStore } from "../../tenant-ledger-store.context";

const allocateViaWaterfallLabel = "Allocate via Tenant Waterfall";
const selectAllocationLabel = "Select Tenant Ledger Allocation";

const AccountingPeriodSchema = Schema.Struct({
  id: EnderIdFormSchema,
});

const LeaseCreditsFormSchema = Schema.Struct({
  amount: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (v): v is O.Some<Money$.Money> => O.exists(v, Money$.isPositive),
      {
        message: () => "Amount is required",
      },
    ),
  ),
  applyToOutstandingCharges: Schema.Boolean,
  debitCategoryId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    // This custom predicate can be replaced or removed once form types are fixed
    Schema.filter(O.isSome, {
      message: () => "Please choose a lease credit type",
    }),
  ),
  description: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Please add a description" }),
  ),
  ledgerDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    // This custom predicate can be replaced or removed once form types are fixed
    Schema.filter(O.isSome, { message: () => "Please choose a date" }),
  ),
  offsetCategoryId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  periodId: Schema.OptionFromSelf(AccountingPeriodSchema),
}).pipe(
  Schema.filter((values) => {
    if (!values.applyToOutstandingCharges) {
      if (O.isNone(values.debitCategoryId)) {
        return {
          message: "Please choose a debit account",
          path: ["debitCategoryId"],
        };
      }
      if (O.isNone(values.offsetCategoryId)) {
        return {
          message: "Please choose a credit type",
          path: ["offsetCategoryId"],
        };
      }
    }
  }),
);

type LeaseCreditsFormValues = Schema.Schema.Type<typeof LeaseCreditsFormSchema>;

type LeaseCreditsModalProps = {
  onSuccess?: () => void;
};

function LeaseCreditsForm({ onSuccess = F.constVoid }: LeaseCreditsModalProps) {
  const leaseId = useGetLeaseId();
  const queryClient = useQueryClient();
  const { userPM } = useContext(UserContext);
  const tenantLedgerStore = useTenantLedgerStore();
  const confirmation = useConfirmationContext();

  const { setLedgerEvent } = useStore(tenantLedgerStore, (state) => ({
    setLedgerEvent: state.setLedgerEvent,
  }));

  const form = useEffectSchemaForm({
    defaultValues: {
      amount: O.none(),
      applyToOutstandingCharges: true,
      debitCategoryId: O.none(),
      description: "",
      ledgerDate: O.fromNullable(LocalDate$.today()),
      offsetCategoryId: O.none(),
      periodId: O.none(),
    },
    schema: LeaseCreditsFormSchema,
  });

  const { mutateAsync: createLeaseCredit, isLoading: isSubmitting } =
    useMutation({
      mutationFn: TenantLedgerAPI.createLeaseCredit,
      mutationKey: ["TenantLedgerAPI.createLeaseCredit"] as const,
    });

  const { glCategories = [], isGlCategoriesFetching } = useGetGlCategories({
    excludeParentCategories: true,
  });

  async function handleSubmit(values: LeaseCreditsFormValues) {
    const {
      amount,
      applyToOutstandingCharges,
      debitCategoryId,
      description,
      ledgerDate,
      periodId,
      offsetCategoryId,
    } = values;
    setLedgerEvent(O.none());

    const isParentCategorySelected = A.isNonEmptyArray(
      glCategories.filter((category) => {
        return (
          (category.id === O.getOrThrow(debitCategoryId) ||
            category.id === O.getOrUndefined(offsetCategoryId)) &&
          category.isParent
        );
      }),
    );

    if (isParentCategorySelected && userPM.confirmParentAllocations) {
      await confirmation({
        confirmButtonLabel: "Yes, use category",
        title:
          "You have selected to allocate to a parent category. Are you sure you would like to proceed?",
      });
    }

    const requestBody: TenantLedgerAPICreateLeaseCreditPayload = {
      amount: O.getOrThrow(amount).toJSON(),
      autoallocate: UNDEFINED,
      debitCategoryId: O.getOrThrow(debitCategoryId),
      description,
      leaseId,
      ledgerDate: O.getOrThrow(ledgerDate).toJSON(),
      offsetCategoryId: UNDEFINED,
      periodId: pipe(
        periodId,
        O.map((accountingPeriod) => accountingPeriod.id),
        O.getOrUndefined,
      ),
      requestId: "",
    };
    // do not send both autoallocate and offsetCategoryId
    if (applyToOutstandingCharges) {
      requestBody.autoallocate = applyToOutstandingCharges;
    } else if (O.isSome(offsetCategoryId)) {
      requestBody.offsetCategoryId = O.getOrThrow(offsetCategoryId);
    }

    await createLeaseCredit(requestBody);
    await queryClient.invalidateQueries(["getTenantLedger"]);
    await queryClient.invalidateQueries(["LeasingAPI.getLeaseDetails"]);
    showSuccessNotification({
      message: `Credit of ${O.getOrThrow(amount).toJSON()} has been added.`,
      title: "Credit created",
    });
    onSuccess();
  }

  const { setValue } = form;

  // lazy useEffect
  useEffect(() => {
    setValue("offsetCategoryId", O.none());
  }, [setValue]);

  const debitCategoryIdOptions = useMemo(
    () =>
      glCategories.map((glAccount) => ({
        label: `${glAccount.accountNumber} ${glAccount.accountName}`,
        value: glAccount.id,
      })),
    [glCategories],
  );

  const offsetCategoryIdOptions = useMemo(
    () =>
      glCategories
        .filter(
          (cat) =>
            cat.flags.includes(CategoryFlagEnum.PREPAID_RENT) ||
            cat.flags.includes(CategoryFlagEnum.TENANT_CHARGE_TYPE),
        )
        .map((glAccount) => ({
          label: `${glAccount.accountNumber} ${glAccount.accountName}`,
          value: glAccount.id,
        })),
    [glCategories],
  );

  const applyToOutstandingCharges = useWatch({
    control: form.control,
    name: "applyToOutstandingCharges",
  });

  if (isGlCategoriesFetching) {
    return NULL;
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <H2>Add Lease Credit</H2>
        <FormDateInput label="Date of credit" name="ledgerDate" form={form} />
        <FormAccountingPeriodSelector
          periodType={AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE}
          form={form}
          name="periodId"
          label="Accounting Period (optional)"
        />
        <FormSelect<EnderId, typeof form>
          label="Lease Credit Type"
          name="debitCategoryId"
          data={debitCategoryIdOptions}
          form={form}
          description="Select the lease credit type. This entry will create a debit on the General Ledger."
        />
        <FormSwitch
          label="Apply via Tenant Waterfall Allocation"
          name="applyToOutstandingCharges"
          form={form}
        />
        <FormSelect<EnderId, typeof form>
          label="Tenant Ledger Allocation"
          name="offsetCategoryId"
          form={form}
          data={offsetCategoryIdOptions}
          disabled={applyToOutstandingCharges}
          placeholder={
            applyToOutstandingCharges
              ? allocateViaWaterfallLabel
              : selectAllocationLabel
          }
          clearable
          description="Choose how you would like to allocate this credit. It will be applied to the selected charge type on the tenant ledger."
        />
        <FormMoneyInput label="Amount" name="amount" form={form} />
        <FormTextInput label="Description" name="description" form={form} />
        <Group justify={Justify.end}>
          <Button type="submit" loading={isSubmitting}>
            Add Credit
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { LeaseCreditsForm };
