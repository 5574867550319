import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const AllocationTypeSchema = z.enum(["DEBIT", "CREDIT"]);
type AllocationType = z.infer<typeof AllocationTypeSchema>;
const AllocationTypeEnum = castEnum<AllocationType>(AllocationTypeSchema);

export { AllocationTypeEnum, AllocationTypeSchema };
export type { AllocationType };
