import type { PropsWithChildren } from "react";

import { RightRailProvider } from "@ender/shared/ui/right-rail";

import { EnderContainer } from "./ender-container";
import { EnderIdle } from "./ender-idle";
import { Nav } from "./nav";
import { NavHeader } from "./nav-header";

function AppShell(props: PropsWithChildren) {
  return (
    <RightRailProvider>
      <Nav />
      <NavHeader />
      <EnderContainer>{props.children}</EnderContainer>
      <EnderIdle />
    </RightRailProvider>
  );
}

export { AppShell };
