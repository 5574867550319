/* eslint-disable sort-keys */
import type {
  ColumnPinningState,
  CoreOptions,
  ExpandedState,
  Row,
  Table,
  ColumnDef as TanstackColumnDef,
} from "@tanstack/react-table";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Predicate as P } from "effect";
import type { SetStateAction } from "react";
import { useMemo, useRef } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { cast } from "@ender/shared/types/cast";
import type { EmptyObject, ObjectValues } from "@ender/shared/types/general";
import { noOpAsync } from "@ender/shared/utils/no-op";

import type {
  ColumnDef,
  ColumnDefMeta,
  EnderTable,
  EnderTableColumnPinningParams,
  EnderTableColumnVisibilityParams,
  EnderTableFilterParams,
  EnderTableFooter,
  EnderTablePaginationParams,
  EnderTableRowExpansionParams,
  EnderTableRowSelectionParams,
  EnderTableSortingParams,
  EnderTableTopAction,
} from "../table.types";
import { asColumnDef } from "../table.types";
import { useSyncQueryParams } from "./use-table-query-params";

function toTanstackColumnDef<RowData, ColumnData, FilterData>(
  columnDef: ColumnDef<RowData, ColumnData, FilterData>,
): TanstackColumnDef<RowData, ColumnData> {
  const {
    className,
    filterComponent,
    getFilterLabel,
    badgeRemoveFilterFn,
    ...columnParts
  } = columnDef;

  return cast<TanstackColumnDef<RowData, ColumnData>>({
    ...columnParts,
    meta: {
      className,
      filterComponent,
      getFilterLabel,
      badgeRemoveFilterFn,
    },
  });
}

// Builtin tanstack function accounts for filtering, which we don't want
function isSomeRowsSelected<RowData>(table: Table<RowData>): boolean {
  const totalSelected = Object.keys(table.getState().rowSelection ?? {}).length;
  return (
    totalSelected > 0 && totalSelected < table.getCoreRowModel().flatRows.length
  );
}

type UseColumnsParams<RowData> = {
  columns: ColumnDef<RowData>[];
  enableSelectAll: boolean;
  enableRowSelection: boolean;
};

function useColumns<RowData>(
  params: UseColumnsParams<RowData>,
): TanstackColumnDef<RowData, ObjectValues<RowData>>[] {
  const { columns, enableSelectAll, enableRowSelection } = params;

  return useMemo(() => {
    const _columns = [...columns];
    // TODO[@EnderHub/fe-table-management][cc`@slamkajs] - Somehow support `enableRowSelection` as a Function when we don't have a row here...
    if (enableRowSelection) {
      const selectColumnDef = asColumnDef<RowData>({
        id: "row-select",
        header: ({ table }) => {
          return (
            enableSelectAll && (
              <Checkbox
                label="Select All Rows"
                hideLabel
                onChange={table.toggleAllRowsSelected}
                value={table.getIsAllRowsSelected()}
                indeterminate={isSomeRowsSelected(table)}
              />
            )
          );
        },
        cell: ({ row }) => (
          <Checkbox
            label={`Select row ${row.index + 1}`}
            hideLabel
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            value={row.getIsSelected()}
          />
        ),
        className: "row-select",
        minSize: 46,
        size: 46,
        maxSize: 46,
      });
      _columns.unshift(selectColumnDef);
    }
    return _columns.map(toTanstackColumnDef);
  }, [columns, enableSelectAll, enableRowSelection]);
}

function useColumnPinningWithRowSelect<RowData>(
  columnPinning: ColumnPinningState | undefined,
  enableRowSelection?: EnderTableRowSelectionParams<RowData>["enableRowSelection"],
) {
  return useMemo(() => {
    const columnPinningLeft = [...(columnPinning?.left ?? [])];
    // TODO[@EnderHub/fe-table-management][cc`@slamkajs] - Somehow support `enableRowSelection` as a Function when we don't have a row here...
    if (enableRowSelection) {
      columnPinningLeft.unshift("row-select");
    }

    return { left: columnPinningLeft, right: columnPinning?.right };
  }, [columnPinning?.left, columnPinning?.right, enableRowSelection]);
}

// @deprecated These should be removed in the future
type DeprecatedTableV1Params = {
  onClearFilters?: () => void;
};

type UseTableParams<RowData, Meta extends object = EmptyObject> = Pick<
  CoreOptions<RowData>,
  "data"
> & {
  columns: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ColumnDef<RowData, any, any, Meta>[] | ColumnDefMeta<RowData, Meta>[];
} & DeprecatedTableV1Params &
  Partial<{
    id: string;
    title: string;
    meta: Meta;
    getRowId?: (row: RowData, index?: number) => string;
    refetch: () => Promise<unknown>;
    fetchNextPage: () => Promise<unknown>;
    isLoading: boolean;
    errorMessage: string;
    totalResults?: number;
    hideResults?: boolean;
    actions?: EnderTableTopAction<RowData, Meta>[];
    enableQueryParams: boolean;
    sorting: EnderTableSortingParams;
    columnFilters: EnderTableFilterParams<RowData>;
    rowExpansion: EnderTableRowExpansionParams<RowData>;
    rowSelection: EnderTableRowSelectionParams<RowData, Meta>;
    pagination: EnderTablePaginationParams;
    columnVisibility: EnderTableColumnVisibilityParams;
    columnPinning: EnderTableColumnPinningParams;
    footer: EnderTableFooter<RowData, Meta>;
  }>;

function useTable<RowData, Meta extends object = EmptyObject>(
  params: UseTableParams<RowData, Meta>,
): EnderTable<RowData, Meta> {
  const {
    id,
    title = "Table",
    data,
    refetch = noOpAsync,
    fetchNextPage = noOpAsync,
    isLoading = false,
    errorMessage,
    totalResults,
    hideResults = false,
    actions,
    meta: customMeta,
    columns: columnParams,
    columnPinning: columnPinningParams,
    columnVisibility: columnVisibilityParams,
    columnFilters: filteringParams,
    getRowId,
    pagination: paginationParams,
    rowExpansion: rowExpansionParams,
    rowSelection: rowSelectionParams,
    sorting: sortingParams,
    enableQueryParams = false,
    footer,
    onClearFilters,
  } = params;
  const tableRef = useRef<EnderTable<RowData, Meta>>();

  const {
    columnFilters,
    onColumnFiltersChange: setColumnFiltersState,
    enableGlobalFilter,
    globalFilter,
    onGlobalFilterChange: setGlobalFilterState,
    globalFilterFn,
    manualFiltering,
  } = filteringParams ?? {};

  const { columnVisibility } = columnVisibilityParams ?? {};

  const {
    pagination,
    pageCount,
    onPaginationChange,
    autoResetPageIndex,
    manualPagination = true,
  } = paginationParams ?? {};

  const {
    expanded,
    onExpandedChange,
    getSubRows,
    getRowCanExpand,
    onExpandRows,
  } = rowExpansionParams ?? {};

  const _onExpandedChange = useMemo(() => {
    if (P.isNullable(onExpandRows)) {
      return onExpandedChange;
    }

    if (P.isNotNullable(onExpandedChange)) {
      return (expandedStateAction: SetStateAction<ExpandedState>): void => {
        const table = tableRef.current;

        if (P.isNullable(table)) {
          return;
        }

        const prevExpanded = table.getState().expanded;
        const nextExpanded = P.isFunction(expandedStateAction)
          ? expandedStateAction(prevExpanded)
          : expandedStateAction;

        const prevRowIds = new Set(
          Object.entries(prevExpanded)
            .filter(([, value]) => value)
            .map(([key]) => key),
        );

        const nextRowIds = new Set(
          Object.entries(nextExpanded)
            .filter(([, value]) => value)
            .map(([key]) => key),
        );

        const expanded = Array.from(nextRowIds)
          .filter((id) => !prevRowIds.has(id))
          .map((id) => table.getRow(id));

        const collapsed = Array.from(prevRowIds)
          .filter((id) => !nextRowIds.has(id))
          .map((id) => table.getRow(id));

        onExpandRows({ expanded, collapsed });
        onExpandedChange(nextExpanded);
      };
    }
  }, [onExpandedChange, onExpandRows]);

  const {
    rowSelection,
    enableSelectAll,
    enableRowSelection,
    enableMultiRowSelection,
    onRowSelectionChange,
  } = rowSelectionParams ?? {};

  const _enableRowSelection = useMemo(() => {
    if (!P.isFunction(enableRowSelection)) {
      return enableRowSelection;
    }

    return (row: Row<RowData>): boolean => {
      const table = tableRef.current;
      if (P.isNullable(table)) {
        return false;
      }
      return enableRowSelection(row, table);
    };
  }, [enableRowSelection]);

  const _enableMultiRowSelection = useMemo(() => {
    if (!P.isFunction(enableMultiRowSelection)) {
      return enableMultiRowSelection;
    }

    return (row: Row<RowData>): boolean => {
      const table = tableRef.current;
      if (P.isNullable(table)) {
        return false;
      }
      return enableMultiRowSelection(row, table);
    };
  }, [enableMultiRowSelection]);

  const {
    sorting,
    onSortingChange: setSortingState,
    enableSorting,
    enableSortingRemoval,
    enableMultiRemove,
    enableMultiSort,
    manualSorting,
    maxMultiSortColCount,
    isMultiSortEvent,
  } = sortingParams ?? {};

  const columnPinning = useColumnPinningWithRowSelect(
    columnPinningParams?.columnPinning,
    Boolean(enableRowSelection),
  );

  const { onSortingChange, onColumnFiltersChange, onGlobalFilterChange } =
    useSyncQueryParams({
      id,
      columns: columnParams as ColumnDef<RowData>[],
      enableQueryParams,
      setSortingState,
      setColumnFiltersState,
      setGlobalFilterState,
    });

  const columns = useColumns({
    columns: columnParams as ColumnDef<RowData>[],
    enableSelectAll: Boolean(enableSelectAll),
    enableRowSelection: Boolean(enableRowSelection),
  });

  const meta = useMemo(() => {
    return Object.assign({}, customMeta, {
      title,
      refetch,
      fetchNextPage,
      isLoading,
      errorMessage,
      totalResults,
      hideResults,
      actions,
      footer,
      onClearFilters,
    });
  }, [
    customMeta,
    title,
    totalResults,
    refetch,
    fetchNextPage,
    isLoading,
    errorMessage,
    hideResults,
    actions,
    footer,
    onClearFilters,
  ]);

  tableRef.current = useReactTable<RowData>({
    data,
    meta,
    columns,
    state: {
      sorting,
      columnFilters,
      globalFilter,
      expanded,
      rowSelection,
      pagination,
      columnVisibility,
      columnPinning,
    },
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    // sorting
    enableSorting,
    enableSortingRemoval,
    enableMultiRemove,
    enableMultiSort,
    manualSorting,
    maxMultiSortColCount,
    onSortingChange,
    isMultiSortEvent,
    getSortedRowModel: getSortedRowModel(),
    // filtering
    onColumnFiltersChange,
    onGlobalFilterChange,
    enableGlobalFilter,
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    manualFiltering,
    // selecting
    enableRowSelection: _enableRowSelection,
    enableMultiRowSelection: _enableMultiRowSelection,
    onRowSelectionChange,
    // pagination
    pageCount,
    manualPagination,
    onPaginationChange,
    autoResetPageIndex,
    getPaginationRowModel: getPaginationRowModel(),
    // expansion
    onExpandedChange: _onExpandedChange,
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows,
    // columnVisibility
    // columnPinning
    // columnSizing
    defaultColumn: {
      size: UNDEFINED,
      minSize: UNDEFINED,
      maxSize: UNDEFINED,
    },
  }) as EnderTable<RowData, Meta>;
  return tableRef.current;
}

export { useTable };
export type { UseTableParams };
