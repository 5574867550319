/**
 * Process an electronic __BANK__ payment by a tenant.
 * HTTP Method: POST
 * Pathname: /makePayment
 * @function makeRentPaymentByACH
 * @memberof PaymentsAPI
 * @param {PaymentsAPIMakeRentPaymentByACHPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransfer>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { MoneyTransfer } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIMakeRentPaymentByACHSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIMakeRentPaymentByACHBodyParams = {
  amount: SerializesInto<Money>;
  bankAccountId: EnderId;
  overrideWarnings?: boolean | undefined;
};

type PaymentsAPIMakeRentPaymentByACHPayload =
  PaymentsAPIMakeRentPaymentByACHSearchParams &
    PaymentsAPIMakeRentPaymentByACHBodyParams;

function makeRentPaymentByACHUnsafeEffect(
  payload: PaymentsAPIMakeRentPaymentByACHPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransfer>({
        body,
        decode: unsafeDecodeJsonResponse<MoneyTransfer>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/makePayment",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function makeRentPaymentByACH(
  payload: PaymentsAPIMakeRentPaymentByACHPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransfer> {
  return F.pipe(
    payload,
    makeRentPaymentByACHUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { makeRentPaymentByACH };
export type {
  PaymentsAPIMakeRentPaymentByACHBodyParams,
  PaymentsAPIMakeRentPaymentByACHPayload,
  PaymentsAPIMakeRentPaymentByACHSearchParams,
};
