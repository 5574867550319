import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BuyBoxBuyBoxTypeValues = ["STANDALONE_DEAL", "BULK_DEAL"] as const;

const BuyBoxBuyBoxTypeEffectSchema = Schema.Literal(...BuyBoxBuyBoxTypeValues);

type BuyBoxBuyBoxType = Schema.Schema.Type<typeof BuyBoxBuyBoxTypeEffectSchema>;

const BuyBoxBuyBoxTypeEnum = castEnum(BuyBoxBuyBoxTypeEffectSchema);

function randomBuyBoxBuyBoxType(): BuyBoxBuyBoxType {
  return rand(BuyBoxBuyBoxTypeValues);
}

export {
  BuyBoxBuyBoxTypeEffectSchema,
  BuyBoxBuyBoxTypeEnum,
  BuyBoxBuyBoxTypeValues,
  randomBuyBoxBuyBoxType,
};
export type { BuyBoxBuyBoxType };
