import { Predicate as P } from "effect";
import type { MouseEvent, ReactNode } from "react";
import { useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Modal } from "@ender/shared/ds/modal";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { BankRecRowEnderBatchResponse } from "@ender/shared/generated/ender.service.accounting.banking";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { InvoiceRightRail } from "@ender/widgets/finance/invoice-view";

import { BatchInvoiceDetails } from "../../../../../../widgets/batch-invoice-details-modal/batch-invoice-details";

type BankingDetailInvoiceIconAndModalsProps = {
  refreshBankTransactions: () => void;
  invoiceIcon: ReactNode;
  isMultiInvoice: boolean;
  linkedInvoice?: {
    id: EnderId;
  };
  enderBatch?: BankRecRowEnderBatchResponse | undefined;
};

function BankingDetailInvoiceIconAndModals({
  refreshBankTransactions,
  invoiceIcon,
  isMultiInvoice,
  linkedInvoice,
  enderBatch,
}: BankingDetailInvoiceIconAndModalsProps) {
  const [invoiceModalId, setInvoiceModalId] = useState<EnderId>();
  const [
    isBatchInvoiceModalOpen,
    { setTrue: openBatchInvoiceModal, setFalse: closeBatchInvoiceModal },
  ] = useBoolean();

  function openInvoiceModal(id: EnderId, e: MouseEvent) {
    e.preventDefault();
    setInvoiceModalId(id);
  }

  function closeInvoiceModal() {
    setInvoiceModalId(UNDEFINED);
  }

  return (
    <>
      {(P.isNotNullable(linkedInvoice) || isMultiInvoice) && (
        <Tooltip label="View Invoice">
          <ActionIcon
            variant={ButtonVariant.transparent}
            onClick={(e) =>
              P.isNotNullable(linkedInvoice)
                ? openInvoiceModal(linkedInvoice.id, e)
                : openBatchInvoiceModal()
            }>
            {invoiceIcon}
          </ActionIcon>
        </Tooltip>
      )}
      {isMultiInvoice && P.isNotNullable(enderBatch) && (
        <Modal
          title=""
          opened={isBatchInvoiceModalOpen}
          onClose={closeBatchInvoiceModal}>
          <BatchInvoiceDetails
            moneyTransferIds={enderBatch.moneyTransfers.map((mt) => mt.id)}
            onClickInvoice={openInvoiceModal}
          />
        </Modal>
      )}
      {P.isNotNullable(invoiceModalId) && (
        <InvoiceRightRail
          invoiceId={invoiceModalId}
          onClose={closeInvoiceModal}
          opened={P.isNotNullable(invoiceModalId)}
          refreshData={refreshBankTransactions}
        />
      )}
    </>
  );
}

export { BankingDetailInvoiceIconAndModals };
