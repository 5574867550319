// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Title } from "@mantine/core";
import type { PropsWithChildren, ReactNode } from "react";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";

import styles from "./right-rail.module.css";

function RightRailEyebrow(props: PropsWithChildren) {
  return (
    <div className={`${styles.rightRailEyebrow} uppercase`}>
      <Text
        size={FontSize.md}
        weight={FontWeight.normal}
        {...props}
      />
    </div>
  );
}

function RightRailTitle(props: PropsWithChildren) {
  return <Title order={1} {...props} />;
}

function RightRailSubtitle(props: PropsWithChildren) {
  return <Text size={FontSize.lg} weight={FontWeight.normal} {...props} />;
}

function RightRailActionBar(props: PropsWithChildren) {
  return <div className={styles.rightRailActionBar} {...props} />;
}

type RightRailHeaderProps = {
  eyebrow?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode;
};

function RightRailHeader(props: RightRailHeaderProps) {
  const { eyebrow, title, subtitle, actions } = props;

  return (
    <div className={styles.rightRailHeader}>
      {eyebrow && <RightRailEyebrow>{eyebrow}</RightRailEyebrow>}
      <RightRailTitle>{title}</RightRailTitle>
      {subtitle && <RightRailSubtitle>{subtitle}</RightRailSubtitle>}
      {actions && <RightRailActionBar>{actions}</RightRailActionBar>}
    </div>
  );
}

export {
  RightRailActionBar,
  RightRailEyebrow,
  RightRailHeader,
  RightRailSubtitle,
  RightRailTitle,
};
export type { RightRailHeaderProps };
