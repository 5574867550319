import { getAnnouncementDetails } from "./get-announcement-details";
import { getCountForTenantAnnouncement } from "./get-count-for-tenant-announcement";
import { getCountForVendorAnnouncement } from "./get-count-for-vendor-announcement";
import { sendApplicantAnnouncement } from "./send-applicant-announcement";
import { sendFilteredTenantAnnouncement } from "./send-filtered-tenant-announcement";
import { sendFilteredVendorAnnouncement } from "./send-filtered-vendor-announcement";
import { sendPropertyAnnouncement } from "./send-property-announcement";
import { sendProspectAnnouncement } from "./send-prospect-announcement";

const AnnouncementAPI = {
  getAnnouncementDetails,
  getCountForTenantAnnouncement,
  getCountForVendorAnnouncement,
  sendApplicantAnnouncement,
  sendFilteredTenantAnnouncement,
  sendFilteredVendorAnnouncement,
  sendPropertyAnnouncement,
  sendProspectAnnouncement,
};

export { AnnouncementAPI };
