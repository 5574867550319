/**
 * Match a bank transaction. There are a few ways this could be happening:
 
  <ul> 
  <li> Matching the bank transaction with multiple singletons, creating a new batch. </li> 
  <li> Matching the bank transaction with a single already-declared batch. </li> 
  </ul>
 * HTTP Method: POST
 * Pathname: /bankTransactions/{bankTransactionId}/manualMatch
 * @function manualMatch
 * @memberof BankingAPI
 * @param {BankingAPIManualMatchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIManualMatchPathParams = {
  bankTransactionId: EnderId;
};

type BankingAPIManualMatchSearchParams = {
  token?: string | undefined;
};

type BankingAPIManualMatchBodyParams = {
  enderBatchIds: EnderId[];
};

type BankingAPIManualMatchPayload = BankingAPIManualMatchPathParams &
  BankingAPIManualMatchSearchParams &
  BankingAPIManualMatchBodyParams;

function manualMatchUnsafeEffect(payload: BankingAPIManualMatchPayload) {
  const { bankTransactionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankTransactions/${bankTransactionId}/manualMatch`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function manualMatch(
  payload: BankingAPIManualMatchPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    manualMatchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { manualMatch };
export type {
  BankingAPIManualMatchBodyParams,
  BankingAPIManualMatchPathParams,
  BankingAPIManualMatchPayload,
  BankingAPIManualMatchSearchParams,
};
