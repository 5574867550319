/**
 * HTTP Method: POST
 * Pathname: /collections/steps
 * @function createStep
 * @memberof CollectionsAPI
 * @param {CollectionsAPICreateStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsStep>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalTime,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { ChargeScheduleBasedThreshold } from "@ender/shared/generated/ender.api.finance.request";
import type {
  CollectionsStep,
  CollectionsStepCollectionsActionType,
  CollectionsStepProcessType,
} from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPICreateStepSearchParams = {
  token?: string | undefined;
};

type CollectionsAPICreateStepBodyParams = {
  actionTime?: SerializesInto<LocalTime> | undefined;
  actionType: CollectionsStepCollectionsActionType;
  automatedAfterNHours?: number | undefined;
  maxBalanceDue?: SerializesInto<Money> | undefined;
  maxChargeScheduleBasedThreshold?: ChargeScheduleBasedThreshold | undefined;
  minBalanceDue?: SerializesInto<Money> | undefined;
  minChargeScheduleBasedThreshold?: ChargeScheduleBasedThreshold | undefined;
  numDaysPastRentDue: number;
  ordering?: number | undefined;
  processType?: CollectionsStepProcessType | undefined;
  taskTitle?: string | undefined;
  templateId?: EnderId | undefined;
};

type CollectionsAPICreateStepPayload = CollectionsAPICreateStepSearchParams &
  CollectionsAPICreateStepBodyParams;

function createStepUnsafeEffect(payload: CollectionsAPICreateStepPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsStep>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsStep>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/collections/steps",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createStep(
  payload: CollectionsAPICreateStepPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsStep> {
  return F.pipe(
    payload,
    createStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createStep };
export type {
  CollectionsAPICreateStepBodyParams,
  CollectionsAPICreateStepPayload,
  CollectionsAPICreateStepSearchParams,
};
