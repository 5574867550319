// eslint-disable-next-line ender-rules/deprecated-import-libraries
import type { MultiSelectProps } from "@mantine/core";
// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { MultiSelect } from "@mantine/core";

function EnderMultiSelect(props: MultiSelectProps) {
  return <MultiSelect {...props} />;
}

export { EnderMultiSelect };
