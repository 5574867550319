/**
 * returns a single prospect by id
 * HTTP Method: GET
 * Pathname: /leads/{leadId}
 * @function getLead
 * @memberof LeasingAPI
 * @param {LeasingAPIGetLeadPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetLeadResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetLeadResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetLeadPathParams = {
  leadId: EnderId;
};

type LeasingAPIGetLeadSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetLeadPayload = LeasingAPIGetLeadPathParams &
  LeasingAPIGetLeadSearchParams;

function getLeadUnsafeEffect(payload: LeasingAPIGetLeadPayload) {
  const { leadId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetLeadResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetLeadResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leads/${leadId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLead(
  payload: LeasingAPIGetLeadPayload,
  options?: { signal?: AbortSignal },
): Promise<GetLeadResponse> {
  return F.pipe(
    payload,
    getLeadUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLead };
export type {
  LeasingAPIGetLeadPathParams,
  LeasingAPIGetLeadPayload,
  LeasingAPIGetLeadSearchParams,
};
