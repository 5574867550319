import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";

import type { EnderId } from "@ender/shared/core";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import { ApprovalProcessTypeEnum } from "@ender/shared/generated/ender.model.approvals";

function useApprovalProcesses({
  approvalProcessType,
  ownershipGroupId,
}: {
  approvalProcessType: ApprovalProcessType;
  ownershipGroupId?: EnderId;
}) {
  const { data: approvalProcesses, refetch } = useQuery({
    enabled:
      approvalProcessType == ApprovalProcessTypeEnum.ESTIMATE
        ? P.isNotNullable(ownershipGroupId)
        : true,
    queryFn: ({ signal }) =>
      ApprovalsAPI.searchApprovalProcesses(
        { ownershipGroupId, type: approvalProcessType },
        { signal },
      ),
    queryKey: [
      "ApprovalsAPI.searchApprovalProcesses",
      { ownershipGroupId, type: approvalProcessType },
    ] as const,
  });

  return { approvalProcesses, refetchApprovalProcesses: refetch };
}

export { useApprovalProcesses };
