import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const ProspectsListTabValues = ["CURRENT", "ARCHIVED"] as const;
const ProspectsListTabSchema = z.enum(ProspectsListTabValues);
type ProspectsListTab = z.infer<typeof ProspectsListTabSchema>;

/**
 * @enum {string} ProspectsListTabEnum
 * @description The values for each tab of prospects list
 */
const ProspectsListTabEnum = castEnum<ProspectsListTab>(ProspectsListTabSchema);

export { ProspectsListTabEnum, ProspectsListTabSchema, ProspectsListTabValues };
export type { ProspectsListTab };
