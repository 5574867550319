import { clsx } from "clsx";
import type { CSSProperties } from "react";
import { useLocation } from "react-router-dom";

import { uploadFiles } from "@ender/shared/api/files";
import { uploadTenantCharges } from "@ender/shared/api/leases";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { H2 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Tuple } from "@ender/shared/ds/tuple";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { FileUploadForm } from "@ender/shared/ui/modals";
import { ScreenRangeEnum, useScreenRange } from "@ender/shared/ui/screen-size";
import { UploadRail, UploadRailHeader } from "@ender/shared/ui/upload-rail";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import styles from "./invoice-file-upload.module.css";

function TenantChargesUploadRailHeader() {
  return (
    <UploadRailHeader
      header="Upload one or multiple tenant charges as a single .csv file."
      sampleFileUrl="/sample-tenant-charges-upload.csv">
      <>
        <Tuple label="Date" value="YYYY-MM-DD" />
        <Tuple label="Period" value="YYYY-MM" />
        <Tuple label="Account Number" value="Valid GL account number" />
        <Tuple label="Property Code" value="Valid Property Code" />
        <Tuple label="Lease ID" value="Valid Lease ID" />
        <Tuple label="Line Description" value="Text string" />
        <Tuple label="Amount" value="Dollar amount, no symbols" />
      </>
    </UploadRailHeader>
  );
}

type InvoiceUploadProps = {
  pmId: EnderId;
  invoiceType: InvoiceInvoiceType;
  apEmailAddress: string;
  style?: { gridArea?: CSSProperties["gridArea"] };
  arEmailAddress: string;
  selectedInvoiceCount: number;
  onDeselectAll: () => void;
  onUploadInvoicePage: () => void;
};

function InvoiceFileUpload({
  pmId,
  invoiceType,
  apEmailAddress,
  arEmailAddress,
  selectedInvoiceCount,
  style,
  onDeselectAll,
  onUploadInvoicePage,
}: InvoiceUploadProps) {
  const { pathname } = useLocation();
  const isReceivablePage = pathname.includes("/accounts-receivable");
  const screenRange = useScreenRange();
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean(false);
  const [
    isUploadTenantChargesOpen,
    { setTrue: openUploadTenantCharges, setFalse: closeUploadTenantCharges },
  ] = useBoolean(false);

  function uploadInvoiceFile(files: File[]) {
    return uploadFiles({
      files,
      uploadType:
        invoiceType == InvoiceInvoiceTypeEnum.PAYABLE ? "AP_IMAGE" : "AR_IMAGE",
      modelType: "PROPERTY_MANAGER",
      modelId: pmId,
    });
  }

  async function submitTenantChargesUpload(files: File[]) {
    await uploadTenantCharges(files);
  }

  const emailAddress =
    invoiceType == InvoiceInvoiceTypeEnum.PAYABLE
      ? apEmailAddress
      : arEmailAddress;
  const headingContent = !emailAddress ? (
    "Upload new invoice images here (PDF or image file)"
  ) : (
    <>
      Email invoices to{" "}
      <a
        href={`mailto:${emailAddress}`}
        target="_blank"
        className={styles.email}
        rel="noreferrer">
        {emailAddress}
      </a>{" "}
      or
    </>
  );

  const isMobile = screenRange !== ScreenRangeEnum.LARGE;

  return (
    <div className={styles.invoiceFileUpload} style={style}>
      <H2>{headingContent}</H2>
      <div
        className={clsx({ [styles.isMobile]: isMobile }, styles.buttonGroup)}>
        <Button onClick={openModal}>Upload File</Button>
        <Button
          disabled={selectedInvoiceCount < 1}
          disabledTooltip="No invoice(s) selected"
          onClick={onDeselectAll}>
          Deselect All
        </Button>
        {isReceivablePage && (
          <Button onClick={openUploadTenantCharges}>
            Upload Tenant Charges
          </Button>
        )}
      </div>

      <Modal title="" opened={isModalOpen} onClose={closeModal}>
        <FileUploadForm
          title="Upload file"
          uploadFiles={uploadInvoiceFile}
          onSuccess={() => {
            closeModal();
            onUploadInvoicePage();
          }}
        />
      </Modal>

      <UploadRail
        title="Upload Tenant Charges"
        header={<TenantChargesUploadRailHeader />}
        opened={isUploadTenantChargesOpen}
        onClose={closeUploadTenantCharges}
        onSubmit={submitTenantChargesUpload}
        onSuccess={() =>
          showSuccessNotification({
            message: "Tenant Charge Upload Successful",
          })
        }
      />
    </div>
  );
}

export { InvoiceFileUpload };
