/**
 * HTTP Method: GET
 * Pathname: /buy/boxes/{firmId}
 * @function getBuyBoxes
 * @memberof BuyBoxAPI
 * @param {BuyBoxAPIGetBuyBoxesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyBoxAPIGetBuyBoxesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyBoxAPIGetBuyBoxesResponse } from "../buy-box-api-get-buy-boxes-response";

type BuyBoxAPIGetBuyBoxesPathParams = {
  firmId: EnderId;
};

type BuyBoxAPIGetBuyBoxesSearchParams = {
  token?: string | undefined;
};

type BuyBoxAPIGetBuyBoxesPayload = BuyBoxAPIGetBuyBoxesPathParams &
  BuyBoxAPIGetBuyBoxesSearchParams;

function getBuyBoxesUnsafeEffect(payload: BuyBoxAPIGetBuyBoxesPayload) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyBoxAPIGetBuyBoxesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<BuyBoxAPIGetBuyBoxesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/boxes/${firmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBuyBoxes(
  payload: BuyBoxAPIGetBuyBoxesPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyBoxAPIGetBuyBoxesResponse[]> {
  return F.pipe(
    payload,
    getBuyBoxesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBuyBoxes };
export type {
  BuyBoxAPIGetBuyBoxesPathParams,
  BuyBoxAPIGetBuyBoxesPayload,
  BuyBoxAPIGetBuyBoxesSearchParams,
};
