import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ListingListingStatusValues = ["LISTED", "UNLISTED", "DRAFTING"] as const;

const ListingListingStatusEffectSchema = Schema.Literal(
  ...ListingListingStatusValues,
);

type ListingListingStatus = Schema.Schema.Type<
  typeof ListingListingStatusEffectSchema
>;

const ListingListingStatusEnum = castEnum(ListingListingStatusEffectSchema);

function randomListingListingStatus(): ListingListingStatus {
  return rand(ListingListingStatusValues);
}

export {
  ListingListingStatusEffectSchema,
  ListingListingStatusEnum,
  ListingListingStatusValues,
  randomListingListingStatus,
};
export type { ListingListingStatus };
