import { useCallback, useEffect, useState } from "react";

import { TextInput } from "@ender/shared/ds/text-input";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import { Size } from "@ender/shared/utils/theming";

type DescriptionFilterProps = {
  onChange: (value: string) => void;
  value: string;
};

/**
 * 2024-05-07 Geoff: This was based heavily on invoices-table-page/invoices-filters/invoices-filters.tsx components
 * Which should probably be refactored into shared components and DRY'd w shared base components
 */
function DescriptionFilter({ onChange, value = "" }: DescriptionFilterProps) {
  const [description, setDescription] = useState(value);

  // Update the local state when the external `value` prop changes
  useEffect(() => {
    setDescription(value);
  }, [value]);

  const onDebounceChange = useDebounce((debouncedAmount: string) => {
    onChange(debouncedAmount);
    setDescription(value);
  }, 750);

  const onDescriptionChange = useCallback(
    (val: string) => {
      setDescription(val);
      onDebounceChange(val);
    },
    [onDebounceChange],
  );

  return (
    <TextInput
      label="Description"
      name="description_filter"
      onChange={onDescriptionChange}
      placeholder="Search Description"
      value={description}
      size={Size.sm}
    />
  );
}

export { DescriptionFilter };
