// eslint-disable-next-line ender-rules/erroneous-import-packages
import {
  CheckboxItem,
  Content,
  Item,
  Portal,
  Root,
  Trigger,
} from "@radix-ui/react-dropdown-menu";
import { clsx } from "clsx";
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  PropsWithChildren,
  ReactNode,
} from "react";
import { forwardRef } from "react";

import { ListButton, ListCheckbox } from "./list-objects";





type MenuProps = {
  onOpenChange?: (open: boolean) => void;
};

function Menu(props: PropsWithChildren<MenuProps>) {
  const { children, onOpenChange } = props;
  return <Root onOpenChange={onOpenChange}>{children}</Root>;
}

type MenuTriggerProps = {
  //
};

function MenuTrigger(props: PropsWithChildren<MenuTriggerProps>) {
  const { children } = props;
  return (
    <Trigger
      asChild
      onClick={(e) => e.stopPropagation()}
      className="group/menu grid">
      {children}
    </Trigger>
  );
}

type MenuContentProps = {
  align?: "start" | "center" | "end";
};

const MenuContent = forwardRef<
  ElementRef<typeof Content>,
  PropsWithChildren<MenuContentProps>
>(function MenuContent(props, ref) {
  const { children, align = "center" } = props;
  return (
    <Portal>
      <Content
        ref={ref}
        sideOffset={4}
        collisionPadding={16}
        align={align}
        onClick={(e) => e.stopPropagation()}
        className={clsx(
          "flex-col gap-0 overflow-auto",
          "bg-white will-change-transform border border-primary-300 rounded max-h-80 z-10",
          "data-[state=open]:flex data-[state=closed]:hidden",
        )}>
        {children}
      </Content>
    </Portal>
  );
});

type MenuButtonProps = {
  /**
   * if true, the menu will not close when the button is clicked
   */
  preventClose?: boolean;
} & Pick<ComponentPropsWithoutRef<typeof ListButton>, "color" | "disabled"> &
  Pick<ComponentPropsWithoutRef<typeof Item>, "onClick">;

const MenuButton = forwardRef<
  ElementRef<typeof Item>,
  PropsWithChildren<MenuButtonProps>
>(function MenuButton(props, ref) {
  const { onClick, children, preventClose, color, disabled } = props;
  return (
    <Item
      ref={ref}
      onClick={onClick}
      className="select-none outline-none"
      disabled={disabled}
      onSelect={(e) => preventClose && e.preventDefault()}>
      <ListButton disabled={disabled} color={color}>
        {children}
      </ListButton>
    </Item>
  );
});

type MenuCheckboxProps = {
  label?: ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
  /**
   * if true, the menu will not close when the checkbox is clicked
   */
  preventClose?: boolean;
} & Pick<ComponentPropsWithoutRef<typeof ListCheckbox>, "disabled">;
const MenuCheckbox = forwardRef<
  ElementRef<typeof CheckboxItem>,
  MenuCheckboxProps
>(function MenuCheckbox(props, ref) {
  const { label, value, onChange, preventClose = false, disabled } = props;
  return (
    <CheckboxItem
      ref={ref}
      checked={value}
      className="select-none outline-none"
      onSelect={(e) => {
        preventClose ? e.preventDefault() : onChange(!value);
      }}
      disabled={disabled}>
      <ListCheckbox
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </CheckboxItem>
  );
});

export { Menu, MenuButton, MenuCheckbox, MenuContent, MenuTrigger };

export type { MenuButtonProps, MenuContentProps, MenuProps };
