import { forwardRef } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";

type MapPinIconProps = {
  fill?: string;
  dotFill?: string;
  dotStroke?: string;
  stroke?: string;
  strokeWidth?: number;
};

const MapPinIcon = forwardRef<SVGSVGElement, MapPinIconProps>(
  function MapPinIcon(
    {
      fill = EnderThemeColorEnum.PURPLE_500,
      dotFill = EnderThemeColorEnum.WHITE,
      strokeWidth = 1,
      stroke = EnderThemeColorEnum.PURPLE_500,
      dotStroke = "none",
    },
    ref,
  ) {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-map-pin"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill={fill}
        strokeLinejoin="miter">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M 17.657 16.657 L 12 22.314 L 6.343 16.657 a 8 8 0 1 1 11.314 0 Z"></path>
        <path
          d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"
          fill={dotFill}
          stroke={dotStroke}></path>
      </svg>
    );
  },
);

export { MapPinIcon };
