import { Slider as MantineSlider } from "@mantine/core";
import { forwardRef, useMemo } from "react";
import { z } from "zod";

import { generateGeneralMantinePropsSchema } from "@ender/shared/forms/types/general";
import { ContextAwareInput } from "@ender/shared/forms/ui/context-aware-input";
import type { LabelValue } from "@ender/shared/types/label-value";
import { genTestId } from "@ender/shared/utils/string";

const SliderBasePropsSchema = z.object({
  inverted: z.boolean().optional(),
  marks: z.array(z.custom<LabelValue<number>>()).optional(),
  max: z.number(),
  min: z.number(),
  sliderLabel: z.string().optional(),
  step: z.number().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SliderPropsSchema = generateGeneralMantinePropsSchema(z.number()).merge(
  SliderBasePropsSchema,
);

type SliderProps = z.infer<typeof SliderPropsSchema>;

/**
 * Calculates the step size for a range of values.
 * @param {number} range - The range of values.
 * @returns {number} The step size.
 */
function getStep(range: number): number {
  if (range < 100) {
    return 1;
  } else if (range > 1000) {
    return 50;
  } else if (range >= 100) {
    return 10;
  }

  return 1;
}

/**
 * Generates an array of default marks for a slider.
 * @param {MinMax} param - An object containing the minimum and maximum values for the slider.
 * @param {number} param.min - The minimum value for the slider.
 * @param {number} param.max - The maximum value for the slider.
 * @returns {Array<{ value: number, label: string }>} An array of default marks.
 */
function genDefaultMarks({
  min,
  max,
}: {
  min: number;
  max: number;
}): LabelValue<number>[] {
  return [
    { value: min, label: `${min}` },
    { value: (max + min) / 2, label: `${Math.round((max + min) / 2)}` },
    { value: max, label: `${max}` },
  ];
}

/**
 * Generates an array of default marks for a slider and memoizes the result.
 * @param {MinMax} param - An object containing the minimum and maximum values for the slider.
 * @param {number} param.min - The minimum value for the slider.
 * @param {number} param.max - The maximum value for the slider.
 * @returns {Array<{ value: number, label: string }>} An array of default marks.
 */
function useDefaultMarks({
  min,
  max,
}: {
  min: number;
  max: number;
}): LabelValue<number>[] {
  return useMemo(() => genDefaultMarks({ min, max }), [min, max]);
}

const sliderStyles = {
  label: { minWidth: 30, textAlign: "center" },
} as const;

const Slider = forwardRef<HTMLDivElement, SliderProps>(function Slider(
  props: SliderProps,
  ref,
) {
  const {
    inverted,
    marks,
    max,
    min,
    name,
    onChange,
    sliderLabel,
    step: _step,
    testId,
    value,
  } = props;
  const step = _step || getStep(max - min);
  const defaultMarks = useDefaultMarks({ min, max });
  return (
    <MantineSlider
      data-testid={genTestId("ender-slider", testId)}
      inverted={inverted}
      label={sliderLabel}
      marks={marks || defaultMarks}
      max={max}
      min={min}
      name={name}
      onChange={onChange}
      radius={8}
      ref={ref}
      step={step}
      styles={sliderStyles}
      value={value}
    />
  );
});

/** @deprecated This should not be used anymore. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function EnderSlider(props: any) {
  const { name, ...rest } = props;
  return <ContextAwareInput Component={Slider} name={name} props={rest} />;
}

export { EnderSlider };
