import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskHistoryResponseTaskEventColumnValues = [
  "CREATED",
  "STATUS",
  "COST_ESTIMATE",
  "COST_ESTIMATE_APPROVAL_STATUS",
  "TARGET_DATE",
  "TARGET_TIME",
  "SCHEDULED_DATE",
  "SCHEDULED_TIME",
  "DESCRIPTION",
  "PRIORITY",
  "INTERNAL_ASSIGNEE_ID",
  "VENDOR_ASSIGNEE_ID",
  "VENDOR_ID",
  "ASSIGNMENT_ASSIGNED",
  "ASSIGNMENT_UNASSIGNED",
  "ASSIGNMENT_ACCEPTED",
  "ASSIGNMENT_REJECTED",
  "COST_ESTIMATE_APPROVED",
  "COST_ESTIMATE_REJECTED",
  "TENANT_CHARGEBACK",
] as const;

const TaskHistoryResponseTaskEventColumnEffectSchema = Schema.Literal(
  ...TaskHistoryResponseTaskEventColumnValues,
);

type TaskHistoryResponseTaskEventColumn = Schema.Schema.Type<
  typeof TaskHistoryResponseTaskEventColumnEffectSchema
>;

const TaskHistoryResponseTaskEventColumnEnum = castEnum(
  TaskHistoryResponseTaskEventColumnEffectSchema,
);

function randomTaskHistoryResponseTaskEventColumn(): TaskHistoryResponseTaskEventColumn {
  return rand(TaskHistoryResponseTaskEventColumnValues);
}

export {
  randomTaskHistoryResponseTaskEventColumn,
  TaskHistoryResponseTaskEventColumnEffectSchema,
  TaskHistoryResponseTaskEventColumnEnum,
  TaskHistoryResponseTaskEventColumnValues,
};
export type { TaskHistoryResponseTaskEventColumn };
