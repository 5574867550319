import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useMemo } from "react";

import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";

function useApprovalProcessSteps(invoiceType: InvoiceInvoiceType) {
  const { data: approvalProcesses } = useQuery({
    enabled: P.isNotNullable(invoiceType),
    queryFn: ({ signal }) =>
      ApprovalsAPI.searchApprovalProcesses({ type: invoiceType }, { signal }),
    queryKey: ["ApprovalsAPI.searchApprovalProcesses", invoiceType] as const,
  });

  const approvalProcessSteps = useMemo(() => {
    if (P.isUndefined(approvalProcesses)) {
      return [];
    }

    return [
      approvalProcesses.defaultApprovalProcess?.steps,
      ...Object.values(approvalProcesses.approvalProcessById).map(
        (approvalProcess) => approvalProcess?.steps,
      ),
    ].flat() as GetApprovalProcessResponseStep[];
  }, [approvalProcesses]);

  return approvalProcessSteps;
}

export { useApprovalProcessSteps };
