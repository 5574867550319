import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import { useCallback } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import {
  EmailEffectSchema,
  PhoneEffectSchema,
} from "@ender/form-system/schema";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { BuyAPIGetBrokersResponse } from "@ender/shared/generated/com.ender.buy.api";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";

type BrokerModalProps = {
  broker?: BuyAPIGetBrokersResponse;
  onSuccess: (broker: BuyAPIGetBrokersResponse) => void;
};

const BrokerModalFormSchema = Schema.Struct({
  brokerId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  firstName: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "First name is required" }),
  ),
  lastName: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Last name is required" }),
  ),
  phone: PhoneEffectSchema.pipe(
    Schema.nonEmptyString({ message: () => "Phone is required" }),
  ),
  email: EmailEffectSchema.pipe(
    Schema.nonEmptyString({ message: () => "Invalid email" }),
  ),
});

type BrokerModalFormValues = Schema.Schema.Type<typeof BrokerModalFormSchema>;

function AddEditBrokerForm({
  broker,
  onSuccess = F.constVoid,
}: BrokerModalProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      brokerId: O.fromNullable(broker?.id),
      email: broker?.email ?? "",
      firstName: broker?.firstName ?? "",
      lastName: broker?.lastName ?? "",
      phone: broker?.phone ?? "",
    },
    schema: BrokerModalFormSchema,
  });

  const { mutateAsync: updateBrokerDetails, isLoading: isUpdatingBroker } =
    useMutation({
      mutationFn: (values: BrokerModalFormValues) => {
        return BuyAPI.updateBrokerDetails({
          ...values,
          brokerId: O.getOrThrow(values.brokerId),
        });
      },
      mutationKey: ["BuyAPI.updateBrokerDetails"] as const,
    });

  const { mutateAsync: saveBroker, isLoading: isCreatingBroker } = useMutation({
    mutationFn: (values: BrokerModalFormValues) => {
      return BuyAPI.saveBroker(values);
    },
    mutationKey: ["BuyAPI.saveBroker"] as const,
  });

  const handleSubmit = useCallback(
    async (values: BrokerModalFormValues) => {
      let broker: BuyAPIGetBrokersResponse;
      if (O.isSome(values.brokerId)) {
        broker = await updateBrokerDetails(values);
      } else {
        broker = await saveBroker(values);
      }
      onSuccess(broker);
    },
    [updateBrokerDetails, saveBroker, onSuccess],
  );

  const isSubmitting = isUpdatingBroker || isCreatingBroker;
  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormTextInput form={form} name="firstName" label="First Name" />
        <FormTextInput form={form} name="lastName" label="Last Name" />
        <FormPhoneInput form={form} name="phone" label="Phone #" />
        <FormTextInput form={form} name="email" label="Email" />
        <Button type="submit" loading={isSubmitting}>
          Save Broker
        </Button>
      </Stack>
    </Form>
  );
}

export { AddEditBrokerForm };
