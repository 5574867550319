/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/properties
 * @function getInspectionEligibleProperties
 * @memberof InspectionAPI
 * @param {InspectionAPIGetInspectionEligiblePropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionEligiblePropertiesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetInspectionEligiblePropertiesResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetInspectionEligiblePropertiesSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetInspectionEligiblePropertiesPayload =
  InspectionAPIGetInspectionEligiblePropertiesSearchParams;

function getInspectionEligiblePropertiesUnsafeEffect(
  payload: InspectionAPIGetInspectionEligiblePropertiesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionEligiblePropertiesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          GetInspectionEligiblePropertiesResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/construction/inspections/properties",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInspectionEligibleProperties(
  payload: InspectionAPIGetInspectionEligiblePropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionEligiblePropertiesResponse[]> {
  return F.pipe(
    payload,
    getInspectionEligiblePropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInspectionEligibleProperties };
export type {
  InspectionAPIGetInspectionEligiblePropertiesPayload,
  InspectionAPIGetInspectionEligiblePropertiesSearchParams,
};
