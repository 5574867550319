import { Schema } from "@effect/schema";
import { Option as O } from "effect";
import { useCallback } from "react";

import type { Null } from "@ender/shared/constants/general";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { InputSize } from "@ender/shared/ds/input";
import { Select } from "@ender/shared/ds/select";
import type { SelectOption } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import type { MinimalPropertyResponse } from "@ender/shared/generated/ender.api.model";
import { useAccountingFiltersStore } from "@ender/shared/stores/accounting-filters-store";
import { castEnum } from "@ender/shared/utils/effect";
import { AccountingPeriodFilter } from "@ender/widgets/filters/accounting-period-filter";
import { PropertyFilter } from "@ender/widgets/filters/property-filter";

import { DateFilters } from "../../../options/date-filters";
import { AmountFilter } from "./amount-filter";
import { DescriptionFilter } from "./description-filter";

const TransactionTypeValues = [
  "DRAWS_AND_DEPOSITS",
  "DRAWS",
  "DEPOSITS",
] as const;
const TransactionTypeEffectSchema = Schema.Literal(...TransactionTypeValues);
type TransactionType = Schema.Schema.Type<typeof TransactionTypeEffectSchema>;
const TransactionTypeEnum = castEnum(TransactionTypeEffectSchema);

const transactionTypeConfig: Record<
  TransactionType,
  { deposit: boolean; draw: boolean }
> = {
  [TransactionTypeEnum.DRAWS_AND_DEPOSITS]: { deposit: true, draw: true },
  [TransactionTypeEnum.DRAWS]: { deposit: false, draw: true },
  [TransactionTypeEnum.DEPOSITS]: { deposit: true, draw: false },
};

type BankingDetailFiltersProps = {
  amountFilterValue: string;
  descriptionFilterValue: string;
  handleTransactionTypeChange: (val: TransactionType | Null) => void;
  onAmountFilterChange: (value: string) => void;
  onDescriptionFilterChange: (value: string) => void;
  onPropertyFilterChange: (
    value: SelectOption<EnderId, MinimalPropertyResponse>[],
  ) => void;
  propertyFilterValue: SelectOption<EnderId, MinimalPropertyResponse>[];
  transactionType: TransactionType;
};

function BankingDetailFilters({
  amountFilterValue,
  descriptionFilterValue,
  handleTransactionTypeChange,
  onAmountFilterChange,
  onDescriptionFilterChange,
  onPropertyFilterChange,
  propertyFilterValue,
  transactionType,
}: BankingDetailFiltersProps) {
  const {
    periodFilter,
    startDate,
    endDate,
    updateStartDate,
    updateEndDate,
    updatePeriodFilter,
  } = useAccountingFiltersStore();

  const handlePeriodFilterChange = useCallback(
    (val: LocalDate$.LocalDate[]) => {
      const _periods = val.map((period) => period.toJSON());
      updatePeriodFilter(_periods);
    },
    [updatePeriodFilter],
  );

  return (
    <Group>
      <Stack spacing={Spacing.sm}>
        <Group align={Align.end}>
          <AccountingPeriodFilter
            value={periodFilter.map((period) => LocalDate$.of(period))}
            onChange={handlePeriodFilterChange}
          />
          <DateFilters
            startDate={startDate}
            endDate={endDate}
            updateEndDate={(value) => updateEndDate(value as LocalDate)}
            updateStartDate={(value) => updateStartDate(value as LocalDate)}
          />
        </Group>
        <Group align={Align.end}>
          <Select
            data={[
              {
                label: "Draws and Deposits",
                value: TransactionTypeEnum.DRAWS_AND_DEPOSITS,
              },
              { label: "Draws Only", value: TransactionTypeEnum.DRAWS },
              { label: "Deposits Only", value: TransactionTypeEnum.DEPOSITS },
            ]}
            label="Transaction Type"
            onChange={(value) =>
              handleTransactionTypeChange(value.pipe(O.getOrNull))
            }
            value={O.fromNullable(transactionType)}
            size={InputSize.sm}
          />
          <PropertyFilter
            onChange={onPropertyFilterChange}
            value={propertyFilterValue}
          />
          <AmountFilter
            onChange={onAmountFilterChange}
            value={amountFilterValue}
          />
          <DescriptionFilter
            onChange={onDescriptionFilterChange}
            value={descriptionFilterValue}
          />
        </Group>
      </Stack>
    </Group>
  );
}

export { BankingDetailFilters, transactionTypeConfig, TransactionTypeEnum };
export type { TransactionType };
