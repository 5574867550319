import { randStateAbbr } from "@ngneat/falso";
import { z } from "zod";

import { isStateAbbreviation } from "./state-abbreviations.types";

const StateSchema = z
  .string()
  .refine(isStateAbbreviation, { message: "Invalid state" })
  .brand("State");

type StateTypes = z.infer<typeof StateSchema>;

function randomState(): string {
  return randStateAbbr();
}

export { randomState, StateSchema };
export type { StateTypes };
