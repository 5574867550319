import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Function as F } from "effect";
import { useCallback, useState } from "react";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import {
  showLoadingNotification,
  showSuccessNotification,
} from "@ender/shared/utils/notifications";
import { pluralize } from "@ender/shared/utils/string";

import type { AllApplicationsRow } from "./all-applications-table.types";

type UseBulkArchiveProps = {
  clearRowSelection?: () => void;
  refetchTable: () => Promise<void>;
};

type UseBulkArchiveReturn = {
  bulkArchive: (selectedRows: AllApplicationsRow[]) => void;
  isBulkArchiveLoading: boolean;
};

function useBulkArchive(props: UseBulkArchiveProps): UseBulkArchiveReturn {
  const { clearRowSelection = F.constVoid, refetchTable } = props;

  const [archiving, setArchiving] = useState(false);

  const confirmation = useConfirmationContext();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: async (applicationGroupIds: EnderId[]) => {
      await Promise.all(
        applicationGroupIds.map((applicationGroupId) =>
          ApplicationsAPI.archiveApplication({ applicationGroupId }),
        ),
      );
    },
    mutationKey: ["archive"],
  });

  const handleArchive = useCallback(
    async (selectedRows: AllApplicationsRow[]) => {
      setArchiving(true);
      try {
        await confirmation({
          confirmButtonLabel: "Yes, Archive Applications",
          content: "This will move applications to Archived.",
          title: `Are you sure you would like to archive ${selectedRows.length} ${pluralize("application", selectedRows.length)}?`,
        });
      } catch {
        setArchiving(false);
        return;
      }

      const [, clearLoadingNotification] = showLoadingNotification({
        message: `Archiving ${selectedRows.length} ${pluralize("application", selectedRows.length)}...`,
      });

      const applicationGroupIds = selectedRows.map(
        (row) => row.applicationGroupId,
      );
      await mutateAsync(applicationGroupIds);

      queryClient.invalidateQueries([
        "searchApplicationsPageTable",
        "applicationsTableTabCounts",
      ]);
      clearRowSelection();
      setArchiving(false);
      await refetchTable();
      clearLoadingNotification();
      showSuccessNotification({
        message: `Successfully archived ${selectedRows.length} ${pluralize("application", selectedRows.length)}.`,
      });
    },
    [clearRowSelection, confirmation, mutateAsync, queryClient, refetchTable],
  );

  return {
    bulkArchive: handleArchive,
    isBulkArchiveLoading: archiving,
  };
}

export { useBulkArchive };
