/**
 * HTTP Method: GET
 * Pathname: /investors/{firmId}/allocations
 * @function getInvestorAllocations
 * @memberof BuyAPI
 * @param {BuyAPIGetInvestorAllocationsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIGetInvestorAllocationsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIGetInvestorAllocationsResponse } from "../buy-api-get-investor-allocations-response";

type BuyAPIGetInvestorAllocationsPathParams = {
  firmId: EnderId;
};

type BuyAPIGetInvestorAllocationsSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetInvestorAllocationsPayload =
  BuyAPIGetInvestorAllocationsPathParams &
    BuyAPIGetInvestorAllocationsSearchParams;

function getInvestorAllocationsUnsafeEffect(
  payload: BuyAPIGetInvestorAllocationsPayload,
) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIGetInvestorAllocationsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          BuyAPIGetInvestorAllocationsResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/investors/${firmId}/allocations`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvestorAllocations(
  payload: BuyAPIGetInvestorAllocationsPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIGetInvestorAllocationsResponse[]> {
  return F.pipe(
    payload,
    getInvestorAllocationsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvestorAllocations };
export type {
  BuyAPIGetInvestorAllocationsPathParams,
  BuyAPIGetInvestorAllocationsPayload,
  BuyAPIGetInvestorAllocationsSearchParams,
};
