import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionZoneZoneNotApplicableReasonValues = [
  "DOES_NOT_EXIST",
  "PROPERTY_MODIFIED",
  "ADDED_BY_MISTAKE",
  "OTHER",
] as const;

const InspectionZoneZoneNotApplicableReasonEffectSchema = Schema.Literal(
  ...InspectionZoneZoneNotApplicableReasonValues,
);

type InspectionZoneZoneNotApplicableReason = Schema.Schema.Type<
  typeof InspectionZoneZoneNotApplicableReasonEffectSchema
>;

const InspectionZoneZoneNotApplicableReasonEnum = castEnum(
  InspectionZoneZoneNotApplicableReasonEffectSchema,
);

function randomInspectionZoneZoneNotApplicableReason(): InspectionZoneZoneNotApplicableReason {
  return rand(InspectionZoneZoneNotApplicableReasonValues);
}

export {
  InspectionZoneZoneNotApplicableReasonEffectSchema,
  InspectionZoneZoneNotApplicableReasonEnum,
  InspectionZoneZoneNotApplicableReasonValues,
  randomInspectionZoneZoneNotApplicableReason,
};
export type { InspectionZoneZoneNotApplicableReason };
