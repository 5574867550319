/**
 * HTTP Method: POST
 * Pathname: /accounting/journal-entries/attachment
 * @function createGLJournalEntryAttachments
 * @memberof AccountingAPI
 * @param {AccountingAPICreateGLJournalEntryAttachmentsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateGLJournalEntryAttachmentResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { CreateGLJournalEntryAttachmentResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPICreateGLJournalEntryAttachmentsSearchParams = {
  token?: string | undefined;
};

type AccountingAPICreateGLJournalEntryAttachmentsBodyParams = {
  journalEntryId: EnderId;
};

type AccountingAPICreateGLJournalEntryAttachmentsPayload =
  AccountingAPICreateGLJournalEntryAttachmentsSearchParams &
    AccountingAPICreateGLJournalEntryAttachmentsBodyParams;

function createGLJournalEntryAttachmentsUnsafeEffect(
  payload: AccountingAPICreateGLJournalEntryAttachmentsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateGLJournalEntryAttachmentResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<CreateGLJournalEntryAttachmentResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/journal-entries/attachment",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createGLJournalEntryAttachments(
  payload: AccountingAPICreateGLJournalEntryAttachmentsPayload,
  options?: { signal?: AbortSignal },
): Promise<CreateGLJournalEntryAttachmentResponse> {
  return F.pipe(
    payload,
    createGLJournalEntryAttachmentsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createGLJournalEntryAttachments };
export type {
  AccountingAPICreateGLJournalEntryAttachmentsBodyParams,
  AccountingAPICreateGLJournalEntryAttachmentsPayload,
  AccountingAPICreateGLJournalEntryAttachmentsSearchParams,
};
