import { clsx } from "clsx";
import { forwardRef } from "react";

type LoadingSpinnerProps = {
  //TODO identify the best way to assign these to the CSS loader
  // strokeWidth?:number;
  // size?:number;
};

const LoadingSpinner = forwardRef<HTMLSpanElement, LoadingSpinnerProps>(
  function LoadingSpinner(props, ref) {
    // const { strokeWidth = 4, size = 20 } = props;
    return (
      <span
        role="progressbar"
        ref={ref}
        className={clsx(
          "[--loader-size:20px] block w-[--loader-size] h-[--loader-size]",
          "after:border-[2.5px] after:rounded-full after:border-current after:border-b-transparent after:border-l-transparent",
          "after:block after:w-[--loader-size] after:h-[--loader-size]",
          "after:animate-spin",
        )}
      />
    );
  },
);

export { LoadingSpinner };

export type { LoadingSpinnerProps };
