import { Array as A } from "effect";

import type { EnderId } from "@ender/shared/core";
import { isSingle } from "@ender/shared/utils/is";

import { getInvoicePropertyIds } from "./get-invoice-property-ids";

function getPropertyShortNameFromPropertiesLength(numProperties: number) {
  return `${numProperties || "Multiple"} Properties`;
}

function getPropertyShortNameFromInvoice(invoice: {
  currentState: {
    allocationsByProperty: { propertyId: EnderId }[];
  };
}): string {
  return getPropertyShortNameFromPropertiesLength(
    invoice.currentState?.allocationsByProperty?.length,
  );
}

function getPropertyShortNameFromInvoices(
  invoices: {
    currentState: {
      allocationsByProperty: { propertyId: EnderId }[];
    };
    property?: {
      friendlyId?: string;
      name: string;
    };
  }[],
): string {
  if (A.isEmptyArray(invoices)) {
    return "";
  }

  if (isSingle(invoices)) {
    // TODO 2024-11-13 Geoffrey: I want better than "1 Property" here
    return getPropertyShortNameFromPropertiesLength(
      invoices[0].currentState?.allocationsByProperty?.length,
    );
  }

  // Multiple Invoices - determine unique property ids for the invoices
  const propertyIds = invoices.flatMap(
    (invoice) => getInvoicePropertyIds(invoice),
    [],
  );
  const uniquePropertyIds = Array.from(new Set(propertyIds));
  if (A.isEmptyArray(uniquePropertyIds)) {
    return "";
  }

  return getPropertyShortNameFromPropertiesLength(uniquePropertyIds.length);
}

export { getPropertyShortNameFromInvoice, getPropertyShortNameFromInvoices };
