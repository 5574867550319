/**
 * HTTP Method: POST
 * Pathname: /collections/process/{id}/steps
 * @function createStepForProcess
 * @memberof CollectionsAPI
 * @param {CollectionsAPICreateStepForProcessPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CollectionsStep>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalTime,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { ChargeScheduleBasedThreshold } from "@ender/shared/generated/ender.api.finance.request";
import type {
  CollectionsStep,
  CollectionsStepCollectionsActionType,
  CollectionsStepProcessType,
} from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPICreateStepForProcessPathParams = {
  id: EnderId;
};

type CollectionsAPICreateStepForProcessSearchParams = {
  token?: string | undefined;
};

type CollectionsAPICreateStepForProcessBodyParams = {
  actionTime?: SerializesInto<LocalTime> | undefined;
  actionType: CollectionsStepCollectionsActionType;
  automatedAfterNHours?: number | undefined;
  maxBalanceDue?: SerializesInto<Money> | undefined;
  maxChargeScheduleBasedThreshold?: ChargeScheduleBasedThreshold | undefined;
  minBalanceDue?: SerializesInto<Money> | undefined;
  minChargeScheduleBasedThreshold?: ChargeScheduleBasedThreshold | undefined;
  numDaysPastRentDue: number;
  ordering?: number | undefined;
  processType?: CollectionsStepProcessType | undefined;
  taskTitle?: string | undefined;
  templateId?: EnderId | undefined;
};

type CollectionsAPICreateStepForProcessPayload =
  CollectionsAPICreateStepForProcessPathParams &
    CollectionsAPICreateStepForProcessSearchParams &
    CollectionsAPICreateStepForProcessBodyParams;

function createStepForProcessUnsafeEffect(
  payload: CollectionsAPICreateStepForProcessPayload,
) {
  const { id, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CollectionsStep>({
        body,
        decode: unsafeDecodeJsonResponse<CollectionsStep>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/collections/process/${id}/steps`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createStepForProcess(
  payload: CollectionsAPICreateStepForProcessPayload,
  options?: { signal?: AbortSignal },
): Promise<CollectionsStep> {
  return F.pipe(
    payload,
    createStepForProcessUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createStepForProcess };
export type {
  CollectionsAPICreateStepForProcessBodyParams,
  CollectionsAPICreateStepForProcessPathParams,
  CollectionsAPICreateStepForProcessPayload,
  CollectionsAPICreateStepForProcessSearchParams,
};
