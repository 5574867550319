import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import type { ReactNode } from "react";
import { forwardRef, useCallback } from "react";

import type { ApplicationApprovalProps } from "@ender/entities/application/approval";
import { ApplicationApproval } from "@ender/entities/application/approval";
import type { EnderId } from "@ender/shared/core";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { ApplicationGroupApprovalResponseApplicationApprovalResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  ApplicationsAPI,
  ApplicationsAPIApplicationApprovalTypeEnum,
} from "@ender/shared/generated/ender.api.leasing";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

type ApprovableCardHeaderProps = {
  approval?: Pick<
    ApplicationGroupApprovalResponseApplicationApprovalResponse,
    "creditReportApproval" | "criminalReportApproval" | "evictionReportApproval"
  >;
  applicationGroupId: EnderId;
  applicationId: EnderId;
  heading?: ReactNode;
  onSuccess?: () => void;
} & Pick<ApplicationApprovalProps, "disabled">;

const ApprovableReportCardHeader = forwardRef<
  HTMLDivElement,
  ApprovableCardHeaderProps
>(function ApprovableCardHeader(props, ref) {
  const {
    approval,
    applicationGroupId,
    applicationId,
    heading,
    onSuccess = F.constVoid,
    disabled,
  } = props;

  const { mutateAsync: setApproval } = useMutation({
    mutationFn: ApplicationsAPI.applicationApproval,
    mutationKey: ["ApplicationsAPI.applicationApproval"] as const,
  });
  const handleApproval = useCallback(async () => {
    await Promise.all(
      [
        ApplicationsAPIApplicationApprovalTypeEnum.CREDIT_REPORT,
        ApplicationsAPIApplicationApprovalTypeEnum.CRIMINAL_REPORT,
        ApplicationsAPIApplicationApprovalTypeEnum.EVICTION_REPORT,
      ].map((approvalType) =>
        setApproval({
          applicationGroupId,
          applicationId,
          approvalType,
          approvalStatus: ApprovableApprovalStatusEnum.APPROVED,
        }),
      ),
    );
    onSuccess();
  }, [applicationGroupId, applicationId, onSuccess, setApproval]);
  const handleRejection = useCallback(async () => {
    await Promise.all(
      [
        ApplicationsAPIApplicationApprovalTypeEnum.CREDIT_REPORT,
        ApplicationsAPIApplicationApprovalTypeEnum.CRIMINAL_REPORT,
        ApplicationsAPIApplicationApprovalTypeEnum.EVICTION_REPORT,
      ].map((approvalType) =>
        setApproval({
          applicationGroupId,
          applicationId,
          approvalType,
          approvalStatus: ApprovableApprovalStatusEnum.REJECTED,
        }),
      ),
    );
    onSuccess();
  }, [applicationGroupId, applicationId, onSuccess, setApproval]);
  const handleUnset = useCallback(async () => {
    await Promise.all(
      [
        ApplicationsAPIApplicationApprovalTypeEnum.CREDIT_REPORT,
        ApplicationsAPIApplicationApprovalTypeEnum.CRIMINAL_REPORT,
        ApplicationsAPIApplicationApprovalTypeEnum.EVICTION_REPORT,
      ].map((approvalType) =>
        setApproval({
          applicationGroupId,
          applicationId,
          approvalType,
          approvalStatus: ApprovableApprovalStatusEnum.NEW,
        }),
      ),
    );
    onSuccess();
  }, [applicationGroupId, applicationId, onSuccess, setApproval]);

  return (
    <Group align={Align.center} ref={ref}>
      {heading}
      {P.isNotNullable(approval?.creditReportApproval) && (
        <ApplicationApproval
          disabled={disabled}
          approval={approval.creditReportApproval}
          onApprove={handleApproval}
          onReject={handleRejection}
          onClear={handleUnset}
        />
      )}
    </Group>
  );
});

export { ApprovableReportCardHeader };

export type { ApprovableCardHeaderProps };
