/**
 * HTTP Method: POST
 * Pathname: /invoices/{invoiceId}/allocations
 * @function allocateInvoiceOffsets
 * @memberof InvoicesAPI
 * @param {InvoicesAPIAllocateInvoiceOffsetsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { GLAllocation } from "@ender/shared/generated/ender.arch.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIAllocateInvoiceOffsetsPathParams = {
  invoiceId: EnderId;
};

type InvoicesAPIAllocateInvoiceOffsetsSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIAllocateInvoiceOffsetsBodyParams = {
  accountingPeriod?: SerializesInto<LocalDate> | undefined;
  allocations: GLAllocation[];
  invoiceAmount?: SerializesInto<Money> | undefined;
};

type InvoicesAPIAllocateInvoiceOffsetsPayload =
  InvoicesAPIAllocateInvoiceOffsetsPathParams &
    InvoicesAPIAllocateInvoiceOffsetsSearchParams &
    InvoicesAPIAllocateInvoiceOffsetsBodyParams;

function allocateInvoiceOffsetsUnsafeEffect(
  payload: InvoicesAPIAllocateInvoiceOffsetsPayload,
) {
  const { invoiceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/invoices/${invoiceId}/allocations`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function allocateInvoiceOffsets(
  payload: InvoicesAPIAllocateInvoiceOffsetsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    allocateInvoiceOffsetsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { allocateInvoiceOffsets };
export type {
  InvoicesAPIAllocateInvoiceOffsetsBodyParams,
  InvoicesAPIAllocateInvoiceOffsetsPathParams,
  InvoicesAPIAllocateInvoiceOffsetsPayload,
  InvoicesAPIAllocateInvoiceOffsetsSearchParams,
};
