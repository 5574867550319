import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Modal } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { AddZoneToAreaForm } from "./add-zone-to-area-form";
import { useCanEditAreasAndZones } from "./use-can-edit-areas-and-zones";

type AddUnitAreaButtonProps = {
  areaId: EnderId;
  areaName: string;
  unitId: EnderId;
};

function AddZoneToAreaButton({
  areaId,
  areaName,
  unitId,
}: AddUnitAreaButtonProps) {
  const { disabled, disabledTooltip } = useCanEditAreasAndZones(unitId);
  const [isAddZoneModalOpen, setIsAddZoneModalOpen] = useBoolean(false);

  return (
    <>
      <Button
        onClick={setIsAddZoneModalOpen.setTrue}
        variant={ButtonVariant.outlined}
        disabled={disabled}
        disabledTooltip={disabledTooltip}>
        Add Zone to {areaName}
      </Button>
      <Modal
        opened={isAddZoneModalOpen}
        onClose={setIsAddZoneModalOpen.setFalse}
        title={`Add Zone to ${areaName}`}>
        <AddZoneToAreaForm
          areaId={areaId}
          onClose={setIsAddZoneModalOpen.setFalse}
          unitId={unitId}
        />
      </Modal>
    </>
  );
}

export { AddZoneToAreaButton };
