import type { LocalDate } from "@ender/shared/core";
import type { EmptyObject } from "@ender/shared/types/general";
import type { EnderDateValue } from "@ender/shared/utils/ender-date";
import { EnderDate } from "@ender/shared/utils/ender-date";

import type { CellProps } from "../table.types";

function DateCell<RowData, Meta extends object = EmptyObject>(
  props: CellProps<RowData, LocalDate | EnderDateValue, Meta>,
) {
  const value = props.getValue();
  return value && EnderDate.of(value).toLongSlashDateString();
}

export { DateCell };
