import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InspectionLineItemLineItemStatusValues = [
  "PENDING_REVIEW",
  "APPROVED",
  "REJECTED",
  "REVISED",
  "ARCHIVED",
] as const;

const InspectionLineItemLineItemStatusEffectSchema = Schema.Literal(
  ...InspectionLineItemLineItemStatusValues,
);

type InspectionLineItemLineItemStatus = Schema.Schema.Type<
  typeof InspectionLineItemLineItemStatusEffectSchema
>;

const InspectionLineItemLineItemStatusEnum = castEnum(
  InspectionLineItemLineItemStatusEffectSchema,
);

function randomInspectionLineItemLineItemStatus(): InspectionLineItemLineItemStatus {
  return rand(InspectionLineItemLineItemStatusValues);
}

export {
  InspectionLineItemLineItemStatusEffectSchema,
  InspectionLineItemLineItemStatusEnum,
  InspectionLineItemLineItemStatusValues,
  randomInspectionLineItemLineItemStatus,
};
export type { InspectionLineItemLineItemStatus };
