import type { EnderId, Money$ } from "@ender/shared/core";
import type { EnderDate } from "@ender/shared/utils/ender-date";
import type { AuthorOptions } from "@ender/shared/utils/rest";
import { rest } from "@ender/shared/utils/rest";

type Payouts = {
  firmId: EnderId;
  amount: Money$.Money;
};

// TODO ENDER-15902: it's still a handler in Java
function createEquityPayout(
  propertyId: EnderId,
  payouts: Payouts,
  date: EnderDate,
  options: AuthorOptions = {},
) {
  return rest.post(
    `/createEquityPayout`,
    { propertyId, payouts, date },
    options,
  );
}

// TODO invoiceType type (does not block merge; adding partial typing is an improvement to existing code)
function getInvoicePages(
  { invoiceType, uploaderId }: { invoiceType: unknown; uploaderId: EnderId },
  options: AuthorOptions = {},
) {
  return rest.get(
    `/invoicePages?invoiceType=${invoiceType}${uploaderId ? `&uploaderId=${uploaderId}` : ""}`,
    null,
    options,
  );
}

/**
 * @temporary Continue using this until REST API is updated to handle File Uploads.
 * The generated API's do not currently support file uploads
 */
function createPayableInvoiceWithFilesUpload({
  files,
  overrideWarnings = false,
}: {
  files: File[];
  overrideWarnings: boolean;
}) {
  const formData = new FormData();
  formData.append("json", JSON.stringify({ overrideWarnings }));
  for (const file of files) {
    formData.append("files", file);
  }
  return rest.post("/invoices/fileUploadPayable", formData);
}

/**
 * @temporary Continue using this until REST API is updated to handle File Uploads.
 * The generated API's do not currently support file uploads
 */
function createReceivableInvoiceWithFilesUpload({
  files,
  overrideWarnings = false,
}: {
  files: File[];
  overrideWarnings: boolean;
}) {
  const formData = new FormData();
  formData.append("json", JSON.stringify({ overrideWarnings }));
  for (const file of files) {
    formData.append("files", file);
  }
  return rest.post("/invoices/fileUploadReceivable", formData);
}

// TODO ENDER-15902: it's still a handler in Java
function deleteInvoicePageById(
  invoicePageId: EnderId,
  options: AuthorOptions = {},
) {
  return rest.post(`/invoicePages/${invoicePageId}/trash`, options);
}

export {
  createEquityPayout,
  createPayableInvoiceWithFilesUpload,
  createReceivableInvoiceWithFilesUpload,
  deleteInvoicePageById,
  getInvoicePages,
};
