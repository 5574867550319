import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TenantChargeTenantChargeStatusValues = [
  "POSTED",
  "OPEN",
  "FUTURE",
] as const;

const TenantChargeTenantChargeStatusEffectSchema = Schema.Literal(
  ...TenantChargeTenantChargeStatusValues,
);

type TenantChargeTenantChargeStatus = Schema.Schema.Type<
  typeof TenantChargeTenantChargeStatusEffectSchema
>;

const TenantChargeTenantChargeStatusEnum = castEnum(
  TenantChargeTenantChargeStatusEffectSchema,
);

function randomTenantChargeTenantChargeStatus(): TenantChargeTenantChargeStatus {
  return rand(TenantChargeTenantChargeStatusValues);
}

export {
  randomTenantChargeTenantChargeStatus,
  TenantChargeTenantChargeStatusEffectSchema,
  TenantChargeTenantChargeStatusEnum,
  TenantChargeTenantChargeStatusValues,
};
export type { TenantChargeTenantChargeStatus };
