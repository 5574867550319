/**
 * Delete a bai upload  &  associated bank transactions
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}/baiUploads/{baiUploadId}/delete
 * @function deleteBAIUpload
 * @memberof BankingAPI
 * @param {BankingAPIDeleteBAIUploadPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIDeleteBAIUploadPathParams = {
  bankAccountId: EnderId;
  baiUploadId: EnderId;
};

type BankingAPIDeleteBAIUploadSearchParams = {
  token?: string | undefined;
};

type BankingAPIDeleteBAIUploadPayload = BankingAPIDeleteBAIUploadPathParams &
  BankingAPIDeleteBAIUploadSearchParams;

function deleteBAIUploadUnsafeEffect(
  payload: BankingAPIDeleteBAIUploadPayload,
) {
  const { bankAccountId, baiUploadId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}/baiUploads/${baiUploadId}/delete`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteBAIUpload(
  payload: BankingAPIDeleteBAIUploadPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteBAIUploadUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteBAIUpload };
export type {
  BankingAPIDeleteBAIUploadPathParams,
  BankingAPIDeleteBAIUploadPayload,
  BankingAPIDeleteBAIUploadSearchParams,
};
