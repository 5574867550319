import type { RefObject } from "react";
import { useCallback, useRef } from "react";

import { NULL } from "@ender/shared/constants/general";
import { useFormContext } from "@ender/shared/forms/hooks/general";
import { cast } from "@ender/shared/types/cast";

import type {
  FormListControls,
  UseFormListControlsReturn,
} from "./form-list.types";
import { getFinalValue } from "./form-list.utils";

/** @deprecated This should not be used anymore. */
// move finalValue into the hook, we should be able to get it from the form context using name
function useCreateFormListControls<T, InT = T>(
  name: string,
  initialValue?: InT,
): UseFormListControlsReturn<T> {
  const form = useFormContext<T>();
  const finalValue = getFinalValue(form, name);

  return {
    clear: () => {
      form.setFieldValue(name, cast([]));
    },
    concat: (val: T[]) => {
      val.forEach((v) => {
        form.insertListItem(name, v);
      });
    },
    insert: (index: number, value?: T) => {
      if (index < 0 || index >= finalValue.length) {
        throw new Error(`FormList: index ${index} out of bounds`);
      }

      if (value) {
        form.insertListItem(name, value, index);
      } else if (initialValue) {
        form.insertListItem(name, initialValue, index);
      }
    },
    pop: () => {
      form.removeListItem(name, finalValue.length - 1);
    },
    push: (val?: T) => {
      if (val) {
        form.insertListItem(name, val);
      } else if (initialValue) {
        form.insertListItem(name, initialValue);
      }
    },
    remove: (index: number) => {
      if (index < 0 || index >= finalValue.length) {
        throw new Error(`FormList: index ${index} out of bounds`);
      }

      form.removeListItem(name, index);
    },
    reorder: (from: number, to: number) => {
      if (from < 0 || from >= finalValue.length) {
        throw new Error(`FormList: index ${from} out of bounds`);
      }

      if (to < 0 || to >= finalValue.length) {
        throw new Error(`FormList: index ${to} out of bounds`);
      }

      form.reorderListItem(name, { from, to });
    },
  };
}

/** @deprecated This should not be used anymore. */
type UseFormListReturn<T> = UseFormListControlsReturn<T> & {
  ref: RefObject<FormListControls<T>>;
};

/** @deprecated This should not be used anymore. */
function useFormList<T>(): UseFormListReturn<T> {
  const ref = useRef<FormListControls<T>>(NULL);

  const pop = useCallback(() => {
    ref.current?.pop();
  }, [ref]);

  const push = useCallback(
    (val?: T) => {
      ref.current?.push(val);
    },
    [ref],
  );

  const remove = useCallback(
    (index: number) => {
      ref.current?.remove(index);
    },
    [ref],
  );

  const insert = useCallback(
    (index: number, value?: T) => {
      ref.current?.insert(index, value);
    },
    [ref],
  );

  const reorder = useCallback(
    (from: number, to: number) => {
      ref.current?.reorder(from, to);
    },
    [ref],
  );

  const clear = useCallback(() => {
    ref.current?.clear();
  }, [ref]);

  const concat = useCallback(
    (val: T[]) => {
      ref.current?.concat(val);
    },
    [ref],
  );

  return { clear, concat, insert, pop, push, ref, remove, reorder };
}

export { useCreateFormListControls, useFormList };
export type { UseFormListControlsReturn, UseFormListReturn };
