/**
 * For a given widget and given factors, this returns statistical data. For example, if the CityFactor was given, this
 might return something as follows, which would indicate that there were 100 rows from Austin and 84 rows from
 Miami.
 
  <pre> 
 {"CITY_FACTOR_ID": [
  {value: "Austin", count: 100}
  {value: "Miami", count 84} 
 ]}
  </pre>
 * HTTP Method: POST
 * Pathname: /widgets/{widgetId}/factorMetadata
 * @function getFactorMetadataForWidget
 * @memberof ReportsAPI
 * @param {ReportsAPIGetFactorMetadataForWidgetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportsAPIGetFactorMetadataForWidgetResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ReportsAPIGetFactorMetadataForWidgetResponse } from "../reports-api-get-factor-metadata-for-widget-response";

type ReportsAPIGetFactorMetadataForWidgetPathParams = {
  widgetId: EnderId;
};

type ReportsAPIGetFactorMetadataForWidgetSearchParams = {
  token?: string | undefined;
};

type ReportsAPIGetFactorMetadataForWidgetBodyParams = {
  factorIds: EnderId[];
};

type ReportsAPIGetFactorMetadataForWidgetPayload =
  ReportsAPIGetFactorMetadataForWidgetPathParams &
    ReportsAPIGetFactorMetadataForWidgetSearchParams &
    ReportsAPIGetFactorMetadataForWidgetBodyParams;

function getFactorMetadataForWidgetUnsafeEffect(
  payload: ReportsAPIGetFactorMetadataForWidgetPayload,
) {
  const { widgetId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportsAPIGetFactorMetadataForWidgetResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ReportsAPIGetFactorMetadataForWidgetResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/widgets/${widgetId}/factorMetadata`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFactorMetadataForWidget(
  payload: ReportsAPIGetFactorMetadataForWidgetPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIGetFactorMetadataForWidgetResponse> {
  return F.pipe(
    payload,
    getFactorMetadataForWidgetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFactorMetadataForWidget };
export type {
  ReportsAPIGetFactorMetadataForWidgetBodyParams,
  ReportsAPIGetFactorMetadataForWidgetPathParams,
  ReportsAPIGetFactorMetadataForWidgetPayload,
  ReportsAPIGetFactorMetadataForWidgetSearchParams,
};
