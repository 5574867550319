"use client";

import { Option as O } from "effect";

import { Form } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Stack } from "@ender/shared/ds/stack";
import { Text, TextColor } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Soc2Hidden } from "@ender/shared/ui/soc2-hidden";

import { FormPasswordInput } from "./form-password-input";
import type { LoginFormProps } from "./login-form.utils";

type LoginFormViewProps = {
  errorMessage: O.Option<string>;
  form: LoginFormProps["form"];
  isFetching: boolean;
  onForgotPasswordClick?: () => void;
  onFormSubmit: LoginFormProps["onSubmit"];
};

function LoginFormView(props: LoginFormViewProps) {
  const {
    errorMessage,
    form,
    isFetching,
    onForgotPasswordClick,
    onFormSubmit,
  } = props;

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack>
        <Soc2Hidden>
          <FormTextInput
            name="email"
            form={form}
            label="Email"
            disabled={isFetching}
          />
        </Soc2Hidden>
        <Soc2Hidden>
          <FormPasswordInput
            name="password"
            form={form}
            label="Password"
            disabled={isFetching}
          />
        </Soc2Hidden>
        {O.isSome(errorMessage) && (
          <Text color={TextColor["red-500"]}>{O.getOrThrow(errorMessage)}</Text>
        )}
        <Button type="submit" loading={isFetching} disabled={isFetching}>
          Login
        </Button>
        {onForgotPasswordClick && (
          <Button
            variant={ButtonVariant.transparent}
            loading={isFetching}
            onClick={onForgotPasswordClick}>
            Forgot password?
          </Button>
        )}
      </Stack>
    </Form>
  );
}

export { LoginFormView };
