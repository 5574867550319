import { zodResolver } from "@mantine/form";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { z } from "zod";

import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { useForm } from "@ender/shared/forms/hooks/general";
import {
  FormGenerator,
  FormInputTypeEnum,
} from "@ender/shared/forms/ui/form-generator";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import { EnderAPI } from "@ender/shared/generated/ender.api.misc";
import { RightRail } from "@ender/shared/ui/right-rail";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { rest } from "@ender/shared/utils/rest";

import type { PipelineStatus } from "../../types";
import { UnderwritingBulkActionDealList } from "./underwriting-bulk-action-deal-list";
import type { PropertyDetails } from "./underwriting-queue-table.types";

type UnderwritingBulkRejectProps = {
  opened: boolean;
  onClose: (prop?: boolean) => void;
  selectedDeals: PropertyDetails[];
};

const BulkFormSchema = z.object({
  terminationReason: z.string().min(1, "Reason is required"),
});
type FormValues = z.infer<typeof BulkFormSchema>;

function UnderwritingBulkReject({
  opened,
  onClose,
  selectedDeals = [],
}: UnderwritingBulkRejectProps) {
  const form = useForm<FormValues>({
    initialValues: {
      terminationReason: "",
    },
    validate: zodResolver(BulkFormSchema),
  });

  const { reset } = form;

  //lazy useEffect
  useEffect(() => {
    reset();
  }, [opened, reset]);

  const { data: terminationReasons = [], isLoading } = useQuery({
    queryFn: async ({ signal }) => {
      const data = await EnderAPI.getEnumValues(
        {
          enumName: "TerminationReason",
        },
        { signal },
      );
      return data;
    },
    queryKey: ["EnderAPI.getEnumValues", "TerminationReason"] as const,
    select: (data) =>
      data
        .map(({ id, name }) => ({ label: name, value: id }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
  });

  async function handleSubmit({
    terminationReason,
  }: {
    terminationReason: string;
  }) {
    try {
      const apiCalls = selectedDeals.flatMap((deal) => {
        const { dealId } = deal;
        const currentStatus: PipelineStatus =
          deal?.status?.value?.["pipelineStatus"].id;
        const newStatus: PipelineStatus =
          currentStatus === "LEAD" ? "ON_MARKET" : "TERMINATED";
        return [
          {
            body: { statusName: "pipelineStatus", statusValue: newStatus },
            method: "POST",
            path: `/purchasableProperties/${dealId}/status`,
          },
          {
            body: { terminationReason, underwriterId: "" },
            method: "POST",
            path: `/buy/deals/${dealId}`,
          },
        ];
      });

      await rest.post("/bulk-api", apiCalls);
      onClose(true);
      showSuccessNotification({
        message: `${selectedDeals.length} Deals Rejected`,
      });
    } catch (err) {
      fail(err);
    }
  }

  const formGeneratorSchema = [
    {
      name: "terminationReason",
      props: {
        clearable: false,
        data: terminationReasons,
        label: "Reason",
      },
      type: FormInputTypeEnum.SELECT,
    },
  ];

  return (
    <RightRail
      opened={opened}
      onClose={onClose}
      title="Bulk Rejection"
      trapFocus={false}>
      <Stack>
        <UnderwritingBulkActionDealList selectedDeals={selectedDeals} />
        <UnmanagedForm form={form} onSubmit={handleSubmit}>
          <Skeleton visible={isLoading}>
            <Stack>
              <FormGenerator formSchema={formGeneratorSchema} />
              <Group justify={Justify.end}>
                <Button type="submit">Save</Button>
              </Group>
            </Stack>
          </Skeleton>
        </UnmanagedForm>
      </Stack>
    </RightRail>
  );
}

export { UnderwritingBulkReject };
export type { PropertyDetails };
