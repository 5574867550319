import { Option as O } from "effect";

import { NULL } from "@ender/shared/constants/general";
import { Modal } from "@ender/shared/ds/modal";
import type { GetJournalEntriesReportResponseJournalEntryReportRow } from "@ender/shared/generated/ender.api.reports.request";

import { ReverseJournalEntryForm } from "./reverse-journal-entry-form";

type ReverseJournalEntryModalProps = {
  row: O.Option<GetJournalEntriesReportResponseJournalEntryReportRow>;
  closeModal: () => void;
  refreshReversalData?: () => void;
  refreshTableData?: () => void;
  refreshTransactionData?: () => void;
};

function ReverseJournalEntryModal(props: ReverseJournalEntryModalProps) {
  const {
    row,
    closeModal,
    refreshReversalData,
    refreshTableData,
    refreshTransactionData,
  } = props;
  return row.pipe(
    O.match({
      onNone: () => NULL,
      onSome: (row) => (
        <Modal opened onClose={closeModal} title="Reverse Journal Entry">
          <ReverseJournalEntryForm
            row={row}
            closeModal={closeModal}
            refreshReversalData={refreshReversalData}
            refreshTableData={refreshTableData}
            refreshTransactionData={refreshTransactionData}
          />
        </Modal>
      ),
    }),
  );
}

export { ReverseJournalEntryModal };
