import { clsx } from "clsx";
import type { HTMLProps } from "react";
import { useState } from "react";

import { Overflow } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import type { EmptyObject } from "@ender/shared/types/general";
import { EmptyTableRow } from "@ender/shared/ui/empty-table-row";
import { EnderPagination } from "@ender/shared/ui/ender-pagination";

import { TableHeader } from "./table-header";
import { TableRow } from "./table-row";
import type { EnderTable } from "./table.types";
import { useIntersectionObserver } from "./use-intersection-observer";

import styles from "./table.module.css";

function Table(props: HTMLProps<HTMLTableElement>) {
  const { className, ...tableProps } = props;
  return (
    <table className={clsx(styles.enderTable, className)} {...tableProps} />
  );
}

type EnderTableTanstackProps<RowData, Meta extends object> = {
  table: EnderTable<RowData, Meta>;
  className?: string;
  maxHeight?: string;
};

function EnderTableTanstack<RowData, Meta extends object = EmptyObject>(
  props: EnderTableTanstackProps<RowData, Meta>,
) {
  const { table, className, maxHeight } = props;
  const { pagination } = table.getState();
  const { errorMessage, fetchNextPage, isLoading, title } = table.options.meta;

  const rows = table.getRowModel().rows;

  const [rootElement, setRootElement] = useState<Element | null>();
  const setObservedElement = useIntersectionObserver({
    onIntersect: fetchNextPage,
    root: rootElement,
    rootMargin: "0px 0px 500px 0px",
  });
  return (
    <Stack overflow={Overflow.auto}>
      <div
        className={clsx(styles.tableWrapper, className)}
        ref={setRootElement}
        style={{ maxHeight }}>
        <Table aria-label={`${title} Table`} className={styles.enderTable}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                aria-label={`Header Row ${headerGroup.id}`}>
                {headerGroup.headers.map((header) => (
                  <TableHeader key={header.id} table={table} header={header} />
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                const isLastRow = Math.max(rows.length - 1 - i, 0) === 0;
                const ref = isLastRow ? setObservedElement : undefined;
                return <TableRow key={row.id} row={row} rowNum={i} ref={ref} />;
              })
            ) : (
              <EmptyTableRow
                message={isLoading ? "Loading..." : errorMessage}
                colSpan={table.getVisibleLeafColumns().length}
              />
            )}
          </tbody>
        </Table>
      </div>

      {pagination && (
        <EnderPagination
          page={pagination.pageIndex + 1}
          total={table.getPageCount()}
          onChange={(index) => table.setPageIndex(index - 1)}
        />
      )}
    </Stack>
  );
}

export { EnderTableTanstack, Table };
