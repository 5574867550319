import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import { CategoryFlagValues } from "@ender/shared/generated/ender.model.accounting";

const CategoryTypeValues = [
  "ASSET",
  "LIABILITY",
  "EQUITY",
  "INCOME",
  "EXPENSE",
] as const;
const CategoryTypeSchema = z.enum(CategoryTypeValues);
type CategoryType = z.infer<typeof CategoryTypeSchema>;

/**
 * @description GLCategory::toJson from model/accounting/GLCategory.java
 */
const GLCategorySchema = z.object({
  accountName: z.string(),
  accountNumber: z.string(),
  id: EnderIdSchema,
  isParent: z.boolean(),
  name: z.string(),
  parentId: EnderIdSchema,
  pmId: EnderIdSchema,
  tenantFriendlyName: z.string(),
  // Not creating a named enum for this because the BE is using CategoryType::toString which calls `capitalize(name())`
  type: z.enum(["Asset", "Liability", "Equity", "Income", "Expense"]),
  waterfallPriority: z.number(),
});
type GLCategory = z.infer<typeof GLCategorySchema>;

/**
 * @deprecated This is was used as as response type for getGLCategories which is now more appropriately typed please use `GetGLCategoriesResponseSchema` instead
 */
const GeneralLedgerAccountSchema = z.object({
  accountName: z.string(),
  accountNumber: z.string(),
  flags: z.enum(CategoryFlagValues).array(),
  id: EnderIdSchema,
  isParent: z.boolean().optional(),
  parentId: EnderIdSchema,
  tenantFriendlyName: z.string(),
  type: z.string(),
});

const GetGLCategoriesResponseItemSchema = GLCategorySchema.extend({
  accountName: z.string(),
  // Not creating a named enum for this because the BE is using CategoryFlag::toString which calls `capitalize(name())`
  flags: z.enum([
    "Accounts Payable",
    "Accounts Receivable",
    "Application Fees",
    "Budget Item",
    "Current Earnings",
    "Default Operations Expense",
    "Deposits Escrow Account",
    "Exclude From Waterfall Allocations",
    "Late Fees",
    "Non Sufficient Funds",
    "Operations Expense",
    "Pending Payout Card Balance",
    "Prepaid Rent",
    "Rent Concessions",
    "Rent",
    "Retained Earnings",
    "Security Deposits",
    "Suspense Account",
    "Tenant Charge Type",
    "Triple Net Charge Type",
  ]),
  isParent: z.boolean(),
});
type GetGLCategoriesResponseItem = z.infer<
  typeof GetGLCategoriesResponseItemSchema
>;

export {
  CategoryTypeSchema,
  CategoryTypeValues,
  GeneralLedgerAccountSchema,
  GetGLCategoriesResponseItemSchema,
  GLCategorySchema,
};
export type { CategoryType, GetGLCategoriesResponseItem, GLCategory };
