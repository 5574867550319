import { useMutation } from "@tanstack/react-query";

import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import { fail } from "@ender/shared/utils/error";
import { withWarningHandler } from "@ender/shared/utils/rest";

function useVoidPayment({
  invoiceId,
  onSuccess,
}: {
  invoiceId: EnderId;
  onSuccess: () => void;
}) {
  const confirmation = useConfirmationContext();

  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };

  const voidPaymentWithWarnings = withWarningHandler(
    InvoicesAPI.voidPayment,
    onWarnings,
  );

  return useMutation({
    mutationFn: voidPaymentWithWarnings,
    mutationKey: ["InvoicesAPI.voidPayment", invoiceId] as const,
    onError: (error) => {
      fail(error);
    },
    onSuccess,
  });
}

export { useVoidPayment };
