import { Function as F } from "effect";
import { useState } from "react";

import { Button, ButtonVariant } from "@ender/shared/ds/button";

import "./warnings-modal.scss";

type WarningsModalProps = {
  className?: string;
  cancelBtnText?: string;
  closeModal: () => void;
  confirmBtnText?: string;
  message?: string;
  onCancelClick?: () => void;
  onConfirm: (closeModal: () => void) => void;
  prompt?: string;
  title?: string;
  warnings: string[];
};

/**
 * @deprecated use a warning toast notification instead of this
 */
function WarningsModal({
  cancelBtnText = "Go Back",
  closeModal,
  confirmBtnText = "Continue",
  message = "",
  onCancelClick = F.constVoid,
  onConfirm,
  prompt = "Please review warnings before continuing.",
  warnings,
}: WarningsModalProps) {
  const [disableConfirm, setdisableConfirm] = useState(false);

  function handleConfirmClick() {
    setdisableConfirm(true);
    onConfirm(closeModal);
  }

  function onCancelButtonClick() {
    onCancelClick();
    closeModal();
  }

  return (
    <div className="warnings-modal">
      <div className="warnings-modal__header">
        <div className="warnings-modal__message">{message}</div>
      </div>
      <div className="warnings-modal__warnings">
        <ul>
          {warnings.map((warning, i) => (
            <li key={i}>
              <div className="warnings-modal__warning">{warning}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className="warnings-modal__prompt">{prompt}</div>
      <div className="warnings-modal__actions">
        <Button variant={ButtonVariant.outlined} onClick={onCancelButtonClick}>
          {cancelBtnText}
        </Button>
        <Button disabled={disableConfirm} onClick={handleConfirmClick}>
          {confirmBtnText}
        </Button>
      </div>
    </div>
  );
}

export { WarningsModal };
