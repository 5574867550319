import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const InboxItemInboxPartyValues = [
  "LEASE",
  "MAINTENANCE",
  "BROKER",
  "BUY_PROPERTY_INTERNAL",
  "PROPERTY",
  "APPLICATION",
  "APPLICATION_GROUP",
] as const;

const InboxItemInboxPartyEffectSchema = Schema.Literal(
  ...InboxItemInboxPartyValues,
);

type InboxItemInboxParty = Schema.Schema.Type<
  typeof InboxItemInboxPartyEffectSchema
>;

const InboxItemInboxPartyEnum = castEnum(InboxItemInboxPartyEffectSchema);

function randomInboxItemInboxParty(): InboxItemInboxParty {
  return rand(InboxItemInboxPartyValues);
}

export {
  InboxItemInboxPartyEffectSchema,
  InboxItemInboxPartyEnum,
  InboxItemInboxPartyValues,
  randomInboxItemInboxParty,
};
export type { InboxItemInboxParty };
