/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/{leaseId}/completeMoveOutFlow
 * @function completeMoveOutFlow
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPICompleteMoveOutFlowPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  CompleteMoveOutFlowRequestEscrowInternalTransferRequest,
  CompleteMoveOutFlowRequestUpdateAddressRequest,
} from "@ender/shared/generated/com.ender.middle.request";
import type { LeaseMoveOutReason } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPICompleteMoveOutFlowPathParams = {
  leaseId: EnderId;
};

type LeasingMiddleLayerAPICompleteMoveOutFlowSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPICompleteMoveOutFlowBodyParams = {
  actualMoveOutDate?: SerializesInto<LocalDate> | undefined;
  allTenantsAmount?: SerializesInto<Money> | undefined;
  escrowInternalTransferRequest?:
    | CompleteMoveOutFlowRequestEscrowInternalTransferRequest
    | undefined;
  moveOutReason: LeaseMoveOutReason;
  securityDepositNotificationLetter: string;
  sendLetterAsEmail?: boolean | undefined;
  userIdRefundAmount?: Partial<Record<EnderId, Money>> | undefined;
  userIdUpdateAddressRequest?:
    | Partial<Record<EnderId, CompleteMoveOutFlowRequestUpdateAddressRequest>>
    | undefined;
};

type LeasingMiddleLayerAPICompleteMoveOutFlowPayload =
  LeasingMiddleLayerAPICompleteMoveOutFlowPathParams &
    LeasingMiddleLayerAPICompleteMoveOutFlowSearchParams &
    LeasingMiddleLayerAPICompleteMoveOutFlowBodyParams;

function completeMoveOutFlowUnsafeEffect(
  payload: LeasingMiddleLayerAPICompleteMoveOutFlowPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/middleLayer/leasing/${leaseId}/completeMoveOutFlow`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function completeMoveOutFlow(
  payload: LeasingMiddleLayerAPICompleteMoveOutFlowPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    completeMoveOutFlowUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { completeMoveOutFlow };
export type {
  LeasingMiddleLayerAPICompleteMoveOutFlowBodyParams,
  LeasingMiddleLayerAPICompleteMoveOutFlowPathParams,
  LeasingMiddleLayerAPICompleteMoveOutFlowPayload,
  LeasingMiddleLayerAPICompleteMoveOutFlowSearchParams,
};
