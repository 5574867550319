import { NotificationsProvider } from "@mantine/notifications";
import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import {
  AuthActorProvider,
  AuthTagEnum,
  useAuthActor,
} from "@ender/features/auth";
import { ConfirmationContextProvider } from "@ender/shared/contexts/confirmation";
import { EnderMantineProvider } from "@ender/shared/contexts/mantine";
import { EnderModalsProvider } from "@ender/shared/contexts/modal";
import { QueryParamProvider } from "@ender/shared/contexts/query-param-provider";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { TooltipProvider } from "@ender/shared/ds/tooltip";
import { EnderErrorBoundary } from "@ender/shared/ui/ender-error-boundary";

import { BrandingStoreInitializer } from "./branding-store-initializer";
import { EnderSpotlightProvider } from "./ender-spotlight-provider";

function AuthActorInitializer(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [authSnapshot] = useAuthActor();

  if (authSnapshot.hasTag(AuthTagEnum.INITIALIZING)) {
    return <LoadingSpinner />;
  }
  return children;
}

function AuthActorInitializerWrapper(props: PropsWithChildren<{}>) {
  const { children } = props;
  const [token] = useQueryParam("token", StringParam);
  return (
    <AuthActorProvider token={token}>
      <AuthActorInitializer>{children}</AuthActorInitializer>
    </AuthActorProvider>
  );
}

function AppProviders(props: PropsWithChildren<{ queryClient: QueryClient }>) {
  const { queryClient, children } = props;

  return (
    <EnderErrorBoundary>
      <QueryParamProvider adapter="react-router">
        <AuthActorInitializerWrapper>
          <BrandingStoreInitializer>
            <EnderMantineProvider withNormalizeCSS withGlobalStyles>
              <TooltipProvider delayDuration={300}>
                <EnderModalsProvider>
                  <ConfirmationContextProvider>
                    <QueryClientProvider client={queryClient}>
                      <NotificationsProvider position="bottom-right">
                        <EnderSpotlightProvider>
                          {children}
                        </EnderSpotlightProvider>
                      </NotificationsProvider>
                    </QueryClientProvider>
                  </ConfirmationContextProvider>
                </EnderModalsProvider>
              </TooltipProvider>
            </EnderMantineProvider>
          </BrandingStoreInitializer>
        </AuthActorInitializerWrapper>
      </QueryParamProvider>
    </EnderErrorBoundary>
  );
}

export { AppProviders };
