import type { ReactNode } from "react";

type EmptyTableRowProps = {
  colSpan: number;
  message?: ReactNode;
};

/**
 * @deprecated this component has no intended replacement.
 * its functionality is built in to the latest Table
 * and therefore the component should be included in the Table package
 */
function EmptyTableRow(props: EmptyTableRowProps) {
  const { colSpan, message = "No Results" } = props;
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="flex-center flex-justify">{message}</div>
      </td>
    </tr>
  );
}

export { EmptyTableRow };
export type { EmptyTableRowProps };
