import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CollectionsStepCollectionsActionTypeValues = [
  "PHONE_CALL",
  "EMAIL",
  "SMS",
  "CERTIFIED_MAIL",
  "LATE_FEE",
  "CREATE_TASK",
  "INTEND_TO_EVICT",
  "MOVE_TO_EVICT",
] as const;

const CollectionsStepCollectionsActionTypeEffectSchema = Schema.Literal(
  ...CollectionsStepCollectionsActionTypeValues,
);

type CollectionsStepCollectionsActionType = Schema.Schema.Type<
  typeof CollectionsStepCollectionsActionTypeEffectSchema
>;

const CollectionsStepCollectionsActionTypeEnum = castEnum(
  CollectionsStepCollectionsActionTypeEffectSchema,
);

function randomCollectionsStepCollectionsActionType(): CollectionsStepCollectionsActionType {
  return rand(CollectionsStepCollectionsActionTypeValues);
}

export {
  CollectionsStepCollectionsActionTypeEffectSchema,
  CollectionsStepCollectionsActionTypeEnum,
  CollectionsStepCollectionsActionTypeValues,
  randomCollectionsStepCollectionsActionType,
};
export type { CollectionsStepCollectionsActionType };
