import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UserRoleValues = [
  "ENDER",
  "INVESTOR",
  "VENDOR",
  "TENANT",
  "BROKER",
] as const;

const UserRoleEffectSchema = Schema.Literal(...UserRoleValues);

type UserRole = Schema.Schema.Type<typeof UserRoleEffectSchema>;

const UserRoleEnum = castEnum(UserRoleEffectSchema);

function randomUserRole(): UserRole {
  return rand(UserRoleValues);
}

export { randomUserRole, UserRoleEffectSchema, UserRoleEnum, UserRoleValues };
export type { UserRole };
