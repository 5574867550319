/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/people/{applicantUserId}/paymentMethod
 * @function setPaymentMethod
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPISetPaymentMethodPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPISetPaymentMethodPathParams = {
  applicationGroupId: EnderId;
  applicantUserId: EnderId;
};

type ApplicationsAPISetPaymentMethodSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPISetPaymentMethodBodyParams = {
  paymentMethodId: EnderId;
};

type ApplicationsAPISetPaymentMethodPayload =
  ApplicationsAPISetPaymentMethodPathParams &
    ApplicationsAPISetPaymentMethodSearchParams &
    ApplicationsAPISetPaymentMethodBodyParams;

function setPaymentMethodUnsafeEffect(
  payload: ApplicationsAPISetPaymentMethodPayload,
) {
  const { applicationGroupId, applicantUserId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/people/${applicantUserId}/paymentMethod`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setPaymentMethod(
  payload: ApplicationsAPISetPaymentMethodPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setPaymentMethodUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setPaymentMethod };
export type {
  ApplicationsAPISetPaymentMethodBodyParams,
  ApplicationsAPISetPaymentMethodPathParams,
  ApplicationsAPISetPaymentMethodPayload,
  ApplicationsAPISetPaymentMethodSearchParams,
};
