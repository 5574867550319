import { IconAlertTriangle, IconInfoCircle } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import type { ReactNode } from "react";
import { useCallback, useState } from "react";

import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import type { EnderId } from "@ender/shared/core";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { TextInput } from "@ender/shared/ds/text-input";
import { Tooltip } from "@ender/shared/ds/tooltip";
import type { ApprovalsAPIUpdateStepNamePayload } from "@ender/shared/generated/ender.api.misc";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import { useDebounce } from "@ender/shared/hooks/use-debounce";

type StepNameFormProps = {
  disabled?: boolean;
  initialValue: string;
  onSuccess: () => void;
  stepId: EnderId;
  tooltip?: ReactNode;
};

function StepNameForm({
  initialValue,
  onSuccess,
  stepId,
  disabled,
  tooltip,
}: StepNameFormProps) {
  const [name, setName] = useState<string>(initialValue);
  const queryClient = useQueryClient();
  const updateStepNameMutation = useMutation({
    mutationFn: (payload: ApprovalsAPIUpdateStepNamePayload) =>
      ApprovalsAPI.updateStepName(payload),
    mutationKey: ["ApprovalsAPI.updateStepName", stepId] as const,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["approvalProcesses"],
      });
      onSuccess();
    },
  });

  const debouncedUpdateStepName = useDebounce(
    updateStepNameMutation.mutateAsync,
    500,
  );

  const handleChange = useCallback(
    async (value: string) => {
      setName(value);
      if (disabled || value === initialValue) {
        return;
      }
      debouncedUpdateStepName({ name: value, stepId });
    },
    [debouncedUpdateStepName, disabled, initialValue, stepId],
  );

  return (
    <Group justify={Justify.stretch} align={Align.center} grow noWrap>
      <div className="grow">
        <TextInput
          aria-label="Approval Step Name"
          name="name"
          disabled={disabled}
          onChange={handleChange}
          value={name}
        />
      </div>
      {P.isNotNullable(tooltip) && (
        <Tooltip label={tooltip}>
          {!disabled && <IconInfoCircle color={EnderThemeColorEnum.PRIMARY} />}
          {disabled && <IconAlertTriangle color={EnderThemeColorEnum.YELLOW} />}
        </Tooltip>
      )}
    </Group>
  );
}

export { StepNameForm };
