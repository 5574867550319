/**
 * For an overview of how this webhook works in the overall Stripe Payment workflow see:
 https://stripe.com/docs/webhooks

 To test locally use Stripe CLI: https://stripe.com/docs/webhooks/test

 stripe login --api-key XYZ (replace XYZ with the stripe.test.privateKey from your config) stripe listen
 --forward-to localhost:7070/stripe/eventHook
 * HTTP Method: POST
 * Pathname: /stripe/eventHook
 * @function handleStripeEvent
 * @memberof PaymentsAPI
 * @param {PaymentsAPIHandleStripeEventPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIHandleStripeEventSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIHandleStripeEventBodyParams = {
  isConnect?: boolean | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
  livemode?: boolean | undefined;
};

type PaymentsAPIHandleStripeEventPayload =
  PaymentsAPIHandleStripeEventSearchParams &
    PaymentsAPIHandleStripeEventBodyParams;

function handleStripeEventUnsafeEffect(
  payload: PaymentsAPIHandleStripeEventPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/stripe/eventHook",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function handleStripeEvent(
  payload: PaymentsAPIHandleStripeEventPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    handleStripeEventUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { handleStripeEvent };
export type {
  PaymentsAPIHandleStripeEventBodyParams,
  PaymentsAPIHandleStripeEventPayload,
  PaymentsAPIHandleStripeEventSearchParams,
};
