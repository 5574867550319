/**
 * HTTP Method: POST
 * Pathname: /renewalOffers/{offerId}/accept
 * @function acceptOffer
 * @memberof RenewalsAPI
 * @param {RenewalsAPIAcceptOfferPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPIAcceptOfferPathParams = {
  offerId: EnderId;
};

type RenewalsAPIAcceptOfferSearchParams = {
  token?: string | undefined;
};

type RenewalsAPIAcceptOfferPayload = RenewalsAPIAcceptOfferPathParams &
  RenewalsAPIAcceptOfferSearchParams;

function acceptOfferUnsafeEffect(payload: RenewalsAPIAcceptOfferPayload) {
  const { offerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/renewalOffers/${offerId}/accept`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function acceptOffer(
  payload: RenewalsAPIAcceptOfferPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    acceptOfferUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { acceptOffer };
export type {
  RenewalsAPIAcceptOfferPathParams,
  RenewalsAPIAcceptOfferPayload,
  RenewalsAPIAcceptOfferSearchParams,
};
