import type { QueryFunctionContext } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  HashMap as HM,
  Match,
  Number as Num,
  Option as O,
  String as Str,
} from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useMemo, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { TriggerButton, TriggerButtonSize } from "@ender/shared/ds/menu";
import type { SelectOption } from "@ender/shared/ds/select";
import type { FirmsAPISearchFirmsPayload } from "@ender/shared/generated/ender.api.core";
import { FirmsAPI } from "@ender/shared/generated/ender.api.core";
import type {
  Firm,
  FirmFirmType,
} from "@ender/shared/generated/ender.model.core";
import { FirmFirmTypeEnum } from "@ender/shared/generated/ender.model.core";
import { MultiFilter } from "@ender/widgets/filters/multi-filter";

type GetFirmsResponse = Firm[];
type GetFirmsQueryKey = readonly [
  "FirmsAPI.searchFirms",
  FirmsAPISearchFirmsPayload,
];

function searchFirmsQueryFn(
  context: QueryFunctionContext<GetFirmsQueryKey>,
): Promise<GetFirmsResponse> {
  const { queryKey, signal } = context;
  const [, payload] = queryKey;
  return FirmsAPI.searchFirms(payload, { signal });
}

function searchFirmsSelectFn(data: Firm[]): SelectOption<EnderId, Firm>[] {
  return F.pipe(
    data ?? [],
    A.map((meta) => {
      const { name: label, id: value } = meta;
      return { label, meta, value };
    }),
  );
}

type FirmFilterProps = {
  value: EnderId[];
  firmType?: FirmFirmType;
  onChange: (value: EnderId[], firms: Firm[]) => void;
  disabled?: boolean;
};

const FirmFilter = forwardRef<ElementRef<typeof MultiFilter>, FirmFilterProps>(
  function FirmFilter(props, ref) {
    const {
      value,
      firmType = FirmFirmTypeEnum.STANDARD,
      onChange,
      disabled,
    } = props;

    const { data: options = [], isFetching } = useQuery({
      queryFn: searchFirmsQueryFn,
      queryKey: ["FirmsAPI.searchFirms", { firmType }],
      select: searchFirmsSelectFn,
    });
    const idToOption = useMemo(() => {
      return F.pipe(
        options,
        A.map((option): readonly [EnderId, SelectOption<EnderId, Firm>] => {
          return [option.value, option];
        }),
        HM.fromIterable,
      );
    }, [options]);

    const [keyword, setKeywordValue] = useState<string>("");
    const filteredOptions = useMemo(() => {
      const searchText = Str.toUpperCase(keyword);
      return F.pipe(
        options,
        A.filter((opt) =>
          F.pipe(opt.label, Str.toUpperCase, Str.includes(searchText)),
        ),
      );
    }, [keyword, options]);

    const selected = useMemo(
      () =>
        F.pipe(
          value,
          A.flatMap((id) => F.pipe(idToOption, HM.get(id), O.toArray)),
        ),
      [value, idToOption],
    );
    const handleChange = useCallback(
      (selected: SelectOption<EnderId, Firm>[]) => {
        const selectedIds = F.pipe(
          selected,
          A.map((opt) => opt.value),
        );
        const selectedFirms = F.pipe(
          selected,
          A.flatMap((opt) => F.pipe(opt.meta, O.fromNullable, O.toArray)),
        );
        onChange(selectedIds, selectedFirms);
      },
      [onChange],
    );

    const triggerLabel = useMemo(() => {
      const mainLabel = firmType === FirmFirmTypeEnum.FUND ? "Fund" : "Firm";
      const headLabel = F.pipe(
        selected,
        A.head,
        O.map((opt) => `: ${opt.label}`),
        O.getOrElse(F.constant("")),
      );
      const countLabel = Match.value(A.length(selected)).pipe(
        Match.when(Num.greaterThan(1), (size) => ` +${size - 1}`),
        Match.orElse(F.constant("")),
      );
      return `${mainLabel}${headLabel}${countLabel}`;
    }, [firmType, selected]);

    return (
      <MultiFilter
        label={
          firmType === FirmFirmTypeEnum.FUND ? "Fund Filter" : "Firm Filter"
        }
        data={filteredOptions}
        disabled={disabled}
        keyword={keyword}
        isLoading={isFetching}
        onChange={handleChange}
        onKeywordChange={setKeywordValue}
        ref={ref}
        trigger={
          <TriggerButton
            rounded={false}
            disabled={disabled}
            size={TriggerButtonSize.sm}>
            {triggerLabel}
          </TriggerButton>
        }
        value={selected}
      />
    );
  },
);

export { FirmFilter };
export type { FirmFilterProps };
