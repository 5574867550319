import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetShowingsRequestGetShowingsActiveTabValues = [
  "UPCOMING",
  "PREVIOUS",
] as const;

const GetShowingsRequestGetShowingsActiveTabEffectSchema = Schema.Literal(
  ...GetShowingsRequestGetShowingsActiveTabValues,
);

type GetShowingsRequestGetShowingsActiveTab = Schema.Schema.Type<
  typeof GetShowingsRequestGetShowingsActiveTabEffectSchema
>;

const GetShowingsRequestGetShowingsActiveTabEnum = castEnum(
  GetShowingsRequestGetShowingsActiveTabEffectSchema,
);

function randomGetShowingsRequestGetShowingsActiveTab(): GetShowingsRequestGetShowingsActiveTab {
  return rand(GetShowingsRequestGetShowingsActiveTabValues);
}

export {
  GetShowingsRequestGetShowingsActiveTabEffectSchema,
  GetShowingsRequestGetShowingsActiveTabEnum,
  GetShowingsRequestGetShowingsActiveTabValues,
  randomGetShowingsRequestGetShowingsActiveTab,
};
export type { GetShowingsRequestGetShowingsActiveTab };
