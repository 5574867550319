/**
 * HTTP Method: POST
 * Pathname: /journal-entries/batchApprove
 * @function batchApprove
 * @memberof AccountingAPI
 * @param {AccountingAPIBatchApprovePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchedJournalEntryActionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchedJournalEntryActionResponse } from "@ender/shared/generated/ender.model.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIBatchApproveSearchParams = {
  token?: string | undefined;
};

type AccountingAPIBatchApproveBodyParams = {
  comment?: string | undefined;
  journalEntryIds: EnderId[];
  overrideWarnings?: boolean | undefined;
};

type AccountingAPIBatchApprovePayload = AccountingAPIBatchApproveSearchParams &
  AccountingAPIBatchApproveBodyParams;

function batchApproveUnsafeEffect(payload: AccountingAPIBatchApprovePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchedJournalEntryActionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchedJournalEntryActionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/journal-entries/batchApprove",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function batchApprove(
  payload: AccountingAPIBatchApprovePayload,
  options?: { signal?: AbortSignal },
): Promise<BatchedJournalEntryActionResponse> {
  return F.pipe(
    payload,
    batchApproveUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { batchApprove };
export type {
  AccountingAPIBatchApproveBodyParams,
  AccountingAPIBatchApprovePayload,
  AccountingAPIBatchApproveSearchParams,
};
