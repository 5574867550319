import type { EnderId } from "@ender/shared/core";
import type { BuyOfferOfferStatus } from "@ender/shared/generated/com.ender.buy.model.misc";
import { rest } from "@ender/shared/utils/rest";

import type { DealUploadDataMode, RepairCalculation } from "./buy-api-types";

type GetInspectionProps = {
  dealId: EnderId;
};

type GetOffersProps = {
  dealId: EnderId;
};

type SetOfferStatusProps = {
  offerId: EnderId;
  status: BuyOfferOfferStatus;
};

type UpdateTaxMethodProps = {
  marketId: EnderId;
  data: {
    type: string;
    offsetAmount: string;
    offsetPercent: string | number;
  };
};

type UploadPropertiesProps = {
  files: File[];
  dataMode: DealUploadDataMode;
};

function uploadProperties({ files, dataMode }: UploadPropertiesProps) {
  const formData = new FormData();

  files.forEach((file) => formData.append("files", file));
  formData.append("json", JSON.stringify({ dataMode }));

  return rest.post("/uploadProperties", formData);
}

function getOffers({ dealId }: GetOffersProps) {
  return rest.get(`/buy/deals/${dealId}/offers`);
}

function setOfferStatus({ offerId, status }: SetOfferStatusProps) {
  return rest.post(`/offers/${offerId}/status`, { status });
}

function downloadTaxRates() {
  return rest.get("/buy/taxes.xlsx");
}

/**
 * @function getInspections
 * @description returns array of inspections associated with a specific home
 * @param {object} data dealIds
 * @param {string} data.dealId the id of the home for which we want the inspections
 * @returns {Promise<Array<Inspection>>} Array of Inspections matching the search criteria
 */
function getInspections({ dealId }: GetInspectionProps) {
  return rest.get(`/buy/deals/${dealId}/inspections`);
}

function updateTaxMethod({ marketId, data }: UpdateTaxMethodProps) {
  return rest.post(`/markets/${marketId}/taxMethod`, data);
}

function updateRepairCalculations(dealId: EnderId, repairs: RepairCalculation) {
  return rest.post(`/buy/deals/${dealId}/repairCalculations`, repairs);
}

export {
  downloadTaxRates,
  getInspections,
  getOffers,
  setOfferStatus,
  updateRepairCalculations,
  updateTaxMethod,
  uploadProperties,
};
