import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useWatch } from "react-hook-form";
import { useStore } from "zustand";

import { Form, useForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { LocalDate$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { TenantLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { useTenantLedgerStore } from "../../../../../tenant-ledger-store.context";

const ReverseCreditFormSchema = Schema.Struct({
  reversalDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter((v): v is O.Option<LocalDate$.LocalDate> => O.isSome(v), {
      message: () => "Reversal Date is required",
    }),
  ),
});

type ReverseCreditFormType = Schema.Schema.Type<typeof ReverseCreditFormSchema>;

type ReverseCreditFormProps = {
  onSuccess: () => void;
};

function ReverseCreditForm(props: ReverseCreditFormProps) {
  const { onSuccess } = props;
  const { isLoading, mutateAsync: reverseTenantCredit } = useMutation({
    mutationFn: TenantLedgerAPI.reverseTenantLedgerEntry,
    mutationKey: ["TenantLedgerAPI.reverseTenantLedgerEntry"] as const,
  });

  const queryClient = useQueryClient();
  const tenantLedgerStore = useTenantLedgerStore();

  const { ledgerEvent } = useStore(tenantLedgerStore, (state) => ({
    ledgerEvent: state.selectedLedgerEvent,
  }));

  const finalLedgerEvent = ledgerEvent.pipe(
    O.getOrThrowWith(() => new Error("No ledger event selected")),
  );

  const form = useForm<ReverseCreditFormType>({
    defaultValues: {
      reversalDate:
        LocalDate$.parse(finalLedgerEvent.generalLedgerDate) ?? O.none(),
    },
    resolver: effectTsResolver(ReverseCreditFormSchema),
  });

  const reversalDate = useWatch({
    control: form.control,
    name: "reversalDate",
  });

  async function handleFormSubmit() {
    await reverseTenantCredit({
      ledgerEventId: ledgerEvent.pipe(
        O.map((event) => event.id),
        O.getOrThrow,
      ),
      ledgerEventType: ledgerEvent.pipe(
        O.map((event) => event.ledgerEventType),
        O.getOrThrow,
      ),
      reversalDate: reversalDate.pipe(
        O.map((date) => date.toJSON()),
        O.getOrThrow,
      ),
    });
    showSuccessNotification({ message: "Credit successfully reversed." });
    await queryClient.invalidateQueries(["getTenantLedger"]);

    onSuccess();
  }

  return (
    <Form form={form} onSubmit={handleFormSubmit}>
      <Stack spacing={Spacing.xs}>
        <Skeleton visible={isLoading}>
          <Text size={FontSize.sm}>
            Processing a reversal will create offsetting entries for this lease
            credit. The reversing entry will appear on both the General Ledger
            and the Tenant Ledger
          </Text>
          <FormDateInput
            minDate={ledgerEvent.pipe(
              O.map((val) => LocalDate$.of(val.specificLedgerDate)),
              O.getOrThrow,
            )}
            label="Reversal Date"
            name="reversalDate"
            form={form}
          />
          <Group justify={Justify.end}>
            <Button type="submit" loading={isLoading}>
              Confirm
            </Button>
          </Group>
        </Skeleton>
      </Stack>
    </Form>
  );
}

export { ReverseCreditForm };
