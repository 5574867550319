/**
 * HTTP Method: POST
 * Pathname: /middleLayer/ap-ar-additional-info
 * @function getPayablesReceivablesAdditionalInfo
 * @memberof InvoicesMiddleLayerAPI
 * @param {InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPayablesReceivablesAdditionalInfoResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetPayablesReceivablesAdditionalInfoResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoSearchParams = {
  token?: string | undefined;
};

type InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoBodyParams = {
  invoiceIds: EnderId[];
  invoiceType: InvoiceInvoiceType;
};

type InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoPayload =
  InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoSearchParams &
    InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoBodyParams;

function getPayablesReceivablesAdditionalInfoUnsafeEffect(
  payload: InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPayablesReceivablesAdditionalInfoResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<GetPayablesReceivablesAdditionalInfoResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/ap-ar-additional-info",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPayablesReceivablesAdditionalInfo(
  payload: InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPayablesReceivablesAdditionalInfoResponse> {
  return F.pipe(
    payload,
    getPayablesReceivablesAdditionalInfoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPayablesReceivablesAdditionalInfo };
export type {
  InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoBodyParams,
  InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoPayload,
  InvoicesMiddleLayerAPIGetPayablesReceivablesAdditionalInfoSearchParams,
};
