/**
 * HTTP Method: POST
 * Pathname: /vehicles
 * @function addVehicle
 * @memberof LeasingAPI
 * @param {LeasingAPIAddVehiclePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Vehicle>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Vehicle } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIAddVehicleSearchParams = {
  token?: string | undefined;
};

type LeasingAPIAddVehicleBodyParams = {
  color: string;
  leaseId: EnderId;
  licensePlateNumber: string;
  make: string;
  model: string;
  parkingSpot: string;
};

type LeasingAPIAddVehiclePayload = LeasingAPIAddVehicleSearchParams &
  LeasingAPIAddVehicleBodyParams;

function addVehicleUnsafeEffect(payload: LeasingAPIAddVehiclePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Vehicle>({
        body,
        decode: unsafeDecodeJsonResponse<Vehicle>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/vehicles",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addVehicle(
  payload: LeasingAPIAddVehiclePayload,
  options?: { signal?: AbortSignal },
): Promise<Vehicle> {
  return F.pipe(
    payload,
    addVehicleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addVehicle };
export type {
  LeasingAPIAddVehicleBodyParams,
  LeasingAPIAddVehiclePayload,
  LeasingAPIAddVehicleSearchParams,
};
