import { clsx } from "clsx";
import {
  addMonths,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths,
} from "date-fns";
import {
  Array as A,
  Function as F,
  Option as O,
  Predicate as P,
  flow,
  pipe,
} from "effect";
import type { MouseEvent } from "react";
import { useMemo, useState } from "react";
import type {
  DateRange,
  DayEventHandler,
  Matcher,
  MonthCaptionProps,
  PropsMulti,
  PropsRange,
  PropsSingle,
  DayPickerProps as RDPProps,
} from "react-day-picker";
import {
  DayFlag,
  DayPicker as ReactDayPicker,
  SelectionState,
  UI,
  useDayPicker,
} from "react-day-picker";

import { LocalDate$ } from "@ender/shared/core";

import { CalendarHeader } from "../shared-ds-calendar-header";

type DayPickerProps = {
  /**
   * the month shown in the calendar
   */
  month: LocalDate$.LocalDate;
  /**
   * invoked when navigating between months
   */
  onMonthChange: (value: LocalDate$.LocalDate) => void;
  /**
   * The reason this is an array of `Option` instead of just an array of LocalDate is because this pattern supports:
   * - single date selection
   * - multiple date selection
   * - range selection where the first or last date is not yet selected
   *
   * Array of Option of LocalDate represents the lowest common denominator of all of these use cases.
   */
  value: O.Option<LocalDate$.LocalDate>[];
  onChange: (
    value: [
      O.Option<LocalDate$.LocalDate>,
      ...O.Option<LocalDate$.LocalDate>[],
    ],
  ) => void;

  /**
   * which of the `value` dates is currently being selected. Used for determining which dates to highlight
   * as part of the potential range selection.
   */
  activeValueIndex?: number;

  /**
   * a function invoked when the user requests to go "up" a level of granularity
   * e.g. from days -> months -> years
   */
  goUp?: () => void;

  minDate?: LocalDate$.LocalDate;
  maxDate?: LocalDate$.LocalDate;
  mode?: RDPProps["mode"];
  numberOfMonths?: RDPProps["numberOfMonths"];
  onDayClick?: RDPProps["onDayClick"];
};

const DayHeader = ({
  goUp,
  minDate,
  maxDate,
}: {
  goUp?: () => void;
  minDate?: LocalDate$.LocalDate;
  maxDate?: LocalDate$.LocalDate;
}) =>
  function DayHeader({ id, calendarMonth }: MonthCaptionProps) {
    const nav = useDayPicker();

    return (
      <CalendarHeader
        id={id}
        period="Month"
        onPrevClick={() => {
          if (P.isNotNullable(nav.previousMonth)) {
            nav.goToMonth(nav.previousMonth);
          }
        }}
        onNextClick={() => {
          if (P.isNotNullable(nav.nextMonth)) {
            nav.goToMonth(nav.nextMonth);
          }
        }}
        labelText={format(calendarMonth.date, "MMMM yyyy")}
        onLabelClick={goUp}
        //is minDate after the end of the prior year?
        prevDisabled={
          P.isNotNullable(minDate) &&
          isAfter(
            minDate.toDate(),
            endOfMonth(subMonths(calendarMonth.date, 1)),
          )
        }
        //is maxDate before the start of the next year?
        nextDisabled={
          P.isNotNullable(maxDate) &&
          isBefore(
            maxDate.toDate(),
            startOfMonth(addMonths(calendarMonth.date, 1)),
          )
        }
      />
    );
  };

const DayPicker = (props: DayPickerProps) => {
  const {
    activeValueIndex,
    goUp,
    maxDate,
    minDate,
    mode = "single",
    month,
    numberOfMonths,
    onChange,
    onDayClick,
    onMonthChange = F.constVoid,
    value,
  } = props;

  const [modifiers, setModifiers] = useState<
    Record<string, Matcher | Matcher[]>
  >({});
  const handleMouseEnter: DayEventHandler<MouseEvent> = (day) => {
    setModifiers({
      /**
       * the range of dates that will be selected when the user clicks on a date. Based on the current `activeValueIndex`
       */
      futureSelection: {
        from: pipe(
          value,
          //TODO there must be a more effect-y way of doing this
          (v) =>
            P.isNotNullable(activeValueIndex)
              ? A.get((activeValueIndex + 1) % value.length)(v)
              : O.none(),
          O.flatMap(O.map((v) => v.toDate())),
          O.getOrUndefined,
        ),
        to: day,
      },
    });
  };

  const ensureProps = useMemo<PropsSingle | PropsMulti | PropsRange>(() => {
    switch (mode) {
      case "single":
        return {
          mode,
          onSelect: (date?: Date) => onChange([LocalDate$.parse(date)]),
          selected: pipe(
            value,
            A.append(O.none<LocalDate$.LocalDate>()),
            A.headNonEmpty,
            O.map((v) => v.toDate()),
            O.getOrUndefined,
          ),
        };
      case "multiple":
        return {
          mode,
          onSelect: (date: Date[] = []) =>
            onChange(
              pipe(date, A.map(LocalDate$.parse), (arr) =>
                A.isNonEmptyArray(arr)
                  ? arr
                  : A.make(O.none<LocalDate$.LocalDate>()),
              ),
            ),
          selected: pipe(
            value,
            A.getSomes,
            A.map((v) => v.toDate()),
          ),
        };
      case "range":
        return {
          mode,
          onSelect: (date?: DateRange) => {
            setModifiers({});
            onChange([
              LocalDate$.parse(date?.from),
              LocalDate$.parse(date?.to),
            ]);
          },
          selected: pipe(
            value,
            A.appendAll([
              O.none<LocalDate$.LocalDate>(),
              O.none<LocalDate$.LocalDate>(),
            ]),
            //get only the first 2 dates (used as from and to)
            A.take(2),
            flow((val) => {
              return {
                from: pipe(
                  val[0],
                  O.map((v) => v.toDate()),
                  O.getOrUndefined,
                ),
                to: pipe(
                  val[1],
                  O.map((v) => v.toDate()),
                  O.getOrUndefined,
                ),
              };
            }),
          ),
        };
    }
  }, [mode, value, onChange]);

  const minMax: Matcher[] = [];
  if (P.isNotNullable(minDate)) {
    minMax.push({ before: minDate.toDate() });
  }
  if (P.isNotNullable(maxDate)) {
    minMax.push({ after: maxDate.toDate() });
  }

  const numSelected = pipe(value, A.getSomes, A.length);

  return (
    <ReactDayPicker
      numberOfMonths={numberOfMonths}
      showOutsideDays
      hideNavigation
      month={month.toDate()}
      disabled={minMax}
      onMonthChange={(month) => onMonthChange(LocalDate$.makeFromDate(month))}
      onDayMouseEnter={handleMouseEnter}
      modifiers={modifiers}
      modifiersClassNames={{
        //highlight the range of dates that will be selected when the user clicks on a date. Already selected dates receive a darker coloration.
        futureSelection:
          "bg-primary-50 aria-selected:bg-[--day-selected-color] [--day-selected-color:--color-primary-300]",
      }}
      onDayClick={onDayClick}
      {...ensureProps}
      components={{
        MonthCaption: DayHeader({ goUp, minDate, maxDate }),
      }}
      classNames={{
        [UI.Root]: "group/daypicker",
        [UI.Nav]: "flex justify-between",
        [UI.Day]: "p-0",
        [UI.DayButton]:
          "h-9 w-9 hover:text-slate-900 hover:bg-primary-100 active:bg-primary-200 rounded",
        [UI.Month]: "w-63",
        [UI.Months]: "flex",
        [DayFlag.outside]: "text-gray-200",
        [DayFlag.disabled]: "text-gray-200 pointer-events-none",
        [DayFlag.today]: "bg-gray-100 rounded",
        [SelectionState.selected]: clsx(
          "text-white bg-[--day-selected-color]",
          {
            "rounded [--day-selected-color:--color-primary-500]":
              mode !== "range" || numSelected === 1,
            "rounded-none": mode === "range" && numSelected > 1,
          },
        ),
        [SelectionState.range_start]:
          "rounded-l [--day-selected-color:--color-primary-500]",
        [SelectionState.range_middle]:
          //use group selector to set the variable with higher specificity
          "aria-selected:text-slate-900 [--day-selected-color:--color-primary-200]",
        [SelectionState.range_end]:
          "rounded-r [--day-selected-color:--color-primary-500]",
      }}
    />
  );
};
DayPicker.displayName = "DayPicker";

export { DayPicker };

export type { DayPickerProps };
