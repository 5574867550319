import { zodResolver } from "@mantine/form";
import { IconChevronLeft } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { clsx } from "clsx";
import {
  Function as F,
  Option as O,
  Predicate as P,
  String as S,
  pipe,
} from "effect";
import { useCallback, useState } from "react";
import { z } from "zod";

import { PlaceInput } from "@ender/entities/place-input";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { H1 } from "@ender/shared/ds/heading";
import { PhoneInput } from "@ender/shared/ds/phone-input";
import { TextInput } from "@ender/shared/ds/text-input";
import { useForm } from "@ender/shared/forms/hooks/general";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { ApplicationIdentityVerificationResult } from "@ender/shared/generated/ender.model.leasing";
import { ApplicationIdentityVerificationResultEnum } from "@ender/shared/generated/ender.model.leasing";
import { PhoneSchema } from "@ender/shared/types/ender-general";
import { EnderDatePicker } from "@ender/shared/ui/ender-date-picker";
import { EnderLink } from "@ender/shared/ui/ender-link";
import { Text } from "@ender/shared/ds/text";
import { SsnInput } from "@ender/shared/ui/ssn-input";
import { EnderDate, EnderDateSchema } from "@ender/shared/utils/ender-date";
import { PlaceSchema } from "@ender/shared/utils/google-maps";
import { OptionSchema } from "@ender/shared/utils/zod";

import { IdentityQuestions } from "../modals/identity-questions";
import type { ApplyStepProps } from "./step-props";

import commonStyles from "./common.module.css";
import styles from "./credit.module.css";

const FailedStatuses: (ApplicationIdentityVerificationResult | undefined)[] = [
  ApplicationIdentityVerificationResultEnum.FAILED,
  ApplicationIdentityVerificationResultEnum.MANUAL_VERIFICATION_REQUIRED,
];

const CreditFormSchema = z
  .object({
    birthday: EnderDateSchema.nullable().refine(P.isNotNullable, {
      message: "Date of Birth is required",
    }),
    phone: PhoneSchema,
    ssn: z.string(),
    email: z.string().min(1, "Email is required").email(),
    ssn2: z.string(),
    noSsn: z.boolean(),
    place: OptionSchema(PlaceSchema).refine(O.isSome, {
      message: "Current Address is required",
    }),
    isConvictedCriminal: z.boolean(),
    convictedCriminalDescription: z.string().nullable(),
  })
  .superRefine((schema, ctx) => {
    if (schema.noSsn) {
      return ctx;
    }
    if (!(schema.ssn?.trim() && schema.ssn2?.trim())) {
      const missingSsn = !schema.ssn?.trim();
      const missingSsnConfirmation = !schema.ssn2?.trim();
      missingSsn &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["ssn"],
          message: "SSN/ITIN is required",
        });
      missingSsnConfirmation &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["ssn2"],
          message: "SSN/ITIN Confirmation is required",
        });
      return ctx;
    }
    if (schema.ssn !== schema.ssn2) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["ssn"],
        message: "Provided SSN/ITIN values do not match",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["ssn2"],
        message: "Provided SSN/ITIN values do not match",
      });
    }
    if (
      schema.isConvictedCriminal &&
      !S.isNonEmpty(schema.convictedCriminalDescription ?? "")
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["convictedCriminalDescription"],
        message: "Description of conviction is required",
      });
    }

    return ctx;
  });

type CreditFormInput = z.input<typeof CreditFormSchema>;
type CreditFormOutput = z.output<typeof CreditFormSchema>;

const creditStepDescription = `Thanks for allowing us to confirm your identity & financial information.\n\nThe following information is used to perform a credit check and background check.`;

function ApplyStepCredit({
  applicationGroup,
  applications,
  user,
  progress,
  titleRef,
  identityVerificationQuestions,
  isLastStep = false,
  onRequestNext = F.constVoid,
  onRequestPrevious = F.constVoid,
}: ApplyStepProps) {
  const application = applications?.find(
    ({ applicant }) => applicant.userId === user?.userId,
  );

  /**
   * the form for the regular values of SSN, birthday, etc
   */
  const form = useForm<CreditFormInput>({
    initialValues: {
      noSsn: false,
      ssn: "",
      ssn2: "",
      email: application?.applicant.email ?? "",
      birthday: P.isNotNullable(application?.applicant.birthday)
        ? EnderDate.of(application.applicant.birthday)
        : NULL,
      phone: application?.applicant.phone ?? "",
      isConvictedCriminal: application?.isConvictedCriminal ?? false,
      convictedCriminalDescription:
        application?.convictedCriminalDescription ?? NULL,
      place: O.none(),
    },
    validate: zodResolver(CreditFormSchema),
  });

  /**
   * the form for TransUnion follow-up questions
   */
  const tuForm = useForm({
    initialValues: {},
    validate: zodResolver(
      z.object(
        Object.fromEntries(
          identityVerificationQuestions?.map(
            (question: { questionKeyName: string }) => [
              question.questionKeyName,
              z.unknown().refine(P.isNotNullable, { message: "Required" }),
            ],
          ) ?? [],
        ),
      ),
    ),
  });

  /**
   * if the appgroup has identityQuestions, this means the response from TransUnion
   * was to send follow-up IDV questions. It also implies that the original user info form has been submitted
   * because `identityQuestions` is not set until a specific `updateApplication` payload has been sent.
   *
   * In this case, we need to show the follow-up questions
   * and handle the submit of those questions separately from the main form
   */
  const hasIdQuestions = P.isNotNullable(identityVerificationQuestions);

  /**
   * if this step has failed. Used to show the 'needs manual verification' screen.
   */
  const failed = FailedStatuses.includes(
    application?.identityVerificationResult,
  );

  /**
   * show form if the appGroup does not have an identityVerificationResult at all.
   * This means they have never submitted this step, or they have submitted but follow-up IDV is still required.
   *
   * this is a state because we can force it to change. Other fields are memoized so they are automatically re-computed
   *
   */
  const [showForm, setShowForm] = useState<boolean>(
    P.isNullable(application?.identityVerificationResult),
  );

  const {
    mutateAsync: answerIdentityQuestions,
    isLoading: isAnsweringIdentityQuestions,
  } = useMutation({
    mutationFn: ApplicationsAPI.answerIdentityQuestions,
    mutationKey: ["ApplicationsAPI.answerIdentityQuestions"] as const,
  });
  /**
   * handle saving application identity answers.
   * If the ID verification step succeeds, and the credit step as a whole is complete, request the next step
   * otherwise, fail and show the 'needs manual verification' screen
   */
  const handleIdVerification = useCallback(
    async (values: Record<string, unknown>) => {
      if (P.isNotNullable(applicationGroup?.id)) {
        const res = await answerIdentityQuestions({
          applicationGroupId: applicationGroup.id,
          questions: values,
        });
        if (
          res.identityVerificationResult ===
          ApplicationIdentityVerificationResultEnum.FAILED
        ) {
          setShowForm(false);
        }
        onRequestNext();
      }
    },
    [answerIdentityQuestions, onRequestNext, applicationGroup?.id],
  );

  const { mutateAsync: submitApplication, isLoading: isSubmittingApplication } =
    useMutation({
      mutationFn: async (values: CreditFormOutput) => {
        const {
          birthday,
          phone,
          email,
          ssn,
          place,
          isConvictedCriminal,
          convictedCriminalDescription,
        } = values;
        if (P.isNotNullable(applicationGroup?.id)) {
          await ApplicationsAPI.updateApplication({
            applicationGroupId: applicationGroup.id,
            isConvictedCriminal,
            convictedCriminalDescription:
              convictedCriminalDescription ?? UNDEFINED,
          });
          const res = await ApplicationsAPI.submitApplication({
            applicationGroupId: applicationGroup.id,
            phone,
            email,
            ssn: values.noSsn ? UNDEFINED : ssn,
            place: pipe(place, O.getOrThrow),
            //TODO use LocalDate instead of EnderDate
            //@ts-expect-error EnderDate serializes into LocalDate so it should be fine
            birthday,
          });
          //change form shown status based on the response
          setShowForm(P.isNotNullable(res.identityVerificationQuestions));
          return res;
        }
      },
      mutationKey: [
        "ApplicationsAPI.updateApplication",
        "ApplicationsAPI.submitApplication",
      ] as const,
    });
  /**
   * handle saving the application normally. The presence of the `birthday` and `ssn` fields on the application
   * triggers the TU identity verification process.
   */
  const handleSubmit = useCallback(
    async (values: CreditFormOutput) => {
      if (showForm) {
        /**
         * if the step has never been submitted before, showForm will be true
         * and this block will be executed
         */
        await submitApplication(values);
        onRequestNext();
      }
    },
    [showForm, submitApplication, onRequestNext],
  );

  return (
    <form
      className={commonStyles.stepContainer}
      onSubmit={
        hasIdQuestions
          ? tuForm.onSubmit(handleIdVerification)
          : //@ts-expect-error handleSubmit will receive form output, which is validated to match the handleSubmit call
            form.onSubmit(handleSubmit)
      }>
      <div className={commonStyles.indented}>
        <div
          className={clsx(commonStyles.spanFullWidth, commonStyles.stepHeader)}
          ref={titleRef}>
          <div className={commonStyles.navBack}>
            <Button
              variant={ButtonVariant.transparent}
              leftSection={<IconChevronLeft />}
              onClick={onRequestPrevious}>
              Back
            </Button>
          </div>
          <H1>Credit & Background</H1>
          {progress}
        </div>
        {showForm ? (
          <>
            <div className={commonStyles.stepDescription}>
              <Text>
                {creditStepDescription}
              </Text>
            </div>
            {/* only shows follow-up ID verification questions if the form has them and the applicant has completed the main form */}
            {hasIdQuestions ? (
              <IdentityQuestions
                form={tuForm}
                questions={identityVerificationQuestions}
              />
            ) : (
              <>
                <div className={clsx(styles.halfWidth, styles.firstCell)}>
                  <EnderDatePicker
                    label="Date of Birth"
                    {...form.getInputProps("birthday")}
                  />
                </div>
                <div className={styles.halfWidth}>
                  <PlaceInput
                    {...form.getInputProps("place")}
                    label="Current Address"
                    placeholder="123 Main St"
                  />
                </div>
                <div className={styles.halfWidth}>
                  <PhoneInput
                    label="Phone Number"
                    {...form.getInputProps("phone")}
                  />
                </div>
                <div className={styles.halfWidth}>
                  <TextInput
                    label="Email Address"
                    {...form.getInputProps("email")}
                  />
                </div>
                <div className={styles.halfWidth}>
                  <SsnInput
                    disabled={form.values.noSsn}
                    label="SSN/ITIN"
                    {...form.getInputProps("ssn")}
                  />
                </div>
                <div className={styles.halfWidth}>
                  <SsnInput
                    disabled={form.values.noSsn}
                    label="Confirm SSN/ITIN"
                    {...form.getInputProps("ssn2")}
                  />
                </div>
                <div className={styles.halfWidth}>
                  <Checkbox
                    label="I do not have SSN/ITIN"
                    {...form.getInputProps("noSsn")}
                    onChange={(value) => {
                      form.getInputProps("noSsn").onChange(value);
                      form.setFieldError("ssn", "");
                      form.setFieldError("ssn2", "");
                    }}
                  />
                </div>
                <div className={clsx(styles.halfWidth, styles.firstCell)}>
                  <Checkbox
                    label="Have you ever been convicted of anything other than a traffic violation?"
                    {...form.getInputProps("isConvictedCriminal")}
                  />
                </div>
                <div className={clsx(styles.halfWidth, styles.firstCell)}>
                  {form.values.isConvictedCriminal && (
                    <TextInput
                      label="If yes, please explain"
                      {...form.getInputProps("convictedCriminalDescription")}
                      disabled={!form.values.isConvictedCriminal}
                    />
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={commonStyles.stepDescription}>
              <Text>
                {failed
                  ? ""
                  : "Your information has been submitted successfully."}
              </Text>
            </div>
            {failed && (
              <div className={commonStyles.spanFullWidth}>
                <Text>
                  We are unable to verify your identity online. Please call
                  TransUnion customer support at (833) 458-6338 for assistance
                  with completing your screening over the phone.
                  <br />
                  <br />
                  For reference, your TransUnion screening request ID is:{" "}
                  {application?.transunionScreeningRequestId}
                  <br />
                  <br />
                  {application?.identityVerificationResult === "FAILED" && (
                    <>
                      If you think you may have entered incorrect information,{" "}
                      <EnderLink
                        onClick={() => {
                          setShowForm(true);
                        }}>
                        click here to return to the previous page and resubmit.
                      </EnderLink>
                    </>
                  )}
                </Text>
              </div>
            )}
          </>
        )}
      </div>
      <div className={commonStyles.backButton}>
        <Button
          variant={ButtonVariant.transparent}
          leftSection={<IconChevronLeft />}
          onClick={onRequestPrevious}>
          Back
        </Button>
      </div>
      <div className={commonStyles.submitButton}>
        {showForm ? (
          <Button
            loading={isAnsweringIdentityQuestions || isSubmittingApplication}
            type="submit">
            {isLastStep ? "Submit Application" : "Continue"}
          </Button>
        ) : (
          <Button onClick={onRequestNext} disabled={failed}>
            Continue
          </Button>
        )}
      </div>
    </form>
  );
}

export { ApplyStepCredit };
