import { cva } from "class-variance-authority";
import { Predicate as P } from "effect";
import type { ReactNode } from "react";
import { useRouteMatch } from "react-router-dom";

import { Text } from "@ender/shared/ds/text";

import { RouterLink } from "./router-link";

const NavLinkVariantGenerator = cva([
  "flex flex-col gap-0.5 items-center justify-center py-1 text-white rounded",
  "data-[active=true]:bg-primary-500 hover:bg-primary-700",
]);

type NavIconLinkProps = {
  activeOnlyWhenExact?: boolean;
  href: string;
  label: ReactNode;
  icon: ReactNode;
  mobile?: boolean;
};

type LinksProps = Pick<NavIconLinkProps, "mobile">;

/**
 * @deprecated move this to the `nav` package
 */
function NavIconLink(props: NavIconLinkProps) {
  const { activeOnlyWhenExact = false, href, mobile, icon, label } = props;
  // TODO enable this when we're on Next
  // const pathname = usePathname();
  const match = useRouteMatch({
    exact: activeOnlyWhenExact,
    path: href,
  });

  return (
    <RouterLink href={href}>
      <button
        className={NavLinkVariantGenerator()}
        data-active={P.isNotNullable(match)}
        role="presentation">
        <div hidden={mobile} className="text-lg">
          {icon}
        </div>
        <Text color="white" size={mobile ? "lg" : "sm"} align="center">
          {label}
        </Text>
      </button>
    </RouterLink>
  );
}

export { NavIconLink };
export type { LinksProps, NavIconLinkProps };
