import { applicationsTableStatusCount } from "./applications-table-status-count";
import { getApplicationApprovals } from "./get-application-approvals";
import { getSingleApplicationApproval } from "./get-single-application-approval";
import { searchApplicationsPageTable } from "./search-applications-page-table";

const ApplicationsMiddleLayerAPI = {
  applicationsTableStatusCount,
  getApplicationApprovals,
  getSingleApplicationApproval,
  searchApplicationsPageTable,
};

export { ApplicationsMiddleLayerAPI };
