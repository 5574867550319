/**
 * HTTP Method: POST
 * Pathname: /announcements/sendToApplicants
 * @function sendApplicantAnnouncement
 * @memberof AnnouncementAPI
 * @param {AnnouncementAPISendApplicantAnnouncementPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AnnouncementAPIAppGroupApplicantPair } from "../announcement-api-app-group-applicant-pair";
import type { AnnouncementAPIFile } from "../announcement-api-file";

type AnnouncementAPISendApplicantAnnouncementSearchParams = {
  token?: string | undefined;
};

type AnnouncementAPISendApplicantAnnouncementBodyParams = {
  applicationsWithApplicants: AnnouncementAPIAppGroupApplicantPair[];
  body?: string | undefined;
  files: AnnouncementAPIFile[];
  renderBody?: boolean | undefined;
  subject?: string | undefined;
  templateId?: EnderId | undefined;
  useNoReplyEmail?: boolean | undefined;
  useNoReplyPhone?: boolean | undefined;
};

type AnnouncementAPISendApplicantAnnouncementPayload =
  AnnouncementAPISendApplicantAnnouncementSearchParams &
    AnnouncementAPISendApplicantAnnouncementBodyParams;

function sendApplicantAnnouncementUnsafeEffect(
  payload: AnnouncementAPISendApplicantAnnouncementPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/announcements/sendToApplicants",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendApplicantAnnouncement(
  payload: AnnouncementAPISendApplicantAnnouncementPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendApplicantAnnouncementUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendApplicantAnnouncement };
export type {
  AnnouncementAPISendApplicantAnnouncementBodyParams,
  AnnouncementAPISendApplicantAnnouncementPayload,
  AnnouncementAPISendApplicantAnnouncementSearchParams,
};
