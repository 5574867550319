import { useMutation } from "@tanstack/react-query";

import {
  createPayableInvoiceWithFilesUpload,
  createReceivableInvoiceWithFilesUpload,
} from "@ender/shared/api/invoices";
import type { Undefined } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UploadRail } from "@ender/shared/ui/upload-rail";
import { fail } from "@ender/shared/utils/error";
import { isMultiple } from "@ender/shared/utils/is";
import { withWarningHandler } from "@ender/shared/utils/rest";

import { InvoiceUploadRailHeader } from "./invoice-upload-rail-header";

type UploadInvoiceRailProps = {
  isPayableInvoice?: boolean | Undefined;
  isReceivableInvoice?: boolean | Undefined;
  onClose: () => void;
  onSuccess: () => void;
  opened: boolean;
};

function UploadInvoiceRail({
  isPayableInvoice,
  isReceivableInvoice,
  onClose,
  onSuccess,
  opened,
}: UploadInvoiceRailProps) {
  const confirmation = useConfirmationContext();

  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };

  const { mutateAsync: createReceivableInvoiceWithWarnings } = useMutation({
    mutationFn: withWarningHandler(
      createReceivableInvoiceWithFilesUpload,
      onWarnings,
    ),
  });

  const { mutateAsync: createPayableInvoiceWithWarnings } = useMutation({
    mutationFn: withWarningHandler(
      createPayableInvoiceWithFilesUpload,
      onWarnings,
    ),
  });

  const handleSubmit = async (files: File[]): Promise<void> => {
    if (isMultiple(files)) {
      fail("You can only upload one file at a time.");
    }
    if (isReceivableInvoice) {
      await createReceivableInvoiceWithWarnings({
        files,
      });
    } else {
      await createPayableInvoiceWithWarnings({
        files,
      });
    }
  };

  return (
    <UploadRail
      title="Select Invoice File"
      header={
        <InvoiceUploadRailHeader
          isReceivableInvoice={isReceivableInvoice}
          isPayableInvoice={isPayableInvoice}
        />
      }
      opened={opened}
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
    />
  );
}

export { UploadInvoiceRail };
