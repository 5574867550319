import { useEffect, useState } from "react";

type UseIntersectionObserverProps = {
  onIntersect: () => void;
  rootMargin?: string;
  threshold?: number | number[];
  root?: Element | Document | null | undefined;
};

const defaultThreshold = [0.5];

function useIntersectionObserver({
  onIntersect,
  root,
  rootMargin = "-250px 0px 0px 0px",
  threshold = defaultThreshold,
}: UseIntersectionObserverProps) {
  const [observedElement, setObservedElement] = useState<Element | null>();
  // lazy useEffect
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.isIntersecting) {
          onIntersect();
        }
      },
      { root, rootMargin, threshold },
    );
    observedElement && observer.observe(observedElement);
    return () => observer.disconnect();
  }, [root, rootMargin, threshold, onIntersect, observedElement]);

  return setObservedElement;
}

export { useIntersectionObserver };
