import { useInfiniteQuery } from "@tanstack/react-query";
import type { Row, SortingState, VisibilityState } from "@tanstack/react-table";
import {
  Array as A,
  Option as O,
  Predicate as P,
  Record as R,
  String as S,
} from "effect";
import { isNull, isNullable } from "effect/Predicate";
import { useCallback, useEffect, useMemo } from "react";
import { z } from "zod";

import { useAuthActor } from "@ender/features/auth";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { ButtonVariant } from "@ender/shared/ds/button";
import type { PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload } from "@ender/shared/generated/com.ender.middle";
import { PaymentsMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type {
  SortBySortColumn,
  TenantReceiptDepositSearchRequestSortBy,
} from "@ender/shared/generated/com.ender.middle.request";
import { SortBySortColumnEnum } from "@ender/shared/generated/com.ender.middle.request";
import type { TenantReceiptDepositSearchResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  MoneyTransferTransferStatusEnum,
  MoneyTransferTransferTypeEnum as TransferTypeEnum,
} from "@ender/shared/generated/ender.model.payments";
import {
  DEFAULT_ACCOUNTING_END_DATE,
  DEFAULT_ACCOUNTING_START_DATE,
  useAccountingFiltersStore,
} from "@ender/shared/stores/accounting-filters-store";
import type {
  EnderTableTopAction,
  UseTableReturn,
} from "@ender/shared/ui/table-tanstack";
import {
  useTable,
  useTableColumnPinning,
  useTableColumnVisibility,
  useTableFilter,
  useTablePagination,
  useTableRowSelection,
  useTableSorting,
} from "@ender/shared/ui/table-tanstack";
import { castEnum } from "@ender/shared/utils/zod";

import {
  getPeriodFilterProp,
  processDate,
} from "../../../../shared/utils/accounting-utils";
import { columns } from "./check-receipts-table-columns";
import type {
  CheckReceiptsTableMeta,
  CheckReceiptsTableRow,
} from "./check-receipts-table.types";

const PAGE_SIZE = 50;

function useGetInitialColumnFilters() {
  const {
    accountingPropertyFilter,
    amount,
    endDate: endDateInclusive,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    startDate,
  } = useAccountingFiltersStore();

  const initialFilters = [];

  if (!isNull(accountingPropertyFilter)) {
    initialFilters.push({
      id: "propertyName",
      value: accountingPropertyFilter,
    });
  }

  if (!isNull(amount)) {
    initialFilters.push({
      id: "amount",
      value: amount,
    });
  }

  if (endDateInclusive !== DEFAULT_ACCOUNTING_END_DATE) {
    initialFilters.push({
      id: "receiptEndDate",
      value: endDateInclusive,
    });
  }

  if (!isNull(firmFilter)) {
    initialFilters.push({
      id: "firm",
      value: firmFilter,
    });
  }

  if (!isNull(fundFilter)) {
    initialFilters.push({
      id: "fund",
      value: fundFilter,
    });
  }

  if (!isNull(paymentType)) {
    initialFilters.push({
      id: "paymentType",
      value: paymentType,
    });
  }

  if (A.isNonEmptyArray(periodFilter)) {
    initialFilters.push({
      id: "period",
      value: periodFilter,
    });
  }

  if (startDate !== DEFAULT_ACCOUNTING_START_DATE) {
    initialFilters.push({
      id: "receiptStartDate",
      value: startDate,
    });
  }

  return initialFilters;
}

function firstKeyOf(obj: object): string {
  for (const key in obj) {
    return key;
  }
  return "";
}

const SortKeyValues = [
  "amount",
  "bankAccount_name",
  "checkNumber",
  "displayPaymentType",
  "propertyName",
  "receiptDate",
  "tenantName",
  "unitName",
] as const;
const SortKeySchema = z.enum(SortKeyValues);
type SortKey = z.infer<typeof SortKeySchema>;
const SortKeyEnum = castEnum<SortKey>(SortKeySchema);

const ColumnToSortColumnMap: Record<SortKey, SortBySortColumn> = {
  [SortKeyEnum.amount]: SortBySortColumnEnum.AMOUNT,
  [SortKeyEnum.bankAccount_name]: SortBySortColumnEnum.BANK_ACCOUNT_NAME,
  [SortKeyEnum.checkNumber]: SortBySortColumnEnum.CHECK_NUMBER,
  [SortKeyEnum.displayPaymentType]: SortBySortColumnEnum.PAYMENT_TYPE,
  [SortKeyEnum.propertyName]: SortBySortColumnEnum.PROPERTY_NAME,
  [SortKeyEnum.receiptDate]: SortBySortColumnEnum.RECEIPT_DATE,
  [SortKeyEnum.tenantName]: SortBySortColumnEnum.TENANT_NAME,
  [SortKeyEnum.unitName]: SortBySortColumnEnum.UNIT_NAME,
} as const;

type MakeCheckReceiptsRequestPayloadArgs = Pick<
  ReturnType<typeof useAccountingFiltersStore>,
  | "accountingPropertyFilter"
  | "amount"
  | "endDate"
  | "firmFilter"
  | "fundFilter"
  | "paymentType"
  | "periodFilter"
  | "startDate"
> & {
  sortingState: SortingState;
};

function makeCheckReceiptsRequestPayload(
  args: MakeCheckReceiptsRequestPayloadArgs,
): PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload {
  const {
    accountingPropertyFilter,
    amount,
    endDate: endDateInclusive,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    sortingState,
    startDate,
  } = args;

  const propertyIds = A.isNonEmptyArray(accountingPropertyFilter)
    ? accountingPropertyFilter?.map((prop) => prop.id)
    : [];
  const periods = getPeriodFilterProp(periodFilter)?.customFilter;
  return {
    filterBy: {
      /**
       * BE IS FILTERING BY STRING, NOT MONEY
       * On top of that, they are expecting cents as a string
       * We filter off .00 so we can do "fuzzy" filtering
       */
      amountMatch: O.fromNullable(amount).pipe(
        O.match({
          onNone: () => UNDEFINED,
          onSome: (v) =>
            v.valueInCents % 100 === 0
              ? (v.valueInCents / 100).toString()
              : v.valueInCents.toString(),
        }),
      ),
      endDateInclusive,
      firmIds: firmFilter,
      fundIds: fundFilter,
      paymentTypes: !isNull(paymentType) ? [paymentType] : [],
      periods: periods?.map(processDate) || [],
      propertyIds,
      startDate,
      transferStatus: MoneyTransferTransferStatusEnum.IN_RECEIPTS,
    },
    sortBy: sortingState.reduce<TenantReceiptDepositSearchRequestSortBy[]>(
      (acc, { id, desc }) => {
        const sortColumn = SortKeyValues.includes(id as SortKey)
          ? ColumnToSortColumnMap[id as SortKey]
          : UNDEFINED;
        if (P.isNullable(sortColumn)) {
          return acc;
        }

        return acc.concat({
          column: sortColumn,
          descending: desc,
        });
      },
      [],
    ),
  };
}
type UseCheckReceiptsQueryProps = {
  sortingState: SortingState;
};

function useCheckReceiptsQuery({ sortingState }: UseCheckReceiptsQueryProps) {
  const {
    accountingPropertyFilter,
    amount,
    dateValidationState,
    endDate,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    startDate,
  } = useAccountingFiltersStore();

  const checkReceiptsRequestPayload = useMemo(() => {
    return makeCheckReceiptsRequestPayload({
      accountingPropertyFilter,
      amount,
      endDate,
      firmFilter,
      fundFilter,
      paymentType,
      periodFilter,
      sortingState,
      startDate,
    });
  }, [
    accountingPropertyFilter,
    amount,
    endDate,
    firmFilter,
    fundFilter,
    paymentType,
    periodFilter,
    sortingState,
    startDate,
  ]);

  return useInfiniteQuery<
    TenantReceiptDepositSearchResponse,
    unknown,
    TenantReceiptDepositSearchResponse,
    [string, PaymentsMiddleLayerAPITenantReceiptDepositSearchPayload]
  >(
    ["tenantReceiptSearch", checkReceiptsRequestPayload],
    async ({
      pageParam = "",
      queryKey: [, _checkReceiptsRequestPayload],
      signal,
    }) => {
      return await PaymentsMiddleLayerAPI.tenantReceiptDepositSearch(
        {
          ..._checkReceiptsRequestPayload,
          limit: PAGE_SIZE,
          startAfterId: pageParam,
        },
        { signal },
      );
    },
    {
      enabled: dateValidationState.isValidDateRange,
      getNextPageParam: (lastPage) => {
        return lastPage.results.length < PAGE_SIZE
          ? UNDEFINED
          : lastPage.results[PAGE_SIZE - 1].id;
      },
    },
  );
}

type UseCheckReceiptsTableProps = {
  title?: string;
  initialColumnPinning?: { left?: string; right?: string };
  initialVisibility?: VisibilityState;
  onAddCheckAction: (args: {
    table: UseTableReturn<CheckReceiptsTableRow, CheckReceiptsTableMeta>;
  }) => void;
  onCreateDepositAction: (args: {
    table: UseTableReturn<CheckReceiptsTableRow, CheckReceiptsTableMeta>;
  }) => void;
};
type UseCheckReceiptsTableResult = {
  table: UseTableReturn<CheckReceiptsTableRow, CheckReceiptsTableMeta>;
  clearRowSelection: () => void;
};

const initialSorting = [{ desc: true, id: "receiptDate" }];

function useCheckReceiptsTable({
  title = "Tenant Receipts",
  initialColumnPinning,
  initialVisibility,
  onAddCheckAction,
  onCreateDepositAction,
}: UseCheckReceiptsTableProps): UseCheckReceiptsTableResult {
  const {
    updateAccountingPropertyFilter,
    updateAmount,
    updateEndDate,
    updateFirmFilter,
    updateFundFilter,
    updatePaymentType,
    updatePeriodFilter,
    updateStartDate,
  } = useAccountingFiltersStore();
  const [authSnapshot] = useAuthActor();
  const {
    context: { session },
  } = authSnapshot;

  const isEnder = useMemo(() => {
    return O.match(session, {
      onNone: () => false,
      onSome: (s) => s.user.isEnder,
    });
  }, [session]);

  const enableRowSelection = useCallback(
    (
      row: Row<CheckReceiptsTableRow>,
      table: UseTableReturn<CheckReceiptsTableRow>,
    ): boolean => {
      const rowId = firstKeyOf(table.getState().rowSelection);
      const bankAccountId = S.isEmpty(rowId)
        ? undefined
        : table.getRow(rowId).original.bankAccount.id;
      const rowBankAccountId = row.original.bankAccount.id;
      return (
        (isNullable(bankAccountId) || rowBankAccountId === bankAccountId) &&
        (isEnder ||
          row.original.paymentType === TransferTypeEnum.MARK_PAID_CHECK)
      );
    },
    [isEnder],
  );

  const initialColumnFilters = useGetInitialColumnFilters();

  const columnFilters = useTableFilter<CheckReceiptsTableRow>({
    enableGlobalFilter: false,
    initialColumnFilters,
    manualFiltering: true,
  });

  const { columnFilters: columnFiltersState } = columnFilters;

  const sorting = useTableSorting({ initialSorting, manualSorting: true });
  const { sorting: sortingState, onSortingChange } = sorting;

  // Sync filter reset with accountingFilterStore
  useEffect(() => {
    if (A.isNonEmptyArray(columnFiltersState)) {
      return;
    }
    //empty the filters
    onSortingChange(initialSorting);
    updateAccountingPropertyFilter([]);
    updateAmount(NULL);
    updateEndDate(DEFAULT_ACCOUNTING_END_DATE);
    updateFirmFilter([]);
    updateFundFilter([]);
    updatePaymentType(NULL);
    updatePeriodFilter([]);
    updateStartDate(DEFAULT_ACCOUNTING_START_DATE);
  }, [
    columnFiltersState,
    onSortingChange,
    updateAccountingPropertyFilter,
    updateAmount,
    updateEndDate,
    updateFirmFilter,
    updateFundFilter,
    updatePaymentType,
    updatePeriodFilter,
    updateStartDate,
  ]);

  const columnPinning = useTableColumnPinning({ initialColumnPinning });
  const columnVisibility = useTableColumnVisibility({ initialVisibility });
  const pagination = useTablePagination({
    initialPagination: { pageIndex: 0, pageSize: PAGE_SIZE },
    manualPagination: true,
  });

  const rowSelection = useTableRowSelection<
    CheckReceiptsTableRow,
    CheckReceiptsTableMeta
  >({
    enableRowSelection,
    enableSelectAll: true,
  });

  const { onRowSelectionChange: setRowSelection } = rowSelection;
  const clearRowSelection = useCallback(
    () => setRowSelection({}),
    [setRowSelection],
  );

  const {
    data: fetchedReceiptsData,
    isFetching,
    fetchNextPage,
    refetch,
  } = useCheckReceiptsQuery({ sortingState });

  const data = useMemo(() => {
    const pages = fetchedReceiptsData?.pages ?? [];
    return pages.flatMap((page) => page.results);
  }, [fetchedReceiptsData]);

  const addCheckAction: EnderTableTopAction<
    CheckReceiptsTableRow,
    CheckReceiptsTableMeta
  > = useMemo(
    () => ({
      key: "AddCheck",
      label: "Add Check",
      onAction: onAddCheckAction,
      variant: ButtonVariant.filled,
    }),
    [onAddCheckAction],
  );

  const noSelections = R.isEmptyRecord(rowSelection.rowSelection);
  const createDepositAction: EnderTableTopAction<
    CheckReceiptsTableRow,
    CheckReceiptsTableMeta
  > = useMemo(
    () => ({
      disabled: isFetching || noSelections,
      key: "CreateDeposit",
      label: "Create Deposit",
      loading: isFetching,
      onAction: onCreateDepositAction,
      tooltip: "One or more receipts must be selected to create a deposit",
      variant: ButtonVariant.filled,
    }),
    [isFetching, noSelections, onCreateDepositAction],
  );

  const actions: EnderTableTopAction<
    CheckReceiptsTableRow,
    CheckReceiptsTableMeta
  >[] = useMemo(
    () => [addCheckAction, createDepositAction],
    [addCheckAction, createDepositAction],
  );

  const meta = useMemo(() => ({}), []);

  const totalResults = fetchedReceiptsData?.pages[0]?.totalResults ?? 0;

  const table = useTable<CheckReceiptsTableRow, CheckReceiptsTableMeta>({
    actions,
    columnFilters,
    columnPinning,
    columnVisibility,
    columns,
    data,
    enableQueryParams: true,
    fetchNextPage,
    hideResults: false,
    isLoading: isFetching,
    meta,
    pagination,
    refetch,
    rowSelection,
    sorting,
    title,
    totalResults,
  });

  return {
    clearRowSelection,
    table,
  };
}

export { useCheckReceiptsTable };
