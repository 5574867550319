import { useQuery } from "@tanstack/react-query";
import { Array as A } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { InvoiceResponseAllocationResponse } from "@ender/shared/generated/ender.arch.accounting";
import { EnderTableTanstack, useTable } from "@ender/shared/ui/table-tanstack";

import type { AllocationsTableProps } from "./allocations-table-columns";
import { columns } from "./allocations-table-columns";

import styles from "./allocations-table.module.css";

type AllocationsTablePropsAllocation = Pick<
  InvoiceResponseAllocationResponse,
  | "accountName"
  | "accountNumber"
  | "amount"
  | "payableCategoryName"
  | "payableSubCategoryName"
  | "propertyId"
>;

function AllocationsTable({
  allocations = [],
}: {
  allocations: AllocationsTablePropsAllocation[];
}) {
  const propertyIds = allocations.map(({ propertyId }) => propertyId);

  const { data: properties = [], isLoading } = useQuery({
    enabled: A.isNonEmptyArray(propertyIds),
    queryFn: () =>
      PropertiesAPI.getProperties({
        propertyIds,
      }),
    queryKey: ["getProperties", propertyIds],
  });

  const allocationsWithProperty = allocations.map((allocation) => {
    const property = properties?.properties?.find(
      (_property: { id: EnderId }) => _property.id === allocation.propertyId,
    );
    return {
      ...allocation,
      property,
    };
  });

  const table = useTable<AllocationsTableProps>({
    columns,
    data: allocationsWithProperty,
  });

  if (A.isEmptyArray(allocations)) {
    return <div>No allocations set</div>;
  }

  return (
    <Skeleton visible={isLoading}>
      <EnderTableTanstack
        className={styles.allocationsTable}
        table={table}
        title="Allocations"
      />
    </Skeleton>
  );
}

export { AllocationsTable };
export type { AllocationsTablePropsAllocation };
