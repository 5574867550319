/**
 * HTTP Method: POST
 * Pathname: /vehiclesOwner
 * @function addVehicleToOwner
 * @memberof LeasingAPI
 * @param {LeasingAPIAddVehicleToOwnerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Vehicle>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Vehicle } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIAddVehicleToOwnerSearchParams = {
  token?: string | undefined;
};

type LeasingAPIAddVehicleToOwnerBodyParams = {
  color: string;
  licensePlateNumber: string;
  make: string;
  model: string;
  ownerId: EnderId;
  parkingSpot: string;
};

type LeasingAPIAddVehicleToOwnerPayload =
  LeasingAPIAddVehicleToOwnerSearchParams &
    LeasingAPIAddVehicleToOwnerBodyParams;

function addVehicleToOwnerUnsafeEffect(
  payload: LeasingAPIAddVehicleToOwnerPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Vehicle>({
        body,
        decode: unsafeDecodeJsonResponse<Vehicle>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/vehiclesOwner",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addVehicleToOwner(
  payload: LeasingAPIAddVehicleToOwnerPayload,
  options?: { signal?: AbortSignal },
): Promise<Vehicle> {
  return F.pipe(
    payload,
    addVehicleToOwnerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addVehicleToOwner };
export type {
  LeasingAPIAddVehicleToOwnerBodyParams,
  LeasingAPIAddVehicleToOwnerPayload,
  LeasingAPIAddVehicleToOwnerSearchParams,
};
