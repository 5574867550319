/**
 * HTTP Method: POST
 * Pathname: /markets/{marketId}/assumptions
 * @function updateAssumptions
 * @memberof MarketsAPI
 * @param {MarketsAPIUpdateAssumptionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarketAssumptions>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { MarketAssumptions } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MarketsAPIUpdateAssumptionsPathParams = {
  marketId: EnderId;
};

type MarketsAPIUpdateAssumptionsSearchParams = {
  token?: string | undefined;
};

type MarketsAPIUpdateAssumptionsBodyParams = {
  firmId: EnderId;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type MarketsAPIUpdateAssumptionsPayload =
  MarketsAPIUpdateAssumptionsPathParams &
    MarketsAPIUpdateAssumptionsSearchParams &
    MarketsAPIUpdateAssumptionsBodyParams;

function updateAssumptionsUnsafeEffect(
  payload: MarketsAPIUpdateAssumptionsPayload,
) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarketAssumptions>({
        body,
        decode: unsafeDecodeJsonResponse<MarketAssumptions>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/markets/${marketId}/assumptions`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateAssumptions(
  payload: MarketsAPIUpdateAssumptionsPayload,
  options?: { signal?: AbortSignal },
): Promise<MarketAssumptions> {
  return F.pipe(
    payload,
    updateAssumptionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateAssumptions };
export type {
  MarketsAPIUpdateAssumptionsBodyParams,
  MarketsAPIUpdateAssumptionsPathParams,
  MarketsAPIUpdateAssumptionsPayload,
  MarketsAPIUpdateAssumptionsSearchParams,
};
