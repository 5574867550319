import { formatWithOptions } from "date-fns/fp";
import { enUS } from "date-fns/locale";
import { Option as O, pipe } from "effect";

import { LocalDate$ } from "@ender/shared/core";

const LONG_SLASH_FORMAT = "MM/dd/yyyy";

const longSlashFormat = formatWithOptions({ locale: enUS }, LONG_SLASH_FORMAT);

/**
 * Converts LocalDate$ to a string divided by slashes and the full year"
 * @returns {string} Formatted as "MM/dd/yyyy"
 * @example "11/13/2024"
 */

function toLongSlashString(input: LocalDate$.LocalDate | string): string {
  const localDate = LocalDate$.parse(input);

  return pipe(
    localDate,
    O.map((date) => longSlashFormat(date.toDate())),
    O.getOrThrow,
  );
}

export { toLongSlashString };
