/**
 * Dwolla webhook handler. This endpoint is to be registered in Dwolla to receive webhooks from them for payment
 processing and account setup events, to update the corresponding models in Ender.
 * HTTP Method: POST
 * Pathname: /dwolla/webhook
 * @function webhook
 * @memberof DwollaAPI
 * @param {DwollaAPIWebhookPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPIWebhookSearchParams = {
  token?: string | undefined;
};

type DwollaAPIWebhookPayload = DwollaAPIWebhookSearchParams;

function webhookUnsafeEffect(payload: DwollaAPIWebhookPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/dwolla/webhook",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function webhook(
  payload: DwollaAPIWebhookPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    webhookUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { webhook };
export type { DwollaAPIWebhookPayload, DwollaAPIWebhookSearchParams };
