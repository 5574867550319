import type { PropsWithChildren } from "react";
import { Fragment } from "react";

type RerenderOnDepsChangeProps = {
  deps: unknown[];
};

/**
 * @deprecated this can be replaced with a `key` everywhere it's used.
 * every `key` prop not within a `map` should _always_ include an explanatory doc comment justifying its usage
 * and proving that the dev understands why they need it. This component does not do enough but rather makes
 * it easier for devs to abuse react rendering without understanding it
 */
function RerenderOnDepsChange(
  props: PropsWithChildren<RerenderOnDepsChangeProps>,
) {
  const { deps, children } = props;
  return <Fragment key={JSON.stringify(deps)}>{children}</Fragment>;
}

export { RerenderOnDepsChange };
