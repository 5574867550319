import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const UnitZoneEnergyTypeValues = ["GAS", "ELECTRIC", "TANKLESS"] as const;

const UnitZoneEnergyTypeEffectSchema = Schema.Literal(
  ...UnitZoneEnergyTypeValues,
);

type UnitZoneEnergyType = Schema.Schema.Type<
  typeof UnitZoneEnergyTypeEffectSchema
>;

const UnitZoneEnergyTypeEnum = castEnum(UnitZoneEnergyTypeEffectSchema);

function randomUnitZoneEnergyType(): UnitZoneEnergyType {
  return rand(UnitZoneEnergyTypeValues);
}

export {
  randomUnitZoneEnergyType,
  UnitZoneEnergyTypeEffectSchema,
  UnitZoneEnergyTypeEnum,
  UnitZoneEnergyTypeValues,
};
export type { UnitZoneEnergyType };
