import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import { MoneyEffectSchema } from "@ender/form-system/schema";
import { LocalDateEffectSchema } from "@ender/shared/core";
import {
  AmenityAmenityTypeEffectSchema,
  PropertyCatPolicyEffectSchema,
  PropertyDogPolicyEffectSchema,
} from "@ender/shared/generated/ender.model.core.property";

const UpdateListingSchema = Schema.Struct({
  advertisedRent: Schema.OptionFromSelf(MoneyEffectSchema).pipe(
    Schema.filter(O.isSome, { message: () => "This field is required" }),
  ),
  amenities: Schema.Array(AmenityAmenityTypeEffectSchema),
  appliances: Schema.String,
  applicationFee: Schema.OptionFromSelf(MoneyEffectSchema).pipe(
    Schema.filter(O.isSome, { message: () => "This field is required" }),
  ),
  catPolicy: Schema.OptionFromSelf(PropertyCatPolicyEffectSchema),
  dogPolicy: Schema.OptionFromSelf(PropertyDogPolicyEffectSchema),
  hoaInformation: Schema.String,
  isFeatured: Schema.Boolean,
  marketingBody: Schema.NonEmptyString,
  marketingTitle: Schema.NonEmptyString,
  maxNumPets: Schema.OptionFromSelf(
    Schema.Number.pipe(
      Schema.positive({ message: () => "Must be greater than 0" }),
    ),
  ),
  maxNumVehicles: Schema.OptionFromSelf(
    Schema.Number.pipe(
      Schema.positive({ message: () => "Must be greater than 0" }),
    ),
  ),
  minimumLeaseDuration: Schema.OptionFromSelf(Schema.Number),
  moveInReadyDate: Schema.OptionFromSelf(LocalDateEffectSchema),
  offerEndDate: Schema.OptionFromSelf(LocalDateEffectSchema),
  offerStartDate: Schema.OptionFromSelf(LocalDateEffectSchema),
  photoUrls: Schema.Array(Schema.String),
  securityDeposit: Schema.OptionFromSelf(MoneyEffectSchema),
  tourReadyDate: Schema.OptionFromSelf(LocalDateEffectSchema),
});
type UpdateListingFormInput = Schema.Schema.Encoded<typeof UpdateListingSchema>;
type UpdateListingFormOutput = Schema.Schema.Type<typeof UpdateListingSchema>;

export { UpdateListingSchema };
export type { UpdateListingFormInput, UpdateListingFormOutput };
