import { Schema } from "@effect/schema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useCallback } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { UnitZonesAPI } from "@ender/shared/generated/ender.api.core";
import {
  UnitZoneUnitZoneTypeEffectSchema,
  UnitZoneUnitZoneTypeValues,
} from "@ender/shared/generated/ender.model.core.unit.zone";
import { capitalize } from "@ender/shared/utils/string";

const AddZoneFormSchema = Schema.Struct({
  description: Schema.String.pipe(Schema.trimmed()),
  type: UnitZoneUnitZoneTypeEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Zone is required." }),
  ),
});

type AddZoneFormValues = Schema.Schema.Type<typeof AddZoneFormSchema>;

const zoneOptions = UnitZoneUnitZoneTypeValues.map((value) => ({
  label: capitalize(value),
  value,
}));

type AddZoneToAreaFormProps = {
  areaId: EnderId;
  onClose: () => unknown;
  unitId: EnderId;
};

function AddZoneToAreaForm({
  areaId,
  onClose,
  unitId,
}: AddZoneToAreaFormProps) {
  const queryClient = useQueryClient();

  const form = useEffectSchemaForm({
    defaultValues: {
      description: "",
      type: O.none(),
    },
    schema: AddZoneFormSchema,
  });

  const { mutateAsync: createUnitZone, isLoading } = useMutation({
    mutationFn: UnitZonesAPI.createUnitZone,
    mutationKey: ["UnitZonesAPI.createUnitZone"],
  });

  const onSubmit = useCallback(
    async (values: AddZoneFormValues) => {
      await createUnitZone({
        areaId,
        description: values.description,
        type: O.getOrThrow(values.type),
      });
      onClose();
      queryClient.invalidateQueries(["UnitZonesAPI.getUnitZones", { unitId }]);
      queryClient.invalidateQueries([
        "UnitAreasZonesMiddleLayerAPI.getUnitAreaSummary",
        { unitId },
      ]);
    },
    [areaId, createUnitZone, onClose, queryClient, unitId],
  );

  const zoneType = form.watch("type").pipe(O.getOrUndefined, capitalize);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        <FormSelect form={form} label="Zone" name="type" data={zoneOptions} />
        <FormTextInput
          form={form}
          name="description"
          label="Zone Name"
          placeholder="Optional Descriptor"
          leftSection={
            <Text
              size={FontSize.md}
              weight={FontWeight.medium}
              color="slate-500">
              {zoneType}
            </Text>
          }
          disabled={O.isNone(form.watch("type"))}
        />
        <Group justify={Justify.end}>
          <Button variant={ButtonVariant.transparent} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={isLoading}>
            Add Zone
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { AddZoneToAreaForm };
