/**
 * HTTP Method: POST
 * Pathname: /systemReports/Working Trial Balance/run
 * @function runWorkingTrialBalanceReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunWorkingTrialBalanceReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<WorkingTrialBalanceReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { GLTxTxType } from "@ender/shared/generated/ender.model.accounting";
import type { WorkingTrialBalanceReportResponse } from "@ender/shared/generated/ender.service.reports.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIRunWorkingTrialBalanceReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunWorkingTrialBalanceReportBodyParams = {
  asOfDate: SerializesInto<LocalDate>;
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  periodFilter?: PeriodFilter | undefined;
  propertyIds: EnderId[];
  txType?: GLTxTxType | undefined;
};

type ReportsAPIRunWorkingTrialBalanceReportPayload =
  ReportsAPIRunWorkingTrialBalanceReportSearchParams &
    ReportsAPIRunWorkingTrialBalanceReportBodyParams;

function runWorkingTrialBalanceReportUnsafeEffect(
  payload: ReportsAPIRunWorkingTrialBalanceReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, WorkingTrialBalanceReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<WorkingTrialBalanceReportResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/Working Trial Balance/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runWorkingTrialBalanceReport(
  payload: ReportsAPIRunWorkingTrialBalanceReportPayload,
  options?: { signal?: AbortSignal },
): Promise<WorkingTrialBalanceReportResponse> {
  return F.pipe(
    payload,
    runWorkingTrialBalanceReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runWorkingTrialBalanceReport };
export type {
  ReportsAPIRunWorkingTrialBalanceReportBodyParams,
  ReportsAPIRunWorkingTrialBalanceReportPayload,
  ReportsAPIRunWorkingTrialBalanceReportSearchParams,
};
