import { z } from "zod";

import { EnderIdSchema, InstantSchema } from "@ender/shared/core";
import {
  LeaseIntentionEnum,
  LeaseIntentionValues,
  LeaseLeaseStatusValues,
  LeaseLeaseSubstatusValues,
  LeaseMoveOutReasonValues,
  LeaseUserRoleLeaseUserFlagValues,
} from "@ender/shared/generated/ender.model.leasing";
import type { ArrayValues } from "@ender/shared/types/general";
import { castEnum } from "@ender/shared/utils/zod";

import { GeneralLedgerAccountSchema } from "./general-ledger";
import { PropertySchema } from "./property";
import { UnitSchema } from "./unit";
import { UserSchema } from "./user";

const JsonUtilsTimeAgoResponseSchema = z.object({
  color: z.string(),
  minutesAgo: z.number().optional(),
  time: InstantSchema.optional(),
  timeAgo: z.string(),
});

const LeaseSerializerLeaseContactSchema = UserSchema.merge(
  z.object({
    driversLicense: z.string().optional(),
    hasSSN: z.boolean().optional(),
    isTermsAccepted: z.boolean().optional(),
    lastActive: JsonUtilsTimeAgoResponseSchema.optional(),
    roles: z.enum(LeaseUserRoleLeaseUserFlagValues).array(),
  }),
);

const DepositSchema = z.object({
  amount: z.string(),
  glCategory: GeneralLedgerAccountSchema,
});
type Deposit = z.infer<typeof DepositSchema>;

const SignatureSchema = z.object({
  email: z.string(),
  name: z.string(),
});
type Signature = z.infer<typeof SignatureSchema>;

const EXECUTED_LEASE_SUBSTATUSES = [
  "Active - Evicting",
  "Active - Non-renewable",
  "Active - Renewed",
  "Active",
  "Canceled",
  "Creating PDF",
  "Drafting Renewal",
  "Drafting",
  "Error",
  "Expiring",
  "Monthly - Post Term",
  "Monthly",
  "Move Out - Overdue",
  "Move Out Pending",
  "Moved Out - Balance",
  "Moved Out - Pending Deposit Accounting",
  "Moved Out - Refund",
  "Moved Out",
  "Needs Countersignature",
  "On Notice - Evicting",
  "On Notice",
  "Out for Signatures",
  "Renewed",
  "Upcoming",
] as const;

type ExecutedLeaseSubstatus = ArrayValues<typeof EXECUTED_LEASE_SUBSTATUSES>;

type LeaseDisplayStatusEnumProperty =
  | "ACTIVE"
  | "CANCELED"
  | "CREATING_PDF"
  | "DRAFTING"
  | "INACTIVE"
  | "NEEDS_FINAL_SIGNATURE"
  | "OUT_FOR_SIGNATURES"
  | "UPCOMING"
  | "ERROR";

const LeaseDisplayStatusValues = [
  "Active",
  "Canceled",
  "Creating PDF",
  "Drafting",
  "Inactive",
  "Needs Final Signature",
  "Out for Signatures",
  "Upcoming",
  "Error",
] as const;
const LeaseDisplayStatusSchema = z.enum(LeaseDisplayStatusValues);
type LeaseDisplayStatus = z.infer<typeof LeaseDisplayStatusSchema>;

/**
 * @enum {string} LeaseDisplayStatusEnum
 * @description Lease Display Status
 */
const LeaseDisplayStatusEnum: Record<
  LeaseDisplayStatusEnumProperty,
  LeaseDisplayStatus
> = {
  ACTIVE: "Active",
  CANCELED: "Canceled",
  CREATING_PDF: "Creating PDF",
  DRAFTING: "Drafting",
  INACTIVE: "Inactive",
  NEEDS_FINAL_SIGNATURE: "Needs Final Signature",
  OUT_FOR_SIGNATURES: "Out for Signatures",
  UPCOMING: "Upcoming",
  ERROR: "Error",
};

const LeaseRenewalOfferSchema = z.object({
  acceptedTimestamp: z.string().optional(),
  expirationDate: z.string(),
  id: EnderIdSchema,
  newEndDate: z.string().optional(),
  newRent: z.string(),
  rejectedTimestamp: z.string().optional(),
  rescindedTimestamp: z.string().optional(),
  sigRequestId: z.string().optional(),
  signedByPMTimestamp: z.string().optional(),
  signedByTenantsTimestamp: z.string().optional(),
});

type LeaseRenewalOffer = z.infer<typeof LeaseRenewalOfferSchema>;

const MoveOutStatusValues = [
  "EXPIRING",
  "MOVE_OUT_OVERDUE",
  "MOVE_OUT_PENDING_DEPOSIT_ACCOUNTING",
  "MOVED_OUT",
  "ON_NOTICE",
  "POST_TERM",
] as const;
const MoveOutStatusSchema = z.enum(MoveOutStatusValues);
type MoveOutStatus = z.infer<typeof MoveOutStatusSchema>;

/**
 * @enum {string} MoveOutStatusEnum
 * @description Move out status of Lease
 */
const MoveOutStatusEnum = castEnum<MoveOutStatus>(MoveOutStatusSchema);

/**
 * @deprecated use generated types
 */
const LeaseSchema = z.object({
  achReversalFee: z.string().optional(),
  actualMoveOutDate: z.string().optional(),
  allowOnlinePayments: z.boolean(),
  allowPartialPayments: z.boolean(),
  amountOwed: z.string(),
  applicationGroupId: EnderIdSchema.optional(),
  archived: z.boolean(),
  baseRent: z.string().optional(),
  collectionsPausedNote: z.string().optional(),
  collectionsPausedUntilDate: z.string().optional(),
  companyName: z.string(),
  conditionStatementSentTime: z.string().optional(),
  contacts: z.array(LeaseSerializerLeaseContactSchema).optional(),
  currentBalance: z.string(),
  daysUntilExpiration: z.number().optional(),
  depositReceiptSentTime: z.string().optional(),
  deposits: z.array(DepositSchema),
  endDate: z.string().optional(),
  expectedMoveOutDate: z.string(),
  expiring: z.boolean().optional(),
  firstPaymentDueDate: z.string().optional(),
  hasDraftingRenewal: z.boolean().optional(),
  // hasRenewal: true if this lease has a drafting or executed renewal
  hasRenewal: z.boolean().optional(),
  id: EnderIdSchema,
  inclusiveEndDate: z.string(),
  intention: z.enum(LeaseIntentionValues),
  isDrafting: z.boolean(),
  isMonthToMonth: z.boolean(),
  isUpcoming: z.boolean().optional(),
  lateFee: z.string(),
  leaseTemplateId: z.string(),
  leasingAgentId: EnderIdSchema.optional(),
  markedMonthToMonth: z.boolean(),
  marketId: EnderIdSchema.optional(),
  moveInBalanceOverdue: z.boolean().optional(),
  moveInCompleteTime: z.string().optional(),
  moveInDate: z.string().optional(),
  moveOutComplete: z.boolean().optional(),
  moveOutReason: z.enum(LeaseMoveOutReasonValues).optional(),
  moveOutStatus: MoveOutStatusSchema.optional(),
  name: z.string(),
  negotiating: z.boolean(),
  nonRenewalDocumentId: EnderIdSchema.optional(),
  noticeOfMoveOutDate: z.string().optional(),
  numGraceDays: z.number(),
  pendingSignatures: z.array(SignatureSchema),
  pmId: EnderIdSchema,
  // previousRenewedLeaseId: if this lease is a renewal, the EnderId of the old lease that got renewed
  previousRenewedLeaseId: EnderIdSchema.optional(),
  property: PropertySchema.optional(),
  renewalOffers: LeaseRenewalOfferSchema.array().optional(),
  startDate: z.string(),
  status: z.enum(LeaseLeaseStatusValues),
  substatus: z.enum(LeaseLeaseSubstatusValues),
  tenantInPlace: z.boolean(),
  timeLeft: z.string(),
  unit: UnitSchema.optional(),
});
/**
 * @deprecated use generated types
 */
type Lease = z.infer<typeof LeaseSchema>;

// Used for lease creation forms
const LeaseTermValues = [
  "CUSTOM",
  "MONTH_TO_MONTH",
  "SIX_MONTHS",
  "TWELVE_MONTHS",
  "EIGHTEEN_MONTHS",
] as const;
const LeaseTermSchema = z.enum(LeaseTermValues);
type LeaseTerm = z.infer<typeof LeaseTermSchema>;

const LeaseTermEnum = castEnum<LeaseTerm>(LeaseTermSchema);

const LeaseTermSelectOptions = [
  { label: "18 Months", value: LeaseTermEnum.EIGHTEEN_MONTHS },
  { label: "12 Months", value: LeaseTermEnum.TWELVE_MONTHS },
  { label: "6 Months", value: LeaseTermEnum.SIX_MONTHS },
  { label: "Month to Month", value: LeaseTermEnum.MONTH_TO_MONTH },
  { label: "Custom", value: LeaseTermEnum.CUSTOM },
];

const LeaseActionOptionsEnum = {
  ARCHIVE_LEASE: "Archive Lease",
  CANCEL_LEASE: "Cancel Lease",
  CANCEL_MOVE_OUT: "Cancel Move-Out",
  CANCEL_SIGNATURE_REQUEST: "Cancel Signature Request",
  CANCEL_UPCOMING_LEASE: "Cancel Upcoming Lease",
  EDIT_LEASE_DATES: "Edit Lease Dates",
  LEASE_SNAPSHOT: "Lease Snapshot",
  MARK_AS_MOVED_OUT: "Mark as Moved Out",
  MARK_TENANT_IN_PLACE: "Mark Tenant in Place",
  MOVE_OUT_ACCOUNTING: "Move-Out Accounting",
  MOVE_OUT_ACCOUNTING_V2: "Move-Out Accounting V2",
  RENEW_LEASE: "Renew Lease",
  REVIEW_MOVE_OUT: "Review Move-Out",
  SEND_SIGNATURE_REQUEST: "Send Signature Request",
  SET_APPLICATION: "Set Application",
  SET_EXPECTED_MOVE_OUT_DATE: "Set Expected Move-Out Date",
  SET_RENEGOTIATING: "Set Renegotiating",
  UNMARK_TENANT_IN_PLACE: "Unmark Tenant in Place",
  UNSET_RENEGOTIATING: "Unset Renegotiating",
  UPDATE_EXPECTED_MOVE_OUT_DATE: "Update Expected Move-Out Date",
  UPDATE_LEASE_INTENTION: "Update Lease Intention",
};

export {
  DepositSchema,
  EXECUTED_LEASE_SUBSTATUSES,
  LeaseActionOptionsEnum,
  LeaseDisplayStatusEnum,
  LeaseDisplayStatusSchema,
  LeaseIntentionEnum,
  LeaseRenewalOfferSchema,
  LeaseSchema,
  LeaseTermEnum,
  LeaseTermSchema,
  LeaseTermSelectOptions,
  LeaseTermValues,
  MoveOutStatusEnum,
  MoveOutStatusSchema,
  MoveOutStatusValues,
  SignatureSchema,
};

export type {
  Deposit,
  ExecutedLeaseSubstatus,
  Lease,
  LeaseDisplayStatus,
  LeaseRenewalOffer,
  LeaseTerm,
  MoveOutStatus,
  Signature,
};
