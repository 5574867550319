import type { ReactNode } from "react";

import type { ButtonProps } from "@ender/shared/ds/button";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Color } from "@ender/shared/utils/theming";

type LedgerEventReversalButtonProps = ButtonProps & {
  children: ReactNode;
};

function LedgerEventReversalButton(props: LedgerEventReversalButtonProps) {
  const { children, onClick, tooltip, ...buttonProps } = props;

  return (
    <Button
      variant={ButtonVariant.outlined}
      color={Color.red}
      onClick={onClick}
      tooltip={tooltip}
      {...buttonProps}>
      {children}
    </Button>
  );
}

export { LedgerEventReversalButton };
