import { IconClock, IconMessage } from "@tabler/icons-react";
import { Function as F, Option as O, Predicate as P } from "effect";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { Instant, LocalDate } from "@ender/shared/core";
import { Instant$, LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FontSize, Text } from "@ender/shared/ds/text";
import { MessageResponseSenderPartyTypeEnum } from "@ender/shared/generated/ender.api.leasing.response";
import type { LeadLeadSource } from "@ender/shared/generated/ender.model.leasing";
import { LeadLeadSourceEnum } from "@ender/shared/generated/ender.model.leasing";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { DateCell, asColumnDef } from "@ender/shared/ui/table-tanstack";
import { fromScreamingSnakeCaseToSpaceCase } from "@ender/shared/utils/string";

import type {
  ProspectsTableMeta,
  ProspectsTableRow,
} from "./prospects-table.types";

const SourceMap: Record<LeadLeadSource, string> = {
  [LeadLeadSourceEnum.ZILLOW]: "Zillow",
  [LeadLeadSourceEnum.APARTMENTS_COM]: "Apartments.com",
  [LeadLeadSourceEnum.COMPANY_WEBSITE]: "Company Website",
  [LeadLeadSourceEnum.ZUMPER]: "Zumper",
  [LeadLeadSourceEnum.OTHER]: "Other",
};

export const prospectsTableColumns: ColumnDef<ProspectsTableRow>[] = [
  asColumnDef<ProspectsTableRow, string, unknown, ProspectsTableMeta>({
    header: "Prospect",
    accessorKey: "name",
    enableSorting: false,
    cell: (props) => {
      const { name, phone, email } = props.row.original;
      const displayValue = name || phone || email || "-";

      const { setActiveProspectId } = props.table.options.meta;
      return (
        <Button
          variant={ButtonVariant.transparent}
          onClick={() => setActiveProspectId(props.row.original.id)}>
          {displayValue}
        </Button>
      );
    },
  }),
  asColumnDef<ProspectsTableRow, string>({
    header: "Property",
    accessorKey: "propertyName",
    enableSorting: false,
  }),
  asColumnDef<ProspectsTableRow, Instant | undefined>({
    header: "Follow Up",
    accessorKey: "followupTime",
    id: "followUpTime",
    sortDescFirst: false,
    cell: (props) => {
      const followUpDate = F.pipe(
        props.getValue(),
        Instant$.parse,
        O.map((v) => v.toLocalDate()),
      );
      const pastFollowDate = F.pipe(
        followUpDate,
        O.exists((date) => date.isBeforeOrEqual(LocalDate$.today())),
      );

      const senderPartyType = props.row.original.lastMessageSenderPartyType;
      const needsReply =
        senderPartyType !== MessageResponseSenderPartyTypeEnum.PROPERTY_MANAGER;
      const color = needsReply || pastFollowDate ? "red-500" : UNDEFINED;

      return (
        <Group spacing={Spacing.xs} align={Align.center}>
          {needsReply && <IconMessage />}
          {!needsReply && pastFollowDate && <IconClock />}
          {needsReply && (
            <Text color={color} size={FontSize.sm}>
              Needs Reply
            </Text>
          )}
          {!needsReply && (
            <Text color={color} size={FontSize.sm}>
              {F.pipe(
                followUpDate,
                O.map((date) =>
                  LocalDate$.toFormatted(date, LocalDate$.Formats.DEFAULT),
                ),
                O.getOrElse(F.constant("No Follow-Up Set")),
              )}
            </Text>
          )}
        </Group>
      );
    },
  }),
  asColumnDef<ProspectsTableRow, string | undefined>({
    header: "Unit",
    accessorKey: "unitName",
    enableSorting: false,
    cell: (props) => props.getValue() || "-",
  }),
  asColumnDef<ProspectsTableRow, string | undefined>({
    header: "Unit Status",
    accessorKey: "unitStatus",
    enableSorting: false,
    cell: (props) => {
      const value = props.getValue();
      return value ? fromScreamingSnakeCaseToSpaceCase(value) : "-";
    },
  }),
  asColumnDef<ProspectsTableRow, string | undefined>({
    header: "Phone",
    accessorKey: "phone",
    enableSorting: false,
    enableHiding: true,
    cell: (props) => props.getValue() || "-",
  }),
  asColumnDef<ProspectsTableRow, LocalDate | undefined>({
    header: "Prospect Move-in",
    accessorKey: "moveInDate",
    enableSorting: false,
    cell: (props) => (P.isNullable(props.getValue()) ? "-" : DateCell(props)),
  }),
  asColumnDef<ProspectsTableRow, string | undefined>({
    header: "Market",
    accessorKey: "marketName",
    id: "marketName",
    enableSorting: false,
    enableHiding: true,
    cell: (props) => props.getValue() || "-",
  }),
  asColumnDef<ProspectsTableRow, LeadLeadSource | undefined>({
    header: "Source",
    accessorKey: "leadSource",
    enableSorting: false,
    enableHiding: true,
    cell: (props) => {
      const source = props.getValue();
      return source ? SourceMap[source] : "-";
    },
  }),
  asColumnDef<ProspectsTableRow, string>({
    header: "Archive Reason",
    accessorKey: "archiveReason",
    enableSorting: false,
    enableHiding: true,
    cell: (props) => props.getValue() || "-",
  }),
];
