/**
 * HTTP Method: POST
 * Pathname: /middleLayer/leasing/getLeasesInRecovery
 * @function getLeasesInRecovery
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIGetLeasesInRecoveryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetLeasesInRecoveryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Instant, SerializesInto } from "@ender/shared/core";
import type { GetLeasesInRecoveryResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIGetLeasesInRecoverySearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIGetLeasesInRecoveryBodyParams = {
  movedOutSince: SerializesInto<Instant>;
  pmId: EnderId;
};

type LeasingMiddleLayerAPIGetLeasesInRecoveryPayload =
  LeasingMiddleLayerAPIGetLeasesInRecoverySearchParams &
    LeasingMiddleLayerAPIGetLeasesInRecoveryBodyParams;

function getLeasesInRecoveryUnsafeEffect(
  payload: LeasingMiddleLayerAPIGetLeasesInRecoveryPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetLeasesInRecoveryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetLeasesInRecoveryResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/leasing/getLeasesInRecovery",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getLeasesInRecovery(
  payload: LeasingMiddleLayerAPIGetLeasesInRecoveryPayload,
  options?: { signal?: AbortSignal },
): Promise<GetLeasesInRecoveryResponse[]> {
  return F.pipe(
    payload,
    getLeasesInRecoveryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getLeasesInRecovery };
export type {
  LeasingMiddleLayerAPIGetLeasesInRecoveryBodyParams,
  LeasingMiddleLayerAPIGetLeasesInRecoveryPayload,
  LeasingMiddleLayerAPIGetLeasesInRecoverySearchParams,
};
