/**
 * HTTP Method: DELETE
 * Pathname: /pets/{petId}
 * @function deletePet
 * @memberof PetsAPI
 * @param {PetsAPIDeletePetPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIDeletePetPathParams = {
  petId: EnderId;
};

type PetsAPIDeletePetSearchParams = {
  token?: string | undefined;
};

type PetsAPIDeletePetPayload = PetsAPIDeletePetPathParams &
  PetsAPIDeletePetSearchParams;

function deletePetUnsafeEffect(payload: PetsAPIDeletePetPayload) {
  const { petId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/pets/${petId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function deletePet(
  payload: PetsAPIDeletePetPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deletePetUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deletePet };
export type {
  PetsAPIDeletePetPathParams,
  PetsAPIDeletePetPayload,
  PetsAPIDeletePetSearchParams,
};
