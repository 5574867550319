import type { EnderId } from "@ender/shared/core";
import type { SelectOption } from "@ender/shared/ds/select";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import { isMultiple } from "@ender/shared/utils/is";

function getBankAccountsList(
  bankAccounts: BankAccount[],
): SelectOption<EnderId>[] {
  if (!bankAccounts || bankAccounts.length === 0) {
    return [];
  }

  return bankAccounts.map((account) => ({
    label: account.name,
    meta: account,
    value: account.id,
  }));
}

function getRecipientName(invoice: InvoiceSerializerInvoiceResponse): string {
  if (isMultiple(invoice.users)) {
    return invoice.users.map((user) => user.name).join(", ");
  }
  return (
    invoice.owedToParty?.companyName ||
    invoice.owedToParty?.name ||
    "External Merchant"
  );
}

export { getBankAccountsList, getRecipientName };
