import { FontSize, Text } from "@ender/shared/ds/text";

type ItemsSubmittedCellProps = {
  itemsSubmitted: number;
  itemsTotal: number;
};

function ItemsSubmittedCell({
  itemsSubmitted,
  itemsTotal,
}: ItemsSubmittedCellProps) {
  return (
    <Text size={FontSize.sm}>
      {itemsSubmitted}/{itemsTotal} items submitted
    </Text>
  );
}

export { ItemsSubmittedCell };
