import { Schema } from "@effect/schema";
import { Option as O } from "effect";

import { EnderIdFormSchema } from "@ender/shared/core";

const LeaseGeneratorFormSchema = Schema.Struct({
  isMainDocument: Schema.Boolean,
  baseLease: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Base lease is required",
    }),
  ),
  state: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  pmSignee: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  leaseAddenda: Schema.Array(EnderIdFormSchema).pipe(Schema.OptionFromSelf),
  generatedLease: Schema.String.pipe(Schema.OptionFromSelf),
});

type LeaseGeneratorFormInput = Schema.Schema.Encoded<
  typeof LeaseGeneratorFormSchema
>;
type LeaseGeneratorFormOutput = Schema.Schema.Type<
  typeof LeaseGeneratorFormSchema
>;

const leaseGeneratorFormDefaultValues: LeaseGeneratorFormInput = {
  isMainDocument: false,
  baseLease: O.none(),
  state: O.none(),
  pmSignee: O.none(),
  leaseAddenda: O.none(),
  generatedLease: O.none(),
};

export { leaseGeneratorFormDefaultValues, LeaseGeneratorFormSchema };
export type { LeaseGeneratorFormInput, LeaseGeneratorFormOutput };
