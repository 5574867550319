/**
 * HTTP Method: POST
 * Pathname: /petsOwner
 * @function addPetToOwner
 * @memberof PetsAPI
 * @param {PetsAPIAddPetToOwnerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Pet>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PetsAPIAddPetToOwnerSearchParams = {
  token?: string | undefined;
};

type PetsAPIAddPetToOwnerBodyParams = {
  age: number;
  breed: string;
  isEmotionalSupportAnimal?: boolean | undefined;
  isNeutered: boolean;
  name: string;
  ownerId: EnderId;
  species: string;
  weightInPounds: number;
};

type PetsAPIAddPetToOwnerPayload = PetsAPIAddPetToOwnerSearchParams &
  PetsAPIAddPetToOwnerBodyParams;

function addPetToOwnerUnsafeEffect(payload: PetsAPIAddPetToOwnerPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Pet>({
        body,
        decode: unsafeDecodeJsonResponse<Pet>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/petsOwner",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addPetToOwner(
  payload: PetsAPIAddPetToOwnerPayload,
  options?: { signal?: AbortSignal },
): Promise<Pet> {
  return F.pipe(
    payload,
    addPetToOwnerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addPetToOwner };
export type {
  PetsAPIAddPetToOwnerBodyParams,
  PetsAPIAddPetToOwnerPayload,
  PetsAPIAddPetToOwnerSearchParams,
};
