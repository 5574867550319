import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Option as O,
  Order,
  Predicate as P,
  pipe,
} from "effect";
import { useState } from "react";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { uploadFiles } from "@ender/shared/api/files";
import {
  EnderIdFormSchema,
  LocalDate$,
  LocalDateEffectSchema,
  Money$,
} from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tuple } from "@ender/shared/ds/tuple";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { PaymentsAPI } from "@ender/shared/generated/ender.api.accounting";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import type { DepositsReportResponseDepositsReportResponseRow } from "@ender/shared/generated/ender.service.reports";
import { useFullBankAccountNumber } from "@ender/shared/hooks/use-full-bank-account-number";
import { fail } from "@ender/shared/utils/error";

type CheckReceipts = Pick<
  DepositsReportResponseDepositsReportResponseRow,
  "id" | "amount" | "receiptDate"
> & {
  bankAccount: Pick<BankAccount, "id" | "name" | "mask" | "ownerType">;
};

type CreateDepositFormProps = {
  onSuccess?: () => void;
  receipts: CheckReceipts[];
};

const CreateDepositFormSchema = Schema.Struct({
  depositDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  niceId: Schema.String,
  period: Schema.Struct({
    id: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
  receiptDate: Schema.OptionFromSelf(LocalDateEffectSchema),
}).pipe(
  Schema.filter((values) => {
    const receiptDate = O.getOrUndefined(values.receiptDate);
    const depositDate = O.getOrUndefined(values.depositDate);
    if (P.isNotNullable(receiptDate) && P.isNotNullable(depositDate)) {
      if (Order.lessThan(LocalDate$.Order)(depositDate, receiptDate)) {
        const formattedDate = LocalDate$.toFormatted(
          receiptDate,
          LocalDate$.Formats.DEFAULT,
        );
        return {
          message: `Deposit date cannot be before receipt date of ${formattedDate}`,
          path: ["depositDate"],
        };
      }
    }
  }),
);
type CreateDepositFormOutput = Schema.Schema.Type<
  typeof CreateDepositFormSchema
>;

function CreateDepositForm(props: CreateDepositFormProps) {
  const { receipts, onSuccess = F.constVoid } = props;
  const bankAccount = receipts[0]?.bankAccount;
  const amount = Money$.sum(
    A.filterMap(receipts, (receipt) => Money$.parse(receipt.amount)),
  );
  const receiptIds = receipts.map((r) => r.id);
  const bankAccountNumber = useFullBankAccountNumber(bankAccount);

  const [files, setFiles] = useState<File[]>([]);

  const mostRecentReceiptDate = pipe(
    receipts,
    A.filterMap((r) => LocalDate$.parse(r.receiptDate)),
    O.liftPredicate(A.isNonEmptyArray),
    O.map((dates) =>
      A.reduce(dates, dates[0], (acc, date) =>
        acc.isBefore(date) ? date : acc,
      ),
    ),
  );

  const form = useEffectSchemaForm({
    defaultValues: {
      depositDate: O.none(),
      niceId: "",
      period: O.none(),
      receiptDate: mostRecentReceiptDate, // only used for validation against the chosen depositDate
    },
    schema: CreateDepositFormSchema,
  });
  const { mutateAsync: createDeposit, isLoading: isCreatingDeposit } =
    useMutation({
      mutationFn: PaymentsAPI.createDeposit,
      mutationKey: ["PaymentsAPI.createDeposit"] as const,
    });
  const { mutateAsync: _uploadFiles, isLoading: isUploadingFiles } =
    useMutation({
      mutationFn: uploadFiles,
      mutationKey: ["FilesAPI.uploadFiles"] as const,
    });

  async function onSubmit(values: CreateDepositFormOutput) {
    const { depositDate, period, niceId } = values;
    try {
      /**
       * form.values.niceId can be undefined in which case BE will provide an auto-incremented niceId
       * in any case, response from BE will have the niceId
       */
      const { depositId } = await createDeposit({
        date: pipe(
          depositDate,
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
        memo: "",
        niceId,
        periodId: pipe(
          period,
          O.map((v) => v.id),
          O.getOrUndefined,
        ),
        receiptIds,
      });

      if (A.isNonEmptyArray(files)) {
        await _uploadFiles({
          files,
          modelId: depositId,
          modelType: ModelTypeEnum.BANKING_BATCH,
          uploadType: WebserverFilesServiceFileUploadTypeEnum.DOCUMENT,
        });
      }

      onSuccess();
    } catch (err) {
      fail(err);
    }
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        <H3>Details</H3>
        <div>
          <Tuple label="Bank Account" value={bankAccount.name} />
          <Tuple label="Account Number" value={bankAccountNumber} />
          <Tuple
            label="Amount"
            value={<MoneyDisplay showSymbol value={Money$.parse(amount)} />}
          />
        </div>
        <FormDateInput form={form} name="depositDate" label="Deposit Date" />
        <FormAccountingPeriodSelector
          form={form}
          name="period"
          periodType={AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE}
        />
        <FormTextInput
          form={form}
          name="niceId"
          label="Deposit ID (optional)"
          description="Ender will assign a Deposit ID if you do not specify one."
        />
        <FileInput value={files} onChange={setFiles} />
        <Group justify={Justify.end}>
          <Button type="submit" loading={isCreatingDeposit || isUploadingFiles}>
            Deposit
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { CreateDepositForm };
