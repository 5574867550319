import { addDeal } from "./add-deal";
import { assignSellSideBroker } from "./assign-sell-side-broker";
import { autoAllocateToInvestor } from "./auto-allocate-to-investor";
import { confirmUpload } from "./confirm-upload";
import { createInspection } from "./create-inspection";
import { createOffer } from "./create-offer";
import { deleteInspection } from "./delete-inspection";
import { exportAssessorFile } from "./export-assessor-file";
import { getAssessorData } from "./get-assessor-data";
import { getAssessorData2 } from "./get-assessor-data2";
import { getAssessorFileSize } from "./get-assessor-file-size";
import { getBroker } from "./get-broker";
import { getBrokers } from "./get-brokers";
import { getCommunityData } from "./get-community-data";
import { getDeal } from "./get-deal";
import { getInspections } from "./get-inspections";
import { getInvestorAllocations } from "./get-investor-allocations";
import { getInvestorSettings } from "./get-investor-settings";
import { getMLSPublicProperties } from "./get-m-l-s-public-properties";
import { getMLSStatusHistory } from "./get-m-l-s-status-history";
import { getMissingDocuments } from "./get-missing-documents";
import { getOffers } from "./get-offers";
import { getPipelineAnalytics } from "./get-pipeline-analytics";
import { getPropertyPhotos } from "./get-property-photos";
import { getSchoolDistricts } from "./get-school-districts";
import { getStatusInfo } from "./get-status-info";
import { getTaxes } from "./get-taxes";
import { getUploads } from "./get-uploads";
import { permaDeleteProperty } from "./perma-delete-property";
import { saveBroker } from "./save-broker";
import { searchMLSProperties } from "./search-m-l-s-properties";
import { setAcquisitionFee } from "./set-acquisition-fee";
import { setAllocation } from "./set-allocation";
import { setOfferStatus } from "./set-offer-status";
import { setStatus } from "./set-status";
import { updateAllocationStatus } from "./update-allocation-status";
import { updateBrokerDetails } from "./update-broker-details";
import { updateDeal } from "./update-deal";
import { updateInspection } from "./update-inspection";
import { uploadProperties } from "./upload-properties";

const BuyAPI = {
  addDeal,
  assignSellSideBroker,
  autoAllocateToInvestor,
  confirmUpload,
  createInspection,
  createOffer,
  deleteInspection,
  exportAssessorFile,
  getAssessorData,
  getAssessorData2,
  getAssessorFileSize,
  getBroker,
  getBrokers,
  getCommunityData,
  getDeal,
  getInspections,
  getInvestorAllocations,
  getInvestorSettings,
  getMLSPublicProperties,
  getMLSStatusHistory,
  getMissingDocuments,
  getOffers,
  getPipelineAnalytics,
  getPropertyPhotos,
  getSchoolDistricts,
  getStatusInfo,
  getTaxes,
  getUploads,
  permaDeleteProperty,
  saveBroker,
  searchMLSProperties,
  setAcquisitionFee,
  setAllocation,
  setOfferStatus,
  setStatus,
  updateAllocationStatus,
  updateBrokerDetails,
  updateDeal,
  updateInspection,
  uploadProperties,
};

export { BuyAPI };
