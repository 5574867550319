import { useState } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import { TextInput } from "@ender/shared/ds/text-input";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";

import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

function getZipcodeFilter(
  value: string,
  yFactor?: WidgetFactor,
): WidgetFilter[] | Undefined {
  if (value === UNDEFINED || value === "" || !yFactor) {
    return UNDEFINED;
  }

  return [
    {
      factor: yFactor,
      operator: DynamicTableOperatorsEnum.IN,
      values: [value],
    },
  ];
}

function getDefaultValue(widget: Widget) {
  return widget.filters
    .find(({ factor }) => factor.name === Factor.ZIPCODE)
    ?.values?.[0].toString();
}

function ZipcodeFieldFilter({
  factor,
  updateFilters,
  widget,
}: FilterComponentProps) {
  const [zipcode, setZipcode] = useState(getDefaultValue(widget));

  function handleChange(value: string) {
    setZipcode(value);

    const filter = getZipcodeFilter(value, factor);

    updateFilters(Factor.ZIPCODE, filter);
  }

  return (
    <TextInput
      label="Zip Code"
      placeholder="Zip Code"
      value={zipcode ?? ""}
      onChange={handleChange}
    />
  );
}

export { ZipcodeFieldFilter };
