/**
 * HTTP Method: POST
 * Pathname: /invoices/markReceivedBatch
 * @function markReceivedBatch
 * @memberof InvoicesAPI
 * @param {InvoicesAPIMarkReceivedBatchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarkReceivedBatchResponse>}
 */
import { Effect, Function as F } from "effect";

import type { TransferDetails } from "@ender/shared/generated/ender.api.accounting.request";
import type { MarkReceivedBatchResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIMarkReceivedBatchSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIMarkReceivedBatchBodyParams = {
  overrideWarnings?: boolean | undefined;
  transferDetails: TransferDetails[];
};

type InvoicesAPIMarkReceivedBatchPayload =
  InvoicesAPIMarkReceivedBatchSearchParams &
    InvoicesAPIMarkReceivedBatchBodyParams;

function markReceivedBatchUnsafeEffect(
  payload: InvoicesAPIMarkReceivedBatchPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarkReceivedBatchResponse>({
        body,
        decode: unsafeDecodeJsonResponse<MarkReceivedBatchResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/markReceivedBatch",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function markReceivedBatch(
  payload: InvoicesAPIMarkReceivedBatchPayload,
  options?: { signal?: AbortSignal },
): Promise<MarkReceivedBatchResponse> {
  return F.pipe(
    payload,
    markReceivedBatchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { markReceivedBatch };
export type {
  InvoicesAPIMarkReceivedBatchBodyParams,
  InvoicesAPIMarkReceivedBatchPayload,
  InvoicesAPIMarkReceivedBatchSearchParams,
};
