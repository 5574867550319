import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { ReportWidget } from "@ender/shared/generated/ender.model.reports";

import type { Widget } from "./underwriting-queue/underwriting-queue-table/filter-fields/filter-types";

function removeFilter(widget: Widget, filterName: string) {
  const filters = widget.filters.filter(
    ({ factor }) => filterName !== factor.name,
  );

  widget.filters = filters;
}

function widgetToUpdateRequest(widget: ReportWidget | Widget) {
  return {
    filters: widget.filters.map(({ factor, operator, values }) => ({
      factorId: factor.id,
      operator,
      values,
      modelType: ModelTypeEnum.DEAL,
    })),
    sortOrder: widget.sortOrder || "ASCENDING",
    ...(widget.sortByFactor ? { sortByFactor: widget.sortByFactor.id } : {}),
    xFactors: widget.xFactors.map(({ id }) => id),
    yFactors: widget.yFactors.map(({ id }) => id),
  };
}

export { removeFilter, widgetToUpdateRequest };
