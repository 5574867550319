/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/offers
 * @function getOffers
 * @memberof BuyAPI
 * @param {BuyAPIGetOffersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIGetOffersResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIGetOffersResponse } from "../buy-api-get-offers-response";

type BuyAPIGetOffersPathParams = {
  dealId: EnderId;
};

type BuyAPIGetOffersSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetOffersPayload = BuyAPIGetOffersPathParams &
  BuyAPIGetOffersSearchParams;

function getOffersUnsafeEffect(payload: BuyAPIGetOffersPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIGetOffersResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BuyAPIGetOffersResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/offers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOffers(
  payload: BuyAPIGetOffersPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIGetOffersResponse> {
  return F.pipe(
    payload,
    getOffersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOffers };
export type {
  BuyAPIGetOffersPathParams,
  BuyAPIGetOffersPayload,
  BuyAPIGetOffersSearchParams,
};
