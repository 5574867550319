import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const DealOccupancyStatusValues = ["OCCUPIED", "VACANT"] as const;

const DealOccupancyStatusEffectSchema = Schema.Literal(
  ...DealOccupancyStatusValues,
);

type DealOccupancyStatus = Schema.Schema.Type<
  typeof DealOccupancyStatusEffectSchema
>;

const DealOccupancyStatusEnum = castEnum(DealOccupancyStatusEffectSchema);

function randomDealOccupancyStatus(): DealOccupancyStatus {
  return rand(DealOccupancyStatusValues);
}

export {
  DealOccupancyStatusEffectSchema,
  DealOccupancyStatusEnum,
  DealOccupancyStatusValues,
  randomDealOccupancyStatus,
};
export type { DealOccupancyStatus };
