import type { SortingState } from "@tanstack/react-table";
import { useCallback, useState } from "react";

import type { EnderTableSortingParams } from "../table.types";

type UseTableSortingParams = {
  initialSorting?: SortingState;
  manualSorting?: boolean;
  maxColSortCount?: number;
};

type UseTableSortingResult = EnderTableSortingParams;

function useTableSorting(
  params: UseTableSortingParams = {},
): UseTableSortingResult {
  const { initialSorting, manualSorting = false, maxColSortCount = 1 } = params;

  const [sorting, onSortingChange] = useState<SortingState>(
    () => initialSorting ?? [],
  );
  const isMultiSortEvent = useCallback(
    () => maxColSortCount > 1,
    [maxColSortCount],
  );

  return {
    enableMultiRemove: true,
    enableMultiSort: maxColSortCount > 1,
    enableSorting: true,
    enableSortingRemoval: true,
    isMultiSortEvent,
    manualSorting,
    maxMultiSortColCount: maxColSortCount,
    onSortingChange,
    sorting,
  };
}

export { useTableSorting };
export type { UseTableSortingParams, UseTableSortingResult };
