/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}/zones/{zoneId}
 * @function getZone
 * @memberof InspectionAPI
 * @param {InspectionAPIGetZonePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionZoneResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionZoneResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetZonePathParams = {
  inspectionId: EnderId;
  zoneId: EnderId;
};

type InspectionAPIGetZoneSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetZonePayload = InspectionAPIGetZonePathParams &
  InspectionAPIGetZoneSearchParams;

function getZoneUnsafeEffect(payload: InspectionAPIGetZonePayload) {
  const { inspectionId, zoneId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionZoneResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionZoneResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}/zones/${zoneId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getZone(
  payload: InspectionAPIGetZonePayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionZoneResponse> {
  return F.pipe(
    payload,
    getZoneUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getZone };
export type {
  InspectionAPIGetZonePathParams,
  InspectionAPIGetZonePayload,
  InspectionAPIGetZoneSearchParams,
};
