import type { HTMLAttributes, ReactNode } from "react";

import "./ender-table.scss";

type EnderTableProps = {
  children: ReactNode;
  className?: string;
} & HTMLAttributes<HTMLTableElement>;

/**
 * @name EnderTable
 * @param {ReactProps} props
 * @deprecated Move this to the `table-tanstack` package and export as `SimpleTable`.
 * SimpleTable will eventually be the trimmed-down version of the Table library that only renders what you put into it with proper styling-
 * no filtering, no bells and whistles
 */
function EnderTable({ children, className = "", ...props }: EnderTableProps) {
  return (
    // @ts-expect-error could not maintain style if remove and convert the scss file, will replace with the new Table element eventually
    <ender-table class={className} {...props}>
      <table>{children}</table>
      {/* @ts-expect-error */}
    </ender-table>
  );
}

export { EnderTable };
