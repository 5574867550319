import { Predicate as P } from "effect";
import { useMemo } from "react";

import type { Undefined } from "@ender/shared/constants/general";
import { UNDEFINED } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import type {
  GetApprovalProcessResponse,
  GetApprovalProcessesResponse,
} from "@ender/shared/generated/ender.api.misc.response";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";

import { useInvoiceApprovalProcesses } from "./use-invoice-approval-processes";

function getApprovalProcessForInvoice({
  currentStepId,
  approvalProcessesResponse,
}: {
  currentStepId: EnderId;
  approvalProcessesResponse: GetApprovalProcessesResponse;
}): GetApprovalProcessResponse | Undefined {
  const { approvalProcessById, defaultApprovalProcess } =
    approvalProcessesResponse;

  // 2024-09-18 Geoffrey: I'd prefer if this never happened, but legacy allows for this fallback to default
  if (!approvalProcessById || !P.isNotNullable(currentStepId)) {
    return defaultApprovalProcess;
  }

  const matchingApprovalProcess = Object.values(approvalProcessById).find(
    (approvalProcess) =>
      approvalProcess?.steps?.some((step) => step.id === currentStepId),
  );

  return matchingApprovalProcess ?? defaultApprovalProcess;
}

function useApprovalProcessForInvoice(
  invoice: InvoiceSerializerInvoiceResponse | Undefined,
) {
  const {
    data: approvalProcessesResponse,
    isFetching: isFetchingApprovalProcessForInvoice,
  } = useInvoiceApprovalProcesses({
    disabled: P.isNullable(invoice),
    invoice,
  });

  const approvalProcessForInvoice = useMemo(() => {
    if (P.isNullable(invoice) || P.isNullable(approvalProcessesResponse)) {
      return UNDEFINED;
    }

    return getApprovalProcessForInvoice({
      approvalProcessesResponse,
      currentStepId: invoice.currentState?.stepId,
    });
  }, [approvalProcessesResponse, invoice]);

  return {
    approvalProcessForInvoice,
    isFetchingApprovalProcessForInvoice,
  };
}

export { getApprovalProcessForInvoice, useApprovalProcessForInvoice };
