/**
 * HTTP Method: POST
 * Pathname: /moneyTransfers/{moneyTransferId}/cancelACHPayment
 * @function cancelACHPayment
 * @memberof PaymentsAPI
 * @param {PaymentsAPICancelACHPaymentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPICancelACHPaymentPathParams = {
  moneyTransferId: EnderId;
};

type PaymentsAPICancelACHPaymentSearchParams = {
  token?: string | undefined;
};

type PaymentsAPICancelACHPaymentPayload =
  PaymentsAPICancelACHPaymentPathParams &
    PaymentsAPICancelACHPaymentSearchParams;

function cancelACHPaymentUnsafeEffect(
  payload: PaymentsAPICancelACHPaymentPayload,
) {
  const { moneyTransferId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/moneyTransfers/${moneyTransferId}/cancelACHPayment`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function cancelACHPayment(
  payload: PaymentsAPICancelACHPaymentPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    cancelACHPaymentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { cancelACHPayment };
export type {
  PaymentsAPICancelACHPaymentPathParams,
  PaymentsAPICancelACHPaymentPayload,
  PaymentsAPICancelACHPaymentSearchParams,
};
