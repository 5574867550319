import { Predicate as P } from "effect";

import { NULL } from "@ender/shared/constants/general";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import type { InvoiceResponseInvoiceStateResponse } from "@ender/shared/generated/ender.arch.accounting";

type InvoiceApprovalStatusProps = {
  approvalProcessStepName?: string;
  currentState?: InvoiceResponseInvoiceStateResponse;
};

function InvoiceApprovalStatus({
  approvalProcessStepName,
  currentState,
}: InvoiceApprovalStatusProps) {
  if (P.isNullable(currentState)) {
    return NULL;
  }

  const { isApproved, isFullyRejected, stepId } = currentState;

  let badgeProp;

  if (isApproved) {
    badgeProp = { children: "Paid", color: BadgeColor.green };
  } else if (isFullyRejected) {
    badgeProp = { children: "Rejected", color: BadgeColor.red };
  } else if (stepId && approvalProcessStepName) {
    badgeProp = { children: approvalProcessStepName, color: BadgeColor.yellow };
  }

  return badgeProp ? <Badge {...badgeProp} /> : NULL;
}

export { InvoiceApprovalStatus };
