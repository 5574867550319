/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/buyBoxes
 * @function getPropertyBuyBoxes
 * @memberof BuyBoxAPI
 * @param {BuyBoxAPIGetPropertyBuyBoxesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyBoxAPIGetPropertyBuyBoxesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyBoxAPIGetPropertyBuyBoxesResponse } from "../buy-box-api-get-property-buy-boxes-response";

type BuyBoxAPIGetPropertyBuyBoxesPathParams = {
  dealId: EnderId;
};

type BuyBoxAPIGetPropertyBuyBoxesSearchParams = {
  token?: string | undefined;
};

type BuyBoxAPIGetPropertyBuyBoxesPayload =
  BuyBoxAPIGetPropertyBuyBoxesPathParams &
    BuyBoxAPIGetPropertyBuyBoxesSearchParams;

function getPropertyBuyBoxesUnsafeEffect(
  payload: BuyBoxAPIGetPropertyBuyBoxesPayload,
) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyBoxAPIGetPropertyBuyBoxesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BuyBoxAPIGetPropertyBuyBoxesResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/buyBoxes`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPropertyBuyBoxes(
  payload: BuyBoxAPIGetPropertyBuyBoxesPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyBoxAPIGetPropertyBuyBoxesResponse> {
  return F.pipe(
    payload,
    getPropertyBuyBoxesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPropertyBuyBoxes };
export type {
  BuyBoxAPIGetPropertyBuyBoxesPathParams,
  BuyBoxAPIGetPropertyBuyBoxesPayload,
  BuyBoxAPIGetPropertyBuyBoxesSearchParams,
};
