import { deleteFile } from "./delete-file";
import { downloadFilesAsZip } from "./download-files-as-zip";
import { getFiles } from "./get-files";
import { removeFileTag } from "./remove-file-tag";
import { tagFile } from "./tag-file";
import { uploadFiles } from "./upload-files";

const WebserverFilesAPI = {
  deleteFile,
  downloadFilesAsZip,
  getFiles,
  removeFileTag,
  tagFile,
  uploadFiles,
};

export { WebserverFilesAPI };
