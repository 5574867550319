import { createRenewedLease } from "./create-renewed-lease";
import { getRenewalPackage } from "./get-renewal-package";
import { getRenewals } from "./get-renewals";
import { sendLimitedOffers } from "./send-limited-offers";

const RenewalsMiddleLayerAPI = {
  createRenewedLease,
  getRenewalPackage,
  getRenewals,
  sendLimitedOffers,
};

export { RenewalsMiddleLayerAPI };
