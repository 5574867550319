/**
 * A PM or a tenant view of a tenant ledger which includes appropriate accounting metadata depending on the user.
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/ledger
 * @function getTenantLedger
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPIGetTenantLedgerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TenantLedgerReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate } from "@ender/shared/core";
import type { PeriodFilter } from "@ender/shared/generated/ender.api.model";
import type { TenantLedgerReportLedgerEntryTenantLedgerEventType } from "@ender/shared/generated/ender.arch.accounting";
import type {
  TenantLedgerReportResponse,
  TenantLedgerReportServiceTenantLedgerSortKey,
} from "@ender/shared/generated/ender.service.reports.builtin";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPIGetTenantLedgerPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPIGetTenantLedgerSearchParams = {
  token?: string | undefined;
  amountSearchString?: string | undefined;
  ascending?: boolean | undefined;
  count?: number | undefined;
  description?: string | undefined;
  endDate?: LocalDate | undefined;
  offset?: number | undefined;
  periodFilter?: PeriodFilter | undefined;
  selectedCategoryIds: EnderId[];
  sortKey?: TenantLedgerReportServiceTenantLedgerSortKey | undefined;
  startDate?: LocalDate | undefined;
  type?: TenantLedgerReportLedgerEntryTenantLedgerEventType | undefined;
};

type TenantLedgerAPIGetTenantLedgerPayload =
  TenantLedgerAPIGetTenantLedgerPathParams &
    TenantLedgerAPIGetTenantLedgerSearchParams;

function getTenantLedgerUnsafeEffect(
  payload: TenantLedgerAPIGetTenantLedgerPayload,
) {
  const {
    leaseId,
    token,
    amountSearchString,
    ascending,
    count,
    description,
    endDate,
    offset,
    periodFilter,
    selectedCategoryIds,
    sortKey,
    startDate,
    type,
    ...body
  } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TenantLedgerReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<TenantLedgerReportResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/ledger`,
        searchParams: [
          ["token", token],
          ["amountSearchString", amountSearchString],
          ["ascending", ascending],
          ["count", count],
          ["description", description],
          ["endDate", endDate],
          ["offset", offset],
          ["periodFilter", periodFilter],
          ["selectedCategoryIds", selectedCategoryIds],
          ["sortKey", sortKey],
          ["startDate", startDate],
          ["type", type],
        ],
      }),
    ),
  );
}

function getTenantLedger(
  payload: TenantLedgerAPIGetTenantLedgerPayload,
  options?: { signal?: AbortSignal },
): Promise<TenantLedgerReportResponse> {
  return F.pipe(
    payload,
    getTenantLedgerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTenantLedger };
export type {
  TenantLedgerAPIGetTenantLedgerPathParams,
  TenantLedgerAPIGetTenantLedgerPayload,
  TenantLedgerAPIGetTenantLedgerSearchParams,
};
