import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskAssignmentAssignmentStatusValues = [
  "ASSIGNED",
  "UNASSIGNED",
  "ACCEPTED",
  "REJECTED",
] as const;

const TaskAssignmentAssignmentStatusEffectSchema = Schema.Literal(
  ...TaskAssignmentAssignmentStatusValues,
);

type TaskAssignmentAssignmentStatus = Schema.Schema.Type<
  typeof TaskAssignmentAssignmentStatusEffectSchema
>;

const TaskAssignmentAssignmentStatusEnum = castEnum(
  TaskAssignmentAssignmentStatusEffectSchema,
);

function randomTaskAssignmentAssignmentStatus(): TaskAssignmentAssignmentStatus {
  return rand(TaskAssignmentAssignmentStatusValues);
}

export {
  randomTaskAssignmentAssignmentStatus,
  TaskAssignmentAssignmentStatusEffectSchema,
  TaskAssignmentAssignmentStatusEnum,
  TaskAssignmentAssignmentStatusValues,
};
export type { TaskAssignmentAssignmentStatus };
