import { IconInfoCircle } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import type { PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";

import { Align, Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";
import { Stack } from "../../../stack/src";
import { Tooltip } from "../../../tooltip/src";
import { isEmptyReactNode } from "../../../utils";

type InputWrapperProps = {
  error?: ReactNode;
  label?: ReactNode;
  description?: string;
  /**
   * the id of the input element that this wrapper is wrapping
   */
  inputId: string;
  /**
   * the label element of the input wrapper
   */
  labelId: string;
  errorId: string;
  descriptionId?: string;
};

const InputWrapperLabelVariantGenerator = cva(["text-xs leading-normal"], {
  variants: {
    invalid: {
      false: "text-slate-900",
      true: "text-red-500",
    },
  },
});

const InputWrapperErrorVariantGenerator = cva(["text-xs leading-relaxed"], {
  variants: {
    invalid: {
      true: "text-red-500",
    },
  },
});

const InputWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<InputWrapperProps>
>((props, ref) => {
  const {
    label,
    description,
    error,
    children,
    inputId,
    labelId,
    errorId,
    descriptionId,
  } = props;

  const attributes = {
    invalid: !isEmptyReactNode(error),
  };

  return (
    <Stack ref={ref} spacing={Spacing.xs}>
      {!isEmptyReactNode(label) && (
        <Group spacing={Spacing.xs} align={Align.center}>
          <label
            htmlFor={inputId}
            id={labelId}
            className={InputWrapperLabelVariantGenerator(attributes)}>
            {label}
          </label>
          <Tooltip label={description} disabled={isEmptyReactNode(description)}>
            <span className={clsx({ hidden: isEmptyReactNode(description) })}>
              <IconInfoCircle size={16} />
            </span>
          </Tooltip>
          {/* used to describe the input- the tooltip cannot be used due to its temporary existence */}
          <div className="hidden" role="presentation" id={descriptionId}>
            {description}
          </div>
        </Group>
      )}
      {children}
      {!isEmptyReactNode(error) && (
        <p
          id={errorId}
          className={InputWrapperErrorVariantGenerator(attributes)}>
          {error}
        </p>
      )}
    </Stack>
  );
});
InputWrapper.displayName = "InputWrapper";

export { InputWrapper };

export type { InputWrapperProps };
