import { LocalDate$ } from "@ender/shared/core";
import type { InvoicePayableType } from "@ender/shared/generated/ender.model.payments.invoice";

/**
 * This should be generated from the backend, but it's not.
 * Values copied from ender.com/src/ender/model/payments/invoice/Invoice.java
 */
const INVOICE_PAYABLE_TYPE_DISPLAY_NAMES: Record<InvoicePayableType, string> = {
  ACCOUNTING: "Accounting",
  ACQUISITION: "Acquisition",
  GENERAL: "General",
  HOA_3RD_PARTY_MANAGER: "HOA 3rd Party Manager",
  HOA_DUES: "HOA Dues",
  INSURANCE: "Insurance",
  LEGAL: "Legal",
  MANAGEMENT_FEES: "Management Fees",
  MANAGEMENT_FEES_3RD_PARTY_MANAGER: "Management Fees 3rd Party Manager",
  MARKETING: "Marketing",
  OWNER_DISTRIBUTIONS: "Owner Distributions",
  PROPERTY_TAXES_3RD_PARTY_MANAGER: "Property Taxes 3rd Party Manager",
  RECURRING_VENDOR_PAYMENTS: "Recurring Vendor Payments",
  RENOVATION: "Renovation",
  RIPPLING: "Rippling",
  SPECIAL_DAMAGES: "Special Damages",
  TASKS: "Tasks",
  TENANT_REFUNDS: "Tenant Refunds",
  TURNOVER: "Turnover",
  UTILITY_3RD_PARTY_MANAGER: "Utility 3rd Party Manager",
};

/**
 * Currently value: 01/01/2000
 *
 * The purpose of this is to keep a consistent restriction on minimum ledgerDate when Creating and Editing invoices
 * Product is allowing us to restrict the creation of invoices to this century.
 */
const MIN_CREATE_EDIT_INVOICE_DATE = LocalDate$.of("01/01/2000");

export { INVOICE_PAYABLE_TYPE_DISPLAY_NAMES, MIN_CREATE_EDIT_INVOICE_DATE };
