// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { Image } from "@mantine/core";

import type { LogoVariant } from "@ender/shared/types/branding";
import type { ImageProps } from "@ender/shared/ui/logo-image-base";
import { LogoImageBase } from "@ender/shared/ui/logo-image-base";

/**
 * @deprecated this should be located in a Branding package or directly co-located in the nav directory
 */
function LogoImage({
  src: inlineSrc,
  variant,
  fit = "contain",
}: {
  /** @description An image src value which overrides the default and white label branded logos */
  src?: string;
  fit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  /** @description The variant of the logo to display */
  variant: LogoVariant;
}) {
  return (
    <LogoImageBase
      src={inlineSrc}
      variant={variant}
      fit={fit}
      ImageComponent={(props: ImageProps) => (
        <Image
          alt={props.alt}
          // @ts-expect-error - mantine image props are wrong
          src={props.src}
          width={props.width}
          height={props.height}
          fit={props.fit}
        />
      )}
    />
  );
}

export { LogoImage };
