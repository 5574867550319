import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GLCategoryCategoryTypeValues = [
  "ASSET",
  "LIABILITY",
  "EQUITY",
  "INCOME",
  "EXPENSE",
] as const;

const GLCategoryCategoryTypeEffectSchema = Schema.Literal(
  ...GLCategoryCategoryTypeValues,
);

type GLCategoryCategoryType = Schema.Schema.Type<
  typeof GLCategoryCategoryTypeEffectSchema
>;

const GLCategoryCategoryTypeEnum = castEnum(GLCategoryCategoryTypeEffectSchema);

function randomGLCategoryCategoryType(): GLCategoryCategoryType {
  return rand(GLCategoryCategoryTypeValues);
}

export {
  GLCategoryCategoryTypeEffectSchema,
  GLCategoryCategoryTypeEnum,
  GLCategoryCategoryTypeValues,
  randomGLCategoryCategoryType,
};
export type { GLCategoryCategoryType };
