/**
 * HTTP Method: GET
 * Pathname: /tasks/{taskId}/invoicesForVendor
 * @function getInvoicesOnTaskForVendor
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoicesOnTaskForVendorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInvoicesOnTaskForVendorResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInvoicesOnTaskForVendorResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoicesOnTaskForVendorPathParams = {
  taskId: EnderId;
};

type InvoicesAPIGetInvoicesOnTaskForVendorSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetInvoicesOnTaskForVendorPayload =
  InvoicesAPIGetInvoicesOnTaskForVendorPathParams &
    InvoicesAPIGetInvoicesOnTaskForVendorSearchParams;

function getInvoicesOnTaskForVendorUnsafeEffect(
  payload: InvoicesAPIGetInvoicesOnTaskForVendorPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInvoicesOnTaskForVendorResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInvoicesOnTaskForVendorResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/${taskId}/invoicesForVendor`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoicesOnTaskForVendor(
  payload: InvoicesAPIGetInvoicesOnTaskForVendorPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInvoicesOnTaskForVendorResponse> {
  return F.pipe(
    payload,
    getInvoicesOnTaskForVendorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoicesOnTaskForVendor };
export type {
  InvoicesAPIGetInvoicesOnTaskForVendorPathParams,
  InvoicesAPIGetInvoicesOnTaskForVendorPayload,
  InvoicesAPIGetInvoicesOnTaskForVendorSearchParams,
};
