/**
 * List all industries for the business classification
 * HTTP Method: GET
 * Pathname: /classifications/{businessClassificationId}/industries
 * @function getIndustries
 * @memberof DwollaAPI
 * @param {DwollaAPIGetIndustriesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetBankLinkInfoResponseBusinessClassificationIndustryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetBankLinkInfoResponseBusinessClassificationIndustryResponse } from "@ender/shared/generated/ender.api.finance.request";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPIGetIndustriesPathParams = {
  /**
   * The EnderId of the business classification
   */
  businessClassificationId: string;
};

type DwollaAPIGetIndustriesSearchParams = {
  token?: string | undefined;
};

type DwollaAPIGetIndustriesPayload = DwollaAPIGetIndustriesPathParams &
  DwollaAPIGetIndustriesSearchParams;

function getIndustriesUnsafeEffect(payload: DwollaAPIGetIndustriesPayload) {
  const { businessClassificationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<
        typeof body,
        GetBankLinkInfoResponseBusinessClassificationIndustryResponse[]
      >({
        body,
        decode: unsafeDecodeJsonResponse<
          GetBankLinkInfoResponseBusinessClassificationIndustryResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/classifications/${businessClassificationId}/industries`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getIndustries(
  payload: DwollaAPIGetIndustriesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetBankLinkInfoResponseBusinessClassificationIndustryResponse[]> {
  return F.pipe(
    payload,
    getIndustriesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getIndustries };
export type {
  DwollaAPIGetIndustriesPathParams,
  DwollaAPIGetIndustriesPayload,
  DwollaAPIGetIndustriesSearchParams,
};
