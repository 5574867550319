/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/charges
 * @function createLeaseCharges
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPICreateLeaseChargesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TenantLedgerAPILeaseCharge } from "../tenant-ledger-api-lease-charge";

type TenantLedgerAPICreateLeaseChargesPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPICreateLeaseChargesSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPICreateLeaseChargesBodyParams = {
  charges: TenantLedgerAPILeaseCharge[];
  requestId: string;
};

type TenantLedgerAPICreateLeaseChargesPayload =
  TenantLedgerAPICreateLeaseChargesPathParams &
    TenantLedgerAPICreateLeaseChargesSearchParams &
    TenantLedgerAPICreateLeaseChargesBodyParams;

function createLeaseChargesUnsafeEffect(
  payload: TenantLedgerAPICreateLeaseChargesPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice[]>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/charges`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createLeaseCharges(
  payload: TenantLedgerAPICreateLeaseChargesPayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice[]> {
  return F.pipe(
    payload,
    createLeaseChargesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createLeaseCharges };
export type {
  TenantLedgerAPICreateLeaseChargesBodyParams,
  TenantLedgerAPICreateLeaseChargesPathParams,
  TenantLedgerAPICreateLeaseChargesPayload,
  TenantLedgerAPICreateLeaseChargesSearchParams,
};
