const ALL_FINANCIALLY_RESPONSIBLE_TENANTS =
  "All Financially Responsible Tenants";

/**
 * DATE_STRING_DISTANT_PAST and DATE_STRING_FAR_FUTURE
 * - BE endpoints do not all handle when date params are not passed.
 * - These magic dates are the FE workaround for distant past and far future.
 *
 * - BE should handle when date params are not passed.
 * - FE should not need these magic dates.
 */
const DATE_STRING_DISTANT_PAST = "2000-01-01";
const DATE_STRING_FAR_FUTURE = "2999-01-01";
const MONTH_NAMES_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
] as const;
const MONTH_NAMES_LONG = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

const WHITE_SPACES = [
  " ",
  "\n",
  "\r",
  "\t",
  "\f",
  "\v",
  "\u00A0",
  "\u1680",
  "\u180E",
  "\u2000",
  "\u2001",
  "\u2002",
  "\u2003",
  "\u2004",
  "\u2005",
  "\u2006",
  "\u2007",
  "\u2008",
  "\u2009",
  "\u200A",
  "\u2028",
  "\u2029",
  "\u202F",
  "\u205F",
  "\u3000",
] as const;

export {
  ALL_FINANCIALLY_RESPONSIBLE_TENANTS,
  DATE_STRING_DISTANT_PAST,
  DATE_STRING_FAR_FUTURE,
  MONTH_NAMES_LONG,
  MONTH_NAMES_SHORT,
  WHITE_SPACES,
};
