import { useQuery } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import type { MouseEvent } from "react";

import type { EnderId } from "@ender/shared/core";
import { H2 } from "@ender/shared/ds/heading";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { EnderTable } from "@ender/shared/ui/ender-table";

type SummaryOfInvoicesTableProps = {
  linkedInvoices?: Invoice[];
  onClickInvoice?(invoiceId: EnderId, e: MouseEvent<HTMLAnchorElement>): void;
};

type SummaryProperty = {
  id: EnderId;
  name: string;
};

function SummaryOfInvoicesTable({
  linkedInvoices = [],
  onClickInvoice = F.constVoid,
}: SummaryOfInvoicesTableProps = {}) {
  const { data: properties = [], isLoading: isFetchingProperties } = useQuery<
    { properties: SummaryProperty[] },
    unknown,
    SummaryProperty[]
  >({
    queryKey: ["PropertiesAPI.getProperties", linkedInvoices] as const,
    queryFn: ({ signal }) =>
      PropertiesAPI.getProperties(
        {
          propertyIds: linkedInvoices.map(({ propertyId }) => propertyId),
        },
        { signal },
      ) as Promise<{ properties: SummaryProperty[] }>,
    select: (data) => data.properties,
  });
  const uniqueInvoices = linkedInvoices.reduce<Invoice[]>((acc, current) => {
    const duplicate = acc.find(
      (item) =>
        item.id === current.id &&
        item.externalInvoiceId === current.externalInvoiceId &&
        item.timestamp === current.timestamp,
    );
    if (!duplicate) {
      acc.push(current);
    }
    return acc;
  }, []);
  return (
    <>
      <H2>Summary of invoices</H2>

      <Skeleton visible={isFetchingProperties}>
        <EnderTable>
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Property</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {uniqueInvoices.map((rowData) => {
              const rowProperty =
                properties.length === 1
                  ? properties[0]
                  : properties.find(({ id }) => id === rowData.propertyId);

              const href =
                rowData.type === InvoiceInvoiceTypeEnum.PAYABLE
                  ? "/invoices/accounts-payable"
                  : "/invoices/accounts-receivable";

              return (
                <tr key={rowData.id}>
                  <td>
                    <RouterLink
                      href={`${href}/${rowData.id}`}
                      onClick={(e) => onClickInvoice(rowData.id, e)}>
                      {rowData.externalInvoiceId || "Link"}
                    </RouterLink>
                  </td>
                  {P.isNotNullable(rowProperty) && <td>{rowProperty.name}</td>}
                  <td className="text-center">{rowData.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </EnderTable>
      </Skeleton>
    </>
  );
}

export { SummaryOfInvoicesTable };
