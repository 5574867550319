/**
 * HTTP Method: POST
 * Pathname: /invoices/payees/search
 * @function searchPayees
 * @memberof InvoicesAPI
 * @param {InvoicesAPISearchPayeesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchPayeesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { SearchPayeesResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPISearchPayeesSearchParams = {
  token?: string | undefined;
};

type InvoicesAPISearchPayeesPayload = InvoicesAPISearchPayeesSearchParams;

function searchPayeesUnsafeEffect(payload: InvoicesAPISearchPayeesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchPayeesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<SearchPayeesResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/payees/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchPayees(
  payload: InvoicesAPISearchPayeesPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchPayeesResponse[]> {
  return F.pipe(
    payload,
    searchPayeesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchPayees };
export type {
  InvoicesAPISearchPayeesPayload,
  InvoicesAPISearchPayeesSearchParams,
};
