import { Predicate as P, String as S } from "effect";
import { useEffect } from "react";

function useDocumentTitle(title: string) {
  // lazy useEffect to set the document title
  useEffect(() => {
    if (!P.isString(title) || S.isEmpty(title.trim())) {
      document.title = "Ender";
    } else {
      document.title = title.trim();
    }
  }, [title]);
}

export { useDocumentTitle };
