import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useMutation } from "@tanstack/react-query";
import { Function as F } from "effect";

import { Form, useForm } from "@ender/form-system/base";
import { Button } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { MarketsAPI } from "@ender/shared/generated/com.ender.buy.api";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const AddMarketFormSchema = Schema.Struct({
  code: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Code is required" }),
  ),
  name: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Name is required" }),
  ),
});

type AddMarketFormValues = Schema.Schema.Type<typeof AddMarketFormSchema>;

type AddMarketFormProps = {
  closeModal: ({ refresh }: { refresh: boolean }) => unknown;
  onMarketAdded: () => unknown;
};

function AddMarketForm({
  closeModal,
  onMarketAdded = F.constVoid,
}: AddMarketFormProps) {
  const form = useForm<AddMarketFormValues>({
    defaultValues: {
      code: "",
      name: "",
    },
    resolver: effectTsResolver(AddMarketFormSchema),
  });

  const { mutate: handleCreateMarket, isLoading: isSubmitting } = useMutation({
    mutationFn: (values: AddMarketFormValues) =>
      MarketsAPI.createMarket(values),
    mutationKey: ["MarketsAPI.createMarket"] as const,
    onSuccess: () => {
      closeModal({ refresh: true });
      onMarketAdded();
      showSuccessNotification({ message: "Market added." });
    },
  });

  return (
    <Form form={form} onSubmit={(values) => handleCreateMarket(values)}>
      <Stack spacing={Spacing.lg}>
        <FormTextInput name="name" form={form} label="Market Name" />
        <FormTextInput name="code" form={form} label="Code" />
        <Group justify={Justify.end}>
          <Button type="submit" loading={isSubmitting}>
            Save Market
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { AddMarketForm };
