/**
 * HTTP Method: POST
 * Pathname: /createLeaseWithTenants
 * @function createLeaseWithTenants
 * @memberof LeasingAPI
 * @param {LeasingAPICreateLeaseWithTenantsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  CreateLeaseWithTenantsRequestDepositRequest,
  CreateLeaseWithTenantsRequestTenantRequest,
} from "@ender/shared/generated/ender.api.leasing.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPICreateLeaseWithTenantsSearchParams = {
  token?: string | undefined;
};

type LeasingAPICreateLeaseWithTenantsBodyParams = {
  achReversalFee?: SerializesInto<Money> | undefined;
  deposits: CreateLeaseWithTenantsRequestDepositRequest[];
  depositsHeldExternally?: boolean | undefined;
  endDate?: SerializesInto<LocalDate> | undefined;
  expectedMoveOutDate?: SerializesInto<LocalDate> | undefined;
  graceDays?: number | undefined;
  lateFee?: SerializesInto<Money> | undefined;
  monthToMonth?: boolean | undefined;
  startDate: SerializesInto<LocalDate>;
  tenants: CreateLeaseWithTenantsRequestTenantRequest[];
  unitId: EnderId;
};

type LeasingAPICreateLeaseWithTenantsPayload =
  LeasingAPICreateLeaseWithTenantsSearchParams &
    LeasingAPICreateLeaseWithTenantsBodyParams;

function createLeaseWithTenantsUnsafeEffect(
  payload: LeasingAPICreateLeaseWithTenantsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/createLeaseWithTenants",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createLeaseWithTenants(
  payload: LeasingAPICreateLeaseWithTenantsPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId> {
  return F.pipe(
    payload,
    createLeaseWithTenantsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createLeaseWithTenants };
export type {
  LeasingAPICreateLeaseWithTenantsBodyParams,
  LeasingAPICreateLeaseWithTenantsPayload,
  LeasingAPICreateLeaseWithTenantsSearchParams,
};
