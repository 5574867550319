/**
 * HTTP Method: GET
 * Pathname: /stripe/paymentMethods/cards
 * @function listCards
 * @memberof PaymentsAPI
 * @param {PaymentsAPIListCardsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreditCard[]>}
 */
import { Effect, Function as F } from "effect";

import type { CreditCard } from "@ender/shared/generated/ender.model.payments.card";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIListCardsSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIListCardsPayload = PaymentsAPIListCardsSearchParams;

function listCardsUnsafeEffect(payload: PaymentsAPIListCardsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreditCard[]>({
        body,
        decode: unsafeDecodeJsonResponse<CreditCard[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/stripe/paymentMethods/cards",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function listCards(
  payload: PaymentsAPIListCardsPayload,
  options?: { signal?: AbortSignal },
): Promise<CreditCard[]> {
  return F.pipe(
    payload,
    listCardsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { listCards };
export type { PaymentsAPIListCardsPayload, PaymentsAPIListCardsSearchParams };
