/**
 * InvoiceBatcher manages the grouping of invoices into logical batches for payment processing.
 *
 * Key concepts:
 * - Invoices are batched by common "from party" and "to party" relationships
 * - Each batch includes metadata about the "to party" (e.g., bank account details)
 * - Bank account validation includes checking Dwolla status across all accounts,
 *   while display information uses the first available account
 *
 * The batcher maintains state between operations to avoid unnecessary recalculations,
 * tracking relationships between invoices and their batches for efficient updates
 * and deletions.
 */
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import type {
  BankAccount,
  BankAccountAccountStatus,
} from "@ender/shared/generated/ender.model.payments";
import { BankAccountAccountStatusEnum } from "@ender/shared/generated/ender.model.payments";

import { getFromToBatchKeyWithSideEffects } from "./get-from-to-batch-key-with-side-effects";
import type { BatchableInvoice, InvoiceBatchV2 } from "./invoice-batches.types";

type BatchKey = string;

const ValidDwollaStatuses: Set<BankAccountAccountStatus> = new Set([
  BankAccountAccountStatusEnum.VERIFIED,
  BankAccountAccountStatusEnum.RECEIVE_ONLY,
]);

export class InvoiceBatcher<T extends BatchableInvoice> {
  private batches: Map<BatchKey, InvoiceBatchV2<T>> = new Map();
  private invoices: Map<EnderId, T> = new Map();
  private batchToInvoices: Record<BatchKey, EnderId[]> = {};
  private invoiceToBatch: Record<EnderId, BatchKey> = {};
  private bankAccountsByOwedToPartyId: Record<EnderId, BankAccount[]>;

  private constructor(
    bankAccountsByOwedToPartyId: Record<EnderId, BankAccount[]>,
  ) {
    this.bankAccountsByOwedToPartyId = bankAccountsByOwedToPartyId;
  }

  static of<T extends BatchableInvoice>(
    invoices: T[],
    bankAccountsByOwedToPartyId: Record<EnderId, BankAccount[]>,
  ): InvoiceBatcher<T> {
    const batcher = new InvoiceBatcher<T>(bankAccountsByOwedToPartyId);
    batcher.addInvoices(invoices);
    return batcher;
  }

  private buildInvoiceBatch(
    invoice: T,
    bankAccounts: BankAccount[],
  ): InvoiceBatchV2<T> {
    const primaryBankAccount = bankAccounts[0];
    return {
      invoices: [invoice],
      owedByPartyName: invoice.owedByParty?.name ?? "",
      owedToPartyHasDwolla: bankAccounts.some((bankAccount) =>
        ValidDwollaStatuses.has(bankAccount.dwollaStatus),
      ),
      owedToPartyName: invoice.owedToParty?.name ?? "",
      owedToPartyType: invoice.owedToParty?.type,
      toBankAccountDisplayName: primaryBankAccount?.name ?? "",
      toBankAccountDisplayNumber: primaryBankAccount
        ? `****${primaryBankAccount.mask}`
        : "",
      totalAmount: Money$.of(invoice.amount),
    };
  }

  addInvoices(invoices: T[]): void {
    for (const invoice of invoices) {
      if (!invoice.id || this.invoices.has(invoice.id)) {
        continue;
      }

      const batchKey = getFromToBatchKeyWithSideEffects(invoice);
      this.invoices.set(invoice.id, invoice);
      this.invoiceToBatch[invoice.id] = batchKey;

      const existingBatch = this.batches.get(batchKey);
      if (existingBatch) {
        existingBatch.invoices.push(invoice);
        existingBatch.totalAmount = Money$.add(
          existingBatch.totalAmount,
          Money$.of(invoice.amount),
        );
        this.batchToInvoices[batchKey].push(invoice.id);
      } else {
        const bankAccounts =
          (invoice.owedToParty?.id &&
            this.bankAccountsByOwedToPartyId[invoice.owedToParty.id]) ||
          [];
        this.batches.set(
          batchKey,
          this.buildInvoiceBatch(invoice, bankAccounts),
        );
        this.batchToInvoices[batchKey] = [invoice.id];
      }
    }
  }

  deleteInvoices(invoiceIds: EnderId[]): void {
    for (const invoiceId of invoiceIds) {
      const batchKey = this.invoiceToBatch[invoiceId];
      if (!batchKey) {
        continue;
      }

      const batch = this.batches.get(batchKey);
      if (!batch) {
        continue;
      }

      const invoice = this.invoices.get(invoiceId);
      if (!invoice) {
        continue;
      }

      batch.invoices = batch.invoices.filter((inv) => inv.id !== invoiceId);
      batch.totalAmount = batch.totalAmount.subtract(Money$.of(invoice.amount));

      this.invoices.delete(invoiceId);
      delete this.invoiceToBatch[invoiceId];
      this.batchToInvoices[batchKey] = this.batchToInvoices[batchKey].filter(
        (id) => id !== invoiceId,
      );

      if (batch.invoices.length === 0) {
        this.batches.delete(batchKey);
        delete this.batchToInvoices[batchKey];
      }
    }
  }

  getBatches(): InvoiceBatchV2<T>[] {
    return Array.from(this.batches.values());
  }
}
