import { Fragment } from "react";

import type { FormSubSectionReference } from "@ender/form-system/base";
import { FormList, FormSection } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Divider } from "@ender/shared/ds/divider";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { isMultiple } from "@ender/shared/utils/is";

import type {
  AllocationsByPropertyValuesInput,
  EditInvoiceAllocationsFormOutput,
} from "../use-edit-invoice-allocations-form";
import { emptyAllocationsWithProperty } from "../use-edit-invoice-allocations-form";
import { AllocationListItem } from "./allocation-list-item";

type AllocationsByPropertyInputProps = {
  canRemoveProperty: boolean;
  propertyOptions: { label: string; value: EnderId }[];
  removeProperty: () => void;
} & FormSubSectionReference<
  EditInvoiceAllocationsFormOutput,
  AllocationsByPropertyValuesInput
>;

const AllocationsByPropertyInput = (props: AllocationsByPropertyInputProps) => {
  const { canRemoveProperty, propertyOptions, form, name, removeProperty } =
    props;

  return (
    <FormSection form={form} name={name}>
      {({ section }) => {
        return (
          <Card>
            {canRemoveProperty && (
              <Group justify={Justify.end}>
                <Button
                  onClick={removeProperty}
                  variant={ButtonVariant.transparent}>
                  Remove Property
                </Button>
              </Group>
            )}
            <Stack>
              <FormSelect
                data={propertyOptions}
                label="Property"
                placeholder="Choose Property"
                form={form}
                name={section.propertyId}
                clearable
              />
              <Divider />
              <FormList form={form} name={section.allocations}>
                {({ list, arrayMethods }) => (
                  <>
                    {list.map(({ name, key }, id) => {
                      const isLastAllocation = id === list.length - 1;
                      return (
                        <Fragment key={key}>
                          <AllocationListItem
                            form={form}
                            name={name}
                            canRemove={isMultiple(list)}
                            isReadonly={false}
                            removeAllocation={() => arrayMethods.remove(id)}
                          />
                          {!isLastAllocation && <Divider />}
                        </Fragment>
                      );
                    })}
                    <Group>
                      <Button
                        variant={ButtonVariant.transparent}
                        onClick={() =>
                          arrayMethods.append(
                            emptyAllocationsWithProperty.allocations[0],
                          )
                        }>
                        + Add Allocation
                      </Button>
                    </Group>
                  </>
                )}
              </FormList>
            </Stack>
          </Card>
        );
      }}
    </FormSection>
  );
};

export { AllocationsByPropertyInput };
