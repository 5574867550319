import { addStateToMarket } from "./add-state-to-market";
import { assignUsersToMarket } from "./assign-users-to-market";
import { createMarket } from "./create-market";
import { deleteStateFromMarket } from "./delete-state-from-market";
import { getAllocationForMarket } from "./get-allocation-for-market";
import { getCBSAs } from "./get-c-b-s-as";
import { getMarketPhotos } from "./get-market-photos";
import { getMarketSummaryStats } from "./get-market-summary-stats";
import { getMarkets } from "./get-markets";
import { getUsersByRole } from "./get-users-by-role";
import { updateAssumptions } from "./update-assumptions";
import { updateMarket } from "./update-market";
import { updateTaxMethod } from "./update-tax-method";

const MarketsAPI = {
  addStateToMarket,
  assignUsersToMarket,
  createMarket,
  deleteStateFromMarket,
  getAllocationForMarket,
  getCBSAs,
  getMarketPhotos,
  getMarketSummaryStats,
  getMarkets,
  getUsersByRole,
  updateAssumptions,
  updateMarket,
  updateTaxMethod,
};

export { MarketsAPI };
