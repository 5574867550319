/* global ResizeObserverOptions */
import type { RefObject } from "react";
import { useLayoutEffect, useRef } from "react";

import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";

type ObserverCallbackSync = (entry: ResizeObserverEntry) => unknown;
type ObserverCallbackAsync = (entry: ResizeObserverEntry) => Promise<unknown>;
type ObserverCallback = ObserverCallbackSync | ObserverCallbackAsync;
type ObserveFn = (
  target: Element,
  onResize: ObserverCallbackAsync,
  options?: ResizeObserverOptions | undefined,
) => unknown;
type UnobserveFn = (
  target: Element,
  onResize?: ObserverCallbackAsync,
) => unknown;
type ObserverCache = Readonly<{
  observe: ObserveFn;
  unobserve: UnobserveFn;
}>;
type TargetCallbackList = ObserverCallbackAsync[];

// Access to the Singleton ResizeObserver instance and core public API of the hook
type GetObserverFn = () => ObserverCache;

const getObserver: GetObserverFn = (() => {
  const targetCallbackMap = new Map<Element, TargetCallbackList>();
  let observe: ObserveFn = () => null;
  let unobserve: UnobserveFn = () => null;

  const instance: ResizeObserver | null = new ResizeObserver(
    (entries: ResizeObserverEntry[]) => {
      entries.forEach((entry: ResizeObserverEntry) => {
        const { target } = entry;
        const targetCallbackList = targetCallbackMap.get(target);
        if (!targetCallbackList) {
          unobserve(target);
          return;
        }

        targetCallbackList.forEach((onResize) => {
          // Wrap in a promise to support both sync & async functions
          onResize(entry).catch((e) => {
            // This is always going to be a developer error
            console.error("use-resize-observer::onResize error:", e);
          });
        });
      });
    },
  );
  observe = (
    target: Element,
    onResize: ObserverCallbackAsync,
    options?: ResizeObserverOptions | undefined,
  ) => {
    let targetCallbackList = targetCallbackMap.get(target);
    if (targetCallbackList == null) {
      instance.observe(target, options);
      targetCallbackList = [onResize];
    } else {
      targetCallbackList.push(onResize);
    }

    targetCallbackMap.set(target, targetCallbackList);
  };
  unobserve = (target: Element, onResize?: ObserverCallbackAsync) => {
    let targetCallbackList = targetCallbackMap.get(target) ?? [];
    if (onResize != null) {
      targetCallbackList = targetCallbackList.filter(
        (otherOnResize) => onResize !== otherOnResize,
      );
    } else {
      // Will unobserve all
      targetCallbackList = [];
    }

    if (targetCallbackList.length === 0) {
      instance.unobserve(target);
      targetCallbackMap.delete(target);
      return;
    }

    // Save filtered callback list
    targetCallbackMap.set(target, targetCallbackList);
  };

  const cache = Object.freeze({
    observe,
    unobserve,
  });
  return (): ObserverCache => cache;
})();

/**
 * Uses a single ResizeObserver to track all elements used by the hook see: https://groups.google.com/a/chromium.org/g/blink-dev/c/z6ienONUb5A/m/F5-VcUZtBAAJ
 * @param onResize No need to memoize/useMemo/useCallback hook will handel changes
 * @returns A RefObject which you should pass to the element you want to observe
 */
const useResizeObserver = <T extends Element>(
  onResize: ObserverCallback,
): RefObject<T> => {
  const observer = getObserver();
  const targetRef = useRef<T>(null);
  const latestOnResizeRef = useRefLatest(onResize);

  const targetElm: Element | null = targetRef.current;
  useLayoutEffect(() => {
    let isUnobserved = false;

    if (!targetElm) {
      return () => null;
    }

    const callbackWrapper: ObserverCallbackAsync = (
      entry: ResizeObserverEntry,
    ) =>
      Promise.resolve(
        isUnobserved || !latestOnResizeRef.current
          ? null
          : latestOnResizeRef.current(entry),
      );

    observer.observe(targetElm, callbackWrapper);
    return () => {
      isUnobserved = true;
      observer.unobserve(targetElm, callbackWrapper);
    };
  }, [targetElm, latestOnResizeRef, observer]);

  return targetRef;
};

export { useResizeObserver };
