/**
 * HTTP Method: POST
 * Pathname: /allocations/{allocationId}/status
 * @function updateAllocationStatus
 * @memberof BuyAPI
 * @param {BuyAPIUpdateAllocationStatusPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvestorAllocationAllocationStatus } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIUpdateAllocationStatusPathParams = {
  allocationId: EnderId;
};

type BuyAPIUpdateAllocationStatusSearchParams = {
  token?: string | undefined;
};

type BuyAPIUpdateAllocationStatusBodyParams = {
  status: InvestorAllocationAllocationStatus;
};

type BuyAPIUpdateAllocationStatusPayload =
  BuyAPIUpdateAllocationStatusPathParams &
    BuyAPIUpdateAllocationStatusSearchParams &
    BuyAPIUpdateAllocationStatusBodyParams;

function updateAllocationStatusUnsafeEffect(
  payload: BuyAPIUpdateAllocationStatusPayload,
) {
  const { allocationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/allocations/${allocationId}/status`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateAllocationStatus(
  payload: BuyAPIUpdateAllocationStatusPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateAllocationStatusUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateAllocationStatus };
export type {
  BuyAPIUpdateAllocationStatusBodyParams,
  BuyAPIUpdateAllocationStatusPathParams,
  BuyAPIUpdateAllocationStatusPayload,
  BuyAPIUpdateAllocationStatusSearchParams,
};
