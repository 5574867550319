import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskCostEstimateRejectionReasonValues = [
  "PRICE",
  "INCORRECT_APPROACH",
  "MORE_INFO_REQUIRED",
] as const;

const TaskCostEstimateRejectionReasonEffectSchema = Schema.Literal(
  ...TaskCostEstimateRejectionReasonValues,
);

type TaskCostEstimateRejectionReason = Schema.Schema.Type<
  typeof TaskCostEstimateRejectionReasonEffectSchema
>;

const TaskCostEstimateRejectionReasonEnum = castEnum(
  TaskCostEstimateRejectionReasonEffectSchema,
);

function randomTaskCostEstimateRejectionReason(): TaskCostEstimateRejectionReason {
  return rand(TaskCostEstimateRejectionReasonValues);
}

export {
  randomTaskCostEstimateRejectionReason,
  TaskCostEstimateRejectionReasonEffectSchema,
  TaskCostEstimateRejectionReasonEnum,
  TaskCostEstimateRejectionReasonValues,
};
export type { TaskCostEstimateRejectionReason };
