import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GLCategoryGroupingGLCategoryGroupingTypeValues = ["NOI"] as const;

const GLCategoryGroupingGLCategoryGroupingTypeEffectSchema = Schema.Literal(
  ...GLCategoryGroupingGLCategoryGroupingTypeValues,
);

type GLCategoryGroupingGLCategoryGroupingType = Schema.Schema.Type<
  typeof GLCategoryGroupingGLCategoryGroupingTypeEffectSchema
>;

const GLCategoryGroupingGLCategoryGroupingTypeEnum = castEnum(
  GLCategoryGroupingGLCategoryGroupingTypeEffectSchema,
);

function randomGLCategoryGroupingGLCategoryGroupingType(): GLCategoryGroupingGLCategoryGroupingType {
  return rand(GLCategoryGroupingGLCategoryGroupingTypeValues);
}

export {
  GLCategoryGroupingGLCategoryGroupingTypeEffectSchema,
  GLCategoryGroupingGLCategoryGroupingTypeEnum,
  GLCategoryGroupingGLCategoryGroupingTypeValues,
  randomGLCategoryGroupingGLCategoryGroupingType,
};
export type { GLCategoryGroupingGLCategoryGroupingType };
