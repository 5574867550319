/**
 * HTTP Method: POST
 * Pathname: /collections/steps/{stepId}/groupWith
 * @function addToExclusiveGroup
 * @memberof CollectionsAPI
 * @param {CollectionsAPIAddToExclusiveGroupPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIAddToExclusiveGroupPathParams = {
  stepId: EnderId;
};

type CollectionsAPIAddToExclusiveGroupSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIAddToExclusiveGroupBodyParams = {
  otherStepId: EnderId;
};

type CollectionsAPIAddToExclusiveGroupPayload =
  CollectionsAPIAddToExclusiveGroupPathParams &
    CollectionsAPIAddToExclusiveGroupSearchParams &
    CollectionsAPIAddToExclusiveGroupBodyParams;

function addToExclusiveGroupUnsafeEffect(
  payload: CollectionsAPIAddToExclusiveGroupPayload,
) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/collections/steps/${stepId}/groupWith`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addToExclusiveGroup(
  payload: CollectionsAPIAddToExclusiveGroupPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addToExclusiveGroupUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addToExclusiveGroup };
export type {
  CollectionsAPIAddToExclusiveGroupBodyParams,
  CollectionsAPIAddToExclusiveGroupPathParams,
  CollectionsAPIAddToExclusiveGroupPayload,
  CollectionsAPIAddToExclusiveGroupSearchParams,
};
