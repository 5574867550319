import { addPet } from "./add-pet";
import { addPetToLead } from "./add-pet-to-lead";
import { addPetToOwner } from "./add-pet-to-owner";
import { archivePet } from "./archive-pet";
import { deletePet } from "./delete-pet";
import { deletePetFromLead } from "./delete-pet-from-lead";
import { getPets } from "./get-pets";
import { getPetsForLead } from "./get-pets-for-lead";
import { getPetsForOwner } from "./get-pets-for-owner";
import { unarchivePet } from "./unarchive-pet";
import { updatePet } from "./update-pet";

const PetsAPI = {
  addPet,
  addPetToLead,
  addPetToOwner,
  archivePet,
  deletePet,
  deletePetFromLead,
  getPets,
  getPetsForLead,
  getPetsForOwner,
  unarchivePet,
  updatePet,
};

export { PetsAPI };
