import { IconEdit } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Array as A,
  Number as N,
  Predicate as P,
  Record as R,
  flow,
} from "effect";
import { useCallback } from "react";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { FormsAPI } from "@ender/shared/generated/com.ender.middle";
import { UnitAreasAPI } from "@ender/shared/generated/ender.api.core";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

import { getTupleDisplayValue } from "./construction.utils";
import type { FormDiscriminant } from "./form-generator";
import { FormGenerator } from "./form-generator";
import type { UnitAreaDiscriminant } from "./unit-area.types";
import { useCanEditAreasAndZones } from "./use-can-edit-areas-and-zones";

type InspectionAreaProps = {
  area: UnitAreaDiscriminant;
};

function InspectionAreaFields(props: InspectionAreaProps) {
  const queryClient = useQueryClient();
  const { area } = props;
  const { disabled, disabledTooltip } = useCanEditAreasAndZones(area.unitId);
  const [isEditing, isEditingHandlers] = useBoolean(false);

  const { mutateAsync } = useMutation({
    mutationFn: UnitAreasAPI.updateUnitArea,
    mutationKey: ["UnitAreasAPI.updateUnitArea"],
  });

  const handleSubmit = useCallback(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: any) => {
      const { description, ...data } = values;
      return mutateAsync({ data, description, areaId: area.id }).then(() => {
        isEditingHandlers.setFalse();
        queryClient.invalidateQueries([
          "UnitAreasAPI.getUnitAreas",
          { unitId: area.unitId },
        ]);
      });
    },
    [isEditingHandlers, mutateAsync, queryClient, area],
  );

  const { data: schema = [] } = useQuery({
    //@ts-expect-error useQuery typing is dumb
    enabled: P.isNotNullable(area),
    queryFn: () => FormsAPI.getForm({ formType: area.areaType }),
    queryKey: ["FormsAPI.getForm", { formType: area.areaType }],
    select: flow(
      R.reduce([] as FormDiscriminant[], (acc, val) =>
        P.isNotNullable(val) ? A.appendAll(acc, val) : acc,
      ),
      A.sort<FormDiscriminant>((a, b) => N.sign(a.order - b.order)),
    ),
  });

  return (
    <Card>
      {!isEditing ? (
        <Stack>
          <Group justify={Justify.between}>
            <H3>{area.name} Overview</H3>
            <Group spacing={Spacing.xs}>
              <ActionIcon
                variant={ButtonVariant.transparent}
                onClick={isEditingHandlers.setTrue}
                label="Edit"
                disabled={disabled}
                disabledTooltip={disabledTooltip}>
                <IconEdit />
              </ActionIcon>
            </Group>
          </Group>
          <Grid spacingY={Spacing.none}>
            {schema.map((field) => (
              <Tuple
                key={field.label}
                label={field.label}
                // @ts-expect-error tis the nature of dynamic fields
                value={getTupleDisplayValue(area[field.name])}
              />
            ))}
          </Grid>
        </Stack>
      ) : (
        <FormGenerator
          schema={schema}
          defaultValues={area}
          onSubmit={handleSubmit}
          header={
            <Group justify={Justify.between}>
              <H3>{area.name} Overview</H3>
              <Group>
                <Button
                  variant={ButtonVariant.transparent}
                  onClick={isEditingHandlers.setFalse}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Group>
            </Group>
          }
        />
      )}
    </Card>
  );
}

export { InspectionAreaFields };
