/**
 * HTTP Method: GET
 * Pathname: /systemReports
 * @function getSystemReports
 * @memberof ReportsAPI
 * @param {ReportsAPIGetSystemReportsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ReportsAPIGetSystemReportsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ReportsAPIGetSystemReportsResponse } from "../reports-api-get-system-reports-response";

type ReportsAPIGetSystemReportsSearchParams = {
  token?: string | undefined;
};

type ReportsAPIGetSystemReportsPayload = ReportsAPIGetSystemReportsSearchParams;

function getSystemReportsUnsafeEffect(
  payload: ReportsAPIGetSystemReportsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ReportsAPIGetSystemReportsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<ReportsAPIGetSystemReportsResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/systemReports",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSystemReports(
  payload: ReportsAPIGetSystemReportsPayload,
  options?: { signal?: AbortSignal },
): Promise<ReportsAPIGetSystemReportsResponse[]> {
  return F.pipe(
    payload,
    getSystemReportsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSystemReports };
export type {
  ReportsAPIGetSystemReportsPayload,
  ReportsAPIGetSystemReportsSearchParams,
};
