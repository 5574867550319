/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/cancel-move-out
 * @function removeLeaseExpectedMoveOutDate
 * @memberof LeasingAPI
 * @param {LeasingAPIRemoveLeaseExpectedMoveOutDatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIRemoveLeaseExpectedMoveOutDatePathParams = {
  leaseId: EnderId;
};

type LeasingAPIRemoveLeaseExpectedMoveOutDateSearchParams = {
  token?: string | undefined;
};

type LeasingAPIRemoveLeaseExpectedMoveOutDatePayload =
  LeasingAPIRemoveLeaseExpectedMoveOutDatePathParams &
    LeasingAPIRemoveLeaseExpectedMoveOutDateSearchParams;

function removeLeaseExpectedMoveOutDateUnsafeEffect(
  payload: LeasingAPIRemoveLeaseExpectedMoveOutDatePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/cancel-move-out`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeLeaseExpectedMoveOutDate(
  payload: LeasingAPIRemoveLeaseExpectedMoveOutDatePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeLeaseExpectedMoveOutDateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeLeaseExpectedMoveOutDate };
export type {
  LeasingAPIRemoveLeaseExpectedMoveOutDatePathParams,
  LeasingAPIRemoveLeaseExpectedMoveOutDatePayload,
  LeasingAPIRemoveLeaseExpectedMoveOutDateSearchParams,
};
