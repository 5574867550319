import { Schema } from "@effect/schema";
import { IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Option as O, Predicate as P, pipe } from "effect";
import { useCallback, useContext, useState } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import { addFilesToLedgerEvent } from "@ender/shared/api/ledger";
import type { Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema, LocalDate$ } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { FileEffectSchema, FormFileInput } from "@ender/shared/ds/file-input";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import type { FilesClientEnderFile } from "@ender/shared/generated/com.ender.common.arch.client";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import type { TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow } from "@ender/shared/generated/com.ender.middle.response";
import { PaymentsAPI } from "@ender/shared/generated/ender.api.accounting";
import { WebserverFilesAPI } from "@ender/shared/generated/ender.api.files";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type S3File = Pick<FilesClientEnderFile, "id" | "path" | "s3Url"> & {
  name: string;
};
type FileToDelete = { id: EnderId; name: string; path: string };
const noAttachmentsStatement = "No attachments available";

type AttachmentItemProps = {
  id?: EnderId;
  name: string;
  path?: string;
  url?: string;
  deleteTooltip?: string;
  deleteDisabled?: boolean;
  isDeleting?: boolean;
  onDelete: (args: {
    id?: EnderId;
    name: string;
    path?: string;
  }) => Promise<void>;
};

function AttachmentItem(props: AttachmentItemProps) {
  const {
    id,
    name,
    path,
    url,
    deleteDisabled,
    deleteTooltip,
    isDeleting,
    onDelete,
  } = props;
  const onDeleteClick = useCallback(() => {
    onDelete({ id, name, path });
  }, [id, path, name, onDelete]);

  return (
    <Group align={Align.center} spacing={Spacing.md} justify={Justify.between}>
      {P.isNotNullable(url) ? (
        <a href={url} target="_blank" rel="noreferrer">
          <Text size={FontSize.sm}>{name}</Text>
        </a>
      ) : (
        <Text size={FontSize.sm}>{name}</Text>
      )}
      <ActionIcon
        label={`Delete ${name}`}
        onClick={onDeleteClick}
        tooltip={deleteTooltip}
        disabled={deleteDisabled}
        disabledTooltip={deleteTooltip}
        loading={isDeleting}>
        <IconTrash size="18px" />
      </ActionIcon>
    </Group>
  );
}

type Receipt = Pick<
  TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow,
  "id" | "checkNumber" | "receiptDate" | "memo" | "tenantName" | "amount"
> & {
  bankAccount: Pick<
    TenantReceiptDepositSearchResponseTenantReceiptDepositResponseRow["bankAccount"],
    "name"
  >;
};
type EditReceiptFormProps = {
  receipt: Receipt;
  onSuccess: () => void;
};

const EditReceiptFormSchema = Schema.Struct({
  additionalFiles: Schema.Array(FileEffectSchema).pipe(Schema.mutable),
  amount: Schema.String,
  bankAccount: Schema.String,
  checkNumber: Schema.String.pipe(
    Schema.nonEmptyString({
      message: () => "Check Number is required",
    }),
    Schema.pattern(/^[0-9a-zA-Z.-]*$/, {
      message: () => "Check Number has invalid characters",
    }),
  ),
  date: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  memo: Schema.String,
  moneyTransferId: EnderIdFormSchema,
  tenantName: Schema.String,
  token: Schema.optional(Schema.String),
});

type EditReceiptFormValues = Schema.Schema.Type<typeof EditReceiptFormSchema>;

function EditReceiptForm({ onSuccess, receipt }: EditReceiptFormProps) {
  const { hasPermissions } = useContext(UserContext);
  const confirmation = useConfirmationContext();
  const [fileToDelete, setFileToDelete] = useState<FileToDelete | Undefined>(
    UNDEFINED,
  );
  const { mutateAsync: deleteFile, isLoading: isDeletingFile } = useMutation({
    mutationFn: WebserverFilesAPI.deleteFile,
    mutationKey: ["deleteFile", receipt.id],
  });
  const { mutateAsync: editReceipt, isLoading: isSavingReceipt } = useMutation({
    mutationFn: PaymentsAPI.editReceipt,
    mutationKey: ["editReceipt", receipt.id],
  });
  const { mutateAsync: uploadAttachment, isLoading: isUploadingAttachment } =
    useMutation({
      mutationFn: addFilesToLedgerEvent,
      mutationKey: ["addFilesToLedgerEvent", receipt.id],
    });

  const effectForm = useEffectSchemaForm({
    defaultValues: {
      additionalFiles: [],
      amount: receipt.amount,
      bankAccount: receipt.bankAccount.name,
      checkNumber: receipt.checkNumber,
      date: O.fromNullable(LocalDate$.of(receipt.receiptDate)),
      memo: receipt.memo,
      moneyTransferId: receipt.id,
      tenantName: receipt.tenantName,
    },
    schema: EditReceiptFormSchema,
  });
  const [additionalFiles] = effectForm.watch(["additionalFiles"]);

  const {
    data,
    isLoading,
    refetch: fetchExistingFiles,
  } = useQuery({
    queryFn: () =>
      WebserverFilesAPI.getFiles({
        modelId: receipt.id,
        modelType: ModelTypeEnum.MONEY_TRANSFER,
      }),
    queryKey: ["getFiles", receipt.id],
  });

  const existingFiles = data?.files ?? [];

  async function handleSubmit(values: EditReceiptFormValues) {
    const { additionalFiles, checkNumber, memo, moneyTransferId } = values;
    try {
      await Promise.all([
        editReceipt({
          checkNumber,
          memo,
          moneyTransferId,
        }),

        additionalFiles.length &&
          uploadAttachment({
            eventId: moneyTransferId,
            eventType: ModelTypeEnum.MONEY_TRANSFER,
            files: additionalFiles,
            // @ts-expect-error userId should be nullable
            userId: NULL,
          }),
      ]);

      onSuccess();
      showSuccessNotification({ message: "Check updated" });
    } catch (err) {
      fail(err);
    }
  }

  const onDeleteAttachment = useCallback(
    async (args: { id?: EnderId; name: string; path?: string }) => {
      const { id, name, path } = args;
      if (P.isNullable(id) || P.isNullable(path)) {
        // ID is only Nullish for AdditionalFiles
        effectForm.setValue(
          "additionalFiles",
          additionalFiles.filter((f) => f.name !== name),
        );
        return;
      }

      await confirmation(
        {
          confirmButtonLabel: "Delete Attachment",
          title: "Are you sure you want to delete this file?",
        },
        {
          cancelButtonProps: {
            onClick: () => setFileToDelete(UNDEFINED),
          },
        },
      );
      setFileToDelete({ id, name, path });

      await deleteFile({
        fileId: id,
        modelId: receipt.id,
        modelType: ModelTypeEnum.MONEY_TRANSFER,
      });
      fetchExistingFiles();
      showSuccessNotification({ message: "File deleted" });
      setFileToDelete(UNDEFINED);
    },
    [
      effectForm,
      setFileToDelete,
      additionalFiles,
      confirmation,
      deleteFile,
      receipt.id,
      fetchExistingFiles,
    ],
  );

  const hasDeletePermission = hasPermissions(
    FunctionalPermissionEnum.DELETE_DOCUMENTS,
  );
  const noAttachments =
    A.isEmptyArray(existingFiles) && A.isEmptyArray(additionalFiles);

  return (
    <>
      <Form form={effectForm} onSubmit={handleSubmit}>
        <Stack>
          <H2>Edit Check</H2>
          <Skeleton visible={isLoading}>
            <FormTextInput
              label="Tenant Name"
              name="tenantName"
              form={effectForm}
              disabled
            />
            <FormTextInput
              disabled
              label="Bank Account"
              name="bankAccount"
              form={effectForm}
            />
            <FormTextInput
              disabled
              label="Amount"
              name="amount"
              form={effectForm}
            />
            <FormTextInput
              label="Check Number"
              name="checkNumber"
              form={effectForm}
              placeholder="000000"
            />
            <FormDateInput
              label="Received Date"
              name="date"
              form={effectForm}
              disabled
            />
            <FormTextInput
              label="Memo (optional)"
              name="memo"
              form={effectForm}
            />
            <Stack spacing={Spacing.xs}>
              <Text>Attachments</Text>
              <Card>
                <Stack>
                  {noAttachments && (
                    <Text size={FontSize.sm}>{noAttachmentsStatement}</Text>
                  )}
                  {existingFiles.map((file: S3File) => {
                    return (
                      <AttachmentItem
                        key={file.id}
                        id={file.id}
                        name={file.name}
                        path={file.path}
                        url={file.s3Url}
                        deleteDisabled={!hasDeletePermission}
                        deleteTooltip={
                          hasDeletePermission
                            ? "Delete Document"
                            : "You do not have permission to delete documents"
                        }
                        isDeleting={
                          isDeletingFile &&
                          file.id ===
                            pipe(
                              fileToDelete,
                              O.fromNullable,
                              O.map((v) => v.id),
                              O.getOrNull,
                            )
                        }
                        onDelete={onDeleteAttachment}
                      />
                    );
                  })}
                  <FormFileInput name="additionalFiles" form={effectForm} />
                </Stack>
              </Card>
            </Stack>
            <Group justify={Justify.end}>
              <Button
                type="submit"
                loading={isSavingReceipt || isUploadingAttachment}>
                Confirm
              </Button>
            </Group>
          </Skeleton>
        </Stack>
      </Form>
    </>
  );
}

export { EditReceiptForm };
