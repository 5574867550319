import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Array as A, Function as F, Option as O, Predicate as P } from "effect";
import { useWatch } from "react-hook-form";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { EnderIdFormSchema } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import type { SelectOption } from "@ender/shared/ds/select";
import { Select } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import type { UnitsAPIUpdateUnitPayload } from "@ender/shared/generated/ender.api.core";
import {
  PropertiesAPI,
  UnitsAPI,
} from "@ender/shared/generated/ender.api.core";
import type { GetPropertyUnitTypesResponse } from "@ender/shared/generated/ender.api.core.response";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const SpecificationFormSchema = Schema.Struct({
  bedrooms: Schema.Number.pipe(
    Schema.nonNegative({ message: () => "Must not be negative" }),
    Schema.OptionFromSelf,
  ),
  floorNum: Schema.Number.pipe(Schema.OptionFromSelf),
  fullBaths: Schema.Number.pipe(
    Schema.nonNegative({ message: () => "Must not be negative" }),
    Schema.OptionFromSelf,
  ),
  halfBaths: Schema.Number.pipe(
    Schema.nonNegative({ message: () => "Must not be negative" }),
    Schema.OptionFromSelf,
  ),
  sqft: Schema.Number.pipe(
    Schema.positive({ message: () => "Must be greater than 0" }),
    Schema.OptionFromSelf,
  ),
  unitTypeId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
});
type SpecificationFormOutput = Schema.Schema.Type<
  typeof SpecificationFormSchema
>;

type EditSpecificationsFormProps = {
  unit: UnitSerializerUnitResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
};

function EditSpecificationsForm(props: EditSpecificationsFormProps) {
  const { unit, onSuccess = F.constVoid, onCancel = F.constVoid } = props;
  const confirmation = useConfirmationContext();

  const { data: unitTypes = [] } = useQuery({
    queryKey: ["PropertiesAPI.getPropertyUnitTypes", unit?.property.id],
    queryFn: () =>
      PropertiesAPI.getPropertyUnitTypes({
        propertyId: unit?.property.id as EnderId,
      }),
    enabled: P.isNotNullable(unit?.property.id),
  });

  const form = useEffectSchemaForm({
    defaultValues: {
      bedrooms: O.fromNullable(unit?.bedrooms ?? 0),
      floorNum: O.fromNullable(unit?.floorNum),
      fullBaths: O.fromNullable(unit?.fullBaths ?? 0),
      halfBaths: O.fromNullable(unit?.halfBaths ?? 0),
      sqft: O.fromNullable(unit?.sqft ?? 0),
      unitTypeId: O.fromNullable(unit?.unitTypeId),
    },
    schema: SpecificationFormSchema,
  });
  const { formState } = form;

  const differsFromUnitType =
    formState.dirtyFields.bedrooms ||
    formState.dirtyFields.fullBaths ||
    formState.dirtyFields.halfBaths ||
    formState.dirtyFields.sqft;

  const { mutateAsync: updateUnit, isLoading: updatingUnit } = useMutation({
    mutationFn: UnitsAPI.updateUnit,
    mutationKey: ["UnitsAPI.updateUnit"] as const,
  });

  async function handleTypeSelect(
    _value: O.Option<EnderId>,
    item: O.Option<SelectOption<EnderId, GetPropertyUnitTypesResponse>>,
  ) {
    const unitType = O.getOrUndefined(item)?.meta;
    try {
      if (differsFromUnitType) {
        await confirmation({
          content:
            "Changing the unit type will autofill values, which may overwrite the values you have already entered",
          title: "Are you sure?",
        });
      }
      if (unitType) {
        const {
          beds: bedrooms = 0,
          fullBaths = 0,
          halfBaths = 0,
          sqft = 0,
        } = unitType;
        form.reset({ bedrooms, fullBaths, halfBaths, sqft });
      }
      form.setValue("unitTypeId", O.fromNullable(unitType?.id));
    } catch {
      //fails from cancelling confirmation. do nothing
    }
  }

  async function handleSubmit(values: SpecificationFormOutput) {
    if (!unit?.id) {
      return;
    }

    const payload: UnitsAPIUpdateUnitPayload = {
      bedrooms: O.getOrUndefined(values.bedrooms),
      floorNum: O.getOrUndefined(values.floorNum),
      fullBaths: O.getOrUndefined(values.fullBaths),
      halfBaths: O.getOrUndefined(values.halfBaths),
      sqft: O.getOrUndefined(values.sqft),
      unitId: unit.id,
      unitTypeId: O.getOrUndefined(values.unitTypeId),
    };

    await updateUnit({ ...payload });
    showSuccessNotification({ message: "Unit successfully updated" });
    onSuccess();
  }

  const [unitTypeId] = useWatch({
    control: form.control,
    name: ["unitTypeId"],
  });

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <Select
          label="Unit Type"
          placeholder={
            A.isEmptyArray(unitTypes) ? "No unit types configured" : "*"
          }
          description="Unit types can be added on the Property page"
          clearable
          data={unitTypes.map((unitType) => ({
            label: unitType.name,
            meta: unitType,
            value: unitType.id,
          }))}
          value={differsFromUnitType ? O.none() : unitTypeId}
          onChange={handleTypeSelect}
        />
        <FormNumberInput
          form={form}
          //@ts-expect-error min is soon-to-be supported, and is presently supported by form validation
          min={0}
          label="Beds"
          name="bedrooms"
        />
        <FormNumberInput
          form={form}
          //@ts-expect-error min is soon-to-be supported, and is presently supported by form validation
          min={0}
          label="Full Baths"
          name="fullBaths"
        />
        <FormNumberInput
          form={form}
          //@ts-expect-error min is soon-to-be supported, and is presently supported by form validation
          min={0}
          label="Half Baths"
          name="halfBaths"
        />
        <FormNumberInput
          form={form}
          //@ts-expect-error min is soon-to-be supported, and is presently supported by form validation
          min={0}
          label="Actual Sqft"
          name="sqft"
        />
        <FormNumberInput form={form} label="Floor #" name="floorNum" />

        <Group align={Align.center} justify={Justify.end}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={updatingUnit} type="submit">
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditSpecificationsForm };
