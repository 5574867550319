import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetMoveOutTableRequestMoveOutTabValues = [
  "MOVE_OUT_SCHEDULED",
  "MOVE_OUT_ACTUAL",
] as const;

const GetMoveOutTableRequestMoveOutTabEffectSchema = Schema.Literal(
  ...GetMoveOutTableRequestMoveOutTabValues,
);

type GetMoveOutTableRequestMoveOutTab = Schema.Schema.Type<
  typeof GetMoveOutTableRequestMoveOutTabEffectSchema
>;

const GetMoveOutTableRequestMoveOutTabEnum = castEnum(
  GetMoveOutTableRequestMoveOutTabEffectSchema,
);

function randomGetMoveOutTableRequestMoveOutTab(): GetMoveOutTableRequestMoveOutTab {
  return rand(GetMoveOutTableRequestMoveOutTabValues);
}

export {
  GetMoveOutTableRequestMoveOutTabEffectSchema,
  GetMoveOutTableRequestMoveOutTabEnum,
  GetMoveOutTableRequestMoveOutTabValues,
  randomGetMoveOutTableRequestMoveOutTab,
};
export type { GetMoveOutTableRequestMoveOutTab };
