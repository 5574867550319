import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Array as A, Function as F, Predicate as P } from "effect";
import { Fragment, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Checkbox } from "@ender/shared/ds/checkbox";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { ReportsAPI } from "@ender/shared/generated/ender.api.reports";
import { ReportFilterOperatorEnum } from "@ender/shared/generated/ender.model.reports";
import type { WidgetDataJobResponse } from "@ender/shared/generated/ender.service.reports.response";
import { useDocumentTitle } from "@ender/shared/hooks/use-document-title";
import { useSetHeader } from "@ender/shared/hooks/use-set-header";
import { EmptyTableRow } from "@ender/shared/ui/empty-table-row";
import { EnderPagination } from "@ender/shared/ui/ender-pagination";
import { EnderTable } from "@ender/shared/ui/ender-table";
import { fail } from "@ender/shared/utils/error";

import { QUERY_KEY } from "../underwriting-page-helpers";
import { CityFilter } from "../underwriting-queue-table/filter-fields/city-filter";
import type {
  FactorValue,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "../underwriting-queue-table/filter-fields/filter-types";
import { Factor } from "../underwriting-queue-table/filter-fields/filter-types";
import { StatusFilter } from "../underwriting-queue-table/filter-fields/lead-offer-status-filter";
import { MarketFilter } from "../underwriting-queue-table/filter-fields/market-filter";
import type { PropertyDetails } from "../underwriting-queue-table/underwriting-bulk-reject";
import { UnderwritingBulkReject } from "../underwriting-queue-table/underwriting-bulk-reject";
import { HIDDEN_Y_FACTORS as PARTIAL_HIDDEN_Y_FACTORS } from "../underwriting-queue-table/underwriting-queue-table.constants";
import { DEFAULT_STATUSES } from "../underwriting-queue-table/underwriting-widget-helpers";
import {
  getWidget,
  getYFactor,
  widgetToUpdateRequest,
} from "../underwriting-queue-table/widget-helpers";
import { formatCellData } from "../underwriting-queue.utils";
import { AssignedUnderwritingFiltersRail } from "./assigned-queue-filter-rail";

import underwritingQueueStyles from "../underwriting-queue.module.css";
import auqStyles from "./assigned-underwriting-queue.module.css";

const RESULTS_PER_PAGE = 10;

const AssignedFactorOrder: FactorValue[] = [
  Factor.STREET,
  Factor.MARKET,
  Factor.CITY,
  Factor.LIST_DATE,
  Factor.LIST_PRICE,
  Factor.BEDS,
  Factor.BATHS,
  Factor.SQFT,
  Factor.YEAR_BUILT,
  Factor.UNDERWRITING_SCORE,
  Factor.STATUS,
  Factor.NAME,
  Factor.ESTIMATE_RENT,
  Factor.ESTIMATE_VALUE,
  Factor.UPLOAD_DATE,
  Factor.FILE_NAME,
  Factor.UNDERWRITER,
  Factor.ZIPCODE,
  Factor.CHANNEL,
  Factor.DUP_ADDRESS,
];

const HIDDEN_Y_FACTORS: FactorValue[] = [
  ...PARTIAL_HIDDEN_Y_FACTORS,
  Factor.UNDERWRITER,
];

const DEFAULT_TABLE_DATA = {
  columns: [],
  rows: [],
  totalLeaves: 0,
};

const ASSIGNED_UNDERWRITINGS = "assigned-underwriting-queue";

const headerConfig = {
  breadcrumbs: [
    { href: "/buy", title: "Buy Properties" },
    { href: "/buy/underwriting/queue", title: "Global Underwriting Queue" },
    { title: "My Underwriting Queue" },
  ],
};

function AssignedUnderwritingQueue() {
  const [page, setPage] = useState(1);
  const [displayRightRail, setDisplayRightRail] = useState(false);
  const [firstPropertyId, setFirstPropertyId] = useState<EnderId>();
  const [selectedProperties, setSelectedProperties] = useState<
    Record<EnderId, PropertyDetails>
  >({});
  const [showBulkReject, setShowBulkReject] = useState(false);

  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const history = useHistory();

  useSetHeader(headerConfig);
  useDocumentTitle("My Underwriting Queue - Ender");

  const {
    data: widget,
    refetch: refetchWidget,
    error: widgetError,
  } = useQuery<Widget>({
    queryKey: ["ReportsAPI.getWidget", ASSIGNED_UNDERWRITINGS, user.id] as const,
    queryFn: async ({ signal }) => {
      const widget = await getWidget(
        ASSIGNED_UNDERWRITINGS, 
        {
          requiredFilters: [
            {
              factorName: Factor.STATUS,
              operator: ReportFilterOperatorEnum.IN,
              values: [...DEFAULT_STATUSES],
            },
            {
              factorName: Factor.UNDERWRITER,
              operator: ReportFilterOperatorEnum.IN,
              values: [user.id],
            },
          ],
          requiredYFactors: {
            factorNames: AssignedFactorOrder,
            keepOrder: true,
          },
        },
        signal
      );
      return widget as Widget;
    },
  });

  const {
    data: tableData,
    refetch: refetchPage,
    isFetching,
    isFetched,
    isError: tableError,
  } = useQuery({
    enabled: P.isNotNullable(widget),
    initialData: { ...DEFAULT_TABLE_DATA },
    queryFn: async ({ signal }) => {
      if (!widget?.id) {
        return { ...DEFAULT_TABLE_DATA };
      }

      const data = await ReportsAPI.getWidgetData(
        {
          limit: RESULTS_PER_PAGE,
          offset: (page - 1) * RESULTS_PER_PAGE,
          widgetId: widget.id,
        },
        { signal },
      );

      if (page === 1) {
        setFirstPropertyId(data?.root.children?.[0]?.model?.id);
      }

      return data;
    },
    queryKey: ["assigned-underwriting-queue-data", widget, page] as const,
    refetchOnWindowFocus: false,
  });

  const totalNumberOfProperties = tableData.totalLeaves;

  const {
    isLoading: selectAllPropertiesLoading,
    refetch: selectAllProperties,
  } = useQuery({
    enabled: false,
    queryFn: async ({ signal }) => {
      if (!widget?.id) {
        return;
      }
      
      const data = await ReportsAPI.getWidgetData(
        {
          limit: totalNumberOfProperties,
          offset: 0,
          widgetId: widget.id,
        },
        { signal },
      );
      const allSelectedProperties = data.root.children.reduce(
        (acc, row) => ({
          ...acc,
          [row.model.id]: {
            city: row.values[
              widget?.yFactors.findIndex(({ name }) => name === Factor.CITY) ?? -1
            ],
            dealId: row.model.id,
            listPrice:
              row.values[
                widget?.yFactors.findIndex(
                  ({ name }) => name === Factor.LIST_PRICE,
                ) ?? -1
              ],
            priorityScore:
              row.values[
                widget?.yFactors.findIndex(
                  ({ name }) => name === Factor.UNDERWRITING_SCORE,
                ) ?? -1
              ],
            street:
              row.values[
                widget?.yFactors.findIndex(({ name }) => name === Factor.STREET) ?? -1
              ],
          },
        }),
        {},
      );
      setSelectedProperties(allSelectedProperties);
    },
    queryKey: ["select-all-properties"] as const,
  });

  function isWidgetDataJobResponse(
    tableData: WidgetDataJobResponse | typeof DEFAULT_TABLE_DATA,
  ): tableData is WidgetDataJobResponse {
    return "root" in tableData;
  }

  function handleChangePropertyChecked(checked: boolean, dealId: EnderId) {
    const row = isWidgetDataJobResponse(tableData)
      ? tableData.root.children.find((row) => row.model.id === dealId)
      : UNDEFINED;
    const propertyInfo = {
      city: row?.values[
        widget?.yFactors.findIndex(({ name }) => name === Factor.CITY) ?? -1
      ],
      dealId,
      listPrice:
        row?.values[
          widget?.yFactors.findIndex(({ name }) => name === Factor.LIST_PRICE) ?? -1
        ],
      priorityScore:
        row?.values[
          widget?.yFactors.findIndex(
            ({ name }) => name === Factor.UNDERWRITING_SCORE,
          ) ?? -1
        ],
      status:
        row?.values[
          widget?.yFactors.findIndex(({ name }) => name === Factor.STATUS) ?? -1
        ],
      street:
        row?.values[
          widget?.yFactors.findIndex(({ name }) => name === Factor.STREET) ?? -1
        ],
    };
    setSelectedProperties((p) => ({
      ...p,
      [dealId]: checked ? propertyInfo : UNDEFINED,
    }));
  }

  function handleFiltersChanged() {
    queryClient.invalidateQueries([QUERY_KEY.ASSIGNED_PROPERTY_IDS]);
    setPage(1);
    refetchWidget();
  }

  async function handleSort(factor: WidgetFactor) {
    queryClient.invalidateQueries([QUERY_KEY.ASSIGNED_PROPERTY_IDS]);

    if (!widget) {
      return;
    }

    if (factor.id !== widget.sortByFactor?.id) {
      widget.sortByFactor = factor;
      widget.sortOrder = "ASCENDING";
    } else {
      widget.sortOrder =
        widget.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    }

    try {
      await ReportsAPI.updateWidget({
        widgetId: widget.id,
        ...widgetToUpdateRequest(widget),
      });
      refetchWidget();
      if (page !== 1) {
        setPage(1);
      } else {
        refetchPage();
      }
    } catch (err) {
      fail(err);
    }
  }

  function handleChangePage(page: number) {
    setPage(page);
  }

  async function updateFilter(
    factorName: FactorValue,
    filters: WidgetFilter[] | undefined,
  ) {
    if (P.isNullable(widget)) {
      return;
    }

    if (factorName === Factor.MARKET && P.isNullable(filters)) {
      widget.filters = widget.filters.filter(
        ({ factor }) => factor.name !== Factor.CITY,
      );
    }

    widget.filters = widget.filters.filter(
      ({ factor }) => factor.name !== factorName,
    );
    if (P.isNotNullable(filters)) {
      widget.filters.push(...filters);
    }

    await ReportsAPI.updateWidget({
      widgetId: widget.id,
      ...widgetToUpdateRequest(widget),
    });
    handleFiltersChanged();
  }

  function handleCloseBulkRejection(refreshTable?: boolean) {
    setShowBulkReject(false);

    if (P.isBoolean(refreshTable) && Boolean(refreshTable)) {
      setSelectedProperties({});
      refetchPage();
    }
  }

  const marketSelected = !!widget?.filters.find(
    ({ factor }) => factor.name === Factor.MARKET,
  )?.values?.[0];
  const filteredSelectedProperties =
    Object.values(selectedProperties).filter(Boolean);
  const selectedNumberOfProperties = filteredSelectedProperties.length;

  if (widgetError || tableError) {
    return NULL;
  }

  return (
    <Stack>
      <div className={auqStyles.tableHeader}>
        <Group spacing={Spacing.sm} align={Align.end}>
          {P.isNotNullable(widget) && (
            <>
              <StatusFilter
                updateFilters={updateFilter}
                widget={widget}
                factor={getYFactor(widget, Factor.STATUS)}
              />
              <MarketFilter
                updateFilters={updateFilter}
                widget={widget}
                factor={getYFactor(widget, Factor.MARKET)}
                metadata={{
                  filterWidgetName: "assigned-underwriting-queue-filter",
                }}
              />
              <CityFilter
                disabled={!marketSelected}
                updateFilters={updateFilter}
                widget={widget}
                factor={getYFactor(widget, Factor.CITY)}
                metadata={{
                  placeholderOverride: !marketSelected
                    ? "Select a market"
                    : UNDEFINED,
                }}
              />
              <Button
                variant={ButtonVariant.transparent}
                onClick={() => setDisplayRightRail(true)}>
                Filters
              </Button>
            </>
          )}
        </Group>
        {P.isNotNullable(firstPropertyId) ? (
          <Group>
            <Link to={`/buy/underwriting/queue/${firstPropertyId}`}>
              <Button disabledTooltip="No properties in table">
                Begin Underwriting
              </Button>
            </Link>
            <Button
              disabled={selectedNumberOfProperties === 0}
              onClick={() => setShowBulkReject(true)}>
              Bulk Reject{" "}
              {selectedNumberOfProperties
                ? `(${selectedNumberOfProperties})`
                : ""}
            </Button>
          </Group>
        ) : (
          <Button disabled disabledTooltip="No properties in table">
            Begin Underwriting
          </Button>
        )}
      </div>
      <div>Displaying {tableData.totalLeaves} results</div>
      <div className={underwritingQueueStyles.underwritingQueueTable}>
        <EnderTable className={auqStyles.assignedQueueTable}>
          <thead>
            <tr>
              <th>
                <Checkbox
                  value={
                    selectedNumberOfProperties === totalNumberOfProperties &&
                    totalNumberOfProperties > 0
                  }
                  onChange={(val) => {
                    if (!val) {
                      return setSelectedProperties({});
                    }

                    selectAllProperties();
                  }}
                  disabled={selectAllPropertiesLoading}
                />
              </th>
              {widget?.yFactors?.map((factor) => {
                const { id, name } = factor;

                let displayName: string = name;
                switch (name) {
                  case Factor.BEDS:
                    displayName = "Beds";
                    break;
                  case Factor.BATHS:
                    displayName = "Baths";
                    break;
                  case Factor.UNDERWRITING_SCORE:
                    displayName = "Priority Score";
                    break;
                  case Factor.ESTIMATE_RENT:
                    displayName = "Rent AVM";
                    break;
                  case Factor.ESTIMATE_VALUE:
                    displayName = "AVM";
                    break;
                }

                if (HIDDEN_Y_FACTORS.includes(name)) {
                  return <Fragment key={id} />;
                }

                return (
                  <th
                    key={id}
                    onClick={() => handleSort(factor)}
                    className="cursor">
                    <Group spacing={Spacing.xs} noWrap>
                      {displayName}
                      {widget.sortByFactor?.name === name &&
                        (widget.sortOrder === "ASCENDING" ? (
                          <IconChevronUp />
                        ) : (
                          <IconChevronDown />
                        ))}
                    </Group>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!isFetching &&
              isFetched &&
              isWidgetDataJobResponse(tableData) &&
              tableData.root.children.map((row, index) => {
                return (
                  <tr key={`${row.model.id}-${index}`}>
                    <td>
                      <Checkbox
                        value={!!selectedProperties[row.model.id]}
                        onChange={(val) =>
                          handleChangePropertyChecked(val, row.model.id)
                        }
                      />
                    </td>
                    {row.values.map(({ value }, i) => {
                      if (!widget) {
                        return null;
                      }
                      const headerName = widget.yFactors[i].name;
                      if (HIDDEN_Y_FACTORS.includes(headerName)) {
                        return (
                          <Fragment key={`${row.model.id}-${index}-${i}`} />
                        );
                      }

                      return (
                        <td key={`${row.model.id}-${value}-${i}`}>
                          {formatCellData(value, headerName, widget, {
                            assignUnderwriter: F.constVoid,
                            dealId: row.model.id,
                            history,
                            row,
                            userOptions: [],
                          })}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {isFetching && (
              <EmptyTableRow
                message={<Skeleton visible />}
                colSpan={AssignedFactorOrder.length + 1}
              />
            )}
            {!isFetching &&
              isFetched &&
              isWidgetDataJobResponse(tableData) &&
              A.isEmptyArray(tableData.root.children) && (
                <EmptyTableRow
                  message="No Properties Found"
                  colSpan={AssignedFactorOrder.length + 1}
                />
              )}
          </tbody>
        </EnderTable>
      </div>
      {tableData.totalLeaves > RESULTS_PER_PAGE && (
        <EnderPagination
          page={page}
          total={Math.ceil(tableData.totalLeaves / RESULTS_PER_PAGE)}
          onChange={handleChangePage}
        />
      )}
      {P.isNotNullable(widget) && (
        <AssignedUnderwritingFiltersRail
          widget={widget}
          onFilterChanged={handleFiltersChanged}
          displayRightRail={displayRightRail}
          close={() => setDisplayRightRail(false)}
        />
      )}
      <UnderwritingBulkReject
        opened={showBulkReject}
        onClose={handleCloseBulkRejection}
        selectedDeals={Object.values(filteredSelectedProperties)}
      />
    </Stack>
  );
}

export {
  ASSIGNED_UNDERWRITINGS,
  AssignedFactorOrder,
  AssignedUnderwritingQueue,
};
