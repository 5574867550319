import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const FactorOutputTypeEnumValues = [
  "BOOLEAN",
  "DATE",
  "ENUM",
  "MONEY",
  "NUMBER",
  "STRING",
] as const;
const FactorOutputTypeSchema = z.enum(FactorOutputTypeEnumValues);
type FactorOutputType = z.infer<typeof FactorOutputTypeSchema>;

const FactorOutputTypeEnum = castEnum<FactorOutputType>(FactorOutputTypeSchema);

export {
  FactorOutputTypeEnum,
  FactorOutputTypeEnumValues,
  FactorOutputTypeSchema,
};
export type { FactorOutputType };
