/**
 * HTTP Method: POST
 * Pathname: /accountingPeriods/getDefaultAccountingPeriod
 * @function getDefaultAccountingPeriod
 * @memberof AccountingAPI
 * @param {AccountingAPIGetDefaultAccountingPeriodPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AccountingPeriod>}
 */
import { Effect, Function as F } from "effect";

import type { LocalDate, SerializesInto } from "@ender/shared/core";
import type {
  AccountingPeriod,
  AccountingPeriodAccountingModule,
} from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPIGetDefaultAccountingPeriodSearchParams = {
  token?: string | undefined;
};

type AccountingAPIGetDefaultAccountingPeriodBodyParams = {
  accountingModule: AccountingPeriodAccountingModule;
  transactionDate: SerializesInto<LocalDate>;
};

type AccountingAPIGetDefaultAccountingPeriodPayload =
  AccountingAPIGetDefaultAccountingPeriodSearchParams &
    AccountingAPIGetDefaultAccountingPeriodBodyParams;

function getDefaultAccountingPeriodUnsafeEffect(
  payload: AccountingAPIGetDefaultAccountingPeriodPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AccountingPeriod>({
        body,
        decode: unsafeDecodeJsonResponse<AccountingPeriod>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accountingPeriods/getDefaultAccountingPeriod",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getDefaultAccountingPeriod(
  payload: AccountingAPIGetDefaultAccountingPeriodPayload,
  options?: { signal?: AbortSignal },
): Promise<AccountingPeriod> {
  return F.pipe(
    payload,
    getDefaultAccountingPeriodUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDefaultAccountingPeriod };
export type {
  AccountingAPIGetDefaultAccountingPeriodBodyParams,
  AccountingAPIGetDefaultAccountingPeriodPayload,
  AccountingAPIGetDefaultAccountingPeriodSearchParams,
};
