/**
 * This is a special way of adding a lease credit (an Invoice[LEASE -> FIRM] causing the tenant to owe less money),
 such that the credit is for the same amount as the charge and is allocated to the charge.
 
 If any payments had been allocated to the charge, their allocations are removed from it. However the "allocation
 buckets" remain the same (it is exactly the same behavior as if the charge had been removed, as far as these
 payment allocations are concerned).
 
 There is no support for period selection. The period of the reversal date is used to create the credit accounting.
 Moreover, if the period is closed for any of the payments which had been allocated to the charge, this API will
 throw an error since we cannot modify the accounting for the payments which is in a closed period.
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/reverseCharge
 * @function reverseTenantCharge
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPIReverseTenantChargePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Invoice>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { Invoice } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPIReverseTenantChargePathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPIReverseTenantChargeSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPIReverseTenantChargeBodyParams = {
  chargeId: EnderId;
  reversalDate: SerializesInto<LocalDate>;
  reversalReason?: string | undefined;
};

type TenantLedgerAPIReverseTenantChargePayload =
  TenantLedgerAPIReverseTenantChargePathParams &
    TenantLedgerAPIReverseTenantChargeSearchParams &
    TenantLedgerAPIReverseTenantChargeBodyParams;

function reverseTenantChargeUnsafeEffect(
  payload: TenantLedgerAPIReverseTenantChargePayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Invoice>({
        body,
        decode: unsafeDecodeJsonResponse<Invoice>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/reverseCharge`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function reverseTenantCharge(
  payload: TenantLedgerAPIReverseTenantChargePayload,
  options?: { signal?: AbortSignal },
): Promise<Invoice> {
  return F.pipe(
    payload,
    reverseTenantChargeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { reverseTenantCharge };
export type {
  TenantLedgerAPIReverseTenantChargeBodyParams,
  TenantLedgerAPIReverseTenantChargePathParams,
  TenantLedgerAPIReverseTenantChargePayload,
  TenantLedgerAPIReverseTenantChargeSearchParams,
};
