import {
  FactorsAPI,
  ReportsAPI,
} from "@ender/shared/generated/ender.api.reports";
import type { ReportWidget } from "@ender/shared/generated/ender.model.reports";
import {
  DynamicTableOperatorsEnum,
  ModelTypeEnum,
} from "@ender/shared/types/ender-general";

import { widgetToUpdateRequest } from "../../widget-helpers";
import { Factor } from "./filter-fields/filter-types";

const DEFAULT_STATUSES = [
  { status: "LEAD" },
  { status: "OFFER", subStatus: "Need to Underwrite" },
];

async function getRequiredYFactors(
  widget: ReportWidget,
  yFactorOrder: string[],
) {
  const allFields = await FactorsAPI.searchFactors({
    builtInFactors: true,
    customFactors: true,
    inputTypes: [ModelTypeEnum.DEAL],
  });

  const newYFactors = await Promise.all(
    yFactorOrder.map(async (name) => {
      const existingYFactor = widget.yFactors.find(
        (factor) => factor.name === name,
      );
      const field = allFields.find(({ name: fieldName }) => name === fieldName);

      if (existingYFactor && existingYFactor.id) {
        return existingYFactor;
      } else if (existingYFactor) {
        const factor = await FactorsAPI.newFactor({
          factorType: field.factorType,
        });
        return factor;
      }

      if (!field) {
        return null;
      }

      if (!field.id) {
        const factor = await FactorsAPI.newFactor({
          factorType: field.factorType,
        });
        return factor;
      }

      return field;
    }),
  );

  return newYFactors.filter(Boolean);
}

function widgetIsInCorrectOrder(widget: ReportWidget, yFactorOrder: string[]) {
  return yFactorOrder.every((name, index) => {
    if (index > widget.yFactors.length) {
      return false;
    }

    return widget.yFactors[index]?.name === name;
  });
}

async function verifyUnderwritingQueueWidget(
  widget: ReportWidget,
  widgetName: string,
  yFactorOrder: string[],
) {
  // If everything is in the correct order
  if (widgetIsInCorrectOrder(widget, yFactorOrder)) {
    return widget;
  }

  const yFactors = await getRequiredYFactors(widget, yFactorOrder);

  const statusFactor = yFactors.find(({ name }) => name === Factor.STATUS);

  if (!widget.filters.some(({ factor }) => statusFactor?.id === factor.id)) {
    widget.filters.push({
      // @ts-expect-error type mismatching, missing props
      factor: { id: statusFactor.id },
      operator: DynamicTableOperatorsEnum.IN,
      values: [...DEFAULT_STATUSES],
    });
  }

  widget.yFactors = yFactors;

  const updateRequest = widgetToUpdateRequest(widget);

  return ReportsAPI.updateWidget({ widgetId: widget.id, ...updateRequest });
}

export { DEFAULT_STATUSES, verifyUnderwritingQueueWidget };
