import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Option as O } from "effect";
import { useEffect } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import type { EnderId } from "@ender/shared/core";
import { Money$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { Stack } from "@ender/shared/ds/stack";
import type { BankingAPIVerifyMicrodepositsPayload } from "@ender/shared/generated/ender.api.accounting";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type VerifyAccountModalProps = {
  mask: string;
  accountId: EnderId;
  onSuccess: () => void;
  closeModal: () => void;
};

const VerifyAccountFormSchema = Schema.Struct({
  amountA: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (v): v is O.Some<Money$.Money> => O.exists(v, Money$.isPositive),
      {
        message: () => "Amount is required",
      },
    ),
  ),
  amountB: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(
      (v): v is O.Some<Money$.Money> => O.exists(v, Money$.isPositive),
      {
        message: () => "Amount is required",
      },
    ),
  ),
});

type VerifyAccountFormType = Schema.Schema.Type<typeof VerifyAccountFormSchema>;

function VerifyAccountModal({
  mask,
  accountId,
  onSuccess,
  closeModal,
}: VerifyAccountModalProps) {
  const {
    mutate: verifyMicrodeposits,
    error,
    isLoading,
  } = useMutation({
    mutationFn: (payload: BankingAPIVerifyMicrodepositsPayload) =>
      BankingAPI.verifyMicrodeposits(payload).then(() => {
        showSuccessNotification({
          message: `The account ending in ${mask} has been verified`,
        });
        onSuccess();
        closeModal();
      }),
    mutationKey: ["BankingAPI.verifyMicrodeposits"],
  });

  const form = useEffectSchemaForm({
    defaultValues: {
      amountA: O.none(),
      amountB: O.none(),
    },
    schema: VerifyAccountFormSchema,
  });

  function onSubmit(values: VerifyAccountFormType) {
    try {
      verifyMicrodeposits({
        amountA: O.getOrThrow(values.amountA).toJSON(),
        amountB: O.getOrThrow(values.amountB).toJSON(),
        bankAccountId: accountId,
      });
    } catch (err) {
      fail(err);
    }
  }

  // Catch errors from the mutation
  useEffect(() => {
    if (!error) {
      return;
    }

    fail(error);
  }, [error]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        <p>
          After a few days, you will see two small deposits in your bank
          account. Enter those amounts below.
        </p>
        <FormMoneyInput name="amountA" form={form} label="Amount 1" />
        <FormMoneyInput name="amountB" form={form} label="Amount 2" />
        <Group justify={Justify.end}>
          <Button type="submit" loading={isLoading}>
            Verify Account
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { VerifyAccountModal };
