/**
 * HTTP Method: POST
 * Pathname: /bankAccounts/refreshPlaidTransactions
 * @function refreshAllPlaidTransactions
 * @memberof BankingAPI
 * @param {BankingAPIRefreshAllPlaidTransactionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BankingAPIRefreshAllPlaidTransactionsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BankingAPIRefreshAllPlaidTransactionsResponse } from "../banking-api-refresh-all-plaid-transactions-response";

type BankingAPIRefreshAllPlaidTransactionsSearchParams = {
  token?: string | undefined;
};

type BankingAPIRefreshAllPlaidTransactionsPayload =
  BankingAPIRefreshAllPlaidTransactionsSearchParams;

function refreshAllPlaidTransactionsUnsafeEffect(
  payload: BankingAPIRefreshAllPlaidTransactionsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BankingAPIRefreshAllPlaidTransactionsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          BankingAPIRefreshAllPlaidTransactionsResponse[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/bankAccounts/refreshPlaidTransactions",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function refreshAllPlaidTransactions(
  payload: BankingAPIRefreshAllPlaidTransactionsPayload,
  options?: { signal?: AbortSignal },
): Promise<BankingAPIRefreshAllPlaidTransactionsResponse[]> {
  return F.pipe(
    payload,
    refreshAllPlaidTransactionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { refreshAllPlaidTransactions };
export type {
  BankingAPIRefreshAllPlaidTransactionsPayload,
  BankingAPIRefreshAllPlaidTransactionsSearchParams,
};
