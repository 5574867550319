/**
 * HTTP Method: POST
 * Pathname: /systemReports/Rent Roll/run
 * @function runRentRollReport
 * @memberof ReportsAPI
 * @param {ReportsAPIRunRentRollReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RentRollReportResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { RentRollReportResponse } from "@ender/shared/generated/ender.service.reports.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIRunRentRollReportSearchParams = {
  token?: string | undefined;
};

type ReportsAPIRunRentRollReportBodyParams = {
  asOfDate: SerializesInto<LocalDate>;
  filters: ReportFilterRequest[];
  firmIds: EnderId[];
  fundIds: EnderId[];
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  propertyIds: EnderId[];
};

type ReportsAPIRunRentRollReportPayload =
  ReportsAPIRunRentRollReportSearchParams &
    ReportsAPIRunRentRollReportBodyParams;

function runRentRollReportUnsafeEffect(
  payload: ReportsAPIRunRentRollReportPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RentRollReportResponse>({
        body,
        decode: unsafeDecodeJsonResponse<RentRollReportResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/systemReports/Rent Roll/run",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function runRentRollReport(
  payload: ReportsAPIRunRentRollReportPayload,
  options?: { signal?: AbortSignal },
): Promise<RentRollReportResponse> {
  return F.pipe(
    payload,
    runRentRollReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { runRentRollReport };
export type {
  ReportsAPIRunRentRollReportBodyParams,
  ReportsAPIRunRentRollReportPayload,
  ReportsAPIRunRentRollReportSearchParams,
};
