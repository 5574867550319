import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetMoveInTableRequestMoveInTabValues = [
  "IN_PROGRESS",
  "COMPLETE",
] as const;

const GetMoveInTableRequestMoveInTabEffectSchema = Schema.Literal(
  ...GetMoveInTableRequestMoveInTabValues,
);

type GetMoveInTableRequestMoveInTab = Schema.Schema.Type<
  typeof GetMoveInTableRequestMoveInTabEffectSchema
>;

const GetMoveInTableRequestMoveInTabEnum = castEnum(
  GetMoveInTableRequestMoveInTabEffectSchema,
);

function randomGetMoveInTableRequestMoveInTab(): GetMoveInTableRequestMoveInTab {
  return rand(GetMoveInTableRequestMoveInTabValues);
}

export {
  GetMoveInTableRequestMoveInTabEffectSchema,
  GetMoveInTableRequestMoveInTabEnum,
  GetMoveInTableRequestMoveInTabValues,
  randomGetMoveInTableRequestMoveInTab,
};
export type { GetMoveInTableRequestMoveInTab };
