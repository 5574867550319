// eslint-disable-next-line ender-rules/deprecated-import-libraries
import { ActionIcon, TextInput } from "@mantine/core";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { Function as F } from "effect";
import type { CSSProperties, ChangeEvent } from "react";
import { useState } from "react";

type ChangeEventType = ChangeEvent<HTMLInputElement>;

type SsnInputProps = {
  className?: string;
  disabled?: boolean;
  disableToggle?: boolean;
  error?: string;
  label?: string;
  name?: string;
  onChange?: (e: ChangeEventType) => void;
  required?: boolean;
  style?: CSSProperties;
  value?: string;
};

/**
 * @name SsnInput
 * @description Configured field for inputting SSN
 * @deprecated use shared/ds/ssn-input
 */
function SsnInput({
  disableToggle = false,
  disabled = false,
  label = "",
  name = "ssn",
  onChange = F.constVoid,
  required = false,
  ...props
}: SsnInputProps) {
  const [ssnValue, setSsnValue] = useState(props.value || "");
  const [visible, setVisible] = useState(!disableToggle);

  /**
   * @function handleChange
   * @description Filters the user input via regex and set the value, triggers external user onChange function
   * @param {any|Event} e The event which triggered the function
   */
  function handleChange(e: ChangeEventType) {
    const regEx = new RegExp(/[^\d]+/, "g");
    const cleanValue = e.target.value.replace(regEx, "");
    const formattedSsn = cleanValue
      .replace(/^(\d{3})(\d)/, "$1-$2") // 0000 -> 000-0
      .replace(/-(\d{2})(\d)/, "-$1-$2"); // 000-000 -> 000-00-0
    setSsnValue(formattedSsn);
    if (onChange) {
      onChange(e);
    }
  }

  const ToggleVisibilityIcon = (
    <ActionIcon onClick={() => setVisible(!visible)}>
      {visible ? <IconEyeOff /> : <IconEye />}
    </ActionIcon>
  );

  return (
    <TextInput
      label={label}
      inputMode="numeric"
      autoComplete="off"
      name={name}
      error={props.error}
      type={visible ? "text" : "password"}
      disabled={disabled}
      maxLength={11}
      placeholder="xxx-xx-xxxx"
      onChange={handleChange}
      value={ssnValue}
      required={required}
      pattern="[0-9]{3}(-){0,1}[0-9]{2}(-){0,1}[0-9]{4}"
      rightSection={!disableToggle && ToggleVisibilityIcon}
      data-private
      className={props.className}
      style={props.style}
    />
  );
}

export { SsnInput };
