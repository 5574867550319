import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const BuyOfferOfferStatusValues = [
  "PENDING",
  "ACCEPTED",
  "REJECTED",
  "RENEGOTIATING",
] as const;

const BuyOfferOfferStatusEffectSchema = Schema.Literal(
  ...BuyOfferOfferStatusValues,
);

type BuyOfferOfferStatus = Schema.Schema.Type<
  typeof BuyOfferOfferStatusEffectSchema
>;

const BuyOfferOfferStatusEnum = castEnum(BuyOfferOfferStatusEffectSchema);

function randomBuyOfferOfferStatus(): BuyOfferOfferStatus {
  return rand(BuyOfferOfferStatusValues);
}

export {
  BuyOfferOfferStatusEffectSchema,
  BuyOfferOfferStatusEnum,
  BuyOfferOfferStatusValues,
  randomBuyOfferOfferStatus,
};
export type { BuyOfferOfferStatus };
