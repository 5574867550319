import { Predicate as P } from "effect";
import type { ElementRef } from "react";
import { forwardRef, useId } from "react";

import { Money$ } from "@ender/shared/core";
import { Card } from "@ender/shared/ds/card";
import { Align, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H4 } from "@ender/shared/ds/heading";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import type { GetApplyInfoResponseUnitResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { LeasingServiceListingResponse } from "@ender/shared/generated/ender.service.leasing";

type ApplicationListingInfoCardProps = {
  listing?: Partial<
    Pick<
      LeasingServiceListingResponse,
      | "sqft"
      | "bedrooms"
      | "fullBaths"
      | "halfBaths"
      | "advertisedRent"
      | "marketName"
      | "numApplications"
    > & {
      property: Partial<
        Pick<LeasingServiceListingResponse["property"], "name">
      >;
    }
  >;
  unit?: Pick<GetApplyInfoResponseUnitResponse, "name" | "address">;
  loading?: boolean;
};

const ApplicationListingInfoCard = forwardRef<
  ElementRef<typeof Card>,
  ApplicationListingInfoCardProps
>(function ApplicationListingInfoCard(props, ref) {
  const { listing, unit, loading } = props;
  const {
    sqft,
    bedrooms,
    fullBaths,
    halfBaths,
    advertisedRent,
    marketName,
    numApplications,
  } = listing ?? {};
  const labelId = useId();
  return (
    <Card ref={ref} labelledBy={labelId}>
      <Stack>
        <H4 id={labelId}>Property Info</H4>
        <Grid spacingY={Spacing.none} underline="uneven">
          <Tuple
            label="Property"
            value={
              <Skeleton visible={loading}>
                {unit?.address?.fullDisplay ?? "--"}
              </Skeleton>
            }
          />
          <Tuple
            label="Unit"
            value={<Skeleton visible={loading}>{unit?.name || "--"}</Skeleton>}
          />
          <Tuple
            label="Sqft"
            value={<Skeleton visible={loading}>{sqft ?? "--"}</Skeleton>}
          />
          <Tuple
            label="Beds"
            value={<Skeleton visible={loading}>{bedrooms ?? "--"}</Skeleton>}
          />
          <Tuple
            label="Baths"
            value={
              <Skeleton visible={loading}>
                {(fullBaths ?? 0) + (halfBaths ?? 0)}
              </Skeleton>
            }
          />
          <Tuple
            label="Market"
            value={<Skeleton visible={loading}>{marketName ?? "--"}</Skeleton>}
          />
        </Grid>
        <H4>Listing Info</H4>
        <Grid spacingY={Spacing.none} underline="uneven">
          <Tuple
            label="Rent"
            value={
              <Skeleton visible={loading}>
                <MoneyDisplay value={Money$.parse(advertisedRent)} showSymbol />
              </Skeleton>
            }
          />
          <Tuple
            label="# of other Applications"
            value={
              <Skeleton visible={loading}>
                <Group spacing={Spacing.sm} align={Align.center}>
                  <span>
                    {P.isNotNullable(numApplications)
                      ? Math.max(numApplications - 1, 0)
                      : "--"}
                  </span>
                  {/* <ActionIcon size={Size.md} variant={ButtonVariant.transparent}>
                    <IconExternalLink />
                  </ActionIcon> */}
                </Group>
              </Skeleton>
            }
          />
        </Grid>
      </Stack>
    </Card>
  );
});

export { ApplicationListingInfoCard };

export type { ApplicationListingInfoCardProps };
