/**
 * HTTP Method: POST
 * Pathname: /collections/processes/{processId}/delete
 * @function deleteCollectionsProcess
 * @memberof CollectionsAPI
 * @param {CollectionsAPIDeleteCollectionsProcessPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CollectionsAPIDeleteCollectionsProcessPathParams = {
  processId: EnderId;
};

type CollectionsAPIDeleteCollectionsProcessSearchParams = {
  token?: string | undefined;
};

type CollectionsAPIDeleteCollectionsProcessPayload =
  CollectionsAPIDeleteCollectionsProcessPathParams &
    CollectionsAPIDeleteCollectionsProcessSearchParams;

function deleteCollectionsProcessUnsafeEffect(
  payload: CollectionsAPIDeleteCollectionsProcessPayload,
) {
  const { processId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/collections/processes/${processId}/delete`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteCollectionsProcess(
  payload: CollectionsAPIDeleteCollectionsProcessPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteCollectionsProcessUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteCollectionsProcess };
export type {
  CollectionsAPIDeleteCollectionsProcessPathParams,
  CollectionsAPIDeleteCollectionsProcessPayload,
  CollectionsAPIDeleteCollectionsProcessSearchParams,
};
