import { Option as O } from "effect";
import { useWatch } from "react-hook-form";

import type { UseFormReturn } from "@ender/form-system/base";
import { Tuple } from "@ender/shared/ds/tuple";

import type { ApplicantRentalHistoryFormInput } from "./edit-applicant-rental-history-fields";

type DisplayableApplicantRentalHistoryProps = {
  form: UseFormReturn<ApplicantRentalHistoryFormInput>;
};

function DisplayableApplicantRentalHistory(
  props: DisplayableApplicantRentalHistoryProps,
) {
  const { form } = props;
  const [watchedFirstTimeRenter, landlordEmail, landlordName, landlordPhone] =
    useWatch({
      control: form.control,
      name: [
        "firstTimeRenter",
        "landlordEmail",
        "landlordName",
        "landlordPhone",
      ],
    });
  const firstTimeRenter = watchedFirstTimeRenter.pipe(O.getOrUndefined);
  return (
    <>
      <Tuple label="First Time Renter" value={firstTimeRenter ? "Yes" : "No"} />
      {firstTimeRenter || (
        <>
          <Tuple label="Previous Landlord" value={landlordName} />
          <Tuple label="Landlord Email" value={landlordEmail} />
          <Tuple label="Landlord Phone" value={landlordPhone} />
        </>
      )}
    </>
  );
}

export { DisplayableApplicantRentalHistory };
