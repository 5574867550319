import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ender/shared/ds/accordion";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";

import { AuditHistoryTable } from "./audit-history-table";
import type { TimelineStep } from "./types";

type AuditHistoryProps = {
  timeline?: TimelineStep[];
  onlyShowTable?: boolean;
};

/**
 * AuditHistory is the V2 version of the "Approval Timeline" component.
 */
function AuditHistory({ timeline, onlyShowTable = false }: AuditHistoryProps) {
  if (onlyShowTable) {
    return (
      <Stack>
        <AuditHistoryTable timeline={timeline} />
      </Stack>
    );
  }
  return (
    <Accordion>
      <AccordionItem value="first">
        <AccordionTrigger>
          <H3>Audit History</H3>
        </AccordionTrigger>
        <AccordionContent>
          <AuditHistoryTable timeline={timeline} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export { AuditHistory };
