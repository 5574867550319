/**
 * HTTP Method: POST
 * Pathname: /checks/{moneyTransferId}/print
 * @function printCheck
 * @memberof PaymentsAPI
 * @param {PaymentsAPIPrintCheckPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PrintCheckResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PrintCheckResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIPrintCheckPathParams = {
  moneyTransferId: EnderId;
};

type PaymentsAPIPrintCheckSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIPrintCheckPayload = PaymentsAPIPrintCheckPathParams &
  PaymentsAPIPrintCheckSearchParams;

function printCheckUnsafeEffect(payload: PaymentsAPIPrintCheckPayload) {
  const { moneyTransferId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PrintCheckResponse>({
        body,
        decode: unsafeDecodeJsonResponse<PrintCheckResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/checks/${moneyTransferId}/print`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function printCheck(
  payload: PaymentsAPIPrintCheckPayload,
  options?: { signal?: AbortSignal },
): Promise<PrintCheckResponse> {
  return F.pipe(
    payload,
    printCheckUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { printCheck };
export type {
  PaymentsAPIPrintCheckPathParams,
  PaymentsAPIPrintCheckPayload,
  PaymentsAPIPrintCheckSearchParams,
};
