import { useQuery } from "@tanstack/react-query";
import * as A from "effect/Array";
import * as O from "effect/Option";
import * as P from "effect/Predicate";
import * as R from "effect/Record";
import * as S from "effect/String";
import { useState } from "react";

import { MultiFactorVerificationModal } from "@ender/features/auth";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { Modal } from "@ender/shared/ds/modal";
import { InvoicesMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import { MoneyTransferTransferTypeEnum } from "@ender/shared/generated/ender.model.payments";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { fail } from "@ender/shared/utils/error";
import { withWarningHandler } from "@ender/shared/utils/rest";
import { Color } from "@ender/shared/utils/theming";

import { GenerateCheckForm } from "./generate-check-form";
import type { GenerateCheckFormSchemaOutput } from "./generate-check-form.schema";
import { openCheck } from "./open-check";
import type { GenerateCheckFormValues } from "./types";
import { getRecipientName } from "./utils";

type PrintCheckButtonProps = {
  bankAccounts: BankAccount[];
  enabled?: boolean;
  invoice: InvoiceSerializerInvoiceResponse;
  onPaymentSuccess: () => void;
  tooltip?: string;
};

function PrintCheckButton({
  bankAccounts,
  enabled = true,
  invoice,
  onPaymentSuccess,
  tooltip,
}: PrintCheckButtonProps) {
  // State for showing the form modal
  const [isShowingForm, setIsShowingForm] = useState(false);

  // New state from merged GenerateCheckModal logic
  const [isProcessing, setIsProcessing] = useState(false);
  const [values, setValues] = useState<O.Option<GenerateCheckFormValues>>(
    O.none(),
  );
  const confirmation = useConfirmationContext();

  const { data: forwardingAddressMemo } = useQuery({
    queryFn: () =>
      InvoicesMiddleLayerAPI.getDefaultCheckMemo({
        invoiceId: invoice.id,
      }),
    queryKey: [
      "InvoicesMiddleLayerAPI.getDefaultCheckMemo",
      invoice.id,
    ] as const,
    select: (data) => {
      return `${data.invoiceNumber}: ${data.invoiceDescription} - ${data.forwardingAddress.split(",")[0]}`;
    },
  });

  async function getMoneyTransferIdForBatch(
    invoiceId: EnderId,
  ): Promise<EnderId> {
    const { paymentInfo } = await InvoicesAPI.getInvoice({ invoiceId });
    return paymentInfo?.id as EnderId;
  }

  async function markPaidAndPrintCheck(
    formValues: GenerateCheckFormValues,
    invoice: InvoiceSerializerInvoiceResponse,
    onPaymentSuccess: () => void,
  ) {
    const { bankAccountId, checkDate, checkNumber, memo, amount, periodId } =
      formValues;
    const date = checkDate.toJSON();
    const markPaidBatchWithWarnings = withWarningHandler(
      InvoicesAPI.markPaidBatch,
      (warnings) =>
        confirmation(
          {
            content: warnings.join("\n"),
            title: "Warning!",
          },
          { confirmButtonProps: { color: Color.red } },
        ),
    );
    try {
      await markPaidBatchWithWarnings({
        transferDetails: [
          {
            amount,
            bankAccountId,
            checkDate: date,
            checkNumber,
            invoiceIds: [invoice.id],
            ledgerDate: date,
            memo,
            periodId,
            transferType: MoneyTransferTransferTypeEnum.PRINT_CHECK,
          },
        ],
      });
      const moneyTransferId = await getMoneyTransferIdForBatch(invoice.id);
      openCheck(moneyTransferId);
      onPaymentSuccess();
    } catch (err) {
      fail(err);
    }
  }

  function handlePrintCheck(formValues: GenerateCheckFormSchemaOutput) {
    if (isProcessing) {
      return;
    }
    const {
      amount,
      bankAccountId,
      checkDate,
      memo,
      periodId,
      recipientName,
      senderName,
    } = formValues;
    setIsProcessing(true);
    const finalValues = {
      ...formValues,
      amount: O.getOrUndefined(amount)?.toJSON(),
      bankAccountId: O.getOrUndefined(bankAccountId),
      checkDate: O.getOrThrow(checkDate),
      memo: S.isNonEmpty(memo) ? memo : UNDEFINED,
      periodId: O.getOrUndefined(periodId)?.id,
      recipientName: S.isNonEmpty(recipientName) ? recipientName : UNDEFINED,
      senderName: S.isNonEmpty(senderName) ? senderName : UNDEFINED,
    };
    setValues(O.some(finalValues));
  }
  const exemplaryPropertyId =
    invoice.currentState.allocationsByProperty[0].propertyId;

  const { data: exemplaryProperty, isLoading: isLoadingPropertyDetails } =
    useQuery({
      queryFn: () =>
        PropertiesAPI.getProperty({ propertyId: exemplaryPropertyId }),
      queryKey: [
        "PropertiesAPI.getPropertyDetails",
        { propertyId: exemplaryPropertyId },
      ],
    });

  const exemplaryRecipientName = getRecipientName(invoice);
  const exemplarySenderName: string =
    invoice.owedByParty?.companyName ||
    invoice.owedByParty?.name ||
    exemplaryProperty?.name ||
    "";

  const { data: bankAccountIdData, isLoading: isLoadingBankAccountIdDetails } =
    useQuery({
      queryFn: () =>
        PropertiesAPI.getOperatingAccountIdByPropertyId({
          invoiceType: InvoiceInvoiceTypeEnum.PAYABLE,
          propertyIds: [exemplaryPropertyId],
        }),
      queryKey: [
        "PropertiesAPI.getOperatingAccountIdByPropertyId",
        exemplaryPropertyId,
      ],
    });

  const operatingAccountId =
    P.isNotNullable(bankAccountIdData) && !R.isEmptyRecord(bankAccountIdData)
      ? O.fromNullable(bankAccountIdData[exemplaryPropertyId])
      : O.none();

  if (A.isEmptyArray(bankAccounts)) {
    return NULL;
  }

  if (isLoadingBankAccountIdDetails || isLoadingPropertyDetails) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Button
        variant={ButtonVariant.outlined}
        disabledTooltip={tooltip}
        onClick={() => setIsShowingForm(true)}
        disabled={!enabled}>
        Print Check
      </Button>
      <Modal
        opened={isShowingForm}
        onClose={() => setIsShowingForm(false)}
        title="Mark as Paid & Generate Check">
        <GenerateCheckForm
          amount={invoice.amount}
          bankAccounts={bankAccounts}
          handlePrintCheck={handlePrintCheck}
          isProcessing={isProcessing}
          memo={forwardingAddressMemo ?? ""}
          operatingAccountId={operatingAccountId}
          recipientName={exemplaryRecipientName}
          senderName={exemplarySenderName}
        />
        {O.match(values, {
          onNone: () => null,
          onSome: (vals) => (
            <MultiFactorVerificationModal
              onClose={() => {
                setIsProcessing(false);
                setValues(O.none());
              }}
              onDone={async () => {
                await markPaidAndPrintCheck(vals, invoice, onPaymentSuccess);
                setIsProcessing(false);
                setValues(O.none());
                setIsShowingForm(false);
              }}
            />
          ),
        })}
      </Modal>
    </>
  );
}

export { PrintCheckButton };
