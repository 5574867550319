import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Option as O } from "effect";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import type { EnderId } from "@ender/shared/core";
import { Percent$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormPercentInput } from "@ender/shared/ds/percent-input";
import { Stack } from "@ender/shared/ds/stack";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { DealChannel } from "@ender/shared/generated/com.ender.buy.model.misc";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

const EditAcquisitionFeesFormSchema = Schema.Struct({
  acquisitionFee: Schema.instanceOf(Percent$).pipe(Schema.OptionFromSelf),
});

type EditAcquisitionFeesFormValues = Schema.Schema.Type<
  typeof EditAcquisitionFeesFormSchema
>;

type EditAcquisitionFeesFormProps = {
  firmId: EnderId;
  channel: DealChannel;
  value: string;
  refetchAcquisitionFees: () => void;
  onClose: () => void;
};

function EditAcquisitionFeesForm({
  firmId,
  channel,
  value,
  refetchAcquisitionFees,
  onClose,
}: EditAcquisitionFeesFormProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      acquisitionFee: Percent$.parse(value),
    },
    schema: EditAcquisitionFeesFormSchema,
  });

  const { mutateAsync: setAcquisitionFee } = useMutation({
    mutationFn: async ({ acquisitionFee }: EditAcquisitionFeesFormValues) => {
      await BuyAPI.setAcquisitionFee({
        acquisitionFee: acquisitionFee.pipe(
          O.map((val) => val.toJSON()),
          O.getOrElse(() => Percent$.ZERO.toJSON()),
        ),
        channel,
        firmId,
      });
    },
    mutationKey: ["BuyAPI.setAcquisitionFee", { channel, firmId }],
  });

  async function onSubmit(values: EditAcquisitionFeesFormValues) {
    await setAcquisitionFee(values);
    refetchAcquisitionFees();
    showSuccessNotification({
      message: "Acquisition Fee saved.",
    });
    onClose();
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack>
        <FormPercentInput label="Value" form={form} name="acquisitionFee" />
        <Group justify={Justify.end}>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditAcquisitionFeesForm };
