import { IconAlertCircle } from "@tabler/icons-react";
import type { CellContext, Row } from "@tanstack/react-table";
import { Predicate as P, String as S } from "effect";
import type React from "react";

import { LocalDate$, Money$ } from "@ender/shared/core";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { MoneyDisplay } from "@ender/shared/ds/money-display";
import { RouterLink } from "@ender/shared/ds/router-link";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Ellipsis } from "@ender/shared/ui/ellipsis";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { formatLongSlashDateAndDaysFromToday } from "@ender/shared/utils/local-date";

import type { InvoiceRow, InvoicesTableMeta } from "../invoices-table-types";

import styles from "./invoices-table-columns.module.css";

const cellFormat = {
  amount: (props: CellContext<InvoiceRow, string | number>) => {
    const { amount } = props.row.original;
    return <MoneyDisplay value={Money$.parse(amount)} />;
  },
  ellipsis: (props: CellContext<InvoiceRow, string | number>) => {
    return <Ellipsis>{props.getValue()}</Ellipsis>;
  },
  invoiceNumber: (props: CellContext<InvoiceRow, string | number>) => {
    const { externalInvoiceId, id } = props.row.original;
    const { invoiceType } = props.table.options.meta as InvoicesTableMeta;
    return (
      <RouterLink
        href={`/invoices/accounts-${invoiceType.toLowerCase()}/${id}`}>
        <Ellipsis>{externalInvoiceId ? externalInvoiceId : "view"}</Ellipsis>
      </RouterLink>
    );
  },
};

function sortByCurrency(
  rowA: Row<InvoiceRow>,
  rowB: Row<InvoiceRow>,
  columnId: string,
) {
  const a = Money$.of(rowA.getValue(columnId));
  const b = Money$.of(rowB.getValue(columnId));

  return Money$.Order(a, b);
}

type AccountingPeriodCellValueProps = {
  tooltipLabel?: string;
  value: React.ReactNode;
};

function AccountingPeriodCellValue({
  tooltipLabel,
  value,
}: AccountingPeriodCellValueProps): React.ReactNode {
  if (
    (S.isString(tooltipLabel) && S.isEmpty(tooltipLabel)) ||
    P.isUndefined(tooltipLabel)
  ) {
    return value;
  }

  return (
    <Tooltip label={tooltipLabel} disabled={P.isUndefined(tooltipLabel)}>
      <Group align={Align.center} spacing="xs" noWrap>
        <Text color="red-500" size={FontSize.sm}>
          {value}
        </Text>
        <Text color="red-500">
          <IconAlertCircle size={12} />
        </Text>
      </Group>
    </Tooltip>
  );
}

const columns: ColumnDef<InvoiceRow>[] = [
  {
    accessorKey: "externalInvoiceId",
    cell: cellFormat.invoiceNumber,
    className: styles.invoiceNumber,
    header: "Invoice No",
    size: 150,
  },
  {
    accessorKey: "counterPartyName",
    cell: cellFormat.ellipsis,
    className: styles.invoiceNumber,
    header: (props) => {
      const { counterPartyNameHeader } = props.table.options
        .meta as InvoicesTableMeta;
      return counterPartyNameHeader;
    },
  },
  {
    accessorKey: "date",
    cell: (props) => <DateDisplay value={LocalDate$.of(props.getValue())} />,
    header: "Invoice Date",
    size: 110,
  },
  {
    accessorKey: "dueDate",
    cell: (props) => formatLongSlashDateAndDaysFromToday(props.getValue()),
    header: "Due Date",
    size: 160,
  },
  {
    accessorKey: "periodDisplay",
    cell: (props) => {
      return (
        <AccountingPeriodCellValue
          value={props.getValue()}
          tooltipLabel={props.row.original.closedPeriodMessage}
        />
      );
    },
    enableSorting: false,
    header: "Period",
    size: 110,
  },
  {
    accessorKey: "paidDate",
    cell: (props) => {
      if (props.getValue()) {
        return <DateDisplay value={LocalDate$.of(props.getValue())} />;
      }
      return <Text align="center">-</Text>;
    },
    header: "Paid Date",
    size: 110,
  },
  {
    accessorKey: "statusDisplay",
    cell: cellFormat.ellipsis,
    enableSorting: false,
    header: "Status",
    maxSize: 250,
    minSize: 120,
  },
  {
    accessorKey: "propertyDisplay",
    cell: cellFormat.ellipsis,
    header: "Property",
  },
  {
    accessorKey: "firmName",
    cell: cellFormat.ellipsis,
    header: "Firm",
  },
  {
    accessorKey: "paymentMethodDisplay",
    cell: cellFormat.ellipsis,
    header: "Payment",
    maxSize: 180,
    minSize: 120,
  },
  {
    accessorKey: "amount",
    cell: cellFormat.amount,
    className: styles.amountColumn,
    header: "Amount",
    maxSize: 200,
    minSize: 120,
    sortingFn: sortByCurrency,
  },
];

export { columns };
