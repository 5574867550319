/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/prospect/{userId}/sendApplicationUrl
 * @function sendApplicationUrl
 * @memberof LeasingAPI
 * @param {LeasingAPISendApplicationUrlPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISendApplicationUrlPathParams = {
  unitId: EnderId;
  userId: EnderId;
};

type LeasingAPISendApplicationUrlSearchParams = {
  token?: string | undefined;
};

type LeasingAPISendApplicationUrlPayload =
  LeasingAPISendApplicationUrlPathParams &
    LeasingAPISendApplicationUrlSearchParams;

function sendApplicationUrlUnsafeEffect(
  payload: LeasingAPISendApplicationUrlPayload,
) {
  const { unitId, userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/prospect/${userId}/sendApplicationUrl`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendApplicationUrl(
  payload: LeasingAPISendApplicationUrlPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendApplicationUrlUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendApplicationUrl };
export type {
  LeasingAPISendApplicationUrlPathParams,
  LeasingAPISendApplicationUrlPayload,
  LeasingAPISendApplicationUrlSearchParams,
};
