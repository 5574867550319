/**
 * HTTP Method: POST
 * Pathname: /applications/{applicationGroupId}/people/{applicationId}/approval
 * @function applicationApproval
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIApplicationApprovalPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ApplicationsAPIApplicationApprovalType } from "../applications-api-application-approval-type";

type ApplicationsAPIApplicationApprovalPathParams = {
  applicationGroupId: EnderId;
  applicationId: EnderId;
};

type ApplicationsAPIApplicationApprovalSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIApplicationApprovalBodyParams = {
  approvalStatus: ApprovableApprovalStatus;
  approvalType: ApplicationsAPIApplicationApprovalType;
};

type ApplicationsAPIApplicationApprovalPayload =
  ApplicationsAPIApplicationApprovalPathParams &
    ApplicationsAPIApplicationApprovalSearchParams &
    ApplicationsAPIApplicationApprovalBodyParams;

function applicationApprovalUnsafeEffect(
  payload: ApplicationsAPIApplicationApprovalPayload,
) {
  const { applicationGroupId, applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${applicationGroupId}/people/${applicationId}/approval`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function applicationApproval(
  payload: ApplicationsAPIApplicationApprovalPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    applicationApprovalUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { applicationApproval };
export type {
  ApplicationsAPIApplicationApprovalBodyParams,
  ApplicationsAPIApplicationApprovalPathParams,
  ApplicationsAPIApplicationApprovalPayload,
  ApplicationsAPIApplicationApprovalSearchParams,
};
