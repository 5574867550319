/**
 * HTTP Method: POST
 * Pathname: /factorGroups/unlock
 * @function unlockFactorGroup
 * @memberof FactorsAPI
 * @param {FactorsAPIUnlockFactorGroupPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIUnlockFactorGroupSearchParams = {
  token?: string | undefined;
};

type FactorsAPIUnlockFactorGroupBodyParams = {
  factorGroup: string;
  modelId: EnderId;
  modelType: ModelType;
};

type FactorsAPIUnlockFactorGroupPayload =
  FactorsAPIUnlockFactorGroupSearchParams &
    FactorsAPIUnlockFactorGroupBodyParams;

function unlockFactorGroupUnsafeEffect(
  payload: FactorsAPIUnlockFactorGroupPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/factorGroups/unlock",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function unlockFactorGroup(
  payload: FactorsAPIUnlockFactorGroupPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    unlockFactorGroupUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { unlockFactorGroup };
export type {
  FactorsAPIUnlockFactorGroupBodyParams,
  FactorsAPIUnlockFactorGroupPayload,
  FactorsAPIUnlockFactorGroupSearchParams,
};
