import { useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Option as O,
  Predicate as P,
  Record as R,
  pipe,
} from "effect";
import { useMemo } from "react";

import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { LeasingMiddleLayerAPI } from "@ender/shared/generated/com.ender.middle";
import type { Month } from "@ender/shared/generated/java.time";
import { MonthValues } from "@ender/shared/generated/java.time";

function useLeaseUpcomingCharges({
  leaseId,
  installments,
  balanceDue,
  startDate,
  paymentPlanStartDate,
}: {
  leaseId: EnderId;
  installments: readonly {
    amount: O.Option<Money$.Money>;
    dueDate: O.Option<LocalDate$.LocalDate>;
  }[];
  balanceDue: Money$.Money;
  startDate: O.Option<LocalDate$.LocalDate>;
  paymentPlanStartDate: O.Option<LocalDate$.LocalDate>;
}) {
  const { data } = useQuery({
    enabled: P.isNotNullable(leaseId),
    queryFn: ({ queryKey: [, params], signal }) =>
      LeasingMiddleLayerAPI.getUpcomingCharges(params, { signal }),
    queryKey: [
      "LeasingMiddleLayerAPI.getUpcomingCharges",
      { leaseId },
    ] as const,
  });

  const maxEndDate = useMemo(
    () =>
      pipe(
        installments,
        A.map(({ dueDate }) => dueDate),
        A.match({
          onEmpty: () => O.none<LocalDate$.LocalDate>(),
          onNonEmpty: A.max(O.getOrder(LocalDate$.Order)),
        }),
      ),
    [installments],
  );

  const relevantCharges = useMemo(() => {
    if (P.isNullable(data) || O.isNone(maxEndDate)) {
      return {} as Record<Month, Money$.Money>;
    }

    const startDateValue = pipe(
      startDate,
      O.orElse(() => paymentPlanStartDate),
      O.getOrUndefined,
    );
    const maxEndDateValue = O.getOrThrow(maxEndDate);

    const comprehensiveCharges: Record<Month, Money$.Money> = pipe(
      MonthValues,
      R.fromIterableWith((month) => [
        month,
        Money$.of(data.upcomingCharges[month]),
      ]),
    );

    return pipe(
      comprehensiveCharges,
      R.filter((amount: Money$.Money, month: Month): amount is Money$.Money => {
        const monthIndex = MonthValues.indexOf(month) + 1;
        return (
          P.isNotUndefined(startDateValue) &&
          monthIndex >= LocalDate$.month(startDateValue) &&
          monthIndex <= LocalDate$.month(maxEndDateValue) &&
          !Money$.isZero(amount)
        );
      }),
    );
  }, [data, startDate, paymentPlanStartDate, maxEndDate]);

  const totalExpectedAmount = useMemo(() => {
    const upcomingTotal = pipe(
      relevantCharges as Record<Month, Money$.Money>,
      R.reduce(Money$.zero(), Money$.add),
    );

    return pipe(balanceDue, Money$.add(upcomingTotal));
  }, [balanceDue, relevantCharges]);

  return {
    maxEndDate,
    relevantCharges,
    totalExpectedAmount,
  };
}

export { useLeaseUpcomingCharges };
