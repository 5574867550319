import { Array as A } from "effect";
import { useContext, useMemo } from "react";

import { UserContext } from "@ender/shared/contexts/user";
import { Badge } from "@ender/shared/ds/badge";
import { Card } from "@ender/shared/ds/card";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { Tuple } from "@ender/shared/ds/tuple";
import type {
  LeaseSerializerLeaseContact,
  LeaseSerializerLeaseResponse,
} from "@ender/shared/generated/ender.arch.serializer.leasing";
import { LeaseUserRoleLeaseUserFlagEnum } from "@ender/shared/generated/ender.model.leasing";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import { EmailDisplay, PhoneDisplay } from "@ender/shared/ui/contact-card";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { Color, Size } from "@ender/shared/utils/theming";
import { renderPrivateContact } from "@ender/shared/utils/user";

import { LeaseContactMenu } from "./lease-contact-menu";
import { ViewSSNButton } from "./view-ssn/view-ssn-button";

type LeaseContactCardProps = {
  lease: LeaseSerializerLeaseResponse;
  contact: LeaseSerializerLeaseContact;
  onEditContactClick: (contact: LeaseSerializerLeaseContact) => void;
  refetchLeaseContacts: () => Promise<void>;
};

function LeaseContactCard({
  lease,
  contact,
  onEditContactClick,
  refetchLeaseContacts,
}: LeaseContactCardProps) {
  const { permissions, userPM } = useContext(UserContext);
  const hasViewSSNPermission =
    permissions[FunctionalPermissionEnum.VIEW_SOCIAL_SECURITY_NUMBER];

  const { contactStatusText, isOnboarded } = useMemo(() => {
    const _isOnboarded =
      contact.isTermsAccepted && contact.emailVerifiedTimestamp;
    let _contactStatusText;
    if (contact.isTermsAccepted && contact.emailVerifiedTimestamp) {
      const lastActiveDateString = EnderDate.of(
        contact.lastActive?.time,
      ).toLongSlashDateString();
      _contactStatusText = `Last Active: ${lastActiveDateString}`;
    } else {
      _contactStatusText = "Not Onboarded";
    }

    return {
      contactStatusText: _contactStatusText,
      isOnboarded: _isOnboarded,
    };
  }, [contact]);

  return (
    <Card>
      <Stack spacing={Spacing.md}>
        <Group justify={Justify.between}>
          <Group align={Align.center}>
            <H3>{renderPrivateContact(contact)}</H3>
            <Text
              size={FontSize.sm}
              color={isOnboarded ? "slate-900" : "red-500"}>
              {contactStatusText}
            </Text>
          </Group>
          <LeaseContactMenu
            refetchContacts={refetchLeaseContacts}
            contact={contact}
            onEditContactClick={onEditContactClick}
            lease={lease}
          />
        </Group>
        <Group spacing={Spacing.md}>
          {contact.roles.map((role) => (
            <Badge key={role} color={Color.slate} size={Size.md}>
              {role}
            </Badge>
          ))}
        </Group>
        <Grid>
          <Tuple
            label="Primary Phone"
            value={<PhoneDisplay value={contact.phone} />}
          />
          <Tuple label="Email" value={<EmailDisplay value={contact.email} />} />
          {hasViewSSNPermission &&
            A.contains(
              contact.roles,
              LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
            ) && (
              <Tuple
                label="SSN"
                value={<ViewSSNButton contact={contact} className="right" />}
              />
            )}
          {userPM.enableEsusuReporting &&
            A.contains(
              contact.roles,
              LeaseUserRoleLeaseUserFlagEnum.FINANCIALLY_RESPONSIBLE,
            ) && (
              <Tuple
                label="Esusu Eligibility Status"
                value={contact.esusuEnrolled ? "Opted In" : "Opted Out"}
              />
            )}
        </Grid>
      </Stack>
    </Card>
  );
}

export { LeaseContactCard };
