/**
 * HTTP Method: POST
 * Pathname: /markets/{marketId}/taxMethod
 * @function updateTaxMethod
 * @memberof MarketsAPI
 * @param {MarketsAPIUpdateTaxMethodPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import type { TaxMethodTaxMethodType } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type MarketsAPIUpdateTaxMethodPathParams = {
  marketId: EnderId;
};

type MarketsAPIUpdateTaxMethodSearchParams = {
  token?: string | undefined;
};

type MarketsAPIUpdateTaxMethodBodyParams = {
  offsetAmount: SerializesInto<Money>;
  offsetPercent: SerializesInto<Percent>;
  type: TaxMethodTaxMethodType;
};

type MarketsAPIUpdateTaxMethodPayload = MarketsAPIUpdateTaxMethodPathParams &
  MarketsAPIUpdateTaxMethodSearchParams &
  MarketsAPIUpdateTaxMethodBodyParams;

function updateTaxMethodUnsafeEffect(
  payload: MarketsAPIUpdateTaxMethodPayload,
) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/markets/${marketId}/taxMethod`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateTaxMethod(
  payload: MarketsAPIUpdateTaxMethodPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateTaxMethodUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateTaxMethod };
export type {
  MarketsAPIUpdateTaxMethodBodyParams,
  MarketsAPIUpdateTaxMethodPathParams,
  MarketsAPIUpdateTaxMethodPayload,
  MarketsAPIUpdateTaxMethodSearchParams,
};
