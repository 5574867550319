import { Schema } from "@effect/schema";
import { Option as O, pipe } from "effect";
import { z } from "zod";

import type { PlaceResult } from "../shared-utils-google-maps";
import { parseAddress } from "../shared-utils-google-maps";

const PlaceSchema = z.custom<PlaceResult>((maybePlace) => {
  const address = parseAddress(maybePlace);
  return O.isSome(address);
});

const PlaceEffectSchema = Schema.Unknown.pipe(
  Schema.filter(
    (maybePlace) =>
      //@ts-expect-error we know `maybePlace` can be a bad input to parseAddress, but `liftThrowable` is protecting from errors
      pipe(maybePlace, O.liftThrowable(parseAddress), O.flatten, O.isSome),
    {
      message: () => "Invalid Place",
    },
  ),
);

export { PlaceEffectSchema, PlaceSchema };
