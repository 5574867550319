/**
 * HTTP Method: GET
 * Pathname: /accounting/chartOfAccounts
 * @function getChartOfAccounts
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIGetChartOfAccountsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetChartOfAccountsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetChartOfAccountsResponse } from "@ender/shared/generated/ender.api.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIGetChartOfAccountsSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIGetChartOfAccountsPayload =
  GeneralLedgerAPIGetChartOfAccountsSearchParams;

function getChartOfAccountsUnsafeEffect(
  payload: GeneralLedgerAPIGetChartOfAccountsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetChartOfAccountsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetChartOfAccountsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/accounting/chartOfAccounts",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getChartOfAccounts(
  payload: GeneralLedgerAPIGetChartOfAccountsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetChartOfAccountsResponse> {
  return F.pipe(
    payload,
    getChartOfAccountsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getChartOfAccounts };
export type {
  GeneralLedgerAPIGetChartOfAccountsPayload,
  GeneralLedgerAPIGetChartOfAccountsSearchParams,
};
