/**
 * HTTP Method: POST
 * Pathname: /users/{userId}/ssn
 * @function getSSN
 * @memberof LeasingAPI
 * @param {LeasingAPIGetSSNPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetSSNResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetSSNResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIGetSSNPathParams = {
  userId: EnderId;
};

type LeasingAPIGetSSNSearchParams = {
  token?: string | undefined;
};

type LeasingAPIGetSSNBodyParams = {
  reasonToView: string;
};

type LeasingAPIGetSSNPayload = LeasingAPIGetSSNPathParams &
  LeasingAPIGetSSNSearchParams &
  LeasingAPIGetSSNBodyParams;

function getSSNUnsafeEffect(payload: LeasingAPIGetSSNPayload) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetSSNResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetSSNResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${userId}/ssn`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSSN(
  payload: LeasingAPIGetSSNPayload,
  options?: { signal?: AbortSignal },
): Promise<GetSSNResponse> {
  return F.pipe(
    payload,
    getSSNUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSSN };
export type {
  LeasingAPIGetSSNBodyParams,
  LeasingAPIGetSSNPathParams,
  LeasingAPIGetSSNPayload,
  LeasingAPIGetSSNSearchParams,
};
