import { IconTrash, IconX } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Array as A, Option as O } from "effect";
import { useCallback, useState } from "react";

import { SearchInput } from "@ender/entities/search-input";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import type { EnderId } from "@ender/shared/core";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { ActionBadge } from "@ender/shared/ds/badge";
import { ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { ApprovalsAPI } from "@ender/shared/generated/ender.api.misc";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { Color } from "@ender/shared/utils/theming";
import { renderPrivateContact } from "@ender/shared/utils/user";

import { searchManagers } from "./manager-search";

import styles from "./approval-process.module.css";

function ApprovalStepName({
  initialValue,
  onSuccess,
  stepId,
}: {
  initialValue: string;
  onSuccess: (name: string) => void;
  stepId: EnderId;
}) {
  const [name, setName] = useState(initialValue || "");

  const { mutateAsync: updateStepName } = useMutation({
    mutationFn: ApprovalsAPI.updateStepName,
    mutationKey: ["ApprovalsAPI.updateStepName"] as const,
    onSuccess: (_data, variables) => onSuccess(variables.name),
  });
  const debouncedUpdateStepName = useDebounce(updateStepName, 500);

  const handleUpdateName = useCallback(
    async (value: string) => {
      setName(value);
      debouncedUpdateStepName({ stepId, name: value });
    },
    [stepId, debouncedUpdateStepName],
  );

  return <TextInput onChange={handleUpdateName} value={name} />;
}

type ApproverProps = {
  approver: Pick<User, "firstName" | "lastName" | "id">;
  onSuccess: () => void;
  stepId: EnderId;
};

function Approver({ approver, onSuccess, stepId }: ApproverProps) {
  const deleteApprover = useCallback(async () => {
    try {
      await ApprovalsAPI.removeApprover({ approverId: approver.id, stepId });
      onSuccess();
    } catch (e) {
      fail(e);
    }
  }, [stepId, approver.id, onSuccess]);

  return (
    <ActionBadge onClick={deleteApprover} icon={<IconX />}>
      {renderPrivateContact(approver)}
    </ActionBadge>
  );
}

type ApprovalStepComponentProps = {
  canDelete: boolean;
  step: GetApprovalProcessResponseStep;
  onStepUpdated: () => void;
};

function ApprovalStepComponent(props: ApprovalStepComponentProps) {
  const {
    canDelete,
    step: { approvers, id: stepId, name },
    onStepUpdated,
  } = props;

  const confirmation = useConfirmationContext();

  const { mutateAsync: archiveStep } = useMutation({
    mutationFn: ApprovalsAPI.archiveStep,
    mutationKey: ["ApprovalsAPI.archiveStep"] as const,
  });
  const handleDeleteStep = useCallback(async () => {
    await confirmation({
      confirmButtonLabel: "Delete Step",
      title: "Are you sure you want to delete this step?",
    });
    await archiveStep({ stepId });
    onStepUpdated();
  }, [confirmation, onStepUpdated, archiveStep, stepId]);

  const { mutate: addApprover } = useMutation({
    mutationFn: ApprovalsAPI.addApprover,
    mutationKey: ["ApprovalsAPI.addApprover"] as const,
  });

  const handleUserSelect = useCallback(
    async (userId: O.Option<EnderId>) => {
      if (O.isSome(userId)) {
        await addApprover({ stepId, userId: O.getOrThrow(userId) });
        onStepUpdated();
      }
    },
    [addApprover, stepId, onStepUpdated],
  );

  return (
    <div className={styles.approvalStep}>
      <Stack>
        <Group justify={Justify.between}>
          <ApprovalStepName
            initialValue={name}
            onSuccess={(name) => {
              showSuccessNotification({
                message: `Name successfully updated to "${name}".`,
              });
              onStepUpdated();
            }}
            stepId={stepId}
          />
          <SearchInput<EnderId>
            placeholder="Add User or Group"
            search={searchManagers({
              excludeIds: approvers.map(({ id }) => id),
            })}
            modelType={ModelTypeEnum.USER}
            onChange={handleUserSelect}
            value={O.none()}
          />
          {canDelete && (
            <Group justify={Justify.end}>
              <ActionIcon
                variant={ButtonVariant.transparent}
                color={Color.red}
                onClick={handleDeleteStep}
                label="Remove Step">
                <IconTrash />
              </ActionIcon>
            </Group>
          )}
        </Group>
        <Group spacing={Spacing.sm}>
          {A.isNonEmptyArray(approvers) ? (
            approvers.map((approver) => (
              <Approver
                approver={approver}
                key={approver.id}
                onSuccess={onStepUpdated}
                stepId={stepId}
              />
            ))
          ) : (
            <div>Please add an approver.</div>
          )}
        </Group>
      </Stack>
    </div>
  );
}

export { ApprovalStepComponent };
