/**
 * Returns money transfer receipts and associated files, denoted by a fromParty = Lease and status = IN_RECEIPTS
 * HTTP Method: POST
 * Pathname: /receipts
 * @function getReceipts
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetReceiptsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<DepositsReportResponseDepositsReportResponseRow[]>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import type { DepositsReportResponseDepositsReportResponseRow } from "@ender/shared/generated/ender.service.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetReceiptsSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetReceiptsBodyParams = {
  amountMatch?: SerializesInto<Money> | undefined;
  endDateInclusive?: SerializesInto<LocalDate> | undefined;
  firmId?: EnderId | undefined;
  fundIds: EnderId[];
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  paymentType?: MoneyTransferTransferType | undefined;
  periods: LocalDate[];
  propertyIds: EnderId[];
  startDate?: SerializesInto<LocalDate> | undefined;
};

type PaymentsAPIGetReceiptsPayload = PaymentsAPIGetReceiptsSearchParams &
  PaymentsAPIGetReceiptsBodyParams;

function getReceiptsUnsafeEffect(payload: PaymentsAPIGetReceiptsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, DepositsReportResponseDepositsReportResponseRow[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          DepositsReportResponseDepositsReportResponseRow[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/receipts",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getReceipts(
  payload: PaymentsAPIGetReceiptsPayload,
  options?: { signal?: AbortSignal },
): Promise<DepositsReportResponseDepositsReportResponseRow[]> {
  return F.pipe(
    payload,
    getReceiptsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getReceipts };
export type {
  PaymentsAPIGetReceiptsBodyParams,
  PaymentsAPIGetReceiptsPayload,
  PaymentsAPIGetReceiptsSearchParams,
};
