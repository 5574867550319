/**
 * HTTP Method: POST
 * Pathname: /applications/{appGroupId}/residentScreeningReport
 * @function residentScreeningReport
 * @memberof ApplicationsAPI
 * @param {ApplicationsAPIResidentScreeningReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApplicationsAPIResidentScreeningReportPathParams = {
  appGroupId: EnderId;
};

type ApplicationsAPIResidentScreeningReportSearchParams = {
  token?: string | undefined;
};

type ApplicationsAPIResidentScreeningReportPayload =
  ApplicationsAPIResidentScreeningReportPathParams &
    ApplicationsAPIResidentScreeningReportSearchParams;

function residentScreeningReportUnsafeEffect(
  payload: ApplicationsAPIResidentScreeningReportPayload,
) {
  const { appGroupId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/applications/${appGroupId}/residentScreeningReport`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function residentScreeningReport(
  payload: ApplicationsAPIResidentScreeningReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    residentScreeningReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { residentScreeningReport };
export type {
  ApplicationsAPIResidentScreeningReportPathParams,
  ApplicationsAPIResidentScreeningReportPayload,
  ApplicationsAPIResidentScreeningReportSearchParams,
};
