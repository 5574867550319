import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ReportWidgetSortOrderValues = ["ASCENDING", "DESCENDING"] as const;

const ReportWidgetSortOrderEffectSchema = Schema.Literal(
  ...ReportWidgetSortOrderValues,
);

type ReportWidgetSortOrder = Schema.Schema.Type<
  typeof ReportWidgetSortOrderEffectSchema
>;

const ReportWidgetSortOrderEnum = castEnum(ReportWidgetSortOrderEffectSchema);

function randomReportWidgetSortOrder(): ReportWidgetSortOrder {
  return rand(ReportWidgetSortOrderValues);
}

export {
  randomReportWidgetSortOrder,
  ReportWidgetSortOrderEffectSchema,
  ReportWidgetSortOrderEnum,
  ReportWidgetSortOrderValues,
};
export type { ReportWidgetSortOrder };
