import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApplicationRelationshipTypeValues = [
  "SELF",
  "SPOUSE",
  "PARTNER",
  "CHILD",
  "FRIEND",
  "PARENT",
  "GRANDPARENT",
  "OTHER_FAMILY",
] as const;

const ApplicationRelationshipTypeEffectSchema = Schema.Literal(
  ...ApplicationRelationshipTypeValues,
);

type ApplicationRelationshipType = Schema.Schema.Type<
  typeof ApplicationRelationshipTypeEffectSchema
>;

const ApplicationRelationshipTypeEnum = castEnum(
  ApplicationRelationshipTypeEffectSchema,
);

function randomApplicationRelationshipType(): ApplicationRelationshipType {
  return rand(ApplicationRelationshipTypeValues);
}

export {
  ApplicationRelationshipTypeEffectSchema,
  ApplicationRelationshipTypeEnum,
  ApplicationRelationshipTypeValues,
  randomApplicationRelationshipType,
};
export type { ApplicationRelationshipType };
