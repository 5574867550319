/**
 * Gets all bank accounts associated with the pm of the invoker.
 * HTTP Method: GET
 * Pathname: /bankAccounts
 * @function getBankAccounts
 * @memberof BankingAPI
 * @param {BankingAPIGetBankAccountsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchBankAccountResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { SearchBankAccountResponse } from "@ender/shared/generated/ender.model.accounting.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetBankAccountsSearchParams = {
  token?: string | undefined;
};

type BankingAPIGetBankAccountsPayload = BankingAPIGetBankAccountsSearchParams;

function getBankAccountsUnsafeEffect(
  payload: BankingAPIGetBankAccountsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchBankAccountResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<SearchBankAccountResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/bankAccounts",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBankAccounts(
  payload: BankingAPIGetBankAccountsPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchBankAccountResponse[]> {
  return F.pipe(
    payload,
    getBankAccountsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankAccounts };
export type {
  BankingAPIGetBankAccountsPayload,
  BankingAPIGetBankAccountsSearchParams,
};
