/**
 * HTTP Method: POST
 * Pathname: /middleLayer/renewals/getRenewals
 * @function getRenewals
 * @memberof RenewalsMiddleLayerAPI
 * @param {RenewalsMiddleLayerAPIGetRenewalsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetRenewalsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GetRenewalsResponse } from "@ender/shared/generated/com.ender.middle.response";
import type {
  GetRenewalsReportRequestLeaseType,
  GetRenewalsRequestRenewalSortKey,
  GetRenewalsRequestRenewalTableTab,
} from "@ender/shared/generated/ender.api.leasing.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsMiddleLayerAPIGetRenewalsSearchParams = {
  token?: string | undefined;
};

type RenewalsMiddleLayerAPIGetRenewalsBodyParams = {
  ascending?: boolean | undefined;
  depositsHeldExternally?: boolean | undefined;
  expireEndDate?: SerializesInto<LocalDate> | undefined;
  expireStartDate?: SerializesInto<LocalDate> | undefined;
  hcvStatus?: boolean | undefined;
  leaseType?: GetRenewalsReportRequestLeaseType | undefined;
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  ownershipGroupIds: EnderId[];
  propertyIds: EnderId[];
  renewalTab: GetRenewalsRequestRenewalTableTab;
  searchString?: string | undefined;
  sortKey?: GetRenewalsRequestRenewalSortKey | undefined;
  unitIds: EnderId[];
};

type RenewalsMiddleLayerAPIGetRenewalsPayload =
  RenewalsMiddleLayerAPIGetRenewalsSearchParams &
    RenewalsMiddleLayerAPIGetRenewalsBodyParams;

function getRenewalsUnsafeEffect(
  payload: RenewalsMiddleLayerAPIGetRenewalsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetRenewalsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetRenewalsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/renewals/getRenewals",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRenewals(
  payload: RenewalsMiddleLayerAPIGetRenewalsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetRenewalsResponse> {
  return F.pipe(
    payload,
    getRenewalsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRenewals };
export type {
  RenewalsMiddleLayerAPIGetRenewalsBodyParams,
  RenewalsMiddleLayerAPIGetRenewalsPayload,
  RenewalsMiddleLayerAPIGetRenewalsSearchParams,
};
