import { Schema } from "@effect/schema";
import { Option as O } from "effect";
import * as S from "effect/String";
import { useWatch } from "react-hook-form";

import { applicantEmploymentOptions } from "@ender/entities/utils/application-utils";
import type { UseFormReturn } from "@ender/form-system/base";
import {
  EmailEffectSchema,
  MoneyEffectSchema,
  PhoneEffectSchema,
} from "@ender/form-system/schema";
import { Money$ } from "@ender/shared/core";
import { FormCheckbox } from "@ender/shared/ds/checkbox";
import { Spacing } from "@ender/shared/ds/flex";
import { Inset } from "@ender/shared/ds/inset";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormPhoneInput } from "@ender/shared/ds/phone-input";
import { FormSelect } from "@ender/shared/ds/select";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Tuple } from "@ender/shared/ds/tuple";
import type { ApplicationEmploymentStatus } from "@ender/shared/generated/ender.model.leasing";
import { ApplicationEmploymentStatusEnum } from "@ender/shared/generated/ender.model.leasing";

const employedStatuses = new Set<ApplicationEmploymentStatus>([
  ApplicationEmploymentStatusEnum.EMPLOYED,
  ApplicationEmploymentStatusEnum.SELF_EMPLOYED,
]);

const ApplicantEmploymentFormSchema = Schema.Struct({
  annualSalary: MoneyEffectSchema.pipe(Schema.OptionFromSelf),
  employer: Schema.String,
  employmentStatus: Schema.Enums(ApplicationEmploymentStatusEnum).pipe(
    Schema.OptionFromSelf,
    // This custom predicate can be replaced or removed once form types are fixed
    Schema.filter(
      (input): input is O.Option<ApplicationEmploymentStatus> =>
        O.isSome(input),
      {
        message: () => "Required",
      },
    ),
  ),
  housingChoiceVoucher: Schema.Boolean,
  housingChoiceVoucherCaseWorkerEmail: EmailEffectSchema,
  housingChoiceVoucherCaseWorkerName: Schema.String,
  managerName: Schema.String,
  managerPhone: PhoneEffectSchema,
}).pipe(
  Schema.filter((values) => {
    const issues: Schema.FilterIssue[] = [];
    if (
      O.exists(values.employmentStatus, (val) => employedStatuses.has(val)) &&
      !O.exists(values.annualSalary, Money$.isPositive)
    ) {
      issues.push({
        message: "Required",
        path: ["annualSalary"],
      });
    }
    if (
      O.exists(values.employmentStatus, (val) => employedStatuses.has(val)) &&
      S.isEmpty(values.employer)
    ) {
      issues.push({
        message: "Required",
        path: ["employer"],
      });
    }
    if (
      O.exists(
        values.employmentStatus,
        (val) => val === ApplicationEmploymentStatusEnum.EMPLOYED,
      ) &&
      S.isEmpty(values.managerName)
    ) {
      issues.push({
        message: "Required",
        path: ["managerName"],
      });
    }
    if (
      values.housingChoiceVoucher &&
      S.isEmpty(values.housingChoiceVoucherCaseWorkerName)
    ) {
      issues.push({
        message: "Required",
        path: ["housingChoiceVoucherCaseWorkerName"],
      });
    }
    if (
      values.housingChoiceVoucher &&
      S.isEmpty(values.housingChoiceVoucherCaseWorkerEmail)
    ) {
      issues.push({
        message: "Required",
        path: ["housingChoiceVoucherCaseWorkerEmail"],
      });
    }
    return issues;
  }),
);

type ApplicantEmploymentFormInput = Schema.Schema.Encoded<
  typeof ApplicantEmploymentFormSchema
>;
type ApplicantEmploymentFormOutput = Schema.Schema.Type<
  typeof ApplicantEmploymentFormSchema
>;

type EditableApplicantEmploymentProps = {
  form: UseFormReturn<
    ApplicantEmploymentFormInput,
    {},
    ApplicantEmploymentFormOutput
  >;
};

function EditableApplicantEmployment(props: EditableApplicantEmploymentProps) {
  const { form } = props;
  const employmentStatus = useWatch({
    control: form.control,
    name: "employmentStatus",
  });
  const housingChoiceVoucher = useWatch({
    control: form.control,
    name: "housingChoiceVoucher",
  });
  const employmentStatusValue = employmentStatus.pipe(O.getOrUndefined);
  const isEmployed =
    employmentStatusValue === ApplicationEmploymentStatusEnum.EMPLOYED ||
    employmentStatusValue === ApplicationEmploymentStatusEnum.SELF_EMPLOYED;

  return (
    <>
      <Tuple
        label="Employment Status"
        value={
          <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
            <FormSelect
              data={applicantEmploymentOptions}
              form={form}
              name="employmentStatus"
              size="sm"
            />
          </Inset>
        }
      />
      {isEmployed && (
        <>
          <Tuple
            label="Employer"
            value={
              <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                <FormTextInput form={form} name="employer" size="sm" />
              </Inset>
            }
          />
          <Tuple
            label="Salary"
            value={
              <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                <FormMoneyInput form={form} name="annualSalary" size="sm" />
              </Inset>
            }
          />
          <Tuple
            label="Manager"
            value={
              <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                <FormTextInput form={form} name="managerName" size="sm" />
              </Inset>
            }
          />
          <Tuple
            label="Manager Phone"
            value={
              <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                <FormPhoneInput size="sm" form={form} name="managerPhone" />
              </Inset>
            }
          />
        </>
      )}
      <Tuple
        label="Using Housing Choice Vouchers"
        value={
          <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
            <FormCheckbox form={form} name="housingChoiceVoucher" />
          </Inset>
        }
      />
      {housingChoiceVoucher && (
        <>
          <Tuple
            label="Case Worker Name"
            value={
              <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                <FormTextInput
                  form={form}
                  name="housingChoiceVoucherCaseWorkerName"
                  size="sm"
                />
              </Inset>
            }
          />
          <Tuple
            label="Case Worker Email"
            value={
              <Inset t={Spacing.sm} b={Spacing.sm} r={Spacing.md}>
                <FormTextInput
                  form={form}
                  name="housingChoiceVoucherCaseWorkerEmail"
                  size="sm"
                />
              </Inset>
            }
          />
        </>
      )}
    </>
  );
}

export { ApplicantEmploymentFormSchema, EditableApplicantEmployment };
export type { ApplicantEmploymentFormInput, ApplicantEmploymentFormOutput };
