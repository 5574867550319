import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P, String as S } from "effect";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";

import type { Undefined } from "@ender/shared/constants/general";
import type { EnderId, LocalDate } from "@ender/shared/core";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { Text } from "@ender/shared/ds/text";
import { BankingAPI } from "@ender/shared/generated/ender.api.accounting";
import { PeriodFilterFilterTypeEnum } from "@ender/shared/generated/ender.api.model";
import type {
  BankRecRowType,
  BankingReportBankingReportStatsResponse,
} from "@ender/shared/generated/ender.service.accounting.banking";
import { BankRecRowTypeEnum } from "@ender/shared/generated/ender.service.accounting.banking";
import { useAccountingFiltersStore } from "@ender/shared/stores/accounting-filters-store";

import { PAGE_SIZE } from "./use-banking-detail-table";

type BankingDetailCategoryRowProps = {
  bankRecRowType?: BankRecRowType;
  label: string;
  value: number | string;
};

function BankingDetailCategoryRow({
  bankRecRowType,
  label,
  value,
}: BankingDetailCategoryRowProps) {
  const { accountId } = useParams<{ accountId: EnderId }>();
  const { url } = useRouteMatch();
  const { pathname, search } = useLocation();
  const baseUrl = url.split(`/${accountId}`)[0];
  const remainingUrl = bankRecRowType ? `?status=${bankRecRowType}` : "";
  const finalUrl = `${baseUrl}/${accountId}${remainingUrl}`;

  const isSelected = pathname + search === finalUrl;

  return (
    <div className={isSelected ? "bg-gray-100 rounded -m-1 p-1" : ""}>
      <>
        <Group justify={Justify.between}>
          <RouterLink href={finalUrl}>{label}</RouterLink>
          <Text>{value}</Text>
        </Group>
      </>
    </div>
  );
}

type BankingDetailCategoriesProps = {
  amountSearchString?: string | undefined;
  description?: string | undefined;
  periodFilter: LocalDate[];
  propertyIds: EnderId[];
  showDeposits: boolean;
  showDraws: boolean;
};

function BankingDetailCategories({
  amountSearchString,
  description,
  periodFilter,
  propertyIds,
  showDeposits,
  showDraws,
}: BankingDetailCategoriesProps) {
  const { accountId } = useParams<{ accountId: EnderId }>();
  const {
    startDate,
    endDate,
    dateValidationState: { isValidDateRange },
  } = useAccountingFiltersStore();

  const { data } = useQuery({
    enabled: P.isNotUndefined(accountId) && isValidDateRange,
    keepPreviousData: true,
    queryFn: ({ signal }) =>
      BankingAPI.getTransactions(
        {
          ...(P.isNotUndefined(amountSearchString) &&
            S.isNonEmpty(amountSearchString) && {
              amountSearchString: amountSearchString,
            }),
          bankAccountId: accountId,
          ...(P.isNotUndefined(description) &&
            S.isNonEmpty(description) && {
              description: description,
            }),
          inclusiveEndDate: endDate,
          limit: PAGE_SIZE,
          ...(A.isNonEmptyArray(periodFilter) && {
            periodFilter: {
              customFilter: periodFilter,
              type: PeriodFilterFilterTypeEnum.CUSTOM,
            },
          }),
          propertyIds,
          showDeposits,
          showDraws,
          startDate,
        },
        { signal },
      ),
    queryKey: [
      "BankingAPI.getTransactions.categoryStats",
      accountId,
      startDate,
      endDate,
      amountSearchString,
      description,
      periodFilter,
      propertyIds,
      showDeposits,
      showDraws,
    ] as const,
  });

  const stats: BankingReportBankingReportStatsResponse | Undefined =
    data?.bankDetails?.rows?.stats;

  return (
    <Stack spacing={Spacing.xs}>
      <BankingDetailCategoryRow label="Total" value={stats?.total ?? "-"} />
      <BankingDetailCategoryRow
        label="Reconciled"
        value={stats?.reconciled ?? "-"}
        bankRecRowType={BankRecRowTypeEnum.MATCHED}
      />
      <BankingDetailCategoryRow
        label="Missing from Bank Statement"
        value={stats?.missingBank ?? "-"}
        bankRecRowType={BankRecRowTypeEnum.UNMATCHED_ENDER}
      />
      <BankingDetailCategoryRow
        label="Missing from General Ledger"
        value={stats?.missingGL ?? "-"}
        bankRecRowType={BankRecRowTypeEnum.UNMATCHED_BANK}
      />
      <BankingDetailCategoryRow
        label="Bank Excluded"
        value={stats?.bankExcluded ?? "-"}
        bankRecRowType={BankRecRowTypeEnum.BANK_EXCLUDED}
      />
      <BankingDetailCategoryRow
        label="Ender Excluded"
        value={stats?.enderExcluded ?? "-"}
        bankRecRowType={BankRecRowTypeEnum.ENDER_EXCLUDED}
      />
    </Stack>
  );
}

export { BankingDetailCategories };
