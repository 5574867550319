import { z } from "zod";

import { EnderIdSchema } from "@ender/shared/core";
import { PropertyPropertyTypeValues } from "@ender/shared/generated/ender.model.core.property";
import type {
  UnitCoolingSystem,
  UnitHeatingSystem,
  UnitLaundryType,
  UnitParkingType,
} from "@ender/shared/generated/ender.model.core.unit";
import {
  UnitCoolingSystemEnum,
  UnitCoolingSystemValues,
  UnitHeatingSystemEnum,
  UnitHeatingSystemValues,
  UnitLaundryTypeEnum,
  UnitLaundryTypeValues,
  UnitParkingTypeEnum,
  UnitParkingTypeValues,
  UnitUnitStatusValues,
} from "@ender/shared/generated/ender.model.core.unit";

import { IsoDatetimeSchema } from "./iso-datetime";

const AddressSchema = z.object({
  city: z.string(),
  fullDisplay: z.string(),
  id: EnderIdSchema,
  latitude: z.number(),
  longitude: z.number(),
  name: z.string(),
  placeId: z.string(),
  state: z.string(),
  street: z.string(),
  timezone: z.string().optional(),
  unit: z.string(),
  zipcode: z.string(),
});

const HeatingSystemDisplayEnum: Record<UnitHeatingSystem, string> = {
  [UnitHeatingSystemEnum.BASEBOARD]: "Baseboard",
  [UnitHeatingSystemEnum.FORCED_AIR]: "Forced Air",
  [UnitHeatingSystemEnum.HEAT_PUMP]: "Heat Pump",
  [UnitHeatingSystemEnum.NONE]: "None",
  [UnitHeatingSystemEnum.OTHER]: "Other",
  [UnitHeatingSystemEnum.RADIANT]: "Radiant",
  [UnitHeatingSystemEnum.STOVE]: "Stove",
  [UnitHeatingSystemEnum.WALL]: "Wall",
} as const;

const CoolingSystemDisplayEnum: Record<UnitCoolingSystem, string> = {
  [UnitCoolingSystemEnum.CENTRAL]: "Central",
  [UnitCoolingSystemEnum.EVAPORATIVE]: "Evaporative",
  [UnitCoolingSystemEnum.GEOTHERMAL]: "Geothermal",
  [UnitCoolingSystemEnum.NONE]: "None",
  [UnitCoolingSystemEnum.OTHER]: "Other",
  [UnitCoolingSystemEnum.SOLAR]: "Solar",
  [UnitCoolingSystemEnum.WALL]: "Wall",
} as const;

const ParkingTypeDisplayEnum: Record<UnitParkingType, string> = {
  [UnitParkingTypeEnum.ATTACHED_GARAGE]: "Attached Garage",
  [UnitParkingTypeEnum.CARPORT]: "Carport",
  [UnitParkingTypeEnum.DETACHED_GARAGE]: "Detached Garage",
  [UnitParkingTypeEnum.NONE]: "None",
  [UnitParkingTypeEnum.OFF_STREET]: "Off Street",
  [UnitParkingTypeEnum.ON_STREET]: "On Street",
} as const;

const LaundryTypeDisplayEnum: Record<UnitLaundryType, string> = {
  [UnitLaundryTypeEnum.IN_UNIT]: "In Unit",
  [UnitLaundryTypeEnum.NONE]: "None",
  [UnitLaundryTypeEnum.SHARED]: "Shared",
} as const;

// Unit::toJson from model/core/unit/Unit.java
/**
 * @deprecated use the UnitSchema from generated models insteaad
 */
const UnitSchema = z.object({
  activeListingId: EnderIdSchema.optional(), // The id of the active listing associated with this Unit
  address: AddressSchema,
  adverselyOccupiedTime: z.string().optional(),
  applicationUrl: z.string().optional(),
  askingRent: z.string().optional(),
  bathrooms: z.string().default("0"),
  bedrooms: z.number().default(0),
  coolingSystem: z.enum(UnitCoolingSystemValues).nullish(),
  floorNum: z.number().nullish(), //The floor this unit is on e.g. "3"
  fullBaths: z.number().default(0),
  halfBaths: z.number().default(0),
  heatingSystem: z.enum(UnitHeatingSystemValues).nullish(),
  heightFeet: z.number().optional(),
  heldForSaleTime: z.string().optional(),
  id: EnderIdSchema,
  isAdvertised: z.boolean(),
  isOnAirbnb: z.boolean(),
  isResidential: z.boolean(),
  isVacant: z.boolean().optional(),
  laundryType: z.enum(UnitLaundryTypeValues).nullish(),
  lengthFeet: z.number().optional(),
  listingId: EnderIdSchema.optional(),
  marketRent: z.string().optional(),
  marketingDownTime: z.string().optional(),
  name: z.string(),
  needsTenant: z.boolean().optional(),
  parkingType: z.enum(UnitParkingTypeValues).nullish(),
  property: z.object({ id: EnderIdSchema }),
  soldTime: z.string().optional(),
  specialDamageRenovationTime: z.string().optional(),
  sqft: z.number().default(0),
  status: z.enum(UnitUnitStatusValues),
  storageGroup: z.string().optional(), // Storage Units can be grouped into multiple groups
  timestamp: IsoDatetimeSchema,
  type: z.enum(PropertyPropertyTypeValues),
  unitTypeId: EnderIdSchema.optional(), // the ID that associates this unit with a "unit type" template
  url: z.string(),
  versionStart: IsoDatetimeSchema,
  videoUrl: z.string().optional(),
  visualDisplayLeftFeet: z.number().optional(),
  visualDisplayRotation: z.number().optional(),
  visualDisplayTopFeet: z.number().optional(),
  widthFeet: z.number().optional(),
});

/**
 * @deprecated use the Unit type from generated models instead
 */
type Unit = z.infer<typeof UnitSchema>;

export {
  CoolingSystemDisplayEnum,
  HeatingSystemDisplayEnum,
  LaundryTypeDisplayEnum,
  ParkingTypeDisplayEnum,
  UnitSchema,
};
export type { Unit };
