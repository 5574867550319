/**
 * HTTP Method: POST
 * Pathname: /uploadFiles
 * @function uploadFiles
 * @memberof WebserverFilesAPI
 * @param {WebserverFilesAPIUploadFilesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<WebserverFilesServiceHandleFileResult[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { FileRequest } from "@ender/shared/generated/ender.api.files.request";
import type {
  WebserverFilesServiceFileUploadType,
  WebserverFilesServiceHandleFileResult,
  WebserverFilesServiceSubFolder,
} from "@ender/shared/generated/ender.service.files";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type WebserverFilesAPIUploadFilesSearchParams = {
  token?: string | undefined;
};

type WebserverFilesAPIUploadFilesBodyParams = {
  asMessage?: boolean | undefined;
  files?: FileRequest[] | undefined;
  modelId: EnderId;
  modelType: ModelType;
  secondaryModelId?: EnderId | undefined;
  subFolder?: WebserverFilesServiceSubFolder | undefined;
  uploadType: WebserverFilesServiceFileUploadType;
};

type WebserverFilesAPIUploadFilesPayload =
  WebserverFilesAPIUploadFilesSearchParams &
    WebserverFilesAPIUploadFilesBodyParams;

function uploadFilesUnsafeEffect(payload: WebserverFilesAPIUploadFilesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, WebserverFilesServiceHandleFileResult[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          WebserverFilesServiceHandleFileResult[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/uploadFiles",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function uploadFiles(
  payload: WebserverFilesAPIUploadFilesPayload,
  options?: { signal?: AbortSignal },
): Promise<WebserverFilesServiceHandleFileResult[]> {
  return F.pipe(
    payload,
    uploadFilesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { uploadFiles };
export type {
  WebserverFilesAPIUploadFilesBodyParams,
  WebserverFilesAPIUploadFilesPayload,
  WebserverFilesAPIUploadFilesSearchParams,
};
