import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const SelectionCriteriaLeaseStatusValues = [
  "STABLE",
  "MARKED_FOR_EVICTION",
  "IN_EVICTION",
  "MOVED_OUT",
] as const;

const SelectionCriteriaLeaseStatusEffectSchema = Schema.Literal(
  ...SelectionCriteriaLeaseStatusValues,
);

type SelectionCriteriaLeaseStatus = Schema.Schema.Type<
  typeof SelectionCriteriaLeaseStatusEffectSchema
>;

const SelectionCriteriaLeaseStatusEnum = castEnum(
  SelectionCriteriaLeaseStatusEffectSchema,
);

function randomSelectionCriteriaLeaseStatus(): SelectionCriteriaLeaseStatus {
  return rand(SelectionCriteriaLeaseStatusValues);
}

export {
  randomSelectionCriteriaLeaseStatus,
  SelectionCriteriaLeaseStatusEffectSchema,
  SelectionCriteriaLeaseStatusEnum,
  SelectionCriteriaLeaseStatusValues,
};
export type { SelectionCriteriaLeaseStatus };
