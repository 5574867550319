import { Schema } from "@effect/schema";
import { Option as O } from "effect";
import { useCallback } from "react";
import { useStore } from "zustand";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { LocalDateEffectSchema } from "@ender/form-system/schema";
import type { TransformedSchedule } from "@ender/shared/contexts/scheduled-charges";
import { LocalDate$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { ChargeSchedulesAPI } from "@ender/shared/generated/ender.api.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import { useCurrentScheduledCharges } from "../hooks";
import { useTenantLedgerStore } from "../tenant-ledger-store.context";

type EditActiveScheduleFormProps = {
  scheduledCharge: TransformedSchedule;
  onCancel: () => void;
};

const EditActiveScheduleFormSchema = Schema.Struct({
  inclusiveEndDate: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "End Date is required",
    }),
    Schema.filter(
      (input): input is O.Some<LocalDate$.LocalDate> =>
        O.exists(input, (v) => v.isAfterOrEqual(LocalDate$.today())),
      { message: () => "End Date must be in the future" },
    ),
  ),
});
type EditActiveScheduleFormOutput = Schema.Schema.Type<
  typeof EditActiveScheduleFormSchema
>;

function EditActiveScheduleForm({
  scheduledCharge,
  onCancel,
}: EditActiveScheduleFormProps) {
  const { isLoading: isLoadingScheduledCharges } = useCurrentScheduledCharges();
  const form = useEffectSchemaForm({
    defaultValues: {
      inclusiveEndDate: LocalDate$.parse(scheduledCharge.inclusiveEndDate),
    },
    schema: EditActiveScheduleFormSchema,
  });

  const { refetchScheduledCharges } = useCurrentScheduledCharges();
  const tenantLedgerStore = useTenantLedgerStore();

  const { setSelectedScheduledChargeId } = useStore(
    tenantLedgerStore,
    (state) => ({
      setSelectedScheduledChargeId: state.setSelectedScheduledChargeId,
    }),
  );

  const [isLoading, isLoadingHandlers] = useBoolean();
  const submitForm = useCallback(
    async (values: EditActiveScheduleFormOutput) => {
      const inclusiveEndDate = O.getOrThrow(values.inclusiveEndDate).toJSON();

      try {
        isLoadingHandlers.setTrue();
        await ChargeSchedulesAPI.deactivateChargeSchedule({
          chargeScheduleId: scheduledCharge.id,
          inclusiveEndDate,
        });
        showSuccessNotification({
          message: "Scheduled charge successfully created.",
        });
        setSelectedScheduledChargeId(O.some(scheduledCharge.id));
        await refetchScheduledCharges();
      } catch (error) {
        fail(error);
      } finally {
        isLoadingHandlers.setFalse();
      }
    },
    [
      isLoadingHandlers,
      scheduledCharge.id,
      refetchScheduledCharges,
      setSelectedScheduledChargeId,
    ],
  );

  return (
    <Form form={form} onSubmit={submitForm}>
      <Stack>
        <FormDateInput
          label="End Date"
          name="inclusiveEndDate"
          form={form}
          minDate={LocalDate$.of(scheduledCharge.startDate).add({ days: 1 })}
        />
        <Group spacing={Spacing.md}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            loading={isLoading || isLoadingScheduledCharges}
            type="submit">
            Save Changes
          </Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { EditActiveScheduleForm };
