import { differenceInDays } from "date-fns";
import { formatWithOptions } from "date-fns/fp";
import { enUS } from "date-fns/locale";
import { Option as O, Predicate as P, pipe } from "effect";

import { LocalDate$ } from "@ender/shared/core";
import { pluralize } from "@ender/shared/utils/string";

const LONG_SLASH_FORMAT = "MM/dd/yyyy";

const longSlashFormat = formatWithOptions({ locale: enUS }, LONG_SLASH_FORMAT);

/**
 * Takes in an optional LocalDate$ or string, parses it to a date (defaulting to today's date if none is provided),
 * formats it in "MM/dd/yyyy", calculates how many days it differs from today, and then appends either "Day" or "Days"
 * based on that numeric difference.
 *
 * @param {LocalDate$ | string | undefined} input - The date-like input to format.
 * @returns {string} - A string in the format of "MM/dd/yyyy (N) Day/Days"
 * @example "11/13/2024 (4) Days"
 * @example "11/13/2024 (1) Day"
 * @example if the date supplied is today "11/13/2024 (Today)"
 *
 */

function formatLongSlashDateAndDaysFromToday(
  input: LocalDate$.LocalDate | string | undefined,
) {
  const today = LocalDate$.today();
  if (P.isUndefined(input)) {
    return "--";
  }
  const localDate = LocalDate$.parse(input);

  return pipe(
    localDate,
    O.map((date) => {
      const formattedDate = longSlashFormat(date.toDate());
      const diffDays = differenceInDays(date.toDate(), today.toDate());
      const pluralizedLabel = pluralize("Day", diffDays);
      if (diffDays === 0) {
        return `${formattedDate} (Today)`;
      }
      return `${formattedDate} (${diffDays} ${pluralizedLabel})`;
    }),
    O.getOrThrow,
  );
}

export { formatLongSlashDateAndDaysFromToday };
