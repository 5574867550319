import { Predicate as P } from "effect";

import type { EnderId } from "@ender/shared/core";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { GeneralLedgerAPI } from "@ender/shared/generated/ender.api.accounting";
import {
  PropertiesAPI,
  UsersAPI,
  VendorsAPI,
} from "@ender/shared/generated/ender.api.core";
import { ApplicationsAPI } from "@ender/shared/generated/ender.api.leasing";
import { SearchAPI, TasksAPI } from "@ender/shared/generated/ender.api.misc";
import { FactorsAPI } from "@ender/shared/generated/ender.api.reports";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { CustomFactor } from "@ender/shared/generated/ender.model.factor";
import { SearchServiceSearchTypeEnum } from "@ender/shared/generated/ender.service.search";
import { getPropertyNameWithFriendlyId } from "@ender/shared/utils/property/get-property-name-with-friendly-id";

import type { KeywordSearch, ValueHydrate } from "../entities-search-input";

const searchCustomFields = ({
  allowFactorCreation,
  builtInFactors,
  customFactors,
  excludeIds,
  inputTypes,
  keyword,
}: {
  allowFactorCreation?: boolean;
  keyword?: string;
  excludeIds?: EnderId[];
  builtInFactors?: boolean;
  customFactors?: boolean;
  inputTypes?: ModelType[];
}) =>
  FactorsAPI.searchFactors({
    allowFactorCreation,
    builtInFactors,
    customFactors,
    excludeIds,
    inputTypes,
    keyword,
  }).then((res) =>
    res.map((field) => ({
      label: field.name,
      meta: field as CustomFactor,
      value: field.id as EnderId,
    })),
  );

const searchMLSProperties: KeywordSearch<EnderId> = (keyword: string) =>
  BuyAPI.searchMLSProperties({
    keyword,
  }).then((res) =>
    res.map((property) => ({
      label: property.name,
      meta: property,
      value: property.id,
    })),
  );

const searchProperties: KeywordSearch<EnderId> = (keyword: string) =>
  SearchAPI.omnisearch({
    excludeIds: [],
    keyword,
    propertyIds: [],
    resultsOnEmpty: false,
    types: [SearchServiceSearchTypeEnum.PROPERTY],
  }).then((res) =>
    res.map((property) => ({
      label: getPropertyNameWithFriendlyId(property),
      meta: property,
      value: property.id,
    })),
  );

const searchPropertiesShowUnpurchased: KeywordSearch<EnderId> = (
  keyword: string,
) =>
  SearchAPI.omnisearch({
    keyword,
    types: [SearchServiceSearchTypeEnum.PROPERTY],
    resultsOnEmpty: false,
    propertyIds: [],
    excludeIds: [],
    showUnpurchased: true,
  }).then((res) =>
    res.map((property) => ({
      label: `${property.name}${property.friendlyId ? ` (${property.friendlyId})` : ""}`,
      value: property.id,
      meta: property,
    })),
  );

const hydrateProperty: ValueHydrate<EnderId> = (propertyId: EnderId) => {
  if (P.isNullable(propertyId)) {
    return Promise.resolve([]);
  }

  return PropertiesAPI.getProperty({ propertyId }).then(
    (property) =>
      [{ label: property.name, meta: property, value: property.id }] as const,
  );
};

const searchGetTxCategories: KeywordSearch<EnderId> = (keyword: string) =>
  GeneralLedgerAPI.getTxCategories({
    excludeIds: [],
    excludeParentCategories: true,
    includeBankAccountCategories: true,
    includeRoots: false,
    keyword,
  }).then((res) =>
    res.map((category) => ({
      label: `${category.accountNumber} ${category.accountName}`,
      meta: category,
      value: category.id,
    })),
  );

const searchAssignableUsers: KeywordSearch<EnderId> = (keyword: string) =>
  TasksAPI.searchForAssignableUsers({ keyword }).then((res) =>
    res.map((user) => ({
      label: user.name,
      meta: user,
      value: user.id,
    })),
  );

const searchAssignableVendors: KeywordSearch<EnderId> = (keyword: string) =>
  TasksAPI.searchForAssignableVendors({ keyword }).then((res) =>
    res.map((vendor) => ({
      label: vendor.name,
      meta: vendor,
      value: vendor.id,
    })),
  );

const hydrateCategory: ValueHydrate<EnderId> = (categoryId: EnderId) =>
  GeneralLedgerAPI.getCategory({ categoryId }).then((category) => [
    {
      label: `${category.accountNumber} ${category.accountName}`,
      meta: category,
      value: category.id,
    } as const,
  ]);

const hydrateUser: ValueHydrate<EnderId> = (targetId: EnderId) =>
  UsersAPI.getUser({ targetId }).then((user) => [
    {
      label: user.name,
      meta: user,
      value: user.id,
    } as const,
  ]);

const searchApplications: KeywordSearch<EnderId> = (keyword: string) =>
  SearchAPI.omnisearch({
    keyword,
    excludeIds: [],
    limit: 25,
    propertyIds: [],
    useCache: false,
    resultsOnEmpty: false,
    types: [SearchServiceSearchTypeEnum.APPLICATION],
  }).then((res) =>
    res.map((application) => ({
      label: `${application.firstName} ${application.lastName}`,
      meta: application,
      value: application.userId,
    })),
  );

const hydrateApplication: ValueHydrate<EnderId> = (applicationId: EnderId) =>
  ApplicationsAPI.getApplicationUser({ applicationId }).then(
    (applicant) =>
      [
        {
          label: `${applicant.firstName} ${applicant.lastName}`,
          meta: applicant,
          value: applicationId,
        },
      ] as const,
  );

const searchVendors: KeywordSearch<EnderId> = (keyword: string) =>
  SearchAPI.omnisearch({
    excludeIds: [],
    keyword,
    propertyIds: [],
    resultsOnEmpty: false,
    types: [SearchServiceSearchTypeEnum.VENDOR],
  }).then((res) =>
    res.map((vendor) => ({
      label: `${vendor.name}`,
      meta: vendor,
      value: vendor.id,
    })),
  );

const hydrateVendor: ValueHydrate<EnderId> = (vendorId: EnderId) =>
  VendorsAPI.getVendor({ vendorId }).then((vendor) => [
    { label: vendor.name, meta: vendor, value: vendor.id } as const,
  ]);

const searchManagers: (params: {
  excludeIds: EnderId[];
}) => KeywordSearch<EnderId> =
  ({ excludeIds }) =>
  async (keyword: string) => {
    const res = await SearchAPI.omnisearch({
      excludeIds,
      keyword,
      limit: 25,
      propertyIds: [],
      resultsOnEmpty: true,
      types: [SearchServiceSearchTypeEnum.MANAGER],
    });
    return res.map((user: User) => ({
      label: `${user.firstName} ${user.lastName}`,
      meta: user,
      value: user.id,
    }));
  };

const searchOwners: KeywordSearch<EnderId> = async (keyword: string) => {
  const res = await SearchAPI.omnisearch({
    excludeIds: [],
    keyword,
    propertyIds: [],
    resultsOnEmpty: true,
    types: [
      SearchServiceSearchTypeEnum.FIRM,
      SearchServiceSearchTypeEnum.INVESTOR,
    ],
  });
  return res.map((owner) => ({
    label: owner.name,
    meta: owner,
    value: owner.id,
  }));
};

export {
  hydrateApplication,
  hydrateCategory,
  hydrateProperty,
  hydrateUser,
  hydrateVendor,
  searchApplications,
  searchAssignableUsers,
  searchAssignableVendors,
  searchCustomFields,
  searchGetTxCategories,
  searchManagers,
  searchMLSProperties,
  searchOwners,
  searchProperties,
  searchPropertiesShowUnpurchased,
  searchVendors,
};
