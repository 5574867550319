import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CatalogQuestionQuestionTypeValues = [
  "TEXT",
  "MONEY",
  "NUMBER",
  "DATE",
  "BOOLEAN",
  "MULTIPLE_CHOICE",
] as const;

const CatalogQuestionQuestionTypeEffectSchema = Schema.Literal(
  ...CatalogQuestionQuestionTypeValues,
);

type CatalogQuestionQuestionType = Schema.Schema.Type<
  typeof CatalogQuestionQuestionTypeEffectSchema
>;

const CatalogQuestionQuestionTypeEnum = castEnum(
  CatalogQuestionQuestionTypeEffectSchema,
);

function randomCatalogQuestionQuestionType(): CatalogQuestionQuestionType {
  return rand(CatalogQuestionQuestionTypeValues);
}

export {
  CatalogQuestionQuestionTypeEffectSchema,
  CatalogQuestionQuestionTypeEnum,
  CatalogQuestionQuestionTypeValues,
  randomCatalogQuestionQuestionType,
};
export type { CatalogQuestionQuestionType };
