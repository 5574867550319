/**
 * HTTP Method: GET
 * Pathname: /brokers/{brokerId}
 * @function getBroker
 * @memberof BuyAPI
 * @param {BuyAPIGetBrokerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<User>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetBrokerPathParams = {
  brokerId: EnderId;
};

type BuyAPIGetBrokerSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetBrokerPayload = BuyAPIGetBrokerPathParams &
  BuyAPIGetBrokerSearchParams;

function getBrokerUnsafeEffect(payload: BuyAPIGetBrokerPayload) {
  const { brokerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, User>({
        body,
        decode: unsafeDecodeJsonResponse<User>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/brokers/${brokerId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBroker(
  payload: BuyAPIGetBrokerPayload,
  options?: { signal?: AbortSignal },
): Promise<User> {
  return F.pipe(
    payload,
    getBrokerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBroker };
export type {
  BuyAPIGetBrokerPathParams,
  BuyAPIGetBrokerPayload,
  BuyAPIGetBrokerSearchParams,
};
