/**
 * Setup Autopay for a tenant
 * HTTP Method: POST
 * Pathname: /users/{tenantId}/autopaySettings
 * @function setAutopaySettings
 * @memberof PaymentsAPI
 * @param {PaymentsAPISetAutopaySettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { AutopaySettingsTenantPaymentMethodType } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPISetAutopaySettingsPathParams = {
  /**
   * id for the tenant user
   */
  tenantId: EnderId;
};

type PaymentsAPISetAutopaySettingsSearchParams = {
  token?: string | undefined;
};

type PaymentsAPISetAutopaySettingsBodyParams = {
  /**
   * the amount to set the limit to
   */
  autopayLimit: SerializesInto<Money>;
  offsetDays: number;
  /**
   * either a bankAccountId or creditCardId to use for payment
   */
  paymentMethodId: EnderId;
  paymentMethodType: AutopaySettingsTenantPaymentMethodType;
};

type PaymentsAPISetAutopaySettingsPayload =
  PaymentsAPISetAutopaySettingsPathParams &
    PaymentsAPISetAutopaySettingsSearchParams &
    PaymentsAPISetAutopaySettingsBodyParams;

function setAutopaySettingsUnsafeEffect(
  payload: PaymentsAPISetAutopaySettingsPayload,
) {
  const { tenantId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${tenantId}/autopaySettings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setAutopaySettings(
  payload: PaymentsAPISetAutopaySettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setAutopaySettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setAutopaySettings };
export type {
  PaymentsAPISetAutopaySettingsBodyParams,
  PaymentsAPISetAutopaySettingsPathParams,
  PaymentsAPISetAutopaySettingsPayload,
  PaymentsAPISetAutopaySettingsSearchParams,
};
