import { IconCurrencyDollar } from "@tabler/icons-react";
import * as O from "effect/Option";
import * as S from "effect/String";
// @ts-expect-error - exact-math is not typed
import { formula as exactMathFormula } from "exact-math";
import type { FocusEvent, ForwardedRef } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { Money$ } from "@ender/shared/core";

import type { InputBaseProps } from "../../../input/src";
import { Input } from "../../../input/src";
import { Tooltip } from "../../../tooltip/src";

/**
 * matches arithmetic operators + - * / ( )
 * does not consider a leading "-"" to be an operator, because it could be a negative number
 */
const hasArithmetic = /(?!^-)[+\-()*/]|\..*\./;

/**
 * given the user's typed input, strip out all characters not allowed in an arithmetic expression.
 * The resulting string should be something we are comfortable to display in the UI.
 */
function sanitize(value: string) {
  return value.replace(/(^\s+)|[^\d\s.+\-()*/]/g, ""); //filter out non-arithmetic characters and leading spaces
}

/**
 * converts the Money to a formatted, 2-decimal string without the dollar sign
 */
function getDisplayString(value: Money$.Money): string {
  return value.toFormatted().replace("$", "");
}

type MoneyInputProps = {
  value: O.Option<Money$.Money>;
  onChange: (value: O.Option<Money$.Money>) => void;
} & InputBaseProps;

function MoneyInput(
  props: MoneyInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    borderless,
    description,
    disabled = false,
    error,
    label,
    leftSection = <IconCurrencyDollar />,
    onChange,
    placeholder,
    rightSection,
    size,
    textAlign = "right",
    value,
    name,
  } = props;

  /**
   * whether the user is currently typing in the field or not.
   * If this is true, we want to prevent incoming value changes
   * from replacing the user's typed input
   */
  const isEditing = useRef(false);

  /**
   * the display string representing the user's typed input.
   */
  const [display, setDisplay] = useState<string>(() => {
    try {
      return value.pipe(
        O.map(getDisplayString),
        O.getOrElse(() => ""),
      );
    } catch (e) {
      console.error(
        "Error in MoneyInput.getDisplayString for MoneyInput with label",
        label,
      );
      throw e;
    }
  });

  /**
   * react to value changes from the parent state management
   */
  useEffect(() => {
    if (!isEditing.current) {
      setDisplay(
        value.pipe(
          O.map(getDisplayString),
          O.getOrElse(() => ""),
        ),
      );
    }
  }, [value]);

  /**
   * handles the user typing. What this achieves:
   * 1. instantly update the display value with the user's input
   * 2. sanitize and compute the Money value based on the user's input
   * 3. update the Money value via the onChange prop
   */
  const handleTextInput = useCallback(
    (val: string) => {
      //immediately prevent invalid characters from being displayed
      const sanitized = sanitize(val);

      //get rid of trailing mathematical symbols
      const validEquation = sanitized
        .replace(/\.(?!\d)/, "")
        .split(/[^\d)]+$/)[0];
      try {
        setDisplay(sanitized);

        const evaluated = exactMathFormula(validEquation);

        onChange(O.some(Money$.fromDollars(evaluated ?? 0)));
      } catch {
        onChange(O.none());
      }
    },
    [onChange],
  );

  //the only purpose of this is to reformat the displayValue when the user clicks away. The value is unchanged.
  const handleBlur = useCallback(() => {
    isEditing.current = false;
    setDisplay(
      value.pipe(
        O.map(getDisplayString),
        O.getOrElse(() => ""),
      ),
    );
  }, [value]);

  function handleFocus(e: FocusEvent<HTMLInputElement>) {
    isEditing.current = true;
    //highlight the user's input when the field receives focus for any reason
    e.currentTarget.select();
  }

  const showTooltip = hasArithmetic.test(display ?? "");
  const computed = value.pipe(
    O.map((val) => `Amount: ${val.toFormatted()}`),
    O.getOrElse(() => "Invalid Amount"),
  );

  return (
    <Tooltip
      label={computed}
      opened={showTooltip}
      disabled={S.isEmpty(display)}>
      <Input
        borderless={borderless}
        description={description}
        disabled={disabled}
        error={error}
        label={label}
        leftSection={leftSection}
        inputMode="decimal"
        onBlur={handleBlur}
        onChange={handleTextInput}
        onFocus={handleFocus}
        placeholder={placeholder}
        ref={ref}
        rightSection={rightSection}
        size={size}
        textAlign={textAlign}
        value={display}
        name={name}
      />
    </Tooltip>
  );
}

const ForwardedMoneyInput = forwardRef<HTMLInputElement, MoneyInputProps>(
  MoneyInput,
);

export { ForwardedMoneyInput as MoneyInput };
export type { MoneyInputProps };
