/**
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}/onSuccessfulLinkUpdate
 * @function onSuccessfulLinkUpdate
 * @memberof BankingAPI
 * @param {BankingAPIOnSuccessfulLinkUpdatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIOnSuccessfulLinkUpdatePathParams = {
  bankAccountId: EnderId;
};

type BankingAPIOnSuccessfulLinkUpdateSearchParams = {
  token?: string | undefined;
};

type BankingAPIOnSuccessfulLinkUpdatePayload =
  BankingAPIOnSuccessfulLinkUpdatePathParams &
    BankingAPIOnSuccessfulLinkUpdateSearchParams;

function onSuccessfulLinkUpdateUnsafeEffect(
  payload: BankingAPIOnSuccessfulLinkUpdatePayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}/onSuccessfulLinkUpdate`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function onSuccessfulLinkUpdate(
  payload: BankingAPIOnSuccessfulLinkUpdatePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    onSuccessfulLinkUpdateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { onSuccessfulLinkUpdate };
export type {
  BankingAPIOnSuccessfulLinkUpdatePathParams,
  BankingAPIOnSuccessfulLinkUpdatePayload,
  BankingAPIOnSuccessfulLinkUpdateSearchParams,
};
