/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}/photos.zip
 * @function downloadPhotosZIP
 * @memberof InspectionAPI
 * @param {InspectionAPIDownloadPhotosZIPPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIDownloadPhotosZIPPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIDownloadPhotosZIPSearchParams = {
  token?: string | undefined;
};

type InspectionAPIDownloadPhotosZIPPayload =
  InspectionAPIDownloadPhotosZIPPathParams &
    InspectionAPIDownloadPhotosZIPSearchParams;

function downloadPhotosZIPUnsafeEffect(
  payload: InspectionAPIDownloadPhotosZIPPayload,
) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}/photos.zip`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function downloadPhotosZIP(
  payload: InspectionAPIDownloadPhotosZIPPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    downloadPhotosZIPUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { downloadPhotosZIP };
export type {
  InspectionAPIDownloadPhotosZIPPathParams,
  InspectionAPIDownloadPhotosZIPPayload,
  InspectionAPIDownloadPhotosZIPSearchParams,
};
