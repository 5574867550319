import { clsx } from "clsx";
import { Function as F, Predicate as P } from "effect";
import type { PropsWithChildren, TransitionEvent } from "react";
import { forwardRef, useEffect, useRef } from "react";

import { NULL } from "@ender/shared/constants/general";
import { mergeRefs } from "@ender/shared/hooks/use-merged-ref";

import styles from "./expando-box.module.css";

type ExpandoBoxProps = PropsWithChildren<{
  in: boolean;
  className?: string;
  /**
   * the width of the box when collapsed.
   * @default 0
   */
  collapsedWidth?: number;
  /**
   * the height of the box when collapsed.
   * @default 0
   */
  collapsedHeight?: number;
  onTransitionEnd?: (e: TransitionEvent) => void;
  /** Transition duration in ms */
  transitionDuration?: number;
  /** Transition timing function */
  transitionTimingFunctionX?: string;
  transitionTimingFunctionY?: string;
}>;

/**
 * TODO replace this with intersection observer for performant async height measurement
 */
function getElementBounds(el: HTMLElement) {
  return {
    height: el.clientHeight,
    width: el.clientWidth,
  };
}

const raf = requestAnimationFrame;

const ExpandoBox = forwardRef<HTMLDivElement, ExpandoBoxProps>(
  function ExpandoBox(props, ref) {
    const {
      in: opened,
      children,
      className,
      onTransitionEnd = F.constVoid,
      collapsedHeight = 0,
      collapsedWidth = 0,
      transitionDuration = 200,
      transitionTimingFunctionX = "ease-in",
      transitionTimingFunctionY = "ease-in",
    } = props;
    const el = useRef<HTMLDivElement>(NULL);
    const innerRef = useRef<HTMLDivElement>(NULL);

    const transition = `height ${transitionDuration}ms ${transitionTimingFunctionY}, width ${transitionDuration}ms ${transitionTimingFunctionX}`;

    // lazy useEffect
    useEffect(() => {
      if (P.isNullable(el.current) || P.isNullable(innerRef.current)) {
        return;
      }

      if (opened) {
        /**
         * set the innerRef to absolute position so that we can measure its contents
         */
        innerRef.current?.style.setProperty("position", "absolute");
        el.current.style.setProperty("transition", transition);

        //one frame later, set the height and width to the measured values
        raf(() => {
          if (P.isNotNullable(innerRef.current)) {
            const { width, height } = getElementBounds(innerRef.current);
            innerRef.current.setAttribute("style", "position:relative;");

            /**
             * set the height and width to the measured values
             */
            el.current?.style.setProperty("width", `${width}px`);
            el.current?.style.setProperty("height", `${height}px`);
          }
        });
      } else {
        const { width, height } = getElementBounds(innerRef.current);
        /**
         * set the height and width to the measured values
         */
        el.current.style.setProperty("width", `${width}px`);
        el.current.style.setProperty("height", `${height}px`);
        el.current.style.setProperty("overflow", "hidden");
        /**
         * one frame of delay, set the height and width to the collapsed values
         */
        raf(() => {
          el.current?.style.setProperty("width", `${collapsedWidth}px`);
          el.current?.style.setProperty("height", `${collapsedHeight}px`);
        });
      }
    }, [opened, collapsedHeight, collapsedWidth, transition]);

    /**
     * after the transition, we want to unset the height and width styles
     * used for the transition, so that the component can use its natural contents to determine size
     */
    const handleTransitionEnd = (e: TransitionEvent): void => {
      if (
        e.target !== el.current ||
        (e.propertyName !== "height" && e.propertyName !== "width")
      ) {
        return;
      }

      innerRef.current?.style.setProperty("position", "");
      if (opened && P.isNotNullable(innerRef.current)) {
        el.current.style.setProperty("width", ``);
        el.current.style.setProperty("height", ``);
        el.current.style.setProperty("overflow", ``);
      }

      onTransitionEnd(e);
    };

    return (
      <div
        ref={mergeRefs(el, ref)}
        onTransitionEnd={handleTransitionEnd}
        className={clsx(styles.root, className)}>
        <div ref={innerRef} className={styles.inner}>
          {children}
        </div>
      </div>
    );
  },
);
export { ExpandoBox };
