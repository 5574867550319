import { Predicate as P } from "effect";

import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";

import type { PaymentStatus } from "./types";
import { PaymentStatusEnum } from "./types";

const INVOICE_SUCCESS_MESSAGES: Record<string, string> = {
  [`${InvoiceInvoiceTypeEnum.PAYABLE}_${PaymentStatusEnum.PAID}`]:
    "Invoice has been successfully paid.",
  [`${InvoiceInvoiceTypeEnum.PAYABLE}_${PaymentStatusEnum.REJECTED}`]:
    "Invoice has been successfully rejected.",
  [`${InvoiceInvoiceTypeEnum.PAYABLE}_${PaymentStatusEnum.VOIDED}`]:
    "Invoice has been successfully voided.",
  [`${InvoiceInvoiceTypeEnum.RECEIVABLE}_${PaymentStatusEnum.PAID}`]:
    "Payment has been successfully received.",
  [`${InvoiceInvoiceTypeEnum.RECEIVABLE}_${PaymentStatusEnum.REJECTED}`]:
    "Payment has been successfully rejected.",
  [`${InvoiceInvoiceTypeEnum.RECEIVABLE}_${PaymentStatusEnum.VOIDED}`]:
    "Payment has been successfully void.",
};

function getInvoiceSuccessMessage(
  invoiceType?: InvoiceSerializerInvoiceResponse["invoiceType"],
  paymentStatus?: PaymentStatus,
) {
  if (P.isNullable(paymentStatus) || P.isNullable(invoiceType)) {
    return "";
  }
  const key = `${invoiceType}_${paymentStatus}`;
  return INVOICE_SUCCESS_MESSAGES[key] || "";
}

export { getInvoiceSuccessMessage };
