import { batchApprove } from "./batch-approve";
import { batchReject } from "./batch-reject";
import { createGLJournalEntryAttachments } from "./create-g-l-journal-entry-attachments";
import { createJournalEntry } from "./create-journal-entry";
import { createRecurringGLJournalEntry } from "./create-recurring-g-l-journal-entry";
import { deleteGLJournalEntryAttachment } from "./delete-g-l-journal-entry-attachment";
import { deleteRecurringGLJournalEntry } from "./delete-recurring-g-l-journal-entry";
import { editGLJournalEntry } from "./edit-g-l-journal-entry";
import { getAccountingPeriodHistory } from "./get-accounting-period-history";
import { getAccountingPeriods } from "./get-accounting-periods";
import { getApprovalGLJournalEntries } from "./get-approval-g-l-journal-entries";
import { getDefaultAccountingPeriod } from "./get-default-accounting-period";
import { getGLJournalEntryAttachments } from "./get-g-l-journal-entry-attachments";
import { getJournalEntries } from "./get-journal-entries";
import { getJournalEntry } from "./get-journal-entry";
import { getJournalEntryDetails } from "./get-journal-entry-details";
import { getLinkedEvent } from "./get-linked-event";
import { getPMLedger } from "./get-p-m-ledger";
import { getRecurringGLJournalEntries } from "./get-recurring-g-l-journal-entries";
import { getRetainedEarningsJournalEntries } from "./get-retained-earnings-journal-entries";
import { importHistoricalGLTxsForProperty } from "./import-historical-g-l-txs-for-property";
import { importJournalEntriesFromFile } from "./import-journal-entries-from-file";
import { importRecurringGLJournalEntries } from "./import-recurring-g-l-journal-entries";
import { retainEarnings } from "./retain-earnings";
import { reverseJournalEntry } from "./reverse-journal-entry";
import { search } from "./search";
import { updateAccountingPeriod } from "./update-accounting-period";
import { updateJournalEntriesFromFile } from "./update-journal-entries-from-file";
import { updateRecurringGLJournalEntry } from "./update-recurring-g-l-journal-entry";

const AccountingAPI = {
  batchApprove,
  batchReject,
  createGLJournalEntryAttachments,
  createJournalEntry,
  createRecurringGLJournalEntry,
  deleteGLJournalEntryAttachment,
  deleteRecurringGLJournalEntry,
  editGLJournalEntry,
  getAccountingPeriodHistory,
  getAccountingPeriods,
  getApprovalGLJournalEntries,
  getDefaultAccountingPeriod,
  getGLJournalEntryAttachments,
  getJournalEntries,
  getJournalEntry,
  getJournalEntryDetails,
  getLinkedEvent,
  getPMLedger,
  getRecurringGLJournalEntries,
  getRetainedEarningsJournalEntries,
  importHistoricalGLTxsForProperty,
  importJournalEntriesFromFile,
  importRecurringGLJournalEntries,
  retainEarnings,
  reverseJournalEntry,
  search,
  updateAccountingPeriod,
  updateJournalEntriesFromFile,
  updateRecurringGLJournalEntry,
};

export { AccountingAPI };
