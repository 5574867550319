/**
 * Reverse a payment. Currently only supports reversing payments on either the tenant ledger, or internal transfers.
 The original payment's status is set to REVERSED.

 If the original payment was IN_RECEIPTS, its GLTx's (which were PENDING) are POSTED since the reversal will also
 get POSTED GLTx's.
 * HTTP Method: POST
 * Pathname: /moneyTransfers/{moneyTransferId}/reverse
 * @function markReversedPayment
 * @memberof PaymentsAPI
 * @param {PaymentsAPIMarkReversedPaymentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MoneyTransfer>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { MoneyTransfer } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIMarkReversedPaymentPathParams = {
  /**
   * the ID of the MoneyTransfer being reversed.
   */
  moneyTransferId: EnderId;
};

type PaymentsAPIMarkReversedPaymentSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIMarkReversedPaymentBodyParams = {
  overrideWarnings: boolean;
  /**
   * Required, the date on which the payment reversed.
   */
  reversalDate: SerializesInto<LocalDate>;
  showOnBankRecAndTL: boolean;
};

type PaymentsAPIMarkReversedPaymentPayload =
  PaymentsAPIMarkReversedPaymentPathParams &
    PaymentsAPIMarkReversedPaymentSearchParams &
    PaymentsAPIMarkReversedPaymentBodyParams;

function markReversedPaymentUnsafeEffect(
  payload: PaymentsAPIMarkReversedPaymentPayload,
) {
  const { moneyTransferId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MoneyTransfer>({
        body,
        decode: unsafeDecodeJsonResponse<MoneyTransfer>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/moneyTransfers/${moneyTransferId}/reverse`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function markReversedPayment(
  payload: PaymentsAPIMarkReversedPaymentPayload,
  options?: { signal?: AbortSignal },
): Promise<MoneyTransfer> {
  return F.pipe(
    payload,
    markReversedPaymentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { markReversedPayment };
export type {
  PaymentsAPIMarkReversedPaymentBodyParams,
  PaymentsAPIMarkReversedPaymentPathParams,
  PaymentsAPIMarkReversedPaymentPayload,
  PaymentsAPIMarkReversedPaymentSearchParams,
};
