/**
 * HTTP Method: POST
 * Pathname: /insurances
 * @function addInsurance
 * @memberof InsuranceAPI
 * @param {InsuranceAPIAddInsurancePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<InsurancePolicy>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  InsurancePolicy,
  InsurancePolicyInsuredParty,
} from "@ender/shared/generated/ender.model.core.vendor";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InsuranceAPIAddInsuranceSearchParams = {
  token?: string | undefined;
};

type InsuranceAPIAddInsuranceBodyParams = {
  carrier: string;
  coverageAmount: SerializesInto<Money>;
  deductible?: SerializesInto<Money> | undefined;
  effectiveDate: SerializesInto<LocalDate>;
  expirationDate: SerializesInto<LocalDate>;
  insuranceType: string;
  insuredPartyId: EnderId;
  insuredPartyType: InsurancePolicyInsuredParty;
  monthlyPremium?: SerializesInto<Money> | undefined;
  policyNumber: string;
};

type InsuranceAPIAddInsurancePayload = InsuranceAPIAddInsuranceSearchParams &
  InsuranceAPIAddInsuranceBodyParams;

function addInsuranceUnsafeEffect(payload: InsuranceAPIAddInsurancePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, InsurancePolicy>({
        body,
        decode: unsafeDecodeJsonResponse<InsurancePolicy>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/insurances",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addInsurance(
  payload: InsuranceAPIAddInsurancePayload,
  options?: { signal?: AbortSignal },
): Promise<InsurancePolicy> {
  return F.pipe(
    payload,
    addInsuranceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addInsurance };
export type {
  InsuranceAPIAddInsuranceBodyParams,
  InsuranceAPIAddInsurancePayload,
  InsuranceAPIAddInsuranceSearchParams,
};
