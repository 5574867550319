import { z } from "zod";

import { castEnum } from "@ender/shared/utils/zod";

const RmisComplianceStatusValues = [
  "PENDING_SYNCHRONIZATION",
  "COMPLIANT",
  "NON_COMPLIANT",
] as const;
const RmisComplianceStatusSchema = z.enum(RmisComplianceStatusValues);
type RmisComplianceStatus = z.infer<typeof RmisComplianceStatusSchema>;

/**
 * @enum {string} RmisComplianceStatusEnum
 * @description VendorMarketMapping::RmisComplianceStatus from model/core/vendor/VendorMarketMapping.java
 */
const RmisComplianceStatusEnum = castEnum<RmisComplianceStatus>(
  RmisComplianceStatusSchema,
);

export { RmisComplianceStatusEnum, RmisComplianceStatusSchema };
