import type { z } from "zod";

import { InputInfoPropsSchema } from "@ender/shared/forms/types/general";
import { ContextAwareInput } from "@ender/shared/forms/ui/context-aware-input";
import {
  Select as EnderSelect_,
  SelectBasePropsSchema,
} from "@ender/shared/ui/select";

// schema is not used for type because it does not support generics
/** @deprecated This should not be used anymore. */
const EnderSelectPropsSchema =
  SelectBasePropsSchema.merge(InputInfoPropsSchema);

/** @deprecated This should not be used anymore. */
type EnderSelectProps = z.infer<typeof EnderSelectPropsSchema>;

/** @deprecated This should not be used anymore. */
function EnderSelect(props: EnderSelectProps & { name: string }) {
  const { name, ...rest } = props;
  return (
    // @ts-expect-error types are too complex to map correctly, this will be overhauled in the future
    <ContextAwareInput Component={EnderSelect_} name={name} props={rest} />
  );
}

export { EnderSelect, EnderSelectPropsSchema };
export type { EnderSelectProps };
