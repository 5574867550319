import type { AutocompleteItem } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";
import { useForm, zodResolver } from "@mantine/form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import * as A from "effect/Array";
import { useCallback, useContext, useEffect, useState } from "react";

import { UNDEFINED } from "@ender/shared/constants/general";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Align, Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import {
  ApplicationsAPI,
  LeasingAPI,
} from "@ender/shared/generated/ender.api.leasing";
import type { GetApplicationGroupResponseApplicantUserResponse } from "@ender/shared/generated/ender.api.leasing.response";
import type { PMCompany } from "@ender/shared/generated/ender.model.core";
import type {
  ApplicationGroup,
  Showing,
} from "@ender/shared/generated/ender.model.leasing";
import type { LabelValue } from "@ender/shared/types/label-value";
import { EnderDatePicker } from "@ender/shared/ui/ender-date-picker";
import {
  EnderSearch,
  SearchTypeEnum,
  useEnderSearchForm,
} from "@ender/shared/ui/ender-search";
import { EnderTimeInput } from "@ender/shared/ui/ender-time-input";
import { Select } from "@ender/shared/ui/select";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

import type {
  NewShowingFormProps,
  NewShowingFormValues,
} from "./create-showing-form.types";
import { NewShowingFormSchema } from "./create-showing-form.types";

function createInitialValues(
  showing?: Showing,
  applicationGroup?: ApplicationGroup,
  userId?: EnderId,
): Partial<NewShowingFormValues> {
  if (P.isNotNullable(showing)) {
    return {
      userId,
      leadId: showing.prospectId,
      propertyId: showing.propertyId as EnderId,
      unitId: showing.unitId as EnderId,
      date: EnderDate.of(showing.scheduledDate),
      time: EnderDate.from12HourTime(showing.scheduledTime),
    };
  }

  if (P.isNotNullable(applicationGroup)) {
    return {
      userId,
      applicationGroupId: applicationGroup.id,
      propertyId: applicationGroup.propertyId,
      unitId: applicationGroup.unitId,
    };
  }

  return {
    userId,
  };
}

type ProspectOrApplicantSearchProps = {
  userPM: PMCompany;
  form: UseFormReturnType<Partial<NewShowingFormValues>>;
  applicationGroup?: ApplicationGroup;
  setShouldSetProperty: (shouldSetProperty: boolean) => void;
};

function autoCompleteToFormItem(item: AutocompleteItem): {
  userId?: EnderId;
  leadId?: EnderId;
  applicationGroupId?: EnderId;
} {
  if (P.isNotNullable(item)) {
    if (item.type === SearchTypeEnum.PROSPECT) {
      return { userId: item.id, leadId: item.leadId };
    }

    if (item.type === SearchTypeEnum.APPLICATION) {
      return {
        userId: item.userId,
        applicationGroupId: item.applicationGroupId,
      };
    }
  }

  return {};
}

function ProspectOrApplicantSearch({
  userPM,
  form,
  setShouldSetProperty,
}: ProspectOrApplicantSearchProps) {
  function _onSelect(item: AutocompleteItem) {
    if (P.isNullable(item)) {
      form.setFieldValue("userId", UNDEFINED);
      form.setFieldValue("leadId", UNDEFINED);
      form.setFieldValue("applicationGroupId", UNDEFINED);

      setShouldSetProperty(false);
    }
    const ids = autoCompleteToFormItem(item);
    form.setFieldValue("userId", ids.userId);

    if (P.isNotNullable(ids.leadId)) {
      form.setFieldValue("leadId", ids.leadId);
    }

    if (P.isNotNullable(ids.applicationGroupId)) {
      form.setFieldValue("applicationGroupId", ids.applicationGroupId);
    }
  }

  const prospectOrApplicantSearch = useEnderSearchForm({
    name: "prospectOrApplicant",
    form,
    onSelect: (result: unknown) => _onSelect(result as AutocompleteItem),
  });

  if (userPM.beHomeEnabled) {
    return (
      <EnderSearch
        label="Prospect"
        placeholder="Search"
        route="/search"
        requestParams={{
          resultsOnEmpty: false,
          types: [SearchTypeEnum.PROSPECT],
        }}
        onSelect={_onSelect}
        showOptionResultType
      />
    );
  }

  return (
    <EnderSearch
      label="Prospect or Applicant"
      placeholder="Search"
      route="/search"
      useKeyword
      requestParams={{
        resultsOnEmpty: false,
        types: [SearchTypeEnum.PROSPECT, SearchTypeEnum.APPLICATION],
      }}
      {...prospectOrApplicantSearch}
      showOptionResultType
    />
  );
}

function ApplicantFromGroupSelect({
  applicationGroup,
  applicants,
  form,
}: {
  applicationGroup: ApplicationGroup;
  applicants?: GetApplicationGroupResponseApplicantUserResponse[];
  form: UseFormReturnType<Partial<NewShowingFormValues>>;
}) {
  const selectData =
    applicants?.map<LabelValue<EnderId>>((person) => ({
      label: `${person.firstName} ${person.lastName}`,
      value: person.userId,
    })) ?? [];
  const onSelectChange = (value: EnderId | null) => {
    form.setFieldValue("applicationGroupId", applicationGroup.id);
    //TODO fix form type so that userId is nullable but required upon validation
    form.setFieldValue("userId", value as EnderId);
  };

  return (
    <Select
      name="userId"
      data={selectData}
      clearable
      searchable
      label="Applicant"
      placeholder="Select applicant from group"
      {...form.getInputProps("userId")}
      onChange={onSelectChange}
    />
  );
}

function CreateShowingForm({
  showing,
  applicationGroup,
  applicants,
  userId,
  onSuccess,
  onCancel,
}: NewShowingFormProps) {
  const { userPM } = useContext(UserContext);

  const form = useForm<Partial<NewShowingFormValues>>({
    validate: zodResolver(NewShowingFormSchema),
    initialValues: createInitialValues(showing, applicationGroup, userId),
  });

  const { data: propertySelectData } = useQuery({
    queryFn: ({ signal }) =>
      PropertiesAPI.searchPropertiesv2(
        {
          minimal: true,
          ownershipGroupIds: [],
        },
        { signal },
      ),
    queryKey: ["PropertiesAPI.searchPropertiesv2"] as const,
    select: (ret) =>
      ret.properties.map((property) => ({
        label: property.name,
        value: property.id,
      })),
  });

  const { data: unitSelectData = [], isFetching: isUnitSelectDataFetching } =
    useQuery({
      enabled: P.isNotNullable(form.values.propertyId),
      queryFn: async ({ signal }) => {
        if (P.isNotNullable(form.values.propertyId)) {
          return PropertiesAPI.getUnitsForProperty(
            {
              propertyId: form.values.propertyId,
            },
            { signal },
          );
        }
      },
      queryKey: [
        "PropertiesAPI.getUnitsForProperty",
        form.values.propertyId,
      ] as const,
      select: (ret) =>
        ret?.map<LabelValue<EnderId>>((unit) => ({
          label: unit.name,
          value: unit.id,
        })),
    });

  const {
    data: prospectPropertyAndUnit,
    isFetching: isProspectPropertyAndUnitFetching,
  } = useQuery({
    enabled: P.isNotNullable(form.values.leadId),
    queryFn: ({ signal }) => {
      return P.isNotNullable(form.values.prospectOrApplicant?.leadId)
        ? LeasingAPI.getLead(
            { leadId: form.values.prospectOrApplicant.leadId },
            { signal },
          )
        : Promise.resolve(UNDEFINED);
    },
    queryKey: ["LeasingAPI.getLead", form.values.leadId] as const,
    select: (ret) =>
      P.isNotNullable(ret)
        ? {
            propertyId: ret.propertyId ?? UNDEFINED,
            unitId: ret.unitId ?? UNDEFINED,
          }
        : UNDEFINED,
  });

  const [shouldSetProperty, setShouldSetProperty] = useState(true);

  // Set default property and unit when a prospect is selected
  useEffect(() => {
    if (
      isProspectPropertyAndUnitFetching ||
      P.isNullable(prospectPropertyAndUnit) ||
      !shouldSetProperty
    ) {
      return;
    }

    const { propertyId, unitId } = prospectPropertyAndUnit;

    if (P.isNotNullable(propertyId) && form.values.propertyId !== propertyId) {
      form.setFieldValue("propertyId", prospectPropertyAndUnit.propertyId);
    }

    if (P.isNotNullable(unitId) && form.values.unitId !== unitId) {
      form.setFieldValue("unitId", prospectPropertyAndUnit.unitId);
    }

    setShouldSetProperty(false);
  }, [
    isProspectPropertyAndUnitFetching,
    prospectPropertyAndUnit,
    form,
    shouldSetProperty,
  ]);

  const {
    data: appGroupPropertyAndUnit,
    isFetching: isAppGroupPropertyAndUnitFetching,
  } = useQuery({
    enabled: P.isNotNullable(form.values.applicationGroupId),
    queryFn: async ({ signal }) =>
      P.isNotNullable(form.values.applicationGroupId)
        ? ApplicationsAPI.getApplication(
            {
              appGroupId: form.values.applicationGroupId,
            },
            { signal },
          )
        : UNDEFINED,
    queryKey: [
      "ApplicationsAPI.getApplication",
      form.values.applicationGroupId,
    ] as const,
    select: (ret) => ({
      propertyId: ret?.property?.id ?? UNDEFINED,
      unitId: ret?.unit?.unitId ?? UNDEFINED,
    }),
  });

  // Set default property and unit when an applicant is selected
  useEffect(() => {
    if (
      isAppGroupPropertyAndUnitFetching ||
      P.isNullable(appGroupPropertyAndUnit) ||
      !shouldSetProperty
    ) {
      return;
    }

    const { propertyId, unitId } = appGroupPropertyAndUnit;

    if (P.isNotNullable(propertyId) && form.values.propertyId !== propertyId) {
      form.setFieldValue("propertyId", propertyId);
    }

    if (P.isNotNullable(unitId) && form.values.unitId !== unitId) {
      form.setFieldValue("unitId", unitId);
    }

    setShouldSetProperty(false);
  }, [
    isAppGroupPropertyAndUnitFetching,
    appGroupPropertyAndUnit,
    form,
    shouldSetProperty,
  ]);

  const propertyOnChange = (value: EnderId | undefined) => {
    if (value !== form.values.propertyId) {
      form.setFieldValue("unitId", "" as EnderId);
    }

    if (P.isNotNullable(unitSelectData) && unitSelectData.length === 1) {
      form.setFieldValue("unitId", unitSelectData[0].value);
    }

    form.setFieldValue("propertyId", value);
  };

  const { mutateAsync: createShowing, isLoading: isCreateShowingLoading } =
    useMutation({
      mutationKey: ["addShowing"],
      mutationFn: LeasingAPI.addShowing,
    });

  const handleSubmit = useCallback(
    async (values: Partial<NewShowingFormValues>) => {
      await createShowing({
        ...values,
        //@ts-expect-error - userId is required but is nullable. Form type should be adjusted to use the refined output type
        prospectId: values.userId,
        //TODO Date Time string should satisfy Time type
        //@ts-expect-error - time is required but is nullable. Form type should be adjusted to use the refined output type
        time: values.time?.toTimeString().slice(0, 8) ?? "",
      });
      showSuccessNotification({ message: "Showing created successfully" });
      onSuccess();
    },
    [createShowing, onSuccess],
  );

  const disableUnitSelect =
    P.isNullable(form.values.propertyId) ||
    A.isEmptyArray(unitSelectData) ||
    isUnitSelectDataFetching ||
    unitSelectData.length <= 1;

  return (
    <UnmanagedForm onSubmit={handleSubmit} form={form}>
      <Stack justify={Justify.between} align={Align.center}>
        <Stack>
          {P.isNotNullable(applicationGroup) ? (
            <ApplicantFromGroupSelect
              applicationGroup={applicationGroup}
              applicants={applicants}
              form={form}
            />
          ) : (
            <ProspectOrApplicantSearch
              userPM={userPM}
              form={form}
              applicationGroup={applicationGroup}
              setShouldSetProperty={setShouldSetProperty}
            />
          )}
          <Select
            label="Property"
            placeholder="Select Property"
            data={propertySelectData || []}
            searchable
            {...form.getInputProps("propertyId")}
            onChange={propertyOnChange}
          />
          <Select
            label="Unit"
            placeholder="Select Unit"
            data={unitSelectData}
            searchable
            disabled={disableUnitSelect}
            {...form.getInputProps("unitId")}
          />
          <EnderDatePicker
            label="Date"
            minDate={EnderDate.today}
            {...form.getInputProps("date")}
          />
          <EnderTimeInput label="Time" {...form.getInputProps("time")} />
        </Stack>
        <Group justify={Justify.end} align={Align.center}>
          <Button variant={ButtonVariant.transparent} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" loading={isCreateShowingLoading}>
            Create Showing
          </Button>
        </Group>
      </Stack>
    </UnmanagedForm>
  );
}

export { CreateShowingForm };
