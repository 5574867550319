import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useState } from "react";

import { NULL } from "@ender/shared/constants/general";
import type { EnderId } from "@ender/shared/core";
import { ReportsAPI } from "@ender/shared/generated/ender.api.reports";
import { DynamicTableOperatorsEnum } from "@ender/shared/types/ender-general";
import { Select } from "@ender/shared/ui/select";

import { getWidget } from "../widget-helpers";
import type {
  FilterComponentProps,
  Widget,
  WidgetFactor,
  WidgetFilter,
} from "./filter-types";
import { Factor } from "./filter-types";

type MarketFilterProps = {
  metadata: {
    filterWidgetName: string;
  };
  factor?: WidgetFactor;
} & Omit<FilterComponentProps, "metadata" | "factor">;

function getMarketFilter(
  market: string | null | undefined,
  factor: WidgetFactor | undefined,
): WidgetFilter[] | undefined {
  if (P.isNullable(market) || P.isNullable(factor)) {
    return undefined;
  }

  return [
    {
      factor,
      operator: DynamicTableOperatorsEnum.IN,
      values: [market.toString()],
    },
  ];
}

function getDefaultValue(widget: Widget): string | null {
  return (
    widget.filters
      .find(({ factor }) => factor.name === Factor.MARKET)
      ?.values?.[0].toString() || NULL
  );
}

function MarketFilter({
  factor,
  metadata,
  updateFilters,
  widget,
}: MarketFilterProps) {
  const [market, setMarket] = useState<string | null>(getDefaultValue(widget));

  function handleChange(newMarket: string | null) {
    setMarket(newMarket);

    const filters = getMarketFilter(newMarket, factor);

    updateFilters(Factor.MARKET, filters);
  }

  const { data: markets = [], isInitialLoading } = useQuery<string[]>({
    enabled: Boolean(factor),
    queryFn: async ({ signal }) => {
      if (P.isNullable(factor)) {
        return [];
      }
      const filterWidget = await getWidget(metadata.filterWidgetName);
      const data = await ReportsAPI.getFactorMetadataForWidget(
        {
          factorIds: [factor.id],
          widgetId: filterWidget.id,
        },
        { signal },
      );
      return Array.from(
        new Set<string>(
          data[factor.id].map(({ value }: { value: EnderId }) => value),
        ).values(),
      ).sort();
    },
    queryKey: [
      "ReportsAPI.getFactorMetadata",
      metadata.filterWidgetName,
      factor?.id,
    ] as const,
    staleTime: Number.MAX_SAFE_INTEGER,
  });

  return (
    <Select
      label="Market"
      clearable
      searchable
      data={markets}
      value={market}
      onChange={handleChange}
      placeholder={isInitialLoading ? "Loading..." : "Select Market"}
      disabled={isInitialLoading}
    />
  );
}

export { MarketFilter };
