/**
 * HTTP Method: POST
 * Pathname: /getFiles
 * @function getFiles
 * @memberof WebserverFilesAPI
 * @param {WebserverFilesAPIGetFilesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetFilesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { GetFilesResponse } from "@ender/shared/generated/ender.api.files.response";
import type {
  WebserverFilesServiceFileUploadType,
  WebserverFilesServiceSubFolder,
} from "@ender/shared/generated/ender.service.files";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type WebserverFilesAPIGetFilesSearchParams = {
  token?: string | undefined;
};

type WebserverFilesAPIGetFilesBodyParams = {
  modelId: EnderId;
  modelType: ModelType;
  subFolder?: WebserverFilesServiceSubFolder | undefined;
  uploadType?: WebserverFilesServiceFileUploadType | undefined;
};

type WebserverFilesAPIGetFilesPayload = WebserverFilesAPIGetFilesSearchParams &
  WebserverFilesAPIGetFilesBodyParams;

function getFilesUnsafeEffect(payload: WebserverFilesAPIGetFilesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetFilesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetFilesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getFiles",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFiles(
  payload: WebserverFilesAPIGetFilesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetFilesResponse> {
  return F.pipe(
    payload,
    getFilesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFiles };
export type {
  WebserverFilesAPIGetFilesBodyParams,
  WebserverFilesAPIGetFilesPayload,
  WebserverFilesAPIGetFilesSearchParams,
};
