/**
 * Returns money transfer deposits, denoted by a fromParty = Lease, toParty = Firm, and status = normal
 * HTTP Method: POST
 * Pathname: /getDeposits
 * @function getDeposits
 * @memberof PaymentsAPI
 * @param {PaymentsAPIGetDepositsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<DepositsReportResponseDepositsReportResponseRow[]>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { MoneyTransferTransferType } from "@ender/shared/generated/ender.model.payments";
import type { DepositsReportResponseDepositsReportResponseRow } from "@ender/shared/generated/ender.service.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PaymentsAPIGetDepositsSearchParams = {
  token?: string | undefined;
};

type PaymentsAPIGetDepositsBodyParams = {
  amountMatch?: SerializesInto<Money> | undefined;
  /**
   * For date filtering. If null, allows for deposits infinitely far forward in time.
   */
  endDateInclusive?: SerializesInto<LocalDate> | undefined;
  firmId?: EnderId | undefined;
  fundIds: EnderId[];
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
  paymentType?: MoneyTransferTransferType | undefined;
  periods: LocalDate[];
  propertyIds: EnderId[];
  /**
   * For date filtering. If null, allows for deposits infinitely far back in time.
   */
  startDate?: SerializesInto<LocalDate> | undefined;
};

type PaymentsAPIGetDepositsPayload = PaymentsAPIGetDepositsSearchParams &
  PaymentsAPIGetDepositsBodyParams;

function getDepositsUnsafeEffect(payload: PaymentsAPIGetDepositsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, DepositsReportResponseDepositsReportResponseRow[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          DepositsReportResponseDepositsReportResponseRow[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getDeposits",
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function getDeposits(
  payload: PaymentsAPIGetDepositsPayload,
  options?: { signal?: AbortSignal },
): Promise<DepositsReportResponseDepositsReportResponseRow[]> {
  return F.pipe(
    payload,
    getDepositsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDeposits };
export type {
  PaymentsAPIGetDepositsBodyParams,
  PaymentsAPIGetDepositsPayload,
  PaymentsAPIGetDepositsSearchParams,
};
