import { Align, Justify } from "@ender/shared/ds/flex";
import { Grid } from "@ender/shared/ds/grid";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { UnitAreaUnitAreaTypeEnum } from "@ender/shared/generated/ender.model.core.unit.area";

import { AddZoneToAreaButton } from "./add-zone-to-area-button";
import { InspectionAreaFields } from "./inspection-area";
import type { UnitAreaDiscriminant } from "./unit-area.types";
import { ZonesList } from "./zones-list";

type InspectionZoneCardsProps = {
  area: UnitAreaDiscriminant;
};

function InspectionZoneCards(props: InspectionZoneCardsProps) {
  const { area } = props;

  return (
    <Stack>
      <Group justify={Justify.between} align={Align.center}>
        <Text size={FontSize.sm}>
          The cards below represent zones that have been identified within the{" "}
          {area.name} of the property.
        </Text>
        <AddZoneToAreaButton
          areaId={area.id}
          areaName={area.name}
          unitId={area.unitId}
        />
      </Group>
      {area.areaType == UnitAreaUnitAreaTypeEnum.PROPERTY && (
        <InspectionAreaFields area={area} />
      )}
      <Grid>
        {area.areaType !== UnitAreaUnitAreaTypeEnum.PROPERTY && (
          <InspectionAreaFields area={area} />
        )}
        <ZonesList areaId={area.id} unitId={area.unitId} />
      </Grid>
    </Stack>
  );
}

export { InspectionZoneCards };
