/**
 * HTTP Method: GET
 * Pathname: /construction/inspections/{inspectionId}/propertyDetails
 * @function getInspectionPropertyDetails
 * @memberof InspectionAPI
 * @param {InspectionAPIGetInspectionPropertyDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInspectionPropertyDetailsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetInspectionPropertyDetailsResponse } from "@ender/shared/generated/com.ender.construction.api.inspection.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIGetInspectionPropertyDetailsPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIGetInspectionPropertyDetailsSearchParams = {
  token?: string | undefined;
};

type InspectionAPIGetInspectionPropertyDetailsPayload =
  InspectionAPIGetInspectionPropertyDetailsPathParams &
    InspectionAPIGetInspectionPropertyDetailsSearchParams;

function getInspectionPropertyDetailsUnsafeEffect(
  payload: InspectionAPIGetInspectionPropertyDetailsPayload,
) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInspectionPropertyDetailsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInspectionPropertyDetailsResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/construction/inspections/${inspectionId}/propertyDetails`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInspectionPropertyDetails(
  payload: InspectionAPIGetInspectionPropertyDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInspectionPropertyDetailsResponse> {
  return F.pipe(
    payload,
    getInspectionPropertyDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInspectionPropertyDetails };
export type {
  InspectionAPIGetInspectionPropertyDetailsPathParams,
  InspectionAPIGetInspectionPropertyDetailsPayload,
  InspectionAPIGetInspectionPropertyDetailsSearchParams,
};
