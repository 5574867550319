import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TaskFrequencyValues = [
  "MONTHLY",
  "QUARTERLY",
  "BI_ANNUALLY",
  "ANNUALLY",
] as const;

const TaskFrequencyEffectSchema = Schema.Literal(...TaskFrequencyValues);

type TaskFrequency = Schema.Schema.Type<typeof TaskFrequencyEffectSchema>;

const TaskFrequencyEnum = castEnum(TaskFrequencyEffectSchema);

function randomTaskFrequency(): TaskFrequency {
  return rand(TaskFrequencyValues);
}

export {
  randomTaskFrequency,
  TaskFrequencyEffectSchema,
  TaskFrequencyEnum,
  TaskFrequencyValues,
};
export type { TaskFrequency };
