import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const LeaseIntentionValues = [
  "FRIENDLY",
  "NO_RENEW",
  "EVICT",
  "INTEND_TO_EVICT",
] as const;

const LeaseIntentionEffectSchema = Schema.Literal(...LeaseIntentionValues);

type LeaseIntention = Schema.Schema.Type<typeof LeaseIntentionEffectSchema>;

const LeaseIntentionEnum = castEnum(LeaseIntentionEffectSchema);

function randomLeaseIntention(): LeaseIntention {
  return rand(LeaseIntentionValues);
}

export {
  LeaseIntentionEffectSchema,
  LeaseIntentionEnum,
  LeaseIntentionValues,
  randomLeaseIntention,
};
export type { LeaseIntention };
