/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}
 * @function updateLease
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdateLeasePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Instant,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { UpdateLeaseRequestDepositRequest } from "@ender/shared/generated/ender.api.leasing.request";
import type { LeaseIntention } from "@ender/shared/generated/ender.model.leasing";
import type { ChargeScheduleFrequency } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdateLeasePathParams = {
  leaseId: EnderId;
};

type LeasingAPIUpdateLeaseSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdateLeaseBodyParams = {
  achReversalFee?: SerializesInto<Money> | undefined;
  actualMoveOutDate?: SerializesInto<LocalDate> | undefined;
  allowACHPayments?: boolean | undefined;
  allowOnlinePayments?: boolean | undefined;
  applicationGroupId?: EnderId | undefined;
  collectionsPausedNote?: string | undefined;
  collectionsPausedUntilDate?: SerializesInto<LocalDate> | undefined;
  conditionStatementSentTime?: SerializesInto<Instant> | undefined;
  depositReceiptSentTime?: SerializesInto<Instant> | undefined;
  deposits?: UpdateLeaseRequestDepositRequest[] | undefined;
  depositsHeldExternally?: boolean | undefined;
  expectedMoveOutDate?: SerializesInto<LocalDate> | undefined;
  firstPaymentDueDate?: SerializesInto<LocalDate> | undefined;
  frequency?: ChargeScheduleFrequency | undefined;
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  intention?: LeaseIntention | undefined;
  lateFee?: SerializesInto<Money> | undefined;
  leaseTemplateId?: EnderId | undefined;
  leasingAgentId?: EnderId | undefined;
  monthToMonthPremiumAmountOverride?: SerializesInto<Money> | undefined;
  moveInComplete?: boolean | undefined;
  moveInDate?: SerializesInto<LocalDate> | undefined;
  noticeOfMoveOutDate?: SerializesInto<LocalDate> | undefined;
  numGraceDays?: number | undefined;
  overrideWarnings?: boolean | undefined;
  reIncludeInCollections?: boolean | undefined;
  rent?: SerializesInto<Money> | undefined;
  startDate?: SerializesInto<LocalDate> | undefined;
  tenantInPlace?: boolean | undefined;
};

type LeasingAPIUpdateLeasePayload = LeasingAPIUpdateLeasePathParams &
  LeasingAPIUpdateLeaseSearchParams &
  LeasingAPIUpdateLeaseBodyParams;

function updateLeaseUnsafeEffect(payload: LeasingAPIUpdateLeasePayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateLease(
  payload: LeasingAPIUpdateLeasePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateLeaseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateLease };
export type {
  LeasingAPIUpdateLeaseBodyParams,
  LeasingAPIUpdateLeasePathParams,
  LeasingAPIUpdateLeasePayload,
  LeasingAPIUpdateLeaseSearchParams,
};
