/**
 * @enum {string} CustomFieldsActionsEnum
 */
const CustomFieldsActionsEnum = {
  ADD: "ADD",
  EDIT: "EDIT",
  NONE: "NONE",
};

export { CustomFieldsActionsEnum };
