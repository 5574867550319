/**
 * HTTP Method: POST
 * Pathname: /invoices/batchMarkPaid/search
 * @function getInvoicesForBatchMarkPaid
 * @memberof InvoicesAPI
 * @param {InvoicesAPIGetInvoicesForBatchMarkPaidPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BatchMarkPaidInvoicesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BatchMarkPaidInvoicesResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIGetInvoicesForBatchMarkPaidSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIGetInvoicesForBatchMarkPaidBodyParams = {
  invoiceIds: EnderId[];
};

type InvoicesAPIGetInvoicesForBatchMarkPaidPayload =
  InvoicesAPIGetInvoicesForBatchMarkPaidSearchParams &
    InvoicesAPIGetInvoicesForBatchMarkPaidBodyParams;

function getInvoicesForBatchMarkPaidUnsafeEffect(
  payload: InvoicesAPIGetInvoicesForBatchMarkPaidPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BatchMarkPaidInvoicesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BatchMarkPaidInvoicesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/batchMarkPaid/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoicesForBatchMarkPaid(
  payload: InvoicesAPIGetInvoicesForBatchMarkPaidPayload,
  options?: { signal?: AbortSignal },
): Promise<BatchMarkPaidInvoicesResponse> {
  return F.pipe(
    payload,
    getInvoicesForBatchMarkPaidUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoicesForBatchMarkPaid };
export type {
  InvoicesAPIGetInvoicesForBatchMarkPaidBodyParams,
  InvoicesAPIGetInvoicesForBatchMarkPaidPayload,
  InvoicesAPIGetInvoicesForBatchMarkPaidSearchParams,
};
