/**
 * HTTP Method: POST
 * Pathname: /vehicles/{vehicleId}
 * @function updateVehicle
 * @memberof LeasingAPI
 * @param {LeasingAPIUpdateVehiclePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPIUpdateVehiclePathParams = {
  vehicleId: EnderId;
};

type LeasingAPIUpdateVehicleSearchParams = {
  token?: string | undefined;
};

type LeasingAPIUpdateVehicleBodyParams = {
  approvalStatus?: ApprovableApprovalStatus | undefined;
  color?: string | undefined;
  licensePlateNumber?: string | undefined;
  make?: string | undefined;
  model?: string | undefined;
  parkingSpot?: string | undefined;
};

type LeasingAPIUpdateVehiclePayload = LeasingAPIUpdateVehiclePathParams &
  LeasingAPIUpdateVehicleSearchParams &
  LeasingAPIUpdateVehicleBodyParams;

function updateVehicleUnsafeEffect(payload: LeasingAPIUpdateVehiclePayload) {
  const { vehicleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vehicles/${vehicleId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateVehicle(
  payload: LeasingAPIUpdateVehiclePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateVehicleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateVehicle };
export type {
  LeasingAPIUpdateVehicleBodyParams,
  LeasingAPIUpdateVehiclePathParams,
  LeasingAPIUpdateVehiclePayload,
  LeasingAPIUpdateVehicleSearchParams,
};
