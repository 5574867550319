import { IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Array as A, Function as F } from "effect";

import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { Tuple } from "@ender/shared/ds/tuple";
import { useListState } from "@ender/shared/hooks/use-list-state";
import { EnderError } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type FileUploadModalProps = {
  uploadFiles: (files: File[]) => Promise<void>;
  /**
   * called after the upload is successful
   */
  onSuccess: () => void;
  onError?: (error: Error | EnderError) => void;
  canUpload?: boolean;
  resetError?: () => void;
  skipSuccessCall?: boolean;
  /**
   * @deprecated provide a title to the wrapping modal instead
   */
  title?: string;
};

/**
 * @deprecated move this into `widgets` as a reusable form. Combine this with `upload-form` from shared/ui/upload-rail
 * there only needs to be one
 */
function FileUploadForm({
  onSuccess,
  onError,
  uploadFiles,
  canUpload = true,
  resetError = F.constVoid,
  skipSuccessCall = false,
}: FileUploadModalProps) {
  const [files, fileHandlers] = useListState<File>([]);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: uploadFiles,
    mutationKey: ["uploadFiles"],
  });

  async function handleSubmit() {
    try {
      await mutateAsync(files);
      if (!skipSuccessCall) {
        showSuccessNotification({ message: "Files uploaded successfully" });
        onSuccess();
      }
    } catch (error) {
      if (error instanceof Error || error instanceof EnderError) {
        onError?.(error);
      }
    }
  }

  const handleFileChange = (newFiles: File[]) => {
    fileHandlers.setState(newFiles);
    resetError();
  };

  function getDisabledTooltip() {
    if (!canUpload) {
      return "You do not have permission to upload.";
    }
    if (A.isEmptyArray(files)) {
      return "At least one file must be selected";
    }
  }

  return (
    <Stack>
      {A.isNonEmptyArray(files) && (
        <Stack spacing={Spacing.none}>
          {files.map((file, i) => (
            <Tuple
              key={`${file.name}_${i}`}
              label={file.name}
              value={
                <ActionIcon
                  variant={ButtonVariant.transparent}
                  onClick={() => fileHandlers.remove(i)}>
                  <IconTrash />
                </ActionIcon>
              }
            />
          ))}
        </Stack>
      )}
      <FileInput value={files} onChange={handleFileChange} />
      <Group justify={Justify.end}>
        <Button
          onClick={handleSubmit}
          disabled={A.isEmptyArray(files) || !canUpload}
          loading={isLoading}
          disabledTooltip={getDisabledTooltip()}>
          Upload
        </Button>
      </Group>
    </Stack>
  );
}

export { FileUploadForm };
export type { FileUploadModalProps };
