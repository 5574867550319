/**
 * Move a GL category to a different place in the chart of accounts by specifying its new parent.
 * HTTP Method: POST
 * Pathname: /accounting/categories/{categoryId}/move
 * @function moveCategory
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPIMoveCategoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPIMoveCategoryPathParams = {
  /**
   * The ID of the category being moved.
   */
  categoryId: EnderId;
};

type GeneralLedgerAPIMoveCategorySearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPIMoveCategoryBodyParams = {
  /**
   * The ID of the desired new parent GL category.
   */
  parentId: EnderId;
};

type GeneralLedgerAPIMoveCategoryPayload =
  GeneralLedgerAPIMoveCategoryPathParams &
    GeneralLedgerAPIMoveCategorySearchParams &
    GeneralLedgerAPIMoveCategoryBodyParams;

function moveCategoryUnsafeEffect(
  payload: GeneralLedgerAPIMoveCategoryPayload,
) {
  const { categoryId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/accounting/categories/${categoryId}/move`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function moveCategory(
  payload: GeneralLedgerAPIMoveCategoryPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    moveCategoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { moveCategory };
export type {
  GeneralLedgerAPIMoveCategoryBodyParams,
  GeneralLedgerAPIMoveCategoryPathParams,
  GeneralLedgerAPIMoveCategoryPayload,
  GeneralLedgerAPIMoveCategorySearchParams,
};
