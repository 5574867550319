/**
 * HTTP Method: POST
 * Pathname: /buy/boxes/{buyBoxId}
 * @function updateBuyBox
 * @memberof BuyBoxAPI
 * @param {BuyBoxAPIUpdateBuyBoxPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  Money,
  Percent,
  SerializesInto,
} from "@ender/shared/core";
import type { BuyBoxBuyBoxType } from "@ender/shared/generated/com.ender.buy.model.misc";
import type { PropertyHomeType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyBoxAPIUpdateBuyBoxPathParams = {
  buyBoxId: EnderId;
};

type BuyBoxAPIUpdateBuyBoxSearchParams = {
  token?: string | undefined;
};

type BuyBoxAPIUpdateBuyBoxBodyParams = {
  excludeCities: string[];
  excludeZipcodes: string[];
  maxListPrice?: SerializesInto<Money> | undefined;
  maxLotSqft?: number | undefined;
  minBaths: number;
  minBeds: number;
  minCapRate: SerializesInto<Percent>;
  minListPrice: SerializesInto<Money>;
  minLotSqft?: number | undefined;
  minMedianIncome: SerializesInto<Money>;
  minRent: SerializesInto<Money>;
  minSchoolRating: string;
  minSqft: number;
  minYearBuilt: number;
  propertyTypes: PropertyHomeType[];
  type?: BuyBoxBuyBoxType | undefined;
};

type BuyBoxAPIUpdateBuyBoxPayload = BuyBoxAPIUpdateBuyBoxPathParams &
  BuyBoxAPIUpdateBuyBoxSearchParams &
  BuyBoxAPIUpdateBuyBoxBodyParams;

function updateBuyBoxUnsafeEffect(payload: BuyBoxAPIUpdateBuyBoxPayload) {
  const { buyBoxId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/buy/boxes/${buyBoxId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateBuyBox(
  payload: BuyBoxAPIUpdateBuyBoxPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateBuyBoxUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateBuyBox };
export type {
  BuyBoxAPIUpdateBuyBoxBodyParams,
  BuyBoxAPIUpdateBuyBoxPathParams,
  BuyBoxAPIUpdateBuyBoxPayload,
  BuyBoxAPIUpdateBuyBoxSearchParams,
};
