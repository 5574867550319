// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Indicator, Root } from "@radix-ui/react-checkbox";
import { IconCheck, IconMinus } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import { clsx } from "clsx";
import type { ReactNode } from "react";
import { forwardRef, useId } from "react";

import { Align, Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";
import { isEmptyReactNode } from "../../../utils";

type CheckboxProps = {
  label?: ReactNode;
  /**
   * whether to hide the label. A label should still be provided
   * for accessibility purposes and test location
   */
  hideLabel?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  indeterminate?: boolean;
  name?: string;
};
const CheckboxVariantGenerator = cva(
  [
    "peer h-4 w-4 shrink-0 cursor-pointer rounded border text-white flex justify-center items-center",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
    "disabled:border-gray-200 disabled:cursor-default disabled:bg-gray-200",
  ],
  {
    compoundVariants: [],
    defaultVariants: {
      value: false,
    },
    variants: {
      value: {
        false: ["bg-white border-slate-200 "],
        true: ["bg-primary-500 border-primary-500"],
      },
    },
  },
);

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  function Checkbox(props, ref) {
    //setting default `value` to false helps surface an error case where Mantine getInputProps is using type:"checkbox" and the value is undefined.
    //If you are here because your checkbox won't toggle, check the value prop is being set correctly! don't use `type:"checkbox"` in your getInputProps!
    const {
      label,
      value = false,
      onChange,
      indeterminate = false,
      disabled,
      name,
      hideLabel,
    } = props;
    const id = useId();
    return (
      <Group spacing={Spacing.sm} align={Align.center} noWrap>
        <Root
          className={CheckboxVariantGenerator(props)}
          checked={value}
          name={name}
          onCheckedChange={onChange}
          ref={ref}
          id={id}
          disabled={disabled}>
          <Indicator>
            {indeterminate ? (
              <IconMinus
                size={14}
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeWidth={3}
              />
            ) : (
              <IconCheck
                size={14}
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeWidth={3}
              />
            )}
          </Indicator>
        </Root>
        {!isEmptyReactNode(label) && (
          <label
            htmlFor={id}
            className={clsx("text-slate-900 peer-disabled:text-gray-200", {
              hidden: hideLabel,
            })}>
            {label}
          </label>
        )}
      </Group>
    );
  },
);

export { Checkbox };

export type { CheckboxProps };
