import {
  Array as A,
  Function as F,
  Match,
  Number as Num,
  Option as O,
  String as Str,
} from "effect";
import { useCallback, useMemo, useState } from "react";

import { TriggerButton, TriggerButtonSize } from "@ender/shared/ds/menu";
import type { SelectOption } from "@ender/shared/ds/select";
import type { CollectionsStepCollectionsActionType } from "@ender/shared/generated/ender.model.misc";
import { CollectionsStepCollectionsActionTypeValues } from "@ender/shared/generated/ender.model.misc";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";
import { MultiFilter } from "@ender/widgets/filters/multi-filter";

const ANY_ACTION_NEEDED = "ANY_ACTION_NEEDED" as const;
type CollectionActionTypesWithAny =
  | CollectionsStepCollectionsActionType
  | typeof ANY_ACTION_NEEDED;
const CollectionActionValuesWithAny = [
  ANY_ACTION_NEEDED,
  ...CollectionsStepCollectionsActionTypeValues,
] as const;

type ActionNeededFilterProps = {
  value: CollectionsStepCollectionsActionType[];
  onChange: (value: CollectionsStepCollectionsActionType[]) => void;
  onlyShowActionsNeeded: boolean;
  onOnlyShowActionsNeeded: (val: boolean) => void;
};

function ActionsNeededFilter(props: ActionNeededFilterProps) {
  const { value, onChange, onlyShowActionsNeeded, onOnlyShowActionsNeeded } =
    props;
  const [keyword, setKeywordValue] = useState<string>("");

  const options: SelectOption<CollectionActionTypesWithAny>[] = F.pipe(
    CollectionActionValuesWithAny,
    A.map((meta) => ({
      label: convertSnakeCaseToTitleCase(meta),
      meta,
      value: meta,
    })),
  );

  const filteredOptions = useMemo(() => {
    const searchText = Str.toUpperCase(keyword);
    return F.pipe(
      options,
      A.filter((opt) =>
        F.pipe(opt.label, Str.toUpperCase, Str.includes(searchText)),
      ),
    );
  }, [keyword, options]);

  const selected = useMemo(
    () =>
      F.pipe(
        value,
        // if onlyShowActionsNeeded is true but no actions have been selected, force the "Any Action Needed" option to be selected
        (arr) =>
          onlyShowActionsNeeded && A.isEmptyArray(arr)
            ? A.append(ANY_ACTION_NEEDED)(arr)
            : arr,
        A.map((meta) => ({
          label: convertSnakeCaseToTitleCase(meta),
          meta,
          value: meta,
        })),
      ),
    [onlyShowActionsNeeded, value],
  );

  const handleChange = useCallback(
    (selected: SelectOption<CollectionActionTypesWithAny>[]) => {
      const selectedActions = F.pipe(
        selected,
        A.map((opt) => opt.value),
      );

      // as long as any checkbox is checked, we want the `onlyActionNeeded` set to true in the payload
      onOnlyShowActionsNeeded(A.isNonEmptyArray(selectedActions));

      // do not send "ANY_ACTION_NEEDED" in the collectionActionTypes payload as it isn't a valid action type
      onChange(
        selectedActions.filter((action) => action !== ANY_ACTION_NEEDED),
      );
    },
    [onChange, onOnlyShowActionsNeeded],
  );

  const triggerLabel = useMemo(() => {
    const headLabel = F.pipe(
      selected,
      A.head,
      O.map((opt) => `: ${opt.label}`),
      O.getOrElse(F.constant("")),
    );
    const countLabel = Match.value(A.length(selected)).pipe(
      Match.when(Num.greaterThan(1), (size) => ` +${size - 1}`),
      Match.orElse(F.constant("")),
    );
    return `Action${headLabel}${countLabel}`;
  }, [selected]);

  return (
    <MultiFilter
      label="Actions Needed Filter"
      data={filteredOptions}
      keyword={keyword}
      onChange={handleChange}
      onKeywordChange={setKeywordValue}
      trigger={
        <TriggerButton rounded={false} size={TriggerButtonSize.sm}>
          {triggerLabel}
        </TriggerButton>
      }
      value={selected}
    />
  );
}

export { ActionsNeededFilter };
export type { ActionNeededFilterProps };
