import { IconAlertCircle } from "@tabler/icons-react";
import { Predicate as P } from "effect";

import { InvoiceCounterpartyTuple } from "@ender/entities/invoice-counterparty-tuple";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { INVOICE_PAYABLE_TYPE_DISPLAY_NAMES } from "@ender/shared/constants/invoices";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$ } from "@ender/shared/core";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { RouterLink } from "@ender/shared/ds/router-link";
import { Stack } from "@ender/shared/ds/stack";
import { FontWeight, Text } from "@ender/shared/ds/text";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import type { InvoiceSerializerInvoiceResponse } from "@ender/shared/generated/ender.arch.accounting";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { getPropertyShortNameFromInvoice } from "@ender/shared/utils/invoice-utils";
import {
  formatLongSlashDateAndDaysFromToday,
  toLongSlashString,
  toSlashString,
} from "@ender/shared/utils/local-date";

import { DuplicateInvoiceIcon } from "../duplicate-invoice-icon";
import { EditInvoiceButton } from "./edit-invoice-button";
import { LeaseTenants } from "./lease-tenants";

type AccountingPeriodValueProps = {
  isAccountingPeriodClosed: boolean;
  value: string;
};

function AccountingPeriodValue({
  isAccountingPeriodClosed,
  value,
}: AccountingPeriodValueProps) {
  const textColor = isAccountingPeriodClosed ? "red-500" : UNDEFINED;

  return (
    <Tooltip
      disabled={!isAccountingPeriodClosed}
      label="This accounting period is closed for your user type">
      <Group align={Align.center} spacing={Spacing.xs}>
        <Text color={textColor} weight={FontWeight.medium}>
          {value}
        </Text>
        {isAccountingPeriodClosed && (
          <Text color="red-500">
            <IconAlertCircle size={12} />
          </Text>
        )}
      </Group>
    </Tooltip>
  );
}

type PropertyValueProps = {
  label: string;
  propertyId?: EnderId;
};

function PropertyValue({ label, propertyId }: PropertyValueProps) {
  if (P.isNullable(propertyId)) {
    return label;
  }

  return (
    <RouterLink href={`/properties/${propertyId}`} target="_blank">
      {label}
    </RouterLink>
  );
}

const MANAGEMENT_FEE_DESCRIPTION = "Management Fee";

type InvoiceDetailsProps = {
  invoice: InvoiceSerializerInvoiceResponse;
  isAccountingPeriodClosed: boolean;
  isEditable?: boolean;
  onSuccess: () => void;
};

function InvoiceDetails({
  invoice,
  isAccountingPeriodClosed,
  isEditable = true,
  onSuccess,
}: InvoiceDetailsProps) {
  if (P.isNullable(invoice)) {
    return NULL;
  }

  const {
    accountingPeriod,
    amount,
    author,
    currentState,
    date,
    dueDate,
    description,
    externalInvoiceId,
    invoiceType,
    isDupExternalId,
    lease,
    owedByParty,
    owedToParty,
    payableType,
    property,
    poNumbers,
    source,
    transactionDate,
    unit,
  } = invoice;
  if (description === MANAGEMENT_FEE_DESCRIPTION) {
    return (
      <Tuple
        label="Management Fee"
        value={toSlashString(LocalDate$.of(date))}
      />
    );
  }

  const isPayable = invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;
  const createdStep = currentState.timeline.find(
    (step) => step.decision === "Created",
  );
  const systemDate = createdStep?.date
    ? toLongSlashString(LocalDate$.of(createdStep.date))
    : "";
  /**
   * # Values for Firm / "us":
   * - in AP (isPayable === true) is owedByParty/"payer"
   * - in AR (isPayable !== true) is owedToParty/"payee"
   */
  const firmName = isPayable ? owedByParty?.name : owedToParty?.name;

  const purchaseOrderNumbers = poNumbers
    ?.map((num) => num.toString().padStart(4, "0"))
    .join(", ");
  return (
    <>
      <Group noWrap justify={Justify.between}>
        <H3>Invoice Details</H3>
        {isEditable && (
          <EditInvoiceButton invoice={invoice} onSuccess={onSuccess} />
        )}
      </Group>
      <Stack spacing={Spacing.none}>
        <Tuple
          label="Invoice Number"
          value={
            <Group spacing={Spacing.sm}>
              {externalInvoiceId}
              {isDupExternalId && <DuplicateInvoiceIcon />}
            </Group>
          }
        />
        <Tuple label="Amount" value={amount} />
        <Tuple label="Firm" value={firmName} />
        <Tuple
          label="Property"
          value={
            <PropertyValue
              label={getPropertyShortNameFromInvoice(invoice)}
              propertyId={property?.id}
            />
          }
        />
        <Tuple label="Unit" value={unit?.name} />
        <Tuple
          label="Lease"
          value={
            P.isNotNullable(lease?.id) ? (
              <LeaseTenants leaseId={lease?.id} />
            ) : (
              NULL
            )
          }
        />
        <InvoiceCounterpartyTuple invoice={invoice} />
        <Tuple
          label="Task"
          value={
            P.isNotNullable(invoice.task) ? (
              <RouterLink href={`/tasks/${invoice.task.id}`}>
                {/* @ts-expect-error InvoiceSerializerInvoiceResponse should have invoice.task.title string */}
                {invoice.task.title}
                {invoice.task.shortName && <> ({invoice.task.shortName})</>}
              </RouterLink>
            ) : (
              NULL
            )
          }
        />
        <Tuple
          label="Internal Assignee"
          value={invoice.task?.internalAssigneeName ?? "--"}
        />
        <Tuple
          label="Transaction Date"
          value={transactionDate ? toLongSlashString(transactionDate) : ""}
        />
        {isPayable && (
          <Tuple label="Purchase Order Numbers" value={purchaseOrderNumbers} />
        )}

        <Tuple
          label="Accounting Period"
          value={
            <AccountingPeriodValue
              isAccountingPeriodClosed={isAccountingPeriodClosed}
              value={accountingPeriod}
            />
          }
        />
        <Tuple
          label="Due Date"
          value={formatLongSlashDateAndDaysFromToday(dueDate)}
        />
        <Tuple label="System Date" value={systemDate} />
        <Tuple label="Description" value={description} />
        <Tuple
          label="Invoice Type"
          value={
            !P.isNullable(payableType)
              ? INVOICE_PAYABLE_TYPE_DISPLAY_NAMES[payableType]
              : NULL
          }
        />
        <Tuple label="Source" value={source} />
        <Tuple label="Author" value={author?.name} />
      </Stack>
    </>
  );
}

export { InvoiceDetails };
