import { Schema } from "@effect/schema";
import { useQuery } from "@tanstack/react-query";
import { Option as O, pipe } from "effect";
import { useEffect } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Modal, ModalSize } from "@ender/shared/ds/modal";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormSelect } from "@ender/shared/ds/select";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, Text } from "@ender/shared/ds/text";
import { BuyAPI, MarketsAPI } from "@ender/shared/generated/com.ender.buy.api";
import { fail } from "@ender/shared/utils/error";
import { getAndDownloadBlobData } from "@ender/shared/utils/general";
import { rest } from "@ender/shared/utils/rest";

const ExportAttomDataFormSchema = Schema.Struct({
  cbsaCode: Schema.String.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  maxTaxAssessedValue: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
  minTaxAssessedValue: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Required" }),
  ),
}).pipe(
  Schema.filter((schema) => {
    const issues: Schema.FilterIssue[] = [];
    if (
      O.getOrder(Money$.Order)(
        schema.minTaxAssessedValue,
        schema.maxTaxAssessedValue,
      ) > 0
    ) {
      issues.push({
        message: "Must be greater than Min Tax Value",
        path: ["maxTaxAssessedValue"],
      });
      issues.push({
        message: "Must be less than Max Tax Value",
        path: ["minTaxAssessedValue"],
      });
    }
    return issues;
  }),
);
type ExportAttomDataFormOutput = Schema.Schema.Type<
  typeof ExportAttomDataFormSchema
>;

function ExportAttomDataModal({
  opened,
  close,
}: {
  opened: boolean;
  close: () => void;
}) {
  const confirmation = useConfirmationContext();
  const { data: cbsaOptions = [], isLoading } = useQuery({
    queryFn: ({ signal }) => MarketsAPI.getCBSAs({}, { signal }),
    queryKey: ["MarketsAPI.getCBSAs"] as const,
    select: (data: { name: string; id: string }[]) =>
      data
        .filter((cbsa) => cbsa?.id)
        .map((cbsa) => ({
          label: cbsa.name,
          value: cbsa.id,
        })),
  });
  const form = useEffectSchemaForm({
    defaultValues: {
      cbsaCode: O.none(),
      maxTaxAssessedValue: O.none(),
      minTaxAssessedValue: O.none(),
    },
    schema: ExportAttomDataFormSchema,
  });

  const { reset } = form;
  // lazy useEffect
  useEffect(() => {
    reset();
  }, [opened, reset]);

  async function handleSubmit(values: ExportAttomDataFormOutput) {
    const { numOfRows } = await BuyAPI.getAssessorFileSize({
      cbsaCode: pipe(values.cbsaCode, O.getOrThrow),
      maxTaxAssessedValue: pipe(
        values.maxTaxAssessedValue,
        O.map((v) => v.toJSON()),
        O.getOrThrow,
      ),
      minTaxAssessedValue: pipe(
        values.minTaxAssessedValue,
        O.map((v) => v.toJSON()),
        O.getOrThrow,
      ),
    });
    try {
      if (numOfRows > 10000) {
        await confirmation({
          confirmButtonLabel: "Proceed",
          content: (
            <Text size={FontSize.sm}>
              With current exporting params, you are to download 10,000 rows of
              data. This will be very time consuming. Do you want to continue?
            </Text>
          ),
          title: "Proceed with Download?",
        });
      }

      // TODO - update this to use BuyAPI.exportAssessorFile once REST service properly handles files.
      await getAndDownloadBlobData(
        () =>
          rest.post(
            "/buy/attom/assessor.csv.zip",
            {
              cbsaCode: pipe(values.cbsaCode, O.getOrThrow),
              maxTaxAssessedValue: pipe(
                values.maxTaxAssessedValue,
                O.map((v) => v.toJSON()),
                O.getOrThrow,
              ),
              minTaxAssessedValue: pipe(
                values.minTaxAssessedValue,
                O.map((v) => v.toJSON()),
                O.getOrThrow,
              ),
            },
            { download: true },
          ),
        {
          filenameOverride: `Attom Data ${new Date().toLocaleString()}.zip`,
          message: "Downloading...",
        },
      );

      close();
    } catch (err) {
      fail(err);
    }
  }

  return (
    <Modal
      title="Export Attom Data"
      size={ModalSize.sm}
      opened={opened}
      onClose={close}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack>
          <Skeleton visible={isLoading}>
            <FormSelect
              form={form}
              name="cbsaCode"
              label="CBSA"
              data={cbsaOptions}
            />
          </Skeleton>
          <FormMoneyInput
            form={form}
            label="Min Tax Value"
            name="minTaxAssessedValue"
          />
          <FormMoneyInput
            form={form}
            name="maxTaxAssessedValue"
            label="Max Tax Value"
          />
          <Group spacing={Spacing.sm} justify={Justify.end}>
            <Button variant={ButtonVariant.outlined} onClick={close}>
              Close
            </Button>
            <Button type="submit">Export</Button>
          </Group>
        </Stack>
      </Form>
    </Modal>
  );
}

export { ExportAttomDataModal };
