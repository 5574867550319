/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/fullPaymentRequiredInfo
 * @function getFullPaymentRequiredInfo
 * @memberof LeasingMiddleLayerAPI
 * @param {LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetFullPaymentRequiredInfoResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetFullPaymentRequiredInfoResponse } from "@ender/shared/generated/com.ender.middle.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPathParams = {
  leaseId: EnderId;
};

type LeasingMiddleLayerAPIGetFullPaymentRequiredInfoSearchParams = {
  token?: string | undefined;
};

type LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPayload =
  LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPathParams &
    LeasingMiddleLayerAPIGetFullPaymentRequiredInfoSearchParams;

function getFullPaymentRequiredInfoUnsafeEffect(
  payload: LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetFullPaymentRequiredInfoResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetFullPaymentRequiredInfoResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/fullPaymentRequiredInfo`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFullPaymentRequiredInfo(
  payload: LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPayload,
  options?: { signal?: AbortSignal },
): Promise<GetFullPaymentRequiredInfoResponse> {
  return F.pipe(
    payload,
    getFullPaymentRequiredInfoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFullPaymentRequiredInfo };
export type {
  LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPathParams,
  LeasingMiddleLayerAPIGetFullPaymentRequiredInfoPayload,
  LeasingMiddleLayerAPIGetFullPaymentRequiredInfoSearchParams,
};
