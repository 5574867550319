/**
 * This API endpoint was created for Conservice to use. It will remain to be seen if it is useful for anyone else
 * HTTP Method: POST
 * Pathname: /leaseChains/search
 * @function searchLeaseChains
 * @memberof LeasingAPI
 * @param {LeasingAPISearchLeaseChainsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchLeaseChainsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SearchLeaseChainsResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LeasingAPISearchLeaseChainsSearchParams = {
  token?: string | undefined;
};

type LeasingAPISearchLeaseChainsBodyParams = {
  propertyIds: EnderId[];
};

type LeasingAPISearchLeaseChainsPayload =
  LeasingAPISearchLeaseChainsSearchParams &
    LeasingAPISearchLeaseChainsBodyParams;

function searchLeaseChainsUnsafeEffect(
  payload: LeasingAPISearchLeaseChainsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchLeaseChainsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<SearchLeaseChainsResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/leaseChains/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchLeaseChains(
  payload: LeasingAPISearchLeaseChainsPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchLeaseChainsResponse[]> {
  return F.pipe(
    payload,
    searchLeaseChainsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchLeaseChains };
export type {
  LeasingAPISearchLeaseChainsBodyParams,
  LeasingAPISearchLeaseChainsPayload,
  LeasingAPISearchLeaseChainsSearchParams,
};
