/**
 * HTTP Method: POST
 * Pathname: /createRecurringGLJournalEntry
 * @function createRecurringGLJournalEntry
 * @memberof AccountingAPI
 * @param {AccountingAPICreateRecurringGLJournalEntryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { CreateRecurringGLJournalEntryRequestTX } from "@ender/shared/generated/ender.api.accounting.request";
import type { RecurringGLJournalEntryFrequency } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingAPICreateRecurringGLJournalEntrySearchParams = {
  token?: string | undefined;
};

type AccountingAPICreateRecurringGLJournalEntryBodyParams = {
  approverId?: EnderId | undefined;
  endDate?: SerializesInto<LocalDate> | undefined;
  frequency: RecurringGLJournalEntryFrequency;
  startDate: SerializesInto<LocalDate>;
  title: string;
  txs: CreateRecurringGLJournalEntryRequestTX[];
};

type AccountingAPICreateRecurringGLJournalEntryPayload =
  AccountingAPICreateRecurringGLJournalEntrySearchParams &
    AccountingAPICreateRecurringGLJournalEntryBodyParams;

function createRecurringGLJournalEntryUnsafeEffect(
  payload: AccountingAPICreateRecurringGLJournalEntryPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/createRecurringGLJournalEntry",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createRecurringGLJournalEntry(
  payload: AccountingAPICreateRecurringGLJournalEntryPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    createRecurringGLJournalEntryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createRecurringGLJournalEntry };
export type {
  AccountingAPICreateRecurringGLJournalEntryBodyParams,
  AccountingAPICreateRecurringGLJournalEntryPayload,
  AccountingAPICreateRecurringGLJournalEntrySearchParams,
};
