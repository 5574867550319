import { DealStatusPipelineStatusEnum } from "@ender/shared/generated/com.ender.buy.model.misc";

const QUERY_KEY = {
  ASSIGNED_PROPERTY_IDS: "assigned-property-ids",
  ASSIGNED_UNDERWRITINGS_WIDGET: "assigned-underwritings-widget",
  ASSIGNED_UNDERWRITING_FILTER_METADATA:
    "assigned-underwriting-filter-metadata",
  ASSIGNED_UNDERWRITING_FILTER_WIDGET: "assigned-underwriting-filter-widget",
  UNDERWRITING_FILTER_METADATA: "underwriting-filter-metadata",
  UNDERWRITING_FILTER_WIDGET: "underwriting-filter-rail-metadata",
  UNDERWRITING_QUEUE_GET_USERS: "underwriting-queue-get-users",
  UNDERWRITING_QUEUE_MARKETS: "underwriting-queue-markets",
} as const;

const DEFAULT_UW_STATUS_FILTER_VALUES = [
  { status: DealStatusPipelineStatusEnum.LEAD },
  {
    status: DealStatusPipelineStatusEnum.OFFER,
    subStatus: "Need to Underwrite",
  },
];

export { DEFAULT_UW_STATUS_FILTER_VALUES, QUERY_KEY };
