import { Array as A, Predicate as P } from "effect";

import { NULL } from "@ender/shared/constants/general";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  FactorsAPI,
  ReportsAPI,
} from "@ender/shared/generated/ender.api.reports";
import { fail } from "@ender/shared/utils/error";

import type {
  FactorValue,
  Widget,
  WidgetFactor,
  WidgetFilter,
  WidgetFilterOperator,
} from "./filter-fields/filter-types";

type RequiredFilter = {
  factorName: FactorValue;
  operator: WidgetFilterOperator;
  values: unknown[];
};

type GetWidgetOptions = {
  requiredYFactors?: {
    factorNames: FactorValue[];
    keepOrder?: boolean;
  };
  requiredFilters?: RequiredFilter[];
};

function verifyYFactors(
  widget: Widget,
  yFactors: FactorValue[],
  keepOrder: boolean,
) {
  return (
    widget.yFactors.every(({ name }, index) => {
      if (keepOrder) {
        return name === yFactors[index];
      }

      return yFactors.includes(name);
    }) && widget.yFactors.length === yFactors.length
  );
}

async function verifyRequiredYFactors(
  widget: Widget,
  requiredYFactors: FactorValue[],
  keepOrder = false,
) {
  if (verifyYFactors(widget, requiredYFactors, keepOrder)) {
    return widget;
  }

  const allFields = await FactorsAPI.searchFactors({
    builtInFactors: true,
    customFactors: true,
    inputTypes: [ModelTypeEnum.DEAL],
  });

  const newYFactors: WidgetFactor[] = await Promise.all(
    requiredYFactors.map(async (factorName) => {
      const existingYFactor = widget.yFactors.find(
        ({ name }) => name === factorName,
      );

      if (existingYFactor) {
        return existingYFactor;
      }

      const field = allFields.find(({ name }) => factorName === name);

      if (!field) {
        return null;
      }

      if (!field.id) {
        const factor = await FactorsAPI.newFactor({
          factorType: field.factorType,
        });
        return factor;
      }

      return field;
    }),
  );

  widget.yFactors = newYFactors.filter(Boolean);
}

function verifyRequiredFilters(
  widget: Widget,
  requiredFilters: RequiredFilter[],
) {
  const missingFilters = requiredFilters.filter(
    ({ factorName }) =>
      !widget.filters.some((filter) => filter.factor.name === factorName),
  );
  if (A.isEmptyArray(missingFilters)) {
    return widget;
  }

  widget.filters.push(
    ...missingFilters.map<WidgetFilter>(({ factorName, operator, values }) => ({
      // @ts-expect-error does not expect type of undefined
      factor: widget.yFactors.find(({ name }) => name === factorName),
      operator,
      // @ts-expect-error does not expect type of unknown
      values,
    })),
  );
}

function widgetToUpdateRequest(widget: Widget) {
  return {
    filters: widget.filters.map(({ factor, operator, values }) => ({
      factorId: factor.id,
      modelType: ModelTypeEnum.DEAL,
      operator,
      values,
    })),
    ...(widget.sortByFactor ? { sortByFactor: widget.sortByFactor.id } : {}),
    sortOrder: widget.sortOrder || "ASCENDING",
    xFactors: widget.xFactors.map(({ id }) => id),
    yFactors: widget.yFactors.map(({ id }) => id),
  };
}

async function getWidget(
  widgetName: string,
  options?: GetWidgetOptions,
  signal?: AbortSignal,
): Promise<Widget> {
  try {
    // @ts-expect-error type mismatch on API response
    const widget: Widget = await ReportsAPI.getWidget(
      { widgetName },
      { signal },
    );

    if (P.isUndefined(options)) {
      return widget;
    }

    if (options.requiredYFactors) {
      await verifyRequiredYFactors(
        widget,
        options.requiredYFactors.factorNames,
        options.requiredYFactors.keepOrder,
      );
    }

    if (options.requiredFilters) {
      verifyRequiredFilters(widget, options.requiredFilters);
    }

    return await ReportsAPI.updateWidget({
      widgetId: widget.id,
      ...widgetToUpdateRequest(widget),
    });
  } catch (e) {
    fail(e);

    // @ts-expect-error type mismatching
    return NULL;
  }
}

function getYFactor(
  widget: Widget,
  factorName: FactorValue,
): WidgetFactor | undefined {
  return widget?.yFactors?.find(({ name }) => name === factorName);
}

function removeFilter(widget: Widget, factorName: FactorValue): void {
  widget.filters = widget.filters.filter(
    ({ factor }) => factor.name !== factorName,
  );
}

export { getWidget, getYFactor, removeFilter, widgetToUpdateRequest };
