import { Function as F, Match, Number as Num, Option as O } from "effect";
import * as A from "effect/Array";

function formatMultiFilterLabel(
  values: string[],
  defaultLabel: string,
): string {
  if (A.isEmptyArray(values)) {
    return defaultLabel;
  }

  const headLabel = F.pipe(values, A.head, O.getOrElse(F.constant("")));

  const countLabel = Match.value(A.length(values)).pipe(
    Match.when(Num.greaterThan(1), (size) => ` (+${size - 1})`),
    Match.orElse(F.constant("")),
  );

  return `${headLabel}${countLabel}`;
}

export { formatMultiFilterLabel };
