/**
 * Create beneficial owners when PM links bank account
 * HTTP Method: POST
 * Pathname: /dwolla/beneficialOwners
 * @function dwollaBeneficialOwners
 * @memberof DwollaAPI
 * @param {DwollaAPIDwollaBeneficialOwnersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { BeneficiaryOwner } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type DwollaAPIDwollaBeneficialOwnersSearchParams = {
  token?: string | undefined;
};

type DwollaAPIDwollaBeneficialOwnersBodyParams = {
  /**
   * Information of beneficial owners. Optional.
   */
  beneficialOwners: BeneficiaryOwner[];
  /**
   * EnderId of the model
   */
  modelId: EnderId;
  /**
   * Type of the owner model
   */
  modelType: ModelType;
};

type DwollaAPIDwollaBeneficialOwnersPayload =
  DwollaAPIDwollaBeneficialOwnersSearchParams &
    DwollaAPIDwollaBeneficialOwnersBodyParams;

function dwollaBeneficialOwnersUnsafeEffect(
  payload: DwollaAPIDwollaBeneficialOwnersPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/dwolla/beneficialOwners",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function dwollaBeneficialOwners(
  payload: DwollaAPIDwollaBeneficialOwnersPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    dwollaBeneficialOwnersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { dwollaBeneficialOwners };
export type {
  DwollaAPIDwollaBeneficialOwnersBodyParams,
  DwollaAPIDwollaBeneficialOwnersPayload,
  DwollaAPIDwollaBeneficialOwnersSearchParams,
};
