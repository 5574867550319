import { Option as O, Predicate as P, String as S, pipe } from "effect";

import type { Instant, LocalDate } from "@ender/shared/core";
import { Instant$, LocalDate$ } from "@ender/shared/core";
import { DateDisplay } from "@ender/shared/ds/date-display";
import { RouterLink } from "@ender/shared/ds/router-link";
import type { ColumnDef } from "@ender/shared/ui/table-tanstack";
import { asColumnDef } from "@ender/shared/ui/table-tanstack";

import type { AllApplicationsRow } from "./all-applications-table.types";
import { ActionsRequiredCell } from "./cells/actions-required-cell/actions-required-cell";
import { ApplicantNamesCell } from "./cells/applicant-names-cell/applicant-names-cell";
import { ItemsReviewedCell } from "./cells/items-reviewed-cell/items-reviewed-cell";
import { ItemsSubmittedCell } from "./cells/items-submitted-cell/items-submitted-cell";
import { StatusCell } from "./cells/status-cell/status-cell";

const columns: ColumnDef<AllApplicationsRow>[] = [
  asColumnDef<AllApplicationsRow>({
    accessorKey: "marketName",
    cell: (props) => props.getValue() || "-",
    enableSorting: false,
    header: "Market",
    id: "market",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "ownershipGroupName",
    cell: (props) => props.getValue() || "-",
    enableSorting: false,
    header: "Ownership Group",
    id: "ownershipGroup",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "applicantNames",
    cell: (props) => (
      <ApplicantNamesCell
        applicantNames={props.getValue()}
        applicationGroupId={props.row.original.applicationGroupId}
      />
    ),
    enableSorting: false,
    header: "Applicant(s)",
    maxSize: 300,
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "propertyName",
    cell: (props) => (
      <RouterLink href={`/properties/${props.row.original.propertyId}`}>
        {props.getValue()}
      </RouterLink>
    ),
    enableSorting: false,
    header: "Property",
    id: "property",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "unitName",
    cell: (props) => props.getValue() || "--",
    enableSorting: false,
    header: "Unit",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "actionsRequired",
    cell: (props) => (
      <ActionsRequiredCell
        followUpDate={props.row.original.followUpDate}
        needsReply={props.row.original.needsReply}
      />
    ),
    enableSorting: true,
    header: "Actions Required",
    id: "actionsRequired",
  }),
  asColumnDef<AllApplicationsRow, LocalDate>({
    accessorKey: "creationDate",
    cell: (props) =>
      P.isNullable(props.getValue()) ? (
        "-"
      ) : (
        <DateDisplay value={LocalDate$.parse(props.getValue())} />
      ),
    enableSorting: true,
    header: "Date Started",
    id: "dateStarted",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "usingHousingChoiceVoucher",
    cell: (props) => (props.getValue() ? "Yes" : "No"),
    enableSorting: false,
    header: "HCV",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "status",
    cell: (props) => <StatusCell status={props.getValue()} />,
    enableSorting: false,
    header: "Status",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "itemsSubmitted",
    cell: (props) => (
      <ItemsSubmittedCell
        itemsSubmitted={props.row.original.itemsSubmitted}
        itemsTotal={props.row.original.itemsNeeded}
      />
    ),
    enableSorting: false,
    header: "Items Submitted",
    id: "itemsSubmitted",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "itemsReviewed",
    cell: (props) => (
      <ItemsReviewedCell
        itemsReviewed={props.row.original.itemsReviewed}
        reviewItemsNeeded={props.row.original.reviewItemsNeeded}
      />
    ),
    enableSorting: false,
    header: "Items Reviewed",
    id: "itemsReviewed",
  }),
  asColumnDef<AllApplicationsRow>({
    accessorKey: "condition",
    cell: (props) =>
      P.isNullable(props.getValue()) || !S.isNonEmpty(props.getValue())
        ? "None"
        : props.getValue(),
    enableSorting: false,
    header: "Condition",
  }),
  asColumnDef<AllApplicationsRow, LocalDate>({
    accessorKey: "leaseDraftedAt",
    cell: (props) =>
      P.isNullable(props.getValue()) ? (
        "-"
      ) : (
        <DateDisplay value={LocalDate$.parse(props.getValue())} />
      ),
    enableSorting: false,
    header: "Lease Drafted",
  }),
  asColumnDef<AllApplicationsRow, Instant>({
    accessorKey: "leaseSentAt",
    cell: (props) =>
      pipe(
        props.getValue(),
        Instant$.parse,
        O.map((instant) => instant.toLocalDate()),
        O.match({
          onNone: () => "-",
          onSome: (localDate) => <DateDisplay value={localDate} />,
        }),
      ),
    enableSorting: false,
    header: "Lease Sent",
  }),
];

export { columns as allApplicationsTableColumns };
