import { useMutation } from "@tanstack/react-query";
import { Function as F, Predicate as P } from "effect";
import type { ReactNode } from "react";
import { forwardRef, useCallback } from "react";

import { ApplicationApproval } from "@ender/entities/application/approval";
import type { EnderId } from "@ender/shared/core";
import { Align } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import type { ApplicationGroupApprovalResponseApprovalStatusResponse } from "@ender/shared/generated/com.ender.middle.response";
import { PetsAPI } from "@ender/shared/generated/ender.api.leasing";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";

type ApprovableCardHeaderProps = {
  approval?: ApplicationGroupApprovalResponseApprovalStatusResponse;
  heading?: ReactNode;
  onSuccess?: () => void;
  petId: EnderId;
};

const ApprovablePetCardHeader = forwardRef<
  HTMLDivElement,
  ApprovableCardHeaderProps
>(function ApprovableCardHeader(props, ref) {
  const { approval, petId, heading, onSuccess = F.constVoid } = props;

  const { mutateAsync: updatePet } = useMutation({
    mutationFn: PetsAPI.updatePet,
    mutationKey: ["PetsAPI.updatePet"] as const,
  });
  const handleApproval = useCallback(async () => {
    await updatePet({
      petId: petId,
      approvalStatus: ApprovableApprovalStatusEnum.APPROVED,
    });
    onSuccess();
  }, [petId, updatePet, onSuccess]);
  const handleRejection = useCallback(async () => {
    await updatePet({
      petId: petId,
      approvalStatus: ApprovableApprovalStatusEnum.REJECTED,
    });
    onSuccess();
  }, [petId, updatePet, onSuccess]);
  const handleUnset = useCallback(async () => {
    await updatePet({
      petId: petId,
      approvalStatus: ApprovableApprovalStatusEnum.NEW,
    });
    onSuccess();
  }, [petId, updatePet, onSuccess]);

  return (
    <Group align={Align.center} ref={ref}>
      {heading}
      {P.isNotNullable(approval) && (
        <ApplicationApproval
          approval={approval}
          onApprove={handleApproval}
          onReject={handleRejection}
          onClear={handleUnset}
        />
      )}
    </Group>
  );
});

export { ApprovablePetCardHeader };

export type { ApprovableCardHeaderProps };
