import { Option as O, String as Str, pipe } from "effect";





type EmailDisplayProps = {
  value?: string;
};

/**
 * displays an email address in a component which also provides a shortcut link to creating an email
 *
 * @deprecated This component should be relocated to a DS display
 */
function EmailDisplay({ value = "" }: EmailDisplayProps) {
  if (Str.isNonEmpty(value)) {
    const text = Str.includes("@convo")(value)
      ? `...${pipe(value, Str.indexOf("@convo"), O.getOrThrow, Str.substring)(value)}`
      : value;

    return (
      <a
        data-private
        href={`mailto:${value}`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}>
        {text}
      </a>
    );
  }

  return "--";
}

export { EmailDisplay };
