import { Option as O, Predicate as P, String as S } from "effect";

import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H3 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { TupleList } from "@ender/shared/ds/tuple";
import type { GetRecurringGLJournalEntryDetailsResponse } from "@ender/shared/generated/com.ender.middle.response";
import type {
  GetGLJournalEntryResponse,
  GetGLJournalEntryResponseApprovalStatusResponse,
} from "@ender/shared/generated/ender.api.accounting.response";
import type { GetApprovalProcessResponseStep } from "@ender/shared/generated/ender.api.misc.response";
import { ApprovableApprovalStatusEnum } from "@ender/shared/generated/ender.service.approvals";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import { convertSnakeCaseToTitleCase } from "@ender/shared/utils/string";

type ApprovalDetailsProps = {
  approvalProcess: GetApprovalProcessResponseStep[];
  journalEntry: O.Option<GetGLJournalEntryResponse>;
  recurringJournalEntry: O.Option<GetRecurringGLJournalEntryDetailsResponse>;
};

const getApprovalStatusText = (
  status:
    | GetGLJournalEntryResponseApprovalStatusResponse
    | ApprovableApprovalStatus
    | undefined,
  stepName?: string,
): string => {
  const journalEntryStatusMap = {
    default: (s: GetGLJournalEntryResponseApprovalStatusResponse) =>
      s.approvalStepName,
    isApproved: () => "Approved",
    isFullyRejected: () => "Rejected",
  };

  const recurringJournalEntryStatusMap = {
    [ApprovableApprovalStatusEnum.APPROVED]: "Approved",
    [ApprovableApprovalStatusEnum.REJECTED]: "Rejected",
    [ApprovableApprovalStatusEnum.NEW]: stepName ?? "",
  };

  if (P.isObject(status)) {
    const journalStatus =
      status as GetGLJournalEntryResponseApprovalStatusResponse;
    if (journalStatus.isApproved) {
      return journalEntryStatusMap.isApproved();
    }
    if (journalStatus.isFullyRejected) {
      return journalEntryStatusMap.isFullyRejected();
    }
    return journalEntryStatusMap.default(journalStatus);
  } else if (S.isString(status)) {
    return recurringJournalEntryStatusMap[status];
  }

  return "";
};

function GeneralLedgerTransactionApprovalsTransactionApprovalDetails({
  journalEntry,
  recurringJournalEntry,
}: ApprovalDetailsProps) {
  const tuples = [
    {
      label: "Approval Status",
      value: O.isSome(journalEntry)
        ? getApprovalStatusText(journalEntry.value.approvalStatus)
        : recurringJournalEntry.pipe(
            O.map((r) =>
              getApprovalStatusText(r.approvalStatus, r.approvalStepDisplay),
            ),
            O.getOrElse(() => ""),
          ),
    },
    {
      label: "Assigned Approver",
      value: O.isSome(recurringJournalEntry)
        ? recurringJournalEntry.pipe(
            O.map((r) => r.approverNameDisplay),
            O.getOrElse(() => ""),
          )
        : "",
    },
    {
      label: "Author",
      value: O.isSome(journalEntry)
        ? journalEntry.value.authorName
        : recurringJournalEntry.pipe(
            O.map((r) => r.authorDisplay),
            O.getOrElse(() => ""),
          ),
    },

    {
      label: "Source",
      value: O.isSome(journalEntry)
        ? convertSnakeCaseToTitleCase(journalEntry.value.txSource)
        : convertSnakeCaseToTitleCase(
            recurringJournalEntry.pipe(
              O.map((r) => r.source),
              O.getOrElse(() => ""),
            ),
          ),
    },
  ];

  return (
    <Stack spacing={Spacing.md}>
      <Group noWrap justify={Justify.between}>
        <H3>Approval Details</H3>
      </Group>
      <div className="grid content-start grid-cols-1 md:grid-cols-2">
        <TupleList entries={tuples} />
      </div>
    </Stack>
  );
}

export { GeneralLedgerTransactionApprovalsTransactionApprovalDetails };
