import { z } from "zod";

const IsoDateSchema = z
  .string()
  .trim()
  .length(10)
  .regex(/^\d{4}-[01]\d-[0-3]\d$/, "Invalid ISO date")
  .brand("IsoDate");

type IsoDate = z.infer<typeof IsoDateSchema>;

export { IsoDateSchema };
export type { IsoDate };
