import { Function as F, Option as O } from "effect";

import type { Instant } from "@ender/shared/core";
import { Instant$ } from "@ender/shared/core";
import type { EmptyObject } from "@ender/shared/types/general";

import type { CellProps } from "../table.types";

function InstantCell<RowData, Meta extends object = EmptyObject>(
  props: CellProps<
    RowData,
    string | number | Instant | Instant$ | null | undefined,
    Meta
  >,
) {
  return F.pipe(
    props.getValue(),
    Instant$.parse,
    O.map((t) => t.toString()),
    O.getOrElse(F.constant("")),
  );
}

export { InstantCell };
