import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const CollectionsSortByCollectionsSortColumnValues = [
  "TENANT_NAME",
  "PROPERTY_NAME",
  "MARKET",
  "TOTAL_DUE",
  "TOTAL_RENT_DUE",
  "LATE_FEE_ELIGIBLE_BALANCE",
  "DAYS_LATE",
  "ACTION_NEEDED",
] as const;

const CollectionsSortByCollectionsSortColumnEffectSchema = Schema.Literal(
  ...CollectionsSortByCollectionsSortColumnValues,
);

type CollectionsSortByCollectionsSortColumn = Schema.Schema.Type<
  typeof CollectionsSortByCollectionsSortColumnEffectSchema
>;

const CollectionsSortByCollectionsSortColumnEnum = castEnum(
  CollectionsSortByCollectionsSortColumnEffectSchema,
);

function randomCollectionsSortByCollectionsSortColumn(): CollectionsSortByCollectionsSortColumn {
  return rand(CollectionsSortByCollectionsSortColumnValues);
}

export {
  CollectionsSortByCollectionsSortColumnEffectSchema,
  CollectionsSortByCollectionsSortColumnEnum,
  CollectionsSortByCollectionsSortColumnValues,
  randomCollectionsSortByCollectionsSortColumn,
};
export type { CollectionsSortByCollectionsSortColumn };
