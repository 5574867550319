import { IconDogBowl, IconPlus } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Array as A, Predicate as P } from "effect";
import { useState } from "react";

import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import { ActionIcon } from "@ender/shared/ds/action-icon";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { PetsAPI } from "@ender/shared/generated/ender.api.leasing";
import type { LeaseSerializerLeaseResponse } from "@ender/shared/generated/ender.arch.serializer.leasing";
import type { Pet } from "@ender/shared/generated/ender.model.leasing";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { RightRail } from "@ender/shared/ui/right-rail";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { AddEditPetForm } from "@ender/widgets/leasing/add-edit-pet";
import { ApplicationPetCard } from "@ender/widgets/leasing/applications/pet-card";

function TenantPets({ lease }: { lease: LeaseSerializerLeaseResponse }) {
  const [isRightRailOpen, rightRailHandlers] = useBoolean(false);
  /**
   * the pet that is currently being edited. if null (and the rail is open) a pet is being added.
   */
  const [selectedPet, setSelectedPet] = useState<Pet | null>(null);

  const { data: pets = [], refetch: refetchPets } = useQuery({
    enabled: P.isNotNullable(lease),
    queryFn: async ({ signal }) => {
      if (P.isNotNullable(lease)) {
        const result = await PetsAPI.getPets({ leaseId: lease.id }, { signal });
        return result ?? [];
      }
      return [];
    },
    queryKey: ["PetsAPI.getPets", lease?.id] as const,
  });

  function handleAddPet() {
    setSelectedPet(NULL);
    rightRailHandlers.setTrue();
  }

  function handlePetSaveSuccess() {
    showSuccessNotification({ message: "Pet saved." });
    refetchPets();
    rightRailHandlers.setFalse();
  }

  return (
    <>
      <Stack>
        <Group justify={Justify.between}>
          <Group spacing={Spacing.sm}>
            <IconDogBowl size={24} color="var(--color-slate-600)" />
            <H2>Pets</H2>
          </Group>
          <ActionIcon tooltip="Add Pet" onClick={handleAddPet}>
            <IconPlus />
          </ActionIcon>
        </Group>
        {A.isEmptyArray(pets) && (
          <Text size={FontSize.md} weight={FontWeight.medium}>
            There are no pets for this tenant.
          </Text>
        )}
        {pets.map((pet) => (
          <ApplicationPetCard
            key={pet.id}
            pet={pet}
            isEditable
            isDeletable
            onDeleteSuccess={refetchPets}
          />
        ))}
      </Stack>
      <RightRail
        opened={isRightRailOpen}
        onClose={rightRailHandlers.setFalse}
        title={P.isNotNullable(selectedPet) ? "Edit Pet" : "Add Pet"}>
        {P.isNotNullable(lease?.contacts) &&
          A.isNonEmptyArray(lease.contacts) && (
            <AddEditPetForm
              pet={selectedPet ?? UNDEFINED}
              ownerId={lease.contacts[0].id}
              onCancel={rightRailHandlers.setFalse}
              onSuccess={handlePetSaveSuccess}
            />
          )}
      </RightRail>
    </>
  );
}

export { TenantPets };
