import { useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Function as F,
  Match,
  Number as Num,
  Option as O,
  Predicate as P,
  Record as R,
} from "effect";
import type { ElementRef } from "react";
import { forwardRef, useCallback, useMemo, useState } from "react";

import type { EnderId } from "@ender/shared/core";
import { TriggerButton, TriggerButtonSize } from "@ender/shared/ds/menu";
import type { SelectOption } from "@ender/shared/ds/select";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import { SearchAPI } from "@ender/shared/generated/ender.api.misc";
import type { MinimalPropertyResponse } from "@ender/shared/generated/ender.api.model";
import { SearchServiceSearchTypeEnum } from "@ender/shared/generated/ender.service.search";
import { useDebounce } from "@ender/shared/hooks/use-debounce";
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "@ender/shared/utils/notifications";
import { getPropertyNameWithFriendlyId } from "@ender/shared/utils/property/get-property-name-with-friendly-id";
import { MultiFilter } from "@ender/widgets/filters/multi-filter";

const PROPERTY_CODE_REGEX = /([A-Z]+_\d+)/gm;

type ValidPropertiesAccumulator = {
  validProperties: SelectOption<EnderId, MinimalPropertyResponse>[];
  invalidCodes: string[];
};

type PropertyFilterProps = {
  disabled?: boolean;
  onChange: (value: SelectOption<EnderId, MinimalPropertyResponse>[]) => void;
  value: SelectOption<EnderId, MinimalPropertyResponse>[];
};

const PropertyFilter = forwardRef<
  ElementRef<typeof MultiFilter>,
  PropertyFilterProps
>(function PropertyFilter({ disabled, onChange, value }, ref) {
  const [keyword, setKeywordValue] = useState<string>("");
  const [queryKeyword, setQueryKeyword] = useState<string>("");
  const setQueryKeywordDebounced = useDebounce(setQueryKeyword, 300);

  const onKeywordChange = useCallback(
    (keyword: string) => {
      setKeywordValue(keyword);
      setQueryKeywordDebounced(keyword);
    },
    [setKeywordValue, setQueryKeywordDebounced],
  );

  const { data: options = [], isFetching } = useQuery({
    queryFn: (context) => {
      const { queryKey } = context;
      const [, payload] = queryKey;
      const { keyword } = payload;
      /**
       * 2024-09-20 Geoffrey, Kyle, Vadim:
       * - We'd rather not use omnisearch
       * - But PropertiesAPI.searchPropertiesv2 doesn't support property codes
       * - // TODO BE should provide a dedicated property search endpoint that supports property codes
       */
      return SearchAPI.omnisearch({
        excludeIds: [],
        keyword,
        limit: 20,
        propertyIds: [],
        resultsOnEmpty: true,
        types: [SearchServiceSearchTypeEnum.PROPERTY],
      }).then((res) =>
        res.map((property) => ({
          label: getPropertyNameWithFriendlyId(property),
          meta: property,
          value: property.id,
        })),
      );
    },
    queryKey: ["SearchAPI.omnisearch", { keyword: queryKeyword }] as const,
  });

  function showNotification(
    validProperties: SelectOption<EnderId, MinimalPropertyResponse>[],
    invalidCodes: string[],
    duplicateCodes: string[],
  ) {
    if (A.isEmptyArray(validProperties)) {
      showErrorNotification({ message: "Unable to apply filters from paste" });
    } else if (A.isNonEmptyArray(invalidCodes)) {
      showWarningNotification({
        message: `Some filters not applied from paste: ${invalidCodes.join(", ")}`,
      });
    } else if (A.isNonEmptyArray(duplicateCodes)) {
      showWarningNotification({
        message: `Duplicate filters found and excluded: ${duplicateCodes.join(", ")}`,
      });
    } else {
      showSuccessNotification({ message: "Applied valid filters from paste" });
    }
  }

  function getDuplicateCodes(pastedText: string[]): string[] {
    const codeCounts = pastedText.reduce<Record<string, number>>(
      (acc, code) => {
        acc[code] = (acc[code] || 0) + 1;
        return acc;
      },
      {},
    );

    return R.keys(codeCounts).filter((code) => codeCounts[code] > 1);
  }

  const onPasteOptions = useCallback(
    async (
      pastedText: string,
    ): Promise<SelectOption<EnderId, MinimalPropertyResponse>[]> => {
      const matches = pastedText.match(PROPERTY_CODE_REGEX) ?? [];

      if (A.isEmptyArray(matches ?? [])) {
        return [];
      }

      const duplicateCodes = getDuplicateCodes(matches);
      const response = await PropertiesAPI.getPropertyCodeIdMap({
        friendlyIds: matches,
      });
      const { validProperties, invalidCodes } =
        matches.reduce<ValidPropertiesAccumulator>(
          (acc, id) => {
            const property = response[id];
            if (P.isNotNullable(property)) {
              acc.validProperties.push({
                label: getPropertyNameWithFriendlyId({
                  name: property.name,
                  friendlyId: id,
                }),
                value: property.id,
                meta: property,
              });
            } else {
              acc.invalidCodes.push(id);
            }
            return acc;
          },
          {
            validProperties: [],
            invalidCodes: [],
          },
        );

      showNotification(validProperties, invalidCodes, duplicateCodes);

      return validProperties;
    },
    [],
  );

  const triggerLabel = useMemo(() => {
    const headLabel = F.pipe(
      value,
      A.head,
      O.map((opt) => `: ${opt.label}`),
      O.getOrElse(F.constant("")),
    );
    const countLabel = Match.value(A.length(value)).pipe(
      Match.when(Num.greaterThan(1), (size) => ` +${size - 1}`),
      Match.orElse(F.constant("")),
    );
    return `Property${headLabel}${countLabel}`;
  }, [value]);

  return (
    <MultiFilter
      label="Property Filter"
      data={options}
      disabled={disabled}
      keyword={keyword}
      isLoading={isFetching}
      onChange={onChange}
      onKeywordChange={onKeywordChange}
      onPaste={onPasteOptions}
      ref={ref}
      trigger={
        <TriggerButton
          rounded={false}
          disabled={disabled}
          size={TriggerButtonSize.sm}>
          {triggerLabel}
        </TriggerButton>
      }
      value={value}
    />
  );
});

export { PropertyFilter };
export type { PropertyFilterProps };
