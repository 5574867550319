import { useCallback, useEffect, useMemo, useState } from "react";

import { usePrevious } from "@ender/shared/hooks/use-previous";

type UsePaginationProps<T> = {
  items: T[];
  maxVisible: number;
};

type UsePaginationResult<T> = {
  activePage: number;
  setActivePage: (page: number) => void;
  totalPages: number;
  visibleItems: T[];
};

function usePagination<T>({
  items,
  maxVisible,
}: UsePaginationProps<T>): UsePaginationResult<T> {
  const [activePage, setActivePage] = useState(1);
  const previousItems = usePrevious(items);

  const totalPages = useMemo(() => {
    return items?.length ? Math.ceil(items.length / maxVisible) : 0;
  }, [items, maxVisible]);

  const getVisibleItems = useCallback(
    (activePage: number, items: T[], maxVisible: number) => {
      if (!items) {
        return [];
      }

      const startingIndex = activePage * maxVisible - maxVisible;
      const exclusiveEndingIndex = activePage * maxVisible;
      return items.slice(startingIndex, exclusiveEndingIndex);
    },
    [],
  );

  const visibleItems = useMemo(() => {
    return getVisibleItems(activePage, items, maxVisible);
  }, [activePage, items, maxVisible, getVisibleItems]);

  // Reset page to 1 when items change
  useEffect(() => {
    if (previousItems !== items && activePage !== 1) {
      setActivePage(1);
    }
  }, [items, previousItems, activePage]);

  return {
    activePage,
    setActivePage,
    totalPages,
    visibleItems,
  };
}

export { usePagination };
