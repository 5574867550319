/**
 * HTTP Method: POST
 * Pathname: /bankAccounts/{bankAccountId}/transactions
 * @function getTransactions
 * @memberof BankingAPI
 * @param {BankingAPIGetTransactionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTransactionsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { ReconTypeFilter } from "@ender/shared/generated/ender.api.accounting.request";
import type { GetTransactionsResponse } from "@ender/shared/generated/ender.api.accounting.response";
import type {
  GetBankTransactionsRequestMatchFilter,
  PeriodFilter,
} from "@ender/shared/generated/ender.api.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BankingAPIGetTransactionsPathParams = {
  bankAccountId: EnderId;
};

type BankingAPIGetTransactionsSearchParams = {
  token?: string | undefined;
};

type BankingAPIGetTransactionsBodyParams = {
  amountSearchString?: string | undefined;
  description?: string | undefined;
  includeRows?: boolean | undefined;
  inclusiveEndDate?: SerializesInto<LocalDate> | undefined;
  limit?: number | undefined;
  matchFilter?: GetBankTransactionsRequestMatchFilter | undefined;
  offsetId?: EnderId | undefined;
  periodFilter?: PeriodFilter | undefined;
  propertyIds: EnderId[];
  showDeposits?: boolean | undefined;
  showDraws?: boolean | undefined;
  startDate?: SerializesInto<LocalDate> | undefined;
  typeFilter?: ReconTypeFilter | undefined;
};

type BankingAPIGetTransactionsPayload = BankingAPIGetTransactionsPathParams &
  BankingAPIGetTransactionsSearchParams &
  BankingAPIGetTransactionsBodyParams;

function getTransactionsUnsafeEffect(
  payload: BankingAPIGetTransactionsPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTransactionsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetTransactionsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/bankAccounts/${bankAccountId}/transactions`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTransactions(
  payload: BankingAPIGetTransactionsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetTransactionsResponse> {
  return F.pipe(
    payload,
    getTransactionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTransactions };
export type {
  BankingAPIGetTransactionsBodyParams,
  BankingAPIGetTransactionsPathParams,
  BankingAPIGetTransactionsPayload,
  BankingAPIGetTransactionsSearchParams,
};
