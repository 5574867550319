/**
 * Allow FE to know the file size before downloading, that it can pop-up warning to the user if the file is super
 large.
 * HTTP Method: POST
 * Pathname: /buy/attom/assessorRowCount
 * @function getAssessorFileSize
 * @memberof BuyAPI
 * @param {BuyAPIGetAssessorFileSizePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetAssessorFileSizeResponse>}
 */
import { Effect, Function as F } from "effect";

import type { Money, SerializesInto } from "@ender/shared/core";
import type { GetAssessorFileSizeResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetAssessorFileSizeSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetAssessorFileSizeBodyParams = {
  cbsaCode: string;
  maxTaxAssessedValue: SerializesInto<Money>;
  minTaxAssessedValue: SerializesInto<Money>;
};

type BuyAPIGetAssessorFileSizePayload = BuyAPIGetAssessorFileSizeSearchParams &
  BuyAPIGetAssessorFileSizeBodyParams;

function getAssessorFileSizeUnsafeEffect(
  payload: BuyAPIGetAssessorFileSizePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetAssessorFileSizeResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetAssessorFileSizeResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/buy/attom/assessorRowCount",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAssessorFileSize(
  payload: BuyAPIGetAssessorFileSizePayload,
  options?: { signal?: AbortSignal },
): Promise<GetAssessorFileSizeResponse> {
  return F.pipe(
    payload,
    getAssessorFileSizeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAssessorFileSize };
export type {
  BuyAPIGetAssessorFileSizeBodyParams,
  BuyAPIGetAssessorFileSizePayload,
  BuyAPIGetAssessorFileSizeSearchParams,
};
