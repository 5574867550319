import { Fragment } from "react";

import type { EnderId } from "@ender/shared/core";
import type { BadgeColors } from "@ender/shared/ds/badge";
import { Badge, BadgeColor } from "@ender/shared/ds/badge";
import { Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
import { FontSize, FontWeight, Text } from "@ender/shared/ds/text";
import { EnderDate } from "@ender/shared/utils/ender-date";
import { renderPrivateContact } from "@ender/shared/utils/user";

import styles from "./approval-timeline.module.css";

const decisionColorMap: Record<string, BadgeColors> = {
  Created: BadgeColor.green,
  Approved: BadgeColor.green,
  Paid: BadgeColor.green,
  Pending: BadgeColor.yellow,
  Rejected: BadgeColor.red,
  "Payment-Voided": BadgeColor.red,
  Waiting: BadgeColor.slate,
} as const;

type ApprovalTimelineProps = {
  timeline: {
    date: string;
    decision: string;
    timestamp: string;
    stepId?: EnderId | null;
    step?: string | null;
    user?: {
      id: EnderId;
      firstName: string;
      lastName: string;
    } | null;
    comment: string;
  }[];
  showHeader?: boolean;
};

function ApprovalTimeline({
  timeline = [],
  showHeader = true,
}: ApprovalTimelineProps) {
  return (
    <Stack spacing={Spacing.sm}>
      {showHeader && <div>Approval Timeline</div>}
      <div className={styles.approvalTimelineTable}>
        {timeline.map((timelineStep) => (
          <Fragment
            key={`${timelineStep.stepId}${timelineStep.timestamp}${timelineStep.step}${timelineStep.decision}`}>
            <Text size={FontSize.sm} weight={FontWeight.medium}>
              {EnderDate.of(timelineStep.date).toSlashDateString()}
            </Text>
            <Badge
              color={
                decisionColorMap[timelineStep.decision.replace(/\s/g, "-")]
              }>
              {timelineStep.decision}
            </Badge>
            <div>
              {timelineStep.user && (
                <>
                  {renderPrivateContact(timelineStep.user)}
                  {timelineStep.decision !== "Created" && timelineStep.step
                    ? ` (${timelineStep.step})`
                    : ``}
                  {timelineStep.comment && (
                    <>
                      <br />
                      <i className="timeline__history-comment">
                        {timelineStep.comment}
                      </i>
                    </>
                  )}
                </>
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </Stack>
  );
}

export { ApprovalTimeline };
