/**
 * HTTP Method: POST
 * Pathname: /construction/inspections/{inspectionId}/photos
 * @function addPhotos
 * @memberof InspectionAPI
 * @param {InspectionAPIAddPhotosPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<WebserverFilesServiceHandleFileResult[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { WebserverFilesServiceHandleFileResult } from "@ender/shared/generated/ender.service.files";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InspectionAPIAddPhotosPathParams = {
  inspectionId: EnderId;
};

type InspectionAPIAddPhotosSearchParams = {
  token?: string | undefined;
};

type InspectionAPIAddPhotosBodyParams = {
  areaId?: EnderId | undefined;
  lineItemId?: EnderId | undefined;
  zoneId?: EnderId | undefined;
};

type InspectionAPIAddPhotosPayload = InspectionAPIAddPhotosPathParams &
  InspectionAPIAddPhotosSearchParams &
  InspectionAPIAddPhotosBodyParams;

function addPhotosUnsafeEffect(payload: InspectionAPIAddPhotosPayload) {
  const { inspectionId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, WebserverFilesServiceHandleFileResult[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          WebserverFilesServiceHandleFileResult[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/construction/inspections/${inspectionId}/photos`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addPhotos(
  payload: InspectionAPIAddPhotosPayload,
  options?: { signal?: AbortSignal },
): Promise<WebserverFilesServiceHandleFileResult[]> {
  return F.pipe(
    payload,
    addPhotosUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addPhotos };
export type {
  InspectionAPIAddPhotosBodyParams,
  InspectionAPIAddPhotosPathParams,
  InspectionAPIAddPhotosPayload,
  InspectionAPIAddPhotosSearchParams,
};
