/**
 * HTTP Method: POST
 * Pathname: /middleLayer/getRecurringGLJournalEntryApprovalRows
 * @function getRecurringGLJournalEntryApprovalRows
 * @memberof AccountingMiddleLayerAPI
 * @param {AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetRecurringGLJournalEntryApprovalRowsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { GetRecurringGLJournalEntryApprovalRowsResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { ReportFilterRequest } from "@ender/shared/generated/ender.api.reports.request";
import type { RecurringGLJournalEntryFrequency } from "@ender/shared/generated/ender.model.accounting";
import type { ApprovableApprovalStatus } from "@ender/shared/generated/ender.service.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsSearchParams =
  {
    token?: string | undefined;
  };

type AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsBodyParams =
  {
    approvalStatuses: ApprovableApprovalStatus[];
    approverIds?: EnderId[] | undefined;
    assignedToMe?: boolean | undefined;
    authorIds?: EnderId[] | undefined;
    filters: ReportFilterRequest[];
    firmIds: EnderId[];
    frequencies?: RecurringGLJournalEntryFrequency[] | undefined;
    fundIds: EnderId[];
    inclusiveEndEndDate?: SerializesInto<LocalDate> | undefined;
    inclusiveEndStartDate?: SerializesInto<LocalDate> | undefined;
    inclusiveEndSystemDate?: SerializesInto<LocalDate> | undefined;
    limit?: number | undefined;
    offsetId?: EnderId | undefined;
    propertyIds: EnderId[];
    recurringGLJournalEntryIds?: EnderId[] | undefined;
    startEndDate?: SerializesInto<LocalDate> | undefined;
    startStartDate?: SerializesInto<LocalDate> | undefined;
    startSystemDate?: SerializesInto<LocalDate> | undefined;
  };

type AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsPayload =
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsSearchParams &
    AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsBodyParams;

function getRecurringGLJournalEntryApprovalRowsUnsafeEffect(
  payload: AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetRecurringGLJournalEntryApprovalRowsResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<GetRecurringGLJournalEntryApprovalRowsResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/middleLayer/getRecurringGLJournalEntryApprovalRows",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRecurringGLJournalEntryApprovalRows(
  payload: AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetRecurringGLJournalEntryApprovalRowsResponse> {
  return F.pipe(
    payload,
    getRecurringGLJournalEntryApprovalRowsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRecurringGLJournalEntryApprovalRows };
export type {
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsBodyParams,
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsPayload,
  AccountingMiddleLayerAPIGetRecurringGLJournalEntryApprovalRowsSearchParams,
};
