import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { clsx } from "clsx";
import { Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import { Checkbox } from "@ender/shared/ds/checkbox";

import styles from "./unit-image.module.css";

type UnitImageProps = {
  id: EnderId;
  src: string;
  /**
   * selection state of the image/checkbox
   */
  selected?: boolean;
  /**
   * invoked when the checkbox is toggled
   */
  onChange?: () => void;
};

function UnitImage({
  id,
  src,
  selected = false,
  onChange = F.constVoid,
}: UnitImageProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      className={clsx(styles.imgButton, {
        [styles.selected]: selected,
        [styles.isDragging]: isDragging,
      })}>
      <img
        className={styles.draggablePhoto}
        src={src}
        alt=""
        {...attributes}
        {...listeners}
      />
      <div className={styles.cornerCheckbox}>
        <Checkbox value={selected} onChange={onChange} />
      </div>
    </div>
  );
}

export { UnitImage };
