import { Predicate as P } from "effect";
import { useMemo } from "react";

import { Group } from "@ender/shared/ds/group";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { pluralize } from "@ender/shared/utils/string";

import type { EnderTable } from "./table.types";

import styles from "./table.module.css";

type TableTopResultsOldProps = {
  numResults: number;
  totalResults?: number;
  isLoading: boolean;
};

function TableTopResultsOld(props: TableTopResultsOldProps) {
  const { numResults, totalResults, isLoading } = props;

  const results = useMemo(() => {
    if (numResults === 0) {
      return "No results";
    }
    if (P.isNullable(totalResults)) {
      return `${numResults} ${pluralize("result", numResults)}`;
    }
    return `${numResults}/${totalResults} Results`;
  }, [numResults, totalResults]);

  return (
    <Group>
      <div className={styles.numResults} aria-label="Number of results">
        {results}
      </div>
      {isLoading && <LoadingSpinner />}
    </Group>
  );
}

type TableTopResultsProps<RowData, Meta extends object> = {
  table: EnderTable<RowData, Meta>;
};

function TableTopResults<RowData, Meta extends object>(
  props: TableTopResultsProps<RowData, Meta>,
) {
  const { table } = props;
  const numResults = table.getFilteredRowModel()?.rows?.length ?? 0;
  const totalResults = table.options.meta.totalResults;
  const isLoading = table.options.meta.isLoading;

  return (
    <TableTopResultsOld
      numResults={numResults}
      totalResults={totalResults}
      isLoading={isLoading}
    />
  );
}

export { TableTopResults, TableTopResultsOld };
export type { TableTopResultsProps };
