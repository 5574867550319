import type { UseFormInput } from "@mantine/form/lib/types";
import type { PropsWithChildren } from "react";

import { useForm } from "@ender/shared/forms/hooks/general";
import type { BaseFormProps } from "@ender/shared/forms/ui/unmanaged-form";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";

/** @deprecated This should not be used anymore. */
type ManagedFormProps<T> = BaseFormProps<T> & {
  formProps?: UseFormInput<T>;

  /** @description Determine whether or not the form inputs will allow the browser to use autocomplete suggestions */
  noAutoComplete?: boolean;
};

/** @deprecated This should not be used anymore. */
function ManagedForm<T>({
  children,
  formProps,
  noAutoComplete,
  onSubmit,
  onValidationFailure,
}: PropsWithChildren<ManagedFormProps<T>>) {
  const form = useForm<T>(formProps ?? {});
  return (
    <UnmanagedForm
      form={form}
      onSubmit={onSubmit}
      onValidationFailure={onValidationFailure}
      noAutoComplete={noAutoComplete}>
      {children}
    </UnmanagedForm>
  );
}

export { ManagedForm };
export type { ManagedFormProps };
