import { cva } from "class-variance-authority";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";

import type { Sizes } from "@ender/shared/utils/theming";
import { Size } from "@ender/shared/utils/theming";

import styles from "./shared-ds-divider.module.css";

type DividerProps = {
  thickness?: Extract<Sizes, "sm" | "md" | "lg">;
  inset?: "none" | "sm" | "md" | "lg";
  orientation?: "horizontal" | "vertical";
};

const DividerWrapperVariantGenerator = cva(["flex relative"], {
  compoundVariants: [],
  defaultVariants: {
    inset: "none",
    orientation: "horizontal",
  },
  variants: {
    inset: {
      lg: "[--divider-inset:1.5rem]",
      md: "[--divider-inset:1rem]",
      none: "[--divider-inset:0]",
      sm: "[--divider-inset:0.5rem]",
    },
    orientation: {
      /*  intentionally using the opposite flex orientation from the divider orientation.
  this lets us use a better behavior of flex stretching WRT cross-ways orientation */
      horizontal: "flex-col",
      vertical: "flex-row",
    },
  },
});

const DividerVariantGenerator = cva(["border-slate-200"], {
  defaultVariants: {
    orientation: "horizontal",
    thickness: "sm",
  },
  variants: {
    orientation: {
      horizontal: [styles.horizontal, "mx-[--divider-inset]"],
      vertical: [styles.vertical, "my-[--divider-inset]"],
    },
    thickness: {
      [Size.lg]: "[--divider-width:3px]",
      [Size.md]: "[--divider-width:2px]",
      [Size.sm]: "[--divider-width:1px]",
    },
  },
});

function Divider(props: DividerProps, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <div
      role="separator"
      ref={ref}
      className={DividerWrapperVariantGenerator(props)}>
      <div className={DividerVariantGenerator(props)} />
    </div>
  );
}
const ForwardedDivider = forwardRef<HTMLDivElement, DividerProps>(Divider);

export { ForwardedDivider as Divider };
export type { DividerProps };
