import { z } from "zod";

import { Percent } from "@ender/shared/utils/percent";

const PercentEncodeSchema = z
  .string()
  .regex(/^\d+(\.\d+)?%$/, "Must be a valid percent")
  .transform<Percent>(Percent.fromString); // Create a Percent instance

const PercentDecodeSchema = z
  .instanceof(Percent)
  .transform<string>((percent) => percent.toString()); // Create a string

export { PercentDecodeSchema, PercentEncodeSchema };
