import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GLTxTxSourceValues = ["SYSTEM", "MANUAL"] as const;

const GLTxTxSourceEffectSchema = Schema.Literal(...GLTxTxSourceValues);

type GLTxTxSource = Schema.Schema.Type<typeof GLTxTxSourceEffectSchema>;

const GLTxTxSourceEnum = castEnum(GLTxTxSourceEffectSchema);

function randomGLTxTxSource(): GLTxTxSource {
  return rand(GLTxTxSourceValues);
}

export {
  GLTxTxSourceEffectSchema,
  GLTxTxSourceEnum,
  GLTxTxSourceValues,
  randomGLTxTxSource,
};
export type { GLTxTxSource };
