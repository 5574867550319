import type { EnderId } from "@ender/shared/core";
import { EnderLink } from "@ender/shared/ui/ender-link";

type ApplicantNamesCellProps = {
  applicantNames: string;
  applicationGroupId: EnderId;
};

function ApplicantNamesCell({
  applicantNames,
  applicationGroupId,
}: ApplicantNamesCellProps) {
  return (
    <EnderLink
      to={`/leasing-center/applications/${applicationGroupId}`}
      target="_blank">
      {applicantNames}
    </EnderLink>
  );
}

export { ApplicantNamesCell };
