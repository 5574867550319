/**
 * HTTP Method: POST
 * Pathname: /invoices/fileUploadPayable
 * @function bulkUploadPayableInvoices
 * @memberof InvoicesAPI
 * @param {InvoicesAPIBulkUploadPayableInvoicesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type InvoicesAPIBulkUploadPayableInvoicesSearchParams = {
  token?: string | undefined;
};

type InvoicesAPIBulkUploadPayableInvoicesBodyParams = {
  overrideWarnings?: boolean | undefined;
};

type InvoicesAPIBulkUploadPayableInvoicesPayload =
  InvoicesAPIBulkUploadPayableInvoicesSearchParams &
    InvoicesAPIBulkUploadPayableInvoicesBodyParams;

function bulkUploadPayableInvoicesUnsafeEffect(
  payload: InvoicesAPIBulkUploadPayableInvoicesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/invoices/fileUploadPayable",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function bulkUploadPayableInvoices(
  payload: InvoicesAPIBulkUploadPayableInvoicesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    bulkUploadPayableInvoicesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { bulkUploadPayableInvoices };
export type {
  InvoicesAPIBulkUploadPayableInvoicesBodyParams,
  InvoicesAPIBulkUploadPayableInvoicesPayload,
  InvoicesAPIBulkUploadPayableInvoicesSearchParams,
};
