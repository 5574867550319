/**
 * HTTP Method: GET
 * Pathname: /markets/{marketId}/allocation
 * @function getAllocationForMarket
 * @memberof MarketsAPI
 * @param {MarketsAPIGetAllocationForMarketPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MarketsAPIGetAllocationForMarketResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MarketsAPIGetAllocationForMarketResponse } from "../markets-api-get-allocation-for-market-response";

type MarketsAPIGetAllocationForMarketPathParams = {
  marketId: EnderId;
};

type MarketsAPIGetAllocationForMarketSearchParams = {
  token?: string | undefined;
};

type MarketsAPIGetAllocationForMarketPayload =
  MarketsAPIGetAllocationForMarketPathParams &
    MarketsAPIGetAllocationForMarketSearchParams;

function getAllocationForMarketUnsafeEffect(
  payload: MarketsAPIGetAllocationForMarketPayload,
) {
  const { marketId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MarketsAPIGetAllocationForMarketResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          MarketsAPIGetAllocationForMarketResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/markets/${marketId}/allocation`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAllocationForMarket(
  payload: MarketsAPIGetAllocationForMarketPayload,
  options?: { signal?: AbortSignal },
): Promise<MarketsAPIGetAllocationForMarketResponse[]> {
  return F.pipe(
    payload,
    getAllocationForMarketUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAllocationForMarket };
export type {
  MarketsAPIGetAllocationForMarketPathParams,
  MarketsAPIGetAllocationForMarketPayload,
  MarketsAPIGetAllocationForMarketSearchParams,
};
