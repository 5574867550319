/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/draftingRenewalOffers
 * @function createDraftingRenewalOffers
 * @memberof RenewalsAPI
 * @param {RenewalsAPICreateDraftingRenewalOffersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<LeaseRenewalOffer[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { CreateDraftingRenewalOffersRequestDraftingRenewalOffer } from "@ender/shared/generated/ender.api.leasing.request";
import type { LeaseRenewalOffer } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type RenewalsAPICreateDraftingRenewalOffersPathParams = {
  leaseId: EnderId;
};

type RenewalsAPICreateDraftingRenewalOffersSearchParams = {
  token?: string | undefined;
};

type RenewalsAPICreateDraftingRenewalOffersBodyParams = {
  draftingRenewalOffers: CreateDraftingRenewalOffersRequestDraftingRenewalOffer[];
};

type RenewalsAPICreateDraftingRenewalOffersPayload =
  RenewalsAPICreateDraftingRenewalOffersPathParams &
    RenewalsAPICreateDraftingRenewalOffersSearchParams &
    RenewalsAPICreateDraftingRenewalOffersBodyParams;

function createDraftingRenewalOffersUnsafeEffect(
  payload: RenewalsAPICreateDraftingRenewalOffersPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, LeaseRenewalOffer[]>({
        body,
        decode: unsafeDecodeJsonResponse<LeaseRenewalOffer[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/draftingRenewalOffers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createDraftingRenewalOffers(
  payload: RenewalsAPICreateDraftingRenewalOffersPayload,
  options?: { signal?: AbortSignal },
): Promise<LeaseRenewalOffer[]> {
  return F.pipe(
    payload,
    createDraftingRenewalOffersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createDraftingRenewalOffers };
export type {
  RenewalsAPICreateDraftingRenewalOffersBodyParams,
  RenewalsAPICreateDraftingRenewalOffersPathParams,
  RenewalsAPICreateDraftingRenewalOffersPayload,
  RenewalsAPICreateDraftingRenewalOffersSearchParams,
};
