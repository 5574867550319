import { Schema } from "@effect/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Array as A,
  Option as O,
  Predicate as P,
  Record as R,
  pipe,
} from "effect";
import type { ReactNode } from "react";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import type { ArrayPath } from "react-hook-form";
import { useWatch } from "react-hook-form";

import { FormAccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import { FormSearchInput, SearchInput } from "@ender/entities/search-input";
import type {
  FormSubSectionReference,
  UseFormReturn,
} from "@ender/form-system/base";
import {
  Form,
  FormList,
  FormSection,
  useEffectSchemaForm,
} from "@ender/form-system/base";
import { uploadFilesDirect } from "@ender/shared/api/files";
import { useConfirmationContext } from "@ender/shared/contexts/confirmation";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import {
  EnderIdFormSchema,
  LocalDate$,
  LocalDateEffectSchema,
  Money$,
  MoneyFormSchema,
} from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Card } from "@ender/shared/ds/card";
import { FormDateInput } from "@ender/shared/ds/date-input";
import { Divider } from "@ender/shared/ds/divider";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify, Spacing } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { H2 } from "@ender/shared/ds/heading";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import type { SelectOption } from "@ender/shared/ds/select";
import { FormSelect } from "@ender/shared/ds/select";
import { Stack } from "@ender/shared/ds/stack";
import { FormSwitch } from "@ender/shared/ds/switch";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import {
  BankingAPI,
  GeneralLedgerAPI,
  TenantLedgerAPI,
} from "@ender/shared/generated/ender.api.accounting";
import { PropertiesAPI } from "@ender/shared/generated/ender.api.core";
import { LeasingAPI } from "@ender/shared/generated/ender.api.leasing";
import type { CategoryFlag } from "@ender/shared/generated/ender.model.accounting";
import {
  AccountingPeriodAccountingModuleEnum,
  LedgerEventLedgerEventTypeEnum,
} from "@ender/shared/generated/ender.model.accounting";
import { InvoiceInvoiceTypeEnum } from "@ender/shared/generated/ender.model.payments.invoice";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { fail } from "@ender/shared/utils/error";
import { showSuccessNotification } from "@ender/shared/utils/notifications";
import { withWarningHandler } from "@ender/shared/utils/rest";

import { searchGetTxCategories } from "./search-categories";
import { searchTenants } from "./search-tenants";

const allocationsSchema = Schema.Struct({
  amount: MoneyFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Amount is required" }),
    Schema.filter(O.exists<Money$.Money>(Money$.isPositive), {
      message: () => "Amount must be greater than $0.00",
    }),
  ),
  categoryId: EnderIdFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, {
      message: () => "Tenant Ledger Allocation is required",
    }),
  ),
  historical: Schema.Boolean,
  isCustomAmount: Schema.Boolean,
  name: Schema.String,
});

const CreateReceiptFormSchema = Schema.Struct({
  allocations: Schema.Array(allocationsSchema),
  amount: MoneyFormSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Amount is required" }),
    Schema.filter((v) => O.exists(v, Money$.isPositive), {
      message: () => "Amount must be greater than $0.00",
    }),
  ),
  applyToOutstandingCharges: Schema.Boolean,
  bankAccountId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  checkNumber: Schema.String.pipe(
    Schema.minLength(1, { message: () => "Check Number is required" }),
    Schema.pattern(/^[0-9a-zA-Z.-]*$/, {
      message: () => "Check Number has invalid characters",
    }),
  ),
  date: LocalDateEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Date is required" }),
  ),
  depositDate: LocalDateEffectSchema.pipe(Schema.OptionFromSelf),
  isDeposited: Schema.Boolean,
  leaseId: EnderIdFormSchema.pipe(Schema.OptionFromSelf),
  leaseTenant: Schema.Struct({
    id: EnderIdFormSchema,
    leaseId: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
  memo: Schema.String,
  niceId: Schema.String,
  periodId: Schema.Struct({
    id: EnderIdFormSchema,
  }).pipe(Schema.OptionFromSelf),
}).pipe(
  Schema.filter(
    ({
      leaseId,
      leaseTenant,
      isDeposited,
      bankAccountId,
      depositDate,
      date,
    }) => {
      const issues: Schema.FilterIssue[] = [];
      if (O.isNone(leaseId) && O.isNone(leaseTenant)) {
        issues.push({
          message: "Tenant is required",
          path: ["leaseTenant"],
        });
      }
      if (O.isSome(leaseTenant) && O.isNone(bankAccountId)) {
        issues.push({
          message: "Bank Account is required",
          path: ["bankAccountId"],
        });
      }
      if (isDeposited && O.isNone(depositDate)) {
        issues.push({
          message: "Deposit date is required",
          path: ["depositDate"],
        });
      } else if (
        isDeposited &&
        O.getOrder(LocalDate$.Order)(depositDate, date) <= 0
      ) {
        issues.push({
          message: "Deposit date must be on or after receipt date",
          path: ["depositDate"],
        });
      }
      return issues;
    },
  ),
);

type CreateReceiptFormInput = Schema.Schema.Encoded<
  typeof CreateReceiptFormSchema
>;
type CreateReceiptFormOutput = Schema.Schema.Type<
  typeof CreateReceiptFormSchema
>;

type CreateReceiptFormProps = {
  title?: ReactNode;
  categoryFlags?: CategoryFlag[];
  onSuccess: (keepModalOpen: boolean) => void;
  hideCreateAndAddAnother?: boolean;
  leaseId?: EnderId;
};

type AllocationInputProps = FormSubSectionReference<
  CreateReceiptFormInput,
  { categoryId: O.Option<EnderId>; amount: O.Option<Money$.Money> }
> & {
  onDelete: () => void;
  categoryFlags?: CategoryFlag[];
};

function AllocationInput(props: AllocationInputProps) {
  const { form, name, onDelete, categoryFlags } = props;

  return (
    <FormSection form={form} name={name}>
      {({ section }) => (
        <Stack>
          <Group justify={Justify.end}>
            <Button onClick={onDelete} variant={ButtonVariant.transparent}>
              Remove Allocation
            </Button>
          </Group>
          <FormSearchInput
            form={form}
            name={section.categoryId}
            modelType={ModelTypeEnum.GL_CATEGORY}
            label="Tenant Ledger Allocation"
            search={searchGetTxCategories(categoryFlags)}
            placeholder="Select Tenant Ledger Allocation"
          />
          <FormMoneyInput
            form={form}
            name={section.amount}
            label="Allocation Amount"
          />
          <Divider />
        </Stack>
      )}
    </FormSection>
  );
}

type AllocationsCardProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<CreateReceiptFormInput, any, any>;
  name: ArrayPath<CreateReceiptFormInput>;
  categoryFlags?: CategoryFlag[];
};

function AllocationsCard(props: AllocationsCardProps) {
  const { form, categoryFlags, name } = props;

  return (
    <Card>
      <FormList form={form} name={name}>
        {({ list, arrayMethods }) => {
          return (
            <>
              {list.map(({ name, key }, index) => (
                <AllocationInput
                  key={key}
                  form={form}
                  name={name}
                  onDelete={() => arrayMethods.remove(index)}
                  categoryFlags={categoryFlags}
                />
              ))}
              <Group>
                <Button
                  onClick={() =>
                    arrayMethods.append({
                      amount: O.none(),
                      categoryId: O.none(),
                      historical: false,
                      isCustomAmount: false,
                      name: "",
                    })
                  }
                  variant={ButtonVariant.transparent}>
                  + Add Allocation
                </Button>
              </Group>
            </>
          );
        }}
      </FormList>
    </Card>
  );
}

function CreateReceiptForm(props: CreateReceiptFormProps) {
  const {
    onSuccess,
    title = "Add Check Receipt",
    categoryFlags,
    hideCreateAndAddAnother = false,
    leaseId,
  } = props;

  const createAnotherRef = useRef(false);
  const [isProcessing, setIsProcessing] = useBoolean(false);
  const { userPM } = useContext(UserContext);
  const [files, setFiles] = useState<File[]>([]);
  const confirmation = useConfirmationContext();

  const form = useEffectSchemaForm({
    defaultValues: {
      allocations: [],
      amount: O.none(),
      applyToOutstandingCharges: true,
      bankAccountId: O.none(),
      checkNumber: "",
      date: O.some(LocalDate$.today()),
      depositDate: O.some(LocalDate$.today()),
      isDeposited: false,
      leaseId: O.fromNullable(leaseId),
      leaseTenant: O.none(), // is only for determining leaseId if leaseId is unavailable
      memo: "",
      niceId: "", // aka "deposit id" but not really
      periodId: O.none(),
    },
    schema: CreateReceiptFormSchema,
  });

  const { setValue, reset, formState } = form;
  const [
    isDeposited,
    date,
    applyToOutstandingCharges,
    leaseTenant,
    amount,
    allocations,
  ] = useWatch({
    control: form.control,
    name: [
      "isDeposited",
      "date",
      "applyToOutstandingCharges",
      "leaseTenant",
      "amount",
      "allocations",
    ],
  });

  const onTenantChange = useCallback(
    (
      _: O.Option<EnderId>,
      item: O.Option<SelectOption<EnderId, { id: EnderId; leaseId: EnderId }>>,
    ) => {
      setValue(
        "leaseTenant",
        O.flatMapNullable(item, (item) => item.meta),
      );
    },
    [setValue],
  );

  const _leaseId = leaseId || O.getOrUndefined(leaseTenant)?.leaseId;

  const { data: lease } = useQuery({
    queryKey: ["LeasingAPI.getLeaseDetails", _leaseId] as const,
    queryFn: ({ signal }) =>
      LeasingAPI.getLeaseDetails(
        {
          //@ts-expect-error _leaseId will be defined at this point thanks to the `enabled` check. just don't refetch
          leaseId: _leaseId,
        },
        { signal },
      ),
    enabled: P.isNotNullable(_leaseId),
  });

  const propertyId = lease?.property?.id;

  const { data: bankAccounts = [] } = useQuery({
    queryKey: ["BankingAPI.searchBankAccountsByFirm", propertyId] as const,
    queryFn: ({ signal }) =>
      BankingAPI.searchBankAccountsByFirm(
        {
          filters: [],
          firmIds: [],
          fundIds: [],
          propertyIds: [propertyId as EnderId],
        },
        { signal },
      ),
    enabled: P.isNotNullable(propertyId),
  });

  const { data: bankAccountIdData, isFetching: isFetchingBankAccounts } =
    useQuery({
      queryKey: [
        "PropertiesAPI.getOperatingAccountIdByPropertyId",
        {
          invoiceType: InvoiceInvoiceTypeEnum.RECEIVABLE,
          propertyIds: [propertyId],
        },
      ] as const,
      queryFn: ({ signal }) =>
        propertyId &&
        PropertiesAPI.getOperatingAccountIdByPropertyId(
          {
            invoiceType: InvoiceInvoiceTypeEnum.RECEIVABLE,
            propertyIds: [propertyId],
          },
          { signal },
        ),
      enabled: P.isNotNullable(propertyId),
    });

  useMemo(() => {
    if (P.isNotNullable(propertyId)) {
      setValue(
        "bankAccountId",
        O.fromNullable(bankAccountIdData?.[propertyId]),
      );
    }
  }, [bankAccountIdData, propertyId, setValue]);

  /**
   * used to verify if any of the created allocations is trying
   * to allocate to a parent category
   */
  const { data: allGLAccounts = [] } = useQuery({
    queryKey: ["GeneralLedgerAPI.getTxCategories"] as const,
    queryFn: ({ signal }) =>
      GeneralLedgerAPI.getTxCategories({ keyword: "" }, { signal }),
  });

  const bankAccountSelectOptions = useMemo(
    () =>
      bankAccounts.map(
        (bankAccount): SelectOption<EnderId> => ({
          label: bankAccount.name,
          value: bankAccount.id,
        }),
      ),
    [bankAccounts],
  );

  const onWarnings = async (warnings: string[]): Promise<void> => {
    await confirmation({
      confirmButtonLabel: "Proceed",
      content: warnings.join("\n"),
      title: "Warning",
    });
  };

  const { mutateAsync: manuallyAllocate } = useMutation({
    mutationFn: TenantLedgerAPI.manuallyAllocateTenantInflow,
    mutationKey: ["TenantLedgerAPI.manuallyAllocateTenantInflow"] as const,
  });

  const { mutateAsync: createReceipt } = useMutation({
    mutationFn: withWarningHandler(
      TenantLedgerAPI.createLeaseReceipt,
      onWarnings,
    ),
    mutationKey: ["TenantLedgerAPI.createLeaseReceipt"] as const,
  });

  const onSubmit = useCallback(
    async (values: CreateReceiptFormOutput) => {
      try {
        setIsProcessing.setTrue();

        const { allocations, applyToOutstandingCharges } = values;
        if (!applyToOutstandingCharges) {
          // user wishes to manually allocate without using waterfall allocation
          if (userPM.confirmParentAllocations) {
            //confirmation is required if parent categories are selected, so now we check if any are
            /**
             * a map of all categories selected during manual allocation
             */
            const allocationCategories = R.fromIterableBy(allocations, (a) =>
              O.getOrThrow(a.categoryId),
            );
            /**
             * using the above map, find which of those categories (if any) is a parent category
             */
            const isParentCategorySelected = allGLAccounts.some(
              (category) =>
                R.has(allocationCategories, category.id) && category.isParent,
            );
            if (isParentCategorySelected) {
              await confirmation({
                confirmButtonLabel: "Yes, use category",
                title:
                  "You have selected to allocate to a parent category. Are you sure you would like to proceed?",
              });
            }
          }
        }
        const _leaseId = pipe(
          values.leaseId,
          O.orElse(() => O.map(values.leaseTenant, (v) => v.leaseId)),
          O.getOrThrow,
        );
        const receipt = await createReceipt({
          ...values,
          //TODO figure out why this parameter is needed and whether it is satisfied by the separate API call below
          allocations: [],
          amount: pipe(
            amount,
            O.map((v) => v.toJSON()),
            O.getOrThrow,
          ),
          bankAccountId: pipe(values.bankAccountId, O.getOrThrow),
          date: pipe(
            date,
            O.map((v) => v.toJSON()),
            O.getOrThrow,
          ),
          depositDate: pipe(
            values.depositDate,
            O.map((v) => v.toJSON()),
            O.getOrUndefined,
          ),
          leaseId: _leaseId,
          periodId: pipe(
            values.periodId,
            O.map((v) => v.id),
            O.getOrUndefined,
          ),
        });
        if (A.isNonEmptyArray(files)) {
          await uploadFilesDirect({
            modelId: receipt.id,
            modelType: LedgerEventLedgerEventTypeEnum.MONEY_TRANSFER,
            uploadType: WebserverFilesServiceFileUploadTypeEnum.DOCUMENT,
            files,
            userId: "" as EnderId,
          });
        }

        if (!applyToOutstandingCharges) {
          await manuallyAllocate({
            allocations: allocations.map((allocation) => ({
              ...allocation,
              amount: pipe(
                amount,
                O.map((v) => v.toJSON()),
                O.getOrThrow,
              ),
              categoryId: O.getOrThrow(allocation.categoryId),
            })),
            leaseId: _leaseId,
            modelId: (receipt as { id: EnderId }).id,
            modelType: ModelTypeEnum.MONEY_TRANSFER,
          });
        }

        if (createAnotherRef.current) {
          reset();
          setFiles([]);
        }
        showSuccessNotification({
          message: `${pipe(amount, O.getOrThrow).toFormatted()} has been received.`,
          title: "Funds received",
        });
        reset();
        onSuccess(createAnotherRef.current);
      } catch (error) {
        fail(error);
      } finally {
        setIsProcessing.setFalse();
      }
    },
    [
      allGLAccounts,
      amount,
      confirmation,
      createReceipt,
      date,
      files,
      manuallyAllocate,
      onSuccess,
      reset,
      setIsProcessing,
      userPM.confirmParentAllocations,
    ],
  );

  const allocationsEqualTotalAmount = O.contains(
    amount,
    pipe(
      allocations,
      A.map((v) => v.amount),
      O.reduceCompact(Money$.zero(), Money$.add),
    ),
  );

  return (
    <Form form={form} onSubmit={onSubmit} aria-label="Create Receipt Form">
      <Stack>
        <H2>{title}</H2>
        {P.isNullable(leaseId) && (
          <SearchInput<EnderId>
            error={formState.errors.leaseTenant?.message}
            value={O.map(leaseTenant, (v) => v.id)}
            label="Tenant"
            //@ts-expect-error this `onChange` depends on the `searchTenants` response containing metadata which includes a leaseId
            onChange={onTenantChange}
            placeholder="Search Tenant"
            search={searchTenants}
          />
        )}
        <FormSelect
          form={form}
          name="bankAccountId"
          data={bankAccountSelectOptions}
          disabled={P.isNullable(_leaseId) || isFetchingBankAccounts}
          label="Bank Account"
          placeholder="Select Account"
        />
        <FormMoneyInput form={form} name="amount" label="Amount" />
        <FormTextInput
          form={form}
          name="checkNumber"
          label="Check Number"
          placeholder="000000"
        />
        <FormDateInput form={form} name="date" label="Received Date" />
        <FormAccountingPeriodSelector
          label="Accounting Period (optional)"
          form={form}
          name="periodId"
          data-test-id="create-receipt-accounting-period"
          periodType={AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE}
        />
        <FormTextInput form={form} name="memo" label="Memo (optional)" />
        <FormSwitch
          form={form}
          name="applyToOutstandingCharges"
          label="Apply via Tenant Payment Allocation Waterfall"
        />
        {!applyToOutstandingCharges && (
          <AllocationsCard
            form={form}
            name="allocations"
            categoryFlags={categoryFlags}
          />
        )}
        <FormSwitch form={form} name="isDeposited" label="Deposited" />
        {isDeposited && (
          <>
            <FormTextInput
              form={form}
              name="niceId"
              description="Ender will assign a Deposit ID if you do not specify one."
              label="Deposit ID (optional)"
            />
            <FormDateInput
              form={form}
              name="depositDate"
              label="Deposit Date"
              minDate={O.getOrUndefined(date)}
            />
          </>
        )}
        <FileInput onChange={setFiles} value={files} />
        <Group justify={Justify.center} spacing={Spacing.md} grow>
          <Button
            disabled={
              !applyToOutstandingCharges && !allocationsEqualTotalAmount
            }
            disabledTooltip="Allocations must add up to total receipt amount."
            loading={isProcessing}
            variant={ButtonVariant.outlined}
            type="submit"
            onClick={() => {
              createAnotherRef.current = false;
            }}>
            Create Funds & Close
          </Button>
          {!hideCreateAndAddAnother && (
            <Button
              disabled={
                !applyToOutstandingCharges && !allocationsEqualTotalAmount
              }
              disabledTooltip="Allocations must add up to total receipt amount."
              loading={isProcessing}
              type="submit"
              onClick={() => {
                createAnotherRef.current = true;
              }}>
              Create & Add Another
            </Button>
          )}
        </Group>
      </Stack>
    </Form>
  );
}

export { CreateReceiptForm };
