import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const AmenityAmenityTypeValues = [
  "POOL",
  "GYM",
  "FIRE_PIT",
  "SAUNA",
  "ELEVATOR",
  "COVERED_PARKING",
  "IN_WALL_AC",
  "CENTRAL_AC",
  "ON_SITE_LAUNDRY",
  "IN_UNIT_LAUNDRY",
  "IN_UNIT_LAUNDRY_HOOKUP",
  "GARAGE_STORAGE",
  "STORAGE_UNIT",
  "PLAYGROUND",
  "GREEN_AREA",
  "BACK_YARD",
  "WALKING_TRAIL",
  "BALCONY",
  "CLUBHOUSE",
  "ROOFTOP_DECK",
  "PET_FRIENDLY",
  "PRIVATE_ENTRANCE",
  "GARAGE",
  "FINISHED_BASEMENT",
  "PRIVATE_POOL",
  "AIR_CONDITIONING",
  "LAUNDRY_ROOM",
  "WASHER_DRYER_HOOKUPS",
  "WASHER_DRYER",
  "SMART_HOME_TECHNOLOGY",
  "STAINLESS_STEEL_APPLIANCES",
  "COVERED_PATIO",
  "ONE_CAR_GARAGE",
  "TWO_CAR_GARAGE",
  "ONE_STORY_HOME",
  "TWO_STORY_HOME",
  "GAS_FIREPLACE",
  "ELECTRIC_FIREPLACE",
  "CEILING_FANS",
  "PRIVATE_BALCONY",
] as const;

const AmenityAmenityTypeEffectSchema = Schema.Literal(
  ...AmenityAmenityTypeValues,
);

type AmenityAmenityType = Schema.Schema.Type<
  typeof AmenityAmenityTypeEffectSchema
>;

const AmenityAmenityTypeEnum = castEnum(AmenityAmenityTypeEffectSchema);

function randomAmenityAmenityType(): AmenityAmenityType {
  return rand(AmenityAmenityTypeValues);
}

export {
  AmenityAmenityTypeEffectSchema,
  AmenityAmenityTypeEnum,
  AmenityAmenityTypeValues,
  randomAmenityAmenityType,
};
export type { AmenityAmenityType };
