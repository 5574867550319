/**
 * Get underwritings of a given deal.
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/underwriting
 * @function getUnderwriting
 * @memberof UnderwritingAPI
 * @param {UnderwritingAPIGetUnderwritingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnderwritingAPIGetUnderwritingResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { UnderwritingAPIGetUnderwritingResponse } from "../underwriting-api-get-underwriting-response";

type UnderwritingAPIGetUnderwritingPathParams = {
  dealId: EnderId;
};

type UnderwritingAPIGetUnderwritingSearchParams = {
  token?: string | undefined;
  loadComparisons?: boolean | undefined;
};

type UnderwritingAPIGetUnderwritingPayload =
  UnderwritingAPIGetUnderwritingPathParams &
    UnderwritingAPIGetUnderwritingSearchParams;

function getUnderwritingUnsafeEffect(
  payload: UnderwritingAPIGetUnderwritingPayload,
) {
  const { dealId, token, loadComparisons, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnderwritingAPIGetUnderwritingResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          UnderwritingAPIGetUnderwritingResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/underwriting`,
        searchParams: [
          ["token", token],
          ["loadComparisons", loadComparisons],
        ],
      }),
    ),
  );
}

function getUnderwriting(
  payload: UnderwritingAPIGetUnderwritingPayload,
  options?: { signal?: AbortSignal },
): Promise<UnderwritingAPIGetUnderwritingResponse[]> {
  return F.pipe(
    payload,
    getUnderwritingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnderwriting };
export type {
  UnderwritingAPIGetUnderwritingPathParams,
  UnderwritingAPIGetUnderwritingPayload,
  UnderwritingAPIGetUnderwritingSearchParams,
};
