import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ApprovalStepApprovalPhaseValues = [
  "ACCOUNTS_PAYABLE",
  "TREASURY",
] as const;

const ApprovalStepApprovalPhaseEffectSchema = Schema.Literal(
  ...ApprovalStepApprovalPhaseValues,
);

type ApprovalStepApprovalPhase = Schema.Schema.Type<
  typeof ApprovalStepApprovalPhaseEffectSchema
>;

const ApprovalStepApprovalPhaseEnum = castEnum(
  ApprovalStepApprovalPhaseEffectSchema,
);

function randomApprovalStepApprovalPhase(): ApprovalStepApprovalPhase {
  return rand(ApprovalStepApprovalPhaseValues);
}

export {
  ApprovalStepApprovalPhaseEffectSchema,
  ApprovalStepApprovalPhaseEnum,
  ApprovalStepApprovalPhaseValues,
  randomApprovalStepApprovalPhase,
};
export type { ApprovalStepApprovalPhase };
