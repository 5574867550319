import { z } from "zod";

import { cast } from "@ender/shared/types/cast";
import { TransferTypeEnum } from "@ender/shared/types/ender-general";

const MappedLedgerEventPaymentTypeValues = [
  TransferTypeEnum.OTHER,
  TransferTypeEnum.DWOLLA_TRANSFER,
  TransferTypeEnum.MARK_PAID_CHECK,
  TransferTypeEnum.STRIPE_PAYMENT,
  TransferTypeEnum.PAYABLE,
] as const;
const MappedLedgerEventPaymentTypeSchema = z.enum(
  MappedLedgerEventPaymentTypeValues,
);
type MappedLedgerEventPaymentType = z.infer<
  typeof MappedLedgerEventPaymentTypeSchema
>;

function isMappedLedgerEventPaymentType(
  paymentType: unknown,
): paymentType is MappedLedgerEventPaymentType {
  return MappedLedgerEventPaymentTypeValues.includes(cast(paymentType));
}

export {
  isMappedLedgerEventPaymentType,
  MappedLedgerEventPaymentTypeSchema,
  MappedLedgerEventPaymentTypeValues,
};

export type { MappedLedgerEventPaymentType };
