import { IconFile } from "@tabler/icons-react";

import type { TimelineAttachment } from "@ender/shared/types/ender-general";

import styles from "./chat-document.module.css";

const isImage: Record<string, boolean> = {
  bmp: true,
  gif: true,
  jpeg: true,
  jpg: true,
  png: true,
  svg: true,
  webp: true,
} as const;

/**
 * not named Document because it conflicts with the global Document type
 */
const ChatDocument = (props: TimelineAttachment) => {
  //will contain the file extension provided the name has a `.` character
  const extension = props.name.match(/[^.]+$/)?.[0];
  if (props.name.includes(".") && isImage[extension?.toLowerCase() ?? ""]) {
    return (
      <a href={props.url} target="_blank" rel="noopener noreferrer">
        <img
          src={props.url}
          alt={props.name}
          className={styles.image}
          loading="lazy"
          height={400}
        />
      </a>
    );
  }

  return (
    <a
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.attachment}>
      <IconFile className={styles.attachmentIcon} />
      &nbsp;{props.name}
    </a>
  );
};

export { ChatDocument };
