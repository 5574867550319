import { useMutation } from "@tanstack/react-query";
import { isEmptyArray } from "effect/Array";
import { useContext, useState } from "react";

import { uploadFilesDirect } from "@ender/shared/api/files";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { FileInput } from "@ender/shared/ds/file-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Stack } from "@ender/shared/ds/stack";
import { ModelTypeEnum } from "@ender/shared/generated/com.ender.common.model";
import { WebserverFilesServiceFileUploadTypeEnum } from "@ender/shared/generated/ender.service.files";
import { showSuccessNotification } from "@ender/shared/utils/notifications";

type UploadPetDocumentsProps = {
  onSuccess: () => void;
  petId: EnderId;
};

function UploadPetDocuments({ petId, onSuccess }: UploadPetDocumentsProps) {
  const [files, setFiles] = useState<File[]>([]);
  const { user } = useContext(UserContext);

  const { mutate: uploadFiles, isLoading: isUploading } = useMutation({
    mutationFn: async () =>
      uploadFilesDirect({
        files,
        modelId: petId,
        modelType: ModelTypeEnum.PET,
        subFolder: "PUBLIC",
        uploadType: WebserverFilesServiceFileUploadTypeEnum.PET_DOCUMENT,
        userId: user.id,
      }),
    onSuccess: () => {
      showSuccessNotification({
        message: `Pet Document uploaded.`,
      });
      onSuccess();
    },
  });

  return (
    <Stack>
      <FileInput onChange={setFiles} value={files} />
      <Group justify={Justify.end}>
        <Button
          disabled={isEmptyArray(files)}
          loading={isUploading}
          onClick={() => uploadFiles()}>
          Upload
        </Button>
      </Group>
    </Stack>
  );
}

export { UploadPetDocuments };
