/**
 * HTTP Method: GET
 * Pathname: /uploads
 * @function getUploads
 * @memberof BuyAPI
 * @param {BuyAPIGetUploadsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPropertyUploadsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetPropertyUploadsResponse } from "@ender/shared/generated/com.ender.buy.api.response";
import type { PropertyUploadDataMode } from "@ender/shared/generated/com.ender.buy.model.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type BuyAPIGetUploadsSearchParams = {
  token?: string | undefined;
  dataModes?: PropertyUploadDataMode[] | undefined;
};

type BuyAPIGetUploadsPayload = BuyAPIGetUploadsSearchParams;

function getUploadsUnsafeEffect(payload: BuyAPIGetUploadsPayload) {
  const { token, dataModes, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPropertyUploadsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPropertyUploadsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/uploads",
        searchParams: [
          ["token", token],
          ["dataModes", dataModes],
        ],
      }),
    ),
  );
}

function getUploads(
  payload: BuyAPIGetUploadsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPropertyUploadsResponse> {
  return F.pipe(
    payload,
    getUploadsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUploads };
export type { BuyAPIGetUploadsPayload, BuyAPIGetUploadsSearchParams };
