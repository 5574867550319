import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const GetRenewalsReportRequestLeaseTypeValues = [
  "STANDARD",
  "MONTH_TO_MONTH",
  "ALL",
] as const;

const GetRenewalsReportRequestLeaseTypeEffectSchema = Schema.Literal(
  ...GetRenewalsReportRequestLeaseTypeValues,
);

type GetRenewalsReportRequestLeaseType = Schema.Schema.Type<
  typeof GetRenewalsReportRequestLeaseTypeEffectSchema
>;

const GetRenewalsReportRequestLeaseTypeEnum = castEnum(
  GetRenewalsReportRequestLeaseTypeEffectSchema,
);

function randomGetRenewalsReportRequestLeaseType(): GetRenewalsReportRequestLeaseType {
  return rand(GetRenewalsReportRequestLeaseTypeValues);
}

export {
  GetRenewalsReportRequestLeaseTypeEffectSchema,
  GetRenewalsReportRequestLeaseTypeEnum,
  GetRenewalsReportRequestLeaseTypeValues,
  randomGetRenewalsReportRequestLeaseType,
};
export type { GetRenewalsReportRequestLeaseType };
