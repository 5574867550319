/**
 * HTTP Method: GET
 * Pathname: /buy/deals/{dealId}/inspections
 * @function getInspections
 * @memberof BuyAPI
 * @param {BuyAPIGetInspectionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIGetInspectionsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIGetInspectionsResponse } from "../buy-api-get-inspections-response";

type BuyAPIGetInspectionsPathParams = {
  dealId: EnderId;
};

type BuyAPIGetInspectionsSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetInspectionsPayload = BuyAPIGetInspectionsPathParams &
  BuyAPIGetInspectionsSearchParams;

function getInspectionsUnsafeEffect(payload: BuyAPIGetInspectionsPayload) {
  const { dealId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIGetInspectionsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BuyAPIGetInspectionsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/buy/deals/${dealId}/inspections`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInspections(
  payload: BuyAPIGetInspectionsPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIGetInspectionsResponse> {
  return F.pipe(
    payload,
    getInspectionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInspections };
export type {
  BuyAPIGetInspectionsPathParams,
  BuyAPIGetInspectionsPayload,
  BuyAPIGetInspectionsSearchParams,
};
