import type { SortingState } from "@tanstack/react-table";
import { Option as O } from "effect";
import { useMemo } from "react";

import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";

import { useInvoiceFilters } from "../../../../../widgets/invoice-filters/invoice-filters-store";
import { useInvoicesTableTabs } from "../use-invoices-table-tabs";
import { getCommonInvoicesPayload } from "./get-common-invoices-payload";

function useInvoicesPayload({
  invoiceType,
  tableSorting,
}: {
  invoiceType: InvoiceInvoiceType;
  tableSorting?: SortingState;
}) {
  const [activeTab] = useInvoicesTableTabs();
  const {
    accountingPeriodsFilter,
    amountFilter,
    endDateFilter,
    endDueDateFilter,
    firmFilter,
    fundFilter,
    inclusiveEndPaidDate,
    invoiceNumberFilter,
    marketFilter,
    partyFilter,
    payableTypeFilter,
    paymentTypeFilter,
    propertiesFilter,
    startDateFilter,
    startDueDateFilter,
    startPaidDate,
  } = useInvoiceFilters();

  return useMemo(
    () =>
      getCommonInvoicesPayload({
        accountingPeriodsFilter,
        amountMatch: amountFilter,
        endDate: endDateFilter ?? O.none(),
        externalInvoiceId: invoiceNumberFilter,
        firmFilter,
        fundFilter,
        inclusiveEndInvoiceDueDate: endDueDateFilter ?? O.none(),
        inclusiveEndPaidDate: inclusiveEndPaidDate ?? O.none(),
        invoiceType,
        marketIds: marketFilter,
        party: partyFilter,
        payableTypes: payableTypeFilter ?? O.none(),
        paymentTypeFilter,
        properties: propertiesFilter,
        startDate: startDateFilter ?? O.none(),
        startInvoiceDueDate: startDueDateFilter ?? O.none(),
        startPaidDate: startPaidDate ?? O.none(),
        tab: activeTab,
        tableSorting,
      }),
    [
      accountingPeriodsFilter,
      amountFilter,
      endDateFilter,
      endDueDateFilter,
      firmFilter,
      fundFilter,
      inclusiveEndPaidDate,
      invoiceNumberFilter,
      invoiceType,
      marketFilter,
      partyFilter,
      payableTypeFilter,
      paymentTypeFilter,
      propertiesFilter,
      startDateFilter,
      startDueDateFilter,
      startPaidDate,
      activeTab,
      tableSorting,
    ],
  );
}

export { useInvoicesPayload };
