import { useQuery } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, pipe } from "effect";
import type { CSSProperties } from "react";
import { useCallback, useContext, useEffect, useMemo } from "react";

import { AccountingPeriodSelector } from "@ender/entities/accounting-period-selector";
import type { Undefined } from "@ender/shared/constants/general";
import { NULL, UNDEFINED } from "@ender/shared/constants/general";
import {
  INVOICE_PAYABLE_TYPE_DISPLAY_NAMES,
  MIN_CREATE_EDIT_INVOICE_DATE,
} from "@ender/shared/constants/invoices";
import { UserContext } from "@ender/shared/contexts/user";
import type { EnderId } from "@ender/shared/core";
import { LocalDate$, Money$ } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { DateInput } from "@ender/shared/ds/date-input";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { Skeleton } from "@ender/shared/ds/skeleton";
import { Stack } from "@ender/shared/ds/stack";
import { TextInput } from "@ender/shared/ds/text-input";
import { Textarea } from "@ender/shared/ds/textarea";
import { Tooltip } from "@ender/shared/ds/tooltip";
import { Tuple } from "@ender/shared/ds/tuple";
import { UnmanagedForm } from "@ender/shared/forms/ui/unmanaged-form";
import { InvoicesAPI } from "@ender/shared/generated/ender.api.accounting";
import type { CreateInvoiceRequestJob } from "@ender/shared/generated/ender.api.accounting.request";
import type { GetInvoicePagesResponse } from "@ender/shared/generated/ender.api.accounting.response";
import { FirmsAPI } from "@ender/shared/generated/ender.api.core";
import { AccountingPeriodAccountingModuleEnum } from "@ender/shared/generated/ender.model.accounting";
import type { Vendor } from "@ender/shared/generated/ender.model.core.vendor";
import type { BankTransaction } from "@ender/shared/generated/ender.model.payments";
import { PartyEnum } from "@ender/shared/generated/ender.model.payments";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  InvoiceInvoiceTypeEnum,
  InvoicePayableTypeValues,
} from "@ender/shared/generated/ender.model.payments.invoice";
import { FunctionalPermissionEnum } from "@ender/shared/generated/ender.model.permissions";
import type { Task } from "@ender/shared/generated/ender.model.task";
import { TaskTaskStatusGroupingEnum } from "@ender/shared/generated/ender.model.task";
import { SearchServiceSearchTypeEnum } from "@ender/shared/generated/ender.service.search";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useGetAllProperties } from "@ender/shared/hooks/use-get-all-properties";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
import { EnderSearch, useEnderSearchForm } from "@ender/shared/ui/ender-search";
import { Select } from "@ender/shared/ui/select";
import { UploadInvoiceRail } from "@ender/widgets/finance/upload-invoice-rail";

import { CreateInvoiceHeaderLabel } from "./create-invoice-header-label";
import { getTotalAmountFromAllocationsByProperty } from "./get-total-amount-from-allocations-by-property";
import { InstantlyMarkPaidInput } from "./instantly-mark-paid-input";
import { InvoiceNumberLabel } from "./invoice-number-label";
import { InvoicePartySelect } from "./invoice-party-select";
import { PropertiesAllocationsInput } from "./properties-allocations-input";
import { useCreateInvoiceForm } from "./use-create-invoice-form";
import { useCreateInvoiceSubmit } from "./use-create-invoice-submit";

type CreateInvoiceProps = {
  bankTransaction?: BankTransaction;
  hideUploadInvoice?: boolean;
  invoiceType: InvoiceInvoiceType;
  onClose?: () => void;
  onInvoiceSuccessfullyCreated?: (
    invoiceType: InvoiceInvoiceType,
    ops: { shouldCloseForm?: boolean; shouldCloseTask?: boolean },
  ) => Promise<void> | void;
  property?: {
    id: EnderId;
    name: string;
  };
  reconcileTransactionAmount?: string | Undefined;
  selectedPages?: GetInvoicePagesResponse[];
  service?: CreateInvoiceRequestJob;
  showAddAnotherButton?: boolean;
  style?: { gridArea?: CSSProperties["gridArea"] };
  task?: Task;
  vendor?: Vendor;
};

function CreateInvoice({
  bankTransaction,
  hideUploadInvoice = false,
  invoiceType,
  onClose = F.constVoid,
  onInvoiceSuccessfullyCreated = F.constVoid,
  property,
  reconcileTransactionAmount,
  selectedPages,
  service,
  showAddAnotherButton,
  style,
  task,
  vendor,
}: CreateInvoiceProps) {
  const { hasPermissions, user } = useContext(UserContext);
  const [
    isUploadInvoiceOpen,
    { setTrue: openUploadInvoice, setFalse: closeUploadInvoice },
  ] = useBoolean();
  const [
    shouldCloseFormOnSuccess,
    {
      setTrue: setShouldCloseFormOnSuccess,
      setFalse: setShouldNotCloseFormOnSuccess,
    },
  ] = useBoolean(true);
  const [shouldCloseTask, shouldCloseTaskHandlers] = useBoolean(false);

  const { data: firms } = useQuery({
    queryFn: () => FirmsAPI.getFirms({}),
    queryKey: ["firms"],
  });

  const allocationCategoriesRequired = !user.isVendor;

  const { form, onAddProperty, onRemoveProperty, onRemovePropertyAllocation } =
    useCreateInvoiceForm({
      allocationCategoriesRequired,
      bankTransaction,
      invoiceType,
      property,
      service,
      task,
      vendor,
    });

  const propertyIsSelected = form.values?.allocationsByProperty.some(
    (property) => P.isNotNullable(property.propertyId),
  );
  const canInstantApprove =
    hasPermissions(FunctionalPermissionEnum.INSTANT_APPROVE_INVOICE) &&
    propertyIsSelected;

  function onInstantApproveChange(value: boolean) {
    form.setFieldValue("instantApprove", value);

    if (!value) {
      form.setFieldValue("bankAccountId", O.none());
      form.setFieldValue("paymentDate", NULL);
    }
  }

  const { setFieldValue } = form;
  const setNewInvoiceNumber = useCallback(async () => {
    const resp = await InvoicesAPI.getNextInvoiceNumber({});
    if (resp.nextInvoiceNumber) {
      setFieldValue("invoiceNumber", resp.nextInvoiceNumber as string);
    }
  }, [setFieldValue]);

  // lazy useEffect (replaces legacy deprecated useAsync)
  useEffect(() => {
    setNewInvoiceNumber();
  }, [setNewInvoiceNumber]);

  const { handleSubmit, isProcessing } = useCreateInvoiceSubmit({
    form,
    invoiceType,
    onClose,
    onInvoiceSuccessfullyCreated,
    selectedPages,
    setNewInvoiceNumber,
    shouldCloseFormOnSuccess,
    shouldCloseTask,
    task,
  });

  // lazy useEffect
  useEffect(() => {
    // handles resetting checkbox when invoice is no longer able to be instant approved
    if (!canInstantApprove) {
      setFieldValue("instantApprove", false);
    }
  }, [canInstantApprove, setFieldValue]);

  const taskRef = useRefLatest(task);
  const canCloseTask = useMemo(() => {
    if (!taskRef.current) {
      return false;
    }

    return (
      user?.party?.type === PartyEnum.PROPERTY_MANAGER &&
      taskRef.current.statusGrouping !== TaskTaskStatusGroupingEnum.CLOSED
    );
  }, [user?.party?.type, taskRef]);

  const jobSearchProps = useEnderSearchForm({ form, name: "job" });
  const isPayable = invoiceType === InvoiceInvoiceTypeEnum.PAYABLE;
  const showUploadInvoice = (user.isPM || user.isEnder) && !hideUploadInvoice;
  const propertyIds = form.values.allocationsByProperty
    .map((property) => property?.propertyId as EnderId)
    .filter(P.isNotNullable);
  const uniquePropertyIds = Array.from(new Set(propertyIds));

  // TODO extend useGetAllProperties to take enabled and disable for user.isVendor
  const { allProperties = [] } = useGetAllProperties(true, true, true);

  const firm = useMemo(() => {
    const someSelectedPropertyId = form.values.allocationsByProperty.find(
      (property) => P.isNotUndefined(property.propertyId),
    )?.propertyId;
    const firmId = allProperties.find(
      (property) => property.id === someSelectedPropertyId,
    )?.firmId;
    const newFirm = firms?.find(
      (_firm: { id: EnderId }) => _firm.id === firmId,
    );
    return newFirm;
  }, [firms, form.values.allocationsByProperty, allProperties]);

  const totalAllocationsAmountMatchesReconcileTransactionAmount =
    useMemo(() => {
      if (P.isNullable(reconcileTransactionAmount)) {
        return false;
      }
      return Money$.Equivalence(
        Money$.of(reconcileTransactionAmount),
        getTotalAmountFromAllocationsByProperty(
          form.values.allocationsByProperty,
        ),
      );
    }, [form.values.allocationsByProperty, reconcileTransactionAmount]);

  const isReconcileTransactionHideBankAccountSelect =
    P.isNotNullable(bankTransaction);

  return (
    <>
      <div style={style}>
        <Skeleton visible={isProcessing}>
          <UnmanagedForm
            form={form}
            // @ts-expect-error the handleSubmit is going to be called with the post-validated `FormOutput` type
            onSubmit={handleSubmit}>
            <Stack>
              <Group justify={Justify.between}>
                <CreateInvoiceHeaderLabel
                  numSelectedPages={selectedPages?.length ?? 0}
                  isPayable={isPayable}
                />
                {showUploadInvoice && (
                  <Button onClick={openUploadInvoice}>Upload Invoice</Button>
                )}
              </Group>
              {!user.isVendor && (
                <InvoicePartySelect
                  error={
                    form.errors.payee
                      ? `${invoiceType !== InvoiceInvoiceTypeEnum.RECEIVABLE ? "Payee" : "Payer"} is required.`
                      : UNDEFINED
                  }
                  label={
                    invoiceType !== InvoiceInvoiceTypeEnum.RECEIVABLE
                      ? "Payee"
                      : "Payer"
                  }
                  value={form.values.payee?.id}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      const {
                        label: selectedLabel,
                        party,
                        value,
                      } = selectedOption;
                      form.setFieldValue("payee", {
                        id: value,
                        name: selectedLabel,
                        party,
                      });
                    } else {
                      form.setFieldValue("payee", UNDEFINED);
                    }
                  }}
                />
              )}
              <DateInput
                label="Invoice Date"
                name="ledgerDate"
                placeholder="Date"
                {...form.getInputProps("ledgerDate")}
                minDate={MIN_CREATE_EDIT_INVOICE_DATE}
              />
              <TextInput
                label={<InvoiceNumberLabel />}
                name="invoiceNumber"
                {...form.getInputProps("invoiceNumber")}
              />
              {user.isPM && (
                <AccountingPeriodSelector
                  label="Accounting Period (optional)"
                  data-test-id="create-invoice-period"
                  periodType={
                    isPayable
                      ? AccountingPeriodAccountingModuleEnum.ACCOUNTS_PAYABLE
                      : AccountingPeriodAccountingModuleEnum.ACCOUNTS_RECEIVABLE
                  }
                  {...form.getInputProps("periodId")}
                />
              )}
              {P.isNullable(task) && P.isNullable(service) && (
                <EnderSearch
                  label="Task or Service (optional)"
                  placeholder="Search Task or Service"
                  requestParams={{
                    ...(uniquePropertyIds.length === 1 && {
                      propertyId: uniquePropertyIds[0],
                    }),
                    resultsOnEmpty: false,
                    types: [
                      SearchServiceSearchTypeEnum.PROPERTY_SERVICE,
                      SearchServiceSearchTypeEnum.TASK,
                    ],
                  }}
                  showOptionResultType
                  onSelect={jobSearchProps.onSelect}
                  onChange={jobSearchProps.onChange}
                  onClear={jobSearchProps.onClear}
                  value={jobSearchProps.value}
                  useKeyword
                />
              )}
              {isPayable && (
                // Tyler - 11/14/2024 - this pattern of altering an array within a single TextInput  is not good to follow, but no better component exists
                <Tooltip label="When entering multiple purchase orders, separate the numbers with a comma (e.g. 1234, 5678).">
                  <TextInput
                    label="Purchase Order Number(s) (optional)"
                    name="poNumbers"
                    {...form.getInputProps("poNumbers")}
                  />
                </Tooltip>
              )}
              <Textarea
                label="Invoice Description"
                name="description"
                {...form.getInputProps("description")}
              />

              <Select
                label="Invoice Type (optional)"
                {...form.getInputProps("payableType")}
                data={InvoicePayableTypeValues.map((value) => ({
                  label: INVOICE_PAYABLE_TYPE_DISPLAY_NAMES[value],
                  value,
                }))}
              />
              {!user.isVendor && (
                <Tooltip label="Select a property to set firm">
                  <Tuple label="Firm" value={firm?.name || "--"} />
                </Tooltip>
              )}
              <PropertiesAllocationsInput
                canChangeProperty={user.isPM}
                form={form}
                hidePayableCategorySelect={!isPayable}
                onAddProperty={onAddProperty}
                onRemoveProperty={onRemoveProperty}
                onRemovePropertyAllocation={onRemovePropertyAllocation}
              />
              <InstantlyMarkPaidInput
                canInstantApprove={canInstantApprove}
                formInputPropsBankAccountId={form.getInputProps(
                  "bankAccountId",
                )}
                formInputPropsPaymentDate={{
                  ...{
                    ...form.getInputProps("paymentDate"),
                    ...{
                      onChange: (optionLocalDate) => {
                        form.setFieldValue(
                          "paymentDate",
                          pipe(
                            optionLocalDate,
                            O.map((localDate) => localDate.toDate()),
                            O.getOrUndefined,
                          ),
                        );
                      },
                      value: O.some(
                        LocalDate$.of(form.getInputProps("paymentDate").value),
                      ),
                    },
                  },
                }}
                instantApproveCheckboxValue={
                  form.values.instantApprove ?? false
                }
                invoiceType={invoiceType}
                label={
                  isPayable
                    ? "Instantly Mark Paid via Bank Transfer."
                    : "Instantly Mark Received via Bank Transfer."
                }
                onInstantApproveChange={onInstantApproveChange}
                selectedPropertyIds={propertyIds}
                showBankAccountSelect={
                  !isReconcileTransactionHideBankAccountSelect
                }
              />
              {isPayable && (
                <Group justify={Justify.end}>
                  {showAddAnotherButton && (
                    <Button
                      loading={isProcessing}
                      onClick={setShouldNotCloseFormOnSuccess}
                      type="submit"
                      variant={ButtonVariant.outlined}>
                      Save & Add Another
                    </Button>
                  )}
                  <Tooltip
                    disabled={
                      P.isNullable(reconcileTransactionAmount) ||
                      totalAllocationsAmountMatchesReconcileTransactionAmount
                    }
                    label="Invoice amount does not match original transaction amount.">
                    <Button
                      disabled={
                        P.isNotNullable(reconcileTransactionAmount) &&
                        !totalAllocationsAmountMatchesReconcileTransactionAmount
                      }
                      loading={isProcessing}
                      onClick={setShouldCloseFormOnSuccess}
                      type="submit">
                      Save
                    </Button>
                  </Tooltip>
                  {canCloseTask && (
                    <Button
                      variant={ButtonVariant.outlined}
                      type="submit"
                      loading={isProcessing}
                      onClick={() => {
                        shouldCloseTaskHandlers.setTrue();
                      }}>
                      Save & Close Task
                    </Button>
                  )}
                </Group>
              )}
              {invoiceType === InvoiceInvoiceTypeEnum.RECEIVABLE && (
                <Group>
                  {showAddAnotherButton && (
                    <Button
                      loading={isProcessing}
                      onClick={setShouldNotCloseFormOnSuccess}
                      type="submit">
                      Save & Add Another
                    </Button>
                  )}
                  <Button
                    disabledTooltip="Invoice amount does not match original transaction amount."
                    disabled={
                      P.isNotNullable(reconcileTransactionAmount) &&
                      !totalAllocationsAmountMatchesReconcileTransactionAmount
                    }
                    loading={isProcessing}
                    onClick={setShouldCloseFormOnSuccess}
                    type="submit">
                    Save
                  </Button>
                </Group>
              )}
            </Stack>
          </UnmanagedForm>
        </Skeleton>
      </div>

      <UploadInvoiceRail
        onClose={closeUploadInvoice}
        onSuccess={() => {
          closeUploadInvoice();
          onInvoiceSuccessfullyCreated(invoiceType, {
            shouldCloseTask: shouldCloseTask,
          });
        }}
        opened={isUploadInvoiceOpen}
        isReceivableInvoice={invoiceType === InvoiceInvoiceTypeEnum.RECEIVABLE}
        isPayableInvoice={invoiceType === InvoiceInvoiceTypeEnum.PAYABLE}
      />
    </>
  );
}

export { CreateInvoice };
