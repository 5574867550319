import { Predicate as P } from "effect";
import { useCallback, useEffect, useState } from "react";
import type { IIdleTimer } from "react-idle-timer";
import { useIdleTimer } from "react-idle-timer";

import { handleLogout } from "@ender/features/auth";
import { Button } from "@ender/shared/ds/button";
import { H2 } from "@ender/shared/ds/heading";
import { Modal } from "@ender/shared/ds/modal";
import { Stack } from "@ender/shared/ds/stack";
import { useBoolean } from "@ender/shared/hooks/use-boolean";

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

const IdleTimerMessagesEnum = {
  LOGOUT: "logout",
  RESET: "reset",
  WARN_IDLE: "warn_idle",
} as const;

// We launch the modal after 3 hours, then count down for 60s.
const modalTimeout = MINUTE;
const logoutTimeout = modalTimeout + 3 * HOUR;

function EnderIdle() {
  const [remaining, setRemaining] = useState<number>(0);
  const [
    isIdleModalOpen,
    { setTrue: openIdleModal, setFalse: closeIdleModal },
  ] = useBoolean();

  const onIdle = (_e: unknown, idleTimer: IIdleTimer | undefined) => {
    if (P.isNotNullable(idleTimer)) {
      idleTimer.message(IdleTimerMessagesEnum.LOGOUT, true);
    }
  };

  // This function is called when a message is received from another tab
  const onMessage = (cmd: string, idleTimer: IIdleTimer | undefined) => {
    if (cmd === IdleTimerMessagesEnum.LOGOUT) {
      handleLogout();
      return;
    }

    if (cmd === IdleTimerMessagesEnum.RESET && P.isNotNullable(idleTimer)) {
      closeIdleModal();
      idleTimer.reset();
      return;
    }
  };

  const onPrompt = () => {
    openIdleModal();
  };

  const { getRemainingTime, message } = useIdleTimer({
    crossTab: true,
    leaderElection: true,
    onIdle,
    onMessage,
    onPrompt,
    promptBeforeIdle: modalTimeout,
    // The poll interval for syncing timers across tabs
    syncTimers: 30 * SECOND,
    timeout: logoutTimeout,
  });

  const handleReset = useCallback(
    function handleReset() {
      message(IdleTimerMessagesEnum.RESET, true);
    },
    [message],
  );

  // lazy useEffect
  useEffect(handleReset, [handleReset]);

  // lazy useEffect
  useEffect(() => {
    let interval: string | number | ReturnType<typeof setTimeout> | undefined;

    if (isIdleModalOpen) {
      interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }
    };
  }, [isIdleModalOpen, getRemainingTime]);

  return (
    <Modal title="" opened={isIdleModalOpen} onClose={handleReset}>
      <Stack>
        <H2>Session Timeout Warning</H2>
        <p>
          Your session will expire in <strong>{remaining} seconds</strong>.
        </p>
        <Button onClick={handleReset}>Continue Session</Button>
      </Stack>
    </Modal>
  );
}

export { EnderIdle };
