/**
 * HTTP Method: GET
 * Pathname: /sqlReports
 * @function getSqlReports
 * @memberof ReportsAPI
 * @param {ReportsAPIGetSqlReportsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SQLReport[]>}
 */
import { Effect, Function as F } from "effect";

import type { SQLReport } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ReportsAPIGetSqlReportsSearchParams = {
  token?: string | undefined;
};

type ReportsAPIGetSqlReportsPayload = ReportsAPIGetSqlReportsSearchParams;

function getSqlReportsUnsafeEffect(payload: ReportsAPIGetSqlReportsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SQLReport[]>({
        body,
        decode: unsafeDecodeJsonResponse<SQLReport[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/sqlReports",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSqlReports(
  payload: ReportsAPIGetSqlReportsPayload,
  options?: { signal?: AbortSignal },
): Promise<SQLReport[]> {
  return F.pipe(
    payload,
    getSqlReportsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSqlReports };
export type {
  ReportsAPIGetSqlReportsPayload,
  ReportsAPIGetSqlReportsSearchParams,
};
