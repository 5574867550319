/**
 * HTTP Method: POST
 * Pathname: /forms
 * @function getForm
 * @memberof FormsAPI
 * @param {FormsAPIGetFormPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FormInputsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { FormInputsResponse } from "@ender/shared/generated/com.ender.middle.response";
import type { FormType } from "@ender/shared/generated/ender.model.forms";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FormsAPIGetFormSearchParams = {
  token?: string | undefined;
};

type FormsAPIGetFormBodyParams = {
  formType: FormType;
};

type FormsAPIGetFormPayload = FormsAPIGetFormSearchParams &
  FormsAPIGetFormBodyParams;

function getFormUnsafeEffect(payload: FormsAPIGetFormPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FormInputsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<FormInputsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/forms",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getForm(
  payload: FormsAPIGetFormPayload,
  options?: { signal?: AbortSignal },
): Promise<FormInputsResponse> {
  return F.pipe(
    payload,
    getFormUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getForm };
export type {
  FormsAPIGetFormBodyParams,
  FormsAPIGetFormPayload,
  FormsAPIGetFormSearchParams,
};
