/**
 * HTTP Method: GET
 * Pathname: /propertyStatuses
 * @function getStatusInfo
 * @memberof BuyAPI
 * @param {BuyAPIGetStatusInfoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BuyAPIGetStatusInfoResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { BuyAPIGetStatusInfoResponse } from "../buy-api-get-status-info-response";

type BuyAPIGetStatusInfoSearchParams = {
  token?: string | undefined;
};

type BuyAPIGetStatusInfoPayload = BuyAPIGetStatusInfoSearchParams;

function getStatusInfoUnsafeEffect(payload: BuyAPIGetStatusInfoPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BuyAPIGetStatusInfoResponse>({
        body,
        decode: unsafeDecodeJsonResponse<BuyAPIGetStatusInfoResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/propertyStatuses",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getStatusInfo(
  payload: BuyAPIGetStatusInfoPayload,
  options?: { signal?: AbortSignal },
): Promise<BuyAPIGetStatusInfoResponse> {
  return F.pipe(
    payload,
    getStatusInfoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getStatusInfo };
export type { BuyAPIGetStatusInfoPayload, BuyAPIGetStatusInfoSearchParams };
