import { Schema } from "@effect/schema";
import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P, pipe } from "effect";
import { useCallback } from "react";

import { Form, useEffectSchemaForm } from "@ender/form-system/base";
import { MoneyEffectSchema } from "@ender/form-system/schema";
import type { EnderId } from "@ender/shared/core";
import { Money$, Percent$ } from "@ender/shared/core";
import { Button } from "@ender/shared/ds/button";
import { Justify } from "@ender/shared/ds/flex";
import { Group } from "@ender/shared/ds/group";
import { FormMoneyInput } from "@ender/shared/ds/money-input";
import { FormNumberInput } from "@ender/shared/ds/number-input";
import { FormPercentInput } from "@ender/shared/ds/percent-input";
import { Stack } from "@ender/shared/ds/stack";
import type { MarketsAPIGetAllocationForMarketResponse } from "@ender/shared/generated/com.ender.buy.api";
import { BuyAPI } from "@ender/shared/generated/com.ender.buy.api";
import type { Market } from "@ender/shared/generated/com.ender.buy.model.misc";

type UpdateMarketAllocationsFormProps = {
  firmId: EnderId;
  market?: Pick<Market, "id">;
  marketAllocation?: MarketsAPIGetAllocationForMarketResponse;
  onSuccess: () => void;
};

const UpdateMarketAllocationsFormSchema = Schema.Struct({
  capitalAllocation: MoneyEffectSchema.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Budget is required" }),
  ),
  investmentPeriodInDays: Schema.Number.pipe(
    Schema.OptionFromSelf,
    Schema.filter(O.isSome, { message: () => "Duration is required" }),
    Schema.filter((val) => O.exists(val, (v) => v >= 1), {
      message: () => "Duration must be at least 1 day",
    }),
  ),
  minAllocation: Schema.instanceOf(Percent$).pipe(Schema.OptionFromSelf),
});
type UpdateMarketAllocationsFormOutput = Schema.Schema.Type<
  typeof UpdateMarketAllocationsFormSchema
>;

function UpdateMarketAllocationsForm({
  firmId,
  market,
  marketAllocation,
  onSuccess,
}: UpdateMarketAllocationsFormProps) {
  const form = useEffectSchemaForm({
    defaultValues: {
      capitalAllocation: Money$.parse(marketAllocation?.capitalAllocation),
      investmentPeriodInDays: O.fromNullable(
        marketAllocation?.investmentPeriodInDays,
      ),
      minAllocation: Percent$.parse(marketAllocation?.minAllocation),
    },
    schema: UpdateMarketAllocationsFormSchema,
  });

  const { mutateAsync: setAllocation } = useMutation({
    mutationFn: BuyAPI.setAllocation,
    mutationKey: ["BuyAPI.setAllocation"] as const,
  });

  const handleSubmit = useCallback(
    async (values: UpdateMarketAllocationsFormOutput) => {
      if (P.isNullable(market?.id)) {
        throw "Form submitted for nullish market ID";
      }

      await setAllocation({
        firmId,
        marketId: market?.id,
        capitalAllocation: pipe(
          values.capitalAllocation,
          O.map((v) => v.toJSON()),
          O.getOrThrow,
        ),
        investmentPeriodInDays: pipe(
          values.investmentPeriodInDays,
          O.getOrThrow,
        ),
        minAllocation: pipe(
          values.minAllocation,
          O.map((v) => v.toJSON()),
          O.getOrUndefined,
        ),
      });
      onSuccess();
    },
    [firmId, market, setAllocation, onSuccess],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Stack>
        <FormMoneyInput form={form} name="capitalAllocation" label="Budget" />
        <FormNumberInput
          form={form}
          name="investmentPeriodInDays"
          label="Duration (days)"
        />
        <FormPercentInput
          form={form}
          name="minAllocation"
          label="Min Allocation (optional)"
        />
        <Group justify={Justify.end}>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  );
}

export { UpdateMarketAllocationsForm };
