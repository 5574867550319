/**
 * This API is the same as /payout-invoice except provides the option to payout multiple tenants at once, thus
 creating multiple payout invoices per tenant.
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/payout-invoices
 * @function createLeasePayoutInvoices
 * @memberof TenantLedgerAPI
 * @param {TenantLedgerAPICreateLeasePayoutInvoicesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId[]>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TenantLedgerAPICreateLeasePayoutInvoicesPathParams = {
  leaseId: EnderId;
};

type TenantLedgerAPICreateLeasePayoutInvoicesSearchParams = {
  token?: string | undefined;
};

type TenantLedgerAPICreateLeasePayoutInvoicesBodyParams = {
  allTenantsAmount?: SerializesInto<Money> | undefined;
  isForMoveOut?: boolean | undefined;
  ledgerDate: SerializesInto<LocalDate>;
  overrideWarnings?: boolean | undefined;
  periodId?: EnderId | undefined;
  userIdToAmount: Partial<Record<EnderId, Money>>;
};

type TenantLedgerAPICreateLeasePayoutInvoicesPayload =
  TenantLedgerAPICreateLeasePayoutInvoicesPathParams &
    TenantLedgerAPICreateLeasePayoutInvoicesSearchParams &
    TenantLedgerAPICreateLeasePayoutInvoicesBodyParams;

function createLeasePayoutInvoicesUnsafeEffect(
  payload: TenantLedgerAPICreateLeasePayoutInvoicesPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId[]>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/payout-invoices`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createLeasePayoutInvoices(
  payload: TenantLedgerAPICreateLeasePayoutInvoicesPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId[]> {
  return F.pipe(
    payload,
    createLeasePayoutInvoicesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createLeasePayoutInvoices };
export type {
  TenantLedgerAPICreateLeasePayoutInvoicesBodyParams,
  TenantLedgerAPICreateLeasePayoutInvoicesPathParams,
  TenantLedgerAPICreateLeasePayoutInvoicesPayload,
  TenantLedgerAPICreateLeasePayoutInvoicesSearchParams,
};
