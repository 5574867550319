import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const MessageResponseSenderPartyTypeValues = [
  "ENDER_BOT",
  "PROPERTY_MANAGER",
  "PROSPECT",
] as const;

const MessageResponseSenderPartyTypeEffectSchema = Schema.Literal(
  ...MessageResponseSenderPartyTypeValues,
);

type MessageResponseSenderPartyType = Schema.Schema.Type<
  typeof MessageResponseSenderPartyTypeEffectSchema
>;

const MessageResponseSenderPartyTypeEnum = castEnum(
  MessageResponseSenderPartyTypeEffectSchema,
);

function randomMessageResponseSenderPartyType(): MessageResponseSenderPartyType {
  return rand(MessageResponseSenderPartyTypeValues);
}

export {
  MessageResponseSenderPartyTypeEffectSchema,
  MessageResponseSenderPartyTypeEnum,
  MessageResponseSenderPartyTypeValues,
  randomMessageResponseSenderPartyType,
};
export type { MessageResponseSenderPartyType };
