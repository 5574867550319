/**
 * HTTP Method: POST
 * Pathname: /accounting/glCategoryGrouping
 * @function createGLCategoryGrouping
 * @memberof GeneralLedgerAPI
 * @param {GeneralLedgerAPICreateGLCategoryGroupingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GLCategoryGroupingGLCategoryGroupingType } from "@ender/shared/generated/ender.model.accounting";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type GeneralLedgerAPICreateGLCategoryGroupingSearchParams = {
  token?: string | undefined;
};

type GeneralLedgerAPICreateGLCategoryGroupingBodyParams = {
  glCategoryIds: EnderId[];
  name: string;
  type: GLCategoryGroupingGLCategoryGroupingType;
};

type GeneralLedgerAPICreateGLCategoryGroupingPayload =
  GeneralLedgerAPICreateGLCategoryGroupingSearchParams &
    GeneralLedgerAPICreateGLCategoryGroupingBodyParams;

function createGLCategoryGroupingUnsafeEffect(
  payload: GeneralLedgerAPICreateGLCategoryGroupingPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/accounting/glCategoryGrouping",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createGLCategoryGrouping(
  payload: GeneralLedgerAPICreateGLCategoryGroupingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    createGLCategoryGroupingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createGLCategoryGrouping };
export type {
  GeneralLedgerAPICreateGLCategoryGroupingBodyParams,
  GeneralLedgerAPICreateGLCategoryGroupingPayload,
  GeneralLedgerAPICreateGLCategoryGroupingSearchParams,
};
