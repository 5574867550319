/**
 * HTTP Method: GET
 * Pathname: /customFactors
 * @function getCustomFactors
 * @memberof FactorsAPI
 * @param {FactorsAPIGetCustomFactorsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CustomFactor[]>}
 */
import { Effect, Function as F } from "effect";

import type { CustomFactor } from "@ender/shared/generated/ender.model.factor";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FactorsAPIGetCustomFactorsSearchParams = {
  token?: string | undefined;
};

type FactorsAPIGetCustomFactorsPayload = FactorsAPIGetCustomFactorsSearchParams;

function getCustomFactorsUnsafeEffect(
  payload: FactorsAPIGetCustomFactorsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CustomFactor[]>({
        body,
        decode: unsafeDecodeJsonResponse<CustomFactor[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/customFactors",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCustomFactors(
  payload: FactorsAPIGetCustomFactorsPayload,
  options?: { signal?: AbortSignal },
): Promise<CustomFactor[]> {
  return F.pipe(
    payload,
    getCustomFactorsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCustomFactors };
export type {
  FactorsAPIGetCustomFactorsPayload,
  FactorsAPIGetCustomFactorsSearchParams,
};
